import React from 'react';
import { useSelector } from 'react-redux';
import CareNotifications from 'views/CareNotifications/CareNotifications.jsx';
import CareNotificationsMayo from 'views/CareNotifications/CareNotificationsMayo.jsx';

const CareNotificationsWrapper = () => {
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	return isNewExperience ? <CareNotificationsMayo /> : <CareNotifications />;
};

export default CareNotificationsWrapper;
