import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import { getAppointmentsHistory } from 'api/appointments.js';
import { getPreferredLanguageLocale, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import Loader from 'components/Loader.jsx';
import EmptyState from 'components/EmptyState.jsx';
import { UserRoles } from 'constants/enums.js';
import Alert from 'components/Alert.jsx';

const AppointmentsHistory = () => {
	const userRole = getUserRole();
	const userId = getUserId();
	const [isLoading, setIsLoading] = useState(true);
	const [isScrollLoading, setIsScrollLoading] = useState(true);
	const [hasReachedEnd, setHasReachedEnd] = useState(false);
	const [appointmentsHistory, setAppointmentHistory] = useState([]);
	const [error, setError] = useState(null);
	const [pageIndex, setPageIndex] = useState(0);
	const intl = useIntl();

	const getAppointmentHistory = useCallback(async () => {
		const params = {
			userId,
			pageIndex,
			pageSize: 10,
		};
		const response = await getAppointmentsHistory(params);
		if (response.error) {
			setError(`${intl.formatMessage({ id: 'failedToLoad' })}`);
			setIsLoading(false);
			return;
		}
		setAppointmentHistory(prevState => prevState.concat(response));
		setIsScrollLoading(false);
		setHasReachedEnd(response.length < 10);
		setIsLoading(false);
	}, [intl, pageIndex, userId]);

	useEffect(() => {
		if (!hasReachedEnd) {
			getAppointmentHistory();
		}
	}, [pageIndex, userId, hasReachedEnd, getAppointmentHistory]);

	const handleScroll = async event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !isScrollLoading && !hasReachedEnd) {
			setPageIndex(prevState => prevState + 1);
			setIsScrollLoading(true);
			getAppointmentHistory();
		}
	};

	return (
		<div className='appointment-billing-wrapper'>
			{isLoading && (
				<div className='patient-request-loader'>
					<Loader />
				</div>
			)}
			{!isLoading && appointmentsHistory && (
				<div className='appointment-billing' onScroll={handleScroll}>
					<div className='appointment-billing-header'>
						<div className='flex'>
							<div>
								<p>
									{[UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(userRole)
										? translate('patient')
										: translate('doctor')}
								</p>
							</div>
							<div>
								<p>{translate('topic')}</p>
							</div>
							<div>
								<p>{translate('requestedAppointment')}</p>
							</div>
						</div>
					</div>
					{appointmentsHistory.length > 0 &&
						appointmentsHistory.map(appointment => (
							<div key={appointment.id} className='appointment-billing-body'>
								<div className='flex'>
									<div className='flex'>
										<ProfilePicture
											className='doctor-request-img'
											fullName={appointment.participantFullName}
											profilePicture={appointment.profilePicture || ''}
										/>
										<div>
											<p className='bold'>{appointment.participantFullName}</p>
											{userRole === UserRoles.PATIENT && <p>{appointment.specialty}</p>}
										</div>
									</div>
									<div>
										<p>{appointment.title}</p>
									</div>
									<div className='column-direction left-align-items'>
										<p>
											{`${moment
												.utc(appointment.startDateTime)
												.local()
												.locale(getPreferredLanguageLocale())
												.format('ddd, MMMM Do')} `}
										</p>
										<p className='bold'>
											{`${moment
												.utc(appointment.startDateTime)
												.local()
												.locale(getPreferredLanguageLocale())
												.format('hh:mm A')} `}
											{translate('to')}{' '}
											{`${moment
												.utc(appointment.endDateTime)
												.local()
												.locale(getPreferredLanguageLocale())
												.format('hh:mm A')}`}
										</p>
									</div>
									<div>
										<p className='bold'>
											{appointment.place === 'Your mobile device'
												? translate(stringToCamelCase(appointment.place))
												: appointment.place}
										</p>
									</div>
								</div>
							</div>
						))}
					{!appointmentsHistory.length && (
						<div className='empty-state-wrapper empty-appointment-history-wrapper'>
							<EmptyState title={translate('noRequestsFound')} image='no-requests.svg' />
						</div>
					)}
					{isScrollLoading && (
						<div className='patient-request-loader-pagination'>
							<Loader />
						</div>
					)}
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default AppointmentsHistory;
