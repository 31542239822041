import { useEffect, useState } from 'react';
import { TrackChangeEvent } from '@solaborate/calls/webrtc';

const useControllerTracks = controller => {
	const [tracks, setTracks] = useState(controller?.tracks || {});

	useEffect(() => {
		setTracks({ ...controller?.tracks });

		const onEvent = event => {
			if (event instanceof TrackChangeEvent) {
				setTracks({ ...controller?.tracks });
			}
		};

		return controller?.on(onEvent);
	}, [controller]);

	return tracks;
};

export default useControllerTracks;
