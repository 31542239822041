const PatientGettingOutOfChairIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='20' fill='none' viewBox='0 0 18 20' {...props}>
		<path
			d='M8.99332 6.46711C9.95073 6.46711 10.7269 5.69097 10.7269 4.73355C10.7269 3.77614 9.95073 3 8.99332 3C8.0359 3 7.25977 3.77614 7.25977 4.73355C7.25977 5.69097 8.0359 6.46711 8.99332 6.46711Z'
			stroke='currentColor'
			strokeWidth='1.25'
			strokeMiterlimit='10'
		/>
		<path
			d='M10.1612 18.4999C10.0375 18.4999 9.91497 18.4756 9.80065 18.4282C9.68634 18.3809 9.58246 18.3115 9.49497 18.224C9.40747 18.1365 9.33807 18.0326 9.29072 17.9183C9.24337 17.804 9.21899 17.6814 9.21899 17.5577V13.5644C9.21899 13.4407 9.24338 13.3183 9.29074 13.204C9.33811 13.0898 9.40754 12.986 9.49505 12.8986C9.58256 12.8112 9.68645 12.742 9.80076 12.6948C9.91507 12.6476 10.0376 12.6234 10.1612 12.6235C10.4108 12.6235 10.6501 12.7227 10.8265 12.8991C11.003 13.0756 11.1021 13.3149 11.1021 13.5644V17.5577C11.1023 17.6814 11.0781 17.8039 11.0309 17.9182C10.9837 18.0325 10.9144 18.1364 10.827 18.2239C10.7396 18.3114 10.6358 18.3808 10.5216 18.4282C10.4074 18.4756 10.2849 18.4999 10.1612 18.4999Z'
			stroke='currentColor'
			strokeWidth='1.25'
			strokeMiterlimit='10'
		/>
		<path
			d='M7.82542 18.4999C7.70168 18.4999 7.57915 18.4756 7.46484 18.4282C7.35052 18.3809 7.24665 18.3115 7.15915 18.224C7.07166 18.1365 7.00225 18.0326 6.9549 17.9183C6.90755 17.804 6.88318 17.6814 6.88318 17.5577V13.5644C6.88318 13.4407 6.90756 13.3183 6.95493 13.204C7.0023 13.0898 7.07172 12.986 7.15924 12.8986C7.24675 12.8112 7.35063 12.742 7.46494 12.6948C7.57925 12.6476 7.70174 12.6234 7.82542 12.6235C8.07495 12.6235 8.31427 12.7227 8.49072 12.8991C8.66717 13.0756 8.76629 13.3149 8.76629 13.5644V17.5577C8.76647 17.6814 8.74227 17.8039 8.69506 17.9182C8.64786 18.0325 8.57858 18.1364 8.4912 18.2239C8.40381 18.3114 8.30003 18.3808 8.18579 18.4282C8.07155 18.4756 7.94909 18.4999 7.82542 18.4999Z'
			stroke='currentColor'
			strokeWidth='1.25'
			strokeMiterlimit='10'
		/>
		<path
			d='M14.1695 12.4876L12.0485 7.66631C11.9305 7.39872 11.7372 7.17126 11.4921 7.01167C11.2471 6.85208 10.9609 6.76727 10.6684 6.76758H7.3169C7.0247 6.76753 6.73878 6.85247 6.49399 7.01204C6.2492 7.17162 6.0561 7.39893 5.93822 7.66631L3.81445 12.4876C3.74693 12.6598 3.74863 12.8515 3.8192 13.0225C3.88977 13.1935 4.02374 13.3306 4.19306 13.405C4.36239 13.4795 4.55396 13.4856 4.72769 13.4221C4.90141 13.3585 5.04383 13.2303 5.12515 13.0641L6.73905 9.39306C6.74663 9.37797 6.75902 9.36583 6.77426 9.35854C6.7895 9.35126 6.80673 9.34925 6.82324 9.35283C6.83974 9.35641 6.85459 9.36538 6.86544 9.37833C6.87629 9.39127 6.88253 9.40746 6.88317 9.42433V12.9567C7.01374 12.7545 7.20588 12.5996 7.43122 12.515C7.65657 12.4304 7.90315 12.4205 8.13454 12.4868C8.36593 12.5531 8.56986 12.6921 8.71619 12.8832C8.86251 13.0744 8.94348 13.3075 8.94712 13.5482H9.03822C9.04158 13.3074 9.12234 13.074 9.26857 12.8827C9.41479 12.6914 9.61871 12.5522 9.85016 12.4857C10.0816 12.4192 10.3283 12.429 10.5538 12.5136C10.7793 12.5981 10.9715 12.753 11.1022 12.9553V9.42841C11.1013 9.41076 11.1068 9.39338 11.1176 9.37937C11.1283 9.36535 11.1437 9.35561 11.161 9.35186C11.1783 9.34811 11.1963 9.3506 11.2119 9.35889C11.2276 9.36718 11.2397 9.38073 11.2463 9.39714L12.8602 13.0682C12.9366 13.2418 13.0789 13.378 13.2558 13.4467C13.4326 13.5154 13.6295 13.5111 13.8031 13.4346C13.9767 13.3582 14.1129 13.2159 14.1816 13.0391C14.2503 12.8622 14.246 12.6653 14.1695 12.4917V12.4876Z'
			stroke='currentColor'
			strokeWidth='1.25'
			strokeMiterlimit='10'
		/>
		<path
			d='M7 13H5.25M11.5 13H12.75M6.5 17H1V10.5C1 9.67157 1.67157 9 2.5 9C3.32843 9 4 9.67157 4 10.5V12M11.5 17H17V10.5C17 9.67157 16.3284 9 15.5 9C14.6716 9 14 9.67157 14 10.5V12'
			stroke='currentColor'
			strokeWidth='1.25'
			strokeMiterlimit='10'
		/>
		<path
			d='M15 9V5C15 2.79086 13.2091 1 11 1H7C4.79086 1 3 2.79086 3 5V9'
			stroke='currentColor'
			strokeWidth='1.25'
			strokeMiterlimit='10'
		/>
	</svg>
);

export default PatientGettingOutOfChairIcon;
