import React from 'react';
import translate from 'i18n-translations/translate.jsx';

const PatientRisk = ({ assignedCallRequests }) => {
	return (
		<div className='flex patient-risk-grid'>
			<div>
				<p>{translate('pendingRequests')}</p>
				<h1>{assignedCallRequests.totalPendingRequests}</h1>
			</div>
			<div>
				<p>{translate('rapidRequestTypeCalls')}</p>
				<h1>{assignedCallRequests.totalRapidRequests}</h1>
			</div>
			<div>
				<p>{translate('admissionRequestTypeCalls')}</p>
				<h1>{assignedCallRequests.totalAdmissionRequests}</h1>
			</div>
			<div>
				<p>{translate('regularRequestTypeCalls')}</p>
				<h1>{assignedCallRequests.totalRegularRequests}</h1>
			</div>
		</div>
	);
};

export default PatientRisk;
