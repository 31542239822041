import * as React from 'react';

function VideocamOff(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
			<path d='M1.2 1.8l21 21' stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' />
			<path d='M5.4 6H3.2a2 2 0 00-2 2v8a2 2 0 002 2h13a.6.6 0 00.6-.6v0' stroke='currentColor' strokeWidth={1.5} />
			<path d='M8.398 6h6.4a2 2 0 012 2v6.4' stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' />
			<path
				d='M16.8 10.943a1 1 0 01.713-.957l4-1.2a1 1 0 011.288.957v4.512a1 1 0 01-1.288.958l-4-1.2a1 1 0 01-.712-.958v-2.112z'
				stroke='currentColor'
				strokeWidth={1.5}
			/>
		</svg>
	);
}

export default VideocamOff;
