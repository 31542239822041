import LightTheme from 'calls/styles/LightTheme.js';

const UpInAChairIcon = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='48'
		height='48'
		viewBox='0 0 48 48'
		fill='none'
		color={LightTheme.colors.grayZero}
		{...props}>
		<path
			d='M28 32H16C14.8954 32 14 32.8954 14 34C14 35.1046 14.8954 36 16 36H28C29.1046 36 30 35.1046 30 34C30 32.8954 29.1046 32 28 32Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M19 36H16V45L19 36Z' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
		<path d='M25 36H28V45L25 36Z' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
		<path
			d='M14 34C14 34 15 25.3135 15 21C15 16.6865 14 10 14 10'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M24 12C25.933 12 27.5 10.433 27.5 8.5C27.5 6.567 25.933 5 24 5C22.067 5 20.5 6.567 20.5 8.5C20.5 10.433 22.067 12 24 12Z'
			stroke='currentColor'
			strokeWidth='1.5'
			stroke-miterlimit='10'
			strokeLinecap='round'
		/>
		<path
			d='M33.2672 27.6584C34.8746 27.8593 36.1407 29.1254 36.3416 30.7328L37.7063 41.6504C37.8623 42.898 36.8894 44 35.6321 44C34.6729 44 33.8368 43.3472 33.6042 42.4166L31.1429 32.5714C31.0589 32.2356 30.7572 32 30.411 32H21.6364C20.7326 32 20 31.2674 20 30.3636V19C20 16.7909 21.7909 15 24 15C26.2091 15 28 16.7909 28 19V21'
			stroke='currentColor'
			strokeWidth='1.5'
			stroke-miterlimit='10'
			strokeLinecap='round'
		/>
		<path
			d='M23 19L24 26L30.332 29.166C31.2124 29.6062 32.2785 29.1646 32.5897 28.2308V28.2308C32.8294 27.5117 32.5375 26.7225 31.8875 26.3325L28 24V21'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default UpInAChairIcon;
