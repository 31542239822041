import { UserRoles } from 'calls/enums/index.js';
import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';

export const getRegionSubTree = async (healthSystemId, regionId) => {
	try {
		const isAdmin = getUserRole() === UserRoles.ADMIN;
		const companyId = getCompanyId();
		const regionUrl = `organizations/${companyId}/health-systems/${healthSystemId}/regions/${regionId}`;

		const subtree = `/v1.2/${regionUrl}/subtree`;
		const tree = `/v1.1/${regionUrl}`;

		const { data } = await gatewayApi.get(isAdmin ? tree : subtree);
		return data;
	} catch (error) {
		return { error };
	}
};
