import { healthCareCdnUrl } from 'constants/global-variables.js';
import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfUse = () => (
	<div className='new-experience'>
		<div className='privacy-policy-terms-wrapper'>
			<div className='privacy-policy-logo'>
				<Link to='/'>
					<img src={`${healthCareCdnUrl}header/bluelogo-1.svg`} alt='hellocare' />
				</Link>
			</div>
			<div className='privacy-policy-terms'>
				<h1>hellocare Terms of Use</h1>
				<p>
					hellocare together with its subsidiaries including without limitation (&quot;hellocare&quot;, &quot;we&quot;,
					&quot;our&quot;, or the &quot;Company&quot;) welcomes you (the “Yourself”, or “you”) to use the hellocare telehealth
					services through the use of the Hello Devices, the <span className='bold'>hellocare</span> Mobile application (the
					&quot;App&quot;), and/or the Hello Health Platform accessible via a designated access browser/dashboard (the &quot;
					<span className='bold'>hellocare</span> Platform&quot;), and to visit our web website www.hellocare.ai (the “Site”) (use
					of the <span className='bold'>hellocare</span> Platform, the App, the Site and any services provided through the Hello
					Devices, collectively, the “Services”) (all as defined in more detail hereunder). You may use the Services and the Hello
					Devices solely in accordance with the terms and conditions hereunder.
				</p>
				<br />
				<br />
				<h3>1. Acceptance of the Terms</h3>
				<p>
					By entering to, connecting to, accessing or using the Services and/or by setting up an Account, you acknowledge that you
					have read and understood the following terms of use, including the terms of our{' '}
					<Link target='_blank' to='/privacy-policy'>
						Privacy Policy
					</Link>{' '}
					(collectively, the &quot;Terms&quot;) and you agree to be bound by them and to comply with all applicable laws and
					regulations regarding your use of the Services and you acknowledge that these Terms constitute a binding and enforceable
					legal contract between hellocare and you.
					<br />
					<br />
					<span className='bold'>
						IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT ENTER TO, CONNECT TO, ACCESS OR USE THE SERVICES OR ANY PART THEREOF
						IN ANY MANNER.
					</span>
					<br />
					<br />
					THESE TERMS CONSTITUTE THE ENTIRE TERMS AND CONDITIONS BETWEEN YOU AND HELLOCARE RELATING TO THE SUBJECT MATTER HEREIN
					AND SUPERSEDE ANY AND ALL PRIOR OR CONTEMPORANEOUS AGREEMENTS, UNDERSTANDINGS, PROMISES, CONDITIONS, NEGOTIATIONS,
					COVENANTS OR REPRESENTATIONS, WHETHER WRITTEN OR ORAL, BETWEEN HELLOCARE AND YOU, INCLUDING, WITHOUT LIMITATION, THOSE
					MADE BY OR BETWEEN ANY OF OUR RESPECTIVE REPRESENTATIVES, WITH RESPECT TO THE SERVICE. YOU AGREE THAT YOU WILL MAKE NO
					CLAIM AT ANY TIME OR PLACE THAT THESE TERMS HAVE BEEN ORALLY ALTERED OR MODIFIED OR OTHERWISE CHANGED BY ORAL
					COMMUNICATION OF ANY KIND OR CHARACTER. YOU FURTHER AGREE THAT YOU ARE NOT RELYING UPON ANY PROMISE, INDUCEMENT,
					REPRESENTATION, STATEMENT, DISCLOSURE OR DUTY OF DISCLOSURE OF HELLOCARE IN ENTERING INTO THESE TERMS.
					<br />
					<br />
					IMPORTANT ! – DO NOT USE THE SERVICES FOR EMERGENCY MEDICAL NEEDS. IF YOU EXPERIENCE A MEDICAL EMERGENCY, CALL 911
					IMMEDIATELY.
				</p>
				<h3>2. The Service</h3>
				<p>
					hellocare does not provide any physicians’ or other healthcare providers’ (collectively, “Providers”) services itself.
					All of the Providers are independent of hellocare and merely using the Services to communicate with you. Any information
					or advice received from a Provider comes from the Provider, and not from hellocare. Your interactions with the Providers
					via the Services are not intended to take the place of your relationship(s) with your regular health care
					practitioner(s).
					<br /> <br />
					Neither hellocare nor any of its licensors or suppliers or any third parties who promote the Services or provide you
					with a link to the Services shall be liable for any professional Advice you obtain from a Provider via the Service nor
					for any information obtained from our Services. You acknowledge your reliance on any Providers or information provided
					by the Services is solely at your own risk and you assume full responsibility for all risk associated therewith.
					<br /> <br />
					hellocare does not make any representations or warranties about the training or skill of any Providers using the
					Services. You are ultimately responsible for choosing your particular Provider on the Services.
					<br /> <br />
					The hellocare Services provide a telehealth solution enabling communication of Medical Information between individuals
					requiring health services (“Patients”) and health services providers registered to the hellocare Services
					(“Clinicians”). The Service is available through the use of a digital examination and monitoring tool (the “Hello
					Devices”) which allows the viewing, capturing, measuring, processing, transmitting and displaying Patient physiological
					data of relevant body parts or organs including but not limited to legs, feet, shoulders, arms, hands, head (including
					eyes, nose, mouth and ears), neck, back, heart, lungs, skin and, and including but not limited to physiological data
					such as clinical visual appearance, audio sounds (as applicable) and other measurements of such body organs, body
					temperature, heart rate and heart rate variability, blood pressure, pulse oximetry, oxygenation, respiratory rate, fall
					prevention and detection, physical therapy and exercise program (“Patient Medical Information”). The Service also allows
					for the communication of Patient Medical Information, inter-alia for the purpose of producing clinical diagnosis and the
					communication of such clinical analysis or diagnosis information back to the Patient(s) (“Medical Diagnosis
					Information”) (Patient Medical Information and Medical Diagnosis Information, collectively “Medical Information”). The
					hellocare Services enable communication of the Medical Information through the App or the Hello Devices either in
					real-time mode, as a virtual visit, by on-line live audio and video connecting Patients with Clinicians in real time, in
					parallel to streaming of the Patient Medical Information (“hellocare Platform”).
					<br />
					<br />
					TO USE THE SERVICE AND OPEN A USER ACCOUNT YOU MUST BE OVER THE AGE OF EIGHTEEN (18) YEARS (SEE SECTION 17 BELOW). You
					may however use your Account (as defined below) for different Patients in addition to Yourself, which Patients may be
					under the age of eighteen (18) years of age (“Minor Dependents”), by adding and listing such Patients under Your
					Account. You may only add and list a Minor Dependent under your Account if you are the parent or legal guardian of such
					Minor Dependent. Additionally, Minor Dependents may be added under your Account if you are their parent or legal
					guardian, by your healthcare services provider subject to receiving your permission. For any Patient, whether or not a
					Minor Dependent whom you are adding and listing under your Account, you are hereby acknowledging and agreeing to these
					Terms on behalf of such Patient and You declare to hellocare that You have all legal authority to bind such Patient and
					have the necessary legal capacity, authorities, consents and approvals (as applicable) provided from or in connection
					with such Patient(s), and in accordance with Sections 4 and 17 hereof and hellocare Privacy Policy to act as such
					Patient care giver and receive, transmit and access such Patient’s Medical Information. You assume full responsibility
					for your actions and omission on behalf of Patients listed under your Account.
					<br />
					<br />
					You, Patients and Clinicians are all referred to herein as users of the hellocare Services and the Hello Devices (the
					“Users”) and the Terms shall apply to each User accordingly. Users can sign up through the Site for our mailing list in
					order to receive updates about the Company&apos;s developments etc.
					<div className='boxed-inner-terms-section'>
						<span className='bold'>IMPORTANT- NO MEDICAL ADVICE BY HELLOCARE:</span>
						<br />
						<br />
						HELLOCARE IS NOT A HEALTH CARE PROVIDER. HELLOCARE HAS NO RESPONSIBILITY WHATSOEVER TO THE MEDICAL INFORMATION
						PROVIDED OR CONVEYED VIA THE SERVICE, NOR FOR THE CONTENT IN THE SITE.
					</div>
					<div className='boxed-inner-terms-section'>
						THE HELLO DEVICES ARE COMMUNICATION DEVICES ENABLING A MERE CONDUIT BETWEEN PATIENTS AND CLINICIANS FOR COMMUNICATION
						OR MEDICAL INFORMATION GENERATED BY USERS. HELLOCARE THEREFORE IS NOT, AND WILL NOT, BE RESPONSIBLE IN ANY WAY FOR ANY
						MEDICAL DIAGNOSIS OR EXAMINATION INFORMATION, DETERMINATION OR RECOMMENDATION INCLUDING ANY TREATMENT RECOMMENDATIONS,
						CONVEYED USING THE HELLOCARE SERVICES BY ANY USER, INCLUDING BY A CLINICIAN.
						<br />
						<br /> BY USING THE SERVICE, YOU AGREE THAT HELLOCARE IS NOT RESPONSIBLE OR LIABLE TO YOU FOR ANY CLAIM, LOSS, OR
						DAMAGE ARISING FROM THE SERVICE OR ITS USE BY YOU OR ANY OTHER USER.
						<br />
						<br /> UNLESS EXPRESSLY PROVIDED OTHERWISE IN THE SERVICE, HELLOCARE DOES NOT IN ANY WAY ENDORSE, RECOMMEND OR ASSUME
						ANY RESPONSIBILITY FOR ANY DECISION MADE OR ACTION TAKEN OR NOT TAKEN WHOLLY OR PARTIALLY IN RELIANCE ON THE SERVICE
						OR ANY INFORMATION CONVEYED VIA THE SERVICE, OR OBTAINED THEREFROM, NOR FOR ANY LOSS, INJURY, INCONVENIENCE, HARM
						AND/OR DAMAGE INCURRED AS A RESULT OF, OR IN CONNECTION WITH, THE SERVICE OR USE THEREOF.
						<br />
						<br /> HELLOCARE IS NOT SPONSORED OR ENDORSED IN ANY MANNER BY ANY MEDICAL OR HEALTHCARE CLINICIAN, INSTITUTION OR ANY
						PHARMACEUTICAL CORPORATION, AND DOES NOT PROVIDE ANY PARTICULAR ENDORSEMENT FOR SUCH.
						<br />
						<br /> YOU ACKNOWLEDGE AND AGREE THAT THE USE OF THE SERVICE AND THE HELLO DEVICES IS ENTIRELY AT YOUR OWN RISK.
					</div>
				</p>
				<h3>3. Registration and User Account</h3>
				<p>
					In order to use the Services and the Hello Devices, you must register and open a User account on the App (the
					“Account”). Alternatively, if You are a Clinician or if you have granted your healthcare services provider your
					permission, your Account may also be created by your employer administrator or by your healthcare services provider
					(respectively). As part of the registration, users will be required to enter their information such his/her contact
					information such as full name, e-mail address, phone number, full address, username and password. The User may also be
					required to input certain personal demographic details which are related to the Services provided such as date of birth,
					gender and family members, connections or ties and other basic information. During the registration process, you will be
					asked to choose a password and username for your Account. You are solely and fully responsible for maintaining the
					confidentiality of the login credentials (e-mail, username and password) of your Account and for all activities that
					occur under your Account. You agree not to disclose your login credentials to any third party. If you forget your
					password, you can request through the Services appropriate channel to have a new password issued and sent to your
					registered e-mail address. Your Account is at risk if you let someone else use it inappropriately and we reserve the
					right to terminate your Account if we determine that you or anyone using your Account violates these Terms.
					<br />
					<br />
					To learn more about the type of information collected by us when you register for the Service, please visit our{' '}
					<Link target='_blank' to='/privacy-policy'>
						Privacy Policy.
					</Link>
					<br />
					<br />
					You must provide accurate and complete information when creating an Account and you agree to not misrepresent any of the
					personal information provided for the Account registration or Account information, and to keep your Account information
					accurate and up to date.
					<br />
					<br />
					You may not assign or transfer your rights or delegate your duties under the Account and/or these Terms without the
					prior written consent of hellocare.
					<br />
					<br />
					You must notify us immediately of any unauthorized use of your Account of login credentials or any other breach of
					security. We cannot and will not be liable for any loss or damage arising from any failure of yours to comply with the
					Terms or from any unauthorized use of your Account or any other breach of security. You may be liable for the losses of
					hellocare and/or others due to any such use.
					<br />
					<br />
					If you wish to modify your Account information, or if you wish to terminate your Account, you may do so by contacting
					your health care services provider’s administrator or hellocare Care Support support@hellocare.ai. Your Account will be
					terminated within a reasonable time following your request in accordance with the Privacy Policy, and from that date you
					will no longer be able to access your Account, and without derogating from any right or claim available to hellocare,
					any and all permissions, rights and licenses granted to you under these Terms shall be instantly terminated and revoked.
					<br />
					<br />
					NOTE THAT TERMINATING YOUR ACCOUNT MAY CAUSE THE LOSS AND/OR UNAVAILABILITY OF CONTENT, FEATURES, OR CAPACITY WITH
					REGARD TO YOUR ACCOUNT. HELLOCARE SHALL NOT BE LIABLE IN ANY WAY FOR SUCH UNAVAILABILITY AND/OR LOSS.
				</p>
				<h3>4. User Representations and Undertakings</h3>
				<p>
					Your use of the Service (or any part thereof) is dependent on the fact that you hereby represent and warrant that:
					<br />
					<br />
					<ul>
						<li>
							You possess the knowledge and judgment necessary to decide whether to use the Service or otherwise rely on any
							information available therein;
						</li>
						<li>
							You will obtain appropriate health-care advice (including medical advice) to protect your interests and health
							before relying on any Medical Diagnosis Information provided through your use of the Services or the Hello Devices.
							You acknowledge that there is no professional relationship (including any doctor-patient or advisor-advisee
							relationship) between you and hellocare, and that you may not solely or fundamentally rely on any information,
							content or any features you find on or through the Service;
						</li>
						<li>
							You possess the legal authority to enter into these Terms and to form a binding agreement under any applicable law,
							to use the Service in accordance with these Terms, and to fully perform your obligations hereunder all on behalf of
							Yourself and/or any Patients you added and listed under your Account or on behalf of whom you use the Services;
						</li>
						<li>
							If you connect to, access or use the Service on behalf of any third party, family member or dependent, you represent
							and warrant that you are duly authorized under any applicable law to represent such third party, family member or
							dependent in connection with these Terms and to commit it to be bound by these Terms, and hereby make all
							representations and warranties herein on both your and its behalf;
						</li>
						<li>
							You possess any and all consents required under any applicable laws, for the uploading, processing, transmitting and
							displaying of any personal and Medical Information of others on behalf of whom you are acting in connection with the
							Service and the Hello Devices, and you shall adhere to any applicable laws regarding such information;
						</li>
						<li>
							The execution of the Terms does not and will not violate any other agreement to which you are bound or any law,
							rule, regulation, order or judgment to which you are subject;
						</li>
						<li>
							You acknowledge that you are solely responsible for complying with applicable laws regarding your use of the
							Service;
						</li>
						<li>You will not infringe or violate any of the Terms.</li>
						<li>
							hellocare may allow Users to upload and communicate through the Services, certain Medical Information such Users
							obtained from third parties’ systems, products and services (“External Medical Information”). You understand that
							the accuracy and completeness of Medical Information may be critical for any decision or diagnosis made using such
							Information. You are solely responsible for the External Medical Information you upload or otherwise communicate and
							you are aware that hellocare is not checking or monitoring such External Medical Information, including not for its
							accuracy and/or completeness and that such accuracy and/or completeness is not guaranteed, and may vary according to
							its source and measurement techniques.
						</li>
					</ul>
				</p>
				<h3>5. Use Restrictions</h3>
				<p>
					There are certain conducts which are strictly prohibited in our Services. Please read the following restrictions
					carefully. Your failure to comply with the provisions set forth herein may result (at hellocare’s sole discretion) in
					the termination of your access to the Services and may also expose you to civil and/or criminal liability.
					<br />
					<br />
					<br />
					<span className='bold'>You may not, whether by yourself or anyone on your behalf:</span> (i) copy, modify, adapt,
					translate, reverse engineer, decompile, or disassemble any portion of the Services or any of the hellocare information
					and resources about the Services, hellocare’s systems and technology and any other content related thereto (including
					contact information, videos, text, logos, button icons, images, data compilations, links, other specialized content,
					documentation, data, related graphics, intellectual property and other features (collectively, the “Content”), in any
					way or publicly display, perform, or distribute them; (ii) make any use of the Services and/or Content on any other
					website or networked computer environment other than in the Site, for any purpose, or replicate or copy the Content
					without hellocare&apos;s prior written consent; (iii) create a browser or border environment around hellocare&apos;s
					Services, Content and any part thereof (no frames or inline linking); (iv) interfere with or violate any other User’s
					right to privacy or other rights, or harvest or collect personally identifiable information about visitors or users of
					the Service without their express and informed consent, including using any robot, spider, site search or retrieval
					application, or other manual or automatic device or process to retrieve, index, or data-mine; (v) defame, abuse, harass,
					stalk, threaten, or otherwise violate the legal rights of others; (vi) transmit or otherwise make available in
					connection with our Services any virus, worm, Trojan Horse, time bomb, web bug, spyware, or any other computer code,
					file, or program that may or is intended to damage or hijack the operation of any hardware, software, or
					telecommunications equipment, or any other actually or potentially harmful, disruptive, or invasive code or component;
					(vii) interfere with or disrupt the operation of our Services, or the servers or networks that host our Services, or
					disobey any requirements, procedures, policies, or regulations of such servers or networks; (viii) sell, license, or
					exploit for any commercial purposes any use of or access to the Content of our Services; (ix) frame or mirror any part
					of this Site without hellocare&apos;s prior express written authorization; (x) create a database by systematically
					downloading and storing all or any of the Content from our Services; (xi) forward any data generated from our Services
					without the prior written consent of hellocare; or (xii) to transfer and/or assign the User’s Account’s password, even
					temporary, for third party; (xiii) use our Services for any illegal, immoral or unauthorized purpose; (xiv) use the
					Services including without limitation the Hello Devices’ camera to upload any information not reasonably required for
					the viewing, capturing, measuring, processing, displaying, diagnosis and analysis of the Medical Data, without
					limitation; (xv) capture, upload, display, convey and/or otherwise communicate to hellocare or to any Users, any
					information, imagery and other content which is proprietary information of any third party who is not (i) a Patient
					listed under your Account, or (ii) a Patient to whom you provide healthcare services in your capacity as a Clinician, or
					is, inter alia, harassing, offensive, indecent, pornographic, illegal, abusive or vulgar; or (xvi) use our Services, the
					and/or the Content for non-personal or commercial purposes without hellocare&apos;s express prior written consent or
					pursuant to an express written agreement.
				</p>
				<h3>6. No Liability for Clinicians</h3>
				<p>
					You hereby acknowledge and agree that your interaction with and choice of the Clinicians is solely at your own risk, and
					that hellocare does not provide recommendations on any of the Clinicians nor endorses any of them. hellocare shall not
					be liable or responsible in any way whatsoever for any damage, loss, liability, cost, expense or claim in connection
					with any advice or information provided by such Clinician, including without limitation as to the reliability, quality,
					accuracy or usability of such advice or information, nor in connection with the identity, qualification, credentials or
					action and omissions of the Clinician.
				</p>
				<h3>7. Clinicians Undertakings and Representations</h3>
				<p>
					If you are a Clinician, you hereby undertake and represent to hellocare that: (i) you are a licensed health care
					practitioner and that your license has not been revoked or withdrawn, and you also consent to either hellocare or a 3rd
					party service contracted by hellocare to verify that your professional license is not expired or revoked to practice in
					your respective state; (ii) you will use your best efforts and employ reasonably accepted professional standards in your
					field of practice, in your use of the Services including when conducting through <span className='bold'>hellocare</span>{' '}
					Platform, when evaluating Medical Information, and to provide your diagnosis or any other Medical Information to any
					Patient; (iii) you have the requisite authority, consents and approvals required to provide the health care service to
					the Patient including uploading the diagnosis to the <span className='bold'>hellocare</span> Platform and for sharing a
					Patient&apos;s Medical Information with a third party clinician for the sole purpose of clinical/medical consultation;
					(iv) you have the full power and authority to accept these Terms; (v) you shall keep confidential any and all
					information you learn from the hellocare Services and Hello Devices and shall not use such information for any purpose,
					including for commercial or personal purposes, other than as required to perform your health care services and provide
					diagnostics to the Patients, nor will you copy, make screen captures or store any Content including any Medical
					Information from the Services ; (vi) you will not receive any payment, benefit or kickback in violation of any and all
					laws, rules, regulations and ethical rules and standards applicable to you (“
					<span className='bold'>Laws</span>”); (vii) you acknowledge that any Patient you interact with, or receive access to its
					Medical Information, through the hellocare Services, is deemed your patient in accordance with all Laws and that you
					will adhere to such Laws in your provision of health care services; and (viii) you will protect and maintain the
					confidentiality of any Medical Information you receive or have access to in the scope of the Services, and use it or
					disclose it (only to third parties bound by appropriate non-disclosure obligations) to the extent required for the
					provision of health care services to the relevant patient.
				</p>
				<h3>8. Privacy Policy</h3>
				<p>
					At hellocare, we’re committed to protecting and respecting your privacy. We believe that you have a right to know our
					practices regarding the information hellocare collects when you connect to, access or use our Services. Our policy and
					practices and the type of information collected are described in our Privacy Policy. If you intend to connect to, access
					or use our Services you must first read and agree to the Privacy Policy. <br /> <br />
					As set out in the Privacy Policy, hellocare may at its own discretion (and is not obligated to do so) review and monitor
					user’s examination data and audiovisual recordings to detect any incorrect use of the Services and upon such detection
					have hellocare’s support team contact such user through the <span className='bold'>hellocare</span> App and/or Platform
					to provide you with support for correction of such use.
				</p>
				<h3>9. Intellectual Property Rights</h3>
				<p>
					The Company is granting you with a limited, personal, not exclusive, non-assignable, not-tradeable license, which cannot
					be used in order to issue sub-licenses and that can be cancelled completely, to use: (a) the{' '}
					<span className='bold'>hellocare</span> App on your smart device or on a smart device which is in your control; (b) the
					Hello Devices which are in your control; (c) the Site and <span className='bold'>hellocare</span> Platform, subject to
					the terms and conditions in this agreement. These Terms do not entitle you with any right in the{' '}
					<span className='bold'>hellocare</span> App, Hello Devices, <span className='bold'>hellocare</span> Platform and/or the
					Site, rather than a limited right to use it in accordance herewith.
					<br />
					<br /> hellocare’s marks and logo (whether or not registered) and all other proprietary identifiers used by hellocare in
					connection with the Service (the <span className='bold'>hellocare</span> Trademarks”) are all trademarks and/or trade
					names of the Company, whether they are registered or not. All other trademarks, trade names, identifying marks and logos
					might appear in the service belonging to their right owners (“Third Party Trademarks”). No right, license, or interest
					to hellocare Trademarks is granted hereunder, and you agree that no such right, license, or interest shall be asserted
					by you with respect to hellocare Trademarks and therefore you will avoid using any of those marks unless such use was
					specifically authorized in the Terms.
					<br />
					<br /> Our Services, the Content and any and all intellectual property rights pertaining thereto, including, but not
					limited to, inventions, patents and patent applications, trademarks, trade names, logos, copyrightable materials,
					graphics, text, images, designs (including the &quot;look and feel&quot; of our Services), specifications, methods,
					procedures, information, know-how, algorithms, data, technical data, interactive features, source and object code,
					files, interface and trade secrets, whether or not registered or capable of being registered (collectively,
					&quot;Intellectual Property&quot;), are owned and/or licensed to hellocare and subject to copyright and other applicable
					intellectual property rights under the United States laws, foreign laws and international conventions. You may not copy,
					distribute, display, execute publicly, make available to the public, reduce to human readable form, decompile,
					disassemble, adapt, sublicense, make any commercial use, sell, rent, lend, process, compile, reverse engineer, combine
					with other software, translate, modify or create derivative works of any material that is subject to hellocare&apos;s
					proprietary rights, including hellocare&apos;s Intellectual Property, either by yourself or by anyone on your behalf, in
					any way or by any means, unless expressly permitted in the Terms.
					<br />
					<br /> You are hereby prohibited from removing or deleting any and all copyright notices, restrictions and signs
					indicating proprietary rights of hellocare or its licensors, including [®], copyright mark [©] or trademark [™]
					contained in or accompanying the Content included on the Site, and you represent and warrant that you will abide by all
					applicable laws in this respect.
				</p>
				<h3>10. Availability</h3>
				<p>
					Our Services availability and functionality depends on various factors, such as communication networks, internet service
					providers and others. hellocare does not warrant or guarantee that our Services will operate and/or be available at all
					times without disruption or interruption, or that it will be error-free.
				</p>
				<h3>11. Changes to our Services</h3>
				<p>
					hellocare reserves the right to modify, correct, amend, enhance, improve, make any other changes to, or discontinue,
					temporarily or permanently our Services (or any part thereof, including but not limited to the Content) without notice,
					at any time and at its sole discretion. In addition, you hereby acknowledge that the Content provided under our Services
					may be changed, extended in terms of content and form or removed at any time without any notice to you. You agree that
					hellocare shall not be liable to you or to any third party for any modification, suspension, or discontinuance of our
					Services or the Content included in our Services.
				</p>
				<h3>12. Disclaimer and Warranties</h3>
				<p>
					HELLOCARE DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE, THE INABILITY TO USE OR OPERATE, OR THE
					RESULTS OF THE USE OF THE CONTENT AVAILABLE ON OUR SERVICES AND/OR THE HELLO DEVICES.{' '}
					<span className='bold'>YOU AGREE AND ACKNOWLEDGE THAT THE USE OF OUR SERVICES IS ENTIRELY AT YOUR OWN RISK.</span>
					<br />
					<br /> YOU ASSUME FULL RESPONSIBILITY FOR ANY USE OF THE SERVICES NOT IN ACCORDANCE WITH HELLOCARE INSTRUCTIONS AND
					RECOMMENDATIONS.
					<br />
					<br /> HELLOCARE SHALL NOT BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT,
					SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND, WHETHER IT WAS CAUSED CONSEQUENTLY OR IN CONNECTION WITH THE
					USE OF THE SERVICES, OR ANY OTHER INFORMATION EMERGING FROM USING THE SERVICE, WHETHER OR NOT THE COMPANY HAD INFORMED
					THE USER OF SUCH POSSIBLE DAMAGE.
					<br />
					<br /> OUR SERVICES, INCLUDING ANY TECHNICAL SUPPORT SERVICES, ARE PROVIDED ON AN &quot;AS IS&quot; “AS AVAILABLE”
					BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE OR
					NON-INFRINGEMENT OR IMPLIED WARRANTIES OF USE, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR FUNCTION.
					HELLOCARE DISCLAIMS AND MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE ACCURACY, QUALITY, AVAILABILITY, RELIABILITY,
					SUITABILITY, COMPLETENESS, TRUTHFULNESS, USEFULNESS, OR EFFECTIVENESS OF ANY CONTENT AVAILABLE ON OUR SERVICES INCLUDING
					ANY MEDICAL INFORMATION. THE USE OF OUR SERVICES IS ENTIRELY AT YOUR OWN RISK AND HELLOCARE SHALL HAVE NO LIABILITY
					RELATING TO SUCH USE. HELLOCARE DISCLAIMS RESPONSIBILITY FOR ANY AND ALL MEDICAL INFORMATION UPLOADED OR COMMUNICATED
					THROUGH THE SERVICES BY USERS INCLUDING FOR ANY EXTERNAL MEDICAL INFORMATION.
					<br />
					<br /> HELLOCARE DOES NOT WARRANT THAT THE OPERATION OF OUR SERVICES IS OR WILL BE SECURE, ACCURATE, COMPLETE,
					UNINTERRUPTED, WITHOUT ERROR, OR FREE OF VIRUSES, WORMS, OTHER HARMFUL COMPONENTS, OR OTHER PROGRAM LIMITATIONS.
				</p>
				<h3>13. Limitation of Liability</h3>
				<p>
					IN NO EVENT SHALL HELLOCARE, INCLUDING ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, SUB-CONTRACTORS AND ITS AGENTS
					BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, SPECIAL, INCIDENTAL OR
					CONSEQUENTIAL DAMAGES OF ANY KIND, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTIONS RESULTING FROM
					OR ARISING OUT OF OUR SERVICES, OR THE USE OR INABILITY TO USE OUR SERVICES, THE CONTENT, LOSS OF GOODWILL OR PROFITS,
					THE PERFORMANCE OR FAILURE OF HELLOCARE TO PERFORM UNDER THESE TERMS, ANY OTHER ACT OR OMISSION OF HELLOCARE BY ANY
					OTHER CAUSE WHATSOEVER; OR BASED UPON BREACH OF WARRANTY, BREACH OF CONTRACT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER
					LEGAL THEORY, REGARDLESS OF WHHTS AS A CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW. BECAUSE SOME STATES OR
					JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN
					SUCH STATES OR JURISDICTIONS, OUR LIABILITY SHALL BE ETHER HELLOCARE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
					DAMAGES.
					<br />
					<br />
					IF PERMITTED BY LAW NO ACTION MAY BE BROUGHT BY YOU FOR ANY BREACH OF THESE TERMS, AND THAT YOU AGREE THAT THE ENTIRE
					RISK ARISING OUT OF YOUR USE OF THE SERVICES REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE
					LAW.”
					<br />
					<br />
					SUCH LIMITATIONS, EXCLUSIONS AND DISCLAIMERS SHALL APPLY TO ALL CLAIMS FOR DAMAGES, WHETHER BASED IN AN ACTION OF
					CONTRACT, WARRANTY, STRICT LIABILITY, NEGLIGENCE, TORT, OR OTHERWISE. YOU HEREBY ACKNOWLEDGE AND AGREE THAT THESE
					LIMITATIONS OF LIABILITY ARE AGREED ALLOCATIONS OF RISK CONSTITUTING IN PART THE CONSIDERATION FOR HELLOCARE’S SERVICES
					TO YOU, AND SUCH LIMITATIONS WILL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY, AND EVEN
					IF HELLOCARE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LIABILITIES AND/OR DAMAGES. THE FOREGOING LIMITATION OF
					LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. THE LIMITATIONS AND
					DISCLAIMER IN THIS SECTION DO NOT PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGLIMITED TO THE MINIMUM EXTENT PERMITTED BY
					LAW.
					<br />
					<br /> AND IN NO EVENT SHALL HELLOCARE’S CUMULATIVE LIABILITY TO YOU EXCEED AMOUNTS PAID TO HELLOCARE FOR YOUR USE OF
					THE SERVICE. IF YOU HAVE NOT MADE ANY PAYMENTS TO HELLOCARE FOR THE USE OF THE SERVICE OR IF NO PAYMENTS WERE MADE ON
					YOUR BEHALF, THEN HELLOCARE SHALL NOT HAVE ANY LIABILITY TOWARDS YOU.
				</p>
				<h3>14. Indemnification</h3>
				<p>
					You agree to defend, indemnify and hold harmless hellocare from and against any and all claims, damages, obligations,
					losses, liabilities, costs, debts, and expenses (including but not limited to attorney&apos;s fees) arising directly or
					indirectly from: (i) your use of our Services; (ii) your violation of any term of these Terms; (iii) any damage of any
					sort, whether direct, indirect, special or consequential, you may cause to any third party which relates to your use of
					our Services (including your violation of any third party rights); (iv) any claim for financial remedies or other
					compensations due to a decision that was taken or not taken or any action that was made or not made based on the
					Content, output or any other data resulting from the use of the Services; and (v) your violation of any applicable law
					or regulation.
				</p>
				<h3>15. Amendments to the Terms</h3>
				<p>
					hellocare may change the Terms from time to time, at its sole discretion and without any notice. We will notify you
					regarding substantial changes of these Terms on the homepage of the Site and/or the{' '}
					<span className='bold'>hellocare</span> App and/or we will send you an e-mail regarding such changes to the e-mail
					address that you provided during registration. Such substantial changes will take effect seven (7) days after such
					notice was provided on our website or sent via email. Otherwise, all other changes to these Terms are effective as of
					the stated “Last Revised” date and your continued use of our Services the Last Revised date will constitute acceptance
					of, and agreement to be bound by, those changes.
				</p>
				<h3>16. Termination of the Services</h3>
				<p>
					At any time, hellocare may block your access to our Services and/or temporarily or permanently limit your access to our
					Services, at its sole discretion, in addition to any other remedies that may be available to hellocare under any
					applicable law. Such actions by hellocare may be taken if hellocare deems that you have breached any of these Terms in
					any manner.
					<br />
					<br /> Additionally, hellocare may at any time, at its sole discretion, cease the operation of our Services or any part
					thereof, temporarily or permanently, without giving any prior notice. You agree and acknowledge that hellocare does not
					assume any responsibility with respect to, or in connection with the termination of our Services operation and loss of
					any data.
					<br />
					<br /> The provisions of Section 4 (User Representations and Undertakings), Section 5 (Use Restrictions), Section 6 (No
					Liability for Clinicians), Section 7 (Clinicians Undertakings and Representations), Section 8 (Privacy Policy, including
					the Privacy Policy referred to therein), Section 9 (Intellectual Property Rights), Section 10 (Availability), Section 12
					(Disclaimer and Warranties), Section 13 (Limitation of Liability), Section 14 (Indemnification), Section 16 (Termination
					of the Service) Section 17 (Minors), and Section 19 (General), shall survive the termination or expiration of these
					Terms.
				</p>
				<h3>17.1 Minors</h3>
				<p>
					To open an Account on our Services, you must be over the age of eighteen (18). We reserve the right to request proof of
					age at any stage so that we can verify that minors under the age of eighteen (18) are not using our Services.
					<br />
					<br /> If the Patient is under the age of eighteen (18), a parent or guardian may use the Service, and add and list the
					Patient under his or her Account, and provide Medical Information on behalf of his or her child. The parent or guardian
					assumes full responsibility for ensuring that the information that he or she provides to hellocare about his or her
					child is kept secure and that the information submitted is accurate.
					<br />
					<br /> For Clinicians: If the Patient is under the age of eighteen (18), you hereby declare that you have all of the
					required consents, approvals, and rights to provide health services to such Patient including the receipt, access,
					processing, transmitting, displaying and providing Medical Information on the minor’s behalf, as applicable, all in
					accordance with our Privacy Policy.
					<br />
					<br /> If You have added and listed a Patient under the age of 18 you hereby declare to hellocare that You are the
					parent or legal guardian of such Patient or that you are a Clinician having received all required consents, including
					from the Patient’s parents or legal guardians to open and use the hellocare Services on behalf of the Patient and are
					entitled under applicable law to do so.
					<br />
				</p>
				<h3>17.2 Adults without Mental Capacity</h3>
				<p>
					By definition capacity refers to one&apos;s legal ability to enter into an agreement. A person who lacks mental capacity
					cannot legally enter into a contract and is not allowed to register to our services without having the capacity to
					understand the consequences of such use. If someone without the required mental capacity shall register to our services,
					hellocare will not be liable in any way or form from such use. If the Patient lacks the mental capacity to enter into a
					binding legal agreement (proved with a Court Decision), a parent or guardian may use the Service, and add and list the
					Patient under his or her Account, and provide Medical Information on his or her behalf. The parent or guardian assumes
					full responsibility for ensuring that the information that he or she provides to hellocare about his or her dependant is
					kept secure and that the information submitted is accurate. For Clinicians: If the Patient lacks the mental capacity,
					you hereby declare that you have all of the required consents, approvals, and rights to provide health services to such
					Patient including the receipt, access, processing, transmitting, displaying and providing Medical Information on the
					dependents’ behalf, as applicable, all in accordance with our Privacy Policy. Shall an adult without the mental capacity
					register to our services, any damage done to hellocare or a third party shall be compensated by the Parent or Legal
					Guardian.
				</p>
				<h3>18. Platform Providers</h3>
				<p>
					Since you can download the <span className='bold'>hellocare</span> App from a third-party mobile application marketplace
					(e.g. App Store), a service provider or distributor (“App Providers”)<sub>[TS8]</sub> your use of the{' '}
					<span className='bold'>hellocare</span> App may also be subject to the rules of use of the App Providers in connection
					with your use of the <span className='bold'>hellocare</span> App (“Rules of Use”). Note that the Rules of Use may apply
					to you and it is your responsibility to determine whereas the Rules of Use govern your use of the{' '}
					<span className='bold'>hellocare</span> App. You agree to meet all the Rules of Use of the App Providers apply to you.
				</p>
				<h3>19. General</h3>
				<p>
					<ul>
						<li>
							Any heading, caption or section title contained herein is inserted only as a matter of convenience, and in no way
							defines or explains any section or provision hereof.
						</li>
						<li>
							These Terms do not, and shall not be construed to create any relationship, partnership, joint venture,
							employer-employee, agency, or franchisor-franchisee relationship between hellocare and you.
						</li>
						<li>
							Depending on your primary place of residency these Terms, their interpretation, and any claims and disputes related
							hereto, shall be governed by the following governing laws specified below, and any and all such claims and disputes
							shall be brought in, and you hereby consent to them being litigated in and decided exclusively by a court of
							competent jurisdiction located in the following locations:
						</li>
					</ul>
					<br />
					<br />
					a) If your primary residence is in the USA, EU or any other country in the world (with an exception for the Balkan
					Countries), the governing law and the exclusive jurisdiction shall be the laws of the state of Florida and the city of
					Tampa (respectively);
					<br />
					<br />
					b) if your primary residence is in the Balkans (south-eastern Europe), the governing law and the exclusive jurisdiction
					shall be the laws of the Republic of Kosovo and the city of Pristina (respectively).
					<br />
					<br />
					<ul>
						<li>
							hellocare may assign its rights and obligations hereunder and/or transfer ownership rights and title in the Service
							to a third party without your consent or prior notice to you. Your rights and obligations under the Terms are not
							assignable. Any attempted or actual assignment thereof without hellocare’s prior explicit and written consent will
							be null and void.
						</li>
						<li>
							If any provision of these Terms is found to be unlawful, void, or for any reason unenforceable, then that provision
							will be deemed severable from these Terms and will not affect the validity and enforceability of any remaining
							provisions.
						</li>
						<li>
							No waiver by either party of any breach or default hereunder will be deemed to be a waiver of any preceding or
							subsequent breach or default.
						</li>
					</ul>
				</p>
				<h3>20. Payment Authorization</h3>
				<p>
					By providing a credit card or other payment method accepted by hellocare (“Payment Method”), you are expressly agreeing
					that we are authorized to charge to the Payment Method any fees for your use of the Services, together with any
					applicable taxes. Please note that hellocare may not receive complete information from your health insurance plan, if
					applicable, regarding the applicable co-pay due from you for your consultation. As such, you may be billed more than
					once with respect to a Services to account for additional co-pay amounts due, if any. <br />
					<br /> You agree that authorizations to charge your Payment Method remain in effect until you cancel it in writing, and
					you agree to notify hellocare of any changes to your Payment Method. You certify that you are an authorized user of the
					Payment Method and will not dispute charges for the Services that correspond to consultation fees or the co-payment
					required by your health plan. You acknowledge that the origination of ACH transactions to your account must comply with
					applicable provisions of U.S. law. In the case of an ACH transaction rejected for insufficient funds, hellocare may at
					its discretion attempt to process the charge again at any time within 30 days. <br />
					<br /> You acknowledge and agree that fees for Services may change at any time.
				</p>
				<h3>21. Consent for Treatment Performed</h3>
				<p>
					We are providing this information on behalf of Providers:
					<br />
					<br />
					Telemedicine involves the use of communications to enable healthcare providers at sites remote from patients to provide
					consultative services. Providers may include primary care practitioners, specialists, and/or subspecialists, acute care,
					long-term care and others. The information may be used for diagnosis, therapy, follow-up and/or education, and may
					include live two-way audio and video and other materials (e.g., medical records, data from medical devices).
					<br />
					<br />
					The communications systems used will incorporate reasonable security protocols to protect the confidentiality of patient
					information and will include reasonable measures to safeguard the data and to ensure its integrity against intentional
					or unintentional corruption. At the end of each encounter, the patient will be provided an encounter summary, which may
					be kept for the patient’s records and may be shared with the patient’s local primary care or other provider, as
					appropriate.
					<br />
					<br />
					Anticipated Benefits of Telemedicine:
					<ul>
						<li>
							Improved access to medical care by enabling a patient to remain at his or her home or office while consulting a
							clinician.
						</li>
						<li>More efficient medical evaluation and management.</li>
						<li>Possible Risks of Telemedicine:</li>
					</ul>
					<br />
					As with any medical procedure, there are potential risks associated with the use of telemedicine. Provider believes that
					the likelihood of these risks materializing is very low. These risks may include, without limitation, the following:
					<br />
					<ul>
						<li>
							Delays in medical evaluation and consultation or treatment may occur due to deficiencies or failures of the
							equipment.
						</li>
						<li>Security protocols could fail, causing a breach of privacy of personal medical information.</li>
						<li>
							Lack of access to complete medical records may result in adverse drug interactions or allergic reactions or other
							negative outcomes.
						</li>
					</ul>
					<br />I understand that the laws that protect privacy and the confidentiality of healthcare information also apply to
					telemedicine; I have received from the provider rendering services the HIPAA Notice which explains these issues in
					greater detail.
					<br />
					<br />
					I understand that telemedicine may involve electronic communication of my personal medical information to medical
					practitioners who may be located in other areas, including out of state.
					<br />
					<br />
					I understand that I may expect the anticipated benefits from the use of telemedicine in my care, but that no results can
					be guaranteed or assured.
					<br />
					<br />I understand that my healthcare information may be shared with others (including health care providers and health
					insurers) for treatment, payment, and healthcare operations purposes.
					<br />
					<br />
					I further understand that my healthcare information may be shared in the following circumstances:
					<br />
					<br />
					When a valid court order is issued for medical records.
					<br />
					<br />
					Reporting suspected abuse, neglect, or domestic violence.
					<br />
					<br />
					Preventing or reducing a serious threat to anyone’s health or safety.
					<br />
					<br />
					Patient Consent to the Use of Telemedicine
					<br />
					<br />I have read and understand the information provided above, and understand the risks and benefits of telemedicine,
					and by accepting these Terms I hereby give my informed consent to participate in a telemedicine visit under the terms
					described herein.
				</p>
				<h3>22. EXTERNAL SERVICES</h3>
				<p>
					The Licensed Application may enable access to Licensor’s and/or third-party services and Websites (collectively and
					individually, “External Services”). You agree to use the External Services at your sole risk, always complying with
					applicable third-party terms of agreement. We are not responsible for examining or evaluating the content or accuracy of
					any third-party External Services, and shall not be liable for any such third-party External Services. Data displayed by
					any Licensed Application or External Service, including but not limited to financial, medical and location information,
					is for general informational purposes only and is not guaranteed by hellocare or its agents. You will not use the
					External Services in any manner that is inconsistent with the terms of this TOU or that infringes the intellectual
					property rights of TOU or any third party. You agree not to use the External Services to harass, abuse, stalk, threaten
					or defame any person or entity, and that hellocare is not responsible for any such case. External Services may not be
					available in all languages or in your Home Country, and may not be appropriate or available for use in any particular
					location. To the extent you choose to use such External Services, you are solely responsible for compliance with any
					applicable laws. hellocare reserves the right to change, suspend, remove, disable or impose access restrictions or
					limits on any External Services at any time without notice or liability to you.
					<br />
					<br />
					hellocare will use reasonable care and skill with respect to the provision of the Licensed Application and any External
					Services performed or provided by the Licensed Application to you. hellocare does not make any other promises or
					warranties about the External Services and in particular does not warrant that:
					<br />
					<br />
					(i) your use of the External Services will be uninterrupted or error-free;
					<br />
					<br />
					(ii) the External Services will be free from loss, corruption, attack, viruses, interference, hacking, or other security
					intrusion, and hellocare disclaims any liability relating thereto. You shall be responsible for backing up your own
					system, including any Licensed Application that is stored on your system.
				</p>
				<h3>23. Third-Party Services, Licences and Copyrights</h3>
				<p>
					Our Services includes software licensed under other open source software. We acknowledge all third party copyrights
					(“External Services”) where they have been used on this site and where we are aware of them being held under third party
					copyright.
				</p>
				<h3>24. For information, questions or notification of errors, please contact:</h3>
				<p>
					If you have any questions (or comments) concerning the Terms, you are most welcome to send hellocare an email to:
					support@hellocare.ai, and hellocare will make an effort to reply within a reasonable timeframe. By contacting the
					Company you warrant and agree that you are free to do so, and that you do not provide hellocare with information which
					violates any third party intellectual rights. Without degrading from the aforesaid, all rights, including intellectual
					property, arising from the communication will be owned by the Company and will be considered as hellocare’s confidential
					material.
					<br />
					<br />
					You should not share any Medical Information when communicating with hellocare’s support and customer service team.
					Medical Information should be shared with Clinicians, and the designated support and customer service teams are not
					healthcare professionals and cannot offer medical advice.
				</p>
			</div>
			<div className='footer-lp lp-container' id='about-us'>
				<div className='upper-part-lp'>
					<div className='logo-lp'>
						<Link to='/'>
							<img src={`${healthCareCdnUrl}header/bluelogo-1.svg`} alt='hellocare' />
						</Link>
						<p>
							hellocare powered by Solaborate is an End-to-End telehealth and virtual healthcare delivery platform comprising
							software, hardware and AI. hellocare platform provides solutions Hospitals, Home Care and Primary Care such as
							Virtual Patient Observation/eSitter, Telehealth, Remote Patient Monitoring (RPM), Remote Examination Kit,
							Telemedicine Carts, Alerting, Communications and Workflow Management and AI use cases for fall prevention and
							detections.
						</p>
					</div>
					<div className='links-lp'>
						<div className='about-us-lp'>
							<ul>
								<li>
									<p>
										<span className='bold'>Solaborate Inc</span>
									</p>
									<p>
										<span className='bold'>Address:</span> 2605 Enterprise RD E Suite 150 Clearwater, FL 33759{' '}
									</p>
									<p>
										<span className='bold'>Phone Number:</span> +1 424-362-9965
									</p>
									<p>
										<span className='bold'>Email:</span> support@hellocare.ai
									</p>
									<Link to='/privacy-policy' target='_blank'>
										<p>
											<span className='bold'>Privacy Policy</span>
										</p>
									</Link>
									<Link to='/terms-of-use' target='_blank'>
										<p>
											<span className='bold'>Terms of Use</span>
										</p>
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className='bottom-part-lp'>
					<h1>
						© <span className='bold'>hellocare</span> - Built by{' '}
						<a href='https://www.hellocare.ai' target='_blank' rel='noopener noreferrer' className='blue-lp'>
							Solaborate
						</a>{' '}
						{new Date().getFullYear()} | All Rights Reserved
					</h1>
				</div>
			</div>
		</div>
	</div>
);

export default TermsOfUse;
