import React, { useState, useEffect } from 'react';
import { getPatientSurvey } from 'api/rpm.js';
import { Alert, EmptyState, Form, Modal } from 'components';
import translate from 'i18n-translations/translate.jsx';
import { getLettersBasedIndex } from 'infrastructure/helpers/commonHelpers.js';

const Survey = props => {
	const [patientSurvey, setPatientSurvey] = useState([]);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const getSurveyPerPatient = async () => {
			const response = await getPatientSurvey({
				patientId: props.selectedPatient?.patientId,
				id: props.selectedPatient?.rpmProgram?.id,
			});
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			const mappedResponse = response.answers.reduce((acc, { question, option }) => {
				const { id, text } = question;
				const foundQuestion = acc.find(quest => quest.id === id);
				if (foundQuestion) {
					foundQuestion.option.push(option.text);
				} else {
					acc.push({
						id,
						text,
						option: [option.text],
					});
				}
				return acc;
			}, []);

			setPatientSurvey(mappedResponse);
			setError(null);
			setIsLoading(false);
		};

		getSurveyPerPatient();
	}, [props.selectedPatient]);

	return (
		<>
			<Modal
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal vsm-alerts-modal survey-modal'
				position='center'
				display={true}
				isLoading={isLoading}
				onModalClose={() => props.setShouldShowSurvey(false)}>
				<Form className='modal-form-wrapper survey-form-wrapper'>
					{patientSurvey.length === 0 && !isLoading && <EmptyState title={translate('noResultsFound')} image='Notes.svg' />}
					{patientSurvey.length > 0 && !isLoading && (
						<div>
							<p className='semi-bold'>{translate('survey')}</p>
							{patientSurvey.map((item, index) => (
								<div className='top-15'>
									<div key={item} className='flex column-direction questions-wrapper'>
										<p className='--light-gray-color no-margin font-16'>
											{translate('question')} {index + 1} {translate('of')} {patientSurvey.length}
										</p>
										<p className='semi-bold no-margin font-16'>{item.text}</p>
										<div className='flex column-direction position-relative'>
											{item.option.map((option, optionIndex) => (
												<p className='--blue-light-5 font-15 no-margin'>{`${getLettersBasedIndex(optionIndex)}) ${option}`}</p>
											))}
										</div>
									</div>
								</div>
							))}
						</div>
					)}
				</Form>
			</Modal>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default Survey;
