// @ts-nocheck
const cities = [
	{
		city: 'Qal eh-ye Now',
		city_ascii: 'Qal eh-ye',
		lat: 34.98300013,
		lng: 63.13329964,
		pop: 2997,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Badghis',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Chaghcharan',
		city_ascii: 'Chaghcharan',
		lat: 34.5167011,
		lng: 65.25000063,
		pop: 15000,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Ghor',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Lashkar Gah',
		city_ascii: 'Lashkar Gah',
		lat: 31.58299802,
		lng: 64.35999955,
		pop: 201546,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Hilmand',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Zaranj',
		city_ascii: 'Zaranj',
		lat: 31.11200108,
		lng: 61.88699752,
		pop: 49851,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Nimroz',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Tarin Kowt',
		city_ascii: 'Tarin Kowt',
		lat: 32.63329815,
		lng: 65.86669865,
		pop: 10000,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Uruzgan',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Zareh Sharan',
		city_ascii: 'Zareh Sharan',
		lat: 32.85000016,
		lng: 68.41670453,
		pop: 13737,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Paktika',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Asadabad',
		city_ascii: 'Asadabad',
		lat: 34.86600004,
		lng: 71.15000459,
		pop: 48400,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Kunar',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Taloqan',
		city_ascii: 'Taloqan',
		lat: 36.72999904,
		lng: 69.54000364,
		pop: 64256,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Takhar',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Mahmud-E Eraqi',
		city_ascii: 'Mahmud-E Eraqi',
		lat: 35.01669608,
		lng: 69.33330065,
		pop: 7407,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Kapisa',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Mehtar Lam',
		city_ascii: 'Mehtar Lam',
		lat: 34.65000001,
		lng: 70.16670052,
		pop: 17345,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Laghman',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Baraki Barak',
		city_ascii: 'Baraki Barak',
		lat: 33.9667021,
		lng: 68.96670354,
		pop: 22305,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Logar',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Aybak',
		city_ascii: 'Aybak',
		lat: 36.26100015,
		lng: 68.04000051,
		pop: 24000,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Samangan',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Mayda Shahr',
		city_ascii: 'Mayda Shahr',
		lat: 34.45000209,
		lng: 68.79999663,
		pop: 35008,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Wardak',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Karokh',
		city_ascii: 'Karokh',
		lat: 34.48676963,
		lng: 62.59177608,
		pop: 14388.5,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Hirat',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Sheberghan',
		city_ascii: 'Sheberghan',
		lat: 36.65798077,
		lng: 65.73830237,
		pop: 74441,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Jawzjan',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Pol-e Khomri',
		city_ascii: 'Pol-e Khomri',
		lat: 35.95107302,
		lng: 68.70111894,
		pop: 41029,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Baghlan',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Balkh',
		city_ascii: 'Balkh',
		lat: 36.75011985,
		lng: 66.89973018,
		pop: 147426,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Balkh',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Meymaneh',
		city_ascii: 'Meymaneh',
		lat: 35.93022158,
		lng: 64.77009273,
		pop: 199795,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Faryab',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Andkhvoy',
		city_ascii: 'Andkhvoy',
		lat: 36.93165916,
		lng: 65.10149369,
		pop: 50469,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Faryab',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Qalat',
		city_ascii: 'Qalat',
		lat: 32.11226341,
		lng: 66.8867594,
		pop: 12191,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Zabul',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Ghazni',
		city_ascii: 'Ghazni',
		lat: 33.56331179,
		lng: 68.41782873,
		pop: 129892.5,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Ghazni',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Feyzabad',
		city_ascii: 'Feyzabad',
		lat: 37.12976076,
		lng: 70.57924719,
		pop: 52490,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Badakhshan',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Kondoz',
		city_ascii: 'Kondoz',
		lat: 36.72795066,
		lng: 68.87252966,
		pop: 210855.5,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Kunduz',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Jalalabad',
		city_ascii: 'Jalalabad',
		lat: 34.44152692,
		lng: 70.43610347,
		pop: 401697,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Nangarhar',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Charikar',
		city_ascii: 'Charikar',
		lat: 35.01826174,
		lng: 69.16791215,
		pop: 53676,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Parwan',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Gardiz',
		city_ascii: 'Gardiz',
		lat: 33.60005373,
		lng: 69.21462764,
		pop: 82680.5,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Paktya',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Bamian',
		city_ascii: 'Bamian',
		lat: 34.82106447,
		lng: 67.52103593,
		pop: 61863,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Bamyan',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Baghlan',
		city_ascii: 'Baghlan',
		lat: 36.13933026,
		lng: 68.69925858,
		pop: 163598.5,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Baghlan',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Farah',
		city_ascii: 'Farah',
		lat: 32.39172955,
		lng: 62.09681921,
		pop: 58604,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Farah',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Herat',
		city_ascii: 'Herat',
		lat: 34.33000917,
		lng: 62.16999304,
		pop: 439232.5,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Hirat',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Mazar-e Sharif',
		city_ascii: 'Mazar-e Sharif',
		lat: 36.69999371,
		lng: 67.10002803,
		pop: 365432.5,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Balkh',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Kandahar',
		city_ascii: 'Kandahar',
		lat: 31.61002016,
		lng: 65.69494584,
		pop: 613871,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Kandahar',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Kabul',
		city_ascii: 'Kabul',
		lat: 34.51669029,
		lng: 69.18326005,
		pop: 3160266,
		country: 'Afghanistan',
		iso2: 'AF',
		iso3: 'AFG',
		province: 'Kabul',
		timezone: 'Asia/Kabul',
	},
	{
		city: 'Mariehamn',
		city_ascii: 'Mariehamn',
		lat: 60.09699618,
		lng: 19.94900447,
		pop: 10682,
		country: 'Aland',
		iso2: 'AX',
		iso3: 'ALD',
		province: 'Finström',
		timezone: 'Europe/Mariehamn',
	},
	{
		city: 'Kruje',
		city_ascii: 'Kruje',
		lat: 41.51899817,
		lng: 19.79700359,
		pop: 21286,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Durrës',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Fier',
		city_ascii: 'Fier',
		lat: 40.73000402,
		lng: 19.5730026,
		pop: 69747.5,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Fier',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Lushnje',
		city_ascii: 'Lushnje',
		lat: 40.94000113,
		lng: 19.71600348,
		pop: 41469,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Fier',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Puke',
		city_ascii: 'Puke',
		lat: 42.03330302,
		lng: 19.88330448,
		pop: 6495,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Shkodër',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Bajram Curri',
		city_ascii: 'Bajram Curri',
		lat: 42.33330007,
		lng: 20.08330257,
		pop: 7967,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Kukës',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Kukes',
		city_ascii: 'Kukes',
		lat: 42.08300107,
		lng: 20.43399653,
		pop: 17832,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Kukës',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Sarande',
		city_ascii: 'Sarande',
		lat: 39.87700212,
		lng: 19.99999859,
		pop: 15147,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Vlorë',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Erseke',
		city_ascii: 'Erseke',
		lat: 40.33330213,
		lng: 20.68329651,
		pop: 7890,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Korçë',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Pogradec',
		city_ascii: 'Pogradec',
		lat: 40.89999612,
		lng: 20.66400062,
		pop: 35000,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Korçë',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Korce',
		city_ascii: 'Korce',
		lat: 40.61667601,
		lng: 20.76666353,
		pop: 58259,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Korçë',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Berat',
		city_ascii: 'Berat',
		lat: 40.70999705,
		lng: 19.97199958,
		pop: 46866,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Berat',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Corovode',
		city_ascii: 'Corovode',
		lat: 40.51670403,
		lng: 20.23329663,
		pop: 14046,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Berat',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Gramsh',
		city_ascii: 'Gramsh',
		lat: 40.86669601,
		lng: 20.19999652,
		pop: 11556,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Elbasan',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Librazhd',
		city_ascii: 'Librazhd',
		lat: 41.20000211,
		lng: 20.3667036,
		pop: 12691,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Elbasan',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Tepelene',
		city_ascii: 'Tepelene',
		lat: 40.2832981,
		lng: 20.03329854,
		pop: 11955,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Gjirokastër',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Permet',
		city_ascii: 'Permet',
		lat: 40.23399605,
		lng: 20.35199756,
		pop: 10686,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Gjirokastër',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Gjirokaster',
		city_ascii: 'Gjirokaster',
		lat: 40.07899809,
		lng: 20.14900256,
		pop: 23437,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Gjirokastër',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Peshkopi',
		city_ascii: 'Peshkopi',
		lat: 41.6833021,
		lng: 20.43330349,
		pop: 14848,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Dibër',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Burrel',
		city_ascii: 'Burrel',
		lat: 41.62599908,
		lng: 20.01600053,
		pop: 15405,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Dibër',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Lezhe',
		city_ascii: 'Lezhe',
		lat: 41.78799914,
		lng: 19.65400254,
		pop: 18695,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Lezhë',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Rreshen',
		city_ascii: 'Rreshen',
		lat: 41.78330106,
		lng: 19.81670459,
		pop: 10064,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Lezhë',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Vlore',
		city_ascii: 'Vlore',
		lat: 40.47736005,
		lng: 19.49823075,
		pop: 89508.5,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Vlorë',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Elbasan',
		city_ascii: 'Elbasan',
		lat: 41.12150677,
		lng: 20.08382808,
		pop: 132956.5,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Elbasan',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Durres',
		city_ascii: 'Durres',
		lat: 41.3177997,
		lng: 19.44820797,
		pop: 132233,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Durrës',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Shkoder',
		city_ascii: 'Shkoder',
		lat: 42.06845156,
		lng: 19.51884965,
		pop: 122006,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Shkodër',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Tirana',
		city_ascii: 'Tirana',
		lat: 41.32754071,
		lng: 19.81888301,
		pop: 658318,
		country: 'Albania',
		iso2: 'AL',
		iso3: 'ALB',
		province: 'Durrës',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Jijel',
		city_ascii: 'Jijel',
		lat: 36.82199703,
		lng: 5.76600356,
		pop: 148000,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Jijel',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Tizi-Ouzou',
		city_ascii: 'Tizi-Ouzou',
		lat: 36.80000111,
		lng: 4.033332556,
		pop: 144000,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Tizi Ouzou',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Bordj Bou Arreridj',
		city_ascii: 'Bordj Bou Arreridj',
		lat: 36.05900401,
		lng: 4.629996466,
		pop: 134500,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Bordj Bou Arréridj',
		timezone: 'Africa/Algiers',
	},
	{
		city: "M'sila",
		city_ascii: "M'sila",
		lat: 35.7000031,
		lng: 4.545000584,
		pop: 125000,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: "M'Sila",
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Guelma',
		city_ascii: 'Guelma',
		lat: 36.46600213,
		lng: 7.427997486,
		pop: 123590,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Guelma',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Oum el Bouaghi',
		city_ascii: 'Oum el Bouaghi',
		lat: 35.84999715,
		lng: 7.149996522,
		pop: 100821,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Oum el Bouaghi',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Timimoun',
		city_ascii: 'Timimoun',
		lat: 29.23652163,
		lng: 0.269998737,
		pop: 26568,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Adrar',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Sidi bel Abbes',
		city_ascii: 'Sidi bel Abbes',
		lat: 35.19034426,
		lng: -0.639971559,
		pop: 200186.5,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Sidi Bel Abbès',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Tlimcen',
		city_ascii: 'Tlimcen',
		lat: 34.89041424,
		lng: -1.32000757,
		pop: 181059,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Tlemcen',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Beni Ounif',
		city_ascii: 'Beni Ounif',
		lat: 32.04926984,
		lng: -1.251381268,
		pop: 5628,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Béchar',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Abadla',
		city_ascii: 'Abadla',
		lat: 31.01708478,
		lng: -2.733306317,
		pop: 14364,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Béchar',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Sefra',
		city_ascii: 'Sefra',
		lat: 32.76041506,
		lng: -0.579949383,
		pop: 51118,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Naâma',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Skikda',
		city_ascii: 'Skikda',
		lat: 36.88042198,
		lng: 6.899981647,
		pop: 193941.5,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Skikda',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Djanet',
		city_ascii: 'Djanet',
		lat: 24.5529057,
		lng: 9.482252969,
		pop: 666,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Illizi',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'I-n-Amenas',
		city_ascii: 'I-n-Amenas',
		lat: 28.0503408,
		lng: 9.550000772,
		pop: 216,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Illizi',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'In Amguel',
		city_ascii: 'In Amguel',
		lat: 23.69394004,
		lng: 5.164738727,
		pop: 3030,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Tamanghasset',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'El Bayadh',
		city_ascii: 'El Bayadh',
		lat: 33.6903583,
		lng: 1.009953571,
		pop: 67413,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'El Bayadh',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'El Oued',
		city_ascii: 'El Oued',
		lat: 33.37040367,
		lng: 6.859984089,
		pop: 177497,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Biskra',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Hassi Messaoud',
		city_ascii: 'Hassi Messaoud',
		lat: 31.70234011,
		lng: 6.054451862,
		pop: 18124,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Ouargla',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Chlef',
		city_ascii: 'Chlef',
		lat: 36.17041363,
		lng: 1.319960489,
		pop: 449167,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Chlef',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Mascara',
		city_ascii: 'Mascara',
		lat: 35.40040895,
		lng: 0.14003251,
		pop: 108230,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Mascara',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Mostaganem',
		city_ascii: 'Mostaganem',
		lat: 35.940376,
		lng: 0.089983885,
		pop: 159177,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Mostaganem',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Saida',
		city_ascii: 'Saida',
		lat: 34.84039146,
		lng: 0.14003251,
		pop: 134855,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Saïda',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Tiarat',
		city_ascii: 'Tiarat',
		lat: 35.38043601,
		lng: 1.319960489,
		pop: 184195,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Tiaret',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Bejaia',
		city_ascii: 'Bejaia',
		lat: 36.76037762,
		lng: 5.070015827,
		pop: 274520,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Béjaïa',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Blida',
		city_ascii: 'Blida',
		lat: 36.4203467,
		lng: 2.829997517,
		pop: 388174,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Blida',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Bouira',
		city_ascii: 'Bouira',
		lat: 36.38047833,
		lng: 3.900009724,
		pop: 110144,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Bouira',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Medea',
		city_ascii: 'Medea',
		lat: 36.27040753,
		lng: 2.770001179,
		pop: 145863.5,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Médéa',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Souk Ahras',
		city_ascii: 'Souk Ahras',
		lat: 36.29038047,
		lng: 7.949995075,
		pop: 134947,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Souk Ahras',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Tebessa',
		city_ascii: 'Tebessa',
		lat: 35.41043418,
		lng: 8.120010537,
		pop: 171742,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Tébessa',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Adrar',
		city_ascii: 'Adrar',
		lat: 27.86999005,
		lng: -0.289967083,
		pop: 56910,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Adrar',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Reggane',
		city_ascii: 'Reggane',
		lat: 26.69998395,
		lng: 0.166645873,
		pop: 22351.5,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Adrar',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Bechar',
		city_ascii: 'Bechar',
		lat: 31.61110537,
		lng: -2.230003704,
		pop: 142415.5,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Béchar',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Tindouf',
		city_ascii: 'Tindouf',
		lat: 27.67418805,
		lng: -8.147782025,
		pop: 18270,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Tindouf',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Illizi',
		city_ascii: 'Illizi',
		lat: 26.48335634,
		lng: 8.466604369,
		pop: 7293,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Illizi',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Arak',
		city_ascii: 'Arak',
		lat: 25.2799931,
		lng: 3.749993041,
		pop: 423251,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Tamanghasset',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'I-n-Salah',
		city_ascii: 'I-n-Salah',
		lat: 27.21664492,
		lng: 2.466608845,
		pop: 28632,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Tamanghasset',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'El Golea',
		city_ascii: 'El Golea',
		lat: 30.56662132,
		lng: 2.883327595,
		pop: 32049,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Ghardaïa',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Laghouat',
		city_ascii: 'Laghouat',
		lat: 33.80998924,
		lng: 2.880020303,
		pop: 108279,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Laghouat',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Touggourt',
		city_ascii: 'Touggourt',
		lat: 33.0999809,
		lng: 6.05998124,
		pop: 91499,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Ouargla',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Ouargla',
		city_ascii: 'Ouargla',
		lat: 31.96997235,
		lng: 5.340025186,
		pop: 176271,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Ouargla',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Biskra',
		city_ascii: 'Biskra',
		lat: 34.85997683,
		lng: 5.73002722,
		pop: 202103,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Biskra',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Djelfa',
		city_ascii: 'Djelfa',
		lat: 34.67998781,
		lng: 3.250023558,
		pop: 170901,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Djelfa',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Setif',
		city_ascii: 'Setif',
		lat: 36.18002545,
		lng: 5.399969847,
		pop: 274744,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Sétif',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Batna',
		city_ascii: 'Batna',
		lat: 35.56995933,
		lng: 6.170000365,
		pop: 269467,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Batna',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Annaba',
		city_ascii: 'Annaba',
		lat: 36.92000612,
		lng: 7.759980834,
		pop: 355047,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Annaba',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Constantine',
		city_ascii: 'Constantine',
		lat: 36.35998863,
		lng: 6.599948281,
		pop: 527638,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Constantine',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Oran',
		city_ascii: 'Oran',
		lat: 35.71000246,
		lng: -0.61997278,
		pop: 721992,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Oran',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Tamanrasset',
		city_ascii: 'Tamanrasset',
		lat: 22.78500327,
		lng: 5.522804727,
		pop: 71808,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Tamanghasset',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Ghardaia',
		city_ascii: 'Ghardaia',
		lat: 32.48999229,
		lng: 3.669997923,
		pop: 125480,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Ghardaïa',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Algiers',
		city_ascii: 'Algiers',
		lat: 36.7630648,
		lng: 3.05055253,
		pop: 2665831.5,
		country: 'Algeria',
		iso2: 'DZ',
		iso3: 'DZA',
		province: 'Alger',
		timezone: 'Africa/Algiers',
	},
	{
		city: 'Pago Pago',
		city_ascii: 'Pago Pago',
		lat: -14.2766105,
		lng: -170.7066451,
		pop: 12038,
		country: 'American Samoa',
		iso2: 'AS',
		iso3: 'ASM',
		province: '',
		timezone: 'Pacific/Pago_Pago',
	},
	{
		city: 'Andorra',
		city_ascii: 'Andorra',
		lat: 42.50000144,
		lng: 1.516485961,
		pop: 38127,
		country: 'Andorra',
		iso2: 'AD',
		iso3: 'AND',
		province: '',
		timezone: 'Europe/Andorra',
	},
	{
		city: 'Mucusso',
		city_ascii: 'Mucusso',
		lat: -18.01953449,
		lng: 21.42999914,
		pop: 100,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cuando Cubango',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Lucapa',
		city_ascii: 'Lucapa',
		lat: -8.419603659,
		lng: 20.74001542,
		pop: 25578,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Lunda Norte',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Capenda-Camulemba',
		city_ascii: 'Capenda-Camulemba',
		lat: -9.4195943,
		lng: 18.43002722,
		pop: 79842.5,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Lunda Norte',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Saurimo',
		city_ascii: 'Saurimo',
		lat: -9.659579652,
		lng: 20.39001094,
		pop: 40907,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Lunda Sul',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Muconda',
		city_ascii: 'Muconda',
		lat: -10.59962563,
		lng: 21.31998002,
		pop: 2324,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Lunda Sul',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Cacolo',
		city_ascii: 'Cacolo',
		lat: -10.14962726,
		lng: 19.2600024,
		pop: 984,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Lunda Sul',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Caxito',
		city_ascii: 'Caxito',
		lat: -8.579542217,
		lng: 13.65998246,
		pop: 15665.5,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Bengo',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Camabatela',
		city_ascii: 'Camabatela',
		lat: -8.189591859,
		lng: 15.37000728,
		pop: 12731,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cuanza Norte',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Ndalatando',
		city_ascii: 'Ndalatando',
		lat: -9.299549948,
		lng: 14.90998368,
		pop: 8144,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cuanza Norte',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Quibala',
		city_ascii: 'Quibala',
		lat: -10.72959186,
		lng: 14.97995357,
		pop: 5248.5,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cuanza Sul',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Calulo',
		city_ascii: 'Calulo',
		lat: -9.999610577,
		lng: 14.90001013,
		pop: 795,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cuanza Sul',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Waku Kungo',
		city_ascii: 'Waku Kungo',
		lat: -11.35952757,
		lng: 15.1199967,
		pop: 11519.5,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cuanza Sul',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Songo',
		city_ascii: 'Songo',
		lat: -7.349591452,
		lng: 14.84998734,
		pop: 10579,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Uíge',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Mbanza-Congo',
		city_ascii: 'Mbanza-Congo',
		lat: -6.269605694,
		lng: 14.23999874,
		pop: 42201,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Zaire',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Nzeto',
		city_ascii: 'Nzeto',
		lat: -7.229598776,
		lng: 12.86003129,
		pop: 19705.5,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Zaire',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Soyo',
		city_ascii: 'Soyo',
		lat: -6.129614239,
		lng: 12.36998368,
		pop: 65329,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Zaire',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Cabinda',
		city_ascii: 'Cabinda',
		lat: -5.55962319,
		lng: 12.18999467,
		pop: 78905.5,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cabinda',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Calucinga',
		city_ascii: 'Calucinga',
		lat: -11.31958169,
		lng: 16.19998246,
		pop: 531,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Bié',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Camacupa',
		city_ascii: 'Camacupa',
		lat: -12.01959064,
		lng: 17.46998246,
		pop: 19489,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Bié',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Cubal',
		city_ascii: 'Cubal',
		lat: -13.03958006,
		lng: 14.23999874,
		pop: 4837,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Benguela',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Mavinga',
		city_ascii: 'Mavinga',
		lat: -15.7895414,
		lng: 20.36003861,
		pop: 30000,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cuando Cubango',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Cuito Caunavale',
		city_ascii: 'Cuito Caunavale',
		lat: -15.15960569,
		lng: 19.16998205,
		pop: 149,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cuando Cubango',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Luiana',
		city_ascii: 'Luiana',
		lat: -17.36954832,
		lng: 22.99998083,
		pop: 150,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cuando Cubango',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Ondjiva',
		city_ascii: 'Ondjiva',
		lat: -17.06961831,
		lng: 15.73003699,
		pop: 8748,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cunene',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Chitado',
		city_ascii: 'Chitado',
		lat: -17.31962889,
		lng: 13.92001827,
		pop: 468.5,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cunene',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Chibemba',
		city_ascii: 'Chibemba',
		lat: -15.74959552,
		lng: 14.0800085,
		pop: 1502,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Huíla',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Chibia',
		city_ascii: 'Chibia',
		lat: -15.1896297,
		lng: 13.69000647,
		pop: 1411,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Huíla',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Quipungo',
		city_ascii: 'Quipungo',
		lat: -14.82954832,
		lng: 14.55000565,
		pop: 186,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Huíla',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Luau',
		city_ascii: 'Luau',
		lat: -10.7095414,
		lng: 22.23000199,
		pop: 9964,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Moxico',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Cangamba',
		city_ascii: 'Cangamba',
		lat: -13.69959145,
		lng: 19.86001745,
		pop: 1307,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Moxico',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Lumbala Nguimbo',
		city_ascii: 'Lumbala Nguimbo',
		lat: -14.09961871,
		lng: 21.44002437,
		pop: 25,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Moxico',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Cazombo',
		city_ascii: 'Cazombo',
		lat: -11.88957273,
		lng: 22.90003861,
		pop: 298,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Moxico',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Dundo',
		city_ascii: 'Dundo',
		lat: -7.380028871,
		lng: 20.82998409,
		pop: 11985,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Lunda Norte',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Ambriz',
		city_ascii: 'Ambriz',
		lat: -7.85498696,
		lng: 13.12502803,
		pop: 17000,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Bengo',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Dondo',
		city_ascii: 'Dondo',
		lat: -9.690017071,
		lng: 14.43001298,
		pop: 2353,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cuanza Norte',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Sumbe',
		city_ascii: 'Sumbe',
		lat: -11.21002765,
		lng: 13.8499967,
		pop: 29638.5,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cuanza Sul',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Uige',
		city_ascii: 'Uige',
		lat: -7.620014222,
		lng: 15.04997514,
		pop: 56787.5,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Uíge',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Kuito',
		city_ascii: 'Kuito',
		lat: -12.38003375,
		lng: 16.93998897,
		pop: 113955,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Bié',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Lobito',
		city_ascii: 'Lobito',
		lat: -12.37000853,
		lng: 13.54123002,
		pop: 170733,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Benguela',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Xangongo',
		city_ascii: 'Xangongo',
		lat: -16.74002602,
		lng: 14.96998002,
		pop: 447,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cunene',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Luena',
		city_ascii: 'Luena',
		lat: -11.79004393,
		lng: 19.90001501,
		pop: 17276.5,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Moxico',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Tômbua',
		city_ascii: 'Tombua',
		lat: -15.80003172,
		lng: 11.85998897,
		pop: 40000,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Namibe',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Malanje',
		city_ascii: 'Malanje',
		lat: -9.540000388,
		lng: 16.34002559,
		pop: 106451,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Malanje',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Benguela',
		city_ascii: 'Benguela',
		lat: -12.57826455,
		lng: 13.40723303,
		pop: 142017,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Benguela',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Lubango',
		city_ascii: 'Lubango',
		lat: -14.91000853,
		lng: 13.49001868,
		pop: 114086.5,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Huíla',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Namibe',
		city_ascii: 'Namibe',
		lat: -15.19004311,
		lng: 12.16002234,
		pop: 128130.5,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Namibe',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Menongue',
		city_ascii: 'Menongue',
		lat: -14.66661253,
		lng: 17.69999426,
		pop: 13030,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Cuando Cubango',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Huambo',
		city_ascii: 'Huambo',
		lat: -12.74998533,
		lng: 15.76000932,
		pop: 986000,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Huambo',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Luanda',
		city_ascii: 'Luanda',
		lat: -8.838286114,
		lng: 13.23442704,
		pop: 3562086,
		country: 'Angola',
		iso2: 'AO',
		iso3: 'AGO',
		province: 'Luanda',
		timezone: 'Africa/Luanda',
	},

	{
		city: "Saint John's",
		city_ascii: "Saint John's",
		lat: 17.11803652,
		lng: -61.85003382,
		pop: 29862.5,
		country: 'Antigua and Barbuda',
		iso2: 'AG',
		iso3: 'ATG',
		province: '',
		timezone: 'America/Antigua',
	},
	{
		city: '28 de Noviembre',
		city_ascii: '28 de Noviembre',
		lat: -51.65003986,
		lng: -72.30001612,
		pop: 5300,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Cruz',
		timezone: 'America/Santiago',
	},
	{
		city: 'Gobernador Gregores',
		city_ascii: 'Gobernador Gregores',
		lat: -48.76659829,
		lng: -70.25001205,
		pop: 2519,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Cruz',
		timezone: 'America/Argentina/Rio_Gallegos',
	},
	{
		city: 'Comondante Luis Piedrabuena',
		city_ascii: 'Comondante Luis Piedrabuena',
		lat: -49.97454865,
		lng: -68.90347273,
		pop: 410,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Cruz',
		timezone: 'America/Argentina/Rio_Gallegos',
	},
	{
		city: 'Paso Rio Mayo',
		city_ascii: 'Paso Rio Mayo',
		lat: -45.68337563,
		lng: -70.26657434,
		pop: 1825,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chubut',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'Alto Rio Sanguer',
		city_ascii: 'Alto Rio Sanguer',
		lat: -45.03328611,
		lng: -70.83328394,
		pop: 1548,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chubut',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'El Maiten',
		city_ascii: 'El Maiten',
		lat: -42.05000568,
		lng: -71.16662276,
		pop: 4269,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chubut',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'Puerto Madryn',
		city_ascii: 'Puerto Madryn',
		lat: -42.77001341,
		lng: -65.04001998,
		pop: 61159,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chubut',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'Trelew',
		city_ascii: 'Trelew',
		lat: -43.25003579,
		lng: -65.3299506,
		pop: 93128.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chubut',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'Las Heras',
		city_ascii: 'Las Heras',
		lat: -32.82503904,
		lng: -68.80167668,
		pop: 66663,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Mendoza',
		timezone: 'America/Argentina/Mendoza',
	},
	{
		city: 'San Martin',
		city_ascii: 'San Martin',
		lat: -33.06998533,
		lng: -68.49001612,
		pop: 99974,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Mendoza',
		timezone: 'America/Argentina/Mendoza',
	},
	{
		city: 'Uspallata',
		city_ascii: 'Uspallata',
		lat: -32.59311522,
		lng: -69.34598454,
		pop: 2390,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Mendoza',
		timezone: 'America/Argentina/Mendoza',
	},
	{
		city: 'Cutral Co',
		city_ascii: 'Cutral Co',
		lat: -38.94001463,
		lng: -69.24002202,
		pop: 47597,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Neuquén',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Punta Alta',
		city_ascii: 'Punta Alta',
		lat: -38.87996662,
		lng: -62.0799681,
		pop: 55969.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'San Nicolas',
		city_ascii: 'San Nicolas',
		lat: -33.33002114,
		lng: -60.24000289,
		pop: 117123.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Campana',
		city_ascii: 'Campana',
		lat: -34.15999632,
		lng: -58.95997766,
		pop: 77149.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Chacabuco',
		city_ascii: 'Chacabuco',
		lat: -34.65004393,
		lng: -60.48998763,
		pop: 26645,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Mercedes',
		city_ascii: 'Mercedes',
		lat: -34.66001748,
		lng: -59.44002588,
		pop: 48408.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Lincoln',
		city_ascii: 'Lincoln',
		lat: -34.88000405,
		lng: -61.53994938,
		pop: 19924,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Chivilcoy',
		city_ascii: 'Chivilcoy',
		lat: -34.89995115,
		lng: -60.03998926,
		pop: 43719,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Veinticinco de Mayo',
		city_ascii: 'Veinticinco de Mayo',
		lat: -35.42999632,
		lng: -60.17998071,
		pop: 18749,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Nueve de Julio',
		city_ascii: 'Nueve de Julio',
		lat: -35.44596434,
		lng: -60.88998906,
		pop: 26716,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Dolores',
		city_ascii: 'Dolores',
		lat: -36.33004474,
		lng: -57.68997766,
		pop: 21586.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Pedro Luro',
		city_ascii: 'Pedro Luro',
		lat: -39.48334064,
		lng: -62.68331629,
		pop: 7100,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Tres Arroyos',
		city_ascii: 'Tres Arroyos',
		lat: -38.3699719,
		lng: -60.26994938,
		pop: 34773.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Coronel Suarez',
		city_ascii: 'Coronel Suarez',
		lat: -37.46661619,
		lng: -61.9166189,
		pop: 20713,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Balcarce',
		city_ascii: 'Balcarce',
		lat: -37.83331216,
		lng: -58.24999516,
		pop: 18967,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: '25 de Mayo',
		city_ascii: '25 de Mayo',
		lat: -37.80003253,
		lng: -67.68329533,
		pop: 17430,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Río Negro',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'General Roca',
		city_ascii: 'General Roca',
		lat: -39.01995807,
		lng: -67.60996647,
		pop: 38578,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Río Negro',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Comallo',
		city_ascii: 'Comallo',
		lat: -41.03332355,
		lng: -70.26657434,
		pop: 741,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Río Negro',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Ingeniero Jacobacci',
		city_ascii: 'Ingeniero Jacobacci',
		lat: -41.30002562,
		lng: -69.58330855,
		pop: 5719,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Río Negro',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'General Conesa',
		city_ascii: 'General Conesa',
		lat: -40.10004718,
		lng: -64.43328699,
		pop: 2958,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Río Negro',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Choele Choel',
		city_ascii: 'Choele Choel',
		lat: -39.26660968,
		lng: -65.68331405,
		pop: 9895.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Río Negro',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'San Francisco',
		city_ascii: 'San Francisco',
		lat: -31.43003375,
		lng: -62.08996749,
		pop: 43231,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Córdoba',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Alta Gracia',
		city_ascii: 'Alta Gracia',
		lat: -31.65999388,
		lng: -64.4299797,
		pop: 30593,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Córdoba',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Villa Maria',
		city_ascii: 'Villa Maria',
		lat: -32.41002562,
		lng: -63.26002527,
		pop: 76701,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Córdoba',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Bell Ville',
		city_ascii: 'Bell Ville',
		lat: -32.60003986,
		lng: -62.67995732,
		pop: 29605,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Córdoba',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Villa Rumipal',
		city_ascii: 'Villa Rumipal',
		lat: -32.18332111,
		lng: -64.48328394,
		pop: 1269,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Córdoba',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Villa Carlos Paz',
		city_ascii: 'Villa Carlos Paz',
		lat: -31.42000853,
		lng: -64.50000126,
		pop: 60256,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Córdoba',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Chumbicha',
		city_ascii: 'Chumbicha',
		lat: -28.86662433,
		lng: -66.23330632,
		pop: 2572,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Catamarca',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'Tinogasta',
		city_ascii: 'Tinogasta',
		lat: -28.06662148,
		lng: -67.56658411,
		pop: 587,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Catamarca',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'Abra Pampa',
		city_ascii: 'Abra Pampa',
		lat: -22.7166638,
		lng: -65.6999797,
		pop: 4480,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Jujuy',
		timezone: 'America/Argentina/Jujuy',
	},
	{
		city: 'Humahuaca',
		city_ascii: 'Humahuaca',
		lat: -23.19999347,
		lng: -65.34994938,
		pop: 11369,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Jujuy',
		timezone: 'America/Argentina/Jujuy',
	},
	{
		city: 'Susques',
		city_ascii: 'Susques',
		lat: -23.41667275,
		lng: -66.4833169,
		pop: 1093,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Jujuy',
		timezone: 'America/Argentina/Jujuy',
	},
	{
		city: 'Chepes',
		city_ascii: 'Chepes',
		lat: -31.34998696,
		lng: -66.5999506,
		pop: 6020,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'La Rioja',
		timezone: 'America/Argentina/La_Rioja',
	},
	{
		city: 'Yacuiba',
		city_ascii: 'Yacuiba',
		lat: -22.03003904,
		lng: -63.69999841,
		pop: 64811,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Salta',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Tartagal',
		city_ascii: 'Tartagal',
		lat: -22.55000731,
		lng: -63.81001754,
		pop: 59996.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Salta',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Joaquin V. Gonzalez',
		city_ascii: 'Joaquin V. Gonzalez',
		lat: -25.08334105,
		lng: -64.18335392,
		pop: 13376,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Salta',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'General Guemes',
		city_ascii: 'General Guemes',
		lat: -24.6666223,
		lng: -65.04996769,
		pop: 19828,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Salta',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Trancas',
		city_ascii: 'Trancas',
		lat: -26.21665688,
		lng: -65.28331262,
		pop: 1599,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Tucumán',
		timezone: 'America/Argentina/Tucuman',
	},
	{
		city: 'Presidencia Roque Saenz Pena',
		city_ascii: 'Presidencia Roque Saenz Pena',
		lat: -26.7900069,
		lng: -60.45001591,
		pop: 75958,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chaco',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Pampa del Infierno',
		city_ascii: 'Pampa del Infierno',
		lat: -26.51658689,
		lng: -61.16658716,
		pop: 2921,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chaco',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Villa Angela',
		city_ascii: 'Villa Angela',
		lat: -27.58329181,
		lng: -60.7166663,
		pop: 30051,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chaco',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Ingeniero Guillermo N. Juarez',
		city_ascii: 'Ingeniero Guillermo N. Juarez',
		lat: -23.90000242,
		lng: -61.84998214,
		pop: 6453,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Formosa',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Comandante Fontana',
		city_ascii: 'Comandante Fontana',
		lat: -25.33329995,
		lng: -59.68331852,
		pop: 4277,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Formosa',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Doctor Pedro P. Pena',
		city_ascii: 'Doctor Pedro P. Pena',
		lat: -22.47998574,
		lng: -62.29998051,
		pop: 6143,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Formosa',
		timezone: 'America/Asuncion',
	},
	{
		city: 'San Lorenzo',
		city_ascii: 'San Lorenzo',
		lat: -28.12000324,
		lng: -58.76998926,
		pop: 25833.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Corrientes',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Corrientes',
		city_ascii: 'Corrientes',
		lat: -27.48996417,
		lng: -58.80998682,
		pop: 339945,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Corrientes',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Concepcion del Uruguay',
		city_ascii: 'Concepcion del Uruguay',
		lat: -32.47999551,
		lng: -58.23999577,
		pop: 48275,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Entre Ríos',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Victoria',
		city_ascii: 'Victoria',
		lat: -32.61001341,
		lng: -60.17998071,
		pop: 20032.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Entre Ríos',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Gualeguay',
		city_ascii: 'Gualeguay',
		lat: -33.15003213,
		lng: -59.34000615,
		pop: 25913,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Entre Ríos',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Parana',
		city_ascii: 'Parana',
		lat: -31.73332273,
		lng: -60.53334416,
		pop: 226852,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Entre Ríos',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Villa Constitucion',
		city_ascii: 'Villa Constitucion',
		lat: -33.23002724,
		lng: -60.35002202,
		pop: 30282.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Fe',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Rafaela',
		city_ascii: 'Rafaela',
		lat: -31.25004474,
		lng: -61.49997766,
		pop: 69649,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Fe',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Eldorado',
		city_ascii: 'Eldorado',
		lat: -26.20001707,
		lng: -54.59998539,
		pop: 17365,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Misiones',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Rodeo',
		city_ascii: 'Rodeo',
		lat: -30.21558592,
		lng: -69.1399506,
		pop: 701,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'San Juan',
		timezone: 'America/Argentina/San_Juan',
	},
	{
		city: 'Las Plumas',
		city_ascii: 'Las Plumas',
		lat: -43.41627887,
		lng: -67.24998845,
		pop: 605,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chubut',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'Gastre',
		city_ascii: 'Gastre',
		lat: -42.28296303,
		lng: -69.23330408,
		pop: 557,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chubut',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'Telsen',
		city_ascii: 'Telsen',
		lat: -42.39959674,
		lng: -66.95000676,
		pop: 493,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chubut',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'Malargue',
		city_ascii: 'Malargue',
		lat: -35.46611815,
		lng: -69.58330855,
		pop: 11847,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Mendoza',
		timezone: 'America/Argentina/Mendoza',
	},
	{
		city: 'Tunuyan',
		city_ascii: 'Tunuyan',
		lat: -33.56618244,
		lng: -69.01667647,
		pop: 22834,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Mendoza',
		timezone: 'America/Argentina/Mendoza',
	},
	{
		city: 'La Paz',
		city_ascii: 'La Paz',
		lat: -33.46613686,
		lng: -67.54999597,
		pop: 4400,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Mendoza',
		timezone: 'America/Argentina/Mendoza',
	},
	{
		city: 'Chos Malal',
		city_ascii: 'Chos Malal',
		lat: -37.38295205,
		lng: -70.26657434,
		pop: 8556,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Neuquén',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Las Lajas',
		city_ascii: 'Las Lajas',
		lat: -38.51626788,
		lng: -70.36659408,
		pop: 1218,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Neuquén',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Zarate',
		city_ascii: 'Zarate',
		lat: -34.08956134,
		lng: -59.04002446,
		pop: 86192,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Carhue',
		city_ascii: 'Carhue',
		lat: -37.1828609,
		lng: -62.73331323,
		pop: 7190,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Darregueira',
		city_ascii: 'Darregueira',
		lat: -37.69957355,
		lng: -63.16659428,
		pop: 3412,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Juarez',
		city_ascii: 'Juarez',
		lat: -37.66551878,
		lng: -59.80002975,
		pop: 10609,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Mar de Ajo',
		city_ascii: 'Mar de Ajo',
		lat: -36.71622272,
		lng: -56.67660283,
		pop: 13610,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Lobos',
		city_ascii: 'Lobos',
		lat: -35.184895,
		lng: -59.09467228,
		pop: 18278,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Chascomus',
		city_ascii: 'Chascomus',
		lat: -35.56621539,
		lng: -58.01662439,
		pop: 21054,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Junin',
		city_ascii: 'Junin',
		lat: -34.58456989,
		lng: -60.95887374,
		pop: 66141.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'La Plata',
		city_ascii: 'La Plata',
		lat: -34.90961465,
		lng: -57.95996118,
		pop: 440388.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Pergamino',
		city_ascii: 'Pergamino',
		lat: -33.89959878,
		lng: -60.56998275,
		pop: 71448,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Lujan',
		city_ascii: 'Lujan',
		lat: -34.57960895,
		lng: -59.10999435,
		pop: 69744.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Azul',
		city_ascii: 'Azul',
		lat: -36.7796297,
		lng: -59.86999964,
		pop: 43407.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Villalonga',
		city_ascii: 'Villalonga',
		lat: -39.88285114,
		lng: -62.58332239,
		pop: 2838,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Victorica',
		city_ascii: 'Victorica',
		lat: -36.21625324,
		lng: -65.45002079,
		pop: 4458,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'La Pampa',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'General Pico',
		city_ascii: 'General Pico',
		lat: -35.65959471,
		lng: -63.77001998,
		pop: 46483,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'La Pampa',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'San Antonio Oeste',
		city_ascii: 'San Antonio Oeste',
		lat: -40.73287677,
		lng: -64.93328231,
		pop: 8492,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Río Negro',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Sierra Colorado',
		city_ascii: 'Sierra Colorado',
		lat: -40.58286009,
		lng: -67.79998071,
		pop: 1522,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Río Negro',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Mercedes',
		city_ascii: 'Mercedes',
		lat: -33.68958576,
		lng: -65.4699679,
		pop: 49345,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'San Luis',
		timezone: 'America/Argentina/San_Luis',
	},
	{
		city: 'Rio Tercero',
		city_ascii: 'Rio Tercero',
		lat: -32.1796004,
		lng: -64.12002446,
		pop: 38049.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Córdoba',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Belen',
		city_ascii: 'Belen',
		lat: -27.64959267,
		lng: -67.03328333,
		pop: 11359,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Catamarca',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'Rinconada',
		city_ascii: 'Rinconada',
		lat: -22.43290851,
		lng: -66.16659204,
		pop: 6209.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Jujuy',
		timezone: 'America/Argentina/Jujuy',
	},
	{
		city: 'San Pedro',
		city_ascii: 'San Pedro',
		lat: -24.21962116,
		lng: -64.87000452,
		pop: 55249,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Jujuy',
		timezone: 'America/Argentina/Jujuy',
	},
	{
		city: 'Libertador General San Martin',
		city_ascii: 'Libertador General San Martin',
		lat: -23.81954222,
		lng: -64.78998356,
		pop: 47559,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Jujuy',
		timezone: 'America/Argentina/Jujuy',
	},
	{
		city: 'Chilecito',
		city_ascii: 'Chilecito',
		lat: -29.16552081,
		lng: -67.49999903,
		pop: 20343,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'La Rioja',
		timezone: 'America/Argentina/La_Rioja',
	},
	{
		city: 'Chamical',
		city_ascii: 'Chamical',
		lat: -30.34958291,
		lng: -66.3166604,
		pop: 8989,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'La Rioja',
		timezone: 'America/Argentina/La_Rioja',
	},
	{
		city: 'Los Blancos',
		city_ascii: 'Los Blancos',
		lat: -23.59960732,
		lng: -62.60003972,
		pop: 1145,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Salta',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Cafayate',
		city_ascii: 'Cafayate',
		lat: -26.08291828,
		lng: -65.96660425,
		pop: 9478,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Salta',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Cerrillos',
		city_ascii: 'Cerrillos',
		lat: -24.89963133,
		lng: -65.48330042,
		pop: 11498,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Salta',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'San Antonio de los Cobres',
		city_ascii: 'San Antonio de los Cobres',
		lat: -24.18293089,
		lng: -66.35001754,
		pop: 4000,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Salta',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Anatuya',
		city_ascii: 'Anatuya',
		lat: -28.46613198,
		lng: -62.83330713,
		pop: 14133,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santiago del Estero',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Frias',
		city_ascii: 'Frias',
		lat: -28.64958331,
		lng: -65.14998743,
		pop: 13594,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santiago del Estero',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'Monte Quemado',
		city_ascii: 'Monte Quemado',
		lat: -25.79962807,
		lng: -62.86658675,
		pop: 11387,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santiago del Estero',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Juan Jose Castelli',
		city_ascii: 'Juan Jose Castelli',
		lat: -25.9495414,
		lng: -60.61664657,
		pop: 9421,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chaco',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Charata',
		city_ascii: 'Charata',
		lat: -27.21628579,
		lng: -61.19999597,
		pop: 18297,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chaco',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Las Lomitas',
		city_ascii: 'Las Lomitas',
		lat: -24.69959186,
		lng: -60.60000676,
		pop: 7683,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Formosa',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Mercedes',
		city_ascii: 'Mercedes',
		lat: -29.1795768,
		lng: -58.0799797,
		pop: 22872.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Corrientes',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Concordia',
		city_ascii: 'Concordia',
		lat: -31.38957111,
		lng: -58.02998275,
		pop: 132760.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Entre Ríos',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Sunchales',
		city_ascii: 'Sunchales',
		lat: -30.93290648,
		lng: -61.56661442,
		pop: 12655,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Fe',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'San Justo',
		city_ascii: 'San Justo',
		lat: -30.78288979,
		lng: -60.58328943,
		pop: 9607,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Fe',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Vera',
		city_ascii: 'Vera',
		lat: -29.4661743,
		lng: -60.21659347,
		pop: 9979,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Fe',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Reconquista',
		city_ascii: 'Reconquista',
		lat: -29.13952757,
		lng: -59.65001306,
		pop: 86640.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Fe',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Venado Tuerto',
		city_ascii: 'Venado Tuerto',
		lat: -33.74958209,
		lng: -61.97000065,
		pop: 52079,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Fe',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Esquel',
		city_ascii: 'Esquel',
		lat: -42.90003131,
		lng: -71.31661361,
		pop: 20048,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chubut',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'Zapala',
		city_ascii: 'Zapala',
		lat: -38.90001707,
		lng: -70.06666406,
		pop: 19152,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Neuquén',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Olavarria',
		city_ascii: 'Olavarria',
		lat: -36.90003579,
		lng: -60.3299974,
		pop: 65059,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Tandil',
		city_ascii: 'Tandil',
		lat: -37.32001015,
		lng: -59.15004358,
		pop: 84799.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Viedma',
		city_ascii: 'Viedma',
		lat: -40.79995278,
		lng: -63.0000153,
		pop: 54031,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'San Luis',
		city_ascii: 'San Luis',
		lat: -33.29999713,
		lng: -66.35001754,
		pop: 308146,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'San Luis',
		timezone: 'America/Argentina/San_Luis',
	},
	{
		city: 'Rio Cuarto',
		city_ascii: 'Rio Cuarto',
		lat: -33.13003335,
		lng: -64.34998458,
		pop: 135959.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Córdoba',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'San Salvador de Jujuy',
		city_ascii: 'San Salvador de Jujuy',
		lat: -24.1833443,
		lng: -65.30002995,
		pop: 258739,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Jujuy',
		timezone: 'America/Argentina/Jujuy',
	},
	{
		city: 'San Ramon de la Nueva Oran',
		city_ascii: 'San Ramon de la Nueva Oran',
		lat: -23.13999713,
		lng: -64.32001225,
		pop: 69461.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Salta',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Goya',
		city_ascii: 'Goya',
		lat: -29.13999266,
		lng: -59.26998458,
		pop: 71274.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Corrientes',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Puerto San Julian',
		city_ascii: 'Puerto San Julian',
		lat: -49.30000242,
		lng: -67.71665247,
		pop: 2347,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Cruz',
		timezone: 'America/Argentina/Rio_Gallegos',
	},
	{
		city: 'Perito Moreno',
		city_ascii: 'Perito Moreno',
		lat: -46.60001219,
		lng: -70.93335535,
		pop: 3766,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Cruz',
		timezone: 'America/Argentina/Rio_Gallegos',
	},
	{
		city: 'Rio Grande',
		city_ascii: 'Rio Grande',
		lat: -53.79144552,
		lng: -67.6989952,
		pop: 31095,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Tierra del Fuego',
		timezone: 'America/Argentina/Ushuaia',
	},
	{
		city: 'Ushuaia',
		city_ascii: 'Ushuaia',
		lat: -54.79000324,
		lng: -68.31000126,
		pop: 50483.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Tierra del Fuego',
		timezone: 'America/Argentina/Ushuaia',
	},
	{
		city: 'Sarmiento',
		city_ascii: 'Sarmiento',
		lat: -45.60002155,
		lng: -69.08331323,
		pop: 5185,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chubut',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'San Rafael',
		city_ascii: 'San Rafael',
		lat: -34.60002114,
		lng: -68.33333317,
		pop: 79523.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Mendoza',
		timezone: 'America/Argentina/Mendoza',
	},
	{
		city: 'Necochea',
		city_ascii: 'Necochea',
		lat: -38.55998615,
		lng: -58.74999048,
		pop: 70562,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Rio Colorado',
		city_ascii: 'Rio Colorado',
		lat: -38.96657632,
		lng: -64.08328251,
		pop: 11499,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'La Pampa',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Catamarca',
		city_ascii: 'Catamarca',
		lat: -28.47000771,
		lng: -65.78000065,
		pop: 162586,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Catamarca',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'La Rioja',
		city_ascii: 'La Rioja',
		lat: -29.40995034,
		lng: -66.84996118,
		pop: 147130,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'La Rioja',
		timezone: 'America/Argentina/La_Rioja',
	},
	{
		city: 'Santiago del Estero',
		city_ascii: 'Santiago del Estero',
		lat: -27.78333128,
		lng: -64.26665633,
		pop: 317549.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santiago del Estero',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Resistencia',
		city_ascii: 'Resistencia',
		lat: -27.45999184,
		lng: -58.99002751,
		pop: 368455.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chaco',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Gualeguaychu',
		city_ascii: 'Gualeguaychu',
		lat: -33.02001422,
		lng: -58.52000452,
		pop: 55860.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Entre Ríos',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'El Calafate',
		city_ascii: 'El Calafate',
		lat: -50.33332436,
		lng: -72.30001612,
		pop: 6329,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Cruz',
		timezone: 'America/Argentina/Rio_Gallegos',
	},
	{
		city: 'San Juan',
		city_ascii: 'San Juan',
		lat: -31.55002643,
		lng: -68.51998845,
		pop: 433892,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'San Juan',
		timezone: 'America/Argentina/San_Juan',
	},
	{
		city: 'Rawson',
		city_ascii: 'Rawson',
		lat: -43.3000069,
		lng: -65.09999048,
		pop: 25062.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chubut',
		timezone: 'America/Argentina/Catamarca',
	},
	{
		city: 'Neuquen',
		city_ascii: 'Neuquen',
		lat: -38.95003986,
		lng: -68.05999068,
		pop: 213823.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Neuquén',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Santa Rosa',
		city_ascii: 'Santa Rosa',
		lat: -36.6200012,
		lng: -64.29998763,
		pop: 97693.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'La Pampa',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'San Carlos de Bariloche',
		city_ascii: 'San Carlos de Bariloche',
		lat: -41.14995726,
		lng: -71.29999964,
		pop: 91953,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Río Negro',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Salta',
		city_ascii: 'Salta',
		lat: -24.78335936,
		lng: -65.41663782,
		pop: 484646,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Salta',
		timezone: 'America/Argentina/Salta',
	},
	{
		city: 'Tucumán',
		city_ascii: 'San Miguel de Tucuman',
		lat: -26.81600014,
		lng: -65.21662419,
		pop: 678803.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Tucumán',
		timezone: 'America/Argentina/Tucuman',
	},
	{
		city: 'Formosa',
		city_ascii: 'Formosa',
		lat: -26.17283527,
		lng: -58.1828158,
		pop: 202272,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Formosa',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Santa Fe',
		city_ascii: 'Santa Fe',
		lat: -31.62387205,
		lng: -60.69000126,
		pop: 393504,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Fe',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Rosario',
		city_ascii: 'Rosario',
		lat: -32.95112954,
		lng: -60.66630762,
		pop: 1094784.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Fe',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Puerto Deseado',
		city_ascii: 'Puerto Deseado',
		lat: -47.75001951,
		lng: -65.89996749,
		pop: 3305,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Cruz',
		timezone: 'America/Argentina/Rio_Gallegos',
	},
	{
		city: 'Rio Gallegos',
		city_ascii: 'Rio Gallegos',
		lat: -51.63329669,
		lng: -69.21658675,
		pop: 77183,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Santa Cruz',
		timezone: 'America/Argentina/Rio_Gallegos',
	},
	{
		city: 'Comodoro Rivadavia',
		city_ascii: 'Comodoro Rivadavia',
		lat: -45.87003091,
		lng: -67.49999903,
		pop: 123291,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Chubut',
		timezone: 'America/Argentina/Mendoza',
	},
	{
		city: 'Mendoza',
		city_ascii: 'Mendoza',
		lat: -32.88333006,
		lng: -68.81661117,
		pop: 827815,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Mendoza',
		timezone: 'America/Argentina/Mendoza',
	},
	{
		city: 'Bahia Blanca',
		city_ascii: 'Bahia Blanca',
		lat: -38.74002684,
		lng: -62.2650214,
		pop: 279041,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Mar del Plata',
		city_ascii: 'Mar del Plata',
		lat: -38.00002033,
		lng: -57.57998438,
		pop: 554916,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Córdoba',
		city_ascii: 'Cordoba',
		lat: -31.39995807,
		lng: -64.18229456,
		pop: 1374467.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Córdoba',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Posadas',
		city_ascii: 'Posadas',
		lat: -27.3578321,
		lng: -55.88510735,
		pop: 334589.5,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Misiones',
		timezone: 'America/Argentina/Cordoba',
	},
	{
		city: 'Buenos Aires',
		city_ascii: 'Buenos Aires',
		lat: -34.60250161,
		lng: -58.39753137,
		pop: 11862073,
		country: 'Argentina',
		iso2: 'AR',
		iso3: 'ARG',
		province: 'Ciudad de Buenos Aires',
		timezone: 'America/Argentina/Buenos_Aires',
	},
	{
		city: 'Ashtarak',
		city_ascii: 'Ashtarak',
		lat: 40.3016667,
		lng: 44.3591667,
		pop: 18779,
		country: 'Armenia',
		iso2: 'AM',
		iso3: 'ARM',
		province: 'Aragatsotn',
		timezone: 'Asia/Yerevan',
	},
	{
		city: 'Ijevan',
		city_ascii: 'Ijevan',
		lat: 40.8755556,
		lng: 45.1491667,
		pop: 14737,
		country: 'Armenia',
		iso2: 'AM',
		iso3: 'ARM',
		province: 'Tavush',
		timezone: 'Asia/Yerevan',
	},
	{
		city: 'Artashat',
		city_ascii: 'Artashat',
		lat: 39.9538889,
		lng: 44.5505556,
		pop: 20562,
		country: 'Armenia',
		iso2: 'AM',
		iso3: 'ARM',
		province: 'Ararat',
		timezone: 'Asia/Yerevan',
	},
	{
		city: 'Gavarr',
		city_ascii: 'Gavarr',
		lat: 40.3588889,
		lng: 45.1266667,
		pop: 21680,
		country: 'Armenia',
		iso2: 'AM',
		iso3: 'ARM',
		province: 'Gegharkunik',
		timezone: 'Asia/Yerevan',
	},
	{
		city: 'Yeghegnadzor',
		city_ascii: 'Yeghegnadzor',
		lat: 39.7611111,
		lng: 45.3333333,
		pop: 8200,
		country: 'Armenia',
		iso2: 'AM',
		iso3: 'ARM',
		province: 'Vayots Dzor',
		timezone: 'Asia/Yerevan',
	},
	{
		city: 'Gyumri',
		city_ascii: 'Gyumri',
		lat: 40.78943402,
		lng: 43.84749385,
		pop: 140277.5,
		country: 'Armenia',
		iso2: 'AM',
		iso3: 'ARM',
		province: 'Shirak',
		timezone: 'Asia/Yerevan',
	},
	{
		city: 'Vanadzor',
		city_ascii: 'Vanadzor',
		lat: 40.81276593,
		lng: 44.48828162,
		pop: 89295,
		country: 'Armenia',
		iso2: 'AM',
		iso3: 'ARM',
		province: 'Lori',
		timezone: 'Asia/Yerevan',
	},
	{
		city: 'Yerevan',
		city_ascii: 'Yerevan',
		lat: 40.18115074,
		lng: 44.51355139,
		pop: 1097742.5,
		country: 'Armenia',
		iso2: 'AM',
		iso3: 'ARM',
		province: 'Erevan',
		timezone: 'Asia/Yerevan',
	},
	{
		city: 'Oranjestad',
		city_ascii: 'Oranjestad',
		lat: 12.53038373,
		lng: -70.02899195,
		pop: 50887.5,
		country: 'Aruba',
		iso2: 'AW',
		iso3: 'ABW',
		province: '',
		timezone: 'America/Aruba',
	},
	{
		city: 'Central Coast',
		city_ascii: 'Central Coast',
		lat: -33.42004148,
		lng: 151.3000048,
		pop: 3026,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Sunshine Coast',
		city_ascii: 'Sunshine Coast',
		lat: -26.67998777,
		lng: 153.0500272,
		pop: 57215.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Bourke',
		city_ascii: 'Bourke',
		lat: -30.1,
		lng: 145.9333333,
		pop: 2475,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Pine Creek',
		city_ascii: 'Pine Creek',
		lat: -13.81617348,
		lng: 131.816698,
		pop: 505,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Northern Territory',
		timezone: 'Australia/Darwin',
	},
	{
		city: 'Adelaide River',
		city_ascii: 'Adelaide River',
		lat: -13.2495414,
		lng: 131.0999975,
		pop: 237,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Northern Territory',
		timezone: 'Australia/Darwin',
	},
	{
		city: 'McMinns Lagoon',
		city_ascii: 'McMinns Lagoon',
		lat: -12.53289264,
		lng: 131.0500264,
		pop: 5025,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Northern Territory',
		timezone: 'Australia/Darwin',
	},
	{
		city: 'Newcastle Waters',
		city_ascii: 'Newcastle Waters',
		lat: -17.55279295,
		lng: 133.4672432,
		pop: 10,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Northern Territory',
		timezone: 'Australia/Darwin',
	},
	{
		city: 'Ravensthorpe',
		city_ascii: 'Ravensthorpe',
		lat: -33.58287392,
		lng: 120.0333345,
		pop: 639,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Wagin',
		city_ascii: 'Wagin',
		lat: -33.29958372,
		lng: 117.3499841,
		pop: 1037.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Roebourne',
		city_ascii: 'Roebourne',
		lat: -20.7829317,
		lng: 117.1333048,
		pop: 11281.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Pannawonica',
		city_ascii: 'Pannawonica',
		lat: -21.63657469,
		lng: 116.3250337,
		pop: 686,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Tom Price',
		city_ascii: 'Tom Price',
		lat: -22.69346108,
		lng: 117.7930578,
		pop: 1822,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Kalbarri',
		city_ascii: 'Kalbarri',
		lat: -27.66618081,
		lng: 114.1666642,
		pop: 1537,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Mount Magnet',
		city_ascii: 'Mount Magnet',
		lat: -28.06620807,
		lng: 117.8166739,
		pop: 424,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Morawa',
		city_ascii: 'Morawa',
		lat: -29.21626707,
		lng: 116.0000406,
		pop: 259,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Port Denison',
		city_ascii: 'Port Denison',
		lat: -29.28282632,
		lng: 114.9166442,
		pop: 1213,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Merredin',
		city_ascii: 'Merredin',
		lat: -31.48284707,
		lng: 118.2666723,
		pop: 2054,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Mount Barker',
		city_ascii: 'Mount Barker',
		lat: -34.632784,
		lng: 117.6666056,
		pop: 1771.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Katanning',
		city_ascii: 'Katanning',
		lat: -33.69955931,
		lng: 117.5500752,
		pop: 3140,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Narrogin',
		city_ascii: 'Narrogin',
		lat: -32.93288776,
		lng: 117.1666361,
		pop: 3995,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Gingin',
		city_ascii: 'Gingin',
		lat: -31.34957355,
		lng: 115.9000468,
		pop: 1446,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Bunbury',
		city_ascii: 'Bunbury',
		lat: -33.34428384,
		lng: 115.6502429,
		pop: 26683.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Kwinana',
		city_ascii: 'Kwinana',
		lat: -32.23939004,
		lng: 115.7702356,
		pop: 18817.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Southern Cross',
		city_ascii: 'Southern Cross',
		lat: -31.216145,
		lng: 119.3166857,
		pop: 187,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Kaltukatjara',
		city_ascii: 'Kaltukatjara',
		lat: -25.78841429,
		lng: 128.9973352,
		pop: 355,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Northern Territory',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Queanbeyan',
		city_ascii: 'Queanbeyan',
		lat: -35.3546004,
		lng: 149.2113468,
		pop: 32408,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Australian Capital Territory',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Tweed Heads',
		city_ascii: 'Tweed Heads',
		lat: -28.1825834,
		lng: 153.5466377,
		pop: 33065,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Ivanhoe',
		city_ascii: 'Ivanhoe',
		lat: -32.89960814,
		lng: 144.3000187,
		pop: 265,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Wilcannia',
		city_ascii: 'Wilcannia',
		lat: -31.56620115,
		lng: 143.3833304,
		pop: 442,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Merimbula',
		city_ascii: 'Merimbula',
		lat: -36.89962238,
		lng: 149.9000386,
		pop: 3607.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Echuca',
		city_ascii: 'Echuca',
		lat: -36.12959186,
		lng: 144.750017,
		pop: 13460,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Deniliquin',
		city_ascii: 'Deniliquin',
		lat: -35.5295768,
		lng: 144.9500048,
		pop: 6019.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Nowra',
		city_ascii: 'Nowra',
		lat: -34.88284625,
		lng: 150.6000476,
		pop: 61036.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Ulladulla',
		city_ascii: 'Ulladulla',
		lat: -35.34953611,
		lng: 150.4700297,
		pop: 6811,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Batemans Bay',
		city_ascii: 'Batemans Bay',
		lat: -35.68961871,
		lng: 150.2073067,
		pop: 5604,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Cooma',
		city_ascii: 'Cooma',
		lat: -36.23955931,
		lng: 149.1200345,
		pop: 5661.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Tumut',
		city_ascii: 'Tumut',
		lat: -35.30959023,
		lng: 148.2200378,
		pop: 4873,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Leeton',
		city_ascii: 'Leeton',
		lat: -34.54493406,
		lng: 146.3973067,
		pop: 6277,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Young',
		city_ascii: 'Young',
		lat: -34.30959959,
		lng: 148.2900077,
		pop: 6422,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Cowra',
		city_ascii: 'Cowra',
		lat: -33.82962889,
		lng: 148.6800097,
		pop: 5729.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Forbes',
		city_ascii: 'Forbes',
		lat: -33.38960407,
		lng: 148.0199983,
		pop: 4076.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Goulburn',
		city_ascii: 'Goulburn',
		lat: -34.74957273,
		lng: 149.7101794,
		pop: 16444,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Kiama',
		city_ascii: 'Kiama',
		lat: -34.70957518,
		lng: 150.8400329,
		pop: 10379,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Katoomba',
		city_ascii: 'Katoomba',
		lat: -33.70694904,
		lng: 150.320013,
		pop: 20334.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Richmond',
		city_ascii: 'Richmond',
		lat: -33.59951373,
		lng: 150.7399873,
		pop: 9720,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Lithgow',
		city_ascii: 'Lithgow',
		lat: -33.49610919,
		lng: 150.152788,
		pop: 10338,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Parkes',
		city_ascii: 'Parkes',
		lat: -33.12956826,
		lng: 148.170015,
		pop: 7160,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Bathurst',
		city_ascii: 'Bathurst',
		lat: -33.41962807,
		lng: 149.5700329,
		pop: 6111,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Maitland',
		city_ascii: 'Maitland',
		lat: -32.72096271,
		lng: 151.555028,
		pop: 10026.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Singleton',
		city_ascii: 'Singleton',
		lat: -32.56949909,
		lng: 151.1600134,
		pop: 8340.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Mudgee',
		city_ascii: 'Mudgee',
		lat: -32.58960122,
		lng: 149.5801098,
		pop: 5391,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Muswellbrook',
		city_ascii: 'Muswellbrook',
		lat: -32.26956907,
		lng: 150.890004,
		pop: 8171,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Taree',
		city_ascii: 'Taree',
		lat: -31.89760211,
		lng: 152.4618461,
		pop: 30131.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Kempsey',
		city_ascii: 'Kempsey',
		lat: -31.08736733,
		lng: 152.8220308,
		pop: 10681,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Gunnedah',
		city_ascii: 'Gunnedah',
		lat: -30.9870117,
		lng: 150.2622904,
		pop: 6204,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Coffs Harbour',
		city_ascii: 'Coffs Harbour',
		lat: -30.3070532,
		lng: 153.1122973,
		pop: 48961,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Narrabri',
		city_ascii: 'Narrabri',
		lat: -30.33190957,
		lng: 149.7874357,
		pop: 6105.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Inverell',
		city_ascii: 'Inverell',
		lat: -29.7667761,
		lng: 151.1125744,
		pop: 6845.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Yamba',
		city_ascii: 'Yamba',
		lat: -29.42299136,
		lng: 153.353312,
		pop: 1806,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Ballina',
		city_ascii: 'Ballina',
		lat: -28.86135333,
		lng: 153.5679801,
		pop: 13997.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Wagga Wagga',
		city_ascii: 'Wagga Wagga',
		lat: -35.12215981,
		lng: 147.3399882,
		pop: 45549,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Scone',
		city_ascii: 'Scone',
		lat: -32.07960651,
		lng: 150.8501098,
		pop: 4624,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Byron Bay',
		city_ascii: 'Byron Bay',
		lat: -28.65650796,
		lng: 153.6128869,
		pop: 5244.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Berri',
		city_ascii: 'Berri',
		lat: -34.28293455,
		lng: 140.6000378,
		pop: 4523,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Peterborough',
		city_ascii: 'Peterborough',
		lat: -32.96616738,
		lng: 138.8333239,
		pop: 1351,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Wallaroo',
		city_ascii: 'Wallaroo',
		lat: -33.9328784,
		lng: 137.6332938,
		pop: 2442,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Clare',
		city_ascii: 'Clare',
		lat: -33.8328845,
		lng: 138.6000048,
		pop: 2729,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Meningie',
		city_ascii: 'Meningie',
		lat: -35.69954059,
		lng: 139.3333451,
		pop: 850,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Kingston South East',
		city_ascii: 'Kingston South East',
		lat: -36.83280475,
		lng: 139.8500577,
		pop: 206,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Bordertown',
		city_ascii: 'Bordertown',
		lat: -36.31619546,
		lng: 140.7666426,
		pop: 1976,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Penola',
		city_ascii: 'Penola',
		lat: -37.38295205,
		lng: 140.8166654,
		pop: 1513,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Kingoonya',
		city_ascii: 'Kingoonya',
		lat: -30.89957518,
		lng: 135.2999996,
		pop: 50,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Kimba',
		city_ascii: 'Kimba',
		lat: -33.14961871,
		lng: 136.4333671,
		pop: 636,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Streaky Bay',
		city_ascii: 'Streaky Bay',
		lat: -32.81187176,
		lng: 134.2149296,
		pop: 614.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Cowell',
		city_ascii: 'Cowell',
		lat: -33.68286782,
		lng: 136.9166451,
		pop: 537,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Tumby Bay',
		city_ascii: 'Tumby Bay',
		lat: -34.38292845,
		lng: 136.0833109,
		pop: 1791,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Andamooka',
		city_ascii: 'Andamooka',
		lat: -30.43097329,
		lng: 137.1655704,
		pop: 528,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Woomera',
		city_ascii: 'Woomera',
		lat: -31.14958576,
		lng: 136.8000114,
		pop: 450,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Port Pirie',
		city_ascii: 'Port Pirie',
		lat: -33.19106321,
		lng: 137.9900162,
		pop: 12417,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Gawler',
		city_ascii: 'Gawler',
		lat: -34.60735919,
		lng: 138.7263537,
		pop: 15542.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Murray Bridge',
		city_ascii: 'Murray Bridge',
		lat: -35.12960122,
		lng: 139.2600162,
		pop: 14185.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Victor Harbor',
		city_ascii: 'Victor Harbor',
		lat: -35.55960081,
		lng: 138.6173164,
		pop: 7489,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Hamilton',
		city_ascii: 'Hamilton',
		lat: -37.73119953,
		lng: 142.0234135,
		pop: 6992.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Ouyen',
		city_ascii: 'Ouyen',
		lat: -35.06619424,
		lng: 142.3166772,
		pop: 912,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Colac',
		city_ascii: 'Colac',
		lat: -38.33953449,
		lng: 143.5800109,
		pop: 7450,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Stawell',
		city_ascii: 'Stawell',
		lat: -37.05961261,
		lng: 142.7600093,
		pop: 4596,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Horsham',
		city_ascii: 'Horsham',
		lat: -36.70960814,
		lng: 142.1900183,
		pop: 11985.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Ararat',
		city_ascii: 'Ararat',
		lat: -37.27954751,
		lng: 142.9099743,
		pop: 5464,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Maryborough',
		city_ascii: 'Maryborough',
		lat: -37.04958738,
		lng: 143.729976,
		pop: 5838.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Bairnsdale',
		city_ascii: 'Bairnsdale',
		lat: -37.82959145,
		lng: 147.6099975,
		pop: 9427,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Sale',
		city_ascii: 'Sale',
		lat: -38.10957436,
		lng: 147.0600052,
		pop: 17701.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Traralgon',
		city_ascii: 'Traralgon',
		lat: -38.19959471,
		lng: 146.5300118,
		pop: 16982.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Wonthaggi',
		city_ascii: 'Wonthaggi',
		lat: -38.60949217,
		lng: 145.5900175,
		pop: 4471.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Cranbourne',
		city_ascii: 'Cranbourne',
		lat: -38.09960081,
		lng: 145.2833695,
		pop: 249955,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Ballarat',
		city_ascii: 'Ballarat',
		lat: -37.55958209,
		lng: 143.8400468,
		pop: 73404,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Melton',
		city_ascii: 'Melton',
		lat: -37.68954832,
		lng: 144.570028,
		pop: 29750,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Seymour',
		city_ascii: 'Seymour',
		lat: -37.03423948,
		lng: 145.1273067,
		pop: 3693,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Shepparton',
		city_ascii: 'Shepparton',
		lat: -36.37458982,
		lng: 145.3913732,
		pop: 33430.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Cobram',
		city_ascii: 'Cobram',
		lat: -35.91963051,
		lng: 145.6500138,
		pop: 4659,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Swan Hill',
		city_ascii: 'Swan Hill',
		lat: -35.33961424,
		lng: 143.5400134,
		pop: 9073,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Sunbury',
		city_ascii: 'Sunbury',
		lat: -37.56960732,
		lng: 144.7100195,
		pop: 18677.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Proserpine',
		city_ascii: 'Proserpine',
		lat: -20.41623574,
		lng: 148.5834781,
		pop: 3976,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Theodore',
		city_ascii: 'Theodore',
		lat: -24.94949909,
		lng: 150.0833349,
		pop: 246,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Eidsvold',
		city_ascii: 'Eidsvold',
		lat: -25.36621784,
		lng: 151.1333483,
		pop: 459,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Barcaldine',
		city_ascii: 'Barcaldine',
		lat: -23.56617267,
		lng: 145.2833695,
		pop: 1068,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Winton',
		city_ascii: 'Winton',
		lat: -22.39962889,
		lng: 143.0332743,
		pop: 1157,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Longreach',
		city_ascii: 'Longreach',
		lat: -23.44959064,
		lng: 144.2500476,
		pop: 2894,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Caboolture',
		city_ascii: 'Caboolture',
		lat: -27.08296059,
		lng: 152.9499816,
		pop: 26495.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Warwick',
		city_ascii: 'Warwick',
		lat: -28.22924721,
		lng: 152.0203226,
		pop: 10024,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Kingaroy',
		city_ascii: 'Kingaroy',
		lat: -26.53896279,
		lng: 151.840592,
		pop: 7494.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Dalby',
		city_ascii: 'Dalby',
		lat: -27.19385822,
		lng: 151.2657434,
		pop: 9818.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Bongaree',
		city_ascii: 'Bongaree',
		lat: -27.07872313,
		lng: 153.1508996,
		pop: 10327.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Gympie',
		city_ascii: 'Gympie',
		lat: -26.18859658,
		lng: 152.6709289,
		pop: 11338,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Ingham',
		city_ascii: 'Ingham',
		lat: -18.64957355,
		lng: 146.1666231,
		pop: 5996.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Birdsville',
		city_ascii: 'Birdsville',
		lat: -25.89962197,
		lng: 139.3666247,
		pop: 283,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Bedourie',
		city_ascii: 'Bedourie',
		lat: -24.34958738,
		lng: 139.4666186,
		pop: 142,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Boulia',
		city_ascii: 'Boulia',
		lat: -22.89959837,
		lng: 139.9000288,
		pop: 402.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Richmond',
		city_ascii: 'Richmond',
		lat: -20.71626911,
		lng: 143.1333199,
		pop: 296,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Burketown',
		city_ascii: 'Burketown',
		lat: -17.71609048,
		lng: 139.5666125,
		pop: 186.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Hervey Bay',
		city_ascii: 'Hervey Bay',
		lat: -25.28870319,
		lng: 152.8409444,
		pop: 25114,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Biloela',
		city_ascii: 'Biloela',
		lat: -24.39356403,
		lng: 150.4960746,
		pop: 4366.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Yeppoon',
		city_ascii: 'Yeppoon',
		lat: -23.13291746,
		lng: 150.7567305,
		pop: 6450.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Emerald',
		city_ascii: 'Emerald',
		lat: -23.51222247,
		lng: 148.1673278,
		pop: 7489,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Moranbah',
		city_ascii: 'Moranbah',
		lat: -22.00156533,
		lng: 148.0380334,
		pop: 7357,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Charters Towers',
		city_ascii: 'Charters Towers',
		lat: -20.08090737,
		lng: 146.2587105,
		pop: 8369.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Ayr',
		city_ascii: 'Ayr',
		lat: -19.57024087,
		lng: 147.3994677,
		pop: 7166,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Atherton',
		city_ascii: 'Atherton',
		lat: -17.27027789,
		lng: 145.469353,
		pop: 6132.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Port Douglas',
		city_ascii: 'Port Douglas',
		lat: -16.48458812,
		lng: 145.4587219,
		pop: 2004.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Smithton',
		city_ascii: 'Smithton',
		lat: -40.83292234,
		lng: 145.1166613,
		pop: 3351,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Tasmania',
		timezone: 'Australia/Hobart',
	},
	{
		city: 'Scottsdale',
		city_ascii: 'Scottsdale',
		lat: -41.14949217,
		lng: 147.5166699,
		pop: 1683.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Tasmania',
		timezone: 'Australia/Hobart',
	},
	{
		city: 'Bicheno',
		city_ascii: 'Bicheno',
		lat: -41.87838825,
		lng: 148.2886124,
		pop: 177,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Tasmania',
		timezone: 'Australia/Hobart',
	},
	{
		city: 'Oatlands',
		city_ascii: 'Oatlands',
		lat: -42.29960285,
		lng: 147.3666015,
		pop: 1157,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Tasmania',
		timezone: 'Australia/Hobart',
	},
	{
		city: 'Queenstown',
		city_ascii: 'Queenstown',
		lat: -42.08292356,
		lng: 145.5500199,
		pop: 1658,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Tasmania',
		timezone: 'Australia/Hobart',
	},
	{
		city: 'Kingston',
		city_ascii: 'Kingston',
		lat: -42.99113686,
		lng: 147.3084139,
		pop: 12652,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Tasmania',
		timezone: 'Australia/Hobart',
	},
	{
		city: 'Tennant Creek',
		city_ascii: 'Tennant Creek',
		lat: -19.65002928,
		lng: 134.200015,
		pop: 3490.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Northern Territory',
		timezone: 'Australia/Darwin',
	},
	{
		city: 'Yulara',
		city_ascii: 'Yulara',
		lat: -25.24054075,
		lng: 130.9888932,
		pop: 930,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Northern Territory',
		timezone: 'Australia/Darwin',
	},
	{
		city: 'Erldunda',
		city_ascii: 'Erldunda',
		lat: -25.23330605,
		lng: 133.1999727,
		pop: 10,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Northern Territory',
		timezone: 'Australia/Darwin',
	},
	{
		city: 'Norseman',
		city_ascii: 'Norseman',
		lat: -32.20001259,
		lng: 121.7666137,
		pop: 1004,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Halls Creek',
		city_ascii: 'Halls Creek',
		lat: -18.26670286,
		lng: 127.7667126,
		pop: 1209,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Kununurra',
		city_ascii: 'Kununurra',
		lat: -15.76659707,
		lng: 128.7333203,
		pop: 5229.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Derby',
		city_ascii: 'Derby',
		lat: -17.29999184,
		lng: 123.9666345,
		pop: 3199,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Onslow',
		city_ascii: 'Onslow',
		lat: -21.65759354,
		lng: 115.0962959,
		pop: 573,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Exmouth',
		city_ascii: 'Exmouth',
		lat: -21.93109987,
		lng: 114.1233469,
		pop: 1085,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Carnarvon',
		city_ascii: 'Carnarvon',
		lat: -24.89983803,
		lng: 113.6501066,
		pop: 7392,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Newman',
		city_ascii: 'Newman',
		lat: -23.36659829,
		lng: 119.7333011,
		pop: 2678,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Meekatharra',
		city_ascii: 'Meekatharra',
		lat: -26.59999266,
		lng: 118.4832999,
		pop: 654,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Three Springs',
		city_ascii: 'Three Springs',
		lat: -29.53330198,
		lng: 115.7499784,
		pop: 190,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Manjimup',
		city_ascii: 'Manjimup',
		lat: -34.23332518,
		lng: 116.1500057,
		pop: 4016.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Northam',
		city_ascii: 'Northam',
		lat: -31.65658323,
		lng: 116.6533858,
		pop: 5330,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Esperance',
		city_ascii: 'Esperance',
		lat: -33.857307,
		lng: 121.8888973,
		pop: 7205,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Leonara',
		city_ascii: 'Leonara',
		lat: -28.88150714,
		lng: 121.3280358,
		pop: 227,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Laverton',
		city_ascii: 'Laverton',
		lat: -28.62700071,
		lng: 122.4040425,
		pop: 316,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Wyndham',
		city_ascii: 'Wyndham',
		lat: -15.37395953,
		lng: 128.3600614,
		pop: 734.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Albury',
		city_ascii: 'Albury',
		lat: -36.06003538,
		lng: 146.9200138,
		pop: 68534,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Forster-Tuncurry',
		city_ascii: 'Forster-Tuncurry',
		lat: -32.19313963,
		lng: 152.5266483,
		pop: 13275.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Port Macquarie',
		city_ascii: 'Port Macquarie',
		lat: -31.44501992,
		lng: 152.9186657,
		pop: 42070,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Tamworth',
		city_ascii: 'Tamworth',
		lat: -31.10261188,
		lng: 150.9171342,
		pop: 35080,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Grafton',
		city_ascii: 'Grafton',
		lat: -29.71199909,
		lng: 152.9376827,
		pop: 7871.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Moree',
		city_ascii: 'Moree',
		lat: -29.469895,
		lng: 149.8300687,
		pop: 8062,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Goondiwindi',
		city_ascii: 'Goondiwindi',
		lat: -28.55480874,
		lng: 150.325284,
		pop: 4251,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Lismore',
		city_ascii: 'Lismore',
		lat: -28.81665322,
		lng: 153.2931132,
		pop: 28065.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Wollongong',
		city_ascii: 'Wollongong',
		lat: -34.41538125,
		lng: 150.890004,
		pop: 201319.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Ceduna',
		city_ascii: 'Ceduna',
		lat: -32.09913464,
		lng: 133.6622674,
		pop: 1252.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Mount Gambier',
		city_ascii: 'Mount Gambier',
		lat: -37.83134845,
		lng: 140.7650406,
		pop: 21818.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Port Augusta',
		city_ascii: 'Port Augusta',
		lat: -32.49002073,
		lng: 137.7700297,
		pop: 11186.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Warrnambool',
		city_ascii: 'Warrnambool',
		lat: -38.37999713,
		lng: 142.4700012,
		pop: 29882,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Mildura',
		city_ascii: 'Mildura',
		lat: -34.18500771,
		lng: 142.1513643,
		pop: 33324.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Geelong',
		city_ascii: 'Geelong',
		lat: -38.16749505,
		lng: 144.3956335,
		pop: 149336,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Camooweal',
		city_ascii: 'Camooweal',
		lat: -19.91673134,
		lng: 138.1166752,
		pop: 187,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Quilpie',
		city_ascii: 'Quilpie',
		lat: -26.61663247,
		lng: 144.2500476,
		pop: 560,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Charleville',
		city_ascii: 'Charleville',
		lat: -26.40000486,
		lng: 146.2500288,
		pop: 1900,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Hughenden',
		city_ascii: 'Hughenden',
		lat: -20.85000771,
		lng: 144.2000248,
		pop: 421,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Caloundra',
		city_ascii: 'Caloundra',
		lat: -26.80003213,
		lng: 153.1333296,
		pop: 33737,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Roma',
		city_ascii: 'Roma',
		lat: -26.55937498,
		lng: 148.7907006,
		pop: 4560.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Toowoomba',
		city_ascii: 'Toowoomba',
		lat: -27.56453327,
		lng: 151.9555204,
		pop: 86711,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Georgetown',
		city_ascii: 'Georgetown',
		lat: -18.30003416,
		lng: 143.5332955,
		pop: 818,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Thargomindah',
		city_ascii: 'Thargomindah',
		lat: -27.99995888,
		lng: 143.816689,
		pop: 203,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Weipa',
		city_ascii: 'Weipa',
		lat: -12.66663125,
		lng: 141.8666272,
		pop: 2830,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Karumba',
		city_ascii: 'Karumba',
		lat: -17.48333982,
		lng: 140.8334086,
		pop: 173,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Cloncurry',
		city_ascii: 'Cloncurry',
		lat: -20.69999103,
		lng: 140.4999922,
		pop: 1202,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Maryborough',
		city_ascii: 'Maryborough',
		lat: -25.54910073,
		lng: 152.7209,
		pop: 18920.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Bundaberg',
		city_ascii: 'Bundaberg',
		lat: -24.87906411,
		lng: 152.3508968,
		pop: 46062,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Gladstone',
		city_ascii: 'Gladstone',
		lat: -23.8533386,
		lng: 151.2467264,
		pop: 29055,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Bowen',
		city_ascii: 'Bowen',
		lat: -20.00132566,
		lng: 148.208669,
		pop: 10983,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Innisfail',
		city_ascii: 'Innisfail',
		lat: -17.53134723,
		lng: 146.0386722,
		pop: 9707,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Mackay',
		city_ascii: 'Mackay',
		lat: -21.14389158,
		lng: 149.1500069,
		pop: 66053.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Burnie',
		city_ascii: 'Burnie',
		lat: -41.06660317,
		lng: 145.9166642,
		pop: 18490.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Tasmania',
		timezone: 'Australia/Hobart',
	},
	{
		city: 'Launceston',
		city_ascii: 'Launceston',
		lat: -41.44983559,
		lng: 147.1301818,
		pop: 65106.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Tasmania',
		timezone: 'Australia/Hobart',
	},
	{
		city: 'Katherine',
		city_ascii: 'Katherine',
		lat: -14.46662474,
		lng: 132.266593,
		pop: 8171.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Northern Territory',
		timezone: 'Australia/Darwin',
	},
	{
		city: 'Busselton',
		city_ascii: 'Busselton',
		lat: -33.65640949,
		lng: 115.3486592,
		pop: 9595,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Mandurah',
		city_ascii: 'Mandurah',
		lat: -32.52348259,
		lng: 115.7470567,
		pop: 52866,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Broome',
		city_ascii: 'Broome',
		lat: -17.96177069,
		lng: 122.2307681,
		pop: 11890.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Kalgoorlie',
		city_ascii: 'Kalgoorlie',
		lat: -30.73539915,
		lng: 121.4600175,
		pop: 32058,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Albany',
		city_ascii: 'Albany',
		lat: -35.0169466,
		lng: 117.8916048,
		pop: 25179,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Port Hedland',
		city_ascii: 'Port Hedland',
		lat: -20.31040241,
		lng: 118.6060315,
		pop: 8997,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Karratha',
		city_ascii: 'Karratha',
		lat: -20.73037677,
		lng: 116.8700134,
		pop: 16636,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Geraldton',
		city_ascii: 'Geraldton',
		lat: -28.76663043,
		lng: 114.5999711,
		pop: 27065,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Griffith',
		city_ascii: 'Griffith',
		lat: -34.29001422,
		lng: 146.0400158,
		pop: 11664.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Orange',
		city_ascii: 'Orange',
		lat: -33.27999835,
		lng: 149.0999841,
		pop: 36708,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Dubbo',
		city_ascii: 'Dubbo',
		lat: -32.25995726,
		lng: 148.5973274,
		pop: 30467.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Armidale',
		city_ascii: 'Armidale',
		lat: -30.51231199,
		lng: 151.667476,
		pop: 21793.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Broken Hill',
		city_ascii: 'Broken Hill',
		lat: -31.94995034,
		lng: 141.4331136,
		pop: 17232.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Broken_Hill',
	},
	{
		city: 'Port Lincoln',
		city_ascii: 'Port Lincoln',
		lat: -34.73324298,
		lng: 135.86658,
		pop: 12438.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Whyalla',
		city_ascii: 'Whyalla',
		lat: -33.02502684,
		lng: 137.5614119,
		pop: 21102,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Portland',
		city_ascii: 'Portland',
		lat: -38.33999957,
		lng: 141.5900032,
		pop: 10324.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Bendigo',
		city_ascii: 'Bendigo',
		lat: -36.75999266,
		lng: 144.2800199,
		pop: 68790,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Wangaratta',
		city_ascii: 'Wangaratta',
		lat: -36.36001707,
		lng: 146.3,
		pop: 11369.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Windorah',
		city_ascii: 'Windorah',
		lat: -25.43324217,
		lng: 142.6501969,
		pop: 158,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Mount Isa',
		city_ascii: 'Mount Isa',
		lat: -20.72386554,
		lng: 139.490028,
		pop: 27596,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Rockhampton',
		city_ascii: 'Rockhampton',
		lat: -23.36391111,
		lng: 150.5200008,
		pop: 59024.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Cairns',
		city_ascii: 'Cairns',
		lat: -16.88783986,
		lng: 145.7633309,
		pop: 132107,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Gold Coast',
		city_ascii: 'Gold Coast',
		lat: -28.08150429,
		lng: 153.4482458,
		pop: 429954.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Devonport',
		city_ascii: 'Devonport',
		lat: -41.19266757,
		lng: 146.3311017,
		pop: 17932.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Tasmania',
		timezone: 'Australia/Hobart',
	},
	{
		city: 'Darwin',
		city_ascii: 'Darwin',
		lat: -12.42535398,
		lng: 130.8500386,
		pop: 82973,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Northern Territory',
		timezone: 'Australia/Darwin',
	},
	{
		city: 'Alice Springs',
		city_ascii: 'Alice Springs',
		lat: -23.70099648,
		lng: 133.8800345,
		pop: 26949,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Northern Territory',
		timezone: 'Australia/Darwin',
	},
	{
		city: 'Canberra',
		city_ascii: 'Canberra',
		lat: -35.28302855,
		lng: 149.1290262,
		pop: 280866,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Australian Capital Territory',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Newcastle',
		city_ascii: 'Newcastle',
		lat: -32.84534788,
		lng: 151.8150122,
		pop: 816285.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Adelaide',
		city_ascii: 'Adelaide',
		lat: -34.93498777,
		lng: 138.6000048,
		pop: 990677,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'South Australia',
		timezone: 'Australia/Adelaide',
	},
	{
		city: 'Townsville',
		city_ascii: 'Townsville',
		lat: -19.24995034,
		lng: 146.7699971,
		pop: 129212,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Brisbane',
		city_ascii: 'Brisbane',
		lat: -27.45503091,
		lng: 153.0350927,
		pop: 1393176.5,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Queensland',
		timezone: 'Australia/Brisbane',
	},
	{
		city: 'Hobart',
		city_ascii: 'Hobart',
		lat: -42.85000853,
		lng: 147.2950297,
		pop: 64285,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Tasmania',
		timezone: 'Australia/Hobart',
	},
	{
		city: 'Perth',
		city_ascii: 'Perth',
		lat: -31.95501463,
		lng: 115.8399987,
		pop: 1206108,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Western Australia',
		timezone: 'Australia/Perth',
	},
	{
		city: 'Melbourne',
		city_ascii: 'Melbourne',
		lat: -37.82003131,
		lng: 144.9750162,
		pop: 4936000,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Sydney',
		city_ascii: 'Sydney',
		lat: -33.92001097,
		lng: 151.1851798,
		pop: 5230330,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'New South Wales',
		timezone: 'Australia/Sydney',
	},
	{
		city: 'Bregenz',
		city_ascii: 'Bregenz',
		lat: 47.51669707,
		lng: 9.766701588,
		pop: 26928,
		country: 'Austria',
		iso2: 'AT',
		iso3: 'AUT',
		province: 'Vorarlberg',
		timezone: 'Europe/Vienna',
	},
	{
		city: 'Eisenstadt',
		city_ascii: 'Eisenstadt',
		lat: 47.83329908,
		lng: 16.53329747,
		pop: 13165,
		country: 'Austria',
		iso2: 'AT',
		iso3: 'AUT',
		province: 'Burgenland',
		timezone: 'Europe/Vienna',
	},
	{
		city: 'Wiener Neustadt',
		city_ascii: 'Wiener Neustadt',
		lat: 47.81598187,
		lng: 16.24995357,
		pop: 60621.5,
		country: 'Austria',
		iso2: 'AT',
		iso3: 'AUT',
		province: 'Niederösterreich',
		timezone: 'Europe/Vienna',
	},
	{
		city: 'Graz',
		city_ascii: 'Graz',
		lat: 47.0777582,
		lng: 15.41000484,
		pop: 242780,
		country: 'Austria',
		iso2: 'AT',
		iso3: 'AUT',
		province: 'Steiermark',
		timezone: 'Europe/Vienna',
	},
	{
		city: 'Klagenfurt',
		city_ascii: 'Klagenfurt',
		lat: 46.62034426,
		lng: 14.3100203,
		pop: 88588,
		country: 'Austria',
		iso2: 'AT',
		iso3: 'AUT',
		province: 'Kärnten',
		timezone: 'Europe/Vienna',
	},
	{
		city: 'Linz',
		city_ascii: 'Linz',
		lat: 48.31923281,
		lng: 14.28878129,
		pop: 265161.5,
		country: 'Austria',
		iso2: 'AT',
		iso3: 'AUT',
		province: 'Oberösterreich',
		timezone: 'Europe/Vienna',
	},
	{
		city: 'Passau',
		city_ascii: 'Passau',
		lat: 48.56704714,
		lng: 13.46660925,
		pop: 50000,
		country: 'Austria',
		iso2: 'AT',
		iso3: 'AUT',
		province: 'Oberösterreich',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Salzburg',
		city_ascii: 'Salzburg',
		lat: 47.81047833,
		lng: 13.0400203,
		pop: 178274,
		country: 'Austria',
		iso2: 'AT',
		iso3: 'AUT',
		province: 'Salzburg',
		timezone: 'Europe/Vienna',
	},
	{
		city: 'Innsbruck',
		city_ascii: 'Innsbruck',
		lat: 47.28040733,
		lng: 11.4099906,
		pop: 133840.5,
		country: 'Austria',
		iso2: 'AT',
		iso3: 'AUT',
		province: 'Tirol',
		timezone: 'Europe/Vienna',
	},
	{
		city: 'Vienna',
		city_ascii: 'Vienna',
		lat: 48.20001528,
		lng: 16.36663896,
		pop: 2065500,
		country: 'Austria',
		iso2: 'AT',
		iso3: 'AUT',
		province: 'Wien',
		timezone: 'Europe/Vienna',
	},
	{
		city: 'Gadabay',
		city_ascii: 'Gadabay',
		lat: 40.5655556,
		lng: 45.8161111,
		pop: 8657,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'Gadabay',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Goranboy',
		city_ascii: 'Goranboy',
		lat: 40.6102778,
		lng: 46.7897222,
		pop: 7333,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'Goranboy',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Tovuz',
		city_ascii: 'Tovuz',
		lat: 40.9922222,
		lng: 45.6288889,
		pop: 12626,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'Tovuz',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Agdam',
		city_ascii: 'Agdam',
		lat: 40.9052778,
		lng: 45.5563889,
		pop: 0,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'Tovuz',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Qabala',
		city_ascii: 'Qabala',
		lat: 40.9813889,
		lng: 47.8458333,
		pop: 11867,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'Qabala',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Oguz',
		city_ascii: 'Oguz',
		lat: 41.0708333,
		lng: 47.4583333,
		pop: 6876,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'Oguz',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Ganca',
		city_ascii: 'Ganca',
		lat: 40.68499595,
		lng: 46.35002844,
		pop: 301699.5,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'Ganca',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Yevlax',
		city_ascii: 'Yevlax',
		lat: 40.61719647,
		lng: 47.15003129,
		pop: 50014,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'Yevlax',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Sumqayt',
		city_ascii: 'Sumqayt',
		lat: 40.58001528,
		lng: 49.62998328,
		pop: 272154.5,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'Sumqayit',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Ali Bayramli',
		city_ascii: 'Ali Bayramli',
		lat: 39.93230288,
		lng: 48.92025915,
		pop: 70452,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: '?li Bayramli',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Goycay',
		city_ascii: 'Goycay',
		lat: 40.65342165,
		lng: 47.74058956,
		pop: 35031.5,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'Göyçay',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Lankaran',
		city_ascii: 'Lankaran',
		lat: 38.7540027,
		lng: 48.85106441,
		pop: 60180,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'Astara',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Saki',
		city_ascii: 'Saki',
		lat: 41.19232932,
		lng: 47.17054683,
		pop: 63579.5,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'S?ki',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Stepanakert',
		city_ascii: 'Stepanakert',
		lat: 39.81564333,
		lng: 46.75196773,
		pop: 57473,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'Xocali',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Kapan',
		city_ascii: 'Kapan',
		lat: 39.20152061,
		lng: 46.41498572,
		pop: 37724,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'Z?ngilan',
		timezone: 'Asia/Yerevan',
	},
	{
		city: 'Naxcivan',
		city_ascii: 'Naxcivan',
		lat: 39.2092204,
		lng: 45.41220455,
		pop: 79771,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'Naxçivan',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Baku',
		city_ascii: 'Baku',
		lat: 40.39527203,
		lng: 49.86221716,
		pop: 2007150,
		country: 'Azerbaijan',
		iso2: 'AZ',
		iso3: 'AZE',
		province: 'Baki',
		timezone: 'Asia/Baku',
	},
	{
		city: 'Manama',
		city_ascii: 'Manama',
		lat: 26.23613629,
		lng: 50.58305172,
		pop: 360697,
		country: 'Bahrain',
		iso2: 'BH',
		iso3: 'BHR',
		province: '',
		timezone: 'Asia/Bahrain',
	},
	{
		city: 'Tangail',
		city_ascii: 'Tangail',
		lat: 24.24997845,
		lng: 89.92003048,
		pop: 180144,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Dhaka',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Sylhet',
		city_ascii: 'Sylhet',
		lat: 24.90355613,
		lng: 91.87360632,
		pop: 237000,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Sylhet',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Mymensingh',
		city_ascii: 'Mymensingh',
		lat: 24.75041302,
		lng: 90.3800024,
		pop: 330126,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Dhaka',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Jamalpur',
		city_ascii: 'Jamalpur',
		lat: 24.90042971,
		lng: 89.95000281,
		pop: 167900,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Dhaka',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Narayanganj',
		city_ascii: 'Narayanganj',
		lat: 23.62040448,
		lng: 90.49999508,
		pop: 223622,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Dhaka',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Jessore',
		city_ascii: 'Jessore',
		lat: 23.17043194,
		lng: 89.19997107,
		pop: 243987,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Khulna',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Barisal',
		city_ascii: 'Barisal',
		lat: 22.70040895,
		lng: 90.37498979,
		pop: 202242,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Barisal',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Comilla',
		city_ascii: 'Comilla',
		lat: 23.47041363,
		lng: 91.16998002,
		pop: 389411,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Chittagong',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Pabna',
		city_ascii: 'Pabna',
		lat: 24.00038129,
		lng: 89.24999385,
		pop: 137888,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Rajshahi',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Nawabganj',
		city_ascii: 'Nawabganj',
		lat: 24.58039756,
		lng: 88.34999711,
		pop: 142361,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Rajshahi',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Saidpur',
		city_ascii: 'Saidpur',
		lat: 25.80042645,
		lng: 88.99998328,
		pop: 232209,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Rajshahi',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Rangpur',
		city_ascii: 'Rangpur',
		lat: 25.75001609,
		lng: 89.28001786,
		pop: 285564,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Rajshahi',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Khulna',
		city_ascii: 'Khulna',
		lat: 22.839987,
		lng: 89.56000077,
		pop: 1447669.5,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Khulna',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Rajshahi',
		city_ascii: 'Rajshahi',
		lat: 24.37498374,
		lng: 88.6050203,
		pop: 755066.5,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Rajshahi',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Dhaka',
		city_ascii: 'Dhaka',
		lat: 23.72305971,
		lng: 90.40857947,
		pop: 9899167,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Dhaka',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Chittagong',
		city_ascii: 'Chittagong',
		lat: 22.32999229,
		lng: 91.79996741,
		pop: 4224611,
		country: 'Bangladesh',
		iso2: 'BD',
		iso3: 'BGD',
		province: 'Chittagong',
		timezone: 'Asia/Dhaka',
	},
	{
		city: 'Bridgetown',
		city_ascii: 'Bridgetown',
		lat: 13.10200258,
		lng: -59.61652674,
		pop: 143865,
		country: 'Barbados',
		iso2: 'BB',
		iso3: 'BRB',
		province: 'Saint Michael',
		timezone: 'America/Barbados',
	},
	{
		city: 'Baranavichy',
		city_ascii: 'Baranavichy',
		lat: 53.13684572,
		lng: 26.01344031,
		pop: 156514.5,
		country: 'Belarus',
		iso2: 'BY',
		iso3: 'BLR',
		province: 'Brest',
		timezone: 'Europe/Minsk',
	},
	{
		city: 'Polatsk',
		city_ascii: 'Polatsk',
		lat: 55.48938946,
		lng: 28.78598425,
		pop: 79216,
		country: 'Belarus',
		iso2: 'BY',
		iso3: 'BLR',
		province: 'Vitsyebsk',
		timezone: 'Europe/Minsk',
	},
	{
		city: 'Maladzyechna',
		city_ascii: 'Maladzyechna',
		lat: 54.31878908,
		lng: 26.86532629,
		pop: 96055,
		country: 'Belarus',
		iso2: 'BY',
		iso3: 'BLR',
		province: 'Minsk',
		timezone: 'Europe/Minsk',
	},
	{
		city: 'Pinsk',
		city_ascii: 'Pinsk',
		lat: 52.12786338,
		lng: 26.09405554,
		pop: 120838.5,
		country: 'Belarus',
		iso2: 'BY',
		iso3: 'BLR',
		province: 'Brest',
		timezone: 'Europe/Minsk',
	},
	{
		city: 'Mazyr',
		city_ascii: 'Mazyr',
		lat: 52.04595624,
		lng: 29.27215613,
		pop: 100936,
		country: 'Belarus',
		iso2: 'BY',
		iso3: 'BLR',
		province: 'Homyel',
		timezone: 'Europe/Minsk',
	},
	{
		city: 'Mahilyow',
		city_ascii: 'Mahilyow',
		lat: 53.89850466,
		lng: 30.32465002,
		pop: 343527,
		country: 'Belarus',
		iso2: 'BY',
		iso3: 'BLR',
		province: 'Mahilyow',
		timezone: 'Europe/Minsk',
	},
	{
		city: 'Babruysk',
		city_ascii: 'Babruysk',
		lat: 53.12656211,
		lng: 29.19278113,
		pop: 212821.5,
		country: 'Belarus',
		iso2: 'BY',
		iso3: 'BLR',
		province: 'Mahilyow',
		timezone: 'Europe/Minsk',
	},
	{
		city: 'Orsha',
		city_ascii: 'Orsha',
		lat: 54.51531455,
		lng: 30.42154333,
		pop: 130276.5,
		country: 'Belarus',
		iso2: 'BY',
		iso3: 'BLR',
		province: 'Vitsyebsk',
		timezone: 'Europe/Minsk',
	},
	{
		city: 'Lida',
		city_ascii: 'Lida',
		lat: 53.88847943,
		lng: 25.28464758,
		pop: 99126,
		country: 'Belarus',
		iso2: 'BY',
		iso3: 'BLR',
		province: 'Hrodna',
		timezone: 'Europe/Minsk',
	},
	{
		city: 'Hrodna',
		city_ascii: 'Hrodna',
		lat: 53.67787213,
		lng: 23.83409013,
		pop: 285867,
		country: 'Belarus',
		iso2: 'BY',
		iso3: 'BLR',
		province: 'Hrodna',
		timezone: 'Europe/Minsk',
	},
	{
		city: 'Barysaw',
		city_ascii: 'Barysaw',
		lat: 54.22600405,
		lng: 28.49215206,
		pop: 127694.5,
		country: 'Belarus',
		iso2: 'BY',
		iso3: 'BLR',
		province: 'Minsk',
		timezone: 'Europe/Minsk',
	},
	{
		city: 'Homyel',
		city_ascii: 'Homyel',
		lat: 52.43001548,
		lng: 31.00000932,
		pop: 472337.5,
		country: 'Belarus',
		iso2: 'BY',
		iso3: 'BLR',
		province: 'Homyel',
		timezone: 'Europe/Minsk',
	},
	{
		city: 'Vitsyebsk',
		city_ascii: 'Vitsyebsk',
		lat: 55.18871014,
		lng: 30.18533036,
		pop: 333318.5,
		country: 'Belarus',
		iso2: 'BY',
		iso3: 'BLR',
		province: 'Vitsyebsk',
		timezone: 'Europe/Minsk',
	},
	{
		city: 'Brest',
		city_ascii: 'Brest',
		lat: 52.09998395,
		lng: 23.69998979,
		pop: 266775,
		country: 'Belarus',
		iso2: 'BY',
		iso3: 'BLR',
		province: 'Brest',
		timezone: 'Europe/Minsk',
	},
	{
		city: 'Minsk',
		city_ascii: 'Minsk',
		lat: 53.89997744,
		lng: 27.56662716,
		pop: 1691069,
		country: 'Belarus',
		iso2: 'BY',
		iso3: 'BLR',
		province: 'Minsk',
		timezone: 'Europe/Minsk',
	},
	{
		city: 'Mons',
		city_ascii: 'Mons',
		lat: 50.44599911,
		lng: 3.939003561,
		pop: 91277,
		country: 'Belgium',
		iso2: 'BE',
		iso3: 'BEL',
		province: 'Hainaut',
		timezone: 'Europe/Brussels',
	},
	{
		city: 'Hasselt',
		city_ascii: 'Hasselt',
		lat: 50.96400317,
		lng: 5.483997561,
		pop: 69222,
		country: 'Belgium',
		iso2: 'BE',
		iso3: 'BEL',
		province: 'Limburg',
		timezone: 'Europe/Brussels',
	},
	{
		city: 'Arlon',
		city_ascii: 'Arlon',
		lat: 49.68330313,
		lng: 5.816700472,
		pop: 26179,
		country: 'Belgium',
		iso2: 'BE',
		iso3: 'BEL',
		province: 'Arlon',
		timezone: 'Europe/Brussels',
	},
	{
		city: 'Gent',
		city_ascii: 'Gent',
		lat: 51.02999758,
		lng: 3.700021931,
		pop: 337914.5,
		country: 'Belgium',
		iso2: 'BE',
		iso3: 'BEL',
		province: 'East Flanders',
		timezone: 'Europe/Brussels',
	},
	{
		city: 'Liege',
		city_ascii: 'Liege',
		lat: 50.62999615,
		lng: 5.580010537,
		pop: 472803,
		country: 'Belgium',
		iso2: 'BE',
		iso3: 'BEL',
		province: 'Liege',
		timezone: 'Europe/Brussels',
	},
	{
		city: 'Brugge',
		city_ascii: 'Brugge',
		lat: 51.22037355,
		lng: 3.230024779,
		pop: 131589,
		country: 'Belgium',
		iso2: 'BE',
		iso3: 'BEL',
		province: 'Brugge',
		timezone: 'Europe/Brussels',
	},
	{
		city: 'Namur',
		city_ascii: 'Namur',
		lat: 50.47039349,
		lng: 4.870028034,
		pop: 97155.5,
		country: 'Belgium',
		iso2: 'BE',
		iso3: 'BEL',
		province: 'Namur',
		timezone: 'Europe/Brussels',
	},
	{
		city: 'Charleroi',
		city_ascii: 'Charleroi',
		lat: 50.42039654,
		lng: 4.450001992,
		pop: 272749.5,
		country: 'Belgium',
		iso2: 'BE',
		iso3: 'BEL',
		province: 'Charleroi',
		timezone: 'Europe/Brussels',
	},
	{
		city: 'Antwerpen',
		city_ascii: 'Antwerpen',
		lat: 51.22037355,
		lng: 4.415017048,
		pop: 689902.5,
		country: 'Belgium',
		iso2: 'BE',
		iso3: 'BEL',
		province: 'Antwerp',
		timezone: 'Europe/Brussels',
	},
	{
		city: 'Brussels',
		city_ascii: 'Brussels',
		lat: 50.83331708,
		lng: 4.333316608,
		pop: 1381011,
		country: 'Belgium',
		iso2: 'BE',
		iso3: 'BEL',
		province: 'Brussels',
		timezone: 'Europe/Brussels',
	},
	{
		city: 'El Cayo',
		city_ascii: 'El Cayo',
		lat: 17.15599807,
		lng: -89.06100252,
		pop: 13451,
		country: 'Belize',
		iso2: 'BZ',
		iso3: 'BLZ',
		province: 'Cayo',
		timezone: 'America/Belize',
	},
	{
		city: 'Corozal',
		city_ascii: 'Corozal',
		lat: 18.39799811,
		lng: -88.3880005,
		pop: 8724,
		country: 'Belize',
		iso2: 'BZ',
		iso3: 'BLZ',
		province: 'Corozal',
		timezone: 'America/Belize',
	},
	{
		city: 'Dangriga',
		city_ascii: 'Dangriga',
		lat: 16.97003522,
		lng: -88.22000045,
		pop: 8506,
		country: 'Belize',
		iso2: 'BZ',
		iso3: 'BLZ',
		province: 'Stann Creek',
		timezone: 'America/Belize',
	},
	{
		city: 'Belize City',
		city_ascii: 'Belize City',
		lat: 17.49871096,
		lng: -88.18837447,
		pop: 62244.5,
		country: 'Belize',
		iso2: 'BZ',
		iso3: 'BLZ',
		province: 'Belize',
		timezone: 'America/Belize',
	},
	{
		city: 'Orange Walk',
		city_ascii: 'Orange Walk',
		lat: 18.09043194,
		lng: -88.5599797,
		pop: 18066.5,
		country: 'Belize',
		iso2: 'BZ',
		iso3: 'BLZ',
		province: 'Orange Walk',
		timezone: 'America/Belize',
	},
	{
		city: 'Punta Gorda',
		city_ascii: 'Punta Gorda',
		lat: 16.1003467,
		lng: -88.81001612,
		pop: 6387,
		country: 'Belize',
		iso2: 'BZ',
		iso3: 'BLZ',
		province: 'Toledo',
		timezone: 'America/Belize',
	},
	{
		city: 'Belmopan',
		city_ascii: 'Belmopan',
		lat: 17.25203351,
		lng: -88.767073,
		pop: 14300.5,
		country: 'Belize',
		iso2: 'BZ',
		iso3: 'BLZ',
		province: 'Cayo',
		timezone: 'America/Belize',
	},
	{
		city: 'Lokossa',
		city_ascii: 'Lokossa',
		lat: 6.615000092,
		lng: 1.715004457,
		pop: 86971,
		country: 'Benin',
		iso2: 'BJ',
		iso3: 'BEN',
		province: 'Mono',
		timezone: 'Africa/Porto-Novo',
	},
	{
		city: 'Kandi',
		city_ascii: 'Kandi',
		lat: 11.13036582,
		lng: 2.940016641,
		pop: 73483,
		country: 'Benin',
		iso2: 'BJ',
		iso3: 'BEN',
		province: 'Alibori',
		timezone: 'Africa/Porto-Novo',
	},
	{
		city: 'Ouidah',
		city_ascii: 'Ouidah',
		lat: 6.360372741,
		lng: 2.089991006,
		pop: 83503,
		country: 'Benin',
		iso2: 'BJ',
		iso3: 'BEN',
		province: 'Atlantique',
		timezone: 'Africa/Porto-Novo',
	},
	{
		city: 'Abomey',
		city_ascii: 'Abomey',
		lat: 7.190399596,
		lng: 1.98999711,
		pop: 82154,
		country: 'Benin',
		iso2: 'BJ',
		iso3: 'BEN',
		province: 'Zou',
		timezone: 'Africa/Porto-Novo',
	},
	{
		city: 'Natitingou',
		city_ascii: 'Natitingou',
		lat: 10.32041526,
		lng: 1.389982054,
		pop: 65356.5,
		country: 'Benin',
		iso2: 'BJ',
		iso3: 'BEN',
		province: 'Atakora',
		timezone: 'Africa/Porto-Novo',
	},
	{
		city: 'Djougou',
		city_ascii: 'Djougou',
		lat: 9.700427265,
		lng: 1.680041869,
		pop: 152708.5,
		country: 'Benin',
		iso2: 'BJ',
		iso3: 'BEN',
		province: 'Donga',
		timezone: 'Africa/Porto-Novo',
	},
	{
		city: 'Parakou',
		city_ascii: 'Parakou',
		lat: 9.340009988,
		lng: 2.620036172,
		pop: 176303,
		country: 'Benin',
		iso2: 'BJ',
		iso3: 'BEN',
		province: 'Borgou',
		timezone: 'Africa/Porto-Novo',
	},
	{
		city: 'Porto-Novo',
		city_ascii: 'Porto-Novo',
		lat: 6.483310973,
		lng: 2.616625528,
		pop: 267084,
		country: 'Benin',
		iso2: 'BJ',
		iso3: 'BEN',
		province: 'Ouémé',
		timezone: 'Africa/Porto-Novo',
	},
	{
		city: 'Cotonou',
		city_ascii: 'Cotonou',
		lat: 6.400008564,
		lng: 2.519990599,
		pop: 726292,
		country: 'Benin',
		iso2: 'BJ',
		iso3: 'BEN',
		province: 'Ouémé',
		timezone: 'Africa/Porto-Novo',
	},
	{
		city: 'Hamilton',
		city_ascii: 'Hamilton',
		lat: 32.29419029,
		lng: -64.78393742,
		pop: 32910,
		country: 'Bermuda',
		iso2: 'BM',
		iso3: 'BMU',
		province: '',
		timezone: 'Atlantic/Bermuda',
	},
	{
		city: 'Paro',
		city_ascii: 'Paro',
		lat: 27.3833011,
		lng: 89.51670065,
		pop: 15000,
		country: 'Bhutan',
		iso2: 'BT',
		iso3: 'BTN',
		province: 'Thimphu',
		timezone: 'Asia/Thimphu',
	},
	{
		city: 'Punakha',
		city_ascii: 'Punakha',
		lat: 27.63330305,
		lng: 89.83330266,
		pop: 5000,
		country: 'Bhutan',
		iso2: 'BT',
		iso3: 'BTN',
		province: 'Punakha',
		timezone: 'Asia/Thimphu',
	},
	{
		city: 'Wangdue Prodrang',
		city_ascii: 'Wangdue Prodrang',
		lat: 27.43329603,
		lng: 89.91669667,
		pop: 5000,
		country: 'Bhutan',
		iso2: 'BT',
		iso3: 'BTN',
		province: 'Wangdi Phodrang',
		timezone: 'Asia/Thimphu',
	},
	{
		city: 'Thimphu',
		city_ascii: 'Thimphu',
		lat: 27.47298586,
		lng: 89.63901404,
		pop: 88930.5,
		country: 'Bhutan',
		iso2: 'BT',
		iso3: 'BTN',
		province: 'Thimphu',
		timezone: 'Asia/Thimphu',
	},
	{
		city: 'Punata',
		city_ascii: 'Punata',
		lat: -17.55000242,
		lng: -65.83997115,
		pop: 20758.5,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Cochabamba',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Cliza',
		city_ascii: 'Cliza',
		lat: -17.58999998,
		lng: -65.9299915,
		pop: 12235,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Cochabamba',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Quillacollo',
		city_ascii: 'Quillacollo',
		lat: -17.39998574,
		lng: -66.27999597,
		pop: 227052,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Cochabamba',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Puerto Villarroel',
		city_ascii: 'Puerto Villarroel',
		lat: -16.87004393,
		lng: -64.78001001,
		pop: 1778,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Cochabamba',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Tarabuco',
		city_ascii: 'Tarabuco',
		lat: -19.18003213,
		lng: -64.91994979,
		pop: 2364,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Chuquisaca',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Guayaramerin',
		city_ascii: 'Guayaramerin',
		lat: -10.82999917,
		lng: -65.4099974,
		pop: 36008,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'El Beni',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Santa Ana',
		city_ascii: 'Santa Ana',
		lat: -13.7600012,
		lng: -65.57996118,
		pop: 234478,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'El Beni',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Baures',
		city_ascii: 'Baures',
		lat: -13.58001219,
		lng: -63.58005742,
		pop: 2422,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'El Beni',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Sica Sica',
		city_ascii: 'Sica Sica',
		lat: -17.33001585,
		lng: -67.72998499,
		pop: 1006,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'La Paz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Rurrenabaque',
		city_ascii: 'Rurrenabaque',
		lat: -14.46001015,
		lng: -67.55999536,
		pop: 11749,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'La Paz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Sorata',
		city_ascii: 'Sorata',
		lat: -15.79000649,
		lng: -68.66005742,
		pop: 2190,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'La Paz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Achacachi',
		city_ascii: 'Achacachi',
		lat: -16.08332192,
		lng: -68.66656865,
		pop: 8447,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'La Paz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Viacha',
		city_ascii: 'Viacha',
		lat: -16.65000568,
		lng: -68.2999502,
		pop: 34776,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'La Paz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Quime',
		city_ascii: 'Quime',
		lat: -16.98001137,
		lng: -67.22001612,
		pop: 3224.5,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'La Paz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Llallagua',
		city_ascii: 'Llallagua',
		lat: -18.42002684,
		lng: -66.63999984,
		pop: 28069,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Potosí',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Uncia',
		city_ascii: 'Uncia',
		lat: -18.46999795,
		lng: -66.57002995,
		pop: 4723,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Potosí',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Uyuni',
		city_ascii: 'Uyuni',
		lat: -20.46000568,
		lng: -66.82998824,
		pop: 11616,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Potosí',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Villa Martin',
		city_ascii: 'Villa Martin',
		lat: -20.76655027,
		lng: -67.7833409,
		pop: 10,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Potosí',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Betanzos',
		city_ascii: 'Betanzos',
		lat: -19.55995726,
		lng: -65.45002079,
		pop: 4629.5,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Potosí',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Portachuelo',
		city_ascii: 'Portachuelo',
		lat: -17.36003986,
		lng: -63.40001673,
		pop: 9982,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Samaipata',
		city_ascii: 'Samaipata',
		lat: -18.18004148,
		lng: -63.77001998,
		pop: 2926,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Cuevo',
		city_ascii: 'Cuevo',
		lat: -20.45003213,
		lng: -63.52998295,
		pop: 953,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'San Carlos',
		city_ascii: 'San Carlos',
		lat: -17.39998574,
		lng: -63.72999658,
		pop: 5266.5,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'San Lorenzo',
		city_ascii: 'San Lorenzo',
		lat: -21.47994342,
		lng: -64.77001062,
		pop: 3000,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Tarija',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Entre Rios',
		city_ascii: 'Entre Rios',
		lat: -21.53001788,
		lng: -64.18999435,
		pop: 2685,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Tarija',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Aiquile',
		city_ascii: 'Aiquile',
		lat: -18.18954995,
		lng: -65.18001144,
		pop: 5844,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Cochabamba',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Padilla',
		city_ascii: 'Padilla',
		lat: -19.29961139,
		lng: -64.30996118,
		pop: 2276.5,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Chuquisaca',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Camargo',
		city_ascii: 'Camargo',
		lat: -20.63958128,
		lng: -65.20998377,
		pop: 4715,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Chuquisaca',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Reyes',
		city_ascii: 'Reyes',
		lat: -14.30958006,
		lng: -67.37000696,
		pop: 7376,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'El Beni',
		timezone: 'America/La_Paz',
	},
	{
		city: 'San Borja',
		city_ascii: 'San Borja',
		lat: -14.81962645,
		lng: -66.84996118,
		pop: 19640,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'El Beni',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Magdalena',
		city_ascii: 'Magdalena',
		lat: -13.2600834,
		lng: -64.05276758,
		pop: 3445,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'El Beni',
		timezone: 'America/La_Paz',
	},
	{
		city: 'San Ramon',
		city_ascii: 'San Ramon',
		lat: -13.28959064,
		lng: -64.70998845,
		pop: 5439.5,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'El Beni',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Puerto Heath',
		city_ascii: 'Puerto Heath',
		lat: -12.49961302,
		lng: -68.66656865,
		pop: 10,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'La Paz',
		timezone: 'America/Lima',
	},
	{
		city: 'Charana',
		city_ascii: 'Charana',
		lat: -17.5996118,
		lng: -69.46659733,
		pop: 197,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'La Paz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Puerto Acosta',
		city_ascii: 'Puerto Acosta',
		lat: -15.49958494,
		lng: -69.16666732,
		pop: 1123,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'La Paz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Apolo',
		city_ascii: 'Apolo',
		lat: -14.71958087,
		lng: -68.41999455,
		pop: 4189,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'La Paz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Coroico',
		city_ascii: 'Coroico',
		lat: -16.18962034,
		lng: -67.72001144,
		pop: 2361,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'La Paz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Coro Coro',
		city_ascii: 'Coro Coro',
		lat: -17.1696122,
		lng: -68.44996688,
		pop: 1884,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'La Paz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Sabaya',
		city_ascii: 'Sabaya',
		lat: -19.01626951,
		lng: -68.38327844,
		pop: 573,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Oruro',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Challapata',
		city_ascii: 'Challapata',
		lat: -18.89958413,
		lng: -66.77999129,
		pop: 8565,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Oruro',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Llica',
		city_ascii: 'Llica',
		lat: -19.84960366,
		lng: -68.25003076,
		pop: 553,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Potosí',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Potosi',
		city_ascii: 'Potosi',
		lat: -19.56956907,
		lng: -65.75002832,
		pop: 160576,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Potosí',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Villazon',
		city_ascii: 'Villazon',
		lat: -22.07959674,
		lng: -65.5999858,
		pop: 33734,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Potosí',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Tupiza',
		city_ascii: 'Tupiza',
		lat: -21.43958413,
		lng: -65.72000431,
		pop: 25499.5,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Potosí',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Montero',
		city_ascii: 'Montero',
		lat: -17.34960122,
		lng: -63.26002527,
		pop: 83821,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Piso Firme',
		city_ascii: 'Piso Firme',
		lat: -13.68295164,
		lng: -61.86662195,
		pop: 72,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Robore',
		city_ascii: 'Robore',
		lat: -18.3295414,
		lng: -59.75998051,
		pop: 9959,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Puerto Quijarro',
		city_ascii: 'Puerto Quijarro',
		lat: -17.77960081,
		lng: -57.77002446,
		pop: 10392,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'San Ignacio',
		city_ascii: 'San Ignacio',
		lat: -16.36960936,
		lng: -60.96001062,
		pop: 24480,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Ascension',
		city_ascii: 'Ascension',
		lat: -15.69957273,
		lng: -63.07998458,
		pop: 12085,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'San Javier',
		city_ascii: 'San Javier',
		lat: -16.28961424,
		lng: -62.50001998,
		pop: 4210,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'San Rafael',
		city_ascii: 'San Rafael',
		lat: -16.77950682,
		lng: -60.6799502,
		pop: 1201,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Vallegrande',
		city_ascii: 'Vallegrande',
		lat: -18.48958331,
		lng: -64.10999923,
		pop: 6857.5,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Puerto Suarez',
		city_ascii: 'Puerto Suarez',
		lat: -18.94955524,
		lng: -57.8499679,
		pop: 17273,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Charagua',
		city_ascii: 'Charagua',
		lat: -19.79958087,
		lng: -63.2199502,
		pop: 3025,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Villamontes',
		city_ascii: 'Villamontes',
		lat: -21.24956989,
		lng: -63.50001062,
		pop: 18761,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Tarija',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Bermejo',
		city_ascii: 'Bermejo',
		lat: -22.72958291,
		lng: -64.34998458,
		pop: 36544,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Tarija',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Cochabamba',
		city_ascii: 'Cochabamba',
		lat: -17.41001097,
		lng: -66.16997685,
		pop: 804138,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Cochabamba',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Oruro',
		city_ascii: 'Oruro',
		lat: -17.97995034,
		lng: -67.12999577,
		pop: 227592.5,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Oruro',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Camiri',
		city_ascii: 'Camiri',
		lat: -20.05000486,
		lng: -63.51998356,
		pop: 19212.5,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Cobija',
		city_ascii: 'Cobija',
		lat: -11.03334593,
		lng: -68.73330876,
		pop: 35511,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Pando',
		timezone: 'America/Rio_Branco',
	},
	{
		city: 'San Matias',
		city_ascii: 'San Matias',
		lat: -16.35999754,
		lng: -58.42001062,
		pop: 6352,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'San José',
		city_ascii: 'San Jose',
		lat: -17.85003579,
		lng: -60.77999577,
		pop: 9211,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Trinidad',
		city_ascii: 'Trinidad',
		lat: -14.83337238,
		lng: -64.89997685,
		pop: 69333.5,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'El Beni',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Tarija',
		city_ascii: 'Tarija',
		lat: -21.51668537,
		lng: -64.749986,
		pop: 155513,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Tarija',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Sucre',
		city_ascii: 'Sucre',
		lat: -19.04097085,
		lng: -65.25951563,
		pop: 223287,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Chuquisaca',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Riberalta',
		city_ascii: 'Riberalta',
		lat: -10.98301308,
		lng: -66.10000696,
		pop: 74014,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'El Beni',
		timezone: 'America/La_Paz',
	},
	{
		city: 'La Paz',
		city_ascii: 'La Paz',
		lat: -16.49797361,
		lng: -68.14998519,
		pop: 1201399.5,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'La Paz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Santa Cruz',
		city_ascii: 'Santa Cruz',
		lat: -17.75391762,
		lng: -63.22599634,
		pop: 1859530.5,
		country: 'Bolivia',
		iso2: 'BO',
		iso3: 'BOL',
		province: 'Santa Cruz',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Zenica',
		city_ascii: 'Zenica',
		lat: 44.21997398,
		lng: 17.91998083,
		pop: 151388,
		country: 'Bosnia and Herzegovina',
		iso2: 'BA',
		iso3: 'BIH',
		province: 'Zenica-Doboj',
		timezone: 'Europe/Sarajevo',
	},
	{
		city: 'Mostar',
		city_ascii: 'Mostar',
		lat: 43.35049217,
		lng: 17.82003861,
		pop: 133792.5,
		country: 'Bosnia and Herzegovina',
		iso2: 'BA',
		iso3: 'BIH',
		province: 'Herzegovina-Neretva',
		timezone: 'Europe/Sarajevo',
	},
	{
		city: 'Tuzla',
		city_ascii: 'Tuzla',
		lat: 44.5504706,
		lng: 18.6800378,
		pop: 143410,
		country: 'Bosnia and Herzegovina',
		iso2: 'BA',
		iso3: 'BIH',
		province: 'Tuzla',
		timezone: 'Europe/Sarajevo',
	},
	{
		city: 'Prijedor',
		city_ascii: 'Prijedor',
		lat: 44.98039268,
		lng: 16.70000362,
		pop: 70602.5,
		country: 'Bosnia and Herzegovina',
		iso2: 'BA',
		iso3: 'BIH',
		province: 'Serbian Republic',
		timezone: 'Europe/Sarajevo',
	},
	{
		city: 'Banja Luka',
		city_ascii: 'Banja Luka',
		lat: 44.78040489,
		lng: 17.17997432,
		pop: 221422,
		country: 'Bosnia and Herzegovina',
		iso2: 'BA',
		iso3: 'BIH',
		province: 'Serbian Republic',
		timezone: 'Europe/Sarajevo',
	},
	{
		city: 'Sarajevo',
		city_ascii: 'Sarajevo',
		lat: 43.8500224,
		lng: 18.38300167,
		pop: 662816.5,
		country: 'Bosnia and Herzegovina',
		iso2: 'BA',
		iso3: 'BIH',
		province: 'Sarajevo',
		timezone: 'Europe/Sarajevo',
	},
	{
		city: 'Mochudi',
		city_ascii: 'Mochudi',
		lat: -24.377004,
		lng: 26.15200256,
		pop: 39700,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'Kgatleng',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Ghanzi',
		city_ascii: 'Ghanzi',
		lat: -21.69961994,
		lng: 21.63996049,
		pop: 6306,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'Ghanzi',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Lokhwabe',
		city_ascii: 'Lokhwabe',
		lat: -24.16959837,
		lng: 21.83002641,
		pop: 1473,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'Kgalagadi',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Lehututu',
		city_ascii: 'Lehututu',
		lat: -23.96961058,
		lng: 21.87002397,
		pop: 1942,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'Kgalagadi',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Tshabong',
		city_ascii: 'Tshabong',
		lat: -26.00948606,
		lng: 22.40001745,
		pop: 9679,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'Kgalagadi',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Tsau',
		city_ascii: 'Tsau',
		lat: -20.15961058,
		lng: 22.45996212,
		pop: 1409,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'North-West',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Nokaneng',
		city_ascii: 'Nokaneng',
		lat: -19.66961465,
		lng: 22.26999955,
		pop: 1763,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'North-West',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Mohembo',
		city_ascii: 'Mohembo',
		lat: -18.29956907,
		lng: 21.8000024,
		pop: 757,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'North-West',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Maun',
		city_ascii: 'Maun',
		lat: -19.98959511,
		lng: 23.42000688,
		pop: 47059,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'North-West',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Kasane',
		city_ascii: 'Kasane',
		lat: -17.80957314,
		lng: 25.15003048,
		pop: 7774.5,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'North-West',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Nata',
		city_ascii: 'Nata',
		lat: -20.20947833,
		lng: 26.19001868,
		pop: 2492.5,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'Central',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Mopipi',
		city_ascii: 'Mopipi',
		lat: -21.17954832,
		lng: 24.88002112,
		pop: 3301,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'Central',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Palapye',
		city_ascii: 'Palapye',
		lat: -22.55961912,
		lng: 27.13001298,
		pop: 27179,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'Central',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Lobatse',
		city_ascii: 'Lobatse',
		lat: -25.2196118,
		lng: 25.68002397,
		pop: 50343.5,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'South-East',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Kanye',
		city_ascii: 'Kanye',
		lat: -24.96960122,
		lng: 25.33999304,
		pop: 45773.5,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'Southern',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Molepolole',
		city_ascii: 'Molepolole',
		lat: -24.3999719,
		lng: 25.5100085,
		pop: 57713,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'Kweneng',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Francistown',
		city_ascii: 'Francistown',
		lat: -21.17003986,
		lng: 27.50001623,
		pop: 89179.5,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'Central',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Mahalapye',
		city_ascii: 'Mahalapye',
		lat: -23.09999957,
		lng: 26.82000606,
		pop: 47607.5,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'Central',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Serowe',
		city_ascii: 'Serowe',
		lat: -22.39001707,
		lng: 26.71003861,
		pop: 47996,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'Central',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Gaborone',
		city_ascii: 'Gaborone',
		lat: -24.64631346,
		lng: 25.91194779,
		pop: 183827,
		country: 'Botswana',
		iso2: 'BW',
		iso3: 'BWA',
		province: 'South-East',
		timezone: 'Africa/Gaborone',
	},
	{
		city: 'Grajau',
		city_ascii: 'Grajau',
		lat: -5.809995505,
		lng: -46.14998438,
		pop: 30217,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Presidente Dutra',
		city_ascii: 'Presidente Dutra',
		lat: -5.250029685,
		lng: -44.51998051,
		pop: 30330,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Itapecuru Mirim',
		city_ascii: 'Itapecuru Mirim',
		lat: -3.400013409,
		lng: -44.36001611,
		pop: 22347,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Sao Jose de Ribamar',
		city_ascii: 'Sao Jose de Ribamar',
		lat: -2.549987774,
		lng: -44.06998214,
		pop: 41521,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Santa Ines',
		city_ascii: 'Santa Ines',
		lat: -3.659997539,
		lng: -45.39003076,
		pop: 58511.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Rosario',
		city_ascii: 'Rosario',
		lat: -2.940041485,
		lng: -44.26002222,
		pop: 6798,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Timon',
		city_ascii: 'Timon',
		lat: -5.114999167,
		lng: -42.84496647,
		pop: 203157,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Capanema',
		city_ascii: 'Capanema',
		lat: -1.190019105,
		lng: -47.17999903,
		pop: 45831,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Portel',
		city_ascii: 'Portel',
		lat: -1.949972718,
		lng: -50.81998356,
		pop: 10855,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Itupiranga',
		city_ascii: 'Itupiranga',
		lat: -5.120011781,
		lng: -49.30002466,
		pop: 21301,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Pimenta Bueno',
		city_ascii: 'Pimenta Bueno',
		lat: -11.64002724,
		lng: -61.20999536,
		pop: 25762,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rondônia',
		timezone: 'America/Porto_Velho',
	},
	{
		city: 'Ponta Pora',
		city_ascii: 'Ponta Pora',
		lat: -22.53000853,
		lng: -55.7299681,
		pop: 75047,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso do Sul',
		timezone: 'America/Campo_Grande',
	},
	{
		city: 'Maracaju',
		city_ascii: 'Maracaju',
		lat: -21.610013,
		lng: -55.18002751,
		pop: 18156,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso do Sul',
		timezone: 'America/Campo_Grande',
	},
	{
		city: 'Jardim',
		city_ascii: 'Jardim',
		lat: -21.47994342,
		lng: -56.15001998,
		pop: 21252.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso do Sul',
		timezone: 'America/Campo_Grande',
	},
	{
		city: 'Tres Lagoas',
		city_ascii: 'Tres Lagoas',
		lat: -20.79001137,
		lng: -51.72000615,
		pop: 64217.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso do Sul',
		timezone: 'America/Campo_Grande',
	},
	{
		city: 'Guanhaes',
		city_ascii: 'Guanhaes',
		lat: -18.78000486,
		lng: -42.95002466,
		pop: 16761.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Leopoldina',
		city_ascii: 'Leopoldina',
		lat: -21.53001788,
		lng: -42.64004358,
		pop: 37412,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Nova Lima',
		city_ascii: 'Nova Lima',
		lat: -19.98003497,
		lng: -43.8500214,
		pop: 60413.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Pouso Alegre',
		city_ascii: 'Pouso Alegre',
		lat: -22.22000161,
		lng: -45.94002303,
		pop: 102517.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Itauna',
		city_ascii: 'Itauna',
		lat: -20.06003009,
		lng: -44.57002914,
		pop: 70233,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Caratinga',
		city_ascii: 'Caratinga',
		lat: -19.79002073,
		lng: -42.13999658,
		pop: 47517.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Diamantina',
		city_ascii: 'Diamantina',
		lat: -18.23998615,
		lng: -43.60998438,
		pop: 25184.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Nanuque',
		city_ascii: 'Nanuque',
		lat: -17.83995888,
		lng: -40.35002832,
		pop: 27210.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Barbacena',
		city_ascii: 'Barbacena',
		lat: -21.22001097,
		lng: -43.77000045,
		pop: 101628.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Pocos de Caldas',
		city_ascii: 'Pocos de Caldas',
		lat: -21.78002846,
		lng: -46.56998458,
		pop: 125498.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Guaxupe',
		city_ascii: 'Guaxupe',
		lat: -21.29003253,
		lng: -46.7099502,
		pop: 43379.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Sao Joao del Rei',
		city_ascii: 'Sao Joao del Rei',
		lat: -21.1300423,
		lng: -44.24999699,
		pop: 68731.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Muriae',
		city_ascii: 'Muriae',
		lat: -21.1300423,
		lng: -42.38998132,
		pop: 76728.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Passos',
		city_ascii: 'Passos',
		lat: -20.71001626,
		lng: -46.60998214,
		pop: 85136.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Conselheiro Lafaiete',
		city_ascii: 'Conselheiro Lafaiete',
		lat: -20.6700187,
		lng: -43.78999923,
		pop: 102926,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Formiga',
		city_ascii: 'Formiga',
		lat: -20.46000568,
		lng: -45.43002832,
		pop: 46076.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Frutal',
		city_ascii: 'Frutal',
		lat: -20.03000608,
		lng: -48.94002079,
		pop: 26797,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Iturama',
		city_ascii: 'Iturama',
		lat: -19.72997272,
		lng: -50.2000214,
		pop: 21048.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Ituiutaba',
		city_ascii: 'Ituiutaba',
		lat: -18.97001911,
		lng: -49.45998906,
		pop: 63978,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Araguari',
		city_ascii: 'Araguari',
		lat: -18.64001341,
		lng: -48.19998845,
		pop: 79910.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Almenara',
		city_ascii: 'Almenara',
		lat: -16.17003497,
		lng: -40.70000696,
		pop: 22173.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Varzea Grande',
		city_ascii: 'Varzea Grande',
		lat: -15.65001504,
		lng: -56.14002059,
		pop: 242088,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso',
		timezone: 'America/Cuiaba',
	},
	{
		city: 'Cáceres',
		city_ascii: 'Caceres',
		lat: -16.0500423,
		lng: -57.51001449,
		pop: 85274,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso',
		timezone: 'America/Cuiaba',
	},
	{
		city: 'Santana do Livramento',
		city_ascii: 'Santana do Livramento',
		lat: -30.88004148,
		lng: -55.53000615,
		pop: 87312,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Canoas',
		city_ascii: 'Canoas',
		lat: -29.91999673,
		lng: -51.17998743,
		pop: 466661,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Quarai',
		city_ascii: 'Quarai',
		lat: -30.38002033,
		lng: -56.45994938,
		pop: 18462,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Santa Vitoria do Palmar',
		city_ascii: 'Santa Vitoria do Palmar',
		lat: -33.52003538,
		lng: -53.36998295,
		pop: 21826,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Sao Lourenco do Sul',
		city_ascii: 'Sao Lourenco do Sul',
		lat: -31.36998574,
		lng: -51.98001611,
		pop: 21673,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Canela',
		city_ascii: 'Canela',
		lat: -29.36003091,
		lng: -50.81001001,
		pop: 47167,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Sao Gabriel',
		city_ascii: 'Sao Gabriel',
		lat: -30.32002399,
		lng: -54.32002832,
		pop: 41849.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Rosario do Sul',
		city_ascii: 'Rosario do Sul',
		lat: -30.25000242,
		lng: -54.92001754,
		pop: 28596.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Cachoeira do Sul',
		city_ascii: 'Cachoeira do Sul',
		lat: -30.02996417,
		lng: -52.90998519,
		pop: 61871,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Osorio',
		city_ascii: 'Osorio',
		lat: -29.87999917,
		lng: -50.26999129,
		pop: 30882,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Santa Cruz do Sul',
		city_ascii: 'Santa Cruz do Sul',
		lat: -29.71003538,
		lng: -52.44003972,
		pop: 109869,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Sao Luiz Gonzaga',
		city_ascii: 'Sao Luiz Gonzaga',
		lat: -28.41001137,
		lng: -54.95998926,
		pop: 27798.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Santo Angelo',
		city_ascii: 'Santo Angelo',
		lat: -28.30004393,
		lng: -54.28003076,
		pop: 65013,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Carazinho',
		city_ascii: 'Carazinho',
		lat: -28.2900187,
		lng: -52.80004358,
		pop: 49145,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Erechim',
		city_ascii: 'Erechim',
		lat: -27.63000731,
		lng: -52.26999841,
		pop: 85365.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Guaira',
		city_ascii: 'Guaira',
		lat: -24.08996499,
		lng: -54.2699797,
		pop: 28897.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Palmas',
		city_ascii: 'Palmas',
		lat: -26.47999998,
		lng: -51.99998906,
		pop: 29794.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Arapongas',
		city_ascii: 'Arapongas',
		lat: -23.41000649,
		lng: -51.43004968,
		pop: 91203.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Paranagua',
		city_ascii: 'Paranagua',
		lat: -25.52787556,
		lng: -48.53445345,
		pop: 135071,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Sao Jose dos Pinhais',
		city_ascii: 'Sao Jose dos Pinhais',
		lat: -25.57002968,
		lng: -49.18000615,
		pop: 472180,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Guarapuava',
		city_ascii: 'Guarapuava',
		lat: -25.38001544,
		lng: -51.48002079,
		pop: 123381.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Rio Negro',
		city_ascii: 'Rio Negro',
		lat: -26.10002317,
		lng: -49.79002059,
		pop: 44301.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Apucarana',
		city_ascii: 'Apucarana',
		lat: -23.54999795,
		lng: -51.4700214,
		pop: 102577,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Lapa',
		city_ascii: 'Lapa',
		lat: -25.76004393,
		lng: -49.72999841,
		pop: 19934.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Irati',
		city_ascii: 'Irati',
		lat: -25.47003579,
		lng: -50.65996749,
		pop: 41602,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Castro',
		city_ascii: 'Castro',
		lat: -24.79002562,
		lng: -50.00998132,
		pop: 38417.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Telemaco Borba',
		city_ascii: 'Telemaco Borba',
		lat: -24.32995034,
		lng: -50.61999577,
		pop: 53793.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Jacarezinho',
		city_ascii: 'Jacarezinho',
		lat: -23.15994424,
		lng: -49.97998316,
		pop: 33551,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Concordia',
		city_ascii: 'Concordia',
		lat: -27.23003172,
		lng: -52.03001306,
		pop: 46124.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Blumenau',
		city_ascii: 'Blumenau',
		lat: -26.9200248,
		lng: -49.0899858,
		pop: 286326,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Brusque',
		city_ascii: 'Brusque',
		lat: -27.12998615,
		lng: -48.9300214,
		pop: 81163.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Ararangua',
		city_ascii: 'Ararangua',
		lat: -28.94000486,
		lng: -49.49998661,
		pop: 42198.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Jaragua do Sul',
		city_ascii: 'Jaragua do Sul',
		lat: -26.47999998,
		lng: -49.09998519,
		pop: 128803.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Tubarao',
		city_ascii: 'Tubarao',
		lat: -28.48003294,
		lng: -49.02001591,
		pop: 79760,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Laguna',
		city_ascii: 'Laguna',
		lat: -28.48003294,
		lng: -48.77997888,
		pop: 32532,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Joacaba',
		city_ascii: 'Joacaba',
		lat: -27.17003538,
		lng: -51.4999679,
		pop: 31196,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Cacador',
		city_ascii: 'Cacador',
		lat: -26.77000812,
		lng: -51.02002303,
		pop: 57155.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Canoinhas',
		city_ascii: 'Canoinhas',
		lat: -26.17996662,
		lng: -50.4000092,
		pop: 40180,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Camocim',
		city_ascii: 'Camocim',
		lat: -2.89999225,
		lng: -40.85002364,
		pop: 27794.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Russas',
		city_ascii: 'Russas',
		lat: -4.940022767,
		lng: -37.97999211,
		pop: 33613.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Sobral',
		city_ascii: 'Sobral',
		lat: -3.690021547,
		lng: -40.35002832,
		pop: 148439.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Iguatu',
		city_ascii: 'Iguatu',
		lat: -6.359987774,
		lng: -39.29998906,
		pop: 56638,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Quixada',
		city_ascii: 'Quixada',
		lat: -4.969995098,
		lng: -39.02000615,
		pop: 30723.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Caninde',
		city_ascii: 'Caninde',
		lat: -4.35003294,
		lng: -39.30998845,
		pop: 32085,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Campo Maior',
		city_ascii: 'Campo Maior',
		lat: -4.820030091,
		lng: -42.18001998,
		pop: 24089.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Piauí',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Barras',
		city_ascii: 'Barras',
		lat: -4.250039043,
		lng: -42.29998682,
		pop: 11951.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Piauí',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Rio Largo',
		city_ascii: 'Rio Largo',
		lat: -9.48000405,
		lng: -35.83996769,
		pop: 110966,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Alagoas',
		timezone: 'America/Maceio',
	},
	{
		city: 'Palmeira dos Indios',
		city_ascii: 'Palmeira dos Indios',
		lat: -9.416597067,
		lng: -36.61663863,
		pop: 41095,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Alagoas',
		timezone: 'America/Maceio',
	},
	{
		city: 'Santa Cruz Cabralia',
		city_ascii: 'Santa Cruz Cabralia',
		lat: -16.28000242,
		lng: -39.0299797,
		pop: 9980.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Paulo Afonso',
		city_ascii: 'Paulo Afonso',
		lat: -9.330659161,
		lng: -38.26565943,
		pop: 85350,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Brumado',
		city_ascii: 'Brumado',
		lat: -14.20999957,
		lng: -41.67002527,
		pop: 29300,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Jaguaquara',
		city_ascii: 'Jaguaquara',
		lat: -13.5299894,
		lng: -39.96999984,
		pop: 30554.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Itapetinga',
		city_ascii: 'Itapetinga',
		lat: -15.24998777,
		lng: -40.24998275,
		pop: 43224,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Ubaitaba',
		city_ascii: 'Ubaitaba',
		lat: -14.30001992,
		lng: -39.33001306,
		pop: 27411.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Cachoeiro de Itapemirim',
		city_ascii: 'Cachoeiro de Itapemirim',
		lat: -20.85000771,
		lng: -41.12998071,
		pop: 174808.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Espírito Santo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Barra Mansa',
		city_ascii: 'Barra Mansa',
		lat: -22.56003253,
		lng: -44.1699502,
		pop: 166719,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio de Janeiro',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Nova Iguacu',
		city_ascii: 'Nova Iguacu',
		lat: -22.74002155,
		lng: -43.46996708,
		pop: 844583,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio de Janeiro',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Duque de Caxias',
		city_ascii: 'Duque de Caxias',
		lat: -22.76999388,
		lng: -43.30997685,
		pop: 842890,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio de Janeiro',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Niteroi',
		city_ascii: 'Niteroi',
		lat: -22.90001178,
		lng: -43.09998967,
		pop: 993920,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio de Janeiro',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Cabo Frio',
		city_ascii: 'Cabo Frio',
		lat: -22.88998655,
		lng: -42.03997685,
		pop: 184980,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio de Janeiro',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Macae',
		city_ascii: 'Macae',
		lat: -22.37999184,
		lng: -41.78999211,
		pop: 133083,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio de Janeiro',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Miracema',
		city_ascii: 'Miracema',
		lat: -21.41002521,
		lng: -42.19996708,
		pop: 22564.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio de Janeiro',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Apodi',
		city_ascii: 'Apodi',
		lat: -5.650005271,
		lng: -37.80000309,
		pop: 8586,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Norte',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Santa Cruz',
		city_ascii: 'Santa Cruz',
		lat: -6.219996319,
		lng: -36.02998194,
		pop: 22003.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Norte',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Morrinhos',
		city_ascii: 'Morrinhos',
		lat: -17.73004311,
		lng: -49.10998458,
		pop: 27841,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Ceres',
		city_ascii: 'Ceres',
		lat: -15.30331785,
		lng: -49.60519983,
		pop: 18658.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Catalao',
		city_ascii: 'Catalao',
		lat: -18.18004148,
		lng: -47.9500037,
		pop: 53646.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Cristalina',
		city_ascii: 'Cristalina',
		lat: -16.76999835,
		lng: -47.61002446,
		pop: 25627.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Trindade',
		city_ascii: 'Trindade',
		lat: -16.65000568,
		lng: -49.49998661,
		pop: 93113,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Ipora',
		city_ascii: 'Ipora',
		lat: -16.45001788,
		lng: -51.12999048,
		pop: 23354,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Inhumas',
		city_ascii: 'Inhumas',
		lat: -16.35999754,
		lng: -49.49998661,
		pop: 36122,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Itaberai',
		city_ascii: 'Itaberai',
		lat: -16.01996662,
		lng: -49.80996769,
		pop: 18710.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Santo Andre',
		city_ascii: 'Santo Andre',
		lat: -23.65283405,
		lng: -46.52781661,
		pop: 662373,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Pindamonhangaba',
		city_ascii: 'Pindamonhangaba',
		lat: -22.91995888,
		lng: -45.47002588,
		pop: 123985,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Rio Claro',
		city_ascii: 'Rio Claro',
		lat: -22.40996417,
		lng: -47.56002751,
		pop: 177710,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Ourinhos',
		city_ascii: 'Ourinhos',
		lat: -22.97003335,
		lng: -49.86998987,
		pop: 96994,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Itanhaem',
		city_ascii: 'Itanhaem',
		lat: -24.17998533,
		lng: -46.80002222,
		pop: 82722,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Jaboticabal',
		city_ascii: 'Jaboticabal',
		lat: -21.25003497,
		lng: -48.32998051,
		pop: 60780.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Braganca Paulista',
		city_ascii: 'Braganca Paulista',
		lat: -22.95003457,
		lng: -46.5499858,
		pop: 126386,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Jundiai',
		city_ascii: 'Jundiai',
		lat: -23.19999347,
		lng: -46.8799915,
		pop: 413568.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Sao Jose dos Campos',
		city_ascii: 'Sao Jose dos Campos',
		lat: -23.19999347,
		lng: -45.87994918,
		pop: 695322.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Guaratingueta',
		city_ascii: 'Guaratingueta',
		lat: -22.81996499,
		lng: -45.18999129,
		pop: 154730,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Pirassununga',
		city_ascii: 'Pirassununga',
		lat: -21.99004148,
		lng: -47.42998377,
		pop: 51698,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Americana',
		city_ascii: 'Americana',
		lat: -22.74994342,
		lng: -47.32998987,
		pop: 337747,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Piracicaba',
		city_ascii: 'Piracicaba',
		lat: -22.70999754,
		lng: -47.63999679,
		pop: 329530,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Sao Joao da Boa Vista',
		city_ascii: 'Sao Joao da Boa Vista',
		lat: -21.98001626,
		lng: -46.78999699,
		pop: 68666,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Sao Carlos',
		city_ascii: 'Sao Carlos',
		lat: -22.02001382,
		lng: -47.88998153,
		pop: 175219,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Tupa',
		city_ascii: 'Tupa',
		lat: -21.92999347,
		lng: -50.5199502,
		pop: 51798.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Penapolis',
		city_ascii: 'Penapolis',
		lat: -21.41002521,
		lng: -50.08000289,
		pop: 44795,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Presidente Prudente',
		city_ascii: 'Presidente Prudente',
		lat: -22.12000771,
		lng: -51.39000045,
		pop: 199722,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Registro',
		city_ascii: 'Registro',
		lat: -24.49004393,
		lng: -47.83998458,
		pop: 49485,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Tatui',
		city_ascii: 'Tatui',
		lat: -23.35001015,
		lng: -47.8600092,
		pop: 81936,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Avare',
		city_ascii: 'Avare',
		lat: -23.1100248,
		lng: -48.9300214,
		pop: 70709,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Garca',
		city_ascii: 'Garca',
		lat: -22.22000161,
		lng: -49.65997685,
		pop: 38460,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Catanduva',
		city_ascii: 'Catanduva',
		lat: -21.14001585,
		lng: -48.97996668,
		pop: 105238,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Batatais',
		city_ascii: 'Batatais',
		lat: -20.89000527,
		lng: -47.58999984,
		pop: 44061,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Barretos',
		city_ascii: 'Barretos',
		lat: -20.55002602,
		lng: -48.58001693,
		pop: 97562,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Marilia',
		city_ascii: 'Marilia',
		lat: -22.21002806,
		lng: -49.95001083,
		pop: 191083.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Itu',
		city_ascii: 'Itu',
		lat: -23.26004148,
		lng: -47.30001754,
		pop: 228878,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Itapetininga',
		city_ascii: 'Itapetininga',
		lat: -23.58999551,
		lng: -48.03999821,
		pop: 120889,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Jaboatao',
		city_ascii: 'Jaboatao',
		lat: -8.110010153,
		lng: -35.02004358,
		pop: 681214,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pernambuco',
		timezone: 'America/Recife',
	},
	{
		city: 'Olinda',
		city_ascii: 'Olinda',
		lat: -7.999991029,
		lng: -34.8499506,
		pop: 659554,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pernambuco',
		timezone: 'America/Recife',
	},
	{
		city: 'Cabo de Santo Agostinho',
		city_ascii: 'Cabo de Santo Agostinho',
		lat: -8.289999167,
		lng: -35.02999129,
		pop: 144662,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pernambuco',
		timezone: 'America/Recife',
	},
	{
		city: 'Carpina',
		city_ascii: 'Carpina',
		lat: -7.840000795,
		lng: -35.26000309,
		pop: 118134,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pernambuco',
		timezone: 'America/Recife',
	},
	{
		city: 'Arcoverde',
		city_ascii: 'Arcoverde',
		lat: -8.420017071,
		lng: -37.06999597,
		pop: 53066,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pernambuco',
		timezone: 'America/Recife',
	},
	{
		city: 'Manacapuru',
		city_ascii: 'Manacapuru',
		lat: -3.289580873,
		lng: -60.6199797,
		pop: 55780.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Manaus',
	},
	{
		city: 'Maues',
		city_ascii: 'Maues',
		lat: -3.389626446,
		lng: -57.72002751,
		pop: 27518,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Manaus',
	},
	{
		city: 'Pedreiras',
		city_ascii: 'Pedreiras',
		lat: -4.569606101,
		lng: -44.67002303,
		pop: 13638,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Codo',
		city_ascii: 'Codo',
		lat: -4.479585756,
		lng: -43.8799679,
		pop: 83288,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Coroata',
		city_ascii: 'Coroata',
		lat: -4.12958128,
		lng: -44.15000309,
		pop: 34129,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Chapadinha',
		city_ascii: 'Chapadinha',
		lat: -3.739527569,
		lng: -43.35999964,
		pop: 29807.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Pinheiro',
		city_ascii: 'Pinheiro',
		lat: -2.519602032,
		lng: -45.0899974,
		pop: 24912,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Barra do Corda',
		city_ascii: 'Barra do Corda',
		lat: -5.509600404,
		lng: -45.25996118,
		pop: 48901,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Viana',
		city_ascii: 'Viana',
		lat: -3.209585756,
		lng: -45.00000289,
		pop: 15808.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Colinas',
		city_ascii: 'Colinas',
		lat: -6.039542217,
		lng: -44.2399976,
		pop: 13562.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Viseu',
		city_ascii: 'Viseu',
		lat: -1.196478659,
		lng: -46.14001083,
		pop: 9992.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Capitao Poco',
		city_ascii: 'Capitao Poco',
		lat: -1.74962319,
		lng: -47.09000452,
		pop: 32704,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Castanhal',
		city_ascii: 'Castanhal',
		lat: -1.28959959,
		lng: -47.93003076,
		pop: 125314.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Salinopolis',
		city_ascii: 'Salinopolis',
		lat: -0.609486065,
		lng: -47.33998926,
		pop: 32384.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Alenquer',
		city_ascii: 'Alenquer',
		lat: -1.939585756,
		lng: -54.78999964,
		pop: 26290,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Santarem',
	},
	{
		city: 'Oriximina',
		city_ascii: 'Oriximina',
		lat: -1.759596742,
		lng: -55.86998539,
		pop: 35300,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Santarem',
	},
	{
		city: 'Xinguara',
		city_ascii: 'Xinguara',
		lat: -7.100614401,
		lng: -49.94804712,
		pop: 4047,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Jacundá',
		city_ascii: 'Jacunda',
		lat: -4.599578431,
		lng: -49.38996749,
		pop: 51375,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Uruara',
		city_ascii: 'Uruara',
		lat: -3.82959959,
		lng: -54.02999435,
		pop: 10,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Santarem',
	},
	{
		city: 'Altamira',
		city_ascii: 'Altamira',
		lat: -3.199612204,
		lng: -52.21000208,
		pop: 56769,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Santarem',
	},
	{
		city: 'Paragominas',
		city_ascii: 'Paragominas',
		lat: -2.959575176,
		lng: -47.49000594,
		pop: 38095.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Cameta',
		city_ascii: 'Cameta',
		lat: -2.239619121,
		lng: -49.509986,
		pop: 22705,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Rolim de Moura',
		city_ascii: 'Rolim de Moura',
		lat: -11.73020262,
		lng: -61.78063237,
		pop: 24516,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rondônia',
		timezone: 'America/Porto_Velho',
	},
	{
		city: 'Ariquemes',
		city_ascii: 'Ariquemes',
		lat: -9.939614239,
		lng: -63.07998458,
		pop: 58096,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rondônia',
		timezone: 'America/Porto_Velho',
	},
	{
		city: 'Abuna',
		city_ascii: 'Abuna',
		lat: -9.69539142,
		lng: -65.35971623,
		pop: 1929,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rondônia',
		timezone: 'America/Porto_Velho',
	},
	{
		city: 'Tocantinopolis',
		city_ascii: 'Tocantinopolis',
		lat: -6.319576804,
		lng: -47.41998438,
		pop: 8750,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Tocantins',
		timezone: 'America/Araguaina',
	},
	{
		city: 'Gurupi',
		city_ascii: 'Gurupi',
		lat: -11.71960895,
		lng: -49.05998763,
		pop: 45595.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Tocantins',
		timezone: 'America/Araguaina',
	},
	{
		city: 'Aquidauana',
		city_ascii: 'Aquidauana',
		lat: -20.46961749,
		lng: -55.79001611,
		pop: 38053,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso do Sul',
		timezone: 'America/Campo_Grande',
	},
	{
		city: 'Paranaiba',
		city_ascii: 'Paranaiba',
		lat: -19.6795882,
		lng: -51.20001205,
		pop: 25278.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso do Sul',
		timezone: 'America/Campo_Grande',
	},
	{
		city: 'Sete Lagoas',
		city_ascii: 'Sete Lagoas',
		lat: -19.44962807,
		lng: -44.24999699,
		pop: 195032,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Divinopolis',
		city_ascii: 'Divinopolis',
		lat: -20.14953367,
		lng: -44.89998316,
		pop: 181457,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Ipatinga',
		city_ascii: 'Ipatinga',
		lat: -19.4796004,
		lng: -42.51999923,
		pop: 318320,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Araxa',
		city_ascii: 'Araxa',
		lat: -19.5795943,
		lng: -46.95001306,
		pop: 70159.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Lavras',
		city_ascii: 'Lavras',
		lat: -21.24956989,
		lng: -45.0099506,
		pop: 70436,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Uba',
		city_ascii: 'Uba',
		lat: -21.11960366,
		lng: -42.95002466,
		pop: 81698.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Campo Belo',
		city_ascii: 'Campo Belo',
		lat: -20.88959186,
		lng: -45.2799858,
		pop: 42042.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Ponte Nova',
		city_ascii: 'Ponte Nova',
		lat: -20.40962116,
		lng: -42.8999502,
		pop: 40377,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Curvelo',
		city_ascii: 'Curvelo',
		lat: -18.75959267,
		lng: -44.429986,
		pop: 45937,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Paracatu',
		city_ascii: 'Paracatu',
		lat: -17.19958453,
		lng: -46.86999211,
		pop: 51673.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Bocaiuva',
		city_ascii: 'Bocaiuva',
		lat: -17.10961587,
		lng: -43.81004968,
		pop: 22528.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Aracuai',
		city_ascii: 'Aracuai',
		lat: -16.85960529,
		lng: -42.06994918,
		pop: 15301.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Janauba',
		city_ascii: 'Janauba',
		lat: -15.79961831,
		lng: -43.30997685,
		pop: 38641,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Juina',
		city_ascii: 'Juina',
		lat: -11.3995768,
		lng: -59.50002222,
		pop: 980,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso',
		timezone: 'America/Cuiaba',
	},
	{
		city: 'Barra do Garcas',
		city_ascii: 'Barra do Garcas',
		lat: -15.87961342,
		lng: -52.25999903,
		pop: 41214.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso',
		timezone: 'America/Cuiaba',
	},
	{
		city: 'Pontes e Lacerda',
		city_ascii: 'Pontes e Lacerda',
		lat: -15.21960203,
		lng: -59.3499797,
		pop: 22694.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso',
		timezone: 'America/Cuiaba',
	},
	{
		city: 'Barra do Bugres',
		city_ascii: 'Barra do Bugres',
		lat: -15.06958535,
		lng: -57.19000818,
		pop: 22386,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso',
		timezone: 'America/Cuiaba',
	},
	{
		city: 'Rondonopolis',
		city_ascii: 'Rondonopolis',
		lat: -16.4694999,
		lng: -54.63998295,
		pop: 146794,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso',
		timezone: 'America/Cuiaba',
	},
	{
		city: 'Uruguaiana',
		city_ascii: 'Uruguaiana',
		lat: -29.76961831,
		lng: -57.08998845,
		pop: 97736.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Sao Borja',
		city_ascii: 'Sao Borja',
		lat: -28.65960854,
		lng: -56.00997685,
		pop: 48450.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Novo Hamburgo',
		city_ascii: 'Novo Hamburgo',
		lat: -29.70962197,
		lng: -51.13998987,
		pop: 557017,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Rio Grande',
		city_ascii: 'Rio Grande',
		lat: -32.04947915,
		lng: -52.12000757,
		pop: 143150,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Camaqua',
		city_ascii: 'Camaqua',
		lat: -30.83963051,
		lng: -51.81000065,
		pop: 45112.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Bento Goncalves',
		city_ascii: 'Bento Goncalves',
		lat: -29.1694999,
		lng: -51.51996668,
		pop: 92561.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Vacaria',
		city_ascii: 'Vacaria',
		lat: -28.49961831,
		lng: -50.94000208,
		pop: 47275.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Ijui',
		city_ascii: 'Ijui',
		lat: -28.38954751,
		lng: -53.91994938,
		pop: 59047,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Santa Rosa',
		city_ascii: 'Santa Rosa',
		lat: -27.86952757,
		lng: -54.4599681,
		pop: 59119,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Maringa',
		city_ascii: 'Maringa',
		lat: -23.4095414,
		lng: -51.92996749,
		pop: 320029.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Cascavel',
		city_ascii: 'Cascavel',
		lat: -24.95957599,
		lng: -53.46002914,
		pop: 229300,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Campo Murao',
		city_ascii: 'Campo Murao',
		lat: -24.04960569,
		lng: -52.41998926,
		pop: 74173,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Foz do Iguacu',
		city_ascii: 'Foz do Iguacu',
		lat: -25.52346922,
		lng: -54.52998967,
		pop: 366989,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Sao Francisco do Sul',
		city_ascii: 'Sao Francisco do Sul',
		lat: -26.23960122,
		lng: -48.59998987,
		pop: 24354.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Porto Uniao',
		city_ascii: 'Porto Uniao',
		lat: -26.23960122,
		lng: -51.07996769,
		pop: 50242.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Itajai',
		city_ascii: 'Itajai',
		lat: -26.89961261,
		lng: -48.68001083,
		pop: 241421,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Imbituba',
		city_ascii: 'Imbituba',
		lat: -28.22960895,
		lng: -48.66001205,
		pop: 28380,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Lajes',
		city_ascii: 'Lajes',
		lat: -27.80958291,
		lng: -50.30998885,
		pop: 139972,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Granja',
		city_ascii: 'Granja',
		lat: -3.119513734,
		lng: -40.83999841,
		pop: 17233,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Crato',
		city_ascii: 'Crato',
		lat: -7.229598776,
		lng: -39.42000757,
		pop: 164149.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Itapipoca',
		city_ascii: 'Itapipoca',
		lat: -3.499542217,
		lng: -39.58002364,
		pop: 31041,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Paracuru',
		city_ascii: 'Paracuru',
		lat: -3.399548321,
		lng: -39.04003076,
		pop: 19860,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Acarau',
		city_ascii: 'Acarau',
		lat: -2.889605287,
		lng: -40.11999068,
		pop: 21024.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Taua',
		city_ascii: 'Taua',
		lat: -5.999492982,
		lng: -40.31003076,
		pop: 29188,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Crateus',
		city_ascii: 'Crateus',
		lat: -5.165590394,
		lng: -40.66600387,
		pop: 40338,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Baturite',
		city_ascii: 'Baturite',
		lat: -4.329569073,
		lng: -38.87998885,
		pop: 17797.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Ipu',
		city_ascii: 'Ipu',
		lat: -4.319595521,
		lng: -40.72005742,
		pop: 14479.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Floriano',
		city_ascii: 'Floriano',
		lat: -6.769575176,
		lng: -43.0299681,
		pop: 35923,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Piauí',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Piripiri',
		city_ascii: 'Piripiri',
		lat: -4.269572735,
		lng: -41.78999211,
		pop: 32639,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Piauí',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Penedo',
		city_ascii: 'Penedo',
		lat: -10.26961994,
		lng: -36.58002588,
		pop: 37515.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Alagoas',
		timezone: 'America/Maceio',
	},
	{
		city: 'Itabuna',
		city_ascii: 'Itabuna',
		lat: -14.78960244,
		lng: -39.28001611,
		pop: 213799,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Itamaraju',
		city_ascii: 'Itamaraju',
		lat: -17.0395943,
		lng: -39.52994918,
		pop: 35055,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Guanambi',
		city_ascii: 'Guanambi',
		lat: -14.22958494,
		lng: -42.78998275,
		pop: 45730,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Porto Seguro',
		city_ascii: 'Porto Seguro',
		lat: -16.42960569,
		lng: -39.08002832,
		pop: 72031,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Valenca',
		city_ascii: 'Valenca',
		lat: -13.3596122,
		lng: -39.08002832,
		pop: 56584.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Serrinha',
		city_ascii: 'Serrinha',
		lat: -11.64958738,
		lng: -39.01000676,
		pop: 52953.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Tucano',
		city_ascii: 'Tucano',
		lat: -10.96962889,
		lng: -38.78999435,
		pop: 16199,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Senhor do Bonfim',
		city_ascii: 'Senhor do Bonfim',
		lat: -10.44960895,
		lng: -40.19001225,
		pop: 43577,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Remanso',
		city_ascii: 'Remanso',
		lat: -9.599583314,
		lng: -42.10999841,
		pop: 37945,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Itambe',
		city_ascii: 'Itambe',
		lat: -15.23960081,
		lng: -40.62998539,
		pop: 15450.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Bom Jesus da Lapa',
		city_ascii: 'Bom Jesus da Lapa',
		lat: -13.2495414,
		lng: -43.44002059,
		pop: 40691,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Itaberaba',
		city_ascii: 'Itaberaba',
		lat: -12.5196118,
		lng: -40.2999797,
		pop: 31722,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Sao Mateus',
		city_ascii: 'Sao Mateus',
		lat: -18.72962034,
		lng: -39.85998071,
		pop: 63375.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Espírito Santo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Patos',
		city_ascii: 'Patos',
		lat: -7.019585756,
		lng: -37.29000838,
		pop: 85720.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraíba',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Volta Redonda',
		city_ascii: 'Volta Redonda',
		lat: -22.51956989,
		lng: -44.09496769,
		pop: 352971,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio de Janeiro',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Petropolis',
		city_ascii: 'Petropolis',
		lat: -22.50949298,
		lng: -43.19998356,
		pop: 279381,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio de Janeiro',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Nova Cruz',
		city_ascii: 'Nova Cruz',
		lat: -6.469593487,
		lng: -35.43999211,
		pop: 22563.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Norte',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Caico',
		city_ascii: 'Caico',
		lat: -6.459619935,
		lng: -37.10001998,
		pop: 42378,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Norte',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Acu',
		city_ascii: 'Acu',
		lat: -5.57962197,
		lng: -36.91005742,
		pop: 33303,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Norte',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Estancia',
		city_ascii: 'Estancia',
		lat: -11.26961058,
		lng: -37.45002446,
		pop: 50690,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Sergipe',
		timezone: 'America/Maceio',
	},
	{
		city: 'Caracarai',
		city_ascii: 'Caracarai',
		lat: 1.816231505,
		lng: -61.12767481,
		pop: 11368,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Roraima',
		timezone: 'America/Boa_Vista',
	},
	{
		city: 'Porto Santana',
		city_ascii: 'Porto Santana',
		lat: -0.039598369,
		lng: -51.17998743,
		pop: 68849,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amapá',
		timezone: 'America/Belem',
	},
	{
		city: 'Rio Verde',
		city_ascii: 'Rio Verde',
		lat: -17.81959837,
		lng: -50.92997685,
		pop: 48318,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Pires do Rio',
		city_ascii: 'Pires do Rio',
		lat: -17.29952675,
		lng: -48.27998356,
		pop: 21688,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Anapolis',
		city_ascii: 'Anapolis',
		lat: -16.31958657,
		lng: -48.9599679,
		pop: 278595.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Goianesia',
		city_ascii: 'Goianesia',
		lat: -15.30962238,
		lng: -49.1300092,
		pop: 39217.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Niquelandia',
		city_ascii: 'Niquelandia',
		lat: -14.46962197,
		lng: -48.47002364,
		pop: 19785,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Itumbiara',
		city_ascii: 'Itumbiara',
		lat: -18.39961465,
		lng: -49.21000431,
		pop: 65343.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Jatai',
		city_ascii: 'Jatai',
		lat: -17.87959471,
		lng: -51.75000431,
		pop: 57135,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Mineiros',
		city_ascii: 'Mineiros',
		lat: -17.56953611,
		lng: -52.55998071,
		pop: 28247,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Formosa',
		city_ascii: 'Formosa',
		lat: -15.53947915,
		lng: -47.33998926,
		pop: 62585.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Sao Jose do Rio Preto',
		city_ascii: 'Sao Jose do Rio Preto',
		lat: -20.79962319,
		lng: -49.38996749,
		pop: 358243.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Limeira',
		city_ascii: 'Limeira',
		lat: -22.54954222,
		lng: -47.40001144,
		pop: 241071,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Taubate',
		city_ascii: 'Taubate',
		lat: -23.01953937,
		lng: -45.55999455,
		pop: 327600.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Jau',
		city_ascii: 'Jau',
		lat: -22.28960976,
		lng: -48.57001754,
		pop: 102565.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Assis',
		city_ascii: 'Assis',
		lat: -22.65961302,
		lng: -50.41998214,
		pop: 79133,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Itapeva',
		city_ascii: 'Itapeva',
		lat: -23.97958413,
		lng: -48.88002446,
		pop: 55324,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Botucatu',
		city_ascii: 'Botucatu',
		lat: -22.87959959,
		lng: -48.44999903,
		pop: 94938.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Novo Horizonte',
		city_ascii: 'Novo Horizonte',
		lat: -21.45958291,
		lng: -49.2200037,
		pop: 29554,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Andradina',
		city_ascii: 'Andradina',
		lat: -20.90959064,
		lng: -51.37994938,
		pop: 45715,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Fernandopolis',
		city_ascii: 'Fernandopolis',
		lat: -20.2696297,
		lng: -50.26004358,
		pop: 55587,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Barreiros',
		city_ascii: 'Barreiros',
		lat: -8.829604473,
		lng: -35.20000676,
		pop: 35472.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pernambuco',
		timezone: 'America/Recife',
	},
	{
		city: 'Salgueiro',
		city_ascii: 'Salgueiro',
		lat: -8.059625632,
		lng: -39.13002527,
		pop: 31565,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pernambuco',
		timezone: 'America/Recife',
	},
	{
		city: 'Goiana',
		city_ascii: 'Goiana',
		lat: -7.559604473,
		lng: -34.99999312,
		pop: 57764.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pernambuco',
		timezone: 'America/Recife',
	},
	{
		city: 'Timbauba',
		city_ascii: 'Timbauba',
		lat: -7.499608135,
		lng: -35.32002527,
		pop: 51327.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pernambuco',
		timezone: 'America/Recife',
	},
	{
		city: 'Bacabal',
		city_ascii: 'Bacabal',
		lat: -4.229988588,
		lng: -44.79998926,
		pop: 57296.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Braganca',
		city_ascii: 'Braganca',
		lat: -1.05002765,
		lng: -46.76999821,
		pop: 56864.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Obidos',
		city_ascii: 'Obidos',
		lat: -1.910026836,
		lng: -55.52000676,
		pop: 26278.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Santarem',
	},
	{
		city: 'Guajara-Miram',
		city_ascii: 'Guajara-Miram',
		lat: -10.80002684,
		lng: -65.34994938,
		pop: 51852.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rondônia',
		timezone: 'America/La_Paz',
	},
	{
		city: 'Porto Nacional',
		city_ascii: 'Porto Nacional',
		lat: -10.70003294,
		lng: -48.41994918,
		pop: 9129,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Tocantins',
		timezone: 'America/Araguaina',
	},
	{
		city: 'Dourados',
		city_ascii: 'Dourados',
		lat: -22.23002684,
		lng: -54.80999841,
		pop: 144743.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso do Sul',
		timezone: 'America/Campo_Grande',
	},
	{
		city: 'Governador Valadares',
		city_ascii: 'Governador Valadares',
		lat: -18.87002521,
		lng: -41.97000696,
		pop: 201317,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Pirapora',
		city_ascii: 'Pirapora',
		lat: -17.33001585,
		lng: -44.92998132,
		pop: 55910,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Juiz de Fora',
		city_ascii: 'Juiz de Fora',
		lat: -21.77000324,
		lng: -43.3749858,
		pop: 464764.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Santa Maria',
		city_ascii: 'Santa Maria',
		lat: -29.68331867,
		lng: -53.80000838,
		pop: 239211.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Passo Fundo',
		city_ascii: 'Passo Fundo',
		lat: -28.25002114,
		lng: -52.41998926,
		pop: 164047,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Xapeco',
		city_ascii: 'Xapeco',
		lat: -27.10001382,
		lng: -52.64002751,
		pop: 154794,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Joinville',
		city_ascii: 'Joinville',
		lat: -26.31995807,
		lng: -48.83994938,
		pop: 710737.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Juazeiro do Norte',
		city_ascii: 'Juazeiro do Norte',
		lat: -7.210013409,
		lng: -39.32001367,
		pop: 222861,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Nova Vicosa',
		city_ascii: 'Nova Vicosa',
		lat: -17.88000812,
		lng: -39.37001062,
		pop: 30250,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Alagoinhas',
		city_ascii: 'Alagoinhas',
		lat: -12.13999673,
		lng: -38.42999048,
		pop: 123379,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Juazeiro',
		city_ascii: 'Juazeiro',
		lat: -9.420007712,
		lng: -40.49996749,
		pop: 95132,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Vitória',
		city_ascii: 'Vitoria',
		lat: -20.32399331,
		lng: -40.36599634,
		pop: 1008328,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Espírito Santo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Joao Pessoa',
		city_ascii: 'Joao Pessoa',
		lat: -7.10113513,
		lng: -34.87607117,
		pop: 803441.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraíba',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Campina Grande',
		city_ascii: 'Campina Grande',
		lat: -7.230012188,
		lng: -35.88001693,
		pop: 383098.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraíba',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Nova Friburgo',
		city_ascii: 'Nova Friburgo',
		lat: -22.25999917,
		lng: -42.54004968,
		pop: 162676,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio de Janeiro',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Aracatuba',
		city_ascii: 'Aracatuba',
		lat: -21.20998574,
		lng: -50.45000615,
		pop: 166305,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Sena Madureira',
		city_ascii: 'Sena Madureira',
		lat: -9.070003236,
		lng: -68.66997929,
		pop: 23354,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Acre',
		timezone: 'America/Rio_Branco',
	},
	{
		city: 'Fonte Boa',
		city_ascii: 'Fonte Boa',
		lat: -2.513814271,
		lng: -66.09160954,
		pop: 13974.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Manaus',
	},
	{
		city: 'Eirunepe',
		city_ascii: 'Eirunepe',
		lat: -6.66002114,
		lng: -69.87380762,
		pop: 19462.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Eirunepe',
	},
	{
		city: 'Manicore',
		city_ascii: 'Manicore',
		lat: -5.812165915,
		lng: -61.29748356,
		pop: 17802,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Manaus',
	},
	{
		city: 'Barcelos',
		city_ascii: 'Barcelos',
		lat: -0.97499347,
		lng: -62.92389592,
		pop: 9968.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Manaus',
	},
	{
		city: 'Tonantins',
		city_ascii: 'Tonantins',
		lat: -2.872707093,
		lng: -67.80189274,
		pop: 2722,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Manaus',
	},
	{
		city: 'Tefe',
		city_ascii: 'Tefe',
		lat: -3.36001585,
		lng: -64.69998906,
		pop: 48189.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Manaus',
	},
	{
		city: 'Coari',
		city_ascii: 'Coari',
		lat: -4.079971905,
		lng: -63.12998153,
		pop: 51897.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Manaus',
	},
	{
		city: 'Sao Cabriel da Cachoeira',
		city_ascii: 'Sao Cabriel da Cachoeira',
		lat: -0.133236065,
		lng: -67.08330611,
		pop: 15231,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Manaus',
	},
	{
		city: 'Novo Airao',
		city_ascii: 'Novo Airao',
		lat: -2.620784486,
		lng: -60.94378422,
		pop: 9049,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Manaus',
	},
	{
		city: 'Itacoatiara',
		city_ascii: 'Itacoatiara',
		lat: -3.140029278,
		lng: -58.43998356,
		pop: 51509,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Manaus',
	},
	{
		city: 'Parintins',
		city_ascii: 'Parintins',
		lat: -2.610035788,
		lng: -56.74000981,
		pop: 64428,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Manaus',
	},
	{
		city: 'Natal',
		city_ascii: 'Natal',
		lat: -6.983825664,
		lng: -60.26994938,
		pop: 980588,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Manaus',
	},
	{
		city: 'Crato',
		city_ascii: 'Crato',
		lat: -7.46389972,
		lng: -63.03996118,
		pop: 164149.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Manaus',
	},
	{
		city: 'Imperatriz',
		city_ascii: 'Imperatriz',
		lat: -5.520039043,
		lng: -47.49000594,
		pop: 203339.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Balsas',
		city_ascii: 'Balsas',
		lat: -7.520020326,
		lng: -46.04999048,
		pop: 39761.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Breves',
		city_ascii: 'Breves',
		lat: -1.680015036,
		lng: -50.4900037,
		pop: 46818.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Jacareacanga',
		city_ascii: 'Jacareacanga',
		lat: -6.266608461,
		lng: -57.65000594,
		pop: 31661,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Santarem',
	},
	{
		city: 'Tucurui',
		city_ascii: 'Tucurui',
		lat: -3.679996319,
		lng: -49.71999903,
		pop: 38472,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Itaituba',
		city_ascii: 'Itaituba',
		lat: -4.258617331,
		lng: -55.92502079,
		pop: 78532,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Santarem',
	},
	{
		city: 'Conceicao do Araguaia',
		city_ascii: 'Conceicao do Araguaia',
		lat: -8.250001608,
		lng: -49.29002527,
		pop: 27115,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Abaetetuba',
		city_ascii: 'Abaetetuba',
		lat: -1.724530694,
		lng: -48.88488014,
		pop: 78735,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Principe da Beira',
		city_ascii: 'Principe da Beira',
		lat: -12.41667234,
		lng: -64.41664718,
		pop: 956,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rondônia',
		timezone: 'America/Porto_Velho',
	},
	{
		city: 'Araguaina',
		city_ascii: 'Araguaina',
		lat: -7.190014629,
		lng: -48.21001367,
		pop: 50444,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Tocantins',
		timezone: 'America/Araguaina',
	},
	{
		city: 'Palmas',
		city_ascii: 'Palmas',
		lat: -10.23773558,
		lng: -48.2877867,
		pop: 215793.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Tocantins',
		timezone: 'America/Araguaina',
	},
	{
		city: 'Teofilo Otoni',
		city_ascii: 'Teofilo Otoni',
		lat: -17.87003457,
		lng: -41.50000981,
		pop: 86865,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Uberaba',
		city_ascii: 'Uberaba',
		lat: -19.7799955,
		lng: -47.9500037,
		pop: 234807,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Januaria',
		city_ascii: 'Januaria',
		lat: -15.47999957,
		lng: -44.36998967,
		pop: 25753,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Mato Grosso',
		city_ascii: 'Mato Grosso',
		lat: -15.00002887,
		lng: -59.95002059,
		pop: 1612,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso',
		timezone: 'America/Cuiaba',
	},
	{
		city: 'Aripuana',
		city_ascii: 'Aripuana',
		lat: -9.169997133,
		lng: -60.64000431,
		pop: 26983,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso',
		timezone: 'America/Cuiaba',
	},
	{
		city: 'Sinop',
		city_ascii: 'Sinop',
		lat: -11.84998859,
		lng: -55.45998458,
		pop: 8961,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso',
		timezone: 'America/Cuiaba',
	},
	{
		city: 'Jaguarao',
		city_ascii: 'Jaguarao',
		lat: -32.5600423,
		lng: -53.36998295,
		pop: 26020,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Bage',
		city_ascii: 'Bage',
		lat: -31.32001463,
		lng: -54.10001591,
		pop: 102519,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Londrina',
		city_ascii: 'Londrina',
		lat: -23.30003904,
		lng: -51.17998743,
		pop: 496035,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Criciuma',
		city_ascii: 'Criciuma',
		lat: -28.68002073,
		lng: -49.38996749,
		pop: 183085.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Aracati',
		city_ascii: 'Aracati',
		lat: -4.559994284,
		lng: -37.77003076,
		pop: 28126.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Ico',
		city_ascii: 'Ico',
		lat: -6.400037009,
		lng: -38.84999068,
		pop: 15820,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Parnaiba',
		city_ascii: 'Parnaiba',
		lat: -2.910017478,
		lng: -41.76996749,
		pop: 125699,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Piauí',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Picos',
		city_ascii: 'Picos',
		lat: -7.079995505,
		lng: -41.43998763,
		pop: 47694.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Piauí',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Arapiraca',
		city_ascii: 'Arapiraca',
		lat: -9.750013409,
		lng: -36.66999455,
		pop: 177115,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Alagoas',
		timezone: 'America/Maceio',
	},
	{
		city: 'Jequie',
		city_ascii: 'Jequie',
		lat: -13.85002155,
		lng: -40.07999312,
		pop: 131524.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Ilheus',
		city_ascii: 'Ilheus',
		lat: -14.7800423,
		lng: -39.05000431,
		pop: 193060.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Canavieiras',
		city_ascii: 'Canavieiras',
		lat: -15.64004148,
		lng: -38.96000981,
		pop: 26375,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Santa Maria da Vitoria',
		city_ascii: 'Santa Maria da Vitoria',
		lat: -13.38999795,
		lng: -44.21002527,
		pop: 13176,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Irece',
		city_ascii: 'Irece',
		lat: -11.29999632,
		lng: -41.87001306,
		pop: 48079.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Xique-Xique',
		city_ascii: 'Xique-Xique',
		lat: -10.82002562,
		lng: -42.73001225,
		pop: 18633,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Linhares',
		city_ascii: 'Linhares',
		lat: -19.38999347,
		lng: -40.05002079,
		pop: 86413,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Espírito Santo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Campos',
		city_ascii: 'Campos',
		lat: -21.74995278,
		lng: -41.32002079,
		pop: 378943,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio de Janeiro',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Mossoro',
		city_ascii: 'Mossoro',
		lat: -5.190033347,
		lng: -37.34000533,
		pop: 202294,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Norte',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Aracaju',
		city_ascii: 'Aracaju',
		lat: -10.90002073,
		lng: -37.11996708,
		pop: 587765.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Sergipe',
		timezone: 'America/Maceio',
	},
	{
		city: 'Laranjal do Jari',
		city_ascii: 'Laranjal do Jari',
		lat: -0.850039857,
		lng: -52.48001144,
		pop: 43344,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amapá',
		timezone: 'America/Santarem',
	},
	{
		city: 'Amapa',
		city_ascii: 'Amapa',
		lat: 2.049989847,
		lng: -50.80001062,
		pop: 1947,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amapá',
		timezone: 'America/Belem',
	},
	{
		city: 'Vila Velha',
		city_ascii: 'Vila Velha',
		lat: 3.21666282,
		lng: -51.21665186,
		pop: 742413.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amapá',
		timezone: 'America/Belem',
	},
	{
		city: 'Santos',
		city_ascii: 'Santos',
		lat: -23.95372393,
		lng: -46.33294266,
		pop: 1060201.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Bauru',
		city_ascii: 'Bauru',
		lat: -22.33002073,
		lng: -49.08001225,
		pop: 307929.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Iguape',
		city_ascii: 'Iguape',
		lat: -24.72000405,
		lng: -47.56994938,
		pop: 23602.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Franca',
		city_ascii: 'Franca',
		lat: -20.53002724,
		lng: -47.39001205,
		pop: 281149.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Garanhuns',
		city_ascii: 'Garanhuns',
		lat: -8.890014222,
		lng: -36.50003076,
		pop: 107115,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pernambuco',
		timezone: 'America/Recife',
	},
	{
		city: 'Caruaru',
		city_ascii: 'Caruaru',
		lat: -8.280025616,
		lng: -35.98001083,
		pop: 238732.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pernambuco',
		timezone: 'America/Recife',
	},
	{
		city: 'Rio Branco',
		city_ascii: 'Rio Branco',
		lat: -9.966589336,
		lng: -67.80000655,
		pop: 257642,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Acre',
		timezone: 'America/Rio_Branco',
	},
	{
		city: 'São Luís',
		city_ascii: 'Sao Luis',
		lat: -2.515984681,
		lng: -44.26599085,
		pop: 524692.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Porto Velho',
		city_ascii: 'Porto Velho',
		lat: -8.750022767,
		lng: -63.90001205,
		pop: 289534.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rondônia',
		timezone: 'America/Porto_Velho',
	},
	{
		city: 'Alvorada',
		city_ascii: 'Alvorada',
		lat: -12.47000242,
		lng: -49.08200179,
		pop: 9273,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Tocantins',
		timezone: 'America/Araguaina',
	},
	{
		city: 'Corumba',
		city_ascii: 'Corumba',
		lat: -19.01601113,
		lng: -57.65000594,
		pop: 70035.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso do Sul',
		timezone: 'America/Campo_Grande',
	},
	{
		city: 'Belo Horizonte',
		city_ascii: 'Belo Horizonte',
		lat: -19.91502602,
		lng: -43.91500452,
		pop: 3974112,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Montes Claros',
		city_ascii: 'Montes Claros',
		lat: -16.72002724,
		lng: -43.86002079,
		pop: 300022,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Uberlandia',
		city_ascii: 'Uberlandia',
		lat: -18.89999754,
		lng: -48.27998356,
		pop: 484862,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Minas Gerais',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Colider',
		city_ascii: 'Colider',
		lat: -10.81728676,
		lng: -55.45057947,
		pop: 27139,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso',
		timezone: 'America/Cuiaba',
	},
	{
		city: 'Alta Floresta',
		city_ascii: 'Alta Floresta',
		lat: -9.900030091,
		lng: -55.90998295,
		pop: 40466,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso',
		timezone: 'America/Cuiaba',
	},
	{
		city: 'Cuiaba',
		city_ascii: 'Cuiaba',
		lat: -15.56960651,
		lng: -56.08498519,
		pop: 603143.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso',
		timezone: 'America/Cuiaba',
	},
	{
		city: 'Pelotas',
		city_ascii: 'Pelotas',
		lat: -31.75001422,
		lng: -52.33002059,
		pop: 299270,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Caxias do Sul',
		city_ascii: 'Caxias do Sul',
		lat: -29.17999022,
		lng: -51.17003972,
		pop: 377580.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Ponta Grossa',
		city_ascii: 'Ponta Grossa',
		lat: -25.09000731,
		lng: -50.16004968,
		pop: 271321.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Teresina',
		city_ascii: 'Teresina',
		lat: -5.095000388,
		lng: -42.7800092,
		pop: 746860.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Piauí',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Maceio',
		city_ascii: 'Maceio',
		lat: -9.619995505,
		lng: -35.72997441,
		pop: 1000215.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Alagoas',
		timezone: 'America/Maceio',
	},
	{
		city: 'Vitoria da Conquista',
		city_ascii: 'Vitoria da Conquista',
		lat: -14.85001219,
		lng: -40.83999841,
		pop: 272320.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Barreiras',
		city_ascii: 'Barreiras',
		lat: -12.13999673,
		lng: -45.00000289,
		pop: 86245.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Vila Velha',
		city_ascii: 'Vila Velha',
		lat: -20.36760822,
		lng: -40.31798893,
		pop: 742413.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Espírito Santo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Natal',
		city_ascii: 'Natal',
		lat: -5.780023174,
		lng: -35.24000431,
		pop: 925521.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Norte',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Campinas',
		city_ascii: 'Campinas',
		lat: -22.90001178,
		lng: -47.10002975,
		pop: 1911277,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Sorocaba',
		city_ascii: 'Sorocaba',
		lat: -23.49000161,
		lng: -47.46998132,
		pop: 561071.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Ribeirao Preto',
		city_ascii: 'Ribeirao Preto',
		lat: -21.17003986,
		lng: -47.82998519,
		pop: 520774,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Petrolina',
		city_ascii: 'Petrolina',
		lat: -9.380010153,
		lng: -40.50996688,
		pop: 227817.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pernambuco',
		timezone: 'America/Recife',
	},
	{
		city: 'Cruzeiro do Sul',
		city_ascii: 'Cruzeiro do Sul',
		lat: -7.629987774,
		lng: -72.66996769,
		pop: 56862,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Acre',
		timezone: 'America/Rio_Branco',
	},
	{
		city: 'Manaus',
		city_ascii: 'Manaus',
		lat: -3.100031719,
		lng: -60.00001754,
		pop: 1636622,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amazonas',
		timezone: 'America/Manaus',
	},
	{
		city: 'Caxias',
		city_ascii: 'Caxias',
		lat: -4.833000876,
		lng: -43.35002608,
		pop: 134640,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Maranhão',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Santarem',
		city_ascii: 'Santarem',
		lat: -2.433250713,
		lng: -54.69997929,
		pop: 209737.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Santarem',
	},
	{
		city: 'Maraba',
		city_ascii: 'Maraba',
		lat: -5.349971905,
		lng: -49.11597905,
		pop: 166182,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Vilhena',
		city_ascii: 'Vilhena',
		lat: -12.71660236,
		lng: -60.11659957,
		pop: 63231,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rondônia',
		timezone: 'America/Porto_Velho',
	},
	{
		city: 'Ji-Parana',
		city_ascii: 'Ji-Parana',
		lat: -10.83330646,
		lng: -61.96700342,
		pop: 65016,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rondônia',
		timezone: 'America/Porto_Velho',
	},
	{
		city: 'Campo Grande',
		city_ascii: 'Campo Grande',
		lat: -20.45003213,
		lng: -54.61662521,
		pop: 687723,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Mato Grosso do Sul',
		timezone: 'America/Campo_Grande',
	},
	{
		city: 'Florianopolis',
		city_ascii: 'Florianopolis',
		lat: -27.57998452,
		lng: -48.52002059,
		pop: 568783,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Santa Catarina',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Feira de Santana',
		city_ascii: 'Feira de Santana',
		lat: -12.25001585,
		lng: -38.9700092,
		pop: 449194.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Boa Vista',
		city_ascii: 'Boa Vista',
		lat: 2.816092955,
		lng: -60.66599756,
		pop: 202299.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Roraima',
		timezone: 'America/Boa_Vista',
	},
	{
		city: 'Macapá',
		city_ascii: 'Macapa',
		lat: 0.033007018,
		lng: -51.0500212,
		pop: 433781.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Amapá',
		timezone: 'America/Belem',
	},
	{
		city: 'Belem',
		city_ascii: 'Belem',
		lat: -1.450003236,
		lng: -48.48002303,
		pop: 1787368.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pará',
		timezone: 'America/Belem',
	},
	{
		city: 'Brasilia',
		city_ascii: 'Brasilia',
		lat: -15.78334023,
		lng: -47.91605229,
		pop: 3139979.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Distrito Federal',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Porto Alegre',
		city_ascii: 'Porto Alegre',
		lat: -30.05001463,
		lng: -51.20001205,
		pop: 2644870.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio Grande do Sul',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Curitiba',
		city_ascii: 'Curitiba',
		lat: -25.420013,
		lng: -49.3199976,
		pop: 2291430,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Paraná',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Fortaleza',
		city_ascii: 'Fortaleza',
		lat: -3.750017884,
		lng: -38.57998132,
		pop: 2958717.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Ceará',
		timezone: 'America/Fortaleza',
	},
	{
		city: 'Salvador',
		city_ascii: 'Salvador',
		lat: -12.9699719,
		lng: -38.47998743,
		pop: 3081422.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Bahia',
		timezone: 'America/Bahia',
	},
	{
		city: 'Goiania',
		city_ascii: 'Goiania',
		lat: -16.72002724,
		lng: -49.30002466,
		pop: 1596597.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Goiás',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Recife',
		city_ascii: 'Recife',
		lat: -8.075645326,
		lng: -34.91560551,
		pop: 2564549,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Pernambuco',
		timezone: 'America/Recife',
	},
	{
		city: 'Rio de Janeiro',
		city_ascii: 'Rio de Janeiro',
		lat: -22.92502317,
		lng: -43.22502079,
		pop: 6879087.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'Rio de Janeiro',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Sao Paulo',
		city_ascii: 'Sao Paulo',
		lat: -23.55867959,
		lng: -46.62501998,
		pop: 14433147.5,
		country: 'Brazil',
		iso2: 'BR',
		iso3: 'BRA',
		province: 'São Paulo',
		timezone: 'America/Sao_Paulo',
	},
	{
		city: 'Bandar Seri Begawan',
		city_ascii: 'Bandar Seri Begawan',
		lat: 4.883331115,
		lng: 114.9332841,
		pop: 218250,
		country: 'Brunei',
		iso2: 'BN',
		iso3: 'BRN',
		province: 'Brunei and Muara',
		timezone: 'Asia/Brunei',
	},
	{
		city: 'Lovec',
		city_ascii: 'Lovec',
		lat: 43.13799911,
		lng: 24.71900459,
		pop: 42211,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Lovech',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Montana',
		city_ascii: 'Montana',
		lat: 43.41400203,
		lng: 23.23700161,
		pop: 47445,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Montana',
		state_ansi: 'MT',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Razgrad',
		city_ascii: 'Razgrad',
		lat: 43.53399903,
		lng: 26.53599663,
		pop: 38285,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Razgrad',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Sliven',
		city_ascii: 'Sliven',
		lat: 42.67937034,
		lng: 26.33001013,
		pop: 87346.5,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Sliven',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Plovdiv',
		city_ascii: 'Plovdiv',
		lat: 42.15397605,
		lng: 24.7539823,
		pop: 319089.5,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Plovdiv',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Pernik',
		city_ascii: 'Pernik',
		lat: 42.60999473,
		lng: 23.02271846,
		pop: 80625,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Pernik',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Vratsa',
		city_ascii: 'Vratsa',
		lat: 43.20998395,
		lng: 23.56253048,
		pop: 68287,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Vratsa',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Shumen',
		city_ascii: 'Shumen',
		lat: 43.27000612,
		lng: 26.92935339,
		pop: 75487.5,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Shumen',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Khaskovo',
		city_ascii: 'Khaskovo',
		lat: 41.94378216,
		lng: 25.5632869,
		pop: 72805,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Haskovo',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Stara Zagora',
		city_ascii: 'Stara Zagora',
		lat: 42.42313275,
		lng: 25.6227148,
		pop: 128315.5,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Stara Zagora',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Pleven',
		city_ascii: 'Pleven',
		lat: 43.42376935,
		lng: 24.61337073,
		pop: 110445.5,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Pleven',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Turnovo',
		city_ascii: 'Turnovo',
		lat: 43.08624473,
		lng: 25.65552934,
		pop: 53115,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Veliko Tarnovo',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Kyustendil',
		city_ascii: 'Kyustendil',
		lat: 42.28427818,
		lng: 22.6911108,
		pop: 49676.5,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Kyustendil',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Dobrich',
		city_ascii: 'Dobrich',
		lat: 43.58505149,
		lng: 27.83999548,
		pop: 73813,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Dobrich',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Varna',
		city_ascii: 'Varna',
		lat: 43.21564252,
		lng: 27.89528926,
		pop: 245522,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Varna',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Ruse',
		city_ascii: 'Ruse',
		lat: 43.85369143,
		lng: 25.97333939,
		pop: 170254,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Ruse',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Burgas',
		city_ascii: 'Burgas',
		lat: 42.51460004,
		lng: 27.47464311,
		pop: 174254,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Burgas',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Sofia',
		city_ascii: 'Sofia',
		lat: 42.68334943,
		lng: 23.31665401,
		pop: 1029913.5,
		country: 'Bulgaria',
		iso2: 'BG',
		iso3: 'BGR',
		province: 'Grad Sofiya',
		timezone: 'Europe/Sofia',
	},
	{
		city: 'Fada Ngourma',
		city_ascii: 'Fada Ngourma',
		lat: 12.05499605,
		lng: 0.360999451,
		pop: 33910,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Gourma',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Orodara',
		city_ascii: 'Orodara',
		lat: 10.97399705,
		lng: -4.907996452,
		pop: 18632,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Kénédougou',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Solenzo',
		city_ascii: 'Solenzo',
		lat: 12.1833333,
		lng: -4.0833333,
		pop: 10385,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Banwa',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Nouna',
		city_ascii: 'Nouna',
		lat: 12.7289971,
		lng: -3.860000519,
		pop: 29048,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Kossi',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Dedougou',
		city_ascii: 'Dedougou',
		lat: 12.455001,
		lng: -3.464000439,
		pop: 45341,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Mou Houn',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Gorom Gorom',
		city_ascii: 'Gorom Gorom',
		lat: 14.45000398,
		lng: -0.233297546,
		pop: 6691,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Oudalan',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Djibo',
		city_ascii: 'Djibo',
		lat: 14.09900404,
		lng: -1.627001421,
		pop: 22223,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Soum',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Tougan',
		city_ascii: 'Tougan',
		lat: 13.06899899,
		lng: -3.069998409,
		pop: 17590,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Sourou',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Kombissiri',
		city_ascii: 'Kombissiri',
		lat: 12.06399605,
		lng: -1.333997543,
		pop: 30137,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Bazéga',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Ziniare',
		city_ascii: 'Ziniare',
		lat: 12.57699605,
		lng: -1.293002442,
		pop: 12703,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Oubritenga',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Yako',
		city_ascii: 'Yako',
		lat: 12.95399712,
		lng: -2.262995501,
		pop: 22904,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Passoré',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Reo',
		city_ascii: 'Reo',
		lat: 12.33349214,
		lng: -2.466944513,
		pop: 37535,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Sanguié',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Leo',
		city_ascii: 'Leo',
		lat: 11.09400299,
		lng: -2.097998529,
		pop: 26884,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Sissili',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Sapouy',
		city_ascii: 'Sapouy',
		lat: 11.5544444,
		lng: -1.7736111,
		pop: 3837,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Ziro',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Boulsa',
		city_ascii: 'Boulsa',
		lat: 12.65699714,
		lng: -0.568997532,
		pop: 17489,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Namentenga',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Zorgo',
		city_ascii: 'Zorgo',
		lat: 12.24299707,
		lng: -0.611000429,
		pop: 23892,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Ganzourgou',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Koupela',
		city_ascii: 'Koupela',
		lat: 12.17700004,
		lng: -0.356003514,
		pop: 32052,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Kouritenga',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Po',
		city_ascii: 'Po',
		lat: 11.16900002,
		lng: -1.134998478,
		pop: 17924,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Nahouri',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Manga',
		city_ascii: 'Manga',
		lat: 11.66200198,
		lng: -1.064996408,
		pop: 15173,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Zoundwéogo',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Diebougou',
		city_ascii: 'Diebougou',
		lat: 10.95200113,
		lng: -3.248000411,
		pop: 12732,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Bougouriba',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Gaoua',
		city_ascii: 'Gaoua',
		lat: 10.32499811,
		lng: -3.174002407,
		pop: 28023,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Poni',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Bogande',
		city_ascii: 'Bogande',
		lat: 12.96900003,
		lng: -0.1379965,
		pop: 9854,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Gnagna',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Dori',
		city_ascii: 'Dori',
		lat: 14.0339971,
		lng: -0.027998519,
		pop: 37806,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Séno',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Sebba',
		city_ascii: 'Sebba',
		lat: 13.4364122,
		lng: 0.530443,
		pop: 3273,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Yagha',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Diapaga',
		city_ascii: 'Diapaga',
		lat: 12.07700108,
		lng: 1.796004571,
		pop: 26013,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Tapoa',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Koudougou',
		city_ascii: 'Koudougou',
		lat: 12.25047833,
		lng: -2.369995159,
		pop: 85339,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Boulkiemdé',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Ouahigouya',
		city_ascii: 'Ouahigouya',
		lat: 13.5704236,
		lng: -2.419992108,
		pop: 70300,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Yatenga',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Kaya',
		city_ascii: 'Kaya',
		lat: 13.09037539,
		lng: -1.090047446,
		pop: 39623,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Sanmatenga',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Tenkodogo',
		city_ascii: 'Tenkodogo',
		lat: 11.78040367,
		lng: -0.369703818,
		pop: 37883,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Boulgou',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Banfora',
		city_ascii: 'Banfora',
		lat: 10.63044802,
		lng: -4.760004315,
		pop: 45903.5,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Komoé',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Bobo Dioulasso',
		city_ascii: 'Bobo Dioulasso',
		lat: 11.1799752,
		lng: -4.289981325,
		pop: 346035,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Houet',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Ouagadougou',
		city_ascii: 'Ouagadougou',
		lat: 12.37031598,
		lng: -1.524723756,
		pop: 992228.5,
		country: 'Burkina Faso',
		iso2: 'BF',
		iso3: 'BFA',
		province: 'Kadiogo',
		timezone: 'Africa/Ouagadougou',
	},
	{
		city: 'Cankuzo',
		city_ascii: 'Cankuzo',
		lat: -3.166703921,
		lng: 30.51669662,
		pop: 6585,
		country: 'Burundi',
		iso2: 'BI',
		iso3: 'BDI',
		province: 'Cankuzo',
		timezone: 'Africa/Bujumbura',
	},
	{
		city: 'Karusi',
		city_ascii: 'Karusi',
		lat: -3.099995968,
		lng: 30.16299648,
		pop: 10705,
		country: 'Burundi',
		iso2: 'BI',
		iso3: 'BDI',
		province: 'Karuzi',
		timezone: 'Africa/Bujumbura',
	},
	{
		city: 'Rutana',
		city_ascii: 'Rutana',
		lat: -3.93100195,
		lng: 29.99300455,
		pop: 20893,
		country: 'Burundi',
		iso2: 'BI',
		iso3: 'BDI',
		province: 'Rutana',
		timezone: 'Africa/Bujumbura',
	},
	{
		city: 'Ruyigi',
		city_ascii: 'Ruyigi',
		lat: -3.481001905,
		lng: 30.2439966,
		pop: 38458,
		country: 'Burundi',
		iso2: 'BI',
		iso3: 'BDI',
		province: 'Ruyigi',
		timezone: 'Africa/Bujumbura',
	},
	{
		city: 'Bubanza',
		city_ascii: 'Bubanza',
		lat: -3.083300984,
		lng: 29.36670351,
		pop: 12728,
		country: 'Burundi',
		iso2: 'BI',
		iso3: 'BDI',
		province: 'Bubanza',
		timezone: 'Africa/Bujumbura',
	},
	{
		city: 'Kayanza',
		city_ascii: 'Kayanza',
		lat: -2.899997879,
		lng: 29.5667016,
		pop: 19443,
		country: 'Burundi',
		iso2: 'BI',
		iso3: 'BDI',
		province: 'Kayanza',
		timezone: 'Africa/Bujumbura',
	},
	{
		city: 'Makamba',
		city_ascii: 'Makamba',
		lat: -4.133303903,
		lng: 29.79999964,
		pop: 19642,
		country: 'Burundi',
		iso2: 'BI',
		iso3: 'BDI',
		province: 'Makamba',
		timezone: 'Africa/Bujumbura',
	},
	{
		city: 'Ngozi',
		city_ascii: 'Ngozi',
		lat: -2.912003884,
		lng: 29.82500157,
		pop: 21506,
		country: 'Burundi',
		iso2: 'BI',
		iso3: 'BDI',
		province: 'Ngozi',
		timezone: 'Africa/Bujumbura',
	},
	{
		city: 'Kirundo',
		city_ascii: 'Kirundo',
		lat: -2.5847222,
		lng: 30.0972222,
		pop: 6083,
		country: 'Burundi',
		iso2: 'BI',
		iso3: 'BDI',
		province: 'Kirundo',
		timezone: 'Africa/Bujumbura',
	},
	{
		city: 'Muramvya',
		city_ascii: 'Muramvya',
		lat: -3.260997006,
		lng: 29.61199862,
		pop: 18041,
		country: 'Burundi',
		iso2: 'BI',
		iso3: 'BDI',
		province: 'Muramvya',
		timezone: 'Africa/Bujumbura',
	},
	{
		city: 'Bururi',
		city_ascii: 'Bururi',
		lat: -3.950729147,
		lng: 29.61657955,
		pop: 20066.5,
		country: 'Burundi',
		iso2: 'BI',
		iso3: 'BDI',
		province: 'Bururi',
		timezone: 'Africa/Bujumbura',
	},
	{
		city: 'Muyinga',
		city_ascii: 'Muyinga',
		lat: -2.852346579,
		lng: 30.31726029,
		pop: 71076,
		country: 'Burundi',
		iso2: 'BI',
		iso3: 'BDI',
		province: 'Muyinga',
		timezone: 'Africa/Bujumbura',
	},
	{
		city: 'Gitega',
		city_ascii: 'Gitega',
		lat: -3.426006654,
		lng: 29.84359411,
		pop: 23167,
		country: 'Burundi',
		iso2: 'BI',
		iso3: 'BDI',
		province: 'Muramvya',
		timezone: 'Africa/Bujumbura',
	},
	{
		city: 'London',
		city_ascii: 'London',
		lat: 51.49999473,
		lng: -0.116721844,
		pop: 7994104.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'England',
		timezone: 'Europe/London',
	},
	{
		city: 'Bujumbura',
		city_ascii: 'Bujumbura',
		lat: -3.37608722,
		lng: 29.36000606,
		pop: 331700,
		country: 'Burundi',
		iso2: 'BI',
		iso3: 'BDI',
		province: 'Bujumbura Mairie',
		timezone: 'Africa/Bujumbura',
	},
	{
		city: 'Kampong Spoe',
		city_ascii: 'Kampong Spoe',
		lat: 11.4519961,
		lng: 104.5189986,
		pop: 33231,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Kâmpóng Spœ',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Kampong Thum',
		city_ascii: 'Kampong Thum',
		lat: 12.71199613,
		lng: 104.8889977,
		pop: 19951,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Kâmpóng Thum',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Prey Veng',
		city_ascii: 'Prey Veng',
		lat: 11.48399998,
		lng: 105.3240036,
		pop: 74000,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Prey Vêng',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Phnum Tbeng Meanchey',
		city_ascii: 'Phnum Tbeng Meanchey',
		lat: 13.816701,
		lng: 104.9667037,
		pop: 24380,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Preah Vihéar',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Stoeng Treng',
		city_ascii: 'Stoeng Treng',
		lat: 13.52300408,
		lng: 105.9740016,
		pop: 29665,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Stœng Trêng',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Kracheh',
		city_ascii: 'Kracheh',
		lat: 12.46899612,
		lng: 106.0239967,
		pop: 19975,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Krâchéh',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Senmonorom',
		city_ascii: 'Senmonorom',
		lat: 12.44999711,
		lng: 107.1999997,
		pop: 7944,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Môndól Kiri',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Lumphat',
		city_ascii: 'Lumphat',
		lat: 13.50700214,
		lng: 106.9810026,
		pop: 19205,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Rôtânôkiri',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Svay Rieng',
		city_ascii: 'Svay Rieng',
		lat: 11.0799991,
		lng: 105.8010036,
		pop: 23956,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Svay Rieng',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Sisophon',
		city_ascii: 'Sisophon',
		lat: 13.58375612,
		lng: 102.9833158,
		pop: 36760,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Bântéay Méanchey',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Krong Koh Kong',
		city_ascii: 'Krong Koh Kong',
		lat: 11.61753897,
		lng: 102.9849329,
		pop: 30285,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Kaôh Kong',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Pursat',
		city_ascii: 'Pursat',
		lat: 12.53369102,
		lng: 103.9166955,
		pop: 32961,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Pouthisat',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Kampong Cham',
		city_ascii: 'Kampong Cham',
		lat: 12.00044191,
		lng: 105.4500386,
		pop: 72491.5,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Kâmpóng Cham',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Kompong Chhnang',
		city_ascii: 'Kompong Chhnang',
		lat: 12.25047833,
		lng: 104.6666239,
		pop: 65817,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Kâmpóng Chhnang',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Kampot',
		city_ascii: 'Kampot',
		lat: 10.61708966,
		lng: 104.1833459,
		pop: 36398,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Kâmpôt',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Takeo',
		city_ascii: 'Takeo',
		lat: 10.98375978,
		lng: 104.7833093,
		pop: 15264,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Takêv',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Battambang',
		city_ascii: 'Battambang',
		lat: 13.10001304,
		lng: 103.2000468,
		pop: 152608.5,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Batdâmbâng',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Siem Reap',
		city_ascii: 'Siem Reap',
		lat: 13.36663759,
		lng: 103.8500329,
		pop: 97199,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Siemréab',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Phnom Penh',
		city_ascii: 'Phnom Penh',
		lat: 11.55003013,
		lng: 104.9166345,
		pop: 1466000,
		country: 'Cambodia',
		iso2: 'KH',
		iso3: 'KHM',
		province: 'Phnom Penh',
		timezone: 'Asia/Phnom_Penh',
	},
	{
		city: 'Buea',
		city_ascii: 'Buea',
		lat: 4.155003087,
		lng: 9.231003513,
		pop: 90088,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Sud-Ouest',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Bafang',
		city_ascii: 'Bafang',
		lat: 5.170393696,
		lng: 10.17998816,
		pop: 86916.5,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Ouest',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Foumban',
		city_ascii: 'Foumban',
		lat: 5.730385355,
		lng: 10.89999589,
		pop: 64399,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Ouest',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Bafoussam',
		city_ascii: 'Bafoussam',
		lat: 5.490425841,
		lng: 10.40994828,
		pop: 290768,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Ouest',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Kumba',
		city_ascii: 'Kumba',
		lat: 4.640374368,
		lng: 9.439981647,
		pop: 131122,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Sud-Ouest',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Eyumojok',
		city_ascii: 'Eyumojok',
		lat: 5.750358296,
		lng: 8.983317015,
		pop: 5798,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Sud-Ouest',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Limbe',
		city_ascii: 'Limbe',
		lat: 4.030385761,
		lng: 9.190022744,
		pop: 142290,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Sud-Ouest',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Kribi',
		city_ascii: 'Kribi',
		lat: 2.940426452,
		lng: 9.910030476,
		pop: 31473,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Sud',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Nkongsamba',
		city_ascii: 'Nkongsamba',
		lat: 4.960406513,
		lng: 9.940002806,
		pop: 105069,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Littoral',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Edea',
		city_ascii: 'Edea',
		lat: 3.800477314,
		lng: 10.11999182,
		pop: 109506.5,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Littoral',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Wum',
		city_ascii: 'Wum',
		lat: 6.400421976,
		lng: 10.07002071,
		pop: 42601,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Nord-Ouest',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Kumbo',
		city_ascii: 'Kumbo',
		lat: 6.220381286,
		lng: 10.68000932,
		pop: 89728,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Nord-Ouest',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Bafia',
		city_ascii: 'Bafia',
		lat: 4.750393493,
		lng: 11.23000159,
		pop: 41201,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Centre',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Mbalmayo',
		city_ascii: 'Mbalmayo',
		lat: 3.520391051,
		lng: 11.50001094,
		pop: 53501.5,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Centre',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Eseka',
		city_ascii: 'Eseka',
		lat: 3.650408955,
		lng: 10.76661902,
		pop: 14102,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Centre',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Bertoua',
		city_ascii: 'Bertoua',
		lat: 4.580429707,
		lng: 13.67998124,
		pop: 153286.5,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Est',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Abong Mbang',
		city_ascii: 'Abong Mbang',
		lat: 3.983696105,
		lng: 13.18331905,
		pop: 7698,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Est',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Batouri',
		city_ascii: 'Batouri',
		lat: 4.433694477,
		lng: 14.366606,
		pop: 42271,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Est',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Belabo',
		city_ascii: 'Belabo',
		lat: 4.933689798,
		lng: 13.30000443,
		pop: 11455.5,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Est',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Meiganga',
		city_ascii: 'Meiganga',
		lat: 6.520492166,
		lng: 14.28996985,
		pop: 54864.5,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Adamaoua',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Ngaoundere',
		city_ascii: 'Ngaoundere',
		lat: 7.320365823,
		lng: 13.57998734,
		pop: 134322.5,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Adamaoua',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Tibati',
		city_ascii: 'Tibati',
		lat: 6.46698122,
		lng: 12.63332678,
		pop: 22096,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Adamaoua',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Kontcha',
		city_ascii: 'Kontcha',
		lat: 7.967018859,
		lng: 12.23329952,
		pop: 5846,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Adamaoua',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Guider',
		city_ascii: 'Guider',
		lat: 9.930387389,
		lng: 13.94001705,
		pop: 83319,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Nord',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Mbe',
		city_ascii: 'Mbe',
		lat: 7.850436828,
		lng: 13.6000378,
		pop: 3950,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Nord',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Douala',
		city_ascii: 'Douala',
		lat: 4.060409769,
		lng: 9.709991006,
		pop: 1622041,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Littoral',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Ebolowa',
		city_ascii: 'Ebolowa',
		lat: 2.900015481,
		lng: 11.15000647,
		pop: 83687.5,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Sud',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Bamenda',
		city_ascii: 'Bamenda',
		lat: 5.959983743,
		lng: 10.15001583,
		pop: 419567,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Nord-Ouest',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Garoua',
		city_ascii: 'Garoua',
		lat: 9.30001243,
		lng: 13.39002478,
		pop: 365436.5,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Nord',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Maroua',
		city_ascii: 'Maroua',
		lat: 10.59556643,
		lng: 14.32469641,
		pop: 260656,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Extrême-Nord',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Yaounde',
		city_ascii: 'Yaounde',
		lat: 3.866700662,
		lng: 11.51665076,
		pop: 1335793.5,
		country: 'Cameroon',
		iso2: 'CM',
		iso3: 'CMR',
		province: 'Centre',
		timezone: 'Africa/Douala',
	},
	{
		city: 'Selkirk',
		city_ascii: 'Selkirk',
		lat: 50.15002545,
		lng: -96.88332178,
		pop: 9819.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Berens River',
		city_ascii: 'Berens River',
		lat: 52.36655682,
		lng: -97.03331262,
		pop: 522.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Pukatawagan',
		city_ascii: 'Pukatawagan',
		lat: 55.73327639,
		lng: -101.3166171,
		pop: 431,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Gimli',
		city_ascii: 'Gimli',
		lat: 50.63330345,
		lng: -96.99998133,
		pop: 2316,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Island Lake',
		city_ascii: 'Island Lake',
		lat: 53.96658836,
		lng: -94.7665776,
		pop: 10,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Melville',
		city_ascii: 'Melville',
		lat: 50.93331097,
		lng: -102.7999891,
		pop: 4226,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'Weyburn',
		city_ascii: 'Weyburn',
		lat: 49.66656659,
		lng: -103.8500025,
		pop: 9302.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'La Ronge',
		city_ascii: 'La Ronge',
		lat: 55.10000755,
		lng: -105.3000173,
		pop: 3427,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'Stony Rapids',
		city_ascii: 'Stony Rapids',
		lat: 59.26657493,
		lng: -105.8332664,
		pop: 152,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'Camrose',
		city_ascii: 'Camrose',
		lat: 53.01669802,
		lng: -112.8166386,
		pop: 15747,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Hinton',
		city_ascii: 'Hinton',
		lat: 53.39998212,
		lng: -117.5833503,
		pop: 10077,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Vegreville',
		city_ascii: 'Vegreville',
		lat: 53.49997601,
		lng: -112.0499671,
		pop: 5745.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Stettler',
		city_ascii: 'Stettler',
		lat: 52.33296714,
		lng: -112.6832876,
		pop: 5449.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Lac La Biche',
		city_ascii: 'Lac La Biche',
		lat: 54.77193972,
		lng: -111.964701,
		pop: 2952.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Wetaskiwin',
		city_ascii: 'Wetaskiwin',
		lat: 52.96657188,
		lng: -113.3832966,
		pop: 11562.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Meander River',
		city_ascii: 'Meander River',
		lat: 59.03328168,
		lng: -117.6829824,
		pop: 200,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Creston',
		city_ascii: 'Creston',
		lat: 49.09996035,
		lng: -116.516697,
		pop: 4816,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Creston',
	},
	{
		city: 'Cranbrook',
		city_ascii: 'Cranbrook',
		lat: 49.5166791,
		lng: -115.7666653,
		pop: 17990,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Terrace',
		city_ascii: 'Terrace',
		lat: 54.49999249,
		lng: -128.5833248,
		pop: 14772,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Chilliwack',
		city_ascii: 'Chilliwack',
		lat: 49.16664878,
		lng: -121.949983,
		pop: 51942,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Hall Beach',
		city_ascii: 'Hall Beach',
		lat: 68.76746684,
		lng: -81.23608303,
		pop: 654,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Iqaluit',
	},
	{
		city: 'Lutselke',
		city_ascii: 'Lutselke',
		lat: 62.40005292,
		lng: -110.7333291,
		pop: 102,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Northwest Territories',
		timezone: 'America/Yellowknife',
	},
	{
		city: 'Hay River',
		city_ascii: 'Hay River',
		lat: 60.84999249,
		lng: -115.7000027,
		pop: 3774,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Northwest Territories',
		timezone: 'America/Yellowknife',
	},
	{
		city: 'Déline',
		city_ascii: 'Deline',
		lat: 65.18334556,
		lng: -123.4166635,
		pop: 393.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Northwest Territories',
		timezone: 'America/Yellowknife',
	},
	{
		city: 'Paulatuk',
		city_ascii: 'Paulatuk',
		lat: 69.38332176,
		lng: -123.9833214,
		pop: 294,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Northwest Territories',
		timezone: 'America/Yellowknife',
	},
	{
		city: 'Tsiigehtchic',
		city_ascii: 'Tsiigehtchic',
		lat: 67.43328575,
		lng: -133.7499862,
		pop: 175,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Northwest Territories',
		timezone: 'America/Yellowknife',
	},
	{
		city: 'Owen Sound',
		city_ascii: 'Owen Sound',
		lat: 44.56664532,
		lng: -80.84998519,
		pop: 22625,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Orillia',
		city_ascii: 'Orillia',
		lat: 44.59997662,
		lng: -79.41666183,
		pop: 33830.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Kapuskasing',
		city_ascii: 'Kapuskasing',
		lat: 49.4166852,
		lng: -82.43332524,
		pop: 8732,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Thessalon',
		city_ascii: 'Thessalon',
		lat: 46.24997927,
		lng: -83.55000126,
		pop: 1416.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Geraldton',
		city_ascii: 'Geraldton',
		lat: 49.71664105,
		lng: -86.96664026,
		pop: 1290,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Belleville',
		city_ascii: 'Belleville',
		lat: 44.16666974,
		lng: -77.38334924,
		pop: 43990,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Sarnia',
		city_ascii: 'Sarnia',
		lat: 42.96663963,
		lng: -82.3999681,
		pop: 113585,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Peterborough',
		city_ascii: 'Peterborough',
		lat: 44.29996909,
		lng: -78.33326542,
		pop: 79752,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Oshawa',
		city_ascii: 'Oshawa',
		lat: 43.87999473,
		lng: -78.84997807,
		pop: 349476,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'London',
		city_ascii: 'London',
		lat: 42.9699986,
		lng: -81.24998661,
		pop: 340900,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Kitchener',
		city_ascii: 'Kitchener',
		lat: 43.44999514,
		lng: -80.50000655,
		pop: 413056.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'New Liskeard',
		city_ascii: 'New Liskeard',
		lat: 47.50000633,
		lng: -79.66664657,
		pop: 5203,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Brockville',
		city_ascii: 'Brockville',
		lat: 44.5892796,
		lng: -75.69531275,
		pop: 25172,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Big Beaver House',
		city_ascii: 'Big Beaver House',
		lat: 52.94998374,
		lng: -89.88328394,
		pop: 10,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Port-Menier',
		city_ascii: 'Port-Menier',
		lat: 49.82257774,
		lng: -64.34799504,
		pop: 263,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Riviere-du-Loup',
		city_ascii: 'Riviere-du-Loup',
		lat: 47.83329348,
		lng: -69.53331161,
		pop: 16403,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Drummondville',
		city_ascii: 'Drummondville',
		lat: 45.88333498,
		lng: -72.4833641,
		pop: 56806,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Sherbrooke',
		city_ascii: 'Sherbrooke',
		lat: 45.40000531,
		lng: -71.89998885,
		pop: 134549.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Cap-Chat',
		city_ascii: 'Cap-Chat',
		lat: 49.09996035,
		lng: -66.68330469,
		pop: 1475,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Baie-Comeau',
		city_ascii: 'Baie-Comeau',
		lat: 49.22266604,
		lng: -68.15799504,
		pop: 8808,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Natashquan',
		city_ascii: 'Natashquan',
		lat: 50.19134076,
		lng: -61.81065637,
		pop: 722,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Eastmain',
		city_ascii: 'Eastmain',
		lat: 52.23333498,
		lng: -78.51669092,
		pop: 335,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Schefferville',
		city_ascii: 'Schefferville',
		lat: 54.80000002,
		lng: -66.81665572,
		pop: 471,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Salluit',
		city_ascii: 'Salluit',
		lat: 62.18259849,
		lng: -75.65950098,
		pop: 106,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Amos',
		city_ascii: 'Amos',
		lat: 48.56663373,
		lng: -78.11666366,
		pop: 10475.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Joliette',
		city_ascii: 'Joliette',
		lat: 46.03332583,
		lng: -73.43330611,
		pop: 40066.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'St.-Jerome',
		city_ascii: 'St.-Jerome',
		lat: 45.7666496,
		lng: -73.99998987,
		pop: 66693.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'St-Augustin',
		city_ascii: 'St-Augustin',
		lat: 51.2423102,
		lng: -58.64699935,
		pop: 3961,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Blanc-Sablon',
	},
	{
		city: 'Rouyn-Noranda',
		city_ascii: 'Rouyn-Noranda',
		lat: 48.25001223,
		lng: -79.03324854,
		pop: 24312.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'La Sarre',
		city_ascii: 'La Sarre',
		lat: 48.8000045,
		lng: -79.20003422,
		pop: 6366.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'New Glasgow',
		city_ascii: 'New Glasgow',
		lat: 45.58327578,
		lng: -62.63331934,
		pop: 19883.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nova Scotia',
		timezone: 'America/Halifax',
	},
	{
		city: 'Liverpool',
		city_ascii: 'Liverpool',
		lat: 44.03995913,
		lng: -64.72001367,
		pop: 4331,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nova Scotia',
		timezone: 'America/Halifax',
	},
	{
		city: 'Amherst',
		city_ascii: 'Amherst',
		lat: 45.81656903,
		lng: -64.21658187,
		pop: 8631.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nova Scotia',
		timezone: 'America/Halifax',
	},
	{
		city: 'Baddeck',
		city_ascii: 'Baddeck',
		lat: 46.09998842,
		lng: -60.75397669,
		pop: 852,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nova Scotia',
		timezone: 'America/Halifax',
	},
	{
		city: 'Deer Lake',
		city_ascii: 'Deer Lake',
		lat: 49.17440025,
		lng: -57.42691878,
		pop: 3953,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/St_Johns',
	},
	{
		city: 'La Scie',
		city_ascii: 'La Scie',
		lat: 49.96701337,
		lng: -55.58300033,
		pop: 817,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/St_Johns',
	},
	{
		city: 'Hopedale',
		city_ascii: 'Hopedale',
		lat: 55.44996035,
		lng: -60.21667098,
		pop: 442,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/Goose_Bay',
	},
	{
		city: 'Happy Valley - Goose Bay',
		city_ascii: 'Happy Valley - Goose Bay',
		lat: 53.29998822,
		lng: -60.29999923,
		pop: 4309.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/Goose_Bay',
	},
	{
		city: 'Port Hope Simpson',
		city_ascii: 'Port Hope Simpson',
		lat: 52.53329083,
		lng: -56.30001083,
		pop: 197,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/St_Johns',
	},
	{
		city: 'Tofino',
		city_ascii: 'Tofino',
		lat: 49.15207146,
		lng: -125.9031487,
		pop: 1655,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Steinbach',
		city_ascii: 'Steinbach',
		lat: 49.51709251,
		lng: -96.68330815,
		pop: 9668,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Nelson House',
		city_ascii: 'Nelson House',
		lat: 55.80045575,
		lng: -98.85002344,
		pop: 2500,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Shamattawa',
		city_ascii: 'Shamattawa',
		lat: 55.85037518,
		lng: -92.08327885,
		pop: 870,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Oxford House',
		city_ascii: 'Oxford House',
		lat: 54.95040428,
		lng: -95.26659876,
		pop: 184,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Yorkton',
		city_ascii: 'Yorkton',
		lat: 51.21706626,
		lng: -102.4665469,
		pop: 14377.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'Swift Current',
		city_ascii: 'Swift Current',
		lat: 50.28373822,
		lng: -107.766611,
		pop: 14804.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'Biggar',
		city_ascii: 'Biggar',
		lat: 52.05040041,
		lng: -107.9832902,
		pop: 2130,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'Kindersley',
		city_ascii: 'Kindersley',
		lat: 51.46697349,
		lng: -109.1332976,
		pop: 4316,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'Meadow Lake',
		city_ascii: 'Meadow Lake',
		lat: 54.13011843,
		lng: -108.4347356,
		pop: 5081.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'Hudson Bay',
		city_ascii: 'Hudson Bay',
		lat: 52.8504291,
		lng: -102.3832961,
		pop: 1909,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'Lethbridge',
		city_ascii: 'Lethbridge',
		lat: 49.70049217,
		lng: -112.8332784,
		pop: 64594,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Brooks',
		city_ascii: 'Brooks',
		lat: 50.56705426,
		lng: -111.9000021,
		pop: 13453.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Lake Louise',
		city_ascii: 'Lake Louise',
		lat: 51.43369387,
		lng: -116.1832807,
		pop: 1248,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Athabasca',
		city_ascii: 'Athabasca',
		lat: 54.71703351,
		lng: -113.2665853,
		pop: 2399.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Fort Chipewyan',
		city_ascii: 'Fort Chipewyan',
		lat: 58.71709943,
		lng: -111.1499962,
		pop: 3222,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Bella Bella',
		city_ascii: 'Bella Bella',
		lat: 52.14840476,
		lng: -128.1172809,
		pop: 1400,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Sandspit',
		city_ascii: 'Sandspit',
		lat: 53.24040529,
		lng: -131.8332815,
		pop: 538,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Campbell River',
		city_ascii: 'Campbell River',
		lat: 50.01708783,
		lng: -125.2499882,
		pop: 29941.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Port Hardy',
		city_ascii: 'Port Hardy',
		lat: 50.71707094,
		lng: -127.4999801,
		pop: 2295,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Nanaimo',
		city_ascii: 'Nanaimo',
		lat: 49.14602021,
		lng: -123.9342911,
		pop: 82698,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Quesnel',
		city_ascii: 'Quesnel',
		lat: 52.98367678,
		lng: -122.4832837,
		pop: 13788,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Abbotsford',
		city_ascii: 'Abbotsford',
		lat: 49.05037681,
		lng: -122.2999874,
		pop: 151683,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Dawson Creek',
		city_ascii: 'Dawson Creek',
		lat: 55.76696942,
		lng: -120.233266,
		pop: 10676.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Dawson_Creek',
	},
	{
		city: 'Penticton',
		city_ascii: 'Penticton',
		lat: 49.50037518,
		lng: -119.5832799,
		pop: 34035,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Nelson',
		city_ascii: 'Nelson',
		lat: 49.48365786,
		lng: -117.2832911,
		pop: 10796,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Lillooet',
		city_ascii: 'Lillooet',
		lat: 50.68371381,
		lng: -121.9332656,
		pop: 2893,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Powell River',
		city_ascii: 'Powell River',
		lat: 49.88371096,
		lng: -124.5499793,
		pop: 7999.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Revelstoke',
		city_ascii: 'Revelstoke',
		lat: 51.0004645,
		lng: -118.1833395,
		pop: 7600.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Burns Lake',
		city_ascii: 'Burns Lake',
		lat: 54.21701235,
		lng: -125.7665975,
		pop: 2633,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Dease Lake',
		city_ascii: 'Dease Lake',
		lat: 58.45034568,
		lng: -130.033288,
		pop: 303,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Coral Harbour',
		city_ascii: 'Coral Harbour',
		lat: 64.15377016,
		lng: -83.17658736,
		pop: 834,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Coral_Harbour',
	},
	{
		city: 'Baker Lake',
		city_ascii: 'Baker Lake',
		lat: 64.31699017,
		lng: -96.01665633,
		pop: 1584,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Rankin_Inlet',
	},
	{
		city: 'Norman Wells',
		city_ascii: 'Norman Wells',
		lat: 65.28372703,
		lng: -126.8499681,
		pop: 650,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Northwest Territories',
		timezone: 'America/Yellowknife',
	},
	{
		city: 'Fort McPherson',
		city_ascii: 'Fort McPherson',
		lat: 67.49152508,
		lng: -134.8949809,
		pop: 1069,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Northwest Territories',
		timezone: 'America/Yellowknife',
	},
	{
		city: 'Burwash Landing',
		city_ascii: 'Burwash Landing',
		lat: 61.35037539,
		lng: -139.0000017,
		pop: 73,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Yukon',
		timezone: 'America/Whitehorse',
	},
	{
		city: 'Orangeville',
		city_ascii: 'Orangeville',
		lat: 43.91707257,
		lng: -80.08333948,
		pop: 30812,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Little Current',
		city_ascii: 'Little Current',
		lat: 45.96702496,
		lng: -81.93332992,
		pop: 1595,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Chapleau',
		city_ascii: 'Chapleau',
		lat: 47.83370689,
		lng: -83.40001042,
		pop: 2663,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Wawa',
		city_ascii: 'Wawa',
		lat: 48.00041506,
		lng: -84.78333683,
		pop: 2174,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Hearst',
		city_ascii: 'Hearst',
		lat: 49.70049217,
		lng: -83.66658329,
		pop: 4894.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Marathon',
		city_ascii: 'Marathon',
		lat: 48.75039512,
		lng: -86.36665104,
		pop: 4627,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Sioux Lookout',
		city_ascii: 'Sioux Lookout',
		lat: 50.26296429,
		lng: -91.9166482,
		pop: 4570,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Red Lake',
		city_ascii: 'Red Lake',
		lat: 51.03369244,
		lng: -93.83330123,
		pop: 1765,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Deer Lake',
		city_ascii: 'Deer Lake',
		lat: 52.61703249,
		lng: -94.06659448,
		pop: 3743,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Cat Lake',
		city_ascii: 'Cat Lake',
		lat: 51.71703575,
		lng: -91.79998865,
		pop: 277,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Cornwall',
		city_ascii: 'Cornwall',
		lat: 45.01705711,
		lng: -74.73333012,
		pop: 47601.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Kingston',
		city_ascii: 'Kingston',
		lat: 44.23371991,
		lng: -76.48330082,
		pop: 108297.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Barrie',
		city_ascii: 'Barrie',
		lat: 44.38376243,
		lng: -79.7000037,
		pop: 150886.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Parry Sound',
		city_ascii: 'Parry Sound',
		lat: 45.33370445,
		lng: -80.03300663,
		pop: 6787,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Wiarton',
		city_ascii: 'Wiarton',
		lat: 44.73368939,
		lng: -81.13330123,
		pop: 2182,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Cobalt',
		city_ascii: 'Cobalt',
		lat: 47.38370852,
		lng: -79.68331222,
		pop: 1372,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Cochrane',
		city_ascii: 'Cochrane',
		lat: 49.06701662,
		lng: -81.01656417,
		pop: 4441,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Nipigon',
		city_ascii: 'Nipigon',
		lat: 49.01704551,
		lng: -88.24997278,
		pop: 1204,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Nipigon',
	},
	{
		city: 'Atikokan',
		city_ascii: 'Atikokan',
		lat: 48.75039512,
		lng: -91.61658899,
		pop: 3625,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Atikokan',
	},
	{
		city: 'Rimouski',
		city_ascii: 'Rimouski',
		lat: 48.43374778,
		lng: -68.51668115,
		pop: 35584,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Saint-Georges',
		city_ascii: 'Saint-Georges',
		lat: 46.117145,
		lng: -70.66665328,
		pop: 26149,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Victoriaville',
		city_ascii: 'Victoriaville',
		lat: 46.05040489,
		lng: -71.96667729,
		pop: 37963,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Chevery',
		city_ascii: 'Chevery',
		lat: 50.48132306,
		lng: -59.60978296,
		pop: 284,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Blanc-Sablon',
	},
	{
		city: 'Mistassini',
		city_ascii: 'Mistassini',
		lat: 50.41706341,
		lng: -73.86658716,
		pop: 2645,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Kangirsuk',
		city_ascii: 'Kangirsuk',
		lat: 60.02482322,
		lng: -69.99909713,
		pop: 549,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Shawinigan',
		city_ascii: 'Shawinigan',
		lat: 46.55037437,
		lng: -72.733323,
		pop: 41751.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Matagami',
		city_ascii: 'Matagami',
		lat: 49.75038576,
		lng: -77.63328231,
		pop: 1574.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Mont-Laurier',
		city_ascii: 'Mont-Laurier',
		lat: 46.55037437,
		lng: -75.50002751,
		pop: 11642,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Pembroke',
		city_ascii: 'Pembroke',
		lat: 45.85026206,
		lng: -77.11664718,
		pop: 15551,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Radisson',
		city_ascii: 'Radisson',
		lat: 53.78362795,
		lng: -77.61656498,
		pop: 270,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Saint John',
		city_ascii: 'Saint John',
		lat: 45.26704185,
		lng: -66.07667505,
		pop: 71153,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'New Brunswick',
		timezone: 'America/Moncton',
	},
	{
		city: 'Edmundston',
		city_ascii: 'Edmundston',
		lat: 47.37941937,
		lng: -68.3332815,
		pop: 17894,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'New Brunswick',
		timezone: 'America/Moncton',
	},
	{
		city: 'Shelburne',
		city_ascii: 'Shelburne',
		lat: 43.76560895,
		lng: -65.31935694,
		pop: 2553,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nova Scotia',
		timezone: 'America/Halifax',
	},
	{
		city: 'Antigonish',
		city_ascii: 'Antigonish',
		lat: 45.62691652,
		lng: -61.99819015,
		pop: 5871,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nova Scotia',
		timezone: 'America/Halifax',
	},
	{
		city: 'Windsor',
		city_ascii: 'Windsor',
		lat: 44.98059938,
		lng: -64.12911951,
		pop: 3759,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nova Scotia',
		timezone: 'America/Halifax',
	},
	{
		city: 'Digby',
		city_ascii: 'Digby',
		lat: 44.62258506,
		lng: -65.7604928,
		pop: 3000.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nova Scotia',
		timezone: 'America/Halifax',
	},
	{
		city: 'Stephenville',
		city_ascii: 'Stephenville',
		lat: 48.55040733,
		lng: -58.56656498,
		pop: 6666,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/St_Johns',
	},
	{
		city: 'Argentia',
		city_ascii: 'Argentia',
		lat: 47.30043194,
		lng: -53.98999679,
		pop: 1063,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/St_Johns',
	},
	{
		city: 'St. Anthony',
		city_ascii: 'St. Anthony',
		lat: 51.3837486,
		lng: -55.5999502,
		pop: 224,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/St_Johns',
	},
	{
		city: 'Channel-Port aux Basques',
		city_ascii: 'Channel-Port aux Basques',
		lat: 47.56700482,
		lng: -59.15004358,
		pop: 3232,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/St_Johns',
	},
	{
		city: 'Buchans',
		city_ascii: 'Buchans',
		lat: 48.81703188,
		lng: -56.86659123,
		pop: 685,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/St_Johns',
	},
	{
		city: 'Trout River',
		city_ascii: 'Trout River',
		lat: 49.48365786,
		lng: -58.11664413,
		pop: 452,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/St_Johns',
	},
	{
		city: 'Churchill Falls',
		city_ascii: 'Churchill Falls',
		lat: 53.52640851,
		lng: -63.98067896,
		pop: 75,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/Goose_Bay',
	},
	{
		city: 'Forteau',
		city_ascii: 'Forteau',
		lat: 51.45038535,
		lng: -56.94999699,
		pop: 448,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/St_Johns',
	},
	{
		city: 'Trepassey',
		city_ascii: 'Trepassey',
		lat: 46.73697797,
		lng: -53.36329085,
		pop: 398,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/St_Johns',
	},
	{
		city: 'Brochet',
		city_ascii: 'Brochet',
		lat: 57.88322268,
		lng: -101.6665957,
		pop: 278,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Lynn Lake',
		city_ascii: 'Lynn Lake',
		lat: 56.85002993,
		lng: -101.0499667,
		pop: 482,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Gillam',
		city_ascii: 'Gillam',
		lat: 56.34998293,
		lng: -94.69996668,
		pop: 1281,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'North Battleford',
		city_ascii: 'North Battleford',
		lat: 52.76663576,
		lng: -108.2833494,
		pop: 15721.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'Prince Albert',
		city_ascii: 'Prince Albert',
		lat: 53.20002016,
		lng: -105.7499899,
		pop: 29643.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'Courtenay',
		city_ascii: 'Courtenay',
		lat: 49.68333559,
		lng: -124.9999777,
		pop: 28946,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Kelowna',
		city_ascii: 'Kelowna',
		lat: 49.89998903,
		lng: -119.4833118,
		pop: 110207.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Pangnirtung',
		city_ascii: 'Pangnirtung',
		lat: 66.13331341,
		lng: -65.75002832,
		pop: 1320,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Pangnirtung',
	},
	{
		city: 'Holman',
		city_ascii: 'Holman',
		lat: 70.73336855,
		lng: -117.7499809,
		pop: 449,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Northwest Territories',
		timezone: 'America/Yellowknife',
	},
	{
		city: 'Dryden',
		city_ascii: 'Dryden',
		lat: 49.78332949,
		lng: -92.83333643,
		pop: 7862,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Attawapiskat',
		city_ascii: 'Attawapiskat',
		lat: 52.91662661,
		lng: -82.43332524,
		pop: 1802,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Hamilton',
		city_ascii: 'Hamilton',
		lat: 43.24998151,
		lng: -79.82999577,
		pop: 620501,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Windsor',
		city_ascii: 'Windsor',
		lat: 42.33329327,
		lng: -83.03334029,
		pop: 265068.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Detroit',
	},
	{
		city: 'Trois-Rivières',
		city_ascii: 'Trois Rivieres',
		lat: 46.34997316,
		lng: -72.54994918,
		pop: 118051,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Sept-Îles',
		city_ascii: 'Sept-Iles',
		lat: 50.31608767,
		lng: -66.36001693,
		pop: 25686,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Corner Brook',
		city_ascii: 'Corner Brook',
		lat: 48.94999534,
		lng: -57.93334782,
		pop: 19742,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/St_Johns',
	},
	{
		city: 'Norway House',
		city_ascii: 'Norway House',
		lat: 53.96658836,
		lng: -97.83328963,
		pop: 5500,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Flin Flon',
		city_ascii: 'Flin Flon',
		lat: 54.76659121,
		lng: -101.8833008,
		pop: 6197.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Dauphin',
		city_ascii: 'Dauphin',
		lat: 51.15001609,
		lng: -100.0499502,
		pop: 8747.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'The Pas',
		city_ascii: 'The Pas',
		lat: 53.81662335,
		lng: -101.2333147,
		pop: 4928.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Uranium City',
		city_ascii: 'Uranium City',
		lat: 59.56655662,
		lng: -108.6165849,
		pop: 89,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'Moose Jaw',
		city_ascii: 'Moose Jaw',
		lat: 50.39998435,
		lng: -105.5500021,
		pop: 31436.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'Jasper',
		city_ascii: 'Jasper',
		lat: 52.88332115,
		lng: -118.0833714,
		pop: 3504.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Medicine Hat',
		city_ascii: 'Medicine Hat',
		lat: 50.03331423,
		lng: -110.6833322,
		pop: 58382,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Red Deer',
		city_ascii: 'Red Deer',
		lat: 52.26664044,
		lng: -113.8000411,
		pop: 74225,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Banff',
		city_ascii: 'Banff',
		lat: 51.17799888,
		lng: -115.5719227,
		pop: 6897,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Grand Prairie',
		city_ascii: 'Grand Prairie',
		lat: 55.16664431,
		lng: -118.7999943,
		pop: 41153.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Smithers',
		city_ascii: 'Smithers',
		lat: 54.76659121,
		lng: -127.1665896,
		pop: 5841.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Kamloops',
		city_ascii: 'Kamloops',
		lat: 50.66666058,
		lng: -120.3332858,
		pop: 68671,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Williams Lake',
		city_ascii: 'Williams Lake',
		lat: 52.1166496,
		lng: -122.1499966,
		pop: 12361,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Prince George',
		city_ascii: 'Prince George',
		lat: 53.91666892,
		lng: -122.7666515,
		pop: 64132.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Fort Nelson',
		city_ascii: 'Fort Nelson',
		lat: 58.81670575,
		lng: -122.5329706,
		pop: 6315,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Fort_Nelson',
	},
	{
		city: 'Pond Inlet',
		city_ascii: 'Pond Inlet',
		lat: 72.68498069,
		lng: -78.00005579,
		pop: 1549,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Iqaluit',
	},
	{
		city: 'Cape Dorset',
		city_ascii: 'Cape Dorset',
		lat: 64.31251972,
		lng: -76.53857863,
		pop: 1326,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Iqaluit',
	},
	{
		city: 'Kimmirut',
		city_ascii: 'Kimmirut',
		lat: 62.85002545,
		lng: -69.88331608,
		pop: 385,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Iqaluit',
	},
	{
		city: 'Gjoa Haven',
		city_ascii: 'Gjoa Haven',
		lat: 68.63329002,
		lng: -95.91666244,
		pop: 1109,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Cambridge_Bay',
	},
	{
		city: 'Grise Fiord',
		city_ascii: 'Grise Fiord',
		lat: 76.44165061,
		lng: -82.94998621,
		pop: 23,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Iqaluit',
	},
	{
		city: 'Alert',
		city_ascii: 'Alert',
		lat: 82.48332318,
		lng: -62.24998356,
		pop: 97.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Pangnirtung',
	},
	{
		city: 'Ennadai',
		city_ascii: 'Ennadai',
		lat: 61.13328269,
		lng: -100.883336,
		pop: 0,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Rankin_Inlet',
	},
	{
		city: 'Rankin Inlet',
		city_ascii: 'Rankin Inlet',
		lat: 62.81666831,
		lng: -92.09531946,
		pop: 2403,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Rankin_Inlet',
	},
	{
		city: 'Fort Resolution',
		city_ascii: 'Fort Resolution',
		lat: 61.16658815,
		lng: -113.682994,
		pop: 448,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Northwest Territories',
		timezone: 'America/Yellowknife',
	},
	{
		city: 'Fort Simpson',
		city_ascii: 'Fort Simpson',
		lat: 61.84998313,
		lng: -121.3332764,
		pop: 283,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Northwest Territories',
		timezone: 'America/Yellowknife',
	},
	{
		city: 'Inuvik',
		city_ascii: 'Inuvik',
		lat: 68.34997398,
		lng: -133.6999893,
		pop: 3022,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Northwest Territories',
		timezone: 'America/Inuvik',
	},
	{
		city: 'Tuktoyaktuk',
		city_ascii: 'Tuktoyaktuk',
		lat: 69.45477459,
		lng: -133.0492013,
		pop: 899.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Northwest Territories',
		timezone: 'America/Yellowknife',
	},
	{
		city: 'Watson Lake',
		city_ascii: 'Watson Lake',
		lat: 60.1166264,
		lng: -128.80003,
		pop: 802,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Yukon',
		timezone: 'America/Whitehorse',
	},
	{
		city: 'Lansdowne House',
		city_ascii: 'Lansdowne House',
		lat: 52.21664349,
		lng: -87.88332849,
		pop: 120,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Moosonee',
		city_ascii: 'Moosonee',
		lat: 51.28060244,
		lng: -80.65798141,
		pop: 1725,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Sudbury',
		city_ascii: 'Sudbury',
		lat: 46.49998985,
		lng: -80.96664474,
		pop: 119182,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Kenora',
		city_ascii: 'Kenora',
		lat: 49.76668968,
		lng: -94.46664758,
		pop: 10852,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Gaspe',
		city_ascii: 'Gaspe',
		lat: 48.83731488,
		lng: -64.49336084,
		pop: 3504,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Mingan',
		city_ascii: 'Mingan',
		lat: 50.3017589,
		lng: -64.01726532,
		pop: 588,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Dolbeau',
		city_ascii: 'Dolbeau',
		lat: 48.86658958,
		lng: -72.23332768,
		pop: 13126.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: "Val d'Or",
		city_ascii: "Val d'Or",
		lat: 48.11663535,
		lng: -77.76663334,
		pop: 20625,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Ivugivik',
		city_ascii: 'Ivugivik',
		lat: 62.41664105,
		lng: -77.89998438,
		pop: 156,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Inukjuak',
		city_ascii: 'Inukjuak',
		lat: 58.47000856,
		lng: -78.13599064,
		pop: 1597,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Chicoutimi',
		city_ascii: 'Chicoutimi',
		lat: 48.43330853,
		lng: -71.06670638,
		pop: 53940,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Moncton',
		city_ascii: 'Moncton',
		lat: 46.08334861,
		lng: -64.76667749,
		pop: 89051,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'New Brunswick',
		timezone: 'America/Moncton',
	},
	{
		city: 'Fredericton',
		city_ascii: 'Fredericton',
		lat: 45.94999758,
		lng: -66.63330774,
		pop: 44525,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'New Brunswick',
		timezone: 'America/Moncton',
	},
	{
		city: 'Bathurst',
		city_ascii: 'Bathurst',
		lat: 47.59997438,
		lng: -65.64998275,
		pop: 5303.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'New Brunswick',
		timezone: 'America/Moncton',
	},
	{
		city: 'Yarmouth',
		city_ascii: 'Yarmouth',
		lat: 43.83079447,
		lng: -66.1125812,
		pop: 7433,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nova Scotia',
		timezone: 'America/Halifax',
	},
	{
		city: 'Gander',
		city_ascii: 'Gander',
		lat: 48.94999534,
		lng: -54.54998845,
		pop: 3345,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/St_Johns',
	},
	{
		city: 'Cartwright',
		city_ascii: 'Cartwright',
		lat: 53.70140663,
		lng: -57.01214114,
		pop: 505,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/Goose_Bay',
	},
	{
		city: 'Rigolet',
		city_ascii: 'Rigolet',
		lat: 54.17660138,
		lng: -58.44732162,
		pop: 124,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/Goose_Bay',
	},
	{
		city: 'Port Burwell',
		city_ascii: 'Port Burwell',
		lat: 60.26646222,
		lng: -64.74109766,
		pop: 2762,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/Goose_Bay',
	},
	{
		city: 'Thompson',
		city_ascii: 'Thompson',
		lat: 55.74994204,
		lng: -97.86662093,
		pop: 13097,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Brandon',
		city_ascii: 'Brandon',
		lat: 49.83327476,
		lng: -99.94998214,
		pop: 27326,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Fort Smith',
		city_ascii: 'Fort Smith',
		lat: 60.00001853,
		lng: -111.8833364,
		pop: 518,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Yellowknife',
	},
	{
		city: 'Fort McMurray',
		city_ascii: 'Fort McMurray',
		lat: 56.7333187,
		lng: -111.3833153,
		pop: 21863,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Peace River',
		city_ascii: 'Peace River',
		lat: 56.23332338,
		lng: -117.2832911,
		pop: 5014.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Fort St. John',
		city_ascii: 'Fort St. John',
		lat: 56.24998903,
		lng: -120.8332811,
		pop: 18089,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Iqaluit',
		city_ascii: 'Iqaluit',
		lat: 63.75045938,
		lng: -68.50019175,
		pop: 6124,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Iqaluit',
	},
	{
		city: 'Cambridge Bay',
		city_ascii: 'Cambridge Bay',
		lat: 69.11695559,
		lng: -105.0333153,
		pop: 1477,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Cambridge_Bay',
	},
	{
		city: 'Kugluktuk',
		city_ascii: 'Kugluktuk',
		lat: 67.79866282,
		lng: -115.1253504,
		pop: 1302,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Cambridge_Bay',
	},
	{
		city: 'Chesterfield Inlet',
		city_ascii: 'Chesterfield Inlet',
		lat: 63.33829022,
		lng: -90.70008163,
		pop: 374,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Rankin_Inlet',
	},
	{
		city: 'Arviat',
		city_ascii: 'Arviat',
		lat: 61.10859211,
		lng: -94.05860672,
		pop: 1868,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Rankin_Inlet',
	},
	{
		city: 'Taloyoak',
		city_ascii: 'Taloyoak',
		lat: 69.5333126,
		lng: -93.53331954,
		pop: 774,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Cambridge_Bay',
	},
	{
		city: 'Igloolik',
		city_ascii: 'Igloolik',
		lat: 69.25653506,
		lng: -81.79359213,
		pop: 1612,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Iqaluit',
	},
	{
		city: 'Dawson City',
		city_ascii: 'Dawson',
		lat: 64.0666437,
		lng: -139.4166687,
		pop: 1319,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Yukon',
		timezone: 'America/Dawson',
	},
	{
		city: 'Timmins',
		city_ascii: 'Timmins',
		lat: 48.46658815,
		lng: -81.33331486,
		pop: 33937.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'North Bay',
		city_ascii: 'North Bay',
		lat: 46.30000205,
		lng: -79.44999313,
		pop: 45988.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Kuujjuarapik',
		city_ascii: 'Kuujjuarapik',
		lat: 55.28149518,
		lng: -77.76583236,
		pop: 1243,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Kuujjuaq',
		city_ascii: 'Kuujjuaq',
		lat: 58.10000531,
		lng: -68.39999577,
		pop: 1273,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Sydney',
		city_ascii: 'Sydney',
		lat: 46.06611452,
		lng: -60.17998071,
		pop: 37538,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nova Scotia',
		timezone: 'America/Glace_Bay',
	},
	{
		city: 'Labrador City',
		city_ascii: 'Labrador City',
		lat: 52.94143129,
		lng: -66.91587447,
		pop: 8840,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/Goose_Bay',
	},
	{
		city: 'Winnipeg',
		city_ascii: 'Winnipeg',
		lat: 49.88298749,
		lng: -97.16599186,
		pop: 603688,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Churchill',
		city_ascii: 'Churchill',
		lat: 58.76598533,
		lng: -94.1659941,
		pop: 961.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Manitoba',
		timezone: 'America/Winnipeg',
	},
	{
		city: 'Regina',
		city_ascii: 'Regina',
		lat: 50.45003298,
		lng: -104.6170099,
		pop: 176183,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'Saskatoon',
		city_ascii: 'Saskatoon',
		lat: 52.17003135,
		lng: -106.6699854,
		pop: 194075.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Saskatchewan',
		timezone: 'America/Regina',
	},
	{
		city: 'Calgary',
		city_ascii: 'Calgary',
		lat: 51.08299176,
		lng: -114.0799982,
		pop: 1012661,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Prince Rupert',
		city_ascii: 'Prince Rupert',
		lat: 54.31667035,
		lng: -130.3299882,
		pop: 14708,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Victoria',
		city_ascii: 'Victoria',
		lat: 48.43328269,
		lng: -123.3500009,
		pop: 270491.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Arctic Bay',
		city_ascii: 'Arctic Bay',
		lat: 73.03330568,
		lng: -85.16662093,
		pop: 604,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Rankin_Inlet',
	},
	{
		city: 'Resolute',
		city_ascii: 'Resolute',
		lat: 74.68333417,
		lng: -94.90000615,
		pop: 239.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Resolute',
	},
	{
		city: 'Repulse Bay',
		city_ascii: 'Repulse Bay',
		lat: 66.52946689,
		lng: -86.28293502,
		pop: 874,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nunavut',
		timezone: 'America/Rankin_Inlet',
	},
	{
		city: 'Yellowknife',
		city_ascii: 'Yellowknife',
		lat: 62.44201418,
		lng: -114.3969814,
		pop: 18658.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Northwest Territories',
		timezone: 'America/Yellowknife',
	},
	{
		city: 'Fort Good Hope',
		city_ascii: 'Fort Good Hope',
		lat: 66.26661277,
		lng: -128.6333218,
		pop: 597,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Northwest Territories',
		timezone: 'America/Yellowknife',
	},
	{
		city: 'Whitehorse',
		city_ascii: 'Whitehorse',
		lat: 60.71671897,
		lng: -135.0499844,
		pop: 23274,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Yukon',
		timezone: 'America/Whitehorse',
	},
	{
		city: 'Ottawa',
		city_ascii: 'Ottawa',
		lat: 45.4166968,
		lng: -75.7000153,
		pop: 978564.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Fort Severn',
		city_ascii: 'Fort Severn',
		lat: 55.98333864,
		lng: -87.64998356,
		pop: 125,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Thunder Bay',
		city_ascii: 'Thunder Bay',
		lat: 48.44615013,
		lng: -89.27497481,
		pop: 98354,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Thunder_Bay',
	},
	{
		city: 'Québec',
		city_ascii: 'Quebec',
		lat: 46.83996909,
		lng: -71.24561019,
		pop: 576386,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Halifax',
		city_ascii: 'Halifax',
		lat: 44.65002525,
		lng: -63.60000452,
		pop: 290992.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Nova Scotia',
		timezone: 'America/Halifax',
	},
	{
		city: 'St. John’s',
		city_ascii: "St. John's",
		lat: 47.58498822,
		lng: -52.68100692,
		pop: 115325.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/St_Johns',
	},
	{
		city: 'Nain',
		city_ascii: 'Nain',
		lat: 56.54735147,
		lng: -61.6860454,
		pop: 1151,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Newfoundland and Labrador',
		timezone: 'America/Goose_Bay',
	},
	{
		city: 'Charlottetown',
		city_ascii: 'Charlottetown',
		lat: 46.24928164,
		lng: -63.13132512,
		pop: 36847.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Prince Edward Island',
		timezone: 'America/Halifax',
	},
	{
		city: 'Edmonton',
		city_ascii: 'Edmonton',
		lat: 53.55002464,
		lng: -113.4999819,
		pop: 885195.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Alberta',
		timezone: 'America/Edmonton',
	},
	{
		city: 'Montréal',
		city_ascii: 'Montréal',
		lat: 45.49999921,
		lng: -73.58329696,
		pop: 3017278,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Québec',
		timezone: 'America/Montreal',
	},
	{
		city: 'Vancouver',
		city_ascii: 'Vancouver',
		lat: 49.27341658,
		lng: -123.1216442,
		pop: 1458415,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'British Columbia',
		timezone: 'America/Vancouver',
	},
	{
		city: 'Toronto',
		city_ascii: 'Toronto',
		lat: 43.69997988,
		lng: -79.42002079,
		pop: 4573710.5,
		country: 'Canada',
		iso2: 'CA',
		iso3: 'CAN',
		province: 'Ontario',
		timezone: 'America/Toronto',
	},
	{
		city: 'Mindelo',
		city_ascii: 'Mindelo',
		lat: 16.88376141,
		lng: -25.0000092,
		pop: 62962,
		country: 'Cape Verde',
		iso2: 'CV',
		iso3: 'CPV',
		province: '',
		timezone: 'Atlantic/Cape_Verde',
	},
	{
		city: 'Praia',
		city_ascii: 'Praia',
		lat: 14.91669802,
		lng: -23.51668889,
		pop: 101111.5,
		country: 'Cape Verde',
		iso2: 'CV',
		iso3: 'CPV',
		province: '',
		timezone: 'Atlantic/Cape_Verde',
	},
	{
		city: 'George Town',
		city_ascii: 'George Town',
		lat: 19.28043683,
		lng: -81.32998173,
		pop: 2770.5,
		country: 'Cayman Islands',
		iso2: 'KY',
		iso3: 'CYM',
		province: '',
		timezone: 'America/Cayman',
	},
	{
		city: 'Mobaye',
		city_ascii: 'Mobaye',
		lat: 4.320000059,
		lng: 21.17999753,
		pop: 19431,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Basse-Kotto',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Mbaiki',
		city_ascii: 'Mbaiki',
		lat: 3.870421365,
		lng: 17.99997595,
		pop: 43566,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Lobaye',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Carnot',
		city_ascii: 'Carnot',
		lat: 4.933689798,
		lng: 15.8666178,
		pop: 32298,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Mambéré-Kadéï',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Bozoum',
		city_ascii: 'Bozoum',
		lat: 6.316990376,
		lng: 16.38333044,
		pop: 25440.5,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Ouham-Pendé',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Kaga Bandoro',
		city_ascii: 'Kaga Bandoro',
		lat: 6.980386575,
		lng: 19.18000728,
		pop: 42673.5,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Nana-Grébizi',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Zemio',
		city_ascii: 'Zemio',
		lat: 5.033657856,
		lng: 25.13328731,
		pop: 19239,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Haut-Mbomou',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Yakossi',
		city_ascii: 'Yakossi',
		lat: 5.617007262,
		lng: 23.31665401,
		pop: 409.5,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Mbomou',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Nola',
		city_ascii: 'Nola',
		lat: 3.533697732,
		lng: 16.06660559,
		pop: 25301,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Sangha-Mbaéré',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Sibut',
		city_ascii: 'Sibut',
		lat: 5.733770161,
		lng: 19.08332068,
		pop: 26304.5,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Kémo',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Bossangoa',
		city_ascii: 'Bossangoa',
		lat: 6.483724384,
		lng: 17.44998368,
		pop: 45246,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Ouham',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Birao',
		city_ascii: 'Birao',
		lat: 10.28369916,
		lng: 22.78330155,
		pop: 5641,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Vakaga',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Ouadda',
		city_ascii: 'Ouadda',
		lat: 8.06709027,
		lng: 22.40001745,
		pop: 3424.5,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Haute-Kotto',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Bangassou',
		city_ascii: 'Bangassou',
		lat: 4.733753681,
		lng: 22.81663285,
		pop: 28601,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Mbomou',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Bossembele',
		city_ascii: 'Bossembele',
		lat: 5.267002786,
		lng: 17.65002315,
		pop: 7287,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Bangui',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Berberati',
		city_ascii: 'Berberati',
		lat: 4.249958922,
		lng: 15.7800081,
		pop: 60605,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Mambéré-Kadéï',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Bria',
		city_ascii: 'Bria',
		lat: 6.533307921,
		lng: 21.9832987,
		pop: 24985.5,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Haute-Kotto',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Bouar',
		city_ascii: 'Bouar',
		lat: 5.950010192,
		lng: 15.59996741,
		pop: 31476.5,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Nana-Mambéré',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Bambari',
		city_ascii: 'Bambari',
		lat: 5.761959655,
		lng: 20.66720333,
		pop: 47322.5,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Ouaka',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Ndele',
		city_ascii: 'Ndele',
		lat: 8.409136575,
		lng: 20.65304398,
		pop: 7355.5,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Bamingui-Bangoran',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Obo',
		city_ascii: 'Obo',
		lat: 5.399992085,
		lng: 26.50002559,
		pop: 12837,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Haut-Mbomou',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Bangui',
		city_ascii: 'Bangui',
		lat: 4.366644306,
		lng: 18.55828813,
		pop: 727348,
		country: 'Central African Republic',
		iso2: 'CF',
		iso3: 'CAF',
		province: 'Bangui',
		timezone: 'Africa/Bangui',
	},
	{
		city: 'Lai',
		city_ascii: 'Lai',
		lat: 9.395001123,
		lng: 16.30500349,
		pop: 19382,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Tandjilé',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Zouar',
		city_ascii: 'Zouar',
		lat: 20.458737,
		lng: 16.52776607,
		pop: 204,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Bet',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Bol',
		city_ascii: 'Bol',
		lat: 13.45895754,
		lng: 14.71469844,
		pop: 3303,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Lac',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Ati',
		city_ascii: 'Ati',
		lat: 13.21706016,
		lng: 18.33328894,
		pop: 24723.5,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Batha',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Oum Hadjer',
		city_ascii: 'Oum Hadjer',
		lat: 13.30041424,
		lng: 19.68333573,
		pop: 11582.5,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Batha',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Mongo',
		city_ascii: 'Mongo',
		lat: 12.18373822,
		lng: 18.6999849,
		pop: 27763,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Guéra',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Doba',
		city_ascii: 'Doba',
		lat: 8.650439676,
		lng: 16.8500203,
		pop: 26966.5,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Logone Oriental',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Pala',
		city_ascii: 'Pala',
		lat: 9.350422789,
		lng: 14.96662105,
		pop: 17835,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Mayo-Kebbi Est',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Bongor',
		city_ascii: 'Bongor',
		lat: 10.28594708,
		lng: 15.38716386,
		pop: 112229.5,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Mayo-Kebbi Est',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Kelo',
		city_ascii: 'Kelo',
		lat: 9.317065652,
		lng: 15.80000688,
		pop: 69378.5,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Tandjilé',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Fada',
		city_ascii: 'Fada',
		lat: 17.18472495,
		lng: 21.59024776,
		pop: 448,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Bet',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Faya Largeau',
		city_ascii: 'Faya Largeau',
		lat: 17.91666994,
		lng: 19.11670365,
		pop: 13400,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Bet',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Mao',
		city_ascii: 'Mao',
		lat: 14.11943402,
		lng: 15.31331824,
		pop: 15040,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Kanem',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Biltine',
		city_ascii: 'Biltine',
		lat: 14.53325889,
		lng: 20.91669714,
		pop: 6682.5,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Wadi Fira',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Sarh',
		city_ascii: 'Sarh',
		lat: 9.149969909,
		lng: 18.39002966,
		pop: 135862,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Mandoul',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Am Timan',
		city_ascii: 'Am Timan',
		lat: 11.03329165,
		lng: 20.28329911,
		pop: 29664,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Salamat',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Moundou',
		city_ascii: 'Moundou',
		lat: 8.549980691,
		lng: 16.09001501,
		pop: 145936,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Logone Oriental',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Ndjamena',
		city_ascii: 'Ndjamena',
		lat: 12.11309654,
		lng: 15.04914831,
		pop: 835193.5,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Hadjer-Lamis',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Abeche',
		city_ascii: 'Abeche',
		lat: 13.83999371,
		lng: 20.82998409,
		pop: 116252.5,
		country: 'Chad',
		iso2: 'TD',
		iso3: 'TCD',
		province: 'Ouaddaï',
		timezone: 'Africa/Ndjamena',
	},
	{
		city: 'Rio Verde',
		city_ascii: 'Rio Verde',
		lat: -52.64997882,
		lng: -71.46660445,
		pop: 358,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Magallanes y Antártica Chilena',
		timezone: 'America/Santiago',
	},
	{
		city: 'Cuya',
		city_ascii: 'Cuya',
		lat: -19.11667682,
		lng: -70.13330082,
		pop: 20,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Tarapacá',
		timezone: 'America/Santiago',
	},
	{
		city: 'Chuquicamata',
		city_ascii: 'Chuquicamata',
		lat: -22.31999551,
		lng: -68.92998926,
		pop: 11941,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Antofagasta',
		timezone: 'America/Santiago',
	},
	{
		city: 'Maria Elena',
		city_ascii: 'Maria Elena',
		lat: -22.35001951,
		lng: -69.66999577,
		pop: 2370,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Antofagasta',
		timezone: 'America/Santiago',
	},
	{
		city: 'Tierra Amarilla',
		city_ascii: 'Tierra Amarilla',
		lat: -27.4800423,
		lng: -70.27998438,
		pop: 10733,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Atacama',
		timezone: 'America/Santiago',
	},
	{
		city: 'Combarbala',
		city_ascii: 'Combarbala',
		lat: -31.18002317,
		lng: -70.99999211,
		pop: 5134,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Coquimbo',
		timezone: 'America/Santiago',
	},
	{
		city: 'San Bernardo',
		city_ascii: 'San Bernardo',
		lat: -33.59997882,
		lng: -70.69998458,
		pop: 243950,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Región Metropolitana de Santiago',
		timezone: 'America/Santiago',
	},
	{
		city: 'San Felipe',
		city_ascii: 'San Felipe',
		lat: -32.75000486,
		lng: -70.7200092,
		pop: 49256.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Valparaíso',
		timezone: 'America/Santiago',
	},
	{
		city: 'Vina del Mar',
		city_ascii: 'Vina del Mar',
		lat: -33.02998777,
		lng: -71.53998499,
		pop: 399042.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Valparaíso',
		timezone: 'America/Santiago',
	},
	{
		city: 'La Ligua',
		city_ascii: 'La Ligua',
		lat: -32.45999673,
		lng: -71.24002914,
		pop: 25761,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Valparaíso',
		timezone: 'America/Santiago',
	},
	{
		city: 'Quillota',
		city_ascii: 'Quillota',
		lat: -32.87997109,
		lng: -71.26000208,
		pop: 73732.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Valparaíso',
		timezone: 'America/Santiago',
	},
	{
		city: 'Nueva Imperial',
		city_ascii: 'Nueva Imperial',
		lat: -38.74002684,
		lng: -72.96002751,
		pop: 13322.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'La Araucanía',
		timezone: 'America/Santiago',
	},
	{
		city: 'Loncoche',
		city_ascii: 'Loncoche',
		lat: -39.37001422,
		lng: -72.62999597,
		pop: 8653.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'La Araucanía',
		timezone: 'America/Santiago',
	},
	{
		city: 'Villarica',
		city_ascii: 'Villarica',
		lat: -39.28004555,
		lng: -72.22999455,
		pop: 16818,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'La Araucanía',
		timezone: 'America/Santiago',
	},
	{
		city: 'Tolten',
		city_ascii: 'Tolten',
		lat: -39.21659053,
		lng: -73.21233798,
		pop: 2293,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'La Araucanía',
		timezone: 'America/Santiago',
	},
	{
		city: 'Lonquimay',
		city_ascii: 'Lonquimay',
		lat: -38.43332721,
		lng: -71.23328536,
		pop: 8519.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'La Araucanía',
		timezone: 'America/Santiago',
	},
	{
		city: 'Angol',
		city_ascii: 'Angol',
		lat: -37.79000731,
		lng: -72.71001693,
		pop: 38384.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'La Araucanía',
		timezone: 'America/Santiago',
	},
	{
		city: 'Collipulli',
		city_ascii: 'Collipulli',
		lat: -37.95997109,
		lng: -72.43000818,
		pop: 11307.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'La Araucanía',
		timezone: 'America/Santiago',
	},
	{
		city: 'La Union',
		city_ascii: 'La Union',
		lat: -40.28995807,
		lng: -73.0899679,
		pop: 22843,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Los Ríos',
		timezone: 'America/Santiago',
	},
	{
		city: 'Rio Bueno',
		city_ascii: 'Rio Bueno',
		lat: -40.34003253,
		lng: -72.96002751,
		pop: 13059.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Los Ríos',
		timezone: 'America/Santiago',
	},
	{
		city: 'Coronel',
		city_ascii: 'Coronel',
		lat: -37.02995034,
		lng: -73.1600153,
		pop: 78594.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Bío-Bío',
		timezone: 'America/Santiago',
	},
	{
		city: 'Talcahuano',
		city_ascii: 'Talcahuano',
		lat: -36.71668781,
		lng: -73.11665877,
		pop: 270521,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Bío-Bío',
		timezone: 'America/Santiago',
	},
	{
		city: 'Quirihue',
		city_ascii: 'Quirihue',
		lat: -36.28002195,
		lng: -72.53000208,
		pop: 6529,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Bío-Bío',
		timezone: 'America/Santiago',
	},
	{
		city: 'Curanilahue',
		city_ascii: 'Curanilahue',
		lat: -37.48000039,
		lng: -73.34000431,
		pop: 22352.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Bío-Bío',
		timezone: 'America/Santiago',
	},
	{
		city: 'Santa Barbara',
		city_ascii: 'Santa Barbara',
		lat: -37.67001463,
		lng: -72.01998153,
		pop: 3494,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Bío-Bío',
		timezone: 'America/Santiago',
	},
	{
		city: 'Pichilemu',
		city_ascii: 'Pichilemu',
		lat: -34.38003457,
		lng: -71.99998275,
		pop: 11603,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: "Libertador General Bernardo O'Higgins",
		timezone: 'America/Santiago',
	},
	{
		city: 'San Fernando',
		city_ascii: 'San Fernando',
		lat: -34.58002236,
		lng: -70.98996688,
		pop: 60746,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: "Libertador General Bernardo O'Higgins",
		timezone: 'America/Santiago',
	},
	{
		city: 'Puerto Varas',
		city_ascii: 'Puerto Varas',
		lat: -41.32999795,
		lng: -72.98999984,
		pop: 19569,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Los Lagos',
		timezone: 'America/Santiago',
	},
	{
		city: 'Calbuco',
		city_ascii: 'Calbuco',
		lat: -41.75220107,
		lng: -73.14159106,
		pop: 6933.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Los Lagos',
		timezone: 'America/Santiago',
	},
	{
		city: 'Castro',
		city_ascii: 'Castro',
		lat: -42.48076159,
		lng: -73.76233415,
		pop: 25184.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Los Lagos',
		timezone: 'America/Santiago',
	},
	{
		city: 'Chonchi',
		city_ascii: 'Chonchi',
		lat: -42.61002317,
		lng: -73.80994979,
		pop: 381,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Los Lagos',
		timezone: 'America/Santiago',
	},
	{
		city: 'Linares',
		city_ascii: 'Linares',
		lat: -35.83999713,
		lng: -71.58998194,
		pop: 75275,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Maule',
		timezone: 'America/Santiago',
	},
	{
		city: 'Cauquenes',
		city_ascii: 'Cauquenes',
		lat: -35.96004148,
		lng: -72.31998906,
		pop: 24155,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Maule',
		timezone: 'America/Santiago',
	},
	{
		city: 'Cochrane',
		city_ascii: 'Cochrane',
		lat: -47.26627643,
		lng: -72.54994918,
		pop: 4441,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Aisén del General Carlos Ibáñez del Campo',
		timezone: 'America/Santiago',
	},
	{
		city: 'Lagunas',
		city_ascii: 'Lagunas',
		lat: -20.98286782,
		lng: -69.68330245,
		pop: 10,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Tarapacá',
		timezone: 'America/Santiago',
	},
	{
		city: 'Pozo Almonte',
		city_ascii: 'Pozo Almonte',
		lat: -20.2696297,
		lng: -69.80001367,
		pop: 10830,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Tarapacá',
		timezone: 'America/Santiago',
	},
	{
		city: 'Toconao',
		city_ascii: 'Toconao',
		lat: -23.18288857,
		lng: -68.01663416,
		pop: 378,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Antofagasta',
		timezone: 'America/Santiago',
	},
	{
		city: 'Huasco',
		city_ascii: 'Huasco',
		lat: -28.4695943,
		lng: -71.22000452,
		pop: 2558,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Atacama',
		timezone: 'America/Santiago',
	},
	{
		city: 'Diego de Almagro',
		city_ascii: 'Diego de Almagro',
		lat: -26.36961912,
		lng: -70.04999841,
		pop: 18137,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Atacama',
		timezone: 'America/Santiago',
	},
	{
		city: 'Caldera',
		city_ascii: 'Caldera',
		lat: -27.06962807,
		lng: -70.83002832,
		pop: 10259,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Atacama',
		timezone: 'America/Santiago',
	},
	{
		city: 'Coquimbo',
		city_ascii: 'Coquimbo',
		lat: -29.95291461,
		lng: -71.34361454,
		pop: 159082.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Coquimbo',
		timezone: 'America/Santiago',
	},
	{
		city: 'Vicuna',
		city_ascii: 'Vicuna',
		lat: -30.02949909,
		lng: -70.73998214,
		pop: 13496,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Coquimbo',
		timezone: 'America/Santiago',
	},
	{
		city: 'Illapel',
		city_ascii: 'Illapel',
		lat: -31.62960814,
		lng: -71.17000757,
		pop: 24578,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Coquimbo',
		timezone: 'America/Santiago',
	},
	{
		city: 'Salamanca',
		city_ascii: 'Salamanca',
		lat: -31.77957314,
		lng: -70.97996749,
		pop: 16739.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Coquimbo',
		timezone: 'America/Santiago',
	},
	{
		city: 'Los Andes',
		city_ascii: 'Los Andes',
		lat: -32.82958657,
		lng: -70.59999068,
		pop: 53135,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Valparaíso',
		timezone: 'America/Santiago',
	},
	{
		city: 'San Antonio',
		city_ascii: 'San Antonio',
		lat: -33.59951373,
		lng: -71.60998071,
		pop: 94971.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Valparaíso',
		timezone: 'America/Santiago',
	},
	{
		city: 'Victoria',
		city_ascii: 'Victoria',
		lat: -38.23954059,
		lng: -72.34001367,
		pop: 17989.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'La Araucanía',
		timezone: 'America/Santiago',
	},
	{
		city: 'Carahue',
		city_ascii: 'Carahue',
		lat: -38.70948606,
		lng: -73.16998885,
		pop: 8592,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'La Araucanía',
		timezone: 'America/Santiago',
	},
	{
		city: 'Los Lagos',
		city_ascii: 'Los Lagos',
		lat: -39.84951984,
		lng: -72.82998377,
		pop: 12813,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Los Ríos',
		timezone: 'America/Santiago',
	},
	{
		city: 'Lota',
		city_ascii: 'Lota',
		lat: -37.08958494,
		lng: -73.1600153,
		pop: 32702,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Bío-Bío',
		timezone: 'America/Santiago',
	},
	{
		city: 'Lebu',
		city_ascii: 'Lebu',
		lat: -37.6095532,
		lng: -73.64998539,
		pop: 16624.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Bío-Bío',
		timezone: 'America/Santiago',
	},
	{
		city: 'Quellon',
		city_ascii: 'Quellon',
		lat: -43.09960569,
		lng: -73.59998845,
		pop: 7029,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Los Lagos',
		timezone: 'America/Santiago',
	},
	{
		city: 'Constitucion',
		city_ascii: 'Constitucion',
		lat: -35.32958901,
		lng: -72.41998295,
		pop: 26902.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Maule',
		timezone: 'America/Santiago',
	},
	{
		city: "Villa O'Higgins",
		city_ascii: "Villa O'Higgins",
		lat: -48.46790851,
		lng: -72.55997441,
		pop: 175,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Santa Cruz',
		timezone: 'America/Santiago',
	},
	{
		city: 'Puerto Aisen',
		city_ascii: 'Puerto Aisen',
		lat: -45.3999304,
		lng: -72.70001754,
		pop: 8067,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Aisén del General Carlos Ibáñez del Campo',
		timezone: 'America/Santiago',
	},
	{
		city: 'Puerto Natales',
		city_ascii: 'Puerto Natales',
		lat: -51.71830442,
		lng: -72.51168278,
		pop: 10393,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Magallanes y Antártica Chilena',
		timezone: 'America/Santiago',
	},
	{
		city: 'Puerto Williams',
		city_ascii: 'Puerto Williams',
		lat: -54.93330198,
		lng: -67.61671025,
		pop: 2381,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Magallanes y Antártica Chilena',
		timezone: 'America/Santiago',
	},
	{
		city: 'Temuco',
		city_ascii: 'Temuco',
		lat: -38.73000161,
		lng: -72.57999903,
		pop: 252015,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'La Araucanía',
		timezone: 'America/Santiago',
	},
	{
		city: 'Tocopilla',
		city_ascii: 'Tocopilla',
		lat: -22.09003538,
		lng: -70.18998987,
		pop: 22355.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Antofagasta',
		timezone: 'America/Santiago',
	},
	{
		city: 'Calama',
		city_ascii: 'Calama',
		lat: -22.45001341,
		lng: -68.91998987,
		pop: 134336.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Antofagasta',
		timezone: 'America/Santiago',
	},
	{
		city: 'Mejillones',
		city_ascii: 'Mejillones',
		lat: -23.09999957,
		lng: -70.44999984,
		pop: 2041,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Antofagasta',
		timezone: 'America/Santiago',
	},
	{
		city: 'Taltal',
		city_ascii: 'Taltal',
		lat: -25.40001422,
		lng: -70.47002446,
		pop: 8947.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Antofagasta',
		timezone: 'America/Santiago',
	},
	{
		city: 'Vallenar',
		city_ascii: 'Vallenar',
		lat: -28.57000161,
		lng: -70.76000676,
		pop: 37876.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Atacama',
		timezone: 'America/Santiago',
	},
	{
		city: 'Chanaral',
		city_ascii: 'Chanaral',
		lat: -29.03002521,
		lng: -71.43001754,
		pop: 13543,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Atacama',
		timezone: 'America/Santiago',
	},
	{
		city: 'Ovalle',
		city_ascii: 'Ovalle',
		lat: -30.59003335,
		lng: -71.20005742,
		pop: 72984,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Coquimbo',
		timezone: 'America/Santiago',
	},
	{
		city: 'Chillan',
		city_ascii: 'Chillan',
		lat: -36.60000242,
		lng: -72.10599695,
		pop: 149874,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Bío-Bío',
		timezone: 'America/Santiago',
	},
	{
		city: 'Rancagua',
		city_ascii: 'Rancagua',
		lat: -34.17002155,
		lng: -70.73998214,
		pop: 222981.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: "Libertador General Bernardo O'Higgins",
		timezone: 'America/Santiago',
	},
	{
		city: 'Osorno',
		city_ascii: 'Osorno',
		lat: -40.56999266,
		lng: -73.1600153,
		pop: 144952,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Los Lagos',
		timezone: 'America/Santiago',
	},
	{
		city: 'Ancud',
		city_ascii: 'Ancud',
		lat: -41.86996499,
		lng: -73.82997441,
		pop: 24241,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Los Lagos',
		timezone: 'America/Santiago',
	},
	{
		city: 'Talca',
		city_ascii: 'Talca',
		lat: -35.45500771,
		lng: -71.67000289,
		pop: 186283.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Maule',
		timezone: 'America/Santiago',
	},
	{
		city: 'Curico',
		city_ascii: 'Curico',
		lat: -34.97999795,
		lng: -71.24002914,
		pop: 108074.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Maule',
		timezone: 'America/Santiago',
	},
	{
		city: 'Coihaique',
		city_ascii: 'Coihaique',
		lat: -45.56999754,
		lng: -72.07000431,
		pop: 43221,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Aisén del General Carlos Ibáñez del Campo',
		timezone: 'America/Santiago',
	},
	{
		city: 'Arica',
		city_ascii: 'Arica',
		lat: -18.50002195,
		lng: -70.28998377,
		pop: 178446.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Arica y Parinacota',
		timezone: 'America/Santiago',
	},
	{
		city: 'Copiapo',
		city_ascii: 'Copiapo',
		lat: -27.35999795,
		lng: -70.33998071,
		pop: 117316.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Atacama',
		timezone: 'America/Santiago',
	},
	{
		city: 'La Serena',
		city_ascii: 'La Serena',
		lat: -29.89999795,
		lng: -71.24997685,
		pop: 151290,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Coquimbo',
		timezone: 'America/Santiago',
	},
	{
		city: 'Los Angeles',
		city_ascii: 'Los Angeles',
		lat: -37.46000161,
		lng: -72.35998661,
		pop: 135334.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Bío-Bío',
		timezone: 'America/Santiago',
	},
	{
		city: 'Punta Arenas',
		city_ascii: 'Punta Arenas',
		lat: -53.16498615,
		lng: -70.93999577,
		pop: 106296,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Magallanes y Antártica Chilena',
		timezone: 'America/Santiago',
	},
	{
		city: 'Iquique',
		city_ascii: 'Iquique',
		lat: -20.24999266,
		lng: -70.12996769,
		pop: 223012,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Tarapacá',
		timezone: 'America/Santiago',
	},
	{
		city: 'Antofagasta',
		city_ascii: 'Antofagasta',
		lat: -23.64999184,
		lng: -70.40000289,
		pop: 295539,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Antofagasta',
		timezone: 'America/Santiago',
	},
	{
		city: 'Valparaiso',
		city_ascii: 'Valparaiso',
		lat: -33.04776447,
		lng: -71.62101363,
		pop: 434969,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Valparaíso',
		timezone: 'America/Santiago',
	},
	{
		city: 'Valdivia',
		city_ascii: 'Valdivia',
		lat: -39.7950012,
		lng: -73.24502303,
		pop: 146509,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Los Ríos',
		timezone: 'America/Santiago',
	},
	{
		city: 'Concepcion',
		city_ascii: 'Concepcion',
		lat: -36.83001422,
		lng: -73.05002202,
		pop: 550864,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Bío-Bío',
		timezone: 'America/Santiago',
	},
	{
		city: 'Puerto Montt',
		city_ascii: 'Puerto Montt',
		lat: -41.4699894,
		lng: -72.92997766,
		pop: 167341.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Los Lagos',
		timezone: 'America/Santiago',
	},
	{
		city: 'Santiago',
		city_ascii: 'Santiago',
		lat: -33.45001382,
		lng: -70.66704085,
		pop: 2883305.5,
		country: 'Chile',
		iso2: 'CL',
		iso3: 'CHL',
		province: 'Región Metropolitana de Santiago',
		timezone: 'America/Santiago',
	},
	{
		city: 'Yumen',
		city_ascii: 'Yumen',
		lat: 39.83003522,
		lng: 97.72999304,
		pop: 233097,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Linxia',
		city_ascii: 'Linxia',
		lat: 35.60000917,
		lng: 103.2000468,
		pop: 368478.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Zhuozhou',
		city_ascii: 'Zuozhou',
		lat: 39.54005292,
		lng: 115.789976,
		pop: 415000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Beijing',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Sanming',
		city_ascii: 'Sanming',
		lat: 26.2299868,
		lng: 117.5800476,
		pop: 104941.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Fujian',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Huizhou',
		city_ascii: 'Huizhou',
		lat: 23.07997235,
		lng: 114.3999833,
		pop: 289201,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Chaozhou',
		city_ascii: 'Chaozhou',
		lat: 23.68003908,
		lng: 116.630028,
		pop: 424787,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Gyangze',
		city_ascii: 'Gyangze',
		lat: 28.95000165,
		lng: 89.63332963,
		pop: 10000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xizang',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Dali',
		city_ascii: 'Dali',
		lat: 34.79525209,
		lng: 109.937775,
		pop: 109696,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shaanxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Yangquan',
		city_ascii: 'Yangquan',
		lat: 37.86997398,
		lng: 113.5700081,
		pop: 851801.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shanxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Shiyan',
		city_ascii: 'Shiyan',
		lat: 32.57003908,
		lng: 110.7799975,
		pop: 653823.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Danjiangkou',
		city_ascii: 'Danjiangkou',
		lat: 32.5200163,
		lng: 111.5000052,
		pop: 92008,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Shashi',
		city_ascii: 'Shashi',
		lat: 30.32002138,
		lng: 112.2299865,
		pop: 509390,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Anlu',
		city_ascii: 'Anlu',
		lat: 31.26998924,
		lng: 113.670002,
		pop: 71198,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zixing',
		city_ascii: 'Zixing',
		lat: 25.96997683,
		lng: 113.4000443,
		pop: 6618,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hunan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Deyang',
		city_ascii: 'Deyang',
		lat: 31.13333091,
		lng: 104.3999735,
		pop: 152194,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Tengchong',
		city_ascii: 'Tengchong',
		lat: 25.03331565,
		lng: 98.46658891,
		pop: 126058,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Yunnan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Mengzi',
		city_ascii: 'Mengzi',
		lat: 23.3619448,
		lng: 103.4061324,
		pop: 303341,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Yunnan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Chuxiong',
		city_ascii: 'Chuxiong',
		lat: 25.03641624,
		lng: 101.5455741,
		pop: 254370,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Yunnan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Hengshui',
		city_ascii: 'Hengshui',
		lat: 37.71998313,
		lng: 115.7000073,
		pop: 456356,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xuanhua',
		city_ascii: 'Xuanhua',
		lat: 40.59440716,
		lng: 115.0243379,
		pop: 391583.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Luohe',
		city_ascii: 'Luohe',
		lat: 33.57000388,
		lng: 114.02998,
		pop: 417356,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Beipiao',
		city_ascii: 'Beipiao',
		lat: 41.81001772,
		lng: 120.7600085,
		pop: 191757,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Wafangdian',
		city_ascii: 'Wafangdian',
		lat: 39.62591331,
		lng: 121.9959537,
		pop: 303217.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zhucheng',
		city_ascii: 'Zhucheng',
		lat: 35.98995953,
		lng: 119.3800927,
		pop: 881963.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Hangu',
		city_ascii: 'Hangu',
		lat: 39.23195803,
		lng: 117.7769864,
		pop: 270581,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Tianjin',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xinyi',
		city_ascii: 'Xinyi',
		lat: 34.38000612,
		lng: 118.3500264,
		pop: 962656,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangsu',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yangzhou',
		city_ascii: 'Yangzhou',
		lat: 32.39999778,
		lng: 119.4300122,
		pop: 539715,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangsu',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Linhai',
		city_ascii: 'Linhai',
		lat: 28.85000775,
		lng: 121.1199865,
		pop: 202348,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Zhejiang',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Huangyan',
		city_ascii: 'Huangyan',
		lat: 28.65001996,
		lng: 121.2500044,
		pop: 174580.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Zhejiang',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Daan',
		city_ascii: 'Daan',
		lat: 45.49999921,
		lng: 124.2999991,
		pop: 84795.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Changling',
		city_ascii: 'Changling',
		lat: 44.26999676,
		lng: 123.9899922,
		pop: 46703.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Tonghua',
		city_ascii: 'Tonghua',
		lat: 41.67997398,
		lng: 125.7499882,
		pop: 27227,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Baishan',
		city_ascii: 'Baishan',
		lat: 41.90001223,
		lng: 126.4299983,
		pop: 330000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Yanji',
		city_ascii: 'Yanji',
		lat: 42.88230369,
		lng: 129.5127559,
		pop: 407848.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Ergun Zuoqi',
		city_ascii: 'Ergun Zuoqi',
		lat: 50.78332013,
		lng: 121.5166548,
		pop: 42849,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Shangdu',
		city_ascii: 'Shangdu',
		lat: 41.54943931,
		lng: 113.5338863,
		pop: 18831,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Orongen Zizhiqi',
		city_ascii: 'Orongen Zizhiqi',
		lat: 50.56666669,
		lng: 123.7166756,
		pop: 40128,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zalantun',
		city_ascii: 'Zalantun',
		lat: 48.00000165,
		lng: 122.7199922,
		pop: 135128,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Wuchuan',
		city_ascii: 'Wuchuan',
		lat: 41.09551353,
		lng: 111.4408357,
		pop: 23776,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Hanggin Houqi',
		city_ascii: 'Hanggin Houqi',
		lat: 40.88469952,
		lng: 107.140013,
		pop: 39954,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Anda',
		city_ascii: 'Anda',
		lat: 46.39999595,
		lng: 125.3200402,
		pop: 181402,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Qinggang',
		city_ascii: 'Qinggang',
		lat: 46.69002993,
		lng: 126.1000443,
		pop: 60955,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Angangxi',
		city_ascii: 'Angangxi',
		lat: 47.16005292,
		lng: 123.8000297,
		pop: 24317,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Hulan Ergi',
		city_ascii: 'Hulan Ergi',
		lat: 47.20997235,
		lng: 123.6100154,
		pop: 277671.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Qingan',
		city_ascii: 'Qingan',
		lat: 46.87185345,
		lng: 127.5118444,
		pop: 53206,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Baiquan',
		city_ascii: 'Baiquan',
		lat: 47.60183474,
		lng: 126.0818542,
		pop: 52679.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Suileng',
		city_ascii: 'Suileng',
		lat: 47.24599082,
		lng: 127.1059777,
		pop: 57456.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Linkou',
		city_ascii: 'Linkou',
		lat: 45.28189882,
		lng: 130.2518839,
		pop: 66426.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Longxi',
		city_ascii: 'Longxi',
		lat: 35.04763979,
		lng: 104.6394421,
		pop: 355037,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Pingliang',
		city_ascii: 'Pingliang',
		lat: 35.53037518,
		lng: 106.6800927,
		pop: 157706,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Anxi',
		city_ascii: 'Anxi',
		lat: 40.50043357,
		lng: 95.79998165,
		pop: 17886,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Minxian',
		city_ascii: 'Minxian',
		lat: 34.4361784,
		lng: 104.0305904,
		pop: 67826,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Jinchang',
		city_ascii: 'Jinchang',
		lat: 38.49569806,
		lng: 102.1739078,
		pop: 141670,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Guide',
		city_ascii: 'Guide',
		lat: 36.04509829,
		lng: 101.4241862,
		pop: 7642,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Qinzhou',
		city_ascii: 'Qinzhou',
		lat: 21.95042889,
		lng: 108.6199743,
		pop: 173186,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Pingxiang',
		city_ascii: 'Pingxiang',
		lat: 22.09737083,
		lng: 106.7566772,
		pop: 31109,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Yishan',
		city_ascii: 'Yishan',
		lat: 24.50037661,
		lng: 108.6666898,
		pop: 47062,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Beihai',
		city_ascii: 'Beihai',
		lat: 21.4804059,
		lng: 109.1000484,
		pop: 567289,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Hechi',
		city_ascii: 'Hechi',
		lat: 23.09653465,
		lng: 109.6091129,
		pop: 3275189.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Tongren',
		city_ascii: 'Tongren',
		lat: 27.68041506,
		lng: 109.1300207,
		pop: 104441.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guizhou',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Fengjie',
		city_ascii: 'Fengjie',
		lat: 31.05044191,
		lng: 109.5166638,
		pop: 49168,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Chongqing',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Changping',
		city_ascii: 'Changping',
		lat: 40.22476564,
		lng: 116.1943957,
		pop: 372410.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Beijing',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Shaowu',
		city_ascii: 'Shaowu',
		lat: 27.30038658,
		lng: 117.5000008,
		pop: 56889.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Fujian',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Longyan',
		city_ascii: 'Longyan',
		lat: 25.18041262,
		lng: 117.0300036,
		pop: 367896,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Fujian',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zhangzhou',
		city_ascii: 'Zhangzhou',
		lat: 24.52037539,
		lng: 117.6700162,
		pop: 2434799.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Fujian',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Putian',
		city_ascii: 'Putian',
		lat: 25.43034568,
		lng: 119.0200114,
		pop: 376558,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Fujian',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Fuan',
		city_ascii: 'Fuan',
		lat: 27.07042645,
		lng: 119.6200264,
		pop: 81761.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Fujian',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Changting',
		city_ascii: 'Changting',
		lat: 25.8669857,
		lng: 116.3166621,
		pop: 87458,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Fujian',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Nanping',
		city_ascii: 'Nanping',
		lat: 26.63037579,
		lng: 118.1699857,
		pop: 192364,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Fujian',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Ninde',
		city_ascii: 'Ninde',
		lat: 26.68037274,
		lng: 119.5300577,
		pop: 189623.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Fujian',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jieshou',
		city_ascii: 'Jieshou',
		lat: 33.25043683,
		lng: 115.3500028,
		pop: 141993,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Anhui',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Tongling',
		city_ascii: 'Tongling',
		lat: 30.95044802,
		lng: 117.7800354,
		pop: 437710,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Anhui',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Maanshan',
		city_ascii: 'Maanshan',
		lat: 31.73040041,
		lng: 118.4800443,
		pop: 1000121,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Anhui',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Fuyang',
		city_ascii: 'Fuyang',
		lat: 32.90040651,
		lng: 115.82,
		pop: 170023,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Anhui',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yangjiang',
		city_ascii: 'Yangjiang',
		lat: 21.85040916,
		lng: 111.9700024,
		pop: 751181.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Meizhou',
		city_ascii: 'Meizhou',
		lat: 24.30049217,
		lng: 116.1199816,
		pop: 279571,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Heyuan',
		city_ascii: 'Heyuan',
		lat: 23.7304236,
		lng: 114.6800179,
		pop: 269280.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Qingyuan',
		city_ascii: 'Qingyuan',
		lat: 23.7003996,
		lng: 113.0300927,
		pop: 706717,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zhaoqing',
		city_ascii: 'Zhaoqing',
		lat: 23.05041343,
		lng: 112.4500248,
		pop: 420984.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Lianxian',
		city_ascii: 'Lianxian',
		lat: 24.78152224,
		lng: 112.3825354,
		pop: 148233,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jiangmen',
		city_ascii: 'Jiangmen',
		lat: 22.58039044,
		lng: 113.0800122,
		pop: 532419,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Maoming',
		city_ascii: 'Maoming',
		lat: 21.92040489,
		lng: 110.8700179,
		pop: 1217715,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Gar',
		city_ascii: 'Gar',
		lat: 32.20039756,
		lng: 79.98332434,
		pop: 5250,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xizang',
		timezone: 'Asia/Kashgar',
	},
	{
		city: 'Turpan',
		city_ascii: 'Turpan',
		lat: 42.93537539,
		lng: 89.1650378,
		pop: 178863.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Quiemo',
		city_ascii: 'Quiemo',
		lat: 38.13375633,
		lng: 85.53332149,
		pop: 32494,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Koktokay',
		city_ascii: 'Koktokay',
		lat: 47.00037274,
		lng: 89.46662146,
		pop: 60000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Hancheng',
		city_ascii: 'Hancheng',
		lat: 35.47043052,
		lng: 110.429993,
		pop: 140092,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shaanxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Weinan',
		city_ascii: 'Weinan',
		lat: 34.50043805,
		lng: 109.5000756,
		pop: 172321,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shaanxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Shuozhou',
		city_ascii: 'Shuozhou',
		lat: 39.30037762,
		lng: 112.4200008,
		pop: 408068.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shanxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xinzhou',
		city_ascii: 'Xinzhou',
		lat: 38.41043194,
		lng: 112.7199825,
		pop: 216805,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shanxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jincheng',
		city_ascii: 'Jincheng',
		lat: 35.50037701,
		lng: 112.8300016,
		pop: 520000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shanxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jiexiu',
		city_ascii: 'Jiexiu',
		lat: 37.04002464,
		lng: 111.8999808,
		pop: 77178,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shanxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Changzhi',
		city_ascii: 'Changzhi',
		lat: 36.18387534,
		lng: 113.1052819,
		pop: 706000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shanxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Guangshui',
		city_ascii: 'Guangshui',
		lat: 31.62038129,
		lng: 114.0000077,
		pop: 145885.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jingmen',
		city_ascii: 'Jingmen',
		lat: 31.03039146,
		lng: 112.1000203,
		pop: 400000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zicheng',
		city_ascii: 'Zicheng',
		lat: 30.30043601,
		lng: 111.5000052,
		pop: 198212.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Shishou',
		city_ascii: 'Shishou',
		lat: 29.70039512,
		lng: 112.400002,
		pop: 177099,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xiaogan',
		city_ascii: 'Xiaogan',
		lat: 30.92039817,
		lng: 113.9000138,
		pop: 160437,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Puqi',
		city_ascii: 'Puqi',
		lat: 29.72041974,
		lng: 113.880015,
		pop: 169027.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yunxian',
		city_ascii: 'Yunxian',
		lat: 32.80816408,
		lng: 110.8136389,
		pop: 133558,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jinshi',
		city_ascii: 'Jinshi',
		lat: 29.63210472,
		lng: 111.851715,
		pop: 178453,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hunan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Chenzhou',
		city_ascii: 'Chenzhou',
		lat: 25.80042645,
		lng: 113.0300927,
		pop: 251017.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hunan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zhijiang',
		city_ascii: 'Zhijiang',
		lat: 27.44094647,
		lng: 109.6780493,
		pop: 113907,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hunan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xiangtan',
		city_ascii: 'Xiangtan',
		lat: 27.85043052,
		lng: 112.9000232,
		pop: 2183454,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hunan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zigong',
		city_ascii: 'Zigong',
		lat: 29.40000002,
		lng: 104.780002,
		pop: 897480.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Yaan',
		city_ascii: 'Yaan',
		lat: 29.98042971,
		lng: 103.0800024,
		pop: 340000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Langzhong',
		city_ascii: 'Langzhong',
		lat: 31.57593955,
		lng: 105.9655627,
		pop: 60542,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Rongzhag',
		city_ascii: 'Rongzhag',
		lat: 30.95044802,
		lng: 101.9166626,
		pop: 52500,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Simao',
		city_ascii: 'Simao',
		lat: 22.78068829,
		lng: 100.9781669,
		pop: 162725,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Yunnan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Wenshan',
		city_ascii: 'Wenshan',
		lat: 23.3723576,
		lng: 104.2496984,
		pop: 108396,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Yunnan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Zhanyi',
		city_ascii: 'Zhanyi',
		lat: 25.6004645,
		lng: 103.8166499,
		pop: 652604,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Yunnan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Huize',
		city_ascii: 'Huize',
		lat: 26.35041872,
		lng: 103.4166744,
		pop: 5170,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Yunnan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Chengde',
		city_ascii: 'Chengde',
		lat: 40.96037966,
		lng: 117.9300004,
		pop: 377629,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Cangzhou',
		city_ascii: 'Cangzhou',
		lat: 38.32038576,
		lng: 116.8700134,
		pop: 527681,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Baoding',
		city_ascii: 'Baoding',
		lat: 38.87042971,
		lng: 115.4800207,
		pop: 1051326,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Huanghua',
		city_ascii: 'Hunanghua',
		lat: 38.37043439,
		lng: 117.330037,
		pop: 104243,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Dingzhou',
		city_ascii: 'Dingzhou',
		lat: 38.50042645,
		lng: 114.9999983,
		pop: 152934,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Nangong',
		city_ascii: 'Nangong',
		lat: 37.37039207,
		lng: 115.3700016,
		pop: 82386,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Linqing',
		city_ascii: 'Linqing',
		lat: 36.85039797,
		lng: 115.6800085,
		pop: 110046,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xiangtai',
		city_ascii: 'Xiangtai',
		lat: 37.04997235,
		lng: 114.5000288,
		pop: 611739,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Puyang',
		city_ascii: 'Puyang',
		lat: 35.70039064,
		lng: 114.9799996,
		pop: 666322,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Hebi',
		city_ascii: 'Hebi',
		lat: 35.95037539,
		lng: 114.2200459,
		pop: 244662,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xuchang',
		city_ascii: 'Xuchang',
		lat: 34.02038983,
		lng: 113.8200187,
		pop: 449258,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zhoukou',
		city_ascii: 'Zhoukou',
		lat: 33.63041363,
		lng: 114.6300468,
		pop: 377061,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Dengzhou',
		city_ascii: 'Dengzhou',
		lat: 32.68036826,
		lng: 112.0800215,
		pop: 59338,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Tieling',
		city_ascii: 'Tieling',
		lat: 42.30037539,
		lng: 123.8199768,
		pop: 336953.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Chaoyang',
		city_ascii: 'Chaoyang',
		lat: 41.55042116,
		lng: 120.4199776,
		pop: 440150.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Huanren',
		city_ascii: 'Huanren',
		lat: 41.25633059,
		lng: 125.3459818,
		pop: 91384,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zhuanghe',
		city_ascii: 'Zhuanghe',
		lat: 39.6822923,
		lng: 122.9618896,
		pop: 170947,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yishui',
		city_ascii: 'Yishui',
		lat: 35.79043683,
		lng: 118.6199841,
		pop: 94115,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Shanxian',
		city_ascii: 'Shanxian',
		lat: 34.79042035,
		lng: 116.0799841,
		pop: 74459,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Pingyi',
		city_ascii: 'Pingyi',
		lat: 35.51042808,
		lng: 117.6200451,
		pop: 78254,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Pingdu',
		city_ascii: 'Pingdu',
		lat: 36.79037579,
		lng: 119.9400069,
		pop: 91077,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Laiwu',
		city_ascii: 'Laiwu',
		lat: 36.2004118,
		lng: 117.6600427,
		pop: 124108,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Buizhou',
		city_ascii: 'Buizhou',
		lat: 37.37039207,
		lng: 118.0200207,
		pop: 115893,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Liaocheng',
		city_ascii: 'Liaocheng',
		lat: 36.4304236,
		lng: 115.9700166,
		pop: 226930,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Rizhao',
		city_ascii: 'Rizhao',
		lat: 35.43038129,
		lng: 119.4500109,
		pop: 555693.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Dezhou',
		city_ascii: 'Dezhou',
		lat: 37.45041302,
		lng: 116.3000223,
		pop: 379555,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Linchuan',
		city_ascii: 'Linchuan',
		lat: 27.97034568,
		lng: 116.3600187,
		pop: 241104,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Fengcheng',
		city_ascii: 'Fengcheng',
		lat: 28.20040916,
		lng: 115.7700288,
		pop: 61469,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jian',
		city_ascii: 'Jian',
		lat: 27.13042279,
		lng: 114.9999983,
		pop: 490221,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Shangrao',
		city_ascii: 'Shangrao',
		lat: 28.47039268,
		lng: 117.9699979,
		pop: 922421.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jingdezhen',
		city_ascii: 'Jingdezhen',
		lat: 29.27042137,
		lng: 117.1800203,
		pop: 383931.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Taizhou',
		city_ascii: 'Taizhou',
		lat: 32.4904057,
		lng: 119.9000093,
		pop: 612356,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangsu',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Shuyang',
		city_ascii: 'Shuyang',
		lat: 34.12986635,
		lng: 118.7733597,
		pop: 1770000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangsu',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Lianyungang',
		city_ascii: 'Lianyungang',
		lat: 34.60043194,
		lng: 119.170028,
		pop: 715600,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangsu',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Lishui',
		city_ascii: 'Lishui',
		lat: 28.45041974,
		lng: 119.9000093,
		pop: 135861.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Zhejiang',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jiaojing',
		city_ascii: 'Jiaojing',
		lat: 28.6804057,
		lng: 121.4499922,
		pop: 471152,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Zhejiang',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Quzhou',
		city_ascii: 'Quzhou',
		lat: 28.97043968,
		lng: 118.8699947,
		pop: 226108,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Zhejiang',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Fuyu',
		city_ascii: 'Fuyu',
		lat: 45.18038047,
		lng: 124.8200191,
		pop: 247804.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Dunhua',
		city_ascii: 'Dunhua',
		lat: 43.35049217,
		lng: 128.2200183,
		pop: 170357,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Nongan',
		city_ascii: 'Nongan',
		lat: 44.43040041,
		lng: 125.1700752,
		pop: 141482,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Taonan',
		city_ascii: 'Taonan',
		lat: 45.33042299,
		lng: 122.7800402,
		pop: 114715,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Liuhe',
		city_ascii: 'Liuhe',
		lat: 42.27885215,
		lng: 125.7173287,
		pop: 67956.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Huinan',
		city_ascii: 'Huinan',
		lat: 42.62293968,
		lng: 126.2614298,
		pop: 43261.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Panshi',
		city_ascii: 'Panshi',
		lat: 42.94263592,
		lng: 126.0561193,
		pop: 83208,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Jiaohe',
		city_ascii: 'Jiaohe',
		lat: 43.71628379,
		lng: 127.3459631,
		pop: 90420,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Linjiang',
		city_ascii: 'Linjiang',
		lat: 41.83634686,
		lng: 126.9359623,
		pop: 76732,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Wangqing',
		city_ascii: 'Wangqing',
		lat: 43.32478314,
		lng: 129.7343444,
		pop: 79825,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Helong',
		city_ascii: 'Helong',
		lat: 42.53475384,
		lng: 129.0043632,
		pop: 83032.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Shulan',
		city_ascii: 'Shulan',
		lat: 44.40910972,
		lng: 126.9487264,
		pop: 78092,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Jiutai',
		city_ascii: 'Jiutai',
		lat: 44.14473309,
		lng: 125.8443493,
		pop: 190257,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Alxa Zuoqi',
		city_ascii: 'Alxa Zuoqi',
		lat: 38.83901044,
		lng: 105.6686299,
		pop: 56387,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Linxi',
		city_ascii: 'Linxi',
		lat: 43.51707115,
		lng: 118.0333016,
		pop: 679,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Kailu',
		city_ascii: 'Kailu',
		lat: 43.58373374,
		lng: 121.1999816,
		pop: 2809,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Bairin Zuoqi',
		city_ascii: 'Bairin Zuoqi',
		lat: 43.98370933,
		lng: 119.1833606,
		pop: 48540,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yitulihe',
		city_ascii: 'Yitulihe',
		lat: 50.65162274,
		lng: 121.5166548,
		pop: 19645,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yakeshi',
		city_ascii: 'Yakeshi',
		lat: 49.28041445,
		lng: 120.7300362,
		pop: 107047,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Bugt',
		city_ascii: 'Bugt',
		lat: 48.78380393,
		lng: 121.9333736,
		pop: 17457,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Wuyuan',
		city_ascii: 'Wuyuan',
		lat: 41.08962242,
		lng: 108.2721919,
		pop: 30057,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Bayan Obo',
		city_ascii: 'Bayan Obo',
		lat: 41.76764305,
		lng: 109.9711063,
		pop: 27476,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Fengzhen',
		city_ascii: 'Fengzhen',
		lat: 40.45469993,
		lng: 113.1443493,
		pop: 85809,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Suihua',
		city_ascii: 'Suihua',
		lat: 46.63042116,
		lng: 126.9799906,
		pop: 250903,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Shuangyashan',
		city_ascii: 'Shuangyashan',
		lat: 46.67041872,
		lng: 131.3500081,
		pop: 500000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Shangzhi',
		city_ascii: 'Shangzhi',
		lat: 45.22042971,
		lng: 127.9700077,
		pop: 89699.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Fujin',
		city_ascii: 'Fujin',
		lat: 47.2703821,
		lng: 132.019993,
		pop: 80092.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Yian',
		city_ascii: 'Yian',
		lat: 47.88039654,
		lng: 125.2999898,
		pop: 39924,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Tailai',
		city_ascii: 'Tailai',
		lat: 46.3903583,
		lng: 123.409976,
		pop: 71307.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Longjiang',
		city_ascii: 'Longjiang',
		lat: 47.34040367,
		lng: 123.1800158,
		pop: 87115,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Gannan',
		city_ascii: 'Gannan',
		lat: 47.92036826,
		lng: 123.5100215,
		pop: 46854.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Hailun',
		city_ascii: 'Hailun',
		lat: 47.45042279,
		lng: 126.9300195,
		pop: 104848.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Mishan',
		city_ascii: 'Mishan',
		lat: 45.55038373,
		lng: 131.8800016,
		pop: 80459,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Tieli',
		city_ascii: 'Tieli',
		lat: 46.95037579,
		lng: 128.0500028,
		pop: 109228.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Shuangcheng',
		city_ascii: 'Shuangcheng',
		lat: 45.35034426,
		lng: 126.2799816,
		pop: 118525,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Zhaodong',
		city_ascii: 'Zhaodong',
		lat: 46.08042889,
		lng: 125.98,
		pop: 167193,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Lanxi',
		city_ascii: 'Lanxi',
		lat: 46.2663607,
		lng: 126.2759509,
		pop: 67011,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Keshan',
		city_ascii: 'Keshan',
		lat: 48.02633079,
		lng: 125.8659501,
		pop: 72403,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Nancha',
		city_ascii: 'Nancha',
		lat: 47.13635927,
		lng: 129.285948,
		pop: 104570.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Xinqing',
		city_ascii: 'Xinqing',
		lat: 48.23634381,
		lng: 129.5059346,
		pop: 42617.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Hulin',
		city_ascii: 'Hulin',
		lat: 45.76902671,
		lng: 132.9922334,
		pop: 42559,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Boli',
		city_ascii: 'Boli',
		lat: 45.75636599,
		lng: 130.5759468,
		pop: 83317,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Ningan',
		city_ascii: 'Ningan',
		lat: 44.33133669,
		lng: 129.4659371,
		pop: 54636,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Jyekundo',
		city_ascii: 'Jyekundo',
		lat: 33.01662681,
		lng: 96.73330969,
		pop: 16500,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Liuzhou',
		city_ascii: 'Liuzhou',
		lat: 24.28000246,
		lng: 109.2500134,
		pop: 1436030.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Xingyi',
		city_ascii: 'Xingyi',
		lat: 25.09041811,
		lng: 104.8900211,
		pop: 539536,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guizhou',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Anshun',
		city_ascii: 'Anshun',
		lat: 26.25039899,
		lng: 105.9300093,
		pop: 600468,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guizhou',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Zunyi',
		city_ascii: 'Zunyi',
		lat: 27.70002626,
		lng: 106.9200264,
		pop: 657646,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guizhou',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Wanzhou',
		city_ascii: 'Wanxian',
		lat: 30.81999086,
		lng: 108.4000394,
		pop: 1680000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Chongqing',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Huaibei',
		city_ascii: 'Huaibei',
		lat: 33.95036826,
		lng: 116.7500207,
		pop: 908019.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Anhui',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Wuhu',
		city_ascii: 'Wuhu',
		lat: 31.3504236,
		lng: 118.3699735,
		pop: 658762,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Anhui',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Luan',
		city_ascii: 'Luan',
		lat: 31.75034751,
		lng: 116.4800114,
		pop: 1408227.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Anhui',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Bengbu',
		city_ascii: 'Bengbu',
		lat: 32.94999005,
		lng: 117.330037,
		pop: 735324,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Anhui',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Anqing',
		city_ascii: 'Anqing',
		lat: 30.49995872,
		lng: 117.0500024,
		pop: 469579,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Anhui',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Foshan',
		city_ascii: 'Foshan',
		lat: 23.03005292,
		lng: 113.1200097,
		pop: 785174,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Nagchu',
		city_ascii: 'Nagchu',
		lat: 31.48000226,
		lng: 92.05002966,
		pop: 2250,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xizang',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Nyingchi',
		city_ascii: 'Nyingchi',
		lat: 29.53329938,
		lng: 94.41670691,
		pop: 55,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xizang',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Chamdo',
		city_ascii: 'Chamdo',
		lat: 31.16668805,
		lng: 97.23327917,
		pop: 93140,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xizang',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Korla',
		city_ascii: 'Korla',
		lat: 41.72999676,
		lng: 86.15002803,
		pop: 192919,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Kuqa',
		city_ascii: 'Kuqa',
		lat: 41.72774884,
		lng: 82.93637406,
		pop: 89802,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Tacheng',
		city_ascii: 'Tacheng',
		lat: 46.75002626,
		lng: 82.95001664,
		pop: 49796,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Shihezi',
		city_ascii: 'Shihezi',
		lat: 44.29996909,
		lng: 86.02993201,
		pop: 572977,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Karamay',
		city_ascii: 'Karamay',
		lat: 45.58994204,
		lng: 84.8599259,
		pop: 108769,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Aksu',
		city_ascii: 'Aksu',
		lat: 41.15000633,
		lng: 80.25002641,
		pop: 309704.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Kashgar',
	},
	{
		city: 'Sanya',
		city_ascii: 'Sanya',
		lat: 18.25910454,
		lng: 109.5040317,
		pop: 253721,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hainan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Haikou',
		city_ascii: 'Haikou',
		lat: 20.05000226,
		lng: 110.3200256,
		pop: 1606808.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hainan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Hanzhong',
		city_ascii: 'Hanzhong',
		lat: 33.12997906,
		lng: 107.0299939,
		pop: 145986,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shaanxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Baoji',
		city_ascii: 'Baoji',
		lat: 34.38000612,
		lng: 107.1499865,
		pop: 800000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shaanxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Tongchuan',
		city_ascii: 'Tongchuan',
		lat: 35.07998924,
		lng: 109.0299751,
		pop: 252930.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shaanxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Linfen',
		city_ascii: 'Linfen',
		lat: 36.08034161,
		lng: 111.520004,
		pop: 533283,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shanxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yuci',
		city_ascii: 'Yuci',
		lat: 37.68039899,
		lng: 112.7300077,
		pop: 537964.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shanxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Datong',
		city_ascii: 'Datong',
		lat: 40.08001996,
		lng: 113.2999987,
		pop: 1462839,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shanxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jianmen',
		city_ascii: 'Jianmen',
		lat: 30.65005292,
		lng: 113.1600073,
		pop: 937875,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yichang',
		city_ascii: 'Yichang',
		lat: 30.69997235,
		lng: 111.2800187,
		pop: 675862.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xiantao',
		city_ascii: 'Xiantao',
		lat: 30.3704059,
		lng: 113.4400419,
		pop: 897703,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Macheng',
		city_ascii: 'Macheng',
		lat: 31.17999473,
		lng: 115.0300223,
		pop: 126366,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Huangshi',
		city_ascii: 'Huangshi',
		lat: 30.22000165,
		lng: 115.0999922,
		pop: 688090,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zhuzhou',
		city_ascii: 'Zhuzhou',
		lat: 27.82999249,
		lng: 113.1500337,
		pop: 894679,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hunan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yongzhou',
		city_ascii: 'Yongzhou',
		lat: 26.23037437,
		lng: 111.6199979,
		pop: 700180.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hunan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yiyang',
		city_ascii: 'Yiyang',
		lat: 28.60041058,
		lng: 112.3300321,
		pop: 777304,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hunan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Changde',
		city_ascii: 'Changde',
		lat: 29.02999676,
		lng: 111.6800459,
		pop: 993390,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hunan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Shaoyang',
		city_ascii: 'Shaoyang',
		lat: 26.99999147,
		lng: 111.2000752,
		pop: 45617,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hunan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Leshan',
		city_ascii: 'Leshan',
		lat: 29.56709576,
		lng: 103.7333475,
		pop: 655738.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Panzhihua',
		city_ascii: 'Panzhihua',
		lat: 26.5499931,
		lng: 101.7300073,
		pop: 446298,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Fulin',
		city_ascii: 'Fulin',
		lat: 29.35000307,
		lng: 102.7167171,
		pop: 1049,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Guangyuan',
		city_ascii: 'Guangyuan',
		lat: 32.42999595,
		lng: 105.870013,
		pop: 325400,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Luzhou',
		city_ascii: 'Luzhou',
		lat: 28.87998008,
		lng: 105.380017,
		pop: 1537000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Yibin',
		city_ascii: 'Yibin',
		lat: 28.7699868,
		lng: 104.5700406,
		pop: 572055.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Zhaotang',
		city_ascii: 'Zhaotang',
		lat: 27.32038535,
		lng: 103.720015,
		pop: 459200,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Yunnan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Lijiang',
		city_ascii: 'Lijiang',
		lat: 26.80000368,
		lng: 100.2665824,
		pop: 18445,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Yunnan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Yuxi',
		city_ascii: 'Yuxi',
		lat: 24.37999636,
		lng: 102.5700077,
		pop: 250077,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Yunnan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Dali',
		city_ascii: 'Dali',
		lat: 25.70001914,
		lng: 100.1799727,
		pop: 145362.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Yunnan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Qinhuangdao',
		city_ascii: 'Qinhuangdao',
		lat: 39.93036501,
		lng: 119.6200264,
		pop: 881359,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Langfang',
		city_ascii: 'Langfang',
		lat: 39.5203642,
		lng: 116.6799991,
		pop: 694465.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zhangjiakou',
		city_ascii: 'Zhangjiakou',
		lat: 40.83000002,
		lng: 114.9299768,
		pop: 802820.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Tangshan',
		city_ascii: 'Tangshan',
		lat: 39.62433718,
		lng: 118.194377,
		pop: 1737974.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Anyang',
		city_ascii: 'Anyang',
		lat: 36.07997988,
		lng: 114.3500122,
		pop: 834064.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jiaozuo',
		city_ascii: 'Jiaozuo',
		lat: 35.2500047,
		lng: 113.2200036,
		pop: 687270,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Kaifeng',
		city_ascii: 'Kaifeng',
		lat: 34.85000327,
		lng: 114.3500122,
		pop: 872000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Shangqiu',
		city_ascii: 'Shangqiu',
		lat: 34.45041526,
		lng: 115.6500362,
		pop: 967109,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Pingdingshan',
		city_ascii: 'Pingdingshan',
		lat: 33.73040753,
		lng: 113.2999987,
		pop: 849000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xinyang',
		city_ascii: 'Xinyang',
		lat: 32.130376,
		lng: 114.0699776,
		pop: 1411944,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xinxiang',
		city_ascii: 'Xinxiang',
		lat: 35.32043968,
		lng: 113.8699898,
		pop: 823300.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Luoyang',
		city_ascii: 'Luoyang',
		lat: 34.67998781,
		lng: 112.4700752,
		pop: 1552790.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Liaoyang',
		city_ascii: 'Liaoyang',
		lat: 41.27999839,
		lng: 123.1800158,
		pop: 740945,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Dandong',
		city_ascii: 'Dandong',
		lat: 40.14360781,
		lng: 124.3935852,
		pop: 750986.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yingkow',
		city_ascii: 'Yingkow',
		lat: 40.67034568,
		lng: 122.2800191,
		pop: 693079.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jinzhou',
		city_ascii: 'Jinzhou',
		lat: 41.12036989,
		lng: 121.1000394,
		pop: 780134.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Fuxin',
		city_ascii: 'Fuxin',
		lat: 42.0104706,
		lng: 121.6600052,
		pop: 729525,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Benxi',
		city_ascii: 'Benxi',
		lat: 41.33038291,
		lng: 123.7500069,
		pop: 923933,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Fushun',
		city_ascii: 'Fushun',
		lat: 41.86538902,
		lng: 123.8699996,
		pop: 1435323,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jining',
		city_ascii: 'Jining',
		lat: 35.40040895,
		lng: 116.5500329,
		pop: 818163.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Weifang',
		city_ascii: 'Weifang',
		lat: 36.7204059,
		lng: 119.1001098,
		pop: 973866,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Taian',
		city_ascii: 'Taian',
		lat: 36.19999839,
		lng: 117.1200756,
		pop: 1629000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Heze',
		city_ascii: 'Heze',
		lat: 35.22998008,
		lng: 115.4500484,
		pop: 796301,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Laiyang',
		city_ascii: 'Laiyang',
		lat: 36.9684011,
		lng: 120.7084354,
		pop: 209797,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xinyu',
		city_ascii: 'Xinyu',
		lat: 27.80002016,
		lng: 114.9299768,
		pop: 505240,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Ganzhou',
		city_ascii: 'Ganzhou',
		lat: 25.91997988,
		lng: 114.9500272,
		pop: 1216134.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jiujiang',
		city_ascii: 'Jiujiang',
		lat: 29.72997988,
		lng: 115.9800419,
		pop: 545616,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Changzhou',
		city_ascii: 'Changzhou',
		lat: 31.77998395,
		lng: 119.9699792,
		pop: 1138009,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangsu',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zhenjiang',
		city_ascii: 'Zhenjiang',
		lat: 32.21998293,
		lng: 119.4300122,
		pop: 743276,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangsu',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Nantong',
		city_ascii: 'Nantong',
		lat: 32.0303821,
		lng: 120.8250175,
		pop: 806625.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangsu',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jiaxing',
		city_ascii: 'Jiaxing',
		lat: 30.77040733,
		lng: 120.7499833,
		pop: 727050.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Zhejiang',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Huzhou',
		city_ascii: 'Huzhou',
		lat: 30.87037539,
		lng: 120.0999971,
		pop: 694660,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Zhejiang',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Shaoxing',
		city_ascii: 'Shaoxing',
		lat: 30.00037681,
		lng: 120.5700459,
		pop: 599141.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Zhejiang',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jinhua',
		city_ascii: 'Jinhua',
		lat: 29.12004295,
		lng: 119.6499987,
		pop: 617529,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Zhejiang',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Liaoyuan',
		city_ascii: 'Liaoyuan',
		lat: 42.89997703,
		lng: 125.1299743,
		pop: 485898.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Tumen',
		city_ascii: 'Tumen',
		lat: 42.9699986,
		lng: 129.8200756,
		pop: 89220,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Siping',
		city_ascii: 'Siping',
		lat: 43.17001223,
		lng: 124.3300232,
		pop: 528811,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Baicheng',
		city_ascii: 'Baicheng',
		lat: 45.62001772,
		lng: 122.8200378,
		pop: 351915.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Wuhai',
		city_ascii: 'Wuhai',
		lat: 39.66469647,
		lng: 106.8122294,
		pop: 218427,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Erenhot',
		city_ascii: 'Erenhot',
		lat: 43.66155845,
		lng: 111.9654549,
		pop: 19357,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jining',
		city_ascii: 'Jining',
		lat: 41.02998781,
		lng: 113.0800122,
		pop: 270236.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Arxan',
		city_ascii: 'Arxan',
		lat: 47.18330731,
		lng: 119.9666202,
		pop: 32023,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Manzhouli',
		city_ascii: 'Manzhouli',
		lat: 49.59998151,
		lng: 117.4299792,
		pop: 74214,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xilinhot',
		city_ascii: 'Xilinhot',
		lat: 43.94433189,
		lng: 116.0443274,
		pop: 116156.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Heihe',
		city_ascii: 'Heihe',
		lat: 50.25001935,
		lng: 127.4460087,
		pop: 54923,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Qitaihe',
		city_ascii: 'Qitaihe',
		lat: 45.7999809,
		lng: 130.8500386,
		pop: 397825,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Yichun',
		city_ascii: 'Yichun',
		lat: 47.69994244,
		lng: 128.8999768,
		pop: 443111.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Hegang',
		city_ascii: 'Hegang',
		lat: 47.40001243,
		lng: 130.3700162,
		pop: 657833.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Nenjiang',
		city_ascii: 'Nenjiang',
		lat: 49.1799813,
		lng: 125.2300199,
		pop: 79685,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Nehe',
		city_ascii: 'Nehe',
		lat: 48.48999758,
		lng: 124.8800154,
		pop: 74937.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Mudangiang',
		city_ascii: 'Mudangiang',
		lat: 44.57501691,
		lng: 129.5900122,
		pop: 954957.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Xuanzhou',
		city_ascii: 'Xuanzhou',
		lat: 30.9525,
		lng: 118.7552778,
		pop: 866000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Anhui',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zhuhai',
		city_ascii: 'Zhuhai',
		lat: 22.2769444,
		lng: 113.5677778,
		pop: 1023000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xianyang',
		city_ascii: 'Xianyang',
		lat: 34.3455556,
		lng: 108.7147222,
		pop: 1126000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shaanxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Xiangfan',
		city_ascii: 'Xiangfan',
		lat: 32.01999514,
		lng: 112.1300443,
		pop: 765978,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Suining',
		city_ascii: 'Suining',
		lat: 30.5333333,
		lng: 105.5333333,
		pop: 1425000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Lingyuan',
		city_ascii: 'Lingyuan',
		lat: 41.24,
		lng: 119.4011111,
		pop: 806000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Weihai',
		city_ascii: 'Weihai',
		lat: 37.49997072,
		lng: 122.0999784,
		pop: 356425,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yichun',
		city_ascii: 'Yichun',
		lat: 27.8333333,
		lng: 114.4,
		pop: 982000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yancheng',
		city_ascii: 'Yancheng',
		lat: 33.3855556,
		lng: 120.1252778,
		pop: 839000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangsu',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Fuyang',
		city_ascii: 'Fuyang',
		lat: 30.0533333,
		lng: 119.9519444,
		pop: 771000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Zhejiang',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xiamen',
		city_ascii: 'Xiamen',
		lat: 24.44999208,
		lng: 118.080017,
		pop: 1548668.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Fujian',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Nanchong',
		city_ascii: 'Nanchong',
		lat: 30.78043256,
		lng: 106.1299971,
		pop: 2174000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Neijiang',
		city_ascii: 'Neijiang',
		lat: 29.58037661,
		lng: 105.0500114,
		pop: 1006427,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Nanyang',
		city_ascii: 'Nanyang',
		lat: 33.00040041,
		lng: 112.5300199,
		pop: 1097766,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jinxi',
		city_ascii: 'Jinxi',
		lat: 40.7503408,
		lng: 120.8299784,
		pop: 1369623.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yantai',
		city_ascii: 'Yantai',
		lat: 37.53040814,
		lng: 121.4000211,
		pop: 1417666,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zaozhuang',
		city_ascii: 'Zaozhuang',
		lat: 34.88000144,
		lng: 117.5700223,
		pop: 1164332.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Suzhou',
		city_ascii: 'Suzhou',
		lat: 31.30047833,
		lng: 120.620017,
		pop: 1496545.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangsu',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xuzhou',
		city_ascii: 'Xuzhou',
		lat: 34.28001223,
		lng: 117.1800203,
		pop: 1645096.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangsu',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Wuxi',
		city_ascii: 'Wuxi',
		lat: 31.57999615,
		lng: 120.2999849,
		pop: 1428823.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangsu',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jilin',
		city_ascii: 'Jilin',
		lat: 43.84997072,
		lng: 126.5500427,
		pop: 2138988.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Zhangye',
		city_ascii: 'Zhangye',
		lat: 38.9299868,
		lng: 100.4500337,
		pop: 163478,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Wuwei',
		city_ascii: 'Wuwei',
		lat: 37.92800661,
		lng: 102.6410111,
		pop: 493092,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Dunhuang',
		city_ascii: 'Dunhuang',
		lat: 40.14267763,
		lng: 94.66201493,
		pop: 139225,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Tianshui',
		city_ascii: 'Tianshui',
		lat: 34.60001853,
		lng: 105.9199841,
		pop: 649883.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Dulan',
		city_ascii: 'Dulan',
		lat: 36.16658958,
		lng: 98.26660111,
		pop: 50,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Golmud',
		city_ascii: 'Golmud',
		lat: 36.416626,
		lng: 94.88334509,
		pop: 107092,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Yulin',
		city_ascii: 'Yulin',
		lat: 22.62997398,
		lng: 110.1500101,
		pop: 637742.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Bose',
		city_ascii: 'Bose',
		lat: 23.8997156,
		lng: 106.6133268,
		pop: 132942.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Wuzhou',
		city_ascii: 'Wuzhou',
		lat: 23.48002545,
		lng: 111.3200162,
		pop: 354080.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Lupanshui',
		city_ascii: 'Lupanshui',
		lat: 26.59443483,
		lng: 104.8333321,
		pop: 886256,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guizhou',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Quanzhou',
		city_ascii: 'Quanzhou',
		lat: 24.9000163,
		lng: 118.5799865,
		pop: 823571.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Fujian',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Hefei',
		city_ascii: 'Hefei',
		lat: 31.85003135,
		lng: 117.2800142,
		pop: 1711952,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Anhui',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Suzhou',
		city_ascii: 'Suzhou',
		lat: 33.6361111,
		lng: 116.9788889,
		pop: 1964000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Anhui',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zhanjiang',
		city_ascii: 'Zhanjiang',
		lat: 21.19998374,
		lng: 110.3800219,
		pop: 1113895,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Shaoguan',
		city_ascii: 'Shaoguan',
		lat: 24.79997072,
		lng: 113.5799816,
		pop: 674507.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xigaze',
		city_ascii: 'Xigaze',
		lat: 29.25000917,
		lng: 88.88334957,
		pop: 59314.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xizang',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Shache',
		city_ascii: 'Shache',
		lat: 38.42614158,
		lng: 77.25000281,
		pop: 282391.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Kashgar',
	},
	{
		city: 'Yining',
		city_ascii: 'Yining',
		lat: 43.90001935,
		lng: 81.35001094,
		pop: 403489,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Kashgar',
	},
	{
		city: 'Altay',
		city_ascii: 'Altay',
		lat: 47.86659894,
		lng: 88.11662634,
		pop: 140670.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Shizuishan',
		city_ascii: 'Shizuishan',
		lat: 39.23327578,
		lng: 106.7690279,
		pop: 109824,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Ningxia Hui',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Yulin',
		city_ascii: 'Yulin',
		lat: 38.28330792,
		lng: 109.7332914,
		pop: 123415,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shaanxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Ankang',
		city_ascii: 'Ankang',
		lat: 32.67998069,
		lng: 109.0200016,
		pop: 1025000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shaanxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Houma',
		city_ascii: 'Houma',
		lat: 35.61998212,
		lng: 111.2099971,
		pop: 102400,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shanxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yueyang',
		city_ascii: 'Yueyang',
		lat: 29.38005292,
		lng: 113.1000109,
		pop: 826000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hunan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Hengyang',
		city_ascii: 'Hengyang',
		lat: 26.88002464,
		lng: 112.5900162,
		pop: 887801,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hunan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Mianyang',
		city_ascii: 'Mianyang',
		lat: 31.46997703,
		lng: 104.7699768,
		pop: 830068,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Xichang',
		city_ascii: 'Xichang',
		lat: 27.88001528,
		lng: 102.2999983,
		pop: 253390,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Baoshan',
		city_ascii: 'Baoshan',
		lat: 25.11997703,
		lng: 99.15000972,
		pop: 925000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Yunnan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Gejiu',
		city_ascii: 'Gejiu',
		lat: 23.37997988,
		lng: 103.1500756,
		pop: 142620,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Yunnan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Shijianzhuang',
		city_ascii: 'Shijianzhuang',
		lat: 38.05001467,
		lng: 114.4799784,
		pop: 2204737,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Handan',
		city_ascii: 'Handan',
		lat: 36.5799752,
		lng: 114.4799784,
		pop: 1494659,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hebei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Anshan',
		city_ascii: 'Anshan',
		lat: 41.11502138,
		lng: 122.9400305,
		pop: 1419137.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Dalian',
		city_ascii: 'Dalian',
		lat: 38.92283839,
		lng: 121.6298308,
		pop: 2601153.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Qingdao',
		city_ascii: 'Qingdao',
		lat: 36.08997927,
		lng: 120.3300089,
		pop: 2254122.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Linyi',
		city_ascii: 'Linyi',
		lat: 35.07998924,
		lng: 118.329976,
		pop: 1176334.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Huaiyin',
		city_ascii: 'Huaiyin',
		lat: 33.58000327,
		lng: 119.0299849,
		pop: 909615,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangsu',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Wenzhou',
		city_ascii: 'Wenzhou',
		lat: 28.0199809,
		lng: 120.6500927,
		pop: 1607836,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Zhejiang',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Ningbo',
		city_ascii: 'Ningbo',
		lat: 29.87997072,
		lng: 121.5500378,
		pop: 1321433.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Zhejiang',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Tongliao',
		city_ascii: 'Tongliao',
		lat: 43.61995892,
		lng: 122.2699939,
		pop: 572555,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Hohhot',
		city_ascii: 'Hohhot',
		lat: 40.81997479,
		lng: 111.6599955,
		pop: 1250238.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Chifeng',
		city_ascii: 'Chifeng',
		lat: 42.27001548,
		lng: 118.9499898,
		pop: 811827,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Ulanhot',
		city_ascii: 'Ulanhot',
		lat: 46.08001548,
		lng: 122.0800313,
		pop: 203870,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Hailar',
		city_ascii: 'Hailar',
		lat: 49.19998008,
		lng: 119.7000215,
		pop: 221118.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jiamusi',
		city_ascii: 'Jiamusi',
		lat: 46.83002138,
		lng: 130.3500175,
		pop: 784774.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Beian',
		city_ascii: 'Beian',
		lat: 48.23900515,
		lng: 126.4820365,
		pop: 154936,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Daqing',
		city_ascii: 'Daqing',
		lat: 46.57995913,
		lng: 125.0000081,
		pop: 948244,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Jixi',
		city_ascii: 'Jixi',
		lat: 45.29995974,
		lng: 130.9700313,
		pop: 684379.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Jiayuguan',
		city_ascii: 'Jiayuguan',
		lat: 39.82000999,
		lng: 98.29998409,
		pop: 135337.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Xining',
		city_ascii: 'Xining',
		lat: 36.6199986,
		lng: 101.7700048,
		pop: 907765.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Guilin',
		city_ascii: 'Guilin',
		lat: 25.2799931,
		lng: 110.280028,
		pop: 818176,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Huainan',
		city_ascii: 'Huainan',
		lat: 32.62998374,
		lng: 116.9799808,
		pop: 1239327.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Anhui',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Shantou',
		city_ascii: 'Shantou',
		lat: 23.37000633,
		lng: 116.6700256,
		pop: 1467486.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Lhasa',
		city_ascii: 'Lhasa',
		lat: 29.64502382,
		lng: 91.10001013,
		pop: 169160,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xizang',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Hami',
		city_ascii: 'Hami',
		lat: 42.82698407,
		lng: 93.51500484,
		pop: 218960,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Hotan',
		city_ascii: 'Hotan',
		lat: 37.09971092,
		lng: 79.92694535,
		pop: 187865,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Kashgar',
	},
	{
		city: 'Kashgar',
		city_ascii: 'Kashi',
		lat: 39.47633588,
		lng: 75.9699259,
		pop: 472069.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Kashgar',
	},
	{
		city: 'Yinchuan',
		city_ascii: 'Yinchuan',
		lat: 38.46797365,
		lng: 106.2730375,
		pop: 657614,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Ningxia Hui',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Pingxiang',
		city_ascii: 'Pingxiang',
		lat: 27.62000531,
		lng: 113.8500427,
		pop: 666561.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Qiqihar',
		city_ascii: 'Qiqihar',
		lat: 47.34497703,
		lng: 123.9899922,
		pop: 1261682,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Shenzhen',
		city_ascii: 'Shenzhen',
		lat: 22.55237051,
		lng: 114.1221231,
		pop: 4291796,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Zibo',
		city_ascii: 'Zibo',
		lat: 36.79998761,
		lng: 118.049993,
		pop: 1865385,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Lanzhou',
		city_ascii: 'Lanzhou',
		lat: 36.05602785,
		lng: 103.7920003,
		pop: 2282609,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Gansu',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Nanning',
		city_ascii: 'Nanning',
		lat: 22.81998822,
		lng: 108.3200443,
		pop: 1485394,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Guiyang',
		city_ascii: 'Guiyang',
		lat: 26.58004295,
		lng: 106.7200386,
		pop: 2416816.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guizhou',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Chongqing',
		city_ascii: 'Chongqing',
		lat: 29.56497703,
		lng: 106.5949816,
		pop: 5214014,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Chongqing',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Fuzhou',
		city_ascii: 'Fuzhou',
		lat: 26.07999595,
		lng: 119.3000459,
		pop: 1892860,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Fujian',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Guangzhou',
		city_ascii: 'Guangzhou',
		lat: 23.1449813,
		lng: 113.3250101,
		pop: 5990912.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Dongguan',
		city_ascii: 'Dongguan',
		lat: 23.0488889,
		lng: 113.7447222,
		pop: 4528000,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Guangdong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Xian',
		city_ascii: 'Xian',
		lat: 34.27502545,
		lng: 108.8949963,
		pop: 3617406,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shaanxi',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Taiyuan',
		city_ascii: 'Taiyuan',
		lat: 37.87501243,
		lng: 112.5450577,
		pop: 2817737.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shanxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Wuhan',
		city_ascii: 'Wuhan',
		lat: 30.58003135,
		lng: 114.270017,
		pop: 5713603,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hubei',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Changsha',
		city_ascii: 'Changsha',
		lat: 28.19996991,
		lng: 112.969993,
		pop: 2338969,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Hunan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Kunming',
		city_ascii: 'Kunming',
		lat: 25.06998008,
		lng: 102.6799751,
		pop: 1977337,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Yunnan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Zhengzhou',
		city_ascii: 'Zhengzhou',
		lat: 34.75499615,
		lng: 113.6650927,
		pop: 2325062.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Henan',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Shenyeng',
		city_ascii: 'Shenyeng',
		lat: 41.80497927,
		lng: 123.4499735,
		pop: 4149596,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Liaoning',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Jinan',
		city_ascii: 'Jinan',
		lat: 36.67498232,
		lng: 116.9950187,
		pop: 2433633,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shandong',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Tianjin',
		city_ascii: 'Tianjin',
		lat: 39.13002626,
		lng: 117.2000191,
		pop: 5473103.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Tianjin',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Nanchang',
		city_ascii: 'Nanchang',
		lat: 28.67999229,
		lng: 115.8799963,
		pop: 2110675.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangxi',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Nanjing',
		city_ascii: 'Nanjing',
		lat: 32.05001914,
		lng: 118.7799743,
		pop: 3383005,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jiangsu',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Hangzhou',
		city_ascii: 'Hangzhou',
		lat: 30.24997398,
		lng: 120.1700187,
		pop: 2442564.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Zhejiang',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Changchun',
		city_ascii: 'Changchun',
		lat: 43.86500856,
		lng: 125.3399873,
		pop: 2860210.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Jilin',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Baotou',
		city_ascii: 'Baotou',
		lat: 40.65220725,
		lng: 109.8220198,
		pop: 1229664.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Nei Mongol',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Harbin',
		city_ascii: 'Harbin',
		lat: 45.74998395,
		lng: 126.6499849,
		pop: 3425441.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Heilongjiang',
		timezone: 'Asia/Harbin',
	},
	{
		city: 'Urumqi',
		city_ascii: 'Urumqi',
		lat: 43.80501223,
		lng: 87.57500565,
		pop: 1829612.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Xinjiang Uygur',
		timezone: 'Asia/Urumqi',
	},
	{
		city: 'Chengdu',
		city_ascii: 'Chengdu',
		lat: 30.67000002,
		lng: 104.0700195,
		pop: 4036718.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Sichuan',
		timezone: 'Asia/Chongqing',
	},
	{
		city: 'Beijing',
		city_ascii: 'Beijing',
		lat: 39.92889223,
		lng: 116.3882857,
		pop: 9293300.5,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Beijing',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Shanghai',
		city_ascii: 'Shanghai',
		lat: 31.21645245,
		lng: 121.4365047,
		pop: 14797756,
		country: 'China',
		iso2: 'CN',
		iso3: 'CHN',
		province: 'Shanghai',
		timezone: 'Asia/Shanghai',
	},
	{
		city: 'Yopal',
		city_ascii: 'Yopal',
		lat: 5.346999095,
		lng: -72.4059986,
		pop: 61029,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Casanare',
		timezone: 'America/Bogota',
	},
	{
		city: 'San Andres',
		city_ascii: 'San Andres',
		lat: 12.56213711,
		lng: -81.69032658,
		pop: 58257,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: '',
		timezone: 'America/Bogota',
	},
	{
		city: 'Sonson',
		city_ascii: 'Sonson',
		lat: 5.716613585,
		lng: -75.31662785,
		pop: 18024.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Antioquia',
		timezone: 'America/Bogota',
	},
	{
		city: 'Sogamoso',
		city_ascii: 'Sogamoso',
		lat: 5.719998392,
		lng: -72.94000289,
		pop: 120071.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Boyacá',
		timezone: 'America/Bogota',
	},
	{
		city: 'Barrancabermeja',
		city_ascii: 'Barrancabermeja',
		lat: 7.089992288,
		lng: -73.84999903,
		pop: 177802,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Santander',
		timezone: 'America/Bogota',
	},
	{
		city: 'Girardot',
		city_ascii: 'Girardot',
		lat: 4.310006937,
		lng: -74.80999211,
		pop: 121830.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Cundinamarca',
		timezone: 'America/Bogota',
	},
	{
		city: 'Campoalegre',
		city_ascii: 'Campoalegre',
		lat: 2.690002461,
		lng: -75.33001205,
		pop: 17058.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Huila',
		timezone: 'America/Bogota',
	},
	{
		city: 'Tuquerres',
		city_ascii: 'Tuquerres',
		lat: 1.089996764,
		lng: -77.61994979,
		pop: 27081.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Nariño',
		timezone: 'America/Bogota',
	},
	{
		city: 'Mocoa',
		city_ascii: 'Mocoa',
		lat: 1.149993102,
		lng: -76.62998438,
		pop: 22035,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Putumayo',
		timezone: 'America/Bogota',
	},
	{
		city: 'Cartago',
		city_ascii: 'Cartago',
		lat: 4.749980081,
		lng: -75.91002832,
		pop: 123170,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Valle del Cauca',
		timezone: 'America/Bogota',
	},
	{
		city: 'Soledad',
		city_ascii: 'Soledad',
		lat: 10.92001691,
		lng: -74.76999455,
		pop: 520704,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Atlántico',
		timezone: 'America/Bogota',
	},
	{
		city: 'Sabanalarga',
		city_ascii: 'Sabanalarga',
		lat: 10.63998232,
		lng: -74.91998539,
		pop: 65781,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Atlántico',
		timezone: 'America/Bogota',
	},
	{
		city: 'Arjona',
		city_ascii: 'Arjona',
		lat: 10.26003135,
		lng: -75.34998499,
		pop: 47225,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Bolívar',
		timezone: 'America/Bogota',
	},
	{
		city: 'Magangue',
		city_ascii: 'Magangue',
		lat: 9.229990864,
		lng: -74.74002222,
		pop: 94912,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Bolívar',
		timezone: 'America/Bogota',
	},
	{
		city: 'Valledupar',
		city_ascii: 'Valledupar',
		lat: 10.47999208,
		lng: -73.25000981,
		pop: 297627.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Cesar',
		timezone: 'America/Bogota',
	},
	{
		city: 'San Jose del Guaviare',
		city_ascii: 'San Jose del Guaviare',
		lat: 2.569983947,
		lng: -72.63999536,
		pop: 21675,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Meta',
		timezone: 'America/Bogota',
	},
	{
		city: 'Puerto Lopez',
		city_ascii: 'Puerto Lopez',
		lat: 4.089994526,
		lng: -72.96002751,
		pop: 12163.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Meta',
		timezone: 'America/Bogota',
	},
	{
		city: 'Yarumal',
		city_ascii: 'Yarumal',
		lat: 7.030590229,
		lng: -75.5904871,
		pop: 35315,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Antioquia',
		timezone: 'America/Bogota',
	},
	{
		city: 'Puerto Berrio',
		city_ascii: 'Puerto Berrio',
		lat: 6.480442931,
		lng: -74.42001591,
		pop: 33194,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Antioquia',
		timezone: 'America/Bogota',
	},
	{
		city: 'Turbo',
		city_ascii: 'Turbo',
		lat: 8.100369892,
		lng: -76.73997766,
		pop: 42257.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Antioquia',
		timezone: 'America/Bogota',
	},
	{
		city: 'Tunja',
		city_ascii: 'Tunja',
		lat: 5.550448017,
		lng: -73.37002832,
		pop: 139445.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Boyacá',
		timezone: 'America/Bogota',
	},
	{
		city: 'Chiquinquira',
		city_ascii: 'Chiquinquira',
		lat: 5.620392068,
		lng: -73.81994918,
		pop: 49634.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Boyacá',
		timezone: 'America/Bogota',
	},
	{
		city: 'Duitama',
		city_ascii: 'Duitama',
		lat: 5.830456766,
		lng: -73.02004968,
		pop: 96598,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Boyacá',
		timezone: 'America/Bogota',
	},
	{
		city: 'Ayapel',
		city_ascii: 'Ayapel',
		lat: 8.330407531,
		lng: -75.15002303,
		pop: 23120.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Córdoba',
		timezone: 'America/Bogota',
	},
	{
		city: 'Lorica',
		city_ascii: 'Lorica',
		lat: 9.241850605,
		lng: -75.81600305,
		pop: 46688,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Córdoba',
		timezone: 'America/Bogota',
	},
	{
		city: 'Socorro',
		city_ascii: 'Socorro',
		lat: 6.460340799,
		lng: -73.26998275,
		pop: 21323.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Santander',
		timezone: 'America/Bogota',
	},
	{
		city: 'Riohacha',
		city_ascii: 'Riohacha',
		lat: 11.5403408,
		lng: -72.90997888,
		pop: 112808.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'La Guajira',
		timezone: 'America/Bogota',
	},
	{
		city: 'Armenia',
		city_ascii: 'Armenia',
		lat: 4.534282653,
		lng: -75.68112757,
		pop: 314797.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Quindío',
		timezone: 'America/Bogota',
	},
	{
		city: 'Pereira',
		city_ascii: 'Pereira',
		lat: 4.81038983,
		lng: -75.67999068,
		pop: 504434,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Risaralda',
		timezone: 'America/Bogota',
	},
	{
		city: 'Honda',
		city_ascii: 'Honda',
		lat: 5.190340799,
		lng: -74.74999577,
		pop: 31813.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Tolima',
		timezone: 'America/Bogota',
	},
	{
		city: 'San Vicente del Caguan',
		city_ascii: 'San Vicente del Caguan',
		lat: 2.070376199,
		lng: -74.64002832,
		pop: 1250,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Caquetá',
		timezone: 'America/Bogota',
	},
	{
		city: 'Florencia',
		city_ascii: 'Florencia',
		lat: 1.610404275,
		lng: -75.61999435,
		pop: 87599,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Caquetá',
		timezone: 'America/Bogota',
	},
	{
		city: 'Guapi',
		city_ascii: 'Guapi',
		lat: 2.560397968,
		lng: -77.85998682,
		pop: 13853,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Cauca',
		timezone: 'America/Bogota',
	},
	{
		city: 'Neiva',
		city_ascii: 'Neiva',
		lat: 2.931047179,
		lng: -75.33024459,
		pop: 318566,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Huila',
		timezone: 'America/Bogota',
	},
	{
		city: 'Garzon',
		city_ascii: 'Garzon',
		lat: 2.210393493,
		lng: -75.64996668,
		pop: 43027,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Huila',
		timezone: 'America/Bogota',
	},
	{
		city: 'Ipiales',
		city_ascii: 'Ipiales',
		lat: 0.830374368,
		lng: -77.64999964,
		pop: 93673.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Nariño',
		timezone: 'America/Bogota',
	},
	{
		city: 'Buenaventura',
		city_ascii: 'Buenaventura',
		lat: 3.872402425,
		lng: -77.05045329,
		pop: 246596,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Valle del Cauca',
		timezone: 'America/Bogota',
	},
	{
		city: 'Tulua',
		city_ascii: 'Tulua',
		lat: 4.090382099,
		lng: -76.21001001,
		pop: 164281.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Valle del Cauca',
		timezone: 'America/Bogota',
	},
	{
		city: 'El Carmen de Bolivar',
		city_ascii: 'El Carmen de Bolivar',
		lat: 9.720374368,
		lng: -75.12999841,
		pop: 54468.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Bolívar',
		timezone: 'America/Bogota',
	},
	{
		city: 'Jurado',
		city_ascii: 'Jurado',
		lat: 7.121747456,
		lng: -77.75640141,
		pop: 2351,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Chocó',
		timezone: 'America/Bogota',
	},
	{
		city: 'Nuqui',
		city_ascii: 'Nuqui',
		lat: 5.685180092,
		lng: -77.27454844,
		pop: 1487,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Chocó',
		timezone: 'America/Bogota',
	},
	{
		city: 'Quibdo',
		city_ascii: 'Quibdo',
		lat: 5.690413634,
		lng: -76.66000838,
		pop: 83942,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Chocó',
		timezone: 'America/Bogota',
	},
	{
		city: 'El Banco',
		city_ascii: 'El Banco',
		lat: 9.000340799,
		lng: -73.98001693,
		pop: 39628.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Magdalena',
		timezone: 'America/Bogota',
	},
	{
		city: 'Cienaga',
		city_ascii: 'Cienaga',
		lat: 11.01039899,
		lng: -74.25000045,
		pop: 109741,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Magdalena',
		timezone: 'America/Bogota',
	},
	{
		city: 'Sincelejo',
		city_ascii: 'Sincelejo',
		lat: 9.290426452,
		lng: -75.37995732,
		pop: 239787.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Sucre',
		timezone: 'America/Bogota',
	},
	{
		city: 'Tolu',
		city_ascii: 'Tolu',
		lat: 9.535837829,
		lng: -75.57203862,
		pop: 19719.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Sucre',
		timezone: 'America/Bogota',
	},
	{
		city: 'Arauca',
		city_ascii: 'Arauca',
		lat: 7.090664082,
		lng: -70.76163456,
		pop: 46530.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Arauca',
		timezone: 'America/Bogota',
	},
	{
		city: 'Tame',
		city_ascii: 'Tame',
		lat: 6.460340799,
		lng: -71.74002446,
		pop: 25030,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Arauca',
		timezone: 'America/Bogota',
	},
	{
		city: 'Pamplona',
		city_ascii: 'Pamplona',
		lat: 7.390387389,
		lng: -72.66001998,
		pop: 52917,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Norte de Santander',
		timezone: 'America/Bogota',
	},
	{
		city: 'Ocana',
		city_ascii: 'Ocana',
		lat: 8.240413024,
		lng: -73.3500037,
		pop: 83401.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Norte de Santander',
		timezone: 'America/Bogota',
	},
	{
		city: 'Orocue',
		city_ascii: 'Orocue',
		lat: 4.794551004,
		lng: -71.34002303,
		pop: 2126.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Casanare',
		timezone: 'America/Bogota',
	},
	{
		city: 'Obando',
		city_ascii: 'Obando',
		lat: 3.854039936,
		lng: -67.90609827,
		pop: 8181.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Guainía',
		timezone: 'America/Bogota',
	},
	{
		city: 'San Martin',
		city_ascii: 'San Martin',
		lat: 3.690406513,
		lng: -73.68998295,
		pop: 12304,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Meta',
		timezone: 'America/Bogota',
	},
	{
		city: 'Puerto Carreno',
		city_ascii: 'Puerto Carreno',
		lat: 6.185034606,
		lng: -67.49302271,
		pop: 8038.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Vichada',
		timezone: 'America/Bogota',
	},
	{
		city: 'Bello',
		city_ascii: 'Bello',
		lat: 6.329986998,
		lng: -75.5699974,
		pop: 456304.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Antioquia',
		timezone: 'America/Bogota',
	},
	{
		city: 'Monteria',
		city_ascii: 'Monteria',
		lat: 8.757539082,
		lng: -75.8900037,
		pop: 273809,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Córdoba',
		timezone: 'America/Bogota',
	},
	{
		city: 'Bucaramanga',
		city_ascii: 'Bucaramanga',
		lat: 7.1300932,
		lng: -73.12588302,
		pop: 790410,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Santander',
		timezone: 'America/Bogota',
	},
	{
		city: 'Ibague',
		city_ascii: 'Ibague',
		lat: 4.438913797,
		lng: -75.2322144,
		pop: 415156,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Tolima',
		timezone: 'America/Bogota',
	},
	{
		city: 'Popayan',
		city_ascii: 'Popayan',
		lat: 2.419993102,
		lng: -76.61001144,
		pop: 258750,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Cauca',
		timezone: 'America/Bogota',
	},
	{
		city: 'Santa Marta',
		city_ascii: 'Santa Marta',
		lat: 11.24720624,
		lng: -74.20165715,
		pop: 417211,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Magdalena',
		timezone: 'America/Bogota',
	},
	{
		city: 'Cucuta',
		city_ascii: 'Cucuta',
		lat: 7.920019144,
		lng: -72.51997685,
		pop: 721772,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Norte de Santander',
		timezone: 'America/Bogota',
	},
	{
		city: 'Villavicencio',
		city_ascii: 'Villavicencio',
		lat: 4.153323994,
		lng: -73.63499923,
		pop: 348240,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Meta',
		timezone: 'America/Bogota',
	},
	{
		city: 'Tumaco',
		city_ascii: 'Tumaco',
		lat: 1.809978657,
		lng: -78.80998051,
		pop: 86713,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Nariño',
		timezone: 'America/Bogota',
	},
	{
		city: 'Manizales',
		city_ascii: 'Manizales',
		lat: 5.059986998,
		lng: -75.52000045,
		pop: 366831,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Caldas',
		timezone: 'America/Bogota',
	},
	{
		city: 'Pasto',
		city_ascii: 'Pasto',
		lat: 1.21360679,
		lng: -77.28110742,
		pop: 371138.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Nariño',
		timezone: 'America/Bogota',
	},
	{
		city: 'Barranquilla',
		city_ascii: 'Barranquilla',
		lat: 10.95998863,
		lng: -74.79996688,
		pop: 1521245.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Atlántico',
		timezone: 'America/Bogota',
	},
	{
		city: 'Cartagena',
		city_ascii: 'Cartagena',
		lat: 10.39973859,
		lng: -75.51439356,
		pop: 887000,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Bolívar',
		timezone: 'America/Bogota',
	},
	{
		city: 'Mitu',
		city_ascii: 'Mitu',
		lat: 1.198310566,
		lng: -70.17360844,
		pop: 5917,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Vaupés',
		timezone: 'America/Bogota',
	},
	{
		city: 'Leticia',
		city_ascii: 'Leticia',
		lat: -4.220015036,
		lng: -69.93997929,
		pop: 46012.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Amazonas',
		timezone: 'America/Bogota',
	},
	{
		city: 'Medellin',
		city_ascii: 'Medellin',
		lat: 6.275003274,
		lng: -75.57501001,
		pop: 2648489.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Antioquia',
		timezone: 'America/Bogota',
	},
	{
		city: 'Cali',
		city_ascii: 'Cali',
		lat: 3.399959126,
		lng: -76.49996647,
		pop: 2216418,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Valle del Cauca',
		timezone: 'America/Bogota',
	},
	{
		city: 'Bogota',
		city_ascii: 'Bogota',
		lat: 4.596423563,
		lng: -74.08334396,
		pop: 7052830.5,
		country: 'Colombia',
		iso2: 'CO',
		iso3: 'COL',
		province: 'Bogota',
		timezone: 'America/Bogota',
	},
	{
		city: 'Moroni',
		city_ascii: 'Moroni',
		lat: -11.7041577,
		lng: 43.2402441,
		pop: 85785,
		country: 'Comoros',
		iso2: 'KM',
		iso3: 'COM',
		province: '',
		timezone: 'Indian/Comoro',
	},
	{
		city: 'Madingou',
		city_ascii: 'Madingou',
		lat: -4.164002942,
		lng: 13.55400049,
		pop: 22760,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Bouenza',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Kinkala',
		city_ascii: 'Kinkala',
		lat: -4.355999897,
		lng: 14.76199861,
		pop: 13882,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Pool',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Ewo',
		city_ascii: 'Ewo',
		lat: -0.879598776,
		lng: 14.82001501,
		pop: 7786.5,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Cuvette-Ouest',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Impfondo',
		city_ascii: 'Impfondo',
		lat: 1.640376606,
		lng: 18.04002519,
		pop: 20859,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Likouala',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Sembe',
		city_ascii: 'Sembe',
		lat: 1.640376606,
		lng: 14.58002966,
		pop: 6396,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Sangha',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Moloundou',
		city_ascii: 'Moloundou',
		lat: 2.033737609,
		lng: 15.21668331,
		pop: 12244,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Sangha',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Owando',
		city_ascii: 'Owando',
		lat: -0.47962319,
		lng: 15.91999955,
		pop: 29011,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Cuvette',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Makoua',
		city_ascii: 'Makoua',
		lat: -0.009574362,
		lng: 15.64001664,
		pop: 10335,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Cuvette',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Sibiti',
		city_ascii: 'Sibiti',
		lat: -3.689608135,
		lng: 13.35002722,
		pop: 20950,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Lékoumou',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Mossendjo',
		city_ascii: 'Mossendjo',
		lat: -2.939628073,
		lng: 12.71998816,
		pop: 24583.5,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Niari',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Loubomo',
		city_ascii: 'Loubomo',
		lat: -4.179604066,
		lng: 12.67001705,
		pop: 97929.5,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Niari',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Gamboma',
		city_ascii: 'Gamboma',
		lat: -1.879486065,
		lng: 15.85002966,
		pop: 20877,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Plateaux',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Djambala',
		city_ascii: 'Djambala',
		lat: -2.539600811,
		lng: 14.74999345,
		pop: 9650.5,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Plateaux',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Ouesso',
		city_ascii: 'Ouesso',
		lat: 1.609990864,
		lng: 16.05001745,
		pop: 26117.5,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Sangha',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Kayes',
		city_ascii: 'Kayes',
		lat: -4.180017478,
		lng: 13.28000565,
		pop: 60629,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Bouenza',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Pointe-Noire',
		city_ascii: 'Pointe-Noire',
		lat: -4.770007305,
		lng: 11.88003943,
		pop: 602440.5,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Kouilou',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Brazzaville',
		city_ascii: 'Brazzaville',
		lat: -4.259185772,
		lng: 15.28468949,
		pop: 1259445,
		country: 'Congo (Brazzaville)',
		iso2: 'CG',
		iso3: 'COG',
		province: 'Pool',
		timezone: 'Africa/Brazzaville',
	},
	{
		city: 'Buluko',
		city_ascii: 'Buluko',
		lat: -0.756998889,
		lng: 28.52800254,
		pop: 1192,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Nord-Kivu',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Zongo',
		city_ascii: 'Zongo',
		lat: 4.330341613,
		lng: 18.61502885,
		pop: 17667,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Équateur',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Libenge',
		city_ascii: 'Libenge',
		lat: 3.660434183,
		lng: 18.61998979,
		pop: 17402,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Équateur',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Bongandanga',
		city_ascii: 'Bongandanga',
		lat: 1.510358703,
		lng: 21.05002234,
		pop: 2896,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Équateur',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Ikela',
		city_ascii: 'Ikela',
		lat: -1.182939434,
		lng: 23.26657955,
		pop: 291,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Équateur',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Binga',
		city_ascii: 'Binga',
		lat: 2.383406188,
		lng: 20.41998328,
		pop: 64639,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Équateur',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Basankusu',
		city_ascii: 'Basankusu',
		lat: 1.233708922,
		lng: 19.80002112,
		pop: 52216,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Équateur',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Boende',
		city_ascii: 'Boende',
		lat: -0.219587383,
		lng: 20.8600081,
		pop: 24679.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Équateur',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Gbadolite',
		city_ascii: 'Gbadolite',
		lat: 4.290369892,
		lng: 21.01994665,
		pop: 35197,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Équateur',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Businga',
		city_ascii: 'Businga',
		lat: 3.340376199,
		lng: 20.86998165,
		pop: 31583,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Équateur',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Bosobolo',
		city_ascii: 'Bosobolo',
		lat: 4.190375996,
		lng: 19.88001623,
		pop: 8968.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Équateur',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Yangambi',
		city_ascii: 'Yangambi',
		lat: 0.77037803,
		lng: 24.43002274,
		pop: 18035.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Orientale',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Aketi',
		city_ascii: 'Aketi',
		lat: 2.740464497,
		lng: 23.7799849,
		pop: 46881,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Orientale',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Mongbwalu',
		city_ascii: 'Mongbwalu',
		lat: 1.9504352,
		lng: 30.0332983,
		pop: 2819,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Orientale',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Bafwasende',
		city_ascii: 'Bafwasende',
		lat: 1.083847269,
		lng: 27.26659379,
		pop: 149,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Orientale',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Bunia',
		city_ascii: 'Bunia',
		lat: 1.560407327,
		lng: 30.24000403,
		pop: 61537,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Orientale',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Wamba',
		city_ascii: 'Wamba',
		lat: 2.140423603,
		lng: 27.98996049,
		pop: 82122,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Orientale',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Basoko',
		city_ascii: 'Basoko',
		lat: 1.240426858,
		lng: 23.59002234,
		pop: 22953,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Orientale',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Kenge',
		city_ascii: 'Kenge',
		lat: -4.829590231,
		lng: 16.89993974,
		pop: 241,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bandundu',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Bolobo',
		city_ascii: 'Bolobo',
		lat: -2.159520651,
		lng: 16.23998002,
		pop: 22605.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bandundu',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Kahemba',
		city_ascii: 'Kahemba',
		lat: -7.282928855,
		lng: 19.00001827,
		pop: 45644.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bandundu',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Bulungu',
		city_ascii: 'Bulungu',
		lat: -4.549607321,
		lng: 18.59999101,
		pop: 42310.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bandundu',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Lusanga',
		city_ascii: 'Lusanga',
		lat: -5.582929261,
		lng: 16.51665564,
		pop: 177,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bandundu',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Mangai',
		city_ascii: 'Mangai',
		lat: -4.039612611,
		lng: 19.53001176,
		pop: 19946,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bandundu',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Kasongo-Lunda',
		city_ascii: 'Kasongo-Lunda',
		lat: -6.479618715,
		lng: 16.82996985,
		pop: 18748,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bandundu',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Mushie',
		city_ascii: 'Mushie',
		lat: -3.01962319,
		lng: 16.92004187,
		pop: 24013.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bandundu',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Dibaya',
		city_ascii: 'Dibaya',
		lat: -6.509539369,
		lng: 22.87001461,
		pop: 603,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Kasaï-Occidental',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Mweka',
		city_ascii: 'Mweka',
		lat: -4.839615459,
		lng: 21.5699906,
		pop: 45222,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Kasaï-Occidental',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Luebo',
		city_ascii: 'Luebo',
		lat: -5.349506817,
		lng: 21.41000036,
		pop: 17682.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Kasaï-Occidental',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Demba',
		city_ascii: 'Demba',
		lat: -5.509600404,
		lng: 22.25997432,
		pop: 17008.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Kasaï-Occidental',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Ilebo',
		city_ascii: 'Ilebo',
		lat: -4.319595521,
		lng: 20.60999752,
		pop: 71125.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Kasaï-Occidental',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Moanda',
		city_ascii: 'Moanda',
		lat: -5.922908509,
		lng: 12.35499752,
		pop: 153915,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bas-Congo',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Kimpese',
		city_ascii: 'Kimpese',
		lat: -5.549597963,
		lng: 14.43332027,
		pop: 10578,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bas-Congo',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Kasangulu',
		city_ascii: 'Kasangulu',
		lat: -4.579579652,
		lng: 15.17999304,
		pop: 22645,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bas-Congo',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Mbanza-Ngungu',
		city_ascii: 'Mbanza-Ngungu',
		lat: -5.249616273,
		lng: 14.86001257,
		pop: 141950.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bas-Congo',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Tshela',
		city_ascii: 'Tshela',
		lat: -4.969581687,
		lng: 12.93000118,
		pop: 19896,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bas-Congo',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Mwenga',
		city_ascii: 'Mwenga',
		lat: -3.038226706,
		lng: 28.43251745,
		pop: 2216,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Sud-Kivu',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Kampene',
		city_ascii: 'Kampene',
		lat: -3.599536114,
		lng: 26.67004106,
		pop: 19719.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Maniema',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Kalima',
		city_ascii: 'Kalima',
		lat: -2.509576804,
		lng: 26.43000403,
		pop: 194,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Maniema',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Lubutu',
		city_ascii: 'Lubutu',
		lat: -0.732889385,
		lng: 26.583328,
		pop: 1313,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Maniema',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Kabinda',
		city_ascii: 'Kabinda',
		lat: -6.129614239,
		lng: 24.47999385,
		pop: 37366,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Kasaï-Oriental',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Lubao',
		city_ascii: 'Lubao',
		lat: -5.389556052,
		lng: 25.74999385,
		pop: 26142.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Kasaï-Oriental',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Lusambo',
		city_ascii: 'Lusambo',
		lat: -4.969581687,
		lng: 23.4300321,
		pop: 26803,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Kasaï-Oriental',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Gandajika',
		city_ascii: 'Gandajika',
		lat: -6.739602845,
		lng: 23.96002559,
		pop: 105769.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Kasaï-Oriental',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Lodja',
		city_ascii: 'Lodja',
		lat: -3.489620342,
		lng: 23.42000688,
		pop: 68244,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Kasaï-Oriental',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Dilolo',
		city_ascii: 'Dilolo',
		lat: -10.69961953,
		lng: 22.33330318,
		pop: 7854,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Nyunzu',
		city_ascii: 'Nyunzu',
		lat: -5.949573549,
		lng: 28.0166772,
		pop: 15397,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Kasaji',
		city_ascii: 'Kasaji',
		lat: -10.36620319,
		lng: 23.44997921,
		pop: 11969,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Luanza',
		city_ascii: 'Luanza',
		lat: -8.699586569,
		lng: 28.69999467,
		pop: 861,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Moba',
		city_ascii: 'Moba',
		lat: -7.059583314,
		lng: 29.71998409,
		pop: 10006,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Bukama',
		city_ascii: 'Bukama',
		lat: -9.20958128,
		lng: 25.8400142,
		pop: 20900.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Kaniama',
		city_ascii: 'Kaniama',
		lat: -7.569629701,
		lng: 24.17003861,
		pop: 32946.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Kipushi',
		city_ascii: 'Kipushi',
		lat: -11.75960651,
		lng: 27.25000565,
		pop: 87839.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Kambove',
		city_ascii: 'Kambove',
		lat: -10.86958331,
		lng: 26.60001949,
		pop: 18934.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Kongolo',
		city_ascii: 'Kongolo',
		lat: -5.379479147,
		lng: 26.9799963,
		pop: 68572.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Kabalo',
		city_ascii: 'Kabalo',
		lat: -6.049619121,
		lng: 26.91002641,
		pop: 21851,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Beni',
		city_ascii: 'Beni',
		lat: 0.490446797,
		lng: 29.45002641,
		pop: 211275.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Nord-Kivu',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Lisala',
		city_ascii: 'Lisala',
		lat: 2.140010192,
		lng: 21.50999426,
		pop: 64270,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Équateur',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Gemena',
		city_ascii: 'Gemena',
		lat: 3.260019347,
		lng: 19.76999711,
		pop: 157847.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Équateur',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Buta',
		city_ascii: 'Buta',
		lat: 2.819994526,
		lng: 24.74002966,
		pop: 44195,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Orientale',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Watsa',
		city_ascii: 'Watsa',
		lat: 3.040006937,
		lng: 29.52996985,
		pop: 17721.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Orientale',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Isiro',
		city_ascii: 'Isiro',
		lat: 2.75997235,
		lng: 27.62000891,
		pop: 142136,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Orientale',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Bondo',
		city_ascii: 'Bondo',
		lat: 3.809959939,
		lng: 23.67001745,
		pop: 20688.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Orientale',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Inongo',
		city_ascii: 'Inongo',
		lat: -1.939999167,
		lng: 18.28001054,
		pop: 30181,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bandundu',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Tshikapa',
		city_ascii: 'Tshikapa',
		lat: -6.409958884,
		lng: 20.77003943,
		pop: 201256.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Kasaï-Occidental',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Boma',
		city_ascii: 'Boma',
		lat: -5.829994284,
		lng: 13.04999385,
		pop: 178638,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bas-Congo',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Bukavu',
		city_ascii: 'Bukavu',
		lat: -2.509990215,
		lng: 28.8400378,
		pop: 331084,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Sud-Kivu',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Uvira',
		city_ascii: 'Uvira',
		lat: -3.369989401,
		lng: 29.14001949,
		pop: 164353,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Sud-Kivu',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Kindu',
		city_ascii: 'Kindu',
		lat: -2.963915996,
		lng: 25.90998409,
		pop: 199306,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Maniema',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Mwene-Ditu',
		city_ascii: 'Mwene-Ditu',
		lat: -7.000000388,
		lng: 23.44000565,
		pop: 153328.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Kasaï-Oriental',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Likasi',
		city_ascii: 'Likasi',
		lat: -10.9700423,
		lng: 26.7800085,
		pop: 428411,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Manono',
		city_ascii: 'Manono',
		lat: -7.300033754,
		lng: 27.44999345,
		pop: 46111,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Kamina',
		city_ascii: 'Kamina',
		lat: -8.730023988,
		lng: 25.00998734,
		pop: 101180,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Mbandaka',
		city_ascii: 'Mbandaka',
		lat: 0.040035013,
		lng: 18.26001176,
		pop: 229590.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Équateur',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Kisangani',
		city_ascii: 'Kisangani',
		lat: 0.520005716,
		lng: 25.22000036,
		pop: 558814,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Orientale',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Bandundu',
		city_ascii: 'Bandundu',
		lat: -3.309993063,
		lng: 17.37996212,
		pop: 107997.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bandundu',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Kananga',
		city_ascii: 'Kananga',
		lat: -5.890042299,
		lng: 22.40001745,
		pop: 614273,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Kasaï-Occidental',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Kasongo',
		city_ascii: 'Kasongo',
		lat: -4.450026836,
		lng: 26.66001583,
		pop: 59059,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Maniema',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Mbuji-Mayi',
		city_ascii: 'Mbuji-Mayi',
		lat: -6.150026429,
		lng: 23.59999589,
		pop: 1084880.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Kasaï-Oriental',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Kalemie',
		city_ascii: 'Kalemie',
		lat: -5.933295472,
		lng: 29.20001583,
		pop: 176615.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Butembo',
		city_ascii: 'Butembo',
		lat: 0.130003681,
		lng: 29.28001094,
		pop: 220512,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Nord-Kivu',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Goma',
		city_ascii: 'Goma',
		lat: -1.678799101,
		lng: 29.2217868,
		pop: 144124,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Nord-Kivu',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Bumba',
		city_ascii: 'Bumba',
		lat: 2.189981302,
		lng: 22.45996212,
		pop: 128029.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Équateur',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Kikwit',
		city_ascii: 'Kikwit',
		lat: -5.030043112,
		lng: 18.85000159,
		pop: 465973,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bandundu',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Matadi',
		city_ascii: 'Matadi',
		lat: -5.816610088,
		lng: 13.45002112,
		pop: 212985.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Bas-Congo',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Kolwezi',
		city_ascii: 'Kolwezi',
		lat: -10.71672443,
		lng: 25.47243974,
		pop: 418000,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Lubumbashi',
		city_ascii: 'Lubumbashi',
		lat: -11.6800248,
		lng: 27.48001745,
		pop: 1114317,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Katanga',
		timezone: 'Africa/Lubumbashi',
	},
	{
		city: 'Kinshasa',
		city_ascii: 'Kinshasa',
		lat: -4.329724102,
		lng: 15.31497188,
		pop: 6704351.5,
		country: 'Congo (Kinshasa)',
		iso2: 'CD',
		iso3: 'COD',
		province: 'Kinshasa City',
		timezone: 'Africa/Kinshasa',
	},
	{
		city: 'Rarotonga',
		city_ascii: 'Rarotonga',
		lat: -21.25003497,
		lng: -159.7500013,
		pop: 2525,
		country: 'Cook Islands',
		iso2: 'CK',
		iso3: 'COK',
		province: '',
		timezone: 'Pacific/Rarotonga',
	},
	{
		city: 'Heredia',
		city_ascii: 'Heredia',
		lat: 9.991997986,
		lng: -84.12000251,
		pop: 21947,
		country: 'Costa Rica',
		iso2: 'CR',
		iso3: 'CRI',
		province: 'Heredia',
		timezone: 'America/Costa_Rica',
	},
	{
		city: 'Cartago',
		city_ascii: 'Cartago',
		lat: 9.86997764,
		lng: -83.92997807,
		pop: 111770,
		country: 'Costa Rica',
		iso2: 'CR',
		iso3: 'CRI',
		province: 'Cartago',
		timezone: 'America/Costa_Rica',
	},
	{
		city: 'Golfito',
		city_ascii: 'Golfito',
		lat: 8.650026264,
		lng: -83.149974,
		pop: 6777,
		country: 'Costa Rica',
		iso2: 'CR',
		iso3: 'CRI',
		province: 'Puntarenas',
		timezone: 'America/Costa_Rica',
	},
	{
		city: 'Alajuela',
		city_ascii: 'Alajuela',
		lat: 10.02002016,
		lng: -84.23003727,
		pop: 217618.5,
		country: 'Costa Rica',
		iso2: 'CR',
		iso3: 'CRI',
		province: 'Alajuela',
		timezone: 'America/Costa_Rica',
	},
	{
		city: 'Canas',
		city_ascii: 'Canas',
		lat: 10.42999514,
		lng: -85.10001001,
		pop: 15365,
		country: 'Costa Rica',
		iso2: 'CR',
		iso3: 'CRI',
		province: 'Guanacaste',
		timezone: 'America/Costa_Rica',
	},
	{
		city: 'Sixaola',
		city_ascii: 'Sixaola',
		lat: 9.520386575,
		lng: -82.61998051,
		pop: 1150,
		country: 'Costa Rica',
		iso2: 'CR',
		iso3: 'CRI',
		province: 'Limón',
		timezone: 'America/Costa_Rica',
	},
	{
		city: 'Puntarenas',
		city_ascii: 'Puntarenas',
		lat: 9.970164098,
		lng: -84.83358954,
		pop: 46376,
		country: 'Costa Rica',
		iso2: 'CR',
		iso3: 'CRI',
		province: 'Puntarenas',
		timezone: 'America/Costa_Rica',
	},
	{
		city: 'Ciudad Cortes',
		city_ascii: 'Ciudad Cortes',
		lat: 8.960369079,
		lng: -83.53000248,
		pop: 3515.5,
		country: 'Costa Rica',
		iso2: 'CR',
		iso3: 'CRI',
		province: 'Puntarenas',
		timezone: 'America/Costa_Rica',
	},
	{
		city: 'Quesada',
		city_ascii: 'Quesada',
		lat: 10.33049217,
		lng: -84.43997278,
		pop: 29208,
		country: 'Costa Rica',
		iso2: 'CR',
		iso3: 'CRI',
		province: 'Alajuela',
		timezone: 'America/Costa_Rica',
	},
	{
		city: 'Liberia',
		city_ascii: 'Liberia',
		lat: 10.63375531,
		lng: -85.433323,
		pop: 41538,
		country: 'Costa Rica',
		iso2: 'CR',
		iso3: 'CRI',
		province: 'Guanacaste',
		timezone: 'America/Costa_Rica',
	},
	{
		city: 'La Cruz',
		city_ascii: 'La Cruz',
		lat: 11.07042116,
		lng: -85.6300035,
		pop: 4297.5,
		country: 'Costa Rica',
		iso2: 'CR',
		iso3: 'CRI',
		province: 'Guanacaste',
		timezone: 'America/Costa_Rica',
	},
	{
		city: 'Puerto Limon',
		city_ascii: 'Puerto Limon',
		lat: 10.00002138,
		lng: -83.03334029,
		pop: 74041,
		country: 'Costa Rica',
		iso2: 'CR',
		iso3: 'CRI',
		province: 'Limón',
		timezone: 'America/Costa_Rica',
	},
	{
		city: 'San Jose',
		city_ascii: 'San Jose',
		lat: 9.93501243,
		lng: -84.08405135,
		pop: 642862,
		country: 'Costa Rica',
		iso2: 'CR',
		iso3: 'CRI',
		province: 'San José',
		timezone: 'America/Costa_Rica',
	},
	{
		city: 'Sibenik',
		city_ascii: 'Sibenik',
		lat: 43.7272222,
		lng: 15.9058333,
		pop: 37112,
		country: 'Croatia',
		iso2: 'HR',
		iso3: 'HRV',
		province: 'Šibensko-Kninska',
		timezone: 'Europe/Zagreb',
	},
	{
		city: 'Karlovac',
		city_ascii: 'Karlovac',
		lat: 45.48720929,
		lng: 15.54777421,
		pop: 51593,
		country: 'Croatia',
		iso2: 'HR',
		iso3: 'HRV',
		province: 'Karlovacka',
		timezone: 'Europe/Zagreb',
	},
	{
		city: 'Rijeka',
		city_ascii: 'Rijeka',
		lat: 45.32998374,
		lng: 14.45001176,
		pop: 156082,
		country: 'Croatia',
		iso2: 'HR',
		iso3: 'HRV',
		province: 'Primorsko-Goranska',
		timezone: 'Europe/Zagreb',
	},
	{
		city: 'Slavonski Brod',
		city_ascii: 'Slavonski Brod',
		lat: 45.16027834,
		lng: 18.01558223,
		pop: 79230,
		country: 'Croatia',
		iso2: 'HR',
		iso3: 'HRV',
		province: 'Brodsko-Posavska',
		timezone: 'Europe/Zagreb',
	},
	{
		city: 'Dubrovnik',
		city_ascii: 'Dubrovnik',
		lat: 42.66094769,
		lng: 18.09139156,
		pop: 32711,
		country: 'Croatia',
		iso2: 'HR',
		iso3: 'HRV',
		province: 'Dubrovacko-Neretvanska',
		timezone: 'Europe/Zagreb',
	},
	{
		city: 'Split',
		city_ascii: 'Split',
		lat: 43.52040428,
		lng: 16.46999182,
		pop: 195527.5,
		country: 'Croatia',
		iso2: 'HR',
		iso3: 'HRV',
		province: 'Splitsko-Dalmatinska',
		timezone: 'Europe/Zagreb',
	},
	{
		city: 'Zadar',
		city_ascii: 'Zadar',
		lat: 44.12013511,
		lng: 15.26226192,
		pop: 59201.5,
		country: 'Croatia',
		iso2: 'HR',
		iso3: 'HRV',
		province: 'Zadarska',
		timezone: 'Europe/Zagreb',
	},
	{
		city: 'Pula',
		city_ascii: 'Pula',
		lat: 44.86871991,
		lng: 13.84808467,
		pop: 60338.5,
		country: 'Croatia',
		iso2: 'HR',
		iso3: 'HRV',
		province: 'Istarska',
		timezone: 'Europe/Zagreb',
	},
	{
		city: 'Osijek',
		city_ascii: 'Osijek',
		lat: 45.55038373,
		lng: 18.6800378,
		pop: 91608.5,
		country: 'Croatia',
		iso2: 'HR',
		iso3: 'HRV',
		province: 'Osjecko-Baranjska',
		timezone: 'Europe/Zagreb',
	},
	{
		city: 'Zagreb',
		city_ascii: 'Zagreb',
		lat: 45.80000673,
		lng: 15.99999467,
		pop: 710746,
		country: 'Croatia',
		iso2: 'HR',
		iso3: 'HRV',
		province: 'Grad Zagreb',
		timezone: 'Europe/Zagreb',
	},
	{
		city: 'Ciego de Avila',
		city_ascii: 'Ciego de Avila',
		lat: 21.83999636,
		lng: -78.76194727,
		pop: 122343.5,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Ciego de Ávila',
		timezone: 'America/Havana',
	},
	{
		city: 'Palma Soriano',
		city_ascii: 'Palma Soriano',
		lat: 20.21722719,
		lng: -75.99880843,
		pop: 85110,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Santiago de Cuba',
		timezone: 'America/Havana',
	},
	{
		city: 'San Antonio de los Banos',
		city_ascii: 'San Antonio de los Banos',
		lat: 22.89112083,
		lng: -82.4990835,
		pop: 37676,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'La Habana',
		timezone: 'America/Havana',
	},
	{
		city: 'Guines',
		city_ascii: 'Guines',
		lat: 22.8361371,
		lng: -82.02802698,
		pop: 57445,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'La Habana',
		timezone: 'America/Havana',
	},
	{
		city: 'Caibarien',
		city_ascii: 'Caibarien',
		lat: 22.5157949,
		lng: -79.47223983,
		pop: 25431,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Villa Clara',
		timezone: 'America/Havana',
	},
	{
		city: 'Placetas',
		city_ascii: 'Placetas',
		lat: 22.31580711,
		lng: -79.65548446,
		pop: 34973,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Villa Clara',
		timezone: 'America/Havana',
	},
	{
		city: 'Cienfuegos',
		city_ascii: 'Cienfuegos',
		lat: 22.14444806,
		lng: -80.44029444,
		pop: 146549.5,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Cienfuegos',
		timezone: 'America/Havana',
	},
	{
		city: 'Nueva Gerona',
		city_ascii: 'Nueva Gerona',
		lat: 21.88371462,
		lng: -82.79999536,
		pop: 22915,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Isla de la Juventud',
		timezone: 'America/Havana',
	},
	{
		city: 'Sancti Spiritus',
		city_ascii: 'Sancti Spiritus',
		lat: 21.93014589,
		lng: -79.44250004,
		pop: 101710.5,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Sancti Spíritus',
		timezone: 'America/Havana',
	},
	{
		city: 'Moron',
		city_ascii: 'Moron',
		lat: 22.10985069,
		lng: -78.62748519,
		pop: 57551.5,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Ciego de Ávila',
		timezone: 'America/Havana',
	},
	{
		city: 'Nuevitas',
		city_ascii: 'Nuevitas',
		lat: 21.5456474,
		lng: -77.26444177,
		pop: 46796,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Camagüey',
		timezone: 'America/Havana',
	},
	{
		city: 'Manzanillo',
		city_ascii: 'Manzanillo',
		lat: 20.34375694,
		lng: -77.11664718,
		pop: 107433,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Granma',
		timezone: 'America/Havana',
	},
	{
		city: 'Bayamo',
		city_ascii: 'Bayamo',
		lat: 20.37954287,
		lng: -76.64329106,
		pop: 177623,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Granma',
		timezone: 'America/Havana',
	},
	{
		city: 'Banes',
		city_ascii: 'Banes',
		lat: 20.96291811,
		lng: -75.71859298,
		pop: 47745.5,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Holguín',
		timezone: 'America/Havana',
	},
	{
		city: 'Las Tunas',
		city_ascii: 'Las Tunas',
		lat: 20.96012758,
		lng: -76.95438318,
		pop: 179898,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Las Tunas',
		timezone: 'America/Havana',
	},
	{
		city: 'Artemisa',
		city_ascii: 'Artemisa',
		lat: 22.81339947,
		lng: -82.76188399,
		pop: 46024.5,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'La Habana',
		timezone: 'America/Havana',
	},
	{
		city: 'Matanzas',
		city_ascii: 'Matanzas',
		lat: 23.04152508,
		lng: -81.577486,
		pop: 135303,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Matanzas',
		timezone: 'America/Havana',
	},
	{
		city: 'Colon',
		city_ascii: 'Colon',
		lat: 22.71958091,
		lng: -80.90579574,
		pop: 40677,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Matanzas',
		timezone: 'America/Havana',
	},
	{
		city: 'Sagua la Grande',
		city_ascii: 'Sagua la Grande',
		lat: 22.80903282,
		lng: -80.07109216,
		pop: 40752.5,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Villa Clara',
		timezone: 'America/Havana',
	},
	{
		city: 'Pinar del Rio',
		city_ascii: 'Pinar del Rio',
		lat: 22.41750633,
		lng: -83.69808008,
		pop: 173304,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Pinar del Río',
		timezone: 'America/Havana',
	},
	{
		city: 'Camaguey',
		city_ascii: 'Camaguey',
		lat: 21.38082542,
		lng: -77.91693425,
		pop: 321583.5,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Camagüey',
		timezone: 'America/Havana',
	},
	{
		city: 'Guantanamo',
		city_ascii: 'Guantanamo',
		lat: 20.1452936,
		lng: -75.20614364,
		pop: 245069.5,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Guantánamo',
		timezone: 'America/Havana',
	},
	{
		city: 'Holguin',
		city_ascii: 'Holguin',
		lat: 20.88723798,
		lng: -76.26305587,
		pop: 309639.5,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Holguín',
		timezone: 'America/Havana',
	},
	{
		city: 'Santa Clara',
		city_ascii: 'Santa Clara',
		lat: 22.40001385,
		lng: -79.9666541,
		pop: 234900,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Villa Clara',
		timezone: 'America/Havana',
	},
	{
		city: 'Santiago de Cuba',
		city_ascii: 'Santiago de Cuba',
		lat: 20.0250167,
		lng: -75.82132573,
		pop: 500964,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Santiago de Cuba',
		timezone: 'America/Havana',
	},
	{
		city: 'Havana',
		city_ascii: 'Havana',
		lat: 23.13195884,
		lng: -82.36418217,
		pop: 2082458.5,
		country: 'Cuba',
		iso2: 'CU',
		iso3: 'CUB',
		province: 'Ciudad de la Habana',
		timezone: 'America/Havana',
	},
	{
		city: 'Willemstad',
		city_ascii: 'Willemstad',
		lat: 12.20042971,
		lng: -69.01998377,
		pop: 146813,
		country: 'Curacao',
		iso2: 'CW',
		iso3: 'CUW',
		province: '',
		timezone: 'America/Curacao',
	},
	{
		city: 'Larnaka',
		city_ascii: 'Larnaka',
		lat: 34.9170031,
		lng: 33.63599757,
		pop: 48947,
		country: 'Cyprus',
		iso2: 'CY',
		iso3: 'CYP',
		province: 'Larnaca',
		timezone: 'Asia/Nicosia',
	},
	{
		city: 'Paphos',
		city_ascii: 'Paphos',
		lat: 34.75591516,
		lng: 32.42245666,
		pop: 35961,
		country: 'Cyprus',
		iso2: 'CY',
		iso3: 'CYP',
		province: 'Paphos',
		timezone: 'Asia/Nicosia',
	},
	{
		city: 'Lemosos',
		city_ascii: 'Lemosos',
		lat: 34.67541445,
		lng: 33.0333219,
		pop: 149486,
		country: 'Cyprus',
		iso2: 'CY',
		iso3: 'CYP',
		province: 'Limassol',
		timezone: 'Asia/Nicosia',
	},
	{
		city: 'Nicosia',
		city_ascii: 'Nicosia',
		lat: 35.16667645,
		lng: 33.36663489,
		pop: 212376,
		country: 'Cyprus',
		iso2: 'CY',
		iso3: 'CYP',
		province: '',
		timezone: 'Asia/Nicosia',
	},
	{
		city: 'Usti Nad Labem',
		city_ascii: 'Usti Nad Labem',
		lat: 50.66299816,
		lng: 14.08100455,
		pop: 94105,
		country: 'Czech Republic',
		iso2: 'CZ',
		iso3: 'CZE',
		province: 'Liberecký',
		timezone: 'Europe/Prague',
	},
	{
		city: 'Hradec Kralove',
		city_ascii: 'Hradec Kralove',
		lat: 50.20599617,
		lng: 15.81200153,
		pop: 95195,
		country: 'Czech Republic',
		iso2: 'CZ',
		iso3: 'CZE',
		province: 'Královéhradecký',
		timezone: 'Europe/Prague',
	},
	{
		city: 'Ceske Budejovice',
		city_ascii: 'Ceske Budejovice',
		lat: 48.98001935,
		lng: 14.46003699,
		pop: 97452,
		country: 'Czech Republic',
		iso2: 'CZ',
		iso3: 'CZE',
		province: 'Jihoceský',
		timezone: 'Europe/Prague',
	},
	{
		city: 'Liberec',
		city_ascii: 'Liberec',
		lat: 50.79995994,
		lng: 15.07999914,
		pop: 99972.5,
		country: 'Czech Republic',
		iso2: 'CZ',
		iso3: 'CZE',
		province: 'Liberecký',
		timezone: 'Europe/Prague',
	},
	{
		city: 'Olomouc',
		city_ascii: 'Olomouc',
		lat: 49.63003135,
		lng: 17.24999589,
		pop: 97829,
		country: 'Czech Republic',
		iso2: 'CZ',
		iso3: 'CZE',
		province: 'Moravskoslezský',
		timezone: 'Europe/Prague',
	},
	{
		city: 'Pizen',
		city_ascii: 'Pizen',
		lat: 49.74043805,
		lng: 13.36000077,
		pop: 161043,
		country: 'Czech Republic',
		iso2: 'CZ',
		iso3: 'CZE',
		province: 'Karlovarský',
		timezone: 'Europe/Prague',
	},
	{
		city: 'Jihlava',
		city_ascii: 'Jihlava',
		lat: 49.40038129,
		lng: 15.58332759,
		pop: 52010.5,
		country: 'Czech Republic',
		iso2: 'CZ',
		iso3: 'CZE',
		province: 'Kraj Vysocina',
		timezone: 'Europe/Prague',
	},
	{
		city: 'Zlin',
		city_ascii: 'Zlin',
		lat: 49.2304175,
		lng: 17.65002315,
		pop: 101893.5,
		country: 'Czech Republic',
		iso2: 'CZ',
		iso3: 'CZE',
		province: 'Kraj Vysocina',
		timezone: 'Europe/Prague',
	},
	{
		city: 'Brno',
		city_ascii: 'Brno',
		lat: 49.20039349,
		lng: 16.60998328,
		pop: 378918,
		country: 'Czech Republic',
		iso2: 'CZ',
		iso3: 'CZE',
		province: 'Kraj Vysocina',
		timezone: 'Europe/Prague',
	},
	{
		city: 'Pardubice',
		city_ascii: 'Pardubice',
		lat: 50.04041974,
		lng: 15.76000932,
		pop: 97902.5,
		country: 'Czech Republic',
		iso2: 'CZ',
		iso3: 'CZE',
		province: 'Královéhradecký',
		timezone: 'Europe/Prague',
	},
	{
		city: 'Ostrava',
		city_ascii: 'Ostrava',
		lat: 49.83035504,
		lng: 18.24998653,
		pop: 396025.5,
		country: 'Czech Republic',
		iso2: 'CZ',
		iso3: 'CZE',
		province: 'Moravskoslezský',
		timezone: 'Europe/Prague',
	},
	{
		city: 'Prague',
		city_ascii: 'Prague',
		lat: 50.08333701,
		lng: 14.46597978,
		pop: 582043.5,
		country: 'Czech Republic',
		iso2: 'CZ',
		iso3: 'CZE',
		province: 'Prague',
		timezone: 'Europe/Prague',
	},
	{
		city: 'Vejle',
		city_ascii: 'Vejle',
		lat: 55.70900103,
		lng: 9.534996498,
		pop: 51177,
		country: 'Denmark',
		iso2: 'DK',
		iso3: 'DNK',
		province: 'Syddanmark',
		timezone: 'Europe/Copenhagen',
	},
	{
		city: 'Hillerod',
		city_ascii: 'Hillerod',
		lat: 55.93329907,
		lng: 12.31669854,
		pop: 28313,
		country: 'Denmark',
		iso2: 'DK',
		iso3: 'DNK',
		province: 'Hovedstaden',
		timezone: 'Europe/Copenhagen',
	},
	{
		city: 'Soro',
		city_ascii: 'Soro',
		lat: 55.43299811,
		lng: 11.5667016,
		pop: 7167,
		country: 'Denmark',
		iso2: 'DK',
		iso3: 'DNK',
		province: 'Sjaælland',
		timezone: 'Europe/Copenhagen',
	},
	{
		city: 'Viborg',
		city_ascii: 'Viborg',
		lat: 56.43333701,
		lng: 9.399984089,
		pop: 32944,
		country: 'Denmark',
		iso2: 'DK',
		iso3: 'DNK',
		province: 'Midtjylland',
		timezone: 'Europe/Copenhagen',
	},
	{
		city: 'Roskilde',
		city_ascii: 'Roskilde',
		lat: 55.64997398,
		lng: 12.08333451,
		pop: 42284.5,
		country: 'Denmark',
		iso2: 'DK',
		iso3: 'DNK',
		province: 'Sjaælland',
		timezone: 'Europe/Copenhagen',
	},
	{
		city: 'Svendborg',
		city_ascii: 'Svendborg',
		lat: 55.07042279,
		lng: 10.61665401,
		pop: 28366.5,
		country: 'Denmark',
		iso2: 'DK',
		iso3: 'DNK',
		province: 'Syddanmark',
		timezone: 'Europe/Copenhagen',
	},
	{
		city: 'Odense',
		city_ascii: 'Odense',
		lat: 55.40037681,
		lng: 10.38333492,
		pop: 152076.5,
		country: 'Denmark',
		iso2: 'DK',
		iso3: 'DNK',
		province: 'Syddanmark',
		timezone: 'Europe/Copenhagen',
	},
	{
		city: 'Esbjerg',
		city_ascii: 'Esbjerg',
		lat: 55.46703941,
		lng: 8.450016234,
		pop: 68076,
		country: 'Denmark',
		iso2: 'DK',
		iso3: 'DNK',
		province: 'Syddanmark',
		timezone: 'Europe/Copenhagen',
	},
	{
		city: 'Frederikshavn',
		city_ascii: 'Frederikshavn',
		lat: 57.43368939,
		lng: 10.53329993,
		pop: 22385,
		country: 'Denmark',
		iso2: 'DK',
		iso3: 'DNK',
		province: 'Nordjylland',
		timezone: 'Europe/Copenhagen',
	},
	{
		city: 'Aalborg',
		city_ascii: 'Aalborg',
		lat: 57.03371381,
		lng: 9.916593382,
		pop: 111917.5,
		country: 'Denmark',
		iso2: 'DK',
		iso3: 'DNK',
		province: 'Nordjylland',
		timezone: 'Europe/Copenhagen',
	},
	{
		city: 'Århus',
		city_ascii: 'Aarhus',
		lat: 56.157204,
		lng: 10.21068396,
		pop: 232325.5,
		country: 'Denmark',
		iso2: 'DK',
		iso3: 'DNK',
		province: 'Midtjylland',
		timezone: 'Europe/Copenhagen',
	},
	{
		city: 'København',
		city_ascii: 'Kobenhavn',
		lat: 55.67856419,
		lng: 12.56348575,
		pop: 1085000,
		country: 'Denmark',
		iso2: 'DK',
		iso3: 'DNK',
		province: 'Hovedstaden',
		timezone: 'Europe/Copenhagen',
	},
	{
		city: 'Dikhil',
		city_ascii: 'Dikhil',
		lat: 11.10400201,
		lng: 42.37200058,
		pop: 12043,
		country: 'Djibouti',
		iso2: 'DJ',
		iso3: 'DJI',
		province: 'Dikhil',
		timezone: 'Africa/Djibouti',
	},
	{
		city: 'Tadjoura',
		city_ascii: 'Tadjoura',
		lat: 11.78330399,
		lng: 42.9000035,
		pop: 22193,
		country: 'Djibouti',
		iso2: 'DJ',
		iso3: 'DJI',
		province: 'Tadjourah',
		timezone: 'Africa/Djibouti',
	},
	{
		city: 'Ali Sabih',
		city_ascii: 'Ali Sabih',
		lat: 11.15622988,
		lng: 42.71252437,
		pop: 32165.5,
		country: 'Djibouti',
		iso2: 'DJ',
		iso3: 'DJI',
		province: 'Ali Sabieh',
		timezone: 'Africa/Djibouti',
	},
	{
		city: 'Obock',
		city_ascii: 'Obock',
		lat: 11.97344098,
		lng: 43.28556433,
		pop: 13142,
		country: 'Djibouti',
		iso2: 'DJ',
		iso3: 'DJI',
		province: 'Obock',
		timezone: 'Africa/Djibouti',
	},
	{
		city: 'Djibouti',
		city_ascii: 'Djibouti',
		lat: 11.59501446,
		lng: 43.14800167,
		pop: 763506.5,
		country: 'Djibouti',
		iso2: 'DJ',
		iso3: 'DJI',
		province: 'Djibouti',
		timezone: 'Africa/Djibouti',
	},
	{
		city: 'Roseau',
		city_ascii: 'Roseau',
		lat: 15.30101564,
		lng: -61.38701298,
		pop: 19953.5,
		country: 'Dominica',
		iso2: 'DM',
		iso3: 'DMA',
		province: 'Saint George',
		timezone: 'America/Dominica',
	},
	{
		city: 'Sabaneta',
		city_ascii: 'Sabaneta',
		lat: 19.50499807,
		lng: -71.34498854,
		pop: 16380,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Santiago Rodríguez',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Mao',
		city_ascii: 'Mao',
		lat: 19.55199609,
		lng: -71.07499748,
		pop: 48297,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Valverde',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Cotui',
		city_ascii: 'Cotui',
		lat: 19.05900306,
		lng: -70.1520026,
		pop: 41641,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Sánchez Ramírez',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Puerto Plata',
		city_ascii: 'Puerto Plata',
		lat: 19.7902171,
		lng: -70.69024747,
		pop: 119897,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Puerto Plata',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Dajabon',
		city_ascii: 'Dajabon',
		lat: 19.548,
		lng: -71.70499757,
		pop: 16398,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Dajabón',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Moca',
		city_ascii: 'Moca',
		lat: 19.39699814,
		lng: -70.52300059,
		pop: 61834,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Espaillat',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Salcedo',
		city_ascii: 'Salcedo',
		lat: 19.38300302,
		lng: -70.4167015,
		pop: 45299,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Hermanas',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Jimani',
		city_ascii: 'Jimani',
		lat: 18.49300212,
		lng: -71.85099553,
		pop: 6567,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Independencia',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Comendador',
		city_ascii: 'Elias Pina',
		lat: 18.875997,
		lng: -71.70699546,
		pop: 35901,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Elias Pina',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Pedernales',
		city_ascii: 'Pedernales',
		lat: 18.03799801,
		lng: -71.74099755,
		pop: 11072,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Pedernales',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Azua',
		city_ascii: 'Azua',
		lat: 18.45399613,
		lng: -70.7290016,
		pop: 59139,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Azua',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Bonao',
		city_ascii: 'Bonao',
		lat: 18.94200314,
		lng: -70.40899757,
		pop: 73269,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Monseñor Nouel',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Bani',
		city_ascii: 'Bani',
		lat: 18.279999,
		lng: -70.33100347,
		pop: 66709,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Peravia',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Hato Mayor',
		city_ascii: 'Hato Mayor',
		lat: 18.76400114,
		lng: -69.25699747,
		pop: 35999,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Hato Mayor',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Monte Plata',
		city_ascii: 'Monte Plata',
		lat: 18.80700306,
		lng: -69.78400153,
		pop: 15532,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Monte Plata',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Nagua',
		city_ascii: 'Nagua',
		lat: 19.37600108,
		lng: -69.84700149,
		pop: 33862,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'María Trinidad Sánchez',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Samana',
		city_ascii: 'Samana',
		lat: 19.21200313,
		lng: -69.3320036,
		pop: 11432,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Samaná',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'San Cristobal',
		city_ascii: 'San Cristobal',
		lat: 18.4159981,
		lng: -70.10900052,
		pop: 154040,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'San Cristóbal',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'El Seibo',
		city_ascii: 'El Seibo',
		lat: 18.76400114,
		lng: -69.03500346,
		pop: 23547,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'El Seybo',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Higuey',
		city_ascii: 'Higuey',
		lat: 18.61599603,
		lng: -68.70799749,
		pop: 123787,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'La Altagracia',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Neiba',
		city_ascii: 'Neiba',
		lat: 18.46661053,
		lng: -71.41663334,
		pop: 22200.5,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Bahoruco',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'La Vega',
		city_ascii: 'La Vega',
		lat: 19.2165906,
		lng: -70.51658492,
		pop: 132811.5,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'La Vega',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'San Francisco de Macoris',
		city_ascii: 'San Francisco de Macoris',
		lat: 19.29999636,
		lng: -70.25001205,
		pop: 138650.5,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Duarte',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'San Pedro de Macoris',
		city_ascii: 'San Pedro de Macoris',
		lat: 18.4503583,
		lng: -69.29996668,
		pop: 211019.5,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'San Pedro de Macorís',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Monte Cristi',
		city_ascii: 'Monte Cristi',
		lat: 19.86699017,
		lng: -71.65002995,
		pop: 16852.5,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Monte Cristi',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Barahona',
		city_ascii: 'Barahona',
		lat: 18.20037355,
		lng: -71.099986,
		pop: 83644,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Barahona',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Bavaro',
		city_ascii: 'Bavaro',
		lat: 18.71700869,
		lng: -68.44996688,
		pop: 795,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'La Altagracia',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'La Romana',
		city_ascii: 'La Romana',
		lat: 18.41700116,
		lng: -68.96660201,
		pop: 202471.5,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'La Romana',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'San Juan',
		city_ascii: 'San Juan',
		lat: 18.80700306,
		lng: -71.22899657,
		pop: 72950,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'San Juan',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Santiago',
		city_ascii: 'Santiago',
		lat: 19.50000999,
		lng: -70.67001225,
		pop: 1471007.5,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Santiago',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Santo Domingo',
		city_ascii: 'Santo Domingo',
		lat: 18.47007285,
		lng: -69.90008508,
		pop: 1078436.5,
		country: 'Dominican Republic',
		iso2: 'DO',
		iso3: 'DOM',
		province: 'Distrito Nacional',
		timezone: 'America/Santo_Domingo',
	},
	{
		city: 'Dili',
		city_ascii: 'Dili',
		lat: -8.559388409,
		lng: 125.5794559,
		pop: 213947,
		country: 'East Timor',
		iso2: 'TL',
		iso3: 'TLS',
		province: 'Dili',
		timezone: 'Asia/Dili',
	},
	{
		city: 'Puyo',
		city_ascii: 'Puyo',
		lat: -1.483002014,
		lng: -77.98699756,
		pop: 24881,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Pastaza',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Tulcan',
		city_ascii: 'Tulcan',
		lat: 0.821997038,
		lng: -77.73200048,
		pop: 83000,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Carchi',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Pinas',
		city_ascii: 'Pinas',
		lat: -3.670022767,
		lng: -79.64998092,
		pop: 16981,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'El Oro',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Puerto Villamil',
		city_ascii: 'Puerto Villamil',
		lat: -0.933342266,
		lng: -91.01665145,
		pop: 2000,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Galápagos',
		timezone: 'Pacific/Galapagos',
	},
	{
		city: 'Puerto Baquerizo Moreno',
		city_ascii: 'Puerto Baquerizo Moreno',
		lat: -0.900010967,
		lng: -89.5999679,
		pop: 5122,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Galápagos',
		timezone: 'Pacific/Galapagos',
	},
	{
		city: 'Guaranda',
		city_ascii: 'Guaranda',
		lat: -1.60999347,
		lng: -79.01001998,
		pop: 23933,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Bolivar',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Azogues',
		city_ascii: 'Azogues',
		lat: -2.740002015,
		lng: -78.84003036,
		pop: 51982,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Cañar',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Salinas',
		city_ascii: 'Salinas',
		lat: -2.200034974,
		lng: -80.98000309,
		pop: 24616.5,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Guayas',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Alausi',
		city_ascii: 'Alausi',
		lat: -2.18995807,
		lng: -78.84997807,
		pop: 14294,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Chimborazo',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Sangolqui',
		city_ascii: 'Sangolqui',
		lat: -0.31002114,
		lng: -78.4599502,
		pop: 91848,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Pichincha',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Muisne',
		city_ascii: 'Muisne',
		lat: 0.609974384,
		lng: -80.02001001,
		pop: 8252.5,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Esmeraldas',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'San Gabriel',
		city_ascii: 'San Gabriel',
		lat: 0.609974384,
		lng: -77.84003972,
		pop: 15589,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Carchi',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Macara',
		city_ascii: 'Macara',
		lat: -4.379591859,
		lng: -79.94998845,
		pop: 11748,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Loja',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Zamora',
		city_ascii: 'Zamora',
		lat: -4.069584942,
		lng: -78.96999658,
		pop: 11581.5,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Zamora Chinchipe',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Latacunga',
		city_ascii: 'Latacunga',
		lat: -0.929569886,
		lng: -78.60996688,
		pop: 73344.5,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Cotopaxi',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Milagro',
		city_ascii: 'Milagro',
		lat: -2.179622784,
		lng: -79.59998397,
		pop: 55433.5,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Guayas',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Babahoyo',
		city_ascii: 'Babahoyo',
		lat: -1.7995943,
		lng: -79.54001347,
		pop: 59873,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Los Rios',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Chone',
		city_ascii: 'Chone',
		lat: -0.689584535,
		lng: -80.09000574,
		pop: 40379,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Manabi',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Jipijapa',
		city_ascii: 'Jipijapa',
		lat: -1.349595928,
		lng: -80.58000167,
		pop: 28741,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Manabi',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Yaupi',
		city_ascii: 'Yaupi',
		lat: -2.854310284,
		lng: -77.93633875,
		pop: 293,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Morona Santiago',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Macas',
		city_ascii: 'Macas',
		lat: -2.309589011,
		lng: -78.11999679,
		pop: 20644,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Morona Santiago',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Cayambe',
		city_ascii: 'Cayambe',
		lat: 0.050370299,
		lng: -78.15999435,
		pop: 27231.5,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Pichincha',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Ambato',
		city_ascii: 'Ambato',
		lat: -1.269600811,
		lng: -78.61999211,
		pop: 217897,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Napo',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Tena',
		city_ascii: 'Tena',
		lat: -0.979592673,
		lng: -77.80998987,
		pop: 24149,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Tungurahua',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Valdez',
		city_ascii: 'Valdez',
		lat: 1.267103808,
		lng: -78.98549846,
		pop: 6985.5,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Esmeraldas',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'San Lorenzo',
		city_ascii: 'San Lorenzo',
		lat: 1.270399189,
		lng: -78.86005497,
		pop: 20209,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Esmeraldas',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Esmeraldas',
		city_ascii: 'Esmeraldas',
		lat: 0.930419941,
		lng: -79.6699797,
		pop: 134365.5,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Esmeraldas',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Ibarra',
		city_ascii: 'Ibarra',
		lat: 0.360377216,
		lng: -78.12999618,
		pop: 127703.5,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Imbabura',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Portoviejo',
		city_ascii: 'Portoviejo',
		lat: -1.060001201,
		lng: -80.45998316,
		pop: 191963.5,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Manabi',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Machala',
		city_ascii: 'Machala',
		lat: -3.260021953,
		lng: -79.95998784,
		pop: 205578.5,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'El Oro',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Loja',
		city_ascii: 'Loja',
		lat: -3.990003236,
		lng: -79.21000777,
		pop: 122082,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Loja',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Manta',
		city_ascii: 'Manta',
		lat: -0.980006084,
		lng: -80.72996668,
		pop: 176941,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Manabi',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Riobamba',
		city_ascii: 'Riobamba',
		lat: -1.670041485,
		lng: -78.65004195,
		pop: 148471,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Chimborazo',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Cuenca',
		city_ascii: 'Cuenca',
		lat: -2.89999225,
		lng: -78.99999475,
		pop: 281921,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Azuay',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Santa Cruz',
		city_ascii: 'Santa Cruz',
		lat: -0.533315004,
		lng: -90.34999964,
		pop: 8147.5,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Galápagos',
		timezone: 'Pacific/Galapagos',
	},
	{
		city: 'Quito',
		city_ascii: 'Quito',
		lat: -0.214988181,
		lng: -78.50005111,
		pop: 1550407,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Pichincha',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Guayaquil',
		city_ascii: 'Guayaquil',
		lat: -2.220033754,
		lng: -79.92004195,
		pop: 2233014.5,
		country: 'Ecuador',
		iso2: 'EC',
		iso3: 'ECU',
		province: 'Guayas',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Shibin el Kom',
		city_ascii: 'Shibin el Kom',
		lat: 30.59199913,
		lng: 30.89999749,
		pop: 182900,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Minufiyah',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Benha',
		city_ascii: 'Benha',
		lat: 30.46666512,
		lng: 31.18333563,
		pop: 167029,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Qalyubiyah',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Zagazig',
		city_ascii: 'Zagazig',
		lat: 30.58333209,
		lng: 31.5166596,
		pop: 285097,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Ash Sharqiyah',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Kafr el Sheikh',
		city_ascii: 'Kafr el Sheikh',
		lat: 31.109004,
		lng: 30.93599763,
		pop: 143970,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Kafr ash Shaykh',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Tanta',
		city_ascii: 'Tanta',
		lat: 30.79043194,
		lng: 31.00000932,
		pop: 404901,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Gharbiyah',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Ismailia',
		city_ascii: 'Ismailia',
		lat: 30.5903408,
		lng: 32.25998409,
		pop: 470474,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Isma`iliyah',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'El Mansura',
		city_ascii: 'El Mansura',
		lat: 31.05044191,
		lng: 31.3800378,
		pop: 540247,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Ad Daqahliyah',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Dumyat',
		city_ascii: 'Dumyat',
		lat: 31.42039349,
		lng: 31.82001094,
		pop: 188149,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Dumyat',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Matruh',
		city_ascii: 'Matruh',
		lat: 31.3504236,
		lng: 27.23000688,
		pop: 82756,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Matruh',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'El Alamein',
		city_ascii: 'El Alamein',
		lat: 30.81707115,
		lng: 28.94995357,
		pop: 4938,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Matruh',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'El Daba',
		city_ascii: 'El Daba',
		lat: 31.03377626,
		lng: 28.4332926,
		pop: 14212,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Matruh',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Salum',
		city_ascii: 'Salum',
		lat: 31.56697369,
		lng: 25.15003048,
		pop: 7330,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Matruh',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Damanhûr',
		city_ascii: 'Damanhur',
		lat: 31.05044191,
		lng: 30.47001583,
		pop: 371350,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Buhayrah',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Samalut',
		city_ascii: 'Samalut',
		lat: 28.30042889,
		lng: 30.71000118,
		pop: 121281,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Minya',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Mallawi',
		city_ascii: 'Mallawi',
		lat: 27.73041201,
		lng: 30.83996741,
		pop: 179934.5,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Minya',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Beni Mazar',
		city_ascii: 'Beni Mazar',
		lat: 28.49039146,
		lng: 30.80999508,
		pop: 68853,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Minya',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Beni Suef',
		city_ascii: 'Beni Suef',
		lat: 29.08038129,
		lng: 31.09002966,
		pop: 339537,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Bani Suwayf',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Rashid',
		city_ascii: 'Rashid',
		lat: 31.46039105,
		lng: 30.39002071,
		pop: 128970.5,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Kafr ash Shaykh',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Qasr Farafra',
		city_ascii: 'Qasr Farafra',
		lat: 27.067145,
		lng: 27.96655106,
		pop: 5000,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Wadi at Jadid',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'El Qasr',
		city_ascii: 'El Qasr',
		lat: 25.70043256,
		lng: 28.88329097,
		pop: 1716,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Wadi at Jadid',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Isna',
		city_ascii: 'Isna',
		lat: 25.2904059,
		lng: 32.54994055,
		pop: 84667.5,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Qina',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Qena',
		city_ascii: 'Qena',
		lat: 26.15045677,
		lng: 32.72000769,
		pop: 268694.5,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Qina',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Girga',
		city_ascii: 'Girga',
		lat: 26.33036826,
		lng: 31.88000728,
		pop: 115475.5,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Suhaj',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Sohag',
		city_ascii: 'Sohag',
		lat: 26.55040651,
		lng: 31.70001827,
		pop: 404709.5,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Suhaj',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Berenice',
		city_ascii: 'Berenice',
		lat: 23.94599184,
		lng: 35.48418005,
		pop: 10,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Bahr al Ahmar',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Bur Safaga',
		city_ascii: 'Bur Safaga',
		lat: 26.73372866,
		lng: 33.93331864,
		pop: 21035.5,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Bahr al Ahmar',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'El Tur',
		city_ascii: 'El Tur',
		lat: 28.239389,
		lng: 33.61479726,
		pop: 21300,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Janub Sina',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'El Arish',
		city_ascii: 'El Arish',
		lat: 31.12488181,
		lng: 33.80056189,
		pop: 153753,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Shamal Sina',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'El Giza',
		city_ascii: 'El Giza',
		lat: 30.00998863,
		lng: 31.19002356,
		pop: 2681863,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Jizah',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Siwa',
		city_ascii: 'Siwa',
		lat: 29.20001223,
		lng: 25.51667476,
		pop: 23080,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Matruh',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'El Minya',
		city_ascii: 'El Minya',
		lat: 28.09000246,
		lng: 30.74999874,
		pop: 363575,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Minya',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Kom Ombo',
		city_ascii: 'Kom Ombo',
		lat: 24.46999086,
		lng: 32.95001949,
		pop: 181874.5,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Aswan',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'El Kharga',
		city_ascii: 'El Kharga',
		lat: 25.44000917,
		lng: 30.55001094,
		pop: 49991,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Wadi at Jadid',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Hurghada',
		city_ascii: 'Hurghada',
		lat: 27.23000327,
		lng: 33.83001745,
		pop: 157204,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Bahr al Ahmar',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Suez',
		city_ascii: 'Suez',
		lat: 30.00497601,
		lng: 32.54994055,
		pop: 498230,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'As Suways',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Bur Said',
		city_ascii: 'Bur Said',
		lat: 31.25998985,
		lng: 32.2900081,
		pop: 561932,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Bur Sa`id',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'El Faiyum',
		city_ascii: 'El Faiyum',
		lat: 29.31003135,
		lng: 30.83996741,
		pop: 311582.5,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Fayyum',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Aswan',
		city_ascii: 'Aswan',
		lat: 24.08750775,
		lng: 32.8989115,
		pop: 277351.5,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Aswan',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Asyut',
		city_ascii: 'Asyut',
		lat: 27.18997988,
		lng: 31.17994665,
		pop: 420585,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Asyut',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Luxor',
		city_ascii: 'Luxor',
		lat: 25.70001914,
		lng: 32.6500378,
		pop: 548572,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Qina',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Alexandria',
		city_ascii: 'Alexandria',
		lat: 31.20001935,
		lng: 29.94999589,
		pop: 3988258,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Iskandariyah',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Cairo',
		city_ascii: 'Cairo',
		lat: 30.04996035,
		lng: 31.24996822,
		pop: 9813807,
		country: 'Egypt',
		iso2: 'EG',
		iso3: 'EGY',
		province: 'Al Qahirah',
		timezone: 'Africa/Cairo',
	},
	{
		city: 'Ahuachapan',
		city_ascii: 'Ahuachapan',
		lat: 13.91900399,
		lng: -89.84500155,
		pop: 34102,
		country: 'El Salvador',
		iso2: 'SV',
		iso3: 'SLV',
		province: 'Ahuachapán',
		timezone: 'America/El_Salvador',
	},
	{
		city: 'Cojutepeque',
		city_ascii: 'Cojutepeque',
		lat: 13.71670204,
		lng: -88.9333016,
		pop: 48411,
		country: 'El Salvador',
		iso2: 'SV',
		iso3: 'SLV',
		province: 'Cuscatlán',
		timezone: 'America/El_Salvador',
	},
	{
		city: 'Nueva San Salvador',
		city_ascii: 'Nueva San Salvador',
		lat: 13.67399699,
		lng: -89.28999848,
		pop: 124694,
		country: 'El Salvador',
		iso2: 'SV',
		iso3: 'SLV',
		province: 'La Libertad',
		timezone: 'America/El_Salvador',
	},
	{
		city: 'Zacatecoluca',
		city_ascii: 'Zacatecoluca',
		lat: 13.508001,
		lng: -88.86799761,
		pop: 39613,
		country: 'El Salvador',
		iso2: 'SV',
		iso3: 'SLV',
		province: 'La Paz',
		timezone: 'America/El_Salvador',
	},
	{
		city: 'La Union',
		city_ascii: 'La Union',
		lat: 13.33199704,
		lng: -87.83900052,
		pop: 26807,
		country: 'El Salvador',
		iso2: 'SV',
		iso3: 'SLV',
		province: 'La Unión',
		timezone: 'America/El_Salvador',
	},
	{
		city: 'San Francisco Gotera',
		city_ascii: 'San Francisco Gotera',
		lat: 13.69999812,
		lng: -88.10000052,
		pop: 16152,
		country: 'El Salvador',
		iso2: 'SV',
		iso3: 'SLV',
		province: 'Morazán',
		timezone: 'America/El_Salvador',
	},
	{
		city: 'San Vicente',
		city_ascii: 'San Vicente',
		lat: 13.64100303,
		lng: -88.78499961,
		pop: 37326,
		country: 'El Salvador',
		iso2: 'SV',
		iso3: 'SLV',
		province: 'San Vicente',
		timezone: 'America/El_Salvador',
	},
	{
		city: 'Usulutan',
		city_ascii: 'Usulutan',
		lat: 13.3460011,
		lng: -88.43200162,
		pop: 51910,
		country: 'El Salvador',
		iso2: 'SV',
		iso3: 'SLV',
		province: 'Usulután',
		timezone: 'America/El_Salvador',
	},
	{
		city: 'Chalatenango',
		city_ascii: 'Chalatenango',
		lat: 14.07200406,
		lng: -89.09399649,
		pop: 29271,
		country: 'El Salvador',
		iso2: 'SV',
		iso3: 'SLV',
		province: 'Chalatenango',
		timezone: 'America/El_Salvador',
	},
	{
		city: 'Sensuntepeque',
		city_ascii: 'Sensuntepeque',
		lat: 13.88004295,
		lng: -88.63000126,
		pop: 23687.5,
		country: 'El Salvador',
		iso2: 'SV',
		iso3: 'SLV',
		province: 'Cabañas',
		timezone: 'America/El_Salvador',
	},
	{
		city: 'Sonsonate',
		city_ascii: 'Sonsonate',
		lat: 13.7199752,
		lng: -89.7299858,
		pop: 139724,
		country: 'El Salvador',
		iso2: 'SV',
		iso3: 'SLV',
		province: 'Sonsonate',
		timezone: 'America/El_Salvador',
	},
	{
		city: 'San Miguel',
		city_ascii: 'San Miguel',
		lat: 13.48334881,
		lng: -88.18333602,
		pop: 181721.5,
		country: 'El Salvador',
		iso2: 'SV',
		iso3: 'SLV',
		province: 'San Miguel',
		timezone: 'America/El_Salvador',
	},
	{
		city: 'Santa Ana',
		city_ascii: 'Santa Ana',
		lat: 13.9946096,
		lng: -89.55976363,
		pop: 205569.5,
		country: 'El Salvador',
		iso2: 'SV',
		iso3: 'SLV',
		province: 'Santa Ana',
		timezone: 'America/El_Salvador',
	},
	{
		city: 'San Salvador',
		city_ascii: 'San Salvador',
		lat: 13.71000165,
		lng: -89.20304122,
		pop: 717903.5,
		country: 'El Salvador',
		iso2: 'SV',
		iso3: 'SLV',
		province: 'San Salvador',
		timezone: 'America/El_Salvador',
	},
	{
		city: 'Evinayong',
		city_ascii: 'Evinayong',
		lat: 1.449999085,
		lng: 10.56670255,
		pop: 8462,
		country: 'Equatorial Guinea',
		iso2: 'GQ',
		iso3: 'GNQ',
		province: 'Centro Sur',
		timezone: 'Africa/Malabo',
	},
	{
		city: 'Luba',
		city_ascii: 'Luba',
		lat: 3.449997026,
		lng: 8.550000525,
		pop: 8655,
		country: 'Equatorial Guinea',
		iso2: 'GQ',
		iso3: 'GNQ',
		province: 'Bioko Sur',
		timezone: 'Africa/Malabo',
	},
	{
		city: 'Calatrava',
		city_ascii: 'Calatrava',
		lat: 1.116403421,
		lng: 9.418587604,
		pop: 628,
		country: 'Equatorial Guinea',
		iso2: 'GQ',
		iso3: 'GNQ',
		province: 'Litoral',
		timezone: 'Africa/Malabo',
	},
	{
		city: 'Mongomo',
		city_ascii: 'Mongomo',
		lat: 1.633684508,
		lng: 11.31655961,
		pop: 6476.5,
		country: 'Equatorial Guinea',
		iso2: 'GQ',
		iso3: 'GNQ',
		province: 'Wele-Nzás',
		timezone: 'Africa/Malabo',
	},
	{
		city: 'Bata',
		city_ascii: 'Bata',
		lat: 1.870000833,
		lng: 9.769987344,
		pop: 135943.5,
		country: 'Equatorial Guinea',
		iso2: 'GQ',
		iso3: 'GNQ',
		province: 'Litoral',
		timezone: 'Africa/Malabo',
	},
	{
		city: 'Malabo',
		city_ascii: 'Malabo',
		lat: 3.750015278,
		lng: 8.783277546,
		pop: 155963,
		country: 'Equatorial Guinea',
		iso2: 'GQ',
		iso3: 'GNQ',
		province: 'Bioko Norte',
		timezone: 'Africa/Malabo',
	},
	{
		city: 'Tessenei',
		city_ascii: 'Tessenei',
		lat: 15.11038129,
		lng: 36.65749345,
		pop: 9504,
		country: 'Eritrea',
		iso2: 'ER',
		iso3: 'ERI',
		province: 'Gash Barka',
		timezone: 'Africa/Asmara',
	},
	{
		city: 'Agordat',
		city_ascii: 'Agordat',
		lat: 15.54903668,
		lng: 37.88666907,
		pop: 18728.5,
		country: 'Eritrea',
		iso2: 'ER',
		iso3: 'ERI',
		province: 'Gash Barka',
		timezone: 'Africa/Asmara',
	},
	{
		city: 'Massawa',
		city_ascii: 'Massawa',
		lat: 15.61011822,
		lng: 39.45003617,
		pop: 142564,
		country: 'Eritrea',
		iso2: 'ER',
		iso3: 'ERI',
		province: 'Debub',
		timezone: 'Africa/Asmara',
	},
	{
		city: 'Keren',
		city_ascii: 'Keren',
		lat: 15.68039817,
		lng: 38.44999385,
		pop: 148241.5,
		country: 'Eritrea',
		iso2: 'ER',
		iso3: 'ERI',
		province: 'Semenawi Keyih Bahri',
		timezone: 'Africa/Asmara',
	},
	{
		city: 'Mendefera',
		city_ascii: 'Adi Ugri',
		lat: 14.88597638,
		lng: 38.81632808,
		pop: 137585.5,
		country: 'Eritrea',
		iso2: 'ER',
		iso3: 'ERI',
		province: 'Maekel',
		timezone: 'Africa/Asmara',
	},
	{
		city: 'Assab',
		city_ascii: 'Assab',
		lat: 13.01001853,
		lng: 42.72999101,
		pop: 105496,
		country: 'Eritrea',
		iso2: 'ER',
		iso3: 'ERI',
		province: 'Debubawi Keyih Bahri',
		timezone: 'Africa/Asmara',
	},
	{
		city: 'Asmara',
		city_ascii: 'Asmara',
		lat: 15.33333925,
		lng: 38.93332353,
		pop: 592366,
		country: 'Eritrea',
		iso2: 'ER',
		iso3: 'ERI',
		province: 'Anseba',
		timezone: 'Africa/Asmara',
	},
	{
		city: 'Haapsalu',
		city_ascii: 'Haapsalu',
		lat: 58.9430556,
		lng: 23.5413889,
		pop: 11805,
		country: 'Estonia',
		iso2: 'EE',
		iso3: 'EST',
		province: 'Lääne',
		timezone: 'Europe/Tallinn',
	},
	{
		city: 'Viljandi',
		city_ascii: 'Viljandi',
		lat: 58.3638889,
		lng: 25.59,
		pop: 20309,
		country: 'Estonia',
		iso2: 'EE',
		iso3: 'EST',
		province: 'Viljandi',
		timezone: 'Europe/Tallinn',
	},
	{
		city: 'Kohtla-Jarve',
		city_ascii: 'Kohtla-Jarve',
		lat: 59.39997764,
		lng: 27.28333695,
		pop: 31038,
		country: 'Estonia',
		iso2: 'EE',
		iso3: 'EST',
		province: 'Ida-Viru',
		timezone: 'Europe/Tallinn',
	},
	{
		city: 'Narva',
		city_ascii: 'Narva',
		lat: 59.37762758,
		lng: 28.16028601,
		pop: 65707,
		country: 'Estonia',
		iso2: 'EE',
		iso3: 'EST',
		province: 'Ida-Viru',
		timezone: 'Europe/Tallinn',
	},
	{
		city: 'Tartu',
		city_ascii: 'Tartu',
		lat: 58.38394147,
		lng: 26.70988358,
		pop: 90033.5,
		country: 'Estonia',
		iso2: 'EE',
		iso3: 'EST',
		province: 'Tartu',
		timezone: 'Europe/Tallinn',
	},
	{
		city: 'Parnu',
		city_ascii: 'Parnu',
		lat: 58.37474306,
		lng: 24.51358354,
		pop: 40256,
		country: 'Estonia',
		iso2: 'EE',
		iso3: 'EST',
		province: 'Pärnu',
		timezone: 'Europe/Tallinn',
	},
	{
		city: 'Tallinn',
		city_ascii: 'Tallinn',
		lat: 59.43387738,
		lng: 24.72804073,
		pop: 367025.5,
		country: 'Estonia',
		iso2: 'EE',
		iso3: 'EST',
		province: 'Harju',
		timezone: 'Europe/Tallinn',
	},
	{
		city: 'Awasa',
		city_ascii: 'Awasa',
		lat: 7.059996077,
		lng: 38.47699862,
		pop: 133097,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Southern Nations, Nationalities and Peoples',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Gore',
		city_ascii: 'Gore',
		lat: 8.148996048,
		lng: 35.53700451,
		pop: 9352,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Addis Ababa',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Debre Birhan',
		city_ascii: 'Debre Birhan',
		lat: 9.68037681,
		lng: 39.53003129,
		pop: 61509,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Amhara',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Bati',
		city_ascii: 'Bati',
		lat: 11.18374758,
		lng: 40.0165649,
		pop: 12826.5,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Amhara',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Adigrat',
		city_ascii: 'Adigrat',
		lat: 14.28043194,
		lng: 39.46998328,
		pop: 104021,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Tigray',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Aksum',
		city_ascii: 'Aksum',
		lat: 14.13041526,
		lng: 38.72000321,
		pop: 44368,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Tigray',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Yirga Alem',
		city_ascii: 'Yirga Alem',
		lat: 6.750426452,
		lng: 38.4099963,
		pop: 36292,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Southern Nations, Nationalities and Peoples',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Hosaina',
		city_ascii: 'Hosaina',
		lat: 7.550377623,
		lng: 37.85003048,
		pop: 89300,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Southern Nations, Nationalities and Peoples',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Dila',
		city_ascii: 'Dila',
		lat: 6.410421365,
		lng: 38.3100024,
		pop: 47021,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Southern Nations, Nationalities and Peoples',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Giyon',
		city_ascii: 'Giyon',
		lat: 8.530421162,
		lng: 37.97002315,
		pop: 76464,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Addis Ababa',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Hagere Hiywet',
		city_ascii: 'Hagere Hiywet',
		lat: 8.980393696,
		lng: 37.85003048,
		pop: 39412.5,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Addis Ababa',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Nekemte',
		city_ascii: 'Nekemte',
		lat: 9.090464497,
		lng: 36.53000769,
		pop: 73018,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Addis Ababa',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Asela',
		city_ascii: 'Asela',
		lat: 7.950404886,
		lng: 39.1399259,
		pop: 82240,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Addis Ababa',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Shashemene',
		city_ascii: 'Shashemene',
		lat: 7.200398986,
		lng: 38.59003699,
		pop: 100110.5,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Addis Ababa',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Dembi Dolo',
		city_ascii: 'Dembi Dolo',
		lat: 8.533728454,
		lng: 34.79998409,
		pop: 27264,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Addis Ababa',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Gimbi',
		city_ascii: 'Gimbi',
		lat: 9.167023131,
		lng: 35.83330603,
		pop: 22423.5,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Addis Ababa',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Asosa',
		city_ascii: 'Asosa',
		lat: 10.06699404,
		lng: 34.5333337,
		pop: 24192,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Benshangul-Gumaz',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Jijiga',
		city_ascii: 'Jijiga',
		lat: 9.350422789,
		lng: 42.78998734,
		pop: 52507.5,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Somali',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Debre Markos',
		city_ascii: 'Debre Markos',
		lat: 10.33997479,
		lng: 37.72001257,
		pop: 65339,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Amhara',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Dese',
		city_ascii: 'Dese',
		lat: 11.12997825,
		lng: 39.63002519,
		pop: 159929,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Amhara',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Sodo',
		city_ascii: 'Sodo',
		lat: 6.900003885,
		lng: 37.7499849,
		pop: 65737,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Southern Nations, Nationalities and Peoples',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Arba Minch',
		city_ascii: 'Arba Minch',
		lat: 6.040004699,
		lng: 37.54999711,
		pop: 54343.5,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Southern Nations, Nationalities and Peoples',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Harar',
		city_ascii: 'Harar',
		lat: 9.319959533,
		lng: 42.15002641,
		pop: 161150,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Harari',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Goba',
		city_ascii: 'Goba',
		lat: 7.010023009,
		lng: 39.97000443,
		pop: 33197,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Addis Ababa',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Jima',
		city_ascii: 'Jima',
		lat: 7.679982116,
		lng: 36.83004106,
		pop: 128306,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Addis Ababa',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Nazret',
		city_ascii: 'Nazret',
		lat: 8.549980691,
		lng: 39.26999548,
		pop: 345443.5,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Addis Ababa',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Nagele',
		city_ascii: 'Nagele',
		lat: 5.316612161,
		lng: 39.58330969,
		pop: 11772,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Addis Ababa',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Gode',
		city_ascii: 'Gode',
		lat: 5.950010192,
		lng: 43.44999874,
		pop: 71671,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Somali',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Dolo Bay',
		city_ascii: 'Dolo Bay',
		lat: 4.183348001,
		lng: 42.08331213,
		pop: 11810,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Somali',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Bahir Dar',
		city_ascii: 'Bahir Dar',
		lat: 11.60005292,
		lng: 37.38328894,
		pop: 187823.5,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Amhara',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Mekele',
		city_ascii: 'Mekele',
		lat: 13.49998863,
		lng: 39.46998328,
		pop: 95856,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Tigray',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Dire Dawa',
		city_ascii: 'Dire Dawa',
		lat: 9.58999473,
		lng: 41.86001827,
		pop: 250325.5,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Dire Dawa',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Gonder',
		city_ascii: 'Gonder',
		lat: 12.61004295,
		lng: 37.46002844,
		pop: 155072,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Amhara',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Addis Ababa',
		city_ascii: 'Addis Ababa',
		lat: 9.033310363,
		lng: 38.70000443,
		pop: 2928864.5,
		country: 'Ethiopia',
		iso2: 'ET',
		iso3: 'ETH',
		province: 'Addis Ababa',
		timezone: 'Africa/Addis_Ababa',
	},
	{
		city: 'Fox Bay',
		city_ascii: 'Fox Bay',
		lat: -51.95058999,
		lng: -60.08696314,
		pop: 90,
		country: 'Falkland Islands',
		iso2: 'FK',
		iso3: 'FLK',
		province: '',
		timezone: 'Atlantic/Stanley',
	},
	{
		city: 'Stanley',
		city_ascii: 'Stanley',
		lat: -51.70001097,
		lng: -57.8499679,
		pop: 2213,
		country: 'Falkland Islands',
		iso2: 'FK',
		iso3: 'FLK',
		province: '',
		timezone: 'Atlantic/Stanley',
	},
	{
		city: 'Klaksvik',
		city_ascii: 'Klaksvik',
		lat: 62.2374783,
		lng: -6.53901149,
		pop: 4664,
		country: 'Faroe Islands',
		iso2: 'FO',
		iso3: 'FRO',
		province: 'Eysturoyar',
		timezone: 'Atlantic/Faroe',
	},
	{
		city: 'Tórshavn',
		city_ascii: 'Torshavn',
		lat: 62.03002382,
		lng: -6.820033611,
		pop: 14398,
		country: 'Faroe Islands',
		iso2: 'FO',
		iso3: 'FRO',
		province: 'Eysturoyar',
		timezone: 'Atlantic/Faroe',
	},
	{
		city: 'Palikir',
		city_ascii: 'Palikir',
		lat: 6.916643696,
		lng: 158.1499743,
		pop: 4645,
		country: 'Federated States of Micronesia',
		iso2: 'FM',
		iso3: 'FSM',
		province: '',
		timezone: 'Pacific/Pohnpei',
	},
	{
		city: 'Nandi',
		city_ascii: 'Nandi',
		lat: -17.79959959,
		lng: 177.4166019,
		pop: 27329,
		country: 'Fiji',
		iso2: 'FJ',
		iso3: 'FJI',
		province: 'Western',
		timezone: 'Pacific/Fiji',
	},
	{
		city: 'Lautoka',
		city_ascii: 'Lautoka',
		lat: -17.61609658,
		lng: 177.4666247,
		pop: 55894,
		country: 'Fiji',
		iso2: 'FJ',
		iso3: 'FJI',
		province: 'Western',
		timezone: 'Pacific/Fiji',
	},
	{
		city: 'Labasa',
		city_ascii: 'Labasa',
		lat: -16.41658323,
		lng: 179.3833036,
		pop: 24187,
		country: 'Fiji',
		iso2: 'FJ',
		iso3: 'FJI',
		province: 'Western',
		timezone: 'Pacific/Fiji',
	},
	{
		city: 'Suva',
		city_ascii: 'Suva',
		lat: -18.13301593,
		lng: 178.4417073,
		pop: 131835,
		country: 'Fiji',
		iso2: 'FJ',
		iso3: 'FJI',
		province: 'Central',
		timezone: 'Pacific/Fiji',
	},
	{
		city: 'Hameenlinna',
		city_ascii: 'Hameenlinna',
		lat: 60.99699611,
		lng: 24.47199954,
		pop: 47261,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Tavastia Proper',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Kouvola',
		city_ascii: 'Kouvola',
		lat: 60.87600009,
		lng: 26.70900351,
		pop: 31133,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Southern Finland',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Mikkeli',
		city_ascii: 'Mikkeli',
		lat: 61.68999617,
		lng: 27.28500348,
		pop: 46550,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Southern Savonia',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Savonlinna',
		city_ascii: 'Savonlinna',
		lat: 61.86662294,
		lng: 28.88334265,
		pop: 20233.5,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Southern Savonia',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Pori',
		city_ascii: 'Pori',
		lat: 61.47889467,
		lng: 21.77493933,
		pop: 71526,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Satakunta',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Sodankylä',
		city_ascii: 'Sodankyla',
		lat: 67.41705935,
		lng: 26.60001949,
		pop: 6516.5,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Lapland',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Jyväskylä',
		city_ascii: 'Jyvaskyla',
		lat: 62.26034568,
		lng: 25.74999385,
		pop: 91581,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Central Finland',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Kuopio',
		city_ascii: 'Kuopio',
		lat: 62.89428632,
		lng: 27.69493974,
		pop: 90502,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Eastern Finland',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Lappeenranta',
		city_ascii: 'Lappeenranta',
		lat: 61.06705935,
		lng: 28.1833337,
		pop: 54516.5,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'South Karelia',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Porvoo',
		city_ascii: 'Porvoo',
		lat: 60.40035585,
		lng: 25.66601965,
		pop: 12242,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Eastern Uusimaa',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Kemijarvi',
		city_ascii: 'Kemijarvi',
		lat: 66.66666587,
		lng: 27.41666215,
		pop: 8883,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Lapland',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Kokkola',
		city_ascii: 'Kokkola',
		lat: 63.83329877,
		lng: 23.11666622,
		pop: 46714,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Western Finland',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Lahti',
		city_ascii: 'Lahti',
		lat: 60.99385968,
		lng: 25.66493445,
		pop: 97508,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Päijänne Tavastia',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Joensuu',
		city_ascii: 'Joensuu',
		lat: 62.59998903,
		lng: 29.76664791,
		pop: 53388,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'North Karelia',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Turku',
		city_ascii: 'Turku',
		lat: 60.4538668,
		lng: 22.25496171,
		pop: 175442.5,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Finland Proper',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Kemi',
		city_ascii: 'Kemi',
		lat: 65.73331199,
		lng: 24.58169307,
		pop: 17060,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Lapland',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Oulu',
		city_ascii: 'Oulu',
		lat: 64.99999758,
		lng: 25.47001094,
		pop: 132685,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Northern Ostrobothnia',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Rovaniemi',
		city_ascii: 'Rovaniemi',
		lat: 66.50003522,
		lng: 25.71593909,
		pop: 30085,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Lapland',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Vaasa',
		city_ascii: 'Vaasa',
		lat: 63.09998435,
		lng: 21.60001461,
		pop: 48930,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Western Finland',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Tampere',
		city_ascii: 'Tampere',
		lat: 61.5000045,
		lng: 23.75001257,
		pop: 230983,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Pirkanmaa',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Helsinki',
		city_ascii: 'Helsinki',
		lat: 60.17556337,
		lng: 24.93412634,
		pop: 836728.5,
		country: 'Finland',
		iso2: 'FI',
		iso3: 'FIN',
		province: 'Southern Finland',
		timezone: 'Europe/Helsinki',
	},
	{
		city: 'Annecy',
		city_ascii: 'Annecy',
		lat: 45.89997479,
		lng: 6.116670287,
		pop: 77490.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Rhône-Alpes',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Roanne',
		city_ascii: 'Roanne',
		lat: 46.03332583,
		lng: 4.066666218,
		pop: 56577.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Rhône-Alpes',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Roura',
		city_ascii: 'Roura',
		lat: 4.729981302,
		lng: -52.33002059,
		pop: 2069.5,
		country: 'France',
		iso2: 'GF',
		iso3: 'FRA',
		province: 'Guinaa',
		timezone: 'America/Cayenne',
	},
	{
		city: 'Sinnamary',
		city_ascii: 'Sinnamary',
		lat: 5.380019144,
		lng: -52.95998214,
		pop: 2781.5,
		country: 'France',
		iso2: 'GF',
		iso3: 'FRA',
		province: 'Guinaa',
		timezone: 'America/Cayenne',
	},
	{
		city: 'St.-Brieuc',
		city_ascii: 'St.-Brieuc',
		lat: 48.51666262,
		lng: -2.783303265,
		pop: 52998.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Bretagne',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Poitier',
		city_ascii: 'Poitier',
		lat: 46.58329226,
		lng: 0.333276529,
		pop: 85383.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Poitou-Charentes',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Angers',
		city_ascii: 'Angers',
		lat: 47.48000755,
		lng: -0.530029949,
		pop: 178329.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Pays de la Loire',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Biarritz',
		city_ascii: 'Biarritz',
		lat: 43.47327537,
		lng: -1.561594891,
		pop: 89268,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Aquitaine',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Aix-en-Provence',
		city_ascii: 'Aix-en-Provence',
		lat: 43.51999086,
		lng: 5.449992634,
		pop: 145309,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: "Provence-Alpes-Côte-d'Azur",
		timezone: 'Europe/Paris',
	},
	{
		city: 'Perpignan',
		city_ascii: 'Perpignan',
		lat: 42.69998924,
		lng: 2.899967406,
		pop: 128663,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Languedoc-Roussillon',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Tarbes',
		city_ascii: 'Tarbes',
		lat: 43.23329002,
		lng: 0.083343464,
		pop: 53480,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Midi-Pyrénées',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Clermont-Ferrand',
		city_ascii: 'Clermont-Ferrand',
		lat: 45.77998212,
		lng: 3.080008096,
		pop: 185865.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Auvergne',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Melun',
		city_ascii: 'Melun',
		lat: 48.53330243,
		lng: 2.666648314,
		pop: 144192.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Île-de-France',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Arras',
		city_ascii: 'Arras',
		lat: 50.28332481,
		lng: 2.783333698,
		pop: 55608.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Nord-Pas-de-Calais',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Besancon',
		city_ascii: 'Besancon',
		lat: 47.22999697,
		lng: 6.03000891,
		pop: 124193,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Franche-Comté',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Saint-Georges',
		city_ascii: 'Saint-Georges',
		lat: 3.9104706,
		lng: -51.81000065,
		pop: 1558,
		country: 'France',
		iso2: 'GF',
		iso3: 'FRA',
		province: 'Amapá',
		timezone: 'America/Cayenne',
	},
	{
		city: 'Saint-Etienne',
		city_ascii: 'Saint-Etienne',
		lat: 45.43039105,
		lng: 4.380032103,
		pop: 220982,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Rhône-Alpes',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Grenoble',
		city_ascii: 'Grenoble',
		lat: 45.18038047,
		lng: 5.720001992,
		pop: 273563,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Rhône-Alpes',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Fort-de-France',
		city_ascii: 'Fort-de-France',
		lat: 14.6104118,
		lng: -61.08002914,
		pop: 172622,
		country: 'France',
		iso2: 'MQ',
		iso3: 'FRA',
		province: 'Martinique',
		timezone: 'America/Martinique',
	},
	{
		city: 'Saint-Laurent-du-Maroni',
		city_ascii: 'Saint-Laurent-du-Maroni',
		lat: 5.497624081,
		lng: -54.03253459,
		pop: 20850.5,
		country: 'France',
		iso2: 'GF',
		iso3: 'FRA',
		province: 'Guinaa',
		timezone: 'America/Cayenne',
	},
	{
		city: 'Iracoubo',
		city_ascii: 'Iracoubo',
		lat: 5.480426452,
		lng: -53.21999211,
		pop: 881,
		country: 'France',
		iso2: 'GF',
		iso3: 'FRA',
		province: 'Guinaa',
		timezone: 'America/Cayenne',
	},
	{
		city: 'Cherbourg',
		city_ascii: 'Cherbourg',
		lat: 49.65039187,
		lng: -1.649987428,
		pop: 60991,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Basse-Normandie',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Caen',
		city_ascii: 'Caen',
		lat: 49.18375368,
		lng: -0.349989259,
		pop: 150361.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Basse-Normandie',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Lorient',
		city_ascii: 'Lorient',
		lat: 47.75040448,
		lng: -3.366575156,
		pop: 71532,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Bretagne',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Brest',
		city_ascii: 'Brest',
		lat: 48.39044293,
		lng: -4.49500757,
		pop: 142914,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Bretagne',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Le Mans',
		city_ascii: 'Le Mans',
		lat: 48.00041506,
		lng: 0.099983275,
		pop: 143392.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Pays de la Loire',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Nantes',
		city_ascii: 'Nantes',
		lat: 47.21038576,
		lng: -1.590016929,
		pop: 357903,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Pays de la Loire',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Agen',
		city_ascii: 'Agen',
		lat: 44.20041445,
		lng: 0.633335733,
		pop: 46295,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Aquitaine',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Ajaccio',
		city_ascii: 'Ajaccio',
		lat: 41.92706484,
		lng: 8.728293822,
		pop: 46905,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Corse',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Bastia',
		city_ascii: 'Bastia',
		lat: 42.70316734,
		lng: 9.450006875,
		pop: 40558.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Corse',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Toulon',
		city_ascii: 'Toulon',
		lat: 43.13418645,
		lng: 5.918821566,
		pop: 263197,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: "Provence-Alpes-Côte-d'Azur",
		timezone: 'Europe/Paris',
	},
	{
		city: 'Beziers',
		city_ascii: 'Beziers',
		lat: 43.35049217,
		lng: 3.209974323,
		pop: 77759.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Languedoc-Roussillon',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Montpellier',
		city_ascii: 'Montpellier',
		lat: 43.61039878,
		lng: 3.869985716,
		pop: 287753,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Languedoc-Roussillon',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Nimes',
		city_ascii: 'Nimes',
		lat: 43.83038535,
		lng: 4.350008096,
		pop: 158891.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Languedoc-Roussillon',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Vichy',
		city_ascii: 'Vichy',
		lat: 46.117145,
		lng: 3.416680052,
		pop: 35088.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Auvergne',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Nevers',
		city_ascii: 'Nevers',
		lat: 46.98373293,
		lng: 3.166669473,
		pop: 44958.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Bourgogne',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Auxerre',
		city_ascii: 'Auxerre',
		lat: 47.80042727,
		lng: 3.566593382,
		pop: 38034,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Bourgogne',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Dijon',
		city_ascii: 'Dijon',
		lat: 47.33040428,
		lng: 5.030018268,
		pop: 159864,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Bourgogne',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Bourges',
		city_ascii: 'Bourges',
		lat: 47.08372683,
		lng: 2.399997923,
		pop: 70163.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Centre',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Tours',
		city_ascii: 'Tours',
		lat: 47.38037539,
		lng: 0.699946654,
		pop: 188858.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Centre',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Orleans',
		city_ascii: 'Orleans',
		lat: 47.90042116,
		lng: 1.900028441,
		pop: 170725,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Centre',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Dieppe',
		city_ascii: 'Dieppe',
		lat: 49.93373374,
		lng: 1.083334105,
		pop: 39084,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Haute-Normandie',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Rouen',
		city_ascii: 'Rouen',
		lat: 49.43040529,
		lng: 1.079975137,
		pop: 321417.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Haute-Normandie',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Versailles',
		city_ascii: 'Versailles',
		lat: 48.80046958,
		lng: 2.133347533,
		pop: 85416,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Île-de-France',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Brive',
		city_ascii: 'Brive',
		lat: 45.15040814,
		lng: 1.533332477,
		pop: 54457,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Limousin',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Troyes',
		city_ascii: 'Troyes',
		lat: 48.34039431,
		lng: 4.083357705,
		pop: 61244,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Champagne-Ardenne',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Reims',
		city_ascii: 'Reims',
		lat: 49.25039044,
		lng: 4.029975951,
		pop: 177939,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Champagne-Ardenne',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Calais',
		city_ascii: 'Calais',
		lat: 50.95041587,
		lng: 1.833314167,
		pop: 83317,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Nord-Pas-de-Calais',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Amiens',
		city_ascii: 'Amiens',
		lat: 49.90037661,
		lng: 2.300004027,
		pop: 118908.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Picardie',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Mulhouse',
		city_ascii: 'Mulhouse',
		lat: 47.75040448,
		lng: 7.34998002,
		pop: 163442,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Alsace',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Nancy',
		city_ascii: 'Nancy',
		lat: 48.68368085,
		lng: 6.200024372,
		pop: 187155,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Lorraine',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Metz',
		city_ascii: 'Metz',
		lat: 49.1203467,
		lng: 6.180025593,
		pop: 266550,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Lorraine',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Pointe-a-Pitre',
		city_ascii: 'Pointe-a-Pitre',
		lat: 16.24147504,
		lng: -61.5329989,
		pop: 81887.5,
		country: 'France',
		iso2: 'GP',
		iso3: 'FRA',
		province: 'Guadeloupe',
		timezone: 'America/Guadeloupe',
	},
	{
		city: 'Basse-terre',
		city_ascii: 'Basse-terre',
		lat: 16.23039044,
		lng: -61.43998132,
		pop: 307,
		country: 'France',
		iso2: 'GP',
		iso3: 'FRA',
		province: 'Guadeloupe',
		timezone: 'America/Guadeloupe',
	},
	{
		city: 'St.-Benoit',
		city_ascii: 'St.-Benoit',
		lat: -21.03351072,
		lng: 55.71281612,
		pop: 23979,
		country: 'France',
		iso2: 'RE',
		iso3: 'FRA',
		province: 'La Réunion',
		timezone: 'Indian/Reunion',
	},
	{
		city: 'Dzaoudzi',
		city_ascii: 'Dzaoudzi',
		lat: -12.78708901,
		lng: 45.27500362,
		pop: 23099,
		country: 'France',
		iso2: 'YT',
		iso3: 'FRA',
		province: 'Moyotte',
		timezone: 'Indian/Mayotte',
	},
	{
		city: 'Rennes',
		city_ascii: 'Rennes',
		lat: 48.10002138,
		lng: -1.670012046,
		pop: 204329.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Bretagne',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Nice',
		city_ascii: 'Nice',
		lat: 43.71501772,
		lng: 7.265023965,
		pop: 632810,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: "Provence-Alpes-Côte-d'Azur",
		timezone: 'Europe/Paris',
	},
	{
		city: 'Toulouse',
		city_ascii: 'Toulouse',
		lat: 43.61995892,
		lng: 1.449926716,
		pop: 640027.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Midi-Pyrénées',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Limoges',
		city_ascii: 'Limoges',
		lat: 45.82997906,
		lng: 1.249990599,
		pop: 146687.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Limousin',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Lille',
		city_ascii: 'Lille',
		lat: 50.64996909,
		lng: 3.080008096,
		pop: 636164,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Nord-Pas-de-Calais',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Strasbourg',
		city_ascii: 'Strasbourg',
		lat: 48.57996625,
		lng: 7.750007282,
		pop: 357408.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Alsace',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Kourou',
		city_ascii: 'Kourou',
		lat: 5.159980895,
		lng: -52.64994938,
		pop: 22425.5,
		country: 'France',
		iso2: 'GF',
		iso3: 'FRA',
		province: 'Guinaa',
		timezone: 'America/Cayenne',
	},
	{
		city: 'La Rochelle',
		city_ascii: 'La Rochelle',
		lat: 46.16665102,
		lng: -1.149992108,
		pop: 76903.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Poitou-Charentes',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Bordeaux',
		city_ascii: 'Bordeaux',
		lat: 44.85001304,
		lng: -0.595013063,
		pop: 517422,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Aquitaine',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Marseille',
		city_ascii: 'Marseille',
		lat: 43.28997906,
		lng: 5.37501013,
		pop: 1097405.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: "Provence-Alpes-Côte-d'Azur",
		timezone: 'Europe/Paris',
	},
	{
		city: 'Le Havre',
		city_ascii: 'Le Havre',
		lat: 49.50497438,
		lng: 0.104970051,
		pop: 214048,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Haute-Normandie',
		timezone: 'Europe/Paris',
	},
	{
		city: 'St.-Denis',
		city_ascii: 'St.-Denis',
		lat: -20.87889484,
		lng: 55.44807776,
		pop: 163621,
		country: 'France',
		iso2: 'RE',
		iso3: 'FRA',
		province: 'La Réunion',
		timezone: 'Indian/Reunion',
	},
	{
		city: 'Lyon',
		city_ascii: 'Lyon',
		lat: 45.77000856,
		lng: 4.830030475,
		pop: 947658.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Rhône-Alpes',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Cayenne',
		city_ascii: 'Cayenne',
		lat: 4.932992166,
		lng: -52.33002059,
		pop: 57179.5,
		country: 'France',
		iso2: 'GF',
		iso3: 'FRA',
		province: 'Guinaa',
		timezone: 'America/Cayenne',
	},
	{
		city: 'Paris',
		city_ascii: 'Paris',
		lat: 48.86669293,
		lng: 2.333335326,
		pop: 4957588.5,
		country: 'France',
		iso2: 'FR',
		iso3: 'FRA',
		province: 'Île-de-France',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Papeete',
		city_ascii: 'Papeete',
		lat: -17.53336261,
		lng: -149.5666694,
		pop: 78856,
		country: 'French Polynesia',
		iso2: 'PF',
		iso3: 'PYF',
		province: '',
		timezone: 'Pacific/Tahiti',
	},
	{
		city: 'Ebebiyin',
		city_ascii: 'Ebebiyin',
		lat: 2.152997996,
		lng: 11.33000156,
		pop: 24831,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Wouleu-Ntem',
		timezone: 'Africa/Malabo',
	},
	{
		city: 'Tchibanga',
		city_ascii: 'Tchibanga',
		lat: -2.856995957,
		lng: 11.02699849,
		pop: 19365,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Nyanga',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Mekambo',
		city_ascii: 'Mekambo',
		lat: 1.017081318,
		lng: 13.93329911,
		pop: 3170,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Ogooué-Ivindo',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Makokou',
		city_ascii: 'Makokou',
		lat: 0.567082946,
		lng: 12.8665942,
		pop: 15320.5,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Ogooué-Ivindo',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Mitzik',
		city_ascii: 'Mitzik',
		lat: 0.78371055,
		lng: 11.56662187,
		pop: 3998.5,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Wouleu-Ntem',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Bitam',
		city_ascii: 'Bitam',
		lat: 2.083657042,
		lng: 11.48337113,
		pop: 13967,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Wouleu-Ntem',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Lambarene',
		city_ascii: 'Lambarene',
		lat: -0.699609763,
		lng: 10.21662675,
		pop: 23012,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Moyen-Ogooué',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Bifoum',
		city_ascii: 'Bifoum',
		lat: -0.332913799,
		lng: 10.38323157,
		pop: 134,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Moyen-Ogooué',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Ndende',
		city_ascii: 'Ndende',
		lat: -2.382917868,
		lng: 11.38327388,
		pop: 4101,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Ngounié',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Mouila',
		city_ascii: 'Mouila',
		lat: -1.866153545,
		lng: 11.01668127,
		pop: 25234.5,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Ngounié',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Omboue',
		city_ascii: 'Omboue',
		lat: -1.566223532,
		lng: 9.249967406,
		pop: 1667,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Ogooué-Maritime',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Moanda',
		city_ascii: 'Moanda',
		lat: -1.565500062,
		lng: 13.20001054,
		pop: 28633,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Haut-Ogooué',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Okandja',
		city_ascii: 'Okandja',
		lat: -0.682918275,
		lng: 13.78333411,
		pop: 7155,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Haut-Ogooué',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Koulamoutou',
		city_ascii: 'Koulamoutou',
		lat: -1.132916647,
		lng: 12.4833101,
		pop: 14343.5,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Ogooué-Lolo',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Oyem',
		city_ascii: 'Oyem',
		lat: 1.616631286,
		lng: 11.58331335,
		pop: 37146.5,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Wouleu-Ntem',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Mayumba',
		city_ascii: 'Mayumba',
		lat: -3.416601543,
		lng: 10.65003699,
		pop: 3996,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Nyanga',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Gamba',
		city_ascii: 'Gamba',
		lat: -2.650033347,
		lng: 9.999999144,
		pop: 7230.5,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Ogooué-Maritime',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Franceville',
		city_ascii: 'Franceville',
		lat: -1.633299541,
		lng: 13.58329464,
		pop: 41056,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Haut-Ogooué',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Libreville',
		city_ascii: 'Libreville',
		lat: 0.38538861,
		lng: 9.457965046,
		pop: 530755.5,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Estuaire',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Port-Gentil',
		city_ascii: 'Port-Gentil',
		lat: -0.720021953,
		lng: 8.780021931,
		pop: 112999.5,
		country: 'Gabon',
		iso2: 'GA',
		iso3: 'GAB',
		province: 'Ogooué-Maritime',
		timezone: 'Africa/Libreville',
	},
	{
		city: 'Kutaisi',
		city_ascii: 'Kutaisi',
		lat: 42.24999086,
		lng: 42.72999101,
		pop: 181141.5,
		country: 'Georgia',
		iso2: 'GE',
		iso3: 'GEO',
		province: 'Imereti',
		timezone: 'Asia/Tbilisi',
	},
	{
		city: 'Tskhinvali',
		city_ascii: 'Tskhinvali',
		lat: 42.23249839,
		lng: 43.97503129,
		pop: 26751,
		country: 'Georgia',
		iso2: 'GE',
		iso3: 'GEO',
		province: 'Shida Kartli',
		timezone: 'Asia/Tbilisi',
	},
	{
		city: 'Poti',
		city_ascii: 'Poti',
		lat: 42.15565554,
		lng: 41.671606,
		pop: 46001,
		country: 'Georgia',
		iso2: 'GE',
		iso3: 'GEO',
		province: 'Samegrelo-Zemo Svaneti',
		timezone: 'Asia/Tbilisi',
	},
	{
		city: 'Rustavi',
		city_ascii: 'Rustavi',
		lat: 41.57036826,
		lng: 45.05000443,
		pop: 156770,
		country: 'Georgia',
		iso2: 'GE',
		iso3: 'GEO',
		province: 'Kvemo Kartli',
		timezone: 'Asia/Tbilisi',
	},
	{
		city: 'Batumi',
		city_ascii: 'Batumi',
		lat: 41.6000047,
		lng: 41.63000647,
		pop: 138674,
		country: 'Georgia',
		iso2: 'GE',
		iso3: 'GEO',
		province: 'Ajaria',
		timezone: 'Asia/Tbilisi',
	},
	{
		city: 'Sukhumi',
		city_ascii: 'Sukhumi',
		lat: 43.02002138,
		lng: 41.02001786,
		pop: 76219,
		country: 'Georgia',
		iso2: 'GE',
		iso3: 'GEO',
		province: 'Abkhazia',
		timezone: 'Asia/Tbilisi',
	},
	{
		city: 'Tbilisi',
		city_ascii: 'Tbilisi',
		lat: 41.72500999,
		lng: 44.79079545,
		pop: 1052628.5,
		country: 'Georgia',
		iso2: 'GE',
		iso3: 'GEO',
		province: 'Tbilisi',
		timezone: 'Asia/Tbilisi',
	},
	{
		city: 'Mainz',
		city_ascii: 'Mainz',
		lat: 49.98247246,
		lng: 8.273219156,
		pop: 184997,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Rheinland-Pfalz',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Schwerin',
		city_ascii: 'Schwerin',
		lat: 53.63330408,
		lng: 11.41669861,
		pop: 96641,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Mecklenburg-Vorpommern',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Bielefeld',
		city_ascii: 'Bielefeld',
		lat: 52.02998822,
		lng: 8.530011351,
		pop: 311739.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Nordrhein-Westfalen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Dortmund',
		city_ascii: 'Dortmund',
		lat: 51.52996706,
		lng: 7.450025593,
		pop: 588462,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Nordrhein-Westfalen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Duisburg',
		city_ascii: 'Duisburg',
		lat: 51.42997316,
		lng: 6.750016641,
		pop: 882381,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Nordrhein-Westfalen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Wuppertal',
		city_ascii: 'Wuppertal',
		lat: 51.25000999,
		lng: 7.169991006,
		pop: 562997.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Nordrhein-Westfalen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Essen',
		city_ascii: 'Essen',
		lat: 51.44999778,
		lng: 7.016615355,
		pop: 1157801.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Nordrhein-Westfalen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Karlsruhe',
		city_ascii: 'Karlsruhe',
		lat: 48.99999229,
		lng: 8.399993448,
		pop: 330643,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Baden-Württemberg',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Heidelberg',
		city_ascii: 'Heidelberg',
		lat: 49.41999249,
		lng: 8.699975137,
		pop: 284967.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Baden-Württemberg',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Kassel',
		city_ascii: 'Kassel',
		lat: 51.30000694,
		lng: 9.500029662,
		pop: 242212.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Hessen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Oldenburg',
		city_ascii: 'Oldenburg',
		lat: 53.1299986,
		lng: 8.220004434,
		pop: 163338,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Niedersachsen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Emden',
		city_ascii: 'Emden',
		lat: 53.36667666,
		lng: 7.216654824,
		pop: 49786,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Niedersachsen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Braunschweig',
		city_ascii: 'Braunschweig',
		lat: 52.24997479,
		lng: 10.5000203,
		pop: 239884.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Niedersachsen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Erfurt',
		city_ascii: 'Erfurt',
		lat: 50.97005292,
		lng: 11.02996212,
		pop: 189365,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Thüringen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Coburg',
		city_ascii: 'Coburg',
		lat: 50.26660748,
		lng: 10.96660681,
		pop: 51477.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Bayern',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Augsburg',
		city_ascii: 'Augsburg',
		lat: 48.35000612,
		lng: 10.89999589,
		pop: 309092.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Bayern',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Furth',
		city_ascii: 'Furth',
		lat: 49.47001528,
		lng: 10.99998979,
		pop: 174934.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Bayern',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Chemnitz',
		city_ascii: 'Chemnitz',
		lat: 50.82998395,
		lng: 12.91997595,
		pop: 274931.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Sachsen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Bonn',
		city_ascii: 'Bonn',
		lat: 50.72045575,
		lng: 7.080022337,
		pop: 496834,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Nordrhein-Westfalen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Münster',
		city_ascii: 'Munster',
		lat: 51.97040529,
		lng: 7.620041055,
		pop: 253612.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Nordrhein-Westfalen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Düsseldorf',
		city_ascii: 'Dusseldorf',
		lat: 51.22037355,
		lng: 6.779988972,
		pop: 906196.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Nordrhein-Westfalen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Ulm',
		city_ascii: 'Ulm',
		lat: 48.40039064,
		lng: 9.999999144,
		pop: 146703,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Baden-Württemberg',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Mannheim',
		city_ascii: 'Mannheim',
		lat: 49.50037518,
		lng: 8.470015013,
		pop: 1337587,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Baden-Württemberg',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Freiburg',
		city_ascii: 'Freiburg',
		lat: 48.00041506,
		lng: 7.869948281,
		pop: 235427.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Baden-Württemberg',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Giessen',
		city_ascii: 'Giessen',
		lat: 50.58371991,
		lng: 8.650004027,
		pop: 78384.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Hessen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Wiesbaden',
		city_ascii: 'Wiesbaden',
		lat: 50.08039146,
		lng: 8.250028441,
		pop: 444779,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Hessen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Bremerhaven',
		city_ascii: 'Bremerhaven',
		lat: 53.55043805,
		lng: 8.579982461,
		pop: 127598.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Bremen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Osnabrück',
		city_ascii: 'Osnabruck',
		lat: 52.28043805,
		lng: 8.049988972,
		pop: 198865,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Niedersachsen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Hannover',
		city_ascii: 'Hannover',
		lat: 52.36697023,
		lng: 9.716657266,
		pop: 618815,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Niedersachsen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Gottingen',
		city_ascii: 'Gottingen',
		lat: 51.52043276,
		lng: 9.920004027,
		pop: 130784,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Niedersachsen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Gera',
		city_ascii: 'Gera',
		lat: 50.87036908,
		lng: 12.07000199,
		pop: 103857,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Thüringen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Jena',
		city_ascii: 'Jena',
		lat: 50.93044293,
		lng: 11.58000606,
		pop: 99941.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Thüringen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Flensburg',
		city_ascii: 'Flensburg',
		lat: 54.78374778,
		lng: 9.433315388,
		pop: 91884,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Schleswig-Holstein',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Lubeck',
		city_ascii: 'Lubeck',
		lat: 53.87039268,
		lng: 10.66998409,
		pop: 223798.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Schleswig-Holstein',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Kiel',
		city_ascii: 'Kiel',
		lat: 54.33039044,
		lng: 10.13001705,
		pop: 251092.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Schleswig-Holstein',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Koblenz',
		city_ascii: 'Koblenz',
		lat: 50.35047833,
		lng: 7.599990599,
		pop: 209976,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Rheinland-Pfalz',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Saarbrucken',
		city_ascii: 'Saarbrucken',
		lat: 49.25039044,
		lng: 6.970003213,
		pop: 472871,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Saarland',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Regensburg',
		city_ascii: 'Regensburg',
		lat: 49.02040448,
		lng: 12.12002478,
		pop: 146755,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Bayern',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Rosenheim',
		city_ascii: 'Rosenheim',
		lat: 47.8503467,
		lng: 12.13330562,
		pop: 76488,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Bayern',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Hof',
		city_ascii: 'Hof',
		lat: 50.31704368,
		lng: 11.91667802,
		pop: 52696,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Bayern',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Wurzburg',
		city_ascii: 'Wurzburg',
		lat: 49.80043439,
		lng: 9.950028034,
		pop: 151146,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Bayern',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Ingolstadt',
		city_ascii: 'Ingolstadt',
		lat: 48.77041974,
		lng: 11.44998816,
		pop: 141991.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Bayern',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Cottbus',
		city_ascii: 'Cottbus',
		lat: 51.7704175,
		lng: 14.32996741,
		pop: 94910.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Brandenburg',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Potsdam',
		city_ascii: 'Potsdam',
		lat: 52.40040489,
		lng: 13.06999263,
		pop: 181693.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Brandenburg',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Magdeburg',
		city_ascii: 'Magdeburg',
		lat: 52.13042137,
		lng: 11.62000362,
		pop: 227378.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Sachsen-Anhalt',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Leipzig',
		city_ascii: 'Leipzig',
		lat: 51.33540529,
		lng: 12.40998124,
		pop: 523750,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Sachsen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Stralsund',
		city_ascii: 'Stralsund',
		lat: 54.30041811,
		lng: 13.10001664,
		pop: 60172,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Mecklenburg-Vorpommern',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Rostock',
		city_ascii: 'Rostock',
		lat: 54.07038047,
		lng: 12.14999711,
		pop: 200686.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Mecklenburg-Vorpommern',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Stuttgart',
		city_ascii: 'Stuttgart',
		lat: 48.77997988,
		lng: 9.199996296,
		pop: 1775644,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Baden-Württemberg',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Bremen',
		city_ascii: 'Bremen',
		lat: 53.08000165,
		lng: 8.80002071,
		pop: 635705,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Bremen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Nürnberg',
		city_ascii: 'Nurnberg',
		lat: 49.44999066,
		lng: 11.0799849,
		pop: 618270.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Bayern',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Cologne',
		city_ascii: 'Cologne',
		lat: 50.93000368,
		lng: 6.950004434,
		pop: 983697.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Nordrhein-Westfalen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Dresden',
		city_ascii: 'Dresden',
		lat: 51.04997052,
		lng: 13.75000281,
		pop: 552184.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Sachsen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Frankfurt',
		city_ascii: 'Frankfurt',
		lat: 50.09997683,
		lng: 8.67501542,
		pop: 1787332,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Hessen',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Hamburg',
		city_ascii: 'Hamburg',
		lat: 53.55002464,
		lng: 9.999999144,
		pop: 1748058.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Hamburg',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Munich',
		city_ascii: 'Munich',
		lat: 48.12994204,
		lng: 11.57499345,
		pop: 1267695.5,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Bayern',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Berlin',
		city_ascii: 'Berlin',
		lat: 52.52181866,
		lng: 13.40154862,
		pop: 3250007,
		country: 'Germany',
		iso2: 'DE',
		iso3: 'DEU',
		province: 'Berlin',
		timezone: 'Europe/Berlin',
	},
	{
		city: 'Sunyani',
		city_ascii: 'Sunyani',
		lat: 7.335998991,
		lng: -2.336003416,
		pop: 70299,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Brong Ahafo',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Tamale',
		city_ascii: 'Tamale',
		lat: 9.400419738,
		lng: -0.83998519,
		pop: 342871,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Northern',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Yendi',
		city_ascii: 'Yendi',
		lat: 9.433725198,
		lng: -0.016650433,
		pop: 30841.5,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Northern',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Bolgatanga',
		city_ascii: 'Bolgatanga',
		lat: 10.79038658,
		lng: -0.84998458,
		pop: 68303.5,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Upper East',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Bawku',
		city_ascii: 'Bawku',
		lat: 11.06039593,
		lng: -0.239995973,
		pop: 65212,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Upper East',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Wa',
		city_ascii: 'Wa',
		lat: 10.06040529,
		lng: -2.500013063,
		pop: 76891.5,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Upper West',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Obuasi',
		city_ascii: 'Obuasi',
		lat: 6.190408955,
		lng: -1.659986818,
		pop: 158986.5,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Ashanti',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Berekum',
		city_ascii: 'Berekum',
		lat: 7.450383727,
		lng: -2.59000757,
		pop: 36484.5,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Brong Ahafo',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Winneba',
		city_ascii: 'Winneba',
		lat: 5.350434386,
		lng: -0.630049684,
		pop: 41911,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Central',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Cape Coast',
		city_ascii: 'Cape Coast',
		lat: 5.11037152,
		lng: -1.249986004,
		pop: 139265.5,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Central',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Nkawkaw',
		city_ascii: 'Nkawkaw',
		lat: 6.550464497,
		lng: -0.780014691,
		pop: 54914.5,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Eastern',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Koforidua',
		city_ascii: 'Koforidua',
		lat: 6.090415058,
		lng: -0.260020591,
		pop: 126459.5,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Eastern',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Tema',
		city_ascii: 'Tema',
		lat: 5.640365009,
		lng: 0.010014606,
		pop: 184969.5,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Greater Accra',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Ho',
		city_ascii: 'Ho',
		lat: 6.600409769,
		lng: 0.46998653,
		pop: 81521,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Volta',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Kumasi',
		city_ascii: 'Kumasi',
		lat: 6.689990864,
		lng: -1.630014487,
		pop: 1468575.5,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Ashanti',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Sekondi',
		city_ascii: 'Sekondi',
		lat: 4.943275776,
		lng: -1.704015138,
		pop: 212560,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Western',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Accra',
		city_ascii: 'Accra',
		lat: 5.550034606,
		lng: -0.21671574,
		pop: 2042132,
		country: 'Ghana',
		iso2: 'GH',
		iso3: 'GHA',
		province: 'Greater Accra',
		timezone: 'Africa/Accra',
	},
	{
		city: 'Gibraltar',
		city_ascii: 'Gibraltar',
		lat: 36.13243495,
		lng: -5.37807483,
		pop: 106813.5,
		country: 'Gibraltar',
		iso2: 'GI',
		iso3: 'GIB',
		province: 'Gibraltar',
		timezone: 'Europe/Gibraltar',
	},
	{
		city: 'Lamia',
		city_ascii: 'Lamia',
		lat: 38.89899915,
		lng: 22.43400358,
		pop: 47246,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Stereá Elláda',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Polygyros',
		city_ascii: 'Polygyros',
		lat: 40.38100213,
		lng: 23.45300148,
		pop: 5258,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Kentriki Makedonia',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Komatini',
		city_ascii: 'Komatini',
		lat: 41.13330309,
		lng: 25.41670256,
		pop: 45631,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Anatoliki Makedonia kai Thraki',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Piraiévs',
		city_ascii: 'Piraievs',
		lat: 37.95002077,
		lng: 23.69998979,
		pop: 320881,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Attiki',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Volos',
		city_ascii: 'Volos',
		lat: 39.36995994,
		lng: 22.95000972,
		pop: 97528.5,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Thessalia',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Hania',
		city_ascii: 'Hania',
		lat: 35.51221092,
		lng: 24.01557776,
		pop: 66646.5,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Kriti',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Kavala',
		city_ascii: 'Kavala',
		lat: 40.94121113,
		lng: 24.40176036,
		pop: 56095,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Anatoliki Makedonia kai Thraki',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Alexandroupoli',
		city_ascii: 'Alexandroupoli',
		lat: 40.84861937,
		lng: 25.87440978,
		pop: 48711.5,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Anatoliki Makedonia kai Thraki',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Kerkira',
		city_ascii: 'Kerkira',
		lat: 39.61542299,
		lng: 19.9147428,
		pop: 32760,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Ionioi Nisoi',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Tripoli',
		city_ascii: 'Tripoli',
		lat: 37.50914329,
		lng: 22.37939856,
		pop: 27765.5,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Peloponnisos',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Sparti',
		city_ascii: 'Sparti',
		lat: 37.07371767,
		lng: 22.42967973,
		pop: 15842,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Peloponnisos',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Agrinio',
		city_ascii: 'Agrinio',
		lat: 38.62176271,
		lng: 21.4077266,
		pop: 59379,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Dytiki Ellada',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Pirgos',
		city_ascii: 'Pirgos',
		lat: 37.68373212,
		lng: 21.44999792,
		pop: 22311,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Dytiki Ellada',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Larissa',
		city_ascii: 'Larissa',
		lat: 39.63040916,
		lng: 22.42001623,
		pop: 120122.5,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Thessalia',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Ioanina',
		city_ascii: 'Ioanina',
		lat: 39.66790041,
		lng: 20.85086137,
		pop: 75158,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Ipeiros',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Mitilini',
		city_ascii: 'Mitilini',
		lat: 39.11041506,
		lng: 26.55464758,
		pop: 28825,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Voreio Aigaio',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Hios',
		city_ascii: 'Hios',
		lat: 38.36810895,
		lng: 26.1358101,
		pop: 25422,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Voreio Aigaio',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Chalkida',
		city_ascii: 'Chalkida',
		lat: 38.46399457,
		lng: 23.61239823,
		pop: 63200,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Stereá Elláda',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Sitia',
		city_ascii: 'Sitia',
		lat: 35.20042116,
		lng: 26.09855139,
		pop: 8770,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Kriti',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Katerini',
		city_ascii: 'Katerini',
		lat: 40.2723338,
		lng: 22.50249182,
		pop: 50850.5,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Kentriki Makedonia',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Seres',
		city_ascii: 'Seres',
		lat: 41.08597923,
		lng: 23.54971472,
		pop: 50910.5,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Kentriki Makedonia',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Xanthi',
		city_ascii: 'Xanthi',
		lat: 41.14178977,
		lng: 24.88358679,
		pop: 49395.5,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Anatoliki Makedonia kai Thraki',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Ermoupoli',
		city_ascii: 'Ermoupoli',
		lat: 37.45041302,
		lng: 24.93329952,
		pop: 12260,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Notio Aigaio',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Kos',
		city_ascii: 'Kos',
		lat: 36.89372866,
		lng: 27.28881466,
		pop: 18967.5,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Notio Aigaio',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Rodos',
		city_ascii: 'Rodos',
		lat: 36.44122398,
		lng: 28.22250443,
		pop: 56548.5,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Notio Aigaio',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Patra',
		city_ascii: 'Patra',
		lat: 38.23000368,
		lng: 21.72998083,
		pop: 159579.5,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Dytiki Ellada',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Kalamata',
		city_ascii: 'Kalamata',
		lat: 37.03891359,
		lng: 22.11419511,
		pop: 61465.5,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Peloponnisos',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Iraklio',
		city_ascii: 'Iraklio',
		lat: 35.32501304,
		lng: 25.13049678,
		pop: 134404,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Kriti',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Thessaloniki',
		city_ascii: 'Thessaloniki',
		lat: 40.69610638,
		lng: 22.88500077,
		pop: 591145,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Kentriki Makedonia',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Athens',
		city_ascii: 'Athens',
		lat: 37.98332623,
		lng: 23.73332108,
		pop: 1985568.5,
		country: 'Greece',
		iso2: 'GR',
		iso3: 'GRC',
		province: 'Attiki',
		timezone: 'Europe/Athens',
	},
	{
		city: 'Qasigiannguit',
		city_ascii: 'Qasigiannguit',
		lat: 68.81927014,
		lng: -51.17851854,
		pop: 1341,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Qaasuitsup Kommunia',
		timezone: 'America/Godthab',
	},
	{
		city: 'Kullorsuaq',
		city_ascii: 'Kullorsuaq',
		lat: 74.57806289,
		lng: -57.22498603,
		pop: 443,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: '',
		timezone: 'America/Godthab',
	},
	{
		city: 'Tasiusaq',
		city_ascii: 'Tasiusaq',
		lat: 73.36900226,
		lng: -56.05981366,
		pop: 249,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: '',
		timezone: 'America/Godthab',
	},
	{
		city: 'Kulusuk',
		city_ascii: 'Kulusuk',
		lat: 65.56657798,
		lng: -37.18332239,
		pop: 286,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Kommuneqarfik Sermersooq',
		timezone: 'America/Godthab',
	},
	{
		city: 'Paamiut',
		city_ascii: 'Paamiut',
		lat: 62.00410237,
		lng: -49.63507012,
		pop: 1862,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Kommuneqarfik Sermersooq',
		timezone: 'America/Godthab',
	},
	{
		city: 'Ittoqqortoormiit',
		city_ascii: 'Ittoqqortoormiit',
		lat: 70.48335797,
		lng: -21.9666543,
		pop: 469,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Kommuneqarfik Sermersooq',
		timezone: 'America/Danmarkshavn',
	},
	{
		city: 'Timmiarmiut',
		city_ascii: 'Timmiarmiut',
		lat: 62.53327476,
		lng: -42.21671025,
		pop: 10,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Kommune Kujalleq',
		timezone: 'America/Godthab',
	},
	{
		city: 'Qaqortoq',
		city_ascii: 'Qaqortoq',
		lat: 60.72890973,
		lng: -46.03641168,
		pop: 3224,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Kommune Kujalleq',
		timezone: 'America/Godthab',
	},
	{
		city: 'Kangerlussuaq',
		city_ascii: 'Kangerlussuaq',
		lat: 67.01412249,
		lng: -50.70990925,
		pop: 556,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Qeqqata Kommunia',
		timezone: 'America/Godthab',
	},
	{
		city: 'Nord',
		city_ascii: 'Nord',
		lat: 81.71662579,
		lng: -17.80003524,
		pop: 10,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Nationalparken',
		timezone: 'America/Godthab',
	},
	{
		city: 'Qeqertasuaq',
		city_ascii: 'Qeqertasuaq',
		lat: 69.24416391,
		lng: -53.56523854,
		pop: 7.5,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Qaasuitsup Kommunia',
		timezone: 'America/Godthab',
	},
	{
		city: 'Nuussuaq',
		city_ascii: 'Nuussuaq',
		lat: 74.12096872,
		lng: -57.06736051,
		pop: 204,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Qaasuitsup Kommunia',
		timezone: 'America/Godthab',
	},
	{
		city: 'Ilulissat',
		city_ascii: 'Ilulissat',
		lat: 69.21666526,
		lng: -51.09996647,
		pop: 4413,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Qaasuitsup Kommunia',
		timezone: 'America/Godthab',
	},
	{
		city: 'Tasiilaq',
		city_ascii: 'Tasiilaq',
		lat: 65.60186934,
		lng: -37.6337371,
		pop: 1829,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: '',
		timezone: 'America/Godthab',
	},
	{
		city: 'Savissivik',
		city_ascii: 'Savissivik',
		lat: 76.01947624,
		lng: -65.11248065,
		pop: 66,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: '',
		timezone: 'America/Thule',
	},
	{
		city: 'Kangersuatsiaq',
		city_ascii: 'Kangersuatsiaq',
		lat: 72.37963955,
		lng: -55.54911893,
		pop: 193,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: '',
		timezone: 'America/Godthab',
	},
	{
		city: 'Uummannaq',
		city_ascii: 'Uummannaq',
		lat: 70.67502343,
		lng: -52.12293443,
		pop: 1299,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: '',
		timezone: 'America/Godthab',
	},
	{
		city: 'Narsarsuaq',
		city_ascii: 'Narsarsuaq',
		lat: 61.16658815,
		lng: -45.41661829,
		pop: 145,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Kommune Kujalleq',
		timezone: 'America/Godthab',
	},
	{
		city: 'Sisimiut',
		city_ascii: 'Sisimiut',
		lat: 66.95000775,
		lng: -53.66660567,
		pop: 5227,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Qeqqata Kommunia',
		timezone: 'America/Godthab',
	},
	{
		city: 'Upernavik',
		city_ascii: 'Upernavik',
		lat: 72.70936669,
		lng: -56.14167532,
		pop: 1129,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Qaasuitsup Kommunia',
		timezone: 'America/Godthab',
	},
	{
		city: 'Qaanaaq',
		city_ascii: 'Qaanaaq',
		lat: 77.48347333,
		lng: -69.33223861,
		pop: 437,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Qaasuitsup Kommunia',
		timezone: 'America/Thule',
	},
	{
		city: 'Nuuk',
		city_ascii: 'Nuuk',
		lat: 64.19828147,
		lng: -51.73265879,
		pop: 14798,
		country: 'Greenland',
		iso2: 'GL',
		iso3: 'GRL',
		province: 'Kommuneqarfik Sermersooq',
		timezone: 'America/Godthab',
	},
	{
		city: "Saint George's",
		city_ascii: "Saint George's",
		lat: 12.0526334,
		lng: -61.74164323,
		pop: 30538.5,
		country: 'Grenada',
		iso2: 'GD',
		iso3: 'GRD',
		province: '',
		timezone: 'America/Grenada',
	},
	{
		city: 'Agana',
		city_ascii: 'Agana',
		lat: 13.4700163,
		lng: 144.750017,
		pop: 61755.5,
		country: 'Guam',
		iso2: 'GU',
		iso3: 'GUM',
		province: '',
		timezone: 'Pacific/Guam',
	},
	{
		city: 'Salama',
		city_ascii: 'Salama',
		lat: 15.10299903,
		lng: -90.31400061,
		pop: 40000,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Baja Verapaz',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Retalhuleu',
		city_ascii: 'Retalhuleu',
		lat: 14.53709704,
		lng: -91.67701454,
		pop: 36656,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Retalhuleu',
		timezone: 'America/Guatemala',
	},
	{
		city: 'San Marcos',
		city_ascii: 'San Marcos',
		lat: 14.96600106,
		lng: -91.79999952,
		pop: 25088,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'San Marcos',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Chimaltenango',
		city_ascii: 'Chimaltenango',
		lat: 14.66199914,
		lng: -90.8199985,
		pop: 82370,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Chimaltenango',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Antigua Guatemala',
		city_ascii: 'Antigua Guatemala',
		lat: 14.5666981,
		lng: -90.73330161,
		pop: 39368,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Sacatepéquez',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Solola',
		city_ascii: 'Solola',
		lat: 14.77299598,
		lng: -91.18299551,
		pop: 45373,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Sololá',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Totonicapan',
		city_ascii: 'Totonicapan',
		lat: 14.91399898,
		lng: -91.35800061,
		pop: 69734,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Totonicapán',
		timezone: 'America/Guatemala',
	},
	{
		city: 'El Progreso',
		city_ascii: 'El Progreso',
		lat: 14.85,
		lng: -90.01670359,
		pop: 147197,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'El Progreso',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Cuilapa',
		city_ascii: 'Cuilapa',
		lat: 14.27900409,
		lng: -90.2979985,
		pop: 16484,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Santa Rosa',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Chiquimula',
		city_ascii: 'Chiquimula',
		lat: 14.79699906,
		lng: -89.54399653,
		pop: 41521,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Chiquimula',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Jalapa',
		city_ascii: 'Jalapa',
		lat: 14.63300111,
		lng: -89.98900162,
		pop: 45834,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Jalapa',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Zacapa',
		city_ascii: 'Zacapa',
		lat: 14.97200398,
		lng: -89.52900256,
		pop: 36088,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Zacapa',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Santa Cruz Del Quiche',
		city_ascii: 'Santa Cruz Del Quiche',
		lat: 15.0333031,
		lng: -91.13329763,
		pop: 23618,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Quiché',
		timezone: 'America/Guatemala',
	},
	{
		city: 'San Luis',
		city_ascii: 'San Luis',
		lat: 16.2000047,
		lng: -89.4400035,
		pop: 69623,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Petén',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Coban',
		city_ascii: 'Coban',
		lat: 15.46999758,
		lng: -90.3799978,
		pop: 59284.5,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Alta Verapaz',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Livingston',
		city_ascii: 'Livingston',
		lat: 15.83065388,
		lng: -88.75623452,
		pop: 12302.5,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Izabal',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Jutiapa',
		city_ascii: 'Jutiapa',
		lat: 14.28999208,
		lng: -89.90000126,
		pop: 42506.5,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Jutiapa',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Huehuetenango',
		city_ascii: 'Huehuetenango',
		lat: 15.32039431,
		lng: -91.46998295,
		pop: 82709,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Huehuetenango',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Flores',
		city_ascii: 'Flores',
		lat: 16.93368085,
		lng: -89.88328394,
		pop: 29249.5,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Petén',
		timezone: 'America/Guatemala',
	},
	{
		city: 'La Libertad',
		city_ascii: 'La Libertad',
		lat: 16.78043439,
		lng: -90.11998784,
		pop: 8584.5,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Petén',
		timezone: 'America/Guatemala',
	},
	{
		city: 'San Jose',
		city_ascii: 'San Jose',
		lat: 13.93972958,
		lng: -90.82003203,
		pop: 14248,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Escuintla',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Escuintla',
		city_ascii: 'Escuintla',
		lat: 14.30040489,
		lng: -90.77999923,
		pop: 105401.5,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Escuintla',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Mazatenango',
		city_ascii: 'Mazatenango',
		lat: 14.53036501,
		lng: -91.50998051,
		pop: 54392,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Suchitepéquez',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Puerto Barrios',
		city_ascii: 'Puerto Barrios',
		lat: 15.71749529,
		lng: -88.59265184,
		pop: 54877.5,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Izabal',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Quetzaltenango',
		city_ascii: 'Quetzaltenango',
		lat: 14.82995913,
		lng: -91.52000574,
		pop: 399983,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Quezaltenango',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Guatemala',
		city_ascii: 'Guatemala',
		lat: 14.62113466,
		lng: -90.52696558,
		pop: 1009469,
		country: 'Guatemala',
		iso2: 'GT',
		iso3: 'GTM',
		province: 'Guatemala',
		timezone: 'America/Guatemala',
	},
	{
		city: 'Mali',
		city_ascii: 'Mali',
		lat: 12.08400302,
		lng: -12.30100143,
		pop: 5479,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Labe',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Tongue',
		city_ascii: 'Tongue',
		lat: 11.43999904,
		lng: -11.67000248,
		pop: 25531,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Labe',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Kouroussa',
		city_ascii: 'Kouroussa',
		lat: 10.6530031,
		lng: -9.891998549,
		pop: 14223,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Kankan',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Pita',
		city_ascii: 'Pita',
		lat: 11.0799991,
		lng: -12.40100056,
		pop: 20052,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Mamou',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Dalaba',
		city_ascii: 'Dalaba',
		lat: 10.65600001,
		lng: -12.27200357,
		pop: 6349,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Mamou',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Boffa',
		city_ascii: 'Boffa',
		lat: 10.18500307,
		lng: -14.04299645,
		pop: 2332,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Boke',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Koundara',
		city_ascii: 'Koundara',
		lat: 12.4800031,
		lng: -13.29599643,
		pop: 13990,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Boke',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Gaoual',
		city_ascii: 'Gaoual',
		lat: 11.75400014,
		lng: -13.21299843,
		pop: 7461,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Boke',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Telimele',
		city_ascii: 'Telimele',
		lat: 10.90500311,
		lng: -13.04299756,
		pop: 30311,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Kindia',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Forecariah',
		city_ascii: 'Forecariah',
		lat: 9.430002076,
		lng: -13.09799655,
		pop: 12358,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Kindia',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Beyla',
		city_ascii: 'Beyla',
		lat: 8.686997987,
		lng: -8.657000454,
		pop: 13204,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Nzerekore',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Gueckedou',
		city_ascii: 'Gueckedou',
		lat: 8.553996122,
		lng: -10.1510005,
		pop: 221715,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Nzerekore',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Dinguiraye',
		city_ascii: 'Dinguiraye',
		lat: 11.29899604,
		lng: -10.72600144,
		pop: 6062,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Faranah',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Dabola',
		city_ascii: 'Dabola',
		lat: 10.747998,
		lng: -11.10899649,
		pop: 13057,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Faranah',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Kerouane',
		city_ascii: 'Kerouane',
		lat: 9.270375996,
		lng: -9.019976849,
		pop: 11317,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Kankan',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Siguiri',
		city_ascii: 'Siguiri',
		lat: 11.41709251,
		lng: -9.166634564,
		pop: 46880,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Kankan',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Mamou',
		city_ascii: 'Mamou',
		lat: 10.38038576,
		lng: -12.1000214,
		pop: 56386,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Mamou',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Kamsar',
		city_ascii: 'Kamsar',
		lat: 10.6854059,
		lng: -14.60501062,
		pop: 34973,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Boke',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Fria',
		city_ascii: 'Fria',
		lat: 10.38038576,
		lng: -13.54998458,
		pop: 23729,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Boke',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Macenta',
		city_ascii: 'Macenta',
		lat: 8.550368265,
		lng: -9.480000449,
		pop: 42167.5,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Nzerekore',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Yomou',
		city_ascii: 'Yomou',
		lat: 7.570428079,
		lng: -9.269987428,
		pop: 2607.5,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Nzerekore',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Faranah',
		city_ascii: 'Faranah',
		lat: 10.04040651,
		lng: -10.75000045,
		pop: 14409.5,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Faranah',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Kissidougou',
		city_ascii: 'Kissidougou',
		lat: 9.190458393,
		lng: -10.12001306,
		pop: 56957,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Faranah',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Labe',
		city_ascii: 'Labe',
		lat: 11.31999249,
		lng: -12.3000092,
		pop: 99612,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Labe',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Boke',
		city_ascii: 'Boke',
		lat: 10.93998985,
		lng: -14.29999048,
		pop: 116270,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Boke',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Kindia',
		city_ascii: 'Kindia',
		lat: 10.06001772,
		lng: -12.86997441,
		pop: 93511,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Kindia',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Kankan',
		city_ascii: 'Kankan',
		lat: 10.38999758,
		lng: -9.310010825,
		pop: 110457.5,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Kankan',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Nzerekore',
		city_ascii: 'Nzerekore',
		lat: 7.760003071,
		lng: -8.829988445,
		pop: 150424.5,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Nzerekore',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Conakry',
		city_ascii: 'Conakry',
		lat: 9.531522846,
		lng: -13.68023503,
		pop: 1494000,
		country: 'Guinea',
		iso2: 'GN',
		iso3: 'GIN',
		province: 'Conakry',
		timezone: 'Africa/Conakry',
	},
	{
		city: 'Cacheu',
		city_ascii: 'Cacheu',
		lat: 12.26899803,
		lng: -16.16499854,
		pop: 10490,
		country: 'Guinea Bissau',
		iso2: 'GW',
		iso3: 'GNB',
		province: 'Cacheu',
		timezone: 'Africa/Bissau',
	},
	{
		city: 'Farim',
		city_ascii: 'Farim',
		lat: 12.49299903,
		lng: -15.22700042,
		pop: 6792,
		country: 'Guinea Bissau',
		iso2: 'GW',
		iso3: 'GNB',
		province: 'Oio',
		timezone: 'Africa/Bissau',
	},
	{
		city: 'Fulacunda',
		city_ascii: 'Fulacunda',
		lat: 11.77299899,
		lng: -15.19499654,
		pop: 1311,
		country: 'Guinea Bissau',
		iso2: 'GW',
		iso3: 'GNB',
		province: 'Quinara',
		timezone: 'Africa/Bissau',
	},
	{
		city: 'Gabu',
		city_ascii: 'Gabu',
		lat: 12.27999608,
		lng: -14.23400348,
		pop: 14430,
		country: 'Guinea Bissau',
		iso2: 'GW',
		iso3: 'GNB',
		province: 'Gabú',
		timezone: 'Africa/Bissau',
	},
	{
		city: 'Catio',
		city_ascii: 'Catio',
		lat: 11.21670002,
		lng: -15.16670049,
		pop: 9898,
		country: 'Guinea Bissau',
		iso2: 'GW',
		iso3: 'GNB',
		province: 'Tombali',
		timezone: 'Africa/Bissau',
	},
	{
		city: 'Bolama',
		city_ascii: 'Bolama',
		lat: 11.58295303,
		lng: -15.48280399,
		pop: 9179,
		country: 'Guinea Bissau',
		iso2: 'GW',
		iso3: 'GNB',
		province: 'Bolama',
		timezone: 'Africa/Bissau',
	},
	{
		city: 'Bafata',
		city_ascii: 'Bafata',
		lat: 12.16699506,
		lng: -14.66601465,
		pop: 26112.5,
		country: 'Guinea Bissau',
		iso2: 'GW',
		iso3: 'GNB',
		province: 'Bafatá',
		timezone: 'Africa/Bissau',
	},
	{
		city: 'Bissau',
		city_ascii: 'Bissau',
		lat: 11.86502382,
		lng: -15.59836084,
		pop: 395683.5,
		country: 'Guinea Bissau',
		iso2: 'GW',
		iso3: 'GNB',
		province: 'Bissau',
		timezone: 'Africa/Bissau',
	},
	{
		city: 'Corriverton',
		city_ascii: 'Corriverton',
		lat: 5.900039082,
		lng: -57.16998356,
		pop: 11787,
		country: 'Guyana',
		iso2: 'GY',
		iso3: 'GUY',
		province: 'Mahaica-Berbice',
		timezone: 'America/Guyana',
	},
	{
		city: 'Ituni',
		city_ascii: 'Ituni',
		lat: 5.430016092,
		lng: -58.24999516,
		pop: 75,
		country: 'Guyana',
		iso2: 'GY',
		iso3: 'GUY',
		province: 'Upper Takutu-Upper Essequibo',
		timezone: 'America/Guyana',
	},
	{
		city: 'Lethem',
		city_ascii: 'Lethem',
		lat: 3.389959736,
		lng: -59.80002975,
		pop: 352,
		country: 'Guyana',
		iso2: 'GY',
		iso3: 'GUY',
		province: 'Upper Demerara-Berbice',
		timezone: 'America/Guyana',
	},
	{
		city: 'Kumaka',
		city_ascii: 'Kumaka',
		lat: 3.900393696,
		lng: -58.38001306,
		pop: 1467.5,
		country: 'Guyana',
		iso2: 'GY',
		iso3: 'GUY',
		province: 'Upper Demerara-Berbice',
		timezone: 'America/Guyana',
	},
	{
		city: 'Bartica',
		city_ascii: 'Bartica',
		lat: 6.410421365,
		lng: -58.63002364,
		pop: 11340.5,
		country: 'Guyana',
		iso2: 'GY',
		iso3: 'GUY',
		province: 'Pomeroon-Supenaam',
		timezone: 'America/Guyana',
	},
	{
		city: 'Anna Regina',
		city_ascii: 'Anna Regina',
		lat: 7.270420551,
		lng: -58.50005742,
		pop: 3113,
		country: 'Guyana',
		iso2: 'GY',
		iso3: 'GUY',
		province: 'Cuyuni-Mazaruni',
		timezone: 'America/Guyana',
	},
	{
		city: 'Linden',
		city_ascii: 'Linden',
		lat: 5.99000775,
		lng: -58.2699681,
		pop: 28041.5,
		country: 'Guyana',
		iso2: 'GY',
		iso3: 'GUY',
		province: 'Upper Takutu-Upper Essequibo',
		timezone: 'America/Guyana',
	},
	{
		city: 'Mabaruma',
		city_ascii: 'Mabaruma',
		lat: 8.199976216,
		lng: -59.77997929,
		pop: 2972,
		country: 'Guyana',
		iso2: 'GY',
		iso3: 'GUY',
		province: 'Barima-Waini',
		timezone: 'America/Guyana',
	},
	{
		city: 'New Amsterdam',
		city_ascii: 'New Amsterdam',
		lat: 6.250017719,
		lng: -57.52998743,
		pop: 40956.5,
		country: 'Guyana',
		iso2: 'GY',
		iso3: 'GUY',
		province: 'Essequibo Islands-West Demerara',
		timezone: 'America/Guyana',
	},
	{
		city: 'Georgetown',
		city_ascii: 'Georgetown',
		lat: 6.801973693,
		lng: -58.16702865,
		pop: 249683.5,
		country: 'Guyana',
		iso2: 'GY',
		iso3: 'GUY',
		province: 'East Berbice-Corentyne',
		timezone: 'America/Guyana',
	},
	{
		city: 'Jeremie',
		city_ascii: 'Jeremie',
		lat: 18.63393473,
		lng: -74.11842526,
		pop: 30917,
		country: 'Haiti',
		iso2: 'HT',
		iso3: 'HTI',
		province: "Grand'Anse",
		timezone: 'America/Port-au-Prince',
	},
	{
		city: 'Port-De-Paix',
		city_ascii: 'Port-De-Paix',
		lat: 19.93176008,
		lng: -72.82948452,
		pop: 34657,
		country: 'Haiti',
		iso2: 'HT',
		iso3: 'HTI',
		province: 'Nord-Ouest',
		timezone: 'America/Port-au-Prince',
	},
	{
		city: 'Hinche',
		city_ascii: 'Hinche',
		lat: 19.14300009,
		lng: -72.0039956,
		pop: 18590,
		country: 'Haiti',
		iso2: 'HT',
		iso3: 'HTI',
		province: 'Centre',
		timezone: 'America/Port-au-Prince',
	},
	{
		city: 'Fort-Liberte',
		city_ascii: 'Fort-Liberte',
		lat: 19.66556703,
		lng: -71.84483954,
		pop: 11465,
		country: 'Haiti',
		iso2: 'HT',
		iso3: 'HTI',
		province: 'Nord-Est',
		timezone: 'America/Port-au-Prince',
	},
	{
		city: 'Jacmel',
		city_ascii: 'Jacmel',
		lat: 18.23499903,
		lng: -72.53700258,
		pop: 33563,
		country: 'Haiti',
		iso2: 'HT',
		iso3: 'HTI',
		province: 'Sud-Est',
		timezone: 'America/Port-au-Prince',
	},
	{
		city: 'Les Cayes',
		city_ascii: 'Les Cayes',
		lat: 18.20037355,
		lng: -73.74997929,
		pop: 122728.5,
		country: 'Haiti',
		iso2: 'HT',
		iso3: 'HTI',
		province: 'Sud',
		timezone: 'America/Port-au-Prince',
	},
	{
		city: 'Gonaives',
		city_ascii: 'Gonaives',
		lat: 19.45042645,
		lng: -72.68324854,
		pop: 125819.5,
		country: 'Haiti',
		iso2: 'HT',
		iso3: 'HTI',
		province: "L'Artibonite",
		timezone: 'America/Port-au-Prince',
	},
	{
		city: 'Cap-Haitien',
		city_ascii: 'Cap-Haitien',
		lat: 19.7592224,
		lng: -72.21251602,
		pop: 208151,
		country: 'Haiti',
		iso2: 'HT',
		iso3: 'HTI',
		province: 'Nord',
		timezone: 'America/Port-au-Prince',
	},
	{
		city: 'Port-au-Prince',
		city_ascii: 'Port-au-Prince',
		lat: 18.5410246,
		lng: -72.33603459,
		pop: 1616371,
		country: 'Haiti',
		iso2: 'HT',
		iso3: 'HTI',
		province: 'Ouest',
		timezone: 'America/Port-au-Prince',
	},
	{
		city: 'Yoro',
		city_ascii: 'Yoro',
		lat: 15.05999711,
		lng: -87.29000054,
		pop: 15774,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Yoro',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'La Esperanza',
		city_ascii: 'La Esperanza',
		lat: 14.33300406,
		lng: -88.16669954,
		pop: 5318,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Intibucá',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'La Paz',
		city_ascii: 'La Paz',
		lat: 14.31999903,
		lng: -87.68400257,
		pop: 17555,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'La Paz',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'Santa Barbara',
		city_ascii: 'Santa Barbara',
		lat: 14.91900304,
		lng: -88.23599963,
		pop: 15119,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Santa Bárbara',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'Gracias',
		city_ascii: 'Gracias',
		lat: 14.58330306,
		lng: -88.58330051,
		pop: 7909,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Lempira',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'Nueva Ocotepeque',
		city_ascii: 'Nueva Ocotepeque',
		lat: 14.43699912,
		lng: -89.18199855,
		pop: 8780,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Ocotepeque',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'Yuscaran',
		city_ascii: 'Yuscaran',
		lat: 13.94399699,
		lng: -86.85099854,
		pop: 2371,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'El Paraíso',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'Roatan',
		city_ascii: 'Roatan',
		lat: 16.32999599,
		lng: -86.51899761,
		pop: 7514,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Islas de la Bahía',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'Nacaome',
		city_ascii: 'Nacaome',
		lat: 13.5299868,
		lng: -87.48996749,
		pop: 30464.5,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Valle',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'Santa Rosa de Copan',
		city_ascii: 'Santa Rosa de Copan',
		lat: 14.76998863,
		lng: -88.77999211,
		pop: 31641,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Copán',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'Trujillo',
		city_ascii: 'Trujillo',
		lat: 15.9103583,
		lng: -85.9600092,
		pop: 7366.5,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Colón',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'Brus Laguna',
		city_ascii: 'Brus Laguna',
		lat: 15.75041974,
		lng: -84.47999618,
		pop: 4067,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Gracias a Dios',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'Puerto Lempira',
		city_ascii: 'Puerto Lempira',
		lat: 15.26202578,
		lng: -83.77164148,
		pop: 4760,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Gracias a Dios',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'Juticalpa',
		city_ascii: 'Juticalpa',
		lat: 14.67040814,
		lng: -86.22996688,
		pop: 35564,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Olancho',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'Comayagua',
		city_ascii: 'Comayagua',
		lat: 14.46039512,
		lng: -87.64998356,
		pop: 64963,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Comayagua',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'Choluteca',
		city_ascii: 'Choluteca',
		lat: 13.30067263,
		lng: -87.19081262,
		pop: 87650.5,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Choluteca',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'La Ceiba',
		city_ascii: 'La Ceiba',
		lat: 15.7631063,
		lng: -86.79698653,
		pop: 138072,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Atlántida',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'San Pedro Sula',
		city_ascii: 'San Pedro Sula',
		lat: 15.50002159,
		lng: -88.02998621,
		pop: 584778.5,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Cortés',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'Tegucigalpa',
		city_ascii: 'Tegucigalpa',
		lat: 14.1020449,
		lng: -87.21752934,
		pop: 898424,
		country: 'Honduras',
		iso2: 'HN',
		iso3: 'HND',
		province: 'Francisco Morazán',
		timezone: 'America/Tegucigalpa',
	},
	{
		city: 'Hong Kong',
		city_ascii: 'Hong Kong',
		lat: 22.3049809,
		lng: 114.1850093,
		pop: 5878789.5,
		country: 'Hong Kong S.A.R.',
		iso2: 'HK',
		iso3: 'HKG',
		province: '',
		timezone: 'Asia/Hong_Kong',
	},
	{
		city: 'Veszprem',
		city_ascii: 'Veszprem',
		lat: 47.09099714,
		lng: 17.91099957,
		pop: 62023,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Veszprém',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Zalaegerszeg',
		city_ascii: 'Zalaegerszeg',
		lat: 46.84400103,
		lng: 16.83999959,
		pop: 61898,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Zala',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Tatabanya',
		city_ascii: 'Tatabanya',
		lat: 47.54999718,
		lng: 18.43299957,
		pop: 70541,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Komárom-Esztergom',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Szekszard',
		city_ascii: 'Szekszard',
		lat: 46.34399711,
		lng: 18.71299858,
		pop: 34174,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Tolna',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Salgotarjan',
		city_ascii: 'Salgotarjan',
		lat: 48.10500008,
		lng: 19.82600163,
		pop: 39640,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Nógrád',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Bekescsaba',
		city_ascii: 'Bekescsaba',
		lat: 46.67200211,
		lng: 21.10100457,
		pop: 65206,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Békés',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Eger',
		city_ascii: 'Eger',
		lat: 47.89500314,
		lng: 20.38300258,
		pop: 56647,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Heves',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Szombathely',
		city_ascii: 'Szombathely',
		lat: 47.22534609,
		lng: 16.62874182,
		pop: 94526,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Vas',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Kecskemet',
		city_ascii: 'Kecskemet',
		lat: 46.90004295,
		lng: 19.70002722,
		pop: 111871,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Bács-Kiskun',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Szekesfehervar',
		city_ascii: 'Szekesfehervar',
		lat: 47.19467613,
		lng: 18.40806474,
		pop: 122959.5,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Fejér',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Nyiregyhaza',
		city_ascii: 'Nyiregyhaza',
		lat: 47.96532676,
		lng: 21.71871537,
		pop: 146589,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Szabolcs-Szatmár-Bereg',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Pecs',
		city_ascii: 'Pecs',
		lat: 46.08042889,
		lng: 18.2200142,
		pop: 171455.5,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Baranya',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Gyor',
		city_ascii: 'Gyor',
		lat: 47.70035585,
		lng: 17.63002437,
		pop: 132173,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Gyor-Moson-Sopron',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Kaposvar',
		city_ascii: 'Kaposvar',
		lat: 46.36702639,
		lng: 17.79998816,
		pop: 88137,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Somogy',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Vac',
		city_ascii: 'Vac',
		lat: 47.78365826,
		lng: 19.13324011,
		pop: 35200.5,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Pest',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Miskolc',
		city_ascii: 'Miskolc',
		lat: 48.10040896,
		lng: 20.78001298,
		pop: 210197,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Borsod-Abaúj-Zemplén',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Szeged',
		city_ascii: 'Szeged',
		lat: 46.25039268,
		lng: 20.15002559,
		pop: 176324,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Csongrád',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Debrecen',
		city_ascii: 'Debrecen',
		lat: 47.53046958,
		lng: 21.63003861,
		pop: 217705,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Hajdú-Bihar',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Szolnok',
		city_ascii: 'Szolnok',
		lat: 47.18635622,
		lng: 20.17937781,
		pop: 92367.5,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Jász-Nagykun-Szolnok',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Budapest',
		city_ascii: 'Budapest',
		lat: 47.50000633,
		lng: 19.08332068,
		pop: 1679000,
		country: 'Hungary',
		iso2: 'HU',
		iso3: 'HUN',
		province: 'Budapest',
		timezone: 'Europe/Budapest',
	},
	{
		city: 'Borgarnes',
		city_ascii: 'Borgarnes',
		lat: 64.56950277,
		lng: -21.86232219,
		pop: 1783,
		country: 'Iceland',
		iso2: 'IS',
		iso3: 'ISL',
		province: 'Vesturland',
		timezone: 'Atlantic/Reykjavik',
	},
	{
		city: 'Egilsstaðir',
		city_ascii: 'Egilsstadir',
		lat: 65.26742312,
		lng: -14.3949976,
		pop: 1822.5,
		country: 'Iceland',
		iso2: 'IS',
		iso3: 'ISL',
		province: 'Austur-Hérað',
		timezone: 'Atlantic/Reykjavik',
	},
	{
		city: 'Sauðárkrókur',
		city_ascii: 'Saudarkrokur',
		lat: 65.74641197,
		lng: -19.63899276,
		pop: 2191,
		country: 'Iceland',
		iso2: 'IS',
		iso3: 'ISL',
		province: 'Akrahreppur',
		timezone: 'Atlantic/Reykjavik',
	},
	{
		city: 'Selfoss',
		city_ascii: 'Selfoss',
		lat: 63.93342185,
		lng: -20.99694605,
		pop: 5030.5,
		country: 'Iceland',
		iso2: 'IS',
		iso3: 'ISL',
		province: 'Biskupstungnahreppur',
		timezone: 'Atlantic/Reykjavik',
	},
	{
		city: 'Hofn',
		city_ascii: 'Hofn',
		lat: 64.2723151,
		lng: -15.21385946,
		pop: 1695,
		country: 'Iceland',
		iso2: 'IS',
		iso3: 'ISL',
		province: 'Sveitarfélagið Hornafjörður',
		timezone: 'Atlantic/Reykjavik',
	},
	{
		city: 'Ísafjörður',
		city_ascii: 'Isafjordur',
		lat: 66.08331647,
		lng: -23.14996708,
		pop: 1949.5,
		country: 'Iceland',
		iso2: 'IS',
		iso3: 'ISL',
		province: 'Vestfirðir',
		timezone: 'Atlantic/Reykjavik',
	},
	{
		city: 'Akureyi',
		city_ascii: 'Akureyi',
		lat: 65.66657188,
		lng: -18.10001693,
		pop: 14754,
		country: 'Iceland',
		iso2: 'IS',
		iso3: 'ISL',
		province: 'Akureyri',
		timezone: 'Atlantic/Reykjavik',
	},
	{
		city: 'Keflavík',
		city_ascii: 'Keflavik',
		lat: 64.01664675,
		lng: -22.56659184,
		pop: 7377,
		country: 'Iceland',
		iso2: 'IS',
		iso3: 'ISL',
		province: 'Suðurnes',
		timezone: 'Atlantic/Reykjavik',
	},
	{
		city: 'Reykjavík',
		city_ascii: 'Reykjavik',
		lat: 64.15002362,
		lng: -21.95001449,
		pop: 140059,
		country: 'Iceland',
		iso2: 'IS',
		iso3: 'ISL',
		province: 'Suðurnes',
		timezone: 'Atlantic/Reykjavik',
	},
	{
		city: 'Panaji',
		city_ascii: 'Panaji',
		lat: 15.491997,
		lng: 73.81800065,
		pop: 65586,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Goa',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Simla',
		city_ascii: 'Simla',
		lat: 31.10002545,
		lng: 77.16659704,
		pop: 173503,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Himachal Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Gurgaon',
		city_ascii: 'Gurgaon',
		lat: 28.45000633,
		lng: 77.01999101,
		pop: 197340,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Haryana',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Sonipat',
		city_ascii: 'Sonipat',
		lat: 28.9999986,
		lng: 77.01999101,
		pop: 250521,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Haryana',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Rohtak',
		city_ascii: 'Rohtak',
		lat: 28.9000047,
		lng: 76.58001786,
		pop: 317245,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Haryana',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Hisar',
		city_ascii: 'Hisar',
		lat: 29.16998822,
		lng: 75.72503129,
		pop: 423039,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Haryana',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bhiwani',
		city_ascii: 'Bhiwani',
		lat: 28.81001019,
		lng: 76.12500688,
		pop: 190855,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Haryana',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Ambala',
		city_ascii: 'Ambala',
		lat: 30.32002138,
		lng: 76.82000321,
		pop: 146787,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Haryana',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Sopore',
		city_ascii: 'Sopur',
		lat: 34.29995933,
		lng: 74.46665849,
		pop: 60725.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Jammu and Kashmir',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Silvassa',
		city_ascii: 'Silvassa',
		lat: 20.26657819,
		lng: 73.0166178,
		pop: 27359,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Dadra and Nagar Haveli',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Kalyan',
		city_ascii: 'Kalyan',
		lat: 19.25023195,
		lng: 73.16017493,
		pop: 1576614,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bhusawal',
		city_ascii: 'Bhusawal',
		lat: 21.01999473,
		lng: 75.8300378,
		pop: 177683.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Jorhat',
		city_ascii: 'Jorhat',
		lat: 26.7499809,
		lng: 94.21666744,
		pop: 69033,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Assam',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Hoshiarpur',
		city_ascii: 'Hoshiarpur',
		lat: 31.51997398,
		lng: 75.98000281,
		pop: 158142,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Punjab',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Ajmer',
		city_ascii: 'Ajmer',
		lat: 26.44999921,
		lng: 74.63998124,
		pop: 553948,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Rajasthan',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Hathras',
		city_ascii: 'Hathras',
		lat: 27.59998069,
		lng: 78.05000565,
		pop: 126882,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Sitapur',
		city_ascii: 'Sitapur',
		lat: 27.6300047,
		lng: 80.74999589,
		pop: 164435,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Pilibhit',
		city_ascii: 'Pilibhit',
		lat: 28.63999473,
		lng: 79.81000159,
		pop: 131008,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Budaun',
		city_ascii: 'Budaun',
		lat: 28.03000612,
		lng: 79.08999385,
		pop: 161555,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Firozabad',
		city_ascii: 'Firozabad',
		lat: 27.14998232,
		lng: 78.39494584,
		pop: 306409,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Mathura',
		city_ascii: 'Mathura',
		lat: 27.4999868,
		lng: 77.67002885,
		pop: 330511,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bulandshahr',
		city_ascii: 'Bulandshahr',
		lat: 28.4103705,
		lng: 77.84841589,
		pop: 198612,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Hapur',
		city_ascii: 'Hapur',
		lat: 28.74365765,
		lng: 77.76278804,
		pop: 242920,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Muzaffarnagar',
		city_ascii: 'Muzaffarnagar',
		lat: 29.48500775,
		lng: 77.69504024,
		pop: 349706,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Gangtok',
		city_ascii: 'Gangtok',
		lat: 27.3333303,
		lng: 88.6166475,
		pop: 54300,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Sikkim',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Diu',
		city_ascii: 'Diu',
		lat: 20.71967334,
		lng: 70.99039497,
		pop: 23779,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Dadra and Nagar Haveli',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Pathankot',
		city_ascii: 'Pathankot',
		lat: 32.27034161,
		lng: 75.72001868,
		pop: 214146.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Himachal Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Sirsa',
		city_ascii: 'Sirsa',
		lat: 29.4903821,
		lng: 75.02998328,
		pop: 170884,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Haryana',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Panipat',
		city_ascii: 'Panipat',
		lat: 29.40041343,
		lng: 76.96996822,
		pop: 292808,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Haryana',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Karnal',
		city_ascii: 'Karnal',
		lat: 29.68044802,
		lng: 76.96996822,
		pop: 225049,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Haryana',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Baramula',
		city_ascii: 'Baramula',
		lat: 34.20043052,
		lng: 74.35002478,
		pop: 122631,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Jammu and Kashmir',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Proddatur',
		city_ascii: 'Proddatur',
		lat: 14.7504291,
		lng: 78.57002559,
		pop: 187624,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Nandyal',
		city_ascii: 'Nandyal',
		lat: 15.5203821,
		lng: 78.47995357,
		pop: 176995.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Hindupur',
		city_ascii: 'Hindupur',
		lat: 13.78035911,
		lng: 77.48998816,
		pop: 150805,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Tirupati',
		city_ascii: 'Tirupati',
		lat: 13.65041872,
		lng: 79.41999955,
		pop: 268928,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Ongole',
		city_ascii: 'Ongole',
		lat: 15.56037966,
		lng: 80.05003861,
		pop: 187866,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Vizianagaram',
		city_ascii: 'Vizianagaram',
		lat: 18.12040428,
		lng: 83.50000891,
		pop: 90276,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Rajahmundry',
		city_ascii: 'Rajahmundry',
		lat: 17.03034161,
		lng: 81.78998409,
		pop: 304804,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Machilipatnam',
		city_ascii: 'Machilipatnam',
		lat: 16.20041811,
		lng: 81.17999548,
		pop: 192827,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Khammam',
		city_ascii: 'Khammam',
		lat: 17.28042971,
		lng: 80.16005774,
		pop: 230671,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Chirala',
		city_ascii: 'Chirala',
		lat: 15.86041302,
		lng: 80.33999508,
		pop: 170000.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Karimnagar',
		city_ascii: 'Karimnagar',
		lat: 18.4604352,
		lng: 79.10999263,
		pop: 258498,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Nizamabad',
		city_ascii: 'Nizamabad',
		lat: 18.67039654,
		lng: 78.10002844,
		pop: 346971.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Kollam',
		city_ascii: 'Kollam',
		lat: 8.900372741,
		lng: 76.56999263,
		pop: 394163,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Kerala',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Alappuzha',
		city_ascii: 'Alappuzha',
		lat: 9.500413634,
		lng: 76.37000484,
		pop: 176783,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Kerala',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Puri',
		city_ascii: 'Puri',
		lat: 19.82042971,
		lng: 85.90001746,
		pop: 201026,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Orissa',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Sambalpur',
		city_ascii: 'Sambalpur',
		lat: 21.47040651,
		lng: 83.97005774,
		pop: 236869.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Orissa',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Raurkela',
		city_ascii: 'Raurkela',
		lat: 22.2304118,
		lng: 84.82995357,
		pop: 554730,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Orissa',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Kavaratti',
		city_ascii: 'Kavaratti',
		lat: 10.56257331,
		lng: 72.63686717,
		pop: 10688,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Lakshadweep',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Mandya',
		city_ascii: 'Mandya',
		lat: 12.57038129,
		lng: 76.91999711,
		pop: 209939.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Kolar',
		city_ascii: 'Kolar',
		lat: 13.13370607,
		lng: 78.13335974,
		pop: 135533,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Shimoga',
		city_ascii: 'Shimoga',
		lat: 13.93037579,
		lng: 75.56002844,
		pop: 486802.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Raichur',
		city_ascii: 'Raichur',
		lat: 16.21036582,
		lng: 77.35500932,
		pop: 240601,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Hospet',
		city_ascii: 'Hospet',
		lat: 15.28039675,
		lng: 76.37501745,
		pop: 241926.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bidar',
		city_ascii: 'Bidar',
		lat: 17.92292279,
		lng: 77.5175317,
		pop: 252103.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Sangli',
		city_ascii: 'Sangli',
		lat: 16.86040367,
		lng: 74.57502397,
		pop: 601214,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Parbhani',
		city_ascii: 'Parbhani',
		lat: 19.27038576,
		lng: 76.76000688,
		pop: 333977.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Malegaon',
		city_ascii: 'Malegaon',
		lat: 20.5603587,
		lng: 74.52500118,
		pop: 563103,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Port Blair',
		city_ascii: 'Port Blair',
		lat: 11.66702557,
		lng: 92.73598262,
		pop: 119806,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andaman and Nicobar',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Tezpur',
		city_ascii: 'Tezpur',
		lat: 26.6337606,
		lng: 92.80000972,
		pop: 58851,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Assam',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Silchar',
		city_ascii: 'Silchar',
		lat: 24.79041058,
		lng: 92.79003617,
		pop: 152393,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Assam',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Kohima',
		city_ascii: 'Kohima',
		lat: 25.6669979,
		lng: 94.11657019,
		pop: 92113,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Nagaland',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Shillong',
		city_ascii: 'Shillong',
		lat: 25.57049217,
		lng: 91.8800142,
		pop: 364926,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Meghalaya',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Abohar',
		city_ascii: 'Abohar',
		lat: 30.12042116,
		lng: 74.29002844,
		pop: 130603,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Punjab',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Patiala',
		city_ascii: 'Patiala',
		lat: 30.32040895,
		lng: 76.38499101,
		pop: 329224,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Punjab',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bhilwara',
		city_ascii: 'Bhilwara',
		lat: 25.35042808,
		lng: 74.6350203,
		pop: 358171,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Rajasthan',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Pali',
		city_ascii: 'Pali',
		lat: 25.79037539,
		lng: 73.32993201,
		pop: 208748.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Rajasthan',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Tonk',
		city_ascii: 'Tonk',
		lat: 26.15045677,
		lng: 75.79004024,
		pop: 166532.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Rajasthan',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Sikar',
		city_ascii: 'Sikar',
		lat: 27.61039349,
		lng: 75.1400024,
		pop: 318789.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Rajasthan',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bikaner',
		city_ascii: 'Bikaner',
		lat: 28.0303937,
		lng: 73.32993201,
		pop: 485961.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Rajasthan',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bharatpur',
		city_ascii: 'Bharatpur',
		lat: 27.25036379,
		lng: 77.50001339,
		pop: 229384,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Rajasthan',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Alwar',
		city_ascii: 'Alwar',
		lat: 27.5453587,
		lng: 76.6049259,
		pop: 283228,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Rajasthan',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Fatehpur',
		city_ascii: 'Fatehpur',
		lat: 25.88036989,
		lng: 80.80001868,
		pop: 166480,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Faizabad',
		city_ascii: 'Faizabad',
		lat: 26.75039431,
		lng: 82.17001257,
		pop: 153047,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bahraich',
		city_ascii: 'Bahraich',
		lat: 27.62041872,
		lng: 81.66993974,
		pop: 182218,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Mirzapur',
		city_ascii: 'Mirzapur',
		lat: 25.145376,
		lng: 82.56998816,
		pop: 239754,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Jhansi',
		city_ascii: 'Jhansi',
		lat: 25.45295412,
		lng: 78.55746822,
		pop: 619710.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Shahjahanpur',
		city_ascii: 'Shahjahanpur',
		lat: 27.88037701,
		lng: 79.90503454,
		pop: 320434,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Rampur',
		city_ascii: 'Rampur',
		lat: 28.8153587,
		lng: 79.0249849,
		pop: 296418,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bareilly',
		city_ascii: 'Bareilly',
		lat: 28.34538739,
		lng: 79.41999955,
		pop: 781217.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Etawah',
		city_ascii: 'Etawah',
		lat: 26.78545677,
		lng: 79.01495968,
		pop: 257448,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Dehra Dun',
		city_ascii: 'Dehra Dun',
		lat: 30.32040895,
		lng: 78.05000565,
		pop: 646321.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttaranchal',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Haora',
		city_ascii: 'Haora',
		lat: 22.58039044,
		lng: 88.32994665,
		pop: 2934655,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'West Bengal',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Alipur Duar',
		city_ascii: 'Alipur Duar',
		lat: 26.48374392,
		lng: 89.56666703,
		pop: 127342,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'West Bengal',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Haldia',
		city_ascii: 'Haldia',
		lat: 22.02566978,
		lng: 88.05833533,
		pop: 200762,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'West Bengal',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bhatpara',
		city_ascii: 'Bhatpara',
		lat: 22.85042564,
		lng: 88.52001257,
		pop: 483129,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'West Bengal',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Medinipur',
		city_ascii: 'Medinipur',
		lat: 22.3304057,
		lng: 87.15001868,
		pop: 169127,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'West Bengal',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Siliguri',
		city_ascii: 'Siliguri',
		lat: 26.72042198,
		lng: 88.45500362,
		pop: 515574,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'West Bengal',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Purnia',
		city_ascii: 'Purnia',
		lat: 25.78541445,
		lng: 87.4799727,
		pop: 198453,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Bihar',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Muzaffarpur',
		city_ascii: 'Muzaffarpur',
		lat: 26.12043276,
		lng: 85.37994584,
		pop: 333200,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Bihar',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Aurangabad',
		city_ascii: 'Aurangabad',
		lat: 24.7704118,
		lng: 84.38000688,
		pop: 95929,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Bihar',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bilaspur',
		city_ascii: 'Bilaspur',
		lat: 22.09042035,
		lng: 82.15998734,
		pop: 436780,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Chhattisgarh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Burhanpur',
		city_ascii: 'Burhanpur',
		lat: 21.30039105,
		lng: 76.13001949,
		pop: 197233,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Madhya Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Ujjain',
		city_ascii: 'Ujjain',
		lat: 23.19040489,
		lng: 75.79004024,
		pop: 485348,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Madhya Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Ratlam',
		city_ascii: 'Ratlam',
		lat: 23.35039512,
		lng: 75.02998328,
		pop: 272036,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Madhya Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Sagar',
		city_ascii: 'Sagar',
		lat: 23.85039044,
		lng: 78.75001461,
		pop: 287786.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Madhya Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Vellore',
		city_ascii: 'Vellore',
		lat: 12.92038576,
		lng: 79.15004187,
		pop: 177081,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Tiruvannamalai',
		city_ascii: 'Tiruvannamalai',
		lat: 12.26037437,
		lng: 79.09996741,
		pop: 138243,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Rajapalaiyam',
		city_ascii: 'Rajapalaiyam',
		lat: 9.420392679,
		lng: 77.5800085,
		pop: 338975,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Cuddalore',
		city_ascii: 'Cuddalore',
		lat: 11.72040733,
		lng: 79.77000403,
		pop: 158569,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Karur',
		city_ascii: 'Karur',
		lat: 10.95037681,
		lng: 78.08333695,
		pop: 76915,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Kanchipuram',
		city_ascii: 'Kanchipuram',
		lat: 12.83372438,
		lng: 79.71667395,
		pop: 155029,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Tirunelveli',
		city_ascii: 'Tirunelveli',
		lat: 8.730408955,
		lng: 77.68997595,
		pop: 489022,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Nagercoil',
		city_ascii: 'Nagercoil',
		lat: 8.180365009,
		lng: 77.42999182,
		pop: 219093.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Thanjavur',
		city_ascii: 'Thanjavur',
		lat: 10.77041363,
		lng: 79.15004187,
		pop: 219571,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Kumbakonam',
		city_ascii: 'Kumbakonam',
		lat: 10.98047833,
		lng: 79.40000077,
		pop: 139264,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Valparai',
		city_ascii: 'Valparai',
		lat: 10.32041526,
		lng: 76.96996822,
		pop: 102330.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Tiruppur',
		city_ascii: 'Tiruppur',
		lat: 11.08042055,
		lng: 77.32999792,
		pop: 547271.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Daman',
		city_ascii: 'Daman',
		lat: 20.41700828,
		lng: 72.85001298,
		pop: 39737,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Dadra and Nagar Haveli',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Navsari',
		city_ascii: 'Navsari',
		lat: 20.85039268,
		lng: 72.92003454,
		pop: 163000,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Dadra and Nagar Haveli',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bhuj',
		city_ascii: 'Bhuj',
		lat: 23.25037539,
		lng: 69.80999182,
		pop: 289429,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Dadra and Nagar Haveli',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bhavnagar',
		city_ascii: 'Bhavnagar',
		lat: 21.77842389,
		lng: 72.12995357,
		pop: 509790,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Dadra and Nagar Haveli',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Gandhinagar',
		city_ascii: 'Gandhinagar',
		lat: 23.30039817,
		lng: 72.63994828,
		pop: 195891,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Dadra and Nagar Haveli',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Itanagar',
		city_ascii: 'Itanagar',
		lat: 27.10039878,
		lng: 93.61660071,
		pop: 44971,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Arunachal Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Aizawl',
		city_ascii: 'Aizawl',
		lat: 23.71039899,
		lng: 92.72001461,
		pop: 274176,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Mizoram',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Agartala',
		city_ascii: 'Agartala',
		lat: 23.83540428,
		lng: 91.27999914,
		pop: 203264,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tripura',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Kakinada',
		city_ascii: 'Kakinada',
		lat: 16.96747723,
		lng: 82.23750199,
		pop: 292923,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Warangal',
		city_ascii: 'Warangal',
		lat: 18.00999758,
		lng: 79.57998979,
		pop: 1034690,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Brahmapur',
		city_ascii: 'Brahmapur',
		lat: 19.31999514,
		lng: 84.79998124,
		pop: 324726,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Orissa',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bijapur',
		city_ascii: 'Bijapur',
		lat: 16.83541811,
		lng: 75.70999345,
		pop: 270967,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bhiwandi',
		city_ascii: 'Bhiwandi',
		lat: 19.35001914,
		lng: 73.12999589,
		pop: 751017.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Latur',
		city_ascii: 'Latur',
		lat: 18.40041302,
		lng: 76.56999263,
		pop: 361680.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Ahmednagar',
		city_ascii: 'Ahmednagar',
		lat: 19.11042137,
		lng: 74.75000037,
		pop: 379450,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Chandrapur',
		city_ascii: 'Chandrapur',
		lat: 19.9699813,
		lng: 79.30000688,
		pop: 461734.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Amravati',
		city_ascii: 'Amravati',
		lat: 20.94997316,
		lng: 77.77002274,
		pop: 669144,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Dhule',
		city_ascii: 'Dhule',
		lat: 20.89997622,
		lng: 74.76999914,
		pop: 423026.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Dibrugarh',
		city_ascii: 'Dibrugarh',
		lat: 27.48332115,
		lng: 94.8999849,
		pop: 144260.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Assam',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Imphal',
		city_ascii: 'Imphal',
		lat: 24.79997072,
		lng: 93.95001705,
		pop: 244254.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Manipur',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Udaipur',
		city_ascii: 'Udaipur',
		lat: 24.59998293,
		lng: 73.73001094,
		pop: 446260.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Rajasthan',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Gorakhpur',
		city_ascii: 'Gorakhpur',
		lat: 26.75039431,
		lng: 83.38001623,
		pop: 674246,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Barddhaman',
		city_ascii: 'Barddhaman',
		lat: 23.25037539,
		lng: 87.86496212,
		pop: 301725,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'West Bengal',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Krishnanagar',
		city_ascii: 'Krishnanagar',
		lat: 23.38034161,
		lng: 88.5300378,
		pop: 145926,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'West Bengal',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Gaya',
		city_ascii: 'Gaya',
		lat: 24.79997072,
		lng: 85.00002071,
		pop: 423692,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Bihar',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Porbandar',
		city_ascii: 'Porbandar',
		lat: 21.6699809,
		lng: 69.67000037,
		pop: 186778,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Dadra and Nagar Haveli',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Nellore',
		city_ascii: 'Nellore',
		lat: 14.43998293,
		lng: 79.98993892,
		pop: 541081,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Kurnool',
		city_ascii: 'Kurnool',
		lat: 15.83000144,
		lng: 78.03000688,
		pop: 351522,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Guntur',
		city_ascii: 'Guntur',
		lat: 16.32999676,
		lng: 80.4500142,
		pop: 530577,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Tumkur',
		city_ascii: 'Tumkur',
		lat: 13.32997316,
		lng: 77.1000378,
		pop: 353482.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Davangere',
		city_ascii: 'Davangere',
		lat: 14.47000694,
		lng: 75.92000647,
		pop: 469344.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bellary',
		city_ascii: 'Bellary',
		lat: 15.15004295,
		lng: 76.91503617,
		pop: 391034.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Belgaum',
		city_ascii: 'Belgaum',
		lat: 15.86501223,
		lng: 74.5050024,
		pop: 609472.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Tuticorin',
		city_ascii: 'Tuticorin',
		lat: 8.81999005,
		lng: 78.13000077,
		pop: 436094,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Dindigul',
		city_ascii: 'Dindigul',
		lat: 10.37997235,
		lng: 78.00003454,
		pop: 200797,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Chandigarh',
		city_ascii: 'Chandigarh',
		lat: 30.71999697,
		lng: 76.78000565,
		pop: 946685.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Chandigarh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Jammu',
		city_ascii: 'Jammu',
		lat: 32.71178754,
		lng: 74.84673865,
		pop: 628283.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Jammu and Kashmir',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Sholapur',
		city_ascii: 'Sholapur',
		lat: 17.6704059,
		lng: 75.90000769,
		pop: 1009056,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Aurangabad',
		city_ascii: 'Aurangabad',
		lat: 19.89569643,
		lng: 75.32030147,
		pop: 1064720.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Nasik',
		city_ascii: 'Nasik',
		lat: 20.00041872,
		lng: 73.77998205,
		pop: 1381248.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Dispur',
		city_ascii: 'Dispur',
		lat: 26.14402305,
		lng: 91.76663611,
		pop: 16140,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Assam',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Jullundur',
		city_ascii: 'Jullundur',
		lat: 31.33492067,
		lng: 75.56902014,
		pop: 820089,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Punjab',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Allahabad',
		city_ascii: 'Allahabad',
		lat: 25.45499534,
		lng: 81.84000688,
		pop: 1137219,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Moradabad',
		city_ascii: 'Moradabad',
		lat: 28.8417912,
		lng: 78.75678422,
		pop: 754069.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Ghaziabad',
		city_ascii: 'Ghaziabad',
		lat: 28.66038108,
		lng: 77.40839107,
		pop: 1270095.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Agra',
		city_ascii: 'Agra',
		lat: 27.17042035,
		lng: 78.01502071,
		pop: 1511027.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Aligarh',
		city_ascii: 'Aligarh',
		lat: 27.89221092,
		lng: 78.06178788,
		pop: 779103.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Meerut',
		city_ascii: 'Meerut',
		lat: 29.00041201,
		lng: 77.70000118,
		pop: 1310592,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Dhanbad',
		city_ascii: 'Dhanbad',
		lat: 23.80039349,
		lng: 86.41998572,
		pop: 732818,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Jharkhand',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Gwalior',
		city_ascii: 'Gwalior',
		lat: 26.2299868,
		lng: 78.18007523,
		pop: 930229,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Madhya Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Vadodara',
		city_ascii: 'Vadodara',
		lat: 22.31001935,
		lng: 73.18001868,
		pop: 1582738,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Dadra and Nagar Haveli',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Rajkot',
		city_ascii: 'Rajkot',
		lat: 22.31001935,
		lng: 70.80000891,
		pop: 1179941,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Dadra and Nagar Haveli',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Faridabad',
		city_ascii: 'Faridabad',
		lat: 28.4333333,
		lng: 77.3166667,
		pop: 1394000,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Haryana',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Srinagar',
		city_ascii: 'Srinagar',
		lat: 34.09997154,
		lng: 74.81500932,
		pop: 1057928.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Jammu and Kashmir',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Vijayawada',
		city_ascii: 'Vijayawada',
		lat: 16.51995933,
		lng: 80.63000321,
		pop: 1005793.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Thiruvananthapuram',
		city_ascii: 'Thiruvananthapuram',
		lat: 8.499983743,
		lng: 76.95002112,
		pop: 869076.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Kerala',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Kochi',
		city_ascii: 'Kochi',
		lat: 10.01500755,
		lng: 76.22391557,
		pop: 1061848,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Kerala',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Cuttack',
		city_ascii: 'Cuttack',
		lat: 20.47000246,
		lng: 85.88994055,
		pop: 580000,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Orissa',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Hubli',
		city_ascii: 'Hubli',
		lat: 15.35997845,
		lng: 75.12501623,
		pop: 841402,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Mangalore',
		city_ascii: 'Mangalore',
		lat: 12.90002525,
		lng: 74.84999426,
		pop: 597009.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Mysore',
		city_ascii: 'Mysore',
		lat: 12.30998374,
		lng: 76.66001298,
		pop: 877656.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Gulbarga',
		city_ascii: 'Gulbarga',
		lat: 17.34996035,
		lng: 76.82000321,
		pop: 482546.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Kolhapur',
		city_ascii: 'Kolhapur',
		lat: 16.70000002,
		lng: 74.22000688,
		pop: 655920.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Nanded',
		city_ascii: 'Nanded',
		lat: 19.16997845,
		lng: 77.30002559,
		pop: 587136,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Akola',
		city_ascii: 'Akola',
		lat: 20.70998781,
		lng: 77.01001745,
		pop: 466179.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Guwahati',
		city_ascii: 'Guwahati',
		lat: 26.16001691,
		lng: 91.76999508,
		pop: 500258,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Assam',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Ludhiana',
		city_ascii: 'Ludhiana',
		lat: 30.92776206,
		lng: 75.87225745,
		pop: 1597184,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Punjab',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Kota',
		city_ascii: 'Kota',
		lat: 25.17999921,
		lng: 75.83499874,
		pop: 795044,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Rajasthan',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Jodhpur',
		city_ascii: 'Jodhpur',
		lat: 26.29176597,
		lng: 73.01677283,
		pop: 958238,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Rajasthan',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Lucknow',
		city_ascii: 'Lucknow',
		lat: 26.85503908,
		lng: 80.91499874,
		pop: 2583505.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Saharanpur',
		city_ascii: 'Saharanpur',
		lat: 29.97001691,
		lng: 77.55003617,
		pop: 484873,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Ranchi',
		city_ascii: 'Ranchi',
		lat: 23.37000633,
		lng: 85.33002641,
		pop: 945227,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Jharkhand',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bhagalpur',
		city_ascii: 'Bhagalpur',
		lat: 25.22999615,
		lng: 86.98000321,
		pop: 361548,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Bihar',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Raipur',
		city_ascii: 'Raipur',
		lat: 21.23499453,
		lng: 81.63500647,
		pop: 777497.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Chhattisgarh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Jabalpur',
		city_ascii: 'Jabalpur',
		lat: 23.17505699,
		lng: 79.95505733,
		pop: 1157584,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Madhya Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Indore',
		city_ascii: 'Indore',
		lat: 22.71505922,
		lng: 75.86502274,
		pop: 1931520.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Madhya Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Pondicherry',
		city_ascii: 'Pondicherry',
		lat: 11.93499371,
		lng: 79.83000037,
		pop: 227411,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Puducherry',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Salem',
		city_ascii: 'Salem',
		lat: 11.66999697,
		lng: 78.18007523,
		pop: 825698,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Tiruchirappalli',
		city_ascii: 'Tiruchirappalli',
		lat: 10.80999778,
		lng: 78.68996659,
		pop: 863242,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Kozhikode',
		city_ascii: 'Kozhikode',
		lat: 11.25043601,
		lng: 75.76998979,
		pop: 696461,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Kerala',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bhubaneshwar',
		city_ascii: 'Bhubaneshwar',
		lat: 20.27042808,
		lng: 85.82736039,
		pop: 803121.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Orissa',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Jamshedpur',
		city_ascii: 'Jamshedpur',
		lat: 22.78753542,
		lng: 86.19751868,
		pop: 958169,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Jharkhand',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Vishakhapatnam',
		city_ascii: 'Vishakhapatnam',
		lat: 17.73001467,
		lng: 83.30498205,
		pop: 1296089,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Amritsar',
		city_ascii: 'Amritsar',
		lat: 31.63999249,
		lng: 74.86999304,
		pop: 1152225,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Punjab',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Varanasi',
		city_ascii: 'Varanasi',
		lat: 25.32999005,
		lng: 83.00003943,
		pop: 1258202,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Asansol',
		city_ascii: 'Asansol',
		lat: 23.6833333,
		lng: 86.9833333,
		pop: 1328000,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'West Bengal',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bhilai',
		city_ascii: 'Bhilai',
		lat: 21.2166667,
		lng: 81.4333333,
		pop: 1097000,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Chhattisgarh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bhopal',
		city_ascii: 'Bhopal',
		lat: 23.24998781,
		lng: 77.40999304,
		pop: 1663457,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Madhya Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Madurai',
		city_ascii: 'Madurai',
		lat: 9.920026264,
		lng: 78.12002722,
		pop: 1101954,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Coimbatore',
		city_ascii: 'Coimbatore',
		lat: 10.99996035,
		lng: 76.95002112,
		pop: 1327911.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Delhi',
		city_ascii: 'Delhi',
		lat: 28.6699929,
		lng: 77.23000403,
		pop: 11779606.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Delhi',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Hyderabad',
		city_ascii: 'Hyderabad',
		lat: 17.39998313,
		lng: 78.47995357,
		pop: 4986908,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Andhra Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Pune',
		city_ascii: 'Pune',
		lat: 18.53001752,
		lng: 73.85000362,
		pop: 3803872,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Nagpur',
		city_ascii: 'Nagpur',
		lat: 21.16995974,
		lng: 79.08999385,
		pop: 2341009,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Jaipur',
		city_ascii: 'Jaipur',
		lat: 26.92113324,
		lng: 75.80998734,
		pop: 2814379,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Rajasthan',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Kanpur',
		city_ascii: 'Kanpur',
		lat: 26.4599986,
		lng: 80.3199963,
		pop: 2992624.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Uttar Pradesh',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Patna',
		city_ascii: 'Patna',
		lat: 25.62495913,
		lng: 85.13003861,
		pop: 1878960,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Bihar',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Chennai',
		city_ascii: 'Chennai',
		lat: 13.08998781,
		lng: 80.27999874,
		pop: 5745531.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Tamil Nadu',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Ahmedabad',
		city_ascii: 'Ahmedabad',
		lat: 23.03005292,
		lng: 72.58000362,
		pop: 4547355,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Dadra and Nagar Haveli',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Surat',
		city_ascii: 'Surat',
		lat: 21.19998374,
		lng: 72.84003943,
		pop: 3368252,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Dadra and Nagar Haveli',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'New Delhi',
		city_ascii: 'New Delhi',
		lat: 28.60002301,
		lng: 77.19998002,
		pop: 317797,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Delhi',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Bangalore',
		city_ascii: 'Bangalore',
		lat: 12.96999514,
		lng: 77.56000972,
		pop: 5945523.5,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Karnataka',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Mumbai',
		city_ascii: 'Mumbai',
		lat: 19.01699038,
		lng: 72.8569893,
		pop: 15834918,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'Maharashtra',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Kolkata',
		city_ascii: 'Kolkata',
		lat: 22.4949693,
		lng: 88.32467566,
		pop: 9709196,
		country: 'India',
		iso2: 'IN',
		iso3: 'IND',
		province: 'West Bengal',
		timezone: 'Asia/Kolkata',
	},
	{
		city: 'Binjai',
		city_ascii: 'Binjai',
		lat: 3.620359109,
		lng: 98.50007524,
		pop: 405494.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sumatera Utara',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Padangsidempuan',
		city_ascii: 'Padangsidempuan',
		lat: 1.388738219,
		lng: 99.27336137,
		pop: 183721.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sumatera Utara',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Tarutung',
		city_ascii: 'Tarutung',
		lat: 2.017071959,
		lng: 98.96666174,
		pop: 1305,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sumatera Utara',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Tebingtinggi',
		city_ascii: 'Tebingtinggi',
		lat: 3.33037681,
		lng: 99.13001094,
		pop: 192786.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sumatera Utara',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Tidore',
		city_ascii: 'Tidore',
		lat: 0.696377379,
		lng: 127.4359834,
		pop: 60611,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Maluku Utara',
		timezone: 'Asia/Jayapura',
	},
	{
		city: 'Bukittinggi',
		city_ascii: 'Bukittinggi',
		lat: -0.303148174,
		lng: 100.3614603,
		pop: 302855,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sumatera Barat',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Sawahlunto',
		city_ascii: 'Sawahlunto',
		lat: -0.666278464,
		lng: 100.7833467,
		pop: 50354,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sumatera Barat',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Padangpanjang',
		city_ascii: 'Padangpanjang',
		lat: -0.449599183,
		lng: 100.4166508,
		pop: 44096,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sumatera Barat',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Amahai',
		city_ascii: 'Amahai',
		lat: -3.328131491,
		lng: 128.9404911,
		pop: 26172.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Maluku',
		timezone: 'Asia/Jayapura',
	},
	{
		city: 'Mataram',
		city_ascii: 'Mataram',
		lat: -8.579542217,
		lng: 116.1350195,
		pop: 409041.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Nusa Tenggara Barat',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Praya',
		city_ascii: 'Praya',
		lat: -8.7223242,
		lng: 116.2923226,
		pop: 35183,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Nusa Tenggara Barat',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Baubau',
		city_ascii: 'Baubau',
		lat: -5.469861228,
		lng: 122.6163293,
		pop: 24412,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sulawesi Tenggara',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Luwuk',
		city_ascii: 'Luwuk',
		lat: -0.939595114,
		lng: 122.7900138,
		pop: 43550.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sulawesi Tengah',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Poso',
		city_ascii: 'Poso',
		lat: -1.389593487,
		lng: 120.7600085,
		pop: 41507,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sulawesi Tengah',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Biak',
		city_ascii: 'Biak',
		lat: -1.161493715,
		lng: 136.048481,
		pop: 103610,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Papua',
		timezone: 'Asia/Jayapura',
	},
	{
		city: 'Timika',
		city_ascii: 'Timika',
		lat: -4.549607321,
		lng: 136.88998,
		pop: 26021,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Papua',
		timezone: 'Asia/Jayapura',
	},
	{
		city: 'Langsa',
		city_ascii: 'Langsa',
		lat: 4.673576476,
		lng: 97.96636104,
		pop: 117256,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Aceh',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Indramayu',
		city_ascii: 'Indramayu',
		lat: -6.335648174,
		lng: 108.3190108,
		pop: 123263,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Barat',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Sukabumi',
		city_ascii: 'Sukabumi',
		lat: -6.909618308,
		lng: 106.899976,
		pop: 276414,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Barat',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Cilacap',
		city_ascii: 'Cilacap',
		lat: -7.718819561,
		lng: 109.0154024,
		pop: 1174964,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Tengah',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Pati',
		city_ascii: 'Pati',
		lat: -6.741514873,
		lng: 111.034659,
		pop: 122785,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Tengah',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Pakalongan',
		city_ascii: 'Pakalongan',
		lat: -6.8795943,
		lng: 109.6700394,
		pop: 264972.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Tengah',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Tegal',
		city_ascii: 'Tegal',
		lat: -6.869569073,
		lng: 109.1199955,
		pop: 237084,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Tengah',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Salatiga',
		city_ascii: 'Salatiga',
		lat: -7.309542217,
		lng: 110.4900927,
		pop: 174322.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Tengah',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Magelang',
		city_ascii: 'Magelang',
		lat: -7.469584128,
		lng: 110.1799825,
		pop: 111461,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Tengah',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Serang',
		city_ascii: 'Serang',
		lat: -6.109977194,
		lng: 106.1496342,
		pop: 164767,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Banten',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Bekasi',
		city_ascii: 'Bekasi',
		lat: -6.217257468,
		lng: 106.972323,
		pop: 1949165,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jakarta Raya',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Singkawang',
		city_ascii: 'Singkawang',
		lat: 0.911980927,
		lng: 108.9654697,
		pop: 174925,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Kalimantan Barat',
		timezone: 'Asia/Pontianak',
	},
	{
		city: 'Bandar Lampung',
		city_ascii: 'Tanjungkarang-Telubketung',
		lat: -5.449604066,
		lng: 105.3000219,
		pop: 881801,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Lampung',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Perabumulih',
		city_ascii: 'Perabumulih',
		lat: -3.443163229,
		lng: 104.2314567,
		pop: 83754,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sumatera Selatan',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Kuta',
		city_ascii: 'Kuta',
		lat: -8.715112509,
		lng: 115.1841208,
		pop: 22879.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Bali',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Singaraja',
		city_ascii: 'Singaraja',
		lat: -8.115199274,
		lng: 115.094398,
		pop: 184126,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Bali',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Sumenep',
		city_ascii: 'Sumenep',
		lat: -7.004909649,
		lng: 113.8496293,
		pop: 84656,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Timur',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Banyuwangi',
		city_ascii: 'Banyuwangi',
		lat: -8.195017884,
		lng: 114.3695975,
		pop: 140295,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Timur',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Tuban',
		city_ascii: 'Tuban',
		lat: -6.899541403,
		lng: 112.0499975,
		pop: 76242,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Timur',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Probolinggo',
		city_ascii: 'Probolinggo',
		lat: -7.749618715,
		lng: 113.1500337,
		pop: 181656,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Timur',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Pasuruan',
		city_ascii: 'Pasuruan',
		lat: -7.629574362,
		lng: 112.9000232,
		pop: 343161,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Timur',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Mojokerto',
		city_ascii: 'Mojokerto',
		lat: -7.469584128,
		lng: 112.4299743,
		pop: 112557,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Timur',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Madiun',
		city_ascii: 'Madiun',
		lat: -7.634586976,
		lng: 111.5149914,
		pop: 186099,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Timur',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Kediri',
		city_ascii: 'Kediri',
		lat: -7.789616273,
		lng: 112.0000264,
		pop: 235143,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Timur',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Blitar',
		city_ascii: 'Blitar',
		lat: -8.069599183,
		lng: 112.1499914,
		pop: 132416,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Timur',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Waingapu',
		city_ascii: 'Waingapu',
		lat: -9.658236065,
		lng: 120.253011,
		pop: 35990.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Nusa Tenggara Timur',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Maumere',
		city_ascii: 'Maumere',
		lat: -8.618867982,
		lng: 122.212323,
		pop: 75941.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Nusa Tenggara Timur',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Ende',
		city_ascii: 'Ende',
		lat: -8.862315655,
		lng: 121.6489465,
		pop: 60930,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Nusa Tenggara Timur',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Makale',
		city_ascii: 'Makale',
		lat: -3.09682778,
		lng: 119.8530871,
		pop: 9960,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sulawesi Selatan',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Palopo',
		city_ascii: 'Palopo',
		lat: -3.099928366,
		lng: 120.2363195,
		pop: 2444,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sulawesi Selatan',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Watampone',
		city_ascii: 'Watampone',
		lat: -4.532812481,
		lng: 120.3333679,
		pop: 58953,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sulawesi Selatan',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Pinrang',
		city_ascii: 'Pinrang',
		lat: -3.785726299,
		lng: 119.6522208,
		pop: 182731,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sulawesi Selatan',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Majene',
		city_ascii: 'Majene',
		lat: -3.533596986,
		lng: 118.9660095,
		pop: 155046,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sulawesi Barat',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Tanjungpinang',
		city_ascii: 'Tanjungpinang',
		lat: 0.916829039,
		lng: 104.471442,
		pop: 176069,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Kepulauan Riau',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Telukbutun',
		city_ascii: 'Telukbutun',
		lat: 4.216989358,
		lng: 108.2,
		pop: 140,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Kepulauan Riau',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Sungaipenuh',
		city_ascii: 'Sungaipenuh',
		lat: -2.063144105,
		lng: 101.3964359,
		pop: 56773,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jambi',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Sampit',
		city_ascii: 'Sampit',
		lat: -2.532934551,
		lng: 112.9500459,
		pop: 79381.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Kalimantan Tengah',
		timezone: 'Asia/Pontianak',
	},
	{
		city: 'Kualakapuas',
		city_ascii: 'Kualakapuas',
		lat: -3.099618308,
		lng: 114.3500122,
		pop: 18512.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Kalimantan Tengah',
		timezone: 'Asia/Pontianak',
	},
	{
		city: 'Palangkaraya',
		city_ascii: 'Palangkaraya',
		lat: -2.209595114,
		lng: 113.9099873,
		pop: 148289,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Kalimantan Tengah',
		timezone: 'Asia/Pontianak',
	},
	{
		city: 'Bontang',
		city_ascii: 'Bontang',
		lat: 0.133259297,
		lng: 117.5000008,
		pop: 101691,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Kalimantan Timur',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Denpasar',
		city_ascii: 'Denpasar',
		lat: -8.650028871,
		lng: 115.2199849,
		pop: 569133.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Bali',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Sorong',
		city_ascii: 'Sorong',
		lat: -0.855414206,
		lng: 131.2849991,
		pop: 125535,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Irian Jaya Barat',
		timezone: 'Asia/Jayapura',
	},
	{
		city: 'Sibolga',
		city_ascii: 'Sibolga',
		lat: 1.749982319,
		lng: 98.80000525,
		pop: 148513,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sumatera Utara',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Pematangsiantar',
		city_ascii: 'Pematangsiantar',
		lat: 2.961432921,
		lng: 99.061488,
		pop: 275407,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sumatera Utara',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Pekanbaru',
		city_ascii: 'Pekanbaru',
		lat: 0.564964212,
		lng: 101.425013,
		pop: 705218,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Riau',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Manado',
		city_ascii: 'Manado',
		lat: 1.480024637,
		lng: 124.8499914,
		pop: 449497.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sulawesi Utara',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Yogyakarta',
		city_ascii: 'Yogyakarta',
		lat: -7.77995278,
		lng: 110.3750093,
		pop: 636660,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Yogyakarta',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Kendari',
		city_ascii: 'Kendari',
		lat: -3.95532835,
		lng: 122.5973124,
		pop: 165377,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sulawesi Tenggara',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Palu',
		city_ascii: 'Palu',
		lat: -0.907038962,
		lng: 119.8330367,
		pop: 473871,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sulawesi Tengah',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Nabire',
		city_ascii: 'Nabire',
		lat: -3.351540915,
		lng: 135.5134232,
		pop: 28834.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Papua',
		timezone: 'Asia/Jayapura',
	},
	{
		city: 'Merauke',
		city_ascii: 'Merauke',
		lat: -8.493190899,
		lng: 140.401807,
		pop: 34412,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Papua',
		timezone: 'Asia/Jayapura',
	},
	{
		city: 'Lhokseumawe',
		city_ascii: 'Lhokseumawe',
		lat: 5.191400166,
		lng: 97.14145015,
		pop: 114648,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Aceh',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Samarinda',
		city_ascii: 'Samarinda',
		lat: -0.500035381,
		lng: 117.1499963,
		pop: 473694,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Kalimantan Timur',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Cirebon',
		city_ascii: 'Cirebon',
		lat: -6.733298321,
		lng: 108.5666442,
		pop: 254298,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Barat',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Tasikmalaya',
		city_ascii: 'Tasikmalaya',
		lat: -7.325406882,
		lng: 108.2146761,
		pop: 271143,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Barat',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Bogor',
		city_ascii: 'Bogor',
		lat: -6.570000795,
		lng: 106.7500109,
		pop: 859000,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Barat',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Bengkulu',
		city_ascii: 'Bengkulu',
		lat: -3.800040671,
		lng: 102.2699743,
		pop: 368192.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Bengkulu',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Pontianak',
		city_ascii: 'Pontianak',
		lat: -0.029986553,
		lng: 109.3199833,
		pop: 578807.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Kalimantan Barat',
		timezone: 'Asia/Pontianak',
	},
	{
		city: 'Kotabumi',
		city_ascii: 'Kotabumi',
		lat: -4.833310935,
		lng: 104.8999947,
		pop: 42366,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Lampung',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Lahat',
		city_ascii: 'Lahat',
		lat: -3.800040671,
		lng: 103.5333081,
		pop: 50469.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sumatera Selatan',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Pangkalpinang',
		city_ascii: 'Pangkalpinang',
		lat: -2.080042298,
		lng: 106.1500476,
		pop: 99785.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Bangka-Belitung',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Jember',
		city_ascii: 'Jember',
		lat: -8.172693666,
		lng: 113.6873136,
		pop: 298585,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Timur',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Martapura',
		city_ascii: 'Martapura',
		lat: -3.413500957,
		lng: 114.8364941,
		pop: 164844,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Kalimantan Selatan',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Ruteng',
		city_ascii: 'Ruteng',
		lat: -8.611839987,
		lng: 120.4698453,
		pop: 44272.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Nusa Tenggara Timur',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Jambi',
		city_ascii: 'Jambi',
		lat: -1.589994691,
		lng: 103.6100476,
		pop: 438706.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jambi',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Manokwari',
		city_ascii: 'Manokwari',
		lat: -0.871123841,
		lng: 134.0692736,
		pop: 63847,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Irian Jaya Barat',
		timezone: 'Asia/Jayapura',
	},
	{
		city: 'Ternate',
		city_ascii: 'Ternate',
		lat: 0.792960631,
		lng: 127.3630163,
		pop: 144626,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Maluku Utara',
		timezone: 'Asia/Jayapura',
	},
	{
		city: 'Ambon',
		city_ascii: 'Ambon',
		lat: -3.716686586,
		lng: 128.2000195,
		pop: 227561,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Maluku',
		timezone: 'Asia/Jayapura',
	},
	{
		city: 'Raba',
		city_ascii: 'Raba',
		lat: -8.449989401,
		lng: 118.7666418,
		pop: 106101,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Nusa Tenggara Barat',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Jayapura',
		city_ascii: 'Jayapura',
		lat: -2.532986228,
		lng: 140.69998,
		pop: 152118,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Papua',
		timezone: 'Asia/Jayapura',
	},
	{
		city: 'Banda Aceh',
		city_ascii: 'Banda Aceh',
		lat: 5.549982929,
		lng: 95.32001094,
		pop: 344065.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Aceh',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Balikpapan',
		city_ascii: 'Balikpapan',
		lat: -1.250015443,
		lng: 116.8300158,
		pop: 439885.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Kalimantan Timur',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Surakarta',
		city_ascii: 'Surakarta',
		lat: -7.564978822,
		lng: 110.8250077,
		pop: 555308,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Tengah',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Bandar Lampung',
		city_ascii: 'Bandar Lampung',
		lat: -5.430018698,
		lng: 105.2699979,
		pop: 795757,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Lampung',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Tanjungpandan',
		city_ascii: 'Tanjungpandan',
		lat: -2.750027243,
		lng: 107.6500077,
		pop: 61591,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Bangka-Belitung',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Malang',
		city_ascii: 'Malang',
		lat: -7.97999225,
		lng: 112.610015,
		pop: 775858,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Timur',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Kupang',
		city_ascii: 'Kupang',
		lat: -10.17866941,
		lng: 123.5829886,
		pop: 270798,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Nusa Tenggara Timur',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Parepare',
		city_ascii: 'Parepare',
		lat: -4.016668275,
		lng: 119.6333073,
		pop: 87776,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sulawesi Selatan',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Gorontalo',
		city_ascii: 'Gorontalo',
		lat: 0.549978047,
		lng: 123.0700484,
		pop: 254846,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Gorontalo',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Padang',
		city_ascii: 'Padang',
		lat: -0.960007305,
		lng: 100.3600134,
		pop: 847676,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sumatera Barat',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Tarakan',
		city_ascii: 'Tarakan',
		lat: 3.300016906,
		lng: 117.6330159,
		pop: 145273.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Kalimantan Timur',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Semarang',
		city_ascii: 'Semarang',
		lat: -6.966617412,
		lng: 110.4200195,
		pop: 1342042,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Tengah',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Palembang',
		city_ascii: 'Palembang',
		lat: -2.980039043,
		lng: 104.7500297,
		pop: 1595250,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sumatera Selatan',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Bandjarmasin',
		city_ascii: 'Bandjarmasin',
		lat: -3.329991843,
		lng: 114.5800756,
		pop: 588206.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Kalimantan Selatan',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Ujungpandang',
		city_ascii: 'Ujungpandang',
		lat: -5.139958884,
		lng: 119.4320275,
		pop: 1262000,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sulawesi Selatan',
		timezone: 'Asia/Makassar',
	},
	{
		city: 'Medan',
		city_ascii: 'Medan',
		lat: 3.579973978,
		lng: 98.65004024,
		pop: 1932985.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Sumatera Utara',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Bandung',
		city_ascii: 'Bandung',
		lat: -6.950029278,
		lng: 107.5700126,
		pop: 2046859.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Barat',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Surabaya',
		city_ascii: 'Surabaya',
		lat: -7.249235821,
		lng: 112.7508333,
		pop: 2609829,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jawa Timur',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Jakarta',
		city_ascii: 'Jakarta',
		lat: -6.174417705,
		lng: 106.8294376,
		pop: 8832560.5,
		country: 'Indonesia',
		iso2: 'ID',
		iso3: 'IDN',
		province: 'Jakarta Raya',
		timezone: 'Asia/Jakarta',
	},
	{
		city: 'Yasuj',
		city_ascii: 'Yasuj',
		lat: 30.65900412,
		lng: 51.59400361,
		pop: 96786,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Kohgiluyeh and Buyer Ahmad',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Shar e Kord',
		city_ascii: 'Shar e Kord',
		lat: 32.32099805,
		lng: 50.85399659,
		pop: 129153,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Chahar Mahall and Bakhtiari',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Marv Dasht',
		city_ascii: 'Marv Dasht',
		lat: 29.80144838,
		lng: 52.82146806,
		pop: 124429,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Fars',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Shahrud',
		city_ascii: 'Shahrud',
		lat: 36.42287884,
		lng: 54.96288773,
		pop: 125304,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Semnan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Varamin',
		city_ascii: 'Varamin',
		lat: 35.31658978,
		lng: 51.64660437,
		pop: 172215,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Tehran',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Masjed Soleyman',
		city_ascii: 'Masjed Soleyman',
		lat: 31.97999758,
		lng: 49.2999259,
		pop: 132586.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Khuzestan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Borujerd',
		city_ascii: 'Borujerd',
		lat: 33.91995668,
		lng: 48.8000081,
		pop: 251958,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Lorestan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Malayer',
		city_ascii: 'Malayer',
		lat: 34.31998395,
		lng: 48.84997921,
		pop: 176573,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Hamadan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Zanjan',
		city_ascii: 'Zanjan',
		lat: 36.67002138,
		lng: 48.50002641,
		pop: 355012.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Zanjan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Ahar',
		city_ascii: 'Ahar',
		lat: 38.48290814,
		lng: 47.06290482,
		pop: 98993.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'East Azarbaijan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Sanandaj',
		city_ascii: 'Sanandaj',
		lat: 35.30000165,
		lng: 47.02001339,
		pop: 331798,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Kordestan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Neyshabur',
		city_ascii: 'Neyshabur',
		lat: 36.22002301,
		lng: 58.82001664,
		pop: 221314.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Razavi Khorasan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Bojnurd',
		city_ascii: 'Bojnurd',
		lat: 37.46999839,
		lng: 57.32000484,
		pop: 200311.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'North Khorasan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Sirjan',
		city_ascii: 'Sirjan',
		lat: 29.46996991,
		lng: 55.73002437,
		pop: 171007,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Kerman',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Qomsheh',
		city_ascii: 'Qomsheh',
		lat: 32.01149436,
		lng: 51.85971798,
		pop: 118301,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Esfahan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Kashan',
		city_ascii: 'Kashan',
		lat: 33.98041811,
		lng: 51.57999345,
		pop: 249394.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Esfahan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Khomeini Shahr',
		city_ascii: 'Khomeini Shahr',
		lat: 32.70041872,
		lng: 51.46997432,
		pop: 437138,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Esfahan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Fasa',
		city_ascii: 'Fasa',
		lat: 28.97183494,
		lng: 53.67149369,
		pop: 111259.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Fars',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Gonbad-e Kavus',
		city_ascii: 'Gonbad-e Kavus',
		lat: 37.25182049,
		lng: 55.17145382,
		pop: 145699,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Golestan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Gorgan',
		city_ascii: 'Gorgan',
		lat: 36.83034751,
		lng: 54.48002315,
		pop: 262980,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Golestan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Amol',
		city_ascii: 'Amol',
		lat: 36.4713255,
		lng: 52.36330481,
		pop: 210516,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Mazandaran',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Sari',
		city_ascii: 'Sari',
		lat: 36.55039044,
		lng: 53.10000403,
		pop: 263431.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Mazandaran',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Semnan',
		city_ascii: 'Semnan',
		lat: 35.5547923,
		lng: 53.37430253,
		pop: 117888,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Semnan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Karaj',
		city_ascii: 'Karaj',
		lat: 35.8003587,
		lng: 50.97000484,
		pop: 1423000,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Tehran',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Behbehan',
		city_ascii: 'Behbehan',
		lat: 30.58181419,
		lng: 50.26146928,
		pop: 82517,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Khuzestan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Dezful',
		city_ascii: 'Dezful',
		lat: 32.38038658,
		lng: 48.4700024,
		pop: 315482,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Khuzestan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Khorramabad',
		city_ascii: 'Khorramabad',
		lat: 33.48042279,
		lng: 48.35000972,
		pop: 352511.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Lorestan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Ilam',
		city_ascii: 'Ilam',
		lat: 33.63041363,
		lng: 46.43002356,
		pop: 146917,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Ilam',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Saveh',
		city_ascii: 'Saveh',
		lat: 35.02182741,
		lng: 50.33143917,
		pop: 145384.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Markazi',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Arak',
		city_ascii: 'Arak',
		lat: 34.08041201,
		lng: 49.70000484,
		pop: 463449,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Markazi',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Mahabad',
		city_ascii: 'Mahabad',
		lat: 36.77037701,
		lng: 45.72004106,
		pop: 153428.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'West Azarbaijan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Khvoy',
		city_ascii: 'Khvoy',
		lat: 38.53039878,
		lng: 44.97000932,
		pop: 189049,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'West Azarbaijan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Maragheh',
		city_ascii: 'Maragheh',
		lat: 37.42038902,
		lng: 46.22001054,
		pop: 151385,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'East Azarbaijan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Qasr-e Shirin',
		city_ascii: 'Qasr-e Shirin',
		lat: 34.5123753,
		lng: 45.5772074,
		pop: 11202,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Kermanshah',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Bijar',
		city_ascii: 'Bijar',
		lat: 35.87407513,
		lng: 47.59367346,
		pop: 48806,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Kordestan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Yazdan',
		city_ascii: 'Yazdan',
		lat: 33.50649355,
		lng: 60.89379187,
		pop: 1894,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'South Khorasan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Torbat-e Jam',
		city_ascii: 'Torbat-e Jam',
		lat: 35.22333966,
		lng: 60.61287878,
		pop: 81753,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Razavi Khorasan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Quchan',
		city_ascii: 'Quchan',
		lat: 37.11182904,
		lng: 58.50148311,
		pop: 128641.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Razavi Khorasan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Chabahar',
		city_ascii: 'Chabahar',
		lat: 25.30040529,
		lng: 60.62993201,
		pop: 56544,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Sistan and Baluchestan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Kashmar',
		city_ascii: 'Kashmar',
		lat: 35.18143007,
		lng: 58.45146033,
		pop: 126643,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Razavi Khorasan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Bam',
		city_ascii: 'Bam',
		lat: 29.10769228,
		lng: 58.36195675,
		pop: 99268,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Kerman',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Kerman',
		city_ascii: 'Kerman',
		lat: 30.29999676,
		lng: 57.08001949,
		pop: 556518,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Kerman',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Bandar-e Bushehr',
		city_ascii: 'Bandar-e Bushehr',
		lat: 28.91997764,
		lng: 50.83001339,
		pop: 167218.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Bushehr',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Abadan',
		city_ascii: 'Abadan',
		lat: 30.33074424,
		lng: 48.2796781,
		pop: 315129,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Khuzestan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Ardabil',
		city_ascii: 'Ardabil',
		lat: 38.25000246,
		lng: 48.30003861,
		pop: 412678,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Ardebil',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Qom',
		city_ascii: 'Qom',
		lat: 34.65001548,
		lng: 50.95000606,
		pop: 933478,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Qom',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Qazvin',
		city_ascii: 'Qazvin',
		lat: 36.27001996,
		lng: 49.99998653,
		pop: 399093,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Qazvin',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Kermanshah',
		city_ascii: 'Kermanshah',
		lat: 34.38000612,
		lng: 47.06001094,
		pop: 828313,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Kermanshah',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Rasht',
		city_ascii: 'Rasht',
		lat: 37.29998293,
		lng: 49.62998328,
		pop: 544737.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Gilan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Birjand',
		city_ascii: 'Birjand',
		lat: 32.88002016,
		lng: 59.21994055,
		pop: 260842.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'South Khorasan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Sabzewar',
		city_ascii: 'Sabzewar',
		lat: 36.22002301,
		lng: 57.63001176,
		pop: 215910.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Razavi Khorasan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Zabol',
		city_ascii: 'Zabol',
		lat: 31.02145144,
		lng: 61.48145626,
		pop: 177978.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Sistan and Baluchestan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Zahedan',
		city_ascii: 'Zahedan',
		lat: 29.49999392,
		lng: 60.83002315,
		pop: 575433.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Sistan and Baluchestan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Yazd',
		city_ascii: 'Yazd',
		lat: 31.92005292,
		lng: 54.37000403,
		pop: 451923.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Yazd',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Ahvaz',
		city_ascii: 'Ahvaz',
		lat: 31.27998863,
		lng: 48.72001298,
		pop: 918572.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Khuzestan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Bandar-e-Abbas',
		city_ascii: 'Bandar-e-Abbas',
		lat: 27.20405978,
		lng: 56.27213554,
		pop: 414503.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Hormozgan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Hamadan',
		city_ascii: 'Hamadan',
		lat: 34.79602724,
		lng: 48.51501257,
		pop: 264293,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Hamadan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Tabriz',
		city_ascii: 'Tabriz',
		lat: 38.08629152,
		lng: 46.30124589,
		pop: 1304713,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'East Azarbaijan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Isfahan',
		city_ascii: 'Isfahan',
		lat: 32.70000531,
		lng: 51.7000378,
		pop: 1572883,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Esfahan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Shiraz',
		city_ascii: 'Shiraz',
		lat: 29.62996014,
		lng: 52.57001054,
		pop: 1240000,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Fars',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Mashhad',
		city_ascii: 'Mashhad',
		lat: 36.27001996,
		lng: 59.5699967,
		pop: 2318126.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Razavi Khorasan',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Tehran',
		city_ascii: 'Tehran',
		lat: 35.67194277,
		lng: 51.42434403,
		pop: 7513154.5,
		country: 'Iran',
		iso2: 'IR',
		iso3: 'IRN',
		province: 'Tehran',
		timezone: 'Asia/Tehran',
	},
	{
		city: 'Dahuk',
		city_ascii: 'Dahuk',
		lat: 36.86670013,
		lng: 43.00000263,
		pop: 620500,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Dihok',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Samarra',
		city_ascii: 'Samarra',
		lat: 34.19399705,
		lng: 43.87500062,
		pop: 158508,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Sala ad-Din',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Az Aubayr',
		city_ascii: 'Az Aubayr',
		lat: 30.38916445,
		lng: 47.70798173,
		pop: 192447.5,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Al-Basrah',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Ad Diwaniyah',
		city_ascii: 'Ad Diwaniyah',
		lat: 31.9889376,
		lng: 44.92396562,
		pop: 338604.5,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Al-Qadisiyah',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Ash Shatrah',
		city_ascii: 'Ash Shatrah',
		lat: 31.41752545,
		lng: 46.17722245,
		pop: 122340.5,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Dhi-Qar',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Mandali',
		city_ascii: 'Mandali',
		lat: 33.74361086,
		lng: 45.54635657,
		pop: 16420.5,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Diyala',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Ar Ramadi',
		city_ascii: 'Ar Ramadi',
		lat: 33.42001304,
		lng: 43.29998205,
		pop: 284830,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Al-Anbar',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Al Musayyib',
		city_ascii: 'Al Musayyib',
		lat: 32.778631,
		lng: 44.28999914,
		pop: 59677.5,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Babil',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Zakho',
		city_ascii: 'Zakho',
		lat: 37.14454022,
		lng: 42.68720292,
		pop: 114957.5,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Dihok',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Tall Afar',
		city_ascii: 'Tall Afar',
		lat: 36.37598248,
		lng: 42.44974971,
		pop: 144465,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Ninawa',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Tikrit',
		city_ascii: 'Tikrit',
		lat: 34.59704714,
		lng: 43.67696163,
		pop: 49534,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Sala ad-Din',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Karbala',
		city_ascii: 'Karbala',
		lat: 32.61492006,
		lng: 44.02448564,
		pop: 472571,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Karbala',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'As Samawah',
		city_ascii: 'As Samawah',
		lat: 31.3098576,
		lng: 45.28027462,
		pop: 163934,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Al-Muthannia',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'An Nasiriyah',
		city_ascii: 'An Nasiriyah',
		lat: 31.04294883,
		lng: 46.26755286,
		pop: 425898,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Dhi-Qar',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Al Amarah',
		city_ascii: 'Al Amarah',
		lat: 31.84160809,
		lng: 47.15116817,
		pop: 334154.5,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Maysan',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Al Kut',
		city_ascii: 'Al Kut',
		lat: 32.49071576,
		lng: 45.83037024,
		pop: 318341.5,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Wasit',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'As Sulaymaniyah',
		city_ascii: 'As Sulaymaniyah',
		lat: 35.56127769,
		lng: 45.43085974,
		pop: 654318,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'As-Sulaymaniyah',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Baqubah',
		city_ascii: 'Baqubah',
		lat: 33.74764162,
		lng: 44.65726355,
		pop: 226014.5,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Diyala',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Ar Rutbah',
		city_ascii: 'Ar Rutbah',
		lat: 33.0384601,
		lng: 40.28445552,
		pop: 17199.5,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Al-Anbar',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Al Fallujah',
		city_ascii: 'Al Fallujah',
		lat: 33.34766604,
		lng: 43.77726558,
		pop: 210989,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Al-Anbar',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Al Hillah',
		city_ascii: 'Al Hillah',
		lat: 32.47213808,
		lng: 44.42172237,
		pop: 479652.5,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Babil',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Irbil',
		city_ascii: 'Irbil',
		lat: 36.1790436,
		lng: 44.00862097,
		pop: 795870,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Arbil',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Kirkuk',
		city_ascii: 'Kirkuk',
		lat: 35.4722392,
		lng: 44.3922668,
		pop: 555052.5,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: "At-Ta'mim",
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Mosul',
		city_ascii: 'Mosul',
		lat: 36.34500246,
		lng: 43.14500443,
		pop: 1228467,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Ninawa',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'An Najaf',
		city_ascii: 'An Najaf',
		lat: 32.00033225,
		lng: 44.33537105,
		pop: 612776,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'An-Najaf',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Basra',
		city_ascii: 'Basra',
		lat: 30.51352378,
		lng: 47.81355668,
		pop: 870000,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Al-Basrah',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Baghdad',
		city_ascii: 'Baghdad',
		lat: 33.3386485,
		lng: 44.39386877,
		pop: 5054000,
		country: 'Iraq',
		iso2: 'IQ',
		iso3: 'IRQ',
		province: 'Baghdad',
		timezone: 'Asia/Baghdad',
	},
	{
		city: 'Ros Comain',
		city_ascii: 'Ros Comain',
		lat: 53.6333333,
		lng: -8.1833333,
		pop: 4860,
		country: 'Ireland',
		iso2: 'IE',
		iso3: 'IRL',
		province: 'Roscommon',
		timezone: 'Europe/Dublin',
	},
	{
		city: 'Muineachan',
		city_ascii: 'Muineachan',
		lat: 54.25,
		lng: -6.9666667,
		pop: 5937,
		country: 'Ireland',
		iso2: 'IE',
		iso3: 'IRL',
		province: 'Monaghan',
		timezone: 'Europe/Dublin',
	},
	{
		city: 'Shannon',
		city_ascii: 'Shannon',
		lat: 52.7038489,
		lng: -8.864146567,
		pop: 8143.5,
		country: 'Ireland',
		iso2: 'IE',
		iso3: 'IRL',
		province: 'Clare',
		timezone: 'Europe/Dublin',
	},
	{
		city: 'Waterford',
		city_ascii: 'Waterford',
		lat: 52.2582947,
		lng: -7.111927939,
		pop: 49275,
		country: 'Ireland',
		iso2: 'IE',
		iso3: 'IRL',
		province: 'Kilkenny',
		timezone: 'Europe/Dublin',
	},
	{
		city: 'Tralee',
		city_ascii: 'Tralee',
		lat: 52.26669212,
		lng: -9.716652671,
		pop: 24662.5,
		country: 'Ireland',
		iso2: 'IE',
		iso3: 'IRL',
		province: 'Kerry',
		timezone: 'Europe/Dublin',
	},
	{
		city: 'Donegal',
		city_ascii: 'Donegal',
		lat: 54.65000917,
		lng: -8.116672812,
		pop: 2229,
		country: 'Ireland',
		iso2: 'IE',
		iso3: 'IRL',
		province: 'Donegal',
		timezone: 'Europe/Dublin',
	},
	{
		city: 'Drogheda',
		city_ascii: 'Drogheda',
		lat: 53.71926495,
		lng: -6.347762697,
		pop: 34987,
		country: 'Ireland',
		iso2: 'IE',
		iso3: 'IRL',
		province: 'Louth',
		timezone: 'Europe/Dublin',
	},
	{
		city: 'Dundalk',
		city_ascii: 'Dundalk',
		lat: 54.00041058,
		lng: -6.416673219,
		pop: 38884,
		country: 'Ireland',
		iso2: 'IE',
		iso3: 'IRL',
		province: 'Louth',
		timezone: 'Europe/Dublin',
	},
	{
		city: 'Galway',
		city_ascii: 'Galway',
		lat: 53.272393,
		lng: -9.048812298,
		pop: 73140,
		country: 'Ireland',
		iso2: 'IE',
		iso3: 'IRL',
		province: 'Galway',
		timezone: 'Europe/Dublin',
	},
	{
		city: 'Kilkenny',
		city_ascii: 'Kilkenny',
		lat: 52.65454958,
		lng: -7.252255291,
		pop: 21401,
		country: 'Ireland',
		iso2: 'IE',
		iso3: 'IRL',
		province: 'Kilkenny',
		timezone: 'Europe/Dublin',
	},
	{
		city: 'Killarney',
		city_ascii: 'Killarney',
		lat: 52.05040041,
		lng: -9.516664878,
		pop: 9601,
		country: 'Ireland',
		iso2: 'IE',
		iso3: 'IRL',
		province: 'Kerry',
		timezone: 'Europe/Dublin',
	},
	{
		city: 'Sligo',
		city_ascii: 'Sligo',
		lat: 54.26706097,
		lng: -8.483317099,
		pop: 17214,
		country: 'Ireland',
		iso2: 'IE',
		iso3: 'IRL',
		province: 'Sligo',
		timezone: 'Europe/Dublin',
	},
	{
		city: 'Cork',
		city_ascii: 'Cork',
		lat: 51.89860089,
		lng: -8.49577112,
		pop: 162852,
		country: 'Ireland',
		iso2: 'IE',
		iso3: 'IRL',
		province: 'Cork',
		timezone: 'Europe/Dublin',
	},
	{
		city: 'Limerick',
		city_ascii: 'Limerick',
		lat: 52.664704,
		lng: -8.623050172,
		pop: 84066,
		country: 'Ireland',
		iso2: 'IE',
		iso3: 'IRL',
		province: 'Limerick',
		timezone: 'Europe/Dublin',
	},
	{
		city: 'Dublin',
		city_ascii: 'Dublin',
		lat: 53.33306114,
		lng: -6.248905682,
		pop: 1013988,
		country: 'Ireland',
		iso2: 'IE',
		iso3: 'IRL',
		province: 'Dublin',
		timezone: 'Europe/Dublin',
	},
	{
		city: 'Douglas',
		city_ascii: 'Douglas',
		lat: 54.15042727,
		lng: -4.480021404,
		pop: 31036,
		country: 'Isle of Man',
		iso2: 'IM',
		iso3: 'IMN',
		province: '',
		timezone: 'Europe/Isle_of_Man',
	},
	{
		city: 'Ramla',
		city_ascii: 'Ramla',
		lat: 31.91670012,
		lng: 34.86670252,
		pop: 63860,
		country: 'Israel',
		iso2: 'IL',
		iso3: 'ISR',
		province: 'HaMerkaz',
		timezone: 'Asia/Jerusalem',
	},
	{
		city: 'Beer Sheva',
		city_ascii: 'Beer Sheva',
		lat: 31.2500163,
		lng: 34.8300081,
		pop: 196504,
		country: 'Israel',
		iso2: 'IL',
		iso3: 'ISR',
		province: 'HaDarom',
		timezone: 'Asia/Jerusalem',
	},
	{
		city: 'Haifa',
		city_ascii: 'Haifa',
		lat: 32.8204114,
		lng: 34.98002478,
		pop: 639150,
		country: 'Israel',
		iso2: 'IL',
		iso3: 'ISR',
		province: 'Haifa',
		timezone: 'Asia/Jerusalem',
	},
	{
		city: 'Nazareth',
		city_ascii: 'Nazareth',
		lat: 32.70398439,
		lng: 35.2955094,
		pop: 108129.5,
		country: 'Israel',
		iso2: 'IL',
		iso3: 'ISR',
		province: 'HaZafon',
		timezone: 'Asia/Jerusalem',
	},
	{
		city: 'Jerusalem',
		city_ascii: 'Jerusalem',
		lat: 31.77840782,
		lng: 35.20662593,
		pop: 915150,
		country: 'Israel',
		iso2: 'IL',
		iso3: 'ISR',
		province: 'Jerusalem',
		timezone: 'Asia/Jerusalem',
	},
	{
		city: 'Tel Aviv-Yafo',
		city_ascii: 'Tel Aviv-Yafo',
		lat: 32.07999147,
		lng: 34.77001176,
		pop: 1745179,
		country: 'Israel',
		iso2: 'IL',
		iso3: 'ISR',
		province: 'Tel Aviv',
		timezone: 'Asia/Jerusalem',
	},
	{
		city: 'Potenza',
		city_ascii: 'Potenza',
		lat: 40.64200213,
		lng: 15.7989965,
		pop: 69060,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Basilicata',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Campobasso',
		city_ascii: 'Campobasso',
		lat: 41.56299912,
		lng: 14.65599656,
		pop: 50762,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Molise',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Aosta',
		city_ascii: 'Aosta',
		lat: 45.73700107,
		lng: 7.315002596,
		pop: 34062,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: "Valle d'Aosta",
		timezone: 'Europe/Rome',
	},
	{
		city: 'Modena',
		city_ascii: 'Modena',
		lat: 44.65002525,
		lng: 10.91999467,
		pop: 175034.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Emilia-Romagna',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Crotone',
		city_ascii: 'Crotone',
		lat: 39.08333661,
		lng: 17.12333695,
		pop: 59313.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Calabria',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Vibo Valentia',
		city_ascii: 'Vibo Valentia',
		lat: 38.66659202,
		lng: 16.10004024,
		pop: 32168,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Calabria',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Reggio di Calabria',
		city_ascii: 'Reggio di Calabria',
		lat: 38.11499778,
		lng: 15.64136023,
		pop: 179034.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Calabria',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Caserta',
		city_ascii: 'Caserta',
		lat: 41.05996014,
		lng: 14.33735714,
		pop: 164744,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Campania',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Barletta',
		city_ascii: 'Barletta',
		lat: 41.31999595,
		lng: 16.27000403,
		pop: 99962,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Apulia',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Ragusa',
		city_ascii: 'Ragusa',
		lat: 36.93003135,
		lng: 14.72999467,
		pop: 67361,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Sicily',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Asti',
		city_ascii: 'Asti',
		lat: 44.92998232,
		lng: 8.209979206,
		pop: 63410.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Piemonte',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Novara',
		city_ascii: 'Novara',
		lat: 45.45000226,
		lng: 8.61998002,
		pop: 88966,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Piemonte',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Como',
		city_ascii: 'Como',
		lat: 45.81000612,
		lng: 9.08000362,
		pop: 167438,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Lombardia',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Udine',
		city_ascii: 'Udine',
		lat: 46.07001609,
		lng: 13.2400081,
		pop: 107019.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Friuli-Venezia Giulia',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Treviso',
		city_ascii: 'Treviso',
		lat: 45.67001467,
		lng: 12.24001745,
		pop: 128726.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Veneto',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Parma',
		city_ascii: 'Parma',
		lat: 44.81042889,
		lng: 10.32003129,
		pop: 164734,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Emilia-Romagna',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Ravenna',
		city_ascii: 'Ravenna',
		lat: 44.42037518,
		lng: 12.22001868,
		pop: 124302.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Emilia-Romagna',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Ferrara',
		city_ascii: 'Ferrara',
		lat: 44.85042645,
		lng: 11.60992672,
		pop: 121754,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Emilia-Romagna',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Bologna',
		city_ascii: 'Bologna',
		lat: 44.50042198,
		lng: 11.34002071,
		pop: 429694.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Emilia-Romagna',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Olbia',
		city_ascii: 'Olbia',
		lat: 40.9142849,
		lng: 9.515071858,
		pop: 44341,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Sardegna',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Cagliari',
		city_ascii: 'Cagliari',
		lat: 39.22239789,
		lng: 9.103981485,
		pop: 227880,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Sardegna',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Pisa',
		city_ascii: 'Pisa',
		lat: 43.72046958,
		lng: 10.40002641,
		pop: 146515,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Toscana',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Livorno',
		city_ascii: 'Livorno',
		lat: 43.55113366,
		lng: 10.3022747,
		pop: 145016.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Toscana',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Siena',
		city_ascii: 'Siena',
		lat: 43.31703168,
		lng: 11.34999426,
		pop: 48731,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Toscana',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Arezzo',
		city_ascii: 'Arezzo',
		lat: 43.46172569,
		lng: 11.87497514,
		pop: 82613,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Toscana',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Catanzaro',
		city_ascii: 'Catanzaro',
		lat: 38.9003762,
		lng: 16.60000972,
		pop: 90541,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Calabria',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Salerno',
		city_ascii: 'Salerno',
		lat: 40.68039675,
		lng: 14.76994055,
		pop: 546922,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Campania',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Benevento',
		city_ascii: 'Benevento',
		lat: 41.13370241,
		lng: 14.74999345,
		pop: 59280,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Campania',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Bari',
		city_ascii: 'Bari',
		lat: 41.1142204,
		lng: 16.87275793,
		pop: 408554.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Apulia',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Foggia',
		city_ascii: 'Foggia',
		lat: 41.46047833,
		lng: 15.55996985,
		pop: 147028,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Apulia',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Lecce',
		city_ascii: 'Lecce',
		lat: 40.36039044,
		lng: 18.14999263,
		pop: 122942.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Apulia',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Brindisi',
		city_ascii: 'Brindisi',
		lat: 40.64034751,
		lng: 17.93000606,
		pop: 96759,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Apulia',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Taranto',
		city_ascii: 'Taranto',
		lat: 40.50839174,
		lng: 17.22999711,
		pop: 148807,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Apulia',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Messina',
		city_ascii: 'Messina',
		lat: 38.2004706,
		lng: 15.5499963,
		pop: 224047.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Sicily',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Marsala',
		city_ascii: 'Marsala',
		lat: 37.80540428,
		lng: 12.43866166,
		pop: 60481.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Sicily',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Siracusa',
		city_ascii: 'Siracusa',
		lat: 37.0703587,
		lng: 15.28996049,
		pop: 123110,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Sicily',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Pescara',
		city_ascii: 'Pescara',
		lat: 42.45543052,
		lng: 14.21865637,
		pop: 215537.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Abruzzo',
		timezone: 'Europe/Rome',
	},
	{
		city: "L'Aquila",
		city_ascii: "L'Aquila",
		lat: 42.35039817,
		lng: 13.39002478,
		pop: 62201.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Abruzzo',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Civitavecchia',
		city_ascii: 'Civitavecchia',
		lat: 42.10041343,
		lng: 11.79999263,
		pop: 55674,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Lazio',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Ancona',
		city_ascii: 'Ancona',
		lat: 43.60037355,
		lng: 13.49994055,
		pop: 95599,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Marche',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Perugia',
		city_ascii: 'Perugia',
		lat: 43.11037762,
		lng: 12.38998246,
		pop: 141998,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Umbria',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Bergamo',
		city_ascii: 'Bergamo',
		lat: 45.70040041,
		lng: 9.669993448,
		pop: 160658,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Lombardia',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Trieste',
		city_ascii: 'Trieste',
		lat: 45.65037762,
		lng: 13.80002559,
		pop: 213609.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Friuli-Venezia Giulia',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Bolzano',
		city_ascii: 'Bolzano',
		lat: 46.5004291,
		lng: 11.36001949,
		pop: 95442,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Trentino-Alto Adige',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Trento',
		city_ascii: 'Trento',
		lat: 46.08042889,
		lng: 11.11998246,
		pop: 106377,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Trentino-Alto Adige',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Verona',
		city_ascii: 'Verona',
		lat: 45.44039044,
		lng: 10.99001623,
		pop: 300333.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Veneto',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Sassari',
		city_ascii: 'Sassari',
		lat: 40.73000612,
		lng: 8.57000891,
		pop: 102822.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Sardegna',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Turin',
		city_ascii: 'Turin',
		lat: 45.07038719,
		lng: 7.669960489,
		pop: 1258631.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Piemonte',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Genoa',
		city_ascii: 'Genoa',
		lat: 44.40998822,
		lng: 8.930038614,
		pop: 624724,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Liguria',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Florence',
		city_ascii: 'Florence',
		lat: 43.78000083,
		lng: 11.25000036,
		pop: 935758.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Toscana',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Catania',
		city_ascii: 'Catania',
		lat: 37.49997072,
		lng: 15.07999914,
		pop: 482908,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Sicily',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Venice',
		city_ascii: 'Venice',
		lat: 45.43865928,
		lng: 12.33499874,
		pop: 270816,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Veneto',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Palermo',
		city_ascii: 'Palermo',
		lat: 38.12502301,
		lng: 13.35002722,
		pop: 767587.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Sicily',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Naples',
		city_ascii: 'Naples',
		lat: 40.84002525,
		lng: 14.24501135,
		pop: 1619486,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Campania',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Milan',
		city_ascii: 'Milan',
		lat: 45.4699752,
		lng: 9.20500891,
		pop: 2125830.5,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Lombardia',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Rome',
		city_ascii: 'Rome',
		lat: 41.89595563,
		lng: 12.48325842,
		pop: 1687226,
		country: 'Italy',
		iso2: 'IT',
		iso3: 'ITA',
		province: 'Lazio',
		timezone: 'Europe/Rome',
	},
	{
		city: 'Touba',
		city_ascii: 'Touba',
		lat: 8.280000029,
		lng: -7.684001549,
		pop: 27504,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Bafing',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Bouafle',
		city_ascii: 'Bouafle',
		lat: 6.977997104,
		lng: -5.748002428,
		pop: 60962,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Marahoué',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Divo',
		city_ascii: 'Divo',
		lat: 5.839002037,
		lng: -5.360003483,
		pop: 127867,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Sud-Bandama',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Toumodi',
		city_ascii: 'Toumodi',
		lat: 6.552000132,
		lng: -5.019002394,
		pop: 39005,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Lacs',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Aboisso',
		city_ascii: 'Aboisso',
		lat: 5.466699052,
		lng: -3.20000353,
		pop: 37654,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Sud-Comoé',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Ferkessedougou',
		city_ascii: 'Ferkessedougou',
		lat: 9.600407531,
		lng: -5.200029136,
		pop: 57410,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Savanes',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Odienne',
		city_ascii: 'Odienne',
		lat: 9.510413024,
		lng: -7.580013063,
		pop: 34488,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Denguélé',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Man',
		city_ascii: 'Man',
		lat: 7.400412617,
		lng: -7.549989056,
		pop: 143157.5,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Dix-Huit Montagnes',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Seguela',
		city_ascii: 'Seguela',
		lat: 7.950404886,
		lng: -6.670016929,
		pop: 31880,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Worodougou',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Gagnoa',
		city_ascii: 'Gagnoa',
		lat: 6.150411396,
		lng: -5.879987632,
		pop: 111188,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Fromager',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Soubre',
		city_ascii: 'Soubre',
		lat: 5.790407531,
		lng: -6.610020591,
		pop: 83712.5,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Bas-Sassandra',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'San-Pedro',
		city_ascii: 'San-Pedro',
		lat: 4.77041811,
		lng: -6.639967083,
		pop: 203512,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Bas-Sassandra',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Sassandra',
		city_ascii: 'Sassandra',
		lat: 4.950381286,
		lng: -6.083282716,
		pop: 30842.5,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Bas-Sassandra',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Bondoukou',
		city_ascii: 'Bondoukou',
		lat: 8.030425841,
		lng: -2.800020591,
		pop: 38501.5,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Zanzan',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Agboville',
		city_ascii: 'Agboville',
		lat: 5.940346699,
		lng: -4.280033611,
		pop: 73027.5,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Agnéby',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Dimbokro',
		city_ascii: 'Dimbokro',
		lat: 6.650458393,
		lng: -4.710007366,
		pop: 46467.5,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: "N'zi-Comoé",
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Grand Bassam',
		city_ascii: 'Grand Bassam',
		lat: 5.200391865,
		lng: -3.749988445,
		pop: 61226.5,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Lagunes',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Dabou',
		city_ascii: 'Dabou',
		lat: 5.320358703,
		lng: -4.389949383,
		pop: 71287,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Lagunes',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Guiglo',
		city_ascii: 'Guiglo',
		lat: 6.550464497,
		lng: -7.48996688,
		pop: 37490,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Moyen-Cavally',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Abengourou',
		city_ascii: 'Abengourou',
		lat: 6.730375996,
		lng: -3.490004315,
		pop: 87809,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Moyen-Comoe',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Korhogo',
		city_ascii: 'Korhogo',
		lat: 9.459976826,
		lng: -5.639950604,
		pop: 172535,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Savanes',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Daloa',
		city_ascii: 'Daloa',
		lat: 6.889978657,
		lng: -6.450004518,
		pop: 235410,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Haut-Sassandra',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Bouake',
		city_ascii: 'Bouake',
		lat: 7.689981505,
		lng: -5.030013673,
		pop: 511151,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Vallée du Bandama',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Yamoussoukro',
		city_ascii: 'Yamoussoukro',
		lat: 6.81838096,
		lng: -5.275502565,
		pop: 200514.5,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Lacs',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Abidjan',
		city_ascii: 'Abidjan',
		lat: 5.319996967,
		lng: -4.04004826,
		pop: 3496197.5,
		country: 'Ivory Coast',
		iso2: 'CI',
		iso3: 'CIV',
		province: 'Lagunes',
		timezone: 'Africa/Abidjan',
	},
	{
		city: 'Lucea',
		city_ascii: 'Lucea',
		lat: 18.44299809,
		lng: -78.17900362,
		pop: 6289,
		country: 'Jamaica',
		iso2: 'JM',
		iso3: 'JAM',
		province: 'Hanover',
		timezone: 'America/Jamaica',
	},
	{
		city: 'Mandeville',
		city_ascii: 'Mandeville',
		lat: 18.03300305,
		lng: -77.49999851,
		pop: 47115,
		country: 'Jamaica',
		iso2: 'JM',
		iso3: 'JAM',
		province: 'Manchester',
		timezone: 'America/Jamaica',
	},
	{
		city: 'Black River',
		city_ascii: 'Black River',
		lat: 18.03099607,
		lng: -77.85199748,
		pop: 4229,
		country: 'Jamaica',
		iso2: 'JM',
		iso3: 'JAM',
		province: 'Saint Elizabeth',
		timezone: 'America/Jamaica',
	},
	{
		city: 'Falmouth',
		city_ascii: 'Falmouth',
		lat: 18.47958305,
		lng: -77.6560496,
		pop: 7779,
		country: 'Jamaica',
		iso2: 'JM',
		iso3: 'JAM',
		province: 'Trelawny',
		timezone: 'America/Jamaica',
	},
	{
		city: 'Savanna-la-Mar',
		city_ascii: 'Savanna La Mar',
		lat: 18.1639981,
		lng: -77.94800051,
		pop: 25260.5,
		country: 'Jamaica',
		iso2: 'JM',
		iso3: 'JAM',
		province: 'Westmoreland',
		timezone: 'America/Jamaica',
	},
	{
		city: 'Port Antonio',
		city_ascii: 'Port Antonio',
		lat: 18.15900314,
		lng: -76.38000262,
		pop: 14400,
		country: 'Jamaica',
		iso2: 'JM',
		iso3: 'JAM',
		province: 'Portland',
		timezone: 'America/Jamaica',
	},
	{
		city: "St. Ann's Bay",
		city_ascii: 'St. Anns Bay',
		lat: 18.43263914,
		lng: -77.19952454,
		pop: 13671,
		country: 'Jamaica',
		iso2: 'JM',
		iso3: 'JAM',
		province: 'Saint Ann',
		timezone: 'America/Jamaica',
	},
	{
		city: 'Port Maria',
		city_ascii: 'Port Maria',
		lat: 18.37700105,
		lng: -76.89999547,
		pop: 7906,
		country: 'Jamaica',
		iso2: 'JM',
		iso3: 'JAM',
		province: 'Saint Mary',
		timezone: 'America/Jamaica',
	},
	{
		city: 'Half Way Tree',
		city_ascii: 'Halfway Tree',
		lat: 18.0333001,
		lng: -76.79999651,
		pop: 96494,
		country: 'Jamaica',
		iso2: 'JM',
		iso3: 'JAM',
		province: 'Saint Andrew',
		timezone: 'America/Jamaica',
	},
	{
		city: 'Port Morant',
		city_ascii: 'Port Morant',
		lat: 17.89100103,
		lng: -76.32899957,
		pop: 11536,
		country: 'Jamaica',
		iso2: 'JM',
		iso3: 'JAM',
		province: 'Saint Thomas',
		timezone: 'America/Jamaica',
	},
	{
		city: 'May Pen',
		city_ascii: 'May Pen',
		lat: 17.96661521,
		lng: -77.23328089,
		pop: 89948.5,
		country: 'Jamaica',
		iso2: 'JM',
		iso3: 'JAM',
		province: 'Clarendon',
		timezone: 'America/Jamaica',
	},
	{
		city: 'Spanish Town',
		city_ascii: 'Spanish Town',
		lat: 17.98333254,
		lng: -76.94999068,
		pop: 297531.5,
		country: 'Jamaica',
		iso2: 'JM',
		iso3: 'JAM',
		province: 'Saint Catherine',
		timezone: 'America/Jamaica',
	},
	{
		city: 'Montego Bay',
		city_ascii: 'Montego Bay',
		lat: 18.46668805,
		lng: -77.91667586,
		pop: 104437.5,
		country: 'Jamaica',
		iso2: 'JM',
		iso3: 'JAM',
		province: 'Saint James',
		timezone: 'America/Jamaica',
	},
	{
		city: 'Kingston',
		city_ascii: 'Kingston',
		lat: 17.97707662,
		lng: -76.76743371,
		pop: 801336.5,
		country: 'Jamaica',
		iso2: 'JM',
		iso3: 'JAM',
		province: 'Kingston',
		timezone: 'America/Jamaica',
	},
	{
		city: 'Okayama',
		city_ascii: 'Okayama',
		lat: 34.67202964,
		lng: 133.9170865,
		pop: 752872,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Okayama',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Shimonoseki',
		city_ascii: 'Shimonoseki',
		lat: 33.96543194,
		lng: 130.9454333,
		pop: 236198.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Yamaguchi',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Kanoya',
		city_ascii: 'Kanoya',
		lat: 31.38331565,
		lng: 130.8500386,
		pop: 68513.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Kagoshima',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Takamatsu',
		city_ascii: 'Takamatsu',
		lat: 34.34473696,
		lng: 134.044779,
		pop: 329861.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Kagawa',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Tokushima',
		city_ascii: 'Tokushima',
		lat: 34.06738955,
		lng: 134.5525,
		pop: 355552.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Tokushima',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Toyama',
		city_ascii: 'Toyama',
		lat: 36.69999371,
		lng: 137.2300109,
		pop: 329172,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Toyama',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Takaoka',
		city_ascii: 'Takaoka',
		lat: 36.67002138,
		lng: 136.9999991,
		pop: 124437,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Toyama',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Otsu',
		city_ascii: 'Otsu',
		lat: 35.006402,
		lng: 135.8674068,
		pop: 437802.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Shiga',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Maebashi',
		city_ascii: 'Maebashi',
		lat: 36.39269981,
		lng: 139.0726892,
		pop: 313791,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Gunma',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Kawasaki',
		city_ascii: 'Kawasaki',
		lat: 35.52998761,
		lng: 139.705002,
		pop: 1372025.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Kanagawa',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Kawagoe',
		city_ascii: 'Kawagoe',
		lat: 35.91769004,
		lng: 139.4910616,
		pop: 337931,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Saitama',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Utsunomiya',
		city_ascii: 'Utsunomiya',
		lat: 36.54997703,
		lng: 139.8700048,
		pop: 558808.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Tochigi',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Hachioji',
		city_ascii: 'Hachioji',
		lat: 35.65770591,
		lng: 139.3260587,
		pop: 579399,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Tokyo',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Koriyama',
		city_ascii: 'Koriyama',
		lat: 37.40997622,
		lng: 140.3799996,
		pop: 302581,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Fukushima',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Kure',
		city_ascii: 'Kure',
		lat: 34.25097007,
		lng: 132.5655928,
		pop: 196807.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Hiroshima',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Matsue',
		city_ascii: 'Matsue',
		lat: 35.46699404,
		lng: 133.0666475,
		pop: 150527,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Shimane',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Tottori',
		city_ascii: 'Tottori',
		lat: 35.50037701,
		lng: 134.2332946,
		pop: 142635.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Tottori',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Sasebo',
		city_ascii: 'Sasebo',
		lat: 33.1631295,
		lng: 129.7177046,
		pop: 224347.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Nagasaki',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Kitakyushu',
		city_ascii: 'Kitakyushu',
		lat: 33.87039899,
		lng: 130.8200146,
		pop: 990286.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Fukuoka',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Kumamoto',
		city_ascii: 'Kumamoto',
		lat: 32.80092938,
		lng: 130.700642,
		pop: 699327.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Kumamoto',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Oita',
		city_ascii: 'Oita',
		lat: 33.24322797,
		lng: 131.5978999,
		pop: 412100.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Oita',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Gifu',
		city_ascii: 'Gifu',
		lat: 35.42309491,
		lng: 136.7627526,
		pop: 405304.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Gifu',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Tsu',
		city_ascii: 'Tsu',
		lat: 34.71706565,
		lng: 136.5166695,
		pop: 392484.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Mie',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Matsumoto',
		city_ascii: 'Matsumoto',
		lat: 36.2404352,
		lng: 137.9700175,
		pop: 217796.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Nagano',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Shizuoka',
		city_ascii: 'Shizuoka',
		lat: 34.98583478,
		lng: 138.3853926,
		pop: 686446.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Shizuoka',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Hamamatsu',
		city_ascii: 'Hamamatsu',
		lat: 34.71807334,
		lng: 137.7327193,
		pop: 887242.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Shizuoka',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Obihiro',
		city_ascii: 'Obihiro',
		lat: 42.93041445,
		lng: 143.1700101,
		pop: 169614,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Hokkaido',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Tomakomai',
		city_ascii: 'Tomakomai',
		lat: 42.6504057,
		lng: 141.5500057,
		pop: 161355.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Hokkaido',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Kitami',
		city_ascii: 'Kitami',
		lat: 43.8503583,
		lng: 143.8999914,
		pop: 103971.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Hokkaido',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Otaru',
		city_ascii: 'Otaru',
		lat: 43.18871909,
		lng: 140.9783093,
		pop: 139260.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Hokkaido',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Fukui',
		city_ascii: 'Fukui',
		lat: 36.07041974,
		lng: 136.2200468,
		pop: 241288.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Fukui',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Maizuru',
		city_ascii: 'Maizuru',
		lat: 35.4504059,
		lng: 135.3333309,
		pop: 62531.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Kyoto',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Wakayama',
		city_ascii: 'Wakayama',
		lat: 34.22311647,
		lng: 135.1677079,
		pop: 395503,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Wakayama',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Mito',
		city_ascii: 'Mito',
		lat: 36.37042727,
		lng: 140.4800451,
		pop: 300215,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Ibaraki',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Kofu',
		city_ascii: 'Kofu',
		lat: 35.6503937,
		lng: 138.5833134,
		pop: 193770,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Yamanashi',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Iwaki',
		city_ascii: 'Iwaki',
		lat: 37.0553467,
		lng: 140.8900459,
		pop: 324677,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Fukushima',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Nagaoka',
		city_ascii: 'Nagaoka',
		lat: 37.45041302,
		lng: 138.8600406,
		pop: 187560,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Niigata',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Yamagata',
		city_ascii: 'Yamagata',
		lat: 38.27049217,
		lng: 140.3200032,
		pop: 263373.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Yamagata',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Tsuruoka',
		city_ascii: 'Tsuruoka',
		lat: 38.70041424,
		lng: 139.830214,
		pop: 88052.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Yamagata',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Kagoshima',
		city_ascii: 'Kagoshima',
		lat: 31.58596478,
		lng: 130.561064,
		pop: 536092.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Kagoshima',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Matsuyama',
		city_ascii: 'Matsuyama',
		lat: 33.84554262,
		lng: 132.765839,
		pop: 525089,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Ehime',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Kanazawa',
		city_ascii: 'Kanazawa',
		lat: 36.56000226,
		lng: 136.6400211,
		pop: 505093,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Ishikawa',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Muroran',
		city_ascii: 'Muroran',
		lat: 42.34995892,
		lng: 140.9800146,
		pop: 125936.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Hokkaido',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Asahikawa',
		city_ascii: 'Asahikawa',
		lat: 43.75501528,
		lng: 142.3799808,
		pop: 341079.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Hokkaido',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Kobe',
		city_ascii: 'Kobe',
		lat: 34.67998781,
		lng: 135.1699816,
		pop: 1528478,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Hyogo',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Yokohama',
		city_ascii: 'Yokohama',
		lat: 35.32002626,
		lng: 139.5800484,
		pop: 3697894,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Kanagawa',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Akita',
		city_ascii: 'Akita',
		lat: 39.70999086,
		lng: 140.0899914,
		pop: 300962.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Akita',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Aomori',
		city_ascii: 'Aomori',
		lat: 40.82503908,
		lng: 140.7100052,
		pop: 281571.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Aomori',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Hirosaki',
		city_ascii: 'Hirosaki',
		lat: 40.56999005,
		lng: 140.4700199,
		pop: 171700.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Aomori',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Hachinohe',
		city_ascii: 'Hachinohe',
		lat: 40.50999371,
		lng: 141.5400321,
		pop: 225575,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Aomori',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Fukushima',
		city_ascii: 'Fukushima',
		lat: 37.74000775,
		lng: 140.4700199,
		pop: 278961.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Fukushima',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Morioka',
		city_ascii: 'Morioka',
		lat: 39.72001609,
		lng: 141.1300313,
		pop: 294782.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Iwate',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Niigata',
		city_ascii: 'Niigata',
		lat: 37.91999676,
		lng: 139.0400297,
		pop: 537534.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Niigata',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Fukuoka',
		city_ascii: 'Fukuoka',
		lat: 33.59501528,
		lng: 130.4100138,
		pop: 2092144.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Fukuoka',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Miyazaki',
		city_ascii: 'Miyazaki',
		lat: 31.91824424,
		lng: 131.418376,
		pop: 317793.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Miyazaki',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Naha',
		city_ascii: 'Naha',
		lat: 26.20717165,
		lng: 127.6729716,
		pop: 611572,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Okinawa',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Kochi',
		city_ascii: 'Kochi',
		lat: 33.56243329,
		lng: 133.5375232,
		pop: 323095,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Kochi',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Nagoya',
		city_ascii: 'Nagoya',
		lat: 35.15499758,
		lng: 136.9149914,
		pop: 2710639.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Aichi',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Nagano',
		city_ascii: 'Nagano',
		lat: 36.64999676,
		lng: 138.1700052,
		pop: 477243.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Nagano',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Kushiro',
		city_ascii: 'Kushiro',
		lat: 42.97495953,
		lng: 144.3746911,
		pop: 191089,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Hokkaido',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Hakodate',
		city_ascii: 'Hakodate',
		lat: 41.79497988,
		lng: 140.7399776,
		pop: 289357,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Hokkaido',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Kyoto',
		city_ascii: 'Kyoto',
		lat: 35.02999229,
		lng: 135.7499979,
		pop: 1632320,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Kyoto',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Sendai',
		city_ascii: 'Sendai',
		lat: 38.28710614,
		lng: 141.0217175,
		pop: 1643781,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Miyagi',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Sakata',
		city_ascii: 'Sakata',
		lat: 38.92003908,
		lng: 139.8500577,
		pop: 86507.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Yamagata',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Nagasaki',
		city_ascii: 'Nagasaki',
		lat: 32.76498842,
		lng: 129.8850329,
		pop: 422829.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Nagasaki',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Hiroshima',
		city_ascii: 'Hiroshima',
		lat: 34.3878351,
		lng: 132.442913,
		pop: 1594420.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Hiroshima',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Sapporo',
		city_ascii: 'Sapporo',
		lat: 43.07497927,
		lng: 141.3400443,
		pop: 2202893,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Hokkaido',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Osaka',
		city_ascii: 'Osaka',
		lat: 34.75003522,
		lng: 135.4601448,
		pop: 6943206.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Osaka',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Tokyo',
		city_ascii: 'Tokyo',
		lat: 35.68501691,
		lng: 139.7514074,
		pop: 22006299.5,
		country: 'Japan',
		iso2: 'JP',
		iso3: 'JPN',
		province: 'Tokyo',
		timezone: 'Asia/Tokyo',
	},
	{
		city: 'Al Mafraq',
		city_ascii: 'Al Mafraq',
		lat: 32.28329707,
		lng: 36.23329852,
		pop: 57118,
		country: 'Jordan',
		iso2: 'JO',
		iso3: 'JOR',
		province: 'Mafraq',
		timezone: 'Asia/Amman',
	},
	{
		city: 'At Tafilah',
		city_ascii: 'At Tafilah',
		lat: 30.83333404,
		lng: 35.60000464,
		pop: 25429,
		country: 'Jordan',
		iso2: 'JO',
		iso3: 'JOR',
		province: 'Tafilah',
		timezone: 'Asia/Amman',
	},
	{
		city: "Ma'an",
		city_ascii: "Ma'an",
		lat: 30.19200312,
		lng: 35.73600358,
		pop: 50350,
		country: 'Jordan',
		iso2: 'JO',
		iso3: 'JOR',
		province: 'Ma`an',
		timezone: 'Asia/Amman',
	},
	{
		city: 'Irbid',
		city_ascii: 'Irbid',
		lat: 32.54998863,
		lng: 35.84999752,
		pop: 471020,
		country: 'Jordan',
		iso2: 'JO',
		iso3: 'JOR',
		province: 'Irbid',
		timezone: 'Asia/Amman',
	},
	{
		city: 'As Salt',
		city_ascii: 'As Salt',
		lat: 32.03919293,
		lng: 35.72721431,
		pop: 110439,
		country: 'Jordan',
		iso2: 'JO',
		iso3: 'JOR',
		province: 'Balqa',
		timezone: 'Asia/Amman',
	},
	{
		city: 'Az Zarqa',
		city_ascii: 'Az Zarqa',
		lat: 32.06999208,
		lng: 36.1000081,
		pop: 843678,
		country: 'Jordan',
		iso2: 'JO',
		iso3: 'JOR',
		province: 'Zarqa',
		timezone: 'Asia/Amman',
	},
	{
		city: 'Al Aqabah',
		city_ascii: 'Al Aqabah',
		lat: 29.52699485,
		lng: 35.07769324,
		pop: 95048,
		country: 'Jordan',
		iso2: 'JO',
		iso3: 'JOR',
		province: 'Aqaba',
		timezone: 'Asia/Amman',
	},
	{
		city: 'Al Karak',
		city_ascii: 'Al Karak',
		lat: 31.1851107,
		lng: 35.70473507,
		pop: 50870,
		country: 'Jordan',
		iso2: 'JO',
		iso3: 'JOR',
		province: 'Karak',
		timezone: 'Asia/Amman',
	},
	{
		city: 'Amman',
		city_ascii: 'Amman',
		lat: 31.95002525,
		lng: 35.93329993,
		pop: 1060000,
		country: 'Jordan',
		iso2: 'JO',
		iso3: 'JOR',
		province: 'Amman',
		timezone: 'Asia/Amman',
	},
	{
		city: 'Turgay',
		city_ascii: 'Turgay',
		lat: 49.62600011,
		lng: 63.49899651,
		pop: 5277,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qostanay',
		timezone: 'Asia/Qyzylorda',
	},
	{
		city: 'Mangyshlak',
		city_ascii: 'Mangyshlak',
		lat: 43.69045506,
		lng: 51.14173561,
		pop: 147443,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Mangghystau',
		timezone: 'Asia/Aqtau',
	},
	{
		city: 'Maqat',
		city_ascii: 'Maqat',
		lat: 47.64824017,
		lng: 53.32650183,
		pop: 12169,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Atyrau',
		timezone: 'Asia/Atyrau',
	},
	{
		city: 'Bestobe',
		city_ascii: 'Bestobe',
		lat: 52.49967531,
		lng: 73.0997135,
		pop: 7189,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqmola',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Osakarovka',
		city_ascii: 'Osakarovka',
		lat: 50.57994753,
		lng: 72.56992672,
		pop: 4196,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qaraghandy',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Aqadyr',
		city_ascii: 'Aqadyr',
		lat: 48.27486859,
		lng: 72.85988318,
		pop: 5359.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qaraghandy',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Sharbaqty',
		city_ascii: 'Sharbaqty',
		lat: 52.49990786,
		lng: 78.14994787,
		pop: 107,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Pavlodar',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Shemonaikha',
		city_ascii: 'Shemonaikha',
		lat: 50.63159812,
		lng: 81.90496415,
		pop: 23631.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'East Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Serebryansk',
		city_ascii: 'Serebryansk',
		lat: 49.69989789,
		lng: 83.42487138,
		pop: 701,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'East Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Boralday',
		city_ascii: 'Boralday',
		lat: 43.33413658,
		lng: 76.82883988,
		pop: 20996,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Almaty',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Zharkent',
		city_ascii: 'Zharkent',
		lat: 44.16155377,
		lng: 79.9801204,
		pop: 35459,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Almaty',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Esik',
		city_ascii: 'Esik',
		lat: 43.36906984,
		lng: 77.44378943,
		pop: 30883,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Almaty',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Lenger',
		city_ascii: 'Lenger',
		lat: 42.18991701,
		lng: 69.87991003,
		pop: 22148,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'South Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Kentau',
		city_ascii: 'Kentau',
		lat: 43.5165027,
		lng: 68.51988969,
		pop: 55864.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'South Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Zhosaly',
		city_ascii: 'Zhosaly',
		lat: 45.48772605,
		lng: 64.07804195,
		pop: 19023.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qyzylorda',
		timezone: 'Asia/Qyzylorda',
	},
	{
		city: 'Oktyabrsk',
		city_ascii: 'Oktyabrsk',
		lat: 49.47306419,
		lng: 57.44490678,
		pop: 27284.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqtöbe',
		timezone: 'Asia/Aqtobe',
	},
	{
		city: 'Algha',
		city_ascii: 'Algha',
		lat: 49.90316713,
		lng: 57.33499101,
		pop: 28267,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqtöbe',
		timezone: 'Asia/Aqtobe',
	},
	{
		city: 'Bayghanin',
		city_ascii: 'Bayghanin',
		lat: 48.69169069,
		lng: 55.87399491,
		pop: 4271,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqtöbe',
		timezone: 'Asia/Aqtobe',
	},
	{
		city: 'Embi',
		city_ascii: 'Embi',
		lat: 48.82677289,
		lng: 58.14419226,
		pop: 10009.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqtöbe',
		timezone: 'Asia/Aqtobe',
	},
	{
		city: 'Zhetiqara',
		city_ascii: 'Zhetiqara',
		lat: 52.19297569,
		lng: 61.23992061,
		pop: 44922,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qostanay',
		timezone: 'Asia/Qyzylorda',
	},
	{
		city: 'Komsomolets',
		city_ascii: 'Komsomolets',
		lat: 53.75055503,
		lng: 62.05020707,
		pop: 5693.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qostanay',
		timezone: 'Asia/Qyzylorda',
	},
	{
		city: 'Tobol',
		city_ascii: 'Tobol',
		lat: 52.69800946,
		lng: 62.57492956,
		pop: 3913.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qostanay',
		timezone: 'Asia/Qyzylorda',
	},
	{
		city: 'Qusmuryn',
		city_ascii: 'Qusmuryn',
		lat: 52.45799827,
		lng: 64.59997392,
		pop: 4345.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qostanay',
		timezone: 'Asia/Qyzylorda',
	},
	{
		city: 'Shieli',
		city_ascii: 'Shieli',
		lat: 44.16700563,
		lng: 66.75002356,
		pop: 25871,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qyzylorda',
		timezone: 'Asia/Qyzylorda',
	},
	{
		city: 'Makhambet',
		city_ascii: 'Makhambet',
		lat: 47.67136538,
		lng: 51.57978674,
		pop: 4761.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Atyrau',
		timezone: 'Asia/Atyrau',
	},
	{
		city: 'Chapaev',
		city_ascii: 'Chapaev',
		lat: 50.19149579,
		lng: 51.14492956,
		pop: 3515.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'West Kazakhstan',
		timezone: 'Asia/Oral',
	},
	{
		city: 'Zhanibek',
		city_ascii: 'Zhanibek',
		lat: 49.4276406,
		lng: 46.8772314,
		pop: 6824,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'West Kazakhstan',
		timezone: 'Asia/Oral',
	},
	{
		city: 'Aqsay',
		city_ascii: 'Aqsay',
		lat: 51.17143597,
		lng: 53.03489172,
		pop: 30404.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'West Kazakhstan',
		timezone: 'Asia/Oral',
	},
	{
		city: 'Esil',
		city_ascii: 'Esil',
		lat: 51.95696942,
		lng: 66.37748816,
		pop: 7065.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqmola',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Derzhavinsk',
		city_ascii: 'Derzhavinsk',
		lat: 51.10206036,
		lng: 66.30746659,
		pop: 14852,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqmola',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Zhaltyr',
		city_ascii: 'Zhaltyr',
		lat: 51.63241559,
		lng: 69.83278113,
		pop: 694,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqmola',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Makinsk',
		city_ascii: 'Makinsk',
		lat: 52.6403644,
		lng: 70.4099552,
		pop: 20365.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqmola',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Aqsu',
		city_ascii: 'Aqsu',
		lat: 52.45019513,
		lng: 71.9597314,
		pop: 8543,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqmola',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Zholymbet',
		city_ascii: 'Zholymbet',
		lat: 51.75023785,
		lng: 71.70987585,
		pop: 3969,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqmola',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Erymentau',
		city_ascii: 'Erymentau',
		lat: 51.63032269,
		lng: 73.10493282,
		pop: 19655,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqmola',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Saryshaghan',
		city_ascii: 'Saryshaghan',
		lat: 46.11954795,
		lng: 73.61911332,
		pop: 2331,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qaraghandy',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Qarazhal',
		city_ascii: 'Qarazhal',
		lat: 48.02527142,
		lng: 70.79990556,
		pop: 17988.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qaraghandy',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Atasu',
		city_ascii: 'Atasu',
		lat: 48.69032127,
		lng: 71.64993119,
		pop: 16400,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qaraghandy',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Kishkenekol',
		city_ascii: 'Kishkenekol',
		lat: 53.63676353,
		lng: 72.34141353,
		pop: 6779,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'North Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Tayynsha',
		city_ascii: 'Tayynsha',
		lat: 53.84812014,
		lng: 69.76379309,
		pop: 7128.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'North Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Bulaevo',
		city_ascii: 'Bulaevo',
		lat: 54.90531659,
		lng: 70.43997921,
		pop: 5383,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'North Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Ertis',
		city_ascii: 'Ertis',
		lat: 53.34530845,
		lng: 75.44990596,
		pop: 6311,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Pavlodar',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Kachiry',
		city_ascii: 'Kachiry',
		lat: 53.08036338,
		lng: 76.08997025,
		pop: 5130.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Pavlodar',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Zaysan',
		city_ascii: 'Zaysan',
		lat: 47.47522748,
		lng: 84.85982255,
		pop: 14199,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'East Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Zyryanovsk',
		city_ascii: 'Zyryanovsk',
		lat: 49.74526979,
		lng: 84.25484656,
		pop: 47293.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'East Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Ridder',
		city_ascii: 'Ridder',
		lat: 50.35538759,
		lng: 83.5149434,
		pop: 54710,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'East Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Shar',
		city_ascii: 'Shar',
		lat: 49.6003174,
		lng: 81.05493852,
		pop: 5124.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'East Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Urzhar',
		city_ascii: 'Urzhar',
		lat: 47.10018577,
		lng: 81.60482743,
		pop: 13854,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'East Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Sarqan',
		city_ascii: 'Sarqan',
		lat: 45.42033999,
		lng: 79.91490474,
		pop: 61329,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Almaty',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Ushtobe',
		city_ascii: 'Ushtobe',
		lat: 45.26533653,
		lng: 77.96995886,
		pop: 19116.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Almaty',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Shonzhy',
		city_ascii: 'Shonzhy',
		lat: 43.54208254,
		lng: 79.47028072,
		pop: 3902,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Almaty',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Qapshaghay',
		city_ascii: 'Qapshaghay',
		lat: 43.88438723,
		lng: 77.06872188,
		pop: 40319.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Almaty',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Otar',
		city_ascii: 'Otar',
		lat: 43.53458946,
		lng: 75.2138997,
		pop: 11238,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Almaty',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Fort Shevchenko',
		city_ascii: 'Fort Shevchenko',
		lat: 44.51706179,
		lng: 50.26663692,
		pop: 3236,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Mangghystau',
		timezone: 'Asia/Aqtau',
	},
	{
		city: 'Zhangaozen',
		city_ascii: 'Zhangaozen',
		lat: 43.30039187,
		lng: 52.80002234,
		pop: 8895,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Mangghystau',
		timezone: 'Asia/Aqtau',
	},
	{
		city: 'Arys',
		city_ascii: 'Arys',
		lat: 42.43687868,
		lng: 68.80483354,
		pop: 39466.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'South Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Burylbaytal',
		city_ascii: 'Burylbaytal',
		lat: 44.93874147,
		lng: 74.03025102,
		pop: 92.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Zhambyl',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Shu',
		city_ascii: 'Shu',
		lat: 43.59525759,
		lng: 73.74484208,
		pop: 41112,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Zhambyl',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Qulan',
		city_ascii: 'Qulan',
		lat: 42.92036338,
		lng: 72.70495723,
		pop: 10200,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Zhambyl',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Oytal',
		city_ascii: 'Oytal',
		lat: 42.91529909,
		lng: 73.25489783,
		pop: 16247,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Zhambyl',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Qaratau',
		city_ascii: 'Qaratau',
		lat: 43.18538597,
		lng: 70.45997799,
		pop: 35743,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Zhambyl',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Khromtau',
		city_ascii: 'Khromtau',
		lat: 50.26994061,
		lng: 58.44996171,
		pop: 21614,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqtöbe',
		timezone: 'Asia/Aqtobe',
	},
	{
		city: 'Arqalyq',
		city_ascii: 'Arqalyq',
		lat: 50.24180279,
		lng: 66.89761145,
		pop: 48760.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qostanay',
		timezone: 'Asia/Qyzylorda',
	},
	{
		city: 'Oostanay',
		city_ascii: 'Oostanay',
		lat: 53.22089744,
		lng: 63.62830196,
		pop: 223450.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qostanay',
		timezone: 'Asia/Qyzylorda',
	},
	{
		city: 'Baykonur',
		city_ascii: 'Baykonur',
		lat: 45.69135703,
		lng: 63.24134884,
		pop: 36175,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qyzylorda',
		timezone: 'Asia/Qyzylorda',
	},
	{
		city: 'Balyqshy',
		city_ascii: 'Balyqshy',
		lat: 47.06659609,
		lng: 51.86659094,
		pop: 25442,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Atyrau',
		timezone: 'Asia/Atyrau',
	},
	{
		city: 'Atbasar',
		city_ascii: 'Atbasar',
		lat: 51.82188723,
		lng: 68.34770382,
		pop: 35308,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqmola',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Kokshetau',
		city_ascii: 'Kokshetau',
		lat: 53.29998822,
		lng: 69.41998979,
		pop: 126658.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqmola',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Temirtau',
		city_ascii: 'Temirtau',
		lat: 50.06501772,
		lng: 72.96499304,
		pop: 167193.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qaraghandy',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Zhezqazghan',
		city_ascii: 'Zhezqazghan',
		lat: 47.77998924,
		lng: 67.77001298,
		pop: 104357,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qaraghandy',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Qarqaraly',
		city_ascii: 'Qarqaraly',
		lat: 49.42490175,
		lng: 75.46489213,
		pop: 4899,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qaraghandy',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Balqash',
		city_ascii: 'Balqash',
		lat: 46.8532241,
		lng: 74.95024654,
		pop: 80586,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qaraghandy',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Petropavlovsk',
		city_ascii: 'Petropavlovsk',
		lat: 54.87999514,
		lng: 69.22000199,
		pop: 214579,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'North Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Ayakoz',
		city_ascii: 'Ayakoz',
		lat: 47.96473248,
		lng: 80.42970536,
		pop: 39670,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'East Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Taldyqorghan',
		city_ascii: 'Taldyqorghan',
		lat: 45.00000389,
		lng: 78.40001013,
		pop: 88380,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Almaty',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Turkistan',
		city_ascii: 'Turkistan',
		lat: 43.30155458,
		lng: 68.25489294,
		pop: 86743.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'South Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Shalqar',
		city_ascii: 'Shalqar',
		lat: 47.83629071,
		lng: 59.6140767,
		pop: 27256,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqtöbe',
		timezone: 'Asia/Aqtobe',
	},
	{
		city: 'Qazaly',
		city_ascii: 'Qazaly',
		lat: 45.7627997,
		lng: 62.10751623,
		pop: 3436,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qyzylorda',
		timezone: 'Asia/Qyzylorda',
	},
	{
		city: 'Aral',
		city_ascii: 'Aral',
		lat: 46.79997154,
		lng: 61.66661291,
		pop: 30185,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qyzylorda',
		timezone: 'Asia/Qyzylorda',
	},
	{
		city: 'Qulsary',
		city_ascii: 'Qulsary',
		lat: 46.98326784,
		lng: 54.01653723,
		pop: 37103,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Atyrau',
		timezone: 'Asia/Atyrau',
	},
	{
		city: 'Oral',
		city_ascii: 'Oral',
		lat: 51.27111981,
		lng: 51.33499548,
		pop: 204894,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'West Kazakhstan',
		timezone: 'Asia/Oral',
	},
	{
		city: 'Beyneu',
		city_ascii: 'Beyneu',
		lat: 45.18335187,
		lng: 55.10003699,
		pop: 32452,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Mangghystau',
		timezone: 'Asia/Aqtau',
	},
	{
		city: 'Aktau',
		city_ascii: 'Aktau',
		lat: 43.6231887,
		lng: 51.2365002,
		pop: 4479,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Mangghystau',
		timezone: 'Asia/Aqtau',
	},
	{
		city: 'Aktobe',
		city_ascii: 'Aktobe',
		lat: 50.28001752,
		lng: 57.16998816,
		pop: 260493,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqtöbe',
		timezone: 'Asia/Aqtobe',
	},
	{
		city: 'Rudny',
		city_ascii: 'Rudny',
		lat: 52.95269676,
		lng: 63.1300378,
		pop: 104235.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qostanay',
		timezone: 'Asia/Qyzylorda',
	},
	{
		city: 'Qyzylorda',
		city_ascii: 'Qyzylorda',
		lat: 44.80001609,
		lng: 65.46498572,
		pop: 213259.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qyzylorda',
		timezone: 'Asia/Qyzylorda',
	},
	{
		city: 'Atyrau',
		city_ascii: 'Atyrau',
		lat: 47.11269147,
		lng: 51.92002437,
		pop: 170583,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Atyrau',
		timezone: 'Asia/Atyrau',
	},
	{
		city: 'Ekibastuz',
		city_ascii: 'Ekibastuz',
		lat: 51.72998069,
		lng: 75.31993974,
		pop: 124669,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Pavlodar',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Pavlodar',
		city_ascii: 'Pavlodar',
		lat: 52.29999758,
		lng: 76.95002112,
		pop: 316254,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Pavlodar',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Semey',
		city_ascii: 'Semey',
		lat: 50.43499514,
		lng: 80.2750378,
		pop: 302066.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'East Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Oskemen',
		city_ascii: 'Oskemen',
		lat: 49.99003522,
		lng: 82.61494665,
		pop: 284350.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'East Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Shymkent',
		city_ascii: 'Shymkent',
		lat: 42.32001243,
		lng: 69.59501786,
		pop: 439712,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'South Kazakhstan',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Taraz',
		city_ascii: 'Taraz',
		lat: 42.89997703,
		lng: 71.36498734,
		pop: 332723.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Zhambyl',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Astana',
		city_ascii: 'Astana',
		lat: 51.1811253,
		lng: 71.42777421,
		pop: 335312.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Aqmola',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Qaraghandy',
		city_ascii: 'Qaraghandy',
		lat: 49.88497703,
		lng: 73.11500972,
		pop: 378273.5,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Qaraghandy',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Almaty',
		city_ascii: 'Almaty',
		lat: 43.32498985,
		lng: 76.91503617,
		pop: 1096256,
		country: 'Kazakhstan',
		iso2: 'KZ',
		iso3: 'KAZ',
		province: 'Almaty',
		timezone: 'Asia/Almaty',
	},
	{
		city: 'Nyeri',
		city_ascii: 'Nyeri',
		lat: -0.41699699,
		lng: 36.95100363,
		pop: 51084,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Central',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Mwingi',
		city_ascii: 'Mwingi',
		lat: -0.929569886,
		lng: 38.07001705,
		pop: 9546.5,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Eastern',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Embu',
		city_ascii: 'Embu',
		lat: -0.519569073,
		lng: 37.45000321,
		pop: 46771,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Eastern',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Machakos',
		city_ascii: 'Machakos',
		lat: -1.509534486,
		lng: 37.25998897,
		pop: 88448,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Eastern',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Nanyuki',
		city_ascii: 'Nanyuki',
		lat: 0.020397968,
		lng: 37.06000118,
		pop: 34342,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Rift Valley',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Maralal',
		city_ascii: 'Maralal',
		lat: 1.110460631,
		lng: 36.68002437,
		pop: 20841,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Rift Valley',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Konza',
		city_ascii: 'Konza',
		lat: -1.74962319,
		lng: 37.11999752,
		pop: 2004,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Rift Valley',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Lodwar',
		city_ascii: 'Lodwar',
		lat: 3.130389017,
		lng: 35.57001461,
		pop: 17089.5,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Rift Valley',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Eldama Ravine',
		city_ascii: 'Eldama Ravine',
		lat: 0.050370299,
		lng: 35.72003129,
		pop: 15052.5,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Rift Valley',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Sotik',
		city_ascii: 'Sotik',
		lat: -0.679559307,
		lng: 35.12001623,
		pop: 36942.5,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Rift Valley',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Namanga',
		city_ascii: 'Namanga',
		lat: -2.539600811,
		lng: 36.80001705,
		pop: 7664.5,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Rift Valley',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Naivasha',
		city_ascii: 'Naivasha',
		lat: -0.709583314,
		lng: 36.42996212,
		pop: 41174.5,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Rift Valley',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Kericho',
		city_ascii: 'Kericho',
		lat: -0.359578838,
		lng: 35.28000647,
		pop: 67300,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Rift Valley',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Kitale',
		city_ascii: 'Kitale',
		lat: 1.030465514,
		lng: 34.98994665,
		pop: 112809,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Rift Valley',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Bungoma',
		city_ascii: 'Bungoma',
		lat: 0.570390237,
		lng: 34.55999874,
		pop: 55962,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Western',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Kakamega',
		city_ascii: 'Kakamega',
		lat: 0.290407327,
		lng: 34.7300142,
		pop: 63426,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Western',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Wajir',
		city_ascii: 'Wajir',
		lat: 1.750369892,
		lng: 40.04999955,
		pop: 40240,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'North-Eastern',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Garissa',
		city_ascii: 'Garissa',
		lat: -0.439625632,
		lng: 39.67002274,
		pop: 65948,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'North-Eastern',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Witu',
		city_ascii: 'Witu',
		lat: -2.379610576,
		lng: 40.43002803,
		pop: 3945,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Coast',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Tsavo',
		city_ascii: 'Tsavo',
		lat: -2.982777894,
		lng: 38.46663367,
		pop: 414,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Coast',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Voi',
		city_ascii: 'Voi',
		lat: -3.36957599,
		lng: 38.56998653,
		pop: 28055.5,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Coast',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Kilifi',
		city_ascii: 'Kilifi',
		lat: -3.609613018,
		lng: 39.85001176,
		pop: 63228.5,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Coast',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Thika',
		city_ascii: 'Thika',
		lat: -1.039589011,
		lng: 37.09002519,
		pop: 93571.5,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Central',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Kendu Bay',
		city_ascii: 'Kendu Bay',
		lat: -0.359578838,
		lng: 34.63999385,
		pop: 91248,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Nyanza',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Karungu',
		city_ascii: 'Karungu',
		lat: -0.849626446,
		lng: 34.14999792,
		pop: 2376,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Nyanza',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Kisii',
		city_ascii: 'Kisii',
		lat: -0.669585756,
		lng: 34.75998653,
		pop: 28547,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Nyanza',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Marsabit',
		city_ascii: 'Marsabit',
		lat: 2.329998595,
		lng: 37.9799967,
		pop: 15910.5,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Eastern',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Moyale',
		city_ascii: 'Moyale',
		lat: 3.51997764,
		lng: 39.05000891,
		pop: 20540,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Eastern',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Nakuru',
		city_ascii: 'Nakuru',
		lat: -0.279997132,
		lng: 36.06998409,
		pop: 312315,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Rift Valley',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Lamu',
		city_ascii: 'Lamu',
		lat: -2.26204362,
		lng: 40.91966643,
		pop: 17435,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Coast',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Malindi',
		city_ascii: 'Malindi',
		lat: -3.209999167,
		lng: 40.10002234,
		pop: 81160,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Coast',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Kisumu',
		city_ascii: 'Kisumu',
		lat: -0.090034567,
		lng: 34.75001298,
		pop: 306047,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Nyanza',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Meru',
		city_ascii: 'Meru',
		lat: 0.059982116,
		lng: 37.64001745,
		pop: 47226,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Eastern',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Eldoret',
		city_ascii: 'Eldoret',
		lat: 0.520005716,
		lng: 35.26998124,
		pop: 285913.5,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Rift Valley',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Mombasa',
		city_ascii: 'Mombasa',
		lat: -4.040026022,
		lng: 39.68991817,
		pop: 840834,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Coast',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Nairobi',
		city_ascii: 'Nairobi',
		lat: -1.283346742,
		lng: 36.81665686,
		pop: 2880273.5,
		country: 'Kenya',
		iso2: 'KE',
		iso3: 'KEN',
		province: 'Nairobi',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Tarawa',
		city_ascii: 'Tarawa',
		lat: 1.338187506,
		lng: 173.0175708,
		pop: 25668,
		country: 'Kiribati',
		iso2: 'KI',
		iso3: 'KIR',
		province: '',
		timezone: 'Pacific/Tarawa',
	},
	{
		city: 'Prizren',
		city_ascii: 'Prizren',
		lat: 42.22932029,
		lng: 20.75009232,
		pop: 157574.5,
		country: 'Kosovo',
		iso2: '',
		iso3: 'KOS',
		province: 'Prizren',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Peje',
		city_ascii: 'Peje',
		lat: 42.66032757,
		lng: 20.3107393,
		pop: 93481.5,
		country: 'Kosovo',
		iso2: -99,
		iso3: 'KOS',
		province: 'Peje',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Pristina',
		city_ascii: 'Pristina',
		lat: 42.66670961,
		lng: 21.16598425,
		pop: 331700,
		country: 'Kosovo',
		iso2: -99,
		iso3: 'KOS',
		province: 'Pristina',
		timezone: 'Europe/Tirane',
	},
	{
		city: 'Hawalli',
		city_ascii: 'Hawalli',
		lat: 29.33334002,
		lng: 47.99999756,
		pop: 164212,
		country: 'Kuwait',
		iso2: 'KW',
		iso3: 'KWT',
		province: 'Hawalli',
		timezone: 'Asia/Kuwait',
	},
	{
		city: 'Al Ahmadi',
		city_ascii: 'Al Ahmadi',
		lat: 29.0769448,
		lng: 48.08377274,
		pop: 68763,
		country: 'Kuwait',
		iso2: 'KW',
		iso3: 'KWT',
		province: 'Al Ahmadi',
		timezone: 'Asia/Kuwait',
	},
	{
		city: 'Al Jahra',
		city_ascii: 'Al Jahra',
		lat: 29.33747154,
		lng: 47.6580623,
		pop: 194193,
		country: 'Kuwait',
		iso2: 'KW',
		iso3: 'KWT',
		province: 'Al Jahrah',
		timezone: 'Asia/Kuwait',
	},
	{
		city: 'Kuwait',
		city_ascii: 'Kuwait',
		lat: 29.36971763,
		lng: 47.97830115,
		pop: 1061532,
		country: 'Kuwait',
		iso2: 'KW',
		iso3: 'KWT',
		province: 'Al Kuwayt',
		timezone: 'Asia/Kuwait',
	},
	{
		city: 'Tokmak',
		city_ascii: 'Tokmak',
		lat: 42.82987795,
		lng: 75.28459306,
		pop: 87953.5,
		country: 'Kyrgyzstan',
		iso2: 'KG',
		iso3: 'KGZ',
		province: 'Bishkek',
		timezone: 'Asia/Bishkek',
	},
	{
		city: 'Kara Balta',
		city_ascii: 'Kara Balta',
		lat: 42.83062726,
		lng: 73.88566036,
		pop: 68464.5,
		country: 'Kyrgyzstan',
		iso2: 'KG',
		iso3: 'KGZ',
		province: 'Bishkek',
		timezone: 'Asia/Bishkek',
	},
	{
		city: 'Cholpon Ata',
		city_ascii: 'Cholpon Ata',
		lat: 42.65133588,
		lng: 77.08107255,
		pop: 14086.5,
		country: 'Kyrgyzstan',
		iso2: 'KG',
		iso3: 'KGZ',
		province: 'Ysyk-Köl',
		timezone: 'Asia/Bishkek',
	},
	{
		city: 'Naryn',
		city_ascii: 'Naryn',
		lat: 41.42634605,
		lng: 75.99106156,
		pop: 44003.5,
		country: 'Kyrgyzstan',
		iso2: 'KG',
		iso3: 'KGZ',
		province: 'Naryn',
		timezone: 'Asia/Bishkek',
	},
	{
		city: 'Kok Yangak',
		city_ascii: 'Kok Yangak',
		lat: 41.03071128,
		lng: 73.20575354,
		pop: 14523,
		country: 'Kyrgyzstan',
		iso2: 'KG',
		iso3: 'KGZ',
		province: 'Jalal-Abad',
		timezone: 'Asia/Bishkek',
	},
	{
		city: 'Balykchy',
		city_ascii: 'Balykchy',
		lat: 42.4560248,
		lng: 76.18536495,
		pop: 40263.5,
		country: 'Kyrgyzstan',
		iso2: 'KG',
		iso3: 'KGZ',
		province: 'Ysyk-Köl',
		timezone: 'Asia/Bishkek',
	},
	{
		city: 'At Bashy',
		city_ascii: 'At Bashy',
		lat: 41.17248557,
		lng: 75.79680985,
		pop: 15413.5,
		country: 'Kyrgyzstan',
		iso2: 'KG',
		iso3: 'KGZ',
		province: 'Naryn',
		timezone: 'Asia/Bishkek',
	},
	{
		city: 'Jalal Abad',
		city_ascii: 'Jalal Abad',
		lat: 40.94288719,
		lng: 73.00251013,
		pop: 162299.5,
		country: 'Kyrgyzstan',
		iso2: 'KG',
		iso3: 'KGZ',
		province: 'Jalal-Abad',
		timezone: 'Asia/Bishkek',
	},
	{
		city: 'Toktogul',
		city_ascii: 'Toktogul',
		lat: 41.88257143,
		lng: 72.93719112,
		pop: 22725.5,
		country: 'Kyrgyzstan',
		iso2: 'KG',
		iso3: 'KGZ',
		province: 'Jalal-Abad',
		timezone: 'Asia/Bishkek',
	},
	{
		city: 'Tash Komur',
		city_ascii: 'Tash Komur',
		lat: 41.34185508,
		lng: 72.23144608,
		pop: 19974.5,
		country: 'Kyrgyzstan',
		iso2: 'KG',
		iso3: 'KGZ',
		province: 'Jalal-Abad',
		timezone: 'Asia/Bishkek',
	},
	{
		city: 'Talas',
		city_ascii: 'Talas',
		lat: 42.51837242,
		lng: 72.24291825,
		pop: 28646,
		country: 'Kyrgyzstan',
		iso2: 'KG',
		iso3: 'KGZ',
		province: 'Talas',
		timezone: 'Asia/Bishkek',
	},
	{
		city: 'Osh',
		city_ascii: 'Osh',
		lat: 40.54040529,
		lng: 72.79001664,
		pop: 295638.5,
		country: 'Kyrgyzstan',
		iso2: 'KG',
		iso3: 'KGZ',
		province: 'Osh',
		timezone: 'Asia/Bishkek',
	},
	{
		city: 'Karakol',
		city_ascii: 'Karakol',
		lat: 42.49204327,
		lng: 78.38182003,
		pop: 63411.5,
		country: 'Kyrgyzstan',
		iso2: 'KG',
		iso3: 'KGZ',
		province: 'Ysyk-Köl',
		timezone: 'Asia/Bishkek',
	},
	{
		city: 'Bishkek',
		city_ascii: 'Bishkek',
		lat: 42.87307945,
		lng: 74.58520422,
		pop: 820606,
		country: 'Kyrgyzstan',
		iso2: 'KG',
		iso3: 'KGZ',
		province: 'Bishkek',
		timezone: 'Asia/Bishkek',
	},
	{
		city: 'Ban Houayxay',
		city_ascii: 'Ban Houayxay',
		lat: 20.2775,
		lng: 100.4127778,
		pop: 6347,
		country: 'Laos',
		iso2: 'LA',
		iso3: 'LAO',
		province: 'Bokeo',
		timezone: 'Asia/Vientiane',
	},
	{
		city: 'Louang Namtha',
		city_ascii: 'Louang Namtha',
		lat: 20.95000205,
		lng: 101.4166986,
		pop: 3225,
		country: 'Laos',
		iso2: 'LA',
		iso3: 'LAO',
		province: 'Louang Namtha',
		timezone: 'Asia/Vientiane',
	},
	{
		city: 'Champasak',
		city_ascii: 'Champasak',
		lat: 14.88330011,
		lng: 105.8667036,
		pop: 12994,
		country: 'Laos',
		iso2: 'LA',
		iso3: 'LAO',
		province: 'Champasak',
		timezone: 'Asia/Vientiane',
	},
	{
		city: 'Saravan',
		city_ascii: 'Saravan',
		lat: 15.715998,
		lng: 106.4269987,
		pop: 5521,
		country: 'Laos',
		iso2: 'LA',
		iso3: 'LAO',
		province: 'Saravan',
		timezone: 'Asia/Vientiane',
	},
	{
		city: 'Xam Nua',
		city_ascii: 'Xam Nua',
		lat: 20.41669802,
		lng: 104.0333047,
		pop: 38992,
		country: 'Laos',
		iso2: 'LA',
		iso3: 'LAO',
		province: 'Houaphan',
		timezone: 'Asia/Vientiane',
	},
	{
		city: 'Phongsali',
		city_ascii: 'Phongsali',
		lat: 21.68330399,
		lng: 102.0999967,
		pop: 6000,
		country: 'Laos',
		iso2: 'LA',
		iso3: 'LAO',
		province: 'Phôngsali',
		timezone: 'Asia/Vientiane',
	},
	{
		city: 'Attapu',
		city_ascii: 'Attapu',
		lat: 14.8079971,
		lng: 106.8390005,
		pop: 4297,
		country: 'Laos',
		iso2: 'LA',
		iso3: 'LAO',
		province: 'Attapu',
		timezone: 'Asia/Vientiane',
	},
	{
		city: 'Xaignabouri',
		city_ascii: 'Xaignabouri',
		lat: 19.2504645,
		lng: 101.7500577,
		pop: 16200,
		country: 'Laos',
		iso2: 'LA',
		iso3: 'LAO',
		province: 'Xaignabouri',
		timezone: 'Asia/Vientiane',
	},
	{
		city: 'Pakxe',
		city_ascii: 'Pakxe',
		lat: 15.12206016,
		lng: 105.8183365,
		pop: 95553.5,
		country: 'Laos',
		iso2: 'LA',
		iso3: 'LAO',
		province: 'Champasak',
		timezone: 'Asia/Vientiane',
	},
	{
		city: 'Xiangkhoang',
		city_ascii: 'Xiangkhoang',
		lat: 19.33368939,
		lng: 103.3665999,
		pop: 5189,
		country: 'Laos',
		iso2: 'LA',
		iso3: 'LAO',
		province: 'Xiangkhoang',
		timezone: 'Asia/Vientiane',
	},
	{
		city: 'Louangphrabang',
		city_ascii: 'Louangphrabang',
		lat: 19.88453432,
		lng: 102.1416101,
		pop: 77260,
		country: 'Laos',
		iso2: 'LA',
		iso3: 'LAO',
		province: 'Louangphrabang',
		timezone: 'Asia/Vientiane',
	},
	{
		city: 'Thakhek',
		city_ascii: 'Thakhek',
		lat: 17.41119692,
		lng: 104.8361226,
		pop: 51564,
		country: 'Laos',
		iso2: 'LA',
		iso3: 'LAO',
		province: 'Khammouan',
		timezone: 'Asia/Vientiane',
	},
	{
		city: 'Savannakhet',
		city_ascii: 'Savannakhet',
		lat: 16.53758099,
		lng: 104.772974,
		pop: 75725.5,
		country: 'Laos',
		iso2: 'LA',
		iso3: 'LAO',
		province: 'Savannakhét',
		timezone: 'Asia/Vientiane',
	},
	{
		city: 'Vientiane',
		city_ascii: 'Vientiane',
		lat: 17.96669273,
		lng: 102.59998,
		pop: 662174,
		country: 'Laos',
		iso2: 'LA',
		iso3: 'LAO',
		province: 'Vientiane [prefecture]',
		timezone: 'Asia/Vientiane',
	},
	{
		city: 'Rezekne',
		city_ascii: 'Rezekne',
		lat: 56.50002545,
		lng: 27.3165649,
		pop: 38219,
		country: 'Latvia',
		iso2: 'LV',
		iso3: 'LVA',
		province: 'Latgale',
		timezone: 'Europe/Riga',
	},
	{
		city: 'Ventspils',
		city_ascii: 'Ventspils',
		lat: 57.38986778,
		lng: 21.56058549,
		pop: 42764,
		country: 'Latvia',
		iso2: 'LV',
		iso3: 'LVA',
		province: 'Ventspils',
		timezone: 'Europe/Riga',
	},
	{
		city: 'Jelgava',
		city_ascii: 'Jelgava',
		lat: 56.65270347,
		lng: 23.71280554,
		pop: 64499,
		country: 'Latvia',
		iso2: 'LV',
		iso3: 'LVA',
		province: 'Jelgava',
		timezone: 'Europe/Riga',
	},
	{
		city: 'Liepaga',
		city_ascii: 'Liepaga',
		lat: 56.50997316,
		lng: 21.01002478,
		pop: 83969.5,
		country: 'Latvia',
		iso2: 'LV',
		iso3: 'LVA',
		province: 'Liepaja',
		timezone: 'Europe/Riga',
	},
	{
		city: 'Daugavpils',
		city_ascii: 'Daugavpils',
		lat: 55.87995994,
		lng: 26.50999914,
		pop: 109969.5,
		country: 'Latvia',
		iso2: 'LV',
		iso3: 'LVA',
		province: 'Daugavpils',
		timezone: 'Europe/Riga',
	},
	{
		city: 'Riga',
		city_ascii: 'Riga',
		lat: 56.95002382,
		lng: 24.09996537,
		pop: 723802.5,
		country: 'Latvia',
		iso2: 'LV',
		iso3: 'LVA',
		province: 'Riga',
		timezone: 'Europe/Riga',
	},
	{
		city: "B'abda",
		city_ascii: "B'abda",
		lat: 33.83330406,
		lng: 35.53329652,
		pop: 9000,
		country: 'Lebanon',
		iso2: 'LB',
		iso3: 'LBN',
		province: 'Mount Lebanon',
		timezone: 'Asia/Beirut',
	},
	{
		city: 'Nabatiye et Tahta',
		city_ascii: 'Nabatiye et Tahta',
		lat: 33.38330402,
		lng: 35.45000164,
		pop: 60000,
		country: 'Lebanon',
		iso2: 'LB',
		iso3: 'LBN',
		province: 'An Nabatiyah',
		timezone: 'Asia/Beirut',
	},
	{
		city: 'Saida',
		city_ascii: 'Saida',
		lat: 33.56302757,
		lng: 35.36878658,
		pop: 173894,
		country: 'Lebanon',
		iso2: 'LB',
		iso3: 'LBN',
		province: 'South Lebanon',
		timezone: 'Asia/Beirut',
	},
	{
		city: 'Zahle',
		city_ascii: 'Zahle',
		lat: 33.85011599,
		lng: 35.90415442,
		pop: 61192,
		country: 'Lebanon',
		iso2: 'LB',
		iso3: 'LBN',
		province: 'Mount Lebanon',
		timezone: 'Asia/Beirut',
	},
	{
		city: 'Trablous',
		city_ascii: 'Trablous',
		lat: 34.42000368,
		lng: 35.8699963,
		pop: 361286,
		country: 'Lebanon',
		iso2: 'LB',
		iso3: 'LBN',
		province: 'North Lebanon',
		timezone: 'Asia/Beirut',
	},
	{
		city: 'Beirut',
		city_ascii: 'Beirut',
		lat: 33.87197512,
		lng: 35.50970821,
		pop: 1779062.5,
		country: 'Lebanon',
		iso2: 'LB',
		iso3: 'LBN',
		province: 'Beirut',
		timezone: 'Asia/Beirut',
	},
	{
		city: 'Teyateyaneng',
		city_ascii: 'Teyateyaneng',
		lat: -29.15299794,
		lng: 27.75300351,
		pop: 5115,
		country: 'Lesotho',
		iso2: 'LS',
		iso3: 'LSO',
		province: 'Berea',
		timezone: 'Africa/Maseru',
	},
	{
		city: 'Mohales Hoek',
		city_ascii: 'Mohales Hoek',
		lat: -30.15899991,
		lng: 27.47999751,
		pop: 24992,
		country: 'Lesotho',
		iso2: 'LS',
		iso3: 'LSO',
		province: "Mohale's Hoek",
		timezone: 'Africa/Maseru',
	},
	{
		city: 'Moyeni',
		city_ascii: 'Moyeni',
		lat: -30.41099991,
		lng: 27.71600451,
		pop: 24130,
		country: 'Lesotho',
		iso2: 'LS',
		iso3: 'LSO',
		province: 'Quthing',
		timezone: 'Africa/Maseru',
	},
	{
		city: 'Hlotse',
		city_ascii: 'Hlotse',
		lat: -28.87800298,
		lng: 28.05599764,
		pop: 47675,
		country: 'Lesotho',
		iso2: 'LS',
		iso3: 'LSO',
		province: 'Leribe',
		timezone: 'Africa/Maseru',
	},
	{
		city: 'Butha-Buthe',
		city_ascii: 'Butha-Buthe',
		lat: -28.74999591,
		lng: 28.25000158,
		pop: 16330,
		country: 'Lesotho',
		iso2: 'LS',
		iso3: 'LSO',
		province: 'Leribe',
		timezone: 'Africa/Maseru',
	},
	{
		city: 'Mokhotlong',
		city_ascii: 'Mokhotlong',
		lat: -29.29100403,
		lng: 29.07800155,
		pop: 8809,
		country: 'Lesotho',
		iso2: 'LS',
		iso3: 'LSO',
		province: 'Mokhotlong',
		timezone: 'Africa/Maseru',
	},
	{
		city: 'Mafetang',
		city_ascii: 'Mafetang',
		lat: -29.81664386,
		lng: 27.25000565,
		pop: 54708.5,
		country: 'Lesotho',
		iso2: 'LS',
		iso3: 'LSO',
		province: 'Mafeteng',
		timezone: 'Africa/Maseru',
	},
	{
		city: 'Maseru',
		city_ascii: 'Maseru',
		lat: -29.31667438,
		lng: 27.48327307,
		pop: 239839.5,
		country: 'Lesotho',
		iso2: 'LS',
		iso3: 'LSO',
		province: 'Maseru',
		timezone: 'Africa/Maseru',
	},
	{
		city: 'Barclayville',
		city_ascii: 'Barclayville',
		lat: 4.799996997,
		lng: -8.166698518,
		pop: 2733,
		country: 'Liberia',
		iso2: 'LR',
		iso3: 'LBR',
		province: 'GrandKru',
		timezone: 'Africa/Monrovia',
	},
	{
		city: 'Voinjama',
		city_ascii: 'Voinjama',
		lat: 8.416701115,
		lng: -9.749996524,
		pop: 26594,
		country: 'Liberia',
		iso2: 'LR',
		iso3: 'LBR',
		province: 'Lofa',
		timezone: 'Africa/Monrovia',
	},
	{
		city: 'Bensonville',
		city_ascii: 'Bentol',
		lat: 6.433299035,
		lng: -10.60000152,
		pop: 4089,
		country: 'Liberia',
		iso2: 'LR',
		iso3: 'LBR',
		province: 'Montserrado',
		timezone: 'Africa/Monrovia',
	},
	{
		city: 'Kakata',
		city_ascii: 'Kakata',
		lat: 6.525999009,
		lng: -10.34900054,
		pop: 33945,
		country: 'Liberia',
		iso2: 'LR',
		iso3: 'LBR',
		province: 'Margibi',
		timezone: 'Africa/Monrovia',
	},
	{
		city: 'Sanniquellie',
		city_ascii: 'Sanniquellie',
		lat: 7.371000109,
		lng: -8.684999462,
		pop: 11415,
		country: 'Liberia',
		iso2: 'LR',
		iso3: 'LBR',
		province: 'Nimba',
		timezone: 'Africa/Monrovia',
	},
	{
		city: 'Rivercess',
		city_ascii: 'Rivercess',
		lat: 5.464998046,
		lng: -9.577997442,
		pop: 2578,
		country: 'Liberia',
		iso2: 'LR',
		iso3: 'LBR',
		province: 'River Cess',
		timezone: 'Africa/Monrovia',
	},
	{
		city: 'Harper',
		city_ascii: 'Harper',
		lat: 4.375377623,
		lng: -7.716981447,
		pop: 25249,
		country: 'Liberia',
		iso2: 'LR',
		iso3: 'LBR',
		province: 'Maryland',
		state_ansi: 'MD',
		timezone: 'Africa/Monrovia',
	},
	{
		city: 'Gbarnga',
		city_ascii: 'Gbarnga',
		lat: 7.010410582,
		lng: -9.489999839,
		pop: 31856.5,
		country: 'Liberia',
		iso2: 'LR',
		iso3: 'LBR',
		province: 'Bong',
		timezone: 'Africa/Monrovia',
	},
	{
		city: 'Zwedru',
		city_ascii: 'Zwedru',
		lat: 6.070390441,
		lng: -8.130005332,
		pop: 19459.5,
		country: 'Liberia',
		iso2: 'LR',
		iso3: 'LBR',
		province: 'GrandGedeh',
		timezone: 'Africa/Monrovia',
	},
	{
		city: 'Greenville',
		city_ascii: 'Greenville',
		lat: 5.011126932,
		lng: -9.038812908,
		pop: 10374,
		country: 'Liberia',
		iso2: 'LR',
		iso3: 'LBR',
		province: 'Sinoe',
		timezone: 'Africa/Monrovia',
	},
	{
		city: 'Buchanan',
		city_ascii: 'Buchanan',
		lat: 5.916084614,
		lng: -10.05247197,
		pop: 37023,
		country: 'Liberia',
		iso2: 'LR',
		iso3: 'LBR',
		province: 'Grand Bassa',
		timezone: 'Africa/Monrovia',
	},
	{
		city: 'Robertsport',
		city_ascii: 'Robertsport',
		lat: 6.753320332,
		lng: -11.36859318,
		pop: 7951,
		country: 'Liberia',
		iso2: 'LR',
		iso3: 'LBR',
		province: 'Grand Cape Mount',
		timezone: 'Africa/Monrovia',
	},
	{
		city: 'Monrovia',
		city_ascii: 'Monrovia',
		lat: 6.31055666,
		lng: -10.80475163,
		pop: 913331,
		country: 'Liberia',
		iso2: 'LR',
		iso3: 'LBR',
		province: 'Montserrado',
		timezone: 'Africa/Monrovia',
	},
	{
		city: 'Dirj',
		city_ascii: 'Dirj',
		lat: 30.1679118,
		lng: 10.45666378,
		pop: 931,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Ghadamis',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Nalut',
		city_ascii: 'Nalut',
		lat: 31.88044293,
		lng: 10.97001745,
		pop: 66418.5,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Ghadamis',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Zillah',
		city_ascii: 'Zillah',
		lat: 28.55041363,
		lng: 17.58336055,
		pop: 10,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Al Jufrah',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Al Khums',
		city_ascii: 'Al Khums',
		lat: 32.66042116,
		lng: 14.25999752,
		pop: 192502,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Al Marqab',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Tajarhi',
		city_ascii: 'Tajarhi',
		lat: 24.3703587,
		lng: 14.47083736,
		pop: 1498,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Murzuq',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Umm al Abid',
		city_ascii: 'Umm al Abid',
		lat: 27.51701418,
		lng: 15.03333533,
		pop: 299,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Sabha',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Az Zawiyah',
		city_ascii: 'Az Zawiyah',
		lat: 32.76041506,
		lng: 12.71998816,
		pop: 193061.5,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Az Zawiyah',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Gharyan',
		city_ascii: 'Gharyan',
		lat: 32.17037355,
		lng: 13.01996985,
		pop: 116014.5,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Mizdah',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Mizdah',
		city_ascii: 'Mizdah',
		lat: 31.43372601,
		lng: 12.98333126,
		pop: 16379.5,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Mizdah',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Bani Walid',
		city_ascii: 'Bani Walid',
		lat: 31.77039797,
		lng: 13.98998816,
		pop: 55871,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Bani Walid',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Al Marj',
		city_ascii: 'Al Marj',
		lat: 32.50045677,
		lng: 20.82998409,
		pop: 127427.5,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Al Hizam Al Akhdar',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Al Bayda',
		city_ascii: 'Al Bayda',
		lat: 32.76041506,
		lng: 21.62001339,
		pop: 1794,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Al Jabal al Akhdar',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Shahhat',
		city_ascii: 'Shahhat',
		lat: 32.82813702,
		lng: 21.86216915,
		pop: 44188,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Al Jabal al Akhdar',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'El Agheila',
		city_ascii: 'El Agheila',
		lat: 30.25702781,
		lng: 19.20000606,
		pop: 100,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Ajdabiya',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Maradah',
		city_ascii: 'Maradah',
		lat: 29.23370526,
		lng: 19.21664587,
		pop: 2364.5,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Ajdabiya',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Qaminis',
		city_ascii: 'Qaminis',
		lat: 31.66063723,
		lng: 20.01546016,
		pop: 5348,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Benghazi',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'As Sidr',
		city_ascii: 'As Sidr',
		lat: 30.67041343,
		lng: 18.26662634,
		pop: 50,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Surt',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Al Jaghbub',
		city_ascii: 'Al Jaghbub',
		lat: 29.75039207,
		lng: 24.51658077,
		pop: 1744,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Al Butnan',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Ghadamis',
		city_ascii: 'Ghadamis',
		lat: 30.13328859,
		lng: 9.500029662,
		pop: 6623,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Ghadamis',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Hun',
		city_ascii: 'Hun',
		lat: 29.11665814,
		lng: 15.93333207,
		pop: 17352,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Al Jufrah',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Birak',
		city_ascii: 'Birak',
		lat: 27.53331809,
		lng: 14.28335526,
		pop: 42432.5,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Ash Shati',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Ghat',
		city_ascii: 'Ghat',
		lat: 24.96474103,
		lng: 10.17275346,
		pop: 22006,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Ghat',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Marzuq',
		city_ascii: 'Marzuq',
		lat: 25.90439943,
		lng: 13.89722896,
		pop: 49401.5,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Murzuq',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Ajdabiya',
		city_ascii: 'Ajdabiya',
		lat: 30.76999392,
		lng: 20.21999548,
		pop: 139095.5,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Ajdabiya',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Awjilah',
		city_ascii: 'Awjilah',
		lat: 29.10802818,
		lng: 21.2869071,
		pop: 6610,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Ajdabiya',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Surt',
		city_ascii: 'Surt',
		lat: 31.2099929,
		lng: 16.59003617,
		pop: 110756.5,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Surt',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Darnah',
		city_ascii: 'Darnah',
		lat: 32.76481113,
		lng: 22.63913928,
		pop: 103378,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Al Qubbah',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Tubruq',
		city_ascii: 'Tubruq',
		lat: 32.07999147,
		lng: 23.96002559,
		pop: 192289.5,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Al Butnan',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Al Jawf',
		city_ascii: 'Al Jawf',
		lat: 24.19998151,
		lng: 23.28998897,
		pop: 24132,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Al Kufrah',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Tmassah',
		city_ascii: 'Tmassah',
		lat: 26.36664512,
		lng: 15.80000688,
		pop: 350,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Murzuq',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Misratah',
		city_ascii: 'Misratah',
		lat: 32.37997316,
		lng: 15.09999792,
		pop: 301160,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Misratah',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Zuwarah',
		city_ascii: 'Zuwarah',
		lat: 32.93440961,
		lng: 12.07914872,
		pop: 123848,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'An Nuqat al Khams',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Sabha',
		city_ascii: 'Sabha',
		lat: 27.0332711,
		lng: 14.43332027,
		pop: 100249,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Sabha',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Banghazi',
		city_ascii: 'Banghazi',
		lat: 32.11673342,
		lng: 20.06672318,
		pop: 881187,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: 'Benghazi',
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Tripoli',
		city_ascii: 'Tripoli',
		lat: 32.89250002,
		lng: 13.18001176,
		pop: 1209199,
		country: 'Libya',
		iso2: 'LY',
		iso3: 'LBY',
		province: "Tajura' wa an Nawahi al Arba",
		timezone: 'Africa/Tripoli',
	},
	{
		city: 'Vaduz',
		city_ascii: 'Vaduz',
		lat: 47.13372377,
		lng: 9.516669473,
		pop: 20811.5,
		country: 'Liechtenstein',
		iso2: 'LI',
		iso3: 'LIE',
		province: '',
		timezone: 'Europe/Vaduz',
	},
	{
		city: 'Panevežys',
		city_ascii: 'Panevezys',
		lat: 55.74002016,
		lng: 24.37002641,
		pop: 122400,
		country: 'Lithuania',
		iso2: 'LT',
		iso3: 'LTU',
		province: 'Panevezio',
		timezone: 'Europe/Vilnius',
	},
	{
		city: 'Siauliai',
		city_ascii: 'Siauliai',
		lat: 55.93863853,
		lng: 23.32502559,
		pop: 132057.5,
		country: 'Lithuania',
		iso2: 'LT',
		iso3: 'LTU',
		province: 'Šiauliai',
		timezone: 'Europe/Vilnius',
	},
	{
		city: 'Klaipeda',
		city_ascii: 'Klaipeda',
		lat: 55.72040896,
		lng: 21.11994055,
		pop: 191334,
		country: 'Lithuania',
		iso2: 'LT',
		iso3: 'LTU',
		province: 'Klaipedos',
		timezone: 'Europe/Vilnius',
	},
	{
		city: 'Kaunas',
		city_ascii: 'Kaunas',
		lat: 54.95040428,
		lng: 23.88003048,
		pop: 363844.5,
		country: 'Lithuania',
		iso2: 'LT',
		iso3: 'LTU',
		province: 'Kauno',
		timezone: 'Europe/Vilnius',
	},
	{
		city: 'Vilnius',
		city_ascii: 'Vilnius',
		lat: 54.68336631,
		lng: 25.31663529,
		pop: 524697.5,
		country: 'Lithuania',
		iso2: 'LT',
		iso3: 'LTU',
		province: 'Vilniaus',
		timezone: 'Europe/Vilnius',
	},
	{
		city: 'Diekirch',
		city_ascii: 'Diekirch',
		lat: 49.88330105,
		lng: 6.166701555,
		pop: 6242,
		country: 'Luxembourg',
		iso2: 'LU',
		iso3: 'LUX',
		province: 'Diekirch',
		timezone: 'Europe/Luxembourg',
	},
	{
		city: 'Grevenmacher',
		city_ascii: 'Grevenmacher',
		lat: 49.69999811,
		lng: 6.333300575,
		pop: 3958,
		country: 'Luxembourg',
		iso2: 'LU',
		iso3: 'LUX',
		province: 'Grevenmacher',
		timezone: 'Europe/Luxembourg',
	},
	{
		city: 'Luxembourg',
		city_ascii: 'Luxembourg',
		lat: 49.61166038,
		lng: 6.130002806,
		pop: 91972,
		country: 'Luxembourg',
		iso2: 'LU',
		iso3: 'LUX',
		province: 'Luxembourg',
		timezone: 'Europe/Luxembourg',
	},
	{
		city: 'Macau',
		city_ascii: 'Macau',
		lat: 22.20299746,
		lng: 113.5450484,
		pop: 568700,
		country: 'Macau S.A.R',
		iso2: 'MO',
		iso3: 'MAC',
		province: '',
		timezone: 'Asia/Macau',
	},
	{
		city: 'Tetovo',
		city_ascii: 'Tetovo',
		lat: 42.00923037,
		lng: 20.9700789,
		pop: 96038,
		country: 'Macedonia',
		iso2: 'MK',
		iso3: 'MKD',
		province: 'Tetovo',
		timezone: 'Europe/Skopje',
	},
	{
		city: 'Bitola',
		city_ascii: 'Bitola',
		lat: 41.03905703,
		lng: 21.33951371,
		pop: 75551,
		country: 'Macedonia',
		iso2: 'MK',
		iso3: 'MKD',
		province: 'Bitola',
		timezone: 'Europe/Skopje',
	},
	{
		city: 'Skopje',
		city_ascii: 'Skopje',
		lat: 42.00000612,
		lng: 21.43346147,
		pop: 484488,
		country: 'Macedonia',
		iso2: 'MK',
		iso3: 'MKD',
		province: 'Centar',
		timezone: 'Europe/Skopje',
	},
	{
		city: 'Sambava',
		city_ascii: 'Sambava',
		lat: -14.26617186,
		lng: 50.16659135,
		pop: 37493.5,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Antsiranana',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Ambanja',
		city_ascii: 'Ambanja',
		lat: -13.68289996,
		lng: 48.45000362,
		pop: 26231.5,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Antsiranana',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Ihosy',
		city_ascii: 'Ihosy',
		lat: -22.39962889,
		lng: 46.11665767,
		pop: 13902,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Fianarantsoa',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Mandritsara',
		city_ascii: 'Mandritsara',
		lat: -15.83284625,
		lng: 48.81664791,
		pop: 9705,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Mahajanga',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Besalampy',
		city_ascii: 'Besalampy',
		lat: -16.74953449,
		lng: 44.48332068,
		pop: 1022,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Mahajanga',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Marovoay',
		city_ascii: 'Marovoay',
		lat: -16.09954832,
		lng: 46.63331864,
		pop: 16513,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Mahajanga',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Antsohihy',
		city_ascii: 'Antsohihy',
		lat: -14.86608356,
		lng: 47.98336544,
		pop: 15258.5,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Mahajanga',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Ambatondrazaka',
		city_ascii: 'Ambatondrazaka',
		lat: -17.83287921,
		lng: 48.41667232,
		pop: 41843,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Toamasina',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Bekiy',
		city_ascii: 'Bekiy',
		lat: -24.21615884,
		lng: 45.31660315,
		pop: 4286,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Toliary',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Manja',
		city_ascii: 'Manja',
		lat: -21.43291787,
		lng: 44.33325232,
		pop: 1536,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Toliary',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Miandrivazo',
		city_ascii: 'Miandrivazo',
		lat: -19.51618732,
		lng: 45.46661983,
		pop: 11893.5,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Toliary',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Antsirabe',
		city_ascii: 'Antsirabe',
		lat: -19.85001707,
		lng: 47.03329423,
		pop: 307921,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Antananarivo',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Antalaha',
		city_ascii: 'Antalaha',
		lat: -14.88334349,
		lng: 50.28332841,
		pop: 40668,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Antsiranana',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Andoany',
		city_ascii: 'Andoany',
		lat: -13.40002317,
		lng: 48.26660396,
		pop: 20535.5,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Antsiranana',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Farafangana',
		city_ascii: 'Farafangana',
		lat: -22.81660602,
		lng: 47.8332454,
		pop: 14992.5,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Fianarantsoa',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Mananjary',
		city_ascii: 'Mananjary',
		lat: -21.216652,
		lng: 48.33331824,
		pop: 19841,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Fianarantsoa',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Maintirano',
		city_ascii: 'Maintirano',
		lat: -18.06661172,
		lng: 44.01668249,
		pop: 5925,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Mahajanga',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Toamasina',
		city_ascii: 'Toamasina',
		lat: -18.18179848,
		lng: 49.40498409,
		pop: 208299.5,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Toamasina',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Maroantsetra',
		city_ascii: 'Maroantsetra',
		lat: -15.43333576,
		lng: 49.73333614,
		pop: 30952.5,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Toamasina',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Tolanaro',
		city_ascii: 'Tolanaro',
		lat: -25.02680703,
		lng: 46.99004106,
		pop: 16818,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Toliary',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Morombe',
		city_ascii: 'Morombe',
		lat: -21.73913848,
		lng: 43.36567763,
		pop: 16727,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Toliary',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Androka',
		city_ascii: 'Androka',
		lat: -25.02194623,
		lng: 44.07494694,
		pop: 174,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Toliary',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Morondava',
		city_ascii: 'Morondava',
		lat: -20.28327228,
		lng: 44.28333288,
		pop: 20018.5,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Toliary',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Antsiranana',
		city_ascii: 'Antsiranana',
		lat: -12.27650152,
		lng: 49.3115261,
		pop: 76312,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Antsiranana',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Fianarantsoa',
		city_ascii: 'Fianarantsoa',
		lat: -21.43333128,
		lng: 47.08326534,
		pop: 175705.5,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Fianarantsoa',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Mahajanga',
		city_ascii: 'Mahajanga',
		lat: -15.67001382,
		lng: 46.34501583,
		pop: 145158.5,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Mahajanga',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Toliara',
		city_ascii: 'Toliara',
		lat: -23.35683144,
		lng: 43.68998409,
		pop: 106278,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Toliary',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Antananarivo',
		city_ascii: 'Antananarivo',
		lat: -18.91663735,
		lng: 47.5166239,
		pop: 1544216.5,
		country: 'Madagascar',
		iso2: 'MG',
		iso3: 'MDG',
		province: 'Antananarivo',
		timezone: 'Indian/Antananarivo',
	},
	{
		city: 'Mzimba',
		city_ascii: 'Mzimba',
		lat: -11.9,
		lng: 33.6,
		pop: 19308,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Mzimba',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Machinga',
		city_ascii: 'Machinga',
		lat: -14.9666667,
		lng: 35.5166667,
		pop: 1418,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Machinga',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Dedza',
		city_ascii: 'Dedza',
		lat: -14.3666667,
		lng: 34.3333333,
		pop: 15608,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Dedza',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Mchinji',
		city_ascii: 'Mchinji',
		lat: -13.8,
		lng: 32.9,
		pop: 18305,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Mchinji',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Ntcheu',
		city_ascii: 'Ntcheu',
		lat: -14.8166667,
		lng: 34.6333333,
		pop: 10445,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Ntcheu',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Chiradzulu',
		city_ascii: 'Chiradzulu',
		lat: -15.7,
		lng: 35.1833333,
		pop: 1580,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Chiradzulu',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Nsanje',
		city_ascii: 'Nsanje',
		lat: -16.9166667,
		lng: 35.2666667,
		pop: 21774,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Nsanje',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Mwanza',
		city_ascii: 'Mwanza',
		lat: -15.6166667,
		lng: 34.5166667,
		pop: 11379,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Mwanza',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Mulanje',
		city_ascii: 'Mulanje',
		lat: -16.0333333,
		lng: 35.5,
		pop: 16483,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Mulanje',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Karonga',
		city_ascii: 'Karonga',
		lat: -9.932896302,
		lng: 33.93331864,
		pop: 33325.5,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Chitipa',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Chitipa',
		city_ascii: 'Chitipa',
		lat: -9.716217022,
		lng: 33.26664099,
		pop: 11118,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Chitipa',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Nkhata Bay',
		city_ascii: 'Nkhata Bay',
		lat: -11.59961627,
		lng: 34.30001461,
		pop: 16914.5,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Nkhata Bay',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Nkhotakota',
		city_ascii: 'Nkhotakota',
		lat: -12.91628009,
		lng: 34.30001461,
		pop: 42359.5,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Nkhotakota',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Mangochi',
		city_ascii: 'Mangochi',
		lat: -14.45959674,
		lng: 35.26998124,
		pop: 68973.5,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Mangochi',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Salima',
		city_ascii: 'Salima',
		lat: -13.78294554,
		lng: 34.43328813,
		pop: 50616.5,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Salima',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Chiromo',
		city_ascii: 'Chiromo',
		lat: -16.55001178,
		lng: 35.1332454,
		pop: 25235,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Nsanje',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Zomba',
		city_ascii: 'Zomba',
		lat: -15.39003091,
		lng: 35.31003048,
		pop: 80932,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Zomba',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Mzuzu',
		city_ascii: 'Mzuzu',
		lat: -11.45998655,
		lng: 34.01998002,
		pop: 110201,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Mzimba',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Blantyre',
		city_ascii: 'Blantyre',
		lat: -15.79000649,
		lng: 34.98994665,
		pop: 584877,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Blantyre',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Lilongwe',
		city_ascii: 'Lilongwe',
		lat: -13.98329507,
		lng: 33.78330196,
		pop: 646750,
		country: 'Malawi',
		iso2: 'MW',
		iso3: 'MWI',
		province: 'Lilongwe',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Kangar',
		city_ascii: 'Kangar',
		lat: 6.433001991,
		lng: 100.1899987,
		pop: 63869,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Perlis',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Kuala Lipis',
		city_ascii: 'Kuala Lipis',
		lat: 4.18400112,
		lng: 102.0420006,
		pop: 15448,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Pahang',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Shah Alam',
		city_ascii: 'Shah Alam',
		lat: 3.066695996,
		lng: 101.5499977,
		pop: 481654,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Selangor',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Teluk Intan',
		city_ascii: 'Teluk Intan',
		lat: 4.01185976,
		lng: 101.0314453,
		pop: 82506,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Perak',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Butterworth',
		city_ascii: 'Butterworth',
		lat: 5.417071146,
		lng: 100.4000109,
		pop: 464621.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Pulau Pinang',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Sungai Petani',
		city_ascii: 'Sungai Petani',
		lat: 5.649718444,
		lng: 100.4793343,
		pop: 293671,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Kedah',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Alor Setar',
		city_ascii: 'Alor Setar',
		lat: 6.113307718,
		lng: 100.3729325,
		pop: 276921.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Kedah',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Muar',
		city_ascii: 'Muar',
		lat: 2.033737609,
		lng: 102.566597,
		pop: 159621.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Johor',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Batu Pahat',
		city_ascii: 'Batu Pahat',
		lat: 1.850363789,
		lng: 102.9333447,
		pop: 177927.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Johor',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Keluang',
		city_ascii: 'Keluang',
		lat: 2.038310973,
		lng: 103.317869,
		pop: 163264,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Johor',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Seremban',
		city_ascii: 'Seremban',
		lat: 2.710492166,
		lng: 101.9400203,
		pop: 336824,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Negeri Sembilan',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Raub',
		city_ascii: 'Raub',
		lat: 3.792700011,
		lng: 101.8423002,
		pop: 36772.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Pahang',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Chukai',
		city_ascii: 'Chukai',
		lat: 4.233241596,
		lng: 103.4478869,
		pop: 63535.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Trengganu',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Kuala Terengganu',
		city_ascii: 'Kuala Terengganu',
		lat: 5.330409769,
		lng: 103.12,
		pop: 317637.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Trengganu',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Lahad Datu',
		city_ascii: 'Lahad Datu',
		lat: 5.046396097,
		lng: 118.3359704,
		pop: 82966,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Sabah',
		timezone: 'Asia/Kuching',
	},
	{
		city: 'Bintulu',
		city_ascii: 'Bintulu',
		lat: 3.16640749,
		lng: 113.0359838,
		pop: 117643.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Sarawak',
		timezone: 'Asia/Kuching',
	},
	{
		city: 'Miri',
		city_ascii: 'Miri',
		lat: 4.399923929,
		lng: 113.9845048,
		pop: 219957.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Sarawak',
		timezone: 'Asia/Kuching',
	},
	{
		city: 'Johor Bahru',
		city_ascii: 'Johor Bahru',
		lat: 1.480024637,
		lng: 103.7300402,
		pop: 838744.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Johor',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Kelang',
		city_ascii: 'Kelang',
		lat: 3.020369892,
		lng: 101.5500183,
		pop: 917933.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Selangor',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Taiping',
		city_ascii: 'Taiping',
		lat: 4.864960143,
		lng: 100.7199914,
		pop: 227371,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Perak',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Ipoh',
		city_ascii: 'Ipoh',
		lat: 4.599989236,
		lng: 101.0649833,
		pop: 656227,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Perak',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Kota Baharu',
		city_ascii: 'Kota Baharu',
		lat: 6.119973978,
		lng: 102.2299768,
		pop: 392449.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Kelantan',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Malacca',
		city_ascii: 'Malacca',
		lat: 2.206414407,
		lng: 102.2464615,
		pop: 645916.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Melaka',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Kuantan',
		city_ascii: 'Kuantan',
		lat: 3.829958719,
		lng: 103.3200394,
		pop: 320462,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Pahang',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Tawau',
		city_ascii: 'Tawau',
		lat: 4.270965392,
		lng: 117.8959973,
		pop: 297996.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Sabah',
		timezone: 'Asia/Kuching',
	},
	{
		city: 'Sandakan',
		city_ascii: 'Sandakan',
		lat: 5.842962462,
		lng: 118.107974,
		pop: 341788.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Sabah',
		timezone: 'Asia/Kuching',
	},
	{
		city: 'Kota Kinabalu',
		city_ascii: 'Kota Kinabalu',
		lat: 5.979982523,
		lng: 116.1100081,
		pop: 492498.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Sabah',
		timezone: 'Asia/Kuching',
	},
	{
		city: 'Sibu',
		city_ascii: 'Sibu',
		lat: 2.302971821,
		lng: 111.8430334,
		pop: 201035.5,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Sarawak',
		timezone: 'Asia/Kuching',
	},
	{
		city: 'George Town',
		city_ascii: 'George Town',
		lat: 5.413613156,
		lng: 100.3293679,
		pop: 1610101,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Pulau Pinang',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Kuching',
		city_ascii: 'Kuching',
		lat: 1.529969909,
		lng: 110.3299991,
		pop: 537685,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Sarawak',
		timezone: 'Asia/Kuching',
	},
	{
		city: 'Putrajaya',
		city_ascii: 'Putrajaya',
		lat: 2.914019795,
		lng: 101.701947,
		pop: 58982,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Selangor',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Kuala Lumpur',
		city_ascii: 'Kuala Lumpur',
		lat: 3.166665872,
		lng: 101.6999833,
		pop: 1448000,
		country: 'Malaysia',
		iso2: 'MY',
		iso3: 'MYS',
		province: 'Selangor',
		timezone: 'Asia/Kuala_Lumpur',
	},
	{
		city: 'Male',
		city_ascii: 'Male',
		lat: 4.16670819,
		lng: 73.49994747,
		pop: 108310,
		country: 'Maldives',
		iso2: 'MV',
		iso3: 'MDV',
		province: '',
		timezone: 'Indian/Maldives',
	},
	{
		city: 'Goundam',
		city_ascii: 'Goundam',
		lat: 16.41699404,
		lng: -3.666582684,
		pop: 6217.5,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Timbuktu',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Aguelhok',
		city_ascii: 'Aguelhok',
		lat: 19.45487062,
		lng: 0.856371214,
		pop: 8540,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Kidal',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Bourem',
		city_ascii: 'Bourem',
		lat: 16.90037539,
		lng: -0.349989259,
		pop: 28743,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Gao',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Kati',
		city_ascii: 'Kati',
		lat: 12.75042198,
		lng: -8.080008384,
		pop: 54908.5,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Bamako',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Banamba',
		city_ascii: 'Banamba',
		lat: 13.55039899,
		lng: -7.449995159,
		pop: 18312,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Bamako',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Kangaba',
		city_ascii: 'Kangaba',
		lat: 11.94041974,
		lng: -8.440012249,
		pop: 17232,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Bamako',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Nioro du Sahel',
		city_ascii: 'Nioro du Sahel',
		lat: 15.23042564,
		lng: -9.589967897,
		pop: 14421,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Kayes',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Bafoulabe',
		city_ascii: 'Bafoulabe',
		lat: 13.80038373,
		lng: -10.82002201,
		pop: 26823,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Kayes',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Satadougou',
		city_ascii: 'Satadougou',
		lat: 12.61699343,
		lng: -11.4066012,
		pop: 706,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Kayes',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Yelimane',
		city_ascii: 'Yelimane',
		lat: 15.13371319,
		lng: -10.56662663,
		pop: 988,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Kayes',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Kita',
		city_ascii: 'Kita',
		lat: 13.05040367,
		lng: -9.483307741,
		pop: 26102,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Kayes',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Koutiala',
		city_ascii: 'Koutiala',
		lat: 12.39041811,
		lng: -5.469986818,
		pop: 102140,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Sikasso',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Sikasso',
		city_ascii: 'Sikasso',
		lat: 11.3204059,
		lng: -5.679999839,
		pop: 185269.5,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Sikasso',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Bougouni',
		city_ascii: 'Bougouni',
		lat: 11.42042564,
		lng: -7.48996688,
		pop: 30547,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Sikasso',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Markala',
		city_ascii: 'Markala',
		lat: 13.67036582,
		lng: -6.069950197,
		pop: 46161.5,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Ségou',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Sokolo',
		city_ascii: 'Sokolo',
		lat: 14.73373761,
		lng: -6.133305503,
		pop: 4251,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Ségou',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'San',
		city_ascii: 'San',
		lat: 13.30041424,
		lng: -4.900047446,
		pop: 33098.5,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Ségou',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Taoudenni',
		city_ascii: 'Taoudenni',
		lat: 22.66658673,
		lng: -3.983359214,
		pop: 3019,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Timbuktu',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Araouane',
		city_ascii: 'Araouane',
		lat: 18.90002077,
		lng: -3.529950197,
		pop: 4026,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Timbuktu',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Tessalit',
		city_ascii: 'Tessalit',
		lat: 20.20138837,
		lng: 1.088811807,
		pop: 5869.5,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Kidal',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Menaka',
		city_ascii: 'Menaka',
		lat: 15.91666282,
		lng: 2.399997923,
		pop: 9110,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Gao',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Nara',
		city_ascii: 'Nara',
		lat: 15.18001528,
		lng: -7.279979697,
		pop: 18459,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Bamako',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Koulikoro',
		city_ascii: 'Koulikoro',
		lat: 12.88330792,
		lng: -7.549989056,
		pop: 13408.5,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Bamako',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Mopti',
		city_ascii: 'Mopti',
		lat: 14.48997988,
		lng: -4.180039715,
		pop: 93269.5,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Mopti',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Gao',
		city_ascii: 'Gao',
		lat: 16.26658978,
		lng: -0.05000757,
		pop: 87472.5,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Gao',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Kayes',
		city_ascii: 'Kayes',
		lat: 14.44998232,
		lng: -11.44001001,
		pop: 77207,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Kayes',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Segou',
		city_ascii: 'Segou',
		lat: 13.43999229,
		lng: -6.260016115,
		pop: 107752,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Ségou',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Timbuktu',
		city_ascii: 'Timbuktu',
		lat: 16.7665851,
		lng: -3.016596518,
		pop: 68872,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Timbuktu',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Bamako',
		city_ascii: 'Bamako',
		lat: 12.65001467,
		lng: -8.000039105,
		pop: 1395640.5,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Bamako',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Djenne',
		city_ascii: 'Djenne',
		lat: 13.89999005,
		lng: -4.549991294,
		pop: 27663,
		country: 'Mali',
		iso2: 'ML',
		iso3: 'MLI',
		province: 'Mopti',
		timezone: 'Africa/Bamako',
	},
	{
		city: 'Valletta',
		city_ascii: 'Valletta',
		lat: 35.89973248,
		lng: 14.51471065,
		pop: 187608,
		country: 'Malta',
		iso2: 'MT',
		iso3: 'MLT',
		province: '',
		timezone: 'Europe/Malta',
	},
	{
		city: 'Majuro',
		city_ascii: 'Majuro',
		lat: 7.103004311,
		lng: 171.3800002,
		pop: 22950,
		country: 'Marshall Islands',
		iso2: 'MH',
		iso3: 'MHL',
		province: '',
		timezone: 'Pacific/Majuro',
	},
	{
		city: 'Fderik',
		city_ascii: 'Fderik',
		lat: 22.67900113,
		lng: -12.70700053,
		pop: 5760,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Tiris Zemmour',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Aleg',
		city_ascii: 'Aleg',
		lat: 17.057997,
		lng: -13.90899556,
		pop: 8388,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Brakna',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Akjoujt',
		city_ascii: 'Akjoujt',
		lat: 19.74699906,
		lng: -14.39099948,
		pop: 370,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Inchiri',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Zouirat',
		city_ascii: 'Zouirat',
		lat: 22.73038129,
		lng: -12.4833055,
		pop: 56345,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Tiris Zemmour',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Chegga',
		city_ascii: 'Chegga',
		lat: 25.38040041,
		lng: -5.779967897,
		pop: 10,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Tiris Zemmour',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Magta Lajar',
		city_ascii: 'Magta Lajar',
		lat: 17.00039512,
		lng: -13.49998763,
		pop: 10,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Brakna',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Bogue',
		city_ascii: 'Bogue',
		lat: 16.59039431,
		lng: -14.26996647,
		pop: 10415,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Brakna',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Boutilimit',
		city_ascii: 'Boutilimit',
		lat: 17.55038739,
		lng: -14.70004358,
		pop: 14142,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Trarza',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Selibaby',
		city_ascii: 'Selibaby',
		lat: 15.16701866,
		lng: -12.18332381,
		pop: 460,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Guidimaka',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Timbedra',
		city_ascii: 'Timbedra',
		lat: 16.25041506,
		lng: -8.166618084,
		pop: 245,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Hodh ech Chargui',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Nema',
		city_ascii: 'Nema',
		lat: 16.61705935,
		lng: -7.250007366,
		pop: 127500,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Hodh ech Chargui',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Saint-Louis',
		city_ascii: 'Saint-Louis',
		lat: 16.01998985,
		lng: -16.51001062,
		pop: 198396,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Trarza',
		timezone: 'Africa/Dakar',
	},
	{
		city: 'Tidjikdja',
		city_ascii: 'Tidjikdja',
		lat: 18.5500163,
		lng: -11.41660059,
		pop: 19981,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Tagant',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Bir Mogrein',
		city_ascii: 'Bir Mogrein',
		lat: 25.23330345,
		lng: -11.58330876,
		pop: 10,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Tiris Zemmour',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Rosso',
		city_ascii: 'Rosso',
		lat: 16.52401593,
		lng: -15.81266301,
		pop: 47203,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Trarza',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Kiffa',
		city_ascii: 'Kiffa',
		lat: 16.61997906,
		lng: -11.39998661,
		pop: 73930,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Assaba',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Nouadhibou',
		city_ascii: 'Nouadhibou',
		lat: 20.90000205,
		lng: -17.05602381,
		pop: 86738,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Dakhlet Nouadhibou',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Ayoun el Atrous',
		city_ascii: 'Ayoun el Atrous',
		lat: 16.66659121,
		lng: -9.616658774,
		pop: 1423,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Hodh el Gharbi',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Nouakchott',
		city_ascii: 'Nouakchott',
		lat: 18.08642702,
		lng: -15.97534041,
		pop: 701772,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Nouakchott',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Atar',
		city_ascii: 'Atar',
		lat: 20.51664044,
		lng: -13.04998926,
		pop: 44265,
		country: 'Mauritania',
		iso2: 'MR',
		iso3: 'MRT',
		province: 'Adrar',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Curepipe',
		city_ascii: 'Curepipe',
		lat: -20.31619017,
		lng: 57.51663367,
		pop: 192087.5,
		country: 'Mauritius',
		iso2: 'MU',
		iso3: 'MUS',
		province: '',
		timezone: 'Indian/Mauritius',
	},
	{
		city: 'Port Louis',
		city_ascii: 'Port Louis',
		lat: -20.16663857,
		lng: 57.49999385,
		pop: 371953.5,
		country: 'Mauritius',
		iso2: 'MU',
		iso3: 'MUS',
		province: '',
		timezone: 'Indian/Mauritius',
	},
	{
		city: 'Vicente Guerrero',
		city_ascii: 'Vicente Guerrero',
		lat: 30.76405113,
		lng: -116.0092603,
		pop: 7294.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Baja California',
		timezone: 'America/Tijuana',
	},
	{
		city: 'Loreto',
		city_ascii: 'Loreto',
		lat: 26.01333335,
		lng: -111.3516635,
		pop: 10760.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Baja California Sur',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Ciudad Constitucion',
		city_ascii: 'Ciudad Constitucion',
		lat: 25.04000775,
		lng: -111.6599909,
		pop: 37605.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Baja California Sur',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Allende',
		city_ascii: 'Allende',
		lat: 28.32998781,
		lng: -100.8499789,
		pop: 18268.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Coahuila',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Nueva Rosita',
		city_ascii: 'Nueva Rosita',
		lat: 27.94995933,
		lng: -101.2199821,
		pop: 35746.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Coahuila',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Hidalgo del Parral',
		city_ascii: 'Hidalgo del Parral',
		lat: 26.93335472,
		lng: -105.6666358,
		pop: 102573,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chihuahua',
		timezone: 'America/Chihuahua',
	},
	{
		city: 'Ascension',
		city_ascii: 'Ascension',
		lat: 31.10002545,
		lng: -107.979983,
		pop: 10761,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chihuahua',
		timezone: 'America/Ojinaga',
	},
	{
		city: 'Gomez Palacio',
		city_ascii: 'Gomez Palacio',
		lat: 25.57005292,
		lng: -103.5000238,
		pop: 313952.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Durango',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Canatlan',
		city_ascii: 'Canatlan',
		lat: 24.51998781,
		lng: -104.7799974,
		pop: 7806.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Durango',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Villa Union',
		city_ascii: 'Villa Union',
		lat: 23.19999086,
		lng: -106.2300381,
		pop: 14563,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sinaloa',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Altata',
		city_ascii: 'Altata',
		lat: 24.63604509,
		lng: -107.9162153,
		pop: 750,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sinaloa',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Esperanza',
		city_ascii: 'Esperanza',
		lat: 27.58000775,
		lng: -109.9299931,
		pop: 3836,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sonora',
		timezone: 'America/Hermosillo',
	},
	{
		city: 'Magdalena',
		city_ascii: 'Magdalena',
		lat: 30.61661826,
		lng: -111.0499506,
		pop: 13402,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sonora',
		timezone: 'America/Hermosillo',
	},
	{
		city: 'Nacozari Viejo',
		city_ascii: 'Nacozari Viejo',
		lat: 30.42001528,
		lng: -109.6499844,
		pop: 11872,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sonora',
		timezone: 'America/Hermosillo',
	},
	{
		city: 'Villanueva',
		city_ascii: 'Villanueva',
		lat: 22.35001691,
		lng: -102.88001,
		pop: 9093.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Zacatecas',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Montemorelos',
		city_ascii: 'Montemorelos',
		lat: 25.1899986,
		lng: -99.83998885,
		pop: 40167,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Nuevo León',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Sabinas Hidalgo',
		city_ascii: 'Sabinas Hidalgo',
		lat: 26.51002138,
		lng: -100.1799681,
		pop: 26122.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Nuevo León',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Cardenas',
		city_ascii: 'Cardenas',
		lat: 22.00001243,
		lng: -99.66999923,
		pop: 15331,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'San Luis Potosí',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Ciudad Valles',
		city_ascii: 'Ciudad Valles',
		lat: 21.97998781,
		lng: -99.02001306,
		pop: 112234,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'San Luis Potosí',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Rio Verde',
		city_ascii: 'Rio Verde',
		lat: 21.92999086,
		lng: -99.98000615,
		pop: 59233.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'San Luis Potosí',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Ciudad Mante',
		city_ascii: 'Ciudad Mante',
		lat: 22.73335268,
		lng: -98.95001734,
		pop: 78299.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tamaulipas',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Reynosa',
		city_ascii: 'Reynosa',
		lat: 26.07999595,
		lng: -98.30003117,
		pop: 458997,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tamaulipas',
		timezone: 'America/Matamoros',
	},
	{
		city: 'Ciudad Madero',
		city_ascii: 'Ciudad Madero',
		lat: 22.31890769,
		lng: -97.836106,
		pop: 165411.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tamaulipas',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Autlan',
		city_ascii: 'Autlan',
		lat: 19.77001935,
		lng: -104.3699966,
		pop: 44912,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Jalisco',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Ciudad Hidalgo',
		city_ascii: 'Ciudad Hidalgo',
		lat: 19.67997316,
		lng: -100.569996,
		pop: 59573.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Michoacán',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Apatzingan',
		city_ascii: 'Apatzingan',
		lat: 19.07998395,
		lng: -102.3500165,
		pop: 92616.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Michoacán',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Santiago Ixcuintla',
		city_ascii: 'Santiago Ixcuintla',
		lat: 21.81999758,
		lng: -105.2200481,
		pop: 17077.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Nayarit',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Juchitan',
		city_ascii: 'Juchitan',
		lat: 16.42999066,
		lng: -95.01999882,
		pop: 62254.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Oaxaca',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Miahuatlan',
		city_ascii: 'Miahuatlan',
		lat: 16.32999676,
		lng: -96.60000574,
		pop: 16661.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Oaxaca',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Atlixco',
		city_ascii: 'Atlixco',
		lat: 18.90002077,
		lng: -98.44999618,
		pop: 91866.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Puebla',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Acatlan',
		city_ascii: 'Acatlan',
		lat: 18.19996014,
		lng: -98.04999475,
		pop: 17585.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Puebla',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Paraiso',
		city_ascii: 'Paraiso',
		lat: 18.40002545,
		lng: -93.22997888,
		pop: 14167.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tabasco',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Balancan',
		city_ascii: 'Balancan',
		lat: 17.79995872,
		lng: -91.52997929,
		pop: 7666.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tabasco',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Tlaxcala',
		city_ascii: 'Tlaxcala',
		lat: 19.31999514,
		lng: -98.2300096,
		pop: 296836.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tlaxcala',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Irapuato',
		city_ascii: 'Irapuato',
		lat: 20.67001609,
		lng: -101.4999909,
		pop: 339554,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Guanajuato',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Celaya',
		city_ascii: 'Celaya',
		lat: 20.53002464,
		lng: -100.8000078,
		pop: 344799,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Guanajuato',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Chilpancingo',
		city_ascii: 'Chilpancingo',
		lat: 17.54997398,
		lng: -99.5000096,
		pop: 173818.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Guerrero',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Iguala',
		city_ascii: 'Iguala',
		lat: 18.37000144,
		lng: -99.53998133,
		pop: 110641.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Guerrero',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Tecpan',
		city_ascii: 'Tecpan',
		lat: 17.24999229,
		lng: -100.6799893,
		pop: 14638,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Guerrero',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Atoyac',
		city_ascii: 'Atoyac',
		lat: 17.19999534,
		lng: -100.4300304,
		pop: 19798,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Guerrero',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Nezahualcoyotl',
		city_ascii: 'Nezahualcoyotl',
		lat: 19.41001548,
		lng: -99.02998661,
		pop: 929681.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'México',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'San Juan del Rio',
		city_ascii: 'San Juan del Rio',
		lat: 20.37998212,
		lng: -100.0000308,
		pop: 132866,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Querétaro',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Jaltipan',
		city_ascii: 'Jaltipan',
		lat: 17.93997601,
		lng: -94.73999007,
		pop: 66998,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Veracruz',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Orizaba',
		city_ascii: 'Orizaba',
		lat: 18.85002382,
		lng: -97.12999923,
		pop: 238340.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Veracruz',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Xalapa',
		city_ascii: 'Xalapa',
		lat: 19.52998232,
		lng: -96.91998621,
		pop: 438667,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Veracruz',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Nautla',
		city_ascii: 'Nautla',
		lat: 20.21658124,
		lng: -96.78335372,
		pop: 2653.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Veracruz',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'San Cristobal de Las Casas',
		city_ascii: 'San Cristobal de Las Casas',
		lat: 16.74999697,
		lng: -92.63337447,
		pop: 157828.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chiapas',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Escuintla',
		city_ascii: 'Escuintla',
		lat: 15.33000612,
		lng: -92.62998967,
		pop: 58313.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chiapas',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Motul',
		city_ascii: 'Motul',
		lat: 21.09998985,
		lng: -89.27998743,
		pop: 21181,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Yucatán',
		timezone: 'America/Merida',
	},
	{
		city: 'Tekax',
		city_ascii: 'Tekax',
		lat: 20.1999931,
		lng: -89.27998743,
		pop: 20577,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Yucatán',
		timezone: 'America/Merida',
	},
	{
		city: 'Peto',
		city_ascii: 'Peto',
		lat: 20.12997154,
		lng: -88.91998356,
		pop: 12924,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Yucatán',
		timezone: 'America/Merida',
	},
	{
		city: 'Halacho',
		city_ascii: 'Halacho',
		lat: 20.47997601,
		lng: -90.08001612,
		pop: 8116,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Yucatán',
		timezone: 'America/Merida',
	},
	{
		city: 'San Quintin',
		city_ascii: 'San Quintin',
		lat: 30.48373232,
		lng: -115.9499874,
		pop: 5433,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Baja California',
		timezone: 'America/Tijuana',
	},
	{
		city: 'Punta Prieta',
		city_ascii: 'Punta Prieta',
		lat: 28.93369773,
		lng: -114.1665821,
		pop: 527,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Baja California',
		timezone: 'America/Tijuana',
	},
	{
		city: 'San Felipe',
		city_ascii: 'San Felipe',
		lat: 31.02411277,
		lng: -114.8496153,
		pop: 18068,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Baja California',
		timezone: 'America/Tijuana',
	},
	{
		city: 'Santa Rosalia',
		city_ascii: 'Santa Rosalia',
		lat: 27.31707806,
		lng: -112.2833637,
		pop: 11110,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Baja California Sur',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Guerrero Negro',
		city_ascii: 'Guerrero Negro',
		lat: 27.99042198,
		lng: -114.1699669,
		pop: 13054,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Baja California Sur',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Piedras Negras',
		city_ascii: 'Piedras Negras',
		lat: 28.70763918,
		lng: -100.5316521,
		pop: 137295,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Coahuila',
		timezone: 'America/Matamoros',
	},
	{
		city: 'San Pedro de las Colonias',
		city_ascii: 'San Pedro de las Colonias',
		lat: 25.7592145,
		lng: -102.9826911,
		pop: 53688,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Coahuila',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Sierra Mojada',
		city_ascii: 'Sierra Mojada',
		lat: 27.28757082,
		lng: -103.2871945,
		pop: 10,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Coahuila',
		timezone: 'America/Matamoros',
	},
	{
		city: 'Parras',
		city_ascii: 'Parras',
		lat: 25.42039797,
		lng: -102.1799494,
		pop: 23714,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Coahuila',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Cuauhtemoc',
		city_ascii: 'Cuauhtemoc',
		lat: 28.42574424,
		lng: -106.8695856,
		pop: 84967.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chihuahua',
		timezone: 'America/Chihuahua',
	},
	{
		city: 'Nuevo Casas Grandes',
		city_ascii: 'Nuevo Casas Grandes',
		lat: 30.41849082,
		lng: -107.9118993,
		pop: 53091,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chihuahua',
		timezone: 'America/Chihuahua',
	},
	{
		city: 'Ojinaga',
		city_ascii: 'Ojinaga',
		lat: 29.54040489,
		lng: -104.41002,
		pop: 19804.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chihuahua',
		timezone: 'America/Ojinaga',
	},
	{
		city: 'Villa Ahumada',
		city_ascii: 'Villa Ahumada',
		lat: 30.61044293,
		lng: -106.5099952,
		pop: 8213.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chihuahua',
		timezone: 'America/Chihuahua',
	},
	{
		city: 'Santa Barbara',
		city_ascii: 'Santa Barbara',
		lat: 26.80044293,
		lng: -105.8200373,
		pop: 8413.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chihuahua',
		timezone: 'America/Chihuahua',
	},
	{
		city: 'Ciudad Camargo',
		city_ascii: 'Ciudad Camargo',
		lat: 27.69041445,
		lng: -105.1700511,
		pop: 35904.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chihuahua',
		timezone: 'America/Chihuahua',
	},
	{
		city: 'Cuencame',
		city_ascii: 'Cuencame',
		lat: 24.8704057,
		lng: -103.6999858,
		pop: 5416,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Durango',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Papasquiaro',
		city_ascii: 'Papasquiaro',
		lat: 24.83040814,
		lng: -105.3399891,
		pop: 11635.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Durango',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Escuinapa',
		city_ascii: 'Escuinapa',
		lat: 22.85042564,
		lng: -105.7999868,
		pop: 28248,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sinaloa',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Guamuchil',
		city_ascii: 'Guamuchil',
		lat: 25.47036908,
		lng: -108.0900021,
		pop: 60985.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sinaloa',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Guasave',
		city_ascii: 'Guasave',
		lat: 25.57049217,
		lng: -108.4699789,
		pop: 82654.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sinaloa',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'El Fuerte',
		city_ascii: 'El Fuerte',
		lat: 26.42041445,
		lng: -108.6199956,
		pop: 8326.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sinaloa',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Eldorado',
		city_ascii: 'Eldorado',
		lat: 24.3304645,
		lng: -107.3699943,
		pop: 15750.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sinaloa',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'La Cruz',
		city_ascii: 'La Cruz',
		lat: 23.92041201,
		lng: -106.900023,
		pop: 11527.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sinaloa',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Agua Prieta',
		city_ascii: 'Agua Prieta',
		lat: 31.32233746,
		lng: -109.5630388,
		pop: 77264.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sonora',
		timezone: 'America/Hermosillo',
	},
	{
		city: 'Ciudad Obregon',
		city_ascii: 'Ciudad Obregon',
		lat: 27.46660382,
		lng: -109.9249805,
		pop: 273082,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sonora',
		timezone: 'America/Hermosillo',
	},
	{
		city: 'Navajoa',
		city_ascii: 'Navajoa',
		lat: 27.08189862,
		lng: -109.4546216,
		pop: 116093,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sonora',
		timezone: 'America/Hermosillo',
	},
	{
		city: 'Caborca',
		city_ascii: 'Caborca',
		lat: 30.71614707,
		lng: -112.1642495,
		pop: 55126.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sonora',
		timezone: 'America/Hermosillo',
	},
	{
		city: 'Mazatlán',
		city_ascii: 'Mazatlan',
		lat: 29.01710349,
		lng: -110.1333399,
		pop: 469217,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sonora',
		timezone: 'America/Hermosillo',
	},
	{
		city: 'Cananea',
		city_ascii: 'Cananea',
		lat: 30.99041974,
		lng: -110.3000481,
		pop: 28625.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sonora',
		timezone: 'America/Hermosillo',
	},
	{
		city: 'Huatabampo',
		city_ascii: 'Huatabampo',
		lat: 26.83041526,
		lng: -109.6300373,
		pop: 27744.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sonora',
		timezone: 'America/Hermosillo',
	},
	{
		city: 'Zacatecas',
		city_ascii: 'Zacatecas',
		lat: 22.77043052,
		lng: -102.5800025,
		pop: 176521.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Zacatecas',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Juan Aldama',
		city_ascii: 'Juan Aldama',
		lat: 24.29041526,
		lng: -103.3899789,
		pop: 13117.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Zacatecas',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Valparaiso',
		city_ascii: 'Valparaiso',
		lat: 22.77043052,
		lng: -103.5699679,
		pop: 7956.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Zacatecas',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Fresnillo',
		city_ascii: 'Fresnillo',
		lat: 23.17043194,
		lng: -102.8599854,
		pop: 102629.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Zacatecas',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Linares',
		city_ascii: 'Linares',
		lat: 24.86038047,
		lng: -99.57003117,
		pop: 52349.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Nuevo León',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Matehuala',
		city_ascii: 'Matehuala',
		lat: 23.6603762,
		lng: -100.6500169,
		pop: 63624.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'San Luis Potosí',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Tamuin',
		city_ascii: 'Tamuin',
		lat: 21.98037539,
		lng: -98.7500037,
		pop: 11076.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'San Luis Potosí',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Tamazunchale',
		city_ascii: 'Tamazunchale',
		lat: 21.27041872,
		lng: -98.7799502,
		pop: 47108.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'San Luis Potosí',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Tula',
		city_ascii: 'Tula',
		lat: 23.00039064,
		lng: -99.71999618,
		pop: 4683,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tamaulipas',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Aldama',
		city_ascii: 'Aldama',
		lat: 22.92042137,
		lng: -98.06996769,
		pop: 11367,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tamaulipas',
		timezone: 'America/Monterrey',
	},
	{
		city: 'San Fernando',
		city_ascii: 'San Fernando',
		lat: 24.85043276,
		lng: -98.16001388,
		pop: 25596,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tamaulipas',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Tecoman',
		city_ascii: 'Tecoman',
		lat: 18.92038129,
		lng: -103.8799748,
		pop: 85450,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Colima',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Puerto Vallarta',
		city_ascii: 'Puerto Vallarta',
		lat: 20.67709576,
		lng: -105.2449819,
		pop: 183969,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Jalisco',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'La Barca',
		city_ascii: 'La Barca',
		lat: 20.28037579,
		lng: -102.5600037,
		pop: 34897,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Jalisco',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Ciudad Guzman',
		city_ascii: 'Ciudad Guzman',
		lat: 19.71041058,
		lng: -103.4600004,
		pop: 90480,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Jalisco',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Lagos de Moreno',
		city_ascii: 'Lagos de Moreno',
		lat: 21.37041262,
		lng: -101.9299905,
		pop: 89402,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Jalisco',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Morelia',
		city_ascii: 'Morelia',
		lat: 19.73338076,
		lng: -101.189493,
		pop: 618551.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Michoacán',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Lazaro Cardenas',
		city_ascii: 'Lazaro Cardenas',
		lat: 17.95870872,
		lng: -102.199974,
		pop: 122044,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Michoacán',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Zamora',
		city_ascii: 'Zamora',
		lat: 19.98036826,
		lng: -102.2800208,
		pop: 169931.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Michoacán',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Coalcoman',
		city_ascii: 'Coalcoman',
		lat: 18.78038983,
		lng: -103.1499677,
		pop: 9687.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Michoacán',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Uruapan',
		city_ascii: 'Uruapan',
		lat: 19.42037661,
		lng: -102.0700078,
		pop: 250919.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Michoacán',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Tuxpan',
		city_ascii: 'Tuxpan',
		lat: 21.93040428,
		lng: -105.2699675,
		pop: 26115,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Nayarit',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Tepic',
		city_ascii: 'Tepic',
		lat: 21.50539146,
		lng: -104.8799913,
		pop: 299686.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Nayarit',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Compostela',
		city_ascii: 'Compostela',
		lat: 21.23042116,
		lng: -104.8999901,
		pop: 15192,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Nayarit',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Tecuala',
		city_ascii: 'Tecuala',
		lat: 22.40042727,
		lng: -105.4599817,
		pop: 12752,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Nayarit',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Ciudad del Carmen',
		city_ascii: 'Ciudad del Carmen',
		lat: 18.65365928,
		lng: -91.82448019,
		pop: 148751.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Campeche',
		timezone: 'America/Merida',
	},
	{
		city: 'Champoton',
		city_ascii: 'Champoton',
		lat: 19.35043256,
		lng: -90.72000289,
		pop: 25722.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Campeche',
		timezone: 'America/Merida',
	},
	{
		city: 'Salina Cruz',
		city_ascii: 'Salina Cruz',
		lat: 16.16706097,
		lng: -95.19998784,
		pop: 77355.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Oaxaca',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Puerto Escondido',
		city_ascii: 'Puerto Escondido',
		lat: 15.85918031,
		lng: -97.06588835,
		pop: 15402.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Oaxaca',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Pochutla',
		city_ascii: 'Pochutla',
		lat: 15.73039512,
		lng: -96.46998784,
		pop: 18779.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Oaxaca',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Mitla',
		city_ascii: 'Mitla',
		lat: 16.91704103,
		lng: -96.39999211,
		pop: 4313,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Oaxaca',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Tlaxiaco',
		city_ascii: 'Tlaxiaco',
		lat: 17.27040448,
		lng: -97.68001734,
		pop: 17999.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Oaxaca',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Huajuapan de Leon',
		city_ascii: 'Huajuapan de Leon',
		lat: 17.81037152,
		lng: -97.78998478,
		pop: 41766.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Oaxaca',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Tehuacan',
		city_ascii: 'Tehuacan',
		lat: 18.4503583,
		lng: -97.37998397,
		pop: 239370.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Puebla',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Teziutlan',
		city_ascii: 'Teziutlan',
		lat: 19.82042971,
		lng: -97.35998519,
		pop: 124307.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Puebla',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Frontera',
		city_ascii: 'Frontera',
		lat: 18.5803762,
		lng: -92.64998845,
		pop: 15014,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tabasco',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Tenosique',
		city_ascii: 'Tenosique',
		lat: 17.48036582,
		lng: -91.42998539,
		pop: 24949.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tabasco',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Salamanca',
		city_ascii: 'Salamanca',
		lat: 20.57040977,
		lng: -101.2000092,
		pop: 168069,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Guanajuato',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Guanajuato',
		city_ascii: 'Guanajuato',
		lat: 21.02040814,
		lng: -101.2799785,
		pop: 95282,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Guanajuato',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Taxco',
		city_ascii: 'Taxco',
		lat: 18.57037681,
		lng: -99.6199506,
		pop: 53217,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Guerrero',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Ayutla',
		city_ascii: 'Ayutla',
		lat: 16.90037539,
		lng: -99.22000086,
		pop: 9897,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Guerrero',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Ciudad Altamirano',
		city_ascii: 'Ciudad Altamirano',
		lat: 18.32039207,
		lng: -100.6500169,
		pop: 24533,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Guerrero',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Petatlan',
		city_ascii: 'Petatlan',
		lat: 17.52041506,
		lng: -101.2700308,
		pop: 26474,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Guerrero',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Pachuca',
		city_ascii: 'Pachuca',
		lat: 20.17043418,
		lng: -98.73003076,
		pop: 310861,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Hidalgo',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Toluca',
		city_ascii: 'Toluca',
		lat: 19.3303821,
		lng: -99.66999923,
		pop: 1018440.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'México',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Zumpango',
		city_ascii: 'Zumpango',
		lat: 19.81040448,
		lng: -99.10998173,
		pop: 188994,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'México',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Minatitlan',
		city_ascii: 'Minatitlan',
		lat: 17.9804645,
		lng: -94.53000289,
		pop: 176398.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Veracruz',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Coatzacoalcos',
		city_ascii: 'Coatzacoalcos',
		lat: 18.12040428,
		lng: -94.4200096,
		pop: 259001,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Veracruz',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Poza Rica de Hidalgo',
		city_ascii: 'Poza Rica de Hidalgo',
		lat: 20.55043683,
		lng: -97.46997848,
		pop: 214503.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Veracruz',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Cordoba',
		city_ascii: 'Cordoba',
		lat: 18.92038129,
		lng: -96.91998621,
		pop: 177483,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Veracruz',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Santiago Tuxtla',
		city_ascii: 'Santiago Tuxtla',
		lat: 18.47038291,
		lng: -95.29998173,
		pop: 13598,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Veracruz',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Tuxpam',
		city_ascii: 'Tuxpam',
		lat: 20.9604118,
		lng: -97.40998214,
		pop: 58690,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Veracruz',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Panuco',
		city_ascii: 'Panuco',
		lat: 22.06044802,
		lng: -98.18998621,
		pop: 33449.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Veracruz',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Pijijiapan',
		city_ascii: 'Pijijiapan',
		lat: 15.69039756,
		lng: -93.22003117,
		pop: 19388,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chiapas',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Isla Mujeres',
		city_ascii: 'Isla Mujeres',
		lat: 21.20839057,
		lng: -86.7114549,
		pop: 12491,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Quintana Roo',
		timezone: 'America/Cancun',
	},
	{
		city: 'Felipe Carrillo Puerto',
		city_ascii: 'Felipe Carrillo Puerto',
		lat: 19.58039268,
		lng: -88.04998499,
		pop: 23958,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Quintana Roo',
		timezone: 'America/Cancun',
	},
	{
		city: 'Tizimin',
		city_ascii: 'Tizimin',
		lat: 21.13042727,
		lng: -88.14997888,
		pop: 41322.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Yucatán',
		timezone: 'America/Merida',
	},
	{
		city: 'Valladolid',
		city_ascii: 'Valladolid',
		lat: 20.67040367,
		lng: -88.20000167,
		pop: 44071,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Yucatán',
		timezone: 'America/Merida',
	},
	{
		city: 'Izamal',
		city_ascii: 'Izamal',
		lat: 20.93041363,
		lng: -89.02005497,
		pop: 14570,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Yucatán',
		timezone: 'America/Merida',
	},
	{
		city: 'Ticul',
		city_ascii: 'Ticul',
		lat: 20.40039431,
		lng: -89.53002385,
		pop: 30400.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Yucatán',
		timezone: 'America/Merida',
	},
	{
		city: 'Ensenada',
		city_ascii: 'Ensenada',
		lat: 31.86997845,
		lng: -116.6199982,
		pop: 238751,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Baja California',
		timezone: 'America/Tijuana',
	},
	{
		city: 'Saltillo',
		city_ascii: 'Saltillo',
		lat: 25.41995872,
		lng: -101.0049823,
		pop: 679286.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Coahuila',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Ciudad Juárez',
		city_ascii: 'Ciudad Juarez',
		lat: 31.69037701,
		lng: -106.4900481,
		pop: 1343000,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chihuahua',
		timezone: 'America/Ojinaga',
	},
	{
		city: 'Delicias',
		city_ascii: 'Delicias',
		lat: 28.19996991,
		lng: -105.4999793,
		pop: 108876,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chihuahua',
		timezone: 'America/Chihuahua',
	},
	{
		city: 'Durango',
		city_ascii: 'Durango',
		lat: 24.03110292,
		lng: -104.67003,
		pop: 456368.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Durango',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Los Mochis',
		city_ascii: 'Los Mochis',
		lat: 25.78998781,
		lng: -108.9999982,
		pop: 231824,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sinaloa',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Ciudad Victoria',
		city_ascii: 'Ciudad Victoria',
		lat: 23.71995913,
		lng: -99.12998051,
		pop: 272411.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tamaulipas',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Aguascalientes',
		city_ascii: 'Aguascalientes',
		lat: 21.87945992,
		lng: -102.2904135,
		pop: 763589.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Aguascalientes',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Manzanillo',
		city_ascii: 'Manzanillo',
		lat: 19.04958662,
		lng: -104.3230851,
		pop: 85236.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Colima',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Tehuantepec',
		city_ascii: 'Tehuantepec',
		lat: 16.32999676,
		lng: -95.229986,
		pop: 40082.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Oaxaca',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Villahermosa',
		city_ascii: 'Villahermosa',
		lat: 17.99997235,
		lng: -92.89997319,
		pop: 395482.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tabasco',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Cuernavaca',
		city_ascii: 'Cuernavaca',
		lat: 18.92110476,
		lng: -99.23999964,
		pop: 591551.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Morelos',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Queretaro',
		city_ascii: 'Queretaro',
		lat: 20.63001853,
		lng: -100.3799817,
		pop: 786392.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Querétaro',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Tapachula',
		city_ascii: 'Tapachula',
		lat: 14.89998069,
		lng: -92.2699858,
		pop: 209741,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chiapas',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Chetumal',
		city_ascii: 'Chetumal',
		lat: 18.50001935,
		lng: -88.29999557,
		pop: 144464.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Quintana Roo',
		timezone: 'America/Cancun',
	},
	{
		city: 'Progreso',
		city_ascii: 'Progreso',
		lat: 21.28331199,
		lng: -89.66657882,
		pop: 33892.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Yucatán',
		timezone: 'America/Merida',
	},
	{
		city: 'Cabo San Lucas',
		city_ascii: 'Cabo San Lucas',
		lat: 22.89275617,
		lng: -109.9045164,
		pop: 39492.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Baja California Sur',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Monclova',
		city_ascii: 'Monclova',
		lat: 26.89999758,
		lng: -101.4199958,
		pop: 216004,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Coahuila',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Ometepec',
		city_ascii: 'Ometepec',
		lat: 16.68005292,
		lng: -98.42002385,
		pop: 24036,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Guerrero',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Cozumel',
		city_ascii: 'Cozumel',
		lat: 20.51000002,
		lng: -86.95000045,
		pop: 67634,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Quintana Roo',
		timezone: 'America/Cancun',
	},
	{
		city: 'Mexicali',
		city_ascii: 'Mexicali',
		lat: 32.64998252,
		lng: -115.4800161,
		pop: 736138.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Baja California',
		timezone: 'America/Tijuana',
	},
	{
		city: 'La Paz',
		city_ascii: 'La Paz',
		lat: 24.13995933,
		lng: -110.3199952,
		pop: 180626,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Baja California Sur',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Torreon',
		city_ascii: 'Torreon',
		lat: 25.57005292,
		lng: -103.4200029,
		pop: 834033,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Coahuila',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Culiacan',
		city_ascii: 'Culiacan',
		lat: 24.82999473,
		lng: -107.3799679,
		pop: 695734.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sinaloa',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Nogales',
		city_ascii: 'Nogales',
		lat: 31.30500002,
		lng: -110.9449958,
		pop: 99402,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sonora',
		timezone: 'America/Hermosillo',
	},
	{
		city: 'Hermosillo',
		city_ascii: 'Hermosillo',
		lat: 29.09888145,
		lng: -110.954065,
		pop: 554373,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sonora',
		timezone: 'America/Hermosillo',
	},
	{
		city: 'Guaymas',
		city_ascii: 'Guaymas',
		lat: 27.93001223,
		lng: -110.8899862,
		pop: 84496.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sonora',
		timezone: 'America/Hermosillo',
	},
	{
		city: 'San Luis Potosi',
		city_ascii: 'San Luis Potosi',
		lat: 22.16997622,
		lng: -100.9999956,
		pop: 834852,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'San Luis Potosí',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Matamoros',
		city_ascii: 'Matamoros',
		lat: 25.87998232,
		lng: -97.50000248,
		pop: 451394.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tamaulipas',
		timezone: 'America/Matamoros',
	},
	{
		city: 'Nuevo Laredo',
		city_ascii: 'Nuevo Laredo',
		lat: 27.4999868,
		lng: -99.55000655,
		pop: 255152.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tamaulipas',
		timezone: 'America/Matamoros',
	},
	{
		city: 'Colima',
		city_ascii: 'Colima',
		lat: 19.22997479,
		lng: -103.7200104,
		pop: 175261,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Colima',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Campeche',
		city_ascii: 'Campeche',
		lat: 19.82998985,
		lng: -90.49999048,
		pop: 204048.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Campeche',
		timezone: 'America/Merida',
	},
	{
		city: 'Oaxaca',
		city_ascii: 'Oaxaca',
		lat: 17.08268984,
		lng: -96.66994979,
		pop: 396647,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Oaxaca',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Leon',
		city_ascii: 'Leon',
		lat: 21.1499868,
		lng: -101.7000304,
		pop: 1301313,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Guanajuato',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Tijuana',
		city_ascii: 'Tijuana',
		lat: 32.50001752,
		lng: -117.079996,
		pop: 1464728.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Baja California',
		timezone: 'America/Tijuana',
	},
	{
		city: 'Chihuahua',
		city_ascii: 'Chihuahua',
		lat: 28.64498151,
		lng: -106.0849823,
		pop: 750633.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chihuahua',
		timezone: 'America/Chihuahua',
	},
	{
		city: 'Mazatlan',
		city_ascii: 'Mazatlan',
		lat: 23.22110069,
		lng: -106.4200007,
		pop: 361460.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Sinaloa',
		timezone: 'America/Mazatlan',
	},
	{
		city: 'Tampico',
		city_ascii: 'Tampico',
		lat: 22.30001996,
		lng: -97.87000574,
		pop: 578351.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Tamaulipas',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Acapulco',
		city_ascii: 'Acapulco',
		lat: 16.84999086,
		lng: -99.91597905,
		pop: 683860,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Guerrero',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Veracruz',
		city_ascii: 'Veracruz',
		lat: 19.17734235,
		lng: -96.15998092,
		pop: 573638,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Veracruz',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Tuxtla Gutierrez',
		city_ascii: 'Tuxtla Gutierrez',
		lat: 16.74999697,
		lng: -93.1500096,
		pop: 473719,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Chiapas',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Cancun',
		city_ascii: 'Cancun',
		lat: 21.16995974,
		lng: -86.83000777,
		pop: 489452.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Quintana Roo',
		timezone: 'America/Cancun',
	},
	{
		city: 'Merida',
		city_ascii: 'Merida',
		lat: 20.96663881,
		lng: -89.61663355,
		pop: 841087.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Yucatán',
		timezone: 'America/Merida',
	},
	{
		city: 'Guadalajara',
		city_ascii: 'Guadalajara',
		lat: 20.67001609,
		lng: -103.3300342,
		pop: 2919294.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Jalisco',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Puebla',
		city_ascii: 'Puebla',
		lat: 19.04995994,
		lng: -98.20003727,
		pop: 1793549.5,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Puebla',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Monterrey',
		city_ascii: 'Monterrey',
		lat: 25.66999514,
		lng: -100.3299848,
		pop: 2417437,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Nuevo León',
		timezone: 'America/Monterrey',
	},
	{
		city: 'Mexico City',
		city_ascii: 'Mexico City',
		lat: 19.44244244,
		lng: -99.1309882,
		pop: 14919501,
		country: 'Mexico',
		iso2: 'MX',
		iso3: 'MEX',
		province: 'Distrito Federal',
		timezone: 'America/Mexico_City',
	},
	{
		city: 'Dubasari',
		city_ascii: 'Dubasari',
		lat: 47.2630556,
		lng: 29.1608333,
		pop: 23254,
		country: 'Moldova',
		iso2: 'MD',
		iso3: 'MDA',
		province: 'Transnistria',
		timezone: 'Europe/Chisinau',
	},
	{
		city: 'Balti',
		city_ascii: 'Balti',
		lat: 47.75908612,
		lng: 27.90531449,
		pop: 135022.5,
		country: 'Moldova',
		iso2: 'MD',
		iso3: 'MDA',
		province: 'Balti',
		timezone: 'Europe/Chisinau',
	},
	{
		city: 'Cahul',
		city_ascii: 'Cahul',
		lat: 45.90788129,
		lng: 28.19444413,
		pop: 48949.5,
		country: 'Moldova',
		iso2: 'MD',
		iso3: 'MDA',
		province: 'Cahul',
		timezone: 'Europe/Chisinau',
	},
	{
		city: 'Tiraspol',
		city_ascii: 'Tiraspol',
		lat: 46.85309491,
		lng: 29.63998897,
		pop: 137097,
		country: 'Moldova',
		iso2: 'MD',
		iso3: 'MDA',
		province: 'Bender',
		timezone: 'Europe/Chisinau',
	},
	{
		city: 'Chisinau',
		city_ascii: 'Chisinau',
		lat: 47.00502362,
		lng: 28.85771114,
		pop: 662064,
		country: 'Moldova',
		iso2: 'MD',
		iso3: 'MDA',
		province: 'Chisinau',
		timezone: 'Europe/Chisinau',
	},
	{
		city: 'Monaco',
		city_ascii: 'Monaco',
		lat: 43.73964569,
		lng: 7.406913173,
		pop: 36371,
		country: 'Monaco',
		iso2: 'MC',
		iso3: 'MCO',
		province: '',
		timezone: 'Europe/Paris',
	},
	{
		city: 'Suchboatar',
		city_ascii: 'Suchboatar',
		lat: 50.24999712,
		lng: 106.2000006,
		pop: 24235,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Selenge',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Dzuunmod',
		city_ascii: 'Dzuunmod',
		lat: 47.71099805,
		lng: 106.9470006,
		pop: 17738,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Töv',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Tsetserleg',
		city_ascii: 'Tsetserleg',
		lat: 47.47688112,
		lng: 101.4501794,
		pop: 18056,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Arhangay',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Olgiy',
		city_ascii: 'Olgiy',
		lat: 48.93369143,
		lng: 89.95000281,
		pop: 31667,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Bayan-Ölgiy',
		timezone: 'Asia/Hovd',
	},
	{
		city: 'Ulaan-Uul',
		city_ascii: 'Ulaan-Uul',
		lat: 44.33371381,
		lng: 111.2333032,
		pop: 3726,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Dornogovi',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Hodrogo',
		city_ascii: 'Hodrogo',
		lat: 48.96642845,
		lng: 96.7832808,
		pop: 10,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Dzavhan',
		timezone: 'Asia/Hovd',
	},
	{
		city: 'Buyant-Uhaa',
		city_ascii: 'Buyant-Uhaa',
		lat: 44.86660118,
		lng: 110.1500101,
		pop: 8776,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Dornogovi',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Ondorhaan',
		city_ascii: 'Ondorhaan',
		lat: 47.31668418,
		lng: 110.6500313,
		pop: 12433.5,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Hentiy',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Bayankhongor',
		city_ascii: 'Bayankhongor',
		lat: 46.30000205,
		lng: 100.9833345,
		pop: 26252,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Bayanhongor',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Uliastay',
		city_ascii: 'Uliastay',
		lat: 47.75001691,
		lng: 96.81671545,
		pop: 8056,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Dzavhan',
		timezone: 'Asia/Hovd',
	},
	{
		city: 'Altay',
		city_ascii: 'Altay',
		lat: 46.39612022,
		lng: 95.8450435,
		pop: 32488,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Govi-Altay',
		timezone: 'Asia/Hovd',
	},
	{
		city: 'Moron',
		city_ascii: 'Moron',
		lat: 49.6452759,
		lng: 100.1544445,
		pop: 24608.5,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Hövsgöl',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Ulaangom',
		city_ascii: 'Ulaangom',
		lat: 49.98331728,
		lng: 92.0666178,
		pop: 31940.5,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Uvs',
		timezone: 'Asia/Hovd',
	},
	{
		city: 'Bulgan',
		city_ascii: 'Bulgan',
		lat: 48.81059816,
		lng: 103.5317061,
		pop: 17348,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Bulgan',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Mandalgovi',
		city_ascii: 'Mandalgovi',
		lat: 45.74998395,
		lng: 106.2660095,
		pop: 2984,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Dundgovi',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Darhan',
		city_ascii: 'Darhan',
		lat: 49.61669883,
		lng: 106.3500354,
		pop: 74738,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Selenge',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Dzuunharaa',
		city_ascii: 'Dzuunharaa',
		lat: 48.86658958,
		lng: 106.4666174,
		pop: 16074,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Selenge',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Arvayheer',
		city_ascii: 'Arvayheer',
		lat: 46.24997927,
		lng: 102.7665848,
		pop: 28053,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Övörhangay',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Baruun Urt',
		city_ascii: 'Baruun Urt',
		lat: 46.69997764,
		lng: 113.2833073,
		pop: 15805,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Sühbaatar',
		timezone: 'Asia/Choibalsan',
	},
	{
		city: 'Dalandzadgad',
		city_ascii: 'Dalandzadgad',
		lat: 43.58355288,
		lng: 104.4402811,
		pop: 13491,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Ömnögovi',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Dund-Us',
		city_ascii: 'Dund-Us',
		lat: 48.01664146,
		lng: 91.63325924,
		pop: 26596.5,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Hovd',
		timezone: 'Asia/Hovd',
	},
	{
		city: 'Choybalsan',
		city_ascii: 'Choybalsan',
		lat: 48.06658673,
		lng: 114.5060233,
		pop: 33376,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Dornod',
		timezone: 'Asia/Choibalsan',
	},
	{
		city: 'Erdenet',
		city_ascii: 'Erdenet',
		lat: 49.05329653,
		lng: 104.118337,
		pop: 79647,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Orhon',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Ulaanbaatar',
		city_ascii: 'Ulaanbaatar',
		lat: 47.9166734,
		lng: 106.9166158,
		pop: 827306,
		country: 'Mongolia',
		iso2: 'MN',
		iso3: 'MNG',
		province: 'Ulaanbaatar',
		timezone: 'Asia/Ulaanbaatar',
	},
	{
		city: 'Podgorica',
		city_ascii: 'Podgorica',
		lat: 42.46597251,
		lng: 19.26630692,
		pop: 141161.5,
		country: 'Montenegro',
		iso2: 'ME',
		iso3: 'MNE',
		province: 'Podgorica',
		timezone: 'Europe/Podgorica',
	},
	{
		city: 'Ksar El Kebir',
		city_ascii: 'Ksar El Kebir',
		lat: 35.02038047,
		lng: -5.909985801,
		pop: 207676.5,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Tanger - Tétouan',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Larache',
		city_ascii: 'Larache',
		lat: 35.20042116,
		lng: -6.160022218,
		pop: 114688,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Tanger - Tétouan',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Taza',
		city_ascii: 'Taza',
		lat: 34.22037762,
		lng: -4.019971966,
		pop: 170761.5,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Taza - Al Hoceima - Taounate',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Ouezzane',
		city_ascii: 'Ouezzane',
		lat: 34.81034161,
		lng: -5.570006553,
		pop: 64171,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Gharb - Chrarda - Béni Hssen',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Kenitra',
		city_ascii: 'Kenitra',
		lat: 34.27040041,
		lng: -6.579996583,
		pop: 459178,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Gharb - Chrarda - Béni Hssen',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Settat',
		city_ascii: 'Settat',
		lat: 33.01042564,
		lng: -7.620010622,
		pop: 140415,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Chaouia - Ouardigha',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Er Rachidia',
		city_ascii: 'Er Rachidia',
		lat: 31.94041343,
		lng: -4.449971559,
		pop: 228489,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Meknès - Tafilalet',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Meknes',
		city_ascii: 'Meknes',
		lat: 33.90042299,
		lng: -5.559981325,
		pop: 621666.5,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Meknès - Tafilalet',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Tiznit',
		city_ascii: 'Tiznit',
		lat: 29.71042035,
		lng: -9.73998458,
		pop: 56398.5,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Souss - Massa - Draâ',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'El Jadida',
		city_ascii: 'El Jadida',
		lat: 33.2603587,
		lng: -8.509982138,
		pop: 164009.5,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Doukkala - Abda',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Dawra',
		city_ascii: 'Dawra',
		lat: 27.46290895,
		lng: -12.99218917,
		pop: 10,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Laâyoune - Boujdour - Sakia El Hamra',
		timezone: 'Africa/El_Aaiun',
	},
	{
		city: 'Lemsid',
		city_ascii: 'Lemsid',
		lat: 26.54818656,
		lng: -13.84819219,
		pop: 100,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Laâyoune - Boujdour - Sakia El Hamra',
		timezone: 'Africa/El_Aaiun',
	},
	{
		city: 'Tan Tan',
		city_ascii: 'Tan Tan',
		lat: 28.43039512,
		lng: -11.10003076,
		pop: 63396,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Guelmim - Es-Semara',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Bir Anzarane',
		city_ascii: 'Bir Anzarane',
		lat: 23.88374758,
		lng: -14.53330957,
		pop: 6597,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Oued el Dahab',
		timezone: 'Africa/El_Aaiun',
	},
	{
		city: 'Tangier',
		city_ascii: 'Tangier',
		lat: 35.74728701,
		lng: -5.832703696,
		pop: 719208,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Tanger - Tétouan',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Agadir',
		city_ascii: 'Agadir',
		lat: 30.43998822,
		lng: -9.620043581,
		pop: 752031.5,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Souss - Massa - Draâ',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Goulimine',
		city_ascii: 'Goulimine',
		lat: 28.97997398,
		lng: -10.07001611,
		pop: 106748,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Guelmim - Es-Semara',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Smara',
		city_ascii: 'Smara',
		lat: 26.73328941,
		lng: -11.68332849,
		pop: 48149,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Guelmim - Es-Semara',
		timezone: 'Africa/El_Aaiun',
	},
	{
		city: 'Ad Dakhla',
		city_ascii: 'Ad Dakhla',
		lat: 23.71405588,
		lng: -15.93681087,
		pop: 82146,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Oued el Dahab',
		timezone: 'Africa/El_Aaiun',
	},
	{
		city: 'Oujda',
		city_ascii: 'Oujda',
		lat: 34.69001304,
		lng: -1.909971559,
		pop: 407322,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Oriental',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Safi',
		city_ascii: 'Safi',
		lat: 32.31997683,
		lng: -9.239989259,
		pop: 320819.5,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Doukkala - Abda',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Laayoune',
		city_ascii: 'Laayoune',
		lat: 27.14998232,
		lng: -13.20000594,
		pop: 182224.5,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Laâyoune - Boujdour - Sakia El Hamra',
		timezone: 'Africa/El_Aaiun',
	},
	{
		city: 'Fez',
		city_ascii: 'Fez',
		lat: 34.05459963,
		lng: -5.000377239,
		pop: 983445.5,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Fès - Boulemane',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Rabat',
		city_ascii: 'Rabat',
		lat: 34.02529909,
		lng: -6.83613082,
		pop: 1680376.5,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Rabat - Salé - Zemmour - Zaer',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Marrakesh',
		city_ascii: 'Marrakesh',
		lat: 31.6299931,
		lng: -7.999987428,
		pop: 855648,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Marrakech - Tensift - Al Haouz',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Casablanca',
		city_ascii: 'Casablanca',
		lat: 33.59997622,
		lng: -7.616367433,
		pop: 3162954.5,
		country: 'Morocco',
		iso2: 'MA',
		iso3: 'MAR',
		province: 'Grand Casablanca',
		timezone: 'Africa/Casablanca',
	},
	{
		city: 'Moatize',
		city_ascii: 'Moatize',
		lat: -16.09954832,
		lng: 33.95001013,
		pop: 40536.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Tete',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Luangwa',
		city_ascii: 'Luangwa',
		lat: -15.61957762,
		lng: 30.41001949,
		pop: 3065,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Tete',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Manica',
		city_ascii: 'Manica',
		lat: -18.96960569,
		lng: 32.87999792,
		pop: 3713.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Manica',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Espungabera',
		city_ascii: 'Espungabera',
		lat: -20.45013548,
		lng: 32.77003048,
		pop: 393,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Manica',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Montepuez',
		city_ascii: 'Montepuez',
		lat: -13.11957518,
		lng: 39.0000378,
		pop: 49041,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Cabo Delgado',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Mocimboa',
		city_ascii: 'Mocimboa',
		lat: -11.31958169,
		lng: 40.34998124,
		pop: 27909,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Cabo Delgado',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Marrupa',
		city_ascii: 'Marrupa',
		lat: -13.19838174,
		lng: 37.49945756,
		pop: 8762,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Nassa',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Cuamba',
		city_ascii: 'Cuamba',
		lat: -14.78960244,
		lng: 36.53998124,
		pop: 68204.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Nassa',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Ligonha',
		city_ascii: 'Ligonha',
		lat: -15.17567706,
		lng: 37.74001135,
		pop: 3310.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Nampula',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Macia',
		city_ascii: 'Macia',
		lat: -25.01952065,
		lng: 33.09001094,
		pop: 13095,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Gaza',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Massangena',
		city_ascii: 'Massangena',
		lat: -21.53730426,
		lng: 32.95637569,
		pop: 634.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Gaza',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Mapai',
		city_ascii: 'Mapai',
		lat: -22.84265094,
		lng: 31.96305131,
		pop: 201,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Gaza',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Dondo',
		city_ascii: 'Dondo',
		lat: -19.61959186,
		lng: 34.7300142,
		pop: 75217.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Sofala',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Chiramba',
		city_ascii: 'Chiramba',
		lat: -16.89210976,
		lng: 34.65585852,
		pop: 556,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Sofala',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Mocuba',
		city_ascii: 'Mocuba',
		lat: -16.84958006,
		lng: 38.26003129,
		pop: 68984,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Zambezia',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Nicuadala',
		city_ascii: 'Nicuadala',
		lat: -17.60767332,
		lng: 36.81970577,
		pop: 6945,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Zambezia',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Maxixe',
		city_ascii: 'Maxixe',
		lat: -23.86602274,
		lng: 35.38855095,
		pop: 112881.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Inhambane',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Panda',
		city_ascii: 'Panda',
		lat: -24.06288654,
		lng: 34.73027258,
		pop: 602,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Inhambane',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Quissico',
		city_ascii: 'Quissico',
		lat: -24.72568846,
		lng: 34.765981,
		pop: 1210,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Inhambane',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Vilanculos',
		city_ascii: 'Vilanculos',
		lat: -21.99954995,
		lng: 35.31659338,
		pop: 177,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Inhambane',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Matola',
		city_ascii: 'Matola',
		lat: -25.96959186,
		lng: 32.46002356,
		pop: 503368,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Maputo',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Chimoio',
		city_ascii: 'Chimoio',
		lat: -19.12003579,
		lng: 33.47003943,
		pop: 242538.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Manica',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Lichinga',
		city_ascii: 'Lichinga',
		lat: -13.30002928,
		lng: 35.24000891,
		pop: 94333.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Nassa',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Angoche',
		city_ascii: 'Angoche',
		lat: -16.23003131,
		lng: 39.9100081,
		pop: 57835,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Nampula',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Mocambique',
		city_ascii: 'Mocambique',
		lat: -15.03989895,
		lng: 40.68218367,
		pop: 40700.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Nampula',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Inhambane',
		city_ascii: 'Inhambane',
		lat: -23.85803973,
		lng: 35.33981752,
		pop: 94830,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Inhambane',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Tete',
		city_ascii: 'Tete',
		lat: -16.17003497,
		lng: 33.58000688,
		pop: 122183,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Tete',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Pemba',
		city_ascii: 'Pemba',
		lat: -12.98304604,
		lng: 40.53234737,
		pop: 109690,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Cabo Delgado',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Nampula',
		city_ascii: 'Nampula',
		lat: -15.13604124,
		lng: 39.29304317,
		pop: 386185.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Nampula',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Xai-Xai',
		city_ascii: 'Xai-Xai',
		lat: -25.03998452,
		lng: 33.64000321,
		pop: 128085.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Gaza',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Quelimane',
		city_ascii: 'Quelimane',
		lat: -17.88000812,
		lng: 36.88998572,
		pop: 179032.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Zambezia',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Nacala',
		city_ascii: 'Nacala',
		lat: -14.51861123,
		lng: 40.71502356,
		pop: 212212.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Nampula',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Beira',
		city_ascii: 'Beira',
		lat: -19.82004474,
		lng: 34.87000565,
		pop: 507196.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Sofala',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Maputo',
		city_ascii: 'Maputo',
		lat: -25.95527749,
		lng: 32.58916296,
		pop: 1318806.5,
		country: 'Mozambique',
		iso2: 'MZ',
		iso3: 'MOZ',
		province: 'Maputo',
		timezone: 'Africa/Maputo',
	},
	{
		city: 'Loikaw',
		city_ascii: 'Loikaw',
		lat: 19.66500009,
		lng: 97.20600363,
		pop: 17293,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Kayah',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Pa-an',
		city_ascii: 'Pa-an',
		lat: 16.8499981,
		lng: 97.61670064,
		pop: 50000,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Kayin',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Hakha',
		city_ascii: 'Haka',
		lat: 22.6500031,
		lng: 93.6167046,
		pop: 20000,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Chin',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Taunggyi',
		city_ascii: 'Taunggyi',
		lat: 20.78199907,
		lng: 97.03800065,
		pop: 160115,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Shan',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Sagaing',
		city_ascii: 'Sagaing',
		lat: 21.87999903,
		lng: 95.9619966,
		pop: 78739,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Sagaing',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Myingyan',
		city_ascii: 'Myingyan',
		lat: 21.46182823,
		lng: 95.39142777,
		pop: 152762.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Mandalay',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Letpadan',
		city_ascii: 'Letpadan',
		lat: 17.78189781,
		lng: 95.74148393,
		pop: 107753.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Bago',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Taungoo',
		city_ascii: 'Taungoo',
		lat: 18.94828656,
		lng: 96.41792843,
		pop: 105590.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Bago',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Thongwa',
		city_ascii: 'Thongwa',
		lat: 16.75469952,
		lng: 96.51931759,
		pop: 35992.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Yangon',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Mudon',
		city_ascii: 'Mudon',
		lat: 16.26183555,
		lng: 97.72146643,
		pop: 120711.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Mon',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Ye',
		city_ascii: 'Ye',
		lat: 15.25326662,
		lng: 97.86786576,
		pop: 50798,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Mon',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Mawlamyine',
		city_ascii: 'Mawlamyine',
		lat: 16.50042564,
		lng: 97.67004838,
		pop: 400249,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Mon',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Kyaukphyu',
		city_ascii: 'Kyaukphyu',
		lat: 19.42901315,
		lng: 93.54940356,
		pop: 4261,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Rakhine',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Wakema',
		city_ascii: 'Wakema',
		lat: 16.61328697,
		lng: 95.18286169,
		pop: 45555,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Ayeyarwady',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Labutta',
		city_ascii: 'Labutta',
		lat: 16.16189333,
		lng: 94.70144405,
		pop: 1667,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Ayeyarwady',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Phyarpon',
		city_ascii: 'Phyarpon',
		lat: 16.29325482,
		lng: 95.68288285,
		pop: 63177,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Ayeyarwady',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Yandoon',
		city_ascii: 'Yandoon',
		lat: 17.04328656,
		lng: 95.64288529,
		pop: 36172,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Ayeyarwady',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Hinthada',
		city_ascii: 'Hinthada',
		lat: 17.64826255,
		lng: 95.46785722,
		pop: 157837.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Ayeyarwady',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Pathein',
		city_ascii: 'Pathein',
		lat: 16.77040916,
		lng: 94.74996822,
		pop: 216014.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Ayeyarwady',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Allanmyo',
		city_ascii: 'Allanmyo',
		lat: 19.37831199,
		lng: 95.22792354,
		pop: 48446.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Magway',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Yaynangyoung',
		city_ascii: 'Yaynangyoung',
		lat: 20.46145001,
		lng: 94.88096798,
		pop: 104507.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Magway',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Chauk',
		city_ascii: 'Chauk',
		lat: 20.90847699,
		lng: 94.8230387,
		pop: 85016.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Magway',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Pakokku',
		city_ascii: 'Pakokku',
		lat: 21.33204287,
		lng: 95.08664018,
		pop: 125355.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Magway',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Namtu',
		city_ascii: 'Namtu',
		lat: 23.08374473,
		lng: 97.39998734,
		pop: 48591,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Shan',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Dawei',
		city_ascii: 'Dawei',
		lat: 14.09796246,
		lng: 98.19497758,
		pop: 141497.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Tanintharyi',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Shwebo',
		city_ascii: 'Shwebo',
		lat: 22.57827171,
		lng: 95.6928564,
		pop: 81758.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Sagaing',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Bago',
		city_ascii: 'Bago',
		lat: 17.32001385,
		lng: 96.51497676,
		pop: 264347,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Bago',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Pyu',
		city_ascii: 'Pyu',
		lat: 18.477876,
		lng: 96.43787553,
		pop: 37652,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Bago',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Pyay',
		city_ascii: 'Pyay',
		lat: 18.81645998,
		lng: 95.21143876,
		pop: 124833.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Bago',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Magway',
		city_ascii: 'Magway',
		lat: 20.14454429,
		lng: 94.91957027,
		pop: 111463.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Magway',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Myitkyina',
		city_ascii: 'Myitkyina',
		lat: 25.35962648,
		lng: 97.39275264,
		pop: 114997,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Kachin',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Monywa',
		city_ascii: 'Monywa',
		lat: 22.1049931,
		lng: 95.14999548,
		pop: 204116.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Sagaing',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Myeik',
		city_ascii: 'Myeik',
		lat: 12.45408347,
		lng: 98.61148962,
		pop: 220009,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Tanintharyi',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Mandalay',
		city_ascii: 'Mandalay',
		lat: 21.96998842,
		lng: 96.08502885,
		pop: 1167000,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Mandalay',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Sittwe',
		city_ascii: 'Sittwe',
		lat: 20.13999676,
		lng: 92.88000484,
		pop: 178387.5,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Rakhine',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Naypyidaw',
		city_ascii: 'Naypyidaw',
		lat: 19.76655703,
		lng: 96.11861853,
		pop: 562412,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Mandalay',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Rangoon',
		city_ascii: 'Rangoon',
		lat: 16.7833541,
		lng: 96.16667761,
		pop: 3694910,
		country: 'Myanmar',
		iso2: 'MM',
		iso3: 'MMR',
		province: 'Yangon',
		timezone: 'Asia/Rangoon',
	},
	{
		city: 'Omaruru',
		city_ascii: 'Omaruru',
		lat: -21.43600193,
		lng: 15.95099754,
		pop: 11547,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Erongo',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Karibib',
		city_ascii: 'Karibib',
		lat: -21.93900292,
		lng: 15.85299646,
		pop: 6898,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Erongo',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Otavi',
		city_ascii: 'Otavi',
		lat: -19.63999801,
		lng: 17.34200155,
		pop: 4562,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Otjozondjupa',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Gobabis',
		city_ascii: 'Gobabis',
		lat: -22.455,
		lng: 18.96300054,
		pop: 16321,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Omaheke',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Karasburg',
		city_ascii: 'Karasburg',
		lat: -28.01959593,
		lng: 18.73998246,
		pop: 5071.5,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Karas',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Bethanie',
		city_ascii: 'Bethanie',
		lat: -26.49953367,
		lng: 17.15000199,
		pop: 8122,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Karas',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Oranjemund',
		city_ascii: 'Oranjemund',
		lat: -28.54948606,
		lng: 16.42999426,
		pop: 7223,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Karas',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Mariental',
		city_ascii: 'Mariental',
		lat: -24.61959674,
		lng: 17.95992672,
		pop: 12670,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Hardap',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Rehoboth',
		city_ascii: 'Rehoboth',
		lat: -23.31957273,
		lng: 17.0800321,
		pop: 23298,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Hardap',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Outjo',
		city_ascii: 'Outjo',
		lat: -20.10953611,
		lng: 16.1400378,
		pop: 6363.5,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Kunene',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Opuwo',
		city_ascii: 'Opuwo',
		lat: -18.05958372,
		lng: 13.82002437,
		pop: 4857,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Kunene',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Usakos',
		city_ascii: 'Usakos',
		lat: -21.99954995,
		lng: 15.5800203,
		pop: 5393.5,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Erongo',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Okahandja',
		city_ascii: 'Okahandja',
		lat: -21.97960285,
		lng: 16.91001664,
		pop: 19691,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Otjozondjupa',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Otjiwarongo',
		city_ascii: 'Otjiwarongo',
		lat: -20.45954059,
		lng: 16.64000728,
		pop: 23019.5,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Otjozondjupa',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Oshikango',
		city_ascii: 'Oshikango',
		lat: -17.39952065,
		lng: 15.88000199,
		pop: 7540.5,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Ohangwena',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Cuangar',
		city_ascii: 'Cuangar',
		lat: -17.60953367,
		lng: 18.61998979,
		pop: 425,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Kavango',
		timezone: 'Africa/Luanda',
	},
	{
		city: 'Katima Mulilo',
		city_ascii: 'Katima Mulilo',
		lat: -17.4996179,
		lng: 24.26000728,
		pop: 21748.5,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Caprivi',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Keetmanshoop',
		city_ascii: 'Keetmanshoop',
		lat: -26.57389606,
		lng: 18.12999385,
		pop: 16823.5,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Karas',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Maltahöhe',
		city_ascii: 'Maltahohe',
		lat: -24.83999673,
		lng: 16.93998897,
		pop: 2329,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Hardap',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Swakopmund',
		city_ascii: 'Swakopmund',
		lat: -22.6688631,
		lng: 14.53501949,
		pop: 27269,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Erongo',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Ongwediva',
		city_ascii: 'Ongwediva',
		lat: -17.78001422,
		lng: 15.77003454,
		pop: 17343,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Oshana',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Rundu',
		city_ascii: 'Rundu',
		lat: -17.92000568,
		lng: 19.74994665,
		pop: 43485,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Kavango',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Tsumeb',
		city_ascii: 'Tsumeb',
		lat: -19.24002846,
		lng: 17.71001949,
		pop: 13574.5,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Oshikoto',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Lüderitz',
		city_ascii: 'Luderitz',
		lat: -26.64800006,
		lng: 15.15942582,
		pop: 14216,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Karas',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Walvis Bay',
		city_ascii: 'Walvis Bay',
		lat: -22.95752765,
		lng: 14.50530554,
		pop: 49504.5,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Erongo',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Windhoek',
		city_ascii: 'Windhoek',
		lat: -22.57000608,
		lng: 17.0835461,
		pop: 265464,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Khomas',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Grootfontein',
		city_ascii: 'Grootfontein',
		lat: -19.56662352,
		lng: 18.11655798,
		pop: 19149.5,
		country: 'Namibia',
		iso2: 'NA',
		iso3: 'NAM',
		province: 'Otjozondjupa',
		timezone: 'Africa/Windhoek',
	},
	{
		city: 'Salyan',
		city_ascii: 'Sallyan',
		lat: 28.35000004,
		lng: 82.18330255,
		pop: 15000,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Rapti',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Baglung',
		city_ascii: 'Baglung',
		lat: 28.26669606,
		lng: 83.58329762,
		pop: 23296,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Dhawalagiri',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Jumla',
		city_ascii: 'Jumla',
		lat: 29.25000013,
		lng: 82.21670162,
		pop: 9073,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Karnali',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Bhairawa',
		city_ascii: 'Bhairawa',
		lat: 27.53330409,
		lng: 83.38329953,
		pop: 63367,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Lumbini',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Dandeldhura',
		city_ascii: 'Dandeldhura',
		lat: 29.30000416,
		lng: 80.60000455,
		pop: 19014,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Mahakali',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Dhangarhi',
		city_ascii: 'Dhangarhi',
		lat: 28.69499706,
		lng: 80.5930026,
		pop: 92294,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Achham',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Ramechhap',
		city_ascii: 'Ramechhap',
		lat: 27.32599808,
		lng: 86.08699853,
		pop: 15000,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Janakpur',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Bhimphedi',
		city_ascii: 'Bhimphedi',
		lat: 27.5509981,
		lng: 85.13000156,
		pop: 15000,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Narayani',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Rajbiraj',
		city_ascii: 'Rajbiraj',
		lat: 26.5332961,
		lng: 86.73329761,
		pop: 33061,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Sagarmatha',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Ilam',
		city_ascii: 'Ilam',
		lat: 26.90800207,
		lng: 87.92600453,
		pop: 17491,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Mechi',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Lalitpur',
		city_ascii: 'Lalitpur',
		lat: 27.66661745,
		lng: 85.3333337,
		pop: 191208.5,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Bhaktapur',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Hetauda',
		city_ascii: 'Hetauda',
		lat: 27.41668439,
		lng: 85.03335201,
		pop: 158554.5,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Narayani',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Nepalganj',
		city_ascii: 'Nepalganj',
		lat: 28.0503408,
		lng: 81.61666134,
		pop: 64400,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Banke',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Birganj',
		city_ascii: 'Birganj',
		lat: 27.00040489,
		lng: 84.86659216,
		pop: 133238,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Narayani',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Biratnagar',
		city_ascii: 'Biratnagar',
		lat: 26.48374392,
		lng: 87.28334387,
		pop: 182324,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Bhojpur',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Pokhara',
		city_ascii: 'Pokhara',
		lat: 28.26399603,
		lng: 83.97199855,
		pop: 200000,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Gorkha',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Kathmandu',
		city_ascii: 'Kathmandu',
		lat: 27.71669191,
		lng: 85.31664221,
		pop: 895000,
		country: 'Nepal',
		iso2: 'NP',
		iso3: 'NPL',
		province: 'Bhaktapur',
		timezone: 'Asia/Kathmandu',
	},
	{
		city: 'Assen',
		city_ascii: 'Assen',
		lat: 53.00000109,
		lng: 6.550002585,
		pop: 62237,
		country: 'Netherlands',
		iso2: 'NL',
		iso3: 'NLD',
		province: 'Drenthe',
		timezone: 'Europe/Amsterdam',
	},
	{
		city: 'Arnhem',
		city_ascii: 'Arnhem',
		lat: 51.98799603,
		lng: 5.922999562,
		pop: 141674,
		country: 'Netherlands',
		iso2: 'NL',
		iso3: 'NLD',
		province: 'Gelderland',
		timezone: 'Europe/Amsterdam',
	},
	{
		city: 'Maastricht',
		city_ascii: 'Maastricht',
		lat: 50.85299707,
		lng: 5.677002477,
		pop: 122378,
		country: 'Netherlands',
		iso2: 'NL',
		iso3: 'NLD',
		province: 'Limburg',
		timezone: 'Europe/Amsterdam',
	},
	{
		city: 'Zwolle',
		city_ascii: 'Zwolle',
		lat: 52.52400009,
		lng: 6.096996529,
		pop: 111805,
		country: 'Netherlands',
		iso2: 'NL',
		iso3: 'NLD',
		province: 'Overijssel',
		timezone: 'Europe/Amsterdam',
	},
	{
		city: 'Middelburg',
		city_ascii: 'Middelburg',
		lat: 51.50199618,
		lng: 3.609999541,
		pop: 46485,
		country: 'Netherlands',
		iso2: 'NL',
		iso3: 'NLD',
		province: 'Zeeland',
		timezone: 'Europe/Amsterdam',
	},
	{
		city: "'s-Hertogenbosch",
		city_ascii: "'s-Hertogenbosch",
		lat: 51.68333714,
		lng: 5.316660485,
		pop: 134520,
		country: 'Netherlands',
		iso2: 'NL',
		iso3: 'NLD',
		province: 'Noord-Brabant',
		timezone: 'Europe/Amsterdam',
	},
	{
		city: 'Eindhoven',
		city_ascii: 'Eindhoven',
		lat: 51.42997316,
		lng: 5.50001542,
		pop: 303836.5,
		country: 'Netherlands',
		iso2: 'NL',
		iso3: 'NLD',
		province: 'Noord-Brabant',
		timezone: 'Europe/Amsterdam',
	},
	{
		city: 'Leeuwarden',
		city_ascii: 'Leeuwarden',
		lat: 53.25037884,
		lng: 5.783357298,
		pop: 108601,
		country: 'Netherlands',
		iso2: 'NL',
		iso3: 'NLD',
		province: 'Friesland',
		timezone: 'Europe/Amsterdam',
	},
	{
		city: 'Groningen',
		city_ascii: 'Groningen',
		lat: 53.22040651,
		lng: 6.580001179,
		pop: 198941,
		country: 'Netherlands',
		iso2: 'NL',
		iso3: 'NLD',
		province: 'Groningen',
		timezone: 'Europe/Amsterdam',
	},
	{
		city: 'Utrecht',
		city_ascii: 'Utrecht',
		lat: 52.10034568,
		lng: 5.120038614,
		pop: 478224,
		country: 'Netherlands',
		iso2: 'NL',
		iso3: 'NLD',
		province: 'Utrecht',
		timezone: 'Europe/Amsterdam',
	},
	{
		city: 'Haarlem',
		city_ascii: 'Haarlem',
		lat: 52.38043194,
		lng: 4.629991006,
		pop: 248773.5,
		country: 'Netherlands',
		iso2: 'NL',
		iso3: 'NLD',
		province: 'Noord-Holland',
		timezone: 'Europe/Amsterdam',
	},
	{
		city: 'Rotterdam',
		city_ascii: 'Rotterdam',
		lat: 51.9199691,
		lng: 4.479974323,
		pop: 801599.5,
		country: 'Netherlands',
		iso2: 'NL',
		iso3: 'NLD',
		province: 'Zuid-Holland',
		timezone: 'Europe/Amsterdam',
	},
	{
		city: 'The Hague',
		city_ascii: 'The Hague',
		lat: 52.08003684,
		lng: 4.269961302,
		pop: 953862.5,
		country: 'Netherlands',
		iso2: 'NL',
		iso3: 'NLD',
		province: 'Zuid-Holland',
		timezone: 'Europe/Amsterdam',
	},
	{
		city: 'Amsterdam',
		city_ascii: 'Amsterdam',
		lat: 52.34996869,
		lng: 4.916640176,
		pop: 886318,
		country: 'Netherlands',
		iso2: 'NL',
		iso3: 'NLD',
		province: 'Noord-Holland',
		timezone: 'Europe/Amsterdam',
	},
	{
		city: 'Noumea',
		city_ascii: 'Noumea',
		lat: -22.26252776,
		lng: 166.4442852,
		pop: 89742.5,
		country: 'New Caledonia',
		iso2: 'NC',
		iso3: 'NCL',
		province: 'Sud',
		timezone: 'Pacific/Noumea',
	},
	{
		city: 'Greymouth',
		city_ascii: 'Greymouth',
		lat: -42.47274975,
		lng: 171.2087246,
		pop: 9419,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'West Coast',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Upper Hutt',
		city_ascii: 'Upper Hutt',
		lat: -41.13548786,
		lng: 175.0290474,
		pop: 34591,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Manawatu-Wanganui',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Masterton',
		city_ascii: 'Masterton',
		lat: -40.94392332,
		lng: 175.6456506,
		pop: 16720.5,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Manawatu-Wanganui',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Levin',
		city_ascii: 'Levin',
		lat: -40.61236733,
		lng: 175.2772493,
		pop: 18764,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Manawatu-Wanganui',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Waitakere',
		city_ascii: 'Waitakere',
		lat: -36.84959959,
		lng: 174.5500069,
		pop: 83400,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Auckland',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Takapuna',
		city_ascii: 'Takapuna',
		lat: -36.7912569,
		lng: 174.7758329,
		pop: 184815.5,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Auckland',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Whakatane',
		city_ascii: 'Whakatane',
		lat: -37.98291543,
		lng: 176.9999865,
		pop: 20665,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Bay of Plenty',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Ashburton',
		city_ascii: 'Ashburton',
		lat: -43.89790322,
		lng: 171.7300756,
		pop: 8895,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Canterbury',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Kaiapoi',
		city_ascii: 'Kaiapoi',
		lat: -43.3776249,
		lng: 172.6400459,
		pop: 7169,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Canterbury',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'New Plymouth',
		city_ascii: 'New Plymouth',
		lat: -39.0641931,
		lng: 174.0805265,
		pop: 46289.5,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Taranaki',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Westport',
		city_ascii: 'Westport',
		lat: -41.77443223,
		lng: 171.5666665,
		pop: 1899,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'West Coast',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Hokitika',
		city_ascii: 'Hokitika',
		lat: -42.72568188,
		lng: 170.9681006,
		pop: 2139,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'West Coast',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Oamaru',
		city_ascii: 'Oamaru',
		lat: -45.09731321,
		lng: 170.9710005,
		pop: 6628,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Otago',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Palmerston North',
		city_ascii: 'Palmerston North',
		lat: -40.35269326,
		lng: 175.6072033,
		pop: 66551.5,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Manawatu-Wanganui',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Wanganui',
		city_ascii: 'Wanganui',
		lat: -39.9311686,
		lng: 175.0288407,
		pop: 35866.5,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Manawatu-Wanganui',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Hastings',
		city_ascii: 'Hastings',
		lat: -39.63821491,
		lng: 176.8367924,
		pop: 39107,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Gisborne',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Gisborne',
		city_ascii: 'Gisborne',
		lat: -38.64478717,
		lng: 178.0152217,
		pop: 30857.5,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Gisborne',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Rotorua',
		city_ascii: 'Rotorua',
		lat: -38.13458576,
		lng: 176.2454073,
		pop: 51497.5,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Auckland',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Taupo',
		city_ascii: 'Taupo',
		lat: -38.69300128,
		lng: 176.0770455,
		pop: 17480.5,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Auckland',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Tauranga',
		city_ascii: 'Tauranga',
		lat: -37.69642129,
		lng: 176.1536299,
		pop: 84730,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Bay of Plenty',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Timaru',
		city_ascii: 'Timaru',
		lat: -44.38159463,
		lng: 171.2185823,
		pop: 23306,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Canterbury',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Nelson',
		city_ascii: 'Nelson',
		lat: -41.29258421,
		lng: 173.2474507,
		pop: 37133,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Nelson',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Whangarei',
		city_ascii: 'Whangarei',
		lat: -35.71995278,
		lng: 174.3100215,
		pop: 53299.5,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Northland',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Queenstown',
		city_ascii: 'Queenstown',
		lat: -45.02997882,
		lng: 168.6625109,
		pop: 5332,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Otago',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Invercargill',
		city_ascii: 'Invercargill',
		lat: -46.40942951,
		lng: 168.3650097,
		pop: 37135.5,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Southland',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Napier',
		city_ascii: 'Napier',
		lat: -39.4900069,
		lng: 176.9150305,
		pop: 56787,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Gisborne',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Manukau',
		city_ascii: 'Manukau',
		lat: -36.99997801,
		lng: 174.8849735,
		pop: 336141.5,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Auckland',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Hamilton',
		city_ascii: 'Hamilton',
		lat: -37.77000853,
		lng: 175.3000386,
		pop: 112145,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Auckland',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Blenheim',
		city_ascii: 'Blenheim',
		lat: -41.52099404,
		lng: 173.9592419,
		pop: 23056.5,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Marlborough',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Dunedin',
		city_ascii: 'Dunedin',
		lat: -45.87995278,
		lng: 170.4799711,
		pop: 92438.5,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Otago',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Wellington',
		city_ascii: 'Wellington',
		lat: -41.29997394,
		lng: 174.7832743,
		pop: 296300,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Manawatu-Wanganui',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Christchurch',
		city_ascii: 'Christchurch',
		lat: -43.53503131,
		lng: 172.6300207,
		pop: 295351.5,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Canterbury',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Auckland',
		city_ascii: 'Auckland',
		lat: -36.850013,
		lng: 174.7649808,
		pop: 759510,
		country: 'New Zealand',
		iso2: 'NZ',
		iso3: 'NZL',
		province: 'Auckland',
		timezone: 'Pacific/Auckland',
	},
	{
		city: 'Somoto',
		city_ascii: 'Somoto',
		lat: 13.47599712,
		lng: -86.58299659,
		pop: 20316,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Madriz',
		timezone: 'America/Managua',
	},
	{
		city: 'Ocotal',
		city_ascii: 'Ocotal',
		lat: 13.62999605,
		lng: -86.47299861,
		pop: 33928,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Nueva Segovia',
		timezone: 'America/Managua',
	},
	{
		city: 'San Carlos',
		city_ascii: 'San Carlos',
		lat: 11.1999961,
		lng: -84.83329749,
		pop: 13451,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Nicaragua',
		timezone: 'America/Managua',
	},
	{
		city: 'Jinotepe',
		city_ascii: 'Jinotepe',
		lat: 11.84599813,
		lng: -86.19499748,
		pop: 29507,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Carazo',
		timezone: 'America/Managua',
	},
	{
		city: 'Jinotega',
		city_ascii: 'Jinotega',
		lat: 13.09100402,
		lng: -86.00000362,
		pop: 51073,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Jinotega',
		timezone: 'America/Managua',
	},
	{
		city: 'Masaya',
		city_ascii: 'Masaya',
		lat: 11.96900098,
		lng: -86.09499852,
		pop: 130113,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Masaya',
		timezone: 'America/Managua',
	},
	{
		city: 'Esteli',
		city_ascii: 'Esteli',
		lat: 13.08998781,
		lng: -86.35998478,
		pop: 102130.5,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Estelí',
		timezone: 'America/Managua',
	},
	{
		city: 'Boaco',
		city_ascii: 'Boaco',
		lat: 12.46997398,
		lng: -85.66000167,
		pop: 21572,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Boaco',
		timezone: 'America/Managua',
	},
	{
		city: 'Juigalpa',
		city_ascii: 'Juigalpa',
		lat: 12.10999595,
		lng: -85.37996708,
		pop: 35451,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Chontales',
		timezone: 'America/Managua',
	},
	{
		city: 'Rivas',
		city_ascii: 'Rivas',
		lat: 11.44037274,
		lng: -85.8199919,
		pop: 31117,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Nicaragua',
		timezone: 'America/Managua',
	},
	{
		city: 'San Juan de Nicaragua',
		city_ascii: 'San Juan de Nicaragua',
		lat: 10.92040448,
		lng: -83.69999211,
		pop: 863,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Nicaragua',
		timezone: 'America/Managua',
	},
	{
		city: 'Granada',
		city_ascii: 'Granada',
		lat: 11.93372764,
		lng: -85.94998397,
		pop: 97314,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Granada',
		timezone: 'America/Managua',
	},
	{
		city: 'Chinandega',
		city_ascii: 'Chinandega',
		lat: 12.63037762,
		lng: -87.13004114,
		pop: 132705,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Chinandega',
		timezone: 'America/Managua',
	},
	{
		city: 'Matagalpa',
		city_ascii: 'Matagalpa',
		lat: 12.91707847,
		lng: -85.91665267,
		pop: 106514.5,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Matagalpa',
		timezone: 'America/Managua',
	},
	{
		city: 'Puerto Cabezas',
		city_ascii: 'Puerto Cabezas',
		lat: 14.03334108,
		lng: -83.38337061,
		pop: 38318,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Atlántico Norte',
		timezone: 'America/Managua',
	},
	{
		city: 'Leon',
		city_ascii: 'Leon',
		lat: 12.43555747,
		lng: -86.87943628,
		pop: 154489.5,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'León',
		timezone: 'America/Managua',
	},
	{
		city: 'Bluefields',
		city_ascii: 'Bluefields',
		lat: 11.99997683,
		lng: -83.76494938,
		pop: 40033,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Atlántico Sur',
		timezone: 'America/Managua',
	},
	{
		city: 'Managua',
		city_ascii: 'Managua',
		lat: 12.15301658,
		lng: -86.26849166,
		pop: 920000,
		country: 'Nicaragua',
		iso2: 'NI',
		iso3: 'NIC',
		province: 'Managua',
		timezone: 'America/Managua',
	},
	{
		city: 'Goure',
		city_ascii: 'Goure',
		lat: 13.98740074,
		lng: 10.2700085,
		pop: 13291.5,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Zinder',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Gaya',
		city_ascii: 'Gaya',
		lat: 11.88817486,
		lng: 3.446652383,
		pop: 29394.5,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Dosso',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Tillaberi',
		city_ascii: 'Tillaberi',
		lat: 14.21203819,
		lng: 1.453078978,
		pop: 13812,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Niamey',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Ayorou',
		city_ascii: 'Ayorou',
		lat: 14.73179974,
		lng: 0.919468138,
		pop: 14001,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Niamey',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Birni Nkonni',
		city_ascii: 'Birni Nkonni',
		lat: 13.79043601,
		lng: 5.259926716,
		pop: 56677.5,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Tahoua',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Madaoua',
		city_ascii: 'Madaoua',
		lat: 14.07618085,
		lng: 5.95859208,
		pop: 19954,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Tahoua',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Diffa',
		city_ascii: 'Diffa',
		lat: 13.31705406,
		lng: 12.60888383,
		pop: 29468,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Diffa',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Nguigmi',
		city_ascii: 'Nguigmi',
		lat: 14.25317263,
		lng: 13.11081702,
		pop: 15318,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Diffa',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Dosso',
		city_ascii: 'Dosso',
		lat: 13.05001609,
		lng: 3.200000772,
		pop: 46481.5,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Dosso',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Arlit',
		city_ascii: 'Arlit',
		lat: 18.81997398,
		lng: 7.32998124,
		pop: 90000,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Agadez',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Djado',
		city_ascii: 'Djado',
		lat: 21.01498212,
		lng: 12.30750688,
		pop: 10,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Agadez',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Maradi',
		city_ascii: 'Maradi',
		lat: 13.49164288,
		lng: 7.096403767,
		pop: 198021,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Maradi',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Tahoua',
		city_ascii: 'Tahoua',
		lat: 14.89998069,
		lng: 5.259926716,
		pop: 98190.5,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Tahoua',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Zinder',
		city_ascii: 'Zinder',
		lat: 13.79999615,
		lng: 8.983317015,
		pop: 210891,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Zinder',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Niamey',
		city_ascii: 'Niamey',
		lat: 13.51670595,
		lng: 2.116656045,
		pop: 828895.5,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Niamey',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Agadez',
		city_ascii: 'Agadez',
		lat: 16.99587343,
		lng: 7.98280961,
		pop: 103165.5,
		country: 'Niger',
		iso2: 'NE',
		iso3: 'NER',
		province: 'Agadez',
		timezone: 'Africa/Niamey',
	},
	{
		city: 'Umuahia',
		city_ascii: 'Umuahia',
		lat: 5.532003041,
		lng: 7.486002487,
		pop: 264662,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Abia',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Uyo',
		city_ascii: 'Uyo',
		lat: 5.007996056,
		lng: 7.849998524,
		pop: 495756,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Akwa Ibom',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Owerri',
		city_ascii: 'Owerri',
		lat: 5.492997053,
		lng: 7.026003588,
		pop: 215038,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Imo',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Dutse',
		city_ascii: 'Dutse',
		lat: 11.7991891,
		lng: 9.350334607,
		pop: 17129,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Jigawa',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Damaturu',
		city_ascii: 'Damaturu',
		lat: 11.74899608,
		lng: 11.96600457,
		pop: 255895,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Yobe',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Iwo',
		city_ascii: 'Iwo',
		lat: 7.629959329,
		lng: 4.179992634,
		pop: 208688.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Osun',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Iseyin',
		city_ascii: 'Iseyin',
		lat: 7.970016092,
		lng: 3.590002806,
		pop: 98071,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Oyo',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Biu',
		city_ascii: 'Biu',
		lat: 10.62042279,
		lng: 12.18999467,
		pop: 64619.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Borno',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Bama',
		city_ascii: 'Bama',
		lat: 11.5203937,
		lng: 13.69000647,
		pop: 86410,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Borno',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Aba',
		city_ascii: 'Aba',
		lat: 5.100397968,
		lng: 7.34998002,
		pop: 874385,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Abia',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Opobo',
		city_ascii: 'Opobo',
		lat: 4.570404479,
		lng: 7.559993041,
		pop: 34911,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Akwa Ibom',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Orlu',
		city_ascii: 'Orlu',
		lat: 5.783715433,
		lng: 7.033306843,
		pop: 9351,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Imo',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Oturkpo',
		city_ascii: 'Oturkpo',
		lat: 7.190399596,
		lng: 8.129984089,
		pop: 68220,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Benue',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Calabar',
		city_ascii: 'Calabar',
		lat: 4.960406513,
		lng: 8.330023558,
		pop: 354656.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Cross River',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Wukari',
		city_ascii: 'Wukari',
		lat: 7.870409769,
		lng: 9.780012572,
		pop: 74380.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Taraba',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Jalingo',
		city_ascii: 'Jalingo',
		lat: 8.900372741,
		lng: 11.36001949,
		pop: 103773,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Taraba',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Kontagora',
		city_ascii: 'Kontagora',
		lat: 10.4003587,
		lng: 5.469939737,
		pop: 62144,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Niger',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Bida',
		city_ascii: 'Bida',
		lat: 9.080413431,
		lng: 6.01001013,
		pop: 172752.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Niger',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Abeokuta',
		city_ascii: 'Abeokuta',
		lat: 7.160427265,
		lng: 3.350017455,
		pop: 441231,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Ogun',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Ijebu Ode',
		city_ascii: 'Ijebu Ode',
		lat: 6.820448017,
		lng: 3.920008503,
		pop: 204501,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Ogun',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Akure',
		city_ascii: 'Akure',
		lat: 7.250395934,
		lng: 5.199982054,
		pop: 420594,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Ondo',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Ikare',
		city_ascii: 'Ikare',
		lat: 7.530430521,
		lng: 5.759999551,
		pop: 899965.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Ondo',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Owo',
		city_ascii: 'Owo',
		lat: 7.200398986,
		lng: 5.589984089,
		pop: 200912,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Ondo',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Ondo',
		city_ascii: 'Ondo',
		lat: 7.0904057,
		lng: 4.840004027,
		pop: 256444,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Ondo',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Ado Ekiti',
		city_ascii: 'Ado Ekiti',
		lat: 7.630372741,
		lng: 5.219980834,
		pop: 446749,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Ekiti',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Ife',
		city_ascii: 'Ife',
		lat: 7.480433572,
		lng: 4.560021117,
		pop: 482365,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Osun',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Oshogbo',
		city_ascii: 'Oshogbo',
		lat: 7.770364196,
		lng: 4.560021117,
		pop: 408245,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Osun',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Oyo',
		city_ascii: 'Oyo',
		lat: 7.850436828,
		lng: 3.929982054,
		pop: 475016.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Oyo',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Awka',
		city_ascii: 'Awka',
		lat: 6.210433572,
		lng: 7.06999711,
		pop: 400828.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Anambra',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Onitsha',
		city_ascii: 'Onitsha',
		lat: 6.140412007,
		lng: 6.779988972,
		pop: 73374,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Anambra',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Azare',
		city_ascii: 'Azare',
		lat: 11.68040977,
		lng: 10.19001339,
		pop: 87912.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Bauchi',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Bauchi',
		city_ascii: 'Bauchi',
		lat: 10.3103642,
		lng: 9.84000891,
		pop: 244350.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Bauchi',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Gombe',
		city_ascii: 'Gombe',
		lat: 10.29044293,
		lng: 11.16995357,
		pop: 260312,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Gombe',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Kumo',
		city_ascii: 'Kumo',
		lat: 10.04570335,
		lng: 11.21305172,
		pop: 19249,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Gombe',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Sapele',
		city_ascii: 'Sapele',
		lat: 5.890427265,
		lng: 5.680004434,
		pop: 235424,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Delta',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Nsukka',
		city_ascii: 'Nsukka',
		lat: 6.867034321,
		lng: 7.383362995,
		pop: 111017,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Enugu',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Lokoja',
		city_ascii: 'Lokoja',
		lat: 7.800388203,
		lng: 6.739939737,
		pop: 52650.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Kogi',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Idah',
		city_ascii: 'Idah',
		lat: 7.110404479,
		lng: 6.739939737,
		pop: 71895,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Kogi',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Lafia',
		city_ascii: 'Lafia',
		lat: 8.490423603,
		lng: 8.5200378,
		pop: 79470.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Nassarawa',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Keffi',
		city_ascii: 'Keffi',
		lat: 8.849032205,
		lng: 7.873617308,
		pop: 77056.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Nassarawa',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Funtua',
		city_ascii: 'Funtua',
		lat: 11.5203937,
		lng: 7.320007689,
		pop: 158643,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Katsina',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Katsina',
		city_ascii: 'Katsina',
		lat: 12.99040733,
		lng: 7.599990599,
		pop: 432149,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Katsina',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Gusau',
		city_ascii: 'Gusau',
		lat: 12.1704057,
		lng: 6.659996296,
		pop: 185925,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Zamfara',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Nguru',
		city_ascii: 'Nguru',
		lat: 12.8803882,
		lng: 10.44999752,
		pop: 82481,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Yobe',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Gashua',
		city_ascii: 'Gashua',
		lat: 12.87049217,
		lng: 11.03998734,
		pop: 74825.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Yobe',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Potiskum',
		city_ascii: 'Potiskum',
		lat: 11.7103821,
		lng: 11.0799849,
		pop: 82733.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Yobe',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Birnin Kebbi',
		city_ascii: 'Birnin Kebbi',
		lat: 12.45041445,
		lng: 4.199939737,
		pop: 102340.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Kebbi',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Koko',
		city_ascii: 'Koko',
		lat: 11.42319033,
		lng: 4.516974649,
		pop: 25792,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Kebbi',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Mubi',
		city_ascii: 'Mubi',
		lat: 10.2703408,
		lng: 13.2700321,
		pop: 214710,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Adamawa',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Numan',
		city_ascii: 'Numan',
		lat: 9.460441914,
		lng: 12.04002966,
		pop: 45173,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Adamawa',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Ilorin',
		city_ascii: 'Ilorin',
		lat: 8.490010192,
		lng: 4.549995889,
		pop: 701742,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Kwara',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Minna',
		city_ascii: 'Minna',
		lat: 9.619992898,
		lng: 6.550028848,
		pop: 249038,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Niger',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Zaria',
		city_ascii: 'Zaria',
		lat: 11.0799813,
		lng: 7.710009724,
		pop: 754836.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Kaduna',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Jos',
		city_ascii: 'Jos',
		lat: 9.929973978,
		lng: 8.890041055,
		pop: 737068.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Plateau',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Yola',
		city_ascii: 'Yola',
		lat: 9.209992085,
		lng: 12.48000281,
		pop: 92253,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Adamawa',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Benin City',
		city_ascii: 'Benin City',
		lat: 6.340477314,
		lng: 5.620008096,
		pop: 929013,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Edo',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Maiduguri',
		city_ascii: 'Maiduguri',
		lat: 11.84996014,
		lng: 13.16001298,
		pop: 704230,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Borno',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Port Harcourt',
		city_ascii: 'Port Harcourt',
		lat: 4.810002257,
		lng: 7.010000772,
		pop: 1020000,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Rivers',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Makurdi',
		city_ascii: 'Makurdi',
		lat: 7.729979064,
		lng: 8.530011351,
		pop: 245367.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Benue',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Ibadan',
		city_ascii: 'Ibadan',
		lat: 7.380026264,
		lng: 3.929982054,
		pop: 2221285,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Oyo',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Ogbomosho',
		city_ascii: 'Ogbomosho',
		lat: 8.130006326,
		lng: 4.239988972,
		pop: 595063.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Oyo',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Warri',
		city_ascii: 'Warri',
		lat: 5.519958922,
		lng: 5.759999551,
		pop: 683064.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Delta',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Kaduna',
		city_ascii: 'Kaduna',
		lat: 10.52001548,
		lng: 7.440000365,
		pop: 1191296.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Kaduna',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Enugu',
		city_ascii: 'Enugu',
		lat: 6.450031351,
		lng: 7.499996703,
		pop: 688862,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Enugu',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Sokoto',
		city_ascii: 'Sokoto',
		lat: 13.06001548,
		lng: 5.240031289,
		pop: 648019.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Sokoto',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Abuja',
		city_ascii: 'Abuja',
		lat: 9.083333149,
		lng: 7.533328002,
		pop: 869067.5,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Federal Capital Territory',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Kano',
		city_ascii: 'Kano',
		lat: 11.99997683,
		lng: 8.5200378,
		pop: 3140000,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Kano',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Lagos',
		city_ascii: 'Lagos',
		lat: 6.443261653,
		lng: 3.391531071,
		pop: 4733768,
		country: 'Nigeria',
		iso2: 'NG',
		iso3: 'NGA',
		province: 'Lagos',
		timezone: 'Africa/Lagos',
	},
	{
		city: 'Sariwon',
		city_ascii: 'Sariwon',
		lat: 38.50700411,
		lng: 125.7620047,
		pop: 154942,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Hwanghae-bukto',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Sin-Ni',
		city_ascii: 'Sin-Ni',
		lat: 39.48800415,
		lng: 125.4639966,
		pop: 19463,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: "P'yongan-namdo",
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Changyon',
		city_ascii: 'Changyon',
		lat: 38.25173362,
		lng: 125.1020691,
		pop: 44176,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Hwanghae-namdo',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Anbyon',
		city_ascii: 'Anbyon',
		lat: 39.02817202,
		lng: 127.5185624,
		pop: 34993.5,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Kangwon-do',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Munchon',
		city_ascii: 'Munchon',
		lat: 39.38130292,
		lng: 127.2517053,
		pop: 73619,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Kangwon-do',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Kaesong',
		city_ascii: 'Kaesong',
		lat: 37.96399925,
		lng: 126.5644087,
		pop: 305333.5,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Kaesong',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Chosan',
		city_ascii: 'Chosan',
		lat: 40.82547833,
		lng: 125.8008378,
		pop: 7786,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Chagang-do',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Manpo',
		city_ascii: 'Manpo',
		lat: 41.14543296,
		lng: 126.2958463,
		pop: 186827,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Chagang-do',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Sunchon',
		city_ascii: 'Sunchon',
		lat: 39.42360008,
		lng: 125.9389689,
		pop: 400629,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: "P'yongan-namdo",
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Kimhyonggwon',
		city_ascii: 'Kimhyonggwon',
		lat: 40.81752016,
		lng: 128.1553194,
		pop: 3839,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Ryanggang',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Pyongsan',
		city_ascii: 'Pyongsan',
		lat: 38.33668968,
		lng: 126.3866418,
		pop: 66260,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Hwanghae-bukto',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Ongjin',
		city_ascii: 'Ongjin',
		lat: 37.93710166,
		lng: 125.3570922,
		pop: 66721,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Hwanghae-namdo',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Haeju',
		city_ascii: 'Haeju',
		lat: 38.039421,
		lng: 125.7143831,
		pop: 223313.5,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Hwanghae-namdo',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Kilchu',
		city_ascii: 'Kilchu',
		lat: 40.96043133,
		lng: 129.3204162,
		pop: 82408.5,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Hamgyong-bukto',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Musan',
		city_ascii: 'Musan',
		lat: 42.23043133,
		lng: 129.2303959,
		pop: 50942.5,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Hamgyong-bukto',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Sonbong',
		city_ascii: 'Sonbong',
		lat: 42.33768577,
		lng: 130.4027274,
		pop: 44097.5,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Hamgyong-bukto',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Kanggye',
		city_ascii: 'Kanggye',
		lat: 40.97322125,
		lng: 126.6032177,
		pop: 254522,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Chagang-do',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Hungnam',
		city_ascii: 'Hungnam',
		lat: 39.82313641,
		lng: 127.6231555,
		pop: 548702,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Hamgyong-namdo',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Taedong',
		city_ascii: 'Taedong',
		lat: 40.61709312,
		lng: 125.4501098,
		pop: 1884,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: "P'yongan-bukto",
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Chongju',
		city_ascii: 'Chongju',
		lat: 39.68128461,
		lng: 125.2163256,
		pop: 85417,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: "P'yongan-bukto",
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Hyeson',
		city_ascii: 'Hyeson',
		lat: 41.39273053,
		lng: 128.1927331,
		pop: 227461,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Ryanggang',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Nampo',
		city_ascii: 'Nampo',
		lat: 38.76692078,
		lng: 125.4524338,
		pop: 791000,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: "Namp'o-si",
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Chongjin',
		city_ascii: 'Chongjin',
		lat: 41.78461875,
		lng: 129.79,
		pop: 499807,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Hamgyong-bukto',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Kimchaek',
		city_ascii: 'Kimchaek',
		lat: 40.67230939,
		lng: 129.202749,
		pop: 187270,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Hamgyong-bukto',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Hamhung',
		city_ascii: 'Hamhung',
		lat: 39.91005617,
		lng: 127.5454341,
		pop: 595670.5,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Hamgyong-namdo',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Wonsan',
		city_ascii: 'Wonsan',
		lat: 39.16048952,
		lng: 127.4308158,
		pop: 322425,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: 'Kangwon-do',
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Sinuiju',
		city_ascii: 'Sinuiju',
		lat: 40.08585939,
		lng: 124.4212837,
		pop: 167234,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: "P'yongan-bukto",
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Pyongyang',
		city_ascii: 'Pyongyang',
		lat: 39.0194387,
		lng: 125.7546907,
		pop: 2899398.5,
		country: 'North Korea',
		iso2: 'KP',
		iso3: 'PRK',
		province: "P'yongyang",
		timezone: 'Asia/Pyongyang',
	},
	{
		city: 'Kyrenia',
		city_ascii: 'Kyrenia',
		lat: 35.3259991,
		lng: 33.33300361,
		pop: 26701,
		country: 'Northern Cyprus',
		iso2: '',
		iso3: 'CYN',
		province: '',
		timezone: 'Asia/Famagusta',
	},
	{
		city: 'Ammochostos',
		city_ascii: 'Ammochostos',
		lat: 35.12502525,
		lng: 33.95001013,
		pop: 33016.5,
		country: 'Northern Cyprus',
		iso2: -99,
		iso3: 'CYN',
		province: '',
		timezone: 'Asia/Famagusta',
	},
	{
		city: 'Capitol Hill',
		city_ascii: 'Capitol Hill',
		lat: 15.21125368,
		lng: 145.7505761,
		pop: 1764,
		country: 'Northern Mariana Islands',
		iso2: 'MP',
		iso3: 'MNP',
		province: '',
		timezone: 'Pacific/Saipan',
	},
	{
		city: 'Arendal',
		city_ascii: 'Arendal',
		lat: 58.46475606,
		lng: 8.766000553,
		pop: 30916,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Aust-Agder',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Vossavangen',
		city_ascii: 'Vossavangen',
		lat: 60.63000316,
		lng: 6.441003463,
		pop: 5571,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Hordaland',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Leikanger',
		city_ascii: 'Hermansverk',
		lat: 61.18329612,
		lng: 6.849999471,
		pop: 1965,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Sogn og Fjordane',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Bærum',
		city_ascii: 'Baerum',
		lat: 59.91348606,
		lng: 11.34723651,
		pop: 113659,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Akershus',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Hamar',
		city_ascii: 'Hamar',
		lat: 60.82000207,
		lng: 11.06900156,
		pop: 29479,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Hedmark',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Tønsberg',
		city_ascii: 'Tonsberg',
		lat: 59.26400109,
		lng: 10.42100147,
		pop: 38914,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Vestfold',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Finnsnes',
		city_ascii: 'Finnsnes',
		lat: 69.24061729,
		lng: 18.00860592,
		pop: 3611,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Troms',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Gjøvik',
		city_ascii: 'Gjovik',
		lat: 60.80002138,
		lng: 10.7000081,
		pop: 20157.5,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Oppland',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Rørvik',
		city_ascii: 'Rorvik',
		lat: 64.86801597,
		lng: 11.20530025,
		pop: 2615,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Nord-Trøndelag',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Harstad',
		city_ascii: 'Harstad',
		lat: 68.7879059,
		lng: 16.51557043,
		pop: 19203,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Troms',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Ålesund',
		city_ascii: 'Alesund',
		lat: 62.54541872,
		lng: 6.388023233,
		pop: 45377,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Møre og Romsdal',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Sandnes',
		city_ascii: 'Sandnes',
		lat: 58.84541201,
		lng: 5.690029662,
		pop: 46911,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Rogaland',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Drammen',
		city_ascii: 'Drammen',
		lat: 59.75724265,
		lng: 10.19073686,
		pop: 85437.5,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Buskerud',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Moss',
		city_ascii: 'Moss',
		lat: 59.43697797,
		lng: 10.66915727,
		pop: 35696.5,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Ãstfold',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Steinkjer',
		city_ascii: 'Steinkjer',
		lat: 64.01706016,
		lng: 11.50001094,
		pop: 11193.5,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Nord-Trøndelag',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Svolvær',
		city_ascii: 'Svolvaer',
		lat: 68.23328859,
		lng: 14.56669714,
		pop: 4197,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Nordland',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Mo i Rana',
		city_ascii: 'Mo i Rana',
		lat: 66.31660972,
		lng: 14.16666988,
		pop: 19131,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Nordland',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Narvik',
		city_ascii: 'Narvik',
		lat: 68.38315025,
		lng: 17.28999345,
		pop: 19236.5,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Nordland',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Bodø',
		city_ascii: 'Bodo',
		lat: 67.24677227,
		lng: 14.39901132,
		pop: 31383.5,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Nordland',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Haugesund',
		city_ascii: 'Haugesund',
		lat: 59.4119149,
		lng: 5.277496703,
		pop: 36219.5,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Rogaland',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Stavanger',
		city_ascii: 'Stavanger',
		lat: 58.97000389,
		lng: 5.680004434,
		pop: 136999,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Rogaland',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Skien',
		city_ascii: 'Skien',
		lat: 59.19998985,
		lng: 9.600023558,
		pop: 73330,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Telemark',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Namsos',
		city_ascii: 'Namsos',
		lat: 64.48331077,
		lng: 11.50001094,
		pop: 9035,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Nord-Trøndelag',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Alta',
		city_ascii: 'Alta',
		lat: 69.96664532,
		lng: 23.24167151,
		pop: 12077,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Finnmark',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Vadsø',
		city_ascii: 'Vadso',
		lat: 70.09659678,
		lng: 29.76574927,
		pop: 5139,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Finnmark',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Molde',
		city_ascii: 'Molde',
		lat: 62.74830039,
		lng: 7.183323526,
		pop: 16171.5,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Møre og Romsdal',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Lillehammer',
		city_ascii: 'Lillehammer',
		lat: 61.13328269,
		lng: 10.5000203,
		pop: 19319,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Oppland',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Kirkenes',
		city_ascii: 'Kirkenes',
		lat: 69.72500633,
		lng: 30.05164343,
		pop: 2728,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Finnmark',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Kristiansand',
		city_ascii: 'Kristiansand',
		lat: 58.16664207,
		lng: 8.000017862,
		pop: 58292,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Vest-Agder',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Hammerfest',
		city_ascii: 'Hammerfest',
		lat: 70.66127993,
		lng: 23.68800085,
		pop: 9967,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Finnmark',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Tromsø',
		city_ascii: 'Tromso',
		lat: 69.63507623,
		lng: 18.99202525,
		pop: 48900.5,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Troms',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Trondheim',
		city_ascii: 'Trondheim',
		lat: 63.41665753,
		lng: 10.41666622,
		pop: 144336,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Sør-Trøndelag',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Bergen',
		city_ascii: 'Bergen',
		lat: 60.39100242,
		lng: 5.324522256,
		pop: 200389.5,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Hordaland',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Oslo',
		city_ascii: 'Oslo',
		lat: 59.91669029,
		lng: 10.74997921,
		pop: 707500,
		country: 'Norway',
		iso2: 'NO',
		iso3: 'NOR',
		province: 'Oslo',
		timezone: 'Europe/Oslo',
	},
	{
		city: 'Alayat Samail',
		city_ascii: 'Alayat Samail',
		lat: 23.3031887,
		lng: 57.97820756,
		pop: 32862.5,
		country: 'Oman',
		iso2: 'OM',
		iso3: 'OMN',
		province: 'Ad Dakhliyah',
		timezone: 'Asia/Muscat',
	},
	{
		city: 'Dawwah',
		city_ascii: 'Dawwah',
		lat: 20.63301577,
		lng: 58.90802161,
		pop: 1485.5,
		country: 'Oman',
		iso2: 'OM',
		iso3: 'OMN',
		province: 'Ash Sharqiyah',
		timezone: 'Asia/Muscat',
	},
	{
		city: 'Mirbat',
		city_ascii: 'Mirbat',
		lat: 16.99243695,
		lng: 54.69179317,
		pop: 1120,
		country: 'Oman',
		iso2: 'OM',
		iso3: 'OMN',
		province: 'Dhofar',
		timezone: 'Asia/Muscat',
	},
	{
		city: 'Ibri',
		city_ascii: 'Ibri',
		lat: 23.22538983,
		lng: 56.51695308,
		pop: 101640,
		country: 'Oman',
		iso2: 'OM',
		iso3: 'OMN',
		province: 'Al Dhahira',
		timezone: 'Asia/Muscat',
	},
	{
		city: 'Salalah',
		city_ascii: 'Salalah',
		lat: 17.02545819,
		lng: 54.08521521,
		pop: 183508.5,
		country: 'Oman',
		iso2: 'OM',
		iso3: 'OMN',
		province: 'Dhofar',
		timezone: 'Asia/Muscat',
	},
	{
		city: 'Suhar',
		city_ascii: 'Suhar',
		lat: 24.36197335,
		lng: 56.73441896,
		pop: 129811.5,
		country: 'Oman',
		iso2: 'OM',
		iso3: 'OMN',
		province: 'Al Batnah',
		timezone: 'Asia/Muscat',
	},
	{
		city: 'As Sib',
		city_ascii: 'As Sib',
		lat: 23.68024579,
		lng: 58.18253617,
		pop: 237816,
		country: 'Oman',
		iso2: 'OM',
		iso3: 'OMN',
		province: 'Muscat',
		timezone: 'Asia/Muscat',
	},
	{
		city: 'Nizwa',
		city_ascii: 'Nizwa',
		lat: 22.92638999,
		lng: 57.53141313,
		pop: 70429.5,
		country: 'Oman',
		iso2: 'OM',
		iso3: 'OMN',
		province: 'Ad Dakhliyah',
		timezone: 'Asia/Muscat',
	},
	{
		city: 'Sur',
		city_ascii: 'Sur',
		lat: 22.57581708,
		lng: 59.53480505,
		pop: 68738,
		country: 'Oman',
		iso2: 'OM',
		iso3: 'OMN',
		province: 'Ash Sharqiyah',
		timezone: 'Asia/Muscat',
	},
	{
		city: 'Muscat',
		city_ascii: 'Muscat',
		lat: 23.61332481,
		lng: 58.59331213,
		pop: 660779,
		country: 'Oman',
		iso2: 'OM',
		iso3: 'OMN',
		province: 'Muscat',
		timezone: 'Asia/Muscat',
	},
	{
		city: 'Parachinar',
		city_ascii: 'Parachinar',
		lat: 33.89918276,
		lng: 70.10082678,
		pop: 55685,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'F.A.T.A.',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Sialkote',
		city_ascii: 'Sialkote',
		lat: 32.5200163,
		lng: 74.5600378,
		pop: 477396,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Sheikhu Pura',
		city_ascii: 'Sheikhu Pura',
		lat: 31.71998761,
		lng: 73.98999508,
		pop: 361303,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Gujrat',
		city_ascii: 'Gujrat',
		lat: 32.5799868,
		lng: 74.08001542,
		pop: 301506,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Sahiwal',
		city_ascii: 'Sahiwal',
		lat: 30.67173118,
		lng: 73.11180579,
		pop: 235695,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Chiniot',
		city_ascii: 'Chiniot',
		lat: 31.71998761,
		lng: 72.97997921,
		pop: 201781,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Rahim Yar Khan',
		city_ascii: 'Rahimyar Khan',
		lat: 28.4202407,
		lng: 70.29518184,
		pop: 353203,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Mansehra',
		city_ascii: 'Mansehra',
		lat: 34.3417914,
		lng: 73.19681352,
		pop: 66486,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Khyber-Pakhtunkhwa',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Kohat',
		city_ascii: 'Kohat',
		lat: 33.60268923,
		lng: 71.43268347,
		pop: 247227,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'N.W.F.P.',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Abbottabad',
		city_ascii: 'Abbottabad',
		lat: 34.1495034,
		lng: 73.19950069,
		pop: 1032323.5,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'N.W.F.P.',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Mardan',
		city_ascii: 'Mardan',
		lat: 34.20004295,
		lng: 72.0399849,
		pop: 300424,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'N.W.F.P.',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Gwadar',
		city_ascii: 'Gwadar',
		lat: 25.13896812,
		lng: 62.32858801,
		pop: 37632.5,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Baluchistan',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Zhob',
		city_ascii: 'Zhob',
		lat: 31.34897666,
		lng: 69.4385933,
		pop: 69446.5,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Baluchistan',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Gilgit',
		city_ascii: 'Gilgit',
		lat: 35.91709576,
		lng: 74.30000199,
		pop: 124196.5,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Northern Areas',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Kasur',
		city_ascii: 'Kasur',
		lat: 31.12537274,
		lng: 74.45503129,
		pop: 290643,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Kundian',
		city_ascii: 'Kundian',
		lat: 32.45219098,
		lng: 71.47180253,
		pop: 35406,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Okara',
		city_ascii: 'Okara',
		lat: 30.81040489,
		lng: 73.45002803,
		pop: 223648,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Jhang',
		city_ascii: 'Jhang',
		lat: 31.2803762,
		lng: 72.32498043,
		pop: 341210,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Sargodha',
		city_ascii: 'Sargodha',
		lat: 32.08536582,
		lng: 72.6749849,
		pop: 542603,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Dera Ghazi Khan',
		city_ascii: 'Dera Ghazi Khan',
		lat: 30.06039899,
		lng: 70.63505774,
		pop: 236093,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Sadiqabad',
		city_ascii: 'Sadiqabad',
		lat: 28.3006356,
		lng: 70.13023067,
		pop: 189876,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Nawabshah',
		city_ascii: 'Nawabshah',
		lat: 26.24543805,
		lng: 68.40000037,
		pop: 229504,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Sind',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Bannu',
		city_ascii: 'Bannu',
		lat: 32.98897992,
		lng: 70.59857418,
		pop: 586209.5,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'N.W.F.P.',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Dera Ismail Khan',
		city_ascii: 'Dera Ismail Khan',
		lat: 31.82899904,
		lng: 70.89855587,
		pop: 66676.5,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Khyber-Pakhtunkhwa',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Chaman',
		city_ascii: 'Chaman',
		lat: 30.92504905,
		lng: 66.44632117,
		pop: 88568,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Baluchistan',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Turbat',
		city_ascii: 'Turbat',
		lat: 25.99178428,
		lng: 63.07179846,
		pop: 111742.5,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Baluchistan',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Faisalabad',
		city_ascii: 'Faisalabad',
		lat: 31.40998069,
		lng: 73.10999711,
		pop: 2561797.5,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Rawalpindi',
		city_ascii: 'Rawalpindi',
		lat: 33.59997622,
		lng: 73.04002722,
		pop: 1800550.5,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Bahawalpur',
		city_ascii: 'Bahawalpur',
		lat: 29.38997479,
		lng: 71.67499426,
		pop: 552607,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Mirput Khas',
		city_ascii: 'Mirput Khas',
		lat: 25.53178652,
		lng: 69.01179765,
		pop: 286046,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Sind',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Sukkur',
		city_ascii: 'Sukkur',
		lat: 27.71356549,
		lng: 68.8485518,
		pop: 417767,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Sind',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Saidu',
		city_ascii: 'Saidu',
		lat: 34.75003522,
		lng: 72.34999182,
		pop: 1860310,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'N.W.F.P.',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Gujranwala',
		city_ascii: 'Gujranwala',
		lat: 32.16042584,
		lng: 74.18502193,
		pop: 1448735.5,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Quetta',
		city_ascii: 'Quetta',
		lat: 30.22000165,
		lng: 67.02499385,
		pop: 750837.5,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Baluchistan',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Larkana',
		city_ascii: 'Larkana',
		lat: 27.56176597,
		lng: 68.20678218,
		pop: 364033,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Sind',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Islamabad',
		city_ascii: 'Islamabad',
		lat: 33.69999595,
		lng: 73.16663448,
		pop: 690800,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'F.C.T.',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Multan',
		city_ascii: 'Multan',
		lat: 30.19997703,
		lng: 71.45500769,
		pop: 1479615,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Hyderabad',
		city_ascii: 'Hyderabad',
		lat: 25.379987,
		lng: 68.37498897,
		pop: 1422665,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Sind',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Peshawar',
		city_ascii: 'Peshawar',
		lat: 34.00501609,
		lng: 71.53500281,
		pop: 1260886.5,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'N.W.F.P.',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Lahore',
		city_ascii: 'Lahore',
		lat: 31.55997154,
		lng: 74.35002478,
		pop: 6443944,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Punjab',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Karachi',
		city_ascii: 'Karachi',
		lat: 24.86999229,
		lng: 66.99000891,
		pop: 11877109.5,
		country: 'Pakistan',
		iso2: 'PK',
		iso3: 'PAK',
		province: 'Sind',
		timezone: 'Asia/Karachi',
	},
	{
		city: 'Koror',
		city_ascii: 'Koror',
		lat: 7.345226355,
		lng: 134.4695009,
		pop: 11200,
		country: 'Palau',
		iso2: 'PW',
		iso3: 'PLW',
		province: '',
		timezone: 'Pacific/Palau',
	},
	{
		city: 'Melekeok',
		city_ascii: 'Melekeok',
		lat: 7.487396173,
		lng: 134.6265485,
		pop: 7026,
		country: 'Palau',
		iso2: 'PW',
		iso3: 'PLW',
		province: '',
		timezone: 'Pacific/Palau',
	},
	{
		city: 'Ramallah',
		city_ascii: 'Ramallah',
		lat: 31.90294475,
		lng: 35.20620938,
		pop: 24599,
		country: 'Palestine',
		iso2: 'PS',
		iso3: 'PSE',
		province: '',
		timezone: 'Asia/Hebron',
	},
	{
		city: 'Al Khalil',
		city_ascii: 'Al Khalil',
		lat: 31.54059287,
		lng: 35.09557328,
		pop: 220395.5,
		country: 'Palestine',
		iso2: 'PS',
		iso3: 'PSE',
		province: '',
		timezone: 'Asia/Hebron',
	},
	{
		city: 'Nablus',
		city_ascii: 'Nablus',
		lat: 32.22148155,
		lng: 35.25442663,
		pop: 173153.5,
		country: 'Palestine',
		iso2: 'PS',
		iso3: 'PSE',
		province: '',
		timezone: 'Asia/Hebron',
	},
	{
		city: 'Gaza',
		city_ascii: 'Gaza',
		lat: 31.52999921,
		lng: 34.44501868,
		pop: 477460.5,
		country: 'Palestine',
		iso2: 'PS',
		iso3: 'PSE',
		province: '',
		timezone: 'Asia/Gaza',
	},
	{
		city: 'El Porvenir',
		city_ascii: 'El Porvenir',
		lat: 9.541686355,
		lng: -78.97196299,
		pop: 10,
		country: 'Panama',
		iso2: 'PA',
		iso3: 'PAN',
		province: 'Kuna Yala',
		timezone: 'America/Panama',
	},
	{
		city: 'Penonome',
		city_ascii: 'Penonome',
		lat: 8.509983133,
		lng: -80.35998926,
		pop: 20580,
		country: 'Panama',
		iso2: 'PA',
		iso3: 'PAN',
		province: 'Coclé',
		timezone: 'America/Panama',
	},
	{
		city: 'Chitre',
		city_ascii: 'Chitre',
		lat: 7.970016092,
		lng: -80.42003727,
		pop: 44024,
		country: 'Panama',
		iso2: 'PA',
		iso3: 'PAN',
		province: 'Herrera',
		timezone: 'America/Panama',
	},
	{
		city: 'Jaque',
		city_ascii: 'Jaque',
		lat: 7.518958353,
		lng: -78.16601465,
		pop: 955,
		country: 'Panama',
		iso2: 'PA',
		iso3: 'PAN',
		province: 'Darién',
		timezone: 'America/Panama',
	},
	{
		city: 'Bocas del Toro',
		city_ascii: 'Bocas del Toro',
		lat: 9.335350084,
		lng: -82.2474707,
		pop: 6484,
		country: 'Panama',
		iso2: 'PA',
		iso3: 'PAN',
		province: 'Bocas del Toro',
		timezone: 'America/Panama',
	},
	{
		city: 'Almirante',
		city_ascii: 'Almirante',
		lat: 9.30001243,
		lng: -82.3999681,
		pop: 7442.5,
		country: 'Panama',
		iso2: 'PA',
		iso3: 'PAN',
		province: 'Bocas del Toro',
		timezone: 'America/Panama',
	},
	{
		city: 'Las Tablas',
		city_ascii: 'Las Tablas',
		lat: 7.760390644,
		lng: -80.28001998,
		pop: 9964,
		country: 'Panama',
		iso2: 'PA',
		iso3: 'PAN',
		province: 'Los Santos',
		timezone: 'America/Panama',
	},
	{
		city: 'Santiago',
		city_ascii: 'Santiago',
		lat: 8.100369892,
		lng: -80.98333622,
		pop: 45655,
		country: 'Panama',
		iso2: 'PA',
		iso3: 'PAN',
		province: 'Veraguas',
		timezone: 'America/Panama',
	},
	{
		city: 'La Palma',
		city_ascii: 'La Palma',
		lat: 8.398181172,
		lng: -78.14022811,
		pop: 1845,
		country: 'Panama',
		iso2: 'PA',
		iso3: 'PAN',
		province: 'Darién',
		timezone: 'America/Panama',
	},
	{
		city: 'Colon',
		city_ascii: 'Colon',
		lat: 9.365021382,
		lng: -79.8749801,
		pop: 170488,
		country: 'Panama',
		iso2: 'PA',
		iso3: 'PAN',
		province: 'Colón',
		timezone: 'America/Panama',
	},
	{
		city: 'Balboa',
		city_ascii: 'Balboa',
		lat: 8.949982116,
		lng: -79.56665267,
		pop: 62882,
		country: 'Panama',
		iso2: 'PA',
		iso3: 'PAN',
		province: 'Panama',
		timezone: 'America/Panama',
	},
	{
		city: 'Puerto Armuelles',
		city_ascii: 'Puerto Armuelles',
		lat: 8.280023009,
		lng: -82.87001693,
		pop: 22971,
		country: 'Panama',
		iso2: 'PA',
		iso3: 'PAN',
		province: 'Chiriquí',
		timezone: 'America/Panama',
	},
	{
		city: 'David',
		city_ascii: 'David',
		lat: 8.433321146,
		lng: -82.43332524,
		pop: 96448,
		country: 'Panama',
		iso2: 'PA',
		iso3: 'PAN',
		province: 'Chiriquí',
		timezone: 'America/Panama',
	},
	{
		city: 'Panama City',
		city_ascii: 'Panama City',
		lat: 8.96801719,
		lng: -79.53303715,
		pop: 844584,
		country: 'Panama',
		iso2: 'PA',
		iso3: 'PAN',
		province: 'Panama',
		timezone: 'America/Panama',
	},
	{
		city: 'Wabag',
		city_ascii: 'Wabag',
		lat: -5.490000005,
		lng: 143.7180037,
		pop: 3958,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'Enga',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Vanimo',
		city_ascii: 'Vanimo',
		lat: -2.690000936,
		lng: 141.3039966,
		pop: 11204,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'Sandaun',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Kundiawa',
		city_ascii: 'Kundiawa',
		lat: -6.022997883,
		lng: 144.9600127,
		pop: 9383,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'Chimbu',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Kerema',
		city_ascii: 'Kerema',
		lat: -7.927001897,
		lng: 145.8379987,
		pop: 5646,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'Gulf',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Arawa',
		city_ascii: 'Arawa',
		lat: -6.228000033,
		lng: 155.5659907,
		pop: 40266,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'North Solomons',
		timezone: 'Pacific/Bougainville',
	},
	{
		city: 'Lorengau',
		city_ascii: 'Lorengau',
		lat: -2.032001997,
		lng: 147.2799966,
		pop: 5806,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'Manus',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Kimbe',
		city_ascii: 'Kimbe',
		lat: -5.55000289,
		lng: 150.1430137,
		pop: 18847,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'West New Britain',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Daru',
		city_ascii: 'Daru',
		lat: -9.109151958,
		lng: 143.2337226,
		pop: 15214,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: '',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Sohano',
		city_ascii: 'Sohano',
		lat: -5.429742201,
		lng: 154.6711375,
		pop: 2338,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'North Solomons',
		timezone: 'Pacific/Bougainville',
	},
	{
		city: 'Kieta',
		city_ascii: 'Kieta',
		lat: -6.216275616,
		lng: 155.6333321,
		pop: 5284.5,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'North Solomons',
		timezone: 'Pacific/Bougainville',
	},
	{
		city: 'Mendi',
		city_ascii: 'Mendi',
		lat: -6.144393698,
		lng: 143.6452266,
		pop: 21685.5,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'Southern Highlands',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Abau',
		city_ascii: 'Abau',
		lat: -10.04260537,
		lng: 148.5650297,
		pop: 230,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'Central',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Alotau',
		city_ascii: 'Alotau',
		lat: -10.30207273,
		lng: 150.4590743,
		pop: 11624,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'Milne Bay',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Popondetta',
		city_ascii: 'Popondetta',
		lat: -8.769194724,
		lng: 148.2484082,
		pop: 25192,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'Northern',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Hoskins',
		city_ascii: 'Hoskins',
		lat: -5.474615459,
		lng: 150.4099816,
		pop: 871,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'West New Britain',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Wewak',
		city_ascii: 'Wewak',
		lat: -3.553492412,
		lng: 143.6367,
		pop: 21686.5,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'East Sepik',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Madang',
		city_ascii: 'Madang',
		lat: -5.224811586,
		lng: 145.785251,
		pop: 44721,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'Madang',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Kavieng',
		city_ascii: 'Kavieng',
		lat: -2.581284323,
		lng: 150.8129765,
		pop: 17109,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'New Ireland',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Goroka',
		city_ascii: 'Goroka',
		lat: -6.083312155,
		lng: 145.3854821,
		pop: 29101,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'Eastern Highlands',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Mt. Hagen',
		city_ascii: 'Mt. Hagen',
		lat: -5.86322223,
		lng: 144.2168196,
		pop: 46343.5,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'Western Highlands',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Rabaul',
		city_ascii: 'Rabaul',
		lat: -4.205490385,
		lng: 152.1434307,
		pop: 5894,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'East New Britain',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Lae',
		city_ascii: 'Lae',
		lat: -6.732988262,
		lng: 146.9900354,
		pop: 103653.5,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'Morobe',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Port Moresby',
		city_ascii: 'Port Moresby',
		lat: -9.464707826,
		lng: 147.1925036,
		pop: 267434.5,
		country: 'Papua New Guinea',
		iso2: 'PG',
		iso3: 'PNG',
		province: 'Central',
		timezone: 'Pacific/Port_Moresby',
	},
	{
		city: 'Mariscal Estigarribia',
		city_ascii: 'Mariscal Jose F. Estigarribia',
		lat: -22.03000205,
		lng: -60.60999555,
		pop: 2250,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Boquerón',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Caacupe',
		city_ascii: 'Caacupe',
		lat: -25.38700191,
		lng: -57.14000047,
		pop: 21696,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Cordillera',
		timezone: 'America/Asuncion',
	},
	{
		city: 'General Eugenio Alejandrino Garay',
		city_ascii: 'General Eugenio Alejandrino Garay',
		lat: -20.51995034,
		lng: -62.209986,
		pop: 972,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Boquerón',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Arroyos y Esteros',
		city_ascii: 'Arroyos y Esteros',
		lat: -25.04995807,
		lng: -57.08998845,
		pop: 2755,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Cordillera',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Villa Hayes',
		city_ascii: 'Villa Hayes',
		lat: -25.09000731,
		lng: -57.52998743,
		pop: 15643,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Presidente Hayes',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Fortin Falcon',
		city_ascii: 'Fortin Falcon',
		lat: -23.05028685,
		lng: -59.84994918,
		pop: -99,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Presidente Hayes',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Puerto Pinasco',
		city_ascii: 'Puerto Pinasco',
		lat: -22.64002765,
		lng: -57.7899974,
		pop: 473.5,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Presidente Hayes',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Pozo Colorado',
		city_ascii: 'Pozo Colorado',
		lat: -23.43000527,
		lng: -58.85998377,
		pop: 2135,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Presidente Hayes',
		timezone: 'America/Asuncion',
	},
	{
		city: 'San Pedro',
		city_ascii: 'San Pedro',
		lat: -24.08996499,
		lng: -57.07998906,
		pop: 7351,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'San Pedro',
		timezone: 'America/Asuncion',
	},
	{
		city: 'San Lorenzo',
		city_ascii: 'San Lorenzo',
		lat: -25.34001788,
		lng: -57.52003972,
		pop: 385532,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Asunción',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Ypacarai',
		city_ascii: 'Ypacarai',
		lat: -25.40998777,
		lng: -57.27997685,
		pop: 23805.5,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Asunción',
		timezone: 'America/Asuncion',
	},
	{
		city: 'San Juan Bautista',
		city_ascii: 'San Juan Bautista',
		lat: -26.67998777,
		lng: -57.15001062,
		pop: 7882,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Misiones',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Paraguari',
		city_ascii: 'Paraguari',
		lat: -25.62000079,
		lng: -57.16001001,
		pop: 14480,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Paraguarí',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Nacunday',
		city_ascii: 'Nacunday',
		lat: -26.02002806,
		lng: -54.76994918,
		pop: 1185,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Alto Paraná',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Coronel Oviedo',
		city_ascii: 'Coronel Oviedo',
		lat: -25.44998533,
		lng: -56.4399506,
		pop: 69693.5,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Caaguazú',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Caazapa',
		city_ascii: 'Caazapa',
		lat: -26.20001707,
		lng: -56.38000594,
		pop: 5504,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Caazapá',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Ype Jhu',
		city_ascii: 'Ype Jhu',
		lat: -23.91002765,
		lng: -55.45998458,
		pop: 598,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Canindeyú',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Encarnacion',
		city_ascii: 'Encarnacion',
		lat: -27.34718482,
		lng: -55.87391878,
		pop: 251813.5,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Itapúa',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Coronel Bogado',
		city_ascii: 'Coronel Bogado',
		lat: -27.17003538,
		lng: -56.25003972,
		pop: 14297,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Itapúa',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Fuerte Olimpo',
		city_ascii: 'Fuerte Olimpo',
		lat: -21.06958087,
		lng: -57.89999068,
		pop: 2475,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Alto Paraguay',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Capitan Pablo Lagerenza',
		city_ascii: 'Capitan Pablo Lagerenza',
		lat: -19.91611123,
		lng: -60.78327722,
		pop: 1200,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Alto Paraguay',
		timezone: 'America/Asuncion',
	},
	{
		city: 'La Victoria',
		city_ascii: 'La Victoria',
		lat: -22.28960976,
		lng: -57.93998824,
		pop: 5000,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Alto Paraguay',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Horqueta',
		city_ascii: 'Horqueta',
		lat: -23.33962319,
		lng: -57.05001673,
		pop: 13351,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Concepción',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Belen',
		city_ascii: 'Belen',
		lat: -23.46948606,
		lng: -57.23997929,
		pop: 6490,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Concepción',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Rosario',
		city_ascii: 'Rosario',
		lat: -24.41960895,
		lng: -57.10001367,
		pop: 3639,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'San Pedro',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Ita',
		city_ascii: 'Ita',
		lat: -25.50961994,
		lng: -57.36002364,
		pop: 29774.5,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Asunción',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Pilar',
		city_ascii: 'Pilar',
		lat: -26.86948525,
		lng: -58.29999211,
		pop: 28435,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Ñeembucú',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Pedro Juan Caballero',
		city_ascii: 'Pedro Juan Caballero',
		lat: -22.54458128,
		lng: -55.75999211,
		pop: 66029,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Amambay',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Bella Vista',
		city_ascii: 'Bella Vista',
		lat: -22.12961953,
		lng: -56.5199974,
		pop: 11252.5,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Amambay',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Abai',
		city_ascii: 'Abai',
		lat: -26.0295882,
		lng: -55.94000696,
		pop: 2788.5,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Caazapá',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Ygatimi',
		city_ascii: 'Ygatimi',
		lat: -24.0796297,
		lng: -55.49998214,
		pop: 2809,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Canindeyú',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Hohenau',
		city_ascii: 'Hohenau',
		lat: -27.07954995,
		lng: -55.74996688,
		pop: 5306,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Itapúa',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Concepcion',
		city_ascii: 'Concepcion',
		lat: -23.40638914,
		lng: -57.43441187,
		pop: 53620.5,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Concepción',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Villarrica',
		city_ascii: 'Villarrica',
		lat: -25.7500187,
		lng: -56.43331018,
		pop: 41157,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Guairá',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Filadelfia',
		city_ascii: 'Filadelfia',
		lat: -22.33999428,
		lng: -60.02998987,
		pop: 9259,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Boquerón',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Ciudad del Este',
		city_ascii: 'Ciudad del Este',
		lat: -25.51669961,
		lng: -54.61605676,
		pop: 320872,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Alto Paraná',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Asuncion',
		city_ascii: 'Asuncion',
		lat: -25.29640298,
		lng: -57.64150517,
		pop: 940846.5,
		country: 'Paraguay',
		iso2: 'PY',
		iso3: 'PRY',
		province: 'Asunción',
		timezone: 'America/Asuncion',
	},
	{
		city: 'Ferrenafe',
		city_ascii: 'Ferrenafe',
		lat: -6.629997133,
		lng: -79.80002344,
		pop: 42270.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Lambayeque',
		timezone: 'America/Lima',
	},
	{
		city: 'Motupe',
		city_ascii: 'Motupe',
		lat: -6.150026429,
		lng: -79.71000309,
		pop: 9048,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Lambayeque',
		timezone: 'America/Lima',
	},
	{
		city: 'Mollendo',
		city_ascii: 'Mollendo',
		lat: -17.02000893,
		lng: -72.01998153,
		pop: 38993,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Arequipa',
		timezone: 'America/Lima',
	},
	{
		city: 'Urubamba',
		city_ascii: 'Urubamba',
		lat: -13.30421507,
		lng: -72.11661646,
		pop: 5985,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Cusco',
		timezone: 'America/Lima',
	},
	{
		city: 'Santo Tomas',
		city_ascii: 'Santo Tomas',
		lat: -14.46001015,
		lng: -72.0800037,
		pop: 3695.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Cusco',
		timezone: 'America/Lima',
	},
	{
		city: 'Putina',
		city_ascii: 'Putina',
		lat: -15.47002602,
		lng: -69.42998458,
		pop: 8118,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Callao',
		timezone: 'America/Lima',
	},
	{
		city: 'Casma',
		city_ascii: 'Casma',
		lat: -9.440006491,
		lng: -78.20999129,
		pop: 27421,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Ancash',
		timezone: 'America/Lima',
	},
	{
		city: 'Tournavista',
		city_ascii: 'Tournavista',
		lat: -8.932182191,
		lng: -74.70521814,
		pop: 511,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Huánuco',
		timezone: 'America/Lima',
	},
	{
		city: 'Huamachuco',
		city_ascii: 'Huamachuco',
		lat: -7.810028464,
		lng: -78.04994938,
		pop: 26301.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'La Libertad',
		timezone: 'America/Lima',
	},
	{
		city: 'Otuzco',
		city_ascii: 'Otuzco',
		lat: -7.899997133,
		lng: -78.56999516,
		pop: 8754.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'La Libertad',
		timezone: 'America/Lima',
	},
	{
		city: 'Lamas',
		city_ascii: 'Lamas',
		lat: -6.43000934,
		lng: -76.52001693,
		pop: 13098,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'San Martín',
		timezone: 'America/Lima',
	},
	{
		city: 'Nauta',
		city_ascii: 'Nauta',
		lat: -4.570019512,
		lng: -73.78002914,
		pop: 2308.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Loreto',
		timezone: 'America/Lima',
	},
	{
		city: 'Puquio',
		city_ascii: 'Puquio',
		lat: -14.6999955,
		lng: -74.12998194,
		pop: 9027.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Ayacucho',
		timezone: 'America/Lima',
	},
	{
		city: 'Chosica',
		city_ascii: 'Chosica',
		lat: -11.93003538,
		lng: -76.71000533,
		pop: 44572.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Lima',
		timezone: 'America/Lima',
	},
	{
		city: 'Satipo',
		city_ascii: 'Satipo',
		lat: -11.25999876,
		lng: -74.69002527,
		pop: 15532,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Junín',
		timezone: 'America/Lima',
	},
	{
		city: 'Tarma',
		city_ascii: 'Tarma',
		lat: -11.41001544,
		lng: -75.72998763,
		pop: 25906,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Junín',
		timezone: 'America/Lima',
	},
	{
		city: 'La Oroya',
		city_ascii: 'La Oroya',
		lat: -11.52003457,
		lng: -75.94000065,
		pop: 33345,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Junín',
		timezone: 'America/Lima',
	},
	{
		city: 'Huaura',
		city_ascii: 'Huaura',
		lat: -11.06001097,
		lng: -77.59997685,
		pop: 30561.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Lima',
		timezone: 'America/Lima',
	},
	{
		city: 'Huacho',
		city_ascii: 'Huacho',
		lat: -11.11003375,
		lng: -77.61994979,
		pop: 67509.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Lima',
		timezone: 'America/Lima',
	},
	{
		city: 'Pimentel',
		city_ascii: 'Pimentel',
		lat: -6.82962319,
		lng: -79.92998967,
		pop: 14422.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Lambayeque',
		timezone: 'America/Lima',
	},
	{
		city: 'Olmos',
		city_ascii: 'Olmos',
		lat: -5.979597556,
		lng: -79.74997481,
		pop: 7579.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Lambayeque',
		timezone: 'America/Lima',
	},
	{
		city: 'Sechura',
		city_ascii: 'Sechura',
		lat: -5.55962319,
		lng: -80.81998702,
		pop: 15811,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Piura',
		timezone: 'America/Lima',
	},
	{
		city: 'Chulucanas',
		city_ascii: 'Chulucanas',
		lat: -5.089574362,
		lng: -80.17000086,
		pop: 60435,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Piura',
		timezone: 'America/Lima',
	},
	{
		city: 'Sullana',
		city_ascii: 'Sullana',
		lat: -4.889586569,
		lng: -80.67999557,
		pop: 114496.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Piura',
		timezone: 'America/Lima',
	},
	{
		city: 'Abancay',
		city_ascii: 'Abancay',
		lat: -13.63959511,
		lng: -72.89000594,
		pop: 53981,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Apurímac',
		timezone: 'America/Lima',
	},
	{
		city: 'Camana',
		city_ascii: 'Camana',
		lat: -16.61961994,
		lng: -72.71999048,
		pop: 18695,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Arequipa',
		timezone: 'America/Lima',
	},
	{
		city: 'Sicuani',
		city_ascii: 'Sicuani',
		lat: -14.28958128,
		lng: -71.22997807,
		pop: 20924.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Cusco',
		timezone: 'America/Lima',
	},
	{
		city: 'Puno',
		city_ascii: 'Puno',
		lat: -15.83294961,
		lng: -70.03330693,
		pop: 111350,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Callao',
		timezone: 'America/Lima',
	},
	{
		city: 'Ayaviri',
		city_ascii: 'Ayaviri',
		lat: -14.87957111,
		lng: -70.59999068,
		pop: 12524.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Callao',
		timezone: 'America/Lima',
	},
	{
		city: 'Ilave',
		city_ascii: 'Ilave',
		lat: -16.07960122,
		lng: -69.66999577,
		pop: 16033,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Callao',
		timezone: 'America/Lima',
	},
	{
		city: 'Desaguadero',
		city_ascii: 'Desaguadero',
		lat: -16.56458453,
		lng: -69.04499516,
		pop: 5120,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Callao',
		timezone: 'America/Lima',
	},
	{
		city: 'Huarmey',
		city_ascii: 'Huarmey',
		lat: -10.06958047,
		lng: -78.15999435,
		pop: 9184,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Ancash',
		timezone: 'America/Lima',
	},
	{
		city: 'Cajabamba',
		city_ascii: 'Cajabamba',
		lat: -7.619600811,
		lng: -78.04000167,
		pop: 12036.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Cajamarca',
		timezone: 'America/Lima',
	},
	{
		city: 'Jaen',
		city_ascii: 'Jaen',
		lat: -5.709588197,
		lng: -78.80998051,
		pop: 49171,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Cajamarca',
		timezone: 'America/Lima',
	},
	{
		city: 'Chota',
		city_ascii: 'Chota',
		lat: -6.549588604,
		lng: -78.65004195,
		pop: 13452,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Cajamarca',
		timezone: 'America/Lima',
	},
	{
		city: 'Tingo Maria',
		city_ascii: 'Tingo Maria',
		lat: -9.289628073,
		lng: -75.9899976,
		pop: 36138.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Huánuco',
		timezone: 'America/Lima',
	},
	{
		city: 'Moyobamba',
		city_ascii: 'Moyobamba',
		lat: -6.049619121,
		lng: -76.96665633,
		pop: 46005,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'San Martín',
		timezone: 'America/Lima',
	},
	{
		city: 'Juanjui',
		city_ascii: 'Juanjui',
		lat: -7.169602438,
		lng: -76.73997766,
		pop: 27352.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'San Martín',
		timezone: 'America/Lima',
	},
	{
		city: 'Tocache',
		city_ascii: 'Tocache',
		lat: -8.179618308,
		lng: -76.52001693,
		pop: 15822.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'San Martín',
		timezone: 'America/Lima',
	},
	{
		city: 'Sechura',
		city_ascii: 'Sechura',
		lat: -5.235250225,
		lng: -78.50064539,
		pop: 8602,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Amazonas',
		timezone: 'America/Lima',
	},
	{
		city: 'Chachapoyas',
		city_ascii: 'Chachapoyas',
		lat: -6.229608135,
		lng: -77.87001205,
		pop: 23128.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Amazonas',
		timezone: 'America/Lima',
	},
	{
		city: 'Caballococha',
		city_ascii: 'Caballococha',
		lat: -3.916260967,
		lng: -70.50834253,
		pop: 3195,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Loreto',
		timezone: 'America/Lima',
	},
	{
		city: 'Puca Urco',
		city_ascii: 'Puca Urco',
		lat: -2.332791729,
		lng: -71.91668034,
		pop: 10,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Loreto',
		timezone: 'America/Lima',
	},
	{
		city: 'Andoas',
		city_ascii: 'Andoas',
		lat: -2.902059307,
		lng: -76.40247888,
		pop: 10,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Loreto',
		timezone: 'America/Lima',
	},
	{
		city: 'Soldado Bartra',
		city_ascii: 'Soldado Bartra',
		lat: -2.516139711,
		lng: -75.76660038,
		pop: 10,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Loreto',
		timezone: 'America/Lima',
	},
	{
		city: 'Nuevo Rocafuerte',
		city_ascii: 'Nuevo Rocafuerte',
		lat: -0.932928854,
		lng: -75.39998194,
		pop: 40,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Loreto',
		timezone: 'America/Guayaquil',
	},
	{
		city: 'Requena',
		city_ascii: 'Requena',
		lat: -5.069627259,
		lng: -73.90999536,
		pop: 17097.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Loreto',
		timezone: 'America/Lima',
	},
	{
		city: 'Huanta',
		city_ascii: 'Huanta',
		lat: -12.94961139,
		lng: -74.25000045,
		pop: 15534.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Ayacucho',
		timezone: 'America/Lima',
	},
	{
		city: 'Coracora',
		city_ascii: 'Coracora',
		lat: -15.01961424,
		lng: -73.78997685,
		pop: 6433.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Ayacucho',
		timezone: 'America/Lima',
	},
	{
		city: 'Chincha Alta',
		city_ascii: 'Chincha Alta',
		lat: -13.41960854,
		lng: -76.13998845,
		pop: 138608,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Ica',
		timezone: 'America/Lima',
	},
	{
		city: 'Santiago',
		city_ascii: 'Santiago',
		lat: -14.18958738,
		lng: -75.73996118,
		pop: 10449,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Ica',
		timezone: 'America/Lima',
	},
	{
		city: 'San Ramon',
		city_ascii: 'San Ramon',
		lat: -11.12961912,
		lng: -75.34001144,
		pop: 10599,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Junín',
		timezone: 'America/Lima',
	},
	{
		city: 'Junin',
		city_ascii: 'Junin',
		lat: -11.1496179,
		lng: -76.01002222,
		pop: 11495,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Junín',
		timezone: 'America/Lima',
	},
	{
		city: 'Jauja',
		city_ascii: 'Jauja',
		lat: -11.79960407,
		lng: -75.50002751,
		pop: 21057,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Junín',
		timezone: 'America/Lima',
	},
	{
		city: 'Pativilca',
		city_ascii: 'Pativilca',
		lat: -10.69961953,
		lng: -77.79999048,
		pop: 22744,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Lima',
		timezone: 'America/Lima',
	},
	{
		city: 'Chancay',
		city_ascii: 'Chancay',
		lat: -11.55961871,
		lng: -77.26997115,
		pop: 18601,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Lima',
		timezone: 'America/Lima',
	},
	{
		city: 'Chilca',
		city_ascii: 'Chilca',
		lat: -12.5196118,
		lng: -76.73997766,
		pop: 6556,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Lima',
		timezone: 'America/Lima',
	},
	{
		city: 'Chiclayo',
		city_ascii: 'Chiclayo',
		lat: -6.762908916,
		lng: -79.83658452,
		pop: 587083.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Lambayeque',
		timezone: 'America/Lima',
	},
	{
		city: 'Juliaca',
		city_ascii: 'Juliaca',
		lat: -15.49999835,
		lng: -70.13996708,
		pop: 234428,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Callao',
		timezone: 'America/Lima',
	},
	{
		city: 'Cerro de Pasco',
		city_ascii: 'Cerro de Pasco',
		lat: -10.69000771,
		lng: -76.26998051,
		pop: 108071,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Pasco',
		timezone: 'America/Lima',
	},
	{
		city: 'Tarapoto',
		city_ascii: 'Tarapoto',
		lat: -6.50995278,
		lng: -76.47996769,
		pop: 936,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'San Martín',
		timezone: 'America/Lima',
	},
	{
		city: 'Ayacucho',
		city_ascii: 'Ayacucho',
		lat: -13.17502399,
		lng: -74.2199506,
		pop: 153173.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Ayacucho',
		timezone: 'America/Lima',
	},
	{
		city: 'Callao',
		city_ascii: 'Callao',
		lat: -12.07002684,
		lng: -77.13496647,
		pop: 786231.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Lima',
		timezone: 'America/Lima',
	},
	{
		city: 'Paita',
		city_ascii: 'Paita',
		lat: -5.089987774,
		lng: -81.12002039,
		pop: 47891.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Piura',
		timezone: 'America/Lima',
	},
	{
		city: 'Talara',
		city_ascii: 'Talara',
		lat: -4.579993063,
		lng: -81.27998478,
		pop: 74760.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Piura',
		timezone: 'America/Lima',
	},
	{
		city: 'Tumbes',
		city_ascii: 'Tumbes',
		lat: -3.570028871,
		lng: -80.45998316,
		pop: 105783,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Tumbes',
		timezone: 'America/Lima',
	},
	{
		city: 'Puerto Maldonado',
		city_ascii: 'Puerto Maldonado',
		lat: -12.60002033,
		lng: -69.18333297,
		pop: 52349,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Madre de Dios',
		timezone: 'America/Lima',
	},
	{
		city: 'Ilo',
		city_ascii: 'Ilo',
		lat: -17.64002277,
		lng: -71.34002303,
		pop: 53192,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Moquegua',
		timezone: 'America/Lima',
	},
	{
		city: 'Moquegua',
		city_ascii: 'Moquegua',
		lat: -17.18997272,
		lng: -70.93999577,
		pop: 38610,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Moquegua',
		timezone: 'America/Lima',
	},
	{
		city: 'Huaraz',
		city_ascii: 'Huaraz',
		lat: -9.530026836,
		lng: -77.53000696,
		pop: 74986.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Ancash',
		timezone: 'America/Lima',
	},
	{
		city: 'Cajamarca',
		city_ascii: 'Cajamarca',
		lat: -7.150017071,
		lng: -78.53002344,
		pop: 138832.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Cajamarca',
		timezone: 'America/Lima',
	},
	{
		city: 'Huanuco',
		city_ascii: 'Huanuco',
		lat: -9.920028871,
		lng: -76.24000818,
		pop: 153052,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Huánuco',
		timezone: 'America/Lima',
	},
	{
		city: 'Pacasmayo',
		city_ascii: 'Pacasmayo',
		lat: -7.400647767,
		lng: -79.57060592,
		pop: 34223.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'La Libertad',
		timezone: 'America/Lima',
	},
	{
		city: 'Salaverry',
		city_ascii: 'Salaverry',
		lat: -8.220029278,
		lng: -78.98999536,
		pop: 7228,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'La Libertad',
		timezone: 'America/Lima',
	},
	{
		city: 'Gueppi',
		city_ascii: 'Gueppi',
		lat: -0.116596254,
		lng: -75.22996647,
		pop: 10,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Loreto',
		timezone: 'America/Lima',
	},
	{
		city: 'Contamana',
		city_ascii: 'Contamana',
		lat: -7.340031312,
		lng: -75.01997929,
		pop: 16403,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Loreto',
		timezone: 'America/Lima',
	},
	{
		city: 'Huancavelica',
		city_ascii: 'Huancavelica',
		lat: -12.79003457,
		lng: -74.99000696,
		pop: 42982,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Huancavelica',
		timezone: 'America/Lima',
	},
	{
		city: 'Pisco',
		city_ascii: 'Pisco',
		lat: -13.71003009,
		lng: -76.21998356,
		pop: 71538,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Ica',
		timezone: 'America/Lima',
	},
	{
		city: 'Nasca',
		city_ascii: 'Nasca',
		lat: -14.83001341,
		lng: -74.94001001,
		pop: 23387,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Ica',
		timezone: 'America/Lima',
	},
	{
		city: 'Piura',
		city_ascii: 'Piura',
		lat: -5.210032126,
		lng: -80.62997278,
		pop: 361199,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Piura',
		timezone: 'America/Lima',
	},
	{
		city: 'Arequipa',
		city_ascii: 'Arequipa',
		lat: -16.41999388,
		lng: -71.53001144,
		pop: 775785,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Arequipa',
		timezone: 'America/Lima',
	},
	{
		city: 'Chimbote',
		city_ascii: 'Chimbote',
		lat: -9.070003236,
		lng: -78.56999516,
		pop: 333406,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Ancash',
		timezone: 'America/Lima',
	},
	{
		city: 'Pucallpa',
		city_ascii: 'Pucallpa',
		lat: -8.368909079,
		lng: -74.53499597,
		pop: 304917,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Ucayali',
		timezone: 'America/Lima',
	},
	{
		city: 'Iquitos',
		city_ascii: 'Iquitos',
		lat: -3.750017884,
		lng: -73.25000981,
		pop: 448174.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Loreto',
		timezone: 'America/Lima',
	},
	{
		city: 'Huancayo',
		city_ascii: 'Huancayo',
		lat: -12.08000039,
		lng: -75.20001998,
		pop: 394695,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Junín',
		timezone: 'America/Lima',
	},
	{
		city: 'Cusco',
		city_ascii: 'Cusco',
		lat: -13.52502846,
		lng: -71.97215499,
		pop: 336661,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Cusco',
		timezone: 'America/Lima',
	},
	{
		city: 'Tacna',
		city_ascii: 'Tacna',
		lat: -18.00000079,
		lng: -70.25001205,
		pop: 261042.5,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Tacna',
		timezone: 'America/Lima',
	},
	{
		city: 'Trujillo',
		city_ascii: 'Trujillo',
		lat: -8.120035381,
		lng: -79.01996769,
		pop: 521046,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'La Libertad',
		timezone: 'America/Lima',
	},
	{
		city: 'Ica',
		city_ascii: 'Ica',
		lat: -14.06799274,
		lng: -75.72549178,
		pop: 263132,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Ica',
		timezone: 'America/Lima',
	},
	{
		city: 'Lima',
		city_ascii: 'Lima',
		lat: -12.04801268,
		lng: -77.05006209,
		pop: 7385117,
		country: 'Peru',
		iso2: 'PE',
		iso3: 'PER',
		province: 'Lima',
		timezone: 'America/Lima',
	},
	{
		city: 'San Carlos',
		city_ascii: 'San Carlos',
		lat: 10.55037539,
		lng: 123.3800036,
		pop: 6353,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Negros Occidental',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Cadiz',
		city_ascii: 'Cadiz',
		lat: 10.95874839,
		lng: 123.3085868,
		pop: 206105,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Negros Occidental',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Pagadian',
		city_ascii: 'Pagadian',
		lat: 7.852968973,
		lng: 123.5070243,
		pop: 159590,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Zamboanga Del Sur',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Ozamis',
		city_ascii: 'Ozamis',
		lat: 8.146206888,
		lng: 123.8444197,
		pop: 95444,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Misamis Occidental',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Tarlac',
		city_ascii: 'Tarlac',
		lat: 15.48379519,
		lng: 120.5833785,
		pop: 183930,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Tarlac',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Cabanatuan',
		city_ascii: 'Cabanatuan',
		lat: 15.50208864,
		lng: 120.9617016,
		pop: 220250,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Nueva Ecija',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Olongapo',
		city_ascii: 'Olongapo',
		lat: 14.82957155,
		lng: 120.2827767,
		pop: 265829,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Zambales',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Dagupan',
		city_ascii: 'Dagupan',
		lat: 16.04789512,
		lng: 120.3408093,
		pop: 163676,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Pangasinan',
		timezone: 'Asia/Manila',
	},
	{
		city: 'San Pablo',
		city_ascii: 'San Pablo',
		lat: 14.06956626,
		lng: 121.3226098,
		pop: 224203.5,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Laguna',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Quezon City',
		city_ascii: 'Quezon City',
		lat: 14.6504352,
		lng: 121.0299662,
		pop: 2761720,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Metropolitan Manila',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Pasay City',
		city_ascii: 'Pasay City',
		lat: 14.5504413,
		lng: 120.9999939,
		pop: 403064,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Metropolitan Manila',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Iligan',
		city_ascii: 'Iligan',
		lat: 8.171244119,
		lng: 124.2153531,
		pop: 464599,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Lanao del Norte',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Ormac',
		city_ascii: 'Ormac',
		lat: 11.0642975,
		lng: 124.6075256,
		pop: 129964.5,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Leyte',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Tacloban',
		city_ascii: 'Tacloban',
		lat: 11.25043601,
		lng: 125.0000081,
		pop: 234548,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Leyte',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Butuan',
		city_ascii: 'Butuan',
		lat: 8.949542866,
		lng: 125.5435925,
		pop: 190557,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Agusan del Norte',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Tagum',
		city_ascii: 'Tagum',
		lat: 7.382144998,
		lng: 125.8016646,
		pop: 6726,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Davao del Norte',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Surigao',
		city_ascii: 'Surigao',
		lat: 9.784298115,
		lng: 125.4888155,
		pop: 64983,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Dinagat Islands',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Gingoog',
		city_ascii: 'Gingoog',
		lat: 8.830377013,
		lng: 125.1299743,
		pop: 218,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Misamis Oriental',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Legazpi',
		city_ascii: 'Legazpi',
		lat: 13.16998293,
		lng: 123.7500069,
		pop: 320081,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Albay',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Tuguegarao',
		city_ascii: 'Tuguegarao',
		lat: 17.61309674,
		lng: 121.7268746,
		pop: 115105,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Cagayan',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Vigan',
		city_ascii: 'Vigan',
		lat: 17.57472699,
		lng: 120.3869047,
		pop: 48545,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Ilocos Sur',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Bacolod',
		city_ascii: 'Bacolod',
		lat: 10.63168825,
		lng: 122.9816817,
		pop: 730587,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Negros Occidental',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Roxas',
		city_ascii: 'Roxas',
		lat: 11.58527346,
		lng: 122.7511014,
		pop: 91880.5,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Capiz',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Puerto Princesa',
		city_ascii: 'Puerto Princesa',
		lat: 9.754267783,
		lng: 118.744384,
		pop: 147882,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Palawan',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Naga',
		city_ascii: 'Naga',
		lat: 13.61915448,
		lng: 123.1813594,
		pop: 458283,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Camarines Sur',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Angeles',
		city_ascii: 'Angeles',
		lat: 15.14505617,
		lng: 120.5450862,
		pop: 314493,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Pampanga',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Batangas',
		city_ascii: 'Batangas',
		lat: 13.78167686,
		lng: 121.021698,
		pop: 330939,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Batangas',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Cotabato',
		city_ascii: 'Cotabato',
		lat: 7.216909606,
		lng: 124.2484261,
		pop: 229476,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Shariff Kabunsuan',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Calbayog',
		city_ascii: 'Calbayog',
		lat: 12.06718203,
		lng: 124.6041666,
		pop: 55015,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Samar',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Cagayan de Oro',
		city_ascii: 'Cagayan de Oro',
		lat: 8.450839456,
		lng: 124.6852986,
		pop: 861824.5,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Misamis Oriental',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Zamboanga',
		city_ascii: 'Zamboanga',
		lat: 6.919976826,
		lng: 122.0800313,
		pop: 615311.5,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Zamboanga del Sur',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Laoag',
		city_ascii: 'Laoag',
		lat: 18.1988491,
		lng: 120.5936104,
		pop: 154576.5,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Ilocos Norte',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Baguio City',
		city_ascii: 'Baguio City',
		lat: 16.42999066,
		lng: 120.5699426,
		pop: 360269,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Benguet',
		timezone: 'Asia/Manila',
	},
	{
		city: 'General Santos',
		city_ascii: 'General Santos',
		lat: 6.110827249,
		lng: 125.1747261,
		pop: 744308,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'South Cotabato',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Cebu',
		city_ascii: 'Cebu',
		lat: 10.31997601,
		lng: 123.9000752,
		pop: 806817,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Cebu',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Iloilo',
		city_ascii: 'Iloilo',
		lat: 10.70504295,
		lng: 122.5450158,
		pop: 387681,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Iloilo',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Davao',
		city_ascii: 'Davao',
		lat: 7.110016906,
		lng: 125.6299955,
		pop: 1307252,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Davao Del Sur',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Manila',
		city_ascii: 'Manila',
		lat: 14.60415895,
		lng: 120.9822172,
		pop: 7088787.5,
		country: 'Philippines',
		iso2: 'PH',
		iso3: 'PHL',
		province: 'Metropolitan Manila',
		timezone: 'Asia/Manila',
	},
	{
		city: 'Olsztyn',
		city_ascii: 'Olsztyn',
		lat: 53.80003522,
		lng: 20.48003129,
		pop: 179236.5,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Warmian-Masurian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Elblag',
		city_ascii: 'Elblag',
		lat: 54.18995974,
		lng: 19.40268103,
		pop: 124332,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Warmian-Masurian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Inowroclaw',
		city_ascii: 'Inowroclaw',
		lat: 52.77994244,
		lng: 18.24998653,
		pop: 78302,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Kuyavian-Pomeranian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Bytom',
		city_ascii: 'Bytom',
		lat: 50.35003908,
		lng: 18.90999792,
		pop: 425716.5,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Silesian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Opole',
		city_ascii: 'Opole',
		lat: 50.68497988,
		lng: 17.93134965,
		pop: 129544,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Opole',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Koszalin',
		city_ascii: 'Koszalin',
		lat: 54.2,
		lng: 16.1833333,
		pop: 107450,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'West Pomeranian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Elk',
		city_ascii: 'Elk',
		lat: 53.83370241,
		lng: 22.34999467,
		pop: 51312.5,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Warmian-Masurian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Gdynia',
		city_ascii: 'Gdynia',
		lat: 54.52037884,
		lng: 18.53002112,
		pop: 284197,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Pomeranian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Wroclaw',
		city_ascii: 'Wroclaw',
		lat: 51.11043194,
		lng: 17.03000932,
		pop: 622471,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Lower Silesian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Szczecin',
		city_ascii: 'Szczecin',
		lat: 53.42039431,
		lng: 14.53000688,
		pop: 390241.5,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'West Pomeranian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Zielona Gora',
		city_ascii: 'Zielona Gora',
		lat: 51.95040651,
		lng: 15.50002519,
		pop: 113865.5,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Lubusz',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Poznan',
		city_ascii: 'Poznan',
		lat: 52.4057534,
		lng: 16.89993974,
		pop: 597174.5,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Greater Poland',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Grudziadz',
		city_ascii: 'Grudziadz',
		lat: 53.48039064,
		lng: 18.75000769,
		pop: 100964.5,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Kuyavian-Pomeranian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Bydgoszcz',
		city_ascii: 'Bydgoszcz',
		lat: 53.12041262,
		lng: 18.01000118,
		pop: 366222,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Kuyavian-Pomeranian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Katowice',
		city_ascii: 'Katowice',
		lat: 50.26038047,
		lng: 19.02001705,
		pop: 1527362,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Silesian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Gliwice',
		city_ascii: 'Gliwice',
		lat: 50.3303762,
		lng: 18.67001257,
		pop: 353252.5,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Silesian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Kielce',
		city_ascii: 'Kielce',
		lat: 50.8903937,
		lng: 20.6600203,
		pop: 212165.5,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Swietokrzyskie',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Bialystok',
		city_ascii: 'Bialystok',
		lat: 53.15035911,
		lng: 23.1699963,
		pop: 288722.5,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Podlachian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Lublin',
		city_ascii: 'Lublin',
		lat: 51.25039756,
		lng: 22.57272009,
		pop: 358001,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Lublin',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Rzeszow',
		city_ascii: 'Rzeszow',
		lat: 50.07046958,
		lng: 22.00004187,
		pop: 202034,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Subcarpathian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Lódz',
		city_ascii: 'Lodz',
		lat: 51.77499086,
		lng: 19.45136023,
		pop: 758000,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Lódz',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Gdansk',
		city_ascii: 'Gdansk',
		lat: 54.3599752,
		lng: 18.64004024,
		pop: 597915,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Pomeranian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Kraków',
		city_ascii: 'Krakow',
		lat: 50.05997927,
		lng: 19.96001135,
		pop: 755525,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Lesser Poland',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Warsaw',
		city_ascii: 'Warsaw',
		lat: 52.25000063,
		lng: 20.99999955,
		pop: 1704569.5,
		country: 'Poland',
		iso2: 'PL',
		iso3: 'POL',
		province: 'Masovian',
		timezone: 'Europe/Warsaw',
	},
	{
		city: 'Aveiro',
		city_ascii: 'Aveiro',
		lat: 40.64100311,
		lng: -8.650997534,
		pop: 54162,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Aveiro',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Leiria',
		city_ascii: 'Leiria',
		lat: 39.73899603,
		lng: -8.804996462,
		pop: 45112,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Leiria',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Viana Do Castelo',
		city_ascii: 'Viana Do Castelo',
		lat: 41.69623514,
		lng: -8.844137484,
		pop: 15555,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Viana do Castelo',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Beja',
		city_ascii: 'Beja',
		lat: 38.01400214,
		lng: -7.86300241,
		pop: 28756,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Beja',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Evora',
		city_ascii: 'Evora',
		lat: 38.55999611,
		lng: -7.905995561,
		pop: 55620,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Évora',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Portalegre',
		city_ascii: 'Portalegre',
		lat: 39.29000411,
		lng: -7.423001549,
		pop: 15581,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Portalegre',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Santarem',
		city_ascii: 'Santarem',
		lat: 39.23100008,
		lng: -8.682002552,
		pop: 29385,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Santarém',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Braganca',
		city_ascii: 'Braganca',
		lat: 41.80799701,
		lng: -6.755003426,
		pop: 34375,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Bragança',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Castelo Branco',
		city_ascii: 'Castelo Branco',
		lat: 39.81099615,
		lng: -7.487999559,
		pop: 33479,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Castelo Branco',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Guarda',
		city_ascii: 'Guarda',
		lat: 40.54100414,
		lng: -7.262000512,
		pop: 32111,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Guarda',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Viseu',
		city_ascii: 'Viseu',
		lat: 40.65699611,
		lng: -7.910000431,
		pop: 26364,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Viseu',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Vila Real',
		city_ascii: 'Vila Real',
		lat: 41.29399815,
		lng: -7.73700249,
		pop: 17001,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Vila Real',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Braga',
		city_ascii: 'Braga',
		lat: 41.55499453,
		lng: -8.421331219,
		pop: 504326,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Braga',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Covilha',
		city_ascii: 'Covilha',
		lat: 40.28334088,
		lng: -7.499992108,
		pop: 21219,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Castelo Branco',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Horta',
		city_ascii: 'Horta',
		lat: 38.53465595,
		lng: -28.64475681,
		pop: 6591.5,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Azores',
		timezone: 'Atlantic/Azores',
	},
	{
		city: 'Angra do Heroismo',
		city_ascii: 'Angra do Heroismo',
		lat: 38.65039146,
		lng: -27.21666976,
		pop: 11136.5,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Azores',
		timezone: 'Atlantic/Azores',
	},
	{
		city: 'Portimao',
		city_ascii: 'Portimao',
		lat: 37.13373985,
		lng: -8.533314048,
		pop: 49856.5,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Faro',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Faro',
		city_ascii: 'Faro',
		lat: 37.0170803,
		lng: -7.933273154,
		pop: 31259,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Faro',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Coimbra',
		city_ascii: 'Coimbra',
		lat: 40.20037437,
		lng: -8.41668034,
		pop: 97856.5,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Coimbra',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Setubal',
		city_ascii: 'Setubal',
		lat: 38.52995953,
		lng: -8.900010011,
		pop: 117542,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Lisboa',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Porto',
		city_ascii: 'Porto',
		lat: 41.15000633,
		lng: -8.620001263,
		pop: 793316.5,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Porto',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Funchal',
		city_ascii: 'Funchal',
		lat: 32.64998252,
		lng: -16.88003972,
		pop: 152807,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Madeira',
		timezone: 'Atlantic/Madeira',
	},
	{
		city: 'Ponta Delgada',
		city_ascii: 'Ponta Delgada',
		lat: 37.74830182,
		lng: -25.6665835,
		pop: 40791,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Azores',
		timezone: 'Atlantic/Azores',
	},
	{
		city: 'Lisbon',
		city_ascii: 'Lisbon',
		lat: 38.72272288,
		lng: -9.144866305,
		pop: 1664901,
		country: 'Portugal',
		iso2: 'PT',
		iso3: 'PRT',
		province: 'Lisboa',
		timezone: 'Europe/Lisbon',
	},
	{
		city: 'Ponce',
		city_ascii: 'Ponce',
		lat: 18.00038576,
		lng: -66.61664209,
		pop: 156484,
		country: 'Puerto Rico',
		iso2: 'PR',
		iso3: 'PRI',
		province: '',
		timezone: 'America/Puerto_Rico',
	},
	{
		city: 'Mayaguez',
		city_ascii: 'Mayaguez',
		lat: 18.20151044,
		lng: -67.13971094,
		pop: 184993,
		country: 'Puerto Rico',
		iso2: 'PR',
		iso3: 'PRI',
		province: '',
		timezone: 'America/Puerto_Rico',
	},
	{
		city: 'Arecibo',
		city_ascii: 'Arecibo',
		lat: 18.44002301,
		lng: -66.72999435,
		pop: 59312.5,
		country: 'Puerto Rico',
		iso2: 'PR',
		iso3: 'PRI',
		province: '',
		timezone: 'America/Puerto_Rico',
	},
	{
		city: 'San Juan',
		city_ascii: 'San Juan',
		lat: 18.44002301,
		lng: -66.12997929,
		pop: 1437115.5,
		country: 'Puerto Rico',
		iso2: 'PR',
		iso3: 'PRI',
		province: '',
		timezone: 'America/Puerto_Rico',
	},
	{
		city: 'Doha',
		city_ascii: 'Doha',
		lat: 25.28655601,
		lng: 51.53296789,
		pop: 1090655,
		country: 'Qatar',
		iso2: 'QA',
		iso3: 'QAT',
		province: 'Ad Dawhah',
		timezone: 'Asia/Qatar',
	},
	{
		city: 'Targu Jiu',
		city_ascii: 'Targu Jiu',
		lat: 45.04500004,
		lng: 23.27400062,
		pop: 97179,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Gorj',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Slatina',
		city_ascii: 'Slatina',
		lat: 44.43499814,
		lng: 24.37100156,
		pop: 78988,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Olt',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Alexandria',
		city_ascii: 'Alexandria',
		lat: 43.90163107,
		lng: 25.28670654,
		pop: 49346,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Teleorman',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Targoviste',
		city_ascii: 'Targoviste',
		lat: 44.93799913,
		lng: 25.4590025,
		pop: 88435,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Dâmbovita',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Giurgiu',
		city_ascii: 'Giurgiu',
		lat: 43.92999911,
		lng: 25.8399995,
		pop: 69067,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Giurgiu',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Slobozia',
		city_ascii: 'Slobozia',
		lat: 44.56999806,
		lng: 27.38199659,
		pop: 52693,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Ialomita',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Alba Lulia',
		city_ascii: 'Alba Lulia',
		lat: 46.07700313,
		lng: 23.58000059,
		pop: 66085,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Alba',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Bistrita',
		city_ascii: 'Bistrita',
		lat: 47.13800409,
		lng: 24.51300358,
		pop: 81318,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Bistrita-Nasaud',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Deva',
		city_ascii: 'Deva',
		lat: 45.88332304,
		lng: 22.91667357,
		pop: 67802,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Hunedoara',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Zalau',
		city_ascii: 'Zalau',
		lat: 47.1750031,
		lng: 23.06300448,
		pop: 63232,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Salaj',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Satu Mare',
		city_ascii: 'Satu Mare',
		lat: 47.79199816,
		lng: 22.88500248,
		pop: 112490,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Satu Mare',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Rimnicu Vilcea',
		city_ascii: 'Rimnicu Vilcea',
		lat: 45.10999804,
		lng: 24.38299862,
		pop: 107558,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Vâlcea',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Sfintu-Gheorghe',
		city_ascii: 'Sfintu-Gheorghe',
		lat: 45.86799611,
		lng: 25.79300148,
		pop: 60677,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Covasna',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Miercurea Cuic',
		city_ascii: 'Miercurea-Cuic',
		lat: 46.36099808,
		lng: 25.52400051,
		pop: 40004.5,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Harghita',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Piatra-Neamt',
		city_ascii: 'Piatra-Neamt',
		lat: 46.94000405,
		lng: 26.38299657,
		pop: 102688,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Neamt',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Braila',
		city_ascii: 'Braila',
		lat: 45.29199615,
		lng: 27.96900354,
		pop: 213569,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Braila',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Vaslui',
		city_ascii: 'Vaslui',
		lat: 46.63332908,
		lng: 27.73333859,
		pop: 69225,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Vaslui',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Drobeta-Turnu Severin',
		city_ascii: 'Drobeta-Turnu Severin',
		lat: 44.64589113,
		lng: 22.6658927,
		pop: 104462,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Mehedinti',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Tulcea',
		city_ascii: 'Tulcea',
		lat: 45.19934572,
		lng: 28.79668127,
		pop: 89381.5,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Constanta',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Arad',
		city_ascii: 'Arad',
		lat: 46.17000999,
		lng: 21.31998002,
		pop: 159338,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Arad',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Oradea',
		city_ascii: 'Oradea',
		lat: 47.04998212,
		lng: 21.91999508,
		pop: 210222,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Bihor',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Sibiu',
		city_ascii: 'Sibiu',
		lat: 45.79711285,
		lng: 24.13712073,
		pop: 153729.5,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Sibiu',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Suceava',
		city_ascii: 'Suceava',
		lat: 47.63769818,
		lng: 26.25931677,
		pop: 96865.5,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Suceava',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Buzau',
		city_ascii: 'Buzau',
		lat: 45.15650596,
		lng: 26.80651851,
		pop: 130826,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Buzau',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Galati',
		city_ascii: 'Galati',
		lat: 45.45589337,
		lng: 28.04587439,
		pop: 302621.5,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Galati',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Focsani',
		city_ascii: 'Focsani',
		lat: 45.69655052,
		lng: 27.186547,
		pop: 92636.5,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Vrancea',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Craiova',
		city_ascii: 'Craiova',
		lat: 44.3262724,
		lng: 23.82587357,
		pop: 301143.5,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Dolj',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Calarasi',
		city_ascii: 'Calarasi',
		lat: 44.20627972,
		lng: 27.32591833,
		pop: 71195.5,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Calarasi',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Resita',
		city_ascii: 'Resita',
		lat: 45.2969625,
		lng: 21.88650875,
		pop: 82276,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Caras-Severin',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Timisoara',
		city_ascii: 'Timisoara',
		lat: 45.75882062,
		lng: 21.22344844,
		pop: 309575,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Timis',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Botosani',
		city_ascii: 'Botosani',
		lat: 47.74841494,
		lng: 26.65965409,
		pop: 113359,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Botosani',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Baia Mare',
		city_ascii: 'Baia Mare',
		lat: 47.65945396,
		lng: 23.57906693,
		pop: 134630,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Maramures',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Tirgu Mures',
		city_ascii: 'Tirgu Mures',
		lat: 46.55820335,
		lng: 24.55781856,
		pop: 148148,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Mures',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Pitesti',
		city_ascii: 'Pitesti',
		lat: 44.85631757,
		lng: 24.87583533,
		pop: 169345,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Arges',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Brasov',
		city_ascii: 'Brasov',
		lat: 45.64753542,
		lng: 25.6071602,
		pop: 293566,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Brasov',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Ploiesti',
		city_ascii: 'Ploiesti',
		lat: 44.94690635,
		lng: 26.036488,
		pop: 230696.5,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Prahova',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Bacau',
		city_ascii: 'Bacau',
		lat: 46.57843467,
		lng: 26.91963822,
		pop: 185532,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Bacau',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Cluj-Napoca',
		city_ascii: 'Cluj-Napoca',
		lat: 46.78842185,
		lng: 23.5984456,
		pop: 299444.5,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Cluj',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Constanta',
		city_ascii: 'Constanta',
		lat: 44.20266237,
		lng: 28.60997432,
		pop: 285112.5,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Constanta',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Iasi',
		city_ascii: 'Iasi',
		lat: 47.16834698,
		lng: 27.57494706,
		pop: 325914,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Iasi',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Bucharest',
		city_ascii: 'Bucharest',
		lat: 44.4333718,
		lng: 26.09994665,
		pop: 1842097,
		country: 'Romania',
		iso2: 'RO',
		iso3: 'ROU',
		province: 'Bucharest',
		timezone: 'Europe/Bucharest',
	},
	{
		city: 'Nazran',
		city_ascii: 'Nazran',
		lat: 43.23300312,
		lng: 44.78300151,
		pop: 93357,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Ingush',
		timezone: 'Europe/Moscow',
	},
	{
		city: "Ust' Ordynskiy",
		city_ascii: "Ust' Ordynskiy",
		lat: 52.83299713,
		lng: 104.6999977,
		pop: 14538,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Ust-Orda Buryat',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Maykop',
		city_ascii: 'Maykop',
		lat: 44.60997601,
		lng: 40.12002112,
		pop: 143377,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Adygey',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Mozdok',
		city_ascii: 'Mozdok',
		lat: 43.75431765,
		lng: 44.65436967,
		pop: 43262.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'North Ossetia',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Georgievsk',
		city_ascii: 'Georgievsk',
		lat: 44.15990013,
		lng: 43.46994584,
		pop: 59158.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Stavropol',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Pyatigorsk',
		city_ascii: 'Pyatigorsk',
		lat: 44.07995668,
		lng: 43.09002071,
		pop: 86245.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Stavropol',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kislovodsk',
		city_ascii: 'Kislovodsk',
		lat: 43.90996706,
		lng: 42.72001745,
		pop: 132337,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Stavropol',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Nevinnomyssk',
		city_ascii: 'Nevinnomyssk',
		lat: 44.62005292,
		lng: 41.95003861,
		pop: 134362.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Stavropol',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Enurmino',
		city_ascii: 'Enurmino',
		lat: 66.95000775,
		lng: -171.8166032,
		pop: 297,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chukchi Autonomous Okrug',
		timezone: 'Asia/Anadyr',
	},
	{
		city: 'Lavrentiya',
		city_ascii: 'Lavrentiya',
		lat: 65.58326947,
		lng: -171.0249978,
		pop: 660,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chukchi Autonomous Okrug',
		timezone: 'Asia/Anadyr',
	},
	{
		city: 'Zvezdnyy',
		city_ascii: 'Zvezdnyy',
		lat: 70.9565849,
		lng: -179.5899789,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chukchi Autonomous Okrug',
		timezone: 'Asia/Anadyr',
	},
	{
		city: 'Mikhalkino',
		city_ascii: 'Mikhalkino',
		lat: 69.42444039,
		lng: 161.4811431,
		pop: 570,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Koryak',
		timezone: 'Asia/Srednekolymsk',
	},
	{
		city: 'Chernyakhovsk',
		city_ascii: 'Chernyakhovsk',
		lat: 54.6316382,
		lng: 21.81085445,
		pop: 42356.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kaliningrad',
		timezone: 'Europe/Kaliningrad',
	},
	{
		city: 'Severomorsk',
		city_ascii: 'Severomorsk',
		lat: 69.07305646,
		lng: 33.42306555,
		pop: 46709,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Murmansk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Apatity',
		city_ascii: 'Apatity',
		lat: 67.57307049,
		lng: 33.39304154,
		pop: 64046.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Murmansk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Polyarnyy',
		city_ascii: 'Polyarnyy',
		lat: 69.20521893,
		lng: 33.45024735,
		pop: 18266,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Murmansk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Slantsy',
		city_ascii: 'Slantsy',
		lat: 59.11159731,
		lng: 28.07465816,
		pop: 27479,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Leningrad',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kolpino',
		city_ascii: 'Kolpino',
		lat: 59.73000917,
		lng: 30.65000484,
		pop: 180938.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'City of St. Petersburg',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Novozybkov',
		city_ascii: 'Novozybkov',
		lat: 52.53481529,
		lng: 31.9448612,
		pop: 43052.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Bryansk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Dyatkovo',
		city_ascii: 'Dyatkovo',
		lat: 53.5891437,
		lng: 34.33918534,
		pop: 33363.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Bryansk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Shuya',
		city_ascii: 'Shuya',
		lat: 56.85434491,
		lng: 41.36428625,
		pop: 59585.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Ivanovo',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kineshma',
		city_ascii: 'Kineshma',
		lat: 57.46996625,
		lng: 42.12997595,
		pop: 91874,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Ivanovo',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Balakhna',
		city_ascii: 'Balakhna',
		lat: 56.49434104,
		lng: 43.59438269,
		pop: 62487,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Nizhegorod',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Arzamas',
		city_ascii: 'Arzamas',
		lat: 55.39998924,
		lng: 43.80000321,
		pop: 126038,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Nizhegorod',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Rzhev',
		city_ascii: 'Rzhev',
		lat: 56.2574046,
		lng: 34.32745479,
		pop: 62830,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tver',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Vyshnniy Volochek',
		city_ascii: 'Vyshnniy Volochek',
		lat: 57.58303428,
		lng: 34.56309932,
		pop: 50254,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tver',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Uglich',
		city_ascii: 'Uglich',
		lat: 57.52435569,
		lng: 38.33000118,
		pop: 36355,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Yaroslavl',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Yelets',
		city_ascii: 'Yelets',
		lat: 52.58000633,
		lng: 38.50001664,
		pop: 115803.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Lipetsk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Orekhovo-Zuevo',
		city_ascii: 'Orekhovo-Zuevo',
		lat: 55.82001528,
		lng: 38.97998734,
		pop: 130123.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Moskovsskaya',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Klin',
		city_ascii: 'Klin',
		lat: 56.34305829,
		lng: 36.69873124,
		pop: 72221,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Moskovsskaya',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Sergiyev Posad',
		city_ascii: 'Sergiyev Posad',
		lat: 56.33000999,
		lng: 38.17001094,
		pop: 107047.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Moskovsskaya',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kolomna',
		city_ascii: 'Kolomna',
		lat: 55.07998293,
		lng: 38.78496049,
		pop: 130324.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Moskovsskaya',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Bataysk',
		city_ascii: 'Bataysk',
		lat: 47.13682436,
		lng: 39.74485022,
		pop: 106844.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Rostov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Taganrog',
		city_ascii: 'Taganrog',
		lat: 47.22999697,
		lng: 38.91999101,
		pop: 254960,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Rostov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Novocherkassk',
		city_ascii: 'Novocherkassk',
		lat: 47.41995953,
		lng: 40.08002356,
		pop: 159470.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Rostov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kamensk Shakhtinskiy',
		city_ascii: 'Kamensk Shakhtinskiy',
		lat: 48.33176434,
		lng: 40.25179602,
		pop: 69037.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Rostov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Novoshakhtinsk',
		city_ascii: 'Novoshakhtinsk',
		lat: 47.76998985,
		lng: 39.91998165,
		pop: 82769.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Rostov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Aleksin',
		city_ascii: 'Aleksin',
		lat: 54.5143327,
		lng: 37.09436601,
		pop: 59194,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tula',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Novomoskovsk',
		city_ascii: 'Novomoskovsk',
		lat: 54.09001752,
		lng: 38.21998205,
		pop: 74591.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tula',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Shchekino',
		city_ascii: 'Shchekino',
		lat: 54.01433738,
		lng: 37.5142887,
		pop: 73394,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tula',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Nikolayevsk',
		city_ascii: 'Nikolayevsk',
		lat: 50.01610598,
		lng: 45.42610551,
		pop: 9803.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Volgograd',
		timezone: 'Europe/Volgograd',
	},
	{
		city: 'Shebekino',
		city_ascii: 'Shebekino',
		lat: 50.4143504,
		lng: 36.89437821,
		pop: 41301.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Belgorod',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Gubkin',
		city_ascii: 'Gubkin',
		lat: 51.27434959,
		lng: 37.38432247,
		pop: 272,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Belgorod',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Apsheronsk',
		city_ascii: 'Apsheronsk',
		lat: 44.46871848,
		lng: 39.72872717,
		pop: 43163.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnodar',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kropotkin',
		city_ascii: 'Kropotkin',
		lat: 45.44708254,
		lng: 40.58211177,
		pop: 70518,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnodar',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Ruzayevka',
		city_ascii: 'Ruzayevka',
		lat: 54.06433433,
		lng: 44.92437903,
		pop: 44635,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Mordovia',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kirsanov',
		city_ascii: 'Kirsanov',
		lat: 52.65739178,
		lng: 42.71743363,
		pop: 17001,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tambov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Michurinsk',
		city_ascii: 'Michurinsk',
		lat: 52.8999868,
		lng: 40.49999792,
		pop: 93364,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tambov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Borisoglebsk',
		city_ascii: 'Borisoglebsk',
		lat: 51.36871075,
		lng: 42.08873816,
		pop: 64995,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Voronezh',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Oktyabrskiy',
		city_ascii: 'Oktyabrskiy',
		lat: 54.4599691,
		lng: 53.45998205,
		pop: 87793,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Bashkortostan',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Plast',
		city_ascii: 'Plast',
		lat: 54.36850181,
		lng: 60.80852576,
		pop: 15480.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chelyabinsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Bakal',
		city_ascii: 'Bakal',
		lat: 54.94588259,
		lng: 58.79588375,
		pop: 24160.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chelyabinsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Verkhniy Ufaley',
		city_ascii: 'Verkhniy Ufaley',
		lat: 56.06627932,
		lng: 60.23130001,
		pop: 33562,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chelyabinsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Severnyy',
		city_ascii: 'Severnyy',
		lat: 67.60828798,
		lng: 64.12325883,
		pop: 11562,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Komi',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kirovo-Chepetsk',
		city_ascii: 'Kirovo-Chepetsk',
		lat: 58.55437034,
		lng: 50.04437659,
		pop: 71555.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kirov',
		timezone: 'Europe/Kirov',
	},
	{
		city: 'Krasnoturinsk',
		city_ascii: 'Krasnoturinsk',
		lat: 59.79478558,
		lng: 60.48477291,
		pop: 64878,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Asbest',
		city_ascii: 'Asbest',
		lat: 57.02304262,
		lng: 61.45804683,
		pop: 77915.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Alapayevsk',
		city_ascii: 'Alapayevsk',
		lat: 57.84650657,
		lng: 61.69152095,
		pop: 43663.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Krasnouralsk',
		city_ascii: 'Krasnouralsk',
		lat: 58.35151451,
		lng: 60.0515177,
		pop: 20571,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Severouralsk',
		city_ascii: 'Severouralsk',
		lat: 60.15652061,
		lng: 59.96154903,
		pop: 34819,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Novotroitsk',
		city_ascii: 'Novotroitsk',
		lat: 51.20001304,
		lng: 58.33002071,
		pop: 90278.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Orenburg',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Buguruslan',
		city_ascii: 'Buguruslan',
		lat: 53.66301516,
		lng: 52.43301632,
		pop: 51877,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Orenburg',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Chapayevsk',
		city_ascii: 'Chapayevsk',
		lat: 52.97432334,
		lng: 49.72434444,
		pop: 88655.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Samara',
		timezone: 'Europe/Samara',
	},
	{
		city: 'Syzran',
		city_ascii: 'Syzran',
		lat: 53.16999615,
		lng: 48.47997595,
		pop: 171589,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Samara',
		timezone: 'Europe/Samara',
	},
	{
		city: 'Novokuybishevsk',
		city_ascii: 'Novokuybishevsk',
		lat: 53.11999921,
		lng: 49.91993974,
		pop: 132067,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Samara',
		timezone: 'Europe/Samara',
	},
	{
		city: 'Naberezhnyye Chelny',
		city_ascii: 'Naberezhnyye Chelny',
		lat: 55.69999676,
		lng: 52.31994828,
		pop: 461086,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tatarstan',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Zelenodolsk',
		city_ascii: 'Zelenodolsk',
		lat: 55.84055666,
		lng: 48.65500403,
		pop: 50338.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tatarstan',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Leninogorsk',
		city_ascii: 'Leninogorsk',
		lat: 54.59869448,
		lng: 52.44872595,
		pop: 53362,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tatarstan',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Bugulma',
		city_ascii: 'Bugulma',
		lat: 54.55433026,
		lng: 52.79428625,
		pop: 85384,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tatarstan',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Nefteyugansk',
		city_ascii: 'Nefteyugansk',
		lat: 61.07765301,
		lng: 72.70268347,
		pop: 112632,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khanty-Mansiy',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Leninsk Kuznetsky',
		city_ascii: 'Leninsk Kuznetsky',
		lat: 54.66000856,
		lng: 86.16997514,
		pop: 108047.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kemerovo',
		timezone: 'Asia/Novokuznetsk',
	},
	{
		city: 'Anzhero Sudzhensk',
		city_ascii: 'Anzhero Sudzhensk',
		lat: 56.08002525,
		lng: 86.04000891,
		pop: 83109,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kemerovo',
		timezone: 'Asia/Novokuznetsk',
	},
	{
		city: 'Kiselevsk',
		city_ascii: 'Kiselevsk',
		lat: 54.00002301,
		lng: 86.64002397,
		pop: 87164,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kemerovo',
		timezone: 'Asia/Novokuznetsk',
	},
	{
		city: 'Mundybash',
		city_ascii: 'Mundybash',
		lat: 53.23327395,
		lng: 87.31667517,
		pop: 5870,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kemerovo',
		timezone: 'Asia/Novokuznetsk',
	},
	{
		city: 'Chernogorsk',
		city_ascii: 'Chernogorsk',
		lat: 53.8313253,
		lng: 91.22268998,
		pop: 39815.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khakass',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Abaza',
		city_ascii: 'Abaza',
		lat: 52.66901898,
		lng: 90.09536861,
		pop: 17638.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khakass',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Iskitim',
		city_ascii: 'Iskitim',
		lat: 54.65093935,
		lng: 83.28653357,
		pop: 60806.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Novosibirsk',
		timezone: 'Asia/Novosibirsk',
	},
	{
		city: 'Toguchin',
		city_ascii: 'Toguchin',
		lat: 55.23767356,
		lng: 84.37768144,
		pop: 20087,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Novosibirsk',
		timezone: 'Asia/Novosibirsk',
	},
	{
		city: 'Kupina',
		city_ascii: 'Kupina',
		lat: 54.35922589,
		lng: 77.27418738,
		pop: 9856.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Novosibirsk',
		timezone: 'Asia/Novosibirsk',
	},
	{
		city: 'Zaozernyy',
		city_ascii: 'Zaozernyy',
		lat: 55.96197044,
		lng: 94.70278764,
		pop: 33865,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Bogotol',
		city_ascii: 'Bogotol',
		lat: 56.21647687,
		lng: 89.51840124,
		pop: 22559.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Shilka',
		city_ascii: 'Shilka',
		lat: 51.87056643,
		lng: 116.0306331,
		pop: 10561,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chita',
		timezone: 'Asia/Chita',
	},
	{
		city: 'Sherlovaya Gora',
		city_ascii: 'Sherlovaya Gora',
		lat: 50.53059654,
		lng: 116.3006425,
		pop: 411,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chita',
		timezone: 'Asia/Chita',
	},
	{
		city: 'Petrovsk Zabaykalskiy',
		city_ascii: 'Petrovsk Zabaykalskiy',
		lat: 51.28269533,
		lng: 108.8326745,
		pop: 20301,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chita',
		timezone: 'Asia/Chita',
	},
	{
		city: 'Arsenyev',
		city_ascii: 'Arsenyev',
		lat: 44.16230308,
		lng: 133.2823449,
		pop: 56721,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Primor'ye",
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Partizansk',
		city_ascii: 'Partizansk',
		lat: 43.13487225,
		lng: 133.1349121,
		pop: 40734.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Primor'ye",
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Dalnerechensk',
		city_ascii: 'Dalnerechensk',
		lat: 45.92728579,
		lng: 133.7223181,
		pop: 25917.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Primor'ye",
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Zemlya Bunge',
		city_ascii: 'Zemlya Bunge',
		lat: 74.89830813,
		lng: 142.1050105,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Khorgo',
		city_ascii: 'Khorgo',
		lat: 73.48330406,
		lng: 113.6300044,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Put Lenina',
		city_ascii: 'Put Lenina',
		lat: 68.51663047,
		lng: 107.7999727,
		pop: 298,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Obluchye',
		city_ascii: 'Obluchye',
		lat: 48.99999229,
		lng: 131.083306,
		pop: 9689.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Yevrey',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Vanino',
		city_ascii: 'Vanino',
		lat: 49.08732546,
		lng: 140.2424886,
		pop: 15985.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khabarovsk',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Omchak',
		city_ascii: 'Omchak',
		lat: 61.63327801,
		lng: 147.9166971,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Maga Buryatdan',
		timezone: 'Asia/Magadan',
	},
	{
		city: 'Uglegorsk',
		city_ascii: 'Uglegorsk',
		lat: 49.08334637,
		lng: 142.0333353,
		pop: 12139.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakhalin',
		timezone: 'Asia/Sakhalin',
	},
	{
		city: 'Kholmsk',
		city_ascii: 'Kholmsk',
		lat: 47.04732078,
		lng: 142.0623775,
		pop: 32796,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakhalin',
		timezone: 'Asia/Sakhalin',
	},
	{
		city: 'Solikamsk',
		city_ascii: 'Solikamsk',
		lat: 59.669987,
		lng: 56.74996212,
		pop: 97397,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Perm',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Kizel',
		city_ascii: 'Kizel',
		lat: 59.06436505,
		lng: 57.6343009,
		pop: 21971,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Perm',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Pakhachi',
		city_ascii: 'Pakhachi',
		lat: 60.5815851,
		lng: 169.0499808,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kamchatka',
		timezone: 'Asia/Kamchatka',
	},
	{
		city: 'Timiryazevskiy',
		city_ascii: 'Timiryazevskiy',
		lat: 56.49260988,
		lng: 84.90359249,
		pop: 6705.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tomsk',
		timezone: 'Asia/Tomsk',
	},
	{
		city: 'Asino',
		city_ascii: 'Asino',
		lat: 56.99312197,
		lng: 86.16268876,
		pop: 24732.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tomsk',
		timezone: 'Asia/Tomsk',
	},
	{
		city: 'Strezhevoy',
		city_ascii: 'Strezhevoy',
		lat: 60.73315208,
		lng: 77.57768306,
		pop: 38997.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tomsk',
		timezone: 'Asia/Tomsk',
	},
	{
		city: 'Cherkessk',
		city_ascii: 'Cherkessk',
		lat: 44.29040895,
		lng: 42.06000606,
		pop: 101153,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Karachay-Cherkess',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Vladikavkaz',
		city_ascii: 'Vladikavkaz',
		lat: 43.05038129,
		lng: 44.66997595,
		pop: 341000,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'North Ossetia',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Blagodarnyy',
		city_ascii: 'Blagodarnyy',
		lat: 45.10472618,
		lng: 43.43439245,
		pop: 28070.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Stavropol',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Zelenokumsk',
		city_ascii: 'Zelenokumsk',
		lat: 44.40910972,
		lng: 43.87870642,
		pop: 35220.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Stavropol',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Mukhomornoye',
		city_ascii: 'Mukhomornoye',
		lat: 66.4170687,
		lng: 173.333337,
		pop: 55,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chukchi Autonomous Okrug',
		timezone: 'Asia/Anadyr',
	},
	{
		city: 'Beringovskiy',
		city_ascii: 'Beringovskiy',
		lat: 63.0654645,
		lng: 179.3066674,
		pop: 1861,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chukchi Autonomous Okrug',
		timezone: 'Asia/Anadyr',
	},
	{
		city: 'Bilibino',
		city_ascii: 'Bilibino',
		lat: 68.0504057,
		lng: 166.3332991,
		pop: 5757,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chukchi Autonomous Okrug',
		timezone: 'Asia/Anadyr',
	},
	{
		city: 'Mys Shmidta',
		city_ascii: 'Mys Shmidta',
		lat: 68.93371096,
		lng: -179.4999844,
		pop: 492,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chukchi Autonomous Okrug',
		timezone: 'Asia/Anadyr',
	},
	{
		city: 'Egvekinot',
		city_ascii: 'Egvekinot',
		lat: 66.32213166,
		lng: -179.1837225,
		pop: 2248,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chukchi Autonomous Okrug',
		timezone: 'Asia/Anadyr',
	},
	{
		city: 'Sovetsk',
		city_ascii: 'Sovetsk',
		lat: 55.07176638,
		lng: 21.88196122,
		pop: 40166.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kaliningrad',
		timezone: 'Europe/Kaliningrad',
	},
	{
		city: 'Nikel',
		city_ascii: 'Nikel',
		lat: 69.41257062,
		lng: 30.21881669,
		pop: 15731,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Murmansk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Monchegorsk',
		city_ascii: 'Monchegorsk',
		lat: 67.92912111,
		lng: 32.8287349,
		pop: 46934.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Murmansk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kirovsk',
		city_ascii: 'Kirovsk',
		lat: 67.60908897,
		lng: 33.66873531,
		pop: 22949,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Murmansk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Borovichi',
		city_ascii: 'Borovichi',
		lat: 58.39781659,
		lng: 33.89740352,
		pop: 57229,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Novgorod',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Staraya Russa',
		city_ascii: 'Staraya Russa',
		lat: 57.99476625,
		lng: 31.35435461,
		pop: 32591.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Novgorod',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Volkhov',
		city_ascii: 'Volkhov',
		lat: 59.92909263,
		lng: 32.33873897,
		pop: 45366.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Leningrad',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Tikhvin',
		city_ascii: 'Tikhvin',
		lat: 59.64484641,
		lng: 33.51437781,
		pop: 50793,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Leningrad',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Svetogorsk',
		city_ascii: 'Svetogorsk',
		lat: 61.10132082,
		lng: 28.9176558,
		pop: 22924,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Leningrad',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Gatchina',
		city_ascii: 'Gatchina',
		lat: 59.5706649,
		lng: 30.13334387,
		pop: 90123.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Leningrad',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Luga',
		city_ascii: 'Luga',
		lat: 58.7363489,
		lng: 29.83899491,
		pop: 37124.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Leningrad',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Klintsy',
		city_ascii: 'Klintsy',
		lat: 52.7652405,
		lng: 32.24484289,
		pop: 60885,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Bryansk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Roslavl',
		city_ascii: 'Roslavl',
		lat: 53.95090456,
		lng: 32.86041256,
		pop: 54299,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Smolensk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Safonovo',
		city_ascii: 'Safonovo',
		lat: 55.1464905,
		lng: 33.21610144,
		pop: 44461,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Smolensk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Vyazma',
		city_ascii: 'Vyazma',
		lat: 55.21219708,
		lng: 34.29179805,
		pop: 49118.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Smolensk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Segezha',
		city_ascii: 'Segezha',
		lat: 63.75477643,
		lng: 34.32430253,
		pop: 28961,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Karelia',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Vichuga',
		city_ascii: 'Vichuga',
		lat: 57.21912884,
		lng: 41.92874792,
		pop: 38093.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Ivanovo',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Sharya',
		city_ascii: 'Sharya',
		lat: 58.37975568,
		lng: 45.50935624,
		pop: 32666,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kostroma',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Buy',
		city_ascii: 'Buy',
		lat: 58.48468467,
		lng: 41.52437984,
		pop: 25781.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kostroma',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Dzerzhinsk',
		city_ascii: 'Dzerzhinsk',
		lat: 56.25037661,
		lng: 43.46002397,
		pop: 235457.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Nizhegorod',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Vyska',
		city_ascii: 'Vyska',
		lat: 55.32472251,
		lng: 42.16439245,
		pop: 60356,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Nizhegorod',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kimry',
		city_ascii: 'Kimry',
		lat: 56.86912437,
		lng: 37.34437659,
		pop: 50531,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tver',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Bezhetsk',
		city_ascii: 'Bezhetsk',
		lat: 57.76472862,
		lng: 36.68999792,
		pop: 31425,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tver',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Nelidovo',
		city_ascii: 'Nelidovo',
		lat: 56.22350486,
		lng: 32.77307939,
		pop: 24973,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tver',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Bologoye',
		city_ascii: 'Bologoye',
		lat: 57.87216392,
		lng: 34.05176103,
		pop: 21785,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tver',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Torzhok',
		city_ascii: 'Torzhok',
		lat: 57.02906293,
		lng: 34.97873287,
		pop: 45839,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tver',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Sokol',
		city_ascii: 'Sokol',
		lat: 59.46477989,
		lng: 40.11438839,
		pop: 35637.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Vologda',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Cherepovets',
		city_ascii: 'Cherepovets',
		lat: 59.14043276,
		lng: 37.90997514,
		pop: 265606.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Vologda',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Rybinsk',
		city_ascii: 'Rybinsk',
		lat: 58.05034426,
		lng: 38.81999711,
		pop: 203874.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Yaroslavl',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Rostov',
		city_ascii: 'Rostov',
		lat: 57.18915651,
		lng: 39.40430253,
		pop: 33578,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Yaroslavl',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kaluga',
		city_ascii: 'Kaluga',
		lat: 54.52037884,
		lng: 36.27002356,
		pop: 313733.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kaluga',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kirov',
		city_ascii: 'Kirov',
		lat: 54.08518577,
		lng: 34.30482051,
		pop: 33852,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kaluga',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Obninsk',
		city_ascii: 'Obninsk',
		lat: 55.08044802,
		lng: 36.62002803,
		pop: 66236,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kaluga',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Lgov',
		city_ascii: 'Lgov',
		lat: 51.6947632,
		lng: 35.27437374,
		pop: 16093.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kursk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Zheleznogorsk',
		city_ascii: 'Zheleznogorsk',
		lat: 52.3547746,
		lng: 35.40439164,
		pop: 94212,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kursk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Gryazi',
		city_ascii: 'Gryazi',
		lat: 52.49476605,
		lng: 39.9343477,
		pop: 46451,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Lipetsk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Yegoryevsk',
		city_ascii: 'Yegoryevsk',
		lat: 55.38479637,
		lng: 39.02939001,
		pop: 87497.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Moskovsskaya',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Podolsk',
		city_ascii: 'Podolsk',
		lat: 55.38042971,
		lng: 37.52994665,
		pop: 250017.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Moskovsskaya',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Solnechnogorsk',
		city_ascii: 'Solnechnogorsk',
		lat: 56.18069094,
		lng: 36.98088456,
		pop: 48043,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Moskovsskaya',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Noginsk',
		city_ascii: 'Noginsk',
		lat: 55.87042564,
		lng: 38.48001786,
		pop: 172855,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Moskovsskaya',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Serpukhov',
		city_ascii: 'Serpukhov',
		lat: 54.93037966,
		lng: 37.43000443,
		pop: 131871,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Moskovsskaya',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Livny',
		city_ascii: 'Livny',
		lat: 52.42479616,
		lng: 37.60436072,
		pop: 52277.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Orel',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Mtsensk',
		city_ascii: 'Mtsensk',
		lat: 53.26474489,
		lng: 36.54716426,
		pop: 24499.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Orel',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Salsk',
		city_ascii: 'Salsk',
		lat: 46.4775106,
		lng: 41.5420015,
		pop: 54739,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Rostov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Belaya Kalitva',
		city_ascii: 'Belaya Kalitva',
		lat: 48.18650189,
		lng: 40.78613033,
		pop: 47809.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Rostov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Shakhty',
		city_ascii: 'Shakhty',
		lat: 47.72038047,
		lng: 40.2700378,
		pop: 206203.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Rostov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Millerovo',
		city_ascii: 'Millerovo',
		lat: 48.93785138,
		lng: 40.39742021,
		pop: 32726.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Rostov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Yefremov',
		city_ascii: 'Yefremov',
		lat: 53.14911888,
		lng: 38.12153845,
		pop: 44933,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tula',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Bogoroditsk',
		city_ascii: 'Bogoroditsk',
		lat: 53.77468793,
		lng: 38.11435543,
		pop: 34884.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tula',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kamyshin',
		city_ascii: 'Kamyshin',
		lat: 50.08039146,
		lng: 45.40000891,
		pop: 82613,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Volgograd',
		timezone: 'Europe/Volgograd',
	},
	{
		city: 'Pallasovka',
		city_ascii: 'Pallasovka',
		lat: 50.04773195,
		lng: 46.87733476,
		pop: 9960.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Volgograd',
		timezone: 'Europe/Volgograd',
	},
	{
		city: 'Frolovo',
		city_ascii: 'Frolovo',
		lat: 49.77219322,
		lng: 43.65179521,
		pop: 40096.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Volgograd',
		timezone: 'Europe/Volgograd',
	},
	{
		city: 'Volzhskiy',
		city_ascii: 'Volzhskiy',
		lat: 48.79481101,
		lng: 44.77436234,
		pop: 306022.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Volgograd',
		timezone: 'Europe/Volgograd',
	},
	{
		city: 'Mikhaylovka',
		city_ascii: 'Mikhaylovka',
		lat: 50.06785992,
		lng: 43.21745479,
		pop: 57327.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Volgograd',
		timezone: 'Europe/Volgograd',
	},
	{
		city: 'Uryupinsk',
		city_ascii: 'Uryupinsk',
		lat: 50.77344993,
		lng: 42.00305863,
		pop: 37993.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Volgograd',
		timezone: 'Europe/Volgograd',
	},
	{
		city: 'Starsy Oskol',
		city_ascii: 'Starsy Oskol',
		lat: 51.30042035,
		lng: 37.83995357,
		pop: 200131,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Belgorod',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Alekseyevka',
		city_ascii: 'Alekseyevka',
		lat: 50.65348309,
		lng: 38.69307979,
		pop: 38633.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Belgorod',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Valuyki',
		city_ascii: 'Valuyki',
		lat: 50.20909161,
		lng: 38.09869747,
		pop: 32045,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Belgorod',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Tuapse',
		city_ascii: 'Tuapse',
		lat: 44.11476076,
		lng: 39.06437496,
		pop: 81689.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnodar',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Gelendzhik',
		city_ascii: 'Gelendzhik',
		lat: 44.57475852,
		lng: 38.06438432,
		pop: 53111.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnodar',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Labinsk',
		city_ascii: 'Labinsk',
		lat: 44.6347807,
		lng: 40.74427242,
		pop: 51594,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnodar',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Armavir',
		city_ascii: 'Armavir',
		lat: 45.00039146,
		lng: 41.13003699,
		pop: 191813.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnodar',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Timashevsk',
		city_ascii: 'Timashevsk',
		lat: 45.62474611,
		lng: 38.94438228,
		pop: 44024,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnodar',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Tikhoretsk',
		city_ascii: 'Tikhoretsk',
		lat: 45.85310427,
		lng: 40.13774613,
		pop: 62368.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnodar',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Yeysk',
		city_ascii: 'Yeysk',
		lat: 46.69878908,
		lng: 38.26339026,
		pop: 76591.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnodar',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Saransk',
		city_ascii: 'Saransk',
		lat: 54.17037437,
		lng: 45.18002234,
		pop: 303304.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Mordovia',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kamenka',
		city_ascii: 'Kamenka',
		lat: 53.19472333,
		lng: 44.04438106,
		pop: 16560,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Penza',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kuznetsk',
		city_ascii: 'Kuznetsk',
		lat: 53.12041262,
		lng: 46.59998734,
		pop: 93027,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Penza',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Serdobsk',
		city_ascii: 'Serdobsk',
		lat: 52.46218406,
		lng: 44.22178625,
		pop: 30263,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Penza',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kasimov',
		city_ascii: 'Kasimov',
		lat: 54.9434538,
		lng: 41.39307003,
		pop: 36009,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Ryazan',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Sasovo',
		city_ascii: 'Sasovo',
		lat: 54.34914899,
		lng: 41.90869747,
		pop: 30591.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Ryazan',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kotovsk',
		city_ascii: 'Kotovsk',
		lat: 52.59473411,
		lng: 41.50438106,
		pop: 31221,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tambov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Morshansk',
		city_ascii: 'Morshansk',
		lat: 53.4547333,
		lng: 41.80436275,
		pop: 46330.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tambov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kovrov',
		city_ascii: 'Kovrov',
		lat: 56.36036989,
		lng: 41.33002478,
		pop: 153060,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Vladimir',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Murom',
		city_ascii: 'Murom',
		lat: 55.57041811,
		lng: 42.04000728,
		pop: 129109,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Vladimir',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Rayevskiy',
		city_ascii: 'Rayevskiy',
		lat: 54.06738324,
		lng: 54.92692094,
		pop: 13578.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Bashkortostan',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Sibay',
		city_ascii: 'Sibay',
		lat: 52.70911989,
		lng: 58.63873572,
		pop: 54696,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Bashkortostan',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Kumertau',
		city_ascii: 'Kumertau',
		lat: 52.7747748,
		lng: 55.7843363,
		pop: 48667.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Bashkortostan',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Salavat',
		city_ascii: 'Salavat',
		lat: 53.37034568,
		lng: 55.92996049,
		pop: 111648,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Bashkortostan',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Belebey',
		city_ascii: 'Belebey',
		lat: 54.12913658,
		lng: 54.11870154,
		pop: 57674.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Bashkortostan',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Tuymazy',
		city_ascii: 'Tuymazy',
		lat: 54.6047923,
		lng: 53.69433467,
		pop: 61826,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Bashkortostan',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Neftekamsk',
		city_ascii: 'Neftekamsk',
		lat: 56.08351341,
		lng: 54.26308549,
		pop: 122170,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Bashkortostan',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Troitsk',
		city_ascii: 'Troitsk',
		lat: 54.10564964,
		lng: 61.57023637,
		pop: 69919,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chelyabinsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Yemanzhelinsk',
		city_ascii: 'Yemanzhelinsk',
		lat: 54.74886619,
		lng: 61.29350907,
		pop: 35936,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chelyabinsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Kartaly',
		city_ascii: 'Kartaly',
		lat: 53.04739382,
		lng: 60.6819185,
		pop: 27107,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chelyabinsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Asha',
		city_ascii: 'Asha',
		lat: 54.99799827,
		lng: 57.27261755,
		pop: 35944.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chelyabinsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Miass',
		city_ascii: 'Miass',
		lat: 54.99541445,
		lng: 60.0949259,
		pop: 148834.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chelyabinsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Kyshtym',
		city_ascii: 'Kyshtym',
		lat: 55.69999676,
		lng: 60.5595487,
		pop: 46268,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chelyabinsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Kurtamysh',
		city_ascii: 'Kurtamysh',
		lat: 54.90867556,
		lng: 64.43326575,
		pop: 10006,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kurgan',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Shadrinsk',
		city_ascii: 'Shadrinsk',
		lat: 56.08366844,
		lng: 63.6332629,
		pop: 67303.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kurgan',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Varnek',
		city_ascii: 'Varnek',
		lat: 69.73014813,
		lng: 60.06355831,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Nenets',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Bugrino',
		city_ascii: 'Bugrino',
		lat: 68.80787884,
		lng: 49.30416337,
		pop: 300,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Nenets',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Yamburg',
		city_ascii: 'Yamburg',
		lat: 68.35038739,
		lng: 77.13331742,
		pop: 48488,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Yamal-Nenets',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Nakhodka',
		city_ascii: 'Nakhodka',
		lat: 67.75039817,
		lng: 77.51996049,
		pop: 159551,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Yamal-Nenets',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Sosnogorsk',
		city_ascii: 'Sosnogorsk',
		lat: 63.59476035,
		lng: 53.89432247,
		pop: 24270,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Komi',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Sovetsk',
		city_ascii: 'Sovetsk',
		lat: 57.57846092,
		lng: 48.9580863,
		pop: 10020.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kirov',
		timezone: 'Europe/Kirov',
	},
	{
		city: 'Slobodskoy',
		city_ascii: 'Slobodskoy',
		lat: 58.71849469,
		lng: 50.18803707,
		pop: 40661.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kirov',
		timezone: 'Europe/Kirov',
	},
	{
		city: 'Kirs',
		city_ascii: 'Kirs',
		lat: 59.31383303,
		lng: 52.24517248,
		pop: 8319,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kirov',
		timezone: 'Europe/Kirov',
	},
	{
		city: 'Omutninsk',
		city_ascii: 'Omutninsk',
		lat: 58.6589376,
		lng: 52.1591829,
		pop: 29082,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kirov',
		timezone: 'Europe/Kirov',
	},
	{
		city: 'Kotelnich',
		city_ascii: 'Kotelnich',
		lat: 58.30412722,
		lng: 48.31373287,
		pop: 28015.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kirov',
		timezone: 'Europe/Kirov',
	},
	{
		city: 'Yoshkar Ola',
		city_ascii: 'Yoshkar Ola',
		lat: 56.63539187,
		lng: 47.87494828,
		pop: 301753,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Mariy-El',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kamensk Uralskiy',
		city_ascii: 'Kamensk Uralskiy',
		lat: 56.42046958,
		lng: 61.9350203,
		pop: 176598.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Polevskoy',
		city_ascii: 'Polevskoy',
		lat: 56.44341392,
		lng: 60.18804683,
		pop: 42706,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Tavda',
		city_ascii: 'Tavda',
		lat: 58.05365155,
		lng: 65.25827999,
		pop: 32401,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Artemovskiy',
		city_ascii: 'Artemovskiy',
		lat: 57.36519228,
		lng: 61.86975297,
		pop: 39194.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Nevyansk',
		city_ascii: 'Nevyansk',
		lat: 57.49024925,
		lng: 60.21476355,
		pop: 27035,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Verkhnyaya Salda',
		city_ascii: 'Verkhnyaya Salda',
		lat: 58.05018923,
		lng: 60.54978186,
		pop: 48525,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Nizhnyaya Tura',
		city_ascii: 'Nizhnyaya Tura',
		lat: 58.64364138,
		lng: 59.7982515,
		pop: 56084,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Karpinsk',
		city_ascii: 'Karpinsk',
		lat: 59.76016237,
		lng: 60.00981482,
		pop: 30438.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Ivdel',
		city_ascii: 'Ivdel',
		lat: 60.69364545,
		lng: 60.41325273,
		pop: 11466.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Krasnoufimsk',
		city_ascii: 'Krasnoufimsk',
		lat: 56.59911501,
		lng: 57.7586344,
		pop: 40208.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Sarapul',
		city_ascii: 'Sarapul',
		lat: 56.47914817,
		lng: 53.79872107,
		pop: 92622.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Udmurt',
		timezone: 'Europe/Samara',
	},
	{
		city: 'Mozhga',
		city_ascii: 'Mozhga',
		lat: 56.4547569,
		lng: 52.18434932,
		pop: 43098,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Udmurt',
		timezone: 'Europe/Samara',
	},
	{
		city: 'Votkinsk',
		city_ascii: 'Votkinsk',
		lat: 57.03040651,
		lng: 53.99002722,
		pop: 91248,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Udmurt',
		timezone: 'Europe/Samara',
	},
	{
		city: 'Glazov',
		city_ascii: 'Glazov',
		lat: 58.12320803,
		lng: 52.62876664,
		pop: 93352,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Udmurt',
		timezone: 'Europe/Samara',
	},
	{
		city: 'Kanash',
		city_ascii: 'Kanash',
		lat: 55.50912986,
		lng: 47.46866817,
		pop: 49011.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chuvash',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Shumerlya',
		city_ascii: 'Shumerlya',
		lat: 55.4848161,
		lng: 46.42439083,
		pop: 35225,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chuvash',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Alatyr',
		city_ascii: 'Alatyr',
		lat: 54.8503587,
		lng: 46.59998734,
		pop: 44291.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chuvash',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Sol-lletsk',
		city_ascii: 'Sol-lletsk',
		lat: 51.1602997,
		lng: 54.99988806,
		pop: 25155,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Orenburg',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Dombarovskiy',
		city_ascii: 'Dombarovskiy',
		lat: 50.75458803,
		lng: 59.54002437,
		pop: 5564,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Orenburg',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Mednogorsk',
		city_ascii: 'Mednogorsk',
		lat: 51.41912111,
		lng: 57.57874874,
		pop: 27274,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Orenburg',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Gay',
		city_ascii: 'Gay',
		lat: 51.47472495,
		lng: 58.45430253,
		pop: 39710,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Orenburg',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Buzuluk',
		city_ascii: 'Buzuluk',
		lat: 52.78211285,
		lng: 52.26176062,
		pop: 84762,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Orenburg',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Otradnyy',
		city_ascii: 'Otradnyy',
		lat: 53.37781293,
		lng: 51.34739783,
		pop: 46400,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Samara',
		timezone: 'Europe/Samara',
	},
	{
		city: 'Tolyatti',
		city_ascii: 'Tolyatti',
		lat: 53.48039064,
		lng: 49.53004106,
		pop: 648622,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Samara',
		timezone: 'Europe/Samara',
	},
	{
		city: 'Engels',
		city_ascii: 'Engels',
		lat: 51.50040814,
		lng: 46.12001664,
		pop: 183221,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Saratov',
		timezone: 'Europe/Saratov',
	},
	{
		city: 'Pugachev',
		city_ascii: 'Pugachev',
		lat: 52.01476951,
		lng: 48.79437537,
		pop: 26690,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Saratov',
		timezone: 'Europe/Saratov',
	},
	{
		city: 'Volsk',
		city_ascii: 'Volsk',
		lat: 52.03471661,
		lng: 47.37430701,
		pop: 62027,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Saratov',
		timezone: 'Europe/Saratov',
	},
	{
		city: 'Atkarsk',
		city_ascii: 'Atkarsk',
		lat: 51.87645754,
		lng: 44.99610592,
		pop: 23315.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Saratov',
		timezone: 'Europe/Saratov',
	},
	{
		city: 'Balashov',
		city_ascii: 'Balashov',
		lat: 51.55350568,
		lng: 43.16309119,
		pop: 84107,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Saratov',
		timezone: 'Europe/Saratov',
	},
	{
		city: 'Almetyevsk',
		city_ascii: 'Almetyevsk',
		lat: 54.90040733,
		lng: 52.31994828,
		pop: 117971,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tatarstan',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Chistopol',
		city_ascii: 'Chistopol',
		lat: 55.36477175,
		lng: 50.64067094,
		pop: 52232.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tatarstan',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Nizhnekamsk',
		city_ascii: 'Nizhnekamsk',
		lat: 55.64043968,
		lng: 51.82003048,
		pop: 210363,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tatarstan',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Dimitrovgrad',
		city_ascii: 'Dimitrovgrad',
		lat: 54.25042116,
		lng: 49.56001339,
		pop: 121213.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Ul'yanovsk",
		timezone: 'Europe/Ulyanovsk',
	},
	{
		city: 'Peregrebnoye',
		city_ascii: 'Peregrebnoye',
		lat: 62.96699506,
		lng: 65.08593909,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khanty-Mansiy',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Saranpaul',
		city_ascii: 'Saranpaul',
		lat: 64.25045677,
		lng: 60.97001461,
		pop: 2985,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khanty-Mansiy',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Uray',
		city_ascii: 'Uray',
		lat: 60.14013918,
		lng: 64.75479651,
		pop: 20361,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khanty-Mansiy',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Laryak',
		city_ascii: 'Laryak',
		lat: 61.10119163,
		lng: 80.25136999,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khanty-Mansiy',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Kogalym',
		city_ascii: 'Kogalym',
		lat: 62.04462242,
		lng: 74.49409867,
		pop: 58192,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khanty-Mansiy',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Megion',
		city_ascii: 'Megion',
		lat: 61.06075482,
		lng: 76.0953446,
		pop: 47650.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khanty-Mansiy',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Cherlak',
		city_ascii: 'Cherlak',
		lat: 54.16047833,
		lng: 74.82002193,
		pop: 7060.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Omsk',
		timezone: 'Asia/Omsk',
	},
	{
		city: 'Kalachinsk',
		city_ascii: 'Kalachinsk',
		lat: 55.04866701,
		lng: 74.56825435,
		pop: 20506.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Omsk',
		timezone: 'Asia/Omsk',
	},
	{
		city: 'Nazyvayevsk',
		city_ascii: 'Nazyvayevsk',
		lat: 55.56695579,
		lng: 71.35000118,
		pop: 10938.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Omsk',
		timezone: 'Asia/Omsk',
	},
	{
		city: 'Isikul',
		city_ascii: 'Isikul',
		lat: 54.92870017,
		lng: 71.26824906,
		pop: 21136,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Omsk',
		timezone: 'Asia/Omsk',
	},
	{
		city: 'Ishim',
		city_ascii: 'Ishim',
		lat: 56.15022768,
		lng: 69.44980709,
		pop: 60798,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tyumen',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Golyshmanovo',
		city_ascii: 'Golyshmanovo',
		lat: 56.3819448,
		lng: 68.37147497,
		pop: 8708.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tyumen',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Yalutorovsk',
		city_ascii: 'Yalutorovsk',
		lat: 56.67363243,
		lng: 66.29826819,
		pop: 31580,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tyumen',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Biysk',
		city_ascii: 'Biysk',
		lat: 52.53406598,
		lng: 85.18000972,
		pop: 209796.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Altay',
		timezone: 'Asia/Barnaul',
	},
	{
		city: 'Zmeinogorsk',
		city_ascii: 'Zmeinogorsk',
		lat: 51.15709576,
		lng: 82.19502397,
		pop: 10471.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Altay',
		timezone: 'Asia/Barnaul',
	},
	{
		city: 'Aleysk',
		city_ascii: 'Aleysk',
		lat: 52.49176882,
		lng: 82.77767574,
		pop: 22477.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Altay',
		timezone: 'Asia/Barnaul',
	},
	{
		city: 'Novoaltaysk',
		city_ascii: 'Novoaltaysk',
		lat: 53.39925865,
		lng: 83.95884395,
		pop: 76218,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Altay',
		timezone: 'Asia/Barnaul',
	},
	{
		city: 'Kamenna Obi',
		city_ascii: 'Kamenna Obi',
		lat: 53.7936015,
		lng: 81.33879716,
		pop: 40883,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Altay',
		timezone: 'Asia/Barnaul',
	},
	{
		city: 'Gornyak',
		city_ascii: 'Gornyak',
		lat: 50.98808799,
		lng: 81.48767696,
		pop: 9567,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Altay',
		timezone: 'Asia/Barnaul',
	},
	{
		city: 'Kulunda',
		city_ascii: 'Kulunda',
		lat: 52.58266766,
		lng: 78.94726355,
		pop: 8831.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Altay',
		timezone: 'Asia/Barnaul',
	},
	{
		city: 'Slavgorod',
		city_ascii: 'Slavgorod',
		lat: 53.00494163,
		lng: 78.6695544,
		pop: 27651,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Altay',
		timezone: 'Asia/Barnaul',
	},
	{
		city: 'Tashtagol',
		city_ascii: 'Tashtagol',
		lat: 52.79175051,
		lng: 87.86770097,
		pop: 21902,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kemerovo',
		timezone: 'Asia/Novokuznetsk',
	},
	{
		city: 'Guryevsk',
		city_ascii: 'Guryevsk',
		lat: 54.29814435,
		lng: 85.93768957,
		pop: 31878.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kemerovo',
		timezone: 'Asia/Novokuznetsk',
	},
	{
		city: 'Yurga',
		city_ascii: 'Yurga',
		lat: 55.72575747,
		lng: 84.88540238,
		pop: 72495.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kemerovo',
		timezone: 'Asia/Novokuznetsk',
	},
	{
		city: 'Topki',
		city_ascii: 'Topki',
		lat: 55.28017743,
		lng: 85.61083614,
		pop: 24672,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kemerovo',
		timezone: 'Asia/Novokuznetsk',
	},
	{
		city: 'Mariinsk',
		city_ascii: 'Mariinsk',
		lat: 56.21076662,
		lng: 87.76036902,
		pop: 41344.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kemerovo',
		timezone: 'Asia/Novokuznetsk',
	},
	{
		city: 'Shira',
		city_ascii: 'Shira',
		lat: 54.49144004,
		lng: 89.95305172,
		pop: 4836,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khakass',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Cherepanovo',
		city_ascii: 'Cherepanovo',
		lat: 54.23362632,
		lng: 83.36880245,
		pop: 15094.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Novosibirsk',
		timezone: 'Asia/Novosibirsk',
	},
	{
		city: 'Kargat',
		city_ascii: 'Kargat',
		lat: 55.19594484,
		lng: 80.28113562,
		pop: 5861,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Novosibirsk',
		timezone: 'Asia/Novosibirsk',
	},
	{
		city: 'Ob',
		city_ascii: 'Ob',
		lat: 54.99804995,
		lng: 82.70765417,
		pop: 32093.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Novosibirsk',
		timezone: 'Asia/Novosibirsk',
	},
	{
		city: 'Karasuk',
		city_ascii: 'Karasuk',
		lat: 53.72730064,
		lng: 78.02189368,
		pop: 26758.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Novosibirsk',
		timezone: 'Asia/Novosibirsk',
	},
	{
		city: 'Barabinsk',
		city_ascii: 'Barabinsk',
		lat: 55.35727867,
		lng: 78.35189937,
		pop: 29888.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Novosibirsk',
		timezone: 'Asia/Novosibirsk',
	},
	{
		city: 'Tatarsk',
		city_ascii: 'Tatarsk',
		lat: 55.22193809,
		lng: 75.96651526,
		pop: 24182,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Novosibirsk',
		timezone: 'Asia/Novosibirsk',
	},
	{
		city: 'Kaspiysk',
		city_ascii: 'Kaspiysk',
		lat: 42.87473309,
		lng: 47.62436926,
		pop: 61451.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Dagestan',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Derbent',
		city_ascii: 'Derbent',
		lat: 42.05780621,
		lng: 48.27740434,
		pop: 97259,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Dagestan',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Buynaksk',
		city_ascii: 'Buynaksk',
		lat: 42.8334953,
		lng: 47.11303096,
		pop: 75800,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Dagestan',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Yessey',
		city_ascii: 'Yessey',
		lat: 68.48373842,
		lng: 102.1666215,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Evenk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Ulkan',
		city_ascii: 'Ulkan',
		lat: 55.90039797,
		lng: 107.7833329,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Kirensk',
		city_ascii: 'Kirensk',
		lat: 57.78573509,
		lng: 108.1119433,
		pop: 6759,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Zheleznogorsk Ilimskiy',
		city_ascii: 'Zheleznogorsk Ilimskiy',
		lat: 56.57624819,
		lng: 104.1226778,
		pop: 27291,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Vikhorevka',
		city_ascii: 'Vikhorevka',
		lat: 56.09309938,
		lng: 101.2426985,
		pop: 166,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Biryusinsk',
		city_ascii: 'Biryusinsk',
		lat: 55.96065269,
		lng: 97.81443233,
		pop: 8199.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Kodinskiy',
		city_ascii: 'Kodinskiy',
		lat: 58.69805666,
		lng: 99.17765662,
		pop: 15670,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Artemovsk',
		city_ascii: 'Artemovsk',
		lat: 54.34873557,
		lng: 93.43552649,
		pop: 4948,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Uyar',
		city_ascii: 'Uyar',
		lat: 55.81037762,
		lng: 94.31531775,
		pop: 12370,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Uzhur',
		city_ascii: 'Uzhur',
		lat: 55.32916669,
		lng: 89.82070837,
		pop: 17424.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Sayanogorsk',
		city_ascii: 'Sayanogorsk',
		lat: 53.0894326,
		lng: 91.40040523,
		pop: 52790.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Podkamennaya',
		city_ascii: 'Podkamennaya',
		lat: 61.59950747,
		lng: 90.12363562,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Igarka',
		city_ascii: 'Igarka',
		lat: 67.46710797,
		lng: 86.58333492,
		pop: 6262,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Agapa',
		city_ascii: 'Agapa',
		lat: 71.45037905,
		lng: 89.24999385,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Taymyr',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Boyarka',
		city_ascii: 'Boyarka',
		lat: 70.76698407,
		lng: 97.50003292,
		pop: 35968,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Taymyr',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Nordvik',
		city_ascii: 'Nordvik',
		lat: 74.01650148,
		lng: 111.5100305,
		pop: 0,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Taymyr',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Chelyuskin',
		city_ascii: 'Chelyuskin',
		lat: 77.71697329,
		lng: 104.2500085,
		pop: 885,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Taymyr',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Taksimo',
		city_ascii: 'Taksimo',
		lat: 56.33153444,
		lng: 114.8899792,
		pop: 10359,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Buryat',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Gusinoozyorsk',
		city_ascii: 'Gusinoozyorsk',
		lat: 51.28075747,
		lng: 106.5003621,
		pop: 20498.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Buryat',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Aginskoye',
		city_ascii: 'Aginskoye',
		lat: 51.10306805,
		lng: 114.5228182,
		pop: 11491,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Aga Buryat',
		timezone: 'Asia/Chita',
	},
	{
		city: 'Progress',
		city_ascii: 'Progress',
		lat: 49.75038576,
		lng: 129.6166772,
		pop: 146,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Amur',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Belogorsk',
		city_ascii: 'Belogorsk',
		lat: 50.91909995,
		lng: 128.4637243,
		pop: 69057,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Amur',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Nyukzha',
		city_ascii: 'Nyukzha',
		lat: 56.53121218,
		lng: 121.613083,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Amur',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Nerchinsk',
		city_ascii: 'Nerchinsk',
		lat: 52.00895591,
		lng: 116.548586,
		pop: 11979.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chita',
		timezone: 'Asia/Chita',
	},
	{
		city: 'Kavalerovo',
		city_ascii: 'Kavalerovo',
		lat: 44.27020347,
		lng: 135.0497823,
		pop: 17801,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Primor'ye",
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Spassk Dalniy',
		city_ascii: 'Spassk Dalniy',
		lat: 44.60015749,
		lng: 132.8197892,
		pop: 44861,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Primor'ye",
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Shalaurova',
		city_ascii: 'Shalaurova',
		lat: 73.22037437,
		lng: 143.1833427,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Logashkino',
		city_ascii: 'Logashkino',
		lat: 70.85038983,
		lng: 153.9000012,
		pop: 0,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Srednekolymsk',
	},
	{
		city: 'Ust Kuyga',
		city_ascii: 'Ust Kuyga',
		lat: 70.01705568,
		lng: 135.6000329,
		pop: 1517,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Pokrovsk',
		city_ascii: 'Pokrovsk',
		lat: 61.49344159,
		lng: 129.1272497,
		pop: 8065.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Verkhnevilyuysk',
		city_ascii: 'Verkhnevilyuysk',
		lat: 63.44569969,
		lng: 120.3167281,
		pop: 6341,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Vitim',
		city_ascii: 'Vitim',
		lat: 59.45149904,
		lng: 112.5578218,
		pop: 3843,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Olyokminsk',
		city_ascii: 'Olyokminsk',
		lat: 60.50319195,
		lng: 120.3925891,
		pop: 9743.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Tunguskhaya',
		city_ascii: 'Tunguskhaya',
		lat: 64.90044293,
		lng: 125.2500187,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Natara',
		city_ascii: 'Natara',
		lat: 68.41061627,
		lng: 123.9255,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Zhilinda',
		city_ascii: 'Zhilinda',
		lat: 70.13368939,
		lng: 114.0000077,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Trofimovsk',
		city_ascii: 'Trofimovsk',
		lat: 72.59968874,
		lng: 127.0336824,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Tukchi',
		city_ascii: 'Tukchi',
		lat: 57.36697512,
		lng: 139.5000016,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khabarovsk',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Amursk',
		city_ascii: 'Amursk',
		lat: 50.22283754,
		lng: 136.8974214,
		pop: 45901.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khabarovsk',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Bikin',
		city_ascii: 'Bikin',
		lat: 46.82025454,
		lng: 134.2649206,
		pop: 19264.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khabarovsk',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Vyazemskiy',
		city_ascii: 'Vyazemskiy',
		lat: 47.5328467,
		lng: 134.7474751,
		pop: 14191,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khabarovsk',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Chegdomyn',
		city_ascii: 'Chegdomyn',
		lat: 51.11779584,
		lng: 133.0241178,
		pop: 7485.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khabarovsk',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Siglan',
		city_ascii: 'Siglan',
		lat: 59.03372093,
		lng: 152.4165775,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Maga Buryatdan',
		timezone: 'Asia/Magadan',
	},
	{
		city: 'Karamken',
		city_ascii: 'Karamken',
		lat: 60.20041974,
		lng: 151.166628,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Maga Buryatdan',
		timezone: 'Asia/Magadan',
	},
	{
		city: 'Strelka',
		city_ascii: 'Strelka',
		lat: 61.86698468,
		lng: 152.2501794,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Maga Buryatdan',
		timezone: 'Asia/Magadan',
	},
	{
		city: 'Severo Kurilsk',
		city_ascii: 'Severo Kurilsk',
		lat: 50.69048342,
		lng: 156.0850358,
		pop: 2422,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakhalin',
		timezone: 'Asia/Srednekolymsk',
	},
	{
		city: 'Krasnogorsk',
		city_ascii: 'Krasnogorsk',
		lat: 48.4615497,
		lng: 142.0899727,
		pop: 3304,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakhalin',
		timezone: 'Asia/Sakhalin',
	},
	{
		city: 'Poronaysk',
		city_ascii: 'Poronaysk',
		lat: 49.24155377,
		lng: 143.0850024,
		pop: 15555,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakhalin',
		timezone: 'Asia/Sakhalin',
	},
	{
		city: 'Makarov',
		city_ascii: 'Makarov',
		lat: 48.63373557,
		lng: 142.8000069,
		pop: 4571.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakhalin',
		timezone: 'Asia/Sakhalin',
	},
	{
		city: 'Dolinsk',
		city_ascii: 'Dolinsk',
		lat: 47.35042889,
		lng: 142.8000069,
		pop: 11791.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakhalin',
		timezone: 'Asia/Sakhalin',
	},
	{
		city: 'Nevelsk',
		city_ascii: 'Nevelsk',
		lat: 46.68028892,
		lng: 141.8699344,
		pop: 16754.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakhalin',
		timezone: 'Asia/Sakhalin',
	},
	{
		city: 'Kudymkar',
		city_ascii: 'Kudymkar',
		lat: 59.01617678,
		lng: 54.63303707,
		pop: 32009.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Komi-Permyak',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Kungur',
		city_ascii: 'Kungur',
		lat: 57.4347746,
		lng: 56.95434241,
		pop: 59911.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Perm',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Krasnokamsk',
		city_ascii: 'Krasnokamsk',
		lat: 58.07473553,
		lng: 55.74428707,
		pop: 50382,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Perm',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Chusovoy',
		city_ascii: 'Chusovoy',
		lat: 58.2934302,
		lng: 57.81304968,
		pop: 61159,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Perm',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Gubakha',
		city_ascii: 'Gubakha',
		lat: 58.86913149,
		lng: 57.58872229,
		pop: 18716.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Perm',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Utkholok',
		city_ascii: 'Utkholok',
		lat: 57.55040061,
		lng: 157.2333378,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kamchatka',
		timezone: 'Asia/Kamchatka',
	},
	{
		city: "Bol'sheretsk",
		city_ascii: "Bol'sheretsk",
		lat: 52.43898134,
		lng: 156.3593859,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kamchatka',
		timezone: 'Asia/Kamchatka',
	},
	{
		city: "Il'pyrskiy",
		city_ascii: "Il'pyrskiy",
		lat: 59.96738829,
		lng: 164.1720053,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kamchatka',
		timezone: 'Asia/Kamchatka',
	},
	{
		city: 'Korf',
		city_ascii: 'Korf',
		lat: 60.33206545,
		lng: 165.8183435,
		pop: 400,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kamchatka',
		timezone: 'Asia/Kamchatka',
	},
	{
		city: 'Kolpashevo',
		city_ascii: 'Kolpashevo',
		lat: 58.30040651,
		lng: 82.99538855,
		pop: 27876,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tomsk',
		timezone: 'Asia/Tomsk',
	},
	{
		city: 'Omolon',
		city_ascii: 'Omolon',
		lat: 65.24998232,
		lng: 160.5000118,
		pop: 1050,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chukchi Autonomous Okrug',
		timezone: 'Asia/Anadyr',
	},
	{
		city: 'Pevek',
		city_ascii: 'Pevek',
		lat: 69.70082176,
		lng: 170.3133146,
		pop: 4837,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chukchi Autonomous Okrug',
		timezone: 'Asia/Anadyr',
	},
	{
		city: 'Umba',
		city_ascii: 'Umba',
		lat: 66.68139366,
		lng: 34.34554154,
		pop: 6128,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Murmansk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kovda',
		city_ascii: 'Kovda',
		lat: 66.690282,
		lng: 32.87023108,
		pop: 20,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Murmansk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Velikiy Novgorod',
		city_ascii: 'Velikiy Novgorod',
		lat: 58.4999809,
		lng: 31.33001501,
		pop: 218717,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Novgorod',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Velikiye Luki',
		city_ascii: 'Velikiye Luki',
		lat: 56.31995892,
		lng: 30.52003861,
		pop: 93243,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Pskov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Belomorsk',
		city_ascii: 'Belomorsk',
		lat: 64.52522036,
		lng: 34.76582597,
		pop: 12165,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Karelia',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kem',
		city_ascii: 'Kem',
		lat: 64.95359214,
		lng: 34.56837032,
		pop: 13829,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Karelia',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Krasino',
		city_ascii: 'Krasino',
		lat: 70.73144845,
		lng: 54.420799,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Arkhangel'sk",
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Matochkin Shar',
		city_ascii: 'Matochkin Shar',
		lat: 73.26999163,
		lng: 56.44969682,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Arkhangel'sk",
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Severodvinsk',
		city_ascii: 'Severodvinsk',
		lat: 64.57002382,
		lng: 39.83001298,
		pop: 182077.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Arkhangel'sk",
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kursk',
		city_ascii: 'Kursk',
		lat: 51.73998008,
		lng: 36.19002844,
		pop: 398742,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kursk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Tula',
		city_ascii: 'Tula',
		lat: 54.19995913,
		lng: 37.62994055,
		pop: 479155.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tula',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Tambov',
		city_ascii: 'Tambov',
		lat: 52.73002301,
		lng: 41.43001868,
		pop: 296207.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tambov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Sterlitamak',
		city_ascii: 'Sterlitamak',
		lat: 53.62999392,
		lng: 55.96003617,
		pop: 220040,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Bashkortostan',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Kurgan',
		city_ascii: 'Kurgan',
		lat: 55.45995974,
		lng: 65.34499304,
		pop: 329399.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kurgan',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Indiga',
		city_ascii: 'Indiga',
		lat: 67.68975404,
		lng: 49.01659483,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Nenets',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Shoyna',
		city_ascii: 'Shoyna',
		lat: 67.86664431,
		lng: 44.13336788,
		pop: 300,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Nenets',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Novy Port',
		city_ascii: 'Novyy Port',
		lat: 67.69833417,
		lng: 72.92830277,
		pop: 1790,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Yamal-Nenets',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Salekhard',
		city_ascii: 'Salekhard',
		lat: 66.53502016,
		lng: 66.60998043,
		pop: 32427,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Yamal-Nenets',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Gyda',
		city_ascii: 'Gyda',
		lat: 70.88142153,
		lng: 78.46610429,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Yamal-Nenets',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Tazovskiy',
		city_ascii: 'Tazovskiy',
		lat: 67.46666872,
		lng: 78.69999182,
		pop: 5981,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Yamal-Nenets',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Novy Urengoy',
		city_ascii: 'Novy Urengoy',
		lat: 66.08331647,
		lng: 76.63324459,
		pop: 47357.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Yamal-Nenets',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Nadym',
		city_ascii: 'Nadym',
		lat: 65.5298102,
		lng: 72.51478796,
		pop: 26723,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Yamal-Nenets',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Noyabrsk',
		city_ascii: 'Noyabrsk',
		lat: 63.1665436,
		lng: 75.61651078,
		pop: 110572,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Yamal-Nenets',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Syktyvkar',
		city_ascii: 'Syktyvkar',
		lat: 61.65999473,
		lng: 50.81998816,
		pop: 230524.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Komi',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Ukhta',
		city_ascii: 'Ukhta',
		lat: 63.55998212,
		lng: 53.68999385,
		pop: 96396.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Komi',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Serov',
		city_ascii: 'Serov',
		lat: 59.61497744,
		lng: 60.58497351,
		pop: 91831,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Cheboksary',
		city_ascii: 'Cheboksary',
		lat: 56.12997052,
		lng: 47.25002519,
		pop: 444027.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chuvash',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Orsk',
		city_ascii: 'Orsk',
		lat: 51.21001243,
		lng: 58.62731523,
		pop: 159353,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Orenburg',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Balakovo',
		city_ascii: 'Balakovo',
		lat: 52.02998822,
		lng: 47.80001745,
		pop: 172821.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Saratov',
		timezone: 'Europe/Saratov',
	},
	{
		city: 'Igrim',
		city_ascii: 'Igrim',
		lat: 63.19331199,
		lng: 64.41941646,
		pop: 9545,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khanty-Mansiy',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Nyagan',
		city_ascii: 'Nyagan',
		lat: 62.14652834,
		lng: 65.38142493,
		pop: 46238.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khanty-Mansiy',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Khanty Mansiysk',
		city_ascii: 'Khanty Mansiysk',
		lat: 61.00153363,
		lng: 69.00151404,
		pop: 48114,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khanty-Mansiy',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Nizhenvartovsk',
		city_ascii: 'Nizhenvartovsk',
		lat: 60.93497438,
		lng: 76.58001786,
		pop: 136385,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khanty-Mansiy',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Numto',
		city_ascii: 'Numto',
		lat: 63.66671979,
		lng: 71.33330969,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khanty-Mansiy',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Tara',
		city_ascii: 'Tara',
		lat: 56.89824404,
		lng: 74.37824011,
		pop: 24130.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Omsk',
		timezone: 'Asia/Omsk',
	},
	{
		city: 'Tobolsk',
		city_ascii: 'Tobolsk',
		lat: 58.1997925,
		lng: 68.26481482,
		pop: 87877.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tyumen',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Rubtsovsk',
		city_ascii: 'Rubtsovsk',
		lat: 51.52001935,
		lng: 81.21001949,
		pop: 159133,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Altay',
		timezone: 'Asia/Barnaul',
	},
	{
		city: 'Gorno Altaysk',
		city_ascii: 'Gorno Altaysk',
		lat: 51.96133608,
		lng: 85.95768835,
		pop: 57392.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Gorno-Altay',
		timezone: 'Asia/Barnaul',
	},
	{
		city: 'Prokopyevsk',
		city_ascii: 'Prokopyevsk',
		lat: 53.89997744,
		lng: 86.70999385,
		pop: 242547,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kemerovo',
		timezone: 'Asia/Novokuznetsk',
	},
	{
		city: 'Makhachkala',
		city_ascii: 'Makhachkala',
		lat: 42.98002382,
		lng: 47.49998409,
		pop: 526470,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Dagestan',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Tura',
		city_ascii: 'Tura',
		lat: 64.28329714,
		lng: 100.2500459,
		pop: 5444,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Evenk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Noginsk',
		city_ascii: 'Noginsk',
		lat: 64.48331077,
		lng: 91.23333533,
		pop: 229731,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Evenk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Yerema',
		city_ascii: 'Yerema',
		lat: 60.38079632,
		lng: 107.7794055,
		pop: 745,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Tayshet',
		city_ascii: 'Tayshet',
		lat: 55.92770896,
		lng: 97.98770341,
		pop: 44975,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Usolye Sibirskoye',
		city_ascii: 'Usolye Sibirskoye',
		lat: 52.76498212,
		lng: 103.6449808,
		pop: 85012,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Slyudyanka',
		city_ascii: 'Slyudyanka',
		lat: 51.65383547,
		lng: 103.6988277,
		pop: 14920.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Cheremkhovo',
		city_ascii: 'Cheremkhovo',
		lat: 53.15880821,
		lng: 103.0738529,
		pop: 51686.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Zima',
		city_ascii: 'Zima',
		lat: 53.93305035,
		lng: 102.0330896,
		pop: 46781,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Tulun',
		city_ascii: 'Tulun',
		lat: 54.56533734,
		lng: 100.5653755,
		pop: 48407,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Nizhneudinsk',
		city_ascii: 'Nizhneudinsk',
		lat: 54.89766848,
		lng: 99.02769161,
		pop: 41024.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Ust Kut',
		city_ascii: 'Ust Kut',
		lat: 56.76497052,
		lng: 105.7599939,
		pop: 25388,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Bodaybo',
		city_ascii: 'Bodaybo',
		lat: 57.96497479,
		lng: 114.3289799,
		pop: 15933,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Komsa',
		city_ascii: 'Komsa',
		lat: 61.86804405,
		lng: 89.25774532,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Kansk',
		city_ascii: 'Kansk',
		lat: 56.19001853,
		lng: 95.71001298,
		pop: 94420.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Achinsk',
		city_ascii: 'Achinsk',
		lat: 56.26998781,
		lng: 90.49999508,
		pop: 112541.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Yeniseysk',
		city_ascii: 'Yeniseysk',
		lat: 58.4515084,
		lng: 92.15653479,
		pop: 15407.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Lesosibirsk',
		city_ascii: 'Lesosibirsk',
		lat: 58.24325238,
		lng: 92.48328487,
		pop: 65629,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Turukhansk',
		city_ascii: 'Turukhansk',
		lat: 65.83816347,
		lng: 87.95498246,
		pop: 4774,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Vorontsovo',
		city_ascii: 'Vorontsovo',
		lat: 71.69832257,
		lng: 83.56419104,
		pop: 100,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Taymyr',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Starorybnoye',
		city_ascii: 'Starorybnoye',
		lat: 72.76660362,
		lng: 104.8000008,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Taymyr',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Mikhaylova',
		city_ascii: 'Mikhaylova',
		lat: 75.09498863,
		lng: 86.98243201,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Taymyr',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Dudinka',
		city_ascii: 'Dudinka',
		lat: 69.41820335,
		lng: 86.22501054,
		pop: 22913,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Taymyr',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Teli',
		city_ascii: 'Teli',
		lat: 51.03330487,
		lng: 90.23329301,
		pop: 3732,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tuva',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Novyy Uoyin',
		city_ascii: 'Novyy Uoyin',
		lat: 56.13498313,
		lng: 111.7338928,
		pop: 4184,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Buryat',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Bagdarin',
		city_ascii: 'Bagdarin',
		lat: 54.43330406,
		lng: 113.6000321,
		pop: 4676,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Buryat',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Severobaykalsk',
		city_ascii: 'Severobaykalsk',
		lat: 55.63400596,
		lng: 109.3129553,
		pop: 25800,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Buryat',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Kyakhta',
		city_ascii: 'Kyakhta',
		lat: 50.35267458,
		lng: 106.4526648,
		pop: 12368,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Buryat',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Svobodnyy',
		city_ascii: 'Svobodnyy',
		lat: 51.40617617,
		lng: 128.1311865,
		pop: 62318.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Amur',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Zeya',
		city_ascii: 'Zeya',
		lat: 53.75001243,
		lng: 127.2665881,
		pop: 26999,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Amur',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Magdagachi',
		city_ascii: 'Magdagachi',
		lat: 53.44997906,
		lng: 125.8000109,
		pop: 8070.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Amur',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Shimanovsk',
		city_ascii: 'Shimanovsk',
		lat: 52.00244468,
		lng: 127.6974662,
		pop: 16574.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Amur',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Skovorodino',
		city_ascii: 'Skovorodino',
		lat: 53.98330568,
		lng: 123.9166634,
		pop: 6485.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Amur',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Tynda',
		city_ascii: 'Tynda',
		lat: 55.17426658,
		lng: 124.7075712,
		pop: 33187,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Amur',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Olovyannaya',
		city_ascii: 'Olovyannaya',
		lat: 50.94997662,
		lng: 115.5666304,
		pop: 5281.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chita',
		timezone: 'Asia/Chita',
	},
	{
		city: 'Mogocha',
		city_ascii: 'Mogocha',
		lat: 53.73332094,
		lng: 119.7665808,
		pop: 10636.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chita',
		timezone: 'Asia/Chita',
	},
	{
		city: 'Krasnokamensk',
		city_ascii: 'Krasnokamensk',
		lat: 50.0664905,
		lng: 118.0264803,
		pop: 52308,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chita',
		timezone: 'Asia/Chita',
	},
	{
		city: 'Borzya',
		city_ascii: 'Borzya',
		lat: 50.38856386,
		lng: 116.518562,
		pop: 29653,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chita',
		timezone: 'Asia/Chita',
	},
	{
		city: 'Khilok',
		city_ascii: 'Khilok',
		lat: 51.35000389,
		lng: 110.4500435,
		pop: 6540.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chita',
		timezone: 'Asia/Chita',
	},
	{
		city: 'Nakhodka',
		city_ascii: 'Nakhodka',
		lat: 42.83744855,
		lng: 132.8874336,
		pop: 153235.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Primor'ye",
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Ussuriysk',
		city_ascii: 'Ussuriysk',
		lat: 43.80002545,
		lng: 132.019993,
		pop: 140673.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Primor'ye",
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Lesozavodsk',
		city_ascii: 'Lesozavodsk',
		lat: 45.47475527,
		lng: 133.4297778,
		pop: 39241.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Primor'ye",
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Kavache',
		city_ascii: 'Kavache',
		lat: 70.73329104,
		lng: 136.2166361,
		pop: -99,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Verkhoyansk',
		city_ascii: 'Verkhoyansk',
		lat: 67.54470013,
		lng: 133.3849743,
		pop: 1388,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Cherskiy',
		city_ascii: 'Cherskiy',
		lat: 68.75005292,
		lng: 161.3300386,
		pop: 3707,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Srednekolymsk',
	},
	{
		city: 'Srednekolymsk',
		city_ascii: 'Srednekolymsk',
		lat: 67.45000307,
		lng: 153.7100386,
		pop: 3459,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Srednekolymsk',
	},
	{
		city: 'Zyryanka',
		city_ascii: 'Zyryanka',
		lat: 65.73597333,
		lng: 150.890004,
		pop: 3627,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Srednekolymsk',
	},
	{
		city: 'Eldikan',
		city_ascii: 'Eldikan',
		lat: 60.80002138,
		lng: 135.1833142,
		pop: 1516,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Khandyga',
	},
	{
		city: 'Chagda',
		city_ascii: 'Chagda',
		lat: 60.10001243,
		lng: 133.8999816,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Khandyga',
	},
	{
		city: 'Khandyga',
		city_ascii: 'Khandyga',
		lat: 62.66600568,
		lng: 135.6000329,
		pop: 6796,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Khandyga',
	},
	{
		city: 'Ust Maya',
		city_ascii: 'Ust Maya',
		lat: 60.45657981,
		lng: 134.5433016,
		pop: 3062,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Khandyga',
	},
	{
		city: 'Neryungri',
		city_ascii: 'Neryungri',
		lat: 56.67404584,
		lng: 124.7103617,
		pop: 33364,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Chernyshevskiy',
		city_ascii: 'Chernyshevskiy',
		lat: 63.01275454,
		lng: 112.4714188,
		pop: 5137,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Terbyas',
		city_ascii: 'Terbyas',
		lat: 64.37608217,
		lng: 120.5468949,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Vilyuysk',
		city_ascii: 'Vilyuysk',
		lat: 63.75526735,
		lng: 121.6247619,
		pop: 5543.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Sangar',
		city_ascii: 'Sangar',
		lat: 63.92414593,
		lng: 127.4739139,
		pop: 4633,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Menkere',
		city_ascii: 'Menkere',
		lat: 67.98860069,
		lng: 123.3504964,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Saskylakh',
		city_ascii: 'Saskylakh',
		lat: 71.91662966,
		lng: 114.0833101,
		pop: 1920,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Govorovo',
		city_ascii: 'Govorovo',
		lat: 70.21135907,
		lng: 125.9932808,
		pop: 943,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Sagastyr',
		city_ascii: 'Sagastyr',
		lat: 73.37793312,
		lng: 126.5923521,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Ust Olensk',
		city_ascii: 'Ust Olensk',
		lat: 72.99778302,
		lng: 119.7729295,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Suntar',
		city_ascii: 'Suntar',
		lat: 62.14440961,
		lng: 117.6319307,
		pop: 4716,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Olenek',
		city_ascii: 'Olenek',
		lat: 68.52500205,
		lng: 112.4500248,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Udachnyy',
		city_ascii: 'Udachnyy',
		lat: 66.42316652,
		lng: 112.3965397,
		pop: 15266,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Birobidzhan',
		city_ascii: 'Birobidzhan',
		lat: 48.79742067,
		lng: 132.9507889,
		pop: 75022.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Yevrey',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Khakhar',
		city_ascii: 'Khakhar',
		lat: 57.66659507,
		lng: 135.4300175,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khabarovsk',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'De Kastri',
		city_ascii: 'De Kastri',
		lat: 51.46658592,
		lng: 140.7833341,
		pop: 3615,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khabarovsk',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Chumikan',
		city_ascii: 'Chumikan',
		lat: 54.7113945,
		lng: 135.3145141,
		pop: 1305,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khabarovsk',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Komsomolsk na Amure',
		city_ascii: 'Komsomolsk na Amure',
		lat: 50.55498781,
		lng: 137.0199979,
		pop: 264374,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khabarovsk',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Ayan',
		city_ascii: 'Ayan',
		lat: 56.45424113,
		lng: 138.1673305,
		pop: 1286,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khabarovsk',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Nikolayevsk na Amure',
		city_ascii: 'Nikolayevsk na Amure',
		lat: 53.14963564,
		lng: 140.730004,
		pop: 27113,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khabarovsk',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Savetskaya Gavan',
		city_ascii: 'Savetskaya Gavan',
		lat: 48.96989077,
		lng: 140.2748897,
		pop: 27882,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khabarovsk',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Evensk',
		city_ascii: 'Evensk',
		lat: 61.94997703,
		lng: 159.2333191,
		pop: 2024,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Maga Buryatdan',
		timezone: 'Asia/Magadan',
	},
	{
		city: 'Palatka',
		city_ascii: 'Palatka',
		lat: 60.10001243,
		lng: 150.8999776,
		pop: 12993,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Maga Buryatdan',
		timezone: 'Asia/Magadan',
	},
	{
		city: 'Omsukchan',
		city_ascii: 'Omsukchan',
		lat: 62.53327476,
		lng: 155.8000402,
		pop: 4201,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Maga Buryatdan',
		timezone: 'Asia/Magadan',
	},
	{
		city: 'Susuman',
		city_ascii: 'Susuman',
		lat: 62.78333701,
		lng: 148.1667594,
		pop: 7367,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Maga Buryatdan',
		timezone: 'Asia/Magadan',
	},
	{
		city: 'Nogliki',
		city_ascii: 'Nogliki',
		lat: 51.83328188,
		lng: 143.1667028,
		pop: 10098,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakhalin',
		timezone: 'Asia/Sakhalin',
	},
	{
		city: 'Aleksandrovsk Sakhalinskiy',
		city_ascii: 'Aleksandrovsk Sakhalinskiy',
		lat: 50.89749921,
		lng: 142.1561185,
		pop: 9263.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakhalin',
		timezone: 'Asia/Sakhalin',
	},
	{
		city: 'Korsakov',
		city_ascii: 'Korsakov',
		lat: 46.64243593,
		lng: 142.777476,
		pop: 33165.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakhalin',
		timezone: 'Asia/Sakhalin',
	},
	{
		city: 'Manily',
		city_ascii: 'Manily',
		lat: 62.54999208,
		lng: 165.3000288,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kamchatka',
		timezone: 'Asia/Kamchatka',
	},
	{
		city: 'Oktyabrskiy',
		city_ascii: 'Oktyabrskiy',
		lat: 52.66359296,
		lng: 156.2387215,
		pop: 67386,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kamchatka',
		timezone: 'Asia/Kamchatka',
	},
	{
		city: 'Klyuchi',
		city_ascii: 'Klyuchi',
		lat: 56.29996014,
		lng: 160.8500162,
		pop: 1089,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kamchatka',
		timezone: 'Asia/Kamchatka',
	},
	{
		city: 'Ust Kamchatsk',
		city_ascii: 'Ust Kamchatsk',
		lat: 56.21350547,
		lng: 162.4349841,
		pop: 4939,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kamchatka',
		timezone: 'Asia/Kamchatka',
	},
	{
		city: 'Provideniya',
		city_ascii: 'Provideniya',
		lat: 64.4234953,
		lng: -173.2257937,
		pop: 1746.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chukchi Autonomous Okrug',
		timezone: 'Asia/Anadyr',
	},
	{
		city: 'Uelen',
		city_ascii: 'Uelen',
		lat: 66.15413931,
		lng: -169.8105835,
		pop: 776,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chukchi Autonomous Okrug',
		timezone: 'Asia/Anadyr',
	},
	{
		city: 'Kandalaksha',
		city_ascii: 'Kandalaksha',
		lat: 67.16433575,
		lng: 32.41439327,
		pop: 72614.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Murmansk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Vyborg',
		city_ascii: 'Vyborg',
		lat: 60.70387738,
		lng: 28.75492672,
		pop: 97917,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Leningrad',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kondopoga',
		city_ascii: 'Kondopoga',
		lat: 62.20872093,
		lng: 34.28869747,
		pop: 31952,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Karelia',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Rusanovo',
		city_ascii: 'Rusanovo',
		lat: 70.59807362,
		lng: 56.37229099,
		pop: 10,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Arkhangel'sk",
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Mezen',
		city_ascii: 'Mezen',
		lat: 65.85221946,
		lng: 44.24002803,
		pop: 2460.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Arkhangel'sk",
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Velsk',
		city_ascii: 'Velsk',
		lat: 61.06739524,
		lng: 42.0974198,
		pop: 25729,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Arkhangel'sk",
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kotlas',
		city_ascii: 'Kotlas',
		lat: 61.26306805,
		lng: 46.66308427,
		pop: 59529.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Arkhangel'sk",
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Onega',
		city_ascii: 'Onega',
		lat: 63.92714317,
		lng: 38.0771484,
		pop: 20447.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Arkhangel'sk",
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Ivanovo',
		city_ascii: 'Ivanovo',
		lat: 57.01002016,
		lng: 41.00999263,
		pop: 417527,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Ivanovo',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kostroma',
		city_ascii: 'Kostroma',
		lat: 57.77002545,
		lng: 40.94002274,
		pop: 256955.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kostroma',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Velikiy Ustyug',
		city_ascii: 'Velikiy Ustyug',
		lat: 60.76870546,
		lng: 46.29866207,
		pop: 32939.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Vologda',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Lipetsk',
		city_ascii: 'Lipetsk',
		lat: 52.62000389,
		lng: 39.63999874,
		pop: 502144,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Lipetsk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Orel',
		city_ascii: 'Orel',
		lat: 52.96995668,
		lng: 36.06998409,
		pop: 329376,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Orel',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Volgodonsk',
		city_ascii: 'Volgodonsk',
		lat: 47.50997988,
		lng: 42.15994828,
		pop: 122434.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Rostov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Belgorod',
		city_ascii: 'Belgorod',
		lat: 50.62999615,
		lng: 36.5999259,
		pop: 328004.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Belgorod',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Novorossiysk',
		city_ascii: 'Novorossiysk',
		lat: 44.72996869,
		lng: 37.76993201,
		pop: 229927,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnodar',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Vladimir',
		city_ascii: 'Vladimir',
		lat: 56.12997052,
		lng: 40.4099259,
		pop: 314336,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Vladimir',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Birsk',
		city_ascii: 'Birsk',
		lat: 55.42438051,
		lng: 55.54435095,
		pop: 33903,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Bashkortostan',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Zlatoust',
		city_ascii: 'Zlatoust',
		lat: 55.17499005,
		lng: 59.64999182,
		pop: 176285,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chelyabinsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Amderma',
		city_ascii: 'Amderma',
		lat: 69.76301434,
		lng: 61.66769812,
		pop: 282,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Nenets',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Naryan Mar',
		city_ascii: 'Naryan Mar',
		lat: 67.64740704,
		lng: 53.05742265,
		pop: 19849.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Nenets',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Inta',
		city_ascii: 'Inta',
		lat: 66.03742779,
		lng: 60.16742794,
		pop: 12410.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Komi',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Usinsk',
		city_ascii: 'Usinsk',
		lat: 65.92304201,
		lng: 57.40299719,
		pop: 42913.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Komi',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Pechora',
		city_ascii: 'Pechora',
		lat: 65.15874758,
		lng: 57.20874548,
		pop: 45957,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Komi',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Pervouralsk',
		city_ascii: 'Pervouralsk',
		lat: 56.91002626,
		lng: 59.9550378,
		pop: 127236,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Izhevsk',
		city_ascii: 'Izhevsk',
		lat: 56.85002993,
		lng: 53.23002193,
		pop: 611230,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Udmurt',
		timezone: 'Europe/Samara',
	},
	{
		city: 'Akhtubinsk',
		city_ascii: 'Akhtubinsk',
		lat: 48.27871848,
		lng: 46.16869584,
		pop: 38179,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Astrakhan',
		timezone: 'Europe/Astrakhan',
	},
	{
		city: 'Elista',
		city_ascii: 'Elista',
		lat: 46.32865664,
		lng: 44.20871212,
		pop: 99728,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kalmyk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Krasnoarmeysk',
		city_ascii: 'Krasnoarmeysk',
		lat: 51.01738853,
		lng: 45.69740678,
		pop: 20625.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Saratov',
		timezone: 'Europe/Saratov',
	},
	{
		city: 'Berezniki',
		city_ascii: 'Berezniki',
		lat: 59.42000226,
		lng: 56.75998734,
		pop: 153305,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Perm',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Naltchik',
		city_ascii: 'Naltchik',
		lat: 43.4981059,
		lng: 43.61794714,
		pop: 290333,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kabardin-Balkar',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Stavropol',
		city_ascii: 'Stavropol',
		lat: 45.05000083,
		lng: 41.98001094,
		pop: 350795.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Stavropol',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Ugolnye Kopi',
		city_ascii: 'Ugolnyye Kopi',
		lat: 64.73329551,
		lng: 177.6999955,
		pop: 3367,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chukchi Autonomous Okrug',
		timezone: 'Asia/Anadyr',
	},
	{
		city: 'Kaliningrad',
		city_ascii: 'Kaliningrad',
		lat: 54.70000612,
		lng: 20.49734289,
		pop: 403226.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kaliningrad',
		timezone: 'Europe/Kaliningrad',
	},
	{
		city: 'Pskov',
		city_ascii: 'Pskov',
		lat: 57.82999595,
		lng: 28.32993974,
		pop: 189979.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Pskov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Bryansk',
		city_ascii: 'Bryansk',
		lat: 53.25999066,
		lng: 34.42998083,
		pop: 426510,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Bryansk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Smolensk',
		city_ascii: 'Smolensk',
		lat: 54.78268841,
		lng: 32.04733557,
		pop: 311954.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Smolensk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Petrozavodsk',
		city_ascii: 'Petrozavodsk',
		lat: 61.84998313,
		lng: 34.28001583,
		pop: 248350.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Karelia',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Tver',
		city_ascii: 'Tver',
		lat: 56.85997764,
		lng: 35.88999508,
		pop: 382043,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tver',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Vologda',
		city_ascii: 'Vologda',
		lat: 59.20998924,
		lng: 39.91998165,
		pop: 251692,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Vologda',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Yaroslavl',
		city_ascii: 'Yaroslavl',
		lat: 57.61998293,
		lng: 39.87001054,
		pop: 571154,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Yaroslavl',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Rostov',
		city_ascii: 'Rostov',
		lat: 47.23464785,
		lng: 39.7126558,
		pop: 1032567,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Rostov',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Sochi',
		city_ascii: 'Sochi',
		lat: 43.59001243,
		lng: 39.72996741,
		pop: 326639,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnodar',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Krasnodar',
		city_ascii: 'Krasnodar',
		lat: 45.01997683,
		lng: 39.0000378,
		pop: 601191.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnodar',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Penza',
		city_ascii: 'Penza',
		lat: 53.18002138,
		lng: 44.99998165,
		pop: 491943,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Penza',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Ryazan',
		city_ascii: 'Ryazan',
		lat: 54.61995933,
		lng: 39.71999385,
		pop: 502373,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Ryazan',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Voronezh',
		city_ascii: 'Voronezh',
		lat: 51.72998069,
		lng: 39.26999548,
		pop: 569734.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Voronezh',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Magnitogorsk',
		city_ascii: 'Magnitogorsk',
		lat: 53.42269391,
		lng: 58.98000688,
		pop: 308724.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chelyabinsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Chelyabinsk',
		city_ascii: 'Chelyabinsk',
		lat: 55.15499127,
		lng: 61.43866817,
		pop: 1018802,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chelyabinsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Vorkuta',
		city_ascii: 'Vorkuta',
		lat: 67.50000002,
		lng: 64.00998409,
		pop: 71261.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Komi',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Kirov',
		city_ascii: 'Kirov',
		lat: 58.59005292,
		lng: 49.66998083,
		pop: 457410,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kirov',
		timezone: 'Europe/Kirov',
	},
	{
		city: 'Nizhny Tagil',
		city_ascii: 'Nizhny Tagil',
		lat: 57.9200163,
		lng: 59.9749849,
		pop: 374343.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Astrakhan',
		city_ascii: 'Astrakhan',
		lat: 46.34865541,
		lng: 48.05498897,
		pop: 493363.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Astrakhan',
		timezone: 'Europe/Astrakhan',
	},
	{
		city: 'Orenburg',
		city_ascii: 'Orenburg',
		lat: 51.77997764,
		lng: 55.11001054,
		pop: 530820.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Orenburg',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Saratov',
		city_ascii: 'Saratov',
		lat: 51.57998985,
		lng: 46.0299963,
		pop: 814586.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Saratov',
		timezone: 'Europe/Saratov',
	},
	{
		city: 'Ulyanovsk',
		city_ascii: 'Ulyanovsk',
		lat: 54.32997703,
		lng: 48.41000606,
		pop: 571553.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Ul'yanovsk",
		timezone: 'Europe/Ulyanovsk',
	},
	{
		city: 'Omsk',
		city_ascii: 'Omsk',
		lat: 54.98998842,
		lng: 73.39995357,
		pop: 1089201.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Omsk',
		timezone: 'Asia/Omsk',
	},
	{
		city: 'Tyumen',
		city_ascii: 'Tyumen',
		lat: 57.14001223,
		lng: 65.52999467,
		pop: 460952,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tyumen',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Novokuznetsk',
		city_ascii: 'Novokuznetsk',
		lat: 53.75001243,
		lng: 87.11498205,
		pop: 530325.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kemerovo',
		timezone: 'Asia/Novokuznetsk',
	},
	{
		city: 'Kemerovo',
		city_ascii: 'Kemerovo',
		lat: 55.33996706,
		lng: 86.08998002,
		pop: 470684.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kemerovo',
		timezone: 'Asia/Novokuznetsk',
	},
	{
		city: 'Groznyy',
		city_ascii: 'Groznyy',
		lat: 43.31868532,
		lng: 45.69869869,
		pop: 221237.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chechnya',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Ust-Ulimsk',
		city_ascii: 'Ust-Ulimsk',
		lat: 57.98996035,
		lng: 102.6333113,
		pop: 68812,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Angarsk',
		city_ascii: 'Angarsk',
		lat: 52.56000755,
		lng: 103.9200028,
		pop: 231719,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Abakan',
		city_ascii: 'Abakan',
		lat: 53.70368451,
		lng: 91.44500199,
		pop: 161377,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Norilsk',
		city_ascii: 'Norilsk',
		lat: 69.34001691,
		lng: 88.22499182,
		pop: 153336.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Taymyr',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Khatanga',
		city_ascii: 'Khatanga',
		lat: 72.04114402,
		lng: 102.4650012,
		pop: 3205,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Taymyr',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Kyzyl',
		city_ascii: 'Kyzyl',
		lat: 51.70670046,
		lng: 94.38306555,
		pop: 106310.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tuva',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Ulan Ude',
		city_ascii: 'Ulan Ude',
		lat: 51.82498781,
		lng: 107.6249963,
		pop: 354127,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Buryat',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Blagoveshchensk',
		city_ascii: 'Blagoveshchensk',
		lat: 50.26660748,
		lng: 127.5333418,
		pop: 206711,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Amur',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Bukachacha',
		city_ascii: 'Bukachacha',
		lat: 52.98334088,
		lng: 116.9166255,
		pop: 1934.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chita',
		timezone: 'Asia/Chita',
	},
	{
		city: 'Dalnegorsk',
		city_ascii: 'Dalnegorsk',
		lat: 44.5372156,
		lng: 135.5172473,
		pop: 8123,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Primor'ye",
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Ambarchik',
		city_ascii: 'Ambarchik',
		lat: 69.65100568,
		lng: 162.3335949,
		pop: 0,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Srednekolymsk',
	},
	{
		city: 'Batagay',
		city_ascii: 'Batagay',
		lat: 67.65598533,
		lng: 134.6350272,
		pop: 4266,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Chokurdakh',
		city_ascii: 'Chokurdakh',
		lat: 70.61831097,
		lng: 147.8945796,
		pop: 2506,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Srednekolymsk',
	},
	{
		city: 'Ust Nera',
		city_ascii: 'Ust Nera',
		lat: 64.56658734,
		lng: 143.1999825,
		pop: 9148,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Ust-Nera',
	},
	{
		city: 'Lensk',
		city_ascii: 'Lensk',
		lat: 60.72527142,
		lng: 114.94703,
		pop: 24641.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Aldan',
		city_ascii: 'Aldan',
		lat: 58.60299786,
		lng: 125.38939,
		pop: 18571.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Mirnyy',
		city_ascii: 'Mirnyy',
		lat: 62.54001853,
		lng: 113.9613537,
		pop: 30535.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Zhigansk',
		city_ascii: 'Zhigansk',
		lat: 66.76970868,
		lng: 123.3711153,
		pop: 3237,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Okhotsk',
		city_ascii: 'Okhotsk',
		lat: 59.38300193,
		lng: 143.2170357,
		pop: 5570,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khabarovsk',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Khabarovsk',
		city_ascii: 'Khabarovsk',
		lat: 48.4549868,
		lng: 135.1200105,
		pop: 562705.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khabarovsk',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Okha',
		city_ascii: 'Okha',
		lat: 53.57389915,
		lng: 142.9478531,
		pop: 25461,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakhalin',
		timezone: 'Asia/Sakhalin',
	},
	{
		city: 'Yuzhno Sakhalinsk',
		city_ascii: 'Yuzhno Sakhalinsk',
		lat: 46.96497438,
		lng: 142.7400105,
		pop: 174685,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakhalin',
		timezone: 'Asia/Sakhalin',
	},
	{
		city: 'Tomsk',
		city_ascii: 'Tomsk',
		lat: 56.494987,
		lng: 84.97500932,
		pop: 471950,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tomsk',
		timezone: 'Asia/Tomsk',
	},
	{
		city: 'Anadyr',
		city_ascii: 'Anadyr',
		lat: 64.73699038,
		lng: 177.4749963,
		pop: 10332,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chukchi Autonomous Okrug',
		timezone: 'Asia/Anadyr',
	},
	{
		city: 'Murmansk',
		city_ascii: 'Murmansk',
		lat: 68.96998781,
		lng: 33.10003617,
		pop: 271758,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Murmansk',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Archangel',
		city_ascii: 'Archangel',
		lat: 64.57495892,
		lng: 40.5450081,
		pop: 295186.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Arkhangel'sk",
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Nizhny Novgorod',
		city_ascii: 'Nizhny Novgorod',
		lat: 56.33300722,
		lng: 44.00009436,
		pop: 1246463,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Nizhegorod',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Volgograd',
		city_ascii: 'Volgograd',
		lat: 48.71000999,
		lng: 44.49996049,
		pop: 801827.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Volgograd',
		timezone: 'Europe/Volgograd',
	},
	{
		city: 'Ufa',
		city_ascii: 'Ufa',
		lat: 54.78997479,
		lng: 56.04003129,
		pop: 969378,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Bashkortostan',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Yekaterinburg',
		city_ascii: 'Yekaterinburg',
		lat: 56.85002993,
		lng: 60.59995967,
		pop: 1270488,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sverdlovsk',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Samara',
		city_ascii: 'Samara',
		lat: 53.19500755,
		lng: 50.15129512,
		pop: 996595,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Samara',
		timezone: 'Europe/Samara',
	},
	{
		city: 'Kazan',
		city_ascii: 'Kazan',
		lat: 55.74994204,
		lng: 49.12634477,
		pop: 1013635,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Tatarstan',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Surgut',
		city_ascii: 'Surgut',
		lat: 61.25994163,
		lng: 73.42501664,
		pop: 353351.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Khanty-Mansiy',
		timezone: 'Asia/Yekaterinburg',
	},
	{
		city: 'Barnaul',
		city_ascii: 'Barnaul',
		lat: 53.35499778,
		lng: 83.74500688,
		pop: 569711,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Altay',
		timezone: 'Asia/Barnaul',
	},
	{
		city: 'Novosibirsk',
		city_ascii: 'Novosibirsk',
		lat: 55.02996014,
		lng: 82.96004187,
		pop: 1213100.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Novosibirsk',
		timezone: 'Asia/Novosibirsk',
	},
	{
		city: 'Bratsk',
		city_ascii: 'Bratsk',
		lat: 56.15699729,
		lng: 101.6150272,
		pop: 133905,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Irkutsk',
		city_ascii: 'Irkutsk',
		lat: 52.31997052,
		lng: 104.2450476,
		pop: 572325,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Irkutsk',
		timezone: 'Asia/Irkutsk',
	},
	{
		city: 'Krasnoyarsk',
		city_ascii: 'Krasnoyarsk',
		lat: 56.01398277,
		lng: 92.86600053,
		pop: 613605,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Krasnoyarsk',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Dickson',
		city_ascii: 'Dickson',
		lat: 73.50697186,
		lng: 80.54509884,
		pop: 1113,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Taymyr',
		timezone: 'Asia/Krasnoyarsk',
	},
	{
		city: 'Chita',
		city_ascii: 'Chita',
		lat: 52.05502545,
		lng: 113.4650016,
		pop: 293153.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Chita',
		timezone: 'Asia/Chita',
	},
	{
		city: 'Vladivostok',
		city_ascii: 'Vladivostok',
		lat: 43.13001467,
		lng: 131.9100256,
		pop: 586617,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: "Primor'ye",
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Nizhneyansk',
		city_ascii: 'Nizhneyansk',
		lat: 71.43332583,
		lng: 136.0666194,
		pop: 395.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Vladivostok',
	},
	{
		city: 'Yakutsk',
		city_ascii: 'Yakutsk',
		lat: 62.03495892,
		lng: 129.7350162,
		pop: 220813,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Tiksi',
		city_ascii: 'Tiksi',
		lat: 71.62688552,
		lng: 128.8349668,
		pop: 5700,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Sakha (Yakutia)',
		timezone: 'Asia/Yakutsk',
	},
	{
		city: 'Magadan',
		city_ascii: 'Magadan',
		lat: 59.57497988,
		lng: 150.8100089,
		pop: 91221.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Maga Buryatdan',
		timezone: 'Asia/Magadan',
	},
	{
		city: 'Perm',
		city_ascii: 'Perm',
		lat: 57.99995974,
		lng: 56.24999263,
		pop: 924154,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Perm',
		timezone: 'Asia/Kamchatka',
	},
	{
		city: 'Palana',
		city_ascii: 'Palana',
		lat: 59.08400209,
		lng: 159.9500195,
		pop: 3671,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kamchatka',
		timezone: 'Asia/Kamchatka',
	},
	{
		city: 'Petropavlovsk Kamchatskiy',
		city_ascii: 'Petropavlovsk Kamchatskiy',
		lat: 53.06199241,
		lng: 158.6230204,
		pop: 182270.5,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Kamchatka',
		timezone: 'Asia/Kamchatka',
	},
	{
		city: 'St. Petersburg',
		city_ascii: 'St. Petersburg',
		lat: 59.93901451,
		lng: 30.31602006,
		pop: 4023106,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'City of St. Petersburg',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Moscow',
		city_ascii: 'Moscow',
		lat: 55.75216412,
		lng: 37.61552283,
		pop: 10452000,
		country: 'Russia',
		iso2: 'RU',
		iso3: 'RUS',
		province: 'Moskva',
		timezone: 'Europe/Moscow',
	},
	{
		city: 'Gikongoro',
		city_ascii: 'Gikongoro',
		lat: -2.483297945,
		lng: 29.5667016,
		pop: 15000,
		country: 'Rwanda',
		iso2: 'RW',
		iso3: 'RWA',
		province: 'Southern',
		timezone: 'Africa/Kigali',
	},
	{
		city: 'Kibuye',
		city_ascii: 'Kibuye',
		lat: -2.050001985,
		lng: 29.34999959,
		pop: 48024,
		country: 'Rwanda',
		iso2: 'RW',
		iso3: 'RWA',
		province: 'Western',
		timezone: 'Africa/Kigali',
	},
	{
		city: 'Kibungo',
		city_ascii: 'Kibungo',
		lat: -2.166695932,
		lng: 30.53330158,
		pop: 46240,
		country: 'Rwanda',
		iso2: 'RW',
		iso3: 'RWA',
		province: 'Eastern',
		timezone: 'Africa/Kigali',
	},
	{
		city: 'Nyanza',
		city_ascii: 'Nyanza',
		lat: -2.349586569,
		lng: 29.74003454,
		pop: 225209,
		country: 'Rwanda',
		iso2: 'RW',
		iso3: 'RWA',
		province: 'Southern',
		timezone: 'Africa/Kigali',
	},
	{
		city: 'Gitarama',
		city_ascii: 'Gitarama',
		lat: -2.069603659,
		lng: 29.75998165,
		pop: 87613,
		country: 'Rwanda',
		iso2: 'RW',
		iso3: 'RWA',
		province: 'Southern',
		timezone: 'Africa/Kigali',
	},
	{
		city: 'Butare',
		city_ascii: 'Butare',
		lat: -2.589623597,
		lng: 29.73000932,
		pop: 77000,
		country: 'Rwanda',
		iso2: 'RW',
		iso3: 'RWA',
		province: 'Southern',
		timezone: 'Africa/Kigali',
	},
	{
		city: 'Gisenyi',
		city_ascii: 'Gisenyi',
		lat: -1.684665915,
		lng: 29.26290605,
		pop: 83623,
		country: 'Rwanda',
		iso2: 'RW',
		iso3: 'RWA',
		province: 'Western',
		timezone: 'Africa/Kigali',
	},
	{
		city: 'Cyangugu',
		city_ascii: 'Cyangugu',
		lat: -2.479604473,
		lng: 28.89998246,
		pop: 19900,
		country: 'Rwanda',
		iso2: 'RW',
		iso3: 'RWA',
		province: 'Western',
		timezone: 'Africa/Kigali',
	},
	{
		city: 'Byumba',
		city_ascii: 'Byumba',
		lat: -1.579556052,
		lng: 30.06001501,
		pop: 70593,
		country: 'Rwanda',
		iso2: 'RW',
		iso3: 'RWA',
		province: 'Northern',
		timezone: 'Africa/Kigali',
	},
	{
		city: 'Ruhengeri',
		city_ascii: 'Ruhengeri',
		lat: -1.499612611,
		lng: 29.63001542,
		pop: 86685,
		country: 'Rwanda',
		iso2: 'RW',
		iso3: 'RWA',
		province: 'Northern',
		timezone: 'Africa/Kigali',
	},
	{
		city: 'Kigali',
		city_ascii: 'Kigali',
		lat: -1.953590069,
		lng: 30.06053178,
		pop: 802630.5,
		country: 'Rwanda',
		iso2: 'RW',
		iso3: 'RWA',
		province: 'Kigali City',
		timezone: 'Africa/Kigali',
	},
	{
		city: 'Basseterre',
		city_ascii: 'Basseterre',
		lat: 17.30203046,
		lng: -62.71700932,
		pop: 18693.5,
		country: 'Saint Kitts and Nevis',
		iso2: 'KN',
		iso3: 'KNA',
		province: '',
		timezone: 'America/St_Kitts',
	},
	{
		city: 'Castries',
		city_ascii: 'Castries',
		lat: 14.00197349,
		lng: -61.00000818,
		pop: 24298.5,
		country: 'Saint Lucia',
		iso2: 'LC',
		iso3: 'LCA',
		province: '',
		timezone: 'America/St_Lucia',
	},
	{
		city: 'Kingstown',
		city_ascii: 'Kingstown',
		lat: 13.14827883,
		lng: -61.21206242,
		pop: 37001.5,
		country: 'Saint Vincent and the Grenadines',
		iso2: 'VC',
		iso3: 'VCT',
		province: '',
		timezone: 'America/St_Vincent',
	},
	{
		city: 'Apia',
		city_ascii: 'Apia',
		lat: -13.84154504,
		lng: -171.7386416,
		pop: 49812,
		country: 'Samoa',
		iso2: 'WS',
		iso3: 'WSM',
		province: '',
		timezone: 'Pacific/Apia',
	},
	{
		city: 'San Marino',
		city_ascii: 'San Marino',
		lat: 43.91715008,
		lng: 12.46667029,
		pop: 29289.5,
		country: 'San Marino',
		iso2: 'SM',
		iso3: 'SMR',
		province: '',
		timezone: 'Europe/San_Marino',
	},
	{
		city: 'Santo Antonio',
		city_ascii: 'Santo Antonio',
		lat: 1.645002051,
		lng: 7.412004483,
		pop: 1156,
		country: 'Sao Tome and Principe',
		iso2: 'ST',
		iso3: 'STP',
		province: '',
		timezone: 'Africa/Sao_Tome',
	},
	{
		city: 'Sao Tome',
		city_ascii: 'Sao Tome',
		lat: 0.333402119,
		lng: 6.733325153,
		pop: 72192.5,
		country: 'Sao Tome and Principe',
		iso2: 'ST',
		iso3: 'STP',
		province: '',
		timezone: 'Africa/Sao_Tome',
	},
	{
		city: 'An Nabk',
		city_ascii: 'An Nabk',
		lat: 31.33330205,
		lng: 37.33329653,
		pop: 7500,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Al Jawf',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Sakakah',
		city_ascii: 'Sakakah',
		lat: 29.99999706,
		lng: 40.13330454,
		pop: 128332,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Al Jawf',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Yanbu al Bahr',
		city_ascii: 'Yanbu al Bahr',
		lat: 24.09427736,
		lng: 38.0492948,
		pop: 233875.5,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Al Madinah',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Dawmat al Jandal',
		city_ascii: 'Dawmat al Jandal',
		lat: 29.81529767,
		lng: 39.86639319,
		pop: 22583,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Al Jawf',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Qal at Bishah',
		city_ascii: 'Qal at Bishah',
		lat: 20.00868695,
		lng: 42.59868119,
		pop: 85059.5,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: '`Asir',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'At Taif',
		city_ascii: 'At Taif',
		lat: 21.26222801,
		lng: 40.38227901,
		pop: 594065,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Makkah',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Najran',
		city_ascii: 'Najran',
		lat: 17.50653994,
		lng: 44.1315592,
		pop: 314091,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Najran',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Al Quwayiyah',
		city_ascii: 'Al Quwayiyah',
		lat: 24.07371014,
		lng: 45.28063635,
		pop: 8712,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Ar Riyad',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Al Hillah',
		city_ascii: 'Al Hillah',
		lat: 23.4894564,
		lng: 46.75636023,
		pop: 594605,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Ar Riyad',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Al Mubarraz',
		city_ascii: 'Al Mubarraz',
		lat: 25.42905377,
		lng: 49.5659045,
		pop: 294682,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Ash Sharqiyah',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Al-Qatif',
		city_ascii: 'Al-Qatif',
		lat: 26.5196332,
		lng: 50.01151037,
		pop: 233575.5,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Ash Sharqiyah',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Az Zahran',
		city_ascii: 'Az Zahran',
		lat: 26.29143007,
		lng: 50.15832312,
		pop: 54373,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Ash Sharqiyah',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Buraydah',
		city_ascii: 'Buraydah',
		lat: 26.36638674,
		lng: 43.96283565,
		pop: 394958.5,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Al Quassim',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Hail',
		city_ascii: 'Hail',
		lat: 27.52357709,
		lng: 41.70007971,
		pop: 385257,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: "Ha'il",
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Arar',
		city_ascii: 'Arar',
		lat: 30.99000633,
		lng: 41.02068966,
		pop: 185278,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Al Hudud ash Shamaliyah',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Rafha',
		city_ascii: 'Rafha',
		lat: 29.62021914,
		lng: 43.4948022,
		pop: 64755,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Al Hudud ash Shamaliyah',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Al Kharj',
		city_ascii: 'Al Kharj',
		lat: 24.15556561,
		lng: 47.3120369,
		pop: 298428,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Ar Riyad',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Ad Damman',
		city_ascii: 'Ad Damman',
		lat: 26.42819175,
		lng: 50.09967037,
		pop: 1411656,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Ash Sharqiyah',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Hafar al Batin',
		city_ascii: 'Hafar al Batin',
		lat: 28.43365074,
		lng: 45.96007808,
		pop: 249194,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Ash Sharqiyah',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Al Jubayl',
		city_ascii: 'Al Jubayl',
		lat: 27.00464235,
		lng: 49.64600297,
		pop: 235237,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Ash Sharqiyah',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Al Qunfudhah',
		city_ascii: 'Al Qunfudhah',
		lat: 19.12636354,
		lng: 41.07887732,
		pop: 157,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Makkah',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Al Hufuf',
		city_ascii: 'Al Hufuf',
		lat: 25.3487486,
		lng: 49.58559322,
		pop: 518694.5,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Ash Sharqiyah',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Al Wajh',
		city_ascii: 'Al Wajh',
		lat: 26.23241559,
		lng: 36.4635518,
		pop: 34936.5,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Tabuk',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Abha',
		city_ascii: 'Abha',
		lat: 18.2300875,
		lng: 42.50013424,
		pop: 207802.5,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: '`Asir',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Jizan',
		city_ascii: 'Jizan',
		lat: 16.90655072,
		lng: 42.5565649,
		pop: 100397,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Jizan',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'As Sulayyil',
		city_ascii: 'As Sulayyil',
		lat: 20.462251,
		lng: 45.57224646,
		pop: 20858.5,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Ar Riyad',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Medina',
		city_ascii: 'Medina',
		lat: 24.49998903,
		lng: 39.5800024,
		pop: 1010000,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Al Madinah',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Tabuk',
		city_ascii: 'Tabuk',
		lat: 28.38383465,
		lng: 36.55496741,
		pop: 501703.5,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Tabuk',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Jeddah',
		city_ascii: 'Jeddah',
		lat: 21.51688946,
		lng: 39.21919755,
		pop: 2939723,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Makkah',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Makkah',
		city_ascii: 'Makkah',
		lat: 21.43002138,
		lng: 39.82003943,
		pop: 1354312,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Makkah',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Riyadh',
		city_ascii: 'Riyadh',
		lat: 24.64083315,
		lng: 46.77274166,
		pop: 4335480.5,
		country: 'Saudi Arabia',
		iso2: 'SA',
		iso3: 'SAU',
		province: 'Ar Riyad',
		timezone: 'Asia/Riyadh',
	},
	{
		city: 'Fatick',
		city_ascii: 'Fatick',
		lat: 14.3440021,
		lng: -16.41599952,
		pop: 24243,
		country: 'Senegal',
		iso2: 'SM',
		iso3: 'SEN',
		province: 'Fatick',
		timezone: 'Africa/Dakar',
	},
	{
		city: 'Diourbel',
		city_ascii: 'Diourbel',
		lat: 14.66038291,
		lng: -16.24000126,
		pop: 148024,
		country: 'Senegal',
		iso2: 'SM',
		iso3: 'SEN',
		province: 'Diourbel',
		timezone: 'Africa/Dakar',
	},
	{
		city: 'Louga',
		city_ascii: 'Louga',
		lat: 15.61037661,
		lng: -16.25000065,
		pop: 85075,
		country: 'Senegal',
		iso2: 'SM',
		iso3: 'SEN',
		province: 'Louga',
		timezone: 'Africa/Dakar',
	},
	{
		city: 'Thies',
		city_ascii: 'Thies',
		lat: 14.8103996,
		lng: -16.93001083,
		pop: 293001,
		country: 'Senegal',
		iso2: 'SM',
		iso3: 'SEN',
		province: 'Thiès',
		timezone: 'Africa/Dakar',
	},
	{
		city: 'Kolda',
		city_ascii: 'Kolda',
		lat: 12.91043805,
		lng: -14.95002832,
		pop: 64038,
		country: 'Senegal',
		iso2: 'SM',
		iso3: 'SEN',
		province: 'Kolda',
		timezone: 'Africa/Dakar',
	},
	{
		city: 'Tambacounda',
		city_ascii: 'Tambacounda',
		lat: 13.78035911,
		lng: -13.68002832,
		pop: 89212,
		country: 'Senegal',
		iso2: 'SM',
		iso3: 'SEN',
		province: 'Tambacounda',
		timezone: 'Africa/Dakar',
	},
	{
		city: 'Kedougou',
		city_ascii: 'Kedougou',
		lat: 12.56043357,
		lng: -12.17999068,
		pop: 18074,
		country: 'Senegal',
		iso2: 'SM',
		iso3: 'SEN',
		province: 'Tambacounda',
		timezone: 'Africa/Dakar',
	},
	{
		city: 'Ziguinchor',
		city_ascii: 'Ziguinchor',
		lat: 12.58999249,
		lng: -16.28999821,
		pop: 175747,
		country: 'Senegal',
		iso2: 'SM',
		iso3: 'SEN',
		province: 'Ziguinchor',
		timezone: 'Africa/Dakar',
	},
	{
		city: 'Kaolack',
		city_ascii: 'Kaolack',
		lat: 14.14997479,
		lng: -16.10000981,
		pop: 277812,
		country: 'Senegal',
		iso2: 'SM',
		iso3: 'SEN',
		province: 'Kaolack',
		timezone: 'Africa/Dakar',
	},
	{
		city: 'Kaedi',
		city_ascii: 'Kaedi',
		lat: 16.15000775,
		lng: -13.49998763,
		pop: 21656,
		country: 'Senegal',
		iso2: 'SM',
		iso3: 'SEN',
		province: 'Matam',
		timezone: 'Africa/Nouakchott',
	},
	{
		city: 'Dakar',
		city_ascii: 'Dakar',
		lat: 14.71583173,
		lng: -17.47313013,
		pop: 2540200,
		country: 'Senegal',
		iso2: 'SM',
		iso3: 'SEN',
		province: 'Dakar',
		timezone: 'Africa/Dakar',
	},
	{
		city: 'Subotica',
		city_ascii: 'Subotica',
		lat: 46.07001609,
		lng: 19.68002844,
		pop: 96704,
		country: 'Serbia',
		iso2: 'RS',
		iso3: 'SRB',
		province: 'Severno-Backi',
		timezone: 'Europe/Belgrade',
	},
	{
		city: 'Kragujevac',
		city_ascii: 'Kragujevac',
		lat: 44.01996035,
		lng: 20.92000443,
		pop: 159335,
		country: 'Serbia',
		iso2: 'RS',
		iso3: 'SRB',
		province: 'Šumadijski',
		timezone: 'Europe/Belgrade',
	},
	{
		city: 'Zrenjanin',
		city_ascii: 'Zrenjanin',
		lat: 45.3786371,
		lng: 20.39946773,
		pop: 64053,
		country: 'Serbia',
		iso2: 'RS',
		iso3: 'SRB',
		province: 'Srednje-Banatski',
		timezone: 'Europe/Belgrade',
	},
	{
		city: 'Pec',
		city_ascii: 'Pec',
		lat: 43.88973574,
		lng: 20.33011796,
		pop: 137332.5,
		country: 'Serbia',
		iso2: 'RS',
		iso3: 'SRB',
		province: 'Moravicki',
		timezone: 'Europe/Belgrade',
	},
	{
		city: 'Nis',
		city_ascii: 'Nis',
		lat: 43.33041587,
		lng: 21.8999963,
		pop: 230444,
		country: 'Serbia',
		iso2: 'RS',
		iso3: 'SRB',
		province: 'Nišavski',
		timezone: 'Europe/Belgrade',
	},
	{
		city: 'Novi Sad',
		city_ascii: 'Novi Sad',
		lat: 45.2503762,
		lng: 19.84994055,
		pop: 220428.5,
		country: 'Serbia',
		iso2: 'RS',
		iso3: 'SRB',
		province: 'Južno-Backi',
		timezone: 'Europe/Belgrade',
	},
	{
		city: 'Belgrade',
		city_ascii: 'Belgrade',
		lat: 44.81864545,
		lng: 20.46799068,
		pop: 1099000,
		country: 'Serbia',
		iso2: 'RS',
		iso3: 'SRB',
		province: 'Grad Beograd',
		timezone: 'Europe/Belgrade',
	},
	{
		city: 'Victoria',
		city_ascii: 'Victoria',
		lat: -4.616631654,
		lng: 55.44998979,
		pop: 28228.5,
		country: 'Seychelles',
		iso2: 'SC',
		iso3: 'SYC',
		province: '',
		timezone: 'Indian/Mahe',
	},
	{
		city: 'Makeni',
		city_ascii: 'Makeni',
		lat: 8.880425638,
		lng: -12.04997278,
		pop: 83116,
		country: 'Sierra Leone',
		iso2: 'SL',
		iso3: 'SLE',
		province: 'Northern',
		timezone: 'Africa/Freetown',
	},
	{
		city: 'Koidu',
		city_ascii: 'Koidu',
		lat: 8.440478331,
		lng: -10.84999435,
		pop: 45307.5,
		country: 'Sierra Leone',
		iso2: 'SL',
		iso3: 'SLE',
		province: 'Eastern',
		timezone: 'Africa/Freetown',
	},
	{
		city: 'Kenema',
		city_ascii: 'Kenema',
		lat: 7.880409158,
		lng: -11.18997359,
		pop: 133918.5,
		country: 'Sierra Leone',
		iso2: 'SL',
		iso3: 'SLE',
		province: 'Eastern',
		timezone: 'Africa/Freetown',
	},
	{
		city: 'Bo',
		city_ascii: 'Bo',
		lat: 7.970016092,
		lng: -11.74001754,
		pop: 170690.5,
		country: 'Sierra Leone',
		iso2: 'SL',
		iso3: 'SLE',
		province: 'Southern',
		timezone: 'Africa/Freetown',
	},
	{
		city: 'Freetown',
		city_ascii: 'Freetown',
		lat: 8.470011412,
		lng: -13.23421574,
		pop: 420384,
		country: 'Sierra Leone',
		iso2: 'SL',
		iso3: 'SLE',
		province: 'Western',
		timezone: 'Africa/Freetown',
	},
	{
		city: 'Singapore',
		city_ascii: 'Singapore',
		lat: 1.293033466,
		lng: 103.8558207,
		pop: 4236614.5,
		country: 'Singapore',
		iso2: 'SG',
		iso3: 'SGP',
		province: '',
		timezone: 'Asia/Singapore',
	},
	{
		city: 'Banska Bystrica',
		city_ascii: 'Banska Bystrica',
		lat: 48.73329022,
		lng: 19.14998328,
		pop: 80784,
		country: 'Slovakia',
		iso2: 'SK',
		iso3: 'SVK',
		province: 'Banskobystrický',
		timezone: 'Europe/Bratislava',
	},
	{
		city: 'Trnava',
		city_ascii: 'Trnava',
		lat: 48.36659426,
		lng: 17.60000036,
		pop: 60919,
		country: 'Slovakia',
		iso2: 'SK',
		iso3: 'SVK',
		province: 'Trnavský',
		timezone: 'Europe/Bratislava',
	},
	{
		city: 'Zvolen',
		city_ascii: 'Zvolen',
		lat: 48.58373863,
		lng: 19.13324011,
		pop: 38276.5,
		country: 'Slovakia',
		iso2: 'SK',
		iso3: 'SVK',
		province: 'Banskobystrický',
		timezone: 'Europe/Bratislava',
	},
	{
		city: 'Zilina',
		city_ascii: 'Zilina',
		lat: 49.21982383,
		lng: 18.74938757,
		pop: 86805,
		country: 'Slovakia',
		iso2: 'SK',
		iso3: 'SVK',
		province: 'Žilinský',
		timezone: 'Europe/Bratislava',
	},
	{
		city: 'Kosice',
		city_ascii: 'Kosice',
		lat: 48.73044802,
		lng: 21.25001013,
		pop: 210316.5,
		country: 'Slovakia',
		iso2: 'SK',
		iso3: 'SVK',
		province: 'Košický',
		timezone: 'Europe/Bratislava',
	},
	{
		city: 'Presov',
		city_ascii: 'Presov',
		lat: 48.99973391,
		lng: 21.23936479,
		pop: 85368.5,
		country: 'Slovakia',
		iso2: 'SK',
		iso3: 'SVK',
		province: 'Prešov',
		timezone: 'Europe/Bratislava',
	},
	{
		city: 'Bratislava',
		city_ascii: 'Bratislava',
		lat: 48.15001833,
		lng: 17.11698075,
		pop: 398712,
		country: 'Slovakia',
		iso2: 'SK',
		iso3: 'SVK',
		province: 'Bratislavský',
		timezone: 'Europe/Bratislava',
	},
	{
		city: 'Maribor',
		city_ascii: 'Maribor',
		lat: 46.54047833,
		lng: 15.65004187,
		pop: 101642,
		country: 'Slovenia',
		iso2: 'SI',
		iso3: 'SVN',
		province: 'Maribor',
		timezone: 'Europe/Ljubljana',
	},
	{
		city: 'Ljubljana',
		city_ascii: 'Ljubljana',
		lat: 46.05528831,
		lng: 14.51496903,
		pop: 284961,
		country: 'Slovenia',
		iso2: 'SI',
		iso3: 'SVN',
		province: 'Osrednjeslovenska',
		timezone: 'Europe/Ljubljana',
	},
	{
		city: 'Gizo',
		city_ascii: 'Gizo',
		lat: -8.09962319,
		lng: 156.8350158,
		pop: 6154,
		country: 'Solomon Islands',
		iso2: 'SB',
		iso3: 'SLB',
		province: 'Choiseul',
		timezone: 'Pacific/Guadalcanal',
	},
	{
		city: 'Lata',
		city_ascii: 'Lata',
		lat: -10.73801832,
		lng: 165.8567353,
		pop: 553,
		country: 'Solomon Islands',
		iso2: 'SB',
		iso3: 'SLB',
		province: 'Temotu',
		timezone: 'Pacific/Guadalcanal',
	},
	{
		city: 'Honiara',
		city_ascii: 'Honiara',
		lat: -9.437994295,
		lng: 159.9497657,
		pop: 66313,
		country: 'Solomon Islands',
		iso2: 'SB',
		iso3: 'SLB',
		province: 'Guadalcanal',
		timezone: 'Pacific/Guadalcanal',
	},
	{
		city: 'Hudur',
		city_ascii: 'Xuddur',
		lat: 4.183298973,
		lng: 43.86670261,
		pop: 1639,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Bakool',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Garbahaarey',
		city_ascii: 'Garbahaarey',
		lat: 3.327003116,
		lng: 42.22700164,
		pop: 12652,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Gedo',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: "Bu'aale",
		city_ascii: "Bu'aale",
		lat: 1.083303017,
		lng: 42.58330253,
		pop: 1490,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Jubbada Dhexe',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Dhuusa Mareeb',
		city_ascii: 'Dhuusa Mareeb',
		lat: 5.741999984,
		lng: 46.5080045,
		pop: 447,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Galguduud',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Buurhakaba',
		city_ascii: 'Buurhakaba',
		lat: 2.783717671,
		lng: 44.08329342,
		pop: 29529.5,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Bay',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Luuq',
		city_ascii: 'Luuq',
		lat: 3.800477314,
		lng: 42.55000199,
		pop: 33820,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Gedo',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Mandera',
		city_ascii: 'Mandera',
		lat: 3.940442931,
		lng: 41.86001827,
		pop: 44480.5,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Gedo',
		timezone: 'Africa/Nairobi',
	},
	{
		city: 'Ferfer',
		city_ascii: 'Ferfer',
		lat: 5.085411803,
		lng: 45.16503617,
		pop: 5205.5,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Hiiraan',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Jawhar',
		city_ascii: 'Jawhar',
		lat: 2.767000345,
		lng: 45.51659094,
		pop: 86654,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Shabeellaha Dhexe',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Hurdiyo',
		city_ascii: 'Hurdiyo',
		lat: 10.5820272,
		lng: 51.12332882,
		pop: 176,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Bari',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Qardho',
		city_ascii: 'Qardho',
		lat: 9.500413634,
		lng: 49.16598059,
		pop: 1341,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Bari',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Caluula',
		city_ascii: 'Caluula',
		lat: 11.96695559,
		lng: 50.75001827,
		pop: 513,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Bari',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Buur Gaabo',
		city_ascii: 'Buur Gaabo',
		lat: -1.200869868,
		lng: 41.85195979,
		pop: 3096,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Jubbada Hoose',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Baydhabo',
		city_ascii: 'Baydhabo',
		lat: 3.119976216,
		lng: 43.64998653,
		pop: 128830,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Bay',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Marka',
		city_ascii: 'Marka',
		lat: 1.776569843,
		lng: 44.85327226,
		pop: 1958,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Shabeellaha Hoose',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Mereeg',
		city_ascii: 'Mereeg',
		lat: 3.766577575,
		lng: 47.2999963,
		pop: 548,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Galguduud',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Beledweyne',
		city_ascii: 'Beledweyne',
		lat: 4.739980691,
		lng: 45.20002112,
		pop: 59177.5,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Hiiraan',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Boosaaso',
		city_ascii: 'Boosaaso',
		lat: 11.28002077,
		lng: 49.1799849,
		pop: 46969,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Bari',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Bandarbeyla',
		city_ascii: 'Bandarbeyla',
		lat: 9.483308735,
		lng: 50.81668087,
		pop: 13753,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Bari',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Gaalkacyo',
		city_ascii: 'Gaalkacyo',
		lat: 6.769960143,
		lng: 47.4300142,
		pop: 57350.5,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Mudug',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Eyl',
		city_ascii: 'Eyl',
		lat: 7.983348611,
		lng: 49.83327836,
		pop: 9636.5,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Nugaal',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Garoowe',
		city_ascii: 'Garoowe',
		lat: 8.399989847,
		lng: 48.50002641,
		pop: 2568,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Nugaal',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Jamaame',
		city_ascii: 'Jamaame',
		lat: 0.072177754,
		lng: 42.75055823,
		pop: 156923.5,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Jubbada Hoose',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Kismaayo',
		city_ascii: 'Kismaayo',
		lat: -0.356633282,
		lng: 42.51832434,
		pop: 184901.5,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Jubbada Hoose',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Mogadishu',
		city_ascii: 'Mogadishu',
		lat: 2.066681334,
		lng: 45.36667761,
		pop: 987694,
		country: 'Somalia',
		iso2: 'SO',
		iso3: 'SOM',
		province: 'Banaadir',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Laascaanood',
		city_ascii: 'Laascaanood',
		lat: 8.43329714,
		lng: 47.31669964,
		pop: 60100,
		country: 'Somaliland',
		iso2: '',
		iso3: 'SOL',
		province: '',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Ceerigaabo',
		city_ascii: 'Ceerigaabo',
		lat: 10.58329807,
		lng: 47.33330461,
		pop: 165000,
		country: 'Somaliland',
		iso2: '',
		iso3: 'SOL',
		province: '',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Boorama',
		city_ascii: 'Boorama',
		lat: 9.940412617,
		lng: 43.18004106,
		pop: 67664,
		country: 'Somaliland',
		iso2: -99,
		iso3: 'SOL',
		province: '',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Burco',
		city_ascii: 'Burco',
		lat: 9.520386575,
		lng: 45.54000037,
		pop: 102931.5,
		country: 'Somaliland',
		iso2: -99,
		iso3: 'SOL',
		province: '',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Maydh',
		city_ascii: 'Maydh',
		lat: 10.98009076,
		lng: 47.09752803,
		pop: 30000,
		country: 'Somaliland',
		iso2: -99,
		iso3: 'SOL',
		province: '',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Berbera',
		city_ascii: 'Berbera',
		lat: 10.43555035,
		lng: 45.01641475,
		pop: 178407,
		country: 'Somaliland',
		iso2: -99,
		iso3: 'SOL',
		province: '',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: 'Hargeysa',
		city_ascii: 'Hargeysa',
		lat: 9.560022399,
		lng: 44.06531002,
		pop: 362447,
		country: 'Somaliland',
		iso2: -99,
		iso3: 'SOL',
		province: '',
		timezone: 'Africa/Mogadishu',
	},
	{
		city: "Qacha's Nek",
		city_ascii: "Qacha's Nek",
		lat: -30.11726692,
		lng: 28.70199951,
		pop: 25573,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Eastern Cape',
		timezone: 'Africa/Maseru',
	},
	{
		city: 'Colesberg',
		city_ascii: 'Colesberg',
		lat: -30.71953449,
		lng: 25.10000769,
		pop: 7491,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Northern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Poffader',
		city_ascii: 'Poffader',
		lat: -29.13291299,
		lng: 19.38335404,
		pop: 4220,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Northern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Carnarvon',
		city_ascii: 'Carnarvon',
		lat: -30.94959796,
		lng: 22.13331539,
		pop: 5785,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Northern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Prieska',
		city_ascii: 'Prieska',
		lat: -29.65954751,
		lng: 22.73002315,
		pop: 7640.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Northern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Kuruman',
		city_ascii: 'Kuruman',
		lat: -27.4495532,
		lng: 23.42000688,
		pop: 9549.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Northern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Knysna',
		city_ascii: 'Knysna',
		lat: -34.03292397,
		lng: 23.03331213,
		pop: 33887,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Western Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Swellendam',
		city_ascii: 'Swellendam',
		lat: -34.01959145,
		lng: 20.4300085,
		pop: 8954,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Western Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Hermanus',
		city_ascii: 'Hermanus',
		lat: -34.40959349,
		lng: 19.22992672,
		pop: 16274.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Western Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Paarl',
		city_ascii: 'Paarl',
		lat: -33.69955931,
		lng: 18.96002071,
		pop: 159791.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Western Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Bredasdorp',
		city_ascii: 'Bredasdorp',
		lat: -34.52953449,
		lng: 20.02998124,
		pop: 8453,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Western Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Beaufort West',
		city_ascii: 'Beaufort West',
		lat: -32.34961587,
		lng: 22.56998124,
		pop: 28070.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Western Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Brits',
		city_ascii: 'Brits',
		lat: -25.62961261,
		lng: 27.77999914,
		pop: 81222,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'North West',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Bloemhof',
		city_ascii: 'Bloemhof',
		lat: -27.64959267,
		lng: 25.59000362,
		pop: 10662.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'North West',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Potchefstroom',
		city_ascii: 'Potchefstroom',
		lat: -26.69957314,
		lng: 27.09998897,
		pop: 103741.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'North West',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Brandfort',
		city_ascii: 'Brandfort',
		lat: -28.69955442,
		lng: 26.47000159,
		pop: 6190,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Orange Free State',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Bethlehem',
		city_ascii: 'Bethlehem',
		lat: -28.21958372,
		lng: 28.29996741,
		pop: 66373,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Orange Free State',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Springs',
		city_ascii: 'Springs',
		lat: -26.26957355,
		lng: 28.43003699,
		pop: 211238.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Gauteng',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Volksrust',
		city_ascii: 'Volksrust',
		lat: -27.35958453,
		lng: 29.88999955,
		pop: 25394.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Mpumalanga',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Mbombela',
		city_ascii: 'Nelspruit',
		lat: -25.46962238,
		lng: 30.98001054,
		pop: 184839,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Mpumalanga',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Komatipoort',
		city_ascii: 'Komatipoort',
		lat: -25.40957436,
		lng: 31.94000362,
		pop: 10333.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Mpumalanga',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Middelburg',
		city_ascii: 'Middelburg',
		lat: -25.75963051,
		lng: 29.47002519,
		pop: 124248,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Mpumalanga',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Bethal',
		city_ascii: 'Bethal',
		lat: -26.46961302,
		lng: 29.45002641,
		pop: 96184.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Mpumalanga',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Standerton',
		city_ascii: 'Standerton',
		lat: -26.93950682,
		lng: 29.24001339,
		pop: 46057,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Mpumalanga',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Lebowakgomo',
		city_ascii: 'Lebowakgomo',
		lat: -24.19962238,
		lng: 29.49999752,
		pop: 16852.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Limpopo',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Tzaneen',
		city_ascii: 'Tzaneen',
		lat: -23.81954222,
		lng: 30.16998246,
		pop: 42099.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Limpopo',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Ulundi',
		city_ascii: 'Ulundi',
		lat: -28.32960285,
		lng: 31.41001013,
		pop: 13167,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'KwaZulu-Natal',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Ladysmith',
		city_ascii: 'Ladysmith',
		lat: -28.54948606,
		lng: 29.7800321,
		pop: 47375,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'KwaZulu-Natal',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Port Shepstone',
		city_ascii: 'Port Shepstone',
		lat: -30.71953449,
		lng: 30.4599906,
		pop: 37325.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'KwaZulu-Natal',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Ubomba',
		city_ascii: 'Ubomba',
		lat: -27.56608356,
		lng: 32.08330237,
		pop: 564,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'KwaZulu-Natal',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Cradock',
		city_ascii: 'Cradock',
		lat: -32.19954751,
		lng: 25.6100024,
		pop: 32898,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Eastern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Uitenhage',
		city_ascii: 'Uitenhage',
		lat: -33.75960732,
		lng: 25.39001583,
		pop: 217839,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Eastern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Port Alfred',
		city_ascii: 'Port Alfred',
		lat: -33.59951373,
		lng: 26.8800024,
		pop: 9377,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Eastern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Grahamstown',
		city_ascii: 'Grahamstown',
		lat: -33.29958372,
		lng: 26.52002437,
		pop: 70315.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Eastern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Port St. Johns',
		city_ascii: 'Port St. Johns',
		lat: -31.62785114,
		lng: 29.5283162,
		pop: 5939,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Eastern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Aliwal North',
		city_ascii: 'Aliwal North',
		lat: -30.68956216,
		lng: 26.71003861,
		pop: 26423,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Eastern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Queenstown',
		city_ascii: 'Queenstown',
		lat: -31.89961749,
		lng: 26.8800024,
		pop: 96274.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Eastern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Benoni',
		city_ascii: 'Benoni',
		lat: -26.14958087,
		lng: 28.32993974,
		pop: 1795672,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Gauteng',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Vereeniging',
		city_ascii: 'Vereeniging',
		lat: -26.64960203,
		lng: 27.95998816,
		pop: 774340.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Gauteng',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'De Aar',
		city_ascii: 'De Aar',
		lat: -30.64997801,
		lng: 24.00002315,
		pop: 18669.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Northern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Alexander Bay',
		city_ascii: 'Alexander Bay',
		lat: -28.60834552,
		lng: 16.50332312,
		pop: 1476,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Northern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Kimberley',
		city_ascii: 'Kimberley',
		lat: -28.74683836,
		lng: 24.77000199,
		pop: 153676.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Northern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Oudtshoorn',
		city_ascii: 'Oudtshoorn',
		lat: -33.58003172,
		lng: 22.19000443,
		pop: 62353,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Western Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Vanhynsdorp',
		city_ascii: 'Vanhynsdorp',
		lat: -31.61663735,
		lng: 18.7333162,
		pop: 3331,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Western Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Saldanha',
		city_ascii: 'Saldanha',
		lat: -33.01004067,
		lng: 17.93000606,
		pop: 37469,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Western Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Mossel Bay',
		city_ascii: 'Mossel Bay',
		lat: -34.17002155,
		lng: 22.1300081,
		pop: 16743,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Western Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Vryburg',
		city_ascii: 'Vryburg',
		lat: -26.96002236,
		lng: 24.73000443,
		pop: 31589,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'North West',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Rustenburg',
		city_ascii: 'Rustenburg',
		lat: -25.6500248,
		lng: 27.2400321,
		pop: 145020,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'North West',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Mmabatho',
		city_ascii: 'Mmabatho',
		lat: -25.83001382,
		lng: 25.6100024,
		pop: 90591,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'North West',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Klerksdorp',
		city_ascii: 'Klerksdorp',
		lat: -26.88002724,
		lng: 26.62001827,
		pop: 163362.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'North West',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Kroonstad',
		city_ascii: 'Kroonstad',
		lat: -27.66003131,
		lng: 27.2100081,
		pop: 88413.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Orange Free State',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Polokwane',
		city_ascii: 'Polokwane',
		lat: -23.89002887,
		lng: 29.45002641,
		pop: 171897,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Limpopo',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Thohoyandou',
		city_ascii: 'Thohoyandou',
		lat: -22.95003457,
		lng: 30.48004106,
		pop: 156876.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Limpopo',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Musina',
		city_ascii: 'Musina',
		lat: -22.33999428,
		lng: 30.02999101,
		pop: 11848,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Limpopo',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Vryheid',
		city_ascii: 'Vryheid',
		lat: -27.76002521,
		lng: 30.7899963,
		pop: 108364.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'KwaZulu-Natal',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Pietermaritzburg',
		city_ascii: 'Pietermaritzburg',
		lat: -29.61004148,
		lng: 30.39002071,
		pop: 620898,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'KwaZulu-Natal',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Umtata',
		city_ascii: 'Umtata',
		lat: -31.57999876,
		lng: 28.79001501,
		pop: 108217.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Eastern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Graaff Reinet',
		city_ascii: 'Graaff Reinet',
		lat: -32.30000649,
		lng: 24.54004187,
		pop: 32958.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Eastern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Bhisho',
		city_ascii: 'Bhisho',
		lat: -32.86999754,
		lng: 27.38999711,
		pop: 149142,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Eastern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Springbok',
		city_ascii: 'Springbok',
		lat: -29.66673053,
		lng: 17.88329057,
		pop: 6623.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Northern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Upington',
		city_ascii: 'Upington',
		lat: -28.46003416,
		lng: 21.23001135,
		pop: 62086,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Northern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Worcester',
		city_ascii: 'Worcester',
		lat: -33.64002806,
		lng: 19.43993974,
		pop: 109200,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Western Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'George',
		city_ascii: 'George',
		lat: -33.95003497,
		lng: 22.45004024,
		pop: 143915,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Western Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Welkom',
		city_ascii: 'Welkom',
		lat: -27.96998655,
		lng: 26.72998572,
		pop: 279011.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Orange Free State',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'East London',
		city_ascii: 'East London',
		lat: -32.97004311,
		lng: 27.87001949,
		pop: 338627,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Eastern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Middelburg',
		city_ascii: 'Middelburg',
		lat: -31.50000364,
		lng: 25.00998734,
		pop: 10964,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Eastern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Bloemfontein',
		city_ascii: 'Bloemfontein',
		lat: -29.11999388,
		lng: 26.22991288,
		pop: 459866.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Orange Free State',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Pretoria',
		city_ascii: 'Pretoria',
		lat: -25.70692055,
		lng: 28.22942908,
		pop: 1338000,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Gauteng',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Port Elizabeth',
		city_ascii: 'Port Elizabeth',
		lat: -33.97003375,
		lng: 25.60002885,
		pop: 830527,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Eastern Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Durban',
		city_ascii: 'Durban',
		lat: -29.865013,
		lng: 30.98001054,
		pop: 2729000,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'KwaZulu-Natal',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Johannesburg',
		city_ascii: 'Johannesburg',
		lat: -26.17004474,
		lng: 28.03000972,
		pop: 2730734.5,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Gauteng',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Cape Town',
		city_ascii: 'Cape Town',
		lat: -33.92001097,
		lng: 18.43498816,
		pop: 2823929,
		country: 'South Africa',
		iso2: 'ZA',
		iso3: 'ZAF',
		province: 'Western Cape',
		timezone: 'Africa/Johannesburg',
	},
	{
		city: 'Grytviken',
		city_ascii: 'Grytviken',
		lat: -54.28057697,
		lng: -36.50798893,
		pop: 99,
		country: 'South Georgia and the Islands',
		iso2: 'GS',
		iso3: 'SGS',
		province: '',
		timezone: 'Atlantic/South_Georgia',
	},
	{
		city: 'Eumseong',
		city_ascii: 'Eumseong',
		lat: 36.93525067,
		lng: 127.6897147,
		pop: 10077,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Chungcheongbuk-do',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Cheongju',
		city_ascii: 'Cheongju',
		lat: 36.64389895,
		lng: 127.5011991,
		pop: 719420.5,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Chungcheongbuk-do',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Wonju',
		city_ascii: 'Wonju',
		lat: 37.35514752,
		lng: 127.9396219,
		pop: 240898,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Gangwon-do',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Chuncheon',
		city_ascii: 'Chuncheon',
		lat: 37.87470237,
		lng: 127.7341565,
		pop: 218127.5,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Gangwon-do',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Ansan',
		city_ascii: 'Ansan',
		lat: 37.34806785,
		lng: 126.8595328,
		pop: 695110.5,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Gyeonggi-do',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Iksan',
		city_ascii: 'Iksan',
		lat: 35.94097027,
		lng: 126.9454191,
		pop: 261545,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Jeollabuk-do',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Gyeongju',
		city_ascii: 'Gyeongju',
		lat: 35.84275922,
		lng: 129.211689,
		pop: 148852.5,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Daegu',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Changwon',
		city_ascii: 'Masan',
		lat: 35.21910219,
		lng: 128.583562,
		pop: 1081499,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Gyeongsangnam-do',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Yeosu',
		city_ascii: 'Yeosu',
		lat: 34.73678021,
		lng: 127.7458353,
		pop: 302142,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Gwangju',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Andong',
		city_ascii: 'Andong',
		lat: 36.56591921,
		lng: 128.7250004,
		pop: 123920.5,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Daegu',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Jeju',
		city_ascii: 'Jeju',
		lat: 33.51013674,
		lng: 126.5219307,
		pop: 361258,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Jeju',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Gangneung',
		city_ascii: 'Gangneung',
		lat: 37.75587242,
		lng: 128.8961527,
		pop: 173797,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Gangwon-do',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Sokcho',
		city_ascii: 'Sokcho',
		lat: 38.20871299,
		lng: 128.5911584,
		pop: 85430,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Gangwon-do',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Jeonju',
		city_ascii: 'Jeonju',
		lat: 35.83141624,
		lng: 127.1403942,
		pop: 679948.5,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Jeollabuk-do',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Gunsan',
		city_ascii: 'Gunsan',
		lat: 35.98176575,
		lng: 126.7160215,
		pop: 243743,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Jeollabuk-do',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Mokpo',
		city_ascii: 'Mokpo',
		lat: 34.80680178,
		lng: 126.3958402,
		pop: 264210,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Gwangju',
		timezone: 'Asia/Seoul',
	},
	{
		city: "Puch'on",
		city_ascii: "Puch'on",
		lat: 37.4988889,
		lng: 126.7830556,
		pop: 866000,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Gyeonggi-do',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Songnam',
		city_ascii: 'Songnam',
		lat: 37.4386111,
		lng: 127.1377778,
		pop: 986967.5,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Gyeonggi-do',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Goyang',
		city_ascii: 'Goyang',
		lat: 37.65273586,
		lng: 126.8372485,
		pop: 903000,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Gyeonggi-do',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Suwon',
		city_ascii: 'Suwon',
		lat: 37.25778912,
		lng: 127.0108931,
		pop: 1078000,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Gyeonggi-do',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Pohang',
		city_ascii: 'Pohang',
		lat: 36.02086204,
		lng: 129.3715242,
		pop: 435266,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Daegu',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Ulsan',
		city_ascii: 'Ulsan',
		lat: 35.54673077,
		lng: 129.3169539,
		pop: 1011932.5,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Ulsan',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Daegu',
		city_ascii: 'Daegu',
		lat: 35.86678876,
		lng: 128.6069714,
		pop: 2460000,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Taegu-gwangyoksi',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Incheon',
		city_ascii: 'Incheon',
		lat: 37.47614789,
		lng: 126.6422334,
		pop: 2550000,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: "Inch'on-gwangyoksi",
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Daejeon',
		city_ascii: 'Daejeon',
		lat: 36.33554567,
		lng: 127.425028,
		pop: 1458165,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Daejeon',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Gwangju',
		city_ascii: 'Gwangju',
		lat: 35.1709656,
		lng: 126.9104341,
		pop: 1428469,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Kwangju-gwangyoksi',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Busan',
		city_ascii: 'Busan',
		lat: 35.09505292,
		lng: 129.0100476,
		pop: 3480000,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Busan',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Seoul',
		city_ascii: 'Seoul',
		lat: 37.5663491,
		lng: 126.999731,
		pop: 9796000,
		country: 'South Korea',
		iso2: 'KR',
		iso3: 'KOR',
		province: 'Seoul',
		timezone: 'Asia/Seoul',
	},
	{
		city: 'Bentiu',
		city_ascii: 'Bentiu',
		lat: 9.2333333,
		lng: 29.8333333,
		pop: 7653,
		country: 'South Sudan',
		iso2: 'SS',
		iso3: 'SSD',
		province: 'Unity',
		timezone: 'Africa/Juba',
	},
	{
		city: 'Maridi',
		city_ascii: 'Maridi',
		lat: 4.91511212,
		lng: 29.47694983,
		pop: 7757.5,
		country: 'South Sudan',
		iso2: 'SS',
		iso3: 'SSD',
		province: 'West Equatoria',
		timezone: 'Africa/Juba',
	},
	{
		city: 'Yei',
		city_ascii: 'Yei',
		lat: 4.090382099,
		lng: 30.68002885,
		pop: 112691,
		country: 'South Sudan',
		iso2: 'SS',
		iso3: 'SSD',
		province: 'Central Equatoria',
		timezone: 'Africa/Juba',
	},
	{
		city: 'Melut',
		city_ascii: 'Melut',
		lat: 10.43369,
		lng: 32.20003943,
		pop: 6407,
		country: 'South Sudan',
		iso2: 'SS',
		iso3: 'SSD',
		province: 'Upper Nile',
		timezone: 'Africa/Juba',
	},
	{
		city: 'Nasir',
		city_ascii: 'Nasir',
		lat: 8.600391051,
		lng: 33.06660152,
		pop: 1741,
		country: 'South Sudan',
		iso2: 'SS',
		iso3: 'SSD',
		province: 'Upper Nile',
		timezone: 'Africa/Juba',
	},
	{
		city: 'Gogrial',
		city_ascii: 'Gogrial',
		lat: 8.533728454,
		lng: 28.1166711,
		pop: 44318.5,
		country: 'South Sudan',
		iso2: 'SS',
		iso3: 'SSD',
		province: 'Warap',
		timezone: 'Africa/Juba',
	},
	{
		city: 'Kapoeta',
		city_ascii: 'Kapoeta',
		lat: 4.772123432,
		lng: 33.59023881,
		pop: 5021,
		country: 'South Sudan',
		iso2: 'SS',
		iso3: 'SSD',
		province: 'East Equatoria',
		timezone: 'Africa/Juba',
	},
	{
		city: 'Aweil',
		city_ascii: 'Aweil',
		lat: 8.766556619,
		lng: 27.40002234,
		pop: 42725,
		country: 'South Sudan',
		iso2: 'SS',
		iso3: 'SSD',
		province: 'North Bahr-al-Ghazal',
		timezone: 'Africa/Juba',
	},
	{
		city: 'Rumbek',
		city_ascii: 'Rumbek',
		lat: 6.800009988,
		lng: 29.68329382,
		pop: 17772.5,
		country: 'South Sudan',
		iso2: 'SS',
		iso3: 'SSD',
		province: 'Lakes',
		timezone: 'Africa/Juba',
	},
	{
		city: 'Yambio',
		city_ascii: 'Yambio',
		lat: 4.57053367,
		lng: 28.41634273,
		pop: 24420.5,
		country: 'South Sudan',
		iso2: 'SS',
		iso3: 'SSD',
		province: 'West Equatoria',
		timezone: 'Africa/Juba',
	},
	{
		city: 'Bor',
		city_ascii: 'Bor',
		lat: 6.207203795,
		lng: 31.55914831,
		pop: 26782,
		country: 'South Sudan',
		iso2: 'SS',
		iso3: 'SSD',
		province: 'Jungoli',
		timezone: 'Africa/Juba',
	},
	{
		city: 'Nimule',
		city_ascii: 'Nimule',
		lat: 3.599998595,
		lng: 32.05002274,
		pop: 242,
		country: 'South Sudan',
		iso2: 'SS',
		iso3: 'SSD',
		province: 'East Equatoria',
		timezone: 'Africa/Juba',
	},
	{
		city: 'Juba',
		city_ascii: 'Juba',
		lat: 4.829975198,
		lng: 31.58002559,
		pop: 111975,
		country: 'South Sudan',
		iso2: 'SS',
		iso3: 'SSD',
		province: 'Central Equatoria',
		timezone: 'Africa/Juba',
	},
	{
		city: 'Malakal',
		city_ascii: 'Malakal',
		lat: 9.536897195,
		lng: 31.65598995,
		pop: 160765,
		country: 'South Sudan',
		iso2: 'SS',
		iso3: 'SSD',
		province: 'Upper Nile',
		timezone: 'Africa/Juba',
	},
	{
		city: 'Wau',
		city_ascii: 'Wau',
		lat: 7.699980895,
		lng: 27.98996049,
		pop: 99158,
		country: 'South Sudan',
		iso2: 'SS',
		iso3: 'SSD',
		province: 'West Bahr-al-Ghazal',
		timezone: 'Africa/Juba',
	},
	{
		city: 'Merida',
		city_ascii: 'Merida',
		lat: 38.91200402,
		lng: -6.337997512,
		pop: 52423,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Extremadura',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Marbella',
		city_ascii: 'Marbella',
		lat: 36.51661989,
		lng: -4.88333012,
		pop: 153069.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Andalucía',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Linares',
		city_ascii: 'Linares',
		lat: 38.08332013,
		lng: -3.633354738,
		pop: 49549.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Andalucía',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Algeciras',
		city_ascii: 'Algeciras',
		lat: 36.12671215,
		lng: -5.466530363,
		pop: 106687.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Andalucía',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Leon',
		city_ascii: 'Leon',
		lat: 42.57997072,
		lng: -5.570006553,
		pop: 135014,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Castilla y León',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Mataro',
		city_ascii: 'Mataro',
		lat: 41.53995668,
		lng: 2.45002071,
		pop: 149826,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Cataluña',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Gijon',
		city_ascii: 'Gijon',
		lat: 43.53001609,
		lng: -5.670000449,
		pop: 303712,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Principado de Asturias',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Vitoria',
		city_ascii: 'Vitoria',
		lat: 42.84998008,
		lng: -2.669976849,
		pop: 199109.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'País Vasco',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Almeria',
		city_ascii: 'Almeria',
		lat: 36.83034751,
		lng: -2.429991497,
		pop: 152032.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Andalucía',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Malaga',
		city_ascii: 'Malaga',
		lat: 36.7204059,
		lng: -4.419999228,
		pop: 539381.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Andalucía',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Jaén',
		city_ascii: 'Jaen',
		lat: 37.77039349,
		lng: -3.799985394,
		pop: 92909,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Andalucía',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Huelva',
		city_ascii: 'Huelva',
		lat: 37.25037355,
		lng: -6.929949383,
		pop: 119732,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Andalucía',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Albacete',
		city_ascii: 'Albacete',
		lat: 39.00034426,
		lng: -1.869999839,
		pop: 127597,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Castilla-La Mancha',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Toledo',
		city_ascii: 'Toledo',
		lat: 39.86703554,
		lng: -4.016716351,
		pop: 53878.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Castilla-La Mancha',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Guadalajara',
		city_ascii: 'Guadalajara',
		lat: 40.63370709,
		lng: -3.166587363,
		pop: 51906.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Castilla-La Mancha',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Santander',
		city_ascii: 'Santander',
		lat: 43.3804645,
		lng: -3.799985394,
		pop: 196025.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Cantabria',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Salamanca',
		city_ascii: 'Salamanca',
		lat: 40.97040489,
		lng: -5.670000449,
		pop: 160456.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Castilla y León',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Burgos',
		city_ascii: 'Burgos',
		lat: 42.35039817,
		lng: -3.67996688,
		pop: 150251,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Castilla y León',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Tarragona',
		city_ascii: 'Tarragona',
		lat: 41.12036989,
		lng: 1.249990599,
		pop: 107957.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Cataluña',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Lorca',
		city_ascii: 'Lorca',
		lat: 37.68856386,
		lng: -1.698511598,
		pop: 56541.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Región de Murcia',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Cartagena',
		city_ascii: 'Cartagena',
		lat: 37.60042971,
		lng: -0.980028322,
		pop: 166276.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Región de Murcia',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Oviedo',
		city_ascii: 'Oviedo',
		lat: 43.35049217,
		lng: -5.829990683,
		pop: 223524.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Principado de Asturias',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Santiago de Compostela',
		city_ascii: 'Santiago de Compostela',
		lat: 42.88289797,
		lng: -8.541091351,
		pop: 87721,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Galicia',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Badajoz',
		city_ascii: 'Badajoz',
		lat: 38.8804291,
		lng: -6.96997278,
		pop: 115638.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Extremadura',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Logrono',
		city_ascii: 'Logrono',
		lat: 42.47036501,
		lng: -2.429991497,
		pop: 123918.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'La Rioja',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'San Sebastián',
		city_ascii: 'San Sebastian',
		lat: 43.32039064,
		lng: -1.979993125,
		pop: 270498,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'País Vasco',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Alicante',
		city_ascii: 'Alicante',
		lat: 38.3512199,
		lng: -0.483640721,
		pop: 296345,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Comunidad Valenciana',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Castello',
		city_ascii: 'Castello',
		lat: 39.97041424,
		lng: -0.05000757,
		pop: 176360,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Comunidad Valenciana',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Arrecife',
		city_ascii: 'Arrecife',
		lat: 28.96904923,
		lng: -13.53783283,
		pop: 47182.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: '',
		timezone: 'Atlantic/Canary',
	},
	{
		city: 'Cadiz',
		city_ascii: 'Cadiz',
		lat: 36.53499086,
		lng: -6.225005332,
		pop: 153932.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Andalucía',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Granada',
		city_ascii: 'Granada',
		lat: 37.16497825,
		lng: -3.585011435,
		pop: 313269,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Andalucía',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Murcia',
		city_ascii: 'Murcia',
		lat: 37.9799931,
		lng: -1.12996749,
		pop: 368322.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Región de Murcia',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Ceuta',
		city_ascii: 'Ceuta',
		lat: 35.88898378,
		lng: -5.30699935,
		pop: 78674,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Ceuta',
		timezone: 'Africa/Ceuta',
	},
	{
		city: 'La Coruña',
		city_ascii: 'La Coruna',
		lat: 43.32997662,
		lng: -8.419987632,
		pop: 306614.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Galicia',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Ourense',
		city_ascii: 'Ourense',
		lat: 42.32996014,
		lng: -7.869995363,
		pop: 113095,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Galicia',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Pamplona',
		city_ascii: 'Pamplona',
		lat: 42.82000775,
		lng: -1.649987428,
		pop: 233855.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Comunidad Foral de Navarra',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Valladolid',
		city_ascii: 'Valladolid',
		lat: 41.65000165,
		lng: -4.750030763,
		pop: 299373.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Castilla y León',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Melilla',
		city_ascii: 'Melilla',
		lat: 35.30000165,
		lng: -2.950011435,
		pop: 107384,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Melilla',
		timezone: 'Africa/Ceuta',
	},
	{
		city: 'Palma',
		city_ascii: 'Palma',
		lat: 39.57426271,
		lng: 2.65424597,
		pop: 319951,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Islas Baleares',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Zaragoza',
		city_ascii: 'Zaragoza',
		lat: 41.65000165,
		lng: -0.889982138,
		pop: 548955.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Aragón',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Santa Cruz de Tenerife',
		city_ascii: 'Santa Cruz de Tenerife',
		lat: 28.46997927,
		lng: -16.25000065,
		pop: 279125.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: '',
		timezone: 'Atlantic/Canary',
	},
	{
		city: 'Cordoba',
		city_ascii: 'Cordoba',
		lat: 37.87999921,
		lng: -4.770003704,
		pop: 300512,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Andalucía',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Vigo',
		city_ascii: 'Vigo',
		lat: 42.22001853,
		lng: -8.729994549,
		pop: 335848.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Galicia',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Bilbao',
		city_ascii: 'Bilbao',
		lat: 43.24998151,
		lng: -2.929986818,
		pop: 614369.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'País Vasco',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Las Palmas',
		city_ascii: 'Las Palmas',
		lat: 28.09997601,
		lng: -15.42999902,
		pop: 364718,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: '',
		timezone: 'Atlantic/Canary',
	},
	{
		city: 'Seville',
		city_ascii: 'Seville',
		lat: 37.40501528,
		lng: -5.980007366,
		pop: 957533,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Andalucía',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Valencia',
		city_ascii: 'Valencia',
		lat: 39.48501752,
		lng: -0.400012046,
		pop: 806652,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Comunidad Valenciana',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Barcelona',
		city_ascii: 'Barcelona',
		lat: 41.38329958,
		lng: 2.183370319,
		pop: 3250797.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Cataluña',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Madrid',
		city_ascii: 'Madrid',
		lat: 40.40002626,
		lng: -3.683351686,
		pop: 2808718.5,
		country: 'Spain',
		iso2: 'ES',
		iso3: 'ESP',
		province: 'Comunidad de Madrid',
		timezone: 'Europe/Madrid',
	},
	{
		city: 'Trincomalee',
		city_ascii: 'Trincomalee',
		lat: 8.568999036,
		lng: 81.23300155,
		pop: 108420,
		country: 'Sri Lanka',
		iso2: 'LK',
		iso3: 'LKA',
		province: 'Trincomalee',
		timezone: 'Asia/Colombo',
	},
	{
		city: 'Puttalan',
		city_ascii: 'Puttalan',
		lat: 8.033004084,
		lng: 79.82600454,
		pop: 45661,
		country: 'Sri Lanka',
		iso2: 'LK',
		iso3: 'LKA',
		province: 'Puttalam',
		timezone: 'Asia/Colombo',
	},
	{
		city: 'Ratnapura',
		city_ascii: 'Ratnapura',
		lat: 6.693003132,
		lng: 80.38600257,
		pop: 47832,
		country: 'Sri Lanka',
		iso2: 'LK',
		iso3: 'LKA',
		province: 'Ratnapura',
		timezone: 'Asia/Colombo',
	},
	{
		city: 'Batticaloa',
		city_ascii: 'Batticaloa',
		lat: 7.717008279,
		lng: 81.70001542,
		pop: 107982,
		country: 'Sri Lanka',
		iso2: 'LK',
		iso3: 'LKA',
		province: 'Batticaloa',
		timezone: 'Asia/Colombo',
	},
	{
		city: 'Kilinochchi',
		city_ascii: 'Kilinochchi',
		lat: 9.400419738,
		lng: 80.39993974,
		pop: 64358.5,
		country: 'Sri Lanka',
		iso2: 'LK',
		iso3: 'LKA',
		province: 'Kilinochchi',
		timezone: 'Asia/Colombo',
	},
	{
		city: 'Matara',
		city_ascii: 'Matara',
		lat: 5.948976663,
		lng: 80.5427734,
		pop: 68244,
		country: 'Sri Lanka',
		iso2: 'LK',
		iso3: 'LKA',
		province: 'Matara',
		timezone: 'Asia/Colombo',
	},
	{
		city: 'Badulla',
		city_ascii: 'Badulla',
		lat: 6.983693867,
		lng: 81.0499259,
		pop: 44908.5,
		country: 'Sri Lanka',
		iso2: 'LK',
		iso3: 'LKA',
		province: 'Badulla',
		timezone: 'Asia/Colombo',
	},
	{
		city: 'Moratuwa',
		city_ascii: 'Moratuwa',
		lat: 6.780398782,
		lng: 79.88002315,
		pop: 188595,
		country: 'Sri Lanka',
		iso2: 'LK',
		iso3: 'LKA',
		province: 'Colombo',
		timezone: 'Asia/Colombo',
	},
	{
		city: 'Galle',
		city_ascii: 'Galle',
		lat: 6.030005309,
		lng: 80.24000118,
		pop: 96298,
		country: 'Sri Lanka',
		iso2: 'LK',
		iso3: 'LKA',
		province: 'Galle',
		timezone: 'Asia/Colombo',
	},
	{
		city: 'Anuradhapura',
		city_ascii: 'Anuradhapura',
		lat: 8.349992898,
		lng: 80.38329993,
		pop: 89622.5,
		country: 'Sri Lanka',
		iso2: 'LK',
		iso3: 'LKA',
		province: 'Anuradhapura',
		timezone: 'Asia/Colombo',
	},
	{
		city: 'Jaffna',
		city_ascii: 'Jaffna',
		lat: 9.675002461,
		lng: 80.00502844,
		pop: 169069,
		country: 'Sri Lanka',
		iso2: 'LK',
		iso3: 'LKA',
		province: 'Jaffna',
		timezone: 'Asia/Colombo',
	},
	{
		city: 'Kandy',
		city_ascii: 'Kandy',
		lat: 7.279980691,
		lng: 80.67000077,
		pop: 111701,
		country: 'Sri Lanka',
		iso2: 'LK',
		iso3: 'LKA',
		province: 'Kandy',
		timezone: 'Asia/Colombo',
	},
	{
		city: 'Sri Jawewardenepura Kotte',
		city_ascii: 'Sri Jawewardenepura Kotte',
		lat: 6.900003885,
		lng: 79.94999304,
		pop: 115826,
		country: 'Sri Lanka',
		iso2: 'LK',
		iso3: 'LKA',
		province: 'Colombo',
		timezone: 'Asia/Colombo',
	},
	{
		city: 'Colombo',
		city_ascii: 'Colombo',
		lat: 6.931965758,
		lng: 79.85775061,
		pop: 217000,
		country: 'Sri Lanka',
		iso2: 'LK',
		iso3: 'LKA',
		province: 'Colombo',
		timezone: 'Asia/Colombo',
	},
	{
		city: 'Ad Damazin',
		city_ascii: 'Ed Damazin',
		lat: 11.77040428,
		lng: 34.34998572,
		pop: 114030,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Blue Nile',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Haiya',
		city_ascii: 'Haiya',
		lat: 18.33623089,
		lng: 36.3841768,
		pop: 17409,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Red Sea',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'El Manaqil',
		city_ascii: 'El Manaqil',
		lat: 14.2503821,
		lng: 32.97999182,
		pop: 140062,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Gezira',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Shendi',
		city_ascii: 'Shendi',
		lat: 16.68049217,
		lng: 33.42001664,
		pop: 120089.5,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'River Nile',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Berber',
		city_ascii: 'Berber',
		lat: 18.01702557,
		lng: 33.98328975,
		pop: 35975.5,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'River Nile',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Kerma',
		city_ascii: 'Kerma',
		lat: 19.63369692,
		lng: 30.4165824,
		pop: 3928,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Northern',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Ed Dueim',
		city_ascii: 'Ed Dueim',
		lat: 13.99039797,
		lng: 32.29998165,
		pop: 54825.5,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'White Nile',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Umm Ruwaba',
		city_ascii: 'Umm Ruwaba',
		lat: 12.91043805,
		lng: 31.19999711,
		pop: 35999.5,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'North Kurdufan',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'En Nuhud',
		city_ascii: 'En Nuhud',
		lat: 12.69042564,
		lng: 28.42001176,
		pop: 84623.5,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'South Kordofan',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Muglad',
		city_ascii: 'Muglad',
		lat: 11.03373089,
		lng: 27.73333533,
		pop: 17344,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'South Kordofan',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Tokar',
		city_ascii: 'Tokar',
		lat: 18.43333091,
		lng: 37.73329342,
		pop: 47726,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Red Sea',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Medani',
		city_ascii: 'Medani',
		lat: 14.39995953,
		lng: 33.52001054,
		pop: 308540.5,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Gezira',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Gedaref',
		city_ascii: 'Gedaref',
		lat: 14.03998151,
		lng: 35.38000036,
		pop: 201282,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Gedarif',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'EdDamer',
		city_ascii: 'EdDamer',
		lat: 17.58997154,
		lng: 33.95998368,
		pop: 94398.5,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'River Nile',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Atbara',
		city_ascii: 'Atbara',
		lat: 17.70999005,
		lng: 33.97998246,
		pop: 138271,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'River Nile',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Wadi Halfa',
		city_ascii: 'Wadi Halfa',
		lat: 21.80002464,
		lng: 31.34996212,
		pop: 17121,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Northern',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Merowe',
		city_ascii: 'Merowe',
		lat: 18.48330202,
		lng: 31.81665198,
		pop: 7405,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Northern',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Kosti',
		city_ascii: 'Kosti',
		lat: 13.16998293,
		lng: 32.66001135,
		pop: 274463,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'White Nile',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Sennar',
		city_ascii: 'Sennar',
		lat: 13.54995974,
		lng: 33.60000565,
		pop: 103308,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Sennar',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'El Fasher',
		city_ascii: 'El Fasher',
		lat: 13.62998069,
		lng: 25.35001827,
		pop: 220906,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Northern Darfur',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Kadugli',
		city_ascii: 'Kadugli',
		lat: 11.00995974,
		lng: 29.70003699,
		pop: 132298.5,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'South Kordufan',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Babanusa',
		city_ascii: 'Babanusa',
		lat: 11.33335085,
		lng: 27.79999792,
		pop: 19700,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'South Kordofan',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Geneina',
		city_ascii: 'Geneina',
		lat: 13.45001752,
		lng: 22.44001501,
		pop: 161145.5,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'West Darfur',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Omdurman',
		city_ascii: 'Omdurman',
		lat: 15.61668113,
		lng: 32.48002234,
		pop: 2289428.5,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Khartoum',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'El Obeid',
		city_ascii: 'El Obeid',
		lat: 13.18328961,
		lng: 30.21669796,
		pop: 331367.5,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'North Kurdufan',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Port Sudan',
		city_ascii: 'Port Sudan',
		lat: 19.61579103,
		lng: 37.21642574,
		pop: 489725,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Red Sea',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Niyala',
		city_ascii: 'Niyala',
		lat: 12.05997316,
		lng: 24.88999467,
		pop: 392373,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'South Darfur',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Dongola',
		city_ascii: 'Dongola',
		lat: 19.16659365,
		lng: 30.48329667,
		pop: 26404,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Northern',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Kassala',
		city_ascii: 'Kassala',
		lat: 15.45997235,
		lng: 36.39001623,
		pop: 370499,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Kassala',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Khartoum',
		city_ascii: 'Khartoum',
		lat: 15.58807823,
		lng: 32.53417924,
		pop: 3364323.5,
		country: 'Sudan',
		iso2: 'SD',
		iso3: 'SDN',
		province: 'Khartoum',
		timezone: 'Africa/Khartoum',
	},
	{
		city: 'Onverwacht',
		city_ascii: 'Onverwacht',
		lat: 5.599998125,
		lng: -55.19999648,
		pop: 2105,
		country: 'Suriname',
		iso2: 'SR',
		iso3: 'SUR',
		province: 'Para',
		timezone: 'America/Paramaribo',
	},
	{
		city: 'Groningen',
		city_ascii: 'Groningen',
		lat: 5.796998975,
		lng: -55.48100346,
		pop: 3216,
		country: 'Suriname',
		iso2: 'SR',
		iso3: 'SUR',
		province: 'Saramacca',
		timezone: 'America/Paramaribo',
	},
	{
		city: 'Brownsweg',
		city_ascii: 'Brownsweg',
		lat: 5.020015278,
		lng: -55.1699506,
		pop: 3639,
		country: 'Suriname',
		iso2: 'SR',
		iso3: 'SUR',
		province: 'Brokopondo',
		timezone: 'America/Paramaribo',
	},
	{
		city: 'Moengo',
		city_ascii: 'Moengo',
		lat: 5.630003885,
		lng: -54.40999699,
		pop: 7420,
		country: 'Suriname',
		iso2: 'SR',
		iso3: 'SUR',
		province: 'Marowijne',
		timezone: 'America/Paramaribo',
	},
	{
		city: 'Nieuw Amsterdam',
		city_ascii: 'Nieuw Amsterdam',
		lat: 5.909986795,
		lng: -55.07000838,
		pop: 4935,
		country: 'Suriname',
		iso2: 'SR',
		iso3: 'SUR',
		province: 'Commewijne',
		timezone: 'America/Paramaribo',
	},
	{
		city: 'Nieuw Nickerie',
		city_ascii: 'Nieuw Nickerie',
		lat: 5.950423603,
		lng: -56.98999455,
		pop: 14567.5,
		country: 'Suriname',
		iso2: 'SR',
		iso3: 'SUR',
		province: 'Nickerie',
		timezone: 'America/Paramaribo',
	},
	{
		city: 'Brokopondo',
		city_ascii: 'Brokopondo',
		lat: 5.040375792,
		lng: -55.02001144,
		pop: 6170,
		country: 'Suriname',
		iso2: 'SR',
		iso3: 'SUR',
		province: 'Brokopondo',
		timezone: 'America/Paramaribo',
	},
	{
		city: 'Totness',
		city_ascii: 'Totness',
		lat: 5.890427265,
		lng: -56.31998377,
		pop: 1468,
		country: 'Suriname',
		iso2: 'SR',
		iso3: 'SUR',
		province: 'Coronie',
		timezone: 'America/Paramaribo',
	},
	{
		city: 'Cottica',
		city_ascii: 'Cottica',
		lat: 3.850009175,
		lng: -54.23328943,
		pop: 24605,
		country: 'Suriname',
		iso2: 'SR',
		iso3: 'SUR',
		province: 'Sipaliwini',
		timezone: 'America/Paramaribo',
	},
	{
		city: 'Paramaribo',
		city_ascii: 'Paramaribo',
		lat: 5.83503013,
		lng: -55.16703089,
		pop: 238963,
		country: 'Suriname',
		iso2: 'SR',
		iso3: 'SUR',
		province: 'Paramaribo',
		timezone: 'America/Paramaribo',
	},
	{
		city: 'Longyearbyen',
		city_ascii: 'Longyearbyen',
		lat: 78.21668439,
		lng: 15.5499963,
		pop: 1232,
		country: 'Svalbard and Jan Mayen Islands',
		iso2: 'SJ',
		iso3: 'SJM',
		province: 'Svalbard',
		timezone: 'Arctic/Longyearbyen',
	},
	{
		city: 'Piggs Peak',
		city_ascii: 'Piggs Peak',
		lat: -25.96100399,
		lng: 31.2470015,
		pop: 5750,
		country: 'Swaziland',
		iso2: 'SZ',
		iso3: 'SWZ',
		province: 'Hhohho',
		timezone: 'Africa/Mbabane',
	},
	{
		city: 'Siteki',
		city_ascii: 'Siteki',
		lat: -26.45499604,
		lng: 31.95199862,
		pop: 6152,
		country: 'Swaziland',
		iso2: 'SZ',
		iso3: 'SWZ',
		province: 'Lubombo',
		timezone: 'Africa/Mbabane',
	},
	{
		city: 'Manzini',
		city_ascii: 'Manzini',
		lat: -26.49500106,
		lng: 31.3880045,
		pop: 110537,
		country: 'Swaziland',
		iso2: 'SZ',
		iso3: 'SWZ',
		province: 'Manzini',
		timezone: 'Africa/Mbabane',
	},
	{
		city: 'Hlatikulu',
		city_ascii: 'Hlatikulu',
		lat: -26.99999993,
		lng: 31.41669655,
		pop: 2748,
		country: 'Swaziland',
		iso2: 'SZ',
		iso3: 'SWZ',
		province: 'Shiselweni',
		timezone: 'Africa/Mbabane',
	},
	{
		city: 'Golela',
		city_ascii: 'Golela',
		lat: -27.29617755,
		lng: 31.90000606,
		pop: 3695,
		country: 'Swaziland',
		iso2: 'SZ',
		iso3: 'SWZ',
		province: 'Shiselweni',
		timezone: 'Africa/Mbabane',
	},
	{
		city: 'Lobamba',
		city_ascii: 'Lobamba',
		lat: -26.46666746,
		lng: 31.19999711,
		pop: 7169.5,
		country: 'Swaziland',
		iso2: 'SZ',
		iso3: 'SWZ',
		province: 'Manzini',
		timezone: 'Africa/Mbabane',
	},
	{
		city: 'Mbabane',
		city_ascii: 'Mbabane',
		lat: -26.31665078,
		lng: 31.13333451,
		pop: 83178,
		country: 'Swaziland',
		iso2: 'SZ',
		iso3: 'SWZ',
		province: 'Hhohho',
		timezone: 'Africa/Mbabane',
	},
	{
		city: 'Falun',
		city_ascii: 'Falun',
		lat: 60.61300204,
		lng: 15.64700455,
		pop: 36477,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Dalarna',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Nykoping',
		city_ascii: 'Nykoping',
		lat: 58.76399718,
		lng: 17.01500451,
		pop: 27582,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Södermanland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Harnosand',
		city_ascii: 'Harnosand',
		lat: 62.63399704,
		lng: 17.93400362,
		pop: 17016,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Västernorrland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Karlskrona',
		city_ascii: 'Karlskrona',
		lat: 56.20300219,
		lng: 15.29600461,
		pop: 35212,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Blekinge',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Mariestad',
		city_ascii: 'Mariestad',
		lat: 58.70500209,
		lng: 13.82799659,
		pop: 14891,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Västra Götaland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Vannersborg',
		city_ascii: 'Vannersborg',
		lat: 58.36300214,
		lng: 12.33000061,
		pop: 21835,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Västra Götaland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Borlänge',
		city_ascii: 'Borlange',
		lat: 60.48332237,
		lng: 15.4166711,
		pop: 38974.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Dalarna',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Västerås',
		city_ascii: 'Vasteraas',
		lat: 59.63001528,
		lng: 16.54001339,
		pop: 100186.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Västmanland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Bollnäs',
		city_ascii: 'Bollnas',
		lat: 61.35200319,
		lng: 16.36658728,
		pop: 12771.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Gävleborg',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Gävle',
		city_ascii: 'Gavle',
		lat: 60.66698041,
		lng: 17.1666418,
		pop: 68235.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Gävleborg',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Kalmar',
		city_ascii: 'Kalmar',
		lat: 56.66701784,
		lng: 16.36658728,
		pop: 34884,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Kalmar',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Växjö',
		city_ascii: 'Vaxjo',
		lat: 56.88369712,
		lng: 14.81670772,
		pop: 53067.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Kronoberg',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Örebro',
		city_ascii: 'Orebro',
		lat: 59.2803467,
		lng: 15.2199906,
		pop: 95932,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Orebro',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Norrköping',
		city_ascii: 'Norrkoping',
		lat: 58.59542727,
		lng: 16.17869177,
		pop: 85771,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Östergötland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Halmstad',
		city_ascii: 'Halmstad',
		lat: 56.67177207,
		lng: 12.85558712,
		pop: 55433,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Halland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Karlstad',
		city_ascii: 'Karlstad',
		lat: 59.36713727,
		lng: 13.49994055,
		pop: 66703.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Värmland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Skellefteå',
		city_ascii: 'Skelleftea',
		lat: 64.77207867,
		lng: 20.95002844,
		pop: 31075,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Västerbotten',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Visby',
		city_ascii: 'Visby',
		lat: 57.63365135,
		lng: 18.30000932,
		pop: 22281.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Gotland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Trollhättan',
		city_ascii: 'Trollhattan',
		lat: 58.26710105,
		lng: 12.29996212,
		pop: 44532.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Västra Götaland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Borås',
		city_ascii: 'Boras',
		lat: 57.7304413,
		lng: 12.91997595,
		pop: 64115.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Västra Götaland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Kristianstad',
		city_ascii: 'Kristianstad',
		lat: 56.03367149,
		lng: 14.1332869,
		pop: 26763.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Skåne',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Helsingborg',
		city_ascii: 'Helsingborg',
		lat: 56.05049217,
		lng: 12.70004106,
		pop: 91164.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Skåne',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Jönköping',
		city_ascii: 'Jonkoping',
		lat: 57.7713432,
		lng: 14.16501623,
		pop: 86491,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Jönköping',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Örnsköldsvik',
		city_ascii: 'Ornskoldsvik',
		lat: 63.31800722,
		lng: 18.71667639,
		pop: 26406,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Västernorrland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Linköping',
		city_ascii: 'Linkoping',
		lat: 58.41001223,
		lng: 15.62993974,
		pop: 94111.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Östergötland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Östersund',
		city_ascii: 'Ostersund',
		lat: 63.1833126,
		lng: 14.64999955,
		pop: 44559,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Jämtland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Kiruna',
		city_ascii: 'Kiruna',
		lat: 67.8500045,
		lng: 20.21663651,
		pop: 13302,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Norrbotten',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Umeå',
		city_ascii: 'Umea',
		lat: 63.82999147,
		lng: 20.23999426,
		pop: 76101,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Västerbotten',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Uppsala',
		city_ascii: 'Uppsala',
		lat: 59.86005292,
		lng: 17.63999792,
		pop: 130425.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Uppsala',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Göteborg',
		city_ascii: 'Goteborg',
		lat: 57.75000083,
		lng: 12.0000321,
		pop: 520940.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Västra Götaland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Luleå',
		city_ascii: 'Lulea',
		lat: 65.59663477,
		lng: 22.15837833,
		pop: 47094.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Norrbotten',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Sundsvall',
		city_ascii: 'Sundsvall',
		lat: 62.40005292,
		lng: 17.31665849,
		pop: 60926,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Västernorrland',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Malmö',
		city_ascii: 'Malmo',
		lat: 55.58333722,
		lng: 13.03330237,
		pop: 265448.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Skåne',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Stockholm',
		city_ascii: 'Stockholm',
		lat: 59.35075995,
		lng: 18.09733473,
		pop: 1258654.5,
		country: 'Sweden',
		iso2: 'SE',
		iso3: 'SWE',
		province: 'Stockholm',
		timezone: 'Europe/Stockholm',
	},
	{
		city: 'Delemont',
		city_ascii: 'Delemont',
		lat: 47.36999713,
		lng: 7.344999488,
		pop: 11315,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Jura',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Neuchatel',
		city_ascii: 'Neuchatel',
		lat: 46.99899914,
		lng: 6.922998615,
		pop: 31270,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Neuchâtel',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Aarau',
		city_ascii: 'Aarau',
		lat: 47.3900041,
		lng: 8.03400361,
		pop: 15501,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Aargau',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Stans',
		city_ascii: 'Stans',
		lat: 46.95000307,
		lng: 8.383302547,
		pop: 7475,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Nidwalden',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Sion',
		city_ascii: 'Sion',
		lat: 46.23900303,
		lng: 7.353999482,
		pop: 28045,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Valais',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Herisau',
		city_ascii: 'Herisau',
		lat: 47.38329903,
		lng: 9.283302472,
		pop: 15438,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Appenzell Ausserrhoden',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Saint Gallen',
		city_ascii: 'Saint Gallen',
		lat: 47.42299807,
		lng: 9.361998557,
		pop: 70572,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Sankt Gallen',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Bellinzona',
		city_ascii: 'Bellinzona',
		lat: 46.19700013,
		lng: 9.019998609,
		pop: 16572,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Ticino',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Glarus',
		city_ascii: 'Glarus',
		lat: 47.05000204,
		lng: 9.066699587,
		pop: 5681,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Glarus',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Schaffhausen',
		city_ascii: 'Schaffhausen',
		lat: 47.70600309,
		lng: 8.632998523,
		pop: 33863,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Schaffhausen',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Schwyz',
		city_ascii: 'Schwyz',
		lat: 47.01999604,
		lng: 8.648001603,
		pop: 14177,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Schwyz',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Frauenfeld',
		city_ascii: 'Frauenfeld',
		lat: 47.56799717,
		lng: 9.108000502,
		pop: 21979,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Thurgau',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Altdorf',
		city_ascii: 'Altdorf',
		lat: 46.87900214,
		lng: 8.638002584,
		pop: 8678,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Uri',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Zug',
		city_ascii: 'Zug',
		lat: 47.17899903,
		lng: 8.487000565,
		pop: 23435,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Zug',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Fribourg',
		city_ascii: 'Fribourg',
		lat: 46.80000008,
		lng: 7.149996522,
		pop: 32827,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Fribourg',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Liestal',
		city_ascii: 'Liestal',
		lat: 47.48300112,
		lng: 7.737003468,
		pop: 12832,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Basel-Landschaft',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Solothurn',
		city_ascii: 'Solothurn',
		lat: 47.2120021,
		lng: 7.536996608,
		pop: 14853,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Solothurn',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Sarnen',
		city_ascii: 'Sarnen',
		lat: 46.89900002,
		lng: 8.243001529,
		pop: 9410,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Obwalden',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Appenzell',
		city_ascii: 'Appenzell',
		lat: 47.3333041,
		lng: 9.416700505,
		pop: 5649,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Appenzell Innerrhoden',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Chur',
		city_ascii: 'Chur',
		lat: 46.85002016,
		lng: 9.500029662,
		pop: 35361,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Graubünden',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Biel',
		city_ascii: 'Biel',
		lat: 47.16658999,
		lng: 7.2500378,
		pop: 63661,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Bern',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Luzern',
		city_ascii: 'Luzern',
		lat: 47.05042137,
		lng: 8.280000772,
		pop: 163745.5,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Lucerne',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Lugano',
		city_ascii: 'Lugano',
		lat: 46.0003821,
		lng: 8.966677204,
		pop: 65876.5,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Ticino',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Lausanne',
		city_ascii: 'Lausanne',
		lat: 46.53042727,
		lng: 6.650022744,
		pop: 191226.5,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Vaud',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Basel',
		city_ascii: 'Basel',
		lat: 47.58038902,
		lng: 7.590017048,
		pop: 500317.5,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Basel-Stadt',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Bern',
		city_ascii: 'Bern',
		lat: 46.91668276,
		lng: 7.466975462,
		pop: 198480,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Bern',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Zürich',
		city_ascii: 'Zurich',
		lat: 47.37998781,
		lng: 8.55001013,
		pop: 724865,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Zürich',
		timezone: 'Europe/Zurich',
	},
	{
		city: 'Geneva',
		city_ascii: 'Geneva',
		lat: 46.21000755,
		lng: 6.140028034,
		pop: 716192.5,
		country: 'Switzerland',
		iso2: 'CH',
		iso3: 'CHE',
		province: 'Genève',
		timezone: 'Europe/Zurich',
	},
	{
		city: "Dar'a",
		city_ascii: "Dar'a",
		lat: 32.62500014,
		lng: 36.10500351,
		pop: 122225,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Dar`a',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Al Ladhiqiyah',
		city_ascii: 'Al Ladhiqiyah',
		lat: 35.539987,
		lng: 35.77997595,
		pop: 439664,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Lattakia (Al Ladhiqiyah)',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Madinat ath Thawrah',
		city_ascii: 'Madinat ath Thawrah',
		lat: 35.8366614,
		lng: 38.54807572,
		pop: 85590,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Ar Raqqah',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Izaz',
		city_ascii: 'Izaz',
		lat: 36.58878603,
		lng: 37.04408484,
		pop: 31534,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Aleppo (Halab)',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Manbij',
		city_ascii: 'Manbij',
		lat: 36.52664512,
		lng: 37.9563289,
		pop: 94528.5,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Aleppo (Halab)',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Idlib',
		city_ascii: 'Idlib',
		lat: 35.92970481,
		lng: 36.63165523,
		pop: 115785.5,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Idlib',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Al Qamishli',
		city_ascii: 'Al Qamishli',
		lat: 37.03002525,
		lng: 41.22997921,
		pop: 104107,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Hasaka (Al Haksa)',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Al Hasakah',
		city_ascii: 'Al Hasakah',
		lat: 36.48328859,
		lng: 40.7500085,
		pop: 104819.5,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Hasaka (Al Haksa)',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Douma',
		city_ascii: 'Duma',
		lat: 33.5833364,
		lng: 36.39998979,
		pop: 406912,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Damascus',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Tartus',
		city_ascii: 'Tartus',
		lat: 34.88463448,
		lng: 35.88658405,
		pop: 139374.5,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Tartus',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Ar Raqqah',
		city_ascii: 'Ar Raqqah',
		lat: 35.93037661,
		lng: 39.0199849,
		pop: 175600.5,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Ar Raqqah',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Hamah',
		city_ascii: 'Hamah',
		lat: 35.1503467,
		lng: 36.72999548,
		pop: 439796,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Hamah',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Tadmur',
		city_ascii: 'Tadmur',
		lat: 34.55040916,
		lng: 38.28333736,
		pop: 53063,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Homs (Hims)',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Abu Kamal',
		city_ascii: 'Abu Kamal',
		lat: 34.45041526,
		lng: 40.9186287,
		pop: 69190,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Dayr Az Zawr',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Dayr az Zawr',
		city_ascii: 'Dayr az Zawr',
		lat: 35.33038739,
		lng: 40.12999467,
		pop: 275853,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Dayr Az Zawr',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'As Suwayda',
		city_ascii: 'As Suwayda',
		lat: 32.70041872,
		lng: 36.5665946,
		pop: 65650,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'As Suwayda',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Ad Nabk',
		city_ascii: 'Ad Nabk',
		lat: 34.01703086,
		lng: 36.73330277,
		pop: 49775,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Damascus',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Al Qunaytirah',
		city_ascii: 'Al Qunaytirah',
		lat: 33.12566408,
		lng: 35.82359086,
		pop: 2235.5,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Golan',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Hims',
		city_ascii: 'Hims',
		lat: 34.72995892,
		lng: 36.72002193,
		pop: 890202,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Homs (Hims)',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Aleppo',
		city_ascii: 'Aleppo',
		lat: 36.22997072,
		lng: 37.1700203,
		pop: 2170132,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Aleppo (Halab)',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Damascus',
		city_ascii: 'Damascus',
		lat: 33.500034,
		lng: 36.29999589,
		pop: 2466000,
		country: 'Syria',
		iso2: 'SY',
		iso3: 'SYR',
		province: 'Damascus',
		timezone: 'Asia/Damascus',
	},
	{
		city: 'Bade',
		city_ascii: 'Bade',
		lat: 24.9575,
		lng: 121.2988889,
		pop: 172065,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Taoyuan',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Pingzhen',
		city_ascii: 'Pingzhen',
		lat: 24.94388889,
		lng: 121.2161111,
		pop: 201632,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Taoyuan',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Taibao',
		city_ascii: 'Taibao',
		lat: 23.45,
		lng: 120.3333333,
		pop: 34665,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Chiayi',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Taoyuan',
		city_ascii: 'Taoyuan',
		lat: 24.98888889,
		lng: 121.3111111,
		pop: 451007,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Taoyuan',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Yangmei',
		city_ascii: 'Yangmei',
		lat: 24.91666667,
		lng: 121.15,
		pop: 162511,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Taoyuan',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Yilan',
		city_ascii: 'Yilan',
		lat: 24.75,
		lng: 121.75,
		pop: 122915.5,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Yilan',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Zhubei',
		city_ascii: 'Zhubei',
		lat: 24.83333333,
		lng: 121.0119444,
		pop: 174003,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Hsinchu',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Douliou',
		city_ascii: 'Douliou',
		lat: 23.7075,
		lng: 120.5438889,
		pop: 105688,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Yunlin',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Zhongli',
		city_ascii: 'Zhongli',
		lat: 24.96502525,
		lng: 121.2167765,
		pop: 1001193,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Taoyuan',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Keelung',
		city_ascii: 'Keelung',
		lat: 25.13325787,
		lng: 121.7332824,
		pop: 443603.5,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Keelung City',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Nantou',
		city_ascii: 'Nantou',
		lat: 23.91666667,
		lng: 120.6833333,
		pop: 134349,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Nantou',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Puzi',
		city_ascii: 'Puzi',
		lat: 23.46111111,
		lng: 120.2419444,
		pop: 47042,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Chiayi',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Changhua',
		city_ascii: 'Changhua',
		lat: 24.07340008,
		lng: 120.5134086,
		pop: 493294,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Changhua',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Chiayi',
		city_ascii: 'Chiayi',
		lat: 23.47545209,
		lng: 120.4350671,
		pop: 387106,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Chiayi City',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Hsinchu',
		city_ascii: 'Hsinchu',
		lat: 24.8167914,
		lng: 120.9767395,
		pop: 582778.5,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Hsinchu City',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Miaoli',
		city_ascii: 'Miaoli',
		lat: 24.57,
		lng: 120.82,
		pop: 120494,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Miaoli',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Pingtung',
		city_ascii: 'Pingtung',
		lat: 22.68170209,
		lng: 120.4816792,
		pop: 359752.5,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Pingtung',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Hualien',
		city_ascii: 'Hualien',
		lat: 23.98374147,
		lng: 121.6000089,
		pop: 229563,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Hualien',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'New Taipei',
		city_ascii: 'New Taipei',
		lat: 25.01277778,
		lng: 121.465,
		pop: 2821870,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'New Taipei City',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Tainan',
		city_ascii: 'Tainan',
		lat: 23.00000307,
		lng: 120.2000427,
		pop: 1319156,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Tainan City',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Taitung',
		city_ascii: 'Taitung',
		lat: 22.75539268,
		lng: 121.140037,
		pop: 142292,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Taitung',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Magong',
		city_ascii: 'Magong',
		lat: 23.56666667,
		lng: 119.5833333,
		pop: 56435,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Penghu',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Taichung',
		city_ascii: 'Taichung',
		lat: 24.15207745,
		lng: 120.681667,
		pop: 1835024,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Taichung City',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Kaohsiung',
		city_ascii: 'Kaohsiung',
		lat: 22.63330711,
		lng: 120.2666019,
		pop: 2144391.5,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Kaohsiung City',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Taipei',
		city_ascii: 'Taipei',
		lat: 25.03583333,
		lng: 121.5683333,
		pop: 4759522.5,
		country: 'Taiwan',
		iso2: 'TW',
		iso3: 'TWN',
		province: 'Taipei City',
		timezone: 'Asia/Taipei',
	},
	{
		city: 'Leninobod',
		city_ascii: 'Leninobod',
		lat: 39.75000301,
		lng: 69.00000365,
		pop: 11468,
		country: 'Tajikistan',
		iso2: 'TJ',
		iso3: 'TJK',
		province: 'Leninabad',
		timezone: 'Asia/Dushanbe',
	},
	{
		city: 'Qurghonteppa',
		city_ascii: 'Qurghonteppa',
		lat: 37.83731447,
		lng: 68.77134721,
		pop: 188287,
		country: 'Tajikistan',
		iso2: 'TJ',
		iso3: 'TJK',
		province: 'Khatlon',
		timezone: 'Asia/Dushanbe',
	},
	{
		city: 'Konibodom',
		city_ascii: 'Konibodom',
		lat: 40.29215171,
		lng: 70.42716345,
		pop: 155117.5,
		country: 'Tajikistan',
		iso2: 'TJ',
		iso3: 'TJK',
		province: 'Leninabad',
		timezone: 'Asia/Dushanbe',
	},
	{
		city: 'Kuybyshevskiy',
		city_ascii: 'Kuybyshevskiy',
		lat: 37.97939882,
		lng: 68.68897497,
		pop: 8925,
		country: 'Tajikistan',
		iso2: 'TJ',
		iso3: 'TJK',
		province: 'Khatlon',
		timezone: 'Asia/Dushanbe',
	},
	{
		city: 'Kulob',
		city_ascii: 'Kulob',
		lat: 37.92115948,
		lng: 69.77573035,
		pop: 96975,
		country: 'Tajikistan',
		iso2: 'TJ',
		iso3: 'TJK',
		province: 'Khatlon',
		timezone: 'Asia/Dushanbe',
	},
	{
		city: 'Uroteppa',
		city_ascii: 'Uroteppa',
		lat: 39.92191591,
		lng: 69.00146236,
		pop: 104736,
		country: 'Tajikistan',
		iso2: 'TJ',
		iso3: 'TJK',
		province: 'Leninabad',
		timezone: 'Asia/Dushanbe',
	},
	{
		city: 'Khorugh',
		city_ascii: 'Khorugh',
		lat: 37.4875167,
		lng: 71.54756018,
		pop: 21017.5,
		country: 'Tajikistan',
		iso2: 'TJ',
		iso3: 'TJK',
		province: 'Gorno-Badakhshan',
		timezone: 'Asia/Dushanbe',
	},
	{
		city: 'Khujand',
		city_ascii: 'Khujand',
		lat: 40.2899813,
		lng: 69.6199259,
		pop: 291274.5,
		country: 'Tajikistan',
		iso2: 'TJ',
		iso3: 'TJK',
		province: 'Leninabad',
		timezone: 'Asia/Dushanbe',
	},
	{
		city: 'Dushanbe',
		city_ascii: 'Dushanbe',
		lat: 38.56003522,
		lng: 68.77387935,
		pop: 882822,
		country: 'Tajikistan',
		iso2: 'TJ',
		iso3: 'TJK',
		province: 'Tadzhikistan Territories',
		timezone: 'Asia/Dushanbe',
	},
	{
		city: 'Wete',
		city_ascii: 'Wete',
		lat: -5.063463959,
		lng: 39.725799,
		pop: 26450,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Kaskazini-Pemba',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Kibaha',
		city_ascii: 'Kibaha',
		lat: -6.766703953,
		lng: 38.9167026,
		pop: 23651,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Pwani',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Mkokotoni',
		city_ascii: 'Mkokotoni',
		lat: -5.878997975,
		lng: 39.25999862,
		pop: 2572,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Kaskazini-Unguja',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Tunduma',
		city_ascii: 'Tunduma',
		lat: -9.284615459,
		lng: 32.77493974,
		pop: 27543,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Mbeya',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Tukuyu',
		city_ascii: 'Tukuyu',
		lat: -9.249578838,
		lng: 33.64000321,
		pop: 77984,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Mbeya',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Sumbawanga',
		city_ascii: 'Sumbawanga',
		lat: -7.959580059,
		lng: 31.62002315,
		pop: 76546.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Rukwa',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Mpanda',
		city_ascii: 'Mpanda',
		lat: -6.359574362,
		lng: 31.0500321,
		pop: 73338,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Rukwa',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Kipili',
		city_ascii: 'Kipili',
		lat: -7.432893861,
		lng: 30.59998205,
		pop: 1533,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Rukwa',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Karema',
		city_ascii: 'Karema',
		lat: -6.816187318,
		lng: 30.43327388,
		pop: 14507.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Rukwa',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Geita',
		city_ascii: 'Geita',
		lat: -2.866195863,
		lng: 32.16660478,
		pop: 1536,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Mwanza',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Nyahanga',
		city_ascii: 'Nyahanga',
		lat: -2.382917868,
		lng: 33.55003454,
		pop: 16092,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Mwanza',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Kahama',
		city_ascii: 'Kahama',
		lat: -3.819574362,
		lng: 32.58001623,
		pop: 35279,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Shinyanga',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Shinyanga',
		city_ascii: 'Shinyanga',
		lat: -3.659584128,
		lng: 33.42001664,
		pop: 93794,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Shinyanga',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Nzega',
		city_ascii: 'Nzega',
		lat: -4.209628073,
		lng: 33.18003129,
		pop: 18325,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Tabora',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Sikonge',
		city_ascii: 'Sikonge',
		lat: -5.629541403,
		lng: 32.77003048,
		pop: 14549.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Tabora',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Biharamulo',
		city_ascii: 'Biharamulo',
		lat: -2.629621156,
		lng: 31.31001623,
		pop: 21817.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Kagera',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Bukoba',
		city_ascii: 'Bukoba',
		lat: -1.319623597,
		lng: 31.79996049,
		pop: 85566,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Kagera',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Ngara',
		city_ascii: 'Ngara',
		lat: -2.469527569,
		lng: 30.65000484,
		pop: 10744,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Kagera',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Kakonko',
		city_ascii: 'Kakonko',
		lat: -3.279607321,
		lng: 30.96001176,
		pop: 16319,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Kigoma',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Kasulu',
		city_ascii: 'Kasulu',
		lat: -4.579579652,
		lng: 30.10001257,
		pop: 31218.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Kigoma',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Kanyato',
		city_ascii: 'Kanyato',
		lat: -4.456538067,
		lng: 30.26139807,
		pop: 232,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Kigoma',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Uvinza',
		city_ascii: 'Uvinza',
		lat: -5.119598369,
		lng: 30.39002071,
		pop: 52500,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Kigoma',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Kigoma',
		city_ascii: 'Kigoma',
		lat: -4.879613018,
		lng: 29.61001664,
		pop: 164268,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Kigoma',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Mikumi',
		city_ascii: 'Mikumi',
		lat: -7.399614239,
		lng: 36.98000606,
		pop: 12471,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Morogoro',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Ifakara',
		city_ascii: 'Ifakara',
		lat: -8.129595521,
		lng: 36.68002437,
		pop: 27929.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Morogoro',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Kilosa',
		city_ascii: 'Kilosa',
		lat: -6.839596742,
		lng: 36.99003129,
		pop: 52558,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Morogoro',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Chake Chake',
		city_ascii: 'Chake Chake',
		lat: -5.239539369,
		lng: 39.77001664,
		pop: 35822.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Kusini-Pemba',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Kibiti',
		city_ascii: 'Kibiti',
		lat: -7.729619935,
		lng: 38.95001501,
		pop: 15553,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Pwani',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Bagamoyo',
		city_ascii: 'Bagamoyo',
		lat: -6.439621156,
		lng: 38.89001868,
		pop: 41609.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Pwani',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Kilindoni',
		city_ascii: 'Kilindoni',
		lat: -7.916275209,
		lng: 39.65002397,
		pop: 7994.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Pwani',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Mpwapwa',
		city_ascii: 'Mpwapwa',
		lat: -6.349600811,
		lng: 36.4799849,
		pop: 10093.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Dodoma',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Njombe',
		city_ascii: 'Njombe',
		lat: -9.329625632,
		lng: 34.77001176,
		pop: 42017.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Iringa',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Iringa',
		city_ascii: 'Iringa',
		lat: -7.769617494,
		lng: 35.69000728,
		pop: 103290,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Iringa',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Masasi',
		city_ascii: 'Masasi',
		lat: -10.72959186,
		lng: 38.79994665,
		pop: 31549.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Mtwara',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Mtwara',
		city_ascii: 'Mtwara',
		lat: -10.26961994,
		lng: 40.18999101,
		pop: 91674,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Mtwara',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Tunduru',
		city_ascii: 'Tunduru',
		lat: -11.08956989,
		lng: 37.3700081,
		pop: 600,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Ruvuma',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Mbamba Bay',
		city_ascii: 'Mbamba Bay',
		lat: -11.2829431,
		lng: 34.76660111,
		pop: 8997,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Ruvuma',
		timezone: 'Africa/Blantyre',
	},
	{
		city: 'Manyoni',
		city_ascii: 'Manyoni',
		lat: -5.779609763,
		lng: 34.90002966,
		pop: 310,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Singida',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Itigi',
		city_ascii: 'Itigi',
		lat: -5.699614646,
		lng: 34.48000362,
		pop: 11534,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Singida',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Singida',
		city_ascii: 'Singida',
		lat: -4.81961668,
		lng: 34.74003943,
		pop: 47749.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Singida',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Ngorongoro',
		city_ascii: 'Ngorongoro',
		lat: -3.249583314,
		lng: 35.51999182,
		pop: 10836,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Arusha',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Oldeani',
		city_ascii: 'Oldeani',
		lat: -3.349628887,
		lng: 35.55001583,
		pop: 7300.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Arusha',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Mbulu',
		city_ascii: 'Mbulu',
		lat: -3.849598369,
		lng: 35.53001705,
		pop: 10421,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Arusha',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Babati',
		city_ascii: 'Babati',
		lat: -4.219549948,
		lng: 35.75000362,
		pop: 19117,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Arusha',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Same',
		city_ascii: 'Same',
		lat: -4.069584942,
		lng: 37.72001257,
		pop: 9768,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Kilimanjaro',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Moshi',
		city_ascii: 'Moshi',
		lat: -3.339603659,
		lng: 37.33998409,
		pop: 463873,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Kilimanjaro',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Musoma',
		city_ascii: 'Musoma',
		lat: -1.489587383,
		lng: 33.79999345,
		pop: 127137.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Mara',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Korogwe',
		city_ascii: 'Korogwe',
		lat: -5.089574362,
		lng: 38.5400142,
		pop: 47000,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Tanga',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Tabora',
		city_ascii: 'Tabora',
		lat: -5.020017884,
		lng: 32.80000281,
		pop: 145893.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Tabora',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Lindi',
		city_ascii: 'Lindi',
		lat: -10.00002399,
		lng: 39.69999508,
		pop: 27953.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Lindi',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Songea',
		city_ascii: 'Songea',
		lat: -10.68003416,
		lng: 35.65000972,
		pop: 120923,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Ruvuma',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Tanga',
		city_ascii: 'Tanga',
		lat: -5.070040671,
		lng: 39.09000647,
		pop: 217155.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Tanga',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Mwanza',
		city_ascii: 'Mwanza',
		lat: -2.520015443,
		lng: 32.93002071,
		pop: 465372.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Mwanza',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Morogoro',
		city_ascii: 'Morogoro',
		lat: -6.820011374,
		lng: 37.66001623,
		pop: 242718.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Morogoro',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Dodoma',
		city_ascii: 'Dodoma',
		lat: -6.183306052,
		lng: 35.75000362,
		pop: 199405,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Dodoma',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Arusha',
		city_ascii: 'Arusha',
		lat: -3.36001585,
		lng: 36.66999914,
		pop: 330605,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Arusha',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Mbeya',
		city_ascii: 'Mbeya',
		lat: -8.890014222,
		lng: 33.43004187,
		pop: 261855.5,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Mbeya',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Zanzibar',
		city_ascii: 'Zanzibar',
		lat: -6.159999981,
		lng: 39.20002559,
		pop: 388439,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Zanzibar West',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Dar es Salaam',
		city_ascii: 'Dar es Salaam',
		lat: -6.800012595,
		lng: 39.26834184,
		pop: 2814326,
		country: 'Tanzania',
		iso2: 'TZ',
		iso3: 'TZA',
		province: 'Dar-Es-Salaam',
		timezone: 'Africa/Dar_es_Salaam',
	},
	{
		city: 'Mae Hong Son',
		city_ascii: 'Mae Hong Son',
		lat: 19.30100405,
		lng: 97.96899665,
		pop: 9109,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Mae Hong Son',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Phangnga',
		city_ascii: 'Phangnga',
		lat: 8.451000086,
		lng: 98.53399857,
		pop: 9676,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Phangnga',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Ranong',
		city_ascii: 'Ranong',
		lat: 9.962001095,
		lng: 98.63800257,
		pop: 24561,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Ranong',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Krabi',
		city_ascii: 'Krabi',
		lat: 8.052003097,
		lng: 98.91199867,
		pop: 31219,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Krabi',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Phatthalung',
		city_ascii: 'Phatthalung',
		lat: 7.61499898,
		lng: 100.0809996,
		pop: 43522,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Phatthalung',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Satun',
		city_ascii: 'Satun',
		lat: 6.616701099,
		lng: 100.0666986,
		pop: 34544,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Satun',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Lamphun',
		city_ascii: 'Lamphun',
		lat: 18.50300114,
		lng: 99.07399856,
		pop: 14030,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Lamphun',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Kamphaeng Phet',
		city_ascii: 'Kamphaeng Phet',
		lat: 16.47299704,
		lng: 99.52900267,
		pop: 58787,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Kamphaeng Phet',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Phichit',
		city_ascii: 'Phichit',
		lat: 16.43900405,
		lng: 100.3490017,
		pop: 35760,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Phichit',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Phetchabun',
		city_ascii: 'Phetchabun',
		lat: 16.41899707,
		lng: 101.1590017,
		pop: 50656,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Phetchabun',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Supham Buri',
		city_ascii: 'Supham Buri',
		lat: 14.47100105,
		lng: 100.1289966,
		pop: 53399,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Suphan Buri',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Ang Thong',
		city_ascii: 'Ang Thong',
		lat: 14.58330306,
		lng: 100.4499997,
		pop: 13738,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Ang Thong',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Chainat',
		city_ascii: 'Chainat',
		lat: 15.17900402,
		lng: 100.1259997,
		pop: 15469,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Chai Nat',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Nakhon Nayok',
		city_ascii: 'Nakhon Nayok',
		lat: 14.20000203,
		lng: 101.2159987,
		pop: 21309,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Nakhon Nayok',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Sing Buri',
		city_ascii: 'Sing Buri',
		lat: 14.886999,
		lng: 100.4010036,
		pop: 20046,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Sing Buri',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Nakhon Pathom',
		city_ascii: 'Nakhon Pathom',
		lat: 13.81799707,
		lng: 100.0639986,
		pop: 117927,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Nakhon Pathom',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Prachuap Khiri Khan',
		city_ascii: 'Prachuap Khiri Khan',
		lat: 11.80299605,
		lng: 99.80000169,
		pop: 33521,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Prachuap Khiri Khan',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Samut Sakhon',
		city_ascii: 'Samut Sakhon',
		lat: 13.536,
		lng: 100.2740046,
		pop: 63498,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Samut Sakhon',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Samut Songkhram',
		city_ascii: 'Samut Songkhram',
		lat: 13.41299699,
		lng: 100.0009986,
		pop: 35065,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Samut Songkhram',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Yasothon',
		city_ascii: 'Yasothon',
		lat: 15.78799812,
		lng: 104.1509977,
		pop: 21643,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Yasothon',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Chachoengsao',
		city_ascii: 'Chachoengsao',
		lat: 13.67900105,
		lng: 101.0760037,
		pop: 49741,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Chachoengsao',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Trat',
		city_ascii: 'Trat',
		lat: 12.23700309,
		lng: 102.5090016,
		pop: 21590,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Trat',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Kalasin',
		city_ascii: 'Kalasin',
		lat: 16.42799707,
		lng: 103.5090007,
		pop: 55102,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Kalasin',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Maha Sarakham',
		city_ascii: 'Maha Sarakham',
		lat: 16.18399803,
		lng: 103.2980045,
		pop: 51584,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Maha Sarakham',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Roi Et',
		city_ascii: 'Roi Et',
		lat: 16.050996,
		lng: 103.6549986,
		pop: 39328,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Roi Et',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Pattani',
		city_ascii: 'Pattani',
		lat: 6.864003023,
		lng: 101.2500006,
		pop: 96815.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Pattani',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Chumphon',
		city_ascii: 'Chumphon',
		lat: 10.51267743,
		lng: 99.18721676,
		pop: 70760.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Chumphon',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Thung Song',
		city_ascii: 'Thung Song',
		lat: 8.153958353,
		lng: 99.72863074,
		pop: 26037.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Nakhon Si Thammarat',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Trang',
		city_ascii: 'Trang',
		lat: 7.563400084,
		lng: 99.60801794,
		pop: 103728.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Trang',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Yala',
		city_ascii: 'Yala',
		lat: 6.550490335,
		lng: 101.2850732,
		pop: 120849,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Yala',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Chiang Rai',
		city_ascii: 'Chiang Rai',
		lat: 19.91187115,
		lng: 99.82645422,
		pop: 97941.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Chiang Rai',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Lampang',
		city_ascii: 'Lampang',
		lat: 18.29155662,
		lng: 99.48125565,
		pop: 180110,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Lampang',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Nan',
		city_ascii: 'Nan',
		lat: 18.78684938,
		lng: 100.7714611,
		pop: 53576.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Nan',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Phayao',
		city_ascii: 'Phayao',
		lat: 19.17070192,
		lng: 99.90830969,
		pop: 20088,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Phayao',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Phrae',
		city_ascii: 'Phrae',
		lat: 18.15329632,
		lng: 100.1629195,
		pop: 28254.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Phrae',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Phitsanulok',
		city_ascii: 'Phitsanulok',
		lat: 16.8283126,
		lng: 100.2728869,
		pop: 133722,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Phitsanulok',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Sukhothai',
		city_ascii: 'Sukhothai',
		lat: 17.01186729,
		lng: 99.7515234,
		pop: 10276,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Sukhothai',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Uttaradit',
		city_ascii: 'Uttaradit',
		lat: 17.63162274,
		lng: 100.0971871,
		pop: 67471.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Uttaradit',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Kanchanaburi',
		city_ascii: 'Kanchanaburi',
		lat: 14.01742474,
		lng: 99.52202836,
		pop: 63699,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Kanchanaburi',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Mae Sot',
		city_ascii: 'Mae Sot',
		lat: 16.71617474,
		lng: 98.57076859,
		pop: 45172,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Tak',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Tak',
		city_ascii: 'Tak',
		lat: 16.88474326,
		lng: 99.12933915,
		pop: 28647.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Tak',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Uthai Thani',
		city_ascii: 'Uthai Thani',
		lat: 15.38186342,
		lng: 100.026442,
		pop: 19233,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Uthai Thani',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Lop Buri',
		city_ascii: 'Lop Buri',
		lat: 14.80401756,
		lng: 100.6186023,
		pop: 42130.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Lop Buri',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Prachin Buri',
		city_ascii: 'Prachin Buri',
		lat: 14.0572156,
		lng: 101.3767989,
		pop: 56178.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Prachin Buri',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Ayutthaya',
		city_ascii: 'Ayutthaya',
		lat: 14.35879925,
		lng: 100.5684244,
		pop: 113788.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Phra Nakhon Si Ayutthaya',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Pathum Thani',
		city_ascii: 'Pathum Thani',
		lat: 14.01711468,
		lng: 100.5333361,
		pop: 154412,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Pathum Thani',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Saraburi',
		city_ascii: 'Saraburi',
		lat: 14.53036501,
		lng: 100.8799816,
		pop: 70769,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Saraburi',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Nonthaburi',
		city_ascii: 'Nonthaburi',
		lat: 13.83368919,
		lng: 100.4833134,
		pop: 258550,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Nonthaburi',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Phetchaburi',
		city_ascii: 'Phetchaburi',
		lat: 13.11329388,
		lng: 99.9411759,
		pop: 68549,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Phetchaburi',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Hua Hin',
		city_ascii: 'Hua Hin',
		lat: 12.56970949,
		lng: 99.94432816,
		pop: 33963,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Prachuap Khiri Khan',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Ratchaburi',
		city_ascii: 'Ratchaburi',
		lat: 13.54189821,
		lng: 99.82154496,
		pop: 99722,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Ratchaburi',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Samut Prakan',
		city_ascii: 'Samut Prakan',
		lat: 13.60690716,
		lng: 100.6114709,
		pop: 388920,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Samut Prakan',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Sisaket',
		city_ascii: 'Sisaket',
		lat: 15.12025148,
		lng: 104.3298486,
		pop: 42856,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Si Sa Ket',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Si Racha',
		city_ascii: 'Si Racha',
		lat: 13.15902753,
		lng: 100.9286608,
		pop: 94204.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Chon Buri',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Chon Buri',
		city_ascii: 'Chon Buri',
		lat: 13.40040814,
		lng: 100.9999743,
		pop: 221340.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Chon Buri',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Chanthaburi',
		city_ascii: 'Chanthaburi',
		lat: 12.61327272,
		lng: 102.0978918,
		pop: 94950.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Chanthaburi',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Aranyaprathet',
		city_ascii: 'Aranyaprathet',
		lat: 13.68235476,
		lng: 102.4969372,
		pop: 21354,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Sa Kaeo',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Rayong',
		city_ascii: 'Rayong',
		lat: 12.67184796,
		lng: 101.2814559,
		pop: 37035,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Rayong',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Buriram',
		city_ascii: 'Buriram',
		lat: 15.00043968,
		lng: 103.116641,
		pop: 47292,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Buri Ram',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Chaiyaphum',
		city_ascii: 'Chaiyaphum',
		lat: 15.8040082,
		lng: 102.0386189,
		pop: 55191,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Chaiyaphum',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Bua Yai',
		city_ascii: 'Bua Yai',
		lat: 15.58402163,
		lng: 102.4185957,
		pop: 17550.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Nakhon Ratchasima',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Surin',
		city_ascii: 'Surin',
		lat: 14.88682904,
		lng: 103.4914502,
		pop: 54604.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Surin',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Loei',
		city_ascii: 'Loei',
		lat: 17.49188967,
		lng: 101.7315059,
		pop: 29908,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Loei',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Nong Khai',
		city_ascii: 'Nong Khai',
		lat: 17.87326174,
		lng: 102.747878,
		pop: 84057,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Nong Khai',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Sakhon Nakhon',
		city_ascii: 'Sakhon Nakhon',
		lat: 17.16790428,
		lng: 104.1478959,
		pop: 67755.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Sakon Nakhon',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Udon Thani',
		city_ascii: 'Udon Thani',
		lat: 17.4047632,
		lng: 102.7893225,
		pop: 239192,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Udon Thani',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Nakhon Phanom',
		city_ascii: 'Nakhon Phanom',
		lat: 17.39447959,
		lng: 104.76946,
		pop: 44986,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Nakhon Phanom',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Narathiwat',
		city_ascii: 'Narathiwat',
		lat: 6.431841246,
		lng: 101.8214229,
		pop: 57941.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Narathiwat',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Khon Kaen',
		city_ascii: 'Khon Kaen',
		lat: 16.42004295,
		lng: 102.8300435,
		pop: 199317.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Khon Kaen',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Phuket',
		city_ascii: 'Phuket',
		lat: 7.876533426,
		lng: 98.3815295,
		pop: 108595.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Phuket',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Nakhon Si Thammarat',
		city_ascii: 'Nakhon Si Thammarat',
		lat: 8.399989847,
		lng: 99.97001135,
		pop: 176585.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Nakhon Si Thammarat',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Songkhla',
		city_ascii: 'Songkhla',
		lat: 7.209959126,
		lng: 100.5600012,
		pop: 48616,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Songkhla (Songkhla Lake)',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Hat Yai',
		city_ascii: 'Hat Yai',
		lat: 6.996432107,
		lng: 100.4714278,
		pop: 254825,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Songkhla',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Nakhon Sawan',
		city_ascii: 'Nakhon Sawan',
		lat: 15.70003522,
		lng: 100.0700052,
		pop: 111915,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Nakhon Sawan',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Ubon Ratchathani',
		city_ascii: 'Ubon Ratchathani',
		lat: 15.24995933,
		lng: 104.8300248,
		pop: 198213,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Ubon Ratchathani',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Surat Thani',
		city_ascii: 'Surat Thani',
		lat: 9.1500991,
		lng: 99.34012732,
		pop: 142414,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Surat Thani',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Chiang Mai',
		city_ascii: 'Chiang Mai',
		lat: 18.7999752,
		lng: 98.98004594,
		pop: 299081.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Chiang Mai',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Nakhon Ratchasima',
		city_ascii: 'Nakhon Ratchasima',
		lat: 15.00002626,
		lng: 102.1000105,
		pop: 271882.5,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Nakhon Ratchasima',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Bangkok',
		city_ascii: 'Bangkok',
		lat: 13.74999921,
		lng: 100.5166447,
		pop: 5904238,
		country: 'Thailand',
		iso2: 'TH',
		iso3: 'THA',
		province: 'Bangkok Metropolis',
		timezone: 'Asia/Bangkok',
	},
	{
		city: 'Freeport',
		city_ascii: 'Freeport',
		lat: 26.53327578,
		lng: -78.70001306,
		pop: 25383,
		country: 'The Bahamas',
		iso2: 'BS',
		iso3: 'BHS',
		province: '',
		timezone: 'America/Nassau',
	},
	{
		city: 'Nassau',
		city_ascii: 'Nassau',
		lat: 25.08339012,
		lng: -77.35004378,
		pop: 194453,
		country: 'The Bahamas',
		iso2: 'BS',
		iso3: 'BHS',
		province: '',
		timezone: 'America/Nassau',
	},
	{
		city: 'Georgetown',
		city_ascii: 'Georgetown',
		lat: 13.55100308,
		lng: -14.76700152,
		pop: 3584,
		country: 'The Gambia',
		iso2: 'GM',
		iso3: 'GMB',
		province: 'Maccarthy Island',
		timezone: 'Africa/Banjul',
	},
	{
		city: 'Basse Santa Su',
		city_ascii: 'Basse Santa Su',
		lat: 13.31000112,
		lng: -14.2229965,
		pop: 14380,
		country: 'The Gambia',
		iso2: 'GM',
		iso3: 'GMB',
		province: 'Upper River',
		timezone: 'Africa/Banjul',
	},
	{
		city: 'Kerewan',
		city_ascii: 'Kerewan',
		lat: 13.49399711,
		lng: -16.09499647,
		pop: 2751,
		country: 'The Gambia',
		iso2: 'GM',
		iso3: 'GMB',
		province: 'Lower River',
		timezone: 'Africa/Banjul',
	},
	{
		city: 'Mansa Konko',
		city_ascii: 'Mansa Konko',
		lat: 13.3773121,
		lng: -15.67856644,
		pop: 18672,
		country: 'The Gambia',
		iso2: 'GM',
		iso3: 'GMB',
		province: 'Lower River',
		timezone: 'Africa/Banjul',
	},
	{
		city: 'Bansang',
		city_ascii: 'Bansang',
		lat: 13.43363609,
		lng: -14.65002079,
		pop: 4646.5,
		country: 'The Gambia',
		iso2: 'GM',
		iso3: 'GMB',
		province: 'Maccarthy Island',
		timezone: 'Africa/Banjul',
	},
	{
		city: 'Brikama',
		city_ascii: 'Brikama',
		lat: 13.28036379,
		lng: -16.65994979,
		pop: 136418,
		country: 'The Gambia',
		iso2: 'GM',
		iso3: 'GMB',
		province: 'Banjul',
		timezone: 'Africa/Banjul',
	},
	{
		city: 'Banjul',
		city_ascii: 'Banjul',
		lat: 13.45387646,
		lng: -16.59170149,
		pop: 38841.5,
		country: 'The Gambia',
		iso2: 'GM',
		iso3: 'GMB',
		province: 'Banjul',
		timezone: 'Africa/Banjul',
	},
	{
		city: 'Bassar',
		city_ascii: 'Bassar',
		lat: 9.261000068,
		lng: 0.789003574,
		pop: 61845,
		country: 'Togo',
		iso2: 'TG',
		iso3: 'TGO',
		province: 'Kara',
		timezone: 'Africa/Lome',
	},
	{
		city: 'Sotouboua',
		city_ascii: 'Sotouboua',
		lat: 8.557002133,
		lng: 0.984996462,
		pop: 21054,
		country: 'Togo',
		iso2: 'TG',
		iso3: 'TGO',
		province: 'Centre',
		timezone: 'Africa/Lome',
	},
	{
		city: 'Kpalime',
		city_ascii: 'Kpalime',
		lat: 6.900391458,
		lng: 0.630028441,
		pop: 98226.5,
		country: 'Togo',
		iso2: 'TG',
		iso3: 'TGO',
		province: 'Plateaux',
		timezone: 'Africa/Lome',
	},
	{
		city: 'Sokode',
		city_ascii: 'Sokode',
		lat: 8.9904706,
		lng: 1.149996703,
		pop: 99725.5,
		country: 'Togo',
		iso2: 'TG',
		iso3: 'TGO',
		province: 'Centre',
		timezone: 'Africa/Lome',
	},
	{
		city: 'Mango',
		city_ascii: 'Mango',
		lat: 10.35956016,
		lng: 0.470813353,
		pop: 40187,
		country: 'Togo',
		iso2: 'TG',
		iso3: 'TGO',
		province: 'Savanes',
		timezone: 'Africa/Lome',
	},
	{
		city: 'Atakpame',
		city_ascii: 'Atakpame',
		lat: 7.530042947,
		lng: 1.120024372,
		pop: 74757,
		country: 'Togo',
		iso2: 'TG',
		iso3: 'TGO',
		province: 'Plateaux',
		timezone: 'Africa/Lome',
	},
	{
		city: 'Lome',
		city_ascii: 'Lome',
		lat: 6.131937072,
		lng: 1.222757119,
		pop: 1100850,
		country: 'Togo',
		iso2: 'TG',
		iso3: 'TGO',
		province: 'Maritime',
		timezone: 'Africa/Lome',
	},
	{
		city: 'Neiafu',
		city_ascii: 'Neiafu',
		lat: -18.64957355,
		lng: -173.9832927,
		pop: 5855.5,
		country: 'Tonga',
		iso2: 'TO',
		iso3: 'TON',
		province: '',
		timezone: 'Pacific/Tongatapu',
	},
	{
		city: 'Nukualofa',
		city_ascii: 'Nukualofa',
		lat: -21.13851236,
		lng: -175.2205645,
		pop: 33139,
		country: 'Tonga',
		iso2: 'TO',
		iso3: 'TON',
		province: '',
		timezone: 'Pacific/Tongatapu',
	},
	{
		city: 'San Fernando',
		city_ascii: 'San Fernando',
		lat: 10.28046166,
		lng: -61.45937678,
		pop: 166039,
		country: 'Trinidad and Tobago',
		iso2: 'TT',
		iso3: 'TTO',
		province: 'San Fernando',
		timezone: 'America/Port_of_Spain',
	},
	{
		city: 'Port-of-Spain',
		city_ascii: 'Port-of-Spain',
		lat: 10.65199709,
		lng: -61.51703089,
		pop: 171982.5,
		country: 'Trinidad and Tobago',
		iso2: 'TT',
		iso3: 'TTO',
		province: 'Port of Spain',
		timezone: 'America/Port_of_Spain',
	},
	{
		city: 'Medenine',
		city_ascii: 'Medemine',
		lat: 33.399999,
		lng: 10.41669956,
		pop: 61705,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Médenine',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Kebili',
		city_ascii: 'Kebili',
		lat: 33.68999703,
		lng: 8.971002538,
		pop: 19875,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Kebili',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Tataouine',
		city_ascii: 'Tataouine',
		lat: 33.00000315,
		lng: 10.46670359,
		pop: 62577,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Tataouine',
		timezone: 'Africa/Tunis',
	},
	{
		city: "L'Ariana",
		city_ascii: "L'Ariana",
		lat: 36.86667315,
		lng: 10.19999755,
		pop: 97687,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Manubah',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Jendouba',
		city_ascii: 'Jendouba',
		lat: 36.50000406,
		lng: 8.749998615,
		pop: 51408,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Jendouba',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Kasserine',
		city_ascii: 'Kasserine',
		lat: 35.2167031,
		lng: 8.716698503,
		pop: 76243,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Kassérine',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Sdid Bouzid',
		city_ascii: 'Sdid Bouzid',
		lat: 35.01669608,
		lng: 9.500004482,
		pop: 42098,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Sidi Bou Zid',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Siliana',
		city_ascii: 'Siliana',
		lat: 36.08330413,
		lng: 9.3833016,
		pop: 26960,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Siliana',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Mahdia',
		city_ascii: 'Mahdia',
		lat: 35.48391304,
		lng: 11.04087662,
		pop: 45977,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Mahdia',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Monastir',
		city_ascii: 'Monasir',
		lat: 35.73070214,
		lng: 10.76729456,
		pop: 56473,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Monastir',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Zaghouan',
		city_ascii: 'Zaghouan',
		lat: 36.39999616,
		lng: 10.14699661,
		pop: 16911,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Zaghouan',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Ben Gardane',
		city_ascii: 'Ben Gardane',
		lat: 33.14039187,
		lng: 11.22002803,
		pop: 16603.5,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Médenine',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Zarzis',
		city_ascii: 'Zarzis',
		lat: 33.51039512,
		lng: 11.09998368,
		pop: 119238.5,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Médenine',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Dehibat',
		city_ascii: 'Dehibat',
		lat: 32.01704958,
		lng: 10.7000081,
		pop: 3525,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Tataouine',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Tozeur',
		city_ascii: 'Tozeur',
		lat: 33.93042116,
		lng: 8.129984089,
		pop: 37223.5,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Tozeur',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Beja',
		city_ascii: 'Beja',
		lat: 36.73040529,
		lng: 9.190022744,
		pop: 58400,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Béja',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Bizerte',
		city_ascii: 'Bizerte',
		lat: 37.29042279,
		lng: 9.854995075,
		pop: 127555.5,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Bizerte',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Nabeul',
		city_ascii: 'Nabeul',
		lat: 36.46034426,
		lng: 10.7300321,
		pop: 115149,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Nabeul',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'El Kef',
		city_ascii: 'El Kef',
		lat: 36.18263511,
		lng: 8.714754597,
		pop: 42303.5,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Le Kef',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Qasserine',
		city_ascii: 'Qasserine',
		lat: 35.18039654,
		lng: 8.829993041,
		pop: 80072.5,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Kassérine',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Gabes',
		city_ascii: 'Gabes',
		lat: 33.90042299,
		lng: 10.09999304,
		pop: 164796,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Gabès',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Gafsa',
		city_ascii: 'Gafsa',
		lat: 34.42044293,
		lng: 8.780021931,
		pop: 104017.5,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Gafsa',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Qairouan',
		city_ascii: 'Qairouan',
		lat: 35.68039187,
		lng: 10.09999304,
		pop: 132158,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Kairouan',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Sfax',
		city_ascii: 'Sfax',
		lat: 34.75003522,
		lng: 10.72000688,
		pop: 365164,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Sfax',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Sousse',
		city_ascii: 'Sousse',
		lat: 35.82999514,
		lng: 10.62502559,
		pop: 245563.5,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Sousse',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Tunis',
		city_ascii: 'Tunis',
		lat: 36.80277814,
		lng: 10.1796781,
		pop: 1570476.5,
		country: 'Tunisia',
		iso2: 'TN',
		iso3: 'TUN',
		province: 'Tunis',
		timezone: 'Africa/Tunis',
	},
	{
		city: 'Kirklareli',
		city_ascii: 'Kirklareli',
		lat: 41.74299917,
		lng: 27.22599962,
		pop: 58223,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Kirklareli',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Bilecik',
		city_ascii: 'Bilecik',
		lat: 40.14999902,
		lng: 29.9829966,
		pop: 40285,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Bilecik',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Sakarya',
		city_ascii: 'Sakarya',
		lat: 40.76666114,
		lng: 30.40000251,
		pop: 286787,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Sakarya',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Kastamonu',
		city_ascii: 'Kastamonu',
		lat: 41.38900215,
		lng: 33.78300349,
		pop: 70402,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Kastamonu',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Burdur',
		city_ascii: 'Burdur',
		lat: 37.71666011,
		lng: 30.28333554,
		pop: 66158,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Burdur',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Kirsehir',
		city_ascii: 'Kirsehir',
		lat: 39.14199917,
		lng: 34.1710026,
		pop: 94336,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Kirsehir',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Nevsehir',
		city_ascii: 'Nevsehir',
		lat: 38.62400404,
		lng: 34.72399852,
		pop: 75527,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Nevsehir',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Antioch',
		city_ascii: 'Antioch',
		lat: 36.23333409,
		lng: 36.11667656,
		pop: 154803,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Hatay',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Giresun',
		city_ascii: 'Giresun',
		lat: 40.91300115,
		lng: 38.39000452,
		pop: 98864,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Giresun',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Sinop',
		city_ascii: 'Sinop',
		lat: 42.02299802,
		lng: 35.1530015,
		pop: 34834,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Sinop',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Tokat',
		city_ascii: 'Tokat',
		lat: 40.30599617,
		lng: 36.56300452,
		pop: 129702,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Tokat',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Artvin',
		city_ascii: 'Coruh',
		lat: 41.18300114,
		lng: 41.81799654,
		pop: 27899.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Artvin',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Bingol',
		city_ascii: 'Bingol',
		lat: 38.88500404,
		lng: 40.49800256,
		pop: 80568,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Bingöl',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Bitlis',
		city_ascii: 'Bitlis',
		lat: 38.39400012,
		lng: 42.12299765,
		pop: 52960,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Bitlis',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Cankiri',
		city_ascii: 'Cankiri',
		lat: 40.60700101,
		lng: 33.62100359,
		pop: 71379,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Çankiri',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Nigde',
		city_ascii: 'Nigde',
		lat: 37.97600412,
		lng: 34.69400163,
		pop: 91039,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Nigde',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Yozgat',
		city_ascii: 'Yozgat',
		lat: 39.81799809,
		lng: 34.81499749,
		pop: 87881,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Yozgat',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Gumushane',
		city_ascii: 'Gumushane',
		lat: 40.46400013,
		lng: 39.48399962,
		pop: 32250,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Gümüshane',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Siirt',
		city_ascii: 'Siirt',
		lat: 37.94400007,
		lng: 41.93299858,
		pop: 114034,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Siirt',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Tunceli',
		city_ascii: 'Tunceli',
		lat: 39.11670002,
		lng: 39.5333015,
		pop: 29062,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Tunceli',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Aydin',
		city_ascii: 'Aydin',
		lat: 37.8499752,
		lng: 27.85002071,
		pop: 180939.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Aydin',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Luleburgaz',
		city_ascii: 'Luleburgaz',
		lat: 41.40665733,
		lng: 27.35521887,
		pop: 67989.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Kirklareli',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Isparta',
		city_ascii: 'Isparta',
		lat: 37.76998008,
		lng: 30.52996049,
		pop: 161089,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Isparta',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Kutahya',
		city_ascii: 'Kutahya',
		lat: 39.42000856,
		lng: 29.92999711,
		pop: 168459.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Kütahya',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Mugla',
		city_ascii: 'Mugla',
		lat: 37.21637046,
		lng: 28.36389115,
		pop: 44488.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Mugla',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Elazig',
		city_ascii: 'Elazig',
		lat: 38.67997622,
		lng: 39.22999792,
		pop: 271492,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Elazig',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Kahramanmaras',
		city_ascii: 'Kahramanmaras',
		lat: 37.60998985,
		lng: 36.94502112,
		pop: 374745.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'K. Maras',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Icel',
		city_ascii: 'Icel',
		lat: 36.79998761,
		lng: 34.61999508,
		pop: 577416,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Mersin',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Corum',
		city_ascii: 'Corum',
		lat: 40.5199931,
		lng: 34.95000077,
		pop: 168544,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Çorum',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Rize',
		city_ascii: 'Rize',
		lat: 41.02084108,
		lng: 40.52185705,
		pop: 187976.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Rize',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Tatvan',
		city_ascii: 'Tatvan',
		lat: 38.50657595,
		lng: 42.2815946,
		pop: 68436.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Bitlis',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Polatli',
		city_ascii: 'Polatli',
		lat: 39.58415875,
		lng: 32.14722611,
		pop: 72631.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Ankara',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Karabuk',
		city_ascii: 'Karabuk',
		lat: 41.20000327,
		lng: 32.60001501,
		pop: 113022.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Zinguldak',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Nusaybin',
		city_ascii: 'Nusaybin',
		lat: 37.07498374,
		lng: 41.21835201,
		pop: 120822.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Mardin',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Hakkari',
		city_ascii: 'Hakkari',
		lat: 37.57443646,
		lng: 43.7408337,
		pop: 42385,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Hakkari',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Soke',
		city_ascii: 'Soke',
		lat: 37.75122154,
		lng: 27.41025427,
		pop: 72785.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Aydin',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Balikesir',
		city_ascii: 'Balikesir',
		lat: 39.6503821,
		lng: 27.89001827,
		pop: 249833.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Balikesir',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Canakkale',
		city_ascii: 'Canakkale',
		lat: 40.14593325,
		lng: 26.4063879,
		pop: 74667,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Çanakkale',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Edirne',
		city_ascii: 'Edirne',
		lat: 41.67043968,
		lng: 26.56999548,
		pop: 114424,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Edirne',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Tekirdag',
		city_ascii: 'Tekirdag',
		lat: 40.99086875,
		lng: 27.50998979,
		pop: 108266,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Tekirdag',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Izmit',
		city_ascii: 'Kocaeli',
		lat: 40.77602399,
		lng: 29.93061723,
		pop: 383557.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Kocaeli',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Bolu',
		city_ascii: 'Bolu',
		lat: 40.73625897,
		lng: 31.60612219,
		pop: 96489,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Bolu',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Afyon',
		city_ascii: 'Afyon',
		lat: 38.75038535,
		lng: 30.55001094,
		pop: 151564,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Afyon',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Denizli',
		city_ascii: 'Denizli',
		lat: 37.77039349,
		lng: 29.08002315,
		pop: 342791,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Denizli',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Manisa',
		city_ascii: 'Manisa',
		lat: 38.63039268,
		lng: 27.43996822,
		pop: 237700,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Manisa',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Adiyaman',
		city_ascii: 'Adiyaman',
		lat: 37.77039349,
		lng: 38.27992672,
		pop: 195497,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Adiyaman',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Malatya',
		city_ascii: 'Malatya',
		lat: 38.37043439,
		lng: 38.30002885,
		pop: 451689.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Malatya',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Tarsus',
		city_ascii: 'Tarsus',
		lat: 36.9203937,
		lng: 34.87997921,
		pop: 566297,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Mersin',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Samandagi',
		city_ascii: 'Samandagi',
		lat: 36.11705772,
		lng: 35.93329993,
		pop: 93638,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Hatay',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Hatay',
		city_ascii: 'Hatay',
		lat: 36.2303583,
		lng: 36.12000688,
		pop: 305564,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Hatay',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Iskenderun',
		city_ascii: 'Iskenderun',
		lat: 36.58041445,
		lng: 36.17002966,
		pop: 228954,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Hatay',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Amasya',
		city_ascii: 'Amasya',
		lat: 40.65368003,
		lng: 35.83304765,
		pop: 77700.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Amasya',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Ordu',
		city_ascii: 'Ordu',
		lat: 41.00042889,
		lng: 37.8699259,
		pop: 135952.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Ordu',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Sivas',
		city_ascii: 'Sivas',
		lat: 39.74541506,
		lng: 37.03498979,
		pop: 245801.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Sivas',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Bafra',
		city_ascii: 'Bafra',
		lat: 41.56817202,
		lng: 35.90689327,
		pop: 95198,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Samsun',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Erzurum',
		city_ascii: 'Erzurum',
		lat: 39.92039146,
		lng: 41.29002722,
		pop: 391804,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Erzurum',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Erzincan',
		city_ascii: 'Erzincan',
		lat: 39.75264976,
		lng: 39.49277258,
		pop: 121717,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Erzincan',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Agri',
		city_ascii: 'Agri',
		lat: 39.71983522,
		lng: 43.05131506,
		pop: 87854,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Agri',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Diyarbakir',
		city_ascii: 'Diyarbakir',
		lat: 37.92043601,
		lng: 40.23004024,
		pop: 640586.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Diyarbakir',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Mus',
		city_ascii: 'Mus',
		lat: 38.74901593,
		lng: 41.49693966,
		pop: 80541,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Mus',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Zonguldak',
		city_ascii: 'Zonguldak',
		lat: 41.43037681,
		lng: 31.78001339,
		pop: 128573.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Zinguldak',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Eregli',
		city_ascii: 'Eregli',
		lat: 37.50627525,
		lng: 34.05165767,
		pop: 86563,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Konya',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Karaman',
		city_ascii: 'Karaman',
		lat: 37.18154055,
		lng: 33.21501623,
		pop: 103619.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Karaman',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Usak',
		city_ascii: 'Usak',
		lat: 38.68036379,
		lng: 29.4200024,
		pop: 147190.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Usak',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Kilis',
		city_ascii: 'Kilis',
		lat: 36.7204059,
		lng: 37.11999752,
		pop: 73320,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Gaziantep',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Kirikkale',
		city_ascii: 'Kirikkale',
		lat: 39.85036989,
		lng: 33.52998409,
		pop: 208554.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Kinkkale',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Kars',
		city_ascii: 'Kars',
		lat: 40.60846315,
		lng: 43.09746212,
		pop: 62793,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Kars',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Mardin',
		city_ascii: 'Mardin',
		lat: 37.31150677,
		lng: 40.74272213,
		pop: 64479.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Mardin',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Batman',
		city_ascii: 'Batman',
		lat: 37.89041201,
		lng: 41.14001054,
		pop: 276337.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Batman',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Van',
		city_ascii: 'Van',
		lat: 38.49543968,
		lng: 43.39997595,
		pop: 326262,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Van',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Adapazari',
		city_ascii: 'Adapazari',
		lat: 40.79997601,
		lng: 30.4150321,
		pop: 260109,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Sakarya',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Trabzon',
		city_ascii: 'Trabzon',
		lat: 40.97999086,
		lng: 39.71999385,
		pop: 497556.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Trabzon',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Sanliurfa',
		city_ascii: 'Sanliurfa',
		lat: 37.16999086,
		lng: 38.79498572,
		pop: 431407.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Sanliurfa',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Eskisehir',
		city_ascii: 'Eskisehir',
		lat: 39.7949986,
		lng: 30.52996049,
		pop: 490644.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Eskisehir',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Antalya',
		city_ascii: 'Antalya',
		lat: 36.88998212,
		lng: 30.69997595,
		pop: 703468.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Antalya',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Kayseri',
		city_ascii: 'Kayseri',
		lat: 38.73495994,
		lng: 35.49001949,
		pop: 562215.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Kayseri',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Gaziantep',
		city_ascii: 'Gaziantep',
		lat: 37.07498374,
		lng: 37.38499426,
		pop: 943262,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Gaziantep',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Izmir',
		city_ascii: 'Izmir',
		lat: 38.43614968,
		lng: 27.15179401,
		pop: 2454909,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Izmir',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Bursa',
		city_ascii: 'Bursa',
		lat: 40.1999868,
		lng: 29.06999792,
		pop: 1425544.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Bursa',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Samsun',
		city_ascii: 'Samsun',
		lat: 41.27999839,
		lng: 36.34366247,
		pop: 573722.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Samsun',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Konya',
		city_ascii: 'Konya',
		lat: 37.87501243,
		lng: 32.47500972,
		pop: 718680,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Konya',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Adana',
		city_ascii: 'Adana',
		lat: 36.99498863,
		lng: 35.32000403,
		pop: 1245445,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Adana',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Ankara',
		city_ascii: 'Ankara',
		lat: 39.92723859,
		lng: 32.86439164,
		pop: 3511689.5,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Ankara',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Istanbul',
		city_ascii: 'Istanbul',
		lat: 41.10499615,
		lng: 29.01000159,
		pop: 10003305,
		country: 'Turkey',
		iso2: 'TR',
		iso3: 'TUR',
		province: 'Istanbul',
		timezone: 'Europe/Istanbul',
	},
	{
		city: 'Gyzlarbat',
		city_ascii: 'Gyzlarbat',
		lat: 38.97553957,
		lng: 56.27779455,
		pop: 30229,
		country: 'Turkmenistan',
		iso2: 'TM',
		iso3: 'TKM',
		province: 'Balkan',
		timezone: 'Asia/Ashgabat',
	},
	{
		city: 'Celeken',
		city_ascii: 'Celeken',
		lat: 39.43615488,
		lng: 53.12259119,
		pop: 1206,
		country: 'Turkmenistan',
		iso2: 'TM',
		iso3: 'TKM',
		province: 'Balkan',
		timezone: 'Asia/Ashgabat',
	},
	{
		city: 'Tejen',
		city_ascii: 'Tejen',
		lat: 37.37860862,
		lng: 60.49603837,
		pop: 62649.5,
		country: 'Turkmenistan',
		iso2: 'TM',
		iso3: 'TKM',
		province: 'Ahal',
		timezone: 'Asia/Ashgabat',
	},
	{
		city: 'Buzmeyin',
		city_ascii: 'Buzmeyin',
		lat: 38.05166831,
		lng: 58.21002803,
		pop: 40147,
		country: 'Turkmenistan',
		iso2: 'TM',
		iso3: 'TKM',
		province: 'Ahal',
		timezone: 'Asia/Ashgabat',
	},
	{
		city: 'Koneurgench',
		city_ascii: 'Koneurgench',
		lat: 42.31665346,
		lng: 59.16666215,
		pop: 30700,
		country: 'Turkmenistan',
		iso2: 'TM',
		iso3: 'TKM',
		province: 'Tashauz',
		timezone: 'Asia/Ashgabat',
	},
	{
		city: 'Balkanabat',
		city_ascii: 'Balkanabat',
		lat: 39.51238019,
		lng: 54.36493974,
		pop: 99324.5,
		country: 'Turkmenistan',
		iso2: 'TM',
		iso3: 'TKM',
		province: 'Balkan',
		timezone: 'Asia/Ashgabat',
	},
	{
		city: 'Kaka',
		city_ascii: 'Kaka',
		lat: 37.35034161,
		lng: 59.60002071,
		pop: 28463,
		country: 'Turkmenistan',
		iso2: 'TM',
		iso3: 'TKM',
		province: 'Ahal',
		timezone: 'Asia/Ashgabat',
	},
	{
		city: 'Atamyrat',
		city_ascii: 'Atamyrat',
		lat: 37.82480878,
		lng: 65.19973059,
		pop: 32205,
		country: 'Turkmenistan',
		iso2: 'TM',
		iso3: 'TKM',
		province: 'Chardzhou',
		timezone: 'Asia/Ashgabat',
	},
	{
		city: 'Dasoguz',
		city_ascii: 'Dasoguz',
		lat: 41.83999005,
		lng: 59.96495967,
		pop: 183962,
		country: 'Turkmenistan',
		iso2: 'TM',
		iso3: 'TKM',
		province: 'Tashauz',
		timezone: 'Asia/Ashgabat',
	},
	{
		city: 'Turkmenbasy',
		city_ascii: 'Turkmenbasy',
		lat: 40.02304669,
		lng: 52.96967606,
		pop: 66722,
		country: 'Turkmenistan',
		iso2: 'TM',
		iso3: 'TKM',
		province: 'Balkan',
		timezone: 'Asia/Ashgabat',
	},
	{
		city: 'Turkmenabat',
		city_ascii: 'Turkmenabat',
		lat: 39.11000165,
		lng: 63.58003617,
		pop: 231665.5,
		country: 'Turkmenistan',
		iso2: 'TM',
		iso3: 'TKM',
		province: 'Chardzhou',
		timezone: 'Asia/Ashgabat',
	},
	{
		city: 'Mary',
		city_ascii: 'Mary',
		lat: 37.6000163,
		lng: 61.83332108,
		pop: 146694,
		country: 'Turkmenistan',
		iso2: 'TM',
		iso3: 'TKM',
		province: 'Mary',
		timezone: 'Asia/Ashgabat',
	},
	{
		city: 'Ashgabat',
		city_ascii: 'Ashgabat',
		lat: 37.94999493,
		lng: 58.38329911,
		pop: 652841,
		country: 'Turkmenistan',
		iso2: 'TM',
		iso3: 'TKM',
		province: 'Ahal',
		timezone: 'Asia/Ashgabat',
	},
	{
		city: 'Grand Turk',
		city_ascii: 'Grand Turk',
		lat: 21.46642743,
		lng: -71.13597864,
		pop: 4760.5,
		country: 'Turks and Caicos Islands',
		iso2: 'TC',
		iso3: 'TCA',
		province: '',
		timezone: 'America/Grand_Turk',
	},
	{
		city: 'Funafuti',
		city_ascii: 'Funafuti',
		lat: -8.516651999,
		lng: 179.2166471,
		pop: 4749,
		country: 'Tuvalu',
		iso2: 'TV',
		iso3: 'TUV',
		province: '',
		timezone: 'Pacific/Funafuti',
	},
	{
		city: 'Kalangala',
		city_ascii: 'Kalangala',
		lat: -0.3088889,
		lng: 32.225,
		pop: 5200,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kalangala',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Kumi',
		city_ascii: 'Kumi',
		lat: 1.4608333,
		lng: 33.9361111,
		pop: 13000,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kumi',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Kaberamaido',
		city_ascii: 'Kaberamaido',
		lat: 1.7388889,
		lng: 33.1594444,
		pop: 3400,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kaberamaido',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Kayunga',
		city_ascii: 'Kayunga',
		lat: 0.7025,
		lng: 32.8886111,
		pop: 21704,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kayunga',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Iganga',
		city_ascii: 'Iganga',
		lat: 0.6091667,
		lng: 33.4686111,
		pop: 45024,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Iganga',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Kamuli',
		city_ascii: 'Kamuli',
		lat: 0.9472222,
		lng: 33.1197222,
		pop: 12764,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kamuli',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Pallisa',
		city_ascii: 'Pallisa',
		lat: 1.145,
		lng: 33.7094444,
		pop: 30745,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Pallisa',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Mpigi',
		city_ascii: 'Mpigi',
		lat: 0.225,
		lng: 32.3136111,
		pop: 11082,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Mpigi',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Adjumani',
		city_ascii: 'Adjumani',
		lat: 3.3613889,
		lng: 31.8097222,
		pop: 34700,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Adjumani',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Nebbi',
		city_ascii: 'Nebbi',
		lat: 2.4758333,
		lng: 31.1025,
		pop: 30354,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Nebbi',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Kiboga',
		city_ascii: 'Kiboga',
		lat: 0.9161111,
		lng: 31.7741667,
		pop: 14512,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kiboga',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Nakasongola',
		city_ascii: 'Nakasongola',
		lat: 1.3088889,
		lng: 32.4563889,
		pop: 6921,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Nakasongola',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Bombo',
		city_ascii: 'Bombo',
		lat: 0.583299106,
		lng: 32.53329952,
		pop: 48000,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Bamunanika',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Masindi',
		city_ascii: 'Masindi',
		lat: 1.6744444,
		lng: 31.715,
		pop: 31486,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Masindi',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Fort Portal',
		city_ascii: 'Fort Portal',
		lat: 0.671004121,
		lng: 30.27500162,
		pop: 42670,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kabarole',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Kibale',
		city_ascii: 'Kibale',
		lat: 0.8,
		lng: 31.0666667,
		pop: 5200,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kibale',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Sironko',
		city_ascii: 'Sironko',
		lat: 1.25,
		lng: 34.3,
		pop: 14100,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Budadiri',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Busia',
		city_ascii: 'Busia',
		lat: 0.4544444,
		lng: 34.0758333,
		pop: 47100,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Busia',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Katakwi',
		city_ascii: 'Katakwi',
		lat: 1.8911111,
		lng: 33.9661111,
		pop: 8400,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Usuk',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Ntungamo',
		city_ascii: 'Ntungamo',
		lat: -0.8794444,
		lng: 30.2641667,
		pop: 16400,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Ntungamo',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Kisoro',
		city_ascii: 'Kisoro',
		lat: -1.3538889,
		lng: 29.6983333,
		pop: 12900,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kisoro',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Jinja',
		city_ascii: 'Jinja',
		lat: 0.44042401,
		lng: 33.19992672,
		pop: 195659.5,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Jinja',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Soroti',
		city_ascii: 'Soroti',
		lat: 1.710398172,
		lng: 33.60000565,
		pop: 1038,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Soroti',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Arua',
		city_ascii: 'Arua',
		lat: 3.020369892,
		lng: 30.90001542,
		pop: 154700,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Arua Municipality',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Pakwach',
		city_ascii: 'Pakwach',
		lat: 2.470377623,
		lng: 31.47998002,
		pop: 17541,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Nebbi',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Moyo',
		city_ascii: 'Moyo',
		lat: 3.650408955,
		lng: 31.72001705,
		pop: 22434,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Moyo',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Entebbe',
		city_ascii: 'Entebbe',
		lat: 0.060395527,
		lng: 32.46002356,
		pop: 127414,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Wakiso',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Mubende',
		city_ascii: 'Mubende',
		lat: 0.590440693,
		lng: 31.37001257,
		pop: 9556,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Mubende',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Mityana',
		city_ascii: 'Mityana',
		lat: 0.400426452,
		lng: 32.05002274,
		pop: 37420.5,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Busujju',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Kitgum',
		city_ascii: 'Kitgum',
		lat: 3.300404479,
		lng: 32.87002437,
		pop: 32785.5,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kitgum',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Lira',
		city_ascii: 'Lira',
		lat: 2.260390441,
		lng: 32.89002315,
		pop: 127384,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Lira',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Masindi-Port',
		city_ascii: 'Masindi-Port',
		lat: 1.700398782,
		lng: 32.06991817,
		pop: 8073.5,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Masindi',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Mbale',
		city_ascii: 'Mbale',
		lat: 1.090410175,
		lng: 34.1699967,
		pop: 247084,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Bungokho',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Tororo',
		city_ascii: 'Tororo',
		lat: 0.710381692,
		lng: 34.1699967,
		pop: 96850,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Tororo',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Kaabong',
		city_ascii: 'Kaabong',
		lat: 3.520391051,
		lng: 34.12002559,
		pop: 1137,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kaabong',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Moroto',
		city_ascii: 'Moroto',
		lat: 2.540347513,
		lng: 34.63999385,
		pop: 371,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Moroto',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Masaka',
		city_ascii: 'Masaka',
		lat: -0.329606507,
		lng: 31.7299906,
		pop: 65373,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Masaka',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Katwe',
		city_ascii: 'Katwe',
		lat: -0.129618715,
		lng: 29.92002356,
		pop: 1957,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kasese',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Mbarara',
		city_ascii: 'Mbarara',
		lat: -0.599615866,
		lng: 30.65000484,
		pop: 83700,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kashari',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Kabale',
		city_ascii: 'Kabale',
		lat: -1.249602032,
		lng: 29.97996822,
		pop: 44600,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kabale',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Kasese',
		city_ascii: 'Kasese',
		lat: 0.232478047,
		lng: 29.98828813,
		pop: 67269,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kasese',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Gulu',
		city_ascii: 'Gulu',
		lat: 2.779996967,
		lng: 32.28003454,
		pop: 144430.5,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Aswa',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Kampala',
		city_ascii: 'Kampala',
		lat: 0.316658955,
		lng: 32.58332353,
		pop: 1386594.5,
		country: 'Uganda',
		iso2: 'UG',
		iso3: 'UGA',
		province: 'Kampala',
		timezone: 'Africa/Kampala',
	},
	{
		city: 'Mykolayiv',
		city_ascii: 'Mykolayiv',
		lat: 46.96773907,
		lng: 31.984342,
		pop: 352917.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Mykolayiv',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Chernihiv',
		city_ascii: 'Chernihiv',
		lat: 51.50492983,
		lng: 31.3015413,
		pop: 293656.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Chernihiv',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Khmelnytskyy',
		city_ascii: 'Khmelnytskyy',
		lat: 49.42492759,
		lng: 27.00154537,
		pop: 322093,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: "Khmel'nyts'kyy",
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Kamyanets-Podilskyy',
		city_ascii: 'Kamyanets-Podilskyy',
		lat: 48.68430096,
		lng: 26.58089921,
		pop: 107329,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: "Khmel'nyts'kyy",
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Drohobych',
		city_ascii: 'Drohobych',
		lat: 49.34436403,
		lng: 23.49938188,
		pop: 101837.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: "L'viv",
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Uzhgorod',
		city_ascii: 'Uzhgorod',
		lat: 48.62998903,
		lng: 22.25000077,
		pop: 134355,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Transcarpathia',
		timezone: 'Europe/Uzhgorod',
	},
	{
		city: 'Uman',
		city_ascii: 'Uman',
		lat: 48.75429669,
		lng: 30.2109102,
		pop: 87620,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Cherkasy',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Brovary',
		city_ascii: 'Brovary',
		lat: 50.49431968,
		lng: 30.78090124,
		pop: 77355.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Kiev',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Bila Tserkva',
		city_ascii: 'Bila Tserkva',
		lat: 49.77431195,
		lng: 30.13091508,
		pop: 192418.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Kiev',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Illichivsk',
		city_ascii: 'Illichivsk',
		lat: 46.30000205,
		lng: 30.66659298,
		pop: 53906,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Odessa',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Konotop',
		city_ascii: 'Konotop',
		lat: 51.24238771,
		lng: 33.20902177,
		pop: 97672.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Sumy',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Kryvyy Rih',
		city_ascii: 'Kryvyy Rih',
		lat: 47.92832644,
		lng: 33.34498246,
		pop: 571643.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: "Dnipropetrovs'k",
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Makiyivka',
		city_ascii: 'Makiyivka',
		lat: 48.02966392,
		lng: 37.97462235,
		pop: 318882.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: "Donets'k",
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Horlivka',
		city_ascii: 'Horlivka',
		lat: 48.29964744,
		lng: 38.05466915,
		pop: 337717.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: "Donets'k",
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Kramatorsk',
		city_ascii: 'Kramatorsk',
		lat: 48.71936342,
		lng: 37.53439083,
		pop: 178902.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: "Donets'k",
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Berdyansk',
		city_ascii: 'Berdyansk',
		lat: 46.75682172,
		lng: 36.78683956,
		pop: 88409,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Zaporizhzhya',
		timezone: 'Europe/Zaporozhye',
	},
	{
		city: 'Dzhankoy',
		city_ascii: 'Dzhankoy',
		lat: 45.71704022,
		lng: 34.4000085,
		pop: 42805,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Crimea',
		timezone: 'Europe/Simferopol',
	},
	{
		city: 'Yevpatoriya',
		city_ascii: 'Yevpatoriya',
		lat: 45.19689109,
		lng: 33.36306921,
		pop: 90588,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Crimea',
		timezone: 'Europe/Simferopol',
	},
	{
		city: 'Kerch',
		city_ascii: 'Kerch',
		lat: 45.36850852,
		lng: 36.4880981,
		pop: 133972,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Crimea',
		timezone: 'Europe/Simferopol',
	},
	{
		city: 'Simferopol',
		city_ascii: 'Simferopol',
		lat: 44.94915428,
		lng: 34.0987349,
		pop: 305882.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Crimea',
		timezone: 'Europe/Simferopol',
	},
	{
		city: 'Kherson',
		city_ascii: 'Kherson',
		lat: 46.63253718,
		lng: 32.60066489,
		pop: 278578.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Crimea',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Voznesensk',
		city_ascii: 'Voznesensk',
		lat: 47.55036501,
		lng: 31.33332231,
		pop: 43122,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Mykolayiv',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Nizhyn',
		city_ascii: 'Nizhyn',
		lat: 51.0540788,
		lng: 31.89029089,
		pop: 95893.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Chernihiv',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Rivne',
		city_ascii: 'Rivne',
		lat: 50.61658612,
		lng: 26.25280554,
		pop: 253261,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Rivne',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Chernivtsi',
		city_ascii: 'Chernivtsi',
		lat: 48.30530601,
		lng: 25.92155961,
		pop: 267250.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Chernivtsi',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Ivano-Frankivsk',
		city_ascii: 'Ivano-Frankivsk',
		lat: 48.93475079,
		lng: 24.70938554,
		pop: 222719.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: "Ivano-Frankivs'k",
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Ternopil',
		city_ascii: 'Ternopil',
		lat: 49.53598024,
		lng: 25.5821488,
		pop: 240222,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Ternopil',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Lutsk',
		city_ascii: 'Lutsk',
		lat: 50.7471983,
		lng: 25.33337846,
		pop: 211980,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Volyn',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Kovel',
		city_ascii: 'Kovel',
		lat: 51.21706626,
		lng: 24.71662024,
		pop: 68850.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Volyn',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Cherkasy',
		city_ascii: 'Cherkasy',
		lat: 49.43472028,
		lng: 32.07090002,
		pop: 285694.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Cherkasy',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Kirovohrad',
		city_ascii: 'Kirovohrad',
		lat: 48.50405357,
		lng: 32.26029415,
		pop: 243573.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Kirovohrad',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Izmayil',
		city_ascii: 'Izmayil',
		lat: 45.35034426,
		lng: 28.83735063,
		pop: 82839.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Odessa',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Vinnytsya',
		city_ascii: 'Vinnytsya',
		lat: 49.22537905,
		lng: 28.48155839,
		pop: 349627,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Vinnytsya',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Korosten',
		city_ascii: 'Korosten',
		lat: 50.95041587,
		lng: 28.65002356,
		pop: 68992,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Zhytomyr',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Shostka',
		city_ascii: 'Shostka',
		lat: 51.87340863,
		lng: 33.47965124,
		pop: 91128.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Sumy',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Nikopol',
		city_ascii: 'Nikopol',
		lat: 47.56659141,
		lng: 34.40620967,
		pop: 119110,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: "Dnipropetrovs'k",
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Kupyansk',
		city_ascii: 'Kupyansk',
		lat: 49.72178286,
		lng: 37.59805619,
		pop: 78870,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Kharkiv',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Lysychansk',
		city_ascii: 'Lysychansk',
		lat: 48.92041058,
		lng: 38.42735958,
		pop: 118010.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: "Luhans'k",
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Luhansk',
		city_ascii: 'Luhansk',
		lat: 48.56976015,
		lng: 39.33438432,
		pop: 408931,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: "Luhans'k",
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Poltava',
		city_ascii: 'Poltava',
		lat: 49.57403994,
		lng: 34.57028235,
		pop: 302217.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Poltava',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Kremenchuk',
		city_ascii: 'Kremenchuk',
		lat: 49.08352724,
		lng: 33.42962846,
		pop: 209496.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Poltava',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Melitopol',
		city_ascii: 'Melitopol',
		lat: 46.83782452,
		lng: 35.37746822,
		pop: 135850,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Zaporizhzhya',
		timezone: 'Europe/Zaporozhye',
	},
	{
		city: 'Zaporizhzhya',
		city_ascii: 'Zaporizhzhya',
		lat: 47.85729718,
		lng: 35.17680863,
		pop: 600778.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Zaporizhzhya',
		timezone: 'Europe/Zaporozhye',
	},
	{
		city: 'Yalta',
		city_ascii: 'Yalta',
		lat: 44.49931093,
		lng: 34.15940303,
		pop: 76814.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Crimea',
		timezone: 'Europe/Simferopol',
	},
	{
		city: 'Chernobyl',
		city_ascii: 'Chernobyl',
		lat: 51.38940716,
		lng: 30.09887569,
		pop: 0,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Kiev',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Sumy',
		city_ascii: 'Sumy',
		lat: 50.92429344,
		lng: 34.78086381,
		pop: 289801,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Sumy',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Mariupol',
		city_ascii: 'Mariupol',
		lat: 47.09618085,
		lng: 37.55619828,
		pop: 416435,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: "Donets'k",
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Lvov',
		city_ascii: 'Lvov',
		lat: 49.83498008,
		lng: 24.02999548,
		pop: 760841.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: "L'viv",
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Odessa',
		city_ascii: 'Odessa',
		lat: 46.4900163,
		lng: 30.71000118,
		pop: 847500.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Odessa',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Zhytomyr',
		city_ascii: 'Zhytomyr',
		lat: 50.24557517,
		lng: 28.66216752,
		pop: 278581,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Zhytomyr',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Dnipropetrovsk',
		city_ascii: 'Dnipropetrovsk',
		lat: 48.47997235,
		lng: 35.00002356,
		pop: 949424.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: "Dnipropetrovs'k",
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Donetsk',
		city_ascii: 'Donetsk',
		lat: 48.00000165,
		lng: 37.82998002,
		pop: 874137.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: "Donets'k",
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Kharkiv',
		city_ascii: 'Kharkiv',
		lat: 49.99998293,
		lng: 36.25002478,
		pop: 1338063.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Kharkiv',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Sevastapol',
		city_ascii: 'Sevastapol',
		lat: 44.59997662,
		lng: 33.46497514,
		pop: 346832.5,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Crimea',
		timezone: 'Europe/Simferopol',
	},
	{
		city: 'Kiev',
		city_ascii: 'Kiev',
		lat: 50.43336733,
		lng: 30.51662797,
		pop: 2185754,
		country: 'Ukraine',
		iso2: 'UA',
		iso3: 'UKR',
		province: 'Kiev',
		timezone: 'Europe/Kiev',
	},
	{
		city: 'Umm al Qaywayn',
		city_ascii: 'Umm al Qaywayn',
		lat: 25.56527285,
		lng: 55.55334265,
		pop: 38531,
		country: 'United Arab Emirates',
		iso2: 'AE',
		iso3: 'ARE',
		province: 'Umm Al Qaywayn',
		timezone: 'Asia/Dubai',
	},
	{
		city: 'Sharjah',
		city_ascii: 'Sharjah',
		lat: 25.37138287,
		lng: 55.40647823,
		pop: 952015.5,
		country: 'United Arab Emirates',
		iso2: 'AE',
		iso3: 'ARE',
		province: 'Sharjah',
		timezone: 'Asia/Dubai',
	},
	{
		city: 'Jabal Ali',
		city_ascii: 'Jabal Ali',
		lat: 24.97623903,
		lng: 55.01074011,
		pop: 55817,
		country: 'United Arab Emirates',
		iso2: 'AE',
		iso3: 'ARE',
		province: 'Dubay',
		timezone: 'Asia/Dubai',
	},
	{
		city: 'Ras al Khaymah',
		city_ascii: 'Ras al Khaymah',
		lat: 25.79153811,
		lng: 55.94277624,
		pop: 138399,
		country: 'United Arab Emirates',
		iso2: 'AE',
		iso3: 'ARE',
		province: 'Ras Al Khaymah',
		timezone: 'Asia/Dubai',
	},
	{
		city: 'Al Fujayrah',
		city_ascii: 'Al Fujayrah',
		lat: 25.12343935,
		lng: 56.33748083,
		pop: 78289,
		country: 'United Arab Emirates',
		iso2: 'AE',
		iso3: 'ARE',
		province: 'Fujayrah',
		timezone: 'Asia/Dubai',
	},
	{
		city: 'Al Ayn',
		city_ascii: 'Al Ayn',
		lat: 24.2304706,
		lng: 55.73999792,
		pop: 352500.5,
		country: 'United Arab Emirates',
		iso2: 'AE',
		iso3: 'ARE',
		province: 'Abu Dhabi',
		timezone: 'Asia/Dubai',
	},
	{
		city: 'Abu Dhabi',
		city_ascii: 'Abu Dhabi',
		lat: 24.46668357,
		lng: 54.36659338,
		pop: 581861,
		country: 'United Arab Emirates',
		iso2: 'AE',
		iso3: 'ARE',
		province: 'Abu Dhabi',
		timezone: 'Asia/Dubai',
	},
	{
		city: 'Dubai',
		city_ascii: 'Dubai',
		lat: 25.22999615,
		lng: 55.27997432,
		pop: 1258173.5,
		country: 'United Arab Emirates',
		iso2: 'AE',
		iso3: 'ARE',
		province: 'Dubay',
		timezone: 'Asia/Dubai',
	},
	{
		city: 'Greenock',
		city_ascii: 'Greenock',
		lat: 55.93329002,
		lng: -4.750030763,
		pop: 59065,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Inverclyde',
		timezone: 'Europe/London',
	},
	{
		city: 'Sunderland',
		city_ascii: 'Sunderland',
		lat: 54.92001853,
		lng: -1.380029746,
		pop: 315449.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Tyne and Wear',
		timezone: 'Europe/London',
	},
	{
		city: 'Southampton',
		city_ascii: 'Southampton',
		lat: 50.90003135,
		lng: -1.399976849,
		pop: 384417,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Southampton',
		timezone: 'Europe/London',
	},
	{
		city: 'Bristol',
		city_ascii: 'Bristol',
		lat: 51.44999778,
		lng: -2.583315472,
		pop: 492120.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Bristol',
		timezone: 'Europe/London',
	},
	{
		city: 'Bournemouth',
		city_ascii: 'Bournemouth',
		lat: 50.72999005,
		lng: -1.900049684,
		pop: 295272.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Bournemouth',
		timezone: 'Europe/London',
	},
	{
		city: 'Omagh',
		city_ascii: 'Omagh',
		lat: 54.60001223,
		lng: -7.300004315,
		pop: 18691,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Omagh',
		timezone: 'Europe/London',
	},
	{
		city: 'Chester',
		city_ascii: 'Chester',
		lat: 53.20002016,
		lng: -2.919987428,
		pop: 83285.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Cheshire',
		timezone: 'Europe/London',
	},
	{
		city: 'Swansea',
		city_ascii: 'Swansea',
		lat: 51.6299868,
		lng: -3.950002077,
		pop: 232611,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Swansea',
		timezone: 'Europe/London',
	},
	{
		city: 'Carlisle',
		city_ascii: 'Carlisle',
		lat: 54.87999514,
		lng: -2.929986818,
		pop: 69270,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Cumbria',
		timezone: 'Europe/London',
	},
	{
		city: 'Southend-on-Sea',
		city_ascii: 'Southend',
		lat: 51.55001752,
		lng: 0.71999711,
		pop: 395993,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Southend-on-Sea',
		timezone: 'Europe/London',
	},
	{
		city: 'Reading',
		city_ascii: 'Reading',
		lat: 51.46997072,
		lng: -0.980028322,
		pop: 257752,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Oxfordshire',
		timezone: 'Europe/London',
	},
	{
		city: 'Leicester',
		city_ascii: 'Leicester',
		lat: 52.62997744,
		lng: -1.133248943,
		pop: 398611,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Leicester',
		timezone: 'Europe/London',
	},
	{
		city: 'Bradford',
		city_ascii: 'Bradford',
		lat: 53.80003522,
		lng: -1.749981325,
		pop: 397708.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'West Yorkshire',
		timezone: 'Europe/London',
	},
	{
		city: 'Sheffield',
		city_ascii: 'Sheffield',
		lat: 53.36667666,
		lng: -1.499996583,
		pop: 922800,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'South Yorkshire',
		timezone: 'Europe/London',
	},
	{
		city: 'Fort William',
		city_ascii: 'Fort William',
		lat: 56.81647795,
		lng: -5.112075806,
		pop: 9652,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Highland',
		timezone: 'Europe/London',
	},
	{
		city: 'Ayr',
		city_ascii: 'Ayr',
		lat: 55.4503996,
		lng: -4.61667973,
		pop: 57277.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'South Ayrshire',
		timezone: 'Europe/London',
	},
	{
		city: 'Aberdeen',
		city_ascii: 'Aberdeen',
		lat: 57.17039797,
		lng: -2.079987021,
		pop: 186577,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Aberdeen',
		timezone: 'Europe/London',
	},
	{
		city: 'Perth',
		city_ascii: 'Perth',
		lat: 56.40034161,
		lng: -3.469979697,
		pop: 39654,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Perthshire and Kinross',
		timezone: 'Europe/London',
	},
	{
		city: 'Dundee',
		city_ascii: 'Dundee',
		lat: 56.47038902,
		lng: -3.000008384,
		pop: 151013.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Dundee',
		timezone: 'Europe/London',
	},
	{
		city: 'Middlesbrough',
		city_ascii: 'Middlesbrough',
		lat: 54.58037518,
		lng: -1.230013063,
		pop: 279374.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Stockton-on-Tees',
		timezone: 'Europe/London',
	},
	{
		city: 'Coventry',
		city_ascii: 'Coventry',
		lat: 52.42040367,
		lng: -1.499996583,
		pop: 348292,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'West Midlands',
		timezone: 'Europe/London',
	},
	{
		city: 'Bath',
		city_ascii: 'Bath',
		lat: 51.3837486,
		lng: -2.350022218,
		pop: 92679,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Bath and North East Somerset',
		timezone: 'Europe/London',
	},
	{
		city: 'Exeter',
		city_ascii: 'Exeter',
		lat: 50.70040529,
		lng: -3.529950197,
		pop: 108242,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Devon',
		timezone: 'Europe/London',
	},
	{
		city: 'Cambridge',
		city_ascii: 'Cambridge',
		lat: 52.20039125,
		lng: 0.116623086,
		pop: 128488,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Cambridgeshire',
		timezone: 'Europe/London',
	},
	{
		city: 'Kingston upon Hull',
		city_ascii: 'Kingston upon Hull',
		lat: 53.75042584,
		lng: -0.32999048,
		pop: 297398.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Kingston upon Hull',
		timezone: 'Europe/London',
	},
	{
		city: 'Londonderry',
		city_ascii: 'Londonderry',
		lat: 55.00037539,
		lng: -7.333283937,
		pop: 82635,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Derry',
		timezone: 'Europe/London',
	},
	{
		city: 'Lisburn',
		city_ascii: 'Lisburn',
		lat: 54.52037884,
		lng: -6.670016929,
		pop: 12899,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Dungannon',
		timezone: 'Europe/London',
	},
	{
		city: 'Penzance',
		city_ascii: 'Penzance',
		lat: 50.13372154,
		lng: -5.550033611,
		pop: 18150.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Cornwall',
		timezone: 'Europe/London',
	},
	{
		city: 'York',
		city_ascii: 'York',
		lat: 53.97038658,
		lng: -1.080022218,
		pop: 151574.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'York',
		timezone: 'Europe/London',
	},
	{
		city: 'Blackpool',
		city_ascii: 'Blackpool',
		lat: 53.83039512,
		lng: -3.050005332,
		pop: 207946.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Lancashire',
		timezone: 'Europe/London',
	},
	{
		city: 'Dumfries',
		city_ascii: 'Dumfries',
		lat: 55.06708966,
		lng: -3.550000652,
		pop: 31044,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Dumfries and Galloway',
		timezone: 'Europe/London',
	},
	{
		city: 'Scarborough',
		city_ascii: 'Scarborough',
		lat: 54.28039349,
		lng: -0.429984376,
		pop: 70571,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'North Yorkshire',
		timezone: 'Europe/London',
	},
	{
		city: 'Plymouth',
		city_ascii: 'Plymouth',
		lat: 50.38538576,
		lng: -4.159989259,
		pop: 239436,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Plymouth',
		timezone: 'Europe/London',
	},
	{
		city: 'Ipswich',
		city_ascii: 'Ipswich',
		lat: 52.07034751,
		lng: 1.169995482,
		pop: 139012,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Suffolk',
		timezone: 'Europe/London',
	},
	{
		city: 'Norwich',
		city_ascii: 'Norwich',
		lat: 52.63036501,
		lng: 1.300013386,
		pop: 184196,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Norfolk',
		timezone: 'Europe/London',
	},
	{
		city: 'Brighton',
		city_ascii: 'Brighton',
		lat: 50.83034568,
		lng: -0.169974407,
		pop: 321004.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Brighton and Hove',
		timezone: 'Europe/London',
	},
	{
		city: 'Kirkwall',
		city_ascii: 'Kirkwall',
		lat: 58.96698081,
		lng: -2.950011435,
		pop: 5826.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Moray',
		timezone: 'Europe/London',
	},
	{
		city: 'Inverness',
		city_ascii: 'Inverness',
		lat: 57.46712404,
		lng: -4.23326644,
		pop: 42956.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Highland',
		timezone: 'Europe/London',
	},
	{
		city: 'Oxford',
		city_ascii: 'Oxford',
		lat: 51.7704175,
		lng: -1.249986004,
		pop: 173681,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Oxfordshire',
		timezone: 'Europe/London',
	},
	{
		city: 'Luton',
		city_ascii: 'Luton',
		lat: 51.88035911,
		lng: -0.420010825,
		pop: 214813.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Luton',
		timezone: 'Europe/London',
	},
	{
		city: 'Portsmouth',
		city_ascii: 'Portsmouth',
		lat: 50.80034751,
		lng: -1.080022218,
		pop: 323676,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Portsmouth',
		timezone: 'Europe/London',
	},
	{
		city: 'Peterborough',
		city_ascii: 'Peterborough',
		lat: 52.58041974,
		lng: -0.249995363,
		pop: 140141,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Peterborough',
		timezone: 'Europe/London',
	},
	{
		city: 'Nottingham',
		city_ascii: 'Nottingham',
		lat: 52.97034426,
		lng: -1.170016725,
		pop: 565650,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Nottingham',
		timezone: 'Europe/London',
	},
	{
		city: 'Stoke',
		city_ascii: 'Stoke',
		lat: 53.00036826,
		lng: -2.180006756,
		pop: 325610,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Stoke-on-Trent',
		timezone: 'Europe/London',
	},
	{
		city: 'Dover',
		city_ascii: 'Dover',
		lat: 51.13371218,
		lng: 1.300013386,
		pop: 32270,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Kent',
		timezone: 'Europe/London',
	},
	{
		city: 'Edinburgh',
		city_ascii: 'Edinburgh',
		lat: 55.94832786,
		lng: -3.219090618,
		pop: 470378.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Edinburgh',
		timezone: 'Europe/London',
	},
	{
		city: 'Newcastle',
		city_ascii: 'Newcastle',
		lat: 55.00037539,
		lng: -1.59999048,
		pop: 537191,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Tyne and Wear',
		timezone: 'Europe/London',
	},
	{
		city: 'Liverpool',
		city_ascii: 'Liverpool',
		lat: 53.41600181,
		lng: -2.917997886,
		pop: 639972.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Merseyside',
		timezone: 'Europe/London',
	},
	{
		city: 'Cardiff',
		city_ascii: 'Cardiff',
		lat: 51.49999473,
		lng: -3.22500757,
		pop: 603750,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Cardiff',
		timezone: 'Europe/London',
	},
	{
		city: 'Wick',
		city_ascii: 'Wick',
		lat: 58.43329246,
		lng: -3.083362469,
		pop: 7147,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Highland',
		timezone: 'Europe/London',
	},
	{
		city: 'Leeds',
		city_ascii: 'Leeds',
		lat: 53.83000755,
		lng: -1.580017539,
		pop: 992061.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'West Yorkshire',
		timezone: 'Europe/London',
	},
	{
		city: 'Lerwick',
		city_ascii: 'Lerwick',
		lat: 60.15003522,
		lng: -1.149992108,
		pop: 5604,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Aberdeen',
		timezone: 'Europe/London',
	},
	{
		city: 'Manchester',
		city_ascii: 'Manchester',
		lat: 53.50041526,
		lng: -2.247987103,
		pop: 1312757.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Manchester',
		timezone: 'Europe/London',
	},
	{
		city: 'Birmingham',
		city_ascii: 'Birmingham',
		lat: 52.47497398,
		lng: -1.919996787,
		pop: 1634666.5,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'West Midlands',
		timezone: 'Europe/London',
	},
	{
		city: 'Belfast',
		city_ascii: 'Belfast',
		lat: 54.60001223,
		lng: -5.960034425,
		pop: 362588,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Belfast',
		timezone: 'Europe/London',
	},
	{
		city: 'Glasgow',
		city_ascii: 'Glasgow',
		lat: 55.87440472,
		lng: -4.250707236,
		pop: 885134,
		country: 'United Kingdom',
		iso2: 'GB',
		iso3: 'GBR',
		province: 'Glasgow',
		timezone: 'Europe/London',
	},

	{
		city: 'Faribault',
		city_ascii: 'Faribault',
		lat: 44.29048647,
		lng: -93.26801274,
		pop: 24004.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Mankato',
		city_ascii: 'Mankato',
		lat: 44.16362083,
		lng: -93.99915674,
		pop: 45731.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Albert Lea',
		city_ascii: 'Albert Lea',
		lat: 43.64778668,
		lng: -93.36870427,
		pop: 19063.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Willmar',
		city_ascii: 'Willmar',
		lat: 45.12188275,
		lng: -95.04330489,
		pop: 18432,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Brainerd',
		city_ascii: 'Brainerd',
		lat: 46.35800885,
		lng: -94.20084986,
		pop: 21202.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Crookston',
		city_ascii: 'Crookston',
		lat: 47.77376223,
		lng: -96.60773137,
		pop: 8215.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Hardin',
		city_ascii: 'Hardin',
		lat: 45.731768,
		lng: -107.612486,
		pop: 3975.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Montana',
		state_ansi: 'MT',
		timezone: 'America/Denver',
	},
	{
		city: 'Glendive',
		city_ascii: 'Glendive',
		lat: 47.10858319,
		lng: -104.7104926,
		pop: 5277.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Montana',
		state_ansi: 'MT',
		timezone: 'America/Denver',
	},
	{
		city: 'Dillon',
		city_ascii: 'Dillon',
		lat: 45.21567548,
		lng: -112.6839852,
		pop: 4213.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Montana',
		state_ansi: 'MT',
		timezone: 'America/Denver',
	},
	{
		city: 'Polson',
		city_ascii: 'Polson',
		lat: 47.68800519,
		lng: -114.156686,
		pop: 5079,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Montana',
		state_ansi: 'MT',
		timezone: 'America/Denver',
	},
	{
		city: 'Devils Lake',
		city_ascii: 'Devils Lake',
		lat: 48.11221702,
		lng: -98.85968693,
		pop: 7312,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Dakota',
		state_ansi: 'ND',
		timezone: 'America/Chicago',
	},
	{
		city: 'Burley',
		city_ascii: 'Burley',
		lat: 42.53581321,
		lng: -113.7918763,
		pop: 11644.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Idaho',
		state_ansi: 'ID',
		timezone: 'America/Boise',
	},
	{
		city: 'Wallace',
		city_ascii: 'Wallace',
		lat: 47.47421979,
		lng: -115.9268881,
		pop: 1028,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Idaho',
		state_ansi: 'ID',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Kennewick',
		city_ascii: 'Kennewick',
		lat: 46.21137697,
		lng: -119.1360979,
		pop: 82331,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Centralia',
		city_ascii: 'Centralia',
		lat: 46.71641075,
		lng: -122.9529708,
		pop: 16993.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Glendale',
		city_ascii: 'Glendale',
		lat: 33.58194114,
		lng: -112.1958238,
		pop: 363360.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Safford',
		city_ascii: 'Safford',
		lat: 32.83382143,
		lng: -109.7068801,
		pop: 9746.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Casa Grande',
		city_ascii: 'Casa Grande',
		lat: 32.87937421,
		lng: -111.7566258,
		pop: 38396.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Mesa',
		city_ascii: 'Mesa',
		lat: 33.42391461,
		lng: -111.7360844,
		pop: 762217.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Lake Havasu City',
		city_ascii: 'Lake Havasu City',
		lat: 34.49829348,
		lng: -114.3082789,
		pop: 55442.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Nogales',
		city_ascii: 'Nogales',
		lat: 31.340278,
		lng: -110.934167,
		pop: 20837,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Berkeley',
		city_ascii: 'Berkeley',
		lat: 37.87390139,
		lng: -122.271152,
		pop: 298257,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'National City',
		city_ascii: 'National City',
		lat: 32.67194501,
		lng: -117.0980052,
		pop: 104291,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Mendocino',
		city_ascii: 'Mendocino',
		lat: 39.30776735,
		lng: -123.7994308,
		pop: 548,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Paso Robles',
		city_ascii: 'Paso Robles',
		lat: 35.6265967,
		lng: -120.6899823,
		pop: 26221,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Riverside',
		city_ascii: 'Riverside',
		lat: 33.94194501,
		lng: -117.3980386,
		pop: 297554,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Delano',
		city_ascii: 'Delano',
		lat: 35.76193728,
		lng: -119.2430681,
		pop: 42396.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'San Mateo',
		city_ascii: 'San Mateo',
		lat: 37.55691815,
		lng: -122.3130616,
		pop: 361806.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Vallejo',
		city_ascii: 'Vallejo',
		lat: 38.11194887,
		lng: -122.258052,
		pop: 133367.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Glenwood Springs',
		city_ascii: 'Glenwood Springs',
		lat: 39.54658999,
		lng: -107.3247,
		pop: 11272,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Colorado',
		state_ansi: 'CO',
		timezone: 'America/Denver',
	},
	{
		city: 'Aurora',
		city_ascii: 'Aurora',
		lat: 39.69585736,
		lng: -104.808497,
		pop: 431966.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Colorado',
		state_ansi: 'CO',
		timezone: 'America/Denver',
	},
	{
		city: 'Greeley',
		city_ascii: 'Greeley',
		lat: 40.41919822,
		lng: -104.739974,
		pop: 106142.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Colorado',
		state_ansi: 'CO',
		timezone: 'America/Denver',
	},
	{
		city: 'Tonopah',
		city_ascii: 'Tonopah',
		lat: 38.06699038,
		lng: -117.2289791,
		pop: 1993,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nevada',
		state_ansi: 'NV',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Deming',
		city_ascii: 'Deming',
		lat: 32.26109153,
		lng: -107.7557848,
		pop: 15523,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Truth or Consequences',
		city_ascii: 'Truth or Consequences',
		lat: 33.13359641,
		lng: -107.2528956,
		pop: 6479,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Las Vegas',
		city_ascii: 'Las Vegas',
		lat: 35.59701194,
		lng: -105.2225027,
		pop: 15521,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Farmington',
		city_ascii: 'Farmington',
		lat: 36.75415061,
		lng: -108.1860944,
		pop: 42917.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Springfield',
		city_ascii: 'Springfield',
		lat: 44.05194806,
		lng: -122.9780339,
		pop: 55531.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Tillamook',
		city_ascii: 'Tillamook',
		lat: 45.45524742,
		lng: -123.8425031,
		pop: 6351.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Ontario',
		city_ascii: 'Ontario',
		lat: 44.02662661,
		lng: -116.9618895,
		pop: 11578,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Boise',
	},
	{
		city: 'La Grande',
		city_ascii: 'La Grande',
		lat: 45.32468691,
		lng: -118.0866012,
		pop: 13646,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Richfield',
		city_ascii: 'Richfield',
		lat: 38.77247703,
		lng: -112.0832984,
		pop: 7308.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Utah',
		state_ansi: 'UT',
		timezone: 'America/Denver',
	},
	{
		city: 'Nephi',
		city_ascii: 'Nephi',
		lat: 39.71027508,
		lng: -111.8354841,
		pop: 4960,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Utah',
		state_ansi: 'UT',
		timezone: 'America/Denver',
	},
	{
		city: 'Lander',
		city_ascii: 'Lander',
		lat: 42.83300437,
		lng: -108.7325985,
		pop: 6742,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wyoming',
		state_ansi: 'WY',
		timezone: 'America/Denver',
	},
	{
		city: 'Powell',
		city_ascii: 'Powell',
		lat: 44.75867495,
		lng: -108.7584367,
		pop: 6054,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wyoming',
		state_ansi: 'WY',
		timezone: 'America/Denver',
	},
	{
		city: 'Paragould',
		city_ascii: 'Paragould',
		lat: 36.05708722,
		lng: -90.50288436,
		pop: 22886,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arkansas',
		state_ansi: 'AR',
		timezone: 'America/Chicago',
	},
	{
		city: 'Iowa City',
		city_ascii: 'Iowa City',
		lat: 41.66108624,
		lng: -91.52997929,
		pop: 81343,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Iowa',
		state_ansi: 'IA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Ottumwa',
		city_ascii: 'Ottumwa',
		lat: 41.01288291,
		lng: -92.414809,
		pop: 25287.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Iowa',
		state_ansi: 'IA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Spencer',
		city_ascii: 'Spencer',
		lat: 43.14528505,
		lng: -95.14717452,
		pop: 10938.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Iowa',
		state_ansi: 'IA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Ft. Dodge',
		city_ascii: 'Ft. Dodge',
		lat: 42.50682273,
		lng: -94.1802568,
		pop: 26284,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Iowa',
		state_ansi: 'IA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Hutchinson',
		city_ascii: 'Hutchinson',
		lat: 38.06549176,
		lng: -97.92349085,
		pop: 42536.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kansas',
		state_ansi: 'KS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Kansas City',
		city_ascii: 'Kansas City',
		lat: 39.11358052,
		lng: -94.63014638,
		pop: 324221.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kansas',
		state_ansi: 'KS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Lawrence',
		city_ascii: 'Lawrence',
		lat: 38.95975242,
		lng: -95.25522994,
		pop: 88020,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kansas',
		state_ansi: 'KS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Garden City',
		city_ascii: 'Garden City',
		lat: 37.97521303,
		lng: -100.8640866,
		pop: 27494.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kansas',
		state_ansi: 'KS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Manhattan',
		city_ascii: 'Manhattan',
		lat: 39.19402753,
		lng: -96.59243514,
		pop: 51289,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kansas',
		state_ansi: 'KS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Hays',
		city_ascii: 'Hays',
		lat: 38.87936973,
		lng: -99.322191,
		pop: 20638.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kansas',
		state_ansi: 'KS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Goodland',
		city_ascii: 'Goodland',
		lat: 39.34848838,
		lng: -101.7110374,
		pop: 4258.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kansas',
		state_ansi: 'KS',
		timezone: 'America/Denver',
	},
	{
		city: 'Independence',
		city_ascii: 'Independence',
		lat: 39.09111391,
		lng: -94.41528121,
		pop: 130695,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Missouri',
		state_ansi: 'MO',
		timezone: 'America/Chicago',
	},
	{
		city: 'Kirksville',
		city_ascii: 'Kirksville',
		lat: 40.19368227,
		lng: -92.58280908,
		pop: 18083,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Missouri',
		state_ansi: 'MO',
		timezone: 'America/Chicago',
	},
	{
		city: 'Kearney',
		city_ascii: 'Kearney',
		lat: 40.70070559,
		lng: -99.08114628,
		pop: 30155.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nebraska',
		state_ansi: 'NE',
		timezone: 'America/Chicago',
	},
	{
		city: 'Grand Island',
		city_ascii: 'Grand Island',
		lat: 40.92226829,
		lng: -98.35798629,
		pop: 45208.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nebraska',
		state_ansi: 'NE',
		timezone: 'America/Chicago',
	},
	{
		city: 'Alliance',
		city_ascii: 'Alliance',
		lat: 42.10139528,
		lng: -102.8701915,
		pop: 8269,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nebraska',
		state_ansi: 'NE',
		timezone: 'America/Denver',
	},
	{
		city: 'Bartlesville',
		city_ascii: 'Bartlesville',
		lat: 36.74720013,
		lng: -95.98058618,
		pop: 24935,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oklahoma',
		state_ansi: 'OK',
		timezone: 'America/Chicago',
	},
	{
		city: 'Enid',
		city_ascii: 'Enid',
		lat: 36.39554201,
		lng: -97.8780931,
		pop: 45963.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oklahoma',
		state_ansi: 'OK',
		timezone: 'America/Chicago',
	},
	{
		city: 'Ardmore',
		city_ascii: 'Ardmore',
		lat: 34.1810777,
		lng: -97.12940495,
		pop: 24467.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oklahoma',
		state_ansi: 'OK',
		timezone: 'America/Chicago',
	},
	{
		city: 'McAlester',
		city_ascii: 'McAlester',
		lat: 34.93299562,
		lng: -95.76597396,
		pop: 19894,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oklahoma',
		state_ansi: 'OK',
		timezone: 'America/Chicago',
	},
	{
		city: 'Stillwater',
		city_ascii: 'Stillwater',
		lat: 36.13535118,
		lng: -97.06829757,
		pop: 45212,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oklahoma',
		state_ansi: 'OK',
		timezone: 'America/Chicago',
	},
	{
		city: 'Lead',
		city_ascii: 'Lead',
		lat: 44.35084454,
		lng: -103.7657699,
		pop: 2311,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Dakota',
		state_ansi: 'SD',
		timezone: 'America/Denver',
	},
	{
		city: 'Slidell',
		city_ascii: 'Slidell',
		lat: 30.27495953,
		lng: -89.78109379,
		pop: 56019,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Louisiana',
		state_ansi: 'LA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Lake Charles',
		city_ascii: 'Lake Charles',
		lat: 30.22638369,
		lng: -93.21718897,
		pop: 77065,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Louisiana',
		state_ansi: 'LA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Metairie',
		city_ascii: 'Metairie',
		lat: 29.98386619,
		lng: -90.15277653,
		pop: 270171,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Louisiana',
		state_ansi: 'LA',
		timezone: 'America/Chicago',
	},
	{
		city: 'New Iberia',
		city_ascii: 'New Iberia',
		lat: 30.00358075,
		lng: -91.81830794,
		pop: 34985,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Louisiana',
		state_ansi: 'LA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Bryan',
		city_ascii: 'Bryan',
		lat: 30.67418581,
		lng: -96.36968388,
		pop: 108156.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'San Marcos',
		city_ascii: 'San Marcos',
		lat: 29.88307131,
		lng: -97.94111251,
		pop: 58553.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Longview',
		city_ascii: 'Longview',
		lat: 32.50053428,
		lng: -94.74027429,
		pop: 75658,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'McAllen',
		city_ascii: 'McAllen',
		lat: 26.20303754,
		lng: -98.22972538,
		pop: 243291,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Harlingen',
		city_ascii: 'Harlingen',
		lat: 26.19755983,
		lng: -97.69019759,
		pop: 86749,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Alice',
		city_ascii: 'Alice',
		lat: 27.75046246,
		lng: -98.07048446,
		pop: 21122,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'New Braunfels',
		city_ascii: 'New Braunfels',
		lat: 29.6978113,
		lng: -98.12632084,
		pop: 45270,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Cleburne',
		city_ascii: 'Cleburne',
		lat: 32.35152529,
		lng: -97.39248967,
		pop: 32263,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Brownwood',
		city_ascii: 'Brownwood',
		lat: 31.70789532,
		lng: -98.98231511,
		pop: 20261,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Alpine',
		city_ascii: 'Alpine',
		lat: 30.36071657,
		lng: -103.6650009,
		pop: 6429.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Van Horn',
		city_ascii: 'Van Horn',
		lat: 31.04248374,
		lng: -104.8322423,
		pop: 1797,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Big Spring',
		city_ascii: 'Big Spring',
		lat: 32.24318565,
		lng: -101.4751862,
		pop: 23987,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Vernon',
		city_ascii: 'Vernon',
		lat: 34.15105369,
		lng: -99.29038416,
		pop: 11574.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Childress',
		city_ascii: 'Childress',
		lat: 34.4248871,
		lng: -100.2139195,
		pop: 5662,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Hereford',
		city_ascii: 'Hereford',
		lat: 34.82191713,
		lng: -102.3985924,
		pop: 15023.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Dalhart',
		city_ascii: 'Dalhart',
		lat: 36.06080792,
		lng: -102.5186109,
		pop: 6763.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Texas City',
		city_ascii: 'Texas City',
		lat: 29.41087791,
		lng: -94.96276717,
		pop: 55717.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Pasadena',
		city_ascii: 'Pasadena',
		lat: 29.66086265,
		lng: -95.14774296,
		pop: 388767.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Baytown',
		city_ascii: 'Baytown',
		lat: 29.75584393,
		lng: -94.96772811,
		pop: 76687.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Arlington',
		city_ascii: 'Grand Prairie',
		lat: 32.68476076,
		lng: -97.02023849,
		pop: 545107.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'New London',
		city_ascii: 'New London',
		lat: 41.3555235,
		lng: -72.10002832,
		pop: 61236,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Connecticut',
		state_ansi: 'CT',
		timezone: 'America/New_York',
	},
	{
		city: 'Stamford',
		city_ascii: 'Stamford',
		lat: 41.05334556,
		lng: -73.53919112,
		pop: 434781.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Connecticut',
		state_ansi: 'CT',
		timezone: 'America/New_York',
	},
	{
		city: 'Waterbury',
		city_ascii: 'Waterbury',
		lat: 41.55000775,
		lng: -73.05002202,
		pop: 174236,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Connecticut',
		state_ansi: 'CT',
		timezone: 'America/New_York',
	},
	{
		city: 'New Bedford',
		city_ascii: 'New Bedford',
		lat: 41.6561253,
		lng: -70.94469833,
		pop: 115082.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Massachusetts',
		state_ansi: 'MA',
		timezone: 'America/New_York',
	},
	{
		city: 'Springfield',
		city_ascii: 'Springfield',
		lat: 42.12002464,
		lng: -72.57999903,
		pop: 287003.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Massachusetts',
		state_ansi: 'MA',
		timezone: 'America/New_York',
	},
	{
		city: 'Salem',
		city_ascii: 'Salem',
		lat: 42.5224989,
		lng: -70.88309175,
		pop: 188982,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Massachusetts',
		state_ansi: 'MA',
		timezone: 'America/New_York',
	},
	{
		city: 'Pittsfield',
		city_ascii: 'Pittsfield',
		lat: 42.44819582,
		lng: -73.25982833,
		pop: 45202,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Massachusetts',
		state_ansi: 'MA',
		timezone: 'America/New_York',
	},
	{
		city: 'Montpelier',
		city_ascii: 'Montpelier',
		lat: 44.25997154,
		lng: -72.57581323,
		pop: 8183,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Vermont',
		state_ansi: 'VT',
		timezone: 'America/New_York',
	},
	{
		city: 'Auburn',
		city_ascii: 'Auburn',
		lat: 32.60970074,
		lng: -85.48083948,
		pop: 61881,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alabama',
		state_ansi: 'AL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Florence',
		city_ascii: 'Florence',
		lat: 34.79969627,
		lng: -87.67724288,
		pop: 40806.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alabama',
		state_ansi: 'AL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Winter Haven',
		city_ascii: 'Winter Haven',
		lat: 28.02191876,
		lng: -81.73300623,
		pop: 68435,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Melbourne',
		city_ascii: 'Melbourne',
		lat: 28.08331036,
		lng: -80.60832035,
		pop: 170870,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Homestead',
		city_ascii: 'Homestead',
		lat: 25.46830202,
		lng: -80.47778569,
		pop: 60673,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Sanford',
		city_ascii: 'Sanford',
		lat: 28.78995974,
		lng: -81.27998478,
		pop: 189374.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Miami Beach',
		city_ascii: 'Miami Beach',
		lat: 25.80991953,
		lng: -80.13178111,
		pop: 248538,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Coral Springs',
		city_ascii: 'Coral Springs',
		lat: 26.27083701,
		lng: -80.27082158,
		pop: 185548,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Port Charlotte',
		city_ascii: 'Port Charlotte',
		lat: 27.00004315,
		lng: -82.10569666,
		pop: 56806,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Spring Hill',
		city_ascii: 'Spring Hill',
		lat: 28.47894513,
		lng: -82.54771102,
		pop: 91887.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Palm Coast',
		city_ascii: 'Palm Coast',
		lat: 29.53800193,
		lng: -81.22329574,
		pop: 45030,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Palatka',
		city_ascii: 'Palatka',
		lat: 29.64768516,
		lng: -81.65130579,
		pop: 16094.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Leesburg',
		city_ascii: 'Leesburg',
		lat: 28.81050112,
		lng: -81.88333297,
		pop: 33929,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Lake City',
		city_ascii: 'Lake City',
		lat: 30.18971926,
		lng: -82.63974675,
		pop: 20159.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Crestview',
		city_ascii: 'Crestview',
		lat: 30.75420677,
		lng: -86.57260746,
		pop: 19552.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Panama City',
		city_ascii: 'Panama City',
		lat: 30.15861005,
		lng: -85.65527328,
		pop: 68852.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Dalton',
		city_ascii: 'Dalton',
		lat: 34.76972394,
		lng: -84.97030217,
		pop: 45077.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Georgia',
		state_ansi: 'GA',
		timezone: 'America/New_York',
	},
	{
		city: 'Marietta',
		city_ascii: 'Marietta',
		lat: 33.95561342,
		lng: -84.54324813,
		pop: 61360,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Georgia',
		state_ansi: 'GA',
		timezone: 'America/New_York',
	},
	{
		city: 'Waycross',
		city_ascii: 'Waycross',
		lat: 31.21381695,
		lng: -82.35490625,
		pop: 17445.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Georgia',
		state_ansi: 'GA',
		timezone: 'America/New_York',
	},
	{
		city: 'La Grange',
		city_ascii: 'La Grange',
		lat: 33.03647056,
		lng: -85.03187464,
		pop: 28887,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Georgia',
		state_ansi: 'GA',
		timezone: 'America/New_York',
	},
	{
		city: 'Southaven',
		city_ascii: 'Southaven',
		lat: 34.96891075,
		lng: -90.00345748,
		pop: 79923,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Mississippi',
		state_ansi: 'MS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Meridian',
		city_ascii: 'Meridian',
		lat: 32.36418601,
		lng: -88.70361434,
		pop: 40863.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Mississippi',
		state_ansi: 'MS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Laurel',
		city_ascii: 'Laurel',
		lat: 31.69737917,
		lng: -89.1392725,
		pop: 23366,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Mississippi',
		state_ansi: 'MS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Spartanburg',
		city_ascii: 'Spartanburg',
		lat: 34.94942873,
		lng: -81.93227055,
		pop: 81059,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Carolina',
		state_ansi: 'SC',
		timezone: 'America/New_York',
	},
	{
		city: 'Orangeburg',
		city_ascii: 'Orangeburg',
		lat: 33.49680422,
		lng: -80.86223251,
		pop: 24192.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Carolina',
		state_ansi: 'SC',
		timezone: 'America/New_York',
	},
	{
		city: 'Galesburg',
		city_ascii: 'Galesburg',
		lat: 40.94777061,
		lng: -90.37108362,
		pop: 32078.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Joliet',
		city_ascii: 'Joliet',
		lat: 41.52998313,
		lng: -88.10667403,
		pop: 362946.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Cape Girardeau',
		city_ascii: 'Cape Girardeau',
		lat: 37.30582237,
		lng: -89.51808659,
		pop: 38165.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Rockford',
		city_ascii: 'Rockford',
		lat: 42.26970542,
		lng: -89.06969019,
		pop: 204371.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Evanston',
		city_ascii: 'Evanston',
		lat: 42.04834943,
		lng: -87.69995467,
		pop: 212243,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Rock Island',
		city_ascii: 'Rock Island',
		lat: 41.49339622,
		lng: -90.53461369,
		pop: 102055.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Elgin',
		city_ascii: 'Elgin',
		lat: 42.03946108,
		lng: -88.28991866,
		pop: 244050,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Richmond',
		city_ascii: 'Richmond',
		lat: 39.82889833,
		lng: -84.89028121,
		pop: 41015.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Indiana',
		state_ansi: 'IN',
		timezone: 'America/Indiana/Indianapolis',
	},
	{
		city: 'Terre Haute',
		city_ascii: 'Terre Haute',
		lat: 39.46664654,
		lng: -87.41387394,
		pop: 65149.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Indiana',
		state_ansi: 'IN',
		timezone: 'America/Indiana/Indianapolis',
	},
	{
		city: 'Lafayette',
		city_ascii: 'Lafayette',
		lat: 40.41720868,
		lng: -86.87824772,
		pop: 98104,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Indiana',
		state_ansi: 'IN',
		timezone: 'America/Indiana/Indianapolis',
	},
	{
		city: 'Marion',
		city_ascii: 'Marion',
		lat: 40.55833701,
		lng: -85.65917485,
		pop: 34249,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Indiana',
		state_ansi: 'IN',
		timezone: 'America/Indiana/Indianapolis',
	},
	{
		city: 'South Bend',
		city_ascii: 'South Bend',
		lat: 41.68330711,
		lng: -86.25001734,
		pop: 171791,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Indiana',
		state_ansi: 'IN',
		timezone: 'America/Indiana/Indianapolis',
	},
	{
		city: 'New Albany',
		city_ascii: 'New Albany',
		lat: 38.3108773,
		lng: -85.82128382,
		pop: 78381.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Indiana',
		state_ansi: 'IN',
		timezone: 'America/Kentucky/Louisville',
	},
	{
		city: 'Elkhart',
		city_ascii: 'Elkhart',
		lat: 41.68294537,
		lng: -85.96879419,
		pop: 100295,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Indiana',
		state_ansi: 'IN',
		timezone: 'America/Indiana/Indianapolis',
	},
	{
		city: 'Hopkinsville',
		city_ascii: 'Hopkinsville',
		lat: 36.86548749,
		lng: -87.4886239,
		pop: 31630,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kentucky',
		state_ansi: 'KY',
		timezone: 'America/Chicago',
	},
	{
		city: 'London',
		city_ascii: 'London',
		lat: 37.12888226,
		lng: -84.08335372,
		pop: 7844,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kentucky',
		state_ansi: 'KY',
		timezone: 'America/New_York',
	},
	{
		city: 'Madisonville',
		city_ascii: 'Madisonville',
		lat: 37.33274579,
		lng: -87.5022148,
		pop: 20858.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kentucky',
		state_ansi: 'KY',
		timezone: 'America/Chicago',
	},
	{
		city: 'Rocky Mount',
		city_ascii: 'Rocky Mount',
		lat: 35.93799888,
		lng: -77.79076624,
		pop: 57179,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Salisbury',
		city_ascii: 'Salisbury',
		lat: 35.67078005,
		lng: -80.4744784,
		pop: 33907,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Durham',
		city_ascii: 'Durham',
		lat: 35.99995892,
		lng: -78.91999964,
		pop: 257114.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Lumberton',
		city_ascii: 'Lumberton',
		lat: 34.62720034,
		lng: -79.01190617,
		pop: 27049.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Zanesville',
		city_ascii: 'Zanesville',
		lat: 39.94028688,
		lng: -82.01332503,
		pop: 32514,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Ohio',
		state_ansi: 'OH',
		timezone: 'America/New_York',
	},
	{
		city: 'Mansfield',
		city_ascii: 'Mansfield',
		lat: 40.75832481,
		lng: -82.51554244,
		pop: 64039,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Ohio',
		state_ansi: 'OH',
		timezone: 'America/New_York',
	},
	{
		city: 'Bowling Green',
		city_ascii: 'Bowling Green',
		lat: 41.37474713,
		lng: -83.65139042,
		pop: 33147.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Ohio',
		state_ansi: 'OH',
		timezone: 'America/New_York',
	},
	{
		city: 'Springfield',
		city_ascii: 'Springfield',
		lat: 39.92000388,
		lng: -83.799986,
		pop: 74450.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Ohio',
		state_ansi: 'OH',
		timezone: 'America/New_York',
	},
	{
		city: 'Lancaster',
		city_ascii: 'Lancaster',
		lat: 39.71921511,
		lng: -82.6053044,
		pop: 42356,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Ohio',
		state_ansi: 'OH',
		timezone: 'America/New_York',
	},
	{
		city: 'Johnson City',
		city_ascii: 'Johnson City',
		lat: 36.31332481,
		lng: -82.35361434,
		pop: 68070.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Tennessee',
		state_ansi: 'TN',
		timezone: 'America/New_York',
	},
	{
		city: 'Kingsport',
		city_ascii: 'Kingsport',
		lat: 36.54832338,
		lng: -82.56194788,
		pop: 50709.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Tennessee',
		state_ansi: 'TN',
		timezone: 'America/New_York',
	},
	{
		city: 'Columbia',
		city_ascii: 'Columbia',
		lat: 35.61499534,
		lng: -87.03526656,
		pop: 74866,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Tennessee',
		state_ansi: 'TN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Barlett',
		city_ascii: 'Barlett',
		lat: 35.22290041,
		lng: -89.84109013,
		pop: 164843.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Tennessee',
		state_ansi: 'TN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Blacksburg',
		city_ascii: 'Blacksburg',
		lat: 37.22941876,
		lng: -80.41419784,
		pop: 53845.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Virginia',
		state_ansi: 'VA',
		timezone: 'America/New_York',
	},
	{
		city: 'Harrisonburg',
		city_ascii: 'Harrisonburg',
		lat: 38.44942181,
		lng: -78.86917586,
		pop: 42131.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Virginia',
		state_ansi: 'VA',
		timezone: 'America/New_York',
	},
	{
		city: 'Petersburg',
		city_ascii: 'Petersburg',
		lat: 37.22776512,
		lng: -77.40223698,
		pop: 76158.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Virginia',
		state_ansi: 'VA',
		timezone: 'America/New_York',
	},
	{
		city: 'Hampton',
		city_ascii: 'Hampton',
		lat: 37.03002525,
		lng: -76.34994979,
		pop: 256601.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Virginia',
		state_ansi: 'VA',
		timezone: 'America/New_York',
	},
	{
		city: 'Sheboygan',
		city_ascii: 'Sheboygan',
		lat: 43.75082949,
		lng: -87.71442407,
		pop: 51148,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Waukesha',
		city_ascii: 'Waukesha',
		lat: 43.0116498,
		lng: -88.23136926,
		pop: 159012,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'La Crosse',
		city_ascii: 'La Crosse',
		lat: 43.80136904,
		lng: -91.23942855,
		pop: 69599.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Eau Claire',
		city_ascii: 'Eau Claire',
		lat: 44.81135907,
		lng: -91.49835331,
		pop: 71296,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Tomah',
		city_ascii: 'Tomah',
		lat: 43.98505292,
		lng: -90.50389205,
		pop: 10796.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Janesville',
		city_ascii: 'Janesville',
		lat: 42.68262596,
		lng: -89.02157943,
		pop: 65476.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Appleton',
		city_ascii: 'Appleton',
		lat: 44.26867902,
		lng: -88.40050623,
		pop: 136888.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Parkersburg',
		city_ascii: 'Parkersburg',
		lat: 39.26665875,
		lng: -81.56164718,
		pop: 46749.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'West Virginia',
		state_ansi: 'WV',
		timezone: 'America/New_York',
	},
	{
		city: 'White Sulphur Springs',
		city_ascii: 'White Sulphur Springs',
		lat: 37.79388043,
		lng: -80.30348108,
		pop: 2154,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'West Virginia',
		state_ansi: 'WV',
		timezone: 'America/New_York',
	},
	{
		city: 'Clarksburg',
		city_ascii: 'Clarksburg',
		lat: 39.28327272,
		lng: -80.33691573,
		pop: 22502.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'West Virginia',
		state_ansi: 'WV',
		timezone: 'America/New_York',
	},
	{
		city: 'Dover',
		city_ascii: 'Dover',
		lat: 39.15808657,
		lng: -75.524703,
		pop: 54662.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Delaware',
		state_ansi: 'DE',
		timezone: 'America/New_York',
	},
	{
		city: 'St. Charles',
		city_ascii: 'St. Charles',
		lat: 38.60305585,
		lng: -76.93893193,
		pop: 52792,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Maryland',
		state_ansi: 'MD',
		timezone: 'America/New_York',
	},
	{
		city: 'Annapolis',
		city_ascii: 'Annapolis',
		lat: 38.9783301,
		lng: -76.49249923,
		pop: 58776,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Maryland',
		state_ansi: 'MD',
		timezone: 'America/New_York',
	},
	{
		city: 'Hagerstown',
		city_ascii: 'Hagerstown',
		lat: 39.64164878,
		lng: -77.72027958,
		pop: 58487.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Maryland',
		state_ansi: 'MD',
		timezone: 'America/New_York',
	},
	{
		city: 'Paterson',
		city_ascii: 'Paterson',
		lat: 40.91999453,
		lng: -74.17000533,
		pop: 151205,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Jersey',
		state_ansi: 'NJ',
		timezone: 'America/New_York',
	},
	{
		city: 'Saratoga Springs',
		city_ascii: 'Saratoga Springs',
		lat: 43.08296328,
		lng: -73.78501591,
		pop: 41891,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'Poughkeepsie',
		city_ascii: 'Poughkeepsie',
		lat: 41.70023114,
		lng: -73.92141585,
		pop: 100670.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'Plattsburg',
		city_ascii: 'Plattsburg',
		lat: 44.69498374,
		lng: -73.45798161,
		pop: 24233.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'Beaver Falls',
		city_ascii: 'Beaver Falls',
		lat: 40.75194277,
		lng: -80.31942326,
		pop: 64814.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Pennsylvania',
		state_ansi: 'PA',
		timezone: 'America/New_York',
	},
	{
		city: 'Altoona',
		city_ascii: 'Altoona',
		lat: 40.51859784,
		lng: -78.39496708,
		pop: 62784.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Pennsylvania',
		state_ansi: 'PA',
		timezone: 'America/New_York',
	},
	{
		city: 'Williamsport',
		city_ascii: 'Williamsport',
		lat: 41.24108604,
		lng: -77.0013829,
		pop: 43791.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Pennsylvania',
		state_ansi: 'PA',
		timezone: 'America/New_York',
	},
	{
		city: 'Lancaster',
		city_ascii: 'Lancaster',
		lat: 40.03777447,
		lng: -76.30576644,
		pop: 209489,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Pennsylvania',
		state_ansi: 'PA',
		timezone: 'America/New_York',
	},
	{
		city: 'Allentown',
		city_ascii: 'Allentown',
		lat: 40.59998822,
		lng: -75.50002751,
		pop: 300980.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Pennsylvania',
		state_ansi: 'PA',
		timezone: 'America/New_York',
	},
	{
		city: 'Waterville',
		city_ascii: 'Waterville',
		lat: 44.5518917,
		lng: -69.64578536,
		pop: 20529,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Maine',
		state_ansi: 'ME',
		timezone: 'America/New_York',
	},
	{
		city: 'Calais',
		city_ascii: 'Calais',
		lat: 45.16598859,
		lng: -67.24239201,
		pop: 1781.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Maine',
		state_ansi: 'ME',
		timezone: 'America/New_York',
	},
	{
		city: 'Houlton',
		city_ascii: 'Houlton',
		lat: 46.12551658,
		lng: -67.83971989,
		pop: 6051.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Maine',
		state_ansi: 'ME',
		timezone: 'America/New_York',
	},
	{
		city: 'Benton Harbor',
		city_ascii: 'Benton Harbor',
		lat: 42.11663983,
		lng: -86.45419092,
		pop: 34637.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Battle Creek',
		city_ascii: 'Battle Creek',
		lat: 42.32109764,
		lng: -85.17974675,
		pop: 62454,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Bay City',
		city_ascii: 'Bay City',
		lat: 43.5944566,
		lng: -83.88889531,
		pop: 51558.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Alpena',
		city_ascii: 'Alpena',
		lat: 45.06160219,
		lng: -83.43269576,
		pop: 14524,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Iron Mountain',
		city_ascii: 'Iron Mountain',
		lat: 45.82246014,
		lng: -88.06409265,
		pop: 12011,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Menominee',
	},
	{
		city: 'Ironwood',
		city_ascii: 'Ironwood',
		lat: 46.4558065,
		lng: -90.15939112,
		pop: 6400,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Menominee',
	},
	{
		city: 'Sand Point',
		city_ascii: 'Sand Point',
		lat: 55.33970868,
		lng: -160.4971908,
		pop: 667,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Hydaburg',
		city_ascii: 'Hydaburg',
		lat: 55.21397992,
		lng: -132.8006385,
		pop: 382,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Sitka',
	},
	{
		city: 'Mekoryuk',
		city_ascii: 'Mekoryuk',
		lat: 60.38864671,
		lng: -166.1899372,
		pop: 99,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Atqasuk',
		city_ascii: 'Atqasuk',
		lat: 70.4693795,
		lng: -157.395778,
		pop: 201,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Port Heiden',
		city_ascii: 'Port Heiden',
		lat: 56.94909365,
		lng: -158.6268915,
		pop: 102,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Perryville',
		city_ascii: 'Perryville',
		lat: 55.91861391,
		lng: -159.1511489,
		pop: 113,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Dillingham',
		city_ascii: 'Dillingham',
		lat: 59.05656031,
		lng: -158.4803121,
		pop: 1710,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Goodnews Bay',
		city_ascii: 'Goodnews Bay',
		lat: 59.12099265,
		lng: -161.5871302,
		pop: 230,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Nyac',
		city_ascii: 'Nyac',
		lat: 61.00414329,
		lng: -159.9404806,
		pop: 75,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Tununak',
		city_ascii: 'Tununak',
		lat: 60.58548667,
		lng: -165.2557892,
		pop: 352,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Mountain Village',
		city_ascii: 'Mountain Village',
		lat: 62.08552431,
		lng: -163.729009,
		pop: 755,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Emmonak',
		city_ascii: 'Emmonak',
		lat: 62.77698081,
		lng: -164.5229916,
		pop: 100,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Kaltag',
		city_ascii: 'Kaltag',
		lat: 64.32719627,
		lng: -158.7218986,
		pop: 190,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Teller',
		city_ascii: 'Teller',
		lat: 65.26359906,
		lng: -166.3607864,
		pop: 83,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Koyukuk',
		city_ascii: 'Koyukuk',
		lat: 64.88028912,
		lng: -157.7008499,
		pop: 101,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Kobuk',
		city_ascii: 'Kobuk',
		lat: 66.9072455,
		lng: -156.8809774,
		pop: 151,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Selawik',
		city_ascii: 'Selawik',
		lat: 66.60387901,
		lng: -160.0093911,
		pop: 832,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Talkeetna',
		city_ascii: 'Talkeetna',
		lat: 62.3237785,
		lng: -150.1094269,
		pop: 1078,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Whittier',
		city_ascii: 'Whittier',
		lat: 60.78415672,
		lng: -148.6776797,
		pop: 177,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Montana',
		city_ascii: 'Montana',
		lat: 62.07968487,
		lng: -150.0727625,
		pop: 10,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Lake Minchumina',
		city_ascii: 'Lake Minchumina',
		lat: 63.88283063,
		lng: -152.3121865,
		pop: 32,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Cantwell',
		city_ascii: 'Cantwell',
		lat: 63.39159446,
		lng: -148.9507896,
		pop: 222,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Gulkana',
		city_ascii: 'Gulkana',
		lat: 62.27135276,
		lng: -145.3821961,
		pop: 119,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Eagle',
		city_ascii: 'Eagle',
		lat: 64.78799501,
		lng: -141.1999966,
		pop: 104,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Nenana',
		city_ascii: 'Nenana',
		lat: 64.56379681,
		lng: -149.0930032,
		pop: 75,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Big Delta',
		city_ascii: 'Big Delta',
		lat: 64.15252993,
		lng: -145.8421939,
		pop: 591,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Allakaket',
		city_ascii: 'Allakaket',
		lat: 66.56548342,
		lng: -152.6454995,
		pop: 97,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Tanana',
		city_ascii: 'Tanana',
		lat: 65.17187339,
		lng: -152.0787899,
		pop: 291.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Virginia',
		city_ascii: 'Virginia',
		lat: 47.52367413,
		lng: -92.53640365,
		pop: 8709,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Winona',
		city_ascii: 'Winona',
		lat: 44.0504236,
		lng: -91.63919743,
		pop: 29757.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Rochester',
		city_ascii: 'Rochester',
		lat: 44.02205324,
		lng: -92.46968937,
		pop: 102433,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Lakeville',
		city_ascii: 'Lakeville',
		lat: 44.65010276,
		lng: -93.24251042,
		pop: 156151,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Ely',
		city_ascii: 'Ely',
		lat: 47.90042116,
		lng: -91.82569767,
		pop: 3687,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Moorhead',
		city_ascii: 'Moorhead',
		lat: 46.87430808,
		lng: -96.74219344,
		pop: 34332.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'St. Cloud',
		city_ascii: 'St. Cloud',
		lat: 45.56120994,
		lng: -94.16222172,
		pop: 85974,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Miles City',
		city_ascii: 'Miles City',
		lat: 46.4088843,
		lng: -105.8399844,
		pop: 8399.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Montana',
		state_ansi: 'MT',
		timezone: 'America/Denver',
	},
	{
		city: 'Bozeman',
		city_ascii: 'Bozeman',
		lat: 45.68009157,
		lng: -111.0378325,
		pop: 39049.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Montana',
		state_ansi: 'MT',
		timezone: 'America/Denver',
	},
	{
		city: 'Glasgow',
		city_ascii: 'Glasgow',
		lat: 48.18396975,
		lng: -106.6352588,
		pop: 3144,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Montana',
		state_ansi: 'MT',
		timezone: 'America/Denver',
	},
	{
		city: 'Dickinson',
		city_ascii: 'Dickinson',
		lat: 46.88399742,
		lng: -102.7888011,
		pop: 15987.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Dakota',
		state_ansi: 'ND',
		timezone: 'America/Denver',
	},
	{
		city: 'Jamestown',
		city_ascii: 'Jamestown',
		lat: 46.90601158,
		lng: -98.70297815,
		pop: 14954.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Dakota',
		state_ansi: 'ND',
		timezone: 'America/Chicago',
	},
	{
		city: 'Williston',
		city_ascii: 'Williston',
		lat: 48.15678794,
		lng: -103.6280005,
		pop: 12767.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Dakota',
		state_ansi: 'ND',
		timezone: 'America/Chicago',
	},
	{
		city: 'Lihue',
		city_ascii: 'Lihue',
		lat: 21.98151227,
		lng: -159.3710063,
		pop: 10694.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Hawaii',
		state_ansi: 'HI',
		timezone: 'Pacific/Honolulu',
	},
	{
		city: 'Wahiawa',
		city_ascii: 'Wahiawa',
		lat: 21.50309186,
		lng: -158.0236209,
		pop: 95336,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Hawaii',
		state_ansi: 'HI',
		timezone: 'Pacific/Honolulu',
	},
	{
		city: 'Wailuku',
		city_ascii: 'Wailuku',
		lat: 20.89147544,
		lng: -156.5047213,
		pop: 32769.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Hawaii',
		state_ansi: 'HI',
		timezone: 'Pacific/Honolulu',
	},
	{
		city: 'Montpelier',
		city_ascii: 'Montpelier',
		lat: 42.32262209,
		lng: -111.2969123,
		pop: 2775.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Idaho',
		state_ansi: 'ID',
		timezone: 'America/Boise',
	},
	{
		city: 'Twin Falls',
		city_ascii: 'Twin Falls',
		lat: 42.5609538,
		lng: -114.4605693,
		pop: 42958.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Idaho',
		state_ansi: 'ID',
		timezone: 'America/Boise',
	},
	{
		city: 'Caldwell',
		city_ascii: 'Caldwell',
		lat: 43.66096417,
		lng: -116.6705378,
		pop: 83403,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Idaho',
		state_ansi: 'ID',
		timezone: 'America/Boise',
	},
	{
		city: 'Salmon',
		city_ascii: 'Salmon',
		lat: 45.17567792,
		lng: -113.8949966,
		pop: 3297,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Idaho',
		state_ansi: 'ID',
		timezone: 'America/Boise',
	},
	{
		city: "Coeur d'Alene",
		city_ascii: "Coeur d'Alene",
		lat: 47.67808331,
		lng: -116.7794458,
		pop: 34514,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Idaho',
		state_ansi: 'ID',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Richland',
		city_ascii: 'Richland',
		lat: 46.29181134,
		lng: -119.2911013,
		pop: 39940.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Bellingham',
		city_ascii: 'Bellingham',
		lat: 48.76013613,
		lng: -122.4869269,
		pop: 86565.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Longview',
		city_ascii: 'Longview',
		lat: 46.13871991,
		lng: -122.9369511,
		pop: 51290,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Walla Walla',
		city_ascii: 'Walla Walla',
		lat: 46.06515851,
		lng: -118.3418828,
		pop: 37864,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Aberdeen',
		city_ascii: 'Aberdeen',
		lat: 46.97489626,
		lng: -123.8143911,
		pop: 24400,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Bremerton',
		city_ascii: 'Bremerton',
		lat: 47.57359552,
		lng: -122.6420175,
		pop: 82039.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Everett',
		city_ascii: 'Everett',
		lat: 47.9604175,
		lng: -122.1999677,
		pop: 291948,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Bullhead City',
		city_ascii: 'Bullhead City',
		lat: 35.14817629,
		lng: -114.5674878,
		pop: 37989,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Winslow',
		city_ascii: 'Winslow',
		lat: 35.28470542,
		lng: -110.7006954,
		pop: 9931,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Denver',
	},
	{
		city: 'Gila Bend',
		city_ascii: 'Gila Bend',
		lat: 32.95037762,
		lng: -112.7246546,
		pop: 2012,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Tombstone',
		city_ascii: 'Tombstone',
		lat: 31.71314048,
		lng: -110.066884,
		pop: 1396.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Willcox',
		city_ascii: 'Willcox',
		lat: 32.25321088,
		lng: -109.8313945,
		pop: 4451.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Scottsdale',
		city_ascii: 'Scottsdale',
		lat: 33.69234784,
		lng: -111.8680402,
		pop: 15401,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Kingman',
		city_ascii: 'Kingman',
		lat: 35.18987917,
		lng: -114.0522221,
		pop: 33306.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Grand Canyon',
		city_ascii: 'Grand Canyon',
		lat: 36.05478762,
		lng: -112.1385922,
		pop: 1068.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Arcata',
		city_ascii: 'Arcata',
		lat: 40.88519045,
		lng: -124.0882245,
		pop: 19052,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Stockton',
		city_ascii: 'Stockton',
		lat: 37.95813397,
		lng: -121.289739,
		pop: 488506.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Barstow',
		city_ascii: 'Barstow',
		lat: 34.89901837,
		lng: -117.0218858,
		pop: 21119,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Victorville',
		city_ascii: 'Victoriaville',
		lat: 34.5365082,
		lng: -117.2903191,
		pop: 83496,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Pasadena',
		city_ascii: 'Pasadena',
		lat: 34.16038129,
		lng: -118.1388719,
		pop: 144618,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Visalia',
		city_ascii: 'Visalia',
		lat: 36.32502952,
		lng: -119.3160094,
		pop: 114699.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'El Centro',
		city_ascii: 'El Centro',
		lat: 32.79237693,
		lng: -115.5580475,
		pop: 41661.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'San Luis Obispo',
		city_ascii: 'San Luis Obispo',
		lat: 35.28318097,
		lng: -120.6585889,
		pop: 54759,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Merced',
		city_ascii: 'Merced',
		lat: 37.30261843,
		lng: -120.481933,
		pop: 84355,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Yuba City',
		city_ascii: 'Yuba City',
		lat: 39.14103334,
		lng: -121.6157656,
		pop: 84324.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Redding',
		city_ascii: 'Redding',
		lat: 40.58704327,
		lng: -122.3905762,
		pop: 93871.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Santa Rosa',
		city_ascii: 'Santa Rosa',
		lat: 38.45040367,
		lng: -122.6999889,
		pop: 193455,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Oceanside',
		city_ascii: 'Oceanside',
		lat: 33.2204645,
		lng: -117.3349675,
		pop: 396474.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Modesto',
		city_ascii: 'Modesto',
		lat: 37.65541343,
		lng: -120.9899899,
		pop: 269697,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Irvine',
		city_ascii: 'Irvine',
		lat: 33.68041058,
		lng: -117.8299502,
		pop: 1611303.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Ukiah',
		city_ascii: 'Ukiah',
		lat: 39.15423667,
		lng: -123.2108621,
		pop: 21826.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Needles',
		city_ascii: 'Needles',
		lat: 34.84842714,
		lng: -114.6133507,
		pop: 6246.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Bishop',
		city_ascii: 'Bishop',
		lat: 37.36395835,
		lng: -118.394076,
		pop: 4249,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Palm Springs',
		city_ascii: 'Palm Springs',
		lat: 33.77735557,
		lng: -116.5330526,
		pop: 216461,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Santa Maria',
		city_ascii: 'Santa Maria',
		lat: 34.94012697,
		lng: -120.4366386,
		pop: 98092.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Tulare',
		city_ascii: 'Tulare',
		lat: 36.20702639,
		lng: -119.3441213,
		pop: 53005.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Mt. Shasta',
		city_ascii: 'Mt. Shasta',
		lat: 41.3103583,
		lng: -122.3093925,
		pop: 3742.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Crescent City',
		city_ascii: 'Crescent City',
		lat: 41.7564551,
		lng: -124.2004916,
		pop: 9439.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Fort Collins',
		city_ascii: 'Fort Collins',
		lat: 40.56068829,
		lng: -105.0588693,
		pop: 178818.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Colorado',
		state_ansi: 'CO',
		timezone: 'America/Denver',
	},
	{
		city: 'Pueblo',
		city_ascii: 'Pueblo',
		lat: 38.2803882,
		lng: -104.6300066,
		pop: 108244,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Colorado',
		state_ansi: 'CO',
		timezone: 'America/Denver',
	},
	{
		city: 'Lamar',
		city_ascii: 'Lamar',
		lat: 38.08649823,
		lng: -102.6194058,
		pop: 8567,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Colorado',
		state_ansi: 'CO',
		timezone: 'America/Denver',
	},
	{
		city: 'Trinidad',
		city_ascii: 'Trinidad',
		lat: 37.17133445,
		lng: -104.5063965,
		pop: 8701.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Colorado',
		state_ansi: 'CO',
		timezone: 'America/Denver',
	},
	{
		city: 'Gunnison',
		city_ascii: 'Gunnison',
		lat: 38.54476483,
		lng: -106.92829,
		pop: 6273,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Colorado',
		state_ansi: 'CO',
		timezone: 'America/Denver',
	},
	{
		city: 'Durango',
		city_ascii: 'Durango',
		lat: 37.27564333,
		lng: -107.8799891,
		pop: 19127.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Colorado',
		state_ansi: 'CO',
		timezone: 'America/Denver',
	},
	{
		city: 'Montrose',
		city_ascii: 'Montrose',
		lat: 38.47727541,
		lng: -107.8655197,
		pop: 18463.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Colorado',
		state_ansi: 'CO',
		timezone: 'America/Denver',
	},
	{
		city: 'Craig',
		city_ascii: 'Craig',
		lat: 40.51728009,
		lng: -107.5503968,
		pop: 9315.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Colorado',
		state_ansi: 'CO',
		timezone: 'America/Denver',
	},
	{
		city: 'Boulder',
		city_ascii: 'Boulder',
		lat: 40.03844627,
		lng: -105.246093,
		pop: 106897.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Colorado',
		state_ansi: 'CO',
		timezone: 'America/Denver',
	},
	{
		city: 'Boulder City',
		city_ascii: 'Boulder City',
		lat: 35.97895245,
		lng: -114.8315802,
		pop: 15072.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nevada',
		state_ansi: 'NV',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Winnemucca',
		city_ascii: 'Winnemucca',
		lat: 40.97337628,
		lng: -117.7346847,
		pop: 7997.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nevada',
		state_ansi: 'NV',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Roswell',
		city_ascii: 'Roswell',
		lat: 33.39453656,
		lng: -104.5224679,
		pop: 45082.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Clovis',
		city_ascii: 'Clovis',
		lat: 34.40506919,
		lng: -103.2047706,
		pop: 33477.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Las Cruces',
		city_ascii: 'Las Cruces',
		lat: 32.31261293,
		lng: -106.7778083,
		pop: 97675.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Hobbs',
		city_ascii: 'Hobbs',
		lat: 32.71261436,
		lng: -103.1406143,
		pop: 28375.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Socorro',
		city_ascii: 'Socorro',
		lat: 34.06211855,
		lng: -106.8989895,
		pop: 8117,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Gallup',
		city_ascii: 'Gallup',
		lat: 35.52407066,
		lng: -108.7339938,
		pop: 21627,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Raton',
		city_ascii: 'Raton',
		lat: 36.89739768,
		lng: -104.439889,
		pop: 6820,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Tucumcari',
		city_ascii: 'Tucumcari',
		lat: 35.16980288,
		lng: -103.725514,
		pop: 5259.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Roseburg',
		city_ascii: 'Roseburg',
		lat: 43.21843304,
		lng: -123.3560987,
		pop: 25454.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Pendleton',
		city_ascii: 'Pendleton',
		lat: 45.67259849,
		lng: -118.7874886,
		pop: 16669,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'John Day',
		city_ascii: 'John Day',
		lat: 44.41652529,
		lng: -118.9520264,
		pop: 1437.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Grants Pass',
		city_ascii: 'Grants Pass',
		lat: 42.43954002,
		lng: -123.3271857,
		pop: 36690,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Corvallis',
		city_ascii: 'Corvallis',
		lat: 44.57235557,
		lng: -123.2799793,
		pop: 54865.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Albany',
		city_ascii: 'Albany',
		lat: 44.62049217,
		lng: -123.086942,
		pop: 48066.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Astoria',
		city_ascii: 'Astoria',
		lat: 46.18838096,
		lng: -123.8299974,
		pop: 9773,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Logan',
		city_ascii: 'Logan',
		lat: 41.73593955,
		lng: -111.8335979,
		pop: 58664,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Utah',
		state_ansi: 'UT',
		timezone: 'America/Denver',
	},
	{
		city: 'Parowan',
		city_ascii: 'Parowan',
		lat: 37.84253379,
		lng: -112.8272065,
		pop: 2533,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Utah',
		state_ansi: 'UT',
		timezone: 'America/Denver',
	},
	{
		city: 'Kanab',
		city_ascii: 'Kanab',
		lat: 37.04738853,
		lng: -112.5254936,
		pop: 2861,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Utah',
		state_ansi: 'UT',
		timezone: 'America/Denver',
	},
	{
		city: 'Monticello',
		city_ascii: 'Monticello',
		lat: 37.87178265,
		lng: -109.3421995,
		pop: 1811.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Utah',
		state_ansi: 'UT',
		timezone: 'America/Denver',
	},
	{
		city: 'Moab',
		city_ascii: 'Moab',
		lat: 38.57370363,
		lng: -109.5491895,
		pop: 5309,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Utah',
		state_ansi: 'UT',
		timezone: 'America/Denver',
	},
	{
		city: 'Price',
		city_ascii: 'Price',
		lat: 39.59979087,
		lng: -110.8100169,
		pop: 9175,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Utah',
		state_ansi: 'UT',
		timezone: 'America/Denver',
	},
	{
		city: 'Cedar City',
		city_ascii: 'Cedar City',
		lat: 37.67742759,
		lng: -113.061094,
		pop: 25371.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Utah',
		state_ansi: 'UT',
		timezone: 'America/Denver',
	},
	{
		city: 'Vernal',
		city_ascii: 'Vernal',
		lat: 40.45539756,
		lng: -109.5280022,
		pop: 11175.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Utah',
		state_ansi: 'UT',
		timezone: 'America/Denver',
	},
	{
		city: 'Ogden',
		city_ascii: 'Ogden',
		lat: 41.23237856,
		lng: -111.9680341,
		pop: 227774,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Utah',
		state_ansi: 'UT',
		timezone: 'America/Denver',
	},
	{
		city: 'Green River',
		city_ascii: 'Green River',
		lat: 41.51455772,
		lng: -109.4649827,
		pop: 10239.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wyoming',
		state_ansi: 'WY',
		timezone: 'America/Denver',
	},
	{
		city: 'Rawlins',
		city_ascii: 'Rawlins',
		lat: 41.7906649,
		lng: -107.234292,
		pop: 8458,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wyoming',
		state_ansi: 'WY',
		timezone: 'America/Denver',
	},
	{
		city: 'Douglas',
		city_ascii: 'Douglas',
		lat: 42.75647158,
		lng: -105.3845341,
		pop: 5838,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wyoming',
		state_ansi: 'WY',
		timezone: 'America/Denver',
	},
	{
		city: 'Riverton',
		city_ascii: 'Riverton',
		lat: 43.02816042,
		lng: -108.3950481,
		pop: 10350,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wyoming',
		state_ansi: 'WY',
		timezone: 'America/Denver',
	},
	{
		city: 'Thermopolis',
		city_ascii: 'Thermopolis',
		lat: 43.64597801,
		lng: -108.2146715,
		pop: 3195,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wyoming',
		state_ansi: 'WY',
		timezone: 'America/Denver',
	},
	{
		city: 'Gillette',
		city_ascii: 'Gillette',
		lat: 44.28317425,
		lng: -105.5052503,
		pop: 26107,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wyoming',
		state_ansi: 'WY',
		timezone: 'America/Denver',
	},
	{
		city: 'Jonesboro',
		city_ascii: 'Jonesboro',
		lat: 35.84257835,
		lng: -90.70416406,
		pop: 58322,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arkansas',
		state_ansi: 'AR',
		timezone: 'America/Chicago',
	},
	{
		city: 'Texarkana',
		city_ascii: 'Texarkana',
		lat: 33.44210472,
		lng: -94.03747481,
		pop: 52169,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arkansas',
		state_ansi: 'AR',
		timezone: 'America/Chicago',
	},
	{
		city: 'Pine Bluff',
		city_ascii: 'Pine Bluff',
		lat: 34.22869753,
		lng: -92.00305119,
		pop: 51472,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arkansas',
		state_ansi: 'AR',
		timezone: 'America/Chicago',
	},
	{
		city: 'Hot Springs',
		city_ascii: 'Hot Springs',
		lat: 34.50395205,
		lng: -93.05500248,
		pop: 40826,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arkansas',
		state_ansi: 'AR',
		timezone: 'America/Chicago',
	},
	{
		city: 'Fort Smith',
		city_ascii: 'Fort Smith',
		lat: 35.38622377,
		lng: -94.39835718,
		pop: 87986.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arkansas',
		state_ansi: 'AR',
		timezone: 'America/Chicago',
	},
	{
		city: 'Fayetteville',
		city_ascii: 'Fayetteville',
		lat: 36.06297833,
		lng: -94.15720911,
		pop: 108267.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arkansas',
		state_ansi: 'AR',
		timezone: 'America/Chicago',
	},
	{
		city: 'Conway',
		city_ascii: 'Conway',
		lat: 35.09128054,
		lng: -92.4513184,
		pop: 56759.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arkansas',
		state_ansi: 'AR',
		timezone: 'America/Chicago',
	},
	{
		city: 'El Dorado',
		city_ascii: 'El Dorado',
		lat: 33.21392743,
		lng: -92.66251998,
		pop: 21384.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arkansas',
		state_ansi: 'AR',
		timezone: 'America/Chicago',
	},
	{
		city: 'Davenport',
		city_ascii: 'Davenport',
		lat: 41.55398684,
		lng: -90.58753036,
		pop: 178282.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Iowa',
		state_ansi: 'IA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Burlington',
		city_ascii: 'Burlington',
		lat: 40.80793418,
		lng: -91.11276961,
		pop: 27690.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Iowa',
		state_ansi: 'IA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Dubuque',
		city_ascii: 'Dubuque',
		lat: 42.50093162,
		lng: -90.66445073,
		pop: 59834,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Iowa',
		state_ansi: 'IA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Waterloo',
		city_ascii: 'Waterloo',
		lat: 42.49315432,
		lng: -92.34279789,
		pop: 82091.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Iowa',
		state_ansi: 'IA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Sioux City',
		city_ascii: 'Sioux City',
		lat: 42.50038902,
		lng: -96.39999211,
		pop: 87090,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Iowa',
		state_ansi: 'IA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Council Bluffs',
		city_ascii: 'Council Bluffs',
		lat: 41.26227338,
		lng: -95.86080021,
		pop: 80284.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Iowa',
		state_ansi: 'IA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Ames',
		city_ascii: 'Ames',
		lat: 42.05385297,
		lng: -93.61972254,
		pop: 56855,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Iowa',
		state_ansi: 'IA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Mason City',
		city_ascii: 'Mason City',
		lat: 43.15401837,
		lng: -93.20083338,
		pop: 27327,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Iowa',
		state_ansi: 'IA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Emporia',
		city_ascii: 'Emporia',
		lat: 38.40423077,
		lng: -96.18137496,
		pop: 27796.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kansas',
		state_ansi: 'KS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Salina',
		city_ascii: 'Salina',
		lat: 38.82467023,
		lng: -97.6071794,
		pop: 46877,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kansas',
		state_ansi: 'KS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Dodge City',
		city_ascii: 'Dodge City',
		lat: 37.76005821,
		lng: -100.018195,
		pop: 25237.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kansas',
		state_ansi: 'KS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Coffeyville',
		city_ascii: 'Coffeyville',
		lat: 37.03806093,
		lng: -95.6263184,
		pop: 10955,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kansas',
		state_ansi: 'KS',
		timezone: 'America/Chicago',
	},
	{
		city: 'St. Charles',
		city_ascii: 'St. Charles',
		lat: 38.78428509,
		lng: -90.50616581,
		pop: 213139.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Missouri',
		state_ansi: 'MO',
		timezone: 'America/Chicago',
	},
	{
		city: 'Poplar Bluff',
		city_ascii: 'Poplar Bluff',
		lat: 36.76019676,
		lng: -90.40268376,
		pop: 18575,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Missouri',
		state_ansi: 'MO',
		timezone: 'America/Chicago',
	},
	{
		city: 'Joplin',
		city_ascii: 'Joplin',
		lat: 37.08459556,
		lng: -94.51307886,
		pop: 60290.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Missouri',
		state_ansi: 'MO',
		timezone: 'America/Chicago',
	},
	{
		city: 'Columbia',
		city_ascii: 'Columbia',
		lat: 38.95207847,
		lng: -92.33390955,
		pop: 244754,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Missouri',
		state_ansi: 'MO',
		timezone: 'America/Chicago',
	},
	{
		city: 'St. Joseph',
		city_ascii: 'St. Joseph',
		lat: 39.76903119,
		lng: -94.84639185,
		pop: 74878.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Missouri',
		state_ansi: 'MO',
		timezone: 'America/Chicago',
	},
	{
		city: 'McCook',
		city_ascii: 'McCook',
		lat: 40.20559369,
		lng: -100.6261683,
		pop: 8003,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nebraska',
		state_ansi: 'NE',
		timezone: 'America/Chicago',
	},
	{
		city: 'Norfolk',
		city_ascii: 'Norfolk',
		lat: 42.02871238,
		lng: -97.43359827,
		pop: 24562,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nebraska',
		state_ansi: 'NE',
		timezone: 'America/Chicago',
	},
	{
		city: 'North Platte',
		city_ascii: 'North Platte',
		lat: 41.13628623,
		lng: -100.7705005,
		pop: 24709.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nebraska',
		state_ansi: 'NE',
		timezone: 'America/Chicago',
	},
	{
		city: 'Sidney',
		city_ascii: 'Sidney',
		lat: 41.13980023,
		lng: -102.9782727,
		pop: 6221.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nebraska',
		state_ansi: 'NE',
		timezone: 'America/Denver',
	},
	{
		city: 'Scottsbluff',
		city_ascii: 'Scottsbluff',
		lat: 41.86750775,
		lng: -103.6606859,
		pop: 20172,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nebraska',
		state_ansi: 'NE',
		timezone: 'America/Denver',
	},
	{
		city: 'Chadron',
		city_ascii: 'Chadron',
		lat: 42.82791424,
		lng: -103.0030774,
		pop: 5686.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nebraska',
		state_ansi: 'NE',
		timezone: 'America/Denver',
	},
	{
		city: 'Lawton',
		city_ascii: 'Lawton',
		lat: 34.59903668,
		lng: -98.40997278,
		pop: 85795.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oklahoma',
		state_ansi: 'OK',
		timezone: 'America/Chicago',
	},
	{
		city: 'Norman',
		city_ascii: 'Norman',
		lat: 35.22791302,
		lng: -97.34414636,
		pop: 113525,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oklahoma',
		state_ansi: 'OK',
		timezone: 'America/Chicago',
	},
	{
		city: 'Muskogee',
		city_ascii: 'Muskogee',
		lat: 35.74821718,
		lng: -95.36943486,
		pop: 38995.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oklahoma',
		state_ansi: 'OK',
		timezone: 'America/Chicago',
	},
	{
		city: 'Ponca City',
		city_ascii: 'Ponca City',
		lat: 36.7073576,
		lng: -97.08527328,
		pop: 24843,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oklahoma',
		state_ansi: 'OK',
		timezone: 'America/Chicago',
	},
	{
		city: 'Shawnee',
		city_ascii: 'Shawnee',
		lat: 35.34278973,
		lng: -96.93378382,
		pop: 29160,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oklahoma',
		state_ansi: 'OK',
		timezone: 'America/Chicago',
	},
	{
		city: 'Woodward',
		city_ascii: 'Woodward',
		lat: 36.43342084,
		lng: -99.39769027,
		pop: 12339.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oklahoma',
		state_ansi: 'OK',
		timezone: 'America/Chicago',
	},
	{
		city: 'Guymon',
		city_ascii: 'Guymon',
		lat: 36.68580853,
		lng: -101.4795012,
		pop: 10843.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oklahoma',
		state_ansi: 'OK',
		timezone: 'America/Chicago',
	},
	{
		city: 'Yankton',
		city_ascii: 'Yankton',
		lat: 42.88201947,
		lng: -97.39248967,
		pop: 14495,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Dakota',
		state_ansi: 'SD',
		timezone: 'America/Chicago',
	},
	{
		city: 'Brookings',
		city_ascii: 'Brookings',
		lat: 44.30676455,
		lng: -96.78803044,
		pop: 20313.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Dakota',
		state_ansi: 'SD',
		timezone: 'America/Chicago',
	},
	{
		city: 'Mitchell',
		city_ascii: 'Mitchell',
		lat: 43.71429425,
		lng: -98.02619776,
		pop: 14973,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Dakota',
		state_ansi: 'SD',
		timezone: 'America/Chicago',
	},
	{
		city: 'Aberdeen',
		city_ascii: 'Aberdeen',
		lat: 45.46511761,
		lng: -98.48640222,
		pop: 24854,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Dakota',
		state_ansi: 'SD',
		timezone: 'America/Chicago',
	},
	{
		city: 'Mobridge',
		city_ascii: 'Mobridge',
		lat: 45.54012596,
		lng: -100.4347071,
		pop: 3083.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Dakota',
		state_ansi: 'SD',
		timezone: 'America/Chicago',
	},
	{
		city: 'Houma',
		city_ascii: 'Houma',
		lat: 29.59593121,
		lng: -90.71948613,
		pop: 48196,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Louisiana',
		state_ansi: 'LA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Monroe',
		city_ascii: 'Monroe',
		lat: 32.50960349,
		lng: -92.11919397,
		pop: 76674.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Louisiana',
		state_ansi: 'LA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Conroe',
		city_ascii: 'Conroe',
		lat: 30.31206321,
		lng: -95.45586369,
		pop: 41643,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Nacogdoches',
		city_ascii: 'Nacogdoches',
		lat: 31.60374147,
		lng: -94.65526656,
		pop: 30691,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Eagle Pass',
		city_ascii: 'Eagle Pass',
		lat: 28.71102399,
		lng: -100.4892774,
		pop: 39683,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Edinburg',
		city_ascii: 'Edinburg',
		lat: 26.30318646,
		lng: -98.1599622,
		pop: 114573.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Kingsville',
		city_ascii: 'Kingsville',
		lat: 27.51595481,
		lng: -97.8558464,
		pop: 24560.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Port Arthur',
		city_ascii: 'Port Arthur',
		lat: 29.89898765,
		lng: -93.92859257,
		pop: 54972,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Huntsville',
		city_ascii: 'Huntsville',
		lat: 30.72376935,
		lng: -95.55058659,
		pop: 34444.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Killeen',
		city_ascii: 'Killeen',
		lat: 31.11728538,
		lng: -97.72748214,
		pop: 120464,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Lufkin',
		city_ascii: 'Lufkin',
		lat: 31.33843467,
		lng: -94.72887964,
		pop: 38465.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Del Rio',
		city_ascii: 'Del Rio',
		lat: 29.36294802,
		lng: -100.8963843,
		pop: 35803.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'San Angelo',
		city_ascii: 'San Angelo',
		lat: 31.4640084,
		lng: -100.4366966,
		pop: 87297.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Sherman',
		city_ascii: 'Sherman',
		lat: 33.63599469,
		lng: -96.60858403,
		pop: 38696,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Beaumont',
		city_ascii: 'Beaumont',
		lat: 30.08626304,
		lng: -94.10168278,
		pop: 107455.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Bay City',
		city_ascii: 'Bay City',
		lat: 28.98111086,
		lng: -95.96435978,
		pop: 17487,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Port Lavaca',
		city_ascii: 'Port Lavaca',
		lat: 28.61601687,
		lng: -96.62969385,
		pop: 10715.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Falfurrias',
		city_ascii: 'Falfurrias',
		lat: 27.22690269,
		lng: -98.14489852,
		pop: 5152.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Beeville',
		city_ascii: 'Beeville',
		lat: 28.40597801,
		lng: -97.75083989,
		pop: 11748,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Fort Stockton',
		city_ascii: 'Fort Stockton',
		lat: 30.89169191,
		lng: -102.8849968,
		pop: 7434,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Pecos',
		city_ascii: 'Pecos',
		lat: 31.41579429,
		lng: -103.4998947,
		pop: 7991,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Dumas',
		city_ascii: 'Dumas',
		lat: 35.86239626,
		lng: -101.9668875,
		pop: 13551.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Denton',
		city_ascii: 'Denton',
		lat: 33.21576194,
		lng: -97.12883651,
		pop: 138952.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Midland',
		city_ascii: 'Midland',
		lat: 32.030718,
		lng: -102.0974996,
		pop: 98141.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Temple',
		city_ascii: 'Temple',
		lat: 31.10209251,
		lng: -97.36300826,
		pop: 58432,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'New Haven',
		city_ascii: 'New Haven',
		lat: 41.33038291,
		lng: -72.90000533,
		pop: 707883,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Connecticut',
		state_ansi: 'CT',
		timezone: 'America/New_York',
	},
	{
		city: 'Lowell',
		city_ascii: 'Lowell',
		lat: 42.63368837,
		lng: -71.31669112,
		pop: 415074,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Massachusetts',
		state_ansi: 'MA',
		timezone: 'America/New_York',
	},
	{
		city: 'Worcester',
		city_ascii: 'Worcester',
		lat: 42.27042889,
		lng: -71.80002079,
		pop: 232290.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Massachusetts',
		state_ansi: 'MA',
		timezone: 'America/New_York',
	},
	{
		city: 'Manchester',
		city_ascii: 'Manchester',
		lat: 42.99599184,
		lng: -71.45528731,
		pop: 153221.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Hampshire',
		state_ansi: 'NH',
		timezone: 'America/New_York',
	},
	{
		city: 'Newport',
		city_ascii: 'Newport',
		lat: 41.49039899,
		lng: -71.31335799,
		pop: 35893,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Rhode Island',
		state_ansi: 'RI',
		timezone: 'America/New_York',
	},
	{
		city: 'Dothan',
		city_ascii: 'Dothan',
		lat: 31.22345461,
		lng: -85.39058659,
		pop: 61715,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alabama',
		state_ansi: 'AL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Tuscaloosa',
		city_ascii: 'Tuscaloosa',
		lat: 33.22511538,
		lng: -87.54417607,
		pop: 100594.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alabama',
		state_ansi: 'AL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Gadsden',
		city_ascii: 'Gadsden',
		lat: 34.01455039,
		lng: -86.00664718,
		pop: 39265,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alabama',
		state_ansi: 'AL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Enterprise',
		city_ascii: 'Enterprise',
		lat: 31.32781516,
		lng: -85.84399561,
		pop: 23388.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alabama',
		state_ansi: 'AL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Selma',
		city_ascii: 'Selma',
		lat: 32.40756838,
		lng: -87.0211589,
		pop: 19553,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alabama',
		state_ansi: 'AL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Coral Gables',
		city_ascii: 'Coral Gables',
		lat: 25.71541872,
		lng: -80.29107874,
		pop: 98700.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Cape Coral',
		city_ascii: 'Cape Coral',
		lat: 26.60290977,
		lng: -81.97968368,
		pop: 117387.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Naples',
		city_ascii: 'Naples',
		lat: 26.14205935,
		lng: -81.79499211,
		pop: 141902,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Fort Pierce',
		city_ascii: 'Fort Pierce',
		lat: 27.44678591,
		lng: -80.3258053,
		pop: 132984,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Kissimmee',
		city_ascii: 'Kissimmee',
		lat: 28.29205731,
		lng: -81.4077806,
		pop: 144589.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Titusville',
		city_ascii: 'Titusville',
		lat: 28.61234784,
		lng: -80.80779138,
		pop: 47505.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'St. Augustine',
		city_ascii: 'St. Augustine',
		lat: 29.89487937,
		lng: -81.31471135,
		pop: 44214,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Ocala',
		city_ascii: 'Ocala',
		lat: 29.1873515,
		lng: -82.14026819,
		pop: 95470,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Fort Lauderdale',
		city_ascii: 'Fort Lauderdale',
		lat: 26.13606488,
		lng: -80.14178552,
		pop: 1103781.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Apalachicola',
		city_ascii: 'Apalachicola',
		lat: 29.72561322,
		lng: -84.99252303,
		pop: 2134,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Vero Beach',
		city_ascii: 'Vero Beach',
		lat: 27.64225201,
		lng: -80.39112431,
		pop: 51650.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Valdosta',
		city_ascii: 'Valdosta',
		lat: 30.8328583,
		lng: -83.27859664,
		pop: 53420,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Georgia',
		state_ansi: 'GA',
		timezone: 'America/New_York',
	},
	{
		city: 'Albany',
		city_ascii: 'Albany',
		lat: 31.57873008,
		lng: -84.15582992,
		pop: 82280,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Georgia',
		state_ansi: 'GA',
		timezone: 'America/New_York',
	},
	{
		city: 'Athens',
		city_ascii: 'Athens',
		lat: 33.96129783,
		lng: -83.3780221,
		pop: 78017.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Georgia',
		state_ansi: 'GA',
		timezone: 'America/New_York',
	},
	{
		city: 'Macon',
		city_ascii: 'Macon',
		lat: 32.85038373,
		lng: -83.63004806,
		pop: 104932.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Georgia',
		state_ansi: 'GA',
		timezone: 'America/New_York',
	},
	{
		city: 'Columbus',
		city_ascii: 'Columbus',
		lat: 32.47043276,
		lng: -84.98001734,
		pop: 202225,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Georgia',
		state_ansi: 'GA',
		timezone: 'America/New_York',
	},
	{
		city: 'Douglas',
		city_ascii: 'Douglas',
		lat: 31.50777834,
		lng: -82.85068994,
		pop: 12159,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Georgia',
		state_ansi: 'GA',
		timezone: 'America/New_York',
	},
	{
		city: 'Dublin',
		city_ascii: 'Dublin',
		lat: 32.53745709,
		lng: -82.91828272,
		pop: 19258.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Georgia',
		state_ansi: 'GA',
		timezone: 'America/New_York',
	},
	{
		city: 'Gulfport',
		city_ascii: 'Gulfport',
		lat: 30.3675637,
		lng: -89.09276371,
		pop: 76646,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Mississippi',
		state_ansi: 'MS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Hattiesburg',
		city_ascii: 'Hattiesburg',
		lat: 31.32727256,
		lng: -89.2902452,
		pop: 53498.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Mississippi',
		state_ansi: 'MS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Tupelo',
		city_ascii: 'Tupelo',
		lat: 34.25792055,
		lng: -88.70333012,
		pop: 33928,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Mississippi',
		state_ansi: 'MS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Greenville',
		city_ascii: 'Greenville',
		lat: 33.41037539,
		lng: -91.06168746,
		pop: 36539.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Mississippi',
		state_ansi: 'MS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Natchez',
		city_ascii: 'Natchez',
		lat: 31.55480389,
		lng: -91.38750737,
		pop: 20490.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Mississippi',
		state_ansi: 'MS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Florence',
		city_ascii: 'Florence',
		lat: 34.19567629,
		lng: -79.76279057,
		pop: 43977.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Carolina',
		state_ansi: 'SC',
		timezone: 'America/New_York',
	},
	{
		city: 'Greenville',
		city_ascii: 'Greenville',
		lat: 34.85292299,
		lng: -82.3941545,
		pop: 203256.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Carolina',
		state_ansi: 'SC',
		timezone: 'America/New_York',
	},
	{
		city: 'Sumter',
		city_ascii: 'Sumter',
		lat: 33.92065432,
		lng: -80.34172164,
		pop: 27012,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Carolina',
		state_ansi: 'SC',
		timezone: 'America/New_York',
	},
	{
		city: 'Anderson',
		city_ascii: 'Anderson',
		lat: 34.50374534,
		lng: -82.6502629,
		pop: 43475.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Carolina',
		state_ansi: 'SC',
		timezone: 'America/New_York',
	},
	{
		city: 'Aiken',
		city_ascii: 'Aiken',
		lat: 33.5494625,
		lng: -81.72060388,
		pop: 36716.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Carolina',
		state_ansi: 'SC',
		timezone: 'America/New_York',
	},
	{
		city: 'Beaufort',
		city_ascii: 'Beaufort',
		lat: 32.43216636,
		lng: -80.68950403,
		pop: 21941,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Carolina',
		state_ansi: 'SC',
		timezone: 'America/New_York',
	},
	{
		city: 'Rock Hill',
		city_ascii: 'Rock Hill',
		lat: 34.94038535,
		lng: -81.03000004,
		pop: 77165,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Carolina',
		state_ansi: 'SC',
		timezone: 'America/New_York',
	},
	{
		city: 'Decatur',
		city_ascii: 'Decatur',
		lat: 39.8407064,
		lng: -88.95473596,
		pop: 74967.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Alton',
		city_ascii: 'Alton',
		lat: 38.89099693,
		lng: -90.18422164,
		pop: 57386,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Quincy',
		city_ascii: 'Quincy',
		lat: 39.9359719,
		lng: -91.40972823,
		pop: 43419.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Urbana',
		city_ascii: 'Urbana',
		lat: 40.10999229,
		lng: -88.20418746,
		pop: 91792.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Bloomington',
		city_ascii: 'Bloomington',
		lat: 40.48459475,
		lng: -88.99359664,
		pop: 99842.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Kankakee',
		city_ascii: 'Kankakee',
		lat: 41.12036989,
		lng: -87.86110763,
		pop: 48115.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Waukegan',
		city_ascii: 'Waukegan',
		lat: 42.36404075,
		lng: -87.8447262,
		pop: 144539,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Aurora',
		city_ascii: 'Aurora',
		lat: 41.76539512,
		lng: -88.29999557,
		pop: 273949.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Carbondale',
		city_ascii: 'Carbondale',
		lat: 37.72683026,
		lng: -89.22024947,
		pop: 28473,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Belleville',
		city_ascii: 'Belleville',
		lat: 38.52515362,
		lng: -90.0002277,
		pop: 92409.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Bloomington',
		city_ascii: 'Bloomington',
		lat: 39.16565716,
		lng: -86.52640873,
		pop: 85781.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Indiana',
		state_ansi: 'IN',
		timezone: 'America/Indiana/Indianapolis',
	},
	{
		city: 'Muncie',
		city_ascii: 'Muncie',
		lat: 40.19375979,
		lng: -85.38637496,
		pop: 75388,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Indiana',
		state_ansi: 'IN',
		timezone: 'America/Indiana/Indianapolis',
	},
	{
		city: 'Kokomo',
		city_ascii: 'Kokomo',
		lat: 40.48676516,
		lng: -86.13364201,
		pop: 53674,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Indiana',
		state_ansi: 'IN',
		timezone: 'America/Indiana/Indianapolis',
	},
	{
		city: 'Gary',
		city_ascii: 'Gary',
		lat: 41.58039349,
		lng: -87.33000309,
		pop: 335737,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Indiana',
		state_ansi: 'IN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Fort Wayne',
		city_ascii: 'Fort Wayne',
		lat: 41.08039817,
		lng: -85.12998234,
		pop: 264793,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Indiana',
		state_ansi: 'IN',
		timezone: 'America/Indiana/Indianapolis',
	},
	{
		city: 'Covington',
		city_ascii: 'Covington',
		lat: 39.0840084,
		lng: -84.50859908,
		pop: 313064.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kentucky',
		state_ansi: 'KY',
		timezone: 'America/New_York',
	},
	{
		city: 'Bowling Green',
		city_ascii: 'Bowling Green',
		lat: 36.99069948,
		lng: -86.44364893,
		pop: 61349,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kentucky',
		state_ansi: 'KY',
		timezone: 'America/Chicago',
	},
	{
		city: 'Paducah',
		city_ascii: 'Paducah',
		lat: 37.08371706,
		lng: -88.60000309,
		pop: 33812,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kentucky',
		state_ansi: 'KY',
		timezone: 'America/Chicago',
	},
	{
		city: 'Owensboro',
		city_ascii: 'Owensboro',
		lat: 37.77457928,
		lng: -87.11332381,
		pop: 61151.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kentucky',
		state_ansi: 'KY',
		timezone: 'America/Chicago',
	},
	{
		city: 'Jacksonville',
		city_ascii: 'Jacksonville',
		lat: 34.75432436,
		lng: -77.43055567,
		pop: 72651.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Goldsboro',
		city_ascii: 'Goldsboro',
		lat: 35.38513857,
		lng: -77.99305363,
		pop: 42922.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Greenville',
		city_ascii: 'Greenville',
		lat: 35.61287661,
		lng: -77.3666836,
		pop: 81661,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Fayetteville',
		city_ascii: 'Fayetteville',
		lat: 35.06293601,
		lng: -78.88359359,
		pop: 184040.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Hickory',
		city_ascii: 'Hickory',
		lat: 35.7334894,
		lng: -81.34140222,
		pop: 64898,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Asheville',
		city_ascii: 'Asheville',
		lat: 35.60119773,
		lng: -82.55414474,
		pop: 105775,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Winston-Salem',
		city_ascii: 'Winston-Salem',
		lat: 36.10543052,
		lng: -80.25999536,
		pop: 237491.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Kitty Hawk',
		city_ascii: 'Kitty Hawk',
		lat: 36.07731854,
		lng: -75.70471786,
		pop: 2109.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Akron',
		city_ascii: 'Akron',
		lat: 41.07039878,
		lng: -81.51999597,
		pop: 451155,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Ohio',
		state_ansi: 'OH',
		timezone: 'America/New_York',
	},
	{
		city: 'Lima',
		city_ascii: 'Lima',
		lat: 40.74287355,
		lng: -84.10526453,
		pop: 54135,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Ohio',
		state_ansi: 'OH',
		timezone: 'America/New_York',
	},
	{
		city: 'Oak Ridge',
		city_ascii: 'Oak Ridge',
		lat: 36.01065594,
		lng: -84.26972477,
		pop: 30471.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Tennessee',
		state_ansi: 'TN',
		timezone: 'America/New_York',
	},
	{
		city: 'Murfreesboro',
		city_ascii: 'Murfreesboro',
		lat: 35.84596315,
		lng: -86.39026717,
		pop: 100237,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Tennessee',
		state_ansi: 'TN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Clarksville',
		city_ascii: 'Clarksville',
		lat: 36.5300816,
		lng: -87.35943282,
		pop: 122115,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Tennessee',
		state_ansi: 'TN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Jackson',
		city_ascii: 'Jackson',
		lat: 35.61486615,
		lng: -88.81389185,
		pop: 62638,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Tennessee',
		state_ansi: 'TN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Alexandria',
		city_ascii: 'Alexandria',
		lat: 38.82043276,
		lng: -77.09998153,
		pop: 127273,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Virginia',
		state_ansi: 'VA',
		timezone: 'America/New_York',
	},
	{
		city: 'Fredericksburg',
		city_ascii: 'Fredericksburg',
		lat: 38.30351341,
		lng: -77.46078638,
		pop: 76848,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Virginia',
		state_ansi: 'VA',
		timezone: 'America/New_York',
	},
	{
		city: 'Roanoke',
		city_ascii: 'Roanoke',
		lat: 37.27119916,
		lng: -79.94161686,
		pop: 144669.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Virginia',
		state_ansi: 'VA',
		timezone: 'America/New_York',
	},
	{
		city: 'Danville',
		city_ascii: 'Danville',
		lat: 36.58625388,
		lng: -79.39531946,
		pop: 43176,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Virginia',
		state_ansi: 'VA',
		timezone: 'America/New_York',
	},
	{
		city: 'Winchester',
		city_ascii: 'Winchester',
		lat: 39.1787313,
		lng: -78.16663477,
		pop: 39418,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Virginia',
		state_ansi: 'VA',
		timezone: 'America/New_York',
	},
	{
		city: 'Bristol',
		city_ascii: 'Bristol',
		lat: 36.61152366,
		lng: -82.17600244,
		pop: 31276.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Virginia',
		state_ansi: 'VA',
		timezone: 'America/New_York',
	},
	{
		city: 'Superior',
		city_ascii: 'Superior',
		lat: 46.72124249,
		lng: -92.10389775,
		pop: 27474,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'West Bend',
		city_ascii: 'West Bend',
		lat: 43.42570721,
		lng: -88.18333602,
		pop: 31980.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Fond du Lac',
		city_ascii: 'Fond du Lac',
		lat: 43.77343793,
		lng: -88.44691166,
		pop: 48079.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Oshkosh',
		city_ascii: 'Oshkosh',
		lat: 44.02510215,
		lng: -88.54251306,
		pop: 67857.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Rhinelander',
		city_ascii: 'Rhinelander',
		lat: 45.63991315,
		lng: -89.41207239,
		pop: 9633,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Racine',
		city_ascii: 'Racine',
		lat: 42.72771364,
		lng: -87.81183415,
		pop: 105458.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Marinette',
		city_ascii: 'Marinette',
		lat: 45.10038535,
		lng: -87.63047571,
		pop: 19170,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Wheeling',
		city_ascii: 'Wheeling',
		lat: 40.06431032,
		lng: -80.72107833,
		pop: 40940,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'West Virginia',
		state_ansi: 'WV',
		timezone: 'America/New_York',
	},
	{
		city: 'Morgantown',
		city_ascii: 'Morgantown',
		lat: 39.62981488,
		lng: -79.95606043,
		pop: 43882.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'West Virginia',
		state_ansi: 'WV',
		timezone: 'America/New_York',
	},
	{
		city: 'Huntington',
		city_ascii: 'Huntington',
		lat: 38.41957867,
		lng: -82.44528833,
		pop: 66957,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'West Virginia',
		state_ansi: 'WV',
		timezone: 'America/New_York',
	},
	{
		city: 'Beckley',
		city_ascii: 'Beckley',
		lat: 37.78018618,
		lng: -81.18301396,
		pop: 27358,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'West Virginia',
		state_ansi: 'WV',
		timezone: 'America/New_York',
	},
	{
		city: 'Wilmington',
		city_ascii: 'Wilmington',
		lat: 39.74626772,
		lng: -75.54689803,
		pop: 116205.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Delaware',
		state_ansi: 'DE',
		timezone: 'America/New_York',
	},
	{
		city: 'Cumberland',
		city_ascii: 'Cumberland',
		lat: 39.65317263,
		lng: -78.76277409,
		pop: 20831.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Maryland',
		state_ansi: 'MD',
		timezone: 'America/New_York',
	},
	{
		city: 'Atlantic City',
		city_ascii: 'Atlantic City',
		lat: 39.36463727,
		lng: -74.4233232,
		pop: 58563.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Jersey',
		state_ansi: 'NJ',
		timezone: 'America/New_York',
	},
	{
		city: 'Newark',
		city_ascii: 'Newark',
		lat: 40.70042137,
		lng: -74.17000533,
		pop: 280123,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Jersey',
		state_ansi: 'NJ',
		timezone: 'America/New_York',
	},
	{
		city: 'Schenectady',
		city_ascii: 'Schenectady',
		lat: 42.81458173,
		lng: -73.93996769,
		pop: 104767.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'Binghamton',
		city_ascii: 'Binghamton',
		lat: 42.09901817,
		lng: -75.91832239,
		pop: 92687.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'Utica',
		city_ascii: 'Utica',
		lat: 43.10117922,
		lng: -75.23306706,
		pop: 81870,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'Watertown',
		city_ascii: 'Watertown',
		lat: 43.97515688,
		lng: -75.91106185,
		pop: 30938,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'Niagara Falls',
		city_ascii: 'Niagara Falls',
		lat: 43.09482302,
		lng: -79.0369434,
		pop: 117567,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'Jamestown',
		city_ascii: 'Jamestown',
		lat: 42.09736452,
		lng: -79.23553593,
		pop: 37916.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'Elmira',
		city_ascii: 'Elmira',
		lat: 42.09012982,
		lng: -76.80803552,
		pop: 46201,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'York',
		city_ascii: 'York',
		lat: 39.96292116,
		lng: -76.72804041,
		pop: 128798.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Pennsylvania',
		state_ansi: 'PA',
		timezone: 'America/New_York',
	},
	{
		city: 'Johnstown',
		city_ascii: 'Johnstown',
		lat: 40.32708498,
		lng: -78.92222172,
		pop: 45821.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Pennsylvania',
		state_ansi: 'PA',
		timezone: 'America/New_York',
	},
	{
		city: 'Scranton',
		city_ascii: 'Scranton',
		lat: 41.40929283,
		lng: -75.66267908,
		pop: 114701,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Pennsylvania',
		state_ansi: 'PA',
		timezone: 'America/New_York',
	},
	{
		city: 'State College',
		city_ascii: 'State College',
		lat: 40.79372316,
		lng: -77.8602452,
		pop: 64880.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Pennsylvania',
		state_ansi: 'PA',
		timezone: 'America/New_York',
	},
	{
		city: 'Erie',
		city_ascii: 'Erie',
		lat: 42.12992067,
		lng: -80.08499313,
		pop: 138991.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Pennsylvania',
		state_ansi: 'PA',
		timezone: 'America/New_York',
	},
	{
		city: 'Wilkes Barre',
		city_ascii: 'Wilkes Barre',
		lat: 41.24904421,
		lng: -75.87793726,
		pop: 99824.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Pennsylvania',
		state_ansi: 'PA',
		timezone: 'America/New_York',
	},
	{
		city: 'Bar Harbor',
		city_ascii: 'Bar Harbor',
		lat: 44.38789654,
		lng: -68.20437464,
		pop: 4483.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Maine',
		state_ansi: 'ME',
		timezone: 'America/New_York',
	},
	{
		city: 'Lewiston',
		city_ascii: 'Lewiston',
		lat: 44.10070477,
		lng: -70.21525965,
		pop: 46689,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Maine',
		state_ansi: 'ME',
		timezone: 'America/New_York',
	},
	{
		city: 'Presque Isle',
		city_ascii: 'Presque Isle',
		lat: 46.79340863,
		lng: -68.00216476,
		pop: 9466,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Maine',
		state_ansi: 'ME',
		timezone: 'America/New_York',
	},
	{
		city: 'Ann Arbor',
		city_ascii: 'Ann Arbor',
		lat: 42.30037539,
		lng: -83.71999089,
		pop: 189893,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Kalamazoo',
		city_ascii: 'Kalamazoo',
		lat: 42.29215883,
		lng: -85.58718958,
		pop: 128759.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Muskegon',
		city_ascii: 'Muskegon',
		lat: 43.23458193,
		lng: -86.24836369,
		pop: 70644.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Flint',
		city_ascii: 'Flint',
		lat: 43.0128642,
		lng: -83.68753809,
		pop: 206235,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Grand Rapids',
		city_ascii: 'Grand Rapids',
		lat: 42.96371991,
		lng: -85.66994938,
		pop: 361934.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Pontiac',
		city_ascii: 'Pontiac',
		lat: 42.65185264,
		lng: -83.29022384,
		pop: 67994,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Cadillac',
		city_ascii: 'Cadillac',
		lat: 44.25121238,
		lng: -85.41360844,
		pop: 12177.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Traverse City',
		city_ascii: 'Traverse City',
		lat: 44.76844179,
		lng: -85.62217452,
		pop: 28807,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Petoskey',
		city_ascii: 'Petoskey',
		lat: 45.37375368,
		lng: -84.95518681,
		pop: 9424,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Escanaba',
		city_ascii: 'Escanaba',
		lat: 45.7456948,
		lng: -87.06436039,
		pop: 14970,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Marquette',
		city_ascii: 'Marquette',
		lat: 46.54673118,
		lng: -87.40658757,
		pop: 23711,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Hancock',
		city_ascii: 'Hancock',
		lat: 47.12729006,
		lng: -88.5808053,
		pop: 10322.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Wrangell',
		city_ascii: 'Wrangell',
		lat: 56.47126752,
		lng: -132.3715949,
		pop: 1658.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Sitka',
	},
	{
		city: 'Shishmaref',
		city_ascii: 'Shishmaref',
		lat: 66.25697512,
		lng: -166.0718893,
		pop: 254,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Hoonah',
		city_ascii: 'Hoonah',
		lat: 58.11540489,
		lng: -135.438617,
		pop: 361,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Juneau',
	},
	{
		city: 'Atka',
		city_ascii: 'Atka',
		lat: 52.19648968,
		lng: -174.2004887,
		pop: 61,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Adak',
	},
	{
		city: 'Nikolski',
		city_ascii: 'Nikolski',
		lat: 52.93843406,
		lng: -168.8676876,
		pop: 18,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Karluk',
		city_ascii: 'Karluk',
		lat: 57.57228558,
		lng: -154.4550273,
		pop: 96,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'False Pass',
		city_ascii: 'False Pass',
		lat: 54.85121136,
		lng: -163.415023,
		pop: 35,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Kivalina',
		city_ascii: 'Kivalina',
		lat: 67.73149224,
		lng: -164.4859034,
		pop: 374,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Newhalen',
		city_ascii: 'Newhalen',
		lat: 59.72034568,
		lng: -154.8971967,
		pop: 160,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Pilot Point',
		city_ascii: 'Pilot Point',
		lat: 57.56455996,
		lng: -157.5691266,
		pop: 68,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Chignik',
		city_ascii: 'Chignik',
		lat: 56.295671,
		lng: -158.4022282,
		pop: 118,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'King Salmon',
		city_ascii: 'King Salmon',
		lat: 58.68870323,
		lng: -156.6613784,
		pop: 292,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Quinhagak',
		city_ascii: 'Quinhagak',
		lat: 59.74923281,
		lng: -161.9157864,
		pop: 250,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Aniak',
		city_ascii: 'Aniak',
		lat: 61.5787077,
		lng: -159.5221857,
		pop: 501,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Kotlit',
		city_ascii: 'Kotlit',
		lat: 63.03458783,
		lng: -163.5532833,
		pop: 1002,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Unalakleet',
		city_ascii: 'Unalakleet',
		lat: 63.87342552,
		lng: -160.7880516,
		pop: 741,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Koyuk',
		city_ascii: 'Koyuk',
		lat: 64.94026874,
		lng: -161.1574717,
		pop: 254,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'McGrath',
		city_ascii: 'McGrath',
		lat: 62.9568148,
		lng: -155.5957845,
		pop: 138,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Hughes',
		city_ascii: 'Hughes',
		lat: 66.04918418,
		lng: -154.2549878,
		pop: 78,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Ambler',
		city_ascii: 'Ambler',
		lat: 67.08648521,
		lng: -157.8514091,
		pop: 258,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Wales',
		city_ascii: 'Wales',
		lat: 65.60959861,
		lng: -168.0875027,
		pop: 99,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Kotzebue',
		city_ascii: 'Kotzebue',
		lat: 66.89869305,
		lng: -162.5965975,
		pop: 2873.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Wasilla',
		city_ascii: 'Wasilla',
		lat: 61.58173077,
		lng: -149.439442,
		pop: 8521,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Circle',
		city_ascii: 'Circle',
		lat: 65.82589032,
		lng: -144.0605197,
		pop: 100,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Denali Park',
		city_ascii: 'Denali Park',
		lat: 63.73309816,
		lng: -148.9140994,
		pop: 1063,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Yakutat',
		city_ascii: 'Yakutat',
		lat: 59.54730715,
		lng: -139.7272183,
		pop: 109,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Yakutat',
	},
	{
		city: 'Homer',
		city_ascii: 'Homer',
		lat: 59.64293439,
		lng: -151.5482797,
		pop: 5021.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Tanacross',
		city_ascii: 'Tanacross',
		lat: 63.38570335,
		lng: -143.346403,
		pop: 136,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Wiseman',
		city_ascii: 'Wiseman',
		lat: 67.4104706,
		lng: -150.1074891,
		pop: 14,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Kailua-Kona',
		city_ascii: 'Kailua-Kona',
		lat: 19.6405556,
		lng: -155.9955556,
		pop: 9870,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Hawaii',
		state_ansi: 'HI',
		timezone: 'Pacific/Honolulu',
	},
	{
		city: 'Butte',
		city_ascii: 'Butte',
		lat: 46.0038961,
		lng: -112.5338394,
		pop: 31478,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Montana',
		state_ansi: 'MT',
		timezone: 'America/Denver',
	},
	{
		city: 'Grand Forks',
		city_ascii: 'Grand Forks',
		lat: 47.92527753,
		lng: -97.0324858,
		pop: 53496,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Dakota',
		state_ansi: 'ND',
		timezone: 'America/Chicago',
	},
	{
		city: 'Pocatello',
		city_ascii: 'Pocatello',
		lat: 42.87134829,
		lng: -112.4447234,
		pop: 57327,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Idaho',
		state_ansi: 'ID',
		timezone: 'America/Boise',
	},
	{
		city: 'Tacoma',
		city_ascii: 'Tacoma',
		lat: 47.21131594,
		lng: -122.5150131,
		pop: 460273,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Yuma',
		city_ascii: 'Yuma',
		lat: 32.68527753,
		lng: -114.6236084,
		pop: 88402.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Prescott',
		city_ascii: 'Prescott',
		lat: 34.59001914,
		lng: -112.4477723,
		pop: 47587,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Long Beach',
		city_ascii: 'Long Beach',
		lat: 33.78696739,
		lng: -118.1580439,
		pop: 1249195.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Grand Junction',
		city_ascii: 'Grand Junction',
		lat: 39.09385276,
		lng: -108.5499998,
		pop: 75076,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Colorado',
		state_ansi: 'CO',
		timezone: 'America/Denver',
	},
	{
		city: 'Ely',
		city_ascii: 'Ely',
		lat: 39.24702171,
		lng: -114.887675,
		pop: 3911,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nevada',
		state_ansi: 'NV',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Carson City',
		city_ascii: 'Carson City',
		lat: 39.16384849,
		lng: -119.7663953,
		pop: 53767,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nevada',
		state_ansi: 'NV',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Carlsbad',
		city_ascii: 'Carlsbad',
		lat: 32.420565,
		lng: -104.2282998,
		pop: 25240,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Alamogordo',
		city_ascii: 'Alamogordo',
		lat: 32.89947634,
		lng: -105.9597187,
		pop: 33710.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Medford',
		city_ascii: 'Medford',
		lat: 42.32662701,
		lng: -122.8744227,
		pop: 89081.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Klamath Falls',
		city_ascii: 'Klamath Falls',
		lat: 42.22500531,
		lng: -121.7805359,
		pop: 31090.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'St. George',
		city_ascii: 'St. George',
		lat: 37.10415509,
		lng: -113.583336,
		pop: 79988,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Utah',
		state_ansi: 'UT',
		timezone: 'America/Denver',
	},
	{
		city: 'Provo',
		city_ascii: 'Provo',
		lat: 40.24889854,
		lng: -111.63777,
		pop: 231238,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Utah',
		state_ansi: 'UT',
		timezone: 'America/Denver',
	},
	{
		city: 'Laramie',
		city_ascii: 'Laramie',
		lat: 41.31136599,
		lng: -105.5905681,
		pop: 25587.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wyoming',
		state_ansi: 'WY',
		timezone: 'America/Denver',
	},
	{
		city: 'Little Rock',
		city_ascii: 'Little Rock',
		lat: 34.73608258,
		lng: -92.33109318,
		pop: 227555,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arkansas',
		state_ansi: 'AR',
		timezone: 'America/Chicago',
	},
	{
		city: 'Wichita',
		city_ascii: 'Wichita',
		lat: 37.71998313,
		lng: -97.32998702,
		pop: 378543.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kansas',
		state_ansi: 'KS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Jefferson City',
		city_ascii: 'Jefferson City',
		lat: 38.57662335,
		lng: -92.17332503,
		pop: 45511,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Missouri',
		state_ansi: 'MO',
		timezone: 'America/Chicago',
	},
	{
		city: 'Rapid City',
		city_ascii: 'Rapid City',
		lat: 44.08055096,
		lng: -103.2305571,
		pop: 67760,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Dakota',
		state_ansi: 'SD',
		timezone: 'America/Denver',
	},
	{
		city: 'Lafayette',
		city_ascii: 'Lafayette',
		lat: 30.19997703,
		lng: -92.01994938,
		pop: 135205.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Louisiana',
		state_ansi: 'LA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Galveston',
		city_ascii: 'Galveston',
		lat: 29.301143,
		lng: -94.7974801,
		pop: 62516,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Freeport',
		city_ascii: 'Freeport',
		lat: 28.95948427,
		lng: -95.35687748,
		pop: 43762,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Victoria',
		city_ascii: 'Victoria',
		lat: 28.80499758,
		lng: -97.00334029,
		pop: 63126.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Odessa',
		city_ascii: 'Odessa',
		lat: 31.84556134,
		lng: -102.3672248,
		pop: 98655,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Wichita Falls',
		city_ascii: 'Wichita Falls',
		lat: 33.91362632,
		lng: -98.49306848,
		pop: 97429,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Waco',
		city_ascii: 'Waco',
		lat: 31.54917116,
		lng: -97.14638066,
		pop: 143157,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Lubbock',
		city_ascii: 'Lubbock',
		lat: 33.58000327,
		lng: -101.8799677,
		pop: 212343.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Hartford',
		city_ascii: 'Hartford',
		lat: 41.77002016,
		lng: -72.67996708,
		pop: 518509.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Connecticut',
		state_ansi: 'CT',
		timezone: 'America/New_York',
	},
	{
		city: 'Providence',
		city_ascii: 'Providence',
		lat: 41.82110231,
		lng: -71.4149797,
		pop: 663726.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Rhode Island',
		state_ansi: 'RI',
		timezone: 'America/New_York',
	},
	{
		city: 'Birmingham',
		city_ascii: 'Birmingham',
		lat: 33.53000633,
		lng: -86.82499516,
		pop: 670142,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alabama',
		state_ansi: 'AL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Mobile',
		city_ascii: 'Mobile',
		lat: 30.68002525,
		lng: -88.04998499,
		pop: 221870,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alabama',
		state_ansi: 'AL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Pensacola',
		city_ascii: 'Pensacola',
		lat: 30.42112632,
		lng: -87.21693506,
		pop: 145319.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/Chicago',
	},
	{
		city: 'St. Petersburg',
		city_ascii: 'St. Petersburg',
		lat: 27.77053876,
		lng: -82.67938257,
		pop: 523314.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Biloxi',
		city_ascii: 'Biloxi',
		lat: 30.39580487,
		lng: -88.88530868,
		pop: 43857,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Mississippi',
		state_ansi: 'MS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Springfield',
		city_ascii: 'Springfield',
		lat: 39.82000999,
		lng: -89.65001652,
		pop: 125345,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Frankfort',
		city_ascii: 'Frankfort',
		lat: 38.2008065,
		lng: -84.87335718,
		pop: 32214.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kentucky',
		state_ansi: 'KY',
		timezone: 'America/New_York',
	},
	{
		city: 'Greensboro',
		city_ascii: 'Greensboro',
		lat: 36.07000633,
		lng: -79.80002344,
		pop: 310328,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Dayton',
		city_ascii: 'Dayton',
		lat: 39.750376,
		lng: -84.19998743,
		pop: 466067,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Ohio',
		state_ansi: 'OH',
		timezone: 'America/New_York',
	},
	{
		city: 'Virginia Beach',
		city_ascii: 'Virginia Beach',
		lat: 36.85321433,
		lng: -75.97831873,
		pop: 877475.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Virginia',
		state_ansi: 'VA',
		timezone: 'America/New_York',
	},
	{
		city: 'Madison',
		city_ascii: 'Madison',
		lat: 43.07301556,
		lng: -89.40111699,
		pop: 276036,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Green Bay',
		city_ascii: 'Green Bay',
		lat: 44.5299809,
		lng: -88.00001388,
		pop: 149811.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Trenton',
		city_ascii: 'Trenton',
		lat: 40.2169625,
		lng: -74.74335535,
		pop: 225713,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Jersey',
		state_ansi: 'NJ',
		timezone: 'America/New_York',
	},
	{
		city: 'Lansing',
		city_ascii: 'Lansing',
		lat: 42.73352724,
		lng: -84.54673629,
		pop: 198821.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Gambell',
		city_ascii: 'Gambell',
		lat: 63.77971031,
		lng: -171.7310787,
		pop: 681,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Palmer',
		city_ascii: 'Palmer',
		lat: 61.59971417,
		lng: -149.1126919,
		pop: 9848,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Seward',
		city_ascii: 'Seward',
		lat: 60.1261607,
		lng: -149.4699827,
		pop: 2900,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Duluth',
		city_ascii: 'Duluth',
		lat: 46.78333173,
		lng: -92.10637822,
		pop: 82026.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Bemidji',
		city_ascii: 'Bemidji',
		lat: 47.47357383,
		lng: -94.88018823,
		pop: 14063.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Havre',
		city_ascii: 'Havre',
		lat: 48.54523968,
		lng: -109.6776829,
		pop: 10266.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Montana',
		state_ansi: 'MT',
		timezone: 'America/Denver',
	},
	{
		city: 'Kalispell',
		city_ascii: 'Kalispell',
		lat: 48.19776735,
		lng: -114.3159786,
		pop: 25040,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Montana',
		state_ansi: 'MT',
		timezone: 'America/Denver',
	},
	{
		city: 'Idaho Falls',
		city_ascii: 'Idaho Falls',
		lat: 43.46668662,
		lng: -112.0333014,
		pop: 65787,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Idaho',
		state_ansi: 'ID',
		timezone: 'America/Boise',
	},
	{
		city: 'Lewiston',
		city_ascii: 'Lewiston',
		lat: 46.41660992,
		lng: -117.016589,
		pop: 40096.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Idaho',
		state_ansi: 'ID',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Yakima',
		city_ascii: 'Yakima',
		lat: 46.60223167,
		lng: -120.5046965,
		pop: 93846,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Wenatchee',
		city_ascii: 'Wenatchee',
		lat: 47.42362856,
		lng: -120.3090237,
		pop: 45892,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Douglas',
		city_ascii: 'Douglas',
		lat: 31.35864016,
		lng: -109.5483627,
		pop: 23438.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Bakersfield',
		city_ascii: 'Bakersfield',
		lat: 35.36997154,
		lng: -119.0199809,
		pop: 367259,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Oakland',
		city_ascii: 'Oakland',
		lat: 37.76892071,
		lng: -122.2211034,
		pop: 953044,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Lancaster',
		city_ascii: 'Lancaster',
		lat: 34.69804873,
		lng: -118.135823,
		pop: 225799,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Chico',
		city_ascii: 'Chico',
		lat: 39.72862022,
		lng: -121.8363982,
		pop: 83226.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Monterey',
		city_ascii: 'Monterey',
		lat: 36.6002582,
		lng: -121.8935781,
		pop: 77297.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Salinas',
		city_ascii: 'Salinas',
		lat: 36.68221702,
		lng: -121.6416555,
		pop: 152737.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Los Alamos',
		city_ascii: 'Los Alamos',
		lat: 35.89110252,
		lng: -106.2977084,
		pop: 11997,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Eugene',
		city_ascii: 'Eugene',
		lat: 44.05001019,
		lng: -123.1000161,
		pop: 195183,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Coos Bay',
		city_ascii: 'Coos Bay',
		lat: 43.36661521,
		lng: -124.2165888,
		pop: 23685,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Bend',
		city_ascii: 'Bend',
		lat: 44.071921,
		lng: -121.3099962,
		pop: 70598.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Cody',
		city_ascii: 'Cody',
		lat: 44.52321128,
		lng: -109.0571007,
		pop: 8976.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wyoming',
		state_ansi: 'WY',
		timezone: 'America/Denver',
	},
	{
		city: 'Cedar Rapids',
		city_ascii: 'Cedar Rapids',
		lat: 41.96998212,
		lng: -91.66002303,
		pop: 149338.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Iowa',
		state_ansi: 'IA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Springfield',
		city_ascii: 'Springfield',
		lat: 37.18001609,
		lng: -93.31999923,
		pop: 180691,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Missouri',
		state_ansi: 'MO',
		timezone: 'America/Chicago',
	},
	{
		city: 'Lincoln',
		city_ascii: 'Lincoln',
		lat: 40.81997479,
		lng: -96.68000086,
		pop: 244146,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nebraska',
		state_ansi: 'NE',
		timezone: 'America/Chicago',
	},
	{
		city: 'Alexandria',
		city_ascii: 'Alexandria',
		lat: 31.31109784,
		lng: -92.44501388,
		pop: 60876,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Louisiana',
		state_ansi: 'LA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Abilene',
		city_ascii: 'Abilene',
		lat: 32.4486253,
		lng: -99.73278609,
		pop: 108008,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Brownsville',
		city_ascii: 'Brownsville',
		lat: 25.91997988,
		lng: -97.50000248,
		pop: 174707,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Tyler',
		city_ascii: 'Tyler',
		lat: 32.35108604,
		lng: -95.30078272,
		pop: 101561.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Concord',
		city_ascii: 'Concord',
		lat: 43.20807192,
		lng: -71.53804712,
		pop: 42646.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Hampshire',
		state_ansi: 'NH',
		timezone: 'America/New_York',
	},
	{
		city: 'Huntsville',
		city_ascii: 'Huntsville',
		lat: 34.71995953,
		lng: -86.60999536,
		pop: 185474.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alabama',
		state_ansi: 'AL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Key West',
		city_ascii: 'Key West',
		lat: 24.55523114,
		lng: -81.78274479,
		pop: 27011.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'West Palm Beach',
		city_ascii: 'West Palm Beach',
		lat: 26.74501996,
		lng: -80.12362126,
		pop: 675521.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Sarasota',
		city_ascii: 'Sarasota',
		lat: 27.33612083,
		lng: -82.53078699,
		pop: 321223.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Daytona Beach',
		city_ascii: 'Daytona Beach',
		lat: 29.21055422,
		lng: -81.0230754,
		pop: 140775.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Gainesville',
		city_ascii: 'Gainesville',
		lat: 29.65138002,
		lng: -82.32503727,
		pop: 158390.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Ft. Myers',
		city_ascii: 'Ft. Myers',
		lat: 26.64029767,
		lng: -81.86049199,
		pop: 120810.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Brunswick',
		city_ascii: 'Brunswick',
		lat: 31.1496865,
		lng: -81.49165145,
		pop: 31785.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Georgia',
		state_ansi: 'GA',
		timezone: 'America/New_York',
	},
	{
		city: 'Augusta',
		city_ascii: 'Augusta',
		lat: 33.46081158,
		lng: -81.98498051,
		pop: 152895.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Georgia',
		state_ansi: 'GA',
		timezone: 'America/New_York',
	},
	{
		city: 'Vicksburg',
		city_ascii: 'Vicksburg',
		lat: 32.3524813,
		lng: -90.8777452,
		pop: 24669.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Mississippi',
		state_ansi: 'MS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Myrtle Beach',
		city_ascii: 'Myrtle Beach',
		lat: 33.68891136,
		lng: -78.8869784,
		pop: 37333.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Carolina',
		state_ansi: 'SC',
		timezone: 'America/New_York',
	},
	{
		city: 'Charleston',
		city_ascii: 'Charleston',
		lat: 32.79237693,
		lng: -79.99210474,
		pop: 254295,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Carolina',
		state_ansi: 'SC',
		timezone: 'America/New_York',
	},
	{
		city: 'Peoria',
		city_ascii: 'Peoria',
		lat: 40.69998212,
		lng: -89.67004114,
		pop: 142622,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Evansville',
		city_ascii: 'Evansville',
		lat: 37.97469627,
		lng: -87.5558291,
		pop: 144788,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Indiana',
		state_ansi: 'IN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Louisville',
		city_ascii: 'Louisville',
		lat: 38.22501691,
		lng: -85.74870427,
		pop: 595819.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kentucky',
		state_ansi: 'KY',
		timezone: 'America/Kentucky/Louisville',
	},
	{
		city: 'Lexington',
		city_ascii: 'Lexington',
		lat: 38.05001467,
		lng: -84.50002079,
		pop: 244972,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kentucky',
		state_ansi: 'KY',
		timezone: 'America/New_York',
	},
	{
		city: 'Charlotte',
		city_ascii: 'Charlotte',
		lat: 35.20499453,
		lng: -80.83003809,
		pop: 943574.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Youngstown',
		city_ascii: 'Youngstown',
		lat: 41.09969932,
		lng: -80.64973902,
		pop: 194765,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Ohio',
		state_ansi: 'OH',
		timezone: 'America/New_York',
	},
	{
		city: 'Canton',
		city_ascii: 'Canton',
		lat: 40.79886497,
		lng: -81.37863509,
		pop: 168410,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Ohio',
		state_ansi: 'OH',
		timezone: 'America/New_York',
	},
	{
		city: 'Toledo',
		city_ascii: 'Toledo',
		lat: 41.67002626,
		lng: -83.57997359,
		pop: 388449,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Ohio',
		state_ansi: 'OH',
		timezone: 'America/New_York',
	},
	{
		city: 'Columbus',
		city_ascii: 'Columbus',
		lat: 39.97997438,
		lng: -82.9900096,
		pop: 1003418,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Ohio',
		exactCity: 'Columbus',
		exactProvince: 'OH',
		state_ansi: 'OH',
		timezone: 'America/New_York',
	},
	{
		city: 'Chattanooga',
		city_ascii: 'Chattanooga',
		lat: 35.06998985,
		lng: -85.25000086,
		pop: 206571.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Tennessee',
		state_ansi: 'TN',
		timezone: 'America/New_York',
	},
	{
		city: 'Charlottesville',
		city_ascii: 'Charlottesville',
		lat: 38.02918907,
		lng: -78.47692591,
		pop: 61314,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Virginia',
		state_ansi: 'VA',
		timezone: 'America/New_York',
	},
	{
		city: 'Lynchburg',
		city_ascii: 'Lynchburg',
		lat: 37.4136194,
		lng: -79.14246668,
		pop: 84581,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Virginia',
		state_ansi: 'VA',
		timezone: 'America/New_York',
	},
	{
		city: 'Wausau',
		city_ascii: 'Wausau',
		lat: 44.95915367,
		lng: -89.6299919,
		pop: 56100.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Albany',
		city_ascii: 'Albany',
		lat: 42.67001691,
		lng: -73.81994918,
		pop: 484286,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'Ithaca',
		city_ascii: 'Ithaca',
		lat: 42.44057355,
		lng: -76.4969434,
		pop: 45544.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'Harrisburg',
		city_ascii: 'Harrisburg',
		lat: 40.27359987,
		lng: -76.88474919,
		pop: 289210,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Pennsylvania',
		state_ansi: 'PA',
		timezone: 'America/New_York',
	},
	{
		city: 'Bangor',
		city_ascii: 'Bangor',
		lat: 44.80115297,
		lng: -68.77834477,
		pop: 40843,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Maine',
		state_ansi: 'ME',
		timezone: 'America/New_York',
	},
	{
		city: 'Portland',
		city_ascii: 'Portland',
		lat: 43.67216158,
		lng: -70.2455274,
		pop: 99504,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Maine',
		state_ansi: 'ME',
		timezone: 'America/New_York',
	},
	{
		city: 'Saginaw',
		city_ascii: 'Saginaw',
		lat: 43.4194802,
		lng: -83.95082951,
		pop: 89457.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Ketchikan',
		city_ascii: 'Ketchikan',
		lat: 55.3562193,
		lng: -131.6639895,
		pop: 8121,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Sitka',
	},
	{
		city: 'Unalaska',
		city_ascii: 'Unalaska',
		lat: 53.868584,
		lng: -166.5316028,
		pop: 1938.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Togiak',
		city_ascii: 'Togiak',
		lat: 59.07036101,
		lng: -160.3783234,
		pop: 236,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Red Devil',
		city_ascii: 'Red Devil',
		lat: 61.76099632,
		lng: -157.3125273,
		pop: 24,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Hooper Bay',
		city_ascii: 'Hooper Bay',
		lat: 61.53108787,
		lng: -166.0965648,
		pop: 623,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Wainwright',
		city_ascii: 'Wainwright',
		lat: 70.63688865,
		lng: -160.0383041,
		pop: 174,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Galena',
		city_ascii: 'Galena',
		lat: 64.73329551,
		lng: -156.9269953,
		pop: 485,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Kaktovik',
		city_ascii: 'Kaktovik',
		lat: 70.08785552,
		lng: -143.6029132,
		pop: 101,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Skagway',
		city_ascii: 'Skagway',
		lat: 59.45832033,
		lng: -135.3138959,
		pop: 955,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Juneau',
	},
	{
		city: 'Cordova',
		city_ascii: 'Cordova',
		lat: 60.5427761,
		lng: -145.7574962,
		pop: 1622.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Kenai',
		city_ascii: 'Kenai',
		lat: 60.55435162,
		lng: -151.2580131,
		pop: 6580.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Fort Yukon',
		city_ascii: 'Fort Yukon',
		lat: 66.56468243,
		lng: -145.2737789,
		pop: 833,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Santa Cruz',
		city_ascii: 'Santa Cruz',
		lat: 36.97194629,
		lng: -122.0263904,
		pop: 101530.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'San Bernardino',
		city_ascii: 'San Bernardino',
		lat: 34.12038373,
		lng: -117.3000342,
		pop: 973690.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Bridgeport',
		city_ascii: 'Bridgeport',
		lat: 41.17997866,
		lng: -73.19996118,
		pop: 578545,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Connecticut',
		state_ansi: 'CT',
		timezone: 'America/New_York',
	},
	{
		city: 'Rochester',
		city_ascii: 'Rochester',
		lat: 43.17042564,
		lng: -77.61994979,
		pop: 483177,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'International Falls',
		city_ascii: 'International Falls',
		lat: 48.60112775,
		lng: -93.4108464,
		pop: 10832,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'St. Paul',
		city_ascii: 'St. Paul',
		lat: 44.94398663,
		lng: -93.08497481,
		pop: 509961,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Billings',
		city_ascii: 'Billings',
		lat: 45.78830202,
		lng: -108.5400004,
		pop: 102151.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Montana',
		state_ansi: 'MT',
		timezone: 'America/Denver',
	},
	{
		city: 'Great Falls',
		city_ascii: 'Great Falls',
		lat: 47.50029055,
		lng: -111.299987,
		pop: 61316.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Montana',
		state_ansi: 'MT',
		timezone: 'America/Denver',
	},
	{
		city: 'Missoula',
		city_ascii: 'Missoula',
		lat: 46.87224103,
		lng: -113.9930526,
		pop: 68010,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Montana',
		state_ansi: 'MT',
		timezone: 'America/Denver',
	},
	{
		city: 'Minot',
		city_ascii: 'Minot',
		lat: 48.23249392,
		lng: -101.2958173,
		pop: 37162,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Dakota',
		state_ansi: 'ND',
		timezone: 'America/Chicago',
	},
	{
		city: 'Fargo',
		city_ascii: 'Fargo',
		lat: 46.8772278,
		lng: -96.7894257,
		pop: 127472.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Dakota',
		state_ansi: 'ND',
		timezone: 'America/Chicago',
	},
	{
		city: 'Hilo',
		city_ascii: 'Hilo',
		lat: 19.69999778,
		lng: -155.0900273,
		pop: 47720.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Hawaii',
		state_ansi: 'HI',
		timezone: 'Pacific/Honolulu',
	},
	{
		city: 'Olympia',
		city_ascii: 'Olympia',
		lat: 47.03804486,
		lng: -122.899434,
		pop: 100950,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Spokane',
		city_ascii: 'Spokane',
		lat: 47.66999595,
		lng: -117.4199494,
		pop: 272483.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Vancouver',
		city_ascii: 'Vancouver',
		lat: 45.63030133,
		lng: -122.6399925,
		pop: 343796.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Flagstaff',
		city_ascii: 'Flagstaff',
		lat: 35.19809572,
		lng: -111.6505083,
		pop: 60779.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Tucson',
		city_ascii: 'Tucson',
		lat: 32.20499676,
		lng: -110.8899862,
		pop: 670953.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'Santa Barbara',
		city_ascii: 'Santa Barbara',
		lat: 34.43498985,
		lng: -119.7199899,
		pop: 135021,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Fresno',
		city_ascii: 'Fresno',
		lat: 36.7477169,
		lng: -119.7729841,
		pop: 540768,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Eureka',
		city_ascii: 'Eureka',
		lat: 40.80222394,
		lng: -124.1474974,
		pop: 34012,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Colorado Springs',
		city_ascii: 'Colorado Springs',
		lat: 38.86296246,
		lng: -104.7919863,
		pop: 427272,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Colorado',
		state_ansi: 'CO',
		timezone: 'America/Denver',
	},
	{
		city: 'Reno',
		city_ascii: 'Reno',
		lat: 39.52997601,
		lng: -119.8200096,
		pop: 265363.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nevada',
		state_ansi: 'NV',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Elko',
		city_ascii: 'Elko',
		lat: 40.83250633,
		lng: -115.7619886,
		pop: 17537,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nevada',
		state_ansi: 'NV',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Albuquerque',
		city_ascii: 'Albuquerque',
		lat: 35.10497479,
		lng: -106.6413308,
		pop: 725723,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Salem',
		city_ascii: 'Salem',
		lat: 44.92807029,
		lng: -123.0238967,
		pop: 187966,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Casper',
		city_ascii: 'Casper',
		lat: 42.86661989,
		lng: -106.3124878,
		pop: 56149,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wyoming',
		state_ansi: 'WY',
		timezone: 'America/Denver',
	},
	{
		city: 'Topeka',
		city_ascii: 'Topeka',
		lat: 39.05000531,
		lng: -95.66998499,
		pop: 126830.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Kansas',
		state_ansi: 'KS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Kansas City',
		city_ascii: 'Kansas City',
		lat: 39.10708851,
		lng: -94.60409422,
		pop: 955272.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Missouri',
		state_ansi: 'MO',
		timezone: 'America/Chicago',
	},
	{
		city: 'Tulsa',
		city_ascii: 'Tulsa',
		lat: 36.12000327,
		lng: -95.93002079,
		pop: 669434,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oklahoma',
		state_ansi: 'OK',
		timezone: 'America/Chicago',
	},
	{
		city: 'Sioux Falls',
		city_ascii: 'Sioux Falls',
		lat: 43.54998903,
		lng: -96.7299978,
		pop: 148030,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Dakota',
		state_ansi: 'SD',
		timezone: 'America/Chicago',
	},
	{
		city: 'Shreveport',
		city_ascii: 'Shreveport',
		lat: 32.50001752,
		lng: -93.77002344,
		pop: 224099,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Louisiana',
		state_ansi: 'LA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Baton Rouge',
		city_ascii: 'Baton Rouge',
		lat: 30.45794578,
		lng: -91.14015812,
		pop: 322710.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Louisiana',
		state_ansi: 'LA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Ft. Worth',
		city_ascii: 'Ft. Worth',
		lat: 32.73997703,
		lng: -97.34003809,
		pop: 1090830,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Corpus Christi',
		city_ascii: 'Corpus Christi',
		lat: 27.74281435,
		lng: -97.40189478,
		pop: 249977.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Austin',
		city_ascii: 'Austin',
		lat: 30.26694969,
		lng: -97.74277836,
		pop: 919684,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Amarillo',
		city_ascii: 'Amarillo',
		lat: 35.22998008,
		lng: -101.8299966,
		pop: 178526,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'El Paso',
		city_ascii: 'El Paso',
		lat: 31.77998395,
		lng: -106.5099952,
		pop: 658331,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Denver',
	},
	{
		city: 'Laredo',
		city_ascii: 'Laredo',
		lat: 27.50613629,
		lng: -99.50721847,
		pop: 322768.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Burlington',
		city_ascii: 'Burlington',
		lat: 44.47579816,
		lng: -73.21246688,
		pop: 66204,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Vermont',
		state_ansi: 'VT',
		timezone: 'America/New_York',
	},
	{
		city: 'Montgomery',
		city_ascii: 'Montgomery',
		lat: 32.36160219,
		lng: -86.27918868,
		pop: 194491.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alabama',
		state_ansi: 'AL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Tallahassee',
		city_ascii: 'Tallahassee',
		lat: 30.44998761,
		lng: -84.28003422,
		pop: 187402.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Orlando',
		city_ascii: 'Orlando',
		lat: 28.50997683,
		lng: -81.38003036,
		pop: 778985,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Jacksonville',
		city_ascii: 'Jacksonville',
		lat: 30.33002077,
		lng: -81.66998682,
		pop: 904953.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Savannah',
		city_ascii: 'Savannah',
		lat: 32.02110618,
		lng: -81.10999516,
		pop: 155848.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Georgia',
		state_ansi: 'GA',
		timezone: 'America/New_York',
	},
	{
		city: 'Columbia',
		city_ascii: 'Columbia',
		lat: 34.0399752,
		lng: -80.89998214,
		pop: 257185.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Carolina',
		state_ansi: 'SC',
		timezone: 'America/New_York',
	},
	{
		city: 'Indianapolis',
		city_ascii: 'Indianapolis',
		lat: 39.74998842,
		lng: -86.17004806,
		pop: 1104641.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Indiana',
		state_ansi: 'IN',
		timezone: 'America/Indiana/Indianapolis',
	},
	{
		city: 'Wilmington',
		city_ascii: 'Wilmington',
		lat: 34.22551943,
		lng: -77.94502039,
		pop: 126992,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Knoxville',
		city_ascii: 'Knoxville',
		lat: 35.97001243,
		lng: -83.92003036,
		pop: 417137,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Tennessee',
		state_ansi: 'TN',
		timezone: 'America/New_York',
	},
	{
		city: 'Richmond',
		city_ascii: 'Richmond',
		lat: 37.55001935,
		lng: -77.449986,
		pop: 551443,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Virginia',
		state_ansi: 'VA',
		timezone: 'America/New_York',
	},
	{
		city: 'Charleston',
		city_ascii: 'Charleston',
		lat: 38.34973798,
		lng: -81.63272811,
		pop: 87113,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'West Virginia',
		state_ansi: 'WV',
		timezone: 'America/New_York',
	},
	{
		city: 'Baltimore',
		city_ascii: 'Baltimore',
		lat: 39.29999005,
		lng: -76.61998499,
		pop: 1432946,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Maryland',
		state_ansi: 'MD',
		timezone: 'America/New_York',
	},
	{
		city: 'Syracuse',
		city_ascii: 'Syracuse',
		lat: 43.04999371,
		lng: -76.15001367,
		pop: 403873.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'Augusta',
		city_ascii: 'Augusta',
		lat: 44.31056276,
		lng: -69.77998906,
		pop: 21301,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Maine',
		state_ansi: 'ME',
		timezone: 'America/New_York',
	},
	{
		city: 'Sault Ste. Marie',
		city_ascii: 'Sault Ste. Marie',
		lat: 46.49526145,
		lng: -84.34527572,
		pop: 50173.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Sitka',
		city_ascii: 'Sitka',
		lat: 57.06039769,
		lng: -135.3275494,
		pop: 8110,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Sitka',
	},
	{
		city: 'Helena',
		city_ascii: 'Helena',
		lat: 46.59274904,
		lng: -112.035291,
		pop: 33032.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Montana',
		state_ansi: 'MT',
		timezone: 'America/Denver',
	},
	{
		city: 'Bismarck',
		city_ascii: 'Bismarck',
		lat: 46.80831728,
		lng: -100.7833163,
		pop: 60288.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Dakota',
		state_ansi: 'ND',
		timezone: 'America/Chicago',
	},
	{
		city: 'Boise',
		city_ascii: 'Boise',
		lat: 43.60859011,
		lng: -116.2274899,
		pop: 242029,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Idaho',
		state_ansi: 'ID',
		timezone: 'America/Boise',
	},
	{
		city: 'San Jose',
		city_ascii: 'San Jose',
		lat: 37.29998293,
		lng: -121.8499891,
		pop: 1281471.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Sacramento',
		city_ascii: 'Sacramento',
		lat: 38.57502138,
		lng: -121.4700381,
		pop: 1035949,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Las Vegas',
		city_ascii: 'Las Vegas',
		lat: 36.20999778,
		lng: -115.2200061,
		pop: 1150717,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nevada',
		state_ansi: 'NV',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Santa Fe',
		city_ascii: 'Santa Fe',
		lat: 35.68692893,
		lng: -105.9372394,
		pop: 80943,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New Mexico',
		state_ansi: 'NM',
		timezone: 'America/Denver',
	},
	{
		city: 'Portland',
		city_ascii: 'Portland',
		lat: 45.52002382,
		lng: -122.6799901,
		pop: 1207756.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oregon',
		state_ansi: 'OR',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Salt Lake City',
		city_ascii: 'Salt Lake City',
		lat: 40.7750163,
		lng: -111.9300519,
		pop: 572013,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Utah',
		state_ansi: 'UT',
		timezone: 'America/Denver',
	},
	{
		city: 'Cheyenne',
		city_ascii: 'Cheyenne',
		lat: 41.14000694,
		lng: -104.8197107,
		pop: 64185,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wyoming',
		state_ansi: 'WY',
		timezone: 'America/Denver',
	},
	{
		city: 'Des Moines',
		city_ascii: 'Des Moines',
		lat: 41.57998008,
		lng: -93.61998092,
		pop: 286917.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Iowa',
		state_ansi: 'IA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Omaha',
		city_ascii: 'Omaha',
		lat: 41.24000083,
		lng: -96.00999007,
		pop: 643034,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Nebraska',
		state_ansi: 'NE',
		timezone: 'America/Chicago',
	},
	{
		city: 'Oklahoma City',
		city_ascii: 'Oklahoma City',
		lat: 35.47004295,
		lng: -97.51868351,
		pop: 660475,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Oklahoma',
		state_ansi: 'OK',
		timezone: 'America/Chicago',
	},
	{
		city: 'Pierre',
		city_ascii: 'Pierre',
		lat: 44.36833701,
		lng: -100.350552,
		pop: 13734.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'South Dakota',
		state_ansi: 'SD',
		timezone: 'America/Chicago',
	},
	{
		city: 'San Antonio',
		city_ascii: 'San Antonio',
		lat: 29.48733319,
		lng: -98.50730534,
		pop: 1364905,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Jackson',
		city_ascii: 'Jackson',
		lat: 32.29881533,
		lng: -90.18499679,
		pop: 213799,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Mississippi',
		state_ansi: 'MS',
		timezone: 'America/Chicago',
	},
	{
		city: 'Raleigh',
		city_ascii: 'Raleigh',
		lat: 35.81878135,
		lng: -78.64469344,
		pop: 789991.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'North Carolina',
		state_ansi: 'NC',
		timezone: 'America/New_York',
	},
	{
		city: 'Cleveland',
		city_ascii: 'Cleveland',
		lat: 41.4699868,
		lng: -81.69499821,
		pop: 1169757,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Ohio',
		state_ansi: 'OH',
		timezone: 'America/New_York',
	},
	{
		city: 'Cincinnati',
		city_ascii: 'Cincinnati',
		lat: 39.16188479,
		lng: -84.45692265,
		pop: 971191,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Ohio',
		state_ansi: 'OH',
		timezone: 'America/New_York',
	},
	{
		city: 'Nashville',
		city_ascii: 'Nashville',
		lat: 36.16997438,
		lng: -86.77998499,
		pop: 703926,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Tennessee',
		state_ansi: 'TN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Memphis',
		city_ascii: 'Memphis',
		lat: 35.1199868,
		lng: -89.99999516,
		pop: 753843.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Tennessee',
		state_ansi: 'TN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Norfolk',
		city_ascii: 'Norfolk',
		lat: 36.84995872,
		lng: -76.28000574,
		pop: 645336,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Virginia',
		state_ansi: 'VA',
		timezone: 'America/New_York',
	},
	{
		city: 'Milwaukee',
		city_ascii: 'Milwaukee',
		lat: 43.05265505,
		lng: -87.91996708,
		pop: 983590,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Wisconsin',
		state_ansi: 'WI',
		timezone: 'America/Chicago',
	},
	{
		city: 'Buffalo',
		city_ascii: 'Buffalo',
		lat: 42.87997825,
		lng: -78.88000208,
		pop: 647778.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'Pittsburgh',
		city_ascii: 'Pittsburgh',
		lat: 40.4299986,
		lng: -79.99998539,
		pop: 1535267.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Pennsylvania',
		state_ansi: 'PA',
		timezone: 'America/New_York',
	},
	{
		city: 'Kodiak',
		city_ascii: 'Kodiak',
		lat: 57.78999839,
		lng: -152.4069869,
		pop: 7804.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Cold Bay',
		city_ascii: 'Cold Bay',
		lat: 55.20000144,
		lng: -162.7150916,
		pop: 154,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Bethel',
		city_ascii: 'Bethel',
		lat: 60.79330345,
		lng: -161.7557961,
		pop: 5440.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Point Hope',
		city_ascii: 'Point Hope',
		lat: 68.34772605,
		lng: -166.8080201,
		pop: 461,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Barrow',
		city_ascii: 'Barrow',
		lat: 71.29056968,
		lng: -156.78858,
		pop: 3412,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Nome',
		city_ascii: 'Nome',
		lat: 64.50610008,
		lng: -165.4063744,
		pop: 3021,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Nome',
	},
	{
		city: 'Valdez',
		city_ascii: 'Valdez',
		lat: 61.13599571,
		lng: -146.348287,
		pop: 3283,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Juneau',
		city_ascii: 'Juneau',
		lat: 58.31412661,
		lng: -134.419997,
		pop: 26172,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Juneau',
	},
	{
		city: 'Fairbanks',
		city_ascii: 'Fairbanks',
		lat: 64.83698427,
		lng: -147.7106586,
		pop: 43608.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Prudhoe Bay',
		city_ascii: 'Prudhoe Bay',
		lat: 70.29218101,
		lng: -148.6693598,
		pop: 2337,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'Minneapolis',
		city_ascii: 'Minneapolis',
		lat: 44.97997927,
		lng: -93.25178634,
		pop: 1491886.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Minnesota',
		state_ansi: 'MN',
		timezone: 'America/Chicago',
	},
	{
		city: 'Honolulu',
		city_ascii: 'Honolulu',
		lat: 21.30687644,
		lng: -157.8579979,
		pop: 578828.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Hawaii',
		state_ansi: 'HI',
		timezone: 'Pacific/Honolulu',
	},
	{
		city: 'Seattle',
		city_ascii: 'Seattle',
		lat: 47.57000205,
		lng: -122.339985,
		pop: 1821684.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Washington',
		state_ansi: 'WA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Phoenix',
		city_ascii: 'Phoenix',
		lat: 33.53997988,
		lng: -112.0699917,
		pop: 2436022.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Arizona',
		state_ansi: 'AZ',
		timezone: 'America/Phoenix',
	},
	{
		city: 'San Diego',
		city_ascii: 'San Diego',
		lat: 32.82002382,
		lng: -117.1799899,
		pop: 1938570.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'St. Louis',
		city_ascii: 'St. Louis',
		lat: 38.63501772,
		lng: -90.23998051,
		pop: 1259958,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Missouri',
		state_ansi: 'MO',
		timezone: 'America/Chicago',
	},
	{
		city: 'New Orleans',
		city_ascii: 'New Orleans',
		lat: 29.99500246,
		lng: -90.03996688,
		pop: 527428.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Louisiana',
		state_ansi: 'LA',
		timezone: 'America/Chicago',
	},
	{
		city: 'Dallas',
		city_ascii: 'Dallas',
		lat: 32.82002382,
		lng: -96.84001693,
		pop: 3004852,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Boston',
		city_ascii: 'Boston',
		lat: 42.32996014,
		lng: -71.07001367,
		pop: 2528070.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Massachusetts',
		state_ansi: 'MA',
		timezone: 'America/New_York',
	},
	{
		city: 'Tampa',
		city_ascii: 'Tampa',
		lat: 27.94698793,
		lng: -82.45862085,
		pop: 1319232.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Philadelphia',
		city_ascii: 'Philadelphia',
		lat: 39.99997316,
		lng: -75.16999597,
		pop: 3504775,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Pennsylvania',
		state_ansi: 'PA',
		timezone: 'America/New_York',
	},
	{
		city: 'Detroit',
		city_ascii: 'Detroit',
		lat: 42.32996014,
		lng: -83.08005579,
		pop: 2526135,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Michigan',
		state_ansi: 'MI',
		timezone: 'America/Detroit',
	},
	{
		city: 'Anchorage',
		city_ascii: 'Anchorage',
		lat: 61.21996991,
		lng: -149.9002149,
		pop: 252068,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Alaska',
		state_ansi: 'AK',
		timezone: 'America/Anchorage',
	},
	{
		city: 'San Francisco',
		city_ascii: 'San Francisco',
		lat: 37.74000775,
		lng: -122.4599777,
		pop: 2091036,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Denver',
		city_ascii: 'Denver',
		lat: 39.73918805,
		lng: -104.984016,
		pop: 1930799.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Colorado',
		state_ansi: 'CO',
		timezone: 'America/Denver',
	},
	{
		city: 'Houston',
		city_ascii: 'Houston',
		lat: 29.81997438,
		lng: -95.33997929,
		pop: 4053287,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Texas',
		state_ansi: 'TX',
		timezone: 'America/Chicago',
	},
	{
		city: 'Miami',
		city_ascii: 'Miami',
		lat: 25.7876107,
		lng: -80.22410608,
		pop: 2983947,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/New_York',
	},
	{
		city: 'Atlanta',
		city_ascii: 'Atlanta',
		lat: 33.83001385,
		lng: -84.39994938,
		pop: 2464454,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Georgia',
		state_ansi: 'GA',
		timezone: 'America/New_York',
	},
	{
		city: 'Chicago',
		city_ascii: 'Chicago',
		lat: 41.82999066,
		lng: -87.75005497,
		pop: 5915976,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'Illinois',
		exactCity: 'Chicago',
		exactProvince: 'IL',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
	{
		city: 'Los Angeles',
		city_ascii: 'Los Angeles',
		lat: 33.98997825,
		lng: -118.1799805,
		pop: 8097410,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'California',
		state_ansi: 'CA',
		timezone: 'America/Los_Angeles',
	},
	{
		city: 'Washington, D.C.',
		city_ascii: 'Washington, D.C.',
		lat: 38.89954938,
		lng: -77.00941858,
		pop: 2445216.5,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'District of Columbia',
		timezone: 'America/New_York',
	},
	{
		city: 'New York',
		city_ascii: 'New York',
		lat: 40.74997906,
		lng: -73.98001693,
		pop: 13524139,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		province: 'New York',
		state_ansi: 'NY',
		timezone: 'America/New_York',
	},
	{
		city: 'Christiansted',
		city_ascii: 'Christiansted',
		lat: 17.75037518,
		lng: -64.749986,
		pop: 32543,
		country: 'United States Virgin Islands',
		iso2: 'VI',
		iso3: 'VIR',
		province: 'Virgin Islands',
		state_ansi: 'VI',
		timezone: 'America/St_Thomas',
	},
	{
		city: 'Colonia del Sacramento',
		city_ascii: 'Colonia del Sacramento',
		lat: -34.47999901,
		lng: -57.84000247,
		pop: 21714,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Colonia',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Trinidad',
		city_ascii: 'Trinidad',
		lat: -33.54399894,
		lng: -56.90099656,
		pop: 21093,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Flores',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Fray Bentos',
		city_ascii: 'Fray Bentos',
		lat: -33.13899903,
		lng: -58.30399747,
		pop: 23279,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Río Negro',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Canelones',
		city_ascii: 'Canelones',
		lat: -34.53800401,
		lng: -56.28400149,
		pop: 19698,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Canelones',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Florida',
		city_ascii: 'Florida',
		lat: -34.09900201,
		lng: -56.21499845,
		pop: 32234,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Florida',
		state_ansi: 'FL',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Artigas',
		city_ascii: 'Artigas',
		lat: -30.41598712,
		lng: -56.48602014,
		pop: 32072.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Artigas',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Baltasar Brum',
		city_ascii: 'Baltasar Brum',
		lat: -30.7300248,
		lng: -57.31997441,
		pop: 2432,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Artigas',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Tranqueras',
		city_ascii: 'Tranqueras',
		lat: -31.20002195,
		lng: -55.74996688,
		pop: 4775.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Rivera',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Tacuarembo',
		city_ascii: 'Tacuarembo',
		lat: -31.70996499,
		lng: -55.98000452,
		pop: 53065.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Tacuarembó',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Paso de los Toros',
		city_ascii: 'Paso de los Toros',
		lat: -32.8100012,
		lng: -56.51004968,
		pop: 11450,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Tacuarembó',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Vergara',
		city_ascii: 'Vergara',
		lat: -32.92999388,
		lng: -53.94999923,
		pop: 3749,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Treinta y Tres',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Treinta y Tres',
		city_ascii: 'Treinta y Tres',
		lat: -33.23002724,
		lng: -54.38002466,
		pop: 26668.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Treinta y Tres',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Santa Lucia',
		city_ascii: 'Santa Lucia',
		lat: -34.47000324,
		lng: -56.39997888,
		pop: 15264.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Canelones',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Jose Batlle y Ordonez',
		city_ascii: 'Jose Batlle y Ordonez',
		lat: -33.47001259,
		lng: -55.12000533,
		pop: 2438,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Lavalleja',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Minas',
		city_ascii: 'Minas',
		lat: -34.37000934,
		lng: -55.23002446,
		pop: 39602.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Lavalleja',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Maldonado',
		city_ascii: 'Maldonado',
		lat: -34.91002806,
		lng: -54.95998926,
		pop: 51877.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Maldonado',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Punta del Este',
		city_ascii: 'Punta del Este',
		lat: -34.96997272,
		lng: -54.94998987,
		pop: 84140,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Maldonado',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Aigua',
		city_ascii: 'Aigua',
		lat: -34.19999388,
		lng: -54.75000208,
		pop: 2622,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Maldonado',
		timezone: 'America/Montevideo',
	},
	{
		city: 'La Paloma',
		city_ascii: 'La Paloma',
		lat: -34.66999103,
		lng: -54.1699858,
		pop: 2897,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Rocha',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Carmelo',
		city_ascii: 'Carmelo',
		lat: -33.98961912,
		lng: -58.29999211,
		pop: 15113.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Colonia',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Bella Union',
		city_ascii: 'Bella Union',
		lat: -30.25961424,
		lng: -57.59995732,
		pop: 17947,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Artigas',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Mercedes',
		city_ascii: 'Mercedes',
		lat: -33.25953449,
		lng: -58.02998275,
		pop: 41951.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Soriano',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Melo',
		city_ascii: 'Melo',
		lat: -32.35948606,
		lng: -54.17998519,
		pop: 53258.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Cerro Largo',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Rivera',
		city_ascii: 'Rivera',
		lat: -30.89957518,
		lng: -55.56000431,
		pop: 132232.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Rivera',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Lascano',
		city_ascii: 'Lascano',
		lat: -33.66958698,
		lng: -54.20000981,
		pop: 5215.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Rocha',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Castillos',
		city_ascii: 'Castillos',
		lat: -34.16960814,
		lng: -53.82998071,
		pop: 7686,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Rocha',
		timezone: 'America/Montevideo',
	},
	{
		city: 'San Jose de Mayo',
		city_ascii: 'San Jose de Mayo',
		lat: -34.34995888,
		lng: -56.7099858,
		pop: 36462,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'San José',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Rocha',
		city_ascii: 'Rocha',
		lat: -34.48297402,
		lng: -54.33302494,
		pop: 26194.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Rocha',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Paysandu',
		city_ascii: 'Paysandu',
		lat: -32.32997882,
		lng: -58.0799797,
		pop: 76132.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Paysandú',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Salto',
		city_ascii: 'Salto',
		lat: -31.39034625,
		lng: -57.9686945,
		pop: 102756.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Salto',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Durazno',
		city_ascii: 'Durazno',
		lat: -33.41001626,
		lng: -56.51004968,
		pop: 33981.5,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Durazno',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Montevideo',
		city_ascii: 'Montevideo',
		lat: -34.85804157,
		lng: -56.17105229,
		pop: 759162,
		country: 'Uruguay',
		iso2: 'UY',
		iso3: 'URY',
		province: 'Montevideo',
		timezone: 'America/Montevideo',
	},
	{
		city: 'Khujayli',
		city_ascii: 'Khujayli',
		lat: 42.4047101,
		lng: 59.45165767,
		pop: 55200.5,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Karakalpakstan',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Urgut',
		city_ascii: 'Urgut',
		lat: 39.40070742,
		lng: 67.26069006,
		pop: 73524,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Samarkand',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Kattaqorgon',
		city_ascii: 'Kattaqorgon',
		lat: 39.90070274,
		lng: 66.2607511,
		pop: 153247.5,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Samarkand',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Denow',
		city_ascii: 'Denow',
		lat: 38.27715843,
		lng: 67.88716345,
		pop: 143134,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Surkhandarya',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Guliston',
		city_ascii: 'Guliston',
		lat: 40.49573102,
		lng: 68.79072587,
		pop: 74446.5,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Sirdaryo',
		timezone: 'Asia/Tashkent',
	},
	{
		city: 'Iskandar',
		city_ascii: 'Iskandar',
		lat: 41.55073122,
		lng: 69.68074906,
		pop: 111634.5,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Tashkent',
		timezone: 'Asia/Tashkent',
	},
	{
		city: 'Chirchiq',
		city_ascii: 'Chirchiq',
		lat: 41.45497479,
		lng: 69.55998124,
		pop: 155093.5,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Tashkent',
		timezone: 'Asia/Tashkent',
	},
	{
		city: 'Kogon',
		city_ascii: 'Kogon',
		lat: 39.72107546,
		lng: 64.54576534,
		pop: 85093,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Bukhoro',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Khiwa',
		city_ascii: 'Khiwa',
		lat: 41.39112856,
		lng: 60.35573686,
		pop: 102659,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Khorezm',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Chimboy',
		city_ascii: 'Chimboy',
		lat: 42.93113792,
		lng: 59.77075964,
		pop: 34277.5,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Karakalpakstan',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Qunghirot',
		city_ascii: 'Qunghirot',
		lat: 43.0704059,
		lng: 58.90001176,
		pop: 57758,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Karakalpakstan',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Zarafshon',
		city_ascii: 'Zarafshon',
		lat: 41.58222801,
		lng: 64.20180701,
		pop: 55292.5,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Navoi',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Navoi',
		city_ascii: 'Navoi',
		lat: 40.1104057,
		lng: 65.35496659,
		pop: 172276.5,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Navoi',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Shahrisabz',
		city_ascii: 'Shahrisabz',
		lat: 39.06178754,
		lng: 66.83146562,
		pop: 277798,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Kashkadarya',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Qarshi',
		city_ascii: 'Qarshi',
		lat: 38.87042971,
		lng: 65.80000403,
		pop: 304629.5,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Kashkadarya',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Qoqon',
		city_ascii: 'Qoqon',
		lat: 40.54040529,
		lng: 70.94000037,
		pop: 271250,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Ferghana',
		timezone: 'Asia/Tashkent',
	},
	{
		city: 'Jizzax',
		city_ascii: 'Jizzax',
		lat: 40.10038047,
		lng: 67.83000932,
		pop: 193997,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Jizzakh',
		timezone: 'Asia/Tashkent',
	},
	{
		city: 'Angren',
		city_ascii: 'Angren',
		lat: 41.03037539,
		lng: 70.15493201,
		pop: 164513.5,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Tashkent',
		timezone: 'Asia/Tashkent',
	},
	{
		city: 'Olmaliq',
		city_ascii: 'Olmaliq',
		lat: 40.85043805,
		lng: 69.59501786,
		pop: 119768,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Tashkent',
		timezone: 'Asia/Tashkent',
	},
	{
		city: 'Muynoq',
		city_ascii: 'Muynoq',
		lat: 43.76832196,
		lng: 59.0213997,
		pop: 13000,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Karakalpakstan',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Termiz',
		city_ascii: 'Termiz',
		lat: 37.23293276,
		lng: 67.27293738,
		pop: 159036,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Surkhandarya',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Fargona',
		city_ascii: 'Fargona',
		lat: 40.3899752,
		lng: 71.78000077,
		pop: 482000,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Ferghana',
		timezone: 'Asia/Tashkent',
	},
	{
		city: 'Namangan',
		city_ascii: 'Namangan',
		lat: 41.00001548,
		lng: 71.66998165,
		pop: 599600,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Namangan',
		timezone: 'Asia/Tashkent',
	},
	{
		city: 'Urgentch',
		city_ascii: 'Urgentch',
		lat: 41.5599813,
		lng: 60.64000891,
		pop: 126476.5,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Khorezm',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Bukhara',
		city_ascii: 'Bukhara',
		lat: 39.78001243,
		lng: 64.43001013,
		pop: 283560,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Bukhoro',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Nukus',
		city_ascii: 'Nukus',
		lat: 42.47000327,
		lng: 59.61500688,
		pop: 228211,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Karakalpakstan',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Andijon',
		city_ascii: 'Andijon',
		lat: 40.79000246,
		lng: 72.33996659,
		pop: 486950,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Andijon',
		timezone: 'Asia/Tashkent',
	},
	{
		city: 'Samarqand',
		city_ascii: 'Samarqand',
		lat: 39.67001914,
		lng: 66.94499874,
		pop: 652150,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Samarkand',
		timezone: 'Asia/Samarkand',
	},
	{
		city: 'Tashkent',
		city_ascii: 'Tashkent',
		lat: 41.31170188,
		lng: 69.29493282,
		pop: 2081014,
		country: 'Uzbekistan',
		iso2: 'UZ',
		iso3: 'UZB',
		province: 'Tashkent',
		timezone: 'Asia/Tashkent',
	},
	{
		city: 'Luganville',
		city_ascii: 'Luganville',
		lat: -15.51255573,
		lng: 167.1766068,
		pop: 10634.5,
		country: 'Vanuatu',
		iso2: 'VU',
		iso3: 'VUT',
		province: 'Sanma',
		timezone: 'Pacific/Efate',
	},
	{
		city: 'Port Vila',
		city_ascii: 'Port-Vila',
		lat: -17.7333504,
		lng: 168.3166406,
		pop: 39970.5,
		country: 'Vanuatu',
		iso2: 'VU',
		iso3: 'VUT',
		province: 'Shefa',
		timezone: 'Pacific/Efate',
	},
	{
		city: 'Vatican City',
		city_ascii: 'Vatican City',
		lat: 41.90001223,
		lng: 12.44780839,
		pop: 832,
		country: 'Vatican (Holy Sea)',
		iso2: 'VA',
		iso3: 'VAT',
		province: 'Lazio',
		timezone: 'Europe/Rome',
	},
	{
		city: 'San Carlos',
		city_ascii: 'San Carlos',
		lat: 9.657999007,
		lng: -68.58999854,
		pop: 77192,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Cojedes',
		timezone: 'America/Caracas',
	},
	{
		city: 'San Felipe',
		city_ascii: 'San Felipe',
		lat: 10.33599598,
		lng: -68.74599552,
		pop: 76766,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Yaracuy',
		timezone: 'America/Caracas',
	},
	{
		city: 'San Juan De Los Morros',
		city_ascii: 'San Juan De Los Morros',
		lat: 9.900999019,
		lng: -67.35400159,
		pop: 87739,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Guárico',
		timezone: 'America/Caracas',
	},
	{
		city: 'La Asuncion',
		city_ascii: 'La Asuncion',
		lat: 11.03333403,
		lng: -63.8833315,
		pop: 35084,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Nueva Esparta',
		timezone: 'America/Caracas',
	},
	{
		city: 'Guasdualito',
		city_ascii: 'Guasdualito',
		lat: 7.239983133,
		lng: -70.73998214,
		pop: 28287.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Apure',
		timezone: 'America/Caracas',
	},
	{
		city: 'Barinas',
		city_ascii: 'Barinas',
		lat: 8.59997764,
		lng: -70.25001205,
		pop: 261405.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Barinas',
		timezone: 'America/Caracas',
	},
	{
		city: 'Valera',
		city_ascii: 'Valera',
		lat: 9.319959533,
		lng: -70.6200153,
		pop: 142461.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Trujillo',
		timezone: 'America/Caracas',
	},
	{
		city: 'Cabimas',
		city_ascii: 'Cabimas',
		lat: 10.42999514,
		lng: -71.44999048,
		pop: 320956,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Trujillo',
		timezone: 'America/Caracas',
	},
	{
		city: 'Carora',
		city_ascii: 'Carora',
		lat: 10.18998395,
		lng: -70.07999658,
		pop: 121749.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Lara',
		timezone: 'America/Caracas',
	},
	{
		city: 'Guanare',
		city_ascii: 'Guanare',
		lat: 9.049976012,
		lng: -69.75001673,
		pop: 131964,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Portuguesa',
		timezone: 'America/Caracas',
	},
	{
		city: 'Puerto la Cruz',
		city_ascii: 'Puerto la Cruz',
		lat: 10.16995933,
		lng: -64.68001612,
		pop: 500464,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Anzoátegui',
		timezone: 'America/Caracas',
	},
	{
		city: 'Anaco',
		city_ascii: 'Anaco',
		lat: 9.440003885,
		lng: -64.4600037,
		pop: 100118,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Anzoátegui',
		timezone: 'America/Caracas',
	},
	{
		city: 'Los Teques',
		city_ascii: 'Los Teques',
		lat: 10.41996991,
		lng: -67.02002832,
		pop: 303470,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Distrito Capital',
		timezone: 'America/Caracas',
	},
	{
		city: 'Valle de la Pascua',
		city_ascii: 'Valle de la Pascua',
		lat: 9.209992085,
		lng: -66.019986,
		pop: 86273.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Guárico',
		timezone: 'America/Caracas',
	},
	{
		city: 'Ocumare del Tuy',
		city_ascii: 'Ocumare del Tuy',
		lat: 10.11998822,
		lng: -66.77999129,
		pop: 130824,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Miranda',
		timezone: 'America/Caracas',
	},
	{
		city: 'Carupano',
		city_ascii: 'Carupano',
		lat: 10.67000633,
		lng: -63.23000126,
		pop: 119187.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Sucre',
		timezone: 'America/Caracas',
	},
	{
		city: 'Trujillo',
		city_ascii: 'Trujillo',
		lat: 9.38039512,
		lng: -70.44000045,
		pop: 44231.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Trujillo',
		timezone: 'America/Caracas',
	},
	{
		city: 'Santa Rita',
		city_ascii: 'Santa Rita',
		lat: 10.53182698,
		lng: -71.50394902,
		pop: 21095,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Trujillo',
		timezone: 'America/Caracas',
	},
	{
		city: 'Machiques',
		city_ascii: 'Machiques',
		lat: 10.07043052,
		lng: -72.54994918,
		pop: 44936.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Zulia',
		timezone: 'America/Caracas',
	},
	{
		city: 'San Carlos del Zulia',
		city_ascii: 'San Carlos del Zulia',
		lat: 9.010391865,
		lng: -71.91998763,
		pop: 76935,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Zulia',
		timezone: 'America/Caracas',
	},
	{
		city: 'Puerto Cabello',
		city_ascii: 'Puerto Cabello',
		lat: 10.47043194,
		lng: -68.17000981,
		pop: 174000,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Carabobo',
		timezone: 'America/Caracas',
	},
	{
		city: 'Acarigua',
		city_ascii: 'Acarigua',
		lat: 9.580382913,
		lng: -69.20002446,
		pop: 202312.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Portuguesa',
		timezone: 'America/Caracas',
	},
	{
		city: 'Upata',
		city_ascii: 'Upata',
		lat: 8.020426452,
		lng: -62.40999964,
		pop: 53474.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Bolívar',
		timezone: 'America/Caracas',
	},
	{
		city: 'El Manteco',
		city_ascii: 'El Manteco',
		lat: 7.35038983,
		lng: -62.53332544,
		pop: 2215,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Bolívar',
		timezone: 'America/Caracas',
	},
	{
		city: 'Chaguaramas',
		city_ascii: 'Chaguaramas',
		lat: 9.215934874,
		lng: -63.75226913,
		pop: 12500,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Anzoátegui',
		timezone: 'America/Caracas',
	},
	{
		city: 'Barcelona',
		city_ascii: 'Barcelona',
		lat: 10.13037518,
		lng: -64.72001367,
		pop: 361430,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Anzoátegui',
		timezone: 'America/Caracas',
	},
	{
		city: 'El Tigre',
		city_ascii: 'El Tigre',
		lat: 8.890347513,
		lng: -64.26001591,
		pop: 174219.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Anzoátegui',
		timezone: 'America/Caracas',
	},
	{
		city: 'Maiquetia',
		city_ascii: 'Maiquetia',
		lat: 10.60039817,
		lng: -66.9699797,
		pop: 184003,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Vargas',
		timezone: 'America/Caracas',
	},
	{
		city: 'Calabozo',
		city_ascii: 'Calabozo',
		lat: 8.930396748,
		lng: -67.43997685,
		pop: 110907,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Guárico',
		timezone: 'America/Caracas',
	},
	{
		city: 'Zaraza',
		city_ascii: 'Zaraza',
		lat: 9.340397562,
		lng: -65.32000289,
		pop: 35279.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Guárico',
		timezone: 'America/Caracas',
	},
	{
		city: 'Altagracia de Orituco',
		city_ascii: 'Altagracia de Orituco',
		lat: 9.85041811,
		lng: -66.37998987,
		pop: 34427,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Guárico',
		timezone: 'America/Caracas',
	},
	{
		city: 'Tucupita',
		city_ascii: 'Tucupita',
		lat: 9.060492166,
		lng: -62.05999516,
		pop: 49704,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Monagas',
		timezone: 'America/Caracas',
	},
	{
		city: 'Porlamar',
		city_ascii: 'Porlamar',
		lat: 10.9603762,
		lng: -63.84998926,
		pop: 142027,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Nueva Esparta',
		timezone: 'America/Caracas',
	},
	{
		city: 'San Fernando de Apure',
		city_ascii: 'San Fernando de Apure',
		lat: 7.899994526,
		lng: -67.46994918,
		pop: 100740,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Apure',
		timezone: 'America/Caracas',
	},
	{
		city: 'Barquisimeto',
		city_ascii: 'Barquisimeto',
		lat: 10.04999249,
		lng: -69.29996668,
		pop: 962745,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Lara',
		timezone: 'America/Caracas',
	},
	{
		city: 'Maturin',
		city_ascii: 'Maturin',
		lat: 9.749959126,
		lng: -63.17003076,
		pop: 357707.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Monagas',
		timezone: 'America/Caracas',
	},
	{
		city: 'Cumana',
		city_ascii: 'Cumana',
		lat: 10.44999392,
		lng: -64.18002079,
		pop: 287693,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Sucre',
		timezone: 'America/Caracas',
	},
	{
		city: 'Coro',
		city_ascii: 'Coro',
		lat: 11.42001223,
		lng: -69.67999516,
		pop: 184098,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Falcón',
		timezone: 'America/Caracas',
	},
	{
		city: 'Punto Fijo',
		city_ascii: 'Punto Fijo',
		lat: 11.71999392,
		lng: -70.21001449,
		pop: 183260,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Falcón',
		timezone: 'America/Caracas',
	},
	{
		city: 'La Esmeralda',
		city_ascii: 'La Esmeralda',
		lat: 3.173823058,
		lng: -65.54660405,
		pop: 149.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Amazonas',
		timezone: 'America/Caracas',
	},
	{
		city: 'Ciudad Bolivar',
		city_ascii: 'Ciudad Bolivar',
		lat: 8.099982319,
		lng: -63.60000452,
		pop: 317971.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Bolívar',
		timezone: 'America/Caracas',
	},
	{
		city: 'El Dorado',
		city_ascii: 'El Dorado',
		lat: 6.733295714,
		lng: -61.6333287,
		pop: 2383,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Bolívar',
		timezone: 'America/Caracas',
	},
	{
		city: 'Maracay',
		city_ascii: 'Maracay',
		lat: 10.2468797,
		lng: -67.59580713,
		pop: 1007000,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Aragua',
		timezone: 'America/Caracas',
	},
	{
		city: 'Merida',
		city_ascii: 'Merida',
		lat: 8.399989847,
		lng: -71.13001001,
		pop: 275184,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Mérida',
		timezone: 'America/Caracas',
	},
	{
		city: 'Puerto Ayacucho',
		city_ascii: 'Puerto Ayacucho',
		lat: 5.663903624,
		lng: -67.62360905,
		pop: 51622.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Amazonas',
		timezone: 'America/Caracas',
	},
	{
		city: 'San Cristobal',
		city_ascii: 'San Cristobal',
		lat: 7.770002461,
		lng: -72.24996749,
		pop: 342690.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Táchira',
		timezone: 'America/Caracas',
	},
	{
		city: 'Valencia',
		city_ascii: 'Valencia',
		lat: 10.22998151,
		lng: -67.9800214,
		pop: 1569526.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Carabobo',
		timezone: 'America/Caracas',
	},
	{
		city: 'Ciudad Guayana',
		city_ascii: 'Ciudad Guayana',
		lat: 8.370017516,
		lng: -62.61998682,
		pop: 634317.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Bolívar',
		timezone: 'America/Caracas',
	},
	{
		city: 'Maracaibo',
		city_ascii: 'Maracaibo',
		lat: 10.72997683,
		lng: -71.65997766,
		pop: 1764650,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Zulia',
		timezone: 'America/Caracas',
	},
	{
		city: 'Caracas',
		city_ascii: 'Caracas',
		lat: 10.50099855,
		lng: -66.91703719,
		pop: 2400339.5,
		country: 'Venezuela',
		iso2: 'VE',
		iso3: 'VEN',
		province: 'Distrito Capital',
		timezone: 'America/Caracas',
	},
	{
		city: 'Tay Ninh',
		city_ascii: 'Tay Ninh',
		lat: 11.32299911,
		lng: 106.1469997,
		pop: 126370,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Tây Ninh',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Luan Chau',
		city_ascii: 'Luan Chau',
		lat: 21.74000399,
		lng: 103.3430047,
		pop: 7335,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Điện Biên',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Bac Kan',
		city_ascii: 'Bac Kan',
		lat: 22.1333333,
		lng: 105.8333333,
		pop: 29227,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Bac Kan',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Lang Son',
		city_ascii: 'Lang Son',
		lat: 21.8459971,
		lng: 106.7570016,
		pop: 148000,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Lạng Sơn',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Son La',
		city_ascii: 'Son La',
		lat: 21.32800214,
		lng: 103.9100047,
		pop: 19054,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Son La',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Tuyen Quang',
		city_ascii: 'Tuyen Quang',
		lat: 21.8179981,
		lng: 105.2109996,
		pop: 36430,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Tuyên Quang',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Yen Bai',
		city_ascii: 'Yen Bai',
		lat: 21.70500304,
		lng: 104.8750026,
		pop: 96540,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Yên Bái',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Hai Duong',
		city_ascii: 'Hai Duong',
		lat: 20.94200108,
		lng: 106.3310046,
		pop: 58030,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Hải Dương',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Thai Binh',
		city_ascii: 'Thai Binh',
		lat: 20.45030412,
		lng: 106.3330296,
		pop: 210000,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Thái Bình',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Tuy Hoa',
		city_ascii: 'Tuy Hoa',
		lat: 13.08200402,
		lng: 109.3159987,
		pop: 69596,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Phú Yên',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Thu Dau Mot',
		city_ascii: 'Thu Dau Mot',
		lat: 10.96907408,
		lng: 106.6527455,
		pop: 244277,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Bình Duong',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Dong Ha',
		city_ascii: 'Dong Ha',
		lat: 16.8499981,
		lng: 107.1333007,
		pop: 17662,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Quảng Trị',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Cao Lanh',
		city_ascii: 'Cao Lanh',
		lat: 10.46700013,
		lng: 105.6359976,
		pop: 149837,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Đồng Tháp',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Truc Giang',
		city_ascii: 'Truc Giang',
		lat: 10.234998,
		lng: 106.3749966,
		pop: 59442,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Bến Tre',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Tra Vinh',
		city_ascii: 'Tra Vinh',
		lat: 9.934002087,
		lng: 106.3340017,
		pop: 131360,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Trà Vinh',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Vinh Long',
		city_ascii: 'Vinh Long',
		lat: 10.256004,
		lng: 105.9640026,
		pop: 103314,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Vinh Long',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Cao Bang',
		city_ascii: 'Cao Bang',
		lat: 22.66399806,
		lng: 106.2680046,
		pop: 41112,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Cao Bằng',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Hong Gai',
		city_ascii: 'Hong Gai',
		lat: 20.9604118,
		lng: 107.1000154,
		pop: 160490.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Quảng Ninh',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Cam Pha',
		city_ascii: 'Cam Pha',
		lat: 21.04043276,
		lng: 107.320002,
		pop: 75925.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Quảng Ninh',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Lao Chi',
		city_ascii: 'Lao Chi',
		lat: 22.50135134,
		lng: 103.9659948,
		pop: 51854,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Lào Cai',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Hoa Binh',
		city_ascii: 'Hoa Binh',
		lat: 20.81370241,
		lng: 105.3383142,
		pop: 102381,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Hòa Bình',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Son Tay',
		city_ascii: 'Son Tay',
		lat: 21.13817873,
		lng: 105.5050223,
		pop: 115091.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Hà Tây',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Ninh Binh',
		city_ascii: 'Ninh Binh',
		lat: 20.25430503,
		lng: 105.9750195,
		pop: 130517,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Ninh Bình',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Viet Tri',
		city_ascii: 'Viet Tri',
		lat: 21.33041506,
		lng: 105.4299882,
		pop: 305144,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Phú Thọ',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Bac Giang',
		city_ascii: 'Bac Giang',
		lat: 21.26700808,
		lng: 106.2000187,
		pop: 53728,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Bắc Giang',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Ha Tinh',
		city_ascii: 'Ha Tinh',
		lat: 18.33377626,
		lng: 105.900037,
		pop: 165396,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Ha Tinh',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Buon Me Thuot',
		city_ascii: 'Buon Me Thuot',
		lat: 12.66704205,
		lng: 108.0499833,
		pop: 248460,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Dak Lak',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Da Lat',
		city_ascii: 'Da Lat',
		lat: 11.93042035,
		lng: 108.4199865,
		pop: 193698,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Lâm Đồng',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Phan Rang',
		city_ascii: 'Phan Rang',
		lat: 11.56703168,
		lng: 108.9833113,
		pop: 135646.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Ninh Thuận',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Hon Quan',
		city_ascii: 'Hon Quan',
		lat: 11.65038576,
		lng: 106.6000459,
		pop: 40279,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Bình Phước',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Kon Tum',
		city_ascii: 'Kon Tum',
		lat: 14.38375897,
		lng: 107.9833207,
		pop: 76449,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Kon Tum',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Quang Ngai',
		city_ascii: 'Quang Ngai',
		lat: 15.15043052,
		lng: 108.8299873,
		pop: 184625.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Quảng Ngãi',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Quang Tri',
		city_ascii: 'Quang Tri',
		lat: 16.7503587,
		lng: 107.2000093,
		pop: 72722,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Quảng Trị',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Vung Tau',
		city_ascii: 'Vung Tau',
		lat: 10.35537437,
		lng: 107.0849776,
		pop: 229225,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Bà Rịa–Vũng Tàu',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Phan Thiet',
		city_ascii: 'Phan Thiet',
		lat: 10.933737,
		lng: 108.1000577,
		pop: 248749,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Bình Thuận',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Long Xuyen',
		city_ascii: 'Long Xuyen',
		lat: 10.38038576,
		lng: 105.4200146,
		pop: 254076.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'An Giang',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Chau Doc',
		city_ascii: 'Chau Doc',
		lat: 10.70039207,
		lng: 105.1166739,
		pop: 70239,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'An Giang',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Rach Gia',
		city_ascii: 'Rach Gia',
		lat: 10.01539512,
		lng: 105.0913525,
		pop: 252830,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Kiên Giang',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Tan An',
		city_ascii: 'Tan An',
		lat: 10.53373557,
		lng: 106.416698,
		pop: 101149.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Long An',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'My Tho',
		city_ascii: 'My Tho',
		lat: 10.35041343,
		lng: 106.3500354,
		pop: 123226.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Tiền Giang',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Bac Lieu',
		city_ascii: 'Bac Lieu',
		lat: 9.280375385,
		lng: 105.7199963,
		pop: 187500,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Bạc Liêu',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Ca Mau',
		city_ascii: 'Ca Mau',
		lat: 9.177358418,
		lng: 105.1500052,
		pop: 280765.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Cà Mau',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Soc Trang',
		city_ascii: 'Soc Trang',
		lat: 9.603740661,
		lng: 105.9800321,
		pop: 236961,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Sóc Trang',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Ha Giang',
		city_ascii: 'Ha Giang',
		lat: 22.83365664,
		lng: 104.9833488,
		pop: 35526,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Hà Giang',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Thai Nguyen',
		city_ascii: 'Thai Nguyen',
		lat: 21.59995933,
		lng: 105.8300154,
		pop: 415000,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Thái Nguyên',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Thanh Hoa',
		city_ascii: 'Thanh Hoa',
		lat: 19.8200163,
		lng: 105.7999914,
		pop: 197551,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Thanh Hóa',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Nam Dinh',
		city_ascii: 'Nam Dinh',
		lat: 20.42003135,
		lng: 106.2000187,
		pop: 243186,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Nam Định',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Vinh',
		city_ascii: 'Vinh',
		lat: 18.6999813,
		lng: 105.6799987,
		pop: 514426.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Nghệ An',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Dong Hoi',
		city_ascii: 'Dong Hoi',
		lat: 17.48333722,
		lng: 106.6000459,
		pop: 110152.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Quảng Bình',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Play Ku',
		city_ascii: 'Play Ku',
		lat: 13.98329246,
		lng: 108.0000122,
		pop: 128562.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Gia Lai',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Nha Trang',
		city_ascii: 'Nha Trang',
		lat: 12.25003908,
		lng: 109.1700183,
		pop: 347498.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Khánh Hòa',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Cam Ranh',
		city_ascii: 'Cam Ranh',
		lat: 11.90202415,
		lng: 109.2206612,
		pop: 85327,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Khánh Hòa',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Qui Nhon',
		city_ascii: 'Qui Nhon',
		lat: 13.77997154,
		lng: 109.1800435,
		pop: 543095,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Bình Định',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Hue',
		city_ascii: 'Hue',
		lat: 16.46998822,
		lng: 107.5800378,
		pop: 645000,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Thừa Thiên–Huế',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Bien Hoa',
		city_ascii: 'Bien Hoa',
		lat: 10.97001385,
		lng: 106.8300577,
		pop: 652646,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Đồng Nai',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Can Tho',
		city_ascii: 'Can Tho',
		lat: 10.04999249,
		lng: 105.7700191,
		pop: 690299,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Can Tho',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Haiphong',
		city_ascii: 'Haiphong',
		lat: 20.83000633,
		lng: 106.6800927,
		pop: 1285847.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Quảng Ninh',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Da Nang',
		city_ascii: 'Da Nang',
		lat: 16.06003908,
		lng: 108.2499711,
		pop: 943534.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Da Nang',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Hanoi',
		city_ascii: 'Hanoi',
		lat: 21.03332725,
		lng: 105.8500142,
		pop: 2904635,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Thái Nguyên',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Ho Chi Minh City',
		city_ascii: 'Ho Chi Minh City',
		lat: 10.78002545,
		lng: 106.6950272,
		pop: 4390665.5,
		country: 'Vietnam',
		iso2: 'VN',
		iso3: 'VNM',
		province: 'Ho Chi Minh City',
		timezone: 'Asia/Ho_Chi_Minh',
	},
	{
		city: 'Bir Lehlou',
		city_ascii: 'Bir Lehlou',
		lat: 26.11916669,
		lng: -9.652522218,
		pop: 350,
		country: 'Western Sahara',
		iso2: 'EH',
		iso3: 'SAH',
		province: '',
		timezone: 'Africa/El_Aaiun',
	},
	{
		city: 'Al Bayda',
		city_ascii: 'Al Bayda',
		lat: 13.9789981,
		lng: 45.57400265,
		pop: 37821,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Al Bayda',
		timezone: 'Asia/Aden',
	},
	{
		city: "'Ataq",
		city_ascii: "'Ataq",
		lat: 14.55000311,
		lng: 46.80000058,
		pop: 37315,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Shabwah',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Marib',
		city_ascii: 'Marib',
		lat: 15.42099607,
		lng: 45.33399954,
		pop: 16794,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: "Ma'rib",
		timezone: 'Asia/Aden',
	},
	{
		city: 'Dhamar',
		city_ascii: 'Dhamar',
		lat: 14.5574693,
		lng: 44.3874609,
		pop: 191259,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Dhamar',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Ibb',
		city_ascii: 'Ibb',
		lat: 13.97585105,
		lng: 44.17088497,
		pop: 234837,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Ibb',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Ash Shihr',
		city_ascii: 'Ash Shihr',
		lat: 14.75931744,
		lng: 49.60915767,
		pop: 54274,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Hadramawt',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Zabid',
		city_ascii: 'Zabid',
		lat: 14.19508832,
		lng: 43.31553666,
		pop: 102547,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Al Hudaydah',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Hajjah',
		city_ascii: 'Hajjah',
		lat: 15.69174115,
		lng: 43.60213415,
		pop: 125918,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Hajjah',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Lahij',
		city_ascii: 'Lahij',
		lat: 13.05818097,
		lng: 44.88376135,
		pop: 44831.5,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Lahij',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Al Ghaydah',
		city_ascii: 'Al Ghaydah',
		lat: 16.23940798,
		lng: 52.1637821,
		pop: 23702,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Al Mahrah',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Rida',
		city_ascii: 'Rida',
		lat: 14.42949261,
		lng: 44.8341003,
		pop: 45233,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Al Bayda',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Hadiboh',
		city_ascii: 'Hadiboh',
		lat: 12.65187502,
		lng: 54.02392696,
		pop: 9970.5,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Hadramawt',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Saywun',
		city_ascii: 'Saywun',
		lat: 15.94299196,
		lng: 48.78734737,
		pop: 68747,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Hadramawt',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Sadah',
		city_ascii: 'Sadah',
		lat: 16.93977867,
		lng: 43.84976762,
		pop: 105542,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Sa`dah',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Al Hudaydah',
		city_ascii: 'Al Hudaydah',
		lat: 14.79794558,
		lng: 42.95297481,
		pop: 627610.5,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Al Hudaydah',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Sayhut',
		city_ascii: 'Sayhut',
		lat: 15.21050437,
		lng: 51.24544023,
		pop: 189,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Al Mahrah',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Al Mukalla',
		city_ascii: 'Al Mukalla',
		lat: 14.54116538,
		lng: 49.12593135,
		pop: 194080.5,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Hadramawt',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Taizz',
		city_ascii: 'Taizz',
		lat: 13.60445253,
		lng: 44.03942012,
		pop: 683111,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Ta`izz',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Aden',
		city_ascii: 'Aden',
		lat: 12.77972251,
		lng: 45.00949011,
		pop: 775301,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: '`Adan',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Sanaa',
		city_ascii: 'Sanaa',
		lat: 15.3547333,
		lng: 44.20659338,
		pop: 1921926.5,
		country: 'Yemen',
		iso2: 'YE',
		iso3: 'YEM',
		province: 'Amanat Al Asimah',
		timezone: 'Asia/Aden',
	},
	{
		city: 'Kawambwa',
		city_ascii: 'Kawambwa',
		lat: -9.779520651,
		lng: 29.08002315,
		pop: 20355,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Luapula',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Nchelenge',
		city_ascii: 'Nchelenge',
		lat: -9.349572735,
		lng: 28.73001868,
		pop: 15904,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Luapula',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Chinsali',
		city_ascii: 'Chinsali',
		lat: -10.54960285,
		lng: 32.0599963,
		pop: 7482.5,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Northern',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Kasama',
		city_ascii: 'Kasama',
		lat: -10.19959837,
		lng: 31.17994665,
		pop: 156500,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Northern',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Kapiri Mposhi',
		city_ascii: 'Kapiri Mposhi',
		lat: -13.96960081,
		lng: 28.65999711,
		pop: 30078,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Central',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Mumbwa',
		city_ascii: 'Mumbwa',
		lat: -14.97961668,
		lng: 27.07001664,
		pop: 14408.5,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Central',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Chingola',
		city_ascii: 'Chingola',
		lat: -12.53961058,
		lng: 27.85002071,
		pop: 165289.5,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Copperbelt',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Chililabombwe',
		city_ascii: 'Chililabombwe',
		lat: -12.36959511,
		lng: 27.8199967,
		pop: 69698,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Copperbelt',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Nyimba',
		city_ascii: 'Nyimba',
		lat: -14.54951373,
		lng: 30.80999508,
		pop: 814,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Eastern',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Lundazi',
		city_ascii: 'Lundazi',
		lat: -12.28954832,
		lng: 33.17000606,
		pop: 9540.5,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Eastern',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Chipata',
		city_ascii: 'Chipata',
		lat: -13.62956989,
		lng: 32.64001257,
		pop: 82455,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Eastern',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Mwinilunga',
		city_ascii: 'Mwinilunga',
		lat: -11.73955605,
		lng: 24.4399963,
		pop: 8336.5,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'North-Western',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Kasempa',
		city_ascii: 'Kasempa',
		lat: -13.4596061,
		lng: 25.82001542,
		pop: 5622,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'North-Western',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Solwezi',
		city_ascii: 'Solwezi',
		lat: -12.17958087,
		lng: 26.39998002,
		pop: 51793.5,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'North-Western',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Choma',
		city_ascii: 'Choma',
		lat: -16.80947915,
		lng: 26.97002274,
		pop: 42324,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Southern',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Mongu',
		city_ascii: 'Mongu',
		lat: -15.27959837,
		lng: 23.12002519,
		pop: 45098.5,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Western',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Kaoma',
		city_ascii: 'Kaoma',
		lat: -14.77962889,
		lng: 24.79997432,
		pop: 7259,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Western',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Sesheke',
		city_ascii: 'Sesheke',
		lat: -17.46954222,
		lng: 24.30000484,
		pop: 10177.5,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Western',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Lukulu',
		city_ascii: 'Lukulu',
		lat: -14.38957518,
		lng: 23.24001786,
		pop: 3349,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Western',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Kalabo',
		city_ascii: 'Kalabo',
		lat: -14.98959023,
		lng: 22.68000036,
		pop: 7731,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Western',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Senanga',
		city_ascii: 'Senanga',
		lat: -16.11959878,
		lng: 23.27004187,
		pop: 10005,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Western',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Mansa',
		city_ascii: 'Mansa',
		lat: -11.20000242,
		lng: 28.89000891,
		pop: 31357,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Luapula',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Mpika',
		city_ascii: 'Mpika',
		lat: -11.83004148,
		lng: 31.45998124,
		pop: 17242.5,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Northern',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Mbala',
		city_ascii: 'Mbala',
		lat: -8.840043112,
		lng: 31.37001257,
		pop: 18384,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Northern',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Luanshya',
		city_ascii: 'Luanshya',
		lat: -13.13332111,
		lng: 28.40001298,
		pop: 132679,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Copperbelt',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Ndola',
		city_ascii: 'Ndola',
		lat: -12.99994424,
		lng: 28.65002356,
		pop: 395428.5,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Copperbelt',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Zambezi',
		city_ascii: 'Zambezi',
		lat: -13.54001463,
		lng: 23.10994828,
		pop: 4987.5,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'North-Western',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Kafue',
		city_ascii: 'Kafue',
		lat: -15.78003294,
		lng: 28.18002641,
		pop: 25725.5,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Southern',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Mazabuka',
		city_ascii: 'Mazabuka',
		lat: -15.86002806,
		lng: 27.76000036,
		pop: 57874.5,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Southern',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Kabwe',
		city_ascii: 'Kabwe',
		lat: -14.44001137,
		lng: 28.44998409,
		pop: 188667,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Central',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Mufulira',
		city_ascii: 'Mufulira',
		lat: -12.54999754,
		lng: 28.25996985,
		pop: 137062,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Copperbelt',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Kitwe',
		city_ascii: 'Kitwe',
		lat: -12.81003335,
		lng: 28.22002397,
		pop: 402907.5,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Copperbelt',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Livingstone',
		city_ascii: 'Livingstone',
		lat: -17.86000934,
		lng: 25.86001298,
		pop: 137341.5,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Southern',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Lusaka',
		city_ascii: 'Lusaka',
		lat: -15.41664427,
		lng: 28.28332759,
		pop: 1297720,
		country: 'Zambia',
		iso2: 'ZM',
		iso3: 'ZMB',
		province: 'Lusaka',
		timezone: 'Africa/Lusaka',
	},
	{
		city: 'Mazowe',
		city_ascii: 'Mazowe',
		lat: -17.51961668,
		lng: 30.97003699,
		pop: 9966,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Mashonaland Central',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Shamva',
		city_ascii: 'Shamva',
		lat: -17.31962889,
		lng: 31.57000036,
		pop: 8521,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Mashonaland Central',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Victoria Falls',
		city_ascii: 'Victoria Falls',
		lat: -17.92961749,
		lng: 25.8400142,
		pop: 23964.5,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Matabeleland North',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Zvishavane',
		city_ascii: 'Zvishavane',
		lat: -20.32957436,
		lng: 30.04998979,
		pop: 34557.5,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Midlands',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Kwekwe',
		city_ascii: 'Kwekwe',
		lat: -18.92960814,
		lng: 29.79997921,
		pop: 80788,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Midlands',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Plumtree',
		city_ascii: 'Plumtree',
		lat: -20.47953937,
		lng: 27.8199967,
		pop: 1959.5,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Matabeleland South',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Beitbridge',
		city_ascii: 'Beitbridge',
		lat: -22.20961465,
		lng: 29.98999345,
		pop: 13759.5,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Matabeleland South',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Gwanda',
		city_ascii: 'Gwanda',
		lat: -20.93961465,
		lng: 29.01000159,
		pop: 8252.5,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Matabeleland South',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Chiredzi',
		city_ascii: 'Chiredzi',
		lat: -21.04958209,
		lng: 31.66002071,
		pop: 17816.5,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Masvingo',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Masvingo',
		city_ascii: 'Masvingo',
		lat: -20.05961668,
		lng: 30.8200203,
		pop: 76300.5,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Masvingo',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Karoi',
		city_ascii: 'Karoi',
		lat: -16.81955605,
		lng: 29.67998653,
		pop: 13194,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Mashonaland West',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Chinhoyi',
		city_ascii: 'Chinhoyi',
		lat: -17.35962645,
		lng: 30.18000769,
		pop: 52812,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Mashonaland West',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Kariba',
		city_ascii: 'Kariba',
		lat: -16.52959959,
		lng: 28.80004024,
		pop: 23133.5,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Mashonaland West',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Hwange',
		city_ascii: 'Hwange',
		lat: -18.37000405,
		lng: 26.50002559,
		pop: 33599.5,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Matabeleland North',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Gweru',
		city_ascii: 'Gweru',
		lat: -19.45004148,
		lng: 29.82002966,
		pop: 164715.5,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Midlands',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Mutare',
		city_ascii: 'Mutare',
		lat: -18.97001911,
		lng: 32.6500378,
		pop: 216785,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Manicaland',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Kadoma',
		city_ascii: 'Kadoma',
		lat: -18.33000649,
		lng: 29.90994665,
		pop: 56400,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Mashonaland West',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Chitungwiza',
		city_ascii: 'Chitungwiza',
		lat: -18.00000079,
		lng: 31.10000321,
		pop: 331071,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Harare',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Harare',
		city_ascii: 'Harare',
		lat: -17.81778969,
		lng: 31.04470943,
		pop: 1557406.5,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Harare',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Bulawayo',
		city_ascii: 'Bulawayo',
		lat: -20.16999754,
		lng: 28.58000199,
		pop: 697096,
		country: 'Zimbabwe',
		iso2: 'ZW',
		iso3: 'ZWE',
		province: 'Bulawayo',
		timezone: 'Africa/Harare',
	},
	{
		city: 'Copenhagen',
		city_ascii: 'Copenhagen',
		lat: 55.676098,
		lng: 12.568337,
		pop: 602481,
		country: 'Denmark',
		iso2: 'DK',
		iso3: 'DNK',
		province: 'Hovedstaden',
		timezone: 'Europe/Copenhagen',
	},
	{
		city: 'Oakleigh',
		city_ascii: 'Oakleigh',
		lat: -37.913,
		lng: 145.102,
		pop: 7893,
		country: 'Australia',
		iso2: 'AU',
		iso3: 'AUS',
		province: 'Victoria',
		timezone: 'Australia/Melbourne',
	},
	{
		city: 'Oak Park',
		city_ascii: 'Oak Park',
		lat: 41.883333,
		lng: -87.8,
		pop: 52261,
		country: 'United States of America',
		iso2: 'US',
		iso3: 'USA',
		state_ansi: 'IL',
		timezone: 'America/Chicago',
	},
];

export default cities;
