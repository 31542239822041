import React, { useEffect, useRef } from 'react';
import Cookie from 'universal-cookie';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { getAzureSpeechToken } from 'api/speechToText.js';
import {
	SpeechConfig,
	AudioConfig,
	ResultReason,
	SpeechRecognizer,
	CancellationReason,
} from 'microsoft-cognitiveservices-speech-sdk';
import { APP_CONFIG } from 'constants/global-variables.js';

const SpeechToText = ({
	setRecognizedTranscription,
	setLiveTranscription,
	setIsSpeechToText,
	isSpeechToText,
	maxCharacterLength = 400,
	isDisabled = false,
}) => {
	const speechRecognizer = useRef(null);
	const intl = useIntl();

	useEffect(() => {
		const fetchToken = async () => {
			const response = await getAzureSpeechToken();
			if (response.error) {
				setRecognizedTranscription(response.error.message);
				return;
			}
			const tokenResponse = await getTokenOrRefresh(response);
			if (tokenResponse.authToken === null) {
				setRecognizedTranscription(`FATAL_ERROR: ${tokenResponse.error}`);
				return;
			}
			sttFromMic();
		};
		fetchToken();
		return () => {
			setIsSpeechToText(false);
			if (speechRecognizer?.current) {
				speechRecognizer.current.stopContinuousRecognitionAsync();
			}
		};
	}, []);

	useEffect(() => {
		if (speechRecognizer?.current) {
			if (isSpeechToText) {
				speechRecognizer.current.startContinuousRecognitionAsync();
			} else {
				speechRecognizer.current.stopContinuousRecognitionAsync();
			}
		}
	}, [isSpeechToText]);

	const sttFromMic = async () => {
		const tokenObj = await getTokenOrRefresh();
		const speechConfig = SpeechConfig.fromAuthorizationToken(tokenObj.authToken, APP_CONFIG.speechToTextRegion);
		speechConfig.speechRecognitionLanguage = 'en-US';
		const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
		speechRecognizer.current = new SpeechRecognizer(speechConfig, audioConfig);
		setLiveTranscription('');
		speechRecognizer.current.recognizing = (sender, event) => {
			setLiveTranscription(event.result.text);
		};
		speechRecognizer.current.recognized = (sender, event) => {
			if (event.result.reason === ResultReason.RecognizedSpeech) {
				setRecognizedTranscription(prevState => {
					let result = `${prevState} ${event.result.text}`;
					const length = prevState.length + event.result.text.length;
					if (length > maxCharacterLength) {
						result = result.substring(0, maxCharacterLength);
					}
					return result;
				});
				setLiveTranscription('');
			} else if (event.result.reason === ResultReason.NoMatch) {
				// speech not recognized
			}
		};
		speechRecognizer.current.canceled = (sender, event) => {
			if (event.reason === CancellationReason.Error) {
				// error handle
			}
			speechRecognizer.current.stopContinuousRecognitionAsync();
		};

		speechRecognizer.current.sessionStopped = () => {
			speechRecognizer.current.stopContinuousRecognitionAsync();
		};
	};

	const getTokenOrRefresh = async tokenResponse => {
		const cookie = new Cookie();
		const speechToken = cookie.get('speech-token');
		if (speechToken === undefined) {
			try {
				const token = tokenResponse.data;
				const region = APP_CONFIG.speechToTextRegion;
				cookie.set('speech-token', `${region}:${token}`, { maxAge: 540, path: '/' });
				return { authToken: token, region: region };
			} catch (err) {
				return { authToken: null, error: err.response.data };
			}
		} else {
			const idx = speechToken.indexOf(':');
			return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
		}
	};

	return (
		<>
			<div
				id='mic-icon'
				className={classNames('call-button observer-mic-icon', isSpeechToText ? 'active' : '', isDisabled ? 'disabled' : '')}
				data-tooltip={intl.formatMessage({ id: isSpeechToText ? 'turnOffDictation' : 'turnOnDictation' })}
				data-position='left'
				onClick={() => setIsSpeechToText(prevState => !prevState)}
			/>
		</>
	);
};

export default SpeechToText;
