import { gatewayApi } from 'constants/global-variables.js';

/**
 * @param {object} device
 * @returns {Promise<object>}
 */
export const registerDevice = async device => {
	try {
		const { data } = await gatewayApi.post(`/v1/e2ee/devices`, device);
		return data;
	} catch (error) {
		return { error };
	}
};

/**
 * @param {number} deviceId
 * @param {object} preKeys
 * @returns {Promise<object>}
 */
export const uploadPreKeys = async (deviceId, preKeys) => {
	try {
		const { data } = await gatewayApi.post(
			`/v1/e2ee/devices/${deviceId}/key-bundle/pre-keys`,
			JSON.stringify({ preKeys: preKeys })
		);
		return data;
	} catch (error) {
		return { error };
	}
};

/**
 * @param {number} deviceId
 * @returns {Promise<object>}
 */
export const getPreKeysCount = async deviceId => {
	try {
		const { data } = await gatewayApi.get(`/v1/e2ee/devices/${deviceId}/key-bundle/pre-keys/count`);
		return data;
	} catch (error) {
		return { error };
	}
};

/**
 * @param {number} deviceId
 * @param {boolean} popPreKey
 * @returns {Promise<object>}
 */
export const getDevice = async (deviceId, popPreKey = false) => {
	try {
		let query = '';
		if (popPreKey === false) {
			query = `?popPreKey=${popPreKey}`;
		}
		const { data } = await gatewayApi.get(`/v1/e2ee/devices/${deviceId + query}`);
		return data;
	} catch (error) {
		return { error };
	}
};

/**
 * @param {number} userId
 * @param {boolean} popPreKey
 * @returns {Promise<object>}
 */
export const getUserDevices = async (userId, objectType = 0, popPreKey = false) => {
	try {
		let query = '';
		if (popPreKey === false) {
			query = `?popPreKey=${popPreKey}`;
		}
		const { data } = await gatewayApi.get(`v1/e2ee/devices/object-types/${objectType}/object-ids/${userId + query}`);
		return data;
	} catch (error) {
		return { error };
	}
};
