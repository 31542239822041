import { APP_CONFIG } from 'constants/global-variables.js';

export function buildProfilePicUrl(profilePic, size) {
	if (!profilePic) return null;

	if (profilePic.startsWith('http://') || profilePic.startsWith('https://')) return profilePic;
	return getPictureUrl(size) + profilePic;
}

export function buildProfilePic(profilePic) {
	if (!profilePic) {
		return null;
	}

	return APP_CONFIG.profilePicBaseUrl + profilePic;
}

export function getPictureUrl(size) {
	return size ? `${APP_CONFIG.profilePicBaseUrl + size}/${size}/` : APP_CONFIG.profilePicBaseUrl;
}

export function onErrorDefaultSrc(e) {
	e.target.src = APP_CONFIG.defaultPicUrl;
	e.target.onerror = null;
}

export function onErrorInitialsSrc(e) {
	e.target.style.display = 'none';
	e.target.onerror = null;
}
