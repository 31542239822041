import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enums } from '@solaborate/calls';
import { useRouteMatch } from 'react-router';
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check';
import { isChrome, isEdgeChromium } from 'react-device-detect';
import { useIntl } from 'react-intl';
import HelloFeatureBlock from 'components/HelloFeatureBlock.jsx';
import {
	findSectorById,
	getParentSector,
	checkIfMediaDevicesPlugged,
	checkForPermission,
	getRoleConfigurationValue,
	getConfigurationValue,
	getConfigurationVariant,
} from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import { StartQueryStringKeys } from 'calls/enums/index.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { StreamError, MediaPermissions, MediaTypes, UserPermissionDeniedErrors, ErrorComponentTypes } from 'constants/enums.js';
import Alert from 'components/Alert.jsx';
import { MonitoringSettings, PatientInfoFieldsVariants, RoundingSettings } from 'constants/configurationEnums.js';
import Select from 'react-select';
import { generateCustomStyles } from 'constants/react-select-style.js';
import CardInfo from 'calls/components/CardInfo.jsx';
import { getDeviceOwnerPatient, getPatientLangAndInterpreter } from 'api/patients.js';
import { getMonthDayYearDateFormat } from 'infrastructure/helpers/dateHelper.js';
import { getAllVerbalRedirectionLanguages } from 'api/verbalRedirections.js';

const CallPatient = ({ callEvents = [], helloDeviceId, roomId = null }) => {
	const prevRoomId = useRef(null);
	const [error, setError] = useState(null);
	const healthSystems = useSelector(state => state.healthSystems);
	const user = useSelector(state => state.user);
	const dispatch = useDispatch();
	const match = useRouteMatch();
	const roleRoundingConfigurations = useSelector(state => state.configurations.roleRoundingConfigurations);
	const initialRoomState = {
		sectorName: '',
		roomName: '',
		hospitalId: '',
		departmentId: '',
		floorId: '',
		roomId: '',
		sectorType: '',
		sectorParent: {},
		isHelloDevice: false,
		roomType: null,
		isRoomNameEditable: false,
	};

	const [currentRoom, setCurrentRoom] = useState(initialRoomState);
	const camStatus = useRef(null);
	const micStatus = useRef(null);
	const [selectedCallEvent, setSelectedCallEvent] = useState(null);
	const [patientInfo, setPatientInfo] = useState({});
	const isAllowPermissionPrompt = useRef(false);
	const intl = useIntl();
	const healthSystemConfigurations = useSelector(state => state.configurations.healthSystemConfigurations);

	const isEhrField = key =>
		getConfigurationValue(healthSystemConfigurations[key]) &&
		getConfigurationVariant(healthSystemConfigurations[key]) === PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD;

	useEffect(() => {
		const fetchDeviceOwner = async () => {
			if (!helloDeviceId) {
				setPatientInfo({ patientName: '' });
				return;
			}
			const response = await getDeviceOwnerPatient(helloDeviceId);
			if (response && !response.error) {
				const languageResponse = await getAllVerbalRedirectionLanguages();
				if (languageResponse.error) {
					languageResponse.languages = [];
				}
				const languages = languageResponse.languages.map(lang => ({ ...lang, localeName: lang.localeName.split('(')[0].trim() }));
				const { preferredLanguage, interpreterNeeded } = await getPatientLangAndInterpreter(
					response.userId,
					languages,
					isEhrField(MonitoringSettings.PreferredLanguage)
				);
				setPatientInfo({
					patientName: response.isVirtualPatient ? '' : response.fullName,
					...(response.dateOfBirth && { dateOfBirth: getMonthDayYearDateFormat(response.dateOfBirth) }),
					mrn: response.mrn,
					preferredLanguage,
					interpreterNeeded,
				});
				return;
			}
			setPatientInfo({ patientName: '' });
		};
		if (prevRoomId.current !== roomId) {
			fetchDeviceOwner();
			setSelectedCallEvent(null);
		}
		prevRoomId.current = roomId;
	}, [callEvents, roomId]);

	useEffect(() => {
		const bindMediaEvents = async () => {
			camStatus.current = await checkForPermission(MediaTypes.CAMERA);
			micStatus.current = await checkForPermission(MediaTypes.MICROPHONE);
			camStatus.current.onchange = onDevicePermissionChange;
			micStatus.current.onchange = onDevicePermissionChange;
		};
		bindMediaEvents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const room = findSectorById(healthSystems.treeData.tree, match.params.roomId);
		const sectorParent = getParentSector(healthSystems.treeData.tree, room);
		if (!room) {
			setCurrentRoom(initialRoomState);
		} else {
			setCurrentRoom({
				sectorName: room.name ?? '',
				roomName: room.name ?? '',
				hospitalId: room.hospitalId ?? '',
				departmentId: room.departmentId ?? '',
				floorId: room.floorId ?? '',
				roomId: room.roomId ?? '',
				sectorType: room.type ?? '',
				sectorParent,
				isHelloDevice: room.isHelloDevice,
				roomType: room.roomType,
				isRoomNameEditable: getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.EditRoomNameRounding),
			});
		}
	}, [healthSystems.treeData.tree, match, roleRoundingConfigurations]);

	const showAllowPermissionModal = () => {
		isAllowPermissionPrompt.current = true;
		setTimeout(() => {
			if (!isAllowPermissionPrompt.current) {
				return;
			}

			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
				})
			);
		}, 500);
	};

	const handlePermissionErrors = async (callType, permissionError) => {
		const { camera, microphone } = await checkIfMediaDevicesPlugged();
		if ([enums.CallTypes.VIDEO, enums.CallTypes.AUDIO].includes(callType) && (!camera || !microphone)) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: microphone ? StreamError.MICROPHONE_NOT_FOUND.type : StreamError.CAMERA_NOT_FOUND.type,
				})
			);
			return;
		}
		if (micStatus.current.state === MediaPermissions.DENIED) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: StreamError.MICROPHONE_BLOCKED.type,
				})
			);
			return;
		}

		const { type, name } = permissionError;
		if (type === MediaPermissionsErrorType.UserPermissionDenied) {
			if (name === UserPermissionDeniedErrors.NotAllowedError) {
				dispatch(
					healthSystemsActionCreators.setStreamPermissionMessage({
						component: isChrome || isEdgeChromium ? ErrorComponentTypes.Popup : ErrorComponentTypes.Modal,
						type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
					})
				);
			}
		}
	};

	const onDevicePermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			clearPermissions();
		}
	};

	const clearPermissions = () => {
		dispatch(healthSystemsActionCreators.setStreamPermissionMessage(null));
	};

	const talkToPatient = async () => {
		try {
			showAllowPermissionModal();
			await requestMediaPermissions({ audio: true, video: false });
			openCallViewUrl(enums.CallTypes.VIDEO);
		} catch (permissionError) {
			handlePermissionErrors(enums.CallTypes.VIDEO, permissionError);
		}
		isAllowPermissionPrompt.current = false;
	};

	const viewPatient = async () => {
		try {
			showAllowPermissionModal();
			await requestMediaPermissions({ audio: true, video: false });
			// eslint-disable-next-line no-empty
		} catch (e) {}
		isAllowPermissionPrompt.current = false;
		openCallViewUrl(enums.CallTypes.SECURITYCAM);
	};

	const openCallViewUrl = callType => {
		const sector = findSectorById(healthSystems.treeData.tree, match.params.roomId);
		const queryParams = new URLSearchParams({
			[StartQueryStringKeys.OBJECT_ID]: sector.helloDeviceId,
			[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.HELLO_DEVICE,
			[StartQueryStringKeys.CONFERENCE_NAME]: sector.name,
			[StartQueryStringKeys.CALL_TYPE]: callType,
			[StartQueryStringKeys.ROOM_TYPE]: sector.roomType,
			...(selectedCallEvent && { [StartQueryStringKeys.ROUNDING_CALL_TYPE]: selectedCallEvent.value }),
		});
		clearPermissions();
		const url = callType === enums.CallTypes.SECURITYCAM ? 'patient-feed' : 'call';
		window.open(`/${url}?${queryParams.toString()}`, '_blank');
	};

	const getButtonsLength = () => {
		const buttonLength = 145;
		const minimumLength = 2;
		let result = 0;
		if (roleRoundingConfigurations[RoundingSettings.TalkToPatient]) {
			result += 1;
		}
		if (currentRoom.isHelloDevice && roleRoundingConfigurations[RoundingSettings.ViewPatient]) {
			result += 1;
		}
		const gaps = (Math.max(minimumLength, result) - 1) * 10;
		const buttonsWidth = Math.max(minimumLength, result) * buttonLength;
		return buttonsWidth + gaps;
	};

	return (
		<div className='room-actions' style={{ width: `${getButtonsLength()}px` }}>
			<CardInfo metaData={{ room: currentRoom.roomName }} isBold={false} />
			<CardInfo metaData={patientInfo} isBold={false} />
			{callEvents.length > 0 && roleRoundingConfigurations[RoundingSettings.RoundingCareEvents] && (
				<div className='call-care-events-dropdown'>
					<Select
						isClearable={true}
						value={selectedCallEvent}
						placeholder={`${intl.formatMessage({ id: 'select' })}  ${intl.formatMessage({ id: 'careEventType' })}`}
						classNamePrefix='react-select'
						options={callEvents}
						onChange={setSelectedCallEvent}
						styles={generateCustomStyles({ darkMode: user.darkMode })}
					/>
				</div>
			)}
			<div className='hello-feature-grid'>
				{currentRoom.isHelloDevice && roleRoundingConfigurations[RoundingSettings.ViewPatient] && (
					<HelloFeatureBlock
						title={translate('viewPatient')}
						onClick={viewPatient}
						className='hello-feature-camera-feed'
						tooltipData={intl.formatMessage({ id: 'viewPatientTooltip' })}
					/>
				)}
				{roleRoundingConfigurations[RoundingSettings.TalkToPatient] && (
					<>
						<HelloFeatureBlock
							onClick={talkToPatient}
							title={translate('talkToPatient')}
							className='hello-feature-audio'
							tooltipData={intl.formatMessage({ id: 'ttpTooltip' })}
						/>
					</>
				)}
				{!roleRoundingConfigurations[RoundingSettings.ViewPatient] &&
					!roleRoundingConfigurations[RoundingSettings.TalkToPatient] && <p>{translate('checkFeatureAvailability')}</p>}
			</div>

			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
		</div>
	);
};

export default CallPatient;
