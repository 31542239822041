const Incoming = props => (
	<svg width='33' height='32' viewBox='0 0 33 32' fill='none' color='#8EB66F' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M26.749 20.9679L24.5472 19.1888C23.8014 18.5839 22.736 18.548 21.9547 19.1177L19.8594 20.6477C19.5753 20.8612 19.185 20.8256 18.936 20.5765L15.4912 17.4453L12.3305 13.9583C12.0819 13.7092 12.0464 13.3178 12.2595 13.0332L13.7866 10.9338C14.3548 10.151 14.3193 9.08358 13.7155 8.33636L11.9399 6.13028C11.1586 5.13399 9.66699 5.06283 8.77915 5.95237L6.86141 7.87379C6.43525 8.30078 6.22217 8.90567 6.22217 9.47498C6.47076 13.9939 8.53055 18.2993 11.5492 21.3237C14.5679 24.3482 18.8295 26.412 23.3752 26.661C23.979 26.6966 24.5472 26.4472 24.9734 26.0202L26.8911 24.0991C27.85 23.2452 27.7434 21.7507 26.749 20.9679Z'
			stroke='currentColor'
			strokeWidth='2'
		/>
		<path
			d='M23.7173 4.15918V9.81603H29.3741'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M23.7172 9.81603L29.374 4.15918' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
	</svg>
);
export default Incoming;
