import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as languageActionCreators } from 'state/language/actions.js';
import { getPreferredLanguageLocale, setPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import { changeUserLanguage } from 'api/users.js';
import { AlertTypes } from 'constants/enums.js';
import Alert from 'components/Alert.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { languageValues } from 'constants/language.js';
import Button from 'components/Button.jsx';

const AppLanguage = () => {
	const locale = useSelector(state => state.language.locale);
	const dispatch = useDispatch();

	const preferredLanguage = getPreferredLanguageLocale();
	const [selectedLanguage, setSelectedLanguage] = useState(
		languageValues.find(item => item.value === (preferredLanguage || locale))
	);
	const [error, setError] = useState(null);
	const [changesSaved, setChangesSaved] = useState(null);

	useEffect(() => {
		let timeout;
		if (changesSaved) {
			timeout = setTimeout(() => {
				setChangesSaved('');
			}, 1500);
		}
		return () => clearTimeout(timeout);
	}, [changesSaved]);

	const saveChanges = async () => {
		if (locale === selectedLanguage.value) {
			return;
		}
		const response = await changeUserLanguage(selectedLanguage.value);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setPreferredLanguageLocale(selectedLanguage.value);
		dispatch(languageActionCreators.setUserLanguage(selectedLanguage.value));
		setChangesSaved(translate('changesSaved'));
	};

	const transformArray = array => array.map(item => ({ value: item.value, label: item.label }));

	return (
		<div className='account-settings-inner-wrapper'>
			<div className='general-settings-form flex flex-space-between flex-wrap general-setting-wrapper'>
				<label className='general-settings-select-el language-select account-setting'>
					{translate('language')}
					<Select
						isSearchable={false}
						value={transformArray(languageValues).filter(option => option.value === selectedLanguage.value)}
						classNamePrefix='custom-select'
						options={transformArray(languageValues)}
						onChange={value => setSelectedLanguage(value)}
					/>
				</label>
			</div>
			<div className='flex right-align-content'>
				<Button
					className='flex flex-justify-center flex-align-center save-changes-btn'
					onClick={saveChanges}
					text={translate('saveChanges')}
				/>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			<PopUpAlert
				alertType={AlertTypes.SUCCESS}
				display={changesSaved}
				onAlertClose={() => setChangesSaved(null)}
				contentText={changesSaved}
				isSilent={true}
				center={true}
			/>
		</div>
	);
};

export default AppLanguage;
