import { enums } from '@solaborate/calls';
import translate from 'i18n-translations/translate.jsx';

const { ConferenceEndReasons } = enums;
const ConferenceEndReasonMessages = {
	[ConferenceEndReasons.UNDEFINED]: translate('callEnded'),
	[ConferenceEndReasons.PARTICIPANT_OFFLINE]: translate('deviceOfflineTryLater'),
	[ConferenceEndReasons.PARTICIPANT_BUSY]: translate('deviceOnCallTryLater'),
	[ConferenceEndReasons.INITIATOR_LEFT]: translate('callEnded'),
	[ConferenceEndReasons.PARTICIPANT_NOT_ANSWERING]: translate('cannotConnect'),
	[ConferenceEndReasons.PARTICIPANT_DECLINED]: translate('callDeclined'),
	[ConferenceEndReasons.PARTICIPANT_LEFT]: translate('callEnded'),
	[ConferenceEndReasons.OWNER_LEFT]: translate('callEnded'),
	[ConferenceEndReasons.ABORTED]: translate('callEndedTryAgain'),
	[ConferenceEndReasons.DROPPED]: translate('failedReconnect'),
	[ConferenceEndReasons.PARTICIPANT_IDLE]: translate('idleCallEnded'),
	[ConferenceEndReasons.HAS_ACTIVE_CONFERENCE]: translate('thereIsActiveConference'),
	[ConferenceEndReasons.TRANSFERRED_TO_ANOTHER_CLIENT]: translate('callTransferredToAnotherClient'),
	[ConferenceEndReasons.ANSWERED_ELSEWHERE]: translate('callAnsweredElsewhere'),
	[ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR]: translate('sessionEndedByAdmin'),
	[ConferenceEndReasons.UNAUTHORIZED]: 'Your token has expired.',
	[ConferenceEndReasons.PARTICIPANT_INVITE_DENIED]: translate('conferenceEndedParticipantInviteDenied'),
	[ConferenceEndReasons.PARTICIPANT_INVITE_FAILED]: translate('failedToGetParticipantInfo'),
	[ConferenceEndReasons.FAILED_TO_GET_INITIATOR_INFO]: translate('failedToGetInitiatorInfo'),
	[ConferenceEndReasons.REMOVED]: translate('youAreRemovedFromConference'),
};

export default ConferenceEndReasonMessages;
