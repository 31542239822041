import * as React from 'react';
const Medications = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} viewBox='0 0 22 24' fill='none' {...props}>
		<rect
			width={7}
			height={18}
			x={2.661}
			y={8.111}
			stroke='currentColor'
			strokeWidth={1.5}
			rx={3.5}
			transform='rotate(-45 2.661 8.11)'
		/>
		<path stroke='currentColor' strokeWidth={1.5} d='M9.604 15.053s-.91-1.404 1.317-3.632c2.228-2.227 3.632-1.317 3.632-1.317' />
	</svg>
);
export default Medications;
