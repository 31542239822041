import * as React from 'react';

const Room = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={17} height={20} fill='none' {...props}>
		<path
			stroke='currentColor'
			strokeWidth={1.25}
			d='M12 2.5h2a1 1 0 0 1 1 1v14a1 1 0 0 0 1 1h1M2 18.5V3.302a1 1 0 0 1 .783-.976l7-1.556A1 1 0 0 1 11 1.747V17.5a1 1 0 0 1-1 1H2Zm0 0H0'
		/>
		<circle cx={8} cy={10.5} r={1} fill='currentColor' />
	</svg>
);
export default Room;
