import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

function Screenshot(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
			<rect x={1.199} y={3.6} width={21.6} height={16.8} rx={2} stroke={LightTheme.colors.grayFive} strokeWidth={1.5} />
			<path
				d='M7.8 6.6H5.2a1 1 0 00-1 1v2.6m15.6 0V7.6a1 1 0 00-1-1h-2.6m0 10.8h2.6a1 1 0 001-1v-2.6m-15.6 0v2.6a1 1 0 001 1h2.6'
				stroke={LightTheme.colors.grayFive}
				strokeWidth={1.5}
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default Screenshot;
