import React, { useState } from 'react';
import Modal from 'components/Modal.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import MeasurementAlerts from 'containers/MeasurementAlerts.jsx';
import AiAlerts from 'containers/AiAlerts';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import ManualAlerts from 'components/ManualAlerts';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const AlertsModal = props => {
	const [currentTab, setCurrentTab] = useState(0);
	const isDarkMode = useSelector(state => state.user.darkMode);
	const imageUrl = `${healthCareCdnUrl}vsm/`;
	return (
		<Modal
			display={props.display}
			isLoading={props.isLoading}
			position={props.position}
			className={props.className}
			onModalClose={props.onModalClose}>
			<h3>{translate('alerts')}</h3>
			<p>{translate('viewLatestAlerts')}</p>
			<Tabs
				isVertical={true}
				activeIndex={currentTab}
				onChange={index => setCurrentTab(index)}
				className={classNames(isDarkMode ? 'alert-modal-dark-mode' : '')}>
				<TabList>
					<Tab>
						<img src={`${imageUrl}Health${currentTab === 0 ? '-1' : ''}.svg`} alt='health icon' />
						{translate('healthData')}
					</Tab>
					{props.isVitalSigns && (
						<Tab>
							<img src={`${imageUrl}AIControls${currentTab === 1 ? '-1' : ''}.svg`} alt='health icon' />
							{translate('artificialIntelligence')}
						</Tab>
					)}
					{props.isVitalSigns && (
						<Tab>
							<img src={`${imageUrl}Health${currentTab === 2 ? '-1' : ''}.svg`} alt='health icon' />
							{translate('careEvents')}
						</Tab>
					)}
				</TabList>
				<TabPanels>
					<TabPanel>
						<MeasurementAlerts patientId={props.patientId} />
					</TabPanel>
					{props.isVitalSigns && (
						<TabPanel>
							<AiAlerts patientId={props.patientId} />
						</TabPanel>
					)}
					{props.isVitalSigns && (
						<TabPanel>
							<ManualAlerts patientId={props.patientId} />
						</TabPanel>
					)}
				</TabPanels>
			</Tabs>
		</Modal>
	);
};

export default AlertsModal;
