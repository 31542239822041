import React from 'react';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { LOCALES } from 'i18n-translations/locales.js';


export const languageValues = [
	{
		value: LOCALES.ENGLISH,
		label: (
			<div className='general-lang-values flex flex-align-center '>
				<img src={`${healthCareCdnUrl}general-settings/us-flag.svg`} alt='flag' />
				English (US)
			</div>
		),
	},
	{
		value: LOCALES.ALBANIAN,
		label: (
			<div className='general-lang-values flex flex-align-center '>
				<img src={`${healthCareCdnUrl}general-settings/al-flag.svg`} alt='flag' />
				Shqip
			</div>
		),
	},
	{
		value: LOCALES.ARABIC,
		label: (
			<div className='general-lang-values flex flex-align-center '>
				<img src={`${healthCareCdnUrl}general-settings/uae-flag.svg`} alt='flag' />
				عربي
			</div>
		),
	},
	{
		value: LOCALES.GERMAN,
		label: (
			<div className='general-lang-values flex flex-align-center '>
				<img src={`${healthCareCdnUrl}general-settings/germany-flag.svg`} alt='flag' />
				Deutsch
			</div>
		),
	},
	{
		value: LOCALES.SPANISH,
		label: (
			<div className='general-lang-values flex flex-align-center '>
				<img src={`${healthCareCdnUrl}general-settings/spain-flag.svg`} alt='flag' />
				Español
			</div>
		),
	},
];
