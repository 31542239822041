import React, { useEffect } from 'react';
import classNames from 'classnames';
import { AlertTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { playSound } from 'components/CallSounds.jsx';

const PopUpAlert = props => {
	useEffect(() => {
		if (props.display && props.alertType !== AlertTypes.SUCCESS && !props.isSilent) {
			playSound(`${healthCareCdnUrl}sound/alert-danger-sound.mp3`, { muted: false, loop: false });
		}
	}, [props.alertType, props.display, props.isSilent]);

	useEffect(() => {
		let timeout;
		if (props.display && props.selfCloseTimeOut) {
			timeout = setTimeout(() => {
				props.onAlertClose();
			}, props.selfCloseTimeOut);
		}
		return () => clearTimeout(timeout);
	}, [props.selfCloseTimeOut, props.display]);

	return (
		<div
			className={classNames(
				'popup-alert-wrapper',
				props.display ? 'show' : 'hidden',
				props.center ? 'center-alert' : '',
				props.isRight ? 'right-alert' : '',
				props.isBottom ? 'is-bottom' : '',
				props.centerTop ? 'center-top-alert' : '',
				props.className || '',
				props.isRightBottom ? 'right-bottom-alert' : ''
			)}>
			<div className={`alert-body ${props.alertType}`}>
				<div className='alert-content'>
					{props.isMeasurementAlert && (
						<>
							<div>
								<img src={`${healthCareCdnUrl}icon/alert.svg`} alt='ico' />
							</div>
							<div
								className={`flex column-direction left-align-items ${props.onTextClick ? 'cursor-pointer' : ''}`}
								onClick={props.onTextClick}>
								<p className='alert-title'>{props.title}</p>
								<p className='alert-content'>{props.contentText}</p>
							</div>
						</>
					)}
					{!props.isMeasurementAlert && (
						<>
							<div>
								<i className='material-icons'>
									{props.alertType === AlertTypes.SUCCESS ? 'check_circle_outline' : 'error_outline'}
								</i>
							</div>
							<div>
								<p>{props.contentText}</p>
							</div>
						</>
					)}
					<div>
						{!props.removeCloseBtn && (
							<i className='material-icons cursor-pointer close-alert' onClick={props.onAlertClose}>
								close
							</i>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PopUpAlert;
