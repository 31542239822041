import React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const PatientHistoryIcon = props => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={18}
			height={18}
			viewBox='0 0 22 24'
			fill='none'
			color={LightTheme.colors.graySeven}
			{...props}>
			<path d='M12.5 1L10.5 3L12.5 5' stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round' />
			<path
				d='M11 3C16.5228 3 21 7.47715 21 13C21 18.5228 16.5228 23 11 23C5.47715 23 1 18.5228 1 13C1 10.6024 1.84378 8.40187 3.2506 6.67917'
				stroke='currentColor'
				strokeWidth={1.5}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M11.25 9.5C11.25 9.08579 10.9142 8.75 10.5 8.75C10.0858 8.75 9.75 9.08579 9.75 9.5H11.25ZM10.5 14.5H9.75C9.75 14.7361 9.86115 14.9584 10.05 15.1L10.5 14.5ZM12.05 16.6C12.3814 16.8485 12.8515 16.7814 13.1 16.45C13.3485 16.1186 13.2814 15.6485 12.95 15.4L12.05 16.6ZM9.75 9.5V14.5H11.25V9.5H9.75ZM10.05 15.1L12.05 16.6L12.95 15.4L10.95 13.9L10.05 15.1Z'
				strokeWidth={1.5}
				fill='currentColor'
			/>
		</svg>
	);
};
export default PatientHistoryIcon;
