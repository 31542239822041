import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

function Prescriptions(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={26} height={26} fill='none' {...props}>
			<path
				d='M6.077 6.077l7.18 7.18m-10.77 3.59a5.077 5.077 0 010-7.18l7.18-7.18a5.077 5.077 0 017.18 7.18l-7.18 7.18a5.077 5.077 0 01-7.18 0zM25 19.667a5.333 5.333 0 01-10.667 0m10.667 0a5.333 5.333 0 00-10.667 0m10.667 0H14.333'
				stroke={LightTheme.colors.grayFive}
				strokeWidth={1.5}
			/>
		</svg>
	);
}

export default Prescriptions;
