import React, { useState, useEffect, useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { enums } from '@solaborate/calls';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import { actionCreators as PatientsActionCreators } from 'state/patients/actions.js';
import Overview from 'containers/Overview.jsx';
import Conversation from 'views/Chat/Conversation.jsx';
import Visits from 'views/Partials/Visits.jsx';
import HealthData from 'views/Patients/Partials/HealthData.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import EmptyState from 'components/EmptyState.jsx';
import { getConversationByType } from 'api/messenger.js';
import {
	decodeHtml,
	getGender,
	getRoleConfigurationValue,
	getSomeRoleConfigurationsValues,
} from 'infrastructure/helpers/commonHelpers.js';
import ProfilePicture from 'components/ProfilePicture.jsx';
import translate from 'i18n-translations/translate.jsx';
import { PatientTabTypes, DeviceAvailability, UserRoles, PatientSummaryTypes, ConversationType } from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { getUserInfo, getUserRole } from 'infrastructure/auth.js';
import Modal from 'components/Modal.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import AlertHistory from 'components/AlertHistory.jsx';
import { StartQueryStringKeys } from 'calls/enums/index.js';
import { getPatientProfile, getPatientByUserId } from 'api/patients.js';
import { getCountries } from 'api/users.js';
import ViewPatientProfile from 'containers/CheckInAPatient/ViewPatientProfile.jsx';
import { RoundingSettings, GeneralAndMenuSettings, PatientsConfigsList } from 'constants/configurationEnums.js';
import PhysicalTherapyReports from 'containers/PhysicalTherapyReports.jsx';
import Alert from 'components/Alert.jsx';
import { getAdminGeneralSettingConfigValue } from 'infrastructure/helpers/configurationsHelpers.js';

const PatientMain = props => {
	const dispatch = useDispatch();
	const userInfo = getUserInfo();
	const socket = useContext(SocketContext);
	const match = useRouteMatch();
	const user = useSelector(state => state.user);
	const myPatients = useSelector(state => state.myPatients);
	const configurations = useSelector(state => state.configurations);
	const roleRoundingConfigurations = useSelector(state => state.configurations.roleRoundingConfigurations);
	const customRoleConfigurations = useSelector(state => state.configurations.customRoleConfigurations);
	const [showPatientData, setShowPatientData] = useState(false);
	const [isPatientProfilePictureVisible, setIsPatientProfilePictureVisible] = useState(false);
	const [conversationId, setConversationId] = useState(null);
	const [currentTabIndex, setCurrentTabIndex] = useState(0);
	const [overviewTab, setOverviewTab] = useState(0);
	const [currentTab, setCurrentTab] = useState(null);
	const [isFullProfile, setIsFullProfile] = useState(false);
	const [patientDetails, setPatientDetails] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [countries, setCountries] = useState([]);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const patientTabLinksCalls = [PatientTabTypes.CONVERSATION];

	const patientTabLinks = [
		getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.OVERVIEW) && PatientTabTypes.OVERVIEW,
		getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.CONVERSATION_HISTORY) &&
			PatientTabTypes.CONVERSATION,
		getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.PATIENT_HEALTH) && PatientTabTypes.HEALTH_DATA,
		getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.PHYSICAL_THERAPY) &&
			PatientTabTypes.PHYSICAL_THERAPY,
		getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.PATIENT_VISITS) && PatientTabTypes.VISITS,
		getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.ALERT_HISTORY) && PatientTabTypes.ALERT_HISTORY,
	].filter(Boolean);

	const getPatientDetails = async patientId => {
		if (props.isCallView) {
			return;
		}
		setIsLoading(true);
		const [patientProfileDetails, countriesList] = await Promise.all([
			patientId ? getPatientProfile(patientId) : getPatientByUserId(myPatients.selectedPatient.userId),
			getCountries(),
		]);
		const responseError = patientProfileDetails.error || countriesList.error;
		if (responseError) {
			setError(responseError.message);
			setIsLoading(false);
			return;
		}
		setSelectedCountry(countriesList.countries.find(item => item.code === patientProfileDetails.patient.profile?.country)?.name);
		setCountries(countriesList.countries);
		setPatientDetails(patientProfileDetails.patient);
		setIsLoading(false);
	};

	useEffect(() => {
		const patientHistoryTabs = [
			{ setting: RoundingSettings.RoundingAlertHistory, tab: PatientTabTypes.ALERT_HISTORY },
			{ setting: RoundingSettings.RoundingVisits, tab: PatientTabTypes.VISITS },
			{ setting: RoundingSettings.Wearables, tab: PatientTabTypes.WEARABLES },
			{ setting: RoundingSettings.RoundingHealthData, tab: PatientTabTypes.HEALTH_DATA },
		];
		patientHistoryTabs.forEach(item => {
			if (getRoleConfigurationValue(roleRoundingConfigurations, item.setting)) {
				patientTabLinksCalls.unshift(item.tab);
			}
		});
	}, [roleRoundingConfigurations, patientTabLinksCalls]);

	useEffect(() => {
		fetchData(props.isCallView);
		socket.on(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
		socket.on(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);
		getPatientDetails(myPatients.selectedPatient?.patientId);
		return () => {
			socket.off(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
			socket.off(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);
		};
	}, [myPatients.selectedPatient]);

	useEffect(() => {
		socket.on(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		return () => {
			socket.off(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		};
	}, [currentTab]);

	useEffect(() => {
		const changeTabFromNotification = () => {
			const { tab, deviceId } = match.params;
			if (parseInt(deviceId, 10) !== myPatients.selectedPatient?.userId) {
				let selectedPatient = myPatients.patients.find(patient => patient.userId === parseInt(deviceId, 10));
				if (!selectedPatient) {
					selectedPatient = myPatients.patients.length > 0 ? myPatients.patients[0] : null;
				}
				dispatch(PatientsActionCreators.setSelectedPatient(selectedPatient));
			}
			if (tab === PatientTabTypes.CONVERSATION) {
				openTab(1);
			}
		};
		if (!props.isCallView) {
			changeTabFromNotification();
		}
	}, [match.params.tab, match.params.deviceId]);

	useEffect(() => {
		setConversationId(null);

		if (match.path !== '/patients/:deviceId/:tab/:subTab') {
			if (match.params.tab === PatientTabTypes.CONVERSATION) {
				openTab(1);
			} else if (
				match.params.tab !== PatientTabTypes.ALERT_HISTORY ||
				!getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.ALERT_HISTORY)
			) {
				openTab(0);
			}
		}
	}, [myPatients.selectedPatient?.userId]);

	const loadConversationId = async () => {
		const response = await getConversationByType(
			ConversationType.INDIVIDUAL,
			myPatients.selectedPatient?.userId || props.patientId,
			0
		);
		setConversationId(response?.conversation?.id);
	};

	useEffect(() => {
		loadConversationId();
	}, [conversationId]);

	const fetchData = isCallView => {
		const { params } = match;
		let selectedPatient = myPatients.patients.find(patient => patient.userId === myPatients.selectedPatient?.userId);
		if (!selectedPatient) {
			selectedPatient = myPatients.patients.length > 0 && !props.patientGuidId ? myPatients.patients[0] : null;
		}
		dispatch(PatientsActionCreators.setSelectedPatient(selectedPatient));
		if (params.tab) {
			const tabIndex = isCallView
				? patientTabLinksCalls.findIndex(item => item === params.tab)
				: patientTabLinks.findIndex(item => item === params.tab);
			openTab(tabIndex);
		}

		setConversationId(null);
	};

	const handleNewMessage = data => {
		const { message } = data;
		const patients = [...myPatients.patients];
		const isMe = parseInt(message.sender.objectId, 10) === userInfo.userId;
		const patientId = isMe ? myPatients.selectedPatient?.userId : parseInt(message.sender.objectId, 10);
		const index = patients.findIndex(item => item.userId === patientId);
		const isCurrentTabOpened = props.isCallView
			? PatientTabTypes.CONVERSATION === currentTab
			: match.url.includes(`patients/${message.sender.objectId}`) && PatientTabTypes.CONVERSATION === currentTab;
		if (index === -1) {
			return;
		}
		const [recentPatient] = patients.splice(index, 1);

		let unReadMessageCount;
		if (recentPatient.lastActivity) {
			unReadMessageCount = isMe
				? recentPatient && recentPatient.lastActivity.unReadMessageCount
				: recentPatient && recentPatient.lastActivity.unReadMessageCount + 1;
		}
		recentPatient.lastActivity = {
			...recentPatient.lastActivity,
			message: message.content,
			unReadMessageCount: isCurrentTabOpened ? 0 : unReadMessageCount || 0,
		};
		patients.unshift(recentPatient);
		dispatch(PatientsActionCreators.setPatients(patients));
		if (isCurrentTabOpened) {
			updateReadStatus(data.conversationId);
		}
	};

	const onDeviceOffline = ({ helloDeviceId }) => {
		deviceStatusListener(helloDeviceId, false);
	};

	const onDeviceOnline = ({ helloDeviceId }) => {
		deviceStatusListener(helloDeviceId, true);
	};

	const deviceStatusListener = (helloDeviceId, isOnline) => {
		const patients = [...myPatients.patients];
		const selectedPatient = patients.find(
			patient => patient.devicesInfos.length > 0 && patient.devicesInfos.find(device => device.id === helloDeviceId)
		);
		if (selectedPatient) {
			const selectedDevice = selectedPatient?.devicesInfos.find(device => device.id === helloDeviceId);
			selectedDevice.availability = isOnline ? DeviceAvailability.ONLINE : DeviceAvailability.OFFLINE;
			dispatch(PatientsActionCreators.setPatients(patients));
		}
	};

	const updateReadStatus = (tabIndex, isCallView = null) => {
		if (
			PatientTabTypes.CONVERSATION === (isCallView ? patientTabLinksCalls[tabIndex] : patientTabLinks[tabIndex]) &&
			myPatients.selectedPatient?.lastActivity
		) {
			const selectedPatient = { ...myPatients.selectedPatient };
			selectedPatient.lastActivity.unReadMessageCount = 0;
			dispatch(PatientsActionCreators.setSelectedPatient(selectedPatient));
			const conversationNotifications = [...user.conversationNotifications];
			const selectedNotification = conversationNotifications.find(
				item => +item.contact.objectId === myPatients.selectedPatient?.userId
			);
			if (!selectedNotification) {
				return;
			}
			selectedNotification.unreadMessagesCount = 0;
			dispatch(userActionCreators.setConversationNotifications(conversationNotifications));
		}
	};

	const openTab = (tabIndex, isCallView = null) => {
		updateReadStatus(tabIndex, isCallView);
		setCurrentTab(isCallView ? patientTabLinksCalls[tabIndex] : patientTabLinks[tabIndex]);
		setCurrentTabIndex(tabIndex);
	};

	const togglePatientProfilePictureModal = () => {
		if (myPatients.selectedPatient?.profilePicture) {
			setIsPatientProfilePictureVisible(prevState => !prevState);
		}
	};

	const changeTabFromOverview = tab => {
		if (tab !== PatientSummaryTypes.PHYSICAL_THERAPIES) {
			return;
		}
		setCurrentTabIndex(patientTabLinks.findIndex(item => item === PatientTabTypes.PHYSICAL_THERAPY));
		setCurrentTab(patientTabLinks[PatientTabTypes.PHYSICAL_THERAPY]);
		setOverviewTab(tab);
	};

	const showVitalSigns = () => {
		setCurrentTabIndex(patientTabLinks.findIndex(item => item === PatientTabTypes.HEALTH_DATA));
		setCurrentTab(patientTabLinks[PatientTabTypes.HEALTH_DATA]);
	};

	const getCallLink = type => {
		const queryParams = new URLSearchParams({
			[StartQueryStringKeys.OBJECT_ID]: myPatients.selectedPatient?.userId,
			[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.USER,
			[StartQueryStringKeys.CONFERENCE_NAME]: `${myPatients.selectedPatient?.firstName} ${myPatients.selectedPatient?.lastName}`,
			[StartQueryStringKeys.CALL_TYPE]: type,
		});
		return `/call?${queryParams.toString()}`;
	};

	const isPatientTabEnabled = () => getSomeRoleConfigurationsValues(customRoleConfigurations, PatientsConfigsList);

	return (
		<>
			{!props.isCallView && (
				<>
					<div className='patient-convo full-height'>
						{myPatients.patients.length === 0 && !myPatients.selectedPatient && (
							<div className='full-height no-records-raport'>
								<EmptyState
									title='No connected patients'
									image='patients.svg'
									paragraph='Search for patients to connect to their HELLO devices!'
								/>
							</div>
						)}
						<div
							className={`patient-main-view-wrapper ${!showPatientData ? 'expanded-patient-view' : 'unexpanded-patient-view'}`}>
							<span className='toggle-patient-data' onClick={() => setShowPatientData(prevState => !prevState)}>
								{showPatientData && (
									<>
										<img src={`${healthCareCdnUrl}hide-profile.svg`} alt='icon' />
										{translate('hideProfile', {
											value: decodeHtml(`${myPatients.selectedPatient?.firstName} ${myPatients.selectedPatient?.lastName}`),
										})}
									</>
								)}
								{!showPatientData && (
									<>
										<img src={`${healthCareCdnUrl}show-profile.svg`} alt='icon' />
										<span>
											{translate('showProfile', {
												value: decodeHtml(`${myPatients.selectedPatient?.firstName} ${myPatients.selectedPatient?.lastName}`),
											})}
										</span>
									</>
								)}
							</span>
							{!isPatientTabEnabled() && (
								<div className='flex flex-justify-center'>
									<p>{translate('checkFeatureAvailability')}</p>
								</div>
							)}
							<Tabs activeIndex={currentTabIndex} onChange={index => openTab(index)}>
								<TabList>
									{getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.OVERVIEW) && (
										<Tab>{translate('overview')}</Tab>
									)}
									{getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.CONVERSATION_HISTORY) && (
										<Tab>
											{myPatients.selectedPatient?.lastActivity?.unReadMessageCount > 0 && (
												<div className='conversation-label-blue'>
													{myPatients.selectedPatient?.lastActivity.unReadMessageCount}
												</div>
											)}
											{translate('conversation')}
										</Tab>
									)}
									{getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.PATIENT_HEALTH) && (
										<Tab>{translate('health')}</Tab>
									)}
									{getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.PHYSICAL_THERAPY) && (
										<Tab>{translate('physicalTherapy')}</Tab>
									)}
									{getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.PATIENT_VISITS) && (
										<Tab>{translate('visits')}</Tab>
									)}
									{getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.ALERT_HISTORY) && (
										<Tab>{translate('alertHistory')}</Tab>
									)}
								</TabList>
								<TabPanels>
									{getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.OVERVIEW) && (
										<Overview
											patient={myPatients.selectedPatient}
											changeTab={tab => changeTabFromOverview(tab)}
											conversationId={conversationId}
											showVitalSigns={showVitalSigns}
											isHealthShown={getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.PATIENT_HEALTH)}
											isPhysicalTherapyShown={getRoleConfigurationValue(
												customRoleConfigurations,
												GeneralAndMenuSettings.PHYSICAL_THERAPY
											)}
										/>
									)}
									{getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.CONVERSATION_HISTORY) && (
										<TabPanel>
											<Conversation
												selectedUser={{
													id: myPatients.selectedPatient?.userId,
													name: `${myPatients.selectedPatient?.firstName} ${myPatients.selectedPatient?.lastName}`,
													profilePicture: myPatients.selectedPatient?.profilePicture,
												}}
												conversationId={conversationId}
												isFromDoctor={true}
												isPatientDoctorChat={true}
												hideRightSide={
													!getAdminGeneralSettingConfigValue(configurations, GeneralAndMenuSettings.CONVERSATION_FILES)
												}
											/>
										</TabPanel>
									)}
									{getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.PATIENT_HEALTH) && (
										<TabPanel>
											{!isLoading && (
												<HealthData
													patientRpmProgram={patientDetails?.rpmProgram}
													selectedPatient={myPatients.selectedPatient}
													isDoctorView={true}
													isCallView={props.isCallView}
												/>
											)}
										</TabPanel>
									)}
									{getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.PHYSICAL_THERAPY) && (
										<TabPanel>
											<PhysicalTherapyReports
												patientId={myPatients.selectedPatient?.userId}
												conversationId={myPatients.selectedPatient?.lastActivity?.conversationId}
												patientFirstName={myPatients.selectedPatient?.firstName}
											/>
										</TabPanel>
									)}
									{getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.PATIENT_VISITS) && (
										<TabPanel>
											<Visits selectedPatient={myPatients.selectedPatient} />
										</TabPanel>
									)}
									{getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.ALERT_HISTORY) && (
										<TabPanel>
											<AlertHistory
												patientId={myPatients.selectedPatient?.id}
												defaultTab={overviewTab}
												isCallView={props.isCallView}
											/>
										</TabPanel>
									)}
								</TabPanels>
							</Tabs>
							<div className='tabs-box-shadow' />
							{showPatientData && (
								<>
									<div className='patient-info'>
										<div>
											<div className='patient-info-header'>
												<div
													className={`${myPatients.selectedPatient?.profilePicture ? 'cursor-pointer' : ''}`}
													onClick={togglePatientProfilePictureModal}>
													<ProfilePicture
														fullName={`${myPatients.selectedPatient?.firstName} ${myPatients.selectedPatient?.lastName}`}
														profilePicture={myPatients.selectedPatient?.profilePicture}
													/>
												</div>
												<h3>{decodeHtml(`${myPatients.selectedPatient?.firstName} ${myPatients.selectedPatient?.lastName}`)}</h3>
												<div className='flex patient-info-call'>
													<Link to={getCallLink(enums.CallTypes.VIDEO)} target='_blank'>
														<div className='patient-info-video patient-info-inner-div' />
													</Link>
													{currentTab !== PatientTabTypes.CONVERSATION && (
														<div
															className='patient-info-open-convo'
															onClick={() => openTab(patientTabLinks.findIndex(item => item === PatientTabTypes.CONVERSATION))}
														/>
													)}
												</div>
											</div>
											<div className='patient-info-body'>
												<div>
													<p>{translate('sex')}</p>
													<p>{getGender(myPatients.selectedPatient?.genderId).description}</p>
												</div>
												<div>
													<p>{translate('age')}</p>
													<p>{moment().diff(myPatients.selectedPatient?.dateOfBirth, 'years', false)}</p>
												</div>
												{myPatients.selectedPatient?.patientId && (
													<div className='button flex flex-justify-center' onClick={() => setIsFullProfile(true)}>
														<span className='font-14'>{translate('showFullProfile')}</span>
													</div>
												)}
											</div>
										</div>
									</div>
									<Modal
										display={isPatientProfilePictureVisible && myPatients.selectedPatient?.profilePicture}
										onModalClose={togglePatientProfilePictureModal}
										position='center'
										className='standard-modal-wrapper modal-wrapper-wo-btn maximize-profile-modal'>
										<ProfilePicture
											fullName={`${myPatients.selectedPatient?.firstName} ${myPatients.selectedPatient?.lastName}`}
											profilePicture={myPatients.selectedPatient?.profilePicture}
											dynamicSize={true}
										/>
									</Modal>
								</>
							)}
						</div>
					</div>
					<ViewPatientProfile
						display={isFullProfile}
						onModalClose={() => setIsFullProfile(false)}
						onModalSubmit={null}
						setIsSecondTabEnabled={false}
						patientDetails={patientDetails?.profile}
						userId={patientDetails?.userId}
						setSelectedPatient={null}
						setIsPatientFormVisible={null}
						selectedPatientId={patientDetails?.id}
						unitPreferences={user.unitPreferences}
						countries={countries}
						selectedCountry={selectedCountry}
						isViewOnly={true}
					/>
				</>
			)}
			{props.isCallView && props.isPatientHistoryVisible && (
				<Tabs activeIndex={currentTabIndex} onChange={index => openTab(index, props.isCallView)}>
					<TabList>
						{getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RoundingHealthData) && (
							<Tab>{translate('healthData')}</Tab>
						)}
						{getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.Wearables) && (
							<Tab>{translate('wearables')}</Tab>
						)}
						{getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RoundingVisits) && (
							<Tab>{translate('visits')}</Tab>
						)}
						{getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RoundingAlertHistory) && (
							<Tab>{translate('alertHistory')}</Tab>
						)}
						{myPatients.selectedPatient?.userId && getUserRole() === UserRoles.DOCTOR && (
							<Tab>
								{myPatients.selectedPatient?.lastActivity?.unReadMessageCount > 0 && (
									<div className='conversation-label-blue'>{myPatients.selectedPatient?.lastActivity.unReadMessageCount}</div>
								)}
								{translate('conversation')}
							</Tab>
						)}
					</TabList>
					<TabPanels>
						{getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RoundingHealthData) && (
							<TabPanel>
								<HealthData
									selectedPatient={{
										...myPatients.selectedPatient,
										userId: props.patientId,
										fullName: props.fullName,
										id: props.patientGuidId || myPatients.selectedPatient?.id,
									}}
									patientRpmProgram={props.rpmProgram}
									showPatientData={showPatientData}
									isDoctorView={true}
									isCallView={props.isCallView}
									isHealthShown={true}
								/>
							</TabPanel>
						)}
						{getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.Wearables) && (
							<TabPanel>
								<HealthData
									selectedPatient={{
										...myPatients.selectedPatient,
										userId: props.patientId,
										fullName: props.fullName,
										id: props.patientGuidId || myPatients.selectedPatient?.id,
									}}
									showPatientData={showPatientData}
									isDoctorView={true}
									isCallView={props.isCallView}
									isGalaxyWatch={true}
								/>
							</TabPanel>
						)}
						{getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RoundingVisits) && (
							<TabPanel>
								<Visits
									selectedPatient={{ ...myPatients.selectedPatient, id: props.patientGuidId || myPatients.selectedPatient?.id }}
								/>
							</TabPanel>
						)}
						{getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RoundingAlertHistory) && (
							<TabPanel>
								<AlertHistory
									patientId={myPatients.selectedPatient?.id || props.patientGuidId}
									defaultTab={overviewTab}
									isCallView={props.isCallView}
								/>
							</TabPanel>
						)}
						{myPatients.selectedPatient?.userId && getUserRole() === UserRoles.DOCTOR && (
							<TabPanel>
								<Conversation
									selectedUser={{
										id: myPatients.selectedPatient?.userId,
										name: `${myPatients.selectedPatient?.firstName} ${myPatients.selectedPatient?.lastName}`,
										profilePicture: myPatients.selectedPatient?.profilePicture,
									}}
									conversationId={conversationId}
									isFromDoctor={true}
									isCallView={props.isCallView}
									isDarkMode={props.isDarkMode}
								/>
							</TabPanel>
						)}
					</TabPanels>
				</Tabs>
			)}
			{error && <Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />}
		</>
	);
};

export default PatientMain;
