import * as React from "react"
import LightTheme from 'calls/styles/LightTheme.js';

const Syrup = (props) => (
    <svg
        width={35}
        height={34}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={LightTheme.colors.graySeven}
        {...props}
    >
        <path
            d="m21.75 7.083 2.65 3.534c.793 1.057 1.19 1.586 1.395 2.201.205.616.205 1.277.205 2.599v6.333c0 3.771 0 5.657-1.172 6.828C23.657 29.75 21.771 29.75 18 29.75h-1c-3.771 0-5.657 0-6.828-1.172C9 27.407 9 25.521 9 21.75v-6.333c0-1.322 0-1.983.205-2.599.206-.615.602-1.144 1.395-2.201l2.65-3.534"
            stroke="currentColor"
            strokeWidth={2}
        />
        <rect
            x={11.833}
            y={4.25}
            width={11.333}
            height={2.833}
            rx={1}
            stroke="currentColor"
            strokeWidth={2}
        />
        <path
            d="M17.5 17v5.667m-2.833-2.834h5.666M9 14.167h17M9 25.5h17"
            stroke="currentColor"
            strokeWidth={2}
        />
    </svg>
)

export default Syrup
