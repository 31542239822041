import React from 'react';

const CallStats = ({ stats, connectionQualityColor }) => (
	<div className='call-stats'>
		<span>
			<i className='material-icons' style={{ background: connectionQualityColor }}>
				bar_chart
			</i>
		</span>
		<div>
			{stats.map(({ key, value }) => (
				<p key={key}>
					{key}: {value}
				</p>
			))}
		</div>
	</div>
);

export default CallStats;
