import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { BradenScore } from 'constants/roomSign.js';
import { getBradenScore, setBradenScore } from 'api/whiteboard.js';
import { Alert, Button, CustomDropdown } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';

const RoomSignBradenScore = ({ deviceOwnerId, deviceId }) => {
	const socket = useContext(SocketContext);
	const intl = useIntl();
	const [expanded, setExpanded] = useState(true);
	const [error, setError] = useState(null);
	const [bradenScoreValue, setBradenScoreValue] = useState(null);

	const bradenScoreOptions = Array.from({ length: 18 }, (_, i) => {
		const value = i + 6;
		return { key: value, label: value.toString() };
	});

	useEffect(() => {
		const fetchData = async () => {
			const response = await getBradenScore(deviceOwnerId);
			if (response.error) {
				setError(translate('somethingWentWrong'));
				return;
			}

			const foundBradenScore = response.observations.find(item => item.code === BradenScore.Code);

			if (foundBradenScore?.valueInt) {
				setBradenScoreValue(foundBradenScore.valueInt);
			}
		};

		if (deviceOwnerId) {
			fetchData();
		}
	}, [deviceOwnerId]);

	const handleSelectBradenScore = async selectedOption => {
		const selectedValue = selectedOption.value;
		const response = await setBradenScore(deviceOwnerId, selectedValue, deviceId);

		if (response.error) {
			setError(translate('somethingWentWrong'));
			return;
		}

		setBradenScoreValue(selectedValue);
	};

	useEffect(() => {
		const handleBradenScoreUpdate = data => {
			if (data.deviceId !== deviceId) {
				return;
			}

			if (data.observations?.length > 0) {
				const latestBradenScore = data.observations[data.observations.length - 1];

				if (latestBradenScore?.code === BradenScore.Code && latestBradenScore?.valueInt) {
					setBradenScoreValue(latestBradenScore.valueInt);
				}
			}
		};

		socket.on(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_ADDED, handleBradenScoreUpdate);
		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_ADDED, handleBradenScoreUpdate);
		};
	}, [socket, deviceId]);

	return (
		<div className='room-sign-box'>
			<div className={classNames('timeline-box-header', { expanded })}>
				<p className='timeline-box-title'>{translate('bradenScore')}</p>
				<div className='timeline-box-actions'>
					<Button
						icon={expanded ? 'expand_less' : 'expand_more'}
						onClick={() => setExpanded(prevState => !prevState)}
						border='none'
					/>
				</div>
			</div>
			{expanded && (
				<div className={classNames('timeline-box-content', { expanded })}>
					<div className='flex flex-align-center'>
						<p className='timeline-box-title flex-1 left-s'>{translate('selectLevelofBradenScore')}</p>
						<div>
							<CustomDropdown
								defaultOptions={
									bradenScoreValue
										? {
												value: bradenScoreOptions.find(item => item.key === bradenScoreValue)?.key,
												label: bradenScoreOptions.find(item => item.key === bradenScoreValue)?.label,
										  }
										: []
								}
								initialOptions={bradenScoreOptions.map(item => ({ value: item.key, label: item.label }))}
								onSelect={handleSelectBradenScore}
								title={intl.formatMessage({ id: 'bradenScore' })}
								showTitleInPlaceholder={false}
								placeholder=''
								isMulti={false}
							/>
						</div>
					</div>
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default RoomSignBradenScore;
