import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Profile = props => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			color={LightTheme.colors.grayZero}
			{...props}>
			<path
				d='M16.5 19.7727V17.4775C16.5 14.9923 14.4853 12.9775 12 12.9775V12.9775C9.51472 12.9775 7.5 14.9923 7.5 17.4775V19.7727'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M14.4545 17.7273V20.5909' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M9.54547 17.7273L9.54547 20.5909' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
			<path
				d='M14.4546 8.72725C14.4546 10.0829 13.3556 11.1818 12 11.1818C10.6444 11.1818 9.54547 10.0829 9.54547 8.72725C9.54547 7.37164 10.6444 6.27271 12 6.27271C13.3556 6.27271 14.4546 7.37164 14.4546 8.72725Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<circle cx='12' cy='12' r='9' stroke='currentColor' />
		</svg>
	);
};

export default Profile;
