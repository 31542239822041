import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const NightVisionOn = props => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		color={LightTheme.colors.graySeven}
		{...props}>
		<path
			d='M4.62695 8.68681C4.22306 9.65458 4 10.7167 4 11.8309C4 16.3425 7.65745 20 12.1691 20C13.2847 20 14.348 19.7764 15.3167 19.3716M5.82572 6.68302C6.84908 5.42353 8.24 4.47473 9.8351 4C9.8351 4 8.08457 7.74618 12.1691 11.8309C16.2537 15.9155 20 14.1649 20 14.1649C19.5086 15.8162 18.509 17.2487 17.1827 18.281'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M1 5L19 23' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
	</svg>
);

export default NightVisionOn;
