import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'views/Onboarding/css/onboarding.css';
import moment from 'moment';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Alert from 'components/Alert.jsx';
import Genders from 'components/Genders.jsx';
import Button from 'components/Button.jsx';
import { signUpPatient, checkUserEmailExists } from 'api/users.js';
import { HttpStatusCodes, Agreements } from 'constants/enums.js';
import { genderItems } from 'constants/genderItems.js';
import { APP_CONFIG, healthCareCdnUrl } from 'constants/global-variables.js';
import GoogleReCaptchaV3 from 'services/GoogleReCaptchaV3.js';
import { capitalizeFirstLetter } from 'infrastructure/helpers/commonHelpers.js';
import FormInput from 'components/FormInput.jsx';
import translate from 'i18n-translations/translate.jsx';
import UserSignUpSuccess from 'views/Onboarding/UserSignUpSuccess.jsx';

const PatientOnboarding = () => {
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	const intl = useIntl();
	const location = useLocation();
	const history = useHistory();

	const onboardingImgUrl = 'https://static.solaborate.com/onboarding-process/';

	const initialValues = {
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		confirmPassword: '',
		dateOfBirth: '',
		genderId: '',
		agreeTerms: false,
		agreePrivacyPolicy: false,
	};

	const { current: reCaptchaV3 } = useRef(new GoogleReCaptchaV3(APP_CONFIG.googleRecaptchaKey));

	useEffect(() => {
		const { state } = location;
		if (!state || (state && !state.company)) {
			history.push('/onboarding/select-company');
		}
	}, [history, location]);

	useEffect(() => {
		const loadReCaptcha = async () => {
			const googleReCaptcha = await reCaptchaV3.loadReCaptcha();
			if (!googleReCaptcha) {
				setError(translate('recaptchaLoadError'));
			}
		};
		loadReCaptcha();
	}, [reCaptchaV3]);

	const validateForm = () => {
		const pageNumber = 0;
		switch (pageNumber) {
			case 0:
				return Yup.object().shape({
					firstName: Yup.string()
						.required(
							intl.formatMessage({
								id: 'pleaseWriteFirstName',
							})
						)
						.min(
							2,
							intl.formatMessage({
								id: 'firstNameMinLength',
							})
						)
						.max(
							30,
							intl.formatMessage({
								id: 'maxCharacterLengthExceeded',
							})
						)
						.trim(),
					lastName: Yup.string()
						.required(intl.formatMessage({ id: 'pleaseWriteLastName' }))
						.min(
							2,
							intl.formatMessage({
								id: 'lastNameMinLength',
							})
						)
						.max(
							30,
							intl.formatMessage({
								id: 'maxCharacterLengthExceeded',
							})
						)
						.trim(),
					email: Yup.string()
						.email(intl.formatMessage({ id: 'invalidEmail' }))
						.required(intl.formatMessage({ id: 'pleaseWriteEmail' }))
						.test('existing-email', intl.formatMessage({ id: 'userAlreadyExists' }), val => validateEmail(val)),
					password: Yup.string()
						.required(intl.formatMessage({ id: 'pleaseWritePassword' }))
						.matches(
							// eslint-disable-next-line no-useless-escape
							/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*\(\)_\+\-\={}<>,\.\|""'~`:;\\?\/\[\] ]){8,}/,
							intl.formatMessage({ id: 'passwordCannotBeLessThan8Chart' })
						)
						.min(8, `${intl.formatMessage({ id: 'minLengthIs' })} 8`),
					confirmPassword: Yup.string()
						.oneOf([Yup.ref('password'), null], intl.formatMessage({ id: 'passwordsMustMatch' }))
						.required(intl.formatMessage({ id: 'pleaseWriteConfirmPassword' }))
						.min(8, `${intl.formatMessage({ id: 'minLengthIs' })} 8`),
					agreeTerms: Yup.bool()
						.test('consent', intl.formatMessage({ id: 'haveToAgreeToTermsAndConditions' }), value => value === true)
						.required(intl.formatMessage({ id: 'haveToAgreeToTermsAndConditions' })),
					agreePrivacyPolicy: Yup.bool()
						.test('consent', intl.formatMessage({ id: 'haveToAgreePrivacyPolicy' }), value => value === true)
						.required(intl.formatMessage({ id: 'haveToAgreePrivacyPolicy' })),
					dateOfBirth: Yup.string()
						.test('dateOfBirth', intl.formatMessage({ id: 'checkDateOfBirth' }), value =>
							moment(value).isBetween(moment().subtract(120, 'years'), moment().subtract(18, 'years'))
						)
						.required(intl.formatMessage({ id: 'pleaseWriteDateOfBirth' })),
					genderId: Yup.string().required(intl.formatMessage({ id: 'pleaseSelectSex' })),
				});
			default:
				return null;
		}
	};

	const validateEmail = async val => {
		if (!val) {
			return true;
		}
		let emailExists = false;
		const schema = Yup.string()
			.email()
			.required();
		if (await schema.isValid(val)) {
			const response = await checkUserEmailExists(val);
			if (response.error) {
				setError(response.error.message);
				emailExists = false;
			} else {
				emailExists = response;
			}
		}
		return !emailExists;
	};

	const getAgreementCheckBox = (inputName, hrefLink, content, props) => {
		return (
			<div className='flex'>
				<label className='remember-me'>
					<input
						onBlur={props.handleBlur}
						type='checkbox'
						onChange={props.handleChange}
						id={inputName}
						name={inputName}
						value={props.values[inputName]}
						checked={props.values[inputName]}
					/>
					<div className='onb-custom-checkbox-wrapper'>
						<div className='onb-custom-checkbox' />
					</div>
					{translate('agreeToThe')}{' '}
					<Link className='agreement-link-onboarding' target='_blank' to={hrefLink}>
						<span className='blue-color semi-bold'>{content}</span>
					</Link>
					<br />
					{props.errors[inputName] && props.touched[inputName] && <span className='red-error'>{props.errors[inputName]}</span>}
				</label>
			</div>
		);
	};

	const onSubmitHandler = async (values, { setSubmitting }) => {
		const reCaptchaToken = await reCaptchaV3.getToken();
		if (!reCaptchaToken) {
			setError(translate('recaptchaLoadError'));
		}
		const userAgreements = [
			{
				agreementId: Agreements.TERMS_AND_CONDITIONS,
				hasAgreed: values.agreeTerms,
			},
			{
				agreementId: Agreements.PRIVACY_POLICY,
				hasAgreed: values.agreePrivacyPolicy,
			},
		];
		const signUpData = {
			...values,
			firstName: capitalizeFirstLetter(values.firstName),
			lastName: capitalizeFirstLetter(values.lastName),
			userAgreements,
			genderId: parseInt(values.genderId, 10),
			reCaptchaToken,
		};
		setSubmitting(true);
		const response = await signUpPatient(signUpData, location.state.company.value);
		if (response.error) {
			setError(response.error.message);
		} else {
			setSuccess(response.status === HttpStatusCodes.CREATED);
		}
		setSubmitting(false);
	};

	const onBackHandler = () => {
		history.push({
			pathname: '/onboarding/select-company',
			...(location.state?.company && { state: { company: location.state?.company } }),
		});
	};

	return (
		<>
			{!success && (
				<div className='patient-onboarding flex'>
					<div className='patient-box lg-box'>
						<Alert display={error} message={error} variant='error' onClose={() => setError(null)} />
						<div className='flex flex-space-between'>
							<div className='logo'>
								<img src={`${healthCareCdnUrl}hellocare-logo.svg`} alt='logo' />
							</div>
						</div>
						<Formik
							initialValues={initialValues}
							onSubmit={onSubmitHandler}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={validateForm}>
							{props => {
								const { values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting } = props;
								return (
									<>
										<div className='flex'>
											<div className='patient-box-left-info'>
												<h4>{translate('personalInformation')}</h4>
												<p>{translate('fillUpFormOnRightSide')}</p>
											</div>
											<div className='patient-box-right-form'>
												<div className='flex flex-space-between flex-wrap'>
													<FormInput
														text={translate('firstName')}
														id='firstName'
														placeholder={intl.formatMessage({ id: 'firstName' })}
														type='text'
														value={capitalizeFirstLetter(values.firstName)}
														onChange={handleChange}
														onBlur={handleBlur}
														error={errors.firstName}
														touched={touched.firstName}
														validateOnSubmit={true}
														maxLength={256}
													/>
													<FormInput
														text={translate('lastName')}
														id='lastName'
														placeholder={intl.formatMessage({ id: 'lastName' })}
														type='text'
														value={capitalizeFirstLetter(values.lastName)}
														onChange={handleChange}
														onBlur={handleBlur}
														error={errors.lastName}
														touched={touched.lastName}
														validateOnSubmit={true}
														maxLength={256}
													/>
												</div>

												<div className='flex flex-space-between flex-wrap'>
													<FormInput
														text={translate('email')}
														id='email'
														placeholder={intl.formatMessage({ id: 'writeYourEmail' })}
														type='email'
														value={values.email}
														onChange={handleChange}
														onBlur={handleBlur}
														error={errors.email}
														touched={touched.email}
														validateOnSubmit={true}
														maxLength={512}
													/>
													<FormInput
														text={translate('dateOfBirth')}
														id='dateOfBirth'
														type='date'
														max={new Date().toISOString().split('T')[0]}
														value={values.dateOfBirth}
														onChange={handleChange}
														onBlur={handleBlur}
														error={errors.dateOfBirth}
														touched={touched.dateOfBirth}
														validateOnSubmit={true}
														className={!values.dateOfBirth ? 'defaultColor' : ''}
													/>
												</div>
												<div className='flex flex-space-between flex-wrap'>
													<FormInput
														text={translate('password')}
														id='password'
														placeholder={intl.formatMessage({ id: 'password' })}
														type='password'
														value={values.password}
														onChange={handleChange}
														onBlur={handleBlur}
														error={errors.password}
														touched={touched.password}
														validateOnSubmit={true}
														maxLength={32}
													/>
													<FormInput
														text={translate('confirmPassword')}
														id='confirmPassword'
														placeholder={intl.formatMessage({ id: 'confirmPassword' })}
														type='password'
														value={values.confirmPassword}
														onChange={handleChange}
														onBlur={handleBlur}
														error={errors.confirmPassword}
														touched={touched.confirmPassword}
														validateOnSubmit={true}
														maxLength={32}
													/>
												</div>
												<div>
													<Genders
														items={genderItems}
														handleChange={handleChange}
														handleBlur={handleBlur}
														errors={errors}
														touched={touched}
														value={values.genderId}
													/>
												</div>
												{getAgreementCheckBox('agreeTerms', '/terms-of-use', translate('termsAndConditions'), props)}
												{getAgreementCheckBox('agreePrivacyPolicy', '/privacy-policy', translate('privacyPolicy'), props)}
												<div className='flex flex-space-between flex-wrap full-width top-30'>
													<label className='google-recaptcha-wrapper patient-recaptcha-wrapper full-width'>
														<span>
															This site is protected by reCAPTCHA and the Google&nbsp;
															<a rel='noopener noreferrer' target='_blank' href='https://policies.google.com/privacy'>
																Privacy Policy
															</a>
															&nbsp;and&nbsp;
															<a rel='noopener noreferrer' target='_blank' href='https://policies.google.com/terms'>
																Terms of Service
															</a>
															&nbsp;apply.
														</span>
													</label>
												</div>
											</div>
										</div>
										<div className='flex onboarding-buttons'>
											<div className='flex-1'>
												<span>
													{translate('alreadyHaveAnAccount')}? <Link to='/login'>{translate('clickHereToSignIn')}</Link>
												</span>
											</div>
											<div className='flex'>
												<Button
													type='button'
													onClick={onBackHandler}
													imgIcon={`${onboardingImgUrl}go-back.svg`}
													text={translate('goBack')}
													variant='gray-border'
												/>

												<Button
													type='button'
													isLoading={isSubmitting}
													onClick={handleSubmit}
													nextStepIcon={`${onboardingImgUrl}continue.svg`}
													text={translate('signUp')}
												/>
											</div>
										</div>
									</>
								);
							}}
						</Formik>
					</div>
				</div>
			)}
			{success && <UserSignUpSuccess />}
		</>
	);
};

export default PatientOnboarding;
