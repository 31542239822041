import _ from 'lodash';

const spliceItems = (array, intl) => {
	const result = {};
	for (let i = 1; i < 8; i += 1) {
		const foundIndex = array.findIndex(item => item.weekDay === i);
		if (foundIndex !== -1) {
			const [spliced] = array.splice(foundIndex, 1);
			result[getWeekDayString(i, intl)] = spliced.from ? `${spliced.from} - ${spliced.to}` : '';
		} else {
			result[getWeekDayString(i, intl)] = '';
		}
	}
	return result;
};

const getWeekDayString = (weekDay, intl) => {
	let result;
	switch (weekDay) {
		case 1: {
			result = intl.formatMessage({ id: 'monday' });
			break;
		}
		case 2: {
			result = intl.formatMessage({ id: 'tuesday' });
			break;
		}
		case 3: {
			result = intl.formatMessage({ id: 'wednesday' });
			break;
		}
		case 4: {
			result = intl.formatMessage({ id: 'thursday' });
			break;
		}
		case 5: {
			result = intl.formatMessage({ id: 'friday' });
			break;
		}
		case 6: {
			result = intl.formatMessage({ id: 'saturday' });
			break;
		}
		case 7: {
			result = intl.formatMessage({ id: 'sunday' });
			break;
		}
		default: {
			result = 'N/A';
		}
	}
	return result;
};

export const reArrangeVisitingHours = (list, intl) => {
	if (list.length === 0) {
		return [];
	}
	const reMaped = _.cloneDeep(list)
		.map(item => item.hours.map(hour => ({ ...hour, weekDay: item.weekDay })))
		.flat();
	const result = [];
	if (!reMaped.some(item => item.from)) {
		return result;
	}
	while (reMaped.length > 0) {
		if (reMaped.length > 0) {
			result.push(spliceItems(reMaped, intl));
		}
	}
	return result;
};

export const getInitialVisitingHoursMaped = (list = []) => {
	const mapedVisitingHours = list.map(item => ({
		...item,
		hours: item.hours.map((hour, index) => ({
			to: convertFrom24toAmPm(removeSeconds(hour.to)),
			from: convertFrom24toAmPm(removeSeconds(hour.from)),
			id: index,
		})),
	}));
	for (let i = 1; i < 8; i += 1) {
		if (!mapedVisitingHours.find(item => item.weekDay === i)) {
			mapedVisitingHours.push({
				weekDay: i,
				hours: [{ from: null, to: null, id: 0 }],
			});
		}
	}
	mapedVisitingHours.sort((a, b) => a.weekDay - b.weekDay);
	return mapedVisitingHours;
};

export const removeSeconds = stringDate => {
	const endingIndex = stringDate.lastIndexOf(':');
	return stringDate.substring(0, endingIndex);
};

const convertFrom24toAmPm = stringDate => {
	const date = new Date();
	const hours = stringDate.substring(0, stringDate.lastIndexOf(':'));
	const minutes = stringDate.substring(stringDate.lastIndexOf(':') + 1, stringDate.length);
	date.setHours(hours);
	date.setMinutes(minutes); 
	return date.toLocaleTimeString('en-US', {
		hour: '2-digit',
		minute: '2-digit',
	}).replace(/\s/g, '');
};
