import Dexie from 'dexie';

class IndexDb extends Dexie {
	/**
	 * Represents the IndexedDB collection that stores the
	 * necessary e2ee key-value data.
	 *
	 * @type {import('dexie').Table}
	 */
	keyStore;

	/**
	 * @param {string} userId
	 */
	constructor(userId) {
		super(userId);
		this.version(1).stores({
			keyStore: '',
		});

		this.deleteExistingDbs(userId);
	}

	/**
	 * Deletes existing indexedDbs if the participant that joined the multiparty
	 * call is not signed in as an existing user.
	 * @param {string} userId
	 * @memberof IndexDb class which extends Dexie library
	 */
	deleteExistingDbs = async userId => {
		const databases = await Dexie.getDatabaseNames();

		if (!databases.includes(userId)) {
			databases.forEach(async database => {
				if (database !== userId) {
					await Dexie.delete(database);
				}
			});
		}
	};
}

export default IndexDb;
