import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Country } from 'constants/enums.js';
import Select from 'components/Select.jsx';
import 'views/Onboarding/css/onboarding.css';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';

const SelectCountry = () => {
	const countries = [
		{
			name: 'United States',
			value: Country.USA,
		},
		{
			name: 'Kosovo',
			value: Country.KOSOVO,
		},
		{
			name: 'Albania',
			value: Country.ALBANIA,
		},
	];

	const [selectedCountry, setSelectedCountry] = useState(countries[0]);
	const history = useHistory();

	const onContinueHandler = () => {
		history.push({
			pathname: '/onboarding/doctor',
			state: { country: selectedCountry },
		});
	};

	const onSelectedCountryChange = event => {
		setSelectedCountry(countries.find(country => country.value.toString() === event.target.value));
	};

	return (
		<>
			<div className='patient-onboarding flex doctor-onboarding-wrapper'>
				<div className='patient-box lg-box'>
					<div className='flex flex-space-between'>
						<div className='logo'>
							<img src={`${healthCareCdnUrl}hellocare-logo.svg`} alt='logo' />
						</div>
					</div>
					<form>
						<div className='flex'>
							<div className='patient-box-left-info'>
								<h4>Select Country</h4>
								<p>Select your country in order to proceed with the next steps of the process.</p>
							</div>
							<div className='patient-box-right-form'>
								<div className='flex flex-space-between flex-wrap'>
									<Select
										label='Select Country'
										name='selectCountry'
										items={countries}
										valueField='value'
										textField='name'
										placeholder='Select'
										onSelect={onSelectedCountryChange}
									/>
								</div>
							</div>
						</div>
						<div className='flex onboarding-buttons'>
							<div className='flex-1'>
								<span>
									Already have an Account? <Link to='/login'>Click here to sign in</Link>
								</span>
							</div>
							<div>
								<Button
									onClick={onContinueHandler}
									imgIcon='https://static.solaborate.com/onboarding-process/continue.svg'
									text={translate('continue')}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default SelectCountry;
