import React, { useState } from 'react';
import { enums } from '@solaborate/calls';
import Alert from 'components/Alert.jsx';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { assignRequest } from 'api/patientRequests.js';
import { RequestStatus } from 'constants/enums.js';
import { StartQueryStringKeys } from 'calls/enums/index.js';

const CallPatientModal = ({ toggleCallPatientModal, isCallPatientModalOpen, patientCallRequests, stopVoiceOver }) => {
	const [error, setError] = useState('');

	const mainRequest = patientCallRequests[0];

	const patient = JSON.parse(mainRequest.payload).Request.Patient;

	const assignedRequestId = JSON.parse(mainRequest.payload).ActionId;

	const fullName = `${patient.FirstName} ${patient.LastName}`;

	const callPatient = async () => {
		stopVoiceOver();
		try {
			const queryParams = new URLSearchParams({
				[StartQueryStringKeys.OBJECT_ID]: patient.UserId,
				[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.USER,
				[StartQueryStringKeys.CONFERENCE_NAME]: fullName,
			});
			window.open(`/call?${queryParams.toString()}`, '_blank');
			await assignRequest(assignedRequestId, {
				requestStatusId: RequestStatus.ACCEPTED,
			});
		} catch (err) {
			setError(err.message);
		} finally {
			toggleCallPatientModal();
		}
	};

	const closeDialog = () => {
		stopVoiceOver();
		toggleCallPatientModal();
	};

	const getDescription = () => {
		if (patientCallRequests.length === 1) {
			return (
				<p>
					{fullName} {translate('hasRequestedAliveCall')}
				</p>
			);
		}
		if (patientCallRequests.length === 2) {
			const secondPatient = JSON.parse(patientCallRequests[1].payload).Request.Patient;
			return (
				<p>
					{fullName} {translate('and')} {secondPatient.FirstName} {secondPatient.LastName} {translate('haveRequestedAliveCall')}
				</p>
			);
		}
		if (patientCallRequests.length === 3) {
			const secondPatient = JSON.parse(patientCallRequests[1].payload).Request.Patient;
			const thirdPatient = JSON.parse(patientCallRequests[2].payload).Request.Patient;
			return (
				<p>
					{fullName}, {secondPatient.FirstName} {secondPatient.FirstName} {translate('and')} {thirdPatient.FirstName}
					{thirdPatient.LastName} {translate('haveRequestedAliveCall')}
				</p>
			);
		}
		return (
			<p>
				{fullName}{' '}
				{translate('andNumberOthers', {
					value: patientCallRequests.length - 1,
				})}
			</p>
		);
	};

	return (
		<Modal
			display={isCallPatientModalOpen}
			position='center'
			onModalClose={closeDialog}
			onModalSubmit={callPatient}
			primaryButtonLabel={translate('callPatient')}
			closeButtonText={translate('notNow')}
			shouldSubmitOnEnter={false}
			className='emergent-health-conditions'
			keepOpenOnOutsideClick={true}>
			<Form title={translate('liveCallRequest')}>
				<div className='flex'>
					<div>
						<ProfilePicture
							className='doctor-request-img available-doctor-request-img'
							firstName={patient.FirstName}
							lastName={patient.LastName}
							profilePicture={patient.ProfilePicture}
						/>
					</div>
					<div>
						{getDescription()}
						<div className='flex emergent-health-conditions-grid'>
							<div className='flex'>
								<div>
									<p>{translate('requestTime')}</p>
									<p>{formattedDate(mainRequest.creationDate)}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Form>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</Modal>
	);
};

export default CallPatientModal;
