import { useEffect, useState } from 'react';
import Avatar from 'components/Avatar.jsx';
import Video from 'components/Call/Video.jsx';

const TopParticipant = props => {
	const [canvasScreenDimensions, setCanvasScreenDimensions] = useState({ canvasFitScreenWidth: 0, canvasFitScreenHeight: 0 });

	const resizeEventListener = () => {
		setCanvasScreenDimensions({
			canvasFitScreenWidth: (window.innerHeight * 16) / 9,
			canvasFitScreenHeight: window.innerHeight,
		});
	};

	useEffect(() => {
		resizeEventListener();

		window.addEventListener('resize', resizeEventListener);

		return () => {
			window.removeEventListener('resize', resizeEventListener);
		};
	}, []);

	return (
		<div className={`call-view-video${props.isFitScreenOn ? ' fit-screen-bg' : ''}`}>
			{props.topParticipantFeed && props.isTopParticipantVideoActive ? (
				<>
					{props.imgSrc && (
						<img
							className={`call-view-static-image ${props.imgSrc ? 'active' : ''}`}
							src={props.imgSrc}
							alt='static'
							width={window.innerWidth}
							height={(window.innerWidth * 9) / 16}
						/>
					)}
					<Video
						autoPlay
						muted
						ref={props.videoRef}
						feed={props.topParticipantFeed.mediaStream}
						height={(window.innerWidth * 9) / 16}
						width={window.innerWidth}
						className={props.isFitScreenOn ? 'video-fit-screen' : ''}
					/>
					<canvas
						ref={props.canvasRef}
						height={props.isFitScreenOn ? canvasScreenDimensions.canvasFitScreenHeight : (window.innerWidth * 9) / 16}
						width={props.isFitScreenOn ? canvasScreenDimensions.canvasFitScreenWidth : window.innerWidth}
					/>
				</>
			) : (
				<div className='patient-audio-call-logo'>
					<Avatar src='https://static.solaborate.com/healthcare-system/blue-icon.svg?v3' size='large' fullName='' />
				</div>
			)}
		</div>
	);
};

export default TopParticipant;
