import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';

const AuditLogsDetails = props => (
	<Modal
		display={props.isViewDetailsModal}
		position='center'
		hideActionButtons={true}
		onModalSubmit={null}
		onModalClose={props.closeDetailsModal}
		className='wrapper-modal border-radius-modal-wrapper audit-logs-modal'>
		<form className='call-details'>
			<h3>{translate('viewDetails')}</h3>
			<pre>{JSON.stringify(JSON.parse(props.selectedMetaData), null, 2)}</pre>
		</form>
	</Modal>
);

export default AuditLogsDetails;
