import * as React from 'react';

const Education = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
		<rect width={14} height={18} x={5} y={3} stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' rx={2} />
		<path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' d='M19 16H7a2 2 0 0 0-2 2v1' />
		<path stroke='currentColor' strokeLinecap='round' d='M8 7h8M8 10h6' />
	</svg>
);
export default Education;
