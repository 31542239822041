import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const PatientHistory = props => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			color={LightTheme.colors.grayZero}
			{...props}>
			<path d='M13.5 1L11.5 3L13.5 5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
			<path
				d='M12 3C17.5228 3 22 7.47715 22 13C22 18.5228 17.5228 23 12 23C6.47715 23 2 18.5228 2 13C2 10.6024 2.84378 8.40187 4.2506 6.67917'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M11.5 9.5V14.5L13.5 16' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	);
};

export default PatientHistory;
