import { participant } from '@solaborate/calls';

const ParticipantStateMessage = {
	[participant.StateBusy.name]: 'is busy.',
	[participant.StateDeclined.name]: 'declined.',
	[participant.StateLeft.name]: 'left.',
	[participant.StateNotAnswering.name]: 'not answering.',
	[participant.StateOffline.name]: 'is offline.',
	[participant.StateRemoved.name]: 'removed.',
};

export default ParticipantStateMessage;
