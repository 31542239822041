const View = props => (
	<svg width='33' height='33' viewBox='0 0 33 33' fill='none' color='#7892C3' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M16.8889 8.50098C8.22225 8.50098 4.88892 16.501 4.88892 16.501C4.88892 16.501 8.22225 24.501 16.8889 24.501C25.5556 24.501 28.8889 16.501 28.8889 16.501C28.8889 16.501 25.5556 8.50098 16.8889 8.50098Z'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M20.2223 16.5013C20.2223 18.3423 18.7299 19.8346 16.889 19.8346C15.048 19.8346 13.5557 18.3423 13.5557 16.5013C13.5557 14.6604 15.048 13.168 16.889 13.168C18.7299 13.168 20.2223 14.6604 20.2223 16.5013Z'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
export default View;
