const MeasurementUnit = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} fill='none' viewBox='0 0 24 24' {...props}>
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M11.012 19.36a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5z'
		/>
		<path
			fill='currentColor'
			d='M16.209 11a.75.75 0 101.5 0h-1.5zM4.292 11a.75.75 0 001.5 0h-1.5zm1.5 0A5.208 5.208 0 0111 5.792v-1.5A6.708 6.708 0 004.292 11h1.5zM11 5.792A5.208 5.208 0 0116.21 11h1.5A6.708 6.708 0 0011 4.292v1.5z'
		/>
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M9.374 11.488l2.543-1.863.207.207-1.863 2.543-.887-.887z'
		/>
	</svg>
);

export default MeasurementUnit;
