import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const VoiceOverButton = props => {
	const parentDivRef = useRef(null);
	const intl = useIntl();

	const { toggleVoiceOverOptions } = props;

	useEffect(() => {
		const handleOutsideClick = event => {
			if (parentDivRef.current && !parentDivRef.current.contains(event.target) && props.isVoiceOverOptionsVisible) {
				toggleVoiceOverOptions();
			}
		};
		document.addEventListener('click', handleOutsideClick);
		
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, [props.isVoiceOverOptionsVisible, toggleVoiceOverOptions]);

	const getStayStillTooltip = () => {
		if (props.feed.isVoiceOverOtherInitiator) {
			return `${intl.formatMessage({ id: 'activeAlarmTriggered' })} ${props.feed.isVoiceOverOtherInitiator?.name}`;
		}
		if (props.feed.voiceOverAlert?.value) {
			return intl.formatMessage({ id: 'disableStayStill' });
		}
		return intl.formatMessage({ id: 'enableStayStill' });
	};

	const onVoiceOverBtnClick = () => {
		if (props.feed.isVoiceOverOtherInitiator) {
			return;
		}
		if (props.feed.voiceOverAlert) {
			props.toggleVoiceOver(props.feed, '', false);
			return;
		}
		toggleVoiceOverOptions();
	};

	return (
		<div
			id='voice-over-icon'
			ref={parentDivRef}
			className={classNames(
				'alert-timeline-btn call-button time-button',
				props.feed.voiceOverAlert ? 'active' : '',
				props.feed.isVoiceOverOtherInitiator ? 'disabled-voice-over' : '',
				props.feed.isStatAlarmActive ? 'disabled' : ''
			)}
			data-position={props.isVoiceOverOptionsVisible ? 'left' : 'top'}
			data-tooltip={getStayStillTooltip()}
			onClick={onVoiceOverBtnClick}>
			<>
				<div />
				{props.feed.voiceOverAlert?.value && <span>{props.feed.voiceOverAlert?.label}</span>}
				<ul
					className={classNames(
						'list-group workflow-list-group monitoring-nurses ai-level voice-over-monitoring-options',
						props.isVoiceOverOptionsVisible ? '' : 'hidden'
					)}>
					{props.voiceOverLanguages.map(item => (
						<span onClick={() => props.toggleVoiceOver(props.feed, item.value, true)}>
							<li
								className={classNames(
									'flex flex-align-center video-feed-list-item',
									props.feed.voiceOverAlert?.value === item.value ? 'selected-sensitivity' : ''
								)}>
								<img src={`${healthCareCdnUrl}general-settings/${item.img}`} alt='ico' />
								<p className='font-14'>{item.label}</p>
								{props.feed.voiceOverAlert?.value === item.value && <i className='material-icons-outlined'>done</i>}
							</li>
						</span>
					))}
				</ul>
			</>
		</div>
	);
};

export default VoiceOverButton;
