import { deleteCompanionDevice, sendCommand } from 'api/devices.js';
import { UserRoles } from 'constants/enums.js';
import Alert from 'components/Alert.jsx';
import Dropdown from 'components/Dropdown.jsx';
import Form from 'components/Form.jsx';
import Input from 'components/Input.jsx';
import Modal from 'components/Modal.jsx';
import RebootLogExport from 'components/RebootLogsExport.jsx';
import { DeviceCommands } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { useState } from 'react';
import { useIntl } from 'react-intl';

const CompanionDeviceActions = props => {
	const intl = useIntl();
	const userRole = getUserRole();
	const [selectedDevice, setSelectedDevice] = useState({
		serialNumber: '',
		deviceId: '',
	});
	const [isGetDeviceLogsOpen, setIsGetDeviceOpen] = useState(false);
	const [isRebootDeviceOpen, setIsRebootDeviceOpen] = useState(false);
	const [rebootReason, setRebootReason] = useState('');
	const [isDeleteDeviceModalOpen, setIsDeleteDeviceModalOpen] = useState(false);
	const [error, setError] = useState(null);

	const rebootDevice = async () => {
		if (selectedDevice.deviceId) {
			const params = {
				deviceId: selectedDevice.deviceId,
				command: DeviceCommands.REBOOT,
				reason: rebootReason,
			};
			const response = await sendCommand(params);
			if (response.error) {
				setError(intl.formatMessage({ id: 'errorDuringRequestProcessing' }));
				return;
			}
		}
		setIsRebootDeviceOpen(false);
	};

	const unAssignDevice = async () => {
		props.setIsDeleteDeviceModalLoading(true);
		const response = await deleteCompanionDevice(selectedDevice.deviceId);
		if (response.error) {
			setError(intl.formatMessage({ id: 'errorDuringRequestProcessing' }));
			return;
		}
		setIsDeleteDeviceModalOpen(false);
		props.setIsDeleteDeviceModalLoading(false);
		props.loadRoom();
	};

	return (
		<>
			<Dropdown position='bottom' icon='more_horiz' className='device-options-dropdown'>
				<div className='dropdown__items device-options-wrapper'>
					<ul className='list-group'>
						{[UserRoles.ADMIN, UserRoles.SUPER_USER].includes(userRole) && (
							<>
								<RebootLogExport solHelloDeviceId={props.device.solHelloDeviceId} serialNumber={props.device.serialNumber} />
								<div
									className='device-option-details'
									onClick={() => {
										setIsRebootDeviceOpen(true);
										setSelectedDevice({
											deviceId: props.device.solHelloDeviceId,
											serialNumber: props.device.serialNumber,
										});
										setRebootReason('');
									}}>
									<i
										className='material-icons-outlined boxed-icon device-icon'
										id={props.device.solHelloDeviceId}
										data-position='top'>
										refresh
									</i>
									<span>{intl.formatMessage({ id: 'rebootDevice' })}</span>
								</div>
							</>
						)}
						{userRole !== UserRoles.SUPER_USER && (
							<div
								className='device-option-details'
								onClick={() => {
									setSelectedDevice({
										deviceId: props.device.solHelloDeviceId,
										serialNumber: props.device.serialNumber,
									});
									setIsDeleteDeviceModalOpen(prevState => !prevState);
								}}>
								<i
									className='material-icons-outlined boxed-icon device-icon'
									id={props.device.solHelloDeviceId}
									data-position='top'>
									delete
								</i>
								<span>{intl.formatMessage({ id: 'unassignDevice' })}</span>
							</div>
						)}
					</ul>
				</div>
			</Dropdown>
			<Modal
				className='centered-modal'
				display={isGetDeviceLogsOpen}
				position='center'
				hideActionButtons={true}
				onModalClose={() => setIsGetDeviceOpen(false)}>
				<form>
					<h3>{translate('modalLogsTitle')}</h3>
				</form>
			</Modal>
			<Modal
				display={isRebootDeviceOpen}
				position='right'
				primaryButtonLabel={translate('reboot')}
				onModalSubmit={rebootDevice}
				onModalClose={() => setIsRebootDeviceOpen(false)}>
				<Form title={translate('rebootDevice')} onSubmit={event => event.preventDefault()}>
					<p>
						{translate('rebootDeviceQuestion')} {selectedDevice.serialNumber}
					</p>
					<Input
						type='text'
						value={rebootReason}
						validationOptions={{ maxLength: 100 }}
						placeholder={intl.formatMessage({ id: 'rebootDeviceReason' })}
						onChange={e => setRebootReason(e.target.value)}
					/>
				</Form>
			</Modal>
			<Modal
				display={isDeleteDeviceModalOpen}
				isLoading={props.isDeleteDeviceModalLoading}
				position='center'
				primaryButtonLabel={translate('unassign')}
				onModalSubmit={unAssignDevice}
				onModalClose={() => setIsDeleteDeviceModalOpen(prevState => !prevState)}>
				<Form title={translate('unassignDevice')} onSubmit={event => event.preventDefault()}>
					<p>{translate('sureToUnassignDevice', { value: selectedDevice.serialNumber })}</p>
				</Form>
			</Modal>
			<Alert display={error !== null} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
		</>
	);
};
export default CompanionDeviceActions;
