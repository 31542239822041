import moment from 'moment';
import React from 'react';
import { Gender } from 'constants/enums.js';
import { Allergies, MaritalStatusList } from 'constants/visitEnums.js';
import { getFirstName, getLastName, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';

const AdministrationOfParenteralTherapy = ({ selectedCase, isVisitsPage, patient, reportRef }) => {
	const getPerformerVisit = () => {
		const visitId = isVisitsPage
			? selectedCase.visitToPrint?.parenteralTherapyVisits[0].parenteralTherapy.doctorVisit.id
			: selectedCase.visitToPrint?.parenteralTherapies[0].doctorVisit.id;
		return selectedCase[isVisitsPage ? 'visits' : 'medicalVisits'].find(item => item.id === visitId);
	};

	const performerVisit = getPerformerVisit();
	const performerEmail = isVisitsPage ? performerVisit.performerEmail : performerVisit.performer.email;
	const performerName = isVisitsPage
		? performerVisit.performerName
		: `${performerVisit.performer.firstName} ${performerVisit.performer.lastName}`;
	const medications = isVisitsPage
		? selectedCase.visitToPrint?.parenteralTherapyVisits[0].parenteralTherapy.medications
		: selectedCase.visitToPrint?.parenteralTherapies[0].medications;
	let medicationsTable = [];
	if (medications?.length < 10) {
		medicationsTable = [...medications].concat(
			[...Array(10 - medications.length)].map(() => ({
				name: '',
				dosage: '',
				frequency: '',
				duration: '',
			}))
		);
	}

	const myAllergies = Object.values(Allergies)?.filter(item =>
		patient?.allergies?.some(patientAllergy => item.id === patientAllergy.categoryId)
	);

	const maritalStatusList = [
		{ id: MaritalStatusList.UNMARRIED.id, name: 'Beqar/e' },
		{ id: MaritalStatusList.MARRIED.id, name: 'I/e martuar' },
		{ id: MaritalStatusList.DIVORCED.id, name: 'I/e divorcuar' },
		{ id: MaritalStatusList.WIDOWED.id, name: 'I/e ve' },
	];

	const maritalStatus = maritalStatusList.find(item => item.id === patient?.maritalStatusId);

	let genderId = null;

	if (isVisitsPage) {
		genderId = patient?.genderId;
	} else {
		genderId = selectedCase.visitToPrint?.patient.genderId;
	}

	const transform = isVisitsPage ? 'rotate(90deg) translate(1.5in, 0.9in)' : 'rotate(90deg) translate(1.5in, 1.2in)';

	return (
		<div
			className='primary-care-form'
			ref={reportRef}
			style={{ position: 'fixed', width: '8.5in', minHeight: '11in', zIndex: '-1', paddingBottom: '2.8in' }}>
			<>
				<div
					className='flex rotate-90'
					style={{
						transform,
						minWidth: '10.5in',
						maxWidth: '10.5in',
						minHeight: '8in',
					}}>
					<div className='flex full-width primary-care-form-table blank-table' style={{ maxWidth: '5in' }}>
						<div className='no-margin-left full-width full-height flex-1'>
							<div className='no-margin-left full-width full-height' />
						</div>
					</div>
					<div style={{ marginLeft: 'var(--spacing-l)' }}>
						<span className='text-transform-uppercase text-align-center'>
							Qeveria e Kosovës - Ministria e Shëndetësisë / Vlada Kosova - Mistarstvo Zdravstva
						</span>
						<div className='flex flex-align-center flex-space-between'>
							<h1 className='text-transform-uppercase'>
								URDHËRESË PËR DHËNIEN E TERAPISË PARENTERALE <br />
								NAREDBA ZA IZDAVANJE PARENTERALNE TERAPIJE
							</h1>
							<span className='text-transform-uppercase'>DM 0108</span>
						</div>
						<div className='flex full-width primary-care-form-table'>
							<div className='flex-1'>
								<div>
									<span>Institucioni Shëndetësor / Zdravstvena Institucija:</span>
									{/* <p>QKMF Lipjan</p> */}
								</div>
								<div>
									<span>Kodi / Sifra:</span>
									{/* <p>73200</p> */}
								</div>
								<div>
									<span>Public / Javna:</span>
									<p>Po</p>
								</div>
								<div>
									<span>Vendi / Mesto:</span>
									{/* <p>Lipjan</p> */}
								</div>
								<div style={{ height: '50px' }}>
									<span>Nr. i Kartelës Shëndetësore / Br. Zdravstvenog Kartona:</span>
								</div>
								<div>
									<span>Nr. i Regjistrit / Br. Registra:</span>
								</div>
								<div>
									<span>Mjeku / Lekar:</span>
									<p>Dr. {performerName}</p>
								</div>
								<div>
									<span>Kodi i Mjekut / Sifra Lekara:</span>
								</div>
								<div>
									<span>Tel.:</span>
								</div>
								<div>
									<span>E-mail:</span>
									<p>{performerEmail}</p>
								</div>
								<div>
									<span>Alergjitë / Alergije:</span>
									{myAllergies.length > 0 && myAllergies.map(item => <p>{stringToCamelCase(item.type)}, </p>)}
								</div>
							</div>
							<div className='flex-1'>
								<div>
									<span>Nr. Personal / Licni Br.:</span>
									<p>{!isVisitsPage ? selectedCase.visitToPrint?.patient.idCard : patient?.idCard}</p>
								</div>
								<div>
									<span>Emri / Ime:</span>
									<p>{!isVisitsPage ? selectedCase.visitToPrint?.patient.firstName : getFirstName(patient?.fullName || '')}</p>
								</div>
								<div>
									<span>Emri i prindit / Ime roditelja:</span>
									<p>{!isVisitsPage ? selectedCase.visitToPrint?.patient.parentName : patient?.parentName}</p>
								</div>
								<div>
									<span>Mbiemri / Prezime:</span>
									<p>{!isVisitsPage ? selectedCase.visitToPrint?.patient.lastName : getLastName(patient?.fullName || '')}</p>
								</div>
								<div>
									<span>Viti i lindjes / Godina rodjenja:</span>
									<p>
										{!isVisitsPage
											? moment(selectedCase.visitToPrint?.patient.dateOfBirth).format('YYYY')
											: moment()
												.subtract(patient?.age, 'years')
												.format('YYYY')}
									</p>
								</div>
								<div>
									<span>Gjinia / Pol:</span>
									<p>{genderId === Gender.MALE ? 'Mashkull' : 'Femër'}</p>
								</div>
								<div>
									<span>Adresa / Adresa:</span>
									<p>{patient?.address}</p>
								</div>
								<div>
									<span>Profesioni / Profesija:</span>
									<p>{patient?.profession}</p>
								</div>
								<div>
									<span>Statusi bashkëshortor / Bracno stanje:</span>
									<p>{maritalStatus?.name}</p>
								</div>
							</div>
						</div>
						<div className='flex full-width primary-care-form-table'>
							<div className='flex-1'>
								<div>
									<span>Sigurimi shëndetësor / Zdravstveno osiguranje:</span>
								</div>
								<div className='no-border-top'>
									<span>Kompania / Kompanija:</span>
								</div>
								<div>
									<span>Kodi / Sifra:</span>
								</div>
							</div>
							<div className='flex-1'>
								<div style={{ height: '50px' }}>
									<span>Kategoria e sigurimit / Kategorija osiguranika:</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className='flex rotate-90'
					style={{
						marginTop: '3in',
						minWidth: '10.5in',
						maxWidth: '10.5in',
						minHeight: '8in',
						transform,
					}}>
					<div className='flex-1 position-relative'>
						<table>
							<thead />
							<tbody>
								<td>Diagnoza / Dijagnoza</td>
								<td>
									Kodi / Sifra
									<br />
									ICD-10&nbsp;&nbsp;ICPC
								</td>
							</tbody>
						</table>
						<table>
							<thead>
								<tr>
									<th style={{ width: '60px' }}>Nr.</th>
									<th style={{ width: 'calc(20% - 20px)' }}>
										Emri i barit dhe doza <br />
										Ime leka i doza
									</th>
									<th style={{ width: 'calc(20% - 20px)' }}>
										Kohëzgjatja e përdorimit <br />
										Vremenska upotraba
									</th>
									<th style={{ width: 'calc(20% - 20px)' }}>Çmimi / Cena</th>
								</tr>
							</thead>
							<tbody>
								{medicationsTable?.map(el => (
									<tr>
										<td style={{ width: '60px' }}>
											<br />
										</td>
										<td style={{ width: 'calc(20% - 20px)' }}>{el.name ? `${el.name} ${el.dosage}ml` : <br />}</td>
										<td style={{ width: 'calc(20% - 20px)' }}>
											{el.duration ? `${el.duration} ditë, ${el.frequency} herë në ditë` : <br />}
										</td>
										<td style={{ width: 'calc(20% - 20px)' }}>
											<br />
										</td>
									</tr>
								))}

								<tr>
									<td style={{ width: '60px', borderRight: 'none' }}>Gjithsej</td>
									<td style={{ width: 'calc(20% - 20px)', borderLeft: 'none' }}>
										{medicationsTable.filter(item => item.name).length ?? <br />}
									</td>
									<td style={{ width: 'calc(20% - 20px)' }}>
										<br />
									</td>
									<td style={{ width: 'calc(20% - 20px)' }}>
										<br />
									</td>
								</tr>
							</tbody>
						</table>

						<table>
							<thead>
								<tr>
									<th>Shënime shtesë / Dodatni podaci</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td style={{ height: '50px' }}>
										{isVisitsPage
											? selectedCase.visitToPrint?.parenteralTherapyVisits[0].parenteralTherapy.note
											: performerVisit.parenteralTherapies[0].note}
									</td>
								</tr>
							</tbody>
						</table>
						<div className='flex signature-lab full-width'>
							<div>Data / Datum:</div>
							<div>Nënshkrimi dhe faksimili i mjekut</div>
						</div>
					</div>
					<div className='flex-1 position-relative' style={{ marginLeft: 'var(--spacing-l)' }}>
						<table>
							<thead>
								<tr>
									<th style={{ width: '50px' }}>Nr.</th>
									<th style={{ width: 'calc(20% - 20px)' }}>
										Data e caktimit <br />
										Datum odredjivanja
									</th>
									<th style={{ width: 'calc(20% - 20px)' }}>
										Data e dhënies <br />
										Datum izdavanja
									</th>
									<th style={{ width: 'calc(20% - 20px)' }}>
										Punëtori shëndetësor <br /> Zdravstveni radnik
									</th>
								</tr>
							</thead>
							<tbody>
								{[...Array(10)].map((tr, i) => (
									<tr>
										<td style={{ width: '60px' }}>
											<br />
										</td>
										<td style={{ width: 'calc(20% - 20px)' }}>
											{isVisitsPage
												? i === 0 &&
												selectedCase.visitToPrint?.startedAt &&
												moment(selectedCase.visitToPrint?.startedAt).format('DD/MM/YYYY')
												: performerVisit.parenteralTherapies.map(
													(item, index) => index === i && moment(item.doctorVisit.endedAt).format('DD/MM/YYYY')
												)}
											<br />
										</td>
										<td style={{ width: 'calc(20% - 20px)' }}>
											{isVisitsPage
												? i === 0 &&
												selectedCase.visitToPrint?.endedAt &&
												moment(selectedCase.visitToPrint?.endedAt).format('DD/MM/YYYY')
												: performerVisit.parenteralTherapies.map(item =>
													item.nurseVisits.map((nurse, index) => index === i && moment(nurse.endedAt).format('DD/MM/YYYY'))
												)}
											<br />
										</td>
										<td style={{ width: 'calc(20% - 20px)' }}>
											{isVisitsPage
												? ''
												: performerVisit.parenteralTherapies.map(item =>
													item.nurseVisits.map(
														(nurse, index) => index === i && `${nurse.performer.firstName} ${nurse.performer.lastName}`
													)
												)}
											<br />
										</td>
									</tr>
								))}
								<tr>
									<td style={{ width: '60px', borderRight: 'none' }}>Gjithsej</td>
									<td style={{ width: 'calc(20% - 20px)', borderLeft: 'none' }}>
										{isVisitsPage ? '' : performerVisit.parenteralTherapies[0].nurseVisits.length}
										<br />
									</td>
									<td style={{ width: 'calc(20% - 20px)' }}>
										<br />
									</td>
									<td style={{ width: 'calc(20% - 20px)' }}>
										<br />
									</td>
								</tr>
							</tbody>
						</table>

						<table>
							<thead />
							<tbody>
								<tr>
									<td>
										Nr. i aplikimeve të përgjithshme <br />
										Br. opstih aplkacija
									</td>
								</tr>
								<tr>
									<td>ISh / zi</td>
								</tr>
								<tr>
									<td>ISh / zi</td>
								</tr>
								<tr>
									<td>
										Në shtëpi <br /> U stanu
									</td>
								</tr>
							</tbody>
						</table>
						<table>
							<thead>
								<tr>
									<th>Shënime shtesë</th>
									<th>Vlera Totale / Totalna Suma</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td style={{ height: '50px' }} />
									<td style={{ height: '50px' }} />
								</tr>
							</tbody>
						</table>
						<div className='flex signature-lab full-width'>
							<div>Data / Datum:</div>
							<div>Personi Përgjegjës</div>
						</div>
					</div>
				</div>
			</>
		</div>
	);
};
export default AdministrationOfParenteralTherapy;
