import { getMediaCdnURL } from 'infrastructure/helpers/mediaHelper.js';
import translate from 'i18n-translations/translate.jsx';
import { getAttachmentIcon } from 'infrastructure/helpers/commonHelpers.js';

const RequestAttachments = props => (
	<>
		{props.attachments && props.attachments.length > 0 && (
			<div className='appointment-requests-attachments'>
				<h2 className='requests-attachments-title'>{translate('attachments')}</h2>
				<div className='flex flex-wrap full-width'>
					{props.attachments.map(attachment => (
						<a
							className='doctor-request-photo-upload'
							href={`${getMediaCdnURL(attachment.fileName, attachment.fileTypeId)}`}
							rel='noopener noreferrer'
							target='_blank'
							download
							key={attachment.id}>
							<img src={getAttachmentIcon(attachment.fileName)} alt='file-icon' />
							<span>{attachment.originalFileName}</span>
						</a>
					))}
				</div>
			</div>
		)}
	</>
);

export default RequestAttachments;
