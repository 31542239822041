import moment from 'moment';
import { PhysicalCategories } from 'constants/enums.js';

export const getPhysicalExerciseIcon = (item, isActive) => {
	switch (item) {
		case PhysicalCategories.SHOULDER_EXERCISES: {
			return `shoulder-exercise${isActive ? '-active' : ''}`;
		}
		case PhysicalCategories.NECK_EXERCISES: {
			return `neck-exercise${isActive ? '-active' : ''}`;
		}
		case PhysicalCategories.LEG_EXERCISES: {
			return `leg-exercise${isActive ? '-active' : ''}`;
		}
		case PhysicalCategories.HIP_EXERCISES: {
			return `hip-exercise${isActive ? '-active' : ''}`;
		}
		default:
			return `shoulder-exercise${isActive ? '-active' : ''}`;
	}
};

export const getCalendarClass = (date, selectedDate) => {
	if (moment(selectedDate).format('MM/DD/YYYY') === moment(date).format('MM/DD/YYYY')) {
		return 'highlight';
	}
	return '';
};
