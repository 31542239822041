const Abdomen = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} viewBox='0 0 24 24' fill='none' {...props}>
		<path
			d='M6.22344 3.5C6.27996 3.70167 7.63427 8.27849 12.8604 9.52983C12.691 10.0343 12.6413 10.57 12.715 11.0962C12.7888 11.6223 12.984 12.125 13.2858 12.5659C12.2264 12.9481 11.2451 13.7527 11.1495 15.0474C10.495 14.9597 9.24546 14.8851 8.41006 15.9438C7.85156 15.6107 7.22659 15.3993 6.57754 15.324C5.92848 15.2487 5.27051 15.3111 4.64822 15.5072C3.98889 15.7124 3.38189 16.0535 2.86794 16.5078C2.35399 16.962 1.94498 17.5189 1.66833 18.141C1.40864 18.732 1.27841 19.3699 1.28609 20.0135L1.29636 22.5'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeMiterlimit={10}
		/>
		<path
			d='M4.28582 22.5V20.2168C4.28353 19.9761 4.36013 19.7416 4.5035 19.5502C4.64687 19.3588 4.84883 19.2215 5.07744 19.1601C5.45516 19.059 5.85634 19.105 6.20249 19.2891C6.81143 19.6173 7.70787 19.6803 8.87085 19.4781C9.72637 20.0959 14.8325 23.3901 20.4727 18.402C23.0452 15.7348 23.4066 12.5086 23.2569 10.2661C23.0902 7.77664 21.9302 5.58185 20.0734 4.24567C18.3384 2.99688 15.0318 1.65452 11.8813 4.67744C11.444 4.62054 10.0984 4.26083 9.28577 2'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeMiterlimit={10}
		/>
	</svg>
);
export default Abdomen;
