import { components } from 'react-select';

 const Control = ({ children, ...props }) => {
	const { customContent } = props.selectProps;
	return (
		<components.Control {...props}>
			{customContent && <span>{customContent}</span>}
			{children}
		</components.Control>
	);
};

export default Control