import Collapse from 'icons/General/Collapse.jsx';
import Expand from 'icons/General/Expand.jsx';
import { CaptionDropdowns, useNavigation } from 'react-day-picker';

const DatePickerCaption = props => {
	const { nextMonth, previousMonth, goToMonth } = useNavigation();
	return (
		<div className='rdp-nav flex flex-space-between flex-align-center'>
			<button
				className='rdp-button_reset rdp-button rdp-nav_button rdp-nav_button_previous'
				disabled={!previousMonth}
				onClick={() => previousMonth && goToMonth(previousMonth)}>
				<Expand className='rotate-90' />
			</button>
			<CaptionDropdowns displayMonth={props.displayMonth} />
			<button
				className='rdp-button_reset rdp-button rdp-nav_button rdp-nav_button_next'
				disabled={!nextMonth}
				onClick={() => nextMonth && goToMonth(nextMonth)}>
				<Collapse className='rotate-90' />
			</button>
		</div>
	);
};

export default DatePickerCaption;
