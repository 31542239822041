import React, { useEffect, useRef } from 'react';
import { APP_CONFIG } from 'constants/global-variables.js';
import { checkIPPermission, getUserRole } from 'infrastructure/auth.js';
import { isContinueAsSelected } from 'infrastructure/helpers/commonHelpers.js';
import { UserRoles } from 'constants/enums.js';

const IpAccess = () => {
	const ipAccessInterval = useRef(null);

	const startIPAccessInterval = async () => {
		if (getUserRole() !== UserRoles.ADMIN && isContinueAsSelected()) {
			if (ipAccessInterval?.current) {
				clearInterval(ipAccessInterval.current);
			}
			const minutes = APP_CONFIG.ipAccessCheckInterval || 10;
			await checkIPPermission(); // added this to check on refresh
			ipAccessInterval.current = setInterval(async () => {
				await checkIPPermission();
			}, 60000 * minutes);
		}
	};

	useEffect(() => {
		startIPAccessInterval();
		return () => {
			clearInterval(ipAccessInterval.current);
			ipAccessInterval.current = null;
		};
	}, []);

	return <></>;
};

export default IpAccess;
