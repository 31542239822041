import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { debounce } from 'lodash';
import { getPatientAiAlertsSummary } from 'api/alerts.js';
import { PatientAiSetting } from 'constants/enums.js';
import { timezoneToUTCTimestamp } from 'infrastructure/helpers/dateHelper.js';
import Alert from 'components/Alert.jsx';
import { ActivityTypes, FallPreventionSettingTypes } from 'constants/ai.js';
import AlertActivityTypesSummary from 'components/DashboardCharts/PatientCentric/AI/Summaries/AlertActivityTypesSummary.jsx';
import AlertTypesSummary from 'components/DashboardCharts/PatientCentric/AI/Summaries/AlertTypesSummary.jsx';

const AlertSummaries = ({ dateRange, selectedTimezone }) => {
	const location = useLocation();
	const [aiAlertStats, setAiAlertStats] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetchPatientAiAlertsSummary = useCallback(
		debounce(async params => {
			setIsLoading(true);
			const response = await getPatientAiAlertsSummary(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setAiAlertStats(response);
			}
			setIsLoading(false);
		}, 500),
		[]
	);

	useEffect(() => {
		const { patient } = location.state;

		const params = {
			userId: patient?.healthcareUserId,
			activityTypes: ActivityTypes,
			aiSettingTypes: [...FallPreventionSettingTypes, PatientAiSetting.PRESSURE_INJURY, PatientAiSetting.PATIENT_MOBILITY],
			start: timezoneToUTCTimestamp(dateRange.from, selectedTimezone.zone),
			end: timezoneToUTCTimestamp(dateRange.to, selectedTimezone.zone),
		};
		fetchPatientAiAlertsSummary(params);
	}, [location, dateRange, fetchPatientAiAlertsSummary, selectedTimezone.zone]);

	return (
		<>
			{aiAlertStats && (
				<>
					<AlertTypesSummary
						aiAlertTypes={aiAlertStats.aiSettingTypeCounts}
						totalAiAlertTypes={aiAlertStats.allSettingCount}
						isLoading={isLoading}
					/>
					<AlertActivityTypesSummary
						aiAlertActivityTypes={aiAlertStats.aiAlertActivityTypeCounts}
						totalAiAlertActivityTypes={aiAlertStats.activityCount}
						isLoading={isLoading}
					/>
				</>
			)}
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default AlertSummaries;
