import moment from 'moment';
import { AnalysisSubTypes, TestTypes } from 'constants/visitEnums.js';
import { Gender } from 'constants/enums.js';
import { getFirstName, getLastName } from 'infrastructure/helpers/commonHelpers.js';

const LabReport = ({ selectedVisit, isVisitsPage, patient, reportRef }) => {
	let isUrine = false;
	let isBlood = false;

	const getAnalysesByType = type => selectedVisit?.diagnosticResearches.find(item => item.testType === type);

	if (selectedVisit?.diagnosticResearches?.length && getAnalysesByType(TestTypes.URINE)) {
		isUrine = true;
	}

	if (
		selectedVisit?.diagnosticResearches?.length &&
		selectedVisit?.diagnosticResearches.find(item => item.testType !== TestTypes.URINE)
	) {
		isBlood = true;
	}

	let genderId = null;
	const performerEmail = selectedVisit?.diagnosticResearches[0].medicalVisitPerformer.email;
	const performerName = `${selectedVisit?.diagnosticResearches[0].medicalVisitPerformer.firstName} ${selectedVisit?.diagnosticResearches[0].medicalVisitPerformer.lastName}`;

	if (isVisitsPage) {
		genderId = patient?.genderId;
	} else {
		genderId = selectedVisit?.patient.genderId;
	}

	return (
		<div
			className='primary-care-form'
			ref={reportRef}
			style={{ position: 'fixed', width: '8.5in', minHeight: '11in', zIndex: '-1', paddingBottom: '2.8in' }}>
			<>
				<div
					className='flex rotate-90'
					style={{
						transform: 'rotate(90deg) translate(1.5in, 1.3in)',
						minWidth: '10.5in',
						maxWidth: '10.5in',
						minHeight: '8in',
					}}>
					<div className='flex-1'>
						<table>
							<thead>
								<tr>
									<th>Lloji i analizës</th>
									<th>Rezultati</th>
									<th>Vlerat referente</th>
									<th>Njësia</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Koha protrombinës (PT)</td>
									<td />
									<td>70 - 120</td>
									<td>%</td>
								</tr>
								<tr>
									<td>Koha parc. e trom. (aPTT)</td>
									<td />
									<td>20.1 - 36.1</td>
									<td>sec.</td>
								</tr>
								<tr>
									<td>Koha e trombinës (TT)</td>
									<td />
									<td>8 - 14</td>
									<td>sec.</td>
								</tr>
								<tr>
									<td>INR (International Normalised Ratio)</td>
									<td />
									<td>
										Pac. pa terapi 0.8 - 1.3 <br />
										Pac. me terapi 2 - 3.5
									</td>
									<td />
								</tr>
								<tr>
									<td>a - amilaza në urinë</td>
									<td />
									<td>
										Urinë spontane {'<'} 400
										<br />
										Urinë 24h {'<'} 450
									</td>
									<td>U/L</td>
								</tr>
								<tr>
									<td>Kreatiinina në urinë</td>
									<td />
									<td>M: 8.85 - 16</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>Klirensi i kreatininës</td>
									<td />
									<td>1.6 - 2.6</td>
									<td>ml/sec</td>
								</tr>
								<tr>
									<td>Proteinet totale në urinë</td>
									<td />
									<td>deri 1.0</td>
									<td>g/L</td>
								</tr>
								<tr>
									<td>Kanabinoidet (THC) në urinë</td>
									<td />
									<td>negative</td>
									<td />
								</tr>
								<tr>
									<td>Cocaina (COC) në urinë</td>
									<td />
									<td>negative</td>
									<td />
								</tr>
								<tr>
									<td>Methamphetamina (MET) në urinë</td>
									<td />
									<td>negative</td>
									<td />
								</tr>
								<tr>
									<td>Opiatet (OPI) në urinë</td>
									<td />
									<td>negative</td>
									<td />
								</tr>
								<tr>
									<td>AMPH (Amfetamina)</td>
									<td />
									<td>negative</td>
									<td />
								</tr>
								<tr>
									<td>ECS (Ecstasy)</td>
									<td />
									<td>negative</td>
									<td />
								</tr>
								<tr>
									<td>Chlamydia trachomatis</td>
									<td />
									<td>negative</td>
									<td />
								</tr>
							</tbody>
						</table>
						<table>
							<thead>
								<tr>
									<th>Examinimi i urinës</th>
									<th />
								</tr>
							</thead>
							<tbody>
								{[
									{ name: 'Pamja', analysis: AnalysisSubTypes.VIEW },
									{ name: 'Ngjyra', analysis: AnalysisSubTypes.COLOR },
									{ name: 'Reaksioni', analysis: AnalysisSubTypes.REACTION },
									{ name: 'Pesha specifike', analysis: AnalysisSubTypes.SPECIFIC_WEIGHT },
									{ name: 'Proteinet', analysis: AnalysisSubTypes.PROTEINS },
									{ name: 'Glukoza', analysis: AnalysisSubTypes.URINE_GLUCOSE },
									{ name: 'Acetoni', analysis: AnalysisSubTypes.ACETONE },
									{ name: 'Bilirubina', analysis: AnalysisSubTypes.BILIRUBIN },
									{ name: 'Urobilinogjeni', analysis: AnalysisSubTypes.UROBILINOGEN },
									{ name: 'Nitritet', analysis: AnalysisSubTypes.NITRITES },
								].map(({ name, analysis }) => (
									<tr>
										<td>{name}</td>
										<td style={{ width: '60%' }}>
											{getAnalysesByType(TestTypes.URINE)?.attachments.find(el => el.typeId === analysis)?.value}
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<table>
							<thead>
								<tr>
									<th>Sedimenti:</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td style={{ height: '50px' }}>
										{getAnalysesByType(TestTypes.URINE)?.attachments.find(el => el.typeId === AnalysisSubTypes.SEDIMENT)?.value}
									</td>
								</tr>
							</tbody>
						</table>
						<table>
							<thead>
								<tr>
									<th>Ekzaminimi i Fecesit:</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td style={{ height: '50px' }} />
								</tr>
							</tbody>
						</table>
					</div>
					<div className='flex-1  position-relative' style={{ marginLeft: 'var(--spacing-l)' }}>
						<span className='text-transform-uppercase text-align-center'>
							Qeveria e Kosovës-Ministria e Shendetësisë / Vlada Kosova - Mistarstvo Zdravstva
						</span>
						<div className='flex flex-align-center flex-space-between'>
							<h1 className='text-transform-uppercase'>
								Raport kthyes nga laboratori <br />
								Povratni izvestaj sa laboratorije
							</h1>

							<span className='text-transform-uppercase'>DM 0105</span>
						</div>
						<div className='lab-report-types'>
							<div className='flex'>
								<i className='material-icons-outlined'>{isBlood ? 'check_circle' : 'radio_button_unchecked'}</i>Gjaku / Krv
							</div>
							<div className='flex'>
								<i className='material-icons-outlined'>{isUrine ? 'check_circle' : 'radio_button_unchecked'}</i>Urina / Mokraca
							</div>
							<div className='flex'>
								<i className='material-icons-outlined'>radio_button_unchecked</i>Fecesi / Stolica
							</div>
						</div>
						<div className='flex full-width primary-care-form-table'>
							<div className='flex-1'>
								<div>
									<span>Institucioni Shëndetësor / Zdravstvena Institucija:</span>
									{/* <p>QKMF Lipjan</p> */}
								</div>
								<div>
									<span>Njësia organizative / Orgaziciona jedinica:</span>
									<p>Laboratori</p>
								</div>
								<div>
									<span>Kodi / Sifra:</span>
									{/* <p>73200</p> */}
								</div>
								<div>
									<span>Public / Javna:</span>
									<p>Po</p>
								</div>
								<div>
									<span>Vendi / Mesto:</span>
									{/* <p>Lipjan</p> */}
								</div>
								<div>
									<span>Nr. i Kartelës Shëndetësore / Br. Zdravstvenog Kartona:</span>
								</div>
								<div>
									<span>Nr. i Regjistrit / Br. Registra:</span>
								</div>
								<div>
									<span>Mjeku / Lekar:</span>
									<p>Dr. {performerName}</p>
								</div>
								<div>
									<span>Kodi i Mjekut / Sifra Lekara:</span>
								</div>
								<div>
									<span>Tel.:</span>
								</div>
								<div>
									<span>E-mail:</span>
									<p>{performerEmail}</p>
								</div>
							</div>
							<div className='flex-1'>
								<div>
									<span>Nr. Personal / Licni Br.:</span>
									<p>{!isVisitsPage ? selectedVisit?.patient.idCard : patient?.idCard}</p>
								</div>
								<div>
									<span>Emri / Ime:</span>
									<p>{!isVisitsPage ? selectedVisit?.patient.firstName : getFirstName(patient?.fullName || '')}</p>
								</div>
								<div>
									<span>Emri i prindit / Ime roditelja:</span>
									<p>{!isVisitsPage ? selectedVisit?.patient.parentName : patient?.parentName}</p>
								</div>
								<div>
									<span>Mbiemri / Prezime:</span>
									<p>{!isVisitsPage ? selectedVisit?.patient.lastName : getLastName(patient?.fullName || '')}</p>
								</div>
								<div>
									<span>Viti i lindjes / Godina rodjenja:</span>
									<p>
										{!isVisitsPage
											? moment(selectedVisit?.patient.dateOfBirth).format('YYYY')
											: moment().subtract(patient?.age, 'years').format('YYYY')}
									</p>
								</div>
								<div>
									<span>Gjinia / Pol:</span>
									<p>{genderId === Gender.MALE ? 'Mashkull' : 'Femër'}</p>
								</div>
								<div>
									<span>Adresa / Adresa:</span>
									<p>{patient?.address}</p>
								</div>
							</div>
						</div>
						<div className='flex signature-lab full-width'>
							<div>Data / Datum:</div>
							<div>Personi Përgjegjës</div>
						</div>
					</div>
				</div>
				<div
					className='flex rotate-90'
					style={{
						marginTop: '3in',
						minWidth: '10.5in',
						maxWidth: '10.5in',
						minHeight: '8in',
						transform: 'rotate(90deg) translate(1.5in, 1.25in)',
					}}>
					<div className='flex-1'>
						<table>
							<thead>
								<tr>
									<th>Ekzaminimi i gjakut</th>
								</tr>
							</thead>
						</table>
						<table>
							<thead>
								<tr>
									<th>Lloji i analizës</th>
									<th>Rezultati</th>
									<th>Vlerat referente</th>
									<th>Njësia</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Sedimenti i eritrociteve</td>
									<td>
										{
											getAnalysesByType(TestTypes.SEDIMENTATION)?.attachments.find(
												el => el.typeId === AnalysisSubTypes.ERYTHROCYTE_SEDIMENTATION
											)?.value
										}
									</td>
									<td>M deri 10, F deri 15</td>
									<td>mm/h</td>
								</tr>
								{[
									{
										name: 'Numri i eritrociteve',
										analysis: AnalysisSubTypes.ERYTHROCYTE_COUNT,
										referenceValue: 'M (4.5 - 4.9), F (3.8 - 4.9)',
										unit: (
											<>
												x 10<sup>12</sup>/L
											</>
										),
									},
									{
										name: 'Hemoglobina',
										analysis: AnalysisSubTypes.HEMOGLOBIN,
										referenceValue: 'M (140 - 180), F (120 - 160)',
										unit: 'g/L',
									},
									{
										name: 'Numri i leukociteve',
										analysis: AnalysisSubTypes.LEUKOCYTE_COUNT,
										referenceValue: '4.0 - 8.0',
										unit: (
											<>
												x 10<sup>9</sup>/L
											</>
										),
									},
									{
										name: 'MCV',
										analysis: AnalysisSubTypes.MCV,
										referenceValue: '81 - 94',
										unit: 'Femtolitër',
									},
									{
										name: 'MCH',
										analysis: AnalysisSubTypes.MCH,
										referenceValue: '32-36',
										unit: 'Pikogram',
									},
									{
										name: 'MCHC',
										analysis: AnalysisSubTypes.MCHC,
										referenceValue: '310 - 350',
										unit: 'g/L',
									},
									{
										name: 'Hematokriti',
										analysis: AnalysisSubTypes.HEMATOCRIT,
										referenceValue: '35 - 45',
										unit: 'l',
									},
								].map(({ name, analysis, referenceValue, unit }) => (
									<tr>
										<td>{name}</td>
										<td>{getAnalysesByType(TestTypes.HEMOGRAM)?.attachments.find(el => el.typeId === analysis)?.value}</td>
										<td>{referenceValue}</td>
										<td>{unit}</td>
									</tr>
								))}

								<tr>
									<td>Nr. i trombociteve</td>
									<td />
									<td>140 - 310</td>
									<td>
										x 10<sup>9</sup>/L
									</td>
								</tr>
								<tr>
									<td>Nr. i retikulociteve</td>
									<td />
									<td>5 - 15</td>
									<td>%</td>
								</tr>
								<tr>
									<td>Koha e gjakderdhjes</td>
									<td />
									<td>2 - 3</td>
									<td>min</td>
								</tr>
								<tr>
									<td>Koha e ngjizjes</td>
									<td />
									<td>5 - 10</td>
									<td>min</td>
								</tr>
								<tr>
									<td>Formula Leukocitare</td>
									<td />
									<td />
									<td />
								</tr>
								<tr>
									<td>Neutrofile</td>
									<td />
									<td>52 - 62</td>
									<td>%</td>
								</tr>
								<tr>
									<td>Eozionofile</td>
									<td />
									<td>1 - 3</td>
									<td>%</td>
								</tr>
								<tr>
									<td>Bazofile</td>
									<td />
									<td>0 - 1</td>
									<td>%</td>
								</tr>
								<tr>
									<td>Limfocite</td>
									<td />
									<td>25 - 33</td>
									<td>%</td>
								</tr>
								<tr>
									<td>Monocite</td>
									<td />
									<td>3 - 7</td>
									<td>%</td>
								</tr>
								<tr>
									<td>Të shkopëzuara</td>
									<td />
									<td>0</td>
									<td>%</td>
								</tr>
							</tbody>
						</table>

						<table>
							<thead>
								<tr>
									<th>Lyerja periferike:</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td />
								</tr>
							</tbody>
						</table>
						<table>
							<tbody>
								<tr>
									<td>Glukoza</td>
									<td>
										{getAnalysesByType(TestTypes.GLUCOSE)?.attachments.find(el => el.typeId === AnalysisSubTypes.GLUCOSE)?.value}
									</td>
									<td>3.8 - 6.1</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>Urea</td>
									<td>{getAnalysesByType(TestTypes.UREA)?.attachments.find(el => el.typeId === AnalysisSubTypes.UREA)?.value}</td>
									<td>2.5 - 8.3</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>Kreatinina</td>
									<td>
										{
											getAnalysesByType(TestTypes.CREATININE)?.attachments.find(el => el.typeId === AnalysisSubTypes.CREATININE)
												?.value
										}
									</td>
									<td>M (70 - 108), F (44-88)</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>Trigliceridet</td>
									<td>
										{
											getAnalysesByType(TestTypes.TRIGLYCERIDES)?.attachments.find(
												el => el.typeId === AnalysisSubTypes.TRIGLYCERIDES
											)?.value
										}
									</td>
									<td>0.4 - 1.8</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>Lipidet totale</td>
									<td />
									<td>4.5 - 10.5</td>
									<td>mmol/L</td>
								</tr>
								{[
									{
										name: 'Kolesteroli',
										analysis: AnalysisSubTypes.CHOLESTEROL,
										referenceValue: <>{'<'} 6.5</>,
										unit: 'mmol/L',
									},
									{
										name: 'LDL Kolesteroli',
										analysis: AnalysisSubTypes.LDL_CHOLESTEROL,
										referenceValue: '3.9 - 4.9',
										unit: 'mmol/L',
									},
									{
										name: 'VLDL Kolesteroli',
										analysis: AnalysisSubTypes.VLD_CHOLESTEROL,
										referenceValue: 'Deri 0.9',
										unit: 'mmol/L',
									},
									{
										name: 'HDL Kolesteroli',
										analysis: AnalysisSubTypes.HDL_CHOLESTEROL,
										referenceValue: (
											<>
												M {'->'} 1.42, F {'->'} 1.68
											</>
										),
										unit: 'mmol/L',
									},
								].map(({ name, analysis, referenceValue, unit }) => (
									<tr>
										<td>{name}</td>
										<td>{getAnalysesByType(TestTypes.CHOLESTEROL)?.attachments.find(el => el.typeId === analysis)?.value}</td>
										<td>{referenceValue}</td>
										<td>{unit}</td>
									</tr>
								))}

								<tr>
									<td>Acidi Urik</td>
									<td />
									<td>M (140 - 420), F (90 - 350)</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>Proteinet totale</td>
									<td />
									<td>65 - 83</td>
									<td>g/L</td>
								</tr>
								<tr>
									<td>Albuminet</td>
									<td />
									<td>35 - 53</td>
									<td>g/L</td>
								</tr>
								<tr>
									<td>Globulinet</td>
									<td />
									<td>18 - 32</td>
									<td>g/L</td>
								</tr>
								<tr>
									<td>Fibrinogjeni</td>
									<td />
									<td>2 - 4</td>
									<td>g/L</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className='flex-1' style={{ marginLeft: 'var(--spacing-l)' }}>
						<table>
							<thead>
								<tr>
									<th>Ekzaminimi i gjakut</th>
								</tr>
							</thead>
						</table>
						<table>
							<thead>
								<tr>
									<th>Lloji i analizës</th>
									<th>Rezultati</th>
									<th>Vlerat referente</th>
									<th>Njësia</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Anti-n-DNA (Sist. Lup. Erythem)</td>
									<td />
									<td>negative</td>
									<td />
								</tr>
								<tr>
									<td>Latex RF</td>
									<td />
									<td>negative</td>
									<td />
								</tr>
								<tr>
									<td>ASTO</td>
									<td />
									<td>Deri 200</td>
									<td>I.U./mL</td>
								</tr>
								<tr>
									<td>Waler Rosse</td>
									<td />
									<td>negative</td>
									<td />
								</tr>
								<tr>
									<td>CRP</td>
									<td />
									<td>Deri 6</td>
									<td>mg/L</td>
								</tr>
								<tr>
									<td>g GT</td>
									<td />
									<td>M (8 - 37), F (5 - 24)</td>
									<td>Pikogram</td>
								</tr>
								<tr>
									<td>Bilirubina totale</td>
									<td />
									<td>5.0 - 20.0</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>Bilirubina direkte</td>
									<td />
									<td>{'<'} 7.0</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>Fosfataza alkaline</td>
									<td />
									<td>Deri 15 vj. {'<'} 644, mbi 15 vj. 70 - 306</td>
									<td>U/L</td>
								</tr>
								<tr>
									<td>ALT</td>
									<td />
									<td>{'<'} 42</td>
									<td>U/L</td>
								</tr>
								<tr>
									<td>AST</td>
									<td />
									<td>{'<'} 42</td>
									<td>U/L</td>
								</tr>
								<tr>
									<td>CK</td>
									<td />
									<td>24 - 190</td>
									<td>U/L</td>
								</tr>
								<tr>
									<td>CK-MB</td>
									<td />
									<td>{'<'} 25</td>
									<td>U/L</td>
								</tr>
								<tr>
									<td>Troponina I</td>
									<td />
									<td>negative</td>
									<td />
								</tr>
								<tr>
									<td>LDH</td>
									<td />
									<td>225 - 450</td>
									<td>U/L</td>
								</tr>
								<tr>
									<td>a Amilaza</td>
									<td />
									<td>deri 220</td>
									<td>U/L</td>
								</tr>
								<tr>
									<td>Fosfataza acidike</td>
									<td />
									<td>{'<'} 6.5</td>
									<td>U/L</td>
								</tr>
								<tr>
									<td>Fosfataza prostatike</td>
									<td />
									<td>{'<'} 2.6</td>
									<td>U/L</td>
								</tr>
								<tr>
									<td>Natriumi</td>
									<td />
									<td>136 - 146</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>Kaliumi</td>
									<td />
									<td>3.5 - 5.0</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>Kloruret</td>
									<td />
									<td>98 - 106</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>Magnezi</td>
									<td />
									<td>0.8 - 1.0</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>Fosfori</td>
									<td />
									<td>
										Të rriturit: 0.8 - 1.62 <br />
										Fëmijët: 1.30 - 2.26
									</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>Kalciumi</td>
									<td />
									<td>2.02 - 2.60</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>Lithiumi</td>
									<td />
									<td>0.5 - 1.2</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>Hekuri (Fe)</td>
									<td />
									<td>
										M (10.6 - 28.3) <br /> F (6.6 - 26.0)
									</td>
									<td>mmol/L</td>
								</tr>
								<tr>
									<td>TIBC</td>
									<td />
									<td>45 - 70</td>
									<td>mg/dL</td>
								</tr>
								<tr>
									<td>lgA</td>
									<td />
									<td>90 - 450</td>
									<td>mg/dL</td>
								</tr>
								<tr>
									<td>lgG</td>
									<td />
									<td>800 - 1800</td>
									<td>mg/dL</td>
								</tr>
								<tr>
									<td>lgM</td>
									<td />
									<td>70 - 230</td>
									<td>mg/dL</td>
								</tr>
								<tr>
									<td>
										C<sub>3</sub>
									</td>
									<td />
									<td>75 - 135</td>
									<td>mg/dL</td>
								</tr>
								<tr>
									<td>
										C<sub>4</sub>
									</td>
									<td />
									<td>9 - 36</td>
									<td>mg/dL</td>
								</tr>
								<tr>
									<td>Syphilis RPR test</td>
									<td />
									<td>negative</td>
									<td />
								</tr>
								<tr>
									<td>Syphilis TPHA test</td>
									<td />
									<td>negative</td>
									<td />
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</>
		</div>
	);
};

export default LabReport;
