const FaceDown = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 24 24' {...props}>
		<path stroke='currentColor' strokeLinejoin='round' strokeWidth='1.25' d='M2 12H22V14H2z' />
		<path stroke='currentColor' strokeWidth='1.25' d='M5 14v5M19 14v5' />
		<path stroke='currentColor' strokeLinecap='round' strokeWidth='1.25' d='M4 19h2M18 19h2' />
		<path stroke='currentColor' strokeMiterlimit='10' strokeWidth='1.25' d='M20.5 12a1.5 1.5 0 100-3 1.5 1.5 0 000 3z' />
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			strokeWidth='1.25'
			d='M10 9h7s1.5 0 1.5 1.5S17 12 17 12h-7m0-3v3m0-3l-2.5 1H3l-1 2h8'
		/>
	</svg>
);

export default FaceDown;
