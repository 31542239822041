import DefaultCamSettings from 'containers/CallSettings/DefaultCamSettings.jsx';

const SectorCallSettings = ({ selectedHealthSystem, levelId, sectorId }) => (
	<div className='account-settings-inner-wrapper general-menu-wrapper configurations-call-settings-admin overflow-unset'>
		<div className='call-settings-wallpapers no-margin'>
			<DefaultCamSettings healthSystemId={selectedHealthSystem} levelId={levelId} sectorId={sectorId} />
		</div>
	</div>
);

export default SectorCallSettings;
