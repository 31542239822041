import classNames from 'classnames';
import ProvidersVisits from 'components/DashboardCharts/ProvidersVisits.jsx';
import { RoleIcon, Grid, Alert } from 'components/index.js';
import { getRoleValueById } from 'infrastructure/helpers/commonHelpers.js';
import VisitsByHour from 'components/DashboardCharts/VisitsByHour.jsx';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const AllProviders = props => {
	const companySettings = useSelector(state => state.company.companySettings);
	const [error, setError] = useState(null);
	const [currentRole, setCurrentRole] = useState(props.selectedProvider.memberRoles[0] || {});

	return (
		<>
			<div className='visits-header'>
				<div onClick={() => props.setSelectedProvider(null)}>
					<i className='material-icons-outlined'>arrow_back</i>
				</div>
				<span>
					{props.selectedProvider.firstName} {props.selectedProvider.lastName}
				</span>
				<div>
					{props.selectedProvider?.memberRoles.map(role => (
						<span
							key={role.id}
							onClick={() => setCurrentRole(role)}
							className={classNames('provider-role', currentRole?.name === role.name ? 'active' : '')}>
							<RoleIcon role={role} isActive={currentRole?.name === role.name} />
							{getRoleValueById(role.id, companySettings)}
							<div>
								<i className='material-icons'>done</i>
							</div>
						</span>
					))}
				</div>
			</div>
			<Grid columns='1fr' className='dashboard-items'>
				<VisitsByHour
					start={props.start}
					end={props.end}
					selectedHealthSystem={props.selectedHealthSystem}
					selectedHospital={props.selectedHospital}
					selectedDepartment={props.selectedDepartment}
					selectedFloor={props.selectedFloor}
					selectedRoom={props.selectedRoom}
					selectedProvider={props.selectedProvider}
					selectedProviderRole={currentRole}
					selectedTimezone={props.selectedTimezone}
					additionalFilters={props.additionalFilters}
				/>
				<ProvidersVisits
					start={props.start}
					end={props.end}
					selectedHealthSystem={props.selectedHealthSystem}
					selectedHospital={props.selectedHospital}
					selectedDepartment={props.selectedDepartment}
					selectedFloor={props.selectedFloor}
					selectedRoom={props.selectedRoom}
					selectedProvider={props.selectedProvider}
					selectedProviderRole={currentRole}
					selectedTimezone={props.selectedTimezone}
					additionalFilters={props.additionalFilters}
				/>
			</Grid>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default AllProviders;
