import { PatientNotes } from 'constants/action-types.js';

export const actionCreators = {
	setPatientDataSaved: (payload, deviceId) => dispatch => {
		dispatch({ type: PatientNotes.SET_PATIENT_DATA_SAVED, payload, deviceId });
	},
	setPatientDataEdited: (payload, deviceId) => dispatch => {
		dispatch({ type: PatientNotes.SET_PATIENT_DATA_EDITED, payload, deviceId });
	},
	increaseDraftsCount: (payload, deviceId) => dispatch => {
		dispatch({ type: PatientNotes.INCREASE_DRAFTS_COUNT, payload, deviceId });
	},
	decreaseDraftsCount: (payload, deviceId) => dispatch => {
		dispatch({ type: PatientNotes.DECREASE_DRAFTS_COUNT, payload, deviceId });
	},
	setPatientDataBulk: payload => dispatch => {
		dispatch({ type: PatientNotes.SET_PATIENT_DATA_BULK, payload });
	},
};
