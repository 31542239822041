const BloodPressure = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} viewBox='0 0 24 24' fill='none' {...props}>
		<path
			d='M14.3633 17.8765C14.3019 17.9177 14.2399 17.9588 14.1774 18C13.5567 18.4083 12.8796 18.8166 12.1427 19.2231C-3.27434 10.7187 7.51756 1.43418 12.1425 7.27195C15.4169 3.13939 21.7816 6.58496 19.7512 11.9673'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinejoin='round'
		/>
		<path
			d='M17.1428 19C19.352 19 21.1428 17.2091 21.1428 15C21.1428 12.7909 19.352 11 17.1428 11C14.9337 11 13.1428 12.7909 13.1428 15C13.1428 17.2091 14.9337 19 17.1428 19Z'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M16.7249 15.1744L17.6444 14.5L17.7249 14.5698L17.0467 15.5L16.7249 15.1744Z'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
export default BloodPressure;
