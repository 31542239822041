import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const CareEventsIcon = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 25 24'
		width={25}
		height={24}
		fill='none'
		color={LightTheme.colors.graySeven}
		{...props}>
		<path
			d='M3.75533 18.3039L11.6321 4.51943C12.016 3.84761 12.9847 3.84761 13.3686 4.51942L21.2455 18.3039C21.6264 18.9705 21.145 19.8 20.3772 19.8H4.62357C3.85575 19.8 3.37438 18.9705 3.75533 18.3039Z'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M10.3456 12.1478L10.3387 12.3295H10.5205H11.4908H11.6548L11.6654 12.1659C11.6842 11.8779 11.785 11.6587 11.9383 11.5119C12.0911 11.3654 12.3113 11.2764 12.5971 11.2764C12.881 11.2764 13.1012 11.3658 13.2487 11.5023C13.3954 11.6381 13.4814 11.8306 13.4814 12.0614C13.4814 12.2877 13.437 12.4483 13.3399 12.5913C13.239 12.7402 13.0711 12.885 12.7946 13.0593C12.4602 13.2685 12.2038 13.4969 12.0359 13.7753C11.8667 14.0559 11.7957 14.373 11.808 14.7446L11.8127 15.1345L11.8148 15.3074H11.9877H12.958H13.133V15.1324V14.8337C13.133 14.6034 13.1721 14.4506 13.2649 14.3145C13.3624 14.1714 13.5309 14.0292 13.8279 13.8464L13.8279 13.8464L13.8289 13.8457C14.4891 13.4329 14.8955 12.8475 14.8955 12.0173C14.8955 11.4442 14.6662 10.942 14.265 10.585C13.8649 10.229 13.3035 10.0252 12.6486 10.0252C11.9314 10.0252 11.3631 10.2496 10.968 10.6373C10.5739 11.0241 10.3681 11.5579 10.3456 12.1478ZM12.4799 17.5752C12.9318 17.5752 13.2736 17.2188 13.2736 16.7684C13.2736 16.3179 12.9318 15.9615 12.4799 15.9615C12.0374 15.9615 11.6908 16.3179 11.6908 16.7684C11.6908 17.2188 12.0374 17.5752 12.4799 17.5752Z'
			fill='currentColor'
			stroke='currentColor'
			strokeWidth={0.35}
		/>
	</svg>
);

export default CareEventsIcon;
