import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import translate from 'i18n-translations/translate.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import Modal from 'components/Modal.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import {
	searchNurses,
	setWorkflow,
	deleteAssociatedNurse,
	addAssociatedNurses,
	getAssociatedNurses,
	updateNurseAuthorization,
} from 'api/workflow.js';
import { getUserId } from 'infrastructure/auth.js';
import { AlertTypes, WorkFlow } from 'constants/enums.js';
import Loader from 'components/Loader.jsx';
import Alert from 'components/Alert.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import Button from 'components/Button.jsx';

const MyWorkflow = () => {
	const companySettings = useSelector(state => state.company.companySettings);
	const doctorData = useSelector(state => state.user.doctorData);
	const [isPageLoading, setIsPageLoading] = useState(false);
	const [isSearchNursesLoading, setIsSearchNursesLoading] = useState(false);
	const [loadingNurse, setLoadingNurse] = useState(null);
	const [myNurses, setMyNurses] = useState([]);
	const [allNurses, setAllNurses] = useState([]);
	const [toAddNurses, setToAddNurses] = useState([]);
	const [error, setError] = useState('');
	const [searchNurseValue, setSearchNurseValue] = useState('');
	const [isNewNurseModalVisible, setNewNurseModalVisibility] = useState(false);
	const [showAuthorizeNursesPopup, setShowAuthorizeNursesPopup] = useState(false);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0, triggerGetData: false });
	const onSearchTypeTimeout = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		const getMyNurses = async () => {
			const params = {
				doctorId: getUserId(),
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
			};
			const response = await getAssociatedNurses(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setMyNurses(response.nurses);
				setPagination(prevState => ({ ...prevState, totalCount: response.totalCount }));
			}
			setLoadingNurse(null);
		};
		getMyNurses();
	}, [pagination.pageIndex, pagination.pageSize, pagination.triggerGetData]);

	useEffect(() => {
		if (isNewNurseModalVisible) {
			setToAddNurses([]);
		}
	}, [isNewNurseModalVisible]);

	useEffect(() => {
		if (!searchNurseValue) {
			setAllNurses([]);
			return;
		}
		const getAllNurses = async value => {
			if (!value) {
				setAllNurses([]);
				return;
			}
			if (onSearchTypeTimeout.current) clearTimeout(onSearchTypeTimeout.current);
			onSearchTypeTimeout.current = setTimeout(async () => {
				setIsSearchNursesLoading(true);
				const response = await searchNurses(value);
				if (response.error) {
					setError(response.error.message);
				} else {
					const previousNurses = myNurses.map(item => item.nurse);
					const filteredArr = response.nurses.filter(item => ![...toAddNurses, ...previousNurses].find(el => item.id === el.id));
					if (searchNurseValue) {
						setAllNurses(filteredArr);
					}
				}
				setIsSearchNursesLoading(false);
			}, 500);
		};
		getAllNurses(searchNurseValue);
	}, [searchNurseValue, myNurses, toAddNurses]);

	const onPageChange = (pageSize, pageIndex) => {
		setIsSearchNursesLoading(true);
		setPagination(prevState => ({ ...prevState, pageSize, pageIndex }));
	};

	const getAuthorizedNurses = () => myNurses.filter(nurse => nurse.isAuthorized);

	const updateWorkFlow = async workflowTypeId => {
		if (workflowTypeId === doctorData.workflowTypeId) {
			return;
		}
		const canChangeWorkFlow =
			(getAuthorizedNurses().length > 0 && WorkFlow.THROUGH_NURSES === workflowTypeId) || WorkFlow.DIRECTLY === workflowTypeId;
		if (!canChangeWorkFlow) {
			setShowAuthorizeNursesPopup(true);
			return;
		}
		setIsPageLoading(true);
		const response = await setWorkflow(getUserId(), workflowTypeId);
		if (response.error) {
			setError(response.error.message);
		} else {
			dispatch(userActionCreators.setDoctorData({ ...doctorData, workflowTypeId }));
		}
		setIsPageLoading(false);
	};

	const deleteMyNurse = async nurseId => {
		const otherAuthorizedNurses = myNurses.filter(item => item.nurse.id !== nurseId && item.isAuthorized);
		if (otherAuthorizedNurses.length === 0 && WorkFlow.THROUGH_NURSES === doctorData.workflowTypeId) {
			setShowAuthorizeNursesPopup(true);
			return;
		}
		setLoadingNurse(nurseId);
		const response = await deleteAssociatedNurse(getUserId(), nurseId);
		if (response.error) {
			setError(response.error.message);
		} else {
			setPagination(prevState => ({ ...prevState, triggerGetData: !prevState.triggerGetData }));
		}
	};

	const updateAuthorizeStatus = async (nurseId, isAuthorized) => {
		const isLastNurse =
			getAuthorizedNurses().length === 1 && !isAuthorized && WorkFlow.THROUGH_NURSES === doctorData.workflowTypeId;
		setLoadingNurse(nurseId);
		const response = await updateNurseAuthorization({ doctorId: getUserId(), nurseId, isAuthorized });
		if (response.error) {
			setError(response.error.message);
		} else {
			setPagination(prevState => ({ ...prevState, triggerGetData: !prevState.triggerGetData }));
		}
		if (isLastNurse) {
			await setWorkflow(getUserId(), WorkFlow.DIRECTLY);
			dispatch(userActionCreators.setDoctorData({ ...doctorData, workflowTypeId: WorkFlow.DIRECTLY }));
		}
	};

	const inviteNurses = async () => {
		if (toAddNurses.length === 0) {
			return;
		}
		const nurseIds = toAddNurses.map(item => item.id);
		const response = await addAssociatedNurses(getUserId(), nurseIds);
		if (response.error) {
			setError(response.error.message);
		} else {
			setPagination(prevState => ({ ...prevState, triggerGetData: !prevState.triggerGetData }));
			setNewNurseModalVisibility(prevState => !prevState);
		}
	};

	const handleInputChange = event => {
		setSearchNurseValue(event.target.value);
		if (event.target.value) {
			setIsSearchNursesLoading(true);
		}
	};

	const toggleNewNurseModalVisibility = () => {
		setNewNurseModalVisibility(prevStateNewNurseModalVisibility => !prevStateNewNurseModalVisibility);
		setSearchNurseValue('');
	};

	return (
		<div className='account-settings-inner-wrapper'>
			{isPageLoading && <Loader />}
			{!isPageLoading && (
				<>
					<h4>{translate('myWorkflow')}</h4>
					<div className='measurement-units-table my-workflow-table'>
						<div
							onClick={() => updateWorkFlow(WorkFlow.DIRECTLY)}
							className={classNames('flex', doctorData.workflowTypeId === WorkFlow.DIRECTLY ? 'active' : '')}>
							<div>
								<i className='material-icons'>done</i>
							</div>
							<p>{translate('directWorkflow')}</p>
						</div>
						<div
							onClick={() => updateWorkFlow(WorkFlow.THROUGH_NURSES)}
							className={classNames('flex', doctorData.workflowTypeId === WorkFlow.THROUGH_NURSES ? 'active' : '')}>
							<div>
								<i className='material-icons'>done</i>
							</div>
							<p>{translate('throughSomethings', { value: companySettings.nurseDisplayName })}</p>
						</div>
					</div>
					<div>
						<h5 className='top-30'>{translate('pluralRoleName', { value: companySettings.nurseDisplayName })}</h5>
						<div className='account-settings-grid my-workflow-grid flex'>
							<div
								className='account-settings-grid-add-el cursor-pointer'
								onClick={() => setNewNurseModalVisibility(prevState => !prevState)}>
								<div>
									<i className='material-icons'>add</i>
								</div>
								<span>{translate('clickHereAddSomething', { role: companySettings.nurseDisplayName })}</span>
							</div>
							{myNurses.map(item => {
								const nurse = item?.nurse;
								return (
									<div key={nurse?.id}>
										<ProfilePicture
											className='doctor-request-img'
											profilePicture={nurse?.profilePicture}
											firstName={nurse?.firstName}
											lastName={nurse?.lastName}
										/>
										{loadingNurse === nurse?.id && (
											<div className='full-absolute'>
												<Loader />
											</div>
										)}

										<p>
											{nurse?.firstName} {nurse?.lastName}
										</p>
										<span>{nurse?.email}</span>
										<Button
											variant='green'
											className={!item.isAuthorized ? 'active' : ''}
											onClick={() => updateAuthorizeStatus(nurse?.id, !item.isAuthorized)}
											icon='done'
											text={!item.isAuthorized ? translate('authorize') : translate('unauthorize')}
										/>
										<i className='material-icons cursor-pointer' onClick={() => deleteMyNurse(nurse?.id)}>
											close
										</i>
									</div>
								);
							})}
						</div>
						{myNurses.length > 0 && (
							<Pagination
								totalCount={pagination.totalCount}
								pageSize={pagination.pageSize}
								pageIndex={pagination.pageIndex}
								onChange={(size, index) => onPageChange(size, index)}
							/>
						)}
					</div>
				</>
			)}
			<Modal
				keepOpenOnOutsideClick={true}
				display={isNewNurseModalVisible}
				position='center'
				className='standard-modal-wrapper modal-wrapper-wo-btn fixed-min-height-modal'
				onModalClose={toggleNewNurseModalVisibility}>
				<div className='standard-modal-inner modal-wrapper-wo-btn-inner'>
					<div className='my-workflow-modal-inner position-relative'>
						<h3>{translate('addSomething', { value: companySettings.nurseDisplayName })}</h3>
						<div className='mi-input-wrapper full-width top-15'>
							<input type='text' onChange={handleInputChange} value={searchNurseValue} />
							{!isSearchNursesLoading && allNurses.length > 0 && searchNurseValue && (
								<div>
									{allNurses.map(nurse => (
										<div
											key={nurse.id}
											className='cursor-pointer'
											onClick={() => {
												setSearchNurseValue('');
												setToAddNurses(prevState => [...prevState, nurse]);
											}}>
											<p>
												{nurse.firstName} {nurse.lastName}
											</p>
											<p>{nurse.email}</p>
										</div>
									))}
								</div>
							)}
							{allNurses.length === 0 && searchNurseValue && !isSearchNursesLoading && (
								<div>
									<div>
										<p>{translate('noResultsFound')}</p>
									</div>
								</div>
							)}
							{isSearchNursesLoading && (
								<div className='position-relative'>
									<div className='mi-loading-div'>
										<Loader />
									</div>
								</div>
							)}
						</div>

						<div className='mi-selected-list full-width'>
							{toAddNurses.length > 0 && <p>{translate('selectedItems')}</p>}
							{toAddNurses.map(nurse => (
								<>
									<div key={nurse.id} className='position-relative cursor-pointer'>
										<i
											className='material-icons'
											onClick={() => setToAddNurses(prevState => prevState.filter(item => item.id !== nurse.id))}>
											close
										</i>
										<p>
											{nurse.firstName} {nurse.lastName}
										</p>
										<p>{nurse.email}</p>
									</div>
								</>
							))}
						</div>
						<Button
							disabled={toAddNurses.length === 0}
							onClick={inviteNurses}
							imgIcon={`${healthCareCdnUrl}workflow/invite-nurse.svg`}
							text={translate('inviteSomethings', { value: companySettings.nurseDisplayName })}
						/>
					</div>
				</div>
			</Modal>
			<PopUpAlert
				alertType={AlertTypes.WARNING}
				display={showAuthorizeNursesPopup}
				onAlertClose={() => setShowAuthorizeNursesPopup(false)}
				contentText={translate('youNeedAuthorizedSomething', { roleNameNurse: companySettings.nurseDisplayName })}
				isSilent={true}
				center={true}
			/>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default MyWorkflow;
