import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getDataAcquisitionSchedule = async ({ roomId }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/rooms/${roomId}/data-acquisition-schedule`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addDataAcquisitionSchedule = async ({ roomId, ...dataToSend }) => {
	try {
		const { data } = await gatewayApi.post(
			`v1/healthcare/organizations/${getCompanyId()}/rooms/${roomId}/data-acquisition-schedule`,
			dataToSend
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteDataAcquisitionSchedule = async ({ roomId, scheduleId }) => {
	try {
		const { data } = await gatewayApi.delete(
			`v1/healthcare/organizations/${getCompanyId()}/rooms/${roomId}/data-acquisition-schedule/${scheduleId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
