const IvBag = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} viewBox='0 0 24 24' fill='none' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeWidth={1.25}
			d='M18 11.552v-2.352A2.353 2.353 0 0 0 15.647 7v0a2.353 2.353 0 0 0-2.353 2.353v3.293A4.706 4.706 0 0 1 8.706 20v0A4.706 4.706 0 0 1 4 15.647v-.104'
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.25}
			d='m2.08 12.9 1.552 1.294c.233.194.368.481.368.784C4 15.543 4.457 16 5.022 16h1.956C7.543 16 8 15.543 8 14.979c0-.304.135-.591.368-.785l1.553-1.295A3 3 0 0 0 11 10.595V4a3 3 0 0 0-3-3H4a3 3 0 0 0-3 3v6.595a3 3 0 0 0 1.08 2.304Z'
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeWidth={1.25}
			d='M1 4h1.5M1 7h1.5M1 10h1.5M19 16.5a1 1 0 0 1 1-1v0a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v0a1 1 0 0 1-1-1v-1ZM20 18.5v1'
		/>
	</svg>
);

export default IvBag;
