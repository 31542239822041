import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import Cropper from 'react-easy-crop';
import { uploadProfilePic } from 'api/media.js';
import Avatar from 'components/Avatar.jsx';
import Modal from 'components/Modal.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';

const ProfilePicture = props => {
	const [previewImage, setPreviewImage] = useState(null);
	const [uploadedImg, setUploadedImg] = useState(null);
	const [isImageCropperVisible, setIsImageCropperVisible] = useState(false);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [croppedImage, setCroppedImage] = useState('');
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);

	useEffect(() => {
		return () => {
			URL.revokeObjectURL(uploadedImg?.preview);
		};
	}, [uploadedImg]);

	const uploadCroppedImage = async () => {
		const formData = new FormData();
		formData.append('File', uploadedImg);
		formData.append('CropWidth', croppedAreaPixels ? croppedAreaPixels.width : '0');
		formData.append('CropHeight', croppedAreaPixels ? croppedAreaPixels.height : '0');
		formData.append('CropOffsetX', croppedAreaPixels ? croppedAreaPixels.x : '0');
		formData.append('CropOffsetY', croppedAreaPixels ? croppedAreaPixels.y : '0');
		const response = await uploadProfilePic(formData);
		if (response.error) {
			props.setError(response.error.message);
			setIsImageCropperVisible(false);
			return;
		}
		setPreviewImage(response.thumbnails[2].url);
		props.setProfilePicture(response.fileName);
		setIsImageCropperVisible(false);
	};

	const toggleImageCropper = croppedImg => {
		setIsImageCropperVisible(prevState => !prevState);
		setCroppedImage(croppedImg || '');
		setZoom(1);
		setCrop({ x: 0, y: 0 });
	};

	const onImageUpload = async uploadedFile => {
		const photo = uploadedFile[0];
		const sizeInMB = photo.size / 1024 / 1024;
		if (sizeInMB > 2) {
			props.setError(translate('maxSizeExceeded'));
			return;
		}
		photo.preview = URL.createObjectURL(photo);
		toggleImageCropper(photo.preview);
		setUploadedImg(photo);
		props.setError(null);
	};

	return (
		<div className='flex profile-info-image user-profile flex-align-center'>
			<div>
				{previewImage && <Avatar src={previewImage} />}
				{props.profilePicture && !previewImage && <Avatar src={props.profilePicture} />}
			</div>
			<Dropzone accept='image/jpeg, image/jpg, image/png' onDrop={onImageUpload}>
				{({ getRootProps, getInputProps }) => (
					<div style={{ textAlign: 'center' }} {...getRootProps()}>
						<input {...getInputProps()} />
						<Button
							className='attestations-inputs-button'
							imgIcon={`${healthCareCdnUrl}edit-photo-icon.svg`}
							text={translate('uploadNewPicture')}
						/>
					</div>
				)}
			</Dropzone>
			<Modal
				display={isImageCropperVisible}
				onModalClose={toggleImageCropper}
				onModalSubmit={uploadCroppedImage}
				className='image-cropper-modal'
				primaryButtonLabel={translate('upload')}>
				<Cropper
					image={croppedImage}
					crop={crop}
					zoom={zoom}
					aspect={1}
					onCropChange={cropValue => setCrop(cropValue)}
					onCropComplete={(croppedArea, croppedPixels) => setCroppedAreaPixels(croppedPixels)}
					onZoomChange={zoomValue => setZoom(zoomValue)}
				/>
			</Modal>
		</div>
	);
};
export default ProfilePicture;
