const Duration = props => (
	<svg width='33' height='32' viewBox='0 0 33 32' fill='none' color='#7892C3' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M24.6378 22.3039L22.436 20.5248C21.6902 19.9199 20.6248 19.8839 19.8435 20.4536L17.7482 21.9836C17.4641 22.1971 17.0738 22.1615 16.8248 21.9125L13.38 18.7813L10.2193 15.2942C9.97071 15.0452 9.9352 14.6538 10.1483 14.3691L11.6754 12.2698C12.2436 11.487 12.2081 10.4195 11.6043 9.6723L9.82866 7.46622C9.04736 6.46993 7.55578 6.39876 6.66794 7.28831L4.75021 9.20973C4.32404 9.63671 4.11096 10.2416 4.11096 10.8109C4.35956 15.3298 6.41935 19.6352 9.43801 22.6597C12.4567 25.6841 16.7183 27.7479 21.264 27.997C21.8678 28.0325 22.436 27.7831 22.8622 27.3561L24.7799 25.4351C25.7388 24.5811 25.6322 23.0867 24.6378 22.3039Z'
			stroke='currentColor'
			strokeWidth='2'
		/>
		<ellipse cx='22.7776' cy='9.33366' rx='6.66667' ry='6.66667' stroke='currentColor' strokeWidth='2' />
		<path
			d='M26.1109 7.66732L21.9443 11.834L20.2776 10.1673'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
export default Duration;
