import React, { useEffect, useRef, useState } from 'react';
import { Alert, EmptyState, Grid, Loader } from 'components';
import translate from 'i18n-translations/translate.jsx';
import { getHoursWithMinutes, monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import CareLogDetails from 'containers/Rpm/CareLogDetails';
import { getPatientCareLogs } from 'api/rpm.js';
import Button from 'components/Button.jsx';

const CareLogs = props => {
	const [careLogs, setCareLogs] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [error, setError] = useState(null);
	const [expandedRows, setExpandedRows] = useState([]);
	const [pageIndex, setPageIndex] = useState(0);
	const hasReachedEnd = useRef(null);

	useEffect(() => {
		const getCareLogsPerPatient = async () => {
			const response = await getPatientCareLogs({ patientId: props.selectedPatient?.patientId, pageIndex });
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			setCareLogs(prevState => prevState.concat(response.careLogs));
			setSelectedPatient(response.patient);
			setError(null);
			setIsLoading(false);
			hasReachedEnd.current = response.careLogs.length < 10;
		};
		getCareLogsPerPatient();
	}, [pageIndex, props.selectedPatient]);

	const viewCareLogDetails = async rowIndex => {
		if (expandedRows.includes(rowIndex)) {
			setExpandedRows(expandedRows.filter(row => row !== rowIndex));
		} else {
			setExpandedRows([...expandedRows, rowIndex]);
		}
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	return (
		<>
			<div className='care-logs-wrapper' ref={hasReachedEnd} onScroll={handleScroll}>
				<div className='flex flex-align-center'>
					<Button className='care-logs-btn' onClick={() => props.setShouldShowCareLogs(false)} icon='chevron_left' /> 
					<p className='care-logs-title'>
						{translate('patientsCareLogs', { value: `${props.selectedPatient?.firstName} ${props.selectedPatient?.lastName}` })}
					</p>
				</div>
				<div>
					{careLogs.length === 0 && !isLoading && (
						<div className='flex flex-align-center flex-justify-center empty-state-wrapper'>
							<EmptyState title={translate('noResultsFound')} image='alerts.svg?v2' />
						</div>
					)}
					{isLoading && (
						<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
							<div style={{ textAlign: 'center' }}>
								<Loader />
							</div>
						</Grid>
					)}
					{careLogs.length > 0 && !isLoading && (
						<>
							<div>
								{careLogs.map((item, index) => (
									<div key={item.id} className='flex flex-wrap care-logs-item'>
										<div className='care-log-td'>
											<p>{monthDayYear(item.startedAt)}</p>
										</div>
										<div className='care-log-td'>
											<p className='care-log-time'>{translate('at', { value: getHoursWithMinutes(item.startedAt) })}</p>
										</div>
										<div className='care-log-td'>
											<Button
												icon={expandedRows.includes(index) ? 'chevron_right' : 'expand_more'}
												className='care-logs-btn'
												onClick={() => viewCareLogDetails(index)}
											/>
										</div>
										{expandedRows.includes(index) && (
											<div className='full-width'>
												<CareLogDetails
													selectedVisit={item}
													selectedPatient={selectedPatient}
													preferredUnits={props.preferredUnits}
												/>
											</div>
										)}
									</div>
								))}
							</div>
						</>
					)}
				</div>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default CareLogs;
