import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

function MedicalInfo(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			stroke={LightTheme.colors.graySeven}
			{...props}>
			<rect
				x={2.4}
				y={1.199}
				width={19.2}
				height={21.6}
				rx={2}
				strokeWidth={1.5}
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M5.4 15h13.2M7.2 18.6h9.6M12 4.799v3m0 0v3m0-3h3m-3 0H9'
				strokeWidth={1.5}
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default MedicalInfo;
