import React, { useContext, useEffect, useRef, useState } from 'react';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { getAiAlertActivities, getManualAlertActivities } from 'api/alerts.js';
import { Grid, Loader } from 'components/index.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { getInterventions } from 'api/interventions.js';
import Button from 'components/Button.jsx';
import { getDeviceOwnerPatient } from 'api/patients.js';
import AlertDetails from 'components/Monitoring/AlertDetails.jsx';
import Alert from 'components/Alert.jsx';
import { MainAlertType } from 'constants/alerts.js';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { AiSetting, SettingsCategory } from 'constants/configurationEnums.js';
import { AiAlertId } from 'constants/enums.js';

const ManualAlertsList = ({ patientId, deviceId, roomId }) => {
	const intl = useIntl();
	const [error, setError] = useState(null);
	const [details, setDetails] = useState({ activities: [], detailedIntervention: null });
	const [interventions, setInterventions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [isEvidenceEnabled, setIsEvidenceEnabled] = useState(false);
	const socket = useContext(SocketContext);
	const hasReachedEnd = useRef(null);

	useEffect(() => {
		const handleResponse = async intervention => {
			const { creationDate, alertTypeName, manualAlertTypeId, alertId, isDraft } = intervention;
			if (intervention.deviceId !== deviceId || isDraft) {
				return;
			}
			setInterventions(prevState => [
				{
					dateCreated: creationDate,
					mainAlertType: MainAlertType.MANUAL,
					typeId: manualAlertTypeId,
					typeName: alertTypeName,
					id: alertId,
				},
				...prevState,
			]);
		};
		const handleInterventionUpdate = async intervention => {
			const { dateCreated, mainAlertType, typeId, typeName, interventionId, isDraft } = intervention;
			if (intervention.deviceId !== deviceId || isDraft) {
				return;
			}
			setInterventions(prevState => [{ dateCreated, mainAlertType, typeId, typeName, id: interventionId }, ...prevState]);
		};
		socket.on(SocketEvents.Alerts.ALERTS_INTERVENTION_UPDATED, handleInterventionUpdate);
		socket.on(SocketEvents.HealthCare.ALERTS_MANUAL_ADDED, handleResponse);
		return () => {
			socket.off(SocketEvents.HealthCare.ALERTS_INTERVENTION_UPDATED, handleInterventionUpdate);
			socket.off(SocketEvents.HealthCare.ALERTS_MANUAL_ADDED, handleResponse);
		};
	}, [patientId, deviceId, socket]);

	useEffect(() => {
		const fetchIntervetions = async () => {
			if (!deviceId) {
				setInterventions([]);
				setIsLoading(false);
				return;
			}
			let existingPatientId = patientId;
			if (!patientId) {
				const deviceOwnerResponse = await getDeviceOwnerPatient(deviceId);
				if (deviceOwnerResponse.error) {
					setError(deviceOwnerResponse.error.message);
					return;
				}
				existingPatientId = deviceOwnerResponse?.healthcareUserId;
			}
			if (!existingPatientId) {
				setInterventions([]);
				setIsLoading(false);
				return;
			}
			const params = {
				patientId: existingPatientId,
				deviceId,
				isDraft: false,
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
			};
			const response = await getInterventions(params);
			if (response.error) {
				setError(response.error.message);
				setInterventions([]);
				setIsLoading(false);
				return;
			}
			if (pagination.pageIndex === 0) {
				setInterventions(response.devicePatientInterventions || []);
			} else {
				setInterventions(prevState => [...prevState, ...(response.devicePatientInterventions || [])]);
			}
			setIsLoading(false);
			setPagination(prevState => ({ ...prevState, totalCount: response.totalCount || 0 }));
			hasReachedEnd.current = response?.devicePatientInterventions?.length < 10;
		};
		fetchIntervetions();
	}, [deviceId, patientId, pagination.pageIndex, pagination.pageSize]);

	useEffect(() => {
		const fetchRoomSettings = async () => {
			const adminAiSettings = await getRoomSettings(roomId, SettingsCategory.AI_SETTINGS);
			if (adminAiSettings.error) {
				setError(adminAiSettings.error.message);
				return;
			}
			setIsEvidenceEnabled(
				adminAiSettings.settings.some(item => item.settingTypeId === AiSetting.ALERTS_EVIDENCE_NURSE && item.value === 'true')
			);
		};
		fetchRoomSettings();
	}, [roomId]);

	const getDetails = async item => {
		if (item.mainAlertType === MainAlertType.MANUAL) {
			const response = await getManualAlertActivities(item.id);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setDetails({
				activities: response.manualAlertActivities,
				detailedIntervention: null,
			});
		}
		if (item.mainAlertType === MainAlertType.AI) {
			const response = await getAiAlertActivities(item.id);
			if (response.error) {
				setError(response.error.message);
				return;
			}

			setDetails({
				activities: response.aiAlertActivities,
				detailedIntervention: item,
			});
		}
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			setPagination(prevState => ({ ...prevState, pageIndex: prevState.pageIndex + 1 }));
		}
	};

	const messageExists = id => !!intl.messages[id];

	const getDescription = item => {
		const formattedTypeName = intl.formatMessage({ id: item.typeName });
		const isAiVoiceAlert = item.typeId >= AiAlertId.SNEEZING && item.typeId <= AiAlertId.VITAL_SIGN_ALARM;
		return isAiVoiceAlert ? translate('alertDetected', { value: formattedTypeName }) : formattedTypeName;
	};

	return (
		<div className='box-max-height' onScroll={handleScroll}>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='250px' horizAlign='center' vertAlign='center'>
					<div className='text-align-center'>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading &&
				details.activities?.length === 0 &&
				interventions.map(item => (
					<div key={item?.id} className='interventions-history cursor-pointer' onClick={() => getDetails(item)}>
						<div className='flex flex-space-between flex-align-center'>
							<div>
								<h3>{messageExists(item.typeName) ? getDescription(item) : item.typeName}</h3>
								<p>{formattedDate(item.dateCreated)}</p>
							</div>
							<div className='flex flex-space-between flex-align-center draft-item-actions'>
								<Button icon='east' alt='east' />
							</div>
						</div>
					</div>
				))}
			{details.activities?.length > 0 && (
				<>
					<Button onClick={() => setDetails({ activities: [], detailedIntervention: null })} icon='west' alt='west' />
					<AlertDetails itemDetails={details} isEvidenceEnabled={isEvidenceEnabled} />
				</>
			)}
			{!interventions.length && !isLoading && (
				<div className='patient-ai-history-no-data'>
					<p className='font-14'>{translate('noData')}</p>
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default ManualAlertsList;
