import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import { setHours, setMinutes } from 'date-fns';
import sq from 'date-fns/locale/sq/index.js';
import en from 'date-fns/locale/en-US/index.js';
import { getDoctorSchedule, createDoctorSchedule } from 'api/appointments.js';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserId } from 'infrastructure/auth.js';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import Alert from 'components/Alert.jsx';
import { addMinutes, subtractMinutes } from 'infrastructure/helpers/dateHelper.js';
import { useIntl } from 'react-intl';
import Button from 'components/Button.jsx';

registerLocale('en-US', en);
registerLocale('sq', sq);
const SetUpAvailability = props => {
	const intl = useIntl();
	const [availableHoursFrom, setAvailableHoursFrom] = useState(null);
	const [availableHoursTo, setAvailableHoursTo] = useState(null);
	const [availableBreakFrom, setAvailableBreakFrom] = useState(null);
	const [availableBreakTo, setAvailableBreakTo] = useState(null);
	const [selectedBreaks, setSelectedBreaks] = useState([]);
	const [error, setError] = useState(null);
	const [checkedWeekDays, setCheckedWeekDays] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [breakError, setBreakError] = useState(null);

	const weekDays = [
		{
			name: intl.formatMessage({ id: 'sundays' }),
			id: 1,
		},
		{
			name: intl.formatMessage({ id: 'mondays' }),
			id: 2,
		},
		{
			name: intl.formatMessage({ id: 'tuesdays' }),
			id: 3,
		},
		{
			name: intl.formatMessage({ id: 'wednesdays' }),
			id: 4,
		},
		{
			name: intl.formatMessage({ id: 'thursdays' }),
			id: 5,
		},
		{
			name: intl.formatMessage({ id: 'fridays' }),
			id: 6,
		},
		{
			name: intl.formatMessage({ id: 'saturdays' }),
			id: 7,
		},
	];

	useEffect(() => {
		const fetchDoctorSchedule = async () => {
			const availabilityData = await getDoctorSchedule(getUserId());
			if (availabilityData.error) {
				setError(availabilityData.error.message);
				setIsLoading(false);
				return;
			}
			if (availabilityData.weekDays.length > 0) {
				setSelectedBreaks(
					availabilityData.breaks.map(element => ({
						startTime: moment.utc(element.startTime).format('YYYY-MM-DDTHH:mm:ss'),
						endTime: moment.utc(element.endTime).format('YYYY-MM-DDTHH:mm:ss'),
					}))
				);

				setAvailableHoursFrom(new Date(availabilityData.startTime));
				setAvailableHoursTo(new Date(availabilityData.endTime));
				setCheckedWeekDays(availabilityData.weekDays);
			}
			setIsLoading(false);
		};
		fetchDoctorSchedule();
	}, []);

	const createAvailability = async () => {
		if (!isFormValid()) {
			return;
		}
		setIsLoading(true);
		const doctorAvailability = {
			weekDays: checkedWeekDays,
			startTime: moment.utc(availableHoursFrom).format('YYYY-MM-DDTHH:mm'),
			endTime: moment.utc(availableHoursTo).format('YYYY-MM-DDTHH:mm'),
			breaks: selectedBreaks,
		};
		if (
			!doctorAvailability.breaks.every(item =>
				moment(item.startTime).isBetween(
					moment.utc(doctorAvailability.startTime).subtract(1, 'days').format('YYYY-MM-DDTHH:mm'),
					moment.utc(doctorAvailability.endTime).add(1, 'days').format('YYYY-MM-DDTHH:mm')
				)
			)
		) {
			setBreakError(translate('checkBreakTime'));
			return;
		}
		const response = await createDoctorSchedule(getUserId(), doctorAvailability);
		if (response.error || !response.hasSucceeded) {
			setError(translate('anErrorOccurred'));
		}

		props.toggleSetUpAvailability();

		setAvailableHoursFrom(null);
		setAvailableHoursTo(null);
		setAvailableBreakFrom(null);
		setAvailableBreakTo(null);
		setSelectedBreaks([]);
	};

	const setBreaks = () => {
		if (availableBreakFrom && availableBreakTo && moment(availableBreakFrom).isBefore(availableBreakTo)) {
			const formattedAvailableBreakFrom = moment(availableBreakFrom).utc().format('YYYY-MM-DDTHH:mm:ss');
			if (!selectedBreaks.some(el => el.startTime === formattedAvailableBreakFrom)) {
				setSelectedBreaks(prevState => {
					const selectedBreaksCopied = [...prevState];
					selectedBreaksCopied.push({
						startTime: moment.utc(availableBreakFrom).format('YYYY-MM-DDTHH:mm:ss'),
						endTime: moment.utc(availableBreakTo).format('YYYY-MM-DDTHH:mm:ss'),
					});
					return selectedBreaksCopied;
				});
			} else {
				setBreakError(translate('alreadyAddedBreakTime'));
			}

			return {
				selectedBreaks,
				setBreakError: null,
				availableBreakFrom: null,
				availableBreakTo: null,
			};
		}
		return {};
	};

	const selectWeekDays = (ev, key) => {
		if (ev.target.checked) {
			checkedWeekDays.push(key);
			setCheckedWeekDays([...checkedWeekDays]);
		} else {
			const filteredWeekDays = checkedWeekDays.filter(x => x !== key);
			setCheckedWeekDays(filteredWeekDays);
		}
	};

	const removeBreak = index => {
		setSelectedBreaks(prevState => [...prevState]);
		selectedBreaks.splice(index, 1);
		return {
			selectedBreaks,
		};
	};

	const isFormValid = () => checkedWeekDays.length > 0 && availableHoursFrom !== null && availableHoursTo !== null;

	const changeBreakFrom = date => {
		if (availableBreakTo && moment(availableBreakTo).isBefore(moment(date))) {
			setAvailableBreakTo(new Date(moment(date).add(60, 'minutes').format('YYYY-MM-DDTHH:mm:ss')));
		}
		setAvailableBreakFrom(date);
	};

	const changeAvailableHoursFrom = date => {
		if (availableHoursTo && moment(availableHoursTo).isBefore(moment(date))) {
			setAvailableHoursTo(new Date(moment(date).add(60, 'minutes').format('YYYY-MM-DDTHH:mm:ss')));
		}
		setAvailableHoursFrom(date);
		setAvailableBreakFrom(null);
		setAvailableBreakTo(null);
	};

	return (
		<>
			<Modal
				display={props.isSetUpAvailabilityModalVisible}
				primaryButtonLabel={translate('saveChanges')}
				position='center'
				isLoading={isLoading}
				onModalClose={props.toggleSetUpAvailability}
				shouldSubmitOnEnter={false}
				onModalSubmit={createAvailability}
				isSubmitDisabled={!isFormValid()}
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal set-up-availability-modal'>
				<Form height={550} className='modal-form-wrapper'>
					<h3>
						{translate('setup')} {translate('availabilitySetup')}
					</h3>
					<p>{translate('letAvailbleSchedule')}</p>
					<div className='create-appointment-modal'>
						<div>
							<p>{translate('availableDays')}</p>
							<div className='flex available-appointments calendar-events-app-modal'>
								{weekDays.map(weekDay => (
									<div key={weekDay.id} className='appointments-list'>
										<label>
											<input
												type='checkbox'
												value={weekDay.id}
												name={weekDay.name}
												onChange={event => selectWeekDays(event, weekDay.id)}
												checked={checkedWeekDays.includes(weekDay.id)}
											/>
											<div className='active-av-app' />
											<span>{weekDay.name}</span>
										</label>
									</div>
								))}
							</div>
						</div>
						<div>
							<p>{translate('availableHours')}</p>
							<div className='flex available-hours'>
								<DatePicker
									value={availableHoursFrom}
									selected={availableHoursFrom}
									placeholderText={intl.formatMessage({ id: 'from' })}
									onChange={date => changeAvailableHoursFrom(date)}
									showTimeSelect
									showTimeSelectOnly
									timeIntervals={30}
									timeCaption={intl.formatMessage({ id: 'time' })}
									dateFormat='h:mm aa'
									popperPlacement='top'
									minTime={setHours(setMinutes(new Date(), 0), 0)}
									maxTime={availableHoursTo ? subtractMinutes(availableHoursTo, 15) : setHours(setMinutes(new Date(), 30), 23)}
									onKeyDown={event => event.preventDefault()}
								/>
								<DatePicker
									value={availableHoursTo}
									selected={availableHoursTo}
									placeholderText={intl.formatMessage({ id: 'to' })}
									onChange={date => {
										setAvailableHoursTo(date);
										setAvailableBreakFrom(null);
										setAvailableBreakTo(null);
									}}
									showTimeSelect
									showTimeSelectOnly
									timeIntervals={30}
									timeCaption={intl.formatMessage({ id: 'time' })}
									dateFormat='h:mm aa'
									popperPlacement='top'
									minTime={availableHoursFrom ? addMinutes(availableHoursFrom, 15) : setHours(setMinutes(new Date(), 0), 0)}
									maxTime={setHours(setMinutes(new Date(), 30), 23)}
									onKeyDown={event => event.preventDefault()}
								/>
							</div>
						</div>
						<div>
							<p>{translate('break')}</p>
							<div className='flex set-up-break-wrapper'>
								<div className='flex'>
									<DatePicker
										value={availableBreakFrom}
										selected={availableBreakFrom}
										placeholderText={intl.formatMessage({ id: 'from' })}
										onChange={date => changeBreakFrom(date)}
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={15}
										timeCaption={intl.formatMessage({ id: 'time' })}
										dateFormat='h:mm aa'
										popperPlacement='top'
										minTime={availableHoursFrom ? new Date(availableHoursFrom) : setHours(setMinutes(new Date(), 0), 0)}
										maxTime={availableHoursTo ? new Date(availableHoursTo) : setHours(setMinutes(new Date(), 45), 23)}
										onKeyDown={event => event.preventDefault()}
									/>
									<DatePicker
										value={availableBreakTo}
										selected={availableBreakTo}
										placeholderText={intl.formatMessage({ id: 'to' })}
										onChange={date => setAvailableBreakTo(date)}
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={15}
										timeCaption={intl.formatMessage({ id: 'time' })}
										dateFormat='h:mm aa'
										popperPlacement='top'
										minTime={availableBreakFrom ? addMinutes(availableBreakFrom, 15) : new Date(availableHoursFrom)}
										maxTime={availableHoursTo ? new Date(availableHoursTo) : setHours(setMinutes(new Date(), 45), 23)}
										onKeyDown={event => event.preventDefault()}
									/>
								</div>
								<Button onClick={setBreaks} icon='add' />
							</div>
							<div className='flex available-appointments calendar-events-app-modal break-time-av-app'>
								{selectedBreaks.map((x, index) => (
									<div className='appointments-list'>
										<label>
											<span>
												{moment.utc(x.startTime).local().locale(getPreferredLanguageLocale()).format('hh:mm A')}-
												{moment.utc(x.endTime).local().locale(getPreferredLanguageLocale()).format('hh:mm A')}
											</span>
											<Button icon='close' size='small' onClick={() => removeBreak(index)} alt='close' border='none' />
										</label>
									</div>
								))}
								<span className='red-error'>{breakError}</span>
							</div>
						</div>
					</div>
				</Form>
			</Modal>

			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default SetUpAvailability;
