import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getSleepScheduleConfigurations = async params => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${getCompanyId()}/configurations/sleep-schedule`, {
			params,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const addSleepScheduleConfiguration = async ({ teamId, teamTypeId, payload }) => {
	try {
		const { data } = await gatewayApi.post(
			`/v1/healthcare/organizations/${getCompanyId()}/teams/${teamId}/team-types/${teamTypeId}/configurations/sleep-schedule`,
			payload
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const editSleepScheduleConfiguration = async ({ itemId, teamId, teamTypeId, payload }) => {
	try {
		const { data } = await gatewayApi.put(
			`v1/healthcare/organizations/${getCompanyId()}/teams/${teamId}/team-types/${teamTypeId}/configurations/sleep-schedule/${itemId}`,
			payload
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteSleepScheduleConfiguration = async ({ itemId, teamId, teamTypeId }) => {
	try {
		const { data } = await gatewayApi.delete(
			`v1/healthcare/organizations/${getCompanyId()}/teams/${teamId}/team-types/${teamTypeId}/configurations/sleep-schedule/${itemId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
