import { FieldArray } from 'formik';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { Country } from 'constants/enums.js';
import { generateCustomStyles } from 'constants/react-select-style.js';

const Licenses = props => {
	const intl = useIntl();

	const getCountryValue = index =>
		props
			.transformArray(props.countries)
			.filter(option => option.value === props.formikProps.values.licenseHistory[index].country2);

	const getStateValue = index =>
		props
			.transformArray(props.states)
			.filter(option => option.value === props.formikProps.values.licenseHistory[index].licenseStateId);

	return (
		<div className='professional-details-wrapper'>
			<label>{translate('licenses')}</label>
			<div className='flex personal-details-wrapper professional-wrapper'>
				<FieldArray name='licenseHistory'>
					{({ push, remove }) => (
						<>
							{props.formikProps.values.licenseHistory.map((license, index) => {
								const { licenseHistory } = props.formikProps.values;
								const licenseErrors = props.formikProps.errors.licenseHistory;
								return (
									<div className='flex flex-wrap' key={`${license?.cuntry2}-country`}>
										<div className='flex column-direction personal-details position-relative registration-select-wrapper'>
											<label>{translate('country')}</label>
											<Select
												value={getCountryValue(index)}
												placeholder={intl.formatMessage({ id: 'selectCountry' })}
												classNamePrefix='react-select'
												options={props.transformArray(props.countries)}
												onChange={event => props.formikProps.setFieldValue(`licenseHistory[${index}].country2`, event?.value)}
												isSearchable
												isClearable
												styles={{
													...generateCustomStyles({ darkMode: props.isDarkMode }),
												}}
											/>
											{licenseErrors && <span className='red-error'>{licenseErrors[index]?.country2}</span>}
											{licenseHistory[index].country2 === Country.USA && (
												<>
													<label>*{translate('selectState')}</label>
													<Select
														value={getStateValue(index)}
														placeholder={intl.formatMessage({ id: 'selectState' })}
														classNamePrefix='react-select'
														options={props.transformArray(props.states)}
														onChange={event =>
															props.formikProps.setFieldValue(`licenseHistory[${index}].licenseStateId`, event?.value)
														}
														isSearchable
														isClearable
														styles={{
															...generateCustomStyles({ darkMode: props.isDarkMode }),
														}}
													/>
													{licenseErrors && <span className='red-error'>{licenseErrors[index]?.licenseStateId}</span>}
												</>
											)}
										</div>
										<div className='flex column-direction personal-details'>
											<label>{translate('licenseNo')}</label>
											<input
												type='text'
												placeholder={intl.formatMessage({ id: 'licenseNo' })}
												value={licenseHistory[index].licenseNo}
												onChange={props.onChange}
												maxLength={15}
												name={`licenseHistory[${index}].licenseNo`}
											/>
											{licenseErrors && <span className='red-error'>{licenseErrors[index]?.licenseNo}</span>}
										</div>
										{props.formikProps.values.licenseHistory.length > 1 && (
											<div
												className='flex full-width right-align-content cursor-pointer'
												style={{ alignItems: 'center', flex: '0 0 100%' }}>
												<span className='flex top-15' onClick={() => remove(index)}>
													<p className='blue-color'>{translate('removeLicense')}</p>
												</span>
											</div>
										)}
									</div>
								);
							})}
							{props.formikProps.values.licenseHistory.length <= 4 && (
								<div className='flex full-width right-align-content personal-details cursor-pointer'>
									<span className='flex top-15' onClick={() => push({})}>
										<img src={`${healthCareCdnUrl}add-new.svg`} alt='add new icon' className='add-new' />
										<p className='blue-color'>{translate('addLicense')}</p>
									</span>
								</div>
							)}
						</>
					)}
				</FieldArray>
			</div>
		</div>
	);
};

export default Licenses;
