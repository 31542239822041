import React from 'react';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const HelloList = props => (
	<div>
		<div>
			<div className='patients-list-header sub-page-header'>
				<h2>{translate('myDevices')}</h2>
			</div>
		</div>
		<div className='chat-items-area scroll-hover'>
			{props.devices.map(device => (
				<div onClick={() => props.onDeviceClick(device)}>
					<div className={`chat-list-item flex ${device.id === props.selectedDevice.id ? 'active' : ''}`}>
						<div className='chat-list-img'>
							<img src={`${healthCareCdnUrl}hello-icon-gray.svg?v10`} alt='icon' />
						</div>
						<div className='chat-list-name header-search-name flex flex-1'>
							<p>{device.name}</p>
						</div>
						<div className='chat-list-status flex'>
							<span className={device.status ? 'is-on' : 'is-off'} />
						</div>
					</div>
				</div>
			))}
		</div>
	</div>
);

export default HelloList;
