import { addConfig, updateConfig } from 'api/eManager.js';
import { Alert, Modal } from 'components/index.js';
import { ECareConfigurationTypes } from 'constants/e-care-manager-types.js';
import { configurationTypeIds, groupTypes, PatientInfotainmentProviders } from 'constants/integrationEnums.js';
import CloudStorage from 'containers/Configurations/CloudStorage.jsx';
import EcareApiKey from 'containers/Configurations/EcareApiKey.jsx';
import EcareManagerAddEditBaseUrl from 'containers/Configurations/EcareManagerAddEditBaseUrl.jsx';
import EcareManagerEpicCerner from 'containers/Configurations/EcareManagerEpicCerner.jsx';
import TranslationServicesModal from 'containers/Configurations/TranslationServicesModal.jsx';
import HillRoomAddEdit from 'containers/Integrations/HillRoomAddEdit.jsx';
import PatientInfotainment from 'containers/Integrations/PatientInfotainment.jsx';
import PatientEducation from 'containers/Integrations/PatientEducation.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getCompanyId, getUserId, getUserInfo } from 'infrastructure/auth.js';
import { getConfigurationValue, getObjectById, isJSON } from 'infrastructure/helpers/commonHelpers.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { array, object, string, ValidationError } from 'yup';

const IntegrationsAddEdit = ({ itemToEdit, hasApiConfig, groupId, setIsRefetch, setIsAddOpen }) => {
	const intl = useIntl();
	const [selectedConfig, setSelectedConfig] = useState({ value: null, label: null, groupId: null });
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [fieldErrors, setFieldErrors] = useState(null);
	const [configParams, setConfigParams] = useState({
		nodeId: null,
		parentNodeId: null,
		nodeName: null,
		nodeLevel: null,
		configJson: '',
		configurationTypeId: null,
		validations: {},
		createdBy: '',
	});
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);

	const showApiKey = configId => configId !== configurationTypeIds.ECARE_API_KEY.id || itemToEdit || !hasApiConfig;

	const shouldShowConfigType = settingTypeId =>
		!settingTypeId || getConfigurationValue(companyConfigurations[settingTypeId]) || itemToEdit?.configurationTypeId;

	const getConfigTypes = () => {
		let result = [];
		Object.values(configurationTypeIds).forEach(item => {
			if (item.groupId !== groupId) {
				return;
			}
			if (shouldShowConfigType(item.featureFlagKey) && showApiKey(item.id)) {
				result.push({ value: item.id, label: item.description, groupId: item.groupId });
			}
		});
		return result;
	};

	useEffect(() => {
		const fetchConfigTypes = async () => {
			if (itemToEdit) {
				const foundItem = getConfigTypes().find(item => item.value === itemToEdit.configurationTypeId);
				if (foundItem) {
					setSelectedConfig(foundItem);
				}
			}
		};
		fetchConfigTypes();
	}, []);

	const handleYupErrors = error => {
		if (error instanceof ValidationError) {
			const fieldErrors = error.inner.reduce((acc, err) => {
				if (err.path) {
					acc[err.path] = err.message;
				}
				return acc;
			}, {});
			setFieldErrors(fieldErrors);
		}
		return { error: true };
	};

	const getUserSchema = () => {
		if (selectedConfig.groupId === groupTypes.PATIENT_INFOTAINMENT.id) {
			return getPatientInfotainmentSchema();
		}
		if (selectedConfig.groupId === groupTypes.CLOUD_STORAGE.id) {
			return getCloudStorageSchema();
		}
		const userSchema = {};
		const item = getObjectById(selectedConfig.value, configurationTypeIds);
		item.requiredFields.forEach(field => {
			userSchema[field] = string().required(intl.formatMessage({ id: 'requiredField' }));
		});
		// @ts-ignore
		return Object.keys(userSchema).length > 0 ? object(userSchema) : {};
	};

	const validate = async values => {
		const userSchema = getUserSchema();
		if (Object.keys(userSchema).length > 0) {
			try {
				const isValid = await userSchema?.validate(values, { abortEarly: false });
				return { error: !isValid };
			} catch (error) {
				return handleYupErrors(error);
			}
		}
		return { error: false };
	};

	const getParamsToSave = configJson => ({
		tenantId: getCompanyId(),
		configurationTypeId: configParams.configurationTypeId,
		nodeId: configParams.nodeId,
		parentNodeId: configParams.parentNodeId,
		nodeName: configParams.nodeName,
		nodeLevel: configParams.nodeLevel,
		configJson,
		userId: getUserId(),
	});

	const saveConfig = async () => {
		setIsLoading(true);
		const paramsToSend = getParamsToSave(
			JSON.stringify({ ...JSON.parse(configParams.configJson), createdBy: getUserInfo().email })
		);
		let response;
		if (itemToEdit) {
			paramsToSend.id = itemToEdit.id;
			response = await updateConfig(paramsToSend);
		} else {
			response = await addConfig(paramsToSend);
		}
		if (response.error) {
			setIsLoading(false);
			setError(response.error.message);
			return;
		}
		setIsLoading(false);
		setIsRefetch({ id: response.result.id });
		setIsAddOpen(false);
	};

	const onSubmit = async () => {
		if (!selectedConfig.value) {
			return;
		}
		setFieldErrors(null);
		if (!isJSON(configParams.configJson)) {
			return;
		}
		const dataToValidate = JSON.parse(configParams.configJson);
		const { error } = await validate(dataToValidate);
		if (error) {
			return;
		}
		saveConfig();
	};

	const getManualProviderSchema = () => {
		const urlRegex = /^(ftp|http|https):\/\/(www\.)?[^ "]+(\.[a-z]{2,})(\/[^ "]*)?$/i;
		const thumbnailRegex = /^(?:https?:\/\/)?(.*\.(?:jpg|jpeg|png|gif|bmp|webp))(?:\?.*)?$/i;
		const modifiedString = selectedConfig.label.substring(0, selectedConfig.label.length - 1).toLowerCase();
		const userSchema = {
			items: array()
				.min(1, `You must add at least one ${modifiedString}.`)
				.of(
					object().shape({
						link: object().shape({
							label: string(),
							value: string().required('Value is required').matches(urlRegex, 'Value must be a valid URL'),
						}),
						thumbnail: object().shape({
							label: string(),
							value: string()
								.required('Value is required')
								.matches(
									thumbnailRegex,
									`${intl.formatMessage({ id: 'supportedFormats' })} '.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'`
								),
						}),
						content: object().shape({
							label: string(),
							value: string().required(intl.formatMessage({ id: 'requiredField' })),
						}),
					})
				),
		};
		return object(userSchema);
	};

	const getCloudStorageSchema = () =>
		object({
			connectionString: string().required(intl.formatMessage({ id: 'requiredField' })),
			containerName: string().required(intl.formatMessage({ id: 'requiredField' })),
			scheduleLocation: object().shape({
				city: string(),
				province: string(),
				timezone: string(),
			}),
		});

	const getPatientInfotainmentSchema = () => {
		let userSchema = {};
		if (!isJSON(configParams.configJson)) {
			// @ts-ignore
			return object(userSchema);
		}
		const config = JSON.parse(configParams.configJson);
		if (PatientInfotainmentProviders.MANUAL === config.providerType) {
			return getManualProviderSchema();
		}
		if (PatientInfotainmentProviders.SWANK === config.providerType) {
			['userName', 'password', 'portalId', 'deviceId', 'licenceUrl'].forEach(field => {
				userSchema[field] = string().required(intl.formatMessage({ id: 'requiredField' }));
			});
		}
		if (PatientInfotainmentProviders.MAYO === config.providerType) {
			['clientId', 'clientSecret', 'portalId', 'licenceUrl'].forEach(field => {
				userSchema[field] = string().required(intl.formatMessage({ id: 'requiredField' }));
			});
		}
		// @ts-ignore
		return object(userSchema);
	};

	const isSubmitDisabled = () =>
		(configParams.validations && Object.keys(configParams.validations).length > 0) || !selectedConfig.value;

	return (
		<Modal
			modalSelector='baseUrlKeyModal'
			position='right'
			onModalSubmit={onSubmit}
			onModalClose={() => setIsAddOpen(prevState => !prevState)}
			shouldSubmitOnEnter={false}
			display={true}
			isSubmitDisabled={isSubmitDisabled()}
			isLoading={isLoading}>
			<form>
				<h3>{translate('addConfiguration')}</h3>
				<div className='input'>
					<p className='label'>{translate('selectConfiguration')}</p>
					<p className='font-14'>{translate('selectConfigurationDesc')}</p>
					<Select
						isDisabled={itemToEdit}
						value={selectedConfig}
						placeholder={intl.formatMessage({ id: 'selectConfiguration' })}
						classNamePrefix='react-select'
						options={getConfigTypes()}
						onChange={setSelectedConfig}
					/>
					{!configParams.configurationTypeId && <small>{translate('configurationTypeRequired')}</small>}
					{selectedConfig?.groupId === groupTypes.CLOUD_STORAGE.id && (
						<p className='font-14 gray-color'>{translate('selectCompanyDescription')}</p>
					)}
					<br />
					<br />
					{selectedConfig?.value === configurationTypeIds.ECARE_API_KEY.id && (
						<EcareApiKey itemToEdit={itemToEdit} setConfigParams={setConfigParams} fieldErrors={fieldErrors} />
					)}
					{selectedConfig?.value === configurationTypeIds.ECARE_BASE_URL.id && (
						<EcareManagerAddEditBaseUrl itemToEdit={itemToEdit} setConfigParams={setConfigParams} fieldErrors={fieldErrors} />
					)}
					{selectedConfig?.groupId === groupTypes.SMART_FHIR.id && (
						<EcareManagerEpicCerner
							itemToEdit={itemToEdit}
							setConfigParams={setConfigParams}
							type={selectedConfig.value}
							fieldErrors={fieldErrors}
						/>
					)}
					{selectedConfig?.groupId === groupTypes.NURSE_CALLING.id && (
						<HillRoomAddEdit itemToEdit={itemToEdit} setConfigParams={setConfigParams} fieldErrors={fieldErrors} />
					)}
					{selectedConfig?.groupId === ECareConfigurationTypes.PATIENT_INFOTAINMENT && (
						<PatientInfotainment
							itemToEdit={itemToEdit}
							setConfigParams={setConfigParams}
							configurationTypeId={selectedConfig.value}
							fieldErrors={fieldErrors}
						/>
					)}
					{selectedConfig?.groupId === groupTypes.TRANSLATION_SERVICES.id && (
						<TranslationServicesModal
							itemToEdit={itemToEdit}
							setConfigParams={setConfigParams}
							configurationTypeId={selectedConfig.value}
							fieldErrors={fieldErrors}
							setError={setError}
						/>
					)}
					{selectedConfig?.groupId === groupTypes.CLOUD_STORAGE.id && (
						<CloudStorage
							itemToEdit={itemToEdit}
							setConfigParams={setConfigParams}
							configurationTypeId={selectedConfig.value}
							fieldErrors={fieldErrors}
						/>
					)}
					{selectedConfig?.groupId === groupTypes.PATIENT_EDUCATION.id && (
						<PatientEducation
							itemToEdit={itemToEdit}
							setConfigParams={setConfigParams}
							configurationTypeId={selectedConfig.value}
							fieldErrors={fieldErrors}
							setError={setError}
						/>
					)}
				</div>
			</form>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</Modal>
	);
};

export default IntegrationsAddEdit;
