import * as React from 'react';

const OtherDataIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
		<path
			stroke='#247EF9'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M12 9.2v1.2m0 0v1.2m0-1.2h-1.2m1.2 0h1.2m-3.028 2.627a3.2 3.2 0 0 0 4.388-4.548m-4.388 4.548a3.2 3.2 0 1 1 4.388-4.548m-4.388 4.548c-.65.443-1.372.887-2.172 1.329C-5.876 6.702 3.837-1.654 8 3.6c3.077-3.884 9.187-.33 6.56 4.879'
		/>
	</svg>
);
export default OtherDataIcon;
