const UserRoles = {
	DOCTOR: 'Doctor',
	NURSE: 'Nurse',
	PATIENT: 'Patient',
	ADMIN: 'Admin',
	SUPER_USER: 'SuperUser',
	SUPER_ADMIN: 'SuperAdmin',
	OWNER: 'Owner',
	DIGITAL_CLINICIAN: 'DigitalClinician',
	VIRTUAL_SITTER: 'VirtualSitter',
	GUEST: 'Guest',
	VISITOR: 'Visitor',
};

export default UserRoles;
