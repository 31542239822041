import { Link } from 'react-router-dom';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import 'css/LandingPage/footer.scss';

const Footer = props => (
	<div className='footer-lp lp-container' id='about-us'>
		<div className='upper-part-lp'>
			<div className='logo-lp'>
				<Link to='/'>
					{props.isSubPage && <img src={`${healthCareCdnUrl}landing-page/logos/logo_white.svg`} alt='hellocare' />}
					{!props.isSubPage && <img src={`${healthCareCdnUrl}landing-page/logos/hellocare_bysolab.svg`} alt='hellocare' />}
				</Link>
				<p>
					hellocare powered by Solaborate is an End-to-End telehealth and virtual healthcare delivery platform comprising
					software, hardware and AI. hellocare platform provides solutions Hospitals, Home Care and Primary Care such as Virtual
					Patient Observation/eSitter, Telehealth, Remote Patient Monitoring (RPM), Remote Examination Kit, Telemedicine Carts,
					Alerting, Communications and Workflow Management and AI use cases for fall prevention and detections.
				</p>
			</div>
			<div className='links-lp'>
				<div className='about-us-lp'>
					<ul>
						<li>
							<p>
								<span className='semi-bold'>Solaborate Inc</span>
							</p>
							<p>
								<span className='semi-bold'>Address:</span> 2605 Enterprise RD E Suite 150 Clearwater, FL 33759{' '}
							</p>
							<p>
								<span className='semi-bold'>Phone Number:</span> +1 424-362-9965
							</p>
							<p>
								<span className='semi-bold'>Email:</span> support@solaborate.com
							</p>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div className='bottom-part-lp'>
			<h1>
				© <span className='bold'>hellocare</span> - Built by{' '}
				<a href='https://solaborate.com' target='_blank' rel='noopener noreferrer' className='blue-lp'>
					Solaborate
				</a>{' '}
				{new Date().getFullYear()} | All Rights Reserved
			</h1>
		</div>
	</div>
);

export default Footer;
