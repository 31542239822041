import { gatewayApi, gatewayFileUploadApi } from 'constants/global-variables.js';
import { getCompanyId, getUserId, getUserRoleId } from 'infrastructure/auth.js';

export const getDoctors = async ({ levelId, pageIndex, pageSize, search = '' }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/organizations/${getCompanyId()}/doctors?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}&levelId=${levelId}`
		);
		return data.doctors;
	} catch (error) {
		return { error };
	}
};

export const getMyDoctors = async ({ pageIndex = 0, pageSize = 20, searchText = '' } = {}) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1.1/healthcare/doctors/mine?pageIndex=${pageIndex}&pageSize=${pageSize}&searchText=${searchText}`
		);
		return data.doctors;
	} catch (error) {
		return { error };
	}
};

export const getDoctorProfile = async doctorId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/doctors/${doctorId}/profile`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDoctorAssigned = async (
	levelId,
	{ pageIndex = 0, pageSize = 20, search = '', specialtyId = '', presenceStatus = null } = {}
) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/organizations/${getCompanyId()}/levels/${levelId}/assigned-doctors?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}&currentRoleId=${getUserRoleId()}&specialtyId=${specialtyId}&presenceStatus=${presenceStatus}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getLanguages = async () => {
	try {
		const { data } = await gatewayApi.get('/v1/healthcare/languages');
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateDoctorProfile = async sendData => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/doctors/${getUserId()}/profile`, sendData);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getBlobSasPicture = async (fileName, container) => {
	try {
		const { data } = await gatewayApi.get(`/v1/azure-storage/container/${container}/file-name/${fileName}/blob-url`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const uploadStampOrSignatureImage = async formData => {
	try {
		const { data } = await gatewayFileUploadApi.post(`/v1/media/pictures/doctor-files`, formData);
		return data;
	} catch (error) {
		return { error };
	}
};

export const uploadStampAndSignature = async (doctorGuid, signature, stamp) => {
	try {
		const { data } = await gatewayApi.put(`v1/healthcare/doctors/${doctorGuid}/signature-stamp-picture`, {
			StampPicture: signature,
			SignaturePicture: stamp,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getImageToBase64 = async fileName => {
	try {
		const { data } = await gatewayApi.get(`/v1/azure-storage/container/doctor-files/file-name/${fileName}/blob-content`);
		return data.result;
	} catch (error) {
		return { error };
	}
};

export const getDoctorWorkflowAndNurses = async doctorId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${getCompanyId()}/doctors/${doctorId}/workflow-nurses`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCheckedInUsersByChannelType = async channelType => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/users/channel-types/${channelType}/checked-in`);
		return data.result.checkedInUsers;
	} catch (error) {
		return { error };
	}
};
