import { useEffect, useState } from 'react';
import { Modal, Button } from 'calls/components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { useConference } from 'calls/hooks/index.js';
import { ButtonType } from 'constants/enums.js';
import { useIntl } from 'react-intl';
import { languages } from 'constants/languageLine.js';
import { dialOutTranslator } from 'api/sipProvider.js';
import Select from 'react-select';

/**
 * @param {object} props
 * @param {(err) => void} props.setError
 * @param {(err) => void} props.onDismiss
 * @param {object} props.interpretationConfig
 */

const InviteLanguageLineView = ({ onDismiss, setError, interpretationConfig }) => {
	const conference = useConference();
	const [selectedLanguageLine, setSelectedLanguageLine] = useState({
		label: '',
		value: '',
		index: 999,
	});
	const intl = useIntl();

	useEffect(() => {
		const patientInfo = conference.additionalData.find(item => item.key === 'patientInfo');
		if (patientInfo) {
			const { preferredLanguage } = patientInfo.value;
			const foundLanguage = languages.find(
				item => preferredLanguage && item.label.toLowerCase().startsWith(preferredLanguage.toLowerCase())
			);
			if (!foundLanguage) {
				return;
			}
			setSelectedLanguageLine(foundLanguage);
		}
	}, []);

	const getLanguageLineNumber = () => {
		const defaultNumber = 'test-audio-siptestlls@sip.languageline.com';
		return interpretationConfig.authCode === defaultNumber
			? defaultNumber
			: selectedLanguageLine.value.replace('YOUR-AUTHCODE-HERE', interpretationConfig.authCode);
	};

	const removeParticipantOnNoAnswer = (participantId, message = '') => {
		setTimeout(() => {
			const participant = conference.participants.get(participantId);
			if (participant) {
				conference.removeParticipant(participantId);
				onDismiss(message);
			}
		}, 60000);
	};

	const onLanguageLineSelect = event => {
		const foundItem = languages.find(language => language.value === event.value);
		if (!foundItem) {
			return;
		}
		setSelectedLanguageLine(foundItem);
	};

	const inviteDialOutTranslator = async () => {
		if (interpretationConfig.authCode && !selectedLanguageLine.value) {
			return;
		}
		const numberToDial = getLanguageLineNumber();
		const response = await dialOutTranslator(numberToDial, conference.conferenceId);
		if (response.error) {
			setError(translate('phoneNotReachable'));
		} else {
			removeParticipantOnNoAnswer(response.participantId, intl.formatMessage({ id: 'unableToConnectInterpreter' }));
			onDismiss();
		}
	};

	return (
		<>
			<Modal.Content>
				<div className='translation-services'>
					<div className='language-label'>
						<p>{translate('language')}</p>
					</div>
					<div className='language-label-select'>
						<Select
							style={{ minWidth: '300px' }}
							value={selectedLanguageLine}
							placeholder='Select Language'
							classNamePrefix='custom-select'
							options={languages}
							components={<i className='material-icons-outlined'>arrow_drop_down</i>}
							onChange={onLanguageLineSelect}
						/>
					</div>
					<div className='interpreter-desc'>
						<p>{translate('languageLineDesc')}</p>
					</div>
				</div>
			</Modal.Content>
			<Modal.Actions>
				<Button
					type='submit'
					onClick={inviteDialOutTranslator}
					variant={ButtonType.SUBMIT}
					disabled={!selectedLanguageLine.value}>
					{translate('inviteInterpreter')}
				</Button>
			</Modal.Actions>
		</>
	);
};

export default InviteLanguageLineView;
