import * as React from 'react';

const SleepIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
		<path
			stroke='#BF5BF1'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M.8 7.848a7.352 7.352 0 0 0 14.4 2.1s-3.372 1.576-7.048-2.1C4.476 4.17 6.052.8 6.052.8A7.355 7.355 0 0 0 .8 7.848Z'
		/>
	</svg>
);
export default SleepIcon;
