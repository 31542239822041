import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';
import { MeasurementTypes } from 'constants/enums.js';

const Instructions = props => {
	const intl = useIntl();

	const instructions = [
		{
			id: 1,
			text: translate('toCheckConnectToDevice', {
				value: intl.formatMessage({
					id: props.selectedExamination?.isMeasureDevice ? 'toMeasure' : 'check',
				}),
				value2: intl.formatMessage({
					id: props.selectedExamination?.content ? `the${props.selectedExamination?.content}` : 'heartRate',
				}),
				value3: intl.formatMessage({
					id: props.selectedExamination?.device ? `the${props.selectedExamination?.device}` : 'pulseOximeter',
				}),
			}),
		},
		{
			id: 2,
			text: translate('confirmIotProperlyPlaced'),
		},
		props.selectedExamination?.type === MeasurementTypes.TEMPERATURE && {
			id: 3,
			text: translate('toStartMeasureTemperature'),
		},
	].filter(Boolean);

	return instructions.map(instruction => (
		<p className='instructions-wrapper'>
			<span className='examination-instructions'>{instruction.id}</span>
			{instruction.text}
		</p>
	));
};

export default Instructions;
