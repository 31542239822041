import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getNotifications = async (indexPage, pageSize) => {
	try {
		const { data } = await gatewayApi.get(`/v1.1/healthcare/notifications?pageIndex=${indexPage}&pageSize=${pageSize}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateUnreadNotifications = async read => {
	try {
		const { data } = await gatewayApi.put(`v1/healthcare/notifications/read`, read);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUserNotifications = async ({ userId, pageIndex = 0, pageSize = 10 }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/users/${userId}/notifications`, {
			params: {
				pageSize,
				pageIndex,
				appType: 4,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateUnreadMissedCallsNotifications = async (userId, notifications = []) => {
	try {
		const { data } = await gatewayApi.patch(`/v1/users/${userId}/notifications/status`, notifications);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getNurseAnsweredCalls = async ({ userId, healthSystemId, pageIndex = 0, pageSize = 10 }) => {
	try {
		const url = `/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/callstats/providers/${userId}/sessions/patients?page-size=${pageSize}&page-index=${pageIndex}`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};
