import { sendEmailDashboardData } from 'api/dashboard.js';
import { Button, CheckboxInput, Modal } from 'components/index.js';
import { DashboardContent } from 'constants/dashboard.js';
import translate from 'i18n-translations/translate.jsx';
import Download from 'icons/Dashboard/Download.jsx';
import { timezoneToUTCTimestamp } from 'infrastructure/helpers/dateHelper.js';
import { useState } from 'react';

const SelectDashboardContent = props => {
	const [selectedToDownload, setSelectedDownload] = useState(DashboardContent);
	const [isLoading, setIsLoading] = useState(false);

	const downloadCsv = async () => {
		setIsLoading(true);
		const response = await sendEmailDashboardData({
			start: timezoneToUTCTimestamp(props.from, props.selectedTimezone.zone),
			end: timezoneToUTCTimestamp(props.to, props.selectedTimezone.zone),
			healthSystemId: props.selectedHealthSystem,
			regionId: props.selectedHospital?.regionId,
			hospitalId: props.selectedHospital?.value,
			departmentId: props.selectedDepartment?.value,
			floorId: props.selectedFloor?.value,
			roomId: props.selectedRoom?.value,
			timezone: props.selectedTimezone?.zone,
			...props.additionalFilters,
			fields: getCheckedIds(),
		});

		if (!response.error) {
			props.setSuccess(true);
			setSelectedDownload(prevState => prevState.map(checkbox => ({ ...checkbox, checked: false })));
		} else {
			props.setError(response.error.message);
		}
		props.setShowDownloadReport(false);
		setIsLoading(false);
	};

	const getCheckedIds = () =>
		selectedToDownload.reduce((acc, item) => {
			if (item.checked) {
				return acc === '' ? item.id : `${acc}&fields=${item.id}`;
			}
			return acc;
		}, '');

	return (
		<Modal
			display={props.showDownloadReport}
			onModalClose={() => props.setShowDownloadReport(false)}
			onModalSubmit={downloadCsv}
			position='center'
			className='download-report'
			closeIcon='close'
			isSubmitDisabled={!selectedToDownload.some(item => item.checked)}
			primaryButtonLabel={
				!isLoading && (
					<>
						<Download color='#ffffff' />
						<span>{translate('downloadReport')}</span>
					</>
				)
			}
			primaryButtonLoading={isLoading}>
			<h3>{translate('downloadReport')}</h3>
			<div className='dr-actions'>
				<span>{translate('selectContentToDownload')}</span>
				<Button
					icon='remove'
					text={translate('deSelectAll')}
					onClick={() => setSelectedDownload(prevState => prevState.map(checkbox => ({ ...checkbox, checked: false })))}
				/>
				<Button
					icon='add'
					text={translate('selectAll')}
					onClick={() => setSelectedDownload(prevState => prevState.map(checkbox => ({ ...checkbox, checked: true })))}
				/>
			</div>
			<div className='checkboxes-list'>
				{selectedToDownload.map(item => (
					<CheckboxInput
						key={item.id}
						label={translate(item.label)}
						checked={item.checked}
						onChange={() =>
							setSelectedDownload(prevState =>
								prevState.map(checkbox => (checkbox.id === item.id ? { ...checkbox, checked: !checkbox.checked } : checkbox))
							)
						}
					/>
				))}
			</div>
		</Modal>
	);
};

export default SelectDashboardContent;
