const Temperature = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} viewBox='0 0 24 24' fill='none' {...props}>
		<path
			d='M14.5976 18.5328L13.8639 18.3769L14.5976 18.5328ZM15.6058 15.0682L15.1733 14.4555L15.6058 15.0682ZM15.2043 15.6773L15.938 15.8332L15.2043 15.6773ZM17.3775 12.9754L16.6313 12.8999L17.3775 12.9754ZM16.5411 14.408L16.1086 13.7953L16.5411 14.408ZM18.063 6.20135L17.3168 6.12585L18.063 6.20135ZM9.49836 4.75H16.0732V3.25H9.49836V4.75ZM17.3168 6.12585L16.6313 12.8999L18.1237 13.051L18.8092 6.27686L17.3168 6.12585ZM16.1086 13.7953L15.1733 14.4555L16.0383 15.6809L16.9736 15.0208L16.1086 13.7953ZM14.4707 15.5214L13.8639 18.3769L15.3312 18.6887L15.938 15.8332L14.4707 15.5214ZM11.7076 18.3769L11.1008 15.5214L9.63358 15.8332L10.2404 18.6887L11.7076 18.3769ZM10.3982 14.4555L9.46298 13.7953L8.59796 15.0208L9.53321 15.6809L10.3982 14.4555ZM8.94019 12.8999L8.25471 6.12584L6.76233 6.27686L7.44781 13.0509L8.94019 12.8999ZM9.46298 13.7953C9.16732 13.5866 8.97662 13.26 8.94019 12.8999L7.44781 13.0509C7.52796 13.8431 7.9475 14.5616 8.59796 15.0208L9.46298 13.7953ZM11.1008 15.5214C11.0092 15.0903 10.7583 14.7096 10.3982 14.4555L9.53321 15.6809C9.58465 15.7172 9.62049 15.7716 9.63358 15.8332L11.1008 15.5214ZM12.7858 19.25C12.2653 19.25 11.8158 18.886 11.7076 18.3769L10.2404 18.6887C10.4958 19.8905 11.557 20.75 12.7858 20.75V19.25ZM13.8639 18.3769C13.7558 18.886 13.3062 19.25 12.7858 19.25V20.75C14.0145 20.75 15.0758 19.8905 15.3312 18.6887L13.8639 18.3769ZM15.1733 14.4555C14.8132 14.7096 14.5623 15.0903 14.4707 15.5214L15.938 15.8332C15.951 15.7716 15.9869 15.7172 16.0383 15.6809L15.1733 14.4555ZM16.6313 12.8999C16.5949 13.26 16.4042 13.5866 16.1086 13.7953L16.9736 15.0208C17.624 14.5616 18.0436 13.8431 18.1237 13.051L16.6313 12.8999ZM16.0732 4.75C16.8133 4.75 17.3913 5.38949 17.3168 6.12585L18.8092 6.27686C18.9731 4.65688 17.7014 3.25 16.0732 3.25V4.75ZM9.49836 3.25C7.87011 3.25 6.59841 4.65688 6.76233 6.27686L8.25471 6.12584C8.1802 5.38949 8.75824 4.75 9.49836 4.75V3.25Z'
			fill='currentColor'
		/>
		<path d='M14.7858 7.5H17.7858' stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' />
		<path d='M15.2858 10H17.2858' stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' />
	</svg>
);
export default Temperature;
