import classNames from 'classnames';
import { useEffect } from 'react';

const ToastMessage = props => {
	useEffect(() => {
		let timer;

		if (props.timer && props.showToast) {
			timer = setTimeout(() => {
				if (props.onClose) {
					props.onClose();
				}
			}, props.timer);
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [props.timer, props.showToast, props.onClose]);

	if (!props.showToast) {
		return null;
	}

	return (
		<div className={classNames('toast-message flex cursor-pointer', props.className || '')}>
			{props.children}
			<i onClick={props.onClose} className='material-icons'>
				close
			</i>
		</div>
	);
};

export default ToastMessage;
