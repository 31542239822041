const SemiProgressBar = ({ progress, stroke, strokeWidth = 8 }) => {
	const radius = 65;
	const circumference = Math.PI * radius;
	const offset = (progress / 100) * circumference;

	return (
		<svg width='150' height='75'>
			<circle stroke={stroke} strokeWidth={strokeWidth - 1} cx='75' cy='75' r={radius} fill='transparent' />
			<circle
				strokeWidth={strokeWidth}
				stroke='var(--gray-2)'
				cx='75'
				cy='75'
				r={radius}
				fill='transparent'
				strokeDasharray={`${circumference} ${circumference}`}
				strokeDashoffset={offset}
			/>
		</svg>
	);
};

export default SemiProgressBar;
