import { UserAgentApplication } from 'msal';
import { Client } from '@microsoft/microsoft-graph-client';
import moment from 'moment';
import { RecurrenceType } from 'constants/enums.js';

class MicrosoftGraphService {
	constructor() {
		let outlookAppId = null;

		if (process.env.NODE_ENV === 'development') {
			outlookAppId = process.env.REACT_APP_OUTLOOKAPI_APP_ID;
		} else {
			outlookAppId = window.__env__.REACT_APP_OUTLOOKAPI_APP_ID;
		}

		this.scopes = ['User.Read', 'Calendars.Read', 'Calendars.ReadWrite'];
		this.userAgentApplication = new UserAgentApplication({
			auth: {
				clientId: outlookAppId,
			},
			cache: {
				cacheLocation: 'sessionStorage',
				storeAuthStateInCookie: true,
			},
		});
	}

	getAuthenticatedClient(accessToken) {
		const client = Client.init({
			authProvider: done => {
				done(null, accessToken.accessToken);
			},
		});

		return client;
	}

	async getUserDetails(accessToken) {
		const client = await this.getAuthenticatedClient(accessToken);

		const user = await client.api('/me').get();
		return user;
	}

	async getEvents(accessToken, startingDate, topVal, skipVal) {
		const client = await this.getAuthenticatedClient(accessToken);

		const events = await client
			.api(
				`/me/calendar/calendarView?startDateTime=${startingDate.toISOString()}&endDateTime=${new Date(
					startingDate.setFullYear(startingDate.getFullYear() + 1)
				).toISOString()}`
			)
			.select('subject,organizer,start,end,recurrence,location')
			.orderby('start/dateTime ASC')
			.top(topVal)
			.skip(skipVal)
			.get();

		return events;
	}

	async createEvent(event, accessToken) {
		try {
			const client = await this.getAuthenticatedClient(accessToken);

			const timeZone = moment.tz.guess();
			const recurrenceEndDate = moment(event.recurrenceEndDate).format('YYYY-MM-DD');
			let recurrence = null;

			if (event.hasRecurrence) {
				recurrence = this.getOutlookRecurrenceFormat(
					event.recurrence,
					moment(event.startDateTime).format('YYYY-MM-DD'),
					recurrenceEndDate
				);
			}

			const outlookEvent = {
				subject: event.topic,
				body: {
					contentType: 'HTML',
					content: event.description,
				},
				start: {
					dateTime: event.startDateTime,
					timeZone: timeZone,
				},
				end: {
					dateTime: event.endDateTime,
					timeZone: timeZone,
				},
				recurrence: recurrence,
				attendees: [
					{
						emailAddress: {
							address: event.invitedMemberEmail,
							name: event.invitedMemberEmail,
						},
						type: 'required',
					},
				],
			};

			const newEvent = await client.api('/me/events').post(outlookEvent);
			return newEvent;
		} catch (ex) {
			throw new Error(`Error: ${ex.message}`);
		}
	}

	getOutlookRecurrenceFormat = (recurrence, startDateTime, endDateTime) => {
		let recurrenceData = null;

		const range = {
			type: 'endDate',
			startDate: startDateTime,
			endDate: endDateTime,
		};
		switch (recurrence) {
			case RecurrenceType.DAILY:
				recurrenceData = {
					pattern: {
						type: 'daily',
						interval: 1,
					},
					range: range,
				};
				break;
			case RecurrenceType.WEEKLY:
				recurrenceData = {
					pattern: {
						type: 'weekly',
						interval: 1,
						daysOfWeek: [moment().format('dddd')],
					},
					range: range,
				};
				break;
			case RecurrenceType.MONTHLY:
				recurrenceData = {
					pattern: {
						type: 'absoluteMonthly',
						interval: 1,
						dayOfMonth: moment().format('D'),
					},
					range: range,
				};
				break;
			case RecurrenceType.YEARLY:
				recurrenceData = {
					pattern: {
						type: 'absoluteYearly',
						interval: 1,
						dayOfMonth: moment().format('D'),
						month: moment().format('M'),
					},
					range: range,
				};
				break;
			case RecurrenceType.WORKDAYS:
				recurrenceData = {
					pattern: {
						type: 'weekly',
						interval: 1,
						daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
					},
					range: range,
				};
				break;
			default:
				break;
		}

		return recurrenceData;
	};

	async login() {
		const loginresult = await this.userAgentApplication.loginPopup({
			scopes: this.scopes,
		});
		return loginresult;
	}

	logout() {
		try {
			this.clearMsalSessionStorage();
			return true;
		} catch (err) {
			return false;
		}
	}

	clearMsalSessionStorage() {
		const arr = [];

		for (let i = 0; i < sessionStorage.length; i += 1) {
			if (sessionStorage.key(i).substring(0, 4) === 'msal') {
				arr.push(sessionStorage.key(i));
			}
		}

		for (let i = 0; i < arr.length; i += 1) {
			sessionStorage.removeItem(arr[i]);
		}
	}

	async aquireTokenSilent() {
		let accessToken = null;
		try {
			accessToken = await this.userAgentApplication.acquireTokenSilent({
				scopes: this.scopes,
			});
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('Error:', error);
		}
		return accessToken;
	}

	async getAccount() {
		const returnVal = await this.userAgentApplication.getAccount();
		return returnVal;
	}
}

export default MicrosoftGraphService;
