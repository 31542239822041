import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Alert, Grid } from 'components';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import AddNewPatient from 'containers/CheckInAPatient/AddNewPatient.jsx';
import ViewPatientProfile from 'containers/CheckInAPatient/ViewPatientProfile.jsx';
import { getPatientObservations, getPatientConditions, getPatientProfile, searchPatient } from 'api/patients.js';
import { setHealthCareUserId } from 'infrastructure/auth.js';
import { capitalizeFirstLetter, findSectorById, getStorage } from 'infrastructure/helpers/commonHelpers.js';
import { updatePatientDeviceOwner } from 'api/devices.js';
import { getCountries } from 'api/users.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { AlertTypes, ObservationType, ConditionType } from 'constants/enums.js';
import Button from 'components/Button.jsx';

const SelectPatient = props => {
	const [searchedPatient, setSearchedPatient] = useState('');
	const [patientList, setPatientList] = useState([]);
	const [isAddPatientModalOpen, setIsAddPatientModalOpen] = useState(false);
	const [isViewProfileOpen, setIsViewProfileOpen] = useState(false);
	const [patientDetails, setPatientDetails] = useState(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [countries, setCountries] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [pageIndex, setPageIndex] = useState(0);
	const [tobaccoCode, setTobaccoCode] = useState(null);
	const [diabeticCode, setDiabeticCode] = useState(null);
	const helloDeviceId = parseInt(getStorage().getItem('helloDeviceId'), 10);
	const tree = useSelector(state => state.healthSystems.treeData.tree);
	const unitPreferences = useSelector(state => state.user.unitPreferences);

	const [alreadyOnCallMessage, setAlreadyOnCallMessage] = useState(null);
	const intl = useIntl();

	useEffect(() => {
		const keyPressHandler = async () => {
			if (searchedPatient.length < 2) {
				setPatientList([]);
			} else {
				const response = await searchPatient(searchedPatient, pageIndex);
				if (response.error) {
					setError(response.error.message);
					return;
				}
				setPatientList(prevState => prevState.concat(response.patients));
				setTotalCount(response.totalCount);
			}
		};
		const delayCall = setTimeout(() => {
			keyPressHandler();
		}, 500);

		return () => clearTimeout(delayCall);
	}, [searchedPatient, pageIndex]);

	const getPatientObservation = async patientId => {
		const response = await getPatientObservations(patientId, ObservationType.TOBACCO_SMOKE);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		if (response.observations.length > 0) {
			setTobaccoCode(response.observations[0].code);
		}
	};

	const getPatientCondition = async patientId => {
		const response = await getPatientConditions(patientId, ConditionType.DIABETES);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		if (response.conditions.length > 0) {
			setDiabeticCode(response.conditions[0].code);
		}
	};

	const getPatientDetails = async (patientId, isDirectSelect) => {
		setIsLoading(true);
		const [patientProfileDetails, countriesList] = await Promise.all([getPatientProfile(patientId), getCountries()]);
		const responseError = patientProfileDetails.error || countriesList.error;
		if (responseError) {
			setError(responseError.message);
			return;
		}
		setSelectedCountry(countriesList.countries.find(item => item.code === patientProfileDetails.patient.profile?.country)?.name);
		setCountries(countriesList.countries);
		props.setPatientUserId(patientProfileDetails.patient.userId);
		setPatientDetails(patientProfileDetails.patient);
		setHealthCareUserId(patientProfileDetails.patient.healthcareUserId);
		getPatientObservation(patientProfileDetails.patient.healthcareUserId);
		getPatientCondition(patientProfileDetails.patient.healthcareUserId);
		if (!isDirectSelect) {
			setIsViewProfileOpen(true);
		}
		setIsLoading(false);
		if (isDirectSelect) {
			selectPatient(patientProfileDetails.patient.userId, patientProfileDetails.patient.profile);
		}
	};

	const selectPatient = async (id, profile) => {
		const room = findSectorById(tree, helloDeviceId);
		if (room?.deviceBusy) {
			setAlreadyOnCallMessage(intl.formatMessage({ id: 'youAreAlreadyOnCall' }));
			return;
		}
		props.setIsSecondTabEnabled(false);
		const deviceId = getStorage().getItem('helloDeviceId');
		const params = {
			userId: id,
			deviceName: '',
			deviceId: parseInt(deviceId, 10),
		};
		const updateOwnerResponse = await updatePatientDeviceOwner(params);
		if (updateOwnerResponse.error || !updateOwnerResponse.hasSucceeded) {
			const errorMessage = updateOwnerResponse.error?.message || updateOwnerResponse?.message;
			setError(errorMessage);
			return;
		}
		props.setSelectedPatient(profile);
		props.setIsSecondTabEnabled(true);
	};

	const setAddPatientModalStatus = status => {
		setIsAddPatientModalOpen(status);
		props.setIsPatientFormVisible(status);
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		const hasReachedEnd = totalCount - patientList.length <= 0;
		if (isBottom && !hasReachedEnd) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const setSearchValue = event => {
		setPageIndex(0);
		setPatientList([]);
		setSearchedPatient(event.target.value);
	};

	return (
		<>
			<Grid columns='1fr' stretch='100%'>
				<div
					className={`check-in-patient-wrapper select-doctor-tabs-wrapper${
						props.isPatientFormVisible ? ' add-edit-patient-form-wrapper' : ''
					}`}>
					{!props.isPatientFormVisible && (
						<div className='full-page-input-wrapper select-doctor-wrapper'>
							<div className='flex flex-align-center column-direction'>
								<h3>{translate('selectPatient')}</h3>
								{props.selectedPatient && getStorage().getItem('patientId') && (
									<p>
										{`${intl.formatMessage({ id: 'selectedPatient' })}: ${props.selectedPatient.firstName} ${
											props.selectedPatient.lastName
										}`}
									</p>
								)}

								<p className='select-patient' />
								<div className='position-relative'>
									<img src={`${healthCareCdnUrl}search-icon.svg`} alt='ico' className='search-patient-icon' />
									<input
										type='search'
										placeholder={intl.formatMessage({ id: 'searchForPatients' })}
										onChange={setSearchValue}
										value={capitalizeFirstLetter(searchedPatient)}
									/> 
									<Button
										className='button left-s'
										onClick={() => setAddPatientModalStatus(true)}
										text={translate('addNewPatient')}
									/>
									{searchedPatient !== '' && patientList.length > 0 && (
										<div className='position-relative search-results' onScroll={handleScroll}>
											<p className='flex results'>{translate('results')}</p>
											{patientList.map(item => (
												<div className='flex view-profile-wrapper cursor-pointer' key={item.id}>
													<div onClick={() => getPatientDetails(item.patientId, true)} className='flex flex-1'>
														<ProfilePicture
															className='patient-request-details-profile'
															fullName={`${item.firstName} ${item.lastName}`}
															profilePicture={item.profilePicture || ''}
														/>
														<p className='results'>{`${item.firstName} ${item.lastName}`}</p>
													</div>
													<div className='flex view-profile-btn' onClick={() => getPatientDetails(item.patientId)}>
														<img src={`${healthCareCdnUrl}view-profile-icon.svg`} alt='ico' />
														<p className='view-profile'>{translate('viewProfile')}</p>
													</div>
												</div>
											))}
										</div>
									)}
									{searchedPatient.length < 2 && searchedPatient.length !== 0 && (
										<p className='results'>
											{translate('moreThanCharacters', {
												value: 2,
											})}
										</p>
									)}
									{searchedPatient.length > 1 && patientList.length === 0 && (
										<p className='results'>{translate('patientNotFound')}</p>
									)}
								</div>
							</div>
						</div>
					)}
					<AddNewPatient
						display={isAddPatientModalOpen}
						onModalClose={() => setAddPatientModalStatus(false)}
						onModalSubmit={() => setAddPatientModalStatus(false)}
						patientList={patientList}
						setIsSecondTabEnabled={props.setIsSecondTabEnabled}
						goToSymptoms={props.goToSymptoms}
						setPatientUserId={props.setPatientUserId}
						setSelectedPatient={val => props.setSelectedPatient(val)}
						hidePatientForm={() => setAddPatientModalStatus(false)}
						unitPreferences={unitPreferences}
					/>
					{!isLoading && (
						<ViewPatientProfile
							display={isViewProfileOpen}
							onModalClose={() => setIsViewProfileOpen(false)}
							onModalSubmit={() => setIsViewProfileOpen(false)}
							setIsSecondTabEnabled={props.setIsSecondTabEnabled}
							patientDetails={patientDetails?.profile}
							userId={patientDetails?.userId}
							setSelectedPatient={val => props.setSelectedPatient(val)}
							setIsPatientFormVisible={status => props.setIsPatientFormVisible(status)}
							selectedPatientId={patientDetails?.id}
							unitPreferences={unitPreferences}
							countries={countries}
							selectedCountry={selectedCountry}
							tobaccoCode={tobaccoCode}
							diabeticCode={diabeticCode}
						/>
					)}
				</div>
				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
				<PopUpAlert
					alertType={AlertTypes.DANGER}
					display={alreadyOnCallMessage}
					onAlertClose={() => setAlreadyOnCallMessage(null)}
					contentText={alreadyOnCallMessage}
					isSilent={true}
					center={true}
				/>
			</Grid>
		</>
	);
};
export default SelectPatient;
