import { gatewayApi, mobileApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';
import { buildParamsFromArray } from 'infrastructure/helpers/commonHelpers.js';

export const updateRoomSettings = async (roomId, roomSettings, isAi = false) => {
	try {
		const { data } = await gatewayApi.put(`/v1/organizations/${getCompanyId()}/rooms/${roomId}/settings`, {
			roomSettings,
			isAi,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateTeamSettings = async ({ teamId, teamSettings, isOverride, healthSystemId, isAi = false }) => {
	try {
		const { data } = await gatewayApi.put(`/v1/organizations/${getCompanyId()}/teams/${teamId}/settings`, {
			teamSettings,
			isOverride,
			healthSystemId,
			isAi,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getRoomSettings = async (roomId, settingsCategory) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${getCompanyId()}/rooms/${roomId}/settings?settingsCategory=${settingsCategory}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getTeamSettings = async ({ teamId, levelId, settingsCategory }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${getCompanyId()}/teams/${teamId}/settings?level=${levelId}&${buildParamsFromArray(
				'settingsCategory',
				settingsCategory
			)}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHealthSystemRoleConfigs = async (healthSystemId, roleId = null) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/role-settings${roleId ? `?roleId=${roleId}` : ''} `
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const setHealthSystemRoleConfigs = async (healthSystemId, roleSettings) => {
	try {
		const { data } = await gatewayApi.put(`/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/role-settings`, {
			roleSettings,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteHealthSystemRoleConfigs = async (healthSystemId, roleId, settingId) => {
	try {
		const { data } = await gatewayApi.delete(
			`/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/role-settings?roleId=${roleId}&settingTypeId=${settingId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getRoomsBySectorId = async (levelId, settingTypeId = null, value = null) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${getCompanyId()}/levels/${levelId}/rooms${settingTypeId ? `?settingTypeId=${settingTypeId}` : ''}${
				value ? `&value=${value}` : ''
			}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteUserLogins = async (userId, configurationId) => {
	try {
		const { data } = await mobileApi.delete(`v2.0/api/users/${userId}/logins?configurationId=${configurationId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateHelloPin = async ({ healthSystemId, regionId, hospitalId, dataToSend }) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/regions/${regionId}/hospitals/${hospitalId}`,
			{
				...dataToSend,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addMonitoringSettingOption = async (teamId, settingTypeId, option) => {
	try {
		const { data } = await gatewayApi.post(`v1/organizations/${getCompanyId()}/teams/${teamId}/settings/${settingTypeId}/options`, {
			option,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteMonitoringSettingOption = async (teamId, settingTypeId, optionId) => {
	try {
		const { data } = await gatewayApi.delete(
			`v1/organizations/${getCompanyId()}/teams/${teamId}/settings/${settingTypeId}/options/${optionId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getMonitoringSettingOptions = async (teamId, settingTypeId) => {
	try {
		const { data } = await gatewayApi.get(`v1/organizations/${getCompanyId()}/teams/${teamId}/settings/${settingTypeId}/options`);
		return data;
	} catch (error) {
		return { error };
	}
};
