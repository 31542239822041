import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/LandingPage/GeneralPages/Layout.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import { helloTwoImageUrl, helloTwoSpecsUrl, healthCareCdnUrl } from 'constants/global-variables.js';

const TechSpecs = () => {
	const [currentTab, setCurrentTab] = useState(0);

	const sliderTabs = [
		{
			id: 0,
			title: 'Front',
			imgSrc: `${helloTwoImageUrl}front-specs.png`,
		},
		{
			id: 1,
			title: 'Camera & Lens',
			imgSrc: `${helloTwoImageUrl}eye-specs.png`,
		},
		{
			id: 2,
			title: 'Back',
			description: `Select one of the doctors from the list generated based on your signs and symptoms.`,
			imgSrc: `${helloTwoImageUrl}back-specs.png`,
		},
	];

	const sliderContent = [
		{
			id: 0,
			title: 'Front',
			imgSrc: `${helloTwoImageUrl}front-image.png`,
		},
		{
			id: 1,
			title: 'Camera & Lens',
			imgSrc: `${helloTwoImageUrl}eye-image-min.png`,
		},
		{
			id: 2,
			title: 'Back',
			imgSrc: `${helloTwoImageUrl}back-image-min.png`,
		},
	];

	const specifications = [
		{
			title: 'General',
			options: [{ title: 'Designed by : Solaborate' }, { title: 'Limited Warrany: 1 Year' }, { title: 'Supported app OS' }],
		},
		{
			title: 'Video',
			options: [
				{
					title: 'Lens - F.2.2 FOV 112deg, 100+deg Horizontal- Multi aspherical glass/plastic elements',
				},
				{
					title: 'Lens - F.2.2 FOV 112deg, 100+deg Horizontal- Multi aspherical glass/plastic elements',
				},
				{
					title: 'IR Lights and night filters for night vision',
				},
				{
					title: 'Hardware based camera privacy switch',
				},
				{
					title: 'Digital pan, zoom',
				},
				{
					title: 'Digital and electro-mechanical tilting',
				},
			],
		},
		{
			title: 'Audio',
			options: [
				{
					title: 'Built-in smart mic array - 4x High sensitivity MEMS mic array',
				},
				{
					title: '16-core high powered dedicated voice processing DSP',
				},
				{
					title: '7+ meter voice pickup with VoiP and Smart Assistant automatic modes',
				},
				{
					title: 'Built-in supplementary speakers with 6W power',
				},
				{
					title: 'Hardware based microphone privacy switch',
				},
				{
					title: 'High signal-to-noise ratio (65 dB+) matched mics Beamforming, Noise Cancellation, AEC, AGC, EQ',
				},
			],
		},
		{
			title: 'Computing Unit',
			options: [
				{
					title: 'ARMv8 64-bit 6-core CPU (2x A72 + 4x A53 cores)',
				},
				{
					title: 'ARM Quad-Core Mali GPU',
				},
				{
					title: '4GB LPDDR3 RAM',
				},
				{
					title: '16GB EMMC Storage expandable via MicroSD XC',
				},
				{
					title: 'Integrated Image Signal Processor',
				},
				{
					title: 'HW Encryption/Decryption Engine',
				},
				{
					title: 'Supporting OpenGL ES1.1/2.0/3.0, OpenCL1.2, DirectX11.1',
				},
				{
					title: 'Video Encode - Real-time video encoder up to 1080p30fps H.264, MVC and VP8',
				},
				{
					title:
						'Video Decode - Real-time video decoder up to 2160p@60fps ( MPEG-1, MPEG-2, MPEG-4,H.263, H.264, H.265, VC-1,VP9, VP8, MVC)',
				},
			],
		},
		{
			title: 'Ports',
			src: 'ports-img.png',
			options: [
				{
					title: '1x USB 3.0 Type-C + DisplayPort',
				},
				{
					title: '1x USB 3.0 Type-A',
				},
				{
					title: '1x HDMI 2.0 4K60',
				},
				{
					title: '1x Etherent 1000-T',
				},
				{
					title: '1x DC power port 5V 2.5A',
				},
				{
					title: '1x MicroSD XC',
				},
			],
		},
		{
			title: 'Misc',
			options: [
				{
					title: 'Aluminum 6000 series housing',
				},
				{
					title: 'Electro-mechanical camera tilting (up/down, left/right) with software and touch control',
				},
				{
					title: '180 degree hinge with tripod mount',
				},
				{
					title: 'RGB defused ring light for mode notification',
				},
			],
		},
		{
			title: 'Networking & Sensors',
			options: [
				{
					title: 'Wifi 802.11ac 2.4Ghz / 5GHz. MIMO, dual antennas',
				},
				{
					title: 'Bluetooth 5.0 - BLE compatible',
				},
				{
					title: 'Ethernet 1000-T',
				},
				{
					title: 'G-Sensor',
				},
				{
					title: 'Light Sensor',
				},
			],
		},
		{
			title: 'In the box',
			src: 'inbox-img.png',
			options: [
				{
					title: 'AC Power Adapter',
				},
				{
					title: 'Input: 100-240 VAC, 0.8A, 50-60Hz',
				},
				{
					title: 'Output: 5VDC 3A worldwide plugin adapters',
				},
				{
					title: 'High Speed HDMI Cable',
				},
				{
					title: 'Remote control with air-mouse',
				},
				{
					title: 'Quick Start guide and documentation',
				},
				{
					title: 'HELLO Device',
				},
			],
		},
		{
			title: 'Size & Weight',
			src: 'size-weight-img.png',
			options: [
				{
					title: 'Height: 28-56mm',
				},
				{
					title: 'Length: 280mm',
				},
				{
					title: 'Depth: 55mm',
				},

				{
					title: 'Weight: 500gr',
				},
			],
		},
		{
			title: 'Available accessories',
			src: 'acc-img.png',
			options: [
				{
					title: 'HELLO Joystick',
				},
				{
					title: 'HELLO Button',
				},
				{
					title:
						'3rd party Bluetooth and USB accessories also supported (microphone, speakerphone, storage, keyboard, mouse, etc.)',
				},
			],
		},
	];

	return (
		<>
			<Helmet>
				<meta
					property='og:image'
					content={`${healthCareCdnUrl}meta/preview-images_hellocarehello_all.jpg?v2`}
				/>
				<meta
					name='twitter:image'
					content={`${healthCareCdnUrl}meta/preview-images_hellocarehello_twitter.jpg?v2`}
				/>
				<meta
					name='og:url'
					content={
						window.location.hostname === 'healthcare.solaborate.com'
							? 'https://healthcare.solaborate.com/hello/specs'
							: 'https://www.hellocare.ai/hello/specs'
					}
				/>
			</Helmet>

			<Layout className='landing-page' isSubPage={true}>
				<div className='hello-page-wrapper tech-specs-wrapper'>
					<Tabs activeIndex={currentTab} onChange={index => setCurrentTab(index)} className='technical-specifications'>
						<h1>Technical Specifications</h1>

						<TabList>
							{sliderTabs.map(item => (
								<Tab key={item.id}>
									<div>
										<img src={item.imgSrc} alt='icon' />
										<p>{item.title}</p>
									</div>
								</Tab>
							))}
						</TabList>
						<div className='animated-underline-wrapper'>
							<div className='animated-underline' style={{ width: `${(currentTab + 1) * 33.3333333333}%` }} />
						</div>

						<TabPanels>
							{sliderContent.map(item => (
								<TabPanel key={item.id}>
									<div className='lp-container'>
										<img src={item.imgSrc} alt='main-img' />
									</div>
								</TabPanel>
							))}
						</TabPanels>
					</Tabs>

					<div className='tech-specs-list lp-container'>
						{specifications.map(item => (
							<div className='flex'>
								<div>
									<h2>{item.title}</h2>
								</div>
								<ul>
									{item.options.map(sub => (
										<li>{sub.title}</li>
									))}
								</ul>
								{item.src && (
									<div>
										<img src={`${helloTwoSpecsUrl}${item.src}`} alt={item.title} />
									</div>
								)}
							</div>
						))}
					</div>
				</div>
			</Layout>
		</>
	);
};

export default TechSpecs;
