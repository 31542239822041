import React, { useState } from 'react';
import { deleteInactivePatientRpm } from 'api/rpm.js';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Alert.jsx';

const RemoveInactivePatient = ({ isDeleteModalShown, setIsDeleteModalShown, inactivePatient }) => {
	const [error, setError] = useState(null);
	const [isDeleting, setIsDeleting] = useState(false);

	const handleDeleteInactivePatient = async patientId => {
		setIsDeleting(true);
		const response = await deleteInactivePatientRpm(patientId);

		if (response.error) {
			setError(response.error);
		}
		setIsDeleteModalShown(false);
		setIsDeleting(false);
	};
	return (
		<>
			<Modal
				display={isDeleteModalShown}
				isLoading={isDeleting}
				primaryButtonLabel={translate('yes')}
				onModalClose={() => setIsDeleteModalShown(false)}
				onModalSubmit={() => handleDeleteInactivePatient(inactivePatient.patientId)}
				position='center'
				isSubmitDisabled={isDeleting}
				className='make-appointment-modal border-radius-modal-wrapper appoinment-next-arrow-modal discard rpm-modal-wrapper'>
				<Form height={200} className='create-appointment-form'>
					<h3>{translate('removePatient')}</h3>
					<p>{translate('areYouSureRemovePatient')}</p>
				</Form>
			</Modal>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default RemoveInactivePatient;
