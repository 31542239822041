import { localApi } from 'constants/global-variables.js';


export const sendDemoRequest = async params => {
	try {
		const { data } = await localApi.post(`/api/users/demo-request`, params);
		return data;
	} catch (error) {
		return { error };
	}
};
