const Outgoing = props => (
	<svg width='33' height='32' viewBox='0 0 33 32' fill='none' color='#6BC6B5' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M26.749 20.9699L24.5472 19.1908C23.8014 18.5859 22.736 18.55 21.9547 19.1196L19.8594 20.6496C19.5753 20.8631 19.185 20.8276 18.936 20.5785L15.4912 17.4473L12.3305 13.9602C12.0819 13.7112 12.0464 13.3198 12.2595 13.0351L13.7866 10.9358C14.3548 10.153 14.3193 9.08553 13.7155 8.33831L11.9399 6.13224C11.1586 5.13594 9.66699 5.06478 8.77915 5.95433L6.86141 7.87575C6.43525 8.30273 6.22217 8.90762 6.22217 9.47693C6.47076 13.9958 8.53055 18.3012 11.5492 21.3257C14.5679 24.3502 18.8295 26.4139 23.3752 26.663C23.979 26.6986 24.5472 26.4491 24.9734 26.0222L26.8911 24.1011C27.85 23.2471 27.7434 21.7527 26.749 20.9699Z'
			stroke='currentColor'
			strokeWidth='2'
		/>
		<path
			d='M29.374 9.81836V4.16151H23.7172'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M29.3741 4.16151L23.7173 9.81836' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
	</svg>
);
export default Outgoing;
