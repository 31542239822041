import { AnalysisSubTypes, TestTypes } from 'constants/visitEnums.js';

export const forms = (intl) => [
	{
		description: intl.formatMessage({ id: 'sediment' }),
		id: TestTypes.SEDIMENTATION,
		icon: 'blood.svg',
		results: [
			{ id: 1, description: intl.formatMessage({ id: 'erythrocyteSedimentationRate' }), unit: 'mm/h', min: 0, max: 40 },
		],
	},
	{
		description: intl.formatMessage({ id: 'hemogram' }),
		id: TestTypes.HEMOGRAM,
		icon: 'blood.svg',
		results: [
			{
				id: AnalysisSubTypes.ERYTHROCYTE_COUNT,
				description: intl.formatMessage({ id: 'erythrocyteCount' }),
				value: '',
				unit: 'x 10¹²/L',
				min: 0,
				max: 7,
			},
			{
				id: AnalysisSubTypes.HEMOGLOBIN,
				description: intl.formatMessage({ id: 'hemoglobin' }),
				value: '',
				unit: 'g/L',
				min: 0,
				max: 200,
			},
			{
				id: AnalysisSubTypes.LEUKOCYTE_COUNT,
				description: intl.formatMessage({ id: 'leukocyteCount' }),
				value: '',
				unit: 'x 10⁹/L',
				min: 0,
				max: 14,
			},
			{
				id: AnalysisSubTypes.MCV,
				description: 'MCV',
				value: '',
				unit: intl.formatMessage({ id: 'femtolitre' }),
				min: 0,
				max: 120,
			},
			{
				id: AnalysisSubTypes.MCH,
				description: 'MCH',
				value: '',
				unit: intl.formatMessage({ id: 'picograms' }),
				min: 0,
				max: 120,
			},
			{ id: AnalysisSubTypes.MCHC, description: 'MCHC', value: '', unit: 'g/L', min: 0, max: 400 },
			{
				id: AnalysisSubTypes.HEMATOCRIT,
				description: intl.formatMessage({ id: 'hematocrit' }),
				value: '',
				unit: 'l',
				min: 0,
				max: 100,
			},
		],
	},
	{
		description: intl.formatMessage({ id: 'glucose' }),
		id: TestTypes.GLUCOSE,
		icon: 'blood.svg',
		results: [
			{
				id: AnalysisSubTypes.GLUCOSE,
				description: intl.formatMessage({ id: 'glucose' }),
				value: '',
				unit: 'mmol/L',
				min: 0,
				max: 10,
			},
		],
	},
	{
		description: intl.formatMessage({ id: 'urea' }),
		id: TestTypes.UREA,
		icon: 'blood.svg',
		results: [
			{
				id: AnalysisSubTypes.UREA,
				description: intl.formatMessage({ id: 'urea' }),
				value: '',
				unit: 'mmol/L',
				min: 0,
				max: 10,
			},
		],
	},
	{
		description: intl.formatMessage({ id: 'creatinine' }),
		id: TestTypes.CREATININE,
		icon: 'blood.svg',
		results: [
			{
				id: AnalysisSubTypes.CREATININE,
				description: intl.formatMessage({ id: 'creatinine' }),
				value: '',
				unit: 'mmol/L',
				min: 0,
				max: 10,
			},
		],
	},
	{
		description: intl.formatMessage({ id: 'triglycerides' }),
		id: TestTypes.TRIGLYCERIDES,
		icon: 'blood.svg',
		results: [
			{
				id: AnalysisSubTypes.TRIGLYCERIDES,
				description: intl.formatMessage({ id: 'triglycerides' }),
				value: '',
				unit: 'mmol/L',
				min: 0,
				max: 10,
			},
		],
	},
	{
		description: intl.formatMessage({ id: 'cholesterol' }),
		id: TestTypes.CHOLESTEROL,
		icon: 'blood.svg',
		results: [
			{
				id: AnalysisSubTypes.CHOLESTEROL,
				description: intl.formatMessage({ id: 'cholesterol' }),
				value: '',
				unit: 'mmol/L',
				min: 0,
				max: 20,
			},
			{
				id: AnalysisSubTypes.LDL_CHOLESTEROL,
				description: intl.formatMessage({ id: 'ldlCholesterol' }),
				value: '',
				unit: 'mmol/L',
				min: 0,
				max: 20,
			},
			{
				id: AnalysisSubTypes.VLD_CHOLESTEROL,
				description: intl.formatMessage({ id: 'vldCholesterol' }),
				value: '',
				unit: 'mmol/L',
				min: 0,
				max: 20,
			},
			{
				id: AnalysisSubTypes.HDL_CHOLESTEROL,
				description: intl.formatMessage({ id: 'hdlCholesterol' }),
				value: '',
				unit: 'mmol/L',
				min: 0,
				max: 10,
			},
		],
	},
	{
		description: intl.formatMessage({ id: 'urine' }),
		id: TestTypes.URINE,
		icon: 'urine.svg',
		results: [
			{
				id: AnalysisSubTypes.VIEW,
				description: intl.formatMessage({ id: 'view' }),
				value: '',
				children: [
					{
						id: 0,
						description: intl.formatMessage({ id: 'clearUrine' }),
						value: intl.formatMessage({ id: 'clearUrine' }),
					},
					{ id: 1, description: intl.formatMessage({ id: 'turbid' }), value: intl.formatMessage({ id: 'turbid' }) },
				],
			},
			{
				id: AnalysisSubTypes.COLOR,
				description: intl.formatMessage({ id: 'color' }),
				value: '',
				children: [
					{
						id: 0,
						description: intl.formatMessage({ id: 'yellow' }),
						value: intl.formatMessage({ id: 'yellow' }),
					},
					{ id: 1, description: intl.formatMessage({ id: 'reddish' }), value: intl.formatMessage({ id: 'reddish' }) },
				],
			},
			{
				id: AnalysisSubTypes.REACTION,
				description: intl.formatMessage({ id: 'reaction' }),
				value: '',
				children: [{ id: 0, description: intl.formatMessage({ id: 'acidic' }), value: intl.formatMessage({ id: 'acidic' }) }],
			},
			{
				id: AnalysisSubTypes.SPECIFIC_WEIGHT,
				description: intl.formatMessage({ id: 'specificWeight' }),
				value: '',
				children: [
					{
						id: 0,
						description: '1020',
						value: '1020',
					},
					{ id: 1, description: '1024', value: '1024' },
				],
			},
			{
				id: AnalysisSubTypes.PROTEINS,
				description: intl.formatMessage({ id: 'proteins' }),
				value: '',
				children: [
					{
						id: 0,
						description: '1020',
						value: '1020',
					},
					{ id: 1, description: '1024', value: '1024' },
				],
			},
			{
				id: AnalysisSubTypes.URINE_GLUCOSE,
				description: intl.formatMessage({ id: 'glucose' }),
				value: '',
				children: [
					{
						id: 0,
						description: `(+) ${intl.formatMessage({ id: 'positive' })}`,
						value: `(+) ${intl.formatMessage({ id: 'positive' })}`,
					},
					{
						id: 1,
						description: `(-) ${intl.formatMessage({ id: 'negative' })}`,
						value: `(-) ${intl.formatMessage({ id: 'negative' })}`,
					},
					{
						id: 2,
						description: `(-) ${intl.formatMessage({ id: 'inTraces' })}`,
						value: `(-) ${intl.formatMessage({ id: 'inTraces' })}`,
					},
				],
			},

			{
				id: AnalysisSubTypes.BILIRUBIN,
				description: intl.formatMessage({ id: 'bilirubin' }),
				value: '',
				children: [
					{
						id: 0,
						description: `(+) ${intl.formatMessage({ id: 'positive' })}`,
						value: `(+) ${intl.formatMessage({ id: 'positive' })}`,
					},
					{
						id: 1,
						description: `(-) ${intl.formatMessage({ id: 'negative' })}`,
						value: `(-) ${intl.formatMessage({ id: 'negative' })}`,
					},
				],
			},
			{
				id: AnalysisSubTypes.UROBILINOGEN,
				description: intl.formatMessage({ id: 'urobilinogen' }),
				value: '',
				children: [
					{
						id: 0,
						description: `(+) ${intl.formatMessage({ id: 'positive' })}`,
						value: `(+) ${intl.formatMessage({ id: 'positive' })}`,
					},
					{
						id: 1,
						description: `(-) ${intl.formatMessage({ id: 'negative' })}`,
						value: `(-) ${intl.formatMessage({ id: 'negative' })}`,
					},
				],
			},
			{
				id: AnalysisSubTypes.NITRITES,
				description: intl.formatMessage({ id: 'nitrites' }),
				value: '',
				children: [
					{
						id: 0,
						description: `(+) ${intl.formatMessage({ id: 'positive' })}`,
						value: `(+) ${intl.formatMessage({ id: 'positive' })}`,
					},
					{
						id: 1,
						description: `(-) ${intl.formatMessage({ id: 'negative' })}`,
						value: `(-) ${intl.formatMessage({ id: 'negative' })}`,
					},
				],
			},
			{
				id: AnalysisSubTypes.ACETONE,
				description: intl.formatMessage({ id: 'acetone' }),
				value: '',
				children: [],
				isTextarea: true,
			},
			{
				id: AnalysisSubTypes.SEDIMENT,
				description: intl.formatMessage({ id: 'sediment' }),
				value: '',
				children: [],
				isTextarea: true,
			},
		],
	},
];


export const laboratoryTests = (intl) => [
	{ id: TestTypes.CHOLESTEROL, description: intl.formatMessage({ id: 'cholesterol' }) },
	{ id: TestTypes.CREATININE, description: intl.formatMessage({ id: 'creatinine' }) },
	{ id: TestTypes.GLUCOSE, description: intl.formatMessage({ id: 'glucose' }) },
	{ id: TestTypes.HEMOGRAM, description: intl.formatMessage({ id: 'hemogram' }) },
	{ id: TestTypes.SEDIMENTATION, description: intl.formatMessage({ id: 'sedimentation' }) },
	{ id: TestTypes.TRIGLYCERIDES, description: intl.formatMessage({ id: 'triglycerides' }) },
	{ id: TestTypes.UREA, description: intl.formatMessage({ id: 'urea' }) },
	{ id: TestTypes.URINE, description: intl.formatMessage({ id: 'urine' }) },
];

export const radiologyTests = (intl) => [
	{ id: TestTypes.PULMONARY, description: intl.formatMessage({ id: 'rtgPulmonary' }) },
	{ id: TestTypes.SKELETON, description: intl.formatMessage({ id: 'rtgSkeleton' }) },
	{ id: TestTypes.CHEST, description: intl.formatMessage({ id: 'rtgChest' }) },
	{ id: TestTypes.UPPER_EXTREMITIES, description: intl.formatMessage({ id: 'rtgUpperExtremities' }) },
	{ id: TestTypes.LOWER_EXTREMITIES, description: intl.formatMessage({ id: 'rtgLowerExtremities' }) },
	{ id: TestTypes.SPINE, description: intl.formatMessage({ id: 'rtgSpine' }) },
	{ id: TestTypes.ULTRASONOGRAPHY, description: intl.formatMessage({ id: 'ultrasonography' }) },
];