import * as React from 'react';

const Completed = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none' {...props}>
		<g stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5}>
			<path d='M19 10a9 9 0 1 0-18 0 9 9 0 0 0 18 0Z' />
			<path d='M14.366 8 9 13l-2.634-2.454' />
		</g>
	</svg>
);
export default Completed;
