const DurationAvg = props => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' color='#7892C3' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M24.5268 22.3039L22.3249 20.5248C21.5791 19.9199 20.5137 19.8839 19.7324 20.4536L17.6371 21.9836C17.353 22.1971 16.9627 22.1615 16.7138 21.9125L13.2689 18.7813L10.1082 15.2942C9.85963 15.0452 9.82411 14.6538 10.0372 14.3691L11.5643 12.2698C12.1325 11.487 12.097 10.4195 11.4933 9.6723L9.71757 7.46622C8.93627 6.46993 7.4447 6.39876 6.55686 7.28831L4.63912 9.20973C4.21296 9.63671 3.99988 10.2416 3.99988 10.8109C4.24847 15.3298 6.30826 19.6352 9.32692 22.6597C12.3456 25.6841 16.6072 27.7479 21.153 27.997C21.7567 28.0325 22.3249 27.7831 22.7511 27.3561L24.6688 25.4351C25.6277 24.5811 25.5211 23.0867 24.5268 22.3039Z'
			stroke='currentColor'
			strokeWidth='2'
		/>
		<path
			d='M11.3333 5.84624C13.0427 4.68113 15.1084 4 17.3333 4C23.2243 4 27.9999 8.77563 27.9999 14.6667C27.9999 16.8915 27.3188 18.9572 26.1537 20.6667'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
		/>
		<path
			d='M17.3333 11.333V15.9997H19.3333'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M17.9999 6.66699V7.33366' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		<path d='M25.3333 15.333H24.6666' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		<path
			d='M23.3333 9.33301L22.6666 9.99967'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
export default DurationAvg;
