import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getHealthSystemPatients, getPatientObservations, getPatientConditions, getPatientProfile } from 'api/patients.js';
import { getCountries } from 'api/users.js';
import Alert from 'components/Alert.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { capitalizeFirstLetter } from 'infrastructure/helpers/commonHelpers.js';
import { ObservationType } from 'constants/enums.js';
import { ConditionType } from 'constants/enums.js';
import Button from 'components/Button.jsx';
import classNames from 'classnames';

const SelectPatient = props => {
	const [pageIndex, setPageIndex] = useState(0);
	const [patientList, setPatientList] = useState([]);
	const [searchedPatient, setSearchedPatient] = useState('');
	const [totalCount, setTotalCount] = useState(0);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const intl = useIntl();
	const userSession = useSelector(state => state.user.userSession);

	const setSearchValue = event => {
		setPageIndex(0);
		setPatientList([]);
		setIsLoading(true);
		setSearchedPatient(event.target.value);
	};

	useEffect(() => {
		const keyPressHandler = async () => {
			if (searchedPatient.length < 2) {
				setPatientList([]);
			} else {
				const response = await getHealthSystemPatients({
					healthSystemId: userSession.healthSystem.id,
					search: searchedPatient,
					pageIndex: 0,
				});
				if (response.error) {
					setError(response.error.message);
					setIsLoading(false);
					return;
				}
				setPatientList(prevState =>
					prevState.concat(response.patients.filter(member => prevState.every(patient => patient.id !== member.id)))
				);
				setTotalCount(response.totalCount);
				setIsLoading(false);
			}
		};
		const delayCall = setTimeout(() => {
			keyPressHandler();
		}, 500);

		return () => clearTimeout(delayCall);
	}, [searchedPatient, pageIndex]);

	const getPatientObservation = async patientId => {
		const response = await getPatientObservations(patientId, ObservationType.TOBACCO_SMOKE);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		if (response.observations.length > 0) {
			props.setTobaccoCode(response.observations[0].code);
		}
	};

	const getPatientCondition = async patientId => {
		const response = await getPatientConditions(patientId, ConditionType.DIABETES);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		if (response.conditions.length > 0) {
			props.setDiabeticCode(response.conditions[0].code);
		}
	};

	const getPatientDetails = async (patientId, solDeviceId = null) => {
		const [patientProfileDetails, countriesList] = await Promise.all([getPatientProfile(patientId), getCountries()]);
		const responseError = patientProfileDetails.error || countriesList.error;
		if (responseError) {
			setError(responseError.message);
			return;
		}
		props.setUpdatedValues(null);
		props.setCountries(countriesList.countries);
		props.setPatientDetails(patientProfileDetails.patient?.profile);
		props.setPatientUserId(patientProfileDetails.patient?.healthcareUserId);
		props.setPatientId(patientProfileDetails.patient.id);
		getPatientObservation(patientProfileDetails.patient.healthcareUserId);
		getPatientCondition(patientProfileDetails.patient.healthcareUserId);
		props.setIsViewProfileOpen(true);
		props.setSolDeviceId(solDeviceId);
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		const hasReachedEnd = totalCount - patientList.length <= 0;
		if (isBottom && !hasReachedEnd) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	return (
		<>
			<div className='full-page-input-wrapper select-doctor-wrapper'>
				<div className='flex flex-align-center column-direction'>
					<h3>{translate('selectPatient')}</h3>

					<p className='select-patient' />
					<div className='position-relative'>
						<img src={`${healthCareCdnUrl}search-icon.svg`} alt='ico' className='search-patient-icon' />
						<input
							type='search'
							placeholder={intl.formatMessage({ id: 'searchByPatientNameMrn' })}
							onChange={setSearchValue}
							value={capitalizeFirstLetter(searchedPatient)}
						/>
						<Button
							className='button left-s'
							onClick={() => {
								props.setIsAddNewPatient(true);
								props.setUpdatedValues(null);
								props.setPatientDetails(null);
							}}
							text={translate('addNewPatient')}
						/>
						{searchedPatient !== '' && patientList.length > 0 && !isLoading && (
							<div className='position-relative search-results' onScroll={handleScroll}>
								<p className='flex results'>{translate('results')}</p>
								{patientList.map(item => (
									<div
										className='view-profile-wrapper cursor-pointer rpm'
										key={item.id}
										onClick={() => getPatientDetails(item.patientId, item.devicePath?.setHelloDeviceId)}>
										<div className='flex flex-1 flex-align-center'>
											<ProfilePicture
												className='patient-request-details-profile'
												fullName={`${item.firstName} ${item.lastName}`}
												profilePicture={item.profilePicture || ''}
											/>
											<div className='flex column-direction left-align-items'>
												<p className='text-align-left results rpm-search-results'>{`${item.firstName} ${item.lastName}`}</p>
												{item.devicePath && (
													<div>
														<p className='left-10'>{`${item.devicePath?.hospitalName} > ${item.devicePath?.departmentName} > ${item.devicePath?.floorName} > ${item.devicePath?.roomName}`}</p>
													</div>
												)}
											</div>
										</div>
										<div className={classNames('flex', item?.mrn ? 'flex-space-between' : 'right-align-content')}>
											{item?.mrn && (
												<div className='flex view-profile-btn'>
													<p className='rpm-search-results'>{item.mrn}</p>
												</div>
											)}
											<div className='flex view-profile-btn'>
												<img src={`${healthCareCdnUrl}view-profile-icon.svg`} alt='ico' />
												<p className='view-profile rpm-search-results'>{translate('viewProfile')}</p>
											</div>
										</div>
									</div>
								))}
							</div>
						)}
						{searchedPatient.length < 2 && searchedPatient.length !== 0 && (
							<p className='flex results'>
								{translate('moreThanCharacters', {
									value: 2,
								})}
							</p>
						)}
						{searchedPatient.length > 1 && patientList.length === 0 && !isLoading && (
							<p className='flex results'>{translate('patientNotFound')}</p>
						)}
					</div>
				</div>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default SelectPatient;
