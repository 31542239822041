import * as React from 'react';
const Default = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} fill='none' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M15.75 9a6.75 6.75 0 1 0-13.5 0 6.75 6.75 0 0 0 13.5 0Z'
		/>
	</svg>
);
export default Default;
