import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const deleteMemberCallAvailability = async () => {
	try {
		const url = `/v1.1/organizations/${getCompanyId()}/members/call-availability`;
		const { data } = await gatewayApi.delete(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addOrUpdateMemberCallAvailability = async rooms => {
	try {
		const url = `/v1.1/organizations/${getCompanyId()}/members/call-availability`;
		const { data } = await gatewayApi.post(url, { rooms });
		return data;
	} catch (error) {
		return { error };
	}
};

export const getNurseHealthSystemAvailabilities = async () => {
	try {
		const url = `/v1.1/organizations/${getCompanyId()}/members/call-availability`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};
