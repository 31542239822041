import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { UserRoles } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getPhysicalExerciseIcon } from 'infrastructure/helpers/physicalExerciseHelper';
import { monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import { deleteExerciseById, getExerciseFileType, getExerciseFileUrl, getGroupedExercisesByType } from 'api/physicalExercises';
import Alert from 'components/Alert.jsx';
import { downloadFileFromUrl, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import Button from 'components/Button.jsx';

const PTPrescriptionReport = props => {
	const [error, setError] = useState(null);
	const userRole = getUserRole();
	const intl = useIntl();
	const Status = {
		ON_GOING: 'On Going',
		EXPIRED: 'Expired',
		COMPLETED: 'Completed',
	};

	const getExerciseFile = async (exerciseSessionId, isVideo) => {
		const response = await getExerciseFileType(exerciseSessionId);
		if (response.error || !response.item) {
			setError(response.error ? response.error.message : intl.formatMessage({ id: 'invalidLink' }));
			return;
		}
		const file = isVideo ? response.item?.exerciseRepetitions.videoFile : response.item?.exerciseRepetitions.pdfFile;
		const url = await getExerciseFileUrl(file);
		if (url.error) {
			setError(url.error.message);
			return;
		}
		downloadFileFromUrl(url, 'video');
	};

	const deletePhysicalExercise = async exerciseId => {
		const response = await deleteExerciseById(exerciseId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		props.setPtPrescriptionReport(response.items);
		saveInitialData();
	};

	const saveInitialData = async () => {
		const response = await getGroupedExercisesByType(props.selectedPhysicalTherapyId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		if (response.items.length === 0) {
			props.setIsAdded(!props.isAdded);
			props.setSelectedView(props.viewToShow.REPORTS);
		}
	};

	const goBack = () => {
		props.setIsBackClicked(!props.isBackClicked);
		props.setSelectedView(props.viewToShow.REPORTS);
	};

	return (
		<>
			<div className='patient-summary-wrapper'>
				<span className='therapy-reports-back'>
					<i className='material-icons therapy-reports-back-btn' onClick={goBack}>
						keyboard_arrow_left
					</i>
					{translate('goBack')}
				</span>
				<div className='flex flex-space-between right-align-items'>
					<h3 className='physical-therapy-title'>{translate('pTPrescriptionReport')}</h3>
					{getUserRole() === UserRoles.DOCTOR && (
						<Button onClick={() => props.setSelectedView(props.viewToShow.ADD_EXERCISE)} text={translate('addExercise')} />
					)}
				</div>
				<div className='patient-summary-table-wrapper'>
					<table cellPadding='0' cellSpacing='0'>
						<thead>
							<tr>
								<th>{translate('exercise')}</th>
								<th>{translate('exerciseDate')}</th>
								<th>{translate('status')}</th>
								{userRole === UserRoles.DOCTOR && <th>{translate('file')}</th>}
							</tr>
						</thead>
						<tbody>
							{props.ptPrescriptionReport.map(exercise => (
								<tr key={exercise.exerciseSession}>
									<td>
										<div className='flex flex-align-center'>
											<img
												src={`${healthCareCdnUrl}physical-exercises/${getPhysicalExerciseIcon(exercise?.category?.type)}.svg?v2`}
												className='physical-icons'
												alt={exercise?.category?.name || 'physical-exercises-img'}
											/>
											<div>
												<p className='--dark-gray-color bold margin-bottom-0'>
													{intl.formatMessage({ id: stringToCamelCase(exercise?.category?.name) })}
												</p>
												{intl.formatMessage({ id: stringToCamelCase(exercise?.type?.name) })}
											</div>
										</div>
									</td>
									<td>{monthDayYear(exercise.date)}</td>
									<td>
										{userRole === UserRoles.DOCTOR && (
											<div className='flex'>
												{exercise.status === Status.ON_GOING && (
													<Button
														onClick={() => props.setEditExerciseView(exercise)}
														className='prescription-report-btn edit flex flex-justify-center flex-align-center'
														text={translate('edit')}
													/>
												)}
												{exercise.status === Status.EXPIRED && (
													<Button
														className='prescription-report-btn close flex flex-justify-center flex-align-center'
														text={translate('closed')}
													/>
												)}
												{exercise.status === Status.COMPLETED && (
													<Button
														onClick={() => getExerciseFile(exercise.exerciseSession)}
														className='prescription-report-btn generate-pdf flex flex-justify-center flex-align-center'
														text={translate('downloadReport')}
													/>
												)}

												<Button
													onClick={_.debounce(() => deletePhysicalExercise(exercise.id), 500)}
													className='generate-pdf'
													text={translate('delete')}
												/>
											</div>
										)}
										{userRole === UserRoles.PATIENT && (
											<>
												{exercise.status === Status.ON_GOING && (
													<Button
														className='prescription-report-btn edit flex-justify-center flex-align-center'
														text={translate('pending')}
													/>
												)}
												{exercise.status === Status.EXPIRED && (
													<Button
														className='prescription-report-btn close flex flex-justify-center flex-align-center'
														text={translate('closed')}
													/>
												)}
												{exercise.status === Status.COMPLETED && (
													<Button
														className='prescription-report-btn generate-pdf flex flex-justify-center flex-align-center'
														text={translate('completed')}
													/>
												)}
											</>
										)}
									</td>
									{userRole === UserRoles.DOCTOR && exercise.status === Status.COMPLETED && (
										<td>
											<Button
												className='prescription-report-btn generate-pdf flex flex-justify-center flex-align-center'
												onClick={() => getExerciseFile(exercise.exerciseSession, true)}
												text={translate('video')}
											/>
										</td>
									)}
									{exercise.status !== Status.COMPLETED && <td />}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};
export default PTPrescriptionReport;
