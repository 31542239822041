import classNames from 'classnames';

const RadioButton = props => (
	<label
		key={props.name}
		htmlFor={props.id}
		className={classNames(
			'radio-label',
			props.checked ? 'radio-checked' : '',
			props.disabled ? 'disabled' : '',
			props.className
		)}>
		<input
			className='radio-input'
			type='radio'
			name={props.name}
			id={props.id}
			value={props.value}
			onChange={props.onChange}
			checked={props.checked}
		/>
		<span className='custom-radio' />
		<p className={classNames('radio-text', props.checked ? 'radio-checked' : '')}>{props.text}</p>
	</label>
);

export default RadioButton;
