import React, { useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Dropzone from 'react-dropzone';
import Cropper from 'react-easy-crop';
import classNames from 'classnames';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { Alert, Avatar, Button, Modal } from 'components/index.js';
import { getBlobSasPicture, uploadStampOrSignatureImage } from 'api/doctors.js';

const StampAndSignature = props => {
	const [uploadedImg, setUploadedImg] = useState(null);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [isImageCropperVisible, setIsImageCropperVisible] = useState(false);
	const [croppedImage, setCroppedImage] = useState('');
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [error, setError] = useState(null);

	useEffect(() => {
		return () => {
			URL.revokeObjectURL(uploadedImg?.preview);
		};
	}, [uploadedImg]);

	const onImageUpload = async uploadedFile => {
		const photo = uploadedFile[0];
		const sizeInMB = photo.size / 1024 / 1024;
		if (sizeInMB > 2) {
			setError(translate('maxSizeExceeded'));
			return;
		}
		photo.preview = URL.createObjectURL(photo);
		toggleImageCropper(photo.preview);
		setUploadedImg(photo);
		setError(null);
	};

	const toggleImageCropper = croppedImg => {
		setIsImageCropperVisible(prevState => !prevState);
		setCroppedImage(croppedImg || '');
		setZoom(1);
		setCrop({ x: 0, y: 0 });
	};

	const uploadCroppedImage = async () => {
		const formData = new FormData();
		formData.append('File', uploadedImg);
		formData.append('CropWidth', croppedAreaPixels ? croppedAreaPixels.width : '0');
		formData.append('CropHeight', croppedAreaPixels ? croppedAreaPixels.height : '0');
		formData.append('CropOffsetX', croppedAreaPixels ? croppedAreaPixels.x : '0');
		formData.append('CropOffsetY', croppedAreaPixels ? croppedAreaPixels.y : '0');
		const response = await uploadStampOrSignatureImage(formData);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setIsImageCropperVisible(false);
		const blobStamp = await getBlobSasPicture(response.fileName, 'doctor-files');

		if (blobStamp.error || blobStamp.errorResponse) {
			setError(blobStamp.error || blobStamp.errorResponse.message);
		} else {
			props.setStamp(blobStamp.result.uri.uri);
		}
	};

	return (
		<div className='stamp-signature'>
			<div className='signature-wrapper position-relative'>
				<label className='signature-wrapper-label'>
					{translate('doctorsSignature')}
					<SignatureCanvas
						penColor='rgba(36, 126, 249, 1)'
						canvasProps={{ className: 'signature-canvas' }}
						ref={ref => props.setSignatureRef(ref)}
					/>
					<span>{translate('signatureDescription')}</span>
				</label>
				<Button
					onClick={props.clearSignature}
					imgIcon={`${healthCareCdnUrl}general-settings/undo.svg`}
					alt='icon'
					text={translate('clear')}
				/>
			</div>
			<label className='current-signature'>
				<>
					{translate('currentSignature')}
					<div className='flex signature-info-image flex-align-center'>
						<div>
							{!props.currentSignature && <Avatar src={null} />}
							{props.currentSignature && <img src={props.currentSignature} alt='signature' />}
						</div>
					</div>
				</>
			</label>

			<div className='top-30'>
				<h5>{translate('doctorsStamp')}</h5>
				<span className='stamp-description'>{translate('stampDescription')}</span>
				<div className='flex profile-info-image flex-align-center'>
					<div>
						{!props.stamp && <Avatar src={null} />}
						{props.stamp && <img src={props.stamp} className={classNames('avatar', 50, 'border-radius-50')} alt='avatar-ing' />}
					</div>
					<Dropzone accept='image/jpeg, image/jpg, image/png' onDrop={onImageUpload}>
						{({ getRootProps, getInputProps }) => (
							<div style={{ textAlign: 'center' }} {...getRootProps()}>
								<input {...getInputProps()} /> 
								<Button
									className='attestations-inputs-button'
									imgIcon={`${healthCareCdnUrl}edit-photo-icon.svg`}
									text={translate('updatePicture')}
								/>
							</div>
						)}
					</Dropzone>
					<Modal
						display={isImageCropperVisible}
						onModalClose={toggleImageCropper}
						onModalSubmit={uploadCroppedImage}
						className='image-cropper-modal'
						primaryButtonLabel={translate('upload')}>
						<Cropper
							image={croppedImage}
							crop={crop}
							zoom={zoom}
							aspect={1}
							onCropChange={cropValue => setCrop(cropValue)}
							onCropComplete={(croppedArea, croppedPixels) => setCroppedAreaPixels(croppedPixels)}
							onZoomChange={zoomValue => setZoom(zoomValue)}
						/>
					</Modal>
				</div>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default StampAndSignature;
