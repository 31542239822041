import * as React from 'react';

const AiDrawingIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={14} height={17} fill='none' {...props}>
		<g stroke='currentColor'>
			<path fill='#fff' strokeMiterlimit={10} d='M7 2.333A.833.833 0 1 0 7 .666a.833.833 0 0 0 0 1.667Z' />
			<path strokeLinecap='round' d='M1.166 4.832h11.667M4.916 4.832v5.833h4.167V4.832M4.916 10.666v5.833m4.167-5.833v5.833' />
			<path
				fill='currentColor'
				strokeMiterlimit={10}
				d='M4.917 5.25a.417.417 0 1 0 0-.834.417.417 0 0 0 0 .833ZM9.083 5.25a.417.417 0 1 0 0-.834.417.417 0 0 0 0 .833ZM9.083 11.083a.417.417 0 1 0 0-.833.417.417 0 0 0 0 .833ZM4.917 11.083a.417.417 0 1 0 0-.833.417.417 0 0 0 0 .833Z'
			/>
		</g>
	</svg>
);
export default AiDrawingIcon;
