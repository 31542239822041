import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { getRpmPrograms, updatePatientRpmProgram } from 'api/rpm.js';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Alert.jsx';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import _ from 'lodash';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import RpmProgramDetails from 'containers/Rpm/RpmProgramDetails.jsx';
import { useSelector } from 'react-redux';

const EditPatientRpmProgram = ({ patientRpmProgram, toggleRpmProgram, shouldShowRpmProgram, selectedPatient }) => {
	const intl = useIntl();
	const [error, setError] = useState(null);
	const [selectedRpmProgram, setSelectedRpmProgram] = useState(null);
	const [rpmPrograms, setRpmPrograms] = useState([]);
	const [pageIndex, setPageIndex] = useState(0);
	const hasRpmReachedEnd = useRef(null);
	const [searchedRpmProgram, setSearchedRpmProgram] = useState('');
	const [isRpmListShown, setIsRpmListShown] = useState(false);
	const [hasSelectedOption, setHasSelectedOption] = useState(true);
	const [isRequestPending, setIsRequestPending] = useState(false);
	const rpmListRef = useRef(null);
	const userSession = useSelector(state => state.user.userSession);

	const updateRpmProgram = async (patientId, rpmProgramId) => {
		if (patientRpmProgram?.value !== selectedRpmProgram?.value) {
			setError(null);
			setIsRequestPending(true);
			const response = await updatePatientRpmProgram({ patientId, rpmProgramId });
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setIsRequestPending(false);
		}
		toggleRpmProgram(null);
	};

	useEffect(() => {
		if (selectedPatient?.rpmProgram) {
			setSelectedRpmProgram({ value: selectedPatient.rpmProgram?.id, label: selectedPatient.rpmProgram?.name });
		}
	}, [selectedPatient]);

	useEffect(() => {
		const getRpmProgramList = async () => {
			const response = await getRpmPrograms({ pageIndex, healthSystemId: userSession.healthSystem.id });
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setRpmPrograms(prevState => prevState.concat(response.rpmPrograms));
			hasRpmReachedEnd.current = response.rpmPrograms.length < 10;
		};
		getRpmProgramList();
	}, [pageIndex, userSession.healthSystem.id]);

	const handleRpmProgramScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasRpmReachedEnd.current) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const getFilteredRpm = rpmProgram => {
		return rpmProgram.name?.toLowerCase().includes(searchedRpmProgram.toLowerCase());
	};

	const getFilteredRpmPrograms = () => {
		const rpmProgramsList = _.cloneDeep(rpmPrograms);
		let filteredList = rpmProgramsList;
		if (searchedRpmProgram) {
			filteredList = rpmProgramsList.filter(item => getFilteredRpm(item));
		}
		return filteredList;
	};

	useOutsideClick(rpmListRef, () => {
		setSearchedRpmProgram('');
		setIsRpmListShown(false);
	});

	const getValue = () => {
		if (!hasSelectedOption) {
			return searchedRpmProgram;
		}
		return selectedRpmProgram?.label;
	};

	const onRpmProgramClick = item => {
		setSelectedRpmProgram({ value: item.id, label: item.name });
		setHasSelectedOption(true);
		setSearchedRpmProgram(item.name);
		setIsRpmListShown(false);
	};

	const handleChange = event => {
		setHasSelectedOption(false);
		setSearchedRpmProgram(event.target.value);
	};

	const handleClick = () => {
		setIsRpmListShown(prevState => !prevState);
		setSearchedRpmProgram('');
	};

	return (
		<Modal
			display={shouldShowRpmProgram}
			isLoading={false}
			position='center'
			className='make-appointment-modal border-radius-modal-wrapper appoinment-next-arrow-modal discard edit-rpm-modal rpm-modal-wrapper change-rpm-modal'
			onModalClose={() => toggleRpmProgram(null)}
			onModalSubmit={() => updateRpmProgram(selectedPatient.patientId, selectedRpmProgram.value)}
			isSubmitDisabled={!selectedRpmProgram || (isRequestPending && !error)}
			primaryButtonLabel={intl.formatMessage({ id: 'confirm' })}>
			<Form height={650} className='create-appointment-form modal-form-wrapper today-medication-form'>
				<div className='bottom-30'>
					<h3>{translate('changeRpmProgram')}</h3>
					<p className='no-margin'>{translate('rpmProgram')}</p>
					<div ref={rpmListRef}>
						<input
							className='rpm-list-input'
							type='search'
							placeholder={intl.formatMessage({ id: 'selectProgram' })}
							onChange={handleChange}
							value={getValue()}
							onClick={handleClick}
						/>
						{isRpmListShown && (
							<div className='position-relative search-results search-rpm-program' onScroll={handleRpmProgramScroll}>
								{getFilteredRpmPrograms().map(item => (
									<div className='flex view-profile-wrapper cursor-pointer' key={item.id} onClick={() => onRpmProgramClick(item)}>
										<div className='flex flex-1'>
											<p className='results'>{item.name}</p>
										</div>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
				<RpmProgramDetails selectedRpmProgram={selectedRpmProgram} isFromEditPatientRpm={true} />
			</Form>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</Modal>
	);
};

export default EditPatientRpmProgram;
