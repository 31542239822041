import { useContext, useEffect, useState } from 'react';
import { Modal, Button, Avatar, Checkbox } from 'calls/components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { useConference, useConferenceConfigurations, useLocalParticipant } from 'calls/hooks/index.js';
import { ButtonType, VisitorType } from 'constants/enums.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import { getRoomMembers } from 'api/organization.js';
import { useSelector } from 'react-redux';
import { APP_CONFIG } from 'constants/global-variables.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { getFailedInvitationMessage } from 'infrastructure/helpers/commonHelpers.js';
import styled, { css } from 'styled-components';
import { enums } from '@solaborate/calls';

const StyledRoomMembers = styled.div`
	display: ${props => (props.$isVisible ? 'block' : 'none')};

	> div {
		display: flex;
		align-items: center;
		margin-bottom: 12px;

		&:last-of-type {
			margin: 0;
		}
	}

	aside {
		display: flex;

		> div {
			&:first-of-type {
				margin-right: ${LightTheme.spacing[2]}px;
			}
		}

		p {
			margin: 0;
			padding: 0;
			color: ${LightTheme.colors.grayFive};
			font-size: 14px;

			+ p {
				color: ${LightTheme.colors.grayFour};
			}
		}

		~ div {
			margin-left: auto;
		}

		[size='32'] {
			font-size: 10px;
			font-weight: 500;
		}
	}

	${props =>
		props.$isDarkMode &&
		css`
			aside p {
				color: ${DarkTheme.colors.grayFive};
			}

			aside p + p {
				color: ${DarkTheme.colors.grayFour};
			}
		`}
`;

/**
 * @param {object} props
 * @param {(err) => void} props.onDismiss
 * @param {string} props.roomId
 */
const InviteFamilyAndFriends = ({ onDismiss, roomId }) => {
	const conference = useConference();
	const [participants, setParticipants] = useState([]);
	const [roomMembers, setRoomMembers] = useState([]);
	const [failedInvites, setFailedInvites] = useState([]);
	const userSession = useSelector(state => state.user.userSession);
	const socket = useContext(SocketContext);
	const localParticipant = useLocalParticipant();
	const conferenceConfigs = useConferenceConfigurations();

	useEffect(() => {
		if (roomId) {
			const fetchRoomMembers = async () => {
				const room = await getRoomMembers(userSession.healthSystem.id, roomId);
				if (room.error) {
					return;
				}
				setRoomMembers(room.roomMembers);
			};
			fetchRoomMembers();
		}
	}, [roomId, userSession.healthSystem.id]);

	useEffect(() => {
		if (failedInvites) {
			conferenceConfigs.setConferenceErrorMessages(
				failedInvites.map(failedParticipant => ({
					id: failedParticipant.userId,
					message: getFailedInvitationMessage(failedParticipant),
				}))
			);
		}
	}, [failedInvites]);

	const onSubmit = async () => {
		if (!participants?.length) {
			return;
		}
		const response = await conference.inviteParticipants({
			participants,
			emailList: [],
			phoneNumberList: [],
			siteName: APP_CONFIG.URL.localApiBasePath,
			message: '',
			healthSystemName: userSession.healthSystem.name,
		});
		if (response.failedInvitationToParticipants) {
			const failedUsers = [];
			response.failedInvitationToParticipants.forEach(f => {
				const visitor = roomMembers.find(member => member.userIntId === f.objectId);
				if (visitor) {
					failedUsers.push({ id: visitor.userId, objectId: visitor.userIntId, name: visitor.name, reason: f.reason });
				}
			});
			setFailedInvites(failedUsers);
		}
		const invitedParticipantsResponse = await socket.emitWithAck(SocketEvents.Conference.GET_INVITATION_LINKS, {
			conferenceId: conference.conferenceId,
			participantId: localParticipant.id,
		});
		if (invitedParticipantsResponse) {
			conferenceConfigs.setInvitedParticipants(invitedParticipantsResponse);
		}
		onDismiss();
	};

	const toggleParticipants = userIntId => {
		let newParticipants;
		const participantExists = participants.find(p => p.objectId === userIntId);
		if (participantExists) {
			newParticipants = participants.filter(p => p.objectId !== userIntId);
		} else {
			newParticipants = [...participants, { objectId: userIntId, objectType: enums.ObjectTypes.USER }];
		}
		setParticipants(newParticipants);
	};

	const getRoleName = visitorTypeId => translate(visitorTypeId === VisitorType.FAMILY_MEMBER ? 'familyMember' : 'visitor');

	return (
		<>
			<Modal.Content>
				<StyledRoomMembers $isDarkMode={conferenceConfigs.isDarkMode} $isVisible={roomId}>
					{roomMembers.map(({ name, visitorTypeId, userIntId }) => (
						<div key={userIntId}>
							<aside>
								<Avatar size={32} src={null} name={name} />
								<div>
									<p>{name}</p>
									<p>{getRoleName(visitorTypeId)}</p>
								</div>
							</aside>
							<Checkbox onChange={() => toggleParticipants(userIntId)} />
						</div>
					))}
				</StyledRoomMembers>
			</Modal.Content>
			<Modal.Actions>
				<Button type='submit' onClick={onSubmit} variant={ButtonType.SUBMIT}>
					{translate('sendInvite')}
				</Button>
			</Modal.Actions>
		</>
	);
};

export default InviteFamilyAndFriends;
