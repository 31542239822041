import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const List = props => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			color={LightTheme.colors.grayZero}
			{...props}>
			<line x1='10.5' y1='5.5' x2='20.5' y2='5.5' stroke='currentColor' strokeLinecap='round' />
			<line x1='10.5' y1='12.5' x2='20.5' y2='12.5' stroke='currentColor' strokeLinecap='round' />
			<line x1='10.5' y1='19.5' x2='20.5' y2='19.5' stroke='currentColor' strokeLinecap='round' />
			<circle cx='5.5' cy='5.5' r='1.5' stroke='currentColor' strokeLinecap='round' />
			<circle cx='5.5' cy='12.5' r='1.5' stroke='currentColor' strokeLinecap='round' />
			<circle cx='5.5' cy='19.5' r='1.5' stroke='currentColor' strokeLinecap='round' />
		</svg>
	);
};

export default List;
