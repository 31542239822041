const VisitorIcon = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={props.width || 34}
		height={props.height || 34}
		viewBox='0 0 34 34'
		fill='none'
		style={{ color: props.color }}
		{...props}>
		<path stroke='currentColor' strokeLinejoin='round' strokeWidth={1.5} d='M11.333 20.542a5.667 5.667 0 1 0 11.333 0H11.333Z' />
		<path stroke='currentColor' strokeWidth={1.5} d='M19.833 14.875a2.833 2.833 0 1 1-5.667 0 2.833 2.833 0 0 1 5.667 0Z' />
		<path
			stroke='currentColor'
			strokeWidth={1.5}
			d='M11.333 3.542H7.666a2 2 0 0 0-2 2v25.041a2 2 0 0 0 2 2h18.667a2 2 0 0 0 2-2V5.542a2 2 0 0 0-2-2h-3.666'
		/>
		<path
			stroke='currentColor'
			strokeWidth={1.5}
			d='M11.333 3.417a2 2 0 0 1 2-2h7.333a2 2 0 0 1 2 2v.25a2 2 0 0 1-2 2h-7.333a2 2 0 0 1-2-2v-.25Z'
		/>
	</svg>
);

export default VisitorIcon;
