import { useIntl } from 'react-intl';
import Loader from 'components/Loader.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { MeasurementTypes, StethoscopeTypes } from 'constants/enums.js';
import { weightTypes } from 'constants/measurements.js';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import Button from 'components/Button.jsx';

const OverviewDataBox = props => {
	const intl = useIntl();
	const startMeasuring = () => {
		if (props.measurement.type === MeasurementTypes.STETHOSCOPE) {
			props.startMeasuringIoTDevice(StethoscopeTypes.MINTTI);
		} else {
			props.startMeasuringIoTDevice();
		}
	};

	const showLoader = props.measurementButtonText && ['Connecting', 'Measuring'].includes(props.measurementButtonText);
	const isColorGreen = props.measurementButtonText && ['Connecting', 'Connect'].includes(props.measurementButtonText);
	return (
		<div className='info-box'>
			{props.measurement && (
				<>
					<header className={`flex-space-between flex-align-center ${showLoader ? 'no-padding-right' : 'padding-right'}`}>
						<h1>{props.title.text}</h1>
						{props.setSelectedMeasurementType && (
							<div
								className='flex flex-align-center cursor-pointer'
								onClick={measurement => props.setSelectedMeasurementType(measurement)}>
								<span className='material-icons'>arrow_forward</span>
							</div>
						)}
					</header>
					<div className='flex overview-main-box'>
						<div className='full-width'>
							{props.children}
							<div className='flex'>
								{(props.isOnlyWeight || props.measurement.type !== MeasurementTypes.WEIGHT) && (
									<>
										<img src={props.title.icon} alt='title icon' />
										<div className='flex overview-main-box-title full-width'>
											{props.measurement.type && (
												<>
													<h1>
														{(props.time && props.measurement.type === MeasurementTypes.STETHOSCOPE && props.time) ||
															props.measurementValue}
													</h1>
													{(!props.time || props.measurement.type !== MeasurementTypes.STETHOSCOPE) && (
														<span>{props.measurementUnit}</span>
													)}
												</>
											)}
										</div>
									</>
								)}
								{!props.isOnlyWeight && props.measurement.type === MeasurementTypes.WEIGHT && (
									<div className='overview-main-box-title full-width'>
										{weightTypes.map(item => (
											<div key={item.type} className='flex flex-space-between'>
												<p className='weight-details'>{item.title.text}</p>
												<div className='flex'>
													{props.weightTypeValues.map(
														weightType =>
															weightType.itemType === item.type && (
																<span key={weightType.itemType}>{weightType.measurementValue}</span>
															)
													)}
													{props.weightTypeUnits.map(
														weightType =>
															weightType.itemType === item.type && (
																<span key={weightType.itemType}>{weightType.measurementUnit}</span>
															)
													)}
												</div>
											</div>
										))}
									</div>
								)}
							</div>
						</div>
					</div>
					<footer className='flex-space-between flex-align-center'>
						<p>{props.startDate && formattedDate(props.startDate)}</p>
						<div style={{ position: 'relative' }}>
							{props.startMeasuringIoTDevice && (
								<Button
									className={`button small health-data-measurement-btn measurement-btn-loader ${
										isColorGreen ? 'measurement-green-btn-txt' : 'measurement-blue-btn-txt'
									}`}
									onClick={startMeasuring}
									svgIcon={showLoader && <Loader />}
									text={intl.formatMessage({ id: stringToCamelCase(props.measurementButtonText) })}
								/>
							)}
						</div>
					</footer>
				</>
			)}
		</div>
	);
};

export default OverviewDataBox;
