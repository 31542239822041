import * as React from 'react';

const New = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={21} fill='none' {...props}>
		<g stroke='currentColor' strokeWidth={1.5}>
			<g strokeLinecap='round' strokeLinejoin='round'>
				<path d='M5.5 17.773v-2.295a4.5 4.5 0 0 1 6.81-3.862M7.546 15.727v2.864M12.455 6.727a2.455 2.455 0 1 1-4.91 0 2.455 2.455 0 0 1 4.91 0Z' />
			</g>
			<path d='M18.952 10.935a9 9 0 1 0-6.452 7.713' />
			<circle cx={16} cy={15} r={5} />
			<g strokeLinecap='round'>
				<path d='M16 13v4M14 15h4' />
			</g>
		</g>
	</svg>
);
export default New;
