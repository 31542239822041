import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getAppointments = async (userId, startDate, endDate) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1.1/healthcare/users/${userId}/appointments?startDate=${startDate}&endDate=${endDate}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDoctorSchedule = async userId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/users/${userId}/doctor-schedule`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const createDoctorSchedule = async (userId, dataToSend) => {
	try {
		const { data } = await gatewayApi.post(`/v1/healthcare/users/${userId}/doctor-schedule`, dataToSend);
		return data;
	} catch (error) {
		return { error };
	}
};

export const createAppointment = async dataToSend => {
	try {
		const { data } = await gatewayApi.post(`v1.1/healthcare/appointments`, dataToSend);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDoctorAvailability = async ({ userId, startDate, endDate }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/users/${userId}/appointments/available`, {
			params: { startDate, endDate },
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteAppointment = async appointmentId => {
	try {
		const { data } = await gatewayApi.delete(`/v1/healthcare/appointments/${appointmentId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateAppointmentStatus = async (appointmentId, statusId) => {
	try {
		const { data } = await gatewayApi.patch(`/v1/healthcare/appointments/${appointmentId}/status`, {
			statusId: statusId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAppointmentInvitations = async ({ userId, pageIndex, pageSize, as, status }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/users/${userId}/appointments/invitations`, {
			params: { pageIndex, pageSize, as, status },
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateAppointment = async (appointmentId, dataToSend) => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/appointments/${appointmentId}`, dataToSend);
		return data;
	} catch (error) {
		return { error };
	}
};

export const rescheduleAppointment = async (appointmentId, sendData) => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/appointments/${appointmentId}/reschedule`, sendData);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAppointmentsHistory = async ({ userId, pageIndex, pageSize }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/users/${userId}/appointments/history`, {
			params: { pageIndex, pageSize },
		});
		return data.appointmentsHistory;
	} catch (error) {
		return { error };
	}
};

export const getUpcomingAppointments = async ({ userId, pageIndex, pageSize }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/users/${userId}/appointments/upcoming`, {
			params: { pageIndex, pageSize },
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAppointmentRequestDetails = async appointmentId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/appointments/${appointmentId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAppointmentDetails = async appointmentId => {
	try {
		const { data } = await gatewayApi.get(`/v1.1/healthcare/appointments/${appointmentId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateAsRead = async notificationId => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/notifications/${notificationId}/read`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientAppointments = async ({ healthSystemId, patientId, pageIndex = 0, pageSize = 10, from, to }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/organizations/${getCompanyId()}/health-systems/${healthSystemId}/patients/${patientId}/appointments?page-index=${pageIndex}&page-size=${pageSize}&from=${from}&to=${to}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
