import React from 'react';

const TablePinButton = props => (
	<i
		className='material-symbols-outlined boxed-icon --orange-background'
		onClick={props.onClick}
		data-position='top'
		data-tooltip='PIN'>
		passkey
	</i>
);

export default TablePinButton;
