import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

function ObjectDetection(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
			<path
				d='M5.52 1.2H2.2a1 1 0 00-1 1v3.32M18.48 22.8h3.32a1 1 0 001-1v-3.32m0-12.96V2.2a1 1 0 00-1-1h-3.32M1.2 18.48v3.32a1 1 0 001 1h3.32'
				stroke={LightTheme.colors.grayFive}
				strokeWidth={1.5}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M11.998 19.65v-7.425m0 0l6.6-3.713m-6.6 3.713l-6.6-3.713M5.398 15.352V9.098a1 1 0 01.51-.871l5.6-3.15a1 1 0 01.98 0l5.6 3.15a1 1 0 01.51.871v6.255a1 1 0 01-.51.872l-5.6 3.15a1 1 0 01-.98 0l-5.6-3.15a1 1 0 01-.51-.872z'
				stroke={LightTheme.colors.grayFive}
				strokeWidth={1.5}
			/>
		</svg>
	);
}

export default ObjectDetection;
