import { LocalTrackController } from '@solaborate/calls/webrtc';
import { enums, participant } from '@solaborate/calls';
import RemoteParticipant from './RemoteParticipant.js';
import RemoteHelloParticipant from './RemoteHelloParticipant.js';

/**
 * @template R
 * @typedef {import('@solaborate/calls').ParticipantFactory} IParticipantFactory
 * @implements {IParticipantFactory<RemoteParticipant>}
 */
class ParticipantFactory {
	/**
	 * @param {import("@solaborate/calls/webrtc").LocalTrackFactory} trackFactory
	 * @param {import("@solaborate/calls").AllConnections} allConnections
	 * @param {string} conferenceId
	 * @param {import("calls/LocalParticipant.js").default} localParticipant
	 * @param {import("@solaborate/calls").Socket} socket
	 * @param {import("@solaborate/calls/webrtc").Logger} [logger=console]
	 */
	constructor(trackFactory, allConnections, conferenceId, localParticipant, socket, requestTrackTypes, logger = console) {
		this.trackFactory = trackFactory;
		this.allConnections = allConnections;
		this.logger = logger;
		this.conferenceId = conferenceId;
		this.localParticipant = localParticipant;
		this.socket = socket;
		this.requestTrackTypes = requestTrackTypes;
	}

	/**
	 * @param {import('@solaborate/calls').models.Participant} model
	 * @param {import('@solaborate/calls/webrtc').Connection} connection
	 * @returns {Promise<RemoteParticipant>}
	 */
	create = async (model, connection) => {
		this.allConnections.add(connection);
		const localTrackController = new LocalTrackController(connection, this.trackFactory);
		const remoteTrackController = new participant.RemoteTrackController({
			conferenceId: this.conferenceId,
			connection,
			socket: this.socket,
			participantId: this.localParticipant.id,
			actioneeParticipantId: model.id,
		});

		const trackTypes = [...this.trackFactory.tracks.keys()];
		await localTrackController.add(trackTypes);
		// Request at least some track (Mic or Camera) so we can generate a proper offer.
		await remoteTrackController.request(trackTypes.length === 0 ? this.requestTrackTypes : trackTypes);

		return model.objectType === enums.ObjectTypes.HELLO_DEVICE
			? new RemoteHelloParticipant(model, {
					id: model.id,
					conferenceId: this.conferenceId,
					localParticipantId: this.localParticipant.id,
					connection,
					localTrackController,
					remoteTrackController,
					socket: this.socket,
			  })
			: new RemoteParticipant(model, {
					id: model.id,
					connection,
					localTrackController,
					remoteTrackController,
			  });
	};

	remove = ({ connection }) => {
		return Promise.resolve(this.allConnections.remove(connection));
	};
}

export default ParticipantFactory;
