import translate from 'i18n-translations/translate.jsx';

const ContactInfo = ({ mrn = '', reasonToConnect = '', name, phone, ext, hasBoldLabels = false }) => (
	<div className='contact-info'>
		{mrn && (
			<>
				<span className={hasBoldLabels ? 'bold' : ''}>MRN:</span> {mrn}
				<br />
			</>
		)}
		{reasonToConnect && (
			<>
				<span className={hasBoldLabels ? 'bold' : ''}>{translate('reasonToConnect')}:</span> {reasonToConnect}
				<br />
			</>
		)}
		{name && (
			<>
				<span className={hasBoldLabels ? 'bold' : ''}>{translate('name')}:</span> {name}
				<br />
			</>
		)}
		{phone && (
			<>
				<span className={hasBoldLabels ? 'bold' : ''}>{translate('phone')}:</span> {phone}
				<br />
			</>
		)}
		{ext && (
			<>
				<span className={hasBoldLabels ? 'bold' : ''}>{translate('ext')}:</span> {ext}
				<br />
			</>
		)}
	</div>
);

export default ContactInfo;
