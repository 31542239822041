import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import Input from 'components/Input.jsx';

const ContactInformation = props => {
	const intl = useIntl();
	return (
		<div className='as-fields'>
			<Input
				label={translate('emailAddress')}
				type='email'
				placeholder={intl.formatMessage({ id: 'emailAddress' })}
				value={props.values.emailAddress}
				readOnly={props.values.emailAddress}
				onChange={props.onChange}
				maxLength={100}
				name='emailAddress'
				error={props.errors.emailAddress}
			/>
			<Input
				label={translate('phoneNumber')}
				type='tel'
				placeholder={intl.formatMessage({ id: 'phoneNumber' })}
				value={props.values.phoneNumber}
				onChange={props.onChange}
				name='phoneNumber'
				className='number-input-wo-arrows'
				error={props.errors.phoneNumber}
			/>
		</div>
	);
};
export default ContactInformation;
