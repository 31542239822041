import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ChatOptionsViews, CompanyRoles, UserRoles } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import ImagesOrVideos from 'containers/Chat/ImagesOrVideos.jsx';
import Participants from 'containers/Chat/Participants.jsx';
import Links from 'containers/Chat/Links.jsx';
import Attachments from 'containers/Chat/Attachments.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import Image from 'icons/Files/Image.jsx';
import Video from 'icons/Files/Video.jsx';
import Attachment from 'icons/Files/Attachment.jsx';
import Link from 'icons/Files/Link.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import translate from 'i18n-translations/translate.jsx';

const ChatRightSide = props => {
	const [selectedChatOption, setSelectedOption] = useState(ChatOptionsViews.DEFAULT);
	const intl = useIntl();
	const isDarkMode = useSelector(state => state.user.darkMode);

	const getNursesOnDutyLength = () => {
		let result = 0;
		result = props.members.filter(item => item?.roles.some(role => role.id === CompanyRoles.VIRTUAL_CARE_PROVIDER)).length;
		if (UserRoles.NURSE === getUserRole()) {
			result -= 1;
		}
		return result;
	};
	const getIconColor = () => (isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive);

	const getChatRightSideOptionsItem = ({
		icon = null,
		imageLink = '',
		title = '',
		count = null,
		content = '',
		className = '',
		option = 0,
	}) => {
		const imgLink = `${healthCareCdnUrl}chat/`;
		return (
			<div className='flex cursor-pointer' onClick={() => setSelectedOption(option)}>
				<div className={`chat-option-item ${className}`}>
					{!props.isFromDoctor && <img src={`${imgLink}${imageLink}`} alt='chat option icon' />}
					{props.isFromDoctor && icon}
				</div>
				<div>
					<p>{title}</p>
					<p>
						{count} {content}
					</p>
				</div>
				<i style={{ marginLeft: 'auto' }} className={classNames('material-icons-outlined')}>
					keyboard_arrow_right
				</i>
			</div>
		);
	};

	const getChatRightOptionsList = () => {
		return [
			!props.isFromDoctor && {
				id: ChatOptionsViews.PARTICIPANTS,
				component: (
					<Participants
						setDefaultOptions={setSelectedOption}
						conversationId={props.conversationId}
						members={props.members}
						nursesOnDutyLength={getNursesOnDutyLength()}
					/>
				),
			},
			{
				id: ChatOptionsViews.IMAGES,
				component: (
					<ImagesOrVideos
						setDefaultOptions={setSelectedOption}
						type={ChatOptionsViews.IMAGES}
						conversationId={props.conversationId}
						members={props.members}
						isFromDoctor={props.isFromDoctor}
					/>
				),
			},
			{
				id: ChatOptionsViews.VIDEOS,
				component: (
					<ImagesOrVideos
						setDefaultOptions={setSelectedOption}
						type={ChatOptionsViews.VIDEOS}
						conversationId={props.conversationId}
						members={props.members}
						isFromDoctor={props.isFromDoctor}
					/>
				),
			},
			{
				id: ChatOptionsViews.ATTACHMENTS,
				component: (
					<Attachments
						setDefaultOptions={setSelectedOption}
						conversationId={props.conversationId}
						isFromDoctor={props.isFromDoctor}
					/>
				),
			},
			{
				id: ChatOptionsViews.LINKS,
				component: (
					<Links setDefaultOptions={setSelectedOption} conversationId={props.conversationId} isFromDoctor={props.isFromDoctor} />
				),
			},
		];
	};

	return (
		<aside className={classNames('chat-right-side', props.isDarkMode ? 'dark-chat-right-side' : '')}>
			{selectedChatOption === ChatOptionsViews.DEFAULT && (
				<>
					{!props.isFromDoctor && (
						<div className='chat-right-side-info'>
							<img src={`${healthCareCdnUrl}teams/department-ico.svg?v2`} alt='department icon' />
							<p>{props.conversationName}</p>
						</div>
					)}
					{props.isFromDoctor && (
						<div className='shared-chat'>
							<p>{translate('sharedInChat')}</p>
						</div>
					)}

					<div className='chat-right-side-options'>
						{!props.isFromDoctor &&
							getChatRightSideOptionsItem({
								imageLink: 'participants.svg',
								count: getNursesOnDutyLength(),
								title: intl.formatMessage({ id: 'participants' }),
								content: `${getNursesOnDutyLength() === 1 ? ' Nurse is' : ' Nurses are'} currently on duty`,
								className: 'participants',
								option: ChatOptionsViews.PARTICIPANTS,
							})}
						{getChatRightSideOptionsItem({
							icon: <Image color={getIconColor()} />,
							imageLink: 'image.svg',
							title: intl.formatMessage({ id: 'images' }),
							content: intl.formatMessage({ id: 'sharedImages' }),
							className: !props.isFromDoctor ? 'images' : 'chat-option',
							option: ChatOptionsViews.IMAGES,
						})}
						{getChatRightSideOptionsItem({
							icon: <Video color={getIconColor()} />,
							imageLink: 'videos.svg',
							title: intl.formatMessage({ id: 'videos' }),
							content: intl.formatMessage({ id: 'sharedVideos' }),
							className: !props.isFromDoctor ? 'videos' : 'chat-option',
							option: ChatOptionsViews.VIDEOS,
						})}
						{getChatRightSideOptionsItem({
							icon: <Attachment color={getIconColor()} />,
							imageLink: 'attachments.svg',
							title: intl.formatMessage({ id: 'attachments' }),
							content: intl.formatMessage({ id: 'sharedAttachments' }),
							className: !props.isFromDoctor ? 'attachments' : 'chat-option',
							option: ChatOptionsViews.ATTACHMENTS,
						})}
						{getChatRightSideOptionsItem({
							icon: <Link color={getIconColor()} />,
							imageLink: 'links.svg',
							title: intl.formatMessage({ id: 'links' }),
							content: intl.formatMessage({ id: 'sharedLinks' }),
							className: !props.isFromDoctor ? 'links' : 'chat-option',
							option: ChatOptionsViews.LINKS,
						})}
					</div>
				</>
			)}
			{selectedChatOption !== ChatOptionsViews.DEFAULT &&
				getChatRightOptionsList().find(item => item.id === selectedChatOption)?.component}
		</aside>
	);
};

export default ChatRightSide;
