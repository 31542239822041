import { CompanyTypes } from 'constants/action-types.js';

export const actionCreators = {
	setCompanyDetails: companyDetails => dispatch => {
		dispatch({ type: CompanyTypes.SET_COMPANY_DETAILS, payload: companyDetails });
	},
	setCompanySettings: companySettings => dispatch => {
		dispatch({ type: CompanyTypes.SET_COMPANY_SETTINGS, payload: companySettings });
	},
};
