import React from 'react';

const FooterButton = props => (
	<div
		id={props.id}
		className={`call-button call-patient-feed-button ${props.isActive ? `active` : ``} ${props.isDisabled ? 'disabled' : ''}`}
		onClick={props.onClick}
		data-tooltip={props.tooltipText}
		data-position={props.tooltipPosition}
	/>
);

export default FooterButton;
