import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

function AIControls(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
			<path
				d='M5.52 1.2H2.2a1 1 0 00-1 1v3.32M18.48 22.8h3.32a1 1 0 001-1v-3.32M22.8 5.52V2.2a1 1 0 00-1-1h-3.32M1.2 18.48v3.32a1 1 0 001 1h3.32'
				stroke={LightTheme.colors.grayFive}
				strokeWidth={1.5}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M15.778 7.68a1.62 1.62 0 110-3.24 1.62 1.62 0 010 3.24zM13.618 13.08a1.62 1.62 0 110-3.24 1.62 1.62 0 010 3.24zM15.778 18.48a1.62 1.62 0 110-3.24 1.62 1.62 0 010 3.24z'
				stroke={LightTheme.colors.grayFive}
				strokeWidth={1.5}
			/>
			<path d='M7.68 9.3L9.3 6.06h4.86M5.52 13.62h5.4l1.62-1.08M7.68 13.621v3.24h6.48' stroke={LightTheme.colors.grayFive} strokeWidth={1.5} />
		</svg>
	);
}

export default AIControls;
