import translate from 'i18n-translations/translate.jsx';
import { formatDate, getDateDifference } from 'infrastructure/helpers/dateHelper.js';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useIntl } from 'react-intl';
import HealthDataTabs from 'containers/HealthMeasurements/HealthDataTabs.jsx';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { enUS, sq, arSA, de, es } from 'date-fns/locale';

const { region } = new Intl.Locale(navigator.language);

const DatePickerLocales = {
	'en-EN': enUS,
	sq: sq,
	'ar-AE': arSA,
	'de-DE': de,
	'es-ES': es,
};

const DateRange = ({
	startDate,
	endDate,
	handleFromChange = _ => {},
	handleRangeChange,
	maxDays = 0,
	disableFutureDays = true,
	isUSTimesFormat = region === 'US',
	keepDateRangeOpen = false,
	isUTCCalendar = false,
	showLabels = false,
	currentTab = null,
	handleSelect = _ => {},
	isHealthData = false,
	isCallView = false,
}) => {
	const Range = {
		START: 'start',
		END: 'end',
	};
	const locale = useSelector(state => state.language.locale);
	const [selectedRange, setSelectedRange] = useState({ from: startDate, to: endDate });
	const [areDatesShown, setAreDatesShown] = useState(keepDateRangeOpen ?? false);
	const [dateRangeFooter, setDateRangeFooter] = useState(null);
	const [activeInput, setActiveInput] = useState(Range.START);
	const todayUTC = new Date();
	todayUTC.setMinutes(todayUTC.getMinutes() + todayUTC.getTimezoneOffset());
	const today = isUTCCalendar ? todayUTC : new Date();
	const [defaultMonth, setDefaultMonth] = useState(startDate || today);
	const intl = useIntl();
	const pickDatesRef = useRef(null);

	const disabledDays = disableFutureDays ? { after: today } : null;

	const handleValidSelection = (from, to, isRangeSelect = true) => {
		setActiveInput(Range.END);
		setDateRangeFooter(null);
		setSelectedRange({ from, to });
		isRangeSelect ? handleRangeChange({ from, to }) : handleFromChange(from);
	};

	const handleRangeSelect = selectedDay => {
		if (!selectedDay) {
			return;
		}
		let from = null,
			to = null;
		let secondaryDate = activeInput === Range.START ? selectedRange.to : selectedRange.from;
		const isInvertedSelection =
			(activeInput === Range.START && selectedDay > secondaryDate) || (activeInput === Range.END && selectedDay < secondaryDate);

		if (!selectedRange.to && !selectedRange.from) {
			handleValidSelection(selectedDay, secondaryDate, false);
			return;
		}

		if (isInvertedSelection) {
			secondaryDate = activeInput === Range.START ? selectedRange.from : selectedRange.to;
		}
		const diff = getDateDifference(selectedDay, secondaryDate);

		if (maxDays && Math.abs(diff) >= maxDays) {
			if (!dateRangeFooter) {
				setDateRangeFooter(<div className='date-range-error'>{translate('selectWithinDateRange', { value: maxDays })}</div>);
				return;
			}
			handleValidSelection(selectedDay, selectedDay);
			return;
		}
		from = diff < 0 ? secondaryDate : selectedDay;
		to = diff < 0 ? selectedDay : secondaryDate;
		handleValidSelection(from, to);
	};

	useOutsideClick(pickDatesRef, () => {
		if (areDatesShown && !keepDateRangeOpen) {
			setAreDatesShown(false);
		}
	});

	const handleDateRangeClick = e => {
		setDefaultMonth(startDate || today);
		const newActiveInput = e.target.id === 'endDateInput' ? Range.END : Range.START;
		setActiveInput(newActiveInput);
		if (areDatesShown) {
			setDefaultMonth(newActiveInput === Range.START ? startDate || today : endDate || startDate || today);
		}
		if (!areDatesShown || (!keepDateRangeOpen && activeInput === newActiveInput)) {
			setAreDatesShown(prevState => !prevState);
			if (areDatesShown) {
				setDefaultMonth(endDate || startDate || today);
			}
		}
	};

	useEffect(() => {
		setSelectedRange({ from: startDate, to: endDate });
	}, [startDate, endDate]);

	const getDate = date => (isUSTimesFormat ? moment(date).format('MM/DD/YYYY') : formatDate(date));

	return (
		<div
			ref={pickDatesRef}
			className={classNames('flex flex-align-center date-range position-relative', isCallView ? 'date-range-call' : '')}>
			<div className='flex value-container' onClick={handleDateRangeClick}>
				<label className='date-range-input-wrapper'>
					{showLabels && <span>{translate('startDate')}</span>}
					<input
						id='startDateInput'
						onChange={() => null}
						value={selectedRange.from ? getDate(selectedRange.from) : ''}
						className={areDatesShown && activeInput === Range.START ? 'date-range-active-input' : ''}
						placeholder={intl.formatMessage({ id: 'startDate' })}
					/>
					{areDatesShown && activeInput === Range.START && <i className='material-icons'>arrow_drop_down</i>}
				</label>
				<span className='date-range-separator'>-</span>
				<label className='date-range-input-wrapper'>
					{showLabels && <span>{translate('endDate')}</span>}
					<input
						id='endDateInput'
						onChange={() => null}
						value={endDate ? getDate(endDate) : ''}
						className={areDatesShown && activeInput === Range.END ? 'date-range-active-input' : ''}
						placeholder={intl.formatMessage({ id: 'endDate' })}
					/>
					{areDatesShown && activeInput === Range.END && <i className='material-icons'>arrow_drop_down</i>}
				</label>
			</div>
			<div className='InputFromTo date-range_minimal'>
				{areDatesShown && (
					<>
						{isHealthData && <HealthDataTabs handleSelect={handleSelect} currentTab={currentTab} />}
						<DayPicker
							mode='range'
							selected={selectedRange}
							onDayClick={handleRangeSelect}
							disabled={disabledDays}
							numberOfMonths={2}
							month={defaultMonth}
							onMonthChange={setDefaultMonth}
							footer={dateRangeFooter}
							locale={DatePickerLocales[locale]}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default DateRange;
