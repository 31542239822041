import * as React from 'react';
const ChangeRpm = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} viewBox='0 0 22 24' fill='none' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M3 17V8a3 3 0 0 1 3-3h2.757a3 3 0 0 1 2.122.879L12.12 7.12A3 3 0 0 0 14.243 8H18a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3Z'
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M12 17c-6.745-3.704-2.023-7.747 0-5.205 2.024-2.542 6.745 1.501 0 5.205Z'
		/>
	</svg>
);
export default ChangeRpm;
