import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const GridView = props => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		color={LightTheme.colors.graySeven}
		{...props}>
		<path d='M3 15L21 15' stroke='currentColor' strokeWidth='1.5' />
		<path d='M3 9L21 9' stroke='currentColor' strokeWidth='1.5' />
		<path d='M15 20L15 4' stroke='currentColor' strokeWidth='1.5' />
		<path d='M9 20L9 4' stroke='currentColor' strokeWidth='1.5' />
		<rect x='3' y='4' width='18' height='16' rx='2' stroke='currentColor' strokeWidth='1.5' />
	</svg>
);

export default GridView;
