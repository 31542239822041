const Language = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} viewBox='0 0 24 24' fill='none' {...props}>
		<circle cx={11} cy={11} r={8.25} stroke='currentColor' strokeWidth={1.5} />
		<path
			d='M19.25 10.4844C18.2188 12.5469 15.5563 14.0938 11 14.0938C6.44365 14.0938 3.78125 12.5469 2.75 10.4844'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
		<path
			d='M3.41016 7.90625C4.73062 9.57316 7.21011 10.4844 10.9998 10.4844C14.7896 10.4844 17.269 9.57316 18.5895 7.90625'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
		<ellipse cx={11} cy={11} rx={3.09375} ry={8.25} stroke='currentColor' strokeWidth={1.5} />
	</svg>
);
export default Language;
