import { Button, MultiSelectCheckboxes } from 'components/index.js';
import Filter from 'icons/Dashboard/Filter.jsx';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

const ColumnFilters = ({ filterOptions, columnLabels = null, columnFilters, setColumnFilters }) => {
	const [showFiltersDropdown, setShowFiltersDropdown] = useState(false);
	const [selectedValues, setSelectedValues] = useState(columnFilters);
	const containerRef = useRef(null);
	const intl = useIntl();

	const handleChangedValue = (filterField, value) => {
		setSelectedValues(prevState => ({ ...prevState, [filterField]: value }));
		setColumnFilters(prevState => ({ ...prevState, [filterField]: value.map(item => item.value) }));
	};

	const handleClearFilters = () => {
		setSelectedValues(prevState => Object.keys(filterOptions).reduce((acc, curr) => ({ ...acc, [curr]: [] }), prevState));
		setColumnFilters([]);
	};

	useOutsideClick(containerRef, () => setShowFiltersDropdown(false));

	return (
		<div ref={containerRef} className='position-relative' onClick={e => e.preventDefault()}>
			<Button
				svgIcon={<Filter />}
				className='dashboard-column-filters-button'
				disabled={Object.keys(filterOptions).length === 0}
				onClick={() => setShowFiltersDropdown(prevState => !prevState)}
			/>
			<div className='dashboard-additional-filters dashboard-column-filters' hidden={!showFiltersDropdown}>
				<h5>
					<span>{intl.formatMessage({ id: 'columnFilters' })}</span>
					<span onClick={handleClearFilters}>{intl.formatMessage({ id: 'clearAll' })}</span>
				</h5>
				{Object.keys(filterOptions).map(filterField => (
					<div key={filterField}>
						<span>{intl.formatMessage({ id: columnLabels?.[filterField] || filterField })}</span>
						<MultiSelectCheckboxes
							name={`${filterField}-values`}
							selectedOptions={selectedValues?.[filterField] ?? []}
							options={filterOptions[filterField]}
							onChange={value => handleChangedValue(filterField, value)}
						/>
					</div>
				))}
			</div>
			{Object.keys(selectedValues).some(filterField => selectedValues[filterField].length > 0) && (
				<span className='active-filters-badge'></span>
			)}
		</div>
	);
};

export default ColumnFilters;
