import { getExternalDevices, unassignWearable } from 'api/lifeSignals.js';
import classNames from 'classnames';
import Alert from 'components/Alert.jsx';
import AssignWearable from 'components/AssignWearable.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Modal from 'components/Modal.jsx';
import WearablesHistory from 'components/WearablesHistory.jsx';
import { MonitoringSettings, RoundingSettings } from 'constants/configurationEnums.js';
import { WearableProviders, WearablesStatus } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getSomeConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const Wearables = ({ currentSector, ownerId, helloDeviceId }) => {
	const intl = useIntl();
	const noDataArray = [
		{
			id: 0,
			iotDeviceId: '',
			dateCreated: '',
		},
	];
	const [wearables, setWearables] = useState([]);
	const [isAssignWearableModalOpen, setIsAssignWearableModalOpen] = useState(false);
	const [isUnassignWearableModalOpen, setIsUnassignWearableModalOpen] = useState(false);
	const [selectedWearableId, setSelectedWearableId] = useState(null);
	const [error, setError] = useState('');
	const [isWearableHistoryOpen, setIsWearableHistoryOpen] = useState(null);
	const [isUnassignSubmitLoading, setIsUnassignSubmitLoading] = useState(false);
	const [hasWearablesHistory, setHasWearablesHistory] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);

	const enabledProviders = [
		getSomeConfigurationsValues(companyConfigurations, [MonitoringSettings.LifeSignals, RoundingSettings.LifeSignalsPatch]) && {
			value: 0,
			label: WearableProviders[1],
		},
		getSomeConfigurationsValues(companyConfigurations, [MonitoringSettings.BiobeatVitalSigns, RoundingSettings.BiobeatPatch]) && {
			value: 1,
			label: WearableProviders[2],
		},
	].filter(Boolean);

	useEffect(() => {
		const fetchWearables = async () => {
			setIsLoading(true);
			const response = await getExternalDevices({ patientId: ownerId, status: WearablesStatus.ACTIVE });
			if (response.error) {
				setWearables(noDataArray);
				setError(response.error.message);
			} else {
				setWearables(response.patientExternalIotDevices.length ? response.patientExternalIotDevices : noDataArray);
			}
		};

		const fetchWearablesHistory = async () => {
			const response = await getExternalDevices({ patientId: ownerId, status: WearablesStatus.INACTIVE });
			if (response.error) {
				setError(response.error.message);
			} else {
				setHasWearablesHistory(response.patientExternalIotDevices.length > 0);
			}
			setIsLoading(false);
		};

		if (enabledProviders.length > 0) {
			fetchWearables();
			fetchWearablesHistory();
		}
	}, [ownerId, isAssignWearableModalOpen, isUnassignWearableModalOpen, currentSector.roomId]);

	const displayWearables = () => {
		if (!wearables || !wearables.length) {
			return [];
		}
		return wearables.map(wearable => {
			return {
				id: wearable.iotDeviceId,
				provider:
					wearable.iotDeviceId !== '' && wearable?.externalIotDeviceType?.vendorId
						? WearableProviders[wearable.externalIotDeviceType.vendorId]
						: translate('noAssignedWearable'),
				activeWearableId: wearable.iotDeviceId,
				startDate: wearable.dateCreated ? formattedDate(wearable.dateCreated) : '',
				actions: (
					<div className='wrapped justify-content-start wearables-icons'>
						{hasWearablesHistory && (
							<div className='wrapped'>
								<i
									data-tooltip={intl.formatMessage({ id: 'wearableHistoryTooltip' })}
									data-position='top'
									className='material-icons-outlined boxed-icon history-icon'
									onClick={item => setIsWearableHistoryOpen(item)}>
									history
								</i>
							</div>
						)}
						{wearable.iotDeviceId && (
							<div className='wrapped'>
								<i
									data-tooltip={intl.formatMessage({ id: 'unassignWearableTooltip' })}
									data-position='top'
									className='material-icons-outlined boxed-icon delete-icon'
									onClick={() => {
										setIsUnassignWearableModalOpen(true);
										setSelectedWearableId(wearable.iotDeviceId);
									}}>
									delete
								</i>
							</div>
						)}
						<i
							data-tooltip={intl.formatMessage({ id: 'assignWearable' })}
							data-position='top'
							className={classNames('material-icons-outlined boxed-icon add-icon', wearable.iotDeviceId ? 'left-s' : '')}
							onClick={() => setIsAssignWearableModalOpen(true)}>
							add
						</i>
					</div>
				),
				className: !wearable.iotDeviceId ? 'no-iot-devices' : '',
			};
		});
	};

	const handleUnassignWearable = async () => {
		setIsUnassignSubmitLoading(true);
		const response = await unassignWearable(ownerId, selectedWearableId);
		if (response.error) {
			setError(response.error.message);
		} else {
			setSelectedWearableId(null);
			setIsUnassignWearableModalOpen(false);
		}
		setIsUnassignSubmitLoading(false);
	};

	return (
		<div className='full-width wearables'>
			<CustomTable
				headers={[
					{ title: translate('provider'), id: 'provider' },
					{ title: translate('wearableId'), id: 'activeWearableId' },
					{ title: translate('startDate'), id: 'startDate' },
					{ title: translate('actions'), id: 'actions' },
				]}
				rows={displayWearables()}
				className='admin-table'
				isLoading={isLoading}
			/>
			<AssignWearable
				display={isAssignWearableModalOpen}
				setIsAssignWearableModalOpen={setIsAssignWearableModalOpen}
				sector={currentSector}
				ownerId={ownerId}
				helloDeviceId={helloDeviceId}
				enabledProviders={enabledProviders}
			/>
			<Modal
				display={isUnassignWearableModalOpen}
				position='center'
				isLoading={isUnassignSubmitLoading}
				onModalSubmit={handleUnassignWearable}
				primaryButtonLabel={translate('yes')}
				onModalClose={() => setIsUnassignWearableModalOpen(false)}
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'>
				<form>
					<h4>{translate('unassignWearable')}</h4>
				</form>
			</Modal>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
			<WearablesHistory
				display={isWearableHistoryOpen}
				setIsWearableHistoryOpen={setIsWearableHistoryOpen}
				sector={currentSector}
				ownerId={ownerId}
			/>
		</div>
	);
};
export default Wearables;
