import { gatewayApi } from 'constants/global-variables.js';

export async function sendStats(data, retries = 5, token = '') {
	try {
		const response = await gatewayApi.post(`v1/callmetrics`, data, {
			...(token && { headers: { Authorization: `Bearer ${token}` } }),
		});
		return response.data;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('error while sending call stats logs', error);
		if (retries > 0) {
			await new Promise(resolve => setTimeout(resolve, 2000));
			return sendStats(data, retries - 1);
		}

		throw error;
	}
}

export const sendFeedBack = async ({ conferenceId, participantId, rate, comment }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/conferences/${conferenceId}/feedbacks`, {
			participantId,
			rate,
			comment,
		});
		return data;
	} catch (error) {
		return { error };
	}
};
