import * as React from 'react';

const Discharge = props => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			color='currentColor'
			{...props}>
			<path d='M15 5H17C17.5523 5 18 5.44772 18 6V10M20 21H19C18.4477 21 18 20.5523 18 20V16' stroke='currentColor' />
			<path
				d='M5 21V5.80217C5 5.33347 5.32553 4.92766 5.78307 4.82598L12.7831 3.27043C13.4076 3.13165 14 3.60687 14 4.24662V20C14 20.5523 13.5523 21 13 21H5ZM5 21H3'
				stroke='currentColor'
			/>
			<circle cx='11' cy='13' r='1' fill='currentColor' />
			<path d='M21 15L23 13L21 11' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M23 13L17 13' stroke='currentColor' strokeLinecap='round' />
		</svg>
	);
};

export default Discharge;
