import React, { useState, useEffect } from 'react';
import { getMedicalInfo } from 'api/medicalInfo.js';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import Loader from 'components/Loader.jsx';
import { getUserId } from 'infrastructure/auth.js';
import Grid from 'components/Grid.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import Pagination from 'components/Common/Pagination.jsx';
import { DiagnosisTypes, MedicalInfoTypes, ProcedureTypes } from 'constants/enums.js';
import EmptyState from 'components/EmptyState.jsx';
import Modal from 'components/Modal.jsx';

const DiagnosesOrProcedures = props => {
	const [items, setItems] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [selectedItem, setSelectedItem] = useState(null);

	useEffect(() => {
		const getItems = async (pageSize, pageIndex) => {
			setIsLoading(true);
			const params = {
				pageSize,
				pageIndex,
				userId: getUserId(),
				type: props.type,
				patientId: props.patientId || null,
			};
			const response = await getMedicalInfo(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				const data = props.type === MedicalInfoTypes.DIAGNOSES ? response.diagnoses : response.procedures;
				setPagination(prevState => ({ ...prevState, totalCount: response.totalCount }));
				setItems(data);
			}
			setIsLoading(false);
		};
		getItems(pagination.pageSize, pagination.pageIndex);
	}, [props.patientId, props.type, pagination.pageIndex, pagination.pageSize]);

	const onPageChange = (pageSize, pageIndex) => {
		setPagination(prevState => ({ ...prevState, pageSize, pageIndex }));
	};

	const getProcedureName = id => {
		return ProcedureTypes.find(procedure => procedure.id === id)?.value;
	};

	const getDiagnosisName = id => {
		return DiagnosisTypes.find(diagnose => diagnose.id === id)?.value;
	};

	return (
		<>
			{!isLoading && (
				<>
					{items.length > 0 && (
						<div className='patient-summary-wrapper'>
							<h3>{translate(props.type)}</h3>
							<div className='patient-summary-table-wrapper'>
								<table cellPadding='0' cellSpacing='0'>
									<thead>
										<tr>
											<th>{translate('code')}</th>
											<th>{translate(props.type)}</th>
											<th>{translate('type')}</th>
											<th>{translate('dateAdded')}</th>
											<th>{translate('doctor')}</th>
										</tr>
									</thead>
									<tbody>
										{items.map(item => (
											<tr key={item.id} className='cursor-pointer' onClick={() => setSelectedItem(item)}>
												<td>{item.code}</td>
												<td>{item.description}</td>
												{item.diagnosisTypeId && <td>{getDiagnosisName(item.diagnosisTypeId)}</td>}
												{item.procedureTypeId && <td>{getProcedureName(item.procedureTypeId)}</td>}
												<td>{formattedDate(item.visitedAt)}</td>
												<td>{item.doctorFullName}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<Pagination
								totalCount={pagination.totalCount}
								pageSize={pagination.pageSize}
								pageIndex={pagination.pageIndex}
								onChange={(pageSize, pageIndex) => onPageChange(pageSize, pageIndex)}
							/>
							<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
						</div>
					)}
					{items.length === 0 && (
						<div className='empty-state-wrapper'>
							<EmptyState
								title={props.type === MedicalInfoTypes.DIAGNOSES ? translate('noDiagnosesFound') : translate('noProcedureFound')}
								image={props.type === MedicalInfoTypes.DIAGNOSES ? 'Diagnose.svg' : 'Procedure.svg'}
							/>
						</div>
					)}
					{selectedItem && (
						<Modal
							display={selectedItem}
							onModalClose={() => setSelectedItem(null)}
							position='center'
							className='standard-modal-wrapper modal-wrapper-wo-btn'>
							<div className='standard-modal-inner modal-wrapper-wo-btn-inner simple-txt-modal-inner prescriptions-modal'>
								<div>
									<div className='title'>
										<h4>{selectedItem.description}</h4>
									</div>
									<div className='flex'>
										<div>
											<h5>{translate('code')}</h5>
											<p>{selectedItem.code}</p>
										</div>
										<div>
											<h5>{translate('type')}</h5>
											{selectedItem.diagnosisTypeId && <p>{getDiagnosisName(selectedItem.diagnosisTypeId)}</p>}
											{selectedItem.procedureTypeId && <p>{getProcedureName(selectedItem.procedureTypeId)}</p>}
										</div>
									</div>
									<div className='flex'>
										<div>
											<h5>{translate('date')}</h5>
											<p>{formattedDate(selectedItem.visitedAt)}</p>
										</div>
										<div>
											<h5>{translate('doctor')}</h5>
											<p>{selectedItem.doctorFullName}</p>
										</div>
									</div>
								</div>
							</div>
						</Modal>
					)}
				</>
			)}
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}
		</>
	);
};

export default DiagnosesOrProcedures;
