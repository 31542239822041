import * as React from 'react';

const Plus = props => {
	return (
		<svg width={16} height={16} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M8 4.667v6.666M4.667 8h6.666'
				stroke='currentColor'
				strokeWidth={1.5}
				strokeMiterlimit={10}
				strokeLinecap='round'
			/>
		</svg>
	);
};
export default Plus;
