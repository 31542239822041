import styled, { css } from 'styled-components';
import { WindowSize } from 'constants/enums.js';

/**
 * @type {import('styled-components').StyledComponent<"aside", any, { $isDarkMode: boolean }, never>}
 */
const StyledAllParticipantsAside = styled.aside`
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	width: 30%;
	background: var(--gray-0);
	padding: var(--spacing-xl);
	border-radius: var(--spacing-s);
	overflow: auto;
	> h4 {
		padding: 0;
		padding-bottom: var(--spacing-m);
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 0;
		border-bottom: 1px solid var(--gray-8-o05);
	}
	p {
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 14px;
		margin-bottom: var(--spacing-m);
		margin-top: var(--spacing-xl);
		i {
			font-size: 22px;
		}
	}
	> div {
		> div {
			padding: var(--spacing-m);
			margin-bottom: var(--spacing-l);
			background: var(--gray-0);
			border: 1px solid var(--gray-8-o05);
			align-items: center;
			div:not(:has(> button)) {
				margin-right: var(--spacing-m);
			}
			h4 {
				margin: 0;
				padding: 0;
				font-weight: 500;
				font-size: 14px;
			}
			> div:not(:first-of-type) {
				margin-left: auto;
				margin-right: 0;
				button {
					padding: 4px;
					margin-left: var(--spacing-s);
					margin-right: 0;
					&:hover {
						cursor: unset;
					}
					span {
						font-size: 12px;
					}
					&:last-of-type {
						cursor: pointer;
					}
				}
				.pin-participant,
				.pinned-participant {
					display: flex;
					padding: 0;
					background: transparent;
					transition: all 0.3s ease-in-out;
					svg {
						cursor: pointer;
					}
				}
				.pin-participant {
					display: none;
				}
				> span:first-of-type {
					margin-left: var(--spacing-s);
				}
			}
			&:hover {
				> div:not(:first-of-type) {
					.pin-participant {
						display: flex;
					}
				}
			}
		}
		&:last-child {
			margin-bottom: 70px;
		}
	}

	${props =>
		props.$isDarkMode &&
		css`
			h4,
			p {
				color: var(--dark-theme-gray-1);
			}
			background: var(--dark-theme-gray-4-v2);
			> h4 {
				border-color: var(--dark-theme-gray-2);
			}
			> div {
				> div {
					background: var(--dark-theme-gray-4);
				}
			}
		`}

	@media (max-width: ${WindowSize.TABLET}px) {
		width: 100%;
		z-index: 3;
	}
`;

export default StyledAllParticipantsAside;
