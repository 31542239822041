import React, { useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import DatePicker from 'react-datepicker';
import { format, parseISO } from 'date-fns';
import Button from 'components/Button.jsx';
import ActionButtons from 'containers/Monitoring/Whiteboard/ActionButtons.jsx';

const PainMed = props => {
	const { data, setData, locale, isSubmitButtonLoading } = props;

	const [expanded, setExpanded] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);
	const [dataToEdit, setDataToEdit] = useState(data);
	const intl = useIntl();

	useEffect(() => {
		setDataToEdit(data);
	}, [data]);

	const handleChange = (name, date) => {
		setDataToEdit(prevState => ({
			...prevState,
			[name]: date ? date.toISOString() : '',
		}));
	};

	const formattedInitialValue = item => {
		if (!item) {
			return '';
		}
		const date = parseISO(item);
		return new Date(date);
	};

	const handleActionButton = () => {
		setShowEditForm(prevState => !prevState);
		setExpanded(true);
	};

	const handleSave = () =>
		setData({
			lastDose: dataToEdit.lastDose || '',
			nextDose: dataToEdit.nextDose || '',
		});

	return (
		<>
			<div className={classNames('timeline-box-header sub-timeline-box-header', { expanded })}>
				<p className='timeline-box-title'>{translate('painMedAvailable')}</p>
				<div className='timeline-box-actions'>
					<ActionButtons
						handleEditClick={handleActionButton}
						handleExpandClick={() => setExpanded(prevState => !prevState)}
						expanded={expanded}
					/>
				</div>
			</div>
			{expanded && (
				<>
					{!showEditForm && (
						<table className='patient-table-informations full-width'>
							<tbody>
								<tr>
									<td>{translate('lastDoseGiven')}</td>
									<td>{formattedInitialValue(data.lastDose) ? format(parseISO(data.lastDose), 'MMMM d, yyyy h:mm a') : ''}</td>
								</tr>
								<tr>
									<td>{translate('nextDose')}</td>
									<td>{formattedInitialValue(data.nextDose) ? format(parseISO(data.nextDose), 'MMMM d, yyyy h:mm a') : ''}</td>
								</tr>
							</tbody>
						</table>
					)}
					{showEditForm && (
						<div className='flex flex-space-between flex-wrap whiteboard-date-time'>
							<div className='full-width input margin-bottom-0'>
								<label>
									{translate('lastDoseGiven')}
									<DatePicker
										showTimeSelect
										selected={formattedInitialValue(dataToEdit.lastDose)}
										onChange={date => handleChange('lastDose', date)}
										maxDate={dataToEdit.nextDose ? formattedInitialValue(dataToEdit.nextDose) : new Date()}
										dateFormat='MMMM d, yyyy h:mm aa'
										popperPlacement='bottom-start'
										placeholderText={intl.formatMessage({ id: 'lastDoseGiven' })}
										locale={locale}
										timeIntervals={1}
										className='full-width'
										popperProps={{ strategy: 'fixed' }}
									/>
								</label>
							</div>
							<div className='margin-top-s full-width input margin-bottom-0'>
								<label>
									{translate('nextDose')}
									<DatePicker
										showTimeSelect
										selected={formattedInitialValue(dataToEdit.nextDose)}
										minDate={dataToEdit.lastDose ? formattedInitialValue(dataToEdit.lastDose) : null}
										onChange={date => handleChange('nextDose', date)}
										dateFormat='MMMM d, yyyy h:mm aa'
										popperPlacement='bottom-start'
										placeholderText={intl.formatMessage({ id: 'nextDose' })}
										locale={locale}
										timeIntervals={1}
										className='full-width'
										popperProps={{ strategy: 'fixed' }}
									/>
								</label>
							</div>
							<div className='margin-left-auto'>
								<Button
									className={classNames('button', { loading: isSubmitButtonLoading })}
									onClick={handleSave}
									text={translate('save')}
								/>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default PainMed;
