import * as React from 'react';

function ControlCamera(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
			<path
				d='M9.43 3.257l1.69-1.692a1.237 1.237 0 011.755 0l1.697 1.692M9.43 20.743l1.69 1.696a1.246 1.246 0 001.755 0l1.697-1.696M3.256 14.572l-1.692-1.69a1.239 1.239 0 010-1.756l1.692-1.697M20.764 9.429l1.67 1.697a1.24 1.24 0 010 1.756l-1.692 1.69M11.998 14.4a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8z'
				stroke='currentColor'
				strokeWidth={1.5}
				strokeMiterlimit={10}
				strokeLinecap='round'
			/>
		</svg>
	);
}

export default ControlCamera;
