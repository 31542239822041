import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';
import { buildQueryString } from 'infrastructure/helpers/commonHelpers.js';

export const getCareEventsDashboard = async ({ level, id, queryParams }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/levels/${level}/ids/${id}/patients/care-events/dashboards?${buildQueryString(
				queryParams
			)}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addPatientCareEvent = async ({ patientId, careEventId, careMemberUserId, priorityId }) => {
	try {
		const { data } = await gatewayApi.post(`v1/healthcare/patients/${patientId}/care-events`, {
			careEventId,
			careMemberUserId,
			priorityId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateCareEventStatus = async ({ patientId, careEventId, statusId, note, careMemberId, priorityId }) => {
	try {
		const { data } = await gatewayApi.put(`v1/healthcare/patients/${patientId}/care-events/${careEventId}`, {
			statusId,
			note,
			careMemberId,
			priorityId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientsByLevel = async ({ level, id }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/levels/${level}/ids/${id}/patients/basic-info`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCareEventHistory = async ({ patientId, careEventId, pageIndex = 0, pageSize = 20 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/patients/${patientId}/care-events/${careEventId}/history?page-index=${pageIndex}&page-size=${pageSize}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
