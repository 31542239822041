import * as React from 'react';

const BodyMeasurementsIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
		<path
			stroke='#FFB533'
			strokeMiterlimit={10}
			strokeWidth={1.5}
			d='M8.037 3.418a1.31 1.31 0 1 0 0-2.618 1.31 1.31 0 0 0 0 2.618ZM10.14 6.454h2.26a.878.878 0 0 0 .617-.253.859.859 0 0 0 0-1.22.878.878 0 0 0-.617-.253H3.673a.878.878 0 0 0-.618.252.859.859 0 0 0 0 1.221.878.878 0 0 0 .618.253h2.26l-.079 1.399-.244 2.694-.332 3.79a.76.76 0 0 0 .19.606.775.775 0 0 0 .587.257.79.79 0 0 0 .527-.205.774.774 0 0 0 .25-.503l.515-3.937a.594.594 0 0 1 .2-.363.606.606 0 0 1 .393-.146h.192c.144 0 .284.052.392.146.11.093.18.222.202.363l.515 3.937a.774.774 0 0 0 .25.503.79.79 0 0 0 1.112-.052.766.766 0 0 0 .191-.606c-.105-1.123-.218-2.677-.332-3.79l-.244-2.694-.079-1.399Z'
		/>
	</svg>
);
export default BodyMeasurementsIcon;
