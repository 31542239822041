import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Eye = props => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			color={LightTheme.colors.grayZero}
			{...props}>
			<path
				d='M12 6C5.5 6 3 12 3 12C3 12 5.5 18 12 18C18.5 18 21 12 21 12C21 12 18.5 6 12 6Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5C13.3807 9.5 14.5 10.6193 14.5 12Z'
				fill='currentColor'
			/>
		</svg>
	);
};

export default Eye;
