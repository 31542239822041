import { HomeActionTypes } from 'constants/action-types.js';

const initialState = {
	isLeftNavigationExpanded: false,
	isRightPanelOpen: false,
	isFullscreen: false,
	isSwitchHealthSystemModalOpen: false,
	isRoomModalOpen: false,
	breadcrumb: [],
	allHealthSystems: [],
	treeData: {
		tree: [],
		preSelected: [],
	},
	isRoomsOnlyView: false,
	streamPermission: null,
	monitoredDevices: { inCallDevices: [], initiatedDevices: [] },
	isHealthSystemFetched: false,
	measurementAlertData: null,
};

export const healthSystems = (state = initialState, action) => {
	if (action.type === HomeActionTypes.SET_IS_HEALTH_SYSTEM_FETCHED) {
		return {
			...state,
			isHealthSystemFetched: action.payload,
		};
	}

	if (action.type === HomeActionTypes.UPDATE_MONITORING_DEVICES) {
		return { ...state, monitoredDevices: action.payload };
	}

	if (action.type === HomeActionTypes.UPDATE_BREADCRUMB) {
		return { ...state, breadcrumb: action.payload };
	}

	if (action.type === HomeActionTypes.SET_ALL_HEALTH_SYSTEMS) {
		return {
			...state,
			allHealthSystems: action.payload,
		};
	}
	if (action.type === HomeActionTypes.SET_STREAM_PERMISSION_MESSAGE) {
		return { ...state, streamPermission: action.payload };
	}

	if (action.type === HomeActionTypes.CREATE_NEW_HOSPITAL) {
		const newSection = state.treeData.tree.find(item => item.isNewOption);
		if (newSection) {
			return {
				...state,
			};
		}
		state.treeData.tree.push({
			icon: 'business',
			type: 'hospital',
			isNewOption: true,
			subOptions: [],
		});

		const newTreeData = state.treeData.tree.slice();
		return {
			...state,
			treeData: {
				tree: newTreeData,
			},
		};
	}
	if (action.type === HomeActionTypes.SET_TREE_DATA) {
		return { ...state, treeData: action.payload };
	}
	if (action.type === HomeActionTypes.SET_HEALTH_SYSTEM) {
		return { ...state, selectedHealthSystem: action.payload };
	}

	if (action.type === HomeActionTypes.TOGGLE_LEFT_NAVIGATION) {
		return {
			...state,
			isLeftNavigationExpanded: !state.isLeftNavigationExpanded,
		};
	}
	if (action.type === HomeActionTypes.TOGGLE_RIGHT_PANEL) {
		return {
			...state,
			isRightPanelOpen: !state.isRightPanelOpen,
		};
	}

	if (action.type === HomeActionTypes.TOGGLE_LEFT_NAVIGATION_VALUE) {
		return {
			...state,
			isLeftNavigationExpanded: action.payload,
		};
	}

	if (action.type === HomeActionTypes.TOGGLE_FULLSCREEEN) {
		return {
			...state,
			isFullscreen: !state.isFullscreen,
		};
	}

	if (action.type === HomeActionTypes.TOGGLE_IS_ROOMS_VIEW) {
		return {
			...state,
			isRoomsOnlyView: !state.isRoomsOnlyView,
		};
	}

	if (action.type === HomeActionTypes.TOGGLE_HEALTH_SYTEM_MODAL) {
		return {
			...state,
			isSwitchHealthSystemModalOpen: !state.isSwitchHealthSystemModalOpen,
		};
	}
	if (action.type === HomeActionTypes.SET_USER) {
		return { ...state, user: action.user };
	}
	if (action.type === HomeActionTypes.NEW_MEASUREMENT_DATA) {
		return { ...state, measurementAlertData: action.payload };
	}

	return state;
};
