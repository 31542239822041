import React from 'react';

const PatientDetailCell = ({ firstLabel, firstValue, secondLabel = null, secondValue = null }) => (
	<div className='flex patient-data'>
		<div className='semi-width'>
			<span>{firstLabel}</span>
			<span className='font-14'>{firstValue}</span>
		</div>
		<div className='semi-width'>
			<span>{secondLabel}</span>
			<span className='font-14'>{secondValue || 'N/A'}</span>
		</div>
	</div>
);

export default PatientDetailCell;
