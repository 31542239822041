import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useSelector } from 'react-redux';
import translate from 'i18n-translations/translate.jsx';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import { getCompanyId } from 'infrastructure/auth.js';
import { editPatientProfile } from 'api/patients.js';
import PatientDetailsForm from 'components/CheckInAPatient/PatientDetailsForm.jsx';
import {
	AddEditPatientRadioTypes,
	AlertTypes,
	MeasurementUnitOptions,
	PatientAgeLimit,
	UnitCategoryTypes,
} from 'constants/enums.js';
import { Allergies } from 'constants/visitEnums.js';
import { updatePatientDeviceOwner } from 'api/devices.js';
import { convertFeetToCm, convertImperialToMetric, convertInchToCm } from 'infrastructure/helpers/measurementsHelper.js';
import { findSectorById, getStorage } from 'infrastructure/helpers/commonHelpers.js';
import PopUpAlert from 'components/PopUpAlert.jsx';

const EditPatientProfile = props => {
	const intl = useIntl();
	const genders = [
		{ id: 1, name: intl.formatMessage({ id: 'male' }) },
		{ id: 2, name: intl.formatMessage({ id: 'female' }) },
	];
	const [error, setError] = useState(null);
	const [isGenderOpen, setIsGenderOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const genderWrapperRef = useRef(null);
	const [patientExistsError, setPatientExistsError] = useState(null);
	const companyId = getCompanyId();
	const [birthDateError, setBirthDateError] = useState(null);
	const [foodAllergies, setFoodAllergies] = useState([]);
	const [medicationAllergies, setMedicationAllergies] = useState([]);
	const [environmentalAllergies, setEnvironmentalAllergies] = useState([]);
	const [biologicalAllergies, setBiologicalAllergies] = useState([]);
	const helloDeviceId = parseInt(getStorage().getItem('helloDeviceId'), 10);
	const tree = useSelector(state => state.healthSystems.treeData.tree);
	const [alreadyOnCallMessage, setAlreadyOnCallMessage] = useState(null);

	const getConvertedValueForSubmit = (unit, defaultUnit, value, convertFunction) =>
		unit === defaultUnit || !value ? value.toString() : convertFunction;

	useEffect(() => {
		props.patientDetails.allergies.forEach(allergy => {
			if (allergy.categoryId === Allergies.FOOD.id && allergy.note) {
				setFoodAllergies(JSON.parse(allergy.note));
			}
			if (allergy.categoryId === Allergies.MEDICATION.id && allergy.note) {
				setMedicationAllergies(JSON.parse(allergy.note));
			}
			if (allergy.categoryId === Allergies.ENVIRONMENTAL.id && allergy.note) {
				setEnvironmentalAllergies(JSON.parse(allergy.note));
			}
			if (allergy.categoryId === Allergies.BIOLOGICAL.id && allergy.note) {
				setBiologicalAllergies(JSON.parse(allergy.note));
			}
		});
	}, []);

	const getConditionalHealthInformationProperties = (
		initialValues,
		values,
		tobaccoSmokeCode,
		showTobaccoSmokeCode,
		allergies
	) => ({
		isTobaccoSmoker: parseInt(values.isTobaccoSmoker, 10),
		hasDiabet: parseInt(values.hasDiabet, 10),
		hasHyperTension: parseInt(values.hasHyperTension, 10),
		hasAllergy: parseInt(values.hasAllergy, 10),
		hasPreExistingCondition: parseInt(values.hasPreExistingCondition, 10),
		isTakingMedication: parseInt(values.isTakingMedication, 10),
		...(initialValues.height.toString() !== values.height.toString() && {
			height: getConvertedValueForSubmit(
				values.heightUnit,
				MeasurementUnitOptions.HEIGHT.value[1]?.text,
				values.height,
				convertFeetToCm(parseInt(values.height, 10)).toString()
			),
		}),
		...(initialValues.height.toString() !== values.height.toString() && {
			heightUnit: 'cm',
		}),
		...(initialValues.weight.toString() !== values.weight.toString() && {
			weight: getConvertedValueForSubmit(
				values.weightUnit,
				MeasurementUnitOptions.WEIGHT.value[1]?.text,
				values.weight,
				convertImperialToMetric(UnitCategoryTypes.WEIGHT, parseFloat(values.weight)).toString()
			),
		}),
		...(initialValues.weight.toString() !== values.weight.toString() && {
			weightUnit: 'kg',
		}),
		...(initialValues.totalCholesterol.toString() !== values.totalCholesterol.toString() && {
			totalCholesterol: getConvertedValueForSubmit(
				values.totalCholesterolUnit,
				MeasurementUnitOptions.BLOOD_GLUCOSE.value[1]?.text,
				values.totalCholesterol,
				convertImperialToMetric(UnitCategoryTypes.BLOOD_GLUCOSE, parseFloat(values.totalCholesterol)).toString()
			),
		}),
		...(initialValues.totalCholesterol.toString() !== values.totalCholesterol.toString() && {
			totalCholesterolUnit: 'mmol/L',
		}),
		...(initialValues.hdlCholesterol.toString() !== values.hdlCholesterol.toString() && {
			hdlCholesterol: getConvertedValueForSubmit(
				values.hdlCholesterolUnit,
				MeasurementUnitOptions.BLOOD_GLUCOSE.value[1]?.text,
				values.hdlCholesterol,
				convertImperialToMetric(UnitCategoryTypes.BLOOD_GLUCOSE, parseFloat(values.hdlCholesterol)).toString()
			),
		}),
		...(initialValues.hdlCholesterol.toString() !== values.hdlCholesterol.toString() && {
			hdlCholesterolUnit: 'mmol/L',
		}),
		...(initialValues.waistCircumference.toString() !== values.waistCircumference.toString() && {
			waistCircumference: getConvertedValueForSubmit(
				values.waistCircumferenceUnit,
				MeasurementUnitOptions.WAIST_CIRCUMFERENCE.value[1]?.text,
				values.waistCircumference,
				convertInchToCm(parseFloat(values.waistCircumference)).toString()
			),
		}),
		...(initialValues.waistCircumference.toString() !== values.waistCircumference.toString() && {
			waistCircumferenceUnit: 'cm',
		}),
		...(showTobaccoSmokeCode && {
			tobaccoSmokeCode,
		}),
		...((initialValues.diabeticStatusCode.toString() !== values.diabeticStatusCode.toString() ||
			initialValues.hasDiabet.toString() !== values.hasDiabet.toString()) && {
			diabeticStatusCode: parseInt(values.hasDiabet, 10) === AddEditPatientRadioTypes.YES ? values.diabeticStatusCode : '',
		}),
		allergies,
		preExistingCondition:
			parseInt(values.hasPreExistingCondition, 10) === AddEditPatientRadioTypes.YES ? values.preExistingCondition?.trim() : '',
	});

	const getDateOfBirth = values => {
		if (!values.birthMonth || !values.birthYear) {
			return null;
		}
		const month = moment(values.birthMonth).format('MM');
		const year = moment(values.birthYear).format('YYYY');
		let day = values.birthDay.toString();
		if (day.length === 1) {
			day = `${0}${day}`;
		}
		return `${year}-${month}-${day}`;
	};

	const onSubmitForm = async (values, resetForm, initialValues) => {
		setPatientExistsError(null);
		setError(null);
		props.setIsSecondTabEnabled(false);
		if (!moment(getDateOfBirth(values)).isValid() || moment(getDateOfBirth(values)).diff(moment(), 'days') >= 0) {
			setBirthDateError(intl.formatMessage({ id: 'dateOfBirthNotValid' }));
			return;
		}
		if (moment().diff(getDateOfBirth(values), 'years') > PatientAgeLimit.MAX) {
			setBirthDateError(intl.formatMessage({ id: 'maxBirthdayLimit' }));
			return;
		}
		setBirthDateError(null);
		let tobaccoSmokeCode = '';
		let showTobaccoSmokeCode = false;
		if (parseInt(values.isTobaccoSmoker, 10) !== parseInt(initialValues.isTobaccoSmoker, 10)) {
			showTobaccoSmokeCode = true;
		}
		if (parseInt(values.isTobaccoSmoker, 10) === AddEditPatientRadioTypes.YES) {
			tobaccoSmokeCode = values.tobaccoYesCode;
			if (initialValues.tobaccoYesCode !== values.tobaccoYesCode) {
				showTobaccoSmokeCode = true;
			}
		}
		if (parseInt(values.isTobaccoSmoker, 10) === AddEditPatientRadioTypes.NO) {
			tobaccoSmokeCode = values.tobaccoNoCode;
			if (initialValues.tobaccoNoCode !== values.tobaccoNoCode) {
				showTobaccoSmokeCode = true;
			}
		}
		if (parseInt(values.isTobaccoSmoker, 10) === AddEditPatientRadioTypes.NO_ANSWER) {
			tobaccoSmokeCode = '';
		}

		const allergies = [];
		if (values.allergies?.length > 0) {
			values.allergies.forEach(element => {
				let note = '';
				if (parseInt(element, 10) === Allergies.FOOD.id && foodAllergies.length > 0) {
					const foodArr = [...foodAllergies];
					note = JSON.stringify(foodArr);
				}
				if (parseInt(element, 10) === Allergies.MEDICATION.id && medicationAllergies.length > 0) {
					const medicArr = [...medicationAllergies];
					note = JSON.stringify(medicArr);
				}
				if (parseInt(element, 10) === Allergies.ENVIRONMENTAL.id && environmentalAllergies.length > 0) {
					const environmentalArr = [...environmentalAllergies];
					note = JSON.stringify(environmentalArr);
				}
				if (parseInt(element, 10) === Allergies.BIOLOGICAL.id && biologicalAllergies.length > 0) {
					const biologicalArr = [...biologicalAllergies];
					note = JSON.stringify(biologicalArr);
				}
				allergies.push({
					categoryId: parseInt(element, 10),
					note,
				});
			});
		}

		const params = {
			companyId,
			patientUserId: props.userId,
			id: props.selectedPatientId,
			active: true,
			idCard: values.idNumber.toString(),
			firstName: values.firstName.trim(),
			lastName: values.lastName.trim(),
			address: values.address,
			phoneNumber: values.phoneNumber?.toString(),
			email: values.emailAddress,
			genderId: values.gender.id,
			birthDate: getDateOfBirth(values) || '',
			showConsent: values.showConsent,
			healthInformation: getConditionalHealthInformationProperties(
				initialValues,
				values,
				tobaccoSmokeCode,
				showTobaccoSmokeCode,
				allergies
			),
			postalCode: values.zipCode?.toString(),
			city: values?.city,
			country: values?.country,
			communicationLanguage: null,
		};
		if (params.healthInformation?.allergies?.some(item => !item.note)) {
			return;
		}
		setIsLoading(true);
		const response = await editPatientProfile(params);
		if (response.error) {
			const patientExistsErrorStatusCode = 1009;
			const responseData = response.error.response.data;
			if (responseData.code === patientExistsErrorStatusCode) {
				setPatientExistsError(responseData.message);
			} else {
				setError(`${response.error.message}`);
			}
			setIsLoading(false);
			return;
		}
		const selectedPatientId = getStorage().getItem('patientId');
		if (selectedPatientId === props.patientDetails.userId) {
			props.setSelectedPatient(params);
		}
		const deviceParams = {
			userId: props.userId,
			deviceName: '',
			deviceId: +getStorage().getItem('helloDeviceId'),
		};
		const room = findSectorById(tree, helloDeviceId);
		if (room?.deviceBusy) {
			setAlreadyOnCallMessage(intl.formatMessage({ id: 'youAreAlreadyOnCall' }));
			return;
		}
		const deviceOwner = await updatePatientDeviceOwner(deviceParams);
		if (deviceOwner.error || !deviceOwner.hasSucceeded) {
			const errorMessage = deviceOwner.error?.message || deviceOwner?.message;
			setError(errorMessage);
			return;
		}
		setPatientExistsError(null);
		setIsLoading(false);
		props.hidePatientForm();
		props.setIsSecondTabEnabled(true);
		resetForm();
	};

	useOutsideClick(genderWrapperRef, () => {
		if (isGenderOpen) {
			setIsGenderOpen(false);
		}
	});

	const toggleGenderDropDown = (item, formikProps) => {
		formikProps.setFieldValue('gender', item);
		setIsGenderOpen(false);
	};

	return (
		<>
			{props.display && (
				<PatientDetailsForm
					isLoading={isLoading}
					title={translate('editProfile')}
					genderWrapperRef={genderWrapperRef}
					setIsGenderOpen={() => setIsGenderOpen(prevState => !prevState)}
					isGenderOpen={isGenderOpen}
					genders={genders}
					error={error}
					patientExistsError={patientExistsError}
					isAddPatient={false}
					hidePatientForm={props.hidePatientForm}
					birthDateError={birthDateError}
					submitButtonText={intl.formatMessage({ id: 'saveAndSelectPatient' })}
					countries={props.countries}
					foodAllergies={foodAllergies}
					medicationAllergies={medicationAllergies}
					getDateOfBirth={getDateOfBirth}
					environmentalAllergies={environmentalAllergies}
					biologicalAllergies={biologicalAllergies}
					setFoodAllergies={setFoodAllergies}
					setMedicationAllergies={setMedicationAllergies}
					setEnvironmentalAllergies={setEnvironmentalAllergies}
					setBiologicalAllergies={setBiologicalAllergies}
					setBirthDateError={setBirthDateError}
					patientDetails={props.patientDetails}
					toggleGenderDropDown={toggleGenderDropDown}
					onSubmitForm={onSubmitForm}
					healthInformationStatusList={props.healthInformationStatusList}
					tobaccoCode={props.tobaccoCode}
					diabeticCode={props.diabeticCode}
					isEditProfile={true}
				/>
			)}
			<PopUpAlert
				alertType={AlertTypes.DANGER}
				display={alreadyOnCallMessage}
				onAlertClose={() => setAlreadyOnCallMessage(null)}
				contentText={alreadyOnCallMessage}
				isSilent={true}
				center={true}
			/>
		</>
	);
};

export default EditPatientProfile;
