import { useContext, useEffect, useState } from 'react';
import { Modal, Button } from 'calls/components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { useConference, useConferenceConfigurations, useLocalParticipant } from 'calls/hooks/index.js';
import { ButtonType } from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import styled from 'styled-components';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';

const StyledInvitedParticipants = styled.div`
	div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		button {
			padding: 4px 16px !important;
		}
		p {
			margin: 0;
			padding: 0;
		}
	}
	& > * + * {
		padding-top: var(--spacing-xl);
	}
`;

/**
 * @param {object} props
 * @param {(tab) => void} props.setTab
 */
const SendInvitesView = ({ setTab }) => {
	const tabNames = {
		FAMILY_FRIENDS: 1,
		ADD_PEOPLE: 2,
		TRANSLATION_SERVICES: 3,
		SENT_INVITES: 4,
	};
	const conference = useConference();
	const socket = useContext(SocketContext);
	const localParticipant = useLocalParticipant();
	const conferenceConfigs = useConferenceConfigurations();
	const socketFunctions = useContext(SocketFunctionsContext);
	const [isCancelInviteDisabled, setIsCancelInviteDisabled] = useState(false);

	useEffect(() => {
		const getInvitationLinks = async () => {
			const response = await socket.emitWithAck(SocketEvents.Conference.GET_INVITATION_LINKS, {
				conferenceId: conference.conferenceId,
				participantId: localParticipant.id,
			});
			conferenceConfigs.setInvitedParticipants(response);
		};

		getInvitationLinks();
	}, []);

	const removeInvitationLink = async links => {
		setIsCancelInviteDisabled(true);
		const response = await socketFunctions.removeInvitationLinks({
			conferenceId: conference.conferenceId,
			participantId: localParticipant.id,
			links,
		});
		if (response.ok) {
			setIsCancelInviteDisabled(false);
			const invitedParticipantsResponse = await socket.emitWithAck(SocketEvents.Conference.GET_INVITATION_LINKS, {
				conferenceId: conference.conferenceId,
				participantId: localParticipant.id,
			});
			conferenceConfigs.setInvitedParticipants(invitedParticipantsResponse);
			if (invitedParticipantsResponse.links?.length === 0) {
				setTab(tabNames.ADD_PEOPLE);
			}
		}
	};

	return (
		<>
			<Modal.Content>
				<StyledInvitedParticipants>
					{conferenceConfigs.invitedParticipants.links?.map(invitedParticipant => (
						<div key={invitedParticipant.destination}>
							<p>{invitedParticipant.destination}</p>
							<Button
								disabled={isCancelInviteDisabled}
								type='button'
								variant={ButtonType.CANCEL}
								onClick={() =>
									removeInvitationLink([
										{ destination: invitedParticipant.destination, invitationSecret: invitedParticipant.invitationSecret },
									])
								}>
								{translate('cancelInvite')}
							</Button>
						</div>
					))}
				</StyledInvitedParticipants>
			</Modal.Content>
			<Modal.Actions>
				<Button
					disabled={isCancelInviteDisabled}
					type='submit'
					variant={ButtonType.SUBMIT}
					onClick={() => removeInvitationLink(conferenceConfigs.invitedParticipants.links)}>
					{translate('cancelAllInvites')}
				</Button>
			</Modal.Actions>
		</>
	);
};

export default SendInvitesView;
