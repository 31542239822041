export const METADATA_URL = {};
export const METADATA_COMPANY = {};
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
	METADATA_URL.gatewayApiBasePath = process.env.REACT_APP_GATEWAY_API_BASE_URL;
	METADATA_URL.legacyApiBasePath = process.env.REACT_APP_LEGACY_API_BASE_URL;
	METADATA_URL.localApiBasePath = process.env.REACT_APP_SERVER_PUBLIC_URL;
	METADATA_URL.messengerBasePath = process.env.REACT_APP_MESSENGER_BASE_URL;
	METADATA_URL.signalingUrl = process.env.REACT_APP_SIGNALING_URL;
	METADATA_URL.epicUrl = process.env.REACT_APP_EPIC_URL;
	METADATA_COMPANY.companyId = process.env.REACT_APP_COMPANY_ID;
	METADATA_URL.teamBotApiBasePath = process.env.REACT_APP_TEAM_BOT;
} else {
	METADATA_URL.gatewayApiBasePath = window.__env__.REACT_APP_GATEWAY_API_BASE_URL;
	METADATA_URL.legacyApiBasePath = window.__env__.REACT_APP_LEGACY_API_BASE_URL;
	METADATA_URL.localApiBasePath = window.__env__.REACT_APP_PUBLIC_URL;
	METADATA_URL.messengerBasePath = window.__env__.REACT_APP_MESSENGER_BASE_URL;
	METADATA_URL.signalingUrl = window.__env__.REACT_APP_SIGNALING_URL;
	METADATA_URL.epicUrl = window.__env__.REACT_APP_EPIC_URL;
	METADATA_COMPANY.companyId = window.__env__.REACT_APP_COMPANY_ID;
	METADATA_URL.teamBotApiBasePath = window.__env__.REACT_APP_TEAM_BOT;
}

export const CompanyId = METADATA_COMPANY.companyId || '';
export const GatewayApiBasePath = METADATA_URL.gatewayApiBasePath || 'https://api.dev.solaborate.com';
export const MessengerBasePath = METADATA_URL.messengerBasePath || 'https://messenger.dev.solaborate.com';
export const SignalingServerAddress = METADATA_URL.signalingUrl || 'https://signaling.dev.solaborate.com';
export const FitBitApiBasePath = 'https://api.fitbit.com';
export const ClinicaltablesApiBasePath = 'https://clinicaltables.nlm.nih.gov/api';
export const LegacyApiBasePath = METADATA_URL.legacyApiBasePath || 'https://mobile.dev.solaborate.com';
export const MobileApiBasePath = `${METADATA_URL.legacyApiBasePath}/`;
export const LocalApiBasePath = METADATA_URL.localApiBasePath || 'https://localhost:5001';
export const TeamBotApiBasePath = METADATA_URL.teamBotApiBasePath || 'https://bot.dev.solaborate.com';

export const EpicApiBasePath = METADATA_URL.epicUrl;
