const UserTypes = {
	DOCTOR: 1,
	NURSE: 2,
	PATIENT: 3,
	ADMIN: 4,
	SUPER_USER: 5,
	SUPER_ADMIN: 6,
	OWNER: 7,
	DIGITAL_CLINICIAN: 8,
	FAMILY_MEMBER: 9,
	GUEST: 99,
};

export default UserTypes;
