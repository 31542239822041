import moment from 'moment';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { validateMaxLength } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import ProfilePicture from 'components/AccountSettings/PatientProfile/ProfilePicture.jsx';
import { Input } from 'components/index.js';

const genders = [
	{ value: 1, label: translate('male') },
	{ value: 2, label: translate('female') },
];

const PatientPersonalInformation = props => {
	const intl = useIntl();
	const getValue = () => genders.filter(option => option.value === props.values.genderId);

	return (
		<div className='flex as-category'>
			<div className='as-profile-picture flex'>
				<p className='label'>{translate('profilePicture')}</p>
				<ProfilePicture
					profilePicture={props.profilePicture}
					setProfilePicture={props.setProfilePicture}
					setError={props.setError}
				/>
			</div>
			<div className='as-fields'>
				<Input
					label={translate('firstName')}
					type='text'
					placeholder={intl.formatMessage({ id: 'firstName' })}
					maxLength={30}
					value={props.values.firstName}
					onChange={props.onChange}
					name='firstName'
					error={props.errors.firstName}
				/>
				<Input
					label={translate('lastName')}
					type='text'
					placeholder={intl.formatMessage({ id: 'lastName' })}
					maxLength={30}
					value={props.values?.lastName}
					onChange={props.onChange}
					name='lastName'
					error={props.errors.lastName}
				/>
				<Input
					label={translate('idNumber')}
					type='number'
					placeholder={intl.formatMessage({ id: 'idNumber' })}
					maxLength={30}
					value={props.values.idNumber}
					onChange={props.onChange}
					name='idNumber'
					className='number-input-wo-arrows'
					onKeyDown={event => validateMaxLength(event, 10)}
					error={props.errors.idNumber}
				/>
				<Input
					label={translate('birthDate')}
					type='date'
					placeholder={intl.formatMessage({ id: 'selectDate' })}
					value={props.values.birthDate}
					onChange={props.onChange}
					name='birthDate'
					max={moment().format('YYYY-MM-DD')}
					error={props.errors.birthDate}
				/>
				<div className='input'>
					<p className='label'>{translate('sex')}</p>
					<Select
						value={getValue()}
						placeholder={intl.formatMessage({ id: 'sex' })}
						classNamePrefix='react-select'
						options={genders}
						onChange={event => props.formikProps?.setFieldValue('genderId', event.value)}
						isSearchable
					/>
					<span className='red-error'>{props.errors.genderId}</span>
				</div>
			</div>
		</div>
	);
};
export default PatientPersonalInformation;
