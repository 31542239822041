const spacingBase = 4;
const typographyBase = 16;
const typographyScale = 1.25;

const LightTheme = {
	avatar: {
		sizes: {
			1: 24,
			2: 32,
			3: 48,
			4: 64,
			5: 128,
		},
	},
	sizes: {
		1: 24,
		2: 32,
		3: 48,
		4: 64,
		5: 128,
	},
	colors: {
		// Light Theme
		grayZero: '#ffffff',
		grayZero075: 'rgba(255, 255, 255, 0.75)',
		grayZero06: 'rgba(255, 255, 255, 0.6)',
		grayOne: '#f5f6fa',
		grayTwo: '#dcdde1',
		grayThree: '#7f8fa6',
		grayFour: '#718093',
		grayFive: '#353b48',
		graySix: '#2f3640',
		graySeven: '#3C4257',
		grayEight: '#838A94',
		grayNine: '#1818250D',
		grayTen: '#343434',
		grayTen02: '#3434340d',
		grayEleven: '#555555',
		grayNinetyEight: '#FAFAFA',
		grayFourteen: '#242424',
		blueOne: '#5390FF',
		blueOne05: 'rgba(83, 144, 255, 0.05)',
		blueTwo: '#0097e6',
		blueThree: '#0153b6',
		blueFour: '#5390FF',
		blueFour08: 'rgba(83, 144, 255, 0.8)',
		blueFive: '#5390FF1A',
		blueSix: ' #A8C7FF',
		blueSeven: '#247EF9',
		blueLightOne: '#B5CDFA',
		greenLightOne: '#b5e2da',
		greenTwo: '#33C27F',
		greenTwo08: 'rgba(51, 194, 127, 0.8)',
		greenThree: '#33C27F1A',
		greenFour: '#b2e097',
		redOne: '#E84118',
		purpleOne: '#bf5bf1',
		purpleTwo: '#d28db9',
		purpleTwo08: 'rgba(210, 141, 185, 0.8)',
	},
	spacing: {
		1: spacingBase,
		2: spacingBase * 2,
		3: spacingBase * 3,
		4: spacingBase * 4,
		5: spacingBase * 5,
		6: spacingBase * 6,
		7: spacingBase * 8,
		8: spacingBase * 10,
		9: spacingBase * 20,
		10: spacingBase * 40,
		11: spacingBase * 44,
		12: spacingBase * 55,
	},
	typography: {
		h1: typographyBase * typographyScale * 6,
		h2: typographyBase * typographyScale * 5,
		h3: typographyBase * typographyScale * 4,
		h4: typographyBase * typographyScale * 3,
		h5: typographyBase * typographyScale * 1.5,
		h6: typographyBase + 2.5,
		p: typographyBase,
		small: typographyBase - 2.5,
	},
	borderRadius: {
		base: 0,
		buttons: 4,
		avatar: 100,
	},
};

export default LightTheme;
