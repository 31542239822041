import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import Calendar from 'react-calendar';
import moment from 'moment';
import CreatableSelect from 'react-select/creatable';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { Button, Grid, Loader } from 'components';
import translate from 'i18n-translations/translate.jsx';
import { getCalendarClass, getPhysicalExerciseIcon } from 'infrastructure/helpers/physicalExerciseHelper';
import { AlertTypes, HttpStatusCodes, IntensityTypes, PhysicalExerciseTabs } from 'constants/enums.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { editExercise } from 'api/physicalExercises';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';

const EditPhysicalExercise = props => {
	const intl = useIntl();
	const [error, setError] = useState(null);
	const [selectedDate, setSelectedDate] = useState(new Date(props.selectedExercise.date));
	const selectedCategory = props.selectedExercise.category;
	const [intensities, setIntensities] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [validationError, setValidationError] = useState(null);
	const [selectedIntensityType, setSelectedIntensityType] = useState(null);

	useEffect(() => {
		const fetchTypesOfExercise = () => {
			const selectedIntensities = props.categories.find(category => category.id === props.selectedExercise.category.id).types;
			const intensitiesPerType = selectedIntensities.find(intensity => intensity.id === props.selectedExercise.type.id)
				.settingsType;
			const intensityValues = intensitiesPerType.map(intensity => ({
				...intensity,
				selectedValue:
					props.selectedExercise.settingsExercise.find(exercise => exercise.type === intensity.type)?.value ??
					intensity.values[0],
			}));
			setIntensities(intensityValues);
		};
		fetchTypesOfExercise();
	}, [
		props.categories,
		props.selectedExercise.category.id,
		props.selectedExercise.settingsExercise,
		props.selectedExercise.type.id,
	]);

	const transformForSelect = array => array.map(item => ({ value: item.id, label: item.name, icon: item.type }));

	const transformIntensities = array =>
		array.map(item => ({
			value: item,
			label: item,
			selectedValue: item,
		}));

	const editData = async () => {
		setIsLoading(true);
		const intensitiesToSend = intensities.map(item => ({ type: item.type, value: item?.selectedValue ?? item.values[0] }));
		const dataToSend = {
			typeId: props.selectedExercise.type.id,
			categoryId: props.selectedExercise.category.id,
			settingsExercise: intensitiesToSend,
			date: moment(selectedDate).format('MM/DD/YYYY'),
		};
		const response = await editExercise(props.selectedExercise.id, dataToSend, props.physicalTherapyId);
		if (response.error) {
			const errorMessage =
				response.error.response.status === HttpStatusCodes.CONFLICT
					? response.error.response.data.message
					: response.error.message;
			setError(errorMessage);
		} else {
			props.fetchExercises(response.item.type);
		}
		setIsLoading(false);
		props.setStep(PhysicalExerciseTabs.EXERCISE_DETAILS);
		props.setSelectedExercise(null);
	};

	const changeSelectedIntensityValue = (event, intensity) => {
		setIntensities(prevState => {
			const newIntensities = [...prevState];
			const selectedIntensity = newIntensities.find(item => item.type === intensity.type);
			selectedIntensity.selectedValue = selectedIntensity.values.find(val => val === +event) || +event;
			return newIntensities;
		});
		setValidationError(null);
	};

	const formatOptionLabel = ({ label }) => {
		const categoryType = props.selectedExercise.category.type;
		return (
			<div className='flex flex-align-center'>
				<div className='physical-exercise-category'>
					<img
						src={`${healthCareCdnUrl}physical-exercises/${getPhysicalExerciseIcon(categoryType, true)}.svg?v2`}
						alt={categoryType}
					/>
				</div>
				<div>{label}</div>
			</div>
		);
	};

	const cancelEdit = () => {
		props.setStep(PhysicalExerciseTabs.EXERCISE_DETAILS);
		props.setSelectedExercise(null);
	};

	const validateIntensityValues = (event, intensity) => {
		const { value } = event.target;
		const numValue = parseInt(value, 10);
		const intensityValues = intensity.values;
		const minValue = intensity.type === IntensityTypes.MAX_RANGE_OF_MOTION ? 0 : intensityValues.reduce((a, b) => Math.min(a, b));
		const maxValue =
			intensity.type === IntensityTypes.MAX_RANGE_OF_MOTION ? 100 : intensityValues.reduce((a, b) => Math.max(a, b));
		setSelectedIntensityType(intensity.type);
		if (/^[0-9]*$/.test(value) && numValue >= minValue && numValue <= maxValue) {
			setValidationError(null);
			return;
		}
		if (numValue < minValue || numValue > maxValue) {
			setValidationError(
				translate('rangeExceeded', {
					value1: minValue,
					value2: maxValue,
				})
			);
			return;
		}
		setValidationError(translate('containOnlyNumbers'));
	};

	return (
		<>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div className='text-align-center'>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading && (
				<>
					<div className='flex'>
						<div>
							<h4>{translate('editExercise')}</h4>
						</div>
					</div>
					<div className='physical-exercise-wrapper edit'>
						<div className='top-30'>
							<label htmlFor='category'>*{translate('category')}</label>
							<Select
								isDisabled={true}
								options={transformForSelect(props.categories)}
								value={[
									{ label: intl.formatMessage({ id: stringToCamelCase(selectedCategory.name) }), value: selectedCategory.id },
								]}
								placeholder={intl.formatMessage({ id: 'selectCategory' })}
								classNamePrefix='react-select'
								name='selectedCategory'
								formatOptionLabel={formatOptionLabel}
							/>
						</div>

						<div className='top-30'>
							<label htmlFor='exercise'>*{translate('exercise')}</label>
							<Select
								isDisabled={true}
								options={transformForSelect(
									props.categories.find(category => category.id === props.selectedExercise.category.id).types
								)}
								value={[
									{
										label: intl.formatMessage({ id: stringToCamelCase(props.selectedExercise?.type?.name) }),
										value: props.selectedExercise?.type?.id,
									},
								]}
								placeholder={intl.formatMessage({ id: 'selectTypeOfExercise' })}
								classNamePrefix='react-select'
								name='category'
							/>
						</div>

						<div>
							<p className='exercise-intensity'>{translate('regulateExerciseIntensity')}</p>
							<div style={{ marginTop: '30px' }}>
								{intensities.map(intensity => (
									<>
										<div className='flex flex-space-between flex-align-center'>
											<h5>{intl.formatMessage({ id: stringToCamelCase(intensity.name) })}</h5>
											<CreatableSelect
												isClearable={false}
												options={transformIntensities(intensity.values)}
												onChange={event => {
													changeSelectedIntensityValue(event.value, intensity);
												}}
												defaultValue={[{ label: intensity.selectedValue, value: intensity.selectedValue }]}
												isDisabled={intensity.type === IntensityTypes.DAYS_TO_ACHIEVE_TARGET}
												className='exercise-intensity-values'
												classNamePrefix='react-select width-20'
												name='selectedIntensity'
												onKeyDown={event => validateIntensityValues(event, intensity)}
											/>
										</div>
										<div className='exercise-validation'>
											<span className='red-error'>{selectedIntensityType === intensity.type && validationError}</span>
										</div>
									</>
								))}
							</div>
							<div>
								<h5 className='margin-bottom-0'>{translate('selectDatesForExercise')}</h5>
								<Calendar
									className='appointment-calendar'
									formatShortWeekday={(locale, value) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][value.getDay()]}
									calendarType='US'
									onChange={date => {
										setSelectedDate(date);
									}}
									minDate={new Date()}
									value={selectedDate}
									tileClassName={({ date }) => getCalendarClass(date, selectedDate)}
									prev2Label={null}
									next2Label={null}
								/>
							</div>
						</div>
					</div>
					<div className='flex'>
						<Button
							onClick={cancelEdit}
							type='button'
							text={intl.formatMessage({ id: 'cancel' })}
							className='button small white top-30'
						/>
						<Button
							type='button'
							onClick={editData}
							text={intl.formatMessage({ id: 'save' })}
							className='button small top-30'
							isDisabled={validationError}
						/>
					</div>
					<PopUpAlert
						alertType={AlertTypes.DANGER}
						display={error}
						onAlertClose={() => setError(null)}
						contentText={error}
						isSilent={true}
						center={true}
					/>
				</>
			)}
		</>
	);
};

export default EditPhysicalExercise;
