import { useIntl } from 'react-intl';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';

const PatientAdmissionTypes = ({ handleChange, patientAdmission }) => {
	const intl = useIntl();

	return (
		<>
			<h3>
				{translate('patient')} {translate('admissions')}*
			</h3>
			<div className='flex checkbox'>
				<div>
					<img src={`${healthCareCdnUrl}admin/super-admin/view-patient.svg`} alt='icon' />
				</div>
				<label htmlFor='virtualAdmission'>
					{intl.formatMessage({ id: 'virtualPatient' })} {intl.formatMessage({ id: 'admissions' })}
				</label>
				<input
					type='checkbox'
					name='virtualAdmission'
					value={JSON.parse(patientAdmission.virtualAdmission)}
					checked={JSON.parse(patientAdmission.virtualAdmission)}
					onChange={handleChange}
				/>
			</div>
			<div className='flex checkbox'>
				<div>
					<img src={`${healthCareCdnUrl}admin/super-admin/view-patient.svg`} alt='icon' />
				</div>
				<label htmlFor='manualAdmission'>
					{intl.formatMessage({ id: 'manualPatient' })} {intl.formatMessage({ id: 'admissions' })}
				</label>
				<input
					type='checkbox'
					name='manualAdmission'
					value={JSON.parse(patientAdmission.manualAdmission)}
					checked={JSON.parse(patientAdmission.manualAdmission)}
					onChange={handleChange}
				/>
			</div>
			<div className='flex checkbox'>
				<div>
					<img src={`${healthCareCdnUrl}admin/super-admin/view-patient.svg`} alt='icon' />
				</div>
				<label htmlFor='ehrAdmission'>
					{intl.formatMessage({ id: 'ehr' })} {intl.formatMessage({ id: 'admissions' })}
				</label>
				<input
					type='checkbox'
					name='ehrAdmission'
					value={JSON.parse(patientAdmission.ehrAdmission)}
					checked={JSON.parse(patientAdmission.ehrAdmission)}
					onChange={handleChange}
				/>
			</div>
		</>
	);
};

export default PatientAdmissionTypes;
