import { UserRoles } from 'constants/enums.js';
import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';

export const getRoom = async ({ healthSystemId, hospitalId, departmentId, floorId, roomId }) => {
	try {
		const url = `/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateRoom = async ({ healthSystemId, hospitalId, departmentId, floorId, roomId, params }) => {
	try {
		const apiVersion = [UserRoles.NURSE, UserRoles.DOCTOR].includes(getUserRole()) ? 'v1.1' : 'v1.3';
		const url = `/${apiVersion}/organizations/${getCompanyId()}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}`;
		const { data } = await gatewayApi.put(url, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const createRoom = async ({ companyId, healthSystemId, hospitalId, departmentId, floorId, name }) => {
	try {
		const url = `/v1/organizations/${companyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms`;
		const { data } = await gatewayApi.post(url, {
			name,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const createRoomInDepartment = async ({ companyId, healthSystemId, hospitalId, departmentId, name }) => {
	try {
		const url = `/v1.1/organizations/${companyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/rooms`;
		const { data } = await gatewayApi.post(url, {
			name,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getRooms = async (companyId, healthSystemId, hospitalId, floorId) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${companyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/floors/${floorId}/rooms`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getRoomConfiguration = async ({ companyId, healthSystemId, hospitalId, departmentId, floorId, roomId }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${companyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const saveRoomConfiguration = async ({ healthSystemId, hospitalId, departmentId, floorId, roomId, params }) => {
	try {
		const { data } = await gatewayApi.put(
			`v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/configurations`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHospitalRooms = async (companyId, healthSystemId, hospitalId) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${companyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/rooms`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getRoomLocation = async ({ healthSystemId, hospitalId, departmentId, floorId, roomId }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/locations`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateRoomLocation = async ({ healthSystemId, hospitalId, departmentId, floorId, roomId, params }) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/locations`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteRoomLocation = async ({ healthSystemId, hospitalId, departmentId, floorId, roomId }) => {
	try {
		const { data } = await gatewayApi.delete(
			`/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/locations`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDepartmentRooms = async (healthSystemId, departmentId) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/departments/${departmentId}/rooms`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
