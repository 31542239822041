import React, { useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import RemotePatientMonitoring from 'containers/Rpm/RemotePatientMonitoring.jsx';
import ContinuousPatientMonitoring from 'containers/Rpm/ContinuousPatientMonitoring.jsx';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import Button from 'components/Button.jsx';
import { useSelector } from 'react-redux';
import { GeneralAndMenuSettings } from 'constants/configurationEnums.js';
import { getRoleConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { Tab, TabList, Tabs } from 'components/Tabs.jsx';

const PatientMonitoring = () => {
	const [searchedPatient, setSearchedPatient] = useState('');
	const history = useHistory();
	const intl = useIntl();
	const customRoleConfigurations = useSelector(state => state.configurations.customRoleConfigurations);

	const PatientMonitoringTabs = [
		getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.RPM) && {
			id: GeneralAndMenuSettings.RPM,
			title: 'remotePatientMonitoring',
		},
		getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.CPM) && {
			id: GeneralAndMenuSettings.CPM,
			title: 'continuousPatientMonitoring',
		},
	].filter(Boolean);

	const [currentTab, setCurrentTab] = useState(PatientMonitoringTabs[0]?.id);

	const openRpmPatient = () => history.push('/rpm-patient-onboarding');

	return (
		<MainLayout showWarningAlert={true}>
			<div className='inner-main-view'>
				<div className='patient-monitoring-wrapper'>
					<Tabs activeIndex={currentTab} onChange={setCurrentTab} className='patient-monitoring-wrapper'>
						<TabList>
							{PatientMonitoringTabs.map(tab => (
								<Tab customIndex={tab.id} key={tab.id} className='rpm-tabs'>
									{translate(tab.title)}
								</Tab>
							))}
						</TabList>
					</Tabs>

					{currentTab === GeneralAndMenuSettings.RPM && (
						<div className='flex flex-space-between flex-align-center'>
							<Button className='flex' onClick={openRpmPatient} text={translate('selectPatient')} />
						</div>
					)}
					{currentTab === GeneralAndMenuSettings.CPM && (
						<div className='care-event-buttons'>
							<div className='flex'>
								<div className='input-wrapper'>
									<input
										type='text'
										placeholder={intl.formatMessage({ id: 'searchByName' })}
										value={searchedPatient}
										onChange={event => setSearchedPatient(event.target.value)}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
				{currentTab === GeneralAndMenuSettings.RPM && <RemotePatientMonitoring />}
				{currentTab === GeneralAndMenuSettings.CPM && <ContinuousPatientMonitoring searchedPatient={searchedPatient} />}
			</div>
		</MainLayout>
	);
};

export default PatientMonitoring;
