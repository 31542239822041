import Button from 'components/Button.jsx';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';

const QrCode = () => {
	const [step, setStep] = useState(0);
	const [QRCodeVal, setQRCodeVal] = useState('');
	const [security, setSecurity] = useState(0);
	const [hiddenNetwork, setHiddenNetwork] = useState(0);
	const [isWiredConnection, setIsWiredConnection] = useState(false);
	const [networkInfo, setNetworkInfo] = useState({
		network: '',
		password: '',
	});
	const [showPasswordText, setShowPasswordText] = useState(false);

	const showPassword = hiddenNetwork === 0 || (hiddenNetwork === 1 && security !== 0);
	const qrCodePageUrl = 'https://static.solaborate.com/scoped/pages/qrcode';

	return (
		<div className='qr-code-connect'>
			{step === 0 && (
				<main>
					<img src={`${qrCodePageUrl}/wifi-solaborate.svg`} alt='Wifi icon' />
					<h1>Step 1 - Connect</h1>
					<p>
						Please enter your WiFi credentials below so we can connect your device to your network. The WiFi network name &
						password are case sensitive. We will not save your credentials.
					</p>
					<form
						onSubmit={evt => {
							evt.preventDefault();
							if (!isWiredConnection) {
								setQRCodeVal(
									JSON.stringify({
										s: networkInfo.network,
										p: networkInfo.password,
										h: hiddenNetwork,
										...(hiddenNetwork === 1 && { sc: security }),
									})
								);
							}
							setStep(old => old + 1);
						}}>
						<label>Wifi Network</label>
						<input
							type='text'
							placeholder='Enter Wifi Network'
							name='wifiNetwork'
							onChange={evt =>
								setNetworkInfo({
									...networkInfo,
									network: evt.target.value,
								})
							}
							{...(!isWiredConnection && { required: true })}
						/>
						{showPassword && (
							<div>
								<label>Wifi Password</label>
								<input
									type={showPasswordText ? 'text' : 'password'}
									placeholder='Enter Wifi Password'
									name='wifiPassword'
									onChange={evt =>
										setNetworkInfo({
											...networkInfo,
											password: evt.target.value,
										})
									}
									{...(!isWiredConnection && { required: true })}
								/>
								<Button
									onClick={() => setShowPasswordText(old => !old)}
									icon={showPasswordText ? 'visibility' : 'visibility_off'}
								/>
							</div>
						)}
						<p>Is this a hidden network?</p>
						<section>
							<input type='radio' id='yes' name='network' value='1' onChange={evt => setHiddenNetwork(+evt.target.value)} />
							<label htmlFor='yes'>Yes</label>
							<input
								type='radio'
								id='no'
								name='network'
								value='0'
								onChange={evt => setHiddenNetwork(+evt.target.value)}
								defaultChecked
							/>
							<label htmlFor='no'>No</label>
						</section>
						{hiddenNetwork > 0 && (
							<>
								<label>Security</label>
								<select name='security' id='security' onChange={evt => setSecurity(+evt.target.value)}>
									<option value='0' selected>
										Open
									</option>
									<option value='-1'>WEP</option>
									<option value='1'>WPA</option>
									<option value='2'>WPA2</option>
									<option value='3'>WPA3</option>
								</select>
							</>
						)}
						<aside>
							<input type='checkbox' id='ethernet' name='ethernet' onChange={evt => setIsWiredConnection(evt.target.checked)} />
							<label htmlFor='ethernet'>Skip this step, I&apos;d prefer using Ethernet Cable for Internet Connection</label>
						</aside>
						<Button type='submit' text='Next' display='block' />
					</form>
				</main>
			)}
			{step === 1 && !isWiredConnection && (
				<main>
					<img src={`${qrCodePageUrl}/scan-solaborate.svg`} alt='Scan icon' />
					<h1>Step 2 - Scan QR Code</h1>
					<p>Please hold this QR code up to your device to complete set up.</p>
					<QRCode
						size={256}
						style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
						value={QRCodeVal}
						viewBox='0 0 256 256'
					/>
					<Button onClick={() => setStep(0)} text='Go back' display='block'/>
				</main>
			)}
			{step === 1 && isWiredConnection && (
				<main>
					<img src={`${qrCodePageUrl}/info-solaborate.svg`} width='72' height='72' alt='Done icon' />
					<h1>Wired Connection</h1>
					<p>Plug an ethernet cable into the Gigabit Ethernet port on the device. You may close this window.</p>
				</main>
			)}
		</div>
	);
};

export default QrCode;
