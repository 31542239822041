import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import LocalParticipant from 'calls/LocalParticipant.js';
import { Avatar, ParticipantVideo } from 'calls/components/index.js';
import { useConferenceConfigurations, useControllerTracks, useLocalParticipant } from 'calls/hooks/index.js';
import { buildProfilePic } from 'infrastructure/helpers/thumbnailHelper.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';

const VIDEO_ORIENTATION = {
	PORTRAIT: 'portrait',
	LANDSCAPE: 'landscape',
};

/**
 * @type {import('styled-components').StyledComponent<"main", any, { $top: number, $right: number, $isDarkMode: boolean, $isPortrait: boolean, }, never>}
 */
const StyledPictureInPictureCamera = styled.main.attrs(props => ({
	style: {
		top: `${props.$top || 0}px`,
		right: `${props.$right || 0}px`,
	},
}))`
	position: absolute;
	width: ${props => (props.$isPortrait ? 'auto' : '200px')};
	height: ${props => (props.$isPortrait ? '120px' : 'auto')};
	aspect-ratio: ${props => (props.$isPortrait ? '10 / 16' : '16 / 9')};
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${props => (props.$isDarkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayFourteen)};
	border-radius: var(--spacing-s);
	> video {
		border-radius: var(--spacing-s);
	}
`;

const PictureInPictureCameraView = ({ participant, activeTrackType }) => {
	const conferenceConfigs = useConferenceConfigurations();
	const localParticipant = useLocalParticipant();
	const tracks = useControllerTracks(
		participant instanceof LocalParticipant ? localParticipant.localTrackController : participant.remoteTrackController
	);
	const mainRef = useRef(null);
	const videoRef = useRef(null);
	const [loadingConference, setLoadingConference] = useState(true);
	const [participantDisplayedName, setParticipantDisplayedName] = useState(participant.alias || participant.name);
	const [isPortraitVideo, setIsPortraitVideo] = useState(false);
	const [isDragging, setIsDragging] = useState(false);
	const [offset, setOffset] = useState({ x: 0, y: 0 });
	const [position, setPosition] = useState({ top: 20, right: 20 });
	const videoTrack = tracks[activeTrackType];

	useEffect(() => {
		const displayName = participant.alias || participant.name;
		setParticipantDisplayedName(displayName);
	}, [participant]);

	useEffect(() => {
		setLoadingConference(false);
	}, [tracks]);

	useEffect(() => {
		const onLoaded = ({ target }) => {
			if (target) {
				const { videoHeight, videoWidth } = target;
				setIsPortraitVideo(videoWidth <= videoHeight);
			}
		};

		if (videoRef.current) {
			videoRef.current.addEventListener('loadedmetadata', onLoaded);
		}

		return () => {
			if (videoRef.current) {
				videoRef.current.removeEventListener('loadedmetadata', onLoaded);
			}
		};
	}, [participant, videoTrack]);

	const startDragging = (clientX, clientY) => {
		setIsDragging(true);
		const element = mainRef.current;
		setOffset({
			x: clientX - element.getBoundingClientRect().left,
			y: clientY - element.getBoundingClientRect().top,
		});
		element.style.cursor = 'grabbing';
	};

	const handleTouchStart = e => {
		const touch = e.touches[0];
		startDragging(touch.clientX, touch.clientY);
	};

	const moveElement = (clientX, clientY) => {
		const viewportWidth = window.innerWidth;
		const viewportHeight = window.innerHeight;
		const elementWidth = mainRef.current.offsetWidth;
		const elementHeight = mainRef.current.offsetHeight;

		let newRight = viewportWidth - (clientX + (elementWidth - offset.x));
		let newTop = clientY - offset.y;

		if (newTop < 0) newTop = 0;
		if (newTop + elementHeight > viewportHeight) newTop = viewportHeight - elementHeight;
		if (newRight < 0) newRight = 0;
		if (newRight + elementWidth > viewportWidth) newRight = viewportWidth - elementWidth;

		setPosition({ top: newTop, right: newRight });
	};

	const handleMouseMove = e => {
		if (isDragging) {
			moveElement(e.clientX, e.clientY);
		}
	};

	const handleTouchMove = e => {
		if (isDragging) {
			const touch = e.touches[0];
			moveElement(touch.clientX, touch.clientY);
		}
	};

	const stopDragging = () => {
		setIsDragging(false);
		mainRef.current.style.cursor = 'grab';
	};

	useEffect(() => {
		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', stopDragging);
		document.addEventListener('touchmove', handleTouchMove, { passive: false });
		document.addEventListener('touchend', stopDragging);

		return () => {
			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('mouseup', stopDragging);
			document.removeEventListener('touchmove', handleTouchMove);
			document.removeEventListener('touchend', stopDragging);
		};
	}, [isDragging, offset]);

	return (
		<StyledPictureInPictureCamera
			ref={mainRef}
			onMouseDown={e => startDragging(e.clientX, e.clientY)}
			onTouchStart={handleTouchStart}
			$top={position.top}
			$right={position.right}
			$isDarkMode={conferenceConfigs.isDarkMode}
			$isPortrait={isPortraitVideo}
			key={isPortraitVideo ? VIDEO_ORIENTATION.PORTRAIT : VIDEO_ORIENTATION.LANDSCAPE}>
			{!videoTrack && !loadingConference && (
				<Avatar
					size={36}
					src={participant.picture?.includes('user') ? null : buildProfilePic(participant.picture)}
					name={participantDisplayedName}
				/>
			)}
			{videoTrack && <ParticipantVideo ref={videoRef} track={videoTrack} muted />}
		</StyledPictureInPictureCamera>
	);
};

export default PictureInPictureCameraView;
