import * as React from 'react';

const Star = ({ color = '#343434' }) => (
	<svg xmlns='http://www.w3.org/2000/svg' width='40' height='41' viewBox='0 0 40 41' fill='none'>
		<path
			d='M18.2065 7.46731C18.9402 5.98082 21.0598 5.98081 21.7935 7.46731L24.4329 12.8153C24.7242 13.4056 25.2873 13.8147 25.9387 13.9094L31.8406 14.767C33.4811 15.0054 34.1361 17.0213 32.949 18.1784L28.6784 22.3412C28.207 22.8007 27.9919 23.4627 28.1032 24.1115L29.1114 29.9895C29.3916 31.6233 27.6767 32.8692 26.2095 32.0979L20.9307 29.3226C20.348 29.0163 19.652 29.0163 19.0693 29.3226L13.7905 32.0979C12.3233 32.8692 10.6084 31.6233 10.8886 29.9895L11.8968 24.1115C12.0081 23.4627 11.793 22.8007 11.3216 22.3412L7.05095 18.1784C5.86392 17.0213 6.51894 15.0054 8.15938 14.767L14.0613 13.9094C14.7127 13.8147 15.2758 13.4056 15.5671 12.8153L18.2065 7.46731Z'
			fill={color}
			fillOpacity='1'
		/>
	</svg>
);
export default Star;
