import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import { updatePatientAiSettings } from 'api/patients.js';
import Modal from 'components/Modal.jsx';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';
import {
	DeviceFamilyTypes,
	ExcludedAiSettings,
	ExcludedAiSettingsHello3,
	IncludedAiSettings,
	PatientAiSetting,
	RailType,
	SensitivityType,
} from 'constants/enums.js';
import ToastMessage from 'components/ToastMessage.jsx';
import { AiSetting, CallWorkflowType } from 'constants/configurationEnums.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { isAnySettingActive } from 'infrastructure/helpers/aiHelper.js';
import { FallPreventionSettingTypes, FallPreventionTypes } from 'constants/ai.js';

const FallPreventionModal = props => {
	const intl = useIntl();
	const [error, setError] = useState(null);
	const [isMoreThanOneAi, setIsMoreThanOneAi] = useState(false);
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const dispatch = useDispatch();
	const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));

	useEffect(() => {
		const toastTimeOut = setTimeout(() => {
			setIsMoreThanOneAi(false);
		}, 3000);

		return () => {
			clearTimeout(toastTimeOut);
		};
	}, [isMoreThanOneAi]);

	const shouldDisableClick = (settingTypeId, value) => {
		const settings = _.cloneDeep(aiSettingList);
		const found = settings.find(item => item.deviceId === props.deviceId);

		const foundInFallPrevention = FallPreventionTypes.find(item => item === settingTypeId);

		const excludedSettings = props.deviceFamily === DeviceFamilyTypes.HELLO_3 ? ExcludedAiSettingsHello3 : ExcludedAiSettings;

		let isAiSettingEnabled = found.settings.some(
			item => !ExcludedAiSettings.includes(item.settingTypeId) && item.isEnabled && item.settingTypeId !== settingTypeId
		);

		const isIndependentAiActive = isAnySettingActive(found.settings, IncludedAiSettings, settingTypeId, true);

		if (foundInFallPrevention) {
			isAiSettingEnabled = found.settings.reduce((acc, item) => {
				const isValid =
					!ExcludedAiSettings.includes(item.settingTypeId) &&
					item.isEnabled &&
					item.settingTypeId !== settingTypeId &&
					!FallPreventionTypes.includes(item.settingTypeId);

				return acc || isValid;
			}, false);
		}

		return (
			isAiSettingEnabled &&
			value &&
			settingTypeId !== PatientAiSetting.HAND_WASHING &&
			(!excludedSettings.includes(settingTypeId) || isIndependentAiActive)
		);
	};

	const handleSubmit = async () => {
		if (shouldDisableClick(PatientAiSetting.FALL_PREVENTION, true)) {
			setIsMoreThanOneAi(true);
			return;
		}

		const isSettingEnabled = type => getConfigurationValue(props.adminAiSettingsConfigurations[type]);

		const dataToSend = [
			{
				settingTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
				value: isSettingEnabled(AiSetting.GET_OUT_OF_BED) ? SensitivityType.MEDIUM : null,
				isEnabled: isSettingEnabled(AiSetting.GET_OUT_OF_BED),
			},
			{
				settingTypeId: PatientAiSetting.RAILS,
				value: isSettingEnabled(AiSetting.RAILS) ? `${RailType.BOTTOM_LEFT}-${RailType.BOTTOM_RIGHT}` : null,
				isEnabled: isSettingEnabled(AiSetting.RAILS),
			},
			{
				settingTypeId: PatientAiSetting.FALL_DETECTED,
				value: isSettingEnabled(AiSetting.FALL_DETECTION) ? 'true' : 'false',
				isEnabled: isSettingEnabled(AiSetting.FALL_DETECTION),
			},
		];

		props.toggleFallPrevention(true, props.deviceId);
		const found = aiSettingList.find(item => item.deviceId === props.deviceId);
		if (!found) {
			return;
		}
		const settings = _.cloneDeep(aiSettingList);
		const initialSettings = settings.find(item => item.deviceId === props.deviceId).settings;
		const filteredSettings = _.cloneDeep(initialSettings).filter(
			item => !FallPreventionSettingTypes.includes(item.settingTypeId)
		);

		setAiSettingsAction({
			settings: [...filteredSettings, ...dataToSend],
			deviceId: props.deviceId,
		});
		const sendData = dataToSend.map(item => ({
			isEnabled: item.isEnabled,
			settingTypeId: item.settingTypeId,
			workflowType: CallWorkflowType.MONITORING,
		}));

		const params = {
			patientId: props.owner?.healthcareUserId,
			deviceId: props.deviceId,
			roomId: props.roomId,
			sendData,
		};

		const response = await updatePatientAiSettings(params);

		if (response.error) {
			setError(response.error.message);
			setAiSettingsAction({
				settings: initialSettings,
				deviceId: props.deviceId,
			});

			return;
		}
		props.onModalClose();
	};

	return (
		<>
			<Modal
				className='wrapper-modal border-radius-modal-wrapper disable-modal'
				display={true}
				position='center'
				onModalSubmit={handleSubmit}
				onModalClose={props.onModalClose}
				primaryButtonLabel={intl.formatMessage({ id: 'aiMonitoringOn' })}
				closeButtonText={intl.formatMessage({ id: 'continueNoAi' })}>
				<form>
					<h3>{translate('aiFallPrevention')}</h3>
					<p>{translate('wantToTurnOnFallPrevention')}</p>
				</form>
				<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
			</Modal>
			<ToastMessage
				showToast={isMoreThanOneAi}
				onClose={() => setIsMoreThanOneAi(false)}
				className='video-feed-toast-message emergency-toast-message'>
				<span>{translate('noMoreThanOneAiType')}</span>
			</ToastMessage>
		</>
	);
};

export default FallPreventionModal;
