const Controls = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={6} height={8} fill='none' viewBox={`0 0 12 14`} {...props}>
			<rect width={10.667} height={5.333} x={0.667} y={1} stroke='#fff' rx={2.667} />
			<rect width={2.667} height={2.667} x={7.333} y={2.333} fill='#fff' stroke='#fff' rx={1.333} />
			<rect width={10.667} height={5.333} x={0.667} y={7.667} stroke='#fff' rx={2.667} />
			<rect width={2.667} height={2.667} x={2} y={9} stroke='#fff' rx={1.333} />
		</svg>
	);
};

export default Controls;
