import LightTheme from 'calls/styles/LightTheme.js';

const LeftLateralIcon = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='48'
		height='48'
		viewBox='0 0 48 48'
		fill='none'
		color={LightTheme.colors.grayZero}
		{...props}>
		<rect
			x='10'
			y='2'
			width='28'
			height='44'
			rx='4'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M22.4919 16.8225C21.1778 16.7265 19.8736 16.5246 18.592 16.2187C17.8027 13.0821 17.8027 9.79874 18.592 6.66209C22.5318 5.7793 26.6183 5.7793 30.5581 6.66209C31.2074 9.33294 31.3202 12.1061 30.8899 14.8209'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M22.0839 12.5146C22.0386 13.2154 22.2567 13.9078 22.6954 14.4561C23.1341 15.0045 23.7617 15.3692 24.4553 15.4789L24.7327 15.5116C24.8596 15.5116 24.9793 15.5116 25.0917 15.5116C25.6363 15.4771 26.1593 15.2861 26.598 14.9616C27.0366 14.6371 27.3723 14.1928 27.5646 13.6822C27.7569 13.1715 27.7977 12.6162 27.6821 12.0829C27.5665 11.5497 27.2993 11.0611 26.9128 10.676C26.5263 10.2908 26.0368 10.0255 25.5031 9.9118C24.9694 9.79811 24.4142 9.84092 23.9043 10.0351C23.3943 10.2292 22.9513 10.5665 22.6284 11.0063C22.3054 11.4462 22.1164 11.9699 22.0839 12.5146Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M23.7592 19.8467L27.3817 21.7939L29.0841 22.7077C29.4268 22.8909 29.8283 22.9309 30.2004 22.8187C30.5725 22.7066 30.885 22.4515 31.0694 22.1094C31.1947 21.8797 31.2549 21.6201 31.2435 21.3587L31.3631 15.4681C31.3667 15.276 31.3324 15.085 31.2622 14.9061C31.1919 14.7273 31.0872 14.564 30.9538 14.4256C30.8205 14.2872 30.6612 14.1764 30.485 14.0996C30.3089 14.0227 30.1193 13.9814 29.9272 13.9778C29.735 13.9742 29.5441 14.0085 29.3652 14.0788C29.1863 14.149 29.023 14.2538 28.8846 14.3871C28.7462 14.5204 28.6355 14.6797 28.5586 14.8559C28.4818 15.032 28.4404 15.2216 28.4369 15.4137L28.3607 18.9981L27.1641 18.3509L26.0491 17.7526L27.0988 18.1605C26.5549 16.6104 25.9783 16.7246 24.6784 16.643C24.0529 16.6049 23.6341 16.4363 23.2261 16.5723C22.9094 16.7043 22.6454 16.9377 22.4755 17.2359C21.2626 18.9981 20.6153 24.0403 20.4739 25.1444C20.2618 26.7762 20.1476 27.5648 20.599 28.4841C20.9999 29.2522 21.6288 29.8774 22.3994 30.2735L25.8532 33.7274L21.8663 38.1712C21.5057 38.5694 21.318 39.0945 21.3445 39.631C21.371 40.1676 21.6096 40.6716 22.0078 41.0322C22.4059 41.3929 22.931 41.5806 23.4675 41.5541C24.0041 41.5275 24.5081 41.289 24.8688 40.8908L24.9667 40.782L30.1339 35.0274C30.3626 34.7766 30.5244 34.4722 30.6043 34.1423C30.6841 33.8124 30.6795 33.4676 30.5908 33.14C30.4937 32.7509 30.2814 32.4001 29.9816 32.1337L25.924 27.4887C26.0219 27.2766 26.1306 27.0264 26.234 26.749C26.7944 25.2255 27.1705 23.6405 27.3545 22.0278C26.1117 21.3764 24.911 20.6479 23.7592 19.8467V19.8467Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M30.1611 29.5662L26.4679 26.9336C26.3972 27.124 26.3211 27.3035 26.2504 27.4775L30.1774 31.9703C30.4049 32.1708 30.5899 32.415 30.7214 32.6882L30.9607 31.7037C31.0587 31.3084 31.0348 30.8926 30.8921 30.5111C30.7494 30.1295 30.4946 29.8001 30.1611 29.5662V29.5662Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M29.9979 35.5713L25.1027 41.0105C25.306 41.355 25.6074 41.6311 25.9684 41.8035C26.3294 41.9759 26.7336 42.0368 27.1294 41.9784C27.5252 41.9201 27.8946 41.7451 28.1905 41.4758C28.4863 41.2065 28.6952 40.8551 28.7904 40.4665L29.9979 35.5713Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
		<path
			d='M28.067 16.6211C27.3218 16.7244 26.5767 16.7951 25.826 16.8332'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
	</svg>
);

export default LeftLateralIcon;
