import { useEffect, useRef, useState } from 'react';
import { participant } from '@solaborate/calls';

/**
 * @param {MediaStreamTrack} track
 */
const useParticipantVolume = track => {
	const [participantVolume, setParticipantVolume] = useState(0);
	/** @type {React.MutableRefObject<participant.AudioLevel>} */
	const audioLevelRef = useRef(null);

	useEffect(() => {
		if (track) {
			audioLevelRef.current = new participant.AudioLevel(new MediaStream([track]));

			audioLevelRef.current.on(audioLevelEvent => {
				if (audioLevelEvent instanceof participant.AudioLevelChanged) {
					setParticipantVolume(audioLevelEvent.level);
				}
			});
		}

		return () => {
			if (audioLevelRef.current) {
				audioLevelRef.current.close();
				audioLevelRef.current = null;
				setParticipantVolume(0);
			}
		};
	}, [track]);

	return participantVolume;
};

export default useParticipantVolume;
