const StethoscopeSummaryChart = props => (
	<>
		<div className='flex summary-chart-data'>
			{props.measurementValue !== 'No Data' && (
				<>
					<img src='https://static.solaborate.com/homecare-system/stetho_blue.svg?v3' alt={props.measurementType} />
					<h1>
						{props.measurementValue}
						<span>{props.measurementUnit}</span>
					</h1>
				</>
			)}
		</div>

		<img src='https://static.solaborate.com/healthcare-system/measurement-box/stetho-graph.svg' alt={props.measurementType} />
	</>
);

export default StethoscopeSummaryChart;
