import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getVerbalRedirections = async healthSystemId => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/verbal-redirections`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addNewVerbalRedirection = async (healthSystemId, title) => {
	try {
		const { data } = await gatewayApi.post(
			`v1/tasks/organizations/${getCompanyId()}/health-systems/${healthSystemId}/verbal-redirections`,
			{ title }
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteVerbalRedirection = async (healthSystemId, id) => {
	try {
		const { data } = await gatewayApi.delete(
			`v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/verbal-redirections/${id}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addOrUpdateQuickVerbalRedirect = async (healthSystemId, id, isQuickRedirect) => {
	try {
		const { data } = await gatewayApi.patch(
			`v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/verbal-redirections/${id}/quick-redirect`,
			{ isQuickRedirect }
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAllVerbalRedirectionLanguages = async () => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/verbal-redirections/languages`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addOrUpdateVerbalRedirectionLanguages = async (healthSystemId, languages = []) => {
	try {
		const { data } = await gatewayApi.post(
			`v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/verbal-redirections/languages`,
			{ languages }
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHealthSystemVerbalRedirectionLanguages = async healthSystemId => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/verbal-redirections/languages`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
