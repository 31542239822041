import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const LiveExaminationIcon = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' color={LightTheme.colors.graySeven} {...props}>
			<path
				d='M7 7.468V21a1 1 0 0 0 1 1h5.08a1 1 0 0 0 .996-.917l.894-10.718a1 1 0 0 1 .289-.624l2.034-2.034a1 1 0 0 0 0-1.414l-3.647-3.647a1 1 0 0 0-1.348-.061L7.36 6.7a1 1 0 0 0-.36.768Z'
				stroke='currentColor'
				strokeWidth={1.5}
			/>
			<path stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' d='M9.75 16.75v1.5M9.75 8.75v.5' />
			<path d='M8 6a.354.354 0 0 1 0-.5l.75-.75a.707.707 0 0 1 1 0' stroke='currentColor' strokeWidth={1.5} />
		</svg>
	);
};

export default LiveExaminationIcon;
