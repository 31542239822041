import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Notes = props => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			color={LightTheme.colors.grayZero}
			{...props}>
			<path
				d='M12.7374 14.9948L14.5052 16.7626M17.6872 10.045L19.455 11.8128M11.6768 16.0555L18.7478 8.98439C19.1384 8.59386 19.7715 8.59386 20.1621 8.98439L20.5156 9.33794C20.9061 9.72847 20.9061 10.3616 20.5156 10.7522L13.4445 17.8232L11.5 18L11.6768 16.0555Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M19.5 6.5V5C19.5 3.89543 18.6046 3 17.5 3H5.5C4.39543 3 3.5 3.89543 3.5 5V19C3.5 20.1046 4.39543 21 5.5 21H17.5C18.6046 21 19.5 20.1046 19.5 19V14.5'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M6.5 6.5H15.5M6.5 9.5H13M6.5 12.5H11' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	);
};

export default Notes;
