import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Alert } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { actionCreators as companyConfigurationActionCreators } from 'state/company/actions.js';
import { updateOrganizationSettings } from 'api/companies.js';
import { getCompanyId } from 'infrastructure/auth.js';
import { CompanySettings } from 'constants/configurationEnums.js';
import { AuthenticationOptions } from 'constants/enums.js';
import Select from 'react-select';

const TwoFactorAuthenticator = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const twoFactorAuthenticator = useSelector(state => state.company.companySettings.twoFactorAuthenticator);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const toggleItem = async selectedValue => {
		setIsLoading(true);
		const params = {
			companySettings: [
				{
					settingTypeId: CompanySettings.TWO_FACTOR_TYPE,
					value: selectedValue.value,
				},
			],
			companyId: getCompanyId(),
		};
		const response = await updateOrganizationSettings(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			dispatch(
				companyConfigurationActionCreators.setCompanySettings({
					twoFactorAuthenticator: selectedValue.value,
				})
			);
		}
		setIsLoading(false);
	};

	const twoFactorAuthenticatorOptions = [
		{ value: 'none', label: intl.formatMessage({ id: 'authNone' }) },
		{
			value: AuthenticationOptions.EXTERNAL_AUTHENTICATOR,
			label: intl.formatMessage({ id: 'authExternalProvider' }),
		},
		{
			value: 'email',
			label: intl.formatMessage({ id: 'authEmail' }),
		},
	];

	return (
		<div className='account-setting-tab'>
			<p className='label title'>{translate('companySettings')}</p>
			<div className='as-fields'>
				<div className='input'>
					<p className='label'>{translate('selectAuth')}</p>
					<Select
						isDisabled={isLoading}
						options={twoFactorAuthenticatorOptions}
						value={twoFactorAuthenticatorOptions.find(item => item.value === twoFactorAuthenticator)}
						placeholder={intl.formatMessage({ id: 'select' })}
						onChange={toggleItem}
					/>
				</div>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default TwoFactorAuthenticator;
