import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

function TV(props) {
	return (
		<svg
			width={24}
			height={24}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 24 24'
			color={LightTheme.colors.graySeven}
			{...props}>
			<rect x={1.199} y={3.6} width={21.6} height={13.5} rx={1.5} stroke='currentColor' strokeWidth={1.5} />
			<path d='M7.95 19.5h8.1' stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' />
		</svg>
	);
}

export default TV;
