import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ReactSelect from 'react-select';
import { useSelector } from 'react-redux';
import { disableVisits } from 'api/visitingHours.js';
import { DeviceListLevel } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Alert.jsx';
import Modal from 'components/Modal.jsx';
import { generateCustomStyles } from 'constants/react-select-style.js';

const DisableVisitingHoursModal = props => {
	const intl = useIntl();
	const [error, setError] = useState(null);
	const [selectedValueDisableVisits, setSelectedValueDisableVisits] = useState({
		value: 5,
		label: `5 ${intl.formatMessage({ id: 'minutes' })}`,
	});

	const user = useSelector(state => state.user);

	const handleSubmit = async () => {
		const response = await disableVisits(DeviceListLevel.ROOM, props.roomId, selectedValueDisableVisits.value);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		props.setDisabledVisitTime({
			isDisabled: true,
			disableTime: selectedValueDisableVisits.value,
			createdAt: new Date(),
		});
		props.setIsDisableVisitsOpen(false);
	};

	return (
		<>
			<Modal
				display={true}
				position='right'
				onModalSubmit={handleSubmit}
				onModalClose={() => props.setIsDisableVisitsOpen(false)}>
				<form>
					<h3>{translate('disableVisits')}</h3>
					<p>{translate('areYouSureDisableVisits')}</p>
					<div>
						<label htmlFor='selectTime'>*{translate('selectTime')}</label>
						<ReactSelect
							options={[
								{ value: 5, label: `5 ${intl.formatMessage({ id: 'minutes' })}` },
								{ value: 15, label: `15 ${intl.formatMessage({ id: 'minutes' })}` },
								{ value: 30, label: `30 ${intl.formatMessage({ id: 'minutes' })}` },
								{ value: 60, label: `1 ${intl.formatMessage({ id: 'hour' })}` },
							]}
							value={selectedValueDisableVisits}
							onChange={event => setSelectedValueDisableVisits(event)}
							placeholder={intl.formatMessage({ id: 'selectMemberToInvite' })}
							classNamePrefix='react-select'
							className='react-select top-15'
							styles={generateCustomStyles({ darkMode: user.darkMode })}
						/>
					</div>
				</form>
			</Modal>
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
		</>
	);
};

export default DisableVisitingHoursModal;
