import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { ConditionType } from 'constants/enums.js';
import { initialIsolations } from 'constants/roomSign.js';
import { deleteConditions, getIsolations, setConditions } from 'api/whiteboard.js';
import { Alert, Button } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';

const RoomSignIsolations = ({ deviceOwnerId, deviceId }) => {
	const socket = useContext(SocketContext);
	const [expanded, setExpanded] = useState(true);
	const [error, setError] = useState(null);
	const [isolations, setIsolations] = useState([]);

	const isChecked = key => isolations && isolations.some(item => item.key === key);

	useEffect(() => {
		const fetchData = async () => {
			const response = await getIsolations(deviceOwnerId);
			if (response.error) {
				setError(translate('somethingWentWrong'));
				return;
			}
			const filteredItems = _.cloneDeep(initialIsolations).filter(item =>
				response.conditions.find(condition => condition.code === item.key)
			);
			setIsolations(filteredItems);
		};

		if (deviceOwnerId) {
			fetchData();
		}
	}, [deviceOwnerId]);

	const setSelectedIsolations = async (key, name, checked) => {
		let response = null;

		const isolation = {
			conditionType: ConditionType.ISOLATIONS,
			display: name,
			code: key,
		};

		if (checked) {
			response = await setConditions(deviceOwnerId, {
				deviceId,
				conditions: [isolation],
			});
		} else {
			response = await deleteConditions(deviceOwnerId, {
				deviceId,
				conditions: [isolation],
			});
		}

		if (response.error) {
			setError(translate('somethingWentWrong'));
			return;
		}

		setIsolations(prevIsolations => {
			if (checked) {
				return [...prevIsolations, { key, name }];
			} else {
				return prevIsolations.filter(item => item.key !== key);
			}
		});
	};

	const handleToggleChange = async (key, name) => {
		const checked = !isChecked(key);
		await setSelectedIsolations(key, name, checked);
	};

	useEffect(() => {
		const ConditionActionTypes = {
			REMOVE: 1,
			ADD: 2,
		};

		const handleIsolationsUpdate = (data, type) => {
			if (data.deviceId !== deviceId) {
				return;
			}

			if (type === ConditionActionTypes.ADD) {
				const filteredConditions = data.conditions.filter(item => item.conditionType === ConditionType.ISOLATIONS);

				setIsolations(prevState => {
					const existingIsolationsMap = new Map(prevState.map(item => [item.key, item]));

					filteredConditions.forEach(condition => {
						const conditionData = initialIsolations.find(x => condition.code === x.key);
						if (conditionData) {
							existingIsolationsMap.set(condition.code, {
								key: condition.code,
								name: conditionData.name,
								label: conditionData.label,
							});
						}
					});

					return Array.from(existingIsolationsMap.values());
				});
			}

			if (type === ConditionActionTypes.REMOVE) {
				setIsolations(prevState => prevState.filter(item => !data.conditions.some(condition => condition.code === item.key)));
			}
		};

		socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, data => handleIsolationsUpdate(data, ConditionActionTypes.ADD));
		socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED, data =>
			handleIsolationsUpdate(data, ConditionActionTypes.REMOVE)
		);

		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, data =>
				handleIsolationsUpdate(data, ConditionActionTypes.ADD)
			);
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED, data =>
				handleIsolationsUpdate(data, ConditionActionTypes.REMOVE)
			);
		};
	}, [socket, deviceId]);

	return (
		<div className='room-sign-box'>
			<div className={classNames('timeline-box-header', { expanded })}>
				<p className='timeline-box-title'>{translate('isolations')}</p>
				<div className='timeline-box-actions'>
					<Button
						icon={expanded ? 'expand_less' : 'expand_more'}
						onClick={() => setExpanded(prevState => !prevState)}
						border='none'
					/>
				</div>
			</div>
			{expanded && (
				<div className={classNames('timeline-box-content', { expanded })}>
					{initialIsolations.map(item => (
						<div className='flex flex-align-center' key={item.key}>
							<p className='timeline-box-title flex-1 left-s'>{item.label}</p>
							<div className='toggle-switch' onClick={() => handleToggleChange(item.key, item.name)}>
								<input type='checkbox' checked={isChecked(item.key)} onChange={() => null} />
								<span className='toggle-body'>
									<span className='on-text'>{translate('on')}</span>
									<span className='off-text'>{translate('off')}</span>
								</span>
							</div>
						</div>
					))}
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default RoomSignIsolations;
