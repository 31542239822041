import Grid from 'components/Grid.jsx';
import translate from 'i18n-translations/translate.jsx';

const ActiveConference = () => (
	<div style={{ textAlign: 'center' }}>
		<Grid className='incoming-calls' columns='1fr' rows='1fr' horizAlign='center' vertAlign='center' stretch='100vh'>
			<div>
				<p data-test-id='callState-busy' className='incoming-call-text'>
					{translate('alreadyMonitoringPatients')} <br />
					{translate('tryAgainAfterSessionEnds')}
				</p>
			</div>
		</Grid>
	</div>
);

export default ActiveConference;
