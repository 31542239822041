import StreamSettingsView from 'calls/views/StreamSettingsView.jsx';
import { StreamSettingsIcon } from 'calls/icons/index.js';
import translate from 'i18n-translations/translate.jsx';

const StreamSettingsTab = ({ onDismiss }) => (
	<div className='monitoring-timeline'>
		<div className='monitoring-timeline-box stream-settings-box'>
			<div className='timeline-box-header'>
				<p className='timeline-box-title'>
					<StreamSettingsIcon height={24} width={24} color='#fff' />
					{translate('yourAudioAndVideoSettings')}
				</p>
			</div>
			<div className='timeline-box-content'>
				<StreamSettingsView onDismiss={onDismiss} isFromMonitoring={true} />
			</div>
		</div>
	</div>
);

export default StreamSettingsTab;
