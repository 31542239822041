const RedAlert = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={24} height={25} fill='none' {...props}>
		<path
			stroke='#F15E5E'
			strokeWidth={1.5}
			d='M10.293 5.293c.78-1.277 2.634-1.277 3.414 0l7.433 12.164c.815 1.333-.144 3.043-1.706 3.043H4.566c-1.562 0-2.52-1.71-1.706-3.043l7.433-12.164Z'
		/>
		<path stroke='#F15E5E' strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M12 9v5M12 16.5v.25' />
	</svg>
);

export default RedAlert;
