import { getPressureInjurySummary } from 'api/patients.js';
import Alert from 'components/Alert.jsx';
import CustomSummaryChart from 'components/DashboardCharts/PatientCentric/AI/Summaries/CustomSummaryChart.jsx';
import { PressureInjuryChartDetails } from 'infrastructure/helpers/aiHelper.js';
import { getDurationFormat, timezoneToUTCTimestamp } from 'infrastructure/helpers/dateHelper.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const PressureInjurySummary = ({ dateRange, selectedTimezone }) => {
	const location = useLocation();
	const [presssureInjuryStats, setPressureInjuryStats] = useState(null);
	const [totalPressureInjury, setTotalPressureInjury] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetchPressureInjurySummary = useCallback(
		debounce(async params => {
			setIsLoading(true);
			const response = await getPressureInjurySummary(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setPressureInjuryStats(response);
				const total = getDurationFormat(
					response?.leftLateral + response?.rightLateral + response?.upInAChair + response?.prone + response?.supine || 0
				);
				setTotalPressureInjury(total);
			}
			setIsLoading(false);
		}, 500),
		[]
	);

	useEffect(() => {
		const { state } = location;
		const { patient } = state;

		const params = {
			patientId: patient?.healthcareUserId,
			startTime: timezoneToUTCTimestamp(dateRange.from, selectedTimezone.zone),
			endTime: timezoneToUTCTimestamp(dateRange.to, selectedTimezone.zone),
		};
		fetchPressureInjurySummary(params);
	}, [location, dateRange, fetchPressureInjurySummary, selectedTimezone.zone]);

	return (
		<>
			<CustomSummaryChart
				total={totalPressureInjury}
				chartData={presssureInjuryStats}
				chartDetails={PressureInjuryChartDetails}
				dataSets={[
					presssureInjuryStats?.leftLateral || 0,
					presssureInjuryStats?.rightLateral || 0,
					presssureInjuryStats?.prone || 0,
					presssureInjuryStats?.supine || 0,
					presssureInjuryStats?.upInAChair || 0,
				]}
				isLoading={isLoading}
				title='pressureInjuryStats'
			/>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default PressureInjurySummary;
