import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { ConditionType } from 'constants/enums.js';
import { initialRoomSignInfo } from 'constants/roomSign.js';
import { getLanguages } from 'api/doctors.js';
import { deleteConditions, getPrecautions, setConditions, setInterpreterLanguage } from 'api/whiteboard.js';
import { Alert, CustomDropdown, Button } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { getPatientByUserId } from 'api/patients.js';

const DEFAULT_LANGUAGE = 'en';

const RoomSignInfo = ({ deviceOwnerId, userId, deviceId }) => {
	const socket = useContext(SocketContext);
	const intl = useIntl();
	const [expanded, setExpanded] = useState(true);
	const [error, setError] = useState(null);
	const [roomSignInfo, setRoomSignInfo] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [isInterpreterNeeded, setIsInterpreterNeeded] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState(null);

	const isChecked = key => roomSignInfo && roomSignInfo.some(item => item.key === key);

	useEffect(() => {
		const fetchData = async () => {
			const [precautionResponse, languageResponse, patientProfile] = await Promise.all([
				getPrecautions(deviceOwnerId),
				getLanguages(),
				getPatientByUserId(userId),
			]);

			if (precautionResponse.error || languageResponse.error || patientProfile.error) {
				setError(translate('somethingWentWrong'));
				return;
			}

			const filteredItems = _.cloneDeep(initialRoomSignInfo).filter(item =>
				precautionResponse.conditions.find(condition => condition.code === item.key)
			);

			setRoomSignInfo(filteredItems);
			setLanguages(languageResponse.languages);
			setIsInterpreterNeeded(patientProfile.patient.profile?.isInterpreterRequired);
			const responseLanguage = languageResponse.languages.find(
				item => item.name === patientProfile?.patient?.profile?.communicationLanguage
			);
			setSelectedLanguage(responseLanguage);
		};

		if (deviceOwnerId) {
			fetchData();
		}
	}, [deviceOwnerId]);

	const setSelectedRoomInfo = async (key, name, checked) => {
		let response = null;

		const precaution = {
			conditionType: ConditionType.PRECAUTION,
			display: name,
			code: key,
		};

		if (checked) {
			response = await setConditions(deviceOwnerId, {
				deviceId,
				conditions: [precaution],
			});
		} else {
			response = await deleteConditions(deviceOwnerId, {
				deviceId,
				conditions: [precaution],
			});
		}

		if (response.error) {
			setError(translate('somethingWentWrong'));
			return;
		}

		setRoomSignInfo(prevIsolations => {
			if (checked) {
				return [...prevIsolations, { key, name }];
			} else {
				return prevIsolations.filter(item => item.key !== key);
			}
		});
	};

	const handleToggleChange = async (key, name) => {
		const checked = !isChecked(key);
		await setSelectedRoomInfo(key, name, checked);
	};

	const handleSelectLanguage = async (selectedOption = null) => {
		const selectedValue = selectedOption ? selectedOption.value : selectedLanguage?.code || DEFAULT_LANGUAGE;
		const isInterpreterRequired = selectedOption !== null;
		const response = await setInterpreterLanguage(deviceOwnerId, { language: selectedValue, isInterpreterRequired });

		if (response.error) {
			setError(translate('somethingWentWrong'));
			return;
		}

		const language = languages.find(item => item.code === selectedValue);
		setSelectedLanguage(language);
		setIsInterpreterNeeded(isInterpreterRequired);
	};

	const toggleInterpreterNeeded = async () => {
		if (isInterpreterNeeded) {
			await handleSelectLanguage(null);
		} else {
			setIsInterpreterNeeded(true);
			await handleSelectLanguage(selectedLanguage ? { value: selectedLanguage.code } : null);
		}
	};

	useEffect(() => {
		const ConditionActionTypes = {
			REMOVE: 1,
			ADD: 2,
		};

		const handleRoomInfoPrecautionUpdate = (data, type) => {
			if (data.deviceId !== deviceId) {
				return;
			}

			if (type === ConditionActionTypes.ADD) {
				const filteredConditions = data.conditions.filter(item => item.conditionType === ConditionType.PRECAUTION);

				setRoomSignInfo(prevState => {
					const existingPrecautionMap = new Map(prevState.map(item => [item.key, item]));

					filteredConditions.forEach(condition => {
						const conditionData = initialRoomSignInfo.find(x => condition.code === x.key);
						if (conditionData) {
							existingPrecautionMap.set(condition.code, {
								key: condition.code,
								name: conditionData.name,
								label: conditionData.label,
							});
						}
					});

					return Array.from(existingPrecautionMap.values());
				});
			}

			if (type === ConditionActionTypes.REMOVE) {
				setRoomSignInfo(prevState => prevState.filter(item => !data.conditions.some(condition => condition.code === item.key)));
			}
		};

		const handleLanguageUpdate = data => {
			if (data.deviceId !== deviceId) {
				return;
			}

			const language = languages.find(item => item.name === data.communicationLanguage);
			setSelectedLanguage(language);
			setIsInterpreterNeeded(data.isInterpreterRequired);
		};

		socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, data =>
			handleRoomInfoPrecautionUpdate(data, ConditionActionTypes.ADD)
		);
		socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED, data =>
			handleRoomInfoPrecautionUpdate(data, ConditionActionTypes.REMOVE)
		);
		socket.on(SocketEvents.HealthCare.PATIENT_LANGUAGE_UPDATED, handleLanguageUpdate);

		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, data =>
				handleRoomInfoPrecautionUpdate(data, ConditionActionTypes.ADD)
			);
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED, data =>
				handleRoomInfoPrecautionUpdate(data, ConditionActionTypes.REMOVE)
			);
			socket.off(SocketEvents.HealthCare.PATIENT_LANGUAGE_UPDATED, handleLanguageUpdate);
		};
	}, [socket, deviceId, languages]);

	return (
		<div className='room-sign-box'>
			<div className={classNames('timeline-box-header', expanded ? 'expanded' : '')}>
				<p className='timeline-box-title'>{translate('roomInfo')}</p>
				<div className='timeline-box-actions'>
					<Button
						icon={expanded ? 'expand_less' : 'expand_more'}
						onClick={() => setExpanded(prevState => !prevState)}
						border='none'
					/>
				</div>
			</div>
			{expanded && (
				<div className={classNames('timeline-box-content', expanded ? ' expanded' : '')}>
					{initialRoomSignInfo.map(item => (
						<div className='flex flex-align-center' key={item.key}>
							<p className='timeline-box-title flex-1 left-s'>{translate('hearingImpaired')}</p>
							<div className='toggle-switch' onClick={() => handleToggleChange(item.key)}>
								<input type='checkbox' checked={isChecked(item.key)} onChange={() => null} />
								<span className='toggle-body'>
									<span className='on-text'>{translate('on')}</span>
									<span className='off-text'>{translate('off')}</span>
								</span>
							</div>
						</div>
					))}
					<div className='flex flex-align-center'>
						<p className='timeline-box-title flex-1 left-s'>{translate('isInterpreterNeeded')}</p>
						<div className='toggle-switch' onClick={() => toggleInterpreterNeeded()}>
							<input type='checkbox' checked={isInterpreterNeeded} onChange={() => null} />
							<span className='toggle-body'>
								<span className='on-text'>{translate('on')}</span>
								<span className='off-text'>{translate('off')}</span>
							</span>
						</div>
					</div>
					{isInterpreterNeeded && (
						<div className='flex flex-align-center'>
							<p className='timeline-box-title flex-1 left-s'>{translate('whatLanguageIsNeeded')}</p>
							<div>
								<CustomDropdown
									defaultOptions={
										selectedLanguage
											? {
													value: selectedLanguage?.code,
													label: selectedLanguage?.name,
											  }
											: []
									}
									initialOptions={languages.map(language => ({ value: language.code, label: language.name }))}
									onSelect={handleSelectLanguage}
									showTitleInPlaceholder={false}
									placeholder={intl.formatMessage({ id: 'language' })}
									isMulti={false}
									isSearchable={true}
								/>
							</div>
						</div>
					)}
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default RoomSignInfo;
