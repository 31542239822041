export default class GoogleReCaptchaV3 {
	siteKey = null;

	action = null;

	constructor(siteKey, action = 'submit') {
		this.siteKey = siteKey;
		this.action = action;
	}

	async getToken() {
		const token = await window.grecaptcha.execute(this.siteKey, { action: this.action });
		return token;
	}

	async loadReCaptcha() {
		return new Promise((resolve, reject) => {
			const scriptUrl = `https://www.recaptcha.net/recaptcha/api.js?render=${this.siteKey}`;
			const script = document.createElement('script');
			script.innerHTML = '';
			script.src = scriptUrl;
			script.async = true;
			script.defer = true;
			script.onload = () => {
				resolve(window.grecaptcha);
			};
			script.onerror = error => {
				reject(error);
			};
			document.body.appendChild(script);
		});
	}
}

