// Copyright (C) <2018> Intel Corporation
//
// SPDX-License-Identifier: Apache-2.0
export const errors = {
    // 2100-2999 for P2P errors
    // 2100-2199 for connection errors
    // 2100-2109 for server errors
    P2P_CONN_SERVER_UNKNOWN: {
        code: 2100,
        message: 'Server unknown error.',
    },
    P2P_CONN_SERVER_UNAVAILABLE: {
        code: 2101,
        message: 'Server is unavaliable.',
    },
    P2P_CONN_SERVER_BUSY: {
        code: 2102,
        message: 'Server is too busy.',
    },
    P2P_CONN_SERVER_NOT_SUPPORTED: {
        code: 2103,
        message: 'Method has not been supported by server.',
    },
    // 2110-2119 for client errors
    P2P_CONN_CLIENT_UNKNOWN: {
        code: 2110,
        message: 'Client unknown error.',
    },
    P2P_CONN_CLIENT_NOT_INITIALIZED: {
        code: 2111,
        message: 'Connection is not initialized.',
    },
    // 2120-2129 for authentication errors
    P2P_CONN_AUTH_UNKNOWN: {
        code: 2120,
        message: 'Authentication unknown error.',
    },
    P2P_CONN_AUTH_FAILED: {
        code: 2121,
        message: 'Wrong username or token.',
    },
    // 2200-2299 for message transport errors
    P2P_MESSAGING_TARGET_UNREACHABLE: {
        code: 2201,
        message: 'Remote user cannot be reached.',
    },
    P2P_CLIENT_DENIED: {
        code: 2202,
        message: 'User is denied.',
    },
    // 2301-2399 for chat room errors
    // 2401-2499 for client errors
    P2P_CLIENT_UNKNOWN: {
        code: 2400,
        message: 'Unknown errors.',
    },
    P2P_CLIENT_UNSUPPORTED_METHOD: {
        code: 2401,
        message: 'This method is unsupported in current browser.',
    },
    P2P_CLIENT_ILLEGAL_ARGUMENT: {
        code: 2402,
        message: 'Illegal argument.',
    },
    P2P_CLIENT_INVALID_STATE: {
        code: 2403,
        message: 'Invalid peer state.',
    },
    P2P_CLIENT_NOT_ALLOWED: {
        code: 2404,
        message: 'Remote user is not allowed.',
    },
    // 2501-2599 for WebRTC erros.
    P2P_WEBRTC_UNKNOWN: {
        code: 2500,
        message: 'WebRTC error.',
    },
    P2P_WEBRTC_SDP: {
        code: 2502,
        message: 'SDP error.',
    },
};

/**
 * @function getErrorByCode
 * @desc Get error object by error code.
 * @param {string} errorCode Error code.
 * @return {Owt.P2P.Error} Error object
 * @private
 */
export function getErrorByCode(errorCode) {
    const codeErrorMap = {
        2100: errors.P2P_CONN_SERVER_UNKNOWN,
        2101: errors.P2P_CONN_SERVER_UNAVAILABLE,
        2102: errors.P2P_CONN_SERVER_BUSY,
        2103: errors.P2P_CONN_SERVER_NOT_SUPPORTED,
        2110: errors.P2P_CONN_CLIENT_UNKNOWN,
        2111: errors.P2P_CONN_CLIENT_NOT_INITIALIZED,
        2120: errors.P2P_CONN_AUTH_UNKNOWN,
        2121: errors.P2P_CONN_AUTH_FAILED,
        2201: errors.P2P_MESSAGING_TARGET_UNREACHABLE,
        2400: errors.P2P_CLIENT_UNKNOWN,
        2401: errors.P2P_CLIENT_UNSUPPORTED_METHOD,
        2402: errors.P2P_CLIENT_ILLEGAL_ARGUMENT,
        2403: errors.P2P_CLIENT_INVALID_STATE,
        2404: errors.P2P_CLIENT_NOT_ALLOWED,
        2500: errors.P2P_WEBRTC_UNKNOWN,
        2501: errors.P2P_WEBRTC_SDP,
    };
    return codeErrorMap[errorCode];
}

/**
 * @class P2PError
 * @classDesc The P2PError object represents an error in P2P mode.
 * @memberOf Owt.P2P
 * @hideconstructor
 */
export class P2PError extends Error {
    // eslint-disable-next-line require-jsdoc
    constructor(error, message) {
        super(message);
        if (typeof error === 'number') {
            this.code = error;
        } else {
            this.code = error.code;
        }
    }
}
