import { MonitoringTimelineSettings } from 'constants/action-types.js';

const initialState = {
	savedMode: {},
};

export const monitoringTimelineSettings = (state = initialState, action) => {
	switch (action.type) {
		case MonitoringTimelineSettings.SET_MONITORING_TIMELINE_SETTINGS: {
			return { ...state, savedMode: { ...state.savedMode, [action.deviceId]: action.payload } };
		}
		default:
			return state;
	}
};
