import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Dropzone from 'react-dropzone';
import { CircularProgressbar } from 'react-circular-progressbar';
import { v4 as uuidv4 } from 'uuid';
import Grid from 'components/Grid.jsx';
import FormInput from 'components/FormInput.jsx';
import Textarea from 'components/Textarea.jsx';
import translate from 'i18n-translations/translate.jsx';
import 'react-circular-progressbar/dist/styles.css';
import { getAttachmentIcon } from 'infrastructure/helpers/commonHelpers.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { AcceptedFileTypes, AlertTypes } from 'constants/enums.js';
import ToastMessage from 'components/ToastMessage.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const AdditionalHealthData = ({ setAnswers, questions, additionalData }) => {
	const [uploadProgress, setUploadedProgress] = useState(0);
	const [error, setError] = useState('');
	const [healthConcernInformation, setHealthConcernInformation] = useState({
		additionalNotes: additionalData.additionalNotes,
		attachments: additionalData.attachments,
		answer1: additionalData.medicalQuestionAnswers.find(item => item.medicalQuestionId === 1)?.answer,
		answer2: additionalData.medicalQuestionAnswers.find(item => item.medicalQuestionId === 2)?.answer,
		answer3: additionalData.medicalQuestionAnswers.find(item => item.medicalQuestionId === 3)?.answer,
	});
	const intl = useIntl();

	useEffect(() => {
		const healthConcernInformationKeys = Object.keys(healthConcernInformation);
		if (
			healthConcernInformationKeys.some(item => item.startsWith('answer')) ||
			healthConcernInformationKeys.some(item => item.startsWith('additionalNotes')) ||
			healthConcernInformationKeys.some(item => item.startsWith('attachments'))
		) {
			const medicalQuestionAnswers = questions.map(question => {
				return {
					medicalQuestionId: question.id,
					answer: healthConcernInformation[`answer${question.id}`],
				};
			});
			const additionalHealthData = {
				medicalQuestionAnswers,
				additionalNotes: healthConcernInformation.additionalNotes,
				attachments: healthConcernInformation.attachments,
			};
			setAnswers(additionalHealthData);
		}
	}, [healthConcernInformation, setAnswers, questions]);

	const uploadedFileHandler = async acceptedAttachments => {
		const newAttachments = [...healthConcernInformation.attachments];
		if (newAttachments.length >= 5) {
			setError(intl.formatMessage({ id: 'fiveAttachmentsMaximumUpload' }));
			return;
		}
		acceptedAttachments.forEach(attachment => {
			if (attachment.size / 1024 / 1024 > 5) {
				setError(intl.formatMessage({ id: 'fileUploadMaximumSize' }));
				return;
			}
			const reader = new FileReader();
			reader.onabort = () => setError(intl.formatMessage({ id: 'fileReadingWasAborted' }));
			reader.onerror = () => setError(intl.formatMessage({ id: 'fileReadingHasFailed' }));
			// eslint-disable-next-line no-param-reassign
			attachment.id = uuidv4();
			newAttachments.push(attachment);
			setHealthConcernInformation({ ...healthConcernInformation, attachments: newAttachments });

			reader.readAsDataURL(attachment);
			reader.onprogress = event => {
				if (event.lengthComputable) {
					const progress = (event.loaded / event.total) * 100;
					setUploadedProgress(Math.trunc(progress));
				}
			};
			reader.onload = async () => {
				const attachmentIndex = newAttachments.findIndex(item => item.id === attachment.id);
				newAttachments[attachmentIndex].content = reader.result;
				newAttachments[attachmentIndex].isUploadCompleted = true;
				setHealthConcernInformation({ ...healthConcernInformation, attachments: newAttachments });
			};
		});
	};

	const onFileDeleteHandler = id => {
		const prevAttachments = [...healthConcernInformation.attachments];
		const filteredAttachments = prevAttachments.filter(attachment => attachment.id !== id);
		setHealthConcernInformation({ ...healthConcernInformation, attachments: filteredAttachments });
	};

	const handleChange = event => {
		setHealthConcernInformation({ ...healthConcernInformation, [event.target.name]: event.target.value });
	};

	return (
		<Grid columns='1fr' stretch='100%'>
			<div className='full-page-request-input-wrapper select-doctor-tabs-wrapper block top-0 padding-top-80'>
				<div className='full-page-input-wrapper select-doctor-wrapper left-right-auto'>
					<div>
						<h3>{translate('additionalHealthData')}</h3>
						<p>{translate('pleaseSpecifySymptoms')}</p>
					</div>
					<div className='additional-hd-inputs'>
						{questions.map(item => (
							<FormInput
								name={`answer${item.id}`}
								type='text'
								labelClassName='full-width'
								className='full-width'
								text={item.question}
								value={healthConcernInformation[`answer${item.id}`]}
								onChange={handleChange}
								maxLength={32}
								placeholder={intl.formatMessage({ id: 'typeHere' })}
								key={item.id}
							/>
						))}

						<label htmlFor='additionalNotes' className='full-width'>
							{translate('description')}
						</label>
						<Textarea
							maxLength={500}
							value={healthConcernInformation.additionalNotes}
							onChange={handleChange}
							maxNumberLimit={50}
							rows={10}
							id='additionalNotes'
							name='additionalNotes'
							placeholder={intl.formatMessage({ id: 'typeHere' })}
						/>
						<div style={{ paddingBottom: healthConcernInformation.attachments.length > 0 ? '50px' : '0' }}>
							<label>{translate('attachments')}</label>
							<Dropzone accept={Object.values(AcceptedFileTypes).toString()} onDrop={uploadedFileHandler}>
								{({ getRootProps, getInputProps }) => (
									<>
										<div className='drag-drop-user-img available-doctors-drag-drop cursor-pointer' {...getRootProps()}>
											<input {...getInputProps()} />
											<p>{translate('dragAndDropOrSelectAttachments')}</p>
										</div>
										<div className='drag-drop-img-gallery flex'>
											{healthConcernInformation.attachments.length > 0 &&
												healthConcernInformation.attachments.map(attachment => {
													const attachmentIcon = getAttachmentIcon(attachment.name);
													return (
														<>
															{attachmentIcon !== 'invalidFileType' && (
																<div className='doctor-request-photo-upload' key={attachment.id}>
																	<i className='material-icons cursor-pointer' onClick={() => onFileDeleteHandler(attachment.id)}>
																		close
																	</i>
																	<img src={attachmentIcon} alt='file-icon' />
																	<a href={attachment.content} rel='noopener noreferrer' target='_blank' download>
																		{attachment.name}
																	</a>
																	{uploadProgress !== 100 && uploadProgress !== 0 && !attachment.isUploadCompleted && (
																		<div>
																			<CircularProgressbar value={uploadProgress} text={`${uploadProgress}%`} />
																		</div>
																	)}
																</div>
															)}
															<ToastMessage
																showToast={attachmentIcon === 'invalidFileType'}
																onClose={() => onFileDeleteHandler(attachment.id)}>
																<img src={`${healthCareCdnUrl}footer-icons/decline.svg?v3`} alt='icon' />
																<span>{translate('invalidFileType')}</span>
															</ToastMessage>
														</>
													);
												})}
										</div>
									</>
								)}
							</Dropzone>
						</div>
					</div>
				</div>
			</div>
			<PopUpAlert
				alertType={AlertTypes.DANGER}
				display={error}
				onAlertClose={() => setError(null)}
				contentText={error}
				isSilent={true}
				center={true}
			/>
		</Grid>
	);
};

export default AdditionalHealthData;
