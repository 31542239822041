import { MeasurementTypes } from 'constants/enums.js';

export const averagesByHour = intl => [
	{
		label: intl.formatMessage({ id: 'oxygen' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#5390FF',
		borderWidth: 3,
		backgroundColor: 'transparent',
		pointRadius: 0,
		pointBackgroundColor: '#5390FF',
		fill: false,
		measurmentType: MeasurementTypes.OXYGEN,
	},
	{
		label: intl.formatMessage({ id: 'heartRate' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#D28DB9',
		borderWidth: 3,
		backgroundColor: 'transparent',
		pointRadius: 0,
		pointBackgroundColor: '#D28DB9',
		fill: false,
		measurmentType: MeasurementTypes.HEART_RATE,
	},
	{
		label: intl.formatMessage({ id: 'temperature' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#FFB533',
		borderWidth: 3,
		backgroundColor: 'transparent',
		pointRadius: 0,
		pointBackgroundColor: '#FFB533',
		fill: false,
		measurmentType: MeasurementTypes.TEMPERATURE,
	},
	{
		label: intl.formatMessage({ id: 'bloodPressure' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#D28DB9',
		borderWidth: 3,
		backgroundColor: 'transparent',
		pointRadius: 0,
		pointBackgroundColor: '#D28DB9',
		fill: false,
		measurmentType: MeasurementTypes.BLOOD_PRESSURE,
	},

	{
		label: intl.formatMessage({ id: 'weight' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#D28DB9',
		borderWidth: 3,
		backgroundColor: 'transparent',
		pointRadius: 0,
		pointBackgroundColor: '#D28DB9',
		fill: false,
		measurmentType: MeasurementTypes.WEIGHT,
	},
	{
		label: intl.formatMessage({ id: 'bloodGlucose' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#D28DB9',
		borderWidth: 3,
		backgroundColor: 'transparent',
		pointRadius: 0,
		pointBackgroundColor: '#D28DB9',
		fill: false,
		measurmentType: MeasurementTypes.DIABETES,
	},
	{
		label: intl.formatMessage({ id: 'leanBodyMass' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#D28DB9',
		borderWidth: 3,
		backgroundColor: 'transparent',
		pointRadius: 0,
		pointBackgroundColor: '#D28DB9',
		fill: false,
		measurmentType: MeasurementTypes.LEAN_BODY_MASS,
	},
	{
		label: intl.formatMessage({ id: 'bodyMassIndex' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#D28DB9',
		borderWidth: 3,
		backgroundColor: 'transparent',
		pointRadius: 0,
		pointBackgroundColor: '#D28DB9',
		fill: false,
		measurmentType: MeasurementTypes.BODY_MASS_INDEX,
	},
];
