import React, { useState, useEffect } from 'react';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { LOCALES } from 'i18n-translations/locales.js';

const languages = [
	{
		id: 0,
		name: 'English (US)',
		img: `${healthCareCdnUrl}admin/US.svg`,
		value: LOCALES.ENGLISH,
	},
	{
		id: 1,
		name: 'Albanian',
		img: `${healthCareCdnUrl}admin/Albania.svg`,
		value: LOCALES.ALBANIAN,
	},
	{
		id: 2,
		name: 'Arabic',
		img: `${healthCareCdnUrl}admin/uae-flag.svg`,
		value: LOCALES.ARABIC,
	},
	{
		id: 3,
		name: 'German',
		img: `${healthCareCdnUrl}admin/germany-flag.svg`,
		value: LOCALES.GERMAN,
	},
	{
		id: 4,
		name: 'Spanish',
		img: `${healthCareCdnUrl}admin/spain-flag.svg`,
		value: LOCALES.SPANISH,
	},
];

const Languages = ({ settingTypeId, setSelectedLanguage, deviceSettings }) => {
	useEffect(() => {
		const selectedMode = deviceSettings?.find(item => item.settingTypeId === settingTypeId);
		setLanguage(selectedMode);
	}, [deviceSettings, settingTypeId]);

	const [selectedLanguage, setLanguage] = useState(null);

	return (
		<div className='configurable-settings-modal-inner'>
			<h3>{translate('languages')}</h3>
			<div className='flex flex-wrap grid-50'>
				{languages.map(item => (
					<div key={item.id} className='flex' onClick={() => setSelectedLanguage(settingTypeId, item.value)}>
						<img src={item.img} alt={item.name} /> <p>{item.name}</p>
						<div className='rounded-slider-switch rounded-switch'>
							<input
								type='radio'
								name='language'
								checked={selectedLanguage && item.value === selectedLanguage.value}
								onChange={() => null}
							/>
							<span className='rounded-slider' />
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Languages;
