import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { AiAlertId } from 'constants/enums.js';
import { LOCALES } from 'i18n-translations/locales.js';
import translate from 'i18n-translations/translate.jsx';
import FallDetection from 'icons/Monitoring/FallDetection.jsx';
import PressureInjury from 'icons/Monitoring/PressureInjury.jsx';
import IvBag from 'icons/Monitoring/IvBag.js';
import OutOfBed from 'icons/Monitoring/OutOfBed.jsx';
import PatientMobility from 'icons/Monitoring/PatientMobility.jsx';
import Rails from 'icons/Monitoring/Rails.jsx';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import AiVoiceAnalysis from 'icons/Monitoring/AiVoiceAnalysis.jsx';
import { getAiVoiceMessageByType } from 'infrastructure/helpers/alertsHelper.js';

export const getLanguagePrefix = () => {
	let result = '';
	const locale = getPreferredLanguageLocale();
	if (locale === LOCALES.ARABIC) {
		result = 'arabic-';
	}
	if (locale === LOCALES.GERMAN) {
		result = 'german-';
	}
	if (locale === LOCALES.SPANISH) {
		result = 'spanish-';
	}
	return result;
};

export const getPressureInjuryMessageByType = type => {
	switch (type) {
		case AiAlertId.PATIENT_SITTING:
			return translate('patientSitting');
		case AiAlertId.PATIENT_STANDING:
			return translate('patientStanding');
		case AiAlertId.PATIENT_LYING_FRONT:
			return translate('patientLyingFront');
		case AiAlertId.PATIENT_LYING_SIDE:
			return translate('patientLyingSide');
		case AiAlertId.PATIENT_LYING_RIGHT_SIDE:
			return translate('patientLyingRightSide');
		case AiAlertId.PATIENT_LYING_LEFT_SIDE:
			return translate('patientLyingLeftSide');
		case AiAlertId.PATIENT_FACE_DOWN:
			return translate('patientFaceDown');
		case AiAlertId.PATIENT_INACTIVE:
		case AiAlertId.PATIENT_INACTIVE_INFORMATION:
			return translate('patientInactive');
		default:
			return translate('pleaseCheckPatient');
	}
};

export const getRailsMessageByType = type => {
	switch (type) {
		case AiAlertId.RAILS:
			return translate('railDownMessage');
		case AiAlertId.COVERED_RAILS:
			return translate('railNotVisible');
		default:
			return translate('pleaseCheckPatient');
	}
};

export const getHealthConcern = (alertTypeId, darkMode) => {
	switch (alertTypeId) {
		case AiAlertId.FALL_DETECTION:
			return {
				text: translate('pleaseCheckPatient'),
				imgIcon: <FallDetection color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			};
		case AiAlertId.PATIENT_SITTING:
		case AiAlertId.PATIENT_STANDING:
		case AiAlertId.PATIENT_FACE_DOWN:
		case AiAlertId.PATIENT_LYING_FRONT:
		case AiAlertId.PATIENT_LYING_SIDE:
		case AiAlertId.PATIENT_LYING_RIGHT_SIDE:
		case AiAlertId.PATIENT_LYING_LEFT_SIDE:
		case AiAlertId.PATIENT_INACTIVE:
		case AiAlertId.PATIENT_INACTIVE_INFORMATION:
			return {
				text: getPressureInjuryMessageByType(alertTypeId),
				imgIcon: <PressureInjury color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			};
		case AiAlertId.PATIENT_GETTING_OUT_OF_BED:
			return {
				text: translate('patientGettingOutOfBed'),
				imgIcon: <OutOfBed color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			};
		case AiAlertId.PATIENT_GETTING_OUT_OF_CHAIR:
			return {
				text: translate('personGettingOutOfChairMessage'),
				imgIcon: <OutOfBed color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			};
		case AiAlertId.RAILS:
		case AiAlertId.COVERED_RAILS:
			return {
				text: getRailsMessageByType(alertTypeId),
				imgIcon: <Rails color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			};
		case AiAlertId.PATIENT_MOBILITY:
			return {
				text: translate('personIsDetectedWalking'),
				imgIcon: <PatientMobility color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			};
		case AiAlertId.IV_BAG:
		case AiAlertId.LOW_IV_BAG:
			return {
				text: translate('ivBagCheck'),
				imgIcon: <IvBag color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			};
		case AiAlertId.SNEEZING:
		case AiAlertId.SNORING:
		case AiAlertId.COUGHING:
		case AiAlertId.CRYING_BABY:
		case AiAlertId.SCREAMING:
		case AiAlertId.HELP:
		case AiAlertId.LOW_PRIORITY_ALARM:
		case AiAlertId.MEDIUM_PRIORITY_ALARM:
		case AiAlertId.HIGH_PRIORITY_ALARM:
		case AiAlertId.ALARM_OFF:
		case AiAlertId.VITAL_SIGN_ALARM: {
			return {
				text: getAiVoiceMessageByType(alertTypeId),
				imgIcon: <AiVoiceAnalysis color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			};
		}
		default:
			return {
				text: translate('pleaseCheckPatient'),
				imgIcon: <FallDetection color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			};
	}
};
