import { gatewayApi } from 'constants/global-variables.js';


export const getWiFiBands = async () => {
	try {
		const { data } = await gatewayApi.get('/v1/healthcare/wifi-bands');
		return data;
	} catch (error) {
		return { error };
	}
};
