const IceTransportTypes = {
	/**
	 * @type {RTCIceTransportPolicy}
	 */
	ALL: 'all',
	/**
	 * @type {RTCIceTransportPolicy}
	 */
	RELAY: 'relay',
};

export default IceTransportTypes;
