import classNames from 'classnames';
import React from 'react';

const CheckboxInput = props => (
	<label
		key={props.label}
		className={classNames('checkbox-input', { 'checkbox-input-checked': props.checked }, props.className)}
		onChange={props.onChange}>
		<input type='checkbox' name={props.name || 'checkbox'} checked={props.checked} onChange={() => null} onBlur={props.onBlur} />
		<span>{props.label}</span>
	</label>
);

export default CheckboxInput;
