import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Alert, Grid, Textarea } from 'components';
import ToastMessage from 'components/ToastMessage.jsx';
import { AcceptedFileTypes, SymptomsLength } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { capitalizeFirstLetter, getAttachmentIcon } from 'infrastructure/helpers/commonHelpers.js';

const PatientHealthDetails = props => {
	const intl = useIntl();
	const [searchBox, setSearchBox] = useState('');
	const [error, setError] = useState(null);
	const [healthConcern, setHealthConcern] = useState({
		additionalNotes: props.additionalData.additionalNotes,
		attachments: props.additionalData.attachments,
		answer1: props.additionalData.medicalQuestionAnswers.find(item => item.medicalQuestionId === 1)?.answer,
		answer2: props.additionalData.medicalQuestionAnswers.find(item => item.medicalQuestionId === 2)?.answer,
		answer3: props.additionalData.medicalQuestionAnswers.find(item => item.medicalQuestionId === 3)?.answer,
	});
	const [uploadProgress, setUploadedProgress] = useState(0);

	useEffect(() => {
		const healthConcernKeys = Object.keys(healthConcern);
		if (
			healthConcernKeys.some(item => item.startsWith('answer')) ||
			healthConcernKeys.some(item => item.startsWith('additionalNotes')) ||
			healthConcernKeys.some(item => item.startsWith('attachments'))
		) {
			const medicalQuestionAnswers = props.questions.map(question => ({
				medicalQuestionId: question.id,
				answer: healthConcern[`answer${question.id}`],
			}));
			const additionalData = {
				medicalQuestionAnswers,
				additionalNotes: healthConcern.additionalNotes,
				attachments: healthConcern.attachments,
			};
			props.setAnswers(additionalData);
		}
	}, [healthConcern]);

	const onSearch = event => {
		const searchBoxValue = event.target.value;
		setSearchBox(searchBoxValue);
	};

	const addSymptom = symptom => {
		if (props.selectedSymptoms.length < SymptomsLength.MAX_SYMPTOMS) {
			setSearchBox('');
			props.addSymptom(symptom);
		}
	};

	const filteredSymptoms = props.symptoms.filter(item => item.name.toLowerCase().includes(searchBox.toLowerCase()));
	const searchWords = searchBox.split(' ');

	const uploadedFileHandler = async acceptedAttachments => {
		const newAttachments = [...healthConcern.attachments];
		if (newAttachments.length >= 5) {
			setError(props.intl.formatMessage({ id: 'fiveAttachmentsMaximumUpload' }));
			return;
		}
		acceptedAttachments.forEach(attachment => {
			if (attachment.size / 1024 / 1024 > 5) {
				setError(props.intl.formatMessage({ id: 'fileUploadMaximumSize' }));
				return;
			}
			const reader = new FileReader();
			reader.onabort = () => setError(props.intl.formatMessage({ id: 'fileReadingWasAborted' }));
			reader.onerror = () => setError(props.intl.formatMessage({ id: 'fileReadingHasFailed' }));
			// eslint-disable-next-line no-param-reassign
			attachment.id = uuidv4();
			newAttachments.push(attachment);
			setHealthConcern({ ...healthConcern, attachments: newAttachments });

			reader.readAsDataURL(attachment);
			reader.onprogress = event => {
				if (event.lengthComputable) {
					const progress = (event.loaded / event.total) * 100;
					setUploadedProgress(Math.trunc(progress));
				}
			};
			reader.onload = async () => {
				const attachmentIndex = newAttachments.findIndex(item => item.id === attachment.id);
				newAttachments[attachmentIndex].content = reader.result;
				newAttachments[attachmentIndex].isUploadCompleted = true;
				setHealthConcern({ ...healthConcern, attachments: newAttachments });
			};
		});
	};

	const onFileDeleteHandler = id => {
		setHealthConcern(prevState => ({
			...healthConcern,
			attachments: prevState.attachments.filter(attachment => attachment.id !== id),
		}));
	};

	const handleChange = event => {
		setHealthConcern({ ...healthConcern, [event.target.name]: event.target.value });
	};

	return (
		<Grid columns='1fr' stretch='100%'>
			<div className='check-in-patient-wrapper select-doctor-tabs-wrapper'>
				<div className='full-page-input-wrapper additional-health select-doctor-wrapper'>
					<div className='flex flex-align-center column-direction'>
						<div>
							<h3>{translate('patientHealthDetails')}</h3>
							<p>{translate('selectSignsSymptomsAssociatedWithHealthConcerns')}</p>
						</div>
						<div className='flex column-direction position-relative'>
							<label>{translate('searchForSymptoms')}</label>
							<input
								type='search'
								disabled={props.selectedSymptoms.length >= SymptomsLength.MAX_SYMPTOMS}
								placeholder={intl.formatMessage({
									id: props.selectedSymptoms.length < SymptomsLength.MAX_SYMPTOMS ? 'typeSymptoms' : 'maxSymptomsNo',
								})}
								onChange={onSearch}
								value={capitalizeFirstLetter(searchBox)}
								maxLength={100}
							/>
							{searchBox !== '' && (
								<div className='full-page-input-results'>
									{filteredSymptoms.length > 0 &&
										filteredSymptoms.map(symptom => (
											<div key={symptom.id} onClick={() => addSymptom(symptom)} className='cursor-pointer'>
												<Highlighter searchWords={searchWords} autoEscape={true} textToHighlight={symptom.name} />
											</div>
										))}
									{filteredSymptoms.length === 0 && (
										<div>
											<span>{translate('noSymptomsFound')}</span>
										</div>
									)}
								</div>
							)}
						</div>
						<div className='flex column-direction'>
							<label>{translate('mySymptoms')}</label>
							<div className='patient-symptoms-list'>
								{!props.selectedSymptoms.length && <span>{intl.formatMessage({ id: 'selectedSymptoms' })}</span>}
								<div className='flex flex-wrap'>
									{props.selectedSymptoms.length > 0 &&
										props.selectedSymptoms.map(symptom => (
											<div className='symptom-btn' key={symptom.id}>
												<i className='material-icons' onClick={() => props.removeSymptom(symptom)}>
													close
												</i>
												{symptom.name}
											</div>
										))}
								</div>
							</div>
						</div>
						{props.questions.map(item => (
							<div className='flex column-direction' key={item.id}>
								<label>{item.question}</label>
								<input
									type='search'
									placeholder={intl.formatMessage({ id: 'typeHere' })}
									maxLength={50}
									value={capitalizeFirstLetter(healthConcern[`answer${item.id}`])}
									onChange={handleChange}
									name={`answer${item.id}`}
								/>
							</div>
						))}
						<div className='flex column-direction'>
							<label>{translate('additionalNotes')}</label>
							<Textarea
								maxLength={500}
								maxNumberLimit={50}
								rows={10}
								placeholder={intl.formatMessage({ id: 'typeHere' })}
								value={capitalizeFirstLetter(healthConcern.additionalNotes)}
								onChange={handleChange}
								name='additionalNotes'
							/>
						</div>

						<div className='attachments-wrapper'>
							<label>{translate('attachments')}</label>
							<Dropzone accept={Object.values(AcceptedFileTypes).toString()} onDrop={uploadedFileHandler}>
								{({ getRootProps, getInputProps }) => (
									<>
										<div className='drag-drop-user-img available-doctors-drag-drop cursor-pointer' {...getRootProps()}>
											<input {...getInputProps()} />
											<div className='flex flex-align-center'>
												<img src={`${healthCareCdnUrl}upload-attachment.svg`} alt='ico' />
												<p>
													<span>{translate('clickHere')}</span> {translate('orDragAndDrop')}
												</p>
											</div>
										</div>
										<div className='drag-drop-img-gallery flex'>
											{healthConcern.attachments.length > 0 &&
												healthConcern.attachments.map(attachment => {
													const attachmentIcon = getAttachmentIcon(attachment.name);
													return (
														<>
															{attachmentIcon !== 'invalidFileType' && (
																<div className='doctor-request-photo-upload' key={attachment.id}>
																	<i className='material-icons cursor-pointer' onClick={() => onFileDeleteHandler(attachment.id)}>
																		close
																	</i>
																	<img src={attachmentIcon} alt='file-icon' />
																	<a href={attachment.content} rel='noopener noreferrer' target='_blank' download>
																		{attachment.name}
																	</a>
																	{uploadProgress !== 100 && uploadProgress !== 0 && !attachment.isUploadCompleted && (
																		<div>
																			<CircularProgressbar value={uploadProgress} text={`${uploadProgress}%`} />
																		</div>
																	)}
																</div>
															)}
															<ToastMessage
																showToast={attachmentIcon === 'invalidFileType'}
																onClose={() => onFileDeleteHandler(attachment.id)}>
																<img src={`${healthCareCdnUrl}footer-icons/decline.svg?v3`} alt='icon' />
																<span>{translate('invalidFileType')}</span>
															</ToastMessage>
														</>
													);
												})}
										</div>
									</>
								)}
							</Dropzone>
						</div>
					</div>
				</div>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</Grid>
	);
};

export default PatientHealthDetails;
