import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Voice = props => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			color={LightTheme.colors.grayZero}
			{...props}>
			<path
				d='M10.6971 11.2497C10.6971 7.92834 13.5291 5 16.8962 5C20.2583 5 22.9975 7.70404 23 11.0254C23 13.0775 21.7589 14.7142 20.0303 15.8328V20L14 21V18.5L11.8806 18.7164C11.2628 18.7795 10.7375 18.2701 10.7816 17.6506L11 14.5829H9.5L10.6971 11.2497Z'
				stroke='currentColor'
				strokeLinejoin='round'
			/>
			<path d='M4 19C4 19 2 17.125 2 14C2 10.875 4 9 4 9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
			<path
				d='M6 17C6 17 5 15.8692 5 14C5 12.1308 6 11 6 11'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default Voice;
