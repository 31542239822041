import { getPatientsBasedOnProvider } from 'api/dashboard.js';
import Alert from 'components/Alert.jsx';
import Badge from 'components/Badge.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Modal from 'components/Modal.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { CompanySettings } from 'constants/configurationEnums.js';
import { formatDateHeader } from 'constants/dashboard.js';
import { CallStatus } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { Incoming, Outgoing } from 'icons/Dashboard/index.js';
import { getCallStatus, getCallType } from 'infrastructure/helpers/callLogsHelper.js';
import { getConfigurationValue, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { formatTimeDurationWithSeconds, timezoneToUTCTimestamp, utcToTimezone } from 'infrastructure/helpers/dateHelper.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const ProvidersVisits = props => {
	const [sessions, setSessions] = useState([]);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [sessionDetails, setSessionDetails] = useState(null);
	const [sessionStatAlarms, setSessionStatAlarms] = useState([]);
	const [showSessionDetailsModal, setShowSessionDetailsModal] = useState(false);
	const [copiedConferenceId, setCopiedConferenceId] = useState('');
	const conferenceIdCopiedTimeout = useRef(null);
	const controller = useRef(null);
	const companyConfigurations = useSelector(state => state.company.companySettings.companyConfigurations);

	const intl = useIntl();

	const fetchProviderSessions = useCallback(
		debounce(async (params, signal) => {
			const response = await getPatientsBasedOnProvider(params, signal);
			if (!response.error) {
				setPagination(prevState => ({ ...prevState, totalCount: response.totalCount || 0 }));
				setSessions(prevState => [...prevState, ...response.sessions]);
			} else if (response.error.code !== 'ERR_CANCELED') {
				setError(
					translate('somethingWentWrongSpecific', {
						value: intl.formatMessage({ id: 'patients' }),
					})
				);
			}
			setIsLoading(false);
		}, 500),
		[]
	);

	useEffect(() => {
		if (!props.selectedProvider || !props.selectedProviderRole.id) {
			return;
		}
		if (controller.current) {
			controller.current.abort();
		}
		controller.current = new AbortController();
		const signal = controller.current.signal;

		const params = {
			start: timezoneToUTCTimestamp(props.start, props.selectedTimezone.zone),
			end: timezoneToUTCTimestamp(props.end, props.selectedTimezone.zone),
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			roleId: props.selectedProviderRole.id,
			healthSystemId: props.selectedHealthSystem,
			regionId: props.selectedHospital?.regionId,
			hospitalId: props.selectedHospital?.value,
			departmentId: props.selectedDepartment?.value,
			floorId: props.selectedFloor?.value,
			roomId: props.selectedRoom?.value,
			providerId: props.selectedProvider?.userIntId,
			...props.additionalFilters,
		};
		setIsLoading(true);
		fetchProviderSessions(params, signal);
	}, [
		props.start,
		props.end,
		props.selectedDepartment,
		props.selectedFloor,
		props.selectedHealthSystem,
		props.selectedHospital,
		props.selectedRoom,
		props.selectedProvider,
		pagination.pageIndex,
		pagination.pageSize,
		props.selectedProviderRole,
		props.selectedTimezone,
		intl,
		props.additionalFilters,
		fetchProviderSessions,
	]);

	useEffect(() => {
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
		setSessions([]);
	}, [props.selectedProviderRole]);

	const isPatientDisabled = () =>
		getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_VIRTUAL_ADMISSION]) &&
		!getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_MANUAL_ADMISSION]);

	const displayCallStatus = status => {
		const translationKey = stringToCamelCase(getCallStatus(status));
		return (
			<Badge
				text={translationKey ? translate(`${translationKey}OnList`) : 'N/A'}
				variant={status === CallStatus.SUCCESSFUL.type ? 'green' : 'red'}
			/>
		);
	};

	const openSessionDetailsModal = patient => {
		const { usedAudio, usedScreen, usedVideo, statAlarms } = patient;
		setSessionDetails({
			usedAudio: translate(usedAudio ? 'yes' : 'no'),
			usedVideo: translate(usedVideo ? 'yes' : 'no'),
			usedScreen: translate(usedScreen ? 'yes' : 'no'),
		});
		setSessionStatAlarms(
			statAlarms?.map(statAlarm => ({
				activationTime: utcToTimezone(statAlarm.enabledDate, props.selectedTimezone.zone),
				deactivationTime: utcToTimezone(statAlarm.disabledDate, props.selectedTimezone.zone),
				duration:
					statAlarm.enabledDate && statAlarm.disabledDate
						? formatTimeDurationWithSeconds({
								seconds: statAlarm.durationInSeconds || 0,
								joinWith: ', ',
								showDays: false,
								intl,
						  })
						: 'N/A',
			})) || []
		);
		setShowSessionDetailsModal(true);
	};

	const handleCopyConferenceId = conferenceId => {
		if (conferenceIdCopiedTimeout.current) {
			clearTimeout(conferenceIdCopiedTimeout.current);
		}
		navigator.clipboard.writeText(conferenceId);
		setCopiedConferenceId(`${intl.formatMessage({ id: 'copied' })} ${intl.formatMessage({ id: 'conferenceId' })}!`);
		conferenceIdCopiedTimeout.current = setTimeout(() => {
			setCopiedConferenceId('');
		}, 3000);
	};

	const displayProviders = () => {
		if (!sessions || sessions.length === 0) {
			return [];
		}
		return sessions.map(session => ({
			conferenceId: (
				<i
					className='material-icons-outlined cursor-pointer font-14'
					data-tooltip={`${intl.formatMessage({ id: 'copy' })} ${intl.formatMessage({ id: 'conferenceId' })}`}
					onClick={() => handleCopyConferenceId(session.conferenceId)}>
					content_copy
				</i>
			),
			callOrigin: (
				<div className='inline-block' data-tooltip={intl.formatMessage({ id: session.isInitiator ? 'incoming' : 'outgoing' })}>
					{session.isInitiator && <Incoming color='var(--red-3)' data-tooltip={translate('incoming')} width={22} height={22} />}
					{!session.isInitiator && (
						<Outgoing color='var(--green-4)' data-tooltip={translate('outgoing')} width={22} height={22} />
					)}
				</div>
			),
			callType: getCallType(session.callType),
			careEventType: session?.careEventTypeName || 'N/A',
			...(!isPatientDisabled() && {
				patient: (
					<div className='flex flex-align-center'>
						{session.patient && (
							<ProfilePicture
								className='patient-request-details-profile'
								fullName={session.patient}
								profilePicture={session.picture}
							/>
						)}
						<div className='patient-name-mrn-wrapper'>
							<p>{session.patient}</p>
							{session.patientMRN && <p>MRN {session.patientMRN}</p>}
						</div>
					</div>
				),
			}),
			room: session.name,
			timeJoined: utcToTimezone(session.timeJoined, props.selectedTimezone.zone),
			endedTime: utcToTimezone(session.endedTime, props.selectedTimezone.zone),
			duration: formatTimeDurationWithSeconds({
				seconds: session.duration,
				joinWith: ', ',
				showDays: false,
				intl,
			}),
			callStatus: displayCallStatus(session.callStatus),
			sessionDetails: (
				<span className='text-underline cursor-pointer' onClick={() => openSessionDetailsModal(session)}>
					{translate('viewDetails')}
				</span>
			),
		}));
	};
	return (
		<>
			<div className='margin-top-m'>
				<CustomTable
					headers={[
						{ title: 'ID', id: 'conferenceId' },
						{ title: translate('callOrigin'), id: 'callOrigin', columnClass: 'text-align-center' },
						{ title: translate('sessionType'), id: 'callType' },
						{ title: translate('careEventType'), id: 'careEventType' },
						!isPatientDisabled() && { title: translate('patient'), id: 'patient' },
						{ title: translate('room'), id: 'room' },
						{ title: formatDateHeader(intl, 'sessionStart', props.selectedTimezone.zone), id: 'timeJoined' },
						{ title: formatDateHeader(intl, 'sessionEnd', props.selectedTimezone.zone), id: 'endedTime' },
						{ title: translate('sessionDuration'), id: 'duration' },
						{ title: translate('sessionStatus'), id: 'callStatus' },
						{ title: translate('sessionDetails'), id: 'sessionDetails' },
					]}
					rows={displayProviders()}
					isLoading={isLoading}
					stickyHeader={true}
					setPagination={setPagination}>
					<h4 className='no-margin no-padding'>{translate('sessions')}</h4>
				</CustomTable>
			</div>
			<Modal
				display={showSessionDetailsModal}
				position='center'
				hideActionButtons={true}
				onModalSubmit={null}
				onModalClose={() => setShowSessionDetailsModal(false)}>
				<form className='call-details'>
					<h4>{translate('mediaUsage')}</h4>
					{!!sessionDetails && (
						<CustomTable
							headers={[
								{ title: translate('audioUsed'), id: 'usedAudio' },
								{ title: translate('videoUsed'), id: 'usedVideo' },
								{ title: translate('screenUsed'), id: 'usedScreen' },
							]}
							rows={[sessionDetails]}
						/>
					)}
					<h4>{translate('statAlarms')}</h4>
					{!!sessionStatAlarms && (
						<CustomTable
							headers={[
								{ title: translate('activationTime'), id: 'activationTime' },
								{ title: translate('deactivationTime'), id: 'deactivationTime' },
								{ title: translate('duration'), id: 'duration' },
							]}
							rows={sessionStatAlarms}
							tableEmptyText={translate('noStatAlarmsActivated')}
						/>
					)}
				</form>
			</Modal>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
			<Alert display={copiedConferenceId} fixed={true} message={copiedConferenceId} variant='dark' />
		</>
	);
};

export default ProvidersVisits;
