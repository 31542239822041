import { IceCandidate, Offer } from '@solaborate/calls/webrtc';

export default class Signaling {
	constructor(socket, poolId, participantId, userInfo, conversationId) {
		this.socket = socket;
		this.poolId = poolId;
		this.participantId = participantId;
		this.userInfo = userInfo;
		this.conversationId = conversationId;
	}

	emit = event => {
		switch (event.constructor) {
			case Offer:
				this.socket.emit('ptt.offer', {
					poolId: this.poolId,
					participantId: this.participantId,
					sdp: event,
				});
				break;
			case IceCandidate:
				if (!event.candidate) return;
				this.socket.emit('ptt.ice', {
					poolId: this.poolId,
					participantId: this.participantId,
					candidate: {
						sdp: event.candidate.candidate,
						sdpMLineIndex: event.candidate.sdpMLineIndex,
						sdpMid: event.candidate.sdpMid,
					},
				});
				break;
			default:
		}
	};

	// eslint-disable-next-line no-unused-vars
	on = event => {};

	talking = talking => {
		this.socket.emit('ptt.talking', {
			poolId: this.poolId,
			participantId: this.participantId,
			talking: talking,
			conversationId: this.conversationId,
		});
	};

	leave = () => {
		this.socket.emit('ptt.leave', {
			poolId: this.poolId,
			participantId: this.participantId,
		});
	};

	join = () => {
		return new Promise((resolve, reject) => {
			setTimeout(reject, 5000);
			this.socket.emit(
				'ptt.join',
				{
					poolId: this.poolId,
					participantId: this.participantId,
					...this.userInfo,
				},
				ack => (ack.ok ? resolve(ack) : reject())
			);
		});
	};
}
