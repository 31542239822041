import { Modal, Button } from 'calls/components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { useConference } from 'calls/hooks/index.js';
import { ButtonType } from 'constants/enums.js';
import { useIntl } from 'react-intl';
import { dialOutTranslator } from 'api/sipProvider.js';

/**
 * @param {object} props
 * @param {(err) => void} props.setError
 * @param {(err) => void} props.onDismiss
 * @param {object} props.interpretationConfig
 */

const InviteAmnView = ({ onDismiss, setError, interpretationConfig }) => {
	const conference = useConference();
	const intl = useIntl();

	const removeParticipantOnNoAnswer = (participantId, message = '') => {
		setTimeout(() => {
			const participant = conference.participants.get(participantId);
			if (participant) {
				conference.removeParticipant(participantId);
				onDismiss(message);
			}
		}, 60000);
	};

	const inviteDialOutTranslator = async () => {
		const response = await dialOutTranslator(interpretationConfig.sipEndpoint, conference.conferenceId);
		if (response.error) {
			setError(translate('phoneNotReachable'));
		} else {
			removeParticipantOnNoAnswer(response.participantId, intl.formatMessage({ id: 'unableToConnectInterpreter' }));
			onDismiss();
		}
	};

	return (
		<>
			<Modal.Content>
				<div className='translation-services'>
					<div className='interpreter-desc'>
						<p>{translate('inviteInterpreterDesc')}</p>
					</div>
				</div>
			</Modal.Content>
			<Modal.Actions>
				<Button type='submit' onClick={inviteDialOutTranslator} variant={ButtonType.SUBMIT}>
					{translate('inviteInterpreter')}
				</Button>
			</Modal.Actions>
		</>
	);
};

export default InviteAmnView;
