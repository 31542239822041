import React from 'react';

const Conversation = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='1.25'
			d='M.9 5.7v7.539c0 1.622 1.829 2.57 3.154 1.633l1.39-.981a2 2 0 011.154-.367H14.1a3 3 0 003-3V5.7a3 3 0 00-3-3H3.9a3 3 0 00-3 3z'
		/>
		<path stroke='currentColor' strokeLinecap='round' strokeWidth='1.25' d='M4.5 6.3h9M4.5 9.9H9' />
	</svg>
);

export default Conversation;
