import React, { useState, useEffect } from 'react';
import { publicIp } from 'public-ip';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Grid, Button } from 'components/index.js';
import ContinueAs from 'containers/ContinueAs.jsx';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';
import { useSelector } from 'react-redux';

const UserIPError = () => {
	const userRoles = useSelector(state => state.user.userRoles);
	const [ipAddress, setIPAddress] = useState('');
	const [showSwitchRole, setShowSwitchRole] = useState(false);
	const intl = useIntl();
	const history = useHistory();

	const translator = id => intl.formatMessage({ id });

	useEffect(() => {
		const fetchIpAddress = async () => {
			try {
				const ip = await publicIp();
				setIPAddress(ip);
			} catch (error) {
				setIPAddress('Failed to fetch IP');
			}
		};

		fetchIpAddress();
	}, []);

	const switchRole = () => {
		setShowSwitchRole(true);
		getStorage().removeItem('isContinueAs');
		getStorage().removeItem('IPAddressDenied');
	};

	const signOut = () => {
		history.push('/logout');
	};

	if (showSwitchRole) {
		return <ContinueAs />;
	}

	return (
		<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
			<div className='member-error'>
				<h3>{`${translator('notAuthorizedFromThisIP')} ${ipAddress}`}</h3>
				<img src='https://static.solaborate.com/errors/user-ip-error-1.svg' alt='member-error' />
				<p>{translator('ifYouThinkThereAreMistakes')}</p>
				{userRoles.length > 1 && <Button text={translator('switchRole')} onClick={switchRole} variant='white' />}{' '}
				<Button text={translator('logOut')} onClick={signOut} />
			</div>
		</Grid>
	);
};

export default UserIPError;
