import React, { useCallback, useMemo } from 'react';
import { Chart } from 'primereact/chart.js';
import { MeasurementTypes } from 'constants/enums.js';
import { calculateTrendline, getMeasurementColor } from 'infrastructure/helpers/measurementsHelper.js';
import { useIntl } from 'react-intl';

const MeasurementsGraph = ({ measurement, chartData, chartDataSecond, chartLabels }) => {
	const intl = useIntl();
	const isBloodPressure = measurement.type === MeasurementTypes.BLOOD_PRESSURE;
	const measurementColor = getMeasurementColor(measurement.type);

	const getDataSetsAndLabels = useCallback(() => {
		let dataSets = [];
		if (isBloodPressure) {
			dataSets = [
				{
					label: intl.formatMessage({ id: 'systolic' }),
					data: chartData,
					fill: false,
					borderColor: measurementColor,
				},
				{
					label: intl.formatMessage({ id: 'diastolic' }),
					data: chartDataSecond,
					fill: false,
					borderColor: '#804040',
				},
			];
		} else {
			dataSets = [
				{
					label: measurement.link,
					data: chartData,
					fill: false,
					borderColor: measurementColor,
					backgroundColor: [
						MeasurementTypes.STEPS,
						MeasurementTypes.FLIGHTS_CLIMBED,
						MeasurementTypes.DISTANCE_WALKING_RUNNING,
					].includes(measurement.type)
						? measurementColor
						: 'transparent',
				},
			];
		}

		return dataSets;
	}, [chartData, chartDataSecond, intl, isBloodPressure, measurement.link, measurement.type]);

	const trendlineValues = useMemo(
		() => getDataSetsAndLabels().map(dataset => calculateTrendline(dataset.data.map(Number))),
		[getDataSetsAndLabels]
	);

	const data = useMemo(() => {
		return {
			labels: chartLabels,
			datasets: [
				...getDataSetsAndLabels().map(dataset => ({
					type: 'scatter',
					label: dataset.label,
					data: dataset.data.map((value, i) => ({ x: i, y: Number(value) })),
					backgroundColor: dataset.backgroundColor || '#ff8080',
					borderColor: dataset.borderColor || '#ff8080',
					showLine: false,
					fill: dataset.fill,
					pointRadius: 5,
				})),
				...trendlineValues.map((trendline, index) => ({
					type: 'line',
					label: `${getDataSetsAndLabels()[index].label} Trendline`,
					data: trendline.map((value, i) => ({ x: i, y: value })),
					borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'][index % 2],
					fill: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'][index % 2],
					pointRadius: 0,
				})),
			],
		};
	}, [chartLabels, getDataSetsAndLabels, trendlineValues]);

	const options = {
		scales: {
			xAxes: [
				{
					ticks: {
						callback: label => {
							return chartLabels[label];
						},
					},
				},
			],
		},
		tooltips: {
			callbacks: {
				label: (tooltipItem, data) => {
					const dataset = data.datasets[tooltipItem.datasetIndex];
					const date = data.labels[tooltipItem.index];
					const value = dataset.data[tooltipItem.index].y;

					if (dataset.label?.toLowerCase().includes('systolic')) {
						return `Date: ${date}, Systolic: ${value.toFixed(2)}`;
					} else if (dataset.label?.toLowerCase().includes('diastolic')) {
						return `Date: ${date}, Diastolic: ${value.toFixed(2)}`;
					}

					return `Date: ${date}, Value: ${value.toFixed(2)}`;
				},
			},
		},
	};

	return (
		<div className='patient-iot-history-graph'>
			<Chart type='scatter' data={data} options={options} />
		</div>
	);
};

export default MeasurementsGraph;
