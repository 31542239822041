import React, { useRef } from 'react';
import translate from 'i18n-translations/translate.jsx';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import moment from 'moment';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import { AppointmentStatus, UserRoles } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { useIntl } from 'react-intl';

const AppointmentSlotDetails = ({
	appointmentEvent,
	appointmentDetails,
	setIsEventEditModal,
	toggleEditAppointmentModal,
	openCancelAppointmentModal,
	openCompleteAppointmentModal,
}) => {
	const intl = useIntl();

	const { current: userId } = useRef(getUserId());
	const { current: userRole } = useRef(getUserRole());

	const isEditButtonShown =
		userId === appointmentEvent.extendedProps.createdBy.id &&
		appointmentEvent.extendedProps.statusId === AppointmentStatus.PENDING &&
		moment().diff(appointmentEvent.start) < 0;

	const isDeleteButtonShown =
		(appointmentEvent.extendedProps.statusId === AppointmentStatus.ACCEPTED ||
			(appointmentEvent.extendedProps.createdBy.id === userId &&
				appointmentEvent.extendedProps.statusId === AppointmentStatus.PENDING)) &&
		moment().diff(appointmentEvent.start) < 0;

	const isCompleteButtonShown =
		appointmentEvent.extendedProps.statusId === AppointmentStatus.ACCEPTED &&
		userRole === UserRoles.DOCTOR &&
		moment().diff(appointmentEvent.start) < 0;

	const getPrimaryButtonInfo = () => {
		if (isCompleteButtonShown) {
			return {
				textButton: intl.formatMessage({ id: 'complete' }),
				backgroundColor: 'var(--green-4-o10)',
				color: 'var(--green-4)',
				icon: <i className='material-icons complete-appointment'>done</i>,
			};
		}
		if (isEditButtonShown) {
			return {
				textButton: intl.formatMessage({ id: 'edit' }),
				backgroundColor: 'var(--orange-1-o10)',
				color: 'var(--orange-1)',
				icon: <i className='material-icons edit-appointment'>edit</i>,
			};
		}
		return { textButton: '', backgroundColor: '', color: '', icon: '' };
	};

	const handleSubmit = () => {
		setIsEventEditModal(false);
		if (isCompleteButtonShown) {
			openCompleteAppointmentModal(appointmentEvent.extendedProps?.publicId);
		} else if (isEditButtonShown) {
			toggleEditAppointmentModal(appointmentEvent.extendedProps.publicId);
		}
	};

	const deleteAppointment = () => {
		setIsEventEditModal(false);
		openCancelAppointmentModal(appointmentEvent.extendedProps?.publicId);
	};

	return (
		<Modal
			className='wrapper-modal border-radius-modal-wrapper saved-successfully no-padding-appointment-modal appointment-details'
			display={true}
			position='center'
			primaryButtonLabel={getPrimaryButtonInfo().textButton}
			onModalSubmit={handleSubmit}
			primaryButtonIcon={getPrimaryButtonInfo().icon}
			background={getPrimaryButtonInfo().backgroundColor}
			color={getPrimaryButtonInfo().color}
			thirdButtonLabel={translate('delete')}
			onThirdButtonClick={deleteAppointment}
			thirdImgIcon={`${healthCareCdnUrl}appointments/trash.svg`}
			thirdButtonColor='var(--red-3)'
			isThirdButtonShown={isDeleteButtonShown}
			thirdBtnBackground='var(--red-3-o10)'
			closeButtonText={translate('close')}
			onModalClose={() => setIsEventEditModal(false)}
			closeImgIcon={`${healthCareCdnUrl}cancel.svg`}>
			<Form height={500} className='modal-form-wrapper'>
				<h3>{translate('appointmentDetail')}</h3>
				<div className='event-edit-popup'>
					<h3 className='event-popup-title'>{appointmentEvent.title}</h3>

					<div className='flex'>
						<ProfilePicture
							className='doctor-request-img'
							firstName={appointmentDetails.createdByFN}
							lastName={appointmentDetails.createdByFN}
							profilePicture={appointmentDetails.createdByPic}
						/>
						<div>
							<p>{translate('createdBy')}:</p>
							<p>
								{appointmentDetails.createdByFN} {appointmentDetails.createdByLN}
							</p>
						</div>
					</div>

					<div className='flex flex-align-center'>
						<ProfilePicture
							className='doctor-request-img'
							firstName={appointmentDetails.invitedFN}
							lastName={appointmentDetails.invitedLN}
							profilePicture={appointmentDetails.invitedPic}
						/>
						<div>
							<p>{translate('invitedMember')}:</p>
							<p className='appointment-span'>
								{appointmentDetails.invitedFN} {appointmentDetails.invitedLN}
							</p>
						</div>
					</div>
					<div className='flex'>
						<img src={`${healthCareCdnUrl}appointments/Date.svg`} alt='ico' />
						<div>
							<p>{translate('meetingDate')}</p>
							<p>
								{moment(appointmentEvent.extendedProps.appointmentSlot.startDateTime)
									.locale(getPreferredLanguageLocale())
									.format('DD MMMM YYYY')}
							</p>
						</div>
					</div>
					<div className='flex'>
						<img src={`${healthCareCdnUrl}appointments/Time.svg`} alt='ico' />
						<div>
							<p>{translate('meetingTime')}</p>
							<p>
								{translate('from')}{' '}
								{`${moment
									.utc(appointmentEvent.extendedProps.appointmentSlot.startDateTime)
									.local()
									.locale(getPreferredLanguageLocale())
									.format('hh:mm A')} `}
								{translate('to')}{' '}
								{`${moment
									.utc(appointmentEvent.extendedProps.appointmentSlot.endDateTime)
									.local()
									.locale(getPreferredLanguageLocale())
									.format('hh:mm A')}`}
							</p>
						</div>
					</div>
				</div>
			</Form>
		</Modal>
	);
};

export default AppointmentSlotDetails;
