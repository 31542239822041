import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ProfilePicture from 'components/ProfilePicture.jsx';
import translate from 'i18n-translations/translate.jsx';
import { AppointmentStatus, UserRoles } from 'constants/enums.js';
import { getPreferredLanguageLocale, getStatus } from 'infrastructure/helpers/commonHelpers.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import RequestAttachments from 'components/RequestAttachments.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import Button from 'components/Button.jsx';

const AppointmentParticipantProfile = props => {
	const { current: userRole } = useRef(getUserRole());
	const [doctorSpecialty, setDoctorSpecialty] = useState('');
	const doctor = useSelector(state => state.myDoctors.doctors);

	useEffect(() => {
		if (userRole === UserRoles.PATIENT) {
			const { userId } = props.selectedRequest.participant;
			setDoctorSpecialty(doctor.find(item => item.userId === userId)?.specialty.name);
		}
	}, [userRole, props.selectedRequest.participant, doctor]);

	return (
		<div className='patient-request-detail appointment-request-detail selected-appointment'>
			<h4>{userRole === UserRoles.DOCTOR ? translate('patient') : translate('doctor')}</h4>
			<div className='patient-request-detail-title'>
				<div>
					<ProfilePicture
						className='patient-request-details-profile'
						firstName={props.selectedRequest.participant.firstName}
						lastName={props.selectedRequest.participant.lastName}
						profilePicture={props.selectedRequest.participant.profilePicture}
					/>
					<div>
						<p>
							{props.selectedRequest.participant.firstName} {props.selectedRequest.participant.lastName}
						</p>
					</div>
				</div>
				<div>
					<div className='doctor-request-info-btn flex doctor-position cursor-pointer' onClick={props.toggleViewProfileModal}>
						<img src={`${healthCareCdnUrl}doctor-request/view-profile.svg`} alt='icon' />
						{translate('viewSomeonesProfile', { value: props.selectedRequest.participant.firstName })}
					</div>
				</div>
			</div>
			<div className='patient-request-detail-info app-patient-request-detail-info'>
				<h2>{translate('description')}</h2>
				{props.selectedRequest.description && <p>{props.selectedRequest.description}</p>}
				{!props.selectedRequest.description && <p className='italic-style'>{translate('notSpecified')}</p>}
			</div>
			<div className='patient-request-detail-info'>
				<h2>{translate('topic')}</h2>
				<p>{props.selectedRequest.title}</p>
			</div>
			<div className='doctor-request-detail-status doctor-request-detail-symptoms padding-top-xxl'>
				<h2>{translate('status')}</h2>
				<p className={`doctor-request-info-btn doctor-status ${getStatus(props.status)}`}>{translate(getStatus(props.status))}</p>
			</div>
			{userRole === UserRoles.PATIENT && (
				<div className='patient-request-detail-info'>
					<h2>{translate('category')}</h2>
					<div className='flex'>
						<div className='doctor-request-info-btn flex doctor-position'>{doctorSpecialty || translate('noCategory')}</div>
					</div>
				</div>
			)}

			<div className='doctor-request-appointments patient-request-detail-info'>
				<h2>{translate('requestedAppointment')}</h2>
				<p>
					{`${moment(props.selectedRequest.appointmentSlot.startDateTime)
						.locale(getPreferredLanguageLocale())
						.local()
						.format('DD MMMM')} `}
					{translate('from')}{' '}
					{`${moment
						.utc(props.selectedRequest.appointmentSlot.startDateTime)
						.local()
						.locale(getPreferredLanguageLocale())
						.format('hh:mm A')} `}
					{translate('to')}{' '}
					{`${moment
						.utc(props.selectedRequest.appointmentSlot.endDateTime)
						.local()
						.locale(getPreferredLanguageLocale())
						.format('hh:mm A')}`}
				</p>
			</div>

			<RequestAttachments attachments={props.selectedRequest.appointmentAttachments} />
			{props.status === AppointmentStatus.ACCEPTED && (
				<div className='doctor-request-detail-status doctor-request-detail-symptoms reassign-req-btn-wrapper'>
					<Button
						type='button'
						className='reschedule-btn cursor-pointer'
						onClick={() => props.toggleRescheduleModal(props.selectedRequest.id)}
						text={translate('reSchedule')}
					/>
					{userRole === UserRoles.DOCTOR && (
						<Button
							type='button'
							className='reschedule-btn canceled-btn cursor-pointer'
							onClick={() => props.updateAppointmentStatus(props.selectedRequest.id, AppointmentStatus.CANCELED)}
							text={translate('cancelAppointment')}
						/>
					)}
				</div>
			)}

			{props.status === AppointmentStatus.PENDING && (
				<div className='doctor-request-detail-status doctor-request-detail-symptoms reassign-req-btn-wrapper'>
					<div
						className='doctor-request-info-btn flex reject-position cursor-pointer'
						onClick={() => props.updateAppointmentStatus(props.selectedRequest.id, AppointmentStatus.REJECTED)}>
						{translate('rejectRequest')}
					</div>
					<div
						className='doctor-request-info-btn flex accept-position cursor-pointer'
						onClick={() => props.updateAppointmentStatus(props.selectedRequest.id, AppointmentStatus.ACCEPTED)}>
						{translate('acceptRequest')}
					</div>
				</div>
			)}

			{props.status === AppointmentStatus.REJECTED && (
				<div className='patient-request-detail-buttons'>
					<Button icon='close' className='material-icons-outlined' text={translate('rejected')} />
				</div>
			)}
		</div>
	);
};

export default AppointmentParticipantProfile;
