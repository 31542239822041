import * as React from 'react';

const Profile = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} viewBox='0 0 22 24' fill='none' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M16.5 19.773v-2.295a4.5 4.5 0 0 0-4.5-4.5v0a4.5 4.5 0 0 0-4.5 4.5v2.295M14.455 17.727v2.864M9.545 17.727v2.864M14.454 8.727a2.454 2.454 0 1 1-4.908 0 2.454 2.454 0 0 1 4.909 0Z'
		/>
		<circle cx={12} cy={12} r={9} stroke='currentColor' strokeWidth={1.5} />
	</svg>
);
export default Profile;
