import React, { useEffect, useRef, useState, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { LocalTrack, ScreenShare } from '@solaborate/calls/webrtc';
import { useConferenceConfigurations } from 'calls/hooks/index.js';

/**
 * @type {import('styled-components').StyledComponent<"video", any, { $isLocalParticipantTrack?: boolean, $isScreenShare?: $isScreenShare, $isPortrait?: boolean }, never>}
 */
const StyledVideo = styled.video`
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
	object-fit: cover;

	${props => css`
		${props.$isLocalParticipantTrack && !props.$isScreenShare && `transform: scaleX(-1);`}

		${(props.$isPortrait || props.$isScreenShare) &&
		css`
			position: relative;
			height: 100%;
			width: auto;
			object-fit: contain;
			display: flex;
			margin: 0 auto;
		`}
	`}
`;

/**
 * @typedef {{ track: import('@solaborate/calls/webrtc').Track }} Props
 */
const ParticipantVideo = forwardRef(
	(
		/**
		 * @type {Props & React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>}
		 */
		{ track, ...htmlAttributes },
		/** @type {React.RefObject<HTMLVideoElement>} */
		ref
	) => {
		const videoRef = useRef(null);
		const localParticipantTrack = track instanceof LocalTrack;
		const { isFitScreen } = useConferenceConfigurations();
		const [isPortrait, setIsPortrait] = useState(false);

		useEffect(() => {
			// eslint-disable-next-line no-param-reassign
			videoRef.current.srcObject = track ? new MediaStream([track.track]) : null;

			const onLoaded = ({ target }) => {
				if (target) {
					const isPortraitVideo = target.videoWidth <= target.videoHeight;
					setIsPortrait(isPortraitVideo);
				}
			};

			if (videoRef.current) {
				videoRef.current.addEventListener('loadedmetadata', onLoaded);
			}

			if (ref) {
				// @ts-ignore
				// eslint-disable-next-line no-param-reassign
				ref.current = videoRef.current;
			}

			return () => {
				if (videoRef.current) {
					// eslint-disable-next-line react-hooks/exhaustive-deps
					videoRef.current.removeEventListener('loadedmetadata', onLoaded);
				}
			};
		}, [track, ref]);

		return (
			<StyledVideo
				{...htmlAttributes}
				ref={videoRef}
				autoPlay
				playsInline
				controls={false} /* this is needed for Safari on iOS */
				$isLocalParticipantTrack={localParticipantTrack}
				$isScreenShare={track?.type === ScreenShare}
				$isPortrait={isPortrait || isFitScreen}
			/>
		); /* playsInline is put for Safari in iOS */
	}
);
ParticipantVideo.displayName = 'ParticipantVideo';

export default ParticipantVideo;
