import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import sq from 'date-fns/locale/sq/index.js';
import en from 'date-fns/locale/en-US/index.js';
import moment from 'moment';
import classNames from 'classnames';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import Loader from 'components/Loader.jsx';

const CreateAppointment = props => {
	registerLocale('en-US', en);
	registerLocale('sq', sq);
	const language = useSelector(state => state.language.locale);
	const intl = useIntl();
	const getInitialValues = () => ({
		topic: '',
		appointmentSlotId: '',
		appointmentSelectedDate: props.appointmentSelectedDate,
	});

	const selectedAppointmentDateFormat = selectedDate => {
		const today = moment();
		const tomorrow = moment().add(1, 'days');
		if (moment(selectedDate).isSame(today, 'day')) {
			return translate('lowercaseToday');
		}
		if (moment(selectedDate).isSame(tomorrow, 'day')) {
			return translate('lowercaseTomorrow');
		}
		return moment(selectedDate).format('D/M/YYYY');
	};

	const getValidationSchema = () => {
		const validationSchema = {
			topic: Yup.string()
				.required(
					intl.formatMessage({
						id: 'titleIsRequired',
					})
				)
				.max(255, `${intl.formatMessage({ id: 'maxLengthIs' })} 255`)
				.trim(),
			appointmentSlotId: Yup.string().required(
				intl.formatMessage({
					id: 'appointmentSlotIsRequired',
				})
			),
		};
		return Yup.object().shape(validationSchema);
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={getInitialValues()}
			validationSchema={getValidationSchema()}
			onSubmit={props.onScheduleAppointmentClick}>
			{formikProps => {
				const { values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting, setFieldValue } = formikProps;
				return (
					<>
						<Modal
							modalSelector='createAppointmentModal'
							display={props.isAppointmentModalVisible}
							onModalSubmit={handleSubmit}
							position='center'
							primaryButtonLoading={isSubmitting}
							onModalClose={() => props.closeAppointmentModal(formikProps.resetForm)}
							primaryButtonLabel={intl.formatMessage({ id: 'scheduleAppointment' })}
							shouldSubmitOnEnter={false}
							className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal header-w-border'
							isSubmitDisabled={!props.availableAppointments.length}>
							<Form height={500} className='modal-form-wrapper'>
								<h3>{translate('scheduleAppointment')}</h3>
								<div className='create-appointment-modal'>
									<div>
										<label htmlFor='topic'>*{translate('topic')}</label>
										<input
											type='text'
											name='topic'
											value={values.topic}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder={intl.formatMessage({ id: 'enterTopic' })}
											maxLength={255}
										/>
										{errors.topic && touched.topic && <span className='red-error create-app-error'>{errors.topic}</span>}
									</div>
									<div className='appointment-date-wrapper'>
										<label htmlFor='date'>*{translate('appointmentDate')}</label>
										<DatePicker
											className='appointment-topic'
											selected={values.appointmentSelectedDate}
											onChange={event => props.onAppointmentsCalendarChange(event, setFieldValue)}
											minDate={new Date()}
											dateFormat='MM/dd/yyyy'
											onKeyDown={event => event.preventDefault()}
											locale={language}
										/>
										{errors.appointmentSelectedDate && touched.appointmentSelectedDate && (
											<span className='red-error'>{errors.appointmentSelectedDate}</span>
										)}
									</div>
									<div>
										{props.availableAppointments.length === 0 && !props.isTimeSlotsLoading && (
											<p className={classNames('font-14', !props.availableAppointments.length ? 'red-error' : '')}>
												{translate('thereAreAppointmentsFor', { value: props.availableAppointments.length })}{' '}
												{selectedAppointmentDateFormat(values.appointmentSelectedDate)}.{' '}
												{!props.availableAppointments.length && intl.formatMessage({ id: 'pleaseChooseAnotherDate' })}
											</p>
										)}
										<div className='flex available-appointments calendar-events-app-modal'>
											{props.isTimeSlotsLoading && (
												<div style={{ width: '100%', position: 'absolute', right: 'var(--spacing-xl)' }}>
													<Loader />
												</div>
											)}
											{props.availableAppointments.length > 0 &&
												props.availableAppointments.map(appointmentSlot => (
													<div key={appointmentSlot.id} className='appointments-list'>
														<label className='cursor-pointer'>
															<Field
																type='radio'
																onChange={() => setFieldValue('appointmentSlotId', appointmentSlot.id)}
																onBlur={handleBlur}
																value={values.appointmentSlotId}
																name='appointmentSlotId'
																checked={values?.appointmentSlotId === appointmentSlot.id}
															/>

															<div className='active-av-app' />
															<span>
																{`${moment
																	.utc(appointmentSlot.startDateTime)
																	.local()
																	.locale(getPreferredLanguageLocale())
																	.format('hh:mm A')} - ${moment
																	.utc(appointmentSlot.endDateTime)
																	.local()
																	.locale(getPreferredLanguageLocale())
																	.format('hh:mm A')}`}
															</span>
														</label>
													</div>
												))}
											{errors.appointmentSlotId && touched.appointmentSlotId && (
												<span className='red-error create-app-error'>{errors.appointmentSlotId}</span>
											)}
										</div>
									</div>
								</div>
							</Form>
						</Modal>
					</>
				);
			}}
		</Formik>
	);
};

export default CreateAppointment;
