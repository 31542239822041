import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Alert, Form, Modal } from 'components/index.js';
import Select from 'react-select';
import { DayPeriodTypes, TeamTypes } from 'constants/enums.js';
import { useSelector } from 'react-redux';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import { addSleepScheduleConfiguration, editSleepScheduleConfiguration } from 'api/whiteboardConfigurations.js';
import moment from 'moment';
import TimePicker from 'components/TimePicker.jsx';
import { getHospitalDepartments } from 'api/healthSystems.js';
import translate from 'i18n-translations/translate.jsx';
import { formatDateTimeToString } from 'infrastructure/helpers/dateHelper.js';

const delayOptions = translator => [
	{
		value: -1,
		label: translator('never'),
	},
	{
		value: 15,
		label: `15 ${translator('seconds')}`,
	},
	{
		value: 30,
		label: `30 ${translator('seconds')}`,
	},
	{
		value: 60,
		label: `1 ${translator('minute')}`,
	},
	{
		value: 300,
		label: `5 ${translator('nextMinutes')}`,
	},
	{
		value: 600,
		label: `10 ${translator('nextMinutes')}`,
	},
	{
		value: 900,
		label: `15 ${translator('nextMinutes')}`,
	},
];

const emptyInitialValues = {
	displayOffTime: new Date(),
	displayOnTime: new Date(),
	screenDelay: null,
	selectedHealthSystem: null,
	selectedHospital: null,
	selectedDepartment: null,
};

const validateTime = (startTime, endTime) => {
	const timeDifference = Math.ceil(endTime.diff(startTime, 'seconds', true));
	if ((endTime.isAfter(startTime) && timeDifference >= 60) || endTime.isBefore(startTime, 'seconds')) {
		return true;
	}
	return false;
};

const SleepSchedule = props => {
	const { initialValues, toggleModal, isModalOpen, isFormLoading, getConfigurations, healthSystems, setIsFormLoading } = props;

	const intl = useIntl();
	const healthSystemsArray = useSelector(state => state.healthSystems.allHealthSystems);
	const [error, setError] = useState(null);
	const [hospitals, setHospitals] = useState([]);
	const [departments, setDepartments] = useState([]);

	const translator = id => intl.formatMessage({ id });

	const getInitialValues = () => {
		const delayToMinutes =
			initialValues.sleepSchedule.screenDelay >= 60
				? initialValues.sleepSchedule.screenDelay / 60
				: initialValues.sleepSchedule.screenDelay;

		let translatedTimeKey = 'seconds';
		if (initialValues.sleepSchedule.screenDelay === 60) {
			translatedTimeKey = 'minute';
		} else if (initialValues.sleepSchedule.screenDelay > 60) {
			translatedTimeKey = 'nextMinutes';
		} else if (initialValues.sleepSchedule.screenDelay < 0) {
			translatedTimeKey = 'never';
		}

		return {
			...initialValues,
			displayOffTime: moment.utc(initialValues.sleepSchedule.displayOffTime).local().toDate(),
			displayOnTime: moment.utc(initialValues.sleepSchedule.displayOnTime).local().toDate(),
			screenDelay: {
				value: initialValues.sleepSchedule.screenDelay,
				label: `${delayToMinutes > 0 ? delayToMinutes : ''} ${translator(translatedTimeKey)}`,
			},
			selectedHealthSystem: {
				value: initialValues.team.id,
				label: initialValues.team.name,
			},
		};
	};

	const getValidationSchema = () => {
		const validation = {};

		validation.displayOffTime = Yup.string().nullable().required(translator('requiredField'));
		validation.displayOnTime = Yup.string()
			.nullable()
			.required(translator('requiredField'))
			.test('different-times', translator('sleepScheduleTimeError'), displayOnTime =>
				validateTime(moment(values.displayOffTime), moment(displayOnTime))
			);
		validation.screenDelay = Yup.object().nullable().required(translator('requiredField'));

		if (!initialValues) {
			validation.selectedHealthSystem = Yup.object().nullable().required(translator('pleaseSelectHealthSystem'));
		}

		return validation;
	};

	const { values, errors, handleSubmit, resetForm, setFieldValue, touched } = useFormik({
		enableReinitialize: true,
		initialValues: initialValues ? getInitialValues() : emptyInitialValues,
		validationSchema: Yup.object().shape(getValidationSchema()),
		onSubmit: (values, { resetForm }) => onSave(values, resetForm),
	});

	const onSave = async (values, resetForm) => {
		const editMode = !!initialValues?.id;
		let response;

		const payload = {
			sleepSchedule: {
				displayOffTime: formatDateTimeToString(values.displayOffTime),
				displayOnTime: formatDateTimeToString(values.displayOnTime),
				screenDelay: values.screenDelay.value,
			},
			...(!editMode && {
				teamName:
					(values.selectedDepartment?.value && values.selectedDepartment?.label) ||
					(values.selectedHospital?.value && values.selectedHospital?.label) ||
					values.selectedHealthSystem.label,
			}),
		};

		setIsFormLoading(true);

		if (editMode) {
			response = await editSleepScheduleConfiguration({
				itemId: initialValues.id,
				teamId: initialValues.team.id,
				teamTypeId: initialValues.team.typeId,
				payload,
			});
		} else {
			const departmentId = values.selectedDepartment?.value;
			const hospitalId = values.selectedHospital?.value;
			const healthSystemId = values.selectedHealthSystem?.value;
			let teamTypeId = 0;
			if (departmentId) {
				teamTypeId = TeamTypes.DEPARTMENT;
			} else if (hospitalId) {
				teamTypeId = TeamTypes.HOSPITAL;
			} else {
				teamTypeId = TeamTypes.HEALTH_SYSTEM;
			}
			response = await addSleepScheduleConfiguration({
				teamId: departmentId || hospitalId || healthSystemId,
				teamTypeId,
				payload,
			});
		}

		if (!response.hasSucceeded || response.error) {
			setIsFormLoading(false);
			setError(response.error.message);
			return;
		}

		setIsFormLoading(false);
		onCloseModal(resetForm);
		getConfigurations();
	};

	const onCloseModal = resetForm => {
		resetForm();
		toggleModal();
		setHospitals([]);
		setDepartments([]);
	};

	const onFormHealthSystemSelect = async (values, setFieldValue) => {
		if (initialValues) {
			return;
		}
		const found = healthSystems?.find(item => item?.id === values?.value);
		const selectedHS = {
			label: found?.name || '',
			value: found?.id || '',
		};
		setFieldValue('selectedHealthSystem', selectedHS);
		const hsHospitals = await getHealthSystemHospitals(values?.value);
		if (hsHospitals?.error) {
			setError(hsHospitals?.error?.message);
			return;
		}
		setHospitals(hsHospitals);
		setFieldValue('selectedHospital', null);
		setFieldValue('selectedDepartment', null);
	};

	const onFormHospitalSelect = async (values, setFieldValue, hsValue) => {
		if (initialValues) {
			return;
		}
		if (values.label === intl.formatMessage({ id: 'allHospitals' })) {
			const selectedHospital = {
				label: values.label,
				value: null,
			};
			setFieldValue('selectedHospital', selectedHospital);
			setFieldValue('selectedDepartment', null);
			return;
		}
		const found = hospitals?.find(item => item?.id === values?.value);
		const selectedHospital = {
			label: found?.name || '',
			value: found?.id || '',
		};
		setFieldValue('selectedHospital', selectedHospital);
		const hospitalDepartmentsRes = await getHospitalDepartments(hsValue, values?.value);
		if (hospitalDepartmentsRes?.error) {
			setError(hospitalDepartmentsRes?.error?.message);
			return;
		}
		setDepartments(hospitalDepartmentsRes?.hospital?.departments);
		setFieldValue('selectedDepartment', null);
	};

	const onFormDepartmentSelect = (values, setFieldValue) => {
		if (props.initialValues) {
			return;
		}
		if (values.label === intl.formatMessage({ id: 'allDepartments' })) {
			const selectedDepartment = {
				label: values.label,
				value: null,
			};
			setFieldValue('selectedDepartment', selectedDepartment);
			return;
		}
		const found = departments?.find(item => item?.id === values?.value);
		const selectedDepartment = {
			label: found?.name || '',
			value: found?.id || '',
		};
		setFieldValue('selectedDepartment', selectedDepartment);
	};

	const handleSetTime = (value, key) => {
		const { hours, minutes, seconds, dayPeriod } = value;

		let adjustedHours = hours;

		if ((dayPeriod === DayPeriodTypes.PM && hours !== 12) || (dayPeriod === DayPeriodTypes.AM && hours === 12)) {
			adjustedHours = hours + 12;
		}

		const date = new Date();
		date.setHours(adjustedHours, minutes, seconds);

		setFieldValue(key, date);
	};

	const transformArray = (array, sector = '') => {
		if (!Array.isArray(array) || array?.length === 0) {
			return [];
		}
		const newArray = array.map(item => ({ value: item.id, label: item.name || item.value }));
		if (sector) {
			newArray.unshift({ value: '0', label: intl.formatMessage({ id: sector }) });
		}
		return newArray;
	};

	const getHealthSystemPlaceholder = () => {
		if (initialValues.team?.typeId === TeamTypes.HEALTH_SYSTEM) {
			return initialValues.team?.name;
		}
		return initialValues.team?.healthSystemName;
	};

	const getHospitalPlaceholder = () => {
		if (initialValues.team?.typeId === TeamTypes.HOSPITAL) {
			return initialValues.team?.name;
		}
		return initialValues.team?.hospitalName;
	};

	return (
		<>
			<Modal
				modalSelector='deviceConfigurationsModal'
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
				display={isModalOpen}
				position='right'
				onModalSubmit={handleSubmit}
				onModalClose={() => onCloseModal(resetForm)}
				isLoading={isFormLoading}
				shouldSubmitOnEnter={false}>
				<Form title={translator('sleepScheduleTitle')} onSubmit={event => event.preventDefault()} className='manage-hs-form'>
					<div className='sleep-schedule'>
						<div className='margin-bottom-m font-14'>{translator('sleepScheduleDescription')}</div>
						<div className='input'>
							<p className='label'>{translator(initialValues ? 'healthSystem' : 'selectHealthSystem')}</p>
							{!initialValues && <p className='font-14'>{translator('selectHSForConfiguration')}</p>}
							<Select
								value={!initialValues ? values.selectedHealthSystem : ''}
								placeholder={!initialValues ? intl.formatMessage({ id: 'selectHealthSystem' }) : getHealthSystemPlaceholder()}
								classNamePrefix='react-select'
								options={transformArray(healthSystemsArray)}
								onChange={event => onFormHealthSystemSelect(event, setFieldValue)}
								isDisabled={initialValues}
							/>
							<small>{touched.selectedHealthSystem && errors.selectedHealthSystem}</small>
						</div>
						{(!initialValues || [TeamTypes.HOSPITAL, TeamTypes.DEPARTMENT].includes(initialValues?.team?.typeId)) && (
							<div className='input'>
								<p className='label'>{translator(initialValues ? 'hospital' : 'selectHospital')}</p>
								{!initialValues && <p className='font-14'>{translator('chooseHospitalForChanges')}</p>}
								<Select
									value={!initialValues ? values.selectedHospital : ''}
									placeholder={!initialValues ? intl.formatMessage({ id: 'selectHospital' }) : getHospitalPlaceholder()}
									classNamePrefix='react-select'
									options={transformArray(hospitals, 'allHospitals')}
									onChange={event => onFormHospitalSelect(event, setFieldValue, values.selectedHealthSystem?.value)}
									isDisabled={!values.selectedHealthSystem || initialValues}
								/>
							</div>
						)}
						{(!initialValues || initialValues?.team?.typeId === TeamTypes.DEPARTMENT) && (
							<div className='input'>
								<p className='label'>{translator(initialValues ? 'department' : 'selectDepartment')}</p>
								{!initialValues && (
									<p className='font-14'>
										{translate('chooseSectorForChanges', {
											value: translator('department'),
										})}
									</p>
								)}
								<Select
									value={!initialValues ? values.selectedDepartment : ''}
									placeholder={!initialValues ? intl.formatMessage({ id: 'selectDepartment' }) : initialValues?.team?.name}
									classNamePrefix='react-select'
									options={transformArray(departments, 'allDepartments')}
									onChange={event => onFormDepartmentSelect(event, setFieldValue)}
									isDisabled={!values.selectedHospital?.value || initialValues}
								/>
							</div>
						)}
						<div className='flex flex-wrap sleep-schedule-time-pickers'>
							<div className='input'>
								<p className='label'>{translator('startTime')}</p>
								<div className='date-range-time-pickers'>
									<TimePicker value={values.displayOffTime} handleTimeChange={value => handleSetTime(value, 'displayOffTime')} />
								</div>
								<small>{touched.displayOffTime && errors.displayOffTime}</small>
							</div>

							<div className='input'>
								<p className='label'>{translator('endTime')}</p>
								<div className='date-range-time-pickers'>
									<TimePicker value={values.displayOnTime} handleTimeChange={value => handleSetTime(value, 'displayOnTime')} />
								</div>
								<small>
									{errors.displayOnTime === translator('requiredField') && touched.displayOnTime && errors.displayOnTime}
								</small>
							</div>
							<small className='sleep-schedule-time-error'>
								{errors.displayOnTime === translator('sleepScheduleTimeError') && touched.displayOnTime && errors.displayOnTime}
							</small>
						</div>
						<div className='input'>
							<p className='label'>{translator('screenTimeout')}</p>
							<Select
								value={values.screenDelay}
								placeholder={intl.formatMessage({ id: 'selectOption' })}
								classNamePrefix='react-select'
								options={delayOptions(translator)}
								className=''
								onChange={value => setFieldValue('screenDelay', value)}
								menuPlacement='top'
							/>
							<small>{touched.screenDelay && errors.screenDelay}</small>
						</div>
					</div>
				</Form>
			</Modal>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default SleepSchedule;
