import { getUsersRpmList } from 'api/rpm.js';
import Alert from 'components/Alert.jsx';
import CustomTable from 'components/CustomTable.jsx';
import FormInput from 'components/FormInput.jsx';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserId } from 'infrastructure/auth.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import MainLayout from 'views/Layouts/MainLayout.jsx';

const Logistics = () => {
	const [searchBox, setSearchBox] = useState('');
	const [patientLogistics, setPatientLogistics] = useState([]);
	const [pageIndex, setPageIndex] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const hasReachedEnd = useRef(null);
	const intl = useIntl();

	const getRpmPatients = useCallback(async () => {
		const response = await getUsersRpmList({ userId: getUserId(), pageIndex });
		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		setPatientLogistics(prevState => prevState.concat(response.rpmListPatients));
		hasReachedEnd.current = response.rpmListPatients.length < 60;
		setIsLoading(false);
	}, [pageIndex]);

	useEffect(() => {
		getRpmPatients();
	}, [getRpmPatients]);

	const getSearchedPatients = item => {
		const fullName = `${item.firstName} ${item.lastName}`;
		return (
			item.firstName?.toUpperCase().includes(searchBox.toUpperCase()) ||
			item.lastName?.toUpperCase().includes(searchBox.toUpperCase()) ||
			fullName?.toUpperCase().includes(searchBox.toUpperCase())
		);
	};

	const getFilteredPatients = () => {
		const patientsList = [...patientLogistics];
		let filteredList = patientsList;
		if (searchBox) {
			filteredList = patientsList.filter(item => getSearchedPatients(item));
		}
		return filteredList;
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const displayLogistics = () =>
		getFilteredPatients().map(patient => ({
			mrn: patient.mrn,
			patientName: `${patient.firstName} ${patient.lastName}`,
			rpmProgram: patient.rpmProgram?.name,
			shipping: `${patient.postalCode} ${patient.address} ${patient.city} ${patient?.country ?? ''}`,
			dateAdded: formattedDate(patient.dateCreated),
			status: (
				<div className='patient-alert-body-vs logistics position-relative'>
					<div className='shipped-wrapper'>
						<div className='green'>{translate('shipped')}</div>
					</div>
				</div>
			),
		}));

	return (
		<MainLayout showWarningAlert={true}>
			{isLoading && (
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			<div className='inner-main-view'>
				{!isLoading && (
					<div className='patient-alerts-wrapper rpm full-width'>
						<div className='flex flex-space-between flex-align-center'>
							<h3>{translate('logistics')}</h3>
						</div>
						<div className='flex full-width patients-alert-filter'>
							<FormInput
								id='searchBox'
								labelClassName='full-width'
								name='searchBox'
								text=''
								type='search'
								onChange={event => setSearchBox(event.target.value)}
								value={searchBox}
								placeholder={intl.formatMessage({ id: 'searchByName' })}
								className='full-width'
							/>
						</div>
						<div className='patients-alert-table-body rpm-scroll logistics' onScroll={handleScroll}>
							<CustomTable
								headers={[
									{ title: 'MRN', id: 'mrn' },
									{ title: translate('patientName'), id: 'patientName' },
									{ title: translate('rpmProgram'), id: 'rpmProgram' },
									{ title: translate('shippingAddress'), id: 'shipping' },
									{ title: translate('dateAdded'), id: 'dateAdded' },
									{ title: translate('status'), id: 'status' },
								]}
								rows={displayLogistics()}
								className='logistics-table'
								isEditable={false}
							/>
						</div>
					</div>
				)}
			</div>

			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</MainLayout>
	);
};

export default Logistics;
