import classNames from 'classnames';
import translate from 'i18n-translations/translate.jsx';
import { MeasurementTypes } from 'constants/enums.js';
import { HeartExaminationPoints, LungsExaminationPoints, MeasureStates } from 'constants/examinations.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Button from 'components/Button.jsx';

const HeartLungsExaminations = props => (
	<div className='flex column-direction flex-align-center margin-bottom-0'>
		<div onClick={() => props.setShowStethoscopeHistory(true)} className='cursor-pointer'>
			<p className='show-history-stethoscope'>{translate('showHistory')}</p>
		</div>
		<div
			className={classNames(
				'position-relative',
				props.selectedExamination.type === MeasurementTypes.HEART ? 'heart-stetho-wrapper' : 'lungs-stetho-wrapper',
				props.isConnected ? '' : 'hidden'
			)}>
			{props.selectedExamination.type === MeasurementTypes.HEART &&
				HeartExaminationPoints.map(item => (
					<Button
						type='button'
						className={props.selectedExaminationPoint === item.value && props.isConnected ? 'active' : ''}
						onClick={() => props.selectExaminationPoint(item.value)}
						text={item.point}
						marginRight={0}
					/>
				))}

			{props.selectedExamination.type === MeasurementTypes.LUNGS &&
				LungsExaminationPoints.map(item => (
					<Button
						type='button'
						className={props.selectedExaminationPoint === item.value && props.isConnected ? 'active' : ''}
						onClick={() => props.selectExaminationPoint(item.value)}
						text={item.point}
						marginRight={0}
					/>
				))}
			{![MeasureStates.CONNECT, MeasureStates.CONNECTING, MeasureStates.DISCONNECTED].includes(
				props.selectedExamination.buttonText
			) && (
				<img
					src={`${healthCareCdnUrl}vitals/stethoscope/${
						props.selectedExamination.type === MeasurementTypes.HEART ? 'heart.svg' : 'lungs.svg'
					}`}
					alt='ico'
				/>
			)}
		</div>
	</div>
);

export default HeartLungsExaminations;
