import { useState, useEffect, useRef } from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Grid from 'components/Grid.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { validateUserEmail } from 'api/users.js';

const VerifyChangedEmail = () => {
	const intl = useIntl();
	const params = useRef(queryString.parse(window.location.search));
	const [message, setMessage] = useState(null);

	useEffect(() => {
		const validateEmail = async () => {
			const response = await validateUserEmail({ email: params.current.e, changeEmailCode: params.current.code });
			setMessage(intl.formatMessage({ id: response.error ? 'invalidCode' : 'emailChanged' }));
		};

		validateEmail();
	}, []);

	return (
		<div className='login recover-password-wrapper'>
			<Grid width='500px' className='login__wrapper'>
				<div style={{ width: '300px' }}>
					<img src={`${healthCareCdnUrl}hellocare-white-logo.svg`} alt='healthcare logo' />
				</div>
				<div className='login-form-wrapper validate-recover-password recover-password'>
					<div className='warning-message'>
						<div>
							<span>{message}</span>
							<Link to='/login' className='link'>
								{intl.formatMessage({ id: 'goToLoginPage' })}
							</Link>
						</div>
					</div>
				</div>
			</Grid>
		</div>
	);
};

export default VerifyChangedEmail;
