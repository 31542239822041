import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Alert from 'components/Alert.jsx';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import { actionCreators as configurationActionCreators } from 'state/configurations/actions.js';
import { getUserRole } from 'infrastructure/auth.js';
import { setUserPreference } from 'api/users.js';
import {
	AdminMenu,
	DigitalClinicianMenu,
	DoctorMenu,
	GeneralAndMenuSettings,
	NurseMenu,
	PatientMenu,
	SuperAdminMenu,
	SuperUserMenu,
} from 'constants/configurationEnums.js';
import { HealthSystemType, TreeHierarchyType } from 'constants/enums.js';
import { UserRoles } from 'constants/enums.js';
import {
	getConfigurationMenu,
	getConfigurationValue,
	getSomeRoleConfigurationsValues,
} from 'infrastructure/helpers/commonHelpers.js';

const ConfigurableMenu = ({ setIsMenuSettingsOpen }) => {
	const { current: userRole } = useRef(getUserRole());
	const dispatch = useDispatch();
	const helloName = useSelector(state => state.company.companySettings.helloName);
	const [isPrimaryCareType, setIsPrimaryCareType] = useState(false);
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const isAssignedToDoctors = useSelector(state => state.user.isAssignedToDoctors);
	const user = useSelector(state => state.user);
	const configurations = useSelector(state => state.configurations);
	const companySettings = useSelector(state => state.company.companySettings);
	const configurableMenu = getConfigurationMenu(configurations.configurableMenu, userRole);
	const adminConfigurableMenu = getConfigurationMenu(configurations.adminConfigurableMenu, userRole);
	const customRoleConfigurations = useSelector(state => state.configurations.customRoleConfigurations);

	const isSubConfigActive = getSomeRoleConfigurationsValues(customRoleConfigurations, [
		GeneralAndMenuSettings.RPM,
		GeneralAndMenuSettings.CPM,
	]);

	useEffect(() => {
		setIsPrimaryCareType(user.userSession.healthSystem.workflowTypeId === HealthSystemType.PRIMARY_CARE);
	}, [user.userSession]);

	const menuPreferenceTypes = {
		[AdminMenu.ORGANIZATION]: 'organization',
		[AdminMenu.CALL_LOGS]: 'callLogs',
		[AdminMenu.USER_MANAGEMENT]: 'userManagement',
		[AdminMenu.CONFIGURATION]: 'configurations',
		[AdminMenu.FLEET_MANAGEMENT]: 'fleetManagement',
		[AdminMenu.AUDIT_LOGS]: 'auditLogs',
		[AdminMenu.NURSE_STATION]: 'nurseStation',
		[SuperUserMenu.CONFIGURATION]: 'configurations',
		[SuperUserMenu.QUEUE_MANAGEMENT]: 'queueManagement',
		[SuperUserMenu.DASHBOARD]: 'dashboard',
		[SuperUserMenu.NURSE_STATION]: 'nurseStation',
		[SuperAdminMenu.COMPANIES]: 'companies',
		[DigitalClinicianMenu.REGISTER_PATIENT]: 'checkInPatient',
		[DigitalClinicianMenu.PATIENTS]: !isPrimaryCareType && 'patients',
		[DoctorMenu.PATIENT_MONITORING]: isSubConfigActive && 'patientMonitoring',
		[DigitalClinicianMenu.VISITS]: isPrimaryCareType && 'visits',
		[DoctorMenu.HOME]: !isPrimaryCareType && 'homePage',
		[DoctorMenu.WAITING_ROOM]:
			(getUserRole() === UserRoles.DOCTOR || (getUserRole() === UserRoles.NURSE && isAssignedToDoctors)) && 'waitingRoom',
		[DoctorMenu.APPOINTMENTS]: !isPrimaryCareType && 'appointments',
		[DoctorMenu.ROUNDING]: !isPrimaryCareType && 'rounding',
		[DoctorMenu.PATIENTS]: !isPrimaryCareType && 'patients',
		[DoctorMenu.HOSPITAL_AT_HOME]: 'hospitalAtHome',
		[PatientMenu.RECENT]: 'recent',
		[PatientMenu.SUMMARY]: 'summary',
		[PatientMenu.HELLO]: helloName,
		[PatientMenu.HEALTH]: 'health',
		[NurseMenu.CHAT]: !isPrimaryCareType && 'chat',
		[NurseMenu.TEAMS]:
			!isPrimaryCareType &&
			[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM].includes(
				user.userSession.healthSystem.treeHierarchyTypeId
			) &&
			'teams',
		[NurseMenu.MONITORING]: !isPrimaryCareType && 'monitoring',
		[NurseMenu.WAITING_FOR_RESULTS]: isPrimaryCareType && 'waitingForResults',
		[NurseMenu.VISITORS]: !isPrimaryCareType && 'visitors',
		[NurseMenu.CARE_EVENTS]: 'careEvents',
		[NurseMenu.HOSPITAL_AT_HOME]: 'hospitalAtHome',
		[NurseMenu.AMBIENT_MONITORING]: !isPrimaryCareType && 'careNotifications',
	};

	const generalPreferenceTypes = {
		[GeneralAndMenuSettings.NOTIFICATIONS]: 'notifications',
		...(getUserRole() === UserRoles.NURSE && {
			[GeneralAndMenuSettings.CONVERSATION_HISTORY]: 'conversationHistory',
			[GeneralAndMenuSettings.MONITORING_PRECAUTIONS_LEGEND]: 'monitoringPrecautionsLegend',
			[GeneralAndMenuSettings.POOLING_FLOW]: 'poolingFlow',
			[GeneralAndMenuSettings.MONITORING_SESSIONS]: 'savedSessions',
		}),
		...(getUserRole() === UserRoles.VIRTUAL_SITTER && {
			[GeneralAndMenuSettings.MONITORING_PRECAUTIONS_LEGEND]: 'monitoringPrecautionsLegend',
			[GeneralAndMenuSettings.MONITORING_SESSIONS]: 'savedSessions',
		}),
		...([UserRoles.NURSE, UserRoles.DOCTOR].includes(getUserRole()) && {
			[GeneralAndMenuSettings.CONVERSATION_FILES]: 'conversationFiles',
			[GeneralAndMenuSettings.CONVERSATION_PHOTOS]: 'conversationPhotos',
		}),
	};

	const isTheLastUnCheckedMenu = configKey => {
		const result = [];
		if (configurableMenu[configKey].isGeneralSetting) {
			return false;
		}
		const includedRoles = [UserRoles.DOCTOR, UserRoles.DIGITAL_CLINICIAN, UserRoles.NURSE].includes(getUserRole());
		if (includedRoles) {
			Object.entries(configurableMenu).forEach(([key, item]) => {
				if (
					!item.isGeneralSetting &&
					item.value &&
					configurations.adminConfigurableMenu[userRole][key]?.value &&
					menuPreferenceTypes[key]
				) {
					result.push(key);
				}
			});
		} else {
			Object.entries(configurableMenu).forEach(([key, item]) => {
				if (!item.isGeneralSetting && item.value) {
					result.push(key);
				}
			});
		}
		return result.length === 1 && getConfigurationValue(configurableMenu[+configKey]);
	};

	const toggleMenuConfigurableAlert = async key => {
		if (isLoading) {
			return;
		}
		if (isTheLastUnCheckedMenu(key)) {
			return;
		}
		setIsLoading(true);

		const dataToSend = {
			preference: {
				preferenceType: +key,
				value: (!getConfigurationValue(configurableMenu[key])).toString(),
			},
		};
		const response = await setUserPreference(dataToSend);
		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		dispatch(
			configurationActionCreators.setConfigurableMenu({
				...configurations.configurableMenu,
				[userRole]: {
					...configurableMenu,
					[key]: {
						...configurableMenu[key],
						value: !getConfigurationValue(configurableMenu[key]),
					},
				},
			})
		);
		setIsLoading(false);
	};

	const showItem = key => {
		const nonConfigurableMenus = [UserRoles.SUPER_ADMIN, UserRoles.PATIENT].includes(userRole);
		if (nonConfigurableMenus && menuPreferenceTypes[key]) {
			return true;
		}
		if (!nonConfigurableMenus && getConfigurationValue(adminConfigurableMenu[key]) && menuPreferenceTypes[key]) {
			return true;
		}
		return false;
	};

	const hasMenuItems = () =>
		Object.keys(adminConfigurableMenu)
			.filter(item => !configurableMenu[item].isGeneralSetting)
			.some(key => getConfigurationValue(adminConfigurableMenu[+key]) && showItem(key));

	const hasGeneralSettings = () =>
		Object.keys(adminConfigurableMenu)
			.filter(item => configurableMenu[item].isGeneralSetting)
			.some(key => getConfigurationValue(adminConfigurableMenu[+key]));

	const getGeneralSettings = () => {
		const result = {};
		Object.keys(configurations.adminConfigurableMenu[userRole]).forEach(item => {
			if (configurableMenu[item].isGeneralSetting) {
				result[item] = configurations.adminConfigurableMenu[userRole][item].value;
			}
		});
		return result;
	};

	const showGeneralConfig = key => {
		const adminMenu = getConfigurationMenu(configurations.adminConfigurableMenu, userRole);
		return getConfigurationValue(adminMenu[key]) && generalPreferenceTypes[key];
	};

	return (
		<Modal
			display={true}
			position='center'
			className='configurable-settings-modal border-radius-modal-wrapper menu-configurable-modal standard-modal-wrapper'
			onModalClose={setIsMenuSettingsOpen}
			onModalSubmit={setIsMenuSettingsOpen}>
			<div className='configurable-settings-modal-inner'>
				{(hasMenuItems() || ![UserRoles.NURSE, UserRoles.VIRTUAL_SITTER, UserRoles.DOCTOR].includes(userRole)) && (
					<div className='configurable-settings-main-title'>
						<h3>{translate('menuSettings')} </h3>
					</div>
				)}
				<div className='flex flex-wrap'>
					{Object.keys(configurableMenu).map(
						key =>
							showItem(key) && (
								<div className='flex' key={key} onClick={() => toggleMenuConfigurableAlert(+key)}>
									{+key !== PatientMenu.HELLO && (
										<p className='flex-1'>
											{translate(menuPreferenceTypes[key], {
												roleNameNurse: companySettings.nurseDisplayName,
												roleNameVS: companySettings.virtualSitterDisplayName,
											})}
										</p>
									)}
									{+key === PatientMenu.HELLO && <p className='flex-1'>{menuPreferenceTypes[key]}</p>}
									<div className='rounded-slider-switch'>
										<input type='checkbox' checked={getConfigurationValue(configurableMenu[key])} onChange={() => null} />
										<span className='rounded-slider' />
									</div>
								</div>
							)
					)}
				</div>
				{[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.VIRTUAL_SITTER, UserRoles.SUPER_USER].includes(
					userRole
				) && (
					<>
						{hasGeneralSettings() && (
							<div className='configurable-settings-main-title top-15'>
								<h3>{translate('generalSettings')}</h3>
							</div>
						)}
						<div className='flex flex-wrap'>
							{Object.keys(getGeneralSettings()).map(
								key =>
									(UserRoles.PATIENT === userRole || showGeneralConfig(+key)) && (
										<div className='flex' key={key} onClick={() => toggleMenuConfigurableAlert(+key)}>
											<p className='flex-1'>
												{translate(generalPreferenceTypes[key], {
													roleNameNurse: companySettings.nurseDisplayName,
													roleNameVS: companySettings.virtualSitterDisplayName,
												})}
											</p>
											<div className='rounded-slider-switch'>
												<input type='checkbox' checked={getConfigurationValue(configurableMenu[key])} onChange={() => null} />
												<span className='rounded-slider' />
											</div>
										</div>
									)
							)}
						</div>
					</>
				)}
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</Modal>
	);
};

export default ConfigurableMenu;
