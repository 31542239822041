import { AuthConsumer } from 'providers/authProvider.jsx';
import Loader from 'components/Loader.jsx';
import Grid from 'components/Grid.jsx';

const LogoutCallback = () => (
	<AuthConsumer>
		{({ signoutRedirectCallback }) => {
			signoutRedirectCallback();
			return (
				<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			);
		}}
	</AuthConsumer>
);

export default LogoutCallback;
