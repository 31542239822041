import { gatewayApi } from 'constants/global-variables.js';

export const getPharmacies = async zip => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/pharmacies/walgreens?affId=HLLHLTHCR&zip=${zip}&r=20&s=20&requestType=locator&appVer=2.0`
		);
		return data.results ? data.results : [];
	} catch (error) {
		return { error };
	}
};

export const getUserPharmacy = async userId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/users/${userId}/pharmacies`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const saveUserPharmacy = async ({ userId, deliveryTypeId, pharmacy }) => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/users/${userId}/pharmacies`, {
			deliveryTypeId: deliveryTypeId,
			pharmacy: pharmacy,
		});
		return data;
	} catch (error) {
		return { error };
	}
};
