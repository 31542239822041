import * as React from 'react';

const GlucoseIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none' {...props}>
		<path
			stroke='#247EF9'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M14.156 7.809C12.82 6.018 8.8 1 8.8 1S4.6 6.241 3.36 7.922A6.975 6.975 0 0 0 2 12.077C2 15.9 5.044 19 8.8 19a6.68 6.68 0 0 0 2.36-.428m2.996-10.763a5.757 5.757 0 0 0-.823-.059c-3.13 0-5.666 2.518-5.666 5.625 0 2.342 1.442 4.35 3.493 5.197m2.996-10.763c2.74.395 4.844 2.737 4.844 5.566C19 16.482 16.463 19 13.333 19c-.77 0-1.504-.152-2.173-.428m2.173-7.62v1.125m0 0v1.125m0-1.125H12.2m1.133 0h1.134M12.2 15.625h2.267'
		/>
	</svg>
);
export default GlucoseIcon;
