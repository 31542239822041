import { MeasurementTypes, AnalysesColors, UnitCategoryTypes, MeasurementUnits } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';

export const measurementsToShow = [
	{
		id: 0,
		title: {
			text: translate('heartRate'),
			color: AnalysesColors.HEART,
			icon: `${healthCareCdnUrl}footer-icons/new/Heart.svg`,
		},
		unit: MeasurementUnits.BPM,
		type: MeasurementTypes.HEART_RATE,
	},
	{
		id: 1,
		title: {
			text: translate('oxygenSaturation'),
			color: AnalysesColors.RESPIRATORY,
			icon: `${healthCareCdnUrl}footer-icons/new/Respiratory.svg`,
		},
		unit: '%',
		type: MeasurementTypes.OXYGEN,
	},
	{
		id: 2,
		title: {
			text: translate('perfusionIndex'),
			color: AnalysesColors.HEART,
			icon: `${healthCareCdnUrl}footer-icons/new/Heart.svg`,
		},
		unit: '%',
		type: MeasurementTypes.PI,
	},
	{
		id: 3,
		title: {
			text: translate('bloodPressure'),
			color: AnalysesColors.HEART,
			icon: `${healthCareCdnUrl}footer-icons/new/Heart.svg`,
		},
		unit: 'mmHg',
		type: MeasurementTypes.BLOOD_PRESSURE,
	},
	{
		id: 4,
		title: {
			text: translate('bloodGlucose'),
			color: AnalysesColors.BLOOD_GLUCOSE,
			icon: `${healthCareCdnUrl}footer-icons/new/BloodGlucose.svg`,
		},
		unit: '',
		type: MeasurementTypes.DIABETES,
		unitCategoryId: UnitCategoryTypes.BLOOD_GLUCOSE,
	},
	{
		id: 5,
		title: {
			text: translate('bodyTemperature'),
			color: AnalysesColors.BODY_MEASUREMENTS,
			icon: `${healthCareCdnUrl}footer-icons/new/Body-Measurements.svg`,
		},
		unit: '',
		type: MeasurementTypes.TEMPERATURE,
		unitCategoryId: UnitCategoryTypes.TEMPERATURE,
	},
	{
		id: 6,
		title: {
			text: translate('stethoscope'),
			color: AnalysesColors.STETHOSCOPE,
			icon: `${healthCareCdnUrl}footer-icons/new/Stethoscope.svg`,
		},
		unit: 's',
		type: MeasurementTypes.STETHOSCOPE,
	},
	{
		id: 7,
		title: {
			text: translate('weight'),
			color: AnalysesColors.BODY_MEASUREMENTS,
			icon: `${healthCareCdnUrl}footer-icons/new/Body-Measurements.svg`,
		},
		unit: '',
		type: MeasurementTypes.WEIGHT,
		unitCategoryId: UnitCategoryTypes.WEIGHT,
	},
];

export const cameraMeasurementsToShow = [
	{
		id: 0,
		title: {
			text: translate('heartRate'),
			color: AnalysesColors.HEART,
			icon: `${healthCareCdnUrl}footer-icons/new/Heart.svg`,
		},
		unit: MeasurementUnits.BPM,
		type: MeasurementTypes.HEART_RATE,
	},
	{
		id: 1,
		title: {
			text: translate('bloodPressure'),
			color: AnalysesColors.HEART,
			icon: `${healthCareCdnUrl}footer-icons/new/Heart.svg`,
		},
		unit: 'mmHg',
		type: MeasurementTypes.BLOOD_PRESSURE,
	},
	{
		id: 2,
		title: {
			text: translate('stressLevel'),
			color: AnalysesColors.STRESS_LEVEL,
			icon: `${healthCareCdnUrl}footer-icons/new/Heart.svg`,
		},
		unit: ' ',
		type: MeasurementTypes.STRESS_LEVEL,
	},
	{
		id: 3,
		title: {
			text: translate('bloodGlucose'),
			color: AnalysesColors.BLOOD_GLUCOSE,
			icon: `${healthCareCdnUrl}footer-icons/new/Heart.svg`,
		},
		unit: 'mg/Dl',
		type: MeasurementTypes.BLOOD_GLUCOSE,
	},
	{
		id: 4,
		title: {
			text: translate('respiratoryRate'),
			color: AnalysesColors.RESPIRATORY,
			icon: `${healthCareCdnUrl}footer-icons/new/Respiratory.svg`,
		},
		unit: MeasurementUnits.BPM,
		type: MeasurementTypes.RESPIRATORY_RATE,
	},
	{
		id: 5,
		title: {
			text: translate('oxygenSaturation'),
			color: AnalysesColors.RESPIRATORY,
			icon: `${healthCareCdnUrl}footer-icons/new/Respiratory.svg`,
		},
		unit: '%',
		type: MeasurementTypes.OXYGEN,
	},
	{
		id: 6,
		title: {
			text: translate('hvrIndex'),
			color: AnalysesColors.HVR_INDEX,
			icon: `${healthCareCdnUrl}footer-icons/new/Respiratory.svg`,
		},
		unit: 'ms',
		type: MeasurementTypes.HRV_INDEX,
	},

	{
		id: 7,
		title: {
			text: translate('bilirubin'),
			color: AnalysesColors.BILIRUBIN,
			icon: `${healthCareCdnUrl}footer-icons/new/Respiratory.svg`,
		},
		unit: 'mg/Dl',
		type: MeasurementTypes.BILIRUBIN,
	},
];

export const weightTypes = [
	{
		id: 1,
		title: {
			text: translate('weight'),
			color: AnalysesColors.BODY_MEASUREMENTS,
			icon: `${healthCareCdnUrl}footer-icons/new/Body-Measurements.svg`,
		},
		unit: '',
		type: MeasurementTypes.WEIGHT,
		unitCategoryId: UnitCategoryTypes.WEIGHT,
	},
	{
		id: 2,
		title: {
			text: translate('bodyMassIndex'),
			color: AnalysesColors.BODY_MEASUREMENTS,
			icon: `${healthCareCdnUrl}footer-icons/new/Body-Measurements.svg`,
		},
		unit: '',
		type: MeasurementTypes.BODY_MASS_INDEX,
	},
	{
		id: 3,
		title: {
			text: translate('bodyFat'),
			color: AnalysesColors.BODY_MEASUREMENTS,
			icon: `${healthCareCdnUrl}footer-icons/new/Body-Measurements.svg`,
		},
		unit: '%',
		type: MeasurementTypes.BODY_FAT,
	},
	{
		id: 4,
		title: {
			text: translate('leanBodyMass'),
			color: AnalysesColors.BODY_MEASUREMENTS,
			icon: `${healthCareCdnUrl}footer-icons/new/Body-Measurements.svg`,
		},
		unit: '',
		type: MeasurementTypes.LEAN_BODY_MASS,
		unitCategoryId: UnitCategoryTypes.WEIGHT,
	},
	{
		id: 5,
		title: {
			text: translate('visceralFatGrade'),
			color: AnalysesColors.BODY_MEASUREMENTS,
			icon: `${healthCareCdnUrl}footer-icons/new/Body-Measurements.svg`,
		},
		unit: '',
		type: MeasurementTypes.VISCERAL_FAT_GRADE,
	},
	{
		id: 6,
		title: {
			text: translate('bodyWater'),
			color: AnalysesColors.BODY_MEASUREMENTS,
			icon: `${healthCareCdnUrl}footer-icons/new/Body-Measurements.svg`,
		},
		unit: '%',
		type: MeasurementTypes.BODY_WATER,
	},
	{
		id: 7,
		title: {
			text: translate('muscleMass'),
			color: AnalysesColors.BODY_MEASUREMENTS,
			icon: `${healthCareCdnUrl}footer-icons/new/Body-Measurements.svg`,
		},
		unit: '',
		type: MeasurementTypes.MUSCLE_MASS,
		unitCategoryId: UnitCategoryTypes.WEIGHT,
	},
];

export const profileCreationMeasurementElements = {
	height: {
		unit: 'cm (Centimeter)',
		option: 'HEIGHT',
		unitCategory: 'HEIGHT',
		decimal: 0,
	},
	weight: {
		unit: 'kg (Kilogram)',
		option: 'WEIGHT',
		unitCategory: 'WEIGHT',
		decimal: 2,
	},
	cholesterol: {
		unit: 'mmol/L (Millimoles per Liter)',
		option: 'BLOOD_GLUCOSE',
		unitCategory: 'BLOOD_GLUCOSE',
		decimal: 2,
	},
	waist_circumference: {
		unit: 'cm (Centimeter)',
		option: 'WAIST_CIRCUMFERENCE',
		unitCategory: 'HEIGHT',
		decimal: 2,
	},
};

export const HealthDataTabs = {
	healthSummary: { type: 0 },
	heart: { type: 1 },
	heartRate: { type: 2, parentType: 1 },
	bloodPressure: { type: 3, parentType: 1 },
	pi: { type: 4, parentType: 1 },
	activity: { type: 5 },
	steps: { type: 6, parentType: 5 },
	flightsClimbed: { type: 7, parentType: 5 },
	distanceWalkingRunning: { type: 8, parentType: 5 },
	bodyMeasurements: { type: 9 },
	weight: { type: 10, parentType: 9 },
	temperature: { type: 11, parentType: 9 },
	leanBodyMass: { type: 12, parentType: 9 },
	bodyFat: { type: 13, parentType: 9 },
	bodyMassIndex: { type: 14, parentType: 9 },
	waistCircumference: { type: 15, parentType: 9 },
	respiratory: { type: 16 },
	oxygen: { type: 17, parentType: 16 },
	respiratoryRate: { type: 18, parentType: 16 },
	sleep: { type: 19 },
	sleepAnalysis: { type: 20, parentType: 19 },
	diabetes: { type: 21 },
	stethoscope: { type: 22 },
	heartStethoscope: { type: 23, parentType: 22 },
	lungsStethoscope: { type: 24, parentType: 22 },
	numberOfTimesFallen: { type: 25 },
	cholesterol: { type: 26 },
	totalCholesterol: { type: 27, parentType: 26 },
	hdlCholesterol: { type: 28, parentType: 26 },
	otoscope: { type: 29 },
	ear: { type: 30, parentType: 29 },
	throat: { type: 31, parentType: 29 },
	dermatoscope: { type: 32 },
	skin: { type: 33, parentType: 32 },
	spirometry: { type: 34 },
};
