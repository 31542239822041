import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { getFormattedValidDate } from 'infrastructure/helpers/dateHelper.js';

const FleetManagementDeviceStatus = ({ onlineDevices, solHelloDeviceId, lastOnline, isPtzDevice }) => {
	const intl = useIntl();
	const isChildOnline = onlineDevices.includes(solHelloDeviceId);
	const onlineText = isPtzDevice ? 'ptzConnected' : 'online';
	const offlineText = isPtzDevice ? 'ptzDisconnected' : 'offline';

	return (
		<div
			data-tooltip={
				!isChildOnline && !isPtzDevice
					? `${intl.formatMessage({ id: 'lastOnline' })} ${getFormattedValidDate(lastOnline)} UTC`
					: null
			}
			data-position='top'
			className={classNames('status', { online: isChildOnline })}>
			{intl.formatMessage({ id: isChildOnline ? onlineText : offlineText })}
		</div>
	);
};

export default FleetManagementDeviceStatus;
