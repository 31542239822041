import translate from 'i18n-translations/translate.jsx';

const AiConfigRadioButton = ({ key, aiConfig, arrayName, radioButtonName, handleRadioButtonChange, isImbalanceType = false }) => (
	<div className='flex position-relative consent-from-patient margin-right-m margin-top-s' key={key}>
		<label className='remember-me cursor-pointer position-relative flex'>
			<input
				className='auto-width ai-configuration-details'
				type='radio'
				checked={aiConfig.isChecked || false}
				value={aiConfig.value}
				name={radioButtonName}
				onChange={() => handleRadioButtonChange({ value: aiConfig.value, arrayName })}
			/>
			<div className='onb-custom-checkbox-wrapper cursor-pointer top-0 ai-configurations-checkbox'>
				<div className='onb-custom-checkbox' />
			</div>
			{!isImbalanceType && <span className='left-s margin-right-m'>{aiConfig.label}</span>}
			{isImbalanceType && (
				<>
					{aiConfig.value !== 0 && (
						<span className='margin-left-s'>
							{aiConfig.value}
							<sup>{aiConfig.superScript}</sup>
						</span>
					)}
					<span className='left-s margin-right-m'>{translate(aiConfig.value === 0 ? 'noAlert' : 'imbalance')}</span>
				</>
			)}
		</label>
	</div>
);

export default AiConfigRadioButton;
