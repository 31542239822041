import * as React from 'react';

const RemovePatient = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M6 20v-2.286C6 14.558 8.686 12 12 12a6.15 6.15 0 0 1 4 1.455'
		/>
		<circle cx={18} cy={17} r={4} stroke='currentColor' strokeLinecap='round' strokeWidth={1.5} />
		<path stroke='currentColor' strokeLinecap='round' strokeWidth={1.5} d='m19.5 15.5-3 3m0-3 3 3' />
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M8 17.5V20M15.25 6.75a3.25 3.25 0 1 1-6.5 0 3.25 3.25 0 0 1 6.5 0Z'
		/>
	</svg>
);
export default RemovePatient;
