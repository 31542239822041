import React from 'react';
import styled from 'styled-components';

/**
 * @typedef {{ $size: number }} props
 * @type {import('styled-components').StyledComponent<"span", any, props, never>}
 */
const StyledIcon = styled.span`
	font-size: ${props => props.$size}px;
`;

/**
 * @param {object} props
 * @param {string} props.name
 * @param {number} [props.size=24]
 */
const Icon = ({ name, size = 24 }) => {
	return (
		<StyledIcon className='material-icons-outlined' $size={size}>
			{name}
		</StyledIcon>
	);
};

export default Icon;
