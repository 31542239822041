import { Alert, Grid, Loader } from 'components/index.js';
import styled from 'styled-components';

const StatWrapper = styled.div`
	border: 1px solid var(--gray-15);
	background: var(--gray-0);
	padding: var(--spacing-l);
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	h3 {
		align-self: flex-start;
	}
	> div {
		display: flex;
		flex-grow: 1;
		background: ${props => props.$backgroundColor};
		border-radius: var(--spacing-m);
		div:nth-of-type(2) {
			border-right: 1px solid var(--gray-15);
			border-left: 1px solid var(--gray-15);
		}
		> div {
			flex: 1 1 0;
			padding: var(--spacing-m);
			text-align: center;
			display: flex;
			align-items: center;
			gap: var(--spacing-s);
			h4,
			p {
				margin: 0;
				padding: 0;
			}
			h4 {
				font-weight: 300;
				color: var(--gray-14);
				font-size: 15px;
			}
			p {
				padding-left: var(--spacing-s);
				font-weight: 500;
				font-size: 20px;
			}
			> div {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}
`;

const CustomStatsWidget = ({ isLoading, error, title, items, color }) => (
	<StatWrapper $backgroundColor={color}>
		<h3 className='semi-bold font-14'>{title}</h3>
		{isLoading && (
			<Grid columns='1fr' rows='1fr' stretch='100%' horizAlign='center' vertAlign='center'>
				<Loader />
			</Grid>
		)}
		{!isLoading && (
			<div>
				{items.map(item => (
					<div key={item.id}>
						{item.icon}
						<div>
							<h4>{item.label}</h4>
							<p>{item.total}</p>
						</div>
					</div>
				))}
			</div>
		)}
		<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
	</StatWrapper>
);
export default CustomStatsWidget;
