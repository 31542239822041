import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import Dropzone from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { CircularProgressbar } from 'react-circular-progressbar';
import ReactSelect from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import sq from 'date-fns/locale/sq/index.js';
import en from 'date-fns/locale/en-US/index.js';
import translate from 'i18n-translations/translate.jsx';
import { getDoctorAvailability, getAppointments, updateAppointment } from 'api/appointments.js';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import { AppointmentType, UserRoles } from 'constants/enums.js';
import { getMyPatients } from 'api/patients.js';
import { getUserRole, getUserId } from 'infrastructure/auth.js';
import { getMyDoctors } from 'api/doctors.js';
import { getPreferredLanguageLocale, getAttachmentIcon } from 'infrastructure/helpers/commonHelpers.js';
import 'react-circular-progressbar/dist/styles.css';
import { uploadAttachment } from 'api/media.js';
import Alert from 'components/Alert.jsx'; 
import ConfirmModal from 'components/Appointments/ConfirmModal.jsx';



const EditScheduleAppointment = props => {
	registerLocale('en-US', en);
	registerLocale('sq', sq);
	const language = useSelector(state => state.language.locale);
	const userRole = getUserRole();
	const intl = useIntl();

	const userId = getUserId();
	const [availableAppointments, setAvailableAppointments] = useState([]);
	const [members, setMembers] = useState([]);
	const [selectedAppointment, setSelectedAppointment] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [attachments, setAttachments] = useState([]);
	const [error, setError] = useState(null);
	const [notAvailableAppointment, setNotAvailableAppointment] = useState(null);
	const [fileUploadError, setFileUploadError] = useState(null);
	const [invitedMember, setInvitedMember] = useState(null);
	const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
	const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);
	const [selectedAppointmentDate, setSelectedAppointmentDate] = useState(null);
	const [uploadProgress, setUploadProgress] = useState(null);
	const [hasReachedEnd, setHasReachedEnd] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const { selectedAppointmentId } = props;

	useEffect(() => {
		const fetchMyDoctors = async () => {
			const doctors = await getMyDoctors({ pageIndex });
			if (doctors.error) {
				setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${doctors.error.message}`);
				return;
			}
			if (doctors.length > 0) {
				const doctorMembers = doctors.map(doctor => ({
					email: doctor.email,
					invitedMemberUserId: doctor.userId,
					value: doctor.id,
					label: `${doctor.firstName} ${doctor.lastName}`,
					specialty: doctor.specialty.name,
					profilePicture: doctor.profilePicture,
				}));
				setMembers(prevState => [...prevState, ...doctorMembers]);
				setHasReachedEnd(doctors.length < 10);
			}
		};
		const fetchMyPatients = async () => {
			const response = await getMyPatients({ pageIndex });
			if (response.error) {
				setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${response.error.message}`);
				setIsLoading(false);
				return;
			}
			if (response.length > 0) {
				const patients = response.map(patient => ({
					email: patient.email,
					invitedMemberUserId: patient.userId,
					value: patient.id,
					label: `${patient.firstName} ${patient.lastName}`,
					profilePicture: patient.profilePicture,
				}));
				setMembers(prevState => [...prevState, ...patients]);
				setHasReachedEnd(patients.length < 10);
			}
		};
		const getInitialState = async () => {
			const response = await getAppointments(
				userId,
				moment().format('YYYY-MM-DD'),
				moment()
					.add(1, 'years')
					.format('YYYY-MM-DD')
			);
			if (response.error) {
				setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${response.error.message}`);
				setIsLoading(false);
				return;
			}
			const selectedAppointmentById = response.appointments.find(x => x.id === selectedAppointmentId);
			const { invitedUser, invitedUserId } = selectedAppointmentById.appointmentInvitation;
			let memberId = null;
			if (UserRoles.DOCTOR === userRole) {
				fetchMyPatients();
				memberId = userId;
			} else if (userRole === UserRoles.PATIENT) {
				fetchMyDoctors();
				memberId = invitedUserId;
			}
			const params = {
				userId: memberId,
				startDate: moment
					.utc(selectedAppointmentById.date)
					.local()
					.format('YYYY-MM-DD'),
				endDate: moment
					.utc(selectedAppointmentById.date)
					.local()
					.format('YYYY-MM-DD'),
			};

			const responseAvailableAppointments = await getDoctorAvailability(params);
			if (responseAvailableAppointments.error) {
				setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${responseAvailableAppointments.error.message}`);
				setIsLoading(false);
				return;
			}
			const selectedAppointmentSlotToConcat = { ...selectedAppointmentById.appointmentSlot, userId: memberId };
			const concatAvailableAppointments = responseAvailableAppointments.availableAppointments.concat(
				selectedAppointmentSlotToConcat
			);
			const sortedAvailableAppointments = concatAvailableAppointments.sort(
				(a, b) => new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime()
			);
			const newAttachments = selectedAppointmentById.appointmentAttachments.map(item => ({ ...item, accepted: true }));

			setAvailableAppointments(sortedAvailableAppointments);
			setSelectedAppointment(selectedAppointmentById);
			setAttachments(newAttachments);
			setIsLoading(false);
			setInvitedMember({
				label: `${invitedUser.firstName} ${invitedUser.lastName}`,
				value: invitedUserId,
			});
		};
		getInitialState();
	}, [intl, selectedAppointmentId, userId, userRole, pageIndex]);

	const getInitialValues = () => ({
		topic: selectedAppointment?.title ?? '',
		appointmentDate: selectedAppointment?.date ? new Date(selectedAppointment.date) : new Date(),
		appointmentSlotId: selectedAppointment?.appointmentSlot.id ?? '',
		description: selectedAppointment?.description ?? '',
		invitedMemberId: selectedAppointment?.appointmentInvitation.invitedUser.id ?? '',
		attachments: attachments,
	});

	const transformToMembers = array =>
		array.map(item => ({ value: item.value, label: item.label, userId: item.invitedMemberUserId }));

	const editAppointmentHandler = async (values, { setSubmitting }) => {
		try {
			setSubmitting(true);
			const editAppointmentData = { ...values };

			const appointmentInvitation = {
				invitedUserId: editAppointmentData.invitedMemberId?.value ?? editAppointmentData.invitedMemberId,
				conversationId: selectedAppointment.appointmentInvitation.conversationId,
			};

			const editAttachments = attachments.map(attachment => ({
				fileName: attachment.fileName || attachment.name,
				originalFileName: attachment.originalFileName || attachment.oldOriginalFileName,
				fileTypeId: attachment.fileTypeId || 0,
			}));

			const slotStartDateTime = availableAppointments.find(x => x.id === editAppointmentData.appointmentSlotId).startDateTime;
			const slotStartTimeLocal = moment
				.utc(slotStartDateTime)
				.local()
				.locale(getPreferredLanguageLocale())
				.format('HH:mm:ss');
			const appointmentDate = moment(editAppointmentData.appointmentDate).format('YYYY-MM-DD');
			const appointmentDateParam = moment(`${appointmentDate} ${slotStartTimeLocal}`)
				.utc()
				.format('YYYY-MM-DD');

			const appointment = {
				title: editAppointmentData.topic,
				description: editAppointmentData.description,
				date: appointmentDateParam,
				appointmentSlotId: editAppointmentData.appointmentSlotId,
				typeId: AppointmentType.STANDARD,
				appointmentInvitation: appointmentInvitation,
				appointmentAttachments: editAttachments,
			};
			const response = await updateAppointment(selectedAppointmentId, appointment);
			if (!response.hasSucceeded) {
				setError(intl.formatMessage({ id: 'anErrorOccurred' }));
				return;
			}
			if (response.hasSucceeded) {
				setIsRequestSuccessful(true);
				setSelectedAppointmentDate(appointment.date);
				setSubmitting(false);
				props.onSave();
			}
		} catch (ex) {
			setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${ex.message}`);
		}
	};

	const onNewMemberInvited = async (invitedMemberId, values, setFieldValue) => {
		setFieldValue('invitedMemberId', invitedMemberId);
		let memberId = null;
		let notAvailable = null;
		if (UserRoles.DOCTOR === userRole) {
			memberId = userId;
			notAvailable = translate('setUpAvailabilityHours');
		} else {
			memberId = invitedMemberId?.value;
			notAvailable = translate('notAvailableNow');
		}

		const params = {
			userId: memberId,
			startDate: moment(values.appointmentDate).format('YYYY-MM-DD'),
			endDate: moment(values.appointmentDate).format('YYYY-MM-DD'),
		};
		const doctorResponse = await getDoctorAvailability(params);
		if (doctorResponse.error) {
			setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${doctorResponse.error.message}`);
			return;
		}
		if (!doctorResponse.availableAppointments.length && userRole === UserRoles.PATIENT) {
			notAvailable = translate('notAvailableToday');
		}
		setAvailableAppointments(doctorResponse.availableAppointments);
		setNotAvailableAppointment(doctorResponse.availableAppointments.length > 0 ? null : notAvailable);
		setInvitedMember({
			label: invitedMemberId ? members.find(item => item.value === invitedMemberId?.value).label : '',
			value: invitedMemberId?.value || '',
		});
	};

	const onAppointmentDateChange = async (date, values, setFieldValue) => {
		setFieldValue('appointmentDate', date);
		let memberId = null;
		let notAvailable = null;
		if (UserRoles.DOCTOR === userRole) {
			memberId = userId;
			notAvailable = translate('setUpAvailabilityHours');
		} else {
			memberId = values.invitedMemberId;
			notAvailable = translate('notAvailableNow');
		}
		const params = {
			userId: memberId,
			startDate: moment
				.utc(date)
				.local()
				.format('YYYY-MM-DD'),
			endDate: moment
				.utc(date)
				.local()
				.format('YYYY-MM-DD'),
		};

		const availableAppointmentResponse = await getDoctorAvailability(params);
		if (availableAppointmentResponse.error) {
			setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${availableAppointmentResponse.error.message}`);
		}
		if (!availableAppointmentResponse.availableAppointments.length && userRole === UserRoles.PATIENT) {
			notAvailable = translate('notAvailableToday');
		}
		setAvailableAppointments(availableAppointmentResponse.availableAppointments);
		setNotAvailableAppointment(availableAppointmentResponse.availableAppointments.length > 0 ? null : notAvailable);
	};

	const uploadAttachmentsToCdnStorage = async selectedAttachment => {
		const formData = new FormData();
		formData.append('File', selectedAttachment);
		const uploadResponse = await uploadAttachment(formData);
		if (uploadResponse.error) {
			setError(uploadResponse.error.message);
			return {};
		}
		return {
			fileName: uploadResponse.fileName,
			originalFileName: uploadResponse.originalFileName,
			fileTypeId: uploadResponse.fileType,
			id: selectedAttachment.id,
			extension: uploadResponse.extension,
		};
	};

	const uploadedFileHandler = async acceptedAttachments => {
		setError(null);
		const methodAttachments = [...attachments];
		setFileUploadError(null);

		if (methodAttachments.length >= 5) {
			setError(intl.formatMessage({ id: 'fiveAttachmentsMaximumUpload' }));
			return;
		}
		const newAcceptedAttachments = [...acceptedAttachments];
		newAcceptedAttachments.forEach(attachment => {
			if (attachment.size / 1024 / 1024 > 5) {
				setError(intl.formatMessage({ id: 'fileUploadMaximumSize' }));
				return;
			}
			const reader = new FileReader();
			reader.onabort = () => setError(intl.formatMessage({ id: 'fileReadingWasAborted' }));
			reader.onerror = () => setError(intl.formatMessage({ id: 'fileReadingHasFailed' }));

			reader.readAsDataURL(attachment);
			reader.onprogress = event => {
				if (event.lengthComputable) {
					const progress = (event.loaded / event.total) * 100;
					setUploadProgress(Math.trunc(progress));
				}
			};

			reader.onload = async () => {
				const requestAttachment = await uploadAttachmentsToCdnStorage(attachment);
				if (Object.keys(requestAttachment).length > 0) {
					const attachmentIndex = attachments.findIndex(
						selectedAttachment =>
							[selectedAttachment.originalFileName, selectedAttachment.fileName, selectedAttachment.oldOriginalFileName].includes(
								attachment.name
							) && selectedAttachment.size === attachment.size
					);
					// eslint-disable-next-line no-param-reassign
					attachment.id = uuidv4();
					if (attachmentIndex === -1) {
						const newAttachment = {
							content: reader.result,
							isUploadCompleted: true,
							fileName: requestAttachment.fileName,
							originalFileName: requestAttachment.originalFileName,
							fileTypeId: requestAttachment.fileTypeId,
							id: requestAttachment.id,
							accepted: true,
						};
						setAttachments(prevState => [...prevState, newAttachment]);
					} else {
						setFileUploadError(
							translate('fileUploadError', {
								value: attachment.name,
							})
						);
					}
				}
			};
		});
	};

	const onFileDeleteHandler = id => {
		const prevAttachments = [...attachments];
		const attachmentsOnFileDelete = prevAttachments.filter(attachment => attachment.id !== id);
		setAttachments(attachmentsOnFileDelete);
	};

	const renameFileName = async (file, newName) => {
		setAttachments(prevState => {
			const prevAttachments = [...prevState];
			const newAttachmentIndex = prevAttachments.findIndex(attachment => attachment.id === file.id);
			const name = newName.target.value;
			prevAttachments[newAttachmentIndex].fileName = file.fileName;
			prevAttachments[newAttachmentIndex].oldOriginalFileName = file.originalFileName;
			prevAttachments[newAttachmentIndex].originalFileName = name;
			prevAttachments[newAttachmentIndex].fileTypeId = file.fileTypeId;
			return prevAttachments;
		});
	};

	const closeAppointmentModal = touched => {
		if (touched || attachments.length) {
			setIsDiscardModalOpen(true);
		} else {
			props.toggleEditAppointmentModal();
		}
	};

	const appointmentSlotChange = (appointmentSlotId, setFieldValue) => {
		setFieldValue('appointmentSlotId', appointmentSlotId);
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={getInitialValues()}
				validationSchema={Yup.object().shape({
					topic: Yup.string()
						.required(
							intl.formatMessage({
								id: 'titleIsRequired',
							})
						)
						.max(255, `${intl.formatMessage({ id: 'maxLengthIs' })} 255`)
						.trim(),
					appointmentSlotId: Yup.string().required(
						intl.formatMessage({
							id: 'appointmentSlotIsRequired',
						})
					),
					invitedMemberId: Yup.string()
						.required(
							intl.formatMessage({
								id: 'personIsRequired',
							})
						)
						.nullable(),
					description: Yup.string().required(
						intl.formatMessage({
							id: 'descriptionIsRequired',
						})
					),
				})}
				onSubmit={editAppointmentHandler}>
				{formikProps => {
					const {
						values,
						errors,
						touched,
						handleSubmit,
						handleChange,
						handleBlur,
						isSubmitting,
						setFieldValue,
						setFieldTouched,
					} = formikProps;
					const isTouched =
						touched.topic ||
						touched.invitedMemberId ||
						touched.appointmentDate ||
						touched.appointmentSlotId ||
						touched.appointmentSlotId ||
						touched.description;
					return (
						<>
							<Modal
								modalSelector='editAppointmentModal'
								display={props.isEditAppointmentOpen}
								onModalSubmit={handleSubmit}
								primaryButtonLabel={intl.formatMessage({ id: 'editAppointment' })}
								position='center'
								isLoading={isLoading}
								primaryButtonLoading={isSubmitting}
								onModalClose={() => closeAppointmentModal(isTouched)}
								shouldSubmitOnEnter={false}
								isSubmitDisabled={!availableAppointments.length}
								className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'>
								<Form height={500} className='modal-form-wrapper'>
									<h3>{translate('editAppointment')}</h3>
									<div className='create-appointment-modal'>
										<div>
											<label htmlFor='topic'>*{translate('topic')}</label>
											<input
												type='text'
												name='topic'
												value={values.topic}
												onChange={handleChange}
												onBlur={handleBlur}
												placeholder={intl.formatMessage({ id: 'enterTopic' })}
											/>
											{errors.topic && touched.topic && <span className='red-error create-app-error'>{errors.topic}</span>}
										</div>
										<div onScroll={handleScroll}>
											<label htmlFor='invitedMemberId'>*{translate('inviteMember')}</label>
											<ReactSelect
												options={transformToMembers(members)}
												value={invitedMember}
												onChange={event => onNewMemberInvited(event, values, setFieldValue)}
												placeholder={intl.formatMessage({ id: 'selectMemberToInvite' })}
												classNamePrefix='react-select'
												className='react-select full-width'
												isSearchable
												onBlur={() => setFieldTouched('invitedMemberId', true)}
											/>
										</div>
										<div className='appointment-date-wrapper'>
											<label htmlFor='date'>*{translate('appointmentDate')}</label>
											<DatePicker
												selected={values.appointmentDate}
												onChange={event => onAppointmentDateChange(event, values, setFieldValue)}
												onBlur={handleBlur}
												minDate={new Date()}
												dateFormat='MM/dd/yyyy'
												onKeyDown={event => event.preventDefault()}
												locale={language}
											/>
										</div>
										<div>
											<div className='flex available-appointments calendar-events-app-modal'>
												{availableAppointments.length === 0 && (
													<span className='red-error create-app-error'>{notAvailableAppointment}</span>
												)}
												{availableAppointments.map(appointmentSlot => (
													<div key={appointmentSlot.id} className='appointments-list'>
														<label>
															<Field
																type='radio'
																onChange={() => appointmentSlotChange(appointmentSlot.id, setFieldValue)}
																onBlur={handleBlur}
																value={values.appointmentSlotId}
																name='appointmentSlotId'
																checked={values?.appointmentSlotId === appointmentSlot.id}
															/>
															<div className='active-av-app' />
															<span>
																{`${moment
																	.utc(appointmentSlot.startDateTime)
																	.local()
																	.locale(getPreferredLanguageLocale())
																	.format('hh:mm A')} -
																  ${moment
																		.utc(appointmentSlot.endDateTime)
																		.local()
																		.locale(getPreferredLanguageLocale())
																		.format('hh:mm A')}`}
															</span>
														</label>
													</div>
												))}
											</div>
										</div>
										<div>
											<label htmlFor='description'>*{translate('description')}</label>
											<textarea
												maxLength={200}
												value={values.description}
												onBlur={handleBlur}
												onChange={handleChange}
												rows={5}
												name='description'
												placeholder={intl.formatMessage({ id: 'enterDescription' })}
											/>
											{errors.description && touched.description && (
												<span className='red-error create-app-error'>{errors.description}</span>
											)}
										</div>
										<div>
											<Dropzone
												accept='image/jpg, image/jpeg, image/png, text/plain, .pdf, .doc, .docx, .xls, .xlsx'
												onDrop={uploadedFileHandler}>
												{({ getRootProps, getInputProps }) => (
													<>
														<span>{translate('attachments')}</span>
														{attachments.length > 0 && (
															<div className='drag-drop-img-gallery flex'>
																{attachments.map(attachment => (
																	<div className='doctor-request-photo-upload' key={attachment.id}>
																		<i
																			className='material-icons cursor-pointer'
																			onClick={() => onFileDeleteHandler(attachment.id)}>
																			close
																		</i>
																		{attachment.name && (
																			<>
																				<a
																					href={attachment.content}
																					rel='noopener noreferrer'
																					target='_blank'
																					download
																					key={attachment.id}>
																					<img src={getAttachmentIcon(attachment.fileName)} alt='file-icon' />
																					<span>{attachment.name}</span>
																				</a>
																				<input
																					type='text'
																					name='fileName'
																					value={attachment.originalFileName}
																					onChange={event => renameFileName(attachment, event)}
																				/>
																				{uploadProgress !== 100 && uploadProgress !== 0 && !attachment.isUploadCompleted && (
																					<div>
																						<CircularProgressbar value={uploadProgress} text={`${uploadProgress}%`} />
																					</div>
																				)}
																			</>
																		)}
																		{!attachment.name && (
																			<>
																				<a
																					href={attachment.content}
																					rel='noopener noreferrer'
																					target='_blank'
																					download
																					key={attachment.id}>
																					<img src={getAttachmentIcon(attachment.fileName)} alt='file-icon' />
																				</a>
																				<input
																					type='text'
																					name='fileName'
																					value={attachment.originalFileName}
																					onChange={event => renameFileName(attachment, event)}
																				/>
																			</>
																		)}
																	</div>
																))}
																<p>{fileUploadError}</p>
															</div>
														)}
														<div
															className='drag-drop-user-img available-doctors-drag-drop cursor-pointer appointment-attachment'
															{...getRootProps()}>
															<input {...getInputProps()} />
															<p>{translate('dragAndDropOrSelectAttachments')}</p>
														</div>
													</>
												)}
											</Dropzone>
										</div>
									</div>
								</Form>
							</Modal>
							<Modal
								modalSelector='discardAppointmentModal'
								display={isDiscardModalOpen}
								onModalSubmit={() => props.toggleEditAppointmentModal()}
								primaryButtonLabel={intl.formatMessage({ id: 'discard' })}
								position='center'
								isLoading={isLoading}
								onModalClose={() => setIsDiscardModalOpen(false)}
								shouldSubmitOnEnter={false}
								className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal discard'>
								<Form height={200} className='modal-form-wrapper'>
									<h3>{translate('discardChanges')}</h3>
									<p>{translate('discardDescription')}</p>
								</Form>
							</Modal>
							<ConfirmModal
								display={isRequestSuccessful}
								onModalSubmit={() => props.toggleEditAppointmentModal()}
								onModalClose={() => props.toggleEditAppointmentModal()}
								isLoading={isLoading}
								userRole={userRole}
								selectedAppointmentDate={selectedAppointmentDate}
								selectedMember={members.find(member =>
									[values.invitedMemberId, values.invitedMemberId?.value].includes(member.value)
								)}
								members={members}
								appointmentTime={availableAppointments.find(appointmentTime => appointmentTime.id === values.appointmentSlotId)}
							/>
						</>
					);
				}}
			</Formik>

			<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
		</>
	);
};

export default EditScheduleAppointment;
