import React, { createContext } from 'react';

/** @type {import('react').Context<import('calls/RemoteParticipant.js').default>} */
export const ParticipantCtx = createContext(null);

/**
 * @param {object} props
 * @param {any} props.children
 * @param {import('calls/RemoteParticipant.js').default} props.participantData
 */
const ParticipantProvider = ({ children, participantData }) => {
	return <ParticipantCtx.Provider value={participantData}>{children}</ParticipantCtx.Provider>;
};

export default ParticipantProvider;
