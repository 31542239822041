const Patients = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='33' height='33' viewBox='0 0 33 33' color='#5390FF' fill='none' {...props}>
		<path
			d='M28.875 24.75V22.3929C28.875 19.1383 26.1612 16.5 22.8135 16.5C20.9119 16.5 19.2149 17.3512 18.1035 18.6831'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
		/>
		<path d='M25.8164 21.4106V24.6845' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
		<path
			d='M25.4375 11C25.4375 12.5188 24.2063 13.75 22.6875 13.75C21.1687 13.75 19.9375 12.5188 19.9375 11C19.9375 9.48122 21.1687 8.25 22.6875 8.25C24.2063 8.25 25.4375 9.48122 25.4375 11Z'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
		/>
		<path
			d='M20.625 27.5V24.3571C20.625 20.0178 16.9313 16.5 12.375 16.5C7.81865 16.5 4.125 20.0178 4.125 24.3571V27.5'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
		/>
		<path d='M6.875 24.0625V27.5' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
		<path d='M17.875 24.0625V27.5' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
		<path
			d='M16.5 9.625C16.5 11.9032 14.6532 13.75 12.375 13.75C10.0968 13.75 8.25 11.9032 8.25 9.625C8.25 7.34683 10.0968 5.5 12.375 5.5C14.6532 5.5 16.5 7.34683 16.5 9.625Z'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
		/>
	</svg>
);
export default Patients;
