import React, { useEffect, useState } from 'react';
import 'views/Onboarding/css/onboarding.css';
import { Link } from 'react-router-dom';
import { activateUser } from 'api/users.js';
import Loader from 'components/Loader.jsx';
import translate from 'i18n-translations/translate.jsx';
import { APP_CONFIG, healthCareCdnUrl } from 'constants/global-variables.js';
import { isMobileOrTablet } from 'infrastructure/helpers/commonHelpers.js';
import Grid from 'components/Grid.jsx';
import Button from 'components/Button.jsx';

const UserActivation = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const activateAccount = async () => {
			const query = new URLSearchParams(props.location.search);
			const email = query.get('email');
			const activationCode = query.get('activationCode');
			if (isMobileOrTablet()) {
				const publicUrl = APP_CONFIG.URL.localApiBasePath.split('//')[1];
				const uriScheme = APP_CONFIG.mobileAppUriScheme;
				window.location.replace(`${uriScheme}${publicUrl}activate-account?activationCode=${activationCode}&email=${email}`);
			} else {
				const response = await activateUser(email, activationCode);
				if (response.error) {
					setError(response.error.message);
				}
			}
			setIsLoading(false);
		};
		activateAccount();
	}, [props.location.search]);

	return (
		<>
			{isLoading && (
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			{!error && !isLoading && (
				<div className='patient-onboarding flex'>
					<div className='patient-box sm-box'>
						<div>
							<img src='https://static.solaborate.com/onboarding-process/patient/successful-account.svg' alt='success-account' />
						</div>
						<div>
							<h4>{translate('yourAccountActivated')}</h4>
						</div>
						<div>
							<form>
								<label className='submit-button top-0'>
									<Link to='/login'> 
										<Button icon='arrow_right_alt' onClick={props.navigateNext} text={translate('continueToSignIn')} />
									</Link>
								</label>
							</form>
						</div>
					</div>
				</div>
			)}
			{error && (
				<Grid className='login' width='100%' horizAlign='center' vertAlign='center' stretch='100vh' minContent>
					<Grid width='380px' className='login__wrapper'>
						<div className='healthcare-logo'>
							<img src={`${healthCareCdnUrl}/hellocare-white-logo.svg`} alt='logo' />
						</div>
						<div className='login-form-wrapper registration-form'>
							<div className='invalid-invite'>
								<i className='material-icons'>warning</i>
								<h3>{translate('invalidInvitation')}</h3>
								<p>{translate('checkInvitationLinkValidity')}</p>
							</div>
						</div>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default UserActivation;
