import * as React from 'react';

const VolumeMuted = props => {
	return (
		<svg width={24} height={25} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='m11.445 20.463.416-.623-.416.623ZM6.697 17.5v.75-.75Zm.555.168-.416.624.416-.624ZM3 17.5v.75-.75ZM7.252 7.332l-.416-.624.416.624Zm.416.624 4.193-2.795-.832-1.249-4.193 2.796.832 1.248ZM3 8.25h3.697v-1.5H3v1.5Zm-.25 8.25v-8h-1.5v8h1.5Zm3.947.25H3v1.5h3.697v-1.5Zm5.164 3.09-4.193-2.796-.832 1.248 4.193 2.796.832-1.248Zm.389-14.471V19.63h1.5V5.37h-1.5Zm-1.22 15.719c1.162.775 2.72-.059 2.72-1.457h-1.5a.25.25 0 0 1-.389.209l-.832 1.248ZM6.696 18.25a.25.25 0 0 1 .139.042l.832-1.248a1.75 1.75 0 0 0-.97-.294v1.5ZM1.25 16.5c0 .966.784 1.75 1.75 1.75v-1.5a.25.25 0 0 1-.25-.25h-1.5ZM3 6.75A1.75 1.75 0 0 0 1.25 8.5h1.5A.25.25 0 0 1 3 8.25v-1.5Zm8.861-1.59a.25.25 0 0 1 .389.209h1.5c0-1.398-1.558-2.232-2.72-1.457l.831 1.249ZM6.836 6.709a.25.25 0 0 1-.139.042v1.5c.346 0 .683-.102.971-.294l-.832-1.248Z'
				fill='#3C4257'
			/>
			<path d='m20 10.25-4 4M20 14.25l-4-4' stroke='#3C4257' strokeWidth={1.5} strokeLinecap='round' />
		</svg>
	);
};
export default VolumeMuted;
