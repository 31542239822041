import { gatewayApi } from 'constants/global-variables.js'; 
import { gatewayFileUploadApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getAlertTypes = async () => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/alert-types`);
		return data.alertTypes;
	} catch (error) {
		return { error };
	}
};

export const getConfigurations = async patientId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/patients/${patientId}/alert-configurations`);
		return data.alertConfigurations;
	} catch (error) {
		return { error };
	}
};

export const updateConfigurations = async (patientId, dataToSend) => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/patients/${patientId}/alert-configurations`, dataToSend);
		return data;
	} catch (error) {
		return { error };
	}
};

export const setWhiteboardType = async ({ healthSystemId, hospitalId, departmentId, whiteboardId }) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}`,
			{
				WhiteboardTypeId: whiteboardId,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteSingleConfiguration = async (patientId, alertTypeId, alertTypeFieldId) => {
	try {
		const { data } = await gatewayApi.delete(
			`/v1/healthcare/patients/${patientId}/alert-configurations/alert-type/${alertTypeId}/field/${alertTypeFieldId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteConfiguration = async (patientId, alertTypeId) => {
	try {
		const { data } = await gatewayApi.delete(
			`/v1/healthcare/patients/${patientId}/alert-configurations/alert-type/${alertTypeId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const uploadDigitalBackgroud = async (formData, container) => {
	try {
		const { data } = await gatewayFileUploadApi.post(`v1/media/pictures/${container}`, formData);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addHealthSystemCallBackgroundPicture = async (pictureUrl, companyId, healthSystemId) => {
	try {
		const { data } = await gatewayApi.post(`v1/companies/${companyId}/teams/${healthSystemId}/call-background-pictures`, {
			pictureUrl,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteHealthSystemCallBackgroundPicture = async (dataToSend, companyId, healthSystemId) => {
	try {
		const { data } = await gatewayApi.put(
			`v1/companies/${companyId}/teams/${healthSystemId}/call-background-pictures`,
			dataToSend
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getBackgroundImages = async (companyId, healthSystemId) => {
	try {
		const { data } = await gatewayApi.get(`/v1/companies/${companyId}/teams/${healthSystemId}/call-background-pictures `);
		return data.teamCallBackgroundPictures;
	} catch (error) {
		return { error };
	}
};
