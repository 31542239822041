import { gatewayApi } from 'constants/global-variables.js';

export const getExerciseCategories = async () => {
	try {
		const { data } = await gatewayApi.get('/v1/physical-therapies/exercise-categories');
		return data;
	} catch (error) {
		return { error };
	}
};

export const addPhysicalExercise = async (physicalTherapyId, params, patientId) => {
	try {
		const { data } = await gatewayApi.post(`/v1/physical-therapies/${physicalTherapyId}/exercises/patient/${patientId}`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const editExercise = async (exerciseId, dataToSend, physicalTherapyId) => {
	try {
		const { data } = await gatewayApi.put(`/v1/physical-therapies/${physicalTherapyId}/exercises/${exerciseId}`, dataToSend);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addPhysicalTherapy = async params => {
	try {
		const { data } = await gatewayApi.post(`/v1/physical-therapies/report`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getExercisesByPthAndType = async (physicalTherapyId, typeId) => {
	try {
		const { data } = await gatewayApi.get(`/v1/physical-therapies/${physicalTherapyId}/exercises/types/${typeId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getExerciseById = async exerciseId => {
	try {
		const { data } = await gatewayApi.get(`/v1/physical-therapies/exercises/${exerciseId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteExerciseById = async exerciseId => {
	try {
		const { data } = await gatewayApi.delete(`/v1/physical-therapies/exercises/${exerciseId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPhysicalTherapyById = async physicalTherapyId => {
	try {
		const { data } = await gatewayApi.get(`/v1/physical-therapies/report/${physicalTherapyId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deletePhysicalTherapy = async physicalTherapyId => {
	try {
		const { data } = await gatewayApi.delete(`/v1/physical-therapies/report/${physicalTherapyId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getGroupedExercisesByType = async physicalTherapyId => {
	try {
		const { data } = await gatewayApi.get(`/v1/physical-therapies/${physicalTherapyId}/exercises/grouped`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const submitPhysicalTherapies = async (physicalTherapyId, status) => {
	try {
		const { data } = await gatewayApi.put(`/v1/physical-therapies/report/${physicalTherapyId}/status`, { status });
		return data;
	} catch (error) {
		return { error };
	}
};

export const getExerciseFileType = async exerciseSessionId => {
	try {
		const { data } = await gatewayApi.get(`/v1/physical-therapies/exercises/sessions/${exerciseSessionId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getExerciseFileUrl = async url => {
	try {
		const { data } = await gatewayApi.get(url);
		return data.result?.uri?.uri;
	} catch (error) {
		return { error };
	}
};

export const getPhysicalTherapies = async ({ patientId, pageIndex, pageSize }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/physical-therapies/report/patient/${patientId}?pageIndex=${pageIndex}&pageSize=${pageSize}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getExerciseSettingTypes = async settingTypeId => {
	try {
		const { data } = await gatewayApi.get(`/v1/physical-therapies/exercise-types/${settingTypeId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getExercisesByCategory = async (physicalTherapyId, categoryId) => {
	try {
		const { data } = await gatewayApi.get(`/v1/physical-therapies/${physicalTherapyId}/exercises/categories/${categoryId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteExercisesByCategory = async (physicalTherapyId, categoryId) => {
	try {
		const { data } = await gatewayApi.delete(`/v1/physical-therapies/${physicalTherapyId}/exercises/categories/${categoryId}`);
		return data;
	} catch (error) {
		return { error };
	}
};
