import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { actionCreators as languageActionCreators } from 'state/language/actions.js';
import { getPreferredLanguageLocale, setPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import { setUserPreference } from 'api/users.js';
import { AlertTypes, UserRoles } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { Alert, PopUpAlert, Input, RadioButtonIcon } from 'components/index.js';
import { getCompanyId, getUserId, getUserProfile, getUserRole } from 'infrastructure/auth.js';
import { languageValues } from 'constants/language.js';
import { CompanySettings, ThemeTypes, themeOptions } from 'constants/configurationEnums.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import { getPin } from 'api/whiteboard.js';
import Button from 'components/Button.jsx';

const GeneralSettings = () => {
	const locale = useSelector(state => state.language.locale);
	const isDarkMode = useSelector(state => state.user.darkMode);
	const darkModeRoles = [UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.VIRTUAL_SITTER].includes(getUserRole());
	const applyDarkModeProperties = darkModeRoles && isDarkMode;
	const dispatch = useDispatch();
	const intl = useIntl();
	const preferredLanguage = getPreferredLanguageLocale();
	const [idNumber, setIDNumber] = useState('');
	const [email, setEmail] = useState('');
	const [language, setLanguage] = useState(languageValues.find(item => item.value === (preferredLanguage || locale)));
	const [error, setError] = useState(null);
	const [changesSaved, setChangesSaved] = useState(null);
	const [pin, setPin] = useState('');
	const [isSecret, setIsSecret] = useState(true);
	const [theme, setTheme] = useState(isDarkMode ? ThemeTypes.DARK : ThemeTypes.LIGHT);
	const companyId = getCompanyId();

	useEffect(() => {
		const fetchData = async () => {
			const userProfile = await getUserProfile(getUserId(), companyId);
			if (userProfile) {
				setIDNumber(userProfile.idCard || '');
				setEmail(userProfile.email);
				const response = await getPin(getUserId());
				if (response.error) {
					setError(intl.formatMessage({ id: 'somethingWentWrong' }));
					return;
				}
				setPin(response.pin);
			}
		};
		fetchData();
	}, [intl, companyId]);

	useEffect(() => {
		let timeout;
		if (changesSaved) {
			timeout = setTimeout(() => {
				setChangesSaved('');
			}, 1500);
		}
		return () => clearTimeout(timeout);
	}, [changesSaved]);

	const saveChanges = async () => {
		const dataToSend = {
			preferences: [
				{
					preferenceType: CompanySettings.LANGUAGE,
					value: language.value.toString(),
				},
				{
					preferenceType: CompanySettings.THEME,
					value: theme,
				},
			],
		};

		const response = await setUserPreference(dataToSend);
		if (response.error) {
			setError(response.error.message);
			return;
		}

		setPreferredLanguageLocale(language.value);
		dispatch(languageActionCreators.setUserLanguage(language.value));
		dispatch(userActionCreators.setDarkMode(theme === ThemeTypes.DARK));
		setChangesSaved(translate('changesSaved'));
	};

	const DropdownIndicator = () => <i className='material-icons-outlined'>arrow_drop_down</i>;

	const generalSettingsImgUrl = `${healthCareCdnUrl}general-settings/`;

	return (
		<div className='account-settings-inner-wrapper general-settings'>
			<h4>{translate('generalSettings')}</h4>
			<div className='general-settings-form flex flex-space-between flex-wrap general-setting-wrapper'>
				{getUserRole() === UserRoles.DOCTOR && (
					<label className='account-setting'>
						{translate('idNumber')}
						<Input
							inputClassName='gs-input'
							type='text'
							name='idNumber'
							id='idNumber'
							placeholder={intl.formatMessage({ id: 'idNumber' })}
							disabled
							value={idNumber}
							validationOptions={{ maxLength: 15 }}
						/>
					</label>
				)}
				<label className='account-setting'>
					{translate('emailAddress')}
					<Input
						prefixImg={`${generalSettingsImgUrl}email-general.svg`}
						alt='flag'
						type='email'
						name='email'
						id='email'
						disabled
						value={email}
						validationOptions={{ maxLength: 512 }}
						inputClassName='gs-input'
					/>
				</label>
				<label className='account-setting general-settings-select-el language-select'>
					{translate('language')}
					<Select
						isSearchable={false}
						classNamePrefix='custom-select'
						options={languageValues}
						components={{ DropdownIndicator }}
						onChange={value => setLanguage(value)}
						value={language}
						styles={{
							...generateCustomStyles({ darkMode: applyDarkModeProperties }),
						}}
					/>
				</label>
			</div>
			{darkModeRoles && (
				<>
					<h4>{translate('display')}</h4>
					<div className='flex account-setting general-settings-form display-settings'>
						{translate('theme')}
						<label className='flex theme-inputs'>
							{themeOptions.map(option => (
								<RadioButtonIcon
									key={option.value}
									type={option.value}
									text={translate(option.value)}
									icon={option.icon}
									handleChange={() => setTheme(option.value)}
									value={theme}
									name='theme'
								/>
							))}
						</label>
					</div>
				</>
			)}
			<div className='full-width flex right-align-content top-30'>
				<Button onClick={saveChanges} text={translate('saveChanges')} />
			</div>
			{pin && [UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.VIRTUAL_SITTER].includes(getUserRole()) && (
				<>
					<h4>PIN Lock</h4>
					<div className='general-settings-form flex flex-space-between flex-wrap general-setting-wrapper'>
						<label className='account-setting pin-icon'>
							PIN
							<Input
								symbol='passkey'
								inputClassName='gs-input'
								type={isSecret ? 'password' : 'text'}
								name='pin'
								id='pin'
								placeholder='******'
								disabled
								value={pin}
								validationOptions={{ maxLength: 6 }}
								readOnly={true}
								isOutlined={true}
								postfixIcon={isSecret ? 'visibility_off' : 'visibility'}
								onPostfixClick={() => setIsSecret(prevState => !prevState)}
							/>
						</label>
					</div>
				</>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			<PopUpAlert
				alertType={AlertTypes.SUCCESS}
				display={changesSaved}
				onAlertClose={() => setChangesSaved(null)}
				contentText={changesSaved}
				isSilent={true}
				center={true}
			/>
		</div>
	);
};

export default GeneralSettings;
