import classNames from 'classnames';
import Button from 'components/Button.jsx';

const AIToastMessage = props => (
	<div className={classNames('ai-toast-message-wrapper', props.display ? 'show' : '', props.position, props.className)}>
		<div className='toast-message-body'>
			<div className='toast-message-icon'>
				<img src={props.aiToastDetails?.imgUrl} alt='ico' />
			</div>
			<div className='toast-message-content'>
				<div className='flex column-direction left-align-items'>
					<p className='alert-title'>{props.aiToastDetails?.message}</p>
				</div>
			</div>
			{props.showForwardToNurses && (
				<div className='toast-message-actions'>
					<Button onClick={props.onButtonClick} text={props.aiToastDetails?.buttonText} />
				</div>
			)}
			<div className='toast-message-close'>
				<i className='material-icons cursor-pointer close-toast-message' onClick={props.onToastMessageClose}>
					close
				</i>
			</div>
		</div>
	</div>
);
export default AIToastMessage;
