import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import Pagination from 'components/Common/Pagination.jsx';
import { Button, Input, Modal } from 'components/index.js';
import { ConfigHistoryTypes, TeamTypeLabels } from 'constants/configurationEnums.js';
import { Roles, TeamTypes, TreeHierarchyType, UserRoles, UserTypes } from 'constants/enums.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { getHospitalDepartments } from 'api/healthSystems.js';
import {
	deleteTeamNetworkAccessConfiguration,
	deleteTeamNetworkBandwidthConfiguration,
	deleteTeamNetworkWiFiBandConfigurations,
	getTeamNetworkAccressConfiguration,
	getTeamNetworkBandwidthConfigurations,
	getTeamNetworkWiFiBandConfigurations,
} from 'api/networkConfigurations.js';
import { deleteNtpConfiguration, getNtpConfigurations } from 'api/ntpConfig.js';
import { getWiFiBands } from 'api/wifiBands.js';
import CustomTable from 'components/CustomTable.jsx';
import NetworkAccessForm from 'containers/Configurations/NetworkAccessForm.jsx';
import NetworkConfigForm from 'containers/Configurations/NetworkConfigForm.jsx';
import NtpForm from 'containers/Configurations/NtpForm.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { getPort } from 'infrastructure/helpers/commonHelpers.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import TableHistoryButton from 'icons/Admin/TableHistoryButton.jsx';
import TableEditButton from 'icons/Admin/TableEditButton.jsx';
import TableDeleteButton from 'icons/Admin/TableDeleteButton.jsx';
import { Tab, TabList, Tabs } from 'components/Tabs.jsx';

const wiFiConfigHeaders = [
	{ id: 'floor', title: translate('name') },
	{ id: 'type', title: translate('type') },
	{ id: 'wiFiBand', title: translate('wiFiBand') },
	{ id: 'createdBy', title: translate('createdBy') },
	{ id: 'dateCreated', title: translate('dateCreated') },
	{ id: 'edit', title: '' },
];

const bandwidthConfigHeaders = [
	{ id: 'floor', title: translate('name') },
	{ id: 'type', title: translate('type') },
	{ id: 'minP2PBitrateKbps', title: translate('minBandwidth') },
	{ id: 'maxP2PBitrateKbps', title: translate('maxBandwidth') },
	{ id: 'createdBy', title: translate('createdBy') },
	{ id: 'dateCreated', title: translate('dateCreated') },
	{ id: 'edit', title: '' },
];

const accessConfigsHeaders = [
	{ id: 'role', title: translate('role') },
	{ id: 'ipAddress', title: translate('ipAddress') },
	{ id: 'isCIDR', title: translate('cidr') },
	{ id: 'createdBy', title: translate('createdBy') },
	{ id: 'dateCreated', title: translate('dateCreated') },
	{ id: 'edit', title: '' },
];

const ntpConfigHeaders = [
	{ id: 'healthSystemHospital', title: translate('location') },
	{ id: 'ntpUrl', title: translate('ntpUrl') },
	{ id: 'createdBy', title: translate('createdBy') },
	{ id: 'dateCreated', title: translate('dateCreated') },
	{ id: 'edit', title: '' },
];

const transformTypes = {
	WithValues: 1,
	WithLabels: 2,
};

const activeSubTab = {
	Bandwidth: 0,
	WiFiBand: 1,
	WebsiteAccess: 2,
	NtpConfigurations: 3,
};

const getRoles = companySettings => [
	{
		value: '0',
		label: translate('all'),
	},
	{
		value: UserTypes.DIGITAL_CLINICIAN,
		label: translate('digitalClinic'),
	},
	{
		value: UserTypes.DOCTOR,
		label: translate('doctor'),
	},
	{
		value: UserTypes.NURSE,
		label: companySettings.nurseDisplayName,
	},
	{
		value: UserTypes.PATIENT,
		label: translate('patient'),
	},
	{
		value: UserTypes.SUPER_USER,
		label: translate('superUser'),
	},
];

const NetworkConfig = props => {
	const intl = useIntl();
	const organizationState = useSelector(state => state.healthSystems);
	const companySettings = useSelector(state => state.company.companySettings);
	const [isLoading, setLoading] = useState(true);
	const [hospitals, setHospitals] = useState([]);
	const [formHospitals, setFormHospitals] = useState([]);
	const [formFloors, setFormFloors] = useState([]);
	const [isModalHospitalsLoading, setModalHospitalsLoading] = useState(false);
	const [isModalFloorsLoading, setModalFloorsLoading] = useState(false);
	const [bandwidthConfigs, setBandwidthConfigs] = useState([]);
	const [totalBandwidthConfigs, setTotalBandwidthConfigs] = useState(0);
	const [wifiBandConfigs, setWifiBandConfigs] = useState([]);
	const [totalWifiBandConfigs, setTotalWifiBandConfigs] = useState(0);
	const [isHospitalDisabled, setHospitalDisabled] = useState(true);
	const [isDeleteConfigModalOpen, setDeleteConfigModalOpen] = useState(false);
	const [wiFiBands, setWiFiBands] = useState([]);
	const [editConfig, setEditConfig] = useState(null);
	const [isModalOpen, setModalOpen] = useState(false);
	const [formHealthSystemId, setFormHealthSystemId] = useState(null);
	const [accessConfigs, setAccessConfigs] = useState([]);
	const [ntpConfigs, setNtpConfigs] = useState([]);
	const [totalAccessConfigs, setTotalAccessConfigs] = useState(0);
	const [totalNtpConfigs, setTotalNtpConfigs] = useState(0);
	const [hasDeleteError, setDeleteError] = useState(false);
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const [treeHierarchyTypeId, setTreeHierarchyTypeId] = useState(TreeHierarchyType.DEFAULT_TREE);
	const [search, setSearch] = useState('');

	const translator = id => intl.formatMessage({ id });

	const fetchWiFiBands = async () => {
		const res = await getWiFiBands();
		if (!res.error) {
			setWiFiBands(res.wiFiBands);
		}
	};

	const transformHealthSystemsForSelect = healthSystems =>
		healthSystems.map(healthSystem => ({ label: healthSystem.name, value: healthSystem.id })) || [];

	const fetchHealthSystemHospitals = async healthSystemId => {
		const firstHSHospitals = await getHealthSystemHospitals(healthSystemId);
		return transformHealthSystemsForSelect(firstHSHospitals);
	};

	const getHospitals = async healthSystem => {
		let hospitalsArray;
		if (healthSystem.value !== '0') {
			hospitalsArray = await fetchHealthSystemHospitals(healthSystem.value);
			hospitalsArray.unshift({ value: 0, label: translator('all') });
		}

		setHospitalDisabled(healthSystem.value === '0');
		setHospitals(hospitalsArray);
	};

	const getConfigurations = useCallback(async () => {
		if (props.isLoading) {
			return;
		}

		const { selectedHealthSystem, selectedHospitalId } = props;
		let hsId = null;
		let teamType = null;

		if (selectedHospitalId) {
			hsId = selectedHospitalId;
			teamType = TeamTypes.HOSPITAL;
		} else if (selectedHealthSystem && selectedHealthSystem.value !== '0') {
			hsId = selectedHealthSystem.value;
			teamType = TeamTypes.HEALTH_SYSTEM;
		}

		if (getUserRole() === UserRoles.SUPER_USER) {
			hsId = organizationState.allHealthSystems[0].id;
			teamType = TeamTypes.HEALTH_SYSTEM;
			selectedHealthSystem.id = organizationState.allHealthSystems[0].id;
			selectedHealthSystem.label = organizationState.allHealthSystems[0].name;
		}

		if (props.activeSubTab === activeSubTab.Bandwidth) {
			const { teamNetworkBandwidthConfigurations, total } = await getTeamNetworkBandwidthConfigurations({
				pageSize: props.pageSize,
				pageIndex: props.pageIndex,
				teamId: hsId,
				teamType,
			});

			setBandwidthConfigs(teamNetworkBandwidthConfigurations);
			setTotalBandwidthConfigs(total);
		} else if (props.activeSubTab === activeSubTab.WiFiBand) {
			const { teamNetworkWiFiBandConfigurations, total } = await getTeamNetworkWiFiBandConfigurations({
				pageSize: props.pageSize,
				pageIndex: props.pageIndex,
				teamId: hsId,
				teamType,
			});

			setWifiBandConfigs(teamNetworkWiFiBandConfigurations);
			setTotalWifiBandConfigs(total);
		} else if (props.activeSubTab === activeSubTab.WebsiteAccess) {
			const { teamNetworkAccessConfigurations, total } = await getTeamNetworkAccressConfiguration({
				pageSize: props.pageSize,
				pageIndex: props.pageIndex,
				roleId: props.selectedRole.value !== '0' ? props.selectedRole.value : null,
				search,
			});
			setAccessConfigs(teamNetworkAccessConfigurations);
			setTotalAccessConfigs(total);
		} else if (props.activeSubTab === activeSubTab.NtpConfigurations) {
			const response = await getNtpConfigurations({
				pageSize: props.pageSize,
				pageIndex: props.pageIndex,
				teamId: hsId,
				teamType,
			});

			if (!response.error) {
				setNtpConfigs(response.teamNetworkNtpConfigurations);
				setTotalNtpConfigs(response.total);
			}
		}
		if (selectedHealthSystem) {
			await getHospitals(selectedHealthSystem);
		}
		setLoading(false);
	}, [props.pageIndex, props.pageSize, props.selectedHealthSystem, props.selectedHospitalId, props.activeSubTab, search]);

	useEffect(() => {
		getConfigurations();
	}, [getConfigurations]);

	useEffect(() => {
		fetchWiFiBands();
	}, []);

	useEffect(() => {
		const timeOut = setTimeout(() => getConfigurations(), 500);
		return () => clearTimeout(timeOut);
	}, [search]);

	const openEditModal = config => {
		setEditConfig(config);
		setModalOpen(true);
	};

	const openDeleteModal = config => {
		setEditConfig(config);
		setDeleteConfigModalOpen(true);
	};

	const onSearchChanged = e => {
		setLoading(true);
		setSearch(e.target.value);
	};

	const getConfigButtons = config => {
		const editConfiguration = {
			id: config.id,
			teamId: config.team.id,
			name: config.team.name,
			teamType: config.team.typeId,
			minBandwidth: config.minP2PBitrateKbps,
			maxBandwidth: config.maxP2PBitrateKbps,
		};

		return (
			<div className='wrapped'>
				<Link to={`/configurations/${config.id}/type/${ConfigHistoryTypes.NetworkBandwidth}`} onClick={props.onHistoryClick}>
					<div
						className='material-icons-outlined boxed-icon view-history-icon'
						data-tooltip={intl.formatMessage({ id: 'viewHistory' })}
						data-position='top'>
						<TableHistoryButton />
					</div>
				</Link>
				<TableEditButton onClick={() => openEditModal(editConfiguration)} />
				<TableDeleteButton onClick={() => openDeleteModal(editConfiguration)} />
			</div>
		);
	};

	const getWiFiBandConfigButtons = config => {
		const editConfiguration = {
			id: config.id,
			teamId: config.team.id,
			name: config.team.name,
			teamType: config.team.typeId,
			wiFiBandId: config.wiFiBand.id,
		};

		return (
			<div className='wrapped'>
				<Link to={`/configurations/${config.id}/type/${ConfigHistoryTypes.NetworkWiFiBand}`} onClick={props.onHistoryClick}>
					<div
						className='material-icons-outlined boxed-icon view-history-icon'
						data-tooltip={intl.formatMessage({ id: 'viewHistory' })}
						data-position='top'>
						<TableHistoryButton />
					</div>
				</Link>
				<TableEditButton onClick={() => openEditModal(editConfiguration)} />
				<TableDeleteButton onClick={() => openDeleteModal(editConfiguration)} />
			</div>
		);
	};

	const getNetworkAccessConfigButtons = config => {
		const editConfiguration = {
			id: config.id,
			roleId: config.roleId,
			ipAddress: config.ipAddress,
			name: networkAcessRoles.find(x => x.id === config.roleId)?.role,
		};
		return (
			<div className='wrapped'>
				<Link to={`/configurations/${config.id}/type/${ConfigHistoryTypes.NetworkAccess}`} onClick={props.onHistoryClick}>
					<div
						className='material-icons-outlined boxed-icon view-history-icon'
						data-tooltip={intl.formatMessage({ id: 'viewHistory' })}
						data-position='top'>
						<TableHistoryButton />
					</div>
				</Link>
				<TableEditButton onClick={() => openEditModal(editConfiguration)} />
				<TableDeleteButton onClick={() => openDeleteModal(editConfiguration)} />
			</div>
		);
	};

	const getNtpConfigButtons = config => {
		const editConfiguration = {
			id: config.id,
			healthSystemId: config.team.typeId === TeamTypes.HEALTH_SYSTEM ? config.team.id : '',
			hospitalId: config.team.typeId === TeamTypes.HOSPITAL ? config.team.id : '',
			teamId: config.team.id,
			teamType: config.team.typeId,
			name: config.team.name,
			ntpUrl1: config.ntpUrls[0].substr(0, config.ntpUrls[0].lastIndexOf(':')),
			ntpPort1: getPort(config.ntpUrls[0]),
			ntpUrl2: config.ntpUrls.length > 1 ? config.ntpUrls[1].substr(0, config.ntpUrls[1].lastIndexOf(':')) : '',
			ntpPort2: config.ntpUrls.length > 1 ? getPort(config.ntpUrls[1]) : '',
		};
		return (
			<div className='wrapped'>
				<Link to={`/configurations/${config.id}/type/${ConfigHistoryTypes.NtpConfigurations}`} onClick={props.onHistoryClick}>
					<div
						className='material-icons-outlined boxed-icon view-history-icon'
						data-tooltip={intl.formatMessage({ id: 'viewHistory' })}
						data-position='top'>
						<TableHistoryButton />
					</div>
				</Link>
				<TableEditButton onClick={() => openEditModal(editConfiguration)} />
				<TableDeleteButton onClick={() => openDeleteModal(editConfiguration)} />
			</div>
		);
	};

	const transformConfigurations = () => {
		if (props.activeSubTab === activeSubTab.Bandwidth) {
			return bandwidthConfigs.map(item => ({
				id: item.id,
				floor: item.team.name,
				type: TeamTypeLabels[item.team.typeId],
				minP2PBitrateKbps: item.minP2PBitrateKbps,
				maxP2PBitrateKbps: item.maxP2PBitrateKbps,
				createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
				dateCreated: formattedDate(item.dateCreated),
				edit: getConfigButtons(item),
			}));
		}
		if (props.activeSubTab === activeSubTab.WiFiBand) {
			return wifiBandConfigs.map(item => ({
				id: item.id,
				floor: item.team.name,
				type: TeamTypeLabels[item.team.typeId],
				wiFiBand: item.wiFiBand.description,
				createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
				dateCreated: formattedDate(item.dateCreated),
				edit: getWiFiBandConfigButtons(item),
			}));
		}
		if (props.activeSubTab === activeSubTab.WebsiteAccess) {
			return accessConfigs.map(item => ({
				id: item.id,
				role: networkAcessRoles.find(x => x.id === item.roleId)?.role,
				ipAddress: item.ipAddress,
				isCIDR: item.isCIDR ? (
					<span
						className='material-icons-outlined cursor-pointer'
						data-tooltip={translator('representsRangeOfIps')}
						data-position='top'>
						layers
					</span>
				) : (
					''
				),
				createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
				dateCreated: formattedDate(item.dateCreated),
				edit: getNetworkAccessConfigButtons(item),
			}));
		}
		if (props.activeSubTab === activeSubTab.NtpConfigurations) {
			return ntpConfigs?.map(item => ({
				healthSystemHospital: `${translator(item.team.typeId === TeamTypes.HEALTH_SYSTEM ? 'healthSystem' : 'hospital')}: ${
					item.team.name
				}`,
				ntpUrl: item.ntpUrls.map(el => (
					<>
						<span>{el}</span>
						<br />
					</>
				)),
				createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
				dateCreated: formattedDate(item.dateCreated),
				edit: getNtpConfigButtons(item),
			}));
		}

		return [];
	};

	const onTabChange = async tabIndex => {
		setLoading(true);
		await props.onSubTabChange(tabIndex);
	};

	const onPaginationChange = async (pageSize, pageIndex) => {
		setLoading(true);
		await props.onPaginationChange(pageSize, pageIndex);
	};

	const tasnformedWifiBands = useMemo(() => {
		return wiFiBands.map(item => ({ id: item.id, value: item.description }));
	}, [wiFiBands]);

	const transformArray = (array, type, shouldAppendAll) => {
		if (type === transformTypes.WithValues) {
			const newArray = array.map(item => {
				return { id: item.id, value: item.name };
			});
			if (shouldAppendAll && getUserRole() === UserRoles.ADMIN) {
				newArray.unshift({ id: '0', value: translator('all') });
			}

			return newArray;
		}
		if (type === transformTypes.WithLabels) {
			const newArray = array.map(item => {
				return { value: item.id, label: item.name };
			});
			if (shouldAppendAll && getUserRole() === UserRoles.ADMIN) {
				newArray.unshift({ value: '0', label: translator('all') });
			}
			return newArray;
		}
		return [];
	};

	const transformedHealthSystems = useMemo(
		() => transformArray(organizationState.allHealthSystems, transformTypes.WithValues, false),
		[organizationState.allHealthSystems]
	);

	const onHospitalSelect = selection => {
		setLoading(true);
		props.setSelectedHospital(selection);
	};

	const onHealthSystemSelect = healthSystem => {
		setLoading(true);
		props.setSelectedHealthSystem(healthSystem);
	};

	const toggleConfigModal = () => {
		setModalOpen(prevState => !prevState);
	};

	const onHealthSystemChange = async e => {
		const { value } = e.target;
		setTreeHierarchyTypeId(allHealthSystems.find(item => item.id === value)?.treeHierarchyTypeId);
		setModalHospitalsLoading(true);
		setFormHealthSystemId(value);

		const hsHospitals = value !== '0' ? await getHealthSystemHospitals(value) : [];
		const hospitalArray = hsHospitals.map(hospital => ({ id: hospital.id, value: hospital.name }));

		setFormHospitals(hospitalArray);
		if (hospitalArray.length) {
			await updateFormFloors(hospitalArray[0].id);
		} else {
			setFormFloors([]);
		}
		setModalHospitalsLoading(false);
	};

	const updateFormFloors = async id => {
		setModalFloorsLoading(true);

		const hopitalFloors = id !== '0' ? await getHospitalDepartments(formHealthSystemId, id) : null;
		let formFloorsArray = [];
		if (hopitalFloors.hospital?.departments) {
			formFloorsArray = hopitalFloors.hospital.departments
				.map(dep => dep.floors.map(floor => ({ id: floor.id, value: floor.name })))
				.flat();
		}

		setFormFloors(formFloorsArray);
		setModalFloorsLoading(false);
	};

	const onHospitalChange = async e => {
		const { value } = e.target;
		updateFormFloors(value);
	};

	const deleteConfig = async () => {
		setLoading(true);
		setDeleteConfigModalOpen(false);

		let res = null;
		if (props.activeSubTab === activeSubTab.Bandwidth) {
			res = await deleteTeamNetworkBandwidthConfiguration(editConfig.teamId, editConfig.teamType, editConfig.id);
		} else if (props.activeSubTab === activeSubTab.WiFiBand) {
			res = await deleteTeamNetworkWiFiBandConfigurations(editConfig.teamId, editConfig.teamType, editConfig.id);
		} else if (props.activeSubTab === activeSubTab.WebsiteAccess) {
			res = await deleteTeamNetworkAccessConfiguration(editConfig.id);
		} else if (props.activeSubTab === activeSubTab.NtpConfigurations) {
			res = await deleteNtpConfiguration(editConfig.teamId, editConfig.teamType, editConfig.id);
		}

		if (res.error) {
			setDeleteError(true);
			setLoading(false);
		} else {
			setDeleteError(false);
			setEditConfig(null);
			getConfigurations();
		}
	};

	const onRoleSelected = async selectedRole => {
		setLoading(true);
		await props.setSelectedRole(selectedRole);
	};

	const getTotalConfigs = () => {
		switch (props.activeSubTab) {
			case activeSubTab.Bandwidth:
				return totalBandwidthConfigs;
			case activeSubTab.WiFiBand:
				return totalWifiBandConfigs;
			case activeSubTab.WebsiteAccess:
				return totalAccessConfigs;
			case activeSubTab.NtpConfigurations:
				return totalNtpConfigs;
			default:
				return [{ id: 0, title: '' }];
		}
	};

	const networkAcessRoles = Roles.filter(
		x => x.id !== UserTypes.ADMIN && x.id !== UserTypes.OWNER && x.id !== UserTypes.SUPER_ADMIN
	);

	const getGridHeaders = () => {
		switch (props.activeSubTab) {
			case activeSubTab.Bandwidth:
				return bandwidthConfigHeaders;
			case activeSubTab.WiFiBand:
				return wiFiConfigHeaders;
			case activeSubTab.WebsiteAccess:
				return accessConfigsHeaders;
			case activeSubTab.NtpConfigurations:
				return ntpConfigHeaders;
			default:
				return [''];
		}
	};

	return (
		<>
			<Tabs className='configs-sub-tabs' activeIndex={props.activeSubTab} onChange={onTabChange}>
				<TabList>
					<Tab>{translate('bandwidth')}</Tab>
					<Tab>{translate('wifiFrequency')}</Tab>
					<Tab>{translate('webSiteAccess')}</Tab>
					<Tab>{translate('ntpConfigurations')}</Tab>
				</TabList>
			</Tabs>
			<CustomTable
				isLoading={isLoading}
				headers={getGridHeaders()}
				rows={isLoading ? [] : transformConfigurations()}
				headerClass='configs-table-headers'>
				{props.activeSubTab === activeSubTab.WebsiteAccess ? (
					<>
						<div>
							<label>{translate('selectRole')}</label>
							<Select
								value={props.selectedRole}
								placeholder='Select role..'
								classNamePrefix='react-select'
								options={getRoles(companySettings)}
								onChange={r => onRoleSelected(r)}
							/>
						</div>
						<Input
							label={translate('searchByIPAddress')}
							validationOptions={{ maxLength: 127 }}
							type='text'
							className='config-th-input'
							placeholder='Search'
							bottomSpace='0'
							variant='filled'
							name='searchIpAddresses'
							onChange={onSearchChanged}
						/>
					</>
				) : (
					<>
						<div>
							<label>{translate('selectHealthSystem')}</label>
							<Select
								value={props.selectedHealthSystem}
								placeholder={translator('all')}
								classNamePrefix='react-select'
								options={transformArray(organizationState.allHealthSystems, transformTypes.WithLabels, true)}
								onChange={onHealthSystemSelect}
								isDisabled={getUserRole() === UserRoles.SUPER_USER}
							/>
						</div>
						<div>
							<label>{translate('selectHospital')}</label>
							<Select
								placeholder={translator('select')}
								value={props.selectedHospitalId ? hospitals.find(x => x.value === props.selectedHospitalId) : null}
								isDisabled={isHospitalDisabled}
								classNamePrefix='react-select'
								options={hospitals}
								onChange={onHospitalSelect}
							/>
						</div>
					</>
				)}
				<Button text={translator('addConfiguration')} onClick={toggleConfigModal} />
			</CustomTable>
			<Pagination
				totalCount={getTotalConfigs()}
				pageSize={props.pageSize}
				pageIndex={props.pageIndex}
				onChange={(pageSize, pageIndex) => onPaginationChange(pageSize, pageIndex)}
			/>
			{[activeSubTab.Bandwidth, activeSubTab.WiFiBand].includes(props.activeSubTab) && (
				<NetworkConfigForm
					activeTab={props.activeSubTab}
					isModalOpen={isModalOpen}
					initialValues={editConfig}
					wiFiBands={tasnformedWifiBands}
					isSuperUser={getUserRole() === UserRoles.SUPER_USER}
					healthSystems={transformedHealthSystems}
					toggleModal={() => {
						setModalOpen(prevState => !prevState);
						setEditConfig(null);
					}}
					onSucceeded={getConfigurations}
					hospitals={formHospitals}
					floors={formFloors}
					isHospitalLoading={isModalHospitalsLoading}
					isFloorLoading={isModalFloorsLoading}
					onHealthSystemChange={onHealthSystemChange}
					onHospitalChange={onHospitalChange}
					treeHierarchyTypeId={treeHierarchyTypeId}
				/>
			)}
			{props.activeSubTab === activeSubTab.WebsiteAccess && (
				<NetworkAccessForm
					activeTab={props.activeSubTab}
					isModalOpen={isModalOpen}
					initialValues={editConfig}
					toggleModal={() => {
						setModalOpen(prevState => !prevState);
						setEditConfig(null);
					}}
					onSucceeded={getConfigurations}
				/>
			)}
			{props.activeSubTab === activeSubTab.NtpConfigurations && (
				<NtpForm
					activeTab={props.activeSubTab}
					isModalOpen={isModalOpen}
					initialValues={editConfig}
					toggleModal={() => {
						setModalOpen(prevState => !prevState);
						setEditConfig(null);
					}}
					onSucceeded={getConfigurations}
				/>
			)}
			<Modal
				modalSelector='deleteNetworkConfigModal'
				display={isDeleteConfigModalOpen}
				position='center'
				submitButtonText='Delete'
				onModalSubmit={() => deleteConfig()}
				onModalClose={() => {
					setDeleteConfigModalOpen(false);
					setEditConfig(null);
				}}>
				<form>
					<h3>{translator('warning')}</h3>
					<p>{translator('deleteNetworkConfigConfirmation')}</p>
					{hasDeleteError && <p className='error'>{translator('somethingWentWrong')}</p>}
				</form>
			</Modal>
		</>
	);
};
export default NetworkConfig;
