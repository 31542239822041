import CustomTable from 'components/CustomTable.jsx';
import { CallTypes, ObjectType } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { useSelector } from 'react-redux';

const CallDetails = props => {
	const companySettings = useSelector(state => state.company.companySettings);

	const isFeatureUsed = (roomFeatureUsed, vcpFeatureUsed) => (
		<div className='call-properties flex column-direction'>
			<span>
				<b>{translate('patient')}: </b>
				{roomFeatureUsed ? translate('yes') : translate('no')}
			</span>
			<span>
				<b>{companySettings.nurseDisplayName}: </b>
				{vcpFeatureUsed ? translate('yes') : translate('no')}
			</span>
		</div>
	);

	const getCallDetails = () => {
		const log = props.selectedConference;
		if (log.callType === CallTypes.Monitoring) {
			const headers = [
				{ title: translate('room'), id: 'room' },
				{ title: translate('audioUsed'), id: 'audioUsed' },
				{ title: translate('videoUsed'), id: 'videoUsed' },
				{ title: translate('screenUsed'), id: 'screenUsed' },
			];
			const nurse = log.participants?.find(participant => participant.objectType === ObjectType.USER);
			const devices = log.participants?.filter(participant => participant.objectType === ObjectType.HELLO_DEVICE);
			const devicesFeatures = devices?.map(device => ({
				name: device.name,
				audioUsed: isFeatureUsed(device.usedAudio, nurse?.usedAudio),
				videoUsed: isFeatureUsed(device.usedVideo, nurse?.usedVideo),
				screenUsed: isFeatureUsed(device.usedScreen, nurse?.usedScreen),
				id: device.id,
			}));
			return <CustomTable headers={headers} rows={devicesFeatures} className='call-details-table' />;
		}
		{
			const headers = [
				{ title: translate('audioUsed'), id: 'audioUsed' },
				{ title: translate('videoUsed'), id: 'videoUsed' },
				{ title: translate('screenUsed'), id: 'screenUsed' },
			];
			const features = Object.entries(log).length !== 0 &&
				log.constructor === Object && {
					audioUsed: isFeatureUsed(log.usedAudio.room, log.usedAudio.vcp),
					videoUsed: isFeatureUsed(log.usedVideo.room, log.usedVideo.vcp),
					screenUsed: isFeatureUsed(log.usedScreen.room, log.usedScreen.vcp),
					id: log.id,
				};
			return <CustomTable headers={headers} rows={[features]} className='call-details-table' />;
		}
	};
	return (
		<form className='call-details'>
			<h3>{translate('callDetails')}</h3>
			{getCallDetails()}
		</form>
	);
};

export default CallDetails;
