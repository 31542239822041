import * as React from 'react';

const TreeViewIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
		<path
			stroke='currentColor'
			strokeWidth={1.5}
			d='M11 2.4H3.4a1 1 0 0 0-1 1v2.8a1 1 0 0 0 1 1H11a1 1 0 0 0 1-1V3.4a1 1 0 0 0-1-1ZM20.6 9.6H13a1 1 0 0 0-1 1v2.8a1 1 0 0 0 1 1h7.6a1 1 0 0 0 1-1v-2.8a1 1 0 0 0-1-1ZM20.6 16.8H13a1 1 0 0 0-1 1v2.8a1 1 0 0 0 1 1h7.6a1 1 0 0 0 1-1v-2.8a1 1 0 0 0-1-1ZM7.2 7.2V12m0 0v6.2a1 1 0 0 0 1 1H12M7.2 12H12'
		/>
	</svg>
);
export default TreeViewIcon;
