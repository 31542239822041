import { useState, useEffect } from 'react';

function useHasFocus() {
	const [hasFocus, setHasFocus] = useState(() => document.visibilityState === 'visible' && document.hasFocus());

	useEffect(() => {
		let lastFocusState = document.visibilityState === 'visible' && document.hasFocus();

		const updateFocus = newFocusState => {
			if (newFocusState !== lastFocusState) {
				lastFocusState = newFocusState;
				setHasFocus(newFocusState);
			}
		};

		const handleFocus = () => updateFocus(true);
		const handleBlur = () => updateFocus(false);
		const handleVisibilityChange = () => updateFocus(document.visibilityState === 'visible' && document.hasFocus());

		const pollFocus = () => {
			updateFocus(document.visibilityState === 'visible' && document.hasFocus());
			requestAnimationFrame(pollFocus);
		};

		window.addEventListener('focus', handleFocus);
		window.addEventListener('blur', handleBlur);
		document.addEventListener('visibilitychange', handleVisibilityChange);

		pollFocus();

		return () => {
			window.removeEventListener('focus', handleFocus);
			window.removeEventListener('blur', handleBlur);
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	return hasFocus;
}

export default useHasFocus;
