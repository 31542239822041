import { useEffect, useState, useRef, useContext } from 'react';
import { Modal, Button } from 'calls/components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { useSelector } from 'react-redux';
import { useConference, useConferenceConfigurations, useLocalParticipant } from 'calls/hooks/index.js';
import { findSectorById, generateUID, getObjectById } from 'infrastructure/helpers/commonHelpers.js';
import { ButtonType } from 'constants/enums.js';
import CustomSelect from 'components/Select.jsx';
import { useIntl } from 'react-intl';
import { createRequest, finishVoyceRequest, getRequestStatus, getVoyceLanguages } from 'api/voyce.js';
import { requestStatusIds } from 'constants/voyceEnums.js';
import { v4 } from 'uuid';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';
import { getCompanyId } from 'infrastructure/auth.js';
import RemoteParticipant from 'calls/RemoteParticipant.js';
import { APP_CONFIG } from 'constants/global-variables.js';
import { configurationTypeIds } from 'constants/integrationEnums.js';

/**
 * @param {object} props
 * @param {(err) => void} props.setError
 * @param {string} props.roomId
 * @param {object} props.interpretationConfig
 */

const InviteVoyceView = ({ roomId, setError, interpretationConfig }) => {
	const intl = useIntl();
	let interval = useRef(null);
	const callTypes = [
		{ id: 1, name: intl.formatMessage({ id: 'videoAndAudioOption' }) },
		{ id: 2, name: intl.formatMessage({ id: 'audioOnlyOption' }) },
	];
	const conference = useConference();
	const conferenceConfigs = useConferenceConfigurations();
	const socketFunctions = useContext(SocketFunctionsContext);
	const userSession = useSelector(state => state.user.userSession);
	const healthSystems = useSelector(state => state.healthSystems);
	const [selectedLanguage, setSelectedLanguage] = useState({ id: '', name: '', nativeName: '' });
	const [languages, setLanguages] = useState([]);
	const [isInviteLoading, setIsInviteLoading] = useState(false);
	const [interpreterId, setInterpreterId] = useState(null);
	const [invitationSecret, setInvitationSecret] = useState(null);
	const [requestStatusObject, setRequestStatusObject] = useState(null);
	const localParticipant = useLocalParticipant();
	const [selectedCallTypeId, setSelectedCallTypeId] = useState(
		interpretationConfig?.callType ? callTypes.find(item => interpretationConfig.callType === item.id).id : callTypes[0].id
	);

	const findInterpreterParticipant = participants =>
		[...participants.values()].some(item => item instanceof RemoteParticipant && item.interpreterId);

	const getTreeHierarchyParams = () => {
		const sector = findSectorById(healthSystems.treeData.tree, roomId);
		if (!sector) {
			return null;
		}
		const { hospitalId, departmentId, floorId } = sector;
		return { healthSystemId: userSession.healthSystem.id, hospitalId, departmentId, floorId };
	};

	const fetchVoyceLanguages = async (url, location) => {
		if (!url) {
			return;
		}
		const response = await getVoyceLanguages(url, location);
		if (response.error) {
			setError(response.error.message);
		} else if (!response.Successful) {
			setError(response.Reason);
		} else {
			const languages = response?.LanguageList ?? [];
			const mapedLanguages = languages.map(item => ({
				id: item.LanguageId,
				name: item.LanguageName,
				nativeName: item.LanguageNativeName,
			}));
			setLanguages(mapedLanguages);
			const patientInfo = conference.additionalData.find(item => item.key === 'patientInfo');
			if (patientInfo) {
				const { preferredLanguage } = patientInfo.value;
				const foundLanguage = mapedLanguages.find(
					item => preferredLanguage && item.name.toLowerCase().startsWith(preferredLanguage.toLowerCase())
				);
				if (!foundLanguage) {
					return;
				}
				setSelectedLanguage(foundLanguage);
			}
		}
	};

	useEffect(() => {
		const location = getTreeHierarchyParams();
		conferenceConfigs.setInterpreterBaseUrl(interpretationConfig.baseUrl);
		fetchVoyceLanguages(interpretationConfig.baseUrl, location);
	}, []);

	const shouldPoll = statusCodeId =>
		![requestStatusIds.CANCELED.id, requestStatusIds.NO_INTERPRETER_AVILABLE.id, requestStatusIds.SERVICED.id].includes(
			statusCodeId
		);

	useEffect(() => {
		const fetchRequestStatusByPolling = async () => {
			if (!conferenceConfigs.voyceInterpreterRequestId) {
				return;
			}
			const location = getTreeHierarchyParams();
			const response = await getRequestStatus(
				conferenceConfigs.voyceInterpreterRequestId,
				interpretationConfig.baseUrl,
				location
			);

			if (response.error) {
				setError(response.error.message);
			}
			setRequestStatusObject(response);
			if (!shouldPoll(response?.StatusCodeId)) {
				clearInterval(interval.current);
			}
		};

		interval.current = setInterval(() => {
			fetchRequestStatusByPolling();
		}, 1000);

		const interpreter = findInterpreterParticipant(conference.participants);
		if (interpreter) {
			clearInterval(interval.current);
		}
		return () => {
			if (interval?.current) {
				clearInterval(interval.current);
			}
		};
	}, [conferenceConfigs.voyceInterpreterRequestId, conference]);

	useEffect(() => {
		if (requestStatusObject) {
			updateInterpreterAssigmentRequest();
		}
	}, [requestStatusObject?.StatusCodeId, conferenceConfigs.voyceInterpreterRequestId, selectedCallTypeId]);

	const inviteInterpreter = async () => {
		const uuid = v4();
		const generatedSecretUid = generateUID(10);
		setInterpreterId(uuid);
		setInvitationSecret(generatedSecretUid);
		const location = getTreeHierarchyParams();
		const baseUrl = APP_CONFIG.URL.localApiBasePath.replace('localhost:3000', 'hellocare.dev.solaborate.com').trim();
		const params = {
			languageId: selectedLanguage?.id,
			callType: selectedCallTypeId,
			baseUrl: interpretationConfig.baseUrl,
			inviteUrl: `${baseUrl}call-session/${conference.conferenceId}/invitation/${uuid}|${generatedSecretUid}?interpreterId=${uuid}`,
			conferenceId: conference.conferenceId,
		};
		setIsInviteLoading(true);
		const response = await createRequest(params, location);
		if (response.error) {
			setIsInviteLoading(false);
			conferenceConfigs.setVoyceInterpreterRequestId(null);
			setError(response.error.message);
			return;
		}
		conferenceConfigs.setVoyceInterpreterRequestId(response.RequestId);
		setIsInviteLoading(false);
	};

	const onLanguageSelect = event => {
		const foundItem = languages.find(language => language.id.toString() === event.target.value);
		if (!foundItem) {
			return;
		}
		setSelectedLanguage(foundItem);
	};

	const finishRequest = async () => {
		if (!conferenceConfigs.voyceInterpreterRequestId) {
			return;
		}
		const location = getTreeHierarchyParams();
		const response = await finishVoyceRequest(
			conferenceConfigs.voyceInterpreterRequestId,
			interpretationConfig.baseUrl,
			location
		);
		if (response.error) {
			setError(response.error.message);
			return;
		}
	};

	const getInterpreterMethodType = statusCodeId => {
		let result = null;
		switch (statusCodeId) {
			case requestStatusIds.NEW.id: {
				result = 'create';
				break;
			}
			case requestStatusIds.IN_SERVICE.id:
			case requestStatusIds.INTERPRETER_ACCEPTED.id: {
				result = 'update';
				break;
			}
			case requestStatusIds.CANCELED.id:
			case requestStatusIds.SERVICED.id:
			case requestStatusIds.NO_INTERPRETER_AVILABLE.id: {
				result = 'cancel';
				break;
			}
			default: {
				return result;
			}
		}
		return result;
	};

	const getInterpreterName = () =>
		requestStatusObject?.InterpreterName ? requestStatusObject.InterpreterName.replace('Interpreter Name: ', '').trim() : '';

	const updateInterpreterAssigmentRequest = async (uid = null) => {
		const methodType = getInterpreterMethodType(requestStatusObject.StatusCodeId);
		if (!methodType) {
			return;
		}
		await socketFunctions.sendInterpreterAssignmentRequest({
			conferenceId: conference.conferenceId,
			participantId: localParticipant.id,
			methodType,
			data: {
				uid: uid ?? interpreterId,
				healthSystemId: userSession.healthSystem.id,
				companyId: getCompanyId(),
				...(requestStatusObject.InterpreterName && {
					firstName: getInterpreterName(),
				}),
				invitationSecret,
				callType: selectedCallTypeId,
				provider: configurationTypeIds.VOYCE.description,
			},
		});
	};

	const showEstimatedTime = () =>
		![
			requestStatusIds.NO_INTERPRETER_AVILABLE.id,
			requestStatusIds.INTERPRETER_ACCEPTED.id,
			requestStatusIds.CANCELED.id,
			requestStatusIds.SERVICED.id,
		].includes(requestStatusObject?.StatusCodeId);

	const getRequestStatusDescription = () => getObjectById(requestStatusObject.StatusCodeId, requestStatusIds)?.description;

	const showCanelRequest = () =>
		requestStatusObject &&
		[requestStatusIds.NEW.id, requestStatusIds.NO_INITIATED.id, requestStatusIds.NO_INTERPRETER_AVILABLE.id].includes(
			requestStatusObject?.StatusCodeId
		);

	const showInviteButton = () =>
		!findInterpreterParticipant(conference.participants) &&
		(!requestStatusObject ||
			[requestStatusIds.CANCELED.id, requestStatusIds.SERVICED.id].includes(requestStatusObject.StatusCodeId));

	return (
		<>
			<Modal.Content>
				{!findInterpreterParticipant(conference.participants) && (
					<div className='translation-services'>
						<div>
							<CustomSelect
								labelClassName='full-width flex'
								className='margin-left-auto'
								label={intl.formatMessage({ id: 'language' })}
								name='selectLanguage'
								items={languages}
								valueField='id'
								textField='name'
								placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({ id: 'language' })}`}
								value={selectedLanguage.id}
								onSelect={onLanguageSelect}
							/>
						</div>
						<div>
							<CustomSelect
								labelClassName='full-width flex'
								className='margin-left-auto'
								label={intl.formatMessage({ id: 'callType' })}
								name='selectCallType'
								items={callTypes}
								valueField='id'
								textField='name'
								placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({ id: 'callType' })}`}
								value={selectedCallTypeId}
								onSelect={event => setSelectedCallTypeId(+event.target.value)}
							/>
						</div>
						{requestStatusObject && (
							<div>
								{translate('status')}: {getRequestStatusDescription()}
							</div>
						)}
						{requestStatusObject && showEstimatedTime() && (
							<div>
								{translate('estimatedTime')}: {requestStatusObject?.EstimationTimeString}
							</div>
						)}
					</div>
				)}
				{findInterpreterParticipant(conference.participants) && (
					<div className='translation-services'>
						<p>{`You're in call with ${getInterpreterName()} interpreter.`}</p>
					</div>
				)}
			</Modal.Content>
			<Modal.Actions>
				{showInviteButton() && (
					<Button
						type='submit'
						onClick={inviteInterpreter}
						variant={ButtonType.SUBMIT}
						disabled={isInviteLoading || !selectedLanguage.id}>
						{translate('inviteInterpreter')}
					</Button>
				)}
				{showCanelRequest() && (
					<Button type='submit' onClick={finishRequest} variant={ButtonType.SUBMIT}>
						{translate('cancel')} {translate('request')}
					</Button>
				)}
			</Modal.Actions>
		</>
	);
};

export default InviteVoyceView;
