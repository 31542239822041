import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Apps = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill='none'
		color={LightTheme.colors.graySeven}
		{...props}>
		<circle cx={6.6} cy={6.6} r={3.6} stroke='currentColor' strokeMiterlimit={10} />
		<circle cx={6.6} cy={17.4} r={3.6} stroke='currentColor' strokeMiterlimit={10} />
		<circle cx={17.4} cy={6.6} r={3.6} stroke='currentColor' strokeMiterlimit={10} />
		<circle cx={17.4} cy={17.4} r={3.6} stroke='currentColor' strokeMiterlimit={10} />
	</svg>
);

export default Apps;
