import Breadcrumb from 'components/Breadcrumb.jsx';
import Grid from 'components/Grid.jsx';
import classNames from 'classnames';

const Header = props => (
	<header className={classNames('header', props.className)}>
		<div className='header-left-info'>
			<Breadcrumb links={props.links} onBreadcrumbClick={props.onBreadcrumbClick} />
		</div>
		<Grid columns='1fr 3fr' className='full-width header-elements'>
			{props.children}
		</Grid>
	</header>
);

export default Header;
