import * as React from 'react';
const EthernetIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={21} height={20} fill='none' color='#3C4257' {...props}>
		<path
			stroke='currentColor'
			strokeWidth={1.5}
			d='M3.167 14.666v-6.81c0-.915.751-1.653 1.667-1.636l.616.01c.577.01 1.05-.454 1.05-1.032 0-.57.463-1.032 1.033-1.032H13.8c.57 0 1.033.462 1.033 1.032 0 .578.473 1.043 1.05 1.033l.616-.011a1.637 1.637 0 0 1 1.667 1.637v6.809a2 2 0 0 1-2 2h-11a2 2 0 0 1-2-2Z'
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M6.5 12.5v.833m2.778-.833v.833m5.556-.833v.833m-2.778-.833v.833'
		/>
	</svg>
);
export default EthernetIcon;
