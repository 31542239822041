import translate from 'i18n-translations/translate.jsx';

export const MaritalStatusList = {
	ANNULLED: {
		id: 1,
		content: 'annulled',
		code: 'A',
	},
	DIVORCED: {
		id: 2,
		content: 'divorced',
		code: 'D',
	},
	INTERLOCUTORY: {
		id: 3,
		content: 'interlocutory',
		code: 'I',
	},
	LEGALLY_SEPARATED: {
		id: 4,
		content: 'legallySeparated',
		code: 'L',
	},
	MARRIED: {
		id: 5,
		content: 'married',
		code: 'M',
	},
	POLYGAMOUS: {
		id: 6,
		content: 'polygamous',
		code: 'P',
	},
	NEVER_MARRIED: {
		id: 7,
		content: 'neverMarried',
		code: 'S',
	},
	DOMESTIC_PARTNER: {
		id: 8,
		content: 'domesticPartner',
		code: 'T',
	},
	UNMARRIED: {
		id: 9,
		content: 'unmarried',
		code: 'U',
	},
	WIDOWED: {
		id: 10,
		content: 'widowed',
		code: 'W',
	},
};

export const PrimaryCareForms = {
	CONSULTATION_INSTRUCTIONS: 1,
	LABORATORY: 2,
	PTH_INJECTION: 3,
	PTH_INFUSION: 4,
	RADIOLOGY: 6,
	DRUGS: 7
};

export const VisitTypes = {
	FRONT_DESK: 1,
	DOCTOR: 2,
	LAB: 3,
	INJECTION: 4,
	INFUSION: 5,
	RADIOLOGY: 6,
};

export const VisitStatus = {
	PENDING: 1,
	INVITED: 2,
	DID_NOT_SHOW_UP: 3,
	IN_PROGRESS: 4,
	COMPLETED: 5,
	CANCELLED: 6,
	SECOND_INVITED: 7,
	INVITED_IN_CALL: 10,
};

export const CaseStatusTypes = {
	ACTIVE: 1,
	COMPLETED: 2,
	CANCELLED: 3,
};

export const TestTypes = {
	SEDIMENTATION: 1,
	HEMOGRAM: 2,
	GLUCOSE: 3,
	UREA: 4,
	CREATININE: 5,
	TRIGLYCERIDES: 6,
	CHOLESTEROL: 7,
	URINE: 8,
	PULMONARY: 9,
	SKELETON: 10,
	CHEST: 11,
	UPPER_EXTREMITIES: 12,
	LOWER_EXTREMITIES: 13,
	SPINE: 14,
	ULTRASONOGRAPHY: 15,
};

export const AnalysisSubTypes = {
	ERYTHROCYTE_SEDIMENTATION: 1,
	ERYTHROCYTE_COUNT: 2,
	HEMOGLOBIN: 3,
	LEUKOCYTE_COUNT: 4,
	MCV: 5,
	MCH: 6,
	MCHC: 7,
	HEMATOCRIT: 8,
	GLUCOSE: 9,
	UREA: 10,
	CREATININE: 11,
	TRIGLYCERIDES: 12,
	CHOLESTEROL: 13,
	LDL_CHOLESTEROL: 14,
	VLD_CHOLESTEROL: 15,
	HDL_CHOLESTEROL: 16,
	VIEW: 17,
	COLOR: 18,
	REACTION: 19,
	SPECIFIC_WEIGHT: 20,
	PROTEINS: 21,
	URINE_GLUCOSE: 22,
	ACETONE: 23,
	BILIRUBIN: 24,
	UROBILINOGEN: 25,
	NITRITES: 26,
	SEDIMENT: 27,
};

export const QueueChangeType = {
	STATUS_CHANGED: 1,
	NEW: 2,
};

export const Allergies = {
	FOOD: {
		id: 1,
		type: 'food',
	},
	MEDICATION: {
		id: 2,
		type: 'medication',
	},
	ENVIRONMENTAL: {
		id: 3,
		type: 'environmental',
	},
	BIOLOGICAL: {
		id: 4,
		type: 'biological',
	},
};

export const RoomTypes = {
	GENERAL: {
		type: 1,
		message: translate('general'),
	},
	OTHER: {
		type: 2,
		message: translate('other'),
	},
	DEFAULT: {
		type: 3,
		message: translate('room'),
	},
	BABY_ROOM: {
		type: 4,
		message: translate('babyRoom'),
	},
	FRONT_DESK: {
		type: 5,
		message: translate('frontDesk'),
	},
	DOCTOR: {
		type: 6,
		message: translate('doctor'),
	},
	LABORATORY: {
		type: 7,
		message: translate('laboratory'),
	},
	INJECTION_ROOM: {
		type: 8,
		message: translate('injectionRoom'),
	},
	INFUSION_ROOM: {
		type: 9,
		message: translate('infusionRoom'),
	},
	DIGITAL_CLINIC: {
		type: 10,
		message: translate('digitalClinic'),
	},
	PHARMACY: {
		type: 11,
		message: translate('pharmacy'),
	},
	RADIOLOGY: {
		type: 12,
		message: translate('radiology'),
	},
	KIOSK: {
		type: 13,
		message: translate('kiosk'),
	},
	TRIAGE: {
		type: 14,
		message: translate('triage'),
	},
};

export const AllergyAnswer = {
	NO_ANSWER: {
		content: 'NO_ANSWER',
		value: 0,
	},
	YES: { content: 'YES', value: 1 },
	NO: { content: 'NO', value: 2 },
};

export const ParenteralTherapyType = {
	INFUSION: 1,
	INJECTION: 2,
	DRUGS: 3,
};

export const DiagnosticResearchType = {
	LABORATORY: 1,
	RADIOLOGY: 2,
};
