export const languages = [
	{
		label: 'ACHOLI',
		value: 'acholi-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 0,
	},
	{
		label: 'AFAR',
		value: 'afar-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 1,
	},
	{
		label: 'AFRIKAANS',
		value: 'afrikaans-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 2,
	},
	{
		label: 'AKAN',
		value: 'akan-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 3,
	},
	{
		label: 'AKATEKO',
		value: 'akateko-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 4,
	},
	{
		label: 'ALBANIAN',
		value: 'albanian-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 5,
	},
	{
		label: 'All Audio - Other',
		value: 'other-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 6,
	},
	{
		label: 'AMHARIC',
		value: 'amharic-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 7,
	},
	{
		label: 'ANUAK',
		value: 'anuak-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 8,
	},
	{
		label: 'APACHE',
		value: 'apache-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 9,
	},
	{
		label: 'ARABIC',
		value: 'arabic-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 10,
	},
	{
		label: 'ARMENIAN',
		value: 'armenian-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 11,
	},
	{
		label: 'ASSYRIAN',
		value: 'assyrian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 12,
	},
	{
		label: 'AZERBAIJANI',
		value: 'azerbaijani-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 13,
	},
	{
		label: 'BAHASA',
		value: 'bahasa-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 14,
	},
	{
		label: 'BAHDINI',
		value: 'bahdini-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 15,
	},
	{
		label: 'BAHNAR',
		value: 'bahnar-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 16,
	},
	{
		label: 'BAJUNI',
		value: 'bajuni-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 17,
	},
	{
		label: 'BAMBARA',
		value: 'bambara-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 18,
	},
	{
		label: 'BANTU',
		value: 'bantu-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 19,
	},
	{
		label: 'BARESE',
		value: 'barese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 20,
	},
	{
		label: 'BASQUE',
		value: 'basque-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 21,
	},
	{
		label: 'BASSA',
		value: 'bassa-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 22,
	},
	{
		label: 'BELORUSSIAN',
		value: 'belorussian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 23,
	},
	{
		label: 'BEMBA',
		value: 'bemba-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 24,
	},
	{
		label: 'BENAADIR',
		value: 'benaadir-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 25,
	},
	{
		label: 'BENGALI',
		value: 'bengali-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 26,
	},
	{
		label: 'BERBER',
		value: 'berber-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 27,
	},
	{
		label: 'BOSNIAN',
		value: 'bosnian-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 28,
	},
	{
		label: 'BRAVANESE',
		value: 'bravanese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 29,
	},
	{
		label: 'BULGARIAN',
		value: 'bulgarian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 30,
	},
	{
		label: 'BURMESE',
		value: 'burmese-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 31,
	},
	{
		label: 'CANTONESE',
		value: 'cantonese-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 32,
	},
	{
		label: 'CATALAN',
		value: 'catalan-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 33,
	},
	{
		label: 'CEBUANO',
		value: 'cebuano-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 34,
	},
	{
		label: 'CHALDEAN',
		value: 'chaldean-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 35,
	},
	{
		label: 'CHAMORRO',
		value: 'chamorro-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 36,
	},
	{
		label: 'CHAOCHOW',
		value: 'chaochow-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 37,
	},
	{
		label: 'CHIN FALAM',
		value: 'chinfalam-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 38,
	},
	{
		label: 'CHIN HAKHA',
		value: 'chinhakha-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 39,
	},
	{
		label: 'CHIN MARA',
		value: 'chinmara-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 40,
	},
	{
		label: 'CHIN MATU',
		value: 'chinmatu-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 41,
	},
	{
		label: 'CHIN SENTHANG',
		value: 'chinsenthang-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 42,
	},
	{
		label: 'CHIN TEDIM',
		value: 'chintedim-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 43,
	},
	{
		label: 'CHIPEWYAN',
		value: 'chipewyan-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 44,
	},
	{
		label: 'CHUUKESE',
		value: 'chuukese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 45,
	},
	{
		label: 'CREE',
		value: 'cree-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 46,
	},
	{
		label: 'CROATIAN',
		value: 'croatian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 47,
	},
	{
		label: 'CZECH',
		value: 'czech-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 48,
	},
	{
		label: 'DANISH',
		value: 'danish-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 49,
	},
	{
		label: 'DARI',
		value: 'dari-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 50,
	},
	{
		label: 'DEWOIN',
		value: 'dewoin-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 51,
	},
	{
		label: 'DINKA',
		value: 'dinka-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 52,
	},
	{
		label: 'DUALA',
		value: 'duala-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 53,
	},
	{
		label: 'DUTCH',
		value: 'dutch-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 54,
	},
	{
		label: 'DZONGKHA',
		value: 'dzongkha-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 55,
	},
	{
		label: 'EDO',
		value: 'edo-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 56,
	},
	{
		label: 'EKEGUSII',
		value: 'ekegusii-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 57,
	},
	{
		label: 'ESTONIAN',
		value: 'estonian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 58,
	},
	{
		label: 'EWE',
		value: 'ewe-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 59,
	},
	{
		label: 'FARSI',
		value: 'farsi-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 60,
	},
	{
		label: 'FIJIAN',
		value: 'fijian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 61,
	},
	{
		label: 'FIJIAN HINDI',
		value: 'fijianhindi-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 62,
	},
	{
		label: 'FINNISH',
		value: 'finnish-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 63,
	},
	{
		label: 'FLEMISH',
		value: 'flemish-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 64,
	},
	{
		label: 'FRENCH',
		value: 'french-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 65,
	},
	{
		label: 'FRENCH CANADIAN',
		value: 'frenchcanadian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 66,
	},
	{
		label: 'FUKIENESE',
		value: 'fukienese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 67,
	},
	{
		label: 'FULANI',
		value: 'fulani-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 68,
	},
	{
		label: 'FUZHOU',
		value: 'fuzhou-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 69,
	},
	{
		label: 'GA',
		value: 'ga-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 70,
	},
	{
		label: 'GADDANG',
		value: 'gaddang-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 71,
	},
	{
		label: 'GAELIC-IRISH',
		value: 'gaelicirish-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 72,
	},
	{
		label: 'GAELIC-SCOTTISH',
		value: 'gaelicscottish-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 73,
	},
	{
		label: 'GARIFUNA',
		value: 'garifuna-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 74,
	},
	{
		label: 'GARRE',
		value: 'garre-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 75,
	},
	{
		label: 'GEN',
		value: 'gen-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 76,
	},
	{
		label: 'GEORGIAN',
		value: 'georgian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 77,
	},
	{
		label: 'GERMAN',
		value: 'german-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 78,
	},
	{
		label: 'GERMAN PENNSYLVANIA DUTCH',
		value: 'germanpennsylvaniadutch-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 79,
	},
	{
		label: 'GHEG',
		value: 'gheg-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 80,
	},
	{
		label: 'GOKANA',
		value: 'gokana-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 81,
	},
	{
		label: 'GREEK',
		value: 'greek-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 82,
	},
	{
		label: 'GUARANI',
		value: 'guarani-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 83,
	},
	{
		label: 'GUJARATI',
		value: 'gujarati-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 84,
	},
	{
		label: 'GULAY',
		value: 'gulay-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 85,
	},
	{
		label: 'GURANI',
		value: 'gurani-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 86,
	},
	{
		label: 'HAITIAN CREOLE',
		value: 'haitiancreole-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 87,
	},
	{
		label: 'HAKKA-CHINA',
		value: 'hakkachina-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 88,
	},
	{
		label: 'HAKKA-TAIWAN',
		value: 'hakkataiwan-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 89,
	},
	{
		label: 'HASSANIYYA',
		value: 'hassaniyya-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 90,
	},
	{
		label: 'HAUSA',
		value: 'hausa-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 91,
	},
	{
		label: 'HAWAIIAN',
		value: 'hawaiian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 92,
	},
	{
		label: 'HEBREW',
		value: 'hebrew-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 93,
	},
	{
		label: 'HILIGAYNON',
		value: 'hiligaynon-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 94,
	},
	{
		label: 'HINDI',
		value: 'hindi-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 95,
	},
	{
		label: 'HINDKO',
		value: 'hindko-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 96,
	},
	{
		label: 'HMONG',
		value: 'hmong-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 97,
	},
	{
		label: 'HUNANESE',
		value: 'hunanese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 98,
	},
	{
		label: 'HUNGARIAN',
		value: 'hungarian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 99,
	},
	{
		label: 'IBANAG',
		value: 'ibanag-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 100,
	},
	{
		label: 'ICELANDIC',
		value: 'icelandic-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 101,
	},
	{
		label: 'IGBO',
		value: 'igbo-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 102,
	},
	{
		label: 'ILOCANO',
		value: 'ilocano-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 103,
	},
	{
		label: 'INDONESIAN',
		value: 'indonesian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 104,
	},
	{
		label: 'INUKTITUT',
		value: 'inuktitut-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 105,
	},
	{
		label: 'ITALIAN',
		value: 'italian-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 106,
	},
	{
		label: 'JAKARTANESE',
		value: 'jakartanese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 107,
	},
	{
		label: 'JAMAICAN PATOIS',
		value: 'jamaicanpatois-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 108,
	},
	{
		label: 'JAPANESE',
		value: 'japanese-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 109,
	},
	{
		label: 'JARAI',
		value: 'jarai-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 110,
	},
	{
		label: 'JAVANESE',
		value: 'javanese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 111,
	},
	{
		label: 'JINGPHO',
		value: 'jingpho-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 112,
	},
	{
		label: 'JINYU',
		value: 'jinyu-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 113,
	},
	{
		label: 'JUBA ARABIC',
		value: 'jubaarabic-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 114,
	},
	{
		label: 'JULA',
		value: 'jula-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 115,
	},
	{
		label: 'KABA',
		value: 'kaba-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 116,
	},
	{
		label: 'KAM MUANG',
		value: 'kammuang-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 117,
	},
	{
		label: 'KAMBA',
		value: 'kamba-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 118,
	},
	{
		label: 'KANJOBAL',
		value: 'kanjobal-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 119,
	},
	{
		label: 'KANNADA',
		value: 'kannada-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 120,
	},
	{
		label: 'KAREN',
		value: 'karen-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 121,
	},
	{
		label: 'KASHMIRI',
		value: 'kashmiri-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 122,
	},
	{
		label: 'KAYAH',
		value: 'kayah-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 123,
	},
	{
		label: 'KAZAKH',
		value: 'kazakh-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 124,
	},
	{
		label: 'KHAM',
		value: 'kham-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 125,
	},
	{
		label: 'KHANA',
		value: 'khana-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 126,
	},
	{
		label: 'KHMER',
		value: 'khmer-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 127,
	},
	{
		label: 'KICHE',
		value: 'kiche-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 128,
	},
	{
		label: 'KIKUYU',
		value: 'kikuyu-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 129,
	},
	{
		label: 'KIMIIRU',
		value: 'kimiiru-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 130,
	},
	{
		label: 'KINYARWANDA',
		value: 'kinyarwanda-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 131,
	},
	{
		label: 'KISSI NORTHERN',
		value: 'kissinorthern-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 132,
	},
	{
		label: 'KOHO',
		value: 'koho-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 133,
	},
	{
		label: 'KOREAN',
		value: 'korean-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 134,
	},
	{
		label: 'KRAHN',
		value: 'krahn-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 135,
	},
	{
		label: 'KRIO',
		value: 'krio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 136,
	},
	{
		label: 'KUNAMA',
		value: 'kunama-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 137,
	},
	{
		label: 'KURMANJI',
		value: 'kurmanji-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 138,
	},
	{
		label: 'KYRGYZ',
		value: 'kyrgyz-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 139,
	},
	{
		label: 'LAOTIAN',
		value: 'laotian-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 140,
	},
	{
		label: 'LATVIAN',
		value: 'latvian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 141,
	},
	{
		label: 'LIBERIAN PIDGIN ENGLISH',
		value: 'liberianpidginenglish-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 142,
	},
	{
		label: 'LINGALA',
		value: 'lingala-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 143,
	},
	{
		label: 'LITHUANIAN',
		value: 'lithuanian-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 144,
	},
	{
		label: 'LUBA-KASAI',
		value: 'lubakasai-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 145,
	},
	{
		label: 'LUGANDA',
		value: 'luganda-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 146,
	},
	{
		label: 'LUO',
		value: 'luo-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 147,
	},
	{
		label: 'MAAY',
		value: 'maay-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 148,
	},
	{
		label: 'MACEDONIAN',
		value: 'macedonian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 149,
	},
	{
		label: 'MALAY',
		value: 'malay-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 150,
	},
	{
		label: 'MALAYALAM',
		value: 'malayalam-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 151,
	},
	{
		label: 'MALTESE',
		value: 'maltese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 152,
	},
	{
		label: 'MAM',
		value: 'mam-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 153,
	},
	{
		label: 'MANDARIN',
		value: 'mandarin-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 154,
	},
	{
		label: 'MANDINKA',
		value: 'mandinka-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 155,
	},
	{
		label: 'MANINKA',
		value: 'maninka-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 156,
	},
	{
		label: 'MANOBO',
		value: 'manobo-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 157,
	},
	{
		label: 'MARATHI',
		value: 'marathi-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 158,
	},
	{
		label: 'MARKA',
		value: 'marka-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 159,
	},
	{
		label: 'MARSHALLESE',
		value: 'marshallese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 160,
	},
	{
		label: 'MASALIT',
		value: 'masalit-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 161,
	},
	{
		label: 'MBAY',
		value: 'mbay-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 162,
	},
	{
		label: 'MIEN',
		value: 'mien-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 163,
	},
	{
		label: 'MIRPURI',
		value: 'mirpuri-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 164,
	},
	{
		label: 'MIXTECO',
		value: 'mixteco-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 165,
	},
	{
		label: 'MIZO',
		value: 'mizo-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 166,
	},
	{
		label: 'MNONG',
		value: 'mnong-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 167,
	},
	{
		label: 'MONGOLIAN',
		value: 'mongolian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 168,
	},
	{
		label: 'MONTENEGRIN',
		value: 'montenegrin-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 169,
	},
	{
		label: 'MOROCCAN ARABIC',
		value: 'moroccanarabic-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 170,
	},
	{
		label: 'MORTLOCKESE',
		value: 'mortlockese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 171,
	},
	{
		label: 'NAPOLETANO',
		value: 'napoletano-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 172,
	},
	{
		label: 'NAVAJO',
		value: 'navajo-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 173,
	},
	{
		label: 'NEPALI',
		value: 'nepali-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 174,
	},
	{
		label: 'NGAMBAY',
		value: 'ngambay-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 175,
	},
	{
		label: 'NIGERIAN PIDGIN',
		value: 'nigerianpidgin-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 176,
	},
	{
		label: 'NORWEGIAN',
		value: 'norwegian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 177,
	},
	{
		label: 'NUER',
		value: 'nuer-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 178,
	},
	{
		label: 'NUPE',
		value: 'nupe-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 179,
	},
	{
		label: 'NYANJA',
		value: 'nyanja-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 180,
	},
	{
		label: 'NYORO',
		value: 'nyoro-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 181,
	},
	{
		label: 'OJIBWAY',
		value: 'ojibway-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 182,
	},
	{
		label: 'OROMO',
		value: 'oromo-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 183,
	},
	{
		label: 'PAMPANGAN',
		value: 'pampangan-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 184,
	},
	{
		label: 'PAPIAMENTO',
		value: 'papiamento-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 185,
	},
	{
		label: 'PASHTO',
		value: 'pashto-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 186,
	},
	{
		label: 'PASHTO - Interpreter',
		value: 'pashto-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 187,
	},
	{
		label: 'PLAUTDIETSCH',
		value: 'plautdietsch-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 188,
	},
	{
		label: 'POHNPEIAN',
		value: 'pohnpeian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 189,
	},
	{
		label: 'POLISH',
		value: 'polish-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 190,
	},
	{
		label: 'PORTUGUESE',
		value: 'portuguese-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 191,
	},
	{
		label: 'PORTUGUESE BRAZILIAN',
		value: 'portuguesebrazilian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 192,
	},
	{
		label: 'PORTUGUESE CAPE VERDEAN',
		value: 'portuguesecapeverdean-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 193,
	},
	{
		label: 'PUGLIESE',
		value: 'pugliese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 194,
	},
	{
		label: 'PULAAR',
		value: 'pulaar-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 195,
	},
	{
		label: 'PUNJABI',
		value: 'punjabi-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 196,
	},
	{
		label: 'PUTIAN',
		value: 'putian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 197,
	},
	{
		label: 'QUECHUA',
		value: 'quechua-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 198,
	},
	{
		label: 'QUICHUA',
		value: 'quichua-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 199,
	},
	{
		label: 'QEQCHI',
		value: 'qeqchi-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 200,
	},
	{
		label: 'RADE',
		value: 'rade-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 201,
	},
	{
		label: 'RAKHINE',
		value: 'rakhine-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 202,
	},
	{
		label: 'ROHINGYA',
		value: 'rohingya-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 203,
	},
	{
		label: 'ROMANIAN',
		value: 'romanian-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 204,
	},
	{
		label: 'RUNDI',
		value: 'rundi-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 205,
	},
	{
		label: 'RUSSIAN',
		value: 'russian-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 206,
	},
	{
		label: 'SAMOAN',
		value: 'samoan-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 207,
	},
	{
		label: 'SANGO',
		value: 'sango-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 208,
	},
	{
		label: 'SERAIKI',
		value: 'seraiki-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 209,
	},
	{
		label: 'SERBIAN',
		value: 'serbian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 210,
	},
	{
		label: 'SHANGHAINESE',
		value: 'shanghainese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 211,
	},
	{
		label: 'SHONA',
		value: 'shona-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 212,
	},
	{
		label: 'SICHUAN YI',
		value: 'sichuanyi-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 213,
	},
	{
		label: 'SICILIAN',
		value: 'sicilian-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 214,
	},
	{
		label: 'SINHALA',
		value: 'sinhala-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 215,
	},
	{
		label: 'SLOVAK',
		value: 'slovak-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 216,
	},
	{
		label: 'SLOVENE',
		value: 'slovene-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 217,
	},
	{
		label: 'SOGA',
		value: 'soga-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 218,
	},
	{
		label: 'SOMALI',
		value: 'somali-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 219,
	},
	{
		label: 'SONINKE',
		value: 'soninke-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 220,
	},
	{
		label: 'SORANI',
		value: 'sorani-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 221,
	},
	{
		label: 'SPANISH',
		value: 'spanish-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 222,
	},
	{
		label: 'SUDANESE ARABIC',
		value: 'sudanesearabic-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 223,
	},
	{
		label: 'SUNDA',
		value: 'sunda-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 224,
	},
	{
		label: 'SUSU',
		value: 'susu-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 225,
	},
	{
		label: 'SWAHILI',
		value: 'swahili-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 226,
	},
	{
		label: 'SWEDISH',
		value: 'swedish-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 227,
	},
	{
		label: 'SYLHETI',
		value: 'sylheti-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 228,
	},
	{
		label: 'TAGALOG',
		value: 'tagalog-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 229,
	},
	{
		label: 'TAIWANESE',
		value: 'taiwanese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 230,
	},
	{
		label: 'TAJIK',
		value: 'tajik-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 231,
	},
	{
		label: 'TAMIL',
		value: 'tamil-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 232,
	},
	{
		label: 'TELUGU',
		value: 'telugu-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 233,
	},
	{
		label: 'TEST',
		value: 'test-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 234,
	},
	{
		label: 'TETUN',
		value: 'tetun-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 235,
	},
	{
		label: 'THAI',
		value: 'thai-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 236,
	},
	{
		label: 'TIBETAN',
		value: 'tibetan-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 237,
	},
	{
		label: 'TIGRE',
		value: 'tigre-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 238,
	},
	{
		label: 'TIGRIGNA',
		value: 'tigrigna-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 239,
	},
	{
		label: 'TOISHANESE',
		value: 'toishanese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 240,
	},
	{
		label: 'TONGAN',
		value: 'tongan-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 241,
	},
	{
		label: 'TOORO',
		value: 'tooro-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 242,
	},
	{
		label: 'TRIQUE',
		value: 'trique-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 243,
	},
	{
		label: 'TURKISH',
		value: 'turkish-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 244,
	},
	{
		label: 'TURKMEN',
		value: 'turkmen-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 245,
	},
	{
		label: 'TZOTZIL',
		value: 'tzotzil-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 246,
	},
	{
		label: 'UKRAINIAN',
		value: 'ukrainian-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 247,
	},
	{
		label: 'URDU',
		value: 'urdu-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 248,
	},
	{
		label: 'UYGHUR',
		value: 'uyghur-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 249,
	},
	{
		label: 'UZBEK',
		value: 'uzbek-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 250,
	},
	{
		label: 'VIETNAMESE',
		value: 'vietnamese-audio-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 251,
	},
	{
		label: 'VISAYAN',
		value: 'visayan-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 252,
	},
	{
		label: 'WELSH',
		value: 'welsh-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 253,
	},
	{
		label: 'WENZHOUNESE',
		value: 'wenzhounese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 254,
	},
	{
		label: 'WODAABE',
		value: 'wodaabe-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 255,
	},
	{
		label: 'WOLOF',
		value: 'wolof-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 256,
	},
	{
		label: 'YEMENI ARABIC',
		value: 'yemeniarabic-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 257,
	},
	{
		label: 'YIDDISH',
		value: 'yiddish-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 258,
	},
	{
		label: 'YORUBA',
		value: 'yoruba-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 259,
	},
	{
		label: 'YUNNANESE',
		value: 'yunnanese-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 260,
	},
	{
		label: 'ZAPOTECO',
		value: 'zapoteco-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 261,
	},
	{
		label: 'ZARMA',
		value: 'zarma-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 262,
	},
	{
		label: 'ZO',
		value: 'zo-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 263,
	},
	{
		label: 'ZYPHE',
		value: 'zyphe-YOUR-AUTHCODE-HERE@sip.languageline.com',
		index: 264,
	},
];
