import { healthCareCdnUrl } from 'constants/global-variables.js';

export const CareEventStatusType = {
	ALL_CARE_EVENTS: 'allEvents',
	NEW: 'new',
	IN_PROGRESS: 'inProgress',
	COMPLETED: 'completed',
	MY_CARE_EVENTS: 'myCareEvents',
};

export const CareEventType = {
	OTHER: 0,
	ADMISSION: 1,
	EDUCATION: 2,
	ROUNDING: 3,
	DISCHARGE: 4,
};

export const CareEventsIcon = {
	ADMISSION: `${healthCareCdnUrl}care-events/admit-patient.svg`,
	EDUCATION: `${healthCareCdnUrl}care-events/education.svg`,
	ROUNDING: `${healthCareCdnUrl}care-events/rounding.svg`,
	DISCHARGE: `${healthCareCdnUrl}care-events/discharge-patient.svg`,
	OTHER: `${healthCareCdnUrl}care-events/other-new.svg`,
};

export const CareEventsHeaders = [
	'patient',
	'created',
	'room',
	'careEventType',
	'sla',
	'priority',
	'status',
	'careTeam',
	'actions',
];

export const ActiveTab = {
	ALL_EVENTS: 0,
	IN_PROGRESS: 1,
	NEW: 2,
	COMPLETED: 3,
	MY_CARE_EVENTS: 4,
};

export const CareEventStatuses = [
	CareEventStatusType.ALL_CARE_EVENTS,
	CareEventStatusType.IN_PROGRESS,
	CareEventStatusType.NEW,
	CareEventStatusType.COMPLETED,
	CareEventStatusType.MY_CARE_EVENTS,
];

export const CareEventTypes = [
	{ careEventId: CareEventType.ADMISSION, name: 'admission', icon: CareEventsIcon.ADMISSION },
	{ careEventId: CareEventType.EDUCATION, name: 'education', icon: CareEventsIcon.EDUCATION },
	{ careEventId: CareEventType.ROUNDING, name: 'rounding', icon: CareEventsIcon.ROUNDING },
	{ careEventId: CareEventType.DISCHARGE, name: 'discharge', icon: CareEventsIcon.DISCHARGE },
	{ careEventId: CareEventType.OTHER, name: 'other', icon: CareEventsIcon.OTHER },
];

export const CareEventStatus = {
	NEW: 1,
	IN_PROGRESS: 2,
	COMPLETED: 3,
	MY_CARE_EVENTS: 4,
};

export const CareEventPriority = {
	HIGH: 1,
	MEDIUM: 2,
	LOW: 3,
};

export const CareEventProp = {
	STATUS_ID: 'statusId',
	CARE_MEMBER: 'careMember',
	PRIORITY_ID: 'priorityId',
};

export const Priorities = [
	{ id: CareEventPriority.LOW, name: 'low' },
	{ id: CareEventPriority.MEDIUM, name: 'medium' },
	{ id: CareEventPriority.HIGH, name: 'high' },
];

export const Statuses = [
	{ id: CareEventStatus.NEW, name: 'new' },
	{ id: CareEventStatus.IN_PROGRESS, name: 'inProgress' },
	{ id: CareEventStatus.COMPLETED, name: 'completed' },
];

export const SLA = {
	LOW: 1,
	MEDIUM: 2,
	HIGH: 3,
};

export const SLAOptions = [
	{ id: SLA.LOW, name: 'low' },
	{ id: SLA.MEDIUM, name: 'medium' },
	{ id: SLA.HIGH, name: 'high' },
];

export const CareEventFilterTab = {
	PATIENTS: 1,
	DATE_RANGE: 2,
	PATIENT_PLACEMENT: 3,
	TYPE: 4,
	SLA: 5,
	PRIORITY: 6,
	STATUS: 7,
	CARE_TEAM: 8,
};

export const CareEventFilterTabs = [
	{
		id: CareEventFilterTab.PATIENTS,
		title: 'patients',
	},
	{
		id: CareEventFilterTab.DATE_RANGE,
		title: 'dateRange',
	},
	{
		id: CareEventFilterTab.PATIENT_PLACEMENT,
		title: 'patientPlacement',
	},
	{
		id: CareEventFilterTab.TYPE,
		title: 'type',
	},
	{
		id: CareEventFilterTab.SLA,
		title: 'SLA',
	},
	{
		id: CareEventFilterTab.PRIORITY,
		title: 'priority',
	},
	{
		id: CareEventFilterTab.STATUS,
		title: 'status',
	},
	{
		id: CareEventFilterTab.CARE_TEAM,
		title: 'careTeam',
	},
];
