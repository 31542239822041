import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import Input from 'components/Common/FormElements/Input.jsx';
import Select from 'components/Common/FormElements/Select.jsx';
import { Form, Modal, Alert } from 'components/index.js'; 
import Loader from 'components/Loader.jsx';
import {
	addTeamNetworkBandwidthConfigurations,
	updateTeamNetworkBandwidthConfigurations,
	addTeamNetworkWiFiBandConfigurations,
	updateTeamNetworkWiFiBandConfigurations,
} from 'api/networkConfigurations.js';
import { TeamTypes, TreeHierarchyType } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';

const NetworkConfigForm = props => {
	const [isSuccessfulAlertOn, setSuccessfulAlertOn] = useState(false);
	const [isErrorsAlertOn, setErrorAlertOn] = useState(false);
	const [errorText, setErrorText] = useState('');

	const intl = useIntl();

	const translator = id => intl.formatMessage({ id });

	const speedOption = {
		Kbps: 1,
	};

	const getKbps = (amount, unit) => {
		if (unit === speedOption.Kbps) {
			return +amount;
		}
		// mbps to kbps
		return +amount * 1000;
	};

	const handleSubmitMyForm = async (values, { setSubmitting }) => {
		setSubmitting(true);
		try {
			let res = null;
			const { floor, hospital, healthSystem } = values;
			let teamId = null;
			let teamType = null;

			if (floor !== '') {
				teamId = floor;
				teamType = TeamTypes.FLOOR;
			} else if (hospital !== '') {
				teamId = hospital;
				teamType = TeamTypes.HOSPITAL;
			} else {
				teamId = healthSystem;
				teamType = TeamTypes.HEALTHSYSTEM;
			}

			if (props.activeTab === 0) {
				const { minBandwidth, minBandwidthUnit, maxBandwidth, maxBandwidthUnit } = values;
				const minP2PBitrateKbps = getKbps(minBandwidth, minBandwidthUnit);
				const maxP2PBitrateKbps = getKbps(maxBandwidth, maxBandwidthUnit);
				if (minP2PBitrateKbps >= maxP2PBitrateKbps) {
					setErrorText(translator('minShouldBeLessThanMax'));
					setErrorAlertOn(true);
					setSubmitting(false);
					return;
				}
				if (!props.initialValues) {
					res = await addTeamNetworkBandwidthConfigurations(teamId, teamType, { minP2PBitrateKbps, maxP2PBitrateKbps });
				} else {
					res = await updateTeamNetworkBandwidthConfigurations(
						props.initialValues.id,
						props.initialValues.teamId,
						props.initialValues.teamType,
						{
							minP2PBitrateKbps,
							maxP2PBitrateKbps,
						}
					);
				}
				if (res.hasSucceeded) {
					setSuccessfulAlertOn(true);
				}
			} else {
				const { wiFiBandId } = values;
				if (!teamType) {
					setErrorText(translator('fillRequiredFields'));
					setErrorAlertOn(true);
					setSubmitting(false);
					return;
				}
				if (!props.initialValues) {
					res = await addTeamNetworkWiFiBandConfigurations(teamId, teamType, { wiFiBandId: +wiFiBandId });
				} else {
					res = await updateTeamNetworkWiFiBandConfigurations(
						props.initialValues.id,
						props.initialValues.teamId,
						props.initialValues.teamType,
						{
							wiFiBandId: +wiFiBandId,
						}
					);
				}
				if (res.hasSucceeded) {
					setSuccessfulAlertOn(true);
				}
			}
			props.onSucceeded();
			setErrorText('');
		} catch (e) {
			setErrorText('');
			setErrorAlertOn(true);
		}
		setSubmitting(false);
	};

	const onCloseModal = resetForm => {
		setSuccessfulAlertOn(false);
		setErrorText('');
		setErrorAlertOn(false);
		resetForm();
		props.toggleModal();
	};

	const getValidationSchema = () => {
		const validation = {};
		if (!props.initialValues) {
			validation.healthSystem = Yup.string().required(translator('healthSystemRequired'));
		}
		if (props.activeTab === 0) {
			validation.minBandwidth = Yup.number()
				.required(translator('minBandwidthRequired'))
				.max(5000)
				.min(500, translator('minBandwidthLessThan'));
			validation.maxBandwidth = Yup.number()
				.required(translator('maxBandwidthRequired'))
				.max(5000, translator('maxBandwithMoreThan'));
		} else {
			validation.wiFiBandId = Yup.string().required(translator('wiFiBandRequired'));
		}

		return validation;
	};

	const unitSelect = ({ field: { name, ...field }, form: { setFieldValue }, ...properties }) => {
		const handleChange = e => {
			setFieldValue(name, +e.target.value);
			if (properties.onChange) {
				properties.onChange(e);
			}
		};
		return (
			<div className='input'>
				<p className='label'>{translate('chooseUnit')}</p>

				<select required name={properties.name} onChange={handleChange} value={field.value} style={{ marginTop: '-2px' }}>
					{properties.items.map(item => (
						<option key={item.id} value={item.id}>
							{item.value}
						</option>
					))}{' '}
				</select>
			</div>
		);
	};

	const getInitialValues = () => {
		if (props.initialValues) {
			return { ...props.initialValues, maxBandwidthUnit: speedOption.Kbps, minBandwidthUnit: speedOption.Kbps };
		}

		return {
			healthSystem: null,
			hospital: '',
			floor: '',
			minBandwidth: '',
			maxBandwidth: '',
			wiFiBandId: '',
			name: '',
			maxBandwidthUnit: speedOption.Kbps,
			minBandwidthUnit: speedOption.Kbps,
		};
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={getInitialValues()}
			validationSchema={Yup.object().shape(getValidationSchema())}
			onSubmit={handleSubmitMyForm}>
			{({ handleSubmit, isSubmitting, resetForm }) => (
				<Modal
					modalSelector='netowrkFormModal'
					display={props.isModalOpen}
					position='right'
					onModalSubmit={handleSubmit}
					onModalClose={() => onCloseModal(resetForm)}
					isLoading={isSubmitting}
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'>
					<Form className='manage-hs-form'>
						<h3>{translator(props.activeTab === 0 ? 'bandwidth' : 'wiFiBand')}</h3>
						{!props.initialValues ? (
							<>
								<Field
									name='healthSystem'
									type='select'
									label={translator('selectHealthSystem')}
									placeholder={translator('selectHealthSystem')}
									description={translator('applyConfig')}
									onChange={props.onHealthSystemChange}
									items={props.healthSystems}
									component={Select}
								/>
								{props.isHospitalLoading ? (
									<div style={{ textAlign: 'center' }}>
										<Loader />
									</div>
								) : (
									<Field
										name='hospital'
										type='select'
										label={translator('selectHospital')}
										placeholder={translator('selectHospital')}
										description={translator('applyConfig')}
										onChange={props.onHospitalChange}
										items={props.hospitals}
										component={Select}
									/>
								)}
								{props.isFloorLoading && (
									<div style={{ textAlign: 'center' }}>
										<Loader />
									</div>
								)}
								{!props.isFloorLoading &&
									![TreeHierarchyType.HOSPITAL_DEPT_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(
										props.treeHierarchyTypeId
									) && (
										<Field
											name='floor'
											type='select'
											label={translator('selectFloor')}
											placeholder={translator('selectFloor')}
											description={translator('applyConfig')}
											items={props.floors}
											component={Select}
										/>
									)}
							</>
						) : (
							<Field name='name' label={translator('name')} description='' disabled={true} component={Input} />
						)}

						{props.activeTab === 0 ? (
							<>
								<div style={{ display: 'flex' }}>
									<Field
										name='minBandwidth'
										label={translator('setMinBandwidth')}
										placeholder=''
										description=''
										component={Input}
										style={{ flex: 1 }}
									/>
									<Field
										name='minBandwidthUnit'
										type='select'
										label=''
										placeholder=''
										description=''
										items={[{ id: speedOption.Kbps, value: 'Kbps' }]}
										component={unitSelect}
									/>
								</div>
								<div style={{ display: 'flex' }}>
									<Field
										name='maxBandwidth'
										label={translator('setMaxBandwidth')}
										placeholder=''
										description=''
										component={Input}
										style={{ flex: 1 }}
									/>
									<Field
										name='maxBandwidthUnit'
										type='select'
										label=''
										placeholder=''
										description=''
										items={[{ id: speedOption.Kbps, value: 'Kbps' }]}
										component={unitSelect}
									/>
								</div>
							</>
						) : (
							<Field
								name='wiFiBandId'
								type='select'
								label={translator('chooseWiFiBand')}
								placeholder={translator('chooseWiFiBand')}
								description=''
								items={props.wiFiBands}
								component={Select}
							/>
						)}

						<Alert
							alertSelector='networkConfigsMessage'
							display={isSuccessfulAlertOn || isErrorsAlertOn}
							message={translator(isSuccessfulAlertOn ? 'configurationSuccessfullyAdded' : errorText || 'somethingWentWrong')}
							variant={isSuccessfulAlertOn ? 'success' : 'error'}
							onClose={() => {
								setErrorAlertOn(false);
								setErrorText('');
								setSuccessfulAlertOn(false);
							}}
						/>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

export default NetworkConfigForm;
