export const legendColors = [
	['fleet-chart-green-4', 'fleet-chart-gray-2'],
	['fleet-chart-purple-4', 'fleet-chart-purple-5', 'fleet-chart-purple-6', 'fleet-chart-purple-7'],
	['fleet-chart-pink-2', 'fleet-chart-pink-3', 'fleet-chart-pink-4', 'fleet-chart-pink-5'],
	['fleet-chart-blue-light-9', 'fleet-chart-gray-2'],
];
export const onlineLegendColor = legendColors[0][0];
export const offlineLegendColor = legendColors[0][1];
export const assignedLegendColor = legendColors[3][0];
export const unassignedLegendColor = legendColors[3][1];

export const chartColors = [
	['rgba(107, 198, 181, 1)', 'rgba(237, 237, 237, 1)'],
	['rgba(131, 131, 244, 1)', 'rgba(160, 160, 255, 1)', 'rgba(176, 176, 255, 1)', 'rgba(192, 192, 255, 1)'],
	['rgba(210, 141, 185, 1)', 'rgba(224, 161, 200, 1)', 'rgba(234, 174, 211, 1)', 'rgba(242, 186, 223, 1)'],
	['rgba(116, 172, 203, 1)', 'rgba(237, 237, 237, 1)'],
];

export const onlineChartColor = chartColors[0][0];
export const offlineChartColor = chartColors[0][1];
export const assignedChartColor = chartColors[3][0];
export const unassignedChartColor = chartColors[3][1];

export const chartSummaryLegend = [
	'fleet-chart-blue-light-5',
	'fleet-chart-green-4',
	'fleet-chart-blue-light-8',
	'fleet-chart-cyan-1',
	'fleet-chart-gray-2',
];

export const chartSummaryColors = [
	'rgba(83, 144, 255, 1)',
	'rgba(107, 198, 181, 1)',
	'rgba(190, 213, 255, 1)',
	'rgba(98, 211, 253, 1)',
	'rgba(237, 237, 237, 1)',
];

export const getDoughnutChartOptions = (title, elements) => ({
	cutoutPercentage: 80,
	responsive: false,
	maintainAspectRatio: false,
	animation: false,
	legend: false,
	...(!!title && {
		title: {
			text: title,
			display: true,
			font: {
				size: 16,
			},
		},
	}),
	...(!!elements && {
		elements: {
			center: {
				enable: true,
				labelText: elements.labelText,
				text: elements.text,
				sidePadding: 20,
			},
		},
	}),
});
