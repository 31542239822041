import {
	addToHealthSystem,
	assignMemberHealthSystemLevel,
	deleteHealthSystemMember,
	getHealthSystemSubTreeBasic,
	getMemberHealthSystemLevels,
	getMemberHealthSystems,
	unassignMemberHealthSystemLevel,
} from 'api/users.js';
import classNames from 'classnames';
import { Alert, Button, EmptyState, Loader, Modal, SkeletonLoader } from 'components/index.js';
import { CompanyRoles, TreeHierarchyType, UserRoles } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { TrashIcon } from 'icons/Misc/index.js';
import { ArrowFlat, Checked, HospitalIcon, PartiallyChecked, Unchecked } from 'icons/TreeView/index.js';
import { getUserRole } from 'infrastructure/auth.js';
import { Fragment, useEffect, useRef, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';

const SectorsName = { HOSPITALS: 'hospitals', DEPARTMENTS: 'departments', FLOORS: 'floors' };

const ManageHealthSystems = props => {
	const intl = useIntl();
	const sectionRef = useRef(null);
	const userSession = useSelector(state => state.user.userSession);
	const [healthSystems, setHealthSystems] = useState([]);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [currentHealthSystemId, setCurrentHealthSystemId] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [healthSystemError, setHealthSystemError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [addNewHsLoading, setAddNewHsLoading] = useState(null);
	const [isDeleteLoading, setIsDeleteLoading] = useState(null);

	useEffect(() => {
		const fetchMemberHeathSystems = async () => {
			const response = await getMemberHealthSystems(props.member.memberId);
			if (!response.error) {
				const newHealthSystems = response.healthSystems.map(healthSystem => ({
					...healthSystem,
					tree: [],
					treeExpanded: false,
					checked: [],
					expanded: [],
				}));
				setHealthSystems(newHealthSystems);
			} else {
				setErrorMessage(response.error.message);
			}
			setIsLoading(false);
		};

		fetchMemberHeathSystems();
	}, [props.member.memberId]);

	const shouldHideButtons = hs => getUserRole() === UserRoles.SUPER_USER && userSession.healthSystem.id !== hs.id;

	const openDeleteModal = (healthSystemId, index) => {
		if (!healthSystemId) {
			setHealthSystems(prevState => prevState.filter((_, i) => i !== index));
			return;
		}
		if (props.member.roles.some(role => role.id === CompanyRoles.SUPER_USER) && healthSystems.length > 0) {
			setHealthSystemError(intl.formatMessage({ id: 'superUserHealthSystemValidation' }));
			return;
		}
		setIsDeleteModalOpen(true);
		setCurrentHealthSystemId(healthSystemId);
	};

	const deleteHealthSystem = async () => {
		setIsDeleteLoading(true);
		const response = await deleteHealthSystemMember(currentHealthSystemId, props.member.memberId);
		if (response.error) {
			setErrorMessage(response.error.message);
		} else {
			const memberHealthSystems = healthSystems.filter(hs => hs.id !== currentHealthSystemId);
			setHealthSystems(memberHealthSystems);
			props.updateMemberHealthSystems(memberHealthSystems.filter(hs => !!hs.id).map(item => ({ id: item.id, name: item.name })));
			setHealthSystemError(null);
		}
		setIsDeleteModalOpen(false);
		setIsDeleteLoading(false);
	};

	const getSectorIcon = childName => {
		const icons = {
			hospitals: <i className='material-icons-outlined'>south_america</i>,
			departments: <HospitalIcon />,
			floors: <img src={`${healthCareCdnUrl}/treeview/Department.svg`} alt='departments-img' />,
		};
		return icons[childName] || <img src={`${healthCareCdnUrl}/treeview/Floors.svg`} alt='floors-img' />;
	};

	const getChildNameFor = sectorName => {
		const childNames = {
			hospitals: SectorsName.DEPARTMENTS,
			departments: SectorsName.FLOORS,
		};
		return childNames[sectorName] || null;
	};

	const getNode = ({ item, childrenName, treeHierarchyTypeId, shouldDig }) => ({
		value: item.id,
		label: item.name,
		icon: getSectorIcon(childrenName),
		showCheckbox: childrenName !== SectorsName.HOSPITALS,
		...(shouldDig && {
			children: processSectors(item[childrenName], treeHierarchyTypeId, getChildNameFor(childrenName)),
		}),
	});

	const processSectors = (sectors, treeHierarchyTypeId, childrenName = SectorsName.HOSPITALS) => {
		const treeData = sectors.map(item => {
			if (TreeHierarchyType.HOSPITAL_DEPT_ROOM === treeHierarchyTypeId) {
				return getNode({
					item,
					childrenName,
					treeHierarchyTypeId,
					shouldDig: getChildNameFor(childrenName) || childrenName !== SectorsName.FLOORS,
				});
			} else if (TreeHierarchyType.HOSPITAL_FLOOR_ROOM === treeHierarchyTypeId) {
				return {
					...getNode({ item, childrenName, treeHierarchyTypeId, shouldDig: false }),
					...((getChildNameFor(childrenName) || childrenName === SectorsName.FLOORS) && {
						children: processSectors(
							childrenName === SectorsName.DEPARTMENTS ? item[childrenName][0].floors : item[childrenName],
							treeHierarchyTypeId,
							childrenName === SectorsName.DEPARTMENTS ? getChildNameFor(SectorsName.FLOORS) : getChildNameFor(childrenName)
						),
					}),
				};
			} else if (TreeHierarchyType.HOSPITAL_ROOM === treeHierarchyTypeId) {
				return getNode({ item, childrenName, treeHierarchyTypeId, shouldDig: childrenName === SectorsName.HOSPITALS });
			} else {
				return getNode({
					item,
					childrenName,
					treeHierarchyTypeId,
					shouldDig: getChildNameFor(childrenName) || childrenName === SectorsName.FLOORS,
				});
			}
		});

		return treeData;
	};

	const transformArray = (allHealthSystems, assignedHealthSystems) =>
		allHealthSystems.reduce((acc, item) => {
			if (!assignedHealthSystems.some(hs => hs.id === item.id)) {
				acc.push({ ...item, value: item.id, label: item.value });
			}
			return acc;
		}, []);

	const handleSelectHealthSystem = async (selected, index) => {
		setAddNewHsLoading(index);
		const response = await addToHealthSystem(selected.value, props.member.memberId);
		if (!response.error) {
			const healthSystemName = props.healthSystems.find(hs => hs.id === selected.value).value;
			const newHealthSystems = healthSystems.map((item, i) =>
				i === index
					? {
							...item,
							id: selected.value,
							name: healthSystemName,
							tree: [],
							treeExpanded: false,
							checked: [],
							expanded: [],
						}
					: item
			);
			setHealthSystems(newHealthSystems);
			const memberHealthSystems = newHealthSystems.filter(item => !!item.id).map(item => ({ id: item.id, name: item.name }));
			props.updateMemberHealthSystems(memberHealthSystems);
		} else {
			setErrorMessage(response.error.message);
		}
		setAddNewHsLoading(null);
	};

	const toggleExpandTree = async currentHs => {
		updateHealthSystem(currentHs.id, {
			treeExpanded: !currentHs.treeExpanded,
			isTreeLoading: true,
		});

		if (!currentHs.treeExpanded && !currentHs.tree.length) {
			const treeResponse = await getHealthSystemSubTreeBasic(currentHs.id);
			const memberLevels = await getMemberHealthSystemLevels(currentHs.id, props.member.memberId);

			if (treeResponse.error || memberLevels.error) {
				setErrorMessage(intl.formatMessage({ id: 'somethingWrong' }));
				updateHealthSystem(currentHs.id, {
					treeExpanded: false,
					isTreeLoading: false,
				});
				return;
			}
			const tree = processSectors(treeResponse.regions, treeResponse.treeHierarchyTypeId);
			const checked = memberLevels.levels.map(level => level.id);
			updateHealthSystem(currentHs.id, { treeExpanded: true, tree, checked });
		}
		updateHealthSystem(currentHs.id, {
			isTreeLoading: false,
		});
	};

	const handleCheckedSectors = async (healthSystemId, checked, target) => {
		updateHealthSystem(healthSystemId, { checked });
		const response = target.checked
			? await assignMemberHealthSystemLevel(healthSystemId, target.value, props.member.userInfo.id)
			: await unassignMemberHealthSystemLevel(healthSystemId, target.value, props.member.userInfo.id);
		if (response.error) {
			setErrorMessage(intl.formatMessage({ id: 'somethingWrong' }));
			updateHealthSystem(healthSystemId, { checked: checked.filter(id => id !== target.value) });
		}
	};

	const updateHealthSystem = (healthSystemId, updatedValues) =>
		setHealthSystems(prevState => prevState.map(item => (item.id === healthSystemId ? { ...item, ...updatedValues } : item)));

	const handleNewHealthSystem = () => {
		if (props.member.roles.some(role => role.id === CompanyRoles.SUPER_USER) && healthSystems.length > 0) {
			setHealthSystemError(intl.formatMessage({ id: 'superUserRoleValidation' }));
			return;
		}
		setHealthSystems(prevState => [
			{
				id: '',
				name: '',
				checked: [],
				expanded: [],
				tree: [],
				treeExpanded: false,
			},
			...prevState,
		]);
		sectionRef.current.scrollTop = 0;
	};

	return (
		<>
			<Modal
				modalSelector={props.modalSelector}
				display={props.display}
				className={props.className}
				position={props.position}
				onModalClose={props.onModalClose}
				thirdButtonLabel={translate('addNewHealthSystem')}
				isThirdButtonShown
				primaryButtonLabel=''
				thirdButtonClassNames={classNames('add-hs-btn', { disabled: healthSystems.some(item => !item.id) })}
				thirdBtnIcon='add'
				onThirdButtonClick={handleNewHealthSystem}>
				<div className='manage-healthsystems_wrapper'>
					<h3>{translate('manageHealthSystems')}</h3>
					{healthSystems.length > 0 && (
						<div className='manage-healthsystems_header'>
							<span>{translate('healthSystem')}</span>
							<span>{translate('actions')}</span>
						</div>
					)}
					<div className='manage-healthsystems_content' ref={sectionRef}>
						{!isLoading &&
							healthSystems.map((healthSystem, index) => (
								<Fragment key={healthSystem.id || index}>
									<div
										className={classNames('manage-healthsystems_row', {
											expanded: healthSystem.treeExpanded,
											'is-new-hs': !healthSystem.id,
										})}>
										<Select
											value={healthSystem.id ? { ...healthSystem, value: healthSystem.id, label: healthSystem.name } : null}
											placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
											options={transformArray(props.healthSystems, healthSystems)}
											onChange={value => handleSelectHealthSystem(value, index)}
											isSearchable={!healthSystem.id}
											className={classNames('hs-select', { 'options-disabled': healthSystem.id })}
										/>

										{!shouldHideButtons(healthSystem) && (
											<div className={classNames('manage-healthsystems_actions', { disabled: addNewHsLoading === index })}>
												<Button svgIcon={<TrashIcon />} onClick={() => openDeleteModal(healthSystem.id, index)} />
												<Button
													svgIcon={<ArrowFlat />}
													isLoading={addNewHsLoading === index}
													onClick={() => toggleExpandTree(healthSystem)}
													disabled={!healthSystem.id}
												/>
											</div>
										)}
									</div>
									{healthSystem.treeExpanded && (
										<div className='manage-healthsystems_assignation-tree'>
											{!healthSystem.isTreeLoading && (
												<>
													{healthSystem.checked.length > 0 && <h5>{translate('selectAssignationLevel')}</h5>}
													<CheckboxTree
														noCascade={true}
														nodes={healthSystem.tree}
														checked={healthSystem.checked}
														expanded={healthSystem.expanded}
														onCheck={(checked, target) => handleCheckedSectors(healthSystem.id, checked, target)}
														onExpand={expanded => updateHealthSystem(healthSystem.id, { expanded })}
														icons={{
															check: <Checked width={20} height={20} />,
															uncheck: <Unchecked width={20} height={20} />,
															halfCheck: <PartiallyChecked width={20} height={20} />,
														}}
													/>
												</>
											)}
											{healthSystem.isTreeLoading && <SkeletonLoader rows={5} />}
										</div>
									)}
								</Fragment>
							))}
						{isLoading && <Loader />}
						{!isLoading && healthSystems.length === 0 && <EmptyState title={translate('memberNoHs')} image='no-files.svg' />}
						{healthSystemError && <p className='red-error'>{healthSystemError}</p>}
					</div>
					<Alert display={!!errorMessage} fixed hideCloseButton message={errorMessage} variant='dark' />
				</div>
			</Modal>
			<Modal
				modalSelector='deleteHSFromMember'
				display={isDeleteModalOpen}
				position='center'
				onModalSubmit={deleteHealthSystem}
				primaryButtonLabel={translate('delete')}
				onModalClose={() => setIsDeleteModalOpen(false)}
				primaryButtonLoading={isDeleteLoading}>
				<form>
					<h3>{translate('warning')}</h3>
					<p>{translate('deleteHealthSystemMember')}</p>
				</form>
			</Modal>
		</>
	);
};
export default ManageHealthSystems;
