import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const PatientOutOfRoom = props => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			color={LightTheme.colors.grayZero}
			{...props}>
			<path
				d='M13.2029 4.92851C13.7421 4.38938 13.7421 3.51528 13.2029 2.97615C12.6638 2.43702 11.7897 2.43702 11.2506 2.97615C10.7114 3.51528 10.7114 4.38938 11.2506 4.92851C11.7897 5.46764 12.6638 5.46764 13.2029 4.92851Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M13.3893 9.72889C13.5196 10.0231 13.8649 10.4704 14.0164 10.5998C14.3751 10.9061 15.1479 11.4028 15.6203 11.6501C15.9642 11.8301 16.374 11.7812 16.595 11.4619C16.7936 11.1742 16.7459 10.7576 16.483 10.5273L14.7949 9.29172C14.6075 8.94814 14.5097 8.58892 14.348 8.23267C14.103 7.69347 13.8811 6.88194 13.1343 6.65628C12.8029 6.55698 12.6659 6.51974 12.3201 6.51229C11.9874 6.50534 11.651 6.51477 11.3286 6.5962C10.4666 6.81416 9.87604 7.62371 9.37581 8.28158C9.20402 8.55342 8.9652 8.79199 8.86764 9.10329L8 12.4562V12.4592C8.00116 12.6222 8.06271 12.779 8.17275 12.8992C8.2828 13.0195 8.43353 13.0947 8.5958 13.1103C8.94162 13.1386 9.29414 12.8782 9.31573 12.5319L10.1605 9.71349L11.3253 8.33694'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10.7027 13.983C10.7322 15.0457 10.6496 15.4045 10.263 16.7291L9.27697 20.2173C9.12554 20.6753 9.26084 21.2262 9.75908 21.362C10.3209 21.5291 10.6071 21.4365 10.8196 20.7992L12.0293 17.64L12.3881 16.4255'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10.6744 9.16288L10.4639 11.4681L10.4435 12.4438C10.4259 13.2905 10.6896 14.1191 11.1932 14.7999L12.9883 17.2271L14.0149 21.2053C14.1127 21.6993 14.4049 22.0494 14.9399 21.9943C15.4748 21.9392 15.8087 21.5608 15.6638 20.996L14.7782 16.3537C14.777 16.35 14.7755 16.3465 14.774 16.3431C14.7628 16.3072 14.7491 16.2721 14.7331 16.2381L13.1723 12.7481L13.4047 8.32379'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default PatientOutOfRoom;
