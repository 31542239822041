import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Alert.jsx';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { hasNonEmptyValues } from 'infrastructure/helpers/commonHelpers.js';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isBold: number, $isInConference: boolean, $isDarkMode: boolean }, never>}
 */
const StyledPatientInfo = styled.div`
	span {
		font-weight: ${props => (props.$isBold ? 700 : 500)};
	}
	${props => css`
		text-align: left;
		padding: var(--spacing-m);
		z-index: 2;
		background: ${props.$isDarkMode ? 'var(--dark-theme-gray-4-v2)' : 'var(--gray-0)'};
		border-radius: 0;
		margin-left: auto;
		margin-right: auto;
		display: block;
		margin-bottom: var(--spacing-s);
		border: 1px solid var(--gray-8-o05);
		color: ${props.$isDarkMode ? 'var(--dark-theme-gray-1)' : 'var(--gray-5)'};
		span {
			color: ${props.$isDarkMode ? 'var(--dark-theme-gray-1)' : 'var(--gray-5)'};
		}
	`}
`;

/**
 * @param {Object} metaData
 */
const CardInfo = ({ metaData = {}, callEnded = false, mrnDifferenceMessage = '', isBold = true }) => {
	const [showMessage, setShowMessage] = useState(!!mrnDifferenceMessage);
	const isDarkMode = useSelector(state => state.user.darkMode);

	useEffect(() => {
		return () => {
			setShowMessage(null);
		};
	}, []);

	const renderProperties = () => {
		// patientName, location, MRN, dateOfBirth, bedIdentifier
		let result = [];
		Object.entries(metaData).forEach(([key, item]) => {
			if (key && item) {
				result.push(
					<li key={key}>
						<span className={key === 'patientName' ? 'capitalized-text' : ''}>
							{translate(key === 'patientName' ? 'patient' : key)}:
						</span>{' '}
						{item}
					</li>
				);
			}
		});
		return result;
	};

	return (
		<>
			{hasNonEmptyValues(metaData) && (
				<StyledPatientInfo $isDarkMode={isDarkMode} className='metadata-card' $isBold={isBold}>
					<ul>{renderProperties()}</ul>
				</StyledPatientInfo>
			)}
			<Alert
				display={showMessage && !callEnded}
				fixed={true}
				persist={true}
				hideCloseButton={false}
				message={mrnDifferenceMessage}
				onClose={() => setShowMessage(false)}
				variant='dark'
			/>
		</>
	);
};

export default CardInfo;
