import * as React from 'react';

const Admission = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M7.5 19.773v-2.295a4.5 4.5 0 0 1 6.81-3.863M9.545 17.727v2.864M14.455 8.727a2.455 2.455 0 1 1-4.91 0 2.455 2.455 0 0 1 4.91 0Z'
		/>
		<path stroke='currentColor' d='M20.952 12.935a9 9 0 1 0-6.452 7.713' />
		<circle cx={18} cy={17} r={5} stroke='currentColor' />
		<path stroke='currentColor' d='M18 15v4M16 17h4' />
	</svg>
);
export default Admission;
