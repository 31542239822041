import classNames from 'classnames';
import FormInput from 'components/FormInput.jsx';
import SelectTimeZone from 'components/SelectTimeZone.jsx';
import TimePicker from 'components/TimePicker.jsx';
import { DayPeriodTypes } from 'constants/enums.js';
import { DataTypeOptions, ScheduleUnit, ScheduleValueOptions } from 'constants/integrationEnums.js';
import translate from 'i18n-translations/translate.jsx';
import { doNotAllowSpaceAsFirstCharacter } from 'infrastructure/helpers/commonHelpers.js';
import { findByCityAndCountry, getFormatedTimeZone } from 'infrastructure/helpers/timezone/timeZonesHelper.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';

const ScheduleUnits = [{ id: ScheduleUnit.DAILY, title: 'daily' }];

const defaultHealthSystemOption = { value: '0', label: 'all' };
const DefaultDataType = DataTypeOptions[0];
const DefaultScheduleValue = ScheduleValueOptions[ScheduleUnit.DAILY][0];
const DefaultScheduleTime = new Date();
DefaultScheduleTime.setHours(9, 0, 0);

const CloudStorage = ({ itemToEdit, setConfigParams, configurationTypeId, fieldErrors }) => {
	const intl = useIntl();
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [connectionString, setConnectionString] = useState('');
	const [containerName, setContainerName] = useState('');
	const [selectedDataType, setSelectedDataType] = useState({
		...DefaultDataType,
		label: intl.formatMessage({ id: DefaultDataType.label }),
	});
	const [selectedScheduleUnit, setSelectedScheduleUnit] = useState(ScheduleUnits[0].id);
	const [selectedScheduleValue, setSelectedScheduleValue] = useState({
		...DefaultScheduleValue,
		label: intl.formatMessage({ id: DefaultScheduleValue.label }),
	});
	const [scheduleTime, setScheduleTime] = useState(DefaultScheduleTime);

	useEffect(() => {
		setConfigParams({
			configurationTypeId,
			configJson: JSON.stringify({
				healthSystem: defaultHealthSystemOption,
				connectionString,
				containerName,
				dataType: selectedDataType.value,
				scheduleUnit: selectedScheduleUnit,
				scheduleValue: selectedScheduleValue.value,
				scheduleTime: scheduleTime.toTimeString().substring(0, 5),
				scheduleLocation: selectedLocation
					? {
							city: selectedLocation.city,
							province: selectedLocation.province,
							timezone: selectedLocation.timezone,
					  }
					: null,
			}),
		});
	}, [
		configurationTypeId,
		connectionString,
		containerName,
		scheduleTime,
		selectedDataType,
		selectedLocation,
		selectedScheduleUnit,
		selectedScheduleValue.value,
		setConfigParams,
	]);

	useEffect(() => {
		if (!itemToEdit) {
			return;
		}
		setConnectionString(itemToEdit.connectionString);
		setContainerName(itemToEdit.containerName);
		setSelectedScheduleUnit(itemToEdit.scheduleUnit);
		const foundScheduleValue = ScheduleValueOptions[itemToEdit.scheduleUnit].find(
			option => option.value === itemToEdit.scheduleValue
		);
		setSelectedScheduleValue({ ...foundScheduleValue, label: intl.formatMessage({ id: foundScheduleValue.label }) });
		const [hours, minutes] = itemToEdit.scheduleTime.split(':');
		const newScheduleTime = new Date();
		newScheduleTime.setHours(+hours, +minutes);
		setScheduleTime(newScheduleTime);
		const location = findByCityAndCountry(itemToEdit.scheduleLocation.city, itemToEdit.scheduleLocation.province);
		setSelectedLocation({ ...location, formattedTimeZone: getFormatedTimeZone(location.timezone).formattedTimeZone });
	}, [intl, itemToEdit]);

	const getDefaultScheduleValue = scheduleUnit => {
		const defaultOption = ScheduleValueOptions[scheduleUnit][0];
		return { ...defaultOption, label: intl.formatMessage({ id: defaultOption.label }) };
	};

	const handleScheduleUnitChange = scheduleUnit => {
		setSelectedScheduleValue(getDefaultScheduleValue(scheduleUnit));
		setSelectedScheduleUnit(scheduleUnit);
	};

	const handleScheduleTimeChange = time => {
		const { hours, minutes, dayPeriod } = time;
		const newTime = new Date(scheduleTime);
		newTime.setHours(DayPeriodTypes.PM === dayPeriod ? hours + 12 : hours, minutes);
		setScheduleTime(newTime);
	};

	const getTranslatedOptions = options => options.map(option => ({ ...option, label: intl.formatMessage({ id: option.label }) }));

	return (
		<>
			<div className='input'>
				<p className='label'>{translate('blobStorageConnectionString')}</p>
				<p className='font-14'>{translate('blobStorageDescription')}</p>
				<div className='input'>
					<FormInput
						id='blobStorage'
						placeholder={intl.formatMessage({ id: 'blobStorageConnectionString' })}
						type='text'
						value={connectionString}
						onChange={event => setConnectionString(event.target.value)}
						maxLength={256}
						onKeyDown={doNotAllowSpaceAsFirstCharacter}
						error={fieldErrors?.connectionString}
						touched={fieldErrors?.connectionString}
					/>
				</div>
			</div>
			<div className='input'>
				<FormInput
					text={intl.formatMessage({ id: 'containerName' })}
					id='containerName'
					placeholder={intl.formatMessage({ id: 'containerName' })}
					type='text'
					value={containerName}
					onChange={event => setContainerName(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.containerName}
					touched={fieldErrors?.containerName}
				/>
			</div>
			<div className='input'>
				<p className='label'>{translate('dataType')}</p>
				<Select
					value={selectedDataType}
					placeholder={intl.formatMessage({ id: 'selectDataType' })}
					classNamePrefix='react-select'
					options={getTranslatedOptions(DataTypeOptions)}
					onChange={setSelectedDataType}
				/>
			</div>
			<div
				className={classNames('input cloud-storage-timezone', {
					'option-selected': selectedLocation,
				})}>
				<p className='label'>{translate('setLocation')}</p>
				<SelectTimeZone selectedPlace={selectedLocation} setSelectedPlace={setSelectedLocation} />
				{fieldErrors?.scheduleLocation && <span className='red-error'>{fieldErrors.scheduleLocation}</span>}
			</div>
			<div>
				<p className='label'>{translate('uploadScheduler')}</p>
				{ScheduleUnits.map(scheduleUnit => (
					<span
						key={scheduleUnit.id}
						className='inline-flex flex-align-center right-15'
						onClick={() => handleScheduleUnitChange(scheduleUnit.id)}>
						<input
							type='radio'
							id={`scheduleUnit${scheduleUnit.id}`}
							name='scheduleUnit'
							className='unset-width margin-right-m'
							value={scheduleUnit.id}
							checked={selectedScheduleUnit === scheduleUnit.id}
						/>
						<label htmlFor={`scheduleUnit${scheduleUnit.id}`}>{intl.formatMessage({ id: scheduleUnit.title })}</label>
					</span>
				))}
				<div className='cloud-storage-scheduler'>
					<div>
						<label htmlFor='scheduleValue'>{translate('send')}</label>
						<Select
							value={selectedScheduleValue}
							placeholder={intl.formatMessage({ id: 'selectDataType' })}
							classNamePrefix='react-select'
							className={classNames({ 'open-upside': selectedScheduleUnit === ScheduleUnit.WEEKLY })}
							options={getTranslatedOptions(ScheduleValueOptions[selectedScheduleUnit])}
							onChange={setSelectedScheduleValue}
						/>
					</div>
					<div>
						<label>{translate('atDate')}</label>
						<TimePicker value={scheduleTime} handleTimeChange={handleScheduleTimeChange} className='date-range-time-pickers' />
					</div>
				</div>
			</div>
		</>
	);
};

export default CloudStorage;
