import moment from 'moment';
import { Gender } from 'constants/enums.js';
import { getFirstName, getLastName } from 'infrastructure/helpers/commonHelpers.js';
import { TestTypes } from 'constants/visitEnums.js';

const ReportFromDiagnosticsReport = ({ selectedVisit, isVisitsPage, patient, reportRef }) => {
	let note = '';

	if (selectedVisit.notes?.length) {
		note = selectedVisit.notes[0].text;
	}
	let genderId = null;
	if (isVisitsPage) {
		genderId = patient?.genderId;
	} else {
		genderId = selectedVisit?.patient.genderId;
	}

	const radiologyTests = [
		{ id: TestTypes.PULMONARY, description: 'RTG Pulmonare' },
		{ id: TestTypes.SKELETON, description: 'RTG - Skeleti' },
		{ id: TestTypes.CHEST, description: 'RTG - Krahrori' },
		{ id: TestTypes.UPPER_EXTREMITIES, description: 'RTG - Ekstremitetet e sipërme' },
		{ id: TestTypes.LOWER_EXTREMITIES, description: 'RTG - Ekstremitetet e poshtme' },
		{ id: TestTypes.SPINE, description: 'RTG - Shtylla kurrizore' },
		{ id: TestTypes.ULTRASONOGRAPHY, description: 'Echo - Ultrasonografi' },
	];

	const performerEmail = selectedVisit?.diagnosticResearches[0].medicalVisitPerformer.email;
	const performerName = `${selectedVisit?.diagnosticResearches[0].medicalVisitPerformer.firstName} ${selectedVisit?.diagnosticResearches[0].medicalVisitPerformer.lastName}`;
	return (
		<div
			className='primary-care-form position-relative'
			ref={reportRef}
			style={{ position: 'fixed', width: '8.5in', minHeight: '11in', zIndex: '-1' }}>
			<div>
				<span className='text-transform-uppercase text-align-center'>
					Qeveria e Kosovës-Ministria e Shendetësisë / Vlada Kosova - Mistarstvo Zdravstva
				</span>
				<div className='flex flex-align-center flex-space-between'>
					<h1 className='text-transform-uppercase'>
						RAPORTI KTHYES NGA DIAGNOSTIKA <br />
						POVRATNI IZVESTAJ SA DIJAGNOSTIKE
					</h1>
					<span className='text-transform-uppercase'>DM 0104</span>
				</div>
				<div className='flex full-width primary-care-form-table'>
					<div className='flex-1'>
						<div>
							<span>Institucioni Shëndetësor / Zdravstvena Institucija:</span>
							{/* <p>QKMF Lipjan</p> */}
						</div>
						<div>
							<span>Njësia organizative / Orgaziciona jedinica:</span>
						</div>
						<div>
							<span>Kodi / Sifra:</span>
							{/* <p>73200</p> */}
						</div>

						<div>
							<span>Vendi / Mesto:</span>
							{/* <p>Lipjan</p> */}
						</div>
						<div>
							<span>Nr. i Regjistrit / Br. Registra:</span>
						</div>
						<div>
							<span>Mjeku konsulent / Lekar konsultant:</span>
							<p>Dr. {performerName}</p>
						</div>
						<div>
							<span>Kodi i Konsutentit / Sifra Konsultanta:</span>
						</div>
						<div>
							<span>Tel.:</span>
						</div>
						<div>
							<span>E-mail:</span>
							<p>{performerEmail}</p>
						</div>
					</div>
					<div className='flex-1'>
						<div>
							<span>Nr. Personal / Licni Br.:</span>
							<p>{!isVisitsPage ? selectedVisit?.patient.idCard : patient?.idCard}</p>
						</div>
						<div>
							<span>Emri / Ime:</span>
							<p>{!isVisitsPage ? selectedVisit?.patient.firstName : getFirstName(patient?.fullName || '')}</p>
						</div>
						<div>
							<span>Emri i prindit / Ime roditelja:</span>
							<p>{!isVisitsPage ? selectedVisit?.patient.parentName : patient?.parentName}</p>
						</div>
						<div>
							<span>Mbiemri / Prezime:</span>
							<p>{!isVisitsPage ? selectedVisit?.patient.lastName : getLastName(patient?.fullName || '')}</p>
						</div>
						<div>
							<span>Viti i lindjes / Godina rodjenja:</span>
							<p>
								{!isVisitsPage
									? moment(selectedVisit?.patient.dateOfBirth).format('YYYY')
									: moment().subtract(patient?.age, 'years').format('YYYY')}
							</p>
						</div>
						<div>
							<span>Gjinia / Pol:</span>
							<p>{genderId === Gender.MALE ? 'Mashkull' : 'Femër'}</p>
						</div>
						<div>
							<span>Adresa / Adresa:</span>
							<p>{patient?.address}</p>
						</div>
					</div>
				</div>
				<div className='flex full-width primary-care-form-table'>
					<div className='flex-1 no-margin-left'>
						<div>
							<span>Raporti mbi / Izveštavaju o:</span>
						</div>
						<div className='no-border-top no-padding-top flex' style={{ minHeight: '100px', alignItems: 'flex-start' }}>
							{selectedVisit?.diagnosticResearches.map(item => (
								<p
									style={{
										marginRight: '5px',
										padding: '5px',
										borderRadius: '5px',
										background: 'var(--gray-1)',
										border: '1px solid var(--gray-2)',
									}}>
									{radiologyTests.find(el => el.id === item.testType).description}
								</p>
							))}
						</div>
					</div>
				</div>
				<div className='flex full-width primary-care-form-table'>
					<div className='flex-1 no-margin-left'>
						<div>
							<span>Komenti / Koment:</span>
						</div>
						<div className='no-border-top no-padding-top' style={{ minHeight: '250px', alignItems: 'flex-start' }}>
							<p>{note}</p>
						</div>
					</div>
				</div>
				<div className='flex signature-lab full-width'>
					<div>Data / Datum:</div>
					<div>Nënshkrimi dhe faksimili i mjekut</div>
				</div>
			</div>
		</div>
	);
};

export default ReportFromDiagnosticsReport;
