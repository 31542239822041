const RoomSignage = props => (
	<svg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg' color='#B99364' {...props}>
		<rect x='7.22241' y='3.50195' width='18.6667' height='26.6667' rx='2' stroke='currentColor' strokeWidth='2' />
		<path d='M10.5557 7.50195H14.5557V11.502H10.5557V7.50195Z' stroke='currentColor' strokeWidth='2' strokeLinejoin='round' />
		<path d='M10.5557 14.835H14.5557V18.835H10.5557V14.835Z' stroke='currentColor' strokeWidth='2' strokeLinejoin='round' />
		<path d='M10.5557 22.1689H14.5557V26.1689H10.5557V22.1689Z' stroke='currentColor' strokeWidth='2' strokeLinejoin='round' />
		<path d='M18.5557 7.50195H22.5557V11.502H18.5557V7.50195Z' stroke='currentColor' strokeWidth='2' strokeLinejoin='round' />
		<path d='M18.5557 14.835H22.5557V18.835H18.5557V14.835Z' stroke='currentColor' strokeWidth='2' strokeLinejoin='round' />
		<path d='M18.5557 22.1689H22.5557V26.1689H18.5557V22.1689Z' stroke='currentColor' strokeWidth='2' strokeLinejoin='round' />
	</svg>
);

export default RoomSignage;
