import { useIntl } from 'react-intl';
import { ParticipantState } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { fallRiskOptions } from 'constants/monitoring.js';

const FallRiskBadge = props => {
	const intl = useIntl();
	const showLastCondition = props.feed.lastCondition && props.feed.status === ParticipantState.CONNECTED.type;
	const foundCondition = fallRiskOptions(intl).find(item => item.label === props.feed.lastCondition);

	if (!props.feed.lastCondition || !foundCondition) {
		return <></>;
	}

	return (
		<>
			{showLastCondition && props.feed.lastCondition && (
				<div className='video-feed-condition fall-risk-badge flex'>
					<div className='flex' data-tooltip={foundCondition.label} data-position='right'>
						<img src={`${healthCareCdnUrl}monitoring/video-feed/${foundCondition?.img}`} alt='icon' />
						<p className={foundCondition?.className || '--red-color'}>{foundCondition.label}</p>
					</div>
				</div>
			)}
		</>
	);
};

export default FallRiskBadge;
