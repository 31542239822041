import { gatewayApi } from '../constants/global-variables.js';

/**
 * @returns {Promise<RTCIceServer[]>}
 */

export function getIceServers() {
	return new Promise((resolve, reject) => {
		gatewayApi
			.get(`v1/ice-server`)
			.then(response => {
				const { data } = response;
				if (data.hasSucceeded && data.result !== null) {
					resolve(prepareICEList(data.result.iceServers));
				}
			})
			.catch(e => reject(e));
	});
}

function prepareICEList(iceServers) {
	// example:
	// iceServers: [{
	//     urls: [
	//         'turn:global.turn.twilio.com:3478?transport=udp',
	//         'turn:global.turn.twilio.com:3478?transport=tcp',
	//         'turn:global.turn.twilio.com:443?transport=tcp'
	//     ],
	//     username: "3d7f3e4fafba9b1ab42bb9644ac9f33ce98fc99575bbcd5d56d643d491889488",
	//     credential: "ybTn5WxASjj7saN7aKRK8bOug/tiH8RITApVCIgwVL8=",
	// },
	// {
	//     urls: ["stun:global.stun.twilio.com:3478?transport=udp"]
	// }]
	return iceServers.map(server => {
		const modifiedServer = { ...server };
		modifiedServer.urls = server.urls.map(url => `${server.type}:${url}`);
		// const { id, isDeleted, type, ...returnServer } = modifiedServer;
		const { ...returnServer } = modifiedServer;

		if (returnServer.password) {
			returnServer.credential = returnServer.password;
			delete returnServer.password;
		}
		return returnServer;
	});
}
