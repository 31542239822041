import _ from 'lodash';
import { useIntl } from 'react-intl';
import Loader from 'components/Loader.jsx';
import { MeasurementTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { formattedDate, getDurationFormat, monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import Button from 'components/Button.jsx';
import classNames from 'classnames';

const AvailableHeartLungsRecordings = props => {
	const intl = useIntl();
	return (
		<div className='available-recordings'>
			<p>{translate('availableRecordings')}</p>
			<div className='patient-summary-table-wrapper stethoscope-details'>
				<table cellPadding='0' cellSpacing='0'>
					<thead>
						<tr>
							<th />
							<th />
							<th />
						</tr>
					</thead>
					<tbody>
						{props.recordings.length > 0 &&
							props.recordings.map(measurement => (
								<tr
									key={measurement.indexKey}
									className={props.isPlaying && measurement.indexKey === props.selectedRecording?.indexKey ? 'active' : ''}>
									<td>
										<div className='flex'>
											<img src={`${healthCareCdnUrl}stetho/front.svg?v2`} alt='ico' />
											<div className='flex column-direction left-10 flex-justify-center'>
												<p className='font-14 no-margin no-padding semi-bold'>
													{translate('point')}-
													{props.examinationPoints.find(item => item.value === measurement.examinationPoint)?.point}
												</p>
												{!props.isHeartMeasurement && (
													<p className='font-14 no-margin no-padding semi-bold --blue-color'>
														{translate(_.inRange(measurement.examinationPoint, 5, 14) ? 'front' : 'back')}
													</p>
												)}
											</div>
										</div>
									</td>
									<td>
										<div className='flex'>
											<img
												src={`${healthCareCdnUrl}measurements-icons/${
													measurement.device.type === MeasurementTypes.STETHOSCOPE
														? MeasurementTypes.STETHOSCOPE
														: 'vital-kit-active'
												}.svg`}
												alt='ico'
											/>
											<p className='--blue-color semi-bold'>{translate(measurement.device.type)}</p>
										</div>
									</td>
									<td>
										<p className='font-14 no-margin no-padding semi-bold'>{monthDayYear(measurement.recordedAt)}</p>
										<p className='font-12 no-margin no-padding gray-color'>
											{`${intl.formatMessage({ id: 'atDate' })} ${formattedDate(measurement.recordedAt)}`}
										</p>
									</td>
									<td>
										<p className='--blue-color semi-bold'>{getDurationFormat(measurement.recordDuration)}</p>
									</td>
									<td>
										<Button
											className={classNames('visit-btn stethoscope cursor-pointer', {
												active: props.isPlaying && measurement.indexKey === props.selectedRecording?.indexKey,
											})}
											onClick={() => props.playFromList(measurement)}
											imgIcon={`${healthCareCdnUrl}stetho/${
												props.isPlaying && measurement.indexKey === props.selectedRecording?.indexKey ? 'pause' : 'play'
											}.svg?v2`}
											alt='ico'
										/>
									</td>
								</tr>
							))}
						{!props.hasReachedEnd && props.isLoading && (
							<div className='full-width text-align-center'>
								<Loader />
							</div>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default AvailableHeartLungsRecordings;
