import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Dropdown from 'components/Dropdown.jsx';
import ListGroup from 'components/ListGroup.jsx';

const FeedColorsDropdown = props => {
	const intl = useIntl();

	const user = useSelector(state => state.user);

	return (
		<Dropdown
			position='bottom'
			icon='report_problem'
			isSymbol={true}
			closeDropdownOnItemClick={true}
			isPortal={true}
			toolTipPosition='bottom'
			toolTip={intl.formatMessage({ id: 'precautions' })}
			setIsPortalOpen={props.setIsPortalOpen}>
			<ListGroup
				className={classNames('feed-dropdown', user.darkMode ? 'darkmode-feed-dropdown' : '')}
				lists={props.precautions.map(x => ({
					key: x.id,
					icon: (
						<i className='material-icons' style={{ color: x.boxColor }}>
							fiber_manual_record
						</i>
					),
					title: (
						<>
							<span>{x.name}</span>
							{x.active && <i className='material-icons margin-left-auto'>done</i>}
						</>
					),
					color: x.textColor,
				}))}
				onItemClick={props.setFeedColor}
			/>
		</Dropdown>
	);
};

export default FeedColorsDropdown;
