const code = `
    let intervalId;
    onmessage = (e) => {
        if (e.data === 'start') {
          count = 1;
          intervalId = setInterval(() => {
            postMessage(count++);
          }, 1000);
        } else if (e.data === 'stop' && intervalId) {
          clearInterval(intervalId);
        }
    };
`;

export const recordingWorkerScript = URL.createObjectURL(new Blob([code], { type: 'application/javascript' }));
