const PharmacyIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} viewBox='0 0 24 24' fill='none' {...props}>
		<path
			d='M3.77229 8.70834L4.30262 9.23867L3.77229 8.70834ZM8.70834 13.6444L8.17801 13.1141L8.70834 13.6444ZM13.6444 8.70834L14.1747 9.23867L13.6444 8.70834ZM8.70834 3.77229L8.17801 3.24196L8.70834 3.77229ZM13.1141 8.17801L8.17801 13.1141L9.23867 14.1747L14.1747 9.23867L13.1141 8.17801ZM4.30262 9.23867L9.23867 4.30262L8.17801 3.24196L3.24196 8.17801L4.30262 9.23867ZM5.70998 6.77064L10.646 11.7067L11.7067 10.646L6.77064 5.70998L5.70998 6.77064ZM4.30262 13.1141C3.23246 12.0439 3.23246 10.3088 4.30262 9.23867L3.24196 8.17801C1.58601 9.83395 1.58601 12.5188 3.24196 14.1747L4.30262 13.1141ZM8.17801 13.1141C7.10785 14.1842 5.37278 14.1842 4.30262 13.1141L3.24196 14.1747C4.8979 15.8307 7.58272 15.8307 9.23867 14.1747L8.17801 13.1141ZM13.1141 4.30262C14.1842 5.37278 14.1842 7.10785 13.1141 8.17801L14.1747 9.23867C15.8307 7.58272 15.8307 4.8979 14.1747 3.24196L13.1141 4.30262ZM14.1747 3.24196C12.5188 1.58601 9.83395 1.58601 8.17801 3.24196L9.23867 4.30262C10.3088 3.23246 12.0439 3.23246 13.1141 4.30262L14.1747 3.24196Z'
			fill='currentColor'
		/>
		<path
			d='M18.5003 15.5834C18.5003 17.1942 17.1945 18.5001 15.5837 18.5001V20.0001C18.0229 20.0001 20.0003 18.0227 20.0003 15.5834H18.5003ZM15.5837 18.5001C13.9728 18.5001 12.667 17.1942 12.667 15.5834H11.167C11.167 18.0227 13.1444 20.0001 15.5837 20.0001V18.5001ZM12.667 15.5834C12.667 13.9726 13.9728 12.6667 15.5837 12.6667V11.1667C13.1444 11.1667 11.167 13.1442 11.167 15.5834H12.667ZM15.5837 12.6667C17.1945 12.6667 18.5003 13.9726 18.5003 15.5834H20.0003C20.0003 13.1442 18.0229 11.1667 15.5837 11.1667V12.6667ZM11.917 16.3334H19.2503V14.8334H11.917V16.3334Z'
			fill='currentColor'
		/>
	</svg>
);
export default PharmacyIcon;
