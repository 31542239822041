import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import {
	getExerciseCategories,
	addPhysicalTherapy,
	getGroupedExercisesByType,
	submitPhysicalTherapies,
	deleteExercisesByCategory,
} from 'api/physicalExercises';
import { Alert, Dropdown, Grid, Loader } from 'components';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { getPhysicalExerciseIcon } from 'infrastructure/helpers/physicalExerciseHelper';
import AddPhysicalExercise from 'containers/PhysicalTherapies/AddPhysicalExercise';
import PhysicalExerciseDetails from 'containers/PhysicalTherapies/PhysicalExerciseDetails';
import { PhysicalExerciseTabs } from 'constants/enums.js';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { createVisit } from 'api/medicalInfo.js';
import { getUserInfo } from 'infrastructure/auth.js';
import { useConferenceConfigurations } from 'calls/hooks/index.js';
import Button from 'components/Button.jsx';

const AddPTReport = props => {
	const [isLoading, setIsLoading] = useState(false);
	const [step, setStep] = useState(PhysicalExerciseTabs.EXERCISES_LIST);
	const [hasError, setError] = useState(null);
	const [physicalTherapyExercises, setPhysicalTherapyExercises] = useState([]);
	const [selectedCategoryId, setSelectedCategoryId] = useState(null);
	const [categories, setCategories] = useState([]);
	const [physicalTherapyId, setPhysicalTherapyId] = useState('');
	const intl = useIntl();
	const conferenceConfigurations = useConferenceConfigurations();

	useEffect(() => {
		fetchExerciseCategories();
	}, []);

	useEffect(() => {
		const addPhysicalTherapyId = async () => {
			const params = { patientId: props.patientId, doctorId: getUserInfo().userId, place: props.patientName };
			const visitRes = await createVisit(params);
			if (visitRes.error) {
				setError(visitRes.error.message);
				return;
			}
			const dataToSend = {
				conversationId: visitRes.id,
				patientId: props.patientId,
				doctorId: props.doctorId,
				exercises: [],
			};
			const response = await addPhysicalTherapy(dataToSend);
			if (response.error) {
				setError(response.error.message);
			} else {
				setPhysicalTherapyId(response.item.id);
				if (response.item.exercises.length) {
					const groupedExercises = await getGroupedExercisesByType(response.item.id);
					if (groupedExercises.error) {
						setError(groupedExercises.error.message);
						return;
					}
					setPhysicalTherapyExercises(groupedExercises.items);
				}
			}
			setIsLoading(false);
		};
		addPhysicalTherapyId();
	}, [props.doctorId, props.patientId, props.visitId, props.patientName]);

	const fetchExerciseCategories = async () => {
		const response = await getExerciseCategories();
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setCategories(response.items);
	};

	const saveInitialData = async () => {
		setStep(PhysicalExerciseTabs.EXERCISES_LIST);
		const response = await getGroupedExercisesByType(physicalTherapyId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setPhysicalTherapyExercises(response.items);
	};

	const deleteExerciseByCategory = async categoryId => {
		const response = await deleteExercisesByCategory(physicalTherapyId, categoryId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		saveInitialData();
	};

	const openViewDetails = categoryId => {
		setStep(PhysicalExerciseTabs.EXERCISE_DETAILS);
		setSelectedCategoryId(categoryId);
	};

	const submitPhysicalTherapy = async () => {
		const response = await submitPhysicalTherapies(physicalTherapyId, 'Completed');
		if (response.error) {
			setError(response.error.message);
			return;
		}
		props.closePTReport();
	};

	return (
		<>
			<aside
				className={classNames(
					'right-side medical-info-aside add-prescriptions-wrapper add-physical-wrapper',
					step !== PhysicalExerciseTabs.NEW_EXERCISE ? ' flex column-direction' : '',
					conferenceConfigurations.isRightToLeft ? 'rtl-medical-info-aside' : '',
					conferenceConfigurations.isDarkMode ? 'dark-medical-info-aside' : ''
				)}>
				{isLoading && (
					<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
						<div className='text-align-center'>
							<Loader />
						</div>
					</Grid>
				)}
				{!isLoading && <>{step === PhysicalExerciseTabs.EXERCISES_LIST && <h4>{translate('physicalTherapyExercises')}</h4>}</>}
				{step === PhysicalExerciseTabs.EXERCISES_LIST && physicalTherapyExercises.length === 0 && (
					<div className='d-p-no-items'>
						<p>
							{props.patientName}
							{translate('noAddedExercise')}
						</p>
					</div>
				)}
				{step === PhysicalExerciseTabs.EXERCISES_LIST && physicalTherapyExercises.length > 0 && (
					<div className='flex-1'>
						<ul className='list-group exercises-details'>
							{physicalTherapyExercises.map(physicalEx => (
								<li>
									<span className='cursor-unset'>
										<img
											src={`${healthCareCdnUrl}physical-exercises/${getPhysicalExerciseIcon(physicalEx.category.type)}.svg?v2`}
											alt={physicalEx?.category?.name || 'physical-category-type'}
										/>
										<div className='flex'>
											<div className='flex'>{intl.formatMessage({ id: stringToCamelCase(physicalEx?.category.name) })}</div>
											{physicalEx.types.map(exerciseType => (
												<span>{intl.formatMessage({ id: stringToCamelCase(exerciseType.name) })}</span>
											))}
										</div>
										<Dropdown position='bottom' icon='more_vert' className='remove-connection-wrapper physical-exercises'>
											<div className='physical-exercises-dropdown'>
												<div className='flex cursor-pointer' onClick={() => openViewDetails(physicalEx.category.id)}>
													<img src={`${healthCareCdnUrl}physical-exercises/details.svg`} alt='icon' />
													<p>{translate('viewDetails')}</p>
												</div>

												<div className='flex cursor-pointer' onClick={() => deleteExerciseByCategory(physicalEx.category.id)}>
													<img src={`${healthCareCdnUrl}physical-exercises/delete.svg`} alt='icon' />
													<p>{translate('delete')}</p>
												</div>
											</div>
										</Dropdown>
									</span>
								</li>
							))}
						</ul>
					</div>
				)}
				{step === PhysicalExerciseTabs.NEW_EXERCISE && (
					<AddPhysicalExercise
						patientId={props.patientId}
						categories={categories}
						setStep={setStep}
						saveInitialData={saveInitialData}
						physicalTherapyId={physicalTherapyId}
					/>
				)}
				{step === PhysicalExerciseTabs.EXERCISE_DETAILS && (
					<PhysicalExerciseDetails
						getIcons={getPhysicalExerciseIcon}
						selectedCategoryId={selectedCategoryId}
						physicalTherapyId={physicalTherapyId}
						setPhysicalTherapyExercises={setPhysicalTherapyExercises}
						setStep={setStep}
						categories={categories}
						patientId={props.patientId}
					/>
				)}
				{step === PhysicalExerciseTabs.EXERCISES_LIST && (
					<>
						<Button onClick={() => setStep(2)} className='button small block' text={translate('addNewExercise')} />
						{physicalTherapyExercises.length > 0 && (
							<Button
								onClick={submitPhysicalTherapy}
								className='button small block add-exercise top-15 submit-pt-btn'
								text={translate('submitPhysicalTherapy')}
							/>
						)}
					</>
				)}
				<Alert display={hasError} fixed={true} hideCloseButton={true} message={hasError} variant='dark' />
			</aside>
		</>
	);
};
export default AddPTReport;
