import DatePicker, { registerLocale } from 'react-datepicker';
import MaskedTextInput from 'react-text-mask';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import sq from 'date-fns/locale/sq/index.js';
import en from 'date-fns/locale/en-US/index.js';
import Select from 'react-select';
import translate from 'i18n-translations/translate.jsx';
import { validateMaxLength } from 'infrastructure/helpers/commonHelpers.js';
import { getUserRole } from 'infrastructure/auth.js';
import { UserRoles } from 'constants/enums.js';
import { Field } from 'formik';
import Input from 'components/Common/FormElements/Input.jsx';

const PersonalInformation = props => {
	const intl = useIntl();
	registerLocale('en-US', en);
	registerLocale('sq', sq);
	const locale = useSelector(state => state.language.locale);

	const transformArray = array => array.map(item => ({ value: item.code, label: item.name }));

	return (
		<>
			<h4>{translate('personalInformation')}</h4>

			<div className='flex view-profile-modal-wrapper flex-wrap patient-input-wrapper'>
				{[UserRoles.DOCTOR, UserRoles.NURSE].includes(getUserRole()) && (
					<div>
						<div>
							<p>MRN</p>
							<input type='text' value={props.mrn} readOnly disabled />
						</div>
					</div>
				)}
				<div>
					<Field
						name='firstName'
						type='text'
						label={`*${intl.formatMessage({ id: 'firstName' })}`}
						component={Input}
						minLength={2}
						maxLength={30}
						isErrorOutsideDiv={true}
					/>
				</div>
				<div>
					<Field
						type='text'
						name='lastName'
						label={`*${intl.formatMessage({ id: 'lastName' })}`}
						component={Input}
						minLength={2}
						maxLength={30}
						isErrorOutsideDiv={true}
					/>
				</div>
				<div className='patient-birth-date'>
					<div>
						<p>*{translate('dateOfBirth')}</p>
						<div className='flex'>
							<input
								type='number'
								value={props.values.birthDay}
								onChange={props.onChange}
								name='birthDay'
								className='number-input-wo-arrows'
								onKeyDown={event => validateMaxLength(event, 2)}
								onBlur={val => {
									if (props.isFromRpm) {
										return;
									}
									if (!val.target.value) {
										props.setBirthDateError(intl.formatMessage({ id: 'dateOfBirthRequired' }));
									} else if (val.target.value && props.values.birthYear && props.values.birthMonth) {
										props.setBirthDateError(null);
									}
								}}
								placeholder={`${intl.formatMessage({ id: 'day' })} (DD)`}
							/>
							<DatePicker
								selected={props.values.birthMonth}
								onChange={val => {
									props.setFieldValue('birthMonth', val);
									if (props.isFromRpm) {
										return;
									}
									if (val && props.values.birthYear && props.values.birthDay) {
										props.setBirthDateError(null);
									}
								}}
								showMonthYearPicker
								showFullMonthYearPicker
								onBlur={val => {
									if (props.isFromRpm) {
										return;
									}
									if (!val.target.value) {
										props.setBirthDateError(intl.formatMessage({ id: 'dateOfBirthRequired' }));
									}
								}}
								dateFormat='MMMM'
								placeholderText={`${intl.formatMessage({ id: 'month' })} (MM)`}
								locale={locale}
							/>
							<DatePicker
								selected={props.values.birthYear}
								onChange={val => {
									props.setFieldValue('birthYear', val);
									if (props.isFromRpm) {
										return;
									}
									if (val && props.values.birthMonth && props.values.birthDay) {
										props.setBirthDateError(null);
									}
								}}
								showYearPicker
								onBlur={val => {
									if (props.isFromRpm) {
										return;
									}
									if (!val.target.value) {
										props.setBirthDateError(intl.formatMessage({ id: 'dateOfBirthRequired' }));
									}
								}}
								dateFormat='yyyy'
								placeholderText={`${intl.formatMessage({ id: 'year' })} (YY)`}
								maxDate={new Date()}
								customInput={
									<MaskedTextInput type='text' mask={[/\d/, /\d/, /\d/, /\d/]} onKeyDown={event => validateMaxLength(event, 4)} />
								}
							/>
						</div>
					</div>
					{props.showBirthdayError && <span className='red-error'>{props.birthDateError}</span>}
				</div>
				<div className='position-relative' ref={props.genderWrapperRef}>
					<div>
						<p>*{translate('sex')}</p>
						<div className='flex no-margin sex-select-wrapper' onClick={props.setIsGenderOpen}>
							<Field name='gender'>
								{({ form }) => (
									<div className='flex full-width'>
										<input type='select' value={form.values.gender.name || ''} readOnly className='cursor-pointer' />
										<i className='material-icons'>expand_more</i>
									</div>
								)}
							</Field>
						</div>
					</div>
					{props.isGenderOpen && (
						<div className='custom-select-result'>
							{props.genders.map(item => (
								<div
									className='flex cursor-pointer header-elements'
									key={item.id}
									onClick={() => {
										props.toggleGenderDropDown(item);
										props.setFieldValue('gender', item);
									}}>
									<p>{item.name}</p>
								</div>
							))}
						</div>
					)}
					{props.touched?.gender && <span className='red-error'>{props.errors?.gender}</span>}
				</div>
				<div>
					<Field
						type='number'
						name='idNumber'
						label={`*${intl.formatMessage({ id: 'idNumber' })}`}
						className='number-input-wo-arrows'
						component={Input}
						onKeyDown={event => validateMaxLength(event, 10)}
						isErrorOutsideDiv={true}
					/>
				</div>
				<div>
					<Field
						type='email'
						name='emailAddress'
						label={`*${intl.formatMessage({ id: 'emailAddress' })}`}
						component={Input}
						maxLength={100}
						isErrorOutsideDiv={true}
					/>
				</div>
				<div>
					<div className='country-background'>
						<p>{translate('country')}</p>
						<Select
							value={transformArray(props.countries).filter(option => option.value === props.values.country)}
							placeholder={intl.formatMessage({ id: 'selectCountry' })}
							classNamePrefix='custom-select'
							options={transformArray(props.countries)}
							onChange={event => props.setFieldValue('country', event?.value)}
							isSearchable
							isClearable
						/>
					</div>
				</div>
				<div>
					<div>
						<p>{translate('city')}</p>
						<input type='text' value={props.values.city} onChange={props.onChange} name='city' maxLength={50} />
					</div>
					<span className='red-error'>{props.errors?.city}</span>
				</div>
				<div>
					<div>
						<p>{translate('zipCode')}</p>
						<input
							type='number'
							value={props.values.zipCode}
							onChange={props.onChange}
							name='zipCode'
							className='number-input-wo-arrows'
							maxLength={10}
							onKeyDown={event => validateMaxLength(event, 10)}
						/>
					</div>
				</div>
				<div>
					<Field
						type='text'
						name='address'
						label={intl.formatMessage({ id: 'address' })}
						component={Input}
						maxLength={100}
						isErrorOutsideDiv={true}
					/>
				</div>
				<div>
					<Field
						type='number'
						name='phoneNumber'
						label={translate('phoneNumber')}
						className='number-input-wo-arrows'
						component={Input}
						isErrorOutsideDiv={true}
						onKeyDown={event => validateMaxLength(event, 15)}
					/>
				</div>
			</div>
		</>
	);
};

export default PersonalInformation;
