import { gatewayApi } from 'constants/global-variables.js';

export const getUserPresence = async userId => {
	try {
		const url = `/v1/users/${userId}/presence`;
		const { data } = await gatewayApi.get(url);
		return data.presence;
	} catch (error) {
		return { error };
	}
};

export const updateUserPresence = async (userId, presenceStatusTypeId, customMessage) => {
	try {
		const url = `/v1/users/${userId}/presence`;
		const { data } = await gatewayApi.put(url, {
			customMessage: customMessage,
			presenceStatusTypeId: presenceStatusTypeId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};
