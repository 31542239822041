const ArrowFlat = props => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M2.5 8.33325L10 13.3333L17.5 8.33325'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
export default ArrowFlat;
