import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { useSelector } from 'react-redux';

const VideoFeedSubOption = props => {
	const companySettings = useSelector(state => state.company.companySettings);
	const monitoringIconLink = `${healthCareCdnUrl}monitoring/`;
	const reportOptions = [
		{
			imgIcon: `${monitoringIconLink}video-feed/fake-alert.svg`,
			text: translate('falseAlert'),
			color: '--red-color',
		},
		{
			imgIcon: `${monitoringIconLink}video-feed/acknowledge.svg`,
			text: translate('acknowledge'),
			color: '--green-color',
		},

		{
			imgIcon: `${monitoringIconLink}video-feed/forward.svg`,
			text: translate('forwardToNurses'),
			color: '--blue-color',
			onClick: props.onForwardToNurses,
		},
	];

	const contactNursesOptions = [
		{
			imgIcon: `${monitoringIconLink}video-feed/send-message.svg`,
			text: translate('sendGroupMessage'),
			color: '--blue-color',
			onClick: props.onSendGroupMessage,
			id: 0,
		},
		{
			imgIcon: `${monitoringIconLink}video-feed/call-icon.svg`,
			text: translate('callSomethingOnDuty', { value: companySettings.nurseDisplayName }),
			color: '--blue-color',
			onClick: props.onCallNurses,
			id: 1,
		},
		{
			imgIcon: `${monitoringIconLink}video-feed/add-nurses.svg`,
			text: translate('specificSomething', { value: companySettings.nurseDisplayName }),
			color: '--blue-color',
			onClick: props.onSelectSpecificNurse,
			id: 2,
		},
	];
	const monitoringButtons = props.isReports ? reportOptions : contactNursesOptions;
	return (
		<>
			{monitoringButtons.map(item => (
				<div key={item.id} className={props.className} onClick={item.onClick}>
					<img src={item.imgIcon} alt='icon' />
					<p className={item.color}>{item.text}</p>
				</div>
			))}
			{props.children}
		</>
	);
};

export default VideoFeedSubOption;
