import React, { createContext, useState, useRef } from 'react';
import { Observable } from '@solaborate/calls/webrtc'; 
import { ControlsActions, UserRoles } from 'calls/enums/index.js';
import { APP_CONFIG } from 'constants/global-variables.js';
import { getUserRole } from 'infrastructure/auth.js';
import { getRoleConfigurationValue, getSomeRoleConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';
import { MedicalDevicesConfigsList, RoundingSettings } from 'constants/configurationEnums.js';

const ConferenceConfigurationsCtx = createContext({
	isGridView: false,
	isFitScreen: false,
	isMainParticipantPortraitVideo: false,
	isCameraControlsOpen: ![UserRoles.PATIENT, UserRoles.VISITOR, UserRoles.GUEST].includes(getUserRole()),
	isInviteParticipantsModalViewOpen: false,
	isMediaBlockedPopupOpen: false,
	isParticipantsViewHidden: false,
	showPatientMeasurementsButtons: false,
	isHealthMeasurementsVisible: false,
	isLiveExaminationOpen: false,
	isTelemetryModalOpen: false,
	isPatientHistoryOpen: false,
	isCameraMeasurementsVisible: false,
	medicalDataControls: {
		isConversationModalVisible: false,
		isDiagnosesVisible: false,
		isProceduresVisible: false,
		isNotesVisible: false,
		isPrescriptionsVisible: false,
		isPhysicalExercisesVisible: false,
		isMedicalFormsVisible: false,
		isCareEventsFormVisible: false,
	},
	aiControls: {
		isObjectDetectionOn: false,
		isBabyDetectionOn: false,
		isRailsDetectionOn: false,
		isSkeletonDetectionOn: false,
		isPersonDetectionOn: false,
		isAiAlertOn: false,
		isPrivateModeOn: false,
		isMultiSkeletonDetectionOn: false,
	},
	customDisplayNames: { nurseDisplayName: '', helloName: '' },
	isWhiteboardVisible: false,
	isAiOpen: false,
	isRoomSignOpen: false,
	/** @type {Observable<{ key: string, value: boolean }>} */
	controlsObservable: null,
	visitId: '',
	sinkId: '',
	pinnedParticipantId: '',
	/** @type {React.MutableRefObject<string>} */
	pinnedParticipantIdRef: { current: '' },
	isTransferToDoctorModalOpen: false,
	isCECSupportedModalOpen: false,
	isMinimizedView: false,
	isEmbeddedView: false,
	featureFlags: {
		usePhysicalExercises: false,
	},
	isVirtualBackgroundModalOpen: false,
	isStreamSettingsModalOpen: false,
	isRightToLeft: false,
	isDarkMode: false,
	roundingConfigurations: null,
	roomType: null,
	tvVolumeRange: null,
	conferenceErrorMessages: [],
	isLiveCaptionsOpen: false,
	removeParticipantModal: {
		isOpen: false,
		modalMessage: '',
		onSubmit: () => {},
	},
	isTransferOwnershipModalOpen: false,
	isMultiBed: false,
	gridCount: 1,
	isMoreParticipantsViewOpen: false,
	patientInfo: {},
	/** @type {{id: string; participantId: string; participantName: string; participantPicture: string; timestamp: number; message: string}[]} */
	speechToTextMessages: [],
	/** @type {Array<(import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default)>} */
	speechToTextParticipants: [],
	/** @type {{conferenceId: string; ok: boolean; links: {destination: string; invitationSecret: string}[]}}  */
	invitedParticipants: null,
	voyceInterpreterRequestId: null,
	interpreterJoinedTime: null,
	interpreterBaseUrl: null,
	/** @type {any[]} */
	backgroundImages: [],
	/** @type {string} */
	micMutedByOwnerMessage: '',
	// eslint-disable-next-line no-unused-vars
	onConfigurationToggleAction: (/** @type {string} */ _action, /** @type {boolean} */ _value) => {},
	// eslint-disable-next-line no-unused-vars
	onHealthDataToggleAction: (/** @type {object} */ _data) => {},
	// eslint-disable-next-line no-unused-vars
	onAIControlsToggleAction: (/** @type {object} */ _data) => {},
	// eslint-disable-next-line no-unused-vars
	setVisitId: (/** @type {string} */ _id) => {},
	// eslint-disable-next-line no-unused-vars
	setPinnedParticipantId: (/** @type {string} */ _id) => {},
	// eslint-disable-next-line no-unused-vars
	setSinkId: (/** @type {string} */ _id) => {},
	// eslint-disable-next-line no-unused-vars
	onSetCategoryConfigurationsAction: (/** @type {string} */ _action, /** @type {object} */ _data) => {},
	// eslint-disable-next-line no-unused-vars
	setRoomType: (/** @type {string} */ _roomType) => {},
	// eslint-disable-next-line no-unused-vars
	setIsFitScreen: (/** @type {boolean} */ _isFitScreen) => {},
	// eslint-disable-next-line no-unused-vars
	setIsMainParticipantPortraitVideo: (/** @type {boolean} */ _isPortraitVideo) => {},
	// eslint-disable-next-line no-unused-vars
	setTvVolumeRange: (/** @type {number} */ _range) => {},
	// eslint-disable-next-line no-unused-vars
	/** @param {{id: string; message: JSX.Element}[]} _data */
	// eslint-disable-next-line no-unused-vars
	setConferenceErrorMessages: _data => {},
	// eslint-disable-next-line no-unused-vars
	setIsLiveCaptionsOpen: /** @param {boolean} _data */ _data => {},
	// eslint-disable-next-line no-unused-vars
	setIsInviteParticipantsModalViewOpen: _data => {},
	// eslint-disable-next-line no-unused-vars
	setRemoveParticipantModal: _data => {},
	// eslint-disable-next-line no-unused-vars
	setIsTransferOwnershipModalopen: /** @param {boolean} _data */ _data => {},
	/** @type {React.Dispatch<React.SetStateAction<number>>} */
	// eslint-disable-next-line no-unused-vars
	setGridCount: (/** @type {number} */ _count) => {},
	// eslint-disable-next-line no-unused-vars
	setSpeechToTextMessages: (
		/** @type {{id: string; participantId: string; participantName: string; timestamp: number; message: string}[]} */ _data
	) => {},
	pushMessage: (
		/** @type {{id: string; participantId: string; participantName: string; participantPicture: string; timestamp: number; message: string}} */ _data
	) => {},
	// eslint-disable-next-line no-unused-vars
	setSpeechToTextParticipants: (
		/** @type {Array<(import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default)>} */ _data
	) => {},
	// eslint-disable-next-line no-unused-vars
	pushSpeechToTextParticipant: (
		/** @type {import('calls/RemoteHelloParticipant.js').default | import('calls/LocalParticipant.js').default} */ _data
	) => {},
	// eslint-disable-next-line no-unused-vars
	removeSpeechToTextParticipant: (
		/** @type {import('calls/RemoteHelloParticipant.js').default | import('calls/LocalParticipant.js').default} */ _data
	) => {},
	// eslint-disable-next-line no-unused-vars
	setInvitedParticipants:
		/** @type {React.Dispatch<React.SetStateAction<{conferenceId: string, ok: boolean, links: {destination: string, invitationSecret: string}[]}>>} */ _data => {},
	// eslint-disable-next-line no-unused-vars
	setPatientInfo: (/** @type {object} */ _data) => {},
	setVoyceInterpreterRequestId: (/** @type {string} */ _data) => null,
	// eslint-disable-next-line no-unused-vars
	setInterpreterJoinedTime: (/** @type {Date} */ _data) => null,
	setInterpreterBaseUrl: (/** @type {string} */ _data) => null,
	// eslint-disable-next-line no-unused-vars
	setBackgroundImages: (/** @type { any[] } */ _data) => {},
	// eslint-disable-next-line no-unused-vars
	setMicMutedByOwnerMessage: (/** @type {string} */ _data) => {},
});

const ConferenceConfigurationsProvider = ({ children }) => {
	const [isCECSupportedModalOpen, setIsCECSupportedModalOpen] = useState(false);
	const [isMinimizedView, setIsMinimizedView] = useState(false);
	const [isEmbeddedView, setIsEmbeddedView] = useState(false);
	const [isGridView, setIsGridView] = useState(false);
	const [isStreamSettingsModalOpen, setIsStreamSettingsModalOpen] = useState(false);
	const [isVirtualBackgroundModalOpen, setIsVirtualBackgroundModalOpen] = useState(false);
	const [isFitScreen, setIsFitScreen] = useState(false);
	const [isMainParticipantPortraitVideo, setIsMainParticipantPortraitVideo] = useState(false);
	const [isCameraControlsOpen, setIsCameraControlsOpen] = useState(
		![UserRoles.PATIENT, UserRoles.VISITOR, UserRoles.GUEST].includes(getUserRole())
	);
	const [isLiveExaminationOpen, setIsLiveExaminationOpen] = useState(false);
	const [isTelemetryModalOpen, setIsTelemetryModalOpen] = useState(false);
	const [isInviteParticipantsModalViewOpen, setIsInviteParticipantsModalViewOpen] = useState(false);
	const [isMediaBlockedPopupOpen, setIsMediaBlockedPopupOpen] = useState(false);
	const [isHealthMeasurementsVisible, setIsHealthMeasurementsVisible] = useState(false);
	const [isCameraMeasurementsVisible, setIsCameraMeasurementsVisible] = useState(false);
	const [isParticipantsViewHidden, setIsParticipantsViewHidden] = useState(false);
	const [showPatientMeasurementsButtons, setShowPatientMeasurementsButtons] = useState(false);
	const [isPatientHistoryOpen, setIsPatientHistoryOpen] = useState(false);
	const [isRightToLeft, setIsRightToLeft] = useState(false);
	const [isDarkMode, setIsDarkMode] = useState(false);
	const [customDisplayNames, setCustomDisplayNames] = useState({ nurseDisplayName: 'Nurse', helloName: 'Hello' });
	const [roundingConfigurations, setRoundingConfigurations] = useState(null);
	const [roomType, setRoomType] = useState(null);
	const [tvVolumeRange, setTvVolumeRange] = useState(null);
	const [conferenceErrorMessages, setConferenceErrorMessages] = useState([]);
	const [isLiveCaptionsOpen, setIsLiveCaptionsOpen] = useState(false);
	const [isTransferOwnershipModalOpen, setIsTransferOwnershipModalopen] = useState(false);
	const [isMultiBed, setIsMultiBed] = useState(false);
	const [gridCount, setGridCount] = useState(1);
	const [isMoreParticipantsViewOpen, setIsMoreParticipantsViewOpen] = useState(false);
	const [isWhiteboardVisible, setIsWhiteboardVisible] = useState(false);
	const [speechToTextMessages, setSpeechToTextMessages] = useState([]);
	const [speechToTextParticipants, setSpeechToTextParticipants] = useState([]);
	const [isAiOpen, setIsAiOpen] = useState(false);
	const [isRoomSignOpen, setIsRoomSignOpen] = useState(false);
	const [invitedParticipants, setInvitedParticipants] = useState(null);
	const [voyceInterpreterRequestId, setVoyceInterpreterRequestId] = useState(null);
	const [interpreterJoinedTime, setInterpreterJoinedTime] = useState(null);
	const [interpreterBaseUrl, setInterpreterBaseUrl] = useState(null);
	const [patientInfo, setPatientInfo] = useState(null);
	const [backgroundImages, setBackgroundImages] = useState([]);
	const [medicalDataControls, setMedicalDataControls] = useState({
		isConversationModalVisible: false,
		isDiagnosesVisible: false,
		isProceduresVisible: false,
		isNotesVisible: false,
		isPrescriptionsVisible: false,
		isPhysicalExercisesVisible: false,
		isMedicalFormsVisible: false,
		isCareEventsFormVisible: false,
	});

	const [aiControls, setAIControls] = useState({
		isObjectDetectionOn: false,
		isBabyDetectionOn: false,
		isRailsDetectionOn: false,
		isSkeletonDetectionOn: false,
		isPersonDetectionOn: false,
		isAiAlertOn: false,
		isPrivateModeOn: false,
		isMultiSkeletonDetectionOn: false,
	});

	const [featureFlags] = useState({
		usePhysicalExercises: APP_CONFIG.useFeaturePhysicalExercises === 'true',
	});

	const [controlsObservable] = useState(new Observable());
	const [visitId, setVisitId] = useState('');
	const [sinkId, setSinkId] = useState('');
	const [pinnedParticipantId, setPinnedParticipantIdInState] = useState('');
	const pinnedParticipantIdRef = useRef('');
	const [isTransferToDoctorModalOpen, setIsTransferToDoctorModalOpen] = useState(false);
	const [micMutedByOwnerMessage, setMicMutedByOwnerMessage] = useState('');

	const [removeParticipantModal, setRemoveParticipantModal] = useState({
		isOpen: false,
		modalMessage: '',
		onSubmit: () => {},
	});

	/**
	 * @typedef {object} MedicalDataControls
	 * @property {boolean} data.isConversationModalVisible
	 * @property {boolean} data.isDiagnosesVisible
	 * @property {boolean} data.isProceduresVisible
	 * @property {boolean} data.isNotesVisible
	 * @property {boolean} data.isPrescriptionsVisible
	 * @property {boolean} data.isPhysicalExercisesVisible
	 * @property {boolean} data.isMedicalFormsVisible
	 * @property {boolean} data.isCareEventsFormVisible
	 * @param {MedicalDataControls} prevState
	 * @param {string} key
	 * @param {boolean} value
	 */
	const setMedicalDataControl = (prevState, key, value) => {
		const data = { ...prevState };

		Object.keys(data).forEach(medicalDataKey => {
			data[medicalDataKey] = medicalDataKey === key && value;
		});

		return data;
	};

	/**
	 * @typedef {object} AIControls
	 * @property {boolean} data.isObjectDetectionOn
	 * @property {boolean} data.isBabyDetectionOn
	 * @property {boolean} data.isRailsDetectionOn
	 * @property {boolean} data.isSkeletonDetectionOn
	 * @property {boolean} data.isPersonDetectionOn
	 * @property {boolean} data.isAiAlertOn
	 * @property {boolean} data.isPrivateModeOn
	 * @property {boolean} data.isMultiSkeletonDetectionOn
	 * @param {AIControls} prevState
	 * @param {string} key
	 * @param {boolean} value
	 */
	const setAIControl = (prevState, key, value) => {
		const data = { ...prevState };
		Object.keys(data).forEach(aiKey => {
			data[aiKey] = aiKey === key && value;
		});

		controlsObservable.fire({ key, value });

		return data;
	};

	const pushMessage = data => {
		if (data) {
			setSpeechToTextMessages(prev => [...(prev || []), data]);
		} else {
			setSpeechToTextMessages(prev => [...prev] || []);
		}
	};

	const pushSpeechToTextParticipant = data => {
		if (data) {
			setSpeechToTextParticipants(prev => [...(prev || []), data]);
		} else {
			setSpeechToTextParticipants(prev => [...prev] || []);
		}
	};

	const removeSpeechToTextParticipant = data => {
		setSpeechToTextParticipants(prev => prev.filter(participant => participant.id !== data.id));
	};

	const onConfigurationToggleAction = (action, value) => {
		switch (action) {
			case ControlsActions.TOGGLE_GRID_VIEW:
				setIsGridView(prevState => value ?? !prevState);
				break;
			case ControlsActions.TOGGLE_STREAM_SETTINGS_MODAL:
				setIsStreamSettingsModalOpen(prevState => value ?? !prevState);
				break;
			case ControlsActions.TOGGLE_SELECT_BACKGROUND_MODAL:
				setIsVirtualBackgroundModalOpen(prevState => value ?? !prevState);
				break;
			case ControlsActions.TOGGLE_FIT_SCREEN:
				setIsFitScreen(prevState => value ?? !prevState);
				break;
			case ControlsActions.TOGGLE_CAMERA_CONTROLS:
				setIsCameraControlsOpen(prevState => value ?? !prevState);
				break;
			case ControlsActions.TOGGLE_LIVE_CAPTIONS:
				if (!getSomeRoleConfigurationsValues(roundingConfigurations, [RoundingSettings.LiveCaptions])) {
					return;
				}
				if (isInviteParticipantsModalViewOpen) {
					setIsInviteParticipantsModalViewOpen(false);
				}
				if (isLiveCaptionsOpen) {
					setSpeechToTextMessages([]);
				}
				setIsLiveCaptionsOpen(prevState => value ?? !prevState);
				break;
			case ControlsActions.TOGGLE_INVITE_PARTICIPANTS_MODAL:
				if (
					!isEmbeddedView &&
					!getSomeRoleConfigurationsValues(roundingConfigurations, [
						RoundingSettings.MeetingUrl,
						RoundingSettings.InviteViaSms,
						RoundingSettings.DialIn,
						RoundingSettings.DialOut,
						RoundingSettings.TranslationServices,
						RoundingSettings.InviteViaEmail,
					])
				) {
					return;
				}
				setIsInviteParticipantsModalViewOpen(prevState => value ?? !prevState);
				break;
			case ControlsActions.TOGGLE_MEDIA_PERMISSION_POPUP:
				setIsMediaBlockedPopupOpen(prevState => value ?? !prevState);
				break;
			case ControlsActions.TOGGLE_HEALTH_MEASUREMENTS:
				setIsHealthMeasurementsVisible(prevState => {
					const updatedValue = value ?? !prevState;
					controlsObservable.fire({ key: ControlsActions.TOGGLE_HEALTH_MEASUREMENTS, value: updatedValue });
					return updatedValue;
				});
				break;
			case ControlsActions.TOGGLE_LIVE_EXAMINATIONS:
				if (
					!(
						getRoleConfigurationValue(roundingConfigurations, RoundingSettings.MedicalDevices) &&
						getSomeRoleConfigurationsValues(roundingConfigurations, MedicalDevicesConfigsList)
					) &&
					!getRoleConfigurationValue(roundingConfigurations, RoundingSettings.ExamKit)
				) {
					return;
				}
				setIsLiveExaminationOpen(prevState => {
					const updatedState = value ?? !prevState;
					controlsObservable.fire({ key: ControlsActions.TOGGLE_LIVE_EXAMINATIONS, value: updatedState });
					return updatedState;
				});
				if (value && isHealthMeasurementsVisible) {
					setIsHealthMeasurementsVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_HEALTH_MEASUREMENTS, value: false });
				}
				if (value && isCameraMeasurementsVisible) {
					setIsCameraMeasurementsVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_CAMERA_MEASUREMENTS, value: false });
				}
				if (isPatientHistoryOpen) {
					setIsPatientHistoryOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_PATIENT_HISTORY, value: false });
				}
				if (isTelemetryModalOpen) {
					setIsTelemetryModalOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_TELEMETRY, value: false });
				}
				if (isWhiteboardVisible) {
					setIsWhiteboardVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_WHITEBOARD, value: false });
				}
				if (isAiOpen) {
					setIsAiOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_AI, value: false });
				}
				if (isRoomSignOpen) {
					setIsAiOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_ROOM_SIGN, value: false });
				}
				break;
			case ControlsActions.TOGGLE_TELEMETRY:
				if (
					!getRoleConfigurationValue(roundingConfigurations, RoundingSettings.LifeSignalsPatch) &&
					!getRoleConfigurationValue(roundingConfigurations, RoundingSettings.BiobeatPatch)
				) {
					return;
				}
				setIsTelemetryModalOpen(prevState => {
					const updatedState = value ?? !prevState;
					controlsObservable.fire({ key: ControlsActions.TOGGLE_TELEMETRY, value: updatedState });
					return updatedState;
				});
				if (value && isHealthMeasurementsVisible) {
					setIsHealthMeasurementsVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_HEALTH_MEASUREMENTS, value: false });
				}
				if (value && isCameraMeasurementsVisible) {
					setIsCameraMeasurementsVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_CAMERA_MEASUREMENTS, value: false });
				}
				if (isPatientHistoryOpen) {
					setIsPatientHistoryOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_PATIENT_HISTORY, value: false });
				}
				if (isLiveExaminationOpen) {
					setIsLiveExaminationOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_LIVE_EXAMINATIONS, value: false });
				}
				if (isWhiteboardVisible) {
					setIsWhiteboardVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_WHITEBOARD, value: false });
				}
				if (isAiOpen) {
					setIsAiOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_AI, value: false });
				}
				if (isRoomSignOpen) {
					setIsAiOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_ROOM_SIGN, value: false });
				}
				break;
			case ControlsActions.TOGGLE_CAMERA_MEASUREMENTS:
				setIsCameraMeasurementsVisible(prevState => {
					const updatedValue = value ?? !prevState;
					controlsObservable.fire({ key: ControlsActions.TOGGLE_CAMERA_MEASUREMENTS, value: updatedValue });
					return updatedValue;
				});
				if (isHealthMeasurementsVisible) {
					controlsObservable.fire({ key: ControlsActions.TOGGLE_HEALTH_MEASUREMENTS, value: false });
					setIsHealthMeasurementsVisible(false);
				}
				break;
			case ControlsActions.TOGGLE_PATIENT_HISTORY:
				if (
					!getSomeRoleConfigurationsValues(roundingConfigurations, [
						RoundingSettings.RoundingHealthData,
						RoundingSettings.Wearables,
						RoundingSettings.RoundingVisits,
						RoundingSettings.RoundingAlertHistory,
					])
				) {
					return;
				}
				setIsPatientHistoryOpen(prevState => {
					const updatedState = value ?? !prevState;
					controlsObservable.fire({ key: ControlsActions.TOGGLE_PATIENT_HISTORY, value: updatedState });
					return updatedState;
				});
				if (value && isHealthMeasurementsVisible) {
					setIsHealthMeasurementsVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_HEALTH_MEASUREMENTS, value: false });
				}
				if (value && isCameraMeasurementsVisible) {
					setIsCameraMeasurementsVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_CAMERA_MEASUREMENTS, value: false });
				}
				if (isLiveExaminationOpen) {
					setIsLiveExaminationOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_LIVE_EXAMINATIONS, value: false });
				}
				if (isWhiteboardVisible) {
					setIsWhiteboardVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_WHITEBOARD, value: false });
				}
				if (isAiOpen) {
					setIsAiOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_AI, value: false });
				}
				if (isRoomSignOpen) {
					setIsAiOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_ROOM_SIGN, value: false });
				}
				break;
			case ControlsActions.TOGGLE_CONVERSATION_MODAL:
				setMedicalDataControls(prevState =>
					setMedicalDataControl(
						prevState,
						ControlsActions.TOGGLE_CONVERSATION_MODAL,
						!prevState[ControlsActions.TOGGLE_CONVERSATION_MODAL]
					)
				);
				break;
			case ControlsActions.TOGGLE_PARTICIPANTS_VIEW:
				setIsParticipantsViewHidden(prevState => value ?? !prevState);
				break;
			case ControlsActions.SET_SHOW_PATIENT_MEASUREMENTS_BUTTONS:
				setShowPatientMeasurementsButtons(value);
				break;
			case ControlsActions.TOGGLE_DIAGNOSES:
				if (!getRoleConfigurationValue(roundingConfigurations, RoundingSettings.PatientDiagnoses)) {
					return;
				}
				setMedicalDataControls(prevState =>
					setMedicalDataControl(
						prevState,
						ControlsActions.TOGGLE_DIAGNOSES,
						value ?? !prevState[ControlsActions.TOGGLE_DIAGNOSES]
					)
				);
				break;
			case ControlsActions.TOGGLE_PROCEDURES:
				if (!getRoleConfigurationValue(roundingConfigurations, RoundingSettings.PatientProcedures)) {
					return;
				}
				setMedicalDataControls(prevState =>
					setMedicalDataControl(
						prevState,
						ControlsActions.TOGGLE_PROCEDURES,
						value ?? !prevState[ControlsActions.TOGGLE_PROCEDURES]
					)
				);
				break;
			case ControlsActions.TOGGLE_NOTES:
				if (!getRoleConfigurationValue(roundingConfigurations, RoundingSettings.PatientNotes)) {
					return;
				}
				setMedicalDataControls(prevState =>
					setMedicalDataControl(prevState, ControlsActions.TOGGLE_NOTES, value ?? !prevState[ControlsActions.TOGGLE_NOTES])
				);
				break;
			case ControlsActions.TOGGLE_CARE_EVENTS:
				// if (!getRoleConfigurationValue(roundingConfigurations, MonitoringSettings.CareEventsForNurses)) {
				// 	return;
				// }
				setMedicalDataControls(prevState =>
					setMedicalDataControl(
						prevState,
						ControlsActions.TOGGLE_CARE_EVENTS,
						value ?? !prevState[ControlsActions.TOGGLE_CARE_EVENTS]
					)
				);
				break;
			case ControlsActions.TOGGLE_PRESCRIPTIONS:
				if (!getRoleConfigurationValue(roundingConfigurations, RoundingSettings.PatientPrescriptions)) {
					return;
				}
				setMedicalDataControls(prevState =>
					setMedicalDataControl(
						prevState,
						ControlsActions.TOGGLE_PRESCRIPTIONS,
						value ?? !prevState[ControlsActions.TOGGLE_PRESCRIPTIONS]
					)
				);
				break;
			case ControlsActions.TOGGLE_PHYSICAL_EXERCISES:
				if (
					!featureFlags.usePhysicalExercises &&
					!getRoleConfigurationValue(roundingConfigurations, RoundingSettings.PatientPhysicalTherapy)
				) {
					return;
				}

				setMedicalDataControls(prevState =>
					setMedicalDataControl(
						prevState,
						ControlsActions.TOGGLE_PHYSICAL_EXERCISES,
						value ?? !prevState[ControlsActions.TOGGLE_PHYSICAL_EXERCISES]
					)
				);
				break;
			case ControlsActions.TOGGLE_MEDICAL_FORMS:
				setMedicalDataControls(prevState =>
					setMedicalDataControl(
						prevState,
						ControlsActions.TOGGLE_MEDICAL_FORMS,
						value ?? !prevState[ControlsActions.TOGGLE_MEDICAL_FORMS]
					)
				);
				break;
			case ControlsActions.TOGGLE_AI_ALERT:
				setAIControls(prevState =>
					setAIControl(prevState, ControlsActions.TOGGLE_AI_ALERT, !prevState[ControlsActions.TOGGLE_AI_ALERT])
				);
				break;
			case ControlsActions.TOGGLE_AI_BABY_DETECTION:
				setAIControls(prevState =>
					setAIControl(prevState, ControlsActions.TOGGLE_AI_BABY_DETECTION, !prevState[ControlsActions.TOGGLE_AI_BABY_DETECTION])
				);
				break;
			case ControlsActions.TOGGLE_AI_OBJECT_DETECTION:
				setAIControls(prevState =>
					setAIControl(
						prevState,
						ControlsActions.TOGGLE_AI_OBJECT_DETECTION,
						!prevState[ControlsActions.TOGGLE_AI_OBJECT_DETECTION]
					)
				);
				break;
			case ControlsActions.TOGGLE_AI_PERSON_DETECTION:
				setAIControls(prevState =>
					setAIControl(
						prevState,
						ControlsActions.TOGGLE_AI_PERSON_DETECTION,
						!prevState[ControlsActions.TOGGLE_AI_PERSON_DETECTION]
					)
				);
				break;
			case ControlsActions.TOGGLE_AI_RAILS_DETECTION:
				setAIControls(prevState =>
					setAIControl(
						prevState,
						ControlsActions.TOGGLE_AI_RAILS_DETECTION,
						!prevState[ControlsActions.TOGGLE_AI_RAILS_DETECTION]
					)
				);
				break;
			case ControlsActions.TOGGLE_AI_SKELETON_DETECTION:
				setAIControls(prevState =>
					setAIControl(
						prevState,
						ControlsActions.TOGGLE_AI_SKELETON_DETECTION,
						!prevState[ControlsActions.TOGGLE_AI_SKELETON_DETECTION]
					)
				);
				break;
			case ControlsActions.TOGGLE_MULTI_SKELETON_DETECTION:
				setAIControls(prevState =>
					setAIControl(
						prevState,
						ControlsActions.TOGGLE_MULTI_SKELETON_DETECTION,
						!prevState[ControlsActions.TOGGLE_MULTI_SKELETON_DETECTION]
					)
				);
				break;
			case ControlsActions.TOGGLE_PRIVATE_MODE:
				setAIControls(prevState =>
					setAIControl(prevState, ControlsActions.TOGGLE_PRIVATE_MODE, !prevState[ControlsActions.TOGGLE_PRIVATE_MODE])
				);
				break;
			case ControlsActions.TOGGLE_TRANSFER_TO_DOCTOR_MODAL:
				setIsTransferToDoctorModalOpen(prevState => value ?? !prevState);
				break;
			case ControlsActions.TOGGLE_CEC_SUPPORTED_MODAL:
				setIsCECSupportedModalOpen(prevState => !prevState);
				break;
			case ControlsActions.TOGGLE_MINIMIZED_VIEW:
				setIsMinimizedView(prevState => value ?? !prevState);
				break;
			case ControlsActions.TOGGLE_EMBEDDED_VIEW:
				setIsEmbeddedView(prevState => value ?? !prevState);
				break;
			case ControlsActions.IS_RIGHT_TO_LEFT:
				setIsRightToLeft(prevState => value ?? !prevState);
				break;
			case ControlsActions.IS_DARK_MODE:
				setIsDarkMode(prevState => value ?? !prevState);
				break;
			case ControlsActions.IS_MULTI_BED:
				setIsMultiBed(prevState => value ?? !prevState);
				break;
			case ControlsActions.TOGGLE_MORE_PARTICIPANTS:
				setIsMoreParticipantsViewOpen(prevState => {
					const updatedState = value ?? !prevState;
					controlsObservable.fire({ key: ControlsActions.TOGGLE_MORE_PARTICIPANTS, value: updatedState });
					return updatedState;
				});
				break;
			case ControlsActions.TOGGLE_WHITEBOARD:
				if (
					!getRoleConfigurationValue(roundingConfigurations, RoundingSettings.Whiteboard) &&
					!getRoleConfigurationValue(roundingConfigurations, RoundingSettings.DisplayControl)
				) {
					return;
				}
				setIsWhiteboardVisible(prevState => value ?? !prevState);
				if (value && isHealthMeasurementsVisible) {
					setIsHealthMeasurementsVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_HEALTH_MEASUREMENTS, value: false });
				}
				if (value && isCameraMeasurementsVisible) {
					setIsCameraMeasurementsVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_CAMERA_MEASUREMENTS, value: false });
				}
				if (isPatientHistoryOpen) {
					setIsPatientHistoryOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_PATIENT_HISTORY, value: false });
				}
				if (isLiveExaminationOpen) {
					setIsLiveExaminationOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_LIVE_EXAMINATIONS, value: false });
				}
				if (isAiOpen) {
					setIsAiOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_AI, value: false });
				}
				break;
			case ControlsActions.TOGGLE_AI:
				setIsAiOpen(prevState => value ?? !prevState);
				if (value && isHealthMeasurementsVisible) {
					setIsHealthMeasurementsVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_HEALTH_MEASUREMENTS, value: false });
				}
				if (value && isCameraMeasurementsVisible) {
					setIsCameraMeasurementsVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_CAMERA_MEASUREMENTS, value: false });
				}
				if (isPatientHistoryOpen) {
					setIsPatientHistoryOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_PATIENT_HISTORY, value: false });
				}
				if (isLiveExaminationOpen) {
					setIsLiveExaminationOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_LIVE_EXAMINATIONS, value: false });
				}
				if (isWhiteboardVisible) {
					setIsWhiteboardVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_WHITEBOARD, value: false });
				}
				if (isRoomSignOpen) {
					setIsAiOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_ROOM_SIGN, value: false });
				}
				break;
			case ControlsActions.TOGGLE_ROOM_SIGN:
				if (!getRoleConfigurationValue(roundingConfigurations, RoundingSettings.RoomSign)) {
					return;
				}
				setIsRoomSignOpen(prevState => value ?? !prevState);
				if (value && isHealthMeasurementsVisible) {
					setIsHealthMeasurementsVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_HEALTH_MEASUREMENTS, value: false });
				}
				if (value && isCameraMeasurementsVisible) {
					setIsCameraMeasurementsVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_CAMERA_MEASUREMENTS, value: false });
				}
				if (isPatientHistoryOpen) {
					setIsPatientHistoryOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_PATIENT_HISTORY, value: false });
				}
				if (isLiveExaminationOpen) {
					setIsLiveExaminationOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_LIVE_EXAMINATIONS, value: false });
				}
				if (isWhiteboardVisible) {
					setIsWhiteboardVisible(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_WHITEBOARD, value: false });
				}
				if (isAiOpen) {
					setIsAiOpen(false);
					controlsObservable.fire({ key: ControlsActions.TOGGLE_AI, value: false });
				}
				break;
			default:
				break;
		}
	};

	const onSetCategoryConfigurationsAction = (action, value) => {
		switch (action) {
			case ControlsActions.SET_ROUNDING_CONFIGURATIONS:
				setRoundingConfigurations(value);
				break;
			case ControlsActions.SET_CUSTOM_DISPLAY_NAMES:
				setCustomDisplayNames(value);
				break;
			default:
				break;
		}
	};

	/**
	 * @param {object} data
	 */
	const onHealthDataToggleAction = data => {
		Object.entries(data).forEach(([action, value]) => {
			onConfigurationToggleAction(action, value);
		});
	};

	/**
	 * @param {AIControls} data
	 */
	const onAIControlsToggleAction = data => {
		setAIControls(prevState => ({
			isObjectDetectionOn: data.isObjectDetectionOn ?? prevState.isObjectDetectionOn,
			isBabyDetectionOn: data.isBabyDetectionOn ?? prevState.isBabyDetectionOn,
			isRailsDetectionOn: data.isRailsDetectionOn ?? prevState.isRailsDetectionOn,
			isSkeletonDetectionOn: data.isSkeletonDetectionOn ?? prevState.isSkeletonDetectionOn,
			isPersonDetectionOn: data.isPersonDetectionOn ?? prevState.isPersonDetectionOn,
			isAiAlertOn: data.isAiAlertOn ?? prevState.isAiAlertOn,
			isPrivateModeOn: data.isPrivateModeOn ?? prevState.isPrivateModeOn,
			isMultiSkeletonDetectionOn: data.isMultiSkeletonDetectionOn ?? prevState.isMultiSkeletonDetectionOn,
		}));
	};

	const setPinnedParticipantId = participantId => {
		setPinnedParticipantIdInState(participantId);
		pinnedParticipantIdRef.current = participantId;
	};

	return (
		<ConferenceConfigurationsCtx.Provider
			value={{
				isGridView,
				isVirtualBackgroundModalOpen,
				isStreamSettingsModalOpen,
				isFitScreen,
				isMainParticipantPortraitVideo,
				isCameraControlsOpen,
				isLiveExaminationOpen,
				isTelemetryModalOpen,
				isInviteParticipantsModalViewOpen,
				isMediaBlockedPopupOpen,
				isParticipantsViewHidden,
				showPatientMeasurementsButtons,
				isHealthMeasurementsVisible,
				isCameraMeasurementsVisible,
				medicalDataControls,
				aiControls,
				controlsObservable,
				visitId,
				sinkId,
				pinnedParticipantId,
				pinnedParticipantIdRef,
				isTransferToDoctorModalOpen,
				isCECSupportedModalOpen,
				isMinimizedView,
				isEmbeddedView,
				featureFlags,
				isPatientHistoryOpen,
				isRightToLeft,
				isDarkMode,
				customDisplayNames,
				roundingConfigurations,
				roomType,
				tvVolumeRange,
				conferenceErrorMessages,
				isLiveCaptionsOpen,
				isTransferOwnershipModalOpen,
				removeParticipantModal,
				isMultiBed,
				isWhiteboardVisible,
				isAiOpen,
				isRoomSignOpen,
				setIsLiveCaptionsOpen,
				gridCount,
				isMoreParticipantsViewOpen,
				speechToTextMessages,
				speechToTextParticipants,
				invitedParticipants,
				backgroundImages,
				setBackgroundImages,
				setInvitedParticipants,
				setVoyceInterpreterRequestId,
				voyceInterpreterRequestId,
				setInterpreterJoinedTime,
				interpreterJoinedTime,
				patientInfo,
				micMutedByOwnerMessage,
				setMicMutedByOwnerMessage,
				interpreterBaseUrl,
				setInterpreterBaseUrl,
				setSpeechToTextParticipants,
				setSpeechToTextMessages,
				pushSpeechToTextParticipant,
				removeSpeechToTextParticipant,
				pushMessage,
				onSetCategoryConfigurationsAction,
				onConfigurationToggleAction,
				onHealthDataToggleAction,
				onAIControlsToggleAction,
				setVisitId,
				setSinkId,
				setPinnedParticipantId,
				setConferenceErrorMessages,
				setRoomType,
				setIsFitScreen,
				setIsMainParticipantPortraitVideo,
				setTvVolumeRange,
				setIsInviteParticipantsModalViewOpen,
				setRemoveParticipantModal,
				setIsTransferOwnershipModalopen,
				setGridCount,
				setPatientInfo,
			}}>
			{children}
		</ConferenceConfigurationsCtx.Provider>
	);
};

export { ConferenceConfigurationsCtx, ConferenceConfigurationsProvider };
