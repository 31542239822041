const saintPetersBaseUrl = 'https://solprodmediastorage.blob.core.windows.net/static-assets/saint-peters/';

export const BradenScore = {
	Code: '9503944',
	Display: 'Braden Score'
};

export const initialRoomSignInfo = [
	{ key: 'HI', name: 'Hearing IMPAIRED', order: 28 },
];

export const initialIsolations = [
	{ key: 'ABP', name: 'AIRBORNE PRECAUTIONS', label: 'Airborne', order: 2 },
	{ key: 'CON', name: 'CONTACT', label: 'Contact (MDRO)', order: 5 },
	{ key: 'CNTCDIFF', name: 'CONTACT C.DIFF', label: 'Contact (C.DIFF)', order: null },
	{ key: 'CNTCAND', name: 'CONTACT CANDIDA AURIS+ & CANDIDA AURIS R/O', label: 'Contact', order: null },
	{ key: 'DRLPR', name: 'Droplet Precautions', label: 'Droplet', order: 3 },
	{ key: 'NTRPC', name: 'NEUTROPENIC', label: 'Neutropenic', order: 8 },
];

export const intialCareConsiderations = [
	{ key: 'NPO', name: 'NOTHING BY MOUTH', label: 'NPO', src: `${saintPetersBaseUrl}NPO.svg`, order: 13 },
	{ key: 'SZ', name: 'SEIZURE', label: 'Seizure', src: `${saintPetersBaseUrl}seizurePrecautions.svg`, order: 25 },
	{ key: 'LMBALRT', name: 'Limb Alert', label: 'Limb Alert', src: `${saintPetersBaseUrl}limbAlert.svg`, order: null },
	{ key: 'NMALRT', name: 'Name Alert', label: 'Name Alert', src: `${saintPetersBaseUrl}nameAlert.svg`, order: null },
	{ key: 'FR', name: 'Fall', label: 'Fall Risk', src: `${saintPetersBaseUrl}fallRisk.svg`, order: 10 },
	{ key: 'STRICTIO', name: 'Strict I&O', label: 'Strict I&O', src: `${saintPetersBaseUrl}strictI&O.svg`, order: null },
	{ key: 'DLWEIGHT', name: 'Daily Weights', label: 'Daily Weights', src: `${saintPetersBaseUrl}dailyWeights.svg`, order: null },
	{ key: 'TRNQ2H', name: 'Turn Q 2 Hours', label: 'Turn Q 2 Hours', src: `${saintPetersBaseUrl}turnQ2Hours.svg`, order: null },
	{ key: 'IMPV', name: 'Impaired Vision', label: 'Impaired Vision', src: `${saintPetersBaseUrl}impairedVision.svg`, order: null },
	{ key: 'OIRDR', name: 'OIRD Risk', label: 'OIRD Risk', src: `${saintPetersBaseUrl}oirdRisk.svg`, order: null },
	{ key: 'ASP', name: 'ASPIRATION', label: 'Aspiration', src: `${saintPetersBaseUrl}aspirationPrecautions.svg`, order: 16 },
	{ key: 'FOLEY', name: 'Foley', label: 'Foley', src: `${saintPetersBaseUrl}foley.svg`, order: null },
	{ key: 'CNTRLLN', name: 'Central Line', label: 'Central Line', src: `${saintPetersBaseUrl}centralLine.svg`, order: null },
	{ key: 'GNDRALRT', name: 'Gender Alert', label: 'Gender Alert', src: `${saintPetersBaseUrl}gender.svg`, order: null },
];