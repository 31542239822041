const CompanyDetailsIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} viewBox='0 0 24 24' fill='none' {...props}>
		<path
			d='M15 11V4C15 3.44772 14.5523 3 14 3H6C5.44772 3 5 3.44772 5 4V16C5 16.5523 5.44772 17 6 17H11'
			stroke='currentColor'
		/>
		<path d='M3 17H11' stroke='currentColor' />
		<path d='M7 5H9.5V7H7V5Z' fill='currentColor' />
		<path d='M7 8H9.5V10H7V8Z' fill='currentColor' />
		<path d='M7 11H9.5V13H7V11Z' fill='currentColor' />
		<path d='M10.5 5H13V7H10.5V5Z' fill='currentColor' />
		<path d='M10.5 8H13V10H10.5V8Z' fill='currentColor' />
		<path d='M13 15H17M13 17H15.5M13 13H17' stroke='currentColor' strokeLinecap='round' />
		<rect x={11} y={11} width={8} height={8} rx={1} stroke='currentColor' />
	</svg>
);
export default CompanyDetailsIcon;
