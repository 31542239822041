const TvProtocols = {
	NO_TV: -2,
	UNKNOWN: -1,
	EX_LINK: 0,
	MPI: 1,
	CEC: 2,
	TELEHEALTH: 3,
	GET_WELL: 4,
	P_CARE: 5,
	E_VIDEON: 6,
};

export default TvProtocols;
