import React, { useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import CustomDropdown from 'components/CustomDropdown.jsx';
import SpeechToText from 'SpeechToText.jsx';
import Textarea from 'components/Textarea.jsx';
import { DietTypesId } from 'constants/enums.js';
import { dietPlans } from 'constants/monitoring.js';
import Button from 'components/Button.jsx';
import ActionButtons from 'containers/Monitoring/Whiteboard/ActionButtons.jsx';

const handleRecognizedTranscription = (item, recognizedTranscriptionKeys) => {
	let commentValue = '';
	if (recognizedTranscriptionKeys?.[item.value]) {
		commentValue = recognizedTranscriptionKeys[item.value];
	}
	return commentValue;
};

const renderDiets = (reducedDiets, diets) => {
	return reducedDiets.map((item, index) => {
		let valueToShow = item.label;
		if (item.id === DietTypesId.OTHER) {
			const otherDiet = diets?.find(item => item.value === DietTypesId.OTHER.toString());
			valueToShow = otherDiet?.comment ?? '';
		}

		return (
			<React.Fragment key={item.id}>
				{valueToShow}
				{index < diets?.length - 1 ? ', ' : ''}
			</React.Fragment>
		);
	});
};

const Diet = props => {
	const [expanded, setExpanded] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);
	const [dataToEdit, setDataToEdit] = useState(props.diet);
	const [recognizedTranscription, setRecognizedTranscription] = useState('');
	const [liveTranscription, setLiveTranscription] = useState('');
	const [isSpeechToText, setIsSpeechToText] = useState(false);

	const [otherOptionRecognizedTranscription, setOtherOptionRecognizedTranscription] = useState('');
	const [otherOptionLiveTranscription, setOtherOptionLiveTranscription] = useState('');
	const [isOtherOptionSpeechToText, setOtherOptionIsSpeechToText] = useState(false);

	const intl = useIntl();

	const recognizedTranscriptionKeys = {
		[DietTypesId.NPO.toString()]: recognizedTranscription,
		[DietTypesId.OTHER.toString()]: otherOptionRecognizedTranscription,
	};

	useEffect(() => {
		setDataToEdit(props.diet);
		const found = props.diet?.find(item => item.value === DietTypesId.NPO.toString() && item.comment);
		if (found) {
			setRecognizedTranscription(found.comment);
		}

		const foundOtherOption = props.diet?.find(item => item.value === DietTypesId.OTHER.toString() && item.comment);
		if (foundOtherOption) {
			setOtherOptionRecognizedTranscription(foundOtherOption.comment);
		}
	}, [props.diet]);

	useEffect(() => {
		setDataToEdit(prevState =>
			prevState.map(item => ({
				value: item.value,
				comment: handleRecognizedTranscription(item, recognizedTranscriptionKeys),
			}))
		);
	}, [recognizedTranscription, otherOptionRecognizedTranscription]);

	const handleSelect = selectedOptions => {
		setDataToEdit(
			selectedOptions.map(item => ({
				value: item.value,
				comment: handleRecognizedTranscription(item, recognizedTranscriptionKeys),
			}))
		);

		if (!selectedOptions.find(item => item.value === DietTypesId.NPO.toString())) {
			setRecognizedTranscription('');
		}

		if (!selectedOptions.find(item => item.value === DietTypesId.OTHER.toString())) {
			setOtherOptionRecognizedTranscription('');
		}
	};

	const reducedList = () =>
		props.diet.reduce((acc, curr) => {
			const dietItem = dietPlans.find(d => d.id.toString() === curr.value);
			if (dietItem) {
				acc.push(dietItem);
			}

			return acc;
		}, []);

	return (
		<>
			<div className={classNames('timeline-box-header sub-timeline-box-header', expanded ? 'expanded' : '')}>
				<p className='timeline-box-title'>{translate('diet')}</p>
				<div className='timeline-box-actions'>
					<ActionButtons
						handleEditClick={() => {
							setShowEditForm(prevState => !prevState);
							setExpanded(true);
						}}
						handleExpandClick={() => setExpanded(prevState => !prevState)}
						expanded={expanded}
					/>
				</div>
			</div>
			{expanded && (
				<>
					{!showEditForm && (
						<table className='patient-table-informations' style={{ width: '100%' }}>
							<tbody>
								<tr>
									<td>{translate('diet')}</td>
									<td>{renderDiets(reducedList(), props.diet)}</td>
								</tr>
								{props.diet.find(item => item.value === DietTypesId.NPO.toString()) && (
									<tr>
										<td>{translate('comment')}</td>
										<td>{props.diet.find(item => item.value === DietTypesId.NPO.toString()).comment}</td>
									</tr>
								)}
							</tbody>
						</table>
					)}
					{showEditForm && (
						<div className='flex flex-space-between flex-wrap whiteboard-select'>
							<CustomDropdown
								defaultOptions={
									dataToEdit.length > 0
										? dataToEdit.map(item => ({
												value: item.value,
												label: item.value,
										  }))
										: []
								}
								initialOptions={dietPlans.map(item => ({
									value: item.id.toString(),
									label: item.label,
								}))}
								onSelect={handleSelect}
								title={intl.formatMessage({ id: 'diet' })}
								placeholder=''
								showTitleInPlaceholder={true}
							/>
							{dataToEdit.find(item => item.value === DietTypesId.NPO.toString()) && (
								<Textarea
									label={`NPO ${intl.formatMessage({ id: 'comment' })}`}
									labelClassName='npo-label'
									maxLength={1000}
									onChange={event => {
										setRecognizedTranscription(event.target.value);
									}}
									value={isSpeechToText ? `${recognizedTranscription} ${liveTranscription}`.trim() : recognizedTranscription}
									rows={2}
									name='text'
									placeholder={intl.formatMessage({ id: 'comment' })}
									className='full-width top-15'
									icon={
										<SpeechToText
											setRecognizedTranscription={setRecognizedTranscription}
											setLiveTranscription={setLiveTranscription}
											setIsSpeechToText={setIsSpeechToText}
											isSpeechToText={isSpeechToText}
										/>
									}
								/>
							)}
							{dataToEdit.find(item => item.value === DietTypesId.OTHER.toString()) && (
								<Textarea
									label={intl.formatMessage({ id: 'other' })}
									labelClassName='npo-label'
									maxLength={1000}
									onChange={event => {
										setOtherOptionRecognizedTranscription(event.target.value);
									}}
									value={
										isOtherOptionSpeechToText
											? `${otherOptionRecognizedTranscription} ${otherOptionLiveTranscription}`.trim()
											: otherOptionRecognizedTranscription
									}
									rows={2}
									name='text'
									placeholder={intl.formatMessage({ id: 'additionalDiet' })}
									className='full-width top-15'
									icon={
										<SpeechToText
											setRecognizedTranscription={setOtherOptionRecognizedTranscription}
											setLiveTranscription={setOtherOptionLiveTranscription}
											setIsSpeechToText={setOtherOptionIsSpeechToText}
											isSpeechToText={isOtherOptionSpeechToText}
										/>
									}
								/>
							)}

							<Button
								className={classNames('button', props.isSubmitButtonLoading ? 'loading' : '')}
								onClick={() => props.setData(dataToEdit)}
								text={translate('save')}
							/>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default Diet;
