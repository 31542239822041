
import { DoctorActionTypes } from 'constants/action-types.js';

export const actionCreators = {
	setSelectedDoctor: doctor => dispatch => {
		dispatch({ type: DoctorActionTypes.SET_SELECTED_DOCTOR, payload: doctor });
	},
	setDoctors: doctors => dispatch => {
		dispatch({ type: DoctorActionTypes.SET_DOCTORS, payload: doctors });
	},
	setIsRequestUpdated: isRequestUpdated => dispatch => {
		dispatch({ type: DoctorActionTypes.SET_IS_REQUEST_UPDATED, payload: isRequestUpdated });
	},
};
