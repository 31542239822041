import { ConferenceInfo, ConferenceParticipant, FromUser } from 'owt/base/conference.js';
import React, { Component } from 'react';
import _ from 'lodash';
import Grid from 'components/Grid.jsx';
import Dropdown from 'components/Dropdown.jsx';
import ListGroup from 'components/ListGroup.jsx';
import TopParticipant from 'components/TopParticipant.jsx';
import Participant from 'components/Participant.jsx';
import CallEndReason from 'components/CallEndReason.jsx';
import ActiveConference from 'components/Call/ActiveConference.jsx';
import AnotherNursePickedUp from 'components/Call/AnotherNursePickedUp.jsx';
import ConnectionStatus from 'components/Call/ConnectionStatus.jsx';
import StreamPermissions from 'components/StreamPermissions.jsx';
import Loader from 'components/Loader.jsx';
import CallManager from 'owt/p2p/CallManager.js';
import {
	CallTypes,
	ObjectType,
	ConferenceEndReason,
	RTCPeerConnectionEnum,
	CameraType,
	CameraEventTypes,
	CameraTiltDirection,
	CameraTiltAction,
	ZoomDirection,
	SerialTVCommands,
	SocketState,
	UserRoles,
	MedicalInfoTypes,
	RequestStatus,
	AppointmentStatus,
	MeasuringDevices,
	IotDeviceState,
	ConversationType,
} from 'constants/enums.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { TvControls } from 'containers/TvControls.jsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as HealthSystemActionCreators } from 'state/healthSystems/actions.js';
import { v4 } from 'uuid';
import CameraControls from 'containers/CameraControls.jsx';
import CallDuration from 'containers/CallDuration.jsx';
import NightVisionControl from 'containers/NightVisionControl.jsx';
import { stopOutgoingCallSound } from 'components/CallSounds.jsx';
import { getStorage, takeScreenshot } from 'infrastructure/helpers/commonHelpers.js';
import AIControls from 'containers/AIControls.jsx';
import { getDeviceOwnerPatient } from 'api/patients.js';
import classNames from 'classnames';
import CallCorrelationInfo from 'components/CallCorrelationInfo.jsx';
import { APP_CONFIG, healthCareCdnUrl } from 'constants/global-variables.js';
import DropupItem from 'components/DropupItem.jsx';
import Dropup from 'components/Dropup.jsx';
import PeerStats from 'containers/PeerStats.jsx';
import HealthMeasurements from 'containers/HealthMeasurementTabs/HealthMeasurements.jsx';
import OldCameraMeasurements from 'containers/HealthMeasurementTabs/OldCameraMeasurements.jsx';
import SocketEvents from 'constants/socket-events.js';
import translate from 'i18n-translations/translate.jsx';
import { injectIntl } from 'react-intl';
import FooterButton from 'components/FooterButton.jsx';
import { getUserInfo, getUserRole } from 'infrastructure/auth.js';
import { getUserProfiles } from 'api/users.js';
import Alert from 'components/Alert.jsx';
import { buildProfilePicUrl, onErrorInitialsSrc } from 'infrastructure/helpers/thumbnailHelper.js';
import Conversation from 'views/Chat/Conversation.jsx';
import { getConversationByType } from 'api/messenger.js';
import AddDiagnosesOrProcedures from 'containers/AddDiagnosesOrProcedures.jsx';
import AddNotes from 'containers/AddNotes.jsx';
import PrescriptionsWrapper from 'containers/Prescriptions/PrescriptionsWrapper.jsx';
import { createVisit, deactivateVisit, saveVisitEndDateTime } from 'api/medicalInfo.js';
import CallWrapUp from 'containers/CallWrapUp.jsx';
import { getRequest, updateAssignRequest } from 'api/patientRequests.js';
import { reassignRequestNextMedic } from 'api/doctorRequests.js';
import Modal from 'components/Modal.jsx';
import queryString from 'query-string';
import { updateAppointmentStatus } from 'api/appointments.js';
import AddPTReport from 'containers/AddPTReport.jsx';
import Button from 'components/Button.jsx';

/**
 * @deprecated Use calls/views/Conference.js instead
 */
export class Call extends Component {
	constructor(props, context) {
		super(props, context);
		this.callManager = new CallManager(this.context, {
			useCallStats: APP_CONFIG.useCallStats,
			sendCallStatsInterval: APP_CONFIG.sendCallStatsInterval,
		});

		this.detectionsCanvasRef = React.createRef();
		this.videoRef = React.createRef();
	}

	state = {
		callerObjectId: parseInt(this.props.match.params.patientId, 10),
		isWaitingRoom: !!queryString.parse(window.location.search).assignedRequestId,
		localSrc: null,
		localScreenSrc: null,
		peerSrc: null,
		activeSrc: null,
		micActive: true,
		isMyVideoCamActive: true,
		isPeerVideoCamActive: true,
		isVideoCall: false,
		peerMicActive: true,
		showPTZ: false,
		conversationId: queryString.parse(window.location.search).conversationId || null,
		participantId: null,
		initialCallType: null,
		streamPermission: null,
		tvStatus: null,
		hdmiStatus: null,
		volumeStatus: null,
		nightVisionMode: false,
		cameraZoomLevel: 0,
		cameraType: CameraType.HELLO,
		isHuddleCamConnected: true,
		disabledTiltDirections: {},
		disableButtons: false,
		isHealthMeasurementsVisible: false,
		isCameraMeasurementsVisible: false,
		topParticipantStaticImage: null,
		objectType: queryString.parse(window.location.search).conversationId ? ObjectType.USER : ObjectType.HELLO_DEVICE,
		interlocutorId: null,
		isAIControlsActive: false,
		conferenceEndReason: null,
		socketState: SocketState.CONNECTED,
		callStartTime: null,
		peerConnectionState: RTCPeerConnectionEnum.CONNECTION_STATE.NEW,
		hasActiveConference: false,
		anotherNursePickedUp: false,
		conferenceId: null,
		activeAIControlTool: {},
		isWebToWebCall: false,
		myLocalPeerMic: true,
		myLocalPeerVideo: true,
		isFitScreenOn: !!queryString.parse(window.location.search).conversationId,
		error: null,
		participantProfilePic: '',
		hasCameraControlsClass: true,
		hasTiltClass: false,
		peerScreenSrc: {},
		isConversationModalVisible: false,
		conferenceName: null,
		isDiagnosesVisible: false,
		isProceduresVisible: false,
		isNotesVisible: false,
		isPrescriptionsVisible: false,
		visitId: null,
		notes: [],
		diagnoses: [],
		procedures: [],
		prescriptions: [],
		myAvatarUrl: '',
		didMedicJoin: false,
		isTransferToDoctorModalOpen: false,
		doctorToTransfer: null,
		assignedRequestId: queryString.parse(window.location.search).assignedRequestId,
		patientId: parseInt(this.props.match.params.patientId, 10),
		isOverviewOpen: false,
		helloDevice: null,
		isRequestAccepted: false,
		isPhysicalExercisesVisible: false,
	};

	stethoArr = [];

	userProfile = getUserInfo();

	userRole = getUserRole();

	videoRef = null;

	detectionsCanvasRef = null;

	// @ts-ignore
	audioCtx = new (window.AudioContext || window.webkitAudioContext)();

	queuedSoundBuffers = new Map();

	isSoundPlaying = false;

	async componentDidMount() {
		this.getAssignedRequest();
		const conferenceName = decodeURIComponent(this.props.match.params.conferenceName);
		let patientId = this.userRole === UserRoles.PATIENT ? this.userProfile.userId : this.state.callerObjectId;
		let doctorId = this.userRole === UserRoles.DOCTOR ? this.userProfile.userId : this.state.callerObjectId;
		let interlocutorId = this.userRole === UserRoles.DOCTOR ? this.props.match.params.patientId : this.userProfile.userId;
		let interlocutorName = conferenceName;
		// web to hello calls
		if (this.state.objectType === ObjectType.HELLO_DEVICE && !this.state.isWaitingRoom) {
			let userId = null,
				profilePicture = '',
				fullName = '';

			const deviceOwnerResponse = await getDeviceOwnerPatient(this.state.callerObjectId);
			if (deviceOwnerResponse.error) {
				this.setState({ error: deviceOwnerResponse.error.message });
			} else {
				userId = deviceOwnerResponse.userId;
				profilePicture = deviceOwnerResponse.profilePicture;
				fullName = deviceOwnerResponse.fullName;
			}
			doctorId = this.userProfile.userId;
			patientId = userId;
			interlocutorId = userId;
			interlocutorName = fullName;
			const { conversation } = await getConversationByType(ConversationType.INDIVIDUAL, interlocutorId, 0, false);
			this.setState({ participantProfilePic: profilePicture, interlocutorName, conversationId: conversation.id, patientId });
		}
		if (this.state.objectType !== ObjectType.HELLO_DEVICE) {
			this.setParticipantProfilePic();
		}
		this.setState({
			interlocutorId,
			interlocutorName,
			conferenceName,
			myAvatarUrl: this.userProfile.profilePicture,
			patientId,
			doctorId,
		});
		this.callManager
			.on('call-started', this.handleCallStarted)
			.on('audio-call', this.handleOnAudioCall)
			.on('video-call', this.handleOnVideoCall)
			.on('participant-toggled-streams', this.handleParticipantToggledStreams)
			.on('screensharing', this.handleScreenSharing)
			.on('toggle-audio', this.handleToggleAudio)
			.on('toggle-video', this.handleToggleVideo)
			.on('peer-stream', this.handlePeerStream)
			.on('end-call', this.handleEndCall)
			.on('stream-permission', this.handlePeerStreamPermission)
			.on('tv-commands', this.onTvCommands)
			.on('initial-state', this.handleInitialCameraState)
			.on('camera-response', this.cameraResponseListener)
			.on('socket-state', this.onSocketState)
			.on('peer-connection-state', this.handlePeerConnection)
			.on('camera-response', this.cameraResponseListener)
			.on('event-failure', this.handleEventFailure)
			.on('iot-toast-messages', this.toastMessageHandler)
			.bindSocketEventListeners();
		this.bindWindowListeners();
		if (this.props.match.params.incoming === 'start') {
			this.startConference();
		} else {
			this.joinConference();
		}
		this.context.on(SocketEvents.HelloDevice.TOGGLE_HEALTH_DATA, this.toggleHealthDataLocally);
		this.context.on(SocketEvents.Conference.ON_CAMERA_MEASUREMENTS_TOGGLE, this.toggleCameraMeasurementsLocally);
		this.context.on(SocketEvents.Conference.ON_PARTICIPANT_LEFT, this.handleParticipantLeft);
		this.context.on(SocketEvents.HealthCare.ON_NEW_NOTIFICATION, this.handleRequestUpdated);
	}

	componentWillUnmount() {
		this.context.off(SocketEvents.HelloDevice.TOGGLE_HEALTH_DATA, this.toggleHealthDataLocally);
		this.context.off(SocketEvents.Conference.ON_CAMERA_MEASUREMENTS_TOGGLE, this.toggleCameraMeasurementsLocally);
		this.context.off(SocketEvents.HealthCare.ON_NEW_NOTIFICATION, this.handleRequestUpdated);
		this.callManager.unbindSocketEventListeners();
		this.callManager.unbindTimeouts();
		if (this.closeTabTimeout) {
			clearTimeout(this.closeTabTimeout);
			this.closeTabTimeout = null;
		}
	}

	handleToggleAudio = () => {
		this.setState(prevState => ({ micActive: !prevState.micActive }));
	};

	handlePeerStreamPermission = streamPermission => {
		this.setState({ streamPermission });
	};

	handleEventFailure = data => {
		console.error('Event failure', data);
	};

	handlePeerConnection = peerConnectionState => {
		this.setState({ peerConnectionState }, () => {
			this.setAudioSrcObject();
		});
	};

	handleRequestUpdated = data => {
		const request = JSON.parse(data.payload);
		if (this.state.assignedRequestId === request.Id && request.RequestStatusId === RequestStatus.REJECTED) {
			const medicWhoCanceled = `${request.Medic.FirstName} ${request.Medic.LastName}`;
			this.setState({ medicWhoCanceled });
		}
	};

	getAssignedRequest = async () => {
		if ([UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(this.userRole) && this.state.isWaitingRoom) {
			const response = await getRequest(this.state.assignedRequestId);
			if (response.error) {
				this.setState({ error: response.error.message });
			} else {
				const { assignedRequest } = response;
				this.setState({
					symptoms: assignedRequest.request.requestSymptoms,
					doctorToTransfer: assignedRequest.nextMedic,
				});
			}
		}
	};

	handleOnVideoCall = data => {
		this.setState({
			localSrc: data.videoStream,
			activeSrc: data.videoStream,
			isMyVideoCamActive: true,
			isPeerVideoCamActive: true,
		});
	};

	handleOnAudioCall = data => {
		this.setState({
			localSrc: data.audioStream,
			activeSrc: data.audioStream,
			isMyVideoCamActive: false,
			isPeerVideoCamActive: false,
		});
	};

	handleCallStarted = ({ participantId, conferenceId, helloDevice }) => {
		this.setState({
			conferenceId,
			participantId,
			helloDevice,
		});
	};

	handleParticipantToggledStreams = data => {
		let video, audio;
		const newState = {};
		// participant toggled their streams
		if (data.hasAudio !== undefined) {
			const { hasVideo, hasAudio } = data;
			video = hasVideo;
			audio = hasAudio;
		} else {
			const { isVideo, isAudio } = data;
			video = isVideo;
			audio = isAudio;
		}

		Object.assign(newState, { isPeerVideoCamActive: video, peerMicActive: audio });

		if (!_.isEmpty(this.state.peerScreenSrc) && !data.isScreen) {
			Object.assign(newState, { peerScreenSrc: {}, activeSrc: this.state.peerSrc });
		}

		this.setState(newState);
	};

	handleScreenSharing = data => {
		const { localSrc, localScreenSrc } = data;
		let { activeSrc } = data;
		const { peerSrc } = this.state;
		if (localScreenSrc === null && !!peerSrc.mediaStream.getVideoTracks().length) {
			activeSrc = peerSrc;
		}
		this.setState({
			localSrc: localSrc,
			activeSrc: activeSrc,
			localScreenSrc: localScreenSrc,
		});
	};

	cancelRequest = () => {
		if (this.state.didMedicJoin) {
			return;
		}
		this.updateRequest(RequestStatus.CANCELED);
	};

	handleEndCall = data => {
		if (!this.state.visitId) {
			// updatestatus
			this.cancelRequest();
			this.startCloseTabTimeout();
		}
		this.setState({
			localSrc: null,
			localScreenSrc: null,
			peerSrc: null,
			activeSrc: null,
			conferenceEndReason: data.endReason,
			hasActiveConference: data.hasActiveConference,
			anotherNursePickedUp: data.anotherNursePickedUp,
			conferenceId: null,
		});
	};

	handleInitialCameraState = data => {
		if (!data) {
			return;
		}
		const { cameraType, zoomLevel, isNightVision, isHuddleCamConnected } = data;
		this.setState({
			cameraType,
			cameraZoomLevel: zoomLevel,
			nightVisionMode: isNightVision,
			isHuddleCamConnected,
		});
	};

	handleToggleVideo = data => {
		this.setState(
			prevState => ({ isVideoCall: data.hasVideoTrack ? true : prevState.isVideoCall, isMyVideoCamActive: data.hasVideoTrack }),
			() => {
				const { isMyVideoCamActive, isPeerVideoCamActive, peerSrc, localSrc } = this.state;

				if (!isMyVideoCamActive && isPeerVideoCamActive && peerSrc) {
					// Switch to patient if i have toggled my camera off
					this.activeSrcHandle(this.state.peerSrc.id);
				} else if (isMyVideoCamActive && localSrc && (this.isMyVideoSelected() || !isPeerVideoCamActive)) {
					// Show my video as active
					this.activeSrcHandle(this.state.localSrc.id);
				}
			}
		);
	};

	handlePeerStream = data => {
		const { activeSrc, trackTypes, peerSrc } = data;
		const newState = {};
		if (this.state.isPeerVideoCamActive) {
			Object.assign(newState, { peerSrc, activeSrc, isVideoCall: true });
		} else {
			Object.assign(newState, { peerSrc });
		}
		if (!this.state.callStartTime) {
			Object.assign(newState, { callStartTime: new Date() });
		}

		const peerScreenTrackData = trackTypes.find(track => track.type === 'screen');
		if (peerScreenTrackData) {
			const { track } = peerScreenTrackData;
			const peerScreenSrc = { id: track.id, mediaStream: new MediaStream([track]) };
			const peerVideoCamTrackData = trackTypes.find(t => t.type === 'video');
			if (peerVideoCamTrackData && this.state.isPeerVideoCamActive) {
				peerSrc.mediaStream = new MediaStream([peerVideoCamTrackData.track]);
				Object.assign(newState, { peerSrc });
			}
			if (_.isEmpty(this.state.peerScreenSrc)) {
				Object.assign(newState, { peerScreenSrc, activeSrc: peerScreenSrc });
			}
		}

		// peerSrc.mediaStream.id === 'ARDAMS' is an indicator that call came from hello or other clients (IOS, Android)
		Object.assign(newState, { isWebToWebCall: peerSrc.mediaStream.id !== 'ARDAMS', didMedicJoin: true });
		stopOutgoingCallSound();
		this.setState(newState, () => {
			if (this.audioRef) {
				this.audioRef.srcObject = null;
				this.audioRef.srcObject = peerSrc.mediaStream;
			}
		});
		if (!this.state.visitId) {
			this.getVisitId(this.state.patientId, this.state.doctorId);
		}
	};

	onSocketState = socketState => {
		if (this.state.socketState.type === socketState.type) {
			return;
		}

		const newState = { socketState };
		switch (socketState.type) {
			case SocketState.DISCONNECTED.type:
				Object.assign(newState, {
					disableButtons: true,
				});
				break;
			case SocketState.CONNECTED.type:
				Object.assign(newState, {
					disableButtons: false,
				});
				break;
			default:
				break;
		}

		this.setState(newState, () => {
			this.setAudioSrcObject();
		});
	};

	onTvCommands = data => {
		if (data.tvState.isVolume) {
			this.setState({ volumeStatus: data });
			return;
		}

		switch (data.tvState.tvStatus) {
			case SerialTVCommands.INITIAL_TV_POWER:
			case SerialTVCommands.POWER.POWER_ON:
			case SerialTVCommands.POWER.POWER_OFF:
				this.setState({ tvStatus: data });
				break;
			case SerialTVCommands.HDMI.SWITCH_HDMI1:
			case SerialTVCommands.HDMI.SWITCH_HDMI2:
			case SerialTVCommands.HDMI.SWITCH_HDMI3:
				this.setState({ hdmiStatus: data });
				break;
			default:
				break;
		}
	};

	startConference = () => {
		const isVideo = this.props.match.params.callType && this.props.match.params.callType === 'video';
		if (isVideo) {
			this.setState({
				isVideoCall: false,
				isPeerVideoCamActive: true,
				initialCallType: CallTypes.VIDEO,
			});
		} else {
			this.setState({
				initialCallType: CallTypes.AUDIO,
				isPeerVideoCamActive: false,
			});
		}

		const participants = [
			new ConferenceParticipant(
				!this.state.isWaitingRoom ? this.state.callerObjectId : this.userProfile.userId,
				this.state.objectType,
				this.state.conferenceName
			),
		];
		this.setState({ conferenceId: v4(), participantId: v4() }, async () => {
			const fromUser = new FromUser(
				`${this.userProfile.firstName} ${this.userProfile.lastName}`,
				this.userProfile.jobTitle,
				this.userProfile.profilePicture,
				undefined,
				null,
				this.userProfile.userId,
				this.state.participantId
			);
			const startConferenceInfo = new ConferenceInfo(
				isVideo ? CallTypes.VIDEO : CallTypes.AUDIO, // callType
				this.state.conferenceId, // conferenceId
				this.state.conversationId ? fromUser.name : 'Videocall', // conferenceName
				this.state.conversationId, // conversationId
				fromUser, // from
				true, // isAudio
				!!this.state.conversationId, // isChat
				!this.state.conversationId, // isDirectToHello
				!!this.state.isWaitingRoom, // isMeetingRoom
				!!this.state.isWaitingRoom, // isMultiparty
				false, // isScreen
				isVideo, // isVideo
				this.state.participantId, // participantId
				participants // participants
			);
			this.callManager.startConference(startConferenceInfo);
		});
	};

	joinConference = () => {
		const conferenceInfo = JSON.parse(getStorage().getItem('incomingConferenceInfo'));
		getStorage().removeItem('incomingConferenceInfo');
		if (!conferenceInfo) {
			this.startCloseTabTimeout();
			this.setState({ conferenceEndReason: ConferenceEndReason.ABORTED });
		} else {
			this.callManager.joinConference(conferenceInfo).then(() => this.updateRequest(RequestStatus.ACCEPTED));
		}
	};

	getVisitId = async (patientId, doctorId) => {
		if (this.userRole !== UserRoles.DOCTOR) {
			return;
		}
		const params = { patientId, doctorId, place: this.state.conferenceName };
		const response = await createVisit(params);
		if (response.error) {
			this.setState({ error: response.error.message });
		} else {
			this.setState({ visitId: response.id });
		}
	};

	updateRequest = async requestStatusId => {
		if (!this.state.isWaitingRoom) {
			return;
		}
		const response = await updateAssignRequest(this.state.assignedRequestId, {
			RequestStatusId: requestStatusId,
		});
		if (response.error) {
			this.setState({ error: response.error.message });
		}
		this.setState({
			isRequestAccepted: true,
		});
	};

	handleParticipantLeft = () => {
		this.setState(prevState => ({ peerSrc: null, activeSrc: prevState.localSrc }));
	};

	toggleHealthDataLocally = () => {
		if (this.userRole === UserRoles.PATIENT && this.state.isWaitingRoom) {
			return;
		}
		this.setState(prevState => ({
			isHealthMeasurementsVisible: !prevState.isHealthMeasurementsVisible,
			isOverviewOpen: false,
		}));
	};

	setParticipantProfilePic = async () => {
		const response = await getUserProfiles(this.props.match.params.patientId);
		if (response.error) {
			this.setState({
				error: response.error.message,
			});
		} else {
			this.setState({ participantProfilePic: response.profile.profilePicture });
		}
	};

	toggleCameraMeasurementsLocally = () => {
		this.setState(prevState => ({
			isCameraMeasurementsVisible: !prevState.isCameraMeasurementsVisible,
		}));
	};

	beforeUnloadEvent = event => {
		if (this.state.conferenceId) {
			event.preventDefault();
			// eslint-disable-next-line no-param-reassign
			event.returnValue = '';
		}
	};

	bindWindowListeners() {
		window.addEventListener('beforeunload', this.beforeUnloadEvent);
		window.addEventListener('unload', () => {
			if (this.state.conferenceId) {
				this.callManager.endCall({ endReason: ConferenceEndReason.PARTICIPANT_LEFT });
			}
		});

		window.addEventListener('message', message => {
			if (message.data === 'IN_CALL') {
				window.removeEventListener('beforeunload', this.beforeUnloadEvent);
				this.callManager.endCall({ endReason: ConferenceEndReason.PARTICIPANT_IDLE });
			}
		});
	}

	togglePatientHealthData = () => {
		this.callManager
			.toggleHealthData({
				isEnabled: !this.state.isHealthMeasurementsVisible,
				helloDeviceId: this.state.helloDevice?.objectId,
				conferenceId: this.state.conferenceId,
				participantId: this.state.participantId,
			})
			.then(result => {
				this.setState(prevState => ({
					isHealthMeasurementsVisible: result.isEnabled,
					isCameraMeasurementsVisible: false,
					isConversationModalVisible: !prevState.isOverviewOpen && prevState.isConversationModalVisible,
					isOverviewOpen: false,
				}));
			});
	};

	toggleCameraMeasurements = () => {
		this.callManager
			.toggleCameraMeasurements({
				isEnabled: !this.state.isCameraMeasurementsVisible,
				conferenceId: this.state.conferenceId,
				participantId: this.state.participantId,
			})
			.then(result => {
				this.setState({
					isCameraMeasurementsVisible: result.isEnabled,
				});
			});

		this.setState(prevState => ({
			isCameraMeasurementsVisible: !prevState.isCameraMeasurementsVisible,
			isHealthMeasurementsVisible: false,
		}));
	};

	turnOnPatientHealthData = () => {
		this.setState({
			isHealthMeasurementsVisible: true,
			isCameraMeasurementsVisible: false,
		});
	};

	toggleOnCameraMeasurements = () => {
		this.setState({
			isCameraMeasurementsVisible: true,
			isHealthMeasurementsVisible: false,
		});
	};

	togglePeerCamera = () => {
		const { initialCallType, isMyVideoCamActive, localSrc, activeSrc, peerSrc } = this.state;
		const peerCameraToSet = !this.state.isWebToWebCall ? 'isPeerVideoCamActive' : 'myLocalPeerVideo';
		this.callManager.toggleParticipantTrack(CallTypes.VIDEO, this.state[peerCameraToSet]);
		let activeSrcToSet = null;
		if (this.state[peerCameraToSet] && isMyVideoCamActive) {
			activeSrcToSet = localSrc;
		} else {
			const changeActiveSource =
				!!peerSrc.mediaStream.getVideoTracks().length && !this.state[peerCameraToSet] && localSrc.id === activeSrc.id;
			activeSrcToSet = changeActiveSource ? peerSrc : localSrc;
		}
		this.setState(prevState => ({
			[peerCameraToSet]: !prevState[peerCameraToSet],
			showPTZ: prevState[peerCameraToSet] ? false : prevState.showPTZ,
			initialCallType: !prevState[peerCameraToSet] && initialCallType === CallTypes.AUDIO,
			activeSrc: activeSrcToSet,
		}));
	};

	showTopParticipant = () => {
		const { isMyVideoCamActive, isPeerVideoCamActive } = this.state,
			isMyVideoSelected = this.isMyVideoSelected();

		if (isMyVideoSelected && !isMyVideoCamActive) {
			return false;
		}

		if (!isMyVideoSelected && !this.isScreenSharingSelected() && !isPeerVideoCamActive) {
			return false;
		}

		return true;
	};

	toggleMyMic = () => {
		this.callManager.toggleAudio();
	};

	toggleMyVideo = () => {
		this.callManager.toggleVideo();
	};

	toggleMyScreenSharing = () => {
		this.callManager.screenShare();
	};

	endCall = () => {
		if (this.state.isWaitingRoom && [UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(this.userRole)) {
			this.leaveCall();
		} else {
			this.callManager.endCall({ endReason: ConferenceEndReason.INITIATOR_LEFT });
		}
	};

	leaveCall = () => {
		const leaveData = {
			conferenceId: this.state.conferenceId,
			participantId: this.state.participantId,
		};
		this.callManager.sendLeaveEvent(leaveData);
		this.setState(
			{
				localSrc: null,
				localScreenSrc: null,
				peerSrc: null,
				activeSrc: null,
				conferenceEndReason: ConferenceEndReason.UNDEFINED,
				hasActiveConference: false,
				conferenceId: null,
			},
			() => {
				window.close();
			}
		);
	};

	startCloseTabTimeout = () => {
		this.closeTabTimeout = setTimeout(() => {
			clearTimeout(this.closeTabTimeout);
			this.closeTabTimeout = null;
			window.close();
		}, 5000);
	};

	activeSrcHandle = mediaStreamId => {
		let { activeSrc } = this.state;
		if (activeSrc !== null && activeSrc.id === mediaStreamId) return;
		if (!this.state.isMyVideoCamActive || !this.state.myLocalPeerVideo) return;

		switch (mediaStreamId) {
			case this.state.localSrc.id:
				activeSrc = this.state.localSrc;
				break;
			case this.state.peerSrc.id:
				activeSrc = this.state.peerSrc;
				break;
			case this.state.peerScreenSrc.id:
				activeSrc = this.state.peerScreenSrc;
				break;
			case this.state.localScreenSrc.id:
				activeSrc = this.state.localScreenSrc;
				break;
			case this.state.isMyVideoCamActive:
				activeSrc = this.state.peerSrc;
				break;
			default:
				break;
		}
		this.setState({ activeSrc });
	};

	isMyVideoSelected = () => {
		const { localSrc, activeSrc } = this.state;

		if (!localSrc) {
			return false;
		}

		if (activeSrc && activeSrc.id === localSrc.id) {
			return true;
		}

		return false;
	};

	isScreenSharingSelected = () => {
		const { localScreenSrc, activeSrc, peerScreenSrc } = this.state;

		if (!activeSrc || (!localScreenSrc && _.isEmpty(peerScreenSrc))) return false;

		const screenId = localScreenSrc ? localScreenSrc.id : peerScreenSrc.id;

		return activeSrc.id === screenId;
	};

	getMediaList = () => {
		const { isPeerVideoCamActive, peerMicActive, myLocalPeerMic } = this.state;
		const lists = [];
		const peerCameraToSet = !this.state.isWebToWebCall ? 'isPeerVideoCamActive' : 'myLocalPeerVideo';
		if (isPeerVideoCamActive || !this.state.isWebToWebCall) {
			const cameraObj = {
				id: 'toggle_video',
				title: this.props.intl.formatMessage({ id: `${this.state[peerCameraToSet] ? 'turnCameraOff' : 'turnCameraOn'}` }),
				imgIcon: `${healthCareCdnUrl}footer-icons/new/${this.state[peerCameraToSet] ? 'CameraOn.svg' : 'CameraOff.svg'}`,
			};
			lists.push(cameraObj);
		}
		if (peerMicActive) {
			const cameraObj = {
				id: 'toggle_audio',
				title: this.props.intl.formatMessage({ id: `${myLocalPeerMic ? 'muteAudio' : 'unMuteAudio'}` }),
				imgIcon: `${healthCareCdnUrl}footer-icons/new/${myLocalPeerMic ? 'Sound100.svg' : 'SoundMuted.svg'}`,
			};
			lists.push(cameraObj);
		}
		return lists;
	};

	onMyMediaToggleClicked = (event, item) => {
		if (item.id === 'toggle_video') {
			this.setState(
				prevState => ({ isMyVideoCamActive: !prevState.isMyVideoCamActive }),
				() => {
					if (!this.state.isMyVideoCamActive && this.state.isPeerVideoCamActive && this.state.peerSrc) {
						this.activeSrcHandle(this.state.peerSrc.id);
					}
				}
			);
		} else if (item.id === 'toggle_audio') {
			this.setState(prevState => ({ micActive: !prevState.micActive }));
		}
	};

	onPeerMediaToggleClicked = (event, item) => {
		if (this.state.disableButtons) {
			return;
		}

		if (item.id === 'toggle_video') {
			this.togglePeerCamera();
		}

		if (item.id === 'toggle_audio') {
			this.setState(
				prevState => ({ myLocalPeerMic: !prevState.myLocalPeerMic }),
				() => {
					const audioTracks = this.state.peerSrc.mediaStream.getAudioTracks();
					audioTracks.forEach(track => {
						// eslint-disable-next-line no-param-reassign
						track.enabled = this.state.myLocalPeerMic;
					});
				}
			);
		}
	};

	togglePTZ = () => {
		this.setState(prevState => ({ showPTZ: !prevState.showPTZ }));
	};

	showPatientCameraToggledOff = () => {
		const { isVideoCall, isPeerVideoCamActive } = this.state;

		if (this.isMyVideoSelected()) return false;

		if (this.isScreenSharingSelected()) return false;

		return isVideoCall && !isPeerVideoCamActive;
	};

	setAudioSrcObject = () => {
		if (
			this.state.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.CONNECTED &&
			this.state.socketState.type === SocketState.CONNECTED.type &&
			this.audioRef
		) {
			this.audioRef.srcObject = null;
			this.audioRef.srcObject = this.state.peerSrc.mediaStream;
		}
	};

	shouldShowStateComponents = () => {
		return (
			this.state.hasActiveConference ||
			this.state.anotherNursePickedUp ||
			this.state.conferenceEndReason ||
			this.state.streamPermission ||
			this.state.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.DISCONNECTED ||
			this.state.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.FAILED
		);
	};

	shouldShowConnectionStatus = () => {
		return (
			this.state.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.DISCONNECTED ||
			this.state.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.FAILED
		);
	};

	sendPanTiltCameraEvent = (direction, action) => {
		const { participantId, conferenceId, helloDevice } = this.state;
		this.callManager.panTiltCamera({ direction, helloDeviceId: helloDevice?.objectId, action, conferenceId, participantId });
	};

	cameraResponseListener = ({ event, message, isSuccessful }) => {
		switch (event) {
			case CameraEventTypes.SWITCH:
				if (isSuccessful) {
					this.setState({
						cameraType: message,
						cameraZoomLevel: 0,
					});
				}
				break;
			case CameraEventTypes.ZOOM:
				this.setState({
					cameraZoomLevel: +message,
				});
				break;
			case CameraEventTypes.TILT: {
				const { disabledTiltDirections } = this.state;

				if (isSuccessful) {
					if ([CameraTiltDirection.UP, CameraTiltDirection.DOWN].includes(message)) {
						disabledTiltDirections[CameraTiltDirection.UP] = false;
						disabledTiltDirections[CameraTiltDirection.DOWN] = false;
					} else {
						disabledTiltDirections[CameraTiltDirection.LEFT] = false;
						disabledTiltDirections[CameraTiltDirection.RIGHT] = false;
					}
				} else {
					disabledTiltDirections[message] = true;
					this.sendPanTiltCameraEvent(message, CameraTiltAction.STOP);
				}

				this.setState(disabledTiltDirections);
				break;
			}
			case CameraEventTypes.HUDDLE_CONNECTED_STATE:
				this.setState({
					isHuddleCamConnected: isSuccessful,
				});
				break;
			case CameraEventTypes.NIGHT_VISION:
				this.setState({ nightVisionMode: isSuccessful });
				break;
			default:
		}
	};

	renderCallStates = () => {
		let result = null;
		if (this.state.hasActiveConference) {
			return <ActiveConference />;
		}

		if (this.state.anotherNursePickedUp) {
			return <AnotherNursePickedUp />;
		}

		if (this.state.conferenceEndReason) {
			return <CallEndReason reason={this.state.conferenceEndReason} url={this.state.myAvatarUrl} />;
		}

		if (this.state.streamPermission) {
			return <StreamPermissions reason={this.state.streamPermission} />;
		}

		if (this.state.peerConnectionState) {
			result = <ConnectionStatus url={this.state.myAvatarUrl} peerConnectionState={this.state.peerConnectionState} />;
		}
		return result;
	};

	screenCapture = () => {
		takeScreenshot(this.videoRef.current);
	};

	setTopParticipantStaticImage = source => {
		this.setState({
			topParticipantStaticImage: source,
		});
	};

	toggleStethoscope = isEnabled => {
		this.callManager.toggleStethoscope({
			isEnabled,
			helloDeviceId: this.state.helloDevice?.objectId,
			conferenceId: this.state.conferenceId,
			participantId: this.state.participantId,
		});
	};

	toggleAIControlsIsActive = (isActive, activeAIControlTool) => {
		this.setState({ isAIControlsActive: isActive, activeAIControlTool });
	};

	toastMessageHandler = data => {
		if (!data || data.device !== MeasuringDevices.find(item => item.title === 'stethoscope').id) {
			return;
		}
		if (
			[IotDeviceState.MEASURING, IotDeviceState.COMPLETED, IotDeviceState.CONNECTING].includes(data.deviceState) &&
			!this.state.isHealthMeasurementsVisible
		) {
			this.togglePatientHealthData();
		}
	};

	toggleFitScreen = () => {
		this.setState(prevState => ({
			isFitScreenOn: !prevState.isFitScreenOn,
		}));
	};

	toggleClassHandler = isLeft => {
		this.setState({
			hasCameraControlsClass: !isLeft,
			isConversationModalVisible: false,
			isOverviewOpen: isLeft,
			isDiagnosesVisible: false,
			isProceduresVisible: false,
			isNotesVisible: false,
			isPrescriptionsVisible: false,
		});
	};

	toggleTiltClass = hasTiltClass => {
		this.setState({
			hasTiltClass,
		});
	};

	toggleConversationModal = () => {
		this.setState(prevState => ({
			isConversationModalVisible: !prevState.isConversationModalVisible,
			isDiagnosesVisible: false,
			isProceduresVisible: false,
			isNotesVisible: false,
			isPrescriptionsVisible: false,
			isHealthMeasurementsVisible: !prevState.isOverviewOpen && prevState.isHealthMeasurementsVisible,
		}));
	};

	toggleDiagnoses = () => {
		this.setState(
			prevState => ({
				isDiagnosesVisible: !prevState.isDiagnosesVisible,
				isProceduresVisible: false,
				isNotesVisible: false,
				isPrescriptionsVisible: false,
				isConversationModalVisible: false,
				isPhysicalExercisesVisible: false,
			}),
			() => {
				if (this.state.isOverviewOpen && this.state.isDiagnosesVisible) {
					this.togglePatientHealthData();
				}
			}
		);
	};

	toggleProcedures = () => {
		this.setState(
			prevState => ({
				isProceduresVisible: !prevState.isProceduresVisible,
				isDiagnosesVisible: false,
				isNotesVisible: false,
				isPrescriptionsVisible: false,
				isConversationModalVisible: false,
				isPhysicalExercisesVisible: false,
			}),
			() => {
				if (this.state.isOverviewOpen && this.state.isProceduresVisible) {
					this.togglePatientHealthData();
				}
			}
		);
	};

	toggleNotes = () => {
		this.setState(
			prevState => ({
				isNotesVisible: !prevState.isNotesVisible,
				isDiagnosesVisible: false,
				isProceduresVisible: false,
				isPrescriptionsVisible: false,
				isConversationModalVisible: false,
				isPhysicalExercisesVisible: false,
			}),
			() => {
				if (this.state.isOverviewOpen && this.state.isNotesVisible) {
					this.togglePatientHealthData();
				}
			}
		);
	};

	togglePrescriptions = () => {
		this.setState(
			prevState => ({
				isPrescriptionsVisible: !prevState.isPrescriptionsVisible,
				isDiagnosesVisible: false,
				isProceduresVisible: false,
				isNotesVisible: false,
				isConversationModalVisible: false,
				isPhysicalExercisesVisible: false,
			}),
			() => {
				if (this.state.isOverviewOpen && this.state.isPrescriptionsVisible) {
					this.togglePatientHealthData();
				}
			}
		);
	};

	togglePhysicalExercises = () => {
		this.setState(
			prevState => ({
				isPhysicalExercisesVisible: !prevState.isPhysicalExercisesVisible,
				isDiagnosesVisible: false,
				isProceduresVisible: false,
				isNotesVisible: false,
				isConversationModalVisible: false,
				isPrescriptionsVisible: false,
			}),
			() => {
				if (this.state.isOverviewOpen && this.state.isPhysicalExercisesVisible) {
					this.togglePatientHealthData();
				}
			}
		);
	};

	setMedicalInfo = (items, type) => {
		this.setState({ [type]: items });
	};

	cancelVisit = async () => {
		const response = await deactivateVisit(this.state.visitId);
		if (response.error) {
			this.setState({ error: response.error.message });
		} else {
			this.setState({ visitId: null }, () => {
				this.startCloseTabTimeout();
			});
		}
	};

	submitVisit = async () => {
		const currentParams = queryString.parse(this.props.location.search);
		if (currentParams.appointmentId) {
			await updateAppointmentStatus(currentParams.appointmentId, AppointmentStatus.COMPLETED);
		}
		await saveVisitEndDateTime(this.state.visitId);
		this.setState({ visitId: null }, () => {
			this.startCloseTabTimeout();
		});
	};

	transferToDoctor = async () => {
		const response = await reassignRequestNextMedic(this.state.assignedRequestId);
		if (response.error) {
			this.setState({ error: response.error.message });
		} else {
			this.endCall();
		}
	};

	renderMedicalInfoDropDown = disableButtons => {
		const { isPrescriptionsVisible, isNotesVisible, isDiagnosesVisible, isProceduresVisible, isPhysicalExercisesVisible } =
			this.state;
		const isMedicalInfoOn = [
			isPrescriptionsVisible,
			isNotesVisible,
			isDiagnosesVisible,
			isProceduresVisible,
			isPhysicalExercisesVisible,
		].includes(true);

		return (
			this.userRole === UserRoles.DOCTOR && (
				<Dropup
					isDisabled={disableButtons}
					content={translate('medicalInfo')}
					isActive={isMedicalInfoOn}
					imgIcon={`${healthCareCdnUrl}footer-icons/medical-info${isMedicalInfoOn ? '-active' : ''}.svg`}>
					<DropupItem
						imgSrc={`${healthCareCdnUrl}footer-icons/diagnosis${this.state.isDiagnosesVisible ? '-active' : ''}.svg`}
						content={translate('addMedicalInfo', {
							value: this.props.intl.formatMessage({ id: 'diagnoses' }).toLowerCase(),
						})}
						onClick={this.toggleDiagnoses}
					/>
					<DropupItem
						imgSrc={`${healthCareCdnUrl}footer-icons/procedures${this.state.isProceduresVisible ? '-active' : ''}.svg`}
						content={translate('addMedicalInfo', {
							value: this.props.intl.formatMessage({ id: 'procedures' }).toLowerCase(),
						})}
						onClick={this.toggleProcedures}
					/>
					<DropupItem
						imgSrc={`${healthCareCdnUrl}footer-icons/notes${this.state.isNotesVisible ? '-active' : ''}.svg`}
						content={translate('addMedicalInfo', {
							value: this.props.intl.formatMessage({ id: 'notes' }).toLowerCase(),
						})}
						onClick={this.toggleNotes}
					/>
					<DropupItem
						imgSrc={`${healthCareCdnUrl}footer-icons/prescriptions${this.state.isPrescriptionsVisible ? '-active' : ''}.svg`}
						content={translate('addMedicalInfo', {
							value: this.props.intl.formatMessage({ id: 'prescriptions' }).toLowerCase(),
						})}
						onClick={this.togglePrescriptions}
					/>
					<DropupItem
						imgSrc={`${healthCareCdnUrl}physical-exercises/add-exercise.svg`}
						content={translate('addMedicalInfo', {
							value: this.props.intl.formatMessage({ id: 'physicalTherapy' }).toLowerCase(),
						})}
						onClick={this.togglePhysicalExercises}
					/>
				</Dropup>
			)
		);
	};

	renderCallControls = (localScreenSrc, disableButtons, isMyVideoCamActive, micActive, isPeerVideoCamActive, showPTZ) => (
		<div>
			<FooterButton
				id='screenshare-button'
				isActive={localScreenSrc}
				isDisabled={disableButtons}
				onClick={this.toggleMyScreenSharing}
				tooltipText={
					localScreenSrc
						? this.props.intl.formatMessage({ id: 'stopScreenSharing' })
						: this.props.intl.formatMessage({ id: 'startScreenSharing' })
				}
				tooltipPosition='top'
			/>
			<FooterButton
				id='turn-camera-button'
				isActive={isMyVideoCamActive}
				isDisabled={disableButtons}
				onClick={this.toggleMyVideo}
				tooltipText={
					isMyVideoCamActive
						? this.props.intl.formatMessage({ id: 'turnCameraOff' })
						: this.props.intl.formatMessage({ id: 'turnCameraOn' })
				}
				tooltipPosition='top'
			/>

			<FooterButton
				name='endcall'
				isActive={true}
				variant='end'
				action={this.endCall}
				tooltip={this.props.intl.formatMessage({ id: 'endCall' })}
				tooltipPosition='top'
				isDisabled={disableButtons}
			/>
			<FooterButton
				id='mic-button'
				isActive={micActive}
				isDisabled={disableButtons}
				onClick={this.toggleMyMic}
				tooltipText={
					micActive ? this.props.intl.formatMessage({ id: 'turnOffMic' }) : this.props.intl.formatMessage({ id: 'turnOnMic' })
				}
				tooltipPosition='top'
			/>
			<FooterButton
				id='ptz-button'
				isActive={showPTZ}
				isDisabled={!isPeerVideoCamActive || disableButtons}
				onClick={this.togglePTZ}
				tooltipText={
					showPTZ ? this.props.intl.formatMessage({ id: 'hidePTZ' }) : this.props.intl.formatMessage({ id: 'showPTZ' })
				}
				tooltipPosition='top'
			/>
		</div>
	);

	closePTReport = () => {
		this.setState({ isPhysicalExercisesVisible: false });
	};

	renderMedicalInfo = () =>
		this.userRole === UserRoles.DOCTOR && (
			<>
				{this.state.isDiagnosesVisible && (
					<AddDiagnosesOrProcedures
						visitId={this.state.visitId}
						type={MedicalInfoTypes.DIAGNOSES}
						setMedicalInfo={items => this.setMedicalInfo(items, MedicalInfoTypes.DIAGNOSES)}
						diagnoses={this.state.diagnoses}
					/>
				)}
				{this.state.isProceduresVisible && (
					<AddDiagnosesOrProcedures
						visitId={this.state.visitId}
						type={MedicalInfoTypes.PROCEDURES}
						setMedicalInfo={items => this.setMedicalInfo(items, MedicalInfoTypes.PROCEDURES)}
						procedures={this.state.procedures}
					/>
				)}
				{this.state.isNotesVisible && (
					<AddNotes
						visitId={this.state.visitId}
						setMedicalInfo={notes => this.setMedicalInfo(notes, MedicalInfoTypes.NOTES)}
						notes={this.state.notes}
					/>
				)}
				{this.state.isPrescriptionsVisible && (
					<PrescriptionsWrapper
						prescriptions={this.state.prescriptions}
						type={MedicalInfoTypes.PRESCRIPTIONS}
						visitId={this.state.visitId}
						setMedicalInfo={prescriptions => this.setMedicalInfo(prescriptions, MedicalInfoTypes.PRESCRIPTIONS)}
					/>
				)}

				{this.state.isPhysicalExercisesVisible && (
					<AddPTReport
						visitId={this.state.visitId}
						doctorId={this.state.doctorId}
						patientId={this.state.patientId}
						patientName={this.state.conferenceName}
						closePTReport={this.closePTReport}
					/>
				)}
			</>
		);

	renderCameraMeasurements = isMedicalInfoActive =>
		!this.state.isWebToWebCall && (
			<div
				className={classNames(
					this.state.isCameraMeasurementsVisible ? '' : 'hidden',
					this.state.isConversationModalVisible || isMedicalInfoActive ? 'conversation-modal-visible' : ''
				)}>
				<OldCameraMeasurements
					conferenceId={this.state.conferenceId}
					toggleOnCameraMeasurements={this.toggleOnCameraMeasurements}
					callManager={this.callManager}
				/>
			</div>
		);

	renderHealthMeasurements = isMedicalInfoActive =>
		this.state.conferenceId &&
		[UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(this.userRole) &&
		(this.state.didMedicJoin || this.state.isRequestAccepted) && (
			<div
				className={classNames(
					this.state.isHealthMeasurementsVisible ? '' : 'hidden',
					this.state.isConversationModalVisible || isMedicalInfoActive ? 'conversation-modal-visible' : ''
				)}>
				<HealthMeasurements
					patientId={this.state.patientId}
					helloDeviceId={this.state.helloDevice?.objectId}
					conferenceId={this.state.conferenceId}
					participantId={this.state.participantId}
					turnOnPatientHealthData={this.turnOnPatientHealthData}
					callManager={this.callManager}
					toggleStethoscope={this.toggleStethoscope}
					peerMicActive={this.state.peerMicActive}
					toggleClass={this.toggleClassHandler}
					isHealthMeasurementsVisible={this.state.isHealthMeasurementsVisible}
					objectType={ObjectType.HELLO_DEVICE}
				/>
			</div>
		);

	renderConversation = () =>
		this.state.isConversationModalVisible && (
			<aside className='right-side call-conversation-wrapper'>
				<Conversation conversationId={this.state.conversationId} hideRightSide={true} isCallChat={true} />
			</aside>
		);

	renderAIcontrols = (helloDeviceId, conferenceId, participantId, disableButtons) => {
		return (
			this.state.objectType === ObjectType.HELLO_DEVICE &&
			this.state.isPeerVideoCamActive && (
				<AIControls
					isVideoCall={this.state.isVideoCall}
					videoRef={this.videoRef}
					canvasRef={this.detectionsCanvasRef}
					callManager={this.callManager}
					setSkeletonImg={this.setTopParticipantStaticImage}
					helloDeviceId={helloDeviceId}
					conferenceId={conferenceId}
					participantId={participantId}
					patientId={this.state.interlocutorId}
					toggleAIControlsIsActive={this.toggleAIControlsIsActive}
					callType={this.state.initialCallType}
					isRight={this.state.isHealthMeasurementsVisible || this.state.isCameraMeasurementsVisible}
					tiltBottomClass={this.toggleTiltClass}
					isFitScreenOn={this.state.isFitScreenOn}
					isDisabled={disableButtons}
					isActive={this.state.isAIControlsActive}
					content={this.state.isAIControlsActive ? this.state.activeAIControlTool.value : translate('aiControls')}
					imgIcon={
						this.state.isAIControlsActive ? this.state.activeAIControlTool.img : `${healthCareCdnUrl}footer-icons/ai-icon.svg`
					}
				/>
			)
		);
	};

	submitCanceledRequest = () => {
		this.setState({ medicWhoCanceled: null }, () => {
			this.endCall();
		});
	};

	render() {
		const {
			activeSrc,
			peerSrc,
			localSrc,
			localScreenSrc,
			peerMicActive,
			micActive,
			participantId,
			conferenceId,
			isMyVideoCamActive,
			isVideoCall,
			isPeerVideoCamActive,
			showPTZ,
			tvStatus,
			hdmiStatus,
			volumeStatus,
			nightVisionMode,
			conferenceEndReason,
			streamPermission,
			disableButtons,
			cameraZoomLevel,
			cameraType,
			disabledTiltDirections,
			isHuddleCamConnected,
			myLocalPeerVideo,
			peerScreenSrc,
			isWebToWebCall,
			isDiagnosesVisible,
			isNotesVisible,
			isPrescriptionsVisible,
			isProceduresVisible,
			isPhysicalExercisesVisible,
		} = this.state;

		const helloDeviceId = this.state.helloDevice?.objectId;
		const shouldShowStateComponents = this.shouldShowStateComponents();
		const showPatientCameraToggledOff = this.showPatientCameraToggledOff();
		const videoCamActive = isWebToWebCall ? isPeerVideoCamActive && myLocalPeerVideo : isPeerVideoCamActive;
		const isMedicalInfoActive =
			isDiagnosesVisible || isNotesVisible || isPrescriptionsVisible || isProceduresVisible || isPhysicalExercisesVisible;
		return (
			<Grid
				className={classNames('call-view', this.isMyVideoSelected() ? 'top-participant-me' : '')}
				columns='1fr'
				stretch='100vh'>
				{shouldShowStateComponents && !this.state.visitId && this.renderCallStates()}
				{this.state.visitId && !this.state.conferenceId && (
					<CallWrapUp
						visitId={this.state.visitId}
						diagnoses={this.state.diagnoses}
						procedures={this.state.procedures}
						notes={this.state.notes}
						prescriptions={this.state.prescriptions}
						cancelVisit={this.cancelVisit}
						submitVisit={this.submitVisit}
						patientName={this.state.interlocutorName}
						patientProfilePicture={this.state.participantProfilePic}
						setMedicalInfo={(items, type) => this.setMedicalInfo(items, type)}
					/>
				)}
				{!shouldShowStateComponents && (
					<>
						<main>
							{showPatientCameraToggledOff && (
								<Grid columns='1fr' rows='1fr' stretch='100vh' backgroundColor='#0153B6' horizAlign='center' vertAlign='center'>
									<div style={{ textAlign: 'center' }}>
										<img
											style={{ borderRadius: '100%' }}
											onError={e => onErrorInitialsSrc(e)}
											src={buildProfilePicUrl(this.state.participantProfilePic, 150)}
											alt='participant-img'
										/>
										<h3 style={{ color: 'white' }}>{translate('participantCameraOff', { value: this.state.conferenceName })}</h3>
									</div>
								</Grid>
							)}
							{!showPatientCameraToggledOff && (
								<>
									<TopParticipant
										topParticipantFeed={activeSrc}
										isTopParticipantVideoActive={this.showTopParticipant()}
										topParticipantAvatar={!isVideoCall ? this.state.participantProfilePic : ''}
										videoRef={this.videoRef}
										canvasRef={this.detectionsCanvasRef}
										imgSrc={this.state.topParticipantStaticImage}
										isFitScreenOn={this.state.isFitScreenOn}
									/>
								</>
							)}
							<CameraControls
								className={classNames(
									(this.state.isHealthMeasurementsVisible || this.state.isCameraMeasurementsVisible) &&
										this.state.hasCameraControlsClass
										? 'health-data-ptz-left'
										: '',
									this.state.hasTiltClass ? 'tilt-bottom' : ''
								)}
								cameraType={cameraType}
								cameraZoomLevel={cameraZoomLevel}
								showPTZ={showPTZ}
								disabledTiltDirections={disabledTiltDirections}
								isHuddleCamConnected={isHuddleCamConnected}
								isDisabled={disableButtons}
								onToggleCameraSwitch={() =>
									this.callManager.sendCameraEvent(SocketEvents.HelloDevice.SWITCH_CAMERA, {
										participantId,
										conferenceId,
										helloDeviceId,
										level: 0,
									})
								}
								onZoomCamera={direction => {
									const level = direction === ZoomDirection.INCREASE ? cameraZoomLevel + 20 : cameraZoomLevel - 20;
									this.callManager.sendCameraEvent(SocketEvents.HelloDevice.ZOOM_CAMERA, {
										level,
										helloDeviceId,
										conferenceId,
										participantId,
									});
								}}
								onPanTiltCamera={this.sendPanTiltCameraEvent}
								onRebootHuddleCam={() =>
									this.callManager.rebootHuddleCam({
										conferenceId,
										participantId,
										helloDeviceId,
									})
								}
							/>

							{!peerSrc && !this.state.isWaitingRoom && (
								<div className='loader__container'>
									<div className='center-loader'>
										<p>{translate('initiatingCall')}</p>
										<Loader />
									</div>
								</div>
							)}
						</main>

						{(peerSrc || this.state.isWaitingRoom) && (
							<>
								<aside>
									<audio
										ref={audio => {
											this.audioRef = audio;
										}}
										autoPlay
									/>

									{!this.state.isHealthMeasurementsVisible && !this.state.isCameraMeasurementsVisible && (
										<>
											<Participant
												name={this.props.intl.formatMessage({ id: 'you' })}
												participantFeed={localSrc}
												videoCamActive={isVideoCall ? isMyVideoCamActive : false}
												onClick={this.activeSrcHandle}
												participantAvatar={this.state.myAvatarUrl}>
												{peerSrc && <PeerStats callManager={this.callManager} isLocalSrc={true} />}
											</Participant>
											{peerSrc && (
												<Participant
													name={this.state.conferenceName ? this.state.conferenceName : translate('patient')}
													participantFeed={peerSrc}
													onClick={this.activeSrcHandle}
													videoCamActive={isVideoCall ? videoCamActive : false}
													participantAvatar={this.state.participantProfilePic}
													imgSrc={this.state.topParticipantStaticImage}>
													{!peerMicActive && <i className='material-icons-outlined mic_off-icon'>volume_off</i>}
													{this.getMediaList().length > 0 && (
														<Dropdown position='bottom' icon='more_horiz'>
															<ListGroup onItemClick={this.onPeerMediaToggleClicked} lists={this.getMediaList()} />
														</Dropdown>
													)}
													{this.state.peerSrc && <PeerStats callManager={this.callManager} />}
												</Participant>
											)}
											{!_.isEmpty(peerScreenSrc) && (
												<Participant
													name={`${this.props.intl.formatMessage({ id: 'presenting' })} (${this.state.conferenceName})`}
													participantFeed={peerScreenSrc}
													videoCamActive={!!peerScreenSrc}
													onClick={this.activeSrcHandle}
												/>
											)}
											{localScreenSrc && (
												<Participant
													name={`${this.props.intl.formatMessage({ id: 'presenting' })} (${this.props.intl.formatMessage({
														id: 'you',
													})})`}
													participantFeed={localScreenSrc}
													videoCamActive={localScreenSrc != null}
													onClick={this.activeSrcHandle}
												/>
											)}
										</>
									)}
								</aside>
								{this.renderConversation()}
								<div className='call-view__footer'>
									{this.renderHealthMeasurements(isMedicalInfoActive)}
									{this.renderMedicalInfo()}
									{this.renderCameraMeasurements(isMedicalInfoActive)}
									<Grid className='call-view-grid call-footer-wrapper' vertAlign='center'>
										<div className='call-view__footer--desc'>
											{this.userRole === UserRoles.NURSE && this.state.isWaitingRoom && (
												<Button
													onClick={() => this.setState({ isTransferToDoctorModalOpen: true })}
													text={translate('transferToDoctor')}
												/>
											)}
											{!this.state.isWebToWebCall && (
												<div className={`call-grouping-wrapper ${disableButtons ? 'disabled' : ''}`}>
													<div
														className={`call-grouping-btn flex${this.state.isCameraMeasurementsVisible ? ' active' : ''}`}
														onClick={this.toggleCameraMeasurements}>
														<img
															src={
																!this.state.isCameraMeasurementsVisible
																	? `${healthCareCdnUrl}footer-icons/vital-signs-icon.svg`
																	: `${healthCareCdnUrl}footer-icons/vital-signs-icon-active.svg`
															}
															alt='icon'
															style={{ marginRight: '5px' }}
														/>
														<span>{translate('vitalsCamera')}</span>
													</div>
												</div>
											)}
											{this.renderAIcontrols(helloDeviceId, conferenceId, participantId, disableButtons)}
											<div className='footer-call-conference-wrapper'>
												<p className='footer-conference-name-title'>{this.state.conferenceName}</p>
												{!!this.state.conferenceId && <CallDuration callStartTime={this.state.callStartTime} />}
											</div>
										</div>
										{this.renderCallControls(
											localScreenSrc,
											disableButtons,
											isMyVideoCamActive,
											micActive,
											isPeerVideoCamActive,
											showPTZ
										)}
										<div>
											<>
												<div className={`call-grouping-wrapper ${disableButtons ? 'disabled' : ''}`}>
													{[UserRoles.DOCTOR, UserRoles.NURSE].includes(this.userRole) && (
														<div
															className={`call-grouping-btn flex${this.state.isHealthMeasurementsVisible ? ' active' : ''}`}
															onClick={this.togglePatientHealthData}>
															<img
																src={
																	!this.state.isHealthMeasurementsVisible
																		? `${healthCareCdnUrl}footer-icons/vital-signs-icon.svg`
																		: `${healthCareCdnUrl}footer-icons/vital-signs-icon-active.svg`
																}
																alt='icon'
																style={{ marginRight: '5px' }}
															/>
															<span>
																{!this.state.isHealthMeasurementsVisible ? translate('showHealth') : translate('hideHealth')}
															</span>
														</div>
													)}
												</div>
												{![UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(this.userRole) &&
													this.state.objectType !== ObjectType.HELLO_DEVICE && (
														<div className={`call-grouping-wrapper ${disableButtons ? 'disabled' : ''}`}>
															<div
																className={`call-grouping-btn flex${this.state.isConversationModalVisible ? ' active' : ''}`}
																onClick={this.toggleConversationModal}>
																<img
																	src={
																		!this.state.isConversationModalVisible
																			? `${healthCareCdnUrl}footer-icons/conversation.svg`
																			: `${healthCareCdnUrl}footer-icons/conversation-active.svg`
																	}
																	alt='icon'
																	style={{ marginRight: '5px' }}
																/>
																<span>{translate('conversation')}</span>
															</div>
														</div>
													)}
												{this.renderMedicalInfoDropDown(disableButtons)}
												{this.state.objectType === ObjectType.HELLO_DEVICE && (
													<Dropup
														isDisabled={disableButtons}
														content={translate('tvControls')}
														imgIcon={`${healthCareCdnUrl}footer-icons/tv-footer-icon${nightVisionMode ? '-blue' : ''}.svg`}
														additionalClass='tv-controls-grouping-elements'
														isActive={nightVisionMode}>
														<TvControls
															tvStatus={tvStatus}
															volumeStatus={volumeStatus}
															hdmiStatus={hdmiStatus}
															callManagerInstance={this.callManager}
															helloDeviceId={helloDeviceId}
															participantId={participantId}
															conferenceId={conferenceId}
															intl={this.props.intl}
														/>
														<NightVisionControl
															nightVisionMode={nightVisionMode}
															showButton={isPeerVideoCamActive}
															toggleNightvisionHandler={() =>
																this.callManager.toggleNightVision(!nightVisionMode, helloDeviceId, conferenceId, participantId)
															}
															isDisabled={this.state.cameraType === CameraType.HUDDLE || disableButtons}
														/>
													</Dropup>
												)}
											</>
											{(this.state.isMyVideoCamActive || this.state.isPeerVideoCamActive) && (
												<Dropup icon='more_vert' autoWidth additionalClass='additional-call-grouping-elements'>
													<DropupItem
														onClick={this.screenCapture}
														imgSrc={`${healthCareCdnUrl}footer-icons/Screenshot.svg?v2`}
														content={translate('takeScreenshot')}
													/>
													<DropupItem
														onClick={this.toggleFitScreen}
														imgSrc={
															this.state.isFitScreenOn
																? `${healthCareCdnUrl}footer-icons/FullScreen.svg?v2`
																: `${healthCareCdnUrl}footer-icons/ExitFullScreen.svg?v2`
														}
														content={
															this.state.isFitScreenOn
																? this.props.intl.formatMessage({ id: 'fullScreen' })
																: this.props.intl.formatMessage({ id: 'fitToScreen' })
														}
													/>
												</Dropup>
											)}
										</div>
									</Grid>
								</div>
								<Alert
									display={this.state.error !== null}
									message={this.state.error}
									variant='dark'
									fixed={true}
									onClose={() =>
										this.setState({
											error: null,
										})
									}
								/>
							</>
						)}
					</>
				)}
				<CallCorrelationInfo
					correlationId={conferenceId}
					className={(conferenceEndReason || streamPermission || !peerSrc) && 'out-of-call'}
				/>
				{this.state.medicWhoCanceled && (
					<Modal
						className='small-popup-modal'
						display={true}
						position='center'
						onModalSubmit={this.submitCanceledRequest}
						onModalClose={this.submitCanceledRequest}
						primaryButtonLabel='OK'
						hideCloseButton={true}>
						<>
							<h4>{translate('requestRejected')}</h4>
							<p>
								{this.state.medicWhoCanceled} {translate('rejectedRequest')}
							</p>
						</>
					</Modal>
				)}
				{this.state.isTransferToDoctorModalOpen && (
					<Modal
						display={true}
						position='center'
						onModalSubmit={this.transferToDoctor}
						onModalClose={() => this.setState({ isTransferToDoctorModalOpen: false })}
						primaryButtonLabel={this.props.intl.formatMessage({ id: 'yes' })}>
						<form>
							<h3>{translate('warning')}</h3>
							<p>
								{translate('transferToDoctorWarning', {
									doctor: `${this.state.doctorToTransfer?.firstName} ${this.state.doctorToTransfer?.lastName}`,
									patient: this.state.conferenceName,
								})}
							</p>
						</form>
					</Modal>
				)}
			</Grid>
		);
	}
}

Call.contextType = SocketContext;

const mapDispatchToProps = dispatch => {
	return {
		healthSystemAction: bindActionCreators(HealthSystemActionCreators, dispatch),
	};
};

const mapStateToProps = state => {
	return {
		healthSystems: state.healthSystems,
	};
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Call));
