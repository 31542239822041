import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { ConditionType } from 'constants/enums.js';
import { intialCareConsiderations } from 'constants/roomSign.js';
import { getPrecautions, setConditions, deleteConditions } from 'api/whiteboard.js';
import { Alert, Button, CustomDropdown } from 'components/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import { EditIcon } from 'icons/Monitoring/index.js';
import translate from 'i18n-translations/translate.jsx';

const RoomSignCareConsiderations = ({ deviceOwnerId, deviceId, isDarkMode }) => {
	const socket = useContext(SocketContext);
	const intl = useIntl();
	const [expanded, setExpanded] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);
	const [error, setError] = useState(null);
	const [careConsiderations, setCareConsiderations] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const response = await getPrecautions(deviceOwnerId);
			if (response.error) {
				setError(translate('somethingWentWrong'));
				return;
			}
			const filteredItems = _.cloneDeep(intialCareConsiderations).filter(item =>
				response.conditions.find(condition => condition.code === item.key)
			);
			setCareConsiderations(filteredItems);
			setSelectedItems(filteredItems);
		};

		if (deviceOwnerId) {
			fetchData();
		}
	}, [deviceOwnerId]);

	const setSelectedCareConsiderations = async values => {
		let response = null;
		const updatedCareConsiderationsArray = values.map(item => item.key);
		const differences = careConsiderations.filter(item => !updatedCareConsiderationsArray.includes(item.key));

		if (differences.length > 0) {
			response = await deleteConditions(deviceOwnerId, {
				deviceId,
				conditions: differences.map(item => ({
					conditionType: ConditionType.PRECAUTION,
					display: item.name,
					code: item.key,
				})),
			});
		} else {
			response = await setConditions(deviceOwnerId, {
				deviceId,
				conditions: values.map(item => ({
					conditionType: ConditionType.PRECAUTION,
					display: item.name,
					code: item.key,
				})),
			});
		}

		if (response.error) {
			setError(translate('somethingWentWrong'));
			return;
		}

		setCareConsiderations(values);
	};

	useEffect(() => {
		const ConditionActionTypes = {
			REMOVE: 1,
			ADD: 2,
		};

		const handleCareConsiderationsUpdate = (data, type) => {
			if (data.deviceId !== deviceId) {
				return;
			}

			if (type === ConditionActionTypes.ADD) {
				const filteredConditions = data.conditions.filter(item => item.conditionType === ConditionType.PRECAUTION);

				const updateCareConsiderations = prevState => {
					const existingCareConsiderationsMap = new Map(prevState.map(item => [item.key, item]));

					filteredConditions.forEach(condition => {
						const conditionData = intialCareConsiderations.find(x => condition.code === x.key);
						if (conditionData) {
							existingCareConsiderationsMap.set(condition.code, {
								key: condition.code,
								name: conditionData.name,
								label: conditionData.label,
								src: conditionData.src,
							});
						}
					});

					return Array.from(existingCareConsiderationsMap.values());
				};

				setCareConsiderations(prevState => updateCareConsiderations(prevState));
				setSelectedItems(prevState => updateCareConsiderations(prevState));
			}

			if (type === ConditionActionTypes.REMOVE) {
				setCareConsiderations(prevState =>
					prevState.filter(item => !data.conditions.some(condition => condition.code === item.key))
				);
				setSelectedItems(prevState => prevState.filter(item => !data.conditions.some(condition => condition.code === item.key)));
			}
		};

		if (!showEditForm) {
			socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, data =>
				handleCareConsiderationsUpdate(data, ConditionActionTypes.ADD)
			);
			socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED, data =>
				handleCareConsiderationsUpdate(data, ConditionActionTypes.REMOVE)
			);
		}

		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, data =>
				handleCareConsiderationsUpdate(data, ConditionActionTypes.ADD)
			);
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED, data =>
				handleCareConsiderationsUpdate(data, ConditionActionTypes.REMOVE)
			);
		};
	}, [socket, deviceId, showEditForm]);

	const removeDuplicateItems = items => {
		const conditionsList = new Map();
		items.forEach(item => {
			if (!conditionsList.has(item.key)) {
				conditionsList.set(item.key, item);
			}
		});
		return Array.from(conditionsList.values());
	};

	const handleSelectedItems = selectedOptions => {
		const filteredSelectedItems = removeDuplicateItems(
			selectedOptions.map(item => ({
				key: item.value,
				name: item.label,
				label: item.label,
				src: intialCareConsiderations.find(x => item.value === x.key)?.src,
			}))
		);
		setSelectedItems(filteredSelectedItems);
	};

	const handleSaveButton = async () => {
		await setSelectedCareConsiderations(selectedItems);
		setShowEditForm(false);
	};

	return (
		<div className='room-sign-box'>
			<div className={classNames('timeline-box-header', { expanded })}>
				<p className='timeline-box-title'>{translate('careConsiderations')}</p>
				<div className='timeline-box-actions'>
					<button
						className={classNames('edit-btn', { 'save-btn': showEditForm })}
						type='button'
						onClick={showEditForm ? handleSaveButton : () => setShowEditForm(true)}>
						{showEditForm ? (
							<>
								<i className='material-icons'>done</i>
								<p>{translate('save')}</p>
							</>
						) : (
							<EditIcon color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayThree} />
						)}
					</button>
					<Button
						icon={expanded ? 'expand_less' : 'expand_more'}
						onClick={() => setExpanded(prevState => !prevState)}
						border='none'
					/>
				</div>
			</div>
			{expanded && (
				<>
					{!showEditForm && (
						<table className='patient-table-informations' style={{ width: '100%' }}>
							<tbody>
								{careConsiderations.map(item => (
									<tr key={item.key}>
										<td className='care-considerations-td'>
											<img src={item.src} alt={item.label} />
											<span>{item.label}</span>
										</td>
									</tr>
								))}
								{careConsiderations.length === 0 && (
									<tr>
										<td>{translate('noData')}</td>
									</tr>
								)}
							</tbody>
						</table>
					)}
					{showEditForm && (
						<div className='flex-basis-100'>
							<CustomDropdown
								defaultOptions={selectedItems.map(item => ({
									value: item.key,
									label: item.name,
								}))}
								initialOptions={intialCareConsiderations.map(item => ({ value: item.key, label: item.label }))}
								onSelect={handleSelectedItems}
								title={intl.formatMessage({ id: 'careConsiderations' })}
								showTitleInPlaceholder={true}
								placeholder=''
							/>
						</div>
					)}
				</>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default RoomSignCareConsiderations;
