import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { MeasurementTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SocketEvents from 'constants/socket-events.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { useSelector } from 'react-redux';

const VitalSignsAI = props => {
	const socket = useContext(SocketContext);
	const intl = useIntl();
	const preferredUnits = useSelector(state => state.user.unitPreferences);
	const [vitalSigns, setVitalSigns] = useState([
		{
			id: 1,
			name: intl.formatMessage({ id: 'oxygenSaturation' }),
			value: `${intl.formatMessage({ id: 'measuring' })}...`,
			unit: '',
			img: 'respiratory',
			type: MeasurementTypes.OXYGEN,
			shortName: 'SPO2',
		},
		{
			id: 2,
			name: intl.formatMessage({ id: 'heartRate' }),
			value: `${intl.formatMessage({ id: 'measuring' })}...`,
			unit: '',
			img: 'heart',
			type: MeasurementTypes.HEART_RATE,
			shortName: 'HR',
		},
		{
			id: 3,
			name: intl.formatMessage({ id: 'bloodPressure' }),
			value: `${intl.formatMessage({ id: 'measuring' })}...`,
			unit: '',
			img: 'heart',
			type: MeasurementTypes.BLOOD_PRESSURE,
			shortName: 'BP',
		},
		{
			id: 6,
			name: intl.formatMessage({ id: 'respiration' }),
			value: `${intl.formatMessage({ id: 'measuring' })}...`,
			unit: '',
			img: 'respiratory',
			type: MeasurementTypes.RESPIRATORY_RATE,
		},
		{
			id: 7,
			name: 'BMI',
			value: `${intl.formatMessage({ id: 'measuring' })}...`,
			unit: '',
			img: 'body-measurements',
			type: MeasurementTypes.BODY_MASS_INDEX,
		},
		{
			id: 8,
			name: intl.formatMessage({ id: 'facialSkinAge' }),
			value: `${intl.formatMessage({ id: 'measuring' })}...`,
			unit: '',
			img: 'examination',
			type: MeasurementTypes.FACIAL_SKIN_AGE,
		},
		{
			id: 9,
			name: 'HRV',
			value: `${intl.formatMessage({ id: 'measuring' })}...`,
			unit: '',
			img: 'heart',
			type: MeasurementTypes.HRV_INDEX,
		},
		{
			id: 10,
			name: intl.formatMessage({ id: 'stressIndex' }),
			value: `${intl.formatMessage({ id: 'measuring' })}...`,
			unit: '',
			img: 'examination',
			type: MeasurementTypes.STRESS_LEVEL,
		},
		{
			id: 11,
			name: intl.formatMessage({ id: 'cardiacWorkload' }),
			value: `${intl.formatMessage({ id: 'measuring' })}...`,
			unit: '',
			img: 'hrv',
			type: MeasurementTypes.CARDIAC_WORKLOAD,
		},
		{
			id: 12,
			name: intl.formatMessage({ id: 'bilirubin' }),
			value: `${intl.formatMessage({ id: 'measuring' })}...`,
			unit: '',
			img: 'heart',
			type: MeasurementTypes.BILIRUBIN,
		},
		{
			id: 13,
			name: intl.formatMessage({ id: 'heartAttackRisk' }),
			value: `${intl.formatMessage({ id: 'measuring' })}...`,
			unit: '',
			img: 'heart',
			type: MeasurementTypes.HEART_ATTACK_RISK,
		},
		{
			id: 14,
			name: intl.formatMessage({ id: 'strokeRisk' }),
			value: `${intl.formatMessage({ id: 'measuring' })}...`,
			unit: '',
			img: 'heart',
			type: MeasurementTypes.STROKE_RISK,
		},
	]);

	useEffect(() => {
		socket.on(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, healthMeasurementsListener);
		return () => {
			socket.off(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, healthMeasurementsListener);
		};
	}, [socket]);

	const healthMeasurementsListener = data => {
		const newVitalSigns = [...vitalSigns];
		const { measurementType, measurementValue, measurementUnit, unitCategoryId } = data;
		const found = newVitalSigns.find(element => measurementType === element.type);
		if (found) {
			found.value =
				measurementValue === 'Measuring'
					? `${intl.formatMessage({ id: 'measuring' })}...`
					: convertMeasurementTypes(unitCategoryId, measurementValue, getUnitPreference(unitCategoryId)?.unitSystemId);
			found.unit = measurementValue === 'Measuring' ? '' : measurementUnit;
			setVitalSigns(newVitalSigns);
		}
	};

	const getCategoryPreference = categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const getVitalSignsDetails = (isRight = false) => (
		<div
			className={classNames(
				'flex vital-signs-ai',
				isRight ? 'right' : '',
				props.numberOfFeeds > 1 ? 'multiple-feeds' : '',
				props.isPtzActive ? 'ptz-active' : '',
				!props.emergencyButtonsHidden ? 'ai-vital-signs-showing' : '',
				props.hasConditions ? 'has-conditions' : '',
				props.notesShowing ? 'feed-notes-showing' : ''
			)}>
			{vitalSigns.map(vitalSign => {
				const selectedItems = isRight ? vitalSign.id > 8 : vitalSign.id <= 8;
				return (
					selectedItems && (
						<div key={vitalSign.id} className='flex vital-signs-details'>
							<img src={`${healthCareCdnUrl}vital-signs/${vitalSign.img}.svg`} alt='icon' />
							<div className='flex column-direction left-10'>
								<span>{vitalSign.name}</span>
								<div className='flex'>
									<span className='right-s'>{vitalSign.value}</span>
									<span>{vitalSign.unit}</span>
								</div>
							</div>
						</div>
					)
				);
			})}
		</div>
	);

	const getThreeAIVitalSign = () => {
		const filteredVitalSigns = vitalSigns.slice(0, 3);

		return (
			<div className='flex vital-signs-ai'>
				{filteredVitalSigns.map(vsAI => (
					<div key={vsAI.id} className='flex vital-signs-details'>
						<img src={`${healthCareCdnUrl}vital-signs/${vsAI.img}.svg`} alt='icon' />
						<div className='flex column-direction left-10'>
							<span>{vsAI.shortName}</span>
							<div className='flex'>
								{vsAI.value !== `${intl.formatMessage({ id: 'measuring' })}...` && <span className='right-s'>{vsAI.value}</span>}
								{vsAI.value === `${intl.formatMessage({ id: 'measuring' })}...` && <span className='right-s'>...</span>}
								<span>{vsAI.unit}</span>
							</div>
						</div>
					</div>
				))}
			</div>
		);
	};

	return (
		<>
			<div className={classNames('flex monitoring-vital-signs-ai-wrapper', !props.showAllVitalSigns ? 'row' : '')}>
				{props.showAllVitalSigns && getVitalSignsDetails()}
				{props.showAllVitalSigns && getVitalSignsDetails(true)}
				{!props.showAllVitalSigns && getThreeAIVitalSign()}
			</div>
		</>
	);
};

export default VitalSignsAI;
