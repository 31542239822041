import * as React from 'react';

const Doctor = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={16} height={18} {...props}>
		<path
			id='Layer'
			d='M12.78 17.2c0 .35.27.62.62.62s.63-.27.63-.62h-1.25Zm-10.8 0c0 .35.27.62.62.62s.63-.27.63-.62H1.98Zm10.8-2.06v2.06h1.25v-2.06h-1.25ZM3.23 17.2v-2.06H1.98v2.06h1.25ZM8 10.63c2.67 0 4.78 2.05 4.78 4.51h1.25c0-3.21-2.73-5.76-6.03-5.76v1.25Zm0-1.25c-3.3 0-6.02 2.55-6.02 5.76h1.25c0-2.46 2.1-4.51 4.77-4.51V9.38Z'
			style={{
				fill: 'currentcolor',
			}}
		/>
		<path
			d='M10.7 10.9c0 1.24-1.01 2.25-2.25 2.25H8'
			style={{
				fill: 'none',
				stroke: 'currentColor',
				strokeWidth: 1.25,
			}}
		/>
		<path
			d='M8.45 13.15c0 .62-.5 1.12-1.12 1.12-.63 0-1.13-.5-1.13-1.12 0-.62.5-1.13 1.13-1.13.62 0 1.12.51 1.12 1.13Z'
			style={{
				fill: 'currentcolor',
			}}
		/>
		<path
			d='M4.4 14.95v2.25M11.6 14.95v2.25M10.93 6.27C10.93 7.89 9.62 9.2 8 9.2a2.92 2.92 0 0 1-2.92-2.93c0-1.61 1.3-2.92 2.92-2.92 1.62 0 2.93 1.31 2.93 2.92Z'
			style={{
				fill: 'none',
				stroke: 'currentColor',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: 1.25,
			}}
		/>
	</svg>
);

export default Doctor;
