const VitalKit = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} viewBox='0 0 24 24' fill='none' {...props}>
		<path
			d='M8 7.46838V21C8 21.5523 8.44772 22 9 22H14.0799C14.6 22 15.0332 21.6013 15.0764 21.083L15.9696 10.365C15.9892 10.1293 16.0918 9.90823 16.259 9.74098L18.2929 7.70711C18.6834 7.31658 18.6834 6.68342 18.2929 6.29289L14.6457 2.64574C14.28 2.28002 13.6958 2.25352 13.2984 2.58463L8.35982 6.70015C8.13182 6.89015 8 7.1716 8 7.46838Z'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
		<line x1={10.75} y1={16.75} x2={10.75} y2={18.25} stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' />
		<line x1={10.75} y1={9.75} x2={10.75} y2={10.25} stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' />
		<path
			d='M9 6V6C8.86193 5.86193 8.86193 5.63807 9 5.5L9.75 4.75C10.0261 4.47386 10.4739 4.47386 10.75 4.75V4.75'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
	</svg>
);
export default VitalKit;
