import React, { useEffect, useRef } from 'react';

const Video = React.forwardRef(
	(
		/**
		 * @type {{feed: MediaStream} & React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>}
		 * */
		{ feed, ...htmlAttributes },
		ref
	) => {
		const ref1 = useRef(null);

		useEffect(() => {
			ref1.current.srcObject = feed;
			if (ref) {
				// @ts-ignore
				// eslint-disable-next-line no-param-reassign
				ref.current = ref1.current;
			}
		}, [feed]);

		return (
			<>
				<video {...htmlAttributes} ref={ref1} playsInline autoPlay />
				{/* playsInline is put for Safari in iOS */}
			</>
		);
	}
);
Video.displayName = 'CallVideo';

export default Video;
