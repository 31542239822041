const TrashIcon = props => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M15.4167 5.83333L14.8216 16.0869C14.7756 16.8802 14.1188 17.5 13.3242 17.5H6.67593C5.88126 17.5 5.22449 16.8802 5.17845 16.0869L4.58337 5.83333'
			stroke='currentColor'
			strokeWidth='1.5'
		/>
		<path d='M2.5 5L17.5 5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
		<path
			d='M12.4999 5V3.5C12.4999 2.94772 12.0522 2.5 11.4999 2.5H8.49988C7.94759 2.5 7.49988 2.94772 7.49988 3.5V5'
			stroke='currentColor'
			strokeWidth='1.5'
		/>
		<path
			d='M8.54171 14.1667L8.33337 7.5M11.6667 7.5L11.4584 14.1667'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default TrashIcon;
