const EmailPassword = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} fill='none' viewBox='0 0 24 24' {...props}>
		<rect width='14.667' height={11} x={3.667} y={8.25} stroke='currentColor' strokeWidth={1.5} rx={3} />
		<path
			stroke='currentColor'
			strokeWidth={1.5}
			d='M15.584 8.25v-.917A4.583 4.583 0 0011 2.75v0a4.583 4.583 0 00-4.583 4.583v.917'
		/>
		<path
			fill='currentColor'
			d='M12.367 14.056l-.559-.5a.75.75 0 00-.187.582l.746-.082zm-2.733 0l.745.082a.75.75 0 00-.186-.583l-.56.5zm2.45-1.223c0 .278-.104.53-.276.722l1.118 1a2.576 2.576 0 00.658-1.722h-1.5zM11 11.75c.599 0 1.084.485 1.084 1.083h1.5A2.583 2.583 0 0011 10.25v1.5zm-1.083 1.083c0-.598.485-1.083 1.083-1.083v-1.5a2.583 2.583 0 00-2.583 2.583h1.5zm.276.722a1.077 1.077 0 01-.276-.722h-1.5c0 .661.25 1.266.658 1.723l1.118-1zm.182.653c0-.024.002-.047.004-.07l-1.49-.165a2.139 2.139 0 00-.014.235h1.5zm0 .917v-.917h-1.5v.917h1.5zm.625.625a.625.625 0 01-.625-.625h-1.5c0 1.174.952 2.125 2.125 2.125v-1.5zm.625-.625c0 .345-.28.625-.625.625v1.5a2.125 2.125 0 002.125-2.125h-1.5zm0-.917v.917h1.5v-.917h-1.5zm-.004-.07a.649.649 0 01.004.07h1.5c0-.079-.004-.158-.013-.235l-1.49.165z'
		/>
	</svg>
);

export default EmailPassword;
