
import { HealthDataTypes } from 'constants/action-types.js';

export const actionCreators = {
	setWatchMeasurements: isWatchMeasurementVisible => dispatch => {
		dispatch({ type: HealthDataTypes.SET_WATCH_MEASUREMENTS_VISIBLE, payload: isWatchMeasurementVisible });
	},
	setIsFitToScreen: isFitToScreen => dispatch => {
		dispatch({ type: HealthDataTypes.SET_IS_FIT_TO_SCREEN, payload: isFitToScreen });
	},
	setIsSimplifiedPatientForm: isSimplifiedPatientForm => dispatch => {
		dispatch({ type: HealthDataTypes.SET_IS_SIMPLIFIED_PATIENT_FORM, payload: isSimplifiedPatientForm });
	},
};
