import * as React from "react"
import LightTheme from 'calls/styles/LightTheme.js';


const Environment = (props) => (
    <svg
        color={LightTheme.colors.graySeven}
        width={34}
        height={34}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M22.667 22.667c-3.904 0-7.084-3.139-7.084-7.001 0-4.333 4.676-7.822 7.084-10 2.408 2.178 7.083 5.667 7.083 10 0 3.858-3.18 7-7.083 7Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinejoin="round"
        />
        <path
            d="M22.667 12.75v15.583M22.667 18.417l2.833-2.834M22.667 17l-2.834-2.833"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.804 11.822a6.888 6.888 0 0 0-2.555-.489c-4.332 0-7.822 4.675-9.999 7.084 2.177 2.408 5.667 7.083 10 7.083 2.498 0 4.693-1.33 5.932-3.326"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinejoin="round"
        />
        <path
            d="M9.916 18.417h5.667M13.458 18.417l-2.125 2.125"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default Environment
