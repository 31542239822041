const DropupItem = props => (
	<div
		className={`horizontal-call-button flex ${props.isActive && !props.hasActiveToggle ? 'active' : ''}${
			props.className ? ` ${props.className}` : ''
		}`}
		onClick={props.onClick}>
		{props.imgSrc && <img src={props.imgSrc} alt='icon' />}
		{!props.imgSrc && props.icon && <i className='horizontal-call-btn-icon material-icons'>{props.icon}</i>}
		<p>{props.content}</p>
		{props.children}
		{props.hasActiveToggle && (
			<div className='rounded-slider-switch'>
				<input type='checkbox' checked={props.isActive} onChange={() => null} />
				<span className='rounded-slider' />
			</div>
		)}
	</div>
);

export default DropupItem;
