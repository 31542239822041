import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import HeartRateSummaryChart from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/HeartRateSummaryChart';
import OxygenSaturationSummaryChart from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/OxygenSaturationSummaryChart';
import BodyWeightSummaryChart from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/BodyWeightSummaryChart';
import BloodPressureSummaryChart from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/BloodPressureSummaryChart';
import BloodGlucoseSummaryChart from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/BloodGlucoseSummaryChart';
import PerfusionIndexSummaryChart from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/PerfusionIndexSummaryChart';
import TemperatureSummaryChart from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/TemperatureSummaryChart';
import StethoscopeSummaryChart from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/StethoscopeSummaryChart';
import SleepSummaryChart from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/SleepSummaryChart';
import WalkingRunningDistanceSummaryChart from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/WalkingRunningDistanceSummaryChart';
import FlightsClimbedSummaryChart from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/FlightsClimbedSummaryChart';
import StepsSummaryChart from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/StepsSummaryChart';
import { MeasurementTypes, MeasurementUnits, SpirometerIndex, UnitCategoryTypes } from 'constants/enums.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { roundMeasurementValue, convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import translate from 'i18n-translations/translate.jsx';
import SpirometerSummaryChart from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/SpirometerSummaryChart';
import { useSelector } from 'react-redux';

const SummaryDetail = props => {
	const history = useHistory();
	const unitPreferences = useSelector(state => state.user.unitPreferences);

	const getMeasurementType = () => {
		if (props.currentMeasurement.measurementType === MeasurementTypes.HEART) {
			return 'heartStethoscope';
		}
		if (props.currentMeasurement.measurementType === MeasurementTypes.LUNGS) {
			return 'lungsStethoscope';
		}
		return props.currentMeasurement.measurementType;
	};

	const navigateAsPatient = () => {
		if (!props.isOverviewSummaryDetail) {
			history.push(`/health-data/${getMeasurementType()}`);
		}
	};

	const getCategoryPreference = categoryId => unitPreferences.find(item => item.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const navigateAsDoctor = () => {
		if (props.isFromPrimaryCare) {
			return;
		}
		if (!props.isOverviewSummaryDetail && !props.isCallView) {
			history.push(`/patients/${props.selectedPatient.userId}/health-data/${getMeasurementType()}`);
		}
		if (props.isCallView && props.isHealthShown) {
			window.open(`/patients/${props.selectedPatient.userId}/health-data/${getMeasurementType()}`, '_blank');
		}
	};

	const getSummaryChartMaxValue = unit => {
		const isWeight = props.measurement.type === MeasurementTypes.WEIGHT;
		const isLeanBodyMass = props.measurement.type === MeasurementTypes.LEAN_BODY_MASS;
		const isTotalCholesterol = props.measurement.type === MeasurementTypes.TOTAL_CHOLESTEROL;
		const isHdlCholesterol = props.measurement.type === MeasurementTypes.HDL_CHOLESTEROL;
		const isWaistCircumference = props.measurement.type === MeasurementTypes.WAIST_CIRCUMFERENCE;
		if (isWeight) {
			if (unit === MeasurementUnits.KILOGRAMS) {
				return 400;
			}
			if (unit === MeasurementUnits.LBS) {
				return 800;
			}
			if (unit === MeasurementUnits.ST) {
				return 60;
			}
		}
		if (isLeanBodyMass) {
			if (unit === MeasurementUnits.KILOGRAMS) {
				return 124;
			}
			if (unit === MeasurementUnits.LBS) {
				return 275;
			}
			if (unit === MeasurementUnits.ST) {
				return 20;
			}
		}
		if (isTotalCholesterol) {
			if (unit === MeasurementUnits.MMOLL) {
				return 14.4;
			}
			if (unit === MeasurementUnits.MGDL) {
				return 259.2;
			}
		}
		if (isHdlCholesterol) {
			if (unit === MeasurementUnits.MMOLL) {
				return 3.3;
			}
			if (unit === MeasurementUnits.MGDL) {
				return 59.4;
			}
		}
		if (isWaistCircumference) {
			if (unit === MeasurementUnits.CENTIMETERS) {
				return 250;
			}
			if (unit === MeasurementUnits.FEET) {
				return 8.2;
			}
		}
		return false;
	};

	const getSummaryDetailContent = (type, content) => {
		if ((props.isOverViewSummary ? props.currentMeasurement.measurementType : props.measurement.type) === type) {
			return content;
		}
		return '';
	};

	const getSpirometerArr = value => (!value ? value : value.split('/'));

	const { measurementValue, measurementType, measurementUnit } = props.currentMeasurement;
	const roundedValue =
		measurementValue === 'No Data' || measurementType === MeasurementTypes.SPIROMETER
			? getSpirometerArr(measurementValue)[SpirometerIndex.TWO]
			: roundMeasurementValue(measurementValue, measurementType);
	const clickOnPatientSummary = props.isDoctorView ? navigateAsDoctor : navigateAsPatient;

	return (
		<div className='summary-data-box heart-rate-box' onClick={clickOnPatientSummary}>
			<div className='summary-data-box-title flex'>
				<p>
					{props.isOverViewSummary && props.overviewMeasurementTitle ? props.overviewMeasurementTitle : props.measurement.title}
				</p>
				{props.measurement.tooltip && (
					<span className='measurement-info' data-tooltip={props.measurement.tooltip} data-position={props.measurement.position}>
						<img src='https://static.solaborate.com/homecare-system/health-data/info.svg' alt='icon' />
					</span>
				)}
			</div>
			{unitPreferences.length > 0 && (
				<div className='summary-data-box-graph'>
					{measurementValue === 'No Data' && (
						<div className='flex summary-chart-data'>
							<h4>{translate('noData')}</h4>
						</div>
					)}
					{measurementValue !== 'No Data' && (
						<div className='summary-graph-container'>
							{getSummaryDetailContent(
								MeasurementTypes.HEART_RATE,
								<HeartRateSummaryChart
									measurementValue={roundedValue}
									measurementUnit={props.isOverViewSummary ? measurementUnit : props.measurement.unit}
									isMinimalVersion={props.isMinimalVersion}
								/>
							)}

							{getSummaryDetailContent(
								MeasurementTypes.OXYGEN,
								<OxygenSaturationSummaryChart
									measurementValue={roundedValue}
									measurementUnit={props.measurement.unit}
									isMinimalVersion={props.isMinimalVersion}
								/>
							)}

							{getSummaryDetailContent(
								MeasurementTypes.RESPIRATORY_RATE,
								<BloodGlucoseSummaryChart
									maxValue={60}
									measurementValue={roundedValue}
									measurementUnit={props.measurement.unit}
								/>
							)}

							{getSummaryDetailContent(
								MeasurementTypes.TOTAL_CHOLESTEROL,
								<BloodGlucoseSummaryChart
									maxValue={getSummaryChartMaxValue(getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unit)}
									measurementValue={convertMeasurementTypes(
										UnitCategoryTypes.BLOOD_GLUCOSE,
										roundedValue,
										getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId
									)}
									measurementUnit={getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unit}
								/>
							)}

							{getSummaryDetailContent(
								MeasurementTypes.HDL_CHOLESTEROL,
								<BloodGlucoseSummaryChart
									maxValue={getSummaryChartMaxValue(getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unit)}
									measurementValue={convertMeasurementTypes(
										UnitCategoryTypes.BLOOD_GLUCOSE,
										roundedValue,
										getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId
									)}
									measurementUnit={getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unit}
								/>
							)}

							{getSummaryDetailContent(
								MeasurementTypes.WAIST_CIRCUMFERENCE,
								<BodyWeightSummaryChart
									maxValue={getSummaryChartMaxValue(getUnitPreference(UnitCategoryTypes.HEIGHT)?.unit)}
									measurementValue={convertMeasurementTypes(
										UnitCategoryTypes.HEIGHT,
										roundedValue,
										getUnitPreference(UnitCategoryTypes.HEIGHT)?.unitSystemId
									)}
									measurementUnit={getUnitPreference(UnitCategoryTypes.HEIGHT)?.unit}
								/>
							)}

							{getSummaryDetailContent(
								MeasurementTypes.PI,
								<PerfusionIndexSummaryChart
									measurementValue={roundedValue}
									measurementUnit={props.isOverViewSummary ? measurementUnit : props.measurement.unit}
									isMinimalVersion={props.isMinimalVersion}
								/>
							)}

							{getSummaryDetailContent(
								MeasurementTypes.DIABETES,
								<BloodGlucoseSummaryChart
									measurementValue={convertMeasurementTypes(
										UnitCategoryTypes.BLOOD_GLUCOSE,
										roundedValue,
										getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId
									)}
									measurementUnit={getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unit}
									isMinimalVersion={props.isMinimalVersion}
								/>
							)}

							{getSummaryDetailContent(
								MeasurementTypes.BLOOD_PRESSURE,
								<BloodPressureSummaryChart
									measurementValue={roundedValue}
									measurementUnit={props.isOverViewSummary ? measurementUnit : props.measurement.unit}
									isMinimalVersion={props.isMinimalVersion}
								/>
							)}

							{getSummaryDetailContent(
								MeasurementTypes.WEIGHT,
								<BodyWeightSummaryChart
									maxValue={getSummaryChartMaxValue(getUnitPreference(UnitCategoryTypes.WEIGHT)?.unit)}
									measurementValue={convertMeasurementTypes(
										UnitCategoryTypes.WEIGHT,
										roundedValue,
										getUnitPreference(UnitCategoryTypes.WEIGHT)?.unitSystemId
									)}
									measurementUnit={getUnitPreference(UnitCategoryTypes.WEIGHT)?.unit}
								/>
							)}

							{getSummaryDetailContent(
								MeasurementTypes.LEAN_BODY_MASS,
								<BodyWeightSummaryChart
									maxValue={getSummaryChartMaxValue(getUnitPreference(UnitCategoryTypes.WEIGHT)?.unit)}
									measurementValue={convertMeasurementTypes(
										UnitCategoryTypes.WEIGHT,
										roundedValue,
										getUnitPreference(UnitCategoryTypes.WEIGHT)?.unitSystemId
									)}
									measurementUnit={getUnitPreference(UnitCategoryTypes.WEIGHT)?.unit}
								/>
							)}

							{getSummaryDetailContent(
								MeasurementTypes.BODY_FAT,
								<BodyWeightSummaryChart maxValue={75} measurementValue={roundedValue} measurementUnit={props.measurement.unit} />
							)}

							{getSummaryDetailContent(
								MeasurementTypes.BODY_MASS_INDEX,
								<BodyWeightSummaryChart maxValue={75} measurementValue={roundedValue} measurementUnit={props.measurement.unit} />
							)}

							{getSummaryDetailContent(
								MeasurementTypes.TEMPERATURE,
								<TemperatureSummaryChart
									measurementValue={convertMeasurementTypes(
										UnitCategoryTypes.TEMPERATURE,
										roundedValue,
										getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unitSystemId
									)}
									measurementUnit={getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unit}
									isMinimalVersion={props.isMinimalVersion}
								/>
							)}

							{getSummaryDetailContent(
								MeasurementTypes.SLEEP,
								<SleepSummaryChart measurementValue={roundedValue} measurementUnit={props.measurement.unit} />
							)}

							{getSummaryDetailContent(
								MeasurementTypes.DISTANCE_WALKING_RUNNING,
								<WalkingRunningDistanceSummaryChart
									measurementValue={convertMeasurementTypes(
										UnitCategoryTypes.DISTANCE,
										roundedValue,
										getUnitPreference(UnitCategoryTypes.DISTANCE)?.unitSystemId
									)}
									measurementUnit={getUnitPreference(UnitCategoryTypes.DISTANCE)?.unit}
								/>
							)}

							{getSummaryDetailContent(
								MeasurementTypes.FLIGHTS_CLIMBED,
								<FlightsClimbedSummaryChart
									maxValue={100}
									measurementValue={roundedValue}
									measurementUnit={props.measurement.unit}
								/>
							)}

							{getSummaryDetailContent(
								MeasurementTypes.STEPS,
								<StepsSummaryChart measurementValue={roundedValue} measurementUnit={props.measurement.unit} />
							)}

							{getSummaryDetailContent(
								MeasurementTypes.HEART,
								<StethoscopeSummaryChart measurementValue={roundedValue} measurementUnit={props.measurement.unit} />
							)}

							{getSummaryDetailContent(
								MeasurementTypes.LUNGS,
								<StethoscopeSummaryChart measurementValue={roundedValue} measurementUnit={props.measurement.unit} />
							)}

							{getSummaryDetailContent(
								MeasurementTypes.FALLEN_TIMES,
								<FlightsClimbedSummaryChart
									maxValue={30}
									measurementValue={roundedValue}
									measurementUnit={props.measurement.unit}
								/>
							)}
							{getSummaryDetailContent(
								MeasurementTypes.SPIROMETER,
								<SpirometerSummaryChart measurementValue={roundedValue} measurementUnit='%' />
							)}
						</div>
					)}
				</div>
			)}
			<div className='summary-data-box-info'>
				<div className='summary-data-box-status' />
				<div className='summary-data-box-date'>
					{props.currentMeasurement.startDate && <p>{formattedDate(moment.utc(props.currentMeasurement.startDate).local())}</p>}
				</div>
			</div>
		</div>
	);
};

export default SummaryDetail;
