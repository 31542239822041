import { generatePin, getPin } from 'api/whiteboard.js';
import Alert from 'components/Alert.jsx';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PinInput from 'react-pin-input';

const PinGenerate = props => {
	const [pin, setPin] = useState('');
	const [isSecret, setIsSecret] = useState(true);
	const [error, setError] = useState(null);
	const [primaryButtonLoading, setPrimaryButtonLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const response = await getPin(props.memberId);
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			} else {
				setPin(response.pin || '');
			}
			setIsLoading(false);
		};

		if (props.memberId) {
			fetchData();
		}
	}, [intl, props.memberId]);

	const regenerate = async () => {
		setIsSecret(true);
		setPrimaryButtonLoading(true);
		const response = await generatePin(props.memberId);
		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			setPrimaryButtonLoading(false);

			return;
		}
		setPin(response.pin || '');
		setPrimaryButtonLoading(false);
	};

	return (
		<>
			<Modal
				display={props.memberId}
				isLoading={isLoading}
				position='right'
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal admin-delete-modal pin-modal'
				primaryButtonLabel={translate(pin ? 'regeneratePin' : 'generatePin')}
				onModalSubmit={regenerate}
				onModalClose={() => {
					props.onModalClose();
					setIsSecret(true);
				}}
				isThirdButtonShown={pin}
				thirdButtonLabel={translate(isSecret ? 'view' : 'hide')}
				thirdBtnIcon={isSecret ? 'visibility' : 'visibility_off'}
				primaryButtonIcon='refresh'
				onThirdButtonClick={() => setIsSecret(prevState => !prevState)}
				background='var(--orange-1)'
				primaryButtonLoading={primaryButtonLoading}>
				<form>
					<h3>PIN</h3>
					{!pin && <p>{translate('noPinGenerate')}</p>}
					{pin && (
						<>
							{isSecret && (
								<PinInput
									initialValue={pin}
									length={6}
									secret={true}
									type='numeric'
									inputMode='number'
									onComplete={value => setPin(value)}
									autoSelect={true}
									regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
									disabled={true}
									placeholder='*'
								/>
							)}
							{!isSecret && (
								<PinInput
									initialValue={pin}
									length={6}
									secret={false}
									type='numeric'
									inputMode='number'
									onComplete={value => setPin(value)}
									autoSelect={true}
									regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
									disabled={true}
									placeholder='*'
								/>
							)}
						</>
					)}
				</form>
			</Modal>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default PinGenerate;
