import { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { Button, Input, PopUpAlert } from 'components/index.js';
import { AlertTypes } from 'constants/enums.js';
import { reorderObjects } from 'infrastructure/helpers/commonHelpers.js';
import { urlRegex } from 'infrastructure/helpers/validationHelper.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import StatAlarms from 'components/StatAlarms.jsx';
import {
	CallWorkflowType,
	INDEPENDENT_FEATURE_TYPE,
	MonitoringSettings,
	configurableWorkflowTypes,
	getConfigurationWithDiffTypeKey,
} from 'constants/configurationEnums.js';
import classNames from 'classnames';
import AiConfigurationFeatureFlags from 'components/AiConfigurationFeatureFlags.jsx';
import { prepareAiConfigsForSubmit, validateAiConfigurations } from 'infrastructure/helpers/aiHelper.js';
import { updateOrganizationSettings } from 'api/companies.js';
import { AiConfigurationSettings } from 'constants/ai.js';
import _ from 'lodash';

const CreateEditFeatureFlags = props => {
	const intl = useIntl();
	const [featureFlags, setFeaturesFlags] = useState(props.dataToSubmit[props.settingCategory]);
	const [independentFeatureFlags, setIndependentFeatureFlags] = useState({});
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [alertType, setAlertType] = useState(null);
	const [isStatAlarmModalOpen, setIsStatAlarmModalOpen] = useState(false);
	const [selectedType, setSelectedType] = useState(
		props.featureTypes
			? {
					value: props.featureTypes[0].value,
					label: intl.formatMessage({ id: props.featureTypes[0].label }),
			  }
			: null
	);
	const [expandedRows, setExpandedRows] = useState([]);
	const [disabledRows, setDisabledRows] = useState([]);
	const [aiErrors, setAiErrors] = useState([]);
	const [isAiError, setIsAiError] = useState(false);

	useEffect(() => {
		if (props.featureTypes) {
			const { independentFeatureFlags, filteredFeatureFlags } = filterConfigsByType(selectedType.value);
			setFeaturesFlags(filteredFeatureFlags);
			setIndependentFeatureFlags(independentFeatureFlags);
		}
		const isSetDefaultConfig = Object.values(featureFlags).find(item => item.isSelectDefaultConfig);
		setDisabledRows(isSetDefaultConfig ? [isSetDefaultConfig.variant.dependentFrom] : []);
	}, [selectedType]);

	const checkAiSettingsValidation = async (aiSettingsFeatureFlags, callWorkflowTypeId) => {
		const errorsArr = [];

		AiConfigurationSettings.forEach(async setting => {
			const aiSettingType = aiSettingsFeatureFlags[getConfigurationWithDiffTypeKey(setting, callWorkflowTypeId)];
			if (aiSettingType?.value) {
				const aiConfigErrors = validateAiConfigurations(aiSettingType, intl);
				if (Object.keys(aiConfigErrors.errors).length > 0) {
					errorsArr.push(aiConfigErrors);
					setDetailRows(
						+getConfigurationWithDiffTypeKey(setting, callWorkflowTypeId),
						!expandedRows.includes(+getConfigurationWithDiffTypeKey(setting, callWorkflowTypeId))
					);
				}
			}
		});
		return errorsArr;
	};

	const checkErrorsPerWorkflowType = async aiSettingsFeatureFlags => {
		for (let callWorkflowTypeId of [CallWorkflowType.MONITORING, CallWorkflowType.ROUNDING, CallWorkflowType.BACKGROUND]) {
			const errors = await checkAiSettingsValidation(aiSettingsFeatureFlags, callWorkflowTypeId);
			if (errors.length > 0) {
				if (callWorkflowTypeId !== selectedType?.value) {
					const foundWorkflowType = transformArray(configurableWorkflowTypes()).find(item => item.value === callWorkflowTypeId);
					if (foundWorkflowType) {
						setSelectedType(foundWorkflowType);
					}
				}
				return errors;
			}
		}
		return null;
	};

	const getValueForPayload = item => {
		let configValue = item.isURLField ? item.value || 'false' : item.value;
		if (item?.conditionalDependencies?.someConfigs) {
			item.conditionalDependencies.dependedFromConfigCategories.forEach(category => {
				configValue =
					configValue || item.conditionalDependencies.someConfigs.some(itemKey => props.dataToSubmit[category]?.[itemKey]?.value);
			});
		}
		return configValue.toString();
	};

	const getFlattenedItem = (config, key) => ({
		settingTypeId: config.settingTypeId ? +config.settingTypeId : +key,
		value: getValueForPayload(config),
		...(config.variant && { variant: config.variant.value }),
		...(config.roomTypeId && config.roomTypeId !== INDEPENDENT_FEATURE_TYPE && { roomTypeId: config.roomTypeId }),
		...(config.callWorkflowTypeId &&
			config.callWorkflowTypeId !== INDEPENDENT_FEATURE_TYPE && { callWorkflowTypeId: config.callWorkflowTypeId }),
		...(config.aiConfigurationTypeId &&
			config.value && {
				aiConfigurations: prepareAiConfigsForSubmit(config.aiConfigurations),
			}),
	});

	const flattenConfigs = data => {
		const recurse = obj =>
			Object.keys(obj).reduce((acc, key) => {
				const config = obj[key];
				const flattenedItem = getFlattenedItem(config, key);
				const children = config.childrenConfigs ? recurse(config.childrenConfigs) : [];
				let checkboxVariantItem = null;
				if (config.checkboxVariant) {
					checkboxVariantItem = { settingTypeId: config.checkboxVariant.key, value: config.checkboxVariant.value.toString() };
				}
				return [...acc, flattenedItem, ...children, ...(checkboxVariantItem ? [checkboxVariantItem] : [])];
			}, []);

		return Object.keys(data).reduce((acc, category) => acc.concat(recurse(data[category])), []);
	};

	const onSubmit = async e => {
		e.preventDefault();
		setAiErrors([]);
		setIsAiError(false);
		const aiSettingsFeatureFlags = props.dataToSubmit.aiSettings;
		const foundErrors = await checkErrorsPerWorkflowType(aiSettingsFeatureFlags || []);
		if (aiSettingsFeatureFlags && foundErrors) {
			setAiErrors(foundErrors);
			setIsAiError(true);
			return;
		}
		if (!props.selectedCompany) {
			props.setDataToSubmit(featureFlags);
			props.goToNextTab();
		} else {
			setIsSaveLoading(true);

			const settingsParams = {
				companySettings: flattenConfigs(props.dataToSubmit),
				companyId: props.selectedCompany.id,
			};

			const response = await updateOrganizationSettings(settingsParams);
			if (!response.error) {
				setAlertType(AlertTypes.SUCCESS);
				props.setDataToSubmit(featureFlags);
			} else {
				setAlertType(AlertTypes.DANGER);
				if (props.featureTypes) {
					const { independentFeatureFlags, filteredFeatureFlags } = filterConfigsByType(selectedType.value);
					setFeaturesFlags(filteredFeatureFlags);
					setIndependentFeatureFlags(independentFeatureFlags);
				} else {
					setFeaturesFlags(props.dataToSubmit[props.settingCategory]);
				}
			}

			setIsSaveLoading(false);
		}
	};

	const toggleValue = (item, value = !item.value) => {
		item.value = value;
		if (item.variant && item.options && item.value) {
			item.variant = item.options[0];
		}
	};

	const disableDependedConfigs = (copiedConfig, configs) => {
		if (copiedConfig?.otherConfigToDisable) {
			copiedConfig.otherConfigToDisable.forEach(dep => toggleValue(configs[dep], false));
		}
		if (copiedConfig?.disableChildren) {
			Object.keys(copiedConfig.childrenConfigs).forEach(childKey => toggleValue(copiedConfig.childrenConfigs[childKey], false));
		}
		if (copiedConfig?.checkboxVariant) {
			toggleValue(copiedConfig.checkboxVariant, false);
		}
	};

	const toggleItem = (key, parentKey, variantKey) => {
		setFeaturesFlags(prevState => {
			const configsCopied = { ...prevState };
			const copiedConfig = parentKey ? { ...configsCopied[parentKey].childrenConfigs[key] } : { ...configsCopied[key] };
			if (variantKey) {
				const copiedVariant = { ...copiedConfig.checkboxVariant };
				copiedVariant.value = !copiedVariant.value;
				copiedConfig.checkboxVariant = copiedVariant;
			} else {
				toggleValue(copiedConfig);
				disableDependedConfigs(copiedConfig, configsCopied);
			}
			return { ...prevState, ...configsCopied };
		});
		const createCompanyState = { ...props.dataToSubmit[props.settingCategory] };
		const createCompanyConfig = parentKey ? createCompanyState[parentKey].childrenConfigs[key] : createCompanyState[key];

		if (createCompanyConfig.isSelectDefaultConfig) {
			setDisabledRows(!createCompanyConfig?.value ? [createCompanyConfig.variant.dependentFrom] : []);
		}

		if (variantKey) {
			toggleValue(createCompanyConfig.checkboxVariant);
		} else {
			toggleValue(createCompanyConfig);
			setDetailRows(key, !createCompanyConfig.value);
			disableDependedConfigs(createCompanyConfig, createCompanyState);
		}
		props.setDataToSubmit(prevState => ({ ...prevState, [props.settingCategory]: createCompanyState }));
	};

	const customizeConfig = (key, variant, parentKey) => {
		setFeaturesFlags(prevState => {
			const configsCopied = { ...prevState };
			const copiedConfig = parentKey ? configsCopied[parentKey].childrenConfigs[key] : configsCopied[key];
			copiedConfig.variant = copiedConfig.options.find(opt => opt.value === variant);
			setDisabledRows(copiedConfig.isSelectDefaultConfig ? [copiedConfig.variant.dependentFrom] : []);
			return configsCopied;
		});
		const createCompanyState = { ...props.dataToSubmit[props.settingCategory] };
		const copiedCreateCompanyState = parentKey ? createCompanyState[parentKey].childrenConfigs[key] : createCompanyState[key];
		copiedCreateCompanyState.variant = copiedCreateCompanyState.options.find(opt => opt.value === variant);
		props.setDataToSubmit(prevState => ({ ...prevState, [props.settingCategory]: createCompanyState }));
	};

	const handleURLInput = (key, value) => {
		const validURL = urlRegex.test(value);
		setFeaturesFlags(prevState => {
			const configsCopied = { ...prevState };
			configsCopied[key].value = value.trim() ? value : 'false';
			configsCopied[key].valid = validURL;

			return configsCopied;
		});
		const createCompanyState = { ...props.dataToSubmit[props.settingCategory] };
		createCompanyState[key].value = value;
		props.setDataToSubmit(prevState => ({ ...prevState, [props.settingCategory]: createCompanyState }));
	};

	const transformArray = array =>
		array.reduce((accumulator, current) => {
			if (!current.dependentFrom || featureFlags[current.dependentFrom]?.value) {
				accumulator.push({ value: current.value, label: intl.formatMessage({ id: current.label }) });
			}
			return accumulator;
		}, []);

	const groupedCategories = ffList =>
		Object.entries(ffList).reduce((acc, [key, item]) => {
			const { category, orderNr } = item;
			if (!acc[category]) {
				acc[category] = [];
			}
			if (!item.conditionalDependencies || checkDependencyCondition(item.conditionalDependencies)) {
				const index = acc[category].findIndex(([, existingItem]) => (existingItem.orderNr ?? Infinity) > (orderNr ?? Infinity));
				if (index === -1) {
					acc[category].push([key, item]);
				} else {
					acc[category].splice(index, 0, [key, item]);
				}
			}

			return acc;
		}, []);

	const checkDependencyCondition = conditionsObj => {
		const everyConfigsToCheck = [];
		let atLeastOneEnabled = false;
		conditionsObj.dependedFromConfigCategories.forEach(category => {
			if (!atLeastOneEnabled && conditionsObj.someConfigs) {
				atLeastOneEnabled = conditionsObj.someConfigs.some(item => props.dataToSubmit[category]?.[item]?.value);
			}
			if (conditionsObj.everyConfig) {
				conditionsObj.everyConfig.forEach(item => {
					if (props.dataToSubmit[category][item]) {
						everyConfigsToCheck.push(props.dataToSubmit[category][item]);
					}
				});
			}
		});

		return everyConfigsToCheck.length === 0 ? atLeastOneEnabled : everyConfigsToCheck.every(item => item.value);
	};

	const featureFlagsCategories = ffList =>
		props.categoryOrder ? reorderObjects(groupedCategories(ffList), props.categoryOrder) : groupedCategories(ffList);

	const filterConfigsByType = type =>
		Object.keys(props.dataToSubmit[props.settingCategory]).reduce(
			(acc, key) => {
				if (props.dataToSubmit[props.settingCategory][key][props.typeKey] === INDEPENDENT_FEATURE_TYPE) {
					acc.independentFeatureFlags[key] = props.dataToSubmit[props.settingCategory][key];
				} else if (props.dataToSubmit[props.settingCategory][key][props.typeKey] === type) {
					acc.filteredFeatureFlags[key] = props.dataToSubmit[props.settingCategory][key];
				}
				return acc;
			},
			{ independentFeatureFlags: {}, filteredFeatureFlags: {} }
		);

	const handleAiConfigsChange = ({ key, aiConfigs }) => {
		if (!key || !aiConfigs) {
			return;
		}
		setFeaturesFlags(prevState => {
			const configsCopied = { ...prevState };
			configsCopied[key]['aiConfigurations'] = aiConfigs;
			return configsCopied;
		});
		const createCompanyState = { ...props.dataToSubmit[props.settingCategory] };
		createCompanyState[key]['aiConfigurations'] = aiConfigs;
		props.setDataToSubmit(prevState => ({ ...prevState, [props.settingCategory]: createCompanyState }));
	};

	const setDetailRows = (key, value = true) => {
		setExpandedRows(prevExpandedRows =>
			prevExpandedRows.includes(key) && value ? prevExpandedRows.filter(row => row !== key) : [...prevExpandedRows, key]
		);
	};

	const renderFeatureFlag = (item, key, parentKey) => (
		<Fragment key={key}>
			<div className='feature-flag flex' key={key}>
				{!item.isURLField && (
					<div className={classNames('toggle-config', { disabled: disabledRows.includes(+key) })}>
						<div className='rounded-slider-switch' onClick={() => toggleItem(+key, +parentKey)}>
							<input type='checkbox' checked={item.value === 'true' || item.value === true} onChange={() => null} />
							<span className='rounded-slider' />
						</div>
						<p>{item.value ? translate('on') : translate('off')}</p>
					</div>
				)}
				<div className={classNames('feature-description', { 'no-toggle-shown': item.isURLField })}>
					<p className='flex-1'>
						{translate(item.title, {
							roleNameNurse: props.customDisplayNames?.nurseDisplayName,
							roleNameVS: props.customDisplayNames?.virtualSitterDisplayName,
						})}
					</p>
					<p>
						{translate(item.description, {
							value: translate('user'),
							huddleName: props.customDisplayNames?.huddleName,
							helloName: props.customDisplayNames?.helloName,
							roleNameNurse: props.customDisplayNames?.nurseDisplayName,
							roleNameVS: props.customDisplayNames?.virtualSitterDisplayName,
						})}
					</p>
					{item.value && !item.hasButton && (
						<div className='feature-flag-suboptions'>
							{item.options && (
								<div className='feature-flag-options flex'>
									<p>{translate(item.variantTitle || 'customize')}</p>
									<Select
										value={transformArray([item.variant])}
										classNamePrefix='react-select'
										options={transformArray(item.options)}
										onChange={event => customizeConfig(key, event.value, parentKey)}
									/>
								</div>
							)}
							{item.checkboxVariant && (
								<div
									className='toggle-config flex cursor-pointer'
									onClick={() => toggleItem(+key, +parentKey, +item.checkboxVariant.key)}>
									<div>
										<input
											type='checkbox'
											checked={item.checkboxVariant.value === 'true' || item.checkboxVariant.value}
											onChange={() => null}
										/>
									</div>
									<p>{translate(item.checkboxVariant.title)}</p>
								</div>
							)}
						</div>
					)}

					{item.hasButton && item.value && (
						<div className='flex flex-align-center'>
							{item.variant?.value && (
								<div className='flex flex-space-between stat-alarm-details selected-alarm cursor-pointer active'>
									<div className='flex flex-align-center full-width'>
										<i className='material-icons-outlined'>notifications_active</i>
										<span className='font-14 left-s --blue-light-5'>
											{intl.formatMessage({ id: item.variant?.label }, { value: item.variant?.translateValue })}
										</span>
									</div>
								</div>
							)}
							<div className='flex stat-alarm-flag cursor-pointer' onClick={() => setIsStatAlarmModalOpen(true)}>
								<img src={`${healthCareCdnUrl}icon/change.svg`} alt='icon' />
								<span>{translate('selectOtherAlarm')}</span>
							</div>
						</div>
					)}
					{item.isURLField && (
						<div className='feature-flag-text-input'>
							<Input
								type='text'
								placeholder={`http://example.com ${intl.formatMessage({ id: 'or' })} https://example.com`}
								value={item.value === 'false' ? '' : item.value}
								inputWidth='300px'
								onChange={event => handleURLInput(key, event.target.value)}
								name='item.title'
							/>
							{!!item.value && !item?.valid && <span className='red-error'>{translate('badURL')}</span>}
						</div>
					)}
					{item.aiConfigurationTypeId && (
						<div className='flex'>
							{expandedRows.includes(+key) && (
								<AiConfigurationFeatureFlags
									onAiConfigsChange={handleAiConfigsChange}
									settingTypeId={key}
									aiErrors={aiErrors}
									selectedAiConfig={item}
								/>
							)}
						</div>
					)}
				</div>
				{item.isExpandable && item.value && (
					<span className='flex cursor-pointer margin-left-auto expand-ff' onClick={() => setDetailRows(+key)}>
						<i className='material-icons-outlined'>{expandedRows.includes(+key) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i>
					</span>
				)}
			</div>
			{item.childrenConfigs &&
				expandedRows.includes(+key) &&
				Object.entries(featureFlagsCategories(item.childrenConfigs)).map(([categoryName, category]) => (
					<div className={classNames('feature-flags-category children-configs', { disabled: !item.value })} key={category}>
						{categoryName !== item.category && (
							<h4>
								{translate(categoryName, {
									roleNameNurse: props.customDisplayNames?.nurseDisplayName,
									roleNameVS: props.customDisplayNames?.virtualSitterDisplayName,
								})}
							</h4>
						)}
						{item.dependentConfigsTitle && <p className='feature-dependent-title'>{translate(item.dependentConfigsTitle)}</p>}
						{item.secondDependentConfigsTitle && (
							<p className='feature-dependent-title'>{translate(item.secondDependentConfigsTitle)}</p>
						)}
						{category.map(([childKey, childItem]) => renderFeatureFlag(childItem, childKey, key))}
					</div>
				))}
		</Fragment>
	);

	return (
		<div>
			{Object.entries(featureFlagsCategories(independentFeatureFlags)).map(([categoryName, category]) => (
				<div className='feature-flags-category' key={categoryName}>
					{categoryName !== props.settingCategory && (
						<h4>
							{translate(categoryName, {
								roleNameNurse: props.customDisplayNames?.nurseDisplayName,
								roleNameVS: props.customDisplayNames?.virtualSitterDisplayName,
							})}
						</h4>
					)}
					{category?.map(([key, item]) => renderFeatureFlag(item, key))}
				</div>
			))}
			{props.featureTypes && (
				<div className='feature-flags-header'>
					<div>
						<h4>{translate(props.typeTitle)}</h4>
						<Select
							value={selectedType}
							classNamePrefix='react-select'
							options={transformArray(props.featureTypes)}
							onChange={val => {
								setExpandedRows([]);
								setSelectedType(val);
							}}
						/>
					</div>
				</div>
			)}
			{Object.entries(featureFlagsCategories(featureFlags)).map(([categoryName, category]) => (
				<div className='feature-flags-category' key={categoryName}>
					{categoryName !== props.settingCategory && (
						<h4>
							{translate(categoryName, {
								roleNameNurse: props.customDisplayNames?.nurseDisplayName,
								roleNameVS: props.customDisplayNames?.virtualSitterDisplayName,
							})}
						</h4>
					)}
					{category.map(([key, item]) => renderFeatureFlag(item, key))}
				</div>
			))}
			<div className='create-hs__add'>
				{!props.selectedCompany && (
					<Button text={translate('goBack')} variant='white' onClick={props.goToPreviousTab} disabled={!props.isValid} />
				)}
				<Button
					type='submit'
					text={translate(props.selectedCompany ? 'save' : 'nextStep')}
					onClick={onSubmit}
					isLoading={isSaveLoading}
				/>
			</div>
			<PopUpAlert
				alertType={alertType}
				display={alertType}
				onAlertClose={() => setAlertType(null)}
				contentText={intl.formatMessage({ id: alertType === AlertTypes.SUCCESS ? 'changesSaved' : 'somethingWentWrong' })}
				isSilent={true}
				center={true}
				selfCloseTimeOut={1500}
			/>
			{aiErrors.length > 0 && props.currentTab !== 5 && (
				<PopUpAlert
					alertType={AlertTypes.DANGER}
					display={isAiError}
					onAlertClose={() => setIsAiError(false)}
					contentText={intl.formatMessage({ id: 'fillRequiredFieldsAi' })}
					isSilent={true}
					center={true}
					selfCloseTimeOut={1500}
				/>
			)}
			<StatAlarms
				isStatAlarmModalOpen={isStatAlarmModalOpen}
				setIsStatAlarmModalOpen={setIsStatAlarmModalOpen}
				customizeConfig={customizeConfig}
				config={props.dataToSubmit[props.settingCategory][MonitoringSettings.StatAlarm]}
			/>
		</div>
	);
};

export default CreateEditFeatureFlags;
