import React, { useEffect, useRef, useState } from 'react';
import { Modal, Alert } from 'components';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { getDoctorAssigned } from 'api/doctors.js';
import { PresenceStatusType } from 'constants/enums.js';
import { useSelector } from 'react-redux';
import { enQueueToMedic } from 'api/patientRequests.js';
import Loader from 'components/Loader.jsx';
import Input from 'components/Input.jsx';

const AssignToDoctor = ({ selectedRequest, setSelectedRequest, updateListing, healthSystemId }) => {
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [doctors, setDoctors] = useState([]);
	const [selectedDoctor, setSelectedDoctor] = useState(null);
	const userSession = useSelector(state => state.user.userSession);
	const [pageIndex, setPageIndex] = useState(0);
	const [searchQuery, setSearchQuery] = useState('');
	const hasReachedEnd = useRef(null);
	const intl = useIntl();
	const onSearchTypeTimeout = useRef(null);

	useEffect(() => {
		return () => {
			if (onSearchTypeTimeout.current) {
				clearTimeout(onSearchTypeTimeout.current);
			}
		};
	}, []);

	useEffect(() => {
		const fetchDoctors = async () => {
			if (onSearchTypeTimeout.current) {
				clearTimeout(onSearchTypeTimeout.current);
			}
			onSearchTypeTimeout.current = setTimeout(async () => {
				if (pageIndex === 0) {
					setIsLoading(true);
					setSelectedDoctor(null);
				}
				const response = await getDoctorAssigned(userSession.healthSystem.id, {
					pageIndex,
					presenceStatus: PresenceStatusType.AVAILABLE,
					search: searchQuery,
				});
				if (response.error) {
					setError(response.error.message);
				} else {
					setDoctors(prevState => (pageIndex === 0 ? response.doctors : [...prevState, ...response.doctors]));
				}
				setIsLoading(false);
			}, 500);
		};
		fetchDoctors();
	}, [selectedRequest, pageIndex, searchQuery]);

	const handleSearchChange = value => {
		setSearchQuery(value);
		setPageIndex(0);
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const handleTransferQueue = async () => {
		setIsLoading(true);
		const params = { healthSystemId, requestId: selectedRequest.id, medicId: selectedDoctor?.id };
		const response = await enQueueToMedic(params);
		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}

		updateListing(selectedRequest);
		setIsLoading(false);
		setSelectedRequest(null);
	};

	return (
		<>
			<Modal
				display={true}
				position='right'
				className='assign-patients-modal'
				primaryButtonLabel={translate('transfer')}
				isSubmitDisabled={!selectedDoctor}
				onModalSubmit={handleTransferQueue}
				onModalClose={() => {
					setSelectedRequest(null);
				}}>
				<div className='padding-sl queue-modal assign-to-doctor-modal'>
					<h4>{translate('doctors')}</h4>
					<div className='inner-queue-modal' onScroll={handleScroll}>
						{(!!doctors.length || !!searchQuery) && (
							<div className='flex patients-alert-filter margin-bottom-xl'>
								<Input
									type='text'
									placeholder={intl.formatMessage({ id: 'search' })}
									bottomSpace='0'
									variant='filled flex-1'
									name='searchUsers'
									maxLength={100}
									value={searchQuery}
									onChange={event => handleSearchChange(event.target.value)}
								/>
							</div>
						)}
						{isLoading && (
							<div className='assign-to-doctor-loading'>
								<Loader />
							</div>
						)}
						{doctors.length === 0 && !isLoading && (
							<div className='text-align-center'>
								<p className='no-data'>{translate('noAvailableDoctor')}</p>
							</div>
						)}
						{!isLoading &&
							doctors.map(doctor => (
								<div
									className={`home-waiting-room-inner available-doctor-queue ${selectedDoctor?.id === doctor.id ? 'active' : ''}`}
									key={doctor.id}
									onClick={() => setSelectedDoctor(doctor)}>
									<div className='flex'>
										<ProfilePicture
											className='doctor-request-img patient-request-profile-img'
											firstName={doctor.firstName}
											lastName={doctor.lastName}
											profilePicture={doctor.profilePicture}
										/>
										<div>
											<h4>
												{doctor.firstName} {doctor.lastName}
											</h4>
										</div>
										<div className='available-doctor-queue-status'>
											{!!doctor.pendingRequests.length && (
												<span
													className='available-doctor-queue-count'
													data-tooltip={intl.formatMessage({ id: 'patientsInQueue' }, { value: doctor.pendingRequests.length })}
													data-position='top'>
													{doctor.pendingRequests.length}
													<i className='material-icons'>person</i>
												</span>
											)}
											<span>
												<i className='material-icons --green-color'>fiber_manual_record</i>
												{translate('available')}
											</span>
										</div>
									</div>
								</div>
							))}
					</div>
				</div>
			</Modal>
			{error && <Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />}
		</>
	);
};

export default AssignToDoctor;
