import React from 'react';

const BedRails = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='16' height='10' fill='none' {...props}>
		<path
			fill='currentColor'
			stroke='currentColor'
			strokeMiterlimit='10'
			strokeWidth='1.5'
			d='M4.525 2.425a.675.675 0 100-1.35.675.675 0 000 1.35z'
		/>
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			strokeWidth='1.5'
			d='M2.05 2.2h-.9v5.4h1.8l.45-.9h7.2l.45.9h1.8V5.125H3.4L2.05 2.2z'
		/>
		<path
			fill='currentColor'
			d='M6.18 2.57a.75.75 0 10-1.06 1.06l1.06-1.06zm-.08.98l-.53.53c.14.142.331.22.53.22v-.75zm5.85.75a.75.75 0 000-1.5v1.5zm-6.83-.67l.45.45 1.06-1.06-.45-.45-1.06 1.06zm.98.67h5.85V2.8H6.1v1.5z'
		/>
	</svg>
);

export default BedRails;
