import { Chart } from 'primereact/chart.js';

const VerticalBarChart = props => (
	<div>
		<div className='content-section implementation'>
			<Chart
				ref={props.chartRef}
				type='bar'
				data={{ labels: props.labels, datasets: props.datasets }}
				height={props.height || '40vh'}
				options={{ ...props.options, maintainAspectRatio: false }}
			/>
		</div>
	</div>
);

export default VerticalBarChart;
