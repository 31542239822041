const Heart = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} viewBox='0 0 24 24' fill='none' {...props}>
		<path
			d='M12.5713 19.9451C-4.7729 10.3777 7.36799 -0.0674572 12.571 6.50003C17.7747 -0.0674596 29.9161 10.3777 12.5713 19.9451Z'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinejoin='round'
		/>
	</svg>
);
export default Heart;
