import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Patch = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' color={LightTheme.colors.graySeven} {...props}>
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			d='M12 19.228c-15.417-8.505-4.625-17.79 0-11.951 4.625-5.838 15.418 3.446 0 11.95Z'
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M3 13.505h5l1-1.5 1.5 4 1.5-6.5 1.5 5 1.453-3.153 1.048 1.653h5'
		/>
	</svg>
);
export default Patch;
