import { useEffect, useState } from 'react';
import { HelloSettings } from 'constants/enums.js';
import { isJSON } from 'infrastructure/helpers/commonHelpers.js';
import Button from 'components/Button.jsx';
import translate from 'i18n-translations/translate.jsx';

const DIRECTION_CLASSES = {
	topLeft: 'top-15 left-15',
	topRight: 'top-15 right-15',
	bottomLeft: 'bottom-15 left-15',
	bottomRight: 'bottom-15 right-15',
	columnDirection: 'column-direction',
};

const ICONS = {
	north: 'north',
	south: 'south',
	west: 'west',
	east: 'east',
	add: 'add',
	remove: 'remove',
};

const getAspectRatioDirections = (aspectRatio, setAspectRatio, step) => [
	{
		isDisabled: aspectRatio.height === 100,
		onClick: () => aspectRatio.height !== 100 && setAspectRatio(prevState => ({ ...prevState, height: prevState.height + step })),
		wrapperClass: `${DIRECTION_CLASSES.columnDirection} ${DIRECTION_CLASSES.topLeft}`,
		icon1: ICONS.south,
		icon2: ICONS.remove,
		icon3: ICONS.north,
	},
	{
		isDisabled: aspectRatio.width === 0,
		onClick: () => aspectRatio.width !== 0 && setAspectRatio(prevState => ({ ...prevState, width: prevState.width - step })),
		wrapperClass: DIRECTION_CLASSES.topRight,
		icon1: ICONS.west,
		icon2: ICONS.add,
		icon3: ICONS.east,
	},
	{
		isDisabled: aspectRatio.width === 100,
		onClick: () => aspectRatio.width !== 100 && setAspectRatio(prevState => ({ ...prevState, width: prevState.width + step })),
		wrapperClass: DIRECTION_CLASSES.bottomLeft,
		icon1: ICONS.east,
		icon2: ICONS.remove,
		icon3: ICONS.west,
	},
	{
		isDisabled: aspectRatio.height === 0,
		onClick: () => aspectRatio.height !== 0 && setAspectRatio(prevState => ({ ...prevState, height: prevState.height - step })),
		wrapperClass: `${DIRECTION_CLASSES.columnDirection} ${DIRECTION_CLASSES.bottomRight}`,
		icon1: ICONS.north,
		icon2: ICONS.add,
		icon3: ICONS.south,
	},
];

const AspectRatioIcon = ({ wrapperClass, icon1, icon2, icon3 }) => (
	<div className={`aspect-ratio-icon-wrapper ${wrapperClass}`}>
		<span className='material-icons-outlined'>{icon1}</span>
		<div>
			<span className='material-icons-outlined'>{icon2}</span>
		</div>
		<span className='material-icons-outlined'>{icon3}</span>
	</div>
);

const AspectRatio = ({ updateDeviceSetting, deviceSettings, isSettingUpdating }) => {
	const getAspectRatioObj = () => {
		const foundSetting = deviceSettings?.find(item => item.settingTypeId === HelloSettings.ASPECT_RATIO);
		if (isJSON(foundSetting?.value)) {
			return JSON.parse(foundSetting.value);
		}
		return { height: 0, width: 0 };
	};

	const [aspectRatio, setAspectRatio] = useState(getAspectRatioObj());
	const step = 5;

	const aspectRatioDirections = getAspectRatioDirections(aspectRatio, setAspectRatio, step);

	useEffect(() => {
		updateDeviceSetting(HelloSettings.ASPECT_RATIO, JSON.stringify(aspectRatio));
	}, [aspectRatio]);

	return (
		<div className='configurable-settings-modal-inner'>
			<h3>{translate('aspectRatio')}</h3>
			<div className='aspect-ratio-controller'>
				<div className='aspect-ratio-buttons-wrapper'>
					{aspectRatioDirections.map((config, index) => (
						<Button
							key={index}
							isDisabled={isSettingUpdating || config.isDisabled}
							onClick={config.onClick}
							svgIcon={
								<AspectRatioIcon
									wrapperClass={config.wrapperClass}
									icon1={config.icon1}
									icon2={config.icon2}
									icon3={config.icon3}
								/>
							}
						/>
					))}
					<div className='inner-circle'></div>
				</div>
			</div>
		</div>
	);
};

export default AspectRatio;
