const Weight = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} viewBox='0 0 24 24' fill='none' {...props}>
		<path
			d='M12.0928 10.8398C12.0068 10.7548 12.0176 10.6129 12.1156 10.5419L12.8202 10.0313C12.8446 10.0136 12.8785 10.0175 12.8983 10.0403V10.0403C12.916 10.0608 12.9173 10.0908 12.9013 10.1127L12.3888 10.8159C12.3182 10.9128 12.178 10.9241 12.0928 10.8398V10.8398Z'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M8.42859 9C8.9255 8.37223 9.53984 7.86871 10.23 7.52365C10.9201 7.17859 11.6699 7 12.4286 7C13.1873 7 13.9371 7.17859 14.6272 7.52365C15.3173 7.86871 15.9317 8.37223 16.4286 9'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<rect x={3.42859} y={4} width={18} height={16} rx={4} stroke='currentColor' strokeWidth={1.5} />
	</svg>
);
export default Weight;
