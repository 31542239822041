import classNames from 'classnames';
import Button from 'components/Button.jsx';
import translate from 'i18n-translations/translate.jsx';
import React from 'react';

const OverviewDetails = props => (
	<div className={classNames('schedule-wrapper', props.className)}>
		<div className='flex flex-space-between'>
			<span>{translate(props.title)}</span>
			<div className='flex gap-s'>
				<Button variant='white' text={translate('showAll')} className='flex-align-center' onClick={props.onClick} />
			</div>
		</div>
		{props.children}
	</div>
);

export default OverviewDetails;
