/* eslint-disable max-classes-per-file */
import { LocalTrack } from '@solaborate/calls/webrtc';
import { CLEAR_TIMEOUT, SET_TIMEOUT, TIMEOUT_TICK, timerWorkerScript } from 'calls/workers/TimerWorker.js';
import { APP_CONFIG } from 'constants/global-variables.js';

export const VIRTUAL_BACKGROUND_TYPE = {
	IMAGE: 'image',
	BLUR: 'blur',
	NONE: 'none',
};

class Background {
	constructor(width, height) {
		this.width = width;
		this.height = height;
	}
}

export class BackgroundNone extends Background {}
export class BackgroundBlur extends Background {
	constructor(width, height, blurValue) {
		super(width, height);
		this.blurValue = blurValue;
	}
}

export class BackgroundImage extends Background {
	constructor(imageUrl) {
		super();
		this.imageUrl = imageUrl;
	}
}

export class StreamBackgroundEffect extends LocalTrack {
	constructor(track, model, options) {
		super(track.type, track.track, track.constrains);

		this.options = options;

		this.stream = new MediaStream([track.track]);

		this.originalTrack = track;

		if (this.options.virtualBackground.backgroundType === VIRTUAL_BACKGROUND_TYPE.IMAGE) {
			this.virtualImage = document.createElement('img');
			this.virtualImage.crossOrigin = 'anonymous';
			this.virtualImage.src = this.options.virtualBackground.virtualSource;
		}
		this.model = model;
		this.segmentationPixelCount = this.options.width * this.options.height;

		// Bind event handler so it is only bound once for every instance
		this.onMaskFrameTimer = this.onMaskFrameTimer.bind(this);

		// Workaround for FF issue https://bugzilla.mozilla.org/show_bug.cgi?id=1388974
		this.outputCanvasElement = document.createElement('canvas');
		this.outputCanvasElement.getContext('2d');
		this.inputVideoElement = document.createElement('video');
		this.startEffect(this.stream);
	}

	onMaskFrameTimer(response) {
		if (response.data.id === TIMEOUT_TICK) {
			this.renderMask();
		}
	}

	runPostProcessing() {
		const { height, width } = this.track.getSettings() ?? this.track.getConstraints();
		const { backgroundType } = this.options.virtualBackground;

		this.outputCanvasElement.height = typeof height === 'number' ? height : (height.exact && height.exact) || 0;
		this.outputCanvasElement.width = typeof width === 'number' ? width : (width.exact && width.exact) || 0;
		this.outputCanvasCtx.globalCompositeOperation = 'copy';

		// Smooth out edges.
		this.outputCanvasCtx.filter = backgroundType === VIRTUAL_BACKGROUND_TYPE.IMAGE ? 'blur(4px)' : 'blur(8px)';
		this.outputCanvasCtx.drawImage(
			this.segmentationMaskCanvas,
			0,
			0,
			this.options.width,
			this.options.height,
			0,
			0,
			this.inputVideoElement.width,
			this.inputVideoElement.height
		);
		this.outputCanvasCtx.globalCompositeOperation = 'source-in';
		this.outputCanvasCtx.filter = 'none';

		// Draw the foreground video.
		this.outputCanvasCtx.drawImage(this.inputVideoElement, 0, 0);

		// Draw the background.
		this.outputCanvasCtx.globalCompositeOperation = 'destination-over';
		if (backgroundType === VIRTUAL_BACKGROUND_TYPE.IMAGE) {
			this.outputCanvasCtx.drawImage(this.virtualImage, 0, 0, this.outputCanvasElement.width, this.outputCanvasElement.height);
		} else {
			this.outputCanvasCtx.filter = `blur(${this.options.virtualBackground.blurValue}px)`;
			this.outputCanvasCtx.drawImage(this.inputVideoElement, 0, 0);
		}
	}

	runInference() {
		this.model._runInference();
		const outputMemoryOffset = this.model._getOutputMemoryOffset() / 4;

		for (let i = 0; i < this.segmentationPixelCount; i += 1) {
			const person = this.model.HEAPF32[outputMemoryOffset + i];

			// Sets onlt the alpha component of each pixel
			this.segmentationMask.data[i * 4 + 3] = 255 * person;
		}
		this.segmentationMaskCtx.putImageData(this.segmentationMask, 0, 0);
	}

	renderMask() {
		const start = new Date();
		this.resizeSource();
		this.runInference();
		this.runPostProcessing();

		const delta = new Date().getTime() - start.getTime();

		this.maskFrameTimeWorker.postMessage({
			id: SET_TIMEOUT,
			timeMs: Math.max(1000 / 30 - delta, 0),
		});
	}

	resizeSource() {
		this.segmentationMaskCtx.drawImage(
			this.inputVideoElement,
			0,
			0,
			this.inputVideoElement.width,
			this.inputVideoElement.height,
			0,
			0,
			this.options.width,
			this.options.height
		);

		const imageData = this.segmentationMaskCtx.getImageData(0, 0, this.options.width, this.options.height);
		const inputMemoryOffset = this.model._getInputMemoryOffset() / 4;

		for (let i = 0; i < this.segmentationPixelCount; i += 1) {
			this.model.HEAPF32[inputMemoryOffset + i * 3] = imageData.data[i * 4] / 255;
			this.model.HEAPF32[inputMemoryOffset + i * 3 + 1] = imageData.data[i * 4 + 1] / 255;
			this.model.HEAPF32[inputMemoryOffset + i * 3 + 2] = imageData.data[i * 4 + 2] / 255;
		}
	}

	startEffect(stream) {
		this.stream = stream;
		this.maskFrameTimeWorker = new Worker(timerWorkerScript, {
			name: 'Blur effect worker',
		});
		this.maskFrameTimeWorker.onmessage = this.onMaskFrameTimer;
		const firstVideoTrack = this.stream.getVideoTracks()[0];
		const { height, frameRate, width } = firstVideoTrack.getSettings
			? firstVideoTrack.getSettings()
			: firstVideoTrack.getConstraints();

		this.segmentationMask = new ImageData(this.options.width, this.options.height);
		this.segmentationMaskCanvas = document.createElement('canvas');
		this.segmentationMaskCanvas.width = this.options.width;
		this.segmentationMaskCanvas.height = this.options.height;
		this.segmentationMaskCtx = this.segmentationMaskCanvas.getContext('2d');

		this.outputCanvasElement.width = typeof width === 'number' ? width : (width.exact && width.exact) || 0;
		this.outputCanvasElement.height = typeof height === 'number' ? height : (height.exact && height.exact) || 0;
		this.outputCanvasCtx = this.outputCanvasElement.getContext('2d');
		this.inputVideoElement.width = typeof width === 'number' ? width : (width.exact && width.exact) || 0;
		this.inputVideoElement.height = typeof height === 'number' ? height : (height.exact && height.exact) || 0;

		this.inputVideoElement.autoplay = true;
		this.inputVideoElement.srcObject = this.stream;

		// @ts-ignore
		// eslint-disable-next-line prefer-destructuring
		this.track = this.outputCanvasElement.captureStream(parseInt(frameRate, 10)).getVideoTracks()[0];
		this.inputVideoElement.onloadeddata = () => {
			this.maskFrameTimeWorker.postMessage({
				id: SET_TIMEOUT,
				timeMs: 1000 / 30,
			});
		};
	}

	stop() {
		this.stopEffect();
		super.stop();
	}

	stopEffect() {
		this.maskFrameTimeWorker.postMessage({
			id: CLEAR_TIMEOUT,
		});
		this.maskFrameTimeWorker.terminate();
	}

	blurBackground(blurValue) {
		this.options = {
			height: 144,
			width: 256,
			virtualBackground: {
				backgroundEffectEnabled: true,
				backgroundType: 'blur',
				blurValue,
				selectedThumbnail: 'slight-blur',
				virtualScore: undefined,
			},
		};
	}

	changeBackground(base64Image) {
		this.options = {
			height: 144,
			width: 256,
			virtualBackground: {
				backgroundEffectEnabled: true,
				virtualSource: base64Image,
				backgroundType: 'image',
				selectedThumbnail: '5',
			},
		};
		this.virtualImage = document.createElement('img');
		this.virtualImage.crossOrigin = 'anonymous';
		this.virtualImage.src = this.options.virtualBackground.virtualSource;
	}

	removeBackground() {
		this.options = {
			height: 144,
			width: 256,
			virtualBackground: {
				backgroundEffectEnabled: false,
				backgroundType: 'none',
			},
		};
	}
}

let tfliteGlobal = null;

export const loadTfLite = () => {
	return new Promise((resolve, reject) => {
		if (tfliteGlobal) {
			resolve(tfliteGlobal);
			return;
		}
		// const modelUrl = `https://static.solaborate.com/healthcare-system/virtual-backgrounds/models/selfie_segmentation_landscape.tflite`; //local use only
		const modelUrl = `${APP_CONFIG.cdnURL}/healthcare-system/virtual-backgrounds/models/selfie_segmentation_landscape.tflite`;
		const loadModel = async tfliteModel => {
			const modelBufferOffset = tfliteModel._getModelBufferMemoryOffset();
			const modelResponse = await fetch(modelUrl);
			const model = await modelResponse.arrayBuffer();
			tfliteModel.HEAPU8.set(new Uint8Array(model), modelBufferOffset);
			tfliteModel._loadModel(model.byteLength);
			tfliteGlobal = tfliteModel;
			return tfliteModel;
		};

		try {
			window.createTFLiteSIMDModule().then(tflite => {
				loadModel(tflite).then(model => {
					resolve(model);
				});
			});
		} catch (err) {
			try {
				window.createTFLiteModule().then(tflite => {
					loadModel(tflite).then(model => {
						resolve(model);
					});
				});
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
				reject(error);
			}
		}
	});
};
