import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { enums } from '@solaborate/calls'; 
import ConferenceInitData from 'calls/ConferenceInitData.js';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';
import { IframeIntregrationsIds } from 'constants/enums.js';

const CallTypeEnum = {
	CALL_PATIENT: 1,
	VIEW_PATIENT: 2,
};

const CallRedirect = (
	/** @type {{ match: { params: { objectId: string, objectType: string, name: string, type: string, conferenceId: string } }; }} */ props
) => {
	const history = useHistory();

	useEffect(() => {
		const redirectToCall = () => {
			const { objectId, objectType, name, type, conferenceId } = props.match.params;
			let callType = enums.CallTypes.AUDIO;
			const integrationId = getStorage().getItem('iframe_integration_id');
			const isAmwell = +integrationId === IframeIntregrationsIds.AMWELL;
			const isPhilips = +integrationId === IframeIntregrationsIds.PHILIPS;
			if (isAmwell) {
				callType = enums.CallTypes.VIDEO;
			}
			if (+type === CallTypeEnum.VIEW_PATIENT) {
				history.replace(`/patient-feed?objectId=${objectId}&objectType=${objectType}&name=${name}&type=4`);
				return;
			}
			const conferenceData = new ConferenceInitData({
				callType,
				conferenceName: name,
				actioneeObjectId: +objectId,
				actioneeObjectType: +objectType,
				conferenceId,
				isJoin: false,
				...(isPhilips ? { integrationId: +integrationId } : {}),
			});
			history.replace('/call', conferenceData);
		};
		redirectToCall();
	}, [history, props.match.params]);

	return <></>;
};

export default CallRedirect;
