import React from 'react';
import { useSelector } from 'react-redux';
import { HealthSystemType } from 'constants/enums.js';
import DigitalClinicianPrimaryCareCheckIn from 'containers/PrimaryCare/DigitalClinician/DigitalClinicianPrimaryCareCheckIn.jsx';
import CheckInAPatient from 'views/CheckInAPatient/CheckInAPatient.jsx';

const CheckInHome = () => {
	const userSession = useSelector(state => state.user.userSession);

	return (
		<>
			{userSession.healthSystem.workflowTypeId === HealthSystemType.PRIMARY_CARE && <DigitalClinicianPrimaryCareCheckIn />}
			{userSession.healthSystem.workflowTypeId !== HealthSystemType.PRIMARY_CARE && <CheckInAPatient />}
		</>
	);
};

export default CheckInHome;
