import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const createFloor = async ({ companyId, healthSystemId, hospitalId, departmentId, name }) => {
	try {
		const url = `/v1/organizations/${companyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/channel-floors`;
		const { data } = await gatewayApi.post(url, {
			name,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const createFloorInHospital = async ({ companyId, healthSystemId, hospitalId, name }) => {
	try {
		const url = `/v1.1/organizations/${companyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/floors`;
		const { data } = await gatewayApi.post(url, {
			name,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHospitalFloors = async (companyId, healthSystemId, hospitalId) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${companyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/floors`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateFloor = async ({ healthSystemId, hospitalId, departmentId, floorId, params }) => {
	try {
		const url = `/v1.2/organizations/${getCompanyId()}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}`;
		const { data } = await gatewayApi.put(url, params);
		return data;
	} catch (error) {
		return { error };
	}
};
