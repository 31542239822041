import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserPreferences } from 'api/users.js';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { AiSetting, CallWorkflowType, SettingsCategory, UserSettingTypes } from 'constants/configurationEnums.js';
import { findSectorById, getAiSettingsConfigurations, getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';

export const useAiRoomSettings = objectId => {
	const healthSystems = useSelector(state => state.healthSystems);
	const [adminAiConfigurations, setAdminAiConfigurations] = useState({});

	useEffect(() => {
		const fetchRoomSettings = async () => {
			const roomId = findSectorById(healthSystems.treeData.tree, objectId)?.roomId;
			const [adminAiSettings, aiRoomSettings] = await Promise.all([
				getRoomSettings(roomId, SettingsCategory.AI_SETTINGS),
				getUserPreferences(UserSettingTypes.AiSettings, roomId),
			]);
			const responseError = adminAiSettings.error || aiRoomSettings.error;
			if (responseError) {
				return;
			}
			const aiResponse = getAiSettingsConfigurations(adminAiSettings, aiRoomSettings, CallWorkflowType.ROUNDING);
			if (aiResponse.error) {
				return;
			}
			setAdminAiConfigurations(aiResponse.configs);
		};
		if (objectId) {
			fetchRoomSettings();
		}
	}, [objectId, healthSystems]);

	return adminAiConfigurations;
};

export const isAnyAiSettingEnabled = adminAiConfigurations => {
	return (
		Object.keys(adminAiConfigurations).some(key => +key !== AiSetting.DATA_COLLECTION && adminAiConfigurations[key].value) &&
		!getConfigurationValue(adminAiConfigurations[AiSetting.SILENT_MODE])
	);
};
