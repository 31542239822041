import LightTheme from 'calls/styles/LightTheme.js';

const PatientPositionMonitoringIcon = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		color={LightTheme.colors.grayZero}
		{...props}>
		<path
			d='M8.80197 3.28555C9.16139 2.92613 9.16139 2.3434 8.80197 1.98398C8.44255 1.62456 7.85982 1.62456 7.5004 1.98398C7.14098 2.3434 7.14098 2.92613 7.5004 3.28555C7.85982 3.64497 8.44255 3.64497 8.80197 3.28555Z'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M8.92621 6.48548C9.0131 6.6816 9.24331 6.97983 9.34426 7.06606C9.58341 7.27028 10.0986 7.60145 10.4136 7.76629C10.6428 7.88628 10.916 7.85367 11.0633 7.64084C11.1957 7.44902 11.1639 7.17131 10.9887 7.01773L9.86327 6.19403C9.73832 5.96498 9.67311 5.7255 9.56537 5.488C9.40202 5.12853 9.25407 4.58751 8.75624 4.43707C8.53529 4.37087 8.44394 4.34604 8.21339 4.34108C7.99162 4.33644 7.76737 4.34273 7.55238 4.39702C6.97776 4.54233 6.58404 5.08203 6.25055 5.52061C6.13602 5.70183 5.97681 5.86088 5.91177 6.06841L5.33334 8.30367V8.30566C5.33411 8.41434 5.37515 8.51887 5.44851 8.59905C5.52188 8.67923 5.62236 8.72937 5.73055 8.73977C5.96109 8.75863 6.1961 8.58502 6.2105 8.35415L6.7737 6.47522L7.55023 5.55751'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M7.13514 9.32129C7.15484 10.0298 7.09972 10.2689 6.84204 11.1521L6.18467 13.4775C6.08371 13.7829 6.17391 14.1501 6.50607 14.2406C6.8806 14.352 7.07142 14.2903 7.21309 13.8655L8.01958 11.7593L8.25873 10.9497'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M7.11626 6.10822L6.97592 7.64506L6.96235 8.29548C6.95059 8.85995 7.12637 9.41238 7.46216 9.86626L8.6589 11.4844L9.34324 14.1365C9.40845 14.4659 9.60325 14.6992 9.9599 14.6625C10.3166 14.6257 10.5392 14.3735 10.4425 13.997L9.85216 10.9021C9.85133 10.8996 9.85034 10.8973 9.84935 10.895C9.84188 10.8711 9.83275 10.8477 9.82204 10.825L8.78153 8.49839L8.93644 5.54883'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default PatientPositionMonitoringIcon;
