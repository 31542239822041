import * as React from 'react';
const Retry = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={13} height={16} fill='none' {...props}>
		<g stroke='#5390FF' strokeLinecap='round' strokeLinejoin='round'>
			<path d='M8 1.125 6.5 3 8 4.875' />
			<path d='M6.5 3a6 6 0 1 1-4.65 2.207' />
		</g>
	</svg>
);
export default Retry;
