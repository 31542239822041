import { components } from 'react-select';

const MultiValue = props => {
	const content = props.children
		.split(' ')
		.map(letter => letter.substring(0, 1))
		.join('');

	return <components.MultiValue {...props}>{content}</components.MultiValue>;
};

export default MultiValue;
