import { getPressureInjuryHistory } from 'api/patients.js';
import { EvidenceIcon } from 'calls/icons/index.js';
import Alert from 'components/Alert.jsx';
import Button from 'components/Button.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import CustomTable from 'components/CustomTable.jsx';
import { formatDateHeader } from 'constants/dashboard.js';
import EvidenceReport from 'containers/Monitoring/EvidenceReport.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getAnalyticsTypeById } from 'infrastructure/helpers/aiHelper.js';
import { getDurationFormat, timezoneToUTCTimestamp, utcToTimezone } from 'infrastructure/helpers/dateHelper.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

const PressureInjuryHistory = ({ dateRange, selectedTimezone, isEvidenceEnabled }) => {
	const intl = useIntl();
	const location = useLocation();
	const [pressureInjuryPositionHistory, setPressureInjuryPositionHistory] = useState([]);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedEvidence, setSelectedEvidence] = useState(null);

	const fetchPressureInjuryHistory = useCallback(
		debounce(async params => {
			setIsLoading(true);
			const response = await getPressureInjuryHistory(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setPressureInjuryPositionHistory(response.pressureInjuryHistory);
				setPagination(prevState => ({ ...prevState, totalCount: response.totalCount }));
			}
			setIsLoading(false);
		}, 500),
		[]
	);

	useEffect(() => {
		const { state } = location;
		const { patient } = state;

		const params = {
			patientId: patient?.healthcareUserId,
			startTime: timezoneToUTCTimestamp(dateRange.from, selectedTimezone.zone),
			endTime: timezoneToUTCTimestamp(dateRange.to, selectedTimezone.zone),
			pageSize: pagination.pageSize,
			pageIndex: pagination.pageIndex,
		};
		fetchPressureInjuryHistory(params);
	}, [location, dateRange, pagination?.pageIndex, pagination?.pageSize, fetchPressureInjuryHistory, selectedTimezone.zone]);

	const displayPressureInjuryHistory = () =>
		pressureInjuryPositionHistory.map(item => ({
			id: item.id,
			position: translate(getAnalyticsTypeById(item.analyticTypeId)),
			startTime: utcToTimezone(item.startTime, selectedTimezone.zone),
			endTime: utcToTimezone(item.endTime, selectedTimezone.zone),
			duration: getDurationFormat(item.duration),
			evidence: getEvidenceButton({
				helloDeviceSerialNumber: item.serialNumber,
				id: item?.id,
				startTime: utcToTimezone(item.startTime, selectedTimezone.zone),
				endTime: utcToTimezone(item.endTime, selectedTimezone.zone),
				label: `${intl.formatMessage({ id: getAnalyticsTypeById(item.analyticTypeId) })} ${intl.formatMessage({
					id: 'position',
				})}`,
			}),
		}));

	const getEvidenceButton = item =>
		isEvidenceEnabled && <Button className='evidence-btn' svgIcon={<EvidenceIcon />} onClick={() => setSelectedEvidence(item)} />;

	return (
		<>
			<label className='ai-label margin-top-m'>
				{`${intl.formatMessage({ id: 'pressureInjury' })} ${intl.formatMessage({ id: 'history' })}`}
			</label>
			<CustomTable
				headers={[
					{ title: translate('position'), id: 'position' },
					{ title: formatDateHeader(intl, 'startTime', selectedTimezone.zone), id: 'startTime' },
					{ title: formatDateHeader(intl, 'endTime', selectedTimezone.zone), id: 'endTime' },
					{ title: translate('duration'), id: 'duration' },
					isEvidenceEnabled && { title: translate('evidence'), id: 'evidence' },
				].filter(Boolean)}
				rows={displayPressureInjuryHistory()}
				isLoading={isLoading}
			/>
			<div className='pagination-bar'>
				<Pagination
					totalCount={pagination.totalCount}
					pageSize={pagination.pageSize}
					pageIndex={pagination.pageIndex}
					onChange={(size, index) => setPagination(prevState => ({ ...prevState, pageIndex: index, pageSize: size }))}
				/>
			</div>
			<Alert display={error} fixed={true} message={error} variant='dark' />
			{selectedEvidence && (
				<EvidenceReport setIsModalOpen={setSelectedEvidence} evidenceDetails={selectedEvidence} isSlideshow={true} />
			)}
		</>
	);
};

export default PressureInjuryHistory;
