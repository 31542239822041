import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Alert, Grid } from 'components';
import { healthCareCdnUrl, primaryCareImageUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { capitalizeFirstLetter } from 'infrastructure/helpers/commonHelpers.js';
import { searchPatient } from 'api/patients.js';
import Button from 'components/Button.jsx';

const SelectPatient = props => {
	const [searchedPatient, setSearchedPatient] = useState('');
	const [patientList, setPatientList] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [pageIndex, setPageIndex] = useState(0);
	const [error, setError] = useState(null);
	const intl = useIntl();
	const minSearchedPatientLength = 3;

	useEffect(() => {
		const keyPressHandler = async () => {
			if (searchedPatient.length < minSearchedPatientLength) {
				setPatientList([]);
			} else {
				const response = await searchPatient(searchedPatient, pageIndex);
				if (response.error) {
					setError(response.error.message);
					return;
				}
				setPatientList(prevState => prevState.concat(response.patients));
				setTotalCount(response.totalCount);
			}
		};
		const delayCall = setTimeout(() => {
			keyPressHandler();
		}, 500);

		return () => clearTimeout(delayCall);
	}, [searchedPatient, pageIndex]);

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		const hasReachedEnd = totalCount - patientList.length <= 0;
		if (isBottom && !hasReachedEnd) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const setSearchValue = event => {
		setPageIndex(0);
		setPatientList([]);
		setSearchedPatient(event.target.value);
	};

	return (
		<>
			<Grid columns='1fr' stretch='100%'>
				<div className='check-in-patient-wrapper select-doctor-tabs-wrapper'>
					<div className='full-page-input-wrapper select-doctor-wrapper position-relative'>
						<div className='flex flex-space-between primary-care-title-wrapper'>
							<div />
							<div className='flex'>
								<Button
									imgIcon={`${primaryCareImageUrl}next-arrow.svg`}
									className={classNames('visit-btn blue-btn', !props.selectedPatient ? 'disabled' : '')}
									onClick={props.selectedPatient ? props.nextTab : null}
									text={translate('next')}
								/>
							</div>
						</div>
						<div className='flex flex-align-center column-direction position-relative check-in-title'>
							<div className='flex'>
								<div>
									<h3>{translate('selectPatient')}</h3>
									<p>{translate('searchPatientNameId')}</p>
									{props.selectedPatient && (
										<p>
											{translate('selectedPatient')}: {props.selectedPatient.firstName} {props.selectedPatient.lastName}
										</p>
									)}
								</div>
							</div>

							<div className='position-relative'>
								<img src={`${healthCareCdnUrl}search-icon.svg`} alt='ico' className='search-patient-icon' />
								<input
									type='search'
									placeholder={intl.formatMessage({ id: 'searchForPatients' })}
									onChange={setSearchValue}
									value={capitalizeFirstLetter(searchedPatient)}
								/>
								<Button className='add-new-patient' onClick={props.addNewPatient} text={translate('addNewPatient')} />
								{searchedPatient.length >= minSearchedPatientLength && patientList.length > 0 && (
									<div className='primary-care-results search-results' onScroll={handleScroll}>
										{patientList.map(item => (
											<div className='flex cursor-pointer' key={item.id} onClick={() => props.setSelectedPatient(item)}>
												<div className='flex-1'>
													<p className='results'>{`${item.firstName} ${item.lastName}`}</p>
													<span>ID: {item.idCard}</span>
												</div>
												<div>
													<span />
												</div>
											</div>
										))}
									</div>
								)}
								{searchedPatient.length < 3 && searchedPatient.length !== 0 && (
									<p className='no-results'>
										{translate('moreThanCharacters', {
											value: 3,
										})}
									</p>
								)}
								{searchedPatient.length > 2 && patientList.length === 0 && (
									<p className='no-results'>{translate('patientNotFound')}</p>
								)}
							</div>
						</div>
					</div>
				</div>
				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			</Grid>
		</>
	);
};
export default SelectPatient;
