import { VisitTypes } from 'constants/visitEnums.js';

export const getRoomInformation = (intl, type) => {
	switch (type) {
		case VisitTypes.DOCTOR:
			return { image: 'doctor.svg', roomType: intl.formatMessage({ id: 'doctor' }) };
		case VisitTypes.LAB:
			return { image: 'laboratory.svg', roomType: intl.formatMessage({ id: 'laboratory' }) };
		case VisitTypes.INFUSION:
			return { image: 'infusion-room.svg', roomType: intl.formatMessage({ id: 'infusion' }) };
		case VisitTypes.INJECTION:
			return { image: 'injection-room.svg', roomType: intl.formatMessage({ id: 'injection' }) };
		case VisitTypes.RADIOLOGY:
			return { image: 'radiology.svg', roomType: intl.formatMessage({ id: 'radiology' }) };
		default:
			return { image: '.svg', roomType: intl.formatMessage({ id: 'room' }) };
	}
};
