import React from 'react';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import CreateEditCompanyTabs from 'containers/CreateEditCompanyTabs.jsx';

const CreateCompanyWrapper = ({ stopEditLoading, selectedCompany }) => {
	return (
		<>
			{selectedCompany && <CreateEditCompanyTabs selectedCompany={selectedCompany} stopEditLoading={stopEditLoading} />}
			{!selectedCompany && (
				<MainLayout>
					<CreateEditCompanyTabs selectedCompany={null} stopEditLoading={stopEditLoading} />
				</MainLayout>
			)}
		</>
	);
};

export default CreateCompanyWrapper;
