import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Alert.jsx';
import EmptyState from 'components/EmptyState.jsx';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getMedicationIcon } from 'infrastructure/helpers/rpmHelper.js';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import { getPatientMedications, notifyPatientForMedication } from 'api/rpm.js';
import moment from 'moment';
import { MedicationStatus } from 'constants/rpm.js';
import { getDateDifferenceInMinutes } from 'infrastructure/helpers/dateHelper.js';
import Pill from 'icons/RPM/Pill.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import Default from 'icons/RPM/Default.jsx';
import Button from 'components/Button.jsx';

const TodaysMedication = props => {
	const [medications, setMedications] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const [error, setError] = useState(null);
	const hasReachedEnd = useRef(null);

	useEffect(() => {
		const getMedicationsPerPatient = async () => {
			setIsLoading(true);
			const params = {
				userId: getUserId(),
				patientId: props.selectedPatient?.id,
				role: getUserRole(),
				isRpmRequest: true,
				pageIndex,
			};
			const response = await getPatientMedications(params);
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			setMedications(prevState => prevState.concat(response));
			setIsLoading(false);
			hasReachedEnd.current = response.length < 10;
		};

		getMedicationsPerPatient();
	}, [props.selectedPatient, pageIndex]);

	const handleNotifyPatient = async () => {
		const missedPrescriptionIds = medications.reduce((accumulator, item) => {
			if (getMedicationStatus(item) === MedicationStatus.SKIPPED) {
				accumulator.push(item.id);
			}
			return accumulator;
		}, []);
		if (missedPrescriptionIds.length === 0) {
			props.setShouldShowMedication(false);
			return;
		}
		const response = await notifyPatientForMedication(props.selectedPatient?.patientId, missedPrescriptionIds);
		if (response.error) {
			setError(response.error.message);
		}
		props.setShouldShowMedication(false);
	};

	const getMedicationStatus = item => {
		const dayInMinutes = 1440;
		const createdDate = moment(new Date(item.visitedAt));
		const twentyFourHoursAgo = moment().subtract(24, 'hours');
		const utcMoment = moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss');
		const differenceInMinutes = getDateDifferenceInMinutes(new Date(item.intakeHistory[0]?.dateCreated), new Date(utcMoment));
		let medicationStatus = ' ';
		if (
			(createdDate.isBefore(twentyFourHoursAgo) && item.intakeHistory.length === 0) ||
			(item.intakeHistory.length > 0 && differenceInMinutes > dayInMinutes / item.frequency)
		) {
			medicationStatus = MedicationStatus.SKIPPED;
		}
		if (item.intakeHistory.length > 0 && differenceInMinutes <= dayInMinutes / item.frequency) {
			medicationStatus = MedicationStatus.TAKEN;
		}
		return medicationStatus;
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const getIsButtonDisabled = () =>
		medications.reduce((accumulator, item) => {
			if (getMedicationStatus(item) === MedicationStatus.SKIPPED) {
				accumulator.push(item.id);
			}
			return accumulator;
		}, []).length === 0;
	return (
		<>
			<Modal
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal vsm-alerts-modal'
				position='center'
				display={true}
				isLoading={isLoading}
				onModalClose={() => props.setShouldShowMedication(false)}>
				<Form className='modal-form-wrapper today-medication-form'>
					<h3 className='medication-header'>{translate('todaysMedication')}</h3>
					{medications.length === 0 && !isLoading && <EmptyState title={translate('noResultsFound')} image='Notes.svg' />}
					{medications.map(item => (
						<div key={item.id} className='flex flex-space-between rpm-medication-wrapper' onScroll={handleScroll}>
							<div className='flex right-align-items'>
								{getMedicationIcon(getMedicationStatus(item)) === 'default' && (
									<Pill
										color={props.isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive}
										className={classNames('medication-icon', getMedicationIcon(getMedicationStatus(item), true))}
									/>
								)}
								{getMedicationIcon(getMedicationStatus(item)) !== 'default' && (
									<img
										src={`${healthCareCdnUrl}rpm/pill-${getMedicationIcon(getMedicationStatus(item), true)}.svg`}
										className={classNames('medication-icon', getMedicationIcon(getMedicationStatus(item), true))}
										alt='ico'
									/>
								)}
								<div className='column-direction'>
									<p className='no-margin'>{item.medicine?.name}</p>
									<span>
										{item.dosage} {item.medicine?.strengthAndForm}
									</span>
								</div>
							</div>
							<div className='flex flex-align-center'>
								<p className={classNames('medic-status', `${getMedicationStatus(item) === 'skipped' ? 'red' : 'green'}`)}>
									{translate(getMedicationStatus(item))}
								</p>
								{getMedicationIcon(getMedicationStatus(item)) === 'default' && (
									<Default
										color={props.isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive}
										className='status-icon'
									/>
								)}
								{getMedicationIcon(getMedicationStatus(item)) !== 'default' && (
									<img
										src={`${healthCareCdnUrl}rpm/${getMedicationIcon(getMedicationStatus(item))}.svg`}
										className='status-icon'
										alt='ico'
									/>
								)}
							</div>
						</div>
					))}
					{medications.length > 0 && (
						<Button
							className='notify-patient-btn top-10'
							onClick={handleNotifyPatient}
							disabled={getIsButtonDisabled()}
							text={translate('notifyPatientForMedication')}
							imgIcon={`${healthCareCdnUrl}rpm/alerts-white.svg`}
							variant='red'
						/>
					)}
				</Form>
			</Modal>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default TodaysMedication;
