const HelloIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={24} height={10} viewBox='0 0 24 10' fill='none' {...props}>
		<path
			d='M12.8258 4.99883C12.8258 5.45446 12.4564 5.82383 12.0008 5.82383C11.5451 5.82383 11.1758 5.45446 11.1758 4.99883C11.1758 4.54319 11.5451 4.17383 12.0008 4.17383C12.4564 4.17383 12.8258 4.54319 12.8258 4.99883Z'
			fill='currentColor'
		/>
		<path
			d='M15.2992 4.99922C15.2992 6.82176 13.8218 8.29922 11.9992 8.29922C10.1767 8.29922 8.69922 6.82176 8.69922 4.99922C8.69922 3.17668 10.1767 1.69922 11.9992 1.69922C13.8218 1.69922 15.2992 3.17668 15.2992 4.99922Z'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
		<path d='M8.7 4.14453H1V7.44453H9.8' stroke='currentColor' strokeWidth={1.5} strokeLinejoin='round' />
		<path d='M15.3 4.17383H23V7.47383H14.2' stroke='currentColor' strokeWidth={1.5} strokeLinejoin='round' />
	</svg>
);
export default HelloIcon;
