const Sessions = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='33' height='33' viewBox='0 0 33 33' color='#5390FF' fill='none' {...props}>
		<rect x='1.75' y='4.5' width='29.5' height='19.875' rx='3' stroke='currentColor' strokeWidth='2' strokeMiterlimit='10' />
		<path
			d='M5.271 23.3748V21.1444C5.271 18.0648 7.88733 15.5684 11.1147 15.5684C14.3422 15.5684 16.9585 18.0648 16.9585 21.1444V23.3748'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
		/>
		<path d='M15.0105 20.9355V23.3751' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
		<path d='M7.21899 20.9355V23.3751' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
		<path
			d='M8.19287 11.1774C8.19287 12.7942 9.50104 14.1048 11.1147 14.1048C12.7285 14.1048 14.0366 12.7942 14.0366 11.1774C14.0366 9.56065 12.7285 8.25 11.1147 8.25C9.50104 8.25 8.19287 9.56065 8.19287 11.1774Z'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
		/>
		<path
			d='M21.9167 11C21.9167 10.4477 22.3645 10 22.9167 10C23.469 10 23.9167 10.4477 23.9167 11L21.9167 11ZM23.9167 11L23.9167 23.375L21.9167 23.375L21.9167 11L23.9167 11Z'
			fill='currentColor'
		/>
		<path
			d='M22.9167 12.8335L26.5001 12.8335C27.0524 12.8335 27.5001 12.3858 27.5001 11.8335L27.5001 11.0002'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
		/>
		<path
			d='M22.9167 14.6665L19.3334 14.6665C18.7811 14.6665 18.3334 14.2188 18.3334 13.6665L18.3334 12.8332'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
		/>
		<path d='M19.7086 14.6665L19.7086 16.4998' stroke='currentColor' strokeWidth='2' />
		<path
			d='M20.6252 17.4162C20.6252 16.9099 20.2148 16.4995 19.7086 16.4995V16.4995C19.2023 16.4995 18.7919 16.9099 18.7919 17.4162V18.3328C18.7919 18.8391 19.2023 19.2495 19.7086 19.2495V19.2495C20.2148 19.2495 20.6252 18.8391 20.6252 18.3328V17.4162Z'
			stroke='currentColor'
			strokeWidth='2'
		/>
		<path d='M26.1251 12.8335L26.1251 14.6668' stroke='currentColor' strokeWidth='2' />
		<path
			d='M27.0417 15.5837C27.0417 15.0774 26.6313 14.667 26.1251 14.667V14.667C25.6188 14.667 25.2084 15.0774 25.2084 15.5837V16.5003C25.2084 17.0066 25.6188 17.417 26.1251 17.417V17.417C26.6313 17.417 27.0417 17.0066 27.0417 16.5003V15.5837Z'
			stroke='currentColor'
			strokeWidth='2'
		/>
		<path d='M20.625 26.125H12.375' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
	</svg>
);
export default Sessions;
