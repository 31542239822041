import * as React from 'react';

const Image = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none' {...props}>
		<rect width={15} height={15} x={2.5} y={2.5} stroke='currentColor' rx={4} />
		<path stroke='currentColor' d='M7.417 6.25a1.167 1.167 0 1 1-2.334 0 1.167 1.167 0 0 1 2.334 0Z' />
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			d='m2.5 13.333 1.937-2.421a2 2 0 0 1 2.976-.165l.205.204a2 2 0 0 0 2.95-.133l1.295-1.553a2 2 0 0 1 3.016-.065l2.621 2.883'
		/>
	</svg>
);
export default Image;
