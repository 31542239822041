import * as React from 'react';
const Pill = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={40} height={20} fill='none' {...props}>
		<rect
			width={6.417}
			height={16.5}
			x={2.439}
			y={7.435}
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			rx={3.208}
			transform='rotate(-45 2.44 7.435)'
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M8.803 13.799s-.833-1.288 1.208-3.33c2.042-2.041 3.33-1.207 3.33-1.207'
		/>
	</svg>
);
export default Pill;
