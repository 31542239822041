import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const addNewPatient = async params => {
	try {
		const { data } = await gatewayApi.post('v1/tasks/patients', params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const snoozeAlertBasedOnTask = async (taskId, minutes) => {
	try {
		const { data } = await gatewayApi.put(`v1/organizations/${getCompanyId()}/tasks/${taskId}/biobeat/alerts-snooze/${minutes}`);
		return data;
	} catch (error) {
		return { error };
	}
};
