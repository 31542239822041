import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const transferQueue = async (fromDoctorId, toDoctorId, assignedRequestsIds) => {
	try {
		const { data } = await gatewayApi.patch(`/v1/healthcare/medics/${fromDoctorId}/assigned-requests/transfer`, {
			companyId: getCompanyId(),
			assignedRequestsIds,
			toDoctorId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};
