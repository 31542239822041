import EmptyState from 'components/EmptyState.jsx';
import React from 'react';
import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const RecordingsHistory = ({ recordings, isLoading, roomName, isDarkMode, openRecordedVideo }) => {
	const intl = useIntl();
	return (
		<>
			{recordings.length > 0 &&
				!isLoading &&
				recordings.map(record => (
					<div className='patient-record-list-item' key={record.id}>
						<div className='flex'>
							<div className='flex record-list-inner-wrapper'>
								<p className='record-list-date'>{formattedDate(record.recordDate)}</p>
								<p className='record-list-description'>{roomName}</p>
								<p className='record-list-recorder'>
									{translate('recordedBy')} {record.nurseName}
								</p>
							</div>
							<div className='record-list-download-icon' onClick={() => openRecordedVideo(record)}>
								<span data-tooltip={intl.formatMessage({ id: 'watchRecording' })} data-position='bottom'>
									<img alt='ico' src={`${healthCareCdnUrl}${isDarkMode ? 'dark-mode' : 'monitoring'}/play-video.svg`} />
								</span>
							</div>
						</div>
					</div>
				))}

			{!recordings.length && !isLoading && (
				<div className='flex flex-align-center flex-justify-center empty-state-wrapper'>
					<EmptyState title={translate('noResultsFound')} image='alerts.svg?v2' />
				</div>
			)}
			<div className='flex alert-subtabs' />
		</>
	);
};

export default RecordingsHistory;
