import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId, getUserRoleId } from 'infrastructure/auth.js';

export const getMeasurements = async ({ patientId, measurementType, startDate, endDate, pageIndex = null, pageSize = null }) => {
	try {
		const { data } = await gatewayApi.get('v1/healthcare/measurements', {
			params: {
				patientId,
				measurementType,
				startDate,
				endDate,
				pageIndex,
				pageSize,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const addMeasurement = async ({
	userId,
	deviceType,
	measurementType,
	measurementValue,
	measurementUnit,
	startDate,
	endDate,
	isManuallyInserted = false,
	conferenceId = null,
	source,
}) => {
	try {
		const { data } = await gatewayApi.post('v1/healthcare/measurements', {
			userId,
			deviceType,
			measurementType,
			measurementValue,
			measurementUnit,
			startDate,
			endDate,
			isManuallyInserted,
			conferenceId,
			source,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getLatestMeasurements = async (patientId, includeEws = false) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/measurements/latest-by-type`, {
			params: { patientId, includeEws },
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getMeasurementsByRpm = async ({ patientId, measurementTypes }) => {
	let url = `v1/healthcare/measurements/latest?patientId=${patientId}`;
	measurementTypes.forEach(measurementType => {
		url += `&types=${measurementType}`;
	});
	try {
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDailyAveragesByDateRange = async ({
	patientId,
	measurementType,
	startDate,
	endDate,
	pageIndex = null,
	pageSize = null,
	averageType,
}) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/patients/${patientId}/measurements/averages`, {
			params: {
				type: measurementType,
				from: startDate,
				to: endDate,
				pageIndex,
				pageSize,
				'avg-type': averageType,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAggregatesByDateRange = async ({
	patientId,
	measurementType,
	startDate,
	endDate,
	pageIndex = null,
	pageSize = null,
}) => {
	try {
		const { data } = await gatewayApi.get('v1/healthcare/measurements/aggregates', {
			params: {
				patientId,
				measurementType,
				startDate,
				endDate,
				pageIndex,
				pageSize,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteMeasurement = async id => {
	try {
		const { data } = await gatewayApi.delete(`/v1/healthcare/measurements/${id}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteMeasurements = async type => {
	try {
		const { data } = await gatewayApi.delete(`/v1/healthcare/measurements/delete?measurementType=${type}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUserMeasurementUnits = async userId => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/users/${userId}/unit-preferences`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const editUserMeasurementUnits = async (userId, data) => {
	try {
		const response = await gatewayApi.put(`v1/healthcare/users/${userId}/unit-preferences`, data);
		return response.data;
	} catch (error) {
		return { error };
	}
};

export const getLatestStethoscopeMeasurements = async patientId => {
	try {
		const { data } = await gatewayApi.get('v1/healthcare/stethoscope-recordings/last', {
			params: { patientId },
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getVitalSignPatients = async (healthSystemId, regionId) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/vital-signs?companyId=${getCompanyId()}&healthSystemId=${healthSystemId}&regionId=${regionId}&role=${getUserRoleId()}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getMeasurementImagesSasToken = async patientId => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/patients/${patientId}/measurement-images/sas-token`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getStethoscopeRecordingsSasToken = async container => {
	try {
		const { data } = await gatewayApi.get(`v1/azure-storage/container/${container}/sas-token`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getMeasurementImages = async ({ patientId, pageIndex, pageSize, type, examPoint = null }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/organizations/${getCompanyId()}/patients/${patientId}/measurement-images?pageIndex=${pageIndex}&pageSize=${pageSize}&type=${type}&examPoint=${examPoint}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getGalaxyWatchMeasurements = async ({ patientId, pageIndex = 0, pageSize = 10, latest = false }) => {
	try {
		const { data } = await gatewayApi.get('v1/healthcare/measurements/mobile', {
			params: {
				patientId,
				pageIndex,
				pageSize,
				sources: 'samsungWatch',
				latest,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const setBioBeatDeviceState = async ({ taskId, deviceId, state, patientUserId, patientUserGuid }) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1/organizations/${getCompanyId()}${taskId ? `/tasks/${taskId}` : ''}/biobeat/device-state`,
			{
				deviceId,
				state,
				patientUserId,
				patientUserGuid,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};
