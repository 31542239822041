import Grid from 'components/Grid.jsx';
import Avatar from 'components/Avatar.jsx';
import { RTCPeerConnectionEnum } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';

const ConnectionStatus = props => (
	<div style={{ textAlign: 'center' }}>
		<Grid
			className='incoming-calls'
			columns='1fr'
			rows='1fr'
			horizAlign='center'
			vertAlign='center'
			stretch='100vh'
			backgroundColor='rgba(1, 83, 182)'>
			<div>
				<Avatar
					src={props.url ? props.url : 'https://maxcdn.solaborate.com/media/profile-pictures/duser.jpg'}
					size='medium'
					fullName=''
				/>

				{(props.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.DISCONNECTED ||
					props.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.FAILED) && (
					<p data-test-id='callState-busy' className='incoming-call-text'>
						{translate('waitAttemptingReconnect')}
					</p>
				)}
			</div>
		</Grid>
	</div>
);

export default ConnectionStatus;
