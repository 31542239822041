import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import { IntegrationTypesSettings } from 'constants/configurationEnums.js';
import { UserRoles } from 'constants/enums.js';
import { groupTypes } from 'constants/integrationEnums.js';
import IntegrationsList from 'containers/Configurations/IntegrationsList.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { getConfigurationValue, getSomeConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const IntegrationWrapper = () => {
	const companySettings = useSelector(state => state.company.companySettings);
	const [currentTab, setCurrentTab] = useState(0);
	const shouldShowTranslationServicesTab = () =>
		getUserRole() !== UserRoles.SUPER_USER &&
		getSomeConfigurationsValues(companySettings?.companyConfigurations, [
			IntegrationTypesSettings.GLOBO,
			IntegrationTypesSettings.AMN,
			IntegrationTypesSettings.LANGUAGE_LINE,
			IntegrationTypesSettings.VOYCE,
		]);
	const shouldShowCloudStorageTab = () =>
		getUserRole() === UserRoles.ADMIN &&
		getConfigurationValue(companySettings.companyConfigurations[IntegrationTypesSettings.AZURE_BLOB_STORAGE]);

	return (
		<Tabs className='configs-sub-tabs' activeIndex={currentTab} onChange={setCurrentTab}>
			<TabList>
				<Tab>{translate(groupTypes.ECM.title)}</Tab>
				<Tab>{translate(groupTypes.SMART_FHIR.title)}</Tab>
				<Tab>{translate(groupTypes.NURSE_CALLING.title, { value: companySettings.nurseDisplayName })}</Tab>
				<Tab>{translate(groupTypes.PATIENT_INFOTAINMENT.title)}</Tab>
				{shouldShowTranslationServicesTab() && <Tab>{translate(groupTypes.TRANSLATION_SERVICES.title)}</Tab>}
				{shouldShowCloudStorageTab() && <Tab>{translate(groupTypes.CLOUD_STORAGE.title)}</Tab>}
				<Tab>{translate(groupTypes.PATIENT_EDUCATION.title)}</Tab>
			</TabList>
			<TabPanels>
				<TabPanel>
					<IntegrationsList group={groupTypes.ECM} />
				</TabPanel>
				<TabPanel>
					<IntegrationsList group={groupTypes.SMART_FHIR} />
				</TabPanel>
				<TabPanel>
					<IntegrationsList group={groupTypes.NURSE_CALLING} />
				</TabPanel>
				<TabPanel>
					<IntegrationsList group={groupTypes.PATIENT_INFOTAINMENT} />
				</TabPanel>
				{shouldShowTranslationServicesTab() && (
					<TabPanel>
						<IntegrationsList group={groupTypes.TRANSLATION_SERVICES} />
					</TabPanel>
				)}
				{shouldShowCloudStorageTab() && (
					<TabPanel>
						<IntegrationsList group={groupTypes.CLOUD_STORAGE} />
					</TabPanel>
				)}
				<TabPanel>
					<IntegrationsList group={groupTypes.PATIENT_EDUCATION} />
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
};

export default IntegrationWrapper;
