const StartQueryStringKeys = {
	CALL_TYPE: 'type',
	OBJECT_ID: 'objectId',
	OBJECT_TYPE: 'objectType',
	CONFERENCE_ID: 'id',
	PARTICIPANT_ID: 'participantId',
	CONFERENCE_NAME: 'name',
	CONVERSATION_ID: 'conversationId',
	IS_MEETING_ROOM: 'isMeetingRoom',
	MEDICAL_VISIT_ID: 'medicalVisitId',
	ROOM_TYPE: 'roomType',
	IS_MULTI_BED: 'isMultiBed',
	ROUNDING_CALL_TYPE: 'roundingCallType',
};

export default StartQueryStringKeys;
