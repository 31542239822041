import { useEffect, useState } from 'react';
import { conference as Conference } from '@solaborate/calls';
import useConference from 'calls/hooks/useConference.js';

const useConferenceState = () => {
	const conference = useConference();
	const [conferenceState, setConferenceState] = useState(conference.state);

	useEffect(() => {
		const onEvent = event => {
			if (event instanceof Conference.ConferenceState) {
				setConferenceState(event);
			}
		};

		conference.on(onEvent);

		return () => {
			conference.off(onEvent);
		};
	}, [conference]);

	return conferenceState;
};

export default useConferenceState;
