import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Plus = props => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			color={LightTheme.colors.grayZero}
			{...props}>
			<path d='M12 4V20' stroke='currentColor' strokeMiterlimit='10' strokeLinecap='round' />
			<path d='M4 12H20' stroke='currentColor' strokeMiterlimit='10' strokeLinecap='round' />
		</svg>
	);
};

export default Plus;
