import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';
import { buildParamsFromArray } from 'infrastructure/helpers/commonHelpers.js';

export const getMultipleLevelFleetDevices = async ({
	currentPage,
	pageSize,
	search = null,
	levelIds = [],
	levelType = null,
	isOnline = null,
	connectionType = null,
}) => {
	const params = { currentPage, pageSize, search, levelType, isOnline, connectionType };
	try {
		const { data } = await gatewayApi.get(
			`/v1/devices/companies/${getCompanyId()}/fleet-metrics/table-data?${buildParamsFromArray('levelIds', levelIds, true)}`,
			{ params }
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getFleetChartsData = async ({ levelType, levelIds, isOnline = null }) => {
	const params = { isOnline };
	try {
		const { data } = await gatewayApi.get(
			`v1/devices/companies/${getCompanyId()}/fleet-metrics/room-status?levelType=${levelType}${buildParamsFromArray(
				'levelIds',
				levelIds
			)}`,
			{ params }
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDeviceAlertsList = async ({ levelType = null, levelIds, pageSize = 10, pageIndex = 0, search = null }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organization/${getCompanyId()}/device-report-subscriptions?level-type=${levelType}${buildParamsFromArray(
				'levelIds',
				levelIds
			)}&page-size=${pageSize}&page-index=${pageIndex}&search=${search}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDeviceDoughnutChartsData = async ({ levelType, levelIds, isOnline = null }) => {
	const params = { isOnline };
	try {
		const { data } = await gatewayApi.get(
			`v1/devices/companies/${getCompanyId()}/fleet-metrics/summary?levelType=${levelType}${buildParamsFromArray(
				'levelIds',
				levelIds
			)}`,
			{ params }
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addOrEditDeviceAlert = async ({ levelType = null, levelIds = [], emails = [], subscriptionType = null }) => {
	const params = { levelType, levelIds, emails, subscriptionType };
	try {
		const { data } = await gatewayApi.post(`v1/organization/${getCompanyId()}/device-report-subscriptions`, { ...params });
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteDeviceAlert = async email => {
	try {
		const { data } = await gatewayApi.delete(`v1/organization/${getCompanyId()}/device-report-subscriptions/${email}`);
		return data;
	} catch (error) {
		return { error };
	}
};
