import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { getLatestMeasurements } from 'api/measurements.js';
import { Alert, Grid, Loader } from 'components';
import { MeasurementTypes, MeasurementUnits, UnitCategoryTypes } from 'constants/enums.js';
import { healthCareCdnUrl, primaryCareImageUrl } from 'constants/global-variables.js';
import HealthDataTabContent from 'containers/HealthMeasurements/HealthDataTabContent.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserId } from 'infrastructure/auth.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { convertMeasurementTypes, roundMeasurementValue } from 'infrastructure/helpers/measurementsHelper.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { useSelector } from 'react-redux';
import Button from 'components/Button.jsx';

const PatientVitalSigns = props => {
	const intl = useIntl();
	const { current: userId } = useRef(getUserId());
	const measurementValuesObj = {
		[MeasurementTypes.HEART_RATE]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.OXYGEN]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.PI]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.BLOOD_PRESSURE]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.DIABETES]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.TEMPERATURE]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.WEIGHT]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.STEPS]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.LEAN_BODY_MASS]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.BODY_FAT]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.BODY_MASS_INDEX]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.SLEEP]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.DISTANCE_WALKING_RUNNING]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.FLIGHTS_CLIMBED]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.FALLEN_TIMES]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.RESPIRATORY_RATE]: {
			value: 0,
			startDate: null,
		},
	};

	const [errorApiResponse, setErrorApiResponse] = useState(null);
	const [isAddManuallyOpen, setIsAddManuallyOpen] = useState(false);
	const [measurementDetails, setMeasurementDetails] = useState(null);
	const [measurementValues, setMeasurementValues] = useState(measurementValuesObj);
	const [isLoading, setIsLoading] = useState(true);
	const [fetchNewMeasurements, setFetchNewMeasurements] = useState(false);
	const socket = useContext(SocketContext);
	const preferredUnits = useSelector(state => state.user.unitPreferences);


	useEffect(() => {
		const healthMeasurementsListener = data => {
			if (props.selectedPatient?.userId === data.userId) {
				const newAnalysis = { ...measurementValues };
				const { measurementType, measurementValue, startDate, measurementUnit, unitCategoryId } = data;
				if (newAnalysis[measurementType]) {
					newAnalysis[measurementType].value = convertMeasurementTypes(
						unitCategoryId,
						measurementValue,
						getUnitPreference(unitCategoryId)?.unitSystemId
					);
					newAnalysis[measurementType].startDate = startDate;
					newAnalysis[measurementType].measurementUnit = measurementUnit;
					setMeasurementValues(newAnalysis);
				}
			}
		};
		socket.on(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, healthMeasurementsListener);
		return () => {
			socket.off(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, healthMeasurementsListener);
		};
	}, [socket]);

	useEffect(() => {
		const fetchLatestMeasurements = async () => {
			const response = await getLatestMeasurements(props.selectedPatient?.userId);
			const newMeasurementValues = { ...measurementValues };
			if (response.error) {
				setErrorApiResponse(response.error.message);
			} else {
				response.measurements.forEach(item => {
					if (newMeasurementValues[item.measurementType]) {
						newMeasurementValues[item.measurementType].value = roundMeasurementValue(item.measurementValue, item.measurementType);
						newMeasurementValues[item.measurementType].startDate = item.startDate;
					}
				});
				setMeasurementValues(newMeasurementValues);
			}
			setIsLoading(false);
		};
		fetchLatestMeasurements();
	}, [props.selectedPatient, userId, fetchNewMeasurements]);

	const imageUrl = `${healthCareCdnUrl}health-data/`;

	const measurementTypeDetails = [
		{
			id: 0,
			title: {
				text: translate('heartRate'),
				icon: `${imageUrl}heart.svg`,
			},
			unit: MeasurementUnits.BPM,
			type: MeasurementTypes.HEART_RATE,
			minimumValue: 30,
			maximumValue: 350,
			isDecimal: false,
		},
		{
			id: 1,
			title: {
				text: translate('bloodPressure'),
				icon: `${imageUrl}heart.svg`,
			},
			unit: 'mmHg',
			type: MeasurementTypes.BLOOD_PRESSURE,
			minimumValue: 40,
			maximumValue: 300,
			minimumValueSecond: 30,
			maximumValueSecond: 200,
			isDecimal: false,
		},
		{
			id: 2,
			title: {
				text: translate('perfusionIndex'),
				icon: `${imageUrl}heart.svg`,
			},
			unit: '%',
			type: MeasurementTypes.PI,
			minimumValue: 0,
			maximumValue: 20,
			isDecimal: false,
		},
		{
			id: 3,
			title: {
				text: translate('oxygenSaturation'),
				icon: `${imageUrl}respiratory.svg`,
			},
			unit: '%',
			type: MeasurementTypes.OXYGEN,
			minimumValue: 70,
			maximumValue: 100,
			isDecimal: true,
		},
		{
			id: 4,
			title: {
				text: translate('bloodGlucose'),
				icon: `${healthCareCdnUrl}vsm/blood-glucose.svg`,
			},
			unit: '',
			type: MeasurementTypes.DIABETES,
			minimumValue: 0,
			maximumValue: 50,
			isDecimal: true,
			unitCategoryId: UnitCategoryTypes.BLOOD_GLUCOSE,
		},
		{
			id: 5,
			title: {
				text: translate('weight'),
				icon: `${imageUrl}body-measurements.svg`,
			},
			unit: '',
			type: MeasurementTypes.WEIGHT,
			minimumValue: 5,
			maximumValue: 400,
			isDecimal: true,
			unitCategoryId: UnitCategoryTypes.WEIGHT,
		},
		{
			id: 6,
			title: {
				text: translate('bodyTemperature'),
				icon: `${imageUrl}body-measurements.svg`,
			},
			unit: '',
			type: MeasurementTypes.TEMPERATURE,
			minimumValue: 33,
			maximumValue: 42,
			isDecimal: true,
			unitCategoryId: UnitCategoryTypes.TEMPERATURE,
		},
		{
			id: 7,
			title: {
				text: translate('walkingRunningDistance'),
				icon: `${imageUrl}activity.svg`,
			},
			unit: '',
			type: MeasurementTypes.DISTANCE_WALKING_RUNNING,
			minimumValue: 0,
			maximumValue: 15000,
			isDecimal: true,
			unitCategoryId: UnitCategoryTypes.DISTANCE,
		},
		{
			id: 8,
			title: {
				text: translate('steps'),
				icon: `${imageUrl}activity.svg`,
			},
			unit: intl.formatMessage({ id: 'steps' }),
			type: MeasurementTypes.STEPS,
			minimumValue: 0,
			maximumValue: 90000,
			isDecimal: false,
		},
		{
			id: 9,
			title: {
				text: translate('leanBodyMass'),
				icon: `${imageUrl}body-measurements.svg`,
			},
			unit: '',
			type: MeasurementTypes.LEAN_BODY_MASS,
			minimumValue: 30,
			maximumValue: 275,
			isDecimal: true,
			unitCategoryId: UnitCategoryTypes.WEIGHT,
		},
		{
			id: 10,
			title: {
				text: translate('bodyFatPercentage'),
				icon: `${imageUrl}body-measurements.svg`,
			},
			unit: '%',
			type: MeasurementTypes.BODY_FAT,
			minimumValue: 0,
			maximumValue: 75,
			isDecimal: true,
		},
		{
			id: 11,
			title: {
				text: translate('bodyMassIndex'),
				icon: `${imageUrl}body-measurements.svg`,
			},
			unit: translate('bMI'),
			type: MeasurementTypes.BODY_MASS_INDEX,
			minimumValue: 7,
			maximumValue: 75,
			isDecimal: true,
		},
		{
			id: 12,
			title: {
				text: translate('flightsClimbed'),
				icon: `${imageUrl}activity.svg`,
			},
			unit: intl.formatMessage({ id: MeasurementTypes.FLIGHTS_CLIMBED }),
			type: MeasurementTypes.FLIGHTS_CLIMBED,
			minimumValue: 0,
			maximumValue: 500,
			isDecimal: false,
		},
		{
			id: 13,
			title: {
				text: translate('respiratoryRate'),
				icon: `${imageUrl}respiratory.svg`,
			},
			unit: MeasurementUnits.BPM,
			type: MeasurementTypes.RESPIRATORY_RATE,
			minimumValue: 0,
			maximumValue: 60,
			isDecimal: false,
		},
		{
			id: 14,
			title: {
				text: translate('sleepAnalysis'),
				icon: `${healthCareCdnUrl}vsm/Sleep.svg`,
			},
			unit: translate('hrs'),
			type: MeasurementTypes.SLEEP,
			minimumValue: 0,
			maximumValue: 24,
			isDecimal: true,
		},
		{
			id: 15,
			title: {
				text: translate('numberTimesFallen'),
				icon: `${healthCareCdnUrl}footer-icons/number-of-falls.svg`,
			},
			unit: '',
			type: MeasurementTypes.FALLEN_TIMES,
			minimumValue: 1,
			maximumValue: 30,
			isDecimal: false,
		},
	];

	const toggleAddManually = item => {
		setIsAddManuallyOpen(true);
		const measurement = {
			link: item.title.text,
			type: item.type,
			unit: getUnitPreference(item.unitCategoryId)?.unit || item.unit,
			icon: item.title.icon,
			minimumValue: item.unitCategoryId
				? convertMeasurementTypes(item.unitCategoryId, item.minimumValue, getUnitPreference(item.unitCategoryId)?.unitSystemId)
				: item.minimumValue,
			maximumValue: item.unitCategoryId
				? convertMeasurementTypes(item.unitCategoryId, item.maximumValue, getUnitPreference(item.unitCategoryId)?.unitSystemId)
				: item.maximumValue,
			minimumValueSecond: item.minimumValueSecond,
			maximumValueSecond: item.maximumValueSecond,
			isDecimal: item.isDecimal,
			id: item.id,
			unitSystemId: getUnitPreference(item.unitCategoryId)?.unitSystemId,
			categoryId: item.unitCategoryId,
		};
		setMeasurementDetails(measurement);
	};

	const getCategoryPreference = categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const getMeasurementUnit = measurement => {
		return measurement.unitCategoryId ? getUnitPreference(measurement.unitCategoryId)?.unit : measurement.unit;
	};

	return (
		<Grid columns='1fr' stretch='100%'>
			<div className='check-in-patient-wrapper select-doctor-tabs-wrapper'>
				<div
					className={classNames(
						'full-page-input-wrapper select-doctor-wrapper vital-signs',
						props.isPrimaryCare ? 'position-relative primary-care-vital-signs' : ''
					)}>
					{props.isPrimaryCare && (
						<>
							<div className='flex flex-space-between primary-care-title-wrapper'>
								<Button
									className='visit-btn'
									onClick={props.nextTab}
									imgIcon={`${primaryCareImageUrl}back-arrow.svg`}
									alt='next icon'
									text={translate('goBack')}
								/>
								<div className='flex'>
									<Button
										className='visit-btn blue-btn'
										onClick={props.nextTab}
										imgIcon={`${primaryCareImageUrl}next-arrow.svg`}
										alt='next icon'
										text={translate('next')}
									/>
								</div>
							</div>
						</>
					)}
					<div className='flex flex-align-center column-direction'>
						<h3>{translate('measurePatientVitalSigns')}</h3>
						<p>{translate('mostRecentMeasurementByDate')}</p>
					</div>
					<div className='patient-vital-signs flex flex-wrap'>
						{isLoading && (
							<Grid rows='auto' width='100%' stretch='50vh' vertAlign='center' horizAlign='center'>
								<Loader />
							</Grid>
						)}
						{!isLoading &&
							measurementTypeDetails.map(item => (
								<div className='vital-signs-wrapper' key={item.id}>
									<span>{item.title.text}</span>
									<div className='flex'>
										<img src={item.title.icon} alt='ico' />
										<p className='vital-signs-value'>
											{item.unitCategoryId
												? convertMeasurementTypes(
														item.unitCategoryId,
														measurementValues[item.type].value,
														getUnitPreference(item.unitCategoryId)?.unitSystemId
												  )
												: measurementValues[item.type].value}
											<span>{getMeasurementUnit(item)}</span>
										</p>
									</div>
									<p className='measurement-values full-width flex flex-space-between'>
										{measurementValues[item.type]?.startDate && formattedDate(measurementValues[item.type]?.startDate)}

										<span
											className={`add-manually ${measurementValues[item.type]?.startDate ? '' : 'left'}`}
											onClick={() => toggleAddManually(item)}>
											{translate('addManually')}
										</span>
									</p>
								</div>
							))}
					</div>
				</div>
			</div>
			{isAddManuallyOpen && (
				<HealthDataTabContent
					isDoctorView={false}
					measurement={measurementDetails}
					isFromVitalSigns={true}
					isAddManuallyOpen={isAddManuallyOpen}
					setIsAddManuallyOpen={() => setIsAddManuallyOpen(false)}
					fetchLatestMeasurements={() => setFetchNewMeasurements(prevState => !prevState)}
					selectedPatient={props.selectedPatient}
				/>
			)}
			<Alert display={errorApiResponse} fixed={true} hideCloseButton={true} message={errorApiResponse} variant='dark' />
		</Grid>
	);
};

export default PatientVitalSigns;
