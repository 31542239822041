import * as React from 'react';

function ScreenshareOff(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={25} fill='none' {...props}>
			<path d='M18 18.553H3.2a2 2 0 01-2-2V5.4A1.8 1.8 0 013 3.6v0' stroke='currentColor' strokeWidth={1.5} />
			<path
				d='M6 3.6h14.8a2 2 0 012 2v11.153a1.8 1.8 0 01-1.8 1.8v0'
				stroke='currentColor'
				strokeWidth={1.5}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M8.676 21h7.477' stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' />
			<path
				d='M13.66 9l1.662 1.662m0 0l-.923.923m.923-.924H13.2'
				stroke='currentColor'
				strokeWidth={1.5}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M11.454 11.766a.75.75 0 00-1.308-.733l1.308.733zm-2.6 1.575a.75.75 0 001.308.733l-1.309-.733zm1.292-2.308l-1.293 2.308 1.31.733 1.291-2.308-1.308-.733z'
				fill='currentColor'
			/>
			<path d='M1.2 1.8l21.6 21.6' stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' />
		</svg>
	);
}

export default ScreenshareOff;
