import DottedCircle from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/GenericCharts/DottedCircle.jsx';

const PerfusionIndexSummaryChart = props => {
	return (
		<>
			<div className='perfusion-index-graph'>
				<DottedCircle value={props.measurementValue} numberOfLines={20} />
			</div>
			<div className='flex summary-chart-data perfusion-index-data'>
				<h1>
					{props.measurementValue}
					{props.measurementValue !== 'No Data' && <span>{props.measurementUnit}</span>}
				</h1>
			</div>
		</>
	);
};

export default PerfusionIndexSummaryChart;
