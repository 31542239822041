import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import { Range, SectorTypes } from 'constants/enums.js';
import { useSelector } from 'react-redux';
import Alert from 'components/Alert.jsx';
import { updateHospital } from 'api/hospitals.js';
import { updateDepartment } from 'api/departments.js';
import { updateFloor } from 'api/floors.js';
import { saveRoomConfiguration } from 'api/rooms.js';
import TimePicker from 'components/TimePicker.jsx';
import { formatDateTimeToString } from 'infrastructure/helpers/dateHelper.js';
import { useIntl } from 'react-intl';

const ScheduleNightVision = ({ onModalClose, sector, fetchTreeData }) => {
	const userSession = useSelector(state => state.user.userSession);
	const [isLoading, setIsLoading] = useState(false);
	const [alertErrorText, setAlertErrorText] = useState('');
	const [selectedTime, setSelectedTime] = useState({
		from: null,
		to: null,
	});
	const { type, scheduleNightVisionFrom, scheduleNightVisionTo, hospitalId, departmentId, floorId, roomId } = sector;

	const intl = useIntl();

	useEffect(() => {
		if (scheduleNightVisionFrom && scheduleNightVisionTo) {
			const [startHour, startMinute, startSecond] = scheduleNightVisionFrom.split(':');
			const [endHour, endMinute, endSecond] = scheduleNightVisionTo.split(':');
			setSelectedTime({
				from: new Date().setHours(startHour, startMinute, startSecond),
				to: new Date().setHours(endHour, endMinute, endSecond),
			});
		} else {
			setSelectedTime({ from: null, to: null });
		}
	}, [sector]);

	const validateTime = () => {
		let startTime;
		let endTime;
		if (Object.values(selectedTime).every(prop => prop === null)) {
			startTime = null;
			endTime = null;
		} else if (Object.values(selectedTime).some(prop => prop === null)) {
			setAlertErrorText(intl.formatMessage({ id: 'nightVisionError' }));
			setIsLoading(false);
			return null;
		} else {
			startTime = formatDateTimeToString(selectedTime.from);
			endTime = formatDateTimeToString(selectedTime.to);
		}
		return { startTime, endTime };
	};

	const handleSubmit = async () => {
		const { regionId } = userSession;
		const healthSystemId = userSession.healthSystem.id;

		setIsLoading(true);
		const timeValidated = validateTime();

		if (!timeValidated) {
			return;
		}

		let submitResponse = {};

		const params = {
			ScheduleNightVisionFrom: timeValidated.startTime,
			ScheduleNightVisionTo: timeValidated.endTime,
			name: sector?.sectorName,
		};

		if (SectorTypes.HOSPITAL === type) {
			submitResponse = await updateHospital({
				healthSystemId,
				regionId,
				hospitalId,
				params,
			});
		}

		if (SectorTypes.DEPARTMENT === type) {
			submitResponse = await updateDepartment({
				healthSystemId,
				departmentId,
				hospitalId,
				params,
			});
		}

		if (SectorTypes.FLOOR === type) {
			submitResponse = await updateFloor({
				healthSystemId,
				departmentId,
				hospitalId,
				floorId,
				params,
			});
		}

		if (SectorTypes.ROOM === type) {
			submitResponse = await saveRoomConfiguration({
				healthSystemId,
				departmentId,
				hospitalId,
				floorId,
				roomId,
				params,
			});
		}

		if (submitResponse.error) {
			setAlertErrorText(submitResponse.error.message);
		} else {
			fetchTreeData();
			onModalClose();
		}

		setIsLoading(false);
	};

	const handleTimeChange = (range, value) => {
		if (value) {
			const { hours, minutes, seconds, dayPeriod } = value;
			const time = new Date().setHours(dayPeriod === 1 ? hours : hours + 12, minutes, seconds);
			setSelectedTime(prev => ({ ...prev, [Range.START === range ? 'from' : 'to']: time }));
		} else {
			setSelectedTime({ from: null, to: null });
		}
	};

	return (
		<Modal
			modalSelector='editSectorModal'
			display={true}
			onModalSubmit={handleSubmit}
			onModalClose={onModalClose}
			className={classNames(isLoading ? 'none-pointer-events' : '')}
			shouldSubmitOnEnter={false}
			isSubmitDisabled={false}
			primaryButtonLoading={isLoading}
			primaryButtonLabel={translate('confirm')}
			isThirdButtonShown={true}
			thirdButtonClassNames={'reset-btn'}
			thirdButtonLabel={translate('removeSchedule')}
			onThirdButtonClick={() => setSelectedTime({ from: null, to: null })}
			position='right'>
			<Form
				className={classNames('night-vision-form', isLoading ? 'disabled' : '')}
				title={translate('scheduleNightVision')}
				onSubmit={event => event.preventDefault()}
				description={intl.formatMessage({ id: 'nightVisionModalDesc' })}>
				<div>
					<div className='flex-1'>
						<div className='picker-container'>
							<span>{translate('startTime')}</span>
							<div className='row-1'>
								<div className='date-range-time-pickers'>
									<TimePicker
										key={Range.START}
										value={selectedTime.from}
										index={0}
										handleTimeChange={value => handleTimeChange(Range.START, value)}
										disableOption={false}
									/>
								</div>
							</div>
						</div>
						<div className='picker-container'>
							<span>{translate('endTime')}</span>
							<div className='row-1'>
								<div className='date-range-time-pickers'>
									<TimePicker
										key={Range.END}
										value={selectedTime.to}
										index={1}
										handleTimeChange={value => handleTimeChange(Range.END, value)}
										disableOption={false}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Form>
			{alertErrorText && (
				<Alert
					display={alertErrorText}
					fixed={true}
					message={alertErrorText}
					variant='dark'
					onClose={() => setAlertErrorText(null)}
					persist={true}
				/>
			)}
		</Modal>
	);
};

export default ScheduleNightVision;
