import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';

export const mapSelectedOptions = arr => {
	let result = '';
	if (!arr) {
		return result;
	}
	arr.forEach((item, index) => {
		result += `${item.label}${index === arr.length - 1 ? '' : ', '}`;
	});
	return result;
};

export const getMonitoringActiveIconColor = darkMode => {
	return darkMode ? LightTheme.colors.grayZero : DarkTheme.colors.grayFour;
};

export const getMonitoringIconColor = darkMode => {
	return darkMode ? LightTheme.colors.grayTwo : LightTheme.colors.grayFour;
};
