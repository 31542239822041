import React from 'react';

const Help = () => (
	<div className='healthcare-help'>
		<header>
			<img src='https://static.solaborate.com/healthcare-system/hellocare-logo.svg' alt='Healthcare logo' />
			<img src='https://static.solaborate.com/global/brand/assets/logos/sol-full-dark.svg' alt='Solaborate logo' />
		</header>
		<main>
			<div>
				<img src='https://static.solaborate.com/banyan/24-7-white.svg' alt='icon' />
				<h1>24/7 Support</h1>
				<p>If you&apos;re encountering any issues, please contact us on the live chat or via email at support@solaborate.com </p>
			</div>
		</main>
	</div>
);

export default Help;
