import * as React from 'react';

const ListView = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
		<path
			stroke='currentColor'
			strokeWidth={1.5}
			d='M17 2.4H9.4a1 1 0 0 0-1 1v2.8a1 1 0 0 0 1 1H17a1 1 0 0 0 1-1V3.4a1 1 0 0 0-1-1ZM17 9.6H9.4a1 1 0 0 0-1 1v2.8a1 1 0 0 0 1 1H17a1 1 0 0 0 1-1v-2.8a1 1 0 0 0-1-1ZM17 16.8H9.4a1 1 0 0 0-1 1v2.8a1 1 0 0 0 1 1H17a1 1 0 0 0 1-1v-2.8a1 1 0 0 0-1-1ZM8.4 19.2H7a1 1 0 0 1-1-1V12m0 0V5.8a1 1 0 0 1 1-1h1.4M6 12h2.4'
		/>
	</svg>
);
export default ListView;
