import React, { useEffect, useRef } from 'react';

const ParticipantAudio = React.forwardRef(
	(
		/**
		 * @type {{track?: import('@solaborate/calls/webrtc').Track;} & React.DetailedHTMLProps<React.AudioHTMLAttributes<HTMLAudioElement>, HTMLAudioElement>}
		 * */
		{ track, autoPlay = true, ...htmlAttributes },
		/** @type {React.RefObject<HTMLAudioElement>} */
		ref
	) => {
		const audioRef = useRef(null);

		useEffect(() => {
			// eslint-disable-next-line no-param-reassign
			audioRef.current.srcObject = track ? new MediaStream([track.track]) : null;
			if (ref) {
				// @ts-ignore
				// eslint-disable-next-line no-param-reassign
				ref.current = audioRef.current;
			}
		}, [track, ref]);

		return <audio {...htmlAttributes} ref={audioRef} autoPlay={autoPlay} />;
	}
);
ParticipantAudio.displayName = 'ParticipantAudio';

export default ParticipantAudio;
