import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import moment from 'moment';
import Modal from 'components/Modal.jsx';
import Genders from 'components/Genders.jsx';
import { AlertTypes, Gender } from 'constants/enums.js';
import { genderItems } from 'constants/genderItems.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { isAdUser, stringToCamelCase, validateMaxLength } from 'infrastructure/helpers/commonHelpers.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { uploadProfilePic } from 'api/media.js';
import { getPictureUrl } from 'infrastructure/helpers/thumbnailHelper.js';
import ImageUploader from 'containers/ImageUploader.jsx';
import { generateCustomStyles } from 'constants/react-select-style.js';
import Button from 'components/Button.jsx';

const PersonalInformation = props => {
	const intl = useIntl();
	const [isLanguagesModalVisible, setIsLanguagesModalVisible] = useState(false);
	const [error, setError] = useState(null);

	const getCheckedLanguages = () => props.languages.filter(language => language.checked);

	return (
		<>
			<div className='flex flex-justify-center flex-align-center column-direction'>
				<label>{translate('profilePicture')}</label>
				<div className='flex profile-info-image flex-align-center column-direction'>
					<ImageUploader
						setError={setError}
						existingLogo={
							props.formikProps.values.profilePicture ? getPictureUrl(320) + props.formikProps.values.profilePicture : ''
						}
						setProfilePicture={props.setProfilePictureProps}
						uploadProfilePic={uploadProfilePic}
						error={props.formikProps.errors.profilePicture}
						sizeInMB={2}
					/>
				</div>
			</div>
			<div className='flex personal-details-wrapper'>
				<div className='flex column-direction personal-details'>
					<label>*{translate('firstName')}</label>
					<input
						type='text'
						placeholder={intl.formatMessage({ id: 'firstName' })}
						maxLength={30}
						value={props.formikProps.values.firstName}
						onChange={props.onChange}
						readOnly={isAdUser()}
						name='firstName'
					/>
					<span className='red-error'>{props.formikProps.errors.firstName}</span>
				</div>
				<div className='flex column-direction personal-details'>
					<label>*{translate('lastName')}</label>
					<input
						type='text'
						placeholder={intl.formatMessage({ id: 'lastName' })}
						maxLength={30}
						value={props.formikProps.values.lastName}
						onChange={props.onChange}
						readOnly={isAdUser()}
						name='lastName'
					/>
					<span className='red-error'>{props.formikProps.errors.lastName}</span>
				</div>
			</div>
			<div className='flex personal-details-wrapper'>
				<div className='flex column-direction personal-details'>
					<label>{translate('idNumber')}</label>
					<input
						type='number'
						placeholder={intl.formatMessage({ id: 'idNumber' })}
						value={props.formikProps.values.idNumber}
						onChange={props.onChange}
						name='idNumber'
						onKeyDown={event => validateMaxLength(event, 10)}
					/>
				</div>
				<div className='flex column-direction personal-details disabled'>
					<label>*{translate('emailAddress')}</label>
					<input type='email' value={props.formikProps.values.email} name='email' readOnly />
				</div>
			</div>
			<div className='flex personal-details-wrapper'>
				<div className='flex column-direction personal-details position-relative registration-select-wrapper'>
					<label>{translate('specialty')}</label>
					<Select
						value={props.transformArray(props.specialties).filter(option => option.value === props.formikProps.values.specialty)}
						placeholder={intl.formatMessage({ id: 'selectSpecialty' })}
						classNamePrefix='react-select'
						options={props.transformArray(props.specialties)}
						onChange={event => props.setFieldValue('specialty', event?.value)}
						isSearchable
						isClearable
						styles={{
							...generateCustomStyles({ darkMode: props.isDarkMode }),
						}}
					/>
				</div>
				<div className='flex column-direction personal-details'>
					<label>{translate('sex')}</label>
					<Genders
						items={genderItems}
						handleChange={event =>
							props.setFieldValue(
								'genderId',
								event.target.value === props.formikProps.values.genderId ? Gender.UNAVAILABLE : event.target.value
							)
						}
						errors={props.formikProps.errors}
						value={props.formikProps.values.genderId}
						isCompleteRegistration={true}
					/>
				</div>
			</div>
			<div className='flex personal-details-wrapper position-relative'>
				<div className='flex column-direction personal-details'>
					<label>{translate('birthDate')}</label>
					<input
						type='date'
						placeholder={intl.formatMessage({ id: 'selectDate' })}
						value={props.formikProps.values.dateOfBirth}
						onChange={props.onChange}
						name='dateOfBirth'
						max={moment().subtract(18, 'years').format('YYYY-MM-DD')}
					/>
					<span className='red-error'>{props.formikProps.errors.dateOfBirth}</span>
				</div>
				<div className='flex column-direction personal-details'>
					<label>{translate('phoneNumber')}</label>
					<input
						type='number'
						placeholder={intl.formatMessage({ id: 'phoneNumber' })}
						value={props.formikProps.values.phoneNumber}
						onChange={props.onChange}
						name='phoneNumber'
						onKeyDown={event => validateMaxLength(event, 15)}
					/>
				</div>
			</div>

			<div className='flex personal-details-wrapper'>
				<div className='flex column-direction personal-details'>
					<label>{translate('languages')}</label>
					<div className='languages-list'>
						{getCheckedLanguages().length === 0 && <span>{translate('languages')}</span>}
						{getCheckedLanguages().map(language => (
							<p key={language.id}>{language.checked ? intl.formatMessage({ id: stringToCamelCase(language.name) }) : ''}</p>
						))}
					</div>
				</div>
				<div className='flex'>
					<Button
						imgIcon={`${healthCareCdnUrl}add-new.svg`}
						className='select-languages'
						onClick={() => setIsLanguagesModalVisible(prevState => !prevState)}
						text={translate('languages')}
					/>
				</div>
				{isLanguagesModalVisible && (
					<Modal
						display={true}
						position='center'
						onModalClose={() => setIsLanguagesModalVisible(prevState => !prevState)}
						primaryButtonLabel={translate('saveAndContinue')}
						closeButtonText={translate('reset')}
						className='standard-modal-wrapper send-request-modal-wrapper register-to-practice-modal '
						onModalSubmit={() => setIsLanguagesModalVisible(prevState => !prevState)}>
						<div className='standard-modal-inner'>
							<div className='standard-modal-title'>
								<h3>{translate('languages')}</h3>
							</div>
							<div className='flex flex-wrap input-auto-width-wrapper'>
								{props.languages.map(item => (
									<div key={item.id} className='register-practice-modal-grid tick-box-active-input'>
										<label className='full-width remember-me'>
											<input
												onChange={event =>
													props.onLanguagesChange({
														id: item.id,
														name: item.name,
														checked: event.target.checked,
													})
												}
												type='checkbox'
												checked={item.checked}
											/>
											<div className='onb-custom-checkbox-wrapper cursor-pointer'>
												<div className='onb-custom-checkbox' />
											</div>
											<span>{translate(`${stringToCamelCase(item.name)}`)}</span>
										</label>
									</div>
								))}
							</div>
						</div>
					</Modal>
				)}
				<PopUpAlert
					alertType={AlertTypes.DANGER}
					display={error}
					onAlertClose={() => setError(null)}
					contentText={error}
					isSilent={true}
					center={true}
				/>
			</div>
		</>
	);
};

export default PersonalInformation;
