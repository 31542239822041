import { NotificationsCounterActionTypes } from 'constants/action-types.js';

const initialState = {
	notificationsCounter: 0,
	fetchingNotificationCounter: false,
};


export const notifications = (state = initialState, action) => {
	switch (action.type) {
		case NotificationsCounterActionTypes.FETCHING_NOTIFICATIONS_COUNTER:
			return {
				...state,
				fetchingNotificationCounter: true,
			};
		case NotificationsCounterActionTypes.FETCH_NOTIFICATIONS_COUNTER_FAILURE:
			return {
				...state,
				fetchingNotificationCounter: false,
			};
		case NotificationsCounterActionTypes.FETCH_NOTIFICATIONS_COUNTER_SUCCESS:
			return {
				...state,
				fetchingNotificationCounter: false,
				notificationsCounter: action.data,
			};
		default:
			return state;
	}
};
