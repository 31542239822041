import React from 'react';
import {
	signinRedirectCallback,
	logout,
	signoutRedirectCallback,
	isAuthenticated,
	signinRedirect,
	signinSilentCallback,
	createSigninRequest,
} from 'infrastructure/auth.js';

const AuthContext = React.createContext({
	signinRedirectCallback: () => ({}),
	logout: () => ({}),
	signoutRedirectCallback: () => ({}),
	isAuthenticated: () => ({}),
	signinRedirect: () => ({}),
	signinSilentCallback: () => ({}),
	createSigninRequest: () => ({}),
});

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = props => {
	const authService = {
		signinRedirectCallback: signinRedirectCallback,
		logout: logout,
		signoutRedirectCallback: signoutRedirectCallback,
		isAuthenticated: isAuthenticated,
		signinRedirect: signinRedirect,
		signinSilentCallback: signinSilentCallback,
		createSigninRequest: createSigninRequest,
	};
	return <AuthContext.Provider value={authService}>{props.children}</AuthContext.Provider>;
};
