const spacingBase = 4;
const typographyBase = 16;
const typographyScale = 1.25;

const DarkTheme = {
	avatar: {
		sizes: {
			1: 24,
			2: 32,
			3: 48,
			4: 64,
			5: 128,
		},
	},
	sizes: {
		1: 24,
		2: 32,
		3: 48,
		4: 64,
		5: 128,
	},
	colors: {
		grayOne: '#141414',
		grayTwo: '#1a1f24',
		grayThree: '#252c33',
		grayFour: '#313a42',
		grayFourTransparent: 'rgba(49, 58, 66, 0.8)',
		grayFive: '#cfd3da',
		graySix: '#ffffff',
		blueOne: '#5390FF',
		blueTwo: '#0097e6',
		blueThree: '#0153b6',
		blueFour: '#5390FF',
		blueFive: '#5390FF1A',
		blueSix: ' #A8C7FF ',
		blueLightOne: '#B5CDFA',
		greenLightOne: '#b5e2da',
		greenOne: '#e84118',
		greenTwo: '#33C27F',
		greenThree: '#33C27F1A',
		greenFour: '#b2e097',
		redOne: '#e84118',
	},
	spacing: {
		1: spacingBase,
		2: spacingBase * 2,
		3: spacingBase * 3,
		4: spacingBase * 4,
		5: spacingBase * 5,
		6: spacingBase * 6,
		7: spacingBase * 8,
		8: spacingBase * 10,
		9: spacingBase * 20,
		10: spacingBase * 40,
	},
	typography: {
		h1: typographyBase * typographyScale * 6,
		h2: typographyBase * typographyScale * 5,
		h3: typographyBase * typographyScale * 4,
		h4: typographyBase * typographyScale * 3,
		h5: typographyBase * typographyScale * 1.5,
		h6: typographyBase + 2.5,
		p: typographyBase,
		small: typographyBase - 2.5,
	},
	borderRadius: {
		base: 5,
		buttons: 4,
		avatar: 100,
	},
};

export default DarkTheme;
