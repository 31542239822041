import CircularPercentage from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/GenericCharts/CircularPercentage.jsx';

const OxygenSaturationSummaryChart = props => {
	return (
		<>
			<div className='oxygen-saturation-graph'>
				<CircularPercentage value={props.measurementValue} />
			</div>
			<div className='flex summary-chart-data oxygen-saturation-data'>
				<h1>
					{props.measurementValue}
					{props.measurementValue !== 'No Data' && <span>{props.measurementUnit}</span>}
				</h1>
			</div>
		</>
	);
};

export default OxygenSaturationSummaryChart;
