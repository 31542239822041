import { getExternalDevices } from 'api/lifeSignals.js';
import Alert from 'components/Alert.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Modal from 'components/Modal.jsx';
import { WearableProviders, WearablesStatus } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const WearablesHistory = props => {
	const [error, setError] = useState(null);
	const [wearables, setWearables] = useState([]);
	const [pageIndex, setPageIndex] = useState(0);
	const [hasReachedEnd, setHasReachedEnd] = useState(false);
	const intl = useIntl();

	useEffect(() => {
		const fetchWearables = async () => {
			const response = await getExternalDevices({ patientId: props.ownerId, status: WearablesStatus.INACTIVE, pageIndex });
			const pageSize = 20;

			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			if (response.patientExternalIotDevices.length > 0) {
				setWearables(prevState => prevState.concat(response.patientExternalIotDevices));
			}

			if (response.patientExternalIotDevices.length < pageSize) {
				setHasReachedEnd(true);
			}
		};
		if (props.display) {
			fetchWearables();
		}
	}, [intl, props.ownerId, props.display, pageIndex]);

	const displayWearables = () => {
		if (!wearables || !wearables.length) {
			return [];
		}
		return wearables.map(wearable => ({
			provider: WearableProviders[wearable.externalIotDeviceType.vendorId],
			activeWearableId: wearable.iotDeviceId,
			startDate: formattedDate(wearable.dateCreated),
			status: translate('inactive'),
		}));
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const handleModalClose = () => {
		props.setIsWearableHistoryOpen(false);
		setWearables([]);
		setHasReachedEnd(false);
		setPageIndex(0);
	};

	return (
		<Modal
			display={props.display}
			hideActionButtons={true}
			position='right'
			className='assign-patients-modal'
			onModalClose={handleModalClose}
			primaryButtonLabel='Save'>
			<div className='padding-sl wearables-history' onScroll={handleScroll}>
				<h3>{translate('history')}</h3>
				<CustomTable
					headers={[
						{ title: translate('provider'), id: 'provider' },
						{ title: translate('wearableId'), id: 'activeWearableId' },
						{ title: translate('startDate'), id: 'startDate' },
						{ title: translate('status'), id: 'status' },
					]}
					rows={displayWearables()}
					className='admin-table'
				/>
				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			</div>
		</Modal>
	);
};

export default WearablesHistory;
