import { gatewayApi } from 'constants/global-variables.js';
import { HttpMethods } from 'constants/enums.js';
import { getCompanyId, getUserInfo } from 'infrastructure/auth.js';
import { configurationTypeIds } from 'constants/integrationEnums.js';
import { genderOptionCodeIds, specialtyOptionCodeIds } from 'constants/voyceEnums.js';

export const voyceAPI = async params => {
	try {
		const baseUrl = `/api/tenant-configurations/organizations/${getCompanyId()}/translation-services/${
			configurationTypeIds.VOYCE.id
		}`;
		const { data } = await gatewayApi.post(baseUrl, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getVoyceLanguages = async (baseUrl, location) => {
	const url = `${baseUrl}apimeeting/language/list`;
	const params = { method: HttpMethods.GET, path: url, query: '', body: '', location };
	try {
		const response = await voyceAPI(params);
		return response;
	} catch (error) {
		return { error };
	}
};

export const createRequest = async ({ languageId, callType, baseUrl, inviteUrl, conferenceId }, location) => {
	const { firstName, lastName } = getUserInfo();
	const obj = {
		LanguageId: languageId,
		SpecialtyOptionCodeId: specialtyOptionCodeIds.MEDICAL,
		VideoOptionCodeId: callType,
		GenderOptionCodeId: genderOptionCodeIds.NO_PREFERENCE,
		Reference: '',
		MeetingId: conferenceId,
		Note: '',
		URL: inviteUrl,
		USPhone: '',
		PhoneAccessCode: '',
		ClientUserInfo: {
			ClientId: '',
			ClientName: 'hellocare',
			UserId: '',
			UserName: `${firstName} ${lastName}`,
			AdditionalInfo: '',
		},
	};
	const url = `${baseUrl}apimeeting/request/new`;
	const params = { method: HttpMethods.POST, path: url, query: '', body: JSON.stringify(obj), location };
	try {
		const response = await voyceAPI(params);
		return response;
	} catch (error) {
		return { error };
	}
};

export const finishVoyceRequest = async (requestid, baseUrl, location) => {
	const url = `${baseUrl}apimeeting/request/finish?requestid=${requestid}`;
	const params = { method: HttpMethods.POST, path: url, query: '', body: '', location };
	try {
		const response = await voyceAPI(params);
		return response;
	} catch (error) {
		return { error };
	}
};

export const getRequestStatus = async (requestId, baseUrl, location) => {
	const url = `${baseUrl}/apimeeting/request/status?requestId=${requestId}`;
	const params = { method: HttpMethods.GET, path: url, query: '', body: '', location };
	try {
		const response = await voyceAPI(params);
		return response;
	} catch (error) {
		return { error };
	}
};

export const postVoyceRequestServiceTime = async ({ requestId, baseUrl, sessionTimeList = [] }, location) => {
	const obj = {
		PreInviteToken: '',
		RequestId: requestId,
		SessionTimeList: sessionTimeList,
	};
	const url = `${baseUrl}/apimeeting/request/serviceTime`;
	const params = { method: HttpMethods.POST, path: url, query: '', body: JSON.stringify(obj), location };
	try {
		const response = await voyceAPI(params);
		return response;
	} catch (error) {
		return { error };
	}
};
