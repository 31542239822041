import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Play = props => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			color={LightTheme.colors.grayZero}
			{...props}>
			<path
				d='M7 18.382V5.61803C7 4.87465 7.78231 4.39116 8.44721 4.72361L21.2111 11.1056C21.9482 11.4741 21.9482 12.5259 21.2111 12.8944L8.44721 19.2764C7.78231 19.6088 7 19.1253 7 18.382Z'
				stroke='currentColor'
			/>
		</svg>
	);
};

export default Play;

