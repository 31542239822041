import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const SelectBackground = props => (
	<svg
		width={28}
		height={29}
		viewBox='0 0 28 29'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		color={LightTheme.colors.graySeven}
		{...props}>
		<rect
			x='1.33331'
			y='4.16667'
			width='25.3333'
			height='17.1667'
			rx='3'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
		/>
		<path
			d='M9.04165 20.3332V18.4407C9.04165 15.8278 11.2616 13.7095 14 13.7095C16.7384 13.7095 18.9583 15.8278 18.9583 18.4407V20.3332'
			stroke='currentColor'
			strokeWidth='2'
			strokeMiterlimit='10'
		/>
		<path
			d='M16.3054 18.2635C16.3054 17.7112 16.7531 17.2635 17.3054 17.2635C17.8577 17.2635 18.3054 17.7112 18.3054 18.2635H16.3054ZM18.3054 18.2635V20.3334H16.3054V18.2635H18.3054Z'
			fill='currentColor'
		/>
		<path
			d='M9.69452 18.2635C9.69452 17.7112 10.1422 17.2635 10.6945 17.2635C11.2468 17.2635 11.6945 17.7112 11.6945 18.2635H9.69452ZM11.6945 18.2635V20.3334H9.69452V18.2635H11.6945Z'
			fill='currentColor'
		/>
		<path
			d='M11.5207 9.98387C11.5207 11.3557 12.6306 12.4677 13.9998 12.4677C15.369 12.4677 16.479 11.3557 16.479 9.98387C16.479 8.61207 15.369 7.5 13.9998 7.5C12.6306 7.5 11.5207 8.61207 11.5207 9.98387Z'
			stroke='currentColor'
			strokeWidth='2'
			strokeMiterlimit='10'
		/>
		<path d='M17.5 22.6667H10.5' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
	</svg>
);

export default SelectBackground;
