import React, { useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import { generateCustomStyles } from 'constants/react-select-style.js';
import { useIntl } from 'react-intl';
import { goingHomeOptions, rideAnswerOptions } from 'constants/monitoring.js';
import Button from 'components/Button.jsx';
import ActionButtons from 'containers/Monitoring/Whiteboard/ActionButtons.jsx';

const GoingHome = props => {
	const intl = useIntl();
	const [expanded, setExpanded] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);
	const [selectedGoingHomeOption, setSelectedGoingHomeOption] = useState(
		props.data.timeFrame ? goingHomeOptions(intl).find(item => props.data.timeFrame === item.value) : null
	);
	const [rideAnswer, setRideAnswer] = useState(
		props.data.ride ? rideAnswerOptions(intl).find(item => props.data.ride === item.value) : null
	);

	const onSubmit = () => {
		props.setData({
			timeFrame: selectedGoingHomeOption ? selectedGoingHomeOption.value : null,
			ride: rideAnswer ? rideAnswer.value : false,
		});
	};

	useEffect(() => {
		if (props.data.timeFrame) {
			setSelectedGoingHomeOption(goingHomeOptions(intl).find(item => props.data.timeFrame === item.value) || null);
		}
		setRideAnswer(rideAnswerOptions(intl).find(item => props.data.ride === item.value) || null);
	}, [intl, props.data]);

	return (
		<>
			<div className={classNames('timeline-box-header sub-timeline-box-header', expanded ? 'expanded' : '')}>
				<p className='timeline-box-title'>{translate('goingHome')}</p>
				<div className='timeline-box-actions'>
					<ActionButtons
						handleEditClick={() => {
							setShowEditForm(prevState => !prevState);
							setExpanded(true);
						}}
						handleExpandClick={() => setExpanded(prevState => !prevState)}
						expanded={expanded}
					/>
				</div>
			</div>
			{expanded && (
				<>
					{!showEditForm && (
						<table className='patient-table-informations' style={{ width: '100%' }}>
							<tbody>
								<tr>
									<td>{translate('goingHome')}</td>
									<td>
										{props.data.timeFrame ? goingHomeOptions(intl).find(item => props.data.timeFrame === item.value).label : ''}
									</td>
								</tr>
								<tr>
									<td>{translate('rideConfirmed')}</td>
									<td>{translate(props.data.ride ? 'yes' : 'no')}</td>
								</tr>
							</tbody>
						</table>
					)}
					{showEditForm && (
						<>
							<div className='flex flex-space-between flex-wrap whiteboard-select'>
								<ReactSelect
									options={goingHomeOptions(intl)}
									value={selectedGoingHomeOption}
									onChange={setSelectedGoingHomeOption}
									id='select'
									classNamePrefix='react-select full-width'
									styles={{
										...generateCustomStyles({ darkMode: props.isDarkMode }),
									}}
									placeholder={intl.formatMessage({ id: 'goingHome' })}
								/>
								<div className='top-15'>
									<ReactSelect
										options={rideAnswerOptions(intl)}
										value={rideAnswer}
										onChange={setRideAnswer}
										id='select'
										classNamePrefix='react-select full-width'
										styles={{
											...generateCustomStyles({ darkMode: props.isDarkMode }),
										}}
										placeholder={intl.formatMessage({ id: 'rideConfirmed' })}
									/>
								</div>
							</div>
							<Button
								className={classNames('button', props.isSubmitButtonLoading ? 'loading' : '')}
								type='button'
								onClick={onSubmit}
								text={translate('save')}
							/>
						</>
					)}
				</>
			)}
		</>
	);
};

export default GoingHome;
