import { getTeamSettings, updateTeamSettings } from 'api/adminConfigurations.js';
import {
	CompanyCallSettings,
	CompanySettings,
	ConfigurableCallSettings,
	ConfigurableDigitalBackgroundSettings,
	ConfigurableRoomSignCallSettings,
} from 'constants/configurationEnums.js';
import DigitalBackground from 'containers/CallSettings/DigitalBackground.jsx';
import BackgroundImage from 'containers/CallSettings/BackgroundImage.jsx';
import translate from 'i18n-translations/translate.jsx';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import Alert from 'components/Alert.jsx';
import SkeletonLoader from 'components/SkeletonLoader.jsx';
import { DeviceListLevel } from 'constants/enums.js';
import { buildVisualsSettings } from 'infrastructure/helpers/configurationsHelpers.js';
import CallSettings from 'components/CallSettings.jsx';
import DefaultCamSettings from 'containers/CallSettings/DefaultCamSettings.jsx';

const backgroundItems = [
	{
		title: translate('incomingCallBackgrounds'),
		description: translate('uploadImageOnIncomingCall'),
		container: 'hello-background-picture',
		settingKey: CompanySettings.HELLO_DEFAULT_BACKGROUND,
	},
	{
		title: translate('helloWallpaper'),
		description: translate('helloWallpaperDesc'),
		container: 'hello-wallpaper',
		settingKey: CompanySettings.HELLO_DEFAULT_WALLPAPER,
	},
	{
		title: translate('helloAudioCallBackground'),
		description: translate('helloAudioCallBackgroundDes'),
		container: 'audio-call-background-picture',
		settingKey: CompanySettings.AUDIO_CALL_BACKGROUND,
	},
];

const CallSettingsAdmin = ({ selectedHealthSystem, setSelectedHealthSystem, healthSystems }) => {
	const [wallpaperBackgroundConfigs, setWallpaperBackgroundConfigs] = useState(ConfigurableDigitalBackgroundSettings);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [submitParams, setSubmitParams] = useState({
		patientCamera: {
			value: false,
			key: CompanyCallSettings.PATIENT_CAMERA,
		},
		providerMic: {
			value: false,
			key: CompanyCallSettings.PROVIDER_MIC,
		},
		providerCamera: {
			value: false,
			key: CompanyCallSettings.PROVIDER_CAMERA,
		},
		patientMicrophone: {
			value: false,
			key: CompanyCallSettings.PATIENT_MIC,
		},
		rsPatientCamera: {
			value: false,
			key: CompanyCallSettings.RS_PATIENT_CAM,
		},
		rsProviderMic: {
			value: false,
			key: CompanyCallSettings.RS_PROVIDER_MIC,
		},
		rsProviderCamera: {
			value: false,
			key: CompanyCallSettings.RS_PROVIDER_CAM,
		},
		rsPatientMic: {
			value: false,
			key: CompanyCallSettings.RS_PATIENT_MIC,
		},
	});

	const mapCallSettings = configs => {
		const result = {};
		[
			{ key: CompanyCallSettings.PROVIDER_MIC, value: 'providerMic' },
			{ key: CompanyCallSettings.PROVIDER_CAMERA, value: 'providerCamera' },
			{ key: CompanyCallSettings.PATIENT_CAMERA, value: 'patientCamera' },
			{ key: CompanyCallSettings.PATIENT_MIC, value: 'patientMicrophone' },
			{ key: CompanyCallSettings.RS_PROVIDER_MIC, value: 'rsProviderMic' },
			{ key: CompanyCallSettings.RS_PROVIDER_CAM, value: 'rsProviderCamera' },
			{ key: CompanyCallSettings.RS_PATIENT_CAM, value: 'rsPatientCamera' },
			{ key: CompanyCallSettings.RS_PATIENT_MIC, value: 'rsPatientMic' },
		].forEach(item => {
			const foundItem = configs.settings.find(setting => setting.settingTypeId === item.key);

			result[item.value] = { value: foundItem?.value === 'true', key: item.key };
		});
		return result;
	};

	useEffect(() => {
		const fetchSectorSettings = async () => {
			setIsLoading(true);
			const response = await getTeamSettings({
				teamId: selectedHealthSystem.value,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: null,
			});

			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			setWallpaperBackgroundConfigs(buildVisualsSettings(response.settings));
			setSubmitParams(mapCallSettings(response));
			setIsLoading(false);
		};
		fetchSectorSettings();
	}, [selectedHealthSystem]);

	const handleChangeConfig = async obj => {
		const key = submitParams[obj.name].key;
		const value = (!submitParams[obj.name].value).toString();
		const params = {
			teamSettings: [
				{
					settingTypeId: key,
					value,
					isLocked: false,
				},
			],
			teamId: selectedHealthSystem.value,
			isOverride: false,
			healthSystemId: selectedHealthSystem.value,
		};
		const response = await updateTeamSettings(params);

		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}

		setSubmitParams(prevState => ({
			...prevState,
			[obj.name]: { key, value: value === 'true' },
		}));
	};

	return (
		<div className='account-settings-inner-wrapper general-menu-wrapper configurations-call-settings-admin'>
			<div className='configs-table-headers no-padding'>
				<div className='background-health-system'>
					<label>{translate('selectHealthSystem')}</label>
					<Select
						value={selectedHealthSystem}
						classNamePrefix='react-select'
						options={healthSystems.map(item => ({ value: item.id, label: item.name || item.value }))}
						onChange={setSelectedHealthSystem}
					/>
				</div>
			</div>
			{isLoading && <SkeletonLoader rows={10} padding='35px 20px' />}
			{!isLoading && (
				<div className='call-settings-wallpapers'>
					<CallSettings
						key='callSettings'
						handleChangeConfig={handleChangeConfig}
						submitParams={submitParams}
						defaultConfigs={ConfigurableCallSettings}
						title={translate('providerAndPatientCallSettings')}
						callConfigsDescription={translate('talkToPatientSettingsDesc')}
					/>
					<CallSettings
						key='roomSignCallSettings'
						handleChangeConfig={handleChangeConfig}
						submitParams={submitParams}
						defaultConfigs={ConfigurableRoomSignCallSettings}
						title={translate('roomSignCallSettings')}
						callConfigsDescription={translate('callSettingsForRoomSignDesc')}
					/>
					<DefaultCamSettings
						healthSystemId={selectedHealthSystem.value}
						levelId={DeviceListLevel.HEALTH_SYSTEM}
						sectorId={selectedHealthSystem.value}
						isSector={false}
					/>
					<DigitalBackground
						selectedHealthSystemId={selectedHealthSystem.value}
						wallpaperBackgroundConfigs={wallpaperBackgroundConfigs}
						setWallpaperBackgroundConfigs={setWallpaperBackgroundConfigs}
					/>
					{backgroundItems.map(background => (
						<BackgroundImage
							title={background.title}
							description={background.description}
							container={background.container}
							settingKey={background.settingKey}
							selectedHealthSystemId={selectedHealthSystem.value}
							wallpaperBackgroundConfigs={wallpaperBackgroundConfigs}
							setWallpaperBackgroundConfigs={setWallpaperBackgroundConfigs}
						/>
					))}
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default CallSettingsAdmin;
