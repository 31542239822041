import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import Notes from 'containers/Notes.jsx';
import DiagnosesOrProcedures from 'containers/DiagnosesOrProcedures.jsx';
import Prescriptions from 'containers/Prescriptions.jsx';
import { HealthSystemType, MedicalInfoTypes, UserRoles } from 'constants/enums.js';
import { APP_CONFIG, healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import PrintVisitsReport from 'containers/PrintVisitsReport.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import PhysicalTherapyReports from 'containers/PhysicalTherapyReports.jsx';
import Visits from 'views/Partials/Visits.jsx';

const TabsContent = props => {
	const [currentTab, setCurrentTab] = useState(props.defaultTab || 0);
	const userRole = getUserRole(); 
	const [isPrimaryCareType, setIsPrimaryCareType] = useState(false); 
	const userSession = useSelector(state => state.user.userSession);

	useEffect(() => {
		setIsPrimaryCareType(userSession.healthSystem.workflowTypeId === HealthSystemType.PRIMARY_CARE);
	}, [userSession]);

	const tabs = {
		VISITS: 0,
		DIAGNOSES: isPrimaryCareType ? 1 : 0,
		PROCEDURES: isPrimaryCareType ? 2 : 1,
		NOTES: isPrimaryCareType ? 3 : 2,
		PRESCRIPTIONS: isPrimaryCareType ? 4 : 3,
		PRINT_REPORTS: isPrimaryCareType ? 5 : 4,
		PHYSICAL_EXERCISES: userRole === UserRoles.PATIENT ? 5 : 4,
	};

	return (
		<div className='patient-summary-tabs-wrapper'>
			<Tabs isVertical={true} activeIndex={currentTab} onChange={index => setCurrentTab(index)}>
				<TabList>
					{isPrimaryCareType && (
						<Tab>
							<img src={`${healthCareCdnUrl}footer-icons/visits${currentTab === tabs.VISITS ? '-active' : ''}.svg`} alt='icon' />
							{translate('visits')}
						</Tab>
					)}
					<Tab>
						<img
							src={`${healthCareCdnUrl}footer-icons/diagnosis${currentTab === tabs.DIAGNOSES ? '-active' : ''}.svg`}
							alt='icon'
						/>
						{translate('diagnoses')}
					</Tab>
					<Tab>
						<img
							src={`${healthCareCdnUrl}footer-icons/procedures${currentTab === tabs.PROCEDURES ? '-active' : ''}.svg`}
							alt='icon'
						/>
						{translate('procedures')}
					</Tab>
					<Tab>
						<img src={`${healthCareCdnUrl}footer-icons/notes${currentTab === tabs.NOTES ? '-active' : ''}.svg`} alt='icon' />
						{translate('notes')}
					</Tab>
					<Tab>
						<img
							src={`${healthCareCdnUrl}footer-icons/prescriptions${currentTab === tabs.PRESCRIPTIONS ? '-active' : ''}.svg`}
							alt='icon'
						/>
						{translate('prescriptions')}
					</Tab>

					{userRole === UserRoles.PATIENT && (
						<Tab>
							<img
								src={`${healthCareCdnUrl}footer-icons/generated-pdf${currentTab === tabs.PRINT_REPORTS ? '-blue' : '-gray'}.svg`}
								alt='icon'
							/>
							{translate('printReports')}
						</Tab>
					)}
					{APP_CONFIG.useFeaturePhysicalExercises === 'true' && !props.isCallView && (
						<Tab>
							<img
								src={`${healthCareCdnUrl}physical-exercises/add-exercise${
									currentTab === tabs.PHYSICAL_EXERCISES ? '-active' : ''
								}.svg`}
								alt='icon'
							/>
							{translate('physicalTherapy')}
						</Tab>
					)}
				</TabList>
				<TabPanels>
					{isPrimaryCareType && (
						<TabPanel>
							<Visits selectedPatient={props.selectedPatient} />
						</TabPanel>
					)}
					<TabPanel>
						<DiagnosesOrProcedures type={MedicalInfoTypes.DIAGNOSES} patientId={props.selectedPatient?.id} />
					</TabPanel>
					<TabPanel>
						<DiagnosesOrProcedures type={MedicalInfoTypes.PROCEDURES} patientId={props.selectedPatient?.id} />
					</TabPanel>
					<TabPanel>
						<Notes patientId={props.selectedPatient?.id} />
					</TabPanel>
					<TabPanel>
						<Prescriptions patientId={props.selectedPatient?.id} />
					</TabPanel>
					{userRole === UserRoles.PATIENT && (
						<TabPanel>
							<PrintVisitsReport selectedPatient={props.selectedPatient} />
						</TabPanel>
					)}
					<TabPanel>
						<PhysicalTherapyReports
							patientId={props.selectedPatient?.userId}
							conversationId={props.selectedPatient?.lastActivity?.conversationId}
							patientFirstName={props.selectedPatient?.firstName}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</div>
	);
};

const PatientSummary = props => (
	<>
		{!props.selectedPatient?.id && !props.isCallView && (
			<MainLayout>
				<TabsContent selectedPatient={props.selectedPatient} defaultTab={props.defaultTab} />
			</MainLayout>
		)}
		{(props.selectedPatient?.id || props.isCallView) && (
			<TabsContent selectedPatient={props.selectedPatient} defaultTab={props.defaultTab} isCallView={props.isCallView} />
		)}
	</>
);

export default PatientSummary;
