import en from 'i18n-translations/messages/en-US.js';
import al from 'i18n-translations/messages/al-AL.js';
import ar from 'i18n-translations/messages/ar-AE.js';
import de from 'i18n-translations/messages/de-DE.js';
import es from 'i18n-translations/messages/es-ES.js';

export default {
	...en,
	...al,
	...ar,
	...de,
	...es,
};
