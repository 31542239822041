import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Privacy = props => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			color={LightTheme.colors.grayZero}
			{...props}>
			<path
				d='M4 5.57143L12 3L20 5.57139C20 5.57139 19.8367 12 17.8775 15.8571C15.9184 19.7143 12 21 12 21C12 21 8.08163 19.7143 6.12244 15.8571C4.16326 12 4 5.57143 4 5.57143Z'
				stroke='currentColor'
				strokeLinejoin='round'
			/>
			<path
				d='M13.0978 10.5222L12.7319 10.1814C12.5854 10.3387 12.5558 10.5721 12.6585 10.761L13.0978 10.5222ZM10.9022 10.5222L11.3415 10.761C11.4442 10.5721 11.4146 10.3387 11.2681 10.1814L10.9022 10.5222ZM10.8106 10.7575L10.3256 10.6362L10.8106 10.7575ZM10.3106 12.7575L10.7957 12.8787H10.7957L10.3106 12.7575ZM13.6894 12.7575L14.1744 12.6362L13.6894 12.7575ZM13.1894 10.7575L12.7043 10.8787L13.1894 10.7575ZM13 9.5C13 9.76358 12.8987 10.0024 12.7319 10.1814L13.4636 10.863C13.796 10.5062 14 10.0264 14 9.5H13ZM12 8.5C12.5523 8.5 13 8.94772 13 9.5H14C14 8.39543 13.1046 7.5 12 7.5V8.5ZM11 9.5C11 8.94772 11.4477 8.5 12 8.5V7.5C10.8954 7.5 10 8.39543 10 9.5H11ZM11.2681 10.1814C11.1013 10.0024 11 9.76358 11 9.5H10C10 10.0264 10.204 10.5062 10.5364 10.863L11.2681 10.1814ZM11.2957 10.8787C11.3062 10.8366 11.3218 10.7973 11.3415 10.761L10.4629 10.2834C10.4033 10.393 10.3568 10.5113 10.3256 10.6362L11.2957 10.8787ZM10.7957 12.8787L11.2957 10.8787L10.3256 10.6362L9.82556 12.6362L10.7957 12.8787ZM11.2808 13.5C10.9555 13.5 10.7168 13.1943 10.7957 12.8787L9.82556 12.6362C9.58888 13.5829 10.3049 14.5 11.2808 14.5V13.5ZM12.7192 13.5H11.2808V14.5H12.7192V13.5ZM13.2043 12.8787C13.2832 13.1943 13.0445 13.5 12.7192 13.5V14.5C13.6951 14.5 14.4111 13.5829 14.1744 12.6362L13.2043 12.8787ZM12.7043 10.8787L13.2043 12.8787L14.1744 12.6362L13.6744 10.6362L12.7043 10.8787ZM12.6585 10.761C12.6782 10.7973 12.6938 10.8366 12.7043 10.8787L13.6744 10.6362C13.6432 10.5113 13.5967 10.393 13.5371 10.2834L12.6585 10.761Z'
				fill='currentColor'
			/>
		</svg>
	);
};

export default Privacy;
