import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Dropdown from 'components/Dropdown.jsx';
import 'css/LandingPage/navigation-menu.scss';

const NavigationMenu = props => {
	const [selectedMenuItem, setSelectedMenuItem] = useState(null);

	useEffect(() => {
		setSelectedMenuItem(null);
	}, [props.isNavBarVisible]);

	const navigationItems = [
		{
			id: 0,
			title: 'Solutions',
			isDropdown: true,
			subOptions: [
				{ id: 0, title: 'Telehealth', subTitle: 'End-to-End telehealth platform', image: 'telehealth.svg', href: 'telehealth' },
				{
					id: 1,
					title: 'Virtual Patient Observation',
					subTitle: 'eSitter, Virtual Care Delivery',
					image: 'virtual-patient-observation.svg',
					href: 'virtual-patient-observation',
				},
				{
					id: 2,
					title: 'Telemedicine Carts',
					subTitle: 'Mobile telehealth solution in any room, anytime.',
					image: 'telemedicine-cart.svg',
					href: 'telemedicine-carts',
				},
				{
					id: 3,
					title: 'Remote Patient Monitoring',
					subTitle: 'Vital Signs devices, RPM',
					image: 'remote-patient-monitoring.svg',
					href: 'remote-patient-monitoring',
				},
				{
					id: 4,
					title: 'Artificial Intelligence (AI)',
					subTitle: 'Patient Privacy, Fall Prevention and Detection',
					image: 'ai.svg',
					href: 'ai',
				},
				{
					id: 5,
					title: 'Digital Clinic',
					subTitle: 'Turnkey digital clinic solution',
					image: 'digital-clinic.svg',
					href: 'digital-clinic',
				},

				{
					id: 6,
					title: 'Remote Examination Kit',
					subTitle: 'All-in-one remote examination solution',
					image: 'remote-examination-kit.svg',
					href: 'remote-examination-kit',
				},
				{
					id: 7,
					title: 'Alerting, Communications and Workflow Management',
					subTitle: 'Walkie Talkie, Messaging, Video calling, Frontline Communications',
					image: 'alerting-and-workflow-management.svg',
					href: 'alerting',
				},
			],
		},
		{
			id: 1,
			title: 'For Hospitals',
			isDropdown: true,
			subOptions: [
				{ id: 0, title: 'Telehealth', subTitle: 'End-to-End telehealth platform', image: 'telehealth.svg', href: 'telehealth' },
				{
					id: 1,
					title: 'Virtual Patient Observation',
					subTitle: 'eSitter, Virtual Care Delivery',
					image: 'virtual-patient-observation.svg',
					href: 'virtual-patient-observation',
				},
				{
					id: 2,
					title: 'Telemedicine Carts',
					subTitle: 'Mobile telehealth solution in any room, anytime.',
					image: 'telemedicine-cart.svg',
					href: 'telemedicine-carts',
				},
				{
					id: 3,
					title: 'Remote Patient Monitoring',
					subTitle: 'Vital Signs devices, RPM',
					image: 'remote-patient-monitoring.svg',
					href: 'remote-patient-monitoring',
				},
				{
					id: 4,
					title: 'Artificial Intelligence (AI)',
					subTitle: 'Patient Privacy, Fall Prevention and Detection',
					image: 'ai.svg',
					href: 'ai',
				},
				{
					id: 5,
					title: 'Alerting, Communications and Workflow Management',
					subTitle: 'Walkie Talkie, Messaging, Video calling, Frontline Communications',
					image: 'alerting-and-workflow-management.svg',
					href: 'alerting',
				},
			],
		},
		{
			id: 2,
			title: 'For Home Care',
			isDropdown: true,
			subOptions: [
				{ id: 0, title: 'Telehealth', subTitle: 'End-to-End telehealth platform', image: 'telehealth.svg', href: 'telehealth' },
				{
					id: 1,
					title: 'Remote Patient Monitoring',
					subTitle: 'Vital Signs devices, RPM',
					image: 'remote-patient-monitoring.svg',
					href: 'remote-patient-monitoring',
				},
				{
					id: 2,
					title: 'Digital Clinic',
					subTitle: 'Turnkey digital clinic solution',
					image: 'digital-clinic.svg',
					href: 'digital-clinic',
				},
			],
		},
		{
			id: 3,
			title: 'For Primary Care',
			isDropdown: true,
			subOptions: [
				{ id: 0, title: 'Telehealth', subTitle: 'End-to-End telehealth platform', image: 'telehealth.svg', href: 'telehealth' },
				{
					id: 1,
					title: 'Remote Patient Monitoring',
					subTitle: 'Vital Signs devices, RPM',
					image: 'remote-patient-monitoring.svg',
					href: 'remote-patient-monitoring',
				},
			],
		},
		{
			id: 4,
			title: 'Devices',
			isDropdown: true,
			htmlId: 'devices-menu',
			subOptions: [
				{ id: 0, title: 'Hello 2', subTitle: 'All-in-one AI based monitoring 4K camera', image: 'hello-2.svg', href: 'hello-2' },

				{
					id: 1,
					title: 'Remote Examination Kit',
					subTitle: 'All-in-one remote examination solution',
					image: 'remote-examination-kit.svg',
					href: 'remote-examination-kit',
				},
				{
					id: 2,
					title: 'Telemedicine Carts',
					subTitle: 'Mobile telehealth solution in any room, anytime.',
					image: 'telemedicine-cart.svg',
					href: 'telemedicine-carts',
				},
				{
					id: 3,
					title: 'Remote Patient Monitoring',
					subTitle: 'Vital Signs devices, RPM',
					image: 'remote-patient-monitoring.svg',
					href: 'remote-patient-monitoring',
				},
				{
					id: 4,
					title: 'Digital Clinic',
					subTitle: 'Turnkey digital clinic solution',
					image: 'digital-clinic.svg',
					href: 'digital-clinic',
				},
				{
					id: 5,
					title: 'Peripherals',
					subTitle: 'Continuous and ad-hoc vital sign measuring devices',
					image: 'peripherals.svg',
					href: 'peripherals',
				},
			],
		},
		{
			id: 5,
			title: 'Artificial Intelligence (AI)',
			htmlId: 'ai-menu',
			isDropdown: true,
			subOptions: [
				{ id: 0, title: 'Privacy', subTitle: 'AI based patient privacy', image: 'ehr-integrations.svg', href: 'ai' },

				{
					id: 1,
					title: 'Fall Prevention and Detection',
					subTitle: 'Fall prevention and detection, patient activity',
					image: 'fall-detection.svg',
					href: 'ai',
				},
			],
		},
		{
			id: 6,
			title: 'Platform',
			isDropdown: true,
			htmlId: 'platform-menu',
			subOptions: [
				{ id: 0, title: 'EHR Integrations', subTitle: 'Epic, Cerner, Allscripts', image: 'ehr-integrations.svg', href: 'ehr' },

				{
					id: 1,
					title: 'Certifications and Compliance',
					subTitle: 'HITRUST, HIPAA, ISO 27001, SOC, FDA',
					image: 'certifications.svg',
					href: 'certifications',
				},
			],
		},
		{
			id: 7,
			title: 'Video Conferencing',
			isDropdown: true,
			subOptions: [
				{
					id: 0,
					title: 'All-in-one Video Conferencing Devices',
					subTitle: 'Microsoft Teams Rooms, Video Conference Devices, UC',
					image: 'hello-2.svg',
					target: '_blank',
					href: 'https://www.eposaudio.com/en/al/enterprise/video-conferencing',
				},
			],
		},
		{
			id: 8,
			title: 'About Us',
			isDropdown: false,
			href: '#about-us',
			target: '_self',
		},
	];
	const aiMenu = navigationItems.find(elem => elem.htmlId === 'ai-menu');
	const devicesMenu = navigationItems.find(elem => elem.htmlId === 'devices-menu');
	return (
		<div
			className={`navigation-menu lp-container${props.isNavBarVisible ? ' active' : ''}${
				selectedMenuItem ? ' menu-item-active' : ''
			}${props.isSubPage ? ' sub-page-menu' : ''}`}>
			<div>
				<div className='logo-wrapper'>
					<Link to='/'>
						<img
							src={`${healthCareCdnUrl}landing-page/logos/${props.isSubPage ? 'logo_white' : 'hellocare_bysolab'}.svg`}
							alt='hellocare'
						/>
					</Link>
				</div>

				<nav>
					<div className='login-menu-el'>
						<Link className='' to='/login'>
							Sign In
						</Link>
					</div>
					{!props.isSubPage &&
						navigationItems.map(item => (
							<React.Fragment key={item.id}>
								{item.isDropdown && (
									<Dropdown
										position='bottom'
										title={item.title}
										imgIcon={`${healthCareCdnUrl}landing-page/navigation-menu-click-indicator.svg`}
										onWrapperClick={() => setSelectedMenuItem(item)}
										id={item.htmlId ? item.htmlId : ''}>
										<h3>{item.title}</h3>
										<div className='lp-inner-menu-wrapper'>
											{item.subOptions.map(elem => (
												<a
													className='lp-dropdown-item'
													href={elem.href ? `${elem.target ? '' : '#'}${elem.href}` : null}
													target={elem.target || null}
													key={elem.id}>
													<div>
														<img src={`${healthCareCdnUrl}landing-page/menu/${elem.image}`} alt='icon' />
													</div>
													<div>
														<p>{elem.title}</p>
														<p>{elem.subTitle}</p>
													</div>
												</a>
											))}
										</div>
										{aiMenu && item.htmlId === 'platform-menu' && (
											<>
												<h3 className='lp-additional-item'>AI (Artificial Intelligence)</h3>
												<div className='lp-inner-menu-wrapper lp-additional-item'>
													{aiMenu.subOptions.map(elem => (
														<a
															className='lp-dropdown-item'
															href={elem.href ? `${elem.target ? '' : '#'}${elem.href}` : null}
															target={elem.target || null}
															key={elem.id}>
															<div>
																<img src={`${healthCareCdnUrl}landing-page/menu/${elem.image}`} alt='icon' />
															</div>
															<div>
																<p>{elem.title}</p>
																<p>{elem.subTitle}</p>
															</div>
														</a>
													))}
												</div>
											</>
										)}
										{devicesMenu && item.htmlId === 'platform-menu' && (
											<>
												<h3 className='lp-additional-item'>Devices</h3>
												<div className='lp-inner-menu-wrapper lp-additional-item'>
													{devicesMenu.subOptions.map(elem => (
														<a
															className='lp-dropdown-item'
															href={elem.href ? `${elem.target ? '' : '#'}${elem.href}` : null}
															target={elem.target || null}
															key={elem.id}>
															<div>
																<img src={`${healthCareCdnUrl}landing-page/menu/${elem.image}`} alt='icon' />
															</div>
															<div>
																<p>{elem.title}</p>
																<p>{elem.subTitle}</p>
															</div>
														</a>
													))}
												</div>
											</>
										)}
									</Dropdown>
								)}
								{!item.isDropdown && (
									<a href={item.href || null} target={item.target} onClick={() => props.setNavBarVisible(false)}>
										{item.title}
									</a>
								)}
							</React.Fragment>
						))}
				</nav>

				<div className='navigation-buttons-wrapper'>
					{props.isSubPage && (
						<>
							<Link to='/hello' className='sub-menu-nav'>
								Overview
							</Link>
							<Link to='/hello/specs' className='sub-menu-nav'>
								Tech Specs
							</Link>
						</>
					)}
					<Link to='/login'>
						<img src={`${healthCareCdnUrl}landing-page/signin${props.isSubPage ? '-white' : ''}.svg`} alt='icon' />
						Sign In
					</Link>
				</div>
				<i
					onClick={() => props.setNavBarVisible(prevIsNavBarVisible => !prevIsNavBarVisible)}
					className='material-icons navbar-toggle'>
					{props.isNavBarVisible ? 'close' : 'menu'}
				</i>
			</div>
			{selectedMenuItem && (
				<div className={`navigation-mobile-menu${selectedMenuItem ? ' active' : ''}`}>
					<h3>
						<i className='material-icons' onClick={() => setSelectedMenuItem(null)}>
							keyboard_backspace
						</i>
						{selectedMenuItem.title}
					</h3>

					{selectedMenuItem.subOptions.map(elem => (
						<a
							className='lp-dropdown-item'
							href={elem.href ? `${elem.target ? '' : '#'}${elem.href}` : null}
							target={elem.target || null}
							onClick={elem.href ? () => props.setNavBarVisible(false) : null}
							key={elem.id}>
							<div>
								<img src={`${healthCareCdnUrl}landing-page/menu/${elem.image}`} alt='icon' />
							</div>
							<div>
								<p>{elem.title}</p>
								<p>{elem.subTitle}</p>
							</div>
						</a>
					))}
				</div>
			)}
		</div>
	);
};

export default NavigationMenu;
