import * as React from 'react';
const EwsSettings = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} viewBox='0 0 22 24' fill='none' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='m9.78 17.877.185.123c.621.408 1.298.817 2.035 1.223 15.417-8.504 4.625-17.789 0-11.951-3.274-4.133-9.639-.687-7.608 4.695'
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M3 14.857h1.385L6.462 17l2.076-5 2.077 2.857H12'
		/>
	</svg>
);
export default EwsSettings;
