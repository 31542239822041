import { gatewayApi } from 'constants/global-variables.js';
import { VisitTypes } from 'constants/visitEnums.js';

export const getVisitsByDevice = async (deviceId, visitedAtDay, pageIndex = 0, pageSize = 10) => {
	try {
		const url = `/v1/healthcare/visits`;
		const { data } = await gatewayApi.get(url, {
			params: {
				deviceId: deviceId,
				pageIndex: pageIndex,
				pageSize: pageSize,
				visitedAtDay: visitedAtDay,
			},
		});
		return data.visitsByDate;
	} catch (error) {
		return { error };
	}
};

export const createMedicalCase = async (patientId, params) => {
	try {
		const { data } = await gatewayApi.post(`/v1/healthcare/patients/${patientId}/medical-cases`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientMedicalCases = async patientId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/patients/${patientId}/medical-cases`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getMedicalCaseVisits = async caseId => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/medical-cases/${caseId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateMedicalCaseStatus = async (caseId, caseStatusId) => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/medical-cases/${caseId}/status`, {
			caseStatusId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateMedicalVisitStatus = async (visitId, params) => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/medical-visits/${visitId}/status`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDiagnosticResearches = async () => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/medical-visits/diagnostic-researches?visitType=${VisitTypes.LAB}&hasAttachments=false`
		);
		return data.patientsDiagnosticResearches;
	} catch (error) {
		return { error };
	}
};

export const addDiagnosticResearchAttachments = async (visitId, params) => {
	try {
		const { data } = await gatewayApi.post(`/v1/healthcare/medical-visits/${visitId}/diagnostic-researches/attachments`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const createMedicalVisit = async ({ patientId, caseId, referredVisitId, params }) => {
	try {
		const { data } = await gatewayApi.post(
			`/v1/healthcare/patients/${patientId}/medical-cases/${caseId}/medical-visits?referredVisitId=${referredVisitId}`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getMedicalReport = async visitId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/medical-visits/${visitId}/summary`);
		return data;
	} catch (error) {
		return { error };
	}
};
