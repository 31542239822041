import { TreeHierarchyType } from 'constants/enums.js';

export const getHospitalBreadCrumb = hospital => [
	{
		name: hospital.name,
		link: hospital.id,
	},
];

export const getDepartmentBreadCrumb = (hospital, department) => {
	const breadcrumb = getHospitalBreadCrumb(hospital);
	breadcrumb.push({
		name: department.name,
		link: `${hospital.id}/department/${department.id}`,
	});
	return breadcrumb;
};

export const getFloorBreadCrumb = (hospital, department, floor) => {
	const breadcrumb = getDepartmentBreadCrumb(hospital, department);
	breadcrumb.push({
		name: floor.name,
		link: `${hospital.id}/department/${department.id}/floor/${floor.id}`,
	});
	return breadcrumb;
};

export const getRoomBreadCrumb = (hospital, department, floor, room) => {
	const breadcrumb = getFloorBreadCrumb(hospital, department, floor);
	breadcrumb.push({
		name: room.name,
		link: `${hospital.id}/department/${department.id}/floor/${floor.id}/room/${room.id}`,
	});
	return breadcrumb;
};

export const getDepartmentRoomsBreadCrumb = (hospital, department, floor, room) => {
	const breadcrumb = getDepartmentBreadCrumb(hospital, department);
	breadcrumb.push({
		name: room.name,
		link: `${hospital.id}/department/${department.id}/floor/${floor.id}/room/${room.id}`,
	});
	return breadcrumb;
};

export const getHospitalFloorsBreadCrumb = (hospital, department, floor) => {
	const breadcrumb = getHospitalBreadCrumb(hospital);
	breadcrumb.push({
		name: floor.name,
		link: `${hospital.id}/department/${department.id}/floor/${floor.id}`,
	});
	return breadcrumb;
};

export const getHospitalRoomsBreadCrumb = (hospital, floor, department, room) => {
	const breadcrumb = getHospitalBreadCrumb(hospital);
	breadcrumb.push({
		name: room.name,
		link: `${hospital.id}/department/${department.id}/floor/${floor.id}/room/${room.id}`,
	});
	return breadcrumb;
};

export const getFloorRoomsBreadCrumb = (hospital, department, floor, room) => {
	const breadcrumb = getHospitalFloorsBreadCrumb(hospital, department, floor);
	breadcrumb.push({
		name: room.name,
		link: `${hospital.id}/department/${department.id}/floor/${floor.id}/room/${room.id}`,
	});
	return breadcrumb;
};

export const getBreadCrumbBasedHierarchy = (hierarchyTypeId, hospital, department, floor, room) => {
	switch (hierarchyTypeId) {
		case TreeHierarchyType.DEFAULT_TREE:
		case TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM:
			return getRoomBreadCrumb(hospital, department, floor, room);
		case TreeHierarchyType.HOSPITAL_DEPT_ROOM:
			return getDepartmentRoomsBreadCrumb(hospital, department, floor, room);
		case TreeHierarchyType.HOSPITAL_FLOOR_ROOM:
			return getFloorRoomsBreadCrumb(hospital, department, floor, room);
		case TreeHierarchyType.HOSPITAL_ROOM:
			return getHospitalRoomsBreadCrumb(hospital, department, floor, room);
		default:
			return [];
	}
};
