import { useEffect, useState } from 'react';
import { participant as Participant, conference as Conference } from '@solaborate/calls';
import useConference from 'calls/hooks/useConference.js';
import useRemoteParticipant from 'calls/hooks/useRemoteParticipant.js';

const useRemoteParticipantState = () => {
	const conference = useConference();
	const participant = useRemoteParticipant();
	const [participantState, setParticipantState] = useState(participant.state);

	useEffect(() => {
		return participant.on(event => {
			if (event instanceof Participant.StateChanged) {
				setParticipantState(event);
			}
		});
	}, [participant]);

	useEffect(() => {
		return conference.on(event => {
			if (event instanceof Conference.ParticipantAdded && event.participant.id === participant.id) {
				setParticipantState(event.participant.state);
			}
		});
	}, [conference]);

	return participantState;
};

export default useRemoteParticipantState;
