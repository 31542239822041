const Watch = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 24 24' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='1.5'
			d='M7.757 7.757a6 6 0 118.486 8.486 6 6 0 01-8.486-8.486z'
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='1.5'
			d='M9.525 9.525a3.5 3.5 0 114.95 4.95 3.5 3.5 0 01-4.95-4.95z'
		/>
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			strokeWidth='1.5'
			d='M5.99 12.354S5.145 9.847 3.47 7.8c-.35-.428-.354-1.06.037-1.45l2.836-2.836c.39-.39 1.022-.387 1.45-.038 2.048 1.675 4.56 2.513 4.56 2.513M18.01 11.646s.844 2.507 2.52 4.554c.35.427.354 1.059-.037 1.45l-2.836 2.835c-.39.39-1.022.388-1.45.038-2.048-1.675-4.56-2.512-4.56-2.512'
		/>
	</svg>
);

export default Watch;
