import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Alert from 'components/Alert.jsx';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import EditPatientProfile from 'containers/CheckInAPatient/EditPatientProfile.jsx';
import { updatePatientDeviceOwner } from 'api/devices.js';
import { capitalizeFirstLetter, findSectorById, getStorage } from 'infrastructure/helpers/commonHelpers.js';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import { AlertTypes, MeasurementUnitOptions, UnitCategoryTypes, UnitSystemTypes, UserRoles } from 'constants/enums.js';
import {
	convertBloodGlucose,
	convertHeight,
	convertWaistCircumference,
	convertWeight,
} from 'infrastructure/helpers/measurementsHelper.js';
import { tobaccoSmokeCodes, diabeticStatusCodes } from 'constants/health-codes.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { getUserRole } from 'infrastructure/auth.js';

const ViewPatientProfile = props => {
	const intl = useIntl();
	const genders = [
		{ id: 1, name: intl.formatMessage({ id: 'male' }) },
		{ id: 2, name: intl.formatMessage({ id: 'female' }) },
	];
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [error, setError] = useState(null);
	const genderWrapperRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [currentTab, setCurrentTab] = useState(null);
	const helloDeviceId = parseInt(getStorage().getItem('helloDeviceId'), 10);
	const tree = useSelector(state => state.healthSystems.treeData.tree);
	const [alreadyOnCallMessage, setAlreadyOnCallMessage] = useState(null);

	const onSubmitForm = async () => {
		props.setIsSecondTabEnabled(false);
		setError('');
		setIsLoading(true);
		const deviceId = getStorage().getItem('helloDeviceId');
		const params = {
			userId: props.userId,
			deviceName: '',
			deviceId: parseInt(deviceId, 10),
		};
		const room = findSectorById(tree, helloDeviceId);
		if (room?.deviceBusy) {
			setAlreadyOnCallMessage(intl.formatMessage({ id: 'youAreAlreadyOnCall' }));
			return;
		}
		const response = await updatePatientDeviceOwner(params);
		if (response.error || !response.hasSucceeded) {
			const errorMessage = response.error?.message || response?.message;
			setError(errorMessage);
			return;
		}
		props.setSelectedPatient(props.patientDetails);
		props.onModalSubmit(false);
		props.setIsSecondTabEnabled(true);
		setIsLoading(false);
	};

	const setEditProfileStatus = status => {
		setIsEditModalOpen(status);
		props.setIsPatientFormVisible(status);
	};

	const healthInformationStatusList = [
		{
			code: 0,
			text: 'N/A',
			status: null,
		},
		{
			code: 1,
			text: translate('yes'),
			status: true,
		},
		{
			code: 2,
			text: translate('no'),
			status: false,
		},
	];

	const getMeasurementValue = (type, value, convertFunction, decimal) =>
		props.unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes[type])?.unitSystemId !== UnitSystemTypes.METRIC
			? convertFunction
			: parseFloat(value).toFixed(decimal);

	return (
		<>
			{!isEditModalOpen && props.patientDetails && (
				<Modal
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal add-patient-modal view-profile-modal'
					position='center'
					shouldSubmitOnEnter={false}
					display={props.display}
					onModalClose={props.onModalClose}
					onModalSubmit={() => setEditProfileStatus(true)}
					isThirdButtonShown={true}
					thirdImgIcon={`${healthCareCdnUrl}appointments/done.svg`}
					primaryButtonLabel={intl.formatMessage({ id: 'editProfile' })}
					thirdButtonLabel={intl.formatMessage({ id: 'selectThePatient' })}
					isThirdButtonLoading={isLoading}
					background='#E0AF4C'
					thirdBtnBackground='#33C27F'
					onThirdButtonClick={onSubmitForm}
					isViewOnly={props.isViewOnly}>
					<Form className='view-profile-form-wrapper'>
						<h3>{translate('viewProfile')}</h3>
						<Tabs activeIndex={currentTab} onChange={index => setCurrentTab(index)}>
							<TabList className='equal-children full-width'>
								<Tab>{translate('personalInformation')}</Tab>
								<Tab>{translate('otherInformation')}</Tab>
							</TabList>
							<TabPanels>
								<TabPanel>
									<div className='flex read-only-profile-wrapper view-profile-modal-wrapper flex-wrap top-15 patient-input-wrapper'>
										{[UserRoles.DOCTOR, UserRoles.NURSE].includes(getUserRole()) && (
											<div>
												<div>
													<p>MRN</p>
													<input type='text' value={props.patientDetails.mrn} readOnly />
												</div>
											</div>
										)}
										<div>
											<p>{translate('firstName')}</p>
											<input type='text' value={capitalizeFirstLetter(props.patientDetails.firstName)} readOnly />
										</div>
										<div>
											<p>{translate('lastName')}</p>
											<input type='text' value={capitalizeFirstLetter(props.patientDetails.lastName)} readOnly />
										</div>
										<div className='patient-birth-date'>
											<p>{translate('dateOfBirth')}</p>
											<input
												type='text'
												value={props.patientDetails.birthDate && moment(props.patientDetails.birthDate).format('DD/MM/YYYY')}
												readOnly
											/>
										</div>
										<div className='position-relative' ref={genderWrapperRef}>
											<p>{translate('sex')}</p>
											<div className='flex no-margin'>
												<input
													type='select'
													value={genders.find(item => props.patientDetails?.genderId === item.id)?.name}
													disabled
													className='cursor-pointer'
												/>
												<img src={`${healthCareCdnUrl}teams/arrow.svg`} className='arrow-icon gender' alt='ico' />
											</div>
										</div>
										<div>
											<p>{translate('idNumber')}</p>
											<input type='number' value={props.patientDetails.idCard} readOnly />
										</div>
										<div>
											<p>{translate('emailAddress')}</p>
											<input
												type='email'
												value={
													props.patientDetails.email && props.patientDetails.email.includes('@hello-health.com')
														? ''
														: props.patientDetails.email
												}
												readOnly
											/>
										</div>
										<div>
											<p>{translate('country')}</p>
											<input type='text' value={props.selectedCountry} readOnly />
										</div>
										<div>
											<p>{translate('city')}</p>
											<input type='text' value={props.patientDetails?.city} readOnly />
										</div>
										<div>
											<p>{translate('zipCode')}</p>
											<input type='text' value={props.patientDetails?.postalCode} readOnly />
										</div>
										<div>
											<p>{translate('address')}</p>
											<input type='text' value={props.patientDetails?.address} readOnly />
										</div>
										<div>
											<p>{translate('phoneNumber')}</p>
											<input type='text' value={props.patientDetails.phoneNumber} readOnly />
										</div>
									</div>
								</TabPanel>
								<TabPanel>
									<div className='flex read-only-profile-wrapper view-profile-modal-wrapper flex-wrap top-15 patient-input-wrapper'>
										<div className='flex row-direction full-width'>
											<div>
												<p>{translate('height')}</p>
												<input
													type='number'
													value={
														props.patientDetails.height
															? getMeasurementValue(
																	'HEIGHT',
																	props.patientDetails.height,
																	convertHeight(parseFloat(props.patientDetails.height), UnitSystemTypes.IMPERIAL)
																		.toFixed()
																		.toString(),
																	0
															  )
															: ''
													}
													onChange={props.onChange}
													name='height'
													readOnly
												/>
											</div>
											<div>
												<p>{translate('unit')}</p>
												<input
													type='text'
													value={
														MeasurementUnitOptions.HEIGHT.value[
															props.unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes.HEIGHT)?.unitSystemId
														]?.text
													}
													onChange={props.onChange}
													name='unit'
													readOnly
												/>
											</div>
										</div>
										<div className='flex row-direction full-width'>
											<div>
												<p>{translate('weight')}</p>
												<input
													type='number'
													value={
														props.patientDetails.weight
															? getMeasurementValue(
																	'WEIGHT',
																	props.patientDetails.weight,
																	convertWeight(parseFloat(props.patientDetails.weight), UnitSystemTypes.IMPERIAL)
																		.toFixed(2)
																		.toString(),
																	2
															  )
															: ''
													}
													onChange={props.onChange}
													name='weight'
													readOnly
												/>
											</div>
											<div>
												<p>{translate('unit')}</p>
												<input
													type='text'
													value={
														MeasurementUnitOptions.WEIGHT.value[
															props.unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes.WEIGHT)?.unitSystemId
														]?.text
													}
													onChange={props.onChange}
													name='unit'
													readOnly
												/>
											</div>
										</div>
										<div className='flex row-direction full-width'>
											<div>
												<p>{translate('waistCircumference')}</p>
												<input
													type='number'
													value={
														props.patientDetails.waistCircumference
															? getMeasurementValue(
																	'HEIGHT',
																	props.patientDetails.waistCircumference,
																	convertWaistCircumference(
																		parseFloat(props.patientDetails.waistCircumference),
																		UnitSystemTypes.IMPERIAL
																	)
																		.toFixed(2)
																		.toString(),
																	2
															  )
															: ''
													}
													name='waistCircumference'
													readOnly
												/>
											</div>
											<div>
												<p>{translate('unit')}</p>
												<input
													type='text'
													value={
														MeasurementUnitOptions.WAIST_CIRCUMFERENCE.value[
															props.unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes.HEIGHT)?.unitSystemId
														]?.text
													}
													onChange={props.onChange}
													name='unit'
													readOnly
												/>
											</div>
										</div>
										<div className='row-direction full-width'>
											<div>
												<p>{translate('totalCholesterol')}</p>
												<input
													type='number'
													value={
														props.patientDetails.totalCholesterol
															? getMeasurementValue(
																	'BLOOD_GLUCOSE',
																	props.patientDetails.totalCholesterol,
																	convertBloodGlucose(parseFloat(props.patientDetails.totalCholesterol), UnitSystemTypes.IMPERIAL)
																		.toFixed(2)
																		.toString(),
																	2
															  )
															: ''
													}
													name='totalCholesterol'
													readOnly
												/>
											</div>
											<div>
												<p>{translate('unit')}</p>
												<input
													type='text'
													value={
														MeasurementUnitOptions.BLOOD_GLUCOSE.value[
															props.unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes.BLOOD_GLUCOSE)
																?.unitSystemId
														]?.text
													}
													onChange={props.onChange}
													name='unit'
													readOnly
												/>
											</div>
										</div>
										<div className='row-direction full-width'>
											<div>
												<p>{translate('hdlCholesterol')}</p>
												<input
													type='number'
													value={
														props.patientDetails.hdlCholesterol
															? getMeasurementValue(
																	'BLOOD_GLUCOSE',
																	props.patientDetails.hdlCholesterol,
																	convertBloodGlucose(parseFloat(props.patientDetails.hdlCholesterol), UnitSystemTypes.IMPERIAL)
																		.toFixed(2)
																		.toString(),
																	2
															  )
															: ''
													}
													name='hdlCholesterol'
													readOnly
												/>
											</div>
											<div>
												<p>{translate('unit')}</p>
												<input
													type='text'
													value={
														MeasurementUnitOptions.BLOOD_GLUCOSE.value[
															props.unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes.BLOOD_GLUCOSE)
																?.unitSystemId
														]?.text
													}
													onChange={props.onChange}
													name='unit'
													readOnly
												/>
											</div>
										</div>
										<div className='row-direction full-width'>
											<label className='flex-1'>{translate('sufferFromHypertension')}</label>
											<label>
												{!props.patientDetails.hasHyperTension && props.patientDetails.hasHyperTension !== false && 'N/A'}
												{(props.patientDetails.hasHyperTension || props.patientDetails.hasHyperTension === false) &&
													healthInformationStatusList.find(item => item.status === props.patientDetails.hasHyperTension)?.text}
											</label>
										</div>
										<div className='row-direction full-width'>
											<label className='flex-1'>{translate('medicalStatus')}</label>
											<label>
												{!props.patientDetails.isTakingMedication && props.patientDetails.isTakingMedication !== false && 'N/A'}
												{(props.patientDetails.isTakingMedication || props.patientDetails.isTakingMedication === false) &&
													healthInformationStatusList.find(item => item.status === props.patientDetails.isTakingMedication)?.text}
											</label>
										</div>
										<div className='row-direction full-width'>
											<label className='flex-1'>{translate('areYouSmoker')}</label>
											<label>
												{!props.patientDetails.isTobaccoSmoker && props.patientDetails.isTobaccoSmoker !== false && 'N/A'}
												{(props.patientDetails.isTobaccoSmoker || props.patientDetails.isTobaccoSmoker === false) &&
													healthInformationStatusList.find(item => item.status === props.patientDetails.isTobaccoSmoker)?.text}
												{props.tobaccoCode &&
													` | ${intl.formatMessage({
														id: tobaccoSmokeCodes.find(item => item.code === props.tobaccoCode)?.content,
													})}`}
											</label>
										</div>
										<div className='row-direction full-width'>
											<label className='flex-1'>{translate('haveDiabeticStatus')}</label>
											<label>
												{!props.patientDetails.hasDiabet && props.patientDetails.hasDiabet !== false && 'N/A'}
												{(props.patientDetails.hasDiabet || props.patientDetails.hasDiabet === false) &&
													healthInformationStatusList.find(item => item.status === props.patientDetails.hasDiabet)?.text}
												{props.diabeticCode &&
													` | ${intl.formatMessage({
														id: diabeticStatusCodes.find(item => item.code === props.diabeticCode)?.content,
													})}`}
											</label>
										</div>
										<div className='row-direction full-width'>
											<label className='flex-1'>{translate('anyAllergies')}</label>
											<label>
												{!props.patientDetails.hasAllergy && props.patientDetails.hasAllergy !== false && 'N/A'}
												{(props.patientDetails.hasAllergy || props.patientDetails.hasAllergy === false) &&
													healthInformationStatusList.find(item => item.status === props.patientDetails.hasAllergy)?.text}
											</label>
										</div>
										<div className='row-direction full-width'>
											<label className='flex-1'>{translate('preExistingDescription')}</label>
											<label>
												{!props.patientDetails.hasPreExistingCondition &&
													props.patientDetails.hasPreExistingCondition !== false &&
													'N/A'}
												{(props.patientDetails.hasPreExistingCondition ||
													props.patientDetails.hasPreExistingCondition === false) &&
													healthInformationStatusList.find(item => item.status === props.patientDetails.hasPreExistingCondition)
														?.text}
											</label>
										</div>
									</div>
									{!props.isViewOnly && (
										<div className='flex position-relative consent-from-patient'>
											<label className='remember-me flex'>
												<input
													type='checkbox'
													value={props.patientDetails.showConsent}
													checked={props.patientDetails.showConsent}
													readOnly
												/>
												<div className='onb-custom-checkbox-wrapper add-patient-checkbox-wrapper cursor-pointer'>
													<div className='onb-custom-checkbox' />
												</div>
												<p className='consent-patient'>{translate('showConsent')}</p>
											</label>
										</div>
									)}
								</TabPanel>
							</TabPanels>
						</Tabs>
						<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
					</Form>
					<PopUpAlert
						alertType={AlertTypes.DANGER}
						display={alreadyOnCallMessage}
						onAlertClose={() => setAlreadyOnCallMessage(null)}
						contentText={alreadyOnCallMessage}
						isSilent={true}
						center={true}
					/>
				</Modal>
			)}
			{isEditModalOpen && (
				<EditPatientProfile
					display={true}
					onModalSubmit={props.onModalSubmit}
					onModalClose={() => setIsEditModalOpen(false)}
					setIsSecondTabEnabled={props.setIsSecondTabEnabled}
					patientDetails={props.patientDetails}
					setSelectedPatient={val => props.setSelectedPatient(val)}
					selectedPatientId={props.selectedPatientId}
					userId={props.userId}
					hidePatientForm={() => setEditProfileStatus(false)}
					healthInformationStatusList={healthInformationStatusList}
					unitPreferences={props.unitPreferences}
					countries={props.countries}
					tobaccoCode={props.tobaccoCode}
					diabeticCode={props.diabeticCode}
				/>
			)}
		</>
	);
};

export default ViewPatientProfile;
