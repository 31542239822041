import * as React from 'react';

const UnPin = props => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 24 24'
			strokeWidth={1.5}
			fill='none'
			{...props}>
			<path
				d='M9.22727 15.5L5 20M15.1818 14.1818L19.5455 9.81818M15.1818 5.45455L10.8182 9.81818C8.27273 8.72727 6.45455 9.81818 5 11.2727L10.2273 16.5M15.1818 5.45455L13.7273 4M15.1818 5.45455L19.5455 9.81818M21 11.2727L19.5455 9.81818'
				stroke='currentColor'
			/>

			<path d='M12 16L16 20' stroke='currentColor' />
			<path d='M12 20L16 16' stroke='currentColor' />
		</svg>
	);
};
export default UnPin;
