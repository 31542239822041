import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { Route, useRouteMatch } from 'react-router-dom';
import { SectorTypes, UserRoles, TreeHierarchyType } from 'constants/enums.js';
import Grid from 'components/Grid.jsx';
import TreeView from 'components/TreeView.jsx';
import Loader from 'components/Loader.jsx';
import Input from 'components/Input.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import RoundingRoom from 'views/Partials/RoundingRoom.jsx';
import Hospital from 'views/Partials/Hospital.jsx';
import Department from 'views/Partials/Department.jsx';
import Floor from 'views/Partials/Floor.jsx';
import Room from 'views/Partials/Room.jsx';
import translate from 'i18n-translations/translate.jsx';
import {
	searchSectors,
	showPath,
	buildTree,
	findSectorById,
	getHealthSystemDevices,
} from 'infrastructure/helpers/commonHelpers.js';
import { createFrontlineHospital, createHospital } from 'api/hospitals.js';
import { createDepartment } from 'api/departments.js';
import { createFloor, createFloorInHospital } from 'api/floors.js';
import { createRoom, createRoomInDepartment } from 'api/rooms.js';
import { getRegionSubTree } from 'api/tree.js';
import { useIntl } from 'react-intl';
import Alert from 'components/Alert.jsx';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import useScreenType from 'hooks/useScreenType.js';
import _ from 'lodash';
import ListView from 'icons/TreeView/ListView.jsx';
import TreeViewIcon from 'icons/TreeView/TreeViewIcon.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import TreeViewMayo from 'components/TreeViewMayo.jsx';
import classNames from 'classnames';

const HealthSystem = () => {
	const [treeItems, setTreeItems] = useState({});
	const [helloDeviceId, setHelloDeviceId] = useState(null);
	const [roomId, setRoomId] = useState(null);
	const [sectorSearchValue, setSectorSearchValue] = useState('');
	const [expandAllTreeItems, setExpandAllTreeItems] = useState(false);
	const healthSystems = useSelector(state => state.healthSystems);
	const userSession = useSelector(state => state.user.userSession);
	const darkMode = useSelector(state => state.user.darkMode);
	const [isLoadingView, setIsLoadingView] = useState(true);
	const [error, setError] = useState(null);
	const dispatch = useDispatch();
	const socket = useContext(SocketContext);
	const intl = useIntl();
	const match = useRouteMatch();
	const screenType = useScreenType();
	const treeHierarchyTypeId = userSession.healthSystem.treeHierarchyTypeId;
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	const [treeData, setTreeData] = useState([]);

	const isRoundingRoomActive =
		match?.params?.room && [UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(getUserRole());

	const getSelectedSectorId = useCallback(() => {
		const { hospital, department, floor, room } = match.params;
		return room || floor || department || hospital;
	}, [match.params]);

	useEffect(() => {
		const clonedTree = _.cloneDeep(healthSystems.treeData.tree);
		setTreeData(clonedTree);
	}, [healthSystems.treeData]);

	useEffect(() => {
		const { hospital, department, floor, room } = match.params;
		const getExpandedOptions = () => {
			let treeObj = {};
			if (hospital) {
				treeObj = { [hospital]: {} };
			}
			if (department) {
				treeObj = {
					[hospital]: { [department]: {} },
				};
			}
			if (floor) {
				treeObj = {
					[hospital]: {
						[department]: { [floor]: {} },
					},
				};
			}
			if (room) {
				treeObj = {
					[hospital]: {
						[department]: {
							[floor]: { [room]: {} },
						},
					},
				};
			}
			return treeObj;
		};

		const foundSector = findSectorById(treeData, getSelectedSectorId());
		setTreeItems(getExpandedOptions());
		if (foundSector && foundSector.type === SectorTypes.ROOM) {
			setHelloDeviceId(foundSector.helloDeviceId || null);
			setRoomId(foundSector.roomId);
		}
		const breadCrumb = foundSector ? foundSector.breadcrumb : [];
		dispatch(healthSystemsActionCreators.updateBreadcrumb(breadCrumb));

		return () => {
			healthSystemsActionCreators.updateBreadcrumb([]);
		};
	}, [dispatch, getSelectedSectorId, healthSystems.treeData, match.params, treeData]);

	useEffect(() => {
		setIsLoadingView(true);
		if (healthSystems.selectedHealthSystem) {
			const builtTreeData = buildTree(healthSystems.selectedHealthSystem, healthSystems.isRoomsOnlyView);
			dispatch(healthSystemsActionCreators.setTreeData(builtTreeData));
		}
		setIsLoadingView(false);
	}, [dispatch, healthSystems.isRoomsOnlyView, healthSystems.selectedHealthSystem]);

	useEffect(() => {
		const clonedTree = [...treeData];
		const sectors = searchSectors(clonedTree, sectorSearchValue.trim());
		setExpandAllTreeItems(!!sectorSearchValue);
		if (sectorSearchValue) {
			showPath(clonedTree, sectors);
		}
		setTreeData(clonedTree);
	}, [sectorSearchValue]);

	const hasDefaultHierarchy = () =>
		[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM].includes(treeHierarchyTypeId);

	const onNewSector = async selection => {
		const data = { companyId: getCompanyId(), healthSystemId: userSession.healthSystem.id, name: selection.name };
		if (selection.name?.trim()) {
			if (selection.type === SectorTypes.HOSPITAL && hasDefaultHierarchy()) {
				const response = await createFrontlineHospital({ ...data, regionId: userSession.regionId });
				if (response.error) {
					setError(response.error.message);
					return;
				}
			} else if (selection.type === SectorTypes.HOSPITAL && !hasDefaultHierarchy()) {
				const response = await createHospital({
					...data,
					regionId: userSession.regionId,
					hasDefaultDepartment: treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_ROOM,
				});
				if (response.error) {
					setError(response.error.message);
					return;
				}
			} else if (selection.type === SectorTypes.DEPARTMENT) {
				const response = await createDepartment({ ...data, hospitalId: selection.hospitalId });
				if (response.error) {
					setError(response.error.message);
					return;
				}
			} else if (selection.type === SectorTypes.FLOOR && hasDefaultHierarchy()) {
				const response = await createFloor({
					...data,
					hospitalId: selection.hospitalId,
					departmentId: selection.departmentId,
				});
				if (response.error) {
					setError(response.error.message);
					return;
				}
			} else if (selection.type === SectorTypes.FLOOR && treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM) {
				const response = await createFloorInHospital({
					...data,
					hospitalId: selection.hospitalId,
					name: selection.name,
				});
				if (response.error) {
					setError(response.error.message);
					return;
				}
			} else if (
				selection.type === SectorTypes.ROOM &&
				(hasDefaultHierarchy() || treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM)
			) {
				const response = await createRoom({
					...data,
					hospitalId: selection.hospitalId,
					departmentId: selection.departmentId,
					floorId: selection.floorId,
				});
				if (response.error) {
					setError(response.error.message);
					return;
				}
			} else if (
				selection.type === SectorTypes.ROOM &&
				[TreeHierarchyType.HOSPITAL_DEPT_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(treeHierarchyTypeId)
			) {
				const response = await createRoomInDepartment({
					...data,
					hospitalId: selection.hospitalId,
					departmentId: selection.departmentId || selection.defaultDepartmentId,
					name: selection.name,
				});
				if (response.error) {
					setError(response.error.message);
					return;
				}
			}
		}
		await fetchTreeData();
	};

	const fetchTreeData = async () => {
		const subTreeResponse = await getRegionSubTree(userSession.healthSystem.id, userSession.regionId);
		if (subTreeResponse.error) {
			setError(subTreeResponse.error.message);
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const treeData = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			dispatch(healthSystemsActionCreators.setHealthSystem(healthSystem));
			dispatch(healthSystemsActionCreators.setTreeData(treeData));
		}
	};

	const onTreeViewLinkClick = option => {
		setHelloDeviceId(option.helloDeviceId);
		setRoomId(option.roomId);
		dispatch(healthSystemsActionCreators.updateBreadcrumb(option.breadcrumb));
	};

	const createNewHospital = () => {
		dispatch(healthSystemsActionCreators.createNewHospital());
	};

	const toggleTreeView = isRoomsOnlyView => {
		if (isRoomsOnlyView === healthSystems.isRoomsOnlyView || isLoadingView) {
			return;
		}
		dispatch(healthSystemsActionCreators.toggleIsRoomsView());
		const treeData = buildTree(healthSystems.selectedHealthSystem, isRoomsOnlyView);
		dispatch(healthSystemsActionCreators.setTreeData(treeData));
		setIsLoadingView(false);
	};

	const shouldShowDarkModeIcons =
		darkMode && [UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.VIRTUAL_SITTER].includes(getUserRole());

	useEffect(() => {
		const handleAlexaResponse = data => {
			const treeCloned = [...treeData];
			const foundSector = findSectorById(treeCloned, data.helloDeviceId);
			foundSector.alexaServiceStatus = data.isActive;
			dispatch(healthSystemsActionCreators.setTreeData(treeCloned));
		};

		socket.on(SocketEvents.HelloDevice.SETUP_ALEXA_ASP_RESPONSE, handleAlexaResponse);
		return () => {
			socket.off(SocketEvents.HelloDevice.SETUP_ALEXA_ASP_RESPONSE, handleAlexaResponse);
			searchSectors(healthSystems.treeData.tree, '');
		};
	}, [healthSystems.treeData.tree, socket]);

	return (
		<MainLayout>
			{isLoadingView && (
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			{healthSystems.allHealthSystems.length && !isLoadingView && (
				<>
					<Grid columns={screenType.isSmall ? '1fr' : '1fr 3fr'} stretch='100%'>
						{((screenType.isSmall && !helloDeviceId) || !screenType.isSmall) && (
							<aside className='hello-list hospitals-list'>
								<div>
									<div className='left-nav-header flex'>
										<div>
											<h4>{translate(!healthSystems.isRoomsOnlyView ? 'treeView' : 'listView')}</h4>
										</div>
										<div className='flex flex-1 right-align-content tree-view-options'>
											<div
												onClick={() => toggleTreeView(!healthSystems.isRoomsOnlyView)}
												data-tooltip={intl.formatMessage({ id: healthSystems.isRoomsOnlyView ? 'treeView' : 'listView' })}
												data-position='bottom'
												className='cursor-pointer right-before transform-x-0-before'>
												{!healthSystems.isRoomsOnlyView && (
													<ListView color={shouldShowDarkModeIcons ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
												)}
												{healthSystems.isRoomsOnlyView && (
													<TreeViewIcon color={shouldShowDarkModeIcons ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
												)}
											</div>
										</div>
									</div>
									{[UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()) && (
										<div className='create-hospital' onClick={createNewHospital}>
											<p>{translate('createHospital')}</p>
											<div className='margin-left-s'>
												<i className='material-icons-outlined'>add_box</i>
											</div>
										</div>
									)}

									<Input
										className='tree-search'
										type='text'
										name='sectorSearchValue'
										placeholder={intl.formatMessage({ id: 'search' })}
										value={sectorSearchValue}
										onChange={event => setSectorSearchValue(event.target.value)}
										validationOptions={{}}
										bottomSpace='15px'
										autoComplete='off'
									/>

									{!isNewExperience && (
										<TreeView
											isMonitoring={false}
											data={treeData}
											preSelected={treeItems}
											onAdd={onNewSector}
											onLinkClick={onTreeViewLinkClick}
											selectedSectorId={getSelectedSectorId()}
											expandAll={expandAllTreeItems}
										/>
									)}
									{isNewExperience && (
										<TreeViewMayo
											isSmallScreen={screenType.isSmall}
											isMonitoring={false}
											data={treeData}
											preSelected={treeItems}
											onAdd={onNewSector}
											onLinkClick={onTreeViewLinkClick}
											selectedSectorId={getSelectedSectorId()}
											expandAll={expandAllTreeItems}
										/>
									)}
								</div>
							</aside>
						)}
						{((screenType.isSmall && helloDeviceId) || !screenType.isSmall) && (
							<main className='main-view'>
								<section className={classNames('health-system-inner-section', { 'rounding-room-active': isRoundingRoomActive })}>
									<div
										className={classNames('full-width', {
											'center-children': ![UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()),
											'content-height': [UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()),
										})}>
										{[UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()) && (
											<>
												<Route exact path='/health-system/:hospitalId' component={Hospital} />
												<Route exact path='/health-system/:hospitalId/department/:departmentId' component={Department} />
												<Route
													exact
													path='/health-system/:hospitalId/department/:departmentId/floor/:floorId'
													component={Floor}
												/>
												<Route
													exact
													path='/health-system/:hospitalId/department/:departmentId/floor/:floorId/room/:roomId'
													component={Room}
												/>
											</>
										)}

										{[UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.VISITOR].includes(
											// visitor
											getUserRole()
										) && (
											<>
												{!match.params?.room && (
													<p>
														{translate('communicateWithARoom')}, <br />
														{translate('openHospitalChooseDepartment')}, <br />
														{translate('clickFloorSelectRoom')}.
													</p>
												)}
												{match.params.room && !helloDeviceId && (
													<p>
														{translate('roomNoDeviceAssigned')}. <br />
														{translate('roomsNeedDevice')} {translate('inOrderToCall')}.
													</p>
												)}
												{treeData.length && helloDeviceId && (
													<Route
														exact
														path='/health-system/:hospitalId/department/:departmentId/floor/:floorId/room/:roomId'
														render={() => <RoundingRoom helloDeviceId={helloDeviceId} roomId={roomId} />}
													/>
												)}
											</>
										)}
									</div>
								</section>
							</main>
						)}
					</Grid>
				</>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</MainLayout>
	);
};

export default HealthSystem;
