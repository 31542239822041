import ByteBuffer from 'bytebuffer'; 
import { getDevice, registerDevice } from 'api/e2eeDevices.js';
import { getUserId } from 'infrastructure/auth.js';
import IndexDb from 'infrastructure/indexDb/db.js';

/**
 * @param {string | ByteBuffer | Buffer | ArrayBuffer | Uint8Array} thing
 * @param {string} encoding
 */
export const toString = (thing, encoding) => {
	if (typeof thing === 'string') {
		return thing;
	}

	let encodingString = encoding;
	if (!encodingString) {
		encodingString = 'binary';
	}

	return ByteBuffer.wrap(thing).toString(encodingString);
};

const generateKeyPair = async () => {
	const keyPair = await window.crypto.subtle.generateKey({ name: 'ECDH', namedCurve: 'P-256' }, true, [
		'deriveKey',
		'deriveBits',
	]);

	return keyPair;
};

/**
 * @param {{ emit: (arg0: string, arg1: { e2eeDeviceId: any; }) => void; }} socket
 */
const registerE2eeDevice = async socket => {
	const identityKeyPair = await generateKeyPair();

	const userId = getUserId();

	const indexDb = new IndexDb(userId);

	const keys = (await indexDb.keyStore.count()) !== 0;

	const getDeviceId = async () => {
		const deviceId = await indexDb.keyStore.get('deviceId');

		return deviceId;
	};

	const registerNewDevice = async () => {
		const registrationId = Math.floor(Math.random() * 100000);

		const pubKey = await window.crypto.subtle.exportKey('spki', identityKeyPair.publicKey);

		const device = {
			keyBundle: {
				identityPubKey: toString(pubKey, 'base64'),
			},
			registrationId,
		};

		const res = await registerDevice(device);

		const { deviceId } = res;

		const keyStore = [
			{ key: 'deviceId', value: deviceId },
			{ key: 'registrationId', value: registrationId },
			{ key: 'privKey', value: identityKeyPair.privateKey },
		];

		if (!keys) {
			keyStore.forEach(async store => {
				await indexDb.keyStore.add(store.value, store.key);
			});
		}

		socket.emit('client.setE2eeDeviceId', { e2eeDeviceId: res.deviceId });
	};

	const deviceId = await getDeviceId();

	if (!deviceId) {
		return registerNewDevice();
	}

	const result = await getDevice(deviceId, false);

	if (result.error) {
		await registerNewDevice();
	}

	return result;
};

export default registerE2eeDevice;
