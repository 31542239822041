import classNames from 'classnames';

const Form = props => (
	<form
		onSubmit={props.onSubmit}
		style={{ height: props.height || null }}
		className={classNames(props.className, { 'form-description': props.description })}>
		{props.title && <h3>{props.title}</h3>}
		{props.description && <p>{props.description}</p>}
		{props.children}
	</form>
);

export default Form;
