import { Chart as ChartJs } from 'chart.js';
import { Chart } from 'primereact/chart.js';

const fontFamily =
	"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans','Droid Sans', 'Helvetica Neue', sans-serif";

ChartJs.pluginService.register({
	id: 'doughnut-chart',
	beforeDraw: chart => {
		if (chart.config.options.elements?.center?.enable) {
			const total = chart.getDatasetMeta(0).total;
			const { text = total, labelText, fontSize = '24px' } = chart.config.options.elements?.center;
			const ctx = chart.ctx;
			const width = chart.width;
			const height = chart.height;

			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';

			ctx.fillStyle = '#545454';
			ctx.font = `400 16px ${fontFamily}`;
			const labelCenterX = width / 2;
			const labelCenterY = height / 2.4;
			ctx.fillText(labelText, labelCenterX, labelCenterY);

			ctx.fillStyle = '#343434';
			ctx.font = `500 ${fontSize} ${fontFamily}`;
			const centerX = width / 2;
			const centerY = height / 1.8;
			ctx.fillText(text, centerX, centerY);
		}
	},
});

const DoughnutChart = props => <Chart ref={props.chartRef} type='doughnut' {...props} />;

export default DoughnutChart;
