import classNames from 'classnames';
import Modal from 'components/Modal.jsx';
import Button from 'components/Button.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { useRef, useState } from 'react';
import { MonitoringSettings } from 'constants/configurationEnums.js';
import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';

const StatAlarms = ({ isStatAlarmModalOpen, setIsStatAlarmModalOpen, customizeConfig, config }) => {
	const intl = useIntl();
	const [isPlaying, setIsPlaying] = useState(false);
	const [selectedAlarm, setSelectedAlarm] = useState(config?.variant);
	const [audioReady, setAudioReady] = useState(false);
	const audioRefs = useRef([]);
	const modalRef = useRef(null);

	const handlePlayPause = (item, index) => {
		setSelectedAlarm(item);
		const audio = audioRefs.current[index];
		if (!audioReady) {
			return;
		}
		if (selectedAlarm?.value !== item.value) {
			const prevAudio = audioRefs.current.find(ref => ref !== null && ref.paused === false);
			if (prevAudio) {
				prevAudio.pause();
			}
		}
		if (audio.paused) {
			audio.play();
			setIsPlaying(true);
			audio.loop = true;
		} else {
			audio.pause();
			setIsPlaying(false);
		}
	};

	const handleItemClick = item => {
		pauseAlarm();
		setSelectedAlarm(item);
	};

	const pauseAlarm = () => {
		if (selectedAlarm) {
			const audio = audioRefs.current.find(ref => ref !== null && !ref.paused);
			if (audio) {
				audio.pause();
				setIsPlaying(false);
			}
		}
	};

	const handleOnSubmit = () => {
		pauseAlarm();
		customizeConfig(MonitoringSettings.StatAlarm, selectedAlarm?.value);
		setIsStatAlarmModalOpen(false);
	};

	const handleOnCancel = () => {
		pauseAlarm();
		setIsStatAlarmModalOpen(false);
	};

	return (
		<Modal
			display={isStatAlarmModalOpen}
			className='make-appointment-modal border-radius-modal-wrapper appoinment-next-arrow-modal stat-alarm-modal'
			onModalClose={handleOnCancel}
			onModalSubmit={handleOnSubmit}
			submitImgIcon={`${healthCareCdnUrl}tick.svg`}
			closeImgIcon={`${healthCareCdnUrl}cancel.svg`}
			primaryButtonLabel={translate('confirm')}
			isSubmitDisabled={!selectedAlarm}>
			<div ref={modalRef}>
				<div className='modal-form-wrapper stat-alarm-form'>
					<h3>{translate('selectAlarm')}</h3>
					<p>{translate('availableAlarms')}</p>
					<div>
						{config?.options.map((item, index) => (
							<div
								key={item.value}
								className={classNames('flex flex-space-between stat-alarm-details cursor-pointer', {
									active: selectedAlarm?.value === item.value,
								})}>
								<div className='flex flex-align-center full-width' onClick={() => handleItemClick(item)}>
									<input
										readOnly
										type='radio'
										name='choice'
										checked={selectedAlarm?.value === item.value}
										className='cursor-pointer'
									/>
									<span className={classNames('font-14 left-s', { '--blue-light-5': selectedAlarm?.value === item.value })}>
										{intl.formatMessage({ id: item?.label }, { value: item?.translateValue })}
									</span>
								</div>
								<div className='media-player'>
									<audio
										ref={audio => {
											audioRefs.current[index] = audio;
										}}
										controls={false}
										onCanPlayThrough={() => setAudioReady(true)}>
										<source src={item.url} type='audio/mpeg' />
									</audio>
									<Button
										onClick={() => handlePlayPause(item, index)}
										imgIcon={`${healthCareCdnUrl}stetho/${
											isPlaying && selectedAlarm?.value === item.value ? 'pause' : 'play'
										}.svg?v2`}
										alt='ico'
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default StatAlarms;
