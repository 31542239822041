import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

const TableDeleteButton = props => {
	const intl = useIntl();
	return (
		<i
			className={classNames('material-icons-outlined boxed-icon', props.className)}
			onClick={props.onClick}
			data-position='top'
			data-tooltip={intl.formatMessage({ id: 'delete' })}>
			delete
		</i>
	);
};

export default TableDeleteButton;
