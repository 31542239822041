import styled, { css } from 'styled-components';

const StyledNotification = styled.div`
	position: fixed;
	padding: var(--spacing-m);
	bottom: -50px;
	left: 10px;
	background: var(--gray-5);
	border-radius: 5px;
	animation: show-up 3s;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	z-index: 99;

	p {
		padding: 0;
		margin: 0;
		color: var(--gray-0);
		font-size: 14px;
	}

	${css`
		@keyframes show-up {
			0% {
				bottom: -50px;
			}
			10% {
				bottom: 15px;
			}
			80% {
				bottom: 15px;
			}
			100% {
				bottom: -50px;
			}
		}
	`}
`;

const Notification = ({ text }) => (
	<StyledNotification>
		<p>{text}</p>
	</StyledNotification>
);

export default Notification;
