import SemiProgressBar from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/GenericCharts/SemiProgressBar.jsx';
import { MeasurementUnits, MeasurementsMinMax } from 'constants/enums.js';

const WalkingRunningDistanceSummaryChart = props => {
	const maxValue =
		props.measurementUnit === MeasurementUnits.MILES ? MeasurementsMinMax.MILES.MAX : MeasurementsMinMax.METERS.MAX;
	let percentage = (props.measurementValue / maxValue) * 100;
	if (percentage > 100) {
		percentage = 100;
	}
	if (props.measurementValue === 'No Data') {
		percentage = 0;
	}
	return (
		<>
			<div className='body-weight-summary-chart'>
				<SemiProgressBar progress={percentage} stroke='var(--green-1)' />
			</div>
			<div className='flex summary-chart-data body-weight-data'>
				<h1>
					{props.measurementValue}
					{props.measurementValue !== 'No Data' && <span>{props.measurementUnit}</span>}
				</h1>
			</div>
		</>
	);
};

export default WalkingRunningDistanceSummaryChart;
