import { enums } from '@solaborate/calls';
import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { getMemberRooms } from 'api/organization.js';
import { getVisitingHours } from 'api/visitingHours.js';
import { useConference, useConferenceParticipants } from 'calls/hooks/index.js';
import Alert from 'components/Alert.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { AlertTypes, DeviceListLevel, VisitorType } from 'constants/enums.js';
import { timeDifference } from 'infrastructure/helpers/dateHelper.js';

const VisitorContainer = () => {
	const [error, setError] = useState(null);
	const conferenceParticipants = useConferenceParticipants();
	const timeOut = useRef(null);
	const latestToDate = useRef(null);
	const [timeLeft, setTimeLeft] = useState('');
	const conference = useConference();
	const intl = useIntl();

	const fetchVisitingHours = async () => {
		if (conferenceParticipants.length === 0) {
			return;
		}
		const helloParticipant = conferenceParticipants.find(p => p.objectType === enums.ObjectTypes.HELLO_DEVICE);
		const roomMembersResponse = await getMemberRooms();
		if (roomMembersResponse.error) {
			setError(roomMembersResponse.error.message);
			return;
		}
		const found = roomMembersResponse.rooms.find(room => helloParticipant.objectId === +room.helloDeviceId);
		if (!found || found?.visitorTypeId === VisitorType.FAMILY_MEMBER) {
			return;
		}
		const visitingHoursResponse = await getVisitingHours(DeviceListLevel.ROOM, found.id);
		if (visitingHoursResponse.error) {
			setError(roomMembersResponse.error.message);
			return;
		}
		const currentDay = visitingHoursResponse.visitingHours.find(item => item.weekDay === new Date().getDay());
		const result = [];

		for (let i = 0; i < currentDay.hours.length; i += 1) {
			const fromDate = stringToDate(currentDay.hours[i].from);
			const toDate = stringToDate(currentDay.hours[i].to);
			if (isInBetweenFromAndToDate(fromDate, toDate)) {
				result.push({ fromDate, toDate });
			}
		}
		let latest = new Date();

		latest.setSeconds(0);
		for (let i = 0; i < result.length; i += 1) {
			if (+result[i].toDate > +latest) {
				latest = result[i].toDate;
			}
		}
		latestToDate.current = latest;
	};

	const stringToDate = stringDate => {
		const date = new Date();
		const hours = stringDate.substring(0, stringDate.indexOf(':'));
		const minutes = stringDate.substring(stringDate.indexOf(':') + 1, stringDate.lastIndexOf(':'));
		date.setHours(hours);
		date.setMinutes(minutes);
		return date;
	};

	const isInBetweenFromAndToDate = (fromDate, toDate) => {
		const dateNow = new Date();
		return timeDifference(dateNow, fromDate) > 0 && timeDifference(dateNow, toDate) < 0;
	};

	const endCall = async () => {
		conference.leave();
		conference.close(enums.ConferenceEndReasons.PARTICIPANT_LEFT);
	};

	useEffect(() => {
		fetchVisitingHours();
	}, [conferenceParticipants]);

	useEffect(() => {
		timeOut.current = setInterval(() => {
			if (!latestToDate.current) {
				return;
			}
			const now = new Date();
			const difference = timeDifference(latestToDate.current, now);
			if (difference > 0 && difference < 300) {
				const minutes = Math.floor(difference / 60)
					.toString()
					.padStart(2, '0');
				const seconds = Math.floor(difference % 60)
					.toString()
					.padStart(2, '0');
				setTimeLeft(`${minutes}:${seconds}`);
			}
			if (difference <= 0) {
				setTimeLeft(null);
				endCall();
				let closeTabTimeout = setTimeout(() => {
					clearTimeout(closeTabTimeout);
					closeTabTimeout = null;
					window.close();
				}, 5000);
			}
		}, 1000);
	}, []);

	return (
		<>
			<PopUpAlert
				contentText={`${intl.formatMessage({ id: 'visitingHoursEnd' })} ${timeLeft}`}
				display={timeLeft}
				removeCloseBtn={true}
				centerTop={true}
				isSilent={true}
				alertType={AlertTypes.DANGER}
			/>
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
		</>
	);
};

export default VisitorContainer;
