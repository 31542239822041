import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import Select from 'react-select';
import { generateCustomStyles } from 'constants/react-select-style.js';
import { useSelector } from 'react-redux';
import { updatePatientAiSetting } from 'api/patients.js';
import Alert from 'components/Alert.jsx';
import { AiFieldToUpdate, AiNotificationTypes, StatNotificationTypes } from 'constants/ai.js';
import {
	SnoozeTimers,
	areSettingValuesEqual,
	getAdminAiConfigurationsById,
	getCommonRoomAiConfigs,
	getSelectedAiType,
} from 'infrastructure/helpers/aiHelper.js';
import { CallWorkflowType } from 'constants/configurationEnums.js';

const AiConfigurationDetails = ({
	setCheckedItems,
	setRadioButtons,
	setIsItemClicked,
	settingTypeId,
	checkedItems,
	radioButtons,
	aiSettingList,
	deviceId,
	patientId,
	roomId,
	adminAiConfigs,
	isRounding,
}) => {
	const intl = useIntl();
	const foundAiSettings = aiSettingList.find(item => item.deviceId === deviceId);
	const selectedSetting = foundAiSettings ? foundAiSettings.settings.find(item => item.settingTypeId === settingTypeId) : null;
	const user = useSelector(state => state.user);
	const [error, setError] = useState(null);
	const [selectedSnoozeTime, setSelectedSnoozeTime] = useState(null);
	const [snoozeTimes, setSnoozeTimes] = useState(SnoozeTimers(intl));
	const [aiNotificationTypes, setAiNotificationTypes] = useState(AiNotificationTypes(intl));
	const [statNotificationTypes, setStatNotificationTypes] = useState(StatNotificationTypes(intl));
	const workflowType = isRounding ? CallWorkflowType.ROUNDING : CallWorkflowType.MONITORING;

	useEffect(() => {
		if (adminAiConfigs.length > 0) {
			const aiConfigs = getAdminAiConfigurationsById(adminAiConfigs, settingTypeId, workflowType);
			if (!aiConfigs) {
				return;
			}
			setSnoozeTimes(getCommonRoomAiConfigs(SnoozeTimers(intl), aiConfigs?.snoozeValues));
			setAiNotificationTypes(getCommonRoomAiConfigs(AiNotificationTypes(intl), aiConfigs?.alertNotificationOptions));
			setStatNotificationTypes(getCommonRoomAiConfigs(StatNotificationTypes(intl), aiConfigs?.alertNotificationOptions));
		}
	}, [adminAiConfigs, intl, settingTypeId]);

	useEffect(() => {
		setSelectedSnoozeTime({
			label: snoozeTimes.find(item => item.value === selectedSetting?.snoozeTime)?.label,
			value: selectedSetting?.snoozeTime,
		});
	}, [selectedSetting]);

	const handleCheckboxChange = id => {
		setIsItemClicked(prevState => !prevState);
		setCheckedItems(prevCheckedItems => {
			const isChecked = prevCheckedItems.includes(id);

			if (isChecked) {
				return prevCheckedItems.filter(item => item !== id);
			} else {
				return [...prevCheckedItems, id];
			}
		});
	};

	const handleRadioChange = id => {
		setIsItemClicked(prevState => !prevState);
		setRadioButtons([id]);
	};

	const isOptionChecked = alertId => checkedItems.includes(alertId);

	const isRadioBtnEnabled = alertId => radioButtons.includes(alertId);

	const handleSnoozeTimeChange = async item => {
		if (areSettingValuesEqual(selectedSnoozeTime, item)) {
			return;
		}
		setSelectedSnoozeTime(item);
		const params = {
			patientId,
			deviceId,
			roomId,
			fieldToUpdate: AiFieldToUpdate.SNOOZE_TIME,
			value: item?.value ?? null,
			id: selectedSetting.id,
		};
		const response = await updatePatientAiSetting(params);
		if (response.error) {
			setError(response.error.message);
		}
	};

	return (
		<>
			<div className='ai-configurations no-margin'>
				<p className='semi-bold font-14 margin-bottom-0'>{translate('alerts')}</p>
				<div className='flex flex-wrap margin-top-s no-border-bottom margin-bottom-0'>
					{aiNotificationTypes.map(aiNotificationType => (
						<div
							key={aiNotificationType.value}
							className='flex position-relative consent-from-patient margin-right-m margin-top-s'>
							<label className='remember-me cursor-pointer position-relative flex'>
								<input
									className='auto-width ai-configuration-details'
									type='radio'
									checked={isRadioBtnEnabled(aiNotificationType.value)}
									value={aiNotificationType.value}
									name='aiAlertType'
									onChange={() => handleRadioChange(aiNotificationType.value)}
								/>
								<div className='onb-custom-checkbox-wrapper add-patient-checkbox-wrapper ai-configurations-checkbox'>
									<div className='onb-custom-checkbox full-border-radius' />
								</div>
								<p className='left-s font-14'>{aiNotificationType.label}</p>
							</label>
						</div>
					))}
				</div>
			</div>
			<div className='doctor-profile-settings ai-configurations no-margin'>
				{statNotificationTypes.length > 0 && <p className='semi-bold font-14'>{translate('statAlarmActivation')}</p>}
				<div className='flex margin-top-m no-border-bottom'>
					{statNotificationTypes.map(statNotificationType => (
						<div className='register-practice-modal-grid tick-box-active-input' key={statNotificationType.value}>
							<label className='flex remember-me cursor-pointer no-margin'>
								<input
									type='checkbox'
									name={statNotificationType.label}
									checked={isOptionChecked(statNotificationType.value)}
									onChange={() => handleCheckboxChange(statNotificationType.value)}
								/>
								<div className='onb-custom-checkbox-wrapper cursor-pointer top-0 ai-configurations-checkbox'>
									<div className='onb-custom-checkbox' />
								</div>
								<span className='font-14 left-s margin-right-xxl'>{statNotificationType.label}</span>
							</label>
						</div>
					))}
				</div>
				<p className='semi-bold top-15 font-14'>
					{translate('snoozeTimerForAlert', {
						value: intl.formatMessage({ id: getSelectedAiType(settingTypeId) }),
					})}
				</p>
				<div className='flex full-width top-15 ai-configurations-select no-border-bottom margin-bottom-0'>
					<Select
						options={snoozeTimes}
						value={selectedSnoozeTime}
						placeholder={translate('selectTimer')}
						onChange={handleSnoozeTimeChange}
						classNamePrefix='react-select'
						styles={{
							...generateCustomStyles({ darkMode: user.darkMode }),
							menu: base => ({ ...base, width: '100%', maxHeight: 'fit-content' }),
							menuList: base => ({
								...base,
								width: '100%',
								maxHeight: 'fit-content',
							}),
						}}
					/>
				</div>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default AiConfigurationDetails;
