import React from 'react';
import { useSelector } from 'react-redux';
import Monitoring from 'views/Monitoring.jsx';
import MonitoringMayo from 'views/MonitoringMayo.jsx';

const MonitoringWrapper = () => {
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	return isNewExperience ? <MonitoringMayo /> : <Monitoring />;
};

export default MonitoringWrapper;
