import * as React from 'react';
const AmbientMonitoring = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={21} height={20} fill='none' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			d='M8.834 18.053h3.333M16.335 15.551V9.718A5.833 5.833 0 0 0 10.5 3.885v0a5.833 5.833 0 0 0-5.833 5.833v5.833M3.5 15.885h14M2.334 8.052a8.344 8.344 0 0 1 4-5.552m12.333 5.552a8.345 8.345 0 0 0-4-5.552'
		/>
	</svg>
);
export default AmbientMonitoring;
