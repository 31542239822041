import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Grid, Loader } from 'components/index.js';
import { RoomSignIcon } from 'calls/icons/index.js';
import { RoomInfo, Isolations, BradenScore, CareConsiderations } from 'containers/Rounding/RoomSign/index.js';
import translate from 'i18n-translations/translate.jsx';

const RoomSign = ({ deviceOwnerId, userId, deviceId }) => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (deviceId) {
			setIsLoading(false);
		}
	}, [deviceId]);

	return (
		<div className={classNames('monitoring-timeline-box room-sign-wrapper', { 'loading-rounding-whiteboard': isLoading })}>
			<div className='timeline-box-header'>
				<p className='timeline-box-title'>
					<RoomSignIcon height={24} width={24} color='#fff' />
					{translate('roomSign')}
				</p>
			</div>
			<div className={classNames('timeline-box-content rounding-whiteboard-content', { 'loading-whiteboard-data': isLoading })}>
				<div className='patient-informations'>
					{isLoading && (
						<Grid columns='1fr' rows='1fr' horizAlign='center' vertAlign='center'>
							<Loader />
						</Grid>
					)}
					{!isLoading && (
						<>
							<RoomInfo deviceOwnerId={deviceOwnerId} userId={userId} deviceId={deviceId} />
							<Isolations deviceOwnerId={deviceOwnerId} deviceId={deviceId} />
							<BradenScore deviceOwnerId={deviceOwnerId} deviceId={deviceId} />
							<CareConsiderations deviceOwnerId={deviceOwnerId} deviceId={deviceId} isDarkMode={true} />
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default RoomSign;
