import React, { useEffect, useState } from 'react';
import { getProviderAssignedRequests } from 'api/doctorRequests.js';
import { RequestStatus } from 'constants/enums.js';
import { Modal, QueuedPatientsTable, QueuedDoctorsTable, Alert } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';

const PatientsTransferView = props => {
	const [error, setError] = useState(null);
	const [selectedPatientsIds, setSelectedPatientsIds] = useState([]);
	const [patients, setPatients] = useState([]);
	const [showTransferViewToDoctor, setShowTransferViewToDoctor] = useState(false);

	useEffect(() => {
		const getAssignedRequests = async () => {
			const response = await getProviderAssignedRequests({
				doctorGuidId: props.selectedDoctor.id,
				status: RequestStatus.PENDING,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setPatients(response.assignedRequests);
		};
		getAssignedRequests();
	}, [props.selectedDoctor.id]);

	const handleSelectedAllPatientsIds = () => {
		if (selectedPatientsIds.length === patients.length) {
			setSelectedPatientsIds([]);
		} else {
			setSelectedPatientsIds(patients.map(patient => patient.id));
		}
	};

	const handleSelectedPatientsIds = patientId => {
		setSelectedPatientsIds(prevSelected =>
			prevSelected.includes(patientId) ? prevSelected.filter(id => id !== patientId) : [...prevSelected, patientId]
		);
	};

	const handlePatientTransfer = () => {
		if (selectedPatientsIds.length > 0 && !props.isAllHospitalsSelected) {
			setShowTransferViewToDoctor(true);
		}
	};

	return (
		<>
			<Modal
				display={props.display}
				hideActionButtons={true}
				position='right'
				className='assign-patients-modal'
				onModalClose={() => {
					setShowTransferViewToDoctor(false);
					props.setPatientsTransferViewModalOpen(false);
				}}>
				<div className='padding-sl queue-modal'>
					{showTransferViewToDoctor && (
						<>
							<h4>{translate('doctors')}</h4>
							<QueuedDoctorsTable
								selectedHospitalId={props.selectedHospitalId}
								selectedPatientsIds={selectedPatientsIds}
								onBack={() => setShowTransferViewToDoctor(false)}
								onCloseModal={() => props.setPatientsTransferViewModalOpen(false)}
								onPatientTransferComplete={props.onPatientTransferComplete}
								selectedQueueDoctorId={props.selectedDoctor.id}
							/>
						</>
					)}
					{!showTransferViewToDoctor && (
						<>
							<h4>
								{props.selectedDoctor.firstName}
								{translate('doctorsQueue')}
							</h4>
							<QueuedPatientsTable
								selectedHospitalId={props.selectedHospitalId}
								patients={patients}
								selectedPatientsIds={selectedPatientsIds}
								handleSelectedAllPatientsIds={handleSelectedAllPatientsIds}
								handleSelectedPatientsIds={handleSelectedPatientsIds}
								handlePatientTransfer={handlePatientTransfer}
							/>
						</>
					)}
				</div>
			</Modal>
			{error && <Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />}
		</>
	);
};

export default PatientsTransferView;
