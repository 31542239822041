import React from 'react';
import classNames from 'classnames';

export default class CallCorrelationInfo extends React.Component {
	state = {
		copied: false,
	};

	constructor(props) {
		super(props);

		this.correlationIdRef = React.createRef();
	}

	copyToClipboard = () => {
		const textArea = document.createElement('textarea');
		textArea.value = this.props.correlationId;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand('Copy');
		textArea.remove();

		this.setState({
			copied: true,
		});

		setTimeout(() => {
			this.setState({
				copied: false,
			});
		}, 3000);
	};

	render() {
		return (
			<div className={classNames('call-correlation-info', this.props.className)}>
				<span>
					{this.props.correlationId && (
						<span className='correlation-id' ref={this.correlationIdRef}>
							{this.state.copied ? 'Copied!' : this.props.correlationId}
						</span>
					)}

					{!this.props.correlationId && <span className='correlation-id'>n/a</span>}

					{this.props.correlationId && (
						<span className='copy-to-clipboard' title='Copy correlation id to clipboard' onClick={this.copyToClipboard}>
							<i className='material-icons'>file_copy</i>
						</span>
					)}
				</span>
			</div>
		);
	}
}
