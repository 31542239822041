import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SignsSymptoms from 'views/Doctors/Partials/SignsSymptoms.jsx';
import AvailableDoctors from 'views/Doctors/AvailableDoctors.jsx';
import AdditionalHealthData from 'containers/AdditionalHealthData.jsx';
import SelectPharmacy from 'containers/SelectPharmacy.jsx';
import { getSymptoms } from 'api/doctorRequests.js';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import { isUUIDEmpty, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';
import { getUserPharmacy } from 'api/pharmacy.js';
import { getUserId } from 'infrastructure/auth.js';
import { getAppointmentDetails } from 'api/appointments.js';
import { CanceledReschedulingType, SymptomsLength } from 'constants/enums.js';

const DoctorRequestsWrapper = () => {
	const intl = useIntl();
	const location = useLocation();
	const [currentTab, setCurrentTab] = useState(0);
	const [symptoms, setSymptoms] = useState([]);
	const [selectedSymptoms, setSelectedSymptoms] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setLoading] = useState(true);
	const [selectedPharmacy, setSelectedPharmacy] = useState(null);
	const medicalQuestions = [{ id: 1, question: intl.formatMessage({ id: 'howLongSymptoms' }) }];
	const [additionalData, setAdditionalData] = useState({ medicalQuestionAnswers: [], additionalNotes: '', attachments: [] });

	const tabs = {
		SINGS_AND_SYMPTOMS: 0,
		ADDITIONAL_HEALTH_DATA: 1,
		SELECT_PHARMACY: 2,
		SELECT_DOCTOR: 3,
	};

	const imgUrl = `${healthCareCdnUrl}requests/`;

	const userId = getUserId();

	const tabList = [
		{
			id: 0,
			title: translate('signsSymptoms'),
			mainImg: `${imgUrl}1-gray.svg`,
			activeImg: `${imgUrl}1-blue.svg`,
			pastImg: `${imgUrl}1-black.svg`,
		},
		{
			id: 1,
			title: translate('additionalHealthData'),
			mainImg: `${imgUrl}2-gray.svg`,
			activeImg: `${imgUrl}2-blue.svg`,
			pastImg: `${imgUrl}2-black.svg`,
		},
		{
			id: 2,
			title: translate('selectPharmacy'),
			mainImg: `${imgUrl}3-gray.svg`,
			activeImg: `${imgUrl}3-blue.svg`,
			pastImg: `${imgUrl}3-black.svg`,
		},
		{
			id: 3,
			title: translate('selectDoctor'),
			mainImg: `${imgUrl}4-gray.svg`,
			activeImg: `${imgUrl}4-blue.svg`,
			pastImg: `${imgUrl}4-black.svg`,
		},
	].filter(Boolean);

	useEffect(() => {
		if (location?.state?.action === CanceledReschedulingType.RESCHEDULE) {
			setCurrentTab(tabs.SELECT_DOCTOR);
		}
		getPharmacy();
		fetchData();
	}, [intl]);

	useEffect(() => {
		if (selectedPharmacy) {
			goForward();
		}
	}, [selectedPharmacy]);

	const getPharmacy = async () => {
		const response = await getUserPharmacy(userId);
		if (response.error) {
			setError(response.error.message);
		} else {
			let requestPharmacy = null;
			if (!isUUIDEmpty(response.pharmacy.id)) {
				requestPharmacy = { ...response.pharmacy, deliveryTypeId: response.deliveryTypeId };
			}
			setSelectedPharmacy(requestPharmacy);
		}
	};

	const getAppointmentDetail = async () => {
		const response = await getAppointmentDetails(location.state?.notification?.payload.Id);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		const {
			appointmentDetails: { appointmentRequest },
		} = response;
		if (location.state?.recreateAppointment && appointmentRequest && location.state?.notification?.user?.id !== getUserId()) {
			if (appointmentRequest.requestPharmacy) {
				const requestPharmacy = {
					...appointmentRequest.requestPharmacy?.pharmacy,
					deliveryTypeId: appointmentRequest.requestPharmacy?.deliveryType?.id,
				};
				setSelectedPharmacy(requestPharmacy);
			}
			const symptomsList = appointmentRequest.symptoms;
			setSelectedSymptoms(symptomsList);
			setCurrentTab(tabs.SELECT_DOCTOR);
		}
	};

	const fetchData = async () => {
		if (location.state?.recreateAppointment) {
			getAppointmentDetail();
		}
		const symptomsResponse = await getSymptoms();
		if (!symptomsResponse.error) {
			setSymptoms(
				symptomsResponse.symptoms.map(item => {
					const translated = intl.formatMessage({ id: stringToCamelCase(item.name) });
					return { name: translated, id: item.id };
				})
			);
		}
		setLoading(false);
		if (location.state && location.state.selectedRequest) {
			const { selectedRequest } = location.state;
			const symptops = selectedRequest.requestSymptoms.map(item => item.symptom);
			setSelectedSymptoms(symptops);
			setCurrentTab(tabs.SELECT_DOCTOR);
		}
	};

	const addSymptom = symptom => {
		const checkIfSymptomExists = selectedSymptoms.find(item => symptom.name === item.name);
		if (selectedSymptoms.length >= SymptomsLength.MAX_SYMPTOMS) {
			setError(intl.formatMessage({ id: 'limitedSelectedSymptoms' }));
			return;
		}
		if (!checkIfSymptomExists) {
			selectedSymptoms.push(symptom);
		}
		setSelectedSymptoms(selectedSymptoms);
		setSymptoms(symptoms.filter(item => symptom.name !== item.name));
	};

	const removeSymptom = symptom => {
		setError(null);
		symptoms.push(symptom);
		setSelectedSymptoms(selectedSymptoms.filter(item => symptom.name !== item.name));
		setSymptoms(symptoms);
	};

	const tabChange = tab => {
		if (canChangeTab(tab)) {
			setCurrentTab(tab);
		}
	};

	const canChangeTab = nextTab => {
		let result = false;
		switch (currentTab) {
			case tabs.SINGS_AND_SYMPTOMS: {
				if (selectedSymptoms.length > 0) {
					result = true;
				}
				break;
			}

			case tabs.SELECT_DOCTOR: {
				if (nextTab < currentTab) {
					result = true;
				}
				break;
			}

			default: {
				return true;
			}
		}
		return result;
	};

	const nextStep = () => {
		setCurrentTab(prevTab => prevTab + 1);
	};

	const goBack = () => {
		tabChange(currentTab - 1);
	};

	const goForward = () => {
		if (selectedSymptoms.length) {
			tabChange(currentTab + 1);
		}
	};

	return (
		<>
			<MainLayout>
				{currentTab !== tabs.SINGS_AND_SYMPTOMS && (
					<div className='position-relative'>
						<Button
							onClick={goBack}
							imgIcon={`${healthCareCdnUrl}backward-arrow-dark-gray.svg`}
							text={translate('goBack')}
							className='requests-wrapper-button'
							variant='backward'
						/>
					</div>
				)}
				{currentTab !== tabs.SELECT_DOCTOR && (
					<div className='position-relative'>
						<Button
							onClick={goForward}
							imgIcon={`${healthCareCdnUrl}forward-arrow-white.svg`}
							text={translate('nextStep')}
							className={classNames('requests-wrapper-button', !selectedSymptoms.length ? 'disabled' : '')}
							variant='forward'
						/>
					</div>
				)}
				{!isLoading && (
					<Tabs activeIndex={currentTab} onChange={index => tabChange(index)}>
						<TabList className='doctor-wrapper-tabs'>
							{tabList.map(tab => (
								<Tab
									key={tab.id}
									className={classNames(currentTab > tab.id ? 'tab-past' : '', currentTab < tab.id ? 'tab-next' : '')}>
									{currentTab === tab.id && <img src={tab.activeImg} alt='icon' />}
									{currentTab > tab.id && <img src={tab.pastImg} alt='icon' />}
									{currentTab < tab.id && <img src={tab.mainImg} alt='icon' />}
									{tab.title}
								</Tab>
							))}
						</TabList>
						<TabPanels>
							<TabPanel>
								<SignsSymptoms
									nextStep={nextStep}
									symptoms={symptoms}
									selectedSymptoms={selectedSymptoms}
									removeSymptom={symptom => removeSymptom(symptom)}
									addSymptom={symptom => addSymptom(symptom)}
									error={error}
								/>
							</TabPanel>
							<TabPanel>
								<AdditionalHealthData
									additionalData={additionalData}
									questions={medicalQuestions}
									setAnswers={answers => setAdditionalData(answers)}
								/>
							</TabPanel>
							<TabPanel>
								<SelectPharmacy
									setSelectedPharmacy={setSelectedPharmacy}
									selectedPharmacy={selectedPharmacy}
									goForward={goForward}
								/>
							</TabPanel>
							<TabPanel>
								<AvailableDoctors
									symptoms={selectedSymptoms}
									additionalData={additionalData}
									isReassign={!!(location.state && location.state.selectedRequest)}
									selectedPharmacy={selectedPharmacy}
									notificationActionType={location.state?.action}
									isFromNotification={location.state?.recreateAppointment}
								/>
							</TabPanel>
							{/* these componets will be added in the future <VisitFor /> <Payment /> */}
						</TabPanels>
					</Tabs>
				)}
				{isLoading && (
					<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
						<Loader />
					</Grid>
				)}
			</MainLayout>
		</>
	);
};

export default DoctorRequestsWrapper;
