import LightTheme from 'calls/styles/LightTheme.js';

const ProneIcon = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='48'
		height='48'
		viewBox='0 0 48 48'
		fill='none'
		{...props}
		color={LightTheme.colors.grayZero}>
		<rect
			x='10'
			y='2'
			width='28'
			height='44'
			rx='3'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M17.0001 26.5087C16.9983 26.6369 17.0144 26.7647 17.0479 26.8883C17.0874 27.0601 17.1667 27.2198 17.2791 27.3541C17.3916 27.4885 17.534 27.5935 17.6945 27.6606C17.8477 27.7221 18.0114 27.7521 18.1762 27.7489C18.4093 27.7605 18.6397 27.6937 18.8319 27.5589C19.024 27.4241 19.1672 27.2288 19.2394 27.003L20.9752 19.8844L19.8816 41.1873C19.8593 41.4011 19.8787 41.6173 19.9387 41.8236C19.9986 42.0298 20.0979 42.222 20.2309 42.3893C20.3639 42.5565 20.5279 42.6954 20.7137 42.7981C20.8995 42.9008 21.1034 42.9653 21.3137 42.9879C21.3772 42.9922 21.441 42.9922 21.5046 42.9879C21.9101 43.0025 22.3061 42.8605 22.6129 42.5903C22.9196 42.3201 23.1144 41.9418 23.158 41.5315L24.0259 30.1232L24.8938 41.5315C24.936 41.9383 25.1265 42.3143 25.4277 42.5855C25.7288 42.8567 26.1189 43.0034 26.5211 42.9967C26.5862 43.0011 26.6514 43.0011 26.7164 42.9967C27.1403 42.95 27.5288 42.7342 27.7964 42.3967C28.0641 42.0591 28.1891 41.6273 28.1441 41.1961L27.0289 19.8888L28.7647 27.0074C28.8362 27.2336 28.9792 27.4294 29.1715 27.5643C29.3638 27.6992 29.5946 27.7656 29.8279 27.7533C29.9926 27.7565 30.1563 27.7265 30.3096 27.665C30.47 27.5979 30.6124 27.4929 30.7249 27.3585C30.8374 27.2242 30.9167 27.0645 30.9562 26.8927C30.9882 26.7689 31.0028 26.6411 30.9996 26.5131C31.0003 26.378 30.9857 26.2433 30.9562 26.1115C30.7131 25.07 29.9103 19.7123 28.8992 16.9143C27.9922 14.4075 26.2955 15.0828 23.9955 15.0828C21.6955 15.0828 20.0118 14.4208 19.1178 16.8922C18.098 19.677 17.2909 25.0788 17.0479 26.1248C17.0203 26.251 17.0043 26.3795 17.0001 26.5087Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
		<path
			d='M27 10C27 11.6569 25.6569 13 24 13C22.3431 13 21 11.6569 21 10C21 8.34315 22.3431 7 24 7C25.6569 7 27 8.34315 27 10Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
		<path
			d='M26.7324 15C27 14.954 30.2106 14.8116 30.4735 14.7565C31.2811 12.0636 31.0613 8.25217 30.4735 5.55937C27.0117 4.81355 20.9815 4.81354 17.5197 5.55936C16.8268 8.21095 16.8268 12.1049 17.5197 14.7564C17.7778 14.8162 20.7419 14.8713 21 14.9173'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
	</svg>
);

export default ProneIcon;
