import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Grid from 'components/Grid.jsx';
import translate from 'i18n-translations/translate.jsx';
import { useSelector } from 'react-redux';
import ContinueAs from 'containers/ContinueAs.jsx';
import { useIntl } from 'react-intl';
import { Button } from 'components/index.js';

const NoHealthSystem = () => {
	const intl = useIntl();
	const history = useHistory();
	const userRoles = useSelector(state => state.user.userRoles);
	const [showSwitchRole, setShowSwitchRole] = useState(false);

	const signOut = () => history.push('/logout');
	const translator = id => intl.formatMessage({ id });

	if (showSwitchRole) {
		return <ContinueAs />;
	}

	return (
		<Grid className='member-error-wrapper' columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
			<div className='member-error'>
				<h3>{translate('notAssignedHs')}. </h3>
				<img src='https://static.solaborate.com/banyan/healthsystem-error.svg' alt='member-error' />
				<p> {translate('ifYouThinkThereAreMistakes')} </p>
				{userRoles.length === 1 && (
					<span className='logout-link' onClick={signOut}>
						{translate('clickToLoginAnotherAcc')}
					</span>
				)}
				{userRoles.length > 1 && (
					<>
						<Button
							text={translator('switchRole')}
							marginRight='var(--spacing-s)'
							onClick={() => setShowSwitchRole(true)}
							variant='white'
						/>
						<Button text={translator('logOut')} onClick={signOut} />
					</>
				)}
			</div>
		</Grid>
	);
};

export default NoHealthSystem;
