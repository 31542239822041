import * as React from 'react';

function InviteUser(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
			<path
				d='M4.05 21.6a.75.75 0 001.5 0h-1.5zm12.955-6.596a.75.75 0 101.137-.978l-1.137.978zM5.55 21.6v-2.743h-1.5V21.6h1.5zM12 11.25c-4.356 0-7.95 3.372-7.95 7.607h1.5c0-3.339 2.853-6.107 6.45-6.107v-1.5zm0 1.5c2.026 0 3.825.883 5.005 2.254l1.137-.978c-1.462-1.7-3.675-2.776-6.142-2.776v1.5z'
				fill='currentColor'
			/>
			<path
				d='M7.2 18.6v3M15.9 5.7a3.9 3.9 0 11-7.8 0 3.9 3.9 0 017.8 0z'
				stroke='currentColor'
				strokeWidth={1.5}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<circle cx={18.6} cy={18.6} r={4.2} stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' />
			<path d='M19.35 16.8a.75.75 0 00-1.5 0h1.5zm-1.5 3.6a.75.75 0 001.5 0h-1.5zm0-3.6v3.6h1.5v-3.6h-1.5z' fill='currentColor' />
			<path d='M16.8 17.85a.75.75 0 000 1.5v-1.5zm3.6 1.5a.75.75 0 000-1.5v1.5zm-3.6 0h3.6v-1.5h-3.6v1.5z' fill='currentColor' />
		</svg>
	);
}

export default InviteUser;
