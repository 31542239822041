import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { SectorTypes } from 'constants/enums.js';
import { findSectorById } from 'infrastructure/helpers/commonHelpers.js';
import Alert from 'components/Alert.jsx';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import AlexaSmartPropertiesTree from 'containers/AlexaSmartPropertiesTree.jsx';

const AlexaSmartPropertiesWrapper = props => {
	const [aspNotification, setAspNotification] = useState('');
	const [isAspLoading, setIsAspLoading] = useState(false);
	const [tree, setTree] = useState([]);
	const [initialTree, setInitialTree] = useState([]);
	const treeData = useSelector(state => state.healthSystems.treeData.tree);

	const socket = useContext(SocketContext);
	const intl = useIntl();

	useEffect(() => {
		if (props.sector.type) {
			const foundSector = findSectorById(treeData, props.sector[`${props.sector.type}Id`]);
			setTree([foundSector]);
			setInitialTree([_.cloneDeep(foundSector)]);
		}
	}, [props.sector]);

	const gatherItemsToSend = (list, initialList, objs) => {
		for (let i = 0; i < list.length; i += 1) {
			const item = list[i];
			const initialItem = initialList[i];
			if (item.subOptions.length > 0 && SectorTypes.ROOM !== item.type) {
				gatherItemsToSend(item.subOptions, initialItem.subOptions, objs);
			}
			if (
				item.helloDeviceId &&
				SectorTypes.ROOM === item.type &&
				!!item.alexaServiceStatus !== !!initialItem.alexaServiceStatus
			) {
				objs.push({ isActive: !!item.alexaServiceStatus, helloDeviceId: item.helloDeviceId });
			}
		}
	};

	const aspOptionSubmit = async () => {
		setIsAspLoading(true);
		const devices = [];
		gatherItemsToSend(tree, initialTree, devices);
		socket.emit(SocketEvents.HelloDevice.SETUP_ALEXA_ASP, { helloDeviceASPConfigs: devices }, response => {
			if (!response.isSuccessful) {
				setAspNotification(intl.formatMessage({ id: 'aspFailed' }));
				return null;
			}
			props.setIsAlexaSmartPropertiesOpen(false);
			setIsAspLoading(false);
			return null;
		});
	};

	const toggleChildren = (list, isChecked) => {
		list.forEach(item => {
			item.alexaServiceStatus = isChecked;
			toggleChildren(item.subOptions, isChecked);
		});
	};

	const checkParent = (option, treeCloned) => {
		if (treeCloned[0][`${option.type}Id`] !== option[`${option.type}Id`]) {
			const parentType = getParent(option.type);
			const parent = findSectorById(treeCloned, option[`${parentType}Id`]);
			parent.alexaServiceStatus = parent.subOptions.every(item => item.alexaServiceStatus);
			checkParent(parent, treeCloned);
		}
	};

	const handleSelectAll = () => {
		const isChecked = !tree[0].alexaServiceStatus;
		const treeCloned = _.cloneDeep(tree);
		toggleChildren(treeCloned, isChecked);
		setTree(treeCloned);
	};

	const getParent = type => {
		let result;
		switch (type) {
			case SectorTypes.ROOM: {
				result = SectorTypes.FLOOR;
				break;
			}
			case SectorTypes.FLOOR: {
				result = SectorTypes.DEPARTMENT;
				break;
			}
			case SectorTypes.DEPARTMENT: {
				result = SectorTypes.HOSPITAL;
				break;
			}
			default: {
				result = null;
				break;
			}
		}
		return result;
	};

	const handleCheckBoxChange = (option, e) => {
		const isChecked = !!e.target.checked;
		const treeCloned = _.cloneDeep(tree);
		const foundSector = findSectorById(treeCloned, option[`${option.type}Id`]);
		foundSector.alexaServiceStatus = isChecked;
		toggleChildren(foundSector.subOptions, isChecked);
		checkParent(option, tree);
		setTree(treeCloned);
	};

	return (
		<Modal
			modalSelector='addAspOption'
			isLoading={isAspLoading}
			display={props.isAlexaSmartPropertiesOpen}
			position='right'
			isThirdButtonShown={props.sector.type !== SectorTypes.ROOM}
			thirdButtonVariant='white'
			thirdButtonLabel={translate(tree.length > 0 && tree[0].alexaServiceStatus ? 'deSelectAll' : 'selectAll')}
			onThirdButtonClick={handleSelectAll}
			onModalClose={() => props.setIsAlexaSmartPropertiesOpen(prevState => !prevState)}
			onModalSubmit={aspOptionSubmit}>
			<Form title={translate('alexaSmartProperties')} description={intl.formatMessage({ id: 'aspDescription' })}>
				<AlexaSmartPropertiesTree data={tree} handleCheckBoxChange={handleCheckBoxChange} />
				<Alert
					display={aspNotification}
					message={intl.formatMessage({ id: 'alexaSmartPropertiesActivated' })}
					variant='success'
					onClose={() => setAspNotification('')}
				/>
			</Form>
		</Modal>
	);
};

export default AlexaSmartPropertiesWrapper;
