import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import {
	AddEditPatientRadioTypes,
	KeyCodes,
	MeasurementUnitOptions,
	PatientHeightLimit,
	PatientWaistCircumferenceRangeLimit,
	PatientWeightLimit,
	TotalCholesterolLimit,
	UnitCategoryTypes,
	UnitSystemTypes,
} from 'constants/enums.js';
import { diabeticStatusCodes, tobaccoSmokeCodes } from 'constants/health-codes.js';
import translate from 'i18n-translations/translate.jsx';
import { capitalizeFirstLetter, doNotAllowSpaceAsFirstCharacter, isDecimal } from 'infrastructure/helpers/commonHelpers.js';
import { Allergies } from 'constants/visitEnums.js';
import { useSelector } from 'react-redux';
import { RadioButton, Input, CheckboxInput } from 'components/index.js';
import { getPreferredUnit } from 'infrastructure/helpers/measurementsHelper.js';

const radioButtons = [
	{ content: translate('yes'), value: AddEditPatientRadioTypes.YES },
	{ content: translate('no'), value: AddEditPatientRadioTypes.NO },
	{
		content: 'N/A',
		value: AddEditPatientRadioTypes.NO_ANSWER,
	},
];

const HealthInformation = props => {
	const intl = useIntl();
	const unitPreferences = useSelector(state => state.user.unitPreferences);
	const [errorOnAllergyEnter, setErrorOnAllergyEnter] = useState({});

	const getValue = value => {
		const selectedValue = props.healthInformationStatusList.find(item => item?.code === parseInt(value, 10));
		return { value: selectedValue?.code?.toString(), label: selectedValue?.name };
	};

	const getRadioButtons = (name, onChange, description) => (
		<div key={name}>
			<span className='label-desc'>{description}</span>
			<div className='flex'>
				{radioButtons.map(item => (
					<RadioButton
						key={item.value + name}
						value={item.value.toString()}
						onChange={onChange}
						name={name}
						onBlur={props.onBlur}
						checked={props.values[name] === item.value.toString()}
						text={item.content}
						className='margin-right-m'
					/>
				))}
			</div>
		</div>
	);

	const onEnter = (e, formikProps) => {
		if (!e.target.value) {
			return;
		}
		const enter = KeyCodes.ENTER;
		const value = capitalizeFirstLetter(e.target.value);
		const foodArr = [...props.foodAllergies];
		const medicArr = [...props.medicationAllergies];
		const environmentalArr = [...props.environmentalAllergies];
		const biologicalArr = [...props.biologicalAllergies];

		if (e.keyCode === enter) {
			if (!/^[a-zA-Z\s]*$/.test(value)) {
				setErrorOnAllergyEnter({ errorAt: e.target.name, errorMessage: intl.formatMessage({ id: 'allergyNameNonNumber' }) });
				return;
			}
			if (e.target.name === 'foodAllergy') {
				if (!foodArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					foodArr.push(value);
					props.setFoodAllergies(foodArr);
					formikProps.setFieldValue('foodAllergy', '');
				}
			}
			if (e.target.name === 'medicationAllergy') {
				if (!medicArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					medicArr.push(value);
					props.setMedicationAllergies(medicArr);
					formikProps.setFieldValue('medicationAllergy', '');
				}
			}
			if (e.target.name === 'environmentalAllergy') {
				if (!environmentalArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					environmentalArr.push(value);
					props.setEnvironmentalAllergies(environmentalArr);
					formikProps.setFieldValue('environmentalAllergy', '');
				}
			}
			if (e.target.name === 'biologicalAllergy') {
				if (!biologicalArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					biologicalArr.push(value);
					props.setBiologicalAllergies(biologicalArr);
					formikProps.setFieldValue('biologicalAllergy', '');
				}
			}
			setErrorOnAllergyEnter({});
		}
	};

	const removeAllergy = (item, type) => {
		const foodArr = [...props.foodAllergies];
		const medicArr = [...props.medicationAllergies];
		const environmentalArr = [...props.environmentalAllergies];
		const biologicalArr = [...props.biologicalAllergies];
		let foundIndex = null;
		if (type.id === Allergies.FOOD.id) {
			foundIndex = foodArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				foodArr.splice(foundIndex, 1);
			}
			props.setFoodAllergies(foodArr);
		}
		if (type.id === Allergies.MEDICATION.id) {
			foundIndex = medicArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				medicArr.splice(foundIndex, 1);
			}
			props.setMedicationAllergies(medicArr);
		}
		if (type.id === Allergies.ENVIRONMENTAL.id) {
			foundIndex = environmentalArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				environmentalArr.splice(foundIndex, 1);
			}
			props.setEnvironmentalAllergies(environmentalArr);
		}
		if (type.id === Allergies.BIOLOGICAL.id) {
			foundIndex = biologicalArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				biologicalArr.splice(foundIndex, 1);
			}
			props.setBiologicalAllergies(biologicalArr);
		}
	};

	const inputLimits = {
		HEIGHT:
			getPreferredUnit('HEIGHT', 'HEIGHT', unitPreferences)?.unitValue === UnitSystemTypes.METRIC
				? PatientHeightLimit.CENTIMETER.MAX
				: PatientHeightLimit.FEET.MAX,
		WEIGHT:
			getPreferredUnit('WEIGHT', 'WEIGHT', unitPreferences)?.unitValue === UnitSystemTypes.METRIC
				? PatientWeightLimit.KILOGRAMS.MAX
				: PatientWeightLimit.LBS.MAX,
		TOTAL_CHOLESTEROL:
			getPreferredUnit('BLOOD_GLUCOSE', 'BLOOD_GLUCOSE', unitPreferences)?.unitValue === UnitSystemTypes.METRIC
				? TotalCholesterolLimit.MMOLL.MAX
				: TotalCholesterolLimit.MGDL.MAX,
		HDL_CHOLESTEROL:
			getPreferredUnit('BLOOD_GLUCOSE', 'BLOOD_GLUCOSE', unitPreferences)?.unitValue === UnitSystemTypes.METRIC
				? TotalCholesterolLimit.MMOLL.MAX
				: TotalCholesterolLimit.MGDL.MAX,
		WAIST_CIRCUMFERENCE:
			getPreferredUnit('WAIST_CIRCUMFERENCE', 'HEIGHT', unitPreferences)?.unitValue === UnitSystemTypes.METRIC
				? PatientWaistCircumferenceRangeLimit.CM.MAX
				: PatientWaistCircumferenceRangeLimit.INCH.MAX,
	};

	const getMeasurementUnit = measurementType => {
		const measurementDetails = { unitCategoryId: 0, value: '', name: '', limit: 0, error: '' };
		switch (measurementType) {
			case MeasurementUnitOptions.HEIGHT:
				measurementDetails.unitCategoryId = UnitCategoryTypes.HEIGHT;
				measurementDetails.value = props.values.height;
				measurementDetails.name = 'height';
				measurementDetails.limit = inputLimits.HEIGHT;
				measurementDetails.error = props.errors.height;
				break;
			case MeasurementUnitOptions.WEIGHT:
				measurementDetails.unitCategoryId = UnitCategoryTypes.WEIGHT;
				measurementDetails.value = props.values.weight;
				measurementDetails.name = 'weight';
				measurementDetails.limit = inputLimits.WEIGHT;
				measurementDetails.error = props.errors.weight;
				break;
			case MeasurementUnitOptions.WAIST_CIRCUMFERENCE:
				measurementDetails.unitCategoryId = UnitCategoryTypes.HEIGHT;
				measurementDetails.value = props.values.waistCircumference;
				measurementDetails.name = 'waistCircumference';
				measurementDetails.limit = inputLimits.WAIST_CIRCUMFERENCE;
				measurementDetails.error = props.errors.waistCircumference;
				break;
			case MeasurementUnitOptions.TOTAL_CHOLESTEROL:
				measurementDetails.unitCategoryId = UnitCategoryTypes.BLOOD_GLUCOSE;
				measurementDetails.value = props.values.totalCholesterol;
				measurementDetails.name = 'totalCholesterol';
				measurementDetails.limit = inputLimits.TOTAL_CHOLESTEROL;
				measurementDetails.error = props.errors.totalCholesterol;
				break;
			case MeasurementUnitOptions.HDL_CHOLESTEROL:
				measurementDetails.unitCategoryId = UnitCategoryTypes.BLOOD_GLUCOSE;
				measurementDetails.value = props.values.hdlCholesterol;
				measurementDetails.name = 'hdlCholesterol';
				measurementDetails.limit = inputLimits.HDL_CHOLESTEROL;
				measurementDetails.error = props.errors.hdlCholesterol;
				break;
			default:
				break;
		}
		return (
			<Input
				type='number'
				label={translate(measurementDetails.name)}
				placeholder={intl.formatMessage({ id: measurementDetails.name })}
				value={measurementDetails.value}
				onChange={event => {
					if (isDecimal(event.target.value)) {
						props.onChange(event);
					}
				}}
				name={measurementDetails.name}
				className='number-input-wo-arrows'
				error={measurementDetails.error}
				postfix={
					measurementType.value[
						unitPreferences.find(item => item.unitCategoryId === measurementDetails.unitCategoryId)?.unitSystemId
					]?.abbr
				}
			/>
		);
	};

	const getAllergyTypes = () =>
		Object.values(Allergies).map(allergy => {
			const isChecked = props.values.allergies?.find(item => item === allergy.id.toString());
			return (
				<CheckboxInput
					key={allergy.id}
					name='allergies'
					value={allergy.id}
					onChange={() => {
						const updatedArray = isChecked
							? props.values.allergies.filter(item => item !== allergy.id.toString())
							: [...props.values.allergies, allergy.id.toString()];
						props.setFieldValue('allergies', updatedArray);
					}}
					onBlur={props.onBlur}
					checked={isChecked}
					label={translate(allergy.type)}
					className='margin-right-m'
				/>
			);
		});

	const getAllergy = allergyType => {
		const allergyDetails = { label: '', value: '', name: '', list: [] };
		switch (allergyType) {
			case Allergies.FOOD:
				allergyDetails.label = 'foodAllergies';
				allergyDetails.value = props.values.foodAllergy;
				allergyDetails.name = 'foodAllergy';
				allergyDetails.list = props.foodAllergies;
				break;
			case Allergies.MEDICATION:
				allergyDetails.label = 'medicationAllergies';
				allergyDetails.value = props.values.medicationAllergy;
				allergyDetails.name = 'medicationAllergy';
				allergyDetails.list = props.medicationAllergies;
				break;
			case Allergies.ENVIRONMENTAL:
				allergyDetails.label = 'environmentalAllergies';
				allergyDetails.value = props.values.environmentalAllergy;
				allergyDetails.name = 'environmentalAllergy';
				allergyDetails.list = props.environmentalAllergies;
				break;
			case Allergies.BIOLOGICAL:
				allergyDetails.label = 'biologicalAllergies';
				allergyDetails.value = props.values.biologicalAllergy;
				allergyDetails.name = 'biologicalAllergy';
				allergyDetails.list = props.biologicalAllergies;
				break;
			default:
				break;
		}
		return (
			props.values.allergies?.find(item => item === allergyType.id.toString()) && (
				<div key={allergyType.id}>
					<Input
						type='text'
						label={translate(allergyDetails.label)}
						value={allergyDetails.value}
						onChange={props.onChange}
						onBlur={props.onBlur}
						name={allergyDetails.name}
						placeholder={intl.formatMessage({ id: 'typeAllergyAndPressEnter' })}
						onKeyDown={e => onEnter(e, props.formikProps)}
						enterKeyHint='go'
						error={
							(errorOnAllergyEnter.errorAt === allergyDetails.name && errorOnAllergyEnter.errorMessage) ||
							(allergyDetails.list.length === 0 && intl.formatMessage({ id: 'pleaseWriteAllergy' }))
						}
					/>
					{allergyDetails.list.length > 0 && (
						<div className='flex allergies-list flex-wrap'>
							{allergyDetails.list.map(item => (
								<div className='flex' key={item}>
									<i className='material-icons' onClick={() => removeAllergy(item, allergyType)}>
										close
									</i>
									<p>{item}</p>
								</div>
							))}
						</div>
					)}
				</div>
			)
		);
	};

	return (
		<div className='account-setting-tab'>
			<p className='label'>{translate('allergies')}</p>
			<div className='as-fields as-hi'>
				{getRadioButtons('hasAllergy', props.onChange, translate('anyAllergies'))}
				{parseInt(props.values.hasAllergy, 10) === AddEditPatientRadioTypes.YES && (
					<div>
						<span className='label-desc top-10'>{translate('typeOfAllergies')}</span>
						<div className='flex allergy-types'>{getAllergyTypes()}</div>
						{props.errors.allergies && <span className='red-error'>{props.errors.allergies}</span>}
					</div>
				)}
				{parseInt(props.values.hasAllergy, 10) === AddEditPatientRadioTypes.YES && (
					<>{Object.values(Allergies).map(item => getAllergy(item))}</>
				)}
			</div>
			<p className='label title'>{translate('bodyMeasurements')}</p>
			<div className='as-fields as-hi'>
				{getMeasurementUnit(MeasurementUnitOptions.HEIGHT)}
				{getMeasurementUnit(MeasurementUnitOptions.WEIGHT)}
				{getMeasurementUnit(MeasurementUnitOptions.WAIST_CIRCUMFERENCE)}
			</div>
			<p className='label title'>{translate('cholesterol')}</p>
			<div className='as-fields as-hi'>
				{getMeasurementUnit(MeasurementUnitOptions.TOTAL_CHOLESTEROL)}
				{getMeasurementUnit(MeasurementUnitOptions.HDL_CHOLESTEROL)}
			</div>
			<p className='label title'>{translate('otherData')}</p>
			<div className='as-fields as-hi'>
				<div className='input'>
					<p className='label'>{translate('sufferFromHypertension')}</p>
					<Select
						value={getValue(props.values.hasHyperTension)}
						placeholder={intl.formatMessage({ id: 'selectOption' })}
						classNamePrefix='react-select'
						options={props.transformArray(props.healthInformationStatusList)}
						onChange={event => props.formikProps?.setFieldValue('hasHyperTension', event.value)}
					/>
				</div>
				<div className='input'>
					<p className='label'>{translate('medicalStatus')}</p>
					<Select
						value={getValue(props.values.isTakingMedication)}
						placeholder={intl.formatMessage({ id: 'selectOption' })}
						classNamePrefix='react-select'
						options={props.transformArray(props.healthInformationStatusList)}
						onChange={event => props.formikProps?.setFieldValue('isTakingMedication', event.value)}
					/>
				</div>
				<div className='input'>
					<p className='label'>{translate('areYouSmoker')}</p>
					<Select
						value={getValue(props.values.isTobaccoSmoker)}
						placeholder={intl.formatMessage({ id: 'selectOption' })}
						classNamePrefix='react-select'
						options={props.transformArray(props.healthInformationStatusList)}
						onChange={event => props.formikProps?.setFieldValue('isTobaccoSmoker', event.value.toString())}
					/>
					{[AddEditPatientRadioTypes.NO, AddEditPatientRadioTypes.YES].includes(parseInt(props.values.isTobaccoSmoker, 10)) && (
						<div className='row-direction top-s flex flex-wrap'>
							{tobaccoSmokeCodes.map(
								item =>
									item.isSmoker === props.values.isTobaccoSmoker && (
										<RadioButton
											key={item.code}
											className='margin-right-m bottom-5'
											value={item.code}
											onChange={props.onChange}
											name={item.name}
											checked={props.values[item.name] === item.code}
											text={translate(item.content)}
										/>
									)
							)}
						</div>
					)}
					{props.values.isTobaccoSmoker !== AddEditPatientRadioTypes.NO_ANSWER.toString() && (
						<span className='red-error'>{props.errors?.tobaccoYesCode || props.errors?.tobaccoNoCode}</span>
					)}
				</div>
				<div className='input'>
					<p className='label'>{translate('haveDiabeticStatus')}</p>
					<Select
						value={getValue(props.values.hasDiabet)}
						placeholder={intl.formatMessage({ id: 'selectOption' })}
						classNamePrefix='react-select'
						options={props.transformArray(props.healthInformationStatusList)}
						onChange={event => props.formikProps?.setFieldValue('hasDiabet', event.value.toString())}
					/>
					{[AddEditPatientRadioTypes.NO, AddEditPatientRadioTypes.YES].includes(parseInt(props.values.hasDiabet, 10)) && (
						<div className='row-direction top-s flex flex-wrap'>
							{diabeticStatusCodes.map(
								item =>
									item.isDiabetic === props.values.hasDiabet && (
										<div className='flex consent-from-patient' key={item.code}>
											<RadioButton
												value={item.code}
												onChange={props.onChange}
												name={item.name}
												checked={props.values[item.name] === item.code}
												className='margin-right-m bottom-5'
												text={translate(item.content)}
											/>
										</div>
									)
							)}
						</div>
					)}
					{props.errors?.diabeticStatusCode && <span className='red-error'>{props.errors.diabeticStatusCode}</span>}
				</div>
				<div>
					{getRadioButtons('hasPreExistingCondition', props.onChange, translate('preExistingCondition'))}
					{props.values.hasPreExistingCondition === AddEditPatientRadioTypes.YES.toString() && (
						<Input
							id='preExistingCondition'
							placeholder={intl.formatMessage({ id: 'preExistingDescription' })}
							type='text'
							value={props.values.preExistingCondition}
							onChange={props.onChange}
							onBlur={props.onBlur}
							error={props.errors.preExistingCondition}
							maxLength={256}
							onKeyDown={doNotAllowSpaceAsFirstCharacter}
							className='top-s'
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default HealthInformation;
