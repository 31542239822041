import * as React from "react"
import LightTheme from 'calls/styles/LightTheme.js';

const Food = (props) => (
    <svg
        width={34}
        height={34}
        fill="none"
        color={LightTheme.colors.graySeven}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx={17} cy={17} r={8.5} stroke="currentColor" strokeWidth={2} />
        <circle cx={17} cy={17} r={5.667} stroke="currentColor" strokeWidth={2} />
        <path
            d="M4.958 8.5v5.667m0 11.333V14.167m0 0v0a2.125 2.125 0 0 1-2.125-2.125V8.5m2.125 5.667v0a2.125 2.125 0 0 0 2.125-2.125V8.5M31.167 20.719c0-10.094-2.834-12.219-2.834-12.219v12.219m2.834 0h-2.834m2.834 0v3.364c0 .783-.634 1.417-1.417 1.417v0a1.417 1.417 0 0 1-1.416-1.417V20.72"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default Food
