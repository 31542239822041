import { getRoomSettings } from 'api/adminConfigurations.js';
import {
	assignDevices,
	getCompanionDevicesByRoom,
	getDeviceAllowlist,
	getDeviceList,
	getDeviceRemoteApps,
	getDeviceSettings,
	getUnassociatedDevices,
	terminateDeviceRemoteApp,
	updateDeviceSettings,
} from 'api/devices.js';
import { deleteOrgUnit } from 'api/healthSystems.js';
import { getRoomMembersAndInvitees } from 'api/organization.js';
import { getRegionSubTree } from 'api/tree.js';
import { deleteMemberV1M2 } from 'api/users.js';
import { getVisitingHours } from 'api/visitingHours.js';
import classNames from 'classnames';
import CompanionDeviceActions from 'components/CompanionDeviceActions.jsx';
import DeviceActions from 'components/DeviceActions.jsx';
import Companion from 'components/Devices/Companion.jsx';
import FeatureFlagsCategories from 'components/FeatureFlagsCategories.jsx';
import { Alert, Breadcrumb, Button, CustomTable, DescriptionBox, Dropdown, Form, Modal } from 'components/index.js';
import ScheduleNightVision from 'components/ScheduleNightVision.jsx';
import SectorCallSettings from 'components/SectorCallSettings.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import VisitingHoursConfiguration from 'components/VisitingHoursConfiguration.jsx';
import Wearables from 'components/Wearables.jsx';
import {
	CompanySettings,
	IntegrationTypesSettings,
	PTZCameraSettings,
	SettingsCategory,
	VoiceCommandsSettings,
	hospitalFeatureFlagsCategories,
} from 'constants/configurationEnums.js';
import {
	ConnectivityStatus,
	DeviceListLevel,
	DeviceStatus,
	HealthSystemType,
	InviteStatuses,
	InviteTypes,
	SectorTypes,
	UserRoles,
	VisitorType,
} from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SocketEvents from 'constants/socket-events.js';
import { RoomTypes } from 'constants/visitEnums.js';
import AlexaSmartPropertiesWrapper from 'containers/AlexaSmartPropertiesWrapper.jsx';
import AssignFamilyMemberOrVisitor from 'containers/AssignFamilyMemberOrVisitor.jsx';
import AssignPatient from 'containers/AssignPatient.jsx';
import DeviceSettings from 'containers/DeviceSettings.jsx';
import EditSectorModal from 'containers/EditSectorModal.jsx';
import ScheduleDataAcquisition from 'containers/ScheduleDataAcquisition.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserInfo, getUserRole } from 'infrastructure/auth.js';
import {
	buildTree,
	decodeHtml,
	findSectorById,
	getAppVersion,
	getCodeNameByOsVersion,
	getConfigurationValue,
	getHealthSystemDevices,
	getHierarchyByHelloDeviceId,
	getInviteStatus,
	getParentSector,
	getSomeConfigurationsValues,
	safeSplitString,
} from 'infrastructure/helpers/commonHelpers.js';
import { buildAllConfigurations } from 'infrastructure/helpers/configurationsHelpers.js';
import { getFormattedDateWithHour, monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import { getInitialVisitingHoursMaped, reArrangeVisitingHours } from 'infrastructure/helpers/visitingHoursHelper.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ReactSelect from 'react-select';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import { actionCreators } from 'state/healthSystems/actions.js';
import { utils, writeFile } from 'xlsx';

const Room = props => {
	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const socket = useContext(SocketContext);
	const healthSystems = useSelector(state => state.healthSystems);
	const userSession = useSelector(state => state.user.userSession);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const pairedRemoteDevices = useSelector(state => state.devices.pairedRemoteDevices);
	const [isAddDeviceModalLoading, setIsAddDeviceModalLoading] = useState(false);
	const [isRoomModalOpen, setIsRoomModalOpen] = useState(false);
	const [isDevicesModalOpen, setIsDevicesModalOpen] = useState(false);
	const [isCompanionModalOpen, setIsCompanionModalOpen] = useState(false);
	const [isAddPatientModalOpen, setIsAddPatientModalOpen] = useState(false);
	const [isNightVisionModalOpen, setIsNightVisionModalOpen] = useState(false);
	const [visitorType, setVisitorType] = useState(null);
	const [isListLoading, setIsListLoading] = useState(true);
	const [devices, setDevices] = useState([]);
	const [companionDevices, setCompanionDevices] = useState([]);
	const [allowList, setAllowList] = useState([]);
	const [helloDeviceId, setHelloDeviceId] = useState('');
	const [unassociatedDevicesToSelect, setUnassociatedDevicesToSelect] = useState([]);
	const [isAddPatientModalLoading, setIsAddPatientModalLoading] = useState(false);
	const [isDeleteOrgUnitModalOpen, setIsDeleteOrgUnitModalOpen] = useState(false);
	const [isDeleteDeviceModalLoading, setIsDeleteDeviceModalLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isDeviceSettingsModalVisible, setIsDeviceSettingsModalVisible] = useState(false);
	const [currentRoom, setCurrentRoom] = useState({});
	const [currentTab, setCurrentTab] = useState(0);
	const [roomFamilyMembers, setRoomFamilyMembers] = useState([]);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isReversePTZCamModalOpen, setIsReversePTZCamModalOpen] = useState(false);
	const [isReversePTZCamModalLoading, setIsReversePTZCamModalLoading] = useState(false);
	const [currentMember, setCurrentMember] = useState(null);
	const [roomVisitors, setRoomVisitors] = useState([]);
	const [visitingHours, setVisitingHours] = useState([]);
	const [isVisitingHoursModalOpen, setIsVisitingHoursModalOpen] = useState(false);
	const [isAlexaSmartPropertiesOpen, setIsAlexaSmartPropertiesOpen] = useState(false);
	const [isDataAcquisitionOpen, setIsDataAcquisitionOpen] = useState(false);
	const [isReverseCameraEnabled, setIsReverseCameraEnabled] = useState(false);
	const [isDeleteSectorLoading, setIsDeleteSectorLoading] = useState(false);
	const [remoteApps, setRemoteApps] = useState([]);

	const foundSector = useMemo(
		() => findSectorById(healthSystems.treeData.tree, props.match.params.roomId),
		[healthSystems.treeData.tree, props.match.params.roomId]
	);

	useEffect(() => {
		setCurrentRoom({
			...foundSector,
			hasPairedRemote: foundSector?.helloDeviceId && pairedRemoteDevices.some(id => id === +foundSector?.helloDeviceId),
			isOnline: foundSector?.status === DeviceStatus.ONLINE,
		});
	}, [foundSector, pairedRemoteDevices]);

	const fetchUnassociatedDevices = useCallback(async () => {
		const unassociatedDevicesRes = await getUnassociatedDevices();
		if (unassociatedDevicesRes.error) {
			setError(unassociatedDevicesRes.error.message);
		} else {
			const buildDevices = unassociatedDevicesRes.devices.map(item => ({ label: item.uid, value: item.solHelloDeviceId }));
			setUnassociatedDevicesToSelect(buildDevices);
		}
	}, []);

	const openEditSectorModal = () => {
		const sectorParent = getParentSector(healthSystems.treeData.tree, currentRoom);
		setCurrentRoom(prevState => ({ ...prevState, parentSectorName: sectorParent.name, parentSectorType: sectorParent.type }));
		setIsRoomModalOpen(prevState => !prevState);
	};

	const fetchVisitingHours = useCallback(async () => {
		setIsListLoading(true);
		const response = await getVisitingHours(DeviceListLevel.ROOM, props.match.params.roomId);
		if (response.error) {
			setError(response.error.message);
		} else {
			const result = getInitialVisitingHoursMaped(response.visitingHours);
			const visitingHoursRearranged = reArrangeVisitingHours(result, intl);
			setVisitingHours(visitingHoursRearranged);
		}
		setIsListLoading(false);
	}, [intl, props.match.params.roomId]);

	const getSettingsOfDevices = async () => {
		setIsReversePTZCamModalLoading(true);
		const response = await getDeviceSettings(currentRoom?.helloDeviceId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		const reversePtzCamEnabled = response?.deviceSettings.find(x => x.settingTypeId === PTZCameraSettings.REVERSE_CAMERA)?.value;
		setIsReverseCameraEnabled(reversePtzCamEnabled === 'true');
		setIsReversePTZCamModalLoading(false);
	};

	const fetchRoomVisitors = useCallback(async () => {
		setIsListLoading(true);
		const response = await getRoomMembersAndInvitees(userSession.healthSystem.id, props.match.params.roomId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setRoomFamilyMembers(response?.roomMembers.filter(item => item.visitorTypeId === VisitorType.FAMILY_MEMBER));
		setRoomVisitors(response?.roomMembers.filter(item => item.visitorTypeId === VisitorType.VISITOR));
		setIsListLoading(false);
	}, [props.match.params.roomId, userSession.healthSystem.id]);

	const fetchDevices = useCallback(async () => {
		setIsListLoading(true);
		const [devicesRes, roomConfigurations] = await Promise.all([
			getDeviceList(DeviceListLevel.ROOM, props.match.params.roomId),
			getRoomSettings(props.match.params.roomId, SettingsCategory.VOICE_COMMANDS),
		]);
		if (devicesRes.error || roomConfigurations.error) {
			setError(devicesRes.error.message || roomConfigurations.error.message);
		} else {
			if (devicesRes.length > 0) {
				const voiceCommandsConfigs = buildAllConfigurations(roomConfigurations.settings);
				devicesRes[0].voiceCommandsStatus = getSomeConfigurationsValues(
					voiceCommandsConfigs,
					Object.values(VoiceCommandsSettings)
				);
				const response = await getDeviceAllowlist(devicesRes[0].solHelloDeviceId);
				if (response.error) {
					setError(response.error.message);
					return;
				}
				setAllowList(response.result.data);
			}
			setDevices(devicesRes);
		}
		setIsListLoading(false);
	}, [props.match.params.roomId]);

	const fetchCompanionDevicesRes = useCallback(async () => {
		const companionDevicesRes = await getCompanionDevicesByRoom(props.match.params.roomId);
		if (companionDevicesRes.error) {
			setError(companionDevicesRes.error.message);
		} else {
			setCompanionDevices(companionDevicesRes.result);
		}
	}, [props.match.params.roomId]);

	useEffect(() => {
		const fetchRemoteApps = async () => {
			if (!currentRoom?.helloDeviceId) {
				setRemoteApps([]);
				return;
			}
			setIsListLoading(true);
			const response = await getDeviceRemoteApps(currentRoom?.helloDeviceId);
			if (response.error) {
				setError(response.error.message);
			} else {
				setRemoteApps(response.remoteAppSessions);
			}
			setIsListLoading(false);
		};

		fetchRemoteApps();
		socket.on(SocketEvents.RemoteApp.SESSION_CREATED, fetchRemoteApps);
		socket.on(SocketEvents.RemoteApp.SESSION_INVALIDATED, fetchRemoteApps);

		return () => {
			socket.off(SocketEvents.RemoteApp.SESSION_CREATED, fetchRemoteApps);
			socket.off(SocketEvents.RemoteApp.SESSION_INVALIDATED, fetchRemoteApps);
		};
	}, [socket, currentRoom?.helloDeviceId]);

	useEffect(() => {
		fetchDevices();
		fetchCompanionDevicesRes();
		fetchUnassociatedDevices();
	}, [
		props.match.params.roomId,
		fetchRoomVisitors,
		fetchCompanionDevicesRes,
		fetchVisitingHours,
		fetchDevices,
		fetchUnassociatedDevices,
	]);

	useEffect(() => {
		if ([tabsList().indexOf('familyMembers'), tabsList().indexOf('visitors')].includes(currentTab)) {
			fetchRoomVisitors();
		}
		if (currentTab === tabsList().indexOf('visitingHours')) {
			fetchVisitingHours();
		}
	}, [props.match.params.roomId, fetchRoomVisitors, fetchVisitingHours, currentTab]);

	const getConnectionType = device => (
		<>
			{[device.wifi, device.ethernet].includes(ConnectivityStatus.ON) && (
				<>
					{device.wifi === ConnectivityStatus.ON && 'WiFi'}
					{device.ethernet === ConnectivityStatus.ON && device.wifi === ConnectivityStatus.ON && ','}{' '}
					{device.ethernet === ConnectivityStatus.ON && 'Ethernet'}
				</>
			)}

			{device.wifi === ConnectivityStatus.OFF && device.ethernet === ConnectivityStatus.OFF && 'N/A'}
		</>
	);

	const getDeviceOwner = device => (
		<div className='flex'>
			{!device?.isVirtualPatientAssigned && !device?.isDefaultOwner ? decodeHtml(device.owner) : translate('unassigned')}
		</div>
	);

	const getDeviceStatus = status => translate(status === DeviceStatus.ONLINE ? 'online' : 'offline');

	const displayDevices = devices => {
		if (!devices.length || healthSystems.treeData.tree.length === 0) {
			return [];
		}
		return devices.map(device => {
			return {
				id: device.id,
				roomName: currentRoom?.name,
				...(!(isPatientDisabled() || device.isVirtualPatientAssigned) && { owner: getDeviceOwner(device) }),
				serialNumber: device.serialNumber || 'N/A',
				macAddress: device.macAddress || 'N/A',
				appVersion: device.appVersion ? getAppVersion(device.appVersion) : 'N/A',
				androidVersion: device.appVersion ? getCodeNameByOsVersion(device.osVersion) : 'N/A',
				osVersion: device.firmwareRevision || 'N/A',
				ipAddress: device.ipAddress || 'N/A',
				ssid: device.ssid || 'N/A',
				connectionType: getConnectionType(device),
				voiceCommandsStatus: intl.formatMessage({ id: device.voiceCommandsStatus ? 'active' : 'notActive' }),
				actions: (
					<DeviceActions
						setHelloDeviceId={setHelloDeviceId}
						setIsDeleteDeviceModalLoading={setIsDeleteDeviceModalLoading}
						fetchTreeData={fetchTreeData}
						isDeleteDeviceModalLoading={isDeleteDeviceModalLoading}
						loadRoom={fetchDevices}
						helloDeviceId={helloDeviceId}
						device={device}
						isPatientDisabled={isPatientDisabled()}
						setDevices={setDevices}
						fetchUnassociatedDevices={fetchUnassociatedDevices}
					/>
				),
			};
		});
	};

	const displayCompanionDevices = () => {
		if (!companionDevices.length || healthSystems.treeData.tree.length === 0) {
			return [];
		}
		return companionDevices.map(device => {
			return {
				id: device.solHelloDeviceId,
				serialNumber: device.serialNumber || 'N/A',
				name: device.deviceFamilyName || 'N/A',
				mode: device.companionModeName || 'N/A',
				macAddress: device.macAddress || 'N/A',
				status: getDeviceStatus(device.status) || 'N/A',
				osVersion: device.osVersion || 'N/A',
				appVersion: device.appVersion ? getAppVersion(device.appVersion) : 'N/A',
				ipAddress: device.ipAddress || 'N/A',
				ssid: device.ssid || 'N/A',
				connectionType: getConnectionType(device),
				actions: (
					<CompanionDeviceActions
						setIsDeleteDeviceModalLoading={setIsDeleteDeviceModalLoading}
						isDeleteDeviceModalLoading={isDeleteDeviceModalLoading}
						loadRoom={fetchDevices}
						device={device}
					/>
				),
			};
		});
	};

	const displayAllowlist = () => {
		if (!allowList.length) {
			return [];
		}

		return allowList.map(({ id, domainUrl, createdDate, isAllowed, protocol, response }) => ({
			number: id,
			domainUrl,
			createdDate: moment(createdDate).format('YYYY/MM/DD - HH:mm'),
			isAllowed: intl.formatMessage({ id: isAllowed ? 'allowed' : 'denied' }),
			protocol,
			response,
		}));
	};

	const deleteOrgUnitSubmit = async () => {
		setIsDeleteSectorLoading(true);
		const response = await deleteOrgUnit(DeviceListLevel.ROOM, props.match.params.roomId);
		if (response?.error) {
			setError(response.error.message);
			setIsDeleteSectorLoading(false);
			return;
		}
		setIsDeleteOrgUnitModalOpen(prevState => !prevState);
		setIsDeleteSectorLoading(false);
		fetchTreeData();
		history.push('/health-system');
		dispatch(actionCreators.updateBreadcrumb([]));
	};

	const assignDevicesSubmit = async () => {
		setIsAddDeviceModalLoading(true);
		const params = { ...props.match.params, devices: [+helloDeviceId] };
		const response = await assignDevices(params);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		fetchDevices();
		fetchTreeData();
		setIsAddDeviceModalLoading(false);
		setHelloDeviceId('');
		setIsDevicesModalOpen(prevState => !prevState);
		fetchUnassociatedDevices();
	};

	const fetchTreeData = async () => {
		const subTreeResponse = await getRegionSubTree(userSession.healthSystem.id, userSession.regionId);
		if (subTreeResponse.error) {
			setError(subTreeResponse.error.message);
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const tree = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			dispatch(actionCreators.setHealthSystem(healthSystem));
			dispatch(actionCreators.setTreeData(tree));
		}
	};

	const exportAsCsv = () => {
		const destructuredStrings = 3;

		const transformedDevices = devices.map(
			({ serialNumber, macAddress, appVersion, firmwareRevision, osVersion, solHelloDeviceId }, index) => {
				const [ethernetMacAddress, wifiMacAddress] = safeSplitString(macAddress, ' ');
				const [hospital, department, floorAndRoom] = safeSplitString(
					getHierarchyByHelloDeviceId(healthSystems.treeData.tree, solHelloDeviceId),
					',',
					destructuredStrings
				);

				return {
					'NO#': index + 1,
					'Serial Number': serialNumber,
					'Ethernet MAC': ethernetMacAddress,
					'WI-FI MAC': wifiMacAddress,
					'APP Version': appVersion,
					'OS Version': firmwareRevision,
					'Android Version': getCodeNameByOsVersion(osVersion),
					Hospital: hospital,
					Department: department,
					'Unit - Room': floorAndRoom,
				};
			}
		);

		const workBook = utils.book_new();
		const workSheet = utils.json_to_sheet(transformedDevices);
		utils.book_append_sheet(workBook, workSheet, 'Devices');
		writeFile(workBook, `${currentRoom?.name}-${(+new Date()).toString()}.csv`);
	};

	const exportAllowlistCsv = () => {
		return allowList.map(({ id, domainUrl, createdDate, isAllowed, protocol, response }) => ({
			ID: id,
			'Domain Url': domainUrl,
			'Reported On': moment(createdDate).format('YYYY/MM/DD - HH:mm'),
			Allowed: intl.formatMessage({ id: isAllowed ? 'allowed' : 'denied' }),
			Protocol: protocol,
			Response: response,
		}));
	};

	const displayFamilyMembersOrVisitors = list => {
		if (!list || !list.length) {
			return [];
		}
		return list.map(member => ({
			member: member.name || 'N/A',
			email: member.email,
			invitedBy: member.invitedBy,
			invitedDate: monthDayYear(member.inviteDate),
			memberStatus: getInviteStatus(member.memberStatus),
			disableAccess: getEditButtons(member.memberId, member.memberStatus),
		}));
	};

	const getEditButtons = (memberId, memberStatusId) => {
		if (memberStatusId === InviteStatuses.SENT.type) {
			return <></>;
		}
		return (
			<div className='wrapped'>
				&nbsp;
				<i
					className='material-icons-outlined boxed-icon'
					onClick={() => {
						setCurrentMember(memberId);
						setIsDeleteModalOpen(true);
					}}
					data-position='top'
					data-tooltip={intl.formatMessage({ id: 'delete' })}>
					delete
				</i>
			</div>
		);
	};

	const handleDeleteMember = async () => {
		const response = await deleteMemberV1M2(currentMember, props.match.params.roomId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setCurrentMember(null);
		setRoomFamilyMembers(prevState => prevState.filter(member => member.memberId !== currentMember));
		setIsDeleteModalOpen(false);
	};

	const handleReversePTZCameraOption = async () => {
		const params = {
			deviceId: currentRoom?.helloDeviceId,
			settingTypeId: PTZCameraSettings.REVERSE_CAMERA,
			settingValue: (!isReverseCameraEnabled).toString(),
		};
		const response = await updateDeviceSettings(params);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setIsReverseCameraEnabled(value => !value);
	};

	const isPatientDisabled = () =>
		getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_VIRTUAL_ADMISSION]) &&
		!getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_MANUAL_ADMISSION]);

	const onBreadcrumbClick = (options, breadcrumbIndex) => {
		const breadcrumb = [];
		options.forEach((option, index) => {
			if (breadcrumbIndex + 1 > index) {
				breadcrumb.push(option);
			}
		});
		dispatch(actionCreators.updateBreadcrumb(breadcrumb));
	};

	const handleTerminateDeviceRemoteApp = async app => {
		const response = await terminateDeviceRemoteApp(currentRoom?.helloDeviceId, app.sessionId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		const { firstName, lastName } = getUserInfo();
		setRemoteApps(prevState =>
			prevState.map(item =>
				item.sessionId === app.sessionId
					? { ...item, terminatedDate: Date.now(), terminatedByFullName: `${firstName} ${lastName}`, isActive: false }
					: item
			)
		);
	};

	const deviceHeaders = [
		{ title: translate('room'), id: 'roomName' },
		!(isPatientDisabled() || devices[0]?.isVirtualPatientAssigned) && { title: translate('patient'), id: 'owner' },
		{ title: translate('deviceSerialNumber'), id: 'serialNumber' },
		{ title: translate('macAddress'), id: 'macAddress' },
		{ title: translate('appVersion'), id: 'appVersion' },
		{ title: translate('androidVersion'), id: 'androidVersion' },
		{ title: translate('osVersion'), id: 'osVersion' },
		{ title: translate('ipAddress'), id: 'ipAddress' },
		{ title: 'SSID', id: 'ssid' },
		{ title: translate('connectionType'), id: 'connectionType' },
		{ title: translate('voiceCommands'), id: 'voiceCommands' },
		{ title: translate('actions'), id: 'actions', columnClass: 'normal-wrap' },
	].filter(Boolean);

	const displayRemoteApps = () => {
		if (!remoteApps || !remoteApps.length) {
			return [];
		}
		return remoteApps.map(app => ({
			name: app.deviceName || 'N/A',
			type: app.deviceType,
			dateCreated: getFormattedDateWithHour(app.dateCreated),
			status: translate(app.isActive ? 'active' : 'terminated'),
			terminatedDate: app.terminatedDate > app.dateCreated ? getFormattedDateWithHour(app.terminatedDate) : 'N/A',
			terminatedBy: app?.terminatedByFullName || 'N/A',
			actions: app.isActive && (
				<Button
					text={translate('terminate')}
					variant='red'
					imgIcon={`${healthCareCdnUrl}admin/delete-room.svg`}
					onClick={() => handleTerminateDeviceRemoteApp(app)}
				/>
			),
		}));
	};

	const showAssignVisitor = () =>
		userSession.healthSystem.workflowTypeId === HealthSystemType.DEFAULT && RoomTypes.BABY_ROOM.type !== currentRoom?.roomType;

	const tabsList = () =>
		[
			'roomDetails',
			'familyMembers',
			RoomTypes.BABY_ROOM.type !== currentRoom?.roomType && 'visitors',
			RoomTypes.BABY_ROOM.type !== currentRoom?.roomType && 'visitingHours',
			'enrolledCompanionDevices',
			getUserRole() !== UserRoles.SUPER_USER && 'featureFlags',
			devices?.length > 0 && !devices[0].isDefaultOwner && 'wearables',
			'endpointWhitelisting',
			'defaultCamera',
			'remoteApps',
		].filter(Boolean);

	return (
		<div className='users room-wrapper'>
			<div className='flex sector-header-wrapper'>
				<div className='breadcrumb-container hs-breadcrumb-container'>
					<Breadcrumb links={healthSystems.breadcrumb} onBreadcrumbClick={onBreadcrumbClick} isFromHealthSystems={true} />
				</div>
				<div className='sector-header flex'>
					<DescriptionBox
						data={[
							{ title: translate('enrolledDevices'), description: devices.length, id: 0 },
							{
								title: translate('enrolledCompanionDevices'),
								description: companionDevices ? companionDevices.length : 0,
								id: 1,
							},
						]}
					/>
					<div className='sector-btn-actions'>
						{devices.length > 0 && (
							<Button
								text={translate('deviceSettings')}
								variant='white'
								imgIcon={`${healthCareCdnUrl}admin/device-settings.svg`}
								onClick={() => setIsDeviceSettingsModalVisible(prevState => !prevState)}
							/>
						)}
						<Button
							text={translate('deleteRoom')}
							variant='red'
							imgIcon={`${healthCareCdnUrl}admin/delete-room.svg`}
							onClick={() => setIsDeleteOrgUnitModalOpen(prevState => !prevState)}
							disabled={!currentRoom?.roomId}
						/>
						<Button
							text={translate('editDetails')}
							variant='yellow'
							onClick={() => openEditSectorModal()}
							imgIcon={`${healthCareCdnUrl}admin/edit.svg`}
							disabled={!currentRoom?.roomId}
						/>
						<Dropdown
							position='bottom'
							icon='expand_more'
							className={classNames('organization-dropdown', { disabled: !currentRoom?.roomId })}>
							{UserRoles.SUPER_USER !== getUserRole() && !devices.length && (
								<Button
									text={translate('addDevice')}
									variant='green'
									onClick={() => setIsDevicesModalOpen(prevState => !prevState)}
									imgIcon={`${healthCareCdnUrl}admin/add-device-blue.svg`}
								/>
							)}
							{UserRoles.SUPER_USER !== getUserRole() && (
								<Button
									text={translate('addCompanionDevice')}
									variant='green'
									onClick={() => setIsCompanionModalOpen(prevState => !prevState)}
									imgIcon={`${healthCareCdnUrl}admin/add-device-blue.svg`}
								/>
							)}
							<Button
								variant='white'
								imgIcon={`${healthCareCdnUrl}admin/visiting-hours.svg`}
								onClick={() => setIsVisitingHoursModalOpen(true)}
								text={intl.formatMessage({ id: 'visitingHours' })}
							/>
							{devices.length > 0 && (
								<>
									<Button
										variant='white'
										imgIcon={`${healthCareCdnUrl}admin/export-as-csv.svg`}
										text={translate('exportAsCSV')}
										onClick={exportAsCsv}
									/>
									<Button
										variant='white'
										imgIcon={`${healthCareCdnUrl}admin/export-as-csv.svg`}
										text={
											<CSVLink data={exportAllowlistCsv()} filename={`${currentRoom?.name}-${(+new Date()).toString()}.csv`}>
												{translate('endpointWhitelisting')} {translate('csv')}
											</CSVLink>
										}
									/>
									{!isPatientDisabled() && (
										<Button
											text={translate('assignPatient')}
											variant='white'
											onClick={() => {
												setIsAddPatientModalOpen(true);
												setIsAddPatientModalLoading(false);
											}}
											imgIcon={`${healthCareCdnUrl}admin/assign-patient-blue.svg`}
										/>
									)}
									<Button
										text={translate('assignFamilyMember')}
										variant='white'
										onClick={() => setVisitorType(InviteTypes.FAMILY_MEMBER)}
										imgIcon={`${healthCareCdnUrl}admin/assign-family-members.svg`}
									/>
									{showAssignVisitor() && (
										<Button
											text={translate('assignVisitor')}
											variant='white'
											onClick={() => setVisitorType(InviteTypes.VISITOR)}
											imgIcon={`${healthCareCdnUrl}admin/assign-visitors.svg`}
										/>
									)}
									<Button
										text={intl.formatMessage({ id: 'dataAcquisitionSchedule' })}
										variant='white'
										onClick={() => {
											setIsDataAcquisitionOpen(prevState => !prevState);
										}}
										imgIcon={`${healthCareCdnUrl}admin/data-acquisition.svg`}
									/>
									{getConfigurationValue(companyConfigurations[IntegrationTypesSettings.ALEXA]) && (
										<Button
											variant='white blue-icon'
											text={intl.formatMessage({ id: 'alexaSmartProperties' })}
											size='medium'
											icon='home_max'
											onClick={() => setIsAlexaSmartPropertiesOpen(prev => !prev)}
										/>
									)}
									<Button
										text={intl.formatMessage({ id: 'ptzCamSettingsTitle' })}
										variant='white'
										onClick={() => {
											getSettingsOfDevices();
											setIsReversePTZCamModalOpen(true);
										}}
										icon='settings'
									/>
								</>
							)}
							<Button
								variant='white'
								imgIcon={`${healthCareCdnUrl}admin/night-vision.svg`}
								onClick={() => setIsNightVisionModalOpen(true)}
								text={intl.formatMessage({ id: 'scheduleNightVision' })}
							/>
						</Dropdown>
					</div>
				</div>
			</div>
			<Tabs activeIndex={currentTab} onChange={index => setCurrentTab(index)}>
				<TabList>
					{tabsList().map(item => (
						<Tab key={item}>{translate(item)}</Tab>
					))}
				</TabList>
				<TabPanels>
					<TabPanel>
						<CustomTable
							headers={deviceHeaders}
							rows={isListLoading ? [] : displayDevices(devices)}
							className='admin-table'
							isEditable={false}
							isLoading={isListLoading}
						/>
					</TabPanel>
					<TabPanel>
						<CustomTable
							headers={[
								{ title: translate('name'), id: 'member' },
								{ title: translate('email'), id: 'email' },
								{ title: translate('invitedBy'), id: 'invitedBy' },
								{ title: translate('dateSent'), id: 'invitedDate' },
								{ title: translate('memberStatus'), id: 'memberStatus' },
								{ title: translate('disableAccess'), id: 'disableAccess' },
							]}
							rows={isListLoading ? [] : displayFamilyMembersOrVisitors(roomFamilyMembers)}
							className='admin-table'
							isEditable={false}
							isLoading={isListLoading}
						/>
					</TabPanel>
					{RoomTypes.BABY_ROOM.type !== currentRoom?.roomType && (
						<TabPanel>
							<CustomTable
								headers={[
									{ title: translate('name'), id: 'member' },
									{ title: translate('email'), id: 'email' },
									{ title: translate('invitedBy'), id: 'invitedBy' },
									{ title: translate('dateSent'), id: 'invitedDate' },
									{ title: translate('memberStatus'), id: 'memberStatus' },
									{ title: translate('disableAccess'), id: 'disableAccess' },
								]}
								rows={isListLoading ? [] : displayFamilyMembersOrVisitors(roomVisitors)}
								className='admin-table'
								isEditable={false}
								isLoading={isListLoading}
							/>
						</TabPanel>
					)}
					{RoomTypes.BABY_ROOM.type !== currentRoom?.roomType && (
						<TabPanel>
							<CustomTable
								headers={[
									{ title: translate('monday'), id: 0 },
									{ title: translate('tuesday'), id: 1 },
									{ title: translate('wednesday'), id: 2 },
									{ title: translate('thursday'), id: 3 },
									{ title: translate('friday'), id: 4 },
									{ title: translate('saturday'), id: 5 },
									{ title: translate('sunday'), id: 6 },
								]}
								rows={isListLoading ? [] : visitingHours}
								className='admin-table'
								isEditable={false}
								isLoading={isListLoading}
							/>
						</TabPanel>
					)}
					<TabPanel>
						<CustomTable
							headers={[
								{ title: translate('deviceSerialNumber'), id: 'serialNumber' },
								{ title: translate('deviceModel'), id: 'name' },
								{ title: translate('deviceMode'), id: 'mode' },
								{ title: translate('macAddress'), id: 'macAddress' },
								{ title: translate('status'), id: 'status' },
								{ title: translate('osVersion'), id: 'osVersion' },
								{ title: translate('appVersion'), id: 'appVersion' },
								{ title: translate('ipAddress'), id: 'ipAddress' },
								{ title: 'SSID', id: 'ssid' },
								{ title: translate('connectionType'), id: 'connectionType' },
								{ title: translate('actions'), id: 'actions', columnClass: 'normal-wrap' },
							]}
							rows={isListLoading ? [] : displayCompanionDevices()}
							className='admin-table'
							isEditable={false}
							isLoading={isListLoading}
						/>
					</TabPanel>
					{getUserRole() !== UserRoles.SUPER_USER && (
						<TabPanel>
							<FeatureFlagsCategories
								selectedHealthSystem={{ value: userSession.healthSystem.id }}
								levelId={props.match.params.roomId}
								currentSectorType={DeviceListLevel.ROOM}
								featureFlagsCategories={hospitalFeatureFlagsCategories}
								setError={setError}
							/>
						</TabPanel>
					)}
					{devices?.length > 0 && !devices[0].isDefaultOwner && (
						<TabPanel>
							<Wearables currentSector={currentRoom} ownerId={devices[0].ownerUserId} helloDeviceId={devices[0].id} />
						</TabPanel>
					)}
					<TabPanel>
						<CustomTable
							headers={[
								{ title: translate('id'), id: 'number' },
								{ title: translate('domain'), id: 'domainUrl' },
								{ title: translate('reportedOn'), id: 'createdDate' },
								{ title: translate('allowed'), id: 'isAllowed' },
								{ title: translate('protocol'), id: 'protocol' },
								{ title: translate('response'), id: 'response' },
							]}
							rows={displayAllowlist()}
							className='admin-table'
							isEditable={false}
						/>
					</TabPanel>
					<TabPanel>
						<SectorCallSettings
							selectedHealthSystem={userSession?.healthSystem?.id}
							levelId={DeviceListLevel.ROOM}
							sectorId={props.match.params.roomId}
						/>
					</TabPanel>
					<TabPanel>
						<CustomTable
							headers={[
								{ title: translate('deviceName'), id: 'name' },
								{ title: translate('deviceType'), id: 'type' },
								{ title: translate('dateCreated'), id: 'dateCreated' },
								{ title: translate('status'), id: 'status' },
								{ title: translate('terminatedDate'), id: 'terminatedDate' },
								{ title: translate('terminatedBy'), id: 'terminatedBy' },
								{ title: translate('actions'), id: 'actions' },
							]}
							rows={isListLoading ? [] : displayRemoteApps()}
							className='admin-table'
							isLoading={isListLoading}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
			{isRoomModalOpen && (
				<EditSectorModal
					onEditSector={() => {
						fetchTreeData();
						fetchRoomVisitors();
						setIsRoomModalOpen(false);
					}}
					onModalClose={() => setIsRoomModalOpen(false)}
					sectorData={currentRoom}
				/>
			)}
			<Modal
				display={isDeleteModalOpen}
				position='center'
				onModalSubmit={handleDeleteMember}
				primaryButtonLabel={translate('delete')}
				className='warning-delete-member'
				onModalClose={() => setIsDeleteModalOpen(false)}>
				<form>
					<h3>{translate('warning')}</h3>
					<p>{translate('areYouSureDeleteMember')}</p>
				</form>
			</Modal>
			<Modal
				display={isReversePTZCamModalOpen}
				isLoading={isReversePTZCamModalLoading}
				position='right'
				className='border-radius-modal-wrapper device-settings'
				onModalClose={() => setIsReversePTZCamModalOpen(false)}>
				<div className='configurable-settings-modal-inner'>
					<h3 className='configurable-settings-main-title'>{translate('ptzCamSettingsTitle')}</h3>
					<div className='flex flex-wrap'>
						<div className='flex cursor-pointer' onClick={handleReversePTZCameraOption}>
							<i className='material-icons-outlined'>switch_video</i>
							<p>{translate('reverseCamera')}</p>
							<div className='rounded-slider-switch'>
								<input type='checkbox' checked={isReverseCameraEnabled} onChange={() => null} />
								<span className='rounded-slider' />
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				isLoading={isAddDeviceModalLoading}
				display={isDevicesModalOpen}
				position='right'
				onModalClose={() => {
					setHelloDeviceId('');
					setIsDevicesModalOpen(prevState => !prevState);
				}}
				onModalSubmit={assignDevicesSubmit}
				isSubmitDisabled={!helloDeviceId}>
				<Form title={translate('roomDevice')}>
					<p className='label'>{translate('assignDevice')}</p>
					<p>{translate('selectSerialNumberAssociateWithRoom')}</p>
					<ReactSelect
						value={unassociatedDevicesToSelect.find(item => item.value === helloDeviceId) || {}}
						onChange={event => setHelloDeviceId(event.value)}
						options={unassociatedDevicesToSelect}
						placeholder={intl.formatMessage({ id: 'selectSerialNumber' })}
						classNamePrefix='react-select'
					/>
				</Form>
			</Modal>
			{isCompanionModalOpen && (
				<Companion
					isCompanionModalOpen={isCompanionModalOpen}
					isLoading={isAddDeviceModalLoading}
					setIsCompanionModalOpen={setIsCompanionModalOpen}
					roomName={currentRoom?.name}
					loadRoom={fetchCompanionDevicesRes}
				/>
			)}
			{!isAddPatientModalLoading && [UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()) && (
				<AssignPatient
					devices={devices}
					setDevices={setDevices}
					toggleAddPatientModal={() => {
						setIsAddPatientModalOpen(prevState => !prevState);
						setIsAddPatientModalLoading(false);
					}}
					loadRoom={() => {
						fetchRoomVisitors();
						fetchDevices();
					}}
					isAddPatientModalOpen={isAddPatientModalOpen}
					isLoading={false}
					isAddPatientModalLoading={isAddPatientModalLoading}
					setError={setError}
					roomType={currentRoom?.roomType}
					healthSystemId={userSession.healthSystem?.id}
				/>
			)}
			{visitorType && (
				<AssignFamilyMemberOrVisitor
					devices={devices}
					setDevices={setDevices}
					toggleAddFamilyMemberModal={() => setVisitorType(null)}
					sectorData={currentRoom}
					setError={setError}
					onSubmitVisitingHoursSucceed={() => {
						fetchRoomVisitors();
					}}
					visitorType={visitorType}
				/>
			)}
			<Modal
				modalSelector='deleteRoomModal'
				display={isDeleteOrgUnitModalOpen}
				position='center'
				primaryButtonLabel={translate('delete')}
				onModalSubmit={deleteOrgUnitSubmit}
				onModalClose={() => {
					setIsDeleteOrgUnitModalOpen(prevState => !prevState);
					setIsAddPatientModalLoading(false);
				}}
				primaryButtonLoading={isDeleteSectorLoading}>
				<Form title={translate('warning')} onSubmit={event => event.preventDefault()}>
					<p className='paragraph-break-word'>
						{translate('sureToDeleteOrg', {
							value: `${intl.formatMessage({ id: SectorTypes.ROOM })} ${currentRoom?.name}`,
						})}
					</p>
				</Form>
			</Modal>
			{isDeviceSettingsModalVisible && (
				<DeviceSettings
					isDeviceSettingsModalVisible={isDeviceSettingsModalVisible}
					toggleDeviceSettingsModal={() => setIsDeviceSettingsModalVisible(prevState => !prevState)}
					selectedDevice={devices[0]}
					currentRoom={currentRoom}
					setError={setError}
				/>
			)}
			{isDataAcquisitionOpen && (
				<ScheduleDataAcquisition
					isSetUpAvailabilityModalVisible={isDataAcquisitionOpen}
					toggleSetUpAvailability={() => setIsDataAcquisitionOpen(prevState => !prevState)}
					roomId={props.match.params.roomId}
					helloDeviceId={currentRoom?.helloDeviceId}
				/>
			)}
			<Alert display={error !== null} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
			{isVisitingHoursModalOpen && (
				<VisitingHoursConfiguration
					setIsVisitingHoursModalOpen={setIsVisitingHoursModalOpen}
					sector={currentRoom}
					onSubmit={fetchVisitingHours}
				/>
			)}
			{isNightVisionModalOpen && (
				<ScheduleNightVision
					onModalClose={() => setIsNightVisionModalOpen(false)}
					sector={currentRoom}
					fetchTreeData={fetchTreeData}
				/>
			)}
			{isAlexaSmartPropertiesOpen && (
				<AlexaSmartPropertiesWrapper
					sector={currentRoom}
					setIsAlexaSmartPropertiesOpen={setIsAlexaSmartPropertiesOpen}
					isAlexaSmartPropertiesOpen={isAlexaSmartPropertiesOpen}
				/>
			)}
		</div>
	);
};

export default Room;
