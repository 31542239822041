import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const assignWearable = async ({ patientId, iotDeviceVendorId, iotDeviceTypeId, hubDeviceId, iotDeviceId }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/organizations/${getCompanyId()}/patients/${patientId}/external-iots`, {
			iotDeviceVendorId,
			iotDeviceTypeId,
			hubDeviceId,
			iotDeviceId,
			status: true,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const unassignWearable = async (patientUserId, externalIot) => {
	try {
		const { data } = await gatewayApi.delete(
			`/v1/organizations/${getCompanyId()}/patients/${patientUserId}/external-iots/${externalIot}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getExternalDevices = async ({ patientId, status, pageIndex = 0, pageSize = 20 }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${getCompanyId()}/patients/${patientId}/external-iots?status=${status}&pageIndex=${pageIndex}&pageSize=${pageSize}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
