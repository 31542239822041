import * as React from 'react';

const Reassigned = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={16} height={18} fill='none' {...props}>
		<g stroke='currentColor' strokeLinecap='round' strokeWidth={1.5}>
			<path strokeLinejoin='round' d='m4 11-3 3 3 3' />
			<path d='M1 14h14' />
			<path strokeLinejoin='round' d='m12 7 3-3-3-3' />
			<path d='M15 4H1' />
		</g>
	</svg>
);
export default Reassigned;
