import { PatientNotes } from 'constants/action-types.js';

const initialState = {
	savedMode: {
		// [deviceId]: {
		// patientName: '',
		// patientAge: null,
		// nurseNameNumber: '',
		// preferredLanguage: [],
		// nurseAssistantNameNumber: '',
		// tlNameNumber: '',
		// primaryAdverseEvent: '',
		// primaryPatientCondition: '',
		// primaryPatientRiskFactor: '',
		// titleBarNotes: '',
		// additionalNotes: '',
		// notes: '',
		// isPatientOutOfRoom
		// draftsCount
		// nightVision
		// rails
		// nightVision,
		// rails,
		// getOutOfBed,
		// fallDetection,
		// patientMobility,
		// inactiveTime,
		// aiPrivacyMode,
		// handWashing,
		// ewsAiVitalSigns,
		// ivBagFluidLevel,
		// },
	},
};

export const patientNotes = (state = initialState, action) => {
	switch (action.type) {
		case PatientNotes.SET_PATIENT_DATA_SAVED: {
			return { ...state, savedMode: { ...state.savedMode, [action.deviceId]: action.payload } };
		}
		case PatientNotes.SET_PATIENT_DATA_BULK: {
			return { ...state, savedMode: { ...state.savedMode, ...action.payload } };
		}
		case PatientNotes.INCREASE_DRAFTS_COUNT: {
			return {
				...state,
				savedMode: {
					...state.savedMode,
					[action.deviceId]: {
						...state.savedMode[action.deviceId],
						draftsCount: state.savedMode[action.deviceId].draftsCount + 1,
					},
				},
			};
		}
		case PatientNotes.DECREASE_DRAFTS_COUNT: {
			return {
				...state,
				savedMode: {
					...state.savedMode,
					[action.deviceId]: {
						...state.savedMode[action.deviceId],
						draftsCount: state.savedMode[action.deviceId].draftsCount - 1,
					},
				},
			};
		}
		default:
			return state;
	}
};
