import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';
import translate from 'i18n-translations/translate.jsx';
import { checkUserEmailExists, getCountries } from 'api/users.js';
import { getCompanyId, loadUserInfo } from 'infrastructure/auth.js';
import { editPatientProfile, getPatientDetails, getPatientObservations, getPatientConditions } from 'api/patients.js';
import {
	AddEditPatientRadioTypes,
	AlertTypes,
	HdlCholesterolLimit,
	MeasurementUnitOptions,
	ObservationType,
	ConditionType,
	PatientHeightLimit,
	PatientProfileInfoTab,
	PatientWaistCircumferenceRangeLimit,
	PatientWeightLimit,
	TotalCholesterolLimit,
	UnitCategoryTypes,
	UnitSystemTypes,
} from 'constants/enums.js';
import { Allergies } from 'constants/visitEnums.js';
import {
	convertBloodGlucose,
	convertFeetToCm,
	convertHeight,
	convertImperialToMetric,
	convertInchToCm,
	convertToKg,
	convertWaistCircumference,
	convertWeight,
	getMeasurementValueBasedOnUnit,
	getPreferredUnit,
} from 'infrastructure/helpers/measurementsHelper.js';
import { tobaccoSmokeCodes } from 'constants/health-codes.js';
import { profileCreationMeasurementElements } from 'constants/measurements.js';
import { getStates } from 'api/doctorOnBoarding.js';
import { Loader, Button } from 'components/index.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import PatientPersonalInformation from 'components/AccountSettings/PatientProfile/PatientPersonalInformation.jsx';
import ContactInformation from 'components/AccountSettings/PatientProfile/ContactInformation.jsx';
import HealthInformation from 'components/AccountSettings/PatientProfile/HealthInformation.jsx';
import Location from 'components/AccountSettings/PatientProfile/Location.jsx';
import { useSelector } from 'react-redux';

const healthInformationStatusList = [
	{
		code: AddEditPatientRadioTypes.YES,
		name: translate('yes'),
		status: true,
	},
	{
		code: AddEditPatientRadioTypes.NO,
		name: translate('no'),
		status: false,
	},
	{
		code: AddEditPatientRadioTypes.NO_ANSWER,
		name: 'N/A',
		status: null,
	},
];

const PatientProfileInfo = () => {
	const intl = useIntl();
	const transformArray = array => array.map(item => ({ value: getValue(item), label: item.name }));
	const unitPreferences = useSelector(state => state.user.unitPreferences);

	const getValue = value =>
		value.code === AddEditPatientRadioTypes.NO_ANSWER ? AddEditPatientRadioTypes.NO_ANSWER : value.code || value.id;

	const companyId = getCompanyId();
	const [success, setSuccess] = useState(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [countries, setCountries] = useState([]);
	const [states, setStates] = useState([]);
	const [profilePicture, setProfilePicture] = useState('');
	const [patientProfile, setPatientProfile] = useState(null);
	const [foodAllergies, setFoodAllergies] = useState([]);
	const [medicationAllergies, setMedicationAllergies] = useState([]);
	const [environmentalAllergies, setEnvironmentalAllergies] = useState([]);
	const [biologicalAllergies, setBiologicalAllergies] = useState([]);
	const [patientDetails, setPatientDetails] = useState(null);
	const [tobaccoCode, setTobaccoCode] = useState(null);
	const [diabeticCode, setDiabeticCode] = useState(null);
	const [tabsExpanded, setTabsExpanded] = useState([
		{ id: 0, isExpanded: false },
		{ id: 1, isExpanded: false },
		{ id: 2, isExpanded: false },
		{ id: 3, isExpanded: false },
		{ id: 4, isExpanded: false },
		{ id: 5, isExpanded: false },
	]);

	const toggleProfileInfoTabs = id => {
		const expandedTabs = [...tabsExpanded];
		const expandedTab = expandedTabs.find(tab => tab.id === id);
		expandedTab.isExpanded = !expandedTab.isExpanded;
		setTabsExpanded(expandedTabs);
	};

	const profileInfoTabs = [
		{
			id: PatientProfileInfoTab.PERSONAL_INFORMATION,
			title: intl.formatMessage({ id: 'personalInformation' }),
			description: intl.formatMessage({ id: 'updateYourPersonalInformation' }),
		},
		{
			id: PatientProfileInfoTab.ADDRESS,
			title: intl.formatMessage({ id: 'address' }),
			description: intl.formatMessage({ id: 'updateYourAddress' }),
		},
		{
			id: PatientProfileInfoTab.CONTACT_INFORMATION,
			title: intl.formatMessage({ id: 'contactInformation' }),
			description: intl.formatMessage({ id: 'updateYourContactInformation' }),
		},
		{
			id: PatientProfileInfoTab.HEALTH_INFORMATION,
			title: intl.formatMessage({ id: 'healthInformation' }),
			description: intl.formatMessage({ id: 'updateYourHealthInformation' }),
		},
	];

	useEffect(() => {
		getProfileInformationDetails();
	}, []);

	useEffect(() => {
		let timeout;
		if (success || error) {
			timeout = setTimeout(() => {
				closeResponseAlert();
			}, 2000);
		}
		return () => clearTimeout(timeout);
	}, [success, error]);

	const getPatientObservation = async patientId => {
		const response = await getPatientObservations(patientId, ObservationType.TOBACCO_SMOKE);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		if (response.observations.length > 0) {
			setTobaccoCode(response.observations[0].code);
		}
	};

	const getPatientCondition = async patientId => {
		const response = await getPatientConditions(patientId, ConditionType.DIABETES);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		if (response.conditions.length > 0) {
			setDiabeticCode(response.conditions[0].code);
		}
	};

	const getProfileInformationDetails = async () => {
		const [countriesList, statesList, patientDetailsRes] = await Promise.all([getCountries(), getStates(), getPatientDetails()]);
		const responseError = countriesList.error || statesList.error || patientDetailsRes?.error;
		if (responseError) {
			setError(responseError.message);
		} else {
			setCountries(countriesList.countries);
			setStates(statesList.states);
			setPatientDetails(patientDetailsRes.patient);
			setPatientProfile(patientDetailsRes.patient?.profile);
			setProfilePicture(patientDetailsRes.patient?.profile?.profilePicture);
			getPatientObservation(patientDetailsRes.patient?.healthcareUserId);
			getPatientCondition(patientDetailsRes.patient?.healthcareUserId);
			patientDetailsRes.patient?.profile?.allergies?.forEach(allergy => {
				if (allergy.categoryId === Allergies.FOOD.id && allergy.note) {
					setFoodAllergies(JSON.parse(allergy.note));
				}
				if (allergy.categoryId === Allergies.MEDICATION.id && allergy.note) {
					setMedicationAllergies(JSON.parse(allergy.note));
				}
				if (allergy.categoryId === Allergies.ENVIRONMENTAL.id && allergy.note) {
					setEnvironmentalAllergies(JSON.parse(allergy.note));
				}
				if (allergy.categoryId === Allergies.BIOLOGICAL.id && allergy.note) {
					setBiologicalAllergies(JSON.parse(allergy.note));
				}
			});
		}
		setIsLoading(false);
	};

	const getSelectedTab = type => {
		return tabsExpanded.find(x => x.id === type).isExpanded;
	};

	const closeResponseAlert = () => {
		setError(null);
		setSuccess(null);
	};

	const getConvertedValueForSubmit = (unit, defaultUnit, value, convertFunction) =>
		unit === defaultUnit || !value ? value : convertFunction;

	const getConditionalHealthInformationProperties = (
		initialValues,
		values,
		tobaccoSmokeCode,
		showTobaccoSmokeCode,
		allergies
	) => ({
		isTobaccoSmoker: parseInt(values.isTobaccoSmoker, 10),
		hasDiabet: parseInt(values.hasDiabet, 10),
		hasHyperTension: parseInt(values.hasHyperTension, 10),
		hasAllergy: parseInt(values.hasAllergy, 10),
		hasPreExistingCondition: parseInt(values.hasPreExistingCondition, 10),
		isTakingMedication: parseInt(values.isTakingMedication, 10),
		...(values.height && {
			height: getConvertedValueForSubmit(
				values.heightUnit,
				MeasurementUnitOptions.HEIGHT.value[1]?.abbr,
				parseInt(values.height),
				convertFeetToCm(parseInt(values.height, 10))
			)
				.toFixed(2)
				.toString(),
		}),
		heightUnit: getPreferredUnit('HEIGHT', 'HEIGHT', unitPreferences).abbr,
		...(values.weight && {
			weight: getConvertedValueForSubmit(
				values.weightUnit,
				MeasurementUnitOptions.WEIGHT.value[1]?.abbr,
				parseFloat(values.weight),
				convertToKg(parseFloat(values.weight))
			)
				.toFixed(2)
				.toString(),
		}),
		weightUnit: getPreferredUnit('WEIGHT', 'WEIGHT', unitPreferences)?.abbr,
		...(initialValues.totalCholesterol.toString() !== values.totalCholesterol.toString() && {
			totalCholesterol: getConvertedValueForSubmit(
				values.totalCholesterolUnit,
				MeasurementUnitOptions.BLOOD_GLUCOSE.value[1]?.abbr,
				parseFloat(values.totalCholesterol),
				convertImperialToMetric(UnitCategoryTypes.BLOOD_GLUCOSE, parseFloat(values.totalCholesterol))
			).toString(),
		}),
		...(initialValues.totalCholesterol.toString() !== values.totalCholesterol.toString() && {
			totalCholesterolUnit: getPreferredUnit('BLOOD_GLUCOSE', 'BLOOD_GLUCOSE', unitPreferences)?.abbr,
		}),
		...(initialValues.hdlCholesterol.toString() !== values.hdlCholesterol.toString() && {
			hdlCholesterol: getConvertedValueForSubmit(
				values.hdlCholesterolUnit,
				MeasurementUnitOptions.BLOOD_GLUCOSE.value[1]?.abbr,
				parseFloat(values.hdlCholesterol),
				convertImperialToMetric(UnitCategoryTypes.BLOOD_GLUCOSE, parseFloat(values.hdlCholesterol))
			).toString(),
		}),
		...(initialValues.hdlCholesterol.toString() !== values.hdlCholesterol.toString() && {
			hdlCholesterolUnit: getPreferredUnit('BLOOD_GLUCOSE', 'BLOOD_GLUCOSE', unitPreferences)?.abbr,
		}),
		...(initialValues.waistCircumference.toString() !== values.waistCircumference.toString() && {
			waistCircumference: getConvertedValueForSubmit(
				values.waistCircumferenceUnit,
				MeasurementUnitOptions.WAIST_CIRCUMFERENCE.value[1]?.abbr,
				parseFloat(values.waistCircumference),
				convertInchToCm(parseFloat(values.waistCircumference))
			).toString(),
		}),
		...(initialValues.waistCircumference.toString() !== values.waistCircumference.toString() && {
			waistCircumferenceUnit: getPreferredUnit('WAIST_CIRCUMFERENCE', 'HEIGHT', unitPreferences)?.abbr,
		}),
		...(showTobaccoSmokeCode && {
			tobaccoSmokeCode,
		}),
		...((initialValues.diabeticStatusCode.toString() !== values.diabeticStatusCode.toString() ||
			initialValues.hasDiabet.toString() !== values.hasDiabet.toString()) && {
			diabeticStatusCode: parseInt(values.hasDiabet, 10) === AddEditPatientRadioTypes.YES ? values.diabeticStatusCode : '',
		}),
		allergies,
		preExistingCondition:
			parseInt(values.hasPreExistingCondition, 10) === AddEditPatientRadioTypes.YES ? values.preExistingCondition?.trim() : '',
	});

	const onSubmitForm = async (values, initialValues) => {
		setError(null);
		let tobaccoSmokeCode = '';
		let showTobaccoSmokeCode = false;
		if (parseInt(values.isTobaccoSmoker, 10) !== parseInt(initialValues.isTobaccoSmoker, 10)) {
			showTobaccoSmokeCode = true;
		}
		if (parseInt(values.isTobaccoSmoker, 10) === AddEditPatientRadioTypes.YES) {
			tobaccoSmokeCode = values.tobaccoYesCode;
			if (initialValues.tobaccoYesCode !== values.tobaccoYesCode) {
				showTobaccoSmokeCode = true;
			}
		}
		if (parseInt(values.isTobaccoSmoker, 10) === AddEditPatientRadioTypes.NO) {
			tobaccoSmokeCode = values.tobaccoNoCode;
			if (initialValues.tobaccoNoCode !== values.tobaccoNoCode) {
				showTobaccoSmokeCode = true;
			}
		}
		if (parseInt(values.isTobaccoSmoker, 10) === AddEditPatientRadioTypes.NO_ANSWER) {
			tobaccoSmokeCode = '';
		}
		const allergies = [];
		if (values.allergies?.length > 0) {
			values.allergies.forEach(element => {
				let note = '';
				if (parseInt(element, 10) === Allergies.FOOD.id && foodAllergies.length > 0) {
					const foodArr = [...foodAllergies];
					note = JSON.stringify(foodArr);
				}
				if (parseInt(element, 10) === Allergies.MEDICATION.id && medicationAllergies.length > 0) {
					const medicArr = [...medicationAllergies];
					note = JSON.stringify(medicArr);
				}
				if (parseInt(element, 10) === Allergies.ENVIRONMENTAL.id && environmentalAllergies.length > 0) {
					const environmentalArr = [...environmentalAllergies];
					note = JSON.stringify(environmentalArr);
				}
				if (parseInt(element, 10) === Allergies.BIOLOGICAL.id && biologicalAllergies.length > 0) {
					const biologicalArr = [...biologicalAllergies];
					note = JSON.stringify(biologicalArr);
				}
				allergies.push({
					categoryId: parseInt(element, 10),
					note,
				});
			});
		}

		const params = {
			companyId,
			patientUserId: patientDetails.userId,
			id: patientDetails.id,
			active: true,
			idCard: values.idNumber.toString(),
			firstName: values.firstName.trim(),
			lastName: values.lastName.trim(),
			address: values.address,
			phoneNumber: values.phoneNumber?.toString(),
			email: values.emailAddress,
			genderId: parseInt(values.genderId, 10),
			birthDate: values.birthDate,
			showConsent: values.showConsent,
			stateCode: values?.country === 'US' ? values.stateCode : '',
			healthInformation: getConditionalHealthInformationProperties(
				initialValues,
				values,
				tobaccoSmokeCode,
				showTobaccoSmokeCode,
				allergies
			),
			postalCode: values.zipCode?.toString(),
			city: values?.city,
			country: values?.country,
			communicationLanguage: '',
			profilePicture,
		};
		const response = await editPatientProfile(params);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setSuccess(intl.formatMessage({ id: 'changesSaved' }));
		getProfileInformationDetails();
		loadUserInfo();
	};

	const validateEmail = async val => {
		if (!val || val === patientProfile.email) {
			return true;
		}
		let emailExists = false;
		const schema = Yup.string().email().required();
		if (await schema.isValid(val)) {
			const response = await checkUserEmailExists(val);
			if (response.error) {
				setError(response.error.message);
				emailExists = false;
			} else {
				emailExists = response;
			}
		}
		return !emailExists;
	};

	const getInitialValues = () => {
		if (patientProfile) {
			let tobaccoYesCode = '';
			let tobaccoNoCode = '';
			const tobaccoCodes = [...tobaccoSmokeCodes];
			const found = tobaccoCodes.find(item => item.code === tobaccoCode);
			if (tobaccoCode) {
				if (found.name === 'tobaccoYesCode') {
					tobaccoYesCode = found.code;
				}
				if (found.name === 'tobaccoNoCode') {
					tobaccoNoCode = found.code;
				}
			}

			return {
				firstName: patientProfile.firstName,
				lastName: patientProfile.lastName,
				idNumber: patientProfile.idCard,
				address: patientProfile.address,
				birthDate: patientProfile.birthDate ? moment(patientProfile.birthDate).format('YYYY-MM-DD') : '',
				emailAddress: patientProfile.email.includes('@hello-health.com') ? '' : patientProfile.email,
				phoneNumber: patientProfile.phoneNumber,
				showConsent: patientProfile.showConsent,
				genderId: patientProfile.genderId,
				height: patientProfile.height
					? getMeasurementValueBasedOnUnit(
							parseFloat(patientProfile.height),
							convertHeight(parseFloat(patientProfile.height), UnitSystemTypes.IMPERIAL).toFixed(2).toString(),
							profileCreationMeasurementElements.height,
							unitPreferences
						)
					: '',
				heightUnit: getPreferredUnit('HEIGHT', 'HEIGHT', unitPreferences)?.abbr,
				weight: patientProfile.weight
					? getMeasurementValueBasedOnUnit(
							parseFloat(patientProfile.weight),
							convertWeight(parseFloat(patientProfile.weight), UnitSystemTypes.IMPERIAL).toFixed(2).toString(),
							profileCreationMeasurementElements.weight,
							unitPreferences
						)
					: '',
				weightUnit: getPreferredUnit('WEIGHT', 'WEIGHT', unitPreferences)?.abbr,
				isTobaccoSmoker:
					patientProfile.isTobaccoSmoker || patientProfile.isTobaccoSmoker === false
						? healthInformationStatusList.find(item => item.status === patientProfile.isTobaccoSmoker)?.code.toString()
						: AddEditPatientRadioTypes.NO_ANSWER.toString(),
				hasHyperTension:
					patientProfile.hasHyperTension || patientProfile.hasHyperTension === false
						? healthInformationStatusList.find(item => item.status === patientProfile.hasHyperTension)?.code.toString()
						: AddEditPatientRadioTypes.NO_ANSWER.toString(),
				isTakingMedication:
					patientProfile.isTakingMedication || patientProfile.isTakingMedication === false
						? healthInformationStatusList.find(item => item.status === patientProfile.isTakingMedication)?.code.toString()
						: AddEditPatientRadioTypes.NO_ANSWER?.toString(),
				hasDiabet:
					patientProfile.hasDiabet || patientProfile.hasDiabet === false
						? healthInformationStatusList.find(item => item.status === patientProfile.hasDiabet)?.code.toString()
						: AddEditPatientRadioTypes.NO_ANSWER?.toString(),
				hasPreExistingCondition:
					patientProfile.hasPreExistingCondition || patientProfile.hasPreExistingCondition === false
						? healthInformationStatusList.find(item => item.status === patientProfile.hasPreExistingCondition)?.code.toString()
						: AddEditPatientRadioTypes.NO_ANSWER.toString(),
				hasAllergy:
					patientProfile.hasAllergy || patientProfile.hasAllergy === false
						? healthInformationStatusList.find(item => item.status === patientProfile.hasAllergy)?.code.toString()
						: AddEditPatientRadioTypes.NO_ANSWER.toString(),
				tobaccoYesCode,
				tobaccoNoCode,
				diabeticStatusCode: diabeticCode || '',
				totalCholesterol: patientProfile.totalCholesterol
					? getMeasurementValueBasedOnUnit(
							parseFloat(patientProfile.totalCholesterol),
							convertBloodGlucose(parseFloat(patientProfile.totalCholesterol), UnitSystemTypes.IMPERIAL).toString(),
							profileCreationMeasurementElements.cholesterol,
							unitPreferences
						)
					: '',
				totalCholesterolUnit: getPreferredUnit('BLOOD_GLUCOSE', 'BLOOD_GLUCOSE', unitPreferences)?.abbr,
				hdlCholesterol: patientProfile.hdlCholesterol
					? getMeasurementValueBasedOnUnit(
							parseFloat(patientProfile.hdlCholesterol),
							convertBloodGlucose(parseFloat(patientProfile.hdlCholesterol), UnitSystemTypes.IMPERIAL).toString(),
							profileCreationMeasurementElements.cholesterol,
							unitPreferences
						)
					: '',
				hdlCholesterolUnit: getPreferredUnit('BLOOD_GLUCOSE', 'BLOOD_GLUCOSE', unitPreferences)?.abbr,
				waistCircumference: patientProfile.waistCircumference
					? getMeasurementValueBasedOnUnit(
							parseFloat(patientProfile.waistCircumference),
							convertWaistCircumference(parseFloat(patientProfile.waistCircumference), UnitSystemTypes.IMPERIAL).toString(),
							profileCreationMeasurementElements.waist_circumference,
							unitPreferences
						)
					: '',
				waistCircumferenceUnit: getPreferredUnit('WAIST_CIRCUMFERENCE', 'HEIGHT', unitPreferences)?.abbr,
				allergies: patientProfile.allergies?.map(allergy => allergy.categoryId.toString()) ?? [],
				country: patientProfile?.country,
				city: patientProfile?.city,
				zipCode: patientProfile?.postalCode,
				preExistingCondition: patientProfile?.preExistingCondition,
				foodAllergy: '',
				medicationAllergy: '',
				environmentalAllergy: '',
				biologicalAllergy: '',
				stateCode: patientProfile?.country === 'US' ? patientProfile?.stateCode : '',
			};
		}
		return {};
	};

	const getValidationSchema = () =>
		Yup.object().shape({
			firstName: Yup.string()
				.required(intl.formatMessage({ id: 'firstNameCannotBeEmpty' }))
				.matches(/^\D+$/, `${intl.formatMessage({ id: 'firstNameNonNumber' })}`)
				.max(30, `${intl.formatMessage({ id: 'maxLengthIs' })} 30`)
				.trim(),
			lastName: Yup.string()
				.required(intl.formatMessage({ id: 'lastNameCannotBeEmpty' }))
				.matches(/^\D+$/, `${intl.formatMessage({ id: 'lastNameNonNumber' })}`)
				.max(30, `${intl.formatMessage({ id: 'maxLengthIs' })} 30`)
				.trim(),
			idNumber: Yup.string()
				.required(intl.formatMessage({ id: 'idNumberCannotBeEmpty' }))
				.min(
					10,
					intl.formatMessage({
						id: 'idNumberLength',
					})
				)
				.max(
					10,
					intl.formatMessage({
						id: 'idNumberLength',
					})
				)
				.trim(),
			address: Yup.string()
				.max(100, `${intl.formatMessage({ id: 'maxLengthIs' })} 100`)
				.trim(),
			stateCode: Yup.string()
				.nullable()
				.when('country', {
					is: 'US',
					then: () => Yup.string().required(intl.formatMessage({ id: 'stateRequired' })),
				}),
			birthDate: Yup.string()
				.required(intl.formatMessage({ id: 'pleaseWriteDateOfBirth' }))
				.test('dateOfBirth', intl.formatMessage({ id: 'dateOfBirthNotValid' }), value =>
					moment(value).isBetween(moment().subtract(120, 'years'), moment())
				),
			emailAddress: Yup.string()
				.max(100, `${intl.formatMessage({ id: 'maxLengthIs' })} 100`)
				.email(intl.formatMessage({ id: 'invalidEmail' }))
				.test('existing-email', intl.formatMessage({ id: 'userAlreadyExists' }), val => validateEmail(val))
				.trim(),
			phoneNumber: Yup.string()
				.max(20, `${intl.formatMessage({ id: 'maxLengthIs' })} 20`)
				.trim(),
			genderId: Yup.string().required(intl.formatMessage({ id: 'pleaseSelectSex' })),
			height: Yup.number()
				.required(intl.formatMessage({ id: 'pleaseWriteHeight' }))
				.when('heightUnit', {
					is: MeasurementUnitOptions.HEIGHT.value[1]?.abbr,
					then: () =>
						Yup.number()
							.min(
								PatientHeightLimit.CENTIMETER.MIN,
								`${intl.formatMessage({ id: 'minHeightIs' })} ${PatientHeightLimit.CENTIMETER.MIN} cm`
							)
							.max(
								PatientHeightLimit.CENTIMETER.MAX,
								`${intl.formatMessage({ id: 'maxHeightIs' })} ${PatientHeightLimit.CENTIMETER.MAX} cm`
							),
				})
				.when('heightUnit', {
					is: MeasurementUnitOptions.HEIGHT.value[2]?.abbr,
					then: () =>
						Yup.number()
							.min(PatientHeightLimit.FEET.MIN, `${intl.formatMessage({ id: 'minHeightIs' })} ${PatientHeightLimit.FEET.MIN} ft`)
							.max(PatientHeightLimit.FEET.MAX, `${intl.formatMessage({ id: 'maxHeightIs' })} ${PatientHeightLimit.FEET.MAX} ft`),
				}),
			weight: Yup.number()
				.required(intl.formatMessage({ id: 'pleaseWriteWeight' }))
				.when('weightUnit', {
					is: MeasurementUnitOptions.WEIGHT.value[1]?.abbr,
					then: () =>
						Yup.number()
							.min(
								PatientWeightLimit.KILOGRAMS.MIN,
								`${intl.formatMessage({ id: 'minWeightIs' })} ${PatientWeightLimit.KILOGRAMS.MIN} kg`
							)
							.max(
								PatientWeightLimit.KILOGRAMS.MAX,
								`${intl.formatMessage({ id: 'maxWeightIs' })} ${PatientWeightLimit.KILOGRAMS.MAX} kg`
							),
				})
				.when('weightUnit', {
					is: MeasurementUnitOptions.WEIGHT.value[2]?.abbr,
					then: () =>
						Yup.number()
							.min(PatientWeightLimit.LBS.MIN, `${intl.formatMessage({ id: 'minWeightIs' })} ${PatientWeightLimit.LBS.MIN} lbs`)
							.max(PatientWeightLimit.LBS.MAX, `${intl.formatMessage({ id: 'maxWeightIs' })} ${PatientWeightLimit.LBS.MAX} lbs`),
				}),
			totalCholesterol: Yup.number()
				.when('totalCholesterolUnit', {
					is: MeasurementUnitOptions.BLOOD_GLUCOSE.value[1]?.abbr,
					then: () =>
						Yup.number()
							.min(
								TotalCholesterolLimit.MMOLL.MIN,
								`${intl.formatMessage({ id: 'minTotalCholesterolIs' })} ${TotalCholesterolLimit.MMOLL.MIN} mmol/L`
							)
							.max(
								TotalCholesterolLimit.MMOLL.MAX,
								`${intl.formatMessage({ id: 'maxTotalCholesterolIs' })} ${TotalCholesterolLimit.MMOLL.MAX} mmol/L`
							),
				})
				.when('totalCholesterolUnit', {
					is: MeasurementUnitOptions.BLOOD_GLUCOSE.value[2]?.abbr,
					then: () =>
						Yup.number()
							.min(
								TotalCholesterolLimit.MGDL.MIN,
								`${intl.formatMessage({ id: 'minTotalCholesterolIs' })} ${TotalCholesterolLimit.MGDL.MIN} mg/dL`
							)
							.max(
								TotalCholesterolLimit.MGDL.MAX,
								`${intl.formatMessage({ id: 'maxTotalCholesterolIs' })} ${TotalCholesterolLimit.MGDL.MAX} mg/dL`
							),
				}),
			hdlCholesterol: Yup.number()
				.when('hdlCholesterolUnit', {
					is: MeasurementUnitOptions.BLOOD_GLUCOSE.value[1]?.abbr,
					then: () =>
						Yup.number()
							.min(
								HdlCholesterolLimit.MMOLL.MIN,
								`${intl.formatMessage({ id: 'minHdlCholesterolIs' })} ${HdlCholesterolLimit.MMOLL.MIN} mmol/L`
							)
							.max(
								HdlCholesterolLimit.MMOLL.MAX,
								`${intl.formatMessage({ id: 'maxHdlCholesterolIs' })} ${HdlCholesterolLimit.MMOLL.MAX} mmol/L`
							),
				})
				.when('hdlCholesterolUnit', {
					is: MeasurementUnitOptions.BLOOD_GLUCOSE.value[2]?.abbr,
					then: () =>
						Yup.number()
							.min(
								HdlCholesterolLimit.MGDL.MIN,
								`${intl.formatMessage({ id: 'minHdlCholesterolIs' })} ${HdlCholesterolLimit.MGDL.MIN} mg/DL`
							)
							.max(
								HdlCholesterolLimit.MGDL.MAX,
								`${intl.formatMessage({ id: 'maxHdlCholesterolIs' })} ${HdlCholesterolLimit.MGDL.MAX} mg/DL`
							),
				}),
			waistCircumference: Yup.number()
				.when('waistCircumferenceUnit', {
					is: MeasurementUnitOptions.WAIST_CIRCUMFERENCE.value[1]?.abbr,
					then: () =>
						Yup.number()
							.min(
								PatientWaistCircumferenceRangeLimit.CM.MIN,
								`${intl.formatMessage({ id: 'minWaistCircumferenceIs' })} ${PatientWaistCircumferenceRangeLimit.CM.MIN} cm`
							)
							.max(
								PatientWaistCircumferenceRangeLimit.CM.MAX,
								`${intl.formatMessage({ id: 'maxWaistCircumferenceIs' })} ${PatientWaistCircumferenceRangeLimit.CM.MAX} cm`
							),
				})
				.when('waistCircumferenceUnit', {
					is: MeasurementUnitOptions.WAIST_CIRCUMFERENCE.value[2]?.abbr,
					then: () =>
						Yup.number()
							.min(
								PatientWaistCircumferenceRangeLimit.INCH.MIN,
								`${intl.formatMessage({ id: 'minWaistCircumferenceIs' })} ${PatientWaistCircumferenceRangeLimit.INCH.MIN} inch`
							)
							.max(
								PatientWaistCircumferenceRangeLimit.INCH.MAX,
								`${intl.formatMessage({ id: 'maxWaistCircumferenceIs' })} ${PatientWaistCircumferenceRangeLimit.INCH.MAX} inch`
							),
				}),
			tobaccoYesCode: Yup.string().when('isTobaccoSmoker', {
				is: AddEditPatientRadioTypes.YES.toString(),
				then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectOneOfTheOptionsAbove' })),
			}),
			tobaccoNoCode: Yup.string().when('isTobaccoSmoker', {
				is: AddEditPatientRadioTypes.NO.toString(),
				then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectOneOfTheOptionsAbove' })),
			}),
			diabeticStatusCode: Yup.string().when('hasDiabet', {
				is: AddEditPatientRadioTypes.YES.toString(),
				then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectOneOfTheOptionsAbove' })),
			}),
			allergies: Yup.array().when('hasAllergy', {
				is: AddEditPatientRadioTypes.YES.toString(),
				then: () => Yup.array().min(1, intl.formatMessage({ id: 'pleaseSelectOneOfTheOptionsAbove' })),
			}),
			preExistingCondition: Yup.string().when('hasPreExistingCondition', {
				is: AddEditPatientRadioTypes.YES.toString(),
				then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseWritePreExistingCondition' })),
			}),
		});

	return (
		<div className='account-setting-tab'>
			{isLoading && (
				<div className='full-height'>
					<Loader />
				</div>
			)}
			{!isLoading && (
				<Formik
					enableReinitialize={true}
					validateOnBlur={true}
					validateOnMount={true}
					initialValues={getInitialValues()}
					onSubmit={values => onSubmitForm(values, getInitialValues())}
					validationSchema={getValidationSchema()}>
					{formikProps => {
						const { values, handleChange, setFieldValue, handleBlur, errors, handleSubmit, isSubmitting, setErrors } =
							formikProps;
						return (
							<>
								{profileInfoTabs.map(item => (
									<Fragment key={item.id}>
										<div
											className='flex flex-align-center cursor-pointer  as-sub-tab-button'
											onClick={() => toggleProfileInfoTabs(item.id)}>
											<div className='flex column-direction full-width'>
												<span>{item.title}</span>
												<span>{item.description}</span>
											</div>
											<i className='material-icons-outlined'>
												{tabsExpanded.find(x => x.id === item.id).isExpanded ? 'chevron_right' : 'expand_more'}
											</i>
										</div>
										{getSelectedTab(PatientProfileInfoTab.PERSONAL_INFORMATION) &&
											item.id === PatientProfileInfoTab.PERSONAL_INFORMATION && (
												<PatientPersonalInformation
													formikProps={formikProps}
													values={values}
													errors={errors}
													onChange={handleChange}
													setFieldValue={setFieldValue}
													transformArray={transformArray}
													profilePicture={profilePicture}
													setProfilePicture={setProfilePicture}
													setError={setError}
												/>
											)}
										{getSelectedTab(PatientProfileInfoTab.ADDRESS) && item.id === PatientProfileInfoTab.ADDRESS && (
											<Location
												transformArray={transformArray}
												formikProps={formikProps}
												values={values}
												errors={errors}
												countries={countries}
												states={states}
												onChange={handleChange}
											/>
										)}
										{getSelectedTab(PatientProfileInfoTab.CONTACT_INFORMATION) &&
											item.id === PatientProfileInfoTab.CONTACT_INFORMATION && (
												<ContactInformation onChange={handleChange} values={values} errors={errors} />
											)}
										{getSelectedTab(PatientProfileInfoTab.HEALTH_INFORMATION) &&
											item.id === PatientProfileInfoTab.HEALTH_INFORMATION && (
												<HealthInformation
													values={values}
													errors={errors}
													formikProps={formikProps}
													onChange={handleChange}
													setFieldValue={setFieldValue}
													setErrors={setErrors}
													onBlur={handleBlur}
													foodAllergies={foodAllergies}
													medicationAllergies={medicationAllergies}
													environmentalAllergies={environmentalAllergies}
													biologicalAllergies={biologicalAllergies}
													setFoodAllergies={setFoodAllergies}
													setMedicationAllergies={setMedicationAllergies}
													setEnvironmentalAllergies={setEnvironmentalAllergies}
													setBiologicalAllergies={setBiologicalAllergies}
													transformArray={transformArray}
													healthInformationStatusList={healthInformationStatusList}
												/>
											)}
									</Fragment>
								))}
								<Button
									isDisabled={!formikProps.isValid}
									isLoading={isSubmitting}
									className='flex margin-top-l margin-left-auto'
									onClick={() => handleSubmit()}
									text={translate('saveChanges')}
								/>
								<PopUpAlert
									alertType={success ? AlertTypes.SUCCESS : AlertTypes.DANGER}
									display={error || success}
									onAlertClose={closeResponseAlert}
									contentText={success ? intl.formatMessage({ id: 'changesSaved' }) : error}
									isSilent={true}
									center={true}
								/>
							</>
						);
					}}
				</Formik>
			)}
		</div>
	);
};

export default PatientProfileInfo;
