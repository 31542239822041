import * as React from 'react';

const HeartIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
		<path
			stroke='#FA6A5C'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M8 14.356C-5.876 6.702 3.837-1.654 8 3.6c4.162-5.254 13.875 3.102 0 10.756Z'
		/>
	</svg>
);
export default HeartIcon;
