import { PatientActionTypes } from 'constants/action-types.js';
import { DeviceAvailability, ParticipantState } from 'constants/enums.js';

const initialState = {
	patients: [],
	selectedPatient: null,
	fullname: '',
	hasRpmProgram: false,
	selectedPatientDevice: null,
};

export const myPatients = (state = initialState, action) => {
	switch (action.type) {
		case PatientActionTypes.FETCH_MY_PATIENTS_SUCCESS:
			return {
				...state,
				patients: action.payload,
				selectedPatient: state.selectedPatient || action.payload[0],
			};
		case PatientActionTypes.SET_SELECTED_PATIENT:
			return {
				...state,
				selectedPatient: action.payload,
			};
		case PatientActionTypes.SET_HAS_RPM_PROGRAM:
			return {
				...state,
				hasRpmProgram: action.payload,
			};
		case PatientActionTypes.UPDATE_DEVICE_CALL_STATUS:
			return updatePatientDeviceCallStatus(state, action.payload);
		case PatientActionTypes.UPDATE_DEVICE_STATUS:
			return updatePatientDeviceStatus(state, action.payload);
		case PatientActionTypes.UPDATE_DEVICE_PRIVACY_STATUS:
			return updateDevicePrivacyStatus(state, action.payload);
		case PatientActionTypes.SET_SELECTED_PATIENT_DEVICE:
			return {
				...state,
				selectedPatientDevice: action.payload,
			};
		default:
			return state;
	}
};

const updatePatientDeviceCallStatus = (state, payload) => {
	const newPatients = [...state.patients];
	const selectedPatient = newPatients.find(
		patient => patient.devicesInfos.length > 0 && patient.devicesInfos.find(device => device.id === payload.deviceId)
	);
	if (selectedPatient) {
		const selectedDevice = selectedPatient.devicesInfos.find(device => device.id === payload.deviceId);
		selectedDevice.state.conference =
			payload.activeConferences.length > 0 ? ParticipantState.CONNECTED.type : ParticipantState.CONNECTING.type;
	}
	return { ...state, patients: newPatients };
};

const updatePatientDeviceStatus = (state, payload) => {
	const newPatients = [...state.patients];
	const selectedPatient = newPatients.find(
		patient => patient.devicesInfos.length > 0 && patient.devicesInfos.find(device => device.id === payload.deviceId)
	);
	if (selectedPatient) {
		const selectedDevice = selectedPatient.devicesInfos.find(device => device.id === payload.deviceId);
		selectedDevice.state.availability = payload.isOlinne > 0 ? DeviceAvailability.ONLINE : DeviceAvailability.OFFLINE;
	}
	return { ...state, patients: newPatients };
};

const updateDevicePrivacyStatus = (state, payload) => {
	const newPatients = [...state.patients];
	const selectedPatient = newPatients.find(
		patient => patient.devicesInfos.length > 0 && patient.devicesInfos.find(device => device.id === payload.deviceId)
	);
	if (selectedPatient) {
		const selectedDevice = selectedPatient.devicesInfos.find(device => device.id === payload.deviceId);
		selectedDevice.aiPrivacyStatus = payload.aiPrivacyStatus;
	}
	return { ...state, patients: newPatients };
};
