import classNames from 'classnames';

const CustomTextarea = props => (
	<div className='textarea-wrapper'>
		{props.label && <label>{props.label}</label>}
		<div className='position-relative'>
			<textarea
				maxLength={props.maxLength}
				name={props.name}
				id={props.id}
				onChange={props.onChange}
				value={props.value}
				rows={props.rows}
				onBlur={props.onBlur}
				className={props.className}
				placeholder={props.placeholder}
				disabled={props.disabled}
			/>
			<div className='position-absolute flex-end flex-align-center footer-textarea'>
				{props.charactersCounter && (
					<>
						{!props.value && (
							<p className={classNames('characters-counter', props.maxLength - 0 <= props.maxNumberLimit ? 'red-color' : '')}>
								0 / {props.maxLength}
							</p>
						)}
						{props.value && (
							<p
								className={classNames(
									'characters-counter',
									props.maxLength - props.value.length <= props.maxNumberLimit ? 'red-color' : ''
								)}>
								{props.value.length ?? 0} / {props.maxLength}
							</p>
						)}
					</>
				)}
				<div className='flex flex-align-center'>
					{props.icon && <div className='mic-icon'>{props.icon}</div>}
					{props.saveBtn && <div className='save-button'>{props.saveBtn}</div>}
				</div>
			</div>
		</div>
		{props.error && (props.touched || props.validateOnSubmit) && (
			<span className='red-error red-error create-app-error'>{props.error}</span>
		)}
	</div>
);

export default CustomTextarea;
