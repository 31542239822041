import * as React from 'react';
const Surveys = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} viewBox='0 0 22 24' fill='none' {...props}>
		<path stroke='currentColor' strokeWidth={1.5} d='M9 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-2' />
		<rect width={6} height={2} x={9} y={2} stroke='currentColor' strokeWidth={1.5} rx={1} />
		<rect width={3} height={3} x={8} y={8} stroke='currentColor' strokeWidth={1.5} rx={1} />
		<path stroke='currentColor' strokeWidth={1.5} d='M13 9.5h3.5' />
		<rect width={3} height={3} x={8} y={14} stroke='currentColor' strokeWidth={1.5} rx={1} />
		<path stroke='currentColor' strokeWidth={1.5} d='M13 15.5h3.5' />
	</svg>
);
export default Surveys;
