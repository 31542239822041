import PropTypes from 'prop-types';
import DropupItem from 'components/DropupItem.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';

const NightVisionControl = ({
	nightVisionMode = false,
	showButton = true,
	isDisabled = false,
	toggleNightvisionHandler = null,
	...props
}) => {
	return (
		<>
			{props.showButton && (
				<DropupItem
					isActive={props.nightVisionMode}
					onClick={!props.isDisabled ? props.toggleNightvisionHandler : null}
					imgSrc={
						props.nightVisionMode
							? `${healthCareCdnUrl}footer-icons/NightVision-active.svg?v2`
							: `${healthCareCdnUrl}footer-icons/NightVision.svg?v2`
					}
					content={props.nightVisionMode ? translate('disableNightvision') : translate('enableNightvision')}
				/>
			)}
		</>
	);
};

NightVisionControl.propTypes = {
	nightVisionMode: PropTypes.bool,
	showButton: PropTypes.bool,
	isDisabled: PropTypes.bool,
	toggleNightvisionHandler: PropTypes.func,
};

export default NightVisionControl;
