import React from 'react';

const OutOfBed = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 24 24' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='1.25'
			d='M16 19.5H6.4V21H4v-5.5h10.5M4.4 15.5l1.5-6V3h13v2.3' />
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='1.25'
			d='M5.8 12.9h.8c.8 0 1.5-.1 2.2-.4l.3-.1c.7-.2 1.5-.4 2.2-.4h1.8c.5 0 .9-.1 1.4 0M15 9.8H5.9' />
		<path
			stroke='currentColor'
			strokeMiterlimit='10'
			strokeWidth='1.25'
			d='M15.996 11.717v4.198a.745.745 0 01-.208.518.711.711 0 01-.505.215.7.7 0 01-.505-.215.732.732 0 01-.208-.518v-4.579c0-.502.194-.983.54-1.338a1.823 1.823 0 011.306-.555h3.202c.49 0 .96.2 1.305.555.347.355.541.836.541 1.338v4.579a.749.749 0 01-.209.517.712.712 0 01-.504.214.697.697 0 01-.505-.214.733.733 0 01-.208-.517v-4.198' />
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeMiterlimit='10'
			strokeWidth='1.25'
			d='M15.996 15.916V22M20.038 15.916V22M17.974 15.916V22M17.972 8.43c.924 0 1.672-.768 1.672-1.715 0-.947-.748-1.715-1.672-1.715-.924 0-1.673.768-1.673 1.715 0 .947.75 1.715 1.673 1.715z' />
	</svg>
);

export default OutOfBed;
