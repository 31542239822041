import { useState, useEffect } from 'react';
import { ScreenTypes, WindowSize } from 'constants/enums.js';

const useScreenType = () => {
	const getScreenType = () => {
		if (window.innerWidth <= WindowSize.TABLET && window.innerWidth > WindowSize.MOBILE) {
			return ScreenTypes.TABLET;
		}
		if (window.innerWidth <= WindowSize.MOBILE) {
			return ScreenTypes.MOBILE;
		}

		return ScreenTypes.LAPTOP;
	};
	const [screenType, setScreenType] = useState(getScreenType);

	useEffect(() => {
		const handleResize = () => {
			setScreenType(getScreenType);
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return screenType;
};

export default useScreenType;
