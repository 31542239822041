export const drawFrameDetections = ({ context, frame, sx, sy, scaleDownBy }) => {
	frame?.detections.forEach(detection => {
		if (detection.location) {
			drawDetection(context, {
				x: detection.location.left * sx,
				y: detection.location.top * sy,
				w: Math.abs(detection.location.right - detection.location.left) * sx,
				h: Math.abs(detection.location.bottom - detection.location.top) * sy,
				label: detection.title,
				confidence: `${(detection.confidence * 100).toFixed(0)} %`,
			});
		}
	});

	frame?.skeletons?.forEach(skeleton =>
		drawSkeleton(context, {
			label: skeleton.action,
			points: skeleton.keypoints.map(kp => ({
				part: kp.part,
				x: (kp.position.x * frame.info.width) / scaleDownBy,
				y: (kp.position.y * frame.info.height) / scaleDownBy,
			})),
		})
	);

	frame?.beds?.forEach(bed =>
		drawBed(context, {
			label: bed.bed,
			points: bed.polygon.map(kp => ({
				x: (kp.x * frame.info.width) / scaleDownBy,
				y: (kp.y * frame.info.height) / scaleDownBy,
			})),
		})
	);
};

const drawBed = (ctx, opts) => {
	const color = getHexColor(opts.label.toUpperCase());
	ctx.strokeStyle = color;
	ctx.lineWidth = 2;
	ctx.beginPath();
	opts.points.forEach((kp, index) => (index === 0 ? ctx.moveTo(kp.x, kp.y) : ctx.lineTo(kp.x, kp.y)));
	ctx.closePath();
	ctx.stroke();
	const label = `v2: ${opts.label.toUpperCase()}`;
	ctx.beginPath();
	ctx.font = '12px sans-serif';
	ctx.fillStyle = color;
	const metrics = ctx.measureText(label);
	const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
	const pad = 5;
	const opt = opts.points[0];
	ctx.roundRect(opt.x - pad, opt.y - actualHeight - pad, metrics.width + 2 * pad, actualHeight + 2 * pad, pad);
	ctx.fill();
	ctx.fillStyle = 'white';
	ctx.fillText(label, opt.x, opt.y);
	ctx.closePath();
};

const drawDetection = (ctx, opts) => {
	const color = getHexColor(opts.label.toUpperCase());
	ctx.beginPath();
	ctx.strokeStyle = color;
	ctx.lineWidth = 2;
	ctx.roundRect(opts.x, opts.y, opts.w, opts.h, 2);
	ctx.stroke();
	ctx.closePath();
	const label = `${opts.label.toUpperCase()} ${opts.confidence}`;
	ctx.beginPath();
	ctx.font = '12px sans-serif';
	ctx.fillStyle = color;
	const metrics = ctx.measureText(label);
	const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
	const pad = 5;
	ctx.roundRect(opts.x - pad, opts.y - actualHeight + opts.h - pad, metrics.width + 2 * pad, actualHeight + 2 * pad, pad);
	ctx.fill();
	ctx.fillStyle = 'white';
	ctx.fillText(label, opts.x, opts.y + opts.h);
	ctx.closePath();
};

const drawSkeleton = (ctx, opts) => {
	const keypoints = [
		{ points: ['leftShoulder', 'leftElbow', 'leftWrist'] },
		{ points: ['rightShoulder', 'rightElbow', 'rightWrist'] },
		{ points: ['leftHip', 'leftKnee', 'leftAnkle'] },
		{ points: ['rightHip', 'rightKnee', 'rightAnkle'] },
		{ points: ['leftHip', 'rightHip'] },
		{ points: ['leftShoulder', 'rightShoulder'] },
		{ points: ['leftEar', 'leftEye', 'nose', 'rightEye', 'rightEar'] },
		{ points: ['leftShoulder', 'leftHip'] },
		{ points: ['rightShoulder', 'rightHip'] },
		{ points: ['leftEar', 'leftEye', 'nose', 'rightEye', 'rightEar'], method: 'renderHead' },
	];

	const renderLine = parts => {
		ctx.strokeStyle = 'white';
		ctx.lineWidth = 2;
		ctx.beginPath();
		parts.forEach((kp, index) => {
			const point = opts.points.find(({ part }) => part === kp);
			if (point) {
				index === 0 ? ctx.moveTo(point.x, point.y) : ctx.lineTo(point.x, point.y);
			}
		});
		ctx.stroke();
	};

	const renderHead = parts => {
		let minX = 1000000,
			minY = 1000000,
			maxX = 0,
			maxY = 0;
		parts.forEach(kp => {
			const point = opts.points.find(({ part }) => part === kp);
			if (point) {
				minX = Math.min(minX, point.x);
				minY = Math.min(minY, point.y);
				maxX = Math.max(maxX, point.x);
				maxY = Math.max(maxY, point.y);
			}
		});
		const cx = (maxX + minX) / 2;
		const cy = (maxY + minY) / 2;
		const r = Math.max(maxX - minX, maxY - minY) / 1.5;
		ctx.strokeStyle = 'white';
		ctx.lineWidth = 1;
		ctx.beginPath();
		ctx.arc(cx, cy, r, 0, 2 * Math.PI);
		ctx.stroke();
	};

	keypoints.forEach(({ points, method }) => {
		if (method === 'renderHead') {
			renderHead(points);
			return;
		}
		renderLine(points);
	});
};

const getHexColor = str => {
	const intToRGB = num => {
		const hex = (num & 0x00ffffff).toString(16).toUpperCase();
		return `#${'00000'.substring(0, 6 - hex.length)}${hex}`;
	};
	let hash = 0;
	for (const char of str) {
		hash = char.charCodeAt(0) + ((hash << 5) - hash);
	}
	return intToRGB(hash);
};

export const setupCanvas = (width, height, canvasRef) => {
	const canvas = canvasRef.current;
	if (!canvas) {
		return;
	}
	const context = canvas.getContext('2d');
	if (!context) {
		return;
	}
	canvas.width = width;
	canvas.height = height;
	canvas.style.width = width;
	canvas.style.height = height;
	context.setTransform(1, 0, 0, 1, 0, 0);
	context.translate(0.5, 0.5);
	context.clearRect(0, 0, width, height);
};
