import React from 'react';

const Checkbox = props => (
	<label className='container'>
		<input type='checkbox' checked={props.checked} onChange={props.onChange} />
		<span className='checkmark' />
	</label>
);

export default Checkbox;
