import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { MeasurementTypes, MeasurementUnits, UnitCategoryTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { getFormattedDate } from 'infrastructure/helpers/dateHelper.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { useSelector } from 'react-redux';

const BioBeatMeasurements = ({
	measurements,
	isPtzActive,
	shouldShowAIVitals,
	shouldShowAllItems,
	numberOfFeeds,
	notesShowing,
	hasConditions,
	measuredAt,
}) => {
	const intl = useIntl();
	const measurementsInitialArr = [
		{
			id: 1,
			name: intl.formatMessage({ id: 'respiration' }),
			value: 'rr',
			unit: intl.formatMessage({ id: MeasurementUnits.BREATHS_PER_MIN }),
			img: 'respiratory-rate',
			type: MeasurementTypes.RESPIRATORY_RATE,
		},
		{
			id: 2,
			name: intl.formatMessage({ id: 'oxygen' }),
			value: 'spo2',
			unit: MeasurementUnits.PERCENTAGE,
			img: 'spo2',
			type: MeasurementTypes.OXYGEN,
			shortName: 'SPO2',
		},
		{
			id: 3,
			name: intl.formatMessage({ id: 'heartRate' }),
			value: 'hr',
			unit: MeasurementUnits.BPM,
			img: 'heart-rate',
			type: MeasurementTypes.HEART_RATE,
			shortName: 'HR',
		},
		{
			id: 4,
			name: 'HRV',
			value: 'hrv',
			unit: MeasurementUnits.PERCENTAGE,
			img: 'hrv',
			type: MeasurementTypes.HRV_INDEX,
		},
		{
			id: 5,
			name: intl.formatMessage({ id: 'bloodPressure' }),
			value: 'sdp',
			secondValue: 'dbp',
			unit: MeasurementUnits.MMHG,
			img: 'blood-pressure',
			type: MeasurementTypes.BLOOD_PRESSURE,
			shortName: 'BP',
		},
		{
			id: 6,
			name: 'SV',
			value: 'sv',
			unit: MeasurementUnits.ML_BEAT,
			img: 'sv',
			type: MeasurementTypes.STROKE_VOLUME,
		},
		{
			id: 7,
			name: 'SVR',
			value: 'svr',
			unit: '',
			img: 'svr',
			type: MeasurementTypes.SYSTEMIC_VASCULAR_RESISTANCE,
		},
		{
			id: 8,
			name: intl.formatMessage({ id: 'cardiacOutput' }),
			value: 'co',
			unit: MeasurementUnits.L_MIN,
			img: 'co',
			type: MeasurementTypes.CARDIAC_OUTPUT,
		},
		{
			id: 9,
			name: intl.formatMessage({ id: 'cardiacIndex' }),
			value: 'ci',
			unit: MeasurementUnits.L_MIN_M,
			img: 'ci',
			type: MeasurementTypes.CARDIAC_INDEX,
		},
		{
			id: 10,
			name: intl.formatMessage({ id: 'bodyTemperature' }),
			value: 'temp',
			unit: MeasurementUnits.CELSIUS,
			img: 'temperature',
			type: MeasurementTypes.TEMPERATURE,
		},
	];

	const preferredUnits = useSelector(state => state.user.unitPreferences);

	const noMeasurementsValueText = shouldShowAllItems ? `${intl.formatMessage({ id: 'measuring' })}...` : '....';

	const getCategoryPreference = categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const getMeasurementValue = item => {
		if (item.type === MeasurementTypes.BLOOD_PRESSURE) {
			return measurements?.sbp && measurements?.dbp ? `${measurements.sbp}/${measurements?.dbp}` : noMeasurementsValueText;
		}
		if (item.type === MeasurementTypes.TEMPERATURE) {
			return measurements?.temp
				? convertMeasurementTypes(
						UnitCategoryTypes.TEMPERATURE,
						measurements?.temp,
						getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unitSystemId
				  )
				: noMeasurementsValueText;
		}
		return measurements?.[item.value] || noMeasurementsValueText;
	};

	const getMeasurementUnit = item => {
		if (getMeasurementValue(item) === noMeasurementsValueText) {
			return '';
		}
		if (item.type === MeasurementTypes.TEMPERATURE) {
			return getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unit || item.unit;
		}
		return item.unit;
	};

	const getVitalSignsToShow = (isRight, item) => {
		let selectedItems;
		const limitOfMeasurementsPerSide = 5;

		if (shouldShowAllItems) {
			selectedItems = isRight ? item.id > limitOfMeasurementsPerSide : item.id <= limitOfMeasurementsPerSide;
		} else {
			selectedItems = [MeasurementTypes.HEART_RATE, MeasurementTypes.OXYGEN, MeasurementTypes.BLOOD_PRESSURE].includes(item.type);
		}
		return selectedItems;
	};

	const getVitalSignsDetails = isRight => {
		return (
			<div
				className={classNames(
					'flex vital-signs-ai',
					isRight ? 'right' : '',
					isPtzActive ? 'ptz-active' : '',
					numberOfFeeds > 1 ? 'multiple-feeds' : '',
					shouldShowAIVitals ? 'ai-vital-signs-showing' : '',
					hasConditions ? 'has-conditions' : '',
					notesShowing ? 'feed-notes-showing' : '',
					!shouldShowAllItems ? 'flex-wrap' : ''
				)}>
				{measurementsInitialArr.map(
					item =>
						getVitalSignsToShow(isRight, item) && (
							<div className='flex vital-signs-details' key={item.id}>
								<img src={`${healthCareCdnUrl}monitoring/biobeat/${item.img}.svg`} alt='icon' />

								<div className='flex column-direction left-5'>
									<span>{shouldShowAllItems ? item.name : item.shortName}</span>
									<div className='flex'>
										<span className='right-2'>{getMeasurementValue(item)}</span>
										<span>{getMeasurementUnit(item)}</span>
									</div>
								</div>
							</div>
						)
				)}
				{(isRight || !shouldShowAllItems) && (
					<div className='flex vital-signs-details date-vital-signs-details'>
						<div className='flex column-direction left-5'>
							<span>{translate('lastUpdate')}:</span>
							<div className='flex'>
								<span className='right-2'>{measuredAt ? getFormattedDate(measuredAt) : '--'}</span>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	};

	return (
		<>
			<div className={classNames('flex monitoring-vital-signs-ai-wrapper', !shouldShowAllItems ? 'row' : '')}>
				{getVitalSignsDetails()}
				{shouldShowAllItems && getVitalSignsDetails(true)}
			</div>
		</>
	);
};

export default BioBeatMeasurements;
