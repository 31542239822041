import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Highlighter from 'react-highlight-words';
import _ from 'lodash';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';

const SearchToAddTest = props => {
    const intl = useIntl();
    const [filteredTests, setFilteredTests] = useState(props.item.tests);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        const filterTests = props.item.tests.filter(item => item.description.toLowerCase().includes(searchValue.toLowerCase()));
        setFilteredTests(filterTests);
    }, [searchValue])

    const addTest = (item, index) => {
        setFilteredTests(prevState => prevState.filter(elem => elem.id !== item.id));
        props.setAddedForms(prevState => {
            const formsCopied = _.cloneDeep(prevState);
            const foundIndex = formsCopied[index].addedTests.findIndex(el => el.id === item.id);
            if (foundIndex === -1) {
                formsCopied[index].addedTests.push(item);
            }

            return formsCopied;
        });
        setSearchValue('');
    };

    const handleRemoveTest = (test, index) => {
        setFilteredTests(prevState => prevState.concat([test]));
        props.setAddedForms(prevState => {
            const formsCopied = [...prevState];
            formsCopied[index].addedTests = formsCopied[index].addedTests.filter(item => item.id !== test.id);
            return formsCopied;
        });
    };

    return (
        <form onSubmit={e => e.preventDefault} key={props.item.id} className={`${props.editable ? '' : 'disabled'} full-width`}>
            {props.editable && (
                <label className='full-width position-relative'>
                    <div className='position-relative'>
                        <img src={`${healthCareCdnUrl}search-icon.svg`} alt='ico' className='search-patient-icon' />
                        <input
                            className='search-input'
                            placeholder={intl.formatMessage({ id: 'searchforTheCodeAndEnter' })}
                            type='text'
                            name='laboratorySearch'
                            value={searchValue}
                            onChange={event => setSearchValue(event.target.value)}
                        />
                    </div>
                    {searchValue && (
                        <div className='full-page-input-results full-width'>
                            {filteredTests.length > 0 &&
                                filteredTests.map(element => (
                                    <div key={element.id} className='cursor-pointer' onClick={() => addTest(element, props.index)}>
                                        <Highlighter
                                            searchWords={searchValue.split(' ')}
                                            autoEscape={true}
                                            textToHighlight={element.description}
                                        />
                                    </div>
                                ))}
                            {filteredTests.length === 0 && (
                                <div>
                                    <span>{translate('noResultsFound')}</span>
                                </div>
                            )}
                        </div>
                    )}
                </label>
            )}
            {props.item.addedTests.length > 0 && <p>{translate('addedTests')}</p>}
            <div className='flex searched-diagnostic-research'>
                {props.item.addedTests.map(labTest => (
                    <div onClick={() => handleRemoveTest(labTest, props.index)} key={labTest.id}>
                        <p>{labTest.description}</p>
                        {props.editable && <i className='material-icons cursor-pointer'>close</i>}
                    </div>
                ))}
            </div>
        </form>
    );
};

export default SearchToAddTest;
