import classNames from 'classnames';

const AlertInformation = ({ alertInfo, onAlertClose, icon, className = '' }) => (
	<div className={classNames('monitoring-alert-info-wrapper', className)}>
		<div>{icon}</div>
		<p>{alertInfo?.text}</p>
		<span className='timeline-close alert-info-btn cursor-pointer' onClick={() => onAlertClose(alertInfo?.deviceId)}>
			<i className='material-icons'>close</i>
		</span>
	</div>
);

export default AlertInformation;
