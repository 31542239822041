import React, { useState } from 'react';
import classNames from 'classnames';
import { AlertTypes, MeasurementActivityTypes } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { addAlertActivity } from 'api/alerts.js';
import Alert from 'components/Alert.jsx';

const WarningDetection = props => {
	const [alertType, setAlertType] = useState(null);
	const [error, setError] = useState(null);
	const monitoringIconLink = `${healthCareCdnUrl}monitoring/`;

	const onSubmit = async activityType => {
		const params = {
			alertId: props.warningObject.alertId,
			activityType,
			isAiAlert: props.warningObject.isAiAlert,
			comment: '',
		};
		const response = await addAlertActivity(params);
		if (response.error) {
			setError(response.error.message);
		}
		props.setSnoozeType(props.feedDeviceId, alertType);
		setAlertType(null);
		props.onAlertCloseClick();
	};

	return (
		<>
			{!alertType && (
				<div
					className={classNames(
						'detection-wrapper',
						(!props.isFeedExpanded && props.numberOfFeeds !== 1) || props.warningObject.isToastMessage
							? 'fall-detection-wrapper'
							: 'wrapper-top',
						props.warningObject.value ? 'show' : ''
					)}>
					<div className={`alert-body fall-alert-body ${props.alertType}`}>
						<div className='alert-content'>
							<div>
								<i className='material-icons'>
									{props.alertType === AlertTypes.SUCCESS ? 'check_circle_outline' : 'error_outline'}
								</i>
							</div>
							<div>
								<p>{props.warningObject.text}</p>
							</div>
							<div>
								<i className='material-icons cursor-pointer close-alert' onClick={props.onAlertClose}>
									close
								</i>
							</div>
						</div>
						{(props.isFeedExpanded || props.numberOfFeeds === 1) && !props.warningObject.isToastMessage && (
							<div className='alert-feed-sub-option'>
								<div className='alert-feed-sub-options-wrapper'>
									<div
										className='position-relative flex column-direction flex-align-center flex-justify-center'
										onClick={() => {
											setAlertType(MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT);
											onSubmit(MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT);
										}}>
										<img src={`${monitoringIconLink}video-feed/fake-alert.svg`} alt='icon' />
										<p className='--red-color'>{translate('falseAlert')}</p>
									</div>
									<div
										className='position-relative flex column-direction flex-align-center flex-justify-center'
										onClick={() => {
											setAlertType(MeasurementActivityTypes.ACKNOWLEDGED);
											onSubmit(MeasurementActivityTypes.ACKNOWLEDGED);
										}}>
										<img src={`${monitoringIconLink}video-feed/acknowledge.svg`} alt='icon' />
										<p className='--green-color'>{translate('acknowledge')}</p>
									</div>
									{props.showForwardToNurses && (
										<div
											className='position-relative flex column-direction flex-align-center flex-justify-center'
											onClick={() => props.onForwardToNurses(props.warningObject.alertId, props.warningObject.isAiAlert)}>
											<img src={`${monitoringIconLink}video-feed/forward.svg`} alt='icon' />
											<p className='--blue-color forward-ms'>{translate('forwardToNurses')}</p>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			)}
			{error && <Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />}
		</>
	);
};

export default WarningDetection;
