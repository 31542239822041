import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import translate from 'i18n-translations/translate.jsx';
import Grid from 'components/Grid.jsx';
import Input from 'components/Common/FormElements/Input.jsx';
import Button from 'components/Button.jsx';
import GoogleReCaptchaV3 from 'services/GoogleReCaptchaV3.js';
import { APP_CONFIG, healthCareCdnUrl } from 'constants/global-variables.js';
import { sendRecoveryEmail } from 'api/users.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { AlertTypes } from 'constants/enums.js';
import classNames from 'classnames';

const Recover = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [hasEmailSent, setHasEmailSent] = useState(false);
	const [error, setError] = useState(null);

	const { current: reCaptchaV3 } = useRef(new GoogleReCaptchaV3(APP_CONFIG.googleRecaptchaKey));

	useEffect(() => {
		const getReCaptcha = async () => {
			const googleReCaptcha = await reCaptchaV3.loadReCaptcha();
			if (!googleReCaptcha) {
				setError(translate('recaptchaLoadError'));
			}
		};
		getReCaptcha();
	}, [reCaptchaV3]);

	const submitForm = async ({ email }) => {
		setIsLoading(true);
		const reCaptchaToken = await reCaptchaV3.getToken();
		const response = await sendRecoveryEmail(email, reCaptchaToken);
		if (response.error) {
			setError(response.error.message);
		} else {
			setHasEmailSent(true);
		}
		setIsLoading(false);
	};

	const isBanyanUrl = () => window.location.origin.includes('banyan');

	return (
		<div className={classNames('login recover-password-wrapper', isBanyanUrl() ? 'banyan-background' : '')}>
			<Grid width='500px' className='login__wrapper'>
				<div style={{ width: '300px' }}>
					{isBanyanUrl() && <img src='https://static.solaborate.com/temp/banyanlogo.svg' alt='banyan-logo' />}
					{!isBanyanUrl() && <img src={`${healthCareCdnUrl}hellocare-white-logo.svg`} alt='hello-health-logo' />}
				</div>
				<div className='login-form-wrapper recover-password'>
					<h3>Password Assistance Form</h3>
					{hasEmailSent && (
						<>
							<div className='warning-message'>
								<div>
									<span className='semi-bold'>
										If that email address is in our system, we will send you an email to reset your password. Please go to your
										email account to reset your password.
									</span>
									<span className='recover-password-back'>
										If you don&apos;t receive instructions within a minute or two, check your spam and junk filters, or try
										<span className='link' onClick={() => setHasEmailSent(false)}>
											{' '}
											resending your request
										</span>
									</span>
								</div>
							</div>
							<div className='flex full-width top-30'>
								<label className='google-recaptcha-wrapper full-width'>
									<span>
										This site is protected by reCAPTCHA and the Google&nbsp;
										<a rel='noopener noreferrer' target='_blank' href='https://policies.google.com/privacy'>
											Privacy Policy
										</a>
										&nbsp;and&nbsp;
										<a rel='noopener noreferrer' target='_blank' href='https://policies.google.com/terms'>
											Terms of Service
										</a>
										&nbsp;apply.
									</span>
								</label>
							</div>
						</>
					)}
					{!hasEmailSent && (
						<div>
							<div className='warning-message'>
								<span>
									Please enter the email address you used to create your{' '}
									<span className='bold'>{isBanyanUrl() ? 'banyan' : 'hellocare'}</span> account, and we will send you a link to
									reset your password.
								</span>
							</div>
							<div style={{ paddingTop: '25px' }}>
								<Formik
									initialValues={{ email: '' }}
									onSubmit={values => submitForm(values)}
									validationSchema={Yup.object().shape({
										email: Yup.string().email('Email must be a valid email!').required('Email is required'),
									})}
									render={() => (
										<Form>
											<Field
												name='email'
												type='email'
												label='Email Address'
												placeholder='Enter your email address'
												component={Input}
											/>
											<div className='flex no-border-top no-margin-top full-width'>
												<label className='google-recaptcha-wrapper full-width bottom-30'>
													<span>
														This site is protected by reCAPTCHA and the Google&nbsp;
														<a rel='noopener noreferrer' target='_blank' href='https://policies.google.com/privacy'>
															Privacy Policy
														</a>
														&nbsp;and&nbsp;
														<a rel='noopener noreferrer' target='_blank' href='https://policies.google.com/terms'>
															Terms of Service
														</a>
														&nbsp;apply.
													</span>
												</label>
											</div>
											<Button type='submit' text='Continue' display='block' isLoading={isLoading} />
										</Form>
									)}
								/>
							</div>
						</div>
					)}
				</div>
			</Grid>
			<PopUpAlert
				alertType={AlertTypes.DANGER}
				display={error}
				onAlertClose={() => setError(null)}
				contentText={error}
				isSilent={true}
			/>
		</div>
	);
};

export default Recover;
