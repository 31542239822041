export const fallDetectionConstants = {
	fps: 10,
	queueSize: 7,
	skeletonMinConfidence: 0.1,
	lineWidth: 5,
	keypointMinConfidence: 0.5,
	pointWidth: 8,
};

export const SkeletonColorTypes = {
	DEFAULT: 0,
	GREEN: 1,
	ORANGE: 2,
	RED: 3,
};

export const SkeletonColors = {
	[SkeletonColorTypes.DEFAULT]: '#40E0D0',
	[SkeletonColorTypes.GREEN]: '#40EF03',
	[SkeletonColorTypes.ORANGE]: '#FF7E0B',
	[SkeletonColorTypes.RED]: '#FF0A00',
};

export const heightMultiplyUnit = {
	full: 0.3,
	upper: 3,
	mid: 1,
	lower: 1,
	midLow: 3,
};

export const fallPredictionUnit = {
	full: 0.35,
	upper: 0.09,
	mid: 0.19,
	lower: 0.19,
	midLow: 0.09,
};

export const multiPersonOptions = {
	maxDetections: 5,
	scoreThreshold: 0.15,
	nmsRadius: 30.0,
};

const centeredSkeletonFieldWidth = 0.2; // value 0.1 to 1

export function getStartPoint() {
	return (1 - centeredSkeletonFieldWidth) / 2;
}
export function getEndPoint() {
	return 1 - (1 - centeredSkeletonFieldWidth) / 2;
}

export default fallDetectionConstants;
