import * as React from 'react';

const RoomTree = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
		<path d='M2.5 19H4.5M4.5 19H14V4H4.5V19Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
		<path
			d='M11.5 13C11.7762 13 12 12.7761 12 12.5C12 12.2239 11.7762 12 11.5 12C11.2239 12 11 12.2239 11 12.5C11 12.7761 11.2239 13 11.5 13Z'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M14 6H19.5V19H21.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
	</svg>
);
export default RoomTree;
