const TransferOwnership = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
		<path
			d='M5 20V17.7143C5 14.5584 7.68629 12 11 12C12.5367 12 13.9385 12.5502 15 13.455'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<circle cx='17' cy='17' r='4' stroke='currentColor' strokeLinecap='round' />
		<path d='M16.5 19L18.5 17L16.5 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
		<path d='M18.5 17L13 17' stroke='currentColor' strokeLinecap='round' />
		<path d='M7 17.5L7 20' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
		<path
			d='M14.25 6.75C14.25 8.54493 12.7949 10 11 10C9.20507 10 7.75 8.54493 7.75 6.75C7.75 4.95507 9.20507 3.5 11 3.5C12.7949 3.5 14.25 4.95507 14.25 6.75Z'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default TransferOwnership;
