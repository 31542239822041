import React from 'react';
import { useIntl } from 'react-intl';

const TableTwoFactorAuthButton = props => {
	const intl = useIntl();
	return (
		<i
			className='material-icons boxed-icon two-fa-icon'
			data-tooltip={intl.formatMessage({ id: 'resetTwoFa' })}
			data-position='top'
			onClick={props.onClick}>
			lock_reset
		</i>
	);
};

export default TableTwoFactorAuthButton;
