import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

function Procedures(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
			<rect
				x={2.4}
				y={1.199}
				width={19.2}
				height={21.6}
				rx={2}
				stroke={LightTheme.colors.grayFive}
				strokeWidth={1.5}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M5.4 15h13.2M7.2 18.6h9.6M8.4 8.4l2.4 2.4L15.6 6'
				stroke={LightTheme.colors.grayFive}
				strokeWidth={1.5}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default Procedures;
