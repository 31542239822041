import LightTheme from 'calls/styles/LightTheme.js';
import { UserRoles } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import VisitorIcon from 'icons/Roles/VisitorIcon.jsx';
import { getUserRole } from 'infrastructure/auth.js';

const RoleIcon = ({
	role,
	isActive = getUserRole() === role.name,
	iconStyle: { activeColor = LightTheme.colors.blueSeven, width = 18, height = 18 } = {},
}) => {
	const iconColor = isActive ? activeColor : LightTheme.colors.grayEight;
	const iconSuffix = isActive && activeColor === LightTheme.colors.greenTwo ? 'green' : 'active';

	return role.name === UserRoles.VISITOR ? (
		<VisitorIcon color={iconColor} width={width} height={height} />
	) : (
		<img src={`${healthCareCdnUrl}user-types/${role.name.toLowerCase()}${isActive ? `-${iconSuffix}` : ''}.svg?v4`} alt='icon' />
	);
};

export default RoleIcon;
