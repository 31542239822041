import classNames from 'classnames';
import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { VoiceDetectionTimers } from 'infrastructure/helpers/aiHelper.js';
import { useRef } from 'react';
import AiConfigCheckbox from 'components/Configurations/AI/AiConfigCheckbox.jsx';

const AiVoiceAnalysisConfigs = ({
	configData,
	errors = null,
	onCheckboxChange,
	onSetAsDefault,
	onDetectionTimesChange,
	isAdminView,
	shouldSetAsDefault = true,
}) => {
	const intl = useIntl();
	const voiceDetectionTimer = useRef(VoiceDetectionTimers(intl));
	const { values, valuesName, detections, detectionsName } = configData;

	const getDetectionTimes = aiConfig =>
		detections?.find(item => item?.aiAudioAnalysisEvent?.toString() === aiConfig)?.detectionTimes;

	return (
		<div
			className={classNames('top-15 checkbox-select-container doctor-profile-settings ai-configurations', {
				'container-width': isAdminView,
			})}>
			<div className='flex'>
				<div className='sector'>
					<p>{translate('sendAiVoiceAlert')}</p>
					<p className='subtitle'>{translate('selectAudioAlerts')}</p>
				</div>
				<div className='sector'>
					<p>{translate('setDetectionTimesForAlert')}</p>
					<p className='subtitle'>{translate('selectDetectionTimesForAlert')}</p>
				</div>
			</div>
			<div className='flex column-direction gap-m'>
				{values.map(aiConfig => (
					<div className='flex' key={aiConfig.value}>
						<AiConfigCheckbox
							key={aiConfig.value}
							aiConfig={aiConfig}
							arrayName={valuesName}
							onCheckboxChange={onCheckboxChange}
							onSetAsDefault={onSetAsDefault}
							shouldSetAsDefault={shouldSetAsDefault}
						/>
						<div className={classNames('sector', { disabled: !aiConfig.isChecked })}>
							<Select
								options={voiceDetectionTimer?.current}
								value={voiceDetectionTimer.current.find(item => item.value === getDetectionTimes(aiConfig.value))}
								onChange={item =>
									onDetectionTimesChange({
										item,
										arrayName: detectionsName,
										aiConfigValue: parseInt(aiConfig.value),
									})
								}
								classNamePrefix='custom-select'
							/>
						</div>
					</div>
				))}
			</div>
			{errors && <span className='red-error flex margin-top-s'>{errors}</span>}
		</div>
	);
};

export default AiVoiceAnalysisConfigs;
