import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import Grid from 'components/Grid.jsx';
import Alert from 'components/Alert.jsx';
import Loader from 'components/Loader.jsx';
import Button from 'components/Button.jsx';
import Textarea from 'components/Textarea.jsx';
import translate from 'i18n-translations/translate.jsx';
import { MedicalInfoTypes, PrescriptionUnits } from 'constants/enums.js';
import { getMedicinesAndStrengths, createEditPrescription } from 'api/medicalInfo.js';
import { handleOnKeyDownNumeric } from 'infrastructure/helpers/commonHelpers.js';

const AddEditPrescription = props => {
	const intl = useIntl();
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [itemsToSelect, setItemsToSelect] = useState([]);
	const [medicalItemExists, setMedicalItemExists] = useState(null);
	const [medicationChoice, setMedicationChoice] = useState({ isDefault: true, untilDirectedToStop: false, onlyAsNeeded: false });

	const prescriptionOptionsArray = [
		{ title: intl.formatMessage({ id: 'dosage' }), type: 'dosage', id: 0 },
		{
			title: intl.formatMessage({ id: 'frequency' }),
			type: 'frequency',
			optionalText: intl.formatMessage({ id: 'timesAday' }),
			id: 1,
		},
		{
			title: intl.formatMessage({ id: 'duration' }),
			type: 'duration',
			optionalText: intl.formatMessage({ id: 'numberOfDays' }),
			id: 2,
		},
		{ title: intl.formatMessage({ id: 'refills' }), type: 'refills', id: 3 },
	];

	useEffect(() => {
		const fetchMedicinesAndStrengths = async () => {
			const response = await getMedicinesAndStrengths(searchValue);
			if (response?.error) {
				setError(response.error.message);
				return;
			}
			const filteredItems = props.selectedMedicine?.medicine
				? response?.filter(item => item.rxCuisCode !== props.selectedMedicine?.medicine?.rxCuisCode)
				: response;
			setItemsToSelect(searchValue ? filteredItems : []);
		};
		if (searchValue) {
			fetchMedicinesAndStrengths();
		}
	}, [searchValue, props.selectedMedicine]);

	useEffect(() => {
		if (props.selectedMedicine?.medicine) {
			setSearchValue('');
		}
	}, [props.selectedMedicine]);

	const onSubmitHandler = async (values, { validateForm }) => {
		setMedicalItemExists(null);
		const validationErrors = await validateForm();
		if (Object.keys(validationErrors).length) {
			return;
		}
		const dosage = parseInt(values.dosage, 10);
		const frequency = parseInt(values.frequency, 10);
		const duration = parseInt(values.duration, 10);
		const refills = parseInt(values.refills, 10);
		const params = {
			...props.selectedMedicine,
			dosage,
			dosageUnitId: PrescriptionUnits.DOSAGE_UNIT_ID,
			frequency,
			duration,
			quantityToDispense: dosage * frequency * duration,
			refills,
			untilDirectedToStop: medicationChoice.untilDirectedToStop,
			onlyAsNeeded: medicationChoice.onlyAsNeeded,
		};
		if (
			props.prescriptions.length &&
			props.prescriptions.some(
				item =>
					item?.medicine?.rxCuisCode === props.selectedMedicine?.medicine?.rxCuisCode && !item.isDisabled && !item.isCompleted
			) &&
			!params.id
		) {
			setMedicalItemExists(
				translate('medicalItemExists', {
					value: intl.formatMessage({ id: 'prescription' }),
				})
			);
			return;
		}
		setIsLoading(true);
		const response = await createEditPrescription(params, props.visitId);
		if (response?.error) {
			setError(response.error.message);
		} else {
			const item = {
				...params,
				id: response.id,
			};
			const items = response.id
				? [...props.prescriptions, item]
				: [...props.prescriptions.filter(prescription => prescription.id !== params.id), params];
			props.setMedicalInfo(items, MedicalInfoTypes.PRESCRIPTIONS);
		}
		setIsLoading(false);
		props.setSelectedMedicine(null);
		props.closePrescriptions();
	};

	const getMinOrMaxValue = (value, isIncrease) => {
		if (isIncrease) {
			return value === 99 ? value : value + 1;
		}
		return value === 1 ? value : value - 1;
	};

	const getMedicineName = () => {
		return `${props.selectedMedicine?.medicine?.name || props.selectedMedicine.name}${
			props.selectedMedicine?.medicine?.strengthAndForm || props.selectedMedicine.strengthAndForm
		}`;
	};

	const handleAllowedValues = (event, setFieldValue, type) => {
		const { value } = event.target;
		if (!value || value === '0') {
			setFieldValue(type, 1);
			return;
		}
		setFieldValue(type, parseInt(value, 10));
	};

	return (
		<>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div className='text-align-center'>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading && (
				<Formik
					initialValues={{
						dosage: props.selectedMedicine?.dosage || 1,
						frequency: props.selectedMedicine?.frequency || 1,
						duration: props.selectedMedicine?.duration || 1,
						quantityToDispense:
							props.selectedMedicine?.dosage * props.selectedMedicine?.frequency * props.selectedMedicine?.duration || 1,
						refills: props.selectedMedicine?.refills || 1,
					}}
					onSubmit={onSubmitHandler}
					validateOnBlur={false}
					validateOnChange={false}
					enableReinitialize
					validationSchema={Yup.object().shape({
						dosage: Yup.number()
							.required(`${intl.formatMessage({ id: 'dosage' })} ${intl.formatMessage({ id: 'isRequired' })}`)
							.min(1, `${intl.formatMessage({ id: 'minLengthIs' })} 1`)
							.max(99, `${intl.formatMessage({ id: 'maxLengthIs' })} 99`),
						frequency: Yup.number()
							.required(`${intl.formatMessage({ id: 'frequency' })} ${intl.formatMessage({ id: 'isRequired' })}`)
							.min(1, `${intl.formatMessage({ id: 'minLengthIs' })} 1`)
							.max(99, `${intl.formatMessage({ id: 'maxLengthIs' })} 99`),
						quantityToDispense: Yup.number().required(
							`${intl.formatMessage({ id: 'quantityToDispense' })} ${intl.formatMessage({ id: 'isRequired' })}`
						),
						duration: Yup.number()
							.required(`${intl.formatMessage({ id: 'duration' })} ${intl.formatMessage({ id: 'isRequired' })}`)
							.min(1, `${intl.formatMessage({ id: 'minLengthIs' })} 1`)
							.max(99, `${intl.formatMessage({ id: 'maxLengthIs' })} 99`),
						refills: Yup.number()
							.required(`${intl.formatMessage({ id: 'refills' })} ${intl.formatMessage({ id: 'isRequired' })}`)
							.min(1, `${intl.formatMessage({ id: 'minLengthIs' })} 1`)
							.max(99, `${intl.formatMessage({ id: 'maxLengthIs' })} 99`),
					})}>
					{formikProps => {
						const { values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue } = formikProps;
						return (
							<>
								<h4>{translate('prescriptions')}</h4>
								<div className='mi-input-wrapper'>
									<input type='text' onChange={event => setSearchValue(event.target.value)} value={searchValue} />
									{itemsToSelect.length > 0 && (
										<div>
											{itemsToSelect.map(item => (
												<div
													key={item.rxCuisCode}
													className='flex cursor-pointer'
													onClick={() => {
														props.setSelectedMedicine(prevState => ({ ...prevState, medicine: item }));
														setSearchValue('');
														setItemsToSelect([]);
													}}>
													<p>{item.name}</p>
													<p>{item.strengthAndForm}</p>
												</div>
											))}
										</div>
									)}
								</div>
								<div className='simple-txt-modal-inner prescriptions-modal'>
									<div>
										{props.selectedMedicine?.medicine && (
											<div>
												<h4>{getMedicineName()}</h4>
											</div>
										)}
										<div
											className='flex medical-radio-check cursor-pointer'
											onClick={() => setMedicationChoice({ isDefault: true, untilDirectedToStop: false, onlyAsNeeded: false })}>
											<input type='radio' name='choice' checked={medicationChoice.isDefault} onChange={() => null} />
											<h5>{translate('default')}</h5>
										</div>

										<div className='flex'>
											<div
												className='flex medical-radio-check cursor-pointer'
												onClick={() => setMedicationChoice({ isDefault: false, untilDirectedToStop: false, onlyAsNeeded: true })}>
												<input type='radio' name='choice' checked={medicationChoice.onlyAsNeeded} onChange={() => null} />
												<h5>{translate('onlyAsNeeded')}</h5>
											</div>
											<div
												className='flex medical-radio-check cursor-pointer'
												onClick={() => setMedicationChoice({ isDefault: false, untilDirectedToStop: true, onlyAsNeeded: false })}>
												<input type='radio' name='choice' checked={medicationChoice.untilDirectedToStop} onChange={() => null} />
												<h5>{translate('orUntilDiretedToStop')}</h5>
											</div>
										</div>
										<div>
											<h5>{translate('typeOfDosage')}</h5>
											<select className='full-width'>
												<option>{intl.formatMessage({ id: 'capsule' })}</option>
											</select>
										</div>

										{prescriptionOptionsArray.map(item => (
											<div key={item.id} className='flex mi-input-counter-wrapper'>
												<div>
													<h5>{item.title}</h5>
													{item.optionalText && <span>{item.optionalText}</span>}
												</div>
												<div>
													<div className='flex mi-input-counter'>
														<div
															className='cursor-pointer'
															onClick={() => setFieldValue(item.type, getMinOrMaxValue(values[item.type], false))}>
															<i className='material-icons'>remove</i>
														</div>
														<input
															onKeyDown={handleOnKeyDownNumeric}
															type='number'
															id={item.type}
															onChange={handleChange}
															value={parseInt(values[item.type], 10)}
															max={99}
															onBlur={event => handleAllowedValues(event, setFieldValue, item.type)}
														/>
														<div
															className='cursor-pointer'
															onClick={() => setFieldValue(item.type, getMinOrMaxValue(values[item.type], true))}>
															<i className='material-icons'>add</i>
														</div>
													</div>
													{errors[item.type] && touched[item.type] && <span className='red-error'>{errors[item.type]}</span>}
												</div>
											</div>
										))}
										<div className='flex mi-input-counter-wrapper quantity-to-dispense'>
											<h5 className='break-word'>{translate('quantityToDispense')}:</h5>
											<input
												disabled
												value={values.dosage * values.frequency * values.duration}
												type='number'
												id='quantityToDispense'
												className='flex-1'
												style={{ marginLeft: 'var(--spacing-m)' }}
												onChange={() => null}
											/>
										</div>
										<div className='right-s'>
											<h5>{translate('patientInstructions')}</h5>
											<Textarea
												maxLength={500}
												maxNumberLimit={50}
												rows={10}
												value={props.selectedMedicine?.patientInstructions}
												onChange={event => {
													const patientInstructions = event.target.value;
													props.setSelectedMedicine(prevState => ({ ...prevState, patientInstructions }));
												}}
											/>
										</div>
										<div>
											<h5>{translate('noteToPharmacist')}</h5>
											<Textarea
												maxLength={500}
												maxNumberLimit={50}
												rows={10}
												value={props.selectedMedicine?.noteToPharmacist}
												onChange={event => {
													const noteToPharmacist = event.target.value;
													props.setSelectedMedicine(prevState => ({ ...prevState, noteToPharmacist }));
												}}
											/>
										</div>
										{props.isOffCall && props.isReasonShown && (
											<div>
												<h5>{translate('reason')}</h5>
												<Textarea
													maxLength={500}
													maxNumberLimit={50}
													rows={10}
													value={props.selectedMedicine?.changeNote}
													onChange={event => {
														const changeNote = event.target.value;
														props.setSelectedMedicine(prevState => ({ ...prevState, changeNote }));
													}}
												/>
												<div
													className='flex medical-radio-check cursor-pointer'
													onClick={() =>
														props.setSelectedMedicine(prevState => ({
															...prevState,
															isDisabled: !prevState.isDisabled,
														}))
													}>
													<input type='checkbox' checked={props.selectedMedicine?.isDisabled} onChange={() => null} />
													<h5>{translate('disabled')}</h5>
												</div>
											</div>
										)}
										<div
											className='flex medical-radio-check cursor-pointer'
											onClick={() =>
												props.setSelectedMedicine(prevState => ({
													...prevState,
													dispenseAsWritten: !prevState.dispenseAsWritten,
												}))
											}>
											<input type='checkbox' checked={props.selectedMedicine?.dispenseAsWritten} onChange={() => null} />
											<h5>{translate('dispensedAsWritten')}</h5>
										</div>
									</div>
								</div>
								<div className='flex'>
									<Button
										onClick={() => {
											props.setSelectedMedicine(null);
											props.closePrescriptions();
										}}
										type='button'
										isLoading={isSubmitting}
										text={intl.formatMessage({ id: 'cancel' })}
										className='small top-30 prescription-button'
									/>
									<Button
										isDisabled={!props.selectedMedicine?.medicine}
										onClick={handleSubmit}
										type='button'
										isLoading={isSubmitting}
										text={intl.formatMessage({ id: 'save' })}
										className='small top-30 prescription-button'
									/>
								</div>
							</>
						);
					}}
				</Formik>
			)}
			<Alert
				display={error || medicalItemExists}
				fixed={true}
				hideCloseButton={true}
				message={error || medicalItemExists}
				variant='dark'
			/>
		</>
	);
};

export default AddEditPrescription;
