import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import {
	deleteExerciseById,
	getExercisesByCategory,
	getExercisesByPthAndType,
	getGroupedExercisesByType,
} from 'api/physicalExercises';
import { Alert, Dropdown, Grid, Loader } from 'components';
import { PhysicalExerciseTabs } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import EditPhysicalExercise from 'containers/PhysicalTherapies/EditPhysicalExercise';
import Button from 'components/Button.jsx';

const PhysicalExerciseDetails = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [physicalExercises, setPhysicalExercises] = useState([]);
	const [selectedExercise, setSelectedExercise] = useState(null);
	const [error, setError] = useState(null);
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			const response = await getExercisesByCategory(props.physicalTherapyId, props.selectedCategoryId);
			if (response.error) {
				setError(response.error.message);
			} else {
				setPhysicalExercises(response.items);
				if (response.items.length === 0) {
					props.setStep(PhysicalExerciseTabs.EXERCISES_LIST);
				}
			}
			setIsLoading(false);
		};
		fetchData();
	}, [props.selectedCategoryId]);

	const openEditExercise = exercise => {
		setSelectedExercise(exercise);
	};

	const fetchExercises = async typeId => {
		const response = await getExercisesByPthAndType(props.physicalTherapyId, typeId);
		if (response.error) {
			setError(response.error.message);
		} else {
			setPhysicalExercises(response.items);
			if (response.items.length === 0) {
				props.setStep(PhysicalExerciseTabs.EXERCISES_LIST);
			}
		}
		setIsLoading(false);
	};

	const deleteEditExercise = async exercise => {
		const response = await deleteExerciseById(exercise.id);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		fetchExercises(exercise.type.id);
		fetchGroupedExercises();
	};

	const fetchGroupedExercises = async () => {
		const response = await getGroupedExercisesByType(props.physicalTherapyId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		props.setPhysicalTherapyExercises(response.items);
	};

	return (
		<>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div className='text-align-center'>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading && !selectedExercise && (
				<>
					<h4>{translate('physicalTherapyExercises')}</h4>
					<div className='flex-1'>
						<ul className='list-group exercises-details'>
							{physicalExercises.map(physicalExercise => (
								<li>
									<span className='cursor-unset'>
										<img
											style={{ width: 20 }}
											src={`${healthCareCdnUrl}physical-exercises/${props.getIcons(physicalExercise.category.type)}.svg?v2`}
											alt={physicalExercise?.type.name || 'physical-exercises-img'}
										/>
										<div className='flex'>
											{intl.formatMessage({ id: stringToCamelCase(physicalExercise?.type.name) })}
											<span>{intl.formatMessage({ id: stringToCamelCase(physicalExercise.date) })}</span>
										</div>
										<Dropdown position='bottom' icon='more_vert' className='remove-connection-wrapper physical-exercises'>
											<div className='physical-exercises-dropdown'>
												<div className='flex cursor-pointer' onClick={() => openEditExercise(physicalExercise)}>
													<img src={`${healthCareCdnUrl}physical-exercises/edit.svg`} alt='icon' />
													<p>{translate('edit')}</p>
												</div>
												<div
													className='flex cursor-pointer'
													onClick={_.debounce(() => deleteEditExercise(physicalExercise), 500)}>
													<img src={`${healthCareCdnUrl}physical-exercises/delete.svg`} alt='icon' />
													<p>{translate('delete')}</p>
												</div>
											</div>
										</Dropdown>
									</span>
								</li>
							))}
						</ul>
					</div>

					<Button
						className='button small block'
						type='button'
						onClick={() => props.setStep(PhysicalExerciseTabs.EXERCISES_LIST)}
						text={translate('back')}
					/>
				</>
			)}
			{selectedExercise && (
				<EditPhysicalExercise
					selectedExercise={selectedExercise}
					categories={props.categories}
					setStep={props.setStep}
					fetchExercises={fetchExercises}
					setSelectedExercise={setSelectedExercise}
					patientId={props.patientId}
					physicalTherapyId={props.physicalTherapyId}
				/>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default PhysicalExerciseDetails;
