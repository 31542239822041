import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Formik } from 'formik';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { updateRoom } from 'api/rooms.js';
import Input from 'components/Input.jsx';
import { getRegionSubTree } from 'api/tree.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { buildTree, getHealthSystemDevices } from 'infrastructure/helpers/commonHelpers.js';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import Loader from 'components/Loader.jsx';
import LightTheme from 'calls/styles/LightTheme.js';

const ChangeRoomName = props => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const userSession = useSelector(state => state.user.userSession);
	const { hospitalId, departmentId, floorId, roomId } = props.currentRoom;
	const [isEditing, setIsEditing] = useState(false);
	const [updateLoading, setUpdateLoading] = useState(false);
	const darkMode = useSelector(state => state.user.darkMode);

	useEffect(() => {
		setIsEditing(false);
	}, [props.currentRoom]);

	const updateRoomName = async (values, { resetForm }) => {
		if (values?.roomName === props.currentRoom.sectorName) {
			setIsEditing(false);
			return;
		}
		setUpdateLoading(true);
		const params = {
			healthSystemId: userSession.healthSystem.id,
			hospitalId,
			departmentId,
			floorId,
			roomId,
			params: {
				name: values?.roomName,
			},
		};
		const response = await updateRoom(params);
		if (response.error) {
			props.setError(response.error.message);
			resetForm();
		} else {
			updateTree();
			setIsEditing(false);
		}
		setUpdateLoading(false);
	};

	const handleEditRoomBtn = handleSubmit => {
		if (isEditing) {
			handleSubmit();
		} else {
			setIsEditing(true);
		}
	};

	const updateTree = async () => {
		const subTreeResponse = await getRegionSubTree(userSession.healthSystem.id, userSession.regionId);
		if (subTreeResponse.error) {
			props.setError(subTreeResponse.error.message);
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const treeData = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			dispatch(healthSystemsActionCreators.setHealthSystem(healthSystem));
			dispatch(healthSystemsActionCreators.setTreeData(treeData));
		}
	};

	const getInitialData = () => ({
		roomName: props.currentRoom.sectorName,
	});

	const onEnter = (e, handleSubmit) => {
		const enterKey = 13;
		if (e.keyCode === enterKey) {
			handleEditRoomBtn(handleSubmit);
		}
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={getInitialData()}
			validationSchema={Yup.object().shape({
				roomName: Yup.string()
					.required(intl.formatMessage({ id: 'sectorIsRequired' }))
					.min(
						2,
						intl.formatMessage({
							id: 'sectorNameMinLength',
						})
					)
					.max(
						100,
						intl.formatMessage({
							id: 'maxCharacterLengthExceeded',
						})
					)
					.trim(),
			})}
			onSubmit={updateRoomName}>
			{formikProps => {
				const { values, errors, handleChange, handleSubmit, isValid } = formikProps;
				return (
					<div className='flex change-room-name'>
						{isEditing && (
							<Input
								type='text'
								validationOptions={{}}
								name='roomName'
								value={values.roomName}
								onChange={handleChange}
								onBlur={handleChange}
								placeholder={intl.formatMessage({ id: 'writeSectorName' })}
								error={errors.roomName}
								onKeyDown={e => onEnter(e, handleSubmit)}
							/>
						)}
						{!isEditing && <h3>{values.roomName}</h3>}
						{props.currentRoom.isRoomNameEditable && (
							<>
								{!updateLoading && (
									<div className='flex cursor-pointer' onClick={() => handleEditRoomBtn(handleSubmit)}>
										<i
											style={{ color: darkMode ? LightTheme.colors.grayOne : LightTheme.colors.graySix }}
											className={classNames(
												'material-icons',
												isEditing ? 'done-icon' : '',
												isEditing && !isValid ? 'disabled' : ''
											)}>
											{isEditing ? 'done' : 'edit'}
										</i>
									</div>
								)}
								{updateLoading && <Loader />}
							</>
						)}
					</div>
				);
			}}
		</Formik>
	);
};

export default ChangeRoomName;
