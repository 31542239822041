import { Cam, LocalTrackAdded, LocalTrackController } from '@solaborate/calls/webrtc';
import { loadTfLite, StreamBackgroundEffect } from './TrackWithBackground.jsx';

class MaskedTrackController extends LocalTrackController {
	background = null;

	async add(trackType) {
		const req = Array.isArray(trackType) ? trackType : [trackType];
		const tracks = await this.factory.createTracks(req);
		const videoTrackIdx = tracks.findIndex(t => t.type === Cam);
		if (videoTrackIdx !== -1 && this.background !== null) {
			const tflite = await loadTfLite();
			tracks[videoTrackIdx] = new StreamBackgroundEffect(tracks[videoTrackIdx], tflite, this.background);
		}

		tracks.forEach(t => {
			this.tracks[t.type] = t;
			this.fire(new LocalTrackAdded(t));
			// eslint-disable-next-line no-param-reassign
			t.track.onended = () => {
				t.stop();
				this.remove(t.type);
			};
		});
		await this.connection.negotiate(Object.values(this.tracks));
	}

	async remove(trackType) {
		const req = Array.isArray(trackType) ? trackType : [trackType];
		req.forEach(type => {
			if (type === Cam) {
				const videoTrack = this.tracks[Cam];
				if (videoTrack instanceof StreamBackgroundEffect) {
					this.background = videoTrack.options;
				} else {
					this.background = null;
				}
			}
		});
		await super.remove(trackType);
	}
}

export default MaskedTrackController;
