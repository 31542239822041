import LightTheme from 'calls/styles/LightTheme.js';

const RightLateralIcon = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='48'
		height='48'
		viewBox='0 0 48 48'
		fill='none'
		color={LightTheme.colors.grayZero}
		{...props}>
		<rect
			x='10'
			y='2'
			width='28'
			height='44'
			rx='4'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M26.8715 16.8225C28.1856 16.7265 29.4898 16.5246 30.7714 16.2187C31.5607 13.0821 31.5607 9.79874 30.7714 6.66209C26.8316 5.7793 22.7451 5.7793 18.8053 6.66209C18.156 9.33294 18.0432 12.1061 18.4735 14.8209'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M27.2795 12.5146C27.3248 13.2154 27.1067 13.9078 26.668 14.4561C26.2293 15.0045 25.6017 15.3692 24.9081 15.4789L24.6307 15.5116C24.5038 15.5116 24.3841 15.5116 24.2717 15.5116C23.7271 15.4771 23.2041 15.2861 22.7654 14.9616C22.3268 14.6371 21.9911 14.1928 21.7988 13.6822C21.6065 13.1715 21.5657 12.6162 21.6813 12.0829C21.7969 11.5497 22.0641 11.0611 22.4506 10.676C22.8371 10.2908 23.3266 10.0255 23.8603 9.9118C24.394 9.79811 24.9492 9.84092 25.4591 10.0351C25.9691 10.2292 26.4121 10.5665 26.735 11.0063C27.058 11.4462 27.247 11.9699 27.2795 12.5146Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M25.6042 19.8467L21.9818 21.7939L20.2793 22.7077C19.9366 22.8909 19.5351 22.9309 19.163 22.8187C18.7909 22.7066 18.4784 22.4515 18.294 22.1094C18.1687 21.8797 18.1085 21.6201 18.1199 21.3587L18.0003 15.4681C17.9967 15.276 18.031 15.085 18.1012 14.9061C18.1715 14.7273 18.2763 14.564 18.4096 14.4256C18.5429 14.2872 18.7022 14.1764 18.8784 14.0996C19.0545 14.0227 19.2441 13.9814 19.4362 13.9778C19.6284 13.9742 19.8193 14.0085 19.9982 14.0788C20.1771 14.149 20.3404 14.2538 20.4788 14.3871C20.6172 14.5204 20.7279 14.6797 20.8048 14.8559C20.8816 15.032 20.923 15.2216 20.9266 15.4137L21.0027 18.9981L22.1993 18.3509L23.3143 17.7526L22.2646 18.1605C22.8085 16.6104 23.3851 16.7246 24.685 16.643C25.3105 16.6049 25.7293 16.4363 26.1373 16.5723C26.454 16.7043 26.718 16.9377 26.8879 17.2359C28.1008 18.9981 28.7481 24.0403 28.8895 25.1444C29.1016 26.7762 29.2158 27.5648 28.7644 28.4841C28.3636 29.2522 27.7346 29.8774 26.964 30.2735L23.5102 33.7274L27.4971 38.1712C27.8577 38.5694 28.0454 39.0945 28.0189 39.631C27.9924 40.1676 27.7538 40.6716 27.3557 41.0322C26.9575 41.3929 26.4324 41.5806 25.8959 41.5541C25.3593 41.5275 24.8553 41.289 24.4946 40.8908L24.3967 40.782L19.2295 35.0274C19.0008 34.7766 18.839 34.4722 18.7591 34.1423C18.6793 33.8124 18.6839 33.4676 18.7726 33.14C18.8697 32.7509 19.082 32.4001 19.3818 32.1337L23.4394 27.4887C23.3415 27.2766 23.2328 27.0264 23.1294 26.749C22.569 25.2255 22.1929 23.6405 22.0089 22.0278C23.2517 21.3764 24.4524 20.6479 25.6042 19.8467V19.8467Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M19.2023 29.5662L22.8955 26.9336C22.9662 27.124 23.0423 27.3035 23.113 27.4775L19.186 31.9703C18.9585 32.1708 18.7735 32.415 18.642 32.6882L18.4027 31.7037C18.3047 31.3084 18.3286 30.8926 18.4713 30.5111C18.614 30.1295 18.8688 29.8001 19.2023 29.5662V29.5662Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M19.3655 35.5713L24.2607 41.0105C24.0574 41.355 23.756 41.6311 23.395 41.8035C23.034 41.9759 22.6298 42.0368 22.234 41.9784C21.8382 41.9201 21.4688 41.7451 21.1729 41.4758C20.8771 41.2065 20.6682 40.8551 20.573 40.4665L19.3655 35.5713Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
		<path
			d='M21.2964 16.6211C22.0416 16.7244 22.7867 16.7951 23.5374 16.8332'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
	</svg>
);

export default RightLateralIcon;
