import React, { useEffect, useRef, useState } from 'react';
import { Button, Loader } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { getAccountCreationStatus, retryAccountCreationStatus } from 'api/activeDirectory.js';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';

const taskStatusType = {
	FAILED: 'Failed',
	COMPLETED: 'Completed',
	RUNNING: 'Running',
	RETRY: 'Retry',
};

const ADUserSynchronization = ({ setAdUserCreated, orchestrationTaskId, setOrchestrationTaskId }) => {
	const history = useHistory();

	const intervalRef = useRef(null);
	const [taskStatus, setTaskStatus] = useState(taskStatusType.RUNNING);

	const signOut = () => {
		history.push('/logout');
		setOrchestrationTaskId('');
	};

	useEffect(() => {
		const handleAccountCreated = () => {
			getStorage().setItem('tempUserCreated', true);
			setOrchestrationTaskId('');
			setAdUserCreated(true);
		};

		const retryFetchAccountCreationStatus = async () => {
			const response = await retryAccountCreationStatus(orchestrationTaskId);
			if (response.error || response.orchestrationStatus === taskStatusType.FAILED) {
				setTaskStatus(taskStatusType.FAILED);
				return;
			}
			setTaskStatus(response.orchestrationStatus);
			if (response.orchestrationStatus === taskStatusType.COMPLETED) {
				handleAccountCreated();
			}
		};

		const fetchAccountCreationStatus = async () => {
			const response = await getAccountCreationStatus(orchestrationTaskId);
			if (response.error || response.orchestrationStatus === taskStatusType.FAILED) {
				setTaskStatus(taskStatusType.RETRY);
				return;
			}
			setTaskStatus(response.orchestrationStatus);
			if (response.orchestrationStatus === taskStatusType.COMPLETED) {
				handleAccountCreated();
			}
		};

		if (taskStatus === taskStatusType.RUNNING) {
			fetchAccountCreationStatus();
			intervalRef.current = setInterval(() => {
				if (taskStatus === taskStatusType.RUNNING) {
					fetchAccountCreationStatus();
				}
			}, 10000);
		}

		if (taskStatus === taskStatusType.RETRY) {
			retryFetchAccountCreationStatus();
		}

		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
	}, []);

	return (
		<div className='text-align-center flex account-creation'>
			{[taskStatusType.RUNNING, taskStatusType.RETRY].includes(taskStatus) && (
				<>
					<Loader />
					<span>{translate('accountBeingSynchronizedMayTakeMinutes')}</span>
				</>
			)}
			{taskStatus === taskStatusType.FAILED && (
				<>
					<i className='material-icons-outlined'>report</i>
					<span>{translate('accountSynchronizationFailed')}</span>
					<Button text={translate('logOut')} onClick={signOut} />
				</>
			)}
		</div>
	);
};

export default ADUserSynchronization;
