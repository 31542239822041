import translate from 'i18n-translations/translate.jsx';


export const getToastMessages = (intl, device = '') => [
	{
		id: 1,
		content: translate(
			'deviceIsConnected',
			device && {
				value: intl.formatMessage({ id: device }),
			}
		),
	},
	{
		id: 2,
		content: translate(
			'deviceIsConnecting',
			device && {
				value: intl.formatMessage({ id: device }),
			}
		),
	},
	{
		id: 3,
		content: translate(
			'deviceDisconnected',
			device && {
				value: intl.formatMessage({ id: device }),
			}
		),
	},
	{
		id: 4,
		content: translate(
			'deviceMeasuring',
			device && {
				value: intl.formatMessage({ id: device }),
			}
		),
	},
	{
		id: 5,
		content: translate(
			'measureCompleted',
			device && {
				value: intl.formatMessage({ id: device }),
			}
		),
	},
	{
		id: 6,
		content: translate(
			'couldNotGetPatientData',
			device && {
				value: intl.formatMessage({ id: device }),
			}
		),
	},
	{
		id: 7,
		content: translate(
			'deviceConnectFail',
			device && {
				value: intl.formatMessage({ id: device }),
			}
		),
	},
	{
		id: 8,
		content: translate(
			'deviceReconnecting',
			device && {
				value: intl.formatMessage({ id: device }),
			}
		),
	},
	{
		id: 9,
		content: translate('testStripRemoved'),
	},
	{
		id: 10,
		content: translate('problemWithReader'),
	},
	{
		id: 11,
		content: translate(
			'communicationError',
			device && {
				value: intl.formatMessage({ id: device }),
			}
		),
	},
	{
		id: 12,
		content: translate('newTestNewStrip'),
	},
	{
		id: 13,
		content: translate('problemStrip'),
	},
	{
		id: 14,
		content: translate('stripOut'),
	},
	{
		id: 15,
		content: translate('stripIn'),
	},
];
