import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { RiskLevels } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { RpmActiveTab, RpmTabFilter } from 'constants/rpm.js';
import { getRpmTabsClassName, scrollToTop } from 'infrastructure/helpers/rpmHelper.js';

const RiskLevelFilters = ({
	setSortByNameValue,
	totalCount,
	scores,
	setRiskType,
	rpmList,
	setIsRpmListLoading,
	setPagination,
	setIsRiskFilterLoading,
}) => {
	const [currentTab, setCurrentTab] = useState(RpmActiveTab.ALL_PATIENTS);
	const riskLevels = useMemo(
		() => [
			{ label: 'allPatients', value: null, score: totalCount },
			{ label: 'high', value: RiskLevels.HIGH, score: scores.high },
			{ label: 'mod', value: RiskLevels.MODERATE, score: scores.moderate },
			{ label: 'low', value: RiskLevels.LOW, score: scores.low },
		],
		[totalCount, scores]
	);

	const handleFilterTabClick = (index, tab) => {
		setIsRpmListLoading(true);
		setIsRiskFilterLoading(true);
		setCurrentTab(index);
		scrollToTop(rpmList);
		if (![RpmTabFilter.ALL_PATIENTS].includes(tab)) {
			setRiskType(tab);
			return;
		}
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
		setSortByNameValue(null);
		setRiskType(null);
	};

	return (
		<div className='flex hospital-at-home-filters'>
			<div className='flex hospital-at-home-tabs'>
				{Object.values(RpmTabFilter).map((tab, index) => (
					<div
						key={tab}
						className={classNames({ active: currentTab === index }, getRpmTabsClassName(index))}
						onClick={() => handleFilterTabClick(index, tab)}>
						{translate(tab)}
						<span className='counter'>{riskLevels.find(risk => risk?.label === tab)?.score ?? 0}</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default React.memo(RiskLevelFilters);
