import { useMemo } from 'react';
import CustomSummaryChart from 'components/DashboardCharts/PatientCentric/AI/Summaries/CustomSummaryChart.jsx';
import { PatientAiSetting } from 'constants/enums.js';
import { AiAlertTypeChartDetails } from 'constants/alerts.js';

const AlertTypesSummary = ({ aiAlertTypes, totalAiAlertTypes, isLoading }) => {
	const chartData = useMemo(() => {
		const aiSettingMap = {
			[PatientAiSetting.FALL_DETECTED]: 'fallDetection',
			[PatientAiSetting.PATIENT_GETTING_OUT_OF_BED]: 'getOutOfBed',
			[PatientAiSetting.RAILS]: 'rails',
			[PatientAiSetting.PATIENT_MOBILITY]: 'patientMobility',
			[PatientAiSetting.PRESSURE_INJURY]: 'pressureInjury',
		};

		const idToIndexMap = AiAlertTypeChartDetails.reduce((map, item, index) => {
			map[item.id] = index;
			return map;
		}, {});

		const sortedData = aiAlertTypes.sort((a, b) => idToIndexMap[a.aiSettingType] - idToIndexMap[b.aiSettingType]);

		const result = aiAlertTypes.reduce((acc, { aiSettingType, count, percentage }) => {
			const key = aiSettingMap[aiSettingType];
			acc[key] = count;
			acc[`${key}Percentage`] = percentage;
			return acc;
		}, {});

		return { result, sortedData };
	}, [aiAlertTypes]);

	return (
		<CustomSummaryChart
			total={totalAiAlertTypes}
			chartData={chartData.result}
			dataSets={chartData.sortedData.map(item => item.count)}
			chartDetails={AiAlertTypeChartDetails}
			isLoading={isLoading}
			title='aiAlertTypes'
			displayInSeconds={false}
		/>
	);
};
export default AlertTypesSummary;
