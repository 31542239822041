import React, { useState, useEffect } from 'react';
import { APP_CONFIG } from 'constants/global-variables.js';
import { getExternalDevices } from 'api/lifeSignals.js';
import { WearableProviderIds, WearablesStatus } from 'constants/enums.js';
import { getConfigurationValue, updateConfigsList } from 'infrastructure/helpers/commonHelpers.js';
import {
	configurableMonitoringMenu,
	MonitoringSettings,
	SettingsCategory,
	UserSettingTypes,
} from 'constants/configurationEnums.js';
import { getUserRole } from 'infrastructure/auth.js';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { getUserPreferences } from 'api/users.js';
import Alert from 'components/Alert.jsx';

const LifeSignalsPopup = props => {
	const [error, setError] = useState('');
	const [lifeSignalsWearableId, setLifeSignalsWearableId] = useState('');
	const [adminConfigurations, setAdminConfigurations] = useState(configurableMonitoringMenu(getUserRole()));

	useEffect(() => {
		const fetchRoomSettings = async () => {
			const [adminRoomSettings, myRoomSettings] = await Promise.all([
				getRoomSettings(props.feed.roomId, SettingsCategory.MONITORING),
				getUserPreferences(UserSettingTypes.Monitoring, props.feed.roomId),
			]);
			const responseError = adminRoomSettings.error || myRoomSettings.error;
			if (responseError) {
				setError(responseError.message);
				return;
			}
			const response = updateConfigsList(adminRoomSettings, myRoomSettings);
			if (response.error) {
				setError(response.error);
				return;
			}
			setAdminConfigurations(response.configs);
		};
		fetchRoomSettings();
	}, [props.feed.roomId]);

	useEffect(() => {
		const getWearable = async () => {
			const response = await getExternalDevices({ patientId: props.feed.deviceOwner?.userId, status: WearablesStatus.ACTIVE });
			if (response.error) {
				setError(response.error.message);
				return;
			}
			if (response?.patientExternalIotDevices?.length > 0) {
				if (
					getConfigurationValue(adminConfigurations[MonitoringSettings.LifeSignals]) &&
					response.patientExternalIotDevices.some(
						wearable => wearable.externalIotDeviceType?.vendorId === WearableProviderIds.LIFE_SIGNALS
					)
				) {
					const found = response.patientExternalIotDevices.find(
						wearable => wearable.externalIotDeviceType.vendorId === WearableProviderIds.LIFE_SIGNALS
					);
					if (found) {
						setLifeSignalsWearableId(found.iotDeviceId);
					}
				}
			}
		};

		if (
			!props.isDefaultOwner &&
			props.feed.deviceOwner?.userId &&
			(getConfigurationValue(adminConfigurations[MonitoringSettings.BiobeatVitalSigns]) ||
				getConfigurationValue(adminConfigurations[MonitoringSettings.LifeSignals]))
		) {
			getWearable();
		}
	}, [props.feed.deviceId, props.feed.deviceOwner?.userId, props.isDefaultOwner, adminConfigurations]);
	return (
		<div className='monitoring-timeline-wrapper no-padding'>
			<span className='timeline-close cursor-pointer frame-timeline-close' onClick={() => props.onClose()}>
				<i className='material-icons-outlined'>close</i>
			</span>
			{lifeSignalsWearableId && (
				<iframe
					src={`${APP_CONFIG.lifeSignalsUrl}/#/dashboard/dashboard-zoom-view/${lifeSignalsWearableId}`}
					title='LifeSignals'
				/>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default LifeSignalsPopup;
