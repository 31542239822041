import { getMultipleLevelFleetDevices } from 'api/fleetManagement.js';
import FleetManagementDeviceActions from 'components/FleetManagement/FleetManagementDeviceActions.jsx';
import FleetManagementDeviceStatus from 'components/FleetManagement/FleetManagementDeviceStatus.jsx';
import { Alert, CustomTable, Input } from 'components/index.js';
import { DeviceConnectionStatus } from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';
import { isValidJSON } from 'infrastructure/helpers/commonHelpers.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

const DiagnosticDeviceIcons = {
	CAM: 'videocam_off',
	MIC: 'mic_off',
	LS: 'volume_off',
	RS: 'volume_off',
};

const ptzDevice = 'PTZ Camera';
const getMacAddress = macAddress => (macAddress ? macAddress.trim().replace(' ', ' | ') : 'N/A');

const getOnlineDevices = devices => {
	return devices.reduce((accumulator, currentValue) => {
		if (currentValue.isOnline) {
			accumulator.push(currentValue.solHelloDeviceId);
		}
		currentValue.connectedDevices.forEach(companion => {
			if (companion.isOnline && companion.deviceType !== ptzDevice) {
				accumulator.push(companion.solHelloDeviceId);
			}
		});
		return accumulator;
	}, []);
};

const FleetManagementDevices = ({ setFilterValues, filterValues, pagination, setPagination }) => {
	const intl = useIntl();
	const socket = useContext(SocketContext);
	const delayTimerRef = useRef(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [devices, setDevices] = useState([]);
	const [onlineDevices, setOnlineDevices] = useState([]);

	const DiagnosticDeviceTooltip = {
		CAM: intl.formatMessage({ id: 'deviceCameraOff' }),
		MIC: intl.formatMessage({ id: 'microphoneOff' }),
		LS: intl.formatMessage({ id: 'leftSpeakerOff' }),
		RS: intl.formatMessage({ id: 'rightSpeakerOff' }),
	};

	useEffect(() => {
		const onDeviceOffline = data => setOnlineDevices(previousState => [...previousState.filter(id => id !== data.helloDeviceId)]);
		const onDeviceOnline = data => setOnlineDevices(previousState => [...previousState, data.helloDeviceId]);

		socket.on(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
		socket.on(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);
		return () => {
			socket.off(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
			socket.off(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);
		};
	}, [socket]);

	useEffect(() => {
		const lastLevelIds = filterValues[filterValues.lastLevel.name].map(item =>
			isValidJSON(item.value) ? JSON.parse(item.value).id : item.value
		);

		const fetchDevices = async () => {
			const params = {
				...filterValues,
				currentPage: pagination.pageIndex,
				pageSize: pagination.pageSize,
				levelType: filterValues.lastLevel.id,
				levelIds: lastLevelIds,
				...(filterValues.status && { isOnline: filterValues.status === DeviceConnectionStatus.ONLINE }),
				connectionType: filterValues.connection,
			};

			const response = await getMultipleLevelFleetDevices(params);
			if (response.error) {
				setError(response.error.message);
				return;
			}

			setPagination(prevState => ({ ...prevState, totalCount: response.totalRows }));
			setDevices(pagination.pageIndex === 1 ? response.data : prev => [...prev, ...response.data]);
			setOnlineDevices(
				pagination.pageIndex === 1 ? getOnlineDevices(response.data) : prev => [...prev, ...getOnlineDevices(response.data)]
			);
			setIsLoading(false);
		};
		fetchDevices();

		return () => {
			clearTimeout(delayTimerRef.current);
		};
	}, [filterValues, pagination.pageIndex, pagination.pageSize]);

	const devicesHeader = [
		{ title: intl.formatMessage({ id: 'room' }), id: 'roomName' },
		{ title: intl.formatMessage({ id: 'deviceModel' }), id: 'deviceModel' },
		{ title: intl.formatMessage({ id: 'serialNumber' }), id: 'serialNumber', columnClass: 'break-word' },
		{ title: `${intl.formatMessage({ id: 'device' })} ${intl.formatMessage({ id: 'status' })}`, id: 'isOnline' },
		{ title: intl.formatMessage({ id: 'macUpperAddress' }), id: 'macAddress', columnClass: 'break-word' },
		{ title: intl.formatMessage({ id: 'network' }), id: 'network' },
		{ title: intl.formatMessage({ id: 'osVersion' }), id: 'firmwareRevision', columnClass: 'break-word' },
		{ title: intl.formatMessage({ id: 'appVersion' }), id: 'appVersion', columnClass: 'break-word' },
		{ title: `${intl.formatMessage({ id: 'connected' })} ${intl.formatMessage({ id: 'devices' })}`, id: 'connectedDevices' },
		{ title: intl.formatMessage({ id: 'actions' }), id: 'actions' },
	];

	const getConnectedDevices = children => {
		if (children.length === 0) {
			return [];
		}

		const shouldRenderActions = child => {
			const isPtzDevice = child.deviceType === ptzDevice;
			return !isPtzDevice || child.isOnline;
		};

		return children.map(child => {
			return {
				...child,
				id: child.deviceModel,
				serialNumber: child.serialNumber ?? 'N/A',
				isOnline: (
					<FleetManagementDeviceStatus
						isPtzDevice={child.deviceType === ptzDevice}
						onlineDevices={onlineDevices}
						lastOnline={child.lastOnline}
						solHelloDeviceId={child.solHelloDeviceId}
					/>
				),
				macAddress: getMacAddress(child.macAddress),
				network: (
					<>
						{child.ssId && <span>SSID {child.ssId} </span>}
						{child.ipAddress && <span>IP {child.ipAddress}</span>}
						{!child.ssId && !child.ipAddress && 'N/A'}
					</>
				),
				firmwareRevision: child.firmwareRevision ?? child.osVersion ?? 'N/A',
				appVersion: child.appVersion ?? 'N/A',
				connectedDevices: child.deviceType !== ptzDevice && '',
				actions: (
					<>
						{shouldRenderActions(child) && (
							<FleetManagementDeviceActions
								device={child}
								onlineDevices={onlineDevices}
								isPtzDevice={child.deviceType === ptzDevice}
							/>
						)}
					</>
				),
			};
		});
	};

	const devicesRows = () => {
		if (devices.length === 0) {
			return [];
		}

		return devices.map(device => {
			const isAnyChildOffline = device.connectedDevices.some(device => !onlineDevices.includes(device.solHelloDeviceId));
			const isAnyIssue = device.deviceDiagnostics.length > 0;

			return {
				children: getConnectedDevices(device.connectedDevices),
				id: device.solHelloDeviceId,
				roomName: device.roomName ?? 'N/A',
				deviceModel: device.deviceModel,
				serialNumber: device.serialNumber,
				isOnline: (
					<FleetManagementDeviceStatus
						isPtzDevice={device.deviceType === ptzDevice}
						onlineDevices={onlineDevices}
						lastOnline={device.lastOnline}
						solHelloDeviceId={device.solHelloDeviceId}
					/>
				),
				macAddress: getMacAddress(device.macAddress),
				network: (
					<>
						{device.ssId && <span>SSID: {device.ssId} </span>}
						{device.ipAddress && <span>IP: {device.ipAddress}</span>}
						{!device.ssId && !device.ipAddress && 'N/A'}
					</>
				),
				firmwareRevision: device.firmwareRevision ?? 'N/A',
				appVersion: device.appVersion ?? 'N/A',
				connectedDevices: (
					<div className='connected-devices'>
						<span>{device.connectedDevices.length}</span>
						{isAnyChildOffline && (
							<span
								data-tooltip={intl.formatMessage({ id: 'deviceOfflineStatus' })}
								data-position='top'
								className='material-symbols-outlined icon'>
								warning
							</span>
						)}
						{isAnyIssue &&
							device.deviceDiagnostics.map(issue => (
								<span
									key={issue.id}
									data-tooltip={DiagnosticDeviceTooltip[issue.uniqueName] ?? null}
									data-position='top'
									className='material-symbols-outlined icon'>
									{DiagnosticDeviceIcons[issue.uniqueName] ?? null}
								</span>
							))}
					</div>
				),
				actions: <FleetManagementDeviceActions device={device} onlineDevices={onlineDevices} isHelloDevice />,
			};
		});
	};

	const handleSearch = e => {
		clearTimeout(delayTimerRef.current);

		const timer = setTimeout(() => {
			setPagination({ pageIndex: 1, pageSize: 10, totalCount: 0 });
			setFilterValues(prevState => ({ ...prevState, search: e.target.value }));
		}, 500);

		delayTimerRef.current = timer;
	};
	return (
		<div className='fleet-component table-devices'>
			<div>
				<div className='table-devices-header'>
					<label>{intl.formatMessage({ id: 'devices' })}</label>
					<Input
						type='search'
						prefixIcon='search'
						variant='filled'
						placeholder={intl.formatMessage({ id: 'searchByRoomOrSN' })}
						onChange={handleSearch}
					/>
				</div>
				<CustomTable
					headers={devicesHeader}
					rows={devicesRows()}
					isLoading={isLoading}
					stickyHeader={true}
					isNested={true}
					setPagination={setPagination}
				/>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</div>
	);
};

export default FleetManagementDevices;
