import * as React from 'react';
const ClearData = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props} viewBox='0 0 16 16'>
		<path stroke='currentColor' d='m12.334 4.668-.46 7.92a1.5 1.5 0 0 1-1.498 1.413H5.625a1.5 1.5 0 0 1-1.497-1.413l-.46-7.92' />
		<path stroke='currentColor' strokeLinecap='round' d='M2 4h12' />
		<path stroke='currentColor' d='M10 4V3a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v1' />
		<path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' d='M6.834 11.333 6.667 6m2.667 0-.167 5.333' />
	</svg>
);
export default ClearData;
