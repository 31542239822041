import moment from 'moment';
import { useIntl } from 'react-intl';
import { Gender } from 'constants/enums.js';
import { MaritalStatusList, TestTypes } from 'constants/visitEnums.js';
import { getFirstName, getLastName } from 'infrastructure/helpers/commonHelpers.js';
import { monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import translate from 'i18n-translations/translate.jsx';

const InstructionForDiagnosticResearch = props => {
	const intl = useIntl();
	const performerEmail = props.selectedVisit?.performer.email;
	const performerName = `${props.selectedVisit?.performer.firstName} ${props.selectedVisit?.performer.lastName}`;

	let genderId = null;
	const maritalStatusList = [
		{ id: MaritalStatusList.UNMARRIED.id, name: 'Beqar/e' },
		{ id: MaritalStatusList.MARRIED.id, name: 'I/e martuar' },
		{ id: MaritalStatusList.DIVORCED.id, name: 'I/e divorcuar' },
		{ id: MaritalStatusList.WIDOWED.id, name: 'I/e ve' },
	];

	const maritalStatus =
		props.patient?.maritalStatusId !== 0 ? maritalStatusList.find(item => item.id === props.patient?.maritalStatusId) : '';

	if (props.isVisitsPage) {
		genderId = props.patient?.genderId;
	} else {
		genderId = props.selectedVisit?.patient.genderId;
	}

	const laboratoryTests = [
		{ id: TestTypes.CHOLESTEROL, description: 'Kolesteroli' },
		{ id: TestTypes.CREATININE, description: 'Kreatinina' },
		{ id: TestTypes.GLUCOSE, description: 'Glukoza' },
		{ id: TestTypes.HEMOGRAM, description: 'Hemogrami' },
		{ id: TestTypes.SEDIMENTATION, description: 'Sedimentimi' },
		{ id: TestTypes.TRIGLYCERIDES, description: 'Trigliceridet' },
		{ id: TestTypes.UREA, description: 'Urea' },
		{ id: TestTypes.URINE, description: 'Urina' },
	];

	const radiologyTests = [
		{ id: TestTypes.PULMONARY, description: 'RTG Pulmonare' },
		{ id: TestTypes.SKELETON, description: 'RTG - Skeleti' },
		{ id: TestTypes.CHEST, description: 'RTG - Krahrori' },
		{ id: TestTypes.UPPER_EXTREMITIES, description: 'RTG - Ekstremitetet e sipërme' },
		{ id: TestTypes.LOWER_EXTREMITIES, description: 'RTG - Ekstremitetet e poshtme' },
		{ id: TestTypes.SPINE, description: 'RTG - Shtylla kurrizore' },
		{ id: TestTypes.ULTRASONOGRAPHY, description: 'Echo - Ultrasonografi' },
	];

	const mergedMedics = props.selectedVisit.parenteralTherapiesReferrals.reduce((acc, curr) => [...acc, ...curr.medications], []);

	return (
		<div
			className='primary-care-form position-relative'
			ref={props.reportRef}
			style={{ position: 'fixed', width: '8.5in', minHeight: '11in', zIndex: '-1' }}>
			<div>
				<div className='report-header'>
					<div className='report-title'>
						<span className='text-transform-uppercase'>Qeveria e Kosovës - Ministria e Shëndetësisë</span>
						<span className='text-transform-uppercase'> Vlada Kosova - Mistarstvo Zdravstva</span>
						<h1 className='text-transform-uppercase'>
							RAPORTI MJEKESOR <br />
						</h1>
					</div>
				</div>
				<div className='flex full-width primary-care-form-table'>
					<div className='flex-1'>
						<div>
							<span>Institucioni Shëndetësor / Zdravstvena Institucija:</span>
							<p>{props.healthSystem.name}</p>
						</div>
						<div>
							<span>Public / Javna:</span>
							<p>Po</p>
						</div>
						<div>
							<span>Vendi / Mesto:</span>
							<p>{props.healthSystem.name}</p>
						</div>
						<div>
							<span>Nr. i Kartelës Shëndetësore / Br. Zdravstvenog Kartona:</span> <p>1239102302</p>
						</div>
						<div>
							<span>Nr. i Regjistrit / Br. Registra:</span>
						</div>
						<div>
							<span>Mjeku / Lekar:</span>
							<p>Dr. {performerName}</p>
						</div>
						<div>
							<span>Tel.:</span>
						</div>
						<div>
							<span>E-mail:</span>
							<p>{performerEmail}</p>
						</div>
						<div>
							<span>Alergjitë / Alergije:</span>
							{props.allergies.map(item => (
								<p>{`${item}, `}</p>
							))}
						</div>
					</div>
					<div className='flex-1'>
						<div>
							<span>Nr. Personal / Licni Br.:</span>
							<p>{!props.isVisitsPage ? props.selectedVisit?.patient.idCard : props.patient?.idCard}</p>
						</div>
						<div>
							<span>Emri / Ime:</span>
							<p>{!props.isVisitsPage ? props.selectedVisit?.patient.firstName : getFirstName(props.patient?.fullName || '')}</p>
						</div>
						<div>
							<span>Emri i prindit / Ime roditelja:</span>
							<p>{!props.isVisitsPage ? props.selectedVisit?.patient.parentName : props.patient?.parentName}</p>
						</div>
						<div>
							<span>Mbiemri / Prezime:</span>
							<p>{!props.isVisitsPage ? props.selectedVisit?.patient.lastName : getLastName(props.patient?.fullName || '')}</p>
						</div>
						<div>
							<span>Viti i lindjes / Godina rodjenja:</span>
							<p>
								{!props.isVisitsPage
									? moment(props.selectedVisit?.patient.dateOfBirth).format('YYYY')
									: moment().subtract(props.patient?.age, 'years').format('YYYY')}
							</p>
						</div>
						<div>
							<span>Gjinia / Pol:</span>
							<p>{genderId === Gender.MALE ? 'Mashkull' : 'Femër'}</p>
						</div>
						<div>
							<span>Adresa / Adresa:</span>
							<p>{props.patient?.address}</p>
						</div>
						<div>
							<span>Profesioni / Profesija:</span>
							<p>{props.patient?.profession}</p>
						</div>
						<div>
							<span>Statusi bashkëshortor / Bracno stanje:</span>
							<p>{maritalStatus.name}</p>
						</div>
					</div>
				</div>
				<div className='flex full-width medical-content'>
					<div className='full-width'>
						<p>ANKESAT KRYESORE</p>
						<span>{props.selectedVisit.mainConcerns}</span>
					</div>
					<div className='full-width therapy-test'>
						<p>EKZAMINIMET</p>
						<span>{props.selectedVisit.examinations}</span>
						<div className='no-border-top no-padding-top'>
							{props.selectedVisit.measurementsForVisit &&
								props.selectedVisit.measurementsForVisit.map(item => (
									<span>
										{intl.formatMessage({ id: `${item.measurementType}` })}: {item.measurementValue}
									</span>
								))}
						</div>
					</div>
					<div className='full-width drugs'>
						<p>DIAGNOZA</p>
						<div className='no-border-top no-padding-top'>
							{props.selectedVisit?.diagnostifications.map(code => (
								<span>{code}</span>
							))}
						</div>
					</div>
					<div className='full-width therapy-test'>
						<p>TERAPIA</p>
						<div className='no-border-top no-padding-top'>
							{mergedMedics.map(item => (
								<span>
									{translate('medication')}: {item.name}, {translate('dosage')}: {item.dosage}, {translate('frequency')}:{' '}
									{item.frequency}, {translate('duration')}: {item.duration}
								</span>
							))}
						</div>
					</div>
					<div className='full-width drugs'>
						<p>RP</p>
						<div className='no-border-top no-padding-top'>
							{props.selectedVisit?.notes.map(drug => (
								<span>{drug.text}</span>
							))}
						</div>
					</div>
					<div className='full-width recommendations'>
						<p>REKOMANDIMET/HULUMTIMET</p>
						<span>{props.selectedVisit.recommendations}</span>
						<div className='no-border-top no-padding-top'>
							{props.selectedVisit?.diagnosticResearches.map(item => (
								<span>
									{laboratoryTests.find(el => el.id === item.testType)?.description}
									{radiologyTests.find(el => el.id === item.testType)?.description}
								</span>
							))}
						</div>
					</div>
				</div>
				<div className='flex signature-instruction full-width'>
					<div className='date-input'>
						<span>{monthDayYear(props.selectedVisit.visitDate)}</span>
						<label>Data / Datum:</label>
					</div>
					<div className=''>
						<span className='flex'>
							{props.currentSignature && <img alt='signature' src={props.currentSignature} />}
							{props.stamp && <img alt='stamp' src={props.stamp} />}
						</span>
						<label>{translate('doctorsStampSignature')}</label>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InstructionForDiagnosticResearch;
