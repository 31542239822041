/* eslint-disable no-console */
import { sendStats } from 'api/callStats.js';
import { CallStatsLogType } from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';

class CallStats {
	constructor() {
		this.outgoingMetricsBuffer = [];
		this.flushInterval = 30000; // ms
		this.candidateTypeMap = new Map();
	}

	addPeerConnection = async ({ pcObject, pushInterval, conferenceId, participantId, remoteParticipantId, userId, socket }) => {
		this.startSendingStats();

		const getStatsInterval = setInterval(async () => {
			if (pcObject.connectionState === 'closed') {
				this.removePeerConnection(getStatsInterval);
				this.stop();
				return;
			}

			const stats = await pcObject.getStats();

			this.pushStats({ stats, conferenceId, participantId, remoteParticipantId, userId, socket });
		}, pushInterval);
	};

	stop = () => {
		// Send the remaining stats
		this.sendStats();
	};

	removePeerConnection = getStatsInterval => {
		clearInterval(getStatsInterval);
	};

	startSendingStats = () => {
		setTimeout(this.sendStats, this.flushInterval);
	};

	sendStats = async () => {
		if (this.outgoingMetricsBuffer.length === 0) {
			return;
		}

		const batch = this.prepareBatch();

		try {
			await sendStats(batch);
		} catch (ex) {
			console.error('error while sending call stats logs', ex);
		} finally {
			setTimeout(this.sendStats, this.flushInterval);
		}
	};

	prepareBatch = () => {
		const batchSize = this.outgoingMetricsBuffer.length;
		return {
			data: { values: this.outgoingMetricsBuffer.splice(0, batchSize) },
		};
	};

	pushStats = ({ stats, conferenceId, participantId, remoteParticipantId, userId, socket }) => {
		const result = [];
		let selectedCandidatePairs = null;
		stats.forEach(report => {
			if (report.type === 'certificate') {
				return;
			}
			if (report.id === 'T01') {
				if (!selectedCandidatePairs) {
					selectedCandidatePairs = report.selectedCandidatePairId;
				}
			}

			result.push(report);
		});

		if (selectedCandidatePairs) {
			const selectedCandidatePair = result.find(report => report.id === selectedCandidatePairs);
			if (!selectedCandidatePair) {
				return;
			}
			const localCandidateId = selectedCandidatePair?.localCandidateId;
			const remoteCandidateId = selectedCandidatePair?.remoteCandidateId;

			const localCandidate = result.find(report => report.id === localCandidateId);
			const remoteCandidate = result.find(report => report.id === remoteCandidateId);
			if (localCandidate?.timestamp && remoteCandidate?.timestamp) {
				delete localCandidate.timestamp;
				delete remoteCandidate.timestamp;
			}
			const candidateType = {
				localCandidate,
				remoteCandidate,
				actioneeParticipantId: remoteParticipantId,
			};
			if (!localCandidate && !remoteCandidate) {
				return;
			}
			this.sendActiveIceCandidates(candidateType, socket, conferenceId, participantId);
		}

		this.outgoingMetricsBuffer.push({
			conferenceId: conferenceId,
			userId: userId,
			participantId: participantId,
			remoteParticipantId: remoteParticipantId,
			timeStamp: this.generateTimeStamp(),
			stats: result,
		});
	};

	sendActiveIceCandidates = (candidateType, socket, conferenceId, participantId) => {
		const candidate = this.candidateTypeMap.get(candidateType.actioneeParticipantId);

		if (JSON.stringify(candidate) !== JSON.stringify(candidateType)) {
			this.candidateTypeMap.set(candidateType.actioneeParticipantId, candidateType);
			socket.emit(SocketEvents.Conference.LOGGER, {
				logType: CallStatsLogType.INFORMATION,
				conferenceId: conferenceId,
				participantId: participantId,
				key: { value: 'participant.activeIceCandidates' },
				message: 'Selected IceCandidate Pair',
				data: this.candidateTypeMap.get(candidateType.actioneeParticipantId) || {},
			});
		}
	};

	generateTimeStamp = () => {
		return Math.round(new Date().getTime());
	};
}

export default CallStats;
