import React, { useState, useEffect, useRef } from 'react';

const Dropup = props => {
	const wrapperRef = useRef();

	const [isDropUpOpen, setIsDropUpOpen] = useState(false);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleClickOutside = event => {
		if (!wrapperRef.current || wrapperRef.current.contains(event.target)) {
			return;
		}
		setIsDropUpOpen(false);
	};

	const toggleDropUp = () => {
		setIsDropUpOpen(prevState => !prevState);
	};

	return (
		<div className='call-grouping-wrapper' ref={wrapperRef}>
			<div
				className={`call-grouping-btn flex${props.isActive ? ' active' : ''}${props.isDisabled ? ' disabled' : ''}${
					isDropUpOpen ? ' dropup-is-open' : ''
				}${props.autoWidth ? ' dropup-auto-width' : ''}`}
				onClick={toggleDropUp}>
				{!props.imgIcon && (
					<i className='material-icons' onClick={props.onIconClick}>
						{props.icon}
					</i>
				)}
				{props.imgIcon && <img src={props.imgIcon} alt='icon' />}
				<span>{props.content}</span>
			</div>
			<div
				className={`${props.additionalClass ? props.additionalClass : ''} call-grouping-elements ${isDropUpOpen ? 'show' : ''}`}
				onClick={toggleDropUp}>
				{props.children}
			</div>
		</div>
	);
};

export default Dropup;
