import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { useIntl } from 'react-intl';
import { enums } from '@solaborate/calls';
import { checkActiveConference } from 'calls/api/index.js';
import translate from 'i18n-translations/translate.jsx';
import StreamSettingsView from 'calls/views/StreamSettingsView.jsx';
import { checkForPermission, checkIfMediaDevicesPlugged, getStorage } from 'infrastructure/helpers/commonHelpers.js';
import { getRoomInfo } from 'api/ehr.js';
import Alert from 'components/Alert.jsx';
import Badge from 'components/Badge.jsx';
import HelloFeatureBlock from 'components/HelloFeatureBlock.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { isChrome, isEdgeChromium } from 'react-device-detect';
import {
	StreamError,
	MediaPermissions,
	MediaTypes,
	UserPermissionDeniedErrors,
	ErrorComponentTypes,
	CallTypes,
} from 'constants/enums.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check';
import ConferenceInitData from 'calls/ConferenceInitData.js';
import { RoundingSettings } from 'constants/configurationEnums.js';
import Button from 'components/Button.jsx';

const JoinCall = () => {
	const history = useHistory();
	const location = useLocation();
	const match = useRouteMatch();
	const { deviceId, startConferenceId, queryRefToken, conferenceId, invitationSecret, iid } = match.params;
	const queryParams = new URLSearchParams(location.search);

	useEffect(() => {
		const refToken = queryRefToken ?? getStorage().getItem('ref_token');
		const integrationId = iid ?? getStorage().getItem('iframe_integration_id');
		const fetchData = async () => {
			const res = await getRoomInfo(startConferenceId, refToken);
			if (res.error) {
				return;
			}
			const metaData = JSON.parse(res.metadata);
			const conferenceData = new ConferenceInitData({
				callType: enums.CallTypes.VIDEO,
				conferenceId: startConferenceId,
				conferenceName: metaData?.patient ? `${metaData.patient.firstName} ${metaData?.patient?.lastName}` : '',
				isGuest: false,
				interpreterId: null,
				actioneeObjectId: +deviceId,
				actioneeObjectType: enums.ObjectTypes.HELLO_DEVICE,
				refToken: refToken,
				isJoin: !startConferenceId,
				integrationId: +integrationId,
			});
			history.replace('/call', conferenceData);
		};

		const initCheckConference = async () => {
			const response = await checkActiveConference(conferenceId, invitationSecret, queryParams.get('interpreterId'));
			if (!response.exists) {
				return;
			}
			let conferenceData = new ConferenceInitData({
				callType: enums.CallTypes.VIDEO,
				conferenceId: conferenceId,
				conferenceName: response.conference.name,
				isGuest: false,
				interpreterId: null,
				actioneeObjectId: +deviceId,
				actioneeObjectType: enums.ObjectTypes.HELLO_DEVICE,
				refToken: refToken,
				isJoin: !startConferenceId,
				...(integrationId ? { integrationId: +integrationId } : {}),
			});
			conferenceData = { ...conferenceData, ...response.conference };
			getStorage().removeItem('ref_token');
			getStorage().removeItem('iframe_integration_id');
			history.replace('/call', conferenceData);
		};

		if (conferenceId) {
			initCheckConference();
		} else {
			fetchData();
		}
	}, []);

	return <></>;
};

const EHRCallPatientJoinAsGuest = ({ location }) => {
	const history = useHistory();
	/** @type {import('react-router').match<{ conferenceId: string, invitationSecret: string, callType: string, deviceId: string, name: string, startConferenceId: string, refToken: string, isDirect: string, }>} */
	const match = useRouteMatch();
	const intl = useIntl();
	const busyDevices = useSelector(state => state.devices.busyDevices);
	const queryParams = new URLSearchParams(location.search);
	const [activeConference, setActiveConference] = useState(null);
	const [startConferenceData, setStartConferenceData] = useState(null);
	const [isStreamSettingsModalOpen, setIsStreamSettingsModalOpen] = useState(false);
	const [error, setError] = useState(null);
	const localTrackFactoryRef = useRef(null);
	const camStatus = useRef(null);
	const micStatus = useRef(null);
	const isAllowPermissionPrompt = useRef(false);
	const dispatch = useDispatch();
	const [currentRoom, setCurrentRoom] = useState({
		callType: null,
		deviceId: null,
		name: '',
		startConferenceId: '',
		dateOfBirth: '',
		mrn: '',
		refToken: '',
		bedIdentifier: '',
	});

	const roleRoundingConfigurations = useSelector(state => state.configurations.roleRoundingConfigurations);
	const isTalkToPatientShown = roleRoundingConfigurations[RoundingSettings.TalkToPatient];
	const isViewPatientShown = roleRoundingConfigurations[RoundingSettings.ViewPatient];

	const { callType, deviceId, startConferenceId, conferenceId, isDirect } = match.params;

	useEffect(() => {
		const bindMediaEvents = async () => {
			camStatus.current = await checkForPermission(MediaTypes.CAMERA);
			micStatus.current = await checkForPermission(MediaTypes.MICROPHONE);
			camStatus.current.onchange = onDevicePermissionChange;
			micStatus.current.onchange = onDevicePermissionChange;
		};
		bindMediaEvents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onDevicePermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			clearPermissions();
		}
	};

	const clearPermissions = () => {
		dispatch(healthSystemsActionCreators.setStreamPermissionMessage(null));
	};

	const isDeviceInCall = () => busyDevices.some(id => id === +currentRoom.deviceId);

	useEffect(() => {
		const refToken = getStorage().getItem('ref_token');
		const fetchData = async () => {
			const res = await getRoomInfo(conferenceId ?? startConferenceId, refToken);
			if (res.error) {
				return;
			}
			const metadata = JSON.parse(res.metadata);
			setCurrentRoom({
				callType,
				deviceId,
				name: metadata?.patient ? `${metadata.patient.firstName} ${metadata?.patient?.lastName}` : '',
				startConferenceId,
				dateOfBirth: metadata?.patient?.dateOfBirth,
				mrn: metadata?.patient?.mrn,
				refToken,
				bedIdentifier: metadata?.bedIdentifier,
			});
		};
		fetchData();
	}, []);

	useEffect(() => {
		const {
			conferenceId,
			invitationSecret,
			callType,
			deviceId,
			name: startName,
			startConferenceId,
			refToken: queryRefToken,
			iid,
		} = match.params;
		const refToken = queryRefToken ?? getStorage().getItem('ref_token');
		const integrationId = iid ?? getStorage().getItem('iframe_integration_id');

		const initCheckConference = async () => {
			const response = await checkActiveConference(conferenceId, invitationSecret, queryParams.get('interpreterId'));
			if (!response.exists) {
				return;
			}
			setActiveConference({ ...response.conference, refToken });
			getStorage().removeItem('ref_token');
		};
		if (conferenceId) {
			initCheckConference();
		} else {
			setStartConferenceData({
				conferenceId: startConferenceId,
				callType,
				actioneeObject: { actioneeObjectId: +deviceId, actioneeObjectType: enums.ObjectTypes.HELLO_DEVICE },
				name: startName,
				refToken,
				integrationId: +integrationId,
			});
			getStorage().removeItem('ref_token');
			getStorage().removeItem('iframe_integration_id');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match.params]);

	const joinConference = callType => {
		if (!activeConference && !startConferenceData) {
			return;
		}

		const conferenceData = new ConferenceInitData({
			callType,
			...(activeConference ? { conferenceId: activeConference.id } : { conferenceId: startConferenceData.conferenceId }),
			conferenceName: activeConference ? activeConference.name : startConferenceData.name,
			...(activeConference ? { conferenceLink: activeConference.link } : null),
			isGuest: false,
			...(activeConference?.interpreter?.uid ? { interpreterId: activeConference.interpreter.uid } : null),
			...(startConferenceData
				? {
						actioneeObjectId: startConferenceData.actioneeObject.actioneeObjectId,
						actioneeObjectType: startConferenceData.actioneeObject.actioneeObjectType,
				  }
				: null),
			refToken: activeConference ? activeConference.refToken : startConferenceData.refToken,
			isJoin: !startConferenceData,
			...(startConferenceData?.integrationId ? { integrationId: +startConferenceData.integrationId } : {}),
		});

		if (localTrackFactoryRef.current) {
			localTrackFactoryRef.current.destroy();
		}

		history.replace(`/call`, { ...conferenceData });
	};

	const showAllowPermissionModal = () => {
		isAllowPermissionPrompt.current = true;
		setTimeout(() => {
			if (!isAllowPermissionPrompt.current) {
				return;
			}

			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
				})
			);
		}, 500);
	};

	const handlePermissionErrors = async (callType, permissionError) => {
		const { camera, microphone } = await checkIfMediaDevicesPlugged();
		if ([enums.CallTypes.VIDEO, enums.CallTypes.AUDIO].includes(callType) && (!camera || !microphone)) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: microphone ? StreamError.MICROPHONE_NOT_FOUND.type : StreamError.CAMERA_NOT_FOUND.type,
				})
			);
			return;
		}
		if (micStatus.current.state === MediaPermissions.DENIED) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: StreamError.MICROPHONE_BLOCKED.type,
				})
			);
			return;
		}

		const { type, name } = permissionError;
		if (type === MediaPermissionsErrorType.UserPermissionDenied) {
			if (name === UserPermissionDeniedErrors.NotAllowedError) {
				dispatch(
					healthSystemsActionCreators.setStreamPermissionMessage({
						component: isChrome || isEdgeChromium ? ErrorComponentTypes.Popup : ErrorComponentTypes.Modal,
						type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
					})
				);
			}
		}
	};

	const audioCall = async () => {
		try {
			showAllowPermissionModal();
			await requestMediaPermissions({ audio: true, video: false });
			joinConference(CallTypes.VIDEO);
		} catch (permissionError) {
			handlePermissionErrors(enums.CallTypes.AUDIO, permissionError);
		}
		isAllowPermissionPrompt.current = false;
	};

	const viewPatient = async () => {
		try {
			showAllowPermissionModal();
			await requestMediaPermissions({ audio: true, video: false });
			// eslint-disable-next-line no-empty
		} catch (e) {}
		isAllowPermissionPrompt.current = false;
		joinConference(CallTypes.SECURITY_CAM);
	};

	const isDirectCall = () => (isDirect === 'true' ? true : false);

	return (
		<>
			{isDirectCall() && <JoinCall />}
			{!isDirectCall() && (
				<div className='ehr-room-wrapper'>
					<div className='room-actions ehr-room'>
						{[currentRoom.name, currentRoom.mrn, currentRoom.dateOfBirth].find(item => item) && (
							<>
								<div className='hello-feature-grid'>
									<h3>Patient Information</h3>
								</div>
								<div className='hello-feature-grid'>
									<div className='patient-info-ehr'>
										{currentRoom.name && <p>Patient name: {currentRoom.name}</p>}
										{currentRoom.mrn && <p>MRN: {currentRoom.mrn}</p>}
										{currentRoom.dateOfBirth && <p>Date of birth: {currentRoom.dateOfBirth}</p>}
										{currentRoom.bedIdentifier && <p>Bed Identifier: {currentRoom.bedIdentifier}</p>}
									</div>
								</div>
							</>
						)}
						<div className='hello-feature-grid'>
							<h3>Call patient</h3>
						</div>
						{isDeviceInCall() && (
							<div className='hello-feature-grid'>
								<Badge text='Call in progress' variant='blue' />
							</div>
						)}
						<div className='hello-feature-grid'>
							{isViewPatientShown && (
								<HelloFeatureBlock
									title={translate('viewPatient')}
									onClick={viewPatient}
									className='hello-feature-camera-feed'
									tooltipData={intl.formatMessage({ id: 'viewPatientTooltip' })}
								/>
							)}
							{!isViewPatientShown && !isTalkToPatientShown && <p>{translate('checkFeatureAvailability')}</p>}
							{isTalkToPatientShown && (
								<HelloFeatureBlock
									onClick={audioCall}
									title={translate('talkToPatient')}
									className='hello-feature-audio'
									tooltipData={intl.formatMessage({ id: 'ttpTooltip' })}
								/>
							)}
						</div>
						{(isViewPatientShown || isTalkToPatientShown) && (
							<Button
								className='stream-settings-button'
								onClick={() => setIsStreamSettingsModalOpen(true)}
								icon='settings'
								text={translate('streamSettingsModalTitle')}
							/>
						)}
						{isStreamSettingsModalOpen && <StreamSettingsView onDismiss={() => setIsStreamSettingsModalOpen(false)} />}
						<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
					</div>
				</div>
			)}
		</>
	);
};

export default EHRCallPatientJoinAsGuest;
