import { useIntl } from 'react-intl';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import DoughnutChart from 'components/Common/Charts/DoughnutChart.jsx';
import { chartSummaryColors, chartSummaryLegend, getDoughnutChartOptions } from 'constants/fleet-management.js';

const DeviceAssignmentDoughnut = ({ chartData, isLoading }) => {
	const intl = useIntl();

	return (
		<div className='fleet-progressbar-singlebar'>
			<label>
				{intl.formatMessage({ id: 'room' })} {intl.formatMessage({ id: 'enrollment' })}
			</label>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='100%' horizAlign='center' vertAlign='center'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<div className='fleet-progressbar-content'>
					<DoughnutChart
						data={{
							labels: chartData.dataSets.map(item => item.label),
							datasets: [
								{
									data: chartData.dataSets.map(item => item.value),
									backgroundColor: chartData.dataSets.map((_, index) => chartSummaryColors[index]),
									borderWidth: 0,
								},
							],
						}}
						height='180'
						width='180'
						options={getDoughnutChartOptions(false, {
							labelText: intl.formatMessage({ id: 'rooms' }),
							text: chartData.organizationInfo.value,
						})}
					/>
					<div className='device-assignment-legend-wrapper'>
						{chartData.dataSets.map((item, index) => (
							<div key={item.label} className='legend-row'>
								<div className='legend-row-left'>
									<div className={chartSummaryLegend[index]}></div>
									<span>{item.label}</span>
								</div>
								<div className='legend-row-right'>
									<span>{item.value}</span>
									<span>({item.percentage}%)</span>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default DeviceAssignmentDoughnut;
