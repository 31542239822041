import React from 'react';
import styled, { css } from 'styled-components'; 
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import { useConferenceConfigurations } from 'calls/hooks/index.js';
import Button from 'components/Button.jsx';

/**
 * @type {import('styled-components').StyledComponent<"ul", any, { $isRightToLeft: boolean, $isDarkMode: boolean }, never>}
 */
const StyledList = styled.ul`
	border-radius: ${LightTheme.borderRadius.base}px;
	background: ${LightTheme.colors.grayZero};
	min-width: 160px;
	list-style: none;
	z-index: 3;

	li {
		color: ${LightTheme.colors.grayFive};
		> button {
			display: flex;
			align-items: center;
			background: transparent;
			padding: ${props =>
				props.$isRightToLeft
					? `${LightTheme.spacing[2]}px ${LightTheme.spacing[3]}px ${LightTheme.spacing[2]}px ${LightTheme.spacing[8]}px;`
					: `${LightTheme.spacing[2]}px ${LightTheme.spacing[8]}px ${LightTheme.spacing[2]}px ${LightTheme.spacing[3]}px;`} 
			color: ${LightTheme.colors.grayFive};
			white-space: nowrap;
			text-transform: none;
			font-size: 14px;
			width: 100%;

			span {
				margin-right: ${LightTheme.spacing[2]}px;
				flex-shrink: 0;
				color: ${LightTheme.colors.grayFive};
			}

			svg {
				margin-right: ${props => (props.$isRightToLeft ? '0' : `${LightTheme.spacing[2]}px`)};
				margin-left: ${props => (props.$isRightToLeft ? `${LightTheme.spacing[2]}px` : '0')};
				flex-shrink: 0;
				
				path, rect {
					stroke: ${LightTheme.colors.grayFive};
				}
			}

			&:hover {
				background: ${LightTheme.colors.grayOne};
			}

			> div span:last-child {
				color: ${LightTheme.colors.grayEight};
			}
		}
		.active {
			background: ${LightTheme.colors.blueFive};

			> div span {
				color: ${LightTheme.colors.blueFour};
			}

			> div span:last-child {
				color: ${LightTheme.colors.blueFour};
			}

			&:hover {
				background: ${LightTheme.colors.blueFive};
			}
		}
		span {
			color: ${LightTheme.colors.grayFive};
		}
	}

	${props =>
		props.$isDarkMode &&
		css`
			background: ${DarkTheme.colors.grayThree};
			li {
				color: ${DarkTheme.colors.grayFive};
				> button {
					color: ${DarkTheme.colors.grayFive};

					span {
						color: ${DarkTheme.colors.grayFive};
					}

					svg path,
					svg rect {
						stroke: ${DarkTheme.colors.grayFive};
					}

					&:hover {
						background: ${DarkTheme.colors.grayFour};
					}
				}
				span {
					color: ${DarkTheme.colors.grayFive};
				}
			}
		`}

	${props =>
		props.$isRightToLeft &&
		css`
			direction: rtl;
		`}

	.other-options {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: ${LightTheme.spacing[2]}px ${LightTheme.spacing[3]}px;
    	padding-bottom: 0;
		font-size: 16px;
		font-weight: 500;
		line-height: normal;
		span, i {
		    margin: 0;
		}
	}
`;

/**
 * @param {object} props
 * @param {any} props.children
 * @param {string} [props.className]
 */
const List = ({ children, className }) => {
	const conferenceConfigurations = useConferenceConfigurations();
	return (
		<StyledList
			className={className}
			$isRightToLeft={conferenceConfigurations.isRightToLeft}
			$isDarkMode={conferenceConfigurations.isDarkMode}>
			{children}
		</StyledList>
	);
};

/**
 * @param {object} props
 * @param {any} props.children
 * @param {() => void} props.onClick
 * @param {boolean} [props.isActive]
 */
const Item = ({ children, onClick, isActive = false }) => {
	return (
		<li>
			<Button
				border='none'
				className={isActive ? 'active' : ''}
				onClick={ev => {
					ev.stopPropagation();
					onClick();
				}}
				text={children}
			/>
		</li>
	);
};

List.Item = Item;

export default List;
