import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const disableAiAlerts = async (levelid, minutes, aiSettings) => {
	try {
		const url = `/v1/healthcare/organizations/${getCompanyId()}/monitoring-ai-session`;
		const { data } = await gatewayApi.post(url, {
			levelid,
			minutes,
			aiSettings,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const enableAiAlerts = async deviceId => {
	try {
		const { data } = await gatewayApi.delete(
			`/v1/healthcare/organizations/${getCompanyId()}/id/${deviceId}/monitoring-ai-session`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDisabledAiControls = async (organizationLevel, sectorId) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/organizations/${getCompanyId()}/level/${organizationLevel}/id/${sectorId}/monitoring-ai-session`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
