import React, { useState, useRef } from 'react';

const SleepScheduleTooltip = ({ sectorType, sectorCount, sectorNames }) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const iconRef = useRef(null);
	const tooltipRef = useRef(null);

	const handleMouseLeave = event => {
		if (!iconRef?.current || !tooltipRef?.current) {
			return;
		}
		if (!iconRef?.current?.contains(event.relatedTarget) && !tooltipRef?.current?.contains(event.relatedTarget)) {
			setShowTooltip(false);
		}
	};
	return (
		<div className='sleep-schedule-tooltip-container'>
			<div className='flex gap-s'>
				<span>{sectorCount}</span>
				<i
					ref={iconRef}
					className='material-icons-outlined tooltip-info'
					onMouseEnter={e => {
						e.stopPropagation();
						setShowTooltip(true);
					}}
					onMouseLeave={e => {
						e.stopPropagation();
						handleMouseLeave(e);
					}}>
					info
				</i>
			</div>
			{showTooltip && (
				<div
					className='tooltip'
					ref={tooltipRef}
					onMouseLeave={e => {
						e.stopPropagation();
						handleMouseLeave(e);
					}}>
					<div className='tooltip-arrow'></div>
					<div className='tooltip-content'>
						{sectorNames.map((sectorName, i) => {
							return (
								<div className='tooltip-sector' key={i}>
									<img
										src={`https://static.solaborate.com/healthcare-system/treeview/${sectorType}.svg`}
										alt='icon'
										className='right-s'></img>
									<span>{sectorName}</span>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};

export default SleepScheduleTooltip;
