import { getPatientMobilitySummary } from 'api/patients.js';
import Alert from 'components/Alert.jsx';
import CustomSummaryChart from 'components/DashboardCharts/PatientCentric/AI/Summaries/CustomSummaryChart.jsx';
import { MobilityChartDetails } from 'infrastructure/helpers/aiHelper.js';
import { getDurationFormat, timezoneToUTCTimestamp } from 'infrastructure/helpers/dateHelper.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const PatientMobilitySummary = ({ dateRange, selectedTimezone }) => {
	const location = useLocation();
	const [patientMobilityHistory, setPatientMobilityHistory] = useState(null);
	const [totalPatientMobility, setTotalPatientMobility] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetchPatientMobilitySummary = useCallback(
		debounce(async params => {
			setIsLoading(true);
			const response = await getPatientMobilitySummary(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setPatientMobilityHistory(response);
				const total = getDurationFormat(response?.lying + response?.sitting + response?.walking || 0);
				setTotalPatientMobility(total);
			}
			setIsLoading(false);
		}, 500),
		[]
	);

	useEffect(() => {
		const { state } = location;
		const { patient } = state;

		const params = {
			patientId: patient?.healthcareUserId,
			startTime: timezoneToUTCTimestamp(dateRange.from, selectedTimezone.zone),
			endTime: timezoneToUTCTimestamp(dateRange.to, selectedTimezone.zone),
		};
		fetchPatientMobilitySummary(params);
	}, [location, dateRange, fetchPatientMobilitySummary, selectedTimezone.zone]);

	return (
		<>
			<CustomSummaryChart
				total={totalPatientMobility}
				chartData={patientMobilityHistory}
				chartDetails={MobilityChartDetails}
				dataSets={[
					patientMobilityHistory?.lying || 0,
					patientMobilityHistory?.sitting || 0,
					patientMobilityHistory?.walking || 0,
				]}
				isLoading={isLoading}
				title='patientMobility'
			/>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default PatientMobilitySummary;
