import moment from 'moment-timezone';
import { RecurrenceType } from 'constants/enums.js';

class GoogleCalendarService {
	constructor() {
		this.gapi = null;
		this.tokenClient = null;
		this.isSignedIn = false;
		this.onLoadCallback = null;
		this.calendar = 'primary';
		this.config =
			process.env.NODE_ENV === 'development'
				? {
						clientId: process.env.REACT_APP_GOOGLEAPI_CLIENT_ID,
						apiKey: process.env.REACT_APP_GOOGLEAPI_API_KEY,
						scope: process.env.REACT_APP_GOOGLEAPI_SCOPE,
						discoveryDocs: [process.env.REACT_APP_GOOGLEAPI_DISCOVERYDOCS],
				  }
				: {
						clientId: window.__env__.REACT_APP_GOOGLEAPI_CLIENT_ID,
						apiKey: window.__env__.REACT_APP_GOOGLEAPI_API_KEY,
						scope: window.__env__.REACT_APP_GOOGLEAPI_SCOPE,
						discoveryDocs: [window.__env__.REACT_APP_GOOGLEAPI_DISCOVERYDOCS],
				  };

		this.updateSigninStatus = this.updateSigninStatus.bind(this);
		this.initClient = this.initClient.bind(this);
		this.handleSignoutClick = this.handleSignoutClick.bind(this);
		this.handleAuthClick = this.handleAuthClick.bind(this);
		this.createEvent = this.createEvent.bind(this);
		this.listUpcomingEvents = this.listUpcomingEvents.bind(this);
		this.listenSign = this.listenSign.bind(this);
		this.onLoad = this.onLoad.bind(this);
		this.setCalendar = this.setCalendar.bind(this);
		this.getSignedInUserData = this.getSignedInUserData.bind(this);
		this.handleClientLoad();
	}

	updateSigninStatus(isSignedIn) {
		this.isSignedIn = isSignedIn;
	}

	initClient() {
		this.gapi = window.gapi;
		this.gapi.client
			.init({
				apiKey: this.config.apiKey,
				discoveryDocs: this.config.discoveryDocs,
			})
			.then(() => {
				this.tokenClient = window.google.accounts.oauth2.initTokenClient({
					client_id: this.config.clientId,
					scope: this.config.scope,
					callback: tokenResponse => {
						if (tokenResponse.error) {
							console.log('Error during token acquisition:', tokenResponse);
						} else {
							this.updateSigninStatus(true);
						}
					},
				});
				if (this.onLoadCallback) {
					this.onLoadCallback();
				}
			})
			.catch(e => {
				console.log(e);
			});
	}

	handleClientLoad() {
		this.gapi = window.gapi;
		const script = document.createElement('script');
		script.src = 'https://apis.google.com/js/api.js';
		document.body.appendChild(script);
		script.onload = () => {
			window.gapi.load('client', this.initClient);
		};
	}

	async handleAuthClick() {
		if (this.tokenClient && !this.isSignedIn) {
			this.tokenClient.requestAccessToken({ prompt: 'consent' });
		}
	}

	handleSignoutClick() {
		if (this.gapi) {
			window.google.accounts.id.disableAutoSelect();
			this.updateSigninStatus(false);
		}
	}

	getSignedInUserData() {
		const user = window.google.accounts.oauth2.getCurrentAccount();
		return user ? user.name : null;
	}

	setCalendar(newCalendar) {
		this.calendar = newCalendar;
	}

	listenSign(callback) {
		if (this.tokenClient) {
			window.google.accounts.oauth2.initTokenClient({
				client_id: this.config.clientId,
				scope: this.config.scope,
				callback: tokenResponse => {
					callback(!tokenResponse.error);
				},
			});
		}
	}

	onLoad(callback) {
		if (this.gapi) {
			callback();
		} else {
			this.onLoadCallback = callback;
		}
	}

	removeOnLoadCallback() {
		this.onLoadCallback = null;
	}

	async listUpcomingEvents(
		maxResults = null,
		nextPageToken = null,
		timeMin = new Date().toISOString(),
		calendarId = this.calendar
	) {
		let returnVal = null;
		if (this.gapi) {
			const config = {
				calendarId: calendarId,
				timeMin: timeMin,
				showDeleted: false,
				singleEvents: true,
				maxResults: maxResults,
				orderBy: 'startTime',
			};
			if (nextPageToken !== null) {
				config.pageToken = nextPageToken;
			}
			returnVal = await this.gapi.client.calendar.events.list(config);
		}
		return returnVal;
	}

	createEvent(event, calendarId = this.calendar) {
		try {
			const timeZone = moment.tz.guess();
			let recurrence = null;
			if (event.hasRecurrence) {
				if (event.recurrence === RecurrenceType.WORKDAYS) recurrence = 'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR';
				else recurrence = `RRULE:FREQ=${event.recurrence}`;

				recurrence = `${recurrence};UNTIL=${moment(event.recurrenceEndDate).add(1, 'days').format('YYYYMMDD')}`;
			}

			const googleEvent = {
				summary: event.topic,
				description: event.description,
				start: {
					dateTime: event.startDateTime,
					timeZone: timeZone,
				},
				end: {
					dateTime: event.endDateTime,
					timeZone: timeZone,
				},
				recurrence: [recurrence],
				attendees: [
					{
						email: event.invitedMemberEmail,
						displayName: event.invitedMemberEmail,
						optional: false,
					},
				],
			};

			return this.gapi.client.calendar.events.insert({
				calendarId: calendarId,
				resource: googleEvent,
				sendUpdates: 'all',
			});
		} catch (ex) {
			throw new Error(`Error: ${ex.message}`);
		}
	}
}

export default new GoogleCalendarService();
