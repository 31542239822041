import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Radiology = ({ color = LightTheme.colors.graySeven, strokeWidth = 2, ...props }) => (
	<svg color={color} width={28} height={30} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<rect
			x={7}
			y={9.167}
			width={14}
			height={12.833}
			rx={3}
			stroke='currentColor'
			strokeWidth={strokeWidth}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M14 11.5v8.167M13.416 19.667h1.167M16.916 12.083S15.75 11.5 14 11.5s-2.917.583-2.917.583M17.5 15s-.5-.583-3.5-.583-3.5.583-3.5.583M17.5 17.917s-.5-.584-3.5-.584-3.5.584-3.5.584M10.5 22v5.833c0 .645.522 1.167 1.167 1.167v0c.644 0 1.166-.522 1.166-1.167V22M15.166 22v5.833c0 .645.523 1.167 1.167 1.167v0c.645 0 1.167-.522 1.167-1.167V22M10.5 9.167v-.334a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v.334M12.25 2.75c0-.966.784-1.75 1.75-1.75v0c.966 0 1.75.784 1.75 1.75v.583A1.75 1.75 0 0 1 14 5.083v0a1.75 1.75 0 0 1-1.75-1.75V2.75Z'
			stroke='currentColor'
			strokeWidth={strokeWidth}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default Radiology;
