import React, { useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import { generateCustomStyles } from 'constants/react-select-style.js';
import { useIntl } from 'react-intl';
import { activity, frictionShear, mobility, nutrition, sensoryPerceptions, skinMoisture } from 'constants/monitoring.js';
import Button from 'components/Button.jsx';
import ActionButtons from 'containers/Monitoring/Whiteboard/ActionButtons.jsx';

const BradenScore = props => {
	const intl = useIntl();

	const getInitialData = (type, values) =>
		props.data[type]
			? {
					value: values.find(item => item.value === props.data[type])?.value,
					label: values.find(item => item.value === props.data[type])?.label,
			  }
			: null;

	const [expanded, setExpanded] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);
	const [dataToEdit, setDataToEdit] = useState({
		sensory: getInitialData('sensory', sensoryPerceptions(intl)),
		skin: getInitialData('skin', skinMoisture(intl)),
		activity: getInitialData('activity', activity(intl)),
		nutrition: getInitialData('nutrition', nutrition(intl)),
		mobility: getInitialData('mobility', mobility(intl)),
		friction: getInitialData('friction', frictionShear(intl)),
	});

	useEffect(() => {
		setDataToEdit({
			sensory: getInitialData('sensory', sensoryPerceptions(intl)),
			skin: getInitialData('skin', skinMoisture(intl)),
			activity: getInitialData('activity', activity(intl)),
			nutrition: getInitialData('nutrition', nutrition(intl)),
			mobility: getInitialData('mobility', mobility(intl)),
			friction: getInitialData('friction', frictionShear(intl)),
		});
	}, [intl, props.data]);

	const allItemsAreSelected = () => Object.values(dataToEdit).every(item => item);

	const getText = (type, arr) => (props.data[type] ? arr.find(item => item.value === props.data[type])?.key : '');

	return (
		<>
			<div className={classNames('timeline-box-header sub-timeline-box-header', expanded ? 'expanded' : '')}>
				<p className='timeline-box-title'>{translate('bradenScore')}</p>
				<div className='timeline-box-actions'>
					<ActionButtons
						handleEditClick={() => {
							setShowEditForm(prevState => !prevState);
							setExpanded(true);
						}}
						handleExpandClick={() => setExpanded(prevState => !prevState)}
						expanded={expanded}
					/>
				</div>
			</div>
			{expanded && (
				<>
					{!showEditForm && (
						<table className='patient-table-informations' style={{ width: '100%' }}>
							<tbody>
								<tr>
									<td>{translate('sensoryPerceptions')}</td>
									<td>{getText('sensory', sensoryPerceptions(intl))}</td>
								</tr>
								<tr>
									<td>{translate('skinMoisture')}</td>
									<td>{getText('skin', skinMoisture(intl))}</td>
								</tr>
								<tr>
									<td>{translate('activity')}</td>
									<td>{getText('activity', activity(intl))}</td>
								</tr>
								<tr>
									<td>{translate('mobility')}</td>
									<td>{getText('mobility', mobility(intl))}</td>
								</tr>
								<tr>
									<td>{translate('nutrition')}</td>
									<td>{getText('nutrition', nutrition(intl))}</td>
								</tr>
								<tr>
									<td>{translate('frictionShear')}</td>
									<td>{getText('friction', frictionShear(intl))}</td>
								</tr>
							</tbody>
						</table>
					)}
					{showEditForm && (
						<div className='flex flex-space-between flex-wrap whiteboard-select'>
							<ReactSelect
								options={sensoryPerceptions(intl)}
								value={dataToEdit.sensory || null}
								onChange={option => setDataToEdit(prevState => ({ ...prevState, sensory: option }))}
								id='select'
								classNamePrefix='react-select full-width'
								styles={{
									...generateCustomStyles({ darkMode: props.isDarkMode }),
								}}
								placeholder={intl.formatMessage({ id: 'sensoryPerceptions' })}
							/>
							<div className='top-15'>
								<ReactSelect
									options={skinMoisture(intl)}
									value={dataToEdit.skin || null}
									onChange={option => setDataToEdit(prevState => ({ ...prevState, skin: option }))}
									id='select'
									classNamePrefix='react-select full-width'
									styles={{
										...generateCustomStyles({ darkMode: props.isDarkMode }),
									}}
									placeholder={intl.formatMessage({ id: 'skinMoisture' })}
								/>
							</div>
							<div className='top-15'>
								<ReactSelect
									options={activity(intl)}
									value={dataToEdit.activity || null}
									onChange={option => setDataToEdit(prevState => ({ ...prevState, activity: option }))}
									id='select'
									classNamePrefix='react-select full-width'
									styles={{
										...generateCustomStyles({ darkMode: props.isDarkMode }),
									}}
									placeholder={intl.formatMessage({ id: 'activity' })}
								/>
							</div>
							<div className='top-15'>
								<ReactSelect
									options={mobility(intl)}
									value={dataToEdit.mobility || null}
									onChange={option => setDataToEdit(prevState => ({ ...prevState, mobility: option }))}
									id='select'
									classNamePrefix='react-select full-width'
									styles={{
										...generateCustomStyles({ darkMode: props.isDarkMode }),
									}}
									placeholder={intl.formatMessage({ id: 'mobility' })}
								/>
							</div>
							<div className='top-15'>
								<ReactSelect
									options={nutrition(intl)}
									value={dataToEdit.nutrition || null}
									onChange={option => setDataToEdit(prevState => ({ ...prevState, nutrition: option }))}
									id='select'
									classNamePrefix='react-select full-width'
									styles={{
										...generateCustomStyles({ darkMode: props.isDarkMode }),
									}}
									placeholder={intl.formatMessage({ id: 'nutrition' })}
								/>
							</div>
							<div className='top-15'>
								<ReactSelect
									options={frictionShear(intl)}
									value={dataToEdit.friction || null}
									onChange={option => setDataToEdit(prevState => ({ ...prevState, friction: option }))}
									id='select'
									classNamePrefix='react-select full-width'
									styles={{
										...generateCustomStyles({ darkMode: props.isDarkMode }),
									}}
									placeholder={intl.formatMessage({ id: 'frictionShear' })}
								/>
							</div>
							<Button
								className={classNames(
									'button',
									!allItemsAreSelected() ? 'disabled' : '',
									props.isSubmitButtonLoading ? 'loading' : ''
								)}
								onClick={() => props.setData(dataToEdit)}
								text={translate('save')}
							/>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default BradenScore;
