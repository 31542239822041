import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import { findSectorById } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import HelloItems from 'components/HelloItems.jsx';
import { DeviceAvailability, DeviceFamilyTypes } from 'constants/enums.js';
import classNames from 'classnames';
import HelloIcon from 'icons/Devices/HelloIcon.jsx';
import { getMonitoringActiveIconColor } from 'infrastructure/helpers/monitoringTimelineHelpers.js';
import LightTheme from 'calls/styles/LightTheme.js';

const PatientDevices = ({ patient }) => {
	const [selectedHelloDevice, setSelectedHelloDevice] = useState(null);
	const [isHelloDevicesListVisible, setHelloDevicesListVisibility] = useState(false);
	const helloName = useSelector(state => state.company.companySettings.helloName);
	const myPatients = useSelector(state => state.myPatients);
	const treeData = useSelector(state => state.healthSystems.treeData.tree);
	const darkMode = useSelector(state => state.user.darkMode);
	const ref = useRef();

	const setSelectedDevice = device => {
		setSelectedHelloDevice(device);
		setHelloDevicesListVisibility(false);
	};

	useOutsideClick(ref, () => {
		if (isHelloDevicesListVisible) {
			setHelloDevicesListVisibility(false);
		}
	});

	useEffect(() => {
		const selectedPatientDevice =
			myPatients.selectedPatientDevice?.userId === patient?.userId && myPatients.selectedPatientDevice?.device;
		setSelectedHelloDevice(selectedPatientDevice || (patient?.devicesInfos?.length > 0 && patient.devicesInfos[0]));
	}, [patient, myPatients.selectedPatientDevice]);

	const getHelloDeviceName = device => {
		const room = findSectorById(treeData, device.id);
		return room ? room.name : device.name;
	};

	return (
		<div className='position-relative flex flex-justify-center'>
			<div ref={ref}>
				<div
					className={classNames('hello-device-description flex flex-align-center cursor-pointer patient-hello-devices', {
						'active-overview': isHelloDevicesListVisible,
					})}
					onClick={() =>
						setHelloDevicesListVisibility(prevStateHelloDevicesListVisibility => !prevStateHelloDevicesListVisibility)
					}>
					<HelloIcon
						color={isHelloDevicesListVisible ? LightTheme.colors.blueOne : getMonitoringActiveIconColor(darkMode)}
						className='margin-right-m'
					/>
					{selectedHelloDevice && <h3>{getHelloDeviceName(selectedHelloDevice)}</h3>}
					<i className='material-icons-round margin-left-auto'>expand_more</i>
				</div>
				{isHelloDevicesListVisible && (
					<div className='hello-devices-list position-absolute'>
						<p>{translate('selectHelloDevice', { value: helloName })}</p>

						{patient.devicesInfos.map(device => (
							<div
								key={device.id}
								onClick={() => setSelectedDevice(device)}
								className={classNames('flex cursor-pointer', {
									active: selectedHelloDevice && selectedHelloDevice.id === device.id,
								})}>
								<div>
									<HelloIcon
										color={
											selectedHelloDevice.id === device.id ? LightTheme.colors.blueOne : getMonitoringActiveIconColor(darkMode)
										}
									/>
								</div>
								<p className='flex-1'>{getHelloDeviceName(device)}</p>
								<i className='material-icons'>done</i>
							</div>
						))}
					</div>
				)}

				{selectedHelloDevice && (
					<HelloItems
						deviceName={getHelloDeviceName(selectedHelloDevice)}
						deviceId={selectedHelloDevice.id}
						isOnline={selectedHelloDevice.state.availability === DeviceAvailability.ONLINE}
						privacyMode={selectedHelloDevice.state.privacyMode}
						isCameraOn={selectedHelloDevice.state.camera}
						isMicrophoneOn={selectedHelloDevice.state.microphone}
						isOverviewHelloItem={true}
						isHelloDevice={selectedHelloDevice.deviceFamily !== DeviceFamilyTypes.TABLET}
						patient={patient}
						selectedHelloDevice={selectedHelloDevice}
						setSelectedHelloDevice={setSelectedHelloDevice}
					/>
				)}
			</div>
		</div>
	);
};

export default PatientDevices;
