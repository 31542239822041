import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import Select from 'components/Select.jsx';
import { CompanySettings } from 'constants/configurationEnums.js';
import Button from 'components/Button.jsx';

const ConfigSettingInput = props => {
	const intl = useIntl();
	const { config } = props;

	const getSettingImage = key => {
		switch (key) {
			case CompanySettings.TALK_TO_PATIENT_ROUTE:
				return `${healthCareCdnUrl}admin/super-admin/talk-to-patient.svg`;
			case CompanySettings.MONITORING_ROUTE:
				return `${healthCareCdnUrl}admin/super-admin/monitoring.svg`;
			case CompanySettings.VIEW_PATIENT_ROUTE:
				return `${healthCareCdnUrl}admin/super-admin/view-patient.svg`;
			case CompanySettings.E2EE:
				return `${healthCareCdnUrl}encryption.svg`;
			case CompanySettings.DIAL_OUT:
				return `${healthCareCdnUrl}dial-out.svg`;
			default:
				return `${healthCareCdnUrl}admin/super-admin/filter.svg`;
		}
	};

	return (
		<div className='flex call-config-settings' key={config.key}>
			<div className='call-config-type'>
				<div>
					<img src={getSettingImage(config.key)} alt='icon' />
				</div>
				<div className='call-config-description'>
					<p>
						{intl.formatMessage({ id: config.title })}
						{config.key && (
							<Button
								className={classNames(config.locked ? 'locked' : '', props.keyDisabled ? 'disabled' : '')}
								onClick={() => props.handleChangeConfig(config, true)}
								imgIcon={`${healthCareCdnUrl}admin/super-admin/${config.locked ? 'lock' : 'unlock'}.svg`}
								text={translate(config.locked ? 'locked' : 'unlocked')}
							/>
						)}
					</p>
					<span>{props.statusText}</span>
				</div>
			</div>
			{props.isToggle && (
				<div
					className={classNames('toggle-switch call-config-toggle', props.config.locked ? 'disabled' : '')}
					onClick={() => props.handleChangeConfig(config, false)}>
					<input type='checkbox' checked={JSON.parse(config.value)} onChange={() => null} />
					<span className='toggle-body' />
				</div>
			)}
			{!props.isToggle && (
				<Select
					name='routingMonitoring'
					items={config.options}
					value={config.value}
					valueField='value'
					textField='content'
					onSelect={event => props.handleSelectValue(event, config)}
				/>
			)}
		</div>
	);
};

export default ConfigSettingInput;
