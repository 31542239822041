import { TeamTypes } from 'constants/enums.js';
import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getRingToneDurationConfigs = async params => {
	try {
		const { data } = await gatewayApi.get(`v1/companies/${getCompanyId()}/device-configurations/ringtones`, { params });
		return data;
	} catch (error) {
		return { error };
	}
};

export const addRingToneDurationConfig = async ({ departmentId, hospitalId, params }) => {
	try {
		const url = `/v1/companies/${getCompanyId()}/teams/${departmentId}/team-types/${
			TeamTypes.DEPARTMENT
		}/parent-teams/${hospitalId}/device-configurations/ringtones`;
		const { data } = await gatewayApi.post(url, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteRingToneConfiguration = async ({ departmentId, configurationId }) => {
	try {
		const url = `/v1/companies/${getCompanyId()}/teams/${departmentId}/device-configurations/ringtones/${configurationId}`;
		const { data } = await gatewayApi.delete(url);
		return data;
	} catch (error) {
		return { error };
	}
};
