import React, { useState, useEffect } from 'react';
import { FieldArray, Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import translate from 'i18n-translations/translate.jsx';
import { AnswerType, RpmTabsList, rpmMeasurements } from 'constants/rpm.js';
import Alert from 'components/Alert.jsx';
import Grid from 'components/Grid.jsx';
import { createRpmProgram, getRpmProgramDetails, updateRpmProgram } from 'api/rpm.js';
import { HttpStatusCodes, MemberType } from 'constants/enums.js';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import { getSpecialties } from 'api/doctorOnBoarding.js';
import CreateSurvey from 'containers/Rpm/CreateSurvey.jsx';
import CareTeamMembers from 'containers/Rpm/CareTeamMembers.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Button from 'components/Button.jsx';
import Select from 'react-select';
import { useSelector } from 'react-redux';

const AddEditRpmProgram = () => {
	const tabs = {
		RPM: 0,
		SURVEY: 1,
		CARE_TEAM: 2,
	};
	const [error, setError] = useState(null);
	const [currentTab, setCurrentTab] = useState(tabs.RPM);
	const [specialties, setSpecialties] = useState([]);
	const [selectedSurveyType, setSelectedSurveyType] = useState(null);
	const [selectedScheduleType, setSelectedScheduleType] = useState(null);
	const [selectedProgram, setSelectedProgram] = useState(null);
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [showError, setShowError] = useState(true);
	const [savedCareTeamPerHs, setSavedCareTeamPerHs] = useState({});
	const intl = useIntl();
	const location = useLocation();
	const translator = id => intl.formatMessage({ id });
	const healthSystems = useSelector(state => state.healthSystems.allHealthSystems);

	useEffect(() => {
		const getProgramDetails = async id => {
			setSelectedProgram(null);
			const response = await getRpmProgramDetails(id);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setSelectedProgram(response.rpmProgram);
		};

		if (location.state?.rpmProgramId) {
			getProgramDetails(location.state?.rpmProgramId);
		} else {
			setIsAddModalOpen(true);
		}
	}, [location]);

	const getSelectedHS = healthSystemId => {
		const foundHs = healthSystems.find(hs => hs.id === healthSystemId);
		if (!healthSystemId || !foundHs) {
			return null;
		}
		return { label: foundHs.name, value: foundHs.id };
	};

	const getInitialValues = () => ({
		healthSystem: getSelectedHS(location.state?.healthSystemId),
		rpmProgramName: selectedProgram?.name ?? '',
		questions: selectedProgram?.questions?.length > 0 ? selectedProgram?.questions : [],
		measurements: selectedProgram ? getTransformedArr() : [],
		doctors: selectedProgram?.careTeam ? getSelectedMembers(MemberType.DOCTOR) : [],
		nurses: selectedProgram?.careTeam ? getSelectedMembers(MemberType.NURSE) : [],
		specialties: selectedProgram?.careTeam ? getSelectedSpecialties() : [],
	});

	const getTransformedArr = () => {
		const transformedArr = [];
		selectedProgram.devices.forEach(item => {
			const foundMeasurement = transformedArr.find(measurement => measurement.measurementType === item.measurementType);
			if (foundMeasurement) {
				foundMeasurement.devices.push(item.deviceId);
			} else {
				transformedArr.push({
					isSelected: true,
					measurementType: item.measurementType,
					devices: [item.deviceId],
				});
			}
		});
		return transformedArr;
	};

	const getSelectedMembers = careMemberType =>
		selectedProgram.careTeam.reduce((result, item) => {
			if (item.careMemberType === careMemberType) {
				result.push({
					value: item.userId,
					label: `${item.firstName} ${item.lastName}`,
					profilePicture: item.profilePicture,
				});
			}
			return result;
		}, []);

	const getSelectedSpecialties = () => selectedProgram.careTeam.filter(item => item.careMemberType === MemberType.DOCTOR);

	const getValidationSchema = () => {
		return Yup.object().shape({
			rpmProgramName: Yup.string()
				.trim()
				.required(translator('pleaseWriteProgramName'))
				.max(100, `${translator('maxLengthIs')} 100`),
			measurements: Yup.array()
				.min(1, translator('pleaseSelectMeasurement'))
				.test('maximum-devices', translator('maximumDevices'), array => array.every(arr => arr.devices.length <= 2))
				.test('minimum-devices', translator('pleaseSelectDevice'), array => array.every(arr => arr.devices.length > 0)),
			questions: Yup.array()
				.test('options-array-validation', translator('atLeastTwoOptions'), array => array.every(arr => arr.options.length >= 2))
				.test('non-empty', translator('valuesCannotBeEmpty'), array =>
					array.every(item => item.text && item.options.every(el => el))
				),
			doctors: Yup.array()
				.min(1, translator('atLeastOneMember'))
				.test('non-empty-values', translator('valuesCannotBeEmpty'), array => array.every(item => item?.value)),
			nurses: Yup.array().test('non-empty-values', translator('valuesCannotBeEmpty'), array => array.every(item => item?.value)),
			healthSystem: Yup.object().required(intl.formatMessage({ id: 'healthSystemRequired' })),
		});
	};

	const getDevices = values =>
		values.measurements.reduce((selectedDevices, measurement) => {
			if (measurement?.isSelected) {
				const foundMeasurement = rpmMeasurements.find(item => item.type === measurement.measurementType);
				const selectedDeviceIds = measurement.devices || [];
				foundMeasurement.devices.forEach(device => {
					if (selectedDeviceIds.includes(device.id)) {
						selectedDevices.push({ measurementType: measurement.measurementType, deviceId: device.id });
					}
				});
			}
			return selectedDevices;
		}, []);

	const handleOnSubmit = async values => {
		if (currentTab !== tabs.CARE_TEAM) {
			return;
		}
		setError(null);
		const combinedArr = values.doctors.map((member, index) => {
			return { ...member, ...values.specialties[index] };
		});
		const careTeam = [
			...combinedArr.map(item => ({ userId: item.value, careMemberType: MemberType.DOCTOR })),
			...values.nurses.map(item => ({ userId: item.value, careMemberType: MemberType.NURSE })),
		];
		const updatedValues = {
			...values,
			devices: getDevices(values),
			careTeam,
		};
		const payload = {
			name: updatedValues.rpmProgramName,
			devices: updatedValues.devices,
			questions: updatedValues.questions.map(item => ({ ...item, answerType: AnswerType.MULTIPLE_CHOICE })),
			careTeam: updatedValues.careTeam,
			healthSystemId: updatedValues.healthSystem?.value ?? null,
		};
		const response = selectedProgram
			? await updateRpmProgram({
					id: selectedProgram.id,
					...payload,
			  })
			: await createRpmProgram(payload);
		if (response.error) {
			const errorMessage =
				response.error.response.status === HttpStatusCodes.CONFLICT
					? intl.formatMessage({ id: 'programExists' })
					: response.error.message;
			setError(errorMessage);
		} else {
			setIsAddModalOpen(false);
			window.history.back();
		}
	};
	const canChangeTab = (tab, errors) => {
		let result = false;
		switch (tab) {
			case tabs.RPM:
				result = true;
				break;
			case tabs.SURVEY:
				if (!errors.rpmProgramName) {
					result = true;
				}
				break;
			case tabs.CARE_TEAM:
				if (!errors.questions) {
					result = true;
					setShowError(false);
				}
				break;

			default:
				result = true;
		}
		return result;
	};

	const handleChangeTab = (tab, errors) => {
		if (canChangeTab(tab, errors)) {
			setCurrentTab(tab);
		}
	};

	useEffect(() => {
		if (currentTab === tabs.CARE_TEAM) {
			const getSpecialtiesList = async () => {
				const response = await getSpecialties();
				if (response.error) {
					setError(response.error.message);
					return;
				}
				setSpecialties(response.specialties);
			};
			getSpecialtiesList();
		}
	}, [currentTab, tabs.CARE_TEAM]);

	const transformArray = array => array.map(item => ({ value: item.id, label: item.name }));

	const handleHealthSystemChange = (event, values, setFieldValue) => {
		const resetField = field => setFieldValue(field, []);

		setFieldValue('healthSystem', event);
		resetField('doctors');
		resetField('nurses');
		resetField('specialties');

		if (isAddModalOpen) {
			return;
		}

		if (values.healthSystem) {
			setSavedCareTeamPerHs(prevState => ({
				...prevState,
				[values.healthSystem.value]: {
					doctors: values.doctors,
					nurses: values.nurses,
					specialties: values.specialties,
				},
			}));
		}

		if (savedCareTeamPerHs[event.value] && event.value === selectedProgram.healthSystemId) {
			setFieldValue('doctors', savedCareTeamPerHs[event.value].doctors);
			setFieldValue('nurses', savedCareTeamPerHs[event.value].nurses);
			setFieldValue('specialties', savedCareTeamPerHs[event.value].specialties);
		}
	};

	return (
		<MainLayout>
			{(selectedProgram || isAddModalOpen) && (
				<Formik initialValues={getInitialValues()} validationSchema={getValidationSchema} onSubmit={handleOnSubmit}>
					{({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit }) => {
						if (Object.keys(errors).length > 0) {
							if (errors.rpmProgramName || errors.measurements || errors.healthSystem) {
								setCurrentTab(tabs.RPM);
							}
						}
						return (
							<>
								<Tabs activeIndex={currentTab} onChange={index => handleChangeTab(index, errors)}>
									<TabList className='equal-children doctor-wrapper-tabs full-width'>
										{RpmTabsList.map(tab => (
											<Tab
												className={classNames(currentTab > tab.id ? 'tab-past' : '', currentTab < tab.id ? 'tab-next' : '')}
												key={tab.id}>
												{currentTab === tab.id && <img src={tab.activeImg} alt='icon' />}
												{currentTab > tab.id && <img src={tab.pastImg} alt='icon' />}
												{currentTab < tab.id && <img src={tab.mainImg} alt='icon' />}
												{tab.title}
											</Tab>
										))}
									</TabList>
									<TabPanels>
										<TabPanel>
											<Grid columns='1fr' stretch='100%'>
												<div className='add-edit-rpm-program-wrapper'>
													<div className='rpm-program-info'>
														<p className='rpm-program-title semi-bold'>
															{translate(selectedProgram ? 'editingProgram' : 'creatingProgram')}
														</p>
														<div className='flex'>
															<div className='flex column-direction margin-right-m'>
																<p className='no-margin font-14 semi-bold'>{translate('programName')}</p>
																<input
																	type='text'
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.rpmProgramName}
																	name='rpmProgramName'
																	placeholder={intl.formatMessage({ id: 'programName' })}
																/>
																{touched.rpmProgramName && errors.rpmProgramName && (
																	<div>
																		<span className='red-error'>{errors.rpmProgramName}</span>
																	</div>
																)}
															</div>

															<div className='flex column-direction'>
																<div className='flex flex-space-between'>
																	<p className='no-margin font-14 semi-bold'>{translate('healthSystem')}</p>
																	{!values.healthSystem && selectedProgram && (
																		<span
																			className='flex right-2'
																			data-tooltip={intl.formatMessage({ id: 'healthSystemMustBeSet' })}
																			data-position='right'>
																			<img src={`${healthCareCdnUrl}treeview/Hospital.svg`} alt='ico' />
																		</span>
																	)}
																</div>
																<Select
																	value={values.healthSystem}
																	placeholder={translator('selectHealthSystem')}
																	classNamePrefix='custom-select'
																	options={transformArray(healthSystems)}
																	onChange={event => handleHealthSystemChange(event, values, setFieldValue)}
																	onBlur={handleBlur}
																/>
																{!values.healthSystem && touched.healthSystem && errors.healthSystem && (
																	<div>
																		<span className='red-error'>{errors.healthSystem}</span>
																	</div>
																)}
															</div>
														</div>
													</div>
													<div>
														<p className='rpm-program-title semi-bold'>{translate('measurementsSelection')}</p>
														<p className='rpm-description'>{translate('pickMeasurements')}</p>
														{values.measurements?.some(item => item?.isSelected) && (
															<p className='semi-bold'>{translate('selectedMeasurements')}</p>
														)}
														<FieldArray name='measurements'>
															{measurementsArrHelper => (
																<div className='flex flex-wrap'>
																	{values.measurements.map((measurement, index) => {
																		const foundMeasurement = rpmMeasurements.find(
																			item => item.type === measurement.measurementType
																		);
																		if (foundMeasurement) {
																			return (
																				<div key={foundMeasurement.type} className='rpm-measurement-details rpm-device-details'>
																					<div className='flex flex-align-center'>
																						<img src={foundMeasurement.activeImg} className='active' alt='ico' />
																						<p className='no-margin no-padding'>{translate(`${foundMeasurement.name}`)}</p>
																					</div>
																					<div
																						className='checkbox-wrapper measurement-checkbox'
																						onClick={() => measurementsArrHelper.remove(index)}>
																						<input
																							type='checkbox'
																							name={`values.measurements[${index}].isSelected`}
																							checked={values.measurements[index]?.isSelected}
																							onChange={handleChange}
																						/>
																						<span className='checkmark' />
																					</div>
																					{foundMeasurement.devices.map((device, devicesIndex) => (
																						<div
																							key={device.id}
																							className='flex flex-align-center'
																							onClick={() => {
																								const selectedDeviceIds = [...measurement.devices];
																								if (!selectedDeviceIds.includes(device.id)) {
																									measurement.devices.push(device.id);
																								} else {
																									const foundIndex = selectedDeviceIds.indexOf(device.id);
																									if (foundIndex !== -1) {
																										measurement.devices.splice(foundIndex, 1);
																									}
																								}
																								setFieldValue(`measurements[${index}].devices`, measurement.devices);
																							}}>
																							<div className='checkbox-wrapper devices-checkbox'>
																								<input
																									type='checkbox'
																									name={`values.measurements[${index}].devices[${devicesIndex}]`}
																									value={device.id}
																									checked={measurement.devices.includes(device.id)}
																									onChange={() => null}
																								/>
																								<span className='checkmark' />
																							</div>
																							<p className='no-margin'>{device.value}</p>
																						</div>
																					))}
																				</div>
																			);
																		}
																		return null;
																	})}
																</div>
															)}
														</FieldArray>

														<p className='semi-bold'>{translate('measurements')}</p>
														<FieldArray name='measurements'>
															{measurementsArrHelper => {
																const filteredArr = rpmMeasurements.filter(allMeasurements =>
																	values.measurements.every(
																		selectedMeasurements => selectedMeasurements.measurementType !== allMeasurements.type
																	)
																);
																return (
																	<div className='flex flex-wrap'>
																		{filteredArr.map((measurement, index) => (
																			<div
																				key={measurement.type}
																				className='rpm-measurement-details'
																				onClick={() =>
																					measurementsArrHelper.push({
																						isSelected: true,
																						measurementType: measurement.type,
																						devices: [],
																					})
																				}>
																				<div className='flex flex-align-center'>
																					<img src={measurement.img} alt='ico' />
																					<p className='no-margin no-padding'>{translate(`${measurement.name}`)}</p>
																				</div>
																				<div className='checkbox-wrapper'>
																					<input
																						type='checkbox'
																						name={`values.measurements[${index}].isSelected`}
																						onChange={handleChange}
																					/>
																					<span className='checkmark' />
																				</div>
																			</div>
																		))}
																	</div>
																);
															}}
														</FieldArray>
														{touched.measurements && errors.measurements && (
															<div>
																<span className='red-error'>{errors.measurements}</span>
															</div>
														)}
													</div>
													<div className='flex right-align-content top-15 rpm-modal-buttons'>
														<Button variant='white' onClick={() => window.history.back()} text={translate('cancel')} />
														<Button
															type='submit'
															onClick={() => {
																handleSubmit();
																handleChangeTab(tabs.SURVEY, errors);
															}}
															imgIcon={`${healthCareCdnUrl}next.svg`}
															text={translate('nextStep')}
														/>
													</div>
												</div>
											</Grid>
										</TabPanel>
										<Grid columns='1fr' stretch='100%'>
											<div className='add-edit-rpm-program-wrapper'>
												<CreateSurvey
													questions={values.questions}
													handleChange={handleChange}
													errors={errors.questions}
													selectedSurveyType={selectedSurveyType}
													setSelectedSurveyType={setSelectedSurveyType}
													selectedScheduleType={selectedScheduleType}
													setSelectedScheduleType={setSelectedScheduleType}
													setFieldValue={setFieldValue}
													touched={touched}
													setShowError={setShowError}
													showError={showError}
												/>
												<div className='flex right-align-content top-30 rpm-modal-buttons'>
													<Button variant='white' onClick={() => window.history.back()} text={translate('cancel')} />
													<Button
														type='submit'
														onClick={() => {
															setShowError(true);
															handleChangeTab(tabs.CARE_TEAM, errors);
														}}
														imgIcon={`${healthCareCdnUrl}next.svg`}
														text={translate('nextStep')}
													/>
												</div>
											</div>
										</Grid>
										<Grid columns='1fr' stretch='100%'>
											<div className='add-edit-rpm-program-wrapper'>
												<CareTeamMembers
													doctorValues={values.doctors}
													nurseValues={values.nurses}
													specialtyValues={values.specialties}
													doctorErrors={errors.doctors}
													nurseErrors={errors.nurses}
													setFieldValue={setFieldValue}
													specialties={specialties}
													isAddPatient={false}
													setDoctorValues={() => null}
													setNurseValues={() => null}
													setFamilyMemberValues={() => null}
													programCareTeam={[]}
													healthSystemId={values.healthSystem?.value ?? null}
													touched={touched}
													showError={showError}
												/>
												<div className='flex right-align-content top-15 rpm-modal-buttons'>
													<Button variant='white' onClick={() => window.history.back()} text={translate('cancel')} />
													<Button
														type='submit'
														onClick={() => {
															handleSubmit();
															setShowError(true);
														}}
														text={translate('complete')}
														imgIcon={`${healthCareCdnUrl}next.svg`}
													/>
												</div>
											</div>
										</Grid>
									</TabPanels>
								</Tabs>
								<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
							</>
						);
					}}
				</Formik>
			)}
		</MainLayout>
	);
};

export default AddEditRpmProgram;
