import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { CirclePicker, PhotoshopPicker } from 'react-color';
import translate from 'i18n-translations/translate.jsx';
import { getCompanyId } from 'infrastructure/auth.js';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import { actionCreators as companyActionCreators } from 'state/company/actions.js';
import { getCompanyDetails, updateCompanyDetails, updateOrganizationSettings, uploadCompanyLogo } from 'api/companies.js';
import FormInput from 'components/FormInput.jsx';
import { AlertTypes } from 'constants/enums.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import Alert from 'components/Alert.jsx';
import ImageUploader from 'containers/ImageUploader.jsx';
import { CompanySettings } from 'constants/configurationEnums.js';
import Button from 'components/Button.jsx';

const CompanyDetails = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(null);
	const [initialData, setInitialData] = useState({
		companyName: '',
		companyAddress: '',
		companyWebsite: '',
	});
	const [profilePicture, setProfilePicture] = useState('');
	const [previewImage, setPreviewImage] = useState('');
	const [isPictureUploading, setIsPictureUploading] = useState(false);
	const companySettings = useSelector(state => state.company.companySettings);
	const companyDetails = useSelector(state => state.company.companyDetails);
	const [selectedColor, setSelectedColor] = useState(companySettings.background);

	const [displayColorPicker, setDisplayColorPicker] = useState(false);
	const intl = useIntl();
	const dispatch = useDispatch();
	const colors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5'];
	useEffect(() => {
		const companyId = getCompanyId();
		const fetchInitialData = async () => {
			const response = await getCompanyDetails(companyId);
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			const { name, address, website } = response;
			setInitialData({
				companyName: name,
				companyAddress: address,
				companyWebsite: website,
			});
			setIsLoading(false);
		};
		fetchInitialData();
	}, []);

	useEffect(() => {
		let timeout;
		if (success) {
			timeout = setTimeout(() => {
				setSuccess(null);
			}, 1500);
		}
		return () => clearTimeout(timeout);
	}, [success]);

	const saveChanges = async ({ companyAddress, companyName, companyWebsite }) => {
		const companyId = getCompanyId();
		const params = {
			name: companyName,
			website: companyWebsite,
			address: companyAddress,
			profilePicture: profilePicture || companyDetails.logo,
			companyId: companyId,
		};

		const [detailsResponse, settingsResponse] = await Promise.all([
			updateCompanyDetails(params),
			updateOrganizationSettings({
				companySettings: [{ settingTypeId: CompanySettings.CLIENT_THEME_COLOR, value: selectedColor }],
				companyId,
			}),
		]);
		if (detailsResponse.error || settingsResponse.error) {
			setError(detailsResponse.error.message);
			setIsLoading(false);
		} else {
			setSuccess(true);
			setInitialData({
				companyName,
				companyAddress,
				companyWebsite,
			});
			const details = {
				logo: params.profilePicture,
				logoToPreview: previewImage || companyDetails.logoToPreview,
			};
			dispatch(companyActionCreators.setCompanyDetails(details));
			dispatch(companyActionCreators.setCompanySettings({ ...companySettings, background: selectedColor }));
		}
	};

	const closeResponseAlert = () => {
		setError(null);
		setSuccess(null);
	};

	return (
		<>
			{isLoading && (
				<Grid width='100%' stretch='calc(100vh - 105px)' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<div className='account-settings-inner-wrapper personalized-logo-wrapper'>
					<h4>{translate('companyDetails')}</h4>

					<Formik
						initialValues={{
							companyName: initialData.companyName,
							companyWebsite: initialData.companyWebsite,
							companyAddress: initialData.companyAddress,
						}}
						onSubmit={saveChanges}
						validationSchema={Yup.object().shape({
							companyName: Yup.string().required(intl.formatMessage({ id: 'companyNameRequired' })),
							companyWebsite: Yup.string().required(intl.formatMessage({ id: 'companyWebsiteRequired' })),
							companyAddress: Yup.string().required(intl.formatMessage({ id: 'companyAddressRequired' })),
						})}
						enableReinitialize={true}>
						{({ values, errors, touched, handleChange, handleBlur }) => {
							return (
								<>
									<div className='flex'>
										<div className='company-image-wrapper'>
											<h5>{translate('companyImage')}</h5>
											<div className='flex profile-info-image flex-align-center'>
												<ImageUploader
													setError={setError}
													existingLogo={companyDetails.logoToPreview}
													setProfilePicture={setProfilePicture}
													uploadProfilePic={uploadCompanyLogo}
													error=''
													sizeInMB={0.6}
													setIsPictureUploading={setIsPictureUploading}
													isCompanyDetails={true}
													setPreviewImage={setPreviewImage}
													imageTypes='image/png'
												/>
											</div>
										</div>
									</div>
									<Form>
										<div className='general-settings-form flex flex-space-between flex-wrap'>
											<FormInput
												className='gs-input'
												id='companyName'
												name='companyName'
												text={translate('companyName')}
												type='text'
												onChange={handleChange}
												onBlur={handleBlur}
												error={errors.companyName}
												touched={touched.companyName}
												placeholder={intl.formatMessage({ id: 'companyName' })}
												maxLength={255}
												value={values.companyName}
											/>
											<FormInput
												className='gs-input'
												id='companyWebsite'
												name='companyWebsite'
												text={translate('companyWebsite')}
												placeholder={intl.formatMessage({ id: 'companyWebsite' })}
												type='text'
												onChange={handleChange}
												onBlur={handleBlur}
												error={errors.companyWebsite}
												touched={touched.companyWebsite}
												maxLength={255}
												value={values.companyWebsite}
											/>
										</div>
										<div className='general-settings-form flex flex-space-between flex-wrap'>
											<FormInput
												className='gs-input'
												id='companyAddress'
												name='companyAddress'
												text={translate('companyAddress')}
												type='text'
												onChange={handleChange}
												onBlur={handleBlur}
												labelClassName='general-settings-select-el'
												error={errors.companyAddress}
												touched={touched.companyAddress}
												placeholder={intl.formatMessage({ id: 'companyAddress' })}
												maxLength={255}
												value={values.companyAddress}
											/>
										</div>
										<div className='full-width flex right-align-content top-30'>
											<Button type='submit' disabled={isPictureUploading} text={translate('saveChanges')} />
										</div>
									</Form>
								</>
							);
						}}
					</Formik>

					<div className='flex'>
						<div className='color-picker-wrapper'>
							<h5>{translate('interfaceThemeColor')}</h5>
							<div className='flex'>
								<div
									className={`p-l-selected-color${selectedColor === '#ffffff' ? ' white' : ''}`}
									style={{ background: selectedColor }}>
									<i className='material-icons'>done</i>
								</div>
								<CirclePicker colors={colors} onChangeComplete={obj => setSelectedColor(obj.hex)} />
							</div>
							<div>
								<Button onClick={() => setDisplayColorPicker(prevState => !prevState)} text={translate('selectAnotherColor')} />
								{displayColorPicker && (
									<div className='position-relative personalized-picker-absolute'>
										<PhotoshopPicker
											color={selectedColor}
											onChangeComplete={obj => setSelectedColor(obj.hex)}
											onAccept={() => setDisplayColorPicker(false)}
											onCancel={() => setDisplayColorPicker(false)}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			<PopUpAlert
				alertType={success ? AlertTypes.SUCCESS : AlertTypes.DANGER}
				display={error || success}
				onAlertClose={closeResponseAlert}
				contentText={success ? translate('changesSaved') : error}
				isSilent={true}
				center={true}
			/>
		</>
	);
};

export default CompanyDetails;
