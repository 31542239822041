const Spirometer = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} viewBox='0 0 24 24' fill='none' {...props}>
		<rect x={6.85706} y={5} width={3} height={4} stroke='currentColor' strokeWidth={1.5} strokeLinejoin='round' />
		<path
			d='M9.85706 5L9.52165 4.32918C9.26756 4.45622 9.10706 4.71592 9.10706 5H9.85706ZM13.8571 3V2.25C13.7406 2.25 13.6258 2.27711 13.5216 2.32918L13.8571 3ZM13.8571 11L13.5216 11.6708C13.6258 11.7229 13.7406 11.75 13.8571 11.75V11ZM9.85706 9H9.10706C9.10706 9.28408 9.26756 9.54378 9.52165 9.67082L9.85706 9ZM18.8571 3H19.6071C19.6071 2.58579 19.2713 2.25 18.8571 2.25V3ZM18.8571 11V11.75C19.2713 11.75 19.6071 11.4142 19.6071 11H18.8571ZM10.1925 5.67082L14.1925 3.67082L13.5216 2.32918L9.52165 4.32918L10.1925 5.67082ZM13.1071 3V11H14.6071V3H13.1071ZM14.1925 10.3292L10.1925 8.32918L9.52165 9.67082L13.5216 11.6708L14.1925 10.3292ZM10.6071 9V5H9.10706V9H10.6071ZM13.8571 3.75H18.8571V2.25H13.8571V3.75ZM18.1071 3V11H19.6071V3H18.1071ZM18.8571 10.25H13.8571V11.75H18.8571V10.25Z'
			fill='currentColor'
		/>
		<path
			d='M6.85706 6H4.85706C4.30477 6 3.85706 6.44772 3.85706 7V7C3.85706 7.55228 4.30477 8 4.85706 8H6.85706'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinejoin='round'
		/>
		<path
			d='M14.8571 11V19.5C14.8571 20.3284 15.5286 21 16.3571 21V21C17.1855 21 17.8571 20.3284 17.8571 19.5V11'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
	</svg>
);
export default Spirometer;
