import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import promise from 'redux-promise-middleware';
import rootReducer from 'state/index.js';
import { APP_CONFIG } from 'constants/global-variables.js';

function configureStore(initialState) {
	const middleware = [thunk, promise];

	if (APP_CONFIG.showReduxLogs) {
		middleware.push(logger);
	}

	// In development, use the browser's Redux dev tools extension if installed
	const enhancers = [];
	const isDevelopment = process.env.NODE_ENV === 'development';
	if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
		enhancers.push(window.devToolsExtension());
	}

	return createStore(
		rootReducer,
		initialState,
		compose(
			applyMiddleware(...middleware),
			...enhancers
		)
	);
}

export default configureStore();
