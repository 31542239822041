import { AddEditPatientRadioTypes } from 'constants/enums.js';

export const tobaccoSmokeCodes = [
	{
		code: 'LA18976-3',
		content: 'currentEverydaySmoker',
		name: 'tobaccoYesCode',
		isSmoker: AddEditPatientRadioTypes.YES.toString(),
	},
	{
		code: 'LA18977-1',
		content: 'currentSomedaySmoker',
		name: 'tobaccoYesCode',
		isSmoker: AddEditPatientRadioTypes.YES.toString(),
	},
	{
		code: 'LA15920-4',
		content: 'formerSmoker',
		name: 'tobaccoNoCode',
		isSmoker: AddEditPatientRadioTypes.NO.toString(),
	},
	{
		code: 'LA18978-9',
		content: 'neverSmoker',
		name: 'tobaccoNoCode',
		isSmoker: AddEditPatientRadioTypes.NO.toString(),
	},
];

export const diabeticStatusCodes = [
	{
		code: '44054006',
		content: 'diabetesMellitusI',
		isDiabetic: AddEditPatientRadioTypes.YES.toString(),
		name: 'diabeticStatusCode',
	},
	{
		code: '46635009',
		content: 'diabetesMellitusII',
		isDiabetic: AddEditPatientRadioTypes.YES.toString(),
		name: 'diabeticStatusCode',
	},
];
