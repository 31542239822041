import * as React from 'react';

const PhysicalTherapy = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
			<path
				d='M15.003 4.8a1.8 1.8 0 1 0 0-3.6 1.8 1.8 0 0 0 0 3.6ZM17.111 9.18V6.36h-4.596l-3.78 2.4v4.104a1.02 1.02 0 0 0 1.008 1.008v0a1.008 1.008 0 0 0 .996-1.008V10.08L12.515 9v5.364l-8.628 6.732a1.02 1.02 0 0 0 0 1.416v0a1.02 1.02 0 0 0 1.416 0l9.024-7.044 3.6 3.18v3.156a.996.996 0 0 0 .996.996v0a.996.996 0 0 0 .996-.996V17.4l-2.796-3.048v-3.18h2.772a.996.996 0 0 0 .984-.972.996.996 0 0 0-.996-.996l-2.772-.024Z'
				stroke='#3C4257'
				strokeWidth={1.25}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
export default PhysicalTherapy;
