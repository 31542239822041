import React, { createContext, useState } from 'react';

export const VirtualBackgroundTypes = {
	BLUR: 'blurry',
	NONE: 'none',
};

export const VirtualBackgroundContext = createContext({
	activeBackground: null,
	// eslint-disable-next-line no-unused-vars
	setActiveBackground: _bg => null,
});

export const VirtualBackgroundProvider = ({ children }) => {
	const [activeBackground, setActiveBackground] = useState(null);

	return (
		<VirtualBackgroundContext.Provider value={{ activeBackground, setActiveBackground }}>
			{children}
		</VirtualBackgroundContext.Provider>
	);
};
