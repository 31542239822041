import React, { useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';
import Button from 'components/Button.jsx';

const WhiteboardPatientInfo = props => {
	const [expanded, setExpanded] = useState(true);

	return (
		<>
			<div className={classNames('timeline-box-header sub-timeline-box-header', expanded ? 'expanded' : '')}>
				<p className='timeline-box-title'>{translate('patientInfo')}</p>
				<div className='timeline-box-actions'>
					<Button icon={expanded ? 'expand_less' : 'expand_more'} onClick={() => setExpanded(prevState => !prevState)} />
				</div>
			</div>
			{expanded && (
				<table className='patient-table-informations' style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td>{translate('patientName')}</td>
							<td>{props.patientName}</td>
						</tr>
						<tr>
							<td>{translate('patientAge')}</td>
							<td>{props.patientAge}</td>
						</tr>
					</tbody>
				</table>
			)}
		</>
	);
};

export default WhiteboardPatientInfo;
