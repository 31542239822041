import { Gender } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';


export const genderItems = [
	{
		genderId: Gender.MALE.toString(),
		text: translate('male'),
		iconSrc: `${healthCareCdnUrl}sign-up/male.svg`,
		iconSrcChecked: `${healthCareCdnUrl}sign-up/male-blue.svg`,
	},
	{
		genderId: Gender.FEMALE.toString(),
		text: translate('female'),
		iconSrc: `${healthCareCdnUrl}sign-up/female.svg`,
		iconSrcChecked: `${healthCareCdnUrl}sign-up/female-blue.svg`,
	},
];
