import * as React from 'react';
const CareTeam = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} viewBox='0 0 22 24' fill='none' {...props}>
		<path
			stroke='currentColor'
			strokeMiterlimit={10}
			strokeWidth={1.5}
			d='M15.724 8.22h4.102c.285-.001.568.056.831.168.264.111.504.275.705.482.202.206.363.452.472.722.11.27.166.56.166.852v5.388a.877.877 0 0 1-.245.609.839.839 0 0 1-.594.252.82.82 0 0 1-.594-.252.863.863 0 0 1-.245-.608v-4.94M20.312 14.843V22M16.056 14.843V22M18.142 15.754V22M18.142 7.295c.98 0 1.776-.815 1.776-1.82 0-1.006-.795-1.821-1.776-1.821-.98 0-1.776.815-1.776 1.82 0 1.006.796 1.821 1.776 1.821ZM8.276 8.22H4.174c-.285-.001-.568.056-.831.168a2.169 2.169 0 0 0-.705.482 2.231 2.231 0 0 0-.472.722c-.11.27-.166.56-.166.852v5.388a.878.878 0 0 0 .246.609.837.837 0 0 0 .593.252.82.82 0 0 0 .594-.252.862.862 0 0 0 .245-.608v-4.94M3.688 14.843V22M7.944 14.843V22M5.858 15.754V22M5.858 7.295c-.98 0-1.776-.815-1.776-1.82 0-1.006.795-1.821 1.776-1.821.98 0 1.775.815 1.775 1.82 0 1.006-.795 1.821-1.775 1.821Z'
		/>
		<path
			stroke='currentColor'
			strokeMiterlimit={10}
			strokeWidth={1.5}
			d='M9.623 9.902v4.939a.878.878 0 0 1-.245.61.837.837 0 0 1-.594.252.819.819 0 0 1-.594-.252.861.861 0 0 1-.245-.61V9.454c0-.59.229-1.157.636-1.574.407-.418.96-.653 1.535-.653h3.768c.576 0 1.128.235 1.536.653.407.417.636.984.636 1.574v5.387a.879.879 0 0 1-.246.608.836.836 0 0 1-.593.252.82.82 0 0 1-.594-.252.86.86 0 0 1-.245-.608V9.902M9.623 14.843V22M14.378 14.843V22M11.95 14.843V22M11.947 6.035c1.087 0 1.968-.903 1.968-2.017 0-1.115-.88-2.018-1.968-2.018-1.086 0-1.967.903-1.967 2.018 0 1.114.88 2.017 1.967 2.017Z'
		/>
	</svg>
);
export default CareTeam;
