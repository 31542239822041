import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enums } from '@solaborate/calls';
import { actionCreators as healthDataActionCreators } from 'state/healthData/actions.js';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';
import { getUserInfo } from 'infrastructure/auth.js';
import { HealthDataSetupTypes, MeasureDeviceType } from 'constants/enums.js';
import { MonitoringSettings } from 'constants/configurationEnums.js';
import PulseEcg from 'icons/Monitoring/PulseEcg.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import Watch from 'icons/Monitoring/Watch.jsx';

const HealthDataSetup = ({
	deviceId,
	conferenceId,
	participantId,
	patientUserId,
	isBioBeatActive,
	toggleBioBeat,
	isLifeSignalsActive,
	toggleLifeSignals,
	healthDataConfigurableMonitoring,
	lifeSignalsWearable,
	biobeatWearable,
	isDarkMode,
}) => {
	const socketFunctions = useContext(SocketFunctionsContext);
	const [isWatchEnabled, setIsWatchEnabled] = useState(false);
	const setupItemsToShow = [
		{
			id: 0,
			label: 'Biobeat',
			img: <PulseEcg color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			checked: isBioBeatActive,
			type: HealthDataSetupTypes.BIO_BEAT,
			show: biobeatWearable && healthDataConfigurableMonitoring[MonitoringSettings.BiobeatVitalSigns],
		},
		{
			id: 1,
			label: 'Watch',
			img: <Watch color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			checked: isWatchEnabled,
			type: HealthDataSetupTypes.WATCH_MEASUREMENTS,
			show: healthDataConfigurableMonitoring[MonitoringSettings.SmartWatchVitalSigns],
		},
		{
			id: 2,
			label: 'LifeSignals',
			img: <PulseEcg color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			checked: isLifeSignalsActive,
			type: HealthDataSetupTypes.LIFE_SIGNALS,
			show: lifeSignalsWearable && healthDataConfigurableMonitoring[MonitoringSettings.LifeSignals],
		},
	];

	const dispatch = useDispatch();
	const toggleWatchMeasurements = watchMeasurements => dispatch(healthDataActionCreators.setWatchMeasurements(watchMeasurements));
	const watchMeasurements = useSelector(state => state.watchMeasurementsList.watchMeasurementsVisible);

	useEffect(() => {
		const selectedValue = watchMeasurements.find(item => item.deviceId === deviceId)?.value;
		setIsWatchEnabled(selectedValue);
	}, [watchMeasurements, deviceId]);

	const handleOnClick = type => {
		switch (type) {
			case HealthDataSetupTypes.BIO_BEAT:
				toggleBioBeat(deviceId, !isBioBeatActive);
				break;
			case HealthDataSetupTypes.WATCH_MEASUREMENTS:
				toggleWatch(!isWatchEnabled);
				break;
			case HealthDataSetupTypes.LIFE_SIGNALS:
				toggleLifeSignals(deviceId, !isLifeSignalsActive);
				break;
			default:
				break;
		}
	};

	const toggleWatch = startMeasure => {
		socketFunctions.startMeasuringIoTDevice({
			iotDevice: MeasureDeviceType.WATCH,
			helloDeviceId: patientUserId,
			conferenceId,
			participantId,
			iotDeviceType: '',
			objectType: enums.ObjectTypes.USER,
			measureDeviceType: MeasureDeviceType.WATCH,
			startMeasure,
			doctorId: getUserInfo().userId,
			deviceId,
		});
		toggleWatchMeasurements({ value: startMeasure, deviceId });
		setIsWatchEnabled(prevState => !prevState);
	};

	return (
		<div className='ai-alerts-wrapper'>
			{setupItemsToShow.map(
				item =>
					item.show && (
						<div className='flex flex-space-between alert-settings' key={item.id}>
							<div className='flex flex-justify-center flex-align-center'>
								{item.img}
								<label className='left-s right-s'>{item.label}</label>
							</div>
							<div className='flex flex-align-center'>
								<label className='switch-label'>
									<input type='checkbox' checked={item.checked} onClick={() => handleOnClick(item.type)} />
									<span className='slider-input-alert round' />
								</label>
							</div>
						</div>
					)
			)}
		</div>
	);
};

export default HealthDataSetup;
