import * as React from 'react';

const InProgress = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none' {...props}>
		<g stroke='currentColor' strokeLinecap='round' strokeWidth={1.5}>
			<path strokeLinejoin='round' d='m15 11-5-5-5 5' />
			<path d='M10 6v13' />
			<circle cx={10} cy={10} r={9} />
		</g>
	</svg>
);
export default InProgress;
