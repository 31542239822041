import React from 'react';
import { convertHoursFormatToSeconds } from 'infrastructure/helpers/commonHelpers.js';

const SleepSummaryChart = props => {
	let value = 0;
	if (props.measurementValue !== 'No Data') {
		const seconds = convertHoursFormatToSeconds(props.measurementValue);
		const hours = seconds / 3600;
		value = (hours / 12) * 100;
		value = value > 100 ? 100 : value;
	}
	return (
		<>
			<div className='flex summary-chart-data sleep-chart-data'>
				<h1>{props.measurementValue}</h1>
			</div>
			<div className='blood-pressure-summary-chart'>
				<div className='flex'>
					<div className='blood-pressure-summary-chart-inner'>
						<div />
						<div
							className='blood-pressure-progress-bar'
							style={{ position: 'absolute', width: `${value}%`, backgroundColor: '#795bef' }}
						/>
					</div>
					<p style={{ color: '#795bef' }} />
				</div>
				<div className='flex blood-pressure-min-max' />
			</div>
		</>
	);
};

export default SleepSummaryChart;
