import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Edit = props => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			color={props.color ? 'currentColor' : LightTheme.colors.grayZero}
			{...props}>
			<path
				d='M7.13847 20.0905C6.91627 20.3494 6.61449 20.5271 6.2805 20.5958L3 21L3.42899 17.7157C3.45652 17.3902 3.59978 17.0854 3.83274 16.8567L17.2575 3.4164C17.5309 3.14943 17.8977 3 18.2795 3C18.6614 3 19.0282 3.14943 19.3015 3.4164L20.5633 4.67959C20.7014 4.81169 20.8114 4.97047 20.8866 5.14633C20.9617 5.3222 21.0005 5.51149 21.0005 5.70277C21.0005 5.89405 20.9617 6.08334 20.8866 6.25921C20.8114 6.43507 20.7014 6.59385 20.5633 6.72595L7.13847 20.0905Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M14.4565 6.14473L17.7622 9.45429' stroke='currentColor' strokeMiterlimit='10' />
			<path d='M5.34674 15.265L8.65247 18.5746' stroke='currentColor' strokeMiterlimit='10' />
		</svg>
	);
};

export default Edit;
