import React, { useRef, useState } from 'react';
import AddHelloPin from 'containers/AddHelloPin.jsx';
import PinManager from 'containers/PinManager.jsx';

const PinLockWrapper = props => {
	const [pin, setPin] = useState(null);

	return (
		<>
			{!props.pin && (
				<AddHelloPin
					isPinLockOpen={props.isPinLockOpen}
					setIsPinLockOpen={props.setIsPinLockOpen}
					healthSystemId={props.healthSystemId}
					regionId={props.regionId}
					hospitalId={props.hospitalId}
					hasPin={props.pin}
					setPin={setPin}
					pin={pin}
					fetchTreeData={props.fetchTreeData}
				/>
			)}
			{props.pin && (
				<PinManager
					isPinLockOpen={props.isPinLockOpen}
					setIsPinLockOpen={props.setIsPinLockOpen}
					healthSystemId={props.healthSystemId}
					regionId={props.regionId}
					hospitalId={props.hospitalId}
					hasPin={props.pin}
					setPin={setPin}
					pin={pin}
					fetchTreeData={props.fetchTreeData}
				/>
			)}
		</>
	);
};
export default PinLockWrapper;
