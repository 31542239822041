import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const getInitialsFromFullName = fullName => {
	if (!fullName?.length) {
		return '';
	}

	const fullNameSplit = fullName.split(/\b\s+/);
	return `${fullNameSplit[0][0]}${fullNameSplit[1] ? fullNameSplit[1][0] : ''}`;
};

function getInitialsColor(letter) {
	const obj = {};
	if (!letter) {
		obj.color = `#4772f3`;
		obj.backgroundColor = `#d0e2fd`;
		return obj;
	}
	switch (letter.toLowerCase().trim()) {
		case 'a':
		case 'n':
		case '0': {
			obj.color = `#4772f3`;
			obj.backgroundColor = `#d0e2fd`;
			break;
		}
		case 'b':
		case 'o':
		case '1': {
			obj.color = `#e361c9`;
			obj.backgroundColor = `#edd2fc`;
			break;
		}
		case 'c':
		case 'p':
		case '2': {
			obj.color = `#eb605e`;
			obj.backgroundColor = `#f9dcd2`;
			break;
		}
		case 'd':
		case 'q':
		case '3': {
			obj.color = `#15a6ff`;
			obj.backgroundColor = `#d2f0fc`;
			break;
		}
		case 'e':
		case 'r':
		case '4': {
			obj.color = `#44ccb5`;
			obj.backgroundColor = `#d2f7ec`;
			break;
		}
		case 'f':
		case 's':
		case '5': {
			obj.color = `#dd995b`;
			obj.backgroundColor = `#f7e4d2`;
			break;
		}
		case 'g':
		case 't':
		case '6': {
			obj.color = `#e2619c`;
			obj.backgroundColor = `#f9d2f8`;
			break;
		}
		case 'h':
		case 'u':
		case '7': {
			obj.color = `#73c140`;
			obj.backgroundColor = `#d2f7d6`;
			break;
		}
		case 'i':
		case 'v':
		case '8': {
			obj.color = `#8a64dd`;
			obj.backgroundColor = `#e2d2f9`;
			break;
		}
		case 'j':
		case 'w':
		case '9': {
			obj.color = `#d3f4e8`;
			obj.backgroundColor = `#41837a`;
			break;
		}
		case 'k':
		case 'x': {
			obj.color = `#b94557`;
			obj.backgroundColor = `#f4d3d3`;
			break;
		}
		case 'l':
		case 'y': {
			obj.color = `#3c9045`;
			obj.backgroundColor = `#d2f7d6`;
			break;
		}
		case 'm':
		case 'z': {
			obj.color = `#70a8b5`;
			obj.backgroundColor = `#e8f5f9`;
			break;
		}
		default:
	}
	return obj;
}

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $size: number }, never>}
 */
const StyledAvatar = styled.div`
	font-size: 18px;
	border-radius: 100%;
	letter-spacing: 1px;
	font-weight: 300;
	width: ${props => props.$size}px;
	height: ${props => props.$size}px;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	user-select: none;

	img {
		border-radius: 100%;
	}
`;

/**
 * @param {object} props
 * @param {string} props.name Participant name
 * @param {object} props.src Participant profile picture
 * @param {number} [props.size=24]
 */
const Avatar = ({ name, src, size = 24 }) => {
	const firstLetter = name ? name[0] : null;
	const intl = useIntl();

	const [isImageBroken, setIsImageBroken] = useState(false);

	return (
		<>
			{src && !isImageBroken && (
				<StyledAvatar $size={size}>
					<img src={src} alt={intl.formatMessage({ id: 'participantProfile' })} onError={() => setIsImageBroken(true)} />
				</StyledAvatar>
			)}
			{(!src || isImageBroken) && (
				<StyledAvatar
					$size={size}
					style={{
						color: getInitialsColor(firstLetter).color,
						background: getInitialsColor(firstLetter).backgroundColor,
					}}>
					{getInitialsFromFullName(name)}
				</StyledAvatar>
			)}
		</>
	);
};

export default Avatar;
