import React, { useEffect, useState, useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import { enums } from '@solaborate/calls';
import OverviewDataBox from 'components/HealthMeasurements/OverviewDataBox';
import {
	MeasurementTypes,
	IotDeviceState,
	MeasurementUnits,
	UserRoles,
	UnitCategoryTypes,
	MeasuringDevices,
} from 'constants/enums.js';
import { getLatestMeasurements, getLatestStethoscopeMeasurements } from 'api/measurements.js';
import MeasurementHistory from 'containers/HealthMeasurements/MeasurementHistory';
import ToastMessage from 'components/ToastMessage.jsx';
import { roundMeasurementValue, convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { measurementsToShow, weightTypes } from 'constants/measurements.js';
import { getUserRole } from 'infrastructure/auth.js';
import Alert from 'components/Alert.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';
import { getToastMessages } from 'constants/toast-messages';
import HeartLungsMeasurements from 'containers/HealthMeasurements/HeartLungsMeasurements.jsx';
import { useSelector } from 'react-redux';

const HealthMeasurements = props => {
	const intl = useIntl();

	const analysesObj = {
		[MeasurementTypes.HEART_RATE]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.OXYGEN]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.PI]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.BLOOD_PRESSURE]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.DIABETES]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
			unitCategoryId: UnitCategoryTypes.BLOOD_GLUCOSE,
		},
		[MeasurementTypes.TEMPERATURE]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
			unitCategoryId: UnitCategoryTypes.TEMPERATURE,
		},
		[MeasurementTypes.WEIGHT]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
			unitCategoryId: UnitCategoryTypes.WEIGHT,
		},
		[MeasurementTypes.STEPS]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.LEAN_BODY_MASS]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
			unitCategoryId: UnitCategoryTypes.WEIGHT,
		},
		[MeasurementTypes.BODY_FAT]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.BODY_MASS_INDEX]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.SLEEP]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.STETHOSCOPE]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.BODY_FAT]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
			unitCategoryId: UnitCategoryTypes.WEIGHT,
		},
		[MeasurementTypes.VISCERAL_FAT_GRADE]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.BODY_WATER]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.MUSCLE_MASS]: {
			buttonText: 'Connect',
			value: 0,
			startDate: null,
			unitCategoryId: UnitCategoryTypes.WEIGHT,
		},
	};

	const [analyses, setAnalyses] = useState(analysesObj);
	const [isLoading, setLoading] = useState(true);
	const [toastMessage, setToastMessage] = useState(null);
	const [isIotDeviceConnected, setIsIotDeviceConnected] = useState(false);
	const [selectedStethoscope, setSelectedStetho] = useState(null);
	const [errorApiResponse, setErrorApiResponse] = useState('');
	const [selectedMeasurementType, setSelectedMeasurement] = useState(null);
	const [time, setTime] = useState(null);
	const [isOnlyWeight, setIsOnlyWeight] = useState(true);
	const [duration, setDuration] = useState(1);

	const userRole = getUserRole();

	const socket = useContext(SocketContext);

	const socketFunctions = useContext(SocketFunctionsContext);

	const timeoutRef = useRef(null);

	const preferredUnits = useSelector(state => state.user.unitPreferences);

	const getButtonText = deviceState => {
		let buttonText = '';
		switch (deviceState) {
			case IotDeviceState.CONNECTING: {
				buttonText = 'Connecting';
				break;
			}
			case IotDeviceState.MEASURING: {
				buttonText = 'Measuring';
				break;
			}
			case IotDeviceState.CONNECTED:
			case IotDeviceState.COMPLETED:
			case IotDeviceState.STRIP_OUT: {
				buttonText = 'Measure';
				break;
			}
			default: {
				buttonText = 'Connect';
			}
		}
		return buttonText;
	};

	const getLatestMeasurementData = async () => {
		let { measurements } = await getLatestMeasurements(props.patientId);
		if (!measurements) {
			setLoading(false);
			return;
		}
		try {
			const { stethoscopeRecording } = await getLatestStethoscopeMeasurements(props.patientId);
			const newAnalyses = { ...analyses };
			if (stethoscopeRecording) {
				const newStethoscopeRecording = {
					endDate: stethoscopeRecording.recordedAt,
					id: stethoscopeRecording.id,
					isManuallyInserted: false, // This data is temporary static. To be changed in the future
					measurementType: 'stethoscope',
					measurementUnit: 's',
					measurementValue: stethoscopeRecording.recordDuration,
					source: 'web', // This data is temporary static. To be changed in the future
					startDate: stethoscopeRecording.recordedAt,
					userId: props.patientId,
				};
				measurements = [...measurements, newStethoscopeRecording];
			}

			measurements.forEach(item => {
				if (newAnalyses[item.measurementType]) {
					newAnalyses[item.measurementType].value = convertMeasurementTypes(
						item.unitCategoryId,
						item.measurementValue,
						getUnitPreference(item.unitCategoryId)?.unitSystemId
					);
					newAnalyses[item.measurementType].startDate = item.startDate;
					newAnalyses[item.measurementType].measurementUnit = item.measurementUnit;
				}
			});

			setAnalyses(newAnalyses);
		} catch (ex) {
			setErrorApiResponse(`${intl.formatMessage({ id: 'errorOccurred' })} ${ex.message}`);
		} finally {
			setLoading(false);
		}
	};

	const getMeasurementData = async () => {
		if ([UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(userRole)) {
			getLatestMeasurementData();
		}
	};

	useEffect(() => {
		if (analyses[MeasurementTypes.STETHOSCOPE].buttonText === 'Measuring') {
			timeoutRef.current = setInterval(() => setDuration(duration + 1), 1000);
			const hours = Math.floor(duration / 3600);
			const minutes = Math.floor(duration / 60);
			const seconds = duration - Math.floor(duration / 60) * 60;
			if (hours === 0 && minutes === 0) {
				setTime(`${seconds}s`);
			} else if (hours === 0 && minutes !== 0) {
				setTime(`${minutes}m ${seconds}s`);
			} else {
				setTime(`${hours}h ${minutes}m ${seconds}s`);
			}
		}
		if (analyses[MeasurementTypes.STETHOSCOPE].buttonText === 'Measure') {
			setTime(null);
			setDuration(0);
		}
		return () => {
			clearInterval(timeoutRef.current);
		};
	}, [analyses, duration]);

	useEffect(() => {
		const toastMessagesListener = data => {
			if (props.conferenceId === data.conferenceId) {
				const newAnalysis = { ...analyses };
				if (newAnalysis[data.deviceType]) {
					newAnalysis[data.deviceType].buttonText = getButtonText(data.deviceState);
					setAnalyses(newAnalysis);
				}

				const message = getToastMessages(intl, MeasuringDevices.find(el => el.id === data.device)?.title).find(
					item => item.id === data.deviceState
				)?.content;
				if (message) {
					setToastMessage(message);
				}

				setIsIotDeviceConnected(data.deviceState && data.deviceState !== IotDeviceState.NOT_CONNECTED);
			}
		};

		const isAdditionalWeightType = type => {
			return [
				MeasurementTypes.BODY_MASS_INDEX,
				MeasurementTypes.BODY_FAT,
				MeasurementTypes.LEAN_BODY_MASS,
				MeasurementTypes.VISCERAL_FAT_GRADE,
				MeasurementTypes.BODY_WATER,
				MeasurementTypes.MUSCLE_MASS,
			].includes(type);
		};

		const healthMeasurementsListener = data => {
			// Show data only if it belongs to the current conference.
			if (props.conferenceId === data.conferenceId && props.isHealthMeasurementsVisible) {
				const newAnalysis = { ...analyses };
				const { measurementType, measurementValue, startDate, measurementUnit, unitCategoryId } = data;
				if (newAnalysis[measurementType]) {
					newAnalysis[measurementType].value = convertMeasurementTypes(
						unitCategoryId,
						measurementValue,
						getUnitPreference(unitCategoryId)?.unitSystemId
					);
					newAnalysis[measurementType].startDate = startDate;
					newAnalysis[measurementType].measurementUnit = measurementUnit;
					setAnalyses(newAnalysis);
					if (isAdditionalWeightType(data.measurementType)) {
						setIsOnlyWeight(false);
					} else {
						setIsOnlyWeight(true);
					}
				}
			}
		};
		const stethoscopeRecodingListener = async data => {
			if (props.conferenceId === data.conferenceId && props.isHealthMeasurementsVisible) {
				const newAnalyses = { ...analyses };
				newAnalyses[MeasurementTypes.STETHOSCOPE].buttonText = 'Measure';
				newAnalyses[MeasurementTypes.STETHOSCOPE].value = roundMeasurementValue(
					data.recordDuration,
					MeasurementTypes.STETHOSCOPE
				);
				newAnalyses[MeasurementTypes.STETHOSCOPE].startDate = data.recordedAt;
				newAnalyses[MeasurementTypes.STETHOSCOPE].measurementUnit = MeasurementUnits.STETHOSCOPE;
				setAnalyses(newAnalyses);
			}
		};

		socket.on(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, healthMeasurementsListener);
		socket.on(SocketEvents.HelloDevice.IOT_TOAST_MESSAGES, toastMessagesListener);
		socket.on(SocketEvents.HelloDevice.PATIENT_STETHOSCOPE_UPLOADED_RECORD, stethoscopeRecodingListener);
		getMeasurementData();

		return () => {
			socket.off(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, healthMeasurementsListener);
			socket.off(SocketEvents.HelloDevice.IOT_TOAST_MESSAGES, toastMessagesListener);
			socket.off(SocketEvents.HelloDevice.PATIENT_STETHOSCOPE_UPLOADED_RECORD, stethoscopeRecodingListener);
			clearTimeout(timeoutRef.current);
		};
	}, [socket, props.isHealthMeasurementsVisible]);

	useEffect(() => {
		if (!props.isHealthMeasurementsVisible) {
			setSelectedMeasurement(null);
		}
	}, [props.isHealthMeasurementsVisible]);

	useEffect(() => {
		if (selectedMeasurementType) {
			props.toggleClass(selectedMeasurementType);
		}
	}, [selectedMeasurementType]);

	useEffect(() => {
		if (selectedStethoscope) {
			startMeasuringIoTDevice(MeasurementTypes.STETHOSCOPE, selectedStethoscope);
		}
	}, [selectedStethoscope]);

	useEffect(() => {
		const toastTimeOut = setTimeout(() => {
			setToastMessage('');
		}, 10000);

		return () => {
			clearTimeout(toastTimeOut);
		};
	}, [toastMessage !== '']);

	const getCategoryPreference = categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const startMeasuringIoTDevice = (iotDevice, iotDeviceType) => {
		socketFunctions.startMeasuringIoTDevice({
			iotDevice,
			helloDeviceId: props.helloDeviceId,
			conferenceId: props.conferenceId,
			participantId: props.participantId,
			iotDeviceType,
			objectType: props.objectType === enums.ObjectTypes.HELLO_DEVICE ? enums.ObjectTypes.HELLO_DEVICE : enums.ObjectTypes.USER,
		});
	};

	return (
		<>
			<Alert display={errorApiResponse} fixed={true} hideCloseButton={true} message={errorApiResponse} variant='dark' />
			{!isLoading && !selectedMeasurementType && (
				<>
					<aside className='left-side left-side-measurements-show'>
						{measurementsToShow.map(
							item =>
								item.id <= 3 && (
									<OverviewDataBox
										key={item.type}
										selectedStethoscope={selectedStethoscope}
										setSelectedStethoscope={selectedStetho => setSelectedStetho(selectedStetho)}
										title={item.title}
										setSelectedMeasurementType={() => setSelectedMeasurement(item)}
										startMeasuringIoTDevice={ioDeviceType => startMeasuringIoTDevice(item.type, ioDeviceType)}
										measurement={item}
										measurementUnit={
											analyses[item.type].unitCategoryId ? getUnitPreference(analyses[item.type].unitCategoryId)?.unit : item.unit
										}
										measurementValue={analyses[item.type].value}
										measurementButtonText={analyses[item.type].buttonText}
										startDate={analyses[item.type].startDate}
										isOnlyWeight={isOnlyWeight}
									/>
								)
						)}
					</aside>
					<aside
						className={`right-side right-side-measurements-show${
							props.isCameraFeedVisible ? ' camera-feed-measurements-show' : ''
						}`}>
						{measurementsToShow.map(
							item =>
								item.id > 3 && (
									<OverviewDataBox
										key={item.type}
										selectedStethoscope={selectedStethoscope}
										setSelectedStethoscope={selectedStetho => setSelectedStetho(selectedStetho)}
										title={item.title}
										setSelectedMeasurementType={() => setSelectedMeasurement(item)}
										startMeasuringIoTDevice={ioDeviceType => startMeasuringIoTDevice(item.type, ioDeviceType)}
										measurement={item}
										measurementUnit={
											analyses[item.type].unitCategoryId ? getUnitPreference(analyses[item.type].unitCategoryId)?.unit : item.unit
										}
										measurementValue={analyses[item.type].value}
										measurementButtonText={analyses[item.type].buttonText}
										startDate={analyses[item.type].startDate}
										weightTypeValues={weightTypes.map(weightType => ({
											measurementValue: analyses[weightType.type].value,
											itemType: weightType.type,
										}))}
										weightTypeUnits={weightTypes.map(weightType => ({
											measurementUnit: analyses[weightType.type].unitCategoryId
												? getUnitPreference(analyses[weightType.type].unitCategoryId)?.unit
												: weightType.unit,
											itemType: weightType.type,
										}))}
										time={time}
										isOnlyWeight={isOnlyWeight}
									/>
								)
						)}
					</aside>
				</>
			)}

			{!isLoading && selectedMeasurementType && (
				<aside className='right-side measurement-right-side'>
					<div className='patient-measurement-container'>
						{selectedMeasurementType.type !== MeasurementTypes.STETHOSCOPE && (
							<MeasurementHistory
								selectedMeasurementType={selectedMeasurementType}
								setSelectedMeasurementType={setSelectedMeasurement}
								patientId={props.patientId}
								helloDeviceId={props.helloDeviceId}
								unitCategoryId={selectedMeasurementType.unitCategoryId}
								unitPreference={getUnitPreference(selectedMeasurementType.unitCategoryId)}
							/>
						)}
						{selectedMeasurementType.type === MeasurementTypes.STETHOSCOPE && (
							<HeartLungsMeasurements
								isHeartMeasurement={true}
								selectedMeasurementType={selectedMeasurementType}
								setSelectedMeasurementType={setSelectedMeasurement}
								patientId={props.patientId}
								isFromCall={true}
								conferenceId={props.conferenceId}
							/>
						)}
					</div>
				</aside>
			)}
			<ToastMessage showToast={toastMessage} onClose={() => setToastMessage(null)}>
				{isIotDeviceConnected && <img src={`${healthCareCdnUrl}footer-icons/approve.svg?v3`} alt='icon' />}
				{!isIotDeviceConnected && <img src={`${healthCareCdnUrl}footer-icons/decline.svg?v3`} alt='icon' />}
				<span>{toastMessage}</span>
			</ToastMessage>
		</>
	);
};

export default HealthMeasurements;
