import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Modal, Form, TreeView, Alert } from 'components/index.js';
import { SectorTypes, TreeHierarchyType, UserRole } from 'constants/enums.js';
import { buildTree, getHealthSystemDevices } from 'infrastructure/helpers/commonHelpers.js';
import { getRegionSubTree } from 'api/tree.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { reAssignDevice } from 'api/healthSystems.js';
import { createFloor, createFloorInHospital } from 'api/floors.js';
import { createRoom, createRoomInDepartment } from 'api/rooms.js';
import { createDepartment } from 'api/departments.js';
import { createFrontlineHospital, createHospital } from 'api/hospitals.js';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import translate from 'i18n-translations/translate.jsx';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import Select from 'react-select';

const AssignDeviceModal = props => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const healthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const userSession = useSelector(state => state.user.userSession);
	const [selectedHs, setSelectedHs] = useState({
		...userSession.healthSystem,
		label: userSession.healthSystem?.name,
		value: userSession.healthSystem?.id,
	});
	const [selectedRegion, setSelectedRegion] = useState({
		label: userSession.healthSystem.regions.find(item => item.id === userSession.regionId)?.name,
		value: userSession.regionId,
	});
	const [isAddDeviceModalLoading, setIsAddDeviceModalLoading] = useState(false);
	const [tree, setTree] = useState([]);
	const [error, setError] = useState(null);

	useEffect(() => {
		const getTree = async () => {
			if (UserRole.VISITOR === getUserRole()) {
				return;
			}
			const subTreeResponse = await getRegionSubTree(selectedHs.value, selectedRegion.value);
			if (!subTreeResponse.error) {
				const { healthSystem } = subTreeResponse.organization;
				const treeData = buildTree(healthSystem);
				setTree(treeData);
			}
		};
		if (selectedHs.value) {
			getTree();
		}
	}, [selectedRegion, selectedHs]);

	const changeHealthSystem = value => {
		setSelectedHs(value);
		setSelectedRegion({ value: value.regions[0].id, label: value.regions[0].name });
	};

	const fetchTreeData = async (healthSystemId, regionId) => {
		let treeData = null;
		if (UserRole.VISITOR === getUserRole()) {
			return treeData;
		}
		const subTreeResponse = await getRegionSubTree(healthSystemId, regionId);
		if (subTreeResponse.error) {
			setError(subTreeResponse.error.message);
		} else {
			const { healthSystem } = subTreeResponse.organization;
			treeData = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
		}
		return treeData;
	};

	const hasDefaultHierarchy = () =>
		[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM].includes(selectedHs.treeHierarchyTypeId);

	const onNewSector = async selection => {
		const data = {
			companyId: getCompanyId(),
			healthSystemId: selectedHs.value,
			name: selection.name,
			regionId: selectedRegion.value,
		};
		if (selection.type === SectorTypes.HOSPITAL && hasDefaultHierarchy()) {
			await createFrontlineHospital({ ...data, regionId: userSession.regionId });
		} else if (selection.type === SectorTypes.HOSPITAL && !hasDefaultHierarchy()) {
			const response = await createHospital({
				...data,
				regionId: userSession.regionId,
				hasDefaultDepartment: selectedHs.treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_ROOM,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
		} else if (selection.type === SectorTypes.DEPARTMENT) {
			const response = await createDepartment({ ...data, hospitalId: selection.hospitalId });
			if (response.error) {
				setError(response.error.message);
				return;
			}
		} else if (selection.type === SectorTypes.FLOOR && hasDefaultHierarchy()) {
			const response = await createFloor({
				...data,
				hospitalId: selection.hospitalId,
				departmentId: selection.departmentId,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
		} else if (selection.type === SectorTypes.FLOOR && selectedHs.treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM) {
			const response = await createFloorInHospital({
				...data,
				hospitalId: selection.hospitalId,
				name: selection.name,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
		} else if (
			selection.type === SectorTypes.ROOM &&
			(hasDefaultHierarchy() || selectedHs.treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM)
		) {
			const response = await createRoom({
				...data,
				hospitalId: selection.hospitalId,
				departmentId: selection.departmentId,
				floorId: selection.floorId,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
		} else if (
			selection.type === SectorTypes.ROOM &&
			[TreeHierarchyType.HOSPITAL_DEPT_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(selectedHs.treeHierarchyTypeId)
		) {
			const response = await createRoomInDepartment({
				...data,
				hospitalId: selection.hospitalId,
				departmentId: selection.departmentId || selection.defaultDepartmentId,
				name: selection.name,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
		}
		const treeResponse = await fetchTreeData(data.healthSystemId, data.regionId);
		if (treeResponse) {
			setTree(treeResponse);
		}
	};

	const assignDevice = async ({ hospitalId, departmentId, floorId, roomId }) => {
		setIsAddDeviceModalLoading(true);
		const response = await reAssignDevice({ hospitalId, departmentId, floorId, roomId, deviceId: props.helloDeviceId });
		if (response.error) {
			setError(response.error.message);
		} else {
			const treeResponse = await fetchTreeData(userSession.healthSystem.id, userSession.regionId);
			if (treeResponse) {
				dispatch(healthSystemsActionCreators.setTreeData(treeResponse));
			}
			props.setShowAssignDeviceModal();
			props.loadCurrentRoom();
		}
		setIsAddDeviceModalLoading(false);
	};

	const transformArray = list => list.map(item => ({ ...item, label: item.name, value: item.id }));

	return (
		<Modal
			modalSelector='reassignDeviceModal'
			className='reassign-modal'
			display={props.helloDeviceId}
			isLoading={isAddDeviceModalLoading}
			onModalSubmit={() => null}
			position='right'
			primaryButtonLabel=''
			onModalClose={() => props.setShowAssignDeviceModal(false)}>
			<Form title={intl.formatMessage({ id: 'reAssignDevice' })} onSubmit={evt => evt.preventDefault()}>
				<label>{translate('selectHealthSystem')}</label>
				<p>{translate('selectHsToManage')}</p>
				<Select value={selectedHs} onChange={changeHealthSystem} options={transformArray(healthSystems)} />
				<label>{translate('selectLocation')}</label>
				<p>{translate('selectLocationReassign')}</p>
				<Select value={selectedRegion} onChange={setSelectedRegion} options={transformArray(selectedHs.regions)} />
				<label>{translate('selectRoom')}</label>
				<TreeView onAddDevice={assignDevice} data={tree} onAdd={onNewSector} isReassign={true} />
				{tree.length === 0 && <p className='no-data'>{translate('noData')}</p>}
			</Form>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</Modal>
	);
};

export default AssignDeviceModal;
