import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import FormInput from 'components/FormInput.jsx';
import { doNotAllowSpaceAsFirstCharacter } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import { configurationTypeIds } from 'constants/integrationEnums.js';

const EcareApiKey = ({ setConfigParams, itemToEdit, fieldErrors }) => {
	const intl = useIntl();
	const [apiKey, setApiKey] = useState(itemToEdit ? itemToEdit.apiKey : '');
	const [isDirectCall, setIsDirectCall] = useState(!!itemToEdit?.isDirectCall);

	useEffect(() => {
		setConfigParams(() => ({
			configurationTypeId: configurationTypeIds.ECARE_API_KEY.id,
			nodeId: null,
			parentNodeId: null,
			nodeLevel: null,
			configJson: JSON.stringify({ apiKey, isDirectCall }),
		}));
	}, [apiKey, setConfigParams, isDirectCall]);

	return (
		<>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'apiKey' })}*`}
					id='apiKey'
					placeholder={intl.formatMessage({ id: 'apiKey' })}
					type='text'
					value={apiKey}
					onChange={event => setApiKey(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.apiKey}
					touched={fieldErrors?.apiKey}
				/>
			</div>
			<div className='flex flex-align-center'>
				<p className='font-18'>{translate('directCallEcm')}</p>
				<div
					className='rounded-slider-switch'
					style={{ marginLeft: 'var(--spacing-m)' }}
					onClick={() => setIsDirectCall(prevState => !prevState)}>
					<input type='checkbox' checked={isDirectCall} onChange={() => null} />
					<span className='rounded-slider' />
				</div>
			</div>
		</>
	);
};

export default EcareApiKey;
