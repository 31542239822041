import * as React from 'react';

const Link = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			d='M11.473 9.705 8.527 6.76l-4.42 4.42a3.333 3.333 0 0 0 4.714 4.714L10 14.714'
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			d='m8.527 10.295 2.946 2.946 4.42-4.42a3.333 3.333 0 1 0-4.715-4.714L10 5.286'
		/>
	</svg>
);
export default Link;
