const GearIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} viewBox='0 0 24 24' fill='none' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M23.4 14.074a.94.94 0 0 1-.774.734 2.257 2.257 0 0 0-1.198.56 2.365 2.365 0 0 0-.359 3.142.943.943 0 0 1-.122 1.267l-1.15 1.154a.97.97 0 0 1-1.312.123 2.33 2.33 0 0 0-3.06.257 2.185 2.185 0 0 0-.63 1.207.944.944 0 0 1-.682.839 2.111 2.111 0 0 1-.53.04h-1.407a.936.936 0 0 1-1.006-.8 2.422 2.422 0 0 0-.437-1.015 2.344 2.344 0 0 0-2.134-.966c-.41.038-.802.19-1.132.438a.97.97 0 0 1-1.312-.132L5.04 19.804a.949.949 0 0 1-.14-1.312c.335-.447.491-1.004.438-1.56a2.294 2.294 0 0 0-1.426-1.967 3.067 3.067 0 0 0-.56-.162.958.958 0 0 1-.738-.611v-1.858a1.074 1.074 0 0 1 .245-.874c.19-.159.422-.26.669-.293a2.353 2.353 0 0 0 1.84-2.439 2.312 2.312 0 0 0-.437-1.22.966.966 0 0 1 .11-1.275c.393-.398.786-.796 1.189-1.19a.944.944 0 0 1 1.233-.108 2.45 2.45 0 0 0 1.425.459 2.34 2.34 0 0 0 2.274-1.897.997.997 0 0 1 .63-.83c.117-.045.241-.067.367-.066h1.643a.958.958 0 0 1 .993.813c.078.55.357 1.05.783 1.407a2.335 2.335 0 0 0 2.92.127.945.945 0 0 1 1.05-.053c.115.077.221.168.315.271.362.359.73.717 1.088 1.084a.944.944 0 0 1 .092 1.263 2.355 2.355 0 0 0 1.42 3.659 1.1 1.1 0 0 1 .792.472c.106.224.142.475.105.72l.04 1.71Z'
		/>
		<path stroke='currentColor' strokeWidth={1.5} d='M13 16.15a3.15 3.15 0 1 0 0-6.3 3.15 3.15 0 0 0 0 6.3Z' />
	</svg>
);
export default GearIcon;
