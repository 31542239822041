import * as React from 'react';

const CareEvents = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} viewBox='0 0 22 24' fill='none' {...props}>
		<path stroke='currentColor' strokeLinecap='round' strokeMiterlimit={10} strokeWidth={1.5} d='M12 11v6M9 14h6' />
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M3 17V8a3 3 0 0 1 3-3h3.586a1 1 0 0 1 .707.293l2.414 2.414a1 1 0 0 0 .707.293H18a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3Z'
		/>
	</svg>
);
export default CareEvents;
