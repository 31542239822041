import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import Grid from 'components/Grid.jsx';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Conversation from 'views/Chat/Conversation.jsx';
import { getTeams } from 'api/teams.js';
import Loader from 'components/Loader.jsx';
import EmptyState from 'components/EmptyState.jsx';

const Teams = () => {
	const [teams, setTeams] = useState([]);
	const [selectedChannel, setSelectedChannel] = useState(null);
	const [selectedTeam, setSelectedTeam] = useState(null);
	const [isLoading, setLoading] = useState(true);
	const userSession = useSelector(state => state.user.userSession);

	useEffect(() => {
		const fetchData = async () => {
			const response = await getTeams(userSession.healthSystem.id);
			if (!response.error) {
				const teamsToSet = response.teams;
				if (teamsToSet.length > 0) {
					teamsToSet[0].isExpanded = true;
					setSelectedTeam(response.teams[0]);
					setSelectedChannel(response.teams[0].channels[0]);
				}
				setTeams(teamsToSet);
			}
			setLoading(false);
		};
		fetchData();
	}, [userSession.healthSystem.id]);

	const expandTeam = team => {
		const copyOfTeams = [...teams];
		const selectedTeamIndex = copyOfTeams.findIndex(item => item.id === team.id);
		if (selectedTeamIndex === -1) {
			return;
		}
		const [expandedTeam] = copyOfTeams.splice(selectedTeamIndex, 1);
		expandedTeam.isExpanded = !expandedTeam.isExpanded;
		copyOfTeams.splice(selectedTeamIndex, 0, expandedTeam);
		setTeams(copyOfTeams);
	};

	return (
		<MainLayout>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 60px)' horizAlign='center' vertAlign='center'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<Grid columns='2fr 7fr' stretch='100%'>
					<aside className='teams-list full-width full-height'>
						<div className='full-height flex column-direction'>
							<div className='left-nav-header'>
								<h4>{translate('teams')}</h4>
							</div>

							<div className='teams-list-inner flex-1'>
								<div className='teams-list-item'>
									{teams.map(team => (
										<div className='flex' key={team.id}>
											<img src={`${healthCareCdnUrl}treeview/Hospital.svg`} alt='team' />
											<div className='cursor-pointer'>
												<div className='flex cursor-pointer' onClick={() => expandTeam(team)}>
													<p className='full-width flex flex-space-between'>
														{team.name}
														<i className={classNames('material-icons-outlined')}>
															{team.isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
														</i>
													</p>
												</div>
												<div>
													{team.channels.map(channel => (
														<div key={channel.id}>
															{team.isExpanded && (
																<div
																	className='cursor-pointer'
																	onClick={() => {
																		setSelectedTeam(team);
																		setSelectedChannel(channel);
																	}}>
																	<p className={selectedChannel && selectedChannel.id === channel.id ? 'selected-channel' : ''}>
																		{channel.name}
																	</p>
																</div>
															)}
														</div>
													))}
												</div>
											</div>
										</div>
									))}
									{teams.length === 0 && (
										<div>
											<p className='--blue-color'>No teams availabe yet!</p>
										</div>
									)}
								</div>
							</div>
						</div>
					</aside>
					<main className='main-view nurses-wrapper'>
						{selectedChannel && (
							<Conversation
								conversationId={selectedChannel.conversationId}
								selectedTeamId={selectedTeam.id}
								selectedChannelId={selectedChannel.id}
								teamName={`${selectedTeam.name} - ${selectedChannel.name}`}
								isFromDoctor={false}
							/>
						)}
						{teams.length === 0 && !isLoading && (
							<div className='empty-state-wrapper'>
								<EmptyState title={translate('noDataFound')} image='patients.svg' paragraph={translate('tryOtherDateRange')} />
							</div>
						)}
					</main>
				</Grid>
			)}
		</MainLayout>
	);
};

export default Teams;
