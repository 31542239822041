import React, { Component } from 'react';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { Redirect } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Grid from 'components/Grid.jsx';
import Input from 'components/Common/FormElements/Input.jsx';
import Button from 'components/Button.jsx';
import Loader from 'components/Loader.jsx';
import { activateCompany } from 'api/companies.js';
import { getInvite } from 'api/users.js';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';

class CompleteCompany extends Component {
	state = {
		inviteValidation: {
			loading: true,
			error: null,
		},
		submitUserRegistration: {
			loading: false,
			error: false,
			success: false,
		},
	};

	isCompanyActivation = this.props.location.pathname;

	inviteId = null;

	email = null;

	companyId = null;

	code = null;

	componentDidMount = async () => {
		const query = new URLSearchParams(this.props.location.search);
		this.inviteId = parseInt(query.get('id'), 10);
		this.email = query.get('e');
		this.companyId = query.get('cid');
		this.code = query.get('code');

		const inviteResponse = await getInvite(this.companyId, this.inviteId, this.email);
		this.setState(prevState => ({
			inviteValidation: {
				...prevState.inviteValidation,
				loading: false,
				error: inviteResponse.error,
			},
		}));
	};

	submitForm = async values => {
		try {
			this.setState(prevState => ({
				submitUserRegistration: {
					...prevState.submitUserRegistration,
					loading: true,
				},
			}));
			const params = {
				...values,
				inviteId: this.inviteId,
				companyId: this.companyId,
				code: this.code,
				email: this.email,
			};
			const result = await activateCompany(params);
			if (result.hasSucceeded) {
				this.setState(prevState => ({
					submitUserRegistration: {
						...prevState.submitUserRegistration,
						loading: false,
						success: true,
					},
				}));
			}
		} catch {
			this.setState(prevState => ({
				submitUserRegistration: {
					...prevState.submitUserRegistration,
					loading: false,
					success: true,
				},
			}));
		}
	};

	render() {
		if (this.state.submitUserRegistration.loading) {
			return (
				<Grid width='100%' stretch='100vh' horizAlign='center' vertAlign='center' rows='auto'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
						<p>{translate('accountBeingCreatedMayTakeMinutes')}</p>
					</div>
				</Grid>
			);
		}

		if (this.state.inviteValidation.loading) {
			return (
				<Grid width='100%' stretch='100vh' horizAlign='center' vertAlign='center' rows='auto'>
					<Loader />
				</Grid>
			);
		}

		if (this.state.submitUserRegistration.success || this.state.inviteValidation.accountExists) {
			return <Redirect to='/login' />;
		}

		return (
			<Grid className='login' width='100%' horizAlign='center' vertAlign='center' stretch='100vh' minContent>
				<Grid width='380px' className='login__wrapper'>
					<div style={{ width: '300px' }}>
						<img src={`${healthCareCdnUrl}hellocare-white-logo.svg`} alt='logo' />
					</div>
					<div className='login-form-wrapper registration-form'>
						{this.state.inviteValidation.error && (
							<div className='invalid-invite'>
								<i className='material-icons'>warning</i>
								<h3>{translate('invalidInvitation')}</h3>
								<p>{translate('checkInvitationLinkValidity')}</p>
							</div>
						)}
						{!this.state.inviteValidation.error && !this.state.inviteValidation.loading && (
							<Formik
								initialValues={{
									firstName: '',
									lastName: '',
									email: this.email,
									password: '',
									passwordConfirm: '',
								}}
								onSubmit={values => this.submitForm(values)}
								validationSchema={Yup.object().shape({
									firstName: Yup.string()
										.required('First name is required')
										.matches(
											// eslint-disable-next-line no-useless-escape
											/^[A-Za-z]+$/,
											this.props.intl.formatMessage({ id: 'symbolAndSpacesNotAllowed' })
										)
										.min(2, 'Min length is 2')
										.max(256, 'Max length is 256'),
									lastName: Yup.string()
										.matches(
											// eslint-disable-next-line no-useless-escape
											/^[A-Za-z]+$/,
											this.props.intl.formatMessage({ id: 'symbolAndSpacesNotAllowed' })
										)
										.required('Last name is required')
										.min(2, 'Min length is 2')
										.max(256, 'Max length is 256'),
									password: Yup.string()
										.required('Password is required')
										.matches(
											// eslint-disable-next-line no-useless-escape
											/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*\(\)_\+\-\={}<>,\.\|""'~`:;\\?\/\[\] ]){8,}/,
											this.props.intl.formatMessage({ id: 'passwordCannotBeLessThan8Chart' })
										)
										.min(8, 'Min length is 8'),
									passwordConfirm: Yup.string()
										.required('Password confirmation is required')
										.oneOf([Yup.ref('password')], 'Must match password')
										.min(8, 'Min length is 8'),
								})}>
								<Form>
									<Field
										name='firstName'
										type='text'
										label={translate('firstName')}
										placeholder={this.props.intl.formatMessage({ id: 'firstName' })}
										variant='filled'
										component={Input}
										style={{ textTransform: 'capitalize' }}
									/>
									<Field
										name='lastName'
										type='text'
										label={translate('lastName')}
										placeholder={this.props.intl.formatMessage({ id: 'lastName' })}
										variant='filled'
										component={Input}
										style={{ textTransform: 'capitalize' }}
									/>
									<Field
										name='email'
										disabled
										type='email'
										label={translate('email')}
										placeholder={this.props.intl.formatMessage({ id: 'email' })}
										value={this.email}
										variant='filled'
										component={Input}
									/>
									<Field
										name='password'
										type='password'
										label={translate('password')}
										placeholder={this.props.intl.formatMessage({ id: 'password' })}
										variant='filled'
										component={Input}
									/>
									<Field
										name='passwordConfirm'
										type='password'
										label={translate('confirmPassword')}
										placeholder={this.props.intl.formatMessage({ id: 'confirmPassword' })}
										variant='filled'
										component={Input}
									/>

									<br />
									<Button type='submit' text={translate('save')} display='block' className='center-children' />
								</Form>
							</Formik>
						)}
					</div>
					<br />
					<br />
				</Grid>
			</Grid>
		);
	}
}

export default injectIntl(CompleteCompany);
