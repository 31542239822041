import React, { useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import Grid from 'components/Grid.jsx';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Alert.jsx';
import { SymptomsLength } from 'constants/enums.js';

const SignsSymptoms = props => {
	const [searchBox, setSearchBox] = useState('');
	const [error, setError] = useState(null);

	const intl = useIntl();

	const onSearch = event => {
		const searchBoxValue = event.target.value;
		setSearchBox(searchBoxValue);
	};

	const addSymptom = symptom => {
		if (props.selectedSymptoms.length < SymptomsLength.MAX_SYMPTOMS) {
			setSearchBox('');
			props.addSymptom(symptom);
		}
	};

	const filteredSymptoms = props.symptoms.filter(item => item.name.toLowerCase().includes(searchBox.toLowerCase()));
	const searchWords = searchBox.split(' ');
	return (
		<Grid columns='1fr' stretch='100%'>
			<div className='full-page-request-input-wrapper select-doctor-tabs-wrapper'>
				<div className='full-page-input-wrapper select-doctor-wrapper'>
					<div>
						<h3>{translate('signsSymptoms')}</h3>
						<p>{translate('selectSignsSymptomsAssociatedWithHealthConcerns')}</p>
						{error && (
							<h3>
								<Alert
									display={error}
									message={error}
									variant='error'
									onClose={() => {
										setError(null);
									}}
								/>
							</h3>
						)}
					</div>
					<div className='full-page-input-wrapper-inputs'>
						<div className='full-page-input-search'>
							<p>{translate('searchForSymptoms')}</p>
							<input
								type='search'
								disabled={props.selectedSymptoms.length >= SymptomsLength.MAX_SYMPTOMS}
								placeholder={intl.formatMessage({
									id: props.selectedSymptoms.length < SymptomsLength.MAX_SYMPTOMS ? 'searchForSymptoms' : 'maxSymptomsNo',
								})}
								onChange={onSearch}
								value={searchBox}
								maxLength={100}
							/>
							{searchBox !== '' && (
								<div className='full-page-input-results'>
									{filteredSymptoms.length > 0 &&
										filteredSymptoms.map(symptom => (
											<div key={symptom.id} onClick={() => addSymptom(symptom)} className='cursor-pointer'>
												<Highlighter searchWords={searchWords} autoEscape={true} textToHighlight={symptom.name} />
											</div>
										))}
									{filteredSymptoms.length === 0 && (
										<div>
											<span>{translate('noSymptomsFound')}</span>
										</div>
									)}
								</div>
							)}
						</div>
						<div>
							<p>{translate('mySymptoms')}</p>
							<div className='page-input-wrapper-list'>
								<div className='flex flex-wrap'>
									{props.selectedSymptoms.length > 0 &&
										props.selectedSymptoms.map(symptom => (
											<div key={symptom.id} className='symptom-btn'>
												<i className='material-icons' onClick={() => props.removeSymptom(symptom)}>
													close
												</i>
												{symptom.name}
											</div>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Grid>
	);
};

export default SignsSymptoms;
