const PatientOutOfRoom = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='20'
		height='20'
		viewBox='0 0 20 20'
		fill='none'
		color='white'
		strokeWidth='0.8'
		{...props}>
		<path
			d='M10 4.16699H11.5C12.0523 4.16699 12.5 4.61471 12.5 5.16699V16.5003C12.5 17.0526 12.9477 17.5003 13.5 17.5003H14.1667'
			stroke='currentColor'
		/>
		<path
			d='M1.66675 17.5V4.96884C1.66675 4.50014 1.99228 4.09433 2.44982 3.99265L7.94982 2.77043C8.57433 2.63165 9.16675 3.10687 9.16675 3.74662V16.5C9.16675 17.0523 8.71903 17.5 8.16675 17.5H1.66675ZM1.66675 17.5H8.09431e-05'
			stroke='currentColor'
		/>
		<ellipse cx='6.66658' cy='10.8333' rx='0.833333' ry='0.833333' fill='currentColor' />
		<path
			d='M17.0187 6.24127C17.4006 5.85938 17.4006 5.24023 17.0187 4.85834C16.6368 4.47646 16.0177 4.47646 15.6358 4.85834C15.2539 5.24023 15.2539 5.85938 15.6358 6.24127C16.0177 6.62315 16.6368 6.62315 17.0187 6.24127Z'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M17.1507 9.64204C17.243 9.85042 17.4876 10.1673 17.5949 10.2589C17.8489 10.4759 18.3964 10.8278 18.731 11.0029C18.9745 11.1304 19.2649 11.0957 19.4214 10.8696C19.562 10.6658 19.5283 10.3707 19.342 10.2076L18.1463 9.33238C18.0135 9.08901 17.9443 8.83456 17.8298 8.58222C17.6562 8.20029 17.499 7.62545 16.9701 7.46561C16.7353 7.39527 16.6383 7.36889 16.3933 7.36362C16.1577 7.35869 15.9194 7.36538 15.691 7.42305C15.0804 7.57744 14.6621 8.15087 14.3078 8.61686C14.1861 8.80941 14.0169 8.9784 13.9478 9.19891L13.3333 11.5739V11.576C13.3341 11.6915 13.3777 11.8025 13.4556 11.8877C13.5336 11.9729 13.6403 12.0262 13.7553 12.0372C14.0002 12.0573 14.2499 11.8728 14.2652 11.6275L14.8636 9.63114L15.6887 8.65608'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M15.2477 12.6553C15.2686 13.4081 15.2101 13.6622 14.9363 14.6005L14.2378 17.0713C14.1306 17.3957 14.2264 17.7859 14.5793 17.8821C14.9773 18.0004 15.18 17.9348 15.3305 17.4834L16.1874 15.2456L16.4415 14.3854'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M15.2276 9.24084L15.0785 10.8737L15.0641 11.5648C15.0516 12.1646 15.2383 12.7515 15.5951 13.2338L16.8667 14.953L17.5938 17.7709C17.6631 18.1208 17.87 18.3688 18.249 18.3297C18.6279 18.2907 18.8644 18.0227 18.7617 17.6227L18.1345 14.3344C18.1336 14.3317 18.1326 14.3293 18.1315 14.3268C18.1236 14.3014 18.1139 14.2765 18.1025 14.2524L16.997 11.7804L17.1615 8.64648'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default PatientOutOfRoom;
