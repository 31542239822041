import { useIntl } from 'react-intl';
import { DetectionTypes } from 'constants/enums.js';

const CircularDetectionChart = ({ percentage, type }) => {
	const intl = useIntl();

	const getIvBagColor = () => {
		if (percentage >= 80) {
			return 'var(--green-1)';
		}
		if (percentage >= 60) {
			return 'var(--yellow-1)';
		}
		return 'var(--red-1)';
	};

	const getMobilityState = () => {
		if (percentage >= 95) {
			return {
				color: 'var(--green-1)',
				text: intl.formatMessage({ id: 'perfect' }),
			};
		}
		if (percentage >= 80) {
			return {
				color: 'var(--yellow-1)',
				text: intl.formatMessage({ id: 'good' }),
			};
		}
		if (percentage >= 60) {
			return {
				color: 'var(--orange-1)',
				text: intl.formatMessage({ id: 'average' }),
			};
		}
		if (percentage >= 30) {
			return {
				color: 'var(--red-3)',
				text: intl.formatMessage({ id: 'mediocre' }),
			};
		}

		return {
			color: 'var(--red-1)',
			text: intl.formatMessage({ id: 'bad' }),
		};
	};

	return (
		<div className='position-absolute iv-bag-circle'>
			<svg width='140' height='140'>
				{/* Background Circle */}
				<circle
					cx='70'
					cy='70'
					r='60'
					style={{
						fill: 'rgba(250, 250, 250, 0.5)',
					}}
				/>
				{/* Border Circle */}
				<circle
					cx='70'
					cy='70'
					r='60'
					style={{
						fill: 'none',
						stroke: '#E6E6E6',
						strokeWidth: 10,
						strokeLinecap: 'round',
					}}
				/>
				{/* Mask */}
				<circle
					cx='70'
					cy='70'
					r='60'
					style={{
						fill: 'none',
						stroke: type === DetectionTypes.IV_BAG_FLUID_LEVEL ? getIvBagColor() : getMobilityState().color, // Set color based on percentage
						strokeWidth: 10,
						strokeLinecap: 'round',
						strokeDasharray: `${(percentage / 100) * 377} 377`, // 377 is the circumference of the circle
					}}
				/>
				<text
					x='50%'
					y='35%'
					textAnchor='middle'
					dominantBaseline='middle'
					fill={type === DetectionTypes.IV_BAG_FLUID_LEVEL ? getIvBagColor() : getMobilityState().color} // Adjust the text color as needed
					fontSize='20'
					fontWeight='600' // Adjust the font size as needed
				>
					{percentage}%
				</text>
				{type === DetectionTypes.IV_BAG_FLUID_LEVEL && (
					<>
						<text
							x='50%'
							y='55%'
							textAnchor='middle'
							dominantBaseline='middle'
							fill={getIvBagColor()} // Adjust the text color as needed
							fontSize='14' // Adjust the font size as needed
						>
							IV Bag
						</text>
						<text
							x='50%'
							y='70%'
							textAnchor='middle'
							dominantBaseline='middle'
							fill={getIvBagColor()} // Adjust the text color as needed
							fontSize='14' // Adjust the font size as needed
						>
							Level
						</text>
					</>
				)}
				{type === DetectionTypes.MOBILITY_SCORE && (
					<text
						x='50%'
						y='55%'
						textAnchor='middle'
						dominantBaseline='middle'
						fill={getMobilityState().color} // Adjust the text color as needed
						fontSize='14' // Adjust the font size as needed
					>
						{getMobilityState().text}
					</text>
				)}
			</svg>
		</div>
	);
};

export default CircularDetectionChart;
