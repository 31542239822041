import React, { useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import Modal from 'components/Modal.jsx';
import { getPrescriptions } from 'api/medicalInfo.js';
import { getUserId } from 'infrastructure/auth.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import Pagination from 'components/Common/Pagination.jsx';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import EmptyState from 'components/EmptyState.jsx';
import Alert from 'components/Alert.jsx';

const Prescriptions = props => {
	const [prescriptions, setPrescriptions] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [selectedPrescription, setSelectedPrescription] = useState(null);

	useEffect(() => {
		const fetchData = async (pageSize, pageIndex) => {
			const params = { userId: getUserId(), patientId: props.patientId, pageSize, pageIndex };
			const response = await getPrescriptions(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setPrescriptions(response.prescriptions);
				setPagination({ pageSize, pageIndex, totalCount: response.totalCount });
			}
			setIsLoading(false);
		};
		fetchData(pagination.pageSize, pagination.pageIndex);
	}, [pagination.pageSize, pagination.pageIndex, props.patientId]);

	const onPageChange = async (pageSize, pageIndex) => {
		setIsLoading(true);
		setPagination(prevState => ({ ...prevState, pageSize, pageIndex }));
	};

	return (
		<>
			{!isLoading && (
				<>
					{prescriptions.length > 0 && (
						<div className='patient-summary-wrapper'>
							<h3>{translate('prescriptions')}</h3>
							<div className='patient-summary-table-wrapper'>
								<table cellPadding='0' cellSpacing='0'>
									<thead>
										<tr>
											<th>{translate('medication')}</th>
											<th>{translate('dateAdded')}</th>
											<th>{translate('doctor')}</th>
										</tr>
									</thead>
									<tbody>
										{prescriptions.map(prescription => (
											<tr key={prescription.id} onClick={() => setSelectedPrescription(prescription)} className='cursor-pointer'>
												<td>{`${prescription.medicine?.name} ${prescription.medicine?.strengthAndForm}`}</td>
												<td>{formattedDate(prescription.visitedAt)}</td>
												<td>{prescription.doctorFullName}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<Pagination
								totalCount={pagination.totalCount}
								pageSize={pagination.pageSize}
								pageIndex={pagination.pageIndex}
								onChange={(pageSize, pageIndex) => onPageChange(pageSize, pageIndex)}
							/>
							<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
						</div>
					)}
					{prescriptions.length === 0 && (
						<div className='empty-state-wrapper'>
							<EmptyState title={translate('noPrescriptionsFound')} image='Prescription.svg' />
						</div>
					)}
				</>
			)}
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}

			{selectedPrescription && (
				<Modal
					display={selectedPrescription}
					onModalClose={() => setSelectedPrescription(null)}
					position='center'
					className='standard-modal-wrapper modal-wrapper-wo-btn'>
					<div className='standard-modal-inner modal-wrapper-wo-btn-inner simple-txt-modal-inner prescriptions-modal'>
						<div>
							<div className='title'>
								<h4>{translate('prescriptions')}</h4>
							</div>
							<div className='flex'>
								<div>
									<h5>{translate('dosage')}</h5>
									<p>
										{selectedPrescription.dosage} {translate('capsule')}
									</p>
								</div>
								<div>
									<h5>{translate('frequency')}</h5>
									<p>
										{selectedPrescription.frequency} {translate('timesAday')}
									</p>
								</div>
							</div>
							<div>
								<h5>{translate('duration')}</h5>
								<p>
									{selectedPrescription.duration} {translate('daysNo')}
								</p>
							</div>
							<div className='flex'>
								<div>
									<h5>{translate('quantityToDispense')}</h5>
									<p>{selectedPrescription.quantityToDispense}</p>
								</div>
								<div>
									<h5>{translate('refills')}</h5>
									<p>{selectedPrescription.refills}</p>
								</div>
							</div>
							<div className='flex'>
								<div>
									<h5>{translate('patientInstructions')}</h5>
									<p>{selectedPrescription.patientInstructions}</p>
								</div>
								<div>
									<h5>{translate('noteToPharmacist')}</h5>
									<p>{selectedPrescription.noteToPharmacist}</p>
								</div>
							</div>
							{selectedPrescription.dispenseAsWritten && (
								<div>
									<h5>{translate('dispensedAsWritten')}</h5>
								</div>
							)}
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};

export default Prescriptions;
