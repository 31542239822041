import React from 'react';
import classNames from 'classnames';

const Badge = props => (
	<div className={classNames('badge', props.variant, props.size, props.className, props.onClick ? 'cursor-pointer' : '')} onClick={props.onClick}>
		{props.text}
		{props.icon && <i className='material-icons'>{props.icon}</i>}
	</div>
);

export default Badge;
