import * as React from 'react';

const Alerts = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} fill='none' viewBox='0 0 22 24' {...props}>
		<path stroke='currentColor' strokeLinecap='round' strokeWidth={1.5} d='M10 20h4' />
		<path stroke='currentColor' strokeWidth={1.5} d='M19 17v-7a7 7 0 0 0-14 0v7' />
		<path stroke='currentColor' strokeLinecap='round' strokeWidth={1.5} d='M3.75 17.25h16.5' />
	</svg>
);
export default Alerts;
