import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { enums } from '@solaborate/calls';
import { updateAppointmentStatus } from 'api/appointments.js';
import RescheduleAppointment from 'containers/Appointments/RescheduleAppointment.jsx';
import { UserRoles, AppointmentStatus } from 'constants/enums.js';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Alert.jsx';
import Dropdown from 'components/Dropdown.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Modal from 'components/Modal.jsx';
import { StartQueryStringKeys } from 'calls/enums/index.js';
import Button from 'components/Button.jsx';

const UpcomingAppointments = props => {
	const userRole = getUserRole();
	const [isRescheduleModalVisible, setIsRescheduleModalVisible] = useState(false);
	const [participantGuid, setParticipantGuid] = useState(null);
	const [appointmentId, setAppointmentId] = useState(null);
	const [error, setError] = useState(null);
	const [participantName, setParticipantName] = useState(null);
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const [, setUpdatedTime] = useState(false);
	const intl = useIntl();

	const reSchedule = async appointment => {
		setIsRescheduleModalVisible(prevState => !prevState);
		setAppointmentId(appointment ? appointment.id : null);
		setParticipantGuid(appointment ? appointment.participantGuid : null);
		setParticipantName(appointment ? appointment.participantFullName.split(' ')[0] : '');
		props.fetchAppointments();
	};

	const getNextTime = date => {
		const duration = moment.utc(date).diff(moment.utc(), 'minutes');
		const hours = duration / 60;
		let time;
		if (duration === 0) {
			return intl.formatMessage({ id: 'inAMoment' });
		}
		if (duration < 0) {
			props.getUpcomingAppointments();
			return '';
		}
		if (hours < 24) {
			if (duration > 60) {
				time =
					duration > 90
						? `${Math.floor(hours)} ${intl.formatMessage({ id: 'nextHours' })}`
						: intl.formatMessage({ id: 'nextHour' });
			} else {
				time = `${intl.formatMessage({ id: 'nextTime' })} ${Math.floor(duration)} ${intl.formatMessage({ id: 'nextMinutes' })}`;
			}
			return time;
		}
		time = Math.floor(moment.utc(date).diff(moment.utc(), 'minutes') / 60 / 24);

		return `${intl.formatMessage({ id: 'nextTime' })} ${time} ${intl.formatMessage({
			id: time > 1 ? 'nextDays' : 'nextDay',
		})}`;
	};

	const cancelAppointment = async () => {
		const response = await updateAppointmentStatus(appointmentId, AppointmentStatus.CANCELED);
		if (response.error) {
			setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${response.error.response.data.message}`);
		} else {
			props.getUpcomingAppointments();
			props.fetchAppointments();
			setIsCancelModalOpen(false);
		}
	};

	const openCancelModal = id => {
		setAppointmentId(id);
		setIsCancelModalOpen(true);
	};

	const getRedirectLink = item =>
		`/call?${new URLSearchParams({
			[StartQueryStringKeys.OBJECT_ID]: item.participantId,
			[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.USER,
			[StartQueryStringKeys.CONFERENCE_NAME]: item.participantFullName,
			appointmentId: item.id,
		}).toString()}`;

	useEffect(() => {
		const interval = setInterval(() => {
			setUpdatedTime(prevState => !prevState);
		}, 60000);
		return () => {
			clearInterval(interval);
		};
	}, [props.upcomingAppointments]);

	return (
		<>
			<div className='appointments-left-upcoming doctor-app-left-upcoming'>
				<h4>{translate('upcomingAppointments')}</h4>
				{props.upcomingAppointments.map(item => {
					return (
						<div key={item.id} className='position-relative'>
							<div className='flex'>
								<div>
									<p className='bold'>
										{`${intl.formatMessage({
											id: userRole === UserRoles.PATIENT ? 'meetingWithDoctor' : 'meetingWithPatient',
										})} ${item.participantFullName}`}
									</p>
									<p>{item.title}</p>
								</div>
								<div>
									<p>{getNextTime(item.startDateTime)}</p>
								</div>
							</div>
							<div className='flex'>
								<div className='flex'>
									{[UserRoles.DOCTOR, UserRoles.NURSE].includes(userRole) && (
										<>
											<Link to={getRedirectLink(item)} target='_blank'>
												<Button marginRight='var(--spacing-s)' text={translate('callPatient')} />
											</Link>
										</>
									)} 
									<Button className='re-schedule-btn' onClick={() => reSchedule(item)} text={translate('reSchedule')} />
								</div>
							</div>

							<Dropdown position='bottom' icon='more_horiz' className='remove-connection-wrapper'>
								<div className='remove-connection-results' onClick={() => openCancelModal(item.id)}>
									<div className='flex cursor-pointer'>
										<img src={`${healthCareCdnUrl}patient-home/RemoveConnection.svg`} alt='icon' />
										<p>{translate('cancel')}</p>
									</div>
								</div>
							</Dropdown>
						</div>
					);
				})}
				{props.upcomingAppointment?.length === 0 && (
					<div className='chat-item-title'>
						<span className='semi-bold'>{translate('noUpcomingAppointments')}</span>
					</div>
				)}
			</div>
			<Modal
				display={isCancelModalOpen}
				position='center'
				onModalSubmit={cancelAppointment}
				onModalClose={() => setIsCancelModalOpen(false)}
				primaryButtonLabel={translate('yes')}>
				<form>
					<h3>{translate('cancelAppointmentTitle')}</h3>
					<p>{translate('cancelAppointmentQuestion')}</p>
				</form>
			</Modal>
			{isRescheduleModalVisible && (
				<RescheduleAppointment
					isRescheduleModalVisible={isRescheduleModalVisible}
					appointmentId={appointmentId}
					toggleRescheduleModal={reSchedule}
					userId={
						![UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(userRole) ? participantGuid : getUserId()
					}
					participantName={participantName}
					onSave={props.getUpcomingAppointments}
				/>
			)}

			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default UpcomingAppointments;
