import * as React from 'react';

const Download = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
		<path stroke='currentColor' strokeLinecap='round' d='M1.5 7.5V8a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2v-.5' />
		<path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' d='M4 5.75 6 7.5l2-1.75' />
		<path stroke='currentColor' strokeLinecap='round' d='M6 7.5v-5' />
	</svg>
);
export default Download;
