import React, { useState, useEffect, useContext } from 'react';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SelectMode from 'containers/SelectMode.jsx';
import SoftwareUpdate from 'containers/SoftwareUpdate.jsx';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getDeviceSettings, updateDeviceSettings } from 'api/devices.js';
import SocketEvents from 'constants/socket-events.js';
import Languages from 'containers/Languages.jsx';
import { HelloSettings } from 'constants/enums.js';
import { RoomTypes } from 'constants/visitEnums.js';
import Loader from 'components/Loader.jsx';
import Alert from 'components/Alert.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import ToastMessage from 'components/ToastMessage.jsx';
import PortraitMode from 'containers/PortraitMode.jsx';
import { useSelector } from 'react-redux';
import AudioSettings from 'containers/AudioSettings.jsx';
import AspectRatio from 'containers/AspectRatio.jsx';
import Button from 'components/Button.jsx';
import LightTheme from 'calls/styles/LightTheme.js';

const DeviceSettings = props => {
	const helloName = useSelector(state => state.company.companySettings.helloName);

	const deviceSettingsList = [
		[RoomTypes.GENERAL.type, RoomTypes.BABY_ROOM.type, RoomTypes.DEFAULT.type, RoomTypes.OTHER.type].includes(
			props.currentRoom.roomType
		) && {
			id: 0,
			name: translate('selectMode'),
			img: `${healthCareCdnUrl}admin/SelectMode.svg`,
			canToggle: false,
			settingParentId: HelloSettings.SELECT_MODE,
		},
		{
			id: 1,
			name: translate('dataAcquisition'),
			img: `${healthCareCdnUrl}admin/data-acquisition.svg`,
			canToggle: true,
			settingTypeId: HelloSettings.DATA_ACQUISITION,
			settingParentId: null,
		},
		{
			id: 2,
			name: translate('autoAnswer'),
			img: `${healthCareCdnUrl}admin/AutoAnswer.svg`,
			canToggle: true,
			settingTypeId: HelloSettings.AUTO_ANSWER,
			settingParentId: null,
		},
		{
			id: 3,
			name: translate('softwareUpdate'),
			img: `${healthCareCdnUrl}admin/SoftwareUpdates.svg`,
			canToggle: false,
			settingParentId: HelloSettings.SOFTWARE_UPDATE,
		},
		{
			id: 4,
			name: translate('language'),
			img: `${healthCareCdnUrl}admin/Language.svg`,
			canToggle: false,
			settingParentId: HelloSettings.LANGUAGE,
		},
		{
			id: 5,
			name: translate(`connectPatientsHelloToWifi`, { value: helloName }),
			img: `${healthCareCdnUrl}admin/AIControls.svg`,
			canToggle: false,
			settingParentId: null,
		},
		{
			id: 6,
			name: translate('vitalSigns'),
			img: `${healthCareCdnUrl}admin/MedicalIoTDevices.svg`,
			canToggle: false,
			settingParentId: null,
		},
		{
			id: 7,
			name: translate('appInstaller'),
			img: `${healthCareCdnUrl}admin/InstallApps.svg`,
			canToggle: false,
			settingParentId: null,
		},
		{
			id: 8,
			name: translate('audioSettings'),
			img: `${healthCareCdnUrl}admin/audio-settings.svg`,
			canToggle: false,
			settingParentId: HelloSettings.AUDIO_SETTINGS,
		},
		props.currentRoom.roomType === RoomTypes.KIOSK.type && {
			id: 9,
			name: translate('portraitMode'),
			img: `${healthCareCdnUrl}admin/PortraitMode.svg`,
			canToggle: false,
			settingParentId: HelloSettings.PORTRAIT_MODE,
		},
		props.currentRoom.roomType !== RoomTypes.KIOSK.type && {
			id: 10,
			name: translate('deviceOrientation'),
			img: `${healthCareCdnUrl}admin/PortraitMode.svg`,
			canToggle: false,
			settingParentId: HelloSettings.DEVICE_ORIENTATION,
		},
		{
			id: 11,
			name: translate('aspectRatio'),
			icon: 'aspect_ratio',
			canToggle: false,
			settingParentId: HelloSettings.ASPECT_RATIO,
		},
	].filter(Boolean);

	const [isDeviceSettingSelected, setSelectedDeviceSetting] = useState(false);
	const [selectedSettingIndex, setSelectedIndex] = useState(null);
	const [deviceSettings, setDeviceSettings] = useState([]);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isSettingUpdating, setSettingUpdating] = useState(false);
	const [areMoreThanTwoAi, setAreMoreThanTwoAi] = useState(false);
	const [isPrivacyModeEnabled, setIsPrivacyModeEnabled] = useState(false);
	const socket = useContext(SocketContext);

	useEffect(() => {
		const getSettings = async () => {
			const response = await getDeviceSettings(props.selectedDevice.solHelloDeviceId);
			setIsLoading(false);
			if (response.error) {
				props.setError(response.error.message);
				props.toggleDeviceSettingsModal();
			} else {
				setDeviceSettings(response.deviceSettings);
			}
		};
		getSettings();
	}, [props.selectedDevice.solHelloDeviceId]);

	useEffect(() => {
		const onSettingsUpdate = ({ settingValue, succeeded, settingTypeId }) => {
			if (succeeded) {
				setDeviceSettings(prevState => {
					const settings = [...prevState];
					const selectedSetting = settings.find(item => item.settingTypeId === settingTypeId);
					if (selectedSetting) {
						selectedSetting.value = settingValue;
					}
					return settings;
				});
			}
		};
		socket.on(SocketEvents.HealthCare.ON_DEVICE_SETTINGS_UPDATED, onSettingsUpdate);
		return () => {
			socket.off(SocketEvents.HealthCare.ON_DEVICE_SETTINGS_UPDATED, onSettingsUpdate);
		};
	}, [socket]);

	useEffect(() => {
		const toastTimeOut = setTimeout(() => {
			setAreMoreThanTwoAi(false);
		}, 1000);

		return () => {
			clearTimeout(toastTimeOut);
		};
	}, [areMoreThanTwoAi]);

	useEffect(() => {
		const toastTimeOut = setTimeout(() => {
			setIsPrivacyModeEnabled(false);
		}, 5000);

		return () => {
			clearTimeout(toastTimeOut);
		};
	}, [isPrivacyModeEnabled]);

	const setSelectedSetting = index => {
		setSelectedIndex(index);
		setSelectedDeviceSetting(!isDeviceSettingSelected);
	};

	const getSelectedComponent = () => {
		const { settingParentId } = deviceSettingsList[selectedSettingIndex];
		switch (settingParentId) {
			case HelloSettings.SELECT_MODE: {
				return (
					<SelectMode
						isLoading={isSettingUpdating}
						modeData={deviceSettings?.find(item => item.settingTypeId === HelloSettings.SELECT_MODE)}
						roomType={props.currentRoom.roomType}
						setSelectedMode={(settingTypeId, value) => setSelectedMode(settingTypeId, value)}
					/>
				);
			}
			case HelloSettings.LANGUAGE: {
				return (
					<Languages
						settingTypeId={HelloSettings.LANGUAGE}
						deviceSettings={deviceSettings}
						setSelectedLanguage={(settingTypeId, value) => setSelectedMode(settingTypeId, value)}
					/>
				);
			}
			case HelloSettings.SOFTWARE_UPDATE: {
				return <SoftwareUpdate updateApp={updateApp} />;
			}
			case HelloSettings.DEVICE_ORIENTATION: {
				return (
					<PortraitMode
						settingTypeId={HelloSettings.DEVICE_ORIENTATION}
						selectedDevice={props.selectedDevice}
						deviceSettings={deviceSettings}
						setSelectedMode={(settingTypeId, value) => setSelectedMode(settingTypeId, value)}
					/>
				);
			}
			case HelloSettings.AUDIO_SETTINGS: {
				return (
					<AudioSettings
						selectedDevice={props.selectedDevice}
						deviceSettings={deviceSettings}
						updateDeviceSetting={updateDeviceSetting}
					/>
				);
			}
			case HelloSettings.ASPECT_RATIO: {
				return (
					<AspectRatio
						deviceSettings={deviceSettings}
						updateDeviceSetting={updateDeviceSetting}
						isSettingUpdating={isSettingUpdating}
					/>
				);
			}
			default: {
				return null;
			}
		}
	};

	const updateApp = () => {
		socket.emit(SocketEvents.HelloDevice.APP_UPDATED, [props.selectedDevice]);
	};

	const setSelectedMode = async (settingTypeId, settingValue) => {
		let updated = false;
		if (isSettingUpdating) {
			return updated;
		}
		setSettingUpdating(true);
		const params = { deviceId: props.selectedDevice.solHelloDeviceId, settingTypeId, settingValue };
		const updateSettingsResponse = await updateDeviceSettings(params);
		const settings = _.cloneDeep(deviceSettings);
		const selectedSetting = settings?.find(item => item.settingTypeId === settingTypeId);
		if (updateSettingsResponse.error) {
			setError(props.intl.formatMessage({ id: 'errorDuringRequestProcessing' }));
		} else {
			if (selectedSetting?.value) {
				selectedSetting.value = settingValue;
				setDeviceSettings(settings);
			}
			updated = true;
		}
		setSettingUpdating(false);
		return updated;
	};

	const toggleAiModel = async settingTypeId => {
		if (isSettingUpdating) {
			return;
		}
		const settings = _.cloneDeep(deviceSettings);
		const selectedSetting = settings?.find(item => item.settingTypeId === settingTypeId);
		const settingValue = selectedSetting?.value === 'true' ? 'false' : 'true';
		const enabledAIModels = settings?.filter(
			item => item.settingParentId && item.settingTypeId !== settingTypeId && item.value === 'true'
		);
		const skeletonFall = settings?.find(item => item.settingTypeId === HelloSettings.SKELETON_FALL_DETECTION);
		const privacyMode = settings?.find(item => item.settingTypeId === HelloSettings.PRIVACY_MODE);
		if (settingTypeId === HelloSettings.DATA_ACQUISITION && settingValue === 'true' && privacyMode?.value === 'true') {
			setIsPrivacyModeEnabled(true);
			return;
		}
		if (
			enabledAIModels?.length >= 2 &&
			settingValue === 'true' &&
			![HelloSettings.DATA_ACQUISITION, HelloSettings.AUTO_ANSWER].includes(settingTypeId)
		) {
			setAreMoreThanTwoAi(true);
			return;
		}

		if (selectedSetting?.settingTypeId === HelloSettings.PRIVACY_MODE && settingValue === 'true' && !areMoreThanTwoAi) {
			updateDeviceSetting(skeletonFall?.settingTypeId, 'true');
		}

		if (
			selectedSetting?.settingTypeId === HelloSettings.SKELETON_FALL_DETECTION &&
			settingValue === 'false' &&
			!areMoreThanTwoAi
		) {
			updateDeviceSetting(privacyMode?.settingTypeId, 'false');
		}

		if (settingTypeId === HelloSettings.DATA_ACQUISITION) {
			const dataAcquisition = settings?.find(item => item.settingTypeId === HelloSettings.DATA_ACQUISITION);
			updateDeviceSetting(settingTypeId, settingValue);
			if (settingValue === 'true') {
				updateDeviceSetting(dataAcquisition?.settingTypeId, settingValue);
			}
			return;
		}

		if (settingTypeId === HelloSettings.SKELETON_FALL_DETECTION) {
			updateDeviceSetting(settingTypeId, settingValue);
			if (settingValue === 'false') {
				updateDeviceSetting(skeletonFall?.settingTypeId, settingValue);
			}
			return;
		}
		updateDeviceSetting(settingTypeId, settingValue);
	};

	const updateDeviceSetting = async (settingTypeId, settingValue) => {
		setSettingUpdating(true);
		const params = {
			deviceId: props.selectedDevice.solHelloDeviceId,
			settingTypeId,
			settingValue: settingValue,
		};
		const settings = _.cloneDeep(deviceSettings);
		const selectedSetting = settings?.find(item => item.settingTypeId === settingTypeId);
		const updateSettingsResponse = await updateDeviceSettings(params);
		if (updateSettingsResponse.error) {
			setError(props.intl.formatMessage({ id: 'errorDuringRequestProcessing' }));
		} else {
			if (selectedSetting) {
				selectedSetting.value = settingValue;
				setDeviceSettings(settings);
			}
		}
		setSettingUpdating(false);
	};

	const toggleSetting = async index => {
		if (deviceSettingsList[index].canToggle) {
			toggleAiModel(deviceSettingsList[index].settingTypeId);
		}
	};

	const getSelectedAiModels = settingTypeId => {
		return deviceSettings?.find(item => item.settingTypeId === settingTypeId);
	};

	const renderDeviceSettings = (item, index) => {
		return (
			<div
				key={item.id}
				className='flex cursor-pointer'
				onClick={deviceSettingsList[index].settingParentId ? () => setSelectedSetting(index) : () => toggleSetting(index)}>
				{item.img && <img src={item.img} alt={item.name} />}
				{!item.img && item.icon && <i className='material-icons-outlined setting-icon'>{item.icon}</i>} <p>{item.name}</p>
				{!item.canToggle && <i className='material-icons'>east</i>}
				{item.canToggle && (
					<div className='rounded-slider-switch'>
						<input
							type={
								getSelectedAiModels(item.settingTypeId) && getSelectedAiModels(item.settingTypeId).value === 'true'
									? 'checkbox'
									: ''
							}
							checked={getSelectedAiModels(item.settingTypeId) && getSelectedAiModels(item.settingTypeId).value === 'true'}
							onChange={() => null}
						/>
						<span className='rounded-slider' />
					</div>
				)}
			</div>
		);
	};

	return (
		<>
			<Modal
				position='right'
				display={props.isDeviceSettingsModalVisible}
				onModalClose={props.toggleDeviceSettingsModal}
				className='border-radius-modal-wrapper device-settings'
				primaryButtonLabel=''>
				{!isLoading && (
					<>
						{isDeviceSettingSelected && (
							<Button
								icon='west'
								background='transparent'
								color={LightTheme.colors.grayFive}
								onClick={() => setSelectedSetting(null)}
							/>
						)}
						{isDeviceSettingSelected && getSelectedComponent()}
						{!isDeviceSettingSelected && (
							<div className='configurable-settings-modal-inner'>
								<h3 className={!isDeviceSettingSelected ? 'configurable-settings-main-title' : ''}>
									{translate('deviceSettings')}
								</h3>
								<div className='flex flex-wrap'>{deviceSettingsList.map((item, index) => renderDeviceSettings(item, index))}</div>
							</div>
						)}
					</>
				)}
				{isLoading && (
					<div className='configurable-settings-modal-inner text-align-center'>
						<Loader />
					</div>
				)}
				<ToastMessage showToast={areMoreThanTwoAi} onClose={() => setAreMoreThanTwoAi(false)}>
					<span>{translate('noMoreThanTwoAI')}</span>
				</ToastMessage>
				<ToastMessage showToast={isPrivacyModeEnabled} onClose={() => setIsPrivacyModeEnabled(false)}>
					<span>{translate('cannotEnableDataAcquisition')}</span>
				</ToastMessage>
				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			</Modal>
		</>
	);
};

export default injectIntl(DeviceSettings);
