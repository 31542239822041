import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { injectIntl } from 'react-intl';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Form from 'components/Form.jsx';

const ReOrderFeeds = props => {
	const [feeds, updateFeeds] = useState(props.feeds);

	const handleOnDragEnd = result => {
		if (!result.destination) return;
		const items = Array.from(feeds);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		updateFeeds(items);
	};

	return (
		<Modal
			display={true}
			position='center'
			onModalClose={props.toggleReOrder}
			onModalSubmit={() => props.saveReorder(feeds)}
			className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal reorder-feeds-modal'
			primaryButtonLabel={props.intl.formatMessage({ id: 'saveChanges' })}
			shouldSubmitOnEnter={false}>
			<Form height={550} className='reorder-form'>
				<div className='reorder-feeds-wrapper'>
					<h3>{translate('reorderSessions')}</h3>
					<p className='rearrange-title'>{translate('rearrangeMonitoringSessions')}</p>
					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Droppable droppableId='feeds'>
							{droppableProvided => (
								<ul className='reorder-feeds' {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
									{feeds.map((feed, index) => {
										return (
											<Draggable key={feed.deviceId} draggableId={`${feed.deviceId}${index}`} index={index}>
												{provided => (
													<li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
														<p className='room-name'>
															<span>{index + 1}</span> {feed.roomName}
														</p>
														<img src={`${healthCareCdnUrl}monitoring/drag-drop.svg`} alt='drag-drop-ico' />
													</li>
												)}
											</Draggable>
										);
									})}
									{droppableProvided.placeholder}
								</ul>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</Form>
		</Modal>
	);
};

export default injectIntl(ReOrderFeeds);
