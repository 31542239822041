import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import Grid from 'components/Grid.jsx';
import translate from 'i18n-translations/translate.jsx';
import { primaryCareImageUrl, healthCareCdnUrl } from 'constants/global-variables.js';
import PatientHistoryAccordion from 'containers/PrimaryCare/DigitalClinician/Partials/PatientHistoryAccordion.jsx';
import CasesAccordion from 'containers/PrimaryCare/LaboratoryAnalysis/Partials/CasesAccordion.jsx';
import { TestTypes, CaseStatusTypes, VisitTypes, VisitStatus } from 'constants/visitEnums.js';
import { capitalizeFirstLetter, downloadReturnReport, savePdf, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { getUserId, getUserInfo } from 'infrastructure/auth.js';
import { getPatientQueue } from 'api/users.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import LabReport from 'containers/PrimaryCare/Forms/LabReport.jsx';
import AdministrationOfParenteralTherapy from 'containers/PrimaryCare/Forms/AdministrationOfParenteralTherapy.jsx';
import ReportFromDiagnosticsReport from 'containers/PrimaryCare/Forms/ReportFromDiagnosticsReport.jsx';
import InstructionForDiagnosticResearch from 'containers/PrimaryCare/Forms/InstructionForDiagnosticResearch.jsx';
import { forms, laboratoryTests, radiologyTests } from 'containers/PrimaryCare/LaboratoryAnalysis/constants/labForms.js';
import { VisitReasons, visitStatuses } from 'constants/enums.js';
import { getImageToBase64 } from 'api/doctors.js';
import { getMedicalReport } from 'api/visits.js';
import Alert from 'components/Alert.jsx';
import VisitsReport from 'components/Common/VisitsReport.jsx';
import { getPatientProfile } from 'api/patients.js';

const DigitalClinicianVisits = () => {
	const [medicalCases, setMedicalCases] = useState([]);
	const [filteredCases, setFilteredCases] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [selectedCase, setSelectedCase] = useState(null);
	const [selectedFilter, setSelectedFilter] = useState(null);
	const intl = useIntl();
	const reportRef = useState(null);
	const visitReportRef = useState(null);
	const parenteralReportRef = useState(null);
	const radiologyReportRef = useState(null);
	const diagnosticReportRef = useState(null);
	const [currentSignature, setCurrentSignature] = useState('');
	const [stamp, setStamp] = useState('');
	const [selectedVisit, setSelectedVisit] = useState(null);
	const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', genderId: 0, dateOfBirth: '' });
	const [error, setError] = useState(null);
	const [isDownloadingReport, setIsDownloadingReport] = useState(false);
	const [allergies, setAllergies] = useState([])
	const { current: userId } = useRef(getUserId());
	const healthSystem = useSelector(state => state.user.userSession.healthSystem);

	const selectVisitReport = async visit => {
		getStampAndSignature(visit.performer?.signaturePicture, visit.performer?.stampPicture)
		setIsDownloadingReport(true);
	};

	const getStampAndSignature = async (signaturePicture, stampPicture) => {
		let blobSignature = {};
		let blobStamp = {};
		if (signaturePicture) {
			blobSignature = await getImageToBase64(signaturePicture);
			if (blobSignature && !blobSignature.error) {
				const blobType = signaturePicture.split('.')[1];
				setCurrentSignature(`data:image/${blobType};base64, ${blobSignature.content}`);
			}
		}
		if (stampPicture) {
			blobStamp = await getImageToBase64(stampPicture);
			if (blobStamp && !blobStamp.error) {
				const stampType = stampPicture.split('.')[1];
				setStamp(`data:image/${stampType};base64, ${blobStamp.content}`);
			}
		}
	}

	useEffect(() => {
		if (selectedVisit && isDownloadingReport) {
			const fileName = `${intl.formatMessage({ id: 'medicalReport' })}-${userInfo.firstName}-${userInfo.lastName}-${moment(
				selectedVisit.startedAt
			).format('DD-MM-YYYY-HH-mm')}.pdf`;
			const fileType = 'jpeg';
			const configurations = { useCORS: true, allowTaint: true, scrollY: 0 };
			savePdf(visitReportRef, fileName, fileType, configurations);
			setSelectedVisit(null);
		}
	}, [selectedVisit, intl, userInfo, isDownloadingReport, visitReportRef]);

	const getMedicalVisitReport = async visitId => {
		setError(null);
		const response = await getMedicalReport(visitId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setSelectedVisit(response);
		const { firstName, lastName, genderId, dateOfBirth } = getUserInfo();
		setUserInfo(
			response.patient
				? {
					firstName: response?.patient?.firstName,
					lastName: response?.patient?.lastName,
					genderId: response?.patient?.genderId,
					dateOfBirth: response?.patient?.dateOfBirth,
				}
				: { firstName, lastName, genderId, dateOfBirth }
		);
		setIsDownloadingReport(false);
	};

	useEffect(() => {
		if (selectedCase && !isDownloadingReport) {
			if (selectedCase?.visitToPrint?.medicalVisitTypeId === VisitTypes.LAB) {
				downloadReturnReport(reportRef, 'Raporti-kthyes-nga-laboratori.pdf');
			}
			if ([VisitTypes.INFUSION, VisitTypes.INJECTION].includes(selectedCase?.visitToPrint?.medicalVisitTypeId)) {
				downloadReturnReport(parenteralReportRef, 'Urdherese-per-dhenien-e-terapise-parenterale.pdf');
			}
			if (selectedCase?.visitToPrint?.medicalVisitTypeId === VisitTypes.RADIOLOGY) {
				downloadReturnReport(radiologyReportRef, 'Raporti-kthyes-nga-diagnostika.pdf');
			}
			if (selectedCase?.visitToPrint?.medicalVisitTypeId === VisitTypes.DOCTOR) {
				downloadReturnReport(diagnosticReportRef, 'Raporti-mjekësor.pdf');
			}
		}
		setSelectedCase(null);
	}, [isDownloadingReport, selectedCase, reportRef, parenteralReportRef, radiologyReportRef, diagnosticReportRef])

	const downloadInstructionReport = async (visit) => {
		if (visit?.visitToPrint) {
			await getStampAndSignature(visit?.visitToPrint.performer.signaturePicture, visit?.visitToPrint.performer.stampPicture)
		}

		const response = await getPatientProfile(visit.patient.patientId)
		if (response.error) {
			setError(response.error)
		} else {
			const allAllergies = mergeNotes(response.patient.profile.allergies)
			setAllergies(allAllergies)
		}
		setSelectedCase(visit)
	}

	const mergeNotes = (data) => data.map(item => JSON.parse(item.note)).flat()

	useEffect(() => {
		const fetchPatientQueue = async () => {
			const response = await getPatientQueue(userId);
			if (!response.error) {
				setMedicalCases(response);
				setFilteredCases(response);
			}
		};
		fetchPatientQueue();
	}, [userId]);

	useEffect(() => {
		const casesArr = _.cloneDeep(medicalCases);
		if (!searchValue) {
			setFilteredCases(casesArr);
			return;
		}

		const filtered = casesArr.filter(
			item =>
				item.patient?.fullName?.toLowerCase().includes(searchValue.toString().toLowerCase()) ||
				item.patient?.idCard?.toLowerCase().includes(searchValue.toString().toLowerCase()) ||
				item?.caseId
					?.toString()
					.toLowerCase()
					.includes(searchValue.toString().toLowerCase())
		);
		setFilteredCases(filtered);
	}, [searchValue]);

	useEffect(() => {
		const medicalCasesCopied = _.cloneDeep(medicalCases);
		if (!selectedFilter) {
			setFilteredCases(medicalCasesCopied);
			return;
		}

		setFilteredCases(medicalCasesCopied.filter(item => item.caseStatusId === selectedFilter));
	}, [medicalCases, selectedFilter]);

	const visitsInformation = {
		ONGOING: 'var(--blue-2-o10)',
		COMPLETED: 'var(--green-4-o10)',
		NOT_SHOWN_UP: 'var(--red-4-o10)',
	};

	const emptyStates = [
		{
			id: CaseStatusTypes.ACTIVE,
			text: intl.formatMessage({ id: 'noOngoingVisit' }),
		},
		{
			id: CaseStatusTypes.COMPLETED,
			text: intl.formatMessage({ id: 'noCompletedVisits' }),
		},
		{
			id: CaseStatusTypes.CANCELLED,
			text: intl.formatMessage({ id: 'noShowUpVisit' }),
		},
	];

	const visitReasons = [
		{
			id: 0,
			value: VisitReasons.INSTRUCTION,
			text: intl.formatMessage({ id: 'instruction' }),
		},
		{
			id: 1,
			value: VisitReasons.THERAPY_DESCRIPTION,
			text: intl.formatMessage({ id: 'therapyDescription' }),
		},
		{
			id: 2,
			value: VisitReasons.CHECK_UP,
			text: intl.formatMessage({ id: 'checkUp' }),
		},
		{
			id: 3,
			value: VisitReasons.RE_CHECK_UP,
			text: intl.formatMessage({ id: 'reCheckUp' }),
		},
		{
			id: 4,
			value: VisitReasons.REFERRAL,
			text: intl.formatMessage({ id: 'referral' }),
		},
		{
			id: 5,
			value: VisitReasons.OTHER,
			text: intl.formatMessage({ id: 'other' }),
		},
	];

	const getVisitStatusById = (medicalVisitStatusId) => visitStatuses.find(status => status.id === medicalVisitStatusId).description

	const getCase = (title, arr) => (
		<div className='cases'>
			<p>{title ? translate(title) : ''}</p>
			<div className='flex cases-table-head' style={{ padding: 'var(--spacing-l)' }}>
				<span className='flex-1'>{translate('patient')}</span>
				<span className='flex-1'>{translate('registryNumber')}</span>
				<span className='flex-1'>{translate('visitStart')}</span>
				<span className='flex-1'>{translate('visitEnd')}</span>
				<span className='flex-1'>{translate('type')}</span>
				<span className='flex-1'>{translate('status')}</span>
			</div>
			<div>
				{arr.map(item => {
					return (
						<CasesAccordion
							patientFullName={item.patient.fullName}
							idCard={item.patient.idCard}
							id={item.caseId}
							closedAt={item.closedAt}
							openedAt={item.openedAt}
							statusId={item.caseStatusId}>
							{item.visits.length > 0 &&
								item.visits.map((visit, index) => (
									<div className='case-section full-width' key={visit.id}>
										{index === 0 && <h4>{translate('itinerary')}</h4>}
										{visit.medicalVisitTypeId === VisitTypes.FRONT_DESK && (
											<div>
												<PatientHistoryAccordion
													image='frontdesk-nurse-1.svg'
													alt='front desk icon'
													title={intl.formatMessage({ id: 'frontDeskCheckIn' })}
													name={visit.performerName}
													content={
														<>
															{visit.informations.length > 0 && (
																<div>
																	<p>{translate('whatReasonOfVisit')}</p>
																	<p>
																		{visit.informations.length > 0 && (
																			<>
																				{visit?.informations[0]?.medicalVisitReasonId === VisitReasons.OTHER &&
																					visit?.informations[0]?.medicalVisitReasonText}

																				{visit?.informations[0]?.medicalVisitReasonId !== VisitReasons.OTHER &&
																					visitReasons.find(
																						reason => reason.value === visit?.informations[0]?.medicalVisitReasonId
																					)?.text}
																			</>
																		)}
																	</p>
																</div>
															)}
															<div>
																<p>{translate('symptoms')}</p>
																<p className='flex symptoms'>
																	{visit.symptoms.length > 0 &&
																		visit.symptoms.map(symptom => (
																			<span key={symptom.symptomId}>
																				{symptom.name ? intl.formatMessage({ id: stringToCamelCase(symptom.name) }) : ''}
																			</span>
																		))}
																</p>
															</div>
															{visit.informations.length > 0 && (
																<>
																	{visit.informations[0]?.answer && (
																		<div>
																			<p>{translate('symptomsDuration')}</p>
																			<p>{visit.informations[0]?.answer}</p>
																		</div>
																	)}
																	{visit.informations[0]?.additionalNote && (
																		<div>
																			<p>{translate('additionalNote')}</p>
																			<p>{visit.informations[0]?.additionalNote}</p>
																		</div>
																	)}
																</>
															)}
														</>
													}
												/>

												<p className={!visit.endedAt ? '--blue-color' : ''}>
													{visit.endedAt
														? formattedDate(visit.endedAt)
														: intl.formatMessage({ id: getVisitStatusById(visit.medicalVisitStatusId) })}
												</p>
											</div>
										)}
										{visit.medicalVisitTypeId === VisitTypes.DOCTOR && (
											<div>
												<PatientHistoryAccordion
													image='doctor-1.svg'
													alt='doctor checkup icon'
													title={intl.formatMessage({ id: 'doctorCheckUp' })}
													name={visit.performerName}
													isDoctorVisit={true}
													onMedicalVisitClick={() => getMedicalVisitReport(visit.id)}
													content={
														<>
															<div>
																<p>{translate('room')}</p>
																<p>{visit.place}</p>
															</div>
															<div>
																{visit.medicalVisitStatusId === VisitStatus.COMPLETED &&
																	<div className='flex flex-align-center report-download'>
																		<p className='no-margin no-padding'>{translate('printReport')}</p>
																		<span
																			className='left-margin-s text-transform-none padding-s material-icons-outlined cursor-pointer'
																			onClick={() => selectVisitReport(visit)}>
																			file_download
																		</span>
																	</div>}
															</div>
															{visit.diagnostifications.length > 0 && (
																<div className='top-15 lab-visit-content'>
																	<p className='tests-title'>{capitalizeFirstLetter(intl.formatMessage({ id: 'diagnoses' }))}</p>
																	<div className='flex flex-wrap'>
																		{visit.diagnostifications.map(diagnose => (
																			<div key={diagnose}>
																				<p className='no-margin no-padding'>{diagnose}</p>
																			</div>
																		))}
																	</div>
																</div>
															)}

															{visit.diagnosticResearches.length > 0 && (
																<>
																	<div className='top-15 lab-visit-content'>
																		<p className='tests-title'>{capitalizeFirstLetter(intl.formatMessage({ id: 'tests' }))}</p>
																		<div className='flex flex-wrap'>
																			{visit.diagnosticResearches.map(sample => (
																				<div key={sample.testType}>
																					<p className='no-margin no-padding'>
																						{laboratoryTests(intl).find(labTest => labTest.id === sample.testType)
																							? laboratoryTests(intl).find(labTest => labTest.id === sample.testType).description
																							: radiologyTests(intl).find(labTest => labTest.id === sample.testType)?.description}
																					</p>
																				</div>
																			))}
																		</div>
																	</div>
																</>
															)}
															{visit.medicalVisitStatusId === VisitStatus.COMPLETED && (
																<div className='flex flex-align-center report-download'>
																	<p className='no-margin no-padding'>{translate('medicalReport')}</p>
																	<span
																		className='left-margin-s text-transform-none padding-s material-icons-outlined cursor-pointer'
																		onClick={() => downloadInstructionReport({ ...item, visitToPrint: visit })}>
																		file_download
																	</span>
																</div>
															)}
														</>
													}
												/>

												<p className={!visit.endedAt ? '--blue-color' : ''}>
													{visit.endedAt
														? formattedDate(visit.endedAt)
														: intl.formatMessage({ id: getVisitStatusById(visit.medicalVisitStatusId) })}
												</p>
											</div>
										)}
										{visit.medicalVisitTypeId === VisitTypes.LAB && (
											<div>
												<PatientHistoryAccordion
													image='laboratory-1.svg'
													alt='lab test icon'
													title={intl.formatMessage({ id: 'laboratoryTesting' })}
													name={visit.performerName}
													content={
														<>
															<div>
																<p>{translate('room')}</p>
																<p>{visit.place}</p>
															</div>
															<div className='full-width results-accordion-content view-results-accordion-content'>
																<div />

																{visit.diagnosticResearches.length > 0 &&
																	visit.diagnosticResearches.map(test => {
																		const selectedTest = forms(intl).find(formItem => formItem.id === test.testType);
																		return (
																			<React.Fragment key={selectedTest.id}>
																				<div className='flex flex-align-center'>
																					<div>
																						<img src={`${primaryCareImageUrl}${selectedTest.icon}`} alt='icon' />
																					</div>
																					<div>
																						<p className='no-margin'>{selectedTest.description}</p>
																					</div>
																				</div>
																				<div
																					className={classNames(
																						'flex flex-wrap',
																						selectedTest.id === TestTypes.URINE ? 'urine-results' : ''
																					)}>
																					{test.attachments.map(sub => {
																						const selectedResult = selectedTest.results.find(
																							resultItem => resultItem.id === sub.typeId
																						);
																						return (
																							<div className='flex flex-align-center' style={{ marginTop: '5px' }}>
																								<p className='flex-1 no-margin'>{selectedResult.description}</p>
																								{selectedTest.id === TestTypes.URINE && (
																									<textarea
																										name={selectedResult.description}
																										value={sub.value}
																										rows={sub.isTextarea ? 3 : 1}
																										readOnly
																										placeholder={intl.formatMessage({ id: 'description' })}
																									/>
																								)}
																								{selectedTest.id !== TestTypes.URINE && (
																									<>
																										<input
																											name={selectedResult.description}
																											type='text'
																											value={sub.value}
																											readOnly
																										/>
																										<span>{selectedResult.unit || ''}</span>
																									</>
																								)}
																							</div>
																						);
																					})}
																				</div>
																			</React.Fragment>
																		);
																	})}
																{visit.endedAt && visit.medicalVisitStatusId === VisitStatus.COMPLETED && (
																	<div className='flex flex-align-center report-download'>
																		<p className='no-margin no-padding'>{translate('returnReportFromLab')}</p>
																		<span
																			className='left-margin-s text-transform-none padding-s material-icons-outlined cursor-pointer'
																			onClick={() => setSelectedCase({ ...item, visitToPrint: visit })}>
																			file_download
																		</span>
																	</div>
																)}
															</div>
														</>
													}
												/>

												<p className={!visit.endedAt ? '--blue-color' : ''}>
													{visit.endedAt
														? formattedDate(visit.endedAt)
														: intl.formatMessage({ id: getVisitStatusById(visit.medicalVisitStatusId) })}
												</p>
											</div>
										)}
										{[VisitTypes.INFUSION, VisitTypes.INJECTION].includes(visit.medicalVisitTypeId) && (
											<div>
												<PatientHistoryAccordion
													image={
														visit.medicalVisitTypeId === VisitTypes.INFUSION ? 'infusion-room-1.svg' : 'injection-room-1.svg'
													}
													alt='infusion injection icon'
													title={intl.formatMessage({
														id: visit.medicalVisitTypeId === VisitTypes.INFUSION ? 'infusionRoom' : 'injectionRoom',
													})}
													name={visit.performerName}
													content={
														<>
															<div>
																<p>{translate('room')}</p>
																<p>{visit.place}</p>
															</div>

															<div className='top-15 lab-visit-content'>
																<h4>
																	{translate(
																		visit.medicalVisitTypeId === VisitTypes.INFUSION ? 'infusionTherapy' : 'injectionTherapy'
																	)}
																</h4>
																<div className='flex flex-wrap parenteral-therapy-box'>
																	{visit.parenteralTherapyVisits?.length > 0 &&
																		visit.parenteralTherapyVisits?.[0].parenteralTherapy.medications?.map(therapy => (
																			<div>
																				<p className='no-margin no-padding'>{therapy.name}</p>
																				<p>
																					{translate('dosage')}: {therapy.dosage}ml, {translate('duration')}: {therapy.duration}
																					{translate('durationDays')}, {translate('frequency')}: {therapy.frequency}
																					{translate('timesADay')}
																				</p>
																			</div>
																		))}
																</div>
																{visit.endedAt && visit.medicalVisitStatusId === VisitStatus.COMPLETED && (
																	<div className='flex flex-align-center report-download'>
																		<p className='no-margin no-padding'>{translate('parenteralTherapyReport')}</p>
																		<span
																			className='left-margin-s text-transform-none padding-s material-icons-outlined cursor-pointer'
																			onClick={() => setSelectedCase({ ...item, visitToPrint: visit })}>
																			file_download
																		</span>
																	</div>
																)}
															</div>
														</>
													}
												/>

												<p className={!visit.endedAt ? '--blue-color' : ''}>
													{visit.endedAt
														? formattedDate(visit.endedAt)
														: intl.formatMessage({ id: getVisitStatusById(visit.medicalVisitStatusId) })}
												</p>
											</div>
										)}
										{visit.medicalVisitTypeId === VisitTypes.RADIOLOGY && (
											<div>
												<PatientHistoryAccordion
													image='radiology-1.svg'
													alt='radiology test icon'
													title={intl.formatMessage({
														id: 'radiologyRoom',
													})}
													name={visit.performerName}
													content={
														<>
															<div>
																<p>{translate('room')}</p>
																<p>{visit.place}</p>
															</div>

															<div className='top-15 lab-visit-content'>
																{visit.notes?.length && (
																	<h4>
																		{translate('comment')}: {visit.notes[0]?.text}
																	</h4>
																)}
																<div className='flex flex-wrap'>
																	{visit.diagnosticResearches.map(test => (
																		<div key={test.testType}>
																			<p className='no-margin no-padding'>
																				{radiologyTests(intl).find(labTest => labTest.id === test.testType)?.description}
																			</p>
																		</div>
																	))}
																</div>
																{visit.endedAt && visit.medicalVisitStatusId === VisitStatus.COMPLETED && (
																	<div className='flex flex-align-center report-download'>
																		<p className='no-margin no-padding'>{translate('returnReportFromDiagnostics')}</p>
																		<span
																			className='left-margin-s text-transform-none padding-s material-icons-outlined cursor-pointer'
																			onClick={() => setSelectedCase({ ...item, visitToPrint: visit })}>
																			file_download
																		</span>
																	</div>
																)}
															</div>
														</>
													}
												/>

												<p className={!visit.endedAt ? '--blue-color' : ''}>
													{visit.endedAt
														? formattedDate(visit.endedAt)
														: intl.formatMessage({ id: getVisitStatusById(visit.medicalVisitStatusId) })}
												</p>
											</div>
										)}
									</div>
								))}
						</CasesAccordion>
					);
				})}
			</div>
		</div>
	);

	return (
		<>
			<MainLayout>
				<Grid columns='1fr' stretch='100%'>
					<div className='check-in-patient-wrapper dc-visits-wrapper' style={{ height: 'calc(100vh - 60px' }}>
						<div className='full-page-input-wrapper patient-history-wrapper position-relative'>
							<div>
								<h3>{translate('visits')}</h3>
							</div>
							<div className='flex visits-grid'>
								<div
									className={selectedFilter === CaseStatusTypes.ACTIVE ? 'active blue' : ''}
									onClick={() => setSelectedFilter(selectedFilter !== CaseStatusTypes.ACTIVE ? CaseStatusTypes.ACTIVE : null)}>
									<div style={{ background: visitsInformation.ONGOING }}>
										<img src={`${primaryCareImageUrl}visits/ongoing.svg?v3`} alt='ongoing icon' />
									</div>
									<p>{translate('ongoingVisits')}</p>
									<p>{medicalCases.filter(item => item.caseStatusId === CaseStatusTypes.ACTIVE).length || 0}</p>
								</div>
								<div
									className={selectedFilter === CaseStatusTypes.COMPLETED ? 'active green' : ''}
									onClick={() =>
										setSelectedFilter(selectedFilter !== CaseStatusTypes.COMPLETED ? CaseStatusTypes.COMPLETED : null)
									}>
									<div style={{ background: visitsInformation.COMPLETED }}>
										<img src={`${primaryCareImageUrl}visits/completed.svg?v3`} alt='completed icon' />
									</div>
									<p>{translate('completedVisits')}</p>
									<p>{medicalCases.filter(item => item.caseStatusId === CaseStatusTypes.COMPLETED).length || 0}</p>
								</div>
								<div
									className={selectedFilter === CaseStatusTypes.CANCELLED ? 'active red' : ''}
									onClick={() =>
										setSelectedFilter(selectedFilter !== CaseStatusTypes.CANCELLED ? CaseStatusTypes.CANCELLED : null)
									}>
									<div style={{ background: visitsInformation.NOT_SHOWN_UP }}>
										<img src={`${primaryCareImageUrl}visits/failed.svg?v3`} alt='failed visit icon' />
									</div>
									<p>{translate('notShownUp')}</p>
									<p>{medicalCases.filter(item => item.caseStatusId === CaseStatusTypes.CANCELLED).length || 0}</p>
								</div>
							</div>
							<div className='position-relative'>
								<img src={`${healthCareCdnUrl}search-icon.svg`} alt='ico' className='search-patient-icon' />
								<input
									type='text'
									placeholder={intl.formatMessage({ id: 'searchPatientNameIdVisitNumber' })}
									className='search-input'
									onChange={e => setSearchValue(e.target.value)}
									value={searchValue}
								/>
							</div>
							{filteredCases.length > 0 &&
								filteredCases.filter(item => item.caseStatusId === CaseStatusTypes.ACTIVE).length > 0 &&
								getCase(
									'openCases',
									filteredCases.filter(item => item.caseStatusId === CaseStatusTypes.ACTIVE)
								)}
							{filteredCases.length > 0 &&
								filteredCases.filter(item => item.caseStatusId !== CaseStatusTypes.ACTIVE).length > 0 &&
								getCase(
									'closedCases',
									filteredCases.filter(item => item.caseStatusId !== CaseStatusTypes.ACTIVE)
								)}
							{filteredCases.length === 0 &&
								selectedFilter &&
								emptyStates.map(
									item =>
										item.id === selectedFilter && (
											<div key={item.id} className='cases'>
												<p>{item.text}</p>
											</div>
										)
								)}
						</div>
						{selectedCase?.visitToPrint?.medicalVisitTypeId === VisitTypes.LAB && (
							<LabReport
								reportRef={reportRef}
								selectedVisit={selectedCase?.visitToPrint}
								patient={selectedCase?.patient}
								isVisitsPage={true}
							/>
						)}
						{selectedCase?.visitToPrint?.medicalVisitTypeId === VisitTypes.RADIOLOGY && (
							<ReportFromDiagnosticsReport
								reportRef={radiologyReportRef}
								selectedVisit={selectedCase?.visitToPrint}
								patient={selectedCase?.patient}
								isVisitsPage={true}
							/>
						)}
						{selectedCase?.visitToPrint?.medicalVisitTypeId === VisitTypes.DOCTOR && (
								<InstructionForDiagnosticResearch
									reportRef={diagnosticReportRef}
									selectedVisit={selectedCase?.visitToPrint}
									patient={selectedCase?.patient}
									isVisitsPage={true}
									currentSignature={currentSignature}
									stamp={stamp}
									allergies={allergies}
									healthSystem={healthSystem}
								/>
							)}
						{[VisitTypes.INFUSION, VisitTypes.INJECTION].includes(selectedCase?.visitToPrint?.medicalVisitTypeId) && (
							<AdministrationOfParenteralTherapy
								reportRef={parenteralReportRef}
								selectedCase={selectedCase}
								patient={selectedCase?.patient}
								isVisitsPage={true}
							/>
						)}
					</div>
				</Grid>
			</MainLayout>
			<VisitsReport
				visitReportRef={visitReportRef}
				selectedVisit={selectedVisit}
				userInfo={userInfo}
				currentSignature={currentSignature}
				stamp={stamp}
			/>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default DigitalClinicianVisits;
