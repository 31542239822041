import * as React from 'react';

const TimeZoneIcon = ({ width = 18, height = 18, ...props }) => (
	<svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 21 21' fill='none' {...props}>
		<path stroke='currentColor' strokeWidth={1.5} d='M10.833 17.5H10a7.5 7.5 0 1 1 7.5-7.5v.833' />
		<path
			stroke='currentColor'
			strokeWidth={1.5}
			d='M12.625 10c0-4.142-1.175-7.5-2.625-7.5S7.375 5.858 7.375 10c0 4.142 1.175 7.5 2.625 7.5M2.5 10h10.417'
		/>
		<circle cx={15} cy={15} r={5} stroke='currentColor' strokeWidth={1.5} />
		<path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M14.722 13.333v2.778h1.667' />
	</svg>
);

export default TimeZoneIcon;
