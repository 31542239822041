import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, EmptyState, Grid, Loader } from 'components';
import translate from 'i18n-translations/translate.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { getPatientQueue } from 'api/users.js';
import { getUserId } from 'infrastructure/auth.js';
import { updateMedicalVisitStatus } from 'api/visits.js';
import { QueueChangeType, VisitStatus } from 'constants/visitEnums.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { capitalizeFirstLetter } from 'infrastructure/helpers/commonHelpers.js';
import { getRoomInformation } from 'infrastructure/helpers/visitsHelper.js';
import NextVisitConfirmationModal from 'containers/PrimaryCare/NextVisitConfirmationModal.jsx';
import { actionCreators as userActionCreators } from 'state/user/actions.js';

const InfusionWaitingRoom = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [currentCases, setCurrentCases] = useState([]);
	const [caseQueue, setCaseQueue] = useState([]);
	const [isInviteLoadingCase, setIsInviteLoadingCase] = useState(null);
	const [isDidNotShowUpLoadingCase, setIsDidNotShowUpLoadingCase] = useState(null);
	const [isStartVisitLoadingCase, setIsStartVisitLoadingCase] = useState(null);
	const [isCompleteLoadingCase, setIsCompleteLoadingCase] = useState(null);
	const [nextVisit, setNextVisit] = useState(null);
	const { current: userId } = useRef(getUserId());
	const socket = useContext(SocketContext);
	const user = useSelector(state => state.user);
	const setUserWaitingRoomCount = count => dispatch(userActionCreators.setUserWaitingRoomCount(count));

	useEffect(() => {
		const fetchPatientQueue = async () => {
			const response = await getPatientQueue(userId);
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
			} else {
				if (response.length > 0) {
					setUserWaitingRoomCount(response.length);
					setCurrentCases(response.slice(0, 6));
				}
				setCaseQueue(response.slice(6));
				setIsLoading(false);
			}
		};
		fetchPatientQueue();
	}, [userId]);

	const handleStartVisit = async currentCase => {
		setIsStartVisitLoadingCase(currentCase.caseId);
		const response = await updateMedicalVisitStatus(currentCase.activeVisit.id, { visitStatus: VisitStatus.IN_PROGRESS });
		if (response.error) {
			setError(response.error.message);
		} else {
			const activeCase = { ...currentCase };
			activeCase.activeVisit.medicalVisitStatusId = VisitStatus.IN_PROGRESS;
			const currentCasesArr = _.cloneDeep(currentCases);
			const foundIndex = currentCasesArr.findIndex(item => item.caseId === activeCase.caseId);
			currentCasesArr[foundIndex] = activeCase;
			setCurrentCases(currentCasesArr);
		}
		setIsStartVisitLoadingCase(null);
	};

	const handleInvitePatientToRoom = async currentCase => {
		setIsInviteLoadingCase(currentCase.caseId);
		const visitStatus =
			currentCase.activeVisit.medicalVisitStatusId === VisitStatus.DID_NOT_SHOW_UP
				? VisitStatus.SECOND_INVITED
				: VisitStatus.INVITED;
		const response = await updateMedicalVisitStatus(currentCase.activeVisit.id, { visitStatus });
		if (response.error) {
			setError(response.error.message);
		} else {
			const activeCase = { ...currentCase };
			activeCase.activeVisit.medicalVisitStatusId = visitStatus;
			const currentCasesCopied = _.cloneDeep(currentCases);
			const foundIndex = currentCasesCopied.findIndex(item => item.caseId === activeCase.caseId);
			currentCasesCopied[foundIndex] = activeCase;
			setCurrentCases(currentCasesCopied);
		}
		setIsInviteLoadingCase(null);
	};

	const handleMarkAsTaken = async currentCase => {
		setIsCompleteLoadingCase(currentCase.caseId);
		const response = await updateMedicalVisitStatus(currentCase.activeVisit.id, { visitStatus: VisitStatus.COMPLETED });
		if (response.error) {
			setError(response.error.message);
		} else {
			setNextVisit({
				...response,
				icon: response.nextVisitInfo?.visitType ? getRoomInformation(intl, response.nextVisitInfo?.visitType).image : '',
				roomType: response.nextVisitInfo?.visitType ? getRoomInformation(intl, response.nextVisitInfo?.visitType).roomType : '',
			});
			const caseQueueCopied = _.cloneDeep(caseQueue);
			const currentCasesCopied = _.cloneDeep(currentCases).filter(item => item.caseId !== currentCase.caseId);
			const firstElement = caseQueueCopied.shift();
			if (firstElement) {
				currentCasesCopied.push(firstElement);
			}

			setCurrentCases(currentCasesCopied);
			setCaseQueue(caseQueueCopied);
			setUserWaitingRoomCount(user.waitingRoomCount - 1);
		}
		setIsCompleteLoadingCase(false);
	};

	useEffect(() => {
		const handleQueueUpdated = item => {
			if (QueueChangeType.NEW === item.changeType) {
				if (currentCases.length < 6) {
					setCurrentCases(prevState => [
						...prevState.filter(queue => queue.caseId !== item.medicalVisit.caseId),
						item.medicalVisit,
					]);
				} else {
					setCaseQueue(prevState => [...prevState, item.medicalVisit]);
				}
			}
			if (QueueChangeType.STATUS_CHANGED === item.changeType) {
				if (currentCases.length < 6) {
					setCurrentCases(prevState => [
						...prevState.filter(queue => queue.caseId !== item.medicalVisit.caseId),
						item.medicalVisit,
					]);
				} else if (currentCases.find(el => el.caseId === item.medicalVisit.caseId)) {
					setCurrentCases(prevState => [
						...prevState.filter(queue => queue.caseId !== item.medicalVisit.caseId),
						item.medicalVisit,
					]);
				} else {
					setCaseQueue(prevState => [...prevState.filter(queue => queue.caseId !== item.medicalVisit.caseId), item.medicalVisit]);
					setUserWaitingRoomCount(user.waitingRoomCount - 1);
				}
			}
		};
		socket.on(SocketEvents.HealthCare.PATIENT_QUEUE_UPDATED, handleQueueUpdated);
		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_QUEUE_UPDATED, handleQueueUpdated);
		};
	}, [socket, currentCases]);

	const handlePatientDidNotShowUp = async currentCase => {
		setIsDidNotShowUpLoadingCase(currentCase.caseId);
		const visitStatus =
			currentCase.activeVisit.medicalVisitStatusId === VisitStatus.SECOND_INVITED
				? VisitStatus.CANCELLED
				: VisitStatus.DID_NOT_SHOW_UP;
		const response = await updateMedicalVisitStatus(currentCase.activeVisit.id, {
			visitStatus,
		});

		if (response.error) {
			setError(response.error.message);
			setIsDidNotShowUpLoadingCase(null);
			return;
		}

		if (currentCase.activeVisit.medicalVisitStatusId === VisitStatus.SECOND_INVITED) {
			const caseQueueCopied = _.cloneDeep(caseQueue);
			const currentCasesCopied = _.cloneDeep(currentCases);
			let firstItem = null;
			if (caseQueueCopied.length > 0) {
				firstItem = caseQueueCopied.shift();
			}

			const filtered = currentCasesCopied.filter(queue => queue.caseId !== currentCase.caseId);

			if (firstItem) {
				filtered.push(firstItem);
			}

			setCurrentCases(filtered);
			setCaseQueue(caseQueueCopied);
			setIsDidNotShowUpLoadingCase(null);
			setUserWaitingRoomCount(user.waitingRoomCount - 1);
			return;
		}

		if (caseQueue.length > 0) {
			const queue = getOrderedQueue(currentCase);
			setCaseQueue(queue);
		} else {
			const activeCase = { ...currentCase };
			const currentCasesCopied = _.cloneDeep(currentCases);
			const foundIndex = currentCasesCopied.findIndex(item => item.caseId === activeCase.caseId);
			currentCasesCopied.splice(foundIndex, 1);
			activeCase.activeVisit.medicalVisitStatusId = VisitStatus.DID_NOT_SHOW_UP;
			setCurrentCases([...currentCasesCopied, activeCase]);
		}
		setIsDidNotShowUpLoadingCase(null);
	};

	const getOrderedQueue = currentCase => {
		const caseQueueCopied = _.cloneDeep(caseQueue);
		const firstElement = caseQueueCopied.shift();
		const didNotShowUpCases = caseQueueCopied.filter(
			item => item.activeVisit.medicalVisitStatusId === VisitStatus.DID_NOT_SHOW_UP
		);
		const otherCases = caseQueueCopied.filter(item => item.activeVisit.medicalVisitStatusId !== VisitStatus.DID_NOT_SHOW_UP);
		const activeCase = { ...currentCase };
		activeCase.activeVisit.medicalVisitStatusId = VisitStatus.DID_NOT_SHOW_UP;
		setCurrentCases(prevState => [...prevState.filter(item => item.caseId !== activeCase.caseId), firstElement]);
		return [...didNotShowUpCases, activeCase, ...otherCases];
	};

	const NextVisit = props => {
		const { item } = props;
		return (
			<div key={item.caseId}>
				<div className='flex flex-align-center'>
					<div className='flex-1'>
						<div>
							<h4>{item.patient.fullName}</h4>
							<p>{item.patient.idCard}</p>
						</div>
					</div>
					<div className='flex' />
					<div className='flex' />
					{[VisitStatus.INVITED, VisitStatus.SECOND_INVITED].includes(item.activeVisit.medicalVisitStatusId) && (
						<Button
							type='button'
							text={translate('patientNoShowUp')}
							className='--orange-background'
							onClick={() => handlePatientDidNotShowUp(item)}
							isDisabled={isDidNotShowUpLoadingCase === item.caseId}
							isLoading={isDidNotShowUpLoadingCase === item.caseId}
						/>
					)}

					{[VisitStatus.PENDING, VisitStatus.DID_NOT_SHOW_UP].includes(item.activeVisit.medicalVisitStatusId) && (
						<Button
							type='button'
							text={translate('inviteToRoom')}
							onClick={() => handleInvitePatientToRoom(item)}
							isDisabled={isInviteLoadingCase === item.caseId}
							isLoading={isInviteLoadingCase === item.caseId}
						/>
					)}

					{[VisitStatus.INVITED, VisitStatus.SECOND_INVITED].includes(item.activeVisit.medicalVisitStatusId) && (
						<Button
							type='button'
							text={translate('startVisit')}
							onClick={() => handleStartVisit(item)}
							isDisabled={isStartVisitLoadingCase === item.caseId}
							isLoading={isStartVisitLoadingCase === item.caseId}
						/>
					)}
					{[VisitStatus.IN_PROGRESS].includes(item.activeVisit.medicalVisitStatusId) && (
						<Button
							type='button'
							text={translate('markVisitAsCompleted')}
							onClick={() => handleMarkAsTaken(item)}
							isDisabled={isCompleteLoadingCase === item.caseId}
							isLoading={isCompleteLoadingCase === item.caseId}
						/>
					)}
				</div>
				{item.activeVisit.medicalVisitStatusId === VisitStatus.IN_PROGRESS && (
					<div className='top-15 lab-visit-content'>
						<h3>{translate('infusionTherapy')}</h3>
						<div className='flex flex-wrap'>
							{item.activeVisit.parenteralTherapyVisits?.length > 0 &&
								item.activeVisit.parenteralTherapyVisits.map(infusion => (
									<React.Fragment key={infusion.id}>
										<p className='full-width'>
											{capitalizeFirstLetter(intl.formatMessage({ id: 'note' }))}: {infusion.parenteralTherapy.note}
										</p>
										{infusion.parenteralTherapy.medications.map(el => (
											<div key={el.id}>
												<p className='word-break'>{el.name}</p>
												<span className='word-break'>
													{translate('dosage')}: {el.dosage}ml, {translate('duration')}: {el.duration}
													{translate('durationDays')}, {translate('frequency')}: {el.frequency} {translate('timesADay')}
												</span>
											</div>
										))}
									</React.Fragment>
								))}
						</div>
					</div>
				)}
			</div>
		);
	};

	const WaitingList = props => {
		const { item } = props;
		return (
			<div key={item.id}>
				<div className='flex flex-align-center'>
					<div className='flex-1'>
						<h4>{item.patient.fullName}</h4>
						<p>{item.patient.idCard}</p>
					</div>
					<div className='flex' />
					<div className='flex' />
				</div>
			</div>
		);
	};

	return (
		<MainLayout>
			<div className='view-page-wrapper display-block'>
				{!isLoading && (caseQueue.length > 0 || currentCases.length > 0) && (
					<>
						<div className='waiting-room-view-inner full-width pc-waiting-room-inner'>
							<div className='waiting-room-list'>
								{currentCases.length > 0 && (
									<>
										<p>{translate('nextVisit')}</p>
										{currentCases.map(item => (
											<NextVisit key={item.caseId} item={item} />
										))}
									</>
								)}
							</div>
							{caseQueue.length > 0 && (
								<div className='waiting-room-list'>
									<p>{translate('waitingList')}</p>
									{caseQueue.map(item => (
										<WaitingList key={item.caseId} item={item} />
									))}
								</div>
							)}
						</div>
					</>
				)}
				{!isLoading && caseQueue.length === 0 && currentCases.length === 0 && (
					<div className='empty-state-wrapper waiting-room-view-inner'>
						<EmptyState title={translate('noVisitsFound')} image='Visits.svg' />
					</div>
				)}
				{isLoading && (
					<Grid
						columns='1fr'
						width='100%'
						rows='1fr'
						stretch='calc(100vh - 200px)'
						className='max-initial-width'
						horizAlign='center'
						vertAlign='center'>
						<div style={{ textAlign: 'center' }}>
							<Loader />
						</div>
					</Grid>
				)}

				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			</div>
			<NextVisitConfirmationModal modalSubmit={() => setNextVisit(null)} nextVisit={nextVisit} />
		</MainLayout>
	);
};
export default InfusionWaitingRoom;
