import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Alert = props => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			color={LightTheme.colors.grayZero}
			{...props}>
			<path
				d='M10.2934 4.79256C11.0733 3.51643 12.9267 3.51643 13.7066 4.79256L21.1405 16.9571C21.9549 18.2898 20.9957 20 19.4339 20H4.56611C3.00425 20 2.04512 18.2898 2.85955 16.9571L10.2934 4.79256Z'
				stroke='currentColor'
			/>
			<path d='M12 8.5L12 13.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M12 16L12 16.25' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	);
};

export default Alert;
