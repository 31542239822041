import * as React from 'react';

const GoBack = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={14} height={10} fill='none' {...props} className='go-back-svg'>
		<g stroke='currentColor' strokeLinecap='round'>
			<path strokeLinejoin='round' d='M5.5.5 1 5l4.5 4.5' />
			<path d='M1 5h12' />
		</g>
	</svg>
);
export default GoBack;
