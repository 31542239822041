import { addAlertActivity } from 'api/alerts.js';
import Alert from 'components/Alert.jsx';
import Button from 'components/Button.jsx';
import { MeasurementActivityTypes } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { useState } from 'react';

const AiAlertsOnInterventions = props => {
	const [alertType, setAlertType] = useState(null);
	const [error, setError] = useState(null);

	const onSubmit = async (activityType, alert) => {
		const params = {
			alertId: alert.alertId,
			activityType,
			isAiAlert: alert.isAiAlert,
			...(alert.isAiAlert && { conferenceId: props.conferenceId }),
			comment: '',
			isDraft: true,
		};
		const response = await addAlertActivity(params);
		if (response.error) {
			setError(response.error.message);
		}
		props.setSnoozeType(props.feedDeviceId, alertType);
		setAlertType(null);
		props.onAlertCloseClick(alert.alertTypeId);
	};

	return (
		<>
			{props?.activeAlerts?.length > 0 && <p className='font-14 semi-bold'>{translate('activeAlerts')}</p>}
			<div className='alerts-section box-max-height'>
				{!alertType &&
					props?.activeAlerts?.map(alert => {
						const isToastAlert = alert.isToastMessage || alert.isToastDetails;
						return (
							<div className={`alert-body fall-alert-body ${alert.alertType} intervention-alert-content`} key={alert.alertId}>
								<div className={`${isToastAlert ? 'alert-toast-details' : 'alert-content'}`}>
									<div className={'toast-details-title'}>
										<p className='warning-wrapper-text'>{alert.text}</p>
										{isToastAlert && (
											<i
												className='material-icons cursor-pointer close-alert flex'
												onClick={() =>
													alert.isToastMessage
														? props.onAlertCloseClick(alert.alertTypeId)
														: props.onAlertInfoClose(props.feedDeviceId, alert.isToastDetails)
												}>
												close
											</i>
										)}
									</div>
									{alert.isToastDetails && props.showForwardToNurses && (
										<div className='flex flex-justify-center'>
											<div className='interventions-alerts-wrapper'>
												<Button
													icon='arrow_forward'
													className='flex flex-align-center flex-justify-center forward-alert-btn warning-btn'
													onClick={() => {
														props.onForwardToNurses(alert.alertId, alert.isAiAlert);
														props.onAlertInfoClose(props.feedDeviceId, alert.isToastDetails);
													}}
													text={translate('forwardToNurses')}
												/>
											</div>
										</div>
									)}
								</div>
								{alert?.value && !isToastAlert && (
									<div className='alert-feed-sub-option'>
										<div className='alert-feed-sub-options-wrapper flex interventions-alerts-wrapper'>
											<Button
												icon='close'
												className='flex flex-align-center flex-justify-center false-alert-btn warning-btn'
												onClick={() => {
													setAlertType(MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT);
													onSubmit(MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT, alert);
												}}
												text={translate('falseAlert')}
											/>

											<Button
												className='flex flex-align-center flex-justify-center acknowledge-alert-btn warning-btn'
												onClick={() => {
													setAlertType(MeasurementActivityTypes.ACKNOWLEDGED);
													onSubmit(MeasurementActivityTypes.ACKNOWLEDGED, alert);
												}}
												icon='done'
												text={translate('acknowledge')}
											/>

											{props.showForwardToNurses && (
												<Button
													className='flex flex-align-center flex-justify-center forward-alert-btn warning-btn'
													onClick={() => {
														props.onForwardToNurses(alert.alertId, alert.isAiAlert);
														props.onAlertCloseClick(alert.alertTypeId);
													}}
													icon='arrow_forward'
													text={translate('forwardToNurses')}
												/>
											)}
										</div>
									</div>
								)}
							</div>
						);
					})}
			</div>
			{error && <Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />}
		</>
	);
};

export default AiAlertsOnInterventions;
