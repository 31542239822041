import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const VolumeUp = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill='none'
		color={LightTheme.colors.grayFive}
		{...props}>
		<path
			d='M2.7 9.8a2 2 0 012-2h2.682a2 2 0 001.271-.456l2.91-2.397a1 1 0 011.636.772v12.562a1 1 0 01-1.636.772l-2.91-2.397a2 2 0 00-1.271-.456H4.699a2 2 0 01-2-2V9.8z'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
		<path
			d='M16.813 9s1.125 1.125 1.125 3-1.125 3-1.125 3m2.325-8.4s2.16 2.025 2.16 5.4c0 3.375-2.16 5.4-2.16 5.4'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default VolumeUp;
