import { useIntl } from 'react-intl';
import Select from 'react-select';
import { Country } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { validateMaxLength } from 'infrastructure/helpers/commonHelpers.js';
import { generateCustomStyles } from 'constants/react-select-style.js';

const LocationAndAddress = props => {
	const intl = useIntl();

	const getCountryValue = () =>
		props.transformArray(props.countries).filter(option => option.value === props.formikProps.values.country1);

	const getStateValue = () =>
		props.transformArray(props.states).filter(option => option.value === props.formikProps.values.addressStateId);

	return (
		<>
			<div className='flex personal-details-wrapper'>
				<div className='flex column-direction personal-details position-relative registration-select-wrapper country-background'>
					<label>{translate('country')}</label>
					<Select
						value={getCountryValue()}
						placeholder={intl.formatMessage({ id: 'selectCountry' })}
						classNamePrefix='react-select'
						options={props.transformArray(props.countries)}
						onChange={event => props.formikProps.setFieldValue('country1', event?.value)}
						isSearchable
						isClearable
						styles={{
							...generateCustomStyles({ darkMode: props.isDarkMode }),
						}}
					/>
					<span className='red-error'>{props.formikProps.errors?.country1}</span>
					{props.formikProps.values.country1 === Country.USA && (
						<>
							<label>*{translate('selectState')}</label>
							<Select
								value={getStateValue()}
								placeholder={intl.formatMessage({ id: 'selectState' })}
								classNamePrefix='react-select'
								options={props.transformArray(props.states)}
								onChange={event => props.formikProps.setFieldValue('addressStateId', event?.value)}
								isSearchable
								isClearable
								styles={{
									...generateCustomStyles({ darkMode: props.isDarkMode }),
								}}
							/>
							<span className='red-error'>{props.formikProps.errors?.addressStateId}</span>
						</>
					)}
				</div>
				<div className='flex column-direction personal-details'>
					<label>{translate('city')}</label>
					<input
						type='text'
						placeholder={intl.formatMessage({ id: 'city' })}
						value={props.formikProps.values.city}
						onChange={props.onChange}
						maxLength={30}
						name='city'
					/>
					<span className='red-error'>{props.formikProps.errors.city}</span>
				</div>
			</div>

			<div className='flex personal-details-wrapper'>
				<div className='flex column-direction personal-details'>
					<label>Zip</label>
					<input
						type='number'
						placeholder='Zip'
						value={props.formikProps.values.zipCode}
						onChange={props.onChange}
						maxLength={10}
						name='zipCode'
						onKeyDown={event => validateMaxLength(event, 10)}
					/>
					<span className='red-error'>{props.formikProps.errors.zipCode}</span>
				</div>
				<div className='flex column-direction personal-details'>
					<label>{translate('address')} 1</label>
					<input
						type='text'
						placeholder={intl.formatMessage({ id: 'enterAddress' })}
						value={props.formikProps.values.address}
						onChange={props.onChange}
						maxLength={50}
						name='address'
					/>
					<span className='red-error'>{props.formikProps.errors.address}</span>
				</div>
			</div>
			{props.haveSecondAddress && (
				<div className='flex personal-details-wrapper'>
					<div className='flex column-direction personal-details'>
						<label>{translate('address')} 2</label>
						<input
							type='text'
							placeholder={intl.formatMessage({ id: 'enterAddress' })}
							value={props.formikProps.values.address2}
							onChange={props.onChange}
							maxLength={50}
							name='address2'
						/>
						<span className='red-error'>{props.formikProps.errors.address2}</span>
					</div>
				</div>
			)}
		</>
	);
};

export default LocationAndAddress;
