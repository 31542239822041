import { components } from 'react-select';

const MenuList = props => {
	const { menulistTitle } = props.selectProps;
	const menuHeaderStyle = { padding: '.5rem 1rem', borderBottom: '1px solid #d3d3d3', fontWeight: '600' };
	return (
		<components.MenuList {...props}>
			{menulistTitle && <div style={menuHeaderStyle}>{menulistTitle}</div>}
			{props.children}
		</components.MenuList>
	);
};

export default MenuList;
