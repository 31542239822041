import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getHandHygieneAudits = async ({ levelId, pageSize = 10, pageIndex = 0, roomId, startDate, endDate }, signal) => {
	try {
		const params = {
			teamChannelId: roomId,
			pageSize,
			pageIndex,
			dateFrom: startDate,
			dateTo: endDate,
		};

		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${getCompanyId()}/levels/${levelId}/hand-hygiene-audit`, {
			params,
			signal,
		});
		return data.result;
	} catch (error) {
		return { error };
	}
};

export const exportHandHygieneAudit = async ({ levelId, roomId, startDate, endDate }) => {
	try {
		const params = {
			teamChannelId: roomId,
			dateFrom: startDate,
			dateTo: endDate,
		};
		const { data } = await gatewayApi.get(`/v1/tasks/organizations/${getCompanyId()}/levels/${levelId}/hand-hygiene-audit`, {
			params,
		});
		return data;
	} catch (error) {
		return { error };
	}
};
