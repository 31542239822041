import React, { useRef, useEffect, useContext, useState } from 'react';
import CircularPercentage from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/GenericCharts/CircularPercentage.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SocketEvents from 'constants/socket-events.js';
import translate from 'i18n-translations/translate.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import Button from 'components/Button.jsx';

const EcgCanvas = props => {
	const socket = useContext(SocketContext);
	const canvasRef = useRef(null);
	const canvasParentRef = useRef(null);
	const intervalCount = useRef(0);
	const lastCoordinates = useRef({ x: 0, y: 0 });
	const [canvasDimensions, setCanvasDimensions] = useState({ width: 0, height: 0 });
	const [counter, setCounter] = useState(30);
	const [bpmData, setBpmData] = useState('');
	const [isMeasurementCompleted, setIsMeasurementCompleted] = useState(false);
	const countDownRef = useRef(null);

	const drawGrid = (ninthOfCanvasHeight = 0.04, lineWidth = 1) => {
		if (!canvasRef.current) {
			return;
		}
		const context = canvasRef.current.getContext('2d');
		context.beginPath();
		context.lineWidth = lineWidth;
		context.strokeStyle = 'white';
		const width = canvasParentRef.current.clientWidth;
		const height = canvasParentRef.current.clientHeight;
		const rectangularSize = height * ninthOfCanvasHeight;
		for (let i = 0; i < width; i += rectangularSize) {
			context.moveTo(i, height);
			context.lineTo(i, 0);
			context.moveTo(width, i);
			context.lineTo(0, i);
		}
		context.stroke();
	};

	const drawWave = list => {
		if (!canvasRef.current) {
			return;
		}
		const context = canvasRef.current.getContext('2d');
		context.beginPath();
		context.strokeStyle = 'red';
		const width = canvasParentRef.current.clientWidth;
		const height = canvasParentRef.current.clientHeight;
		const halfCanvasHeight = height / 2;
		if (lastCoordinates.current.x === 0) {
			context.moveTo(lastCoordinates.current.x, list[0] + halfCanvasHeight);
			context.lineTo(intervalCount.current, list[1] + halfCanvasHeight);
			context.stroke();
		}
		if (lastCoordinates.current.x !== 0) {
			context.moveTo(lastCoordinates.current.x, lastCoordinates.current.y);
			context.lineTo(intervalCount.current, list[intervalCount.current] + halfCanvasHeight);
			context.stroke();
		}
		for (let i = 0; i < list.length; i += 1) {
			const index = intervalCount.current + i;
			context.moveTo(index, list[i - 1] + halfCanvasHeight);
			context.lineTo(index, list[i] + halfCanvasHeight);
			if (i === list.length - 1) {
				lastCoordinates.current = { x: index, y: list[i - 1] + halfCanvasHeight };
			}
		}

		if (intervalCount.current > width) {
			context.clearRect(0, 0, width, height);
			drawGrid(0.2, 3);
			drawGrid();
			intervalCount.current = 10;
			lastCoordinates.current = { x: 0, y: 0 };
		}
		context.stroke();
	};

	useEffect(() => {
		drawGrid(0.2, 3);
		drawGrid();
	}, [canvasDimensions]);

	useEffect(() => {
		setCanvasDimensions({ width: canvasParentRef.current.clientWidth, height: canvasParentRef.current.clientHeight });

		const handleECGevent = ({ ecgData, heartRateData }) => {
			drawWave(ecgData);
			setBpmData(heartRateData);
			if (intervalCount.current === 0) {
				countDownRef.current = setInterval(() => {
					setCounter(prevState => {
						if (prevState - 1 === 0) {
							setIsMeasurementCompleted(true);
							clearInterval(countDownRef.current);
						}
						return prevState - 1 !== 0 ? prevState - 1 : 30;
					});
				}, 1000);
			}
			intervalCount.current += 10;
		};
		const updateCanvasDimensions = () => {
			if (!canvasParentRef.current) {
				return;
			}
			setCanvasDimensions({ width: canvasParentRef.current.clientWidth, height: canvasParentRef.current.clientHeight });
			drawGrid(0.2, 3);
			drawGrid();
		};

		setTimeout(() => {
			drawGrid(0.2, 3);
			drawGrid();
		}, 1000);

		const toastMessagesListener = () => {
			if (!canvasRef.current) {
				return;
			}
			const context = canvasRef.current.getContext('2d');
			const width = canvasParentRef.current.clientWidth;
			const height = canvasParentRef.current.clientHeight;
			context.clearRect(0, 0, width, height);
			intervalCount.current = 0;
			lastCoordinates.current = { x: 0, y: 0 };
			drawGrid(0.2, 3);
			drawGrid();
			setCounter(30);
			clearInterval(countDownRef.current);
		};

		socket.on(SocketEvents.Conference.SEND_MEASUREMENT_BUFFER_DATA, handleECGevent);
		socket.on(SocketEvents.HelloDevice.IOT_TOAST_MESSAGES, toastMessagesListener);

		window.addEventListener('resize', updateCanvasDimensions);
		return () => {
			if (canvasRef.current) {
				const context = canvasRef.current.getContext('2d');
				context.clearRect(0, 0, canvasParentRef.current.clientWidth, canvasParentRef.current.clientHeight);
			}
			if (countDownRef.current) {
				clearInterval(countDownRef.current);
			}
			socket.off(SocketEvents.Conference.SEND_MEASUREMENT_BUFFER_DATA, handleECGevent);
			socket.off(SocketEvents.HelloDevice.IOT_TOAST_MESSAGES, toastMessagesListener);
			window.removeEventListener('resize', updateCanvasDimensions);
		};
	}, []);

	const retakeMeasurement = () => {
		props.startMeasuringIoTDevice();
		setIsMeasurementCompleted(false);
	};

	useEffect(() => {
		if (canvasParentRef.current) {
			setCanvasDimensions({ width: canvasParentRef.current.clientWidth, height: canvasParentRef.current.clientHeight });
			drawGrid(0.2, 3);
			drawGrid();
		}
		if (isMeasurementCompleted) {
			props.startMeasuringIoTDevice();
		}
	}, [isMeasurementCompleted]);

	return (
		<>
			<div className='flex full-width flex-space-between ecg-counter-wrapper'>
				<div className='ecg-counter'>
					<CircularPercentage value={30 - counter} maximumValue={30} /> <span>{counter}s</span>
				</div>
				{bpmData && (
					<div>
						<p className='no-margin no-padding'>{translate('heartRate')}</p>
						<h3 className='no-margin no-padding'>
							{bpmData} <span>bpm</span>
						</h3>
					</div>
				)}
			</div>

			{isMeasurementCompleted && (
				<>
					<div className='ecg-end'>
						<p>{translate('ecgDoesNotShowSigns')}</p>
						<p>
							{translate('cannotCheckForSignsOfHeartAttack')}
							<span className='--red-color'> {translate('callEmergencyServices')}</span>
						</p>
					</div> 
					<Button
						onClick={retakeMeasurement}
						imgIcon={`${healthCareCdnUrl}live-examination/devices/ecg.svg?v2`}
						text={translate('initiateDevice', { value: translate('ECG') })}
					/>
				</>
			)}

			{!isMeasurementCompleted && (
				<div
					ref={canvasParentRef}
					style={{
						height: '100%',
						width: '99%',
						transform: 'scaleY(-1)',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-end',
					}}>
					<canvas
						className='overflow-hidden'
						height={`${canvasDimensions.height}px`}
						width={`${canvasDimensions.width}px`}
						ref={canvasRef}
					/>
				</div>
			)}
		</>
	);
};

export default EcgCanvas;
