import * as React from 'react';

const Rounding = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
		<path stroke='currentColor' strokeLinejoin='round' d='M16 10.5h3V13h-3m0 1.5h3V17h-3' />
		<path stroke='currentColor' d='M16 8h4a1 1 0 0 1 1 1v11h-5M3 5a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v15H3V5Z' />
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			d='M5 10.5h3.5V13H5v-2.5ZM10.5 10.5H14V13h-3.5v-2.5ZM5 14.5h3.5V17H5v-2.5ZM10.5 14.5H14V17h-3.5v-2.5Z'
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M8.146 6.564H9.5m0 0h1.354m-1.354 0v-1.37m0 1.37v1.371'
		/>
	</svg>
);
export default Rounding;
