import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { MeasurementTypes, MeasurementUnits, UnitCategoryTypes, VitalsAlertTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { useSelector } from 'react-redux';
import { MindRayCodes } from 'components/Monitoring/enums.js';

const vitalMeasurements = intl => [
	{
		id: 1,
		name: intl.formatMessage({ id: 'heartRate' }),
		value: '--',
		unit: MeasurementUnits.BPM,
		img: 'heart-rate',
		type: MeasurementTypes.HEART_RATE,
		shortName: 'HR',
	},
	{
		id: 2,
		name: intl.formatMessage({ id: 'oxygen' }),
		value: '--',
		unit: MeasurementUnits.PERCENTAGE,
		img: 'spo2',
		type: MeasurementTypes.OXYGEN,
		shortName: 'SPO2',
	},
	{
		id: 3,
		name: intl.formatMessage({ id: 'bloodPressure' }),
		value: '--/--',
		secondValue: 'BPDIA',
		unit: MeasurementUnits.MMHG,
		img: 'blood-pressure',
		type: MeasurementTypes.BLOOD_PRESSURE,
		shortName: 'BP',
	},
	{
		id: 4,
		name: intl.formatMessage({ id: 'respiration' }),
		value: '--',
		unit: intl.formatMessage({ id: MeasurementUnits.BREATHS_PER_MIN }),
		img: 'respiratory-rate',
		type: MeasurementTypes.RESPIRATORY_RATE,
	},
	{
		id: 5,
		name: intl.formatMessage({ id: 'bodyTemperature' }),
		value: '--',
		unit: MeasurementUnits.CELSIUS,
		img: 'temperature',
		type: MeasurementTypes.TEMPERATURE,
	},
];

const MindrayMeasurements = ({
	isPtzActive,
	shouldShowAIVitals,
	shouldShowAllItems,
	numberOfFeeds,
	notesShowing,
	hasConditions,
	deviceOwner,
	isHidden,
}) => {
	const intl = useIntl();

	const socket = useContext(SocketContext);

	const preferredUnits = useSelector(state => state.user.unitPreferences);
	const [measurements, setMeasurements] = useState({});
	const [alerts, setAlerts] = useState([]);

	const mapMeasurements = arr => {
		const result = {};
		let bloodPressure = null;
		arr.forEach(item => {
			if (+item.code === MindRayCodes.BLOOD_PRESSURE_SYSTOLIC) {
				bloodPressure = { ...item };
			}
			if (+item.code === MindRayCodes.BLOOD_PRESSURE_DIASTOLIC) {
				bloodPressure.value2 = item.value;
			}
			if (item.type !== MeasurementTypes.BLOOD_PRESSURE) {
				result[item.type] = item;
			}
		});
		if (bloodPressure) {
			result[MeasurementTypes.BLOOD_PRESSURE] = bloodPressure;
		}
		return result;
	};

	useEffect(() => {
		const measurementsListener = data => {
			if (deviceOwner.healthcareUserId !== data.patientId) {
				return;
			}
			setMeasurements(prevState => ({ ...prevState, ...mapMeasurements(data.vitals) }));
		};

		socket.on(SocketEvents.Mindray.MEASUREMENTS_ADDED, measurementsListener);
		return () => {
			socket.off(SocketEvents.Mindray.MEASUREMENTS_ADDED, measurementsListener);
		};
	}, [deviceOwner?.userId, socket]);

	const getCategoryPreference = categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const getMeasurementValue = item => {
		if (!measurements[item.type]) {
			return item.type === MeasurementTypes.BLOOD_PRESSURE ? '--/--' : '--';
		}
		if (measurements[item.type].value2) {
			return `${measurements[item.type].value}/${measurements[item.type].value2}`;
		}
		return measurements[item.type].value;
	};

	const getMeasurementUnit = item => {
		if (item.type === MeasurementTypes.TEMPERATURE) {
			return getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unit || item.unit;
		}
		return item.unit;
	};

	const getAlertClassName = alert => {
		let className = '';
		const alertType = alert[2];
		switch (alertType) {
			case VitalsAlertTypes.LOW:
				className = 'low-alert';
				break;
			case VitalsAlertTypes.MEDIUM:
				className = 'medium-alert';
				break;
			case VitalsAlertTypes.HIGH:
				className = 'high-alert';
				break;
			default:
				break;
		}
		return className;
	};

	const shouldShowItem = item => {
		let showItem = true;

		if (!shouldShowAllItems) {
			showItem = [MeasurementTypes.HEART_RATE, MeasurementTypes.OXYGEN, MeasurementTypes.BLOOD_PRESSURE].includes(item.type);
		}
		return showItem;
	};

	const getVitalSignsDetails = isRight => {
		return (
			<div
				className={classNames('vital-signs-ai', {
					right: isRight && shouldShowAllItems,
					'ptz-active': isPtzActive,
					hidden: isHidden,
					flex: !isHidden,
					'multiple-feeds': numberOfFeeds > 1,
					'ai-vital-signs-showing': shouldShowAIVitals,
					'has-conditions': hasConditions,
					'feed-notes-showing': notesShowing,
					'flex-wrap': !shouldShowAllItems,
				})}>
				{vitalMeasurements(intl).map(item => {
					const foundAlert = alerts.find(alert => alert[0] === item.value);
					return (
						shouldShowItem(item) && (
							<div
								className={classNames('flex vital-signs-details', foundAlert ? getAlertClassName(foundAlert) : '')}
								key={item.id}>
								<img src={`${healthCareCdnUrl}monitoring/biobeat/${item.img}.svg`} alt='icon' />
								<div className='flex column-direction left-5'>
									<span>{shouldShowAllItems ? item.name : item.shortName}</span>
									<div className='flex'>
										<span style={{ marginRight: '2px' }}>{getMeasurementValue(item)}</span>
										{getMeasurementValue(item) && <span>{getMeasurementUnit(item)}</span>}
									</div>
								</div>
							</div>
						)
					);
				})}
			</div>
		);
	};

	return (
		<>
			<div className={classNames('flex monitoring-vital-signs-ai-wrapper', !shouldShowAllItems ? 'row' : '')}>
				{getVitalSignsDetails(true)}
			</div>
		</>
	);
};

export default MindrayMeasurements;
