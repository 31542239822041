import { gatewayApi, gatewayFileUploadApi, localApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getHelloHealthCompanies = async ({ search = '', pageIndex = 0, pageSize = 10 }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/hello-health?pageIndex=${pageIndex}&pageSize=${pageSize}${search ? `&search=${search}` : ''}`,
			{
				search,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const createCompany = async ({ name, email, website, companySettings, users }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/organizations`, {
			name,
			email,
			website,
			companySettings,
			users,
		});
		return data;
	} catch (error) {
		return { error: error.response.data.message };
	}
};

export const activateCompany = async ({
	inviteId,
	healthSystem,
	region,
	code,
	email,
	firstName,
	lastName,
	password,
	companyId,
}) => {
	try {
		const { data } = await localApi.put(`/api/organizations/${companyId}/activate`, {
			inviteId: inviteId,
			healthSystem: healthSystem,
			region: region,
			companyActivation: {
				code: code,
				email: email,
			},
			user: {
				firstName: firstName,
				lastName: lastName,
				password: password,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteCompany = async companyId => {
	try {
		const { data } = await gatewayApi.put(`/v1/organizations/${companyId}/deactivate`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getOrganizationDetails = async (companyId = getCompanyId()) => {
	try {
		const { data } = await gatewayApi.get(`/v1/organizations/${companyId}/details`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateOrganizationSettings = async ({ companySettings, companyId = getCompanyId() }) => {
	try {
		const { data } = await gatewayApi.put(`/v1/organizations/${companyId}/settings`, {
			companySettings,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCompanyDetails = async companyId => {
	try {
		const { data } = await gatewayApi.get(`/v1/companies/${companyId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateCompanyDetails = async ({ name, website, address, profilePicture, companyId }) => {
	try {
		const { data } = await gatewayApi.put(`/v1/companies/${companyId}`, {
			name,
			website,
			address,
			profilePicture,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const checkIfOwnerEmailExists = async email => {
	try {
		const { data } = await gatewayApi.get(`v1/organizations/owners/${email}/exists`);
		return data;
	} catch (error) {
		return { error: error.response.data.message };
	}
};

export const uploadCompanyLogo = async formData => {
	try {
		const { data } = await gatewayFileUploadApi.post(`v1.1/media/pictures/organization-logo`, formData);
		return data;
	} catch (error) {
		return { error };
	}
};
