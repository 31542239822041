import * as React from 'react';

const SoundOnAiAlertIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
		<g stroke='currentColor'>
			<g strokeLinecap='round'>
				<path d='M9.458 5.5V2.167a1.25 1.25 0 0 0-1.943-1.04L5.503 2.469c-.274.183-.595.28-.924.28H2.167C1.247 2.75.5 3.495.5 4.416v4.166c0 .92.746 1.667 1.667 1.667h2.412c.329 0 .65.098.924.28l.234.156' />
				<path
					strokeLinejoin='round'
					d='M11.969 4.417s.262.262.483.736c.22.474.34.764.34.764m.791-3.167s1.5 1.406 1.5 3.75c0 .071-.001.142-.004.212'
				/>
			</g>
			<path d='M9.646 8.152a2 2 0 0 1 3.375 0l2.19 3.44c.847 1.332-.11 3.075-1.688 3.075h-4.38c-1.578 0-2.534-1.743-1.687-3.074l2.19-3.441Z' />
			<g strokeLinecap='round' strokeLinejoin='round'>
				<path d='M11.334 8.81v2.547M11.334 12.63v.128' />
			</g>
		</g>
	</svg>
);
export default SoundOnAiAlertIcon;
