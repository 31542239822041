import { SocketIOWrapper } from '@solaborate/calls';

class SocketWrapper extends SocketIOWrapper {
	/**
	 * @typedef {{ doConnect: () => Promise<void>, doDisconnect: () => void, authPromise: Promise<void> }} ExtendedSocket
	 * @param {ExtendedSocket & import('@solaborate/calls').types.SocketIO} socket
	 */
	constructor(socket) {
		super(socket);

		/** @type {() => Promise<void>} */
		this.doConnect = socket.doConnect;
		/** @type {() => void} */
		this.doDisconnect = socket.doDisconnect;
		/** @type {Promise<void>} */
		this.authPromise = socket.authPromise;
	}
}

export default SocketWrapper;
