const HelloFeatureBlock = props => (
	<div className={`hello-feature-block ${props.className ? props.className : ''}`} onClick={props.onClick}>
		{props.tooltipData && (
			<i className='material-icons-outlined tooltip-info' data-tooltip={props.tooltipData} data-position='top'>
				info
			</i>
		)}
		<div className='hello-feature-block-icon' />
		<p>{props.title}</p>
	</div>
);

export default HelloFeatureBlock;
