const ECG = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} viewBox='0 0 24 24' fill='none' {...props}>
		<path
			d='M12.5713 19.2277C-2.84575 10.7234 7.94615 1.43882 12.571 7.27659C17.1966 1.43882 27.9889 10.7234 12.5713 19.2277Z'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinejoin='round'
		/>
		<path
			d='M3.57141 12.7546C3.1572 12.7546 2.82141 13.0904 2.82141 13.5046C2.82141 13.9189 3.1572 14.2546 3.57141 14.2546V12.7546ZM8.57141 13.5046V14.2546C8.82218 14.2546 9.05635 14.1293 9.19545 13.9207L8.57141 13.5046ZM9.57141 12.0046L10.2737 11.7413C10.1748 11.4778 9.93679 11.2917 9.65719 11.2596C9.37758 11.2274 9.10349 11.3544 8.94737 11.5886L9.57141 12.0046ZM11.0714 16.0046L10.3692 16.268C10.4853 16.5778 10.7907 16.7748 11.1209 16.753C11.451 16.7312 11.7278 16.4956 11.8022 16.1733L11.0714 16.0046ZM12.5714 9.50464L13.2898 9.28913C13.192 8.96328 12.8872 8.74403 12.5472 8.75503C12.2071 8.76603 11.9171 9.00451 11.8406 9.33599L12.5714 9.50464ZM14.0714 14.5046L13.353 14.7201C13.4426 15.0188 13.7077 15.2309 14.0187 15.2528C14.3298 15.2747 14.6219 15.1018 14.7525 14.8187L14.0714 14.5046ZM15.5247 11.3525L16.1581 10.9508C16.011 10.7189 15.7489 10.5859 15.4749 10.6041C15.2008 10.6224 14.9586 10.789 14.8436 11.0384L15.5247 11.3525ZM16.5723 13.0046L15.9389 13.4063C16.0765 13.6232 16.3155 13.7546 16.5723 13.7546V13.0046ZM21.5723 13.7546C21.9866 13.7546 22.3223 13.4189 22.3223 13.0046C22.3223 12.5904 21.9866 12.2546 21.5723 12.2546V13.7546ZM3.57141 14.2546H8.57141V12.7546H3.57141V14.2546ZM9.19545 13.9207L10.1954 12.4207L8.94737 11.5886L7.94737 13.0886L9.19545 13.9207ZM8.86916 12.268L10.3692 16.268L11.7737 15.7413L10.2737 11.7413L8.86916 12.268ZM11.8022 16.1733L13.3022 9.67328L11.8406 9.33599L10.3406 15.836L11.8022 16.1733ZM11.853 9.72015L13.353 14.7201L14.7898 14.2891L13.2898 9.28913L11.853 9.72015ZM14.7525 14.8187L16.2058 11.6665L14.8436 11.0384L13.3903 14.1906L14.7525 14.8187ZM14.8913 11.7541L15.9389 13.4063L17.2057 12.603L16.1581 10.9508L14.8913 11.7541ZM16.5723 13.7546H21.5723V12.2546H16.5723V13.7546Z'
			fill='currentColor'
		/>
	</svg>
);
export default ECG;
