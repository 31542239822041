const PressureInjuryStatsIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
		<path
			d='M1.33325 7.99967C1.33325 11.6816 4.31802 14.6663 7.99992 14.6663C11.6818 14.6663 14.6666 11.6816 14.6666 7.99967C14.6666 4.31778 11.6818 1.33301 7.99992 1.33301'
			stroke='currentColor'
			strokeOpacity='0.5'
			strokeLinecap='round'
		/>
		<path
			d='M8.0493 4C10.2312 4 11.9999 5.79086 11.9999 8C11.9999 10.2091 10.2312 12 8.0493 12C6.99724 12 6.04123 11.5836 5.33325 10.9048'
			stroke='currentColor'
			strokeOpacity='0.5'
			strokeLinecap='round'
		/>
		<circle cx='7.99984' cy='7.99935' r='1.33333' stroke='currentColor' strokeOpacity='0.5' strokeLinecap='round' />
	</svg>
);

export default PressureInjuryStatsIcon;
