import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ReactSelect from 'react-select';
import { useSelector } from 'react-redux';
import Modal from 'components/Modal.jsx';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import { disableAiAlerts } from 'api/monitoringAiAlerts.js';
import { generateCustomStyles } from 'constants/react-select-style.js';

const DisableAiModal = props => {
	const intl = useIntl();
	const [error, setError] = useState(null);
	const [selectedValueDisableVisits, setSelectedValueDisableVisits] = useState({
		value: 5,
		label: `5 ${intl.formatMessage({ id: 'minutes' })}`,
	});

	const user = useSelector(state => state.user);

	const handleSubmit = async () => {
		const response = await disableAiAlerts(props.roomId, selectedValueDisableVisits.value, props.disabledAiControls);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		props.setDisabledVisitTime({
			isDisabled: true,
			disableTime: selectedValueDisableVisits.value,
			createdAt: new Date(),
		});
		props.emitDisableAiEvent();
		props.setIsDisableVisitsOpen(false);
	};

	return (
		<Modal
			className='wrapper-modal border-radius-modal-wrapper disable-modal'
			display={true}
			position='center'
			onModalSubmit={handleSubmit}
			onModalClose={() => props.setIsDisableVisitsOpen(false)}>
			<form>
				<h3>{translate('disableAi')}</h3>
				<p>{translate('areYouSureDisableAi')}</p>
				<div>
					<label htmlFor='selectTime'>*{translate('selectTime')}</label>
					<ReactSelect
						options={[
							{ value: 5, label: `5 ${intl.formatMessage({ id: 'minutes' })}` },
							{ value: 15, label: `15 ${intl.formatMessage({ id: 'minutes' })}` },
							{ value: 30, label: `30 ${intl.formatMessage({ id: 'minutes' })}` },
						]}
						value={selectedValueDisableVisits}
						onChange={event => setSelectedValueDisableVisits(event)}
						placeholder={intl.formatMessage({ id: 'selectMemberToInvite' })}
						classNamePrefix='react-select'
						className='react-select top-15'
						styles={generateCustomStyles({ darkMode: user.darkMode })}
					/>
				</div>
			</form>
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
		</Modal>
	);
};

export default DisableAiModal;
