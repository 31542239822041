import React, { useState } from 'react';
import { primaryCareImageUrl } from 'constants/global-variables.js';

const PatientHistoryAccordion = props => {
	const [showContent, setShowContent] = useState(false);
	const handleOnClick = () => {
		setShowContent(prevContentState => !prevContentState);
		if (props.isDoctorVisit) {
			props.onMedicalVisitClick();
		}
	};
	return (
		<>
			<div className='icon-wrapper  cursor-pointer' onClick={() => handleOnClick()}>
				<i className='material-icons'>{showContent ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</i>
				<div className='icon'>
					<img src={`${primaryCareImageUrl}icons/${props.image}`} alt={props.alt} />
				</div>
			</div>
			<div>
				<p>{props.title}</p>
				<p>{props.name}</p>
			</div>
			{showContent && props.content}
		</>
	);
};

export default PatientHistoryAccordion;
