import { useEffect, useState } from 'react';
import { participant as Participant } from '@solaborate/calls';
import _ from 'lodash';

const useHelloDeviceState = helloParticipant => {
	const [deviceState, setDeviceState] = useState(_.clone(helloParticipant.deviceState));

	useEffect(() => {
		setDeviceState(_.clone(helloParticipant.deviceState));

		const onEvent = event => {
			if (event instanceof Participant.HelloDeviceStateChanged) {
				setDeviceState(_.clone(helloParticipant.deviceState));
			}
		};

		helloParticipant.on(onEvent);

		return () => {
			helloParticipant.off(onEvent);
		};
	}, [helloParticipant]);

	return deviceState;
};

export default useHelloDeviceState;
