import MultiSelectCheckboxes from 'components/MultiSelectCheckboxes.jsx';
import { CallStatus } from 'constants/enums.js';
import Filter from 'icons/Dashboard/Filter.jsx';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

const FilterTypes = {
	duration: 0,
	callStatus: 1,
};

const initialFilters = [
	{
		id: FilterTypes.duration,
		checked: false,
		value: 10,
	},
	{
		id: FilterTypes.callStatus,
		checked: false,
		value: null,
	},
];

const DurationExcludedStatuses = [CallStatus.ABORTED, CallStatus.FAILED, CallStatus.MISSED];

const CallStatuses = Object.values(CallStatus).filter(status => status.type !== CallStatus.UNDEFINED.type);

const AdditionalFilters = props => {
	const intl = useIntl();
	const containerRef = useRef(null);
	const [showFiltersDropdown, setShowFiltersDropdown] = useState(false);
	const [filters, setFilters] = useState(initialFilters);
	const [error, setError] = useState('');

	const validateFilters = () => {
		let filtersValid = true;
		filters.forEach(filter => {
			if (!filter.checked) {
				return;
			}
			let validFilter = true;
			if (filter.id === FilterTypes.duration && !(filter.value > 0 && filter.value <= 60)) {
				setError(intl.formatMessage({ id: 'excludeDurationValidation' }));
				validFilter = false;
			}
			// @ts-ignore
			if (filter.id === FilterTypes.callStatus && filter.value?.length >= CallStatuses.length) {
				setError(intl.formatMessage({ id: 'excludeStatusValidation' }));
				validFilter = false;
			}
			filtersValid = filtersValid && validFilter;
		});
		return filtersValid;
	};

	useEffect(() => {
		if (props.defaultFilters) {
			setFilters(prevState =>
				prevState.map(filter => ({
					...filter,
					checked:
						(filter.id === FilterTypes.duration && props.defaultFilters.excludeUnder) ||
						(filter.id === FilterTypes.callStatus && props.defaultFilters.excludeStatus),
					value:
						filter.id === FilterTypes.duration
							? props.defaultFilters.excludeUnder || filter.value
							: props.defaultFilters.excludeStatus?.map(status => ({
									value: status,
									label: getCallStatusOptions().find(opt => opt.value === status).label,
							  })) ?? filter.value,
				}))
			);
		}
	}, [props.defaultFilters]);

	useEffect(() => {
		if (!validateFilters()) {
			return;
		}
		setError('');
		const appliedFilters = filters.reduce((acc, curr) => {
			if (!curr.checked) {
				return acc;
			}
			if (curr.id === FilterTypes.duration) {
				acc['excludeUnder'] = curr.value;
			} else if (curr.id === FilterTypes.callStatus) {
				// @ts-ignore
				acc['excludeStatus'] = curr.value?.map(val => val.value);
			}
			return acc;
		}, {});
		props.setAdditionalFilters(appliedFilters);
	}, [filters]);

	const toggleDurationFilter = event =>
		// @ts-ignore
		setFilters(prevState =>
			prevState.map(af => {
				if (af.id === FilterTypes.duration) {
					return { ...af, checked: !af.checked };
				}
				return event.target.checked
					? {
							...af,
							checked: true,
							value: DurationExcludedStatuses.reduce((/** @type array */ acc, curr) => {
								if (!acc.find(item => item.value === curr.type)) {
									acc.push({
										value: curr.type,
										label: intl.formatMessage({ id: stringToCamelCase(curr.message) }),
									});
								}
								return acc;
							}, af.value || []),
					  }
					: af;
			})
		);

	const handleChangedValue = (filter, value) => {
		setFilters(prevState =>
			prevState.map(af =>
				af.id === filter ? { ...af, value, ...(af.id === FilterTypes.callStatus && { checked: value?.length > 0 }) } : af
			)
		);
	};

	const getCallStatusOptions = useCallback(
		() =>
			CallStatuses.map(item => ({
				value: item.type,
				label: (
					<>
						<span className='custom-label'>{intl.formatMessage({ id: stringToCamelCase(item.message) })}</span>
						<i
							className='material-icons-outlined'
							data-tooltip={intl.formatMessage({ id: `${stringToCamelCase(item.message)}Description` })}
							data-position='left'>
							info
						</i>
					</>
				),
				isDisabled: filters[FilterTypes.duration].checked && DurationExcludedStatuses.includes(item),
			})),
		[filters[FilterTypes.duration].checked]
	);

	const handleClearAll = () =>
		setFilters(prevState =>
			prevState.map(filter => ({ ...filter, checked: false, value: filter.id === FilterTypes.duration ? 10 : null }))
		);

	useOutsideClick(containerRef, () => setShowFiltersDropdown(false));

	return (
		<div ref={containerRef} className='position-relative' onClick={e => e.preventDefault()}>
			<button type='button' onClick={() => setShowFiltersDropdown(prevState => !prevState)}>
				<Filter />
			</button>
			<div className='dashboard-additional-filters' hidden={!showFiltersDropdown}>
				<h5>
					{intl.formatMessage({ id: 'excludeCalls' })}{' '}
					<span onClick={handleClearAll}>{intl.formatMessage({ id: 'clearAll' })}</span>
				</h5>
				<div>
					<input type='checkbox' checked={filters[FilterTypes.duration].checked} onChange={toggleDurationFilter} />
					<span>{intl.formatMessage({ id: 'under' })}</span>
					<input
						type='number'
						min={1}
						max={60}
						value={filters[FilterTypes.duration].value}
						onChange={e => handleChangedValue(FilterTypes.duration, e.target.value)}
						disabled={!filters[FilterTypes.duration].checked}
					/>
					<span>{intl.formatMessage({ id: 'seconds' })}</span>
					<i
						className='material-icons-outlined'
						data-tooltip={intl.formatMessage({ id: 'excludeDurationInfo' })}
						data-position='left'>
						info
					</i>
				</div>
				<div>
					<span>{intl.formatMessage({ id: 'withStatus' })}</span>
					<MultiSelectCheckboxes
						name='call-statuses'
						selectedOptions={filters[FilterTypes.callStatus].value}
						options={getCallStatusOptions}
						isClearable={!filters[FilterTypes.duration].checked}
						onChange={status => handleChangedValue(FilterTypes.callStatus, status)}
					/>
				</div>
				{error && <span className='red-error'>{error}</span>}
			</div>
			{filters.some(filter => filter.checked) && <span className='active-filters-badge'></span>}
		</div>
	);
};

export default AdditionalFilters;
