import React, { useState, useRef, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Mic, Cam, ScreenAudio, ScreenShare } from '@solaborate/calls/webrtc';
import { participant as CallsParticipant } from '@solaborate/calls';
import LocalParticipant from 'calls/LocalParticipant.js';
import { ClosingOwnerViews } from 'calls/constants/index.js';
import {
	Participant,
	ParticipantAudio,
	ParticipantVideo,
	ParticipantAudioLevel,
	StyledParticipantInfo,
	Loader,
	Dropdown,
	List,
	Tooltip,
	Icon,
	IconButton,
	Avatar,
} from 'calls/components/index.js';
import {
	useConference,
	useConferenceConfigurations,
	useRemoteParticipantState,
	useRemoteParticipant,
	useControllerTracks,
	useHelloDeviceState,
	useLocalParticipant,
} from 'calls/hooks/index.js';
import { attachSinkId, getSavedStreamSettings, getCallsButtonBackground } from 'calls/helpers/index.js';
import translate from 'i18n-translations/translate.jsx';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import {
	VideocamIcon,
	VideocamOffIcon,
	VolumeUpIcon,
	VolumeOffIcon,
	PinIcon,
	UnPinIcon,
	LiveCaptionsIcon,
	LiveCaptionsDisabledIcon,
} from 'calls/icons/index.js';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import { buildProfilePic } from 'infrastructure/helpers/thumbnailHelper.js';
import { getUserRole } from 'infrastructure/auth.js';
import { ControlsActions, UserRoles, UserTypes, WindowSize } from 'calls/enums/index.js';
import {
	getCallsButtonColor,
	getRoleConfigurationValue,
	getSomeRoleConfigurationsValues,
} from 'infrastructure/helpers/commonHelpers.js';
import { RoundingSettings } from 'constants/configurationEnums.js';
import TransferOwnershipIcon from 'calls/icons/TransferOwnership.jsx';
import { v4 as uuidv4 } from 'uuid';
import SocketEvents from 'constants/socket-events.js';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';
import { ObjectType } from 'constants/enums.js';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isGridView: boolean }, never>}
 */
const StyledParticipantState = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		margin: 0;
		color: ${LightTheme.colors.grayFive};
		font-size: 12px;

		@media (max-width: ${WindowSize.TABLET}px) {
			color: ${props => (props.$isGridView ? LightTheme.colors.grayZero : '')};
		}
	}
`;

const StyledParticipantActions = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: 0.2s;
	z-index: 3;

	&:hover {
		opacity: 1;
	}

	> div {
		margin-right: ${LightTheme.spacing[2]}px;

		&:last-of-type {
			margin: 0;
		}

		@media (max-width: ${WindowSize.TABLET}px) {
			margin-right: ${LightTheme.spacing[1]}px;

			> button {
				padding: ${LightTheme.spacing[2]}px;
			}
		}
	}
`;

const RemoveParticipantAction = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	padding: 10px;
	z-index: 3;

	> div {
		> button {
			padding: ${LightTheme.spacing[2]}px;
		}
	}
`;

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $position: string, $isDarkMode: boolean }, never>}
 */
const LimitedVisitorSideView = styled.div`
	position: absolute;
	z-index: 1;
	top: auto;
	bottom: 0;
	height: 100%;
	width: 20%;
	left: ${props => (props.$position === 'left' ? '0' : 'auto')};
	right: ${props => (props.$position === 'right' ? '0' : 'auto')};
	background: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFour : LightTheme.colors.grayTen)};
`;

const ParticipantInfo = ({ isScreenShare = false }) => {
	const participant = useRemoteParticipant();
	const connectionState = useRemoteParticipantState();
	const tracks = useControllerTracks(participant.remoteTrackController);
	const deviceState = useHelloDeviceState(participant);

	return (
		<StyledParticipantInfo>
			{connectionState instanceof CallsParticipant.StateConnected && (
				<>
					{(!tracks[Mic] || deviceState?.isMicPrivacyOn) && !isScreenShare && (
						<IconButton icon='mic_off' background={LightTheme.colors.redOne} color={LightTheme.colors.grayZero} />
					)}
					{tracks[Mic] && !deviceState?.isMicPrivacyOn && (
						// This check will avoid a web crash when an offer is sent incorrectly
						// on iOS side. This will be fixed when calls functionality will be implemented
						// correctly on iOS. This check should be removed in the future when that happens
						<ParticipantAudioLevel track={tracks[Mic].track.kind === 'audio' ? tracks[Mic].track : null} />
					)}
					{/* This should be with a feature flag */}
					{/* {!isMobile && (
						<Dropdown isOpen={showStats} onClickOutside={() => setShowStats(false)}>
							<Dropdown.Button
								icon={<PeerConnectionStatsIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />}
								onMouseOver={e => {
									e.stopPropagation();
									setShowStats(true);
								}}
								onMouseOut={e => {
									e.stopPropagation();
									setShowStats(false);
								}}
							/>
							<Dropdown.Items renderIntoPortal={true}>
								<PeerConnectionStats participant={participant} isScreenShare={isScreenShare} />
							</Dropdown.Items>
						</Dropdown>
					)} */}
				</>
			)}
		</StyledParticipantInfo>
	);
};

/**
 * @param {object} props
 * @param {{[key: string]: import('@solaborate/calls/webrtc').Track}} props.tracks
 */
const ParticipantScreen = ({ tracks }) => {
	return (
		<>
			<ParticipantAudio track={tracks[ScreenAudio]} />
			<ParticipantVideo track={tracks[ScreenShare]} />
		</>
	);
};

/**
 * @param {object} props
 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.mainParticipant Local or remote participant
 * @param {import('@solaborate/calls/webrtc').TrackType} props.activeTrackType
 * @param {(trackType: import('@solaborate/calls/webrtc').TrackType) => void} [props.onClick=null]
 */
const RemoteParticipantView = ({ mainParticipant, activeTrackType, onClick = null }) => {
	const conference = useConference();
	const conferenceConfigs = useConferenceConfigurations();
	const intl = useIntl();
	const localParticipant = useLocalParticipant();
	const participant = useRemoteParticipant();
	const isHelloParticipant = participant instanceof RemoteHelloParticipant;
	const isWebParticipant = !isHelloParticipant && participant.objectType !== ObjectType.SIP_USER;
	const socketFunctions = useContext(SocketFunctionsContext);
	const connectionState = useRemoteParticipantState();
	const tracks = useControllerTracks(participant.remoteTrackController);
	const isCurrentlyPinnedParticipant = conferenceConfigs.pinnedParticipantId === participant.id;
	const [isLiveCaptionsEnabled, setIsLiveCaptionsEnabled] = useState(isHelloParticipant ? participant.isCCEnabled : false);
	const isStateConnected = connectionState instanceof CallsParticipant.StateConnected;
	const { roundingConfigurations } = conferenceConfigs;

	const audioRef = useRef(null);

	const [participantDisplayedName] = useState(participant.alias || participant.name);

	const [showControls, setShowControls] = useState({
		mainBox: false,
		screenShare: false,
	});

	const toggleDropdown = dropdownKey => {
		setShowControls(prevState => ({
			...prevState,
			[dropdownKey]: !prevState[dropdownKey],
		}));
	};

	const showLiveCaptionsButton = () =>
		isHelloParticipant &&
		getSomeRoleConfigurationsValues(conferenceConfigs.roundingConfigurations, [RoundingSettings.LiveCaptions]);

	const onRemoveParticipant = participantId => {
		if (participant instanceof LocalParticipant || !localParticipant.isOwner) {
			return;
		}
		conferenceConfigs.setRemoveParticipantModal({
			isOpen: true,
			modalMessage: `${intl.formatMessage({ id: 'confirmationOfRemovalParticipant' }, { value: participantDisplayedName })}`,
			onSubmit: () => {
				if (!localParticipant.isOwner) {
					return;
				}
				conference.removeParticipant(participantId);
				conferenceConfigs.setRemoveParticipantModal({
					isOpen: false,
					message: '',
					onSubmit: () => {},
				});
			},
		});
	};

	const onToggleCC = isEnabled => {
		if (isHelloParticipant) {
			participant.toggleCC(isEnabled);
		}
	};

	useEffect(() => {
		const setAudioTrackSinkId = async () => {
			const audioTrack = tracks[Mic];
			const streamSettings = getSavedStreamSettings();
			if (audioTrack && streamSettings?.audiooutput) {
				await attachSinkId(audioRef.current, streamSettings.audiooutput);
			}
		};
		setAudioTrackSinkId();
	}, [tracks, conferenceConfigs.sinkId]);

	useEffect(() => {
		return participant.on(event => {
			if (event instanceof CallsParticipant.CCStateChanged) {
				setIsLiveCaptionsEnabled(event.isCCEnabled);
			}
		});
	}, [participant]);

	const toggleTrack = trackType => {
		participant.remoteTrackController.toggle(trackType);
		setShowControls({ mainBox: false, screenShare: false });
	};

	const onSetMainParticipant = (trackType = Cam, fromTogglePin = false) => {
		if (isConnecting()) {
			return;
		}

		if (!fromTogglePin && conferenceConfigs.pinnedParticipantId) {
			return;
		}

		if (trackType) {
			onClick(trackType);
			return;
		}

		onClick(!tracks[Cam] && tracks[ScreenShare] ? ScreenShare : Cam);
	};

	const togglePinnedParticipant = trackType => {
		conferenceConfigs.setPinnedParticipantId(isCurrentlyPinnedParticipant ? '' : participant.id);

		if (participant?.role === UserTypes.GUEST) {
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_LIVE_EXAMINATIONS, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PATIENT_HISTORY, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_DIAGNOSES, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_NOTES, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PROCEDURES, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PRESCRIPTIONS, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PHYSICAL_EXERCISES, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_WHITEBOARD, false);
		}

		if (!isCurrentlyPinnedParticipant) {
			onSetMainParticipant(trackType, true);
		}
	};

	const makeHost = async participantId => {
		const response = await conference.transferOwnership(participantId);
		if (!response) {
			conferenceConfigs.setConferenceErrorMessages([{ id: uuidv4(), message: translate('somethingWentWrong') }]);
			return;
		}
		localParticipant.isGuest = true;
		conferenceConfigs.onConfigurationToggleAction(ControlsActions.SET_SHOW_PATIENT_MEASUREMENTS_BUTTONS, false);
		ClosingOwnerViews.forEach(({ action, value }) => {
			conferenceConfigs.onConfigurationToggleAction(action, value ?? null);
			if (action === ControlsActions.TOGGLE_LIVE_EXAMINATIONS) {
				socketFunctions.toggleHealthData({
					isEnabled: false,
					helloDeviceId: mainParticipant.objectId,
					conferenceId: conference.conferenceId,
					participantId: localParticipant.id,
					toolType: SocketEvents.HelloDevice.TOOLTYPE_LIVE_EXAMINATION,
					measurementType: '',
				});
			}
		});
	};

	useEffect(() => {
		const setAudioTrackSinkId = async () => {
			const audioTrack = tracks[Mic];
			const streamSettings = getSavedStreamSettings();
			if (audioTrack && streamSettings?.audiooutput) {
				await attachSinkId(audioRef.current, streamSettings.audiooutput);
			}
		};
		setAudioTrackSinkId();
	}, [tracks, conferenceConfigs.sinkId]);

	const shouldShowCamFeed = conferenceConfigs.isGridView || activeTrackType !== Cam || mainParticipant.id !== participant.id;

	const shouldShowScreenFeed =
		tracks[ScreenShare] &&
		(conferenceConfigs.isGridView || activeTrackType !== ScreenShare || mainParticipant.id !== participant.id);

	const isConnecting = () => connectionState instanceof CallsParticipant.StateConnecting && !tracks[Cam] && !tracks[ScreenShare];

	const showMuteThirdPartyParticipant = () =>
		getRoleConfigurationValue(roundingConfigurations, RoundingSettings.ThirdPartyMembersMic) && tracks[Mic] && isWebParticipant;

	return (
		<>
			{(participant.isCompanion || !shouldShowCamFeed) && <ParticipantAudio track={tracks[Mic]} />}
			{!participant.isCompanion && (
				<>
					{shouldShowCamFeed && (
						<Participant
							style={{
								opacity: !isStateConnected ? 0.75 : 1,
								pointerEvents: !isStateConnected && !participant.isSip ? 'none' : 'unset',
							}}
							onClick={() => {
								onSetMainParticipant();
							}}>
							<Participant.Info>
								<p>
									<ParticipantInfo />
									<span>
										{participantDisplayedName} {participant.interpreterId ? `(${intl.formatMessage({ id: 'interpreter' })})` : ''}
									</span>
								</p>
							</Participant.Info>
							<Participant.Main className={!tracks[Cam] && !tracks[ScreenShare] ? 'mainAvatar' : ''}>
								<ParticipantAudio ref={audioRef} track={tracks[Mic]} />
								{isStateConnected && (
									<>
										{tracks[Cam] && <ParticipantVideo track={tracks[Cam]} />}
										{!tracks[Cam] && (
											<Avatar
												size={conferenceConfigs.isGridView ? 60 : 48}
												src={participant.picture?.includes('user') ? null : buildProfilePic(participant.picture)}
												name={participantDisplayedName}
											/>
										)}
									</>
								)}

								{connectionState instanceof CallsParticipant.StateConnecting && !tracks[Cam] && !tracks[ScreenShare] && (
									<StyledParticipantState $isGridView={conferenceConfigs.isGridView}>
										{localParticipant.isOwner && participant.isSip && (
											<RemoveParticipantAction>
												<Tooltip
													text={intl.formatMessage({
														id: 'removeParticipant',
													})}
													position='bottom-left'>
													<IconButton
														background={getCallsButtonBackground(conferenceConfigs.isDarkMode)}
														color={getCallsButtonColor(conferenceConfigs.isDarkMode)}
														size={18}
														onClick={() => onRemoveParticipant(participant.id)}>
														<Icon name='close' size={14} />
													</IconButton>
												</Tooltip>
											</RemoveParticipantAction>
										)}
										<Loader $color={LightTheme.colors.blueTwo} />
										<p>Connecting</p>
									</StyledParticipantState>
								)}
								{conferenceConfigs.isGridView && (
									<StyledParticipantActions>
										{isHelloParticipant && !localParticipant.isGuest && (
											<>
												<Tooltip text={intl.formatMessage({ id: tracks[Cam] ? 'cameraOff' : 'cameraOn' })}>
													<IconButton
														background={getCallsButtonBackground(conferenceConfigs.isDarkMode)}
														color={getCallsButtonColor(conferenceConfigs.isDarkMode)}
														size={18}
														onClick={evt => {
															evt.stopPropagation();
															toggleTrack(Cam);
														}}>
														{tracks[Cam] && (
															<VideocamIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
														)}
														{!tracks[Cam] && (
															<VideocamOffIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
														)}
													</IconButton>
												</Tooltip>
												<Tooltip text={intl.formatMessage({ id: tracks[Mic] ? 'muteAudio' : 'unMuteAudio' })}>
													<IconButton
														background={getCallsButtonBackground(conferenceConfigs.isDarkMode)}
														color={getCallsButtonColor(conferenceConfigs.isDarkMode)}
														size={18}
														onClick={evt => {
															evt.stopPropagation();
															toggleTrack(Mic);
														}}>
														{tracks[Mic] && (
															<VolumeUpIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
														)}
														{!tracks[Mic] && (
															<VolumeOffIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
														)}
													</IconButton>
												</Tooltip>
											</>
										)}
										{localParticipant.isOwner && (
											<RemoveParticipantAction>
												<Tooltip
													text={intl.formatMessage({
														id: 'removeParticipant',
													})}
													position='bottom-left'>
													<IconButton
														background={getCallsButtonBackground(conferenceConfigs.isDarkMode)}
														color={getCallsButtonColor(conferenceConfigs.isDarkMode)}
														size={18}
														onClick={() => onRemoveParticipant(participant.id)}>
														<Icon name='close' size={14} />
													</IconButton>
												</Tooltip>
											</RemoveParticipantAction>
										)}
									</StyledParticipantActions>
								)}

								{isHelloParticipant && conferenceConfigs.isMultiBed && getUserRole() === UserRoles.VISITOR && (
									<>
										<LimitedVisitorSideView $position='left' $isDarkMode={conferenceConfigs.isDarkMode}></LimitedVisitorSideView>
										<LimitedVisitorSideView $position='right' $isDarkMode={conferenceConfigs.isDarkMode}></LimitedVisitorSideView>
									</>
								)}
							</Participant.Main>
							{!conferenceConfigs.isGridView && !isConnecting() && (
								<Participant.Controls>
									<Dropdown
										isOpen={showControls.mainBox}
										onClickOutside={() => setShowControls({ mainBox: false, screenShare: false })}>
										<Dropdown.Button onClick={() => toggleDropdown('mainBox')} />
										<Dropdown.Items renderIntoPortal={true}>
											<List>
												{!localParticipant.isGuest && (
													<>
														{isHelloParticipant && (
															<>
																<List.Item onClick={() => toggleTrack(Cam)}>
																	{tracks[Cam] ? (
																		<VideocamIcon width={18} height={18} />
																	) : (
																		<VideocamOffIcon width={18} height={18} />
																	)}
																	{translate(tracks[Cam] ? 'cameraOff' : 'cameraOn')}
																</List.Item>
																<List.Item onClick={() => toggleTrack(Mic)}>
																	{tracks[Mic] ? (
																		<VolumeUpIcon width={18} height={18} />
																	) : (
																		<VolumeOffIcon width={18} height={18} />
																	)}
																	{translate(tracks[Mic] ? 'muteAudio' : 'unMuteAudio')}
																</List.Item>
															</>
														)}
														{showMuteThirdPartyParticipant() && (
															<List.Item onClick={() => toggleTrack(Mic)}>
																<VolumeUpIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={16} height={16} />
																{translate('muteAudio')}
															</List.Item>
														)}
													</>
												)}
												<List.Item
													onClick={() => {
														togglePinnedParticipant();
														setShowControls({ mainBox: false, screenShare: false });
													}}>
													{isCurrentlyPinnedParticipant && (
														<UnPinIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
													)}
													{!isCurrentlyPinnedParticipant && (
														<PinIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
													)}
													{translate(isCurrentlyPinnedParticipant ? 'unpinFeed' : 'pinFeed')}
												</List.Item>
												{localParticipant.isOwner && (
													<List.Item onClick={() => onRemoveParticipant(participant.id)}>
														<Icon name='close' size={18} />
														{translate('removeParticipant')}
													</List.Item>
												)}
												{showLiveCaptionsButton() && (
													<List.Item
														onClick={() => {
															onToggleCC(!isLiveCaptionsEnabled);
															setShowControls({ mainBox: false, screenShare: false });
														}}>
														{isLiveCaptionsEnabled && (
															<LiveCaptionsIcon
																height={18}
																width={18}
																color={getCallsButtonColor(conferenceConfigs.isDarkMode)}
															/>
														)}
														{!isLiveCaptionsEnabled && (
															<LiveCaptionsDisabledIcon
																height={18}
																width={18}
																color={getCallsButtonColor(conferenceConfigs.isDarkMode)}
															/>
														)}
														{translate(isLiveCaptionsEnabled ? 'disableLiveCaptions' : 'enableLiveCaptions')}
													</List.Item>
												)}
												{!isHelloParticipant &&
													[UserTypes.DOCTOR, UserTypes.NURSE].includes(participant.role) &&
													localParticipant.isOwner && (
														<List.Item
															onClick={() => {
																makeHost(participant.id);
																setShowControls({ mainBox: false, screenShare: false });
															}}>
															<TransferOwnershipIcon
																height={18}
																width={18}
																color={getCallsButtonColor(conferenceConfigs.isDarkMode)}
															/>
															{translate('makeHost')}
														</List.Item>
													)}
											</List>
										</Dropdown.Items>
									</Dropdown>
								</Participant.Controls>
							)}
						</Participant>
					)}
					{shouldShowScreenFeed && (
						<Participant
							onClick={() => {
								onSetMainParticipant(ScreenShare);
							}}>
							<Participant.Info>
								<p>
									{participantDisplayedName} ({translate('presenting')})
								</p>
							</Participant.Info>
							<Participant.Main>
								<ParticipantScreen tracks={tracks} />
								<ParticipantInfo isScreenShare />
							</Participant.Main>
							{!conferenceConfigs.isGridView && (
								<Participant.Controls>
									<Dropdown
										isOpen={showControls.screenShare}
										onClickOutside={() => setShowControls({ mainBox: false, screenShare: false })}>
										<Dropdown.Button onClick={() => toggleDropdown('screenShare')} />
										<Dropdown.Items renderIntoPortal={true}>
											<List>
												{isHelloParticipant && !localParticipant.isGuest && (
													<>
														<List.Item onClick={() => toggleTrack(Cam)}>
															{tracks[Cam] ? <VideocamIcon width={18} height={18} /> : <VideocamOffIcon width={18} height={18} />}
															{translate(tracks[Cam] ? 'cameraOff' : 'cameraOn')}
														</List.Item>
														<List.Item onClick={() => toggleTrack(Mic)}>
															{tracks[Mic] ? <VolumeUpIcon width={18} height={18} /> : <VolumeOffIcon width={18} height={18} />}
															{translate(tracks[Mic] ? 'muteAudio' : 'unMuteAudio')}
														</List.Item>
													</>
												)}
												<List.Item
													onClick={() => {
														togglePinnedParticipant(ScreenShare);
														setShowControls({ mainBox: false, screenShare: false });
													}}>
													{isCurrentlyPinnedParticipant && (
														<UnPinIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
													)}
													{!isCurrentlyPinnedParticipant && (
														<PinIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
													)}
													{translate(isCurrentlyPinnedParticipant ? 'unpinFeed' : 'pinFeed')}
												</List.Item>
											</List>
										</Dropdown.Items>
									</Dropdown>
								</Participant.Controls>
							)}
						</Participant>
					)}
				</>
			)}
		</>
	);
};

export default RemoteParticipantView;
