const UserRoleIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} fill='none' viewBox='0 0 24 24' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M16.006 3.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM13 7.58a3.035 3.035 0 016 0M4.006 15.08a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM1 19.08a3.034 3.034 0 016 0'
		/>
		<path stroke='currentColor' strokeLinecap='round' d='M9 2H5a2 2 0 00-2 2v2' />
		<path
			fill='currentColor'
			stroke='currentColor'
			d='M3.12 7.34l1.2-1.6a.15.15 0 00-.12-.24H1.8a.15.15 0 00-.12.24l1.2 1.6c.06.08.18.08.24 0z'
		/>
		<path stroke='currentColor' strokeLinecap='round' d='M11 18.08h4a2 2 0 002-2v-2' />
		<path
			fill='currentColor'
			stroke='currentColor'
			d='M16.88 12.74l-1.2 1.6a.15.15 0 00.12.24h2.4a.15.15 0 00.12-.24l-1.2-1.6a.15.15 0 00-.24 0z'
		/>
	</svg>
);

export default UserRoleIcon;
