import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import Calendar from 'react-calendar';
import moment from 'moment';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { Alert, Grid, Loader } from 'components';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getPhysicalExerciseIcon } from 'infrastructure/helpers/physicalExerciseHelper';
import { HttpStatusCodes, IntensityTypes } from 'constants/enums.js';
import { addPhysicalExercise, getExerciseCategories } from 'api/physicalExercises';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import Button from 'components/Button.jsx';

const AddPTExercise = props => {
	const [intensities, setIntensities] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState('');
	const [validationError, setValidationError] = useState(null);
	const [selectedIntensityType, setSelectedIntensityType] = useState(null);
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedType, setSelectedType] = useState(null);
	const [isSaveEnabled, setIsSaveEnabled] = useState(false);
	const [selectedDays, setSelectedDays] = useState([]);
	const intl = useIntl();

	useEffect(() => {
		fetchExerciseCategories();
	}, []);

	useEffect(() => {
		setIsSaveEnabled(
			selectedDays.length ===
				intensities.find(intensity => intensity.type === IntensityTypes.DAYS_TO_ACHIEVE_TARGET)?.selectedValue
		);
	}, [selectedDays, intensities]);

	const fetchExerciseCategories = async () => {
		const response = await getExerciseCategories();
		if (response.error) {
			setError(response.error.message);
		} else {
			setCategories(response.items);
		}
		setIsLoading(false);
	};

	const transformIntensities = array =>
		array.map(item => ({
			value: item,
			label: item,
		}));

	const changeSelectedIntensityValue = (event, intensity) => {
		if (intensity.type === IntensityTypes.DAYS_TO_ACHIEVE_TARGET) {
			setSelectedDays([]);
		}
		setIntensities(prevState => {
			const newIntensities = [...prevState];
			const selectedIntensity = newIntensities.find(item => item.type === intensity.type);
			selectedIntensity.selectedValue = selectedIntensity.values.find(val => val === event.value) ?? +event.value;
			return newIntensities;
		});
		setValidationError(null);
	};

	const transformForSelect = array =>
		array.map(item => ({ value: item.id, label: intl.formatMessage({ id: stringToCamelCase(item.name) }), icon: item.type }));

	const validateIntensityValues = (event, intensity) => {
		const { value } = event.target;
		const numValue = parseInt(value, 10);
		const intensityValues = intensity.values;
		const minValue = intensity.type === IntensityTypes.MAX_RANGE_OF_MOTION ? 0 : intensityValues.reduce((a, b) => Math.min(a, b));
		const maxValue =
			intensity.type === IntensityTypes.MAX_RANGE_OF_MOTION ? 100 : intensityValues.reduce((a, b) => Math.max(a, b));
		setSelectedIntensityType(intensity.type);
		if (/^[0-9]*$/.test(value) && numValue >= minValue && numValue <= maxValue) {
			setValidationError(null);
			return;
		}
		if (numValue < minValue || numValue > maxValue) {
			setValidationError(
				translate('rangeExceeded', {
					value1: minValue,
					value2: maxValue,
				})
			);
			return;
		}
		setValidationError(translate('containOnlyNumbers'));
	};

	const resetData = () => {
		setSelectedType(null);
		setIsSaveEnabled(false);
		setIntensities([]);
	};

	const onSelectedCategory = exerciseValue => {
		setSelectedCategory(exerciseValue);
		resetData();
	};

	const formatOptionLabel = ({ label, icon }) => (
		<div className='flex flex-align-center'>
			<div className='physical-exercise-category'>
				<img src={`${healthCareCdnUrl}physical-exercises/${getPhysicalExerciseIcon(icon, true)}.svg?v2`} alt={icon} />
			</div>
			<div>{label}</div>
		</div>
	);

	const onSelectedTypeOfExercise = typeValue => {
		resetData();
		const intensitiesPerType = categories
			.find(category => category.id === selectedCategory)
			.types.find(categoryType => categoryType.id === typeValue).settingsType;
		setSelectedType(typeValue);
		const intensityValues = intensitiesPerType.map(intensity => ({
			...intensity,
			selectedValue: intensity.values[0],
		}));
		setIntensities(intensityValues);
		if (intensitiesPerType.filter(intensity => intensity.type === IntensityTypes.DAYS_TO_ACHIEVE_TARGET).length === 0) {
			setIsSaveEnabled(true);
		}
		setValidationError(null);
	};

	const onSelectedDateChanged = date => {
		const givenDate = moment(date).format('MM/DD/YYYY');
		if (selectedDays.some(item => item === givenDate)) {
			return;
		}
		const intensityValue = intensities.find(intensity => intensity.type === IntensityTypes.DAYS_TO_ACHIEVE_TARGET)?.selectedValue;
		const isArrayFull = selectedDays.length === intensityValue;
		if (!isArrayFull) {
			setSelectedDays([...selectedDays, givenDate]);
		} else {
			setSelectedDays([givenDate]);
		}
	};

	const addExercise = async () => {
		setIsLoading(true);
		const intensitiesToSend = intensities.map(item => ({ type: item.type, value: item?.selectedValue ?? item.values[0] }));
		const dataToSend = {
			typeId: selectedType,
			categoryId: selectedCategory,
			dates: selectedDays,
			settingsExercise: intensitiesToSend,
		};

		const exercisesRes = await addPhysicalExercise(props.physicalTherapyId, dataToSend, props.patientId);
		if (exercisesRes.error) {
			const errorMessage =
				exercisesRes.error.response.status === HttpStatusCodes.CONFLICT
					? exercisesRes.error.response.data.message
					: exercisesRes.error.message;
			setError(errorMessage);
			setIsLoading(false);
			return;
		}
		setIsLoading(false);
		props.setIsAdded(!props.isAdded);
		props.setAddedTherapy(props.physicalTherapyId);
		props.setSelectedView(props.ptPrescriptionReport.length > 0 ? props.viewToShow.PT_PRESCRIPTION : props.viewToShow.REPORTS);
	};

	const getCalendarClass = date => {
		if (selectedDays.find(item => item === moment(date).format('MM/DD/YYYY'))) {
			return 'highlight';
		}
		return '';
	};

	return (
		<>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div className='text-align-center'>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading && (
				<div className='patient-summary-wrapper text-align-left'>
					<span className='therapy-reports-back'>
						<i
							className='material-icons therapy-reports-back-btn'
							onClick={() => props.setSelectedView(props.viewToShow.REPORTS)}>
							keyboard_arrow_left
						</i>
						{translate('goBack')}
					</span>
					<h3>{translate('addExercise')}</h3>
					<div className='semi-width'>
						<div>
							<label htmlFor='category'>*{translate('category')}</label>
							<Select
								options={transformForSelect(categories)}
								value={transformForSelect(categories).filter(option => option.value === selectedCategory)}
								onChange={event => onSelectedCategory(event.value)}
								placeholder={intl.formatMessage({ id: 'selectCategory' })}
								classNamePrefix='react-select'
								name='selectedCategory'
								formatOptionLabel={formatOptionLabel}
							/>
						</div>
						<div className='top-15'>
							<label htmlFor='exercise'>*{translate('exercise')}</label>
							<Select
								options={
									selectedCategory && transformForSelect(categories.find(category => category.id === selectedCategory).types)
								}
								value={
									selectedCategory &&
									transformForSelect(categories.find(category => category.id === selectedCategory).types).filter(
										option => option.value === selectedType
									)
								}
								onChange={event => onSelectedTypeOfExercise(event.value)}
								placeholder={intl.formatMessage({ id: 'selectTypeOfExercise' })}
								classNamePrefix='react-select'
								name='category'
								isDisabled={!selectedCategory}
							/>
						</div>
					</div>
					{selectedCategory && <p className='exercise-intensity'>{translate('regulateExerciseIntensity')}</p>}
					<div className='flex physical-exercises-calendar-wrapper'>
						<div>
							{intensities.map(intensity => (
								<>
									<div className='flex flex-space-between flex-align-center' style={{ marginBottom: '10px' }}>
										<h5>{intl.formatMessage({ id: stringToCamelCase(intensity.name) })}</h5>
										<CreatableSelect
											isClearable={false}
											options={transformIntensities(intensity.values)}
											onChange={event => {
												changeSelectedIntensityValue(event, intensity);
											}}
											defaultValue={[{ label: intensity.selectedValue, value: intensity.selectedValue }]}
											className='exercise-intensity-values'
											classNamePrefix='react-select width-20'
											name='selectedIntensity'
											onKeyDown={event => validateIntensityValues(event, intensity)}
										/>
									</div>
									<div className='exercise-validation'>
										<span className='red-error'>{selectedIntensityType === intensity.type && validationError}</span>
									</div>
								</>
							))}
						</div>
						<div>
							<p className='no-padding-top no-margin-bottom'>{translate('selectDatesForExercise')}</p>
							<Calendar
								className='appointment-calendar'
								formatShortWeekday={(locale, value) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][value.getDay()]}
								calendarType='US'
								onChange={date => {
									onSelectedDateChanged(date);
								}}
								minDate={new Date()}
								tileClassName={({ date }) => getCalendarClass(date)}
								prev2Label={null}
								next2Label={null}
							/>
						</div>
					</div>
					<div className='flex'>
						<Button
							className='edit-pt-report-btn'
							onClick={addExercise}
							isDisabled={!isSaveEnabled || validationError}
							text={translate('saveChanges')}
						/>
					</div>
					<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
				</div>
			)}
		</>
	);
};
export default AddPTExercise;
