import React, { useEffect, useRef, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import { HelloSettings, HdmiSysDelayValues } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const AudioSettings = props => {
	const sysDelayRef = useRef(null);
	const audioLevelRef = useRef(null);
	const customDefaultValue = 500;
	const maxAudioLevel = 100;
	const maxSysDelayLevel = 2400;
	const [audioLevel, setAudioLevel] = useState(0);
	const [hdmiSysDelayLevel, setHdmiSysDelay] = useState(customDefaultValue);
	const [selectedSysDelay, setSelectedSysDelay] = useState(HdmiSysDelayValues.CUSTOM);
	const audioValue = props.deviceSettings?.find(item => item.settingTypeId === HelloSettings.AUDIO_LEVEL);
	const hdmiSysDelayValue = props.deviceSettings?.find(item => item.settingTypeId === HelloSettings.HDMI_SYSDELAY);
	const [audioLeftPosition, setAudioLeftPosition] = useState(0);
	const [sysLeftPosition, setSysLeftPosition] = useState(0);

	useEffect(() => {
		if (audioValue) {
			setAudioLevel(audioValue.value);
		}

		if (hdmiSysDelayValue?.value === HdmiSysDelayValues.DEFAULT) {
			setSelectedSysDelay(HdmiSysDelayValues.DEFAULT);
		} else {
			setHdmiSysDelay(hdmiSysDelayValue?.value);
		}
	}, [props.selectedDevice.solHelloDeviceId]);

	const sysDelayOptions = [
		{
			value: HdmiSysDelayValues.DEFAULT,
			name: 'hdmiSysDelay',
		},
		{
			value: HdmiSysDelayValues.CUSTOM,
			name: 'hdmiSysDelay',
		},
	];

	const handleSelectOption = value => {
		if (value === HdmiSysDelayValues.CUSTOM) {
			props.updateDeviceSetting(HelloSettings.HDMI_SYSDELAY, customDefaultValue.toString());
			setHdmiSysDelay(customDefaultValue);
		} else {
			props.updateDeviceSetting(HelloSettings.HDMI_SYSDELAY, value.toString());
		}
		setSelectedSysDelay(value);
	};

	const updateSlider = (ref, maxWidth, level) => {
		const thumbWidth = 20.4;
		const inputWidth = ref.current.offsetWidth;
		const position = (+level - 0) / (maxWidth - 0);
		const thumbWidthCorrect = thumbWidth * (position - 0.5) * -1;
		const titlePosition = Math.round(position * inputWidth - thumbWidth / 4 + thumbWidthCorrect);
		return titlePosition;
	};

	useEffect(() => {
		if (audioLevelRef.current) {
			setAudioLeftPosition(updateSlider(audioLevelRef, maxAudioLevel, audioLevel));
		}
	}, [audioLevel]);

	useEffect(() => {
		if (sysDelayRef.current) {
			setSysLeftPosition(updateSlider(sysDelayRef, maxSysDelayLevel, hdmiSysDelayLevel));
		}
	}, [hdmiSysDelayLevel]);

	return (
		<div className='configurable-settings-modal-inner audio-settings'>
			<h3>{translate('audioSettings')}</h3>
			<div key='volume' className='flex flex-wrap grid-50'>
				<div className='flex cursor-pointer'>
					<img src={`${healthCareCdnUrl}admin/AudioVolume.svg`} alt={'audioLevel'} />
					<div>
						<p>{translate('audioLevel')}</p>
						<p>{translate('selectValue')}</p>
						<div></div>
						<div className='audio-volume flex flex-align-center'>
							<input
								type='range'
								id='volume'
								name='audio-volume'
								onChange={event => setAudioLevel(+event.target.value)}
								min='0'
								value={audioLevel}
								onMouseUp={() => props.updateDeviceSetting(HelloSettings.AUDIO_LEVEL, audioLevel.toString())}
								ref={audioLevelRef}
								max={maxAudioLevel}
							/>
							<span style={{ left: audioLeftPosition }}>{audioLevel}</span>
						</div>
						<span>{maxAudioLevel}</span>
					</div>
				</div>
			</div>
			<div key='hdmi' className='flex flex-wrap grid-50'>
				<div className='flex '>
					<img src={`${healthCareCdnUrl}admin/hdmi.svg`} alt={'hdmi'} />
					<div>
						<p>{translate('hdmiSyncDelay')}</p>
						<p>{translate('selectOneOfTheOptions')}</p>
						<div className='flex radio-options'>
							{sysDelayOptions.map(item => (
								<div className='flex' key={item.value}>
									<input
										type='radio'
										id={item.value}
										name={item.name}
										value={item.value}
										checked={selectedSysDelay === item.value}
										onChange={() => handleSelectOption(item.value)}
									/>
									<label htmlFor={item.value}>{translate(item.value)}</label>
								</div>
							))}
						</div>
						{selectedSysDelay === HdmiSysDelayValues.CUSTOM && (
							<>
								<p>{translate('selectCustomValue')}</p>
								<div className='audio-volume flex flex-align-center'>
									<input
										type='range'
										id='volume'
										name='audio-volume'
										onChange={event => setHdmiSysDelay(+event.target.value)}
										value={hdmiSysDelayLevel}
										onMouseUp={() => props.updateDeviceSetting(HelloSettings.HDMI_SYSDELAY, hdmiSysDelayLevel.toString())}
										ref={sysDelayRef}
										max={maxSysDelayLevel}
									/>
									<span style={{ left: sysLeftPosition }}>{hdmiSysDelayLevel}</span>
								</div>
								<span>{maxSysDelayLevel}</span>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AudioSettings;
