import React from 'react';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import EmptyState from 'components/EmptyState.jsx';
import translate from 'i18n-translations/translate.jsx';

const ComingSoon = () => (
	<MainLayout>
		<div className='empty-state-wrapper'>
			<EmptyState title={translate('comingSoon')} paragraph={translate('pleaseTryAgainLater')} image='no-hello.svg' />
		</div>
	</MainLayout>
);

export default ComingSoon;
