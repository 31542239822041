import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getTeamNetworkBandwidthConfigurations = async ({ pageSize = 10, pageIndex = 0, teamId = null, teamType = null }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${getCompanyId()}/configurations/network-bandwidth`, {

			params: {
				pageSize,
				pageIndex,
				teamId,
				teamType,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};
export const getTeamNetworkWiFiBandConfigurations = async ({ pageSize = 10, pageIndex = 0, teamId = null, teamType = null }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${getCompanyId()}/configurations/wifi-band`, {
			params: {
				pageSize,
				pageIndex,
				teamId,
				teamType,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};
export const addTeamNetworkBandwidthConfigurations = async (teamId, teamType, params) => {
	try {
		const { data } = await gatewayApi.post(
			`/v1/healthcare/organizations/${getCompanyId()}/teams/${teamId}/team-types/${teamType}/configurations/network-bandwidth`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};
export const updateTeamNetworkBandwidthConfigurations = async (id, teamId, teamType, params) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1/healthcare/organizations/${getCompanyId()}/teams/${teamId}/team-types/${teamType}/configurations/network-bandwidth/${id}`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};
export const getTeamNetworkBandwidthConfigurationHistory = async (id, { pageSize = 10, pageIndex = 0 }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${getCompanyId()}/configurations/network-bandwidth/${id}/history`, {
			params: {
				pageSize,
				pageIndex,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};
export const deleteTeamNetworkBandwidthConfiguration = async (teamId, teamType, id) => {
	try {
		const { data } = await gatewayApi.delete(
			`/v1/healthcare/organizations/${getCompanyId()}/teams/${teamId}/team-types/${teamType}/configurations/network-bandwidth/${id}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
export const addTeamNetworkWiFiBandConfigurations = async (teamId, teamType, params) => {
	try {
		const { data } = await gatewayApi.post(
			`/v1/healthcare/organizations/${getCompanyId()}/teams/${teamId}/team-types/${teamType}/configurations/wifi-band`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};
export const updateTeamNetworkWiFiBandConfigurations = async (id, teamId, teamType, params) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1/healthcare/organizations/${getCompanyId()}/teams/${teamId}/team-types/${teamType}/configurations/wifi-band/${id}`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};
export const deleteTeamNetworkWiFiBandConfigurations = async (teamId, teamType, id) => {
	try {
		const { data } = await gatewayApi.delete(
			`/v1/healthcare/organizations/${getCompanyId()}/teams/${teamId}/team-types/${teamType}/configurations/wifi-band/${id}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
export const getTeamNetworkWiFiBandConfigurationHistory = async (id, { pageSize = 10, pageIndex = 0 }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${getCompanyId()}/configurations/wifi-band/${id}/history`, {
			params: {
				pageSize,
				pageIndex,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};


export const addTeamNetworkAccessConfiguration = async ({ ipAddresses, roleId }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/healthcare/organizations/${getCompanyId()}/configurations/network-access`, {
			ipAddresses,
			roleId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};
export const updateTeamNetworkAccessConfiguration = async ({ id, ipNetwork }) => {
	try {
		const { data } = await gatewayApi.patch(`/v1/healthcare/organizations/${getCompanyId()}/configurations/network-access/${id}`, {
			ipNetwork,
		});
		return data;
	} catch (error) {
		return { error };
	}
};
export const deleteTeamNetworkAccessConfiguration = async id => {
	try {
		const { data } = await gatewayApi.delete(`/v1/healthcare/organizations/${getCompanyId()}/configurations/network-access/${id}`);
		return data;
	} catch (error) {
		return { error };
	}
};
export const getTeamNetworkAccressConfiguration = async ({ pageSize = 10, pageIndex = 0, roleId = null, search = '' }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${getCompanyId()}/configurations/network-access`, {
			params: {
				pageSize,
				pageIndex,
				roleId,
				search
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};
export const getTeamNetworkAccressConfigurationHistory = async ({ id, pageSize = 10, pageIndex = 0 }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${getCompanyId()}/configurations/network-access/${id}/history`, {
			params: {
				pageSize,
				pageIndex,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

