const HospitalIcon = props => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path d='M21 10H18V22H23V12C23 10.8954 22.1046 10 21 10Z' stroke='currentColor' strokeWidth='1.25' />
		<rect x='18' y='12' width='3' height='2' stroke='currentColor' strokeWidth='1.25' strokeLinejoin='round' />
		<rect x='18' y='16' width='3' height='2' stroke='currentColor' strokeWidth='1.25' strokeLinejoin='round' />
		<path d='M3 10H6V22H1V12C1 10.8954 1.89543 10 3 10Z' stroke='currentColor' strokeWidth='1.25' />
		<rect
			width='3'
			height='2'
			transform='matrix(-1 0 0 1 6 12)'
			stroke='currentColor'
			strokeWidth='1.25'
			strokeLinejoin='round'
		/>
		<rect
			width='3'
			height='2'
			transform='matrix(-1 0 0 1 6 16)'
			stroke='currentColor'
			strokeWidth='1.25'
			strokeLinejoin='round'
		/>
		<path d='M6 6C6 4.34315 7.34315 3 9 3H15C16.6569 3 18 4.34315 18 6V22H6V6Z' stroke='currentColor' strokeWidth='1.25' />
		<path
			d='M14 18H10C9.44772 18 9 18.4477 9 19V22H15V19C15 18.4477 14.5523 18 14 18Z'
			stroke='currentColor'
			strokeWidth='1.25'
		/>
		<path d='M12 18V22' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' />
		<path
			d='M10.5 6.49992H12M12 6.49992H13.5M12 6.49992V5M12 6.49992V8'
			stroke='currentColor'
			strokeWidth='1.25'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M8 10H11V12H8V10Z' stroke='currentColor' strokeWidth='1.25' strokeLinejoin='round' />
		<path d='M13 10H16V12H13V10Z' stroke='currentColor' strokeWidth='1.25' strokeLinejoin='round' />
		<path d='M8 14H11V16H8V14Z' stroke='currentColor' strokeWidth='1.25' strokeLinejoin='round' />
		<path d='M13 14H16V16H13V14Z' stroke='currentColor' strokeWidth='1.25' strokeLinejoin='round' />
	</svg>
);
export default HospitalIcon;
