import Countdown from 'react-countdown';
import { PrivacyIcon } from 'icons/Monitoring/index.js';
import translate from 'i18n-translations/translate.jsx';

const PrivacyMode = ({ feed, toggleOffPrivacy, patientId, togglePrivacyMode }) => {
	const getCountDownTimer = ({ minutes, seconds, completed }) => {
		if (completed) {
			toggleOffPrivacy();
			return <></>;
		}
		const getFormattedTime = value => (value.toString().length > 0 ? value.toString().padStart(2, '0') : value.toString());
		return (
			<div>
				<span className='privacy-timer'>
					{getFormattedTime(minutes)}:{getFormattedTime(seconds)}
				</span>
			</div>
		);
	};

	return (
		<>
			<div className='monitoring-timeline-box patient-privacy'>
				<div className='timeline-box-header'>
					<p className='timeline-box-title'>
						<PrivacyIcon />
						{translate('patientPrivacy')}
					</p>
					<div className='timeline-box-actions'>
						<div className='flex'>
							{feed.aiPrivacyStatus && feed.privacyTimerMilliseconds > 0 && (
								// @ts-ignore
								<Countdown date={feed.privacyTimerMilliseconds} renderer={getCountDownTimer} />
							)}
							<div className='toggle-switch' onClick={() => togglePrivacyMode(feed.deviceId, feed.aiPrivacyStatus, patientId)}>
								<input type='checkbox' checked={feed.aiPrivacyStatus} onChange={() => null} />
								<span className='toggle-body'>
									<span className='on-text'>{translate('on')}</span>
									<span className='off-text'>{translate('off')}</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrivacyMode;
