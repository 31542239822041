import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Input from 'components/Common/FormElements/Input.jsx';
import Select from 'components/Common/FormElements/Select.jsx';
import { Form, Modal, Alert } from 'components/index.js';
import { addUserIdleConfiguration } from 'api/userIdleConfigurations.js';
import translate from 'i18n-translations/translate.jsx';
import { handleOnKeyDownNumeric } from 'infrastructure/helpers/commonHelpers.js';

const UserIdleForm = props => {
	const intl = useIntl();
	const [isSuccessfulAlertOn, setIsSuccessfulAlert] = useState(false);
	const [isErrorsAlertOn, setIsErrorsAlertOn] = useState(false);
	const healthSystemsArray = useSelector(state => state.healthSystems.allHealthSystems);

	const handleSubmitMyForm = async (values, { resetForm, setSubmitting }) => {
		setSubmitting(true);

		const healthSystemIds =
			values.healthSystem === '0' ? props.healthSystems.filter(x => x.id !== '0').map(x => x.id) : [values.healthSystem];
		const roleId = values.role === '0' ? null : parseInt(values.role, 10);
		const { minutes } = values;

		const res = await addUserIdleConfiguration({ healthSystemIds, roleId, timeoutMinutes: minutes });

		if (res.hasSucceeded) {
			setIsSuccessfulAlert(true);
			props.onSucceeded();

			if (!props.initialValues) {
				resetForm();
			}
		}
		if (res.error || !res.hasSucceeded) {
			setIsErrorsAlertOn(true);
		}
		setSubmitting(false);
	};

	const onCloseModal = resetForm => {
		setIsSuccessfulAlert(false);
		setIsErrorsAlertOn(false);
		resetForm();
		props.toggleModal();
	};

	const getInitialValues = () => {
		if (props.initialValues)
			return {
				healthSystem: props.healthSystems.find(x => x.id === props.initialValues.healthSystem)
					? props.initialValues.healthSystem
					: '',
				role: props.initialValues.role,
				minutes: props.initialValues.minutes,
			};
		return { healthSystem: props.isSuperUser ? healthSystemsArray[0].id : '', role: '', minutes: '' };
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={getInitialValues()}
			validationSchema={Yup.object().shape({
				healthSystem: Yup.string().required(intl.formatMessage({ id: 'healthSystemRequired' })),
				role: Yup.string().required(intl.formatMessage({ id: 'roleIsRequired' })),
				minutes: Yup.number()
					.positive(intl.formatMessage({ id: 'minutesGreaterThanZero' }))
					.max(480)
					.required(intl.formatMessage({ id: 'minutesRequired' })),
			})}
			onSubmit={handleSubmitMyForm}>
			{fProps => {
				const { handleSubmit, resetForm, isSubmitting } = fProps;
				return (
					<Modal
						modalSelector='setTimeoutModal'
						display={props.isModalOpen}
						position='right'
						onModalSubmit={handleSubmit}
						onModalClose={() => onCloseModal(resetForm)}
						isLoading={isSubmitting}
						shouldSubmitOnEnter={false}
						className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'>
						<Form className='manage-hs-form' onSubmit={event => event.preventDefault()}>
							<h3>{translate('userIdleFormTitle')}</h3>
							<Field
								name='healthSystem'
								type='select'
								label={intl.formatMessage({ id: 'selectHealthSystem' })}
								placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
								description={intl.formatMessage({ id: 'selectHealthSystemOrAll' })}
								items={props.healthSystems}
								component={Select}
							/>
							<Field
								name='role'
								type='select'
								label={intl.formatMessage({ id: 'chooseRole' })}
								placeholder={`${intl.formatMessage({ id: 'selectRole' })}...`}
								description={intl.formatMessage({ id: 'selectRoleOrAll' })}
								items={props.roles}
								component={Select}
							/>
							<Field
								name='minutes'
								type='number'
								label={intl.formatMessage({ id: 'setInActivityPeriod' })}
								placeholder={`3 ${intl.formatMessage({ id: 'minutes' })}`}
								description={intl.formatMessage({ id: 'userIdleNotifedPeriod' })}
								component={Input}
								onKeyDown={handleOnKeyDownNumeric}
							/>
							<Alert
								alertSelector='setTimeoutAlertMessage'
								display={isSuccessfulAlertOn}
								message={intl.formatMessage({ id: 'configurationSuccessfullyAdded' })}
								variant='success'
								onClose={() => setIsSuccessfulAlert(false)}
							/>
							<Alert
								alertSelector='setTimeoutErrorMessage'
								display={isErrorsAlertOn}
								message={intl.formatMessage({ id: 'somethingWentWrong' })}
								variant='error'
								onClose={() => setIsErrorsAlertOn(false)}
							/>
						</Form>
					</Modal>
				);
			}}
		</Formik>
	);
};

export default UserIdleForm;
