import { healthCareCdnUrl } from 'constants/global-variables.js';
import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => (
	<div className='new-experience'>
		<div className='privacy-policy-terms-wrapper'>
			<div className='privacy-policy-logo'>
				<Link to='/'>
					<img src={`${healthCareCdnUrl}header/bluelogo-1.svg`} alt='hellocare' />
				</Link>
			</div>
			<div className='privacy-policy-terms'>
				<h1>hellocare Privacy Policy</h1>
				<p>
					Effective Date: October 1st, 2023 hellocare together with its subsidiaries including without limitation (“hellocare”,
					“Company” “we” or “us”) respect the privacy of the Users of our Services and are committed to protect the Personal and
					Medical Information that Users share with us in connection with the use of the Service.This Privacy Policy (the “Privacy
					Policy”) is intended to describe our practices regarding the information we may collect from you when you use the
					Services (or any part thereof), the manners in which we may use such information, and the options and rights available
					to you.Capitalized terms which are not defined herein, shall have the meaning ascribed to them in our Terms of Use
					available at https://beta.hellocare.ai/terms-of-use (the “TOU”), which this Privacy Policy is incorporated thereto by
					reference.
				</p>
				<h3>1. Your Consent (PLEASE READ CAREFULLY!)</h3>
				<p>
					BY ENTERING, CONNECTING TO, ACCESSING OR USING THE SERVICES (OR ANY PART THEREOF), YOU AGREE TO THE TERMS AND CONDITIONS
					SET FORTH IN THIS PRIVACY POLICY, INCLUDING THE COLLECTION AND PROCESSING OF YOUR PERSONAL AND MEDICAL INFORMATION (AS
					DEFINED BELOW). IF YOU DISAGREE TO ANY TERM PROVIDED HEREIN, YOU MAY NOT ACCESS AND/OR USE THE SERVICE IN ANY MANNER
					WHATSOEVER.
					<br />
					<br />
					<span className='bold'>
						To the extent that you use the Services on behalf of a Patient who is not you, such use must be conducted solely in
						strict compliance with the TOU and the terms hereof and you shall be solely responsible to have or receive and hereby
						represent that you have or received the consent, authority, permission and approval of such Patient to provide the
						Personal Information detailed above and allow hellocare to collect, store, and use such personal Information in
						accordance with this Privacy Policy. If you are acting on behalf of another Patient who is a minor, you must also
						follow the terms in Section 9 herein.
					</span>
					<br />
					<br />
					IMPORTANT:
					<br />
					PLEASE NOTE: YOU ARE NOT OBLIGATED TO PROVIDE US WITH ANY PERSONAL OR MEDICAL INFORMATION. YOU HEREBY ACKNOWLEDGE AND
					AGREE THAT YOU ARE PROVIDING US WITH SUCH INFORMATION AT YOUR OWN FREE WILL, FOR THE PURPOSES DESCRIBED IN SECTION 3 AND
					4 BELOW, AND THAT WE MAY USE, PROCESS AND RETAIN SUCH PERSONAL OR MEDICAL INFORMATION IN ACCORDANCE WITH THIS PRIVACY
					POLICY AND SUBJECT TO ANY APPLICABLE LAWS AND REGULATIONS.
				</p>
				<h3>2. We may collect the following kinds of information when you use the Services:</h3>
				<p>
					<span className='underline'>
						Information you provide directly to us. For certain activities, such as when you register, use our telemedicine
						services, subscribe to our alerts, or contact us directly, we may collect some or all of the following types of
						information:
					</span>
					<br />
					<br />
					We may collect various types of data and information from our Users: i. Personal Information, which is information that
					identifies an individual or may with reasonable effort identify an individual, either alone or in combination with other
					information, or may be of private or sensitive nature including Medical Information of a User, all unless anonymized;
					and ii. Non-personal Information, which is non-identifying and non-identifiable information, without particular
					reference to the identity of the User from or about whom such information was collected. iii. Medical Information, which
					is any medical information, including physiological data of the body parts or organs, physiological data, diagnosis,
					tests, results, treatments, prescription, therapy, counseling, laboratory and any other health related information.
					<br />
					<br />
					For the avoidance of doubt, any Non-personal Information connected or linked to any Personal Information shall be deemed
					as Personal Information as long as such connection or linkage exists. In this context it is important to note that
					Medical Information may be categorized as either Personal Information or Non-Personal Information in accordance with the
					definitions above and the characteristics of each specific item of Medical Information.
				</p>
				<h3>3. How we collect information of our Users</h3>
				<p>
					Personal and Medical Information which is collected when you interact with the Service. This is information you provide
					to us when you access the Services or in the course of your use of the Service, including when you register and open an
					Account, when you provide additional information about yourself and/or about a Patient during your use of the Service,
					when you conduct an exam and communicate its results to a clinician via Service, or when you contact us directly.
					<br />
					<br />
					<ul>
						<li>
							Registration Information: As part of the registration, users will be required to enter their information such
							his/her contact information such as full name, e-mail address, phone number, full address, username and password.
							The User may also be required to input certain personal demographic details which are related to the Services
							provided such as date of birth, gender and family members, connections or ties and other basic information. During
							the registration process, you will be asked to choose a password and username for your Account.
						</li>
						<li>
							Medical Information transmitted in the course of using the Services: the User&apos;s Medical Information as may be
							transmitted by or to You through your active voluntary the use of the Hello Devices, included but not limited to
							descriptions of medical/health status, audiovisual recording, descriptions and images of human body organs and
							related symptoms and related materials, auscultations of the heart, lung or gastro, body temperature readings
							whether in real time or off-line and diagnosis of visits as inputted by clinician, etc.
						</li>
						<li>
							For health care providers, information about your employment, such as your job title, practice area, primary
							specialty, and medical license status, gender, date of birth, languages spoken, educational background, address,
							photograph, social security number, Tax ID, NPI number, professional license information and bank account
							information.
						</li>
						<li>
							Medical Information Users obtained from third parties&apos; systems, products and services (“External Medical
							Information”): The Services may include features which enable Users to upload and communicate External Medical
							Information through the Services (such as information generated via external weight scale, blood pressure monitor,
							pulse oximeter, glucose meter, lab documents, medical summaries). You understand that the accuracy and completeness
							of Medical Information may be critical for any decision or diagnosis made based on or using such Information. You
							are solely responsible for the External Medical Information and you are aware that hellocare is not checking or
							monitoring such Information, including (without limitation) not monitoring such External Medical Information
							accuracy or completeness. Personal Information which is being collected by us automatically when you use the
							Services. This is information which we automatically receive from devices (including Hello Devices and mobile
							devices) and software that you use when you access or interact with our Services. This information includes:
						</li>
						<li>
							IP address, UDID (Unique Device Identifier) or other persistent user and/or mobile device token (as applicable),
							geolocation, device type, operating system, browser type and version, screen resolution, browser and keyboard
							language, the User&apos;s &apos;click-stream&apos; and activities on the Service, the period of time the User
							visited the Service and related timestamps.
						</li>
						<li>
							We may also collect technical data to address and fix technical problems and improve our Services, including the
							memory state of your device when a system or app crash occurs while using our Services. Your device or browser
							settings may permit you to control the collection of this technical data. This data may include parts of a document
							you were using when a problem occurred, or the contents of your communications. By using the Services, you are
							consenting to the collection of this technical data.
						</li>
						<li>Payment information, such as your credit card number, expiration date, and credit card security code.</li>
					</ul>
					<br />
					<span>
						We will not collect any Personal and Medical Information from you or related to you without your approval, which is
						obtained, inter alia, through your active acceptance of the TOU and this Privacy Policy.
					</span>
					<br />
					Non-Personal Information is collected through your use of the Service. We may be aware of your use of the Service, and
					may gather, collect and record the information relating to such usage, either independently or through the help of our
					third-party services. We may also collect Non-personal Information through the analysis aggregation and anonymization of
					Personal Information provided by you.
				</p>
				<h3>4. Audiovisual Recording Consent.</h3>
				<p>
					You agree and acknowledge that in providing telehealth services hellocare records and stores the audiovisual Personal
					Information (and related materials) related to the User&apos;s communicated through the Service by you or on your
					behalf, and to you in the scope of your use thereof including in your communication with your Clinician or healthcare
					provider using the Service, and that hellocare shall maintain and use such information in accordance with the terms
					hereof.
				</p>
				<h3>5. The Purposes and Legal Basis of the Collection, Processing and Use of Information</h3>
				<p>
					<span className='underline'>Legal Basis for use</span> <br />
					<br />
					We collect, process and use your Personal Information for the purposes described in this Privacy Policy, based at least
					on one of the following legal grounds:
					<br />
					<br />
					<ul>
						<li>
							In Performing an agreement with you: We collect and process your Personal Information in order to provide you with
							the Service, following your acceptance of this Privacy Policy and pursuant to the Terms of Use; to maintain and
							improve our Services; to develop new services and features for our Users; and to personalize the Services in order
							for you to get a better user experience.
						</li>
						<li>
							With your consent: We ask for your agreement to collect and process your information for specific purposes and you
							have the right to withdraw your consent at any time.
						</li>
						<li>
							Legitimate interest: We process your information for our legitimate interests while applying appropriate safeguards
							that protect your privacy. This means that we process your information for things like detecting, preventing, or
							otherwise addressing fraud, abuse, security, usability, functionality or technical issues with our services,
							protecting against harm to the rights, property or safety of our properties, or our users, or the public as required
							or permitted by law; Enforcing legal claims, including investigation of potential violations of this Privacy Policy;
							in order to comply and/or fulfil our obligation under applicable laws, regulation, guidelines, industry standards
							and contractual requirements, legal process, subpoena or governmental request, as well as our TOU.
						</li>
					</ul>
					<br />
					<span className='underline'>Purpose of use</span>
					<br />
					We may use the Personal Information that we collect about you for the following purposes:
					<br />
					<br />
					<ul>
						<li>
							To provide and operate the Service, including without limitation, enabling Users to perform a full physical
							examination remotely without the need to meet a Clinician face-to-face and enabling Clinicians to collect digital
							physical exam information and have it available for future review and other functions or services as otherwise
							described to you at the time of collection;
						</li>
						<li>To send you updates, notices, notifications, and additional communications regarding the Services</li>
						<li>
							To be able to manage your Account and provide you with customer support services which may include at hellocare
							discretion, review of your examination data and audiovisual recordings to detect any incorrect use of the Services
							and upon such detection having our support team contact you through the Hello Health App to provide you with support
							for correction of such use.
						</li>
						<li>
							To study and analyze the information on an aggregated, statistical basis to discover patterns and anomalies, to
							study complications and treatment methods and improvements, and other medical/clinical related research and
							development;
						</li>
						<li>
							To enable us to further develop, customize and improve the Service based on Users&apos; common preferences, uses,
							attributes and anonymized data;
						</li>
						<li>
							To enable us to provide our Users with a better user experience, with more relevant and accurate information,
							services, third party services, features and functionalities, statistical and research purposes, etc.
						</li>
						<li>
							To prevent, detect, mitigate, and investigate fraud, security breaches or other potentially prohibited or illegal
							activities
						</li>
						<li>
							To comply with any applicable rule or regulation, to protect our legal interests and/or respond to or defend against
							(actual or potential) legal proceedings against us or our affiliates.
						</li>
					</ul>
				</p>
				<h3>6. Sharing Information with Third Parties</h3>
				<p>
					hellocare will not share or otherwise allow access to any Personal or Medical Information it collects to any third
					party, except in the following cases:
					<br />
					<br /> (a) Law enforcement, legal proceedings, and as authorized by law: We may disclose Personal Information to satisfy
					any applicable law, regulation, legal process, subpoena or governmental request; <br />
					<br />
					(b) Protecting Rights and Safety: We may share Personal Information to enforce this Privacy Policy and/or the TOU,
					including investigation of potential violations thereof; to detect, prevent, or otherwise address fraud, security or
					technical issues; to respond to User&apos;s support requests; to respond to claims that any content available on the
					Service or the Site violates the rights of third-parties; to respond to claims that contact information (e.g. name,
					e-mail address, etc.) of a third-party has been posted or transmitted without their consent or as a form of harassment;
					and to protect the rights, property, or personal safety of hellocare, any of its Users, or the general public;
					<br />
					<br /> (c) Our Affiliated Companies: We may share Personal Information internally with our family of companies for the
					purposes described in this Privacy Policy. In addition, when hellocare or any of our affiliated companies is undergoing
					any change in control, including by means of merger, acquisition or purchase of all or substantially all of its assets,
					we may share Personal Information with the parties involved in such an event. If we believe that such change in control
					might materially affect your Personal Information then stored with us, we will notify you of this event and the choices
					you may have via e-mail and/or prominent notice on our website or Services;
					<br />
					<br /> (d) Third Party Services: We partner with certain third parties to provide selected services that are used to
					facilitate and enhance the Services. Service Providers&quot;). Such Third-Party Service Providers may have access to, or
					process on our behalf Personal Information which we collect, hold, use, analyze, process and/or manage. These Services
					Providers include hosting, database and server co-location services (e.g. Microsoft Azure Services), data analytics
					services (e.g. Google Analytics), session replay records for app analytic purposes such as crashes, functionality and
					usability, remote access services, data and cyber security services, fraud detection and prevention services, e-mail and
					text message distribution and monitoring services (e.g. Twilio), payment processors (e.g. Stripe) dispute resolution
					providers, customer support and call center services, and our business, legal and financial advisors (collectively,
					&quot;Third Party Service Providers&quot;). We remain responsible for any Personal Information processing done by Third
					Party Service Providers on its behalf, except for events outside of its reasonable control.
					<br />
					<br /> (e) Clinicians and Third-Party Professionals: We may share with or allow Clinicians of your choice access to your
					Personal Information. Additionally, if your Clinicians, if so permitted under applicable law, determine that they
					require clinical/medical consultation with a third party clinicians, we may share your Personal Information with such
					third party professionals, in order to provide you with the Services. For avoidance of doubt, hellocare may transfer and
					disclose to third parties or otherwise use Non-personal Information (which includes Medical Information which is
					Non-Personal including by being anonymized) at its own discretion.
				</p>
				<h3>7. Storage, Transfer and Retention of your Information</h3>
				<p>
					Information regarding the Users will be maintained, processed and stored by us and our authorized affiliates and service
					providers in the United States, and as necessary, in secure cloud storage, provided by our third party service provider.
					While the data protection laws in the above jurisdictions may be different than the laws of your residence or location,
					please know that we, our affiliates and our service providers that store or process your Personal Information on our
					behalf are each committed to keep it protected and secured, pursuant to this Privacy Policy and industry standards,
					regardless of any lesser legal requirements that may apply in their jurisdiction.
					<br />
					<br /> By providing your information, you expressly consent to the place of storage and transfer described above,
					including transfers outside of the jurisdiction in which the information was provided. We retain the Personal
					Information we collect only for as long as needed in order to provide you with our Services and to comply with
					applicable laws and regulations. We then either delete from our systems or anonymize it without further notice to you.
					<br />
					<br /> If you withdraw your consent to us processing your Personal Information, we will delete your Personal Information
					from our systems (except to the extent such data in whole or in part to comply with any applicable rule or regulation
					and/or response or defend against legal proceedings versus us or our affiliates).
				</p>
				<h3>8. Updating, Obtaining a copy of, or Deleting of Personal Information</h3>
				<p>
					In accordance with the law applicable to you, if for any reason you wish to modify, update, suspend the processing,
					revise and/or delete your Personal Information stored with us and identifying to you, ask for our confirmation as to
					whether or not we process your Personal Information, or delete your Account from the Service, you may do so by
					contacting your system administrator (the third party that provided you with access to the hellocare Services) or
					hellocare through Services&apos; appropriate channel (certain changes may be performed through the Hello Health App App
					or Site while all changes and deletions of Accounts may be done by contacting hellocare support at
					support@hellocare.ai). We will delete any such Personal Information pursuant to any applicable privacy laws or otherwise
					de-identify any such Personal Information and thereby transform it to become Non-Personal Information. Note that we may
					retain your Personal Information in accordance with this Privacy Policy for a minimum period required to provide the
					Services subject to and as required by applicable laws or as required by us for archival purposes.
					<br />
					<br /> Subject the limitations pursuant to applicable law and if entitled for the same thereunder, you may also be
					entitled to obtain the Personal Information you directly provided us (excluding data we obtained from other sources) in
					a structured, commonly used, and machine-readable format and may have the right to transmit such data to another party.
					We may provide such access through the Services.
					<br />
					<br /> If you wish to exercise any of these rights, contact us at support@hellocare.ai. When handling these requests, we
					may ask for additional information to confirm your identity and your request. Please note, upon request to delete your
					Personal Information, we may retain such data in whole or in part to comply with any applicable rule or regulation
					and/or response or defend against legal proceedings versus us or our affiliates.
					<br />
					<br />
					To find out whether these rights apply to you and on any other privacy related matter, you can contact your local data
					protection authority if you have concerns regarding your rights under the local law.
				</p>
				<h3>9. Minors and Adults without Mental Capacity</h3>
				<p>
					To open an Account on our Services, you must be over the age of eighteen (18) and have the mental capacity to enter into
					a legal agreement. Without relieving you of your responsibility to comply with the TOU and the terms hereof we reserve
					the right (without obligation) to request proof of age at any stage so that we can verify that minors under the age of
					eighteen (18) are not using our Services. <br />
					<br />
					If You have added and listed a Patient under the age of 18 or an Adult without mental capacity you hereby declare to
					hellocare that You are the parent or legal guardian of such Patient or that you are a Clinician authorized to provide
					care for such Patient:
					<br />
					<br />
					<span className='bold'>
						If you are a parent or guardian you may use the Service, to provide Medical Information on behalf of your child or
						dependent who is under the age of eighteen (18) or without mental capacity by adding and listing them under your
						Account. In such a case, you (in your capacity as parent or guardian) assume full responsibility for ensuring that the
						information that you provide to hellocare about your child or dependent is kept secure and accurate.
					</span>
					<br />
					<br />
					<span className='bold'>
						For Clinicians: If a Patient is under the age of eighteen (18) or an adult without mental capacity, you hereby declare
						that you have all of the required consents, approvals, and rights to provide health services to such Patient including
						the receipt, access, processing, transmitting, displaying and providing Medical Information on the minor&apos;s
						behalf.
					</span>
					<br />
					<br />
					In the event that it comes to our knowledge that a person under the age of eighteen (18) or an adult without mental
					capacity is using our Services, not in accordance with the above mentioned terms, we will prohibit and block such User
					from accessing our Services and will make all efforts to promptly delete any Personal Information (as such term is
					defined in herein with regard to such User).
				</p>
				<h3>10. Direct Marketing</h3>
				<p>
					If you provide us with your contact details through the Services or our website (separately from any of your PHI), for
					the purpose of receiving from hellocare different offers with respect to the hellocare Services, you hereby agree that
					we may use such contact details, in order to contact you, inform you regarding our products and services which may
					interest you, and send you other marketing material, including news and updates by transmission to the e-mail address or
					phone number you have provided. <br />
					<br />
					You may withdraw your consent by sending us a written notice via email to the following address: support@hellocare.ai.{' '}
				</p>
				<h3>11. Security</h3>
				<p>
					hellocare is responsible for taking all reasonable and appropriate steps for the protection of the confidentiality,
					availability, privacy, and integrity of information in its custody. This includes the preservation of information in
					case of intentional, accidental, or natural disaster. In addition, hellocare is responsible for the maintenance and
					currency of applications that use this information.
					<br />
					<br />
					hellocare will use reasonable efforts to ensure that personally identifiable information is adequately protected from
					unauthorized disclosure.
					<br />
					<br /> Enforcement of hellocare Information Security Policies and compliance with Federal and State regulations
					regarding information technology is the responsibility of the Information Security Officer. All hellocare Information
					Security Policies are to be reviewed on an annual basis by the Information Security Officer (ISO) for compliance with
					the hellocare Information Security Policy and Federal and State regulations.
					<br />
					<br />
					We take great care in implementing and maintaining the security of the Service and the Site, and our Users&apos;
					Personal Information. Our Services, or parts thereof, are hosted on MICROSOFT AZURE, which provides advanced security
					features. hellocare employs industry standard procedures and policies to ensure the safety of its Users&apos; Personal
					Information, and prevent unauthorized use of any such information, including secured transmission protocols and AES
					256-bit encryption. However, we do not and cannot guarantee that unauthorized access will never occur. In case of the
					personal data breach, the notification toward the appropriate supervisory authorities shall be communicated within 72
					hours after hellocare has become aware of such event. Personal data breach notification will include information on data
					subjects affected, the types of data affected, the number of data subjects affected, improvements that have been made in
					order to prevent such an event in the future and more.
					<br />
					<br /> We urge you to use the strongest password combination available on your mobile device and employ reasonable
					physical security means to protect unauthorized access.
				</p>
				<h3>12. Third Party Websites</h3>
				<p>
					While using the Service you may encounter links to third party websites and/or services. Please be advised that such
					third-party websites and/or services are independent from hellocare, and may use cookies and other webtracking
					technologies to collect Non-Personal and/or Personal Information about you. We assume no responsibility or liability
					whatsoever with regard to privacy matters or any other legal matter with respect to such third-party websites and/or
					services. We encourage you to carefully read the privacy policies and the terms of use of such third-party websites
					and/or services, as their terms, not ours, will apply to any of your interactions with such third parties. hellocare has
					no control over the use of any information you provide by these third-party services.
				</p>
				<h3>13. Changes to the Privacy Policy</h3>
				<p>
					hellocare reserves the right to change this Privacy Policy at any time, so please re-visit this page frequently. We will
					provide notice of substantial changes of this Privacy Policy on the Service and/or we will send you an email regarding
					such changes to the e-mail address that is registered with your Account. Such substantial changes will take effect seven
					(7) days after such notice was provided on any of the above-mentioned methods. Otherwise, all other changes to this
					Privacy Policy are effective as of the stated “Last Revised” date, and your continued use of the Service or the Site
					after the Last Revised date will constitute acceptance of, and agreement to be bound by, those changes.
				</p>
				<h3>14. Compliance with HIPAA Privacy Regulations</h3>
				<p>
					Our privacy practices are intended to comply with our obligations as a Business Associate with respect to your health
					care provider under the Health Insurance Portability and Accountability Act of 1996 (&quot;HIPAA&quot;). We will
					maintain the privacy of your Personal Information as required by HIPAA and by any Business Associate Agreement we might
					have with your healthcare provider. We encourage you to review your healthcare provider&apos;s Notice of Privacy
					Practices, which describes how your Provider may use and disclose your PHI to us and others.
				</p>
				<h3>15. Notice of Electronic Disclosure</h3>
				<p>
					The following notice is made pursuant to Section 181.154 to the Texas Health and Safety Code to the extent applicable -
					Subject to and in accordance with the terms hereof, PHI may be stored, processed, conveyed, and in some instances,
					disclosed in an electronic format. hellocare will provide you with a written notice and request a separate authorization
					in the event of electronic disclosure other than for the purpose of treatment, payment, health care operations, or as
					otherwise authorized or required by state or federal law.
				</p>
				<h3>16. Your use of information; Data protection laws</h3>
				<p>
					You agree that you shall comply with our TOU Policy specifically section five (5) regarding your use of information. If
					you receive information about another customer of ours, you must keep the information confidential and only use it in
					connection with our services permitted to our policies and the applicable laws. You may not disclose or distribute any
					information about our users to a third party or use the information for marketing purposes unless you receive that
					user&apos;s express consent to do so. You may not send unsolicited emails to a customer or assist in sending unsolicited
					emails to third parties regarding our services without prior consent of ours.
					<br />
					<br />
					The privacy and data protection laws that may apply include any associated regulations, regulatory requirements and
					codes of practice applicable to the provision of the services described in this agreement. For example, if you or your
					business is subject to the requirements of the General Data Protection Regulation (EU) 2016/679 (GDPR), The Health
					Insurance Portability and Accountability Act (HIPAA) or the California Consumer Privacy Act (CCPA), you will comply with
					such regulation with respect to the processing of personal data. In complying with such laws, you will:
					<br />
					<br />
					<ul>
						<li>implement and maintain all appropriate security measures for the processing of personal data; and</li>
						<li>
							not knowingly do anything or permit anything to be done which might lead to a breach of any privacy and data
							protection laws by hellocare.
						</li>
					</ul>
				</p>
				<h3>17. External Services</h3>
				<p>
					The Licensed Application may enable access to Licensor&apos;s and/or third-party services and Websites (collectively and
					individually, “External Services”). You agree to use the External Services at your sole risk, always complying with
					applicable third-party terms of agreement. We are not responsible for examining or evaluating the content or accuracy of
					any third-party External Services, and shall not be liable for any such third-party External Services. Data displayed by
					any Licensed Application or External Service, including but not limited to financial, medical and location information,
					is for general informational purposes only and is not guaranteed by hellocare or its agents. You will not use the
					External Services in any manner that is inconsistent with the terms of this TOU or that infringes the intellectual
					property rights of TOU or any third party. You agree not to use the External Services to harass, abuse, stalk, threaten
					or defame any person or entity, and that hellocare is not responsible for any such case. External Services may not be
					available in all languages or in your Home Country, and may not be appropriate or available for use in any particular
					location. To the extent you choose to use such External Services, you are solely responsible for compliance with any
					applicable laws. hellocare reserves the right to change, suspend, remove, disable or impose access restrictions or
					limits on any External Services at any time without notice or liability to you.
					<br />
					<br />
					hellocare will use reasonable care and skill with respect to the provision of the Licensed Application and any External
					Services performed or provided by the Licensed Application to you. hellocare does not make any other promises or
					warranties about the External Services and in particular does not warrant that:
					<br />
					<br />
					(i) your use of the External Services will be uninterrupted or error-free;
					<br />
					<br />
					(ii) the External Services will be free from loss, corruption, attack, viruses, interference, hacking, or other security
					intrusion, and hellocare disclaims any liability relating thereto. You shall be responsible for backing up your own
					system, including any Licensed Application that is stored on your system.
				</p>
				<h3>18. Third-Party Services, Licenses and Copyrights</h3>
				<p>
					Our Services includes software licensed under other open source software. We acknowledge all third party copyrights
					(“External Services”) where they have been used on this site and where we are aware of them being held under third party
					copyright. A list of acknowledged copyrights of our (“External Services”) can be found here: (“Open Source/Third-party
					License List”).
				</p>
				<h3>19. General Information</h3>
				<p>
					Depending on your primary place of residency, this Privacy Policy, its interpretation, and any claims and disputes
					related hereto, shall be governed by laws and the exclusive jurisdiction of the state of Florida.
					<br />
					<br />
					This Privacy Policy was written in English and may be translated into other languages for your convenience. If a
					translated (non-English) version of this Privacy Policy conflicts in any way with the English version, the provisions of
					the English version shall prevail.
				</p>
				<h3>20. Have any Concerns?</h3>
				<p>
					If in any case you suspect a violation of hellocare Policies from our side, a third-party vendor, clients or anyone in
					relation with the Policies published that have an impact in your usage of our services, you can raise your concern via
					our helpline at the email address: support@hellocare.ai.
					<br />
					<br />
					Our reputation as a company that our users can trust is our most valuable asset, and it is up to all of us to make sure
					that we continually earn that trust. All of our communications and other interactions with our users should increase
					their trust in us.
					<br />
					<br />
					Therefore, hellocare prohibits retaliation against any user who reports or participates in an investigation of a
					possible violation of our policies, or the law. If you believe you are being retaliated against, please contact the
					Human Resources & Recruiting Department at hr@hellocare.ai.
				</p>
				<h3>21. Have any Questions?</h3>
				<p>
					If you have any questions (or comments) concerning this Privacy Policy, you are welcome to send us an email at
					support@hellocare.ai and we will make an effort to reply within a reasonable timeframe. By contacting us, you represent
					that you are free to do so and that you will not knowingly provide hellocare with information that infringes upon third
					parties&apos; rights, including any intellectual property rights. You further acknowledge that notwithstanding anything
					herein to the contrary, any and all rights, including intellectual property rights in such information provided, shall
					belong exclusively to hellocare, and hellocare may use or refrain from using any such information at its sole
					discretion. You should not share any Medical Information when communicating with hellocare&apos;s support and customer
					service team. Medical Information should be shared with Clinicians, and the designated support and customer service
					teams are not healthcare professionals and cannot offer medical advice.
				</p>
			</div>
			<div className='footer-lp lp-container' id='about-us'>
				<div className='upper-part-lp'>
					<div className='logo-lp'>
						<Link to='/'>
							<img src={`${healthCareCdnUrl}header/bluelogo-1.svg`} alt='hellocare' />
						</Link>
						<p>
							hellocare powered by Solaborate is an End-to-End telehealth and virtual healthcare delivery platform comprising
							software, hardware and AI. hellocare platform provides solutions Hospitals, Home Care and Primary Care such as
							Virtual Patient Observation/eSitter, Telehealth, Remote Patient Monitoring (RPM), Remote Examination Kit,
							Telemedicine Carts, Alerting, Communications and Workflow Management and AI use cases for fall prevention and
							detections.
						</p>
					</div>
					<div className='links-lp'>
						<div className='about-us-lp'>
							<ul>
								<li>
									<p>
										<span className='bold'>Solaborate Inc</span>
									</p>
									<p>
										<span className='bold'>Address:</span> 2605 Enterprise RD E Suite 150 Clearwater, FL 33759{' '}
									</p>
									<p>
										<span className='bold'>Phone Number:</span> +1 424-362-9965
									</p>
									<p>
										<span className='bold'>Email:</span> support@hellocare.ai
									</p>
									<Link to='/privacy-policy' target='_blank'>
										<p>
											<span className='bold'>Privacy Policy</span>
										</p>
									</Link>
									<Link to='/terms-of-use' target='_blank'>
										<p>
											<span className='bold'>Terms of Use</span>
										</p>
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className='bottom-part-lp'>
					<h1>
						© <span className='bold'>hellocare</span> - Built by{' '}
						<a href='https://www.hellocare.ai' target='_blank' rel='noopener noreferrer' className='blue-lp'>
							Solaborate
						</a>{' '}
						{new Date().getFullYear()} | All Rights Reserved
					</h1>
				</div>
			</div>{' '}
		</div>
	</div>
);

export default PrivacyPolicy;
