const Throat = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} viewBox='0 0 24 24' fill='none' {...props}>
		<path
			d='M5.50003 10.5C5.50003 6.51417 8.83783 3 12.8062 3C16.7687 3 19.9971 6.245 20 10.2308C20 12.6934 18.5373 14.6575 16.5 16V21.0008H8.49988V18H5.99988V14.5H3.49988L5.50003 10.5Z'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinejoin='round'
		/>
		<path d='M12.4999 21V18C12.4999 16.8954 11.6044 16 10.4999 16H5.99988' stroke='currentColor' strokeWidth={1.5} />
	</svg>
);
export default Throat;
