import React, { useEffect, useRef, useState } from 'react';
import { timeDifference } from 'infrastructure/helpers/dateHelper.js';

const DisabledCountDown = ({ disabledTime, setDisabledTime }) => {
	const timeOut = useRef(null);
	const [timeLeft, setTimeLeft] = useState('');

	useEffect(() => {
		timeOut.current = setInterval(() => {
			const now = new Date();
			const difference = timeDifference(now, disabledTime.createdAt);
			const result = disabledTime.disableTime * 60 + 2 - difference;
			if (result < 0) {
				setDisabledTime();
				return;
			}
			const minutes = Math.floor(result / 60)
				.toString()
				.padStart(2, '0');
			const seconds = Math.floor(result % 60)
				.toString()
				.padStart(2, '0');
			setTimeLeft(`${minutes}:${seconds}`);
		}, 1000);
		return () => clearInterval(timeOut.current);
	}, [disabledTime]);

	return <span>{timeLeft}</span>;
};

export default DisabledCountDown;
