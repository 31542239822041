import React from 'react';

const SkeletonLoader = props => (
	<div className='skeleton-loader' style={{ padding: props.padding }}>
		{Array.from(Array(props.rows).keys()).map(row => (
			<div key={row} />
		))}
	</div>
);

export default SkeletonLoader;
