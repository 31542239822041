const PrecautionsIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width={16} height={16} fill='none' color='#fff' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M7.208 5.158c0-.32-.258-.58-.576-.58a.578.578 0 0 0-.576.58v4.935L4.98 9.139a.588.588 0 0 0-.808.026.598.598 0 0 0 .005.847s1.59 1.532 2.742 2.693c.094.094.19.18.288.258v1.701m0-6.603V4.578c0-.32.258-.58.577-.58.318 0 .576.26.576.58V8.06 5.158c0-.32.258-.58.576-.58.319 0 .577.26.577.58v2.903-1.742c0-.32.258-.58.576-.58.319 0 .577.26.577.58v5.225c-.08.555-.287 1.002-.577 1.328v1.792'
		/>
		<rect
			width={13.333}
			height={13.333}
			x={1.334}
			y={1.332}
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			rx={2}
		/>
	</svg>
);
export default PrecautionsIcon;
