import React, { useMemo, useState } from 'react';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import Button from 'components/Button.jsx';
import Alert from 'components/Alert.jsx';
import classNames from 'classnames';
import translate from 'i18n-translations/translate.jsx';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getBase64 } from 'infrastructure/helpers/commonHelpers.js';
import { createNurseCallEvent, updateNurseCallEvent } from 'api/badgeAuthorization.js';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const BadgeModal = ({
	title,
	selectedNurseCallEvent,
	setSelectedNurseCallEvent,
	isLoading,
	setIsModalOpen,
	fetchNurseCallEvents,
}) => {
	const intl = useIntl();
	const userSession = useSelector(state => state.user.userSession);
	const [error, setError] = useState(null);

	const handleCloseModal = () => {
		setIsModalOpen(false);
		resetForm({});
		setSelectedNurseCallEvent(null);
		setError(null);
	};

	const initialValues = useMemo(() => {
		return {
			...(selectedNurseCallEvent ?? {}),
			employeeId: selectedNurseCallEvent?.employeeId || '',
			firstName: selectedNurseCallEvent?.firstName || '',
			lastName: selectedNurseCallEvent?.lastName || '',
			title: selectedNurseCallEvent?.title || '',
			accessRFID: selectedNurseCallEvent?.accessRFID || '',
			nurseCallSystemBadgeId: selectedNurseCallEvent?.nurseCallSystemBadgeId || '',
			imageUrl: selectedNurseCallEvent?.imageUrl || '',
			isActive: selectedNurseCallEvent?.isActive || false,
			hasPacsAccess: selectedNurseCallEvent?.hasPacsAccess || false,
			fileName: selectedNurseCallEvent?.fileName || '',
			file: null,
		};
	}, [selectedNurseCallEvent?.id]);

	const getValidationSchema = () => {
		return Yup.object().shape({
			employeeId: Yup.string()
				.nullable()
				.required(intl.formatMessage({ id: 'employeeIdCannotBeEmpty' })),
			firstName: Yup.string()
				.nullable()
				.required(intl.formatMessage({ id: 'firstNameCannotBeEmpty' })),
			lastName: Yup.string()
				.nullable()
				.required(intl.formatMessage({ id: 'lastNameCannotBeEmpty' })),
		});
	};

	const {
		values,
		errors,
		handleSubmit: handleFormikSubmit,
		resetForm,
		setFieldValue,
		setValues,
		isSubmitting,
		setSubmitting,
	} = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema: getValidationSchema(),
		onSubmit: values => handleSubmit(values),
	});

	const handleSubmit = async data => {
		setError(null);
		const isEditMode = !!selectedNurseCallEvent?.id;
		const response = isEditMode
			? await updateNurseCallEvent(data)
			: await createNurseCallEvent({ ...data, healthSystemId: userSession.healthSystem?.id });
		setSubmitting(false);
		if (response.error) {
			setError(response.error?.response?.data?.message);
			return;
		}
		fetchNurseCallEvents();
		handleCloseModal();
	};

	const onImageAdded = async event => {
		setError(null);
		const file = event.target.files[0];
		const url = await getBase64(file);
		setValues({
			...values,
			file,
			imageUrl: url,
		});
	};

	return (
		<Modal
			position='right'
			modalSelector='editSectorModal'
			display={true}
			onModalSubmit={handleFormikSubmit}
			onModalClose={handleCloseModal}
			className={classNames('assign-doctor-modal add-user-modal', { 'none-pointer-events': isLoading })}
			shouldSubmitOnEnter={false}
			isSubmitDisabled={isSubmitting}
			primaryButtonLoading={isSubmitting}>
			<Form className={classNames({ disabled: isLoading })} title={title} onSubmit={e => e.preventDefault()}>
				<div className='mi-input-wrapper'>
					<label>{intl.formatMessage({ id: 'employeeId' })}</label>
					<input
						type='text'
						onChange={event => {
							if (event.target.value.length > 16) {
								return;
							}
							setFieldValue('employeeId', event.target.value);
						}}
						value={values?.employeeId}
						placeholder={intl.formatMessage({ id: 'employeeId' })}
					/>
					{errors?.employeeId && <small>{errors.employeeId}</small>}
				</div>
				<div className='mi-input-wrapper'>
					<label>{intl.formatMessage({ id: 'name' })}</label>
					<input
						type='text'
						onChange={event => setFieldValue('firstName', event.target.value)}
						value={values?.firstName}
						placeholder={intl.formatMessage({ id: 'name' })}
					/>
					{errors?.firstName && <small>{errors.firstName}</small>}
				</div>
				<div className='mi-input-wrapper'>
					<label>{intl.formatMessage({ id: 'lastName' })}</label>
					<input
						type='text'
						onChange={event => setFieldValue('lastName', event.target.value)}
						value={values?.lastName}
						placeholder={intl.formatMessage({ id: 'lastName' })}
					/>
					{errors?.lastName && <small>{errors.lastName}</small>}
				</div>
				<div className='mi-input-wrapper'>
					<label>{intl.formatMessage({ id: 'title' })}</label>
					<input
						type='text'
						onChange={event => setFieldValue('title', event.target.value)}
						value={values?.title}
						placeholder={intl.formatMessage({ id: 'title' })}
					/>
				</div>
				<div className='mi-input-wrapper'>
					<label>{intl.formatMessage({ id: 'proxBadge' })}</label>
					<input
						type='text'
						onChange={event => {
							if (event.target.value.length > 10) {
								return;
							}
							setFieldValue('accessRFID', event.target.value);
						}}
						value={values?.accessRFID}
						placeholder={intl.formatMessage({ id: 'proxBadge' })}
					/>
				</div>
				<div className='mi-input-wrapper'>
					<label>{intl.formatMessage({ id: 'centrakBadge' })}</label>
					<input
						type='text'
						onChange={event => {
							if (event.target.value.length > 10) {
								return;
							}
							setFieldValue('nurseCallSystemBadgeId', event.target.value);
						}}
						value={values?.nurseCallSystemBadgeId}
						placeholder={intl.formatMessage({ id: 'centrakBadge' })}
					/>
				</div>

				<div className='badge-radio-btn flex'>
					<h5>{translate('status')}:</h5>
					<div className='flex'>
						<input
							type='radio'
							id='radio-active'
							name='isActive'
							onChange={() => setFieldValue('isActive', true)}
							checked={values?.isActive}
						/>
						<label htmlFor='radio-active'>{translate('active')}</label>
					</div>
					<div className='flex'>
						<input
							type='radio'
							id='radio-inactive'
							name='isActive'
							onChange={() => setFieldValue('isActive', false)}
							checked={!values?.isActive}
						/>
						<label htmlFor='radio-inactive'>{translate('inactive')}</label>
					</div>
				</div>

				<div className='badge-radio-btn flex'>
					<h5>PAC {translate('status')}:</h5>
					<div className='flex'>
						<input
							type='radio'
							id='radio-pacactive'
							name='hasPacsAccess'
							onChange={() => setFieldValue('hasPacsAccess', true)}
							checked={values?.hasPacsAccess}
						/>
						<label htmlFor='radio-pacactive'>{translate('active')}</label>
					</div>
					<div className='flex'>
						<input
							type='radio'
							id='radio-pacinactive'
							name='hasPacsAccess'
							onChange={() => setFieldValue('hasPacsAccess', false)}
							checked={!values?.hasPacsAccess}
						/>
						<label htmlFor='radio-pacinactive'>{translate('inactive')}</label>
					</div>
				</div>

				<div className='badge-authorization'>
					<h5>{intl.formatMessage({ id: 'userPicture' })}</h5>
					{!values?.imageUrl && (
						<div className='add-background-image'>
							<label htmlFor='image-upload'>
								<img src={`${healthCareCdnUrl}admin/add-image-icon.svg`} alt='icon' />
								<span className='virtual-background-text'>{translate('addImage')}</span>
							</label>
							<input
								type='file'
								accept='image/png, image/jpeg'
								id='image-upload'
								onChange={onImageAdded}
								className='display-none'
							/>
						</div>
					)}
					{values?.imageUrl && (
						<div className='background-item'>
							<>
								<img src={values?.imageUrl} alt={intl.formatMessage({ id: 'backgroundImage' })} />
								<Button
									size='small'
									svgIcon={<i className='fa fa-trash'></i>}
									className='delete-img-button'
									type='button'
									onClick={() =>
										setValues({
											...values,
											file: null,
											imageUrl: '',
											fileName: '',
										})
									}
								/>
							</>
						</div>
					)}
				</div>
			</Form>
			<Alert display={error} message={error} persist variant='dark' fixed={true} onClose={() => setError(null)} />
		</Modal>
	);
};

export default BadgeModal;
