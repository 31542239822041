import * as React from 'react';

const Attachment = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			d='m15.25 10.643-4.66 4.66a4.167 4.167 0 0 1-5.893 0v0a4.167 4.167 0 0 1 0-5.892l5.45-5.45a3.125 3.125 0 0 1 4.42 0v0a3.125 3.125 0 0 1 0 4.419l-5.304 5.303a1.875 1.875 0 0 1-2.651 0v0a1.875 1.875 0 0 1 0-2.652l4.941-4.942'
		/>
	</svg>
);
export default Attachment;
