import React, { useState } from 'react';
import Grid from 'components/Grid.jsx';
import ContinueAs from 'containers/ContinueAs.jsx';
import Button from 'components/Button.jsx';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { useSelector } from 'react-redux';

const Unauthorized = props => {
	const userRoles = useSelector(state => state.user.userRoles);
	const intl = useIntl();
	const history = useHistory();
	const [showSwitchRole, setShowSwitchRole] = useState(false);

	const translator = id => intl.formatMessage({ id });
	const signOut = () => history.push('/logout');

	if (showSwitchRole) {
		return <ContinueAs />;
	}

	return (
		<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
			<div className='member-error'>
				<h3>
					{translator(
						props.featureFlagsDisabled && !props.isAuthorized ? 'allRoleCustomizationsDisabled' : 'notAuthorizedToAccessThisPage'
					)}
				</h3>
				<img src={`${healthCareCdnUrl}empty-state/member-error.svg`} alt='member-error' />
				<p>{translator('ifYouThinkThereAreMistakes')}</p>
				{userRoles.length === 1 && (
					<span className='logout-link' onClick={signOut}>
						{translator('clickToLoginAnotherAcc')}
					</span>
				)}
				{userRoles.length > 1 && (
					<>
						<Button text={translator('switchRole')} onClick={() => setShowSwitchRole(true)} variant='white' />{' '}
						<Button text={translator('logOut')} onClick={signOut} />
					</>
				)}
			</div>
		</Grid>
	);
};

export default Unauthorized;
