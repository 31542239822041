const IntegratedApps = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} fill='none' viewBox='0 0 24 24' {...props}>
		<rect width={5.5} height={5.5} x={13.75} y={2.75} stroke='currentColor' strokeWidth={1.5} rx={1} />
		<rect width={5.5} height={5.5} x={2.75} y={13.75} stroke='currentColor' strokeWidth={1.5} rx={1} />
		<path stroke='currentColor' strokeLinecap='round' strokeWidth={1.5} d='M9.625 4.125h-3.5a2 2 0 00-2 2v1.667' />
		<path
			fill='currentColor'
			stroke='currentColor'
			strokeWidth={1.5}
			d='M4.245 9.007L5.32 7.573a.15.15 0 00-.12-.24H3.05a.15.15 0 00-.12.24l1.075 1.434c.06.08.18.08.24 0z'
		/>
		<path stroke='currentColor' strokeLinecap='round' strokeWidth={1.5} d='M12.375 17.875h3.5a2 2 0 002-2v-1.667' />
		<path
			fill='currentColor'
			stroke='currentColor'
			strokeWidth={1.5}
			d='M17.755 12.993l-1.075 1.434a.15.15 0 00.12.24h2.15a.15.15 0 00.12-.24l-1.075-1.434a.15.15 0 00-.24 0z'
		/>
	</svg>
);

export default IntegratedApps;
