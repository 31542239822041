import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Expand = props => (
	<svg
		width='25'
		height='24'
		viewBox='0 0 25 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		color={LightTheme.colors.graySeven}
		{...props}>
		<path d='M15.5 9L21.5 3' stroke='currentColor' strokeWidth='1.5' strokeMiterlimit='10' />
		<path d='M21.5 9V3H15.5' stroke='currentColor' strokeWidth='1.5' strokeLinejoin='round' />
		<path d='M9.5 15L3.5 21' stroke='currentColor' strokeWidth='1.5' strokeMiterlimit='10' />
		<path d='M3.5 15V21H9.5' stroke='currentColor' strokeWidth='1.5' strokeLinejoin='round' />
		<path
			d='M11.4999 5.99976H8.49988C7.96944 5.99976 7.46089 6.2105 7.08582 6.58557C6.71074 6.96064 6.49988 7.46932 6.49988 7.99976V11.9998'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeMiterlimit='10'
		/>
		<path
			d='M12.4999 17.9998H16.4999C17.0303 17.9998 17.5391 17.789 17.9142 17.414C18.2893 17.0389 18.4999 16.5302 18.4999 15.9998V11.9998'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeMiterlimit='10'
		/>
	</svg>
);

export default Expand;
