import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { PatientAiSetting } from 'constants/enums.js';
import Alert from 'components/Alert.jsx';
import classNames from 'classnames';
import {
	AiModelCheckTimers,
	DispenserTimers,
	initialAiConfigurations,
	SinkTimers,
	PressureInjuryEvidenceTimers,
} from 'infrastructure/helpers/aiHelper.js';
import { validateDecimalNumber } from 'infrastructure/helpers/commonHelpers.js';
import Select from 'react-select';
import AiConfigCheckboxList from 'components/AiConfigCheckboxList.jsx';
import { FallPreventionSettingTypes } from 'constants/ai.js';
import AiVoiceAnalysisConfigs from 'components/AiVoiceAnalysisConfigs.jsx';
import AiConfigRadioButton from 'components/Configurations/AI/AiConfigRadioButton.jsx';

const AiConfigurationFeatureFlags = ({ onAiConfigsChange, settingTypeId, selectedAiConfig, aiErrors, isAdminView = false }) => {
	const intl = useIntl();
	const aiModelCheckTimer = useRef(AiModelCheckTimers(intl));
	const dispenserTimer = useRef(DispenserTimers(intl));
	const sinkTimer = useRef(SinkTimers(intl));
	const pressureInjuryEvidenceTimer = useRef(PressureInjuryEvidenceTimers(intl));
	const [error, setError] = useState(null);
	const [selectedAiConfiguration, setSelectedAiConfiguration] = useState(
		selectedAiConfig.aiConfigurations ||
			initialAiConfigurations(intl).find(item => item.aiConfigurationTypeId === selectedAiConfig.aiConfigurationTypeId)
	);
	const [foundAiErrors, setFoundAiErrors] = useState(null);

	useEffect(() => {
		setFoundAiErrors(
			aiErrors.length > 0
				? aiErrors.find(item => item.aiConfigurationTypeId === selectedAiConfig.aiConfigurationTypeId)?.errors
				: null
		);
	}, [aiErrors, selectedAiConfig]);

	const handleCheckboxChange = ({ event, aiConfigValue, arrayName }) => {
		const { checked } = event.target;
		setSelectedAiConfiguration(prevState => {
			const updatedOptions = prevState[arrayName].map(aiOption => {
				if (aiOption.value === aiConfigValue) {
					return { ...aiOption, isChecked: checked, isDefault: checked ? aiOption.isDefault : false };
				}
				return aiOption;
			});
			return { ...prevState, [arrayName]: updatedOptions };
		});
	};

	const handleRadioButtonChange = ({ value, arrayName }) => {
		setSelectedAiConfiguration(prevState => {
			const updatedOptions = prevState[arrayName]?.map(aiOption => {
				return { ...aiOption, isChecked: aiOption.value === value };
			});
			return { ...prevState, [arrayName]: updatedOptions };
		});
	};

	const handleSetAsDefault = (aiConfigValue, arrayName) => {
		setSelectedAiConfiguration(prevState => {
			const updatedOptions = prevState[arrayName].map(aiOption => {
				if (aiOption.value === aiConfigValue) {
					return { ...aiOption, isDefault: true };
				}
				return { ...aiOption, isDefault: false };
			});
			return { ...prevState, [arrayName]: updatedOptions };
		});
	};

	const handleSetMultipleDefaults = (aiConfigValue, arrayName) => {
		setSelectedAiConfiguration(prevState => {
			const updatedOptions = prevState[arrayName].map(aiOption => {
				if (aiOption.value === aiConfigValue) {
					return { ...aiOption, isDefault: !aiOption.isDefault };
				}
				return aiOption;
			});
			return { ...prevState, [arrayName]: updatedOptions };
		});
	};

	const handleThresholdChange = ({ value, minValue, maxValue, index }) => {
		const numericValue = value ? parseFloat(value) : null;
		if (!/^\d*\.?\d{0,2}$/.test(value) || numericValue < minValue || numericValue > maxValue) {
			return;
		}
		setSelectedAiConfiguration(prevState => {
			const updatedThresholds = [...prevState.thresholdValues];
			updatedThresholds[index] = { ...updatedThresholds[index], value: numericValue };
			return { ...prevState, thresholdValues: updatedThresholds };
		});
	};

	const handleTimerChange = (item, property) => {
		setSelectedAiConfiguration(prevState => ({
			...prevState,
			[property]: item.value,
		}));
	};

	const handleDetectionTimesChange = ({ item, arrayName, aiConfigValue }) => {
		setSelectedAiConfiguration(prevState => {
			const updatedOptions = prevState[arrayName].map(aiOption => {
				if (aiOption.aiAudioAnalysisEvent === aiConfigValue) {
					return { ...aiOption, detectionTimes: item.value };
				}
				return aiOption;
			});
			return { ...prevState, [arrayName]: updatedOptions };
		});
	};

	useEffect(() => {
		onAiConfigsChange({ key: settingTypeId, aiConfigs: selectedAiConfiguration });
	}, [selectedAiConfiguration]);

	return (
		<div className={classNames('ai-feature-flags', selectedAiConfig.value ? '' : 'disabled')}>
			<div>
				{selectedAiConfig.aiConfigurationTypeId === PatientAiSetting.PATIENT_GETTING_OUT_OF_BED && (
					<>
						<AiConfigCheckboxList
							array={selectedAiConfiguration.values}
							onCheckboxChange={handleCheckboxChange}
							onSetAsDefault={handleSetAsDefault}
							arrayName='values'
							subtitle='thresholdValues'
							errors={foundAiErrors?.values}
						/>
						<div className='top-15'>
							<div className='flex'>
								{selectedAiConfiguration.thresholdValues?.map((item, index) => (
									<div className='column-direction margin-right-m' key={index}>
										<input
											key={index}
											type='number'
											value={item.value || ''}
											onChange={event =>
												handleThresholdChange({ value: event.target.value, minValue: 0, maxValue: 10000, index })
											}
											onKeyDown={event => validateDecimalNumber(event, 5)}
											placeholder={intl.formatMessage({ id: 'enterNumber' })}
											className='ai-threshold-input number-input-wo-arrows'
										/>
									</div>
								))}
							</div>
							{foundAiErrors?.thresholdValues && (
								<span className='red-error flex margin-top-s'>{foundAiErrors?.thresholdValues}</span>
							)}
						</div>
					</>
				)}

				{selectedAiConfig.aiConfigurationTypeId === PatientAiSetting.RAILS && (
					<>
						<AiConfigCheckboxList
							array={selectedAiConfiguration.values}
							onCheckboxChange={handleCheckboxChange}
							onSetAsDefault={handleSetMultipleDefaults}
							arrayName='values'
							subtitle='sendRailsAlert'
							errors={foundAiErrors?.values}
						/>
						<div className='top-15'>
							<div className='flex'>
								{selectedAiConfiguration.thresholdValues?.map((item, index) => (
									<div className='column-direction margin-right-m' key={index}>
										<p className='bottom-5'>{translate(index === 0 ? 'thresholdsForRailDown' : 'thresholdsForRailUp')}</p>
										<input
											type='number'
											value={item.value || ''}
											onChange={event => handleThresholdChange({ value: event.target.value, minValue: 0, maxValue: 100, index })}
											onKeyDown={event => validateDecimalNumber(event, 3)}
											placeholder={intl.formatMessage({ id: 'enterNumber' })}
											className='ai-threshold-input number-input-wo-arrows'
										/>
									</div>
								))}
							</div>
							{foundAiErrors?.thresholdValues && (
								<span className='red-error flex margin-top-s'>{foundAiErrors?.thresholdValues}</span>
							)}
						</div>

						<div className='top-15 ai-feature-flag-select'>
							<p className='bottom-5'>{translate('aiModelCheckTimer')}</p>
							<Select
								options={aiModelCheckTimer?.current}
								value={aiModelCheckTimer.current.find(item => item.value === selectedAiConfiguration.aiModelCheckTimer)}
								placeholder={translate('selectTimer')}
								onChange={item => handleTimerChange(item, 'aiModelCheckTimer')}
								classNamePrefix='custom-select'
							/>
							{foundAiErrors?.aiModelCheckTimer && (
								<span className='red-error flex margin-top-s'>{foundAiErrors?.aiModelCheckTimer}</span>
							)}
						</div>

						<div className='top-15'>
							<p className='bottom-5'>{translate('triggerAlertsRailsCovered')}</p>
							<div className='flex flex-wrap margin-top-s'>
								{selectedAiConfiguration.coveredRailsTriggerAlert?.map(aiConfig => (
									<AiConfigRadioButton
										key={aiConfig.value}
										aiConfig={aiConfig}
										arrayName='coveredRailsTriggerAlert'
										radioButtonName={`railCovered${selectedAiConfig.aiConfigurationTypeId}`}
										handleRadioButtonChange={handleRadioButtonChange}
									/>
								))}
							</div>
							{foundAiErrors?.coveredRailsTriggerAlert && (
								<span className='red-error flex margin-top-s'>{foundAiErrors?.coveredRailsTriggerAlert}</span>
							)}
						</div>
						{selectedAiConfiguration.coveredRailsTriggerAlert.some(item => !item.value && item.isChecked) && (
							<AiConfigCheckboxList
								array={selectedAiConfiguration.alertIfRailCovered}
								onCheckboxChange={handleCheckboxChange}
								onSetAsDefault={handleSetAsDefault}
								arrayName='alertIfRailCovered'
								shouldSetAsDefault={false}
							/>
						)}
					</>
				)}

				{selectedAiConfig.aiConfigurationTypeId === PatientAiSetting.FALL_DETECTED &&
					selectedAiConfiguration.thresholdValues?.map((item, index) => (
						<div className='top-15 flex column-direction' key={index}>
							<p className='bottom-5'>{translate('thresholdValues')}</p>
							<input
								type='number'
								value={item.value || ''}
								onChange={event => handleThresholdChange({ value: event.target.value, minValue: 0, maxValue: 100, index })}
								onKeyDown={event => validateDecimalNumber(event, 3)}
								placeholder={intl.formatMessage({ id: 'enterNumber' })}
								className='ai-threshold-input number-input-wo-arrows'
							/>
						</div>
					))}

				{foundAiErrors?.thresholdValues && selectedAiConfig.aiConfigurationTypeId === PatientAiSetting.FALL_DETECTED && (
					<span className='red-error flex margin-top-s'>{foundAiErrors?.thresholdValues}</span>
				)}

				{selectedAiConfig.aiConfigurationTypeId === PatientAiSetting.AI_VOICE_ANALYSIS && (
					<AiVoiceAnalysisConfigs
						configData={{
							values: selectedAiConfiguration.values,
							valuesName: 'values',
							detections: selectedAiConfiguration.aiAudioAnalysisDetections,
							detectionsName: 'aiAudioAnalysisDetections',
						}}
						errors={foundAiErrors?.values}
						onCheckboxChange={handleCheckboxChange}
						onSetAsDefault={handleSetMultipleDefaults}
						onDetectionTimesChange={handleDetectionTimesChange}
						isAdminView={isAdminView}
					/>
				)}

				{FallPreventionSettingTypes.includes(selectedAiConfig.aiConfigurationTypeId) && (
					<div className='top-15'>
						<p className='bottom-5'>{translate('triggerAlertsMoreThanOnePerson')}</p>
						<div className='flex flex-wrap margin-top-s'>
							{selectedAiConfiguration.notAloneTriggerAlert.map(aiConfig => (
								<AiConfigRadioButton
									key={aiConfig.value}
									aiConfig={aiConfig}
									arrayName='notAloneTriggerAlert'
									radioButtonName={`notAloneTriggerAlert${selectedAiConfig.aiConfigurationTypeId}`}
									handleRadioButtonChange={handleRadioButtonChange}
								/>
							))}
						</div>
						{foundAiErrors?.notAloneTriggerAlert && (
							<span className='red-error flex margin-top-s'>{foundAiErrors?.notAloneTriggerAlert}</span>
						)}
					</div>
				)}
				{selectedAiConfig.aiConfigurationTypeId === PatientAiSetting.PRESSURE_INJURY && (
					<>
						<AiConfigCheckboxList
							array={selectedAiConfiguration.values}
							onCheckboxChange={handleCheckboxChange}
							onSetAsDefault={handleSetAsDefault}
							arrayName='values'
							header='inactiveTime'
							title={selectedAiConfig.title}
							errors={foundAiErrors?.values}
						/>
						<AiConfigCheckboxList
							array={selectedAiConfiguration.bodyPositionValues}
							onCheckboxChange={handleCheckboxChange}
							onSetAsDefault={handleSetMultipleDefaults}
							arrayName='bodyPositionValues'
							header='bodyPositionToSendAlert'
							title={selectedAiConfig.title}
							errors={foundAiErrors?.bodyPositionValues}
						/>
						<div className='top-15 ai-feature-flag-select'>
							<p className='bottom-5'>{translate('pressureInjuryEvidenceTimer')}</p>
							<Select
								options={pressureInjuryEvidenceTimer?.current}
								value={pressureInjuryEvidenceTimer.current.find(
									item => item.value === selectedAiConfiguration.pressureInjuryEvidenceTimer
								)}
								placeholder={translate('selectTimer')}
								onChange={item => handleTimerChange(item, 'pressureInjuryEvidenceTimer')}
								classNamePrefix='custom-select'
							/>
							{foundAiErrors?.pressureInjuryEvidenceTimer && (
								<span className='red-error flex margin-top-s'>{foundAiErrors?.pressureInjuryEvidenceTimer}</span>
							)}
						</div>
					</>
				)}
				{selectedAiConfig.aiConfigurationTypeId === PatientAiSetting.PATIENT_MOBILITY && (
					<div className='top-15'>
						<p className='bottom-5'>{translate('imbalance')}</p>
						<div className='flex flex-wrap margin-top-s'>
							{selectedAiConfiguration.imbalanceAlert.map(aiConfig => (
								<AiConfigRadioButton
									key={aiConfig.value}
									aiConfig={aiConfig}
									arrayName='imbalanceAlert'
									radioButtonName={`imbalanceAlert${selectedAiConfig.aiConfigurationTypeId}`}
									handleRadioButtonChange={handleRadioButtonChange}
									isImbalanceType
								/>
							))}
						</div>
						{foundAiErrors?.imbalanceAlert && (
							<span className='red-error flex margin-top-s'>{foundAiErrors?.imbalanceAlert}</span>
						)}
					</div>
				)}
				{selectedAiConfig.aiConfigurationTypeId !== PatientAiSetting.HAND_WASHING && (
					<>
						<AiConfigCheckboxList
							array={selectedAiConfiguration.snoozeValues}
							onCheckboxChange={handleCheckboxChange}
							onSetAsDefault={handleSetAsDefault}
							arrayName='snoozeValues'
							header='snoozeTimerForAlert'
							title={selectedAiConfig.title}
							errors={foundAiErrors?.snoozeValues}
						/>

						<AiConfigCheckboxList
							array={selectedAiConfiguration.alerts}
							onCheckboxChange={handleCheckboxChange}
							onSetAsDefault={handleSetAsDefault}
							arrayName='alerts'
							header='alerts'
							title={selectedAiConfig.title}
							errors={foundAiErrors?.alerts}
						/>

						{(!isAdminView || selectedAiConfiguration.statAlerts.length > 0) && (
							<AiConfigCheckboxList
								array={selectedAiConfiguration.statAlerts}
								onCheckboxChange={handleCheckboxChange}
								onSetAsDefault={handleSetAsDefault}
								arrayName='statAlerts'
								header='statAlarmActivation'
								title={selectedAiConfig.title}
								errors={foundAiErrors?.statAlerts}
							/>
						)}
					</>
				)}

				<div className='top-15'>
					<p className='bottom-5'>{translate('sendAlertsToCareNotifications')}</p>
					<div className='flex flex-wrap margin-top-s'>
						{selectedAiConfiguration.sendAlertsToCareNotifications?.map(aiConfig => (
							<AiConfigRadioButton
								key={aiConfig.value}
								aiConfig={aiConfig}
								arrayName='sendAlertsToCareNotifications'
								radioButtonName={`careNotifications${selectedAiConfig.aiConfigurationTypeId}`}
								handleRadioButtonChange={handleRadioButtonChange}
							/>
						))}
					</div>
					{foundAiErrors?.sendAlertsToCareNotifications && (
						<span className='red-error flex margin-top-s'>{foundAiErrors?.sendAlertsToCareNotifications}</span>
					)}
				</div>

				{selectedAiConfig.aiConfigurationTypeId === PatientAiSetting.HAND_WASHING && (
					<>
						<div className='top-15'>
							<p className='bottom-5'>{translate('showNonCompliantData')}</p>
							<div className='flex flex-wrap margin-top-s'>
								{selectedAiConfiguration.saveNonCompliantAudits?.map(aiConfig => (
									<AiConfigRadioButton
										key={aiConfig.value}
										aiConfig={aiConfig}
										arrayName='saveNonCompliantAudits'
										radioButtonName={`saveNonCompliantAudits${selectedAiConfig.aiConfigurationTypeId}`}
										handleRadioButtonChange={handleRadioButtonChange}
									/>
								))}
							</div>
							{foundAiErrors?.saveNonCompliantAudits && (
								<span className='red-error flex margin-top-s'>{foundAiErrors?.saveNonCompliantAudits}</span>
							)}
						</div>
						<div className='top-15 ai-feature-flag-select'>
							<p className='bottom-5'>{translate('dispenserTimer')}</p>
							<Select
								options={dispenserTimer?.current}
								value={dispenserTimer.current.find(item => item.value === selectedAiConfiguration.dispenserTimer)}
								placeholder={translate('selectTimer')}
								onChange={item => handleTimerChange(item, 'dispenserTimer')}
								classNamePrefix='custom-select'
							/>
							<p className='hand-wash-timer'>{translate('configureDisinfectTimer', { value: 'dispenser' })}</p>
							{foundAiErrors?.dispenserTimer && (
								<span className='red-error flex margin-top-s'>{foundAiErrors?.dispenserTimer}</span>
							)}
						</div>
						<div className='top-15 ai-feature-flag-select'>
							<p className='bottom-5'>{translate('sinkTimer')}</p>
							<Select
								options={sinkTimer?.current}
								value={sinkTimer.current.find(item => item.value === selectedAiConfiguration.sinkTimer)}
								placeholder={translate('selectTimer')}
								onChange={item => handleTimerChange(item, 'sinkTimer')}
								classNamePrefix='custom-select'
							/>
							<p className='hand-wash-timer'>{translate('configureDisinfectTimer', { value: 'sink' })}</p>
							{foundAiErrors?.sinkTimer && <span className='red-error flex margin-top-s'>{foundAiErrors?.sinkTimer}</span>}
						</div>
					</>
				)}
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</div>
	);
};

export default AiConfigurationFeatureFlags;
