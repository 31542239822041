import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Hdmi = props => {
	return (
		<svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' color={LightTheme.colors.graySeven} {...props}>
			<path
				d='M3 9a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v3.429a1 1 0 0 1-1 1h-1.955a1 1 0 0 0-.79.386l-1.4 1.799a1 1 0 0 1-.789.386H8.934a1 1 0 0 1-.79-.386l-1.4-1.8a1 1 0 0 0-.789-.385H4a1 1 0 0 1-1-1V9Z'
				stroke="currentColor"
				strokeWidth={1.5}
			/>
			<path d='M6 10.5h12' stroke="currentColor" strokeWidth={1.5} strokeLinecap='round' />
		</svg>
	);
};

export default Hdmi;