import React from 'react';

const HelloIcon = props => (
	<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M1.40039 8.01367C1.65244 9.31121 2.35012 10.4798 3.37272 11.3173C4.39532 12.1548 5.6785 12.6085 7.00026 12.6C8.32202 12.6085 9.60521 12.1548 10.6278 11.3173C11.6504 10.4798 12.3481 9.31121 12.6001 8.01367'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
		/>
		<path
			d='M1.40039 5.98681C1.65244 4.68927 2.35012 3.52066 3.37272 2.68315C4.39532 1.84564 5.6785 1.39193 7.00026 1.40051C8.32202 1.39193 9.60521 1.84564 10.6278 2.68315C11.6504 3.52066 12.3481 4.68927 12.6001 5.98681'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
		/>
	</svg>
);

export default HelloIcon;
