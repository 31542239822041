import { addDeviceConfiguration } from 'api/deviceConfig.js';
import { getHospitalFloors } from 'api/floors.js';
import { getHospitalDepartments } from 'api/healthSystems.js';
import { getHospitalRooms } from 'api/rooms.js';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import { Alert, Form, Input, Modal } from 'components/index.js';
import { EndCallSource, TVs } from 'constants/configurationEnums.js';
import { DeviceListLevel, SectorTypes, TreeHierarchyType, TreeHierarchyTypeItems } from 'constants/enums.js';
import { Formik } from 'formik';
import translate from 'i18n-translations/translate.jsx';
import { getCompanyId } from 'infrastructure/auth.js';
import { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import * as Yup from 'yup';

const TVConfig = props => {
	const intl = useIntl();
	const [error, setError] = useState(null);
	const translator = id => intl.formatMessage({ id });
	const [shouldShowEndCallOptions, setShouldShowEndCallOptions] = useState(false);
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const defaultState = { value: '0', label: intl.formatMessage({ id: 'all' }) };
	const [healthSystems, setHealthSystems] = useState([defaultState]);
	const [hospitals, setHospitals] = useState([defaultState]);
	const [departments, setDepartments] = useState([defaultState]);
	const [floors, setFloors] = useState([defaultState]);
	const [rooms, setRooms] = useState([defaultState]);
	const [floorsToFilter, setFloorsToFilter] = useState([]);
	const [roomsToFilter, setRoomsToFilter] = useState([]);
	const [hierarchyItems, setHierarchyItems] = useState(TreeHierarchyTypeItems[TreeHierarchyType.DEFAULT_TREE]);
	const [hierarchyType, setHierarchyType] = useState(TreeHierarchyType.DEFAULT_TREE);

	const tvTypes = Object.values(TVs);

	const ports = [
		{ id: 1, value: 'HDMI 1' },
		{ id: 2, value: 'HDMI 2' },
		{ id: 3, value: 'HDMI 3' },
	];

	const endCallSource = EndCallSource(intl);
	const endCallOptions = Object.values(endCallSource);

	const fetchHealthSystemHospitals = async healthSystem => {
		const response = await getHealthSystemHospitals(healthSystem.value);

		if (response.error) {
			setError(response.error.message);
			return;
		}
		const hospitals = response?.map(hospital => ({ value: hospital.id, label: hospital.name }));
		setHospitals([defaultState, ...hospitals]);
	};

	const fetchHospitalDepartments = async (healthSystem, hospital) => {
		const response = await getHospitalDepartments(healthSystem.value, hospital.value);

		if (response.error) {
			setError(response.error.message);
			return;
		}
		const departments = response?.hospital?.departments?.map(department => ({
			value: department.id,
			label: department.name,
		}));
		const allFloors = response.hospital.departments.flatMap(item => item.floors.map(floor => ({ ...floor, parentId: item.id })));
		const allRooms = allFloors.flatMap(item =>
			item.rooms.map(room => ({ ...room, parentId: item.id, departmentId: item.parentId }))
		);
		setRoomsToFilter(allRooms);
		setFloorsToFilter(allFloors);
		setDepartments([defaultState, ...departments]);
	};

	const fetchHospitalFloors = async (healthSystem, hospital) => {
		const response = await getHospitalFloors(getCompanyId(), healthSystem.value, hospital.value);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		const floors = response.floors.map(floor => ({ value: floor.id, label: floor.name }));
		setFloors([defaultState, ...floors]);
	};

	const fetchHospitalRooms = async (healthSystem, hospital, floor = null) => {
		const response = await getHospitalRooms(getCompanyId(), healthSystem.value, hospital.value);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		const rooms = response.rooms
			.filter(room => !floor || room.floorId === floor.value)
			.map(room => ({ value: room.id, label: room.name }));

		setRooms([defaultState, ...rooms]);
	};

	useEffect(() => {
		if (!allHealthSystems) {
			return;
		}
		const transformedHealthSystems = allHealthSystems.map(item => ({ value: item.id, label: item.name, ...item }));
		setHealthSystems(transformedHealthSystems);
	}, [allHealthSystems]);

	const handleHealthSystemChange = async (healthSystem, setFieldValue) => {
		setFieldValue('selectedHospital', defaultState);

		setDepartments([defaultState]);
		setFieldValue('selectedDepartment', defaultState);

		setFloors([defaultState]);
		setFieldValue('selectedFloor', defaultState);

		setRooms([defaultState]);
		setFieldValue('selectedRoom', defaultState);

		setHierarchyType(healthSystem.treeHierarchyTypeId);
		setHierarchyItems(TreeHierarchyTypeItems[healthSystem.treeHierarchyTypeId]);

		setFieldValue('selectedHealthSystem', healthSystem);
		await fetchHealthSystemHospitals(healthSystem);
	};

	const handleHospitalChange = async (hospital, healthSystem, setFieldValue) => {
		setFieldValue('selectedHospital', hospital);

		setDepartments([defaultState]);
		setFieldValue('selectedDepartment', defaultState);

		setFloors([defaultState]);
		setFieldValue('selectedFloor', defaultState);

		setRooms([defaultState]);
		setFieldValue('selectedRoom', defaultState);

		if (hospital.value === '0') {
			return;
		}

		if (hierarchyType === TreeHierarchyType.HOSPITAL_ROOM) {
			await fetchHospitalRooms(healthSystem, hospital);
		} else if (hierarchyType === TreeHierarchyType.HOSPITAL_FLOOR_ROOM) {
			await fetchHospitalFloors(healthSystem, hospital);
		} else {
			await fetchHospitalDepartments(healthSystem, hospital);
		}
	};

	const handleDepartmentChange = async (department, setFieldValue) => {
		setFieldValue('selectedDepartment', department);

		setFloors([defaultState]);
		setFieldValue('selectedFloor', defaultState);

		setRooms([defaultState]);
		setFieldValue('selectedRoom', defaultState);

		if (department.value === '0') {
			return;
		}

		if (hierarchyType === TreeHierarchyType.HOSPITAL_DEPT_ROOM) {
			const filteredRooms = roomsToFilter
				.filter(room => room.departmentId === department.value)
				.map(item => ({ value: item.id, label: item.name }));
			setRooms([defaultState, ...filteredRooms]);
		} else {
			const filteredFloors = floorsToFilter
				.filter(floor => floor.parentId === department.value)
				.map(item => ({ value: item.id, label: item.name }));
			setFloors([defaultState, ...filteredFloors]);
		}
	};

	const handleFloorChange = async (floor, values, setFieldValue) => {
		setFieldValue('selectedFloor', floor);

		setRooms([defaultState]);
		setFieldValue('selectedRoom', defaultState);

		if (floor.value === '0') {
			return;
		}

		if (hierarchyType === TreeHierarchyType.HOSPITAL_FLOOR_ROOM) {
			await fetchHospitalRooms(values.selectedHealthSystem, values.selectedHospital, floor);
		} else {
			const filteredRooms = roomsToFilter
				.filter(room => room.parentId === floor.value)
				.map(item => ({ value: item.id, label: item.name }));
			setRooms([defaultState, ...filteredRooms]);
		}
	};

	const getInitialValues = () => {
		if (props.initialValues) {
			const { level, teamConfigurationProfile, tv, hdmiPort, defaultSource, ringtoneVolume } = props.initialValues;

			return {
				...props.initialValues,
				selectedTeamProfile: { value: teamConfigurationProfile?.id, label: teamConfigurationProfile?.profileName },
				selectedTV: { value: tvTypes.find(item => item.value === tv)?.id, label: tv },
				selectedSector: { value: level.id, label: level.name },
				selectedHdmiPortValue: ports.find(port => hdmiPort === port.id).id,
				selectedEndCallSource: defaultSource !== undefined ? endCallOptions.find(ds => defaultSource === ds.id).id : null,
				hdmiPorts: ports,
				endCallSource: endCallOptions,
				tvs: tvTypes,
				ringtoneVolumeValue: ringtoneVolume,
				isEditing: true,
				teamTypeId: level.typeId,
			};
		}
		return {
			healthSystems: props.healthSystems,
			hdmiPorts: ports,
			endCallSource: endCallOptions,
			tvs: tvTypes,
			selectedHealthSystem: null,
			selectedHospital: defaultState,
			selectedDepartment: defaultState,
			selectedFloor: defaultState,
			selectedRoom: defaultState,
			selectedHdmiPortValue: null,
			selectedTV: null,
			selectedEndCallSource: null,
			selectedTeamProfile: null,
			ringtoneVolumeValue: 0,
		};
	};

	const getValidationSchema = () => {
		const validation = {};
		if (props.initialValues) {
			validation.selectedEndCallSource =
				shouldShowEndCallOptions && Yup.string().nullable().required(translator('pleaseSelectEndCallSource'));
		}
		if (!props.initialValues) {
			validation.selectedHealthSystem = Yup.object().nullable().required(translator('pleaseSelectHealthSystem'));
			validation.selectedHdmiPortValue = Yup.string().nullable().required(translator('pleaseSelectHDMIPort'));
			validation.selectedTV = Yup.object().nullable().required(translator('pleaseSelectTV'));
			validation.selectedEndCallSource =
				shouldShowEndCallOptions && Yup.string().nullable().required(translator('pleaseSelectEndCallSource'));
			validation.ringtoneVolumeValue = Yup.number().required(translator('ringtoneVolumeValidation'));
		}
		return validation;
	};

	const resetStates = () => {
		setHospitals([defaultState]);
		setDepartments([defaultState]);
		setFloors([defaultState]);
		setRooms([defaultState]);
		setHierarchyItems(TreeHierarchyTypeItems[TreeHierarchyType.DEFAULT_TREE]);
		setHierarchyType(TreeHierarchyType.DEFAULT_TREE);
	};

	const onCloseModal = resetForm => {
		resetForm();
		props.toggleModal();
		resetStates();
	};

	const getTeamConfigurationIds = values => {
		if (values.isEditing) {
			return { teamId: values.selectedSector.value, teamTypeId: values.teamTypeId };
		}

		const levelOptions = [
			{ value: values.selectedRoom.value, typeId: DeviceListLevel.ROOM },
			{ value: values.selectedFloor.value, typeId: DeviceListLevel.FLOOR },
			{ value: values.selectedDepartment.value, typeId: DeviceListLevel.DEPARTMENT },
			{ value: values.selectedHospital.value, typeId: DeviceListLevel.HOSPITAL },
			{ value: values.selectedHealthSystem.value, typeId: DeviceListLevel.HEALTH_SYSTEM },
		];

		for (const option of levelOptions) {
			if (option.value !== '0') {
				return { teamId: option.value, teamTypeId: option.typeId };
			}
		}
		return { teamId: 0, teamTypeId: DeviceListLevel.HEALTH_SYSTEM };
	};

	const addDeviceConfigurations = async (values, resetForm) => {
		const hdmiPort = values.selectedHdmiPortValue;
		const { teamId, teamTypeId } = getTeamConfigurationIds(values);
		const teamConfigurationProfileId = values.selectedTeamProfile ? values.selectedTeamProfile.value : null;
		let defaultSource = null;
		if (values.selectedEndCallSource != null) {
			defaultSource = values.endCallSource[values.selectedEndCallSource]?.id;
		}
		props.setIsFormLoading(true);
		const response = await addDeviceConfiguration(teamTypeId, teamId, {
			defaultSource,
			hdmiPort,
			ringtoneVolume: values.ringtoneVolumeValue,
			teamConfigurationProfileId,
			tv: values.selectedTV.label,
		});
		if (!response.hasSucceeded || response.error) {
			props.setIsFormLoading(false);
			setError(response.error.message);
			return;
		}
		props.setIsFormLoading(false);
		props.toggleModal();
		props.getConfigurations();
		resetForm();
		resetStates();
	};

	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={getInitialValues()}
				validationSchema={Yup.object().shape({ ...getValidationSchema() })}
				onSubmit={(values, { resetForm }) => addDeviceConfigurations(values, resetForm)}>
				{formikProps => {
					const { values, errors, handleSubmit, resetForm, setFieldValue } = formikProps;

					if (values.selectedTV?.value) {
						setShouldShowEndCallOptions(
							![TVs.TELEHEALTH_CONTROLLED.id, TVs.GET_WELL.id, TVs.SONIFY.id, TVs.SONY.id].includes(values.selectedTV?.value)
						);
					}

					const filteredEndCallOptions = () =>
						endCallOptions.filter(item => {
							if ([TVs.LG.id, TVs.PCARE.id, TVs.SAMSUNG.id, TVs.SONY.id].includes(values.selectedTV?.value)) {
								return item;
							}
							if ([TVs.CEC.id].includes(values.selectedTV?.value)) {
								return ![endCallSource.PREVIOUS_STATE.id, endCallSource.TV_CHANNELS.id].includes(item.id);
							}
							return ![endCallSource.PREVIOUS_STATE.id].includes(item.id);
						});

					return (
						<Modal
							modalSelector='deviceConfigurationsModal'
							className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
							display={props.isModalOpen}
							position='right'
							onModalSubmit={handleSubmit}
							onModalClose={() => onCloseModal(resetForm)}
							isLoading={props.isFormLoading}
							shouldSubmitOnEnter={false}>
							<Form title='TV' onSubmit={event => event.preventDefault()} className='manage-hs-form'>
								{!values.isEditing && (
									<>
										<div className='input'>
											<p className='label'>{translate('selectHealthSystem')}</p>
											<p className='font-14'>{translate('selectHSForConfiguration')}</p>
											<Select
												value={values.selectedHealthSystem}
												placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
												classNamePrefix='react-select'
												options={healthSystems}
												onChange={value => handleHealthSystemChange(value, setFieldValue)}
											/>
											<small>{errors.selectedHealthSystem}</small>
										</div>

										<div className='input'>
											<p className='label'>{translate('selectHospital')}</p>
											<p className='font-14'>{translate('chooseHospitalForChanges')}</p>
											<Select
												value={values.selectedHospital}
												placeholder={intl.formatMessage({ id: 'selectHospital' })}
												classNamePrefix='react-select'
												options={hospitals}
												onChange={value => handleHospitalChange(value, values.selectedHealthSystem, setFieldValue)}
											/>
										</div>

										{hierarchyItems.includes(SectorTypes.DEPARTMENT) && (
											<div className='input'>
												<p className='label'>{translate('selectDepartment')}</p>
												<p className='font-14'>
													{translate('chooseSectorForChanges', {
														value: intl.formatMessage({ id: 'department' }),
													})}
												</p>
												<Select
													value={values.selectedDepartment}
													placeholder={intl.formatMessage({ id: 'selectDepartment' })}
													classNamePrefix='react-select'
													options={departments}
													onChange={value => handleDepartmentChange(value, setFieldValue)}
												/>
											</div>
										)}

										{hierarchyItems.includes(SectorTypes.FLOOR) && (
											<div className='input'>
												<p className='label'>{translate('selectFloor')}</p>
												<p className='font-14'>
													{translate('chooseSectorForChanges', {
														value: intl.formatMessage({ id: 'floor' }),
													})}
												</p>
												<Select
													value={values.selectedFloor}
													placeholder={intl.formatMessage({ id: 'selectFloor' })}
													classNamePrefix='react-select'
													options={floors}
													onChange={value => handleFloorChange(value, values, setFieldValue)}
												/>
											</div>
										)}

										{hierarchyItems.includes(SectorTypes.ROOM) && (
											<div className='input'>
												<p className='label'>{translate('selectRoom')}</p>
												<p className='font-14'>
													{translate('chooseSectorForChanges', {
														value: intl.formatMessage({ id: 'room' }),
													})}
												</p>
												<Select
													value={values.selectedRoom}
													placeholder={intl.formatMessage({ id: 'selectRoom' })}
													classNamePrefix='react-select'
													options={rooms}
													onChange={value => setFieldValue('selectedRoom', value)}
												/>
											</div>
										)}
									</>
								)}

								{values.isEditing && (
									<Input
										type='text'
										label={translate('valueItemSelected', {
											value: intl.formatMessage({ id: 'sector' }),
										})}
										name='selectedSector'
										value={values.selectedSector ? values.selectedSector.label : ''}
										disabled={true}
									/>
								)}

								<div className='input'>
									<p className='label'>{translate('chooseTVSetup')}</p>
									<p className='font-14'>{translate('chooseTVSetupType')}</p>
									<Select
										value={values.selectedTV}
										placeholder={intl.formatMessage({ id: 'chooseTVSetup' })}
										classNamePrefix='react-select'
										options={props.transformArray(values.tvs)}
										onChange={event => {
											setFieldValue('selectedHdmiPortValue', null);
											setFieldValue('selectedEndCallSource', null);
											setFieldValue('selectedTV', event);
										}}
									/>
									<small>{errors.selectedTV}</small>
								</div>
								<div className='input-el'>
									<label>{translate('selectHelloPort')}</label>
									<p className='font-14'>{translate('selectHDMIPort')}</p>
									<main>
										{values.hdmiPorts.map(item => (
											<Fragment key={item.id}>
												<input
													type='radio'
													id={`hdmi${item.id}`}
													name='hdmi'
													value={item.id}
													onChange={event => setFieldValue('selectedHdmiPortValue', +event.target.value)}
													checked={values.selectedHdmiPortValue === item.id}
												/>
												<label htmlFor={`hdmi${item.id}`}>HDMI {item.id}</label>
											</Fragment>
										))}
									</main>
									<div className='input no-margin'>
										<small>{errors.selectedHdmiPortValue}</small>
									</div>
								</div>
								{shouldShowEndCallOptions && (
									<div className='input-el'>
										<label>{translate('defaultSource')}</label>
										<p>{translate('selectTVOption')}</p>
										<main>
											{filteredEndCallOptions().map(item => (
												<>
													<input
														type='radio'
														id={`endCall${item.id}`}
														name='endCall'
														value={item.id}
														onChange={event => setFieldValue('selectedEndCallSource', +event.target.value)}
														checked={values.selectedEndCallSource === item.id}
													/>
													<label htmlFor={`endCallSource${item.id}`}>{item.value}</label>
												</>
											))}
										</main>
										<div className='input no-margin'>
											<small>{errors.selectedEndCallSource}</small>
										</div>
									</div>
								)}
								<div className='input'>
									<p className='label'>{translate('selectTeleHealthProfile')}</p>
									<p className='font-14'>{translate('selectPreConfiguredProfile')}</p>
									<Select
										value={values.selectedTeamProfile}
										placeholder={intl.formatMessage({ id: 'selectTeleHealthProfile' })}
										classNamePrefix='react-select'
										options={props.transformArray(props.teamProfiles)}
										onChange={event => setFieldValue('selectedTeamProfile', event)}
									/>
								</div>
								<div className='input-el'>
									<label>{translate('helloCareRingtone')}</label>
									<p>{translate('applyToAllDevices')}</p>
									<div className='input-range-wrapper'>
										<input
											type='range'
											min={0}
											step={1}
											max={100}
											value={values.ringtoneVolumeValue}
											onChange={event => setFieldValue('ringtoneVolumeValue', +event.target.value)}
										/>
										<small>{values.ringtoneVolumeValue || 0}</small>
									</div>
									<small>{errors.ringtoneVolumeValue}</small>
								</div>
							</Form>
						</Modal>
					);
				}}
			</Formik>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default TVConfig;
