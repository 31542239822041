import React from 'react';

const DoctorRequestListItem = props => (
	<div className={`doctor-request-info-btn flex doctor-status ${props.className}`}>
		{props.imageSrc && <img src={props.imageSrc} alt='icon' />}
		{props.content}
	</div>
);

export default DoctorRequestListItem;
