import React, { useState } from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { Alert, Form, Loader } from 'components/index.js';
import {
	PatientHeightLimit,
	PatientWeightLimit,
	TotalCholesterolLimit,
	UnitSystemTypes,
	PatientWaistCircumferenceRangeLimit,
	UserRoles,
	AddEditPatientRadioTypes,
	PatientAgeLimit,
} from 'constants/enums.js';
import PersonalInformation from 'components/CheckInAPatient/PersonalInformation.jsx';
import OtherInformation from 'components/CheckInAPatient/OtherInformation.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { getValidationSchema, validateInformations } from 'infrastructure/helpers/addEditPatientHelper.js';
import {
	convertBloodGlucose,
	convertHeight,
	convertWaistCircumference,
	getMeasurementValueBasedOnUnit,
	getPreferredUnit,
} from 'infrastructure/helpers/measurementsHelper.js';
import { profileCreationMeasurementElements } from 'constants/measurements.js';
import { tobaccoSmokeCodes } from 'constants/health-codes.js';
import { useSelector } from 'react-redux';
import Button from 'components/Button.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const PatientDetailsForm = props => {
	const [showBirthdayError, setShowBirthdayError] = useState(false);
	const intl = useIntl();
	const genders = [
		{ id: 1, name: intl.formatMessage({ id: 'male' }) },
		{ id: 2, name: intl.formatMessage({ id: 'female' }) },
	];

	const unitPreferences = useSelector(state => state.user.unitPreferences);

	const getDayOfBirth = () => {
		if (!props.patientDetails?.birthDate) {
			return 'N/A';
		}
		props.setBirthDateError(null);
		return parseInt(
			props.patientDetails?.birthDate?.substring(props.patientDetails.birthDate.lastIndexOf('-') + 1)?.substring(0, 2),
			10
		);
	};

	const getBirthdayItem = position => new Date(props.patientDetails?.birthDate?.split('-')[position]);

	const getInitialValues = () => {
		let tobaccoYesCode = '';
		let tobaccoNoCode = '';
		const tobaccoCodes = [...tobaccoSmokeCodes];
		const found = tobaccoCodes.find(item => item.code === props.tobaccoCode);
		if (props.tobaccoCode) {
			if (found.name === 'tobaccoYesCode') {
				tobaccoYesCode = found.code;
			}
			if (found.name === 'tobaccoNoCode') {
				tobaccoNoCode = found.code;
			}
		}

		return {
			firstName: props.patientDetails?.firstName ?? '',
			lastName: props.patientDetails?.lastName ?? '',
			birthDay: props.patientDetails?.birthDate ? getDayOfBirth() : '',
			birthMonth: props.patientDetails?.birthDate ? getBirthdayItem(1) : '',
			birthYear: props.patientDetails?.birthDate ? getBirthdayItem(0) : '',
			idNumber: props.patientDetails?.idCard ?? '',
			address: props.patientDetails?.address ?? '',
			emailAddress: ['@hello-health.com', ''].includes(props.patientDetails?.email) ? '' : props.patientDetails?.email,
			phoneNumber: props.patientDetails?.phoneNumber ?? '',
			showConsent: props.patientDetails?.showConsent ?? false,
			gender: genders.find(item => props.patientDetails?.genderId === item.id) ?? '',
			height: props.patientDetails?.height
				? getMeasurementValueBasedOnUnit(
						parseFloat(props.patientDetails?.height),
						convertHeight(parseFloat(props.patientDetails?.height), UnitSystemTypes.IMPERIAL).toFixed(2).toString(),
						profileCreationMeasurementElements.height,
						unitPreferences
				  )
				: null,
			heightUnit: getPreferredUnit('HEIGHT', 'HEIGHT', unitPreferences)?.text ?? '',
			weight: props.patientDetails?.weight
				? getMeasurementValueBasedOnUnit(
						parseFloat(props.patientDetails?.weight),
						convertHeight(parseFloat(props.patientDetails?.weight), UnitSystemTypes.IMPERIAL).toFixed(2).toString(),
						profileCreationMeasurementElements.weight,
						unitPreferences
				  )
				: null,
			weightUnit: getPreferredUnit('WEIGHT', 'WEIGHT', unitPreferences)?.text ?? '',
			isTobaccoSmoker:
				props.patientDetails?.isTobaccoSmoker || props.patientDetails?.isTobaccoSmoker === false
					? props.healthInformationStatusList.find(item => item.status === props.patientDetails?.isTobaccoSmoker)?.code.toString()
					: AddEditPatientRadioTypes.NO_ANSWER.toString(),
			hasHyperTension:
				props.patientDetails?.hasHyperTension || props.patientDetails?.hasHyperTension === false
					? props.healthInformationStatusList.find(item => item.status === props.patientDetails?.hasHyperTension)?.code.toString()
					: AddEditPatientRadioTypes.NO_ANSWER.toString(),
			isTakingMedication:
				props.patientDetails?.isTakingMedication || props.patientDetails?.isTakingMedication === false
					? props.healthInformationStatusList
							.find(item => item.status === props.patientDetails?.isTakingMedication)
							?.code.toString()
					: AddEditPatientRadioTypes.NO_ANSWER.toString(),
			hasDiabet:
				props.patientDetails?.hasDiabet || props.patientDetails?.hasDiabet === false
					? props.healthInformationStatusList.find(item => item.status === props.patientDetails?.hasDiabet)?.code.toString()
					: AddEditPatientRadioTypes.NO_ANSWER.toString(),
			hasPreExistingCondition:
				props.patientDetails?.hasPreExistingCondition || props.patientDetails?.hasPreExistingCondition === false
					? props.healthInformationStatusList
							.find(item => item.status === props.patientDetails?.hasPreExistingCondition)
							?.code.toString()
					: AddEditPatientRadioTypes.NO_ANSWER.toString(),
			hasAllergy:
				props.patientDetails?.hasAllergy || props.patientDetails?.hasAllergy === false
					? props.healthInformationStatusList.find(item => item.status === props.patientDetails?.hasAllergy)?.code.toString()
					: AddEditPatientRadioTypes.NO_ANSWER.toString(),
			tobaccoYesCode,
			tobaccoNoCode,
			diabeticStatusCode: props.diabeticCode || '',
			totalCholesterol: props.patientDetails?.totalCholesterol
				? getMeasurementValueBasedOnUnit(
						parseFloat(props.patientDetails?.totalCholesterol),
						convertBloodGlucose(parseFloat(props.patientDetails?.totalCholesterol), UnitSystemTypes.IMPERIAL).toString(),
						profileCreationMeasurementElements.cholesterol,
						unitPreferences
				  )
				: '',
			totalCholesterolUnit: getPreferredUnit('BLOOD_GLUCOSE', 'BLOOD_GLUCOSE', unitPreferences)?.text ?? '',
			hdlCholesterol: props.patientDetails?.hdlCholesterol
				? getMeasurementValueBasedOnUnit(
						parseFloat(props.patientDetails?.hdlCholesterol),
						convertBloodGlucose(parseFloat(props.patientDetails?.hdlCholesterol), UnitSystemTypes.IMPERIAL).toString(),
						profileCreationMeasurementElements.cholesterol,
						unitPreferences
				  )
				: '',
			hdlCholesterolUnit: getPreferredUnit('BLOOD_GLUCOSE', 'BLOOD_GLUCOSE', unitPreferences)?.text ?? '',
			waistCircumference: props.patientDetails?.waistCircumference
				? getMeasurementValueBasedOnUnit(
						parseFloat(props.patientDetails?.waistCircumference),
						convertWaistCircumference(parseFloat(props.patientDetails?.waistCircumference), UnitSystemTypes.IMPERIAL).toString(),
						profileCreationMeasurementElements.waist_circumference,
						unitPreferences
				  )
				: '',
			waistCircumferenceUnit: getPreferredUnit('WAIST_CIRCUMFERENCE', 'HEIGHT', unitPreferences)?.text ?? '',
			allergies:
				props.patientDetails?.hasAllergy && props.patientDetails?.allergies?.length
					? props.patientDetails?.allergies.map(allergy => allergy.categoryId.toString())
					: [],
			country: props.patientDetails?.country ?? null,
			city: props.patientDetails?.city ?? '',
			zipCode: props.patientDetails?.postalCode ?? '',
			preExistingCondition: props.patientDetails?.preExistingCondition ?? '',
			foodAllergy: '',
			medicationAllergy: '',
			environmentalAllergy: '',
			biologicalAllergy: '',
		};
	};

	const inputLimits = {
		HEIGHT:
			getPreferredUnit('HEIGHT', 'HEIGHT', unitPreferences) === UnitSystemTypes.METRIC
				? PatientHeightLimit.CENTIMETER.MAX
				: PatientHeightLimit.FEET.MAX,
		WEIGHT:
			getPreferredUnit('WEIGHT', 'WEIGHT', unitPreferences) === UnitSystemTypes.METRIC
				? PatientWeightLimit.KILOGRAMS.MAX
				: PatientWeightLimit.LBS.MAX,
		TOTAL_CHOLESTEROL:
			getPreferredUnit('BLOOD_GLUCOSE', 'BLOOD_GLUCOSE', unitPreferences) === UnitSystemTypes.METRIC
				? TotalCholesterolLimit.MMOLL.MAX
				: TotalCholesterolLimit.MGDL.MAX,
		HDL_CHOLESTEROL:
			getPreferredUnit('BLOOD_GLUCOSE', 'BLOOD_GLUCOSE', unitPreferences) === UnitSystemTypes.METRIC
				? TotalCholesterolLimit.MMOLL.MAX
				: TotalCholesterolLimit.MGDL.MAX,
		WAIST_CIRCUMFERENCE:
			getPreferredUnit('WAIST_CIRCUMFERENCE', 'HEIGHT', unitPreferences) === UnitSystemTypes.METRIC
				? PatientWaistCircumferenceRangeLimit.CM.MAX
				: PatientWaistCircumferenceRangeLimit.INCH.MAX,
	};

	const validateForm = (errors, values) => {
		const measurementUnits = [
			values.heightUnit,
			values.weightUnit,
			values.totalCholesterolUnit,
			values.hdlCholesterolUnit,
			values.waistCircumferenceUnit,
		];
		if (props.isRpmPatient && measurementUnits.every(item => item !== '')) {
			props.setUpdatedValues(values);
		}
		if (Object.keys(errors).length > 0) {
			props.setBirthDateError(null);
			if (props.isRpmPatient) {
				props.setHasErrors(true);
				props.setIsFormValid(false);
				props.setUpdatedValues(values);
			}
			if (!moment(props.getDateOfBirth(values)).isValid() || moment(props.getDateOfBirth(values)).diff(moment(), 'days') >= 0) {
				props.setBirthDateError(intl.formatMessage({ id: 'dateOfBirthNotValid' }));
			}
			if (moment().diff(props.getDateOfBirth(values), 'years') > PatientAgeLimit.MAX) {
				props.setBirthDateError(intl.formatMessage({ id: 'maxBirthdayLimit' }));
			}
		} else if (props.isRpmPatient) {
			props.setHasErrors(false);
			props.setIsFormValid(!validateInformations(props.updatedValues));
		}
	};

	return (
		<Formik
			initialValues={props.updatedValues || getInitialValues()}
			onSubmit={(values, resetForm) => {
				if (props.isRpmPatient) {
					return;
				}
				props.patientDetails ? props.onSubmitForm(values, resetForm, getInitialValues()) : props.onSubmitForm(values);
			}}
			validationSchema={getValidationSchema(intl, props.isAddPatient)}>
			{formikProps => {
				const { values, handleChange, errors, handleSubmit, setFieldValue, touched, handleBlur } = formikProps;
				validateForm(errors, values);
				return (
					<Form className='overflow-auto add-edit-patient-form'>
						<div className='flex flex-align-center'>
							{getUserRole() === UserRoles.DIGITAL_CLINICIAN && (
								<div className='cursor-pointer circle-icon' onClick={props.hidePatientForm}>
									<i className='material-icons'>arrow_back_ios_new</i>
								</div>
							)}
							<h3>{props.title}</h3>
						</div>
						<PersonalInformation
							touched={touched}
							values={values}
							errors={errors}
							onChange={handleChange}
							onBlur={handleBlur}
							setFieldValue={setFieldValue}
							birthDateError={props.birthDateError}
							setBirthDateError={props.setBirthDateError}
							genderWrapperRef={props.genderWrapperRef}
							setIsGenderOpen={props.setIsGenderOpen}
							isGenderOpen={props.isGenderOpen}
							genders={props.genders}
							emailExistsError={props.emailExistsError}
							unitPreferences={unitPreferences}
							toggleGenderDropDown={gender => props.toggleGenderDropDown(gender, formikProps)}
							inputLimits={inputLimits}
							countries={props.countries}
							formikProps={formikProps}
							mrn={props.mrn}
							isEmailReadOnly={(props.isViewProfileOpen && props.isRpmPatient) || props.isEditProfile}
							setCanChangeTab={props.isFormValid}
							isFromRpm={props.isRpmPatient}
							showBirthdayError={showBirthdayError}
						/>
						<OtherInformation
							touched={touched}
							values={values}
							errors={errors}
							onChange={handleChange}
							onBlur={handleBlur}
							setFieldValue={setFieldValue}
							genders={props.genders}
							unitPreferences={unitPreferences}
							inputLimits={inputLimits}
							formikProps={formikProps}
							foodAllergies={props.foodAllergies}
							setFoodAllergies={props.setFoodAllergies}
							medicationAllergies={props.medicationAllergies}
							setMedicationAllergies={props.setMedicationAllergies}
							environmentalAllergies={props.environmentalAllergies}
							setEnvironmentalAllergies={props.setEnvironmentalAllergies}
							biologicalAllergies={props.biologicalAllergies}
							setBiologicalAllergies={props.setBiologicalAllergies}
						/>
						{getUserRole() === UserRoles.DIGITAL_CLINICIAN && (
							<div className='submit-btn-wrapper'>
								<Button
									className='position-relative'
									onClick={handleSubmit}
									text={props.isLoading ? <Loader /> : props.submitButtonText}
								/>
							</div>
						)}
						{props.isRpmPatient && (
							<Button
								onClick={() => {
									handleSubmit();
									setShowBirthdayError(true);
									props.tabChange(props.currentTab + 1);
								}}
								imgIcon={`${healthCareCdnUrl}forward-arrow-white.svg`}
								text={intl.formatMessage({ id: 'next' })}
								className='requests-wrapper-button forward-button-rpm'
								variant='forward'
							/>
						)}
						<Alert display={props.error} fixed={true} hideCloseButton={true} message={props.error} variant='dark' />
					</Form>
				);
			}}
		</Formik>
	);
};

export default PatientDetailsForm;
