import React, { useEffect } from 'react';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Client, InputObjectTypeDetails, ObjectTypeDetail, SourceOfInput, UserRoles } from 'constants/enums.js';
import { validateMaxLength } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { AdminsLogTypes } from 'constants/configurationEnums.js';
import Button from 'components/Button.jsx';

const AuditLogsFilters = props => {
	const intl = useIntl();
	const transformArray = array => array.map(item => ({ value: item.id, label: props.transformLogName(item.name) }));

	const getMaxTime = () => {
		const visitDateTime = moment(props.values.fromDate);
		return new Date(visitDateTime.isSame(moment(), 'day') ? props.values.toDate : '12:00');
	};

	const maxTime = getMaxTime();
	const minDate = new Date(props.values.fromDate);
	minDate.setHours(0);
	minDate.setMinutes(0);
	minDate.setSeconds(0);

	useEffect(() => {
		if (UserRoles.ADMIN === getUserRole()) {
			props.setFieldValue('logTypeId', {
				value: props.logTypes.find(item => item.name === AdminsLogTypes.HEALTH_SYSTEM_SETTINGS.label)?.id,
				label: props.transformLogName(AdminsLogTypes.HEALTH_SYSTEM_SETTINGS.label),
			});
		}
	}, [props.logTypes]);

	return (
		<div className='audit-log-filters'>
			{UserRoles.ADMIN !== getUserRole() && (
				<Select
					value={props.values.clientId}
					placeholder={translate('client')}
					classNamePrefix='react-select'
					options={transformArray(Client)}
					onChange={value => props.setFieldValue('clientId', value)}
					noOptionsMessage={() => intl.formatMessage({ id: 'noOptions' })}
					isClearable={true}
				/>
			)}
			<Select
				value={props.values.logTypeId}
				placeholder={translate('logType')}
				classNamePrefix='react-select'
				options={transformArray(props.logTypes)}
				onChange={value => props.setFieldValue('logTypeId', value)}
				noOptionsMessage={() => intl.formatMessage({ id: 'noOptions' })}
				isClearable={UserRoles.ADMIN !== getUserRole()}
			/>
			{UserRoles.ADMIN !== getUserRole() && (
				<>
					<Select
						value={props.values.platformId}
						placeholder={translate('sourceOfInput')}
						classNamePrefix='react-select'
						options={transformArray(SourceOfInput)}
						onChange={value => props.setFieldValue('platformId', value)}
						noOptionsMessage={() => intl.formatMessage({ id: 'noOptions' })}
						isClearable={true}
					/>
					<Select
						value={props.values.objectType}
						placeholder={translate('inputterType')}
						classNamePrefix='react-select'
						options={transformArray(InputObjectTypeDetails)}
						onChange={value => props.setFieldValue('objectType', value)}
						noOptionsMessage={() => intl.formatMessage({ id: 'noOptions' })}
						isClearable={true}
					/>
				</>
			)}
			<input
				type='text'
				placeholder={intl.formatMessage({ id: 'inputterFirstName' })}
				className='filter-audit-log-input'
				maxLength={50}
				value={props.values.firstName}
				onChange={event => props.setFieldValue('firstName', event.target.value)}
			/>
			<input
				type='text'
				placeholder={intl.formatMessage({ id: 'inputterLastName' })}
				className='filter-audit-log-input'
				maxLength={50}
				value={props.values.lastName}
				onChange={event => props.setFieldValue('lastName', event.target.value)}
			/>
			<input
				type='number'
				placeholder={intl.formatMessage({ id: 'inputterId' })}
				className='filter-audit-log-input number-input-wo-arrows'
				maxLength={50}
				value={props.values.objectId}
				onChange={event => props.setFieldValue('objectId', event.target.value)}
				onKeyDown={event => validateMaxLength(event, 10)}
			/>
			<input
				type='email'
				placeholder={intl.formatMessage({ id: 'email' })}
				className='filter-audit-log-input'
				maxLength={50}
				value={props.values.email}
				onChange={event => props.setFieldValue('email', event.target.value)}
			/>
			{UserRoles.ADMIN !== getUserRole() && (
				<>
					<Select
						value={props.values.actioneeObjectType}
						placeholder={translate('subjectType')}
						classNamePrefix='react-select'
						options={transformArray(ObjectTypeDetail)}
						onChange={value => props.setFieldValue('actioneeObjectType', value)}
						noOptionsMessage={() => intl.formatMessage({ id: 'noOptions' })}
						isClearable={true}
					/>
					<input
						type='number'
						placeholder={intl.formatMessage({ id: 'subjectId' })}
						className='filter-audit-log-input number-input-wo-arrows'
						maxLength={50}
						value={props.values.actioneeObjectId}
						onChange={event => props.setFieldValue('actioneeObjectId', event.target.value)}
						onKeyDown={event => validateMaxLength(event, 10)}
					/>
					<input
						type='text'
						placeholder={intl.formatMessage({ id: 'source' })}
						className='filter-audit-log-input'
						maxLength={50}
						value={props.values.source}
						onChange={event => props.setFieldValue('source', event.target.value)}
					/>
					<input
						type='text'
						placeholder={intl.formatMessage({ id: 'companyId' })}
						className='filter-audit-log-input'
						maxLength={50}
						value={props.values.tenantId}
						onChange={event => props.setFieldValue('tenantId', event.target.value)}
					/>
				</>
			)}
			<div className='audit-logs-date-wrapper'>
				<DatePicker
					showTimeSelect
					selected={props.values.fromDate}
					onChange={date => props.setFieldValue('fromDate', new Date(date))}
					maxDate={new Date()}
					minTime={minDate}
					maxTime={maxTime}
					dateFormat='MMMM d, yyyy h:mm aa'
					popperPlacement='bottom-start'
					placeholderText={intl.formatMessage({ id: 'startDate' })}
					locale={props.locale}
				/>
			</div>
			<div className='audit-logs-date-wrapper'>
				<DatePicker
					showTimeSelect
					selected={props.values.toDate}
					onChange={date => props.setFieldValue('toDate', new Date(date))}
					maxDate={new Date()}
					minDate={new Date(props.values.fromDate)}
					minTime={minDate}
					maxTime={new Date()}
					dateFormat='MMMM d, yyyy h:mm aa'
					popperPlacement='bottom-start'
					placeholderText={intl.formatMessage({ id: 'endDate' })}
					locale={props.locale}
				/>
			</div>
			<div className='flex right-align-content'>
				<Button className='button margin-right-m' onClick={props.handleSubmit} text={translate('filterLogs')} /> 
				<input
					type='reset'
					className='button white cursor-pointer'
					value={intl.formatMessage({ id: 'resetFilter' })}
					onClick={props.resetForm}
				/>
			</div>
		</div>
	);
};

export default AuditLogsFilters;
