import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Minimize = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill='none'
		stroke={props.color || LightTheme.colors.grayFive}
		strokeWidth={1.5}
		strokeLinecap='round'
		strokeLinejoin='round'
		{...props}>
		<path d='M4 14h6v6M20 10h-6V4M14 10l7-7M3 21l7-7' stroke='currentColor' />
	</svg>
);

export default Minimize;
