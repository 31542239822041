import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { addTeamConfigurationProfile, updateTeamConfigurationProfile } from 'api/teamConfigurationProfiles.js';
import { Alert, Form, Input, Modal } from 'components/index.js';
import { TeamTypes, UserRoles } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { IntegrationTypesSettings, TeamConfigurationIntegrationTypes } from 'constants/configurationEnums.js';
import { getConfigurationValue, getIntegrationTypes } from 'infrastructure/helpers/commonHelpers.js';
import { decodeValidBase64 } from 'infrastructure/helpers/validationHelper.js';

const HealthSystemConfigForm = props => {
	const intl = useIntl();
	const [error, setError] = useState(null);
	const [integrationTypes, setIntegrationTypes] = useState([]);
	const translator = id => intl.formatMessage({ id });
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);

	useEffect(() => {
		const filteredIntegrationTypes = [
			IntegrationTypesSettings.TELEHEALTH,
			IntegrationTypesSettings.EVIDEON,
			IntegrationTypesSettings.PCARE,
			IntegrationTypesSettings.GET_WELL,
			IntegrationTypesSettings.SONIFI,
			IntegrationTypesSettings.MDM,
		];

		const filteredArray = filteredIntegrationTypes.filter(configId => getConfigurationValue(companyConfigurations[configId]));
		const enabledIntegrationTypes = getIntegrationTypes(filteredArray);
		setIntegrationTypes(enabledIntegrationTypes);
	}, [companyConfigurations]);

	const { EVIDEON, TELEHEALTH, PCARE, GET_WELL, SONIFI, MDM } = TeamConfigurationIntegrationTypes;

	const getInitialValues = () => {
		if (props.initialValues) {
			const { team, id, integrationType, secret, apiKey } = props.initialValues;
			let secretKey = '';
			let userName = '';
			let clientId;
			let clientSecret;
			if (integrationType?.value === PCARE || (integrationType?.value === GET_WELL && decodeValidBase64(secret).length > 1)) {
				if (integrationType?.value === GET_WELL) {
					clientId = decodeValidBase64(secret).at(0);
					clientSecret = decodeValidBase64(secret).at(1);
				} else {
					[, secretKey] = decodeValidBase64(secret);
					[userName] = decodeValidBase64(secret);
				}
			} else if ([SONIFI, MDM].includes(integrationType?.value)) {
				clientId = apiKey;
				clientSecret = secret;
			} else {
				secretKey = secret;
				userName = '';
			}
			return {
				...props.initialValues,
				selectedHealthSystem: {
					value: team.id,
					label: team.name,
				},
				selectedId: id,
				secret: secretKey,
				...(secretKey && { maskedSecret: `${secretKey.slice(0, 3)}************` }),
				isSecretChanged: false,
				isEdit: true,
				userName,
				clientId,
				clientSecret,
			};
		}
		return {
			healthSystems: props.healthSystems,
			selectedHealthSystem: props.selectedHealthSystem,
			integrationType: integrationTypes[0],
			selectedId: null,
			profileName: '',
			url: '',
			port: 0,
			secret: '',
			apiKey: '',
			siteId: '',
			userName: '',
			scope: '',
			tenantID: '',
			facilityAccessKey: '',
			theme: '',
			tokenUrl: '',
			isEdit: false,
		};
	};

	const encodeToBase64 = (userName, secret) => btoa(`${userName}:${secret}`);

	const shouldSubmitProperty = (changedValues, integrationTypes, values) =>
		(changedValues || props.initialValues) &&
		(integrationTypes.includes(values.integrationType.value) ||
			integrationTypes.includes(props.initialValues?.integrationTypeId));

	const addDeviceConfigurations = async values => {
		const changedValues = props.initialValues?.integrationType?.value !== values.integrationType?.value;
		props.setIsFormLoading(true);
		const params = {
			integrationTypeId: values.integrationType.value,
			profileName: values.profileName,
			url: values.url,
			userName: values.userName,
			...((changedValues || props.initialValues) &&
				(![TELEHEALTH, PCARE, GET_WELL].includes(values.integrationType.value) ||
					![TELEHEALTH, PCARE, GET_WELL].includes(props.initialValues?.integrationTypeId)) && { port: 0, secret: null }),
			...(shouldSubmitProperty(changedValues, [TELEHEALTH, PCARE, GET_WELL], values) && {
				port: parseInt(values.port, 10),
				secret: values.integrationType.value === PCARE ? encodeToBase64(values.userName, values.secret) : values.secret,
			}),
			...(shouldSubmitProperty(changedValues, [EVIDEON], values) && {
				apiKey: values.apiKey,
			}),
			...(shouldSubmitProperty(changedValues, [PCARE, EVIDEON], values) && {
				siteId: values.siteId,
			}),
			...(shouldSubmitProperty(changedValues, [PCARE], values) && { port: 0 }),
			...(shouldSubmitProperty(changedValues, [GET_WELL], values) && {
				siteId: values.siteId,
				secret: encodeToBase64(values.clientId, values.clientSecret),
			}),
			...(shouldSubmitProperty(changedValues, [MDM, SONIFI], values) && {
				siteId: values.siteId,
				apiKey: values.clientId,
				secret: values.clientSecret,
			}),
			...(shouldSubmitProperty(changedValues, [MDM], values) && {
				scope: values.scope,
				tenantId: values.tenantId,
				facilityAccessKey: values.facilityAccessKey,
				theme: values.theme,
				tokenUrl: values.tokenUrl,
			}),
		};
		const response = !props.initialValues
			? await addTeamConfigurationProfile(values.selectedHealthSystem.value, TeamTypes.HEALTH_SYSTEM, params)
			: await updateTeamConfigurationProfile(
					values.selectedHealthSystem.value,
					TeamTypes.HEALTH_SYSTEM,
					values.selectedId,
					params
			  );
		if (!response.hasSucceeded || response.error) {
			props.setIsFormLoading(false);
			setError(response.error.message);
		}
		props.setIsFormLoading(false);
		props.toggleModal();
		props.getConfigurations();
	};

	const onCloseModal = resetForm => {
		resetForm();
		props.toggleModal();
	};

	const getValidationSchema = () => {
		const validation = {};
		validation.selectedHealthSystem = Yup.object().nullable().required(translator('pleaseSelectHealthSystem'));
		validation.profileName = Yup.string().required(translator('pleaseSelectProfileName'));
		validation.url = Yup.string()
			.required(translator('pleaseSetURL'))
			.max(63, `${intl.formatMessage({ id: 'maxLengthIs' })} 63`);
		validation.secret = Yup.string()
			.when('integrationType.value', {
				is: TELEHEALTH,
				then: schema => schema.required(translator('pleaseSetSecret')),
			})
			.when('integrationType.value', {
				is: PCARE,
				then: schema => schema.required(translator('pleaseSetPassword')),
			});
		validation.port = Yup.number().when('integrationType.value', {
			is: TELEHEALTH,
			then: schema => schema.required(translator('portValidation')).min(1, translator('portValidation')),
		});
		validation.apiKey = Yup.string().when('integrationType.value', {
			is: EVIDEON,
			then: schema => schema.required(translator('pleaseSetApiKey')),
		});
		validation.siteId = Yup.string().when('integrationType.value', {
			is: EVIDEON,
			then: schema => schema.required(translator('pleaseSetSiteId')),
		});
		validation.userName = Yup.string().when('integrationType.value', {
			is: PCARE,
			then: schema => schema.required(translator('pleaseSetUserName')),
		});
		validation.clientId = Yup.string().when('integrationType.value', {
			is: GET_WELL,
			then: schema => schema.required(translator('pleaseSetClientId')),
		});
		validation.clientId = Yup.string().when('integrationType.value', {
			is: SONIFI,
			then: schema => schema.required(translator('pleaseSetClientId')),
		});
		validation.clientId = Yup.string().when('integrationType.value', {
			is: MDM,
			then: schema => schema.required(translator('pleaseSetClientId')),
		});
		validation.clientSecret = Yup.string().when('integrationType.value', {
			is: GET_WELL,
			then: schema => schema.required(translator('pleaseSetSecret')),
		});
		validation.clientSecret = Yup.string().when('integrationType.value', {
			is: SONIFI,
			then: schema => schema.required(translator('pleaseSetSecret')),
		});
		validation.clientSecret = Yup.string().when('integrationType.value', {
			is: MDM,
			then: schema => schema.required(translator('pleaseSetSecret')),
		});
		validation.siteId = Yup.string().when('integrationType.value', {
			is: GET_WELL,
			then: schema => schema.required(translator('pleaseSetSiteId')),
		});
		validation.siteId = Yup.string().when('integrationType.value', {
			is: SONIFI,
			then: schema => schema.required(translator('pleaseSetSiteId')),
		});
		validation.siteId = Yup.string().when('integrationType.value', {
			is: PCARE,
			then: schema => schema.required(translator('pleaseSetSiteId')),
		});
		validation.siteId = Yup.string().when('integrationType.value', {
			is: MDM,
			then: schema => schema.required(translator('pleaseSetSiteId')),
		});
		validation.scope = Yup.string().when('integrationType.value', {
			is: MDM,
			then: schema => schema.required(translator('pleaseSetScope')),
		});
		validation.tenantId = Yup.string().when('integrationType.value', {
			is: MDM,
			then: schema => schema.required(translator('pleaseSetTenantId')),
		});
		validation.facilityAccessKey = Yup.string().when('integrationType.value', {
			is: MDM,
			then: schema => schema.required(translator('pleaseSetFacilityAccessKey')),
		});
		validation.theme = Yup.string().when('integrationType.value', {
			is: MDM,
			then: schema => schema.required(translator('pleaseSetTheme')),
		});
		validation.tokenUrl = Yup.string().when('integrationType.value', {
			is: MDM,
			then: schema => schema.required(translator('pleaseSetTokenUrl')),
		});
		return validation;
	};

	const onFormHealthSystemSelect = async (values, setFieldValue) => {
		if (values.isEdit) {
			return;
		}
		const selectedHS = props.healthSystems.reduce((acc, item) => {
			const mapped = {
				label: item.name,
				value: item.id,
			};
			if (item.id === values.value) {
				return mapped;
			}
			return acc;
		}, null);
		setFieldValue('selectedHealthSystem', selectedHS);
	};

	const handleSecretKey = (e, values, setFieldValue) => {
		setFieldValue('isSecretChanged', true);
		if (values.maskedSecret) {
			setFieldValue('secret', '');
			setFieldValue('maskedSecret', '');
		} else {
			setFieldValue('secret', e.target.value);
		}
	};

	return (
		<>
			{integrationTypes.length > 0 && (
				<Formik
					enableReinitialize={true}
					initialValues={getInitialValues()}
					validationSchema={Yup.object().shape({ ...getValidationSchema() })}
					onSubmit={(values, { resetForm }) => {
						addDeviceConfigurations(values);
						resetForm({ values: '' });
					}}>
					{formikProps => {
						const { values, errors, handleSubmit, handleChange, resetForm, setFieldValue } = formikProps;
						return (
							<Modal
								modalSelector='deviceConfigurationsModal'
								className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
								display={props.isModalOpen}
								position='right'
								onModalSubmit={handleSubmit}
								onModalClose={() => onCloseModal(resetForm)}
								isLoading={props.isFormLoading}
								shouldSubmitOnEnter={false}>
								<Form
									title={intl.formatMessage({ id: 'healthSystem' })}
									onSubmit={event => event.preventDefault()}
									className='manage-hs-form'>
									{!props.initialValues && (
										<div className='input'>
											<p className='label'>{translate('selectHealthSystem')}</p>
											{getUserRole() != UserRoles.SUPER_USER && (
												<p className='font-14'>{translate('selectHSForConfiguration')}</p>
											)}
											<Select
												value={values.selectedHealthSystem}
												placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
												classNamePrefix='react-select'
												options={props.transformArray(props.healthSystems)}
												onChange={event => onFormHealthSystemSelect(event, setFieldValue)}
												isDisabled={getUserRole() === UserRoles.SUPER_USER}
											/>
											<small>{errors.selectedHealthSystem}</small>
										</div>
									)}
									<div className='input'>
										<p className='label'>{translate('selectIntegrationType')}</p>
										<Select
											value={values.integrationType}
											placeholder={intl.formatMessage({ id: 'selectIntegrationType' })}
											classNamePrefix='react-select'
											options={integrationTypes}
											onChange={event => setFieldValue('integrationType', event)}
										/>
									</div>
									<Input
										type='text'
										label={translate('setProfileName')}
										name='profileName'
										placeholder={intl.formatMessage({ id: 'profileName' })}
										value={values.profileName}
										onChange={handleChange}
										validationOptions={{}}
										error={errors.profileName}
										bottomSpace='20px'
									/>
									{values.integrationType.value === PCARE && (
										<Input
											type='text'
											label={translate('userName')}
											name='userName'
											placeholder={intl.formatMessage({ id: 'userName' })}
											value={values.userName}
											onChange={handleChange}
											validationOptions={{}}
											autoComplete='off'
											error={errors.userName}
											bottomSpace='20px'
										/>
									)}
									<Input
										type='text'
										label={translate('setURL')}
										name='url'
										placeholder='URL'
										value={values.url}
										onChange={handleChange}
										validationOptions={{}}
										error={errors.url}
										bottomSpace='20px'
									/>
									{[GET_WELL, SONIFI, MDM].includes(values.integrationType.value) && (
										<>
											<Input
												type='text'
												label={translate('setSiteId')}
												name='siteId'
												placeholder={intl.formatMessage({ id: 'siteId' })}
												value={values.siteId}
												onChange={handleChange}
												validationOptions={{}}
												autoComplete='off'
												error={errors.siteId}
												bottomSpace='20px'
											/>
											<Input
												type='text'
												label={translate('clientId')}
												name='clientId'
												placeholder={intl.formatMessage({ id: 'clientId' })}
												value={values.clientId}
												onChange={handleChange}
												validationOptions={{}}
												autoComplete='off'
												error={errors.clientId}
												bottomSpace='20px'
											/>
											<Input
												type='password'
												label={translate('clientSecret')}
												name='clientSecret'
												placeholder={intl.formatMessage({ id: 'clientSecret' })}
												value={values.clientSecret}
												onChange={handleChange}
												validationOptions={{}}
												autoComplete='off'
												error={errors.clientSecret}
												bottomSpace='20px'
											/>
										</>
									)}
									{values.integrationType.value === PCARE && (
										<Input
											type='text'
											label={translate('setSiteId')}
											name='siteId'
											placeholder={intl.formatMessage({ id: 'setSiteId' })}
											value={values.siteId}
											onChange={handleChange}
											validationOptions={{}}
											autoComplete='off'
											error={errors.siteId}
											bottomSpace='20px'
										/>
									)}
									{values.integrationType.value === TELEHEALTH && (
										<Input
											type='text'
											label={translate('setPort')}
											name='port'
											placeholder={intl.formatMessage({ id: 'setPort' })}
											value={values.port}
											onChange={handleChange}
											validationOptions={{}}
											autoComplete='off'
											error={errors.port}
											bottomSpace='20px'
										/>
									)}
									{[TELEHEALTH, PCARE].includes(values.integrationType.value) && (
										<Input
											type={values.isEdit && !values.isSecretChanged ? 'text' : 'password'}
											label={translate(values.integrationType.value === PCARE ? 'password' : 'setSecret')}
											autoComplete='off'
											name='secret'
											placeholder={intl.formatMessage({ id: values.integrationType.value === PCARE ? 'password' : 'secret' })}
											value={values.isEdit && !values.isSecretChanged ? values.maskedSecret : values.secret}
											onChange={e => handleSecretKey(e, values, setFieldValue)}
											validationOptions={{}}
											error={errors.secret}
											bottomSpace='20px'
										/>
									)}
									{values.integrationType.value === EVIDEON && (
										<>
											<Input
												type='text'
												label={translate('setSiteId')}
												name='siteId'
												placeholder={intl.formatMessage({ id: 'siteId' })}
												value={values.siteId}
												onChange={handleChange}
												validationOptions={{}}
												autoComplete='off'
												error={errors.siteId}
												bottomSpace='20px'
											/>
											<Input
												type='text'
												label={translate('setAPIKey')}
												autoComplete='off'
												name='apiKey'
												placeholder={intl.formatMessage({ id: 'apiKey' })}
												value={values.apiKey}
												onChange={handleChange}
												validationOptions={{}}
												error={errors.apiKey}
												bottomSpace='20px'
											/>
										</>
									)}
									{values.integrationType.value === MDM && (
										<>
											<Input
												type='text'
												label={translate('setScope')}
												name='scope'
												placeholder={intl.formatMessage({ id: 'scope' })}
												value={values.scope}
												onChange={handleChange}
												validationOptions={{}}
												autoComplete='off'
												error={errors.scope}
												bottomSpace='20px'
											/>
											<Input
												type='text'
												label={translate('tenantId')}
												name='tenantId'
												placeholder={intl.formatMessage({ id: 'tenantId' })}
												value={values.tenantId}
												onChange={handleChange}
												validationOptions={{}}
												autoComplete='off'
												error={errors.tenantId}
												bottomSpace='20px'
											/>
											<Input
												type='text'
												label={translate('facilityAccessKey')}
												name='facilityAccessKey'
												placeholder={intl.formatMessage({ id: 'facilityAccessKey' })}
												value={values.facilityAccessKey}
												onChange={handleChange}
												validationOptions={{}}
												autoComplete='off'
												error={errors.facilityAccessKey}
												bottomSpace='20px'
											/>
											<Input
												type='text'
												label={translate('setTheme')}
												name='theme'
												placeholder={intl.formatMessage({ id: 'theme' })}
												value={values.theme}
												onChange={handleChange}
												validationOptions={{}}
												autoComplete='off'
												error={errors.theme}
												bottomSpace='20px'
											/>
											<Input
												type='text'
												label={translate('tokenUrl')}
												name='tokenUrl'
												placeholder={intl.formatMessage({ id: 'tokenUrl' })}
												value={values.tokenUrl}
												onChange={handleChange}
												validationOptions={{}}
												autoComplete='off'
												error={errors.tokenUrl}
												bottomSpace='20px'
											/>
										</>
									)}
								</Form>
							</Modal>
						);
					}}
				</Formik>
			)}

			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default HealthSystemConfigForm;
