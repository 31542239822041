import * as React from 'react';

const ReportAsFalse = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 24 24' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='m3.755 18.304 7.877-13.785a1 1 0 0 1 1.737 0l7.877 13.785a1 1 0 0 1-.869 1.496H4.624a1 1 0 0 1-.869-1.496Z'
		/>
		<path
			fill='currentColor'
			stroke='currentColor'
			strokeWidth={0.35}
			d='m10.346 12.148-.007.181h1.316l.01-.163c.02-.288.12-.507.273-.654.153-.147.373-.236.66-.236.283 0 .503.09.65.226a.742.742 0 0 1 .233.56c0 .226-.044.386-.141.53-.101.148-.269.293-.545.467-.335.21-.591.438-.76.716-.168.28-.24.598-.227.97l.005.39.002.172h1.318v-.473c0-.23.04-.383.132-.52.097-.143.266-.285.563-.468.661-.413 1.068-.998 1.068-1.829 0-.573-.23-1.075-.631-1.432-.4-.356-.962-.56-1.616-.56-.718 0-1.286.225-1.681.612-.394.387-.6.92-.622 1.51Zm2.134 5.427a.789.789 0 0 0 .794-.807.79.79 0 1 0-1.583 0c0 .45.346.807.789.807Z'
		/>
	</svg>
);
export default ReportAsFalse;
