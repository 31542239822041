import React, { useState, useEffect, useRef, useCallback } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { injectIntl } from 'react-intl';
import Grid from 'components/Grid.jsx';
import { getPharmacies } from 'api/pharmacy.js';
import { APP_CONFIG } from 'constants/global-variables.js';
import { handleOnKeyDownCellPhoneNumbers, getUserLocation, calcDistanceInMiles } from 'infrastructure/helpers/commonHelpers.js';
import Alert from 'components/Alert.jsx';
import { DeliveryTypes, KeyCodes } from 'constants/enums.js';
import Button from 'components/Button.jsx';
import translate from 'i18n-translations/translate.jsx';

const SelectPharmacy = props => {
	const [searchValue, setSearchValue] = useState('');
	const [isChangePharmacyMode, setisChangePharmacyMode] = useState(false);
	const [error, setError] = useState('');
	const [pharmacies, setPharmacies] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedMarker, setSelectedSelectedMarker] = useState(null);
	const [hoveredStoreNumber, setHoveredStoreNumber] = useState(null);
	const [userLocation, setUserLocation] = useState(null);
	const [mapCenter, setMapCenter] = useState({
		lat: 37.0902, // center of usa lat
		lng: -95.7129, // center of usa lng
	});

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: APP_CONFIG.googleMapsKey,
	});

	const mapRef = useRef();

	useEffect(() => {
		if (!props.selectedPharmacy) {
			setisChangePharmacyMode(true);
		}
		getUserLocation(onGeolocationSuccess, onGeolocationError);
	}, [props.selectedPharmacy]);

	useEffect(() => {
		if (mapRef && mapRef.current) {
			// @ts-ignore
			mapRef.current.panTo(mapCenter);
			// @ts-ignore
			mapRef.current.setZoom(12);
		}
	}, [mapCenter]);

	useEffect(() => {
		if (pharmacies[0]) {
			const lat = parseFloat(pharmacies[0].latitude);
			const lng = parseFloat(pharmacies[0].longitude);
			setMapCenter({ lat, lng });
		}
	}, [pharmacies]);

	const onMapLoad = useCallback(map => {
		mapRef.current = map;
	}, []);

	const onGeolocationSuccess = pos => {
		const crd = pos.coords;
		setUserLocation({ latitude: crd.latitude, longitude: crd.longitude });
		setMapCenter({ lat: crd.latitude, lng: crd.longitude });
	};

	const onGeolocationError = err => {
		setError(`ERROR(${err.code}): ${err.message}`);
	};

	const searchPharmacies = async () => {
		setIsLoading(true);
		const response = await getPharmacies(searchValue);
		if (response.error) {
			setError(response.error.response.statusText);
		} else {
			const mappedResponse = response.map(pharmacy => ({
				storeNumber: pharmacy.store.storeNumber,
				name: pharmacy.store.name,
				email: '',
				phoneNr: `${pharmacy.store.phone.areaCode}${pharmacy.store.phone.number}`,
				latitude: pharmacy.latitude,
				longitude: pharmacy.longitude,
				distance: calcDistanceInMiles(userLocation, {
					latitude: parseFloat(pharmacy.latitude),
					longitude: parseFloat(pharmacy.longitude),
				}),
				address: {
					...pharmacy.store.address,
					zipCode: searchValue,
				},
			}));
			setPharmacies(mappedResponse);
		}
		setIsLoading(false);
	};

	return (
		<Grid columns='1fr' stretch='100%'>
			<div className='full-page-request-input-wrapper select-doctor-tabs-wrapper'>
				<div className='full-page-input-wrapper select-doctor-wrapper pharmacy-wrapper'>
					<div className='position-relative'>
						<h3>{translate('selectPharmacy')}</h3>
						{props.selectedPharmacy && isChangePharmacyMode && (
							<div className='pharmacy-next-btn'>
								<Button onClick={() => setisChangePharmacyMode(false)} icon='close' />
							</div>
						)}
						{isChangePharmacyMode && (
							<div className='position-relative'>
								<i className='material-icons'>search</i>
								<input
									placeholder='Search by zip code'
									type='text'
									onChange={event => setSearchValue(event.target.value)}
									value={searchValue}
									onKeyDown={event =>
										event.keyCode === KeyCodes.ENTER ? searchPharmacies() : handleOnKeyDownCellPhoneNumbers(event)
									}
								/>
								<Button
									type='button'
									onClick={searchPharmacies}
									text={isLoading ? '' : props.intl.formatMessage({ id: 'findPharmacy' })}
									isLoading={isLoading}
								/>
							</div>
						)}
					</div>
					{!props.selectedPharmacy ||
						(!isChangePharmacyMode && (
							<div className='select-pharmacy-wrapper'>
								{props.selectedPharmacy && (
									<div className='single-pharmacy-box cursor-pointer' onClick={props.goForward}>
										<div className='flex flex-space-between'>
											<div>
												<div className='flex'>
													<h3>{props.selectedPharmacy.name}</h3>
													{props.selectedPharmacy.distance && (
														<span>
															{props.selectedPharmacy.distance} {translate('miles')}
														</span>
													)}
												</div>
												<div className='flex'>
													<p>
														<i className='material-icons'>room</i>
														{props.selectedPharmacy.address.street}
														<span className='bold --blue-color'>{props.selectedPharmacy.address.zip}</span>
														<i className='material-icons'>call</i>
														{props.selectedPharmacy.phoneNr}
													</p>
												</div>
											</div>
											<i className='material-icons'>arrow_forward</i>
										</div>
									</div>
								)}
								<div className='flex pharmacy-wrapper-btn'>
									<Button onClick={props.goForward} icon='done' />
									<Button onClick={() => setisChangePharmacyMode(true)} icon='arrow_forward' text={translate('change')} />
								</div>
							</div>
						))}
					{isChangePharmacyMode && (
						<>
							<div className='select-pharmacy-map-wrapper position-relative'>
								{pharmacies.length > 0 && (
									<div className='pharmacy-list'>
										<div className='pharmacy-list-inner'>
											{pharmacies.map(pharmacy => (
												<div
													className='single-pharmacy-box'
													onMouseEnter={() => setHoveredStoreNumber(pharmacy.storeNumber)}
													onMouseLeave={() => setHoveredStoreNumber(null)}>
													<div className='flex'>
														<div>
															<div className='flex'>
																<h3>{pharmacy.name}</h3>
																{pharmacy.distance && (
																	<span>
																		{pharmacy.distance} {translate('miles')}
																	</span>
																)}
															</div>
															<div className='flex'>
																<p>
																	<i className='material-icons'>room</i>
																	{pharmacy.address.street}
																	<span className='bold --blue-color'>{pharmacy.address.zipCode}</span>
																	<i className='material-icons'>call</i>
																	{pharmacy.phoneNr}
																</p>
															</div>
														</div>
													</div>
													<div className='pharmacy-delivery-options'>
														<Button
															text={translate('homeDelivery')}
															onClick={() =>
																props.setSelectedPharmacy({ ...pharmacy, deliveryTypeId: DeliveryTypes.HOME_DELIVERY })
															}
															icon='arrow_forward'
														/> 
														<Button
															type='button'
															className='active'
															onClick={() =>
																props.setSelectedPharmacy({ ...pharmacy, deliveryTypeId: DeliveryTypes.PHARMACY_PICK_UP })
															}
															icon='arrow_forward'
															text={translate('pickUp')}
														/>
													</div>
												</div>
											))}
										</div>
									</div>
								)}
								{isLoaded && (
									<GoogleMap
										mapContainerStyle={{
											height: '100%',
											width: '100%',
										}}
										zoom={3.5}
										center={mapCenter}
										options={{
											disableDefaultUI: true,
											zoomControl: true,
										}}
										onLoad={onMapLoad}>
										{pharmacies.map(marker => (
											<Marker
												icon={{
													url: `https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png`,
													origin: new window.google.maps.Point(0, 0),
													anchor: new window.google.maps.Point(15, 15),
													scaledSize: new window.google.maps.Size(
														marker.storeNumber === hoveredStoreNumber ? 45 : 30,
														marker.storeNumber === hoveredStoreNumber ? 45 : 30
													),
												}}
												key={`${marker.latitude}-${marker.longitude}`}
												position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
												onClick={() => {
													setSelectedSelectedMarker(marker);
												}}
											/>
										))}

										{selectedMarker && (
											<InfoWindow
												position={{ lat: parseFloat(selectedMarker.latitude), lng: parseFloat(selectedMarker.longitude) }}
												onCloseClick={() => {
													setSelectedSelectedMarker(null);
												}}>
												<div>
													<div className='single-pharmacy-box'>
														<div className='flex'>
															<div>
																<div className='flex'>
																	<h3>{selectedMarker.name}</h3>
																	{selectedMarker.distance && (
																		<span>
																			{selectedMarker.distance} {translate('miles')}
																		</span>
																	)}
																</div>
																<div className='flex'>
																	<p>
																		<i className='material-icons'>room</i>
																		{selectedMarker.address.street}
																		<span className='bold --blue-color'>{selectedMarker.address.zipCode}</span>
																		<i className='material-icons'>call</i>
																		{selectedMarker.phoneNr}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</InfoWindow>
										)}
									</GoogleMap>
								)}
								{loadError && <p>{translate('errorLoading')}</p>}
							</div>
						</>
					)}
				</div>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</Grid>
	);
};

export default injectIntl(SelectPharmacy);
