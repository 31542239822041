const HeartRate = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} viewBox='0 0 24 24' fill='none' {...props}>
		<path
			d='M12.4287 19.2231L12.791 19.8798C12.5655 20.0042 12.292 20.0042 12.0665 19.8798L12.4287 19.2231ZM12.429 7.27195L13.0168 7.73768C12.8746 7.91724 12.6581 8.02194 12.429 8.02195C12.1999 8.02195 11.9834 7.91727 11.8411 7.73772L12.429 7.27195ZM10.394 18L9.98186 18.6266L10.394 18ZM9.7906 18.4996C9.44649 18.269 9.35444 17.8032 9.58501 17.4591C9.81558 17.115 10.2814 17.0229 10.6256 17.2535L9.7906 18.4996ZM5.52197 11.7026C5.66817 12.0902 5.47251 12.5229 5.08495 12.6691C4.6974 12.8153 4.2647 12.6196 4.11851 12.232L5.52197 11.7026ZM12.0665 18.5664C15.8486 16.4801 17.934 14.393 18.9257 12.5862C19.9071 10.798 19.8233 9.28603 19.2759 8.209C18.1512 5.9958 14.9581 5.28726 13.0168 7.73768L11.8411 6.80621C14.5247 3.41887 19.04 4.43372 20.6132 7.52944C21.4148 9.10688 21.4273 11.1457 20.2407 13.3079C19.0643 15.4513 16.7174 17.7139 12.791 19.8798L12.0665 18.5664ZM10.8062 17.3734C11.4096 17.7703 12.0698 18.1686 12.791 18.5664L12.0665 19.8798C11.3137 19.4646 10.6199 19.0463 9.98186 18.6266L10.8062 17.3734ZM10.6256 17.2535C10.6852 17.2934 10.7454 17.3334 10.8062 17.3734L9.98186 18.6266C9.91754 18.5843 9.85379 18.5419 9.7906 18.4996L10.6256 17.2535ZM11.8411 7.73772C10.4506 5.98283 8.45857 5.8573 7.01285 6.69958C5.58085 7.53387 4.63669 9.35586 5.52197 11.7026L4.11851 12.232C2.97338 9.19644 4.19638 6.60446 6.25775 5.4035C8.30538 4.21055 11.1329 4.42851 13.0168 6.80618L11.8411 7.73772Z'
			fill='currentColor'
		/>
		<path
			d='M3.42859 14.8571H4.8132L6.89013 17L8.96705 12L11.044 14.8571H12.4286'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
export default HeartRate;
