import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const VolumeOff = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill='none'
		color={LightTheme.colors.grayFive}
		{...props}>
		<path
			d='M7.818 7.8H4.699a2 2 0 00-2 2v4.4a2 2 0 002 2h2.683a2 2 0 011.271.456l2.91 2.397a1 1 0 001.636-.772V13.2M13.498 10.8V5.719a1 1 0 00-1.636-.772L9.598 6.812'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
		<path
			d='M16.813 9s1.125 1.125 1.125 3-1.125 3-1.125 3m2.325-8.4s2.16 2.025 2.16 5.4c0 3.375-2.16 5.4-2.16 5.4'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M1.2 1.2l21.6 21.6' stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' />
	</svg>
);

export default VolumeOff;
