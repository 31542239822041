import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl'; 
import { UserRoles } from 'calls/enums/index.js';
import { useHelloDeviceState, useConferenceConfigurations } from 'calls/hooks/index.js';
import { IconButton, Notification } from 'calls/components/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js'; 
import { getUserRole } from 'infrastructure/auth.js';
import translate from 'i18n-translations/translate.jsx';

const PrivacyButtonsNotification = styled.div`
	display: flex;
	align-items: center;
	max-width: 520px;

	button {
		pointer-events: none;
		&:first-of-type {
			margin-right: ${LightTheme.spacing[1]}px;
		}
		&:nth-child(3) {
			margin-left: ${LightTheme.spacing[1]}px;
		}
	}

	p {
		margin: 0 0 0 ${LightTheme.spacing[3]}px;
		padding: 0;
		color: ${LightTheme.colors.grayZero};
		font-size: 14px;
	}
`;

/**
 * @param {object} props
 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.participant Local or remote participant
 *
 * */

const PrivacyNotification = ({ participant }) => {
	const intl = useIntl();

	const deviceState = useHelloDeviceState(participant);
	const conferenceConfigs = useConferenceConfigurations();

	const isCameraPrivacyOn = deviceState?.isCameraPrivacyOn;
	const isMicPrivacyOn = deviceState?.isMicPrivacyOn;
	const isTvMuted = conferenceConfigs.tvVolumeRange <= 0 && conferenceConfigs.tvVolumeRange >= -100 && deviceState?.tvPowerOn;

	const seeingOrHearingPhrase = () => {
		const userRole = intl.formatMessage({ id: getUserRole() === UserRoles.VISITOR ? 'theBaby' : 'thePatient' });
		let seeingOrHearing = '',
			micOrCamera = '',
			tvMuted = '';

		if (isCameraPrivacyOn && !isMicPrivacyOn) {
			seeingOrHearing = intl.formatMessage({ id: 'seeing' });
			micOrCamera = intl.formatMessage({ id: 'cam' });
			tvMuted = isTvMuted ? intl.formatMessage({ id: 'alsoTvIsMuted' }, { value: userRole }) : ' ';
		} else if (!isCameraPrivacyOn && isMicPrivacyOn) {
			seeingOrHearing = intl.formatMessage({ id: 'hearing' });
			micOrCamera = intl.formatMessage({ id: 'mic' });
			tvMuted = isTvMuted ? intl.formatMessage({ id: 'alsoTvIsMuted' }, { value: userRole }) : ' ';
		} else if (!isCameraPrivacyOn && !isMicPrivacyOn && isTvMuted) {
			return translate('tvIsMuted', { value: userRole });
		} else {
			seeingOrHearing = intl.formatMessage({ id: 'seeingAndHearing' });
			micOrCamera = intl.formatMessage({ id: 'camAndMic' });
			tvMuted = isTvMuted ? intl.formatMessage({ id: 'alsoTvIsMuted' }, { value: userRole }) : ' ';
		}

		return translate('seeingOrHearingPatient', {
			value: conferenceConfigs.customDisplayNames.helloName,
			value5: seeingOrHearing,
			value2: userRole,
			value3: micOrCamera,
			value4: tvMuted,
		});
	};

	return (
		<Notification
			background={conferenceConfigs.isDarkMode ? DarkTheme.colors.grayFour : LightTheme.colors.grayTen}
			color={LightTheme.colors.grayZero}
			layoutPosition='absolute'
			offset={LightTheme.spacing[5]}
			position='top-center'>
			<PrivacyButtonsNotification>
				{isCameraPrivacyOn && (
					<IconButton icon='videocam_off' background={LightTheme.colors.grayFourteen} color={LightTheme.colors.redOne} />
				)}
				{isMicPrivacyOn && (
					<IconButton icon='mic_off' background={LightTheme.colors.grayFourteen} color={LightTheme.colors.redOne} />
				)}
				{isTvMuted && <IconButton icon='tv_off' background={LightTheme.colors.grayFourteen} color={LightTheme.colors.redOne} />}
				<p>{seeingOrHearingPhrase()}</p>
			</PrivacyButtonsNotification>
		</Notification>
	);
};

export default PrivacyNotification;
