import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Grid from 'components/Grid.jsx';
import Input from 'components/Common/FormElements/Input.jsx';
import Button from 'components/Button.jsx';
import { sendPasswordRecoveredEmail } from 'api/users.js';
import { healthCareCdnUrl, APP_CONFIG } from 'constants/global-variables.js';
import { isMobileOrTablet } from 'infrastructure/helpers/commonHelpers.js';
import { isAuthenticated } from 'infrastructure/auth.js';
import LogOutUser from 'views/LogOutUser.jsx';
import classNames from 'classnames';
import Alert from 'components/Alert.jsx';

const ResetPassword = () => {
	const intl = useIntl();
	const [isLoading, setIsLoading] = useState(false);
	const [hasPasswordChanged, setHasPasswordChanged] = useState(false);
	const params = useRef(queryString.parse(window.location.search));
	const [error, setError] = useState(null);

	useEffect(() => {
		if (isMobileOrTablet()) {
			const publicUrl = APP_CONFIG.URL.localApiBasePath.split('//')[1];
			const uriScheme = APP_CONFIG.mobileAppUriScheme;
			window.location.replace(`${uriScheme}${publicUrl}reset-password?code=${params.current.c}&email=${params.current.e}`);
		}
	}, []);

	const submitForm = async ({ password }) => {
		setIsLoading(true);
		const response = await sendPasswordRecoveredEmail({
			email: params.current.e,
			password,
			code: params.current.c,
		});
		if (response.error) {
			setError(response.error.message);
		} else {
			setHasPasswordChanged(true);
		}
		setIsLoading(false);
	};

	if (isAuthenticated()) {
		return <LogOutUser />;
	}

	const isBanyanUrl = () => window.location.origin.includes('banyan');

	return (
		<>
			<div className={classNames('login recover-password-wrapper', isBanyanUrl() ? 'banyan-background' : '')}>
				<Grid width='500px' className='login__wrapper'>
					<div style={{ width: '300px' }}>
						{isBanyanUrl() && <img src='https://static.solaborate.com/temp/banyanlogo.svg' alt='banyan-logo' />}
						{!isBanyanUrl() && <img src={`${healthCareCdnUrl}hellocare-white-logo.svg`} alt='hello-health-logo' />}
					</div>
					<div className='login-form-wrapper validate-recover-password recover-password'>
						{hasPasswordChanged && (
							<div className='warning-message'>
								<div>
									<span>
										Your <span className='bold'>{isBanyanUrl() ? 'banyan' : 'hellocare'}</span> password was successfully changed.
										Please login again using your new password.
									</span>
									<Link to='/login' className='link'>
										Click here to go to login page
									</Link>
								</div>
							</div>
						)}
						{!hasPasswordChanged && (
							<div>
								<div className='warning-message'>
									<span>
										To verify your new password, please enter it once in each field below. Password are case-sensitive and must be
										at least 8 characters long. A good password should contain a mix of capital and lower-case letters, numbers
										and symbols.
									</span>
								</div>
								<div style={{ paddingTop: '25px' }}>
									<Formik
										initialValues={{ password: '', passwordConfirm: '' }}
										onSubmit={values => submitForm(values)}
										validationSchema={Yup.object().shape({
											password: Yup.string()
												.matches(
													// eslint-disable-next-line no-useless-escape
													/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*\(\)_\+\-\={}<>,\.\|""'~`:;\\?\/\[\] ]){8,}/,
													intl.formatMessage({ id: 'passwordCannotBeLessThan8Chart' })
												)
												.min(8, 'Min length is 8'),
											passwordConfirm: Yup.string()
												.required('Password confirmation is required')
												.oneOf([Yup.ref('password')], 'Must match password')
												.min(8, 'Min length is 8'),
										})}
										render={() => (
											<Form>
												<Field
													name='password'
													type='password'
													label='Password'
													placeholder='Password'
													variant='filled'
													component={Input}
												/>
												<Field
													name='passwordConfirm'
													type='password'
													label='Confirm Password'
													placeholder='Confirm Password'
													variant='filled'
													component={Input}
												/>
												<br />
												<Button type='submit' text='Continue' display='block' isLoading={isLoading} />
											</Form>
										)}
									/>
								</div>
							</div>
						)}
					</div>
				</Grid>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default ResetPassword;
