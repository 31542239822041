import * as React from 'react';

const Other = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
		<path stroke='currentColor' strokeMiterlimit={10} d='M12 3v7M6 15v-3a2 2 0 0 1 2-2h4M18 15v-3a2 2 0 0 0-2-2h-4M6 3h12' />
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			d='M20 15h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1ZM8 15H4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1Z'
		/>
	</svg>
);
export default Other;
