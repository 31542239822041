import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import React from 'react';

const HandoverSession = ({
	isDarkMode,
	sharedViewFromAnotherNurse,
	videoFeeds,
	openSharedView,
	saveSession,
	toggleTransfer,
	toggleReOrder,
    toggleDeleteSessionModal,
    setSessionToClose
}) => (
	<>
		<h4>{translate('sharedView')}</h4>
		<ul className='monitoring-session-ul'>
			<li className={sharedViewFromAnotherNurse.isActive ? 'active-monitoring-session' : ''} key={sharedViewFromAnotherNurse.id}>
				<div onClick={event => openSharedView(event, sharedViewFromAnotherNurse)}>
					<img
						src={`${healthCareCdnUrl}monitoring/session${sharedViewFromAnotherNurse.isActive ? '-active' : ''}.svg`}
						alt='close_session'
					/>
					<span>
						{translate('sharedBy', {
							value1: sharedViewFromAnotherNurse.userCreated.firstName,
							value2: sharedViewFromAnotherNurse.userCreated.lastName,
						})}
					</span>
				</div>
				{sharedViewFromAnotherNurse.isActive && (
					<div className='flex'>
						<div
							className={videoFeeds.length === 0 || !sharedViewFromAnotherNurse.isActive ? 'disabled' : ''}
							onClick={() => saveSession(sharedViewFromAnotherNurse)}
							id='save-session'>
							<img
								src={`${healthCareCdnUrl}${isDarkMode ? 'dark-mode/save-gray.svg' : 'monitoring/save.svg'}`}
								alt='save_session'
							/>
							<img className='active-session-img' src={`${healthCareCdnUrl}monitoring/save-active.svg`} alt='save_session' />
							<span>{translate('save')}</span>
						</div>
						<div
							className={videoFeeds.length < 1 || !sharedViewFromAnotherNurse.isActive ? 'disabled' : ''}
							onClick={() => toggleTransfer(sharedViewFromAnotherNurse)}
							id='transfer-session'>
							<i
								className='material-symbols-outlined'
								style={{
									color: isDarkMode ? '#787F89' : '#3C4257',
								}}>
								forward
							</i>
							<span>{translate('transfer')}</span>
						</div>
						<div
							className={videoFeeds.length <= 1 || !sharedViewFromAnotherNurse.isActive ? 'disabled' : ''}
							onClick={() => toggleReOrder(sharedViewFromAnotherNurse)}
							id='reorder-session'>
							<img
								src={`${healthCareCdnUrl}${isDarkMode ? 'dark-mode/sort-gray.svg' : 'monitoring/sort.svg'}`}
								alt='re-order-session'
								className='position-relative edit-order-img'
							/>
							<img
								src={`${healthCareCdnUrl}monitoring/sort-active.svg`}
								alt='re-order-session'
								className='position-relative edit-order-img active-session-img'
							/>
							<span>{translate('sort')}</span>
						</div>
						<div
							onClick={() => toggleDeleteSessionModal(sharedViewFromAnotherNurse)}
							className={sharedViewFromAnotherNurse.isCurrent || !sharedViewFromAnotherNurse.isActive ? 'disabled' : ''}
							id='delete-session'>
							<img
								src={`${healthCareCdnUrl}${isDarkMode ? 'dark-mode/delete-gray.svg' : 'monitoring/trash.svg'}`}
								alt='delete_session'
							/>
							<img className='active-session-img' src={`${healthCareCdnUrl}monitoring/trash-active.svg`} alt='delete_session' />
							<span>{translate('delete')}</span>
						</div>
						<div
							className={videoFeeds.length === 0 || !sharedViewFromAnotherNurse.isActive ? 'disabled' : ''}
							onClick={setSessionToClose}
							id='close-session'>
							<img
								src={`${healthCareCdnUrl}${isDarkMode ? 'dark-mode/remove-gray.svg' : 'monitoring/remove.svg'}`}
								alt='close_session'
							/>
							<img className='active-session-img' src={`${healthCareCdnUrl}monitoring/remove-active.svg`} alt='close_session' />
							<span>{translate('close')}</span>
						</div>
					</div>
				)}
			</li>
		</ul>
	</>
);

export default HandoverSession;
