import { useIntl } from 'react-intl';

const CallSettings = ({ handleChangeConfig, submitParams, defaultConfigs, title, callConfigsDescription }) => {
	const intl = useIntl();

	return (
		<>
			<h3>{title}</h3>
			<p>{callConfigsDescription}</p>
			{defaultConfigs.map(item => (
				<div className='flex call-config-settings' key={item.key}>
					<div className='call-config-type'>
						<div>
							<img src={item.icon} alt='icon' />
						</div>
						<section>
							<p>
								{intl.formatMessage({ id: item.title })}
								<br />
								<span> {intl.formatMessage({ id: item.description })}</span>
							</p>
						</section>
					</div>
					<div className='toggle-switch' onClick={() => handleChangeConfig({ name: item.name }, false)}>
						<input type='checkbox' checked={submitParams[item.name].value} onChange={() => null} />
						<span className='toggle-body' />
					</div>
				</div>
			))}
		</>
	);
};

export default CallSettings;
