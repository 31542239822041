import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const WhiteboardIcon = props => (
	<svg
		width={18}
		height={18}
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		color={LightTheme.colors.graySeven}
		{...props}>
		<path
			d='M12.8282 11.0712H11.414V9.657L17.0708 4.00015L18.4851 5.41436L12.8282 11.0712Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='square'
		/>
		<path d='M17.0703 6.82812L15.6561 5.41391' stroke='currentColor' strokeWidth='1.5' strokeLinecap='square' />
		<path d='M10.707 11.7783L11.4141 11.0712' stroke='currentColor' strokeWidth='1.5' strokeLinecap='square' />
		<path
			d='M15 3.5V3C15 1.89543 14.1046 1 13 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H13C14.1046 19 15 18.1046 15 17V12'
			stroke='currentColor'
			strokeWidth='1.5'
		/>
		<path
			d='M9.72975 12.9687C8.80855 13.1161 6.50098 15.9998 5.12373 13.7054C3.5947 11.1582 7.04512 10.2302 6.9274 8.6475C6.80969 7.06482 5.01361 6.36309 4.53516 5.79199'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
		/>
	</svg>
);
export default WhiteboardIcon;
