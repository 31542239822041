import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Pill = ({ color = LightTheme.colors.graySeven, strokeWidth = 2, ...props }) => (
	<svg width={28} height={28} fill='none' xmlns='http://www.w3.org/2000/svg' color={color} {...props}>
		<rect
			x={3.105}
			y={9.463}
			width={8.167}
			height={21}
			rx={4.083}
			transform='rotate(-45 3.105 9.463)'
			stroke='currentColor'
			strokeWidth={strokeWidth}
		/>
		<path d='M11.204 17.562s-1.06-1.638 1.538-4.237 4.237-1.537 4.237-1.537' stroke='currentColor' strokeWidth={strokeWidth} />
	</svg>
);

export default Pill;
