import React, { Component } from 'react';
import CallStats from 'components/CallStats';

class PeerStats extends Component {
	state = {
		videoBytesSent: null,
		audioBytesSent: null,
		videoBytesRec: null,
		audioBytesRec: null,
		frameRate: null,
		resolution: { width: 0, height: 0 },
		codec: null,
		transportType: null,
	};

	previousStats = {
		outbound: {},
		inbound: {},
	};

	currentStats = {
		outbound: {},
		inbound: {},
		videoResolution: undefined,
		videoOutboundResolution: undefined,
		audioCodec: undefined,
		videoCodec: undefined,
		videoOutboundCodec: undefined,
		audioOutboundCodec: undefined,
		transportType: undefined,
	};

	RTCStatsType = {
		OUTBOUND: 'outbound-rtp',
		INBOUND: 'inbound-rtp',
		TRACK: 'track',
		CODEC: 'codec',
		REMOTE_CANDIDATE: 'remote-candidate',
	};

	RTCMediaType = {
		AUDIO: 'audio',
		VIDEO: 'video',
	};

	fetchTimeInSeconds = 3;

	byteToKilobit = 125;

	getStatsInterval = null;

	currInboundReports = [];

	currOutboundReports = [];

	componentDidMount = async () => {
		this.getStatsInterval = setInterval(async () => {
			await this.getStats();
			const newStats = JSON.parse(JSON.stringify(this.currentStats));
			this.setState(this.getState(), () => {
				this.previousStats = newStats;
			});
		}, this.fetchTimeInSeconds * 1000);
	};

	getState = () => {
		if (!this.props.isLocalSrc) {
			return {
				videoBytesSent:
					this.currentStats.outbound.video && this.previousStats.outbound.video
						? this.getDifference(this.currentStats.outbound.video.bytesSent, this.previousStats.outbound.video.bytesSent) /
						  this.fetchTimeInSeconds /
						  this.byteToKilobit
						: null,
				audioBytesSent:
					this.currentStats.outbound.audio && this.previousStats.outbound.audio
						? this.getDifference(this.currentStats.outbound.audio.bytesSent, this.previousStats.outbound.audio.bytesSent) /
						  this.fetchTimeInSeconds /
						  this.byteToKilobit
						: null,
				videoBytesRec:
					this.currentStats.inbound.video && this.previousStats.inbound.video
						? this.getDifference(this.currentStats.inbound.video.bytesReceived, this.previousStats.inbound.video.bytesReceived) /
						  this.fetchTimeInSeconds /
						  this.byteToKilobit
						: null,
				audioBytesRec:
					this.currentStats.inbound.audio && this.previousStats.inbound.audio
						? this.getDifference(this.currentStats.inbound.audio.bytesReceived, this.previousStats.inbound.audio.bytesReceived) /
						  this.fetchTimeInSeconds /
						  this.byteToKilobit
						: null,
				frameRate: this.getInboundFrameRate(),
				resolution:
					this.getInboundFrameRate() && this.currentStats.videoResolution
						? this.currentStats.videoResolution
						: { width: 0, height: 0 },
				codec:
					// eslint-disable-next-line no-nested-ternary
					!this.currentStats.videoCodec && !this.currentStats.audioCodec
						? 'N/A'
						: this.currentStats.videoCodec
						? `${this.currentStats.audioCodec}\\${this.currentStats.videoCodec}`
						: `${this.currentStats.audioCodec}`,
				transportType: this.currentStats.transportType ? this.currentStats.transportType : 'N/A',
			};
		}
		return {
			frameRate:
				this.currentStats.outbound.video && this.previousStats.outbound.video
					? this.getDifference(this.currentStats.outbound.video.framesEncoded, this.previousStats.outbound.video.framesEncoded) /
					  this.fetchTimeInSeconds
					: null,
			resolution: this.currentStats.videoOutboundResolution ? this.currentStats.videoOutboundResolution : { width: 0, height: 0 },
			codec:
				// eslint-disable-next-line no-nested-ternary
				!this.currentStats.videoOutboundCodec && !this.currentStats.audioOutboundCodec
					? 'N/A'
					: this.currentStats.videoOutboundCodec
					? `${this.currentStats.audioOutboundCodec}\\${this.currentStats.videoOutboundCodec}`
					: `${this.currentStats.audioOutboundCodec}`,
		};
	};

	getDifference = (a, b) => {
		return a - b >= 0 ? a - b : 0;
	};

	componentWillUnmount = () => {
		if (this.getStatsInterval) {
			clearInterval(this.getStatsInterval);
			this.getStatsInterval = null;
		}
	};

	getStats = async () => {
		const stats = await this.props.callManager.getStats();

		this.currInboundReports = [];
		this.currOutboundReports = [];

		stats.forEach(report => {
			if (report.type === this.RTCStatsType.INBOUND) {
				this.currInboundReports.push(report);
				const codecReport = stats.get(report.codecId);
				if (codecReport) {
					codecReport.kind = report.kind;
					codecReport.remoteSource = true;
					this.currInboundReports.push(codecReport);
				}
				if (report.kind === this.RTCMediaType.VIDEO) {
					const trackReport = stats.get(report.trackId);
					if (trackReport) {
						trackReport.kind = report.kind;
						this.currInboundReports.push(trackReport);
					}
				} else if (report.kind === this.RTCMediaType.AUDIO) {
					const transportReport = stats.get(report.transportId);
					if (transportReport) {
						const candidatePair = stats.get(transportReport.selectedCandidatePairId);
						if (candidatePair) {
							const remoteCandidate = stats.get(candidatePair.remoteCandidateId);
							if (remoteCandidate) {
								remoteCandidate.kind = report.kind;
								this.currInboundReports.push(remoteCandidate);
							}
						}
					}
				}
			} else if (report.type === this.RTCStatsType.OUTBOUND) {
				this.currOutboundReports.push(report);
				const codecReport = stats.get(report.codecId);
				if (codecReport) {
					codecReport.kind = report.kind;
					codecReport.remoteSource = false;
					this.currOutboundReports.push(codecReport);
				}

				if (report.kind === this.RTCMediaType.VIDEO) {
					const trackReport = stats.get(report.trackId);
					if (trackReport) {
						trackReport.kind = report.kind;
						this.currOutboundReports.push(trackReport);
					}
				}
			}
		});

		this.setCurrentStats();
	};

	setCurrentStats = () => {
		// Inbound
		[this.currentStats.inbound.video] = this.currInboundReports
			.filter(x => x.kind === this.RTCMediaType.VIDEO && x.type === this.RTCStatsType.INBOUND)
			.sort((a, b) => (a.trackId > b.trackId ? -1 : 1));

		const [track] = this.currInboundReports
			.filter(x => x.kind === this.RTCMediaType.VIDEO && x.type === this.RTCStatsType.TRACK && x.remoteSource === true)
			.sort((a, b) => (a.id > b.id ? -1 : 1));

		if (track && track.frameHeight) {
			this.currentStats.videoResolution = { width: track.frameWidth, height: track.frameHeight };
		} else {
			this.currentStats.videoResolution = { width: 0, height: 0 };
		}

		const videoCodec = this.currInboundReports.find(
			x => x.kind === this.RTCMediaType.VIDEO && x.type === this.RTCStatsType.CODEC && x.remoteSource === true
		);

		if (videoCodec) {
			this.currentStats.videoCodec = videoCodec.mimeType.replace('video/', '');
		}
		const audioCodec = this.currInboundReports.find(
			x => x.kind === this.RTCMediaType.AUDIO && x.type === this.RTCStatsType.CODEC && x.remoteSource === true
		);

		if (audioCodec) {
			this.currentStats.audioCodec = audioCodec.mimeType.replace('audio/', '').toUpperCase();
		}

		[this.currentStats.inbound.audio] = this.currInboundReports
			.filter(x => x.kind === this.RTCMediaType.AUDIO && x.type === this.RTCStatsType.INBOUND)
			.sort((a, b) => (a.trackId > b.trackId ? -1 : 1));

		const remoteCandidate = this.currInboundReports.find(
			x => x.kind === this.RTCMediaType.AUDIO && x.type === this.RTCStatsType.REMOTE_CANDIDATE
		);
		if (remoteCandidate) {
			this.currentStats.transportType = remoteCandidate.candidateType === 'relay' ? 'Relay' : 'P2P';
		}

		// Outbound
		[this.currentStats.outbound.video] = this.currOutboundReports
			.filter(x => x.kind === this.RTCMediaType.VIDEO && x.type === this.RTCStatsType.OUTBOUND && x.trackId)
			.sort((a, b) => (a.trackId > b.trackId ? -1 : 1));

		[this.currentStats.outbound.audio] = this.currOutboundReports
			.filter(x => x.kind === this.RTCMediaType.AUDIO && x.type === this.RTCStatsType.OUTBOUND)
			.sort((a, b) => (a.trackId > b.trackId ? -1 : 1));

		const [outboundTrack] = this.currOutboundReports
			.filter(x => x.kind === this.RTCMediaType.VIDEO && x.type === this.RTCStatsType.TRACK && x.remoteSource === false)
			.sort((a, b) => (a.id > b.id ? -1 : 1));

		if (outboundTrack && outboundTrack.frameWidth) {
			this.currentStats.videoOutboundResolution = {
				width: outboundTrack.frameWidth,
				height: outboundTrack.frameHeight,
			};
		} else {
			this.currentStats.videoOutboundResolution = {
				width: 0,
				height: 0,
			};
		}

		const videoOutboundCodec = this.currOutboundReports.find(
			x => x.kind === this.RTCMediaType.VIDEO && x.type === this.RTCStatsType.CODEC && x.remoteSource === false
		);

		if (videoOutboundCodec) {
			this.currentStats.videoOutboundCodec = videoOutboundCodec.mimeType.replace('video/', '');
		}
		const audioOutboundCodec = this.currOutboundReports.find(
			x => x.kind === this.RTCMediaType.AUDIO && x.type === this.RTCStatsType.CODEC && x.remoteSource === false
		);

		if (audioOutboundCodec) {
			this.currentStats.audioOutboundCodec = audioOutboundCodec.mimeType.replace('audio/', '').toUpperCase();
		}
	};

	getInboundFrameRate = () => {
		return this.currentStats.inbound.video && this.previousStats.inbound.video
			? this.getDifference(this.currentStats.inbound.video.framesDecoded, this.previousStats.inbound.video.framesDecoded) /
					this.fetchTimeInSeconds
			: null;
	};

	render() {
		const {
			audioBytesSent,
			videoBytesSent,
			videoBytesRec,
			audioBytesRec,
			frameRate,
			resolution,
			codec,
			transportType,
		} = this.state;

		let stats;
		if (!this.props.isLocalSrc) {
			stats = [
				{ key: 'Video', value: `${Math.round(videoBytesSent)}kbs↑ ${Math.round(videoBytesRec)}kbs↓` },
				{ key: 'Audio', value: `${Math.round(audioBytesSent)}kbs↑ ${Math.round(audioBytesRec)}kbs↓` },
				{ key: 'Codec', value: codec },
				{ key: 'Framerate', value: `${Math.round(frameRate)} FPS` },
				{ key: 'Resolution', value: `${resolution.width}x${resolution.height}` },
				{ key: 'TT', value: transportType },
			];
		} else {
			stats = [
				{ key: 'Framerate', value: `${Math.round(frameRate)} FPS` },
				{ key: 'Resolution', value: `${resolution.width}x${resolution.height}` },
				{ key: 'Codec', value: codec },
			];
		}

		return <CallStats stats={stats} connectionQualityColor='var(--blue-2)' />;
	}
}

export default PeerStats;
