import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { ActiveTab, CareEventPriority, CareEventStatus, CareEventStatusType, CareEventType, SLA } from 'constants/care-events.js';
import Admission from 'icons/CareEvents/Admission.jsx';
import Discharge from 'icons/CareEvents/Discharge.jsx';
import Education from 'icons/CareEvents/Education.jsx';
import Other from 'icons/CareEvents/Other.jsx';
import Rounding from 'icons/CareEvents/Rounding.jsx';

export const getStatusDetails = status => {
	switch (status) {
		case CareEventStatus.NEW:
			return { clsName: 'new', name: 'new' };
		case CareEventStatus.IN_PROGRESS:
			return { clsName: 'in-progress', name: 'inProgress' };
		case CareEventStatus.COMPLETED:
			return { clsName: 'completed', name: 'completed' };
		default:
			return { clsName: '', name: 'selectStatus' };
	}
};

export const getCareEventTabClassName = index => {
	switch (index) {
		case ActiveTab.ALL_EVENTS:
			return 'all-events';
		case ActiveTab.IN_PROGRESS:
			return 'in-progress';
		case ActiveTab.NEW:
			return 'new';
		case ActiveTab.COMPLETED:
			return 'completed';
		case ActiveTab.MY_CARE_EVENTS:
			return 'my-events';
		default:
			return 'all-events';
	}
};

export const getSelectedStatus = status => {
	switch (status) {
		case 1:
			return CareEventStatus.IN_PROGRESS;
		case 2:
			return CareEventStatus.NEW;
		case 3:
			return CareEventStatus.COMPLETED;
		case 4:
			return CareEventStatus.MY_CARE_EVENTS;
		default:
			return null;
	}
};

export const formatOptionLabel = data => (
	<div className='flex flex-align-center'>
		<ProfilePicture className='doctor-request-img' profilePicture={data.profilePicture} fullName={data.label} />
		<div className='flex column-direction care-team-info'>
			<span>{data.label}</span>
			<span className='additional-info'>{data.mrn || data.email}</span>
		</div>
	</div>
);

export const SingleValue = ({ data }) => (
	<div className='flex flex-align-center position-absolute left-10'>
		<ProfilePicture
			key={data.id}
			className='doctor-request-img care-team-img'
			profilePicture={data.profilePicture}
			fullName={data.label}
		/>
		<span>{data.label}</span>
	</div>
);

export const filterOptions = (option, inputValue) => {
	const labelIncludes = option.label.toLowerCase().includes(inputValue.toLowerCase());
	const mrnIncludes = option.mrn.toString().includes(inputValue);
	return labelIncludes || mrnIncludes;
};

export const getOptionLabel = option => `${option.label} ${option.mrn}`;

export const getPriorityColor = priorityId => {
	switch (priorityId) {
		case CareEventPriority.LOW:
			return 'low';
		case CareEventPriority.MEDIUM:
			return 'medium';
		case CareEventPriority.HIGH:
			return 'high';
		default:
			return 'selectPriority';
	}
};

export const getStatusDescription = status => {
	switch (status) {
		case CareEventStatus.NEW:
			return 'new';
		case CareEventStatus.IN_PROGRESS:
			return 'inProgress';
		case CareEventStatus.COMPLETED:
			return 'completed';
		default:
			return '';
	}
};

export const getSLAColor = id => {
	switch (id) {
		case SLA.LOW:
			return 'green';
		case SLA.MEDIUM:
			return 'orange';
		case SLA.HIGH:
			return 'red';
		default:
			return '';
	}
};

export const getCareEventStatusOptions = (statusId, intl) => {
	switch (statusId) {
		case CareEventStatus.NEW:
			return [
				{ label: intl.formatMessage({ id: CareEventStatusType.NEW }), value: CareEventStatus.NEW },
				{ label: intl.formatMessage({ id: CareEventStatusType.IN_PROGRESS }), value: CareEventStatus.IN_PROGRESS },
			];
		case CareEventStatus.IN_PROGRESS:
			return [
				{ label: intl.formatMessage({ id: CareEventStatusType.IN_PROGRESS }), value: CareEventStatus.IN_PROGRESS },
				{ label: intl.formatMessage({ id: CareEventStatusType.COMPLETED }), value: CareEventStatus.COMPLETED },
			];
		case CareEventStatus.COMPLETED:
			return [{ label: intl.formatMessage({ id: CareEventStatusType.COMPLETED }), value: CareEventStatus.COMPLETED }];
		default:
			return [];
	}
};

export const getCareEventTypeIcon = (type, isDarkMode) => {
	switch (type) {
		case CareEventType.DISCHARGE:
			return <Discharge color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
		case CareEventType.EDUCATION:
			return <Education color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
		case CareEventType.ROUNDING:
			return <Rounding color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
		case CareEventType.OTHER:
			return <Other color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
		default:
			return <Admission color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
	}
};

export const formatOptionLabelHospitalAtHome = data => (
	<div className='flex flex-align-center'>
		<ProfilePicture
			className='doctor-request-img'
			profilePicture={data.profilePicture}
			fullName={data.label || `${data.firstName} ${data.lastName}`}
		/>
		<div className='flex column-direction care-team-info'>
			<span>{data.label}</span>
			<span className='additional-info'>
				{data.firstName} {data.lastName}
			</span>
		</div>
	</div>
);
