import React from 'react';
import Select from 'react-select';
import { Button } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';

const WhiteboardConfigFilters = props => {
	const transformWhiteboardArray = (array, sector = '') => {
		if (!Array.isArray(array) || array?.length === 0 || !sector) {
			return [];
		}
		const newArray = [...array];
		newArray.unshift({ value: '0', label: props.intl.formatMessage({ id: sector }) });
		return newArray;
	};

	return (
		<>
			<div>
				<label>{translate('selectHospital')}</label>
				<Select
					value={props.selectedHospitalId ? props.hospitals.find(x => x.value === props.selectedHospitalId) : null}
					isDisabled={props.isHospitalDropdownDisabled}
					classNamePrefix='react-select'
					options={transformWhiteboardArray(props.hospitals, 'allHospitals')}
					onChange={props.setSelectedHospital}
					placeholder={props.intl.formatMessage({
						id: props.selectedHealthSystem?.value !== '0' ? 'allHospitals' : 'hospitals',
					})}
				/>
			</div>
			{props.showDepartmentsFilter && (
				<div>
					<label>{translate('selectDepartment')}</label>
					<Select
						value={
							props.selectedDepartmentId && props.selectedHospitalId !== '0'
								? props.departments?.find(x => x.value === props.selectedDepartmentId)
								: null
						}
						isDisabled={props.isDepartmentDropdownDisabled || props.selectedHospitalId === '0'}
						classNamePrefix='react-select'
						options={transformWhiteboardArray(props.departments, 'allDepartments')}
						onChange={props.setSelectedDepartment}
						placeholder={props.intl.formatMessage({
							id:
								props.selectedHealthSystem?.value !== '0' && props.selectedHospitalId && props.selectedHospitalId !== '0'
									? 'allDepartments'
									: 'departments',
						})}
					/>
				</div>
			)}
			<Button text={props.intl.formatMessage({ id: 'addSleepSchedule' })} onClick={props.toggleConfigModal} />
		</>
	);
};

export default WhiteboardConfigFilters;
