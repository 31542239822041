import * as React from 'react';

const CalendarIcon = props => (
	<svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M18.667 5H6.66699C5.01014 5 3.66699 6.34315 3.66699 8V18C3.66699 19.6569 5.01014 21 6.66699 21H18.667C20.3238 21 21.667 19.6569 21.667 18V8C21.667 6.34315 20.3238 5 18.667 5Z'
			stroke='currentColor'
			strokeWidth='1.5'
		/>
		<path d='M3.66699 10.5H21.667' stroke='currentColor' strokeWidth='1.5' />
		<path d='M8.66699 7V3M16.667 7V3' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
		<path
			d='M15.667 17.5C15.667 16.6716 16.3386 16 17.167 16H18.167C18.9954 16 19.667 16.6716 19.667 17.5C19.667 18.3284 18.9954 19 18.167 19H17.167C16.3386 19 15.667 18.3284 15.667 17.5Z'
			fill='currentColor'
		/>
	</svg>
);
export default CalendarIcon;
