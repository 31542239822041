import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import Calendar from 'react-calendar';
import moment from 'moment';
import CreatableSelect from 'react-select/creatable';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { Button, Grid, Loader } from 'components';
import { addPhysicalExercise } from 'api/physicalExercises';
import translate from 'i18n-translations/translate.jsx';
import { getPhysicalExerciseIcon } from 'infrastructure/helpers/physicalExerciseHelper';
import { AlertTypes, HttpStatusCodes, IntensityTypes, PhysicalExerciseTabs } from 'constants/enums.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';

const AddPhysicalExercise = props => {
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedType, setSelectedType] = useState(null);
	const [intensities, setIntensities] = useState([]);
	const [isSaveEnabled, setIsSaveEnabled] = useState(false);
	const [selectedDays, setSelectedDays] = useState([]);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [validationError, setValidationError] = useState(null);
	const [selectedIntensityType, setSelectedIntensityType] = useState(null);

	const intl = useIntl();

	useEffect(() => {
		setIsSaveEnabled(
			selectedDays.length ===
				intensities.find(intensity => intensity.type === IntensityTypes.DAYS_TO_ACHIEVE_TARGET)?.selectedValue
		);
	}, [selectedDays, intensities]);

	const onSelectedCategory = exerciseValue => {
		setSelectedCategory(exerciseValue);
		resetData();
	};

	const resetData = () => {
		setSelectedType(null);
		setIsSaveEnabled(false);
		setIntensities([]);
	};

	const cancel = () => {
		props.setStep(PhysicalExerciseTabs.EXERCISES_LIST);
		setSelectedCategory([]);
		resetData();
	};

	const onSelectedTypeOfExercise = typeValue => {
		resetData();
		const intensitiesPerType = props.categories
			.find(category => category.id === selectedCategory)
			.types.find(categoryType => categoryType.id === typeValue).settingsType;
		setSelectedType(typeValue);
		const intensityValues = intensitiesPerType.map(intensity => ({
			...intensity,
			selectedValue: intensity.values[0],
		}));
		setIntensities(intensityValues);
		if (intensitiesPerType.filter(intensity => intensity.type === IntensityTypes.DAYS_TO_ACHIEVE_TARGET).length === 0) {
			setIsSaveEnabled(true);
		}
		setValidationError(null);
	};

	const transformForSelect = array =>
		array.map(item => ({ value: item.id, label: intl.formatMessage({ id: stringToCamelCase(item.name) }), icon: item.type }));

	const transformIntensities = array => array.map(item => ({ value: item, label: item, selectedValue: item }));

	const formatOptionLabel = ({ label, icon }) => (
		<div className='flex flex-align-center'>
			<div className='physical-exercise-category'>
				<img src={`${healthCareCdnUrl}physical-exercises/${getPhysicalExerciseIcon(icon, true)}.svg?v2`} alt={icon} />
			</div>
			<div>{label}</div>
		</div>
	);

	const changeSelectedIntensityValue = (selectedValue, intensity) => {
		if (intensity.type === IntensityTypes.DAYS_TO_ACHIEVE_TARGET) {
			setSelectedDays([]);
		}
		setIntensities(prevState => {
			const newIntensities = [...prevState];
			const selectedIntensity = newIntensities.find(item => item.type === intensity.type);
			selectedIntensity.selectedValue = selectedIntensity.values.find(val => val === selectedValue) || +selectedValue;
			return newIntensities;
		});
		setValidationError(null);
	};

	const saveData = async () => {
		setIsLoading(true);
		const intensitiesToSend = intensities.map(item => ({ type: item.type, value: item?.selectedValue ?? item.values[0] }));
		const dataToSend = {
			typeId: selectedType,
			categoryId: selectedCategory,
			dates: selectedDays,
			settingsExercise: intensitiesToSend,
		};
		const response = await addPhysicalExercise(props.physicalTherapyId, dataToSend, props.patientId);
		if (response.error) {
			const errorMessage =
				response.error.response.status === HttpStatusCodes.CONFLICT ? translate('sameExerciseSameDay') : response.error.message;
			setError(errorMessage);
			setIsLoading(false);
			return;
		}
		props.saveInitialData();
		setIsLoading(false);
	};

	const onSelectedDateChanged = date => {
		const givenDate = moment(date).format('MM/DD/YYYY');
		if (selectedDays.some(item => item === givenDate)) {
			return;
		}
		const intensityValue = intensities.find(intensity => intensity.type === IntensityTypes.DAYS_TO_ACHIEVE_TARGET).selectedValue;
		const isArrayFull = selectedDays.length === intensityValue;
		if (!isArrayFull) {
			const selectedDaysList = [...selectedDays];
			selectedDaysList.push(givenDate);
			setSelectedDays(selectedDaysList);
		} else {
			setSelectedDays([givenDate]);
		}
	};

	const validateIntensityValues = (event, intensity) => {
		const { value } = event.target;
		const numValue = parseInt(value, 10);
		const intensityValues = intensity.values;
		const minValue = intensity.type === IntensityTypes.MAX_RANGE_OF_MOTION ? 0 : intensityValues.reduce((a, b) => Math.min(a, b));
		const maxValue =
			intensity.type === IntensityTypes.MAX_RANGE_OF_MOTION ? 100 : intensityValues.reduce((a, b) => Math.max(a, b));
		setSelectedIntensityType(intensity.type);
		if (/^[0-9]*$/.test(value) && numValue >= minValue && numValue <= maxValue) {
			setValidationError(null);
			return;
		}
		if (numValue < minValue || numValue > maxValue) {
			setValidationError(
				translate('rangeExceeded', {
					value1: minValue,
					value2: maxValue,
				})
			);
			return;
		}
		setValidationError(translate('containOnlyNumbers'));
	};

	return (
		<>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div className='text-align-center'>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading && (
				<>
					<div className='flex'>
						<div>
							<h4>
								{translate('addMedicalInfo', {
									value: intl.formatMessage({ id: 'exercise' }).toLowerCase(),
								})}
							</h4>
						</div>
					</div>
					<div className='physical-exercise-wrapper'>
						<div className='top-30'>
							<label htmlFor='category'>*{translate('category')}</label>
							<Select
								options={transformForSelect(props.categories)}
								value={transformForSelect(props.categories).filter(option => option.value === selectedCategory)}
								onChange={event => onSelectedCategory(event.value)}
								placeholder={intl.formatMessage({ id: 'selectCategory' })}
								classNamePrefix='react-select'
								name='selectedCategory'
								formatOptionLabel={formatOptionLabel}
							/>
						</div>
						<div className='top-30'>
							<label htmlFor='exercise'>*{translate('exercise')}</label>
							<Select
								options={
									selectedCategory &&
									transformForSelect(props.categories.find(category => category.id === selectedCategory).types)
								}
								value={
									selectedCategory &&
									transformForSelect(props.categories.find(category => category.id === selectedCategory).types).filter(
										option => option.value === selectedType
									)
								}
								onChange={event => onSelectedTypeOfExercise(event.value)}
								placeholder={intl.formatMessage({ id: 'selectTypeOfExercise' })}
								classNamePrefix='react-select'
								name='category'
								isDisabled={!selectedCategory}
							/>
						</div>

						{selectedType && (
							<div>
								<p className='exercise-intensity'>{translate('regulateExerciseIntensity')}</p>
								<div style={{ marginTop: '30px' }}>
									{intensities.map(intensity => (
										<React.Fragment key={intensity.id}>
											<div className='flex flex-space-between flex-align-center'>
												<h5>{intl.formatMessage({ id: stringToCamelCase(intensity.name) })}</h5>
												<CreatableSelect
													isClearable={false}
													options={transformIntensities(intensity.values)}
													onChange={event => {
														changeSelectedIntensityValue(event.value, intensity);
													}}
													value={[{ label: intensity.selectedValue, value: intensity.selectedValue }]}
													className='exercise-intensity-values'
													classNamePrefix='react-select width-20'
													name='selectedIntensity'
													onKeyDown={event => validateIntensityValues(event, intensity)}
												/>
											</div>
											<div className='exercise-validation'>
												<span className='red-error'>{selectedIntensityType === intensity.type && validationError}</span>
											</div>
										</React.Fragment>
									))}
								</div>
								<div className='exercises-calendar'>
									<h5 className='margin-bottom-0'>{translate('selectDatesForExercise')}</h5>
									<Calendar
										className='appointment-calendar'
										formatShortWeekday={(locale, value) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][value.getDay()]}
										calendarType='US'
										onChange={date => {
											onSelectedDateChanged(date);
										}}
										minDate={new Date()}
										tileClassName={({ date }) => {
											if (selectedDays.find(item => item === moment(date).format('MM/DD/YYYY'))) {
												return 'highlight';
											}
											return '';
										}}
										prev2Label={null}
										next2Label={null}
									/>
								</div>
							</div>
						)}
					</div>
					<div className='flex'>
						<Button onClick={cancel} type='button' text={intl.formatMessage({ id: 'cancel' })} className='small white' />
						<Button
							isDisabled={!isSaveEnabled || validationError}
							onClick={saveData}
							type='button'
							text={intl.formatMessage({ id: 'save' })}
							className='small'
						/>
					</div>
					<PopUpAlert
						alertType={AlertTypes.DANGER}
						display={error}
						onAlertClose={() => setError(null)}
						contentText={error}
						isSilent={true}
						center={true}
					/>
				</>
			)}
		</>
	);
};

export default AddPhysicalExercise;
