import { conference as Conference } from '@solaborate/calls';
import { useEffect, useRef, useState } from 'react';
import useConference from 'calls/hooks/useConference.js';

const useConferenceParticipants = () => {
	const conference = useConference();
	const [conferenceParticipants, setConferenceParticipants] = useState([...conference.participants.values()]);

	const participantRemovedTimeoutRef = useRef(null);

	useEffect(() => {
		const unbind = conference.on(event => {
			if (event instanceof Conference.ParticipantAdded) {
				setConferenceParticipants([...conference.participants.values()]);
			} else if (event instanceof Conference.ParticipantRemoved) {
				participantRemovedTimeoutRef.current = setTimeout(() => {
					setConferenceParticipants([...conference.participants.values()]);
				}, 2000);
			}
		});

		return () => {
			unbind();
			if (participantRemovedTimeoutRef.current) {
				clearTimeout(participantRemovedTimeoutRef.current);
			}
		};
	}, [conference]);

	return conferenceParticipants;
};

export default useConferenceParticipants;
