import { PatientActionTypes } from 'constants/action-types.js';

export const actionCreators = {
	setPatients: patients => async dispatch => {
		dispatch({ type: PatientActionTypes.FETCH_MY_PATIENTS_SUCCESS, payload: patients });
	},
	setSelectedPatient: patientDevice => dispatch => {
		dispatch({ type: PatientActionTypes.SET_SELECTED_PATIENT, payload: patientDevice });
	},
	updateDeviceCallStatus: (deviceId, activeConferences) => async dispatch => {
		dispatch({ type: PatientActionTypes.UPDATE_DEVICE_CALL_STATUS, payload: { deviceId, activeConferences } });
	},
	updateDeviceStatus: (deviceId, isOnline) => async dispatch => {
		dispatch({ type: PatientActionTypes.UPDATE_DEVICE_STATUS, payload: { deviceId, isOnline } });
	},
	updateDeviceAIPrivacyStatus: (deviceId, aiPrivacyStatus) => async dispatch => {
		dispatch({ type: PatientActionTypes.UPDATE_DEVICE_PRIVACY_STATUS, payload: { deviceId, aiPrivacyStatus } });
	},
	setHasPatientRpmProgram: hasRpmProgram => async dispatch => {
		dispatch({ type: PatientActionTypes.SET_HAS_RPM_PROGRAM, payload: hasRpmProgram });
	},
	setSelectedPatientDevice: selectedDevice => async dispatch => {
		dispatch({ type: PatientActionTypes.SET_SELECTED_PATIENT_DEVICE, payload: selectedDevice });
	},
};
