import React from 'react';
import { useIntl } from 'react-intl';

const BloodPressureSummaryChart = props => {
	const intl = useIntl();
	const value = props.measurementValue || '0/0';
	const indexFound = value.indexOf('/');
	const systolicValue = parseInt(value.substring(0, indexFound), 10);
	const diastolicValue = parseInt(value.substring(indexFound + 1, value.length), 10);

	const svgCoordinates = {
		x1: 20,
		x2: 480,
		y: 100,
	};

	const safeRange = {
		min: 80,
		max: 120,
	};

	const maxGraphValue = 300;
	const graphNumbersPosition = svgCoordinates.y + 130;
	const lineLength = svgCoordinates.x2 - svgCoordinates.x1;
	const calcFactor = index => index * (lineLength / maxGraphValue) + svgCoordinates.x1;

	return (
		<>
			<div className='blood-pressure-graph'>
				<svg viewBox='0 0 500 300' xmlns='http://www.w3.org/2000/svg' className='blood-pressure'>
					{[0, 1].map(index => (
						<React.Fragment key={index}>
							<line
								className='graph-background'
								x1={svgCoordinates.x1}
								x2={svgCoordinates.x2}
								y1={svgCoordinates.y + index * 80}
								y2={svgCoordinates.y + index * 80}
							/>

							<line
								className={`active ${index ? 'diastolic' : 'systolic'}`}
								x1={svgCoordinates.x1}
								x2={index ? calcFactor(diastolicValue) : calcFactor(systolicValue)}
								y1={svgCoordinates.y + index * 80}
								y2={svgCoordinates.y + index * 80}
								style={{ transformOrigin: `${((svgCoordinates.x1 - 5) / 500) * 100}% ${(svgCoordinates.y / 300) * 100}%` }}
							/>

							<text
								x={svgCoordinates.x1 - 9.5}
								y={svgCoordinates.y - 20 + index * 80}
								className={`measurement-type ${index ? 'diastolic' : 'systolic'}`}>
								{intl.formatMessage({ id: index ? 'diastolic' : 'systolic' })}
							</text>
						</React.Fragment>
					))}

					<g className='graph-scale-numbers'>
						<text x={svgCoordinates.x1} y={graphNumbersPosition}>
							0
						</text>
						<text x={calcFactor(safeRange.min)} y={graphNumbersPosition}>
							{safeRange.min}
						</text>
						<text x={calcFactor(safeRange.max)} y={graphNumbersPosition}>
							{safeRange.max}
						</text>
						<text x={svgCoordinates.x2} y={graphNumbersPosition}>
							{maxGraphValue}
						</text>
					</g>
				</svg>
			</div>

			<div className='flex summary-chart-data blood-pressure-data'>
				<h1>
					{props.measurementValue}
					{props.measurementValue !== 'No Data' && <span>{props.measurementUnit}</span>}
				</h1>
			</div>
		</>
	);
};

export default BloodPressureSummaryChart;
