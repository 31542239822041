import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Maximize = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill='none'
		stroke={props.color || LightTheme.colors.grayFive}
		strokeWidth={1.5}
		strokeLinecap='round'
		strokeLinejoin='round'
		{...props}>
		<path d='M15 3h6v6M9 21H3v-6M21 3l-7 7M3 21l7-7' stroke='currentColor' />
	</svg>
);

export default Maximize;
