import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const getDecibelLevelMessage = (decibelLevel, intl) => {
	if (decibelLevel >= 0 && decibelLevel <= 49) {
		return intl.formatMessage({ id: 'safeEnvironment' });
	} else if (decibelLevel >= 50 && decibelLevel <= 79) {
		return intl.formatMessage({ id: 'moderateEnvironment' });
	} else if (decibelLevel >= 80 && decibelLevel <= 109) {
		return intl.formatMessage({ id: 'loudEnvironment' });
	} else if (decibelLevel >= 110) {
		return intl.formatMessage({ id: 'veryLoudEnvironment' });
	} else {
		return '';
	}
};

const DecibelLevelAI = ({ numberOfFeeds, isFeedExpanded, callManager }) => {
	const intl = useIntl();
	const [decibel, setDecibel] = useState({
		description: intl.formatMessage({ id: 'calculatingDecibelLevel' }),
		value: '...',
		img: 'decibel-level',
	});

	const currentDecibelListener = ({ decibelLevel }) => {
		setDecibel(prevState => ({
			...prevState,
			description: getDecibelLevelMessage(+decibelLevel, intl),
			value: `${decibelLevel} dB`,
		}));
	};

	useEffect(() => {
		callManager?.on('decibel-level', currentDecibelListener);
		return () => {
			callManager?.off('decibel-level', currentDecibelListener);
		};
	}, [callManager]);

	return (
		<>
			<div
				className={classNames('flex decibel-level-ai right', {
					'multiple-feeds': numberOfFeeds > 1 && isFeedExpanded,
				})}>
				<div className='flex'>
					<img src={`${healthCareCdnUrl}monitoring/video-feed/${decibel.img}.svg`} alt='icon' width={18} height={18} />
				</div>
				<div className='flex left-5'>
					<span>
						{decibel.description} <span className='bold'>{decibel.value}</span>
					</span>
				</div>
			</div>
		</>
	);
};

export default DecibelLevelAI;
