import styled from 'styled-components';
import LightTheme from 'calls/styles/LightTheme.js';

const StyledParticipantInfo = styled.span`
	display: flex;
	align-items: center;
	bottom: ${LightTheme.spacing[2]}px;
	left: ${LightTheme.spacing[2]}px;
	z-index: 1;

	button {
		cursor: default !important;
		margin-right: 6px;
		padding: ${LightTheme.spacing[1]}px;
		span {
			font-size: 12px;
		}
	}

	div {
		svg {
			cursor: pointer;
		}
	}
`;

export default StyledParticipantInfo;
