const Workflow = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} fill='none' viewBox='0 0 24 24' {...props}>
		<path stroke='currentColor' strokeLinejoin='round' d='M2 13H7V18H2z' />
		<rect width={5} height={5} x={2} y={2} stroke='currentColor' rx={2.5} />
		<rect width={5} height={5} x={13} y={13} stroke='currentColor' rx={2.5} />
		<path stroke='currentColor' strokeLinejoin='round' d='M15.5 0.964H20.5V5.964H15.5z' transform='rotate(45 15.5 .964)' />
		<path stroke='currentColor' d='M4.5 7v6M15.5 8v5M7 4.5h5' />
	</svg>
);

export default Workflow;
