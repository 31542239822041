const Rooms = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='33' height='33' viewBox='0 0 33 33' fill='none' color='#5390FF' {...props}>
		<path
			d='M20.625 6.875H23.75C24.3023 6.875 24.75 7.32272 24.75 7.875V27.875C24.75 28.4273 25.1977 28.875 25.75 28.875H27.5'
			stroke='currentColor'
			strokeWidth='2'
		/>
		<path
			d='M6.875 28.875V7.67717C6.875 7.20847 7.20053 6.80266 7.65807 6.70098L18.0331 4.39543C18.6576 4.25665 19.25 4.73187 19.25 5.37162V27.875C19.25 28.4273 18.8023 28.875 18.25 28.875H6.875ZM6.875 28.875H4.125'
			stroke='currentColor'
			strokeWidth='2'
		/>
		<circle cx='15.125' cy='17.875' r='1' fill='currentColor' stroke='currentColor' strokeWidth='0.75' />
	</svg>
);
export default Rooms;
