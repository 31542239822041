import React from 'react';
import { useIntl } from 'react-intl';

const TableAddButton = props => {
	const intl = useIntl();
	return (
		<i
			className='material-icons-outlined boxed-icon add-icon'
			data-tooltip={intl.formatMessage({ id: 'addARole' })}
			data-position='top'
			onClick={props.onClick}>
			add
		</i>
	);
};

export default TableAddButton;
