/** *
 * Used for saving the audio sound that it is playing and stopping it afterwards
 * */
let audio;

/**
 * Method used to play a sound depending on audio url
 * @param {string} audioUrl the string url for the audio to be played
 * @param {Object} options
 * @param {boolean} options.muted used to mute or unmute the audio
 * @param {boolean} options.loop used to loop through the audio
 */
export const playSound = async (audioUrl, options) => {
	const { muted, loop } = options;
	audio = new Audio(audioUrl);

	audio.muted = muted;
	audio.loop = loop;
	audio.currentTime = 0;

	try {
		await audio.play();
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log('Autoplay failed:', error);
	}
};

/**
 * Method used to stop a sound depending on audio object
 */
export const stopSound = () => {
	if (audio) {
		audio.pause();
		audio.currentTime = 0;
	}
};

/**
 * Method used to play a busy sound
 */
export const busySound = () => {
	return playSound('https://static.solaborate.com/global/brand/calls/busy.mp3', { muted: false, loop: false });
};

/**
 * Method used to play a drop sound
 */
export const dropSound = () => {
	return playSound('https://static.solaborate.com/global/brand/calls/call_drop.mp3', { muted: false, loop: false });
};

/**
 * Method used to play the incoming call sound eg. when receiving a call
 */
export const incomingCallSound = () => {
	return playSound('https://static.solaborate.com/global/brand/calls/ring.mp3', { muted: false, loop: true });
};

/**
 * Method used to play the outgoing call sound eg. when calling someone
 */
export const outGoingCallSound = () => {
	return playSound('https://static.solaborate.com/global/brand/calls/call_ringing.mp3', { muted: false, loop: true });
};

/**
 * Method used to stop the incoming call sound eg. when user answeres the call
 */
export const stopIncomingCallSound = () => {
	stopSound();
};

/**
 * Method used to stop the outgoing call sound eg. when user answeres the call
 */
export const stopOutgoingCallSound = () => {
	stopSound();
};
