import { gatewayApi } from 'constants/global-variables.js';

export const getStethoscopeRecordings = async (patientId, startDate, endDate, pageIndex, pageSize) => {
	try {
		const { data } = await gatewayApi.get('v1/healthcare/stethoscope-recordings', {
			params: {
				patientId: patientId,
				endDate: endDate,
				startDate: startDate,
				pageIndex: pageIndex,
				pageSize: pageSize,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getStethoscopeRecordingsCount = async patientId => {
	try {
		const { data } = await gatewayApi.get('v1/healthcare/stethoscope-recordings/count', {
			params: { patientId },
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getConferenceRecordingsCount = async ({ patientId, examType, pageSize = 10, pageIndex = 0 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/patients/${patientId}/conferences/stethoscope-recordings/count?examType=${examType}&pageSize=${pageSize}&pageIndex=${pageIndex}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getRecordingsPerMeasurement = async ({ patientId, conferenceId, examType, pageSize = 10, pageIndex = 0 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/patients/${patientId}/conferences/${conferenceId}/stethoscope-recordings?examType=${examType}&pageSize=${pageSize}&pageIndex=${pageIndex}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
