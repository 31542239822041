import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import styled, { css } from 'styled-components';

const fontColor = isDarkMode => (isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayEleven);
const iconSize = isPatientPosition => (isPatientPosition ? '30px' : '22px');
/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isDarkMode: boolean, $scroll: boolean, $hasError: boolean, $isPatientPosition?: boolean }, never>}
 */

const StyledPatientHistory = styled.div`
	display: flex;
	flex-direction: column;
	padding: var(--spacing-s);

	${props =>
		props.$scroll &&
		css`
			overflow: auto;
			max-height: 250px;
		`}

	${props =>
		props.$hasError &&
		css`
			align-items: center;
			padding: var(--spacing-xl);
		`}

	.history-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: var(--spacing-s) ${props => !props.$isPatientPosition && '0'};
	}

	.history-info {
		display: flex;
		align-items: center;

		svg {
			margin-right: var(--spacing-m);
			width: ${props => iconSize(props.$isPatientPosition)} !important;
			height: ${props => iconSize(props.$isPatientPosition)} !important;
		}

		& > div {
			& > p:first-of-type {
				font-weight: 400;
				color: ${props => fontColor(props.$isDarkMode)};
				font-size: 14px;
				line-height: 18px;
			}

			& > p:last-of-type {
				color: var(--gray-7);
				font-size: 12px;
				font-weight: 500;
				line-height: 14px;
				margin-top: 3px;
			}
		}
	}

	.history-icon {
		padding: 5px;
		background: #5390ff1a;
		display: flex;
		align-items: center;
		border-radius: 3px;
		margin-left: var(--spacing-m);
	}

	.error-message {
		color: var(--red-4) !important;
	}
	.empty-state {
		padding: var(--spacing-s);
	}
`;

export default StyledPatientHistory;
