import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { AddEditPatientRadioTypes, KeyCodes, MeasurementUnitOptions, UnitCategoryTypes } from 'constants/enums.js';
import { Allergies } from 'constants/visitEnums.js';
import { diabeticStatusCodes, tobaccoSmokeCodes } from 'constants/health-codes.js';
import translate from 'i18n-translations/translate.jsx';
import { capitalizeFirstLetter, isDecimal } from 'infrastructure/helpers/commonHelpers.js';
import { Field } from 'formik';
import Input from 'components/Common/FormElements/Input.jsx';

const OtherInformation = props => {
	const [errorOnAllergyEnter, setErrorOnAllergyEnter] = useState({});
	const intl = useIntl();
	const radioButtons = [
		{ id: 1, content: intl.formatMessage({ id: 'yes' }), value: AddEditPatientRadioTypes.YES },
		{ id: 2, content: intl.formatMessage({ id: 'no' }), value: AddEditPatientRadioTypes.NO },
		{ id: 3, content: 'N/A', value: AddEditPatientRadioTypes.NO_ANSWER },
	];

	const onEnter = (e, formikProps) => {
		if (!e.target.value) {
			return;
		}
		const enter = KeyCodes.ENTER;
		const value = capitalizeFirstLetter(e.target.value);
		const foodArr = [...props.foodAllergies];
		const medicArr = [...props.medicationAllergies];
		const environmentalArr = [...props.environmentalAllergies];
		const biologicalArr = [...props.biologicalAllergies];

		if (e.keyCode === enter) {
			if (!/^[a-zA-Z\s]*$/.test(value)) {
				setErrorOnAllergyEnter({ errorAt: e.target.name, errorMessage: intl.formatMessage({ id: 'allergyNameNonNumber' }) });
				return;
			}
			if (e.target.name === 'foodAllergy') {
				if (!foodArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					foodArr.push(value);
					props.setFoodAllergies(foodArr);
					formikProps.setFieldValue('foodAllergy', '');
				}
			}
			if (e.target.name === 'medicationAllergy') {
				if (!medicArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					medicArr.push(value);
					props.setMedicationAllergies(medicArr);
					formikProps.setFieldValue('medicationAllergy', '');
				}
			}
			if (e.target.name === 'environmentalAllergy') {
				if (!environmentalArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					environmentalArr.push(value);
					props.setEnvironmentalAllergies(environmentalArr);
					formikProps.setFieldValue('environmentalAllergy', '');
				}
			}
			if (e.target.name === 'biologicalAllergy') {
				if (!biologicalArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					biologicalArr.push(value);
					props.setBiologicalAllergies(biologicalArr);
					formikProps.setFieldValue('biologicalAllergy', '');
				}
			}
			setErrorOnAllergyEnter({});
		}
	};

	const removeAllergy = (item, type) => {
		const foodArr = [...props.foodAllergies];
		const medicArr = [...props.medicationAllergies];
		const environmentalArr = [...props.environmentalAllergies];
		const biologicalArr = [...props.biologicalAllergies];
		let foundIndex = null;
		if (type.id === Allergies.FOOD.id) {
			foundIndex = foodArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				foodArr.splice(foundIndex, 1);
			}
			props.setFoodAllergies(foodArr);
		}
		if (type.id === Allergies.MEDICATION.id) {
			foundIndex = medicArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				medicArr.splice(foundIndex, 1);
			}
			props.setMedicationAllergies(medicArr);
		}
		if (type.id === Allergies.ENVIRONMENTAL.id) {
			foundIndex = environmentalArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				environmentalArr.splice(foundIndex, 1);
			}
			props.setEnvironmentalAllergies(environmentalArr);
		}
		if (type.id === Allergies.BIOLOGICAL.id) {
			foundIndex = biologicalArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				biologicalArr.splice(foundIndex, 1);
			}
			props.setBiologicalAllergies(biologicalArr);
		}
	};

	const getRadioButtons = name => (
		<>
			{radioButtons.map(item => (
				<div className='flex position-relative consent-from-patient margin-right-m' key={item.id}>
					<label className='remember-me flex'>
						<input
							className='auto-width cursor-pointer'
							type='radio'
							value={item.value.toString()}
							onChange={props.onChange}
							onBlur={props.onBlur}
							name={name}
							checked={props.values[name] === item.value.toString()}
						/>
						<div className='onb-custom-checkbox-wrapper add-patient-checkbox-wrapper cursor-pointer'>
							<div className='onb-custom-checkbox' />
						</div>
						<p className=''>{item.content}</p>
					</label>
				</div>
			))}
		</>
	);

	return (
		<>
			<h4>{translate('otherInformation')}</h4>
			<div className='flex view-profile-modal-wrapper flex-wrap patient-input-wrapper'>
				<div className='row-direction'>
					<Field
						type='number'
						name='height'
						label={`*${intl.formatMessage({ id: 'height' })}`}
						onChange={event => {
							if (isDecimal(event.target.value)) {
								props.onChange(event);
							}
						}}
						className='number-input-wo-arrows'
						component={Input}
						isErrorOutsideDiv={true}
					/>
					<div>
						<p>{translate('unit')}</p>
						<input
							type='text'
							value={
								MeasurementUnitOptions.HEIGHT.value[
									props.unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes.HEIGHT)?.unitSystemId
								]?.text
							}
							onChange={props.onChange}
							name='unit'
							readOnly
						/>
					</div>
				</div>
				<div className='row-direction'>
					<Field
						type='number'
						name='weight'
						label={`*${intl.formatMessage({ id: 'weight' })}`}
						className='number-input-wo-arrows'
						component={Input}
						isErrorOutsideDiv={true}
					/>
					<div>
						<p>{translate('unit')}</p>

						<input
							type='text'
							value={
								MeasurementUnitOptions.WEIGHT.value[
									props.unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes.WEIGHT)?.unitSystemId
								]?.text
							}
							onChange={props.onChange}
							name='unit'
							readOnly
						/>
					</div>
				</div>
				<div className='row-direction'>
					<div>
						<p>{translate('waistCircumference')}</p>
						<input
							type='text'
							value={props.values.waistCircumference}
							onChange={event => {
								if (isDecimal(event.target.value)) {
									props.onChange(event);
								}
							}}
							onBlur={props.onBlur}
							name='waistCircumference'
							className='number-input-wo-arrows'
						/>
					</div>
					<div>
						<p>{translate('unit')}</p>
						<input
							type='text'
							value={
								MeasurementUnitOptions.WAIST_CIRCUMFERENCE.value[
									props.unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes.HEIGHT)?.unitSystemId
								]?.text
							}
							onChange={props.onChange}
							name='unit'
							readOnly
						/>
					</div>
					{props.errors?.waistCircumference && <span className='red-error'>{props.errors.waistCircumference}</span>}
				</div>
				<div className='row-direction z-index-100'>
					<div>
						<p>{translate('totalCholesterol')}</p>
						<input
							type='text'
							value={props.values.totalCholesterol}
							onChange={event => {
								if (isDecimal(event.target.value)) {
									props.onChange(event);
								}
							}}
							onBlur={props.onBlur}
							name='totalCholesterol'
							className='number-input-wo-arrows'
						/>
					</div>
					<div>
						<p>{translate('unit')}</p>
						<input
							type='text'
							value={
								MeasurementUnitOptions.BLOOD_GLUCOSE.value[
									props.unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId
								]?.text
							}
							onChange={props.onChange}
							name='unit'
							readOnly
						/>
					</div>
					{props.errors?.totalCholesterol && <span className='red-error'>{props.errors.totalCholesterol}</span>}
				</div>
				<div className='row-direction z-index-100'>
					<div>
						<p>{translate('hdlCholesterol')}</p>
						<input
							type='text'
							value={props.values.hdlCholesterol}
							onChange={event => {
								if (isDecimal(event.target.value)) {
									props.onChange(event);
								}
							}}
							onBlur={props.onBlur}
							name='hdlCholesterol'
							className='number-input-wo-arrows'
						/>
					</div>
					<div>
						<p>{translate('unit')}</p>
						<input
							type='text'
							value={
								MeasurementUnitOptions.BLOOD_GLUCOSE.value[
									props.unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId
								]?.text
							}
							onChange={props.onChange}
							name='unit'
							readOnly
						/>
					</div>
					{props.errors?.hdlCholesterol && <span className='red-error'>{props.errors.hdlCholesterol}</span>}
				</div>
				<div className='flex row-direction transparent-background full-width'>
					<div className='row-direction full-width flex flex-align-center'>
						<label className='flex-1'>{translate('sufferFromHypertension')}</label>
						{getRadioButtons('hasHyperTension')}
					</div>
					<div className='row-direction full-width flex flex-align-center'>
						<label className='flex-1'>{translate('medicalStatus')}</label>
						{getRadioButtons('isTakingMedication')}
					</div>
				</div>
				<div className='flex row-direction transparent-background full-width bottom-30'>
					<div className='row-direction full-width flex flex-wrap flex-align-center'>
						<label className='flex-1'>{translate('areYouSmoker')}</label>
						{getRadioButtons('isTobaccoSmoker')}
						{(props.values.isTobaccoSmoker === AddEditPatientRadioTypes.NO.toString() ||
							props.values.isTobaccoSmoker === AddEditPatientRadioTypes.YES.toString()) && (
							<div className='row-direction full-width flex flex-align-center'>
								<label className='flex-1' />
								{tobaccoSmokeCodes.map(
									item =>
										item.isSmoker === props.values.isTobaccoSmoker && (
											<div className='flex position-relative consent-from-patient margin-right-m' key={item.code}>
												<label className='remember-me flex'>
													<input
														className='auto-width cursor-pointer'
														type='radio'
														value={item.code}
														onChange={props.onChange}
														onBlur={props.onBlur}
														name={item.name}
														checked={props.values[item.name] === item.code}
													/>
													<div className='onb-custom-checkbox-wrapper add-patient-checkbox-wrapper cursor-pointer'>
														<div className='onb-custom-checkbox' />
													</div>
													<p>{translate(item.content)}</p>
												</label>
											</div>
										)
								)}
							</div>
						)}
						<span className='red-error margin-left-auto'>
							{props.values.isTobaccoSmoker !== AddEditPatientRadioTypes.NO_ANSWER.toString() && props.errors?.tobaccoYesCode}
							{props.values.isTobaccoSmoker !== AddEditPatientRadioTypes.NO_ANSWER.toString() && props.errors?.tobaccoNoCode}
						</span>
					</div>

					<div className='row-direction full-width flex flex-wrap flex-align-center'>
						<label className='flex-1'>{translate('haveDiabeticStatus')}</label>
						{getRadioButtons('hasDiabet')}
						{(props.values.hasDiabet === AddEditPatientRadioTypes.YES.toString() ||
							props.values.hasDiabet === AddEditPatientRadioTypes.NO.toString()) && (
							<div className='row-direction full-width flex'>
								<label className='flex-1' />
								{diabeticStatusCodes.map(
									item =>
										item.isDiabetic === props.values.hasDiabet && (
											<div className='flex position-relative consent-from-patient margin-right-m' key={item.code}>
												<label className='remember-me flex'>
													<input
														className='auto-width cursor-pointer'
														type='radio'
														value={item.code}
														onChange={props.onChange}
														onBlur={props.onBlur}
														name={item.name}
														checked={props.values[item.name] === item.code}
													/>
													<div className='onb-custom-checkbox-wrapper add-patient-checkbox-wrapper cursor-pointer'>
														<div className='onb-custom-checkbox' />
													</div>
													<p>{translate(item.content)}</p>
												</label>
											</div>
										)
								)}
							</div>
						)}
						{props.errors?.diabeticStatusCode && (
							<span className='red-error margin-left-auto'>{props.errors.diabeticStatusCode}</span>
						)}
					</div>
				</div>
			</div>
			<div className='health-information-questions'>
				<label className='full-width'>{translate('anyAllergies')}</label>
				<div>
					<div className='flex'>{getRadioButtons('hasAllergy')}</div>
					{parseInt(props.values.hasAllergy, 10) === AddEditPatientRadioTypes.YES && (
						<>
							<div className='patient-registration-checkboxes'>
								<div>
									<label>{translate('typeOfAllergies')}</label>
									<div className='flex'>
										<label className='remember-me flex flex-align-center flex-justify-center position'>
											<input
												className='cursor-pointer position-relative bottom-s'
												type='checkbox'
												value={Allergies.FOOD.id}
												onChange={props.onChange}
												onBlur={props.onBlur}
												name='allergies'
												checked={props.values.allergies.find(item => item === Allergies.FOOD.id.toString())}
											/>
											<p>{translate('food')}</p>
										</label>

										<label className='flex flex-align-center flex-justify-center'>
											<input
												type='checkbox'
												name='allergies'
												value={Allergies.MEDICATION.id}
												onChange={props.onChange}
												onBlur={props.onBlur}
												checked={props.values.allergies.find(item => item === Allergies.MEDICATION.id.toString())}
												className='cursor-pointer position-relative bottom-s'
											/>
											<p>{translate('medication')}</p>
										</label>
										<label className='flex flex-align-center flex-justify-center'>
											<input
												type='checkbox'
												name='allergies'
												value={Allergies.ENVIRONMENTAL.id}
												onChange={props.onChange}
												onBlur={props.onBlur}
												checked={props.values.allergies.find(item => item === Allergies.ENVIRONMENTAL.id.toString())}
												className='cursor-pointer position-relative bottom-s'
											/>
											<p> {translate('environmental')}</p>
										</label>
										<label className='flex flex-align-center flex-justify-center'>
											<input
												type='checkbox'
												name='allergies'
												value={Allergies.BIOLOGICAL.id}
												onChange={props.onChange}
												onBlur={props.onBlur}
												checked={props.values.allergies.find(item => item === Allergies.BIOLOGICAL.id.toString())}
												className='cursor-pointer position-relative bottom-s'
											/>
											<p>{translate('biological')}</p>
										</label>
									</div>
								</div>
								{props.errors.allergies && <span className='red-error'>{props.errors.allergies}</span>}
							</div>
							{props.values.allergies.find(item => item === Allergies.FOOD.id.toString()) && (
								<div className='patient-registration-allergy-list'>
									<div>
										<label>{translate('foodAllergies')}</label>
										<input
											type='text'
											value={props.values.foodAllergy}
											onChange={props.onChange}
											onBlur={props.onBlur}
											name='foodAllergy'
											placeholder={intl.formatMessage({ id: 'typeAllergyAndPressEnter' })}
											onKeyDown={e => onEnter(e, props.formikProps)}
											enterKeyHint='go'
										/>
									</div>
									<div className='allergies-errors'>
										{errorOnAllergyEnter.errorAt === 'foodAllergy' && (
											<span className='red-error'>{errorOnAllergyEnter.errorMessage}</span>
										)}
										{props.foodAllergies.length === 0 && (
											<span className='red-error'>{intl.formatMessage({ id: 'pleaseWriteAllergy' })}</span>
										)}
									</div>
									{props.foodAllergies.length > 0 && (
										<div className='flex allergies-list flex-wrap'>
											{props.foodAllergies.map(item => (
												<div className='flex'>
													<i className='material-icons' onClick={() => removeAllergy(item, Allergies.FOOD)}>
														close
													</i>
													<p>{item}</p>
												</div>
											))}
										</div>
									)}
								</div>
							)}
							{props.values.allergies.find(item => item === Allergies.MEDICATION.id.toString()) && (
								<div className='patient-registration-allergy-list'>
									<div>
										<label>{translate('medicationAllergies')}</label>
										<input
											type='text'
											value={props.values.medicationAllergy}
											onChange={props.onChange}
											onBlur={props.onBlur}
											name='medicationAllergy'
											placeholder={intl.formatMessage({ id: 'typeAllergyAndPressEnter' })}
											onKeyDown={e => onEnter(e, props.formikProps)}
											enterKeyHint='go'
										/>
									</div>
									<div className='allergies-errors'>
										{errorOnAllergyEnter.errorAt === 'medicationAllergy' && (
											<span className='red-error'>{errorOnAllergyEnter.errorMessage}</span>
										)}
										{props.medicationAllergies.length === 0 && (
											<span className='red-error'>{intl.formatMessage({ id: 'pleaseWriteAllergy' })}</span>
										)}
									</div>
									{props.medicationAllergies.length > 0 && (
										<div className='flex allergies-list flex-wrap'>
											{props.medicationAllergies.map(item => (
												<div className='flex'>
													<i className='material-icons' onClick={() => removeAllergy(item, Allergies.MEDICATION)}>
														close
													</i>
													<p>{item}</p>
												</div>
											))}
										</div>
									)}
								</div>
							)}
							{props.values.allergies.find(item => item === Allergies.ENVIRONMENTAL.id.toString()) && (
								<div className='patient-registration-allergy-list'>
									<div>
										<label>{translate('environmentalAllergies')}</label>
										<input
											type='text'
											value={props.values.environmentalAllergy}
											onChange={props.onChange}
											onBlur={props.onBlur}
											name='environmentalAllergy'
											placeholder={intl.formatMessage({ id: 'typeAllergyAndPressEnter' })}
											onKeyDown={e => onEnter(e, props.formikProps)}
											enterKeyHint='go'
										/>
									</div>
									<div className='allergies-errors'>
										{errorOnAllergyEnter.errorAt === 'environmentalAllergy' && (
											<span className='red-error'>{errorOnAllergyEnter.errorMessage}</span>
										)}
										{props.environmentalAllergies.length === 0 && (
											<span className='red-error'>{intl.formatMessage({ id: 'pleaseWriteAllergy' })}</span>
										)}
									</div>
									{props.environmentalAllergies.length > 0 && (
										<div className='flex allergies-list flex-wrap'>
											{props.environmentalAllergies.map(item => (
												<div className='flex'>
													<i className='material-icons' onClick={() => removeAllergy(item, Allergies.ENVIRONMENTAL)}>
														close
													</i>
													<p>{item}</p>
												</div>
											))}
										</div>
									)}
								</div>
							)}
							{props.values.allergies.find(item => item === Allergies.BIOLOGICAL.id.toString()) && (
								<div className='patient-registration-allergy-list'>
									<div>
										<label>{translate('biologicalAllergies')}</label>
										<input
											type='text'
											value={props.values.biologicalAllergy}
											onChange={props.onChange}
											onBlur={props.onBlur}
											name='biologicalAllergy'
											placeholder={intl.formatMessage({ id: 'typeAllergyAndPressEnter' })}
											onKeyDown={e => onEnter(e, props.formikProps)}
											enterKeyHint='go'
										/>
									</div>
									<div className='allergies-errors'>
										{errorOnAllergyEnter.errorAt === 'biologicalAllergy' && (
											<span className='red-error'>{errorOnAllergyEnter.errorMessage}</span>
										)}
										{props.biologicalAllergies.length === 0 && (
											<span className='red-error'>{intl.formatMessage({ id: 'pleaseWriteAllergy' })}</span>
										)}
									</div>
									{props.biologicalAllergies.length > 0 && (
										<div className='flex allergies-list flex-wrap'>
											{props.biologicalAllergies.map(item => (
												<div className='flex'>
													<i className='material-icons' onClick={() => removeAllergy(item, Allergies.BIOLOGICAL)}>
														close
													</i>
													<p>{item}</p>
												</div>
											))}
										</div>
									)}
								</div>
							)}
						</>
					)}
				</div>
				<label className='full-width'>{translate('preExistingCondition')}</label>
				<div className='flex'>{getRadioButtons('hasPreExistingCondition')}</div>
				{props.values.hasPreExistingCondition === AddEditPatientRadioTypes.YES.toString() && (
					<div className='patient-registration-allergy-list'>
						<input
							type='text'
							value={props.values.preExistingCondition}
							onChange={props.onChange}
							onBlur={props.onBlur}
							name='preExistingCondition'
							placeholder={intl.formatMessage({ id: 'writePreExistingCondition' })}
						/>
					</div>
				)}
				{props.errors.preExistingCondition && <span className='red-error'>{props.errors.preExistingCondition}</span>}
			</div>
			<div className='flex position-relative consent-from-patient margin-right-m'>
				<label className='remember-me flex'>
					<input
						type='checkbox'
						onChange={props.onChange}
						onBlur={props.onBlur}
						id='showConsent'
						name='showConsent'
						value={props.values.showConsent.toString()}
						checked={props.values.showConsent}
						className='cursor-pointer'
					/>
					<div className='onb-custom-checkbox-wrapper add-patient cursor-pointer'>
						<div className='onb-custom-checkbox' />
					</div>
					<p className='consent-patient'>{translate('showConsent')}</p>
				</label>
			</div>
		</>
	);
};

export default OtherInformation;
