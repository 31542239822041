import { useIntl } from 'react-intl';
import {
	chartColors,
	getDoughnutChartOptions,
	legendColors,
	offlineChartColor,
	offlineLegendColor,
	onlineChartColor,
	onlineLegendColor,
	assignedLegendColor,
	unassignedLegendColor,
	assignedChartColor,
	unassignedChartColor,
} from 'constants/fleet-management.js';
import Dropdown from 'components/Dropdown.jsx';
import { StatusEnum } from 'constants/enums.js';
import DoughnutChart from 'components/Common/Charts/DoughnutChart.jsx';
import classNames from 'classnames';

const dualLegendsCharts = [StatusEnum.STATUS, StatusEnum.ENROLLMENT];

const LegendStatus = {
	Status: {
		Online: onlineLegendColor,
		Offline: offlineLegendColor,
	},
	Enrollment: {
		Assigned: assignedLegendColor,
		Unassigned: unassignedLegendColor,
	},
};

const ChartStatus = {
	Online: onlineChartColor,
	Offline: offlineChartColor,
	Assigned: assignedChartColor,
	Unassigned: unassignedChartColor,
};

const getChartBackgroundColor = (item, index) =>
	dualLegendsCharts.includes(item.label) ? item.dataSets.map(item => ChartStatus[item.label]) : chartColors[index];

const DeviceStatusMultiDoughnuts = ({ chartData, title }) => {
	const intl = useIntl();

	const renderLegend = (legend, index) => {
		const legendColorsArray = legendColors[index];
		if (dualLegendsCharts.includes(legend.label)) {
			const legendColor = LegendStatus[legend.label];
			return legend.dataSets.map(item => (
				<div key={item.label} className='status-legend'>
					<div>
						<div className={classNames('legend-pill', legendColor[item.label])}></div>
						<div>{item.label}</div>
					</div>
					<span>{item.value}</span>
				</div>
			));
		} else {
			return (
				<>
					<div className='status-legend'>
						<div>
							<div className={`legend-pill ${legendColorsArray[0] ?? onlineLegendColor}`}></div>
							<div>{legend.dataSets[0].label}</div>
						</div>
						<span>{legend.dataSets[0].value}</span>
					</div>
					{legend.dataSets.length > 1 && (
						<div className='status-legend'>
							<div>{intl.formatMessage({ id: 'other' })}</div>
							<Dropdown
								position='bottom'
								icon='expand_more'
								toolTip={intl.formatMessage({ id: 'seeMoreData' })}
								toolTipPosition='top'
								className='fleet-management-legend-dropdown'>
								<div className='fleet-management-header'>
									<div className='chart-dropdown-wrapper'>
										<div className='dropdown-title'>{legend.label}</div>
										{legend.dataSets.slice(1).map((item, index) => (
											<div key={item.label} className='dropdown-list-item'>
												<div>
													<div className={classNames('legend-pill', legendColorsArray[index + 1] || offlineLegendColor)}></div>
													<div>{item.label}</div>
												</div>
												<div>{item.value}</div>
											</div>
										))}
									</div>
								</div>
							</Dropdown>
						</div>
					)}
				</>
			);
		}
	};

	return (
		<div className='fleet-progressbar'>
			<label>{title}</label>
			<div className='multi-progressbar-wrapper'>
				{chartData.map((item, index) => (
					<div key={item.label} className='progressbar-item'>
						<DoughnutChart
							data={{
								labels: item.dataSets.map(item => item.label),
								datasets: [
									{
										data: item.dataSets.map(item => item.value),
										backgroundColor: getChartBackgroundColor(item, index),
										borderWidth: 0,
									},
								],
							}}
							height='140'
							width='140'
							options={getDoughnutChartOptions(item.label)}
						/>
						<div
							className='legend-wrapper'
							{...(item.label === StatusEnum.ENROLLMENT && {
								'data-tooltip': intl.formatMessage({ id: 'companyDeviceEnrollment' }),
								'data-position': 'top',
							})}>
							{renderLegend(item, index)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default DeviceStatusMultiDoughnuts;
