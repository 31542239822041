import { ConditionType, ObservationType } from 'constants/enums.js';
import { gatewayApi, whiteboardApi } from 'constants/global-variables.js';
import { BradenScore } from 'constants/roomSign.js';
import { getCompanyId } from 'infrastructure/auth.js';
export let encounterId = '';
export const setEncounterId = _encounterId => {
	encounterId = _encounterId;
};

export const getActiveEncounter = async userId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${getCompanyId()}/patients/${userId}/encounters/active`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getTvWidgets = async ownerId => {
	try {
		const { data } = await whiteboardApi.get(`v1/healthcare/patients/${ownerId}/tv-widgets`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getIsolations = async ownerId => {
	try {
		const { data } = await whiteboardApi.get(
			`v1.1/healthcare/patients/${ownerId}/conditions?conditionType=${ConditionType.ISOLATIONS}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPrecautions = async ownerId => {
	try {
		const { data } = await whiteboardApi.get(
			`v1.1/healthcare/patients/${ownerId}/conditions?conditionType=${ConditionType.PRECAUTION}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPainLevel = async ownerId => {
	try {
		const { data } = await whiteboardApi.get(
			`v1/healthcare/patients/${ownerId}/observations?ObservationType=${ObservationType.PAIN_MANAGEMENT}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getBradenScore = async ownerId => {
	try {
		const { data } = await whiteboardApi.get(
			`v1/healthcare/patients/${ownerId}/observations?ObservationType=${ObservationType.BRADEN_SCORE}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const setTvWidgets = async (ownerId, params) => {
	try {
		const { data } = await whiteboardApi.post(`v1/healthcare/patients/${ownerId}/tv-widgets/manual`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const setPainLevel = async (ownerId, valueString, deviceId) => {
	try {
		const { data } = await whiteboardApi.post(`v1/healthcare/patients/${ownerId}/observations`, {
			observations: [
				{
					code: 'HCI100',
					display: `Pain Score`,
					valueString,
					effectiveDateTime: new Date().toISOString(),
					observationTypeId: 2,
				},
			],
			deviceId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const setBradenScore = async (ownerId, valueInt, deviceId) => {
	try {
		const { data } = await whiteboardApi.post(`v1/healthcare/patients/${ownerId}/observations`, {
			observations: [
				{
					code: BradenScore.Code,
					observationTypeId: ObservationType.BRADEN_SCORE,
					display: BradenScore.Display,
					effectiveDateTime: new Date().toISOString(),
					valueInt: valueInt,
				},
			],
			deviceId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const setConditions = async (ownerId, values) => {
	try {
		const { data } = await whiteboardApi.post(`v1.1/healthcare/patients/${ownerId}/conditions`, values);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteConditions = async (ownerId, values) => {
	try {
		const { data } = await whiteboardApi.put(`v1/healthcare/patients/${ownerId}/conditions/abate`, values);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPin = async userId => {
	try {
		const { data } = await whiteboardApi.get(`v1/tenants/${getCompanyId()}/users/${userId}/pin`, {
			useEncounterId: false,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const generatePin = async userId => {
	try {
		const { data } = await whiteboardApi.put(`v1/tenants/${getCompanyId()}/users/${userId}/pin`, {
			useEncounterId: false,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const setInterpreterLanguage = async (ownerId, values) => {
	try {
		const { data } = await whiteboardApi.patch(
			`/v1/healthcare/organizations/${getCompanyId()}/patients/${ownerId}/communication-language`,
			{ ...values, useEncounterId: false }
		);
		return data;
	} catch (error) {
		return { error };
	}
};
