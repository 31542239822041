import React, { useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import { generateCustomStyles } from 'constants/react-select-style.js';
import { useIntl } from 'react-intl';
import { dailyWeightOptions } from 'constants/monitoring.js';
import ActionButtons from 'containers/Monitoring/Whiteboard/ActionButtons.jsx';

const DailyWeight = props => {
	const { dailyWeight, setData, isDarkMode } = props;

	const intl = useIntl();
	const [expanded, setExpanded] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);

	const options = dailyWeightOptions(intl);

	const getDailyWeightValue = () => {
		if (dailyWeight === null) {
			return null;
		}

		return {
			value: options.find(item => item.value === dailyWeight)?.value,
			label: options.find(item => item.value === dailyWeight)?.label,
		};
	};

	return (
		<>
			<div className={classNames('timeline-box-header sub-timeline-box-header', expanded ? 'expanded' : '')}>
				<p className='timeline-box-title'>{translate('dailyWeight')}</p>
				<div className='timeline-box-actions'>
					<ActionButtons
						handleEditClick={() => {
							setShowEditForm(prevState => !prevState);
							setExpanded(true);
						}}
						handleExpandClick={() => setExpanded(prevState => !prevState)}
						expanded={expanded}
					/>
				</div>
			</div>
			{expanded && (
				<>
					{!showEditForm && (
						<table className='patient-table-informations full-width'>
							<tbody>
								<tr>
									<td>{translate('dailyWeight')}</td>
									<td>{getDailyWeightValue()?.label}</td>
								</tr>
							</tbody>
						</table>
					)}
					{showEditForm && (
						<div className='flex flex-space-between flex-wrap whiteboard-select'>
							<div className='top-15'>
								<ReactSelect
									options={options}
									value={getDailyWeightValue()}
									onChange={option => setData(option.value)}
									id='select'
									classNamePrefix='react-select full-width'
									styles={{
										...generateCustomStyles({ darkMode: isDarkMode }),
									}}
									placeholder={intl.formatMessage({ id: 'dailyWeight' })}
								/>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default DailyWeight;
