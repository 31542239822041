import ReactSelect from 'react-select';
import { generateCustomStyles } from 'constants/react-select-style.js';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import DarkTheme from 'calls/styles/DarkTheme.js';
import {
	Option,
	MultiValueContainer,
	MultiValue,
	MenuList,
	Control,
} from 'components/Common/ReactSelectCustomComponents/index.js';
import translate from 'i18n-translations/translate.jsx';

const AiMultipleSelect = ({
	isMenuOpen,
	options,
	selectedOptions,
	handleOnSelect,
	setIsMenuOpen,
	customContent,
	menulistTitle,
	placeholder,
}) => {
	const intl = useIntl();
	const darkMode = useSelector(state => state.user.darkMode);

	return (
		<>
			<p className='semi-bold top-15 font-14'>{translate(customContent)}</p>
			<div className='flex full-width top-15 ai-configurations-select no-border-bottom margin-bottom-0'>
				<ReactSelect
					menuIsOpen={isMenuOpen}
					onMenuOpen={() => setIsMenuOpen(true)}
					onMenuClose={() => setIsMenuOpen(false)}
					options={options}
					value={selectedOptions}
					placeholder={intl.formatMessage({ id: placeholder })}
					onChange={handleOnSelect}
					isClearable={false}
					isSearchable={false}
					closeMenuOnSelect={false}
					hideSelectedOptions={false}
					classNamePrefix='react-select'
					customContent={
						selectedOptions.length > 0 ? `${selectedOptions.length} ${intl.formatMessage({ id: customContent })}` : ''
					}
					menulistTitle={intl.formatMessage({ id: menulistTitle })}
					styles={{
						...generateCustomStyles({ darkMode }),
						menu: base => ({ ...base, width: '100%', color: darkMode && DarkTheme.colors.grayFive }),
						menuList: base => ({ ...base, height: 'fit-content', width: '100%' }),
						multiValue: base => ({
							...base,
							display: 'none',
						}),
						control: base => ({
							...base,
							paddingLeft: '10px',
							backgroundColor: darkMode && DarkTheme.colors.grayThree,
							color: darkMode && DarkTheme.colors.grayFive,
						}),
					}}
					isMulti
					components={{
						Option,
						MultiValueContainer,
						MultiValue,
						Control,
						MenuList,
					}}
				/>
			</div>
		</>
	);
};
export default AiMultipleSelect;
