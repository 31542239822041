import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import AvailableDoctors from 'views/Doctors/AvailableDoctors.jsx';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';
import SelectPatient from 'containers/CheckInAPatient/SelectPatient.jsx';
import PatientHealthDetails from 'containers/CheckInAPatient/PatientHealthDetails';
import PatientVitalSigns from 'containers/CheckInAPatient/PatientVitalSigns';
import { getSymptoms } from 'api/doctorRequests.js';
import { findSectorById, getStorage, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { DeviceStatus, SymptomsLength } from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import DeviceOffline from 'components/DeviceOffline';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { actionCreators as patientsActionCreators } from 'state/patients/actions.js';

const CheckInAPatient = () => {
	const intl = useIntl();
	const [currentTab, setCurrentTab] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [isSecondTabEnabled, setIsSecondTabEnabled] = useState(false);
	const [symptoms, setSymptoms] = useState([]);
	const [selectedSymptoms, setSelectedSymptoms] = useState([]);
	const [additionalData, setAdditionalData] = useState({ medicalQuestionAnswers: [], additionalNotes: '', attachments: [] });
	const [patientId, setPatientId] = useState(null);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [isDeviceOffline, setIsDeviceOffline] = useState(false);
	const [isPatientFormVisible, setIsPatientFormVisible] = useState(false);
	const history = useHistory();
	const socket = useContext(SocketContext);
	const userSession = useSelector(state => state.user.userSession);
	const medicalQuestions = [{ id: 1, question: intl.formatMessage({ id: 'howLongSymptoms' }) }];
	const helloDeviceId = parseInt(getStorage().getItem('helloDeviceId'), 10);
	const healthcareUserId = getStorage().getItem('patientId');
	const tree = useSelector(state => state.healthSystems.treeData.tree);
	const room = findSectorById(tree, helloDeviceId);
	const dispatch = useDispatch();

	useEffect(() => {
		if (userSession.healthSystem.id && !userSession.checkedInChannelId) {
			history.push('check-in-dc');
		}
	}, [userSession, history]);

	useEffect(() => {
		if (room?.status === DeviceStatus.OFFLINE) {
			setIsDeviceOffline(true);
		}
	}, [room, currentTab]);

	const tabs = {
		SELECT_PATIENT: 0,
		PATIENT_HEALTH_DETAILS: 1,
		VITAL_SIGNS: 2,
		SELECT_DOCTOR: 3,
	};

	const imgUrl = `${healthCareCdnUrl}requests/`;

	const tabList = [
		{
			id: 0,
			title: translate('selectPatient'),
			mainImg: `${imgUrl}1-gray.svg`,
			activeImg: `${imgUrl}1-blue.svg`,
			pastImg: `${imgUrl}1-black.svg`,
		},
		{
			id: 1,
			title: translate('patientHealthDetails'),
			mainImg: `${imgUrl}2-gray.svg`,
			activeImg: `${imgUrl}2-blue.svg`,
			pastImg: `${imgUrl}2-black.svg`,
		},
		{
			id: 2,
			title: translate('measureVitalSigns'),
			mainImg: `${imgUrl}3-gray.svg`,
			activeImg: `${imgUrl}3-blue.svg`,
			pastImg: `${imgUrl}3-black.svg`,
		},
		{
			id: 3,
			title: translate('requestDoctor'),
			mainImg: `${imgUrl}4-gray.svg`,
			activeImg: `${imgUrl}4-blue.svg`,
			pastImg: `${imgUrl}4-black.svg`,
		},
	];

	useEffect(() => {
		const onDeviceOffline = data => {
			if (data.helloDeviceId === helloDeviceId) {
				setIsDeviceOffline(true);
			}
		};
		const onDeviceOnline = data => {
			if (data.helloDeviceId === helloDeviceId) {
				setIsDeviceOffline(false);
			}
		};
		const handleCallEndWithPatient = data => {
			if (room.helloDeviceId === data.helloDeviceId && !data.isActive) {
				setCurrentTab(0);
				setSelectedPatient(null);
			}
		};
		socket.on(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
		socket.on(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);
		socket.on(SocketEvents.HelloDevice.ON_CALL_STATE_CHANGED, onDeviceStateChange);
		socket.on(SocketEvents.Conference.ON_CALL_STATE_CHANGED, handleCallEndWithPatient);

		return () => {
			socket.off(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
			socket.off(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);
			socket.off(SocketEvents.HelloDevice.ON_CALL_STATE_CHANGED, onDeviceStateChange);
			socket.off(SocketEvents.Conference.ON_CALL_STATE_CHANGED, handleCallEndWithPatient);
		};
	}, [socket]);

	const onDeviceStateChange = ({ deviceId, activeConferences }) => {
		if (deviceId === helloDeviceId) {
			updateDeviceCallStatus(deviceId, activeConferences);
		}
	};

	const updateDeviceCallStatus = (deviceId, activeConferences) => {
		dispatch(healthSystemsActionCreators.updateDeviceCallStatus(deviceId, activeConferences));
		dispatch(patientsActionCreators.updateDeviceCallStatus(deviceId, activeConferences));
	};

	const tabChange = tab => {
		if (canChangeTab(tab)) {
			setCurrentTab(tab);
		}
	};

	useEffect(() => {
		const handleBeforeUnload = event => {
			if (healthcareUserId) {
				event.preventDefault();
				// eslint-disable-next-line no-param-reassign
				event.returnValue = '';
			}
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => window.removeEventListener('beforeunload', handleBeforeUnload);
	}, [healthcareUserId]);

	useEffect(() => {
		getStorage().removeItem('patientId');
	}, [intl]);

	useEffect(() => {
		const getHealthDetails = async () => {
			const symptomsList = await getSymptoms();
			if (symptomsList.error) {
				setError(symptomsList.error.message);
			} else {
				setSymptoms(
					symptomsList.symptoms.map(item => {
						const translated = intl.formatMessage({ id: stringToCamelCase(item.name) });
						return { name: translated, id: item.id };
					})
				);
			}
			setIsLoading(false);
		};
		getHealthDetails();
	}, [intl, selectedPatient]);

	useEffect(() => {
		if (!healthcareUserId) {
			setCurrentTab(0);
			setIsSecondTabEnabled(false);
			setSelectedSymptoms([]);
			setAdditionalData({ medicalQuestionAnswers: [], additionalNotes: '', attachments: [] });
		}
	}, [healthcareUserId]);

	useEffect(() => {
		if (currentTab === tabs.SELECT_PATIENT && isSecondTabEnabled) {
			setCurrentTab(tabs.PATIENT_HEALTH_DETAILS);
		}
	}, [isSecondTabEnabled]);

	useEffect(() => {
		setSelectedSymptoms([]);
		setAdditionalData({ medicalQuestionAnswers: [], additionalNotes: '', attachments: [] });
	}, [selectedPatient]);

	const addSymptom = symptom => {
		const checkIfSymptomExists = selectedSymptoms.find(item => symptom.name === item.name);
		if (selectedSymptoms.length >= SymptomsLength.MAX_SYMPTOMS) {
			setError(intl.formatMessage({ id: 'limitedSelectedSymptoms' }));
			return;
		}
		if (!checkIfSymptomExists) {
			selectedSymptoms.push(symptom);
		}
		setSelectedSymptoms(selectedSymptoms);
		setSymptoms(symptoms.filter(item => symptom.name !== item.name));
	};

	const removeSymptom = symptom => {
		setError(null);
		symptoms.push(symptom);
		setSelectedSymptoms(selectedSymptoms.filter(item => symptom.name !== item.name));
		setSymptoms(symptoms);
	};

	const canChangeTab = nextTab => {
		let result = false;
		switch (currentTab) {
			case tabs.SELECT_PATIENT: {
				if (isSecondTabEnabled) {
					result = true;
				}
				break;
			}

			case tabs.SELECT_DOCTOR: {
				if (nextTab < currentTab) {
					result = true;
				}
				break;
			}

			default: {
				return true;
			}
		}
		return result;
	};

	return (
		<>
			<MainLayout>
				{currentTab !== tabs.SELECT_PATIENT && (
					<Button
						onClick={() => tabChange(currentTab - 1)}
						imgIcon={`${healthCareCdnUrl}backward-arrow-dark-gray.svg`}
						text={translate('goBack')}
						className='requests-wrapper-button'
						variant='backward'
					/>
				)}
				{((tabs.SELECT_PATIENT === currentTab && isPatientFormVisible) || currentTab !== tabs.SELECT_DOCTOR) && (
					<Button
						onClick={() => tabChange(currentTab + 1)}
						imgIcon={`${healthCareCdnUrl}forward-arrow-white.svg`}
						text={translate('next')}
						className='requests-wrapper-button'
						variant='forward'
						isDisabled={!isSecondTabEnabled}
					/>
				)}
				{!isLoading && (
					<Tabs activeIndex={currentTab} onChange={index => tabChange(index)}>
						<TabList className='doctor-wrapper-tabs'>
							{tabList.map(tab => (
								<Tab
									className={classNames(currentTab > tab.id ? 'tab-past' : '', currentTab < tab.id ? 'tab-next' : '')}
									key={tab.id}>
									{currentTab === tab.id && <img src={tab.activeImg} alt='icon' />}
									{currentTab > tab.id && <img src={tab.pastImg} alt='icon' />}
									{currentTab < tab.id && <img src={tab.mainImg} alt='icon' />}
									{tab.title}
								</Tab>
							))}
						</TabList>
						<TabPanels>
							<TabPanel>
								{!isDeviceOffline && (
									<SelectPatient
										setIsSecondTabEnabled={setIsSecondTabEnabled}
										setPatientUserId={id => setPatientId(id)}
										goToSymptoms={() => tabChange(tabs.PATIENT_HEALTH_DETAILS)}
										setSelectedPatient={val => setSelectedPatient(val)}
										selectedPatient={selectedPatient}
										isPatientFormVisible={isPatientFormVisible}
										setIsPatientFormVisible={setIsPatientFormVisible}
									/>
								)}
								{isDeviceOffline && <DeviceOffline />}
							</TabPanel>
							<TabPanel>
								{!isDeviceOffline && (
									<PatientHealthDetails
										symptoms={symptoms}
										selectedSymptoms={selectedSymptoms}
										removeSymptom={symptom => removeSymptom(symptom)}
										addSymptom={symptom => addSymptom(symptom)}
										error={error}
										setAnswers={answers => setAdditionalData(answers)}
										questions={medicalQuestions}
										additionalData={additionalData}
									/>
								)}
								{isDeviceOffline && <DeviceOffline />}
							</TabPanel>
							<TabPanel>
								{!isDeviceOffline && <PatientVitalSigns selectedPatient={{ ...selectedPatient, userId: patientId }} />}
								{isDeviceOffline && <DeviceOffline />}
							</TabPanel>
							<TabPanel>
								{!isDeviceOffline && (
									<AvailableDoctors
										symptoms={selectedSymptoms}
										additionalData={additionalData}
										isCheckInPatient={true}
										departmentId={getStorage().getItem('departmentId')}
										room={room}
									/>
								)}
								{isDeviceOffline && <DeviceOffline />}
							</TabPanel>
						</TabPanels>
					</Tabs>
				)}
				{isLoading && (
					<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
						<Loader />
					</Grid>
				)}
			</MainLayout>
		</>
	);
};

export default CheckInAPatient;
