import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const InjectionRoom = ({ color = LightTheme.colors.graySeven, strokeWidth = 2, ...props }) => (
	<svg width={29} height={28} fill='none' xmlns='http://www.w3.org/2000/svg' color={color} {...props}>
		<path
			d='m10.13 22.662 7.837-7.837a2.917 2.917 0 0 0 0-4.125v0a2.917 2.917 0 0 0-4.125 0l-7.837 7.837M13.842 11.525l1.237 1.238M11.367 14l1.237 1.238M8.892 16.475l1.238 1.237M24.566 4.1l-6.6 6.6M8.067 20.6l-3.3 3.3M3.53 22.662l2.475 2.475M6.005 18.537l4.124 4.125'
			stroke='currentColor'
			strokeWidth={strokeWidth}
			strokeLinecap='round'
		/>
	</svg>
);

export default InjectionRoom;
