import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const addVisitingHours = async params => {
	try {
		const { data } = await gatewayApi.post(`/v1/healthcare/organizations/${getCompanyId()}/visiting-hours`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getVisitingHours = async (organizationLevel, sectorId) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/organizations/${getCompanyId()}/level/${organizationLevel}/id/${sectorId}/visiting-hours`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const disableVisits = async (level, id, minutes) => {
	try {
		const url = `/v1/healthcare/organizations/${getCompanyId()}/level/${level}/id/${id}/visiting-hours/disable`;
		const { data } = await gatewayApi.post(url, { minutes });
		return data;
	} catch (error) {
		return { error };
	}
};

export const enableVisits = async (level, id) => {
	try {
		const url = `/v1/healthcare/organizations/${getCompanyId()}/level/${level}/id/${id}/visiting-hours/disable`;
		const { data } = await gatewayApi.delete(url);
		return data;
	} catch (error) {
		return { error };
	}
};

