import * as React from 'react';

const RespiratoryIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={16} fill='none' {...props}>
		<path
			fill='#62D3FD'
			d='M9.75 5.2a.75.75 0 0 0-1.5 0h1.5Zm3.582 6.585a.75.75 0 0 0 .936-1.171l-.937 1.171ZM13.85 9a.75.75 0 1 0-.9-1.2l.9 1.2ZM3.731 10.614a.75.75 0 1 0 .938 1.171l-.938-1.171ZM5.05 7.8a.75.75 0 1 0-.9 1.2l.9-1.2Zm1.6-.2a.75.75 0 0 0 1.5 0h-1.5Zm1.5 3.6a.75.75 0 0 0-1.5 0h1.5Zm1.7-3.6a.75.75 0 0 0 1.5 0h-1.5Zm1.5 3.6a.75.75 0 0 0-1.5 0h1.5Zm-5.702 2.619-.093-.745.093.745Zm-1.752.219-.093-.745.093.745ZM14.14 2.96l-.727.182.727-.182ZM8.25 5.2v3.6h1.5V5.2h-1.5Zm6.018 5.414-2-1.6-.937 1.171 2 1.6.938-1.171Zm-2.262-1.736-2.8-.8-.412 1.443 2.8.8.412-1.443Zm.244 1.322 1.6-1.2-.9-1.2-1.6 1.2.9 1.2Zm-7.581 1.585 2-1.6-.938-1.171-2 1.6.938 1.171Zm1.737-1.464 2.8-.8-.412-1.443-2.8.8.412 1.443ZM6.65 9l-1.6-1.2-.9 1.2 1.6 1.2.9-1.2Zm0-5.603V7.6h1.5V3.397h-1.5Zm-3.518-.618L.98 11.386l1.456.364 2.151-8.607-1.455-.364Zm.857 12.003 1.752-.22-.186-1.488-1.752.22.186 1.488Zm4.161-2.948V11.2h-1.5v.634h1.5Zm1.7-8.437V7.6h1.5V3.397h-1.5Zm3.563-.254 2.152 8.607 1.455-.364-2.152-8.607-1.455.364Zm.784 10.15-1.752-.219-.186 1.489 1.752.219.186-1.489Zm-2.847-1.459V11.2h-1.5v.634h1.5Zm1.095 1.24a1.25 1.25 0 0 1-1.095-1.24h-1.5a2.75 2.75 0 0 0 2.409 2.729l.186-1.489Zm3.12-1.324a1.25 1.25 0 0 1-1.368 1.543l-.186 1.489c1.917.24 3.477-1.521 3.009-3.396l-1.455.364ZM12.396.85A2.547 2.547 0 0 0 9.85 3.397h1.5c0-.579.469-1.047 1.047-1.047V.85ZM5.741 14.563a2.75 2.75 0 0 0 2.409-2.729h-1.5c0 .63-.47 1.162-1.095 1.24l.186 1.489Zm-4.76-3.177c-.47 1.875 1.09 3.636 3.008 3.396l-.186-1.489a1.25 1.25 0 0 1-1.367-1.543L.98 11.386ZM12.396 2.35c.48 0 .9.327 1.016.793l1.455-.364a2.547 2.547 0 0 0-2.471-1.93v1.5Zm-6.794 0c.578 0 1.047.468 1.047 1.047h1.5A2.547 2.547 0 0 0 5.603.85v1.5Zm0-1.5a2.547 2.547 0 0 0-2.47 1.929l1.454.364a1.047 1.047 0 0 1 1.016-.793V.85Z'
		/>
	</svg>
);
export default RespiratoryIcon;
