const LiveCaptionsIcon = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={props.width || 24}
		height={props.height || 24}
		viewBox='0 0 24 24'
		fill='none'
		strokeWidth={1.5}
		{...props}>
		<rect x='3' y='6' width='18' height='12' rx='1' stroke='currentColor' />
		<path
			d='M10.5 13.5C10.5 14.0523 10.0523 14.5 9.5 14.5H8.5C7.94772 14.5 7.5 14.0523 7.5 13.5V10.5C7.5 9.94772 7.94772 9.5 8.5 9.5H9.5C10.0523 9.5 10.5 9.94772 10.5 10.5M16.5 13.5C16.5 14.0523 16.0523 14.5 15.5 14.5H14.5C13.9477 14.5 13.5 14.0523 13.5 13.5V10.5C13.5 9.94772 13.9477 9.5 14.5 9.5H15.5C16.0523 9.5 16.5 9.94772 16.5 10.5'
			stroke='currentColor'
		/>
	</svg>
);

export default LiveCaptionsIcon;
