const getChannel = () => {
	let result = null;
	try {
		const broadcastChannel = new BroadcastChannel('userIdle');
		result = broadcastChannel;
	} catch (err) {
		// eslint-disable-next-line no-console
		console.log(err);
	}
	return result;
};

const channel = getChannel();

export const postUserIdleMessage = msg => {
	try {
		if (channel) {
			channel.postMessage(msg);
		}
	} catch (error) {
		console.warn('Failed to post message:', error);
	}
};

export const onUserIdleMessage = handler => {
	if (channel) {
		channel.onmessage = handler;
	}
};

export const closeUserIdleChannel = () => {
	if (channel) {
		channel.close();
	}
};
