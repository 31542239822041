import { getTeamSettings } from 'api/adminConfigurations.js';
import ColumnFilters from 'components/ColumnFilters.jsx';
import AIOverview from 'components/DashboardCharts/PatientCentric/AI/AIOverview.jsx';
import Interventions from 'components/DashboardCharts/PatientCentric/Interventions.jsx';
import Session from 'components/DashboardCharts/PatientCentric/Session.jsx';
import DateRangePicker from 'components/DateRangePicker.jsx';
import { Alert, Button } from 'components/index.js';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { Tab, TabList, Tabs } from 'components/Tabs.jsx';
import { AiSetting, DashboardSettings, SettingsCategory } from 'constants/configurationEnums.js';
import { DefaultTimezone, DetailedReportType, InterventionColumnLabels } from 'constants/dashboard.js';
import { DateRangePresets, DeviceListLevel } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import Download from 'icons/Dashboard/Download.jsx';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { getMonthDayYearDateFormat, localTimeToTimezone } from 'infrastructure/helpers/dateHelper.js';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import MainLayout from 'views/Layouts/MainLayout.jsx';

const StickyHeader = styled.div`
	position: sticky;
	z-index: 50;
	top: 0;
	border-bottom: 1px solid var(--gray-2);
	display: flex;
	align-items: center;
	gap: var(--spacing-s);
	padding: var(--spacing-xl);
	background: var(--gray-0);
	min-height: 88px;

	> div {
		&:first-of-type {
			align-items: center;
			background: var(--gray-12);
			border-radius: var(--spacing-s);
			cursor: pointer;
			display: flex;
			flex-shrink: 0;
			height: 25px;
			justify-content: center;
			margin-right: var(--spacing-s);
			width: 25px;
			i {
				font-size: 16px;
			}
		}

		&:nth-of-type(3) {
			margin-left: var(--spacing-s);
			text-align: left;
			p {
				margin: 0;
				padding: 0;
				text-align: left;
				color: var(--blue-2);
			}
		}

		&:last-of-type {
			margin-left: var(--spacing-sl);
			font-size: 14px;
			text-align: left;
			p {
				margin: 0;
				padding: 0;
			}
		}
		.chat-list-item-initials {
			font-size: 10px;
			height: 25px;
			width: 25px;
		}
	}
`;

const fromDefault = new Date();
const toDefault = new Date();
const maximumRangeOfDays = 90;

const DetailedReport = () => {
	const history = useHistory();
	const location = useLocation();
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const currentHealthSystem = useSelector(state => state.user.userSession.healthSystem);
	const [currentTab, setCurrentTab] = useState(DetailedReportType.SESSIONS);
	const [dateRange, setDateRange] = useState({ from: fromDefault, to: toDefault, preset: DateRangePresets.TODAY });
	const [dateRangeConstraint, setDateRangeConstraint] = useState({ minDate: null, maxDate: null });
	const [error, setError] = useState(null);
	const [selectedTimezone, setSelectedTimezone] = useState(DefaultTimezone);
	const [isEvidenceEnabled, setIsEvidenceEnabled] = useState(false);
	const [columnFilters, setColumnFilters] = useState({});
	const [filterOptions, setFilterOptions] = useState({});
	const downloadButtonRef = useRef(null);

	const getPatientProperty = property => location.state.patient?.[property] ?? '';

	const tabs = [
		{
			id: DetailedReportType.SESSIONS,
			description: 'sessions',
		},
		getConfigurationValue(companyConfigurations[DashboardSettings.PATIENT_INTERVENTIONS]) && {
			id: DetailedReportType.INTERVENTIONS,
			description: 'interventions',
		},
		getConfigurationValue(companyConfigurations[DashboardSettings.AI_ALERTS]) && {
			id: DetailedReportType.AI,
			description: 'ai',
		},
	].filter(Boolean);

	useEffect(() => {
		const getAiFeatureFlags = async () => {
			const response = await getTeamSettings({
				teamId: currentHealthSystem?.id,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: [SettingsCategory.AI_SETTINGS],
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setIsEvidenceEnabled(
				response.settings.some(item => item.settingTypeId === AiSetting.ALERTS_EVIDENCE_ADMIN && item.value === 'true')
			);
		};
		getAiFeatureFlags();
	}, []);

	const utcToTimezoneWithSeconds = (date, zone) => moment(date).utc(true).tz(zone).format('YYYY-MM-DD[T]HH:mm:ss');

	useEffect(() => {
		const { patient, filters } = location.state;
		if (patient) {
			const zone = filters.selectedTimezone.zone;
			const today = new Date(localTimeToTimezone(new Date(), zone));
			today.setHours(23, 59, 59, 999);
			const minDate = new Date(utcToTimezoneWithSeconds(patient.admissionDate, zone));
			const maxDate = patient.dischargeDate ? new Date(utcToTimezoneWithSeconds(patient.dischargeDate, zone)) : today;
			setDateRange({ from: minDate, to: maxDate, preset: DateRangePresets.CUSTOM_RANGE });
			setDateRangeConstraint({ minDate, maxDate });
			setSelectedTimezone(filters.selectedTimezone ?? DefaultTimezone);
		}
		setColumnFilters({});
	}, [location, currentTab]);

	return (
		<MainLayout>
			<div className='main-view'>
				<StickyHeader>
					<div
						onClick={() =>
							history.push({
								pathname: location.state.prevPath,
								search: location.state.prevSearch,
								state: { ...location.state, prevPath: location.pathname },
							})
						}>
						<i className='material-icons-outlined'>arrow_back</i>
					</div>
					<ProfilePicture
						fullName={getPatientProperty('fullName')}
						profilePicture={getPatientProperty('profilePicture')}
						className='provider-intervening-img-wrapper'
					/>
					<div>
						<span>{getPatientProperty('fullName')}</span>
						{getPatientProperty('mrn') && <p>MRN {getPatientProperty('mrn')}</p>}
					</div>
					<div>
						<label>{translate('dateOfBirth')}</label>
						<p>
							{location.state.patient?.birthDate ? getMonthDayYearDateFormat(location.state.patient.birthDate) : translate('na')}
						</p>
					</div>
				</StickyHeader>

				<div className='position-relative'>
					<div className='detailed-report-header'>
						<div className='position-absolute detailed-report-filters'>
							{currentTab === DetailedReportType.INTERVENTIONS && (
								<>
									<Button buttonRef={downloadButtonRef} svgIcon={<Download />} text={translate('downloadPatientReport')} />
									<ColumnFilters
										filterOptions={filterOptions}
										columnLabels={InterventionColumnLabels}
										columnFilters={columnFilters}
										setColumnFilters={setColumnFilters}
									/>
								</>
							)}
							<label>
								<DateRangePicker
									defaultFrom={dateRange.from}
									defaultTo={dateRange.to}
									defaultPreset={dateRange.preset}
									handleRangeChange={setDateRange}
									maxDays={maximumRangeOfDays}
									selectedTimezone={selectedTimezone}
									{...dateRangeConstraint}
								/>
							</label>
						</div>
					</div>
					<Tabs activeIndex={currentTab} onChange={setCurrentTab} className='detailed-report-tabs'>
						<TabList>
							{tabs.map(tab => (
								<Tab customIndex={tab.id} key={tab.id}>
									{translate(tab.description)}
								</Tab>
							))}
						</TabList>
					</Tabs>
					{currentTab === DetailedReportType.SESSIONS && <Session dateRange={dateRange} />}
					{currentTab === DetailedReportType.INTERVENTIONS && (
						<Interventions
							dateRange={dateRange}
							downloadButtonRef={downloadButtonRef}
							columnFilters={columnFilters}
							setFilterOptions={setFilterOptions}
						/>
					)}
					{currentTab === DetailedReportType.AI && (
						<AIOverview dateRange={dateRange} selectedTimezone={selectedTimezone} isEvidenceEnabled={isEvidenceEnabled} />
					)}
				</div>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</MainLayout>
	);
};

export default DetailedReport;
