const IoTDevice = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} viewBox='0 0 25 24' fill='none' {...props}>
		<circle cx={14.875} cy={13} r={4} stroke='currentColor' strokeWidth={1.5} />
		<circle cx={20.875} cy={20} r={2} stroke='currentColor' strokeWidth={1.5} />
		<circle cx={20.875} cy={4} r={2} stroke='currentColor' strokeWidth={1.5} />
		<circle cx={4.875} cy={5} r={2} stroke='currentColor' strokeWidth={1.5} />
		<circle cx={5.875} cy={18} r={2} stroke='currentColor' strokeWidth={1.5} />
		<path d='M6.375 6.5L11.875 10.5' stroke='currentColor' strokeWidth={1.5} />
		<path d='M19.875 5.5L16.875 9.5' stroke='currentColor' strokeWidth={1.5} />
		<path d='M7.875 17L11.375 15' stroke='currentColor' strokeWidth={1.5} />
		<path d='M19.375 18.5L17.375 16' stroke='currentColor' strokeWidth={1.5} />
	</svg>
);
export default IoTDevice;
