import { useState, useRef, useEffect, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { enums, participant } from '@solaborate/calls';
import { ToggleGroup, Modal, Button, CameraBookmarks, Icon } from 'calls/components/index.js';
import { useConferenceConfigurations, useControllerTracks, useHelloDeviceState, useScreenType } from 'calls/hooks/index.js';
import { CameraMaxZoomLevels, CameraTypes } from 'calls/enums/index.js';
import { LOCALES } from 'i18n-translations/locales.js';
import translate from 'i18n-translations/translate.jsx';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { ButtonType, CameraDirections, CameraZoomLevels } from 'constants/enums.js';
import { Dropdown } from 'components/index.js';
import { StyledPTZ, StyledCameraControls } from 'calls/styles/styled-components/index.js';
import CustomButton from 'components/Button.jsx';
import { useSelector } from 'react-redux';

const { CameraMoveDirections, CameraEventActions } = enums;

/**
 * @param {object} props
 * @param {import('calls/RemoteHelloParticipant.js').default} props.helloDevice
 * @param {string} props.activeTrackType
 * @param {boolean} props.isHelloParticipant
 */
const CameraControls = ({ helloDevice, activeTrackType, isHelloParticipant }) => {
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	const socket = useContext(SocketContext);
	const [isRebootHuddleCamModalOpen, setIsRebootHuddleCamModalOpen] = useState(false);
	const [isSwitchDeviceDisabled, setIsSwitchDeviceDisabled] = useState(false);
	const intl = useIntl();
	const prevActionRef = useRef(null);
	const moveCamInterval = useRef(null);
	const disabledSwitchTimeout = useRef(null);
	const conferenceConfigs = useConferenceConfigurations();
	const locale = getPreferredLanguageLocale();
	const deviceState = useHelloDeviceState(helloDevice);
	const screenType = useScreenType();
	const selectedCamera = useMemo(() => {
		return deviceState?.mediaDevices?.find(mediaDevice => {
			if (isNewExperience) {
				return mediaDevice.isActive && [CameraTypes.HELLO, CameraTypes.HUDDLE].includes(mediaDevice.type);
			}
			return mediaDevice.isActive && mediaDevice.type === enums.MediaTypes.CAMERA;
		});
	}, [deviceState, isNewExperience]);
	const [sliderRange, setSliderRange] = useState(0);
	const [presets, setPresets] = useState(deviceState?.bookmarkList);
	const [activePreset, setActivePreset] = useState(null);
	const [availableDirections, setAvailableDirections] = useState(selectedCamera?.capabilities?.tilt?.availableDirection);

	const tracks = useControllerTracks(helloDevice?.remoteTrackController);
	const videoTrack = tracks[activeTrackType];

	useEffect(() => {
		const onEvent = event => {
			if (isNewExperience && event instanceof participant.HelloDeviceStateChanged) {
				setAvailableDirections(selectedCamera?.capabilities?.tilt?.availableDirection);
				if (isSwitchDeviceDisabled && selectedCamera?.isActive) {
					setIsSwitchDeviceDisabled(false);
				}
			}
			if (event instanceof participant.ActiveDeviceChanged) {
				setIsSwitchDeviceDisabled(false);
				setActivePreset(null);
			}
		};

		helloDevice.on(onEvent);

		return () => {
			helloDevice.off(onEvent);

			if (disabledSwitchTimeout.current) {
				clearTimeout(disabledSwitchTimeout.current);
			}
		};
	}, [helloDevice, isNewExperience, selectedCamera, isSwitchDeviceDisabled]);

	useEffect(() => {
		const onInitialState = () => {
			if (selectedCamera?.isActive && selectedCamera?.capabilities?.bookmarks?.isSupported) {
				setPresets(selectedCamera?.capabilities?.bookmarks?.list);
			}
		};

		const onMediaControlsResponse = data => {
			if (data.command === enums.MediaControlsCommands.BOOKMARK_LIST) {
				setPresets(data.data);
				if (activePreset && !data.data.some(item => item.id === activePreset?.id)) {
					setActivePreset(null);
				}
			}

			if (data.command === enums.MediaControlsCommands.MOVE_TO_BOOKMARK) {
				const active = presets?.find(x => x.id === data.data);
				setActivePreset(active);
			}

			if (data.command === enums.MediaControlsCommands.ACTIVE_DEVICE) {
				if (isNewExperience) {
					setSliderRange(selectedCamera?.capabilities?.zoom?.current);
					return;
				}
				setSliderRange(0);
			}

			if (isNewExperience && data.command === enums.MediaControlsCommands.DEVICES_LIST) {
				const incomingPresets = data?.data.find(item => item?.capabilities?.bookmarks?.isSupported)?.capabilities?.bookmarks
					?.list;
				const activeCamera = data?.data?.find(
					mediaDevice => mediaDevice.isActive && [CameraTypes.HELLO, CameraTypes.HUDDLE].includes(mediaDevice.type)
				);
				setAvailableDirections(activeCamera?.capabilities?.tilt?.availableDirection);
				setPresets(incomingPresets || []);
				setSliderRange(activeCamera?.capabilities?.zoom?.current);
			}
		};

		socket.on(SocketEvents.HelloDevice.ON_INITIAL_STATE, onInitialState);
		socket.on(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, onMediaControlsResponse);

		return () => {
			socket.off(SocketEvents.HelloDevice.ON_INITIAL_STATE, onInitialState);
			socket.off(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, onMediaControlsResponse);
		};
	}, [socket, presets, isNewExperience, activePreset, selectedCamera]);

	useEffect(() => {
		if (!isNewExperience && isHelloParticipant) {
			setSliderRange(deviceState?.cameraZoomLevel);
		}
	}, [helloDevice, deviceState?.cameraZoomLevel, isNewExperience, isHelloParticipant]);

	const enableOppositeDirection = direction => {
		if ([CameraDirections.RIGHT, CameraDirections.LEFT].includes(direction)) {
			setAvailableDirections({
				...availableDirections,
				right: true,
				left: true,
			});
		} else {
			setAvailableDirections({
				...availableDirections,
				up: true,
				down: true,
			});
		}
	};

	const handleNewExperienceDirection = (direction, action) => {
		if (
			!availableDirections[direction] ||
			!selectedCamera?.capabilities?.tilt?.isSupported ||
			!selectedCamera?.id ||
			!direction ||
			!action
		) {
			return;
		}

		helloDevice.sendMediaControlsEvent(
			enums.MediaControlsCommands.MOVE,
			enums.MediaTypes.CAMERA,
			JSON.stringify({
				cameraId: selectedCamera?.id,
				direction,
				action,
			})
		);
		enableOppositeDirection(direction);
	};

	const sendDirection = (direction, action) => {
		if (isNewExperience) {
			handleNewExperienceDirection(direction, action);
			return;
		}

		if (
			(prevActionRef.current === CameraEventActions.STOP && action === CameraEventActions.STOP) ||
			(selectedCamera?.name === CameraTypes.HELLO && deviceState?.disabledDirections[direction])
		) {
			return;
		}

		helloDevice.moveCamera(direction, action);
		prevActionRef.current = action;
	};

	const filteredDevices = useMemo(() => {
		if (!deviceState?.mediaDevices) {
			return [];
		}

		return deviceState?.mediaDevices
			.filter(device => [CameraTypes.HELLO, CameraTypes.HUDDLE].includes(isNewExperience ? device.type : device.name))
			.sort((a, b) => (a.type === CameraTypes.HELLO ? -1 : b.type === CameraTypes.HELLO ? 1 : 0));
	}, [deviceState?.mediaDevices, isNewExperience]);

	const handleToggleCamera = id => {
		if (isSwitchDeviceDisabled) {
			return;
		}
		setIsSwitchDeviceDisabled(true);
		// If no response is received from Hello enable switch after 3 seconds
		if (disabledSwitchTimeout.current) {
			clearTimeout(disabledSwitchTimeout.current);
		}
		disabledSwitchTimeout.current = setTimeout(() => {
			setIsSwitchDeviceDisabled(false);
		}, 3000);
		if (!id) {
			return;
		}
		helloDevice.sendMediaControlsEvent(enums.MediaControlsCommands.ACTIVE_DEVICE, enums.MediaTypes.CAMERA, id);
	};

	const getCameraName = (name, type) => {
		if (CameraTypes.HUDDLE === type) {
			return name || '20x';
		}
		return name || type;
	};

	if (!videoTrack) {
		return <></>;
	}

	const saveHomePosition = () => {
		if (!selectedCamera?.id) {
			return;
		}
		const dataToSend = isNewExperience ? JSON.stringify({ cameraId: selectedCamera?.id }) : 'default';
		helloDevice.sendMediaControlsEvent(enums.MediaControlsCommands.UPDATE_HOME_POSITION, enums.MediaTypes.CAMERA, dataToSend);
	};

	const moveToHomePosition = async event => {
		event.stopPropagation();
		if (!selectedCamera?.id) {
			return;
		}
		const dataToSend = isNewExperience ? JSON.stringify({ cameraId: selectedCamera?.id }) : 'recenter';
		const mediaControlsCommand = isNewExperience ? enums.MediaControlsCommands.HOME : enums.MediaControlsCommands.RECENTER;
		const response = await helloDevice.sendMediaControlsEvent(mediaControlsCommand, enums.MediaTypes.CAMERA, dataToSend);

		setActivePreset(null);

		if (!response.ok) {
			// error
		}
	};

	// send direction event every 500ms so camera won't stop moving
	const handleMoveTimeout = (direction, action) => {
		// clear interval on function call to prevent creating one on every call
		clearInterval(moveCamInterval.current);
		if (action === CameraEventActions.STOP) {
			sendDirection(direction, action);
		} else {
			sendDirection(direction, action);
			moveCamInterval.current = setInterval(() => {
				handleMoveTimeout(direction, action);
			}, 500);
			if (activePreset) {
				setActivePreset(null);
			}
		}
	};

	const handleSliderZoom = evt => {
		if (!selectedCamera?.id) {
			return;
		}
		if (isNewExperience) {
			helloDevice.sendMediaControlsEvent(
				enums.MediaControlsCommands.ZOOM,
				enums.MediaTypes.CAMERA,
				JSON.stringify({ cameraId: selectedCamera?.id, level: +evt.target.value })
			);
			return;
		}
		helloDevice.zoomCamera(+evt.target.value);
	};

	const handleRebootDevice = () => {
		if (isNewExperience && filteredDevices.some(device => device?.type === CameraTypes.HUDDLE && device?.isActive)) {
			if (!selectedCamera?.id) {
				return;
			}
			helloDevice.sendMediaControlsEvent(
				enums.MediaControlsCommands.REBOOT,
				enums.MediaTypes.CAMERA,
				JSON.stringify({
					cameraId: selectedCamera?.id,
				})
			);
			setIsRebootHuddleCamModalOpen(false);
			return;
		}
		if (deviceState?.isHuddleConnected) {
			helloDevice.sendDeviceCommand(enums.DeviceCommands.REBOOT_HUDDLE_CAM);
			setIsRebootHuddleCamModalOpen(false);
		}
	};

	const getDirectionIcon = direction => {
		switch (direction) {
			case CameraMoveDirections.UP:
			case CameraDirections.UP:
				return 'keyboard_arrow_up';
			case CameraMoveDirections.RIGHT:
			case CameraDirections.RIGHT:
				return 'keyboard_arrow_right';
			case CameraMoveDirections.LEFT:
			case CameraDirections.LEFT:
				return 'keyboard_arrow_left';
			case CameraMoveDirections.DOWN:
			case CameraDirections.DOWN:
				return 'keyboard_arrow_down';
			default:
				return null;
		}
	};

	const renderCameraDirections = isNewExperience
		? selectedCamera?.capabilities?.tilt?.isSupported
		: selectedCamera?.name !== CameraTypes.OTOSCOPE;

	const cameraDirections = isNewExperience
		? Object.values(CameraDirections)
		: [CameraMoveDirections.UP, CameraMoveDirections.RIGHT, CameraMoveDirections.LEFT, CameraMoveDirections.DOWN];

	const showHomeButton = isNewExperience
		? selectedCamera?.capabilities?.home?.isSupported
		: selectedCamera?.name === CameraTypes.HUDDLE && deviceState?.isHuddleConnected;

	const maxZoomLevel = isNewExperience
		? selectedCamera?.capabilities?.zoom?.levels
		: selectedCamera?.name === CameraTypes.HUDDLE
		? CameraMaxZoomLevels.HUDDLE_MAX
		: CameraMaxZoomLevels.HELLO_MAX;

	const showBookmarks = isNewExperience
		? selectedCamera?.capabilities?.bookmarks?.isSupported
		: selectedCamera?.name === CameraTypes.HUDDLE;

	return (
		<>
			<StyledPTZ
				$isVisible={conferenceConfigs.isCameraControlsOpen && isHelloParticipant}
				$top={0}
				$fixedPosition={screenType.isSmall}
				$isRightToLeft={locale === LOCALES.ARABIC}
				$isDarkMode={conferenceConfigs.isDarkMode}>
				{filteredDevices.length > 1 && (
					<header>
						<ToggleGroup>
							{filteredDevices.map(({ id, name, isActive, capabilities }) => (
								<ToggleGroup.Item
									key={id}
									onChange={() => handleToggleCamera(id)}
									disabled={isSwitchDeviceDisabled}
									name={name}
									checked={isActive}>
									{getCameraName(capabilities.customName, name)}
								</ToggleGroup.Item>
							))}
						</ToggleGroup>
					</header>
				)}
				{renderCameraDirections && (
					<>
						<StyledCameraControls $isDarkMode={conferenceConfigs.isDarkMode} $fixedPosition={screenType.isSmall}>
							<div>
								{cameraDirections.map(direction => (
									<Button disabled={availableDirections && availableDirections[direction] === false} key={direction}>
										<i
											key={direction}
											onMouseDown={() => handleMoveTimeout(direction, CameraEventActions.START)}
											onMouseUp={() => handleMoveTimeout(direction, CameraEventActions.STOP)}
											onMouseOut={() => {
												if (!document.body.matches(':active')) {
													return;
												}
												handleMoveTimeout(direction, CameraEventActions.STOP);
											}}
											onTouchStart={() => handleMoveTimeout(direction, CameraEventActions.START)}
											onTouchEnd={() => handleMoveTimeout(direction, CameraEventActions.STOP)}
											onBlur={() => {}}>
											<Icon name={getDirectionIcon(direction)} />
										</i>
									</Button>
								))}
								<div>
									{showHomeButton && <CustomButton onClick={moveToHomePosition} svgIcon={<Icon name='home' />} alt='home' />}
								</div>
							</div>
							<div>
								<input
									type='range'
									min={0}
									max={maxZoomLevel}
									step={isNewExperience ? 1 : 20}
									value={sliderRange}
									onChange={evt => {
										evt.stopPropagation();
										setSliderRange(+evt.target.value);
									}}
									onMouseUp={handleSliderZoom}
									onTouchEnd={handleSliderZoom}
									disabled={selectedCamera?.capabilities?.zoom <= CameraZoomLevels.NO_ZOOM}
								/>
								{(isNewExperience
									? selectedCamera?.capabilities?.reboot?.isSupported && selectedCamera?.capabilities?.home?.isSupported
									: selectedCamera?.name === CameraTypes.HUDDLE) && (
									<Dropdown position='right' icon='more_vert'>
										<div>
											<div>
												<CustomButton
													icon='my_location'
													text={intl.formatMessage({ id: 'updateToCurrentPosition' })}
													onClick={saveHomePosition}
													disabled={
														isNewExperience ? !selectedCamera?.capabilities?.home?.isSupported : !deviceState?.isHuddleConnected
													}
												/>
											</div>
											<div>
												<CustomButton
													icon='refresh'
													text={intl.formatMessage({ id: 'resetCamera' })}
													onClick={evt => {
														evt.stopPropagation();
														setIsRebootHuddleCamModalOpen(true);
													}}
													disabled={
														isNewExperience ? !selectedCamera?.capabilities?.reboot?.isSupported : !deviceState?.isHuddleConnected
													}
												/>
											</div>
										</div>
									</Dropdown>
								)}
							</div>
						</StyledCameraControls>
						{showBookmarks && (
							<CameraBookmarks
								selectedCamera={selectedCamera}
								helloDevice={helloDevice}
								activePreset={activePreset}
								setActivePreset={setActivePreset}
								bookmarks={presets}
							/>
						)}
					</>
				)}
			</StyledPTZ>
			{isRebootHuddleCamModalOpen && (
				<Modal onDismiss={() => setIsRebootHuddleCamModalOpen(false)} title={intl.formatMessage({ id: 'rebootCamera' })}>
					<Modal.Content>
						<p>
							{translate('areYouSureRebootHuddleCam', {
								value: filteredDevices.find(device => device?.name === CameraTypes.HUDDLE)?.capabilities?.customName || '20x',
							})}
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button variant={ButtonType.SUBMIT} onClick={handleRebootDevice}>
							{translate('reboot')}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
		</>
	);
};

export default CameraControls;
