import * as React from 'react';

function MicOff(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} viewBox="0 0 24 24" fill='none' {...props}>
			<path d='M1.2 1.8l21 21' stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' />
			<path d='M9 9.6V12a3 3 0 003 3h2.4' stroke='currentColor' strokeWidth={1.5} />
			<path
				d='M16.73 17.33a.75.75 0 10-1.06-1.06l1.06 1.06zm-5.48 4.27a.75.75 0 001.5 0h-1.5zm-4.5-10.8a.75.75 0 00-1.5 0h1.5zm12 0a.75.75 0 00-1.5 0h1.5zm-.923 2.35l.742.112-.742-.112zm-.749-.062l-.111.742 1.483.223.112-.742-1.484-.223zM9 21.45a.75.75 0 000 1.5v-1.5zm6 1.5a.75.75 0 000-1.5v1.5zm.67-6.68c-.42.418-1.255.692-2.143.84a10.814 10.814 0 01-1.503.14H12v1.5h.043l.11-.003a12.325 12.325 0 001.621-.158c.911-.151 2.175-.478 2.956-1.259l-1.06-1.06zm-2.92 5.33V18h-1.5v3.6h1.5zm-6-9.6v-1.2h-1.5V12h1.5zm-1.5 0A6.75 6.75 0 0012 18.75v-1.5A5.25 5.25 0 016.75 12h-1.5zm12-1.2v.036h1.5V10.8h-1.5zm-.164 2.24l-.008.049 1.484.222.007-.05-1.483-.222zm.164-2.204c0 .738-.055 1.474-.164 2.203l1.483.223c.12-.803.181-1.614.181-2.426h-1.5zM9 22.95h6v-1.5H9v1.5z'
				fill='currentColor'
			/>
			<path d='M9 6V4.8a3 3 0 013-3v0a3 3 0 013 3v6.6' stroke='currentColor' strokeWidth={1.5} />
		</svg>
	);
}

export default MicOff;
