export const SettingsCategories = {
	COMPANY_DETAILS: 'companyDetails',
	COMPANY_SETTINGS: 'companySettings',
	MONITORING_SETTINGS: 'monitoringSettings',
	ROUNDING_SETTINGS: 'roundingSettings',
	GENERAL_AND_MENU_SETTINGS: 'generalAndMenuSettings',
	INTEGRATION_TYPES_SETTINGS: 'integrationTypesSettings',
	ROOM_TYPES_SETTINGS: 'roomTypesSettings',
	ROOM_MODES_SETTINGS: 'roomModesSettings',
	TELEMEDICINE_MODES_SETTINGS: 'telemedicineModesSettings',
	HELLOCARE_ENGAGEMENT_SETTINGS: 'helloCareEngagementSettings',
	WHITEBOARD_SETTINGS: 'whiteboardSettings',
	AI_SETTINGS: 'aiSettings',
	PATIENTS: 'patients',
	USER_EXPERIENCE: 'userExperience',
	DASHBOARD_SETTINGS: 'dashboardSettings',
	VOICE_COMMANDS_SETTINGS: 'voiceCommandsSettings',
	CALL_SETTINGS: 'callSettings',
	COMPANY_INVITES: 'companyInvite',
};
