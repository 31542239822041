import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import translate from 'i18n-translations/translate.jsx';
import { getVisits } from 'api/medicalInfo.js';
import { getUserId, getUserInfo, getUserRole } from 'infrastructure/auth.js';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import { formattedDate, monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import Alert from 'components/Alert.jsx';
import EmptyState from 'components/EmptyState.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import { genderItems } from 'constants/genderItems.js';
import { MedicalInfoTypes } from 'constants/enums.js';
import { savePdf, stringToCamelCase, getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import { getImageToBase64 } from 'api/doctors.js';
import Button from 'components/Button.jsx';

const PrintVisitsReport = props => {
	const [visits, setVisits] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [selectedVisit, setSelectedVisit] = useState(null);
	const reportRef = useRef(null);
	const [currentSignature, setCurrentSignature] = useState('');
	const [stamp, setStamp] = useState('');
	const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', genderId: 0, dateOfBirth: '' });
	const intl = useIntl();

	useEffect(() => {
		const fetchUserInfo = () => {
			const { firstName, lastName, genderId, dateOfBirth } = getUserInfo();
			setUserInfo(
				props.selectedPatient
					? {
							firstName: props.selectedPatient.firstName,
							lastName: props.selectedPatient.lastName,
							genderId: props.selectedPatient?.genderId,
							dateOfBirth: props.selectedPatient?.dateOfBirth,
					  }
					: { firstName, lastName, genderId, dateOfBirth }
			);
		};
		fetchUserInfo();
	}, [props.selectedPatient]);

	useEffect(() => {
		const getAllVisits = async () => {
			setIsLoading(true);
			const params = {
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
				userId: getUserId(),
				patientId: props.selectedPatient?.id || getUserId(),
				role: getUserRole(),
			};
			const response = await getVisits(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setVisits(response.medicalVisits);
				setPagination(prevState => ({ ...prevState, totalCount: response.totalCount }));
			}
			setIsLoading(false);
		};
		getAllVisits();
	}, [pagination.pageSize, pagination.pageIndex, props.selectedPatient]);

	useEffect(() => {
		if (selectedVisit) {
			const fileName = `${intl.formatMessage({ id: 'medicalReport' })}-${userInfo.firstName}-${
				userInfo.lastName
			}-${getDateForFileName(selectedVisit.startedAt)}.pdf`;
			const fileType = 'jpeg';
			const configurations = { useCORS: true, allowTaint: true, scrollY: 0 };
			savePdf(reportRef, fileName, fileType, configurations);
			setSelectedVisit(null);
		}
	}, [selectedVisit, intl, userInfo]);

	const medicalItem = (prescription, type, optionalText) => (
		<div>
			<p style={{ color: 'var(--gray-5)' }}>{translate(type)}</p>
			<p>
				{prescription[type]} {optionalText && <span>{optionalText}</span>}
			</p>
		</div>
	);

	const getDateForFileName = date => {
		const currentDate = moment.utc(date).local().locale(getPreferredLanguageLocale());
		return moment.utc(currentDate).local().locale(getPreferredLanguageLocale()).format('DD-MM-YYYY-HH-mm');
	};

	const selectVisitReport = async visit => {
		let blobSignature = {};
		let blobStamp = {};
		if (visit.performer?.signaturePicture) {
			blobSignature = await getImageToBase64(visit.performer?.signaturePicture);
			if (blobSignature && !blobSignature.error) {
				const blobType = visit.performer?.signaturePicture.split('.')[1];
				setCurrentSignature(`data:image/${blobType};base64, ${blobSignature.content}`);
			}
		}
		if (visit.performer?.stampPicture) {
			blobStamp = await getImageToBase64(visit.performer?.stampPicture);
			if (blobStamp && !blobStamp.error) {
				const stampType = visit.performer?.stampPicture.split('.')[1];
				setStamp(`data:image/${stampType};base64, ${blobStamp.content}`);
			}
		}
		setSelectedVisit(visit);
	};

	return (
		<>
			{!isLoading && (
				<>
					{visits.length > 0 && (
						<div className='patient-summary-wrapper visits-wrapper'>
							{!props.hideTitle && <h3 className='text-align-left'>{translate('printReports')}</h3>}

							<div className='patient-summary-table-wrapper'>
								<table cellPadding='0' cellSpacing='0' className={props.tableClassName}>
									<thead>
										<tr>
											<th>{translate('patient')}</th>
											<th>{translate('doctor')}</th>
											<th>{translate('dateAndTime')}</th>
											<th>{translate('lengthOfVisit')}</th>
											<th>{translate('actions')}</th>
										</tr>
									</thead>
									<tbody>
										{visits.map(visit => (
											<tr key={visit.id}>
												<td>
													{visit.patient?.firstName} {visit.patient?.lastName}
												</td>
												<td>
													{visit.performer?.firstName} {visit.performer?.lastName}
												</td>
												<td>{visit.startedAt ? formattedDate(visit.startedAt) : 'N/A'}</td>
												<td>{visit.duration || 'N/A'}</td>
												<td>
													<Button
														className='generate-pdf'
														onClick={() => selectVisitReport(visit)}
														color='primary'
														text={translate('printReport')}
													/>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<Pagination
								totalCount={pagination.totalCount}
								pageSize={pagination.pageSize}
								pageIndex={pagination.pageIndex}
								onChange={(pageSize, pageIndex) => setPagination(prevState => ({ ...prevState, pageSize, pageIndex }))}
							/>
							<Alert display={error} fixed={true} hideCloseButton={true} message={error} letiant='dark' />
						</div>
					)}
					{visits.length === 0 && (
						<div className='empty-state-wrapper visits-wrapper'>
							<EmptyState title={translate('noVisitsFound')} image='Visits.svg' />
						</div>
					)}
				</>
			)}
			{isLoading && (
				<Grid rows='auto' width='100%' stretch='100vh' vertAlign='center' horizAlign='center'>
					<Loader />
				</Grid>
			)}
			<div
				id='albanian-doctor-report'
				ref={reportRef}
				style={{ position: 'fixed', width: '8.5in', minHeight: '11in', zIndex: '-1' }}>
				{selectedVisit && (
					<>
						<div>
							<div className='logo'>
								<h3>{translate('medicalReport')}</h3>
							</div>
							<div className='main-info flex'>
								<div>
									<p>{translate('patient')}</p>
									<p>
										{userInfo.firstName} {userInfo.lastName}
									</p>
								</div>
								<div>
									<p>{translate('dateOfBirth')}</p>
									<p>{monthDayYear(userInfo.dateOfBirth)}</p>
								</div>
								<div>
									<p>{translate('sex')}</p>
									<p>{genderItems.find(x => x.genderId === userInfo.genderId?.toString())?.text}</p>
								</div>
								<div>
									<p>{translate('doctorFullName')}</p>
									<p>
										{selectedVisit.performer.firstName} {selectedVisit.performer.lastName}
									</p>
								</div>
								{selectedVisit?.performer?.specialty?.name && (
									<div>
										<p>{translate('specialty')}</p>
										<p>{intl.formatMessage({ id: stringToCamelCase(selectedVisit.performer.specialty.name) })}</p>
									</div>
								)}
							</div>
							<div className='main-diagnoses'>
								<div>
									<p>{translate('diagnoses')}</p>
								</div>
								<div className='flex flex-wrap'>
									{selectedVisit.diagnoses &&
										selectedVisit.diagnoses.map(diagnose => (
											<div key={diagnose.id}>
												<p>{diagnose.description}</p>
												<p>{diagnose.code}</p>
											</div>
										))}
									{!selectedVisit.diagnoses ||
										(selectedVisit.diagnoses.length === 0 && (
											<div style={{ background: 'none', padding: '0', borderRadius: '0' }}>
												{translate('noAdded', {
													value: intl.formatMessage({ id: MedicalInfoTypes.DIAGNOSES }).toLowerCase(),
												})}
											</div>
										))}
								</div>
							</div>
							<div className='main-prescriptions'>
								<div>
									<p>{translate('prescriptions')}</p>
								</div>
								<div className='flex flex-wrap'>
									{selectedVisit.prescriptions &&
										selectedVisit.prescriptions.map(prescription => (
											<div key={prescription.id} className='simple-txt-modal-inner prescriptions-modal'>
												<div>
													<h4>{`${prescription.medicine.name} ${prescription.medicine.strengthAndForm}`}</h4>
													<div className='flex'>
														<p>
															{parseInt(prescription.dosage, 10) > 1 &&
																translate('capsulesDosage', {
																	value: prescription.dosage,
																})}
															{parseInt(prescription.dosage, 10) === 1 &&
																translate('oneCapsule', {
																	value: prescription.dosage,
																})}
														</p>
														<p>
															{prescription.frequency} {translate('timesAday')},
														</p>
														<p>
															{translate('for')} {prescription.duration} {translate('daysNo')},
														</p>
														<p>
															{prescription.refills} {translate('refillsLowercase')}
														</p>
													</div>

													<div className='flex'>{medicalItem(prescription, 'patientInstructions')}</div>
													<div className='flex'>{medicalItem(prescription, 'noteToPharmacist')}</div>
												</div>
											</div>
										))}
									{!selectedVisit.prescriptions ||
										(selectedVisit.prescriptions.length === 0 && (
											<div style={{ background: 'none', padding: '0', borderRadius: '0' }}>
												{translate('noAdded', {
													value: intl.formatMessage({ id: MedicalInfoTypes.PRESCRIPTIONS }).toLowerCase(),
												})}
											</div>
										))}
								</div>
							</div>
							<div className='main-prescriptions'>
								<div>
									<p>{translate('procedures')}</p>
								</div>
								<div className='flex flex-wrap'>
									{selectedVisit.procedures &&
										selectedVisit.procedures.map(procedure => (
											<div key={procedure.id} className='simple-txt-modal-inner prescriptions-modal'>
												<div>
													<h4>{procedure.code}</h4>
													<p>{procedure.description}</p>
												</div>
											</div>
										))}
									{!selectedVisit.procedures ||
										(selectedVisit.procedures.length === 0 && (
											<div style={{ background: 'none', padding: '0', borderRadius: '0' }}>
												{translate('noAdded', {
													value: intl.formatMessage({ id: MedicalInfoTypes.PROCEDURES }).toLowerCase(),
												})}
											</div>
										))}
								</div>
							</div>
							<div className='main-notes'>
								<div>
									<p>{translate('notes')}</p>
								</div>
								{selectedVisit.notes &&
									selectedVisit.notes.map(item => (
										<div key={item.id}>
											<p>{item.title}</p>
											<p>{item.text}</p>
										</div>
									))}
								{!selectedVisit.notes ||
									(selectedVisit.notes.length === 0 && (
										<div>
											{translate('noAdded', {
												value: intl.formatMessage({ id: MedicalInfoTypes.NOTES }).toLowerCase(),
											})}
										</div>
									))}
							</div>
						</div>
						<div className='doctor-signature'>
							<p>{translate('doctorsStampSignature')}</p>
							<div className='flex'>
								{currentSignature && <img alt='signature' src={currentSignature} />}
								{stamp && <img alt='stamp' src={stamp} />}
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default PrintVisitsReport;
