import { localApi } from 'constants/global-variables.js';

export const getStates = async () => {
	try {
		const { data } = await localApi.get(`/api/users/sign-up/states`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getSpecialties = async () => {
	try {
		const { data } = await localApi.get(`/api/users/sign-up/specialties`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const signUpDoctor = async obj => {
	try {
		const url = `/api/users/doctor-signup`;
		const response = await localApi.post(url, obj);
		return response;
	} catch (error) {
		return { error };
	}
};

export const uploadOnboardingProfilePicture = async obj => {
	try {
		const url = `/api/media/profile-picture`;
		const { data } = await localApi.post(url, obj);
		return data;
	} catch (error) {
		return { error };
	}
};
