import Button from 'components/Button.jsx';
import React from 'react';

const DemoRequest = props => (
	<div className='demo-request lp-container'>
		<h2>Care to see a live demo?</h2> 
		<Button onClick={() => props.setIsMessageModalVisible(true)} text='Request a demo' />
	</div>
);

export default DemoRequest;
