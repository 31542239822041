export const AssignmentStatus = {
	NEW: 'new',
	RESOURCE_ASSIGNED: 'resource-assigned',
	IN_PROGRESS: 'in-progress',
	COMPLETED: 'completed',
	CANCELED: 'canceled',
};

export const genderOptionCodeIds = {
	MALE_PREFERENCE: 1,
	FEMALE_PREFERENCE: 2,
	MALE_ONLY: 3,
	FEMALE_ONLY: 4,
	NO_PREFERENCE: 5,
};

export const specialtyOptionCodeIds = {
	GENERAL: 1,
	MEDICAL: 2,
	LEGAL: 3,
};

export const requestStatusIds = {
	NO_INITIATED: { id: 0, description: 'Request is not initiated', type: 'NO_INITIATED' },
	NEW: { id: 1, description: 'Request has been sent', type: 'NEW' },
	IN_SERVICE: { id: 2, description: 'Interpreter in service', type: 'IN_SERVICE' },
	SERVICED: { id: 3, description: 'Interpreter serviced', type: 'SERVICED' },
	CANCELED: { id: 4, description: 'Request has been canceled', type: 'CANCELED' },
	INTERPRETER_ACCEPTED: { id: 5, description: 'Interpreter has accepted your request', type: 'INTERPRETER_ACCEPTED' },
	NO_INTERPRETER_AVILABLE: { id: 9, description: 'No Interpreter is available', type: 'NO_INTERPRETER_AVILABLE' },
};
