import { MeasurementUnits, MeasurementsMinMax } from 'constants/enums.js';
import { createArrayWithDefaultValues } from 'infrastructure/helpers/commonHelpers.js';
import React from 'react';

const BloodGlucoseSummaryChart = props => {
	let maxGlucoseValue = props.maxValue;
	if (!props.maxValue) {
		maxGlucoseValue =
			props.measurementUnit === MeasurementUnits.MMOLL ? MeasurementsMinMax.MMOL.MAX : MeasurementsMinMax.MGDL.MAX;
	}
	const maxChartValue = 12;
	let glucoseValue =
		props.measurementValue && props.measurementValue !== 'No Data'
			? Math.round((props.measurementValue / maxGlucoseValue) * maxChartValue)
			: 0;

	if (props.measurementValue > maxGlucoseValue) {
		glucoseValue = maxChartValue;
	}

	let bloodGlucoseProgress;
	if (glucoseValue > 0) {
		bloodGlucoseProgress = createArrayWithDefaultValues(glucoseValue).map((item, index) => {
			let data;
			if (index + 1 === glucoseValue) {
				data = createArrayWithDefaultValues(maxChartValue - glucoseValue).map(key => (
					<div key={key} style={{ background: 'var(--gray-2)' }} />
				));
			}

			data = (
				<React.Fragment key={item}>
					<div style={{ background: 'var(--blue-2)' }} />
					{data}
				</React.Fragment>
			);

			return data;
		});
	} else {
		bloodGlucoseProgress = createArrayWithDefaultValues(maxChartValue - glucoseValue).map(key => (
			<div key={key} style={{ background: 'var(--gray-2)' }} />
		));
	}

	return (
		<>
			<div className='flex summary-chart-data sleep-chart-data'>
				<h1>
					{props.measurementValue}
					{props.measurementValue !== 'No Data' && <span>{props.measurementUnit}</span>}
				</h1>
			</div>
			<div className='blood-glucose-summary-chart'>
				<div className='blood-glucose-summary-base'>{bloodGlucoseProgress}</div>
			</div>
		</>
	);
};

export default BloodGlucoseSummaryChart;
