import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/LandingPage/GeneralPages/Layout.jsx';
import PartnersLogos from 'components/LandingPage/PartnersLogos.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import { helloTwoImageUrl, healthCareCdnUrl } from 'constants/global-variables.js';

const HelloLandingPage = () => {
	const [currentTab, setCurrentTab] = useState(0);

	const sliderTabs = [
		{
			id: 0,
			title: 'Front',
			imgSrc: `${helloTwoImageUrl}front-specs.png`,
		},
		{
			id: 1,
			title: 'Camera & Lens',
			imgSrc: `${helloTwoImageUrl}eye-specs.png`,
		},
		{
			id: 2,
			title: 'Back',
			description: `Select one of the doctors from the list generated based on your signs and symptoms.`,
			imgSrc: `${helloTwoImageUrl}back-specs.png`,
		},
	];

	const sliderContent = [
		{
			id: 0,
			title: 'Front',
			imgSrc: `${helloTwoImageUrl}front-image.png`,
		},
		{
			id: 1,
			title: 'Camera & Lens',
			imgSrc: `${helloTwoImageUrl}eye-image-min.png`,
		},
		{
			id: 2,
			title: 'Back',
			imgSrc: `${helloTwoImageUrl}back-image-min.png`,
		},
	];

	const firstLogos = [
		{
			id: 0,
			src: 'align-dark.png',
			alt: 'ALign',
		},
		{
			id: 2,
			src: 'hitrust-dark.png',
			alt: 'HiTrust',
		},
		{
			id: 3,
			src: 'hipaa-dark.png',
			alt: 'Hipaa',
		},
		{
			id: 4,
			src: '21972-312_SOC_NonCPA-dark.png',
			alt: 'AICPA | SOC',
			customSize: true,
		},
		{
			id: 5,
			src: 'fc-dark.png',
			alt: 'FC',
		},
		{
			id: 6,
			src: 'ic-dark.png',
			alt: 'IC',
		},
		{
			id: 7,
			src: 'ce-dark.png',
			alt: 'CE',
		},
	];

	return (
		<>
			<Helmet>
				<meta property='og:image' content={`${healthCareCdnUrl}meta/preview-images_hellocarehello_all.jpg?v2`} />
				<meta name='twitter:image' content={`${healthCareCdnUrl}meta/preview-images_hellocarehello_twitter.jpg?v2`} />
				<meta
					name='og:url'
					content={
						window.location.hostname === 'healthcare.solaborate.com'
							? 'https://healthcare.solaborate.com/hello'
							: 'https://www.hellocare.ai/hello'
					}
				/>
			</Helmet>
			<Layout className='landing-page' isSubPage={true}>
				<div className='hello-page-wrapper'>
					<div>
						<div>
							<h1>The World’s Most Powerful End-to-End Encrypted Communication Device</h1>
							<p>Say HELLO to the future</p>
							<video autoPlay loop muted playsInline>
								<source src={`${helloTwoImageUrl}helloDeviceLooped.mp4`} type='video/mp4' />
								Your browser does not support the video tag.
							</video>
						</div>
						<PartnersLogos logos={firstLogos} showCarousel={true} />
					</div>
					<div>
						<h1>Built-in Hack-Proof Video and Audio Controls for Ultimate Privacy</h1>
						<p>Hardware disconnect via separate buttons</p>
						<video autoPlay loop muted playsInline>
							<source src={`${helloTwoImageUrl}Hello2Button.mp4`} type='video/mp4' />
							Your browser does not support the video tag.
						</video>
					</div>
					<Tabs activeIndex={currentTab} onChange={index => setCurrentTab(index)} className='technical-specifications'>
						<h1>Technical Specifications</h1>

						<TabList>
							{sliderTabs.map(item => (
								<Tab key={item.id}>
									<div>
										<img src={item.imgSrc} alt='icon' />
										<p>{item.title}</p>
									</div>
								</Tab>
							))}
						</TabList>
						<div className='animated-underline-wrapper'>
							<div className='animated-underline' style={{ width: `${(currentTab + 1) * 33.3333333333}%` }} />
						</div>

						<TabPanels>
							{sliderContent.map(item => (
								<TabPanel key={item.id}>
									<div className='lp-container'>
										<img src={item.imgSrc} alt='main-img' />
									</div>
								</TabPanel>
							))}
						</TabPanels>
					</Tabs>
				</div>
			</Layout>
		</>
	);
};

export default HelloLandingPage;
