function Screenshare(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' strokeWidth={1.5} {...props}>
			<rect x={1.199} y={3.6} width={21.6} height={14.954} rx={2} stroke='currentColor' />
			<path d='M8.676 21h7.477' stroke='currentColor' strokeLinecap='round' />
			<path d='M13.66 9l1.662 1.662-1.662 1.661' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
			<path
				d='M8.818 13.274a.75.75 0 001.38.59l-1.38-.59zm6.09-3.362h-2.176v1.5h2.176v-1.5zm-5.623 2.272l-.467 1.09 1.38.59.466-1.089-1.379-.59zm3.447-2.272a3.75 3.75 0 00-3.447 2.272l1.38.591a2.25 2.25 0 012.067-1.363v-1.5z'
				fill='currentColor'
			/>
		</svg>
	);
}

export default Screenshare;
