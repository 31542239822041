import * as React from 'react';

const VideoMonitoring = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={21} height={20} fill='none' {...props}>
		<rect width={17.667} height={11.833} x={1.666} y={2.834} stroke='currentColor' strokeMiterlimit={10} rx={2.5} />
		<path
			stroke='currentColor'
			strokeLinecap='round'
			d='M3.694 14.167v-1.352c0-1.866 1.586-3.38 3.542-3.38 1.956 0 3.541 1.514 3.541 3.38v1.352M9.598 12.688v1.478M4.875 12.688v1.478M5.464 6.774c0 .98.793 1.774 1.771 1.774.978 0 1.77-.794 1.77-1.774S8.214 5 7.236 5s-1.77.794-1.77 1.774Z'
		/>
		<path fill='currentColor' d='M13.889 6.666a.5.5 0 1 1 1 0h-1Zm1 0v7.5h-1v-7.5h1Z' />
		<path
			stroke='currentColor'
			strokeLinecap='round'
			d='M14.389 7.777h1.777a1 1 0 0 0 1-1v-.11M14.389 8.889H12.61a1 1 0 0 1-1-1v-.111'
		/>
		<path
			stroke='currentColor'
			d='M12.445 8.889v1.11M13 10.556a.556.556 0 1 0-1.111 0v.555a.556.556 0 1 0 1.111 0v-.555ZM16.334 7.777v1.111M16.889 9.444a.556.556 0 1 0-1.111 0V10a.556.556 0 0 0 1.111 0v-.556Z'
		/>
		<path stroke='currentColor' strokeLinecap='round' d='M13 15.834H8' />
	</svg>
);
export default VideoMonitoring;
