import * as React from 'react';

const Priority = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} fill='none' {...props}>
		<g stroke='currentColor' strokeLinecap='round' strokeWidth={1.5}>
			<g strokeLinejoin='round'>
				<path d='m7 11 4-4 4 4M11 15V7' />
			</g>
			<path d='M9.586 1.414a2 2 0 0 1 2.828 0l8.172 8.172a2 2 0 0 1 0 2.828l-8.172 8.172a2 2 0 0 1-2.828 0l-8.172-8.172a2 2 0 0 1 0-2.828l8.172-8.172Z' />
		</g>
	</svg>
);
export default Priority;
