import PropTypes from 'prop-types';

const DottedCircle = ({
	isSemiCircle = false,
	svgCoordinates = {
		x1: 0,
		x2: 0,
		y1: 0,
		y2: 0,
	},
	...props
}) => {
	const rotationValue = isSemiCircle ? 180 / props.numberOfLines : 360 / props.numberOfLines;

	return (
		<svg
			viewBox={`0 0 500 ${isSemiCircle ? '250' : '500'}`}
			xmlns='http://www.w3.org/2000/svg'
			className={props.isSemiCircle ? 'semi-dotted-circle-graph' : 'dotted-circle-graph'}>
			{[...Array(Math.round(props.numberOfLines)).keys()].map(item => (
				<line
					key={item}
					className={Math.round(props.value) > item ? 'active' : ''}
					x1={svgCoordinates.x1 || (isSemiCircle ? 15 : 250)}
					x2={svgCoordinates.x2 || (isSemiCircle ? 35 : 250)}
					y1={svgCoordinates.y1 || (isSemiCircle ? 240 : 15)}
					y2={svgCoordinates.y2 || (isSemiCircle ? 240 : 35)}
					style={{
						transform: isSemiCircle
							? `rotate(${((180 + rotationValue) / Math.round(props.numberOfLines)) * item}deg)`
							: `rotate(${rotationValue * item}deg)`,
						transformOrigin: isSemiCircle ? `50% ${((svgCoordinates.y2 || 240) / 250) * 100}%` : '50% 50%',
					}}
				/>
			))}
		</svg>
	);
};

DottedCircle.propTypes = {
	numberOfLines: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
	isSemiCircle: PropTypes.bool,
	svgCoordinates: PropTypes.shape({
		x1: PropTypes.number,
		x2: PropTypes.number,
		y1: PropTypes.number,
		y2: PropTypes.number,
	}),
};

export default DottedCircle;
