import * as React from 'react';

const Discharge = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
		<path
			stroke='currentColor'
			d='M15 5h2a1 1 0 0 1 1 1v4m2 11h-1a1 1 0 0 1-1-1v-4M5 21V5.802a1 1 0 0 1 .783-.976l7-1.556A1 1 0 0 1 14 4.247V20a1 1 0 0 1-1 1H5Zm0 0H3'
		/>
		<circle cx={11} cy={13} r={1} fill='#343434' />
		<path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' d='m21 15 2-2-2-2' />
		<path stroke='currentColor' strokeLinecap='round' d='M23 13h-6' />
	</svg>
);
export default Discharge;
