import {
	getRoomsBySectorId,
	getRoomSettings,
	getTeamSettings,
	updateRoomSettings,
	updateTeamSettings,
} from 'api/adminConfigurations.js';
import classNames from 'classnames';
import AiConfigurationFeatureFlags from 'components/AiConfigurationFeatureFlags.jsx';
import Button from 'components/Button.jsx';
import { EmptyState, Modal, PopUpAlert } from 'components/index.js';
import SkeletonLoader from 'components/SkeletonLoader.jsx';
import {
	AiSetting,
	AiSettingFeaturesCategory,
	ConfigSettingType,
	configurableWorkflowTypes,
	getConfigurationWithDiffTypeKey,
	INDEPENDENT_FEATURE_TYPE,
} from 'constants/configurationEnums.js';
import { AlertTypes, DeviceListLevel } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { getSectorAiConfigs, prepareAiConfigsForSubmit, validateAiConfigurations } from 'infrastructure/helpers/aiHelper.js';
import { reorderObjects } from 'infrastructure/helpers/commonHelpers.js';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';

const AiFeatureFlags = props => {
	const disabledToggleTimeouts = useRef({});
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const companySettings = useSelector(state => state.company.companySettings);
	const [roomsToBeOverwritten, setRoomsToBeOverwritten] = useState([]);
	const [selectedConfig, setSelectedConfig] = useState(null);
	const [adminConfigurations, setAdminConfigurations] = useState({});
	const [isLoading, setLoading] = useState(true);
	const [isEmptyState, setIsEmptyState] = useState(false);
	const [expandedRows, setExpandedRows] = useState([]);
	const [selectedAiSettings, setSelectedAiSettings] = useState(null);
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
	const [aiErrors, setAiErrors] = useState([]);
	const [alertType, setAlertType] = useState(null);
	const [enabledAiFeatureFlags, setEnabledAiFeatureFlags] = useState([]);
	const intl = useIntl();
	const [selectedCallWorkflow, setSelectedCallWorkflow] = useState({
		value: configurableWorkflowTypes()[0].value,
		label: intl.formatMessage({ id: configurableWorkflowTypes()[0].label }),
	});

	useEffect(() => {
		setLoading(true);
		setAiErrors([]);
		const fetchSectorSettings = async () => {
			const response =
				props.currentSector !== DeviceListLevel.ROOM
					? await getTeamSettings({
							teamId: props.levelId,
							levelId: props.currentSector,
							settingsCategory: [props.settingCategory],
						})
					: await getRoomSettings(props.levelId, props.settingCategory);

			if (response.error) {
				props.setError(response.error.message);
			} else {
				const featureFlagConfigs = {};
				response.settings.forEach(item => {
					const config = [AiSetting.ALERTS_EVIDENCE_ADMIN, AiSetting.ALERTS_EVIDENCE_NURSE].includes(item.settingTypeId)
						? props.defaultConfigurations[item.settingTypeId]
						: props.defaultConfigurations[getConfigurationWithDiffTypeKey(item.settingTypeId, item.callWorkflowTypeId)];

					if (config && item.callWorkflowTypeId === selectedCallWorkflow.value) {
						config.value = JSON.parse(item.value);
						config.isLocked = item.isLocked;

						if (item?.aiConfigurations) {
							config.aiConfigurations = getSectorAiConfigs(item.aiConfigurations, intl);
						}
						featureFlagConfigs[item.settingTypeId] = config;
					}

					if (config && (!item.callWorkflowTypeId || item.callWorkflowTypeId === INDEPENDENT_FEATURE_TYPE)) {
						config.value = JSON.parse(item.value);
						config.isLocked = item.isLocked;
						featureFlagConfigs[item.settingTypeId] = config;
					}
				});

				setAdminConfigurations(featureFlagConfigs);
				setIsEmptyState(
					Object.keys(featureFlagConfigs)?.filter(
						el => ![AiSetting.ALERTS_EVIDENCE_ADMIN, AiSetting.ALERTS_EVIDENCE_NURSE].includes(+el)
					)?.length === 0
				);
			}
			setLoading(false);
		};

		fetchSectorSettings();

		return () => {
			Object.values(disabledToggleTimeouts.current).forEach(timeout => {
				clearTimeout(timeout);
			});
		};
	}, [props.levelId, props.settingCategory, selectedCallWorkflow]);

	useEffect(() => {
		const enabledAi = Object.values(adminConfigurations).reduce((acc, item) => {
			if (item.category === AiSettingFeaturesCategory.AI_SETTINGS && item.value) {
				acc.push(item);
			}
			return acc;
		}, []);
		setEnabledAiFeatureFlags(enabledAi);
	}, [adminConfigurations]);

	const updateConfigState = (key, settingType) => {
		setAdminConfigurations(prevState => {
			const configsCopied = _.cloneDeep(prevState);
			configsCopied[key].value = settingType === ConfigSettingType.TOGGLE ? !configsCopied[key].value : configsCopied[key].value;
			configsCopied[key].isLocked =
				settingType === ConfigSettingType.LOCK ? !configsCopied[key].isLocked : configsCopied[key].isLocked;

			return configsCopied;
		});
	};

	const updateRoomConfiguration = async (setting, settingType) => {
		const response = await updateRoomSettings(props.levelId, [setting], true);
		const key = setting.settingTypeId;
		if (response.error) {
			props.setError(response.error.message);
			setAlertType(settingType === ConfigSettingType.SELECT && AlertTypes.DANGER);
		} else {
			updateConfigState(key, settingType);
			setAlertType(settingType === ConfigSettingType.SELECT && AlertTypes.SUCCESS);
		}
	};

	const updateTeamsConfigurations = async ({ isOverride, settingType, key, selectedItem = null }) => {
		const item = adminConfigurations[key];
		const setting = {
			teamSettings: [
				{
					settingTypeId: key,
					value: settingType === ConfigSettingType.TOGGLE ? (!item.value).toString() : item.value.toString(),
					isLocked: settingType === ConfigSettingType.LOCK ? !item.isLocked : item.isLocked,
					...(item.callWorkflowTypeId && item.callWorkflowTypeId !== INDEPENDENT_FEATURE_TYPE
						? { callWorkflowTypeId: selectedCallWorkflow?.value }
						: {}),
					...(item.aiConfigurationTypeId &&
						selectedItem && {
							aiConfigurations: prepareAiConfigsForSubmit(item.aiConfigurations),
						}),
				},
			],
			teamId: props.levelId,
			isOverride,
			healthSystemId: props.healthSystemId,
			isAi: true,
		};
		const response = await updateTeamSettings(setting);
		if (response.error) {
			props.setError(response.error.message);
			setAlertType(settingType === ConfigSettingType.SELECT && AlertTypes.DANGER);
		} else {
			updateConfigState(key, settingType);
			setAlertType(settingType === ConfigSettingType.SELECT && AlertTypes.SUCCESS);
		}
		setSelectedConfig(null);
	};

	const toggleHealthSystemTeamItem = (settingType, key) => {
		updateTeamsConfigurations({ isOverride: true, settingType, key });
	};

	const toggleTeamsConfigsItem = async (settingType, key, selectedItem = null) => {
		const response = await getRoomsBySectorId(props.levelId, key, adminConfigurations[key].value.toString());
		if (response.error) {
			props.setError(response.error.message);
			return;
		}
		if (response.rooms.length > 0) {
			setRoomsToBeOverwritten(response.rooms);
			setSelectedAiSettings(selectedItem);
			setSelectedConfig(key);
		} else {
			updateTeamsConfigurations({ isOverride: true, settingType, key, selectedItem });
		}
	};

	const toggleRoomConfigsItem = (settingType, key, selectedItem = null) => {
		const item = adminConfigurations[key];
		const value = settingType === ConfigSettingType.TOGGLE ? (!item.value).toString() : item.value.toString();
		const setting = {
			settingTypeId: key,
			value,
			isLocked: item.isLocked,
			...(item.variant ? { variant: item.variant.value } : {}),
			...(item.callWorkflowTypeId && item.callWorkflowTypeId !== INDEPENDENT_FEATURE_TYPE
				? { callWorkflowTypeId: selectedCallWorkflow?.value }
				: {}),
			...(item.aiConfigurationTypeId &&
				selectedItem && {
					aiConfigurations: prepareAiConfigsForSubmit(item.aiConfigurations),
				}),
		};
		updateRoomConfiguration(setting, settingType);
	};

	const toggleItem = key => {
		const settingType = ConfigSettingType.TOGGLE;

		clearTimeout(disabledToggleTimeouts.current[key]);

		setAdminConfigurations(prevState => {
			const updatedConfig = {
				...prevState[key],
				isDisabled: true,
			};

			return {
				...prevState,
				[key]: updatedConfig,
			};
		});

		disabledToggleTimeouts.current[key] = setTimeout(() => {
			setAdminConfigurations(prevState => {
				const updatedConfig = {
					...prevState[key],
					isDisabled: false,
				};

				return {
					...prevState,
					[key]: updatedConfig,
				};
			});
		}, 1000);
		if (props.currentSector === DeviceListLevel.HEALTH_SYSTEM) {
			toggleHealthSystemTeamItem(settingType, key);
			return;
		}
		if (props.currentSector !== DeviceListLevel.ROOM) {
			toggleTeamsConfigsItem(settingType, key);
		} else {
			toggleRoomConfigsItem(settingType, key);
		}
	};

	const toggleLock = async key => {
		const item = adminConfigurations[key];
		const setting = {
			settingTypeId: key,
			value: item.value.toString(),
			isLocked: !item.isLocked,
			...(item.callWorkflowTypeId &&
				item.callWorkflowTypeId !== INDEPENDENT_FEATURE_TYPE && { callWorkflowTypeId: selectedCallWorkflow?.value }),
		};

		if (props.currentSector === DeviceListLevel.ROOM) {
			updateRoomConfiguration(setting, ConfigSettingType.LOCK);
		} else {
			updateTeamsConfigurations({ isOverride: true, settingType: ConfigSettingType.LOCK, key });
		}
	};

	const isLockEditable = () =>
		[DeviceListLevel.ROOM, DeviceListLevel.FLOOR, DeviceListLevel.DEPARTMENT, DeviceListLevel.HOSPITAL].includes(
			props.currentSector
		);

	const groupedCategories = Object.entries(adminConfigurations).reduce((acc, [key, item]) => {
		const { category } = item;
		if (!acc[category]) {
			acc[category] = [];
		}
		acc[category].push([key, item]);
		return acc;
	}, []);

	const featureFlagsCategories = props.categoryOrder ? reorderObjects(groupedCategories, props.categoryOrder) : groupedCategories;

	const generalSettingFeatureFlags = featureFlagsCategories.generalSettings
		? Object.fromEntries(featureFlagsCategories.generalSettings)
		: [];

	const handleAiConfigsChange = ({ key, aiConfigs }) => {
		if (!key || !aiConfigs) {
			return;
		}
		setAdminConfigurations(prevState => {
			const configsCopied = _.cloneDeep(prevState);
			configsCopied[key].aiConfigurations = aiConfigs;
			return configsCopied;
		});
	};

	const handleSaveAiConfigurations = async (key, item) => {
		setAiErrors([]);
		const settingType = ConfigSettingType.SELECT;
		const aiConfigErrors = validateAiConfigurations(item, intl);
		if (Object.keys(aiConfigErrors.errors).length > 0) {
			setAiErrors([aiConfigErrors]);
			return;
		}
		setIsSubmitButtonLoading(true);
		if (props.currentSector === DeviceListLevel.HEALTH_SYSTEM) {
			await updateTeamsConfigurations({
				isOverride: true,
				settingType,
				key,
				selectedItem: item,
			});
			setIsSubmitButtonLoading(false);
			return;
		}
		if (props.currentSector !== DeviceListLevel.ROOM) {
			await toggleTeamsConfigsItem(settingType, key, item);
		} else {
			await toggleRoomConfigsItem(settingType, key, item);
		}
		setIsSubmitButtonLoading(false);
	};

	const viewAiConfigurationDetails = rowIndex => {
		setExpandedRows(prevExpandedRows => {
			if (prevExpandedRows.includes(rowIndex)) {
				return prevExpandedRows.filter(row => row !== rowIndex);
			} else {
				return [...prevExpandedRows, rowIndex];
			}
		});
	};

	const isSilentModeDisabled = (key, value) => enabledAiFeatureFlags.length === 0 && +key === AiSetting.SILENT_MODE && !value;

	const areAiFeatureFlagsDisabled = (category, value) => category === AiSettingFeaturesCategory.AI_SETTINGS && value;

	const transformArray = array => array.map(item => ({ value: item.value, label: intl.formatMessage({ id: item.label }) }));

	return (
		<>
			<div className='feature-flags'>
				<div className='feature-flags-header'>
					{props.currentSector === DeviceListLevel.HEALTH_SYSTEM && (
						<div>
							<h4>{translate('selectHealthSystem')}</h4>
							<Select
								value={props.selectedHealthSystem}
								classNamePrefix='react-select'
								options={allHealthSystems.map(item => ({ value: item.id, label: item.name || item.value }))}
								onChange={props.setSelectedHealthSystem}
							/>
						</div>
					)}
				</div>
				{Object.keys(featureFlagsCategories)
					.filter(category => category === AiSettingFeaturesCategory.EVIDENCE_CONFIGURATIONS)
					.map(category => {
						const categoryItems = groupedCategories[category];
						return (
							<div className='feature-flags-category' key={category}>
								<h4>{translate(category)}</h4>
								{categoryItems?.map(([key, item]) => (
									<div className='feature-flag flex' key={key}>
										<div className='toggle-config'>
											<div className='rounded-slider-switch' onClick={() => toggleItem(+key)}>
												<input type='checkbox' checked={item.value === 'true' || item.value} onChange={() => null} />
												<span className='rounded-slider' />
											</div>
											<p>{translate(item.value ? 'on' : 'off')}</p>
										</div>
										<div className='feature-description'>
											<p className='flex-1'>
												{translate(item.title, {
													roleNameNurse: companySettings.nurseDisplayName,
													roleNameVS: companySettings.virtualSitterDisplayName,
												})}
											</p>
											<p>
												{translate(item.description, {
													roleNameNurse: companySettings.nurseDisplayName,
													roleNameVS: companySettings.virtualSitterDisplayName,
												})}
											</p>
										</div>
									</div>
								))}
							</div>
						);
					})}

				<div className='feature-flags-header'>
					<div>
						<h4>{translate('workflow')}</h4>
						<Select
							value={selectedCallWorkflow}
							classNamePrefix='react-select'
							options={transformArray(configurableWorkflowTypes())}
							onChange={setSelectedCallWorkflow}
						/>
					</div>
				</div>
				{!isLoading && (
					<>
						{Object.keys(featureFlagsCategories)
							.filter(category => category !== AiSettingFeaturesCategory.EVIDENCE_CONFIGURATIONS)
							.map(category => (
								<div
									className={classNames('feature-flags-category', {
										disabled: areAiFeatureFlagsDisabled(category, generalSettingFeatureFlags[AiSetting.SILENT_MODE]?.value),
									})}
									key={category}>
									{props.categoryOrder && <h4>{translate(category)}</h4>}
									{groupedCategories[category]?.map(([key, item]) => (
										<div
											className={classNames('feature-flag flex', { disabled: isSilentModeDisabled(key, item.value) })}
											key={key}>
											<div className='toggle-config'>
												{isLockEditable() && (
													<i
														className='material-icons cursor-pointer right-s opacity-transformation'
														onClick={() => toggleLock(+key)}>
														{item.isLocked ? 'lock' : 'lock_open'}
													</i>
												)}
												<div
													className={classNames('rounded-slider-switch', item.isDisabled ? 'disabled' : '')}
													onClick={() => toggleItem(+key)}>
													<input type='checkbox' checked={item.value} onChange={() => null} />
													<span className='rounded-slider' />
												</div>
												<p>{item.value ? translate('on') : translate('off')}</p>
											</div>
											<div className='feature-description full-width'>
												<p className='flex-1 ai-feature-title'>{translate(item.title)}</p>
												<div className='flex flex-space-between'>
													<p className='margin-top-m'>
														{translate(item.description, {
															roleNameNurse: companySettings.nurseDisplayName,
															roleNameVS: companySettings.virtualSitterDisplayName,
														})}
													</p>
												</div>
												{item.aiConfigurationTypeId && expandedRows.includes(item.aiConfigurationTypeId) && (
													<div className='flex column-direction'>
														<AiConfigurationFeatureFlags
															onAiConfigsChange={handleAiConfigsChange}
															settingTypeId={key}
															selectedAiConfig={item}
															aiErrors={aiErrors}
															isAdminView={true}
														/>
														<Button
															type='button'
															className={classNames(
																'ai-config-btn',
																!item.value ? 'disabled' : '',
																isSubmitButtonLoading ? 'loading' : ''
															)}
															onClick={() => handleSaveAiConfigurations(key, item)}
															text={translate('save')}
														/>
													</div>
												)}
											</div>
											{item.isExpandable && (
												<span
													className='flex cursor-pointer margin-left-auto expand-ff'
													onClick={() => viewAiConfigurationDetails(item.aiConfigurationTypeId)}>
													<i className='material-icons-outlined'>
														{expandedRows.includes(item.aiConfigurationTypeId) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
													</i>
												</span>
											)}
										</div>
									))}
								</div>
							))}
					</>
				)}
				{!isLoading && isEmptyState && <EmptyState title={translate('pleaseReachAdmin')} image='no-files.svg' />}
				{isLoading && <SkeletonLoader rows={10} padding='35px 20px' />}
			</div>
			<Modal
				position='center'
				modalSelector='confirmOverwritingAdminConfigs'
				onModalClose={() => setSelectedConfig(null)}
				className='confirm-overwrite-configs'
				display={selectedConfig}
				primaryButtonLabel={translate('applyToAll')}
				onModalSubmit={() =>
					updateTeamsConfigurations({
						isOverride: true,
						settingType: selectedAiSettings ? ConfigSettingType.SELECT : ConfigSettingType.TOGGLE,
						key: selectedConfig,
						selectedItem: selectedAiSettings,
					})
				}
				isThirdButtonShown
				onThirdButtonClick={() =>
					updateTeamsConfigurations({
						isOverride: false,
						settingType: selectedAiSettings ? ConfigSettingType.SELECT : ConfigSettingType.TOGGLE,
						key: selectedConfig,
						selectedItem: selectedAiSettings,
					})
				}
				thirdButtonLabel={translate('exclude')}>
				<div className='overwrite-configs-content'>
					<h2>
						{translate('warning')} - {translate('featureOverwrite')}
					</h2>
					<p>{translate('waringOverwriteDescription')}</p>
					<h4>{translate('roomsWithDifferentConfigs')}:</h4>
					<div className='rooms-list flex'>
						{roomsToBeOverwritten.map(item => (
							<div key={item.room.id}>
								<img className='device-owner-ico' src={`${healthCareCdnUrl}treeview/Room.svg`} alt='ico' />
								<span>{item.room.name}</span>
							</div>
						))}
					</div>
					<p>{translate('bySavingThisDescription')}</p>
				</div>
			</Modal>
			<PopUpAlert
				alertType={alertType}
				display={alertType}
				onAlertClose={() => setAlertType(null)}
				contentText={intl.formatMessage({ id: alertType === AlertTypes.SUCCESS ? 'changesSaved' : 'somethingWentWrong' })}
				isSilent={true}
				center={true}
				selfCloseTimeOut={1500}
			/>
		</>
	);
};

export default AiFeatureFlags;
