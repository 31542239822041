import axios from 'axios';
import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getToken = async () => {
	try {
		const { data } = await gatewayApi.get('v1/azure-storage/data-acquisition/sas-token?isAnonymizerBlob=true');
		return {
			containerUri: data.containerUri,
			sasToken: data.sasToken?.substring(1),
		};
	} catch (error) {
		return { error };
	}
};

export const getAiBlobs = async ({ serialNumber, evidenceId, frameType, containerUri, sasToken }) => {
	const url = `AiLogs/${getCompanyId()}/${serialNumber}/evidence/${evidenceId}/${frameType}`;
	const encodedUrl = encodeURIComponent(url);
	try {
		const { data } = await axios.get(`${containerUri}?restype=container&comp=list&prefix=${encodedUrl}&${sasToken}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAiFrames = async ({ containerUri, filePath, sasToken, responseType }) => {
	try {
		const { data } = await axios.get(`${containerUri}/${filePath}?${sasToken}`, {
			responseType,
		});
		return data;
	} catch (error) {
		return { error };
	}
};
