import { Loader } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { getFormatedTimeZone, searchPlaceOrTimeZone } from 'infrastructure/helpers/timezone/timeZonesHelper.js';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';

const SelectTimeZone = ({ selectedPlace, setSelectedPlace }) => {
	const [searchValue, setSearchValue] = useState('');
	const [isSearchLoading, setIsSearchLoading] = useState(false);
	const [searchResult, setSearchResult] = useState([]);
	const [showItemDropdown, setShowItemDropdown] = useState(false);
	const onSearchTypeTimeout = useRef(null);
	const dropdownRef = useRef(null);
	const intl = useIntl();

	useEffect(() => {
		if (searchValue) {
			handleSeach(searchValue);
		}
		return () => {
			if (onSearchTypeTimeout.current) {
				clearTimeout(onSearchTypeTimeout.current);
			}
		};
	}, [searchValue]);

	const handleSeach = search => {
		setIsSearchLoading(true);
		if (onSearchTypeTimeout.current) clearTimeout(onSearchTypeTimeout.current);
		onSearchTypeTimeout.current = setTimeout(async () => {
			const cityLookup = searchPlaceOrTimeZone(search);
			const mapedLookUp = cityLookup.map(item => {
				return {
					timezone: item.timezone,
					city: item.city,
					country: item.country,
					province: item.province,
					formattedTimeZone: getFormatedTimeZone(item.timezone).formattedTimeZone,
					state_ansi: item.state_ansi,
				};
			});
			setSearchResult(mapedLookUp);
			setIsSearchLoading(false);
		}, 500);
	};

	useOutsideClick(dropdownRef, () => {
		setSearchValue('');
		setShowItemDropdown(false);
	});

	const onSelectTimeZone = item => {
		setSelectedPlace(item);
		setShowItemDropdown(false);
		setSearchValue('');
	};

	const getHighlighter = word => <Highlighter searchWords={searchValue.split(' ')} autoEscape={true} textToHighlight={word} />;

	const getFullTimeZone = item => (
		<p className='gray-color'>
			<p> {getHighlighter(item.country)}</p>
			{item.province === item.city ? getHighlighter(item.province) : getHighlighter(`${item.city} | ${item.province}`)}
			<p>{getHighlighter(item.formattedTimeZone)}</p>
		</p>
	);

	return (
		<>
			<div className='mi-input-wrapper'>
				<input
					type='text'
					onChange={event => {
						setSearchValue(event.target.value);
						setShowItemDropdown(true);
						if (!event.target.value) {
							setShowItemDropdown(false);
						}
					}}
					value={searchValue}
					placeholder={intl.formatMessage({ id: 'searchPlaceTimeZone' })}
				/>
				{!isSearchLoading && searchResult.length > 0 && showItemDropdown && (
					<div ref={dropdownRef}>
						{searchResult.map(item => (
							<div key={item.state_ansi} className='cursor-pointer timezone-item' onClick={() => onSelectTimeZone(item)}>
								{getFullTimeZone(item)}
							</div>
						))}
					</div>
				)}
				{searchResult.length === 0 && searchValue && !isSearchLoading && (
					<div>
						<div>
							<p>{translate('noResultsFound')}</p>
						</div>
					</div>
				)}
				{isSearchLoading && (
					<div className='mi-loading-div position-relative' style={{ minHeight: '50px' }}>
						<Loader />
					</div>
				)}
			</div>
			{selectedPlace && (
				<div className='mi-selected-list'>
					<p>{translate('selectedTimeZone')}</p>
					<div key={selectedPlace.state_ansi} className='position-relative'>
						<i className='material-icons cursor-pointer' onClick={() => setSelectedPlace(null)}>
							close
						</i>
						<span>
							<p className='bold-weight'> {selectedPlace.country}</p>
							<p className='gray-color bold-weight'>
								{selectedPlace.province === selectedPlace.city
									? selectedPlace.province
									: `${selectedPlace.city} | ${selectedPlace.province}`}
							</p>
							<p className='gray-color'>{selectedPlace.formattedTimeZone}</p>
						</span>
					</div>
				</div>
			)}
		</>
	);
};

export default SelectTimeZone;
