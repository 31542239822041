import React, { useContext, useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';
import { getPainLevel, setPainLevel } from 'api/whiteboard.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import ReactSelect from 'react-select';
import { useIntl } from 'react-intl';
import Alert from 'components/Alert.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { painLevelOptions, painLevels } from 'constants/monitoring.js';
import ActionButtons from 'containers/Monitoring/Whiteboard/ActionButtons.jsx';

const CurrentPain = props => {
	const [expanded, setExpanded] = useState(true);
	const [currentPainLevel, setCurrentPainLevel] = useState('');
	const [showEditForm, setShowEditForm] = useState(false);
	const [error, setError] = useState(null);
	const intl = useIntl();

	const painScoreCode = 'HCI100';

	const socket = useContext(SocketContext);

	useEffect(() => {
		const fetchData = async () => {
			const response = await getPainLevel(props.deviceOwnerId);
			if (response.error) {
				setError(translate('somethingWentWrong'));
				return;
			}

			const found = response.observations.find(item => item.code === painScoreCode);

			if (found?.valueString) {
				setCurrentPainLevel(painLevels.find(item => item.value === parseInt(found?.valueString, 10))?.name || '');
			}
		};

		if (props.deviceOwnerId) {
			fetchData();
		}
	}, [props.deviceOwnerId]);

	const onChange = async item => {
		const response = await setPainLevel(props.deviceOwnerId, item.value, props.deviceId);

		if (response.error) {
			setError(translate('somethingWentWrong'));
			return;
		}

		setCurrentPainLevel(item.label);
	};

	useEffect(() => {
		const handleObservationsUpdate = data => {
			if (data.deviceId !== props.deviceId) {
				return;
			}

			const found = data.observations.find(item => item.code === painScoreCode);
			setCurrentPainLevel(painLevelOptions.find(item => item.value === found.valueString)?.label);
		};
		socket.on(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_ADDED, handleObservationsUpdate);

		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_ADDED, handleObservationsUpdate);
		};
	}, [socket, props.deviceOwnerId, props.deviceId]);

	return (
		<>
			<div className={classNames('timeline-box-header sub-timeline-box-header', expanded ? 'expanded' : '')}>
				<p className='timeline-box-title'>{translate('painLevel')}</p>
				<div className='timeline-box-actions'>
					<ActionButtons
						handleEditClick={() => {
							setShowEditForm(prevState => !prevState);
							setExpanded(true);
						}}
						handleExpandClick={() => setExpanded(prevState => !prevState)}
						expanded={expanded}
					/>
				</div>
			</div>
			{expanded && (
				<>
					{!showEditForm && (
						<table className='patient-table-informations' style={{ width: '100%' }}>
							<tbody>
								<tr>
									<td>{translate('painLevel')}</td>
									<td>{currentPainLevel}</td>
								</tr>
							</tbody>
						</table>
					)}
					{showEditForm && (
						<div className='flex flex-space-between flex-wrap whiteboard-select'>
							<ReactSelect
								options={painLevelOptions}
								value={
									currentPainLevel
										? {
												value: painLevelOptions.find(item => item.label === currentPainLevel)?.value,
												label: painLevelOptions.find(item => item.label === currentPainLevel)?.label,
										  }
										: null
								}
								onChange={onChange}
								id='select'
								classNamePrefix='react-select full-width'
								styles={{
									...generateCustomStyles({ darkMode: props.isDarkMode }),
								}}
								placeholder={intl.formatMessage({ id: 'painLevel' })}
							/>
						</div>
					)}
				</>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default CurrentPain;
