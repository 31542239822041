import React from 'react';
import { useHistory } from 'react-router';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import PoolingFlow from 'containers/PoolingFlow/index.jsx';
import Grid from 'components/Grid.jsx';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';

const PoolingFlowWrapper = () => {
	const redirect = () => {
		const refToken = getStorage().getItem('ref_token');
		const redirectUrl = getStorage().getItem('redirectUri');
		history.push(refToken ? redirectUrl : '/');
	};
	const history = useHistory();
	return (
		<MainLayout>
			<Grid width='100%'>
				<main className='main-view'>
					<PoolingFlow changePage={redirect} />
				</main>
			</Grid>
		</MainLayout>
	);
};

export default PoolingFlowWrapper;
