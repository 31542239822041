import styled, { css } from 'styled-components';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';

// These colors are used only for the background color of PTZ when the cursor hovers
const PTZLightHoverColor = 'rgba(84, 84, 84, 0.3)';
const PTZDarkHoverColor = 'rgba(37, 43, 50, 1)';

const backgroundColor = (isDarkMode, fixedPosition) =>
	isDarkMode ? (fixedPosition ? DarkTheme.colors.grayThree : DarkTheme.colors.grayFour) : LightTheme.colors.grayZero;
const backgroundHoverColor = isDarkMode => (isDarkMode ? PTZDarkHoverColor : PTZLightHoverColor);
const fontColor = isDarkMode => (isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayEleven);

/**
 * @type {import('styled-components').StyledComponent<"aside", any, { $top?: number, $zIndex?: number, $fixedPosition: boolean, $isRightToLeft: boolean, $isDarkMode: boolean, $isVisible: boolean }, never>}
 */
const StyledPTZ = styled.aside`
	display: ${props => (props.$isVisible ? 'flex' : 'none')};
	flex-direction: column;
	align-items: center;
	position: ${props => (props.$fixedPosition ? 'fixed' : 'absolute')};
	top: ${props => (props.$top ? `${props.$top}px` : props.$fixedPosition ? `${LightTheme.spacing[2]}px` : '0')};
	left: ${LightTheme.spacing[4]}px;
	padding: ${LightTheme.spacing[2]}px !important;
	padding-left: 0 !important;
	z-index: ${props => props.$zIndex || 2};

	> header,
	> aside {
		opacity: 0.9;
	}

	&:hover {
		> header,
		> aside {
			opacity: 1;
			transition: all 0.2s linear;
		}
	}

	${props =>
		props.$isRightToLeft &&
		css`
			left: unset;
			right: ${LightTheme.spacing[4]}px;
			padding-right: 0 !important;
			direction: rtl;
		`}

	header {
		margin-bottom: var(--spacing-s);
		position: relative;
		z-index: 10;

		&:empty {
			margin: 0;
		}
	}

	> header {
		display: flex;
		width: 100%;
		> div {
			width: 100%;
			> main {
				flex: 1;
			}
		}
	}

	p,
	span,
	button {
		color: ${props => fontColor(props.$isDarkMode)};
	}

	button {
		display: flex;
		padding: var(--spacing-m);
		width: 100%;
		background-color: transparent;
		font-size: 14px;
		text-transform: none;
		user-select: none;

		span {
			font-size: var(--spacing-xl);
		}

		&:disabled {
			pointer-events: none;
		}
	}

	.dropdown__items {
		background-color: ${props => backgroundColor(props.$isDarkMode, props.$fixedPosition)} !important;
		> div {
			background-color: ${props => backgroundColor(props.$isDarkMode, props.$fixedPosition)} !important;
			padding: 5px;
			width: 200px;
			max-width: 220px;
			border-radius: var(--spacing-s);
			> div {
				display: flex;
				border-bottom: 1px solid #3434341a;
				border-width: 95%;
				margin: 0 auto;
				&:last-of-type {
					border-bottom: none;
				}
				&:nth-of-type(3) {
					button {
						i {
							color: var(--red-3);
						}
					}
				}
				button {
					font-size: 12px;
					padding: 5px;
					width: 100%;
					border-radius: var(--spacing-s);
					background-color: transparent;
					text-transform: none;
					align-items: center;
					i {
						color: #5390ff;
						padding-right: var(--spacing-s);
					}
					&:hover {
						background-color: ${props => backgroundHoverColor(props.$isDarkMode)};
					}
				}
			}
		}
	}

	section {
		header {
			button {
				&:hover {
					background-color: unset;
				}
			}
		}
		button {
			padding: var(--spacing-m);
			width: unset;
			font-size: 14px;
		}
		button[type='submit'] {
			color: var(--gray-0);
			background-color: var(--blue-light-5);
		}
	}
`;

/**
 * @type {import('styled-components').StyledComponent<"aside", any, { $isDarkMode: boolean, $fixedPosition: boolean }, never>}
 */
const StyledCameraControls = styled.aside`
	width: 160px;
	padding: var(--spacing-s);
	display: flex;
	align-items: center;
	border-radius: var(--spacing-s);
	background-color: ${props => backgroundColor(props.$isDarkMode, props.$fixedPosition)};
	border: 1px solid #3434341a;

	> div:first-of-type {
		width: 110px;
		height: 110px;
		display: flex;
		flex-flow: row wrap;
		transform: rotate(45deg);
		justify-content: center;
		align-items: center;
		position: relative;
		border-radius: 50%;
		overflow: hidden;

		button {
			height: 55px;
			width: 55px;
			justify-content: center;
			align-items: center;
			padding: 0;
			border-radius: 0;
			background-color: ${props => backgroundColor(props.$isDarkMode, props.$fixedPosition)};

			span {
				transform: rotate(-45deg);
				font-size: 32px;
			}

			&:hover {
				background-color: ${props => backgroundHoverColor(props.$isDarkMode)};
			}

			&:disabled {
				pointer-events: none;
			}
		}

		> div {
			position: absolute;
			background-color: ${props => backgroundColor(props.$isDarkMode, props.$fixedPosition)};
			height: 50px !important;
			width: 50px !important;
			border-radius: 50%;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: center;

			> span {
				transform: rotate(-45deg);
			}

			button {
				height: 50px !important;
				width: 50px !important;
				border-radius: 50%;
				transform: rotate(0deg);

				span {
					font-size: 24px;
				}
			}
		}
	}

	> div:nth-of-type(2) {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		align-items: center;
		margin: 0 var(--spacing-s) 0 var(--spacing-m);

		input[type='range'] {
			height: 80px;
			width: 2px;
			-webkit-writing-mode: vertical-lr;
			-ms-writing-mode: tb-lr;
			writing-mode: vertical-lr;
			direction: rtl;
			appearance: auto;
			margin: 0;
			margin-bottom: var(--spacing-s);
			transition: none;
		}

		input[type='range']::-webkit-slider-thumb,
		input[type='range']::-moz-range-thumb {
			appearance: none;
			-webkit-appearance: none;
			background: var(--blue-2);
			width: 12px;
			height: 12px;
			border-radius: 50%;
			cursor: pointer;
			position: relative;
		}

		.dropdown {
			padding: 0;

			> button {
				padding: 0;
				border-radius: var(--spacing-s);

				i {
					margin: 0;
					align-self: center;
				}
			}
		}
	}
`;

/**
 * @type {import('styled-components').StyledComponent<"aside", any, { $isDarkMode: boolean, $fixedPosition: boolean, $isRightToLeft: boolean }, never>}
 */
const StyledCameraBookmarks = styled.aside`
	width: 160px;
	margin-top: var(--spacing-s);
	border-radius: var(--spacing-s);
	background-color: ${props => backgroundColor(props.$isDarkMode, props.$fixedPosition)};

	p {
		margin: 0;
		padding: 0;
		font-size: 12px;
	}

	button {
		width: 100%;
		text-align: left;
		font-size: 12px;
		padding: var(--spacing-s) var(--spacing-m);
		border-radius: var(--spacing-s);

		> div {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			> div {
				display: flex;
				text-align: left;

				> span {
					align-self: center;
					padding-right: var(--spacing-s);
				}

				div {
					p {
						font-size: 14px;
						font-weight: 500;
						margin-bottom: -5px;
					}

					span {
						font-size: var(--spacing-m);
					}
				}
			}

			> span {
				padding-right: var(--spacing-s);
			}
		}
	}

	> div {
		padding-bottom: var(--spacing-s);

		> p {
			padding: 3px var(--spacing-m);
		}

		> div {
			display: flex;
			border-radius: var(--spacing-s);
			padding: 3px var(--spacing-m);

			&:hover {
				background-color: ${props => backgroundHoverColor(props.$isDarkMode)};
			}

			> button {
				padding: 0;
			}

			button {
				${props =>
					props.$isRightToLeft &&
					css`
						text-align: right;
					`}
				&:hover {
					background-color: unset;
				}
			}

			i {
				padding: 0;
				margin: 0;
			}
		}
		.dropdown {
			padding: 0;

			> button {
				padding: 0;
				padding-right: var(--spacing-s);

				i {
					margin: 0;
					align-self: center;
				}
			}
		}
	}
`;

const StyledAddNewCameraBookmarksButton = styled.aside`
	margin-top: var(--spacing-s);
	padding: 0 var(--spacing-m);

	&:hover {
		background-color: none !important;
	}

	button {
		font-weight: 500;
		text-align: center;
		align-items: center;
		color: var(--blue-light-5);
		background-color: var(--blue-light-5-o10);
		padding: 3px var(--spacing-m);

		span {
			font-size: var(--spacing-xl);
			padding-right: var(--spacing-s);
			color: var(--blue-light-5);
		}

		&:hover {
			background-color: var(--blue-light-5-o30);
		}
	}
`;

export { StyledPTZ, StyledCameraControls, StyledCameraBookmarks, StyledAddNewCameraBookmarksButton };
