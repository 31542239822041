import React from 'react';
import { Formik } from 'formik';
import Modal from 'components/Modal.jsx'; 
import translate from 'i18n-translations/translate.jsx';
import Form from 'components/Form.jsx';
import Textarea from 'components/Textarea.jsx';

const SendMessageModal = props => (
	<Formik
		initialValues={{
			message: '',
		}}
		onSubmit={props.sendGroupMessage}>
		{formikProps => {
			const { values, handleSubmit, handleChange } = formikProps;
			return (
				<Modal
					display={props.isSendGroupMessageOpen}
					onModalSubmit={handleSubmit}
					primaryButtonLabel={translate('sendMessage')}
					closeButtonText={translate('discard')}
					position='center'
					onModalClose={props.closeSendGroupMessage}
					shouldSubmitOnEnter={false}
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
					isSubmitDisabled={!values.message}
					primaryButtonLoading={props.isSendMessageLoading}>
					<Form height={350} className='modal-form-wrapper send-group-message'>
						<h3>
							{translate('message')} {props.selectedFloor}
						</h3>
						<p>{translate('message')}</p>
						<Textarea
							maxLength={500}
							maxNumberLimit={50}
							rows={10}
							value={values.message}
							onChange={handleChange}
							name='message'
						/>
					</Form>
				</Modal>
			);
		}}
	</Formik>
);

export default SendMessageModal;
