import axios from 'axios';
import { APP_CONFIG } from 'constants/global-variables.js';

export const getAzureSpeechToken = async () => {
	const speechKey = APP_CONFIG.speechToTextKey;
	const speechRegion = APP_CONFIG.speechToTextRegion;
	const headers = {
		headers: {
			'Ocp-Apim-Subscription-Key': speechKey,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	};
	try {
		const tokenResponse = await axios.post(
			`https://${speechRegion}.api.cognitive.microsoft.com/sts/v1.0/issueToken`,
			null,
			headers
		);
		return tokenResponse;
	} catch (error) {
		return error;
	}
};
