import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Highlighter from 'react-highlight-words';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getClinicalDiagnoses } from 'api/medicalInfo.js';

const AddDiagnoses = props => {
    const intl = useIntl();
    const [searchValue, setSearchValue] = useState('');
    const [diagnosisToAdd, setDiagnosisToAdd] = useState([]);

    useEffect(() => {
        const fetchDiagnosis = async () => {
            if (searchValue === '') {
                setDiagnosisToAdd([]);
                return;
            }
            const response = await getClinicalDiagnoses(searchValue);
            if (response.error) {
                props.setError(response.error.message);
            } else {
                const filtered = response.filter(item => !props.diagnoses.find(diagnose => diagnose.code === item.code));
                setDiagnosisToAdd(filtered);
            }
        };
        fetchDiagnosis();
    }, [searchValue]);

    const handleRemoveDiagnose = diagnose => props.setDiagnoses(prevState => prevState.filter(item => item.code !== diagnose.code));

    return (
        <div className='diagnoses'>
            <div className='form-item'>
                <div className='flex'>
                    <h3>*{translate('diagnoses')}</h3>
                </div>

                <form onSubmit={e => e.preventDefault()}>
                    <label className='full-width position-relative'>
                        <div className='position-relative'>
                            <img src={`${healthCareCdnUrl}search-icon.svg`} alt='ico' className='search-patient-icon' />
                            <input
                                className='search-input'
                                placeholder={intl.formatMessage({ id: 'searchForDiagnoses' })}
                                type='text'
                                name='searchValue'
                                value={searchValue}
                                onChange={event => {
                                    setSearchValue(event.target.value)
                                }}

                            />
                        </div>
                        {searchValue && (
                            <div className='full-page-input-results full-width'>
                                {diagnosisToAdd.length > 0 &&
                                    diagnosisToAdd.map(diagnose => (
                                        <div
                                            key={diagnose.code}
                                            className='cursor-pointer'
                                            onClick={() => {
                                                props.setDiagnoses(prevState => [...prevState, diagnose]);
                                                setSearchValue('');
                                            }}>
                                            <Highlighter
                                                searchWords={searchValue.split(' ')}
                                                autoEscape={true}
                                                textToHighlight={`${diagnose.code} | ${diagnose.description} `}
                                            />
                                        </div>
                                    ))}
                                {diagnosisToAdd.length === 0 && (
                                    <div>
                                        <span>{translate('noResultsFound')}</span>
                                    </div>
                                )}
                            </div>
                        )}
                    </label>
                    {props.diagnoses.length > 0 && <span>{translate('addedDiagnoses')}</span>}
                    <div className='flex searched-diagnostic-research'>
                        {props.diagnoses.map(diagnose => (
                            <div onClick={() => handleRemoveDiagnose(diagnose)} key={diagnose.code}>
                                <p className='font-12'>{diagnose.code}</p>
                                <p>{diagnose.description}</p>
                                <i className='material-icons cursor-pointer'>close</i>
                            </div>
                        ))}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddDiagnoses;
