import Tooltip from 'calls/components/Tooltip.jsx';
import Controls from 'calls/icons/Controls.jsx';
import classNames from 'classnames';
import { TvSettingIndex, TvSettingTypes } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import SpinLoader from 'icons/Monitoring/SpinLoader.jsx';
import { useIntl } from 'react-intl';

const newValueToSend = {
	on: 'off',
	off: 'on',
};

const DisplayControl = ({ data, toggleDisplayControl, isDeviceAssigned, isLoading }) => {
	const intl = useIntl();

	const onToggleSwitch = () => {
		if (!isDeviceAssigned || isLoading) {
			return;
		}

		toggleDisplayControl({
			settingType: TvSettingTypes.DISPLAY,
			settingValue: newValueToSend?.[data?.settingValue] ?? 'on',
			settingTypeId: data?.settingTypeId,
			tvIndex: TvSettingIndex.WHITEBOARD,
		});
	};

	const renderSwitchContent = () => {
		if (isLoading) {
			return <SpinLoader />;
		}

		return (
			<div className='toggle-switch' onClick={onToggleSwitch}>
				<input type='checkbox' checked={data?.settingValue === 'on'} onChange={() => null} />
				<span className={classNames('toggle-body', !isDeviceAssigned ? 'toggle-body-disabled' : '')}>
					<span className='on-text'>{translate('on')}</span>
					<span className='off-text'>{translate('off')}</span>
				</span>
			</div>
		);
	};

	return (
		<Tooltip isPortal text={!isDeviceAssigned && !isLoading ? intl.formatMessage({ id: 'noWhiteboardAssigned' }) : ''}>
			<div
				className={classNames(
					'monitoring-timeline-box display-control full-width',
					!isDeviceAssigned || isLoading ? 'display-control-disabled' : ''
				)}>
				<div className='timeline-box-header'>
					<p className='timeline-box-title'>
						<Controls color={'var(--gray-0)'} />
						{translate('whiteboardDisplayControl')}
					</p>
					<div className='timeline-box-actions'>
						<div className='flex'>{renderSwitchContent()}</div>
					</div>
				</div>
			</div>
		</Tooltip>
	);
};

export default DisplayControl;
