import React from 'react';

const DescriptionBox = props => (
	<ul className='description-box'>
		{props.data.map(
			data =>
				data.title && (
					<li key={data.id}>
						<span>{data.title}:</span>
						{data.description}
					</li>
				)
		)}
	</ul>
);

export default DescriptionBox;
