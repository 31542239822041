import { Formik } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import Select from 'react-select';
import { Form, Modal } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import { getHospitalDepartments } from 'api/healthSystems.js';
import { addRingToneDurationConfig } from 'api/ringtone.js';

const items = translator =>
	Array.from({ length: 31 }, (_, index) => ({ value: index, label: `${index} ${translator('seconds')}` }));

const RingToneConfig = props => {
	const intl = useIntl();
	const translator = id => intl.formatMessage({ id });
	const [error, setError] = useState(null);
	const [hospitals, setHospitals] = useState([]);
	const [sectorList, setSectorList] = useState([]);
	const [selectedTimer, setSelectedTimer] = useState(items(translator)[0]);

	const getInitialValues = () => {
		if (props.initialValues) {
			const { team, ringtoneVolume } = props.initialValues;
			return {
				...props.initialValues,
				selectedHealthSystem: {
					value: team.id,
					label: team.name,
				},
				selectedSector: { value: team.id, label: team.name },
				ringtoneVolumeValue: ringtoneVolume,
			};
		}
		return {
			healthSystems: props.healthSystems,
			selectedHealthSystem: null,
			selectedHospital: null,
			selectedSector: null,
			ringtoneVolumeValue: 0,
		};
	};

	const getValidationSchema = () => {
		const validation = {};
		if (!props.initialValues) {
			validation.selectedHealthSystem = Yup.object().nullable().required(translator('pleaseSelectHealthSystem'));
			validation.selectedHospital = Yup.object().nullable().required(translator('pleaseSelectHospital'));
			validation.selectedSector = Yup.object().nullable().required(translator('pleaseSelectDepartment'));
		}
		return validation;
	};

	const onCloseModal = resetForm => {
		resetForm();
		props.toggleModal();
		setSelectedTimer(items(translator)[0]);
		setError(null);
	};

	const onFormHealthSystemSelect = async (values, setFieldValue) => {
		if (props.initialValues) {
			return;
		}
		const selectedHS = props.healthSystems.reduce((acc, item) => {
			const mapped = {
				label: item.name,
				value: item.id,
			};
			if (item.id === values.value) {
				return mapped;
			}
			return acc;
		}, null);
		setFieldValue('selectedHealthSystem', selectedHS);
		const hsHospitals = await getHealthSystemHospitals(values.value);
		if (hsHospitals.error) {
			setError(hsHospitals.error.message);
			return;
		}
		setHospitals(hsHospitals);
		setFieldValue('selectedHospital', null);
		setFieldValue('selectedSector', null);
	};

	const onFormHospitalSelect = async (values, setFieldValue, hsValue) => {
		if (props.initialValues) {
			return;
		}
		const selectedHospital = hospitals.reduce((acc, item) => {
			const mapped = {
				label: item.name,
				value: item.id,
			};
			if (item.id === values.value) {
				return mapped;
			}
			return acc;
		}, null);
		setFieldValue('selectedHospital', selectedHospital);
		const response = await getHospitalDepartments(hsValue, values.value);
		if (response.error) {
			props.setIsFormLoading(false);
			setError(response.error.message);
			return;
		}
		setSectorList(response.hospital.departments);
		setFieldValue('selectedSector', null);
	};

	const onFormDepartmentSelect = (values, setFieldValue) => {
		if (props.initialValues) {
			return;
		}
		const selectedSector = sectorList.reduce((acc, item) => {
			const mapped = {
				label: item.name,
				value: item.id,
			};
			if (item.id === values.value) {
				return mapped;
			}
			return acc;
		}, null);
		setFieldValue('selectedSector', selectedSector);
	};

	const addDeviceConfigurations = async values => {
		setError(null);
		const dataToSend = {
			departmentId: values.selectedSector.value,
			hospitalId: values.selectedHospital.value,
			params: {
				teamName: values.selectedSector.label,
				configuration: {
					customRingtoneDurationSeconds: selectedTimer.value,
				},
			},
		};
		props.setIsFormLoading(true);
		const response = await addRingToneDurationConfig(dataToSend);
		if (response.error && response.error.response?.data) {
			props.setIsFormLoading(false);
			setError(response.error?.response?.data?.message);
			return;
		}
		if (response.error) {
			props.setIsFormLoading(false);
			setError(response.error.message);
			return;
		}
		props.setIsFormLoading(false);
		props.toggleModal();
		props.getConfigurations();
		setSelectedTimer(items(translator)[0]);
	};

	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={getInitialValues()}
				validationSchema={Yup.object().shape({ ...getValidationSchema() })}
				onSubmit={(values, { resetForm }) => {
					addDeviceConfigurations(values);
					resetForm({ values: '' });
				}}>
				{formikProps => {
					const { values, errors, handleSubmit, resetForm, setFieldValue } = formikProps;

					return (
						<Modal
							modalSelector='ringtoneConfig'
							className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
							display={props.isModalOpen}
							position='right'
							onModalSubmit={handleSubmit}
							onModalClose={() => onCloseModal(resetForm)}
							isLoading={props.isFormLoading}
							shouldSubmitOnEnter={false}>
							<Form title={intl.formatMessage({ id: 'customRingtoneDuration' })} onSubmit={event => event.preventDefault()}>
								{!props.initialValues && (
									<div className='input'>
										<p className='label'>{translate('selectHealthSystem')}</p>
										<p className='font-14'>{translate('selectHSForConfiguration')}</p>
										<Select
											value={values.selectedHealthSystem}
											placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
											classNamePrefix='react-select'
											options={props.transformArray(props.healthSystems)}
											onChange={event => onFormHealthSystemSelect(event, setFieldValue)}
										/>
										<small>{errors.selectedHealthSystem}</small>
									</div>
								)}

								<div className='input'>
									<p className='label'>{translate('selectHospital')}</p>
									<p className='font-14'>{translate('chooseHospitalForChanges')}</p>
									<Select
										isDisabled={!values.selectedHealthSystem?.value}
										value={values.selectedHospital}
										placeholder={intl.formatMessage({ id: 'selectHospital' })}
										classNamePrefix='react-select'
										options={props.transformArray(hospitals)}
										onChange={event => onFormHospitalSelect(event, setFieldValue, values.selectedHealthSystem?.value)}
									/>
									<small>{errors.selectedHospital}</small>
								</div>

								<div className='input'>
									<p className='label'>
										{translate('selectSector', {
											value: intl.formatMessage({ id: 'department' }),
										})}
									</p>
									<p className='font-14'>
										{translate('chooseSectorForChanges', {
											value: intl.formatMessage({ id: 'department' }),
										})}
									</p>
									<Select
										isDisabled={!values.selectedHospital?.value}
										value={values.selectedSector}
										placeholder={translate('selectSector', {
											value: intl.formatMessage({ id: 'department' }),
										})}
										classNamePrefix='react-select'
										options={props.transformArray(sectorList)}
										onChange={event => onFormDepartmentSelect(event, setFieldValue)}
									/>
									<small>{errors.selectedSector}</small>
								</div>

								<div className='input'>
									<p className='label'>{translator('customRingtoneDuration')}</p>
									<p className='font-14'>{translator('customRingtoneDurationDesc')}</p>
									<Select
										value={selectedTimer}
										placeholder={intl.formatMessage({ id: 'selectOption' })}
										classNamePrefix='react-select'
										options={items(translator)}
										onChange={setSelectedTimer}
									/>
								</div>
								{error && <div className='error'>{error}</div>}
							</Form>
						</Modal>
					);
				}}
			</Formik>
		</>
	);
};

export default RingToneConfig;
