import React, { useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import { addAlertActivity } from 'api/alerts.js';
import Alert from 'components/Alert.jsx';
import { MeasurementActivityTypes } from 'constants/enums.js';
import { Button } from 'components/index.js';

const CareNotificationsAlertModal = ({ feed, onCloseClick, onForwardToNurses, showForwardToNurses }) => {
	const [error, setError] = useState('');

	const onSubmit = async activityType => {
		const params = {
			alertId: feed.warning.alertId,
			activityType,
			isAiAlert: feed.warning.isAiAlert,
			comment: '',
			isDraft: true,
		};
		const response = await addAlertActivity(params);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		onCloseClick(feed);
	};

	return (
		<div className='flex column-direction ambient-monitoring-alert-modal'>
			<div className='flex column-direction ambient-monitoring-alert-modal-wrapper'>
				<div className='flex flex-space-between flex-align-center'>
					<span>
						{feed?.roomName} {translate('alert')}
					</span>
					<span className='flex flex-align-center flex-justify-center' onClick={onCloseClick}>
						<i className='material-icons-outlined'>close</i>
					</span>
				</div>
				<div className='flex flex-justify-center flex-align-center'>
					<span>{feed?.warning?.text}</span>
				</div>

				<div className='flex flex-justify-center'>
					<Button
						onClick={() => onSubmit(MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT)}
						className='false-alert'
						text={translate('falseAlert')}
					/>
					<Button
						onClick={() => onSubmit(MeasurementActivityTypes.ACKNOWLEDGED)}
						className='acknowledged-alert'
						text={translate('acknowledge')}
					/>
				</div>
				{showForwardToNurses && (
					<Button onClick={() => onForwardToNurses(feed)} className='forward-to-nurses' text={translate('forwardToNurses')} />
				)}
			</div>
			{error && <Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />}
		</div>
	);
};

export default CareNotificationsAlertModal;
