import * as React from 'react';

function Videocam(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
			<rect x={1.199} y={6} width={15.6} height={12} rx={2} stroke='currentColor' strokeWidth={1.5} />
			<path
				d='M16.8 10.944a1 1 0 01.713-.957l4-1.2a1 1 0 011.288.957v4.512a1 1 0 01-1.288.958l-4-1.2a1 1 0 01-.712-.958v-2.112z'
				stroke='currentColor'
				strokeWidth={1.5}
			/>
		</svg>
	);
}

export default Videocam;
