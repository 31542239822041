import { healthCareCdnUrl } from 'constants/global-variables.js';
import { formatDateTimeToString } from 'infrastructure/helpers/dateHelper.js';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const MarkSpan = styled.span`
	position: absolute;
	cursor: pointer;
	left: ${({ $markValue, $min, $max }) => ($markValue / ($max - $min)) * 100}%;
	transform: translateX(-50%);
	padding: 2px;
	border-radius: var(--spacing-s);
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	bottom: 0px;
	& > img {
		height: var(--spacing-l);
		width: var(--spacing-l);
		background: var(--gray-4);
		border-radius: var(--spacing-s);
	}
	.timestamp {
		font-size: 13px;
		margin-top: var(--spacing-s);
		text-align: center;
	}
`;

const SliderWithMarks = ({ value, min, max, step, framesFromAlert, onChange }) => {
	const marks = useMemo(() => {
		return framesFromAlert.reduce((acc, frame, index) => {
			if (frame.imageUrl) {
				acc[index] = {
					image: <img src={`${healthCareCdnUrl}alert-history/frame-icon.svg`} alt='ico' />,
					timestamp: formatDateTimeToString(frame.timeStamp),
				};
			}
			return acc;
		}, {});
	}, [framesFromAlert]);

	const sliderMarks = Object.keys(marks).map(markValue => (
		<MarkSpan
			key={markValue}
			data-mark={markValue}
			$markValue={markValue}
			$min={min}
			$max={max}
			onClick={() => onChange(+markValue)}>
			{marks[markValue].image}
			<div className='timestamp'>{marks[markValue].timestamp}</div>
		</MarkSpan>
	));

	return (
		<div className='slider-container'>
			<input type='range' min={min} max={max} step={step} value={value} onChange={event => onChange(+event.target.value)} />
			<div className='slider-marks'>{sliderMarks}</div>
		</div>
	);
};

export default SliderWithMarks;
