import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';

const ModalSelector = props => (
	<Modal
		display={props.display}
		position='center'
		onModalClose={props.onModalClose}
		primaryButtonLabel={translate('saveAndContinue')}
		closeButtonText={translate('reset')}
		className='standard-modal-wrapper send-request-modal-wrapper register-to-practice-modal'
		onModalSubmit={props.onModalSubmit}>
		<div className='standard-modal-inner'>
			<div className='standard-modal-title'>
				<h3>{props.title}</h3>
			</div>
			<div className='flex flex-wrap input-auto-width-wrapper'>
				{props.list.length > 0 &&
					props.list.map(item => (
						<div key={item.id} className='register-practice-modal-grid tick-box-active-input'>
							<label className='full-width remember-me'>
								<input
									onChange={event => {
										const selectedItem = {
											id: item.id,
											name: item.name,
											checked: event.target.checked,
										};
										props.formikProps.setFieldValue(`${props.valueField}[${item.id}]`, selectedItem);
									}}
									type='checkbox'
									checked={props.value && props.value[item.id] && props.value[item.id].checked}
								/>
								<div className='onb-custom-checkbox-wrapper cursor-pointer'>
									<div className='onb-custom-checkbox' />
								</div>
								<span>{translate(`${stringToCamelCase(item.name)}`)}</span>
							</label>
						</div>
					))}
			</div>
		</div>
	</Modal>
);

export default ModalSelector;
