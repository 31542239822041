import LightTheme from 'calls/styles/LightTheme.js';

const SupineIcon = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='48'
		height='48'
		viewBox='0 0 48 48'
		fill='none'
		color={LightTheme.colors.grayZero}
		{...props}>
		<rect x='4' y='24' width='40' height='4' stroke='currentColor' strokeWidth='1.5' strokeLinejoin='round' />
		<path d='M10 28V38' stroke='currentColor' strokeWidth='1.5' />
		<path d='M38 28V38' stroke='currentColor' strokeWidth='1.5' />
		<path d='M8 38H12' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
		<path d='M36 38H40' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
		<path
			d='M41 18C42.6569 18 44 19.3431 44 21C44 22.6569 42.6569 24 41 24C39.3431 24 38 22.6569 38 21C38 19.3431 39.3431 18 41 18Z'
			stroke='currentColor'
			strokeWidth='1.5'
			stroke-miterlimit='10'
		/>
		<path
			d='M20 24H34C34 24 37 24 37 21C37 18 34 18 34 18H20M20 24V18M20 24H15H6L4 20L20 18'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
	</svg>
);

export default SupineIcon;
