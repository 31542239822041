import { createCareEvent, deleteCareEvent, editCareEvent, getCareEvents } from 'api/teamConfigurationProfiles.js';
import classNames from 'classnames';
import Pagination from 'components/Common/Pagination.jsx';
import CustomTable from 'components/CustomTable.jsx';
import { Alert, Button, Input, Modal, Textarea } from 'components/index.js';
import { Tab, TabList, Tabs } from 'components/Tabs.jsx';
import { RoundingSettings } from 'constants/configurationEnums.js';
import { UserRoles } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import TableDeleteButton from 'icons/Admin/TableDeleteButton.jsx';
import TableEditButton from 'icons/Admin/TableEditButton.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';

const CareEvents = props => {
	const intl = useIntl();
	const companyConfigurations = useSelector(state => state.company.companySettings.companyConfigurations);
	const careEventType = {
		// LegacyBasicManualAlert: 1,
		SafetyCompanions: 3,
		FrontLineNurses: 2,
		...(getConfigurationValue(companyConfigurations[RoundingSettings.RoundingCareEvents]) && { Rounding: 4 }),
	};
	const [isLoading, setIsLoading] = useState(false);
	const [transformedHealthSystemConfigs, setTransformedHealthSystemConfigs] = useState([]);
	const [totalConfigs, setTotalConfigs] = useState(null);
	const [alertMessage, setAlertMessage] = useState(null);
	const [isCareEventModalOpen, setIsCareEventModalOpen] = useState(false);
	const [isDeleteConfigModalOpen, setIsDeleteConfigModalOpen] = useState(false);
	const [isModalLoading, setIsModalLoading] = useState(false);
	const [currentTab, setCurrentTab] = useState(Object.values(careEventType)[0]);
	const [careEventTitle, setCareEventTitle] = useState('');
	const [careEventDescription, setCareEventDescription] = useState('');
	const [careEventId, setCareEventId] = useState('');

	const healthSystemConfigHeaders = [
		{ title: 'Title', id: 'title' },
		{ title: 'Description', id: 'description' },
		{ action: '', id: 'actions' },
	];

	const fetchConfiguration = async (pageSize, pageIndex) => {
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		const response = await getCareEvents({
			pageSize: pageSize ?? props.pageSize,
			pageIndex: pageIndex ?? props.pageIndex,
			teamCareEventType: currentTab,
			...(props.selectedHealthSystem?.value !== '0' && { healthSystemId: props.selectedHealthSystem?.value }),
		});
		if (response?.error) {
			setAlertMessage(response.error.message);
		} else {
			getHealthSystemConfigs(response?.teamCareEvents);
			setTotalConfigs(response?.total);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		fetchConfiguration();
	}, [props.pageIndex, props.pageSize, props.selectedHealthSystem?.value, currentTab]);

	const resetCareEventData = () => {
		setCareEventTitle('');
		setCareEventDescription('');
		setCareEventId('');
	};

	const transformArray = array => {
		if (!array) {
			return [];
		}
		const newArray = array.map(item => ({ value: item.id, label: item.name || item.value }));
		if (getUserRole() === UserRoles.ADMIN) {
			newArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
		}
		return newArray;
	};

	const createNewCareEvent = async (name, description) => {
		const response = await createCareEvent({
			teamId: props.selectedHealthSystem?.value,
			params: {
				name,
				...(description.length > 0 && { description }),
				teamCareEventType: currentTab,
			},
		});
		if (response.error) {
			setAlertMessage(response.error.message);
		}
		fetchConfiguration();
	};

	const editCareEventById = async (id, params) => {
		const response = await editCareEvent({
			id,
			params,
		});
		if (response.error) {
			setAlertMessage(response.error.message);
			return;
		}
		fetchConfiguration();
	};

	const deleteCareEventById = async id => {
		const response = await deleteCareEvent({ id });
		if (response.error) {
			setAlertMessage(response.error.message);
			return;
		}
		fetchConfiguration();
	};

	const getHealthSystemConfigs = configurationsList => {
		if (!configurationsList?.length) {
			setTransformedHealthSystemConfigs([]);
			setIsLoading(false);
			return;
		}
		const transformConfigs = configurationsList.map(config => ({
			title: config.name,
			description: config.description,
			actions: (
				<div className='wrapped'>
					<TableEditButton
						onClick={() => {
							setCareEventTitle(config.name);
							setCareEventDescription(config.description ?? '');
							setCareEventId(config.id);
							setIsCareEventModalOpen(true);
						}}
					/>
					<TableDeleteButton
						onClick={() => {
							setCareEventId(config.id);
							setIsDeleteConfigModalOpen(true);
						}}
					/>
				</div>
			),
		}));
		setTransformedHealthSystemConfigs(transformConfigs);
		setIsLoading(false);
	};

	const toggleConfigModal = () => {
		setIsCareEventModalOpen(prevState => !prevState);
	};

	const onCareEventSave = async () => {
		if (isModalLoading) {
			return;
		}
		setIsModalLoading(true);
		if (careEventId) {
			await editCareEventById(careEventId, {
				name: careEventTitle,
				...(careEventDescription.length > 0 && { description: careEventDescription }),
				teamCareEventType: currentTab,
			});
		} else {
			await createNewCareEvent(careEventTitle, careEventDescription);
		}
		setIsCareEventModalOpen(false);
		resetCareEventData();
		setIsModalLoading(false);
	};

	return (
		<>
			<Tabs className='configs-sub-tabs' activeIndex={currentTab} onChange={setCurrentTab}>
				<TabList>
					<Tab customIndex={careEventType.SafetyCompanions}>{translate('safetyCompanions')}</Tab>
					<Tab customIndex={careEventType.FrontLineNurses}>{translate('frontlineNurses')}</Tab>
					{getConfigurationValue(companyConfigurations[RoundingSettings.RoundingCareEvents]) && (
						<Tab customIndex={careEventType.Rounding}>{translate('rounding')}</Tab>
					)}
				</TabList>
			</Tabs>
			<CustomTable
				isLoading={isLoading}
				headers={healthSystemConfigHeaders}
				rows={isLoading ? [] : transformedHealthSystemConfigs}
				headerClass='configs-table-headers'>
				<div>
					<label>{translate('selectHealthSystem')}</label>
					<Select
						value={props.selectedHealthSystem}
						placeholder={translate('all')}
						classNamePrefix={classNames('react-select', { 'react-select-disabled': isLoading })}
						options={transformArray(props.healthSystems)}
						onChange={props.setSelectedHealthSystem}
					/>
				</div>
				{props.selectedHealthSystem?.value !== '0' && <Button text={translate('createCareEvent')} onClick={toggleConfigModal} />}
			</CustomTable>
			<Pagination
				totalCount={totalConfigs}
				pageSize={props.pageSize}
				pageIndex={props.pageIndex}
				onChange={(pageSize, pageIndex) => {
					props.onPaginationChange(pageSize, pageIndex);
				}}
			/>
			<Modal
				modalSelector='deleteTeamProfileModal'
				display={isDeleteConfigModalOpen}
				position='center'
				className='make-appointment-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
				onModalSubmit={async () => {
					await deleteCareEventById(careEventId);
					setIsDeleteConfigModalOpen(false);
					resetCareEventData();
				}}
				onModalClose={() => {
					setIsDeleteConfigModalOpen(false);
					resetCareEventData();
				}}
				shouldSubmitOnEnter={false}
				primaryButtonLabel={translate('yes')}>
				<form>
					<h3>{translate('deleteConfiguration')}</h3>
					<p>{translate('areYouSureToDeleteConfig')}</p>
				</form>
			</Modal>
			<Modal
				modalSelector='deleteTeamProfileModal'
				display={isCareEventModalOpen}
				position='right'
				className='care-events-modal border-radius-modal-wrapper'
				isSubmitDisabled={careEventTitle.length === 0}
				onModalSubmit={onCareEventSave}
				isLoading={isModalLoading}
				onModalClose={() => {
					setIsCareEventModalOpen(false);
					resetCareEventData();
				}}
				shouldSubmitOnEnter={false}
				title='Create care event'>
				<form onSubmit={event => event.preventDefault()}>
					<h3>{translate('addCareEvent')}</h3>
					<Input
						type='text'
						label='Title'
						value={careEventTitle}
						validationOptions={{ maxLength: 30 }}
						placeholder='Care Event Title'
						onChange={evt => setCareEventTitle(evt.target.value)}
					/>
					<Textarea
						maxLength={400}
						maxNumberLimit={40}
						rows={8}
						value={careEventDescription}
						onChange={evt => setCareEventDescription(evt.target.value)}
						name='message'
						placeholder='Care Event Description'
						label='Description'
					/>
				</form>
			</Modal>
			<Alert display={alertMessage} fixed={true} hideCloseButton={true} message={alertMessage} variant='dark' />
		</>
	);
};

export default CareEvents;
