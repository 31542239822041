import React from 'react';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';

const CreateCompanyConfirmation = () => (
	<div className='company-confirmation'>
		<div>
			<img src={`${healthCareCdnUrl}success.svg`} alt='success icon' />
			<h3>{translate('success')}!</h3>
			<p>{translate('verificationEmailFollowInstructions')}</p>
		</div>
	</div>
);

export default CreateCompanyConfirmation;
