import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const createDepartment = async ({ healthSystemId, hospitalId, name }) => {
	try {
		const url = `/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments`;
		const { data } = await gatewayApi.post(url, {
			name,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateDepartment = async ({ healthSystemId, hospitalId, departmentId, params }) => {
	try {
		const url = `/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments/${departmentId}`;
		const { data } = await gatewayApi.put(url, params);
		return data;
	} catch (error) {
		return { error };
	}
};
