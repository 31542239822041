import React, { useEffect, useRef } from 'react';
import * as AdaptiveCards from 'adaptivecards';
import { addAlertActivity, addManualAlertActivity } from 'api/alerts.js';
import { MeasurementActivityTypes, AlertTabs } from 'constants/enums.js';
import { getUserInfo } from 'infrastructure/auth.js';
import { insertMessage } from 'api/messenger.js';
import MarkdownIt from 'markdown-it';

const ChatAdaptiveCard = ({ payload, conversationId }) => {
	const payLoadRef = useRef(payload);
	const markdownIt = useRef(new MarkdownIt());
	const cardWrapperRef = useRef(null);
	const adaptiveCardOptions = useRef({
		choiceSetInputValueSeparator: ',',
		supportsInteractivity: true,
		spacing: {
			small: 8,
			default: 12,
			medium: 16,
			large: 20,
			extraLarge: 24,
			padding: 16,
		},
		containerStyles: {
			adaptiveCard: {
				allowCustomStyle: false,
				style: { border: '1px solid black' },
			},
			default: {
				foregroundColors: {
					default: {
						accent: '#fff',
					},
					light: {
						default: '#838A94',
						subtle: '#838A94',
						accent: '#fff',
					},
				},
				backgroundColor: '#fff',
			},
		},
	});

	useEffect(() => {
		const getSelectedAction = id => payLoadRef.current.actions.find(item => item.id === id);

		const getMessageObject = content => ({
			attachments: [],
			clientMessageId: `${conversationId}_${getUserInfo().userId}_${new Date().getTime()}`,
			content,
			links: [],
			mentions: [],
		});

		const confirm = async action => {
			const selectedAction = getSelectedAction(action._propertyBag.id);
			if (!selectedAction) {
				return;
			}
			if ([AlertTabs.AI, AlertTabs.MEASUREMENT].includes(selectedAction.data.MainAlertType)) {
				const params = {
					alertId: selectedAction.data.AlertId,
					activityType: MeasurementActivityTypes.ADDED_AS_HOSPITAL_CARETAKER,
					isAiAlert: selectedAction.data.MainAlertType === AlertTabs.AI,
				};
				await addAlertActivity(params);
			} else {
				await addManualAlertActivity(selectedAction.data.AlertId, MeasurementActivityTypes.ADDED_AS_HOSPITAL_CARETAKER);
			}
			insertMessage(
				conversationId,
				getMessageObject(
					selectedAction?.data?.CaseId ? `Case: ${selectedAction?.data?.CaseId}. ${selectedAction?.title}` : selectedAction.title
				)
			);
		};

		const reject = action => {
			const selectedAction = getSelectedAction(action._propertyBag.id);
			if (!selectedAction) {
				return;
			}
			insertMessage(
				conversationId,
				getMessageObject(
					getSelectedAction(action._propertyBag.id)?.data?.CaseId
						? `Case: ${getSelectedAction(action._propertyBag.id)?.data?.CaseId}. ${
								getSelectedAction(action._propertyBag.id).title
						  }`
						: getSelectedAction(action._propertyBag.id).title
				)
			);
		};

		const actionTypes = { POSITIVE: 'positive', NEGATIVE: 'negative' };

		if (payLoadRef.current) {
			AdaptiveCards.AdaptiveCard.onProcessMarkdown = (text, result) => {
				const mdResult = result;
				mdResult.outputHtml = markdownIt.current.render(text);
				mdResult.didProcess = true;
			};
			const adaptiveCard = new AdaptiveCards.AdaptiveCard();
			adaptiveCard.hostConfig = new AdaptiveCards.HostConfig(adaptiveCardOptions.current);
			adaptiveCard.onExecuteAction = async action => {
				if (action._propertyBag.id === actionTypes.POSITIVE) {
					confirm(action);
				}
				if (action._propertyBag.id === actionTypes.NEGATIVE) {
					reject(action);
				}
			};
			adaptiveCard.parse(payLoadRef.current);
			const renderedCard = adaptiveCard.render();
			cardWrapperRef.current.appendChild(renderedCard);
		}
	}, [payload.uid, conversationId]);

	return <div ref={cardWrapperRef} />;
};

export default ChatAdaptiveCard;
