import React from 'react';

const Checkbox = ({ type = 'checkbox', name = '', id, checked = false, onChange }) => (
	<label className='checkbox' key={id}>
		<input type={type} id={id} name={name} checked={checked} onChange={onChange} />
		<span className='checkbox-name'>{name}</span>
	</label>
);

export default Checkbox;
