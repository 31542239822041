import React from 'react';
import 'views/Onboarding/css/onboarding.css';
import translate from 'i18n-translations/translate.jsx';

const UserSignUpSuccess = () => (
	<div className='patient-onboarding flex'>
		<div className='patient-box sm-box'>
			<div>
				<img src='https://static.solaborate.com/onboarding-process/patient/successful-account.svg' alt='success-account' />
			</div>
			<div>
				<h4>{translate('yourAccountHasBeenSuccessfullyCreated')}</h4>
			</div>
			<div>{translate('checkEmailToActivateAccount')}</div>
		</div>
	</div>
);
export default UserSignUpSuccess;
