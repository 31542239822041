import classNames from 'classnames';
import Badge from 'components/Badge.jsx';

const TagInput = ({ field: { name, ...field }, form: { touched, errors }, ...props }) => {
	const error = errors ? errors[name] : '';
	const touch = touched ? touched[name] : '';

	return (
		<div
			className={classNames('input', props.variant, error && touch && 'error')}
			style={{ marginBottom: props.bottomSpace, ...props.style }}>
			<p className='label'>{props.label}</p>
			{props.description && <p>{props.description}</p>}
			{props.tags && (
				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
					{props.tags.map(x => (
						<div key={x} className='ml-2'>
							<Badge
								className='flex flex-align-center'
								key={x}
								text={x}
								variant='gray'
								icon='close'
								onClick={() => {
									props.onRemoveTag(x);
								}}
							/>
						</div>
					))}
				</div>
			)}
			<input
				id={props.name ? props.name : name}
				type={props.type || 'text'}
				{...field}
				onKeyDown={props.onKeyDown}
				onKeyUp={props.onKeyUp}
				placeholder={props.placeholder}
			/>
			{error && touch && <small>{error}</small>}
		</div>
	);
};

export default TagInput;
