import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

function Notes(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
			<path
				d='M12.884 15.594l2.122 2.121m3.818-8.06l2.121 2.12m-9.334 5.092l8.627-8.627a1 1 0 011.415 0l.707.707a1 1 0 010 1.414l-8.627 8.627-2.334.212.213-2.333z'
				stroke={LightTheme.colors.grayFive}
				strokeWidth={1.5}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M21 5.4v-.2a4 4 0 00-4-4H5.8a4 4 0 00-4 4v13.6a4 4 0 004 4H17a4 4 0 004-4V15M5.4 5.4h10.8M5.4 9h7.8m-7.8 3.6h5.4'
				stroke={LightTheme.colors.grayFive}
				strokeWidth={1.5}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default Notes;
