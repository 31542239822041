const Textarea = props => (
	<div className='textarea-wrapper'>
		{props.label && <label className={props.labelClassName || ''}>{props.label}</label>}
		<div className='position-relative'>
			<textarea
				maxLength={props.maxLength}
				name={props.name}
				id={props.id}
				onChange={props.onChange}
				value={props.value}
				rows={props.rows}
				onBlur={props.onBlur}
				className={props.className}
				placeholder={props.placeholder}
				{...props}
			/>
			{props.icon && <div className='position-absolute textarea-icon'>{props.icon}</div>}
		</div>
		{props.error && (props.touched || props.validateOnSubmit) && (
			<span className='red-error red-error create-app-error'>{props.error}</span>
		)}

		{props.value && (
			<p className={props.maxLength - props.value.length <= props.maxNumberLimit ? 'red-color' : ''}>
				{props.value.length} / {props.maxLength}
			</p>
		)}
		{!props.value && <p className={props.maxLength - 0 <= props.maxNumberLimit ? 'red-color' : ''}>0 / {props.maxLength}</p>}
	</div>
);

export default Textarea;
