import translate from 'i18n-translations/translate.jsx';
import React from 'react';
import Select from 'react-select';
import { Button } from 'components/index.js';

const TVConfigFilters = props => {
	return (
		<>
			<div>
				<label>{translate('selectHospital')}</label>
				<Select
					value={props.selectedHospitalId ? props.hospitals.find(x => x.value === props.selectedHospitalId) : null}
					isDisabled={props.isHospitalDropdownDisabled}
					classNamePrefix='react-select'
					options={props.hospitals}
					onChange={props.setSelectedHospital}
				/>
			</div>
			{props.showDepartmentsFilter && (
				<div>
					<label>{translate('selectDepartment')}</label>
					<Select
						value={props.selectedDepartmentId ? props.departments?.find(x => x.value === props.selectedDepartmentId) : null}
						isDisabled={props.isDepartmentDropdownDisabled}
						classNamePrefix='react-select'
						options={props.departments}
						onChange={props.setSelectedDepartment}
					/>
				</div>
			)}
			<Button text={translate('addTVConfiguration')} onClick={props.toggleConfigModal} />
		</>
	);
};

export default TVConfigFilters;
