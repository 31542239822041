import { UserActionTypes } from 'constants/action-types.js';

export const actionCreators = {
	setConversationNotifications: notifications => async dispatch => {
		dispatch({ type: UserActionTypes.SET_USER_CONVERSATION_NOTIFICATIONS, payload: notifications });
	},
	setUserSession: session => async dispatch => {
		dispatch({ type: UserActionTypes.SET_USER_SESSION, payload: session });
	},
	setUserRoles: roles => async dispatch => {
		dispatch({ type: UserActionTypes.SET_USER_ROLES, payload: roles });
	},
	setUserPresenceStatus: status => async dispatch => {
		dispatch({ type: UserActionTypes.SET_USER_PRESENCE_STATUS, payload: status });
	},
	setDoctorData: doctor => async dispatch => {
		dispatch({ type: UserActionTypes.SET_DOCTOR_DATA, payload: doctor });
	},
	setUserWaitingRoomCount: count => dispatch => {
		dispatch({ type: UserActionTypes.SET_USER_WAITING_ROOM_COUNT, payload: count });
	},
	setUserSessionQueueCount: count => dispatch => {
		dispatch({ type: UserActionTypes.SET_SESSIONS_QUEUE_COUNT, payload: count });
	},
	setUnreadNotificationsCounter: unreadNotificationsCounter => async dispatch => {
		dispatch({ type: UserActionTypes.SET_UNREAD_NOTIFICATIONS_COUNTER, payload: unreadNotificationsCounter });
	},
	setUnreadNotificationsCounterIncrement: async dispatch => {
		dispatch({ type: UserActionTypes.SET_UNREAD_NOTIFICATIONS_COUNTER_INCREMENT });
	},
	setDarkMode: mode => dispatch => {
		dispatch({ type: UserActionTypes.SET_USER_DARK_MODE, payload: mode });
	},
	setIsAssignedToDoctors: isAssignedToDoctors => dispatch => {
		dispatch({ type: UserActionTypes.SET_ASSIGNED_TO_DOCTORS, payload: isAssignedToDoctors });
	},
	setUnreadMissedCallsNotificationsCounter: unreadNotificationsCounter => async dispatch => {
		dispatch({ type: UserActionTypes.SET_UNREAD_MISSED_CALLS_NOTIFICATIONS_COUNTER, payload: unreadNotificationsCounter });
	},
	setUnreadMissedCallsNotificationsCounterIncrement: async dispatch => {
		dispatch({ type: UserActionTypes.SET_UNREAD_MISSED_CALLS_NOTIFICATIONS_COUNTER_INCREMENT });
	},
	setUserUnitPreferences: payload => async dispatch => {
		dispatch({ type: UserActionTypes.SET_UNIT_PREFERENCES, payload });
	},
};
