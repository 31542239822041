import * as React from 'react';

const Pin = props => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			strokeWidth={1.5}
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
			height={props.height}
			viewBox='0 0 24 24'
			{...props}>
			<path
				d='M9.36364 15.6364L5 20M15.1818 5.45455L10.8182 9.81818C8.27273 8.72727 6.45455 9.81818 5 11.2727L13.7273 20C15.1818 18.5455 16.2727 16.7273 15.1818 14.1818L19.5455 9.81818M15.1818 5.45455L13.7273 4M15.1818 5.45455L19.5455 9.81818M21 11.2727L19.5455 9.81818'
				stroke='currentColor'
			/>
		</svg>
	);
};
export default Pin;
