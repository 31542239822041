import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import isCidr from 'is-cidr';
import Input from 'components/Common/FormElements/Input.jsx';
import Select from 'components/Common/FormElements/Select.jsx';
import { Form, Modal, Alert } from 'components';
import { addTeamNetworkAccessConfiguration, updateTeamNetworkAccessConfiguration } from 'api/networkConfigurations.js';
import translate from 'i18n-translations/translate.jsx';
import { HealthcareErrorCode, KeyCodes, UserRole, UserTypes } from 'constants/enums.js';
import TagInput from 'components/TagInput';

const NetworkAccessForm = props => {
	const [isSuccessfulAlertOn, setSuccessfulAlertOn] = useState(false);
	const [isErrorsAlertOn, setErrorAlertOn] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [ipAddresses, setIpAddresses] = useState([]);

	const intl = useIntl();
	const translator = id => intl.formatMessage({ id });

	const maxIpAddresses = 50;

	const roles = [
		{
			id: UserTypes.DIGITAL_CLINICIAN,
			value: UserRole.DIGITAL_CLINICIAN,
		},
		{
			id: UserTypes.DOCTOR,
			value: UserRole.DOCTOR,
		},
		{
			id: UserTypes.NURSE,
			value: UserRole.VCP,
		},
		{
			id: UserTypes.PATIENT,
			value: UserRole.PATIENT,
		},
		{
			id: UserTypes.SUPER_USER,
			value: UserRole.SUPER_USER,
		},
	];

	const isIpValid = ip => {
		if (ip && ip.trim()) {
			const isValidCidr = isCidr(ip);
			if (isValidCidr !== 0) {
				return true;
			}

			const test = /(^(\d{1,3}\.){3}(\d{1,3})$)/.test(ip);
			const ipParts = ip.split('.');
			return !(!test || ipParts.length !== 4 || ipParts.some(x => parseInt(x, 10) > 255 || parseInt(x, 10) < 0));
		}
		return false;
	};

	const isSchemaValid = ip => {
		if ((!ip || ip.trim()) && ipAddresses.length > 0) {
			return true;
		}
		if (ip && ip.trim()) {
			const isValidCidr = isCidr(ip);
			if (isValidCidr !== 0) {
				return true;
			}

			const test = /(^(\d{1,3}\.){3}(\d{1,3})$)/.test(ip);
			const ipParts = ip.split('.');
			return !(!test || ipParts.length !== 4 || ipParts.some(x => parseInt(x, 10) > 255 || parseInt(x, 10) < 0));
		}
		return false;
	};

	const handleSubmitMyForm = async (values, { setSubmitting, setFieldError }) => {
		setSubmitting(true);
		try {
			let res = null;
			const { roleId, ipAddress } = values;
			if (!props.initialValues) {
				if (ipAddress) {
					const isValid = isIpValid(ipAddress);
					if (!isValid) {
						setFieldError('ipAddress', translator('invalidIpAddress'));
						return;
					}
				}

				const ipArray = [...ipAddresses];
				if (ipAddress) {
					ipArray.push({ ipAddress: ipAddress, IsCIDR: isCidr(ipAddress) !== 0 });
				}
				res = await addTeamNetworkAccessConfiguration({ roleId: +roleId, ipAddresses: ipArray });
			} else {
				res = await updateTeamNetworkAccessConfiguration({
					id: props.initialValues.id,
					ipNetwork: { ipAddress: ipAddress, IsCIDR: isCidr(ipAddress) !== 0 },
				});
			}
			if (res.hasSucceeded) {
				setSuccessfulAlertOn(true);
				props.onSucceeded();
				setErrorText('');
				setIpAddresses([]);
			} else if (res.error?.response?.data?.code === HealthcareErrorCode.RESOURCE_EXISTS) {
				setSuccessfulAlertOn(false);
				setErrorAlertOn(true);
				setErrorText('configurationExists');
			}
		} catch (e) {
			setErrorText('');
			setErrorAlertOn(true);
		}
		setSubmitting(false);
	};

	const onCloseModal = resetForm => {
		setSuccessfulAlertOn(false);
		setErrorText('');
		setErrorAlertOn(false);
		resetForm();
		props.toggleModal();
	};

	const getValidationSchema = () => {
		const validation = {};
		if (!props.initialValues) {
			validation.roleId = Yup.string().required(translator('roleIsRequired'));
		}
		validation.ipAddress = Yup.string().test('ipAddress', translator('invalidIpAddress'), val => isSchemaValid(val));
		return validation;
	};

	const getInitialValues = () => {
		if (props.initialValues) {
			return { ...props.initialValues };
		}

		return {
			roleId: '',
			ipAddress: '',
		};
	};

	const handleDelete = tag => {
		setIpAddresses(prev => prev.filter(i => i.ipAddress !== tag));
	};

	const handleAddition = (tag, setFieldValue, setFieldError, setFieldTouched) => {
		const isValid = isIpValid(tag);
		if (!isValid) {
			setFieldError('ipAddress', translator('invalidIpAddress'));
			setFieldTouched('ipAddress', true);
			return;
		}

		const any = ipAddresses.some(x => x.ipAddress === tag);

		if (ipAddresses.length === maxIpAddresses) {
			setFieldError('ipAddress', translate('maximumIpAddresses', { value: 50 }));
			setFieldTouched('ipAddress', true);
			return;
		}

		if (!any) {
			setIpAddresses(prev => [...prev, { ipAddress: tag, IsCIDR: isCidr(tag) !== 0 }]);
		}

		setFieldValue('ipAddress', '');
		setFieldError('ipAddress', '');
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={getInitialValues()}
			validationSchema={Yup.object().shape(getValidationSchema())}
			onSubmit={handleSubmitMyForm}>
			{({ handleSubmit, isSubmitting, setFieldValue, resetForm, setFieldError, setFieldTouched }) => (
				<Modal
					modalSelector='netowrkAccessFormModal'
					display={props.isModalOpen}
					position='right'
					shouldSubmitOnEnter={false}
					onModalSubmit={handleSubmit}
					onModalClose={() => onCloseModal(resetForm)}
					isLoading={isSubmitting}>
					<Form>
						<h3>{translate('webSiteAccess')}</h3>
						{!props.initialValues ? (
							<Field
								name='roleId'
								type='select'
								label={`${intl.formatMessage({ id: 'selectRole' })}...`}
								placeholder={`${intl.formatMessage({ id: 'selectRole' })}...`}
								description={translator('applyConfig')}
								items={roles}
								component={Select}
							/>
						) : (
							<Field name='name' label={translator('role')} disabled={true} component={Input} />
						)}

						{!props.initialValues ? (
							<Field
								name='ipAddress'
								type='text'
								label={translator('setIpAddress')}
								placeholder='127.0.0.1'
								description={translator('provideCIDRRange')}
								tags={ipAddresses.map(x => x.ipAddress)}
								onRemoveTag={e => handleDelete(e)}
								onKeyDown={e => {
									if (e.which === KeyCodes.ENTER) {
										e.preventDefault();
									}
								}}
								onKeyUp={e => {
									if (e.which === KeyCodes.ENTER) {
										handleAddition(e.target.value, setFieldValue, setFieldError, setFieldTouched);
									}
								}}
								component={TagInput}
							/>
						) : (
							<Field
								name='ipAddress'
								label={translator('setIpAddress')}
								placeholder='127.0.0.1'
								description={translator('setIpAddressOrRange')}
								component={Input}
							/>
						)}

						<Alert
							alertSelector='networkAccessConfigsMessage'
							display={isSuccessfulAlertOn || isErrorsAlertOn}
							message={translator(isSuccessfulAlertOn ? 'configurationSuccessfullyAdded' : errorText || 'somethingWentWrong')}
							variant={isSuccessfulAlertOn ? 'success' : 'error'}
							onClose={() => {
								setErrorAlertOn(false);
								setErrorText('');
								setSuccessfulAlertOn(false);
							}}
						/>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

export default NetworkAccessForm;
