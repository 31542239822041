import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import translate from 'i18n-translations/translate.jsx';
import { WhiteboardFormIcon } from 'icons/Monitoring/index.js';
import {
	CareTeam,
	RoomInfo,
	ManageablePain,
	Isolations,
	CurrentPain,
	GoingHome,
	FallRisk,
	Diet,
	BradenScore,
	Activity,
	Question,
	SafetyAlerts,
	NewMedication,
	WhatMatters,
	TodaysPlan,
	PainMed,
	WhiteboardPatientInfo,
	DailyWeight,
} from 'containers/Monitoring/Whiteboard/index.js';
import { getTvWidgets, setTvWidgets } from 'api/whiteboard.js';
import { WhiteboardWidgets } from 'constants/enums.js';
import _ from 'lodash';
import { isJSON } from 'infrastructure/helpers/commonHelpers.js';
import { useSelector } from 'react-redux';
import Alert from 'components/Alert.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { dietPlans, initialWhiteboardData, whiteboardWidgetList } from 'constants/monitoring.js';
import Button from 'components/Button.jsx';

const Whiteboard = ({ deviceOwnerId, deviceId, patientName, patientAge, initExpanded = false }) => {
	const socket = useContext(SocketContext);
	const isDarkMode = useSelector(state => state.user.darkMode);
	const [expandedBox, setExpandedBox] = useState(initExpanded);
	const [isLoading, setIsLoading] = useState(false);
	const [whiteboardData, setWhiteboardData] = useState(_.cloneDeep(initialWhiteboardData));
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

	const [error, setError] = useState(null);

	const getValuesPerWidget = (data, type) => data.find(item => item.widgetType === type)?.value;

	const setValuesPerWidget = (data, type, initial) =>
		getValuesPerWidget(data, type) && isJSON(getValuesPerWidget(data, type))
			? JSON.parse(getValuesPerWidget(data, type))
			: _.cloneDeep(initial);

	const formatDietValue = (data, isObject, key) => {
		const newData = [];
		data.forEach(dt => {
			let newFormat;

			if (isObject) {
				newFormat = dietPlans.find(item => {
					return item.name === dt?.[key] || item.id.toString() === dt?.[key];
				});
			} else {
				newFormat = dietPlans.find(item => item.name === dt || item.id.toString() === dt);
			}

			if (newFormat) {
				newData.push({
					value: newFormat.id.toString(),
					comment: dt.comment,
				});
			}
		});

		return newData;
	};

	const setCorrectDietStructure = array => {
		if (array.length > 0 && array.every(item => typeof item === 'object' && item.hasOwnProperty('value'))) {
			return formatDietValue(array, true, 'value');
		} else {
			return formatDietValue(array, false);
		}
	};

	const getNewWidgetData = response => ({
		careTeam: setValuesPerWidget(response.tvWidgets, WhiteboardWidgets.CARE_TEAM, initialWhiteboardData.careTeam),
		goingHome: setValuesPerWidget(response.tvWidgets, WhiteboardWidgets.GOING_HOME, initialWhiteboardData.goingHome),
		diet: setCorrectDietStructure(setValuesPerWidget(response.tvWidgets, WhiteboardWidgets.DIET, initialWhiteboardData.diet)),
		painMed: setValuesPerWidget(response.tvWidgets, WhiteboardWidgets.PAIN_MED, initialWhiteboardData.painMed),
		activity: setValuesPerWidget(response.tvWidgets, WhiteboardWidgets.ACTIVITY, initialWhiteboardData.activity),
		fallRisk: setValuesPerWidget(response.tvWidgets, WhiteboardWidgets.FALL_RISK, initialWhiteboardData.fallRisk),
		dailyWeight: setValuesPerWidget(response.tvWidgets, WhiteboardWidgets.DAILY_WEIGHT, initialWhiteboardData.dailyWeight),
		question: setValuesPerWidget(response.tvWidgets, WhiteboardWidgets.QUESTION, initialWhiteboardData.question),
		painLevel: setValuesPerWidget(response.tvWidgets, WhiteboardWidgets.PAIN_LEVEL, initialWhiteboardData.painLevel),
		currentPainLevel: null,
		safetyAlerts: setValuesPerWidget(response.tvWidgets, WhiteboardWidgets.SAFETY_ALERTS, initialWhiteboardData.safetyAlerts),
		newMedications: setValuesPerWidget(
			response.tvWidgets,
			WhiteboardWidgets.NEW_MEDICATIONS,
			initialWhiteboardData.newMedications
		),
		whatMetters: setValuesPerWidget(response.tvWidgets, WhiteboardWidgets.WHAT_MATTERS, initialWhiteboardData.whatMetters),
		todaysPlan: setValuesPerWidget(response.tvWidgets, WhiteboardWidgets.TODAYS_PLAN, initialWhiteboardData.todaysPlan),
		bradenScore: setValuesPerWidget(response.tvWidgets, WhiteboardWidgets.BRADEN_SCORE, initialWhiteboardData.bradenScore),
	});

	useEffect(() => {
		const onTvWidgetUpdated = data => {
			if (data.deviceId !== deviceId) {
				return;
			}
			setIsLoading(true);
			const updatedWidget = whiteboardWidgetList.find(item => item.id === data.tvWidgets?.[0]?.widgetType);
			if (updatedWidget && data.tvWidgets?.[0]?.value && isJSON(data.tvWidgets?.[0].value)) {
				const name = updatedWidget.name;
				setWhiteboardData({ ...whiteboardData, [name]: JSON.parse(data.tvWidgets?.[0].value) });
			}
			setIsLoading(false);
		};
		socket.on(SocketEvents.HealthCare.ON_TV_WIDGETS_UPDATED, onTvWidgetUpdated);

		return () => {
			socket.off(SocketEvents.HealthCare.ON_TV_WIDGETS_UPDATED, onTvWidgetUpdated);
		};
	}, [deviceId, socket, whiteboardData]);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const response = await getTvWidgets(deviceOwnerId);
			if (response.error) {
				setError(translate('somethingWentWrong'));
				setIsLoading(false);
				return;
			}
			setWhiteboardData(getNewWidgetData(response));
			setIsLoading(false);
		};

		if (deviceOwnerId) {
			fetchData();
		}
	}, [deviceOwnerId]);

	const setWidgetData = async (type, values) => {
		setIsSubmitButtonLoading(true);
		const params = {
			TvWidgets: [
				{
					Value: JSON.stringify(values),
					WidgetType: type,
					isManuallyAdded: true,
				},
			],
		};
		const response = await setTvWidgets(deviceOwnerId, params);
		if (response.error) {
			setError(translate('somethingWentWrong'));
			setIsSubmitButtonLoading(false);
			return false;
		}
		setIsSubmitButtonLoading(false);
		return true;
	};

	const updateWidgetData = ({ widgetType, values }) => {
		let widgetToUpdate = '';
		switch (widgetType) {
			case WhiteboardWidgets.CARE_TEAM:
				widgetToUpdate = 'careTeam';
				break;
			case WhiteboardWidgets.PAIN_LEVEL:
				widgetToUpdate = 'painLevel';
				break;
			case WhiteboardWidgets.GOING_HOME:
				widgetToUpdate = 'goingHome';
				break;
			case WhiteboardWidgets.FALL_RISK:
				widgetToUpdate = 'fallRisk';
				break;
			case WhiteboardWidgets.DIET:
				widgetToUpdate = 'diet';
				break;
			case WhiteboardWidgets.BRADEN_SCORE:
				widgetToUpdate = 'bradenScore';
				break;
			case WhiteboardWidgets.ACTIVITY:
				widgetToUpdate = 'activity';
				break;
			case WhiteboardWidgets.PAIN_MED:
				widgetToUpdate = 'painMed';
				break;
			case WhiteboardWidgets.QUESTION:
				widgetToUpdate = 'question';
				break;
			case WhiteboardWidgets.SAFETY_ALERTS:
				widgetToUpdate = 'safetyAlerts';
				break;
			case WhiteboardWidgets.NEW_MEDICATIONS:
				widgetToUpdate = 'newMedications';
				break;
			case WhiteboardWidgets.TODAYS_PLAN:
				widgetToUpdate = 'todaysPlan';
				break;
			case WhiteboardWidgets.WHAT_MATTERS:
				widgetToUpdate = 'whatMetters';
				break;
			case WhiteboardWidgets.DAILY_WEIGHT:
				widgetToUpdate = 'dailyWeight';
				break;
			default:
				widgetToUpdate = '';
		}

		if (widgetToUpdate && setWidgetData(widgetType, values)) {
			setWhiteboardData(prevState => ({
				...prevState,
				[widgetToUpdate]: values,
			}));
		}
	};

	return (
		<>
			<div className={classNames('monitoring-timeline-box whiteboard-wrapper', { 'white-mode-whiteboard': !isDarkMode })}>
				<div className={classNames('timeline-box-header', { expanded: expandedBox })}>
					<p className='timeline-box-title'>
						<WhiteboardFormIcon height={24} width={24} color='#fff' />
						{translate('whiteboard')}
					</p> 
					<div className='timeline-box-actions'>
						<Button onClick={() => setExpandedBox(prevState => !prevState)} icon={expandedBox ? 'expand_less' : 'expand_more'} />
					</div>
				</div>
				{expandedBox && (
					<div className={classNames('timeline-box-content', { expanded: expandedBox, 'loading-whiteboard-data': isLoading })}>
						<div className='patient-informations'>
							{isLoading && (
								<Grid columns='1fr' rows='1fr' horizAlign='center' vertAlign='center'>
									<Loader />
								</Grid>
							)}
							{!isLoading && (
								<>
									{patientName && <WhiteboardPatientInfo patientName={patientName} patientAge={patientAge} />}
									<CareTeam
										data={whiteboardData.careTeam}
										setData={values =>
											updateWidgetData({
												widgetType: WhiteboardWidgets.CARE_TEAM,
												values,
											})
										}
										isSubmitButtonLoading={isSubmitButtonLoading}
									/>
									<RoomInfo />
									<ManageablePain
										data={whiteboardData.painLevel}
										setData={values =>
											updateWidgetData({
												widgetType: WhiteboardWidgets.PAIN_LEVEL,
												values,
											})
										}
										isDarkMode={isDarkMode}
									/>
									<CurrentPain deviceOwnerId={deviceOwnerId} isDarkMode={isDarkMode} deviceId={deviceId} />
									<GoingHome
										data={whiteboardData.goingHome}
										isDarkMode={isDarkMode}
										setData={values =>
											updateWidgetData({
												widgetType: WhiteboardWidgets.GOING_HOME,
												values,
											})
										}
										isSubmitButtonLoading={isSubmitButtonLoading}
									/>
									<FallRisk
										fallRisk={whiteboardData.fallRisk}
										isDarkMode={isDarkMode}
										setData={values =>
											updateWidgetData({
												widgetType: WhiteboardWidgets.FALL_RISK,
												values,
											})
										}
									/>
									<DailyWeight
										dailyWeight={whiteboardData.dailyWeight}
										isDarkMode={isDarkMode}
										setData={values =>
											updateWidgetData({
												widgetType: WhiteboardWidgets.DAILY_WEIGHT,
												values,
											})
										}
									/>
									<Diet
										diet={whiteboardData.diet}
										setData={values =>
											updateWidgetData({
												widgetType: WhiteboardWidgets.DIET,
												values,
											})
										}
										isSubmitButtonLoading={isSubmitButtonLoading}
									/>
									<BradenScore
										data={whiteboardData.bradenScore}
										isDarkMode={isDarkMode}
										setData={data => {
											const bradenScore = {
												sensory: data.sensory.value,
												skin: data.skin.value,
												activity: data.activity.value,
												nutrition: data.nutrition.value,
												mobility: data.mobility.value,
												friction: data.friction.value,
											};

											updateWidgetData({
												widgetType: WhiteboardWidgets.BRADEN_SCORE,
												values: bradenScore,
											});
										}}
										isSubmitButtonLoading={isSubmitButtonLoading}
									/>
									<Activity
										data={whiteboardData.activity}
										setData={values =>
											updateWidgetData({
												widgetType: WhiteboardWidgets.ACTIVITY,
												values,
											})
										}
										isSubmitButtonLoading={isSubmitButtonLoading}
									/>
									<PainMed
										data={whiteboardData.painMed}
										setData={values =>
											updateWidgetData({
												widgetType: WhiteboardWidgets.PAIN_MED,
												values,
											})
										}
										isSubmitButtonLoading={isSubmitButtonLoading}
									/>
									<Question
										data={whiteboardData.question}
										setData={values =>
											updateWidgetData({
												widgetType: WhiteboardWidgets.QUESTION,
												values,
											})
										}
										isSubmitButtonLoading={isSubmitButtonLoading}
									/>
									<SafetyAlerts
										data={whiteboardData.safetyAlerts}
										setData={values =>
											updateWidgetData({
												widgetType: WhiteboardWidgets.SAFETY_ALERTS,
												values,
											})
										}
										isSubmitButtonLoading={isSubmitButtonLoading}
									/>
									<NewMedication
										data={whiteboardData.newMedications}
										setData={values =>
											updateWidgetData({
												widgetType: WhiteboardWidgets.NEW_MEDICATIONS,
												values,
											})
										}
										isSubmitButtonLoading={isSubmitButtonLoading}
									/>
									<WhatMatters
										data={whiteboardData.whatMetters}
										setData={values =>
											updateWidgetData({
												widgetType: WhiteboardWidgets.WHAT_MATTERS,
												values,
											})
										}
										isSubmitButtonLoading={isSubmitButtonLoading}
									/>
									<TodaysPlan
										data={whiteboardData.todaysPlan}
										setData={values =>
											updateWidgetData({
												widgetType: WhiteboardWidgets.TODAYS_PLAN,
												values,
											})
										}
										isSubmitButtonLoading={isSubmitButtonLoading}
									/>
									<Isolations deviceOwnerId={deviceOwnerId} deviceId={deviceId} />
								</>
							)}
						</div>
					</div>
				)}
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default Whiteboard;
