import classNames from 'classnames';
import { buildProfilePicUrl, onErrorInitialsSrc } from 'infrastructure/helpers/thumbnailHelper.js';
import { getInitialsColor } from 'infrastructure/helpers/commonHelpers.js';

const ProfilePicture = props => {
	let firstNameLetter = '';
	let lastNameLetter = '';
	let initialsColor = null;
	const getFirstLetterLastName = string => (string.split(' ')[1] ? string.split(' ')[1][0] : string.split(' ')[0][0]);

	if (props.fullName || (props.firstName && props.lastName)) {
		firstNameLetter = props.fullName ? props.fullName[0] : props.firstName[0];
		lastNameLetter = props.fullName ? getFirstLetterLastName(props.fullName) : props.lastName[0];
		initialsColor = getInitialsColor(firstNameLetter);
	}

	const defaultPicture = 'duser.jpg';

	return (
		<div
			className={classNames(
				'profile-picture-wrapper',
				props.className,
				props.isActive ? 'active' : '',
				props.profilePicture && props.profilePicture !== defaultPicture ? 'profile-picture-exists' : ''
			)}>
			{firstNameLetter && lastNameLetter && (
				<div
					className={`chat-list-item-initials${
						props.profilePicture && props.profilePicture !== defaultPicture ? '' : ' no-profile-pic'
					}`}
					style={{
						backgroundColor: `#${initialsColor.backgroundColor}`,
						color: `#${initialsColor.color}`,
					}}>
					{firstNameLetter}
					{lastNameLetter}
				</div>
			)}

			{props.profilePicture && props.profilePicture !== defaultPicture && (
				<img
					style={{ position: 'relative', minHeight: '100%' }}
					onError={e => onErrorInitialsSrc(e)}
					src={buildProfilePicUrl(props.profilePicture, !props.dynamicSize ? 150 : null)}
					alt='profile-img'
				/>
			)}
		</div>
	);
};
export default ProfilePicture;
