import { AiFieldToUpdate } from 'constants/ai.js';
import { PatientAiSetting } from 'constants/enums.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const AiVoiceAnalysisEvents = ({ toggleAiSettings, aiSettingList, deviceId, aiVoiceEventOptions }) => {
	const intl = useIntl();
	const [selectedAiVoiceEvents, setSelectedAiVoiceEvents] = useState([]);
	const [isAiVoiceEventUpdated, setIsAiVoiceEventUpdated] = useState(false);

	useEffect(() => {
		const foundDevice = aiSettingList.find(item => item.deviceId === deviceId);
		if (!foundDevice) {
			return;
		}

		const handleSetSelectedAiVoiceEvents = foundSettings => {
			const foundAiVoiceEvents = foundSettings.find(item => item.settingTypeId === PatientAiSetting.AI_VOICE_ANALYSIS)?.value;
			if (foundAiVoiceEvents) {
				const onMountAiVoiceEvents = aiVoiceEventOptions.filter(voiceType => foundAiVoiceEvents.includes(voiceType.value));
				setSelectedAiVoiceEvents(onMountAiVoiceEvents);
			} else {
				setSelectedAiVoiceEvents([]);
			}
		};

		handleSetSelectedAiVoiceEvents(foundDevice.settings);
	}, [aiSettingList, deviceId, aiVoiceEventOptions]);

	const isAiVoiceEventChecked = value => selectedAiVoiceEvents.some(item => item.value === value);

	const handleCheckboxChange = value => {
		setSelectedAiVoiceEvents(prevCheckedItems => {
			const isChecked = prevCheckedItems.some(item => item.value === value);
			if (isChecked) {
				return prevCheckedItems.filter(item => item.value !== value);
			}
			return [...prevCheckedItems, { value }];
		});
		setIsAiVoiceEventUpdated(prevState => !prevState);
	};

	useEffect(() => {
		if (selectedAiVoiceEvents.length === 0) {
			return;
		}
		const mappedValues = selectedAiVoiceEvents.map(item => +item.value);

		toggleAiSettings({
			settingTypeId: PatientAiSetting.AI_VOICE_ANALYSIS,
			customValue: JSON.stringify(mappedValues),
			fieldToUpdate: AiFieldToUpdate.VALUE,
		});
	}, [isAiVoiceEventUpdated]);

	return (
		<div className='doctor-profile-settings ai-configurations no-margin'>
			<p className='semi-bold font-14'>{intl.formatMessage({ id: 'sendAiVoiceAlert' })}</p>
			<div className='flex flex-wrap margin-top-m no-border-bottom'>
				{aiVoiceEventOptions.map(aiVoiceEvent => (
					<div className='register-practice-modal-grid tick-box-active-input' key={aiVoiceEvent.value}>
						<label className='flex remember-me cursor-pointer'>
							<input
								type='checkbox'
								name={aiVoiceEvent.label}
								checked={isAiVoiceEventChecked(aiVoiceEvent.value)}
								onChange={() => handleCheckboxChange(aiVoiceEvent.value)}
							/>
							<div className='onb-custom-checkbox-wrapper cursor-pointer top-0 ai-configurations-checkbox'>
								<div className='onb-custom-checkbox' />
							</div>
							<span className='font-14 left-s margin-right-xxl'>{aiVoiceEvent.label}</span>
						</label>
					</div>
				))}
			</div>
		</div>
	);
};

export default AiVoiceAnalysisEvents;
