import React, { useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { TestTypes } from 'constants/visitEnums.js';
import { primaryCareImageUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { handleOnKeyDownNumeric } from 'infrastructure/helpers/commonHelpers.js';
import Button from 'components/Button.jsx';

const ResultAccordion = props => {
	const { item } = props;
	const [showContent, setShowContent] = useState(false);
	const intl = useIntl();

	return (
		<>
			<div className='flex flex-wrap'>
				<div className='flex-1 flex'>
					<div>
						<p>{item.patientName}</p>
						<span>{item.patientIdCard}</span>
					</div>
				</div>
				<div className='flex-1'>
					<p>{item.lastVisit}</p>
					<p>{formattedDate(item.dateCreated)}</p>
				</div>
				<div className='flex-1'>
					<p>{item.tests.length}</p>
				</div>

				<div className='flex-1'>
					<Button
						onClick={() => setShowContent(prevContentState => !prevContentState)}
						className='margin-left-auto'
						icon={showContent ? 'keyboard_arrow_right' : 'keyboard_arrow_down'}
					/>
				</div>
				{showContent && item.tests.length > 0 && (
					<div className='full-width results-accordion-content'>
						<h3>{translate('addResults')}</h3>
						<div />

						{item.tests.map(el => {
							const selectedAnalysis = item.forms.find(analysis => analysis.id === el);
							return (
								<React.Fragment key={selectedAnalysis.id}>
									<div className='flex flex-align-center'>
										<div>
											<img src={`${primaryCareImageUrl}${selectedAnalysis.icon}`} alt='icon' />
										</div>
										<div>
											<p>{selectedAnalysis.description}</p>
										</div>
									</div>
									<div className={classNames('flex flex-wrap', selectedAnalysis.id === TestTypes.URINE ? 'urine-results' : '')}>
										{selectedAnalysis.results.map((sub, index) => (
											<div className='flex flex-align-center' style={{ marginTop: '5px' }} key={sub.id}>
												<p className='flex-1'>{sub.description}</p>
												{selectedAnalysis.id === TestTypes.URINE && !sub.isTextarea && (
													<select
														name={sub.description}
														onChange={event => props.handleResultChangeString(event.target.value, selectedAnalysis, index, item)}>
														<option value=''>{intl.formatMessage({ id: 'select' })}</option>
														{sub.children.map(child => (
															<option key={child.id} value={child.value}>
																{child.description}
															</option>
														))}
													</select>
												)}

												{selectedAnalysis.id === TestTypes.URINE && sub.isTextarea && (
													<textarea
														name={sub.description}
														value={sub.value}
														rows={3}
														placeholder={intl.formatMessage({ id: 'description' })}
														onChange={event => props.handleResultChangeString(event.target.value, selectedAnalysis, index, item)}
													/>
												)}
												{selectedAnalysis.id !== TestTypes.URINE && (
													<>
														<input
															name={sub.description}
															type='number'
															value={sub.value}
															onChange={event => props.handleResultChange(event.target.value, selectedAnalysis, index, item, sub)}
															onKeyDown={event => handleOnKeyDownNumeric(event, true)}
															step='0.01'
															placeholder={`${sub.min} - ${sub.max}`}
														/>
														<span>{sub.unit || ''}</span>
													</>
												)}
											</div>
										))}
									</div>
								</React.Fragment>
							);
						})}

						<Button
							type='button'
							onClick={() => props.saveAnalysis(item)}
							className={classNames('no-border', !props.canEnterResults(item) ? 'disabled' : '')}
							text={translate('enterResults')}
							isDisabled={props.isSaveAnalysisLoading === item.medicalVisitId}
							isLoading={props.isSaveAnalysisLoading === item.medicalVisitId}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default ResultAccordion;
