import { gatewayFileUploadApi } from 'constants/global-variables.js';

export const uploadProfilePic = async formData => {
	try {
		const { data } = await gatewayFileUploadApi.post(`/v1/media/pictures/profile`, formData);
		return data;
	} catch (error) {
		return { error };
	}
};

export const uploadAttachment = async formData => {
	try {
		const { data } = await gatewayFileUploadApi.post(`/v1.1/media/attachments`, formData);
		return data;
	} catch (error) {
		return { error };
	}
};
