import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { getLatestManualAlerts, saveManualAlert } from 'api/alerts.js';
import ReactSelect from 'react-select';
import { OutOfRoomEnums } from 'constants/enums.js';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import { PatientOutOfRoomIcon } from 'icons/Monitoring/index.js';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';
import { getDeviceOwnerPatient } from 'api/patients.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { isJSON } from 'infrastructure/helpers/commonHelpers.js';
import { getMonitoringSettingOptions } from 'api/adminConfigurations.js';
import { MonitoringSettings } from 'constants/configurationEnums.js';

const PatientOutOfRoom = ({ patientId, feed, patientOutOfRoomCareEvent, patientReturnedCareEvent, conferenceId }) => {
	const intl = useIntl();
	const otherOption = 'Other';
	const socket = useContext(SocketContext);

	const user = useSelector(state => state.user);
	const [expandedBox, setExpandedBox] = useState(true);
	const [selectedReason, setSelectedReason] = useState(null);
	const [outOfTheRoomOptions, setOutOfTheRoomOptions] = useState([]);
	const [error, setError] = useState(null);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);

	const [noteValue, setNoteValue] = useState('');
	const patientNotes = useSelector(state => state.patientNotes.savedMode);
	const dispatch = useDispatch();

	useEffect(() => {
		const fetchOptions = async () => {
			const response = await getMonitoringSettingOptions(feed?.healthSystemId, MonitoringSettings.PatientOutOfRoom);
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			const mapped = response.teamSettingOptions.map(item => ({
				...item,
				id: item.id,
			}));
			mapped.push({
				id: 'Other',
				option: 'Other',
			});
			setOutOfTheRoomOptions(mapped);
		};

		if (feed?.healthSystemId) {
			fetchOptions();
		}
	}, [intl, feed?.healthSystemId]);

	useEffect(() => {
		const getPatientOutOfRoomStatus = async () => {
			const names = [OutOfRoomEnums.PATIENT_OUT_OF_ROOM, OutOfRoomEnums.PATIENT_RETURNED_TO_ROOM];
			let existingPatientId = patientId;
			if (!patientId) {
				const deviceOwnerResponse = await getDeviceOwnerPatient(feed.deviceId);
				if (deviceOwnerResponse.error) {
					setError(deviceOwnerResponse.error.message);
					return;
				}
				existingPatientId = deviceOwnerResponse?.healthcareUserId;
			}
			const response = await getLatestManualAlerts(existingPatientId, feed.deviceId, names);
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			const outOfRoom = response.devicePatientLatestManualAlerts.find(
				item => item.typeName.toLowerCase() === OutOfRoomEnums.PATIENT_OUT_OF_ROOM.toLowerCase()
			);
			const backToRoom = response.devicePatientLatestManualAlerts.find(
				item => item.typeName.toLowerCase() === OutOfRoomEnums.PATIENT_RETURNED_TO_ROOM.toLowerCase()
			);
			if (
				(outOfRoom && !backToRoom) ||
				(outOfRoom && backToRoom && new Date(outOfRoom.dateCreated) > new Date(backToRoom.dateCreated))
			) {
				const comment = outOfRoom.properties && isJSON(outOfRoom.properties) ? JSON.parse(outOfRoom.properties).Comment : '';

				if (outOfTheRoomOptions.find(item => item.option === comment)) {
					setSelectedReason({
						label: comment,
						value: outOfTheRoomOptions.find(item => item.option === comment)?.id,
					});
					setNoteValue('');
					return;
				}
				setSelectedReason({
					label: intl.formatMessage({ id: 'other' }),
					value: otherOption,
				});
				setNoteValue(comment);
			}
		};

		getPatientOutOfRoomStatus();
	}, [feed.deviceId, intl, patientId, outOfTheRoomOptions]);

	useEffect(() => {
		const handleAlertsResponse = async data => {
			if (feed.deviceId !== data.deviceId) {
				return;
			}
			const { isDraft, comment } = data;
			const isPatientOutOfRoom = data.alertTypeName?.toLowerCase() === OutOfRoomEnums.PATIENT_OUT_OF_ROOM.toLowerCase();
			if (isDraft && isPatientOutOfRoom) {
				if (comment && outOfTheRoomOptions.find(item => item.option === comment)) {
					setSelectedReason({
						label: comment,
						value: outOfTheRoomOptions.find(item => item.option === comment)?.id,
					});
					setNoteValue('');
					return;
				}
				setSelectedReason({
					label: intl.formatMessage({ id: 'other' }),
					value: otherOption,
				});
				setNoteValue(comment);
			}
		};

		socket.on(SocketEvents.HealthCare.ALERTS_MANUAL_ADDED, handleAlertsResponse);
		return () => {
			socket.off(SocketEvents.HealthCare.ALERTS_MANUAL_ADDED, handleAlertsResponse);
		};
	}, [feed.deviceId, intl, patientId, socket, outOfTheRoomOptions]);

	const togglePatientOutofRoom = async () => {
		setIsSubmitLoading(true);
		if (!selectedReason) {
			setError(intl.formatMessage({ id: 'selectReasonPlease' }));
			setIsSubmitLoading(false);
			return;
		}
		let existingPatientId = patientId;
		if (!patientId) {
			const deviceOwnerResponse = await getDeviceOwnerPatient(feed.deviceId);
			if (deviceOwnerResponse.error) {
				setError(deviceOwnerResponse.error.message);
				setIsSubmitLoading(false);
				return;
			}
			existingPatientId = deviceOwnerResponse?.healthcareUserId;
		}
		const dataToSend = {
			patientId: existingPatientId,
			deviceId: feed.deviceId,
			manualAlertTypeId: patientNotes[feed.deviceId].isPatientOutOfRoom
				? patientReturnedCareEvent?.id
				: patientOutOfRoomCareEvent?.id,
			comment: selectedReason.value === otherOption ? noteValue : selectedReason.label,
			isDraft: !patientNotes[feed.deviceId].isPatientOutOfRoom,
			conferenceId,
		};
		const response = await saveManualAlert(dataToSend);
		if (response.error) {
			setError(response.error.message);
			setIsSubmitLoading(false);
			return;
		}
		dispatch(
			patientNotesActionCreators.setPatientDataSaved(
				{
					...patientNotes[feed.deviceId],
					isPatientOutOfRoom: !patientNotes[feed.deviceId].isPatientOutOfRoom,
					outOfRoomReason: selectedReason.label,
					draftsCount: patientNotes[feed.deviceId].isPatientOutOfRoom
						? patientNotes[feed.deviceId].draftsCount
						: patientNotes[feed.deviceId].draftsCount + 1,
				},
				feed.deviceId
			)
		);
		setError(null);
		setIsSubmitLoading(false);
	};

	useEffect(() => {
		setError(null);
	}, [selectedReason]);

	return (
		<>
			<div className='monitoring-timeline-box patient-out-of-room'>
				<div className={classNames('timeline-box-header', expandedBox ? 'expanded' : '')}>
					<p className='timeline-box-title flex-1'>
						<PatientOutOfRoomIcon />
						{translate('patientOutOfRoom')}
					</p>
					<div className='timeline-box-actions'>
						<Button
							border='none'
							style={{ top: 0 }}
							onClick={() => setExpandedBox(prevState => !prevState)}
							icon={expandedBox ? 'expand_less' : 'expand_more'}
						/>
					</div>
				</div>
				{expandedBox && (
					<div className={classNames('timeline-box-content', expandedBox ? ' expanded' : '')}>
						<div className='flex flex-align-center'>
							<ReactSelect
								options={outOfTheRoomOptions.map(item => ({
									label: item.option,
									value: item.id,
								}))}
								value={selectedReason}
								onChange={item => setSelectedReason(item)}
								id='select'
								className='select-voice-announcements'
								classNamePrefix='react-select'
								styles={generateCustomStyles({ darkMode: user.darkMode, menuListMaxHeight: 175, menuMaxHeight: 175 })}
								isDisabled={patientNotes[feed.deviceId]?.isPatientOutOfRoom}
							/>
							<div
								className={classNames('toggle-switch', {
									disabled: isSubmitLoading,
								})}
								onClick={togglePatientOutofRoom}>
								<input type='checkbox' checked={patientNotes[feed.deviceId]?.isPatientOutOfRoom} onChange={() => null} />
								<span className='toggle-body'>
									<span className='on-text'>{translate('on')}</span>
									<span className='off-text'>{translate('off')}</span>
								</span>
							</div>
						</div>
						{error && <p className='error'>{error}</p>}
						{selectedReason?.value === otherOption && (
							<textarea
								className='text-input'
								onChange={event => setNoteValue(event.target.value)}
								name='note'
								rows={3}
								value={noteValue}
								placeholder={intl.formatMessage({ id: 'addAdditionalNotes' })}
								style={{ fontSize: '14px' }}
							/>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default PatientOutOfRoom;
