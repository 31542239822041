import React, { useContext, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actionCreators as patientsActionCreators } from 'state/patients/actions.js';
import PatientMain from 'views/Patients/Partials/PatientMain.jsx';
import ChatList from 'views/Partials/ChatList.jsx';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { dissociateUserById } from 'api/users.js';
import Alert from 'components/Alert.jsx';
import { UserStatus } from 'constants/enums.js';
import { getMyPatients } from 'api/patients.js';

const Patients = props => {
	const match = { props };
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [pageIndex, setPageIndex] = useState(0);
	const [isScrollLoading, setIsScrollLoading] = useState(true);
	const [searchValue, setSearchValue] = useState('');
	const [doctorDissociated, setDoctorDissociated] = useState(false);
	const socket = useContext(SocketContext);
	const history = useHistory();
	const dispatch = useDispatch();
	const setSelectedPatient = selectedPatient => dispatch(patientsActionCreators.setSelectedPatient(selectedPatient));
	const hasReachedEnd = useRef(null);

	const patientSelector = useSelector(state => ({
		patients: state.myPatients.patients,
		selectedPatient: state.myPatients.selectedPatient,
	}));

	useEffect(() => {
		const onUserUpdatedPresence = ({ objectId, presenceStatusTypeId }) => {
			updatePatientPresence(objectId, presenceStatusTypeId);
		};

		const onUserOnlineStatus = ({ userId }) => {
			updatePatientAvailability(userId, UserStatus.ONLINE);
		};

		const onUserOfflineStatus = ({ userId }) => {
			updatePatientAvailability(userId, UserStatus.OFFLINE);
		};

		const onDoctorPatientDissociate = () => {
			setDoctorDissociated(prevState => prevState);
		};

		socket.on(SocketEvents.Client.ON_USER_ONLINE, onUserOnlineStatus);
		socket.on(SocketEvents.Client.ON_USER_OFFLINE, onUserOfflineStatus);
		socket.on(SocketEvents.Client.ON_NOTIFY_UPDATED_USER_PRESENCE, onUserUpdatedPresence);
		socket.on(SocketEvents.HealthCare.DOCTOR_PATIENT_DISSOCIATED, onDoctorPatientDissociate);

		return () => {
			socket.off(SocketEvents.Client.ON_NOTIFY_UPDATED_USER_PRESENCE, onUserUpdatedPresence);
			socket.off(SocketEvents.HealthCare.DOCTOR_PATIENT_DISSOCIATED, onDoctorPatientDissociate);
			socket.off(SocketEvents.Client.ON_USER_ONLINE, onUserOnlineStatus);
			socket.off(SocketEvents.Client.ON_USER_OFFLINE, onUserOfflineStatus);
		};
	}, [socket, dispatch]);

	useEffect(() => {
		const fetchPatients = async () => {
			const response = await getMyPatients({ pageIndex: searchValue ? 0 : pageIndex, search: searchValue });
			if (response.error) {
				setError(response.error.message);
			} else {
				const updatedPatients = searchValue ? response : [...new Set([...patientSelector.patients, ...response])];
				dispatch(patientsActionCreators.setPatients(updatedPatients));
				hasReachedEnd.current = response.length < 20;
			}
			setIsScrollLoading(false);
			setIsLoading(false);
		};
		const timeoutId = setTimeout(fetchPatients, 500);

		return () => clearTimeout(timeoutId);
	}, [searchValue, pageIndex, doctorDissociated]);

	const updatePatientPresence = (objectId, presenceStatusTypeId) => {
		const patients = [...patientSelector.patients];
		const foundPatient = patients.find(patient => patient.userId === parseInt(objectId, 10));
		if (foundPatient) {
			foundPatient.presenceStatusTypeId = presenceStatusTypeId;
			dispatch(patientsActionCreators.setPatients(patients));
		}
	};

	const updatePatientAvailability = (userId, availability) => {
		const patients = [...patientSelector.patients];
		const foundPatient = patients.find(patient => patient.userId === userId);
		if (foundPatient) {
			foundPatient.availability = availability;
			dispatch(patientsActionCreators.setPatients(patients));
		}
	};

	const onChatListItemClicked = patient => {
		setSelectedPatient(patient);
		history.push(`/patients/${patient.userId}`);
	};

	const toggleRemoveConnectionDropdown = id => {
		const patients = [...patientSelector.patients];
		const selectedPatient = patients.find(item => item.id === id);
		if (selectedPatient) {
			selectedPatient.isConnectionDropdownVisible = !selectedPatient.isConnectionDropdownVisible;
		}
		dispatch(patientsActionCreators.setPatients(patients));
	};

	const removeConnection = async id => {
		setIsLoading(true);
		toggleRemoveConnectionDropdown(id);
		const response = await dissociateUserById(id);
		if (response.error) {
			setError(response.error.message);
		} else {
			const patients = [...patientSelector.patients];
			const newPatients = patients.filter(item => item.id !== id);
			const selectedPatient = newPatients.length > 0 ? newPatients[0] : null;
			dispatch(patientsActionCreators.setPatients(newPatients));
			setSelectedPatient(selectedPatient);
			const url = newPatients.length > 0 ? `/patients/${selectedPatient.userId}` : `/patients`;
			history.push(url);
		}
		setIsLoading(false);
	};

	const handleScroll = async event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !isScrollLoading && !hasReachedEnd.current && event.target.scrollTop !== 0) {
			setIsScrollLoading(true);
			setPageIndex(prevState => prevState + 1);
		}
	};

	useEffect(() => {
		if (match.path === '/patients/:deviceId' || match.path === '/patients/:deviceId/:tab/:subTab') {
			const findPatient = patientSelector.patients.find(patient => patient.userId === Number(match.params.deviceId));
			if (findPatient) {
				dispatch(patientsActionCreators.setSelectedPatient(findPatient));
			}
		}
	}, [dispatch, match, patientSelector.patients]);

	return (
		<MainLayout showWarningAlert={true}>
			{patientSelector.patients && (
				<Grid columns='1fr 3fr' stretch='100%'>
					<aside className='patients-list'>
						{!isLoading && (
							<ChatList
								removeConnection={removeConnection}
								onChatListItemClicked={onChatListItemClicked}
								isDoctorView={true}
								selectedPatient={patientSelector.selectedPatient}
								patients={patientSelector.patients}
								handleScroll={handleScroll}
								isScrollLoading={isScrollLoading}
								setSearchValue={setSearchValue}
								searchValue={searchValue}
							/>
						)}
						{isLoading && (
							<div className='patient-request-loader'>
								<Loader />
							</div>
						)}
					</aside>
					<main className='main-view'>
						<div className='flex full-height'>{patientSelector.selectedPatient && <PatientMain />}</div>
					</main>
				</Grid>
			)}
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
		</MainLayout>
	);
};

export default Patients;
