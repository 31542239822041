import React, { useState, useEffect } from 'react';
import { getMedicalInfo } from 'api/medicalInfo.js';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import Loader from 'components/Loader.jsx';
import { getUserId } from 'infrastructure/auth.js';
import Modal from 'components/Modal.jsx';
import Grid from 'components/Grid.jsx';
import EmptyState from 'components/EmptyState.jsx';
import { MedicalInfoTypes } from 'constants/enums.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import Pagination from 'components/Common/Pagination.jsx';

const Notes = props => {
	const [notes, setNotes] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [selectedNote, setSelectedNote] = useState({ title: '', text: '' });

	useEffect(() => {
		setIsLoading(true);
		const getNotes = async (pageSize, pageIndex) => {
			const params = {
				pageSize,
				pageIndex,
				userId: getUserId(),
				type: MedicalInfoTypes.NOTES,
				patientId: props.patientId || null,
			};
			const response = await getMedicalInfo(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setNotes(response.notes);
			}
			setIsLoading(false);
			setPagination(prevState => ({ ...prevState, totalCount: response.totalCount }));
		};
		getNotes(pagination.pageSize, pagination.pageIndex);
	}, [pagination.pageIndex, pagination.pageSize, props.patientId]);

	const onPageChange = async (pageSize, pageIndex) => {
		setPagination(prevState => ({ ...prevState, pageSize, pageIndex }));
	};

	return (
		<>
			{!isLoading && (
				<>
					{notes.length > 0 && (
						<div className='patient-summary-wrapper'>
							<h3>{translate('notes')}</h3>
							<div className='patient-summary-table-wrapper'>
								<table cellPadding='0' cellSpacing='0'>
									<thead>
										<tr>
											<th>{translate('title')}</th>
											<th>{translate('dateAdded')}</th>
											<th>{translate('doctor')}</th>
										</tr>
									</thead>
									<tbody>
										{notes.map(note => (
											<tr key={note.id} onClick={() => setSelectedNote(note)} className='cursor-pointer'>
												<td>{note.title}</td>
												<td>{formattedDate(note.visitedAt)}</td>
												<td>{note.doctorFullName}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<Pagination
								totalCount={pagination.totalCount}
								pageSize={pagination.pageSize}
								pageIndex={pagination.pageIndex}
								onChange={(pageSize, pageIndex) => onPageChange(pageSize, pageIndex)}
							/>
							<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
						</div>
					)}
					{notes.length === 0 && (
						<div className='empty-state-wrapper'>
							<EmptyState title={translate('noNotesFound')} image='Notes.svg' />
						</div>
					)}
				</>
			)}
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}

			<Modal
				display={selectedNote.text}
				onModalClose={() => setSelectedNote({ title: '', text: '' })}
				position='center'
				className='standard-modal-wrapper modal-wrapper-wo-btn'>
				<div className='standard-modal-inner modal-wrapper-wo-btn-inner simple-txt-modal-inner notes-modal'>
					<div>
						<div className='title'>
							<h4>{selectedNote.title}</h4>
						</div>
						<div>
							<p>{selectedNote.text}</p>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default Notes;
