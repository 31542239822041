import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const RoomSign = props => (
	<svg
		width={18}
		height={18}
		viewBox='0 0 20 20'
		fill='none'
		color={LightTheme.colors.graySeven}
		xmlns='http://www.w3.org/2000/svg'
		{...props}>
		<rect width={11.667} height={16.667} x={4.167} y={1.667} stroke='currentColor' strokeWidth={1.2} rx={2} />
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			strokeWidth={1}
			d='M6.25 4.167h2.5v2.5h-2.5v-2.5ZM6.25 8.75h2.5v2.5h-2.5v-2.5ZM6.25 13.333h2.5v2.5h-2.5v-2.5ZM11.25 4.167h2.5v2.5h-2.5v-2.5ZM11.25 8.75h2.5v2.5h-2.5v-2.5ZM11.25 13.333h2.5v2.5h-2.5v-2.5Z'
		/>
	</svg>
);

export default RoomSign;
