import * as React from 'react';

function CallEnd(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} viewBox="0 0 24 24" fill='none' {...props}>
			<path
				d='M19.167 15.343l-2.304-1.597a1.147 1.147 0 01-.356-.389 1.02 1.02 0 01-.13-.488v-.856a15.383 15.383 0 00-4.401-.635c-1.5.001-2.99.21-4.418.62v.87c0 .17-.042.337-.125.489a1.139 1.139 0 01-.352.389l-2.296 1.597a1.3 1.3 0 01-.434.194 1.376 1.376 0 01-.942-.108 1.211 1.211 0 01-.365-.286l-1.589-1.89a1.032 1.032 0 01-.249-.795c.034-.284.189-.546.432-.73C4.558 9.576 8.218 8.4 11.996 8.4c3.777 0 7.438 1.176 10.357 3.328.246.181.403.44.438.724.036.284-.053.569-.247.794l-1.59 1.897c-.098.123-.224.226-.37.303a1.366 1.366 0 01-.975.109 1.284 1.284 0 01-.442-.212v0z'
				stroke="white"
				strokeWidth={1.5}
				strokeMiterlimit={10}
				strokeLinecap='round'
			/>
		</svg>
	);
}

export default CallEnd;
