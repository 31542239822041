import * as React from 'react';

const Video = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none' {...props}>
		<rect width={15} height={15} x={2.5} y={2.5} stroke='currentColor' rx={3} />
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M8.333 12.524V9.976a.5.5 0 0 1 .724-.448l2.549 1.275a.5.5 0 0 1 0 .894l-2.549 1.275a.5.5 0 0 1-.724-.448Z'
		/>
		<path stroke='currentColor' d='M2.5 5h15M7.083 2.5 4.583 5M11.25 2.5 8.75 5M15.417 2.5l-2.5 2.5' />
	</svg>
);
export default Video;
