import * as React from 'react';

const LiveCaptionsDisabled = props => (
	<svg
		{...props}
		xmlns='http://www.w3.org/2000/svg'
		width={props.width || 24}
		height={props.height || 24}
		viewBox='0 0 24 24'
		fill='none'
		strokeWidth={1.5}>
		<path
			d='M21 18V7C21 6.44772 20.5523 6 20 6H9M6 6H4C3.44772 6 3 6.44772 3 7V17C3 17.5523 3.44772 18 4 18H18'
			stroke='currentColor'
		/>
		<path
			d='M9.5 9.5H8.5C7.94772 9.5 7.5 9.94772 7.5 10.5V13.5C7.5 14.0523 7.94772 14.5 8.5 14.5H9.5C10.0523 14.5 10.5 14.0523 10.5 13.5M14.5 14.5C13.9477 14.5 13.5 14.0523 13.5 13.5M16.5 13.5C16.5 13.6821 16.4513 13.8529 16.3662 14M16.5 10.5C16.5 9.94772 16.0523 9.5 15.5 9.5H14.5C13.9477 9.5 13.5 9.94772 13.5 10.5V11'
			stroke='currentColor'
		/>
		<path d='M3 3L21 21' stroke='currentColor' strokeLinecap='round' />
	</svg>
);

export default LiveCaptionsDisabled;
