import { useSelector } from 'react-redux';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import StreamPermissions from 'components/StreamPermissions.jsx';
import StreamPermissionsModal from 'components/StreamPermissionModal.jsx';
import { UserRoles } from 'calls/enums/index.js';
import { getUserRole } from 'infrastructure/auth.js';
import { HealthSystemType } from 'constants/enums.js';

const StreamPermissionsWrapper = () => {
	const streamPermission = useSelector(state => state.healthSystems.streamPermission);
	const userSession = useSelector(state => state.user.userSession);
	const darkMode = useSelector(state => state.user.darkMode);

	const isDarkMode =
		darkMode &&
		[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.VIRTUAL_SITTER].includes(getUserRole()) &&
		userSession.healthSystem.workflowTypeId !== HealthSystemType.PRIMARY_CARE;

	return (
		<div className={isDarkMode ? 'dark-mode' : ''}>
			{streamPermission && streamPermission.component === 'popup' && <StreamPermissions reason={streamPermission} />}
			{streamPermission && streamPermission.component === 'modal' && (
				<StreamPermissionsModal
					reason={streamPermission}
					setStreamPermissionMessage={healthSystemsActionCreators.setStreamPermissionMessage}
				/>
			)}
		</div>
	);
};

export default StreamPermissionsWrapper;
