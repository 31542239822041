import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getConversationByChannelId = async channelId => {
	try {
		const { data } = await gatewayApi.get(`/v1/conversations/channels/${channelId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getTeams = async healthSystemId => {
	try {
		const url = `/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/frontline-teams`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCheckedInNurses = async teamId => {
	try {
		const { data } = await gatewayApi.get(`/v1/companies/${getCompanyId()}/teams/${teamId}/users/checked-in`);
		return data;
	} catch (error) {
		return { error };
	}
};
