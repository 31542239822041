import React from 'react';
import { useSelector } from 'react-redux';
import { UserRoles } from 'constants/enums.js';
import DoctorWaitingRoom from 'containers/PrimaryCare/Doctor/DoctorWaitingRoom.jsx';
import LabWaitingRoom from 'containers/PrimaryCare/LaboratoryAnalysis/LabWaitingRoom.jsx';
import RadiologyWaitingRoom from 'containers/PrimaryCare/Radiology/RadiologyWaitingRoom.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import InfusionWaitingRoom from 'containers/PrimaryCare/Infusion/InfusionWaitingRoom.jsx';
import InjectionWaitingRoom from 'containers/PrimaryCare/Injection/InjectionWaitingRoom.jsx';
import { RoomTypes } from 'constants/visitEnums.js';

const WaitingRoomHomePrimaryCare = () => {
	const userSession = useSelector(state => state.user.userSession);
	return (
		!userSession.isInBreak &&
		<>
			{getUserRole() === UserRoles.NURSE && userSession.channelTypeId === RoomTypes.LABORATORY.type && <LabWaitingRoom />}
			{getUserRole() === UserRoles.NURSE && userSession.channelTypeId === RoomTypes.RADIOLOGY.type && <RadiologyWaitingRoom />}
			{getUserRole() === UserRoles.NURSE && userSession.channelTypeId === RoomTypes.INJECTION_ROOM.type && <InjectionWaitingRoom />}
			{getUserRole() === UserRoles.NURSE && userSession.channelTypeId === RoomTypes.INFUSION_ROOM.type && <InfusionWaitingRoom />}
			{getUserRole() === UserRoles.DOCTOR && <DoctorWaitingRoom />}

		</>
	);
};

export default WaitingRoomHomePrimaryCare;
