import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const editWhiteboardControl = async ({ roomId, payload }) => {
	try {
		const { data } = await gatewayApi.put(`v1/organizations/${getCompanyId()}/rooms/${roomId}/tv-control`, payload);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getWhiteboardControls = async ({ roomId }) => {
	try {
		const { data } = await gatewayApi.get(`v1/organizations/${getCompanyId()}/rooms/${roomId}/tv-controls`);
		return data;
	} catch (error) {
		return { error };
	}
};
