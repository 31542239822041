import { DetectionTypes } from 'constants/enums.js';

const detectionColors = ['#0000FF', '#498EF5'];

const getDetectionText = detectionType => {
	let text = '';
	switch (detectionType) {
		case DetectionTypes.FACE_DETECTION:
			text = '';
			break;
		case DetectionTypes.HANDS:
			text = 'Hands';
			break;
		case DetectionTypes.DISINFECTING_HANDS:
			text = 'Disinfecting Hands';
			break;
		default:
			return detectionType;
	}
	return text;
};

function addRect(ctx, x, y, width, height, detectionType, color) {
	ctx.beginPath();
	ctx.strokeStyle = color;
	ctx.lineWidth = '4';
	if (![DetectionTypes.FACE_DETECTION, DetectionTypes.DISINFECTING_HANDS].includes(detectionType)) {
		ctx.rect(x, y, width, height);
	}
	if ([DetectionTypes.FACE_DETECTION, DetectionTypes.DISINFECTING_HANDS].includes(detectionType)) {
		const radius = 20;
		ctx.fillStyle = 'rgba(36, 126, 249, 0.4)';
		ctx.fillRect(x, y, width, height);
		ctx.lineJoin = 'round';
		ctx.lineWidth = radius;
	}
	ctx.stroke();
	ctx.beginPath();
	ctx.font = '18px Arial';
	ctx.lineWidth = 4;
	ctx.strokeStyle = 'black';
	ctx.strokeText(getDetectionText(detectionType), x + 3, y - 10);
	ctx.fillStyle = 'white';
	ctx.fillText(getDetectionText(detectionType), x + 3, y - 10);
}

function drawObjectDetections(ctx, detectionsList, detectType, width, height, patientName) {
	ctx.clearRect(0, 0, width, height);
	detectionsList.forEach((d, index) => {
		if (index <= detectionsList.length - 1) {
			if (!detectType) {
				addRect(
					ctx,
					d.leftCoordinate * width,
					d.topCoordinate * height,
					(d.rightCoordinate - d.leftCoordinate) * width,
					(d.bottomCoordinate - d.topCoordinate) * height,
					d.detectionType,
					detectionColors[d.detectionType.includes(DetectionTypes.RAIL) ? 0 : 1],
					patientName
				);
			} else if (
				[DetectionTypes.PERSON, DetectionTypes.BABY].includes(detectType) &&
				d.detectionType.toLowerCase().startsWith(detectType)
			) {
				addRect(
					ctx,
					d.leftCoordinate * width,
					d.topCoordinate * height,
					(d.rightCoordinate - d.leftCoordinate) * width,
					(d.bottomCoordinate - d.topCoordinate) * height,
					d.detectionType,
					detectionColors[d.detectionType.includes(DetectionTypes.PERSON) ? 1 : 0],
					patientName
				);
			} else if (
				detectType.toLowerCase() === DetectionTypes.COMBINE ||
				detectType.toLowerCase().startsWith(DetectionTypes.BED) ||
				detectType.toLowerCase().startsWith(DetectionTypes.RAIL)
			) {
				addRect(
					ctx,
					d.leftCoordinate * width,
					d.topCoordinate * height,
					(d.rightCoordinate - d.leftCoordinate) * width,
					(d.bottomCoordinate - d.topCoordinate) * height,
					d.detectionType,
					detectionColors[d.detectionType.includes(DetectionTypes.RAIL) ? 0 : 1],
					patientName
				);
			} else if ([DetectionTypes.IV_BAG_FLUID_LEVEL, DetectionTypes.MOBILITY_SCORE].includes(detectType)) {
				addRect(
					ctx,
					d.leftCoordinate * width,
					d.topCoordinate * height,
					(d.rightCoordinate - d.leftCoordinate) * width,
					(d.bottomCoordinate - d.topCoordinate) * height,
					`${Math.round(d.confidence)}%`,
					detectionColors[1],
					patientName
				);
			}
		}
	});
}

export default drawObjectDetections;
