import React from 'react';
import { useIntl } from 'react-intl';

const TableCancelInviteButton = props => {
	const intl = useIntl();
	return (
		<i
			className='material-icons-outlined boxed-icon'
			onClick={props.onClick}
			data-tooltip={intl.formatMessage({ id: 'cancelInvite' })}
			data-position='top'>
			remove
		</i>
	);
};

export default TableCancelInviteButton;
