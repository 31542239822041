import React, { useEffect, useRef, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import { Button, MultiSelectCheckboxes } from 'components/index.js';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import EthernetIcon from 'icons/Devices/EthernetIcon.jsx';
import ReactSelect from 'react-select';
import { getOrganizationSectorsChildren } from 'api/organization.js';
import { DeviceConnectionStatus, DeviceConnectionType, DeviceListLevel, TreeHierarchyType } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { isJSON } from 'infrastructure/helpers/commonHelpers.js';

const initialPagination = { pageIndex: 1, pageSize: 10, totalCount: 0 };

const FleetFilters = {
	ASSIGNATIONS: 1,
	STATUS: 2,
	ALERTS: 3,
	CONNECTION: 4,
	DATE_RANGE: 5,
};

const AssignationFilters = {
	HOSPITAL: 1,
	DEPARTMENT: 2,
	FLOOR: 3,
	ROOM: 4,
	HEALTH_SYSTEMS: 5,
};

const DeviceStatusOptions = [
	{ value: DeviceConnectionStatus.ONLINE, title: 'online', color: 'var(--green-4)' },
	{ value: DeviceConnectionStatus.OFFLINE, title: 'offline', color: 'var(--gray-4)' },
];

const ConnectionOptions = [
	{ value: DeviceConnectionType.WIRELESS, title: 'wireless', icon: <i className='material-icons-outlined'>wifi</i> },
	{ value: DeviceConnectionType.ETHERNET, title: 'ethernet', icon: <EthernetIcon /> },
];

const FleetManagementFilters = props => {
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const healthSystemsOptions = allHealthSystems.map(hs => ({
		value: hs.id,
		label: hs.name,
		treeHierarchyTypeId: hs.treeHierarchyTypeId,
	}));
	const wrapperRef = useRef(null);
	const [treeHierarchyTypeId, setTreeHierarchyTypeId] = useState(props.filterValues.healthSystems[0]?.treeHierarchyTypeId);
	const [extendedFilter, setExtendedFilter] = useState(0);
	const [assignationFilter, setAssignationFilter] = useState(0);
	const [allSectorsOptions, setAllSectorsOptions] = useState({
		healthSystems: [props.filterValues.healthSystems[0]],
		hospitals: [],
		departments: [],
		floors: [],
		rooms: [],
	});
	const [menuScrollPosition, setMenuScrollPosition] = useState(0);

	const transformArray = array =>
		array.map(item => ({
			value: JSON.stringify(item),
			label: (
				<>
					<span className='custom-label'>{item.name}</span>
					{item.hospitalName && (
						<span className='second-label'>
							<img src={`${healthCareCdnUrl}treeview/Hospital.svg`} alt='hos' /> {item.hospitalName}
						</span>
					)}
				</>
			),
		}));

	useEffect(() => {
		const fetchHospitals = async () => {
			const params = {
				level: DeviceListLevel.HEALTH_SYSTEM,
				teamsIds: [props.filterValues.healthSystems[0].value],
				hierarchyType: treeHierarchyTypeId === 0 ? TreeHierarchyType.HOSPITAL_DEPT_ROOM : treeHierarchyTypeId,
			};
			const response = await getOrganizationSectorsChildren(params);
			if (response.error) {
				props.setError(response.error.message);
				return;
			}
			await updateChildrenSectors('hospitals', response.teamsWithChildren[0]?.children);
		};

		fetchHospitals();
	}, []);

	useEffect(() => {
		const handleClickOutside = event => {
			if (!wrapperRef.current || wrapperRef.current.contains(event.target)) {
				return;
			}
			setExtendedFilter(0);
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const updateChildrenSectors = (level, children) =>
		setAllSectorsOptions(prevState => ({ ...prevState, [level]: children ?? [] }));

	const haveDepartmentsHierarchy = () =>
		![TreeHierarchyType.HOSPITAL_FLOOR_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(treeHierarchyTypeId);

	const haveFloorsHierarchy = () =>
		![TreeHierarchyType.HOSPITAL_DEPT_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(treeHierarchyTypeId);

	const getLevelsChildren = async (level, ids, sectorToUpdate) => {
		if (ids.length === 0) {
			return;
		}
		const params = {
			level,
			teamsIds: ids.map(item => (isJSON(item.value) ? JSON.parse(item.value).id : item.value)),
			hierarchyType: treeHierarchyTypeId === 0 ? TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM : treeHierarchyTypeId,
		};
		const response = await getOrganizationSectorsChildren(params);
		if (response.error) {
			props.setError(response.error.message);
			return;
		}

		if (level === DeviceListLevel.HEALTH_SYSTEM) {
			updateChildrenSectors(sectorToUpdate, response.teamsWithChildren[0]?.children);
		} else {
			const sectorsChildren = response.teamsWithChildren.reduce((acc, sector) => {
				const sectorChildren = sector.children.map(item => ({ ...item, hospitalName: sector.hospitalName }));
				acc.push(...sectorChildren);
				return acc;
			}, []);
			updateChildrenSectors(sectorToUpdate, sectorsChildren);
		}
	};

	const handleHealthSystemSelect = healthSystems => {
		setAllSectorsOptions(prevState => ({ ...prevState, departments: [], floors: [], rooms: [] }));
		setTreeHierarchyTypeId(healthSystems[0].treeHierarchyTypeId ?? TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM);
		getLevelsChildren(DeviceListLevel.HEALTH_SYSTEM, healthSystems, 'hospitals');
		props.setFilterValues(prevState => ({
			...prevState,
			healthSystems: healthSystems,
			lastLevel: { name: 'healthSystems', id: DeviceListLevel.HEALTH_SYSTEM },
			hospitals: [],
			departments: [],
			floors: [],
			rooms: [],
		}));
		props.setPagination(initialPagination);
	};

	const handleHospitalsSelect = hospitals => {
		let childSector = 'departments';

		props.setFilterValues(prevState => ({
			...prevState,
			hospitals,
			departments: [],
			floors: [],
			rooms: [],
			lastLevel: { name: 'hospitals', id: DeviceListLevel.HOSPITAL },
		}));
		props.setPagination(initialPagination);

		if (hospitals.length === 0) {
			handleHealthSystemSelect(props.filterValues.healthSystems);
			setAllSectorsOptions(prevState => ({ ...prevState, departments: [], floors: [], rooms: [] }));
			return;
		}

		if (!haveDepartmentsHierarchy() && haveFloorsHierarchy()) {
			childSector = 'floors';
		}

		if (!haveDepartmentsHierarchy() && !haveFloorsHierarchy()) {
			childSector = 'rooms';
		}

		getLevelsChildren(DeviceListLevel.HOSPITAL, hospitals, childSector);
	};

	const handleDepartmentsSelect = departments => {
		const childSector = haveFloorsHierarchy() ? 'floors' : 'rooms';

		props.setFilterValues(prevState => ({
			...prevState,
			departments,
			lastLevel: { name: 'departments', id: DeviceListLevel.DEPARTMENT },
			floors: [],
			rooms: [],
		}));
		props.setPagination(initialPagination);

		if (departments.length === 0) {
			handleHospitalsSelect(props.filterValues.hospitals);
			setAllSectorsOptions(prevState => ({ ...prevState, floors: [], rooms: [] }));
			return;
		}

		getLevelsChildren(DeviceListLevel.DEPARTMENT, departments, childSector);
	};

	const handleFloorsSelect = floors => {
		props.setFilterValues(prevState => ({
			...prevState,
			floors,
			lastLevel: { name: 'floors', id: DeviceListLevel.FLOOR },
			rooms: [],
		}));
		props.setPagination(initialPagination);

		if (floors.length === 0) {
			if (!haveDepartmentsHierarchy()) {
				handleHospitalsSelect(props.filterValues.hospitals);
				return;
			}
			handleDepartmentsSelect(props.filterValues.departments);
			setAllSectorsOptions(prevState => ({ ...prevState, rooms: [] }));
			return;
		}
		getLevelsChildren(DeviceListLevel.FLOOR, floors, 'rooms');
	};

	const handleRoomsSelect = rooms => {
		props.setFilterValues(prevState => ({
			...prevState,
			rooms,
			lastLevel: { name: 'rooms', id: DeviceListLevel.ROOM },
		}));
		props.setPagination(initialPagination);

		if (rooms.length === 0) {
			if (!haveDepartmentsHierarchy() && !haveFloorsHierarchy()) {
				handleHospitalsSelect(props.filterValues.hospitals);
				return;
			}

			if (haveDepartmentsHierarchy() && !haveFloorsHierarchy()) {
				handleDepartmentsSelect(props.filterValues.departments);
				return;
			}

			handleFloorsSelect(props.filterValues.floors);
		}
	};

	const clearFilters = () => {
		setExtendedFilter(0);
		setAssignationFilter(0);
		props.setFilterValues(prevState => ({
			...prevState,
			healthSystems: [props.initialHs],
			hospitals: [],
			departments: [],
			floors: [],
			rooms: [],
			status: null,
			connection: null,
			fromDate: '',
			toDate: '',
			lastLevel: { name: 'healthSystems', id: DeviceListLevel.HEALTH_SYSTEM },
		}));
		props.setPagination(initialPagination);
		setAllSectorsOptions(prevState => ({ ...prevState, departments: [], floors: [], rooms: [] }));
	};

	const CustomFleetFilter = ({ children, title, filterType, hasValuesSelected, className = '' }) => (
		<div key={filterType} className={classNames('fleet-filter', className)}>
			<div onClick={() => setExtendedFilter(prevSate => (prevSate === filterType ? 0 : filterType))} className='dropdown-btn'>
				<p>{translate(title)}</p>
				{hasValuesSelected && (
					<div className='filter-clear-all'>
						<span className='empty' />
					</div>
				)}
				<i className='material-icons-outlined'>keyboard_arrow_down</i>
			</div>
			{extendedFilter === filterType && <div className='fleet-filter-expended'>{children}</div>}
		</div>
	);

	const CustomLevelFilter = ({ children, title, filterType, valuesSelected, isDisabled = false }) => (
		<div key={filterType} className={classNames('fleet-filter', isDisabled ? 'disabled' : '')}>
			<div className='dropdown-btn'>
				<span
					className='dropdown-btn'
					onClick={() => setAssignationFilter(prevSate => (prevSate === filterType ? 0 : filterType))}>
					<i className='material-icons-outlined'>
						{assignationFilter === filterType ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
					</i>
					<p>{translate(title)}</p>
				</span>
				{valuesSelected > 0 && (
					<div className='filter-clear-all'>
						<span>{valuesSelected}</span>
					</div>
				)}
			</div>
			{assignationFilter === filterType && <div>{children}</div>}
		</div>
	);

	return (
		<div className='filters-wrapper' ref={wrapperRef}>
			<CustomFleetFilter
				className='parent-filter'
				title='deviceEnrollment'
				hasValuesSelected={true}
				filterType={FleetFilters.ASSIGNATIONS}>
				<CustomLevelFilter title='healthSystem' filterType={AssignationFilters.HEALTH_SYSTEMS} valuesSelected={1}>
					<ReactSelect
						value={props.filterValues.healthSystems[0]}
						onChange={hs => handleHealthSystemSelect([hs])}
						options={healthSystemsOptions}
						className='fleet-filter-select'
						menuIsOpen={true}
						components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
					/>
				</CustomLevelFilter>
				<CustomLevelFilter
					title='hospitals'
					filterType={AssignationFilters.HOSPITAL}
					valuesSelected={props.filterValues.hospitals.length}
					isDisabled={allSectorsOptions.hospitals.length === 0}>
					<MultiSelectCheckboxes
						name='hospitals'
						selectedOptions={props.filterValues.hospitals}
						searchable
						options={transformArray(allSectorsOptions.hospitals)}
						onChange={hospitals => handleHospitalsSelect(hospitals)}
						isMenuOpen={assignationFilter === AssignationFilters.HOSPITAL}
						keepOpenOnClickOutside
						menuScrollPosition={menuScrollPosition}
						setMenuScrollPosition={setMenuScrollPosition}
					/>
				</CustomLevelFilter>
				{haveDepartmentsHierarchy() && (
					<CustomLevelFilter
						title='departments'
						filterType={AssignationFilters.DEPARTMENT}
						valuesSelected={props.filterValues.departments.length}
						isDisabled={allSectorsOptions.departments.length === 0}>
						<MultiSelectCheckboxes
							name='departments'
							selectedOptions={props.filterValues.departments}
							searchable
							options={transformArray(allSectorsOptions.departments)}
							onChange={departments => handleDepartmentsSelect(departments)}
							isMenuOpen={assignationFilter === AssignationFilters.DEPARTMENT}
							keepOpenOnClickOutside
							menuScrollPosition={menuScrollPosition}
							setMenuScrollPosition={setMenuScrollPosition}
						/>
					</CustomLevelFilter>
				)}
				{haveFloorsHierarchy() && (
					<CustomLevelFilter
						title='floors'
						filterType={AssignationFilters.FLOOR}
						valuesSelected={props.filterValues.floors.length}
						isDisabled={allSectorsOptions.floors.length === 0}>
						<MultiSelectCheckboxes
							name='floors'
							selectedOptions={props.filterValues.floors}
							searchable
							options={transformArray(allSectorsOptions.floors)}
							onChange={floors => handleFloorsSelect(floors)}
							isMenuOpen={assignationFilter === AssignationFilters.FLOOR}
							keepOpenOnClickOutside
							menuScrollPosition={menuScrollPosition}
							setMenuScrollPosition={setMenuScrollPosition}
						/>
					</CustomLevelFilter>
				)}
				<CustomLevelFilter
					title='rooms'
					filterType={AssignationFilters.ROOM}
					valuesSelected={props.filterValues.rooms.length}
					isDisabled={allSectorsOptions.rooms.length === 0}>
					<MultiSelectCheckboxes
						name='rooms'
						selectedOptions={props.filterValues.rooms}
						searchable
						options={transformArray(allSectorsOptions.rooms)}
						onChange={rooms => handleRoomsSelect(rooms)}
						isMenuOpen={assignationFilter === AssignationFilters.ROOM}
						keepOpenOnClickOutside
						menuScrollPosition={menuScrollPosition}
						setMenuScrollPosition={setMenuScrollPosition}
					/>
				</CustomLevelFilter>
			</CustomFleetFilter>
			<CustomFleetFilter
				title='deviceStatus'
				filterType={FleetFilters.STATUS}
				hasValuesSelected={props.filterValues.status !== null}>
				{DeviceStatusOptions.map(item => (
					<div
						key={item.value}
						className='filter-item'
						onClick={() => {
							props.setFilterValues(prevState => ({
								...prevState,
								status: item.value === props.filterValues.status ? null : item.value,
							}));
							props.setPagination(initialPagination);
						}}>
						<input type='checkbox' checked={item.value === props.filterValues.status} name='status' onChange={() => null} />
						<span style={{ backgroundColor: item.color }} className='status-color' />
						{translate(item.title)}
					</div>
				))}
			</CustomFleetFilter>
			<CustomFleetFilter
				title='connection'
				filterType={FleetFilters.CONNECTION}
				hasValuesSelected={props.filterValues.connection !== null}>
				{ConnectionOptions.map(item => (
					<div
						key={item.value}
						className='filter-item'
						onClick={() => {
							props.setFilterValues(prevState => ({
								...prevState,
								connection: item.value === props.filterValues.connection ? null : item.value,
							}));
							props.setPagination(initialPagination);
						}}>
						<input
							type='checkbox'
							checked={item.value === props.filterValues.connection}
							name='connection'
							onChange={() => null}
						/>
						{item.icon}
						{translate(item.title)}
					</div>
				))}
			</CustomFleetFilter>
			<Button size='small' text={translate('clearAll')} variant='white' background='white' onClick={clearFilters} />
		</div>
	);
};

export default FleetManagementFilters;
