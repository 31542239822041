const CameraTypes = {
	HELLO: 'Hello',
	HUDDLE: 'Huddle',
	OTOSCOPE: 'Otoscope',
	DERMATOSCOPE: 'Dermatoscope',
	EXTERNAL: 'External',
};

/**
 * This is defined also Hello side, notify Hello dev for changes
 * @enum {string}
 */
export default CameraTypes;
