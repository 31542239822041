import React from 'react';
import drawObjectDetections from 'services/aiDetections/objectDetection.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { DetectionModes, AiDetectionTypes, AlertTypes, AiAlertType } from 'constants/enums.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import DropupItem from 'components/DropupItem.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { adjustPositions } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import drawSkeletonDetections from 'services/aiDetections/skeletonDetection.js';
import Dropup from 'components/Dropup.jsx';

export default class AIControls extends React.Component {
	state = {
		isObjectDetectionOn: false,
		isBabyDetectionOn: false,
		isRailsDetectionOn: false,
		isSkeletonDetectionOn: false,
		isPersonDetectionOn: false,
		detectionMode: null,
		hasFall: false,
		isAiAlertOn: false,
		aiAlertType: null,
		isPrivateModeOn: false,
		isActionRecognition: false,
	};

	aiTools = {
		OBJECT: {
			state: 'isObjectDetectionOn',
			detectionMode: null,
			value: 'Object Detection',
			img: `${healthCareCdnUrl}footer-icons/ObjectDetection.svg?v2`,
		},
		BABY: {
			state: 'isBabyDetectionOn',
			detectionMode: DetectionModes.BABY,
			value: 'Baby Detection',
			img: `${healthCareCdnUrl}footer-icons/BabyDetection.svg?v2`,
		},
		RAILS: {
			state: 'isRailsDetectionOn',
			detectionMode: DetectionModes.COMBINE,
			value: 'Bed and Rails Detection',
			img: `${healthCareCdnUrl}footer-icons/BedAndRailsDetection.svg?v2`,
		},
		SKELETON: {
			state: 'isSkeletonDetectionOn',
			detectionMode: null,
			value: 'Skeleton Fall Detection',
			img: `${healthCareCdnUrl}footer-icons/SkeletonFallDetection.svg?v2`,
		},
		PERSON: {
			state: 'isPersonDetectionOn',
			detectionMode: DetectionModes.PERSON,
			value: 'Patient Position Detection',
			img: `${healthCareCdnUrl}footer-icons/PatientPositionDetection.svg?v2`,
		},
		PRIVATE_MODE: {
			state: 'isPrivateModeOn',
			detectionMode: null,
			value: 'Privacy on Skeleton Detection',
			img: `${healthCareCdnUrl}footer-icons/PrivacyOnSkeletonDetection.svg?v2`,
		},
		ACTION_RECOGNITION: {
			state: 'isActionRecognition',
			detectionMode: null,
			value: 'Action Recognition',
			img: `${healthCareCdnUrl}footer-icons/MultiplePersonSkeletonDetection.svg?v2`,
		},
	};

	ctx = null;

	componentDidMount = () => {
		this.props.callManager.on('draw-skeleton', this.skeletonDetectionListener);
		this.props.callManager.on('object-detection', this.objectDetectionsListener);
		this.props.callManager.on('ai-alert', this.aiAlertListener);
		this.props.callManager.on('toggle-ai-tools', this.aiToolsListener);
	};

	componentWillUnmount = () => {
		this.props.callManager.off('draw-skeleton', this.skeletonDetectionListener);
		this.props.callManager.off('object-detection', this.objectDetectionsListener);
		this.props.callManager.off('ai-alert', this.aiAlertListener);
		this.props.callManager.off('toggle-ai-tools', this.aiToolsListener);
	};

	skeletonDetectionListener = ({ skeletons, conferenceId }) => {
		const { isSkeletonDetectionOn, isActionRecognition } = this.state;
		if (this.props.conferenceId !== conferenceId || (!isSkeletonDetectionOn && !isActionRecognition)) {
			return;
		}
		const clientWidth = this.props.isFitScreenOn ? (window.innerHeight * 16) / 9 : this.props.videoRef.current.clientWidth;
		const clientHeight = this.props.isFitScreenOn ? window.innerHeight : this.props.videoRef.current.clientHeight;
		this.ctx.clearRect(0, 0, clientWidth, clientHeight);
		const skeletonArray = adjustPositions(skeletons, clientWidth, clientHeight);
		drawSkeletonDetections({
			ctx: this.ctx,
			skeleton: skeletonArray,
		});
	};

	onFallDetect = () => {
		this.setState({
			hasFall: true,
		});
		const { helloDeviceId, conferenceId, participantId, patientId } = this.props;
		const data = {
			isFallDetected: true,
			helloDeviceId,
			conferenceId,
			participantId,
			patientId,
			addToHealthMeasurements: true,
		};
		this.props.callManager.sendFallDetected(data);
	};

	prepareCanvas = () => {
		this.ctx = this.props.canvasRef.current.getContext('2d');
		if (this.ctx !== null) {
			this.ctx.clearRect(0, 0, this.props.canvasRef.current.width, this.props.canvasRef.current.height);
		}
	};

	toggleAITools = tool => {
		const initialState = {
			...this.state,
			isObjectDetectionOn: false,
			isBabyDetectionOn: false,
			isRailsDetectionOn: false,
			isSkeletonDetectionOn: false,
			isPersonDetectionOn: false,
			detectionMode: null,
			isPrivateModeOn: false,
			isActionRecognition: false,
			hasFall: false,
			isAiAlertOn: false,
			aiAlertType: null,
		};
		const newState = {
			...initialState,
			[tool.state]: !this.state[tool.state],
			detectionMode: !this.state[tool.state] ? tool.detectionMode : null,
		};

		this.props.toggleAIControlsIsActive(!this.state[tool.state], tool);
		this.props.tiltBottomClass(false);
		this.setState(newState);
	};

	toggleObjectDetection = () => {
		this.prepareCanvas();
		this.props.callManager.toggleAiTools(
			!this.state.isObjectDetectionOn,
			AiDetectionTypes.OBJECT,
			this.props.helloDeviceId,
			this.props.conferenceId,
			this.props.participantId
		);

		this.toggleAITools(this.aiTools.OBJECT);
	};

	toggleBabyDetection = () => {
		this.prepareCanvas();
		this.props.callManager.toggleAiTools(
			!this.state.isBabyDetectionOn,
			AiDetectionTypes.BABY,
			this.props.helloDeviceId,
			this.props.conferenceId,
			this.props.participantId
		);

		this.toggleAITools(this.aiTools.BABY);
	};

	toggleRailsDetection = () => {
		this.prepareCanvas();
		this.props.callManager.toggleAiTools(
			!this.state.isRailsDetectionOn,
			AiDetectionTypes.RAILS,
			this.props.helloDeviceId,
			this.props.conferenceId,
			this.props.participantId
		);
		this.toggleAITools(this.aiTools.RAILS);
	};

	toggleSkeletonDetection = option => {
		this.prepareCanvas();
		this.props.callManager.toggleAiTools(
			option.isActionRecognition ? !this.state.isActionRecognition : !this.state.isSkeletonDetectionOn,
			option.isActionRecognition ? AiDetectionTypes.ACTION_RECOGNITION : AiDetectionTypes.SKELETON,
			this.props.helloDeviceId,
			this.props.conferenceId,
			this.props.participantId
		);
		this.toggleAITools(option.isActionRecognition ? this.aiTools.ACTION_RECOGNITION : this.aiTools.SKELETON);
	};

	togglePrivateMode = () => {
		this.setState(
			prevState => ({
				isPrivateModeOn: !prevState.isPrivateModeOn,
			}),
			() => {
				this.props.setSkeletonImg(!this.state.isPrivateModeOn ? this.props.privacyImageUrl : null);
			}
		);
	};

	togglePersonDetection = () => {
		this.prepareCanvas();
		this.props.callManager.toggleAiTools(
			!this.state.isPersonDetectionOn,
			AiDetectionTypes.PATIENT,
			this.props.helloDeviceId,
			this.props.conferenceId,
			this.props.participantId
		);
		this.toggleAITools(this.aiTools.PERSON);
	};

	objectDetectionsListener = data => {
		if (
			(this.state.isObjectDetectionOn || this.state.detectionMode) &&
			this.props.isVideoCall &&
			this.props.conferenceId === data.conferenceId
		) {
			const innerHeight = (window.innerWidth * 9) / 16;
			drawObjectDetections(this.ctx, data.detections, this.state.detectionMode, window.innerWidth, innerHeight);
		}
	};

	aiAlertListener = data => {
		const patientPositionAlertTypes = [
			AiAlertType.PATIENT_SITTING,
			AiAlertType.PATIENT_STANDING,
			AiAlertType.PATIENT_FACE_DOWN,
			AiAlertType.PATIENT_LYING_FRONT,
			AiAlertType.PATIENT_LYING_SIDE,
		];
		if (data.conferenceId === this.props.conferenceId) {
			this.props.tiltBottomClass(true);
			if (
				(this.state.isPersonDetectionOn && patientPositionAlertTypes.includes(data.type)) ||
				(this.state.isBabyDetectionOn &&
					(data.type === AiAlertType.PATIENT_LYING_RIGHT_SIDE || data.type === AiAlertType.BABY_EXITS_CRIB)) ||
				((this.state.isSkeletonDetectionOn || this.state.isActionRecognition) && data.type === AiAlertType.FALL_DETECTION) ||
				data.type === AiAlertType.RAILS
			) {
				this.setState({
					isAiAlertOn: true,
					aiAlertType: data.type,
					aiAlertMessage: this.getAiAlertMessage(data.type),
				});
			}
		}
	};

	aiToolsListener = data => {
		if (data.conferenceId === this.props.conferenceId) {
			this.prepareCanvas();
			switch (data.aiOption) {
				case AiDetectionTypes.OBJECT:
					this.toggleAITools(this.aiTools.OBJECT);
					break;
				case AiDetectionTypes.BABY:
					this.toggleAITools(this.aiTools.BABY);
					break;
				case AiDetectionTypes.PATIENT:
					this.toggleAITools(this.aiTools.PERSON);
					break;
				case AiDetectionTypes.RAILS:
					this.toggleAITools(this.aiTools.RAILS);
					break;
				case AiDetectionTypes.SKELETON:
					this.toggleAITools(this.aiTools.SKELETON);
					break;
				case AiDetectionTypes.ACTION_RECOGNITION:
					this.toggleAITools(this.aiTools.ACTION_RECOGNITION);
					break;
				default:
					break;
			}
		}
	};

	closeFallAlert = () => {
		this.props.tiltBottomClass(false);
		this.setState({ hasFall: false, isSkeletonDetectionOn: false, isActionRecognition: false });
		this.props.callManager.sendFallDetected(false, this.props.helloDeviceId, this.props.conferenceId, this.props.participantId);
		this.prepareCanvas();
	};

	closePersonUpAlert = () => {
		this.props.tiltBottomClass(false);
		this.setState({ isAiAlertOn: false, aiAlertType: null, aiAlertMessage: null });
		this.props.callManager.cancelPersonUpAlert(
			this.props.helloDeviceId,
			this.props.conferenceId,
			this.props.participantId,
			this.state.aiAlertType
		);
	};

	getAiAlertMessage = type => {
		switch (type) {
			case AiAlertType.PATIENT_SITTING:
			case AiAlertType.PATIENT_STANDING:
			case AiAlertType.PATIENT_FACE_DOWN:
			case AiAlertType.PATIENT_LYING_FRONT:
			case AiAlertType.PATIENT_LYING_SIDE:
			case AiAlertType.PATIENT_LYING_RIGHT_SIDE:
				return translate('patientPosition');
			case AiAlertType.BABY_EXITS_CRIB:
				return translate('alertBabyExitingCrib');
			case AiAlertType.FALL_DETECTION:
				return translate('fallDetected');
			case AiAlertType.RAILS:
				return translate('railDownMessage');
			default:
				return null;
		}
	};

	render() {
		return (
			<>
				<Dropup
					isDisabled={this.props.isDisabled}
					isActive={this.props.isActive}
					content={this.props.content}
					imgIcon={this.props.imgIcon}>
					<DropupItem
						isActive={this.state.isObjectDetectionOn}
						onClick={this.toggleObjectDetection}
						imgSrc={
							this.state.isObjectDetectionOn
								? `${healthCareCdnUrl}footer-icons/ObjectDetection.svg?v2`
								: `${healthCareCdnUrl}footer-icons/ObjectDetection-1.svg?v2`
						}
						content={translate('objectDetection')}
					/>
					<DropupItem
						isActive={this.state.isBabyDetectionOn}
						onClick={this.toggleBabyDetection}
						imgSrc={
							this.state.isBabyDetectionOn
								? `${healthCareCdnUrl}footer-icons/BabyDetection.svg?v2`
								: `${healthCareCdnUrl}footer-icons/BabyDetection-1.svg?v2`
						}
						content={translate('babyDetection')}
					/>
					<DropupItem
						isActive={this.state.isPersonDetectionOn}
						onClick={this.togglePersonDetection}
						imgSrc={
							this.state.isPersonDetectionOn
								? `${healthCareCdnUrl}footer-icons/PatientPositionDetection.svg?v2`
								: `${healthCareCdnUrl}footer-icons/PatientPositionDetection-1.svg?v2`
						}
						content={translate('patientPositionDetection')}
					/>
					<DropupItem
						isActive={this.state.isRailsDetectionOn}
						onClick={this.toggleRailsDetection}
						imgSrc={
							this.state.isRailsDetectionOn
								? `${healthCareCdnUrl}footer-icons/BedAndRailsDetection.svg?v2`
								: `${healthCareCdnUrl}footer-icons/BedAndRailsDetection-1.svg?v2`
						}
						content={translate('bedRailDetection')}
					/>
					<DropupItem
						isActive={this.state.isActionRecognition}
						onClick={() => this.toggleSkeletonDetection({ isActionRecognition: true })}
						imgSrc={
							this.state.isActionRecognition
								? `${healthCareCdnUrl}footer-icons/MultiplePersonSkeletonDetection.svg?v2`
								: `${healthCareCdnUrl}footer-icons/MultiplePersonSkeletonDetection-1.svg?v2`
						}
						content={translate('actionRecognition')}
					/>
					<DropupItem
						isActive={this.state.isSkeletonDetectionOn}
						onClick={() => this.toggleSkeletonDetection({ isActionRecognition: false })}
						imgSrc={
							this.state.isSkeletonDetectionOn
								? `${healthCareCdnUrl}footer-icons/SkeletonFallDetection.svg?v2`
								: `${healthCareCdnUrl}footer-icons/SkeletonFallDetection-1.svg?v2`
						}
						content={translate('skeletonFallDetection')}
					/>
				</Dropup>
				<PopUpAlert
					display={this.state.hasFall}
					alertType={AlertTypes.DANGER}
					contentText={translate('fallDetected')}
					onAlertClose={this.closeFallAlert}
					isRight={this.props.isRight}
				/>
				<PopUpAlert
					display={this.state.isAiAlertOn}
					alertType={AlertTypes.DANGER}
					contentText={this.state.aiAlertMessage}
					onAlertClose={this.closePersonUpAlert}
					isRight={this.props.isRight}
				/>
			</>
		);
	}
}

AIControls.contextType = SocketContext;
