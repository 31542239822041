import { ChatOptionsViews } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { getUserInfo } from 'infrastructure/auth.js';

const Participants = props => {
	const userInfo = getUserInfo();

	return (
		<div className='chat-options-wrapper position-relative'>
			<div className='flex'>
				<div className='cursor-pointer' onClick={() => props.setDefaultOptions(ChatOptionsViews.DEFAULT)}>
					<img src={`${healthCareCdnUrl}chat/back.svg`} alt='back arrow icon' />
				</div>
				<div>
					<p>Participants</p>
					<p>
						{props.nursesOnDutyLength}
						{props.nursesOnDutyLength === 1 ? ' Nurse is' : ' Nurses are'} currently on duty
					</p>
				</div>
			</div>
			<div className='teams-participant-list'>
				{props.members.map(member => (
					<div key={member.userId} className='flex flex-align-center'>
						<div className='flex position-relative chat-list-img-status-wrapper'>
							<ProfilePicture
								className='chat-list-img'
								fullName={`${member.firstName} ${member.lastName}`}
								profilePicture={member.profilePicture}
							/>
						</div>
						<p>
							{member.firstName} {member.lastName} {userInfo.userId === member.userId && <span>(You)</span>}
						</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default Participants;
