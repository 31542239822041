import { LiveCaptionsIcon, MicIcon, NightVisionIconOn } from 'calls/icons/index.js';
import Screenshare from 'calls/icons/Screenshare.jsx';
import LightTheme from 'calls/styles/LightTheme.js';
import { CallTypes, EventCategory, UserRoles, UserTypes } from 'constants/enums.js';
import AISettings from 'icons/Dashboard/AISettings.jsx';
import ConnectIcon from 'icons/Dashboard/ConnectIcon.jsx';
import {
	AiIcon,
	AlertIcon,
	AmbientMonitoring,
	GearIconAlt,
	PatientOutOfRoomAlt,
	PatientPrivacy,
	PrecautionsIcon,
	ProfileIcon,
	VideoMonitoring,
	VoiceIcon,
} from 'icons/Monitoring/index.js';
import { formatTimeDurationWithSeconds, getTimezoneAbbreviation } from 'infrastructure/helpers/dateHelper.js';

export const DashboardContent = [
	{ id: 'conferenceId', label: 'conferenceId', checked: false },
	{ id: 'initiatorRole', label: 'initiatorRole', checked: false },
	{ id: 'initiatorDevice', label: 'initiatorDevice', checked: false },
	{ id: 'provider', label: 'provider', checked: false },
	{ id: 'room', label: 'room', checked: false },
	{ id: 'patient', label: 'patient', checked: false },
	{ id: 'startTime', label: 'callStart', checked: false },
	{ id: 'endedTime', label: 'callEnd', checked: false },
	{ id: 'duration', label: 'duration', checked: false },
	{ id: 'durationInSeconds', label: 'durationInSeconds', checked: false },
	{ id: 'calltype', label: 'callType', checked: false },
	{ id: 'origin', label: 'callOrigin', checked: false },
	{ id: 'careEventTypeName', label: 'careEventType', checked: false },
	{ id: 'patientmrn', label: 'patientMRN', checked: false },
	{ id: 'usedaudio', label: 'usedAudio', checked: false },
	{ id: 'usedvideo', label: 'usedVideo', checked: false },
	{ id: 'usedscreen', label: 'usedScreen', checked: false },
	{ id: 'status', label: 'callStatus', checked: false },
	{ id: 'invitedParticipants', label: 'invitedParticipants', checked: false },
	{ id: 'statAlarm', label: 'statAlarmInCSV', cheked: false },
	{ id: 'interpretationServices', label: 'interpretationServices', checked: false },
];

export const sessionByHours = intl => [
	{
		label: intl.formatMessage({ id: 'videoMonitoring' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: LightTheme.colors.blueFour,
		borderWidth: 0,
		backgroundColor: LightTheme.colors.blueFour08,
		pointRadius: 0,
		pointBackgroundColor: LightTheme.colors.blueFour,
		fill: false,
		callTypes: [CallTypes.MONITORING],
		total: 0,
		order: 1,
	},
	{
		label: intl.formatMessage({ id: 'ambientMonitoring' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: LightTheme.colors.purpleTwo,
		borderWidth: 0,
		backgroundColor: LightTheme.colors.purpleTwo08,
		pointRadius: 0,
		pointBackgroundColor: LightTheme.colors.purpleTwo,
		fill: false,
		callTypes: [CallTypes.AMBIENT],
		total: 0,
		order: 2,
	},
	{
		label: intl.formatMessage({ id: 'talkToPatient' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: LightTheme.colors.greenTwo,
		borderWidth: 0,
		backgroundColor: LightTheme.colors.greenTwo08,
		pointRadius: 0,
		pointBackgroundColor: LightTheme.colors.greenTwo,
		fill: false,
		callTypes: [CallTypes.AUDIO, CallTypes.VIDEO],
		total: 0,
		order: 3,
	},
	{
		label: intl.formatMessage({ id: 'viewPatient' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#7892C3',
		borderWidth: 0,
		backgroundColor: '#7892C3CC',
		pointRadius: 0,
		pointBackgroundColor: '#7892C3',
		fill: false,
		callTypes: [CallTypes.SECURITY_CAM],
		total: 0,
		order: 4,
	},
];

export const providersRoles = [
	{ id: UserTypes.DOCTOR, name: UserRoles.DOCTOR },
	{ id: UserTypes.NURSE, name: UserRoles.NURSE },
	{ id: UserTypes.VIRTUAL_SITTER, name: UserRoles.VIRTUAL_SITTER },
	{ id: UserTypes.ROOM_SIGN, name: UserRoles.ROOM_SIGN },
];

export const BarChartColors = {
	NURSE: 'var(--green-7)',
	DOCTOR: 'var(--green-2)',
	VIRTUAL_SITTER: 'var(--green-6)',
	VISITOR: 'var(--green-5)',
	PATIENT: 'var(--green-4)',
};

export const byHourChartLabels = [
	'12 AM',
	'1 AM',
	'2 AM',
	'3 AM',
	'4 AM',
	'5 AM',
	'6 AM',
	'7 AM',
	'8 AM',
	'9 AM',
	'10 AM',
	'11 AM',
	'12 PM',
	'1 PM',
	'2 PM',
	'3 PM',
	'4 PM',
	'5 PM',
	'6 PM',
	'7 PM',
	'8 PM',
	'9 PM',
	'10 PM',
	'11 PM',
];

const customTooltip = (tooltipModel, tooltipRef) => {
	if (!tooltipRef.current) {
		return;
	}
	const tooltipEl = tooltipRef.current.children[0];
	const tooltipCaret = tooltipRef.current.children[1];

	if (tooltipModel.opacity === 0) {
		tooltipEl.style.opacity = '0';
		tooltipCaret.style.opacity = '0';
		return;
	}

	if (tooltipModel.title?.length) {
		const tooltipHeader = document.getElementById('chartjs-tooltip-header') ?? document.createElement('div');
		tooltipHeader.innerHTML = tooltipModel.title[0];
		tooltipHeader.id = 'chartjs-tooltip-header';
		tooltipEl.appendChild(tooltipHeader);
	}

	if (tooltipModel.body) {
		const tooltipBody = document.getElementById('chartjs-tooltip-body') ?? document.createElement('div');
		tooltipBody.id = 'chartjs-tooltip-body';
		tooltipBody.innerHTML = '';
		const bodyLines = tooltipModel.body.map(bodyItem => bodyItem.lines);

		bodyLines.forEach((bodyLine, bodyLineIndex) => {
			const tooltipItem = document.createElement('div');
			const itemMarker = document.createElement('span');
			const itemLabel = document.createElement('span');
			const labelContent = document.createElement('div');
			const itemValue = document.createElement('span');

			labelContent.className = 'chartjs-tooltip-label';
			itemMarker.style.backgroundColor = tooltipModel.labelColors[bodyLineIndex].backgroundColor;
			itemLabel.innerHTML = bodyLine[0].split(':')[0];
			itemValue.innerHTML = bodyLine[0].split(':')[1];

			labelContent.appendChild(itemMarker);
			labelContent.appendChild(itemLabel);
			tooltipItem.appendChild(labelContent);
			tooltipItem.appendChild(itemValue);
			tooltipBody.appendChild(tooltipItem);
		});
		tooltipEl.appendChild(tooltipBody);
	}

	if (tooltipModel.footer) {
		const tooltipFooter = document.getElementById('chartjs-tooltip-footer') ?? document.createElement('div');
		tooltipFooter.id = 'chartjs-tooltip-footer';
		tooltipFooter.innerHTML = '';
		tooltipModel.footer.forEach(footerItem => {
			const tooltipItem = document.createElement('div');
			const itemLabel = document.createElement('span');
			const itemValue = document.createElement('span');

			itemLabel.innerHTML = footerItem.label;
			itemValue.innerHTML = footerItem.value;

			tooltipItem.appendChild(itemLabel);
			tooltipItem.appendChild(itemValue);
			tooltipFooter.appendChild(tooltipItem);
		});
		tooltipEl.appendChild(tooltipFooter);
	}

	const getCaretOffset = (caretPosition, caretSize) => {
		switch (caretPosition) {
			case 'right-center':
			case 'left-bottom':
				return -caretSize;
			case 'right-bottom':
			case 'left-center':
				return caretSize;
			default:
				return 0;
		}
	};

	const { xAlign, yAlign, caretX, caretY, caretSize } = tooltipModel;
	const xAlignTransformValues = { left: 0, center: -50, right: -100 };
	const yAlignTransformValues = { top: 0, center: -50, bottom: -100 };
	const translateX = xAlignTransformValues[xAlign];
	const translateY = yAlignTransformValues[yAlign];
	const caretDirection = yAlign === 'center' ? xAlign : yAlign;
	const caretPosition = `${xAlign}-${yAlign}`;
	const caretXOffset = getCaretOffset(caretPosition, caretSize);
	const caretYOffset = yAlign === 'bottom' ? -caretSize : 0;

	tooltipEl.style.opacity = '1';
	tooltipEl.style.position = 'absolute';
	tooltipEl.style.left = window.scrollX + caretX + caretXOffset + 'px';
	tooltipEl.style.top = window.scrollY + caretY + caretYOffset + 'px';
	tooltipEl.style.pointerEvents = 'none';
	tooltipEl.style.transform = `translate(${translateX}%, ${translateY}%)`;
	tooltipCaret.style.opacity = '1';
	tooltipCaret.style.left = window.scrollX + caretX + 'px';
	tooltipCaret.style.top = window.scrollY + caretY + 'px';
	tooltipCaret.style.transform = `translate(${translateX}%, ${translateY}%)`;
	tooltipCaret.className = `direction-${caretDirection}`;
};

export const byHourChartOptions = (translator, tooltipRef) => ({
	responsive: true,
	scales: {
		yAxes: [
			{
				stacked: true,
			},
		],
		xAxes: [
			{
				stacked: true,
				gridLines: {
					display: false,
				},
			},
		],
	},
	legend: {
		display: false,
	},
	tooltips: {
		enabled: false,
		mode: 'index',
		caretSize: 8,
		custom: tooltipModel => customTooltip(tooltipModel, tooltipRef),
		itemSort: (a, b, { datasets }) => datasets[b.datasetIndex].order - datasets[a.datasetIndex].order,
		callbacks: {
			title: (tooltipItem, data) => {
				const { index, label } = tooltipItem[0];
				const toIndex = (index + 1) % data.labels.length;

				return `${label} to ${data.labels[toIndex]}`;
			},
			footer: (tooltipItem, data) => {
				const { index } = tooltipItem[0];
				const total = data.datasets.reduce(
					(acc, curr) =>
						Object.values(curr._meta)[0]?.hidden
							? acc
							: { sessions: (acc.sessions += curr.data[index]), duration: (acc.duration += curr.duration[index]) },
					{ sessions: 0, duration: 0 }
				);

				const totalSessions = { label: translator('totalSessions'), value: total.sessions };
				const totalSessionsDuration = {
					label: translator('totalSessionsDuration'),
					value: formatTimeDurationWithSeconds({
						seconds: total.duration,
						joinWith: ' ',
						showDays: true,
					}),
				};
				const averageSessionDuration = {
					label: translator('averageSessionDuration'),
					value: formatTimeDurationWithSeconds({
						seconds: Math.round(total.duration / total.sessions),
						joinWith: ' ',
						showDays: true,
					}),
				};

				return [totalSessions, totalSessionsDuration, averageSessionDuration];
			},
		},
	},
});

export const aiAlertsChartOptions = label => ({
	cutoutPercentage: 75,
	maintainAspectRatio: false,
	responsive: true,
	legend: false,
	elements: {
		center: {
			enable: true,
			labelText: label,
			fontSize: '48px',
		},
	},
});

export const buildDataPerHours = list => {
	const data = byHourChartLabels.map(label => {
		let hour = parseInt(label.split(' ')[0], 10);
		if (hour === 12 && label.includes('AM')) {
			hour = 0;
		} else if (hour !== 12 && label.includes('PM')) {
			hour += 12;
		}

		const hourData = list.reduce(
			(accumulator, item) => {
				let count = accumulator.count;
				let duration = accumulator.duration;

				if (item.hour === hour) {
					count += item.count;
					duration += item.totalDuration;
				}

				return { count, duration };
			},
			{ count: 0, duration: 0 }
		);

		return hourData;
	});

	return data;
};

export const DevicesStatus = {
	OFFLINE: 'Offline',
	ONLINE: 'Online',
};

export const initialDevicesStatusData = {
	[DevicesStatus.OFFLINE]: {
		label: 'Offline',
		value: 0,
		percentage: 0,
		stroke: '#718093',
	},
	[DevicesStatus.ONLINE]: {
		label: 'Online',
		value: 0,
		percentage: 0,
		stroke: '#33C27F',
	},
};

export const RoundingTypes = {
	TALK_TO_PATIENT: {
		type: 0,
		value: 'talk-to-patient',
	},
	VIEW_PATIENT: {
		type: 1,
		value: 'view-patient',
	},
	ROOM_SIGNAGE: {
		type: 2,
		value: 'source',
	},
};

export const RoundingOrigins = {
	OUTGOING: {
		type: 0,
		color: '#6BC6B5',
		title: 'outgoing',
		description: 'outgoingSessionsDescription',
	},
	INCOMING: {
		type: 1,
		color: '#8EB66F',
		title: 'incoming',
		description: 'incomingSessionsDescription',
	},
	VIEW_PATIENT: {
		type: 2,
		color: '#7892C3',
		title: 'viewPatient',
		description: 'viewPatientSessionsDescription',
	},
	ROOM_SIGNAGE: {
		type: 1,
		color: '#B99364',
		title: 'digitalRoomSignage',
		description: 'roomSignageSessionsDescription',
	},
};

export const DashboardWidget = {
	ORANGE: { iconColor: 'var(--orange-1)', backgroundColor: 'var(--orange-1-o10)' },
	PURPLE: { iconColor: 'var(--purple-1)', backgroundColor: 'var(--purple-3-o10)' },
};

export const EventCategoryIcon = {
	[EventCategory.AI_ALERT]: <AlertIcon color={LightTheme.colors.grayFive} />,
	[EventCategory.STAT_ALARM]: <AmbientMonitoring color={LightTheme.colors.grayFive} width={24} height={24} />,
	[EventCategory.PATIENT_INFO_BOX_UPDATE]: <ProfileIcon color={LightTheme.colors.grayFive} />,
	[EventCategory.VERBAL_REDIRECTION]: <VoiceIcon color={LightTheme.colors.grayFive} />,
	[EventCategory.MICROPHONE]: <MicIcon color={LightTheme.colors.grayFive} strokeWidth={1} />,
	[EventCategory.PATIENT_OUT_OF_ROOM]: <PatientOutOfRoomAlt color={LightTheme.colors.grayFive} width={24} height={24} />,
	[EventCategory.PRIVACY]: <PatientPrivacy color={LightTheme.colors.grayFive} strokeWidth={1} width={24} height={24} />,
	[EventCategory.NIGHT_VISION]: <NightVisionIconOn color={LightTheme.colors.grayFive} strokeWidth={1} />,
	[EventCategory.PRECAUTIONS]: <PrecautionsIcon color={LightTheme.colors.grayFive} strokeWidth={0.7} width={24} height={24} />,
	[EventCategory.LIVE_CAPTION]: <LiveCaptionsIcon color={LightTheme.colors.grayFive} strokeWidth={1.25} />,
	[EventCategory.MANUAL_INTERVENTION_AND_ALERT]: <GearIconAlt color={LightTheme.colors.grayFive} />,
	[EventCategory.AI_ALERT_INTERVENTION]: <AiIcon color={LightTheme.colors.grayFive} />,
	[EventCategory.AMBIENT_VIDEO_SWITCH]: <VideoMonitoring color={LightTheme.colors.grayFive} width={24} height={24} />,
	[EventCategory.START_AND_END_POINT_CARE_ACTIONS]: <ConnectIcon color={LightTheme.colors.grayFive} width={24} height={24} />,
	[EventCategory.AI_SETTINGS]: <AISettings color={LightTheme.colors.grayFive} width={24} height={24} />,
	[EventCategory.SCREENSHARE]: <Screenshare color={LightTheme.colors.grayFive} width={24} height={24} strokeWidth={1} />,
};

export const tabEnums = { OVERVIEW: 0, CARE_TEAM_REPORT: 1, PATIENT_REPORT: 2, HAND_HYGIENE: 3 };

export const DetailedReportType = { SESSIONS: 0, INTERVENTIONS: 1, AI: 2 };

export const DefaultTimezone = { label: 'Coordinated Universal Time', zone: 'UTC' };

export const formatDateHeader = (intl, intlId, timezone) =>
	`${intl.formatMessage({ id: intlId })} (${getTimezoneAbbreviation(timezone)})`;

export const InterventionColumnLabels = {
	category: 'type',
	event: 'event',
	provider: 'intervenedBy',
};
