/* This file will be importet from an external library in the future, therefore ESLINT is ignored. */
// eslint-disable-next-line max-classes-per-file
import hark from 'hark';
import {
	LocalTrackController,
	BaseRemoteTrackController,
	Cam, Mic, ScreenShare,
	P2PConnection,
	TrackAdded,
	Answer,
	LocalTrackFactory,
} from '@solaborate/calls/webrtc';
import { v4 } from 'uuid';
import { HarkEvents, MediaPermissions, MediaTypes } from 'constants/enums.js';
import Emitter from 'owt/p2p/Emitter.js';
import { checkForPermission } from 'infrastructure/helpers/commonHelpers.js';
import Signaling from 'services/walkieTalkie/signaling.js';

export default class PTT extends Emitter {
	constructor(userInfo, audioElement, socket, iceServers) {
		super();
		this.participantId = v4();
		this.audioElement = audioElement;
		this.socket = socket;
		this.signaling = null;
		this.hark = null;
		this.connection = null;
		this.track = null;
		this.userInfo = userInfo;

		socket.on('ptt.answer', answer => {
			if (this.connection) {
				this.connection.signal(new Answer(answer.sdp.sdp, answer.sdp.type, answer.sdp.track || {}));
			}
		});

		socket.on('ptt.left', participant => {
			this.emit('left', participant);
		});

		socket.on('ptt.talking', data => {
			this.emit('ptt_talking', data);
		});

		socket.on('ptt.joined', data => {
			this.emit('joined', data);
		});

		this.configuration = {
			iceServers: iceServers,
		};

		this.localTrackFactory = new LocalTrackFactory({
			[Mic]: true,
			[Cam]: false,
			[ScreenShare]: false,
		});

		this.localTrackController = null;
	}

	setVolumeForTrack = track => {
		if (!track || track.type !== Mic) {
			return;
		}

		if (this.hark) {
			this.hark.stop();
			this.hark = null;
		}

		const audioStream = new MediaStream([track.track]);
		this.hark = hark(audioStream, {});

		this.hark.on(HarkEvents.STOPPED_SPEAKING, () => {
			this.emit('ptt_stopped_speaking', 0);
		});

		this.hark.on(HarkEvents.VOLUME_CHANGE, (volume, threshold) => {
			if (volume < threshold) {
				return;
			}
			const calcVolume = Math.round(((volume - threshold) * -10) / threshold);
			this.emit('ptt_volume_changed', calcVolume);
		});
	};

	mic = async enabled => {
		if (!this.track && enabled) {
			try {
				await this.localTrackController.add(Mic);
				this.track = this.localTrackController.tracks[Mic];
				this.track.track.enabled = false;
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
				return err;
			}
		}
		this.track.track.enabled = enabled;
		this.signaling.talking(enabled);
		return { enabled };
	};

	speaker = enabled => {
		this.audioElement.muted = !enabled;
	};

	/**
	 * data.objectId
	 * data.objectType
	 * data.name
	 * data.pic
	 */
	join = async (poolId, conversationId) => {
		this.signaling = new Signaling(this.socket, poolId, this.participantId, this.userInfo, conversationId);

		this.connection = new P2PConnection(this.signaling, this.configuration);
		this.localTrackController = new LocalTrackController(this.connection, this.localTrackFactory);
		const remoteTrackController = new BaseRemoteTrackController(this.connection);
		let ack = null;
		this.connection.on(event => {
			if (event instanceof TrackAdded) {
				// eslint-disable-next-line no-param-reassign
				this.audioElement.srcObject = new MediaStream([event.track.track]);
				// eslint-disable-next-line no-param-reassign
				this.audioElement.muted = false;
				this.audioElement.play();
			}
		});
		remoteTrackController.request(Mic);
		const permission = await checkForPermission(MediaTypes.MICROPHONE);
		if (permission.state === MediaPermissions.GRANTED) {
			try {
				await this.localTrackController.add(Mic);
				this.track = this.localTrackController.tracks[Mic];
				this.track.track.enabled = false;
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
			}
		}

		ack = await this.signaling.join();
		await this.connection.connect();
		this.setVolumeForTrack(this.track);

		return ack;
	};

	leave = () => {
		if (this.signaling) {
			this.signaling.leave();
		}
		if (this.connection) {
			this.connection.close();
		}
		if (this.localTrackController) {
			this.localTrackController.remove(Mic);
		}
		this.signaling = null;
		this.connection = null;
		this.localTrackController = null;
	};
}
