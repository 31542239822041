import { forwardRef } from 'react';
import styled from 'styled-components';
import LightTheme from 'calls/styles/LightTheme.js';

const StyledSelect = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: ${LightTheme.spacing[4]}px;

	label {
		display: flex;
		align-items: center;
		width: 100%;
		margin-bottom: ${LightTheme.spacing[2]}px;
		font-size: 14px;
		color: ${LightTheme.colors.grayFive};
		font-weight: 500;
	}

	select {
		background: ${LightTheme.colors.grayZero};
		padding: ${LightTheme.spacing[2]}px;
		color: ${LightTheme.colors.grayFive};
		border: 1px solid transparent;
		font-size: 14px;
		border-radius: ${LightTheme.borderRadius.base}px;
		transition: 0.2s;
		color: ${LightTheme.colors.grayFive};
		box-shadow:
			0 0 0 1px rgb(0 0 0 / 10%),
			0 2px 2px rgb(0 0 0 / 8%);
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23353b48' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: right 8px center;
		background-size: 18px;
		appearance: none;

		&:placeholder {
			color: ${LightTheme.colors.grayThree};
		}

		&:focus {
			border: 1px solid ${LightTheme.colors.blueTwo};
		}

		option {
			font-size: 16px;
		}
	}

	small {
		margin-top: ${LightTheme.spacing[2]}px;
		color: ${LightTheme.colors.grayThree};
		font-size: 12px;
	}
`;

const Select = ({ children }) => {
	return <StyledSelect>{children}</StyledSelect>;
};

const SelectLabel = ({ children }) => {
	return <label>{children}</label>;
};

const SelectField = forwardRef(
	(
		/**
		 * @type {React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>}
		 */
		{ children, ...htmlAttributes },
		ref
	) => (
		<select {...htmlAttributes} ref={ref}>
			{children}
		</select>
	)
);

const SelectOption = ({ ...htmlAttributes }) => {
	return <option {...htmlAttributes} />;
};

const SelectDescription = ({ children }) => {
	return <small>{children}</small>;
};

Select.Label = SelectLabel;
Select.Field = SelectField;
Select.Option = SelectOption;
Select.Description = SelectDescription;

export default Select;
