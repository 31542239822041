import { FieldArray } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { TabPanel } from 'components/Tabs.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { ScheduledSurveyType, SurveyType } from 'constants/rpm.js';
import translate from 'i18n-translations/translate.jsx';
import { getScheduledSurveyType, getSurveyType } from 'infrastructure/helpers/rpmHelper.js';
import Button from 'components/Button.jsx';
import TableDeleteButton from 'icons/Admin/TableDeleteButton.jsx';
import TableEditButton from 'icons/Admin/TableEditButton.jsx';

const CreateSurvey = ({
	questions,
	handleChange,
	errors,
	selectedSurveyType,
	setSelectedSurveyType,
	selectedScheduleType,
	setSelectedScheduleType,
	setFieldValue,
	touched,
	setShowError,
	showError,
}) => {
	const intl = useIntl();
	const MAX_OPTIONS = 4;
	const MAX_QUESTIONS = 9;
	const SCHEDULED_SURVEY_TYPES = 3;
	const [isCreateSurvey, setIsCreateSurvey] = useState(false);
	const [isEditSurvey, setIsEditSurvey] = useState(false);

	const shouldShowCreateButton = () =>
		questions.some(item => {
			if (item.surveyType !== SurveyType.SCHEDULED) {
				return item.surveyType === selectedSurveyType?.value;
			}
			return item.surveyScheduleType === selectedScheduleType?.value;
		});

	const groupSurveyByType = items => {
		const groupedSurveys = {};
		items.forEach(item => {
			if (!groupedSurveys[item.surveyType]) {
				if (item.surveyType !== SurveyType.SCHEDULED) {
					groupedSurveys[item.surveyType] = item;
				} else {
					groupedSurveys[item.surveyScheduleType + SCHEDULED_SURVEY_TYPES] = item;
				}
			}
		});
		return Object.values(groupedSurveys);
	};

	const groupedSurveys = groupSurveyByType(questions);

	const handleDeleteSurvey = (surveyType, scheduledSurveyType) => {
		const filteredSurvey = questions.filter(item => {
			if (surveyType !== SurveyType.SCHEDULED) {
				return item.surveyType !== surveyType;
			}
			return item.surveyScheduleType !== scheduledSurveyType;
		});
		setFieldValue('questions', filteredSurvey);
	};

	const getSurveyDescription = selectedSurvey => {
		switch (selectedSurvey) {
			case SurveyType.GENERIC:
				return 'genericDescription';
			case SurveyType.SAFETY_RANGE:
				return 'safetyRangeDescription';
			case SurveyType.SCHEDULED:
				return 'scheduledDescription';
			default:
				return 'genericDescription';
		}
	};

	return (
		<TabPanel>
			{!isCreateSurvey && (
				<div className='full-width'>
					<div className='flex flex-space-between'>
						<p className='semi-bold'>{translate('surveys')}</p>
						{!isEditSurvey && (
							<Button
								type='button'
								className='survey-btn questions-btn margin-top-m'
								onClick={() => {
									setIsCreateSurvey(true);
									setSelectedSurveyType(null);
									setSelectedScheduleType(null);
								}}
								imgIcon={`${healthCareCdnUrl}rpm/plus-active.svg`}
								text={translate('createSurvey')}
							/>
						)}
					</div>
					{questions.length === 0 && <p className='--gray-color'>{translate('addNewSurveyDescription')}</p>}
					{questions.length > 0 && !isEditSurvey && (
						<div className='full-width'>
							<div className='flex flex-space-between survey-header'>
								<p>{translate('surveyType')}</p>
								<p>{translate('actions')}</p>
							</div>
							{groupedSurveys.map(item => (
								<div className='flex flex-space-between survey-list-wrapper'>
									<p>
										{item.surveyScheduleType ? intl.formatMessage({ id: getScheduledSurveyType(item.surveyScheduleType) }) : ''}{' '}
										{translate('typeOfSurvey', {
											value: intl.formatMessage({
												id: getSurveyType(item.surveyType),
											}),
										})}
									</p>
									<div className='survey-action-buttons wrapped'>
										<TableEditButton
											onClick={() => {
												setIsEditSurvey(true);
												setSelectedSurveyType({
													label: intl.formatMessage({ id: getSurveyType(item.surveyType) }),
													value: item.surveyType,
												});
												setSelectedScheduleType(
													item.surveyScheduleType
														? {
																label: intl.formatMessage({ id: getScheduledSurveyType(item.surveyScheduleType) }),
																value: item.surveyScheduleType,
														  }
														: null
												);
											}}
										/>
										<TableDeleteButton onClick={() => handleDeleteSurvey(item.surveyType, item.surveyScheduleType)} />
									</div>
								</div>
							))}
						</div>
					)}
				</div>
			)}

			{(isCreateSurvey || isEditSurvey) && (
				<div className='survey-wrapper'>
					<div className='flex'>
						<Button
							type='button'
							className='care-logs-btn'
							onClick={() => {
								setIsCreateSurvey(false);
								setIsEditSurvey(false);
							}}
							icon='chevron_left'
						/>

						<p className='semi-bold font-16 no-margin'>{translate(isCreateSurvey ? 'createSurvey' : 'editSurvey')}</p>
					</div>

					<div className='flex'>
						<div className='survey-types-wrapper'>
							<p className='semi-bold font-14 margin-bottom-0'>{translate('surveyType')}</p>
							<Select
								classNamePrefix='custom-select'
								onChange={survey => setSelectedSurveyType(survey)}
								value={selectedSurveyType}
								options={[
									{ label: intl.formatMessage({ id: 'generic' }), value: SurveyType.GENERIC },
									{ label: intl.formatMessage({ id: 'safetyRange' }), value: SurveyType.SAFETY_RANGE },
									{ label: intl.formatMessage({ id: 'scheduled' }), value: SurveyType.SCHEDULED },
								]}
								placeholder={intl.formatMessage({ id: 'selectSurvey' })}
								isDisabled={selectedSurveyType}
							/>
						</div>
						{selectedSurveyType?.value === SurveyType.SCHEDULED && (
							<div className='survey-types-wrapper'>
								<p className='semi-bold font-14 margin-bottom-0'>{translate('repeat')}</p>
								<Select
									classNamePrefix='custom-select'
									onChange={survey => setSelectedScheduleType(survey)}
									value={selectedScheduleType}
									options={[
										{ label: intl.formatMessage({ id: 'daily' }), value: ScheduledSurveyType.DAILY },
										{ label: intl.formatMessage({ id: 'weekly' }), value: ScheduledSurveyType.WEEKLY },
										{ label: intl.formatMessage({ id: 'monthly' }), value: ScheduledSurveyType.MONTHLY },
									]}
									placeholder={intl.formatMessage({ id: 'selectSchedule' })}
									isDisabled={selectedScheduleType}
								/>
							</div>
						)}
					</div>
					{selectedSurveyType?.value && (
						<div className='margin-top-m'>
							<p className='font-14 margin-bottom-0 --gray-color'>{translate(getSurveyDescription(selectedSurveyType?.value))}</p>
						</div>
					)}
				</div>
			)}

			<FieldArray name='questions'>
				{arrayHelpers => (
					<>
						{(isCreateSurvey || isEditSurvey) &&
							((selectedSurveyType && selectedSurveyType?.value !== SurveyType.SCHEDULED) || selectedScheduleType) && (
								<>
									<div className='full-width margin-top-m'>
										<div className='flex right-align-content'>
											{questions.length === 0 && (
												<Button
													className='survey-btn questions-btn margin-top-m'
													onClick={() =>
														arrayHelpers.push({
															text: '',
															options: [],
															surveyType: selectedSurveyType.value,
															surveyScheduleType: selectedScheduleType?.value || null,
														})
													}
													disabled={!selectedSurveyType}
													imgIcon={`${healthCareCdnUrl}rpm/plus-active.svg`}
													text={translate('addNewQuestion')}
												/>
											)}
										</div>
									</div>
									<div className='top-15'>
										{questions.map((question, index) => (
											<>
												{question.surveyType === selectedSurveyType.value &&
													(question.surveyType !== SurveyType.SCHEDULED ||
														question.surveyScheduleType === selectedScheduleType?.value) && (
														<div key={question} className='questions-wrapper'>
															<div className='flex flex-align-center'>
																<input
																	type='text'
																	placeholder={intl.formatMessage({ id: 'question' })}
																	value={question.text}
																	onChange={handleChange}
																	name={`questions[${index}].text`}
																	maxLength={250}
																	className='question-input'
																/>
																<div className='wrapped'>
																	<TableDeleteButton
																		className='remove-item-btn left-s'
																		onClick={() => arrayHelpers.remove(index)}
																	/>
																</div>
															</div>
															<FieldArray name={`questions[${index}].options`}>
																{optionsArrayHelpers => (
																	<div className='position-relative'>
																		<p className='survey-options-title'>{translate('options')}</p>
																		{question.options.map((option, optionIndex) => (
																			<div className='flex flex-align-center right-align-content'>
																				<input
																					type='text'
																					placeholder={intl.formatMessage({ id: 'option' })}
																					value={option}
																					name={`questions[${index}].options[${optionIndex}]`}
																					onChange={handleChange}
																					maxLength={250}
																					className='question-input option-input'
																				/>
																				<div className='wrapped'>
																					<TableDeleteButton
																						className='remove-item-btn left-s'
																						onClick={() => optionsArrayHelpers.remove(optionIndex)}
																					/>
																				</div>
																			</div>
																		))}
																		{question.options.length <= MAX_OPTIONS && (
																			<div className='flex right-align-content'>
																				<Button
																					type='button'
																					className='survey-btn options-btn'
																					onClick={() => optionsArrayHelpers.push('')}
																					imgIcon={`${healthCareCdnUrl}rpm/plus-active.svg`}
																					text={translate('addMoreOptions')}
																				/>
																			</div>
																		)}
																	</div>
																)}
															</FieldArray>
														</div>
													)}
											</>
										))}
										{questions.length > 0 &&
											questions.filter(item => item.surveyType === selectedSurveyType?.value)?.length <= MAX_QUESTIONS && (
												<div className='flex'>
													<Button
														className='survey-btn questions-btn margin-top-m'
														onClick={() =>
															arrayHelpers.push({
																text: '',
																options: [],
																surveyType: selectedSurveyType.value,
																surveyScheduleType: selectedScheduleType?.value || null,
															})
														}
														imgIcon={`${healthCareCdnUrl}rpm/plus-active.svg`}
														text={translate('addNewQuestion')}
													/>
												</div>
											)}
									</div>
								</>
							)}

						{(isCreateSurvey || isEditSurvey) && questions.length > 0 && shouldShowCreateButton() && (
							<div className='flex-end'>
								<Button
									className='survey-btn create'
									disabled={errors}
									onClick={() => {
										setIsCreateSurvey(false);
										setIsEditSurvey(false);
										setShowError(false);
									}}
									imgIcon={`${healthCareCdnUrl}next.svg`}
									text={translate(isCreateSurvey ? 'createSurvey' : 'editSurvey')}
								/>
							</div>
						)}
					</>
				)}
			</FieldArray>
			{showError && touched.questions?.length > 0 && <span className='red-error'>{errors}</span>}
		</TabPanel>
	);
};

export default CreateSurvey;
