const ConnectIcon = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='20'
		height='20'
		viewBox='0 0 20 20'
		strokeWidth='0.8'
		fill='none'
		color='white'
		{...props}>
		<path d='M5 6.66602L5 13.3327' stroke='currentColor' strokeMiterlimit='10' />
		<path d='M15 6.66602V13.3327' stroke='currentColor' strokeMiterlimit='10' />
		<path d='M6.6665 15L13.3332 15' stroke='currentColor' strokeMiterlimit='10' />
		<path d='M6.6665 5L13.3332 5' stroke='currentColor' strokeMiterlimit='10' />
		<rect x='3.33325' y='3.33301' width='3.33333' height='3.33333' rx='1' stroke='currentColor' strokeMiterlimit='10' />
		<rect x='3.33325' y='13.333' width='3.33333' height='3.33333' rx='1' stroke='currentColor' strokeMiterlimit='10' />
		<rect x='13.3333' y='3.33301' width='3.33333' height='3.33333' rx='1' stroke='currentColor' strokeMiterlimit='10' />
		<rect x='13.3333' y='13.333' width='3.33333' height='3.33333' rx='1' stroke='currentColor' strokeMiterlimit='10' />
	</svg>
);

export default ConnectIcon;
