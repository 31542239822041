const SortActive = props => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' color='#343434' {...props}>
		<path d='M23 16L19 20L15 16' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
		<path d='M19 9L19 20' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
		<line x1='1.99997' y1='4.5' x2='22' y2='4.5' stroke='currentColor' />
		<line x1='1.99997' y1='9.5' x2='14' y2='9.5' stroke='currentColor' />
		<line x1='1.99997' y1='14.5' x2='8.99997' y2='14.5' stroke='currentColor' />
		<line x1='1.99997' y1='19.5' x2='5.99997' y2='19.5' stroke='currentColor' />
	</svg>
);

export default SortActive;
