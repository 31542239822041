import React, { useEffect, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import ProfilePicture from 'components/ProfilePicture.jsx';

const SearchResultsItems = props => {
	const activeSearchItem = useRef(null);
	const intl = useIntl();

	useEffect(() => {
		if (activeSearchItem?.current) {
			activeSearchItem.current.focus();
		}
	}, [props.index]);

	const getSearchItem = (textToHighlight, status, item) => {
		const searchedDevices =
			props.isPatientsSearch &&
			item.devices.filter(patient => patient.name.toLowerCase().includes(props.searchBox.toLowerCase()));
		return (
			<div
				key={item.id}
				onClick={() => props.onItemClick(item)}
				tabIndex={item.focusIndex}
				ref={item.focusIndex === props.index ? activeSearchItem : null}>
				<div className='chat-list-item flex'>
					<>
						{props.isDevicesSearchedItems && (
							<div className='chat-list-img'>
								<img src='https://static.solaborate.com/healthcare-system/hello-icon-gray.svg?v10' alt='icon' />
							</div>
						)}
						{!props.isDevicesSearchedItems && (
							<ProfilePicture className='chat-list-img' fullName={textToHighlight} profilePicture={item.profilePicture} />
						)}
						<div className='chat-list-name header-search-name flex flex-1'>
							<p className='header-search-primary'>
								<Highlighter searchWords={props.searchWords} autoEscape={true} textToHighlight={textToHighlight} />
							</p>
							{!props.isDoctorsList && !props.isPatientsSearch && item.devicesInfos?.length > 0 && (
								<span>
									<Highlighter searchWords={props.searchWords} autoEscape={true} textToHighlight={item.devicesInfos[0].name} />
								</span>
							)}
							{props.isPatientsSearch &&
								item.devices.length > 0 &&
								searchedDevices.map(patient => (
									<Highlighter searchWords={props.searchWords} autoEscape={true} textToHighlight={patient.name} />
								))}
							{props.isPatientsSearch && item.devices.length > 0 && item.devices.length - searchedDevices.length > 0 && (
								<span className='--blue-color'>
									{`${intl.formatMessage({ id: 'and' })} ${item.devices.length - searchedDevices.length} ${intl.formatMessage({
										id: 'otherDevices',
									})}`}
								</span>
							)}
						</div>
						<div className='chat-list-status flex'>
							<span className={status ? 'is-on' : 'is-off'} />
						</div>
					</>
				</div>
			</div>
		);
	};

	return (
		<>
			{props.searchedItems.length > 0 && (
				<div className='chat-item-title'>
					<span className='semi-bold'>{props.title}</span>
				</div>
			)}
			{props.searchedItems.map(item => {
				const status = props.isDoctorsList ? item.presenceStatusTypeId === 1 : item.status;
				const textToHighlight = props.isDevicesSearchedItems ? item.name : `${item.firstName} ${item.lastName}`;
				return getSearchItem(textToHighlight, status, item);
			})}
		</>
	);
};

export default SearchResultsItems;
