import React from 'react';
import { useSelector } from 'react-redux'; 
import { HealthSystemType } from 'constants/enums.js';
import DoctorHomePage from 'views/DoctorHomePage.jsx';
import DoctorCheckIn from 'containers/PrimaryCare/Doctor/DoctorCheckIn.jsx';

const DoctorHomePageWrapper = () => {
	const workflowTypeId = useSelector(state => state.user.userSession.healthSystem.workflowTypeId); 
	return (
		<>
			{workflowTypeId === HealthSystemType.PRIMARY_CARE && <DoctorCheckIn />}
			{workflowTypeId !== HealthSystemType.PRIMARY_CARE && <DoctorHomePage />}
		</>
	);
};

export default DoctorHomePageWrapper;
