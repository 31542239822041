import React, { useState } from 'react';
import { Mic, Cam, ScreenShare } from '@solaborate/calls/webrtc';
import { useLocalParticipant, useConferenceConfigurations, useControllerTracks } from 'calls/hooks/index.js';
import { ControlsActions, UserTypes } from 'calls/enums/index.js';
import {
	Participant,
	ParticipantVideo,
	ParticipantAudioLevel,
	StyledParticipantInfo,
	Dropdown,
	List,
	Avatar,
	IconButton,
} from 'calls/components/index.js';
import { PinIcon, UnPinIcon } from 'calls/icons/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { getCallsButtonColor } from 'infrastructure/helpers/commonHelpers.js';
import { buildProfilePic } from 'infrastructure/helpers/thumbnailHelper.js';
import translate from 'i18n-translations/translate.jsx';

/**
 * @param {object} props
 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.mainParticipant Local or remote participant
 */
const ParticipantInfo = () => {
	const participant = useLocalParticipant();
	const tracks = useControllerTracks(participant.localTrackController);

	return (
		<StyledParticipantInfo>
			{!tracks[Mic] && <IconButton icon='mic_off' background={LightTheme.colors.redOne} color={LightTheme.colors.grayZero} />}
			{tracks[Mic] && <ParticipantAudioLevel track={tracks[Mic].track} />}
		</StyledParticipantInfo>
	);
};

/**
 * @param {object} props
 * @param {(trackType: import('@solaborate/calls/webrtc').TrackType) => void} [props.onClick=null]
 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.mainParticipant Local or remote participant
 * @param {import('@solaborate/calls/webrtc').TrackType} props.activeTrackType
 */
const LocalParticipantView = ({ onClick = null, mainParticipant, activeTrackType }) => {
	const participant = useLocalParticipant();
	const tracks = useControllerTracks(participant.localTrackController);
	const conferenceConfigs = useConferenceConfigurations();
	const isCurrentlyPinnedParticipant = conferenceConfigs.pinnedParticipantId === participant.id;

	const [showControls, setShowControls] = useState({
		mainBox: false,
		screenShare: false,
	});

	const toggleDropdown = dropdownKey => {
		setShowControls(prevState => ({
			...prevState,
			[dropdownKey]: !prevState[dropdownKey],
		}));
	};

	const onSetMainParticipant = (trackType = Cam, fromTogglePin = false) => {
		if (!fromTogglePin && conferenceConfigs.pinnedParticipantId) {
			return;
		}

		if (trackType) {
			onClick(trackType);
			return;
		}

		onClick(!tracks[Cam] && tracks[ScreenShare] ? ScreenShare : Cam);
	};

	const togglePinnedParticipant = trackType => {
		conferenceConfigs.setPinnedParticipantId(isCurrentlyPinnedParticipant ? '' : participant.id);
		if (participant?.role === UserTypes.GUEST) {
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_LIVE_EXAMINATIONS, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PATIENT_HISTORY, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_DIAGNOSES, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_NOTES, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PROCEDURES, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PRESCRIPTIONS, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PHYSICAL_EXERCISES, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_WHITEBOARD, false);
		}

		if (!isCurrentlyPinnedParticipant) {
			onSetMainParticipant(trackType, true);
		}
	};

	const shouldShowCamFeed = conferenceConfigs.isGridView || activeTrackType !== Cam || mainParticipant.id !== participant.id;

	const shouldShowScreenFeed =
		tracks[ScreenShare] &&
		(conferenceConfigs.isGridView || activeTrackType !== ScreenShare || mainParticipant.id !== participant.id);

	return (
		<>
			{shouldShowCamFeed && (
				<Participant
					onClick={() => {
						onSetMainParticipant();
					}}>
					<Participant.Info>
						<ParticipantInfo />
						<p>{translate('you')}</p>
					</Participant.Info>
					<Participant.Main>
						{tracks[Cam] && <ParticipantVideo track={tracks[Cam]} />}
						{!tracks[Cam] && (
							<Avatar
								size={conferenceConfigs.isGridView ? 60 : 48}
								src={buildProfilePic(participant.picture)}
								name={participant.name}
							/>
						)}
					</Participant.Main>
					{!conferenceConfigs.isGridView && (
						<Participant.Controls>
							<Dropdown
								isOpen={showControls.mainBox}
								onClickOutside={() => setShowControls({ mainBox: false, screenShare: false })}>
								<Dropdown.Button onClick={() => toggleDropdown('mainBox')} />
								<Dropdown.Items renderIntoPortal={true}>
									<List>
										<List.Item
											onClick={() => {
												togglePinnedParticipant();
												setShowControls({ mainBox: false, screenShare: false });
											}}>
											{isCurrentlyPinnedParticipant && (
												<UnPinIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
											)}
											{!isCurrentlyPinnedParticipant && (
												<PinIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
											)}
											{translate(isCurrentlyPinnedParticipant ? 'unpinFeed' : 'pinFeed')}
										</List.Item>
									</List>
								</Dropdown.Items>
							</Dropdown>
						</Participant.Controls>
					)}
				</Participant>
			)}
			{shouldShowScreenFeed && (
				<Participant
					onClick={() => {
						onSetMainParticipant(ScreenShare);
					}}>
					<Participant.Info>
						<p>
							{translate('you')} ({translate('presenting')})
						</p>
					</Participant.Info>
					<Participant.Main>
						<ParticipantVideo track={tracks[ScreenShare]} />
					</Participant.Main>
					{!conferenceConfigs.isGridView && (
						<Participant.Controls>
							<Dropdown
								isOpen={showControls.screenShare}
								onClickOutside={() => setShowControls({ mainBox: false, screenShare: false })}>
								<Dropdown.Button onClick={() => toggleDropdown('screenShare')} />
								<Dropdown.Items renderIntoPortal={true}>
									<List>
										<List.Item
											onClick={() => {
												togglePinnedParticipant(ScreenShare);
												setShowControls({ mainBox: false, screenShare: false });
											}}>
											{isCurrentlyPinnedParticipant && (
												<UnPinIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
											)}
											{!isCurrentlyPinnedParticipant && (
												<PinIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
											)}
											{translate(isCurrentlyPinnedParticipant ? 'unpinFeed' : 'pinFeed')}
										</List.Item>
									</List>
								</Dropdown.Items>
							</Dropdown>
						</Participant.Controls>
					)}
				</Participant>
			)}
		</>
	);
};

export default LocalParticipantView;
