const Watch = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} viewBox='0 0 24 24' fill='none' {...props}>
		<path
			d='M7.75729 7.75736C10.1004 5.41421 13.8994 5.41421 16.2426 7.75736C18.5857 10.1005 18.5857 13.8995 16.2426 16.2426C13.8994 18.5858 10.1004 18.5858 7.75729 16.2426C5.41414 13.8995 5.41414 10.1005 7.75729 7.75736Z'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M9.52517 9.52513C10.892 8.15829 13.1081 8.15829 14.4749 9.52513C15.8417 10.892 15.8417 13.108 14.4749 14.4749C13.1081 15.8417 10.892 15.8417 9.52517 14.4749C8.15833 13.108 8.15833 10.892 9.52517 9.52513Z'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M5.98947 12.3537C5.98947 12.3537 5.1458 9.84736 3.46942 7.80053C3.11948 7.37326 3.11627 6.74164 3.5068 6.35111L6.34302 3.51489C6.73355 3.12437 7.36516 3.1276 7.79268 3.47723C9.84089 5.15225 12.3534 5.98976 12.3534 5.98976'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinejoin='round'
		/>
		<path
			d='M18.0103 11.6465C18.0103 11.6465 18.854 14.1529 20.5303 16.1997C20.8803 16.627 20.8835 17.2586 20.493 17.6491L17.6567 20.4854C17.2662 20.8759 16.6346 20.8726 16.2071 20.523C14.1589 18.848 11.6463 18.0105 11.6463 18.0105'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinejoin='round'
		/>
	</svg>
);
export default Watch;
