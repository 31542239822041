const FormInput = props => (
	<label className={props.labelClassName ? props.labelClassName : ''}>
		{props.text}
		<input
			className={props.className}
			onBlur={props.onBlur}
			type={props.type}
			placeholder={props.placeholder}
			onChange={props.onChange}
			id={props.id}
			value={props.value}
			max={props.max}
			min={props.min}
			onKeyDown={props.onKeyDown}
			onKeyPress={props.onKeyPress}
			name={props.name}
			maxLength={props.maxLength}
			readOnly={props.readOnly}
			disabled={props.isDisabled}
		/>
		{props.error && (props.touched || props.validateOnSubmit) && <span className='red-error'>{props.error}</span>}
	</label>
);

export default FormInput;
