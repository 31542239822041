import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const createFrontlineHospital = async ({ companyId, healthSystemId, regionId, name }) => {
	try {
		const url = `/v1/organizations/${companyId}/health-systems/${healthSystemId}/frontline-hospitals`;
		const { data } = await gatewayApi.post(url, {
			regionId,
			name,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const createHospital = async ({ companyId, healthSystemId, regionId, name, hasDefaultDepartment }) => {
	try {
		const url = `/v1/organizations/${companyId}/health-systems/${healthSystemId}/hospitals?default-department=${hasDefaultDepartment}`;
		const { data } = await gatewayApi.post(url, {
			regionId,
			name,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateHospital = async ({ healthSystemId, regionId, hospitalId, params }) => {
	try {
		const url = `/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/regions/${regionId}/hospitals/${hospitalId}`;
		const { data } = await gatewayApi.put(url, params);
		return data;
	} catch (error) {
		return { error };
	}
};
