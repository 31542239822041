import React from "react";

const ThreeDots = () => (
    <div className="three-dots-container">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
    </div>
)

export default ThreeDots